import {
	GetRoutesFromModule,
	IRouteMenuWithController
} from 'RtUi/app/@RtUi/RtUiDecorators';
import { RtUiAppModule } from 'RtUi/app/RtUiApp.module';
import { RtUserModule } from 'RtUi/app/user/user.module';
import store from 'RtUi/store';
import { reportRoutesActions } from 'RtUi/store/reducers/ReportRoutesReducer';
import { useEffect, useState } from 'react';

interface IUseGetAppRoutesParams {
	isLoggedIn: boolean;
}

export const useGetAppRoutes = ({ isLoggedIn }: IUseGetAppRoutesParams) => {
	const [routes, setRoutes] = useState<IRouteMenuWithController[]>([]);
	const dispatch = store.dispatch;

	useEffect(() => {
		const moduleName = isLoggedIn ? RtUiAppModule : RtUserModule;
		const { routeMenus, reportRoutes } = GetRoutesFromModule(moduleName);
		setRoutes(routeMenus);
		dispatch(reportRoutesActions.setRoutes(Object.fromEntries(reportRoutes)));
	}, [isLoggedIn, dispatch]);

	return routes;
};
