/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $UserProfileResponse = {
    properties: {
        userId: {
            type: 'number',
            isRequired: true,
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        emailAuthorizationTs: {
            type: 'Date',
            format: 'date-time',
        },
        badPasswordCount: {
            type: 'number',
            isRequired: true,
        },
        badPasswordTime: {
            type: 'Date',
            format: 'date-time',
        },
        isLocked: {
            type: 'number',
        },
        firstName: {
            type: 'string',
            isRequired: true,
        },
        lastName: {
            type: 'string',
            isRequired: true,
        },
        iso3166Alpha3: {
            type: 'string',
            isRequired: true,
        },
        timezoneId: {
            type: 'number',
            isRequired: true,
        },
        twoFactorAuthType: {
            type: 'number',
            isRequired: true,
        },
        badTwoFactorCount: {
            type: 'number',
            isRequired: true,
        },
        badTwoFactorTime: {
            type: 'Date',
            format: 'date-time',
        },
        officePhone: {
            type: 'string',
            isNullable: true,
        },
        mobilePhone: {
            type: 'string',
            isNullable: true,
        },
        smsPhone: {
            type: 'string',
            isNullable: true,
        },
        lastLoginTs: {
            type: 'Date',
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            format: 'date-time',
        },
        isActive: {
            type: 'number',
        },
    },
};