import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DefaultDataGridColumn,
	DataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { Link } from 'react-router-dom';

interface LinkDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	url: (row: T) => string;
}

export const LinkDataGridColumn = <T = any,>({
	url,
	...config
}: LinkDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return DefaultDataGridColumn({
		...config,
		getValue: ({ cell, row: { original } }) => (
			<Link to={url(original as T)}>{`${cell.getValue()}`}</Link>
		)
	});
};
