import { AosCol } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosCol';
import type { Cpr } from 'Somos/lib/SomosCpr/RtCprV2/Cpr';
import { type AosNetwork, AosNetworks, AosNodeType, CprNodeType, type ICprLerg, NonCombinationalAosNetworks } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

// exported definitions
// ======================================================================

export class AosColNetwork extends AosCol {

	public readonly cprNodeTypeId = undefined;
	public readonly aosNodeTypeId = AosNodeType.Network;

	public readonly valueLimit = 23;
	public readonly valueRegExp = /^[A-Z]{2}$/;

	public readonly isLergNode: boolean = false;

	public constructor(cpr: Cpr, cprIdx: number, uuid: string | undefined = undefined) {
		super(cpr, cprIdx, uuid);

		for (const aosNetwork of AosNetworks) {
			const aosAsCprLerg: ICprLerg = {
				cprNodeTypeId: CprNodeType.Switch, // @TODO not really used
				worldValue: aosNetwork.aosNetworkId,
				somosValue: aosNetwork.aosNetworkId,
				pointId: '',
				state: '',
				lata: '',
				ocn: ''
			};

			this.possibles.push(aosNetwork.aosNetworkId);
			this.possiblesByKey[aosNetwork.aosNetworkId] = aosAsCprLerg;
		}
	}

	public validate(): boolean {
		//reset
		this.errors = [];

		const values = this.getRawValues();
		const nonCombinationalAosNetworks: AosNetwork[] = [];
		let otherAosNetworksQty = 0;
		const areaCodeValues = this.cpr.aos.areaCodes.getRawValues();
		const lataValues = this.cpr.aos.latas.getRawValues();
		const stateValues = this.cpr.aos.states.getRawValues();

		for (const value of values) {
			if (NonCombinationalAosNetworks.includes(value as AosNetwork)) {
				nonCombinationalAosNetworks.push(value as AosNetwork);
			} else {
				otherAosNetworksQty++;
			}
		}

		const hasNonCombinational = nonCombinationalAosNetworks.length > 0;
		const tooManyNonCombinational = nonCombinationalAosNetworks.length > 1;
		const othersMixedWithNonCombinational = hasNonCombinational && otherAosNetworksQty > 0;

		if (tooManyNonCombinational || othersMixedWithNonCombinational) {
			this.addError(
				null,
				`${nonCombinationalAosNetworks.join(', ')} cannot be combined with other Network values`,
				undefined
			);
		}

		if (hasNonCombinational && areaCodeValues.length > 0) {
			this.addWarning(
				null,
				`${nonCombinationalAosNetworks.join(', ')} should not be combined with Area Code values`,
				undefined
			);
		}

		if (hasNonCombinational && lataValues.length > 0) {
			this.addWarning(
				null,
				`${nonCombinationalAosNetworks.join(', ')} should not be combined with LATA values`,
				undefined
			);
		}

		if (hasNonCombinational && stateValues.length > 0) {
			this.addWarning(
				null,
				`${nonCombinationalAosNetworks.join(', ')} should not be combined with State values`,
				undefined
			);
		}

		// break here if errors were found
		if (this.hasErrors()) {
			return true;
		}

		return super.validate();
	}
}
