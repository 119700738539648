import { PureComponent } from 'react';
import { RespOrgIndexResponse } from 'RtModels';
import { RespOrgResource } from 'RtUi/app/rt800/RespOrgs/lib/resources/RespOrgResource';
import { GenericTypeRender } from 'RtUi/components/ui/GenericTypeRenderer';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

export interface IRespOrgRenderProps {
	id: string;
	onLabelUpdate?: (label: string | undefined) => void;
}

export class RespOrgRender extends PureComponent<IRespOrgRenderProps> {
	public static getLabelFor(id: string): string {
		return RespOrgRender.ResourceCacheMap.getLabelFor(id);
	}

	public static async GetRespOrg(id: string) {
		return RespOrgRender.ResourceCacheMap.get(id);
	}

	private static readonly ResourceCacheMap =
		new ResourceCacheMap<RespOrgIndexResponse>(
			new RespOrgResource(),
			'respOrgId',
			'label'
		);

	public render() {
		return (
			<GenericTypeRender<RespOrgIndexResponse>
				{...this.props}
				resourceCacheMap={RespOrgRender.ResourceCacheMap}
				showIdInLabel={false}
			/>
		);
	}
}
