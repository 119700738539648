import { GuardianDoNotOriginateBulkCreateRequestItem } from 'RtModels';
import { SpreadsheetParser } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParser';
import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';

export class DoNotOriginateParser extends SpreadsheetParser {
	public aniColumn = new SpreadsheetParserColumn('ani');

	public numberTypeColumn = new SpreadsheetParserColumn('numberType', [
		'type',
		'number type'
	]);

	constructor(spreadsheet: string[][]) {
		super(spreadsheet);

		this.addParserColumn(this.aniColumn, this.numberTypeColumn);
	}

	public parseAndFindDoNotOriginateItems(): GuardianDoNotOriginateBulkCreateRequestItem[] {
		const doNotOriginateItems: GuardianDoNotOriginateBulkCreateRequestItem[] =
			[];

		const possibleHeaderMatches = this.findPossibleHeaderMatches();
		const possibleHeaderMatch = possibleHeaderMatches[0];

		const rows = this.parse(possibleHeaderMatch);
		const aniColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.aniColumn
		);
		const numberTypeColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.numberTypeColumn
		);

		for (const row of rows) {
			const ani = row[aniColumnIndex];
			const numberType = row[numberTypeColumnIndex];

			const doNotOriginateItem: GuardianDoNotOriginateBulkCreateRequestItem = {
				ani,
				numberType: Number(numberType)
			};

			doNotOriginateItems.push(doNotOriginateItem);
		}

		return doNotOriginateItems;
	}
}
