import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { ActivePortsPartitionRouter } from 'RtUi/app/rtSip/ActivePortsPartition/ActivePortsPartition.router';
import { ActivePortsPartitionDataGrid } from 'RtUi/app/rtSip/ActivePortsPartition/lib/grid/ActivePortsPartitionDataGrid';

@ActivePortsPartitionRouter.getNetworkReportsIndexRtUiController()
export class ActivePortsPartitionIndexContainer extends ApplicationContainer<
	{},
	{}
> {
	public state = {};

	public render() {
		return (
			<StandardLayout router={ActivePortsPartitionRouter}>
				<ActivePortsPartitionDataGrid />
			</StandardLayout>
		);
	}
}
