import { Row, Col } from 'react-bootstrap';
import { RoutingCacheTypes, TemplateIndexResponse } from 'RtModels';
import { RadioRoutingCacheControl } from 'RtUi/app/rt800/Cprs/lib/controls/RadioRoutingCacheControl';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import { TemplateSelect } from '../../../Templates/lib/controls/TemplateSelect';
import { useState } from 'react';

interface IRoutingCacheSelectProps {
	routingCacheType: RoutingCacheTypes;
	routingCacheValue: string;
	onChange: (
		routingCacheTypeId: RoutingCacheTypes,
		routingCacheKey: string
	) => void;
	columnMode?: boolean;
	label?: string;
	hiddenTypes?: RoutingCacheTypes[];
	displayMode?: boolean;
	respOrgId?: string;
	required?: boolean;
}

export const RoutingCacheSelect = ({
	routingCacheType,
	routingCacheValue,
	respOrgId,
	label,
	hiddenTypes,
	displayMode,
	onChange,
	required,
	columnMode
}: IRoutingCacheSelectProps) => {
	const [template, setTemplate] = useState<TemplateIndexResponse>();

	const cacheControl = (
		<RadioRoutingCacheControl
			label={label}
			hiddenTypes={hiddenTypes}
			value={routingCacheType}
			displayMode={displayMode}
			onChange={(routingCacheTypeId) => {
				setTemplate(undefined);

				onChange(routingCacheTypeId, '');
			}}
		/>
	);

	let cacheValueControl: JSX.Element | null = null;

	switch (routingCacheType) {
		case RoutingCacheTypes.TollFree: {
			cacheValueControl = (
				<TollFreeNumberFormControl
					required={required}
					alwaysShowMask
					onlyNumbers
					displayMode={displayMode}
					onChange={(tfn) => {
						onChange(RoutingCacheTypes.TollFree, tfn);
					}}
					value={routingCacheValue}
				/>
			);
			break;
		}
		case RoutingCacheTypes.Template: {
			cacheValueControl = (
				<TemplateSelect
					required={required}
					initialOptionId={routingCacheValue}
					respOrgId={respOrgId}
					displayMode={displayMode}
					onChange={(template) => {
						setTemplate(template);

						onChange(RoutingCacheTypes.Template, template.templateName);
					}}
					value={template}
				/>
			);
		}
	}

	if (columnMode) {
		return (
			<Row>
				<Col lg={6}>{cacheControl}</Col>
				<Col lg={6}>{cacheValueControl}</Col>
			</Row>
		);
	}

	return (
		<section>
			{cacheControl}
			{cacheValueControl}
		</section>
	);
};
