/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	CprNumberIndexResponse,
	EntityResLimitProfileRequest,
	EntityResLimitProfileResponse,
	TaskErrorIndexResponse,
	TaskIndexRequest,
	TaskIndexResponse,
	TaskNumberIndexRequest,
	TaskNumberIndexResponse,
	TaskProfileRequest,
	TaskProfileResponse,
	TaskTypeProfileRequest,
	TaskTypeProfileResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';

export const fetchTasks = async <IncludeFullResponse extends boolean = false>(
	queryParams?: TaskIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<TaskIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(Rt800ApiRoutes.Tasks.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetTasks = (queryParams?: TaskIndexRequest) => {
	return useQuery<FullResponse<TaskIndexResponse[]>, Error>(
		['useGetTasks', queryParams],
		() => {
			return fetchTasks(queryParams, true);
		},
		{ enabled: Boolean(queryParams) }
	);
};

const fetchTask = async (
	urlParams: TaskProfileRequest
): Promise<TaskProfileResponse> => {
	return handleGetRequest<TaskProfileResponse>(Rt800ApiRoutes.Tasks.Profile, {
		urlParams
	});
};

export const useGetTask = (urlParams: TaskProfileRequest) => {
	return useQuery<TaskProfileResponse, Error>(['useGetTask', urlParams], () => {
		return fetchTask(urlParams);
	});
};

const fetchTaskType = async (
	urlParams: TaskTypeProfileRequest
): Promise<TaskTypeProfileResponse> => {
	return handleGetRequest<TaskTypeProfileResponse>(
		Rt800ApiRoutes.TaskTypes.Profile,
		{
			urlParams
		}
	);
};

export const useGetTaskType = (
	urlParams: TaskTypeProfileRequest,
	enabled: boolean = true
) => {
	return useQuery<TaskTypeProfileResponse, Error>(
		['useGetTaskType', urlParams],
		() => {
			return fetchTaskType(urlParams);
		},
		{ enabled }
	);
};

const fetchTaskNumbers = async (
	urlParams: TaskProfileRequest,
	queryParams?: TaskNumberIndexRequest
): Promise<FullResponse<TaskNumberIndexResponse[]>> => {
	return handleGetRequest<TaskNumberIndexResponse[], true>(
		Rt800ApiRoutes.TaskNumbers.Index,
		{
			urlParams,
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetTaskNumbers = (
	urlParams: TaskProfileRequest,
	queryParams?: TaskNumberIndexRequest
) => {
	return useQuery<FullResponse<TaskNumberIndexResponse[]>, Error>(
		['useGetTaskNumbers', urlParams, queryParams],
		() => {
			return fetchTaskNumbers(urlParams, queryParams);
		}
	);
};

const fetchTaskNumberFutureCpr = async (
	urlParams: TaskProfileRequest
): Promise<CprNumberIndexResponse[]> => {
	return handleGetRequest<CprNumberIndexResponse[]>(
		Rt800ApiRoutes.TaskNumbersFutureCprs.Index,
		{
			urlParams
		}
	);
};

export const useGetTaskNumberFutureCprs = (urlParams: TaskProfileRequest) => {
	return useQuery<CprNumberIndexResponse[], Error>(
		['useGetTaskNumberFutureCprs', urlParams],
		() => {
			return fetchTaskNumberFutureCpr(urlParams);
		}
	);
};

const fetchTaskErrors = async (
	urlParams: TaskProfileRequest
): Promise<FullResponse<TaskErrorIndexResponse[]>> => {
	return handleGetRequest<TaskErrorIndexResponse[], true>(
		Rt800ApiRoutes.TaskErrors.Index,
		{
			urlParams,
			includeFullResponse: true
		}
	);
};

export const useGetTaskErrors = (urlParams: TaskProfileRequest) => {
	return useQuery<FullResponse<TaskErrorIndexResponse[]>, Error>(
		['useGetTaskErrors', urlParams],
		() => {
			return fetchTaskErrors(urlParams);
		}
	);
};

const fetchEntityReservationLimit = async (
	urlParams: EntityResLimitProfileRequest
): Promise<EntityResLimitProfileResponse> => {
	return handleGetRequest(Rt800ApiRoutes.EntityReservationLimit.Profile, {
		urlParams
	});
};

export const useGetEntityReservationLimit = (
	urlParams: EntityResLimitProfileRequest
) => {
	return useQuery<EntityResLimitProfileResponse, Error>(
		['useGetEntityReservationLimit', urlParams],
		() => {
			return fetchEntityReservationLimit(urlParams);
		},
		{ enabled: Boolean(urlParams) }
	);
};
