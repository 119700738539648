import { FlowIndexResponse } from 'RtModels';
import {
	FlowProfileContainerTabs,
	FlowRouter
} from 'RtUi/app/rtAdmin/Flows/Flow.router';
import { FlowEditor } from 'RtUi/app/rtAdmin/Flows/lib/forms/FlowEditor';
import { FlowResource } from 'RtUi/app/rtAdmin/Flows/lib/resources/FlowResource';
import { FlowStepList } from 'RtUi/app/rtAdmin/Flows/lib/forms/FlowStepList';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IFlowProfileContainerState {
	activeTab: string;
	flow?: FlowIndexResponse;
}

@FlowRouter.getProfileRtUiController()
export class FlowProfileContainer extends ProfileApplicationContainer<
	{},
	IFlowProfileContainerState
> {
	public Tabs = FlowProfileContainerTabs;
	public state: IFlowProfileContainerState = {
		activeTab: this.Tabs.Profile.header
	};

	public async componentDidMount() {
		const flowId = this.getIdParam();
		const flowResource = new FlowResource();

		const flow = await flowResource.get(flowId);

		this.setState({ flow });
	}

	public render() {
		const { flow } = this.state;

		if (!flow) {
			return <Loading />;
		}

		return (
			<TabbedLayout<FlowIndexResponse>
				router={FlowRouter}
				profile={flow}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<FlowEditor
						editMode={flow}
						onCreateOrUpdate={(flowProfile) =>
							this.setState({ flow: flowProfile })
						}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Steps}>
					<FlowStepList flowId={flow.flowId} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
