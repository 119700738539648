import { useAppState } from 'RtUi/app/@RtUi/lib/hooks/useAppState';

export const useUserDefaults = () => {
	const appState = useAppState();
	const userState = appState.get('user');

	return {
		defaultEntityId: userState.defaultEntityId,
		defaultRespOrgId: userState.defaultRespOrgId
	};
};
