import {
	JobProfileResponse,
	JobStepExtended,
	JobStepResourceExtended
} from 'RtModels';
import { JobStatusHelper } from 'RtUi/app/rtCarrierConnect/Jobs/bin/JobStatusHelper';
import { JobTaskGrid } from 'RtUi/app/rtCarrierConnect/Jobs/lib/grids/JobTaskGrid';
import { JobResource } from 'RtUi/app/rtCarrierConnect/Jobs/lib/resources/JobResource';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { Loading } from 'RtUi/components/ui/Loading';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import * as moment from 'moment-timezone';
import {
	Badge,
	Card,
	Col,
	Collapse,
	ListGroup,
	OverlayTrigger,
	Row,
	Tooltip
} from 'react-bootstrap';
import { JobRouter } from './Job.router';

interface IJobProfileContainerProps {}

interface IJobProfileContainerState {
	job?: JobProfileResponse;
	openedJobStepResourceId?: string;
}

@JobRouter.getProfileRtUiController()
export class JobProfileContainer extends ProfileApplicationContainer<
	IJobProfileContainerProps,
	IJobProfileContainerState
> {
	public Tabs = JobRouter.getProfileTabs();

	public state: IJobProfileContainerState = {
		//activeTab: this.Tabs.Profile.header,
	};

	protected jobStatusHelper = new JobStatusHelper();

	public async componentDidMount() {
		this.init();
	}

	public async init() {
		const jobId = this.getIdParam();
		const jobResource = new JobResource();
		const job = await jobResource.get(jobId);

		this.setState({ job });
	}

	/**
	 * Toggles which jobStepResource is shown
	 * @param openedJobStepResourceId
	 */
	public onToggle(openedJobStepResourceId: string) {
		if (this.state.openedJobStepResourceId === openedJobStepResourceId) {
			this.setState({ openedJobStepResourceId: undefined });
		} else {
			this.setState({ openedJobStepResourceId });
		}
	}

	/**
	 * Renders a JobStepResource status in a Badge
	 * @param jobStepResource
	 */
	public renderJobStepResourceStatusBadges(
		jobStepResource: JobStepResourceExtended
	) {
		const { totalQty, pendingQty, warningQty, errorQty } = jobStepResource;
		const completedQty = totalQty - pendingQty - warningQty - errorQty;

		return (
			<section className="rounded" style={{ overflow: 'hidden' }}>
				<Badge
					style={{ minWidth: 60 }}
					text="dark"
					bg="light"
					className="rounded-0"
				>
					Pending: {pendingQty}
				</Badge>
				<OverlayTrigger
					overlay={(props) => (
						<Tooltip id={`completed-tooltip`} {...props}>
							Success
						</Tooltip>
					)}
				>
					{({ ref, ...triggerHandler }) => (
						<Badge
							ref={ref}
							{...triggerHandler}
							style={{ minWidth: 60 }}
							text={completedQty > 0 ? 'white' : 'dark'}
							bg={completedQty > 0 ? 'success' : 'light'}
							className="rounded-0"
						>
							{completedQty}
						</Badge>
					)}
				</OverlayTrigger>
				<OverlayTrigger
					overlay={(props) => (
						<Tooltip id={`completed-tooltip`} {...props}>
							Warning
						</Tooltip>
					)}
				>
					{({ ref, ...triggerHandler }) => (
						<Badge
							ref={ref}
							{...triggerHandler}
							style={{ minWidth: 60 }}
							text={warningQty > 0 ? 'white' : 'dark'}
							bg={warningQty > 0 ? 'warning' : 'light'}
							className="rounded-0"
						>
							{warningQty}
						</Badge>
					)}
				</OverlayTrigger>
				<OverlayTrigger
					overlay={(props) => (
						<Tooltip id={`completed-tooltip`} {...props}>
							Error
						</Tooltip>
					)}
				>
					{({ ref, ...triggerHandler }) => (
						<Badge
							ref={ref}
							{...triggerHandler}
							style={{ minWidth: 60 }}
							text={errorQty > 0 ? 'white' : 'dark'}
							bg={errorQty > 0 ? 'danger' : 'light'}
							className="rounded-0"
						>
							{errorQty}
						</Badge>
					)}
				</OverlayTrigger>
			</section>
		);
	}

	/**
	 * Renders a JobStepResource,
	 * if it is within the ONLY step and is the ONLY resource, then it will just show the JobTaskGrid component
	 * @param jobStep
	 * @param jobStepResource
	 * @param stepsLength
	 * @param stepResourcesLength
	 */
	public renderStepResource(
		jobStepResource: JobStepResourceExtended,
		jobStep: JobStepExtended,
		jobStepIndex: number,
		stepsLength: number,
		stepResourcesLength: number
	) {
		const isStepResourceOpen =
			jobStepResource.jobStepResourceId === this.state.openedJobStepResourceId;
		const isOnlyStepAndResource =
			stepsLength === 1 && stepResourcesLength === 1;
		const jobTaskGridComponent = (
			<JobTaskGrid
				stepNumber={jobStepIndex + 1}
				onUpdate={() => this.init()}
				disableCollapse
				removeCardHeaderXPadding
				jobStepId={jobStep.jobStepId}
				isManageable={jobStepResource.isManageable}
				resourceId={jobStepResource.resourceId}
			/>
		);

		if (isOnlyStepAndResource) {
			return jobTaskGridComponent;
		}

		let fontIcon = 'fa-angle-down';

		if (
			this.state.openedJobStepResourceId === jobStepResource.jobStepResourceId
		) {
			fontIcon = 'fa-angle-up';
		}

		return (
			<>
				<header
					className="d-flex justify-content-between align-items-center mb-1"
					onClick={() => this.onToggle(jobStepResource.jobStepResourceId)}
					style={{ cursor: 'pointer' }}
				>
					<section className="d-flex justify-content-start align-items-end">
						<i className={`fas fa-fw ${fontIcon} me-1`} />
						<a className="h6 mb-0">
							<b>
								{jobStepResource.configurationLabel}
								&nbsp;
							</b>
						</a>
					</section>
					<section>
						{this.renderJobStepResourceStatusBadges(jobStepResource)}
					</section>
				</header>
				<Collapse in={isStepResourceOpen}>
					<div>
						{isStepResourceOpen && jobTaskGridComponent}
						{!isStepResourceOpen && (
							<section
								style={
									{ height: 475 } //placeholder for collapsing animation
								}
							></section>
						)}
					</div>
				</Collapse>
			</>
		);
	}

	/**
	 * Renders a jobStep. If stepsLength === 1 (is only step),
	 * then the step name will not be rendered.
	 *
	 * @param jobStep
	 * @param jobStepIndex
	 * @param stepsLength
	 */
	public renderStep(
		jobStep: JobStepExtended,
		jobStepIndex: number,
		stepsLength: number
	) {
		const jobStepResources = jobStep.stepResources;

		return (
			<Card className="mb-4" key={jobStep.jobStepId}>
				<ListGroup key={jobStep.jobStepId} variant="flush">
					<CollapsibleCard
						header={`Step ${jobStepIndex + 1}. ${jobStep.label}`}
						closedByDefault={jobStepIndex === 0}
					>
						{jobStepResources.map((jobStepResource) => (
							<ListGroup.Item key={jobStepResource.jobStepResourceId}>
								{this.renderStepResource(
									jobStepResource,
									jobStep,
									jobStepIndex,
									stepsLength,
									jobStepResources.length
								)}
							</ListGroup.Item>
						))}
					</CollapsibleCard>
				</ListGroup>
			</Card>
		);
	}

	/**
	 * Render steps in job
	 * @param job
	 */
	public renderSteps(job: JobProfileResponse) {
		const jobSteps = job.steps;
		//const jobSteps = [job.steps[0]]; //test single step

		return jobSteps.map((jobStep, jobStepIndex) =>
			this.renderStep(jobStep, jobStepIndex, jobSteps.length)
		);
	}

	public render() {
		const { job } = this.state;

		if (!job) {
			return <Loading />;
		}

		return (
			<StandardLayout<JobProfileResponse> profile={job} router={JobRouter}>
				<CollapsibleCard header="Job Status">
					<Row>
						<Col className="d-flex gap-2 w-100">
							<InputFormControl
								label="Status"
								controlGroupClassName="flex-grow-1"
								displayMode
								value={this.jobStatusHelper.getReadableJobStatus(
									job.jobStatusId
								)}
							/>
							<DatetimeFormControl
								label="Created"
								displayMode
								className="flex-grow-1"
								value={job.createdTs ? moment(job.createdTs) : undefined}
							/>
							<DatetimeFormControl
								label="Updated"
								displayMode
								className="flex-grow-1"
								value={job.updatedTs ? moment(job.updatedTs) : undefined}
							/>
							<DatetimeFormControl
								label="Completed"
								displayMode
								className="flex-grow-1"
								value={job.completedTs ? moment(job.completedTs) : undefined}
							/>
						</Col>
					</Row>
				</CollapsibleCard>
				<Row>
					<Col>{this.renderSteps(job)}</Col>
				</Row>
			</StandardLayout>
		);
	}
}
