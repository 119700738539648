/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { ServerProfileResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchServers = async (): Promise<
	FullResponse<ServerProfileResponse[]>
> => {
	return handleGetRequest(RtAdmApiRoutes.Servers.Index, {
		includeFullResponse: true
	});
};

export const useGetServers = (enabled: boolean = true) => {
	return useQuery<FullResponse<ServerProfileResponse[]>, Error>(
		'getServers',
		() => fetchServers(),
		{ enabled }
	);
};
