import { RejectCodes } from 'RtModels';
import { rejectCodeToLabel } from 'RtUi/app/rtVue/RerateControl/lib/utils/Constants';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface RejectCodeDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const RejectCodeDataGridColumn = <T = any,>({
	...config
}: RejectCodeDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: RejectCodes) => {
		return rejectCodeToLabel(value);
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: any) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<RejectCodes>())
	});
};
