import { Rt800ApiRoutes } from 'RtExports/routes';
import { CprNumberIndexRequest, CprNumberIndexResponse } from 'RtModels';
import {
	FullResponse,
	handlePostRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchCprNumber = async <
	IncludeFullResponse extends boolean = false
>(
	bodyParams: CprNumberIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<CprNumberIndexResponse[], IncludeFullResponse>> => {
	return handlePostRequest(Rt800ApiRoutes.CprNumbers.Index, bodyParams, {
		includeFullResponse
	});
};

export const useGetCprNumber = (bodyParams: CprNumberIndexRequest) => {
	return useQuery<FullResponse<CprNumberIndexResponse[]>, Error>(
		[`useGetCprNumber`],
		() => fetchCprNumber(bodyParams, true),
		{ enabled: true }
	);
};
