/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LergIndexExtendedResponse = {
    properties: {
        npa: {
            type: 'string',
            isRequired: true,
        },
        nxx: {
            type: 'string',
            isRequired: true,
        },
        tbp: {
            type: 'string',
            isRequired: true,
        },
        lata: {
            type: 'string',
            isRequired: true,
        },
        ocn: {
            type: 'string',
            isRequired: true,
        },
        ocnCompany: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        lineType: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        wireCenter: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        rateCenter: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        latitude: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        longitude: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        zipCodes: {
            type: 'Array',
            isRequired: true,
            isNullable: true,
        },
    },
};