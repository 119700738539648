import { Row, Col } from 'react-bootstrap';
import { ScenarioSelect } from 'RtUi/app/rtSip/Scenarios/lib/controls/ScenarioSelect';
import {
	ScenarioActionForm,
	IScenarioActionFormState
} from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionForm';
import { ScenarioIndexResponse, ScenarioActionGotoScenario } from 'RtModels';

export class ScenarioActionGotoScenarioForm extends ScenarioActionForm<ScenarioActionGotoScenario> {
	public state: IScenarioActionFormState = {};

	public updateScenario(scenario: ScenarioIndexResponse) {
		const { action } = this.props;

		action.scenarioId = scenario.scenarioId;

		this.setState({ scenario });

		this.props.onChange(action);
	}

	public render() {
		const { action } = this.props;
		const scenarioId = action.scenarioId
			? String(action.scenarioId)
			: undefined;

		return (
			<Row>
				<Col md={6}>
					<ScenarioSelect
						label="Scenario"
						required
						resource={this.scenarioResource}
						displayMode={this.props.displayMode}
						appendDropdownToBody
						initialOptionId={scenarioId}
						onChange={(scenario) => this.updateScenario(scenario)}
						value={this.state.scenario}
					/>
				</Col>
			</Row>
		);
	}
}
