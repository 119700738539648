/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $AsrSummaryIndexResponse = {
    properties: {
        timeslot: {
            type: 'string',
            isRequired: true,
        },
        igcs: {
            type: 'string',
            isRequired: true,
        },
        igrcs: {
            type: 'string',
            isRequired: true,
        },
        egcs: {
            type: 'string',
            isRequired: true,
        },
        egrcs: {
            type: 'string',
            isRequired: true,
        },
        ercs: {
            type: 'string',
            isRequired: true,
        },
        erss: {
            type: 'string',
            isRequired: true,
        },
        eccs: {
            type: 'string',
            isRequired: true,
        },
        ecss: {
            type: 'string',
            isRequired: true,
        },
        errk: {
            type: 'string',
            isRequired: true,
        },
        errr: {
            type: 'number',
            isRequired: true,
        },
        ecrk: {
            type: 'string',
            isRequired: true,
        },
        ecrr: {
            type: 'string',
            isRequired: true,
        },
        inps: {
            type: 'string',
            isRequired: true,
        },
        irel: {
            type: 'string',
            isRequired: true,
        },
        igw: {
            type: 'string',
            isRequired: true,
        },
        egps: {
            type: 'string',
            isRequired: true,
        },
        erel: {
            type: 'string',
            isRequired: true,
        },
        egw: {
            type: 'string',
            isRequired: true,
        },
        erAtt: {
            type: 'number',
            isRequired: true,
        },
        erInv: {
            type: 'number',
            isRequired: true,
        },
        erUnq: {
            type: 'number',
            isRequired: true,
        },
        erLop: {
            type: 'number',
            isRequired: true,
        },
        erLmt: {
            type: 'number',
            isRequired: true,
        },
        erBsy: {
            type: 'number',
            isRequired: true,
        },
        erCng: {
            type: 'number',
            isRequired: true,
        },
        egCon: {
            type: 'number',
            isRequired: true,
        },
        rawMin: {
            type: 'number',
            isRequired: true,
        },
        billMin: {
            type: 'number',
            isRequired: true,
        },
        erPDD: {
            type: 'number',
            isRequired: true,
        },
        erAsr: {
            type: 'number',
            isRequired: true,
        },
        erAcd: {
            type: 'number',
            isRequired: true,
        },
        ecAtt: {
            type: 'number',
            isRequired: true,
        },
        ecBsy: {
            type: 'number',
            isRequired: true,
        },
        ecCng: {
            type: 'number',
            isRequired: true,
        },
        ecCon: {
            type: 'number',
            isRequired: true,
        },
        ecMin: {
            type: 'number',
            isRequired: true,
        },
        ecPDD: {
            type: 'number',
            isRequired: true,
        },
        ecAsr: {
            type: 'number',
            isRequired: true,
        },
        ecAcd: {
            type: 'number',
            isRequired: true,
        },
        erChg: {
            type: 'number',
            isRequired: true,
        },
        ecChg: {
            type: 'number',
            isRequired: true,
        },
        mrgn: {
            type: 'number',
            isRequired: true,
        },
        mpct: {
            type: 'number',
            isRequired: true,
        },
    },
};