import { RtxFlowApiRoutes } from 'RtExports/routes';
import {
	FlowStepIndexResponse,
	FlowStepResourceIndexResponse,
	FlowStepResourceUpdateRequest,
	FlowStepUpdateRequest,
	IntegrationTaskTypeIndexResponse,
	FlowProfileRequest
} from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export interface IFlowStepResourceUiUpdateRequest
	extends FlowStepResourceUpdateRequest {
	flowStepResourceId: string;
}
export type FlowStepUiResourceTypes =
	| FlowStepResourceIndexResponse
	| IFlowStepResourceUiUpdateRequest;

export interface IFlowStepUiIndex
	extends Omit<FlowStepIndexResponse, 'resources' | 'flowStepId'> {
	flowStepId: number | string;
	resources: FlowStepUiResourceTypes[];
	integrationTaskType?: IntegrationTaskTypeIndexResponse;
}

export class FlowStepResource extends ArrayResource<IFlowStepUiIndex> {
	constructor(private flowId: number) {
		super('flowStepId');

		const urlParams: FlowProfileRequest = { flowId: this.flowId };

		this.setApiRouteForGetAll(RtxFlowApiRoutes.FlowSteps.Index, {
			urlParams
		});
	}

	public updateSteps(steps: IFlowStepUiIndex[]) {
		const req: FlowStepUpdateRequest = { steps };
		const body = JSON.stringify(req);
		const urlParams: FlowProfileRequest = { flowId: this.flowId };

		return this.fetchWithRoute<IFlowStepUiIndex[]>(
			RtxFlowApiRoutes.FlowSteps.Update,
			{ body, urlParams }
		);
	}
}
