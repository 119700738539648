import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	LcrTaskResultIndexRequest,
	LcrTaskResultIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchLcrTasksResults = async (
	urlParams: LcrTaskResultIndexRequest
): Promise<FullResponse<LcrTaskResultIndexResponse[]>> => {
	return handleGetRequest<LcrTaskResultIndexResponse[], true>(
		Rt800ApiRoutes.LcrTaskResults.Index,
		{
			includeFullResponse: true,
			urlParams
		}
	);
};

export const useGetLcrTasksResults = (urlParams: LcrTaskResultIndexRequest) => {
	return useQuery<FullResponse<LcrTaskResultIndexResponse[]>, Error>(
		[`useGetLcrTasksResults`],
		() => fetchLcrTasksResults(urlParams)
	);
};
