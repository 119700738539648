import {
	OriginationNumberIndexResponse,
	ServiceNumberTagIndexResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';
import { ScenarioDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ScenarioDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getServiceNumbersColumns = (
	removeServiceNumber: (row: OriginationNumberIndexResponse) => void
): Array<DataGridColumn<OriginationNumberIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => removeServiceNumber(row)
	}),
	PhoneNumberDataGridColumn({
		accessorKey: 'serviceNumber',
		header: 'Service Number'
	}),
	PhoneNumberDataGridColumn({
		accessorKey: 'translatedNumber',
		header: 'Translated Number'
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	ScenarioDataGridColumn({
		accessorKey: 'scenarioId',
		header: 'Scenario'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	})
];

export const getServiceNumberTagsColumns = (
	removeServiceNumberTag: (row: ServiceNumberTagIndexResponse) => void
): Array<DataGridColumn<ServiceNumberTagIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: removeServiceNumberTag
	}),
	DefaultDataGridColumn({
		accessorKey: 'tag',
		header: 'Tag'
	}),
	DefaultDataGridColumn({
		accessorKey: 'minValue',
		header: 'Min Value'
	}),
	DefaultDataGridColumn({
		accessorKey: 'maxValue',
		header: 'Max Value'
	}),
	IntegerDataGridColumn({
		accessorKey: 'valueQty',
		header: 'Value Qty'
	})
];
