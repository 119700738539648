import { ResourceType } from 'RtModels';
import { GetResourceTypeName } from 'RtUi/app/rtDid/Dashboard/lib/components/types';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { Alert } from 'react-bootstrap';

interface ResourceConnectionTypeDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const ResourceConnectionTypeDataGridColumn = <T = any,>({
	...config
}: ResourceConnectionTypeDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (resourceType: ResourceType) => {
		const resourceTypeName = GetResourceTypeName(resourceType);
		let color = '';

		switch (resourceType) {
			case ResourceType.Billing:
				color = 'primary';
				break;
			case ResourceType.Switch:
				color = 'info';
				break;
			case ResourceType.DidVendor:
				color = 'dark';
				break;
		}

		return (
			<section className="d-flex justify-content-center">
				<Alert transition={false} variant={color} className="alert-sm mb-0 p-0">
					<span>{resourceTypeName}</span>
				</Alert>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value) => GetResourceTypeName(value),
		getValue: ({ cell }) => getValue(cell.getValue<ResourceType>())
	});
};
