/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CprNumberIndexRequest = {
    properties: {
        tfns: {
            type: 'Array',
        },
        entityId: {
            type: 'string',
        },
        respOrgId: {
            type: 'string',
        },
        templateName: {
            type: 'string',
        },
        cprStatusIds: {
            type: 'Array',
        },
        numberGroupId: {
            type: 'number',
        },
        isAssignedToTemplate: {
            type: 'number',
        },
        search: {
            type: 'string',
        },
        effectiveAfterTs: {
            type: 'Date',
            format: 'date-time',
        },
        effectiveBeforeTs: {
            type: 'Date',
            format: 'date-time',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};