import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { CallflowService, TagIndexResponse } from 'RtModels';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { TagResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/TagResource';

interface ITagTypeSelectProps
	extends ISelectFormControlProps<TagIndexResponse> {
	valueKey?: keyof TagIndexResponse;
	serviceId?: number;
}

export class TagTypeSelect extends SelectFormControl<
	TagIndexResponse,
	false,
	ITagTypeSelectProps
> {
	public resourceClass = TagResource;
	public state: ISelectFormControlState<TagIndexResponse> = {
		formLabel: 'Tag',
		valueKey: 'tag',
		labelKey: 'tag'
	};

	public filterOption(
		option: FilterOptionOption<TagIndexResponse>,
		rawInput: string
	) {
		if (
			this.props.serviceId === CallflowService.InboundCustomerIdentification
		) {
			return option.value === 'CUSTOM';
		}

		return super.filterOption(option, rawInput);
	}
}
