import { ScenarioResource } from 'RtUi/app/rtSip/Scenarios/lib/resources/ScenarioResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ScenarioIndexResponse } from 'RtModels';
import { ScenarioRouter } from 'RtUi/app/rtSip/Scenarios/Scenario.router';

interface IScenarioSelectProps
	extends ISelectFormControlProps<ScenarioIndexResponse> {
	allowAll?: boolean;
	resource?: ScenarioResource;
}

export class ScenarioSelect extends SelectFormControl<
	ScenarioIndexResponse,
	false,
	IScenarioSelectProps
> {
	public static defaultProps = {
		router: ScenarioRouter
	};
	public resourceClass = ScenarioResource;
	public state: ISelectFormControlState<ScenarioIndexResponse> = {
		formLabel: 'Scenario',
		valueKey: 'scenarioId',
		labelKey: 'label'
	};

	constructor(props: IScenarioSelectProps) {
		super(props);

		if (this.props.resource) {
			this.resource = this.props.resource;
		}
	}

	public optionRenderer = (scenario: ScenarioIndexResponse) => (
		<span>
			{scenario.scenarioId} ({scenario.label})
		</span>
	);

	public componentDidMount() {
		const { allowAll = false } = this.props;
		const getAllParams: any = { isActive: 1 };

		if (!allowAll) {
			getAllParams.isManaged = 1;
		}

		this.setGetAllParams(getAllParams);

		super.componentDidMount();
	}
}
