import { CellProps, DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	PercentDataGridColumn,
	PercentDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/PercentDataGridColumn';
import { ReactNode } from 'react';

type SQDataGridColumnConfiguration<T> = Omit<
	PercentDataGridColumnConfiguration<T>,
	'accessorKey'
> & {
	accessorKey: keyof T;
};

export const SQDataGridColumn = <T = any,>({
	accessorKey,
	size = 80,
	...config
}: SQDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const accessorFn = (data: Record<keyof T, T[keyof T]>) => {
		return Number(data[accessorKey]) * 100;
	};

	const customWrapper = (cell: CellProps<T>, value: ReactNode) => {
		const parsedNumber = accessorFn(cell.row.original);
		let theme: string = 'dark';

		if (parsedNumber > 0 && parsedNumber < 100) {
			theme = 'info';
		}

		if (parsedNumber === 100) {
			theme = 'success';
		}

		return (
			<section className="d-flex">
				<div
					role="alert"
					style={{ width: '60px', textAlign: 'center' }}
					className={`alert-sm mb-0 p-0 alert alert-${theme}`}
				>
					<span>{value}</span>
				</div>
			</section>
		);
	};

	return PercentDataGridColumn({
		size,
		...config,
		customWrapper,
		decimals: 0,
		enableColumnActions: false,
		accessorFn
	});
};
