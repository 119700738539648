/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NpaIndexResponse = {
    properties: {
        npa: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        iso2: {
            type: 'string',
            isRequired: true,
        },
        iso3: {
            type: 'string',
            isRequired: true,
        },
    },
};