/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ResourceType {
    Switch = 1,
    Billing = 2,
    DidVendor = 3,
    CarrierConnect = 4,
    NumberSync = 5,
}