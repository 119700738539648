/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $VitcomResourceCreateRequest = {
    properties: {
        integrationId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isDefault: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        subscriptionId: {
            properties: {
            },
        },
        protocol: {
            properties: {
            },
        },
        host: {
            properties: {
            },
        },
        port: {
            properties: {
            },
        },
        path: {
            properties: {
            },
        },
        resRef1: {
            type: 'string',
            isRequired: true,
        },
    },
};