import { PublicHttp } from 'RtUi/app/public/lib/http/PublicHttp';
import { BrandingIndexResponse } from 'RtModels';
import { FC, useState } from 'react';
import { useMount } from 'react-use';

interface ILogoProps {
	height?: number;
	className?: string;
	style?: React.CSSProperties;
}

const publicHttp = new PublicHttp();

export const Logo: FC<React.PropsWithChildren<ILogoProps>> = ({
	height,
	className,
	style
}) => {
	const [branding, setBranding] = useState<BrandingIndexResponse>();

	useMount(async () => {
		try {
			const brand = await publicHttp.getBranding();
			setBranding(brand);
		} catch (err) {
			console.error('Unable to load site logo', err);
		}
	});

	if (!branding) {
		return <img height={height} className={className} style={style} />;
	}

	return (
		<img
			alt={`${branding.loginTitle} Logo`}
			src={branding?.logo}
			height={height}
			className={className}
			style={style}
		/>
	);
};
