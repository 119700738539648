import { RtCommonApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class ConvertRateSheetRouterClass extends RtVueReportRouter<void, void, {}> {
	constructor() {
		super('Convert Rate Sheet', 'crs', undefined, {});

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.FileProcess);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(): string {
		return '';
	}
}

export const ConvertRateSheetRouter = new ConvertRateSheetRouterClass();
