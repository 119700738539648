import { CpsIndexResponseData } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';

export const getCpsColumns = (
	onDrillDown: (row: CpsIndexResponseData) => void,
	callDateFormat?: string
): Array<DataGridColumn<CpsIndexResponseData>> => [
	FileStreamDataGridColumn({
		accessorKey: 'fileStreamId',
		header: 'File Stream',
		hiddenIfEmpty: true
	}),
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		format: callDateFormat
	}),
	DefaultDataGridColumn({
		accessorKey: 'externalAccountId',
		header: 'External Account Id',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'externalSubAccountId',
		header: 'External Sub Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'connectionId',
		header: 'Connection Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'trunkGroupId',
		header: 'Trunk Group Id',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			onDrillDown(row);
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'trunkGroupLabel',
		header: 'Trunk Group Label',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'direction',
		header: 'Direction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dialCode',
		header: 'Dial Code',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'rateCenterId',
		header: 'Rate Center',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'mincps',
		header: 'Min Cps',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'maxcps',
		header: 'Max Cps',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'avgcps',
		header: 'Avg Cps',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'totalcps',
		header: 'Total Cps',
		hiddenIfEmpty: true
	})
];
