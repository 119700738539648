import pluralize from 'pluralize';
import { Alert, Badge, Button } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { Item } from 'react-contexify';
import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	CprStatus,
	TemplateCprIndexResponse,
	TemplateIndexResponse
} from 'RtModels';
import { TemplateCprsRouter } from 'RtUi/app/rt800/Cprs/Cprs.router';
import { ConfigAdminData } from 'RtUi/app/rt800/Cprs/lib/components/ConfigAdminData';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { Aside } from 'RtUi/components/ui/Aside';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
import { TemplateContainerTabs, TemplateRouter } from '../../Template.router';
import { TemplateResource } from '../resources/TemplateResource';

interface ITemplateQuickViewAsideProps {
	isOpen: boolean;
	toggle: (isOpen: boolean) => void;
	template: TemplateIndexResponse;
}

interface ITemplateQuickViewAsideState {
	selectedRoutes?: TemplateCprIndexResponse[];
	activeCpr?: Cpr | null;
}

export class TemplateQuickViewAside extends ApplicationContainer<
	ITemplateQuickViewAsideProps,
	ITemplateQuickViewAsideState
> {
	public state: ITemplateQuickViewAsideState = {
		selectedRoutes: undefined,
		activeCpr: undefined
	};

	public templateResource = new TemplateResource();
	public isComponentMounted = false;

	public componentDidMount() {
		this.isComponentMounted = true;

		this.selectTemplate(this.props.template);
	}

	public componentWillUnmount() {
		this.isComponentMounted = false;
	}

	public isSelectedTemplate(template: TemplateIndexResponse) {
		if (!this.isComponentMounted) {
			return false;
		}

		const { template: currentTemplate } = this.props;

		return (
			currentTemplate && currentTemplate.templateName === template.templateName
		);
	}

	public async selectTemplate(selectedTemplate: TemplateIndexResponse) {
		this.setState({ selectedRoutes: undefined, activeCpr: undefined });
		const { template } = this.props;

		const { templateName } = template;
		const selectedRoutes = await this.templateResource.routing(templateName);

		if (!this.isSelectedTemplate(template)) {
			return;
		}

		this.setState({ selectedRoutes });

		let activeCpr: Cpr | null = null;

		if (selectedTemplate.isActive) {
			const selectedActiveRoute = selectedRoutes.find(
				(route) => route.cprStatusId === CprStatus.Active
			);

			if (selectedActiveRoute) {
				const effectiveTs =
					typeof selectedActiveRoute.effectiveTs === 'string'
						? selectedActiveRoute.effectiveTs
						: selectedActiveRoute.effectiveTs.toISOString();
				activeCpr = await this.templateResource.cpr(templateName, effectiveTs);
			}
		}

		if (!this.isSelectedTemplate(selectedTemplate)) {
			return;
		}

		this.setState({ activeCpr });
	}

	public getCprRef(activeCpr: Cpr) {
		const cprPath = TemplateCprsRouter.getProfileRoute(activeCpr);
		const cprPathRef = this.createRef(cprPath);

		return cprPathRef;
	}

	public goToTemplate(template: TemplateIndexResponse, goToRouteTabs = false) {
		const tabId: string | undefined = goToRouteTabs
			? TemplateContainerTabs.Routing.header
			: undefined;
		const templatePath = TemplateRouter.getProfileRoute(template, tabId);

		this.goToPath(templatePath);
	}

	public renderActiveCpr() {
		const { activeCpr } = this.state;
		const { template } = this.props;
		const isLoading = typeof activeCpr === 'undefined' || !template;

		if (activeCpr === null) {
			return (
				<Alert variant="warning" className="d-flex justify-content-start">
					<i className="fas fa-fw fa-sticky-note me-2" />
					This Template does not have an Active Routing Profile.
				</Alert>
			);
		}

		return (
			<section>
				<header className="mb-3">
					<Button
						variant="link"
						className="ps-0"
						disabled={!activeCpr}
						onClick={() => {
							if (!activeCpr) {
								return;
							}

							this.goToPath(this.getCprRef(activeCpr));
						}}
					>
						<h5 className="mb-0">
							<span>Active Routing Profile</span>
							{isLoading && (
								<>
									&nbsp;
									<i className="fas fa-fw fa-cog fa-spin" />
								</>
							)}
						</h5>
					</Button>
					<h5 className="mb-0"></h5>
				</header>
				{activeCpr && <ConfigAdminData quickView mgiProfile={activeCpr} />}
			</section>
		);
	}

	public renderRouteStatsSkeleton() {
		return (
			<ContentLoader preserveAspectRatio="none" height={80} width={459}>
				<rect x={0} y={0} rx={5} ry={5} width={175} height={32} />
				<rect x={183} y={0} rx={5} ry={5} width={175} height={32} />
				<rect x={0} y={40} rx={5} ry={5} width={175} height={32} />
				<rect x={183} y={40} rx={5} ry={5} width={175} height={32} />
			</ContentLoader>
		);
	}

	public renderRouteStats() {
		const { selectedRoutes } = this.state;
		const { template } = this.props;

		if (!selectedRoutes || !template) {
			return this.renderRouteStatsSkeleton();
		}

		const getRoutesWithStatusOf = (...status: CprStatus[]) => {
			return selectedRoutes.filter((route) =>
				status.includes(route.cprStatusId)
			);
		};

		const oldRoutes = getRoutesWithStatusOf(CprStatus.Old);
		const pendingRoutes = getRoutesWithStatusOf(
			CprStatus.Pending,
			CprStatus.Sending
		);
		const failedRoutes = getRoutesWithStatusOf(
			CprStatus.Failed,
			CprStatus.Invalid
		);
		const renderStat = (
			title: string,
			color: string,
			count: number,
			skipPluralize = false
		) => {
			if (!skipPluralize) {
				title = pluralize(title, count);
			}

			return (
				<Button
					size="sm"
					variant={color}
					className="me-2 mb-2"
					onClick={() => this.goToTemplate(template, true)}
					style={{ width: 175, height: 32 }}
				>
					<article className="d-flex justify-content-start">
						<Badge bg="white" text="dark">
							{count.toLocaleString()}
						</Badge>
						<span>&nbsp;&nbsp;{title}</span>
					</article>
				</Button>
			);
		};

		return (
			<section className="">
				{renderStat('Old Routes', 'warning', oldRoutes.length)}
				{renderStat('Failed Routes', 'danger', failedRoutes.length)}
				{renderStat('Pending Routes', 'success', pendingRoutes.length)}
				{renderStat('Total Routes', 'dark', selectedRoutes.length, true)}
			</section>
		);
	}

	public additionalContentMenuItems(template: TemplateIndexResponse) {
		const canViewCpr = this.Actions.User.has(
			...Rt800ApiRoutes.TemplateCprs.Index.permissions
		);

		if (!canViewCpr) {
			return undefined;
		}

		return (
			<Item onClick={() => this.selectTemplate(template)}>
				<span className="d-flex justify-content-start align-items-center">
					<i className="fas fa-fw fa-bolt" />
					<span>
						<span>Quick Template View</span>
					</span>
				</span>
			</Item>
		);
	}

	public render() {
		const { template } = this.props;

		return (
			<Aside isOpen={this.props.isOpen}>
				<Button
					type="button"
					variant="light"
					size="sm"
					style={{ position: 'absolute', right: 20 }}
					onClick={() => this.props.toggle(false)}
				>
					<i className="fas fa-fw fa-times" />
				</Button>
				{template && (
					<header className="mb-3 d-flex justify-content-start align-items-center">
						<a className="h5 mb-0" onClick={() => this.goToTemplate(template)}>
							{template.templateName}
						</a>
					</header>
				)}
				{this.renderRouteStats()}
				<hr />
				{this.renderActiveCpr()}
			</Aside>
		);
	}
}
