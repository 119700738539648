import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PartitionEntityProfileResponse } from 'RtModels';
import { cloneDeep } from 'lodash-es';
import { PartitionEntityResource } from 'RtUi/app/rtAdmin/Partitions/lib/resource/PartitionEntityResource';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { FC, useState } from 'react';

interface IPartitionEntityEditorProps {
	onSuccess: (updatedResource: PartitionEntityProfileResponse) => void;
	disablePadding?: boolean;
	partitionId: number;
	editMode?: PartitionEntityProfileResponse;
	onCancel?: () => void;
	createMode?: boolean;
	narrow?: boolean;
}

const resource = new PartitionEntityResource();

export const PartitionEntityEditor: FC<
	React.PropsWithChildren<IPartitionEntityEditorProps>
> = ({
	narrow,
	disablePadding,
	editMode,
	onSuccess,
	partitionId,
	onCancel = () => null,
	createMode = false
}) => {
	const [error, setError] = useState();
	const [displayMode, setDisplayMode] = useState<boolean>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [editingEntity, setEditingEntity] =
		useState<PartitionEntityProfileResponse>(
			editMode
				? cloneDeep(editMode)
				: {
						entityId: '',
						partitionId,
						partitionEntityId: 0
					}
		);
	const [entity, setEntity] = useState({
		entityId: '',
		label: '',
		summary: '',
		assigned: 0,
		disconnected: 0,
		pending: 0,
		reserved: 0,
		transitional: 0,
		unavailable: 0,
		working: 0,
		total: 0
	});

	const onChange = <K extends keyof PartitionEntityProfileResponse>(
		key: K,
		value: PartitionEntityProfileResponse[K]
	) => {
		if (!isSubmitting) {
			const updated = {
				[key]: value
			};
			setEditingEntity({
				...editingEntity,
				...updated
			});
		}
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setIsSubmitting(true);
		setError(undefined);

		try {
			let newEntity: PartitionEntityProfileResponse;

			if (createMode) {
				newEntity = await resource.createPartitionEntity(
					editingEntity.partitionId,
					{
						entityId: entity.entityId
					}
				);
			} else {
				newEntity = await resource.updatePartitionEntity(
					editingEntity.partitionId,
					editingEntity.partitionEntityId,
					{
						entityId: editingEntity.entityId
					}
				);
			}
			setIsSubmitting(false);
			onSuccess(newEntity);
		} catch (err: any) {
			setIsSubmitting(false);
			setError(err);
		}
	};

	const onCancelInternal = () => {
		setEditingEntity(cloneDeep(editMode!));
		onCancel();
	};

	return (
		<RtForm
			narrow={narrow}
			createMode={createMode}
			disablePadding={!createMode}
			onCancel={onCancelInternal}
			isSubmitting={isSubmitting}
			displayMode={displayMode}
			onSubmit={onSubmit}
			error={error}
			onChange={(displayMode) => {
				setDisplayMode(!displayMode);
			}}
		>
			{!createMode && (
				<InputFormControl
					label="ID"
					readOnly={true}
					displayMode={true}
					value={String(editingEntity.partitionEntityId)}
				/>
			)}
			<InputFormControl
				type="number"
				label="Partition Id"
				displayMode={!createMode}
				value={String(editingEntity.partitionId)}
				onChange={(value) => onChange('partitionId', Number(value))}
			/>
			<EntitySelect
				clearable={false}
				allowAll={true}
				onChange={(selectedEntity) => {
					setEntity(selectedEntity);
					onChange('entityId', selectedEntity.entityId);
				}}
				value={entity.entityId ? entity : undefined}
				initialOptionId={editingEntity.entityId}
			/>
		</RtForm>
	);
};
