export const URL_VALUES_SEPARATOR = '+';

export const addSearchParams = (
	paramName: string,
	paramValues: string
): URLSearchParams => {
	const searchParams = new URLSearchParams(location.search);
	if (searchParams.has(paramName)) {
		searchParams.delete(paramName);
	}
	searchParams.append(paramName, paramValues);
	return searchParams;
};

export const setURLParam = (
	paramName: string,
	paramValues: string[]
): string => {
	const noValuesSelected = paramValues && paramValues.length === 0;
	let searchParams = new URLSearchParams(location.search);
	if (noValuesSelected) {
		searchParams.delete(paramName);
	} else {
		searchParams = addSearchParams(
			paramName,
			paramValues.join(URL_VALUES_SEPARATOR)
		);
	}
	return searchParams.toString();
};
