/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TemplateScpIndexResponse = {
    properties: {
        serviceControlPointId: {
            type: 'string',
            isRequired: true,
        },
        scpStatusId: {
            type: 'number',
            isRequired: true,
        },
        inAos: {
            type: 'string',
            isRequired: true,
        },
        effectiveTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};