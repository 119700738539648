import { GuardianDoNotOriginateIndexResponse } from 'RtModels';
import { DoNotOriginateNumberTypeToNameMap } from 'RtUi/app/rtVue/DoNotOriginate/lib/utils/Constants';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { UserNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';

export const doNotOriginateColumns = (
	onClickDelete: (row: GuardianDoNotOriginateIndexResponse) => void
): Array<DataGridColumn<GuardianDoNotOriginateIndexResponse>> => [
	DeleteDataGridColumn({
		header: 'Delete',
		onClick: onClickDelete
	}),
	DefaultDataGridColumn({
		accessorKey: 'ani',
		header: 'ANI'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'active'
	}),
	DefaultDataGridColumn({
		header: 'Number Type',
		accessorKey: 'numberType',
		getValue: ({ cell }) =>
			DoNotOriginateNumberTypeToNameMap.get(cell.getValue() as any) ??
			(cell.getValue() as any)
	}),
	TimeStampDataGridColumn({
		accessorKey: 'created',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updated',
		header: 'Updated'
	}),
	UserNameDataGridColumn({
		accessorKey: 'createdBy',
		header: 'Created By'
	})
];
