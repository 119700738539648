import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

// exported definitions
// ======================================================================

export class CprColTimeOfDay extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.Time;
	public readonly hasTimeZone = true;

	public readonly allowOther = true;
	public readonly valueLimit = 1;
	public readonly valueRegExp = /^(1[0-2]|0?[1-9]):([0-5][0-9])(A|P)|((1[0-2]|0?[1-9]):([0-5][0-9])(A|P)-(1[0-2]|0?[1-9]):([0-5][0-9])(A|P))$/;
	public readonly isTreeNode = true;

	/*
		hh:mmx-hh:mmx where x can be A (am) or P (pm)
		time is standard time and default is central time
	*/

	public isPossibleValue(rawVal: string) {
		// only validating by valueRegExp
		return true;
	}

}
