/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $BlockedNumber = {
    properties: {
        blockedNumberId: {
            type: 'number',
            isRequired: true,
        },
        phoneNumber: {
            type: 'string',
            isRequired: true,
        },
        iso3: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isActive: {
            type: 'number',
        },
        blockAni: {
            type: 'number',
        },
        blockDestination: {
            type: 'number',
        },
    },
};