import { useMemo } from 'react';
import { Lerg6IndexRequest, Lerg6IndexResponse } from 'RtModels';
import { useGetLerg6Data } from 'RtUi/app/rtVue/DialCode/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLerg6Columns } from 'RtUi/components/data/DataGrid/configurations/administration/rateSheet/lerg6';

interface ILerg6GridProps {
	resourceParams?: Lerg6IndexRequest;
}

export const Lerg6Grid = ({ resourceParams }: ILerg6GridProps) => {
	const { data: lerg6Data, isLoading: lerg6Loading } =
		useGetLerg6Data(resourceParams);

	const lerg6GridColumns = useMemo(() => getLerg6Columns(), []);

	return (
		<DataGrid<Lerg6IndexResponse>
			data={lerg6Data?.data}
			columns={lerg6GridColumns}
			pageName={'rateSheet-dialCode-lerg6Grid'}
			loading={lerg6Loading}
			totalRows={lerg6Data?.totalCount}
		/>
	);
};
