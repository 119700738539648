import moment from 'moment';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SipAgentIndexResponse, SipAgentStatisticIndexRequest } from 'RtModels';
import { SipAgentsSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentSelect';
import { SipAgentStatsDataGrid } from 'RtUi/app/rtLcr/SipAgentStats/lib/grids/SipAgentStatsDataGrid';
import { SipAgentStatsRouter } from 'RtUi/app/rtLcr/SipAgentStats/SipAgentStats.router';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { CheckboxButton } from 'RtUi/components/ui/CheckboxButton';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const SipAgentStatsIndexContainer = (): JSX.Element => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true }
	});

	const [sipAgent, setSipAgent] = useState<SipAgentIndexResponse>();
	const [resourceParams, setResourceParams] =
		useState<SipAgentStatisticIndexRequest>({
			timeslot: moment().utc().startOf('day').toDate(),
			showServerInfo: 0,
			showDailyTotals: 0
		});

	const { control, handleSubmit } = useForm<SipAgentStatisticIndexRequest>({
		defaultValues: resourceParams
	});

	const handleSubmitForm = (data: SipAgentStatisticIndexRequest) => {
		setResourceParams(data);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={SipAgentStatsRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm onSubmit={handleSubmit(handleSubmitForm)}>
					<Controller
						control={control}
						name="sipAgentId"
						render={({ field: { onChange, value } }) => (
							<SipAgentsSelect
								label="SIP Agent"
								value={sipAgent}
								onChange={(val) => {
									setSipAgent(val);
									onChange(val?.sipAgentId);
								}}
								initialOptionId={value}
							/>
						)}
					/>
					<Controller
						control={control}
						name="timeslot"
						render={({ field: { onChange, value } }) => (
							<DatetimeFormControl
								label="Timeslot"
								value={moment(value)}
								onChange={(val) => onChange(val?.toDate())}
							/>
						)}
					/>
					<Controller
						control={control}
						name="showServerInfo"
						render={({ field: { onChange, value } }) => (
							<CheckboxButton
								name="Show Server Info"
								active={Boolean(value)}
								onClick={() => onChange(Number(!value))}
							/>
						)}
					/>
					<Controller
						control={control}
						name="showDailyTotals"
						render={({ field: { onChange, value } }) => (
							<CheckboxButton
								name="Show Daily Totals"
								active={Boolean(value)}
								onClick={() => onChange(Number(!value))}
							/>
						)}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<SipAgentStatsDataGrid
					resourceParams={resourceParams}
					pageName="rtLcr_sipAgentStats"
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

SipAgentStatsIndexContainer.displayName = 'SipAgentStatsIndexContainer';

SipAgentStatsRouter.setIndexRtUiFunctionalComponent(
	SipAgentStatsIndexContainer
);
