/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RtVueLcoSummaryIndexRequest = {
    properties: {
        timeRange: {
            type: 'TimeFilter',
            isRequired: true,
        },
        compareToDate: {
            type: 'Date',
            format: 'date-time',
        },
        dataColumns: {
            type: 'Array',
        },
        dataSums: {
            type: 'Array',
        },
        presets: {
            type: 'Array',
        },
        filters: {
            type: 'Array',
        },
        customerTestingOverride: {
            type: 'string',
        },
        orderBy: {
            type: 'OrderBy',
        },
        havingFilter: {
            type: 'Array',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        fillMissingTimeSlots: {
            type: 'number',
        },
        createMinuteProfile: {
            type: 'number',
        },
        respOrgId: {
            type: 'string',
        },
        label: {
            type: 'string',
        },
    },
};