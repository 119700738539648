export type DaysOfWeekAbbr = 'SU' | 'M' | 'TU' | 'W' | 'TH' | 'F' | 'SA' | '';

export type DaysOfWeekTypes = DaysOfWeekAbbr | 'OTHER';

export interface IDaysOfWeekMapType {
	key: DaysOfWeekAbbr;
	label: string;
}

export const DaysOfWeekMap: IDaysOfWeekMapType[] = [
	{ key: 'SU', label: 'Sunday' },
	{ key: 'M', label: 'Monday' },
	{ key: 'TU', label: 'Tuesday' },
	{ key: 'W', label: 'Wednesday' },
	{ key: 'TH', label: 'Thursday' },
	{ key: 'F', label: 'Friday' },
	{ key: 'SA', label: 'Saturday' }
	//{ key: 'OTHER', label: 'OTHER' },
];

export const getDayOfWeek = (daysOfWeekType: DaysOfWeekTypes) => {
	const dayOfWeek = DaysOfWeekMap.find((d) => d.key === daysOfWeekType);

	return dayOfWeek ?? undefined;
};

export const getLabelForDayOfWeek = (daysOfWeekType: DaysOfWeekAbbr) => {
	const dayOfWeekMap = getDayOfWeek(daysOfWeekType);

	if (dayOfWeekMap) {
		return dayOfWeekMap.label;
	}

	return '';
};
