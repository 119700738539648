import { Delete, Download, Upload } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import FileSaver from 'file-saver';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { FileShareIndexRequest, FileShareIndexResponse } from 'RtModels';
import { FileSharingUploadForm } from 'RtUi/app/user/MyProfile/lib/forms/FileSharingUploadForm';
import {
	deleteFileShare,
	downloadFileShare,
	useGetUserFileSharing
} from 'RtUi/app/user/MyProfile/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getUserFileSharingColumns } from 'RtUi/components/data/DataGrid/configurations/user';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';

interface IFileSharingGridProps {
	resourceParams?: FileShareIndexRequest;
}

export const FileSharingGrid = ({ resourceParams }: IFileSharingGridProps) => {
	const [isAsideOpen, setIsAsideOpen] = useState<boolean>(false);
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetUserFileSharing(resourceParams);
	const columns = useMemo(() => getUserFileSharingColumns(), []);
	const { mutateAsync } = useMutation(deleteFileShare);

	const downloadFile = async (fileShareId: number) => {
		try {
			const fileData = await downloadFileShare({ fileShareId });

			const response = await fetch(fileData.signedUrl, {
				method: 'GET',
				mode: 'cors',
				credentials: 'omit'
			});

			if (response.ok) {
				const blob = await response.blob();
				const blobUrl = URL.createObjectURL(blob);
				FileSaver.saveAs(blobUrl, fileData.fileName);
			} else {
				const text = await response.text();

				throw new Error(text);
			}
		} catch (e) {}
	};

	const deleteFile = async (fileShareId: number, fileName: string) => {
		const confirm = await Confirmation.createDelete(`File ${fileName}`);

		if (!confirm) {
			return;
		}

		await mutateAsync({ fileShareId });
		refetch();
	};

	return (
		<>
			<DataGrid<FileShareIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'user_fileSharing'}
				headerAction={() => (
					<Tooltip title="Upload File" arrow>
						<IconButton
							type="button"
							color="default"
							onClick={() => setIsAsideOpen(true)}
						>
							<Upload />
						</IconButton>
					</Tooltip>
				)}
				inlineActions={(row) => (
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<Tooltip title="Download File" arrow>
							<IconButton
								type="button"
								color="info"
								size="small"
								onClick={() => downloadFile(row.fileShareId)}
								sx={{
									padding: 0
								}}
							>
								<Download />
							</IconButton>
						</Tooltip>
						{Boolean(row.canDelete) && (
							<Tooltip title="Delete File" arrow>
								<IconButton
									type="button"
									color="error"
									size="small"
									onClick={() => deleteFile(row.fileShareId, row.localFileName)}
									sx={{
										padding: 0,
										marginLeft: '5px'
									}}
								>
									<Delete />
								</IconButton>
							</Tooltip>
						)}
					</Box>
				)}
			/>
			<Aside isOpen={isAsideOpen} disabledBody>
				<Aside.Header
					header="Upload File"
					onClose={() => setIsAsideOpen(false)}
				/>
				<FileSharingUploadForm
					onCreate={() => {
						refetch();
						setIsAsideOpen(false);
					}}
					onCancel={() => setIsAsideOpen(false)}
				/>
			</Aside>
		</>
	);
};
