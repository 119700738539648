import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { FileTypeIndexResponse, FileTypeProfileResponse } from 'RtModels';
import { RtVueApiRoutes } from 'RtExports/routes';

export class FileTypesResource extends HttpResource<
	FileTypeIndexResponse,
	FileTypeProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.FileTypes);
	}
}
