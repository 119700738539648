import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationNoteCreateRequest,
	GuardianReconciliationNoteIndexResponse,
	GuardianReconciliationNoteProfileResponse,
	GuardianReconciliationNoteUpdateRequest,
	GuardianReconciliationProfileUrlRequest,
	GuardianReconciliationNoteTypes
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ReconciliationNoteResource extends HttpResource<
	GuardianReconciliationNoteIndexResponse,
	GuardianReconciliationNoteProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianReconciliationNotes);
	}

	public create(
		reconciliationId: number,
		note: string,
		reconciliationNoteType: GuardianReconciliationNoteTypes,
		reconciliationDetailId: number | null = null
	) {
		const req: GuardianReconciliationNoteCreateRequest = {
			reconciliationDetailId,
			reconciliationId,
			reconciliationNoteType,
			note
		};
		const body = JSON.stringify(req);
		return this.fetchWithRoute<GuardianReconciliationNoteProfileResponse>(
			RtVueApiRoutes.GuardianReconciliationNotes.Create,
			{
				body,
				disableNotification: true
			}
		);
	}

	public update(
		guardianReconciliationId: number,
		req: GuardianReconciliationNoteUpdateRequest
	) {
		const body = JSON.stringify(req);
		const urlParams: GuardianReconciliationProfileUrlRequest = {
			guardianReconciliationId
		};

		return this.fetchWithRoute<GuardianReconciliationNoteProfileResponse>(
			RtVueApiRoutes.GuardianReconciliationNotes.Update,
			{
				urlParams,
				body,
				disableNotification: true
			}
		);
	}
}
