import moment from 'moment';
import { FC, useState } from 'react';
import {
	GatewayIndexResponse,
	SipMethod,
	SipTraceIndexRequest
} from 'RtModels';
import { GatewaySelect } from 'RtUi/app/rtSip/Connections/lib/forms/GatewaySelect';
import { SipTraceDataGrid } from 'RtUi/app/rtSip/SipTrace/lib/grids/SipTraceDataGrid';
import { SipTraceRouter } from 'RtUi/app/rtSip/SipTrace/SipTrace.router';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const SipTraceIndexContainer: FC = () => {
	const [resourceParams, setResourceParams] = useState<SipTraceIndexRequest>();
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search', isDefault: true },
		Results: { header: 'Results', isDisabled: resourceParams === undefined }
	});
	const [gateway, setGateway] = useState<GatewayIndexResponse>();
	const [startDate, setStartDate] = useState<moment.Moment>(
		moment().subtract(15, 'minutes')
	);
	const [fromUser, setFromUser] = useState<string>();
	const [toUser, setToUser] = useState<string>();
	const [sourceIp, setSourceIp] = useState<string>();
	const [destIp, setDestIp] = useState<string>();
	const [callId, setCallId] = useState<string>();
	const [orLogic, setOrLogic] = useState<boolean>(false);
	const [endDate, setEndDate] = useState<moment.Moment>(moment().endOf('day'));
	const [sipMethodSelectValue, setSipMethodSelectValue] =
		useState<ISimpleSelectFormControlOption<SipMethod | undefined>>();

	return (
		<TabbedLayout
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
			router={SipTraceRouter}
		>
			<TabbedLayoutTab header={tabs.Search.header}>
				<RtUiSearchForm
					onSubmit={(evt) => {
						evt.preventDefault();

						const sipMethod = sipMethodSelectValue?.value;

						setResourceParams({
							startTime: startDate.toDate(),
							endTime: endDate.toDate(),
							gatewayId: gateway?.partitionSipGatewayId || 0,
							orLogic,
							sipFromUser: fromUser,
							sipToUser: toUser,
							srcIp: sourceIp,
							dstIp: destIp,
							sipMethod,
							callId
						});
						setActiveTab(tabs.Results.header);
					}}
				>
					<GatewaySelect required value={gateway} onChange={setGateway} />
					<DatetimeFormControl
						label="Start Date"
						required
						value={startDate}
						onChange={setStartDate}
					/>
					<DatetimeFormControl
						label="End Date"
						required
						value={endDate}
						onChange={setEndDate}
					/>
					<InputFormControl
						label="From User"
						value={fromUser}
						onChange={setFromUser}
					/>
					<InputFormControl
						label="To User"
						value={toUser}
						onChange={setToUser}
					/>
					<InputFormControl
						label="Source IP"
						value={sourceIp}
						onChange={setSourceIp}
					/>
					<InputFormControl
						label="Destination IP"
						value={destIp}
						onChange={setDestIp}
					/>
					<SimpleSelectFormControl<SipMethod | undefined>
						label="SIP Method"
						value={sipMethodSelectValue}
						onChange={setSipMethodSelectValue}
						options={[
							{
								label: 'Invite',
								value: SipMethod.SipInvite
							},
							{
								label: 'Bye',
								value: SipMethod.Bye
							},
							{
								label: 'Cancel',
								value: SipMethod.Cancel
							},
							{
								label: '100',
								value: SipMethod.OneZeroZero
							},
							{
								label: '200',
								value: SipMethod.TwoZeroZero
							},
							{
								label: '183',
								value: SipMethod.OneEightThree
							}
						]}
					/>
					<InputFormControl
						label="Call Id"
						value={callId}
						onChange={setCallId}
					/>
					<CheckboxFormControl
						id="or-logic-checkbox"
						label="Apply OR logic to search parameters"
						onChange={setOrLogic}
						value={orLogic}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<SipTraceDataGrid resourceParams={resourceParams!} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

SipTraceRouter.setIndexRtUiFunctionalComponent(SipTraceIndexContainer, {
	groupName: 'Network Ops',
	orderPriority: 40,
	groupOrderPriority: 1020
});
