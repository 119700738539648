/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NumberProfileResponse = {
    properties: {
        numberId: {
            type: 'string',
            isRequired: true,
        },
        number: {
            type: 'string',
            isRequired: true,
        },
        iso3166Alpha3: {
            type: 'string',
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        nonRecurringAmount: {
            type: 'number',
            isRequired: true,
        },
        recurringAmount: {
            type: 'number',
            isRequired: true,
        },
        lastProfiled: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        trunkGroup: {
            type: 'string',
            isRequired: true,
        },
        translatedNumber: {
            type: 'string',
            isRequired: true,
        },
        accountId: {
            type: 'string',
            isRequired: true,
        },
        orderId: {
            type: 'string',
            isRequired: true,
        },
        status: {
            type: 'string',
            isRequired: true,
        },
        createdAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};