/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MinuteProfileMinute = {
    properties: {
        minuteProfileMinuteId: {
            type: 'number',
            isRequired: true,
        },
        minuteProfileId: {
            type: 'number',
            isRequired: true,
        },
        aniNpa: {
            type: 'string',
            isRequired: true,
            maxLength: 3,
        },
        aniNxx: {
            type: 'string',
            isRequired: true,
            maxLength: 3,
        },
        aniLata: {
            type: 'string',
            isRequired: true,
            maxLength: 4,
        },
        aniOcn: {
            type: 'string',
            isRequired: true,
            maxLength: 4,
        },
        lrnNpa: {
            type: 'string',
            isRequired: true,
            maxLength: 3,
        },
        lrnNxx: {
            type: 'string',
            isRequired: true,
            maxLength: 3,
        },
        lrnLata: {
            type: 'string',
            isRequired: true,
            maxLength: 4,
        },
        lrnOcn: {
            type: 'string',
            isRequired: true,
            maxLength: 4,
        },
        interConnects: {
            type: 'number',
            isRequired: true,
        },
        interMinutes: {
            type: 'number',
            isRequired: true,
        },
        interCharges: {
            type: 'number',
            isRequired: true,
        },
        intraConnects: {
            type: 'number',
            isRequired: true,
        },
        intraMinutes: {
            type: 'number',
            isRequired: true,
        },
        intraCharges: {
            type: 'number',
            isRequired: true,
        },
        indetConnects: {
            type: 'number',
            isRequired: true,
        },
        indetMinutes: {
            type: 'number',
            isRequired: true,
        },
        indetCharges: {
            type: 'number',
            isRequired: true,
        },
        totalConnects: {
            type: 'number',
            isRequired: true,
        },
        totalMinutes: {
            type: 'number',
            isRequired: true,
        },
        totalCharges: {
            type: 'number',
            isRequired: true,
        },
    },
};