/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum Reports {
    Asr = 'asr',
    Cps = 'cps',
    Ports = 'ports',
    Numbers = 'numbers',
    Financial = 'financial',
    Cdrs = 'cdrs',
    FullSummary = 'fullSummary',
    NetworkFinancialSummary = 'networkFinancialSummary',
    NetworkSummary = 'networkSummary',
    ReconciliationSummary = 'vw_reconciliation_byDay',
    LcoSummary = 'lcoSummary',
    CustomMargin = 'customMargin',
    RejectSummary = 'vw_rejectsByDay',
    RejectRevenue = 'vw_rejectsByDayRevenueDetail',
    RejectCost = 'vw_rejectsByDayCostDetail',
    TollfreeEnhanced = 'tollfreeEnhanced',
    TollfreeEnhancedDetail = 'tollfreeEnhancedDetail',
    Reconciliation = 'vendorReconciliation',
    UsageAuditByDay = 'usageAuditByDay',
    FourteenDay = 'fourteenDay',
    BirdsEyeView = 'superSummary',
    AsrLite = 'asrLite',
    NumbersLite = 'numbersSummaryDetailed',
    Roboscore = 'vw_roboscoreByDay',
    MonthlyTrending = 'vw_monthlyTrending',
    MessagesSummary = 'vw_messagesByDay',
    MessagesDetail = 'messages',
}