import {
	GuardianSubscriptionCicIndexRequest,
	GuardianSubscriptionCicIndexResponse
} from 'RtModels';
import { SubscriptionCicRouter } from 'RtUi/app/rtVue/SubscriptionCic/SubscriptionCic.router';
import { SubscriptionCicResource } from 'RtUi/app/rtVue/SubscriptionCic/lib/resources/SubscriptionCicResource';
import { useGetSubscriptionCIC } from 'RtUi/app/rtVue/SubscriptionCic/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSubscriptionCICColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/subscriptionCIC';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { useCallback, useMemo } from 'react';

interface ISubscriptionCicGridProps {
	resourceParams?: GuardianSubscriptionCicIndexRequest;
	autoFocusFilter?: boolean;
}

export const SubscriptionCicGrid = ({}: ISubscriptionCicGridProps) => {
	const { data, refetch, isLoading } = useGetSubscriptionCIC();

	const handleRemoveEntity = useCallback(
		() => async (row: GuardianSubscriptionCicIndexResponse) => {
			const resource = new SubscriptionCicResource();

			const confirm = await Confirmation.createDelete(
				String(row.guardianSubscriptionCicId)
			);

			if (!confirm) {
				return;
			}

			await resource.delete(row.guardianSubscriptionCicId);
			await refetch();
		},
		[refetch]
	);

	const columns = useMemo(
		() => getSubscriptionCICColumns(handleRemoveEntity),
		[handleRemoveEntity]
	);

	return (
		<>
			<DataGrid<GuardianSubscriptionCicIndexResponse>
				data={data?.data}
				columns={columns}
				pageName={'configureRateSheet-subscriptionCIC'}
				router={SubscriptionCicRouter}
				loading={isLoading}
				totalRows={data?.totalCount}
			/>
			<Confirmation />
		</>
	);
};
