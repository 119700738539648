import {
	FormControl,
	FormControlLabel,
	FormControlProps,
	FormLabel,
	Radio,
	RadioGroup,
	RadioGroupProps
} from '@mui/material';
import { noop } from 'lodash-es';

interface RtxUiRadioInputOptions<T> {
	label: string;
	value: T;
}

export type RtxUiRadioInputProps<T> = Omit<
	FormControlProps,
	'onChange' | 'value'
> &
	Omit<RadioGroupProps, 'onChange' | 'value'> & {
		label: string;
		value?: T;
		displayMode?: boolean;
		options: Array<RtxUiRadioInputOptions<T>>;
		onChange?: (value: string) => void;
	};

export const RtxUiRadioInput = <T = any,>({
	label,
	options,
	value,
	defaultValue,
	disabled,
	displayMode,
	onChange = noop,
	...props
}: RtxUiRadioInputProps<T>) => {
	return (
		<FormControl
			variant="outlined"
			sx={{ width: '100%' }}
			disabled={displayMode || disabled}
			{...props}
		>
			<FormLabel sx={{ fontSize: '12px' }}>{label}</FormLabel>
			<RadioGroup
				row
				defaultValue={defaultValue}
				value={value}
				onChange={(evt) => onChange(evt.target.value)}
			>
				{options.map((option, key) => (
					<FormControlLabel
						key={key}
						value={option.value}
						sx={{ '.MuiTypography-root': { fontSize: 16 } }}
						control={
							<Radio
								sx={{
									'& .MuiSvgIcon-root': {
										fontSize: 16
									}
								}}
							/>
						}
						label={option.label}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};
