import { useCallback } from 'react';
import { CountryIndexResponse } from 'RtModels';
import { useGetCountries } from 'RtUi/app/user/service';
import {
	ISelectFormControlInstanceProps,
	SelectFormControl
} from 'RtUi/components/ui/SelectFormControl';

interface ICountrySelectProps<
	IsMulti extends boolean,
	K extends keyof CountryIndexResponse
> extends ISelectFormControlInstanceProps<CountryIndexResponse, K, IsMulti> {
	valueKey?: K;
}

export const CountrySelect = <
	IsMulti extends boolean = false,
	K extends keyof CountryIndexResponse = 'iso3166Alpha3'
>({
	label = 'Country',
	valueKey = 'iso3166Alpha3' as any,
	...props
}: ICountrySelectProps<IsMulti, K>): JSX.Element => {
	const { data, isLoading } = useGetCountries();

	const formatOptionLabel = useCallback((country: CountryIndexResponse) => {
		return (
			<span>
				{country.label} ({country.iso3166Alpha3})
			</span>
		);
	}, []);

	return (
		<SelectFormControl<CountryIndexResponse, K, IsMulti>
			label={label}
			valueKey={valueKey}
			isLoading={isLoading}
			labelKey="label"
			formatOptionLabel={formatOptionLabel}
			options={data ?? []}
			{...props}
		/>
	);
};
