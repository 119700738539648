/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $Rt800ResourceIndexResponse = {
    properties: {
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        resourceTypeId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isDefault: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        integrationId: {
            type: 'Integrations',
            isRequired: true,
        },
        resRef1: {
            type: 'string',
            isRequired: true,
        },
        resRef2: {
            type: 'string',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        protocol: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        host: {
            type: 'string',
            isRequired: true,
        },
        port: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        path: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        passwordTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        extraParams: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        lastSyncTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        nextSyncTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        lastApiAttemptTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        lastApiSuccessTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
    },
};