import {
	NumberIndexResponse,
	ResourceNumberBillingRecord,
	ResourceNumberSwitchRecord
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';
import { ResourceConnectionTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ResourceConnectionTypeDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getDidNumbersColumns = (): Array<
	DataGridColumn<NumberIndexResponse>
> => [
	PhoneNumberDataGridColumn({
		accessorKey: 'number',
		header: 'Number'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	PhoneNumberDataGridColumn({
		accessorKey: 'translatedNumber',
		header: 'Translated Number'
	}),
	DefaultDataGridColumn({
		accessorKey: 'status',
		header: 'Status'
	}),
	ResourceConnectionTypeDataGridColumn({
		accessorKey: 'resourceTypeId',
		header: 'Type'
	}),
	DefaultDataGridColumn({
		accessorKey: 'resourceLabel',
		header: 'Resource'
	}),
	AccountDataGridColumn({
		accessorKey: 'accountId',
		header: 'Account'
	}),
	DefaultDataGridColumn({
		accessorKey: 'trunkGroup',
		header: 'Trunk Group'
	}),
	DefaultDataGridColumn({
		accessorKey: 'resRef2',
		header: 'Ref2'
	}),
	DefaultDataGridColumn({
		accessorKey: 'nonRecurringAmount',
		header: 'Non-Recurring Amount'
	}),
	DefaultDataGridColumn({
		accessorKey: 'recurringAmount',
		header: 'Recurring Amount'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdAt',
		header: 'Created'
	})
];

export const getDidBillingInventoryColumns = (): Array<
	DataGridColumn<ResourceNumberBillingRecord>
> => [
	PhoneNumberDataGridColumn({
		accessorKey: 'number',
		header: 'Number'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	AccountDataGridColumn({
		accessorKey: 'accountNumber',
		header: 'Account Number'
	}),
	DefaultDataGridColumn({
		accessorKey: 'recurringAmount',
		header: 'Recurring Amount'
	}),
	DefaultDataGridColumn({
		accessorKey: 'nonRecurringAmount',
		header: 'Non-Recurring Amount'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'startTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'endTs',
		header: 'End'
	})
];

export const getDidSwitchInventoryColumns = (): Array<
	DataGridColumn<ResourceNumberSwitchRecord>
> => [
	PhoneNumberDataGridColumn({
		accessorKey: 'number',
		header: 'Number'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	AccountDataGridColumn({
		accessorKey: 'accountNumber',
		header: 'Account Number'
	}),
	DefaultDataGridColumn({
		accessorKey: 'recurringAmount',
		header: 'Recurring Amount'
	}),
	DefaultDataGridColumn({
		accessorKey: 'nonRecurringAmount',
		header: 'Non-Recurring Amount'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'startTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'endTs',
		header: 'End'
	}),
	DefaultDataGridColumn({
		accessorKey: 'folder',
		header: 'Folder'
	})
];
