import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';
import { ICarrierRatesParseConfig } from 'RtUi/app/rtLco/Carriers/lib/bin/interfaces';
import {
	ISpreadsheetParserHeaderMatch,
	ISpreadsheetParseResult
} from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParser';
import { LcrRateSheetType } from 'RtModels';
import { memoize, uniqBy } from 'lodash-es';

export type ICarrierRateParserClass = new (
	config: ICarrierRatesParseConfig
) => CarrierRatesParser;

export abstract class CarrierRatesParser {
	public static GetUniqueColumns(columns: SpreadsheetParserColumn[]) {
		return uniqBy(columns, (column) => column.getColumnName());
	}

	public readonly InterRateColumn = this.createParserColumn('Inter. Rate', [
		'current price',
		'current rate',
		'daytime',
		'inter',
		'interrate',
		'inter rate',
		'interstate',
		'interstate rate',
		'interstate_rate',
		'latest rate',
		'new rate',
		'new rate (/min)',
		'new rate usd',
		'newrate',
		'new_rate',
		'peak rate',
		'price',
		'price in usd',
		'price()',
		'rate',
		'rate ( us)',
		'rate (usd)',
		'rate in usd',
		'rate per minute',
		'rate per minute usd',
		'rate us',
		'rate usd',
		'rate(usd)/min',
		'rate/min',
		'rates',
		'rates (us)',
		'to rate',
		'usd'
	]).setIsRequired(true);
	public readonly IntraRateColumn = this.createParserColumn('Intra. Rate', [
		'intra',
		'intra rate',
		'intrarate',
		'intrastate',
		'intrastate rate',
		'intrastate_rate'
	]);
	public readonly IndetRateColumn = this.createParserColumn('Indet. Rate', [
		'indet',
		'indet rate',
		'indeterminate',
		'indeterminaterate',
		'indeterminate rate',
		'indeterminate_rate'
	]);
	public readonly SummaryColumn = this.createParserColumn('Summary', [
		'activity',
		'change',
		'change type',
		'changes',
		'comment',
		'comments',
		'direction',
		'notes',
		'rate change',
		'rate status',
		'ratechangeflag',
		'remark',
		'remarks',
		'status',
		'terms'
	]);

	public readonly RequiredColumns = [this.InterRateColumn];

	protected getColumnIndexFor = memoize(
		(column: SpreadsheetParserColumn) => this.getColumnIndexForInternal(column),
		(column: SpreadsheetParserColumn) => column.getColumnName()
	);

	protected headerMatch: ISpreadsheetParserHeaderMatch | null = null;

	constructor(
		protected rateSheetType: LcrRateSheetType,
		protected config: ICarrierRatesParseConfig
	) {}

	public abstract getRateKey1Index(): number;
	public abstract getRateKey2Index(): number;

	public getRateKey1ForRow(row: ISpreadsheetParseResult): string {
		const columnIndex = this.getRateKey1Index();
		const rowHasColumnIndex = columnIndex in row;

		return rowHasColumnIndex ? row[columnIndex] : '';
	}

	/**
	 * @override
	 * @param row
	 */
	public getRateKey2ForRow(row: ISpreadsheetParseResult): string {
		const columnIndex = this.getRateKey2Index();
		const rowHasColumnIndex = columnIndex in row;

		return rowHasColumnIndex ? row[columnIndex] : '';
	}

	public getUniqueIdentifier(row: ISpreadsheetParseResult) {
		const rate1Key = this.getRateKey1ForRow(row);
		const rate2Key = this.getRateKey2ForRow(row);

		return `${rate1Key}/${rate2Key}`;
	}

	public getUniqueSummaryIdentifier(row: ISpreadsheetParseResult) {
		const interRateStr = this.getInterRateForRow(row);
		const intraRateStr = this.getIntraRateForRow(row);
		const indetRateStr = this.getIndetRateForRow(row);
		const summary = this.getSummaryForRow(row);

		return `${interRateStr}/${intraRateStr}/${indetRateStr}/${summary}`;
	}

	public getHeaderMatch() {
		return this.headerMatch;
	}

	public getRateSheetType() {
		return this.rateSheetType;
	}

	public getInterRateForRow(row: ISpreadsheetParseResult): string {
		const columnIndex = this.getColumnIndexFor(this.InterRateColumn);
		const rowHasColumnIndex = columnIndex in row;

		return rowHasColumnIndex ? row[columnIndex] : '';
	}

	public getIntraRateForRow(
		row: ISpreadsheetParseResult,
		shouldAutoFill = this.config.autofillIntraWithBestMatch
	): string {
		const columnIndex = this.getColumnIndexFor(this.IntraRateColumn);
		const rowHasColumnIndex = columnIndex in row;
		const possibleValue = rowHasColumnIndex ? row[columnIndex] : '';

		if (possibleValue === '' && shouldAutoFill) {
			return this.getInterRateForRow(row);
		}

		return possibleValue;
	}

	public getIndetRateForRow(
		row: ISpreadsheetParseResult,
		shouldAutoFill = this.config.autofillIndetWithBestMatch
	): string {
		const columnIndex = this.getColumnIndexFor(this.IndetRateColumn);
		const rowHasColumnIndex = columnIndex in row;
		const possibleValue = rowHasColumnIndex ? row[columnIndex] : '';

		if (possibleValue === '' && shouldAutoFill) {
			const interRateStr = this.getInterRateForRow(row);
			const intraRateStr = this.getIntraRateForRow(row);
			const interRate = Number(interRateStr) || 0; //remove NaN
			const intraRate = Number(intraRateStr) || 0; //remove NaN
			const indetRate = Math.max(interRate, intraRate);

			return String(indetRate);
		}

		return possibleValue;
	}

	public hasSummaryColumn() {
		const summaryColumn = this.getColumnIndexFor(this.SummaryColumn);

		return summaryColumn >= 0;
	}

	public getSummaryForRow(row: ISpreadsheetParseResult): string {
		const columnIndex = this.getColumnIndexFor(this.SummaryColumn);
		const rowHasColumnIndex = columnIndex in row;

		return rowHasColumnIndex ? row[columnIndex] : '';
	}

	public testHeaderMatches(headerMatches: ISpreadsheetParserHeaderMatch[]) {
		for (const headerMatch of headerMatches) {
			const passesHeaderMatch = this.testHeaderMatch(headerMatch);

			if (passesHeaderMatch) {
				return headerMatch;
			}
		}

		return undefined;
	}

	public testHeaderMatch(headerMatch: ISpreadsheetParserHeaderMatch) {
		const { columnsFound } = headerMatch;

		return this.getRequiredColumns().every((requiredColumn) =>
			columnsFound.some(
				(columnFound) =>
					columnFound.getColumnName() === requiredColumn.getColumnName()
			)
		);
	}

	public setHeaderMatch(headerMatch: ISpreadsheetParserHeaderMatch) {
		this.headerMatch = headerMatch;
	}

	public getRequiredAndOptionalColumns(): SpreadsheetParserColumn[] {
		return [
			...this.RequiredColumns.map((column) => column.clone()),
			this.IntraRateColumn.clone(),
			this.IndetRateColumn.clone(),
			this.SummaryColumn.clone()
		];
	}

	public getRequiredColumns(): SpreadsheetParserColumn[] {
		const columns = this.RequiredColumns.map((column) => column.clone());

		if (!this.config.autofillIntraWithBestMatch) {
			columns.push(this.IntraRateColumn.clone());
		}

		if (!this.config.autofillIndetWithBestMatch) {
			columns.push(this.IndetRateColumn.clone());
		}

		return columns;
	}

	protected createParserColumn(
		columnName: string,
		columnSearchKeywords: string[]
	): SpreadsheetParserColumn {
		return new SpreadsheetParserColumn(columnName, columnSearchKeywords);
	}

	private getColumnIndexForInternal(column: SpreadsheetParserColumn) {
		if (this.headerMatch === null) {
			return -1;
		}

		const { columnsFound } = this.headerMatch;

		const columnFound = columnsFound.find(
			(cf) => cf.getColumnName() === column.getColumnName()
		);

		if (columnFound) {
			return columnFound.getIndex();
		}

		return -1;
	}
}
