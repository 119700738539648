/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum AosType {
    AreaCode = 4,
    Lata = 5,
    Network = 6,
    States = 7,
}