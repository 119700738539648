import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	NumberGroupIndexRequest,
	NumberGroupIndexResponse,
	NumberGroupNumberIndexRequest,
	NumberGroupNumberIndexResponse,
	NumberGroupProfileRequest
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchNumberGroups = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: NumberGroupIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<
	RequestResponse<NumberGroupIndexResponse[], IncludeFullResponse>
> => {
	return handleGetRequest(Rt800ApiRoutes.NumberGroups.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetNumberGroups = (queryParams?: NumberGroupIndexRequest) => {
	return useQuery<FullResponse<NumberGroupIndexResponse[]>, Error>(
		[`useGetNumberGroups`, queryParams],
		() => fetchNumberGroups(queryParams, true),
		{ enabled: true }
	);
};

export const fetchNumberGroupNumbers = async <
	IncludeFullResponse extends boolean = false
>(
	urlParams: NumberGroupProfileRequest,
	queryParams?: NumberGroupNumberIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<
	RequestResponse<NumberGroupNumberIndexResponse[], IncludeFullResponse>
> => {
	return handleGetRequest(Rt800ApiRoutes.NumberGroupNumbers.Index, {
		urlParams,
		queryParams,
		includeFullResponse
	});
};

export const useGetNumberGroupNumbers = (
	urlParams: NumberGroupProfileRequest,
	queryParams?: NumberGroupNumberIndexRequest
) => {
	return useQuery<FullResponse<NumberGroupNumberIndexResponse[]>, Error>(
		[`useGetNumberGroups`, urlParams, queryParams],
		() => fetchNumberGroupNumbers(urlParams, queryParams, true),
		{ enabled: true }
	);
};
