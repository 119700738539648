/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationAttachmentProfileResponse = {
    properties: {
        guardianReconciliationAttachmentId: {
            type: 'number',
            isRequired: true,
        },
        reconciliationId: {
            type: 'number',
            isRequired: true,
        },
        fileName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        href: {
            type: 'string',
            isRequired: true,
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
    },
};