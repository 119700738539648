/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum MessageType {
    Email = 1,
    Sms = 2,
    Socket = 3,
    Firebase = 4,
}