/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipConnection = {
    properties: {
        sipConnectionId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        gatewayId: {
            type: 'number',
            isRequired: true,
        },
        directionId: {
            type: 'number',
            isRequired: true,
        },
        localAddress: {
            type: 'string',
        },
        localPort: {
            type: 'string',
        },
        hostAddress: {
            type: 'string',
            isRequired: true,
        },
        hostCidr: {
            type: 'string',
            isRequired: true,
        },
        hostPort: {
            type: 'number',
            isRequired: true,
        },
        techPrefix: {
            type: 'string',
            isRequired: true,
        },
        nanpFormat: {
            type: 'string',
            isRequired: true,
        },
        idddFormat: {
            type: 'string',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
        },
        mediaServerGroupId: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        deletedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        auditId: {
            type: 'number',
            isRequired: true,
        },
    },
};