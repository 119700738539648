import { FC } from 'react';
import { GuardianReconciliationStatus } from 'RtModels';
import {
	ISimpleSelectFormControlProps,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

export const GuardianReconciliationStatusSelect: FC<
	React.PropsWithChildren<
		Omit<
			ISimpleSelectFormControlProps<GuardianReconciliationStatus, false>,
			'options'
		>
	>
> = (props) => (
	<SimpleSelectFormControl<GuardianReconciliationStatus>
		{...props}
		options={[
			{ value: GuardianReconciliationStatus.Pending, label: 'Pending' },
			{ value: GuardianReconciliationStatus.Completed, label: 'Completed' },
			{ value: GuardianReconciliationStatus.Submitted, label: 'Submitted' },
			{
				value: GuardianReconciliationStatus.VendorApproved,
				label: 'Vendor Approved'
			},
			{
				value: GuardianReconciliationStatus.VendorRejected,
				label: 'Vendor Rejected'
			},
			{
				value: GuardianReconciliationStatus.VendorEscalated,
				label: 'Vendor Escalated'
			},
			{
				value: GuardianReconciliationStatus.Void,
				label: 'Void'
			}
		]}
	/>
);
