import * as React from 'react';
import { Card, Button } from 'react-bootstrap';
import {
	DashboardTabContext,
	IDashboardTabContext
} from 'RtUi/components/ui/DashboardTabs/context';
import { DashboardTab } from 'RtUi/components/ui/DashboardTabs/tab';
import AutoHeight from 'RtUi/components/ui/AutoHeight';

interface IDashboardTabsProps extends IDashboardTabContext {
	className?: string;
	header?: React.ReactNode;
	filters?: React.ReactChild;
	onTabChange: (activeTab: string) => void;
}

export class DashboardTabs extends React.PureComponent<
	React.PropsWithChildren<IDashboardTabsProps>
> {
	public render() {
		const { activeTab } = this.props;
		const tabs: DashboardTab[] = React.Children.toArray(this.props.children)
			//Filter conditional children
			.filter((child) => child && typeof child === 'object')
			//Treat the rest as tabs
			.map((child) => child as unknown as DashboardTab)
			//Filter out non-tabs
			.filter((child) => typeof child.props?.tabId === 'string');

		return (
			<DashboardTabContext.Provider value={{ activeTab }}>
				<Card className={this.props.className}>
					<Card.Header className="d-flex justify-content-between">
						<header className="align-self-center flex-fill">
							{typeof this.props.header === 'string' && (
								<b>{this.props.header}</b>
							)}
							{typeof this.props.header !== 'string' && this.props.header}
						</header>
						<section className="ps-3">
							{tabs.map((tab) => (
								<Button
									type="button"
									key={tab.props.tabId}
									className="d-block d-md-inline-block"
									variant={
										this.props.activeTab === tab.props.tabId ? 'light' : 'white'
									}
									onClick={() => this.props.onTabChange(tab.props.tabId)}
								>
									<i className={tab.props.icon} />
									<span style={{ textTransform: 'capitalize' }}>
										&nbsp;{tab.props.tabId}
									</span>
								</Button>
							))}
						</section>
					</Card.Header>
					{this.props.filters && (
						<Card.Header>{this.props.filters}</Card.Header>
					)}
					<AutoHeight>
						<Card.Body>{this.props.children}</Card.Body>
					</AutoHeight>
				</Card>
			</DashboardTabContext.Provider>
		);
	}
}
