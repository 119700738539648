import { CountryDialCodeIndexResponse } from 'RtModels';
import { useGetCountriesDialCodes } from 'RtUi/app/user/service';
import {
	ISelectFormControlInstanceProps,
	SelectFormControl
} from 'RtUi/components/ui/SelectFormControl';

interface ICountryDialCodeSelectProps<IsMulti extends boolean>
	extends ISelectFormControlInstanceProps<
		CountryDialCodeIndexResponse,
		'iso3',
		IsMulti
	> {}

export const CountryDialCodeSelect = <IsMulti extends boolean = false>({
	label = 'Country',
	...props
}: ICountryDialCodeSelectProps<IsMulti>): JSX.Element => {
	const { data, isLoading } = useGetCountriesDialCodes();

	return (
		<SelectFormControl<CountryDialCodeIndexResponse, 'iso3', IsMulti>
			label={label}
			valueKey="iso3"
			isLoading={isLoading}
			labelKey="summary"
			options={data ?? []}
			{...props}
		/>
	);
};
