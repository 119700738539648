/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CprRateSheetCreateRequest = {
    properties: {
        rateSheetTypeId: {
            type: 'number',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        routingCacheTypeId: {
            type: 'number',
            isRequired: true,
        },
        routingCacheKey: {
            type: 'string',
            isRequired: true,
        },
        effectiveTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        respOrgCarrierIds: {
            type: 'Array',
            isRequired: true,
        },
        minuteProfileIds: {
            type: 'Array',
            isRequired: true,
        },
    },
};