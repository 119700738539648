/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FileLogResource } from 'RtUi/app/rtVue/FileLog/lib/resources/FileLogResource';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import clsx from 'clsx';
import { Button } from 'react-bootstrap';

interface CustomFileLogButtonProps {
	selected: any;
	refetch: any;
	setRowSelected: any;
}

export const CustomFileLogButton = (props: CustomFileLogButtonProps) => {
	const { selected, setRowSelected, refetch } = props;
	const handleReprocess = async () => {
		const confirm = await Confirmation.create(
			<div>
				<span>
					<span>You are submitting</span>
					<span className="text-muted"> {selected.length} </span>
					<span>files. Do you wish to process?</span>
				</span>
			</div>
		);

		const currentDataGridResource = new FileLogResource();

		if (!confirm || !currentDataGridResource) {
			return;
		}

		await currentDataGridResource.reProcess({
			fileIds: selected
		});
		setRowSelected(null);

		await refetch();
	};

	return (
		<Button
			onClick={handleReprocess}
			className={clsx({
				'd-none': selected.length === 0
			})}
			id="addFileForReprocess"
		>
			Re Process
		</Button>
	);
};
