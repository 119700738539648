import { SipAgentEditor } from 'RtUi/app/AccountManagement/SipAgents/lib/forms/SipAgentEditor';
import { useGetSipAgent } from 'RtUi/app/AccountManagement/SipAgents/lib/services';
import { SipAgentRouter } from 'RtUi/app/AccountManagement/SipAgents/SipAgent.router';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const SipAgentProfileContainer = (): JSX.Element => {
	const entityId = useIdParam();
	const { data, isLoading, refetch } = useGetSipAgent(Number(entityId));

	const [tabs, activeTab, setActiveTab] = useTabs(
		SipAgentRouter.getProfileTabs()
	);

	if (isLoading) {
		return <Loading />;
	}

	if (!data) {
		return <p>Something went wrong!</p>;
	}

	return (
		<TabbedLayout
			profile={data}
			router={SipAgentRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<SipAgentEditor value={data} onSuccess={() => refetch()} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

SipAgentProfileContainer.displayName = 'SipAgentProfileContainer';

SipAgentRouter.setProfileRtUiFunctionalComponent(SipAgentProfileContainer);
