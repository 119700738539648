/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum LcrScenarios {
    Lata = 1,
    LataAreaCode = 2,
    LataAreaCodeExchange = 3,
    LataSixDigit = 4,
    AreaCode = 5,
    AreaCodeExchange = 6,
    State = 7,
    StateAreaCode = 8,
    StateAreaCodeExchange = 9,
    StateSixDigit = 10,
    SixDigit = 11,
}