import { Component } from 'react';
import { Link } from 'react-router-dom';
import { AccountRouter } from 'RtUi/app/AccountManagement/Accounts/Account.router';
import { Alert } from 'react-bootstrap';
import { AccountContactResource } from 'RtUi/app/AccountManagement/Accounts/lib/resources/AccountContactResource';

interface IAccountWarningsProps {
	accountId: number;
	onAddContact: () => void;
}

interface IAccountWarningsState {
	missingBillingAndRatesMsg: string;
	missingConnectionsMsg: string;
}

export class AccountWarnings extends Component<
	IAccountWarningsProps,
	IAccountWarningsState
> {
	public state: IAccountWarningsState = {
		missingBillingAndRatesMsg: '',
		missingConnectionsMsg: ''
	};

	public async componentDidMount() {
		const missingBillingAndRatesMsg =
			await this.getMissingRatesAndBillingContactMessage();
		const missingConnectionsMsg = await this.getMissingConnectionsMessage();
		this.setState({
			missingBillingAndRatesMsg,
			missingConnectionsMsg
		});
	}

	public render() {
		const { missingBillingAndRatesMsg, missingConnectionsMsg } = this.state;

		if (!missingBillingAndRatesMsg && !missingConnectionsMsg) {
			return null;
		}

		return (
			<Alert variant="warning">
				<div className="d-flex">
					<div className="align-self-center me-1">
						<i className="fas fa-fw fa-exclamation-triangle" />
					</div>
					<div>
						{/*TODO:No API to get account connections*/}
						{/*{missingConnectionsMsg && (*/}
						{/*	<div>*/}
						{/*		{` ${missingConnectionsMsg} `}*/}
						{/*		<Link*/}
						{/*			to={ConnectionRouter.getIndexRoute(*/}
						{/*				true,*/}
						{/*				'Create SIP Connection'*/}
						{/*			)}*/}
						{/*		>*/}
						{/*			Create a connection now.*/}
						{/*		</Link>*/}
						{/*	</div>*/}
						{/*)}*/}
						{missingBillingAndRatesMsg && (
							<div>
								{` Missing ${missingBillingAndRatesMsg} contacts for this carrier. `}
								<Link
									to={AccountRouter.getProfileRoute(
										this.props.accountId,
										'Contacts'
									)}
									onClick={this.props.onAddContact}
								>
									Set up contacts now
								</Link>
							</div>
						)}
					</div>
				</div>
			</Alert>
		);
	}

	private async getMissingRatesAndBillingContactMessage(): Promise<string> {
		const accountContacts = new AccountContactResource(this.props.accountId);
		const contacts = await accountContacts.getAll();

		let missingFields: string = '';
		if (!contacts.some((contact) => contact.isBilling)) {
			missingFields += 'billing';
		}

		if (!contacts.some((contact) => contact.isRates)) {
			if (missingFields !== '') {
				missingFields += ' and ';
			}
			missingFields += 'rates';
		}

		return missingFields;
	}

	private async getMissingConnectionsMessage(): Promise<string> {
		//TODO: No API to get account connections
		// const connectionsResource = new AccountConnectionResource(
		// 	this.props.accountId
		// );
		//
		// const connections = await connectionsResource.getAll();
		//
		// if (connections.length === 0) {
		// 	return 'Missing connections for this carrier.';
		// }

		return '';
	}
}
