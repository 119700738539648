import { ServiceIndexResponse } from 'RtModels';
import { ServiceResource } from 'RtUi/app/rtSip/Services/lib/resources/ServiceResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface ServiceDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		ServiceIndexResponse,
		T
	> {}

export const ServiceDataGridCache = new ResourceCacheMap<ServiceIndexResponse>(
	new ServiceResource(),
	'serviceId',
	'label'
);

export const ServiceDataGridColumn = <T = any,>(
	config: ServiceDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: ServiceDataGridCache,
		...config
	});
};
