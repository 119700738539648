import * as React from 'react';
import {
	Card,
	Col,
	Nav,
	Row,
	Button,
	Form,
	ListGroup,
	Modal,
	Table
} from 'react-bootstrap';
import { DidSwitchInventorySpreadsheetParser } from 'RtUi/app/rtDid/Numbers/bin/DidSwitchInventorySpreadsheetParser';
import { DidSwitchInventoryGrid } from 'RtUi/app/rtDid/Numbers/lib/grids/DidSwitchInventoryGrid';
import {
	DidNumbersResource,
	IDidSwitchInventoryRecord,
	INumberCreateResponse
} from 'RtUi/app/rtDid/Numbers/lib/resources/DidNumbersResource';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { ResourceType, ResourceConfigurationIndexResponse } from 'RtModels';
import { RtError } from 'RtUi/utils/errors/RtError';
import { ResourceConfigurationSelect } from 'RtUi/app/rtCommon/ResourceConfigurations/lib/controls/ResourceConfigurationSelect';

interface IUploadDidSwitchInventoryFormProps {
	onUpdate: (res: INumberCreateResponse) => void;
}

interface ISpreadsheetLogEntry {
	message: string;
	type: 'error' | 'info' | 'debug';
}

interface IUploadDidSwitchInventoryFormState {
	error?: any;
	step1Errors?: RtError;
	spreadsheetLogEntries: ISpreadsheetLogEntry[];
	showVerbose: boolean;
	isSubmitting: boolean;
	inventoryFile: File | undefined;
	processingInventoryFile: boolean;
	disableWizardActions: boolean;
	isSamplesModalOpen: boolean;
	wizardStep: number;
	switchInventoryRecords?: IDidSwitchInventoryRecord[];
	vendor?: ResourceConfigurationIndexResponse;
	overwriteInventory: 0 | 1;
}

export class UploadDidSwitchInventoryForm extends React.Component<
	IUploadDidSwitchInventoryFormProps,
	IUploadDidSwitchInventoryFormState
> {
	public state: IUploadDidSwitchInventoryFormState = {
		error: undefined,
		spreadsheetLogEntries: [],
		isSubmitting: false,
		showVerbose: false,
		inventoryFile: undefined,
		processingInventoryFile: false,
		isSamplesModalOpen: false,
		wizardStep: 1,
		disableWizardActions: true,
		step1Errors: undefined,
		//2nd step
		overwriteInventory: 0
	};

	public didSwitchInventorySpreadsheetParser!: DidSwitchInventorySpreadsheetParser;
	public fileUtils = new FileUtils();

	public toggleIsSamplesModalOpen(
		isSamplesModalOpen = !this.state.isSamplesModalOpen
	) {
		this.setState({ isSamplesModalOpen });
	}

	public pushSpreadsheetMessage(
		type: ISpreadsheetLogEntry['type'],
		message: string
	) {
		const { showVerbose } = this.state;

		if (type === 'debug' && !showVerbose) {
			return;
		}

		this.setState(({ spreadsheetLogEntries }) => {
			//spreadsheetLogEntries.splice(0, 0, { message, type });
			spreadsheetLogEntries.push({ message, type });

			return { spreadsheetLogEntries };
		});
	}

	public submitStep2(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		this.setState({ wizardStep: 3 });
	}

	public async uploadBillingInventory(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		const { switchInventoryRecords, overwriteInventory, vendor } = this.state;

		if (!switchInventoryRecords || !vendor) {
			return Promise.reject();
		}

		this.pushSpreadsheetMessage('info', 'Uploading Inventory...');

		this.setState({ isSubmitting: true, error: undefined });

		let createRes: INumberCreateResponse | null = null;

		try {
			const didNumbersResource = new DidNumbersResource();
			createRes = await didNumbersResource.updateSwitchInventory(
				vendor.resourceId,
				overwriteInventory,
				switchInventoryRecords
			);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (createRes) {
			this.props.onUpdate(createRes);
		}
	}

	public renderSampleTable() {
		const sampleData: string[][] = [
			[
				'1-888-833-1212',
				'Label 1',
				'',
				'0.0002',
				'0.0001',
				'01/12/2016',
				'',
				'Directory B'
			],
			[
				'1-888-833-1213',
				'Label 2',
				'Customer A',
				'0.00025',
				'0.0001',
				'',
				'',
				''
			],
			[
				'1-888-833-1214',
				'Third Label',
				'Customer B',
				'0.00025',
				'0',
				'01/15/2018',
				'01/15/2020',
				'Folder A'
			]
		];

		return (
			<CollapsibleCard
				header="Example of Billing Inventory Spreadsheet"
				className="mb-3"
			>
				<Table size="sm" bordered className="bg-white" responsive>
					<thead>
						<tr>
							<th>Number</th>
							<th>Label</th>
							<th>Account</th>
							<th>Recurring Amount</th>
							<th>Non-Recurring Amount</th>
							<th>Start</th>
							<th>End</th>
							<th>Folder</th>
						</tr>
					</thead>
					<tbody>
						{sampleData.map((values, index) => (
							<tr key={index}>
								{values.map((value, innerIndex) => (
									<td className="text-monospace" key={innerIndex}>
										{value}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</CollapsibleCard>
		);
	}

	public submitStep1(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		this.setState({ wizardStep: 2 });
	}

	public async setInventoryFile(inventoryFile: File) {
		this.pushSpreadsheetMessage('debug', 'Downloading Spreadsheet...');

		this.setState({
			step1Errors: undefined,
			inventoryFile: undefined,
			disableWizardActions: true
		});
		this.setState({ processingInventoryFile: true });

		try {
			const parserErrors: string[] = [];
			const inventorySpreadsheet =
				await this.fileUtils.spreadsheetFileToJson(inventoryFile);
			const onInfoMessage = (infoMessage: string) =>
				this.pushSpreadsheetMessage('info', infoMessage);
			const onDebugMessage = (debugMessage: string) =>
				this.pushSpreadsheetMessage('debug', debugMessage);
			const onErrorMessage = (errorMessage: string) => {
				parserErrors.push(errorMessage);
				this.pushSpreadsheetMessage('error', errorMessage);
			};

			this.didSwitchInventorySpreadsheetParser =
				new DidSwitchInventorySpreadsheetParser(inventorySpreadsheet);

			this.didSwitchInventorySpreadsheetParser.on('info', onInfoMessage);
			this.didSwitchInventorySpreadsheetParser.on('debug', onDebugMessage);
			this.didSwitchInventorySpreadsheetParser.on('error', onErrorMessage);

			const switchInventoryRecords =
				this.didSwitchInventorySpreadsheetParser.getInventoryRecords();

			if (parserErrors.length > 0) {
				throw parserErrors;
			}

			this.setState({
				disableWizardActions: false,
				inventoryFile,
				switchInventoryRecords
			});
		} catch (step1Errors: any) {
			this.setState({ step1Errors });
		} finally {
			this.setState({ processingInventoryFile: false });
		}
	}

	public renderStep1() {
		return (
			<Form onSubmit={(e) => this.submitStep1(e)}>
				<a
					className="px-4 pt-4 d-flex justify-content-end"
					onClick={() => this.toggleIsSamplesModalOpen(true)}
				>
					<span className="text-muted">
						<i className="fas fa-fw fa-info-circle" />
						&nbsp;
						<u>Example File</u>
					</span>
				</a>
				<DragAndDropFileUploader
					processing={this.state.processingInventoryFile}
					accept={FileUtils.AcceptTypes.Spreadsheet}
					onDrop={(inventoryFile) => this.setInventoryFile(inventoryFile)}
					value={this.state.inventoryFile}
				/>
				<FormErrors error={this.state.step1Errors} className="p-4" />
				{this.renderWizardSteps()}
			</Form>
		);
	}

	public renderStep2() {
		return (
			<Form onSubmit={(e) => this.submitStep2(e)}>
				<Card.Body style={{ maxWidth: 450 }}>
					<RadioFormControl<
						IUploadDidSwitchInventoryFormState['overwriteInventory']
					>
						required
						label="Import Process"
						onChange={(overwriteInventory) =>
							this.setState({ overwriteInventory })
						}
						value={this.state.overwriteInventory}
						options={[
							{
								label: 'Add to Current Inventory',
								value: 0
							},
							{
								label: (
									<>
										Replace Current Inventory{' '}
										<span className="text-danger">
											(previous inventory is deleted)
										</span>
									</>
								),
								value: 1
							}
						]}
					/>
					<ResourceConfigurationSelect
						required
						label="Import Inventory Into"
						appendDropdownToBody
						resourceType={ResourceType.Switch}
						onChange={(vendor: ResourceConfigurationIndexResponse) =>
							this.setState({ vendor })
						}
						value={this.state.vendor}
					/>
				</Card.Body>
				{this.renderWizardSteps()}
			</Form>
		);
	}

	public renderStep3() {
		const { switchInventoryRecords } = this.state;

		if (!switchInventoryRecords) {
			return null;
		}

		return (
			<Form onSubmit={(e) => this.uploadBillingInventory(e)}>
				<section className="table-info text-info">
					<section className="p-3 d-flex justify-content-start">
						<article>
							<i className="fas fa-fw fa-info-circle me-2" />
						</article>
						<article>
							Review the switch records below before submitting.
						</article>
					</section>
				</section>
				<DidSwitchInventoryGrid
					disableCardAppearance
					disableCollapse
					records={switchInventoryRecords}
				/>
				<FormErrors error={this.state.error} className="p-4" />
				{this.renderWizardSteps('Submit')}
			</Form>
		);
	}

	public renderSpreadsheetMessage() {
		const { spreadsheetLogEntries } = this.state;

		if (spreadsheetLogEntries.length <= 0) {
			return null;
		}

		return (
			<article
				className="list-group list-group-flush mb-3"
				style={{ maxHeight: 300, overflowY: 'auto' }}
			>
				{spreadsheetLogEntries.map((spreadsheetLogEntry) => {
					const { type } = spreadsheetLogEntry;
					let color = 'light';

					if (type === 'error') {
						color = 'danger';
					} else if (type === 'info') {
						color = 'success';
					}

					return (
						<ListGroup.Item
							key={spreadsheetLogEntry.message}
							className="rounded-0"
							variant={color}
						>
							{spreadsheetLogEntry.message}
						</ListGroup.Item>
					);
				})}
			</article>
		);
	}

	public renderWizardSteps(nextStepText = 'Continue') {
		const { wizardStep, isSubmitting, disableWizardActions } = this.state;
		const previousStep = wizardStep - 1;

		return (
			<Card.Body className="d-flex justify-content-around px-md-5">
				{wizardStep !== 1 && (
					<Button
						size="lg"
						variant="light"
						className="flex-fill mx-md-5 mx-2"
						style={{ maxWidth: 300 }}
						disabled={isSubmitting || disableWizardActions}
						onClick={() => this.setState({ wizardStep: previousStep })}
					>
						{/* <i className="fas fa-fw fa-angle-left" /> */}
						<span>&nbsp;Back</span>
					</Button>
				)}
				<Button
					type="submit"
					size="lg"
					variant="primary"
					className="flex-fill mx-md-5 mx-2"
					disabled={isSubmitting || disableWizardActions}
					style={{ maxWidth: 300 }}
				>
					<span>{nextStepText}&nbsp;</span>
					{!isSubmitting && <i className="fas fa-fw fa-long-arrow-alt-right" />}
					{isSubmitting && <i className="fas fa-fw fa-cog fa-spin" />}
				</Button>
			</Card.Body>
		);
	}

	public render() {
		const { wizardStep } = this.state;
		return (
			<section>
				<Row className="g-0">
					<Col xl={10} className="mb-4">
						<section>
							<Card style={{ overflowX: 'hidden' }}>
								<Card.Header className="d-none d-md-block">
									<Nav className="nav-steps d-md-flex justify-content-between">
										<Nav.Item>
											<Nav.Link active={wizardStep === 1}>1. Upload</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link active={wizardStep === 2}>
												2. Import Options
											</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link active={wizardStep === 3}>
												3. Review & Submit
											</Nav.Link>
										</Nav.Item>
									</Nav>
								</Card.Header>
								{wizardStep === 1 && (
									<section className="subtleSlideInRight">
										{this.renderStep1()}
									</section>
								)}
								{wizardStep === 2 && (
									<section className="subtleSlideInRight">
										{this.renderStep2()}
									</section>
								)}
								{wizardStep === 3 && (
									<section className="subtleSlideInRight">
										{this.renderStep3()}
									</section>
								)}
							</Card>
						</section>
					</Col>
				</Row>
				<Modal
					show={this.state.isSamplesModalOpen}
					size="xl"
					onHide={() => this.toggleIsSamplesModalOpen()}
				>
					<Modal.Body className="bg-light rounded">
						<article className="d-flex justify-content-end mb-2">
							<Button
								variant="light"
								onClick={() => this.toggleIsSamplesModalOpen(false)}
							>
								<i className="fas fa-times fa-fw fa-lg" />
							</Button>
						</article>
						{this.renderSampleTable()}
					</Modal.Body>
				</Modal>
			</section>
		);
	}
}
