import { AccountManagementRouter } from 'RtUi/app/AccountManagement/AccountManagement.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { SipAgentIndexResponse, SipAgentProfileResponse } from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';

interface ISipAgentProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const SipAgentProfileContainerTabs: ISipAgentProfileContainerTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class SipAgentRouterClass extends AccountManagementRouter<
	SipAgentIndexResponse,
	SipAgentProfileResponse,
	ISipAgentProfileContainerTabs
> {
	constructor() {
		super(
			'SIP Agents',
			'sipAgents',
			'sipAgentId',
			SipAgentProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.SipAgents);
	}

	public getIndexLabel(record: SipAgentIndexResponse) {
		return record.label;
	}

	public getProfileLabel(profile: SipAgentProfileResponse) {
		return profile.label;
	}
}

export const SipAgentRouter = new SipAgentRouterClass();
