/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CostClassificationTypes {
    UsaRegular = 1,
    UsaMediumCost = 2,
    UsaHighCost = 3,
    InternationalRegular = 10,
    InternationalExpensive = 11,
    InternationalHighCost = 12,
}