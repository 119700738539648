export enum CprApprovalIndicator {
	NotApplicable = 'NA',
	Ok = 'OK',
	AwaitingApproval = 'AW',
	ApprovalDenied = 'DN',
	NotRequired = 'NR',
	RejectedEffectiveDate = 'RJ',
	Unknown = 'UN'
};

export enum SomosRecordType {
	Template = 'TAD',
	Pointer = 'PAD',
	Number = 'CAD'
}

export enum AosNetwork {
	BA = 'BA',
	BS = 'BS',
	Canada = 'CN',
	Caribbean = 'CR',
	UnitedStates = 'US',
	UnitedStatesCanada = 'XA',
	UnitedStatesCaribbean = 'XB',
	UnitedStatesCanadaCaribbean = 'XC',
	UW = 'UW',
}

// Cannot be combined with other Networks, LATAs, States, or AreaCodes
export const NonCombinationalAosNetworks = [
	AosNetwork.UnitedStates,
	AosNetwork.UnitedStatesCanada,
	AosNetwork.UnitedStatesCaribbean,
	AosNetwork.UnitedStatesCanadaCaribbean
];

export interface IAosNetwork {
	aosNetworkId: AosNetwork;
	label: string;
}

export const AosNetworks: IAosNetwork[] = [
	{ aosNetworkId: AosNetwork.BA, label: 'BA' },
	{ aosNetworkId: AosNetwork.BS, label: 'BS' },
	{ aosNetworkId: AosNetwork.Canada, label: 'CN' },
	{ aosNetworkId: AosNetwork.Caribbean, label: 'CR' },
	{ aosNetworkId: AosNetwork.UnitedStates, label: 'US' },
	{ aosNetworkId: AosNetwork.UnitedStatesCanada, label: 'XA' },
	{ aosNetworkId: AosNetwork.UnitedStatesCaribbean, label: 'XB' },
	{ aosNetworkId: AosNetwork.UnitedStatesCanadaCaribbean, label: 'XC' },
	{ aosNetworkId: AosNetwork.UW, label: 'UW' },
];

export enum AosNodeType {
	AreaCode = 'AC',
	Lata = 'LT',
	Network = 'NT',
	State = 'ST',
}

export enum CprErrorType {
	Notice = 1,
	Warning = 2,
	Error = 3,
	Critical = 9,
}

export enum CprLergLata {
	Alaska = '832',
	Canada = '888',
	Hawaii = '834',
	PuertoRico = '820',
}

export enum CprLergPoint {
	UnitedStates = '0',
	Canada = '2',
	Alaska = '3',
	Caribbean = '4',
	Hawaii = '6',
	SpecialServices = '9',
	PersonalServices = 'B',
	Pacific = 'C',
	Unknown = 'X',
}

export interface ICprLergPoint {
	pointId: string;
	label: string;
}

export const CprLergPoints: ICprLergPoint[] = [
	{ pointId: '0', label: 'US - 48' },
	{ pointId: '2', label: 'Canada' },
	{ pointId: '3', label: 'US - Alaska' },
	{ pointId: '4', label: 'Caribbean' },
	{ pointId: '6', label: 'US - Hawaii' },
	{ pointId: '9', label: '9YY Services' },
	{ pointId: 'B', label: 'Personal Communications Services (Non-Geographic) (5XX-NXX, 6YY)' },
	{ pointId: 'C', label: 'Pacific (Guam, Northern Mariana Islands, American Samoa)' },
];

export interface ICprLergState {
	worldValue: string;
	somosValue: string;
	iso2: string;
	pointId: string;
	label: string;
}

export const CprLergStates: ICprLergState[] = [
	{ worldValue: 'AB', somosValue: 'AB', iso2: 'CA', pointId: '2', label: 'Alberta' },
	{ worldValue: 'AI', somosValue: 'AI', iso2: 'AI', pointId: '4', label: 'Anguilla' },
	{ worldValue: 'AK', somosValue: 'AK', iso2: 'US', pointId: '3', label: 'Alaska' },
	{ worldValue: 'AL', somosValue: 'AL', iso2: 'US', pointId: '0', label: 'Alabama' },
	{ worldValue: 'AN', somosValue: 'AN', iso2: 'AG', pointId: '4', label: 'Antigua and Barbuda' },
	{ worldValue: 'AR', somosValue: 'AR', iso2: 'US', pointId: '0', label: 'Arkansas' },
	{ worldValue: 'AS', somosValue: 'AS', iso2: 'AS', pointId: 'C', label: 'American Samoa' },
	{ worldValue: 'AZ', somosValue: 'AZ', iso2: 'US', pointId: '0', label: 'Arizona' },
	{ worldValue: 'BA', somosValue: 'BA', iso2: 'BS', pointId: '4', label: 'Bahamas' },
	{ worldValue: 'BC', somosValue: 'BC', iso2: 'CA', pointId: '2', label: 'British Columbia' },
	{ worldValue: 'BD', somosValue: 'BD', iso2: 'BB', pointId: '4', label: 'Barbados' },
	{ worldValue: 'BM', somosValue: 'BM', iso2: 'BM', pointId: '4', label: 'Bermuda' },
	{ worldValue: 'BV', somosValue: 'BV', iso2: 'VG', pointId: '4', label: 'Virgin Islands (British)' },
	{ worldValue: 'CA', somosValue: 'CA', iso2: 'US', pointId: '0', label: 'California' },
	{ worldValue: 'CO', somosValue: 'CO', iso2: 'US', pointId: '0', label: 'Colorado' },
	{ worldValue: 'CQ', somosValue: 'CQ', iso2: 'KY', pointId: '4', label: 'Cayman Islands' },
	{ worldValue: 'CT', somosValue: 'CT', iso2: 'US', pointId: '0', label: 'Connecticut' },
	{ worldValue: 'DC', somosValue: 'DC', iso2: 'US', pointId: '0', label: 'District of Columbia' },
	{ worldValue: 'DE', somosValue: 'DE', iso2: 'US', pointId: '0', label: 'Delaware' },
	{ worldValue: 'DM', somosValue: 'DM', iso2: 'DM', pointId: '4', label: 'Dominica' },
	{ worldValue: 'DR', somosValue: 'DR', iso2: 'DO', pointId: '4', label: 'Dominican Republic' },
	{ worldValue: 'FL', somosValue: 'FL', iso2: 'US', pointId: '0', label: 'Florida' },
	{ worldValue: 'GA', somosValue: 'GA', iso2: 'US', pointId: '0', label: 'Georgia' },
	{ worldValue: 'GN', somosValue: 'GN', iso2: 'GD', pointId: '4', label: 'Grenada' },
	{ worldValue: 'GU', somosValue: 'GU', iso2: 'GU', pointId: 'C', label: 'Guam' },
	{ worldValue: 'HI', somosValue: 'HI', iso2: 'US', pointId: '6', label: 'Hawaii' },
	{ worldValue: 'IA', somosValue: 'IA', iso2: 'US', pointId: '0', label: 'Iowa' },
	{ worldValue: 'ID', somosValue: 'ID', iso2: 'US', pointId: '0', label: 'Idaho' },
	{ worldValue: 'IL', somosValue: 'IL', iso2: 'US', pointId: '0', label: 'Illinois' },
	{ worldValue: 'IN', somosValue: 'IN', iso2: 'US', pointId: '0', label: 'Indiana' },
	{ worldValue: 'JM', somosValue: 'JM', iso2: 'JM', pointId: '4', label: 'Jamaica' },
	{ worldValue: 'KA', somosValue: 'KA', iso2: 'KN', pointId: '4', label: 'Saint Kitts and Nevis' },
	{ worldValue: 'KS', somosValue: 'KS', iso2: 'US', pointId: '0', label: 'Kansas' },
	{ worldValue: 'KY', somosValue: 'KY', iso2: 'US', pointId: '0', label: 'Kentucky' },
	{ worldValue: 'LA', somosValue: 'LA', iso2: 'US', pointId: '0', label: 'Louisiana' },
	{ worldValue: 'MA', somosValue: 'MA', iso2: 'US', pointId: '0', label: 'Massachusetts' },
	{ worldValue: 'MB', somosValue: 'MB', iso2: 'CA', pointId: '2', label: 'Manitoba' },
	{ worldValue: 'MD', somosValue: 'MD', iso2: 'US', pointId: '0', label: 'Maryland' },
	{ worldValue: 'ME', somosValue: 'ME', iso2: 'US', pointId: '0', label: 'Maine' },
	{ worldValue: 'MI', somosValue: 'MI', iso2: 'US', pointId: '0', label: 'Michigan' },
	{ worldValue: 'MN', somosValue: 'MN', iso2: 'US', pointId: '0', label: 'Minnesota' },
	{ worldValue: 'MO', somosValue: 'MO', iso2: 'US', pointId: '0', label: 'Missouri' },
	{ worldValue: 'MP', somosValue: 'NN', iso2: 'MP', pointId: 'C', label: 'Northern Mariana Islands' },
	{ worldValue: 'MS', somosValue: 'MS', iso2: 'US', pointId: '0', label: 'Mississippi' },
	{ worldValue: 'MT', somosValue: 'MT', iso2: 'US', pointId: '0', label: 'Montana' },
	{ worldValue: 'NB', somosValue: 'NB', iso2: 'CA', pointId: '2', label: 'New Brunswick' },
	{ worldValue: 'NC', somosValue: 'NC', iso2: 'US', pointId: '0', label: 'North Carolina' },
	{ worldValue: 'ND', somosValue: 'ND', iso2: 'US', pointId: '0', label: 'North Dakota' },
	{ worldValue: 'NE', somosValue: 'NE', iso2: 'US', pointId: '0', label: 'Nebraska' },
	{ worldValue: 'NH', somosValue: 'NH', iso2: 'US', pointId: '0', label: 'New Hampshire' },
	{ worldValue: 'NJ', somosValue: 'NJ', iso2: 'US', pointId: '0', label: 'New Jersey' },
	{ worldValue: 'NL', somosValue: 'NF', iso2: 'CA', pointId: '2', label: 'Newfoundland' },
	{ worldValue: 'NM', somosValue: 'NM', iso2: 'US', pointId: '0', label: 'New Mexico' },
	{ worldValue: 'NS', somosValue: 'NS', iso2: 'CA', pointId: '2', label: 'Nova Scotia' },
	{ worldValue: 'NT', somosValue: 'NT', iso2: 'CA', pointId: '2', label: 'Northwest Territory' },
	{ worldValue: 'NU', somosValue: 'VU', iso2: 'CA', pointId: '2', label: 'Nunavut Territory' },
	{ worldValue: 'NV', somosValue: 'NV', iso2: 'US', pointId: '0', label: 'Nevada' },
	{ worldValue: 'NY', somosValue: 'NY', iso2: 'US', pointId: '0', label: 'New York' },
	{ worldValue: 'OH', somosValue: 'OH', iso2: 'US', pointId: '0', label: 'Ohio' },
	{ worldValue: 'OK', somosValue: 'OK', iso2: 'US', pointId: '0', label: 'Oklahoma' },
	{ worldValue: 'ON', somosValue: 'ON', iso2: 'CA', pointId: '2', label: 'Ontario' },
	{ worldValue: 'OR', somosValue: 'OR', iso2: 'US', pointId: '0', label: 'Oregon' },
	{ worldValue: 'PA', somosValue: 'PA', iso2: 'US', pointId: '0', label: 'Pennsylvania' },
	{ worldValue: 'PE', somosValue: 'PE', iso2: 'CA', pointId: '2', label: 'Prince Edward Island' },
	{ worldValue: 'PR', somosValue: 'PR', iso2: 'PR', pointId: '4', label: 'Puerto Rico' },
	{ worldValue: 'QC', somosValue: 'PQ', iso2: 'CA', pointId: '2', label: 'Quebec' },
	{ worldValue: 'RI', somosValue: 'RI', iso2: 'US', pointId: '0', label: 'Rhode Island' },
	{ worldValue: 'RT', somosValue: 'RT', iso2: 'MS', pointId: '4', label: 'Montserrat' },
	{ worldValue: 'SA', somosValue: 'SA', iso2: 'LC', pointId: '4', label: 'Saint Lucia' },
	{ worldValue: 'SC', somosValue: 'SC', iso2: 'US', pointId: '0', label: 'South Carolina' },
	{ worldValue: 'SD', somosValue: 'SD', iso2: 'US', pointId: '0', label: 'South Dakota' },
	{ worldValue: 'SF', somosValue: 'SF', iso2: 'SX', pointId: '4', label: 'Sint Maarten (Dutch)' },
	{ worldValue: 'SK', somosValue: 'SK', iso2: 'CA', pointId: '2', label: 'Saskatchewan' },
	{ worldValue: 'TC', somosValue: 'TC', iso2: 'TC', pointId: '4', label: 'Turks and Caicos Islands' },
	{ worldValue: 'TN', somosValue: 'TN', iso2: 'US', pointId: '0', label: 'Tennessee' },
	{ worldValue: 'TR', somosValue: 'TR', iso2: 'TT', pointId: '4', label: 'Trinidad and Tobago' },
	{ worldValue: 'TX', somosValue: 'TX', iso2: 'US', pointId: '0', label: 'Texas' },
	{ worldValue: 'UT', somosValue: 'UT', iso2: 'US', pointId: '0', label: 'Utah' },
	{ worldValue: 'VA', somosValue: 'VA', iso2: 'US', pointId: '0', label: 'Virginia' },
	{ worldValue: 'VI', somosValue: 'VI', iso2: 'VI', pointId: '4', label: 'Virgin Islands (U.S.)' },
	{ worldValue: 'VT', somosValue: 'VT', iso2: 'US', pointId: '0', label: 'Vermont' },
	{ worldValue: 'WA', somosValue: 'WA', iso2: 'US', pointId: '0', label: 'Washington' },
	{ worldValue: 'WI', somosValue: 'WI', iso2: 'US', pointId: '0', label: 'Wisconsin' },
	{ worldValue: 'WV', somosValue: 'WV', iso2: 'US', pointId: '0', label: 'West Virginia' },
	{ worldValue: 'WY', somosValue: 'WY', iso2: 'US', pointId: '0', label: 'Wyoming' },
	{ worldValue: 'YT', somosValue: 'YT', iso2: 'CA', pointId: '2', label: 'Yukon Territory' },
	{ worldValue: 'ZF', somosValue: 'ZF', iso2: 'VC', pointId: '4', label: 'Saint Vincent and the Grenadines' },
];

export enum CprNodeType {
	Announcement = 'AN',
	AreaCode = 'AC',
	Carrier = 'CA',
	Date = 'DT',
	DayOfWeek = 'DA',
	GoTo = 'GT',
	Lata = 'LT',
	NpaNxx = 'NX',
	Percent = 'PC',
	SixDigit = 'SD',
	State = 'ST',
	Switch = 'SW',
	TenDigit = 'TD',
	TerminatingNumber = 'TE',
	Time = 'TI',
}

export enum CprSection {
	CprSectionAdmin = 1,
	CprSectionAos = 2,
	CprSectionAosLabel = 3,
	CprSectionCpr = 4,
	CprSectionCprLabel = 5,
}

export interface ICprLerg {
	cprNodeTypeId: CprNodeType;
	worldValue: string;
	somosValue: string;
	pointId: string;
	state: string;
	lata: string;
	ocn: string;
}

export enum CprStatus {
	Saved = 1,
	Pending = 2,
	Sending = 3,
	Active = 4,
	Old = 5,
	Invalid = 6,
	Disconnect = 7,
	MustCheck = 8,
	Failed = 9,
	Hold = 10,
	Unknown = 99
}

export const CprStatusLabels: { [cprStatus in CprStatus]: string} = {
	[CprStatus.Saved] : 'Saved',
	[CprStatus.Pending]: 'Pending',
	[CprStatus.Sending]: 'Sending',
	[CprStatus.Active]: 'Active',
	[CprStatus.Old]: 'Old',
	[CprStatus.Invalid]: 'Invalid',
	[CprStatus.Disconnect]: 'Disconnect',
	[CprStatus.MustCheck]: 'Must Check',
	[CprStatus.Failed]: 'Failed',
	[CprStatus.Hold]: 'Hold',
	[CprStatus.Unknown]: 'Unknown',
};

/**
 * These currently must be indexed from 'Best' to 'Worst' (Exact = 1)
 */
export enum CprValueHighlightType {
	Exact = 1,
	Parent = 2,
	Child = 4,
}

export interface ICleanConfigRequest {
	removeEmptyLabels: boolean;
	removeUnusedLabels: boolean;
	maxIterations?: number;
}

export interface IValidatorCleanResponse {
	addedQty: number;
	removedQty: number;
	wasRemoved: boolean;
}

export interface ICprCleanResponse {
	//rows
	removedCprRowsQty: number;
	modifiedCprRowsQty: number;
	//labels
	removedCprLabelsQty: number;
	modifiedCprLabelsQty: number;
	//admin
	removedInterCarriersQty: number;
	removedIntraCarriersQty: number;
}

export interface ICprBulkCarrierResponse {
	modifiedCprRowsQty: number;
	modifiedLabelsQty: number;
}

export enum RoutingCacheTypes {
	TollFree = 1,
	Template = 2,
	MgiTask = 3,
	LcrGenerator = 4
}
