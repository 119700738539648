import { SwitchAddressIndexResponse } from 'RtModels';
import { SwitchAddressesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SwitchAddressesResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface SwitchAddressDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		SwitchAddressIndexResponse,
		T
	> {}

export const SwitchAddressDataGridCache =
	new ResourceCacheMap<SwitchAddressIndexResponse>(
		new SwitchAddressesResource(),
		'switchAddressId',
		'label'
	);

export const SwitchAddressDataGridColumn = <T = any,>(
	config: SwitchAddressDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: SwitchAddressDataGridCache,
		...config
	});
};
