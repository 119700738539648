import { RatePlanIndexResponse } from 'RtModels';
import { RatePlanResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/RatePlanResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface RatePlanDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		RatePlanIndexResponse,
		T
	> {}

export const RatePlanDataGridCache =
	new ResourceCacheMap<RatePlanIndexResponse>(
		new RatePlanResource(),
		'ratePlanId',
		'summary'
	);

export const RatePlanDataGridColumn = <T = any,>(
	config: RatePlanDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: RatePlanDataGridCache,
		...config
	});
};
