import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { RtAdminRouter } from 'RtUi/app/rtAdmin/rtAdmin.router';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { IntegrationErrorMessage } from 'RtModels';

interface IIntegrationErrorMessagesContainerTabs
	extends IProfileApplicationContainerTabs {}

export const IntegrationErrorMessagesProfileContainerTabs: IIntegrationErrorMessagesContainerTabs =
	{};

export class IntegrationErrorMessagesRouterClass extends RtAdminRouter<
	IntegrationErrorMessage,
	IntegrationErrorMessage,
	IIntegrationErrorMessagesContainerTabs
> {
	constructor() {
		super(
			'Custom Error Message',
			'integrationErrorMessages',
			'integrationErrorMessageId',
			IntegrationErrorMessagesProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtAdmApiRoutes.IntegrationErrorMessages);
	}

	public getIndexLabel(record: IntegrationErrorMessage) {
		return record.label;
	}

	public getProfileLabel(record: IntegrationErrorMessage) {
		return record.label;
	}
}

export const IntegrationErrorMessagesRouter =
	new IntegrationErrorMessagesRouterClass();
