import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	BlockedNumber,
	BlockedNumberCreateRequest,
	BlockedNumberIndexRequest
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	handlePostRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchBlockedNumbers = async (
	queryParams?: BlockedNumberIndexRequest
): Promise<FullResponse<BlockedNumber[]>> => {
	return handleGetRequest<BlockedNumber[], true>(
		RtxSipApiRoutes.BlockedNumbers.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetBlockedNumbers = (
	queryParams?: BlockedNumberIndexRequest
) => {
	return useQuery<FullResponse<BlockedNumber[]>, Error>(
		[`useGetBlockedNumbers`, queryParams],
		() => fetchBlockedNumbers(queryParams),
		{ enabled: true }
	);
};

export const postBlockedNumbers = (data: BlockedNumberCreateRequest) => {
	return handlePostRequest<BlockedNumber>(
		RtxSipApiRoutes.BlockedNumbers.Create,
		data
	);
};
