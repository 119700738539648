import moment from 'moment';
import { FC, useState } from 'react';
import { useMount } from 'react-use';
import {
	GuardianReconciliationUsageDetailsIndexRequest,
	GuardianReconciliationUsageDetailsIndexResponse,
	GuardianReconciliationUsageDetailsProfileResponse,
	GuardianReconciliationUsageDetailsSummaryIndexResponse
} from 'RtModels';
import { ReconciliationUsageMonthlyChart } from 'RtUi/app/rtVue/ReconciliationUsage/lib/components/ReconciliationUsageMonthlyChart';
import { ReconciliationUsageResource } from 'RtUi/app/rtVue/ReconciliationUsage/lib/resources/ReconciliationUsageDetailsResource';
import { ReconciliationUsageDetailsSummaryResource } from 'RtUi/app/rtVue/ReconciliationUsage/lib/resources/ReconciliationUsageDetailsSummaryResource';
import { calculatePercentVariance } from 'RtUi/app/rtVue/ReconciliationUsage/lib/utils/VarianceHelpers';
import {
	DashboardCard,
	DashboardCardRow,
	DashboardCardStat
} from 'RtUi/components/ui/DashboardCard';
import { safeDivision } from 'RtUi/utils/core/utils';

interface IReconciliationUsageBreakdownProps {
	profile: GuardianReconciliationUsageDetailsProfileResponse;
	className?: string;
}

export const ReconciliationUsageBreakdown: FC<
	React.PropsWithChildren<IReconciliationUsageBreakdownProps>
> = ({ className, profile }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [summaryDetails, setSummaryDetails] = useState<
		GuardianReconciliationUsageDetailsSummaryIndexResponse[]
	>([]);
	const [allMonthsUsage, setAllMonthsUsage] = useState<
		GuardianReconciliationUsageDetailsIndexResponse[]
	>([]);
	const [currentMonthUsage, setCurrentMonthUsage] =
		useState<GuardianReconciliationUsageDetailsIndexResponse>();
	const [comparisonMonthsUsage, setComparisonMonthsUsage] = useState<
		GuardianReconciliationUsageDetailsIndexResponse[]
	>([]);

	useMount(async () => {
		const { reconciliationId } = profile;
		const summaryResource = new ReconciliationUsageDetailsSummaryResource(
			reconciliationId
		);
		const startTs = moment.tz(profile.guardianReconciliationStartTs, 'UTC');
		const fromStartTs = startTs
			.clone()
			.subtract(6, 'months')
			.startOf('month')
			.toDate();
		const endStartTs = startTs.clone().endOf('month').toDate();
		const varianceResourceParams: GuardianReconciliationUsageDetailsIndexRequest =
			{
				fromStartTs,
				endStartTs,
				mappingId: profile.mappingId,
				serviceTypeId: profile.serviceTypeId
			};

		summaryResource.getAll({ reconciliationId }).then(setSummaryDetails);

		const monthlyUsageResource = new ReconciliationUsageResource();
		monthlyUsageResource.getAll(varianceResourceParams).then((records) => {
			//ascending order by startTs
			records.sort((r1, r2) => {
				const r1Start = moment(r1.guardianReconciliationStartTs);
				const r2Start = moment(r2.guardianReconciliationStartTs);

				return r1Start.isAfter(r2Start) ? 1 : -1;
			});

			setAllMonthsUsage(records);

			const previousMonths = records.slice(-4);
			const currentMonthUsage = previousMonths.pop();
			setCurrentMonthUsage(currentMonthUsage);

			setComparisonMonthsUsage(previousMonths); // only do three months back

			setIsLoading(false);
		});
	});

	let totalSrcConnects = 0;
	let costMatchesTenPercentAndOver = 0;
	let totalMatchedConnects = 0;

	for (const summaryDetail of summaryDetails) {
		totalSrcConnects += summaryDetail.srcConnects;
		totalMatchedConnects += summaryDetail.matchedConnects;
		costMatchesTenPercentAndOver += summaryDetail.costMatchVarianceCategoryTen;
		costMatchesTenPercentAndOver +=
			summaryDetail.costMatchVarianceCategoryTwentyFive;
		costMatchesTenPercentAndOver +=
			summaryDetail.costMatchVarianceCategoryHigher;
	}

	const averageMatchPercentage =
		safeDivision(totalMatchedConnects, totalSrcConnects) * 100;

	const costMatchesTenAndOverRatio = safeDivision(
		costMatchesTenPercentAndOver,
		totalMatchedConnects
	);
	const costMatchOverTenPercent = costMatchesTenAndOverRatio * 100;

	//
	//

	let largestVariance = 0;
	let largestVarianceName = '';
	let monthlyConnectsVariance = 0;
	let monthlyChargeVariance = 0;
	let monthlyDurationVariance = 0;

	if (currentMonthUsage && comparisonMonthsUsage.length > 0) {
		let previousMonthsTotalConnect = 0;
		let previousMonthsTotalCharges = 0;
		let previousMonthsTotalDuration = 0;

		for (const previousMonthUsage of comparisonMonthsUsage) {
			previousMonthsTotalConnect += previousMonthUsage.rtSrcConnects ?? 0;
			previousMonthsTotalCharges += previousMonthUsage.rtSrcCharge ?? 0;
			previousMonthsTotalDuration += previousMonthUsage.rtSrcDuration ?? 0;
		}

		const previousMonthsAvgConnect =
			previousMonthsTotalConnect / comparisonMonthsUsage.length;
		const previousMonthsAvgCharges =
			previousMonthsTotalCharges / comparisonMonthsUsage.length;
		const previousMonthsAvgDuration =
			previousMonthsTotalDuration / comparisonMonthsUsage.length;

		monthlyConnectsVariance = calculatePercentVariance(
			currentMonthUsage.rtSrcConnects,
			previousMonthsAvgConnect
		);

		monthlyChargeVariance = calculatePercentVariance(
			currentMonthUsage.rtSrcCharge,
			previousMonthsAvgCharges
		);

		monthlyDurationVariance = calculatePercentVariance(
			currentMonthUsage.rtSrcDuration,
			previousMonthsAvgDuration
		);

		largestVariance = Math.max(
			monthlyConnectsVariance,
			monthlyChargeVariance,
			monthlyDurationVariance
		);

		if (largestVariance === monthlyConnectsVariance) {
			largestVarianceName = 'Connects';
		} else if (largestVariance === monthlyDurationVariance) {
			largestVarianceName = 'Duration';
		} else if (largestVariance === monthlyChargeVariance) {
			largestVarianceName = 'Charges';
		}
	}

	//
	//
	//

	const targetCpm = safeDivision(
		profile.rtTgtCharge ?? 0,
		profile.rtTgtDuration ?? 0
	);
	const sourceCpm = safeDivision(
		profile.rtSrcCharge ?? 0,
		profile.rtSrcDuration ?? 0
	);

	const cpmPercentDiff = calculatePercentVariance(targetCpm, sourceCpm);

	//
	//
	//

	const internalConnectsAuditVariance = calculatePercentVariance(
		profile.customerExpectedConnects,
		profile.rtSrcConnects
	);

	const internalDurationAuditVariance = calculatePercentVariance(
		profile.customerExpectedDuration,
		profile.rtSrcDuration
	);

	const internalChargesAuditVariance = calculatePercentVariance(
		profile.customerExpectedCharge,
		profile.rtSrcCharge
	);

	let largestInternalAuditVariance = internalConnectsAuditVariance;
	let largestInternalAuditVarianceName = 'Connects';

	if (internalDurationAuditVariance > largestInternalAuditVariance) {
		largestInternalAuditVariance = internalDurationAuditVariance;
		largestInternalAuditVarianceName = 'Duration';
	}

	if (internalChargesAuditVariance > largestInternalAuditVariance) {
		largestInternalAuditVariance = internalChargesAuditVariance;
		largestInternalAuditVarianceName = 'Charges';
	}

	//
	//
	//

	const externalConnectsAuditVariance = calculatePercentVariance(
		profile.carrierInvoiceConnects,
		profile.rtTgtConnects
	);

	const externalDurationAuditVariance = calculatePercentVariance(
		profile.carrierInvoiceDuration,
		profile.rtTgtDuration
	);

	const externalChargesAuditVariance = calculatePercentVariance(
		profile.carrierInvoiceCharge,
		profile.rtTgtCharge
	);

	let largestExternalAuditVariance = externalConnectsAuditVariance;
	let largestExternalAuditVarianceName = 'Connects';

	if (externalDurationAuditVariance > largestExternalAuditVariance) {
		largestExternalAuditVariance = externalDurationAuditVariance;
		largestExternalAuditVarianceName = 'Duration';
	}

	if (externalChargesAuditVariance > largestExternalAuditVariance) {
		largestExternalAuditVariance = externalChargesAuditVariance;
		largestExternalAuditVarianceName = 'Charges';
	}

	return (
		<section className={className}>
			<DashboardCard>
				<DashboardCardRow>
					<DashboardCardStat
						isLoading={isLoading}
						header="CDR Match %"
						subheader={`Avg ${averageMatchPercentage.toLocaleString()}%`}
						variant={
							averageMatchPercentage >= 97
								? 'success'
								: averageMatchPercentage <= 90
									? 'danger'
									: 'warning'
						}
					/>
					<DashboardCardStat
						isLoading={isLoading}
						header="Monthly Variance"
						subheader={
							comparisonMonthsUsage.length <= 0
								? 'Not enough data'
								: `${largestVarianceName} Variance: ${largestVariance.toLocaleString()}%`
						}
						variant={
							largestVariance <= 25
								? 'success'
								: largestVariance <= 100
									? 'warning'
									: 'danger'
						}
						tooltip={
							<section className="text-start">
								<h6>Average of the last 3 months compared to current month</h6>
								<ul className="list-unstyled">
									<li>
										Charges Variance: {monthlyChargeVariance.toLocaleString()}%
									</li>
									<li>
										Duration Variance:{' '}
										{monthlyDurationVariance.toLocaleString()}%
									</li>
									<li>
										Connects Variance:{' '}
										{monthlyConnectsVariance.toLocaleString()}%
									</li>
								</ul>
							</section>
						}
					/>
				</DashboardCardRow>
				<DashboardCardRow>
					<DashboardCardStat
						isLoading={isLoading}
						header="Average CPM"
						variant={
							cpmPercentDiff < 5
								? 'success'
								: cpmPercentDiff <= 10
									? 'warning'
									: 'danger'
						}
						subheader={
							<span className="d-flex">
								<span>Source: {sourceCpm.toFixed(6)}</span>
								<span className="mx-2">·</span>
								<span>Target: {targetCpm.toFixed(6)}</span>
							</span>
						}
					/>
					<DashboardCardStat
						isLoading={isLoading}
						variant={costMatchOverTenPercent < 5 ? 'success' : 'warning'}
						header="Cost Variance in Matched CDRs"
						subheader={`${Math.round(
							costMatchOverTenPercent
						).toLocaleString()}% of matched calls had a variance greater than 10%`}
					/>
				</DashboardCardRow>
				<DashboardCardRow>
					<DashboardCardStat
						isLoading={isLoading}
						header="Invoice Audit Variance"
						variant={largestExternalAuditVariance < 1 ? 'success' : 'warning'}
						subheader={
							largestExternalAuditVariance < 1
								? 'Normal'
								: `${largestExternalAuditVarianceName} Variance: ${largestExternalAuditVariance.toLocaleString()}%`
						}
						tooltip={
							<ul className="list-unstyled text-start">
								<li>
									Charges Variance:{' '}
									{externalChargesAuditVariance.toLocaleString()}%
								</li>
								<li>
									Duration Variance:{' '}
									{externalDurationAuditVariance.toLocaleString()}%
								</li>
								<li>
									Connects Variance:{' '}
									{externalConnectsAuditVariance.toLocaleString()}%
								</li>
							</ul>
						}
					/>
					<DashboardCardStat
						isLoading={isLoading}
						header="Expected Audit Variance"
						variant={
							largestInternalAuditVariance < 5
								? 'success'
								: largestInternalAuditVariance < 10
									? 'warning'
									: 'danger'
						}
						subheader={
							largestInternalAuditVariance < 5
								? 'Normal'
								: `${largestInternalAuditVarianceName} Variance: ${largestInternalAuditVariance.toLocaleString()}%`
						}
						tooltip={
							<section className="text-start">
								<h6>Expected totals from external CDR management tool</h6>
								<ul className="list-unstyled">
									<li>
										Charges Variance:{' '}
										{internalChargesAuditVariance.toLocaleString()}%
									</li>
									<li>
										Duration Variance:{' '}
										{internalDurationAuditVariance.toLocaleString()}%
									</li>
									<li>
										Connects Variance:{' '}
										{internalConnectsAuditVariance.toLocaleString()}%
									</li>
								</ul>
							</section>
						}
					/>
				</DashboardCardRow>
			</DashboardCard>
			<ReconciliationUsageMonthlyChart
				className="mt-5"
				records={allMonthsUsage}
			/>
		</section>
	);
};
