import { SubscriptionIndexRequest, SubscriptionIndexResponse } from 'RtModels';
import { SubscriptionRouter } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.router';
import { useGetSubscriptions } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRatePlanSubscriptionsColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/ratePlans';
import { useMemo } from 'react';

interface IRatePlanSubscriptionsDataGridProps {
	resourceParams: SubscriptionIndexRequest;
}

export const RatePlanSubscriptionsDataGrid = ({
	resourceParams
}: IRatePlanSubscriptionsDataGridProps) => {
	const { data, isFetching: isLoading } = useGetSubscriptions(resourceParams);
	const columns = useMemo(() => getRatePlanSubscriptionsColumns(), []);

	return (
		<DataGrid<SubscriptionIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'accountManagement_ratePlanSubscriptions'}
			router={SubscriptionRouter}
			disableExport
		/>
	);
};
