import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationChargeDetailIndexResponse,
	GuardianReconciliationChargeDetailProfileResponse
} from 'RtModels';
import { GuardianReconciliationsRouter } from 'RtUi/app/rtVue/GuardianReconciliation/GuardianReconciliation.router';
import { GuardianReconciliationResource } from 'RtUi/app/rtVue/GuardianReconciliation/lib/resources/GuardianReconciliationResource';
import { IRouterBreadcrumb } from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';

interface IReconciliationChargesDetailsProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const ReconciliationChargesDetailsProfileContainerTabs: IReconciliationChargesDetailsProfileContainerTabs =
	{
		Profile: {
			header: 'Profile'
		}
	};

export class ReconciliationChargesRouterClass extends RtVueModuleRouter<
	GuardianReconciliationChargeDetailIndexResponse,
	GuardianReconciliationChargeDetailProfileResponse,
	IReconciliationChargesDetailsProfileContainerTabs
> {
	constructor() {
		super(
			'Reconciliation Charges',
			'ReconciliationChargesDetails',
			'guardianReconciliationChargeDetailId',
			ReconciliationChargesDetailsProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(
			RtVueApiRoutes.GuardianReconciliationChargeDetail
		);
	}

	public async getBreadcrumbs(
		currentPath: string,
		profile?: GuardianReconciliationChargeDetailProfileResponse
	): Promise<IRouterBreadcrumb[]> {
		const reconciliationId = this.getReconciliationIdFromCurrentPath();

		const resource = new GuardianReconciliationResource();
		const reconciliation = await resource.get(reconciliationId);
		const crumb = GuardianReconciliationsRouter.getProfileBreadcrumb(
			String(reconciliationId),
			reconciliation,
			false,
			'Charges'
		);

		crumb.name = 'Charges Details';

		const breadcrumbs = [
			this.getSectionBreadcrumb(),
			GuardianReconciliationsRouter.getIndexBreadcrumb(),
			GuardianReconciliationsRouter.getProfileBreadcrumb(
				String(reconciliationId),
				reconciliation,
				false,
				'Profile'
			),
			crumb,
			this.getProfileBreadcrumb(
				String(profile?.guardianReconciliationChargeDetailId),
				profile,
				true
			)
		];

		return breadcrumbs;
	}

	public getProfileLabel(
		profile: GuardianReconciliationChargeDetailProfileResponse
	) {
		return profile.guardianReconciliationChargeDetailId.toString();
	}

	public getProfileRouteWithIds(
		guardianReconciliationId: number | string = ':guardianReconciliationId',
		usageId: number | string = ':id',
		tabId?: string,
		prefixSectionKey = true
	) {
		const route = super.getIndexRoute(prefixSectionKey);

		return this.getRouteWithTab(
			`${route}/${guardianReconciliationId}/${usageId}`,
			tabId
		);
	}

	public getProfileRoute(
		record: GuardianReconciliationChargeDetailIndexResponse,
		tabId?: string,
		prefixSectionKey = true
	) {
		return this.getProfileRouteWithIds(
			record.reconciliationId,
			record.guardianReconciliationChargeDetailId,
			tabId,
			prefixSectionKey
		);
	}

	protected getReconciliationIdFromCurrentPath() {
		const currentPath = location.pathname;
		const profilePath = this.getProfileMatch<{
			id: string;
			guardianReconciliationId: string;
		}>(currentPath);
		let guardianReconciliationId: number | undefined;

		if (profilePath) {
			const { guardianReconciliationId: guardianReconciliationIdStr } =
				profilePath.params;
			guardianReconciliationId = Number(guardianReconciliationIdStr);
		}

		return guardianReconciliationId;
	}
}

export const ReconciliationChargesRouter =
	new ReconciliationChargesRouterClass();
