import {
	AccountIndexResponse,
	CountryIndexResponse,
	RoutingSummaryIndexRequest,
	SubscriptionIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { RoutingSummaryRouter } from 'RtUi/app/rtSip/RoutingSummary/RoutingSummary.router';
import { RoutingSummaryDataGrid } from 'RtUi/app/rtSip/RoutingSummary/lib/grids/RoutingSummaryDataGrid';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

interface IRoutingSummaryIndexContainerState
	extends ISearchResultsContainerState<RoutingSummaryIndexRequest> {
	startDate?: moment.Moment;
	endDate?: moment.Moment;
	countries?: CountryIndexResponse[];
	costAccounts?: AccountIndexResponse[];
	costSubscriptions?: SubscriptionIndexResponse[];
	revenueAccounts?: AccountIndexResponse[];
	revenueSubscriptions?: SubscriptionIndexResponse[];
}

@RoutingSummaryRouter.getBusinessReportIndexRtUiController()
export class RoutingSummaryIndexContainer extends SearchResultsContainer<
	{},
	{},
	IRoutingSummaryIndexContainerState,
	RoutingSummaryIndexRequest
> {
	public state: IRoutingSummaryIndexContainerState = {
		activeTab: this.tabs.Search,
		resourceParams: {
			startDate: new Date(),
			endDate: new Date()
		},
		startDate: moment().startOf('day'),
		endDate: moment().endOf('day')
	};

	public Tabs = RoutingSummaryRouter.getProfileTabs();

	public initParams = {};

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const {
			startDate,
			endDate,
			costAccounts,
			costSubscriptions,
			revenueSubscriptions,
			revenueAccounts,
			countries
		} = this.state;

		const params: RoutingSummaryIndexRequest = {
			startDate: startDate!.format('YYYY-MM-DD HH:mm:ss') as any,
			endDate: endDate!.format('YYYY-MM-DD HH:mm:ss') as any
		};

		if (costAccounts) {
			params.costAccountId = costAccounts.map((customer) => customer.accountId);
		}

		if (costSubscriptions) {
			params.costSubscriptionId = costSubscriptions.map(
				(subscription) => subscription.subscriptionId
			);
		}

		if (revenueSubscriptions) {
			params.revenueSubscriptionId = revenueSubscriptions.map(
				(subscription) => subscription.subscriptionId
			);
		}

		if (revenueAccounts) {
			params.revenueAccountId = revenueAccounts.map(
				(customer) => customer.accountId
			);
		}

		if (countries) {
			params.destinationCountries = countries.map(
				(country) => country.iso3166Alpha3
			);
		}

		return params;
	}

	public render() {
		return (
			<TabbedLayout
				router={RoutingSummaryRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm wide onSubmit={(e) => this.submitAndGoToResults(e)}>
						<Row>
							<Col md={6}>
								<DatetimeFormControl
									required
									label="Start Date"
									showDateOnly={true}
									value={this.state.startDate}
									onChange={(startDate) =>
										this.setState({
											startDate: moment(startDate)
												.set(`hour`, 0)
												.set('minutes', 0)
												.set('seconds', 0)
										})
									}
								/>
								<DatetimeFormControl
									required
									label="End Date"
									showDateOnly={true}
									value={this.state.endDate}
									onChange={(endDate) =>
										this.setState({
											endDate: moment(endDate).endOf('day')
										})
									}
								/>
								<CountrySelect<true>
									isMulti
									label="Countries"
									value={this.state.countries}
									onChange={(countries) => this.setState({ countries })}
								/>
							</Col>
							<Col md={6}>
								<AccountSelect<true>
									label="Cost accounts"
									value={this.state.costAccounts}
									onChange={(costAccounts) => this.setState({ costAccounts })}
									multi
								/>
								<SubscriptionSelect<true>
									label="Cost subscriptions"
									value={this.state.costSubscriptions}
									onChange={(costSubscriptions) =>
										this.setState({ costSubscriptions })
									}
									isMulti
								/>
								<AccountSelect<true>
									label="Revenue accounts"
									value={this.state.revenueAccounts}
									onChange={(revenueAccounts) =>
										this.setState({ revenueAccounts })
									}
									multi
								/>
								<SubscriptionSelect<true>
									label="Revenue subscriptions"
									value={this.state.revenueSubscriptions}
									onChange={(revenueSubscriptions) =>
										this.setState({ revenueSubscriptions })
									}
									isMulti
								/>
							</Col>
						</Row>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<RoutingSummaryDataGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
