import { FC, FormEvent, useState } from 'react';
import { GuardianFraudListCreateRequest } from 'RtModels';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { FraudConfigurationListResource } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/resources/FraudConfigurationListResource';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { TrunkGroupSelect } from 'RtUi/app/rtVue/Reconciliation/lib/components/TrunkGroupSelect';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

import ProperInput from 'RtUi/components/form/ProperInput';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IFraudConfigurationListEditorProps {
	onSuccess?: () => void;
	onCancel?: () => void;
	editMode?: GuardianFraudListCreateRequest;
	block: boolean;
	global: boolean;
	guardianFraudListId?: number;
}

export enum FraudDataSourceField {
	Subscription = 'Subscription',
	TrunkGroup = 'Trunk Group'
}

export enum FraudDestinationType {
	DialCode = 'DialCode',
	CountryCode = 'CountryCode',
	RateCenter = 'RateCenter'
}

export const FraudConfigurationListEditor: FC<
	React.PropsWithChildren<IFraudConfigurationListEditorProps>
> = ({
	onSuccess = () => ({}),
	onCancel = () => ({}),
	editMode,
	guardianFraudListId,
	global,
	block
}) => {
	const resource = new FraudConfigurationListResource();
	const [destinationType, setDestinationType] =
		useState<ISimpleSelectFormControlOption<FraudDestinationType>>();
	const [dataSourceField, setDataSourceField] =
		useState<ISimpleSelectFormControlOption<FraudDataSourceField>>();
	const [dataSourceValue, setDataSourceValue] = useState<any>();
	const [destinationValue, setDestinationValue] = useState<any>();

	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		editMode,
		createNew: () => resource.createNewProfile(Number(global), Number(block))
	});
	const { workingCopy } = rtUiFormState;

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			if (editMode) {
				await resource.update(guardianFraudListId!, workingCopy);
			} else {
				await resource.create(workingCopy);
			}
			rtUiFormMethods.setIsSubmitting(false);
			onSuccess();
		} catch (err) {
			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setError(err);
		}
	};

	const renderDataSourceSelect = () => {
		switch (workingCopy.dataSourceField) {
			case FraudDataSourceField.Subscription:
				return (
					<SubscriptionSelect
						initialOptionId={Number(workingCopy.dataSourceValue)}
						value={dataSourceValue}
						onChange={(dataSourceValue) => {
							setDataSourceValue(dataSourceValue);
							rtUiFormMethods.setWorkingCopy({
								dataSourceValue: String(dataSourceValue.subscriptionId)
							});
						}}
					/>
				);
			case FraudDataSourceField.TrunkGroup:
				return (
					<TrunkGroupSelect
						required
						initialOptionId={String(workingCopy.dataSourceValue)}
						value={dataSourceValue}
						onChange={(dataSourceValue) => {
							setDataSourceValue(dataSourceValue);
							rtUiFormMethods.setWorkingCopy({
								dataSourceValue: String(dataSourceValue.trunkGroupId)
							});
						}}
					/>
				);
		}
	};

	const renderDestinationValueSelect = () => {
		switch (workingCopy.destinationType) {
			case FraudDestinationType.DialCode:
				return (
					<ProperInput
						label="Dial code"
						value={destinationValue}
						onChange={(value) => {
							setDestinationValue(value);
							rtUiFormMethods.setWorkingCopy({
								destinationValue: value
							});
						}}
					/>
				);
			case FraudDestinationType.CountryCode:
				return (
					<CountrySelect
						required
						initialOptionId={String(workingCopy.destinationValue)}
						value={destinationValue}
						onChange={(value) => {
							setDestinationValue(value);
							rtUiFormMethods.setWorkingCopy({
								destinationValue: value.iso3166Alpha3
							});
						}}
					/>
				);
			case FraudDestinationType.RateCenter:
				return (
					<RateCenterSelect
						required
						label="Rate Center"
						initialOptionId={String(workingCopy.destinationValue)}
						value={destinationValue}
						onChange={(value) => {
							setDestinationValue(value);
							rtUiFormMethods.setWorkingCopy({
								destinationValue: String(value.rateCenterId)
							});
						}}
					/>
				);
			default:
				return (
					<InputFormControl
						required
						label="Destination Value"
						value={workingCopy.destinationValue ?? ''}
						onChange={(destinationValue) => {
							rtUiFormMethods.setWorkingCopy({
								destinationValue
							});
						}}
					/>
				);
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			displayMode={false}
			onSubmit={onSubmit}
			onCancel={() => {
				rtUiFormMethods.onCancel();
				onCancel();
			}}
		>
			<InputFormControl
				label="Summary"
				value={workingCopy.summary ?? ''}
				onChange={(summary) => {
					rtUiFormMethods.setWorkingCopy({ summary });
				}}
			/>
			{!global && (
				<>
					<SimpleSelectFormControl<FraudDataSourceField>
						required
						label="Data Source"
						initialOptionId={String(workingCopy.dataSourceField)}
						value={dataSourceField}
						onChange={(dataSourceFieldSelectValue) => {
							setDataSourceField(dataSourceFieldSelectValue);
							rtUiFormMethods.setWorkingCopy({
								dataSourceField: dataSourceFieldSelectValue.value
							});
						}}
						options={[
							{
								value: FraudDataSourceField.TrunkGroup,
								label: 'Trunk Group'
							},
							{
								value: FraudDataSourceField.Subscription,
								label: 'Subscription'
							}
						]}
					/>
					{renderDataSourceSelect()}
				</>
			)}
			<SimpleSelectFormControl<FraudDestinationType>
				label="Destination Type"
				initialOptionId={String(workingCopy.destinationType)}
				value={destinationType}
				onChange={(selectedItem) => {
					if (selectedItem.value !== destinationType?.value) {
						setDestinationValue(undefined);
					}
					setDestinationType(selectedItem);
					rtUiFormMethods.setWorkingCopy({
						destinationType: selectedItem.value
					});
				}}
				options={[
					{
						value: FraudDestinationType.DialCode,
						label: 'Dial Code'
					},
					{
						value: FraudDestinationType.CountryCode,
						label: 'Country Code'
					},
					{
						value: FraudDestinationType.RateCenter,
						label: 'Rate Center'
					}
				]}
			/>
			{renderDestinationValueSelect()}
			<IsActiveRadioFormControl
				onChange={(isActive) => {
					rtUiFormMethods.setWorkingCopy({ isActive });
				}}
				hideBothOption
				value={workingCopy.isActive ?? true}
			/>
		</RtUiForm>
	);
};
