/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FinancialIndexResponseData = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        hour: {
            type: 'string',
            isRequired: true,
        },
        minute: {
            type: 'string',
            isRequired: true,
        },
        ingressConnectionId: {
            type: 'number',
            isRequired: true,
        },
        customerAccountId: {
            type: 'number',
        },
        customerSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        ingressTrunkGroup: {
            type: 'string',
            isRequired: true,
        },
        ingressTrunkGroupLabel: {
            type: 'string',
            isRequired: true,
        },
        ingressRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        egressConnectionId: {
            type: 'number',
            isRequired: true,
        },
        vendorAccountId: {
            type: 'number',
        },
        vendorSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        egressTrunkGroup: {
            type: 'string',
            isRequired: true,
        },
        egressTrunkGroupLabel: {
            type: 'string',
            isRequired: true,
        },
        countTokens: {
            type: 'number',
        },
        egressRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        dialCode: {
            type: 'number',
            isRequired: true,
        },
        jurisdiction: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        attestation: {
            type: 'string',
            isRequired: true,
        },
        callCount: {
            type: 'number',
            isRequired: true,
        },
        duration: {
            type: 'number',
            isRequired: true,
        },
        revenue: {
            type: 'number',
            isRequired: true,
        },
        cost: {
            type: 'number',
            isRequired: true,
        },
        margin: {
            type: 'number',
            isRequired: true,
        },
        negCallCount: {
            type: 'number',
            isRequired: true,
        },
        negDuration: {
            type: 'number',
            isRequired: true,
        },
        negRevenue: {
            type: 'number',
            isRequired: true,
        },
        negCost: {
            type: 'number',
            isRequired: true,
        },
        negMargin: {
            type: 'number',
            isRequired: true,
        },
        fileStreamId: {
            type: 'number',
        },
        marginPercent: {
            type: 'number',
            isRequired: true,
        },
        ocn: {
            type: 'string',
        },
        ocnType: {
            type: 'string',
        },
        company: {
            type: 'string',
        },
        commonName: {
            type: 'string',
        },
        customerExternalAccountId: {
            type: 'string',
        },
        customerExternalSubAccountId: {
            type: 'string',
        },
        vendorExternalAccountId: {
            type: 'string',
        },
        vendorExternalSubAccountId: {
            type: 'string',
        },
        ingressCountry: {
            type: 'string',
        },
        egressCountry: {
            type: 'string',
        },
    },
};