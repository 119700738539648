import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class CprLblLata extends CprLbl {

	public readonly cprNodeTypeId = CprNodeType.Lata;

	public readonly valueLimit = 255;
	public readonly valueRegExp = /^\d{3}$/;

	protected readonly possiblesByKey = CprLergCache.CprLergByLata;
	protected readonly possibles = Object.keys(CprLergCache.CprLergByLata);

	protected readonly canadaPossibles = CprLergCache.CanadaLatas;
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaLatasByKey;

	protected readonly canLabel = true;

}
