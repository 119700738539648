import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	CdrExportCreateRequest,
	CdrExportIndexResponse,
	CdrExportSourceTypes
} from 'RtModels';

export class CdrOneTimeExportResource extends HttpResource<CdrExportIndexResponse> {
	constructor() {
		super(RtxSipApiRoutes.CdrExport);
	}

	public create(
		cdrExportTypeId: number,
		isConnected: number,
		cdrRetentionId: number,
		begTs: Date,
		endTs: Date,
		cdrSourceTypeId?: CdrExportSourceTypes,
		cdrSourceValue?: number
	) {
		const req: CdrExportCreateRequest = {
			cdrExportTypeId,
			cdrSourceTypeId: cdrSourceTypeId ?? null,
			cdrSourceValue: cdrSourceValue ?? null,
			isConnected,
			cdrRetentionId,
			begTs,
			endTs
		};

		const body = JSON.stringify(req);

		return this.fetchWithRoute<CdrExportIndexResponse>(
			RtxSipApiRoutes.CdrExport.Create,
			{ body }
		);
	}
}
