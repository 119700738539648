import clsx from 'clsx';
import { cloneDeep } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	Attestation,
	CallTypeIndexResponse,
	ConnectionIndexResponse,
	CountryIndexResponse,
	DataFilter,
	DataFilterOperator,
	DataSources,
	FileStreamIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { AttestationSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/AttestationSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { CallTypesSelect } from 'RtUi/app/rtVue/Connections/lib/controls/CallTypesSelect';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import { TrunkGroupSelect } from 'RtUi/app/rtVue/Reconciliation/lib/components/TrunkGroupSelect';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	IDefaultSelectOption,
	SelectFormControl
} from 'RtUi/components/ui/SelectFormControl';

interface IExpectedParametersControlProps {
	dataFilters?: string[];
	value: DataFilter;
	onChange: (newValue: DataFilter) => void;
	isDisabled?: boolean;
	hideLabel?: boolean;
	className?: string;
}

export const createOptionsFromList = (
	values: Array<string | string[]> = []
) => {
	return values.map((val) => {
		if (Array.isArray(val)) {
			return {
				label: val[0],
				value: val[1]
			};
		} else {
			return {
				label: val,
				value: val
			};
		}
	});
};

export const ParametersFilteringControl = ({
	className,
	hideLabel,
	dataFilters,
	value,
	isDisabled,
	onChange
}: IExpectedParametersControlProps) => {
	const [filterValue, setFilterValue] = useState<DataFilter>(value);
	const [subscriptions, setSubscriptions] =
		useState<SubscriptionIndexResponse[]>();
	const [callTypes, setCallTypes] = useState<CallTypeIndexResponse[]>();
	const [trunkGroups, setTrunkGroups] = useState<ConnectionIndexResponse[]>();
	const [attestations, setAttestations] = useState<Attestation[]>();
	const [countries, setCountries] = useState<CountryIndexResponse[]>();
	const [fileStreams, setFileStreams] = useState<FileStreamIndexResponse[]>();
	const [selectedCriteria, setSelectedCriteria] =
		useState<IDefaultSelectOption<DataFilterOperator>>();
	const [selectedDataFilter, setSelectedDataFilter] =
		useState<IDefaultSelectOption<string>>();

	const dataSource = useMemo(
		() =>
			selectedDataFilter?.value.toLowerCase().includes('subscription')
				? DataSources.Subscriptions
				: DataSources.Text,
		[selectedDataFilter]
	);

	const onChangeFilterValueHandler = (value: string[]) => {
		setFilterValue((currentState) => {
			const newState = cloneDeep(currentState);
			newState.operands = value.map((fieldValue) => ({
				dataSource,
				value: fieldValue
			}));

			onChange(newState);
			return newState;
		});
	};

	const onChangeSelectedDataFilterHandler = (value: string) => {
		setFilterValue((currentState) => {
			const newState = cloneDeep(currentState);
			newState.key = value;
			newState.operands = [];

			onChange(newState);
			return newState;
		});
	};

	const onChangeSelectedCriteriaOperatorHandler = (
		value: DataFilterOperator
	) => {
		setFilterValue((currentState) => {
			const newState = cloneDeep(currentState);
			newState.operator = value;

			onChange(newState);
			return newState;
		});
	};

	const renderValueControl = () => {
		const isSubscription = selectedDataFilter?.value
			?.toLowerCase()
			//cSpell:disable-next-line
			.includes('subscriptionid');

		const isCallType = selectedDataFilter?.value
			?.toLocaleLowerCase()
			//cSpell:disable-next-line
			.includes('calltype');

		const isTrunkGroup = selectedDataFilter?.value
			?.toLocaleLowerCase()
			.includes('trunkgroup');

		const isAttestation = selectedDataFilter?.value
			?.toLocaleLowerCase()
			.includes('attestation');

		const isFileStream = selectedDataFilter?.value
			?.toLocaleLowerCase()
			//cSpell:disable-next-line
			.includes('filestreamid');

		const isCountry = selectedDataFilter?.value
			?.toLocaleLowerCase()
			//cSpell:disable-next-line
			.includes('country');

		const filterValueInitial = filterValue?.operands.map((op) => op.value);

		if (isFileStream) {
			return (
				<FileStreamSelect<true>
					autoHeight
					displayMode={isDisabled}
					label=""
					hideLabel={hideLabel}
					multi
					initialOptionId={filterValueInitial}
					required
					onChange={(fileStreams) => {
						setFileStreams(fileStreams);
						onChangeFilterValueHandler(
							fileStreams.map((f) => String(f.fileStreamId))
						);
					}}
					value={fileStreams}
					clearable={false}
				/>
			);
		}

		if (isSubscription) {
			return (
				<SubscriptionSelect<true>
					displayMode={isDisabled}
					label=""
					hideLabel={hideLabel}
					isMulti
					initialOptionId={filterValueInitial?.map((v) => Number(v))}
					required
					onChange={(subscriptions) => {
						setSubscriptions(subscriptions);
						onChangeFilterValueHandler(
							subscriptions.map((s) => String(s.subscriptionId))
						);
					}}
					value={subscriptions}
					isClearable={false}
				/>
			);
		}

		if (isCallType) {
			return (
				<CallTypesSelect<true>
					autoHeight
					displayMode={isDisabled}
					label=""
					hideLabel={hideLabel}
					multi
					initialOptionId={filterValueInitial}
					required
					onChange={(callTypes) => {
						setCallTypes(callTypes);
						onChangeFilterValueHandler(callTypes.map((c) => c.callTypeId));
					}}
					value={callTypes}
					clearable={false}
				/>
			);
		}

		if (isTrunkGroup) {
			return (
				<TrunkGroupSelect<true>
					clearable={false}
					displayMode={isDisabled}
					label=""
					multi
					required
					autoHeight
					hideLabel={hideLabel}
					initialOptionId={filterValueInitial}
					onChange={(trunkGroups) => {
						setTrunkGroups(trunkGroups);
						onChangeFilterValueHandler(trunkGroups.map((t) => t.trunkGroupId));
					}}
					value={trunkGroups}
				/>
			);
		}

		if (isAttestation) {
			return (
				<AttestationSelect<true>
					autoHeight
					hideLabel={hideLabel}
					displayMode={isDisabled}
					label=""
					multi
					initialOptionId={filterValueInitial}
					onChange={(attestations) => {
						setAttestations(attestations);
						onChangeFilterValueHandler(attestations);
					}}
					value={attestations}
					required
					clearable={false}
				/>
			);
		}

		if (isCountry) {
			return (
				<CountrySelect<true>
					hideLabel={hideLabel}
					displayMode={isDisabled}
					label=""
					isMulti
					initialOptionId={filterValueInitial}
					onChange={(countries) => {
						setCountries(countries);
						onChangeFilterValueHandler(countries.map((c) => c.iso3166Alpha3));
					}}
					required
					value={countries}
					isClearable={false}
				/>
			);
		}

		return (
			<InputFormControl
				label=""
				autoHeight
				value={filterValueInitial ? filterValueInitial[0] : ''}
				hideLabel={hideLabel}
				disabled={isDisabled}
				onChange={(value) => {
					onChangeFilterValueHandler([value]);
				}}
				displayMode={isDisabled}
			/>
		);
	};

	return (
		<Row className={clsx('row-sm', className)}>
			<Col lg={4}>
				<SelectFormControl<IDefaultSelectOption<string>, 'value'>
					hideLabel={hideLabel}
					label="Criteria"
					labelKey="label"
					valueKey="value"
					options={createOptionsFromList(dataFilters)}
					value={selectedDataFilter}
					onChange={(selectedValue) => {
						setSelectedDataFilter(selectedValue);
						if (filterValue?.key !== selectedValue.value) {
							onChangeSelectedDataFilterHandler(selectedValue.value);
						}
					}}
					required
					initialOptionId={filterValue?.key}
					displayMode={isDisabled}
					isClearable={false}
				/>
			</Col>
			<Col lg={2}>
				<SelectFormControl<IDefaultSelectOption<DataFilterOperator>, 'value'>
					label=""
					labelKey="label"
					valueKey="value"
					hideLabel={hideLabel}
					initialOptionId={filterValue?.operator || DataFilterOperator.In}
					options={[
						{
							label: 'In',
							value: DataFilterOperator.In
						},
						{
							label: 'Not In',
							value: DataFilterOperator.NotIn
						}
					]}
					value={selectedCriteria}
					onChange={(selectedValue) => {
						setSelectedCriteria(selectedValue);
						onChangeSelectedCriteriaOperatorHandler(selectedValue.value);
					}}
					displayMode={isDisabled}
					isClearable={false}
				/>
			</Col>
			<Col lg={6}>{renderValueControl()}</Col>
		</Row>
	);
};
