import { FC, useContext } from 'react';
import { StandardLayoutHelpContainer } from 'RtUi/components/ui/StandardLayout';
import { TabLayoutContext } from 'RtUi/components/ui/TabbedLayout/TabLayoutContext';

export type TabbedLayoutHelpContainerProps = {
	tab: string;
};

export const TabbedLayoutHelpContainer: FC<
	React.PropsWithChildren<TabbedLayoutHelpContainerProps>
> = ({ tab, children }) => {
	const tabLayoutContext = useContext(TabLayoutContext);

	if (tabLayoutContext.activeTabHeader !== tab) {
		return null;
	}

	return <StandardLayoutHelpContainer>{children}</StandardLayoutHelpContainer>;
};
