import { $LcrMinuteCreateRequest, RespOrgIndexResponse } from 'RtModels';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { LcrMinutesCreateDataGrid } from 'RtUi/app/rtLco/Minutes/lib/grids/LcrMinutesCreateDataGrid';
import { TrafficProfileSpreadsheetParser } from 'RtUi/app/rtLco/Minutes/lib/parsers/TrafficProfileSpreadsheetParser';
import {
	LcrMinuteRecordType,
	LcrMinutesResource
} from 'RtUi/app/rtLco/Minutes/lib/resources/LcrMinutesResource';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { FormWizard, FormWizardStep } from 'RtUi/components/form/FormWizard';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtError } from 'RtUi/utils/errors/RtError';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { Component } from 'react';
import { Button, Card, Col, Modal, Row, Table } from 'react-bootstrap';

interface IAddTrafficProfileProps {
	onCancel?: () => void;
	onSave?: () => void;
}

interface IAddTrafficProfileState {
	newMinutesLabel: string;
	newMinutesIsActive: number;
	newMinutesData: LcrMinuteRecordType[];
	processingNewMinutesFile: boolean;
	newMinutesRespOrg?: RespOrgIndexResponse;
	wizardStep: number;
	error?: any;
	file?: File;
	isSubmitting?: boolean;
	isSamplesModalOpen?: boolean;
}

export class AddTrafficProfile extends Component<
	IAddTrafficProfileProps,
	IAddTrafficProfileState
> {
	public initialState: IAddTrafficProfileState = {
		newMinutesLabel: '',
		newMinutesIsActive: 1,
		processingNewMinutesFile: false,
		newMinutesData: [],
		wizardStep: 1,
		isSubmitting: false,
		error: undefined,
		file: undefined,
		newMinutesRespOrg: undefined,
		isSamplesModalOpen: false
	};

	public state = this.initialState;

	public render() {
		return (
			<Row>
				<Col lg={10} xl={8}>
					<Card>
						<FormWizard
							error={this.state.error}
							isSubmitting={this.state.isSubmitting}
							currentStep={this.state.wizardStep}
							onChange={(wizardStep) => this.updateWizardStep(wizardStep)}
						>
							<FormWizardStep step={1} header="Profile">
								<Row>
									<Col lg={{ span: 6, offset: 3 }}>
										<InputFormControl
											label="Label"
											displayMode={false}
											value={this.state.newMinutesLabel}
											onChange={(newMinutesLabel) =>
												this.setState({
													newMinutesLabel
												})
											}
											required={
												$LcrMinuteCreateRequest.properties.label.isRequired
											}
										/>
										<RespOrgSelect
											displayMode={false}
											onChange={(newMinutesRespOrg: RespOrgIndexResponse) =>
												this.setState({
													newMinutesRespOrg
												})
											}
											value={this.state.newMinutesRespOrg}
											required={
												$LcrMinuteCreateRequest.properties.respOrgId.isRequired
											}
										/>
										<IsActiveRadioFormControl
											label="Status"
											hideBothOption
											displayMode={false}
											value={this.state.newMinutesIsActive}
											onChange={(isActive) =>
												this.setState({
													newMinutesIsActive: isActive
												})
											}
											required={
												$LcrMinuteCreateRequest.properties.isActive.isRequired
											}
										/>
									</Col>
								</Row>
							</FormWizardStep>
							<FormWizardStep step={2} header={'Upload'}>
								<div className="text-end">
									<Button
										variant="link"
										onClick={() => this.toggleIsSamplesModalOpen(true)}
									>
										<span className="text-muted">
											<i className="fas fa-fw fa-info-circle" />
											&nbsp;
											<u>Example File</u>
										</span>
									</Button>
								</div>
								<DragAndDropFileUploader
									required
									accept={FileUtils.AcceptTypes.Spreadsheet}
									processing={this.state.processingNewMinutesFile}
									value={this.state.file}
									onDrop={(file) => this.processFile(file)}
								/>
							</FormWizardStep>
							<FormWizardStep
								step={3}
								header={'Review & Submit'}
								disableBody
								onSubmit={() => this.createTrafficProfile()}
							>
								<section className="table-info text-info">
									<section className="p-3 d-flex justify-content-start">
										<article>
											<i className="fas fa-fw fa-info-circle me-2" />
										</article>
										<article>
											Review the traffic profile below before submitting. Upload
											may take several minutes depending on the size of the
											submission.
										</article>
									</section>
								</section>
								<LcrMinutesCreateDataGrid data={this.state.newMinutesData} />
							</FormWizardStep>
						</FormWizard>
						<div className="d-flex justify-content-center">
							<Button
								type="button"
								variant="white"
								className="mb-3 mt-1"
								disabled={this.state.isSubmitting}
								onClick={this.onCancel}
							>
								Cancel
							</Button>
						</div>
					</Card>
					<Modal
						show={this.state.isSamplesModalOpen}
						size="xl"
						onHide={() => this.toggleIsSamplesModalOpen()}
					>
						<Modal.Body className="bg-light rounded">
							<article className="d-flex justify-content-end mb-2">
								<Button
									variant="light"
									onClick={() => this.toggleIsSamplesModalOpen(false)}
								>
									<i className="fas fa-times fa-fw fa-lg" />
								</Button>
							</article>
							{this.renderSampleTable()}
						</Modal.Body>
					</Modal>
				</Col>
			</Row>
		);
	}

	private toggleIsSamplesModalOpen(
		isSamplesModalOpen = !this.state.isSamplesModalOpen
	) {
		this.setState({ isSamplesModalOpen });
	}

	private renderSampleTable() {
		const sampleData: string[][] = [
			[
				'201200',
				'732944',
				'3',
				'5',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'7',
				'2',
				'0'
			],
			[
				'201201',
				'848227',
				'1',
				'5',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'0',
				'1',
				'5',
				'0'
			]
		];

		return (
			<CollapsibleCard
				header="Example of Traffic Profile Spreadsheet"
				className="mb-3"
			>
				<Table size="sm" bordered className="bg-white" responsive>
					<thead>
						<tr>
							<th>aniNpaNxx</th>
							<th>lrnNpaNxx</th>
							<th>interConnects</th>
							<th>interMinutes</th>
							<th>interCharges</th>
							<th>intraConnects</th>
							<th>intraMinutes</th>
							<th>intraCharges</th>
							<th>indetConnects</th>
							<th>indetMinutes</th>
							<th>indetCharges</th>
							<th>totalConnects</th>
							<th>totalMinutes</th>
							<th>totalCharges</th>
						</tr>
					</thead>
					<tbody>
						{sampleData.map((values, index) => (
							<tr key={index}>
								{values.map((value, innerIndex) => (
									<td className="text-monospace" key={innerIndex}>
										{value}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</CollapsibleCard>
		);
	}

	private updateWizardStep(wizardStep: number) {
		this.setState({ wizardStep, isSubmitting: false });
	}

	private async processFile(file: File) {
		const fileUtils = new FileUtils();

		try {
			this.setState({ processingNewMinutesFile: true, error: undefined });

			const spreadsheet = await fileUtils.fileToSpreadsheet(file);

			const trafficProfileParser = new TrafficProfileSpreadsheetParser(
				spreadsheet
			);
			trafficProfileParser.on('error', (eventMessage) => {
				this.setState(() => ({
					error: new RtError({ message: eventMessage })
				}));
			});
			const minutesData = trafficProfileParser.parseTrafficProfileRecords();
			this.setState({
				newMinutesData: minutesData,
				file: file,
				processingNewMinutesFile: false
			});
		} catch (error) {
			this.setState({ error, processingNewMinutesFile: false });
		}
	}

	private async createTrafficProfile() {
		try {
			const { onSave = () => null } = this.props;
			const minutesResource = new LcrMinutesResource();
			this.setState({ isSubmitting: true, error: undefined });
			await minutesResource.create({
				isActive: this.state.newMinutesIsActive,
				label: this.state.newMinutesLabel,
				respOrgId: this.state.newMinutesRespOrg!.respOrgId,
				data: this.state.newMinutesData.map((record) => Object.values(record))
			});
			this.setState(this.initialState, onSave);
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	}

	private onCancel = async () => {
		const { onCancel = () => null } = this.props;
		if (
			this.state.file ||
			this.state.processingNewMinutesFile ||
			this.state.isSubmitting
		) {
			await this.showCancelUploadConfirmation();
		} else {
			this.setState(this.initialState, onCancel);
		}
	};

	private async showCancelUploadConfirmation() {
		const confirm = await Confirmation.create(
			<span>
				<span>
					You have not submitted your traffic profile yet. Are you sure you
					would like to cancel?
				</span>
			</span>,
			{
				confirmationBtnText: 'Yes, Cancel.',
				cancelTextBtnText: 'Go back'
			}
		);

		if (!confirm) {
			return;
		}

		this.setState(this.initialState, this.props.onCancel);
	}
}
