import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	SipAgentStatisticIndexRequest,
	SipAgentStatisticIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchSipAgentStats = (
	queryParams?: SipAgentStatisticIndexRequest
): Promise<FullResponse<SipAgentStatisticIndexResponse[]>> => {
	return handleGetRequest<SipAgentStatisticIndexResponse[], true>(
		RtCommonApiRoutes.SipAgentStatistics.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetSipAgentStats = (
	queryParams?: SipAgentStatisticIndexRequest
) => {
	const cacheKey = JSON.stringify(queryParams);

	return useQuery<
		FullResponse<SipAgentStatisticIndexResponse[]> | undefined,
		Error
	>(
		[`useGetSipAgentStats${cacheKey}`, cacheKey],
		() => fetchSipAgentStats(queryParams),
		{ enabled: true }
	);
};
