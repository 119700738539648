import { TaskResource, ITaskProfile } from '../resources/TaskResource';

export class TaskPollPromiseWrapper {
	public taskResource = new TaskResource();
	private isCancelled = false;
	private pollPromise!: Promise<ITaskProfile | undefined>;

	constructor(protected taskId: number) {
		this.pollPromise = new Promise<ITaskProfile | undefined>(
			(resolve, reject) => {
				const pollTask = async () => {
					if (this.isCancelled) {
						return resolve(undefined);
					}

					try {
						const task = await this.taskResource.get(this.taskId);

						if (this.isCancelled) {
							return resolve(undefined);
						}

						if (task) {
							const isCompleted = Boolean(task.completedAt);

							if (isCompleted) {
								return resolve(task);
							}
						}

						setTimeout(() => pollTask(), 1000);
					} catch (error) {
						reject(error);
					}
				};

				pollTask();
			}
		);
	}

	public getPromise() {
		return this.pollPromise;
	}

	public cancel() {
		this.isCancelled = true;
	}
}
