/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtxApiRoutes } from 'RtExports/routes';
import { UserIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchAdminUsers = async (): Promise<
	FullResponse<UserIndexResponse[]>
> => {
	return handleGetRequest<UserIndexResponse[], true>(RtxApiRoutes.Users.Index, {
		includeFullResponse: true
	});
};

export const useGetAdminUsers = () => {
	return useQuery<FullResponse<UserIndexResponse[]>, Error>(
		'getAdminUsers',
		() => {
			return fetchAdminUsers();
		}
	);
};
