import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	RateLoadIndexRequest,
	RateLoadIndexResponse,
	RateLoadNewRateIndexResponse,
	RateLoadNewRateIndexUrlRequest,
	RateLoadOldRateIndexResponse,
	RateLoadOldRateIndexUrlRequest
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchRateLoads = async (
	queryParams?: RateLoadIndexRequest
): Promise<FullResponse<RateLoadIndexResponse[]>> => {
	return handleGetRequest<RateLoadIndexResponse[], true>(
		RtCommonApiRoutes.RateLoads.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetRateLoads = (queryParams?: RateLoadIndexRequest) => {
	return useQuery<FullResponse<RateLoadIndexResponse[]>, Error>(
		[`useGetRateLoads`, queryParams],
		() => fetchRateLoads(queryParams),
		{ enabled: true }
	);
};

const fetchRateLoadNewRates = async (
	urlParams: RateLoadNewRateIndexUrlRequest
): Promise<FullResponse<RateLoadNewRateIndexResponse[]>> => {
	return handleGetRequest<RateLoadNewRateIndexResponse[], true>(
		RtCommonApiRoutes.RateLoadNewRates.Index,
		{
			includeFullResponse: true,
			urlParams
		}
	);
};

export const useGetRateLoadNewRates = (
	urlParams: RateLoadNewRateIndexUrlRequest
) => {
	return useQuery<FullResponse<RateLoadNewRateIndexResponse[]>, Error>(
		[`useGetRateLoadNewRates`, urlParams],
		() => fetchRateLoadNewRates(urlParams),
		{ enabled: true }
	);
};

const fetchRateLoadOldRates = async (
	urlParams: RateLoadOldRateIndexUrlRequest
): Promise<FullResponse<RateLoadOldRateIndexResponse[]>> => {
	return handleGetRequest<RateLoadOldRateIndexResponse[], true>(
		RtCommonApiRoutes.RateLoadOldRates.Index,
		{
			includeFullResponse: true,
			urlParams
		}
	);
};

export const useGetRateLoadOldRates = (
	urlParams: RateLoadOldRateIndexUrlRequest
) => {
	return useQuery<FullResponse<RateLoadOldRateIndexResponse[]>, Error>(
		[`useGetRateLoadOldRates`, urlParams],
		() => fetchRateLoadOldRates(urlParams),
		{ enabled: true }
	);
};
