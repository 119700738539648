import {
	DataFilter,
	DataFilterOperator,
	DataSources,
	RejectSummaryIndexResponse,
	ReportAggregates,
	Reports,
	TimeFilterDetailKeys,
	TimeRangePresets
} from 'RtModels';
import { RejectDetailsCostReportRouter } from 'RtUi/app/rtVue/RejectDetailsCostReport/RejectDetailsCostReport.router';
import { RejectDetailsRevenueReportRouter } from 'RtUi/app/rtVue/RejectDetailsRevenueReport/RejectDetailsRevenueReport.router';
import { RejectSuspenseReportRouter } from 'RtUi/app/rtVue/RejectSuspenseReport/RejectSuspenseReport.router';
import { RejectDetailsGrid } from 'RtUi/app/rtVue/RejectSuspenseReport/grids/RejectDetailsGrid';
import { convertDateToUtc } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { RtVueReportUrlSearchParamsCreate } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueReportUrlSearchParams';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { endOfToday, endOfYesterday, startOfYesterday } from 'date-fns';
import { isNil } from 'lodash-es';
import moment from 'moment';

interface IRejectSuspenseViewIndexContainerState
	extends IRtVueContainerState<RejectSummaryIndexResponse> {}

@RejectSuspenseReportRouter.getIndexRtUiController({
	groupName: 'Rating',
	orderPriority: 2,
	groupOrderPriority: 30
})
export class RejectSuspenseReportIndexContainer extends RtVueReportContainer<
	RejectSummaryIndexResponse,
	IRejectSuspenseViewIndexContainerState
> {
	public state: IRejectSuspenseViewIndexContainerState = {
		...this.getBaseState({
			defaultTimeRangePreset: TimeRangePresets.ThirtyDays,
			defaultDetailLevel: TimeFilterDetailKeys.Days,
			timeObject: {
				mode: TimeRangePresets.Custom,
				start: this.getStartDateInUtc(startOfYesterday()),
				end: this.getEndDateInUtc(endOfYesterday())
			}
		}),
		maxDate: convertDateToUtc(endOfToday()),
		dataSums: [ReportAggregates.Margin]
	};

	protected reportId = Reports.RejectSummary;
	protected router = RejectSuspenseReportRouter;

	public getStartDateInUtc(date: Date | undefined) {
		return moment(date).utc().toDate();
	}

	public getEndDateInUtc(date: Date | undefined) {
		return moment(date).utc().toDate();
	}

	protected onRevenueDrillDown = (row: RejectSummaryIndexResponse) => {
		this.storeTimeRange(this.state.timeRange);

		const filters: DataFilter[] = [];
		if (!isNil(row.revenue_reject_code)) {
			filters.push({
				key: 'rejectCode',
				operator: DataFilterOperator.Begins,
				operands: [
					{
						dataSource: DataSources.Text,
						value: row.revenue_reject_code.toString()
					}
				]
			});
		}

		if (!isNil(row.calculated_call_type)) {
			filters.push({
				key: 'callType',
				operator: DataFilterOperator.Begins,
				operands: [
					{
						dataSource: DataSources.Text,
						value: row.calculated_call_type.toString()
					}
				]
			});
		}

		const params: RtVueReportUrlSearchParamsCreate = {
			...Object.fromEntries(this.urlSearchParams.getEntries())
		};

		if (this.state.timeRange.detailLevel.key !== TimeFilterDetailKeys.Months) {
			params.date = row.callDate;
		}

		ApplicationContainer.openNewTab(
			RejectDetailsRevenueReportRouter.getDrillDownPath(params, filters)
		);
	};

	protected onCostCountDrillDown = (row: RejectSummaryIndexResponse) => {
		this.storeTimeRange(this.state.timeRange);

		const filters: DataFilter[] = [];
		if (!isNil(row.revenue_reject_code)) {
			filters.push({
				key: 'rejectCode',
				operator: DataFilterOperator.Begins,
				operands: [
					{
						dataSource: DataSources.Text,
						value: row.revenue_reject_code.toString()
					}
				]
			});
		}

		if (!isNil(row.calculated_call_type)) {
			filters.push({
				key: 'callType',
				operator: DataFilterOperator.Begins,
				operands: [
					{
						dataSource: DataSources.Text,
						value: row.calculated_call_type.toString()
					}
				]
			});
		}

		const params: RtVueReportUrlSearchParamsCreate = {
			...Object.fromEntries(this.urlSearchParams.getEntries())
		};

		if (this.state.timeRange.detailLevel.key !== TimeFilterDetailKeys.Months) {
			params.date = row.callDate;
		}

		ApplicationContainer.openNewTab(
			RejectDetailsCostReportRouter.getDrillDownPath(params, filters)
		);
	};

	protected async getResource(): Promise<
		HttpResource<RejectSummaryIndexResponse>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns,
			havingFilter
		} = this.state;
		const vueHttp = new RtVueHttp();
		const { data, totalCount } = await vueHttp.getRejectSummaryView(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			additionalColumns?.map((column) => column.value),
			havingFilter
		);
		const responseResource = new ArrayResource<RejectSummaryIndexResponse>(
			'callDate',
			data
		);

		responseResource.setTotalCount(totalCount);

		return responseResource;
	}

	protected renderReports(): React.ReactNode {
		return (
			<RejectDetailsGrid
				isLoading={this.state.loadingReport}
				onRevenueDrillDown={this.onRevenueDrillDown}
				onCostCountDrillDown={this.onCostCountDrillDown}
				resource={this.state.resource}
				callDateFormat={this.getCallDateFormat()}
			/>
		);
	}
}
