import { useMemo } from 'react';
import {
	MetricsTotalsByDateRequest,
	MetricsTotalsByDateResponse
} from 'RtModels';
import { useGetMetricsByDay } from 'RtUi/app/rtDid/Dashboard/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getMetricsByDayColumns } from 'RtUi/components/data/DataGrid/configurations/rtDid/dashboard';

interface IMetricTotalsByDateGridProps {
	resourceId: number;
	resourceParams?: MetricsTotalsByDateRequest;
	onRowClick?: (row: MetricsTotalsByDateResponse) => void;
	autoFocusFilter?: boolean;
}

export const MetricTotalsByDateGrid = ({
	resourceParams,
	resourceId,
	onRowClick
}: IMetricTotalsByDateGridProps) => {
	const { data, isFetching: isLoading } = useGetMetricsByDay(
		{ resourceId },
		resourceParams
	);
	const columns = useMemo(() => getMetricsByDayColumns(), []);

	return (
		<DataGrid<MetricsTotalsByDateResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			onRowClick={onRowClick}
			pageName={'rtDid_dashboard'}
		/>
	);
};
