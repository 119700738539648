import {
	DataGridColumn,
	FooterProps
} from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { PrecisionDataGridColumnMenuItem } from 'RtUi/components/data/DataGrid/components/PrecisionDataGridColumnMenuItem';
import { PrecisionDataGridRenderer } from 'RtUi/components/data/DataGrid/components/PrecisionDataGridRenderer';
import { sumColumnTotal } from 'RtUi/components/data/DataGrid/utils';
import store from 'RtUi/store';
import { dataGridActions } from 'RtUi/store/reducers/DataGridReducers';
import { isNil } from 'lodash-es';

export interface PrecisionDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	decimals?: number;
	showFooter?: boolean;
	showBlankIfEmpty?: boolean;
	template?: string;
	footerTotal?: (props: FooterProps<T>) => number;
	convertFromDecimal?: boolean;
}

export const PrecisionDataGridColumn = <T = any,>({
	decimals = 2,
	align = DataGridColumnAlignment.RIGHT,
	showFooter = false,
	showBlankIfEmpty = false,
	template = ':value:',
	footerTotal,
	convertFromDecimal = false,
	...config
}: PrecisionDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const dispatch = store.dispatch;
	const columnId = (config.accessorKey ?? config.header) as string;

	dispatch(dataGridActions.setPrecision({ key: columnId, value: decimals }));

	const getValue = (value: any) => {
		if (isNil(value) || (!value && showBlankIfEmpty)) {
			return '';
		}

		if (convertFromDecimal) {
			return (value * 100).toFixed(decimals).toLocaleString();
		}

		return Number(value);
	};

	return DefaultDataGridColumn({
		enableColumnActions: true,
		...config,
		exportValue: (value: any) => getValue(value),
		align,
		getValue: ({ cell }) => (
			<PrecisionDataGridRenderer
				showBlankIfEmpty={showBlankIfEmpty}
				value={cell.getValue()}
				columnId={columnId}
				template={template}
				convertFromDecimal={convertFromDecimal}
			/>
		),
		renderColumnActionsMenuItems: ({ closeMenu }) => [
			<PrecisionDataGridColumnMenuItem
				key="precision"
				columnId={columnId}
				onChange={() => closeMenu()}
			/>
		],
		...(showFooter && {
			getFooter: (props) => {
				const total = footerTotal
					? footerTotal(props)
					: sumColumnTotal(props, (row) =>
							Number(row.getValue(props.column.id))
						);

				if (total === null) {
					return '';
				}

				return (
					<PrecisionDataGridRenderer
						showBlankIfEmpty={showBlankIfEmpty}
						value={total}
						columnId={columnId}
						template={template}
						convertFromDecimal={convertFromDecimal}
					/>
				);
			}
		})
	});
};
