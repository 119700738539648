import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationTypeIndexResponse,
	GuardianReconciliationTypeProfileResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ReconciliationTypeResource extends HttpResource<
	GuardianReconciliationTypeIndexResponse,
	GuardianReconciliationTypeProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianReconciliationTypes);
	}
}
