import { SubscriptionIndexResponse } from 'RtModels';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface SubscriptionDataGridColumnConfiguration<T>
	extends Omit<
		ResourceDataGridColumnConfigurationInstance<SubscriptionIndexResponse, T>,
		'header'
	> {
	header?: string;
}

export const SubscriptionDataGridCache =
	new ResourceCacheMap<SubscriptionIndexResponse>(
		new SubscriptionResource(),
		'subscriptionId',
		'label'
	);

export const SubscriptionDataGridColumn = <T = any,>({
	header = 'Subscription',
	...config
}: SubscriptionDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		header,
		resourceCacheMap: SubscriptionDataGridCache,
		...config
	});
};
