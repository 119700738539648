import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { timestampToReadable } from 'RtUi/utils/maps';
import { NumberGroupResource } from '../resources/NumberGroupResource';
import { NumberGroupIndexResponse } from 'RtModels';
import { NumberGroupRouter } from 'RtUi/app/rt800/NumberGroups/NumberGroup.router';
import { isEqual } from 'lodash-es';

interface INumberGroupSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<NumberGroupIndexResponse, isMulti> {
	respOrgId?: string;
	entityId?: string;
	isActive?: boolean;
}

export class NumberGroupSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	NumberGroupIndexResponse,
	isMulti,
	INumberGroupSelectProps<isMulti>
> {
	public static defaultProps = {
		router: NumberGroupRouter
	};
	public resourceClass = NumberGroupResource;
	public state: ISelectFormControlState<NumberGroupIndexResponse> = {
		formLabel: 'Number Group',
		valueKey: 'numberGroupId',
		labelKey: 'label'
	};

	public componentDidMount() {
		this.checkAndSetGetAllParams();

		super.componentDidMount();
	}

	public componentDidUpdate(prevProps: INumberGroupSelectProps<isMulti>) {
		if (!isEqual(prevProps, this.props)) {
			this.checkAndSetGetAllParams();
		}
		super.componentDidUpdate(prevProps);
	}

	public optionRenderer = (numberGroup: NumberGroupIndexResponse) => (
		<span>
			{numberGroup.label} ({timestampToReadable(numberGroup.createdAt)})
		</span>
	);

	public checkAndSetGetAllParams() {
		const getAllParams: any = {};
		const { isActive = true, entityId, respOrgId } = this.props;

		if (isActive) {
			getAllParams.isActive = 1;
		} else if (isActive === false) {
			getAllParams.isActive = 0;
		}

		if (entityId) {
			getAllParams.entityId = entityId;
		}

		if (respOrgId) {
			getAllParams.respOrgId = respOrgId;
		}

		this.setGetAllParams(getAllParams);
	}
}
