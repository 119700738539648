/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CountryDialCodeIndexResponse = {
    properties: {
        iso3: {
            type: 'string',
            isRequired: true,
        },
        id: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        countryCode: {
            type: 'string',
            isRequired: true,
        },
        isNanp: {
            type: 'string',
            isRequired: true,
        },
    },
};