import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	OriginationNumberIndexRequest,
	OriginationNumberIndexResponse,
	ServiceNumberTagIndexRequest,
	ServiceNumberTagIndexResponse
} from 'RtModels';

import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchServiceNumbers = async (
	queryParams?: OriginationNumberIndexRequest
): Promise<FullResponse<OriginationNumberIndexResponse[]>> => {
	return handleGetRequest<OriginationNumberIndexResponse[], true>(
		RtxSipApiRoutes.ServiceNumbers.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetServiceNumbers = (
	queryParams?: OriginationNumberIndexRequest
) => {
	return useQuery<FullResponse<OriginationNumberIndexResponse[]>, Error>(
		[`useGetServiceNumbers`, queryParams],
		() => fetchServiceNumbers(queryParams),
		{ enabled: true }
	);
};

const fetchServiceNumberTags = async (
	queryParams?: ServiceNumberTagIndexRequest
): Promise<FullResponse<ServiceNumberTagIndexResponse[]>> => {
	return handleGetRequest<ServiceNumberTagIndexResponse[], true>(
		RtxSipApiRoutes.ServiceNumberTag.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetServiceNumberTags = (
	queryParams?: ServiceNumberTagIndexRequest
) => {
	return useQuery<FullResponse<ServiceNumberTagIndexResponse[]>, Error>(
		[`useGetServiceNumbers`, queryParams],
		() => fetchServiceNumberTags(queryParams),
		{ enabled: true }
	);
};
