/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateLoadIndetMethod {
    MaxInterIntra = 1,
    InterRate = 2,
    IntraRate = 3,
}