import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	JobTaskIndexResponse,
	JobTaskProfileResponse,
	JobTaskUpdateResponse,
	JobTaskUpdateRequest,
	JobTaskRecord,
	JobStepResourceProfileRequest
} from 'RtModels';
import { RtxFlowApiRoutes } from 'RtExports/routes';

export class JobTaskResource extends HttpResource<
	JobTaskIndexResponse,
	JobTaskProfileResponse
> {
	constructor(protected urlParams: JobStepResourceProfileRequest) {
		super(RtxFlowApiRoutes.Tasks);
	}

	/**
	 * Updates a single JobTask
	 * @param task
	 */
	public async update(task: JobTaskRecord) {
		return this.bulkUpdate([task]);
	}

	/**
	 * Bulk Update JobTask
	 * @param tasks
	 */
	public bulkUpdate(tasks: JobTaskRecord[]) {
		const req: JobTaskUpdateRequest = { tasks };
		const body = JSON.stringify(req);

		return this.fetchWithRoute<JobTaskUpdateResponse>(
			RtxFlowApiRoutes.Tasks.Update,
			{ body }
		);
	}
}
