/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationConfigVerizon = {
    properties: {
        apiKey: {
            type: 'string',
            isRequired: true,
        },
        certPath: {
            type: 'string',
            isRequired: true,
        },
        certKeyPath: {
            type: 'string',
            isRequired: true,
        },
        account: {
            type: 'string',
            isRequired: true,
        },
        trunkGroup: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        allowedRespOrgIds: {
            type: 'string',
        },
        ban: {
            type: 'string',
            isRequired: true,
        },
        corpSrp: {
            type: 'string',
            isRequired: true,
        },
        relatedPartyId: {
            type: 'string',
            isRequired: true,
        },
        relatedPartyName: {
            type: 'string',
            isRequired: true,
        },
        areaOfService: {
            type: 'string',
            isRequired: true,
        },
        primaryContactName: {
            type: 'string',
            isRequired: true,
        },
        primaryContactEmail: {
            type: 'string',
            isRequired: true,
        },
        primaryContactPhone: {
            type: 'string',
            isRequired: true,
        },
        secondaryContactName: {
            type: 'string',
            isRequired: true,
        },
        secondaryContactEmail: {
            type: 'string',
            isRequired: true,
        },
        secondaryContactPhone: {
            type: 'string',
            isRequired: true,
        },
    },
};