import { FC } from 'react';
import { CdrOneTimeExportRouter } from 'RtUi/app/Administration/CdrOneTimeExport/CdrOneTimeExport.router';
import { CdrOneTimeExportForm } from 'RtUi/app/Administration/CdrOneTimeExport/lib/forms/CdrOneTimeExportForm';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';

export const CdrOneTimeExportIndexContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	return (
		<StandardLayout router={CdrOneTimeExportRouter}>
			<CdrOneTimeExportForm />
		</StandardLayout>
	);
};

CdrOneTimeExportRouter.setIndexRtUiFunctionalComponent(
	CdrOneTimeExportIndexContainer
);
