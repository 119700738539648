import {
	ManagedRespOrgIndexRequest,
	ManagedRespOrgIndexResponse
} from 'RtModels';
import { useGetManagedRespOrgs } from 'RtUi/app/rtAdmin/ManagedRespOrgs/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getManagedRespOrgsColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/managedRespOrgs';
import { useMemo } from 'react';
import { ManagedRespOrgRouter } from '../../ManagedRespOrg.router';

interface IManagedRespOrgGridProps {
	onRowClick?: (row: ManagedRespOrgIndexResponse) => void;
	resourceParams?: ManagedRespOrgIndexRequest;
	autoFocusFilter?: boolean;
}

export const ManagedRespOrgGrid = ({
	resourceParams
}: IManagedRespOrgGridProps) => {
	const columns = useMemo(() => getManagedRespOrgsColumns(), []);

	const { data, isLoading } = useGetManagedRespOrgs(resourceParams);

	return (
		<DataGrid<ManagedRespOrgIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'rtAdmin-managedRespOrgs'}
			router={ManagedRespOrgRouter}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
