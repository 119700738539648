/* eslint-disable max-classes-per-file */

import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { ResourceType } from 'RtModels';
import {
	GetResourceTypeValues,
	GetResourceTypeName
} from 'RtUi/app/rtDid/Dashboard/lib/components/types';

export interface IResourceTypeOption {
	label?: string;
	value: ResourceType;
}

export const IntegrationOptions: IResourceTypeOption[] =
	GetResourceTypeValues().map((ResourceType) => {
		return {
			label: GetResourceTypeName(ResourceType),
			value: ResourceType
		};
	});

export class SwitchResource extends ArrayResource<IResourceTypeOption> {
	constructor() {
		super('value', IntegrationOptions);
	}
}

interface ISwitchSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<IResourceTypeOption, isMulti> {}

export class ResourceTypeSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	IResourceTypeOption,
	isMulti,
	ISwitchSelectProps<isMulti>
> {
	public resourceClass = SwitchResource;
	public state: ISelectFormControlState<IResourceTypeOption> = {
		formLabel: 'Resource Type',
		valueKey: 'value',
		labelKey: 'label'
	};

	public optionRenderer = (integrationOption: IResourceTypeOption) => {
		const option = IntegrationOptions.find(
			(so) => so.value === integrationOption.value
		);
		const label = option ? option.label : integrationOption.value;

		return <span>{label}</span>;
	};
}
