import { compareAsc } from 'date-fns';
import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { GuardianAlertHistoryNoteIndexResponse } from 'RtModels';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { dateToReadable } from 'RtUi/utils/maps';

interface IAlertHistoryNotesControl {
	notes: GuardianAlertHistoryNoteIndexResponse[];
	isSubmitting: boolean;
	onNoteCreate: (note: string) => Promise<Boolean>;
}

export const AlertHistoryNotesControl: React.FC<
	React.PropsWithChildren<IAlertHistoryNotesControl>
> = ({ notes, isSubmitting, onNoteCreate }) => {
	const [note, setNote] = React.useState<string>('');
	const notesContainerRef = React.useRef<HTMLDivElement>(null);
	React.useLayoutEffect(() => {
		const notesContainer = notesContainerRef.current;
		if (notesContainer) {
			notesContainer.scrollTop = notesContainer.scrollHeight;
		}
	});

	notes = notes.sort((n1, n2) =>
		compareAsc(new Date(n1.createdTs), new Date(n2.createdTs))
	);

	return (
		<section>
			{notes.length <= 0 && (
				<Alert variant="info" className="rounded-0">
					No notes taken for this alert. Type in the box below to start.
				</Alert>
			)}
			<div
				ref={notesContainerRef}
				className="card-body flex-fill"
				style={{ minHeight: 250, height: '50vh', overflowY: 'auto' }}
			>
				{notes.map((note) => (
					<Alert
						key={note.guardianAlertHistoryNoteId}
						variant="warning"
						className="d-flex flex-column"
					>
						<small className="text-muted">
							{dateToReadable(note.createdTs, true)}
						</small>
						<section className="my-1">{note.note}</section>
						<small className="text-muted text-end">
							&mdash; {note.userEmail}
						</small>
					</Alert>
				))}
			</div>
			<footer className="p-2 d-flex justify-content-between">
				<InputFormControl
					className="me-2"
					placeholder="Type to add note here"
					disabled={isSubmitting}
					useControlGroup={false}
					label="Create new note"
					value={note}
					onChange={(note) => setNote(note)}
				/>
				<Button
					className="rounded-circle"
					type="submit"
					variant="primary"
					disabled={isSubmitting}
					onClick={async (e) => {
						e.preventDefault();

						const result = await onNoteCreate(note);
						if (result === true) {
							setNote('');
						}
					}}
				>
					<i className="fas fa-paper-plane fa-lg" />
				</Button>
			</footer>
		</section>
	);
};
