import type { Cpr } from 'Somos/lib/SomosCpr/RtCprV2/Cpr';
import type { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprColAnnouncement } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColAnnouncement';
import { CprColAreaCode } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColAreaCode';
import { CprColCarrier } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColCarrier';
import { CprColDate } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColDate';
import { CprColDayOfWeek } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColDayOfWeek';
import { CprColGoTo } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColGoTo';
import { CprColLata } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColLata';
import { CprColNpaNxx } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColNpaNxx';
import { CprColPercent } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColPercent';
import { CprColSixDigit } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColSixDigit';
import { CprColState } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColState';
import { CprColSwitch } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColSwitch';
import { CprColTenDigit } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColTenDigit';
import { CprColTerminatingNumber } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColTerminatingNumber';
import { CprColTimeOfDay } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColTimeOfDay';
import { type CprErrorType, CprNodeType, CprSection, type IValidatorCleanResponse } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import type { CprError } from 'Somos/lib/SomosCpr/RtCprV2/CprError';
import { CprValidator } from 'Somos/lib/SomosCpr/RtCprV2/CprValidator';

// exported definitions
// ======================================================================

export class CprRow extends CprValidator {

	public readonly cprNodeTypeId = undefined;
	public readonly cprSectionId = CprSection.CprSectionCpr;

	public readonly announcement: CprColAnnouncement;
	public readonly areaCodes: CprColAreaCode;
	public readonly carrier: CprColCarrier;
	public readonly dates: CprColDate;
	public readonly daysOfWeek: CprColDayOfWeek;
	public readonly goto: CprColGoTo;
	public readonly latas: CprColLata;
	public readonly npaNxxs: CprColNpaNxx;
	public readonly percent: CprColPercent;
	public readonly sixDigits: CprColSixDigit;
	public readonly states: CprColState;
	public readonly tenDigits: CprColTenDigit;
	public readonly terminatingNumber: CprColTerminatingNumber;
	public readonly times: CprColTimeOfDay;
	public readonly switches: CprColSwitch;

	public readonly root: CprCol;

	protected readonly cprCols: CprCol[] = [];
	protected readonly cprColsByKey: { [key: string]: CprCol; } = {};

	protected errors: CprError[] = [];
	protected isRowNew = false;

	public constructor(cpr: Cpr, cprIdx: number, uuid: string | undefined = undefined) {
		super(cpr, cprIdx, uuid);

		this.announcement = new CprColAnnouncement(this, 0);
		this.areaCodes = new CprColAreaCode(this, 0);
		this.carrier = new CprColCarrier(this, 0);
		this.dates = new CprColDate(this, 0);
		this.daysOfWeek = new CprColDayOfWeek(this, 0);
		this.goto = new CprColGoTo(this, 0);
		this.latas = new CprColLata(this, 0);
		this.npaNxxs = new CprColNpaNxx(this, 0);
		this.percent = new CprColPercent(this, 0);
		this.sixDigits = new CprColSixDigit(this, 0);
		this.states = new CprColState(this, 0);
		this.tenDigits = new CprColTenDigit(this, 0);
		this.terminatingNumber = new CprColTerminatingNumber(this, 0);
		this.times = new CprColTimeOfDay(this, 0);
		this.switches = new CprColSwitch(this, 0);

		this.root = new CprColCarrier(this, 0);

		for (const cprCol of this.cpr.getCprCols()) {
			this.makeCprCol(cprCol.cprNodeTypeId, cprCol.getCprIdx());
		}
	}

	public hasCanada(): boolean {
		for (const cprCol of this.cprCols) {
			if (cprCol.hasCanada()) {
				return true;
			}
		}
		return false;
	}

	public setIsRowNew(isRowNew: boolean) {
		this.isRowNew = isRowNew;
	}

	public isNew() {
		return this.isRowNew;
	}

	/**
	 * Clones the following values from cloneFrom param:
	 * announcement, areaCodes, carrier, dates, daysOfWeek, latas, npaNxxs,
	 * percent, sixDigits, states, tenDigits, terminatingNumber, times, switches
	 */
	public cloneValuesFrom(cloneFrom: CprRow) {
		const doValidation = this.cpr.doValidation();

		// turn off validation if necessary
		if (doValidation) {
			this.cpr.setValidation(false);
		}

		if (this.cpr.hasCprCol(CprNodeType.Announcement)) {
			this.announcement.setValues(cloneFrom.announcement.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.AreaCode)) {
			this.areaCodes.setValues(cloneFrom.areaCodes.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.Carrier)) {
			this.carrier.setValues(cloneFrom.carrier.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.Date)) {
			this.dates.setValues(cloneFrom.dates.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.DayOfWeek)) {
			this.daysOfWeek.setValues(cloneFrom.daysOfWeek.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.Lata)) {
			this.latas.setValues(cloneFrom.latas.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.NpaNxx)) {
			this.npaNxxs.setValues(cloneFrom.npaNxxs.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.Percent)) {
			this.percent.setValues(cloneFrom.percent.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.SixDigit)) {
			this.sixDigits.setValues(cloneFrom.sixDigits.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.State)) {
			this.states.setValues(cloneFrom.states.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.TenDigit)) {
			this.tenDigits.setValues(cloneFrom.tenDigits.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.TerminatingNumber)) {
			this.terminatingNumber.setValues(cloneFrom.terminatingNumber.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.Time)) {
			this.times.setValues(cloneFrom.times.getRawValues());
		}
		if (this.cpr.hasCprCol(CprNodeType.Switch)) {
			this.switches.setValues(cloneFrom.switches.getRawValues());
		}

		// re-enable validation and validate (if previously enabled)
		if (doValidation) {
			this.cpr.setValidation(doValidation);
		}
	}

	public makeCprCol(cprNodeTypeId: CprNodeType, cprIdx = this.cprCols.length): CprCol {
		if (cprNodeTypeId in this.cprColsByKey) {
			return this.cprColsByKey[cprNodeTypeId];
		}

		let cprCol: CprCol;

		switch (cprNodeTypeId) {
			case CprNodeType.Announcement:
				cprCol = this.announcement;
				break;
			case CprNodeType.AreaCode:
				cprCol = this.areaCodes;
				break;
			case CprNodeType.Carrier:
				cprCol = this.carrier;
				break;
			case CprNodeType.DayOfWeek:
				cprCol = this.daysOfWeek;
				break;
			case CprNodeType.Date:
				cprCol = this.dates;
				break;
			case CprNodeType.GoTo:
				cprCol = this.goto;
				break;
			case CprNodeType.Lata:
				cprCol = this.latas;
				break;
			case CprNodeType.NpaNxx:
				cprCol = this.npaNxxs;
				break;
			case CprNodeType.Percent:
				cprCol = this.percent;
				break;
			case CprNodeType.SixDigit:
				cprCol = this.sixDigits;
				break;
			case CprNodeType.State:
				cprCol = this.states;
				break;
			case CprNodeType.TenDigit:
				cprCol = this.tenDigits;
				break;
			case CprNodeType.TerminatingNumber:
				cprCol = this.terminatingNumber;
				break;
			case CprNodeType.Time:
				cprCol = this.times;
				break;
			case CprNodeType.Switch:
				cprCol = this.switches;
				break;
		}

		this.cprCols.splice(cprIdx, 0, cprCol);
		this.cprColsByKey[cprNodeTypeId] = cprCol;

		for (let reIdx = cprIdx; reIdx < this.cprCols.length; reIdx++) {
			this.cprCols[reIdx].setCprIdx(reIdx);
		}

		return cprCol;
	}

	public deleteCprCol(cprNodeTypeId: CprNodeType) {
		const cprColToDeleteIndex = this.cprCols.findIndex(
			(cprCol) => cprCol.cprNodeTypeId === cprNodeTypeId
		);

		// node type not found
		if (cprColToDeleteIndex < 0) {
			return;
		}

		const cprColToDelete = this.cprCols.splice(cprColToDeleteIndex, 1)[0];

		cprColToDelete.setValues([]);

		delete this.cprColsByKey[cprNodeTypeId];
	}

	public moveCprCol(cprNodeTypeId: CprNodeType, cprIdx: number) {
		const cprColToMoveIdx = this.cprCols.findIndex(
			(cprCol) => cprCol.cprNodeTypeId === cprNodeTypeId
		);

		// node type not found
		if (cprColToMoveIdx < 0) {
			return;
		}

		// out of bounds
		if (cprIdx < 0 || cprIdx >= this.cprCols.length) {
			return;
		}

		// remove moving cprCol
		const cprColToMove = this.cprCols.splice(cprColToMoveIdx, 1)[0];

		// push back cprCol in new index
		this.cprCols.splice(cprIdx, 0, cprColToMove);

		for (let idx = 0; idx < this.cprCols.length; idx++) {
			this.cprCols[idx].setCprIdx(idx);
		}
	}

	public getCprCol(cprNodeTypeId: CprNodeType): CprCol | null {
		if (!this.cpr.hasCprCol(cprNodeTypeId)) {
			return null;
		}

		return this.cprColsByKey[cprNodeTypeId];
	}

	public hasCprCol(cprNodeTypeId: CprNodeType): boolean {
		return (cprNodeTypeId in this.cprColsByKey && this.cpr.hasCprCol(cprNodeTypeId));
	}

	public getCprColByIdx(idx: number): CprCol | undefined {
		return this.cprCols[idx];
	}

	public getCprCols(): CprCol[] {
		return this.cprCols;
	}

	public getErrors(): CprError[] {
		return this.errors;
	}

	public hasErrors(type?: CprErrorType): boolean {
		if (!type) {
			return this.errors.length > 0;
		}

		for (const error of this.errors) {
			if (error.cprErrorTypeId === type) {
				return true;
			}
		}

		return false;
	}

	public reset() {
		for (const cprCol of this.cprCols) {
			cprCol.reset();
		}
	}

	public clean() {
		let isEmptyRow = true;

		const response: IValidatorCleanResponse = {
			removedQty: 0,
			addedQty: 0,
			wasRemoved: false,
		};

		for (const cprCol of this.cprCols) {
			const colRes = cprCol.clean();

			response.removedQty += colRes.removedQty;
			response.addedQty += colRes.addedQty;

			isEmptyRow &&= cprCol.getRawValues().length <= 0;
		}

		if (isEmptyRow) {
			this.cpr.deleteCprRow(this.getCprIdx());
			response.wasRemoved = true;
		}

		return response;
	}

	public validate(): boolean {
		this.errors = [];

		// almost all validation happens in Cpr.validateCprTree, to minimize iterations.

		const rawAnn = this.announcement.getValue();
		const rawCic = this.carrier.getValue();
		const rawTerm = this.terminatingNumber.getValue();
		const rawGoto = this.goto.getValue();

		if (rawAnn && rawCic) {
			this.addWarning(null, `Should not specify both Carrier and Announcement.`);
		}

		if (!rawAnn && !rawCic && !rawGoto) {
			this.addError(null, `A value for Carrier or Announcement must be specified.`);
		}

		if ((rawAnn || rawCic) && rawGoto) {
			this.addWarning(null, `Should not specify a value for Carrier or Announcement.`);
		}

		if (rawAnn && rawTerm) {
			this.addError(null, `Cannot specify a value for both Destination and Announcement.`);
		}

		if ((rawCic && !rawAnn && !rawTerm) && rawGoto) {
			this.addError(null, `A value for Destination must be specified with a Carrier.`);
		}

		return this.hasErrors();
	}

	public bulkUpdateCarriers(oldCic: string, newCic: string, dryRun: boolean): number {
		let numberOfNodesAffected = 0;

		for (const cprCol of this.cprCols) {
			numberOfNodesAffected += cprCol.bulkUpdateCarriers(oldCic, newCic, dryRun);
		}

		return numberOfNodesAffected;
	}

}
