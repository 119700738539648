import { Theme } from '@mui/material';

export const themeConfiguration: Partial<Theme> = {
	components: {
		MuiTableCell: {
			styleOverrides: {
				body: {
					position: 'relative',
					padding: '4px 6px 3px !important',
					borderBottomColor: '#f0f0f0',
					borderRight: '1px solid #e0e0e0'
				},
				root: {
					fontFamily: 'Arial, Helvetica, sans-serif',
					fontSize: '12px'
				}
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: '#dadada',
					opacity: 1
				}
			}
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					tr: {
						// styling for multi header
						':first-of-type': {
							':not(:only-child)': {
								th: {
									// hide parent filters
									'.MuiCollapse-root': {
										display: 'none'
									}
								}
							}
						}
						// end styling
					},
					th: {
						'fontSize': '12px',
						'backgroundColor': '#ededed',
						'.Mui-TableHeadCell-Content-Labels': {
							paddingLeft: '1rem'
						}
					}
				}
			}
		},
		MuiInput: {
			styleOverrides: {
				root: {
					fontSize: '14px'
				}
			}
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'fontSize': '12px',
					'fontWeight': '500',
					'th': { fontWeight: '500' },
					'.Mui-TableBodyCell-DetailPanel:empty': {
						display: 'none'
					}
				}
			}
		},
		MuiTableFooter: {
			styleOverrides: {
				root: {
					tr: {
						backgroundColor: '#ededed'
					},
					td: {
						lineHeight: 'inherit',
						padding: '4px 6px 3px !important'
					}
				}
			}
		}
	}
};
