import { RtUiController } from 'RtUi/app/@RtUi/RtUiDecorators';
import { LoginPath, LogoutPath } from 'RtUi/app/user/lib/Constants';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';

@RtUiController({
	name: 'Logout',
	path: LogoutPath,
	hideMdAndAbove: true
})
export class LogoutContainer extends ApplicationContainer<{}, {}> {
	public state = {};

	public componentDidMount() {
		this.Actions.User.logout();

		this.goToPath(LoginPath, undefined, () => {
			//Reload Assets
			location.reload();
		});
	}

	public render() {
		return <Loading message="Logging Off..." />;
	}
}
