/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExportDestinationProfileUrlRequest = {
    properties: {
        cdrExportDestinationId: {
            type: 'number',
            isRequired: true,
        },
    },
};