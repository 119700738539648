export interface IPagingResponse<T> {
	data: T[];
	pages: number;
}

export interface IPagingRequest<T> {
	pageSize?: number;
	page?: number;
	search?: string;
	sorted?: Array<ISorted<T>>;
	filtered?: Array<IFiltered<T>>;
}

export interface ISorted<T> {
	id: keyof T;
	desc: boolean;
}

export interface IFiltered<T> {
	id: keyof T | string;
	value: string;
}

export const Page = <T>(
	allData: T[],
	req: IPagingRequest<T>
): Promise<IPagingResponse<T>> => {
	const currentPage = req.page || 0;
	const pageSize = req.pageSize || 10;
	let matches = [...allData];
	const startIndex = currentPage * pageSize;
	const endIndex = startIndex + pageSize;

	if (Array.isArray(req.filtered) && req.filtered.length >= 1) {
		const filters = req.filtered;

		matches = matches.filter((match) => {
			return filters.every((f: IFiltered<T>) => {
				const matchId = f.id as string;
				return String(matchId).includes(f.value);
			});
		});
	}

	if (Array.isArray(req.sorted) && req.sorted.length >= 1) {
		//quick sort function
		//TODO: Make more comprehensive
		const { id, desc: isDescending } = req.sorted[0];

		matches.sort((w1, w2) => {
			const a1 = w1[id];
			const a2 = w2[id];

			if (a1 > a2) {
				return isDescending ? 1 : -1;
			} else if (a2 > a1) {
				return isDescending ? -1 : 1;
			}

			return 0;
		});
	}

	const pages = Math.ceil(matches.length / pageSize);
	const data = matches.slice(startIndex, endIndex);
	const response: IPagingResponse<T> = { data, pages };

	return Promise.resolve(response);
};

export const safeDivision = (
	nominator: number | undefined,
	denominator: number | undefined,
	defaultValue = 0
) => {
	if (typeof nominator !== 'number' || isNaN(nominator)) {
		return defaultValue;
	}

	if (
		typeof denominator !== 'number' ||
		isNaN(denominator) ||
		denominator === 0
	) {
		return defaultValue;
	}

	return nominator / denominator;
};
