import { RocActionItem, RocActionItems, RocTfnIndexResponse } from 'RtModels';
import { NumberRouter } from 'RtUi/app/rt800/Numbers/Number.router';
import { RocActionsSelect } from 'RtUi/app/rt800/RespOrgChange/lib/controls/RocActionsSelect';
import { RocRequestResource } from 'RtUi/app/rt800/RespOrgChange/lib/resources/RocRequestResource';
import DataGrid from 'RtUi/components/data/DataGrid';
import { TollFreeNumbersCopyButton } from 'RtUi/components/data/DataGrid/components/TollFreeNumbersCopyButton';
import { getRocRequestNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/rocRequests';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { useMemo, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';

interface IRocRequestNumbersGridProps {
	rocTxnId: string;
	data: RocTfnIndexResponse[];
	actions: RocActionItems;
	isIncoming: boolean;
	onUpdateNumbers: () => void;
}

export const RocRequestNumbersGrid = ({
	rocTxnId,
	data,
	actions,
	isIncoming,
	onUpdateNumbers
}: IRocRequestNumbersGridProps) => {
	const [rowSelection, setRowSelection] = useState<RocTfnIndexResponse[]>([]);
	const [rocAction, setRocAction] = useState<RocActionItem>();
	const [rejectNote, setRejectNote] = useState<string>();
	const [processingAction, setProcessingAction] = useState<boolean>();
	const [processingErrors, setProcessingErrors] = useState<string[]>();
	const [processingSuccess, setProcessingSuccess] = useState<boolean>();

	const columns = useMemo(
		() => getRocRequestNumbersColumns(actions),
		[actions]
	);

	const isRocTfnRespondable = (rocTfn: RocTfnIndexResponse) => {
		const pendingAndOverdueStatus = [0, 8];
		const hasBeenProcessed = Boolean(rocTfn.processedTs);

		if (pendingAndOverdueStatus.includes(rocTfn.rocStatusId)) {
			return true;
		}

		return !hasBeenProcessed;
	};

	const isSomeRocTfnRespondable = useMemo(
		() => data.some((tfn) => isRocTfnRespondable(tfn)),
		[data]
	);

	const onAction = async (e: React.FormEvent<HTMLFormElement>) => {
		if (actions === null || !rocAction) {
			return;
		}

		const rocRequestResource = new RocRequestResource();
		const tfns = rowSelection.map((roc) => roc.tfn);
		const isRemovedDialedNumbersAction =
			rocAction.code === actions.removeDialNumbers.code;
		const currentRejectNote = rejectNote ? rejectNote : undefined; //set as undefined if empty

		e.preventDefault();

		setProcessingAction(true);
		setProcessingErrors(undefined);
		setProcessingSuccess(undefined);

		try {
			if (isRemovedDialedNumbersAction) {
				await rocRequestResource.removeNumbers(rocTxnId, tfns);
			} else {
				await rocRequestResource.createAction(
					rocTxnId,
					tfns,
					rocAction.code,
					currentRejectNote
				);
			}

			setProcessingSuccess(true);
			setRowSelection([]);
			setRocAction(undefined);
			setRejectNote('');

			onUpdateNumbers();
		} catch (processingErrors: any) {
			setProcessingErrors(processingErrors);
		} finally {
			setProcessingAction(false);
			setProcessingSuccess(false);
		}
	};

	return (
		<Form onSubmit={(e) => onAction(e)}>
			<Card body>
				{isSomeRocTfnRespondable && (
					<Row>
						<Col md={8}>
							<RocActionsSelect
								label="Processing Action"
								onlyIncoming={isIncoming}
								required
								onChange={setRocAction}
								value={rocAction}
							/>
							{rocAction?.isRejection && (
								<TextAreaFormControl
									label="Reject Note"
									onChange={setRejectNote}
									value={rejectNote}
								/>
							)}
						</Col>
						<Col>
							<Button
								variant="submit"
								type="submit"
								disabled={processingAction}
							>
								Process
								{rowSelection.length > 0 && (
									<>&nbsp;{rowSelection.length} ROCs</>
								)}
								{processingAction && (
									<>
										&nbsp;
										<i className="fa fa-fw fa-cog fa-spin" />
									</>
								)}
							</Button>
						</Col>
					</Row>
				)}
				<ControlGroup label="Toll-Free Numbers">
					<DataGrid<RocTfnIndexResponse>
						data={data}
						router={NumberRouter}
						columns={columns}
						pageName={'rt800_RocRequestsNumbers'}
						enableRowSelection={isRocTfnRespondable}
						selectedRows={rowSelection}
						onChangeSelectedRows={setRowSelection}
						headerAction={(table) => (
							<TollFreeNumbersCopyButton table={table} numberKey="tfn" />
						)}
					/>
				</ControlGroup>
				{processingErrors && (
					<Form.Group className="mb-3">
						<Alert variant="danger">
							The following errors occurred:
							<ul>
								{processingErrors.map((processingError, index) => (
									<li key={index}>{processingError}</li>
								))}
							</ul>
						</Alert>
					</Form.Group>
				)}
				{processingSuccess && (
					<Form.Group className="mb-3">
						<Alert variant="success">Processing has been successful!</Alert>
					</Form.Group>
				)}
			</Card>
		</Form>
	);
};
