import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	FileStreamIndexResponse,
	FileStreamCreateRequest,
	FileStreamProfileRequest,
	FileStreamUpdateRequest,
	FileStreamProfileResponse
} from 'RtModels';
import { RtVueApiRoutes } from 'RtExports/routes';

export class FileStreamResource extends HttpResource<
	FileStreamIndexResponse,
	FileStreamProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.FileStreams);
	}

	public create(fileStream: FileStreamCreateRequest) {
		const req: FileStreamCreateRequest = fileStream;
		const body = JSON.stringify(req);

		return this.fetchWithRoute<FileStreamProfileResponse>(
			RtVueApiRoutes.FileStreams.Create,
			{ body }
		);
	}

	public update(fileStreamId: number, fileStream: FileStreamUpdateRequest) {
		const urlParams: FileStreamProfileRequest = { fileStreamId };
		const bodyReq: FileStreamUpdateRequest = fileStream;
		const body = JSON.stringify(bodyReq);

		return this.fetchWithRoute<FileStreamProfileResponse>(
			RtVueApiRoutes.FileStreams.Update,
			{ body, urlParams }
		);
	}
}
