/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum SipAgentDialFormat {
    SubscriptionFormat = 1,
    E164 = 2,
    E164ShortForm = 3,
}