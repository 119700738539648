/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import { Add, DragHandle, Refresh } from '@mui/icons-material';
import {
	Box,
	CircularProgress,
	IconButton,
	ThemeProvider,
	Tooltip,
	createTheme
} from '@mui/material';
import {
	ColumnOrderState,
	ExpandedState,
	PaginationState,
	RowSelectionState,
	Updater,
	VisibilityState
} from '@tanstack/react-table';
import {
	UserPageConfigurationParameters,
	UserPageConfigurationParametersSortingOrder
} from 'RtModels';
import { ComponentController } from 'RtUi/components/ComponentController';
import { ExternalDataGridMenuItem } from 'RtUi/components/data/DataGrid/components/ExternalDataGridMenuItem';
import { InternalDataGridMenuItem } from 'RtUi/components/data/DataGrid/components/InternalDataGridMenuItem';
import { themeConfiguration } from 'RtUi/components/data/DataGrid/constants';
import Skeleton from 'RtUi/components/data/DataGrid/skeleton';
import {
	ColumnOrderingProps,
	DataGridBaseProps,
	DataGridTableInstance,
	DataGridUserPageConfiguration,
	ExternalExportProps,
	PaginationProps,
	RowOrderingProps,
	RowSelectionProps
} from 'RtUi/components/data/DataGrid/types';
import {
	copyDataToClipboard,
	exportToCsv,
	exportToExcel,
	getColumnsVisibility,
	getExportColumns,
	getThemeColor,
	mapColumnOrder,
	mapInitialColumnOrder
} from 'RtUi/components/data/DataGrid/utils';
import {
	SCREEN_SIZE,
	useGetScreenSize
} from 'RtUi/components/hooks/useGetScreenSize';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import { isArray, isEmpty, isEqual, noop, omit } from 'lodash-es';
import {
	MRT_GlobalFilterTextField,
	MRT_Row,
	MRT_RowData,
	MRT_ShowHideColumnsButton,
	MRT_TablePagination,
	MRT_ToggleFiltersButton,
	MRT_ToggleFullScreenButton,
	MaterialReactTable,
	useMaterialReactTable
} from 'material-react-table';
import {
	forwardRef,
	memo,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState
} from 'react';
import { Menu, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useDebounce } from 'react-use';
import { ConfigurationStoreActions } from './components/ConfigurationStoreActions';
import { postUserConfiguration, useGetUserConfiguration } from './services';

const formatter = new Intl.NumberFormat('en-US');

type DataGridProps<T = any> = DataGridBaseProps<T> &
	RowSelectionProps<T> &
	ExternalExportProps<T> &
	RowOrderingProps<T> &
	ColumnOrderingProps<T> &
	PaginationProps;

type DataGridRef<T = any> = DataGridTableInstance<T>;

const DataGridComponent = <T = any,>(
	props: DataGridProps<T>,
	ref: React.ForwardedRef<DataGridRef<T>>
) => {
	const {
		data = [],
		columns,
		className,
		disableExport = false,
		disableControls = false,
		enableExternalExport = false,
		getExternalExportData,
		router,
		disableExternalLinks = false,
		disableConfigurationStore = true,
		pageName,
		menuLinks,
		onRowClick = () => {},
		onClickCreate,
		onClickRefetch,
		inlineEdit,
		onRowEdit = () => {},
		loading = false,
		enableHiding = true,
		manualFilter = '',
		hasManualFiltering = false,
		setManualFilter,
		selectedRows = [],
		enableRowSelection = false,
		onChangeSelectedRows = noop,
		inlineActions,
		headerAction,
		footerAction,
		defaultSorting = [],
		enableSorting = true,
		onSortingChange = noop,
		rowThemeColor,
		totalRows,
		detailPanel,
		pagination,
		setPagination,
		enableRowOrdering = false,
		enableColumnDragging = false,
		onRowDragEnd = noop,
		onColumnDragEnd = noop,
		getRowId,
		customExportToCsv
	} = props;

	const screenSize = useGetScreenSize();
	const menuId = useMemo(() => generateUUID(), []);
	const { show } = useContextMenu({
		id: menuId
	});

	const [internalMenuItem, setInternalMenuItem] = useState<JSX.Element>();
	const [externalMenuItems, setExternalMenuItems] = useState<JSX.Element[]>();
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>();
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
	const [expanded, setExpanded] = useState<ExpandedState>();
	const [internalPagination, setInternalPagination] = useState<PaginationState>(
		{
			pageSize: 10,
			pageIndex: 0
		}
	);

	const [selectedRow, setSelectedRow] = useState<T>();
	const [sorting, setSorting] =
		useState<UserPageConfigurationParametersSortingOrder[]>(defaultSorting);
	const [isExporting, setIsExporting] = useState<boolean>(false);
	const [enabledColumnDragging, setEnabledColumnDragging] =
		useState<boolean>(false);
	const userConfigurationRef = useRef<
		DataGridUserPageConfiguration | undefined
	>(undefined);

	const manuallyUpdatedVisibilityRef = useRef<VisibilityState | undefined>(
		undefined
	);

	const {
		data: userConfiguration,
		isFetching: isLoadingConfiguration,
		refetch
	} = useGetUserConfiguration({ pageName }, !disableConfigurationStore);
	const { mutateAsync, isLoading: isSavingConfiguration } = useMutation(
		postUserConfiguration
	);

	const displayedColumns = useMemo(
		() => columns.filter((c) => !c.isInvisible),
		[columns]
	);
	const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
	const isDefaultConfigurationApplied = useMemo(() => {
		if (!userConfiguration) {
			return true;
		}

		const { columnOrder: confColumnOrder, sortingOrder: confSortingOrder } =
			userConfiguration.configurationParameters;

		const currentColumnOrder = mapInitialColumnOrder(
			columnOrder,
			displayedColumns
		);

		return (
			isEqual(confColumnOrder, currentColumnOrder) &&
			isEqual(confSortingOrder, defaultSorting)
		);
	}, [userConfiguration, columnOrder, defaultSorting, displayedColumns]);

	const tableTheme = useMemo(() => createTheme(themeConfiguration), []);
	const enableInlineEdit = useMemo(
		() => inlineEdit || displayedColumns.some((column) => column.enableEditing),
		[inlineEdit, displayedColumns]
	);
	const hasHiddenColumn = useMemo(
		() =>
			Object.values(omit(columnVisibility, 'mrt-row-expand') ?? {}).some(
				(val) => !val
			),
		[columnVisibility]
	);
	const onColumnSortingChange = useCallback(
		(newSorting: UserPageConfigurationParametersSortingOrder[]) => {
			onSortingChange(newSorting);
			setSorting(newSorting);
		},
		[onSortingChange]
	);

	const hasParentColumns = useMemo(
		() => displayedColumns.some((c) => !isEmpty(c.columns)),
		[displayedColumns]
	);

	const displayTotalRows = useMemo(
		() => totalRows !== undefined && data.length > 0 && totalRows > data.length,
		[totalRows, data]
	);

	const initialPageSize = useMemo(() => {
		if (screenSize === SCREEN_SIZE.LARGE) {
			return 25;
		}

		if (screenSize === SCREEN_SIZE.MEDIUM) {
			return 20;
		}

		return 10;
	}, [screenSize]);

	const layoutMode = useMemo(() => {
		if (hasParentColumns) {
			return 'grid';
		}

		if (inlineActions !== undefined) {
			return 'semantic';
		}

		return 'grid-no-grow';
	}, [hasParentColumns, inlineActions]);

	useDebounce(
		() => {
			setColumnOrder((currentState) =>
				mapColumnOrder(
					currentState,
					displayedColumns,
					userConfiguration?.configurationParameters.columnOrder
				)
			);
		},
		0,
		[userConfiguration, displayedColumns]
	);

	useEffect(() => {
		if (!pagination) {
			setInternalPagination({
				pageSize: initialPageSize,
				pageIndex: 0
			});
		}
	}, [initialPageSize, pagination]);

	useEffect(() => {
		const visibility = getColumnsVisibility(
			data,
			displayedColumns,
			manuallyUpdatedVisibilityRef.current
		);

		if (!isEqual(visibility, columnVisibility)) {
			setColumnVisibility({ ...visibility });
		}
	}, [displayedColumns, data, columnVisibility]);

	useEffect(
		() => setEnabledColumnDragging(enableColumnDragging),
		[enableColumnDragging]
	);

	useEffect(() => {
		const selectionState = selectedRows.reduce<RowSelectionState>(
			(dest, row) => {
				const index = data.findIndex((r) => isEqual(r, row));
				dest[index] = true;

				return dest;
			},
			{}
		);

		if (!isEqual(selectionState, rowSelection)) {
			setRowSelection(selectionState);
		}
	}, [selectedRows, rowSelection, data]);

	useEffect(() => {
		if (!(detailPanel && getRowId)) {
			return;
		}

		const newExpanded = data.reduce<Record<string, boolean>>((dest, curr) => {
			const hasPanel = detailPanel(curr);

			if (hasPanel) {
				const id = getRowId(curr as any);
				dest[id] = true;
			}

			return dest;
		}, {});

		if (!isEqual(newExpanded, expanded)) {
			setExpanded(newExpanded);
		}
	}, [detailPanel, getRowId, data, expanded]);

	useEffect(() => {
		if (router && selectedRow) {
			const componentController = ComponentController.getInstance();
			const internalItem = (
				<InternalDataGridMenuItem
					router={router}
					record={selectedRow}
					content={menuLinks && menuLinks(selectedRow)}
				/>
			);
			setInternalMenuItem(internalItem);

			if (disableExternalLinks !== true) {
				const externalRouters = componentController.getRoutersWithProfileAccess(
					selectedRow,
					router
				);

				const filteredRouters =
					disableExternalLinks instanceof Array
						? externalRouters.filter(
								(router) => !disableExternalLinks.includes(router)
							)
						: externalRouters;

				const externalItems = filteredRouters.map((externalRouter, index) => (
					<ExternalDataGridMenuItem
						key={index}
						router={externalRouter}
						record={selectedRow}
					/>
				));
				setExternalMenuItems(externalItems);
			}
		}
	}, [selectedRow, router, menuLinks, disableExternalLinks]);

	useEffect(() => {
		if (
			!userConfiguration ||
			isEqual(userConfigurationRef.current, userConfiguration)
		) {
			return;
		}
		userConfigurationRef.current = userConfiguration;

		const { columnOrder, sortingOrder } =
			userConfiguration.configurationParameters;

		if (!isEmpty(columnOrder)) {
			setColumnOrder(columnOrder);
		}

		if (!isEmpty(sortingOrder)) {
			onColumnSortingChange(sortingOrder);
		}
	}, [
		displayedColumns,
		userConfiguration,
		userConfigurationRef,
		onColumnSortingChange
	]);

	const onColumnOrderChange = (data: Updater<ColumnOrderState>) => {
		setColumnOrder(data);
	};

	const onColumnVisibilityChange = (data: Updater<VisibilityState>) => {
		setColumnVisibility((currentState) => {
			const updatedValues =
				data instanceof Function ? data(currentState ?? {}) : data;

			const updatedState = Object.keys(updatedValues).reduce<VisibilityState>(
				(dest, key) => {
					const current = updatedValues[key];

					if (!current) {
						dest[key] = false;
					}

					return dest;
				},
				{}
			);

			manuallyUpdatedVisibilityRef.current = updatedState;
			return updatedState;
		});
	};

	const onRowSelectionChange = (updater: Updater<RowSelectionState>) => {
		const values =
			updater instanceof Function ? updater(rowSelection) : updater;

		const mappedValues = Object.keys(values).reduce<T[]>((dest, key) => {
			const element = data[Number(key)];
			if (element) {
				dest.push(element);
			}

			return dest;
		}, []);

		onChangeSelectedRows(mappedValues);
	};

	const onPaginationChange = (updater: Updater<PaginationState>) => {
		const paginationState = pagination ?? internalPagination;
		const paginationUpdater = setPagination ?? setInternalPagination;
		const values =
			updater instanceof Function ? updater(paginationState) : updater;
		if (!isEqual(paginationState, values)) {
			paginationUpdater(values);
		}
	};

	const handleSaveConfigurations = async () => {
		try {
			const configurationParameters: UserPageConfigurationParameters = {
				columnOrder: columnOrder.filter((c) => !c.startsWith('mrt-')),
				sortingOrder: sorting
			};

			const payload = {
				pageName,
				body: {
					configurationParameters
				}
			};
			await mutateAsync(payload);
			refetch();
			toast.success('Successfully saved table configurations.');
		} catch (e) {}
	};

	const handleResetConfigurations = async () => {
		const columnOrderFromColumns = mapInitialColumnOrder(
			columnOrder.filter((c) => !c.startsWith('mrt-')),
			displayedColumns
		);
		setColumnOrder(columnOrderFromColumns);
		onColumnSortingChange([]);
		try {
			const configurationParameters: UserPageConfigurationParameters = {
				columnOrder: columnOrderFromColumns,
				sortingOrder: []
			};

			const payload = {
				pageName,
				body: {
					configurationParameters
				}
			};
			await mutateAsync(payload);
			refetch();
			toast.success('Successfully reset table configurations.');
		} catch (e) {}
	};

	const handleClick = (
		event: React.MouseEvent<HTMLTableRowElement>,
		selectedRow: T
	) => {
		setSelectedRow(selectedRow);
		onRowClick(selectedRow);
		const menuLinksContent = menuLinks && menuLinks(selectedRow);

		if (router || menuLinksContent) {
			show({ event });
		}
	};

	const getCurrentData = async (table: DataGridTableInstance<T>) => {
		if (enableExternalExport && getExternalExportData) {
			return getExternalExportData();
		}

		return table
			.getPrePaginationRowModel()
			.rows.map((row) => row.original as T);
	};

	const copyToClipboardHandler = async (table: DataGridTableInstance<T>) => {
		setIsExporting(true);
		const currentColumns = table.getAllColumns();
		const currentData = await getCurrentData(table);

		await copyDataToClipboard(
			currentData,
			getExportColumns(currentColumns, columnOrder)
		);

		setIsExporting(false);
	};

	const exportToCsvHandler = async (table: DataGridTableInstance<T>) => {
		setIsExporting(true);
		if (customExportToCsv) {
			await customExportToCsv(table);
		} else {
			const currentColumns = table.getAllColumns();
			const currentData = await getCurrentData(table);

			exportToCsv(currentData, getExportColumns(currentColumns, columnOrder));
		}
		setIsExporting(false);
	};

	const exportToExcelHandler = async (table: DataGridTableInstance<T>) => {
		setIsExporting(true);
		const currentColumns = table.getAllColumns();
		const currentData = await getCurrentData(table);

		await exportToExcel(
			currentData,
			getExportColumns(currentColumns, columnOrder)
		);

		setIsExporting(false);
	};

	const customGlobalFilterFn = (
		row: any,
		columnId: string,
		filterValue: any
	): boolean => {
		const column = columns.find((column) => column.id === columnId);
		const parsedFilterValue = String(filterValue);
		let value = (row as MRT_Row<Record<keyof T, T[keyof T]>>).getValue(
			columnId
		);

		if (column && column.globalFilterFn) {
			return column.globalFilterFn(value, parsedFilterValue);
		}

		if (isArray(value)) {
			value = value.join('');
		}

		return String(value)
			.toLowerCase()
			.includes(parsedFilterValue.toLowerCase());
	};

	const hasRowSelection = (row: MRT_Row<MRT_RowData>): boolean => {
		if (enableRowSelection instanceof Function) {
			return enableRowSelection(row.original as T);
		}

		return enableRowSelection;
	};

	const table = useMaterialReactTable({
		autoResetPageIndex: pagination === undefined,
		layoutMode,
		data: !isEmpty(data) ? (data as any) : [],
		columns: displayedColumns as any,
		manualFiltering: hasManualFiltering,
		enableRowSelection: enableRowSelection && hasRowSelection,
		positionGlobalFilter: 'left',
		filterFns: { customGlobalFilterFn },
		globalFilterFn: 'customGlobalFilterFn',
		getColumnCanGlobalFilter: () => true,
		defaultColumn: {
			minSize: 20,
			size: 80,
			maxSize: 400
		},
		state: {
			sorting,
			density: 'compact',
			columnOrder,
			expanded,
			columnVisibility,
			pagination: pagination ?? internalPagination,
			...(hasManualFiltering && { globalFilter: manualFilter }),
			...(enableRowSelection && { rowSelection: rowSelection })
		},
		initialState: {
			sorting,
			columnOrder,
			columnVisibility: {
				'mrt-row-expand': false
			},
			showGlobalFilter: true,
			pagination: pagination ?? internalPagination
		},
		editDisplayMode: enableInlineEdit ? 'table' : undefined,
		onSortingChange: (newSorting) =>
			onColumnSortingChange(
				newSorting as UserPageConfigurationParametersSortingOrder[]
			),
		onRowSelectionChange,
		...(setManualFilter && { onGlobalFilterChange: setManualFilter }),
		onColumnOrderChange,
		onColumnVisibilityChange,
		onPaginationChange,
		enableColumnActions: false,
		enableColumnDragging: enabledColumnDragging,
		enableColumnOrdering: true,
		enableColumnResizing: true,
		enableDensityToggle: false,
		enableEditing: enableInlineEdit,
		enableHiding: enableHiding,
		enableSorting: enableSorting && !enableRowOrdering,
		enableRowOrdering: enableRowOrdering,
		displayColumnDefOptions: {
			'mrt-row-expand': {
				header: '',
				visibleInShowHideMenu: false
			},
			'mrt-row-drag': {
				header: '',
				visibleInShowHideMenu: false
			},
			'mrt-row-actions': {
				visibleInShowHideMenu: false
			}
		},
		enableRowActions: inlineActions !== undefined,
		getRowId,
		positionActionsColumn: 'last',
		renderRowActions: ({ row }) =>
			inlineActions && inlineActions(row.original as T),
		muiColumnDragHandleProps: ({ table }) => ({
			onDragEnd: () => {
				const { draggingColumn, hoveredColumn } = table.getState();
				if (draggingColumn && hoveredColumn) {
					onColumnDragEnd(draggingColumn, hoveredColumn);
				}
			}
		}),
		muiRowDragHandleProps: ({ table }) => ({
			onDragEnd: () => {
				const { draggingRow, hoveredRow } = table.getState();
				if (hoveredRow && draggingRow) {
					onRowDragEnd(draggingRow, hoveredRow);
				}
			}
		}),
		muiEditTextFieldProps: ({ cell }) => ({
			//onBlur is more efficient, but could use onChange instead
			onBlur: (event) => {
				onRowEdit(
					cell.row.original as T,
					cell.column.id,
					event.target.value as T[keyof T]
				);
			}
		}),
		muiSearchTextFieldProps: () => ({
			autoFocus: true
		}),
		muiSelectAllCheckboxProps: ({ table }) => ({
			sx: {
				...(table.getRowModel().rows.every((row) => !hasRowSelection(row)) && {
					display: 'none'
				})
			}
		}),
		muiSelectCheckboxProps: ({ row }) => ({
			sx: {
				...(!hasRowSelection(row) && { display: 'none' })
			}
		}),
		muiDetailPanelProps: () => ({
			sx: {
				width: '100%'
			}
		}),
		muiTableHeadCellProps: {
			align: !enabledColumnDragging ? 'center' : 'left',
			sx: {
				backgroundColor: '#ededed'
			}
		},
		muiTableBodyRowProps: ({ row }) => ({
			onClick: (event) => {
				handleClick(event, row.original as T);
			},
			sx: {
				cursor: 'pointer',
				backgroundColor:
					rowThemeColor && getThemeColor(rowThemeColor(row.original as T))
			}
		}),
		enableExpandAll: false,
		...(detailPanel && {
			renderDetailPanel: ({ row }) => detailPanel(row.original as T)
		}),
		renderBottomToolbar: ({ table }) => (
			<div className="d-flex">
				{displayTotalRows && (
					<div className="d-flex align-items-center gap-2 p-3">
						<span>
							<i className="me-2 fas fa-fw fa-database" />
							<span>Total Records:&nbsp;</span>
							<span className="text-monospace">
								{formatter.format(totalRows ?? 0)}
							</span>
						</span>
						<span className="text-danger border-bottom border-danger">
							<i className="me-2 fas fa-fw fa-table" />
							<span>Records Loaded:&nbsp;</span>
							<span className="text-monospace">
								{formatter.format(data.length)}
							</span>
						</span>
					</div>
				)}
				{footerAction && footerAction()}
				<div className="ms-auto">
					<MRT_TablePagination table={table} />
				</div>
			</div>
		),
		renderTopToolbar: ({ table }) => (
			<div className="d-flex justify-content-between p-2">
				<div className="d-flex">
					{!disableControls && (
						<>
							<MRT_GlobalFilterTextField table={table} />
							<MRT_ToggleFiltersButton table={table} />
							{!disableConfigurationStore && (
								<ConfigurationStoreActions
									isDefaultConfigurationApplied={isDefaultConfigurationApplied}
									handleResetConfigurations={handleResetConfigurations}
									handleSaveConfigurations={handleSaveConfigurations}
									isLoadingConfiguration={isLoadingConfiguration}
									isSavingConfiguration={isSavingConfiguration}
								/>
							)}
							{enableHiding && (
								<MRT_ShowHideColumnsButton
									table={table}
									color={hasHiddenColumn ? 'info' : 'default'}
								/>
							)}
							<Tooltip title="Enable/Disable column dragging" arrow>
								<IconButton
									type="button"
									color={enabledColumnDragging ? 'info' : 'default'}
									onClick={() => setEnabledColumnDragging((action) => !action)}
								>
									<DragHandle />
								</IconButton>
							</Tooltip>
							<MRT_ToggleFullScreenButton table={table} />
						</>
					)}
					{onClickRefetch && (
						<Tooltip title="Refresh data" arrow>
							<IconButton
								type="button"
								color="default"
								onClick={onClickRefetch}
							>
								<Refresh />
							</IconButton>
						</Tooltip>
					)}
				</div>
				<div className="d-flex align-items-center">
					{headerAction !== undefined && headerAction(table as any)}
					{onClickCreate && (
						<Tooltip title="Add new" arrow>
							<IconButton
								type="button"
								color="default"
								onClick={() => onClickCreate()}
							>
								<Add />
							</IconButton>
						</Tooltip>
					)}
					{!disableExport && (
						<>
							<Box sx={{ position: 'relative' }}>
								<Tooltip title="Copy to clipboard" arrow>
									<IconButton
										type="button"
										color="default"
										sx={{
											width: 40,
											height: 40
										}}
										onClick={() => copyToClipboardHandler(table as any)}
									>
										<i className="fas fa-clipboard-list fa-xs" />
									</IconButton>
								</Tooltip>
								{isExporting && (
									<CircularProgress
										size={40}
										color="secondary"
										sx={{
											position: 'absolute',
											zIndex: 1,
											inset: 0
										}}
									/>
								)}
							</Box>
							<Box sx={{ position: 'relative' }}>
								<Tooltip title="Export CSV" arrow>
									<IconButton
										type="button"
										color="default"
										sx={{
											width: 40,
											height: 40
										}}
										onClick={() => exportToCsvHandler(table as any)}
									>
										<i className="fas fa-file-csv fa-xs" />
									</IconButton>
								</Tooltip>
								{isExporting && (
									<CircularProgress
										size={40}
										color="secondary"
										sx={{
											position: 'absolute',
											zIndex: 1,
											inset: 0
										}}
									/>
								)}
							</Box>
							<Box sx={{ position: 'relative' }}>
								<Tooltip title="Export Excel" arrow>
									<IconButton
										type="button"
										color="default"
										sx={{
											width: 40,
											height: 40
										}}
										onClick={() => exportToExcelHandler(table as any)}
									>
										<i className="fas fa-file-excel fa-xs" />
									</IconButton>
								</Tooltip>
								{isExporting && (
									<CircularProgress
										size={40}
										color="secondary"
										sx={{
											position: 'absolute',
											zIndex: 1,
											inset: 0
										}}
									/>
								)}
							</Box>
						</>
					)}
				</div>
			</div>
		)
	});

	useImperativeHandle(ref, () => ({
		...(table as any)
	}));

	return (
		<ThemeProvider theme={tableTheme}>
			{loading ? (
				<Skeleton data={[...Array(15).keys()]} columns={displayedColumns} />
			) : (
				<>
					<div style={{ maxWidth: '100%' }} className={className}>
						<MaterialReactTable table={table} />
					</div>
					<Menu id={menuId}>
						{selectedRow && (
							<>
								{internalMenuItem}
								{!router && menuLinks && menuLinks(selectedRow)}
								{externalMenuItems}
							</>
						)}
					</Menu>
				</>
			)}
		</ThemeProvider>
	);
};

const DataGrid = memo(forwardRef(DataGridComponent)) as <T = any>(
	props: DataGridProps<T> & {
		ref?: React.ForwardedRef<DataGridRef<T>>;
	}
) => ReturnType<typeof DataGridComponent>;

export default DataGrid;
