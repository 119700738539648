import * as React from 'react';
import { Button } from 'react-bootstrap';
import {
	FileStreamIndexResponse,
	GuardianDnoNumberTypes,
	GuardianDoNotOriginate
} from 'RtModels';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import { DoNotOriginateResource } from 'RtUi/app/rtVue/DoNotOriginate/lib/resources/DoNotOriginateResource';
import { NpaNxxInputFormControl } from 'RtUi/app/rtVue/lib/controls/NpaNxxInputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';

enum NumberLength {
	TenDigit,
	NpaNxx
}

interface IDoNotOriginateFormEditorProps {
	profile?: GuardianDoNotOriginate;
	onSuccess?: (profile: GuardianDoNotOriginate) => void;
}
interface IDoNotOriginateFormEditorState {
	isSubmitting: boolean;
	displayMode: boolean;
	active: number;
	numberLength: NumberLength;
	numberType?: ISimpleSelectFormControlOption<GuardianDnoNumberTypes>;
	fileStream?: FileStreamIndexResponse;
	ani: string[];
	error?: any;
}

export class DoNotOriginateFormEditor extends React.Component<
	IDoNotOriginateFormEditorProps,
	IDoNotOriginateFormEditorState
> {
	private resource = new DoNotOriginateResource();

	constructor(props: IDoNotOriginateFormEditorProps) {
		super(props);

		this.state = this.getInitialState(props);
	}

	public render() {
		const createMode = !this.props.profile;

		return (
			<RtUiNarrowForm
				createMode={createMode}
				isSubmitting={this.state.isSubmitting}
				displayMode={this.state.displayMode}
				error={this.state.error}
				onChange={(displayMode) => this.setState({ displayMode })}
				onCancel={this.onCancel}
				onSubmit={this.onSubmit}
			>
				<IsActiveRadioFormControl
					required
					hideBothOption
					displayMode={this.state.displayMode}
					onChange={(active) => this.setState({ active })}
					value={this.state.active}
				/>
				<RadioFormControl<NumberLength>
					label=""
					hideLabel
					onChange={(numberLength) => {
						this.setState({ numberLength });
					}}
					value={this.state.numberLength}
					options={[
						{ value: NumberLength.TenDigit, label: '10 Digit' },
						{ value: NumberLength.NpaNxx, label: 'NpaNxx' }
					]}
				/>
				<SimpleSelectFormControl<GuardianDnoNumberTypes>
					label="Number Type"
					displayMode={this.state.displayMode}
					value={this.state.numberType}
					onChange={(newFieldType) => {
						this.setState({ numberType: newFieldType });
					}}
					initialOptionId={
						this.props.profile
							? String(this.props.profile.numberType)
							: GuardianDnoNumberTypes.Dno.toString()
					}
					options={[
						{
							value: GuardianDnoNumberTypes.Dno,
							label: 'Do Not Originate'
						},
						{
							value: GuardianDnoNumberTypes.WhiteList,
							label: 'White List'
						}
					]}
				/>
				{this.state.ani.map((ani, idx) => (
					<div className="d-flex align-items-center" key={`ani_key_${idx}`}>
						{this.state.numberLength === NumberLength.TenDigit && (
							<TollFreeNumberFormControl
								label="ANI"
								displayMode={Boolean(this.props.profile)}
								required
								alwaysShowMask
								onlyNumbers
								onChange={this.handleAniChange(idx)}
								value={ani}
							/>
						)}
						{this.state.numberLength === NumberLength.NpaNxx && (
							<NpaNxxInputFormControl
								label="NpaNxx"
								displayMode={Boolean(this.props.profile)}
								value={ani}
								required
								onChange={this.handleAniChange(idx)}
							/>
						)}
						{!this.props.profile && this.state.ani.length > 1 && (
							<Button
								className="ml-1 mt-3"
								variant="outline-danger"
								onClick={this.handleRemoveShareholder(idx)}
								title="Remove ANI"
							>
								<i className="fas fa-fw fa-times" />
							</Button>
						)}
					</div>
				))}
				{!this.props.profile && (
					<Button
						title="Add ANI"
						variant="outline-primary"
						onClick={this.addAniHandler}
					>
						<i className="fas fa-fw fa-plus" /> Add ANI
					</Button>
				)}
			</RtUiNarrowForm>
		);
	}

	private getInitialState(
		props: IDoNotOriginateFormEditorProps
	): IDoNotOriginateFormEditorState {
		let numberLength = NumberLength.TenDigit;

		if (props.profile?.ani.length === 6) {
			numberLength = NumberLength.NpaNxx;
		}

		return {
			isSubmitting: false,
			displayMode: Boolean(props.profile),
			numberLength,
			active: props.profile?.active ?? 1,
			ani: props.profile ? [props.profile.ani] : ['']
		};
	}

	private onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const { onSuccess = () => {} } = this.props;

		try {
			let profile: GuardianDoNotOriginate;
			this.setState({ isSubmitting: true, error: undefined });

			if (!this.props.profile) {
				profile = await this.resource.create({
					ani: this.state.ani,
					active: this.state.active,
					numberType: this.state.numberType!.value
				});
			} else {
				profile = await this.resource.update(this.props.profile.ani, {
					active: this.state.active,
					numberType: this.state.numberType!.value
				});
			}
			this.setState({ displayMode: true, isSubmitting: false }, () => {
				onSuccess(profile);
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	};

	private onCancel = () => {
		this.setState(this.getInitialState(this.props));
	};

	private handleAniChange = (idx: number) => (value: string) => {
		const ani = this.state.ani.map((ani, i) => {
			if (idx !== i) return ani;
			return value;
		});

		this.setState({ ani });
	};

	private handleRemoveShareholder = (idx: number) => (e: React.MouseEvent) => {
		e.preventDefault();

		this.setState({
			ani: this.state.ani.filter((s: string, i: number) => idx !== i)
		});
	};

	private addAniHandler = (e: React.MouseEvent) => {
		e.preventDefault();

		this.setState({
			ani: this.state.ani.concat([''])
		});
	};
}
