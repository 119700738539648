import { CurrencyFormatter } from 'RtUi/utils/currency/CurrencyFormatter';
import { SpreadsheetParser } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParser';
import { NumberFormatter } from 'RtUi/utils/number/NumberFormatter';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';
import { isNil } from 'lodash-es';
import moment from 'moment';

export class SpreadsheetParserEmitValidation extends SpreadsheetParser {
	public static formatErrorMessage(
		fieldName: string,
		rowIndex: number,
		validationMessage = ''
	): string {
		return `Error at row: ${rowIndex} - \'${fieldName}\' ${validationMessage}`;
	}

	protected readonly MAX_ERROR_MESSAGES_EMIT_LIMIT = 100;
	protected unknownFieldsMap = new Map<string, number>();
	protected totalErrors: number = 0;

	protected emitErrorMessage() {
		if (this.unknownFieldsMap.size > 0) {
			for (const [key, value] of this.unknownFieldsMap) {
				this.emit(
					'error',
					`There is ${value} values set as unknown for \"${key}\" field`
				);
			}
		}
	}

	protected emitValidationCheck(
		fieldName: string,
		fieldValue: any,
		rowIndex: number
	) {
		if (this.totalErrors > this.MAX_ERROR_MESSAGES_EMIT_LIMIT) {
			return fieldValue;
		}

		const column = this.columns.find(
			(column) => column.getColumnName() === fieldName
		);

		if (!column) {
			console.warn(
				'Can not find column config for validation, please double check configuration.'
			);
			return fieldValue;
		}

		const errorMessages: string[] = [];

		if (column.isRequired()) {
			const isDefined = !isNil(fieldValue);

			if (!isDefined && column.getDefaultValue()) {
				this.countAsUnknownField(fieldName);
				fieldValue = column.getDefaultValue();
			} else if (!isDefined) {
				const columnSearchKeywords = column
					.getSearchKeywords()
					.filter((name) => name !== fieldName)
					.map((name) => `'${name}'`);

				const message = columnSearchKeywords.length
					? `is missing, please validate that the column header matches ${columnSearchKeywords.join(
							', '
						)} and the value is not empty`
					: 'is missing, please validate column position';

				errorMessages.push(message);
			}
		}

		if (column.getIsInteger() && !NumberFormatter.isInteger(fieldValue)) {
			errorMessages.push('incorrect integer number');
		}

		if (column.getIsDecimal() && !NumberFormatter.isDecimal(fieldValue)) {
			errorMessages.push('incorrect decimal number');
		}

		if (column.getIsCurrency() && !CurrencyFormatter.isCurrency(fieldValue)) {
			errorMessages.push('incorrect currency value');
		}

		if (column.getIsDate() && !moment(fieldValue).isValid()) {
			errorMessages.push('incorrect date');
		}

		const minLength = column.getMinLength();
		if (
			minLength !== undefined &&
			typeof fieldValue === 'string' &&
			fieldValue.length < minLength
		) {
			if (column.getDefaultValue()) {
				this.countAsUnknownField(fieldName);
				fieldValue = column.getDefaultValue();
			} else {
				errorMessages.push(
					`should contain at least ${column.getMinLength()} chars`
				);
			}
		}

		if (errorMessages.length === 0 && fieldValue && column.getIsPhoneNumber()) {
			const phoneNumberFormatter = new PhoneNumberFormatter();
			const awesomePhone = phoneNumberFormatter.toPhoneNumber(fieldValue);
			const isValid = awesomePhone.valid;
			const isPossible = awesomePhone.possible;
			if (!isValid || !isPossible) {
				errorMessages.push(`appears to be an invalid phone number`);
			}
		}

		if (errorMessages.length > 0) {
			this.emit(
				'error',
				SpreadsheetParserEmitValidation.formatErrorMessage(
					fieldName,
					rowIndex,
					errorMessages.join(', ')
				)
			);
			this.totalErrors += errorMessages.length;
		}

		return fieldValue;
	}

	protected countAsUnknownField(fieldName: string) {
		const lastUnknownFieldCount = this.unknownFieldsMap.get(fieldName);

		if (!lastUnknownFieldCount) {
			this.unknownFieldsMap.set(fieldName, 1);
		} else {
			this.unknownFieldsMap.set(fieldName, lastUnknownFieldCount + 1);
		}
	}
}
