import { DidApplicationRouter } from 'RtUi/app/rtDid/rtDid.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { DidDashboardRouter } from 'RtUi/app/rtDid/Dashboard/DidDashboard.router';
import { IRouterBreadcrumb } from 'RtUi/components/containers/lib/RtUiRouter';
import { RtxDidApiRoutes } from 'RtExports/routes';

interface IDidDrilldownProfileContainerTabs
	extends IProfileApplicationContainerTabs {}
export const DidDrilldownProfileContainerTabs: IDidDrilldownProfileContainerTabs =
	{};

export class DidDrilldownRouterClass extends DidApplicationRouter<
	void,
	void,
	IDidDrilldownProfileContainerTabs
> {
	constructor() {
		super(
			'Drilldown',
			'didDrilldown',
			undefined,
			DidDrilldownProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxDidApiRoutes.Numbers);
	}

	/**
	 * @override
	 * @param currentPath
	 */
	public async getBreadcrumbs(
		currentPath: string
	): Promise<IRouterBreadcrumb[]> {
		const sectionBreadcrumb = super.getSectionBreadcrumb();
		const didDashBreadcrumb = DidDashboardRouter.getIndexBreadcrumb();
		const drilldownBreadcrumb = this.getIndexBreadcrumb(true);

		return [sectionBreadcrumb, didDashBreadcrumb, drilldownBreadcrumb];
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getIndexLabel(record: undefined) {
		return '';
	}

	public getProfileLabel(profile: undefined) {
		return '';
	}
}

export const DidDrilldownRouter = new DidDrilldownRouterClass();
