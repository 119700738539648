import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianServiceNumberSubscriptionBulkUpdateResponse,
	GuardianServiceNumberSubscriptionCreateRequestBulk,
	GuardianServiceNumberSubscriptionCreateRequestType,
	GuardianServiceNumberSubscriptionIndexRequest,
	GuardianServiceNumberSubscriptionIndexRequestBulk,
	GuardianServiceNumberSubscriptionIndexResponse,
	GuardianServiceNumberSubscriptionProfileResponse,
	GuardianServiceNumberSubscriptionProfileUrlRequest,
	GuardianServiceNumberSubscriptionUpdateRequest,
	GuardianServiceNumberSubscriptionUpdateRequestBulk,
	GuardianServiceNumberSubscriptionWithGlobalData
} from 'RtModels';
import { convertDateToUTC } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class GuardianServiceNumberSubscriptionResource extends HttpResource<
	GuardianServiceNumberSubscriptionWithGlobalData,
	GuardianServiceNumberSubscriptionProfileResponse,
	GuardianServiceNumberSubscriptionIndexRequest
> {
	constructor() {
		super(RtVueApiRoutes.GuardianServiceNumberSubscriptions);
	}

	public create(request: GuardianServiceNumberSubscriptionCreateRequestType) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianServiceNumberSubscriptionProfileResponse>(
			RtVueApiRoutes.GuardianServiceNumberSubscriptions.Create,
			{
				body
			}
		);
	}

	public update(
		guardianServiceNumberSubscriptionId: number,
		request: GuardianServiceNumberSubscriptionUpdateRequest
	) {
		const body = JSON.stringify(request);
		const urlParams: GuardianServiceNumberSubscriptionProfileUrlRequest = {
			guardianServiceNumberSubscriptionId
		};

		return this.fetchWithRoute<GuardianServiceNumberSubscriptionProfileResponse>(
			RtVueApiRoutes.GuardianServiceNumberSubscriptions.Update,
			{
				body,
				urlParams
			}
		);
	}

	public cancel(guardianServiceNumberSubscriptionId: number) {
		const urlParams: GuardianServiceNumberSubscriptionProfileUrlRequest = {
			guardianServiceNumberSubscriptionId
		};

		return HttpRequest.fetchWithRoute(
			RtVueApiRoutes.GuardianServiceNumberSubscriptions.Delete,
			{
				urlParams
			}
		);
	}

	public bulkGet(request: GuardianServiceNumberSubscriptionIndexRequestBulk) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<
			GuardianServiceNumberSubscriptionIndexResponse[]
		>(RtVueApiRoutes.GuardianServiceNumberSubscriptionsBulk.Index, {
			body,
			disableNotification: true
		});
	}

	public bulkCreate(
		request: GuardianServiceNumberSubscriptionCreateRequestBulk
	) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianServiceNumberSubscriptionBulkUpdateResponse>(
			RtVueApiRoutes.GuardianServiceNumberSubscriptionsBulk.Create,
			{
				body
			}
		);
	}

	public bulkUpdate(
		request: GuardianServiceNumberSubscriptionUpdateRequestBulk
	) {
		if (request.decommissionedTs) {
			request.decommissionedTs = convertDateToUTC(request.decommissionedTs);
		}
		if (request.endTs) {
			request.endTs = convertDateToUTC(request.endTs);
		}

		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianServiceNumberSubscriptionBulkUpdateResponse>(
			RtVueApiRoutes.GuardianServiceNumberSubscriptionsBulk.Update,
			{
				body
			}
		);
	}

	public createNewProfile() {
		const profile: GuardianServiceNumberSubscriptionProfileResponse = {
			serviceNumber: '',
			guardianServiceNumberSubscriptionId: -1,
			subscriptionId: -1,
			originationNumberId: -1,
			directionId: 1,
			callType: '',
			serviceTypeId: -1,
			startTs: new Date(),
			endTs: null,
			isActive: 1,
			createdTs: new Date(),
			updatedTs: new Date(),
			updatedBy: -1
		};

		return profile;
	}
}
