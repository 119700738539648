import { FC } from 'react';
import { Card } from 'react-bootstrap';
import { SipTraceIndexRequest } from 'RtModels';
import { SipTraceResource } from 'RtUi/app/rtSip/SipTrace/lib/resource/SipTraceResource';
import { SipTraceView } from 'RtUi/app/rtSip/SipTrace/lib/SipTraceView';
import { Aside } from 'RtUi/components/ui/Aside';

interface ISipTraceDetailsAsideProps {
	show: boolean;
	toggle: () => void;
	resourceParams: SipTraceIndexRequest;
	onCancel?: () => void;
	callId: string;
}

export const SipTraceDetailsAside: FC<
	React.PropsWithChildren<ISipTraceDetailsAsideProps>
> = ({ show, toggle, resourceParams, callId }) => {
	const resource = new SipTraceResource(resourceParams);
	return (
		<Aside fullscreen isOpen={show} disabledBody>
			<Aside.Header
				warning
				header={`SIP Trace: ${callId}`}
				onClose={() => {
					toggle();
				}}
			/>
			<Card.Body>
				<SipTraceView resource={resource} callId={callId} />
			</Card.Body>
		</Aside>
	);
};
