/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DataPreset = {
    properties: {
        reportType: {
            type: 'Reports',
            isRequired: true,
        },
        reportPreset: {
            type: 'ReportDataColumnPresets',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        columnDescriptions: {
            type: 'string',
            isRequired: true,
        },
        directionPreset: {
            type: 'DirectionPreset',
            isRequired: true,
        },
    },
};