import { LcrCarrierIndexRequest, LcrCarrierIndexResponse } from 'RtModels';
import { useGetCarriers } from 'RtUi/app/rtLco/Carriers/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCarrierColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/carriers';
import { useMemo } from 'react';
import { LcrCarrierRouter } from '../../LcrCarrier.router';

interface ILcrCarriersGridProps {
	resourceParams?: LcrCarrierIndexRequest;
}

export const LcrCarriersGrid = ({ resourceParams }: ILcrCarriersGridProps) => {
	const { data, isFetching: isLoading } = useGetCarriers(resourceParams);
	const columns = useMemo(() => getCarrierColumns(), []);

	return (
		<DataGrid<LcrCarrierIndexResponse>
			pageName="rtLco-carriers"
			loading={isLoading}
			data={data?.data}
			router={LcrCarrierRouter}
			totalRows={data?.totalCount}
			columns={columns}
		/>
	);
};
