import { GuardianSubscriptionCicProfileResponse } from 'RtModels';
import { SubscriptionCicFormEditor } from 'RtUi/app/rtVue/SubscriptionCic/lib/form/SubscriptionCicFormEditor';
import { SubscriptionCicResource } from 'RtUi/app/rtVue/SubscriptionCic/lib/resources/SubscriptionCicResource';
import { SubscriptionCicRouter } from 'RtUi/app/rtVue/SubscriptionCic/SubscriptionCic.router';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface ISubscriptionCicProfileContainerProps {}

interface ISubscriptionCicProfileContainerState {
	activeTab: string;
	profile?: GuardianSubscriptionCicProfileResponse;
}

@SubscriptionCicRouter.getProfileRtUiController()
export class SubscriptionCicProfileContainer extends ProfileApplicationContainer<
	ISubscriptionCicProfileContainerProps,
	ISubscriptionCicProfileContainerState
> {
	public Tabs = SubscriptionCicRouter.getProfileTabs();

	public state: ISubscriptionCicProfileContainerState = {
		activeTab: this.Tabs.Profile.header
	};

	public componentDidMount() {
		const profileId = this.getIdParam();
		const resource = new SubscriptionCicResource();

		resource.get(profileId).then((profile) => this.setState({ profile }));
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}

		const canManage = SubscriptionCicRouter.canManageWith(profile);

		return (
			<TabbedLayout<GuardianSubscriptionCicProfileResponse>
				router={SubscriptionCicRouter}
				profile={profile}
				canManageFn={() => canManage}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<SubscriptionCicFormEditor editMode={profile} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
