import { DialCodeIndexRequest, DialCodeIndexResponse } from 'RtModels';
import { useGetDialCodeData } from 'RtUi/app/rtVue/DialCode/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getDialCodeColumns } from 'RtUi/components/data/DataGrid/configurations/administration/rateSheet/dialCodes';
import { useMemo } from 'react';

interface IDialCodeGridProps {
	resourceParams?: DialCodeIndexRequest;
}

export const DialCodeGrid = ({ resourceParams }: IDialCodeGridProps) => {
	const { data: dialCodeData, isLoading: dialCodeLoading } =
		useGetDialCodeData(resourceParams);

	const dialCodeGridColumns = useMemo(() => getDialCodeColumns(), []);

	return (
		<DataGrid<DialCodeIndexResponse>
			data={dialCodeData?.data}
			columns={dialCodeGridColumns}
			pageName={'rateSheet-dialCode-dialCodeGrid'}
			loading={dialCodeLoading}
			totalRows={dialCodeData?.totalCount}
		/>
	);
};
