import * as moment from 'moment';
import * as React from 'react';
import {
	AccountIndexResponse,
	CdrExportControlIndexResponse,
	CdrExportServiceIndexResponse,
	CdrExportSourceTypes,
	ServiceIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { CdrRecurringExportResource } from 'RtUi/app/Administration/CdrRecurringExport/lib/resources/CdrRecurringExportResource';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { CdrExportIntervalSelect } from 'RtUi/app/rtSip/CdrExports/lib/controls/CdrExportIntervalSelect';
import { CdrExportRetentionSelect } from 'RtUi/app/rtSip/CdrExports/lib/controls/CdrExportRetentionSelect';
import { CdrExportServiceSelect } from 'RtUi/app/rtSip/CdrExports/lib/controls/CdrExportServiceSelect';
import { CdrExportTypeIdsControl } from 'RtUi/app/rtSip/CdrExports/lib/controls/CdrExportTypeIdsRadioFormControl';
import { ICdrExportIntervalIndex } from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportIntervalResource';
import { ICdrExportRetentionIndex } from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportRetentionResource';
import { ServiceSelect } from 'RtUi/app/rtSip/Services/lib/controls/ServiceSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';

interface ICdrRecurringExportEditorProps {
	createMode?: boolean;
	editMode?: CdrExportControlIndexResponse;
	onCreate?: (profile: CdrExportControlIndexResponse) => void;
	onUpdate?: (profile: CdrExportControlIndexResponse) => void;
}

interface ICdrRecurringExportEditorState {
	isSubmitting: boolean;
	displayMode: boolean;
	error?: any;
	//form
	isActive: number;
	cdrSourceTypeId: CdrExportSourceTypes | undefined;
	service?: ServiceIndexResponse;
	cdrExportService?: CdrExportServiceIndexResponse;
	account?: AccountIndexResponse;
	subscription?: SubscriptionIndexResponse;
	//cdrSourceTypeId: number;
	//cdrSourceValue: number;
	cdrExportInterval?: ICdrExportIntervalIndex;
	cdrExportRetention?: ICdrExportRetentionIndex;
	isConnected: number;
	nextRunTime: moment.Moment;
	defaultCdrExportRetentionId?: string;
}

export class CdrRecurringExportEditor extends React.Component<
	ICdrRecurringExportEditorProps,
	ICdrRecurringExportEditorState
> {
	public state: ICdrRecurringExportEditorState = {
		isSubmitting: false,
		displayMode: true,
		cdrSourceTypeId: CdrExportSourceTypes.Account,
		error: undefined,
		isActive: 1,
		isConnected: 1,
		nextRunTime: moment()
	};

	constructor(props: ICdrRecurringExportEditorProps) {
		super(props);

		if (this.props.createMode) {
			this.state.displayMode = false;
		} else if (!this.props.editMode) {
			throw new Error(
				'CdrRecurringExportEditor: Must be in create or edit mode'
			);
		}
	}

	public componentDidMount() {
		this.init();
	}

	public init() {
		const { editMode } = this.props;
		let isActive = 1;
		let isConnected = 1;
		const cdrExportService = undefined;
		let cdrSourceTypeId = CdrExportSourceTypes.Account;
		const cdrExportInterval = undefined;
		const cdrExportRetention = undefined;
		let nextRunTime = moment();
		let defaultCdrExportRetentionId: string | undefined;

		if (editMode) {
			isActive = editMode.isActive;
			isConnected = editMode.isConnected;
			nextRunTime = moment(editMode.nextRunTimeTs);
			cdrSourceTypeId = editMode.cdrSourceTypeId;
			defaultCdrExportRetentionId = String(editMode.cdrRetentionId);
		}

		this.setState({
			isActive,
			isConnected,
			cdrExportService,
			nextRunTime,
			cdrExportInterval,
			cdrExportRetention,
			cdrSourceTypeId,
			defaultCdrExportRetentionId
		});
	}

	public onCancel() {
		this.init();
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();
		const {
			isActive,
			isConnected,
			cdrExportService,
			cdrExportInterval,
			cdrExportRetention,
			nextRunTime,
			cdrSourceTypeId
		} = this.state;
		let cdrSourceValue: number;
		let cdrRecurringExportProfile: CdrExportControlIndexResponse | null = null;

		if (!cdrExportService || !cdrExportInterval || !cdrExportRetention) {
			return;
		}

		this.setState({ isSubmitting: true, error: undefined });

		try {
			if (cdrSourceTypeId === CdrExportSourceTypes.Account) {
				const { account } = this.state;

				if (!account) {
					throw new Error('Account must be filled out');
				}

				cdrSourceValue = account.accountId;
			} else if (cdrSourceTypeId === CdrExportSourceTypes.Subscription) {
				const { subscription } = this.state;

				if (!subscription) {
					throw new Error('Subscription must be filled out');
				}

				cdrSourceValue = subscription.subscriptionId;
			} else {
				throw new Error('Must fill in either Account or Subscription fields');
			}

			const { cdrExportServiceId } = cdrExportService;
			const cdrExportIntervalId = cdrExportInterval.intervalId;
			const cdrExportRetentionId = cdrExportRetention.retentionId;
			const nextRunTimeTs = nextRunTime.toDate();

			const cdrRecurringExportResource = new CdrRecurringExportResource();

			if (this.props.createMode) {
				cdrRecurringExportProfile = await cdrRecurringExportResource.create(
					isActive,
					cdrSourceTypeId,
					cdrSourceValue,
					cdrExportServiceId,
					cdrExportIntervalId,
					cdrExportRetentionId,
					isConnected,
					nextRunTimeTs
				);
			} else if (this.props.editMode) {
				const { cdrExportControlId } = this.props.editMode;

				cdrRecurringExportProfile = await cdrRecurringExportResource.update(
					cdrExportControlId,
					isActive,
					cdrSourceTypeId,
					cdrSourceValue,
					cdrExportServiceId,
					cdrExportIntervalId,
					cdrExportRetentionId,
					isConnected,
					nextRunTimeTs
				);
			}

			this.setState({ displayMode: true });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (cdrRecurringExportProfile) {
			const { onCreate = () => ({}), onUpdate = () => ({}) } = this.props;

			if (this.props.createMode) {
				onCreate(cdrRecurringExportProfile);
			} else if (this.props.editMode) {
				onUpdate(cdrRecurringExportProfile);
			}
		}
	}

	public render() {
		const { editMode } = this.props;
		let defaultCdrExportServiceId = '0';
		let defaultCdrExportIntervalId: string | undefined;
		let defaultCdrSourceValue: string | undefined = '0';
		let defaultServiceId: number | undefined;

		if (editMode) {
			defaultCdrExportServiceId =
				typeof editMode.cdrExportServiceId === 'number'
					? String(editMode.cdrExportServiceId)
					: '0';
			defaultCdrExportIntervalId = String(editMode.cdrExportIntervalId);
			defaultCdrSourceValue = editMode.cdrSourceValue
				? String(editMode.cdrSourceValue)
				: '0';
			defaultServiceId = editMode.serviceId ?? undefined;
		}

		return (
			<RtUiNarrowForm
				createMode={this.props.createMode}
				isSubmitting={this.state.isSubmitting}
				displayMode={this.state.displayMode}
				error={this.state.error}
				onCancel={() => this.onCancel()}
				onChange={(displayMode) => this.setState({ displayMode })}
				onSubmit={(evt) => this.onSubmit(evt)}
			>
				<ServiceSelect
					required
					showAllOption
					displayMode={this.state.displayMode}
					onChange={(service) =>
						this.setState({ service, cdrExportService: undefined })
					}
					value={this.state.service}
					initialOptionId={
						typeof defaultServiceId === 'number'
							? String(defaultServiceId)
							: '0'
					}
				/>
				<CdrExportServiceSelect
					required
					displayMode={this.state.displayMode}
					value={this.state.cdrExportService}
					serviceId={this.state.service?.serviceId ?? defaultServiceId}
					initialOptionId={defaultCdrExportServiceId}
					onChange={(cdrExportService) => {
						if (cdrExportService.cdrExportServiceId === 6) {
							this.setState({
								cdrExportInterval: undefined,
								defaultCdrExportRetentionId: '6'
							});
						}

						this.setState({ cdrExportService });
					}}
				/>
				{!this.state.displayMode && (
					<CdrExportTypeIdsControl
						required
						value={this.state.cdrSourceTypeId}
						onChange={(cdrSourceTypeId) =>
							this.setState({
								cdrSourceTypeId,
								subscription: undefined,
								account: undefined
							})
						}
					/>
				)}
				{this.state.cdrSourceTypeId === CdrExportSourceTypes.Account && (
					<AccountSelect
						required
						showAllOption
						displayMode={this.state.displayMode}
						onChange={(account) => this.setState({ account })}
						value={this.state.account}
						initialOptionId={defaultCdrSourceValue}
					/>
				)}
				{this.state.cdrSourceTypeId === CdrExportSourceTypes.Subscription && (
					<SubscriptionSelect
						required
						showAllOption
						displayMode={this.state.displayMode}
						onChange={(subscription) => this.setState({ subscription })}
						value={this.state.subscription}
						initialOptionId={Number(defaultCdrSourceValue)}
					/>
				)}
				<CdrExportIntervalSelect
					required
					displayMode={this.state.displayMode}
					onChange={(cdrExportInterval) => this.setState({ cdrExportInterval })}
					value={this.state.cdrExportInterval}
					initialOptionId={defaultCdrExportIntervalId}
				/>
				<CdrExportRetentionSelect
					required
					displayMode={this.state.displayMode}
					onChange={(cdrExportRetention) =>
						this.setState({ cdrExportRetention })
					}
					value={this.state.cdrExportRetention}
					initialOptionId={this.state.defaultCdrExportRetentionId}
				/>
				<DatetimeFormControl
					label="Next Run Time"
					required
					disableNowPreset
					displayMode={this.state.displayMode}
					onChange={(nextRunTime) => this.setState({ nextRunTime })}
					value={this.state.nextRunTime}
				/>
				<BooleanRadioFormControl
					label="Only Export Connected Calls"
					required
					displayMode={this.state.displayMode}
					onChange={(isConnected) => this.setState({ isConnected })}
					value={this.state.isConnected}
				/>
				<IsActiveRadioFormControl
					label="Status"
					required
					hideBothOption
					displayMode={this.state.displayMode}
					onChange={(isActive) => this.setState({ isActive })}
					value={this.state.isActive}
				/>
			</RtUiNarrowForm>
		);
	}
}
