/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RerateQueryParameters = {
    properties: {
        filters: {
            type: 'Array',
            isRequired: true,
        },
        timeRange: {
            type: 'TimeFilter',
            isRequired: true,
        },
    },
};