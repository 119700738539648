import { RtxSipApiRoutes } from 'RtExports/routes';
import { CdrExportIndexResponse, CdrExportProfileResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';

class CdrRouterClass extends SipApplicationRouter<
	CdrExportIndexResponse,
	CdrExportProfileResponse,
	{}
> {
	constructor() {
		super('CDR Export', 'cdrExports', 'cdrExportId', {});

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.CdrExport);
	}

	public getProfileLabel(profile: CdrExportProfileResponse) {
		return '';
	}
}

export const CdrRouter = new CdrRouterClass();
