import { LcoSummaryIndexResponse } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLcoExportColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/lcoExport';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { memo, useMemo } from 'react';
import { useQuery } from 'react-query';

interface ILcoSummaryGridProps extends IRtVueReportDataGridProps {
	isLoading?: boolean;
	resource: HttpResource<LcoSummaryIndexResponse> | null;
}

export const LcoSummaryGrid = memo(
	({ resource, isLoading }: ILcoSummaryGridProps) => {
		const { data = [] } = useQuery<
			LcoSummaryIndexResponse[] | undefined,
			Error
		>([`useGetLcoSummaryReport`, resource], () => resource?.getAll());
		const columns = useMemo(() => getLcoExportColumns(), []);

		return (
			<DataGrid<LcoSummaryIndexResponse>
				pageName="rtVue_lcoExport"
				data={data}
				columns={columns}
				loading={isLoading}
				totalRows={resource?.getTotalDbCount()}
			/>
		);
	}
);
