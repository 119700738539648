import { type CprErrorType, type CprSection } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

// exported definitions
// ======================================================================

export interface ICprErrorCleanData {
	valuesToAdd?: string[];
	valuesToRemove?: string[];
	wasRemoved?: boolean;
}

export class CprError {
	/**
	 * Given a collection of CprErrors, get their
	 * ICprErrorCleanData and combine them
	 */
	public static AggregateCleanData(errors: CprError[]) {
		const cleanData: ICprErrorCleanData = {};
		const valuesToRemoveMap = new Set<string>();
		const valuesToAddMap = new Set<string>();

		for (const error of errors) {
			const singleCleanData = error.getCleanData();

			if (!singleCleanData) {
				continue;
			}

			if (singleCleanData.valuesToAdd) {
				for (const valueToAdd of singleCleanData.valuesToAdd) {
					valuesToAddMap.add(valueToAdd);
				}
			}

			if (singleCleanData.valuesToRemove) {
				for (const valueToRemove of singleCleanData.valuesToRemove) {
					valuesToRemoveMap.add(valueToRemove);
				}
			}
		}

		if (valuesToRemoveMap.size > 0) {
			cleanData.valuesToRemove = Array.from(valuesToRemoveMap);
		}

		if (valuesToAddMap.size > 0) {
			cleanData.valuesToAdd = Array.from(valuesToAddMap);
		}

		if (Object.keys(cleanData).length <= 0) {
			return undefined;
		}

		return cleanData;
	}

	public readonly cprErrorTypeId: CprErrorType;
	public readonly cprSectionId: CprSection;
	public readonly cprIndex: number;
	public readonly cprValue: string | null;
	public readonly message: string;

	protected cleanData: ICprErrorCleanData | undefined;

	public constructor(cprErrorTypeId: CprErrorType, cprSectionId: CprSection, cprIdx: number, rawValue: string | null, message: string) {
		this.cprErrorTypeId = cprErrorTypeId;
		this.cprSectionId = cprSectionId;
		this.cprIndex = cprIdx;
		this.cprValue = rawValue;
		this.message = message;
	}

	public setCleanData(cleanData: ICprErrorCleanData | undefined) {
		if (typeof cleanData === 'undefined') {
			this.cleanData = undefined;
			return;
		}

		//Copy data so it cannot be changed externally
		this.cleanData = {};

		if (cleanData.valuesToAdd) {
			this.cleanData.valuesToAdd = [...cleanData.valuesToAdd];
		}

		if (cleanData.valuesToRemove) {
			this.cleanData.valuesToRemove = [...cleanData.valuesToRemove];
		}
	}

	public getCleanData() {
		return this.cleanData;
	}

}
