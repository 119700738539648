import { RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { LcrApplicationRouter } from 'RtUi/app/rtLcr/rtLcr.router';
import { SipAgentStatsIndexContainer } from 'RtUi/app/rtLcr/SipAgentStats/SipAgentStats.index';
import { SipAgentStatsRouter } from 'RtUi/app/rtLcr/SipAgentStats/SipAgentStats.router';
import { SubscriptionPeeringIndexContainer } from 'RtUi/app/rtLcr/SubscriptionPeering/SubscriptionPeering.index';
import { SubscriptionPeeringRouter } from 'RtUi/app/rtLcr/SubscriptionPeering/SubscriptionPeering.router';

@RtUiModule({
	route: {
		path: `/${LcrApplicationRouter.SectionKey}`,
		iconClass: 'fa-shuffle',
		name: 'RT/LCR',
		defaultOpen: false
	},
	controllers: [SubscriptionPeeringIndexContainer, SipAgentStatsIndexContainer],
	routers: [SubscriptionPeeringRouter, SipAgentStatsRouter]
})
export class RtLcrModule {}
