import { noop } from 'lodash-es';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { BlockedNumber, BlockedNumberUpdateRequest } from 'RtModels';
import { BlockNumberResource } from 'RtUi/app/rtSip/BlockNumbers/lib/resources/BlockNumbersResource';
import { RtxUiForm } from 'RtUi/components/rtx/form';
import { RtxUiIsActiveInput } from 'RtUi/components/rtx/inputs/IsActive/RtxUiIsActiveInput';
import { RtxUiPhoneNumberInput } from 'RtUi/components/rtx/inputs/PhoneNumber/RtxUiPhoneNumberInput';
import { RtxUiTextInput } from 'RtUi/components/rtx/inputs/Text/RtxUiTextInput';

interface IBlockNumberFormEditorProps {
	profile: BlockedNumber;
	onUpdate?: (profile: BlockedNumber) => void;
}

export const BlockNumberFormEditor = ({
	profile,
	onUpdate = noop
}: IBlockNumberFormEditorProps) => {
	const [displayMode, setDisplayMode] = useState<boolean>(true);
	const onSubmitSingle = async (data: BlockedNumberUpdateRequest) => {
		const newNumber = await new BlockNumberResource().update(
			profile.blockedNumberId,
			data
		);
		setDisplayMode(true);
		onUpdate(newNumber);
	};

	return (
		<Row>
			<Col md={8}>
				<RtxUiForm<BlockedNumberUpdateRequest>
					onSubmit={onSubmitSingle}
					displayMode={displayMode}
					onCancel={() => setDisplayMode(true)}
					onEdit={() => setDisplayMode((curr) => !curr)}
					defaultValues={{
						...profile,
						summary: profile.summary ?? ''
					}}
				>
					{({ control }) => (
						<>
							<Controller
								control={control}
								name="phoneNumber"
								render={({ field: { onChange, value } }) => (
									<RtxUiPhoneNumberInput
										required
										label="Phone Number"
										value={value}
										onChange={onChange}
										className="mb-3"
										displayMode
									/>
								)}
							/>
							<Controller
								control={control}
								name="summary"
								render={({ field: { onChange, value } }) => (
									<RtxUiTextInput
										required
										label="Summary"
										onChange={onChange}
										value={value}
										className="mb-3"
										displayMode={displayMode}
									/>
								)}
							/>
							<Controller
								control={control}
								name="isActive"
								render={({ field: { onChange, value } }) => (
									<RtxUiIsActiveInput
										required
										hideBothOption
										onChange={onChange}
										value={value}
										className="mb-3"
										displayMode={displayMode}
									/>
								)}
							/>
							<Controller
								control={control}
								name="blockAni"
								render={({ field: { onChange, value } }) => (
									<RtxUiIsActiveInput
										required
										hideBothOption
										label="Block ANI"
										onChange={onChange}
										value={value}
										className="mb-3"
										displayMode={displayMode}
									/>
								)}
							/>
							<Controller
								control={control}
								name="blockDestination"
								render={({ field: { onChange, value } }) => (
									<RtxUiIsActiveInput
										required
										hideBothOption
										label="Block Destination"
										onChange={onChange}
										value={value}
										className="mb-3"
										displayMode={displayMode}
									/>
								)}
							/>
						</>
					)}
				</RtxUiForm>
			</Col>
		</Row>
	);
};
