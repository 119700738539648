import { RtAdminRouter } from '../rtAdmin.router';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { ServerProfileResponse } from 'RtModels';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IServerRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

const PartitionContainerTabs: IServerRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class ServerRouterClass extends RtAdminRouter<
	ServerProfileResponse,
	ServerProfileResponse,
	IServerRouterTabs
> {
	constructor() {
		super('Servers', 'servers', 'serverId', PartitionContainerTabs);

		this.setPermissionsFromApiRoute(RtAdmApiRoutes.Servers);
	}

	public getProfileLabel(server: ServerProfileResponse): string {
		return server.serverId.toString();
	}
}

export const ServerRouter = new ServerRouterClass();
