import * as moment from 'moment';
import {
	MetricsTotalsByDateRequest,
	MetricsTotalsByDateResponse,
	MetricsByResourceRequest
} from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { RtxDidApiRoutes } from 'RtExports/routes';

export class DidMetricTotalsByDateResource extends ArrayResource<MetricsTotalsByDateResponse> {
	constructor(
		startMoment: moment.Moment,
		endMoment: moment.Moment,
		resourceId?: number
	) {
		super('date');

		const startDate = startMoment.toDate();
		const endDate = endMoment.toDate();
		const params: MetricsTotalsByDateRequest = { startDate, endDate };
		let urlParams: MetricsByResourceRequest | undefined;
		let route = RtxDidApiRoutes.MetricsByDay.Index;

		if (typeof resourceId === 'number') {
			route = RtxDidApiRoutes.MetricsByDay.Profile;
			urlParams = { resourceId };
		}

		this.setApiRouteForGetAll(route, { params, urlParams });
	}
}
