import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	TemplateLcrScenarioIndexResponse,
	TemplateLcrScenarioIndexRequest,
	TemplateLcrScenarioDeleteResponse,
	TemplateLcrScenarioCreateRequest
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';

export class TemplateLcrScenarioResource extends HttpResource<
	TemplateLcrScenarioIndexResponse,
	TemplateLcrScenarioIndexResponse
> {
	constructor(templateName: string) {
		super();

		const urlParams: TemplateLcrScenarioIndexRequest = { templateName };
		this.setApiModule(Rt800ApiRoutes.TemplateLcrScenarios, urlParams);
	}

	public addToTemplate(templateName: string, lcrScenarioId: number) {
		const req: TemplateLcrScenarioCreateRequest = { lcrScenarioId };
		const urlParams: TemplateLcrScenarioIndexRequest = { templateName };
		const body = JSON.stringify(req);

		return this.fetchWithRoute<TemplateLcrScenarioIndexResponse>(
			Rt800ApiRoutes.TemplateLcrScenarios.Create,
			{
				body,
				urlParams
			}
		);
	}

	public removeFromTemplate(
		templateName: string,
		templateLcrScenarioId: number
	) {
		const urlParams = { templateName, templateLcrScenarioId };

		return this.fetchWithRoute<TemplateLcrScenarioDeleteResponse>(
			Rt800ApiRoutes.TemplateLcrScenarios.Delete,
			{
				urlParams
			}
		);
	}
}
