import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	SipAgentCreateRequest,
	SipAgentDeleteResponse,
	SipAgentIndexResponse,
	SipAgentProfileResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class SipAgentsResource extends HttpResource<
	SipAgentIndexResponse,
	SipAgentProfileResponse
> {
	constructor() {
		super(RtCommonApiRoutes.SipAgents);
	}

	public getProfile(sipAgentId: number): Promise<SipAgentProfileResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.SipAgents.Profile, {
			urlParams: {
				sipAgentId
			}
		});
	}

	public create(req: SipAgentCreateRequest): Promise<SipAgentProfileResponse> {
		const body = JSON.stringify(req);

		return this.fetchWithRoute(RtCommonApiRoutes.SipAgents.Create, {
			body
		});
	}

	public update(
		sipAgent: SipAgentProfileResponse
	): Promise<SipAgentProfileResponse> {
		const { sipAgentId, ...req } = sipAgent;
		const urlParams = {
			sipAgentId
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute(RtCommonApiRoutes.SipAgents.Update, {
			urlParams,
			body
		});
	}

	public delete(sipAgentId: number): Promise<SipAgentDeleteResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.SipAgents.Delete, {
			urlParams: {
				sipAgentId
			}
		});
	}
}
