import clsx from 'clsx';
import { takeRight } from 'lodash-es';
import React, {
	FocusEventHandler,
	forwardRef,
	useMemo,
	useRef,
	useState
} from 'react';
import { useCombinedRefs } from 'RtUi/utils/hooks/useCombinedRefs';
import { isElementInViewport } from 'RtUi/utils/react/ViewportHelpers';

export type TGetLabelStyleFn = (
	value: string
) => 'exact-match' | 'parent-match' | 'child-match' | 'invalid' | undefined;

export type SuggestionsPropTypes =
	| string[]
	| ((inputStr: string, hasFocus: boolean) => string[]);

interface IMultiDataListProps {
	suggestions: SuggestionsPropTypes;
	values: string[] | undefined;
	onChange: (newValues: string[]) => void;
	disableNoSuggestionsPrompt?: boolean;
	inputType?: string; //default to text
	inputStep?: number;
	inputMax?: number;
	inputMin?: number;
	inputMaxLength?: number;
	inputMinLength?: number;
	shownValuesLimit?: number;
	getLabelStyle?: TGetLabelStyleFn;
	required?: boolean;
	name?: string;
	onPaste?: (clipboardData: string) => void;
	displayMode?: boolean;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	onBlur?: (
		evt: React.FocusEvent<HTMLInputElement>,
		setInputText: (newInputText: string) => void
	) => void;
	upperCaseValueOnCreate?: boolean;
	className?: string;
}

export const MultiDataList = forwardRef<HTMLInputElement, IMultiDataListProps>(
	(
		{
			suggestions,
			onChange,
			getLabelStyle,
			values = [],
			required,
			name,
			className,
			shownValuesLimit,
			inputStep,
			inputMax,
			inputMin,
			inputMaxLength,
			inputMinLength,
			disableNoSuggestionsPrompt = false,
			inputType = 'text',
			onPaste = () => ({}),
			onBlur = () => ({}),
			onFocus = () => ({}),
			upperCaseValueOnCreate = false,
			displayMode = true
		},
		forwardedRef
	) => {
		const multiListRef = useRef<HTMLElement>(null);
		const inputRef = useRef<HTMLInputElement>(null);
		const combinedRef = useCombinedRefs<HTMLInputElement>(
			forwardedRef,
			inputRef
		);
		const [hasFocus, setHasFocus] = useState(false);
		const [blurTimeoutId, setBlurTimeoutId] = useState<number>();
		const [text, setText] = useState('');
		const [showAllOverride, setShowAllOverride] = useState(false);
		const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
		const [dataListOptionSelected, setDataListOptionSelected] = useState(false);
		const currentSuggestions = useMemo(() => {
			if (Array.isArray(suggestions)) {
				return suggestions;
			}

			return suggestions(text, hasFocus);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [text, suggestions, hasFocus]);

		const clearBlurTimeout = () => {
			if (typeof blurTimeoutId === 'number') {
				window.clearTimeout(blurTimeoutId);
				setBlurTimeoutId(undefined);
			}
		};

		const internalOnFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
			clearBlurTimeout();
			setHasFocus(true);
			onFocus(evt);
		};

		const internalOnBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
			clearBlurTimeout();

			//Allow for click events to go through
			const blurTimeoutId = window.setTimeout(() => {
				setHasFocus(false);
				onBlur(evt, setText);
			}, 150);

			setBlurTimeoutId(blurTimeoutId);
		};

		const updateText = (value: string, forceUpdate = false) => {
			if (value === '') {
				setText(value);
				return;
			}

			if (upperCaseValueOnCreate) {
				value = value.toUpperCase();
			}

			const isSuggestion = () =>
				dataListOptionSelected || currentSuggestions.includes(value);

			if (forceUpdate || isSuggestion()) {
				addValue(value);
			} else {
				setText(value);
			}
		};

		const addValue = (value: string) => {
			onChange([...values, value]);
			setText('');
			setDataListOptionSelected(false);
		};

		const removeValueAt = (index: number) => {
			const newValues = [...values];
			newValues.splice(index, 1);
			onChange(newValues);
		};

		const focusOnInputElement = () => {
			if (!inputRef.current) {
				return;
			}

			inputRef.current.focus();

			if (!dropdownIsOpen) {
				setDropdownIsOpen(true);
			}

			if (!isElementInViewport(inputRef.current)) {
				inputRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest'
				});
			}
		};

		const onInputKeydown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
			const keyPressed = ev.key.toLowerCase();
			const isDataListSelect = keyPressed === 'unidentified';

			if (keyPressed === 'enter') {
				updateText(ev.currentTarget.value, true);
			}

			if (text === '' && keyPressed === 'backspace') {
				removeValueAt(values.length - 1);
			}

			setDataListOptionSelected(isDataListSelect);
		};

		let shownValues = values;
		let notShownAmount = 0;

		if (
			!showAllOverride &&
			typeof shownValuesLimit === 'number' &&
			shownValues.length > shownValuesLimit
		) {
			shownValues = takeRight(values, shownValuesLimit);
			notShownAmount = values.length - shownValuesLimit;
		}

		let shouldRenderDropdownMenu = !displayMode && hasFocus;

		if (shouldRenderDropdownMenu && disableNoSuggestionsPrompt) {
			shouldRenderDropdownMenu = suggestions.length > 0;
		}

		const multiListRect = multiListRef.current?.getBoundingClientRect();

		return (
			<section
				ref={multiListRef}
				onClick={focusOnInputElement}
				className={clsx('multi-data-list', className, {
					'multi-data-list-display-mode': displayMode
				})}
			>
				{notShownAmount > 0 && (
					<a
						style={{ cursor: 'pointer' }}
						className="multi-data-list-item"
						onClick={(ev) => {
							//stop click capture above from firing
							ev.stopPropagation();
							ev.preventDefault();

							setShowAllOverride(true);
						}}
					>
						<strong className="multi-data-list-item-label">
							Click to show all ({notShownAmount.toLocaleString()} more)
						</strong>
					</a>
				)}
				{showAllOverride && (
					<a
						style={{ cursor: 'pointer' }}
						className="multi-data-list-item"
						onClick={(ev) => {
							//stop click capture above from firing
							ev.stopPropagation();
							ev.preventDefault();

							setShowAllOverride(false);
						}}
					>
						<strong className="multi-data-list-item-label">Show less</strong>
					</a>
				)}
				{shownValues.map((value, index) => {
					const labelStyle = getLabelStyle ? getLabelStyle(value) : undefined;
					return (
						<span
							key={value + index + labelStyle}
							className="multi-data-list-item"
							onClick={(ev) => {
								//stop click capture above from firing
								ev.stopPropagation();
								ev.preventDefault();
							}}
						>
							<span
								className={clsx(
									'multi-data-list-item-label',
									`multi-data-list-item-label-${labelStyle}`
								)}
							>
								{value}
							</span>
							<span
								className="multi-data-list-item-action"
								onClick={() => removeValueAt(notShownAmount + index)}
							>
								<i className="fas fa-fw fa-times" />
							</span>
						</span>
					);
				})}
				<input
					ref={combinedRef}
					type={inputType}
					step={inputStep}
					max={inputMax}
					min={inputMin}
					maxLength={inputMaxLength}
					minLength={inputMinLength}
					required={required}
					name={name}
					onPaste={(evt) => {
						evt.preventDefault();

						const clipboardData = evt.clipboardData.getData('text');
						onPaste(clipboardData);
					}}
					value={text}
					onFocus={internalOnFocus}
					onBlur={internalOnBlur}
					onChange={(ev) => updateText(ev.target.value)}
					onKeyDown={(ev) => onInputKeydown(ev)}
				/>
				<section
					className={clsx('typeahead', {
						'is-open': shouldRenderDropdownMenu
					})}
					style={{
						top: multiListRect?.bottom,
						left: multiListRect?.x
					}}
				>
					{shouldRenderDropdownMenu && (
						<>
							{currentSuggestions.length <= 0 && (
								<span className="typeahead-item disabled">No Suggestions</span>
							)}
							{currentSuggestions.map((suggestion, index) => (
								<span
									className="typeahead-item"
									key={suggestion + index}
									onClick={(ev) => {
										ev.stopPropagation();
										addValue(suggestion);
									}}
								>
									{suggestion}
								</span>
							))}
						</>
					)}
				</section>
			</section>
		);
	}
);
