/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $UserFavoriteIndexRequest = {
    properties: {
        userFavoriteId: {
            type: 'number',
        },
        userFavoriteTypeId: {
            type: 'RtxUserFavoriteType',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};