/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $VitcomResourceConfigurationExtraParams = {
    properties: {
        accountNumber: {
            type: 'string',
            isRequired: true,
        },
        trunkId: {
            type: 'string',
            isRequired: true,
        },
    },
};