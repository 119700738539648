import { Edit } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { CprValuesInput } from 'RtUi/app/rt800/Cprs/lib/controls/CprValuesInput';
import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2';
import { noop } from 'lodash-es';
import { useState } from 'react';

interface ICprColDataGridColumnInputProps {
	cprCol: CprCol;
	onUpdate?: () => void;
}

export const CprColDataGridColumnInput = ({
	cprCol,
	onUpdate = noop
}: ICprColDataGridColumnInputProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className="d-flex align-items-center justify-content-between w-100">
			<CprValuesInput validator={cprCol} onUpdate={onUpdate} />
			<IconButton onClick={handleClick}>
				<Edit fontSize="small" />
			</IconButton>
			{anchorEl && (
				<Menu
					open={open}
					onClose={handleClose}
					anchorEl={anchorEl}
					disableScrollLock
				>
					<MenuItem
						onClick={() => {
							cprCol.updateColValuesDownward('fill');

							cprCol.cpr.validate();
							onUpdate();
							handleClose();
						}}
					>
						Fill down
					</MenuItem>
					<MenuItem
						onClick={() => {
							cprCol.updateColValuesDownward('replace');

							cprCol.cpr.validate();
							onUpdate();
							handleClose();
						}}
					>
						Replace down
					</MenuItem>
				</Menu>
			)}
		</div>
	);
};
