import { StylesConfig } from 'react-select';
import { JurisPrecedenceOptions } from 'RtModels';
import { SelectFormControl } from 'RtUi/components/form/SelectFormControl';
import {
	ISimpleSelectFormControlOption,
	ISimpleSelectFormControlProps,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface IRatingPrecedenceSelectProps<isMulti extends boolean>
	extends ISimpleSelectFormControlProps<JurisPrecedenceOptions, isMulti> {}

export class RatingPrecedenceSelect<
	isMulti extends boolean = true
> extends SimpleSelectFormControl<
	JurisPrecedenceOptions,
	isMulti,
	IRatingPrecedenceSelectProps<isMulti>
> {
	public static defaultProps = {
		options: [
			{
				value: JurisPrecedenceOptions.Ani,
				label: 'Ani'
			},
			{
				value: JurisPrecedenceOptions.Indet,
				label: 'Indet'
			},
			{
				value: JurisPrecedenceOptions.Inter,
				label: 'Inter'
			},
			{
				value: JurisPrecedenceOptions.Intra,
				label: 'Intra'
			},
			{
				value: JurisPrecedenceOptions.Jip,
				label: 'Jip'
			},
			{
				value: JurisPrecedenceOptions.Lrn,
				label: 'Lrn'
			},
			{
				value: JurisPrecedenceOptions.Pai,
				label: 'Pai'
			},
			{
				value: JurisPrecedenceOptions.Poi,
				label: 'Poi'
			},
			{
				value: JurisPrecedenceOptions.Piu,
				label: 'Piu'
			}
		]
	};

	public getStylesConfig(): StylesConfig<
		ISimpleSelectFormControlOption<JurisPrecedenceOptions>
	> {
		const { isInvalid = () => false, getLabelCssProperties = () => {} } =
			this.state;

		return {
			multiValueLabel(styles, props) {
				styles[':before'] = {
					content: `'${props.index + 1}. '`
				};

				const { data } = props;

				if (data) {
					const cssAttrsFromSubclass = getLabelCssProperties(data);
					const isRecordInvalid = isInvalid(data);

					if (cssAttrsFromSubclass) {
						Object.assign(styles, cssAttrsFromSubclass);
					}

					if (isRecordInvalid) {
						Object.assign(styles, SelectFormControl.getInvalidStyle());
					}
				}

				return styles;
			}
		};
	}
}
