import {
	FirebaseMessageSeverity,
	FirebaseMessageType,
	ProductId
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { IFirebaseUIMessage } from 'RtUi/state/actions/user/BaseFireBase';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getSystemNotificationColumns = (): Array<
	DataGridColumn<IFirebaseUIMessage>
> => [
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'expireTs',
		header: 'Expire'
	}),
	DefaultDataGridColumn({
		accessorKey: 'messageType',
		header: 'Message Type',
		getValue: ({ cell }) =>
			FirebaseMessageType[cell.getValue<FirebaseMessageType>()]
	}),
	DefaultDataGridColumn({
		accessorKey: 'severity',
		header: 'Severity',
		getValue: ({ cell }) =>
			FirebaseMessageSeverity[cell.getValue<FirebaseMessageSeverity>()]
	}),
	DefaultDataGridColumn({
		accessorKey: 'productId',
		header: 'Product Id',
		getValue: ({ cell }) => ProductId[cell.getValue<ProductId>()]
	}),
	DefaultDataGridColumn({
		accessorKey: 'routeType',
		header: 'Route Type'
	}),
	DefaultDataGridColumn({
		accessorKey: 'routeTypeId',
		header: 'Route Type Id'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createTs',
		header: 'Created'
	})
];
