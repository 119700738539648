/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RoutingRuleActionMode {
    PrioritizeOneLcrOthers = 1,
    PrioritizeOneIgnoreOthers = 2,
    PrioritizeOneDeleteOthers = 3,
    LcrAll = 4,
    BlockAll = 5,
}