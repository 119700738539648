import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

function not(a: readonly number[], b: readonly number[]) {
	return a.filter((value) => !b.includes(value));
}

function intersection(a: readonly number[], b: readonly number[]) {
	return a.filter((value) => b.includes(value));
}

interface DoubleListBoxProps {
	left: any[];
	setLeft: any;
	right: any[];
	setRight: any;
}

export default function DoubleListBox(props: DoubleListBoxProps) {
	const { left, right, setLeft, setRight } = props;
	const [checked, setChecked] = React.useState<readonly number[]>([]);

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const handleToggle = (value: number) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		setRight(right.concat(left));
		setLeft([]);
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		setLeft(left.concat(right));
		setRight([]);
	};

	const customList = (items: readonly any[], title: string) => (
		<>
			<span style={{ fontWeight: '500' }}>{title}</span>
			<Paper
				sx={{ width: 350, height: 230, overflow: 'auto', marginTop: '10px' }}
			>
				<List dense component="div" role="list">
					{items.map((value: any) => {
						const labelId = `transfer-list-item-${value.resourceId}-label`;
						return (
							<ListItem
								sx={{
									'backgroundColor': `${
										checked.includes(value) ? '#d6eaf8' : 'white'
									}`,
									'&.MuiListItem-root:hover': {
										backgroundColor: '#d6eaf8'
									}
								}}
								key={Math.random()}
								role="listitem"
								button
								onClick={handleToggle(value)}
							>
								<ListItemIcon sx={{ width: '1px', minWidth: '0px' }}>
									<Checkbox
										sx={{ display: 'none' }}
										checked={checked.includes(value)}
										tabIndex={-1}
										disableRipple
										inputProps={{
											'aria-labelledby': labelId
										}}
									/>
								</ListItemIcon>
								<ListItemText
									sx={{ paddingLeft: '5px' }}
									id={labelId}
									primary={`${value.label}`}
								/>
							</ListItem>
						);
					})}
					<ListItem />
				</List>
			</Paper>
		</>
	);

	return (
		<Grid
			container
			spacing={2}
			sx={{ marginTop: '10px', marginBottom: '10px' }}
		>
			<Grid item>{customList(left, 'Available Resources')}</Grid>
			<Grid sx={{ display: 'flex', alignItems: 'center' }} item>
				<Grid container direction="column" alignItems="center">
					<Button
						sx={{ my: 0.5 }}
						variant="outlined"
						size="small"
						onClick={handleAllRight}
						disabled={left.length === 0}
						aria-label="move all right"
					>
						≫
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant="outlined"
						size="small"
						onClick={handleCheckedRight}
						disabled={leftChecked.length === 0}
						aria-label="move selected right"
					>
						&gt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant="outlined"
						size="small"
						onClick={handleCheckedLeft}
						disabled={rightChecked.length === 0}
						aria-label="move selected left"
					>
						&lt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant="outlined"
						size="small"
						onClick={handleAllLeft}
						disabled={right.length === 0}
						aria-label="move all left"
					>
						≪
					</Button>
				</Grid>
			</Grid>
			<Grid sx={{ marginLeft: '5px' }} item>
				{customList(right, 'Search These Resources')}
			</Grid>
		</Grid>
	);
}
