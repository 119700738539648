import { MinuteProfile } from 'RtModels';
import { LcrMinutesResource } from 'RtUi/app/rtLco/Minutes/lib/resources/LcrMinutesResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface LcrMinutesDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<MinuteProfile, T> {}

export const LcMinutesDataGridCache = new ResourceCacheMap<MinuteProfile>(
	new LcrMinutesResource(),
	'minuteProfileId',
	'label'
);

export const LcrMinutesDataGridColumn = <T = any,>(
	config: LcrMinutesDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: LcMinutesDataGridCache,
		...config
	});
};
