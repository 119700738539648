/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum DownloadTypes {
    Ftp = 'ftp',
    Sftp = 'sftp',
    Ftptls = 'ftptls',
    DigitalOcean = 'do',
    Aws = 'aws',
    Azure = 'azure',
    Browser = 'browser',
    Api = 'api',
}