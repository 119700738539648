import { Component } from 'react';
import { WarningAlert } from 'RtUi/components/ui/WarningAlert/index';
import { Link } from 'react-router-dom';
import { MyProfileRouter } from 'RtUi/app/user/MyProfile/MyProfile.router';
import { UserActions } from 'RtUi/state/actions/user';

export class TwoFADisabledWarning extends Component<{}, {}> {
	public render() {
		return (
			<WarningAlert hide={UserActions.isTFAEnabled()}>
				<span>
					{'  '}Some features are not available for security purposes.{' '}
					<Link
						to={MyProfileRouter.getProfileRoute(
							UserActions.getUserId()!,
							'Two-Factor Authentication'
						)}
					>
						Set Up Two-Factor Authentication
					</Link>{' '}
					to resolve this.
				</span>
			</WarningAlert>
		);
	}
}
