import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	ServerGroupCreateRequest,
	ServerGroupIndexResponse,
	ServerGroupProfileResponse,
	ServerGroupProfileUrlRequest,
	ServerGroupUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ServerGroupResource extends HttpResource<
	ServerGroupIndexResponse,
	ServerGroupProfileResponse
> {
	constructor() {
		super(RtAdmApiRoutes.ServerGroups);
	}

	public create(request: ServerGroupCreateRequest) {
		const body = JSON.stringify(request);
		return this.fetchWithRoute<ServerGroupProfileResponse>(
			RtAdmApiRoutes.ServerGroups.Create,
			{ body }
		);
	}

	public update(serverGroupId: number, request: ServerGroupUpdateRequest) {
		const urlParams: ServerGroupProfileUrlRequest = {
			serverGroupId
		};
		const body = JSON.stringify(request);

		return this.fetchWithRoute<ServerGroupProfileResponse>(
			RtAdmApiRoutes.ServerGroups.Update,
			{ body, urlParams }
		);
	}

	public createNew(): ServerGroupCreateRequest {
		return {
			summary: '',
			isActive: 1,
			isMedia: 1
		};
	}
}
