import {
	DataGridColumn,
	DataGridTableInstance
} from 'RtUi/components/data/DataGrid/types';
import { CprRowIndexDataGridButton } from 'RtUi/components/data/DataGrid/columns/CprRowIndexDataGridColumn/components/CprRowIndexDataGridButton';
import { CprRowIndexDataGridColumnHeader } from 'RtUi/components/data/DataGrid/columns/CprRowIndexDataGridColumn/components/CprRowIndexDataGridColumnHeader';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2';

interface CprRowIndexDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	displayMode: boolean;
	onAddCprNode: (decisionTypes: CprNodeType) => void;
}

export const CprRowIndexDataGridColumn = <T = any,>({
	displayMode,
	onAddCprNode,
	...config
}: CprRowIndexDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (index: number) => {
		return index + 1;
	};

	const renderHeader = (table: DataGridTableInstance<T>) => {
		const data = table
			.getPrePaginationRowModel()
			.rows.map((row) => row.original);

		if (displayMode || data.length > 0) {
			return '';
		}

		return <CprRowIndexDataGridColumnHeader onAddCprNode={onAddCprNode} />;
	};

	return DefaultDataGridColumn({
		...config,
		enableColumnDragging: false,
		enableEditing: !displayMode,
		exportValue: (value) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<number>()),
		getHeader: ({ table }) => renderHeader(table),
		Edit: ({ cell, row }) => (
			<CprRowIndexDataGridButton
				cprRow={row.original as any}
				index={cell.getValue<number>()}
			/>
		)
	});
};
