import * as moment from 'moment';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { ChartLoader } from 'RtUi/app/rtVue/common/lib/charts/ChartLoader';
import { Button } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import clearPlugin from 'RtUi/app/rtVue/common/lib/components/FlatDateRangePicker/plugins/clear';
import { isEqual, isEmpty } from 'lodash-es';
import { Component, createRef, default as React } from 'react';
import { ChartWrapper } from 'RtUi/app/rtVue/common/lib/charts/ChartWrapper';
import { ChartConfiguration } from 'chart.js';

interface IVueTotalsLineChartAbstractProps<T> {
	resource: HttpResource<T> | null;
	isCompareToDate?: boolean;
	onCompareToDateUpdate?: (compareToDate: Date | undefined) => void;
}

export enum ChartFlags {
	MAX_DATA_CEILING_HIT = 1
}

interface IVueTotalsLineChartAbstractState {
	wasZoomed: boolean;
	compareToDate?: Date;
	chartData?: ChartConfiguration | ChartFlags;
}

export abstract class VueTotalsLineChartAbstract<T> extends Component<
	IVueTotalsLineChartAbstractProps<T>,
	IVueTotalsLineChartAbstractState
> {
	public state: IVueTotalsLineChartAbstractState = {
		wasZoomed: false
	};

	public plugins = {
		zoom: {
			pan: {
				enabled: true,
				mode: 'x',
				onPan: () => this.updateWasZoomed(true)
			},
			zoom: {
				wheel: {
					enabled: true
				},
				mode: 'x',
				speed: 1,
				threshold: 0,
				sensitivity: 0,
				onZoom: () => this.updateWasZoomed(true)
			}
		}
	};

	protected isComponentMounted = false;
	protected LineChartRef = createRef<any>();

	public componentDidMount() {
		this.isComponentMounted = true;

		if (!this.LineChartRef) {
			this.LineChartRef = createRef();
		}

		this.updateChartData();
	}

	public componentWillUnmount() {
		this.isComponentMounted = false;
	}

	public updateWasZoomed(wasZoomed: boolean) {
		if (this.state.wasZoomed !== wasZoomed) {
			this.setState({ wasZoomed });
		}
	}

	public shouldComponentUpdate(
		nextProps: Readonly<IVueTotalsLineChartAbstractProps<T>>,
		nextState: Readonly<IVueTotalsLineChartAbstractState>
	): boolean {
		return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
	}

	/**
	 * Update chart when resources are updated
	 * @param prevProps
	 */
	public componentDidUpdate() {
		this.updateChartData();
	}

	public getPreparedChartData(
		chartData: ChartConfiguration | ChartFlags | undefined
	): ChartConfiguration | undefined {
		if (chartData && chartData !== ChartFlags.MAX_DATA_CEILING_HIT) {
			return {
				...chartData,
				options: {
					maintainAspectRatio: false,
					plugins: {
						legend: {
							//@ts-expect-error
							onClick: this.onLegendClickHandler
						},
						//@ts-expect-error
						zoom: this.plugins.zoom
					}
				}
			};
		} else {
			return undefined;
		}
	}

	public render() {
		const { chartData } = this.state;
		const isLoading = this.props.resource === null;
		const preparedChartData = this.getPreparedChartData(chartData);

		return (
			<CollapsibleCard
				useCardBody
				header="Totals"
				actions={() => (
					<div className="d-flex align-items-baseline">
						{this.props.isCompareToDate && (
							<div className="rdtDateTimeControl d-flex align-items-baseline me-2 ms-2">
								<div className="d-lg-flex flat-picker-wrapper input-group">
									<Flatpickr
										className="form-control-sm"
										placeholder="Compare to date"
										options={{
											altInput: true,
											altFormat: 'Y-m-d',
											plugins: [clearPlugin()],
											maxDate: new Date()
										}}
										value={this.state.compareToDate}
										onChange={(compareToDate) => {
											this.setState({ compareToDate: compareToDate[0] }, () => {
												if (this.props.onCompareToDateUpdate) {
													this.props.onCompareToDateUpdate(
														this.state.compareToDate
													);
												}
											});
										}}
									/>
								</div>
							</div>
						)}
						<Button
							type="button"
							variant="white"
							className="btn-sm"
							disabled={!this.state.wasZoomed}
							onClick={() => {
								this.updateWasZoomed(false);
								this.LineChartRef?.current?.chartInstance.resetZoom();
							}}
						>
							Reset
						</Button>
					</div>
				)}
			>
				{isLoading && <ChartLoader />}
				{!isLoading && !isEmpty(chartData) && preparedChartData && (
					<ChartWrapper
						style={{ height: '20vh' }}
						config={preparedChartData}
						ref={this.LineChartRef}
					/>
				)}
				{!isLoading && ChartFlags.MAX_DATA_CEILING_HIT === chartData && (
					<div>
						<i className="fas fa-fw fa-info-circle" /> The current query does
						not have a corresponding chart.
					</div>
				)}
			</CollapsibleCard>
		);
	}

	protected onLegendClickHandler = (
		e: React.FormEvent<HTMLFormElement>,
		legendItem: any //Chart.ChartLegendLabelItem
	) => {
		const chart = this.LineChartRef?.current?.chartInstance;
		const index = legendItem.datasetIndex!;

		if (!chart) {
			return;
		}

		// Chart.Meta not found
		const toggleHidden = (meta: any, index: number) => {
			meta.hidden = !meta.hidden
				? !chart.data.datasets![index].hidden
				: undefined;
		};

		if (!this.state.compareToDate) {
			toggleHidden(chart.getDatasetMeta(index), index);
		} else {
			const length = chart?.data.datasets?.length!;
			let nextIndex = length / 2;

			if (index >= nextIndex) {
				nextIndex = index - nextIndex;
			} else {
				nextIndex += index;
			}

			[chart.getDatasetMeta(index), chart.getDatasetMeta(nextIndex)].forEach(
				function (meta) {
					toggleHidden(meta, index);
				}
			);
		}
		chart.update();
	};

	/**
	 * Only sets chartData if there were changes
	 * @param chartData
	 */
	protected async updateChartData() {
		const chartData = await this.getChartData();
		if (!this.isComponentMounted) {
			return;
		}

		if (!isEqual(chartData, this.state.chartData)) {
			this.setState({ chartData });
		}
	}

	/**
	 * Give a dataset, return chart labels that make the most sense given the
	 * callData, hour, and minute properties. For instance, there is no need to
	 * show the same date if all data is from the same date, etc.
	 *
	 * @param dataset
	 */
	protected getLabels(
		dataset: Array<{ callDate: string; hour?: string; minute?: string }>
	) {
		let areTheSameDate = true;
		let areTheSameYear = true;
		let areTheSameMonth = true;
		let areTheSameTimeOfDay = true;

		const firstAsr = dataset[0];

		if (!firstAsr) {
			return [];
		}

		const firstMoment = this.createMomentFrom(firstAsr);

		for (const record of dataset) {
			const recordMoment = this.createMomentFrom(record);

			areTheSameDate = areTheSameDate && firstAsr.callDate === record.callDate;
			areTheSameYear =
				areTheSameYear && firstMoment.year() === recordMoment.year();
			areTheSameMonth =
				areTheSameMonth && firstMoment.month() === recordMoment.month();
			areTheSameTimeOfDay =
				areTheSameTimeOfDay &&
				firstMoment.hours() === recordMoment.hours() &&
				firstMoment.minutes() === recordMoment.minutes();
		}

		return dataset.map((asrIndex) => {
			const callMoment = this.createMomentFrom(asrIndex);

			if (areTheSameDate) {
				return callMoment.format('HH:mm');
			} else if (areTheSameMonth) {
				if (areTheSameTimeOfDay) {
					return callMoment.format('Do');
				}
				return callMoment.format('Do - HH:mm');
			} else if (areTheSameYear) {
				return callMoment.format('MM-DD HH:mm');
			}

			if (areTheSameTimeOfDay) {
				return callMoment.format('YYYY-MM-DD');
			}

			return callMoment.format('YYYY-MM-DD HH:mm');
		});
	}

	/**
	 * Given the new asr data, create a new chart to display
	 */
	protected abstract getChartData(): Promise<any>;

	protected createMomentFrom(data: {
		callDate: string;
		hour?: string;
		minute?: string;
	}) {
		const dataMoment = moment.utc(data.callDate);

		if (data.hour) {
			dataMoment.hours(Number(data.hour));
		}

		if (data.minute) {
			dataMoment.minutes(Number(data.minute));
		}

		return dataMoment;
	}
}
