/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipCallSimulatorResponseDto = {
    properties: {
        rate: {
            type: 'SipCallSimulatorResponseDtoRateable',
            isRequired: true,
        },
        ratedPn: {
            type: 'SipCallSimulatorResponseDtoPhoneNumber',
            isRequired: true,
        },
        jurisPn: {
            type: 'SipCallSimulatorResponseDtoPhoneNumber',
            isRequired: true,
        },
        rateables: {
            type: 'Array',
            isRequired: true,
        },
        routingRuleId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        errorId: {
            type: 'CallProcessingResult',
            isNullable: true,
        },
    },
};