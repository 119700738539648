/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MyProfilePasswordUpdateRequest = {
    properties: {
        currentPassword: {
            type: 'string',
            isRequired: true,
        },
        newPassword: {
            type: 'string',
            isRequired: true,
        },
    },
};