/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ReportMetadataProfileResponse = {
    properties: {
        searchFields: {
            type: 'Array',
            isRequired: true,
        },
        advancedSearchFields: {
            type: 'Array',
            isRequired: true,
        },
        presets: {
            type: 'Array',
            isRequired: true,
        },
        dataSums: {
            type: 'Array',
            isRequired: true,
        },
        allColumns: {
            type: 'Array',
            isRequired: true,
        },
        reportDomains: {
            type: 'Array',
            isRequired: true,
        },
    },
};