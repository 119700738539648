import * as React from 'react';
import { Form } from 'react-bootstrap';
import { IFormControlProps } from 'RtUi/components/form/FormControl';

interface ICheckboxFormControlProps extends IFormControlProps<boolean> {
	inline?: boolean;
	isInvalid?: boolean;
	styles?: any;
}

export class CheckboxFormControl extends React.Component<
	ICheckboxFormControlProps,
	{}
> {
	public renderBooleanProperty() {
		const isYes = this.props.value === true;

		const fontColor = isYes ? 'success' : 'danger';
		const fontIcon = isYes ? 'check-circle' : 'times-circle';

		return (
			<section
				className={`${this.props.inline ? 'd-flex align-items-center' : ''}`}
			>
				<i className={`fas fa-fw fa-${fontIcon} text-${fontColor}`} />
				<span>&nbsp;{this.props.label}</span>
			</section>
		);
	}

	public render() {
		const {
			onChange = () => ({}),
			onClick = () => ({}),
			displayMode = false
		} = this.props;

		if (displayMode) {
			return this.renderBooleanProperty();
		}

		return (
			<Form.Check
				id={this.props.id}
				inline={this.props.inline}
				onChange={({ target }) => onChange(target.checked)}
				onClick={(event) => onClick(event)}
				label={this.props.label}
				className={this.props.className}
				required={this.props.required}
				disabled={this.props.disabled}
				isInvalid={this.props.isInvalid}
				checked={this.props.value}
				style={{ ...this.props.styles }}
			/>
		);
	}
}
