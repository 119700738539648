import {
	SubscriptionProfileRequest,
	SubscriptionTagProfileResponse,
	TagAssignmentIndexRequest,
	TagAssignmentIndexResponse,
	TagMatchingIndexRequest,
	TagMatchingIndexResponse
} from 'RtModels';
import { AddSubscriptionTagAside } from 'RtUi/app/AccountManagement/Subscriptions/lib/components/AddSubscriptionTagAside';
import { SubscriptionTagResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionTagResource';
import { useGetTags } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getSubscriptionTagsColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/subscriptions';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { noop } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

export type TagResponse =
	| SubscriptionTagProfileResponse
	| TagMatchingIndexResponse
	| TagAssignmentIndexResponse;

type TagSummaryRequest<T extends TagResponse> =
	T extends TagMatchingIndexResponse
		? TagMatchingIndexRequest
		: TagAssignmentIndexRequest;

export type TagRequest<T extends TagResponse> =
	T extends SubscriptionTagProfileResponse
		? SubscriptionProfileRequest
		: TagSummaryRequest<T>;

export type IsMatching<T extends TagResponse> =
	T extends TagMatchingIndexResponse ? true : false;

export type IsAssignment<T extends TagResponse> =
	T extends TagAssignmentIndexResponse ? true : false;

interface ISubscriptionTagGridProps<T extends TagResponse> {
	requestParams: TagRequest<T>;
	subscriptionId?: number;
	isMatching?: IsMatching<T>;
	isAssignment?: IsAssignment<T>;
	pageName: string;
	onUpdate?: () => void;
}

export const TagDataGrid = <T extends TagResponse>({
	requestParams,
	subscriptionId,
	isMatching,
	isAssignment,
	pageName,
	onUpdate = noop
}: ISubscriptionTagGridProps<T>) => {
	const [isAddAsideOpen, setIsAddAsideOpen] = useState<boolean>(false);
	const [selectedTag, setSelectedTag] =
		useState<SubscriptionTagProfileResponse>();
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetTags(requestParams, isMatching, isAssignment);

	const onDeleteHandler = useCallback(
		async (row: TagResponse) => {
			const confirm = await Confirmation.createDelete(
				row.subscriptionTagId.toString()
			);

			if (!confirm) {
				return;
			}

			const resource = new SubscriptionTagResource();
			await resource.delete(row.subscriptionTagId);
			refetch();
		},
		[refetch]
	);

	const columns = useMemo(
		() =>
			getSubscriptionTagsColumns(
				onDeleteHandler,
				'subscriptionId' in requestParams,
				isAssignment
			),
		[onDeleteHandler, requestParams, isAssignment]
	);

	return (
		<>
			<DataGrid<TagResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={pageName}
				rowThemeColor={(record) =>
					record.subscriptionTagId === selectedTag?.subscriptionTagId
						? RowThemeColor.WARNING
						: undefined
				}
				onRowClick={async (row) => {
					const profile = await new SubscriptionTagResource().get(
						row.subscriptionTagId
					);

					setSelectedTag(profile);
					setIsAddAsideOpen(true);
				}}
				headerAction={() => (
					<OverlayTrigger
						overlay={(props) => (
							<Tooltip id={`addTagBtn-tooltip`} {...props}>
								Add Tag
							</Tooltip>
						)}
					>
						{({ ref, ...triggerHandler }) => (
							<Button
								ref={ref}
								{...triggerHandler}
								onClick={() => {
									setIsAddAsideOpen(true);
									setSelectedTag(undefined);
								}}
								variant="white"
								id="addTagBtn"
							>
								<i className="fas fa-fw fa-plus fa-lg" />
							</Button>
						)}
					</OverlayTrigger>
				)}
			/>
			<AddSubscriptionTagAside
				profile={selectedTag}
				subscriptionId={selectedTag?.subscriptionId ?? subscriptionId}
				isOpen={isAddAsideOpen}
				isMatching={isMatching}
				isAssignment={isAssignment}
				onCancel={() => {
					setIsAddAsideOpen(false);
					setSelectedTag(undefined);
				}}
				onSave={() => {
					setIsAddAsideOpen(false);
					setSelectedTag(undefined);
					refetch();
					onUpdate();
				}}
			/>
			<Confirmation />
		</>
	);
};
