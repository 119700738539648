import clsx from 'clsx';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { MessageEventType } from 'RtModels';
import { RocRequestsGrid } from 'RtUi/app/rt800/RespOrgChange/lib/grids/RocRequestsGrid';
import {
	IRocIndexResponse,
	RocRequestResource
} from 'RtUi/app/rt800/RespOrgChange/lib/resources/RocRequestResource';
import { DashboardContent } from 'RtUi/app/rtCommon/DashboardOld/lib/components/DashboardContent';
import {
	UiNotification,
	UiNotificationCategoryEnum
} from 'RtUi/notifications/lib/UiNotification';
import { UserActions } from 'RtUi/state/actions/user';

export class RocUiNotification extends UiNotification {
	private static rocRecordsPromise: Promise<IRocIndexResponse[]> | null = null;

	private static GetRocRecords(
		rocType: MessageEventType
	): Promise<IRocIndexResponse[]> {
		const isIncoming = rocType === MessageEventType.IncomingRespOrgChange;

		if (!RocUiNotification.rocRecordsPromise) {
			const rocRequestResource = new RocRequestResource();
			rocRequestResource.setGetAllDoNotNotifyOn404Error(true);
			const entityId = UserActions.getDefaultEntityId();
			const progressId = 0;
			const params = { entityId, progressId };

			RocUiNotification.rocRecordsPromise = rocRequestResource.getAll(
				params,
				true
			);

			//10min cache
			const tenMinInMs = 10 * 60 * 1000;
			setTimeout(
				() => (RocUiNotification.rocRecordsPromise = null),
				tenMinInMs
			);
		}

		return RocUiNotification.rocRecordsPromise.then((records) =>
			records.filter((record) => record.isIncoming === isIncoming)
		);
	}

	constructor(private type: MessageEventType) {
		super(
			UiNotificationCategoryEnum.Rt800,
			Rt800ApiRoutes.Roc.Index.permissions,
			`${
				type === MessageEventType.IncomingRespOrgChange
					? 'Incoming'
					: 'Outgoing'
			} RespOrg Change`,
			type,
			{ isWarning: true }
		);

		this.updateIssuesWord('requests');
	}

	public getIcon() {
		const isIncoming = this.type === MessageEventType.IncomingRespOrgChange;

		return (
			<>
				<i
					className={clsx('fas fa-sm', {
						'fa-download': isIncoming,
						'fa-upload': !isIncoming
					})}
				/>
				<span
					className="fa-layers-counter"
					style={{
						fontSize: '9.5px',
						fontWeight: 700,
						bottom: -5,
						left: 13.5
					}}
				>
					ROC
				</span>
			</>
		);
	}

	protected getDetailedView() {
		const rocRecordsPromise = RocUiNotification.GetRocRecords(this.type).catch(
			() => []
		);

		return (
			<DashboardContent
				notification={this}
				renderWhenHasErrors={() => (
					<RocRequestsGrid requestData={rocRecordsPromise} />
				)}
			/>
		);
	}

	protected getIssueCount(): Promise<number> {
		return RocUiNotification.GetRocRecords(this.type).then(
			(records) => records.length
		);
	}
}
