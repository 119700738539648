import { MarginSummaryIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';

export const getMarginSummaryColumns = (): Array<
	DataGridColumn<MarginSummaryIndexResponse>
> => [
	AccountDataGridColumn({
		accessorKey: 'costAccountId',
		header: 'Cost Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'costSubscriptionId',
		header: 'Cost Subscription',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'rateCenterId',
		header: 'Rate Center',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'costConnects',
		header: 'Cost Connects'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'costMinutes',
		header: 'Cost Mins'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'costCharges',
		header: 'Cost Charges'
	}),
	AccountDataGridColumn({
		accessorKey: 'revenueAccountId',
		header: 'Revenue Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'revenueSubscriptionId',
		header: 'Revenue Subscription',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenueConnects',
		header: 'Revenue Connects'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenueMinutes',
		header: 'Revenue Min'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenueCharges',
		header: 'Revenue Charges'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rawMinutes',
		header: 'Raw Min'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'margin',
		header: 'Margin'
	})
];
