/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TollFreeActivateRequest = {
    properties: {
        templateName: {
            type: 'string',
            isRequired: true,
        },
        effectiveTs: {
            type: 'Date',
            format: 'date-time',
        },
        serviceOrderNumber: {
            type: 'string',
        },
        lineCount: {
            type: 'number',
        },
        scheduledAt: {
            type: 'Date',
            format: 'date-time',
        },
        priority: {
            type: 'number',
        },
    },
};