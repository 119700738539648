import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	ContactIndexResponse,
	Contact,
	ContactCreateRequest,
	ContactUpdateRequest,
	ContactProfileRequest,
	ContactDeleteResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ContactResource extends HttpResource<
	ContactIndexResponse,
	Contact
> {
	constructor() {
		super(RtxSipApiRoutes.Contacts);
	}

	public create(createRequest: ContactCreateRequest) {
		const body = JSON.stringify(createRequest);

		return this.fetchWithRoute<Contact>(RtxSipApiRoutes.Contacts.Create, {
			body
		});
	}

	public update(contactId: number, updateRequest: ContactUpdateRequest) {
		const body = JSON.stringify(updateRequest);
		const urlParams: ContactProfileRequest = { contactId };

		return this.fetchWithRoute<Contact>(RtxSipApiRoutes.Contacts.Update, {
			body,
			urlParams
		});
	}

	public delete(contactId: number) {
		const urlParams: ContactProfileRequest = { contactId };
		return this.fetchWithRoute<ContactDeleteResponse>(
			RtxSipApiRoutes.Contacts.Delete,
			{ urlParams }
		);
	}
}
