import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { TollFreeIndexRequest } from 'RtModels';

export class TollFreesExportResource {
	public getAll(req: TollFreeIndexRequest) {
		const body = JSON.stringify(req);

		return HttpRequest.fetchWithRoute<number>(
			Rt800ApiRoutes.TollFreesExportCsv.Index,
			{
				body
			}
		);
	}
}
