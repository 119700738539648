import { FC, useState } from 'react';
import { useAsync, useCounter } from 'react-use';
import { Alert, Card, Button } from 'react-bootstrap';
import { ContactIndexResponse } from 'RtModels';
import { AccountContactResource } from 'RtUi/app/AccountManagement/Accounts/lib/resources/AccountContactResource';
import {
	ContactIsTypeKeys,
	ContactIsTypeSelect
} from 'RtUi/app/rtSip/Contacts/lib/controls/ContactIsTypeSelect';
import { ContactFormEditor } from 'RtUi/app/rtSip/Contacts/lib/forms/ContactFormEditor';

interface IAccountContactsProps {
	accountId: number;
}

const appendContactIcon = (
	contact: ContactIndexResponse,
	filter?: ContactIsTypeKeys
): React.ReactNode => {
	if (!filter) {
		return null;
	}

	if (contact[filter] === 2) {
		return <i className="fas fa-fw fa-star" />;
	}

	return null;
};

export const AccountContacts: FC<
	React.PropsWithChildren<IAccountContactsProps>
> = ({ accountId }) => {
	const [contactFilter, setContactFilter] = useState<
		ContactIsTypeKeys | undefined
	>();
	const [contacts, setContacts] = useState<ContactIndexResponse[]>([]);
	const [selected, setSelected] = useState<ContactIndexResponse | undefined>();
	const [updateCount, updateCountActions] = useCounter();

	const contactsLoading = useAsync(async () => {
		const accountContactResource = new AccountContactResource(accountId);
		const fetchedContacts = await accountContactResource.getAll();

		setContacts(fetchedContacts);

		let newSelected: ContactIndexResponse | undefined = fetchedContacts[0];

		if (selected) {
			const potentiallySelected = fetchedContacts.find(
				({ contactId }) => selected.contactId === contactId
			);

			newSelected = potentiallySelected ?? newSelected;
		}

		setSelected(newSelected);
	}, [accountId, updateCount]);

	let displayedContacts = [...contacts];

	if (contactFilter) {
		displayedContacts = contacts.filter(
			(contact) =>
				typeof contact[contactFilter] === 'number' && contact[contactFilter] > 0
		);

		if (selected) {
			if (!displayedContacts.includes(selected)) {
				setSelected(displayedContacts[0]);
			}
		} else if (displayedContacts[0]) {
			setSelected(displayedContacts[0]);
		}
	}

	const contactsExistButAllAreHidden =
		contacts.length > 0 && displayedContacts.length <= 0;

	return (
		<Card className="d-flex flex-row">
			<Card.Body className="bg-light" style={{ maxWidth: 225 }}>
				<section className="d-flex flex-column">
					<header className="d-flex flex-column my-3">
						<Button
							type="button"
							variant="white-alt"
							className="mb-3"
							onClick={() => {
								setSelected(undefined);
								setContactFilter(undefined);
							}}
						>
							<i className="fa fa-fw fa-plus" />
							&nbsp;Create Contact
						</Button>
						<ContactIsTypeSelect
							contacts={contacts}
							value={contactFilter}
							onChange={setContactFilter}
						/>
						{contactsExistButAllAreHidden && (
							<Alert
								variant="info"
								className="alert-sm mb-0 mt-2"
								onClick={() => {
									setContactFilter(undefined);
									setSelected(contacts[0]);
								}}
								style={{ cursor: 'pointer' }}
							>
								<i className="fas fa-fw fa-info-circle" />
								&nbsp; No contacts match this criteria. Click here to reset the
								filter.
							</Alert>
						)}
					</header>
					{displayedContacts.map((contact) => {
						const isSelected = selected?.contactId === contact.contactId;
						return (
							<Button
								key={contact.contactId}
								type="button"
								className="d-flex justify-content-between"
								variant={isSelected ? 'primary' : 'link'}
								onClick={() => setSelected(contact)}
							>
								<span
									style={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap'
									}}
								>
									{contact.firstName} {contact.lastName}
								</span>
								<span>{appendContactIcon(contact, contactFilter)}</span>
							</Button>
						);
					})}
				</section>
			</Card.Body>
			<section className="flex-fill" style={{ minHeight: 515 }}>
				{!selected &&
					!contactsLoading.loading &&
					!contactsExistButAllAreHidden && (
						<section>
							{contacts.length === 0 && (
								<Alert
									variant="info"
									className="rounded-0 d-flex flex-row mb-0"
								>
									<i className="fas fa-fw fa-info-circle me-2" />
									There are no contacts for this account. To get started, fill
									out the form below.
								</Alert>
							)}
							<ContactFormEditor
								accountId={accountId}
								onSuccess={(createdContact) => {
									setSelected(createdContact);
									updateCountActions.inc();
								}}
							/>
						</section>
					)}
				{selected && (
					<ContactFormEditor
						key={selected.contactId}
						profile={selected}
						onSuccess={() => updateCountActions.inc()}
						onDelete={() => {
							setSelected(contacts ? contacts[0] : undefined);
							updateCountActions.inc();
						}}
					/>
				)}
			</section>
		</Card>
	);
};
