import { ManagedStatus } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { Alert } from 'react-bootstrap';

interface ApiStatusDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const ApiStatusDataGridColumn = <T = any,>({
	size = 100,
	...config
}: ApiStatusDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const renderValue = (columnValue: ManagedStatus) => {
		let color = '';

		switch (columnValue) {
			case ManagedStatus.Failing:
				color = 'danger';
				break;
			case ManagedStatus.NoInfo:
				color = 'warning';
				break;
			case ManagedStatus.Working:
				color = 'success';
				break;
			default:
				color = 'light';
				break;
		}

		return (
			<>
				<section className="d-flex justify-content-center">
					<Alert
						transition={false}
						variant={color}
						className="d-flex align-content-center justify-content-center w-75 alert-sm mb-0 p-0"
					>
						<span>{columnValue}</span>
					</Alert>
				</section>
			</>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		getValue: ({ cell }) => renderValue(cell.getValue<ManagedStatus>())
	});
};
