/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum TimeFilterDetailKeys {
    Minutes = 'minute',
    Hours = 'hour',
    Days = 'day',
    Months = 'month',
}