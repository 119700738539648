import { useMemo } from 'react';
import { RateLoadOldRateIndexResponse } from 'RtModels';
import { useGetRateLoadOldRates } from 'RtUi/app/AccountManagement/RateManagement/libs/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getRateLoadOldRatesColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/rateLoads';

interface IRateLoadOldRatesGridProps {
	rateLoadId: number;
	autoFocusFilter?: boolean;
}

export const RateLoadOldRatesDataGrid = ({
	rateLoadId
}: IRateLoadOldRatesGridProps) => {
	const { data, isFetching: isLoading } = useGetRateLoadOldRates({
		rateLoadId
	});
	const columns = useMemo(() => getRateLoadOldRatesColumns(), []);

	return (
		<DataGrid<RateLoadOldRateIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			rowThemeColor={(record) =>
				record.rateLoadErrorTypeId !== null ? RowThemeColor.DANGER : undefined
			}
			pageName={'accountManagement_rateLoadOldRates'}
		/>
	);
};
