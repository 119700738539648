import { RocActionItem, RocActionItems } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { RocRequestHttp } from '../resources/RocRequestHttp';

interface IRocActionsSelect<isMulti extends boolean = false>
	extends ISelectFormControlProps<RocActionItem, isMulti> {
	onlyRejection?: boolean;
	onlyIncoming?: boolean;
	onlyOutgoing?: boolean;
}

export class RocActionsSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	RocActionItem,
	isMulti,
	IRocActionsSelect<isMulti>
> {
	public resourceClass = undefined;
	public state: ISelectFormControlState<RocActionItem> = {
		formLabel: 'Processing Action',
		valueKey: 'code',
		labelKey: 'label'
	};

	public rocRequestHttp = new RocRequestHttp();

	public componentDidMount() {
		const rocActionResource = new ArrayResource<RocActionItem>('code', []);
		this.resource = rocActionResource;

		this.rocRequestHttp.getActions().then((rocActions) => {
			let rocRejectionActions = Object.keys(rocActions)
				.map((actionKey) => rocActions[actionKey as keyof RocActionItems])
				.map((rocActionItem) => {
					const rejectPrependText = 'Decline: ';
					const hasRejectText =
						rocActionItem.label.startsWith(rejectPrependText);

					if (rocActionItem.isRejection && !hasRejectText) {
						rocActionItem.label = rejectPrependText + rocActionItem.label;
					}

					return rocActionItem;
				})
				.filter((action) => !action.systemUseOnly);

			if (this.props.onlyRejection) {
				rocRejectionActions = rocRejectionActions.filter(
					(action) => action.isRejection
				);
			}

			if (this.props.onlyIncoming) {
				rocRejectionActions = rocRejectionActions.filter(
					(action) => action.isIncoming
				);
			}

			if (this.props.onlyOutgoing) {
				rocRejectionActions = rocRejectionActions.filter(
					(action) => action.isOutgoing
				);
			}

			rocActionResource.setAllData(rocRejectionActions);

			super.componentDidMount();
		});
	}
}
