import * as React from 'react';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IManagedResourceClass } from '../resources/ManagedResource';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { Col, Row, Button } from 'react-bootstrap';

interface IManagedRocProfile {
	rocUserName: string;
	rocPassword?: string;
}

interface IManagedUpdateRocProps<ProfileType extends IManagedRocProfile> {
	id: string;
	resourceClass: IManagedResourceClass<any, ProfileType>;
	profile: IManagedRocProfile;
	onUpdate: (profile: ProfileType) => void;
}

interface IManagedUpdateRocState extends IManagedRocProfile {
	rocUserName: string;
	rocPassword: string;
	confirmRocPassword: string;
	isSubmitting: boolean;
	displayMode: boolean;
	error?: any;
	customValidityError?: string;
}

export class ManagedUpdateRoc<
	ProfileType extends IManagedRocProfile
> extends React.Component<
	IManagedUpdateRocProps<ProfileType>,
	IManagedUpdateRocState
> {
	public state: IManagedUpdateRocState = {
		rocUserName: '',
		rocPassword: '',
		confirmRocPassword: '',
		isSubmitting: false,
		displayMode: true,
		error: undefined
	};

	public componentDidMount() {
		this.init();
	}

	public init(reset = false) {
		const { rocUserName = '', rocPassword = '' } = this.props.profile;
		const confirmRocPassword = rocPassword;

		this.setState({ rocUserName, rocPassword, confirmRocPassword });

		if (reset) {
			this.setState({
				isSubmitting: false,
				displayMode: true,
				error: undefined
			});
		}
	}

	public updatePassword(rocPassword: string) {
		this.setState({ rocPassword }, () => this.checkForPasswordsMatching());
	}

	public updatePasswordConfirm(confirmRocPassword: string) {
		this.setState({ confirmRocPassword }, () =>
			this.checkForPasswordsMatching()
		);
	}

	public checkForPasswordsMatching() {
		const { confirmRocPassword, rocPassword } = this.state;
		let customValidityError = '';

		if (confirmRocPassword !== rocPassword) {
			customValidityError = 'Passwords must match.';
		}

		if (this.state.customValidityError !== customValidityError) {
			this.setState({ customValidityError });
		}
	}

	public submit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const { resourceClass, id } = this.props;
		const { rocPassword, rocUserName } = this.state;

		const managedResource = new resourceClass();

		this.setState({ isSubmitting: true, error: undefined });

		managedResource
			.updateRoc(id, rocUserName, rocPassword)
			.then((profile) => {
				this.props.onUpdate(profile);

				this.setState({
					displayMode: true,
					rocPassword: '',
					confirmRocPassword: ''
				});
			})
			.catch((error) => {
				this.setState({ error });
			})
			.finally(() => {
				this.setState({ isSubmitting: false });
			});
	}

	public delete() {
		const { resourceClass, id } = this.props;

		const managedResource = new resourceClass();

		this.setState({ isSubmitting: true, error: undefined });

		managedResource
			.deleteRoc(id)
			.then((profile) => {
				this.props.onUpdate(profile);

				this.setState({
					displayMode: true,
					rocUserName: '',
					rocPassword: '',
					confirmRocPassword: ''
				});
			})
			.catch((error) => {
				this.setState({ error });
			})
			.finally(() => {
				this.setState({ isSubmitting: false });
			});
	}

	public render() {
		return (
			<RtUiNarrowForm
				header="ROC Credentials"
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onCancel={() => this.init(true)}
				onChange={(displayMode) => this.setState({ displayMode })}
				editModeActions={() => (
					<Button
						type="button"
						variant="outline-danger"
						disabled={this.state.isSubmitting}
						onClick={() => this.delete()}
					>
						Delete Credentials
					</Button>
				)}
				onSubmit={(evt) => this.submit(evt)}
			>
				<InputFormControl
					label="ROC Username"
					required
					displayMode={this.state.displayMode}
					onChange={(rocUserName) => this.setState({ rocUserName })}
					value={this.state.rocUserName}
				/>
				<Row>
					<Col md>
						<InputFormControl
							label="ROC Password"
							required
							type="password"
							displayMode={this.state.displayMode}
							onChange={(rocPassword) => this.updatePassword(rocPassword)}
							value={
								this.state.displayMode ? '********' : this.state.rocPassword
							}
						/>
					</Col>
					{!this.state.displayMode && (
						<Col md>
							<InputFormControl
								label="Confirm ROC Password"
								required
								type="password"
								displayMode={this.state.displayMode}
								customValidityError={this.state.customValidityError}
								onChange={(confirmRocPassword) =>
									this.updatePasswordConfirm(confirmRocPassword)
								}
								value={this.state.confirmRocPassword}
							/>
						</Col>
					)}
				</Row>
			</RtUiNarrowForm>
		);
	}
}
