/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocProfileResponse = {
    properties: {
        rocTxnId: {
            type: 'string',
            isRequired: true,
        },
        requestingRespOrgId: {
            type: 'string',
            isRequired: true,
        },
        submittingRespOrgId: {
            type: 'string',
            isRequired: true,
        },
        isIncoming: {
            type: 'boolean',
            isRequired: true,
        },
        submittedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        dueTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        totalQty: {
            type: 'number',
            isRequired: true,
        },
        approvedQty: {
            type: 'number',
            isRequired: true,
        },
        declinedQty: {
            type: 'number',
            isRequired: true,
        },
        overdueQty: {
            type: 'number',
            isRequired: true,
        },
        expiredQty: {
            type: 'number',
            isRequired: true,
        },
        otherQty: {
            type: 'number',
            isRequired: true,
        },
        pendingQty: {
            type: 'number',
            isRequired: true,
        },
        documents: {
            type: 'Array',
            isRequired: true,
        },
        tfns: {
            type: 'Array',
            isRequired: true,
        },
        notes: {
            type: 'string',
            isRequired: true,
        },
    },
};