/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TagIndexResponse = {
    properties: {
        tag: {
            type: 'string',
            isRequired: true,
        },
        tagTypeId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
    },
};