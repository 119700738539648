/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MarginSummaryIndexResponse = {
    properties: {
        costAccountId: {
            type: 'number',
        },
        costSubscriptionId: {
            type: 'number',
        },
        revenueAccountId: {
            type: 'number',
        },
        revenueSubscriptionId: {
            type: 'number',
        },
        rateCenterId: {
            type: 'number',
            isRequired: true,
        },
        countryIsoId: {
            type: 'string',
            isRequired: true,
        },
        costConnects: {
            type: 'number',
            isRequired: true,
        },
        costMinutes: {
            type: 'number',
            isRequired: true,
        },
        costCharges: {
            type: 'number',
            isRequired: true,
        },
        revenueConnects: {
            type: 'number',
            isRequired: true,
        },
        revenueMinutes: {
            type: 'number',
            isRequired: true,
        },
        revenueCharges: {
            type: 'number',
            isRequired: true,
        },
        rawMinutes: {
            type: 'number',
            isRequired: true,
        },
        margin: {
            type: 'number',
            isRequired: true,
        },
    },
};