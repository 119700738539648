import { CprNodeTypeNameMap } from 'RtUi/app/rt800/Cprs/lib/Constants';
import { AreaOfServiceLabelsMap } from 'RtUi/app/rt800/Cprs/lib/resources/AreaOfServiceValuesResource';
import { CprError, CprErrorType, CprSection } from 'Somos/lib/SomosCpr/RtCprV2';

export const formatErrorMessage = (
	cprError: CprError,
	removeEndingPunctuation = false
) => {
	let { message } = cprError;

	if (removeEndingPunctuation && message.endsWith('.')) {
		message = message.substring(0, message.length - 1);
	}

	if (cprError.cprSectionId === CprSection.CprSectionAos) {
		message = replaceAosValuesForLabelInErrorMessage(message);
	}

	if (cprError.cprSectionId === CprSection.CprSectionCpr) {
		message = replaceCprNodeTypeValuesForLabelInErrorMessage(message);
	}

	return message;
};

export const replaceAosValuesForLabelInErrorMessage = (
	errorMessage: string
) => {
	for (const [aosValue, aosLabel] of AreaOfServiceLabelsMap.entries()) {
		if (errorMessage.includes(aosValue)) {
			errorMessage = errorMessage.replace(aosValue, aosLabel);
		}
	}

	return errorMessage;
};

export const getErrorIconAndColorClassnames = (cprErrorType: CprErrorType) => {
	let iconAndColorClasses = '';

	switch (cprErrorType) {
		case CprErrorType.Warning:
			iconAndColorClasses = 'text-warning fa-info-circle';
			break;
		case CprErrorType.Error:
			iconAndColorClasses = 'text-danger fa-exclamation-circle';
			break;
		case CprErrorType.Critical:
			iconAndColorClasses = 'text-primary fa-exclamation-triangle';
			break;
	}

	return `fas fa-fw fa-sm ${iconAndColorClasses}`;
};

export const replaceCprNodeTypeValuesForLabelInErrorMessage = (
	errorMessage: string
) => {
	for (const [cprNodeType, cprLabel] of CprNodeTypeNameMap.entries()) {
		if (errorMessage.includes(cprNodeType)) {
			//only replace cprNodeType with names if word breaks are around
			const nodeRegex = new RegExp(`\\b${cprNodeType}\\b`, 'g');
			errorMessage = errorMessage.replace(nodeRegex, cprLabel);
		}
	}

	return errorMessage;
};
