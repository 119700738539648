/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NotificationPreferenceIndexResponse = {
    properties: {
        messageEventTypeId: {
            type: 'MessageEventType',
            isRequired: true,
        },
        productId: {
            type: 'ProductId',
            isRequired: true,
        },
        notifications: {
            type: 'Array',
            isRequired: true,
        },
    },
};