import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

// exported definitions
// ======================================================================

export class CprColSwitch extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.Switch;

	public readonly valueLimit = 1;

	public isPossibleValue(rawVal: string) {
		// only validating by valueRegExp
		return true;
	}

}
