/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CompressionTypes {
    None = '',
    Gzip = 'gz',
    Bz2 = 'bz2',
    Zip = 'zip',
    Tgz = 'tgz',
}