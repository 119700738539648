import { FC, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CprCleanAside } from 'RtUi/app/rt800/Cprs/lib/components/CprCleanAside';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { generateShortUUID } from 'RtUi/utils/http/resources/utils';

interface ICprEditorCleanAsideProps {
	className?: string;
}

export const CprEditorCleanAside: FC<
	React.PropsWithChildren<ICprEditorCleanAsideProps>
> = ({ className }) => {
	const { isNumberAndHasTemplateAssigned } = useContext(CprProfileContext);
	const [asideIsOpen, setAsideIsOpen] = useState(false);
	const [resetKey, setResetKey] = useState(generateShortUUID());

	useEffect(() => {
		if (!asideIsOpen) {
			setTimeout(() => {
				setResetKey(generateShortUUID());
			}, 250);
		}
	}, [asideIsOpen]);

	return (
		<>
			<Button
				variant="white-alt"
				className={className}
				onClick={() => setAsideIsOpen(true)}
				disabled={isNumberAndHasTemplateAssigned}
			>
				<i className="fas fa-fw fa-broom" />
				<span>&nbsp;Clean</span>
			</Button>
			<CprCleanAside
				key={resetKey}
				isOpen={asideIsOpen}
				toggle={setAsideIsOpen}
			/>
		</>
	);
};
