import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { EmailAliasIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getEmailAliasesColumns = (): Array<
	DataGridColumn<EmailAliasIndexResponse>
> => [
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'emailAddress',
		header: 'Email Address'
	})
];
