import { IVariableOption } from 'RtUi/app/rtSip/Scenarios/lib/controls/VariableSelect';
import {
	SimpleSelectFormControl,
	ISimpleSelectFormControlOption,
	ISimpleSelectFormControlProps
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

export type TagSelectOption = ISimpleSelectFormControlOption<string>;

interface ITagSelectProps<isMulti extends boolean>
	extends ISimpleSelectFormControlProps<string, isMulti> {
	options: TagSelectOption[];
}

export class TagSelect<
	isMulti extends boolean = false
> extends SimpleSelectFormControl<string, isMulti, ITagSelectProps<isMulti>> {
	/**
	 * @override
	 * @param inputValue
	 */
	public isValidNewOption(
		inputValue: string,
		values?: readonly IVariableOption[]
	) {
		if (inputValue === '') {
			return false;
		}

		const alphaNumericRegex = /^\w*$/;

		const isAlphaNumeric = alphaNumericRegex.test(inputValue);

		if (!isAlphaNumeric) {
			return false;
		}

		//All values are uppercase
		const inputValueUpper = inputValue.toUpperCase();

		const alreadyHasValue = (values ?? []).some(
			(value: any) => value.value === inputValueUpper
		);

		if (alreadyHasValue) {
			return false;
		}

		return true;
	}

	public triggerOnChange(
		value: isMulti extends true ? TagSelectOption[] : TagSelectOption
	) {
		//@ts-ignore
		let values: TagSelectOption[] = Array.isArray(value) ? value : [value];

		if (value === null) {
			values = [];
		}

		for (const regValue of values) {
			regValue.value = regValue.value.toUpperCase();
			regValue.label = regValue.label.toUpperCase();
		}

		super.triggerOnChange(value);
	}
}
