/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum FileLocation {
    Local = 0,
    Spaces = 1,
    S3 = 2,
}