import { cloneDeep } from 'lodash-es';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { DaysOfWeekMap, IDaysOfWeekMapType } from './interfaces';

interface IDaysOfWeekSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<IDaysOfWeekMapType, isMulti> {}

export class DaysOfWeekSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	IDaysOfWeekMapType,
	isMulti,
	IDaysOfWeekSelectProps<isMulti>
> {
	public resourceClass = undefined;
	public resource = new ArrayResource<IDaysOfWeekMapType>(
		'key',
		cloneDeep(DaysOfWeekMap)
	);
	public state: ISelectFormControlState<IDaysOfWeekMapType> = {
		formLabel: 'Days Of Week',
		valueKey: 'key',
		labelKey: 'label'
	};
}
