/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CprStatus {
    Saved = 1,
    Pending = 2,
    Sending = 3,
    Active = 4,
    Old = 5,
    Invalid = 6,
    Disconnect = 7,
    MustCheck = 8,
    Failed = 9,
    Hold = 10,
    Unknown = 99,
}