import { PortsIndexResponseData } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getPortColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/port';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface IPortDataGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<PortsIndexResponseData> | null;
	isLoading?: boolean;
	callDateFormat?: string;
}

export const PortDataGrid = ({
	resource,
	isLoading,
	callDateFormat
}: IPortDataGridProps) => {
	const { data = [] } = useQuery<PortsIndexResponseData[] | undefined, Error>(
		[`useGetPortReport`, resource],
		() => resource?.getAll()
	);
	const columns = useMemo(
		() => getPortColumns(callDateFormat),
		[callDateFormat]
	);

	return (
		<DataGrid<PortsIndexResponseData>
			pageName="rtVue_port"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
