import { TollFreeCprProfileResponse, TollFreeProfileRequest } from 'RtModels';
import { CprProfileContainer } from 'RtUi/app/rt800/Cprs/lib/components/CprProfileContainer';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { TollFreeCprsRouter } from './Cprs.router';

export const TollFreeCprsProfileContainer = () => {
	const id = useIdParam();
	const resourceParams: TollFreeProfileRequest = {
		tfn: id
	};
	return (
		<CprProfileContainer<TollFreeCprProfileResponse>
			router={TollFreeCprsRouter}
			requestParams={resourceParams}
			isNumber={true}
		/>
	);
};

TollFreeCprsRouter.setProfileRtUiFunctionalComponent(
	TollFreeCprsProfileContainer
);
