import { ServerGroupRouter } from 'RtUi/app/rtAdmin/ServerGroups/ServerGroup.router';
import { ServerGroupEditor } from 'RtUi/app/rtAdmin/ServerGroups/lib/forms/ServerGroupEditor';
import { ServerGroupDataGrid } from 'RtUi/app/rtAdmin/ServerGroups/lib/grids/ServerGroupGrid';
import { RtAdminRouter } from 'RtUi/app/rtAdmin/rtAdmin.router';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { FC } from 'react';

export const ServerGroupIndexContainer: FC = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		'Results': { header: 'Results', isDefault: true },
		'Create Server Group': { header: 'Create Server Group', isAction: true }
	});

	return (
		<TabbedLayout
			router={ServerGroupRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Results}>
				<ServerGroupDataGrid />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs['Create Server Group']}>
				<ServerGroupEditor
					onUpdate={() => {
						setActiveTab(tabs.Results.header);
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ServerGroupIndexContainer.displayName = 'ServerGroupIndexContainer';

ServerGroupRouter.setIndexRtUiFunctionalComponent(
	ServerGroupIndexContainer,
	RtAdminRouter.getServerIndexRtUiControllerProps()
);
