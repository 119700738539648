import {
	ResourceConfigurationIndexRequest,
	ResourceConfigurationIndexResponse,
	ResourceIndexRequest
} from 'RtModels';
import { useGetResourceConfiguration } from 'RtUi/app/rtCommon/Resources/lib/services';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getResourceConfigurationColumns } from 'RtUi/components/data/DataGrid/configurations/rtCommon/resources';
import { FC, useMemo } from 'react';

type ResourceConfigurationIndexRequestType = ResourceConfigurationIndexRequest &
	ResourceIndexRequest;
interface IResourceGridProps {
	router: RtUiRouter;
	onRowClick?: (row: ResourceConfigurationIndexResponse) => void;
	resourceParams?: ResourceConfigurationIndexRequestType;
	pageName: string;
	autoFocusFilter?: boolean;
}

export const ResourceGrid: FC<IResourceGridProps> = ({
	resourceParams,
	onRowClick,
	pageName,
	router
}) => {
	const { data, isFetching: isLoading } =
		useGetResourceConfiguration(resourceParams);
	const columns = useMemo(() => getResourceConfigurationColumns(), []);

	return (
		<DataGrid<ResourceConfigurationIndexResponse>
			disableExternalLinks
			router={router}
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			onRowClick={onRowClick}
			pageName={pageName}
		/>
	);
};
