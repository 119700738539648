import { ActivePortAllIndexResponse } from 'RtModels';
import { useGetActivePortsPartition } from 'RtUi/app/rtSip/ActivePortsPartition/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getActivePortsPartitionColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/activePortsPartition';
import { useMemo } from 'react';

interface IActivePortsPartitionGridProps {
	autoFocusFilter?: boolean;
}

export const ActivePortsPartitionDataGrid =
	({}: IActivePortsPartitionGridProps) => {
		const { data, isFetching: isLoading } = useGetActivePortsPartition();
		const columns = useMemo(() => getActivePortsPartitionColumns(), []);

		return (
			<DataGrid<ActivePortAllIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtSip_activePortsPartition'}
			/>
		);
	};
