import * as React from 'react';
import { Aside } from 'RtUi/components/ui/Aside';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { TemplateLcrScenarioAvailableIndexResponse } from 'RtModels';
import { TemplateLcrScenarioResource } from '../../Templates/lib/resources/TemplateLcrScenarioResource';
import { TemplateLcrScenarioSelect } from 'RtUi/app/rtLco/Templates/controls/TemplateLcrScenarioSelect';

interface ILcrCarriersAddTemplateAsideProps {
	isOpen: boolean;
	templateName: string;
	onCancel: () => void;
	onBeforeSave: () => void;
	onSave: () => void;
}

interface ILcrCarriersAddTemplateAsideState {
	isSubmitting?: boolean;
	lcrScenario?: TemplateLcrScenarioAvailableIndexResponse;
	error?: any;
}

export class LcrScenariosAddTemplateAside extends React.Component<
	ILcrCarriersAddTemplateAsideProps,
	ILcrCarriersAddTemplateAsideState
> {
	public initialState: ILcrCarriersAddTemplateAsideState = {
		error: undefined,
		isSubmitting: false,
		lcrScenario: undefined
	};

	public state = this.initialState;

	public render() {
		return (
			<Aside isOpen={this.props.isOpen}>
				<header className="h3 d-flex justify-content-between">
					<span>Add Scenario to Template</span>
					<a
						className="text-dark"
						onClick={() =>
							this.setState(this.initialState, this.props.onCancel)
						}
					>
						<i className="fas fa-fw fa-times" />
					</a>
				</header>

				<hr className="mb-0" />

				<RtUiForm
					displayMode={false}
					disablePadding
					isSubmitting={this.state.isSubmitting}
					error={this.state.error}
					onCancel={() => this.setState(this.initialState, this.props.onCancel)}
					onSubmit={async (e) => {
						e.preventDefault();

						await this.addLcrScenario();
					}}
				>
					<InputFormControl
						label="Template Name"
						readOnly={true}
						displayMode={true}
						value={this.props.templateName}
					/>
					<TemplateLcrScenarioSelect
						required
						templateName={this.props.templateName}
						onChange={(lcrScenario) => this.setState({ lcrScenario })}
						value={this.state.lcrScenario}
					/>
				</RtUiForm>
			</Aside>
		);
	}

	private async addLcrScenario() {
		if (!this.state.lcrScenario) {
			return;
		}

		try {
			this.props.onBeforeSave();
			const templateLcrScenarioResource = new TemplateLcrScenarioResource(
				this.props.templateName
			);
			this.setState({ isSubmitting: true, error: undefined });
			await templateLcrScenarioResource.addToTemplate(
				this.props.templateName,
				this.state.lcrScenario.lcrScenarioId
			);
			this.setState(
				{ isSubmitting: false, error: undefined },
				this.props.onSave
			);
		} catch (error) {
			this.setState({ error, isSubmitting: false }, this.props.onSave);
		}
	}
}
