import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface DelimiterDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	delimiter?: string;
	separator?: string;
}

export const DelimiterDataGridColumn = <T = any,>({
	delimiter = ',',
	separator = ',',
	...config
}: DelimiterDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: any) => {
		let formattedValue;

		if (Array.isArray(value)) {
			formattedValue = value.join(separator);
		} else {
			const valueString = value ? String(value) : '';

			formattedValue = valueString.split(delimiter).join(separator);
		}

		return formattedValue;
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: any) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<any>())
	});
};
