import { RoutingSummaryIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AverageCallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AverageCallDurationDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';

export const getRoutingSummaryColumns = (): Array<
	DataGridColumn<RoutingSummaryIndexResponse>
> => [
	IntegerDataGridColumn({
		accessorKey: 'attempts',
		header: 'Attempts'
	}),
	IntegerDataGridColumn({
		accessorKey: 'accepts',
		header: 'Accepts'
	}),
	IntegerDataGridColumn({
		accessorKey: 'connects',
		header: 'Connects'
	}),
	IntegerDataGridColumn({
		accessorKey: 'minutes',
		header: 'Minutes'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'asr',
		header: 'Asr'
	}),
	AverageCallDurationDataGridColumn({
		accessorKey: 'acd',
		header: 'Acd'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'ner',
		header: 'Ner'
	}),
	DefaultDataGridColumn({
		accessorKey: 'customerSubscription',
		header: 'Customer Subscription'
	}),
	DefaultDataGridColumn({
		accessorKey: 'vendorSubscription',
		header: 'Vendor Subscription'
	}),
	DefaultDataGridColumn({
		accessorKey: 'country',
		header: 'Country'
	})
];
