import { Rt800ApiRoutes } from 'RtExports/routes';
import { LcoApplicationRouter } from 'RtUi/app/rtLco/rtLco.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { LcrTaskIndexResponse, LcrTaskProfileResponse } from 'RtModels';

export interface ILcrTaskContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Results: TabbedLayoutTabProps;
}

export const LcrTaskContainerTabs: ILcrTaskContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Results: {
		header: 'Results'
	}
};

export class LcrTaskRouterClass extends LcoApplicationRouter<
	LcrTaskIndexResponse,
	LcrTaskProfileResponse,
	ILcrTaskContainerTabs
> {
	constructor() {
		super('LCO Task', 'lcrTasks', 'lcrTaskId', LcrTaskContainerTabs);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.LcrTasks);
	}

	/**
	 * @override
	 */
	public getProfileLabel(profile: LcrTaskProfileResponse) {
		const lcrTaskIdStr = profile.lcrTaskId ? String(profile.lcrTaskId) : '';

		return `${profile.templateName} (${lcrTaskIdStr})`;
	}
}

export const LcrTaskRouter = new LcrTaskRouterClass();
