/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $InvoiceIndexResponse = {
    properties: {
        invoiceId: {
            type: 'number',
            isRequired: true,
        },
        accountId: {
            type: 'number',
            isRequired: true,
        },
        accountName: {
            type: 'string',
            isRequired: true,
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        invoiceCycleGroupId: {
            type: 'number',
            isRequired: true,
        },
        invoiceDate: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        charges: {
            type: 'number',
            isRequired: true,
        },
        invoiceCycleGroupLabel: {
            type: 'string',
            isRequired: true,
        },
    },
};