import { PartitionSipGateway } from 'RtModels';
import { PartitionSipGatewayEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionSipGatewayEditor';
import { useGetPartitionSipGateways } from 'RtUi/app/rtAdmin/Partitions/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getPartitionSipGatewaysColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/partitions';
import { Aside } from 'RtUi/components/ui/Aside';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Item } from 'react-contexify';

interface IPartitionSipGatewayDataGridProps {
	partitionId: number;
	autoFocusFilter?: boolean;
}

export const PartitionSipGatewayDataGrid = ({
	partitionId
}: IPartitionSipGatewayDataGridProps) => {
	const [asideIsOpen, setAsideIsOpen] = useState<boolean>(false);
	const [selectedRecord, setSelectedRecord] = useState<PartitionSipGateway>();
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetPartitionSipGateways({ partitionId });
	const columns = useMemo(() => getPartitionSipGatewaysColumns(), []);

	return (
		<>
			<DataGrid<PartitionSipGateway>
				data={data?.data}
				loading={isLoading}
				pageName="rtAdmin-partitionSipGateways"
				menuLinks={(record) => (
					<Item
						onClick={() => {
							setSelectedRecord(record);
							setAsideIsOpen(true);
						}}
					>
						<span className="d-flex justify-content-start align-items-center">
							<i className="fas fa-fw fa-pen" />
							<span>Edit</span>
						</span>
					</Item>
				)}
				columns={columns}
			/>
			<Aside isOpen={asideIsOpen}>
				{selectedRecord && (
					<React.Fragment key={selectedRecord.partitionSipGatewayId}>
						<header className="d-flex justify-content-between align-items-center mb-3">
							<h5 className="mb-0">
								<b>SIP Gateway - {selectedRecord.partitionSipGatewayId}</b>
							</h5>
							<Button
								type="button"
								variant="light"
								onClick={() => setAsideIsOpen(false)}
							>
								<i className="fas fa-fw fa-times" />
							</Button>
						</header>
						<PartitionSipGatewayEditor
							disablePadding
							partitionId={partitionId}
							editMode={selectedRecord}
							onCancel={() => {
								setAsideIsOpen(false);
								setSelectedRecord(undefined);
							}}
							onUpdate={() => {
								setAsideIsOpen(false);
								setSelectedRecord(undefined);
								refetch();
							}}
						/>
					</React.Fragment>
				)}
			</Aside>
		</>
	);
};
