import { LcrCarrierStateProfileResponse } from 'RtModels';
import { BulkUpdateCarrierBtn } from 'RtUi/app/rtLco/Carriers/lib/components/BulkUpdateCarrierBtn';
import { useGetRatingSetup } from 'RtUi/app/rtLco/Carriers/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCarrierRatingSetupColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/carriers';
import { useMemo, useState } from 'react';
import { BulkUpdateCarrierByRegionBtn } from '../components/BulkUpdateCarrierByRegionBtn';
import { UpdateCarrierStateRateAside } from '../components/UpdateCarrierStateRateAside';

interface ILcrCarrierRatesSetupGridProps {
	lcrCarrierId: number;
}

export const LcrCarrierRatesSetupGrid = ({
	lcrCarrierId
}: ILcrCarrierRatesSetupGridProps): JSX.Element => {
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetRatingSetup({ lcrCarrierId });
	const [editingCarrierRate, setEditingCarrierRate] =
		useState<LcrCarrierStateProfileResponse>();
	const columns = useMemo(() => getCarrierRatingSetupColumns(), []);

	const onCarrierStateUpdate = () => {
		setEditingCarrierRate(undefined);
		refetch();
	};

	return (
		<>
			<DataGrid<LcrCarrierStateProfileResponse>
				pageName="rtLco-carrierRatesSetup"
				loading={isLoading}
				data={data?.data}
				onRowClick={(record) => setEditingCarrierRate(record)}
				totalRows={data?.totalCount}
				columns={columns}
				headerAction={() => (
					<>
						<BulkUpdateCarrierBtn
							lcrCarrierId={lcrCarrierId}
							onUpdate={() => refetch()}
						/>
						<BulkUpdateCarrierByRegionBtn
							lcrCarrierId={lcrCarrierId}
							onUpdate={() => refetch()}
						/>
					</>
				)}
			/>
			<UpdateCarrierStateRateAside
				lcrCarrierId={lcrCarrierId}
				profile={editingCarrierRate}
				onCancel={() => setEditingCarrierRate(undefined)}
				onUpdate={() => onCarrierStateUpdate()}
			/>
		</>
	);
};
