import * as moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { JobRouter } from 'RtUi/app/rtCarrierConnect/Jobs/Job.router';
import {
	JobProfileResponse,
	JobIndexRequest,
	$JobIndexRequest,
	ResourceConfigurationIndexResponse,
	UserIndexResponse,
	ResourceType,
	TFActionResponse
} from 'RtModels';
import { JobGrid } from 'RtUi/app/rtCarrierConnect/Jobs/lib/grids/JobGrid';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	JobTaskStatusSelect,
	IJobTaskStatusSelectOption
} from 'RtUi/app/rtCarrierConnect/Jobs/lib/controls/JobTaskStatusSelect';
import { UploadCarrierConnectForm } from 'RtUi/app/rtCarrierConnect/Jobs/lib/forms/UploadCarrierConnectForm';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { ResourceConfigurationSelect } from 'RtUi/app/rtCommon/ResourceConfigurations/lib/controls/ResourceConfigurationSelect';

interface IInitialResourceParams {
	jobId: string | undefined;
	search: string | undefined;
	jobResources: ResourceConfigurationIndexResponse[] | undefined;
	scheduledBefore: moment.Moment | undefined;
	scheduledAfter: moment.Moment | undefined;
	submittedBy: UserIndexResponse | undefined;
	referenceKey: string | undefined;
	includeResourceStatusIds: IJobTaskStatusSelectOption[] | undefined;
	excludeResourceStatusIds: IJobTaskStatusSelectOption[] | undefined;
}

interface IJobIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {
	resourceParams: JobIndexRequest | undefined;
}

@JobRouter.getIndexRtUiController()
export class JobIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IJobIndexContainerState
> {
	public tabs = {
		Search: 'Search',
		Results: 'Results',
		Actions: 'Create'
	};
	public initParams: IInitialResourceParams = {
		jobId: undefined,
		search: undefined,
		jobResources: undefined,
		scheduledBefore: undefined,
		scheduledAfter: moment().subtract(90, 'days').startOf('day'),
		submittedBy: undefined,
		referenceKey: undefined,
		includeResourceStatusIds: undefined,
		excludeResourceStatusIds: undefined
	};

	public state: IJobIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const {
			jobId,
			jobResources,
			scheduledBefore,
			scheduledAfter,
			submittedBy,
			referenceKey,
			includeResourceStatusIds,
			excludeResourceStatusIds,
			search
		} = this.state;

		const resourceParams: JobIndexRequest = {};

		if (jobId) {
			resourceParams.jobId = jobId;
		}

		if (scheduledBefore) {
			resourceParams.scheduledBeforeTs = scheduledBefore.toDate();
		}

		if (scheduledAfter) {
			resourceParams.scheduledAfterTs = scheduledAfter.toDate();
		}

		if (jobResources) {
			resourceParams.resourceId = jobResources.map((jr) => jr.resourceId);
		}

		if (submittedBy) {
			resourceParams.submittedBy = submittedBy.userId;
		}

		if (referenceKey) {
			resourceParams.contains = referenceKey;
		}

		if (search) {
			resourceParams.search = search;
		}

		if (includeResourceStatusIds) {
			resourceParams.includeResourceStatusId = includeResourceStatusIds.map(
				(s) => s.value
			);
		}

		if (excludeResourceStatusIds) {
			resourceParams.excludeResourceStatusId = excludeResourceStatusIds.map(
				(s) => s.value
			);
		}

		return resourceParams;
	}

	public goToJobProfile(profile: JobProfileResponse) {
		const jobProfilePath = JobRouter.getProfileRoute(profile.jobId);

		this.goToPath(jobProfilePath);
	}

	public onCarrierConnectUpdate(res: TFActionResponse | void) {
		if (res && this.isJobProfileResponse(res)) {
			this.goToJobProfile(res);
		}
	}

	public isJobProfileResponse(
		response: TFActionResponse
	): response is JobProfileResponse {
		return 'jobId' in response;
	}

	public render() {
		return (
			<TabbedLayout
				router={JobRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm wide onSubmit={(e) => this.submitAndGoToResults(e)}>
						<Row>
							<Col lg={6}>
								<ResourceConfigurationSelect<true>
									multi
									resourceType={ResourceType.CarrierConnect}
									onChange={(jobResources) => this.setState({ jobResources })}
									value={this.state.jobResources}
								/>
							</Col>
							<Col lg={6}>
								<InputFormControl
									label="Reference"
									subLabel="(exact match)"
									onChange={(referenceKey) => this.setState({ referenceKey })}
									value={this.state.referenceKey}
								/>
							</Col>
							<Col lg={6}>
								<DatetimeFormControl
									label="Scheduled Before"
									disablePresets
									onChangeWithIsNow={(scheduledBefore) =>
										this.setState({
											scheduledBefore
										})
									}
									value={this.state.scheduledBefore}
								/>
							</Col>
							<Col lg={6}>
								<DatetimeFormControl
									label="Scheduled After"
									minDate={this.state.scheduledBefore}
									disablePresets
									onChangeWithIsNow={(scheduledAfter) =>
										this.setState({ scheduledAfter })
									}
									value={this.state.scheduledAfter}
								/>
							</Col>
							<Col lg={6}>
								<InputFormControl
									label="Search"
									subLabel="(Approx. Reference Match)"
									minLength={$JobIndexRequest.properties.search.minLength}
									onChange={(search) => this.setState({ search })}
									value={this.state.search}
								/>
							</Col>
							<Col lg={6}>
								<UserSelect
									label="Submitted By"
									onChange={(submittedBy) => this.setState({ submittedBy })}
									value={this.state.submittedBy}
								/>
							</Col>
							<Col lg={6}>
								<JobTaskStatusSelect<true>
									label="Included Statuses"
									multi
									onChange={(includeResourceStatusIds) =>
										this.setState({
											includeResourceStatusIds
										})
									}
									value={this.state.includeResourceStatusIds}
								/>
							</Col>
							<Col lg={6}>
								<JobTaskStatusSelect<true>
									label="Excluded Statuses"
									multi
									onChange={(excludeResourceStatusIds) =>
										this.setState({
											excludeResourceStatusIds
										})
									}
									value={this.state.excludeResourceStatusIds}
								/>
							</Col>
							<Col lg={6}>
								<InputFormControl
									label="Job ID"
									placeholder="e.g. ba48242a-e8cd-4e8a-93dc-76d9ed605a9b"
									minLength={$JobIndexRequest.properties.jobId.minLength}
									maxLength={$JobIndexRequest.properties.jobId.maxLength}
									onChange={(jobId) => this.setState({ jobId })}
									value={this.state.jobId}
								/>
							</Col>
						</Row>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<JobGrid resourceParams={this.state.resourceParams} autoFocusFilter />
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Actions} isAction>
					<UploadCarrierConnectForm
						onUpdate={(res) => this.onCarrierConnectUpdate(res)}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
