import { assign, cloneDeep } from 'lodash-es';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { Rt800Router } from 'RtUi/app/rt800/rt800.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { timestampToReadable } from 'RtUi/utils/maps';
import { IMgiTaskLabelType } from '../Tasks/lib/interfaces';
import { MgiTaskOptionsHelper } from '../Tasks/lib/MgiTaskOptions';
import { NumberGroupIndexResponse, NumberGroupProfileResponse } from 'RtModels';

interface IMgiTaskLabelTabProps extends TabbedLayoutTabProps {
	header: IMgiTaskLabelType;
}

interface INumberTabbedContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Numbers: TabbedLayoutTabProps;
	Audit: TabbedLayoutTabProps;
	Tasks: TabbedLayoutTabProps;
}

export const NumberGroupTabs: INumberTabbedContainerTabs = {
	Profile: { header: 'Profile' },
	Numbers: { header: 'Numbers' },
	Audit: { header: 'Audit' },
	Tasks: { header: 'Tasks' }
};

class NumberGroupRouterClass extends Rt800Router<
	NumberGroupIndexResponse,
	NumberGroupProfileResponse,
	IProfileApplicationContainerTabs
> {
	constructor() {
		super('Number Group', 'numberGroups', 'numberGroupId', NumberGroupTabs);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.NumberGroups);
	}

	// Removed as Per Russ' Request
	// public globalSearch(search: string) {
	// 	return this.defaultGlobalSearch(NumberGroupResource, search);
	// }

	/**
	 * @override
	 */
	public getProfileTabs() {
		const NumberGroupTabsInstance = cloneDeep(NumberGroupTabs);
		const actionTabs = this.getActionTabs();

		assign(NumberGroupTabsInstance, actionTabs);

		return NumberGroupTabsInstance;
	}

	//Must refresh actions when permissions update
	public getActionTabs(): IMgiTaskLabelTabProps[] {
		return MgiTaskOptionsHelper.getInstance()
			.getNumberGroupOptionLabels()
			.map((numberGroupOption) => {
				return {
					header: numberGroupOption,
					isAction: true
				};
			});
	}

	public getIndexLabel(record: NumberGroupIndexResponse) {
		return record.label;
	}

	public getProfileLabel(profile: NumberGroupProfileResponse) {
		return `${profile.label}: ${timestampToReadable(profile.createdAt)}`;
	}
}

export const NumberGroupRouter = new NumberGroupRouterClass();
