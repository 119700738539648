import * as React from 'react';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { ResourceConfigurationIndexResponse } from 'RtModels';
import { FlowStepUiResourceTypes } from 'RtUi/app/rtAdmin/Flows/lib/resources/FlowStepResource';
import { Form } from 'react-bootstrap';
import { ResourceConfigurationSelect } from 'RtUi/app/rtCommon/ResourceConfigurations/lib/controls/ResourceConfigurationSelect';

type FlowStepResourceFormOnChangeHandler<
	K extends keyof FlowStepUiResourceTypes
> = (propertyKey: K, propertyValue: FlowStepUiResourceTypes[K]) => void;

interface IFlowStepResourceFormProps {
	//integrationTypeId?: number;
	value: FlowStepUiResourceTypes;
	onChange: FlowStepResourceFormOnChangeHandler<keyof FlowStepUiResourceTypes>;
}

interface IFlowStepResourceFormState {
	resource?: ResourceConfigurationIndexResponse;
}

export class FlowStepResourceForm extends React.Component<
	IFlowStepResourceFormProps,
	IFlowStepResourceFormState
> {
	public state: IFlowStepResourceFormState = {};

	public updateResource(resource?: ResourceConfigurationIndexResponse) {
		this.setState({ resource });

		this.props.onChange('resourceId', resource?.resourceId ?? -1);
	}

	public render() {
		const flowStepResource = this.props.value;
		const { onChange } = this.props;

		return (
			<Form>
				<ResourceConfigurationSelect
					clearable={false}
					onChange={(resource) => this.updateResource(resource)}
					value={this.state.resource}
					initialOptionId={String(flowStepResource.resourceId)}
				/>
				<BooleanRadioFormControl
					label="Default"
					onChange={(isDefault) => onChange('isDefault', isDefault)}
					value={flowStepResource.isDefault}
				/>
				<BooleanRadioFormControl
					label="Required"
					onChange={(isRequired) => onChange('isRequired', isRequired)}
					value={flowStepResource.isRequired}
				/>
			</Form>
		);
	}
}
