import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { UserIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';

export const getAdminUserColumns = (): Array<
	DataGridColumn<UserIndexResponse>
> => [
	DefaultDataGridColumn({
		header: 'User Name',
		accessorFn: (row) => `${row.firstName} ${row.lastName}`
	}),
	DefaultDataGridColumn({
		accessorKey: 'email',
		header: 'Email'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'emailAuthorizationTs',
		header: 'Email Verified'
	}),
	BooleanDataGridColumn({
		accessorKey: 'twoFactorAuthType',
		header: 'Two Factor Auth'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isLocked',
		header: 'Is Locked'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastLoginTs',
		header: 'Last Login'
	})
];
