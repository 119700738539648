/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateCenterTypes {
    COUNTRY = 1,
    FIXED = 2,
    MOBILE = 3,
    WIRELESS = 4,
    VOIP = 5,
    RURAL = 6,
    SPECIAL = 7,
}