/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MessagesDetailIndexResponse = {
    properties: {
        vuid: {
            type: 'string',
            isRequired: true,
        },
        sentTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        completedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        frStd: {
            type: 'string',
            isRequired: true,
        },
        frRaw: {
            type: 'string',
            isRequired: true,
        },
        toStd: {
            type: 'string',
            isRequired: true,
        },
        toRaw: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        direction: {
            type: 'string',
            isRequired: true,
        },
        jurisdiction: {
            type: 'string',
            isRequired: true,
        },
        messageStatus: {
            type: 'string',
            isRequired: true,
        },
        messageStatusCode: {
            type: 'string',
            isRequired: true,
        },
        totalSegments: {
            type: 'number',
            isRequired: true,
        },
        segmentNumber: {
            type: 'number',
            isRequired: true,
        },
        messageLength: {
            type: 'number',
            isRequired: true,
        },
        billable: {
            type: 'number',
            isRequired: true,
        },
        campaignId: {
            type: 'string',
            isRequired: true,
        },
        campaignClass: {
            type: 'string',
            isRequired: true,
        },
        dlrCode: {
            type: 'string',
            isRequired: true,
        },
        locationId: {
            type: 'string',
            isRequired: true,
        },
        carrierName: {
            type: 'string',
            isRequired: true,
        },
        brandId: {
            type: 'string',
            isRequired: true,
        },
        customerAccountId: {
            type: 'number',
        },
        customerSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        customerExternalAccountId: {
            type: 'string',
        },
        customerExternalSubAccountId: {
            type: 'string',
        },
        vendorAccountId: {
            type: 'number',
        },
        vendorSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        vendorExternalAccountId: {
            type: 'string',
        },
        vendorExternalSubAccountId: {
            type: 'string',
        },
        ingressRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        ingressCountry: {
            type: 'string',
        },
        egressRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        egressCountry: {
            type: 'string',
        },
        revenue: {
            type: 'number',
            isRequired: true,
        },
        revenueRate: {
            type: 'number',
        },
        revenueRateKey: {
            type: 'string',
        },
        cost: {
            type: 'number',
            isRequired: true,
        },
        costRate: {
            type: 'number',
        },
        costRateKey: {
            type: 'string',
        },
        margin: {
            type: 'number',
            isRequired: true,
        },
        customSmsField1: {
            type: 'string',
        },
        customSmsField2: {
            type: 'string',
        },
    },
};