/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationIndexResponse = {
    properties: {
        guardianReconciliationId: {
            type: 'number',
            isRequired: true,
        },
        reconciliationType: {
            type: 'ReconciliationType',
            isRequired: true,
            isNullable: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        reconciliationStatus: {
            type: 'GuardianReconciliationStatus',
            isRequired: true,
            isNullable: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        serviceId: {
            type: 'number',
            isRequired: true,
        },
        carrierBanNumber: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        carrierInvoiceId: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        carrierInvoiceCharges: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rtSrcCharges: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rtTgtCharges: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        disputedAmount: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        approvedAmount: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        updatedBy: {
            type: 'number',
            isRequired: true,
        },
        updateTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        followupTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        receivedAmount: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        reconciliationUsageDetailId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
    },
};