import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { FullSummaryIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IFullSummaryReportRouterTabs
	extends IProfileApplicationContainerTabs {
	Search: TabbedLayoutTabProps;
	Results: TabbedLayoutTabProps;
}

export const FullSummaryReportContainerTabs: IFullSummaryReportRouterTabs = {
	Search: {
		header: 'Search'
	},
	Results: {
		header: 'Results'
	}
};

export class FullSummaryReportRouterClass extends SipApplicationRouter<
	FullSummaryIndexResponse,
	FullSummaryIndexResponse,
	IFullSummaryReportRouterTabs
> {
	constructor() {
		super(
			'Full Summary',
			'fullSummary',
			'callDate',
			FullSummaryReportContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.FullSummary);
	}

	public getProfileLabel(profile: FullSummaryIndexResponse): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const FullSummaryReportRouter = new FullSummaryReportRouterClass();
