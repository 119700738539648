/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RtAosLblDto = {
    properties: {
        name: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        aosNodeTypeId: {
            type: 'AosNodeType',
            isRequired: true,
        },
        values: {
            type: 'Array',
            isRequired: true,
        },
    },
};