import { TemplateLcrScenarioAvailableIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { TemplateLcrScenarioAvailable } from 'RtUi/app/rtLco/Templates/lib/resources/TemplateLcrScenarioAvailable';

interface ILcrScenarioSelectProps
	extends ISelectFormControlProps<TemplateLcrScenarioAvailableIndexResponse> {
	templateName: string;
}

export class TemplateLcrScenarioSelect extends SelectFormControl<
	TemplateLcrScenarioAvailableIndexResponse,
	false,
	ILcrScenarioSelectProps
> {
	public resourceClass = undefined;
	public state: ISelectFormControlState<TemplateLcrScenarioAvailableIndexResponse> =
		{
			formLabel: 'Scenario',
			valueKey: 'lcrScenarioId',
			labelKey: 'label',
			placeholder: 'Select Scenario...'
		};

	constructor(props: ILcrScenarioSelectProps) {
		super(props);

		this.resource = new TemplateLcrScenarioAvailable(this.props.templateName);
	}
}
