/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoutingGroupTemplateCreateRequest = {
    properties: {
        templateName: {
            type: 'string',
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        routingTemplateTypeId: {
            type: 'RoutingTemplateTypes',
            isRequired: true,
        },
        isCurrent: {
            type: 'number',
            isRequired: true,
        },
    },
};