import { SipCallSimulatorResponseDtoRateable } from 'RtModels';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCallSimulatorColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/callSimulator';
import { FC, useMemo } from 'react';

interface ICallSimulatorGridProps {
	rateables: SipCallSimulatorResponseDtoRateable[];
	srcUseRate: number;
}

export const CallSimulatorGrid: FC<
	React.PropsWithChildren<ICallSimulatorGridProps>
> = ({ rateables, srcUseRate }) => {
	const columns = useMemo(
		() => getCallSimulatorColumns(srcUseRate),
		[srcUseRate]
	);

	return (
		<DataGrid<SipCallSimulatorResponseDtoRateable>
			data={rateables}
			columns={columns}
			pageName={'rtSip_CallSimulator'}
		/>
	);
};
