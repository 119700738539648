import { TagMatchingRouter } from 'RtUi/app/rtSip/TagMatching/TagMatching.router';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import {
	SearchResultsContainer,
	ISearchResultsContainerState
} from 'RtUi/components/containers/SearchResultsContainer';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	TagIndexResponse,
	TagMatchingIndexRequest,
	TagMatchingIndexResponse
} from 'RtModels';
import { StatesSelect } from 'RtUi/app/rtCommon/States/lib/controls/StatesSelect';
import { IStateOption } from 'RtUi/app/rtCommon/States/lib/resources/States';
import { TagTypeSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/TagTypeSelect';
import { TagDataGrid } from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/TagDataGrid';

interface IInitialResourceParams {
	value: string;
	stateValue?: IStateOption;
	tag: TagIndexResponse | undefined;
}

interface ITagMatchingIndexContainerState
	extends ISearchResultsContainerState<TagMatchingIndexRequest>,
		IInitialResourceParams {}

@TagMatchingRouter.getBusinessOpsIndexRtUiController()
export class TagMatchingIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	ITagMatchingIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		tag: undefined,
		value: ''
	};

	public state: ITagMatchingIndexContainerState = {
		activeTab: this.tabs.Results,
		resourceParams: {},
		...this.initParams
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { tag, value } = this.state;
		const resourceParams: TagMatchingIndexRequest = {};

		if (tag) {
			resourceParams.tag = tag.tag;
		}

		if (value.length > 0) {
			resourceParams.tagVal = value;
		}

		return resourceParams;
	}

	public render() {
		return (
			<TabbedLayout
				router={TagMatchingRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<TagTypeSelect
							onChange={(tag) => this.setState({ tag })}
							value={this.state.tag}
						/>
						{this?.state?.tag?.tag === 'SYS:FR:ST' ? (
							<StatesSelect
								value={this.state.stateValue}
								onChange={(value) => {
									this.setState({
										stateValue: value,
										value: value.abbreviation
									});
								}}
							/>
						) : (
							<InputFormControl
								label="Tag value"
								value={this.state.value}
								onChange={(value) =>
									this.setState({ value: value.toUpperCase() })
								}
							/>
						)}
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<TagDataGrid<TagMatchingIndexResponse>
						requestParams={this.state.resourceParams}
						isMatching
						pageName={'rtSip_tagMatching'}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
