import {
	PromptIndexResponse,
	PromptCreateRequest,
	PromptProfileResponse,
	PromptUpdateRequest,
	PromptProfileRequest
} from 'RtModels';
import { UiPromptAudioFileUtils } from 'RtUi/app/rtSip/PromptSegments/lib/utils/UiPromptAudioFileUtils';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class PromptResource extends HttpResource<
	PromptIndexResponse,
	PromptProfileResponse
> {
	public uiPromptAudioFileUtils = new UiPromptAudioFileUtils();

	constructor() {
		super(RtxSipApiRoutes.Prompts);
	}

	public create(label: string, isActive: number, audioFile: File) {
		const req: PromptCreateRequest = { label, isActive };
		const body = new FormData();
		const params = req;
		const removeContentType = true; //otherwise it defaults to JSON

		body.append('file', audioFile);

		return this.fetchWithRoute<PromptProfileResponse>(
			RtxSipApiRoutes.Prompts.Create,
			{ params, body, removeContentType }
		);
	}

	public async bulkCreate(files: File[]) {
		const body = new FormData();
		const errors: string[] = [];
		const removeContentType = true; //otherwise it defaults to JSON

		for (const file of files) {
			const [isValid, errorStr] =
				await this.uiPromptAudioFileUtils.isValidatePromptFile(file);

			if (!isValid && errorStr) {
				errors.push(errorStr);

				continue;
			}

			body.append('files', file);
		}

		if (errors.length > 0) {
			throw errors;
		}

		return this.fetchWithRoute<PromptIndexResponse[]>(
			RtxSipApiRoutes.PromptBulk.Create,
			{ body, removeContentType }
		);
	}

	public update(
		promptId: number,
		label: string,
		script: string,
		isActive: number
	) {
		const req: PromptUpdateRequest = { label, script, isActive };
		const body = JSON.stringify(req);
		const urlParams: PromptProfileRequest = { promptId };

		return this.fetchWithRoute<PromptProfileResponse>(
			RtxSipApiRoutes.Prompts.Update,
			{ body, urlParams }
		);
	}

	public async getPromptFileUrl(promptId: number) {
		const urlParams: PromptProfileRequest = { promptId };
		const response = await HttpRequest.rawFetchWithRoute<PromptProfileResponse>(
			RtxSipApiRoutes.PromptFiles.Profile,
			{ urlParams }
		);
		const blob = await response.blob();
		const objectUrl = URL.createObjectURL(blob);

		return objectUrl;
	}
}
