import {
	ConnectionProfileResponse,
	AccountIndexResponse,
	SubscriptionIndexResponse,
	TimezoneIndexResponse,
	FileStreamIndexResponse,
	GuardianConnectionTrunkType,
	SwitchIndexResponse,
	TrafficTypes,
	Attestation
} from 'RtModels';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { ConnectionResource } from 'RtUi/app/rtVue/Connections/lib/resources/ConnectionResource';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { TimezoneSelect } from 'RtUi/app/rtCommon/Timezones/lib/controls/TimezoneSelect';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import { IpAddressFormControl } from 'RtUi/app/rtVue/Connections/lib/controls/IpAddressFormControl';
import { cloneDeep } from 'lodash-es';
import { FormWizard, FormWizardStep } from 'RtUi/components/form/FormWizard';
import { ConnectionContainerTabs } from 'RtUi/app/rtVue/Connections/Connection.router';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { Component } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { AttestationSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/AttestationSelect';
import { SwitchesSelect } from 'RtUi/app/AccountManagement/Switches/lib/controls/SwitchesSelect';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { FqdnInputFormControl } from 'RtUi/components/form/FqdnInputFormControl';
import { PortInputFormControl } from 'RtUi/components/form/PortFormControl';

type TOnSuccessReturnType<CreateMode extends boolean> = CreateMode extends true
	? ConnectionProfileResponse[]
	: ConnectionProfileResponse;

interface IConnectionEditorProps<CreateMode extends boolean> {
	onUpdate: (profile: TOnSuccessReturnType<CreateMode>) => void;
	onCancel: () => void;
	profile?: ConnectionProfileResponse;
	onEdit?: () => void;
	currentStep?: number;
	displayMode?: boolean;
	col?: number;
}

interface IConnectionEditorState {
	activeTab: string;
	isSubmitting: boolean;
	createParentRecord: boolean;
	editConnection: Partial<ConnectionProfileResponse>;
	guardianSwitch?: SwitchIndexResponse;
	trunkGroupId?: string;
	cpsLimit?: string;
	portLimit?: string;
	account?: AccountIndexResponse;
	error?: any;
	subscriptionId?: SubscriptionIndexResponse;
	timeZoneOverride?: TimezoneIndexResponse;
	fileStreams?: FileStreamIndexResponse[];
	trunkTypeSelect?: ISimpleSelectFormControlOption<GuardianConnectionTrunkType> | null;
	trafficTypeSelect?: ISimpleSelectFormControlOption<TrafficTypes> | null;
	wizardStep: number;
	attestationOverride?: Attestation;
}

export class ConnectionEditor<
	CreateMode extends boolean = false
> extends Component<
	IConnectionEditorProps<CreateMode>,
	IConnectionEditorState
> {
	private connectionResource = new ConnectionResource();

	constructor(props: IConnectionEditorProps<CreateMode>) {
		super(props);

		let editConnection: Partial<ConnectionProfileResponse>;

		if (props.profile) {
			editConnection = cloneDeep(props.profile);
		} else {
			editConnection = {
				isActive: 1
			};
		}

		this.state = {
			editConnection,
			isSubmitting: false,
			createParentRecord: false,
			wizardStep: this.props.currentStep || 1,
			activeTab: ConnectionContainerTabs.Profile.header
		};
	}

	public render() {
		const {
			state: { editConnection }
		} = this;
		const { onEdit = () => null, col = 10 } = this.props;
		const createMode = !this.props.displayMode && !this.props.profile;

		return (
			<Row>
				<Col lg={col}>
					<Card>
						{this.props.displayMode && (
							<header className="d-flex border-bottom flex-row-reverse p-2">
								<Button
									type="button"
									variant="warning"
									className="text-end"
									onClick={() => onEdit()}
								>
									<i className="fas fa-fw fa-edit" />
									<span>&nbsp;Edit</span>
								</Button>
							</header>
						)}
						<FormWizard
							hideWizardActions={this.props.displayMode}
							currentStep={this.state.wizardStep}
							onChange={(wizardStep) =>
								this.setState({ wizardStep, isSubmitting: false })
							}
							isSubmitting={this.state.isSubmitting}
							error={this.state.error}
						>
							<FormWizardStep
								step={1}
								header={ConnectionContainerTabs.Profile.header}
							>
								<Row>
									<Col md={6}>
										{!createMode && (
											<InputFormControl
												label="ID"
												readOnly={true}
												displayMode={true}
												value={String(editConnection.connectionId)}
											/>
										)}
										{Boolean(
											editConnection.gatewayAddress ||
												editConnection.peerAddress
										) &&
											createMode && (
												<CheckboxFormControl
													label="Create Parent Record"
													value={this.state.createParentRecord}
													onChange={(createParentRecord) =>
														this.setState({ createParentRecord })
													}
												/>
											)}
										<IpAddressFormControl
											label="Gateway Address"
											required={
												createMode &&
												(!editConnection.trunkGroupId ||
													Boolean(editConnection.peerAddress))
											}
											displayMode={!createMode}
											value={editConnection.gatewayAddress}
											onChange={(value) => {
												this.onChange('gatewayAddress', value);
											}}
										/>
										<PortInputFormControl
											label="Gateway Port"
											displayMode={!createMode}
											value={editConnection.gatewayPort}
											onChange={(value) => {
												this.onChange('gatewayPort', value);
											}}
										/>
										<FqdnInputFormControl
											label="Peer Address"
											required={
												createMode &&
												(!editConnection.trunkGroupId ||
													Boolean(editConnection.gatewayAddress))
											}
											displayMode={!createMode}
											value={editConnection.peerAddress}
											onChange={(value) => {
												this.onChange('peerAddress', value);
											}}
										/>
										<PortInputFormControl
											label="Peer Port"
											displayMode={!createMode}
											value={editConnection.peerPort}
											onChange={(value) => {
												this.onChange('peerPort', value);
											}}
										/>
										<InputFormControl
											label="Tech Prefix"
											displayMode={this.props.displayMode}
											value={editConnection.techPrefix}
											onChange={(value) => {
												this.onChange('techPrefix', value);
											}}
										/>
										<FileStreamSelect<true>
											multi
											required={createMode}
											displayMode={!createMode}
											onChange={(fileStreams) => this.setState({ fileStreams })}
											value={this.state.fileStreams}
											initialOptionId={
												editConnection.fileStreamId
													? [String(editConnection.fileStreamId)]
													: undefined
											}
										/>
										<InputFormControl
											label="External Account ID"
											maxLength={64}
											displayMode={this.props.displayMode}
											value={editConnection.externalAccountId}
											onChange={(value) => {
												this.onChange('externalAccountId', value);
											}}
										/>
										<AttestationSelect
											name="attestationOverride"
											displayMode={this.props.displayMode}
											onChange={(attestationOverride: Attestation) => {
												this.setState({ attestationOverride });
												this.onChange(
													'attestationOverride',
													attestationOverride ?? null
												);
											}}
											value={this.state.attestationOverride}
											initialOptionId={String(
												editConnection.attestationOverride ?? ''
											)}
										/>
									</Col>
									<Col md={6}>
										<IsActiveRadioFormControl
											hideBothOption
											displayMode={this.props.displayMode}
											onChange={(value) => this.onChange('isActive', value)}
											value={editConnection.isActive}
										/>
										<SwitchesSelect
											label="Switch"
											displayMode={this.props.displayMode}
											value={this.state.guardianSwitch}
											onChange={(guardianSwitch) => {
												this.setState({ guardianSwitch });
												this.onChange(
													'switchId',
													Number(guardianSwitch.switchId)
												);
											}}
											initialOptionId={
												editConnection.switchId
													? String(editConnection.switchId)
													: undefined
											}
										/>
										<InputFormControl
											maxLength={30}
											label="External ID"
											displayMode={this.props.displayMode}
											onChange={(value) =>
												this.onChange('externalId', value ?? '')
											}
											value={editConnection.externalId}
										/>
										<InputFormControl
											label="Summary"
											onChange={(value) =>
												this.onChange('summary', value ?? '')
											}
											displayMode={this.props.displayMode}
											value={editConnection.summary}
										/>
										<InputFormControl
											required={
												!editConnection.gatewayAddress ||
												!editConnection.peerAddress
											}
											label="Trunk Group ID"
											onChange={(value) => this.onChange('trunkGroupId', value)}
											displayMode={this.props.displayMode}
											value={editConnection.trunkGroupId}
										/>
										<InputFormControl
											label="Trunk Group Label"
											onChange={(value) => {
												this.onChange('trunkGroupLabel', value);
											}}
											displayMode={this.props.displayMode}
											value={editConnection.trunkGroupLabel}
										/>
										<InputFormControl
											label="Port Limit"
											type="number"
											onChange={(value) =>
												this.onChange('portLimit', value ? Number(value) : 0)
											}
											displayMode={this.props.displayMode}
											value={String(editConnection.portLimit)}
										/>
										<InputFormControl
											label="CPS Limit"
											type="number"
											onChange={(value) =>
												this.onChange('cpsLimit', value ? Number(value) : 0)
											}
											displayMode={this.props.displayMode}
											value={String(editConnection.cpsLimit)}
										/>
										<TimezoneSelect
											label="Time Zone override"
											displayMode={this.props.displayMode}
											onChange={(timeZoneOverride) => {
												this.setState({
													timeZoneOverride
												});
												this.onChange(
													'timezoneOverride',
													timeZoneOverride.label
												);
											}}
											value={this.state.timeZoneOverride}
											initialOptionId={String(editConnection.timezoneOverride)}
										/>
									</Col>
								</Row>
							</FormWizardStep>
							<FormWizardStep
								step={2}
								header={ConnectionContainerTabs.Rating.header}
								onSubmit={this.onSubmit}
							>
								<Row>
									<Col md={6}>
										<SubscriptionSelect
											label="Parent Subscription"
											displayMode={this.props.displayMode}
											onChange={(subscriptionId) => {
												this.setState({
													subscriptionId
												});
												this.onChange(
													'subscriptionId',
													subscriptionId.subscriptionId
												);
											}}
											value={this.state.subscriptionId}
											initialOptionId={editConnection.subscriptionId}
										/>
										<AccountSelect
											label="Account ID"
											displayMode={this.props.displayMode}
											onChange={(account) => this.setState({ account })}
											value={this.state.account}
											initialOptionId={String(editConnection.carrierId)}
										/>
										<SimpleSelectFormControl<TrafficTypes>
											clearable={true}
											label="Traffic Type"
											displayMode={this.props.displayMode}
											value={this.state.trafficTypeSelect!}
											initialOptionId={editConnection.trafficType!}
											onChange={(trafficTypeSelect) => {
												this.setState({ trafficTypeSelect });
												this.onChange(
													'trafficType',
													trafficTypeSelect ? trafficTypeSelect.value : null
												);
											}}
											options={[
												{
													value: TrafficTypes.commercial,
													label: TrafficTypes.commercial
												},
												{
													value: TrafficTypes.retail,
													label: TrafficTypes.retail
												},
												{
													value: TrafficTypes.wholesale,
													label: TrafficTypes.wholesale
												},
												{
													value: TrafficTypes.dialer,
													label: TrafficTypes.dialer
												},
												{
													value: TrafficTypes.misdial,
													label: TrafficTypes.misdial
												}
											]}
										/>
									</Col>
									<Col md={6}>
										<SimpleSelectFormControl<GuardianConnectionTrunkType>
											clearable={true}
											label="Trunk Type"
											displayMode={this.props.displayMode}
											value={this.state.trunkTypeSelect!}
											initialOptionId={editConnection.trunkType ?? undefined}
											onChange={(trunkTypeSelect) => {
												this.setState({ trunkTypeSelect });
												this.onChange(
													'trunkType',
													trunkTypeSelect ? trunkTypeSelect.value : null
												);
											}}
											options={[
												{
													value: GuardianConnectionTrunkType.Dedicated,
													label: 'Dedicated'
												},
												{
													value: GuardianConnectionTrunkType.Switched,
													label: 'Switched'
												}
											]}
										/>
										<BooleanRadioFormControl
											label="Imt"
											displayMode={this.props.displayMode}
											onChange={(isImt) => this.onChange('isImt', isImt)}
											value={editConnection.isImt}
										/>
										<BooleanRadioFormControl
											label="Peering"
											displayMode={this.props.displayMode}
											onChange={(isPeering) =>
												this.onChange('isPeering', isPeering)
											}
											value={editConnection.isPeering}
										/>
									</Col>
								</Row>
							</FormWizardStep>
						</FormWizard>
						{!this.props.displayMode && (
							<div className="d-flex justify-content-center">
								<Button
									type="button"
									variant="white"
									className="mb-3 mt-1"
									disabled={this.state.isSubmitting}
									onClick={this.onCancel}
								>
									Cancel
								</Button>
							</div>
						)}
					</Card>
				</Col>
			</Row>
		);
	}

	private onSubmit = async () => {
		this.setState({ isSubmitting: true, error: undefined });
		const { editConnection, account, createParentRecord, fileStreams } =
			this.state;
		const { onUpdate = () => ({}) } = this.props;

		const updateReq = {
			connectionId: editConnection.connectionId!,
			externalId: editConnection.externalId,
			summary: editConnection.summary,
			isActive: editConnection.isActive,
			carrierId: account ? String(account.accountId) : undefined,
			trunkGroupId: editConnection.trunkGroupId
				? String(editConnection.trunkGroupId)
				: undefined,
			trunkGroupLabel: editConnection.trunkGroupLabel,
			cpsLimit: editConnection.cpsLimit,
			portLimit: editConnection.portLimit,
			switchId: editConnection.switchId,
			subscriptionId: editConnection.subscriptionId,
			timezoneOverride: editConnection.timezoneOverride,
			externalAccountId: editConnection.externalAccountId,
			isImt: editConnection.isImt,
			isPeering: editConnection.isPeering,
			gatewayAddress: editConnection.gatewayAddress,
			gatewayPort: editConnection.gatewayPort,
			peerAddress: editConnection.peerAddress,
			peerPort: editConnection.peerPort,
			trunkType: editConnection.trunkType,
			trafficType: editConnection.trafficType,
			techPrefix: editConnection.techPrefix,
			attestationOverride: this.state.attestationOverride ?? null
		};

		try {
			if (!this.props.profile) {
				const fileStreamIds = fileStreams!.map((fs) => fs.fileStreamId);

				const createdConnections = await this.connectionResource.create({
					...updateReq,
					createParent: createParentRecord,
					fileStreamIds
				});

				this.setState(
					{
						isSubmitting: false
					},
					() => onUpdate(createdConnections as TOnSuccessReturnType<CreateMode>)
				);

				return;
			}

			const updatedConnection = await this.connectionResource.update(
				this.state.editConnection.connectionId!,
				updateReq
			);

			this.setState(
				{
					isSubmitting: false,
					editConnection: updatedConnection
				},
				() => onUpdate(updatedConnection as TOnSuccessReturnType<CreateMode>)
			);
		} catch (error) {
			this.setState({ isSubmitting: false, error });
			throw error;
		}
	};

	private onCancel = () => {
		const editConnection = cloneDeep(this.props.profile);

		if (editConnection) {
			this.setState({ editConnection }, () => {
				this.props.onCancel();
			});
		} else {
			this.props.onCancel();
		}
	};

	private onChange = <K extends keyof ConnectionProfileResponse>(
		key: K,
		value: ConnectionProfileResponse[K]
	) => {
		if (!this.state.isSubmitting) {
			const { editConnection } = this.state;
			editConnection[key] = value;
			this.setState({ editConnection });
		}
	};
}
