/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TransactionReversalCreateRequest = {
    properties: {
        externalRef1: {
            type: 'string',
            isNullable: true,
        },
        externalRef2: {
            type: 'string',
            isNullable: true,
        },
        comments: {
            type: 'string',
            isNullable: true,
        },
    },
};