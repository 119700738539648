/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LingoResourceConfigurationExtraParams = {
    properties: {
        accountNumber: {
            type: 'string',
            isRequired: true,
        },
        switchId: {
            type: 'string',
            isRequired: true,
        },
        directory: {
            type: 'string',
            isRequired: true,
        },
        trunkGroupId: {
            type: 'string',
            isRequired: true,
        },
        serviceTypeId: {
            type: 'string',
            isRequired: true,
        },
        routeListId: {
            type: 'string',
        },
    },
};