/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianSubscriptionCicUpdateRequest = {
    properties: {
        subscriptionId: {
            type: 'number',
        },
        cic: {
            type: 'string',
        },
        lcoCarrierId: {
            type: 'number',
        },
        priority: {
            type: 'number',
        },
    },
};