import { RtxFlowApiRoutes } from 'RtExports/routes';
import {
	JobIndexRequest,
	JobIndexResponse,
	JobTaskIndexPageRequest,
	JobTaskIndexRequest,
	JobTaskIndexResponse,
	JobTaskRequestsProfileRequest,
	JobTaskRequestsProfileResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchJobTasks = async (
	urlParams: JobTaskIndexRequest,
	queryParams?: JobTaskIndexPageRequest
): Promise<FullResponse<JobTaskIndexResponse[]>> => {
	return handleGetRequest<JobTaskIndexResponse[], true>(
		RtxFlowApiRoutes.Tasks.Index,
		{
			includeFullResponse: true,
			queryParams,
			urlParams
		}
	);
};

export const useGetJobTasks = (
	urlParams: JobTaskIndexRequest,
	queryParams?: JobTaskIndexPageRequest
) => {
	return useQuery<FullResponse<JobTaskIndexResponse[]>, Error>(
		[`useGetJobTasks`],
		() => fetchJobTasks(urlParams, queryParams)
	);
};

export const fetchJobs = async (
	queryParams?: JobIndexRequest
): Promise<FullResponse<JobIndexResponse[]>> => {
	return handleGetRequest<JobIndexResponse[], true>(
		RtxFlowApiRoutes.Jobs.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetJobs = (queryParams?: JobIndexRequest) => {
	return useQuery<FullResponse<JobIndexResponse[]>, Error>([`useGetJobs`], () =>
		fetchJobs(queryParams)
	);
};

export const fetchJobTaskRequests = async (
	urlParams: JobTaskRequestsProfileRequest
): Promise<JobTaskRequestsProfileResponse> => {
	return handleGetRequest(RtxFlowApiRoutes.TaskRequests.Profile, {
		urlParams
	});
};

export const useGetJobsTaskRequests = (
	urlParams: JobTaskRequestsProfileRequest,
	enabled: boolean = true
) => {
	return useQuery<JobTaskRequestsProfileResponse, Error>(
		[`useGetJobsTaskRequests`],
		() => fetchJobTaskRequests(urlParams),
		{ enabled }
	);
};
