import {
	RocActionRequest,
	RocActionResponse,
	RocContactRequest,
	RocCreateRequest,
	RocCreateResponse,
	RocDocumentCreateRequest,
	RocDocumentCreateResponse,
	RocDocumentIndexResponse,
	RocGetDocumentRequest,
	RocGetDocumentResponse,
	RocGetLoaRequest,
	RocGetLoaResponse,
	RocIndexResponse,
	RocLoaCreateRequest,
	RocLoaCreateResponse,
	RocProfileResponse,
	RocRemoveDialNumbersRequest,
	RocTfnIndexResponse,
	RocProfileRequest
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RocNumberStatusEnum } from 'RtUi/utils/maps';

export interface IRocCreateResponse extends RocCreateResponse {}
export interface IRocDocumentCreateRequest extends RocDocumentCreateRequest {}
export interface IRocLoaCreateRequest extends RocLoaCreateRequest {}
export interface IRocContactRequest extends RocContactRequest {}
export interface IRocLoaCreateResponse extends RocLoaCreateResponse {}
export interface IRocIndexResponse extends RocIndexResponse {}
export interface IRocActionRequest extends RocActionRequest {}
export interface IRocActionResponse extends RocActionResponse {}
export interface IRocGetLoaRequest extends RocGetLoaRequest {}
export interface IRocGetLoaResponse extends RocGetLoaResponse {}
export interface IRocDocumentIndexResponse extends RocDocumentIndexResponse {}
export interface IRocDocumentCreateResponse extends RocDocumentCreateResponse {}
export interface IRocGetDocumentRequest extends RocGetDocumentRequest {}
export interface IRocGetDocumentResponse extends RocGetDocumentResponse {}
export interface IRocRemoveDialNumbersRequest
	extends RocRemoveDialNumbersRequest {}
export interface IRocTfnIndexResponse extends RocTfnIndexResponse {
	rocStatusId: RocNumberStatusEnum;
}
export interface IRocProfileResponse extends RocProfileResponse {
	tfns: IRocTfnIndexResponse[];
}

export class RocRequestResource extends HttpResource<
	IRocIndexResponse,
	IRocProfileResponse
> {
	public static MAX_LOA_FILE_SIZE_IN_KB = 2 * 1000; //2mb

	// Match all not \w chars in the string except the extension that are in non-capturing group
	// match $%#$$^24-324.33.com -> replace('') -> 2432433.com
	public static NOT_DIGITS_AND_ASCII_CHARS_REGEXP = /(?:\.(?![^.]+$)|[^\w.])+/g;

	public fileUtils = new FileUtils();

	constructor() {
		super(Rt800ApiRoutes.Roc);
	}

	/**
	 * Create a Toll-Free ROC Request Letter of Agency
	 */
	public createLetterOfAgency(
		tfns: string[],
		companyName: string,
		companyAddress1: string,
		companyAddress2: string,
		city: string,
		state: string,
		zipCode: string,
		name: string,
		title: string,
		phone: string,
		extension: string,
		fax: string,
		email: string,
		respOrgInstructions: string,
		endUserInformation: string
	) {
		const contact: IRocContactRequest = {
			name,
			title,
			phone,
			extension,
			fax,
			email
		};
		const req: IRocLoaCreateRequest = {
			tfns,
			companyName,
			companyAddress1,
			companyAddress2,
			city,
			state,
			zipCode,
			contact,
			respOrgInstructions,
			endUserInformation
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute<IRocLoaCreateResponse>(
			Rt800ApiRoutes.RocLoa.Create,
			{ body }
		);
	}

	public downloadDocument(doc: IRocDocumentIndexResponse) {
		if (doc.isLoa) {
			return this.downloadLetterOfAgency(doc.documentId);
		}

		const { documentId } = doc;
		const params: IRocGetDocumentRequest = { documentId };

		return this.fetchWithRoute<IRocGetDocumentResponse>(
			Rt800ApiRoutes.RocDocument.Profile,
			{ params }
		).then((res) => {
			this.fileUtils.downloadBase64EncodedContent(
				res.encodedContent,
				res.fileName
			);
		});
	}

	public createDocument(rocTxnId: string, docFile: File, notes = '') {
		return this.fileUtils
			.getBase64EncodedContentFromBlob(docFile)
			.then((encodedContent) => {
				const { name: fileName } = docFile;
				const docCreateReq: IRocDocumentCreateRequest = {
					rocTxnId,
					fileName,
					encodedContent,
					notes
				};
				const body = JSON.stringify(docCreateReq);

				return this.fetchWithRoute<IRocDocumentCreateResponse>(
					Rt800ApiRoutes.RocDocument.Create,
					{ body, disableNotification: true }
				);
			});
	}

	/**
	 * Create a Toll-Free RespOrg Change (ROC) Request
	 */
	public async createROC(
		respOrgId: string,
		tfns: string[],
		effectiveTs: Date,
		notes: string,
		loaFile?: File
	) {
		const req: RocCreateRequest = { respOrgId, tfns, effectiveTs, notes };

		if (loaFile) {
			const { name } = loaFile;
			const fileName = name.replace(
				RocRequestResource.NOT_DIGITS_AND_ASCII_CHARS_REGEXP,
				''
			);
			const encodedContent =
				await this.fileUtils.getBase64EncodedContentFromBlob(loaFile);

			req.letterOfAgency = {
				fileName,
				encodedContent
			};
		}

		const body = JSON.stringify(req);

		return this.fetchWithRoute<IRocCreateResponse>(Rt800ApiRoutes.Roc.Create, {
			body,
			disableNotification: true
		});
	}

	public cancelROC(rocTxnId: string) {
		const urlParams: RocProfileRequest = { rocTxnId };

		return this.fetchWithRoute<void>(Rt800ApiRoutes.Roc.Delete, {
			urlParams
		});
	}

	/**
	 * Process a Toll-Free RespOrg Change (ROC) Action
	 */
	public createAction(
		rocTxnId: string,
		tfns: string[],
		rocActionId: string,
		rejectNote?: string
	) {
		const req: IRocActionRequest = {
			rocTxnId,
			tfns,
			rocActionId,
			rejectNote
		};
		const body = JSON.stringify(req);
		const urlParams: RocProfileRequest = { rocTxnId };

		return this.fetchWithRoute<IRocActionResponse>(
			Rt800ApiRoutes.RocAction.Update,
			{ body, urlParams }
		);
	}

	/**
	 * Remove numbers from ROC
	 */
	public removeNumbers(rocTxnId: string, tfns: string[]) {
		const req: IRocRemoveDialNumbersRequest = { rocTxnId, tfns };
		const body = JSON.stringify(req);
		const urlParams: RocProfileRequest = { rocTxnId };

		return this.fetchWithRoute<IRocActionResponse>(
			Rt800ApiRoutes.RocAction.Delete,
			{ body, urlParams }
		);
	}

	private downloadLetterOfAgency(loaId: number) {
		const params: IRocGetLoaRequest = { loaId };

		return this.fetchWithRoute<IRocGetLoaResponse>(
			Rt800ApiRoutes.RocLoa.Profile,
			{ params }
		).then((res) => {
			this.fileUtils.downloadBase64EncodedContent(
				res.encodedContent,
				res.fileName,
				res.mimeType
			);
		});
	}
}
