import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { IntegrationTaskTypeIndexResponse } from 'RtModels';
import { IntegrationsApiRoutes } from 'RtExports/routes';

export class IntegrationTaskTypeResource extends ArrayResource<IntegrationTaskTypeIndexResponse> {
	constructor() {
		super('integrationTaskTypeId');

		this.setApiRouteForGetAll(IntegrationsApiRoutes.IntegrationTaskTypes.Index);
	}
}
