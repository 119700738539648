//import * as React from 'react';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { CdrExportServiceResource } from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportServiceResource';
import { CdrExportServiceIndexResponse } from 'RtModels';
import { isEqual } from 'lodash-es';

interface ICdrExportServiceSelectProps
	extends ISelectFormControlProps<CdrExportServiceIndexResponse> {
	serviceId?: number;
}

export class CdrExportServiceSelect extends SelectFormControl<
	CdrExportServiceIndexResponse,
	false,
	ICdrExportServiceSelectProps
> {
	public resourceClass = CdrExportServiceResource;
	public state: ISelectFormControlState<CdrExportServiceIndexResponse> = {
		formLabel: 'Export Type',
		valueKey: 'cdrExportServiceId',
		labelKey: 'cdrExportTypeName'
	};

	public componentDidMount() {
		super.componentDidMount();

		this.updateGetAllParams();
	}

	public componentDidUpdate(prevProps: ICdrExportServiceSelectProps) {
		super.componentDidUpdate(prevProps);

		if (!isEqual(prevProps, this.props)) {
			this.updateGetAllParams();
		}
	}

	public updateGetAllParams() {
		const getAllParams: any = {};
		const { serviceId } = this.props;

		if (typeof serviceId === 'number') {
			getAllParams.serviceId = serviceId;
		}

		this.setGetAllParams(getAllParams);
	}
}
