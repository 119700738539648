import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

export class UsageAuditByDayRouterClass extends RtVueReportRouter<
	void,
	void,
	{}
> {
	constructor() {
		super('Recurring Usage Audit', 'usageAuditByDay', undefined, {});
		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewReconciliationSummary);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const UsageAuditByDayRouter = new UsageAuditByDayRouterClass();
