/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CallProcessingResult {
    Q931_UNALLOCATED_NUMBER = 1,
    Q931_NORMAL_CLEARING = 16,
    Q931_USER_BUSY = 17,
    Q931_NO_USER_RESPONDING = 18,
    Q931_T301_EXPIRED = 19,
    Q931_INVALID_NUMBER_FORMAT = 28,
    Q931_NO_CIRCUIT_AVAILABLE = 34,
    Q931_NETWORK_OUT_OF_ORDER = 38,
    Q931_BEARER_CAPABILITY_NOT_PRESENTLY_AVAILABLE = 58,
    Q931_PROTOCOL_ERROR = 111,
    Q931_INTERNETWORKING = 127,
    MEDIATION_INVALID_GATEWAY = 1005,
    MEDIATION_UNKNOWN_GATEWAY = 1006,
    MEDIATION_INVALID_PARTITION = 1010,
    MEDIATION_UNKNOWN_PARTITION = 1011,
    MEDIATION_INVALID_PEER = 1020,
    MEDIATION_UNKNOWN_PEER = 1021,
    MEDIATION_PEER_NOT_REGISTERED = 1022,
    MEDIATION_INVALID_ROUTE = 1030,
    MEDIATION_UNKNOWN_ROUTE = 1031,
    MEDIATION_INVALID_SUBSCRIPTION = 1040,
    MEDIATION_UNKNOWN_SUBSCRIPTION = 1041,
    MEDIATION_CARRIER_CREDIT_LIMIT = 1042,
    MEDIATION_INVALID_CARRIER = 1043,
    MEDIATION_UNKNOWN_CARRIER = 1044,
    MEDIATION_INVALID_RATE_KEY = 1050,
    MEDIATION_INVALID_RATE_CENTER = 1051,
    MEDIATION_INVALID_REQUEST_URI = 1060,
    MEDIATION_UNSPECIFIED = 1099,
    AGI_ABORTED = 1101,
    AGI_CALLER_CANCELLED = 1102,
    AGI_NO_ANSWER = 1103,
    AGI_SOCKET_TIMEOUT = 1190,
    AGI_UNKNOWN = 1199,
    CALLFLOW_PORT_LIMIT = 1202,
    CALLFLOW_PORT_LIMIT_INGRESS_PARTITION = 1203,
    CALLFLOW_PORT_LIMIT_INGRESS_CARRIER = 1204,
    CALLFLOW_PORT_LIMIT_INGRESS_SUBSCRIPTION = 1205,
    CALLFLOW_PORT_LIMIT_INGRESS_COUNTRY = 1206,
    CALLFLOW_INITIALIZATION_FAILURE = 1207,
    CALLFLOW_LOOP_PREVENTION_INBOUND = 1210,
    CALLFLOW_LOOP_PREVENTION_OUTBOUND = 1211,
    CALLFLOW_DUPLICATE_PREVENTION_OUTBOUND = 1212,
    CALLFLOW_PORT_LIMIT_EGRESS_PARTITION = 1213,
    CALLFLOW_PORT_LIMIT_EGRESS_CARRIER = 1214,
    CALLFLOW_PORT_LIMIT_EGRESS_SUBSCRIPTION = 1215,
    CALLFLOW_PORT_LIMIT_EGRESS_COUNTRY = 1216,
    CALLFLOW_ROUTE_ADVANCE_LIMIT = 1220,
    CALLFLOW_ROUTE_ADVANCE_TIMEOUT = 1225,
    CALLFLOW_CUSTOMER_RATE_CENTER_MISSING = 1230,
    CALLFLOW_RATE_CENTER_MISSING = 1240,
    CALLFLOW_DIRECTCONNECT_INACTIVE = 1241,
    CALLFLOW_DIRECTCONNECT_MISSING = 1260,
    CALLFLOW_CUSTOMER_RATE_MISSING = 1270,
    CALLFLOW_INVALID_SUBSCRIPTION = 1271,
    CALLFLOW_UNKNOWN_SUBSCRIPTION = 1280,
    CALLFLOW_NO_SCENARIO = 1281,
    CALLFLOW_NO_CURRENT_ROUTE = 1360,
    CALLFLOW_VENDOR_RATE_MISSING = 1399,
    CALLFLOW_UNSPECIFIED = 1400,
    DATABASE_CONNECTION_ERROR = 1401,
    DATABASE_QUERY_ERROR = 3100,
    ROUTER_EXECUTION = 3110,
    ROUTER_INITIALIZATION = 3120,
    ROUTER_RATE_CENTER_FEATURE_MISSING = 3130,
    ROUTER_RATE_CENTER_QUALITY_MISSING = 3140,
    ROUTER_ROUTE_LIST_EMPTY = 3145,
    ROUTER_ROUTE_LIST_EMPTY_LCR = 3150,
    ROUTER_ROUTE_LIST_EMPTY_MARGIN_PROTECTION = 3155,
    ROUTER_ROUTE_LIST_EMPTY_RATE_CENTER_MATCHING = 3160,
    ROUTER_ROUTE_LIST_EMPTY_ROUTING_RULES = 3170,
    ROUTER_RULE_BLOCK = 3175,
    ROUTER_RULE_DELETE = 3180,
    ROUTER_RULE_NO_APPEND = 3200,
    ROUTER_CALLING_PARTY_IDDD_REQUIRED = 3210,
    ROUTER_CALLING_PARTY_MATCHES_DESTINATION = 3220,
    ROUTER_CALLING_PARTY_REQUIRES_COUNTRY_CODE = 3230,
    ROUTER_CALLING_PARTY_INVALID = 3240,
    ROUTER_DESTINATION_REQUIRES_COUNTRY_CODE = 3250,
    ROUTER_DESTINATION_INVALID = 3260,
    ROUTER_LNP_CACHE_CONNECTION_ERROR = 3262,
    ROUTER_LNP_PROVIDER_CONNECTION_ERROR = 3264,
    ROUTER_LNP_PROVIDER_ERROR = 3265,
    ROUTER_LNP_PROVIDER_CALLID_MISMATCH = 3266,
    ROUTER_LNP_PROVIDER_TIMEOUT = 3269,
    ROUTER_LNP_ERROR = 3300,
    ROUTER_CUSTOMER_MARGIN_PLAN_INVALID = 3310,
    ROUTER_CUSTOMER_MARGIN_PLAN_MISSING = 3320,
    ROUTER_CUSTOMER_RATE_CENTER_BLOCKED = 3330,
    ROUTER_CUSTOMER_RATE_CENTER_MISSING = 3350,
    ROUTER_CUSTOMER_RATE_INVALID = 3360,
    ROUTER_CUSTOMER_RATE_MISSING = 3365,
    ROUTER_CUSTOMER_CREDIT_LIMIT = 3366,
    ROUTER_CUSTOMER_COMBINED_LIMIT = 3400,
    ROUTER_VENDOR_MARGIN_PLAN_INVALID = 3410,
    ROUTER_VENDOR_MARGIN_PLAN_MISSING = 3415,
    ROUTER_VENDOR_MARGIN_PROTECT = 3420,
    ROUTER_VENDOR_RATE_CENTER_BLOCKED = 3430,
    ROUTER_VENDOR_RATE_CENTER_MISSING = 3450,
    ROUTER_VENDOR_RATE_INVALID = 3460,
    ROUTER_VENDOR_RATE_MISSING = 3465,
    ROUTER_VENDOR_CREDIT_LIMIT = 3470,
    ROUTER_VENDOR_ROUTE_LIMIT = 3480,
    ROUTER_VENDOR_RATE_CENTER_NOT_ROUTABLE = 3485,
    ROUTER_VENDOR_RATE_CENTER_NOT_LCR_ENABLED = 3500,
    ROUTER_BLOCK_CUSTOMER = 3510,
    ROUTER_BLOCK_VENDOR = 3520,
    ROUTER_BLOCK_CALLING_PARTY = 3521,
    ROUTER_BLOCK_CALLING_PARTY_LOCATION = 3522,
    ROUTER_TIME_OF_DAY_BLOCK = 3523,
    ROUTER_TIME_OF_DAY_BLOCK_BUSY = 3530,
}