import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import { ConnectionIndexResponse, ConnectionProfileResponse } from 'RtModels';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RtVueApiRoutes } from 'RtExports/routes';

interface IConnectionRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Rating: TabbedLayoutTabProps;
	RatePlanManagement: TabbedLayoutTabProps;
}

export const ConnectionContainerTabs: IConnectionRouterTabs = {
	Profile: {
		header: 'Profile'
	},
	Rating: {
		header: 'Settings'
	},
	RatePlanManagement: {
		header: 'Rate Plan Management'
	}
};

export class ConnectionRouterClass extends RtVueModuleRouter<
	ConnectionIndexResponse,
	ConnectionProfileResponse,
	IConnectionRouterTabs
> {
	constructor() {
		super(
			'Connections',
			'connections',
			'connectionId',
			ConnectionContainerTabs
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.Connections);
	}

	public getProfileLabel(profile: ConnectionProfileResponse): string {
		return String(profile.connectionId);
	}
}

export const ConnectionRouter = new ConnectionRouterClass();
