/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum DashboardItemStatus {
    Good = 1,
    Warning = 2,
    Error = 3,
}