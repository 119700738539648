import { IntegrationsApiRoutes } from 'RtExports/routes';
import {
	IResourceProfileContainerTabs,
	ResourceProfileContainerTabs
} from 'RtUi/app/rtCommon/Resources/lib/components/ResourceProfileContainer';
import {
	IDidResourceIndex,
	IDidResourceProfile
} from 'RtUi/app/rtDid/Resources/lib/resources/DidResourceResource';
import { DidApplicationRouter } from 'RtUi/app/rtDid/rtDid.router';

export class DidResourceRouterClass extends DidApplicationRouter<
	IDidResourceIndex,
	IDidResourceProfile,
	IResourceProfileContainerTabs
> {
	constructor() {
		super(
			'Resource',
			'resource',
			'resourceId',
			ResourceProfileContainerTabs(true)
		);

		this.setPermissionsFromApiRoute(IntegrationsApiRoutes.Resources);
	}

	public getIndexLabel(record: IDidResourceIndex) {
		return record.label ?? String(record.resourceId);
	}

	public getProfileLabel(profile: IDidResourceProfile) {
		return profile.label ?? String(profile.resourceId);
	}
}

export const DidResourceRouter = new DidResourceRouterClass();
