import {
	GuardianReconciliationIndexRequest,
	GuardianReconciliationIndexResponse
} from 'RtModels';
import { GuardianReconciliationsRouter } from 'RtUi/app/rtVue/GuardianReconciliation/GuardianReconciliation.router';
import { useMemo } from 'react';
import { useGetGuardianReconciliations } from 'RtUi/app/rtVue/GuardianReconciliation/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getGuardianReconciliationColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/guardianReconciliation';

interface IGuardianReconciliationGridProps {
	onRowClick?: (row: GuardianReconciliationIndexResponse) => void;
	resourceParams?: GuardianReconciliationIndexRequest;
	pageName: string;
}

export const GuardianReconciliationGrid = ({
	resourceParams,
	onRowClick,
	pageName
}: IGuardianReconciliationGridProps) => {
	const { data, isLoading } = useGetGuardianReconciliations(resourceParams);

	const columns = useMemo(() => getGuardianReconciliationColumns(), []);

	return (
		<DataGrid<GuardianReconciliationIndexResponse>
			data={data?.data}
			loading={isLoading}
			pageName={pageName}
			router={GuardianReconciliationsRouter}
			onRowClick={onRowClick}
			columns={columns}
			totalRows={data?.totalCount}
		/>
	);
};
