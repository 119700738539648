/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum IntegrationRequestObjectType {
    Request = '1',
    Response = '2',
    CompletionRequest = '3',
    CompletionResponse = '4',
}