import { isEqual } from 'lodash-es';
import * as moment from 'moment-timezone';
import * as React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { RtError } from 'RtUi/utils/errors/RtError';
import {
	IBulkActionFromValues,
	IMgiTaskLabelType,
	IMgiTaskOption,
	ITasksFormState
} from '../interfaces';
import { MgiTaskOptionsHelper } from '../MgiTaskOptions';

interface ITaskActionProps {
	type: IMgiTaskLabelType;
	hideDescriptionHeader?: boolean;
	defaultTollFreeNumbers?: string[];
	defaultRespOrgId?: string;
	singleNumberMode?: boolean;
	defaultNumberGroupId?: number;
	disablePadding?: boolean;
	disablePathToTaskOnSuccess?: boolean;
	onCancel?: () => void;
	onSubmit?: (taskDefer: Promise<ITaskProfile>) => void;
	onSuccess?: (task: ITaskProfile) => void;
}

interface ITaskActionState extends ITasksFormState {
	option: IMgiTaskOption | null;
}

export class TaskAction extends React.Component<
	ITaskActionProps,
	ITaskActionState
> {
	public state: ITaskActionState = {
		activeTab: '',
		option: null,
		scheduledAtMoment: moment(),
		effectiveTimeMoment: moment(),
		scheduledAtIsNow: true,
		effectiveTimeIsNow: true,
		quantityStr: '10',
		tollFreeNumbers: [],
		updateForm: this.updateForm.bind(this),
		getFormValues: this.getFormValues.bind(this),
		getErrorUI: this.getErrorUI.bind(this),
		pattern: '*******' //must be filled in
	};

	public componentDidMount() {
		this.updateOption();
		this.getDefaults();
	}

	public getDefaults() {
		const {
			defaultTollFreeNumbers: tollFreeNumbers = [],
			onCancel,
			onSuccess,
			onSubmit,
			disablePadding,
			defaultRespOrgId,
			defaultNumberGroupId,
			singleNumberMode,
			disablePathToTaskOnSuccess
		} = this.props;

		this.setState({
			tollFreeNumbers,
			defaultRespOrgId,
			defaultNumberGroupId,
			singleNumberMode,
			disablePadding,
			onCancel,
			onSuccess,
			onSubmit,
			disablePathToTaskOnSuccess
		});
	}

	public componentDidUpdate(prevProps: ITaskActionProps) {
		if (prevProps.type !== this.props.type) {
			this.updateOption();
		}
	}

	public updateOption() {
		const { type } = this.props;
		const taskHelper = MgiTaskOptionsHelper.getInstance();
		const option = taskHelper.getTaskFromLabel(type);

		if (option && !isEqual(this.state.option, option)) {
			this.setState({ option });
		}
	}

	public updateForm<K extends keyof ITasksFormState>(
		updateState: ITasksFormState | Pick<ITasksFormState, K>
	) {
		//Remove TFNs when numberGroup is selected
		if ('numberGroup' in updateState && updateState.numberGroup) {
			updateState.tollFreeNumbers = [];
		}

		const updateSubState = updateState as ITasksFormState;

		this.setState(updateSubState);
	}

	public getFormValues(): IBulkActionFromValues {
		const {
			respOrg,
			template,
			scheduledAtMoment,
			effectiveTimeMoment,
			effectiveTimeIsNow,
			newRespOrg,
			selectedAreaCodes,
			numberGroup,
			taskLabel
		} = this.state;
		const { selectedPriority = 0, pattern = '', quantityStr } = this.state;

		const respOrgId = respOrg ? respOrg.respOrgId : '';
		const scheduledAt = scheduledAtMoment
			? moment(scheduledAtMoment).toDate()
			: new Date(); //this.props.scheduledAt;
		const tfns = this.state.tollFreeNumbers;
		const priority = selectedPriority;
		const serviceOrderNumber = 'RT01';
		const lineCount = 9999;
		const templateName = template ? template.templateName : '';
		const newRespOrgId = newRespOrg ? newRespOrg.respOrgId : '';
		const areaCodes = selectedAreaCodes ? selectedAreaCodes : [];
		const numberGroupId = numberGroup ? numberGroup.numberGroupId : undefined;
		const quantity = parseInt(quantityStr, 10);
		const consecutive = false; //TODO
		const formValues: IBulkActionFromValues = {
			respOrgId,
			scheduledAt,
			tfns,
			priority,
			serviceOrderNumber,
			lineCount,
			templateName,
			newRespOrgId,
			areaCodes,
			pattern,
			quantity,
			consecutive,
			taskLabel
		};

		if (effectiveTimeIsNow) {
			//Remove from payload if effectiveTs is now
			delete formValues.effectiveTs;
		} else {
			formValues.effectiveTs = effectiveTimeMoment
				? moment(effectiveTimeMoment).toDate()
				: new Date(); //this.props.scheduledAt;
		}

		if (numberGroupId) {
			formValues.numberGroupId = numberGroupId;
		}

		return formValues;
	}

	public getErrorUI() {
		const { exceptions: exceptionObject } = this.state;
		let exceptions: string[] = [];

		if (exceptionObject instanceof RtError) {
			exceptions = exceptionObject.messages;
		} else if (Array.isArray(exceptionObject)) {
			exceptions = exceptionObject;
		}

		if (exceptions.length <= 0) {
			return <></>;
		}

		return (
			<Alert variant="danger">
				<h6 className="d-flex justify-content-start align-items-center">
					<i className="fas fa-fw fa-exclamation-triangle me-2" />
					Some errors have occurred. Fix the following and submit again:
				</h6>
				{exceptions && (
					<ol className="mb-0">
						{exceptions.map((exceptionMessage, index) => (
							<li key={index}>{exceptionMessage}</li>
						))}
					</ol>
				)}
			</Alert>
		);
	}

	public render() {
		const { option } = this.state;
		const { hideDescriptionHeader = false } = this.props;

		if (option === null) {
			return null;
		}

		const TaskComponent = option.component();

		return (
			<section>
				{option.description && (
					<Alert variant="warning">
						{!hideDescriptionHeader && (
							<header className="mb-2">
								<i className="fas fa-info-circle" />
								<b>&nbsp;{option.label}</b>
							</header>
						)}
						<section>{option.description}</section>
					</Alert>
				)}
				<Row>
					<Col md={6}>
						<TaskComponent {...this.state} />
					</Col>
				</Row>
			</section>
		);
	}
}
