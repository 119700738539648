/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { IFirebaseUIMessage } from 'RtUi/state/actions/user/BaseFireBase';
import { FirebaseUser } from 'RtUi/state/actions/user/FirebaseUser';

const fetchFirebaseValue = async () => {
	let data: Array<IFirebaseUIMessage<any>> = [];
	const firebaseUser = FirebaseUser.getInstance();

	await firebaseUser.onMessages(
		(messages) => {
			data = messages;
		},
		[],
		false
	);

	return data;
};

const fetchSystemNotifications = async (): Promise<
	Array<IFirebaseUIMessage<any>>
> => {
	return await fetchFirebaseValue();
};

export const useGetSystemNotifications = () => {
	return useQuery<Array<IFirebaseUIMessage<any>>, Error>(
		'getSystemNotifications',
		() => {
			return fetchSystemNotifications();
		}
	);
};
