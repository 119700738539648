import { ManagedStatus } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { Alert } from 'react-bootstrap';

interface ColorPercentageDataGridColumnProps<T>
	extends DataGridColumnConfiguration<T> {
	decimals?: number;
}

export const getPercentageColor = (value: number) => {
	if (value < 3) {
		return 'black';
	} else if (value > 3 && value < 7) {
		return '#DBA800';
	} else if (value > 7 && value < 12) {
		return '#ff7415';
	}
	return '#ff1616';
};

export const ColorPercentageDataGridColumn = <T = any,>({
	size = 100,
	decimals = 2,
	...config
}: ColorPercentageDataGridColumnProps<T>): DataGridColumn<T> => {
	const renderValue = (columnValue: ManagedStatus) => {
		const color = getPercentageColor(Number(columnValue));

		return (
			<>
				<section className="d-flex justify-content-center">
					<span style={{ color }}>
						{Number(columnValue).toFixed(decimals)} %
					</span>
				</section>
			</>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		getValue: ({ cell }) => renderValue(cell.getValue<ManagedStatus>())
	});
};
