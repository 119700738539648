import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	SwitchCreateRequest,
	SwitchDeleteResponse,
	SwitchIndexResponse,
	SwitchProfileResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class SwitchesResource extends HttpResource<
	SwitchIndexResponse,
	SwitchProfileResponse
> {
	constructor() {
		super(RtCommonApiRoutes.Switches);
	}

	public getProfile(switchId: number): Promise<SwitchProfileResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.Switches.Profile, {
			urlParams: {
				switchId
			}
		});
	}

	public create(req: SwitchCreateRequest): Promise<SwitchProfileResponse> {
		const body = JSON.stringify(req);

		return this.fetchWithRoute(RtCommonApiRoutes.Switches.Create, {
			body
		});
	}

	public update(
		switchData: SwitchProfileResponse
	): Promise<SwitchProfileResponse> {
		const { switchId, ...req } = switchData;
		const urlParams = {
			switchId
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute(RtCommonApiRoutes.Switches.Update, {
			urlParams,
			body
		});
	}

	public delete(switchId: number): Promise<SwitchDeleteResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.Switches.Delete, {
			urlParams: {
				switchId
			}
		});
	}
}
