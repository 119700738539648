import * as React from 'react';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { RoboscoreParameterIndexResponse } from 'RtModels';
import { Row, Col } from 'react-bootstrap';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { timestampToReadable } from 'RtUi/utils/maps';
import { RoboscoringResource } from 'RtUi/app/rtVue/Roboscoring/lib/resources/RoboscoringResource';
import { cloneDeep } from 'lodash-es';

interface IRoboscoringEditorProps {
	editMode: RoboscoreParameterIndexResponse;
}

interface IRoboscoringEditorState {
	isSubmitting: boolean;
	displayMode: boolean;
	roboscore: RoboscoreParameterIndexResponse;
	error?: any;
}

export class RoboscoringEditor extends React.Component<
	IRoboscoringEditorProps,
	IRoboscoringEditorState
> {
	public roboscoreResource = new RoboscoringResource();

	constructor(props: IRoboscoringEditorProps) {
		super(props);

		this.state = {
			displayMode: true,
			isSubmitting: false,
			roboscore: cloneDeep(props.editMode)
		};
	}

	public onChange = <K extends keyof RoboscoreParameterIndexResponse>(
		key: K,
		value: RoboscoreParameterIndexResponse[K]
	) => {
		const roboscore = cloneDeep(this.state.roboscore);
		roboscore[key] = value;
		this.setState({ roboscore });
	};

	public onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });
		const { roboscore } = this.state;

		try {
			const updatedRoboscore = await this.roboscoreResource.update(
				roboscore.paramId,
				{
					parameterInputValue: roboscore.parameterInputValue,
					parameterOutputValue: roboscore.parameterOutputValue
				}
			);
			this.setState({
				isSubmitting: false,
				displayMode: true,
				roboscore: updatedRoboscore
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	};

	public onCancel = () => {
		const roboscore = cloneDeep(this.props.editMode);

		if (roboscore) {
			this.setState({
				roboscore,
				displayMode: true,
				error: undefined
			});
		}
	};

	public render() {
		const { roboscore } = this.state;
		return (
			<RtUiWideForm
				createMode={false}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onCancel={this.onCancel}
				onChange={(displayMode) => this.setState({ displayMode })}
				onSubmit={this.onSubmit}
			>
				<Row>
					<Col md={6}>
						<InputFormControl
							label="Param Id"
							readOnly={true}
							displayMode={true}
							value={String(roboscore.paramId)}
						/>
						<InputFormControl
							label="Parameter Name"
							readOnly={true}
							displayMode={true}
							value={roboscore.parameterName}
						/>
						<InputFormControl
							label="Parameter Summary"
							readOnly={true}
							displayMode={true}
							value={roboscore.parameterSummary}
						/>
						<InputFormControl
							label="Updated By"
							readOnly={true}
							displayMode={true}
							value={roboscore.emailAddress}
						/>
					</Col>
					<Col md={6}>
						<InputFormControl
							label="Created At"
							readOnly={true}
							displayMode={true}
							value={timestampToReadable(roboscore.createdTs)}
						/>
						<InputFormControl
							label="Updated At"
							readOnly={true}
							displayMode={true}
							value={timestampToReadable(roboscore.updatedTs)}
						/>
						<InputFormControl
							type="number"
							label="Parameter Input Value"
							displayMode={this.state.displayMode}
							value={String(roboscore.parameterInputValue)}
							onChange={(value) =>
								this.onChange('parameterInputValue', Number(value))
							}
						/>
						<InputFormControl
							type="number"
							min={0}
							max={100}
							label="Parameter Input Value"
							displayMode={this.state.displayMode}
							value={String(roboscore.parameterOutputValue)}
							onChange={(value) =>
								this.onChange('parameterOutputValue', Number(value))
							}
						/>
					</Col>
				</Row>
			</RtUiWideForm>
		);
	}
}
