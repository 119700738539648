import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import {
	TemplateLcrCarrierAvailableIndexResponse,
	TemplateLcrCarrierAvailableIndexRequest
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';

export class TemplateLcrCarrierAvailable extends ArrayResource<TemplateLcrCarrierAvailableIndexResponse> {
	constructor(templateName: string) {
		super('lcrCarrierId');

		const urlParams: TemplateLcrCarrierAvailableIndexRequest = {
			templateName
		};
		this.setApiRouteForGetAll(
			Rt800ApiRoutes.TemplateLcrCarriersAvailable.Index,
			{ urlParams }
		);
	}
}
