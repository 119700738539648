import {
	LcrMinuteIndexResponse,
	MinuteProfile,
	LcrMinuteCreateRequest,
	LcrMinuteUpdateRequest,
	LcrMinuteProfileRequest,
	LcrMinuteIndexRequest
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export type LcrMinuteRecordType = {
	aniNpaNxx: string;
	interConnects: number;
	interMinutes: number;
	interCharges: number;
	intraConnects: number;
	intraMinutes: number;
	intraCharges: number;
	indetConnects: number;
	indetMinutes: number;
	indetCharges: number;
	totalConnects: number;
	totalMinutes: number;
	totalCharges: number;
	lrnNpaNxx: string;
};

export class LcrMinutesResource extends HttpResource<
	MinuteProfile,
	MinuteProfile,
	LcrMinuteIndexRequest
> {
	public static IsMismatchOrZeroMinutes(minuteProfile: MinuteProfile) {
		if (minuteProfile.totalMinutes === null) {
			return false;
		}

		if (minuteProfile.totalMinutes === 0) {
			return true;
		}

		const calculateTotalMins = [
			minuteProfile.interMinutes,
			minuteProfile.indetMinutes,
			minuteProfile.intraMinutes
		].reduce<number>((val, current) => val + (current ?? 0), 0);

		if (minuteProfile.totalMinutes !== calculateTotalMins) {
			return true;
		}

		return false;
	}

	constructor() {
		super(Rt800ApiRoutes.LcrMinutes);
	}

	public create(minutesCreateRequest: LcrMinuteCreateRequest) {
		return this.fetchWithRoute<LcrMinuteIndexResponse>(
			Rt800ApiRoutes.LcrMinutes.Create,
			{
				body: JSON.stringify(minutesCreateRequest)
			}
		);
	}

	public update(minuteProfileId: number, request: LcrMinuteUpdateRequest) {
		const urlParams: LcrMinuteProfileRequest = {
			minuteProfileId
		};

		return this.fetchWithRoute<LcrMinuteIndexResponse>(
			Rt800ApiRoutes.LcrMinutes.Update,
			{
				body: JSON.stringify(request),
				urlParams
			}
		);
	}
}
