import { ResourceConfigurationProfileResponse, ResourceType } from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { JobGrid } from 'RtUi/app/rtCarrierConnect/Jobs/lib/grids/JobGrid';
import { ResourceUsersList } from 'RtUi/app/rtCommon/Resources/lib/components/ResourceUsersList';
import { ResourceEditor } from 'RtUi/app/rtCommon/Resources/lib/forms/ResourceEditor';
import { MetricTotalsByDateDashboardTabs } from 'RtUi/app/rtDid/Dashboard/lib/components/MetricTotalsByDateDashboardTabs';
import { DidNumbersGrid } from 'RtUi/app/rtDid/Numbers/lib/grids/DidNumbersGrid';
import { useGetResourceConfiguration } from 'RtUi/app/rtDid/Resources/service';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export interface IResourceConfigurationProfileContainerTabs
	extends IProfileApplicationContainerTabs {}

export const ResourceConfigurationProfileContainerTabs = (
	isDid: boolean
): IResourceConfigurationProfileContainerTabs => ({
	...(isDid && {
		Dashboard: {
			header: 'Dashboard'
		}
	}),
	Profile: { header: 'Profile', isDefault: true },
	...(isDid && {
		Numbers: {
			header: 'Numbers'
		}
	}),
	Users: {
		header: 'Users'
	},
	...(!isDid && {
		Jobs: { header: 'Jobs' }
	})
});

interface IResourceConfigurationProfileContainerProps {
	isDid?: boolean;
	excludeResourceTypes: ResourceType[];
	router: RtUiRouter;
}

export const ResourceConfigurationProfileContainer = ({
	isDid = false,
	excludeResourceTypes,
	router
}: IResourceConfigurationProfileContainerProps) => {
	const entityId = useIdParam();
	const [tabs, activeTab, setActiveTab] = useTabs(
		ResourceConfigurationProfileContainerTabs(isDid)
	);

	const { data, isLoading } = useGetResourceConfiguration({
		resourceConfigurationId: Number(entityId)
	});

	if (isLoading) {
		return <Loading />;
	}

	if (!data) {
		return <p>Not found!</p>;
	}

	const returnJobTabResourceParams = () => {
		return { resourceConfigurationId: [Number(entityId)] };
	};

	return (
		<TabbedLayout<ResourceConfigurationProfileResponse>
			profile={data}
			router={router}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			{isDid && (
				<TabbedLayoutTab {...tabs.Dashboard}>
					<MetricTotalsByDateDashboardTabs
						className="mb-4"
						resourceId={data.resourceId}
					/>
				</TabbedLayoutTab>
			)}
			<TabbedLayoutTab {...tabs.Profile}>
				<ResourceEditor
					editMode={data}
					onCopy={(resource) => {
						BrowserHistory.push(router.getProfileRoute(resource.resourceId));
					}}
					excludeResourceTypes={excludeResourceTypes}
				/>
			</TabbedLayoutTab>
			{isDid && (
				<TabbedLayoutTab {...tabs.Numbers}>
					<DidNumbersGrid
						key={entityId}
						resourceParams={{ resourceId: Number(entityId) }}
					/>
				</TabbedLayoutTab>
			)}
			<TabbedLayoutTab {...tabs.Users}>
				<ResourceUsersList resourceId={Number(entityId)} />
			</TabbedLayoutTab>
			{!isDid && (
				<TabbedLayoutTab {...tabs.Jobs}>
					<JobGrid resourceParams={returnJobTabResourceParams()} />
				</TabbedLayoutTab>
			)}
		</TabbedLayout>
	);
};
