import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RouteDepthIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IRouteDepthRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const RouteDepthContainerTabs: IRouteDepthRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class RouteDepthRouterClass extends SipApplicationRouter<
	RouteDepthIndexResponse,
	RouteDepthIndexResponse,
	IRouteDepthRouterTabs
> {
	constructor() {
		super(
			'Route Depth',
			'routeDepth',
			'subscriptionRateKey',
			RouteDepthContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.RouteDepth);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const RouteDepthRouter = new RouteDepthRouterClass();
