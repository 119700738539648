import { CdrExportIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { CdrExportIntervalDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CdrExportIntervalDataGridColumn';
import { CdrExportRetentionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CdrExportRetentionDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DownloadCdrExportDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DownloadCdrExportDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { capitalize } from 'lodash-es';

export const getCdrExportsColumns = (
	isDownloading: (cdrExportId: number) => boolean,
	onDownload: (cdrExportId: number) => void
): Array<DataGridColumn<CdrExportIndexResponse>> => [
	DownloadCdrExportDataGridColumn({
		header: 'Download',
		isDownloading,
		onDownload
	}),
	DefaultDataGridColumn({
		header: 'Type Name',
		accessorFn: (row) => capitalize(row.sourceTypeName as string)
	}),
	IntegerDataGridColumn({
		header: 'Qty',
		accessorKey: 'qty'
	}),
	CdrExportIntervalDataGridColumn({
		header: 'Interval',
		accessorKey: 'cdrExportIntervalId'
	}),
	DefaultDataGridColumn({
		header: 'Time-Zone',
		accessorKey: 'timezone'
	}),
	TimeStampDataGridColumn({
		header: 'Start',
		accessorKey: 'begTs',
		timezone: 'UTC'
	}),
	TimeStampDataGridColumn({
		header: 'End',
		accessorKey: 'endTs',
		timezone: 'UTC'
	}),
	DefaultDataGridColumn({
		header: 'Export Type Name',
		accessorKey: 'exportTypeName'
	}),
	CdrExportRetentionDataGridColumn({
		header: 'Retention',
		accessorKey: 'cdrExportRetentionId'
	}),
	DefaultDataGridColumn({
		header: 'Service',
		accessorKey: 'serviceName'
	}),
	DefaultDataGridColumn({
		header: 'CDR Source Value',
		accessorKey: 'cdrSourceValue'
	}),
	DefaultDataGridColumn({
		header: 'Reference Id',
		accessorKey: 'referenceId'
	}),
	BooleanDataGridColumn({
		header: 'Connected',
		accessorKey: 'isConnected'
	}),
	DefaultDataGridColumn({
		header: 'File Name',
		accessorKey: 'fileName'
	}),
	TimeStampDataGridColumn({
		header: 'Created',
		accessorKey: 'createdTs'
	})
];
