import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { FlowIndexResponse, FlowProfileResponse } from 'RtModels';
import { FlowRouter } from 'RtUi/app/rtAdmin/Flows/Flow.router';
import { FlowEditor } from 'RtUi/app/rtAdmin/Flows/lib/forms/FlowEditor';
import { FlowGrid } from 'RtUi/app/rtAdmin/Flows/lib/grids/FlowGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

import { FlowSelect } from 'RtUi/app/rtAdmin/Flows/lib/controls/FlowSelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';

export const FlowIndexContainer: FC = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		'Results': { header: 'Results', isDefault: true },
		'Search': { header: 'Search' },
		'Create Flow': { header: 'Create Server Group', isAction: true }
	});

	const [flow, setFlow] = useState<FlowIndexResponse>();
	const [search, setSearch] = useState<string>();

	const navigate = useNavigate();

	const goToFlowProfile = (flowProfile: FlowProfileResponse) => {
		const flowProfileRoute = FlowRouter.getProfileRoute(flowProfile);

		navigate(flowProfileRoute);
	};

	const searchFlows = async (event: React.FormEvent<HTMLFormElement>) => {
		try {
			event.preventDefault();
			setActiveTab('Results');
		} catch (e) {}
	};

	return (
		<TabbedLayout
			router={FlowRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm hideButtons onSubmit={(event) => searchFlows(event)}>
					<FlowSelect onChange={setFlow} value={flow} />
					<InputFormControl
						label="Search"
						type="text"
						onChange={setSearch}
						value={search}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<FlowGrid resourceParams={{ flowId: flow?.flowId, search }} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs['Create Flow']}>
				<FlowEditor
					createMode
					onCreateOrUpdate={(flowProfile) => goToFlowProfile(flowProfile)}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

FlowIndexContainer.displayName = 'FlowIndexContainer';

FlowRouter.setIndexRtUiFunctionalComponent(FlowIndexContainer);
