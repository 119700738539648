import { GuardianServiceNumberSubscriptionCreateRequestBulkItem } from 'RtModels';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getGuardianServiceNumberSubscriptionCreateColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/serviceNumberSubscriptions';
import { FC, useMemo } from 'react';

interface IGuardianServiceNumberCreateGridProps {
	data?: GuardianServiceNumberSubscriptionCreateRequestBulkItem[];
	autoFocusFilter?: boolean;
}

export const GuardianServiceNumberCreateGrid: FC<
	React.PropsWithChildren<IGuardianServiceNumberCreateGridProps>
> = ({ data = [] }) => {
	const columns = useMemo(
		() => getGuardianServiceNumberSubscriptionCreateColumns(),
		[]
	);

	return (
		<DataGrid<GuardianServiceNumberSubscriptionCreateRequestBulkItem>
			data={data}
			columns={columns}
			pageName={'rtVue_serviceNumberCreate'}
		/>
	);
};
