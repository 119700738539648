/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum MgiTaskTypes {
    SearchQuantity = 1,
    Search = 2,
    ReserveQuantity = 3,
    Reserve = 4,
    Query = 5,
    StatusChange = 6,
    RespOrgChange = 7,
    Activate = 8,
    Disconnect = 9,
    Spare = 10,
    ReRoute = 11,
    RoutingGroupActivate = 12,
    RoutingCopy = 13,
    TemplateCprCreate = 14,
    TemplateCprUpdate = 15,
    TemplateCprDelete = 16,
    TemplateCprTransfer = 17,
    TemplateCreate = 18,
    TollFreeCprCreate = 19,
    TollFreeCprUpdate = 20,
    TollFreeCprDelete = 21,
    TollFreeCprTransfer = 22,
    TemplateCprClone = 23,
    TollFreeCprClone = 24,
    LcrActivationTemplate = 25,
    LcrActivationTollFree = 26,
    TemplateCprDisconnect = 27,
    CprQuery = 28,
}