import { Col, Row, Card } from 'react-bootstrap';
import { GuardianReconciliationGrid } from 'RtUi/app/rtVue/GuardianReconciliation/lib/grids/GuardianReconciliationGrid';
import { DisputeByMonthChart } from 'RtUi/app/rtVue/GuardianReconciliationDashboard/lib/charts/DisputeByMonthChart';
import { TopDisputeItemsByPersonGrid } from 'RtUi/app/rtVue/GuardianReconciliationDashboard/lib/grids/TopDisputeItemsByPersonGrid';
import { GuardianReconciliationDisputeAmountResource } from 'RtUi/app/rtVue/GuardianReconciliationDashboard/lib/resources/GuardianReconciliationDisputeAmountResource';
import { GuardianReconciliationDisputeCountResource } from 'RtUi/app/rtVue/GuardianReconciliationDashboard/lib/resources/GuardianReconciliationDisputeCountResource';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { GuardianReconciliationDashboardRouter } from './GuardianReconciliationDashboard.router';

const disputeAmountResource = new GuardianReconciliationDisputeAmountResource();
const disputeCountResource = new GuardianReconciliationDisputeCountResource();

@GuardianReconciliationDashboardRouter.getReconciliationIndexRtUiController()
export class GuardianReconciliationDashboardIndexContainer extends ApplicationContainer<
	{},
	{}
> {
	public state = {};

	public render() {
		return (
			<StandardLayout router={GuardianReconciliationDashboardRouter}>
				<Row>
					<Col>
						<header>
							<h3>Dashboard</h3>
						</header>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col md={6}>
						<Card>
							<Card.Body>
								<DisputeByMonthChart resource={disputeCountResource} />
							</Card.Body>
						</Card>
					</Col>
					<Col md={6}>
						<Card>
							<Card.Body>
								<DisputeByMonthChart
									resource={disputeAmountResource}
									precision={2}
									formatAsCurrency
								/>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<h3>Top Open Disputes</h3>
						<GuardianReconciliationGrid
							pageName="rtVue-dashboard"
							resourceParams={{ topOpenDisputes: true }}
						/>
						<h3>Top Dispute Items by person</h3>
						<TopDisputeItemsByPersonGrid />
					</Col>
				</Row>
			</StandardLayout>
		);
	}
}
