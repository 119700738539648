import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { CdrExportIntervalIndexResponse } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';

export interface ICdrExportIntervalIndex
	extends CdrExportIntervalIndexResponse {}

export class CdrExportIntervalResource extends ArrayResource<ICdrExportIntervalIndex> {
	constructor() {
		super('intervalId');

		this.setApiRouteForGetAll(RtxSipApiRoutes.CdrExportIntervals.Index);
	}
}
