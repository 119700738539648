import { useMemo } from 'react';
import { SipAgentConfigIndexResponse } from 'RtModels';
import { useGetSipAgentConfigs } from 'RtUi/app/AccountManagement/SipAgents/lib/services';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface ISipAgentConfigsSelectProps {
	value?: SipAgentConfigIndexResponse;
	onChange?: (value?: SipAgentConfigIndexResponse) => void;
	label?: string;
	required?: boolean;
	isClearable?: boolean;
	initialOptionId?: number;
	displayMode?: boolean;
}
export const SipAgentConfigsSelect = ({
	value,
	onChange = () => {},
	label = 'SIP Agent Configs',
	required,
	isClearable = true,
	initialOptionId,
	displayMode = false
}: ISipAgentConfigsSelectProps): JSX.Element => {
	const { data, isLoading } = useGetSipAgentConfigs();

	const selectData = useMemo(() => data?.data || [], [data]);
	const options: Array<ISimpleSelectFormControlOption<number>> = useMemo(() => {
		return selectData.map((sipAgentConfigConfig) => ({
			value: sipAgentConfigConfig.sipAgentConfigId,
			label: sipAgentConfigConfig.label
		}));
	}, [selectData]);

	const selectValue = useMemo(() => {
		const currentValue = value?.sipAgentConfigId ?? initialOptionId;
		return options.find((opt) => opt.value === currentValue);
	}, [value, initialOptionId, options]);

	const onChangeHandler = (selectedValue: number | undefined) => {
		const sipAgentConfigConfig = selectData?.find(
			(st) => st.sipAgentConfigId === selectedValue
		);
		onChange(sipAgentConfigConfig);
	};

	return (
		<SimpleSelectFormControl<number>
			label={label}
			clearable={isClearable}
			isLoading={isLoading}
			required={required}
			value={selectValue}
			onChange={(val) => onChangeHandler(val?.value)}
			options={options}
			initialOptionId={initialOptionId?.toString()}
			displayMode={displayMode}
		/>
	);
};
