import { SipAgentStatisticIndexResponse } from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { LcrApplicationRouter } from 'RtUi/app/rtLcr/rtLcr.router';

export class SipAgentStatsRouterClass extends LcrApplicationRouter<
	SipAgentStatisticIndexResponse,
	void,
	any
> {
	constructor() {
		super('SIP Agent Stats', 'SipAgentStats', 'timeslot', {});

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.SipAgentStatistics);
	}

	public getIndexLabel(record: SipAgentStatisticIndexResponse) {
		return '';
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const SipAgentStatsRouter = new SipAgentStatsRouterClass();
