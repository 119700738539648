import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class ReconciliationRouterClass extends RtVueReportRouter<void, void, {}> {
	constructor() {
		super('Ad-Hoc Usage', 'reconciliation', undefined, {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewVendorReconciliation);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const ReconciliationRouter = new ReconciliationRouterClass();
