/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FullSummaryIndexResponse = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        in_cost_carrier_id: {
            type: 'number',
            isRequired: true,
        },
        in_cost_sub_id: {
            type: 'number',
            isRequired: true,
        },
        in_rev_carrier_id: {
            type: 'number',
            isRequired: true,
        },
        in_rev_sub_id: {
            type: 'number',
            isRequired: true,
        },
        eg_cost_carrier_id: {
            type: 'number',
            isRequired: true,
        },
        eg_cost_sub_id: {
            type: 'number',
            isRequired: true,
        },
        eg_rev_carrier_id: {
            type: 'number',
            isRequired: true,
        },
        eg_rev_sub_id: {
            type: 'number',
            isRequired: true,
        },
        in_fr_raw: {
            type: 'string',
            isRequired: true,
        },
        in_fr_rate_center_id: {
            type: 'number',
            isRequired: true,
        },
        in_to_raw: {
            type: 'string',
            isRequired: true,
        },
        in_to_rate_center_id: {
            type: 'number',
            isRequired: true,
        },
        in_ingress_connection_id: {
            type: 'number',
            isRequired: true,
        },
        in_egress_connection_id: {
            type: 'number',
            isRequired: true,
        },
        eg_fr_raw: {
            type: 'string',
            isRequired: true,
        },
        eg_fr_rate_center_id: {
            type: 'number',
            isRequired: true,
        },
        eg_to_raw: {
            type: 'string',
            isRequired: true,
        },
        eg_to_rate_center_id: {
            type: 'number',
            isRequired: true,
        },
        eg_ingress_connection_id: {
            type: 'number',
            isRequired: true,
        },
        eg_egress_connection_id: {
            type: 'number',
            isRequired: true,
        },
        in_atts: {
            type: 'number',
            isRequired: true,
        },
        in_cons: {
            type: 'number',
            isRequired: true,
        },
        eg_atts: {
            type: 'number',
            isRequired: true,
        },
        eg_cons: {
            type: 'number',
            isRequired: true,
        },
        in_revenue_duration: {
            type: 'number',
            isRequired: true,
        },
        in_revenue_total_charge: {
            type: 'number',
            isRequired: true,
        },
        in_cost_duration: {
            type: 'number',
            isRequired: true,
        },
        in_cost_total_charge: {
            type: 'number',
            isRequired: true,
        },
        eg_revenue_duration: {
            type: 'number',
            isRequired: true,
        },
        eg_revenue_total_charge: {
            type: 'number',
            isRequired: true,
        },
        eg_cost_duration: {
            type: 'number',
            isRequired: true,
        },
        eg_cost_total_charge: {
            type: 'number',
            isRequired: true,
        },
        in_cpUnq: {
            type: 'number',
            isRequired: true,
        },
        in_dnUnq: {
            type: 'number',
            isRequired: true,
        },
        eg_cpUnq: {
            type: 'number',
            isRequired: true,
        },
        eg_dnUnq: {
            type: 'number',
            isRequired: true,
        },
        in_raw_duration: {
            type: 'number',
            isRequired: true,
        },
        eg_raw_duration: {
            type: 'number',
            isRequired: true,
        },
        in_acd: {
            type: 'number',
            isRequired: true,
        },
        eg_acd: {
            type: 'number',
            isRequired: true,
        },
    },
};