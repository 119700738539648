import * as moment from 'moment';
import { DidInventorySpreadsheetParser } from 'RtUi/app/rtDid/Numbers/bin/DidInventorySpreadsheetParser';
import { IDidBillingInventoryRecord } from 'RtUi/app/rtDid/Numbers/lib/resources/DidNumbersResource';

export class DidBillingInventorySpreadsheetParser extends DidInventorySpreadsheetParser<IDidBillingInventoryRecord> {
	public internalGetInventoryRecords() {
		const records: IDidBillingInventoryRecord[] = [];
		const headerMatch = this.getBestHeaderMatch();

		if (!headerMatch) {
			return records;
		}

		const rawRecords = this.parse(headerMatch);
		const numberColumnIndex = this.getIndexFor(headerMatch, this.NumberColumn);
		const labelColumnIndex = this.getIndexFor(headerMatch, this.LabelColumn);
		const startColumnIndex = this.getIndexFor(headerMatch, this.StartColumn);
		const endColumnIndex = this.getIndexFor(headerMatch, this.EndColumn);
		const accountColumnIndex = this.getIndexFor(
			headerMatch,
			this.AccountColumn
		);
		const recurringColumnIndex = this.getIndexFor(
			headerMatch,
			this.RecurringColumn
		);
		const nonRecurringColumnIndex = this.getIndexFor(
			headerMatch,
			this.NonRecurringColumn
		);

		for (const rawRecord of rawRecords) {
			const record: IDidBillingInventoryRecord = {
				// eslint-disable-next-line id-blacklist
				number: rawRecord[numberColumnIndex]
			};

			if (rawRecord[labelColumnIndex]) {
				record.label = rawRecord[labelColumnIndex];
			}

			if (rawRecord[startColumnIndex]) {
				const startMoment = moment(
					rawRecord[startColumnIndex],
					moment.ISO_8601
				);

				if (startMoment.isValid()) {
					record.startTs = startMoment.toDate();
				}
			}

			if (rawRecord[endColumnIndex]) {
				const endMoment = moment(rawRecord[endColumnIndex], moment.ISO_8601);

				if (endMoment.isValid()) {
					record.endTs = endMoment.toDate();
				}
			}

			if (rawRecord[accountColumnIndex]) {
				record.accountNumber = rawRecord[accountColumnIndex];
			}

			const recurringAmountStr = rawRecord[recurringColumnIndex];
			const nonRecurringAmountStr = rawRecord[nonRecurringColumnIndex];
			const recurringAmount = recurringAmountStr
				? Number(recurringAmountStr)
				: NaN;
			const nonRecurringAmount = nonRecurringAmountStr
				? Number(nonRecurringAmountStr)
				: NaN;

			if (!isNaN(recurringAmount)) {
				record.recurringAmount = recurringAmount;
			}

			if (!isNaN(nonRecurringAmount)) {
				record.nonRecurringAmount = nonRecurringAmount;
			}

			records.push(record);
		}

		return records;
	}

	protected getParserColumns() {
		return [
			this.NumberColumn,
			this.LabelColumn,
			this.StartColumn,
			this.EndColumn,
			this.AccountColumn,
			this.RecurringColumn,
			this.NonRecurringColumn
		];
	}
}
