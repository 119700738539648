import { ConnectionIndexRequest, ConnectionIndexResponse } from 'RtModels';
import { ConnectionRouter } from 'RtUi/app/rtVue/Connections/Connection.router';
import {
	fetchConnections,
	useGetConnections
} from 'RtUi/app/rtVue/Connections/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getConnectionsColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/connections';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';
import { useMemo } from 'react';

interface IConnectionGridProps {
	resourceParams?: ConnectionIndexRequest;
}

export const ConnectionGrid = ({ resourceParams }: IConnectionGridProps) => {
	const { data: connectionsData, isLoading } =
		useGetConnections(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportVueConnections', resourceParams],
		resourceParams ?? {},
		(params) => fetchConnections(params)
	);
	const columns = useMemo(() => getConnectionsColumns(), []);

	return (
		<DataGrid<ConnectionIndexResponse>
			data={connectionsData?.data}
			columns={columns}
			pageName={'rateSheet-connections'}
			router={ConnectionRouter}
			loading={isLoading}
			enableExternalExport
			getExternalExportData={fullResultsReq}
			totalRows={connectionsData?.totalCount}
		/>
	);
};
