import { MinutesRouter } from 'RtUi/app/rtLco/Minutes/Minutes.router';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { LcrMinutesDataGrid } from 'RtUi/app/rtLco/Minutes/lib/grids/LcrMinutesDataGrid';
import { LcrMinuteDataRequest, MinuteProfile } from 'RtModels';
import { LcrMinutesResource } from 'RtUi/app/rtLco/Minutes/lib/resources/LcrMinutesResource';
import { Loading } from 'RtUi/components/ui/Loading';
import { FC, useState } from 'react';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useMount } from 'react-use';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import ProperInput from 'RtUi/components/form/ProperInput/ProperInput';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { changeMinutesLabel } from 'RtUi/app/rtLco/Minutes/lib/service';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

export const MinutesProfileContainer: FC = () => {
	const navigate = useNavigate();
	const entityId = useIdParam();
	const [entity, setEntity] = useState<MinuteProfile>();
	const [aniNpaNxx, setAniNpaNxx] = useState<string>();
	const [lrnNpaNxx, setLrnNpaNxx] = useState<string>();
	const [label, setNewLabel] = useState('');
	const [error, setError] = useState<string | null>(null);
	const { mutateAsync, isLoading } = useMutation(() =>
		changeMinutesLabel(
			entityId,
			label,
			entity?.isActive as number,
			entity?.respOrgId as string
		)
	);

	const [tabs, activeTab, setActiveTab] = useTabs(
		MinutesRouter.getProfileTabs()
	);

	useMount(async () => {
		const resource = new LcrMinutesResource();
		const newEntity = await resource.get(entityId);

		setEntity(newEntity);
		setNewLabel(newEntity?.label || '');
	});

	if (!entity) {
		return <Loading />;
	}

	const resourceParams: LcrMinuteDataRequest = {};

	if (aniNpaNxx) {
		resourceParams.aniNpaNxx = aniNpaNxx;
	}

	if (lrnNpaNxx) {
		resourceParams.lrnNpaNxx = lrnNpaNxx;
	}

	const editLabel = async () => {
		try {
			await mutateAsync();
			navigate(-1);
		} catch (e) {
			setError((e as AxiosError).message);
		}
	};

	return (
		<TabbedLayout
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
			router={MinutesRouter}
			profile={entity}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					onSubmit={(evt) => {
						evt.preventDefault();

						setActiveTab(tabs.Profile.header);
					}}
				>
					<InputFormControl
						type="number"
						maxLength={6}
						label="aniNpaNxx"
						value={aniNpaNxx}
						onChange={(val) => {
							setAniNpaNxx(val);
						}}
					/>
					<InputFormControl
						type="number"
						maxLength={6}
						label="lrnNpaNxx"
						value={lrnNpaNxx}
						onChange={(val) => {
							setLrnNpaNxx(val);
						}}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Profile}>
				<LcrMinutesDataGrid
					minuteProfileId={Number(entityId)}
					resourceParams={resourceParams}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Edit}>
				<Row>
					<Col lg={6}>
						<Card body>
							<Form
								className={'rt-ui-form display-mode'}
								onSubmit={(event) => {
									event.preventDefault();
									editLabel();
								}}
							>
								<ProperInput
									value={label}
									label="Label"
									onChange={setNewLabel}
								/>
								{isLoading ? (
									<Loading />
								) : (
									<Button className="btn-submit" type="submit">
										<span>&nbsp;Submit</span>
									</Button>
								)}
								{error && (
									<Alert variant="danger">
										<i className="fas fa-fw fa-exclamation-triangle" />
										<span>&nbsp;{error}</span>
									</Alert>
								)}
							</Form>
						</Card>
					</Col>
				</Row>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

MinutesRouter.setProfileRtUiFunctionalComponent(MinutesProfileContainer);
