/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrTaskResultProfileRequest = {
    properties: {
        lcrTaskId: {
            type: 'number',
            isRequired: true,
        },
        lcrTaskResultId: {
            type: 'number',
            isRequired: true,
        },
    },
};