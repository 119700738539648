import { LrnProfileResponse } from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class LrnResource extends HttpResource<LrnProfileResponse> {
	public getProfile(nanpNumber: string): Promise<LrnProfileResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.NanpNpac.Profile, {
			urlParams: {
				nanpNumber
			}
		});
	}
}
