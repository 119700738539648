import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';
import { BlockedNumberCreateObject } from 'RtModels';
import { SpreadsheetParserEmitValidation } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserEmitValidation';

export class BlockedNumberSpreadsheetParser extends SpreadsheetParserEmitValidation {
	public phoneNumberColumn = new SpreadsheetParserColumn('phoneNumber', [
		'tn',
		'Phone Number'
	])
		.setIsRequired(true)
		.setIsPhoneNumber();
	public summaryColumn = new SpreadsheetParserColumn('summary', [
		'summary'
	]).setIsRequired(true);
	public activeColumn = new SpreadsheetParserColumn('isActive', [
		'active'
	]).setIsRequired(true);
	public blockAniColumn = new SpreadsheetParserColumn('blockAni', [
		'block ani'
	]).setIsRequired(true);
	public blockDestinationColumn = new SpreadsheetParserColumn(
		'blockDestination',
		['block destination']
	).setIsRequired(true);

	constructor(spreadsheet: string[][]) {
		super(spreadsheet);

		this.addParserColumn(
			this.phoneNumberColumn,
			this.summaryColumn,
			this.activeColumn,
			this.blockAniColumn,
			this.blockDestinationColumn
		);
	}

	public parseServiceNumberRecords(): BlockedNumberCreateObject[] {
		const serviceNumbers: BlockedNumberCreateObject[] = [];
		this.unknownFieldsMap = new Map<string, number>();

		const possibleHeaderMatches = this.findPossibleHeaderMatches();
		const possibleHeaderMatch = possibleHeaderMatches[0];

		const rows = this.parse(possibleHeaderMatch);

		const phoneNumberColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.phoneNumberColumn
		);
		const summaryColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.summaryColumn
		);
		const isActiveColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.activeColumn
		);
		const blockAniColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.blockAniColumn
		);
		const blockDestinationColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.blockDestinationColumn
		);

		for (const [i, row] of rows.entries()) {
			const rowIndex = i + possibleHeaderMatch.headerRowIndex + 1;
			const isActiveColumn =
				row[isActiveColumnIndex] && row[isActiveColumnIndex].toLowerCase();

			if (isActiveColumn === 'yes') {
				row[isActiveColumnIndex] = '1';
			}
			if (isActiveColumn === 'no') {
				row[isActiveColumnIndex] = '0';
			}

			const blockAniColumn =
				row[blockAniColumnIndex] && row[blockAniColumnIndex].toLowerCase();

			if (blockAniColumn === 'yes') {
				row[blockAniColumnIndex] = '1';
			}
			if (blockAniColumn === 'no') {
				row[blockAniColumnIndex] = '0';
			}

			const blockDestinationColumn =
				row[blockDestinationColumnIndex] &&
				row[blockDestinationColumnIndex].toLowerCase();

			if (blockDestinationColumn === 'yes') {
				row[blockDestinationColumnIndex] = '1';
			}
			if (blockDestinationColumn === 'no') {
				row[blockDestinationColumnIndex] = '0';
			}

			const phoneNumber = this.emitValidationCheck(
				'phoneNumber',
				row[phoneNumberColumnIndex],
				rowIndex
			);
			const summary = this.emitValidationCheck(
				'summary',
				row[summaryColumnIndex],
				rowIndex
			);
			const isActive = this.emitValidationCheck(
				'isActive',
				Number(row[isActiveColumnIndex]),
				rowIndex
			);
			const blockAni = this.emitValidationCheck(
				'blockAni',
				Number(row[blockAniColumnIndex]),
				rowIndex
			);
			const blockDestination = this.emitValidationCheck(
				'blockDestination',
				Number(row[blockDestinationColumnIndex]),
				rowIndex
			);

			const record: BlockedNumberCreateObject = {
				phoneNumber,
				summary,
				isActive,
				blockDestination,
				blockAni
			};

			serviceNumbers.push(record);
		}

		this.emitErrorMessage();

		return serviceNumbers;
	}
}
