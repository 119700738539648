import { uniq } from 'lodash-es';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { IRouterBreadcrumb } from 'RtUi/components/containers/lib/RtUiRouter';
import { Rt800Router } from '../rt800.router';

class CloneRoutingRouterClass extends Rt800Router<void, void, {}> {
	constructor() {
		super('Clone Routing', 'clone/routing', undefined, {});

		const indexPermissions = uniq([
			...Rt800ApiRoutes.TemplateCprClone.Update.permissions,
			...Rt800ApiRoutes.TollFreeCprClone.Update.permissions
		]);

		this.setIndexPermissions(indexPermissions);
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public async getBreadcrumbs(
		currentPath: string,
		profile?: void
	): Promise<IRouterBreadcrumb[]> {
		const path = this.getIndexRoute();
		const name = this.getPluralName();
		const isActive = true;

		const indexBreadcrumb: IRouterBreadcrumb = { path, name, isActive };

		return [this.getSectionBreadcrumb(), indexBreadcrumb];
	}

	public getProfileLabel(profile: void): string {
		throw new Error('Method not implemented.');
	}
}

export const CloneRoutingRouter = new CloneRoutingRouterClass();
