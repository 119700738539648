import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	DirectConnectIndexRequest,
	DirectConnectIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchDirectConnect = async (
	queryParams?: DirectConnectIndexRequest
): Promise<FullResponse<DirectConnectIndexResponse[]>> => {
	return handleGetRequest<DirectConnectIndexResponse[], true>(
		RtxSipApiRoutes.DirectConnect.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetDirectConnect = (
	queryParams?: DirectConnectIndexRequest
) => {
	return useQuery<FullResponse<DirectConnectIndexResponse[]>, Error>(
		[`useGetDirectConnect`, queryParams],
		() => fetchDirectConnect(queryParams),
		{ enabled: true }
	);
};
