import * as moment from 'moment-timezone';
import * as React from 'react';
import {
	MgiCprIndexResponse,
	RoutingCacheTypes,
	TemplateIndexResponse
} from 'RtModels';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import { NumberResource } from 'RtUi/app/rt800/Numbers/lib/resources/NumberResource';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import { TemplateResource } from 'RtUi/app/rt800/Templates/lib/resources/TemplateResource';
import { EffectiveTimeFormControl } from 'RtUi/components/form/EffectiveTimeFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { cprStatusIdToName, timestampToReadable } from 'RtUi/utils/maps';
import { LcrTaskResultsResource } from '../resources/LcrTaskResultsResource';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { maxBy } from 'lodash-es';

interface ILcrTaskResultActivateFormProps {
	lcrTaskId: number;
	lcrTaskResultId: number;
	onSuccess?: (taskProfile: ITaskProfile) => void;
}

interface ILcrTaskResultActivateFormState {
	cloneTarget: RoutingCacheTypes;
	targetNumber: string;
	targetRefCustomValidityMessage: string;
	minEffectiveTs: moment.Moment;
	targetEffectiveTs: moment.Moment;
	targetEffectiveTsIsNow: boolean;
	targetTemplate?: TemplateIndexResponse;
	targetCpr?: MgiCprIndexResponse;
	isSubmitting: boolean;
	error?: any;
}

export class LcrTaskResultActivateForm extends React.Component<
	ILcrTaskResultActivateFormProps,
	ILcrTaskResultActivateFormState
> {
	public state: ILcrTaskResultActivateFormState = {
		cloneTarget: RoutingCacheTypes.TollFree,
		targetNumber: '',
		targetRefCustomValidityMessage: '',
		minEffectiveTs: moment(),
		targetEffectiveTs: moment(),
		targetEffectiveTsIsNow: true,
		isSubmitting: false,
		error: undefined
	};

	public numberResource = new NumberResource();
	public templateResource = new TemplateResource();
	public targetRef: HTMLInputElement | null = null;

	public setTargetRefCustomValidityMessage(
		targetRefCustomValidityMessage: string
	) {
		this.setState({ targetRefCustomValidityMessage });

		if (this.targetRef) {
			this.targetRef.setCustomValidity(targetRefCustomValidityMessage);
		}
	}

	public getMinEffectiveTsFromTfn(tfn: string) {
		const cprResource = this.numberResource.getCprResource(tfn);

		cprResource
			.getAll()
			.then((cprs) => this.setMinEffectiveTsFromArray(cprs))
			.catch(() =>
				this.setTargetRefCustomValidityMessage(
					'Error Loading Toll-Free Number. Please enter a valid number.'
				)
			);
	}

	public setTargetRef(ref: HTMLInputElement | null) {
		this.targetRef = ref;

		if (this.targetRef) {
			this.targetRef.setCustomValidity(
				this.state.targetRefCustomValidityMessage
			);
		}
	}

	public setMinEffectiveTsFromArray(cprs: MgiCprIndexResponse[]) {
		const targetCpr = maxBy(cprs, (cpr) => moment(cpr.effectiveTs).valueOf());

		if (targetCpr) {
			const cprStatusName = cprStatusIdToName(targetCpr.cprStatusId);
			const isBadCpr =
				cprStatusName === 'Failed' || cprStatusName === 'Invalid';
			const minEffectiveTs = moment(targetCpr.effectiveTs)
				.add(30, 'seconds')
				.ceil(15, 'minutes'); // add 15mins if valid

			this.setState({ minEffectiveTs, targetCpr });

			let targetRefCustomValidityMessage = '';

			if (isBadCpr) {
				const readableEffectiveTs = timestampToReadable(targetCpr.effectiveTs);
				targetRefCustomValidityMessage = `Latest CPR at ${readableEffectiveTs} is not usable. Please select a valid entry.`;
			}

			this.setTargetRefCustomValidityMessage(targetRefCustomValidityMessage);
		}
	}

	public updateTargetNumber(targetNumber: string) {
		const formattedTFN = TollFreeNumbersUtils.tollFreeNumberToReadableString(
			targetNumber,
			false
		);

		this.setTargetRefCustomValidityMessage('');

		if (TollFreeNumbersUtils.isValid(formattedTFN)) {
			this.setTargetRefCustomValidityMessage(
				'Validating CPRs... Press "Submit" once this message disappears.'
			);
			//check if any failed/invalid and you can't use that number/template
			this.getMinEffectiveTsFromTfn(formattedTFN);
		}

		this.setState({ targetNumber });
	}

	public updateTargetTemplate(targetTemplate?: TemplateIndexResponse) {
		this.setState({ targetTemplate });

		this.setTargetRefCustomValidityMessage('');

		if (targetTemplate) {
			this.setTargetRefCustomValidityMessage(
				'Validating CPRs... Press "Submit" once this message disappears.'
			);
			this.getMinEffectiveTsFromTemplate(targetTemplate.templateName);
		}
	}

	public getMinEffectiveTsFromTemplate(templateName: string) {
		const cprResource = this.templateResource.getCprResource(templateName);

		cprResource
			.getAll()
			.then((cprs) => this.setMinEffectiveTsFromArray(cprs))
			.catch(() =>
				this.setTargetRefCustomValidityMessage(
					'Error loading template. Please enter a valid template.'
				)
			);
	}

	public submit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		const { lcrTaskId, lcrTaskResultId, onSuccess = () => ({}) } = this.props;
		const {
			cloneTarget,
			targetNumber,
			targetTemplate,
			targetEffectiveTsIsNow,
			targetEffectiveTs
		} = this.state;
		const targetEffectiveTsDate = targetEffectiveTsIsNow
			? undefined
			: targetEffectiveTs.toDate();
		let clonePromise: Promise<ITaskProfile> = Promise.reject([
			'Unknown Error Occurred.'
		]);
		const setErrors = (error: RtError) => this.setState({ error });
		let targetKey = '';

		if (cloneTarget === RoutingCacheTypes.Template) {
			if (!targetTemplate) {
				setErrors(
					new RtError({
						message: 'You must select a Template source.'
					})
				);
				return;
			}

			targetKey = targetTemplate.templateName;
		} else if (cloneTarget === RoutingCacheTypes.TollFree) {
			const targetTfn = TollFreeNumbersUtils.tollFreeNumberToReadableString(
				targetNumber,
				false
			);

			if (!TollFreeNumbersUtils.isValid(targetNumber)) {
				setErrors(new RtError({ message: 'Target number is malformed.' }));
				return;
			}

			targetKey = targetTfn;
		}

		const lcrTaskResultsResource = new LcrTaskResultsResource(lcrTaskId);

		clonePromise = lcrTaskResultsResource.activate(
			lcrTaskResultId,
			cloneTarget,
			targetKey,
			targetEffectiveTsDate
		);

		clonePromise
			.then((taskProfile) => onSuccess(taskProfile))
			.catch((error: RtError) => this.setState({ error }))
			.finally(() => this.setState({ isSubmitting: false }));
	}

	public render() {
		return (
			<RtUiNarrowForm
				createMode
				error={this.state.error}
				onChange={() => void 0}
				isSubmitting={this.state.isSubmitting}
				onSubmit={(e) => this.submit(e)}
			>
				<RadioFormControl<RoutingCacheTypes>
					required
					label=""
					value={this.state.cloneTarget}
					onChange={(cloneTo) => this.setState({ cloneTarget: cloneTo })}
					options={[
						{
							value: RoutingCacheTypes.TollFree,
							label: 'Toll-Free Number'
						},
						{
							value: RoutingCacheTypes.Template,
							label: 'Template'
						}
					]}
				/>
				<section style={{ position: 'relative' }}>
					{this.state.cloneTarget === RoutingCacheTypes.TollFree && (
						<TollFreeNumberFormControl
							required
							alwaysShowMask
							onlyNumbers
							onChange={(targetNumber) => this.updateTargetNumber(targetNumber)}
							value={this.state.targetNumber}
						/>
					)}
					{this.state.cloneTarget === RoutingCacheTypes.Template && (
						<TemplateSelect
							required
							isActive
							onChange={(targetTemplate: TemplateIndexResponse) =>
								this.updateTargetTemplate(targetTemplate)
							}
							value={this.state.targetTemplate}
						/>
					)}
					<input
						type="text"
						ref={(ref) => this.setTargetRef(ref)}
						tabIndex={-1}
						required
						minLength={1}
						onChange={() => void 0}
						value={this.state.targetRefCustomValidityMessage ? '' : '1'}
						style={{
							height: 1,
							width: 0,
							opacity: 0.01,
							position: 'absolute',
							left: '50%',
							bottom: 0
						}}
					/>
				</section>
				<EffectiveTimeFormControl
					required
					isAfter={this.state.minEffectiveTs}
					onChangeWithIsNow={(
						targetEffectiveTs = moment(),
						targetEffectiveTsIsNow
					) =>
						this.setState({
							targetEffectiveTs,
							targetEffectiveTsIsNow
						})
					}
					value={this.state.targetEffectiveTs}
				/>
			</RtUiNarrowForm>
		);
	}
}
