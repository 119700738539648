import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationBulkIndexRequest,
	GuardianReconciliationBulkUpdateRequest,
	GuardianReconciliationIndexResponse
} from 'RtModels';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';

export class GuardianReconciliationBulkResource {
	public getAll(request: GuardianReconciliationBulkIndexRequest) {
		const body = JSON.stringify(request);

		return HttpRequest.fetchWithRoute<GuardianReconciliationIndexResponse[]>(
			RtVueApiRoutes.GuardianReconciliationBulk.Index,
			{
				body
			}
		);
	}

	public update(request: GuardianReconciliationBulkUpdateRequest) {
		const body = JSON.stringify(request);

		return HttpRequest.fetchWithRoute<GuardianReconciliationIndexResponse[]>(
			RtVueApiRoutes.GuardianReconciliationBulk.Update,
			{
				body
			}
		);
	}
}
