/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum MessageEventType {
    RocRequest = 1,
    TaskCompletion = 2,
    RoutingGroupsAudit = 3,
    TemplateRoutingAudit = 4,
    TollFreeRoutingAudit = 5,
    IncomingRespOrgChange = 6,
    OutgoingRespOrgChange = 7,
    ManagedEntitiesAudit = 8,
    ManagedRespOrgsAudit = 9,
    TaskCompletionActivate = 10,
    TaskCompletionReserve = 11,
    TaskCompletionSpare = 12,
    TaskCompletionDisconnect = 13,
    TaskCompletionReRoute = 14,
    TaskCompletionRespOrgChange = 15,
    TaskCompletionTemplateCprCreate = 16,
    TaskCompletionTemplateCprUpdate = 17,
    TaskCompletionTemplateCprDelete = 18,
    TaskCompletionTemplateCprTransfer = 19,
    TaskCompletionTemplateCreate = 20,
    TaskCompletionTollFreeCprCreate = 21,
    TaskCompletionTollFreeCprUpdate = 22,
    TaskCompletionTollFreeCprDelete = 23,
    TaskCompletionTollFreeCprTransfer = 24,
    TaskCompletionTemplateCprClone = 25,
    TaskCompletionTollFreeCprClone = 26,
    TaskCompletionLcrActivationTemplate = 27,
    TaskCompletionLcrActivationTollFree = 28,
    TaskCompletionTemplateCprDisconnect = 29,
    UserScheduledReport = 30,
    CsvExport = 31,
}