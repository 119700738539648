import { SipTraceIndexResponseData } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

export const getSipTraceColumns = (): Array<
	DataGridColumn<SipTraceIndexResponseData>
> => [
	DateDataGridColumn({
		accessorKey: 'create_date',
		header: 'Date',
		showTime: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'sid',
		header: 'Session Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'method',
		header: 'SIP Method'
	}),
	DefaultDataGridColumn({
		accessorKey: 'from_user',
		header: 'SIP From User'
	}),
	DefaultDataGridColumn({
		accessorKey: 'to_user',
		header: 'SIP To User'
	}),
	DefaultDataGridColumn({
		header: 'Source IP',
		accessorFn: (row) => `${row.srcIp}:${row.srcPort}`
	}),
	DefaultDataGridColumn({
		header: 'Destination IP',
		accessorFn: (row) => `${row.dstIp}:${row.dstPort}`
	})
];
