/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NetworkEfficiencyIndexResponse = {
    properties: {
        connects: {
            type: 'number',
            isRequired: true,
        },
        attempts: {
            type: 'number',
            isRequired: true,
        },
        account: {
            type: 'string',
            isRequired: true,
        },
        subscription: {
            type: 'string',
            isRequired: true,
        },
        country: {
            type: 'string',
            isRequired: true,
        },
        rateCenter: {
            type: 'string',
            isRequired: true,
        },
        routeDepth: {
            type: 'number',
            isRequired: true,
        },
        minutes: {
            type: 'number',
            isRequired: true,
        },
        acr: {
            type: 'number',
            isRequired: true,
        },
        adr: {
            type: 'number',
            isRequired: true,
        },
        ner: {
            type: 'number',
            isRequired: true,
        },
        pdd: {
            type: 'number',
            isRequired: true,
        },
    },
};