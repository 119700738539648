import moment from 'moment';
import * as pluralize from 'pluralize';
import VisibilitySensor from 'react-visibility-sensor';
import {
	Alert,
	Button,
	Dropdown,
	Collapse,
	Form,
	ListGroup,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';
import { FirebaseMessageType, ProductId } from 'RtModels';
import { NotificationGroupItem } from 'RtUi/app/ApplicationShell/lib/components/NotificationGroupItem';
import { DashboardOldStatusIcon } from 'RtUi/app/rtCommon/DashboardOld/lib/components/DashboardStatusIcon';
import { ProductFilter } from 'RtUi/app/rtCommon/DashboardOld/lib/components/ProductFilter';
import { UiNotification } from 'RtUi/notifications/lib/UiNotification';
import { UiNotificationsManager } from 'RtUi/notifications/UiNotificationsManager';
import { ComponentController } from 'RtUi/components/ComponentController';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { Aside } from 'RtUi/components/ui/Aside';
import { BeautifulNight } from 'RtUi/components/ui/BeautifulNight';
import { UserActions } from 'RtUi/state/actions/user';
import { FirebaseUser } from 'RtUi/state/actions/user/FirebaseUser';
import {
	FireBaseUserMessageListener,
	IFirebaseUIMessage
} from 'RtUi/state/actions/user/BaseFireBase';
interface IDashboardStatusProps {
	className?: string;
}

interface IDashboardStatusState {
	unlockedEasterEgg: boolean;
	productFilter: ProductId[];
	notPinnedIssuesCount: number;
	notPinnedWarningsCount: number;
	notifications: UiNotification[];
	messages: IFirebaseUIMessage[];
	notificationAsideIsOpen: boolean;
	seeAll: boolean;
}

export class DashboardStatus extends ApplicationContainer<
	IDashboardStatusProps,
	IDashboardStatusState
> {
	public static MAX_NOTIFICATIONS = 10;

	public state: IDashboardStatusState = {
		productFilter: UserActions.getUsersProducts(true),
		unlockedEasterEgg: false,
		notPinnedIssuesCount: 0,
		notPinnedWarningsCount: 0,
		notifications: [],
		messages: [],
		notificationAsideIsOpen: false,
		seeAll: false
	};

	public uiNotificationsManager = UiNotificationsManager.getInstance();
	public firebaseUser = FirebaseUser.getInstance();
	public removeListenerFns: Array<() => void> = [];

	public componentDidMount() {
		this.initNotifications();
		this.initUserMessages();
	}

	public componentWillUnmount() {
		for (const removeListenerFN of this.removeListenerFns) {
			removeListenerFN();
		}
	}

	public resetProductFilters() {
		const productFilter = UserActions.getUsersProducts(true);

		this.setState({ productFilter });
	}

	public initNotifications() {
		this.uiNotificationsManager.getNotifications().then((notifications) => {
			this.setState({ notifications });

			for (const notification of notifications) {
				const removeListenerFn = notification.onUpdate(() =>
					this.aggregateTotals()
				);

				this.removeListenerFns.push(removeListenerFn);
			}
		});
	}

	public initUserMessages() {
		const firebaseMessagesListener: FireBaseUserMessageListener = (
			messages,
			err
		) => {
			if (err) {
				console.error('Could not load User Messages: ', err);
				return;
			}

			this.setState({ messages });
		};

		const removeListenerFn = this.firebaseUser.onMessages(
			firebaseMessagesListener,
			[FirebaseMessageType.UserAlert]
		);

		this.removeListenerFns.push(removeListenerFn);
	}

	public aggregateTotals() {
		let notPinnedIssuesCount = 0;
		let notPinnedWarningsCount = 0;

		for (const notification of this.state.notifications) {
			const [settings, state] = notification.getCurrentSettingsAndState();

			if (typeof state.issuesCount === 'number') {
				if (!settings.isPinned) {
					if (settings.isWarning) {
						notPinnedWarningsCount += state.issuesCount;
					} else {
						notPinnedIssuesCount += state.issuesCount;
					}
				}
			}
		}

		this.setState({ notPinnedIssuesCount, notPinnedWarningsCount });
	}

	public markMessageVisibility(
		isVisible: boolean,
		message: IFirebaseUIMessage
	) {
		if (isVisible && moment(message.expireTs).isBefore(moment())) {
			this.markMessageAsRead(message);
		}
	}

	public markMessageAsRead(message: IFirebaseUIMessage) {
		if (!message || message.userHasRead) {
			return;
		}

		this.firebaseUser.markUserMessageAsRead(message);
	}

	public markAllAsRead() {
		for (const message of this.state.messages) {
			this.markMessageAsRead(message);
		}
	}

	public filterMessages(messages: Array<IFirebaseUIMessage<any>>) {
		const { productFilter } = this.state;
		const mappedRt800ProductIds = [
			ProductId.RT_CARRIER_CONNECT,
			ProductId.RT_LCO
		];

		return messages.filter((message) => {
			let checkedProductId = message.productId;

			//Carrier Connect and LCO are mapped to RT_800 in login logic
			//In other words, they are not separate from RT_800
			if (mappedRt800ProductIds.includes(checkedProductId)) {
				checkedProductId = ProductId.RT_800;
			}

			return productFilter.includes(checkedProductId);
		});
	}

	public filterMaxShownMessages(messages: Array<IFirebaseUIMessage<any>>) {
		return messages.length > DashboardStatus.MAX_NOTIFICATIONS &&
			!this.state.seeAll
			? messages.slice(0, DashboardStatus.MAX_NOTIFICATIONS)
			: messages;
	}

	public goToAlert(message: IFirebaseUIMessage) {
		const profilePath =
			ComponentController.getInstance().getProfilePathFromFirebaseMessage(
				message
			);

		if (!profilePath) {
			return;
		}

		this.setState({ notificationAsideIsOpen: false });

		this.goToPath(profilePath);
	}

	public renderMessages(messages: Array<IFirebaseUIMessage<any>>) {
		if (messages.length <= 0) {
			if (this.state.unlockedEasterEgg) {
				return (
					<Alert
						style={{
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							position: 'absolute'
						}}
						variant="info"
						className="rounded-0 mb-0 text-white"
					>
						<BeautifulNight />
						<section
							className="text-center mb-3"
							style={{
								position: 'absolute',
								top: 75,
								right: 50,
								width: 200
							}}
						>
							<h4>You have achieved zero notifications!</h4>
						</section>
					</Alert>
				);
			}

			return (
				<Alert variant="success" className="rounded-0 mb-0">
					<a
						onClick={(evt) => {
							evt.stopPropagation(); //aside wont collapse
							this.setState({ unlockedEasterEgg: true });
						}}
					>
						<i className="fas fa-fw fa-check-circle" />
						&nbsp;You have achieved zero notifications!
					</a>
				</Alert>
			);
		}

		const shownMessages = this.filterMessages(messages);
		const hiddenMessagesLength = messages.length - shownMessages.length;
		const maxShownMessages = this.filterMaxShownMessages(shownMessages);

		return (
			<>
				<header
					className="d-flex justify-content-start align-items-center mt-3"
					style={{ overflowX: 'scroll' }}
				>
					<Form.Label className="ms-3 me-1 mb-0">Filter</Form.Label>
					<ProductFilter
						value={this.state.productFilter}
						onChange={(productFilter) => this.setState({ productFilter })}
					/>
				</header>
				<hr className="mb-0" />
				<ListGroup variant="flush">
					<Collapse
						className="list-group-item list-group-item-warning"
						in={hiddenMessagesLength > 0}
					>
						<div>
							<span>
								{hiddenMessagesLength.toLocaleString()}&nbsp;
								{pluralize('notification', hiddenMessagesLength)}&nbsp;
								{pluralize('has', hiddenMessagesLength)} been filtered.&nbsp;
							</span>
							<span>
								<a href="" onClick={() => this.resetProductFilters()}>
									Click here to show all
								</a>{' '}
								notifications.
							</span>
						</div>
					</Collapse>
					{maxShownMessages.map((message) => {
						const unread = !message.userHasRead;

						return (
							<VisibilitySensor
								key={message.uuid}
								intervalDelay={5 * 1000}
								onChange={(isVisible: boolean) =>
									this.markMessageVisibility(isVisible, message)
								}
							>
								<NotificationGroupItem
									message={message}
									isLight={!unread}
									onClick={() => this.goToAlert(message)}
								/>
							</VisibilitySensor>
						);
					})}
				</ListGroup>
				{shownMessages.length > DashboardStatus.MAX_NOTIFICATIONS && (
					<div className="text-center">
						<Button
							variant="link"
							onClick={() => {
								this.setState((state) => ({ seeAll: !state.seeAll }));
							}}
						>
							See {this.state.seeAll ? 'Less' : 'All'}{' '}
							{!this.state.seeAll && `(${shownMessages.length})`}
						</Button>
					</div>
				)}
			</>
		);
	}

	public render() {
		const { className = '' } = this.props;
		const { notifications, messages } = this.state;
		const numberOfUnReadMessages = messages.reduce(
			(prev, message) => (message.userHasRead ? prev : prev + 1),
			0
		);
		const notExpiredMessagesCount = messages.length - numberOfUnReadMessages;
		const numberOfUnReadMessagesStr = numberOfUnReadMessages.toLocaleString();
		const unreadNotificationsWord = pluralize(
			'notification',
			numberOfUnReadMessages
		);

		return (
			<section className={className}>
				{notifications.map((notification) => (
					<DashboardOldStatusIcon
						key={notification.getTitle()}
						notification={notification}
					/>
				))}
				<OverlayTrigger
					placement="bottom"
					overlay={(props) => (
						<Tooltip id="dashBtnNotifications-tooltip" {...props}>
							{notExpiredMessagesCount > 0 && (
								<>
									You have {numberOfUnReadMessagesStr} unread{' '}
									{unreadNotificationsWord}
								</>
							)}
							{notExpiredMessagesCount <= 0 && <>See notifications</>}
						</Tooltip>
					)}
				>
					{({ ref, ...triggerHandler }) => (
						<Button
							ref={ref}
							{...triggerHandler}
							onClick={() => {
								this.setState({ notificationAsideIsOpen: true });
							}}
							id="dashBtnNotifications"
						>
							<span className="fa-layers fa-fw">
								<i
									className={`fas fa-bell ${
										numberOfUnReadMessages > 0 ? 'show-swing' : ''
									}`}
								/>
								{numberOfUnReadMessages > 0 && (
									<span className="fa-layers-counter fa-layers-counter-top-right bg-warning">
										{numberOfUnReadMessagesStr}
									</span>
								)}
							</span>
						</Button>
					)}
				</OverlayTrigger>
				<Aside
					disabledBody
					isOpen={this.state.notificationAsideIsOpen}
					onClickOutside={() =>
						this.setState({ notificationAsideIsOpen: false })
					}
				>
					<Aside.Header
						closable={false}
						header={
							<>
								<i className="fas fa-fw fa-bell" />
								<b>&nbsp;Notifications&nbsp;</b>
								<span>({notExpiredMessagesCount.toLocaleString()})</span>
							</>
						}
					>
						<Dropdown>
							<Dropdown.Toggle
								bsPrefix="m-0"
								variant="white-alt"
								className="rounded"
							>
								<i className="fas fa-fw fa-ellipsis-v" />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item
									onClick={() => {
										this.markAllAsRead();
										this.setState({
											notificationAsideIsOpen: false
										});
									}}
								>
									Mark all as read
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() =>
										this.setState({
											notificationAsideIsOpen: false
										})
									}
								>
									Close
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Aside.Header>
					<section
						style={{
							overflowY: 'auto',
							position: 'absolute',
							bottom: 0,
							left: 0,
							right: 0,
							top: 60
						}}
					>
						{this.renderMessages(messages)}
					</section>
				</Aside>
			</section>
		);
	}
}
