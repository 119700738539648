import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationUsageDetailsIndexRequest,
	GuardianReconciliationUsageDetailsIndexResponse,
	GuardianReconciliationUsageDetailsSummaryIndexRequest,
	GuardianReconciliationUsageDetailsSummaryIndexResponse,
	GuardianReconciliationUsageDialCodeSummaryIndexRequest,
	GuardianReconciliationUsageDialCodeSummaryIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchReconciliationUsageDialCodeSummary = async (
	queryParams: GuardianReconciliationUsageDialCodeSummaryIndexRequest
): Promise<GuardianReconciliationUsageDialCodeSummaryIndexResponse[]> => {
	return handleGetRequest(
		RtVueApiRoutes.GuardianReconciliationUsageDialCodeSummary.Index,
		{
			queryParams
		}
	);
};

export const useGetReconciliationUsageDialCodeSummary = (
	queryParams: GuardianReconciliationUsageDialCodeSummaryIndexRequest
) => {
	const cacheKey = JSON.stringify(queryParams);

	return useQuery<
		GuardianReconciliationUsageDialCodeSummaryIndexResponse[],
		Error
	>([`getReconciliationUsageDialCodeSummary`, cacheKey], () => {
		return fetchReconciliationUsageDialCodeSummary(queryParams);
	});
};

export const fetchGuardianReconciliationUsageDetails = async (
	queryParams?: GuardianReconciliationUsageDetailsIndexRequest
): Promise<FullResponse<GuardianReconciliationUsageDetailsIndexResponse[]>> => {
	return handleGetRequest<
		GuardianReconciliationUsageDetailsIndexResponse[],
		true
	>(RtVueApiRoutes.GuardianReconciliationUsageDetails.Index, {
		includeFullResponse: true,
		queryParams
	});
};

export const useGetGuardianReconciliationUsageDetails = (
	queryParams?: GuardianReconciliationUsageDetailsIndexRequest
) => {
	return useQuery<
		FullResponse<GuardianReconciliationUsageDetailsIndexResponse[]>,
		Error
	>([`useGetGuardianReconciliationUsageDetails`, queryParams], () =>
		fetchGuardianReconciliationUsageDetails(queryParams)
	);
};

export const fetchGuardianReconciliationUsageDetailsSummary = async (
	queryParams: GuardianReconciliationUsageDetailsSummaryIndexRequest
): Promise<
	FullResponse<GuardianReconciliationUsageDetailsSummaryIndexResponse[]>
> => {
	return handleGetRequest<
		GuardianReconciliationUsageDetailsSummaryIndexResponse[],
		true
	>(RtVueApiRoutes.GuardianReconciliationUsageDetailsSummary.Index, {
		includeFullResponse: true,
		queryParams
	});
};

export const useGetGuardianReconciliationUsageDetailsSummary = (
	queryParams: GuardianReconciliationUsageDetailsSummaryIndexRequest
) => {
	return useQuery<
		FullResponse<GuardianReconciliationUsageDetailsSummaryIndexResponse[]>,
		Error
	>([`useGetGuardianReconciliationUsageDetails`, queryParams], () =>
		fetchGuardianReconciliationUsageDetailsSummary(queryParams)
	);
};
