/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipConnectionUpdateRequest = {
    properties: {
        isActive: {
            type: 'number',
        },
        nanpFormat: {
            type: 'string',
            isRequired: true,
        },
        idddFormat: {
            type: 'string',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        mediaServerGroupId: {
            type: 'number',
            isNullable: true,
        },
    },
};