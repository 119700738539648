export enum AosNetwork {
	BA = 'BA',
	BS = 'BS',
	Canada = 'CN',
	Caribbean = 'CR',
	UnitedStates = 'US',
	UnitedStatesCanada = 'XA',
	UnitedStatesCaribbean = 'XB',
	UnitedStatesCanadaCaribbean = 'XC',
	UW = 'UW',
}

export enum AosType {
	AreaCode = 4,
	Lata = 5,
	Network = 6,
	States = 7,
}

export enum CprApprovalIndicator {
	NotApplicable = 'NA',
	Ok = 'OK',
	AwaitingApproval = 'AW',
	ApprovalDenied = 'DN',
	NotRequired = 'NR',
	RejectedEffectiveDate = 'RJ',
	Unknown = 'UN'
};

export enum CprStatus {
	Saved = 1,
	Pending = 2,
	Sending = 3,
	Active = 4,
	Old = 5,
	Invalid = 6,
	Disconnect = 7,
	MustCheck = 8,
	Failed = 9,
	Hold = 10,
	Unknown = 99
}
