import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { ActivePortDetailIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';

interface IActivePortsDetailsRouterTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const ActivePortsDetailsContainerTabs: IActivePortsDetailsRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class ActivePortsDetailsRouterClass extends SipApplicationRouter<
	ActivePortDetailIndexResponse,
	ActivePortDetailIndexResponse,
	IActivePortsDetailsRouterTabs
> {
	constructor() {
		super(
			'Active Ports Details',
			'ActivePortsDetails',
			'igCps',
			ActivePortsDetailsContainerTabs
		);

		this.setIndexPermissions([]);
		this.setProfilePermissions([]);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const ActivePortsDetailsRouter = new ActivePortsDetailsRouterClass();
