import {
	GuardianReconciliationMappingFiltersIndexRequest,
	GuardianReconciliationMappingFiltersIndexResponse,
	GuardianReconciliationMappingFiltersProfileResponse,
	ReconciliationMappingDataSourceDirection
} from 'RtModels';
import { GuardianReconciliationMappingFiltersEditor } from 'RtUi/app/rtVue/GuardianReconciliationMappingFilter/lib/forms/GuardianReconciliationMappingFiltersEditor';
import { useGetReconciliationMappingFilters } from 'RtUi/app/rtVue/GuardianReconciliationMappingFilter/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getGuardianReconciliationMappingFilterColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/guardianReconciliationMappingFilter';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { useCallback, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Item } from 'react-contexify';
import { GuardianReconciliationMappingFilterResource } from '../resources/GuardianReconciliationMappingFilterResource';

interface IGuardianReconciliationMappingFilterGridProps {
	guardianReconciliationMappingId: number;
	dataSourceDirection: ReconciliationMappingDataSourceDirection;
	resourceParams?: Omit<
		GuardianReconciliationMappingFiltersIndexRequest,
		'guardianReconciliationMappingId' | 'dataSourceDirection'
	>;
	autoFocusFilter?: boolean;
	header?: string;
}

export const GuardianReconciliationMappingFilterGrid = ({
	resourceParams = {},
	guardianReconciliationMappingId,
	dataSourceDirection,
	header
}: IGuardianReconciliationMappingFilterGridProps) => {
	const dataGridResourceParams = {
		...resourceParams,
		guardianReconciliationMappingId,
		dataSourceDirection
	};
	const [isAsideOpen, setIsAsideOpen] = useState(false);
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetReconciliationMappingFilters(dataGridResourceParams);
	const [editMode, setEditMode] =
		useState<GuardianReconciliationMappingFiltersProfileResponse>();

	const closeAside = useCallback(() => {
		setEditMode(undefined);
		setIsAsideOpen(false);
	}, []);

	const removeEntity = useCallback(
		async (row: GuardianReconciliationMappingFiltersIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				row.guardianReconciliationMappingFilterId.toString()
			);

			if (!confirm) {
				return;
			}
			setIsAsideOpen(false);

			try {
				const resource = new GuardianReconciliationMappingFilterResource();

				await resource.delete(row.guardianReconciliationMappingFilterId);

				refetch();
			} catch (error) {
				console.error(error);
			}
		},
		[refetch]
	);

	const columns = useMemo(
		() => getGuardianReconciliationMappingFilterColumns(removeEntity),
		[removeEntity]
	);

	return (
		<>
			<h2>{header}</h2>
			<DataGrid<GuardianReconciliationMappingFiltersIndexResponse>
				pageName="rtVue_GuardianReconciliationMappingProfile"
				disableExternalLinks
				data={data}
				loading={isLoading}
				rowThemeColor={(row) =>
					row.guardianReconciliationMappingFilterId ===
					editMode?.guardianReconciliationMappingFilterId
						? RowThemeColor.WARNING
						: undefined
				}
				menuLinks={(record) => (
					<Item
						onClick={() => {
							setEditMode(record);
							setIsAsideOpen(true);
						}}
					>
						<span className="d-flex justify-content-start align-items-center">
							<i className="fas fa-fw fa-pencil-alt" />
							<span>Update</span>
						</span>
					</Item>
				)}
				headerAction={() => (
					<Button variant="white" onClick={() => setIsAsideOpen(true)}>
						<i className="fas fa-fw fa-plus" />
					</Button>
				)}
				columns={columns}
			/>
			<Aside isOpen={isAsideOpen} disabledBody onClickOutside={closeAside}>
				<Aside.Header
					header={editMode ? 'Edit Filter' : 'Create Filter'}
					warning={Boolean(editMode)}
					onClose={closeAside}
				/>
				<GuardianReconciliationMappingFiltersEditor
					key={editMode?.guardianReconciliationMappingFilterId}
					guardianReconciliationMappingId={guardianReconciliationMappingId}
					dataSourceDirection={dataSourceDirection}
					displayMode={false}
					createMode={typeof editMode === 'undefined'}
					editMode={editMode}
					onUpdate={() => {
						setIsAsideOpen(false);
						refetch();
						setEditMode(undefined);
					}}
				/>
			</Aside>
		</>
	);
};
