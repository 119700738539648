/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExportCreateRequest = {
    properties: {
        cdrExportTypeId: {
            type: 'number',
            isRequired: true,
        },
        cdrSourceTypeId: {
            type: 'CdrExportSourceTypes',
            isRequired: true,
            isNullable: true,
        },
        cdrSourceValue: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        isConnected: {
            type: 'number',
            isRequired: true,
        },
        cdrRetentionId: {
            type: 'number',
            isRequired: true,
        },
        begTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};