import { IRtxApiRoute, RtxSipApiRoutes } from 'RtExports/routes';
import {
	SipTraceIndexRequest,
	SipTraceIndexResponse,
	SipTraceIndexResponseData,
	SipTraceProfileRequest,
	SipTraceProfileResponse
} from 'RtModels';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { addMinutes, subMinutes } from 'date-fns';

const fileUtils = new FileUtils();

export class SipTraceResource extends ArrayResource<SipTraceIndexResponseData> {
	protected _keys: string[] = [];
	protected _total: number = 0;

	constructor(protected resourceParams: SipTraceIndexRequest) {
		super('uuid');

		this.setApiRouteForGetAll(RtxSipApiRoutes.SipTrace.Index);

		const body = JSON.stringify(resourceParams);

		const dataP = Promise.resolve(
			this.fetchWithRoute<SipTraceIndexResponse>(
				RtxSipApiRoutes.SipTrace.Index,
				{
					body,
					disableNotification: true
				}
			)
		).then((res) => {
			this._keys = res.keys;
			this._total = res.total;
			return res.data;
		});

		this.setGetAllPromise(dataP);
	}

	public get keys(): string[] {
		return this._keys;
	}

	public get total(): number {
		return this._total;
	}

	public async getProfile(callId: string) {
		const body = JSON.stringify({
			startTime: subMinutes(new Date(this.resourceParams.startTime), 5),
			endTime: addMinutes(new Date(this.resourceParams.endTime), 5),
			gatewayId: this.resourceParams.gatewayId,
			callId
		});

		const res = await this.fetchWithRoute<SipTraceProfileResponse>(
			RtxSipApiRoutes.SipTrace.Profile,
			{
				body,
				disableNotification: true
			}
		);

		return res.data;
	}

	public async downloadPcap(callId: string) {
		const defer = this.getDownloadPcapResponse(callId);
		await fileUtils.downloadFromUrlWithPromise(defer);
	}

	public async downloadText(callId: string) {
		const defer = this.getDownloadTextResponse(callId);
		await fileUtils.downloadFromUrlWithPromise(defer);
	}

	private async getDownloadTextResponse(callId: string) {
		return this.getDownloadResponse(
			RtxSipApiRoutes.SipTraceExportText.Profile,
			callId,
			'txt'
		);
	}

	private async getDownloadPcapResponse(callId: string) {
		return this.getDownloadResponse(
			RtxSipApiRoutes.SipTraceExportPcap.Profile,
			callId,
			'pcap'
		);
	}

	private async getDownloadResponse(
		route: IRtxApiRoute,
		callId: string,
		ext: string
	) {
		const body = JSON.stringify({
			startTime: this.resourceParams.startTime,
			endTime: this.resourceParams.endTime,
			gatewayId: this.resourceParams.gatewayId,
			callId
		});

		const response =
			await HttpRequest.rawFetchWithRoute<SipTraceProfileRequest>(route, {
				body
			});

		if (!response.ok) {
			const error: any = await response.json();

			throw error;
		}

		const blob = await response.blob();
		const url = URL.createObjectURL(blob);

		return { url, fileName: `sip_trace_${callId}.${ext}` };
	}
}
