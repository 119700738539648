import {
	GuardianFraudSystemConfigCreateRequest,
	GuardianFraudSystemConfigIndexResponse,
	GuardianFraudSystemConfigProfileResponse,
	GuardianFraudSystemConfigProfileUrlRequest,
	GuardianFraudSystemConfigUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtVueApiRoutes } from 'RtExports/routes';

export class FraudConfigurationResource extends HttpResource<
	GuardianFraudSystemConfigIndexResponse,
	GuardianFraudSystemConfigProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianFraudSystemConfig);
	}

	public update(
		guardianFraudSystemConfigId: number,
		updateRequest: GuardianFraudSystemConfigUpdateRequest
	) {
		const urlParams: GuardianFraudSystemConfigProfileUrlRequest = {
			guardianFraudSystemConfigId
		};

		const body = JSON.stringify(updateRequest);

		return this.fetchWithRoute<GuardianFraudSystemConfigProfileResponse>(
			RtVueApiRoutes.GuardianFraudSystemConfig.Update,
			{
				body,
				urlParams
			}
		);
	}

	public create(createRequest: GuardianFraudSystemConfigCreateRequest) {
		const body = JSON.stringify(createRequest);

		return this.fetchWithRoute<GuardianFraudSystemConfigProfileResponse>(
			RtVueApiRoutes.GuardianFraudSystemConfig.Create,
			{
				body
			}
		);
	}

	public delete(guardianFraudSystemConfigId: number) {
		const urlParams: GuardianFraudSystemConfigProfileUrlRequest = {
			guardianFraudSystemConfigId
		};

		return this.fetchWithRoute(
			RtVueApiRoutes.GuardianFraudSystemConfig.Delete,
			{ urlParams }
		);
	}

	public createNewProfile(): GuardianFraudSystemConfigCreateRequest {
		return {
			paramName: '',
			paramValue: '',
			summary: '',
			isActive: 1
		};
	}
}
