/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianAlertProfileResponse = {
    properties: {
        guardianAlertId: {
            type: 'number',
            isRequired: true,
        },
        alertTypeId: {
            type: 'number',
            isRequired: true,
        },
        timeRange: {
            type: 'AlertTimeRange',
            isRequired: true,
        },
        resourceIds: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        errorMessage: {
            type: 'string',
            isRequired: true,
        },
        filters: {
            type: 'Array',
            isRequired: true,
        },
        rules: {
            type: 'Array',
            isRequired: true,
        },
        having: {
            type: 'Array',
            isRequired: true,
        },
        dataColumns: {
            type: 'Array',
            isRequired: true,
            isNullable: true,
        },
        guardianAlertActionIds: {
            type: 'Array',
            isRequired: true,
            isNullable: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        runIntervalQty: {
            type: 'number',
            isRequired: true,
        },
        runIntervalType: {
            type: 'AlertInterval',
            isRequired: true,
        },
        lastRunTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        lastRunAlertQty: {
            type: 'number',
            isRequired: true,
        },
        nextRunTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        updatedBy: {
            type: 'number',
            isRequired: true,
        },
        schedules: {
            type: 'Array',
            isRequired: true,
        },
    },
};