/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $InvoiceSubscription = {
    properties: {
        invoiceSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        invoiceId: {
            type: 'number',
            isRequired: true,
        },
        carrierId: {
            type: 'number',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        serviceId: {
            type: 'number',
            isRequired: true,
        },
        connects: {
            type: 'number',
            isRequired: true,
        },
        duration: {
            type: 'number',
            isRequired: true,
        },
        charges: {
            type: 'number',
            isRequired: true,
        },
        margin: {
            type: 'number',
            isRequired: true,
        },
    },
};