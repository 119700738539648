/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum GlobalUserQueryIdentifiers {
    RoboScore = 'roboscore',
    CostDetail = 'costDetail',
    RevenueDetail = 'revenueDetail',
}