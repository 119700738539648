import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import {
	ConnectionContainerTabs,
	ConnectionRouter
} from 'RtUi/app/rtVue/Connections/Connection.router';
import {
	ConnectionProfileResponse,
	GuardianConnectionSubscriptionProfileResponse
} from 'RtModels';
import { ConnectionResource } from 'RtUi/app/rtVue/Connections/lib/resources/ConnectionResource';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { ConnectionEditor } from 'RtUi/app/rtVue/Connections/lib/forms/ConnectionEditor';
import { ConnectionSubscriptionGrid } from 'RtUi/app/rtVue/Connections/lib/grids/ConnectionSubscriptionGrid';
import { RtVueApiRoutes } from 'RtExports/routes';
import { ConnectionSubscriptionFormEditor } from 'RtUi/app/rtVue/ConnectionSubscription/lib/forms/ConnectionSubscriptionEditor';
import { ConnectionSubscriptionRouter } from 'RtUi/app/rtVue/ConnectionSubscription/ConnectionSubscription.router';

interface IConnectionProfileState {
	activeTab: string;
	resource: ConnectionResource;
	profile?: ConnectionProfileResponse;
	isEdit: boolean;
	editStep: number;
	error?: any;
}

@ConnectionRouter.getProfileRtUiController()
export class ConnectionProfileContainer extends ApplicationContainer<
	{},
	IConnectionProfileState
> {
	public Tabs = ConnectionRouter.getProfileTabs();

	public state: IConnectionProfileState = {
		activeTab: ConnectionContainerTabs.Profile.header,
		resource: new ConnectionResource(),
		isEdit: false,
		editStep: 1
	};

	public async componentDidMount() {
		try {
			const profile = await this.state.resource.get(this.getIdParam());
			this.setState({ profile });
		} catch (error) {
			this.setState({ error });
		}
	}

	private goToProfile = (
		profile: GuardianConnectionSubscriptionProfileResponse
	) => {
		const profilePath = ConnectionSubscriptionRouter.getProfileRoute(profile);

		this.goToPath(profilePath);
	};

	public render() {
		const { profile, activeTab, error } = this.state;

		if (error) {
			throw error;
		}

		if (!profile) {
			return <Loading />;
		}

		if (this.state.isEdit) {
			return (
				<TabbedLayout
					activeTab={'Edit'}
					onTabChange={(activeTab) => this.setState({ activeTab })}
					router={ConnectionRouter}
					profile={profile}
				>
					<TabbedLayoutTab header="Edit">
						<ConnectionEditor
							currentStep={this.state.editStep}
							displayMode={!this.state.isEdit}
							profile={profile}
							onEdit={() => this.setState({ isEdit: true, editStep: 1 })}
							onCancel={() => this.setState({ isEdit: false })}
							onUpdate={(profile) => this.setState({ profile, isEdit: false })}
						/>
					</TabbedLayoutTab>
				</TabbedLayout>
			);
		}

		return (
			<TabbedLayout
				activeTab={activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={ConnectionRouter}
				profile={profile}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<ConnectionEditor
						currentStep={1}
						displayMode={!this.state.isEdit}
						profile={this.state.profile}
						onUpdate={(profile) => this.setState({ profile, isEdit: false })}
						onEdit={() => this.setState({ isEdit: true, editStep: 1 })}
						onCancel={() => this.setState({ isEdit: false })}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Rating}>
					<ConnectionEditor
						currentStep={2}
						displayMode={!this.state.isEdit}
						profile={this.state.profile}
						onUpdate={(profile) => this.setState({ profile, isEdit: false })}
						onEdit={() => this.setState({ isEdit: true, editStep: 2 })}
						onCancel={() => this.setState({ isEdit: false })}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.RatePlanManagement}>
					<ConnectionSubscriptionGrid
						resourceParams={{ guardianConnectionId: profile.connectionId }}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					isAction
					rtRoute={RtVueApiRoutes.GuardianConnectionSubscriptions.Create}
					header="Create Rate Plan Record"
				>
					<ConnectionSubscriptionFormEditor
						connectionId={profile.connectionId}
						onSuccess={this.goToProfile}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
