import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	IntegrationErrorMessage,
	IntegrationErrorMessageProfileRequest,
	IntegrationErrorMessageUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export interface IIntegrationErrorMessageConfig {
	label?: string;
	summary?: string;
	isActive?: number;
	isWarning?: number;
}

export class IntegrationErrorMessageResource extends HttpResource<IntegrationErrorMessage> {
	constructor() {
		super(RtAdmApiRoutes.IntegrationErrorMessages);
	}

	public update(
		integrationErrorMessageId: number,
		request: IntegrationErrorMessageUpdateRequest
	) {
		const urlParams: IntegrationErrorMessageProfileRequest = {
			integrationErrorMessageId
		};
		const body = JSON.stringify(request);

		return this.fetchWithRoute<IntegrationErrorMessage>(
			RtAdmApiRoutes.IntegrationErrorMessages.Update,
			{ body, urlParams }
		);
	}
}
