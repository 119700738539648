import {
	RoutingRuleCreateRequest,
	RoutingRuleDeleteResponse,
	RoutingRuleIndexResponse,
	RoutingRuleProfileRequest,
	RoutingRuleProfileResponse,
	RoutingRuleUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtxSipApiRoutes } from 'RtExports/routes';

export class RoutingRuleResource extends HttpResource<
	RoutingRuleIndexResponse,
	RoutingRuleProfileResponse
> {
	constructor() {
		super(RtxSipApiRoutes.RoutingRules);
	}

	public create(createRequest: RoutingRuleCreateRequest) {
		const body = JSON.stringify(createRequest);

		return this.fetchWithRoute<RoutingRuleProfileResponse>(
			RtxSipApiRoutes.RoutingRules.Create,
			{
				body
			}
		);
	}

	public update(routingRuleId: number, routingRule: RoutingRuleUpdateRequest) {
		const urlParams: RoutingRuleProfileRequest = { routingRuleId };
		const body = JSON.stringify(routingRule);

		return this.fetchWithRoute<RoutingRuleProfileResponse>(
			RtxSipApiRoutes.RoutingRules.Update,
			{
				body,
				urlParams
			}
		);
	}

	public delete(routingRuleId: number) {
		const urlParams: RoutingRuleProfileRequest = { routingRuleId };

		return this.fetchWithRoute<RoutingRuleDeleteResponse>(
			RtxSipApiRoutes.RoutingRules.Delete,
			{ urlParams }
		);
	}
}
