/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CpsIndexResponseData = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        hour: {
            type: 'string',
            isRequired: true,
        },
        minute: {
            type: 'string',
            isRequired: true,
        },
        connectionId: {
            type: 'number',
            isRequired: true,
        },
        accountId: {
            type: 'number',
        },
        externalAccountId: {
            type: 'number',
        },
        externalSubAccountId: {
            type: 'number',
        },
        trunkGroupLabel: {
            type: 'string',
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        trunkGroupId: {
            type: 'string',
            isRequired: true,
        },
        direction: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        dialCode: {
            type: 'number',
            isRequired: true,
        },
        rateCenterId: {
            type: 'number',
            isRequired: true,
        },
        mincps: {
            type: 'number',
            isRequired: true,
        },
        maxcps: {
            type: 'number',
            isRequired: true,
        },
        avgcps: {
            type: 'number',
            isRequired: true,
        },
        totalcps: {
            type: 'number',
            isRequired: true,
        },
        fileStreamId: {
            type: 'number',
        },
        ocn: {
            type: 'string',
        },
    },
};