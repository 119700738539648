//import * as React from 'react';
import { RoutePlanIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { RoutePlanResource } from '../resources/RoutePlanResource';

interface IRoutePlanSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<RoutePlanIndexResponse, IsMulti> {
	allowedRoutePlanIds?: number[];
}
interface IRoutePlanSelectState
	extends ISelectFormControlState<RoutePlanIndexResponse> {}

export class RoutePlanSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	RoutePlanIndexResponse,
	IsMulti,
	IRoutePlanSelectProps<IsMulti>,
	IRoutePlanSelectState
> {
	public resourceClass = RoutePlanResource;
	public state: IRoutePlanSelectState = {
		formLabel: 'Route Plan',
		valueKey: 'routePlanId',
		labelKey: 'label'
	};
	/**
	 * @override
	 */
	public async getData() {
		let routePlans = await super.getData();
		const { allowedRoutePlanIds } = this.props;

		if (allowedRoutePlanIds && allowedRoutePlanIds.length > 0) {
			routePlans = routePlans.filter((routePlan) =>
				allowedRoutePlanIds.includes(routePlan.routePlanId)
			);
		}

		return routePlans;
	}
}
