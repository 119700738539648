import { ReleaseCodeIndexResponse } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class ReleaseCodeResource extends ArrayResource<ReleaseCodeIndexResponse> {
	constructor() {
		super('releaseCodeId');

		this.setApiRouteForGetAll(RtxSipApiRoutes.ReleaseCodes.Index);
	}
}
