import {
	IPrecisionFormControlProps,
	PrecisionFormControl
} from 'RtUi/components/form/PrecisionInputFormControl';
import { FC } from 'react';

export const formatToCurrency = (amountStr: string) => {
	const amount = Number(amountStr.replace(/\,/g, ''));
	const usdFormatter = Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});

	const formatted = usdFormatter.format(amount);

	return formatted;
};

export const CurrencyInputFormControl: FC<
	React.PropsWithChildren<IPrecisionFormControlProps>
> = (props) => (
	<PrecisionFormControl
		step={0.01}
		min={0}
		precision={2}
		allowCurrency
		readonlyFormat={(val: string) => formatToCurrency(val)}
		{...props}
	/>
);
