/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CprNodeType {
    Announcement = 'AN',
    AreaCode = 'AC',
    Carrier = 'CA',
    Date = 'DT',
    DayOfWeek = 'DA',
    GoTo = 'GT',
    Lata = 'LT',
    NpaNxx = 'NX',
    Percent = 'PC',
    SixDigit = 'SD',
    State = 'ST',
    Switch = 'SW',
    TenDigit = 'TD',
    TerminatingNumber = 'TE',
    Time = 'TI',
}