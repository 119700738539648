import { FileStreamIndexResponse } from 'RtModels';
import { FileStreamResource } from 'RtUi/app/rtVue/FileStreams/lib/resource/FileStreamResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface FileStreamDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		FileStreamIndexResponse,
		T
	> {
	header?: string;
}

export const FileStreamRenderCache =
	new ResourceCacheMap<FileStreamIndexResponse>(
		new FileStreamResource(),
		'fileStreamId',
		'description'
	);

export const FileStreamDataGridColumn = <T = any,>({
	header = 'Rate Center',
	...config
}: FileStreamDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		header,
		resourceCacheMap: FileStreamRenderCache,
		...config
	});
};
