import { ScenarioAction } from 'RtUi/app/rtSip/Scenarios/lib/resources/ScenarioResource';
import {
	ScenarioActionDial,
	ScenarioActionMenu,
	ScenarioActionGotoScenario,
	ScenarioActionGotoRandomScenario,
	ScenarioActionSetEgressRevenue,
	ScenarioActionPlayTone,
	ScenarioActionTagMatching,
	ScenarioActionType,
	ScenarioActionResourceTagging
} from 'RtModels';
import { IScenarioActionFormProps } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionForm';
import { ScenarioActionMenuForm } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionMenuForm';
import { ScenarioActionDialForm } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionDialForm';
import { ScenarioActionGotoScenarioForm } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionGotoScenarioForm';
import { ScenarioActionSetEgressRevenueForm } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionSetEgressRevenueForm';
import { ScenarioActionPlayToneForm } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionPlayToneForm';
import { ScenarioActionTagMatchingForm } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionTagMatchingForm';
import { ScenarioResourceTaggingForm } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioResourceTaggingForm';

type TScenarioIdMapping = {
	[key in ScenarioActionType]?: {
		name: string;
		isTerminal: boolean;
		isVisible: boolean;
	};
};

/**
 * Helper class for Scenario Actions
 */
export class ScenarioActionUtils {
	/**
	 * Get mapping of ScenarioActionId to Name
	 */
	public getScenarioActionMapping() {
		const scenarioActions: TScenarioIdMapping = {
			1: {
				name: 'Dial',
				isTerminal: true,
				isVisible: true
			},
			2: {
				name: 'Menu',
				isTerminal: false,
				isVisible: true
			},
			4: {
				name: 'Go To Scenario',
				isTerminal: true,
				isVisible: true
			},
			5: {
				name: 'Go To Random Scenario',
				isTerminal: true,
				isVisible: false
			},
			11: {
				name: 'Set Egress Revenue',
				isTerminal: false,
				isVisible: true
			},
			12: {
				name: 'Play Tone',
				isTerminal: false,
				isVisible: true
			},
			13: {
				name: 'Tag Matching',
				isTerminal: false,
				isVisible: true
			},
			14: {
				name: 'Resource Tagging',
				isTerminal: false,
				isVisible: true
			}
		};

		return scenarioActions;
	}

	/**
	 * Get a list of actionIds
	 */
	public getScenarioActionsIds() {
		const scenarioActionIdMappings = this.getScenarioActionMapping();

		const scenarioActionIds: ScenarioActionType[] = Object.keys(
			scenarioActionIdMappings
		).map((scenarioActionId) => Number(scenarioActionId) as ScenarioActionType);

		return scenarioActionIds;
	}

	/**
	 * * Get name of scenario, given a ScenarioActionId
	 * @param scenarioActionId
	 */
	public getScenarioActionName(scenarioActionId: ScenarioActionType): string {
		const scenarioActionsNameMapping = this.getScenarioActionMapping();

		const scenarioAction = scenarioActionsNameMapping[scenarioActionId];

		if (!scenarioAction) {
			return '';
		}

		return scenarioAction.name;
	}

	/**
	 * Is Scenario terminal?
	 * @param scenarioActionId
	 */
	public isTerminal(scenarioActionId: ScenarioActionType) {
		const scenarioActionsNameMapping = this.getScenarioActionMapping();
		const scenarioAction = scenarioActionsNameMapping[scenarioActionId];

		if (!scenarioAction) {
			return false;
		}

		return scenarioAction.isTerminal;
	}

	/**
	 * Is Scenario visible for usage?
	 * @param scenarioActionId
	 */
	public isVisible(scenarioActionId: ScenarioActionType) {
		const scenarioActionsNameMapping = this.getScenarioActionMapping();
		const scenarioAction = scenarioActionsNameMapping[scenarioActionId];

		if (!scenarioAction) {
			return false;
		}

		return scenarioAction.isVisible;
	}

	/**
	 * Gets the correct form for the respective scenarioActionId
	 * @param scenarioActionId
	 */
	public getScenarioActionForm(scenarioActionId: ScenarioActionType) {
		let ScenarioActionForm:
			| React.ComponentClass<IScenarioActionFormProps<ScenarioAction>>
			| undefined;

		if (scenarioActionId === ScenarioActionType.Menu) {
			//@ts-expect-error TODO
			ScenarioActionForm = ScenarioActionMenuForm;
		} else if (scenarioActionId === ScenarioActionType.Dial) {
			//@ts-expect-error TODO
			ScenarioActionForm = ScenarioActionDialForm;
		} else if (scenarioActionId === ScenarioActionType.GotoScenario) {
			//@ts-expect-error TODO
			ScenarioActionForm = ScenarioActionGotoScenarioForm;
		} else if (scenarioActionId === ScenarioActionType.SetEgressRevenue) {
			//@ts-expect-error TODO
			ScenarioActionForm = ScenarioActionSetEgressRevenueForm;
		} else if (scenarioActionId === ScenarioActionType.PlayTone) {
			//@ts-expect-error TODO
			ScenarioActionForm = ScenarioActionPlayToneForm;
		} else if (scenarioActionId === ScenarioActionType.TagMatching) {
			ScenarioActionForm = ScenarioActionTagMatchingForm;
		} else if (scenarioActionId === ScenarioActionType.ResourceTagging) {
			//@ts-expect-error TODO
			ScenarioActionForm = ScenarioResourceTaggingForm;
		}

		return ScenarioActionForm;
	}

	/**
	 * Create a scenario action based on an id
	 */
	public createScenarioAction(
		scenarioId: number,
		scenarioActionId: ScenarioActionType
	): ScenarioAction {
		const dialAction: ScenarioActionDial = {
			scenarioActionId: ScenarioActionType.Dial,
			subscriptionId: -1,
			toPhoneNumber: ''
		};

		if (scenarioActionId === ScenarioActionType.Dial) {
			return dialAction;
		}

		if (scenarioActionId === ScenarioActionType.Menu) {
			const menuAction: ScenarioActionMenu = {
				scenarioActionId: ScenarioActionType.Menu,
				promptSegments: [],
				digits: [],
				timeoutMs: 6000,
				variableName: 'KEYPRESS'
			};

			return menuAction;
		}

		if (scenarioActionId === ScenarioActionType.GotoScenario) {
			const goToAction: ScenarioActionGotoScenario = {
				scenarioActionId: ScenarioActionType.GotoScenario,
				scenarioId: -1
			};

			return goToAction;
		}

		if (scenarioActionId === ScenarioActionType.GotoRandomScenario) {
			const goToRandomAction: ScenarioActionGotoRandomScenario = {
				scenarioActionId: ScenarioActionType.GotoRandomScenario,
				scenarioGroupId: -1
			};

			return goToRandomAction;
		}

		if (scenarioActionId === ScenarioActionType.SetEgressRevenue) {
			const setEgressAction: ScenarioActionSetEgressRevenue = {
				scenarioActionId: ScenarioActionType.SetEgressRevenue,
				subscriptionId: -1,
				toPhoneNumber: ''
			};

			return setEgressAction;
		}

		if (scenarioActionId === ScenarioActionType.PlayTone) {
			const playToneAction: ScenarioActionPlayTone = {
				scenarioActionId: ScenarioActionType.PlayTone,
				tone: 'ring',
				durationMs: 2200
			};

			return playToneAction;
		}

		if (scenarioActionId === ScenarioActionType.TagMatching) {
			const tagMatchingAction: ScenarioActionTagMatching = {
				scenarioActionId: ScenarioActionType.TagMatching
			};

			return tagMatchingAction;
		}

		if (scenarioActionId === ScenarioActionType.ResourceTagging) {
			const resourceTaggingAction: ScenarioActionResourceTagging = {
				scenarioActionId: ScenarioActionType.ResourceTagging,
				scenarioId,
				resourceConfigurationId: -1
			};

			return resourceTaggingAction;
		}

		return dialAction;
	}
}
