/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum AsrSummaryColumns {
    CustomerAccountId = 'customerAccountId',
    CustomerSubscriptionId = 'customerSubscriptionId',
    ExtendedStats = 'extendedStats',
    VendorAccountId = 'vendorAccountId',
    VendorSubscriptionId = 'vendorSubscriptionId',
    CustomerRemoteIp = 'customerRemoteIp',
    CustomerResult = 'customerResult',
    CustomerLocalIp = 'customerLocalIp',
    VendorRemoteIp = 'vendorRemoteIp',
    VendorResult = 'vendorResult',
    VendorLocalIp = 'vendorLocalIp',
    CustomerRate = 'customerRate',
    CustomerRateKey = 'customerRateKey',
    VendorRate = 'vendorRate',
    VendorRateKey = 'vendorRateKey',
    CallingPartyCountryIsoId = 'callingPartyCountryIsoId',
    CallingPartyRateCenterId = 'callingPartyRateCenterId',
    DialedNumberCountryIsoId = 'dialedNumberCountryIsoId',
    DialedNumberRateCenterId = 'dialedNumberRateCenterId',
}