import moment from 'moment';
import { useMemo, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import {
	GuardianAlertHistoryIndexResponse,
	GuardianAlertTypeIndexResponse
} from 'RtModels';
import { AlertHistoryRouter } from 'RtUi/app/rtGuardian/AlertHistory/AlertHistory.router';
import { AlertHistoryNotesControl } from 'RtUi/app/rtGuardian/AlertHistory/lib/controls/AlertHistoryNotesControl';
import { AlertHistoryFailureDataGrid } from 'RtUi/app/rtGuardian/AlertHistory/lib/grids/AlertHistoryFailureDataGrid';
import { AlertHistoryNoteResource } from 'RtUi/app/rtGuardian/AlertHistory/lib/resources/AlertHistoryNoteResource';
import {
	useGetGuardianAlertHistory,
	useGetGuardianAlertHistoryNotes
} from 'RtUi/app/rtGuardian/AlertHistory/lib/services';
import { AlertTypeSelect } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/AlertTypeSelect';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { RtError } from 'RtUi/utils/errors/RtError';

export const AlertHistoryProfileContainer = (): JSX.Element => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<RtError>();
	const [alertTypeSelect, setAlertTypeSelect] =
		useState<GuardianAlertTypeIndexResponse>();

	const id = useIdParam();

	const { data, isLoading } = useGetGuardianAlertHistory(Number(id));

	const alertHistory = useMemo(() => data?.data, [data]);

	const {
		data: notes,
		isLoading: isLoadingNotes,
		refetch: refetchNotes
	} = useGetGuardianAlertHistoryNotes(Number(id));

	if (isLoading || isLoadingNotes) {
		return <Loading />;
	}

	if (!alertHistory) {
		return <p>Invalid alert history id</p>;
	}

	const onNoteCreate = async (note: string) => {
		if (!note.trim()) {
			return false;
		}

		setError(undefined);
		setIsSubmitting(true);

		const guardianAlertHistoryId = Number(id);

		try {
			await new AlertHistoryNoteResource(guardianAlertHistoryId).create({
				guardianAlertHistoryId,
				note
			});

			await refetchNotes();
			return true;
		} catch (error: any) {
			setError(new RtError(error));
		} finally {
			setIsSubmitting(false);
		}

		return false;
	};

	return (
		<StandardLayout<GuardianAlertHistoryIndexResponse>
			router={AlertHistoryRouter}
			profile={alertHistory}
		>
			<Row style={{ height: '100%' }}>
				<Col lg={8} className="mb-3">
					<Row>
						<RtUiForm
							hideButtons={true}
							displayMode={true}
							isSubmitting={isSubmitting}
							error={error}
						>
							<Row>
								<Col md={6}>
									<InputFormControl
										autoHeight
										displayMode={true}
										label="Summary"
										value={alertHistory.summary ?? undefined}
									/>
								</Col>
								<Col md={6}>
									<InputFormControl
										autoHeight
										displayMode={true}
										label="Lookup Info"
										value={alertHistory.lookupInfo ?? undefined}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<InputFormControl
										autoHeight
										displayMode={true}
										label="Alert Key"
										value={alertHistory.alertKey ?? ''}
									/>
								</Col>
								<Col md={6}>
									<AlertTypeSelect
										autoHeight
										displayMode={true}
										label="Alert Type"
										value={alertTypeSelect}
										onChange={setAlertTypeSelect}
										showSystemAlerts
										initialOptionId={alertHistory.alertRuleId.toString()}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<DatetimeFormControl
										label="Start Time"
										displayMode={true}
										value={moment(alertHistory.startTs)}
									/>
								</Col>
								<Col md={6}>
									<DatetimeFormControl
										label="Last Error Time"
										displayMode={true}
										value={moment(alertHistory.lastTs)}
									/>
								</Col>
							</Row>
						</RtUiForm>
					</Row>
					<Row className="mt-4">
						<Card>
							<Card.Body className="ps-0 pe-0">
								<AlertHistoryFailureDataGrid
									data={alertHistory.failureData}
									totalCount={data?.totalCount}
								/>
							</Card.Body>
						</Card>
					</Row>
				</Col>
				<Col lg={4}>
					<Card>
						<Card.Header>
							<b>Notes</b>
						</Card.Header>
						<AlertHistoryNotesControl
							isSubmitting={isSubmitting}
							notes={notes || []}
							onNoteCreate={onNoteCreate}
						/>
					</Card>
				</Col>
			</Row>
		</StandardLayout>
	);
};

AlertHistoryProfileContainer.displayName = 'AlertHistoryProfileContainer';

AlertHistoryRouter.setProfileRtUiFunctionalComponent(
	AlertHistoryProfileContainer
);
