import {
	isValidFqdn,
	isValidIpAddress
} from 'RtUi/app/AccountManagement/Switches/lib/utils';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn,
	FallbackHeaderDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

type FqdnDataGridColumnConfiguration<T> = Omit<
	DataGridColumnConfiguration<T>,
	'header'
> &
	FallbackHeaderDataGridColumnConfiguration<T> & {
		fullSupport?: boolean;
	};

export const FqdnDataGridColumn = <T = any,>({
	header = 'Fqdn',
	fullSupport = true,
	...config
}: FqdnDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: string) => {
		if ((fullSupport && !isValidFqdn(value)) || !isValidIpAddress(value)) {
			return 'Invalid FQDN';
		}

		return value;
	};

	return DefaultDataGridColumn({
		header,
		...config,
		exportValue: (value: any) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<string>())
	});
};
