import { RatePlanDefaultsForm } from 'RtUi/app/AccountManagement/RatePlan/RatePlanDefaults/form';
import { RatePlanDefaultsCreateForm } from 'RtUi/app/AccountManagement/RatePlan/RatePlanDefaults/form/CreateForm';
import {
	deleteRatePlanDefaults,
	postRatePlanDefault,
	putRatePlanDefaults,
	useGetRatePlanDefaults
} from 'RtUi/app/AccountManagement/RatePlan/lib/service';
import { Aside } from 'RtUi/components/ui/Aside';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

interface RatePlanDefaultsProps {
	id: number;
}

export const RatePlanDefaults = (props: RatePlanDefaultsProps) => {
	const { id } = props;

	const { data, isLoading, error, refetch } = useGetRatePlanDefaults({
		ratePlanDefaultId: id
	});
	const [modalOpen, setModalOpen] = useState(false);
	const [displayMode, setDisplayMode] = useState(true);
	const { mutateAsync: createRatePlanDefault, isLoading: loadingCreate } =
		useMutation(postRatePlanDefault);
	const { mutateAsync: updateRatePlanDefault, isLoading: loadingUpdate } =
		useMutation(putRatePlanDefaults);
	const { mutateAsync: deleteRatePlanDefault } = useMutation(
		deleteRatePlanDefaults
	);

	const handleDelete = async () => {
		try {
			await deleteRatePlanDefault({ ratePlanDefaultId: id });
			toast.success('Successfully deleted rate plan default');
			await refetch();
		} catch (e) {
			toast.error('Error on deleting rate plan default');
		}
	};

	const handleEdit = async (editedData: any) => {
		if (!data) {
			return;
		}

		try {
			await updateRatePlanDefault({
				urlParams: { ratePlanDefaultId: data.ratePlanDefaultId },
				bodyParams: editedData
			});
			setDisplayMode(true);
			toast.success('Successfully updated rate plan default');
			await refetch();
		} catch (e) {
			toast.error('Error on updating rate plan default');
		}
	};

	const handleCreate = async (createdData: any) => {
		const body = { ...createdData, ratePlanId: id };
		try {
			await createRatePlanDefault(body);
			toast.success('Successfully created rate plan default');
			setModalOpen(false);
			await refetch();
		} catch (e) {
			toast.error('Error on creating rate plan default');
		}
	};

	return (
		<>
			{!isLoading && (
				<RatePlanDefaultsForm
					error={error}
					data={data as any}
					displayMode={displayMode}
					setIsEditing={setDisplayMode}
					onSubmit={handleEdit}
					isLoading={loadingUpdate}
					setModalOpen={setModalOpen}
					handleDelete={handleDelete}
				/>
			)}
			<Aside onClickOutside={() => setModalOpen(false)} isOpen={modalOpen}>
				<RatePlanDefaultsCreateForm
					onSubmit={handleCreate}
					isLoading={loadingCreate}
				/>
			</Aside>
		</>
	);
};
