import { RtCommonApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { BillingEntityIndexResponse } from 'RtModels';

export class BillingEntityResource extends ArrayResource<BillingEntityIndexResponse> {
	constructor() {
		super('billingEntityId');

		this.setApiRouteForGetAll(RtCommonApiRoutes.BillingEntity.Index);
	}
}
