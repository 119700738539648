import { CdrExportControlIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { RecurringExportDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RecurringExportDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const getRecurringExportColumns = (): Array<
	DataGridColumn<CdrExportControlIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'cdrExportControlId',
		header: 'Id'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DefaultDataGridColumn({
		accessorKey: 'cdrSourceTypeName',
		header: 'Source'
	}),
	RecurringExportDataGridColumn({
		accessorKey: 'cdrSourceValue',
		header: 'Source Value',
		cdrSourceTypeId: (row) => row.cdrSourceTypeId
	}),
	DefaultDataGridColumn({
		accessorKey: 'cdrExportTypeName',
		header: 'Export Type'
	}),
	DefaultDataGridColumn({
		accessorKey: 'serviceName',
		header: 'Service'
	}),
	DefaultDataGridColumn({
		accessorKey: 'cdrExportIntervalName',
		header: 'Interval'
	}),
	DefaultDataGridColumn({
		accessorKey: 'cdrRetentionName',
		header: 'Retention'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isConnected',
		header: 'Connected'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastAttemptTs',
		header: 'Last Attempt'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastSuccessTs',
		header: 'Last Success'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'nextRunTimeTs',
		header: 'Next Run'
	})
];
