import Rheostat from 'rheostat';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from 'RtUi/components/form/FormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';

interface ISliderFormControlProps extends IFormControlProps<number> {
	onChange: (value: number) => void;
	value: number;
}

export class SliderFormControl extends FormControl<
	number,
	false,
	ISliderFormControlProps
> {
	public resource = undefined;
	public state: IFormControlState = {
		formLabel: ''
	};

	public render() {
		return (
			<ControlGroup
				displayMode={this.props.displayMode}
				label={`${this.props.label}`}
				sublabel={`${this.props.value.toString() + '%'}`}
				sublabelClassName="text-dark"
				labelClassName="d-flex justify-content-between"
			>
				<Rheostat
					disabled={this.props.disabled || this.props.displayMode}
					onValuesUpdated={(sliderState) => {
						this.props.onChange(sliderState.values[0]);
					}}
					values={[this.props.value]}
				/>
			</ControlGroup>
		);
	}
}
