import { MessagesDetailIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { CostDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CostDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const getMessagesDetailColumns = (): Array<
	DataGridColumn<MessagesDetailIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'vuid',
		header: 'vuid',
		hiddenIfEmpty: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'sentTs',
		header: 'Sent Ts',
		hiddenIfEmpty: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'completedTs',
		header: 'Completed Ts',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'frStd',
		header: 'ANI E164',
		hiddenIfEmpty: true
	}),
	PhoneNumberDataGridColumn({
		accessorKey: 'frRaw',
		header: 'ANI Raw',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'toStd',
		header: 'DN E164',
		hiddenIfEmpty: true
	}),
	PhoneNumberDataGridColumn({
		accessorKey: 'toRaw',
		header: 'DN Raw',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customSmsField1',
		header: 'Custom Sms Field 1',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'customSmsField2',
		header: 'Custom Sms Field 2',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'direction',
		header: 'Direction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'jurisdiction',
		header: 'Jurisdiction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'messageStatus',
		header: 'Message Status',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'messageStatusCode',
		header: 'Message Status Code',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalSegments',
		header: 'Total Segments',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'segmentNumber',
		header: 'Segment Number',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'messageLength',
		header: 'Message Length',
		hiddenIfEmpty: true
	}),
	BooleanDataGridColumn({
		accessorKey: 'billable',
		header: 'Billable',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'campaignId',
		header: 'Campaign Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'campaignClass',
		header: 'Campaign Class',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dlrCode',
		header: 'Dlr Code',
		hiddenIfEmpty: true
	}),
	IdDataGridColumn({
		accessorKey: 'locationId',
		header: 'Location Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'carrierName',
		header: 'Carrier Name',
		hiddenIfEmpty: true
	}),
	IdDataGridColumn({
		accessorKey: 'brandId',
		header: 'Brand Id',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'customerAccountId',
		header: 'Customer Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'customerSubscriptionId',
		header: 'Customer Subscription',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'customerExternalAccountId',
		header: 'Customer External Account',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'customerExternalSubAccountId',
		header: 'Customer External Sub Account',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorAccountId',
		header: 'Vendor Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'vendorSubscriptionId',
		header: 'Vendor Subscription',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorExternalAccountId',
		header: 'Vendor External Account',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorExternalSubAccountId',
		header: 'Vendor External Sub Account',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'ingressRateCenterId',
		header: 'Ingress Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressCountry',
		header: 'Ingress Country',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		accessorKey: 'egressRateCenterId',
		header: 'Egress Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressCountry',
		header: 'Egress Country',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue',
		header: 'Revenue',
		decimals: 4,
		hiddenIfEmpty: true
	}),
	RateDataGridColumn({
		accessorKey: 'revenueRate',
		header: 'Revenue Rate',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'revenueRateKey',
		header: 'Revenue Rate Key',
		hiddenIfEmpty: true
	}),
	CostDataGridColumn({
		accessorKey: 'cost',
		header: 'Cost',
		hiddenIfEmpty: true
	}),
	RateDataGridColumn({
		accessorKey: 'costRate',
		header: 'Cost Rate',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'costRateKey',
		header: 'Cost Rate Key',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'margin',
		header: 'Margin',
		decimals: 4,
		hiddenIfEmpty: true
	})
];
