import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { isArray } from 'lodash-es';
import { Badge } from 'react-bootstrap';

interface SubscriptionTagsDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const SubscriptionTagsDataGridColumn = <T = any,>({
	size = 150,
	...config
}: SubscriptionTagsDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const renderValue = (value: string[]) => {
		return (
			<section className="d-flex flex-wrap gap-1">
				{value?.map((val) => (
					<Badge key={val} pill bg="secondary">
						{val}
					</Badge>
				))}
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		enableGlobalFilter: true,
		globalFilterFn: (value, filterValue) => {
			if (!isArray(value)) {
				return (
					String(value).toLowerCase() === String(filterValue).toLowerCase()
				);
			}

			return value.some(
				(curr) =>
					String(curr).toLowerCase() === String(filterValue).toLowerCase()
			);
		},
		filterFn: (row, id, filterValue) => {
			const value = row.getValue(id);

			if (!isArray(value)) {
				return (
					String(value).toLowerCase() === String(filterValue).toLowerCase()
				);
			}

			return value.some(
				(curr) =>
					String(curr).toLowerCase() === String(filterValue).toLowerCase()
			);
		},
		exportValue: (value: T[keyof T]) => (value as string[])?.join(', '),
		getValue: ({ cell }) => renderValue(cell.getValue<string[]>())
	});
};
