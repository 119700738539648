import { CprErrorType, CprRow } from 'Somos/lib/SomosCpr/RtCprV2';
import { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import clsx from 'clsx';
import { KeyboardArrowDown } from '@mui/icons-material';

interface ICprRowIndexDataGridButtonProps {
	cprRow: CprRow;
	index: number;
}

export const CprRowIndexDataGridButton = ({
	cprRow,
	index
}: ICprRowIndexDataGridButtonProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { cpr } = cprRow;

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				className={clsx({
					'text-danger border-danger': cprRow.hasErrors(CprErrorType.Error),
					'text-warning border-warning': cprRow.hasErrors(CprErrorType.Warning)
				})}
				endIcon={<KeyboardArrowDown />}
				onClick={handleClick}
			>
				{index + 1}
			</Button>
			<Menu
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				disableScrollLock
			>
				<MenuItem
					onClick={() => {
						cpr.makeCprRow(index, true, cprRow);
					}}
				>
					<i className="fas fa-fw fa-arrow-up" />
					&nbsp;&nbsp;Add Row Above
				</MenuItem>
				<MenuItem
					onClick={() => {
						cpr.makeCprRow(index, true, cprRow);
					}}
				>
					<i className="fas fa-fw fa-arrow-down" />
					&nbsp;&nbsp;Add Row Below
				</MenuItem>
				<MenuItem onClick={() => cpr.deleteCprRow(index)}>
					<i className="fas fa-fw fa-trash-alt" />
					&nbsp;&nbsp;Delete Row
				</MenuItem>
			</Menu>
		</>
	);
};
