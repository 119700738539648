import { noop } from 'lodash-es';
import { createContext } from 'react';
import { FieldErrors } from 'react-hook-form';

type Errors = Record<'root' | string, any>;

interface IRtxFormContext {
	errors: FieldErrors<Errors>;
	setError: (name: string, message: string) => void;
	clearFormErrors: (name?: string) => void;
}

export const RtxFormContext = createContext<IRtxFormContext>({
	errors: {},
	setError: noop,
	clearFormErrors: noop
});
