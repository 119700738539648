import {
	ConnectionIndexResponse,
	GuardianConnectionSubscriptionCreateRequest,
	GuardianConnectionSubscriptionProfileResponse,
	GuardianConnectionSubscriptionUpdateRequest,
	GuardianConnectionUpdateOptions
} from 'RtModels';
import { ConnectionSubscriptionResource } from 'RtUi/app/rtVue/ConnectionSubscription/lib/resources/ConnectionSubscriptionResource';
import {
	CallTypesSelect,
	ICallTypeSelectOption
} from 'RtUi/app/rtVue/Connections/lib/controls/CallTypesSelect';
import { VueConnectionSelect } from 'RtUi/app/rtVue/Connections/lib/controls/VueConnectionSelect';
import { ConnectionEditor } from 'RtUi/app/rtVue/Connections/lib/forms/ConnectionEditor';
import { DirectionFormControl } from 'RtUi/app/rtVue/common/lib/controls/DirectionFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';
import moment from 'moment';
import { useState } from 'react';
import { Alert, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

interface IVueConnectionEditorFormProps {
	value?: GuardianConnectionSubscriptionProfileResponse;
	createMode?: boolean;
	onCancel?: () => void;
	onSuccess?: () => void;
	subscriptionId: number;
}

export const VueConnectionEditorForm = ({
	value,
	createMode = false,
	subscriptionId,
	onSuccess = () => {},
	onCancel = () => {}
}: IVueConnectionEditorFormProps): JSX.Element => {
	const [vueConnection, setVueConnection] = useState<ConnectionIndexResponse>();
	const [showCreateConnectionModal, setShowCreateConnectionModal] =
		useState<boolean>(false);
	const [updateOption, setUpdateOption] =
		useState<GuardianConnectionUpdateOptions>(
			GuardianConnectionUpdateOptions.ThisTrunkGroupOnly
		);
	const [callType, setCallType] = useState<ICallTypeSelectOption>();
	const [error, setError] = useState<RtError>();
	const {
		control,
		formState: { isSubmitting },
		handleSubmit,
		setValue
	} = useForm<GuardianConnectionSubscriptionProfileResponse>({
		defaultValues: {
			...value,
			subscriptionId
		}
	});

	const submitHandler = async (
		data: GuardianConnectionSubscriptionProfileResponse
	) => {
		const resource = new ConnectionSubscriptionResource();

		try {
			setError(undefined);
			const payload:
				| GuardianConnectionSubscriptionCreateRequest
				| GuardianConnectionSubscriptionUpdateRequest = {
				...data,
				updateOption
			};

			if (createMode) {
				await resource.create(
					payload as GuardianConnectionSubscriptionCreateRequest
				);
			} else {
				await resource.update(
					data.guardianConnectionSubscriptionId,
					payload as GuardianConnectionSubscriptionUpdateRequest
				);
			}

			onSuccess();
		} catch (e: any) {
			setError(new RtError(e));
		}
	};

	return (
		<>
			<Modal show={showCreateConnectionModal} size="lg">
				<Modal.Header>
					<Modal.Title>Create new connection</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<ConnectionEditor<true>
						col={12}
						onUpdate={([profile]) => {
							setValue('guardianConnectionId', profile?.connectionId);
							setShowCreateConnectionModal(false);
						}}
						onCancel={() => setShowCreateConnectionModal(false)}
					/>
				</Modal.Body>
			</Modal>
			<RtUiForm
				isSubmitting={isSubmitting}
				onSubmit={handleSubmit(submitHandler)}
				error={error}
				onCancel={onCancel}
			>
				<InputFormControl
					label="Subscription ID"
					required
					type="number"
					displayMode
					value={String(subscriptionId)}
				/>
				<Controller
					control={control}
					name="guardianConnectionId"
					render={({ field: { onChange, value } }) => (
						<div className="d-flex gap-1 align-items-end">
							<div className="flex-grow-1">
								<VueConnectionSelect
									value={vueConnection}
									required
									onChange={(val) => {
										setVueConnection(val);
										onChange(val.connectionId);
									}}
									initialOptionId={value?.toString()}
								/>
							</div>
							<OverlayTrigger
								overlay={(props) => (
									<Tooltip id={`connection-tooltip`} {...props}>
										Create new connection
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										onClick={() => setShowCreateConnectionModal(true)}
										variant="white"
										className="mb-3"
										id="connection"
									>
										<i className="fas fa-fw fa-plus fa-lg" />
									</Button>
								)}
							</OverlayTrigger>
						</div>
					)}
				/>
				<Controller
					control={control}
					name="callType"
					render={({ field: { onChange, value } }) => (
						<CallTypesSelect
							required
							onChange={(val) => {
								setCallType(val);
								onChange(val.callTypeId);
							}}
							clearable={true}
							value={callType}
							initialOptionId={value?.toString()}
						/>
					)}
				/>
				<Controller
					control={control}
					name="directionId"
					render={({ field: { onChange, value } }) => (
						<DirectionFormControl
							callType={callType?.callTypeId}
							onChange={onChange}
							value={value}
							required
						/>
					)}
				/>
				<Controller
					control={control}
					name="startTs"
					render={({ field: { onChange, value } }) => (
						<DatetimeFormControl
							required
							showDateOnly
							label="Start Date"
							onChange={(val) => onChange(val.toDate())}
							value={moment(value)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="endTs"
					render={({ field: { onChange, value } }) => (
						<DatetimeFormControl
							showDateOnly
							label="End Date"
							initAsNow={false}
							onChange={(val) => onChange(val?.toDate())}
							value={value ? moment(value) : moment().endOf('day')}
						/>
					)}
				/>
				{!createMode && (
					<Controller
						control={control}
						name="isActive"
						render={({ field: { onChange, value } }) => (
							<IsActiveRadioFormControl
								hideBothOption={true}
								label="Active"
								onChange={onChange}
								value={value}
							/>
						)}
					/>
				)}
				<Alert variant="warning">
					<RadioFormControl<GuardianConnectionUpdateOptions>
						label="Apply Changes To"
						value={updateOption}
						required
						onChange={(val) => setUpdateOption(val)}
						options={[
							{
								value: GuardianConnectionUpdateOptions.ThisTrunkGroupOnly,
								label: 'This trunk group only'
							},
							{
								value:
									GuardianConnectionUpdateOptions.AllTrunkGroupsInThisSwitch,
								label:
									'All trunk groups in this switch with this trunk group number'
							},
							{
								value: GuardianConnectionUpdateOptions.AllTrunkGroups,
								label:
									'All trunk groups with this number across ALL switches (dangerous)'
							}
						]}
					/>
				</Alert>
			</RtUiForm>
		</>
	);
};
