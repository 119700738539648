/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ReportDataColumnPresets {
    AllColumns = 'allColumns',
    IngressDetail = 'ingressDetail',
    EgressDetail = 'egressDetail',
}