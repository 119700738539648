import * as React from 'react';
import { mapPriorityToLabel, PriorityOptions } from 'RtUi/utils/maps';
import { ControlGroup } from '../../ui/ControlGroup';
import { RadioButtons } from '../../ui/RadioButtons';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from '../FormControl';

export interface IPriorityRadioButtons
	extends IFormControlProps<number, false> {
	useDefaultValue?: boolean;
	vertical?: boolean;
}

export class PriorityRadioButtons extends FormControl<
	number,
	false,
	IPriorityRadioButtons
> {
	public resource = undefined;
	public state: IFormControlState = {
		formLabel: 'Priority'
	};

	public componentDidMount() {
		if (this.props.useDefaultValue && !this.props.value) {
			this.triggerOnChange(PriorityOptions[1].value);
		}
	}

	public render() {
		const { formLabel } = this.state;
		const { displayMode } = this.props;
		const value: string | number | undefined = this.props.value;
		let displayValue: React.ReactNode = String(value);

		if (displayMode) {
			displayValue = mapPriorityToLabel(value);
		}

		return (
			<ControlGroup
				label={formLabel}
				value={displayValue}
				displayMode={displayMode}
				required={this.props.required}
			>
				<br />
				<RadioButtons<number>
					vertical={this.props.vertical}
					options={PriorityOptions}
					name={this.props.name}
					required={this.props.required}
					value={this.props.value}
					onChange={(value) => this.triggerOnChange(value)}
				/>
			</ControlGroup>
		);
	}
}
