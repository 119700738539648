import { Rt800ApiRoutes } from 'RtExports/routes';
import { LcoApplicationRouter } from 'RtUi/app/rtLco/rtLco.router';
import { IRouterBreadcrumb } from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { LcrTaskContainerTabs, LcrTaskRouter } from '../Tasks/LcrTask.router';
import {
	LcrTaskResultIndexResponse,
	LcrTaskResultProfileResponse
} from 'RtModels';
import { LcrTasksResource } from 'RtUi/app/rtLco/Tasks/lib/resources/LcrTasksResource';

interface ILcrTaskResultContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Activate: TabbedLayoutTabProps;
}

export const LcrTaskResultContainerTabs: ILcrTaskResultContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Activate: {
		header: 'Activate',
		rtRoute: Rt800ApiRoutes.LcrTaskCarrierResultsActivate.Create,
		isAction: true
	}
};

export class LcrTaskResultRouterClass extends LcoApplicationRouter<
	LcrTaskResultIndexResponse,
	LcrTaskResultProfileResponse,
	ILcrTaskResultContainerTabs
> {
	constructor() {
		super(
			'LCO Task Result',
			'lcrTaskResults',
			'lcrTaskResultId',
			LcrTaskResultContainerTabs
		);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.LcrTaskResults);
	}

	public getProfileRouteWithParamNames(prefixSectionKey?: boolean) {
		return this.getProfileRouteWithIds(
			':lcrTaskId',
			':id',
			undefined,
			prefixSectionKey
		);
	}

	public getProfileRouteWithIds(
		lcrTaskId: number | string = ':lcrTaskId',
		lcrTaskResultId: number | string = ':id',
		tabId?: string,
		prefixSectionKey = true
	) {
		const lcrResultProfileRoute = super.getIndexRoute(prefixSectionKey);

		return this.getRouteWithTab(
			`${lcrResultProfileRoute}/${lcrTaskId}/${lcrTaskResultId}`,
			tabId
		);
	}

	public getProfileRoute(
		record: LcrTaskResultIndexResponse,
		tabId?: string,
		prefixSectionKey = true
	) {
		return this.getProfileRouteWithIds(
			record.lcrTaskId,
			record.lcrTaskResultId,
			tabId,
			prefixSectionKey
		);
	}

	public getIndexRoute(
		prefixSectionKey = true,
		tabId?: string,
		lcrTaskId?: number
	) {
		if (!lcrTaskId) {
			lcrTaskId = this.getLcrTaskIdFromCurrentPath();

			if (!lcrTaskId) {
				throw new Error('lcrTaskId is required!');
			}
		}

		if (!tabId) {
			tabId = LcrTaskContainerTabs.Results.header;
		}

		return LcrTaskRouter.getProfileRoute(lcrTaskId, tabId, prefixSectionKey);
	}

	/**
	 * @override
	 */
	public async getBreadcrumbs(
		currentPath: string,
		profile?: LcrTaskResultProfileResponse
	): Promise<IRouterBreadcrumb[]> {
		const lcrTaskId = this.getLcrTaskIdFromCurrentPath();

		const lcrTaskResource = new LcrTasksResource();
		const lcrTask = await lcrTaskResource.get(lcrTaskId);

		const breadcrumbs = [
			this.getSectionBreadcrumb(),
			LcrTaskRouter.getIndexBreadcrumb(),
			LcrTaskRouter.getProfileBreadcrumb(
				String(lcrTaskId),
				lcrTask,
				false,
				'Results'
			),
			this.getProfileBreadcrumb(String(profile?.lcrTaskResultId), profile, true)
		];

		return breadcrumbs;
	}

	public getProfileLabel(profile: LcrTaskResultProfileResponse) {
		return `Result ID (${String(profile.lcrTaskResultId)})`;
	}

	protected getLcrTaskIdFromCurrentPath() {
		const currentPath = location.pathname;
		const profilePath = this.getProfileMatch<{
			id: string;
			lcrTaskId: string;
		}>(currentPath);
		let lcrTaskId: number | undefined;

		if (profilePath) {
			const { lcrTaskId: lctTaskIdStr } = profilePath.params;
			lcrTaskId = Number(lctTaskIdStr);
		}

		return lcrTaskId;
	}
}

export const LcrTaskResultRouter = new LcrTaskResultRouterClass();
