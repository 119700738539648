import * as React from 'react';
import { ScenarioSelect } from 'RtUi/app/rtSip/Scenarios/lib/controls/ScenarioSelect';
import {
	ScenarioActionForm,
	IScenarioActionFormState
} from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { PromptSelect } from 'RtUi/app/rtSip/PromptSegments/lib/controls/PromptSelect';
import { Aside } from 'RtUi/components/ui/Aside';
import { PromptEditor } from 'RtUi/app/rtSip/PromptSegments/lib/forms/PromptEditor';
import {
	DigitScenario,
	ScenarioIndexResponse,
	ScenarioActionMenu,
	PromptIndexResponse,
	PromptProfileResponse
} from 'RtModels';
import { Col, Row, Card, Button, InputGroup, Form } from 'react-bootstrap';

interface IScenarioActionMenuFormState extends IScenarioActionFormState {
	showUploadNewSegmentAside: boolean;
	prompts: PromptIndexResponse[];
	scenarios: Map<string, ScenarioIndexResponse>;
}

export class ScenarioActionMenuForm extends ScenarioActionForm<
	ScenarioActionMenu,
	IScenarioActionMenuFormState
> {
	public state: IScenarioActionMenuFormState = {
		prompts: [],
		scenarios: new Map(),
		showUploadNewSegmentAside: false
	};

	public setUploadNewSegmentAside(showUploadNewSegmentAside: boolean) {
		const updateRequired =
			showUploadNewSegmentAside !== this.state.showUploadNewSegmentAside;

		if (!updateRequired) {
			return;
		}

		this.setState({ showUploadNewSegmentAside });
	}

	public updateTimeoutMs(timeoutMs: number | null) {
		const { action } = this.props;

		if (timeoutMs !== null) {
			action.timeoutMs = timeoutMs;
		}

		this.props.onChange(action);
	}

	public updateVarName(variableName: string) {
		const { action } = this.props;

		action.variableName = variableName;

		this.props.onChange(action);
	}

	public updateDigitScenario(
		scenario: ScenarioIndexResponse | null,
		digit: string
	) {
		const { scenarios } = this.state;
		const { action } = this.props;
		action.digits = action.digits ?? [];
		const foundActionDigitIndex = action.digits.findIndex(
			(actionDigit) => actionDigit.digit === digit
		);

		if (scenario) {
			const { scenarioId } = scenario;

			if (foundActionDigitIndex >= 0) {
				action.digits[foundActionDigitIndex].scenarioId = scenarioId;
			} else {
				action.digits.push({ digit, scenarioId });
			}
		} else if (foundActionDigitIndex >= 0) {
			action.digits.splice(foundActionDigitIndex, 1);
		}

		if (scenario === null) {
			scenarios.delete(digit);
		} else {
			scenarios.set(digit, scenario);
		}

		this.props.onChange(action);

		this.setState({ scenarios });
	}

	public addSegment(prompt?: PromptIndexResponse) {
		const { action } = this.props;
		const { prompts } = this.state;

		action.promptSegments.push({
			segmentTypeId: 1,
			fileId: prompt?.promptId ?? (undefined as unknown as number)
		});

		if (prompt) {
			const segmentIndex = action.promptSegments.length - 1;

			prompts[segmentIndex] = prompt;

			this.setState({ prompts });
		}

		this.props.onChange(action);
	}

	public updateSegment(
		prompt: PromptIndexResponse | undefined,
		segmentIndex: number
	) {
		const { prompts } = this.state;
		const { action } = this.props;

		//TODO?
		action.promptSegments[segmentIndex].fileId = prompt?.promptId ?? 0;

		if (prompt) {
			prompts[segmentIndex] = prompt;
		} else {
			delete prompts[segmentIndex];
		}

		this.setState({ prompts });

		this.props.onChange(action);
	}

	public deleteSegment(segmentIndex: number) {
		const { prompts } = this.state;
		const { action } = this.props;

		action.promptSegments.splice(segmentIndex, 1);

		delete prompts[segmentIndex];

		this.setState({ prompts });

		this.props.onChange(action);
	}

	public moveSegment(fromIndex: number, toIndex: number) {
		this.setState({
			prompts: this.moveElement(this.state.prompts, fromIndex, toIndex)
		});

		this.props.onChange({
			...this.props.action,
			promptSegments: this.moveElement(
				this.props.action.promptSegments,
				fromIndex,
				toIndex
			)
		});
	}

	public onPromptCreate(newProfile: PromptProfileResponse) {
		this.addSegment(newProfile);

		this.setUploadNewSegmentAside(false);
	}

	public render(): React.ReactNode {
		const { action } = this.props;
		const allDigits = '0123456789*#';
		const digitKeys: DigitScenario[] = allDigits.split('').map((digit) => {
			if (action.digits) {
				const foundDigit = action.digits.find(
					(actionDigit) => actionDigit.digit === digit
				);

				if (foundDigit) {
					return foundDigit;
				}
			}

			return { digit, scenarioId: -1 };
		});

		return (
			<section>
				<Row>
					<Col lg>
						<PrecisionFormControl
							label="Timeout in Milliseconds"
							required
							precision={0}
							displayMode={this.props.displayMode}
							onChange={(timeoutMs) => this.updateTimeoutMs(timeoutMs)}
							value={action.timeoutMs}
						/>
					</Col>
					<Col lg>
						<InputFormControl
							label="Variable Name"
							required
							displayMode={this.props.displayMode}
							disabled
							onChange={(varName) => this.updateVarName(varName)}
							value={action.variableName}
						/>
					</Col>
				</Row>
				{(action.promptSegments.length > 0 || !this.props.displayMode) && (
					<h6 className="text-muted">
						<b>Prompt Segments</b>
					</h6>
				)}
				{action.promptSegments.map((prompt, index) => (
					<Row
						key={`${String(prompt.fileId)}-${String(
							this.props.displayMode
						)}-${index}`}
					>
						<Col lg={12}>
							<Form.Group className="mb-3">
								<InputGroup>
									<PromptSelect
										required
										resource={this.promptResource}
										containerClassName="flex-fill"
										useControlGroup={false}
										clearable={false}
										initialOptionId={
											typeof prompt.fileId !== 'undefined'
												? String(prompt.fileId)
												: undefined
										}
										displayMode={this.props.displayMode}
										onChange={(newPrompt: PromptIndexResponse) =>
											this.updateSegment(newPrompt, index)
										}
										value={this.state.prompts[index]}
										autoHeight
									/>
									{!this.props.displayMode && (
										<>
											<Button
												variant="white-alt"
												disabled={index === 0}
												onClick={() => this.moveSegment(index, index - 1)}
											>
												<i className="fas fa-fw fa-arrow-up" />
											</Button>
											<Button
												variant="white-alt"
												disabled={index >= action.promptSegments.length - 1}
												onClick={() => this.moveSegment(index, index + 1)}
											>
												<i className="fas fa-fw fa-arrow-down" />
											</Button>
											<Button
												variant="white-alt"
												onClick={() => this.deleteSegment(index)}
											>
												<i className="fas fa-fw fa-trash" />
											</Button>
										</>
									)}
								</InputGroup>
							</Form.Group>
						</Col>
					</Row>
				))}
				{!this.props.displayMode && (
					<Form.Group className="d-flex justify-content-start mb-3">
						<Button
							variant="success"
							className="me-3 text-white"
							onClick={() => this.addSegment()}
						>
							<i className="fas fa-fw fa-plus" />
							&nbsp;Add Segment
						</Button>
						<Button
							variant="light"
							onClick={() => this.setUploadNewSegmentAside(true)}
						>
							<i className="fas fa-fw fa-cloud-upload-alt" />
							&nbsp;Upload New Segment
						</Button>
					</Form.Group>
				)}
				<h6 className="text-muted" style={{ position: 'relative' }}>
					<b>Digits</b>
				</h6>
				<Row>
					{digitKeys.map((actionDigit) => (
						<Form.Group key={actionDigit.digit} className="mb-3">
							<InputGroup>
								<InputGroup.Text>{actionDigit.digit}</InputGroup.Text>
								<ScenarioSelect
									resource={this.scenarioResource}
									containerClassName="flex-fill"
									autoHeight={true}
									useControlGroup={false}
									appendDropdownToBody
									label={actionDigit.digit}
									initialOptionId={
										actionDigit.scenarioId >= 0
											? String(actionDigit.scenarioId)
											: undefined
									}
									displayMode={this.props.displayMode}
									onChange={(scenario) =>
										this.updateDigitScenario(scenario, actionDigit.digit)
									}
									value={this.state.scenarios.get(actionDigit.digit)}
								/>
							</InputGroup>
						</Form.Group>
					))}
				</Row>
				<Aside
					disabledBody
					isOpen={this.state.showUploadNewSegmentAside}
					onClickOutside={() => this.setUploadNewSegmentAside(false)}
				>
					<Aside.Header
						header="Upload Prompt Segment"
						onClose={() => {
							this.setUploadNewSegmentAside(false);
						}}
					/>
					<Card.Body>
						<PromptEditor
							key={String(this.state.showUploadNewSegmentAside)}
							disablePadding
							disableBody
							createMode
							onCreate={(newPrompt) => this.onPromptCreate(newPrompt)}
						/>
					</Card.Body>
				</Aside>
			</section>
		);
	}

	private moveElement(array: any[], initialIndex: number, finalIndex: number) {
		array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
		return array;
	}
}
