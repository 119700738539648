import {
	GuardianReconciliationChargeDetailProfileResponse,
	GuardianReconciliationNoteTypes
} from 'RtModels';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { ReconciliationChargesRouter } from './ReconciliationCharges.router';
import { ReconciliationChargesResource } from 'RtUi/app/rtVue/ReconciliationCharge/lib/resources/ReconciliationChargesResource';
import { ReconciliationChargesEditor } from 'RtUi/app/rtVue/ReconciliationCharge/lib/forms/ReconciliationChargesEditor';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { Row, Col } from 'react-bootstrap';
import { ReconciliationNotes } from 'RtUi/app/rtVue/ReconciliationNote/lib/components/ReconciliationNotes';

interface IReconciliationChargesProfileContainerProps {}

interface IReconciliationChargesProfileContainerState {
	profile?: GuardianReconciliationChargeDetailProfileResponse;
}

@ReconciliationChargesRouter.getProfileRtUiController()
export class ReconciliationChargesProfileContainer extends ProfileApplicationContainer<
	IReconciliationChargesProfileContainerProps,
	IReconciliationChargesProfileContainerState
> {
	public Tabs = ReconciliationChargesRouter.getProfileTabs();

	public state: IReconciliationChargesProfileContainerState = {};

	public componentDidMount() {
		const profileId = this.getIdParam();
		const resource = new ReconciliationChargesResource();

		resource.get(profileId).then((profile) => this.setState({ profile }));
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}

		return (
			<StandardLayout<GuardianReconciliationChargeDetailProfileResponse>
				router={ReconciliationChargesRouter}
				profile={profile}
			>
				<Row>
					<Col lg={8}>
						<ReconciliationChargesEditor
							editMode={profile}
							onUpdate={(
								newProfile: GuardianReconciliationChargeDetailProfileResponse
							) => this.setState({ profile: newProfile })}
						/>
					</Col>
					<Col lg={4}>
						<ReconciliationNotes
							reconciliationId={profile.reconciliationId}
							reconciliationNoteType={GuardianReconciliationNoteTypes.Usage}
							reconciliationDetailId={
								profile.guardianReconciliationChargeDetailId
							}
						/>
					</Col>
				</Row>
			</StandardLayout>
		);
	}
}
