import {
	RocActionItems,
	RocDocumentIndexResponse,
	RocIndexResponse,
	RocNumbersIndexResponse,
	RocTfnIndexResponse
} from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { RocRejectReasonsDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RocRejectReasonsDataGridColumn';
import { RocSomosRejectReasonsDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RocSomosRejectReasonsDataGridColumn';
import { RocStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RocStatusDataGridColumn';
import { RocTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RocTypeDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { TollFreeNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeNumberDataGridColumn';
import {
	DataGridColumn,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import { isBefore } from 'date-fns';
import { Badge, Button } from 'react-bootstrap';

export const getRocRequestsColumns = (): Array<
	DataGridColumn<RocIndexResponse>
> => [
	TollFreeNumberDataGridColumn({
		accessorKey: 'firstTfn',
		header: 'First TFN'
	}),
	...RespOrgColumnsBuilder<RocIndexResponse>({
		accessorKey: 'requestingRespOrgId',
		header: 'Requesting RespOrg'
	}),
	RocTypeDataGridColumn({
		accessorKey: 'isIncoming',
		header: 'ROC Type'
	}),
	IntegerDataGridColumn({
		accessorKey: 'pendingQty',
		header: 'Pending',
		highlightCell: ({ cell }) => {
			return cell.getValue<number>() > 0 ? RowThemeColor.INFO : undefined;
		}
	}),
	IntegerDataGridColumn({
		accessorKey: 'declinedQty',
		header: 'Declined'
	}),
	IntegerDataGridColumn({
		accessorKey: 'approvedQty',
		header: 'Approved'
	}),
	IntegerDataGridColumn({
		accessorKey: 'overdueQty',
		header: 'Overdue'
	}),
	IntegerDataGridColumn({
		accessorKey: 'expiredQty',
		header: 'Expired'
	}),
	IntegerDataGridColumn({
		accessorKey: 'otherQty',
		header: 'Other'
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalQty',
		header: 'Total'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'dueEndOfDay',
		header: 'Due Timestamp',
		highlightCell: ({ cell, row: { original } }) => {
			const hasPending = Number(original.pendingQty) > 0;

			if (!hasPending) {
				return undefined;
			}

			const dueEndOfDay = cell.getValue<Date>();

			if (isBefore(dueEndOfDay, new Date())) {
				return RowThemeColor.INFO;
			}

			if (isBefore(new Date(), dueEndOfDay)) {
				return RowThemeColor.WARNING;
			}

			return undefined;
		},
		timezone: 'UTC'
	})
];

export const getRocRequestNumbersColumns = (
	actions: RocActionItems
): Array<DataGridColumn<RocTfnIndexResponse>> => [
	TollFreeNumberDataGridColumn({
		accessorKey: 'tfn',
		header: 'Toll-Free Number'
	}),
	...RespOrgColumnsBuilder<RocTfnIndexResponse>({
		accessorKey: 'controllingRespOrgId',
		header: 'Controlling RespOrg'
	}),
	RocRejectReasonsDataGridColumn({
		header: 'Reject Reasons',
		accessorKey: 'rejectReasons',
		actions
	}),
	RocSomosRejectReasonsDataGridColumn({
		header: 'System Reject Reasons',
		id: 'rejectSystemReasons',
		accessorKey: 'rejectReasons',
		actions
	}),
	RocStatusDataGridColumn({
		accessorKey: 'rocStatusId',
		header: 'Status'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'processedTs',
		header: 'Status Time'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rejectNote',
		header: 'Reject Note'
	})
];

export const getRocRequestDocumentsColumns = (
	downloadDocument: (doc: RocDocumentIndexResponse) => void
): Array<DataGridColumn<RocDocumentIndexResponse>> => [
	DefaultDataGridColumn({
		header: 'Title',
		getValue: ({ row: { original } }) => (
			<article>
				{original.fileTitle}
				{original.isLoa && (
					<small>
						<Badge className="ms-2" bg="info">
							Letter of Agency
						</Badge>
					</small>
				)}
			</article>
		)
	}),
	DefaultDataGridColumn({
		header: 'Download',
		getValue: ({ row: { original } }) => (
			<div className="d-flex justify-content-center">
				<Button
					variant="primary"
					size="sm"
					onClick={() => downloadDocument(original as RocDocumentIndexResponse)}
				>
					<i className="fas fa-fw fa-download" />
				</Button>
			</div>
		)
	})
];

export const getRocNumbersColumns = (): Array<
	DataGridColumn<RocNumbersIndexResponse>
> => [
	PhoneNumberDataGridColumn({
		accessorKey: 'tfn',
		header: 'Toll Free Number'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'syncTs',
		header: 'Last Sync'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rocTxnId',
		header: 'Request Id',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'inRespOrgId',
		header: 'In RespOrg'
	}),
	DefaultDataGridColumn({
		accessorKey: 'outRespOrgId',
		header: 'Out RespOrg'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rocStatus',
		header: 'Status'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rejectReasonLabel',
		header: 'Declined Reason'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'rocStatusTs',
		header: 'Status Time'
	}),
	DefaultDataGridColumn({
		accessorKey: 'changeContactName',
		header: 'Change Contact Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'changeContactPhone',
		header: 'Change Contact Phone'
	}),
	DefaultDataGridColumn({
		accessorKey: 'changeContactEmail',
		header: 'Change Contact Email'
	})
];
