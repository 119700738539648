/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TransactionIndexRequest = {
    properties: {
        search: {
            type: 'string',
        },
        accountId: {
            type: 'Array',
        },
        transactionTypeId: {
            type: 'Array',
        },
        gteAmount: {
            type: 'number',
        },
        lteAmount: {
            type: 'number',
        },
        isReversed: {
            type: 'number',
        },
        isReversal: {
            type: 'number',
        },
        startDate: {
            type: 'Date',
            format: 'date-time',
        },
        endDate: {
            type: 'Date',
            format: 'date-time',
        },
        comments: {
            type: 'string',
            isNullable: true,
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};