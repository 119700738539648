/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CdrExportSourceTypes {
    Subscription = 1,
    Account = 2,
    Invoice = 3,
    Full = 4,
    BillingCycle = 5,
    FileStream = 6,
}