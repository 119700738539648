import clsx from 'clsx';
import { cloneDeep, debounce, sortBy } from 'lodash-es';
import { Nav, Navbar, Badge, ListGroup, Collapse } from 'react-bootstrap';
import { matchPath, NavLink } from 'react-router-dom';
import { Clock } from 'RtUi/app/ApplicationShell/lib/components/Clock';
import {
	IPermissionRoute,
	IRouteMenu
} from 'RtUi/app/ApplicationShell/lib/interfaces';
import { Logo } from 'RtUi/app/public/lib/components/Logo';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import {
	isViewportAtOrAboveBreakpoint,
	isViewportAtOrBelowBreakpoint
} from 'RtUi/utils/react/ViewportHelpers';

interface INavigationProps {
	routesConfig: IRouteMenu[];
	onRouteClickedTwice?: () => void;
}

interface INavigationState {
	openedMenus: number[];
	openedGroups: string[];
	isMobileMenuExpanded: boolean;
}

export class Navigation extends ApplicationContainer<
	INavigationProps,
	INavigationState
> {
	public state: INavigationState = {
		openedMenus: [],
		openedGroups: [],
		isMobileMenuExpanded: false
	};

	public resizeListener = debounce(() => this.forceUpdate(), 500).bind(this);

	public componentDidMount() {
		window.addEventListener('resize', this.resizeListener);

		this.initOpenMenus();
	}

	public componentWillUnmount() {
		window.removeEventListener('resize', this.resizeListener);
	}

	public initOpenMenus() {
		const { routesConfig } = this.props;
		const openedMenus: number[] = [];
		const currentPath = this.getCurrentPath();
		let foundMatch = false;
		const openedGroups: string[] = [];

		//first search for current path's routeConfig
		for (let i = 0; i < routesConfig.length; i++) {
			const routeConfig = routesConfig[i];

			//Menu cannot be open if in singleMode
			if (routeConfig.singleMode) {
				continue;
			}

			for (const route of routeConfig.routes) {
				foundMatch = matchPath(route, currentPath) !== null;

				if (foundMatch) {
					if (route.groupName) {
						openedGroups.push(route.groupName);
					}
					openedMenus.push(i);
					break;
				}
			}

			if (foundMatch) {
				break;
			}
		}

		//if route is not found, find defaultOpen routeConfig
		if (!foundMatch) {
			for (let i = 0; i <= routesConfig.length; i++) {
				const route = routesConfig[i];

				if (route.defaultOpen) {
					openedMenus.push(i);
					break;
				}
			}
		}

		this.setState({ openedMenus, openedGroups });
	}

	public toggleMenuOpen(index: number, routeConfig: IRouteMenu) {
		const openedMenus: number[] = [...this.state.openedMenus];
		const openIndex = openedMenus.indexOf(index);
		if (openIndex < 0) {
			openedMenus.push(index);
		} else {
			openedMenus.splice(openIndex, 1);
		}

		let groupIdentifier: string | undefined;
		const routeWithGroupName = routeConfig.routes.find((route) =>
			Boolean(route.groupName)
		);
		if (routeWithGroupName && !routeConfig.doNotExpandChildren) {
			groupIdentifier =
				routeWithGroupName.groupId ?? routeWithGroupName.groupName;
		}

		const openedGroups = groupIdentifier ? [groupIdentifier] : [];

		this.setState({
			openedMenus,
			openedGroups
		});
	}

	public toggleSubMenuOpen(groupName: string) {
		const openedGroups: string[] = [];
		if (!this.state.openedGroups.includes(groupName)) {
			openedGroups.push(groupName);
		}
		this.setState({ openedGroups });
	}

	public onClick = (clickedRoute: IPermissionRoute) => () => {
		const currentPath = location.pathname;

		if (clickedRoute.path === currentPath) {
			const { onRouteClickedTwice = () => ({}) } = this.props;

			onRouteClickedTwice();
		}

		this.setState({ isMobileMenuExpanded: false });
	};

	public renderRouteGroup(routeGroup: IPermissionRoute[]) {
		if (routeGroup.length <= 0) {
			return null;
		}

		const firstRoute = routeGroup[0];
		const routeIdentifier = firstRoute.groupId ?? firstRoute.groupName!;

		return (
			<section className="nesting-container" key={routeIdentifier}>
				<header
					className="nav-header nav-link"
					onClick={() => this.toggleSubMenuOpen(routeIdentifier)}
				>
					<span>{firstRoute.groupName}</span>
				</header>
				<Collapse in={this.state.openedGroups.includes(routeIdentifier)}>
					<ListGroup>
						{routeGroup.map((routeFromGroup) =>
							this.renderRoute(routeFromGroup)
						)}
					</ListGroup>
				</Collapse>
			</section>
		);
	}

	public renderRoute(route: IPermissionRoute | { routes: IPermissionRoute[] }) {
		if ('routes' in route) {
			return this.renderRouteGroup(route.routes);
		}

		return (
			<NavLink
				key={route.path}
				to={route.path}
				className={clsx('nav-link', {
					'd-md-none': route.hideMdAndAbove
				})}
				onClick={this.onClick(route)}
			>
				{route.name}
				{route.description && (
					<span className="nav-description">{route.description}</span>
				)}
			</NavLink>
		);
	}

	public renderRoutes() {
		return this.props.routesConfig.map((routeConfig, index) => {
			const isOpen = this.state.openedMenus.includes(index);
			const classNames = ['nav-header', 'nav-link'];
			const firstRoute = routeConfig.routes[0];
			const isMdAndAbove = isViewportAtOrAboveBreakpoint('md');
			const allHidden = routeConfig.routes.every((route) => {
				if (Boolean(route.isHidden)) {
					return true;
				}

				if (route.hideMdAndAbove && isMdAndAbove) {
					return true;
				}

				return false;
			});

			if (!firstRoute || allHidden) {
				return null;
			}

			if (isOpen) {
				classNames.push('open');
			}

			if (routeConfig.singleMode) {
				classNames.push('no-menu');
			}

			let orderedRoutes = cloneDeep(routeConfig.routes).sort((r1, r2) => {
				const r1orderPriority = r1.orderPriority ?? 1000;
				const r2orderPriority = r2.orderPriority ?? 1000;

				//desc order
				return r2orderPriority - r1orderPriority;
			});

			const groupsByIdent: Record<string, IPermissionRoute[]> = {};
			const routesInGroup: IPermissionRoute[] = [];
			for (const route of orderedRoutes) {
				const groupIdentifier = route.groupId ?? route.groupName;

				if (!groupIdentifier || route.isHidden) {
					continue;
				}

				if (!(groupIdentifier in groupsByIdent)) {
					groupsByIdent[groupIdentifier] = [];
				}

				groupsByIdent[groupIdentifier].push(route);

				routesInGroup.push(route);
			}

			orderedRoutes = orderedRoutes.filter(
				(route) => !routesInGroup.includes(route)
			);

			const groups = Object.values(groupsByIdent)
				.sort(([group1], [group2]) => {
					// sort here
					if (group1.groupOrderPriority && group2.groupOrderPriority) {
						const order1 = group1.groupOrderPriority ?? 1000;
						const order2 = group2.groupOrderPriority ?? 1000;
						if (typeof group1.groupOrderPriority === 'number') {
							if (typeof group2.groupOrderPriority === 'number') {
								return order1 - order2;
							}

							return 1;
						} else if (typeof group2.groupOrderPriority === 'number') {
							return -1;
						}
					}
					return group1.groupName?.localeCompare(group2.groupName ?? '') ?? 0;
				})
				.map((group) => {
					return group.sort((route1, route2) => {
						const order1 = route1.orderPriority ?? 1000;
						const order2 = route2.orderPriority ?? 1000;

						if (typeof route1.orderPriority === 'number') {
							if (typeof route2.orderPriority === 'number') {
								return order1 - order2;
							}

							return 1;
						} else if (typeof route2.orderPriority === 'number') {
							return -1;
						}

						return route1.name.localeCompare(route2.name);
					});
				});

			const filteredGroups = groups.filter((item) => item.length);
			const remapFilteredGroups = filteredGroups.map((gr) => {
				return {
					orderPriority: gr[0].groupOrderPriority ?? 1000,
					routes: gr,
					name: gr[0].name,
					path: gr[0].path
				};
			});

			orderedRoutes = sortBy(
				[...orderedRoutes, ...remapFilteredGroups],
				'orderPriority'
			);

			return (
				<Nav.Item key={routeConfig.name + index}>
					{routeConfig.singleMode && (
						<NavLink
							key={routeConfig.name}
							to={firstRoute.path}
							className={classNames.join(' ')}
							onClick={this.onClick(firstRoute)}
						>
							<i className={`fas fa-fw me-2 ${routeConfig.iconClass}`} />
							<span>{routeConfig.name}</span>
						</NavLink>
					)}
					{!routeConfig.singleMode && (
						<>
							<header
								className={classNames.join(' ')}
								onClick={() => this.toggleMenuOpen(index, routeConfig)}
							>
								<i className={`fas fa-fw me-2 ${routeConfig.iconClass}`} />
								<span>{routeConfig.name}</span>
							</header>
							<Collapse in={this.state.openedMenus.includes(index)}>
								<ListGroup>
									{orderedRoutes.map((route) => {
										//Must render something for hot-reload to work
										if (route.isHidden) {
											return <span key={route.path}></span>;
										}
										return this.renderRoute(route);
									})}
								</ListGroup>
							</Collapse>
						</>
					)}
				</Nav.Item>
			);
		});
	}

	public render() {
		const isBelowBreakPoint = isViewportAtOrBelowBreakpoint('md');

		return (
			<Navbar
				collapseOnSelect
				expand="md"
				expanded={this.state.isMobileMenuExpanded}
				className={clsx('left-nav', { 'sticky-top': isBelowBreakPoint })}
			>
				<h5 className="w-100">
					<Badge className="mb-2 w-100 text-muted" bg="light">
						<Clock />
					</Badge>
				</h5>
				<Navbar.Brand className="d-md-none">
					<article className="d-flex justify-content-start align-content-center mt-2">
						<article className="ms-2">
							<Logo height={34} />
						</article>
					</article>
				</Navbar.Brand>
				<Navbar.Toggle
					aria-controls="basic-navbar-nav"
					className="text-white"
					onClick={() =>
						this.setState({
							isMobileMenuExpanded: !this.state.isMobileMenuExpanded
						})
					}
				>
					<span className="navbar-toggler-icon"></span>
				</Navbar.Toggle>
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="flex-column">{this.renderRoutes()}</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}
