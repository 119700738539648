import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	LcrTaskCarrierResultIndexRequest,
	LcrTaskCarrierResultIndexResponse,
	LcrTaskIndexRequest,
	LcrTaskIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchLcrTasks = async (
	queryParams?: LcrTaskIndexRequest
): Promise<FullResponse<LcrTaskIndexResponse[]>> => {
	return handleGetRequest<LcrTaskIndexResponse[], true>(
		Rt800ApiRoutes.LcrTasks.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetLcrTasks = (queryParams?: LcrTaskIndexRequest) => {
	return useQuery<FullResponse<LcrTaskIndexResponse[]>, Error>(
		['getLcrTasks', queryParams],
		() => fetchLcrTasks(queryParams)
	);
};

const fetchLcrTaskCarrierResults = async (
	urlParams: LcrTaskCarrierResultIndexRequest
): Promise<FullResponse<LcrTaskCarrierResultIndexResponse[]>> => {
	return handleGetRequest<LcrTaskCarrierResultIndexResponse[], true>(
		Rt800ApiRoutes.LcrTaskCarrierResults.Index,
		{
			includeFullResponse: true,
			urlParams
		}
	);
};

export const useGetLcrTaskCarrierResults = (
	urlParams: LcrTaskCarrierResultIndexRequest
) => {
	return useQuery<FullResponse<LcrTaskCarrierResultIndexResponse[]>, Error>(
		['useGetLcrTaskCarrierResults', urlParams],
		() => fetchLcrTaskCarrierResults(urlParams)
	);
};
