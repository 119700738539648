import { FirebaseCreateRequest, FirebaseCreateResponse } from 'RtModels';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { RtAdmApiRoutes } from 'RtExports/routes';

export class SystemNotificationHttp {
	/**
	 * Creates a system notification that will
	 * be sent out to users
	 * @param req
	 */
	public create(req: FirebaseCreateRequest) {
		const body = JSON.stringify(req);

		return HttpRequest.fetchWithRoute<FirebaseCreateResponse>(
			RtAdmApiRoutes.Firebase.Create,
			{ body }
		);
	}
}
