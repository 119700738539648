/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NaHistory = {
    properties: {
        number: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        tfnStatusId: {
            type: 'string',
            isRequired: true,
        },
        statusTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        lastUpdateTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        downloadTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        logonId: {
            type: 'string',
            isRequired: true,
        },
    },
};