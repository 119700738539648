import { ChangeEvent } from 'react';
import { RtUiController } from 'RtUi/app/@RtUi/RtUiDecorators';
import {
	ApplicationContainer,
	IContainerAppState
} from 'RtUi/components/containers/ApplicationContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { TFAChallengeError } from 'RtUi/state/actions/user/interfaces';
import { Link } from 'react-router-dom';
import { Logo } from 'RtUi/app/public/lib/components/Logo';
import {
	Container,
	Alert,
	Card,
	Button,
	Form,
	ListGroup
} from 'react-bootstrap';
import {
	LoginPath,
	RedirectSearchParamName
} from 'RtUi/app/user/lib/Constants';
import { FirebaseMessageSeverity, FirebaseMessageType } from 'RtModels';
import { SeverityIconsMap } from 'RtUi/app/ApplicationShell/lib/components/NotificationGroupItem';
import { FirebasePublic } from 'RtUi/state/actions/user/FirebasePublic';
import {
	FireBaseUserMessageListener,
	IFirebaseUIMessage
} from 'RtUi/state/actions/user/BaseFireBase';
import { RtDashboardOldRouter } from 'RtUi/app/rtCommon/DashboardOld/DashboardOld.router';

interface ILoginContainerState {
	emailAddress: string;
	password: string;
	redirectPath: string | null;
	error: string | null;
	submitting: boolean;
	sixDigitTfaCode: string;
	rememberThisDevice: boolean;
	tfaChallenged: boolean;
}

@RtUiController({
	name: 'Login',
	path: LoginPath,
	isHidden: true,
	unauthorizedAccessAllowed: true,
	hideNav: true
})
export class LoginContainer extends ApplicationContainer<
	{},
	ILoginContainerState
> {
	public state = {
		emailAddress: '',
		password: '',
		redirectPath: null,
		error: null,
		submitting: false,
		sixDigitTfaCode: '',
		rememberThisDevice: false,
		tfaChallenged: false,
		firebaseMessages: [] as IFirebaseUIMessage[]
	};

	public removeFirebaseListenerFn: () => void = () => ({});

	public onFirebaseMessage: FireBaseUserMessageListener = (
		firebaseMessages
	) => {
		//@ts-ignore
		this.setState({ firebaseMessages });
	};

	public componentDidMount() {
		const possibleHashPath = this.checkForOldPathInHash();
		const firebaseUser = FirebasePublic.getInstance();

		this.removeFirebaseListenerFn = firebaseUser.onMessages(
			this.onFirebaseMessage,
			[FirebaseMessageType.BannerAlert, FirebaseMessageType.UserAlert]
		);

		if (possibleHashPath) {
			this.goToPath(possibleHashPath);
		}
	}

	public componentWillUnmount() {
		this.removeFirebaseListenerFn();
	}

	public onHistoryPathChange(path: string, pathParams: URLSearchParams) {
		const redirectPath = pathParams.get(RedirectSearchParamName) ?? null;

		this.setState({ redirectPath });
	}

	public onAppStateChange(appState: IContainerAppState) {
		let { emailAddress } = this.state;
		const user = appState.get('user');

		if (user && user.isInitialized && user.loginInfo && user.loginInfo.userId) {
			const possibleHashPath = this.checkForOldPathInHash();

			if (possibleHashPath && possibleHashPath !== LoginPath) {
				this.goToPath(possibleHashPath);
			} else {
				this.goToPath(RtDashboardOldRouter.getIndexRoute());
			}
		} else if (!emailAddress) {
			const user = appState.get('user');
			emailAddress = user.emailAddress || '';

			this.setState({ emailAddress });
		}
	}

	public updateForm<K extends keyof ILoginContainerState>(
		key: K,
		value: ILoginContainerState[K]
	) {
		const error = null;
		const subState: any = { [key]: value, error };

		this.setState(subState);
	}

	public login(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		const { emailAddress, password } = this.state;

		this.setState({ submitting: true, error: null });

		this.Actions.User.login(emailAddress, password)
			.then(() => {
				const pathParam = this.getSearchParam(RedirectSearchParamName);
				const redirectPath = pathParam ?? RtDashboardOldRouter.getIndexRoute();
				this.setState({ submitting: false });
				this.goToPath(redirectPath);
			})
			.catch((err: Error) => {
				this.setState({ submitting: false });
				if (err instanceof TFAChallengeError) {
					this.setState({ tfaChallenged: true });
				} else {
					const error = err.message;

					this.setState({ error });
				}
			});
	}

	public challengeTfa(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		const { emailAddress, sixDigitTfaCode, rememberThisDevice } = this.state;

		this.setState({ submitting: true, error: null });

		this.Actions.User.challengeTfa(
			emailAddress,
			sixDigitTfaCode,
			rememberThisDevice
		)
			.then(() => {
				const pathParam = this.getSearchParam(RedirectSearchParamName);
				const redirectPath = pathParam ?? RtDashboardOldRouter.getIndexRoute();

				this.goToPath(redirectPath);
			})
			.catch((err: Error) => {
				this.setState({ error: err.message });
			})
			.finally(() => this.setState({ submitting: false }));
	}

	public cancelTfa() {
		this.setState({
			tfaChallenged: false,
			sixDigitTfaCode: '',
			rememberThisDevice: false,
			error: ''
		});
	}

	public render() {
		const { error, submitting, tfaChallenged, firebaseMessages } = this.state;

		return (
			<section className="v-align-container">
				<Container className="animate__animated animate__fadeIn d-flex justify-content-center">
					<Card
						className="border-primary"
						style={{ minWidth: 450, maxWidth: 600 }}
					>
						<Card.Header className="bg-brand-gradient p-3 text-white d-flex align-items-center justify-content-start">
							<Logo height={24} />
						</Card.Header>
						<Card.Body>
							{firebaseMessages?.map((firebaseMessage) => (
								<div
									style={{ maxWidth: 415 }}
									key={firebaseMessage.label}
									className={`rtNotification d-flex justify-content-between mb-2 p-2 border-1
					rtNotification--${FirebaseMessageSeverity[firebaseMessage.severity]}
					rtNotification--${FirebaseMessageSeverity[firebaseMessage.severity]}--fill`}
								>
									<div className="d-flex align-items-baseline flex-wrap">
										<h6 className="rtNotification__heading mb-0 pb-0 pe-1 flex-grow-1 ">
											<i
												className={`rtNotification__heading__icon fas fa-fw fa-sm ${SeverityIconsMap.get(
													firebaseMessage.severity
												)}`}
											/>
											<b>{firebaseMessage.label}</b>
										</h6>
										<ListGroup.Item
											bsPrefix="list-group-item-text"
											className="mb-0 rtNotification__text"
										>
											{firebaseMessage.summary}
										</ListGroup.Item>
									</div>
								</div>
							))}
							{tfaChallenged && (
								<form onSubmit={(e) => this.challengeTfa(e)}>
									<Alert variant="info">
										<section className="d-flex justify-content-start">
											<i className="fas fa-fw fa-info-circle me-2 align-self-baseline" />
											<span className="align-self-baseline">
												Enter the verification code generated by your mobile
												application.
											</span>
										</section>
									</Alert>
									<InputFormControl
										label="6-digit Code"
										autoFocus
										minLength={6}
										maxLength={6}
										onChange={(sixDigitTfaCode) =>
											this.setState({ sixDigitTfaCode })
										}
										value={this.state.sixDigitTfaCode}
									/>
									<Form.Check
										id="remember-this-device-checkbox"
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											this.setState({
												rememberThisDevice: e.currentTarget.checked
											});
										}}
										label="Remember this Device"
										checked={this.state.rememberThisDevice}
									></Form.Check>
									{error && (
										<Form.Group className="mb-3">
											<Alert
												variant="danger"
												className="d-flex justify-content-start"
											>
												<i className="fas fa-fw fa-exclamation-circle me-2" />
												<span>{error}</span>
											</Alert>
										</Form.Group>
									)}
									<footer className="pt-3 d-flex justify-content-end">
										<Button
											type="button"
											size="lg"
											variant="link"
											className="me-2"
											onClick={() => this.cancelTfa()}
										>
											<span className="text-dark">Cancel</span>
										</Button>
										<Button
											type="submit"
											size="lg"
											variant="submit"
											disabled={submitting}
										>
											{!submitting && <i className="fas fa-fw fa-check" />}
											<span>&nbsp;Verify Device&nbsp;</span>
											{submitting && <i className="fas fa-fw fa-cog fa-spin" />}
										</Button>
									</footer>
								</form>
							)}
							{!tfaChallenged && (
								<form onSubmit={(evt) => this.login(evt)}>
									<InputFormControl
										type="email"
										label="E-mail"
										bsSize="lg"
										required
										autoFocus
										onChange={(emailAddress) =>
											this.updateForm('emailAddress', emailAddress)
										}
										value={this.state.emailAddress}
									/>
									<InputFormControl
										type="password"
										label="Password"
										bsSize="lg"
										required
										onChange={(password) =>
											this.updateForm('password', password)
										}
										value={this.state.password}
										formTextDanger={error ? String(error) : ''}
									/>
									<footer className="pt-3 d-flex justify-content-between">
										<Link to="/sendResetPassword">
											<Button variant="link" type="button" className="ps-0">
												Forgot Password?
											</Button>
										</Link>
										<Button
											type="submit"
											size="lg"
											variant="submit"
											disabled={submitting}
										>
											<span>Login</span>
											{submitting && (
												<>
													&nbsp;
													<i className="fas fa-fw fa-cog fa-spin" />
												</>
											)}
										</Button>
									</footer>
								</form>
							)}
						</Card.Body>
					</Card>
				</Container>
			</section>
		);
	}
}
