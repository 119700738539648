import clsx from 'clsx';
import mermaid from 'mermaid';
import { FC, useState } from 'react';
import { useDebounce } from 'react-use';

export interface IMermaidProps {
	id: string;
	content: string;
	className?: string;
	onClick: (args: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Mermaid: FC<React.PropsWithChildren<IMermaidProps>> = ({
	id,
	content,
	onClick,
	className
}) => {
	mermaid.mermaidAPI.initialize({
		startOnLoad: false,
		//@ts-ignore
		securityLevel: 'loose',
		sequence: {
			diagramMarginY: 0,
			useMaxWidth: true,
			boxMargin: 1,
			boxTextMargin: 1,
			messageMargin: 1
		}
	});

	const [svg, setSvg] = useState<string | null>(null);
	useDebounce(
		() => {
			const getRenderResult = async () => {
				const result = await mermaid.mermaidAPI.render(id, content);
				const svgParsed = new DOMParser().parseFromString(
					result.svg,
					'image/svg+xml'
				);

				const rects = svgParsed.getElementsByClassName('rect');

				Array.from(rects).forEach((rect) => {
					svgParsed.documentElement.appendChild(rect);
				});

				setSvg(new XMLSerializer().serializeToString(svgParsed));
			};

			getRenderResult();
		},
		500,
		[id, content]
	);

	if (!svg) {
		return <div>Loading...</div>;
	}

	return (
		<div
			onClick={(e) => {
				onClick(e);
			}}
			className={clsx('mermaid-diagram', className)}
			dangerouslySetInnerHTML={{ __html: svg }}
		/>
	);
};
