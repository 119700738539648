import { PartitionIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { UserActions } from 'RtUi/state/actions/user';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { PartitionResource } from 'RtUi/app/rtAdmin/lib/resources/PartitionResource';
import { PartitionRouter } from 'RtUi/app/rtAdmin/Partitions/Partition.router';

interface IPartitionSelectProps
	extends ISelectFormControlProps<PartitionIndexResponse> {}

export class PartitionSelect extends SelectFormControl<
	PartitionIndexResponse,
	false,
	IPartitionSelectProps
> {
	public static defaultProps = {
		router: PartitionRouter
	};
	public static HasPermissions() {
		return UserActions.has(...RtAdmApiRoutes.Partitions.Index.permissions);
	}

	public resourceClass = PartitionResource;
	public state: ISelectFormControlState<PartitionIndexResponse> = {
		formLabel: 'Partition',
		valueKey: 'partitionId',
		labelKey: 'summary'
	};

	/**
	 * For usage in refs to set new values based on prefix
	 * @param prefix
	 */
	public async setByIds(prefix: string, callback?: () => void) {
		const options = await this.loadOptions();

		if (!options) {
			return;
		}

		const newValue = options.find((option) => prefix === option.prefix);

		this.triggerOnChange(newValue);

		if (callback) {
			callback();
		}
	}

	public render() {
		if (!PartitionSelect.HasPermissions()) {
			return null;
		}

		return super.render();
	}
}
