import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { IStateOption } from 'RtUi/app/rtCommon/States/lib/resources/States';
import { StatesResource } from 'RtUi/app/rtCommon/States/lib/resources/StatesResource';

interface IStatesSelectSelectProps
	extends ISelectFormControlProps<IStateOption> {}

export class StatesSelect extends SelectFormControl<
	IStateOption,
	false,
	IStatesSelectSelectProps
> {
	public resourceClass = StatesResource;
	public state: ISelectFormControlState<IStateOption> = {
		formLabel: 'State',
		valueKey: 'abbreviation',
		labelKey: 'name'
	};
}
