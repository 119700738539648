/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $AuthorizationResetPasswordRequest = {
    properties: {
        token: {
            type: 'string',
            isRequired: true,
            minLength: 8,
        },
        newPassword: {
            type: 'string',
            isRequired: true,
            minLength: 8,
        },
        confirmNewPassword: {
            type: 'string',
            isRequired: true,
            minLength: 8,
        },
    },
};