import { Component } from 'react';
import Select from 'react-select';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface IVueAdditionalColumnsProps {
	allColumns: Array<ISimpleSelectFormControlOption<string>>;
	selectedAdditionalColumns: Array<ISimpleSelectFormControlOption<string>>;
	onChange: (columns: Array<ISimpleSelectFormControlOption<string>>) => void;
	isMulti: boolean;
}

export class VueSelectAdditionalColumns extends Component<
	IVueAdditionalColumnsProps,
	{}
> {
	public render() {
		return (
			<section>
				<Select
					isClearable={true}
					menuPortalTarget={document.body}
					isMulti={this.props.isMulti}
					placeholder="Search for Additional Columns..."
					//@ts-expect-error
					onChange={(
						columns: Array<ISimpleSelectFormControlOption<string>>
					) => {
						this.setState({ columns }, () => {
							this.props.onChange(columns);
						});
					}}
					value={this.props.selectedAdditionalColumns}
					options={this.props.allColumns}
					closeMenuOnSelect={!this.props.isMulti}
				/>
			</section>
		);
	}
}
