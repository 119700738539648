/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TaskNumberIndexResponse = {
    properties: {
        taskNumberId: {
            type: 'number',
            isRequired: true,
        },
        taskId: {
            type: 'number',
            isRequired: true,
        },
        tfn: {
            type: 'string',
            isRequired: true,
        },
        isProcessed: {
            type: 'number',
            isRequired: true,
        },
        responseId: {
            type: 'number',
            isRequired: true,
        },
        responseLabel: {
            type: 'string',
            isRequired: true,
        },
        responseValue: {
            type: 'string',
            isRequired: true,
        },
        activationTs: {
            type: 'Date',
            format: 'date-time',
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        templateName: {
            type: 'string',
            isRequired: true,
        },
        numberStatus: {
            type: 'string',
            isRequired: true,
        },
        numberStatusLabel: {
            type: 'string',
            isRequired: true,
        },
        statusTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        effectiveTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastUpdateTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        cprStatusId: {
            type: 'number',
            isRequired: true,
        },
    },
};