import { useMemo } from 'react';
import {
	GuardianRerateControlIndexRequest,
	GuardianRerateControlIndexResponse
} from 'RtModels';
import { useGetRerateControl } from 'RtUi/app/rtVue/RerateControl/lib/services';
import { RerateControlRouter } from 'RtUi/app/rtVue/RerateControl/RerateControl.router';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRerateControlGridColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/rerateControl';

interface IRateControlGridProps {
	resourceParams?: GuardianRerateControlIndexRequest;
}

export const RerateControlGrid = ({
	resourceParams
}: IRateControlGridProps) => {
	const { data, isFetching: isLoading } = useGetRerateControl(resourceParams);
	const columns = useMemo(() => getRerateControlGridColumns(), []);

	return (
		<DataGrid<GuardianRerateControlIndexResponse>
			pageName="rtVue_RerateControl"
			data={data}
			loading={isLoading}
			router={RerateControlRouter}
			columns={columns}
			defaultSorting={[
				{
					id: 'jobId',
					desc: true
				}
			]}
		/>
	);
};
