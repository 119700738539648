import { get } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type UrlSearchParamsSource = {
	[key: string]: any;
};

interface IUseUrlSearchParamsResult<T extends UrlSearchParamsSource> {
	urlSearchParams: T;
	setUrlSearchParams: (source: UrlSearchParamsSource) => void;
	getParam: <K extends keyof T>(key: K, fallback?: any) => T[K];
}

export const useUrlSearchParams = <
	T extends UrlSearchParamsSource
>(): IUseUrlSearchParamsResult<T> => {
	const [searchParams, setSearchParams] = useSearchParams();

	const setUrlSearchParams = useCallback(
		(source: UrlSearchParamsSource) => {
			const queryString = Object.keys(source)
				.map(
					(key) => key + '=' + encodeURIComponent(JSON.stringify(source[key]))
				)
				.join('&');
			setSearchParams(queryString);
		},
		[setSearchParams]
	);

	const urlSearchParams = useMemo(() => {
		const entries = Object.fromEntries(searchParams);

		return Object.keys(entries).reduce<any>((dest, key) => {
			const value = entries[key];
			try {
				dest[key] = JSON.parse(value);
			} catch (e) {
				dest[key] = value;
			}

			return dest;
		}, {});
	}, [searchParams]);

	const getParam = useCallback(
		<K extends keyof T>(key: K, fallback?: any) => {
			return get(urlSearchParams, key) ?? fallback;
		},
		[urlSearchParams]
	);

	return { urlSearchParams, setUrlSearchParams, getParam };
};
