import * as React from 'react';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from 'RtUi/components/form/FormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { ITollNumberInputProps, TollNumberInput } from './TollNumberInput';

interface ITollFreeNumberFormControlState extends IFormControlState {}

interface ITollFreeNumberFormControlProps
	extends IFormControlProps<string, false> {
	bsSize?: ITollNumberInputProps['bsSize'];
	allowWildcards?: ITollNumberInputProps['allowWildcards'];
	inputRef?: ITollNumberInputProps['inputRef'];
	onlyNumbers?: ITollNumberInputProps['onlyNumbers'];
	alwaysShowMask?: ITollNumberInputProps['alwaysShowMask'];
	enforceAreCodeAndPrefixRules?: boolean;
	errorText?: string;
}

export class TollFreeNumberFormControl extends FormControl<
	string,
	false,
	ITollFreeNumberFormControlProps,
	ITollFreeNumberFormControlState
> {
	public resource = undefined;
	public state: ITollFreeNumberFormControlState = {
		formLabel: 'Toll-Free Number'
	};

	public inputRef: HTMLInputElement | null = null;

	public onChangeInternal(evt: React.ChangeEvent<HTMLInputElement>) {
		let { value } = evt.currentTarget;
		const { onChange = () => ({}) } = this.props;

		value = value.replace(/[\_|\-]/g, '');

		if (this.props.onlyNumbers) {
			value = value.replace(/\D/g, '');
		}

		if (this.props.enforceAreCodeAndPrefixRules && this.inputRef) {
			let customValidation = '';

			if (value.length === 10) {
				const areaCode = Number(value.substr(0, 3));
				const prefix = Number(value.substr(3, 3));

				if (areaCode < 201) {
					customValidation = 'Area Code must be between 201 and 999';
				} else if (prefix < 200) {
					customValidation = 'Prefix must be between 200 and 999';
				}
			}

			this.inputRef.setCustomValidity(customValidation);
		}

		onChange(value);
	}

	public render() {
		const { value, displayMode, errorText } = this.props;
		const { formLabel } = this.state;

		const formattedValue = value
			? TollFreeNumbersUtils.tollFreeNumberToReadableString(value)
			: '';

		return (
			<ControlGroup
				label={formLabel}
				value={formattedValue}
				displayMode={displayMode}
				required={this.props.required}
				valueClassName="text-monospace form-control-tollfree"
				errorText={errorText}
				className={`form-control-tollfree-wrapper ${
					Boolean(errorText) ? 'is-invalid' : ''
				}`}
			>
				<section style={{ position: 'relative' }}>
					<TollNumberInput
						bsSize={this.props.bsSize}
						allowWildcards={this.props.allowWildcards}
						alwaysShowMask={this.props.alwaysShowMask}
						onChange={(evt) => this.onChangeInternal(evt)}
						required={this.props.required}
						name={this.props.name}
						value={value}
						inputRef={this.props.inputRef}
						id={this.props.id}
					/>
					<input
						ref={(inputRef) => (this.inputRef = inputRef)}
						onChange={() => void 0}
						tabIndex={-1}
						style={{
							position: 'absolute',
							height: 0,
							opacity: 0,
							bottom: 0,
							left: '25%'
						}}
						required={this.props.required}
						value={value ? '1' : ''}
					/>
				</section>
			</ControlGroup>
		);
	}
}
