const dnsRegex =
	/(?=^.{4,253}\.?$)(^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,63}\.?$)/;
const ipAddressRegex =
	/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

export const isValidFqdn = (value: string) =>
	isValidDns(value) || isValidIpAddress(value);

export const isValidDns = (value: string) => dnsRegex.test(value);
export const isValidIpAddress = (value: string) => ipAddressRegex.test(value);
