/**
 * https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript/8809472#8809472
 * RFC4122 version 4 compliant solution that solves that issue by offsetting the first 13 hex numbers by a
 * hex portion of the timestamp. That way, even if Math.random is on the same seed, both clients would have
 * to generate the UUID at the exact same millisecond (or 10,000+ years later) to get the same UUID:
 */
export const generateUUID = () => {
	// Public Domain/MIT
	let d = new Date().getTime();
	if (
		typeof performance !== 'undefined' &&
		typeof performance.now === 'function'
	) {
		d += performance.now(); //use high-precision timer if available
	}
	//cspell:ignore yxxx
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		// eslint-disable-next-line no-bitwise
		const r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		// eslint-disable-next-line no-bitwise
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
};

// base32LookupTable
const base32LookupTableWithoutPad = [
	'a',
	'b',
	'c',
	'd',
	'e',
	'f',
	'g',
	'h', // 7
	'i',
	'j',
	'k',
	'l',
	'm',
	'n',
	'o',
	'p', // 15
	'q',
	'r',
	's',
	't',
	'u',
	'v',
	'w',
	'x', // 23
	'y',
	'z',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7' // 31
];

// base32LookupTable
const base32LookupTable = [
	...base32LookupTableWithoutPad,
	'=' // padding char
];

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min: number, max: number) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Generates a pseudo base32 encoded string (which is not always decode-able)
 * but seems to be enough for our needs
 * @returns {string}
 */
export const generateShortUUID = (opts: { noPaddingChar?: boolean } = {}) => {
	const { noPaddingChar = false } = opts;
	const lookupTable = noPaddingChar
		? base32LookupTableWithoutPad
		: base32LookupTable;

	let result = '';
	for (let i = 0; i < 16; i++) {
		result += lookupTable[getRandomInt(0, lookupTable.length - 1)];
	}
	return result;
};

/**
 * Generates a short uuid with only alphanumeric characters
 */
export const generateUniqueReactKey = () => {
	return generateShortUUID().replace(/[^a-zA-Z0-9]/g, '');
};
