import { RtxSipApiRoutes } from 'RtExports/routes';
import { FullSummaryIndexRequest, SipFullSummaryIndexResponse } from 'RtModels';
import { handlePostRequest } from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchFullSummaryReport = async (
	resourceParams: FullSummaryIndexRequest
): Promise<SipFullSummaryIndexResponse[]> => {
	const data = await handlePostRequest(
		RtxSipApiRoutes.FullSummary.Index,
		resourceParams
	);
	return data;
};

export const useGetFullSummaryReport = (
	resourceParams: FullSummaryIndexRequest
) => {
	return useQuery<SipFullSummaryIndexResponse[], Error>(
		'getFullSummaryReport',
		() => fetchFullSummaryReport(resourceParams)
	);
};
