import { Component } from 'react';
import { Link } from 'react-router-dom';
import { TransactionRouter } from 'RtUi/app/rtSip/Transactions/Transaction.router';
import { Form } from 'react-bootstrap';
interface IProfileReferenceControlProps {
	label: string;
	profileId: number;
	linkLabel: string;
}

export class ProfileReferenceControl extends Component<
	IProfileReferenceControlProps,
	{}
> {
	public render() {
		return (
			<Form.Group className="mb-3">
				<Form.Label>{this.props.label}</Form.Label>
				<div className="form-control-plaintext">
					<Link to={TransactionRouter.getProfileRoute(this.props.profileId)}>
						{this.props.linkLabel}
					</Link>
				</div>
			</Form.Group>
		);
	}
}
