import { MgiCprIndexResponse } from 'RtModels';
import { CprProfileResponse } from 'RtUi/app/rt800/Cprs/lib/components/CprProfileContainer';
import { CprRouting } from 'Somos/lib/SomosCpr/RtCprV1';
import { CprCol, CprNodeType, CprRow } from 'Somos/lib/SomosCpr/RtCprV2';
import { SomosCprFactory } from 'Somos/lib/SomosCpr/SomosCprFactory';
import moment from 'moment';

export const getCprV2 = (
	id: string,
	mgiProfile: CprProfileResponse,
	effectiveTs: string
) => {
	const interLataCarriers = mgiProfile.interLataCarriers ?? [];
	const intraLataCarriers = mgiProfile.intraLataCarriers ?? [];

	const cprV1: CprRouting = {
		respOrgId: mgiProfile.respOrgId,
		interLataCarriers,
		intraLataCarriers,
		areasOfService: mgiProfile.areasOfService,
		cprStatusId: mgiProfile.cprStatusId,
		approvalIndicator: mgiProfile.approvalIndicator,
		summary: mgiProfile.summary ?? '',
		notes: mgiProfile.notes ?? '',
		contactName: mgiProfile.contactName ?? '',
		contactNumber: mgiProfile.contactNumber ?? '',
		templateName: mgiProfile.templateName,
		cpr: {
			interLataCarriers,
			intraLataCarriers,
			nodeTypes: mgiProfile.cpr?.nodeTypes ?? [],
			nodes: mgiProfile.cpr?.nodes ?? [],
			labels: mgiProfile.cpr?.labels ?? []
		}
	};

	const effectiveDate = new Date(effectiveTs);

	const cprV2 = SomosCprFactory.RtCprV1ToRtCprV2(
		mgiProfile.respOrgId,
		id,
		effectiveDate,
		cprV1
	);

	return cprV2;
};

export const getNextApproximateNowDateTime = (
	routes: MgiCprIndexResponse[],
	possibleNextEffectiveTs?: moment.Moment
): moment.Moment => {
	const roundToInMinutes = 15;

	if (!possibleNextEffectiveTs) {
		possibleNextEffectiveTs = moment().round(
			roundToInMinutes,
			'minutes',
			'floor'
		);
	}

	for (const route of routes) {
		const routeEffectiveTimestamp = moment(route.effectiveTs);
		const areSameEffectiveTs = possibleNextEffectiveTs.isSame(
			routeEffectiveTimestamp,
			'minutes'
		);

		if (areSameEffectiveTs) {
			possibleNextEffectiveTs = possibleNextEffectiveTs
				.add(roundToInMinutes, 'minutes')
				.round(roundToInMinutes, 'minutes', 'floor');

			return getNextApproximateNowDateTime(routes, possibleNextEffectiveTs);
		}
	}

	return possibleNextEffectiveTs;
};

export const getCprCol = (cprRow: CprRow, nodeType: CprNodeType) => {
	let column: CprCol | undefined;

	switch (nodeType) {
		case CprNodeType.Announcement:
			column = cprRow.announcement;
			break;
		case CprNodeType.AreaCode:
			column = cprRow.areaCodes;
			break;
		case CprNodeType.Carrier:
			column = cprRow.carrier;
			break;
		case CprNodeType.Date:
			column = cprRow.dates;
			break;
		case CprNodeType.DayOfWeek:
			column = cprRow.daysOfWeek;
			break;
		case CprNodeType.GoTo:
			column = cprRow.goto;
			break;
		case CprNodeType.Lata:
			column = cprRow.latas;
			break;
		case CprNodeType.NpaNxx:
			column = cprRow.npaNxxs;
			break;
		case CprNodeType.Percent:
			column = cprRow.percent;
			break;
		case CprNodeType.SixDigit:
			column = cprRow.sixDigits;
			break;
		case CprNodeType.State:
			column = cprRow.states;
			break;
		case CprNodeType.Switch:
			column = cprRow.switches;
			break;
		case CprNodeType.TenDigit:
			column = cprRow.tenDigits;
			break;
		case CprNodeType.TerminatingNumber:
			column = cprRow.terminatingNumber;
			break;
		default:
			// CprNodeType.Time:
			column = cprRow.times;
	}

	return column;
};
