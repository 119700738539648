import { Instance } from 'flatpickr/dist/types/instance';
import { Plugin } from 'flatpickr/dist/types/options';

export interface Config {
	confirmIcon?: string;
	confirmText?: string;
	showAlways?: boolean;
	theme?: string;
	onClick?: (selectedDates: Date[]) => void;
}

const defaultConfig: Config = {
	confirmIcon:
		"<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='17' height='17' viewBox='0 0 17 17'> <g> </g> <path d='M15.418 1.774l-8.833 13.485-4.918-4.386 0.666-0.746 4.051 3.614 8.198-12.515 0.836 0.548z' fill='#000000' /> </svg>",
	confirmText: 'OK ',
	showAlways: false,
	theme: 'light',
	onClick: () => {}
};

function getEventTarget(event: Event): EventTarget | null {
	try {
		if (typeof event.composedPath === 'function') {
			const path = event.composedPath();
			return path[0];
		}
		return event.target;
	} catch (error) {
		return event.target;
	}
}

function customConfirmDatePlugin(pluginConfig: Config): Plugin {
	const config = { ...defaultConfig, ...pluginConfig };
	let confirmContainer: HTMLDivElement;
	const confirmButtonCSSClass = 'flatpickr-confirm';

	return function (fp: Instance) {
		if (fp.config.noCalendar || fp.isMobile) return {};
		return {
			onKeyDown(_: Date[], __: string, ___: Instance, e: KeyboardEvent) {
				const eventTarget = getEventTarget(e);
				if (
					fp.config.enableTime &&
					e.key === 'Tab' &&
					eventTarget === fp.amPM
				) {
					e.preventDefault();
					confirmContainer.focus();
				} else if (e.key === 'Enter' && eventTarget === confirmContainer) {
					// @ts-ignore
					pluginConfig.onClick(fp.selectedDates);
					fp.close();
				}
			},

			onReady() {
				// eslint-disable-next-line no-underscore-dangle
				confirmContainer = fp._createElement<HTMLDivElement>(
					'div',
					`${confirmButtonCSSClass} ${config.showAlways ? 'visible' : ''} ${
						config.theme
					}Theme`,
					config.confirmText
				);

				confirmContainer.tabIndex = -1;
				confirmContainer.innerHTML += config.confirmIcon;

				confirmContainer.addEventListener('click', (event) => {
					// @ts-ignore
					pluginConfig.onClick(fp.selectedDates);
					fp.close();
				});
				fp.calendarContainer.appendChild(confirmContainer);

				fp.loadedPlugins.push('customConfirmDate');
			},
			...(!config.showAlways
				? {
						onChange: function (_: Date[], dateStr: string) {
							const showCondition =
								fp.config.enableTime ||
								fp.config.mode === 'multiple' ||
								fp.loadedPlugins.includes('monthSelect');

							const localConfirmContainer = fp.calendarContainer.querySelector(
								`.${confirmButtonCSSClass}`
							);

							if (!localConfirmContainer) return;

							if (
								dateStr &&
								!fp.config.inline &&
								showCondition &&
								localConfirmContainer
							) {
								return localConfirmContainer.classList.add('visible');
							}

							localConfirmContainer.classList.remove('visible');
						}
					}
				: {})
		};
	};
}

export default customConfirmDatePlugin;
