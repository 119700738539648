import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianRerateControlIndexRequest,
	GuardianRerateControlIndexResponse
} from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchRerateControl = async (
	queryParams: GuardianRerateControlIndexRequest
): Promise<GuardianRerateControlIndexResponse[]> => {
	return handleGetRequest(RtVueApiRoutes.GuardianRerateControl.Index, {
		queryParams
	});
};

export const useGetRerateControl = (
	queryParams: GuardianRerateControlIndexRequest = {}
) => {
	const cacheKey = JSON.stringify(queryParams);

	return useQuery<GuardianRerateControlIndexResponse[], Error>(
		[`useGetRerateControl${cacheKey}`, cacheKey],
		() => fetchRerateControl(queryParams),
		{ enabled: true }
	);
};
