import { type AosNodeType, CprApprovalIndicator, type CprNodeType, CprStatus, type RoutingCacheTypes } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

export interface IAosCol {
	aosNodeTypeId: AosNodeType;
	values: string[];
}

export interface IAosLbl {
	name: string;
	aosNodeTypeId: AosNodeType;
	values: string[];
}

export interface ICprCol {
	cprNodeTypeId: CprNodeType;
	timeZone: string | null;
}

export interface ICprLbl {
	name: string;
	cprNodeTypeId: CprNodeType;
	values: string[];
}

export interface ICprRow {
	announcement?: string[];
	areaCodes?:  string[];
	carrier?:  string[];
	dates?:  string[];
	daysOfWeek?:  string[];
	goto?:  string[];
	latas?:  string[];
	npaNxxs?:  string[];
	percent?:  string[];
	sixDigits?:  string[];
	states?:  string[];
	tenDigits?:  string[];
	terminatingNumber?:  string[];
	times?:  string[];
	switches?:  string[];
}

export class CprDto {
	public routingCacheTypeId!: RoutingCacheTypes;
	public routingCacheKey!: string;

	public sourceRespOrgId!: string;
	public sourceEffectiveTs: Date | null = null;
	public sourceRecVersionId: string | null = null;
	public sourceTemplateName: string | null = null;

	public targetRespOrgId: string | null = null;
	public targetEffectiveTs: Date | null = null;
	public targetTemplateName: string | null = null;

	public cprStatusId: CprStatus = CprStatus.Unknown;
	public approvalIndicator: CprApprovalIndicator = CprApprovalIndicator.Unknown;

	public contactName: string = '';
	public contactNumber: string = '';

	public notes: string = '';
	public summary: string = '';

	public company: string | null = null;
	public daylightSavings: string = 'Y';
	public timeZone: string = 'C';

	public lineQty: number = 9999;

	public interLataCarriers: string[] = [];
	public intraLataCarriers: string[] = [];

	// CAD and TAD only

	public aosLbls: IAosLbl[] = [];

	public aosCols: IAosCol[] = [];

	public cprLbls: ICprLbl[] = [];

	public cprCols: ICprCol[] = [];

	public cprRows: ICprRow[] = [];
}
