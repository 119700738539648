import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianFraudSystemConfigIndexResponse,
	GuardianFraudSystemConfigProfileResponse
} from 'RtModels';
import { GuardianApplicationRouter } from 'RtUi/app/rtGuardian/rtGuardian.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IFraudConfigurationRouterTabs
	extends IProfileApplicationContainerTabs {
	FraudConfigurations: TabbedLayoutTabProps;
	FraudAllowList: TabbedLayoutTabProps;
	FraudBlockList: TabbedLayoutTabProps;
	GlobalBlockList: TabbedLayoutTabProps;
	Recipients: TabbedLayoutTabProps;
}

export const FraudConfigurationContainerTabs: IFraudConfigurationRouterTabs = {
	FraudConfigurations: {
		header: 'Global Configuration',
		isDefault: true
	},
	FraudAllowList: {
		header: 'Allow List'
	},
	FraudBlockList: {
		header: 'Block List'
	},
	GlobalBlockList: {
		header: 'Global Block List'
	},
	Recipients: {
		header: 'Recipients'
	}
};

class FraudConfigurationRouterClass extends GuardianApplicationRouter<
	GuardianFraudSystemConfigIndexResponse,
	GuardianFraudSystemConfigProfileResponse,
	IFraudConfigurationRouterTabs
> {
	constructor() {
		super(
			'Fraud Configuration',
			'fraudConfiguration',
			'guardianFraudSystemConfigId',
			FraudConfigurationContainerTabs
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.GuardianFraudSystemConfig);
	}

	public getProfileLabel(
		profile: GuardianFraudSystemConfigProfileResponse
	): string {
		return profile.paramName;
	}

	public getPluralName() {
		return this.getName();
	}
}

export const FraudConfigurationRouter = new FraudConfigurationRouterClass();
