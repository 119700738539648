import {
	getApps as firebaseGetApps,
	initializeApp as firebaseInitializeApp
} from 'firebase/app';
import {
	update as firebaseDbUpdate,
	ref as firebaseGetRef,
	getDatabase as firebaseGetDb
} from 'firebase/database';
import {
	browserLocalPersistence,
	getAuth as firebaseGetAuth,
	setPersistence,
	signInWithCustomToken as firebaseAuthSignInWithCustomToken,
	signOut as firebaseAuthSignOut,
	Unsubscribe as FirebaseAuthUnsubscribe,
	User as FirebaseAuthUser
} from 'firebase/auth';
import { AuthorizationResponse } from 'RtModels';
import {
	BaseFireBase,
	IFirebaseUIMessage
} from 'RtUi/state/actions/user/BaseFireBase';

/**
 * Class for Firebase usage
 * @singleton
 */
export class FirebaseUser extends BaseFireBase {
	/**
	 * Singleton method
	 */
	public static getInstance() {
		if (!FirebaseUser.instance) {
			FirebaseUser.instance = new FirebaseUser();
		}

		return FirebaseUser.instance;
	}

	private static instance: FirebaseUser | null = null;

	private constructor() {
		super();

		const firebaseApps = firebaseGetApps();

		if (firebaseApps.length > 0) {
			this.firebaseApp = firebaseApps[0];
		} else {
			this.firebaseApp = firebaseInitializeApp(this.firebaseConfig);
		}

		this.isInitializedDefer = new Promise<FirebaseAuthUser | null>(
			(resolve) => {
				this.isInitializedResolver = resolve;
			}
		);
	}

	/**
	 * Sign in user to Firebase app
	 */
	public async signIn(loginInfo: AuthorizationResponse) {
		this.partitionUserId = loginInfo.partitionUserId;
		this.fireToken = loginInfo.fireToken;
		this.partitionId = loginInfo.partitionId;
		this.productIds = loginInfo.products;

		if (!this.fireToken) {
			throw new Error('FirebaseUser: fireToken is null.');
		}

		if (typeof loginInfo.userId !== 'number') {
			throw new Error('FirebaseUser: partitionUserId is not a number.');
		}

		const currentUser = await this.initAuthentication();

		if (currentUser) {
			const uid = String(loginInfo.userId);

			if (currentUser.uid === uid) {
				// User is already logged in
				return;
			}

			try {
				await this.signOut();
			} catch {
				/**/
			}
		}

		try {
			const firebaseAuth = firebaseGetAuth(this.firebaseApp);

			// Firebase Auth will span all tabs/windows
			// https://firebase.google.com/docs/auth/web/auth-state-persistence
			await setPersistence(firebaseAuth, browserLocalPersistence);

			await firebaseAuthSignInWithCustomToken(firebaseAuth, this.fireToken);
		} catch (err) {
			console.error(err);
		}
	}

	/**
	 * Mark a Firebase message as read
	 * @param uid
	 * @param message
	 */
	public async markUserMessageAsRead(message: IFirebaseUIMessage) {
		const firebaseMessage = this.serializeFirebaseUiMessage(message);
		const messagePath = this.getUserMessagePath(message);

		firebaseMessage.userHasRead = true;

		const database = firebaseGetDb(this.firebaseApp);
		const databaseRef = firebaseGetRef(database, messagePath);

		await firebaseDbUpdate(databaseRef, firebaseMessage);
	}

	/**
	 * Update Firebase message
	 * @param message
	 */
	public async updateMessage(message: IFirebaseUIMessage) {
		const firebaseMessage = this.serializeFirebaseUiMessage(message);
		const messagePath = this.getUserMessagePath(message);

		const database = firebaseGetDb(this.firebaseApp);
		const databaseRef = firebaseGetRef(database, messagePath);

		await firebaseDbUpdate(databaseRef, firebaseMessage);
	}

	/**
	 * Sign user out of Firebase
	 */
	public async signOut() {
		const firebaseAuth = firebaseGetAuth(this.firebaseApp);

		await firebaseAuthSignOut(firebaseAuth);
	}

	protected getDataBasePaths() {
		const databasePaths = [
			'global/messages/',
			`partitions/${this.partitionId}/messages/`,
			`partitions/${this.partitionId}/users/${this.partitionUserId}/messages/`,
			//Listen for every product
			...this.productIds.map((productId) => `products/${productId}/messages/`)
		];
		return databasePaths;
	}

	/**
	 * Update current firebase user
	 * @param currentUser
	 */
	private updateCurrentUser(currentUser: FirebaseAuthUser | null) {
		this.currentUser = currentUser;
	}

	private authStateChangeUnsubscribeFn: FirebaseAuthUnsubscribe = () => ({});

	/**
	 * Initializes Firebase Auth. Firebase user may be stored in localStorage
	 * and it takes about a second for that to be returned.
	 *
	 * Usage: in signIn() method it is used to wait and see if a user will be signed in from localStorage,
	 *        so that user will not be signed in multiple times.
	 *
	 * @returns null or the current firebase user
	 */
	private initAuthentication() {
		if (this.currentUser) {
			return Promise.resolve(this.currentUser);
		}

		this.authStateChangeUnsubscribeFn();

		const firebaseAuth = firebaseGetAuth(this.firebaseApp);

		this.authStateChangeUnsubscribeFn = firebaseAuth.onAuthStateChanged(
			(currentUser) => {
				this.updateCurrentUser(currentUser);

				if (currentUser) {
					this.isInitializedResolver(currentUser);
				}
			}
		);
	}

	/**
	 * Return a users message path.
	 * If a message is sent as an argument, then the path for that specific
	 * message is returned
	 */
	private getUserMessagePath(message?: IFirebaseUIMessage) {
		const messagesPath = `partitions/${this.partitionId}/users/${this.partitionUserId}/messages/`;

		if (!message) {
			return messagesPath;
		}

		const messagePath = `${messagesPath}${message.uuid}`;

		return messagePath;
	}
}
