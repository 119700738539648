/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum FilterReportsEnum {
    All = 'All',
    CountryList = 'Country_List',
    SipReleaseCodes = 'SIP_Release_Codes',
}