import { CdrExportIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { Button } from 'react-bootstrap';

interface DownloadCdrExportDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	isDownloading: (cdrExportId: number) => boolean;
	onDownload: (cdrExportId: number) => void;
}

export const DownloadCdrExportDataGridColumn = <T = any,>({
	size = 150,
	isDownloading,
	onDownload,
	...config
}: DownloadCdrExportDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const renderValue = (row: CdrExportIndexResponse) => {
		if (row.statusId !== 40) {
			return;
		}

		const isCdrDownloading = isDownloading(row.cdrExportId);

		return (
			<Button
				variant="primary"
				className="p-0"
				size="sm"
				disabled={isCdrDownloading}
				onClick={() => onDownload(row.cdrExportId)}
			>
				{isCdrDownloading && <i className="fas fa-fw fa-cog fa-spin" />}
				{!isCdrDownloading && <i className="fas fa-fw fa-download" />}
			</Button>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		exportValue: () => '',
		getValue: ({ row }) => renderValue(row.original as any)
	});
};
