/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationErrorMessageIndexRequest = {
    properties: {
        integrationId: {
            type: 'number',
        },
        intMessageType: {
            type: 'string',
        },
        intResponseCode: {
            type: 'string',
        },
        intResponseSummary: {
            type: 'string',
        },
        isWarning: {
            type: 'number',
        },
        isActive: {
            type: 'number',
        },
        code: {
            type: 'number',
        },
        label: {
            type: 'string',
        },
        summary: {
            type: 'string',
        },
        createdTs: {
            type: 'Date',
            format: 'date-time',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};