import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
	FileStreamIndexResponse,
	GuardianSwitchFileStreamCreateRequest,
	GuardianSwitchFileStreamProfileResponse
} from 'RtModels';
import {
	postFileStreams,
	useGetFileAllFileStreams
} from 'RtUi/app/AccountManagement/Switches/lib/services';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';

interface IGuardianSwitchFileStreamEditorProps {
	value?: GuardianSwitchFileStreamProfileResponse;
	switchId: number;
	onSuccess?: () => void;
	onCancel?: () => void;
	existingFileStream: GuardianSwitchFileStreamProfileResponse[] | [];
}

export const GuardianSwitchFileStreamEditor = ({
	value,
	switchId,
	onSuccess = () => {},
	onCancel = () => {},
	existingFileStream
}: IGuardianSwitchFileStreamEditorProps) => {
	const [newFileStream, setNewFileStream] =
		useState<FileStreamIndexResponse[]>();
	const [error, setError] = useState<RtError>();
	const [rawOptions, setRawOptions] = useState<any[]>([]);

	const { data: allFileStream, isLoading } = useGetFileAllFileStreams();

	const { mutateAsync } = useMutation(postFileStreams);

	const {
		control,
		formState: { isSubmitting },
		handleSubmit
	} = useForm<GuardianSwitchFileStreamProfileResponse>({
		defaultValues: {
			...value,
			switchId
		}
	});

	const onSubmitHandler = async (
		data:
			| GuardianSwitchFileStreamCreateRequest
			| GuardianSwitchFileStreamProfileResponse
	) => {
		setNewFileStream(undefined);
		setError(undefined);

		try {
			await mutateAsync(data as GuardianSwitchFileStreamCreateRequest);
			onSuccess();
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	const onCancelHandler = () => {
		setNewFileStream(undefined);
		onCancel();
	};

	useEffect(() => {
		if (!isLoading) {
			if (!isEmpty(existingFileStream)) {
				const filteredData = allFileStream.filter((val: any) => {
					return existingFileStream.every((v) => {
						return v.fileStreamId !== val.fileStreamId;
					});
				});
				setRawOptions(filteredData);
			} else {
				setRawOptions(allFileStream);
			}
		}
	}, [allFileStream, existingFileStream, isLoading]);

	return (
		<>
			{!isLoading && (
				<RtUiForm
					onSubmit={handleSubmit(onSubmitHandler)}
					onCancel={onCancelHandler}
					isSubmitting={isSubmitting}
					error={error}
				>
					<Controller
						control={control}
						name="fileStreamId"
						render={({ field: { onChange } }) => (
							<FileStreamSelect
								value={newFileStream}
								rawOptions={rawOptions}
								required
								multi
								onChange={(val) => {
									setNewFileStream(val);
									onChange(
										val.map((v: { fileStreamId: any }) => v.fileStreamId)
									);
								}}
							/>
						)}
					/>
				</RtUiForm>
			)}
		</>
	);
};
