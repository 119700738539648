import { IRtUiFormProps, RtUiForm } from 'RtUi/components/ui/RtUiForm/RtUiForm';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm/RtUiNarrowForm';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm/RtUiWideForm';

interface IRtForm extends IRtUiFormProps {
	children: React.ReactNode;
	wide?: boolean;
	narrow?: boolean;
}

export function RtForm(props: IRtForm) {
	let RtUiFormInstance: React.ComponentClass<IRtUiFormProps> = RtUiForm;

	if (props.wide) {
		RtUiFormInstance = RtUiWideForm;
	}

	if (props.narrow) {
		RtUiFormInstance = RtUiNarrowForm;
	}

	return <RtUiFormInstance {...props}>{props.children}</RtUiFormInstance>;
}
