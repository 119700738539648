import * as React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { TaskAction } from 'RtUi/app/rt800/Tasks/lib/components/TaskAction';
import { IMgiTaskLabelType } from 'RtUi/app/rt800/Tasks/lib/interfaces';
import { TaskRouter } from 'RtUi/app/rt800/Tasks/Task.router';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { Aside } from 'RtUi/components/ui/Aside';

interface INavCreateDropdownState {
	actionLabel: IMgiTaskLabelType | null;
	isCreateDropdownOpen: boolean;
	defaultRespOrgId?: string;
	defaultEntityId?: string;
}

export class NavCreateDropdown extends ApplicationContainer<
	{},
	INavCreateDropdownState
> {
	public state: INavCreateDropdownState = {
		actionLabel: null,
		isCreateDropdownOpen: false
	};

	public onAppStateChange() {
		if (typeof this.state.defaultRespOrgId === 'undefined') {
			const defaultRespOrgId = this.getUsersDefaultRespOrgId();

			this.setState({ defaultRespOrgId });
		}
	}

	public goToNewAction(actionLabel: IMgiTaskLabelType) {
		this.setState({ actionLabel, isCreateDropdownOpen: false });
	}

	public render() {
		const { actionLabel, isCreateDropdownOpen } = this.state;
		const indexTaskOptions = TaskRouter.getIndexMgiTaskOptions();
		const createIconStyle: React.CSSProperties = {
			transition: '150ms ease-in-out'
		};

		if (isCreateDropdownOpen) {
			createIconStyle.transform = 'rotate(135deg)';
		}

		return (
			<>
				{indexTaskOptions.length > 0 && (
					<Dropdown
						align="end"
						className="ms-1"
						show={isCreateDropdownOpen}
						onToggle={() =>
							this.setState({
								isCreateDropdownOpen: !isCreateDropdownOpen
							})
						}
					>
						<Dropdown.Toggle bsPrefix="p-0">
							<i className="fas fa-fw fa-plus-circle" style={createIconStyle} />
						</Dropdown.Toggle>
						<Dropdown.Menu className="card-body" style={{ width: 310 }}>
							<h6>
								<b>Create New Task</b>
							</h6>
							<hr />
							<ul className="list-unstyled">
								{indexTaskOptions.map((taskOption) => (
									<li key={taskOption.label} className="my-2">
										<a onClick={() => this.goToNewAction(taskOption.label)}>
											{taskOption.label}
										</a>
									</li>
								))}
							</ul>
						</Dropdown.Menu>
					</Dropdown>
				)}
				<Aside isOpen={Boolean(actionLabel)}>
					<header className="d-flex justify-content-between align-items-center mb-3">
						<h5 className="mb-0">
							<b>{actionLabel}</b>
						</h5>
						<Button
							type="button"
							variant="light"
							onClick={() => this.setState({ actionLabel: null })}
						>
							<i className="fas fa-fw fa-times" />
						</Button>
					</header>
					{actionLabel && (
						<TaskAction
							hideDescriptionHeader
							key={actionLabel}
							type={actionLabel}
							disablePadding
							defaultRespOrgId={this.state.defaultRespOrgId}
							onSuccess={() => this.setState({ actionLabel: null })}
						/>
					)}
				</Aside>
			</>
		);
	}
}
