import { LcrMinuteRecordType } from 'RtUi/app/rtLco/Minutes/lib/resources/LcrMinutesResource';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getMinutesCreateDataGrid } from 'RtUi/components/data/DataGrid/configurations/rtLco/minutes';
import { useMemo } from 'react';

interface ILcrMinutesGridProps {
	data: LcrMinuteRecordType[];
}

export const LcrMinutesCreateDataGrid = ({ data }: ILcrMinutesGridProps) => {
	const columns = useMemo(() => getMinutesCreateDataGrid(), []);

	return (
		<DataGrid<LcrMinuteRecordType>
			data={data}
			pageName="rtLcr_createMinutes"
			columns={columns}
		/>
	);
};
