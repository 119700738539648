/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtxFlowApiRoutes } from 'RtExports/routes';
import { FlowIndexRequest, FlowIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchFlows = async (
	urlParams?: FlowIndexRequest
): Promise<FullResponse<FlowIndexResponse[]>> => {
	return handleGetRequest<FlowIndexResponse[], true>(
		RtxFlowApiRoutes.Flows.Index,
		{
			urlParams,
			includeFullResponse: true
		}
	);
};

export const useGetFlows = (urlParams?: FlowIndexRequest) => {
	return useQuery<FullResponse<FlowIndexResponse[]>, Error>('getFlows', () =>
		fetchFlows(urlParams)
	);
};
