import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { RespOrgResource } from '../resources/RespOrgResource';
import { RespOrgRouter } from 'RtUi/app/rt800/RespOrgs/RespOrg.router';
import { RespOrgIndexResponse } from 'RtModels';
import { isEqual } from 'lodash-es';

interface IRespOrgSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<RespOrgIndexResponse, isMulti> {
	allowAll?: boolean;
	isActive?: boolean | null;
	entityId?: string;
	autoSelectIfSingleOption?: boolean;
}

export class RespOrgSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	RespOrgIndexResponse,
	isMulti,
	IRespOrgSelectProps<isMulti>
> {
	public static defaultProps = {
		router: RespOrgRouter
	};

	public resourceClass = RespOrgResource;
	public state: ISelectFormControlState<RespOrgIndexResponse> = {
		formLabel: 'RespOrg',
		valueKey: 'respOrgId',
		labelKey: 'label'
	};

	public componentDidMount() {
		super.componentDidMount();

		this.updateGetAllParams();
		this.autoSelectIfUserHasSingleRespOrg();
	}

	public componentDidUpdate(prevProps: IRespOrgSelectProps<isMulti>) {
		super.componentDidUpdate(prevProps);

		if (!isEqual(prevProps, this.props)) {
			this.updateGetAllParams();
		}
	}

	public optionRenderer = (respOrg: RespOrgIndexResponse) => (
		<span>
			{respOrg.respOrgId} ({respOrg.summary})
		</span>
	);

	public autoSelectIfUserHasSingleRespOrg() {
		const { autoSelectIfSingleOption = false } = this.props;

		if (!autoSelectIfSingleOption) {
			return;
		}

		this.getData().then((options) => {
			if (options.length !== 1) {
				return;
			}

			const singleOption = options[0];

			//@ts-ignore
			this.triggerOnChange(singleOption);
		});
	}

	public updateGetAllParams() {
		const getAllParams: any = {};
		const { isActive = true, allowAll = false, entityId } = this.props;

		if (isActive) {
			getAllParams.isActive = 1;
		} else if (isActive === false) {
			getAllParams.isActive = 0;
		}

		if (entityId) {
			getAllParams.entityId = entityId;
		}

		if (!allowAll) {
			getAllParams.isManaged = 1;
		}

		this.setGetAllParams(getAllParams);
	}

	public render() {
		const hasPermissions = RespOrgRouter.hasAccessToIndex();

		if (!hasPermissions) {
			return null;
		}

		return super.render();
	}
}
