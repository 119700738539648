import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	CdrExportDestinationCreateRequest,
	CdrExportDestinationDeleteRequest,
	CdrExportDestinationIndexRequest,
	CdrExportDestinationIndexResponse,
	CdrExportDestinationProfileUrlRequest,
	CdrExportDestinationUpdateRequest
} from 'RtModels';
import {
	FullResponse,
	handleDeleteRequest,
	handleGetRequest,
	handlePostRequest,
	handlePutRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const postCdrDestinationTypeSelect = (
	body: CdrExportDestinationCreateRequest
) => {
	return handlePostRequest(RtxSipApiRoutes.CdrExportDestinations.Create, body);
};

export const deleteCdrDestinationTypeSelect = (
	body: CdrExportDestinationDeleteRequest
) => {
	return handleDeleteRequest(RtxSipApiRoutes.CdrExportDestinations.Delete, {
		data: body
	});
};

interface IUpdateCdrDestinationTypeSelect {
	urlParams: CdrExportDestinationProfileUrlRequest;
	body: CdrExportDestinationUpdateRequest;
}

export const updateCdrDestinationTypeSelect = ({
	urlParams,
	body
}: IUpdateCdrDestinationTypeSelect) => {
	return handlePutRequest(RtxSipApiRoutes.CdrExportDestinations.Update, body, {
		urlParams
	});
};

const fetchCdrExportDestinations = async (
	queryParams?: CdrExportDestinationIndexRequest
): Promise<FullResponse<CdrExportDestinationIndexResponse[]>> => {
	return handleGetRequest<CdrExportDestinationIndexResponse[], true>(
		RtxSipApiRoutes.CdrExportDestinations.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetCdrExportDestinations = (
	queryParams?: CdrExportDestinationIndexRequest
) => {
	return useQuery<FullResponse<CdrExportDestinationIndexResponse[]>, Error>(
		[`useGetCdrExportDestinations`, queryParams],
		() => fetchCdrExportDestinations(queryParams),
		{ enabled: true }
	);
};
