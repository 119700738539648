import { InvoicesRouter } from 'RtUi/app/rtSip/Invoices/Invoices.router';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { InvoiceDataGrid } from 'RtUi/app/rtSip/Invoices/lib/grid/InvoiceDataGrid';
import { AccountIndexResponse, InvoiceIndexRequest } from 'RtModels';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import moment from 'moment';

interface IInitialResourceParams {
	startTs?: moment.Moment;
	endTs?: moment.Moment;
	account?: AccountIndexResponse;
}

interface IInvoicesIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@InvoicesRouter.getInvoicingIndexRtUiController()
export class InvoicesIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IInvoicesIndexContainerState,
	InvoiceIndexRequest
> {
	public initParams: IInitialResourceParams = {
		startTs: moment().subtract(60, 'days'),
		endTs: moment().endOf('day')
	};

	public state = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { startTs, endTs, account } = this.state;

		const resourceParams: InvoiceIndexRequest = {};

		if (startTs) {
			resourceParams.startTs = startTs.toDate();
		}

		if (endTs) {
			resourceParams.endTs = endTs.toDate();
		}

		if (account) {
			resourceParams.accountId = account.accountId;
		}

		return resourceParams;
	}

	public render() {
		return (
			<TabbedLayout
				router={InvoicesRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<DatetimeFormControl
							showDateOnly
							label="Start Date"
							onChange={(startTs) => this.setState({ startTs })}
							value={this.state.startTs}
						/>
						<DatetimeFormControl
							showDateOnly
							label="End Date"
							onChange={(endTs) => this.setState({ endTs })}
							value={this.state.endTs}
						/>
						<AccountSelect
							label="Account"
							onChange={(account: AccountIndexResponse) =>
								this.setState({ account })
							}
							value={this.state.account}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<InvoiceDataGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
