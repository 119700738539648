/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RoutingCacheTypes {
    TollFree = 1,
    Template = 2,
    MgiTask = 3,
    LcrGenerator = 4,
}