import { GuardianReconciliationTopOpenItemsIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

export const getTopDisputeItemsByPersonColumns = (): Array<
	DataGridColumn<GuardianReconciliationTopOpenItemsIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'chargeType',
		header: 'Charge Type'
	}),
	DefaultDataGridColumn({
		accessorKey: 'serviceDetail',
		header: 'Service Detail'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'disputedAmount',
		header: 'Disputed Amount'
	}),
	DefaultDataGridColumn({
		accessorKey: 'assignedToEmailAddress',
		header: 'Assigned To'
	})
];
