import { ManagedEntityIndexResponse } from 'RtModels';
//import * as React from 'react';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ManagedEntityResource } from '../resources/ManagedEntityResource';

interface IManagedEntitySelectSelectProps
	extends ISelectFormControlProps<ManagedEntityIndexResponse> {
	// allowAll?: boolean;
	// isActive?: boolean | null;
	// entityId?: string;
	// autoSelectIfSingleOption?: boolean;
}

export class ManagedEntitySelect extends SelectFormControl<
	ManagedEntityIndexResponse,
	false,
	IManagedEntitySelectSelectProps
> {
	public resourceClass = ManagedEntityResource;
	public state: ISelectFormControlState<ManagedEntityIndexResponse> = {
		formLabel: 'Managed Entity',
		valueKey: 'entityId',
		labelKey: 'entityId'
	};
}
