import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';
import { GuardianReconciliationBulkUpdateItem } from 'RtModels';
import { SpreadsheetParserEmitValidation } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserEmitValidation';
import { CurrencyFormatter } from 'RtUi/utils/currency/CurrencyFormatter';
import { NumberFormatter } from 'RtUi/utils/number/NumberFormatter';

export class GuardianReconciliationSpreadsheetParser extends SpreadsheetParserEmitValidation {
	public carrierBanNumberColumn = new SpreadsheetParserColumn(
		'carrierBanNumber',
		['Vendor Ban Number']
	).setIsRequired(true);

	public startTsColumn = new SpreadsheetParserColumn('startTs', [
		'Invoice Start Date'
	])
		.setIsRequired(true)
		.setIsDate();

	public endTsColumn = new SpreadsheetParserColumn('endTs', [
		'Invoice End Date'
	])
		.setIsRequired(true)
		.setIsDate();

	public carrierInvoiceConnectsColumn = new SpreadsheetParserColumn(
		'carrierInvoiceConnects',
		['Invoice Calls']
	)
		.setIsRequired(true)
		.setIsInteger();

	public carrierInvoiceDurationColumn = new SpreadsheetParserColumn(
		'carrierInvoiceDuration',
		['Invoice MOU']
	)
		.setIsRequired(true)
		.setIsDecimal();

	public carrierInvoiceChargeColumn = new SpreadsheetParserColumn(
		'carrierInvoiceCharge',
		['Invoice Cost']
	)
		.setIsCurrency()
		.setIsRequired(true);

	public customerExpectedConnectsColumn = new SpreadsheetParserColumn(
		'customerExpectedConnects',
		['Expected Calls']
	)
		.setIsRequired(true)
		.setIsInteger();

	public customerExpectedDurationColumn = new SpreadsheetParserColumn(
		'customerExpectedDuration',
		['Expected MOU']
	)
		.setIsRequired(true)
		.setIsDecimal();

	public customerExpectedChargeColumn = new SpreadsheetParserColumn(
		'customerExpectedCharge',
		['Expected Cost']
	)
		.setIsCurrency()
		.setIsRequired(true);

	constructor(spreadsheet: string[][]) {
		super(spreadsheet);

		this.addParserColumn(
			this.carrierBanNumberColumn,
			this.startTsColumn,
			this.endTsColumn,
			this.carrierInvoiceChargeColumn,
			this.carrierInvoiceConnectsColumn,
			this.carrierInvoiceDurationColumn,
			this.customerExpectedChargeColumn,
			this.customerExpectedConnectsColumn,
			this.customerExpectedDurationColumn
		);
	}

	public parseGuardianReconciliationIndexRecords(): GuardianReconciliationBulkUpdateItem[] {
		const guardianReconciliationBulkItems: GuardianReconciliationBulkUpdateItem[] =
			[];

		this.unknownFieldsMap = new Map<string, number>();

		const possibleHeaderMatches = this.findPossibleHeaderMatches();
		const possibleHeaderMatch = possibleHeaderMatches[0];

		const rows = this.parse(possibleHeaderMatch);

		const carrierBanNumberColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.carrierBanNumberColumn
		);
		const startTsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.startTsColumn
		);
		const endTsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.endTsColumn
		);
		const carrierInvoiceChargeColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.carrierInvoiceChargeColumn
		);
		const carrierInvoiceConnectsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.carrierInvoiceConnectsColumn
		);
		const carrierInvoiceDurationColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.carrierInvoiceDurationColumn
		);
		const customerExpectedChargeColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.customerExpectedChargeColumn
		);
		const customerExpectedConnectsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.customerExpectedConnectsColumn
		);
		const customerExpectedDurationColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.customerExpectedDurationColumn
		);

		for (const [i, row] of rows.entries()) {
			const rowIndex = i + possibleHeaderMatch.headerRowIndex + 1;

			const carrierBanNumber = this.emitValidationCheck(
				'carrierBanNumber',
				row[carrierBanNumberColumnIndex],
				rowIndex
			);
			const startTs = this.emitValidationCheck(
				'startTs',
				row[startTsColumnIndex],
				rowIndex
			);
			const endTs = this.emitValidationCheck(
				'endTs',
				row[endTsColumnIndex],
				rowIndex
			);
			const carrierInvoiceConnects = this.emitValidationCheck(
				'carrierInvoiceConnects',
				row[carrierInvoiceConnectsColumnIndex],
				rowIndex
			);
			const carrierInvoiceDuration = this.emitValidationCheck(
				'carrierInvoiceDuration',
				row[carrierInvoiceDurationColumnIndex],
				rowIndex
			);
			const carrierInvoiceCharge = this.emitValidationCheck(
				'carrierInvoiceCharge',
				row[carrierInvoiceChargeColumnIndex],
				rowIndex
			);
			const customerExpectedConnects = this.emitValidationCheck(
				'customerExpectedConnects',
				row[customerExpectedConnectsColumnIndex],
				rowIndex
			);
			const customerExpectedDuration = this.emitValidationCheck(
				'customerExpectedDuration',
				row[customerExpectedDurationColumnIndex],
				rowIndex
			);
			const customerExpectedCharge = this.emitValidationCheck(
				'customerExpectedCharge',
				row[customerExpectedChargeColumnIndex],
				rowIndex
			);

			guardianReconciliationBulkItems.push({
				carrierBanNumber,
				startTs,
				endTs,
				carrierInvoiceConnects: NumberFormatter.toNumber(
					carrierInvoiceConnects
				),
				carrierInvoiceDuration: NumberFormatter.toNumber(
					carrierInvoiceDuration
				),
				carrierInvoiceCharge: CurrencyFormatter.toNumber(carrierInvoiceCharge),
				customerExpectedConnects: NumberFormatter.toNumber(
					customerExpectedConnects
				),
				customerExpectedDuration: NumberFormatter.toNumber(
					customerExpectedDuration
				),
				customerExpectedCharge: CurrencyFormatter.toNumber(
					customerExpectedCharge
				)
			});
		}

		this.emitErrorMessage();

		return guardianReconciliationBulkItems;
	}
}
