import { AsrIndexResponseData } from 'RtModels';
import {
	ChartFlags,
	VueTotalsLineChartAbstract
} from 'RtUi/app/rtVue/common/lib/charts/VueTotalsLineChartAbstract';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { ChartJsColors } from 'RtUi/components/charts/Constants';

export class AsrTotalsLineChart extends VueTotalsLineChartAbstract<AsrIndexResponseData> {
	protected async getChartData() {
		const { resource } = this.props;

		if (!resource) {
			return {};
		}

		const asrIndexes = await resource.getAll();
		asrIndexes.sort((a, b) => {
			return a.callDate < b.callDate ? -1 : 1;
		});

		const asrChartData: ChartConfiguration = {
			type: 'line',
			data: {
				datasets: []
			}
		};

		let labelsAndProperties: Array<[string, string, string]> = [
			['Minutes', 'duration', ChartJsColors.LightOrange],
			['Attempts', 'attempts', ChartJsColors.LightBlue],
			['Connects', 'connects', ChartJsColors.LightGreen]
		];

		if (this.state.compareToDate) {
			labelsAndProperties = [
				...labelsAndProperties,
				['Minutes 2', 'duration2', ChartJsColors.DarkOrange],
				['Attempts 2', 'attempts2', ChartJsColors.DarkBlue],
				['Connects 2', 'connects2', ChartJsColors.DarkGreen]
			];
		}

		const labels = this.getLabels(asrIndexes);

		asrChartData.data.labels = labels;

		// Max labels when the chart should be visible
		if (asrChartData.data.labels.length > 300) {
			return ChartFlags.MAX_DATA_CEILING_HIT;
		}

		for (const [label, property, color] of labelsAndProperties) {
			const data: ChartDataset[] = [];

			for (const asrIndex of asrIndexes) {
				let value = 0;

				if (property in asrIndex) {
					value = parseInt(
						String(asrIndex[property as keyof AsrIndexResponseData]),
						10
					);
				}
				//@ts-expect-error
				data.push(value);
			}

			asrChartData.data.datasets.push({
				type: 'line',
				label,
				//@ts-expect-error
				data,
				fill: false,
				borderColor: color,
				backgroundColor: color,
				lineTension: 0.4
			});
		}

		return asrChartData;
	}
}
