import { FC, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { ImportCprForm } from 'RtUi/app/rt800/Cprs/lib/forms/ImportCprForm';
import { useGetCprActions } from 'RtUi/app/rt800/Cprs/lib/hooks/useGetCprActions';
import { Aside } from 'RtUi/components/ui/Aside';

interface IImportCprBtnProps {
	btnColor?: string;
	style?: React.CSSProperties;
	className?: string;
}

export const ImportCprBtn: FC<React.PropsWithChildren<IImportCprBtnProps>> = (
	props
) => {
	const { cpr, tfns, isNumberAndHasTemplateAssigned } =
		useContext(CprProfileContext);
	const { canCopy, canEdit, canTransfer } = useGetCprActions({ cpr, tfns });
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const canImport =
		!isNumberAndHasTemplateAssigned && (canCopy || canEdit || canTransfer);

	return (
		<>
			<Button
				variant={props.btnColor ?? 'white-alt'}
				type="button"
				disabled={!canImport}
				className={props.className}
				style={props.style}
				onClick={() => setModalIsOpen(true)}
			>
				<i className="fas fa-fw fa-download" />
				<span>&nbsp;Import</span>
			</Button>
			<Aside isOpen={modalIsOpen} disabledBody>
				<Aside.Header
					header="CPR Import"
					onClose={() => setModalIsOpen(false)}
				/>
				<ImportCprForm
					key={String(modalIsOpen)}
					btnStyle={props.style}
					btnClassName={props.className}
					onSuccess={() => setModalIsOpen(false)}
					onCancel={() => setModalIsOpen(false)}
				/>
			</Aside>
		</>
	);
};
