import { UserAuditIndexResponse } from 'RtModels';
import { useGetUsersAudit } from 'RtUi/app/user/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getUsersAuditColumns } from 'RtUi/components/data/DataGrid/configurations/user';
import { useMemo } from 'react';

export const UsersAuditGrid = () => {
	const { data, isFetching: isLoading } = useGetUsersAudit();
	const columns = useMemo(() => getUsersAuditColumns(), []);

	return (
		<DataGrid<UserAuditIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'users_audit'}
		/>
	);
};
