import { SpreadsheetParser } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParser';
import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';
import { LcrMinuteRecordType } from 'RtUi/app/rtLco/Minutes/lib/resources/LcrMinutesResource';
import { NumberFormatter } from 'RtUi/utils/number/NumberFormatter';

export class TrafficProfileSpreadsheetParser extends SpreadsheetParser {
	public static formatErrorMessage(
		fieldName: string,
		rowIndex: number,
		validationMessage = ''
	): string {
		return `Error at row: ${rowIndex} - \'${fieldName}\' ${validationMessage}`;
	}

	public aniNpaColumn = new SpreadsheetParserColumn('aniNpa', ['aniNpa']);
	public aniNxxColumn = new SpreadsheetParserColumn('aniNxx', ['aniNxx']);

	public lrnNpaColumn = new SpreadsheetParserColumn('lrnNpa', ['lrnNpa']);
	public lrnNxxColumn = new SpreadsheetParserColumn('lrnNxx', ['lrnNxx']);

	public aniNpaNxxColumn = new SpreadsheetParserColumn('aniNpaNxx', [
		'aniNpaNxx'
	])
		.setIsRequired(true)
		.setMinLength(6)
		.setDefaultValue('unkunk');
	public lrnNpaNxxColumn = new SpreadsheetParserColumn('lrnNpaNxx', [
		'lrnNpaNxx'
	])
		.setMinLength(6)
		.setDefaultValue('unkunk');
	public interConnectsColumn = new SpreadsheetParserColumn('interConnects', [
		'interConnects',
		'Inter. Connects'
	]).setIsRequired(true);
	public interMinutesColumn = new SpreadsheetParserColumn('interMinutes', [
		'interMinutes',
		'Inter. Minutes'
	]).setIsRequired(true);
	public interChargesColumn = new SpreadsheetParserColumn('interCharges', [
		'interCharges',
		'Inter. Charges'
	]).setIsRequired(true);
	public intraConnectsColumn = new SpreadsheetParserColumn('intraConnects', [
		'intraConnects',
		'Intra. Connects'
	]);
	public intraMinutesColumn = new SpreadsheetParserColumn('intraMinutes', [
		'intraMinutes',
		'Intra. Minutes'
	]);
	public intraChargesColumn = new SpreadsheetParserColumn('intraCharges', [
		'intraCharges',
		'Intra. Charges'
	]);
	public indetConnectsColumn = new SpreadsheetParserColumn('indetConnects', [
		'indetConnects',
		'Indet. Connects'
	]);
	public indetMinutesColumn = new SpreadsheetParserColumn('indetMinutes', [
		'indetMinutes',
		'Indet. Minutes	'
	]);
	public indetChargesColumn = new SpreadsheetParserColumn('indetCharges', [
		'indetCharges',
		'Indet. Charges'
	]);
	public totalConnectsColumn = new SpreadsheetParserColumn('totalConnects', [
		'totalConnects',
		'Total Connects'
	]).setIsRequired(true);
	public totalMinutesColumn = new SpreadsheetParserColumn('totalMinutes', [
		'totalMinutes',
		'Total Minutes'
	]).setIsRequired(true);
	public totalChargesColumn = new SpreadsheetParserColumn('totalCharges', [
		'totalCharges',
		'Total Charges'
	]).setIsRequired(true);

	// Limit number of errors, no sens to find them all if there are more then 100
	// this will allow to parse quick and show first 100 errors
	private readonly MAX_ERROR_MESSAGES_EMIT_LIMIT = 100;
	private unknownFieldsMap = new Map<string, number>();
	private totalErrors: number = 0;

	constructor(spreadsheet: string[][]) {
		super(spreadsheet);

		this.addParserColumn(
			this.aniNpaColumn,
			this.aniNxxColumn,
			this.lrnNpaColumn,
			this.lrnNxxColumn,
			this.aniNpaNxxColumn,
			this.lrnNpaNxxColumn,
			this.interConnectsColumn,
			this.interMinutesColumn,
			this.interChargesColumn,
			this.intraConnectsColumn,
			this.intraMinutesColumn,
			this.intraChargesColumn,
			this.indetConnectsColumn,
			this.indetMinutesColumn,
			this.indetChargesColumn,
			this.totalConnectsColumn,
			this.totalMinutesColumn,
			this.totalChargesColumn
		);
	}

	public parseTrafficProfileRecords(): LcrMinuteRecordType[] {
		const trafficProfiles: LcrMinuteRecordType[] = [];
		this.unknownFieldsMap = new Map<string, number>();

		const possibleHeaderMatches = this.findPossibleHeaderMatches();
		const possibleHeaderMatch = possibleHeaderMatches[0];

		const rows = this.parse(possibleHeaderMatch);

		const aniNpaColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.aniNpaColumn
		);
		const aniNxxColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.aniNxxColumn
		);
		const lrnNpaColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.lrnNpaColumn
		);
		const lrnNxxColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.lrnNxxColumn
		);
		const aniNpaNxxColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.aniNpaNxxColumn
		);
		const lrnNpaNxxColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.lrnNpaNxxColumn
		);
		const interConnectsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.interConnectsColumn
		);
		const interMinutesColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.interMinutesColumn
		);
		const interChargesColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.interChargesColumn
		);
		const intraConnectsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.intraConnectsColumn
		);
		const intraMinutesColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.intraMinutesColumn
		);
		const intraChargesColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.intraChargesColumn
		);
		const indetConnectsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.indetConnectsColumn
		);
		const indetMinutesColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.indetMinutesColumn
		);
		const indetChargesColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.indetChargesColumn
		);
		const totalConnectsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.totalConnectsColumn
		);
		const totalMinutesColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.totalMinutesColumn
		);
		const totalChargesColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.totalChargesColumn
		);

		for (const [i, row] of rows.entries()) {
			const rowIndex = i + possibleHeaderMatch.headerRowIndex + 1;

			const aniNpa = row[aniNpaColumnIndex];
			const aniNxx = row[aniNxxColumnIndex];
			const lrnNpa = row[lrnNpaColumnIndex];
			const lrnNxx = row[lrnNxxColumnIndex];

			const aniNpaNxx =
				aniNpa && aniNxx
					? `${aniNpa}${aniNxx}`
					: this.emitValidationCheck(
							'aniNpaNxx',
							row[aniNpaNxxColumnIndex],
							rowIndex
						);
			const lrnNpaNxx =
				lrnNpa && lrnNxx
					? `${lrnNpa}${lrnNxx}`
					: this.emitValidationCheck(
							'lrnNpaNxx',
							row[lrnNpaNxxColumnIndex],
							rowIndex
						);
			const interConnects = this.emitValidationCheck(
				'interConnects',
				row[interConnectsColumnIndex]
					? NumberFormatter.toNumber(row[interConnectsColumnIndex])
					: NaN,
				rowIndex
			);
			const interMinutes = this.emitValidationCheck(
				'interMinutes',
				row[interMinutesColumnIndex]
					? NumberFormatter.toNumber(row[interMinutesColumnIndex])
					: NaN,
				rowIndex
			);
			const interCharges = this.emitValidationCheck(
				'interCharges',
				row[interChargesColumnIndex]
					? NumberFormatter.toNumber(row[interChargesColumnIndex]) >= 0
						? NumberFormatter.toNumber(row[interChargesColumnIndex])
						: 0
					: NaN,
				rowIndex
			);
			const intraConnects = NumberFormatter.toNumber(
				row[intraConnectsColumnIndex]
			);
			const intraMinutes = NumberFormatter.toNumber(
				row[intraMinutesColumnIndex]
			);
			const intraCharges = NumberFormatter.toNumber(
				row[intraChargesColumnIndex]
			);
			const indetConnects = NumberFormatter.toNumber(
				row[indetConnectsColumnIndex]
			);
			const indetMinutes = NumberFormatter.toNumber(
				row[indetMinutesColumnIndex]
			);
			const indetCharges = NumberFormatter.toNumber(
				row[indetChargesColumnIndex]
			);
			const totalConnects = this.emitValidationCheck(
				'totalConnects',
				row[totalConnectsColumnIndex]
					? NumberFormatter.toNumber(row[totalConnectsColumnIndex])
					: NaN,
				rowIndex
			);
			const totalMinutes = this.emitValidationCheck(
				'totalMinutes',
				row[totalMinutesColumnIndex]
					? NumberFormatter.toNumber(row[totalMinutesColumnIndex])
					: NaN,
				rowIndex
			);
			const totalCharges = this.emitValidationCheck(
				'totalCharges',
				row[totalChargesColumnIndex]
					? NumberFormatter.toNumber(row[totalChargesColumnIndex]) >= 0
						? NumberFormatter.toNumber(row[totalChargesColumnIndex])
						: 0
					: NaN,
				rowIndex
			);

			const trafficProfileRecord: LcrMinuteRecordType = {
				aniNpaNxx,
				lrnNpaNxx,
				interConnects: Number.isFinite(interConnects) ? interConnects : 0,
				interMinutes: Number.isFinite(interMinutes) ? interMinutes : 0,
				interCharges: Number.isFinite(interCharges) ? interCharges : 0,
				intraConnects: Number.isFinite(intraConnects) ? intraConnects : 0,
				intraMinutes: Number.isFinite(intraMinutes) ? intraMinutes : 0,
				intraCharges: Number.isFinite(intraCharges) ? intraCharges : 0,
				indetConnects: Number.isFinite(indetConnects) ? indetConnects : 0,
				indetMinutes: Number.isFinite(indetMinutes) ? indetMinutes : 0,
				indetCharges: Number.isFinite(indetCharges) ? indetCharges : 0,
				totalConnects: Number.isFinite(totalConnects) ? totalConnects : 0,
				totalMinutes: Number.isFinite(totalMinutes) ? totalMinutes : 0,
				totalCharges: Number.isFinite(totalCharges) ? totalCharges : 0
			};

			trafficProfiles.push(trafficProfileRecord);
		}

		if (this.unknownFieldsMap.size > 0) {
			for (const [key, value] of this.unknownFieldsMap) {
				this.emit(
					'error',
					`There is ${value} values set as unknown for \"${key}\" field`
				);
			}
		}

		return trafficProfiles;
	}

	private emitValidationCheck<K extends keyof LcrMinuteRecordType>(
		fieldName: K,
		fieldValue: LcrMinuteRecordType[K],
		rowIndex: number
	): LcrMinuteRecordType[K] {
		if (this.totalErrors > this.MAX_ERROR_MESSAGES_EMIT_LIMIT) {
			return fieldValue;
		}

		const column = this.columns.find(
			(column) => column.getColumnName() === fieldName
		);

		if (!column) {
			console.warn(
				'Can not find column config for validation, please double check configuration.'
			);
			return fieldValue;
		}

		const errorMessages: string[] = [];

		if (column.isRequired()) {
			let isDefined = typeof fieldValue !== undefined && fieldValue !== null;

			if (isDefined) {
				if (typeof fieldValue === 'string') {
					isDefined = fieldValue.length > 0;
				} else if (typeof fieldValue === 'number') {
					isDefined = !isNaN(fieldValue) && fieldValue >= 0;
				}
			}

			if (!isDefined) {
				if (column.getDefaultValue()) {
					this.countAsUnknownField(fieldName);
					fieldValue = column.getDefaultValue() as LcrMinuteRecordType[K];
				} else {
					errorMessages.push('is required');
				}
			}
		}

		const columnMinLength = column?.getMinLength();
		if (
			columnMinLength !== undefined &&
			typeof fieldValue === 'string' &&
			fieldValue.length < columnMinLength
		) {
			if (column.getDefaultValue()) {
				this.countAsUnknownField(fieldName);
				fieldValue = column.getDefaultValue() as LcrMinuteRecordType[K];
			} else {
				errorMessages.push(
					`should contain at least ${column.getMinLength()} chars`
				);
			}
		}

		if (errorMessages.length > 0) {
			this.emit(
				'error',
				TrafficProfileSpreadsheetParser.formatErrorMessage(
					fieldName,
					rowIndex,
					errorMessages.join(', ')
				)
			);
			this.totalErrors += errorMessages.length;
		}

		return fieldValue;
	}

	private countAsUnknownField(fieldName: string) {
		const lastUnknownFieldCount = this.unknownFieldsMap.get(fieldName);

		if (!lastUnknownFieldCount) {
			this.unknownFieldsMap.set(fieldName, 1);
		} else {
			this.unknownFieldsMap.set(fieldName, lastUnknownFieldCount + 1);
		}
	}
}
