/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $JobStepExtended = {
    properties: {
        jobStepId: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        jobId: {
            type: 'string',
            isRequired: true,
        },
        flowStepId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        sequence: {
            type: 'number',
            isRequired: true,
        },
        integrationTaskTypeId: {
            type: 'number',
            isRequired: true,
        },
        jobStatusId: {
            type: 'number',
            isRequired: true,
        },
        continueOnErrors: {
            type: 'number',
            isRequired: true,
        },
        omitErrors: {
            type: 'number',
            isRequired: true,
        },
        totalQty: {
            type: 'number',
            isRequired: true,
        },
        pendingQty: {
            type: 'number',
            isRequired: true,
        },
        warningQty: {
            type: 'number',
            isRequired: true,
        },
        errorQty: {
            type: 'number',
            isRequired: true,
        },
        startedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        completedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        stepResources: {
            type: 'Array',
            isRequired: true,
        },
    },
};