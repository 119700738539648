import {
	ITollFreeReserveActivated,
	ITollFreeReserveStatus
} from 'RtUi/app/rt800/SearchAndReserve/lib/grids/SearchAndReserveFiltersGrid';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { Alert } from 'react-bootstrap';

interface TollFreeReservedStatusDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	status: ITollFreeReserveActivated;
}

export const TollFreeReservedActivatedStatusDataGridColumn = <T = any,>({
	status: reserveActivated,
	...config
}: TollFreeReservedStatusDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getReserveStatus = (tfn: string): ITollFreeReserveStatus => {
		let activatedNumber: ITollFreeReserveStatus = {
			isSubmitting: false,
			isSuccess: false
		};

		if (tfn in reserveActivated) {
			activatedNumber = reserveActivated[tfn];
		}

		return activatedNumber;
	};

	const getBadgeValues = (reservedStatus: ITollFreeReserveStatus) => {
		let badgeColor = '';
		let badgeText = '';

		if (reservedStatus.isSuccess) {
			badgeColor = 'success';
			badgeText = 'Reserved!';
		} else if (reservedStatus.isSubmitting) {
			badgeColor = 'warning';
			badgeText = 'Submitting...';
		} else if (reservedStatus.error) {
			badgeColor = 'danger';
			badgeText = 'Unable to Reserve';
		}

		return { badgeColor, badgeText };
	};

	const renderValue = (value: string) => {
		const reservedNumber = getReserveStatus(value);
		const { badgeColor, badgeText } = getBadgeValues(reservedNumber);

		return (
			<Alert
				transition={false}
				style={{ textAlign: 'center', minWidth: 125 }}
				variant={badgeColor}
				className="d-inline-block alert-sm mb-0 py-0 px-3"
			>
				{reservedNumber.isSubmitting && (
					<>
						<i className="fas fa-cog fa-spin" />
						&nbsp;
					</>
				)}
				{badgeText}
			</Alert>
		);
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value) => {
			const reservedNumber = getReserveStatus(value);
			const { badgeText } = getBadgeValues(reservedNumber);

			return badgeText;
		},
		getValue: ({ cell }) => renderValue(cell.getValue<string>())
	});
};
