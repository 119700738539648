import { RtCommonApiRoutes } from 'RtExports/routes';
import { DownloadIndexResponse, DownloadProfileResponse } from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class DownloadsResource extends HttpResource<
	DownloadIndexResponse,
	DownloadProfileResponse
> {
	constructor() {
		super(RtCommonApiRoutes.Downloads);
	}
}
