/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RespOrgContactIndexResponse = {
    properties: {
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        contactTypeId: {
            type: 'number',
            isRequired: true,
        },
        contactType: {
            type: 'string',
            isRequired: true,
        },
        name: {
            type: 'string',
            isRequired: true,
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        phone: {
            type: 'number',
            isRequired: true,
        },
        fax: {
            type: 'number',
            isRequired: true,
        },
    },
};