/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum JurisPrecedenceOptions {
    Lrn = 'lrn',
    Ani = 'ani',
    Jip = 'jip',
    Pai = 'pai',
    Poi = 'poi',
    Inter = 'Inter',
    Intra = 'Intra',
    Indet = 'Indet',
    Piu = 'piu',
}