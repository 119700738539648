import clsx from 'clsx';
import { FC } from 'react';
import { useBoolean } from 'react-use';
import { Button } from 'react-bootstrap';

interface IDownloadButtonProps {
	downloadFn: () => Promise<any>;
}

export const DownloadButton: FC<
	React.PropsWithChildren<IDownloadButtonProps>
> = ({ downloadFn }) => {
	const [isDownloading, setIsDownloading] = useBoolean(false);

	return (
		<Button
			variant="primary"
			size="sm"
			disabled={isDownloading}
			onClick={async () => {
				setIsDownloading(true);
				try {
					await downloadFn();
				} catch (err) {
					console.error(err);
				} finally {
					setIsDownloading(false);
				}
			}}
		>
			<i
				className={clsx('fas fa-fw', {
					'fa-download': !isDownloading,
					'fa-cog fa-spin': isDownloading
				})}
			/>
		</Button>
	);
};
