import {
	CdrExportControlCreateRequest,
	CdrExportControlIndexResponse,
	CdrExportControlUpdateRequest,
	CdrExportControlProfileRequest
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class CdrRecurringExportResource extends HttpResource<CdrExportControlIndexResponse> {
	constructor() {
		super(RtxSipApiRoutes.CdrExportControl);
	}

	public create(
		isActive: number,
		cdrSourceTypeId: number,
		cdrSourceValue: number,
		cdrExportServiceId: number,
		cdrExportIntervalId: number,
		cdrExportRetentionId: number,
		isConnected: number,
		nextRunTimeTs: Date
	) {
		const req: CdrExportControlCreateRequest = {
			isActive,
			cdrSourceTypeId,
			cdrSourceValue,
			cdrExportServiceId,
			cdrExportIntervalId,
			cdrExportRetentionId,
			isConnected,
			nextRunTimeTs
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute<CdrExportControlIndexResponse>(
			RtxSipApiRoutes.CdrExportControl.Create,
			{ body }
		);
	}

	public update(
		cdrExportControlId: number,
		isActive: number,
		cdrSourceTypeId: number,
		cdrSourceValue: number,
		cdrExportServiceId: number,
		cdrExportIntervalId: number,
		cdrExportRetentionId: number,
		isConnected: number,
		nextRunTimeTs: Date
	) {
		const req: CdrExportControlUpdateRequest = {
			isActive,
			cdrSourceTypeId,
			cdrSourceValue,
			cdrExportServiceId,
			cdrExportIntervalId,
			cdrExportRetentionId,
			isConnected,
			nextRunTimeTs
		};
		const body = JSON.stringify(req);
		const urlParams: CdrExportControlProfileRequest = {
			cdrExportControlId
		};

		return this.fetchWithRoute<CdrExportControlIndexResponse>(
			RtxSipApiRoutes.CdrExportControl.Update,
			{ body, urlParams }
		);
	}
}
