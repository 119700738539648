import { GuardianServiceNumberSubscriptionCreateRequestBulkItem } from 'RtModels';
import { ServiceNumberResource } from 'RtUi/app/rtCommon/ServiceNumbers/lib/resources/ServiceNumberResource';
import { ScenarioResource } from 'RtUi/app/rtSip/Scenarios/lib/resources/ScenarioResource';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { convertDateToUtc } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { UserActions } from 'RtUi/state/actions/user';
import { KnownPartitions } from 'RtUi/state/actions/user/Constants';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { SpreadsheetParser } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParser';
import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';
import { StandardEventEmitterTypes } from 'RtUi/utils/file/TypedEventEmitter';

export class GuardianServiceNumberSubscriptionSpreadsheetParser extends SpreadsheetParser {
	/**
	 * Downloads an example file
	 * TODO: abstract to super class
	 */
	public static downloadExampleFile() {
		const instance = new GuardianServiceNumberSubscriptionSpreadsheetParser([]);
		const exampleArr: Array<Record<string, any>> = [];
		const fileUtils = new FileUtils();

		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let colIdx = 0; colIdx < instance.columns.length; colIdx++) {
			const column = instance.columns[colIdx];
			const firstKeyword = column.getSearchKeywords()[0];
			const examples = column.getExamples();

			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let rowIdx = 0; rowIdx < examples.length; rowIdx++) {
				if (typeof exampleArr[rowIdx] === 'undefined') {
					exampleArr[rowIdx] = {};
				}

				exampleArr[rowIdx][firstKeyword] = examples[rowIdx];
			}
		}

		return fileUtils.downloadCSV(exampleArr, 'service_number_example.csv');
	}

	public static formatErrorMessage(
		fieldName: string,
		rowIndex: number,
		validationMessage = ''
	) {
		return `Error at row: ${rowIndex} - \'${fieldName}\' ${validationMessage}`;
	}

	public serviceNumberColumn = new SpreadsheetParserColumn('serviceNumber', [
		'service number',
		'number'
	])
		.setIsRequired(true)
		.setExamples('8007129876', '9157402358');

	public subscriptionIdColumn = new SpreadsheetParserColumn('subscriptionId')
		.setIsInteger()
		.setExamples('22', '29')
		.setIsRequired(true);

	public callTypeColumn = new SpreadsheetParserColumn('callType')
		.setIsRequired(true)
		.setExamples('TF', 'TF');

	public startTsColumn = new SpreadsheetParserColumn('startTs', [
		'start date',
		'start'
	])
		.setIsRequired(true)
		.setExamples('3/19/2022', '3/20/2022');

	public scenarioIdColumn = new SpreadsheetParserColumn('scenarioId')
		.setIsRequired(true)
		.setExamples('5', '6');

	public portingStatusIdColumn = new SpreadsheetParserColumn(
		'portingStatusId'
	).setExamples('1', '2');

	public endTsColumn = new SpreadsheetParserColumn('endTs', [
		'end date',
		'end'
	]).setExamples('3/25/2022', '3/26/2022');

	public isActiveColumn = new SpreadsheetParserColumn('isActive')
		.setIsInteger()
		.setExamples('yes', 'no');
	public isPortedColumn = new SpreadsheetParserColumn('isPorted')
		.setIsInteger()
		.setExamples('yes', 'no');
	public cnamRegColumn = new SpreadsheetParserColumn('cnamReg')
		.setIsInteger()
		.setExamples('yes', 'no');
	public cnamDipColumn = new SpreadsheetParserColumn('cnamDip')
		.setIsInteger()
		.setExamples('yes', 'no');
	public isDirAsstColumn = new SpreadsheetParserColumn('isDirAsst')
		.setIsInteger()
		.setExamples('yes', 'no');
	public isE911Column = new SpreadsheetParserColumn('isE911')
		.setIsInteger()
		.setExamples('yes', 'no');
	public labelColumn = new SpreadsheetParserColumn('label').setExamples(
		'vendor number 1',
		'vendor number 2'
	);

	public translatedNumberStdColumn = new SpreadsheetParserColumn(
		'translatedNumberStd'
	).setExamples('+18007129876', '+19157402358');
	public pinColumn = new SpreadsheetParserColumn('pin').setExamples(
		'1234',
		'4455'
	);
	public notesColumn = new SpreadsheetParserColumn('notes').setExamples(
		'some notes',
		'more notes'
	);
	public dirAsstNameColumn = new SpreadsheetParserColumn(
		'dirAsstName'
	).setExamples('John Doe', 'Jane Doe');
	public dirAsstAddress1Column = new SpreadsheetParserColumn(
		'dirAsstAddress1'
	).setExamples('123 Park Way', '8899 Some Dr');
	public dirAsstAddress2Column = new SpreadsheetParserColumn(
		'dirAsstAddress2'
	).setExamples('', '');
	public dirAsstCityColumn = new SpreadsheetParserColumn(
		'dirAsstCity'
	).setExamples('Austin', 'Dallas');
	public dirAsstStateColumn = new SpreadsheetParserColumn(
		'dirAsstState'
	).setExamples('TX', 'TX');
	public dirAsstZipColumn = new SpreadsheetParserColumn(
		'dirAsstZip'
	).setExamples('78799', '74566');
	public e911NameColumn = new SpreadsheetParserColumn('e911Name').setExamples(
		'John Doe',
		'Jane Doe'
	);
	public e911Address1Column = new SpreadsheetParserColumn(
		'e911Address1'
	).setExamples('123 Park Way', '8899 Some Dr');
	public e911Address2Column = new SpreadsheetParserColumn(
		'e911Address2'
	).setExamples('', '');
	public e911CityColumn = new SpreadsheetParserColumn('e911City').setExamples(
		'Austin',
		'Dallas'
	);
	public e911StateColumn = new SpreadsheetParserColumn('e911State').setExamples(
		'TX',
		'TX'
	);
	public e911ZipColumn = new SpreadsheetParserColumn('e911Zip').setExamples(
		'78799',
		'745666'
	);
	public custOrderNumberColumn = new SpreadsheetParserColumn(
		'custOrderNumber'
	).setExamples('99-2222', '88-45333');
	public custExtRef1Column = new SpreadsheetParserColumn(
		'custExtRef1'
	).setExamples('', '');
	public custExtRef2Column = new SpreadsheetParserColumn(
		'custExtRef2'
	).setExamples('', '');
	public custExtRef3Column = new SpreadsheetParserColumn(
		'custExtRef3'
	).setExamples('', '');
	public vendExtRef1Column = new SpreadsheetParserColumn(
		'vendExtRef1'
	).setExamples('', '');
	public vendExtRef2Column = new SpreadsheetParserColumn(
		'vendExtRef2'
	).setExamples('', '');

	public assignedTsColumn = new SpreadsheetParserColumn('assignedTs', [
		'assigned date',
		'assign date'
	]).setExamples('', '');
	public portedTsColumn = new SpreadsheetParserColumn('portedTs', [
		'ported date',
		'port date'
	]).setExamples('', '');
	public activatedTsColumn = new SpreadsheetParserColumn('activatedTs', [
		'activated date',
		'activate date'
	]).setExamples('', '');
	public decommissionedTsColumn = new SpreadsheetParserColumn(
		'decommissionedTs',
		['decommissioned date', 'decommission date']
	).setExamples('', '');
	public deletedTsColumn = new SpreadsheetParserColumn('deletedTs', [
		'deleted date',
		'delete date'
	]).setExamples('', '');

	// Limit number of errors, no sens to find them all if there are more then 100
	// this will allow to parse quick and show first 100 errors
	private readonly MAX_ERROR_MESSAGES_EMIT_LIMIT = 100;
	private unknownFieldsMap = new Map<string, number>();
	private totalErrors: number = 0;

	constructor(spreadsheet: string[][]) {
		super(spreadsheet);

		this.addParserColumn(
			this.serviceNumberColumn,
			this.subscriptionIdColumn,
			this.callTypeColumn,
			this.startTsColumn,
			this.endTsColumn,
			this.isActiveColumn,
			this.isPortedColumn,
			this.cnamRegColumn,
			this.cnamDipColumn,
			this.isDirAsstColumn,
			this.isE911Column,
			this.labelColumn,
			this.scenarioIdColumn,
			this.translatedNumberStdColumn,
			this.portingStatusIdColumn,
			this.pinColumn,
			this.notesColumn,
			this.dirAsstNameColumn,
			this.dirAsstAddress1Column,
			this.dirAsstAddress2Column,
			this.dirAsstCityColumn,
			this.dirAsstStateColumn,
			this.dirAsstZipColumn,
			this.e911NameColumn,
			this.e911Address1Column,
			this.e911Address2Column,
			this.e911CityColumn,
			this.e911StateColumn,
			this.e911ZipColumn,
			this.custOrderNumberColumn,
			this.custExtRef1Column,
			this.custExtRef2Column,
			this.custExtRef3Column,
			this.vendExtRef1Column,
			this.vendExtRef2Column,
			this.assignedTsColumn,
			this.portedTsColumn,
			this.activatedTsColumn,
			this.decommissionedTsColumn,
			this.deletedTsColumn
		);
	}

	public async parseGuardianServiceNumberRecord(
		filename: string
	): Promise<GuardianServiceNumberSubscriptionCreateRequestBulkItem[]> {
		const profiles: GuardianServiceNumberSubscriptionCreateRequestBulkItem[] =
			[];

		this.unknownFieldsMap = new Map<string, number>();

		const possibleHeaderMatches = this.findPossibleHeaderMatches();
		const possibleHeaderMatch = possibleHeaderMatches[0];
		const subscriptionResource = new SubscriptionResource();
		const scenarioResource = new ScenarioResource();
		const serviceNumberHttp = new ServiceNumberResource();

		const [subscriptions, scenarios, portingStatuses] = await Promise.all([
			subscriptionResource.getAll(),
			scenarioResource.getAll(),
			serviceNumberHttp.getPortingStatuses()
		]);

		const rows = this.parse(possibleHeaderMatch);

		const serviceNumberColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.serviceNumberColumn
		);
		const subscriptionIdColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.subscriptionIdColumn
		);
		const callTypeColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.callTypeColumn
		);
		const startTsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.startTsColumn
		);

		if (startTsColumnIndex < 0) {
			this.emit(
				'info',
				'Start date was not found. The current date will be used.'
			);
		}

		const endTsColumIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.endTsColumn
		);
		const isActiveColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.isActiveColumn
		);
		const cnamRegColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.cnamRegColumn
		);
		const cnamDipColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.cnamDipColumn
		);
		const isDirAsstColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.isDirAsstColumn
		);
		const isPortedColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.isPortedColumn
		);
		const isE911ColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.isE911Column
		);
		const labelColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.labelColumn
		);
		const scenarioIdColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.scenarioIdColumn
		);
		const translatedNumberStdColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.translatedNumberStdColumn
		);
		const portingStatusIdColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.portingStatusIdColumn
		);
		const pinColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.pinColumn
		);
		const notesColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.notesColumn
		);
		const dirAsstNameColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.dirAsstNameColumn
		);
		const dirAsstAddress1ColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.dirAsstAddress1Column
		);
		const dirAsstAddress2ColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.dirAsstAddress2Column
		);
		const dirAsstCityColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.dirAsstCityColumn
		);
		const dirAsstStateColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.dirAsstStateColumn
		);
		const dirAsstZipColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.dirAsstZipColumn
		);
		const e911NameColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.e911NameColumn
		);
		const e911Address1ColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.e911Address1Column
		);
		const e911Address2ColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.e911Address2Column
		);
		const e911CityColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.e911CityColumn
		);
		const e911StateColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.e911StateColumn
		);
		const e911ZipColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.e911ZipColumn
		);
		const custOrderNumberColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.custOrderNumberColumn
		);
		const custExtRef1ColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.custExtRef1Column
		);
		const custExtRef2ColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.custExtRef2Column
		);
		const custExtRef3ColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.custExtRef3Column
		);
		const vendExtRef1ColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.vendExtRef1Column
		);
		const vendExtRef2ColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.vendExtRef2Column
		);
		const assignedTsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.assignedTsColumn
		);
		const portedTsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.portedTsColumn
		);
		const activatedTsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.activatedTsColumn
		);
		const decommissionedTsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.decommissionedTsColumn
		);
		const deletedTsColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.deletedTsColumn
		);

		const defaultPin = this.getDefaultPin();

		for (const [i, row] of rows.entries()) {
			const rowIndex = i + possibleHeaderMatch.headerRowIndex + 1;

			let serviceNumberRaw = row[serviceNumberColumnIndex];
			const internationalPrefix = '+1';

			if (serviceNumberRaw.startsWith(internationalPrefix)) {
				serviceNumberRaw = serviceNumberRaw.substring(
					internationalPrefix.length
				);
			}

			//remove non-digits
			serviceNumberRaw = serviceNumberRaw.replace(/\D/g, '');

			const serviceNumber = this.emitValidationCheck(
				'serviceNumber',
				serviceNumberRaw,
				rowIndex
			);

			const subscriptionId = this.emitValidationCheck(
				'subscriptionId',
				Number(row[subscriptionIdColumnIndex]),
				rowIndex
			);

			const subscriptionIdExists = subscriptions.some(
				(sub) => sub.subscriptionId === subscriptionId
			);

			if (!subscriptionIdExists) {
				this.emit(
					'error',
					GuardianServiceNumberSubscriptionSpreadsheetParser.formatErrorMessage(
						'subscriptionId',
						rowIndex,
						`No subscriptions were found with with id ${subscriptionId}`
					)
				);
			}

			const callType = this.emitValidationCheck(
				'callType',
				row[callTypeColumnIndex],
				rowIndex
			);
			const startTs = this.emitValidationCheck(
				'startTs',
				this.parseOptionalDate(row[startTsColumnIndex]) as any,
				rowIndex
			);

			const endTs = this.parseOptionalDate(row[endTsColumIndex]);
			const assignedTs = this.parseOptionalDate(row[assignedTsColumnIndex]);
			const portedTs = this.parseOptionalDate(row[portedTsColumnIndex]);
			const activatedTs = this.parseOptionalDate(row[activatedTsColumnIndex]);
			const deletedTs = this.parseOptionalDate(row[deletedTsColumnIndex]);
			const decommissionedTs = this.parseOptionalDate(
				row[decommissionedTsColumnIndex]
			);

			const isActive = this.parseOptionalNumber(row[isActiveColumnIndex], 1);
			const isPorted = this.parseOptionalNumber(row[isPortedColumnIndex], 0);
			const cnamReg = this.parseOptionalNumber(row[cnamRegColumnIndex], 0);
			const cnamDip = this.parseOptionalNumber(row[cnamDipColumnIndex], 0);
			const isDirAsst = this.parseOptionalNumber(row[isDirAsstColumnIndex], 0);
			const isE911 = this.parseOptionalNumber(row[isE911ColumnIndex], 0);
			let scenarioId: number | null = this.parseOptionalNumber(
				row[scenarioIdColumnIndex],
				-1
			);

			const scenarioExists = scenarios.find(
				(scenario) => scenario.scenarioId === scenarioId
			);

			if (scenarioId === -1) {
				scenarioId = null;
			} else if (!scenarioExists) {
				scenarioId = null;
				this.emit(
					'info',
					GuardianServiceNumberSubscriptionSpreadsheetParser.formatErrorMessage(
						'scenarioId',
						rowIndex,
						`No scenarios were found with with id ${scenarioId}`
					)
				);
			}

			const portingStatusId = this.parseOptionalNumber(
				row[portingStatusIdColumnIndex],
				1
			);

			const portingStatusExists = portingStatuses.some(
				(portingStatus) => portingStatus.portingStatusId === portingStatusId
			);

			if (!portingStatusExists) {
				const portingPossibilities = portingStatuses
					.map(
						({ portingStatusId, label }) => `${portingStatusId} for ${label}`
					)
					.join(', ');
				this.emit(
					'error',
					GuardianServiceNumberSubscriptionSpreadsheetParser.formatErrorMessage(
						'portingStatusId',
						rowIndex,
						`Porting status with id ${portingStatusId} not found. Possibilities include: ${portingPossibilities}`
					)
				);
			}

			const label = this.emitValidationCheck(
				'label',
				row[labelColumnIndex],
				rowIndex
			);
			const translatedNumberStd = this.emitValidationCheck(
				'translatedNumberStd',
				row[translatedNumberStdColumnIndex],
				rowIndex
			);
			const pin = this.emitValidationCheck(
				'pin',
				row[pinColumnIndex] || defaultPin,
				rowIndex
			);
			const notes = this.emitValidationCheck(
				'notes',
				row[notesColumnIndex],
				rowIndex
			);
			const dirAsstName = this.emitValidationCheck(
				'dirAsstName',
				row[dirAsstNameColumnIndex],
				rowIndex
			);
			const dirAsstAddress1 = this.emitValidationCheck(
				'dirAsstAddress1',
				row[dirAsstAddress1ColumnIndex],
				rowIndex
			);
			const dirAsstAddress2 = this.emitValidationCheck(
				'dirAsstAddress2',
				row[dirAsstAddress2ColumnIndex],
				rowIndex
			);
			const dirAsstCity = this.emitValidationCheck(
				'dirAsstCity',
				row[dirAsstCityColumnIndex],
				rowIndex
			);
			const dirAsstState = this.emitValidationCheck(
				'dirAsstState',
				row[dirAsstStateColumnIndex],
				rowIndex
			);
			const dirAsstZip = this.emitValidationCheck(
				'dirAsstZip',
				row[dirAsstZipColumnIndex],
				rowIndex
			);
			const e911Name = this.emitValidationCheck(
				'e911Name',
				row[e911NameColumnIndex],
				rowIndex
			);
			const e911Address1 = this.emitValidationCheck(
				'e911Address1',
				row[e911Address1ColumnIndex],
				rowIndex
			);
			const e911Address2 = this.emitValidationCheck(
				'e911Address2',
				row[e911Address2ColumnIndex],
				rowIndex
			);
			const e911City = this.emitValidationCheck(
				'e911City',
				row[e911CityColumnIndex],
				rowIndex
			);
			const e911State = this.emitValidationCheck(
				'e911State',
				row[e911StateColumnIndex],
				rowIndex
			);
			const e911Zip = this.emitValidationCheck(
				'e911Zip',
				row[e911ZipColumnIndex],
				rowIndex
			);
			const custOrderNumber = this.emitValidationCheck(
				'custOrderNumber',
				row[custOrderNumberColumnIndex],
				rowIndex
			);
			const custExtRef1 = this.emitValidationCheck(
				'custExtRef1',
				row[custExtRef1ColumnIndex],
				rowIndex
			);
			const custExtRef2 = this.emitValidationCheck(
				'custExtRef2',
				row[custExtRef2ColumnIndex],
				rowIndex
			);
			const custExtRef3 = this.emitValidationCheck(
				'custExtRef3',
				row[custExtRef3ColumnIndex],
				rowIndex
			);
			const vendExtRef1 = this.emitValidationCheck(
				'vendExtRef1',
				row[vendExtRef1ColumnIndex],
				rowIndex
			);
			const vendExtRef2 = this.emitValidationCheck(
				'vendExtRef2',
				row[vendExtRef2ColumnIndex],
				rowIndex
			);

			const profileRecords: GuardianServiceNumberSubscriptionCreateRequestBulkItem =
				{
					directionId: 1,
					serviceTypeId: 0,
					updatedBy: -1,
					serviceNumber,
					subscriptionId,
					callType,
					startTs,
					endTs,
					isActive,
					isPorted,
					cnamReg,
					cnamDip,
					isDirAsst,
					isE911,
					label,
					scenarioId,
					assignedTs,
					portedTs,
					activatedTs,
					decommissionedTs,
					deletedTs,
					translatedNumberStd,
					portingStatusId,
					notes,
					pin,
					filename,
					dirAsstName,
					dirAsstAddress1,
					dirAsstAddress2,
					dirAsstCity,
					dirAsstState,
					dirAsstZip,
					e911Name,
					e911Address1,
					e911Address2,
					e911City,
					e911State,
					e911Zip,
					custOrderNumber,
					custExtRef1,
					custExtRef2,
					custExtRef3,
					vendExtRef1,
					vendExtRef2
				};

			profiles.push(profileRecords);
		}

		if (this.unknownFieldsMap.size > 0) {
			for (const [key, value] of this.unknownFieldsMap) {
				this.emit(
					'error',
					`There is ${value} values set as unknown for \"${key}\" field`
				);
			}
		}

		return profiles;
	}

	protected override emit(
		eventType: StandardEventEmitterTypes,
		eventMessage: string,
		errorCount = 1
	) {
		if (eventType === 'error') {
			this.totalErrors += errorCount;
		}

		return super.emit(eventType, eventMessage);
	}

	private parseOptionalDate(dateStr: string | undefined) {
		if (typeof dateStr === 'undefined' || dateStr === '') {
			return undefined;
		}

		const dateInstance = new Date(dateStr);

		return convertDateToUtc(dateInstance);
	}

	private parseOptionalNumber(
		propertyValueStr: string | undefined,
		defaultValue: number
	): number {
		if (!propertyValueStr) {
			return defaultValue;
		}

		const fieldValueLower = propertyValueStr.toLowerCase();
		const truthyWords = ['yes', 'y', 'true'];
		const falsyWords = ['no', 'n', 'false'];

		if (truthyWords.includes(fieldValueLower)) {
			return 1;
		} else if (falsyWords.includes(fieldValueLower)) {
			return 0;
		}

		const propertyValue = Number(propertyValueStr);

		if (isNaN(propertyValue)) {
			return defaultValue;
		}

		return propertyValue;
	}

	/**
	 * Netrio would like a default pin -- let's give it to them!
	 * https://trello.com/c/NWCaFNI3/1259-ui-and-api-changes-for-screens
	 */
	private getDefaultPin(): string {
		const isOnNetrio = UserActions.isOnPartitionId(KnownPartitions.Netrio);

		if (isOnNetrio) {
			return '8500';
		}

		return '';
	}

	private emitValidationCheck<
		K extends keyof GuardianServiceNumberSubscriptionCreateRequestBulkItem
	>(
		fieldName: K,
		fieldValue: GuardianServiceNumberSubscriptionCreateRequestBulkItem[K],
		rowIndex: number
	): GuardianServiceNumberSubscriptionCreateRequestBulkItem[K] {
		if (this.totalErrors > this.MAX_ERROR_MESSAGES_EMIT_LIMIT) {
			return fieldValue;
		}

		const column = this.columns.find(
			(column) => column.getColumnName() === fieldName
		);

		if (!column) {
			console.warn(
				'Can not find column config for validation, please double check configuration.'
			);
			return fieldValue;
		}

		const errorMessages: string[] = [];

		if (column.isRequired()) {
			let isDefined = typeof fieldValue !== undefined && fieldValue !== null;

			if (isDefined) {
				if (typeof fieldValue === 'string') {
					isDefined = fieldValue.length > 0;
				} else if (typeof fieldValue === 'number') {
					isDefined = !isNaN(fieldValue) && fieldValue >= 0;
				}
			}

			if (!isDefined) {
				if (column.getDefaultValue()) {
					this.countAsUnknownField(fieldName);
					fieldValue =
						column.getDefaultValue() as GuardianServiceNumberSubscriptionCreateRequestBulkItem[K];
				} else {
					errorMessages.push('is required');
				}
			}
		}

		const columnMinLength = column?.getMinLength();
		if (
			columnMinLength !== undefined &&
			typeof fieldValue === 'string' &&
			fieldValue.length < columnMinLength
		) {
			if (column.getDefaultValue()) {
				this.countAsUnknownField(fieldName);
				fieldValue =
					column.getDefaultValue() as GuardianServiceNumberSubscriptionCreateRequestBulkItem[K];
			} else {
				errorMessages.push(
					`should contain at least ${column.getMinLength()} chars`
				);
			}
		}

		if (errorMessages.length > 0) {
			this.emit(
				'error',
				GuardianServiceNumberSubscriptionSpreadsheetParser.formatErrorMessage(
					fieldName,
					rowIndex,
					errorMessages.join(', ')
				),
				errorMessages.length
			);
		}

		return fieldValue;
	}

	private countAsUnknownField(fieldName: string) {
		const lastUnknownFieldCount = this.unknownFieldsMap.get(fieldName);

		if (!lastUnknownFieldCount) {
			this.unknownFieldsMap.set(fieldName, 1);
		} else {
			this.unknownFieldsMap.set(fieldName, lastUnknownFieldCount + 1);
		}
	}
}
