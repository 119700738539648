/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RateLoadIndexRequest = {
    properties: {
        rateLoadId: {
            type: 'number',
        },
        ratePlanId: {
            type: 'number',
        },
        rateLoadTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            format: 'date-time',
        },
        approvedTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        completedTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        isComplete: {
            type: 'number',
        },
        createdDateTs: {
            type: 'Date',
            format: 'date-time',
        },
        completedDateTs: {
            type: 'Date',
            format: 'date-time',
        },
        isDeleted: {
            type: 'number',
        },
    },
};