import moment from 'moment';
import { Component, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Form } from 'react-bootstrap';
import {
	RateExpireMode,
	RateLoadProfileResponse,
	RatePlanIndexResponse
} from 'RtModels';
import { RateLoadResource } from 'RtUi/app/AccountManagement/RateManagement/libs/resources/RateLoadResource';
import { RateManagementRouter } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.router';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { SubmitFormControl } from 'RtUi/components/form/SubmitFormControl';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { RatePlanSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/RatePlanSelect';
import { Confirmation } from 'RtUi/components/form/Confirmation';

interface IRateLoadEditFormProps {
	ratePlanId: number;
	onSuccess?: (profile: RateLoadProfileResponse) => void;
	onCancel?: () => void;
}

interface IRateLoadEditFormState {
	isSubmitting: boolean;
	effectiveDate?: moment.Moment;
	notificationDate?: moment.Moment;
	rateSheetType?: RateExpireMode;
	ratePlan?: RatePlanIndexResponse;
	ratesFile?: File;
	error?: any;
	errorLink?: JSX.Element;
}

export class RateLoadEditForm extends Component<
	IRateLoadEditFormProps,
	IRateLoadEditFormState
> {
	public static defaultProps = {
		narrow: true,
		onCancel: () => null
	};

	public state: IRateLoadEditFormState = {
		isSubmitting: false,
		effectiveDate: moment(),
		notificationDate: moment()
	};

	private rateLoadResource = new RateLoadResource();

	public render() {
		return (
			<RtForm
				hideButtons={true}
				isSubmitting={this.state.isSubmitting}
				onSubmit={this.onSubmit}
			>
				<DatetimeFormControl
					label="Notification Date"
					required
					showDateOnly
					onChange={(notificationDate) => this.setState({ notificationDate })}
					value={this.state.notificationDate}
				/>
				<DatetimeFormControl
					label="Effective Date"
					required
					showDateOnly
					onChange={(effectiveDate) => {
						this.setState({ effectiveDate });
					}}
					value={this.state.effectiveDate}
				/>
				{!this.props.ratePlanId && (
					<RatePlanSelect
						required
						name="ratePlanId"
						onChange={(ratePlan) => {
							this.setState({ ratePlan });
						}}
						value={this.state.ratePlan}
					/>
				)}
				<RadioFormControl<number>
					required
					vertical
					label="Rate Sheet Type"
					value={this.state.rateSheetType}
					onChange={(rateSheetType) => this.setState({ rateSheetType })}
					options={[
						{
							value: RateExpireMode.Atoz,
							label: (
								<section>
									<section>A to Z</section>
									<small className="text-muted">
										All existing rates will be expired using standard expiration
										rules (or overridden by dates in the import - if approved).
									</small>
								</section>
							)
						},
						{
							value: RateExpireMode.Country,
							label: (
								<section>
									<section>Country</section>
									<small className="text-muted">
										Existing rates for all countries in this export will be
										expired. Existing rates for all countries <b>not</b>{' '}
										contained in this import will not be modified and remain in
										effect.
									</small>
								</section>
							)
						},
						{
							value: RateExpireMode.Exact,
							label: (
								<section>
									<section>
										Individual Codes{' '}
										<span className="text-danger">(Dangerous)</span>
									</section>
									<small className="text-muted">
										Rates in this import will be merged with existing rates.
										Existing rates not overridden by this import will remain
										active.
									</small>
								</section>
							)
						}
					]}
				/>
				<Form.Group className="mb-3">
					<Form.Label>Rates File</Form.Label>
					<DragAndDropFileUploader
						required
						accept={FileUtils.AcceptTypes.Spreadsheet}
						onDrop={(ratesFile) => this.setState({ ratesFile })}
						value={this.state.ratesFile}
					/>
				</Form.Group>
				{this.state.errorLink && (
					<Alert variant="danger">
						<h6 className="alert-heading fw-bold">
							{this.state.error.message}
						</h6>
						<ul>
							<li>{this.state.errorLink}</li>
						</ul>
					</Alert>
				)}
				{this.state.error && !this.state.errorLink && (
					<FormErrors className="mt-3" error={this.state.error} />
				)}
				<SubmitFormControl
					onCancel={this.props.onCancel}
					isSubmitting={this.state.isSubmitting}
					displayMode={false}
					footerClassName="d-flex justify-content-end py-2"
				/>
			</RtForm>
		);
	}

	private onSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const confirm = await Confirmation.create(
			<span>&nbsp;Do you want to proceed? Please confirm dates and type.</span>
		);

		if (!confirm) {
			return;
		}

		const { rateSheetType, notificationDate, effectiveDate } = this.state;
		const { onSuccess = () => ({}) } = this.props;

		this.setState({
			isSubmitting: true,
			error: undefined,
			errorLink: undefined
		});

		try {
			const profile = await this.rateLoadResource.create(
				this.state.ratesFile!,
				{
					effectiveTs: effectiveDate!.toDate(),
					notificationTs: notificationDate!.toDate(),
					rateExpirationId: rateSheetType!,
					ratePlanId: this.props.ratePlanId
				}
			);
			this.setState({ isSubmitting: false }, () => {
				onSuccess(profile);
			});
		} catch (error: any) {
			if (error.pOrigin.id) {
				const errorLink = (
					<Link to={RateManagementRouter.getProfileRoute(error.pOrigin.id)}>
						Rate Load - ({error.pOrigin.id})
					</Link>
				);
				this.setState({
					isSubmitting: false,
					error,
					errorLink
				});
			} else {
				this.setState({ isSubmitting: false, error });
			}
		}
	};
}
