import * as React from 'react';
import { Card } from 'react-bootstrap';
import { DidMetricTotalsByResourceResource } from 'RtUi/app/rtDid/Dashboard/lib/resources/DidMetricTotalsByResourceResource';
import { IMetricsTotalsByResource } from 'RtUi/app/rtDid/Dashboard/lib/http/DidMetricHttp';
import { ChartWrapper } from 'RtUi/app/rtVue/common/lib/charts/ChartWrapper';
import { ChartData, ChartDataset } from 'chart.js';

interface IMetricTotalsByVendorChartProps {
	resource: DidMetricTotalsByResourceResource;
	onClick?: (record: IMetricsTotalsByResource) => void;
}

interface IMetricTotalsByVendorChartState {
	records: IMetricsTotalsByResource[];
}

export class MetricTotalsByVendorChart extends React.Component<
	IMetricTotalsByVendorChartProps,
	IMetricTotalsByVendorChartState
> {
	public state: IMetricTotalsByVendorChartState = {
		records: []
	};

	public componentDidMount() {
		this.getRecords();
	}

	public componentDidUpdate(prevProps: IMetricTotalsByVendorChartProps) {
		if (prevProps.resource.getUuid() !== this.props.resource.getUuid()) {
			this.getRecords();
		}
	}

	public async getRecords() {
		const records = await this.props.resource.getAll();

		this.setState({ records });
	}

	public onRecordClick(record: IMetricsTotalsByResource) {
		const { onClick = () => ({}) } = this.props;

		onClick(record);
	}

	public onChartClick(charts?: Array<{ _index: number }>) {
		const { records } = this.state;

		if (Array.isArray(charts) && charts.length > 0) {
			// eslint-disable-next-line no-underscore-dangle
			const recordIndex = charts[0]._index;
			const record = records[recordIndex];

			if (record) {
				this.onRecordClick(record);
			}
		}
	}

	public render() {
		const { records } = this.state;
		const datasets: ChartDataset[] = [
			{
				type: 'bar',
				backgroundColor: '#5562A4',
				data: records.map((r) => r.totalActiveQty)
			}
		];
		const labels: string[] = records.map((r) => r.resourceLabel);
		const data: ChartData = { datasets, labels };

		return (
			<Card>
				{records.length !== 0 && (
					<ChartWrapper
						key="metric-by-vendor"
						config={{
							data,
							options: {
								height: 200,
								responsive: true,
								maintainAspectRatio: false,
								plugins: {
									legend: { display: false }
								},
								scales: {
									y: {
										//@ts-expect-error
										height: 200,
										beginAtZero: false,
										title: {
											display: true,
											text: datasets[0].label
										},
										ticks: {
											// @ts-expect-error
											callback: ({ label }) => Number(label).toLocaleString()
										}
									}
								}
							}
						}}
					/>
				)}
				{/* this hack it needed to keep the same card height if no chard */}
				{records.length === 0 && <div style={{ height: '200px' }}></div>}
			</Card>
		);
	}
}
