/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $JobTaskIndexResponse = {
    properties: {
        taskId: {
            type: 'string',
            isRequired: true,
        },
        taskTypeId: {
            type: 'string',
            isRequired: true,
        },
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        referenceKey: {
            type: 'string',
            isRequired: true,
        },
        taskStatusId: {
            type: 'JobStatusId',
            isRequired: true,
        },
        warningId: {
            type: 'string',
            isRequired: true,
        },
        errorId: {
            type: 'string',
            isRequired: true,
        },
        resErrorText: {
            type: 'string',
            isRequired: true,
        },
        startedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        completedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};