import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType, type ICprLerg } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import type { CprRow } from 'Somos/lib/SomosCpr/RtCprV2/CprRow';

// exported definitions
// ======================================================================

export class CprColAnnouncement extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.Announcement;

	public readonly isRequired = false;
	public readonly isArray = false;
	public readonly valueLimit = 1;

	public readonly isTermNode = true;

	// remove readonly property from possibles and possiblesByKey
	protected possibles: string[] = [];
	protected possiblesByKey: Record<string, ICprLerg> = {};

	public constructor(cprRow: CprRow, cprIdx: number, uuid: string | undefined = undefined) {
		super(cprRow, cprIdx, uuid);
		this.resetPossibles();
	}

	public getValue(): string | null {
		if (this.cprValues.length !== 1) {
			return null;
		}
		return this.cprValues[0].getValue();
	}

	public resetPossibles() {
		// DO NOT override resetPossbiles unless the values are dynamic based on other selections.

		this.possibles = [];
		this.possiblesByKey = {};

		const obaAsCprLerg: ICprLerg = {
			cprNodeTypeId: CprNodeType.Switch, // @TODO not really used
			worldValue: 'OBA',
			somosValue: 'OBA',
			pointId: '',
			state: '',
			lata: '',
			ocn: ''
		};

		this.possiblesByKey.OBA = obaAsCprLerg;

		const vcaAsCprLerg: ICprLerg = {
			cprNodeTypeId: CprNodeType.Switch, // @TODO not really used
			worldValue: 'OBA',
			somosValue: 'OBA',
			pointId: '',
			state: '',
			lata: '',
			ocn: ''
		};

		this.possiblesByKey.VCA = vcaAsCprLerg;
	}

}
