import { RtCommonApiRoutes } from 'RtExports/routes';
import { CountryDialCodeIndexResponse, CountryIndexResponse } from 'RtModels';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class CountryHttp {
	/**
	 * getting list of countries
	 * @returns {Promise<>}
	 */
	public getCountries() {
		return HttpRequest.fetchWithRoute<CountryIndexResponse[]>(
			RtCommonApiRoutes.Countries.Index
		);
	}

	/**
	 * Get a resource of countries
	 */
	public getCountriesResource() {
		const countriesResource = new ArrayResource<CountryDialCodeIndexResponse>(
			'iso3'
		);

		countriesResource.setApiRouteForGetAll(
			RtCommonApiRoutes.CountryDialCodes.Index
		);

		return countriesResource;
	}
}
