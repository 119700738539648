import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { CprValidatorFormControl } from 'RtUi/app/rt800/Cprs/lib/controls/CprValidatorFormControl';
import * as CprErrorUtils from 'RtUi/app/rt800/Cprs/lib/util/CprErrorUtils';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
import { FC, useContext, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useUpdate } from 'react-use';

interface ICprAreasOfServiceProps {
	mgiProfile?: Cpr;
}

export const CprAreasOfService: FC<
	React.PropsWithChildren<ICprAreasOfServiceProps>
> = (props) => {
	const { displayMode, cpr } = useContext(CprProfileContext);
	const workingMgiProfile = props.mgiProfile ?? cpr;
	const updateComponent = useUpdate();

	useEffect(() => {
		return workingMgiProfile?.onValidate(() => {
			updateComponent();
		});
	});

	if (!workingMgiProfile) {
		return null;
	}

	const getCprValidatorFormControlFor = (
		aosTypeId: keyof Cpr['aos'],
		label: string
	) => {
		const validator = workingMgiProfile.aos[aosTypeId];

		return (
			<Form.Group className="mb-3">
				<CprValidatorFormControl
					hideFormGroup
					label={label}
					validator={validator}
					displayMode={displayMode}
					value={validator.getRawValues()}
					onChange={(newValues) =>
						workingMgiProfile.aos[aosTypeId].setValues(newValues)
					}
				/>
				{validator.getErrors().map((cprError) => (
					<footer key={cprError.cprIndex} className="small text-danger">
						{CprErrorUtils.formatErrorMessage(cprError)}
					</footer>
				))}
			</Form.Group>
		);
	};

	return (
		<section>
			{getCprValidatorFormControlFor('network', 'Network')}
			{getCprValidatorFormControlFor('areaCodes', 'Area Codes')}
			{getCprValidatorFormControlFor('latas', 'LATAs')}
			{getCprValidatorFormControlFor('states', 'States')}
		</section>
	);
};
