/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $VendorReconciliationIndexResponseData = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        jurisdiction: {
            type: 'string',
            isRequired: true,
        },
        description: {
            type: 'string',
            isRequired: true,
        },
        callCount: {
            type: 'number',
            isRequired: true,
        },
        cost: {
            type: 'number',
            isRequired: true,
        },
        duration: {
            type: 'number',
            isRequired: true,
        },
        ingressTrunkGroup: {
            type: 'string',
        },
        egressTrunkGroup: {
            type: 'string',
        },
        ingressRateCenterId: {
            type: 'number',
        },
        egressRateCenterId: {
            type: 'number',
        },
        dialCode: {
            type: 'number',
        },
        callType2: {
            type: 'string',
            isRequired: true,
        },
        jurisdiction2: {
            type: 'string',
            isRequired: true,
        },
        description2: {
            type: 'string',
            isRequired: true,
        },
        callCount2: {
            type: 'number',
            isRequired: true,
        },
        cost2: {
            type: 'number',
            isRequired: true,
        },
        duration2: {
            type: 'number',
            isRequired: true,
        },
        ingressTrunkGroup2: {
            type: 'string',
        },
        egressTrunkGroup2: {
            type: 'string',
        },
        ingressRateCenterId2: {
            type: 'number',
        },
        egressRateCenterId2: {
            type: 'number',
        },
        dialCode2: {
            type: 'number',
        },
        costDiff: {
            type: 'number',
            isRequired: true,
        },
        callCountDiff: {
            type: 'number',
            isRequired: true,
        },
        durationDiff: {
            type: 'number',
            isRequired: true,
        },
    },
};