/* eslint-disable no-return-await */
import { IRtxApiRoute } from 'RtExports/routes';
import { BrowserHistory, logoutUser } from 'RtUi/app/@RtUi/lib/browser';
import { ManifestChecker } from 'RtUi/app/ApplicationShell/lib/components/ManifestChecker';
import { RtDashboardOldRouter } from 'RtUi/app/rtCommon/DashboardOld/DashboardOld.router';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import axios, {
	AxiosError,
	AxiosResponse,
	AxiosResponseHeaders,
	RawAxiosResponseHeaders,
	ResponseType
} from 'axios';
import { get } from 'lodash-es';

//this is the start of a refactor of HTTPResource.
// things that we need to take into account to complete remove that class and it's helpers
// 1- Don't handle requests without proper libraries, this is even the official recommendation of react team. Lets use react query
// 2- Never handle requests in useEffects
// 3- We need to externalize and improve the token handling methods.
// 4- We need to externalize and improve the permissions handling methods, lets transform them all into simple functions
// 5- Regardless to use this wrapper, just call the appropriate axios method passing the route just as we did before, but using react query

export interface PageSizeParams extends Record<string, any> {
	pageSize?: number;
	page?: number;
}

interface ParamDictionary<T = any> {
	[key: string]: T;
}

export interface IInitRequestParams<
	IncludeFullResponse extends boolean = false
> {
	urlParams?: ParamDictionary<any>;
	queryParams?: ParamDictionary<any>;
	removeContentType?: boolean;
	responseType?: ResponseType;
	data?: object;
	includeFullResponse?: IncludeFullResponse;
}

export interface FullResponse<T> extends AxiosResponse<T, any> {
	totalCount: number;
}

export type RequestResponse<
	T,
	IncludeFullResponse extends boolean = false
> = IncludeFullResponse extends true ? FullResponse<T> : T;

export const ensureFullResults = (
	resourceParams: PageSizeParams = {}
): Required<PageSizeParams> => ({
	...resourceParams,
	pageSize: 1_000_000,
	page: 0
});

const returnHeader = () => {
	const header = HttpRequest.AuthorizationHeaderValue;
	return header;
};

const formatUrlParams = (baseUrl: string, urlParams: ParamDictionary<any>) => {
	return Object.keys(urlParams).reduce<string>((result, key) => {
		const urlParam = urlParams[key];

		return result.replace(`:${key}`, String(urlParam));
	}, baseUrl);
};

const getBaseUrl = (path: string, urlParams: ParamDictionary<any> = {}) => {
	const baseUrl = `${HttpRequest.getApiEndpoint()}${path}`;
	return formatUrlParams(baseUrl, urlParams);
};

const getTotalCountHeader = (
	headers?: RawAxiosResponseHeaders | AxiosResponseHeaders
) => {
	return Number(get(headers, 'x-total-count', 0));
};

const executeRequest = async <
	T = any,
	IncludeFullResponse extends boolean = false
>(
	request: Promise<AxiosResponse<T, any>>,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<T, IncludeFullResponse>> => {
	try {
		const response = await request;
		return parseResponse(response, includeFullResponse);
	} catch (e: any) {
		const error: AxiosError = { ...e };
		if (error.response?.status === 401) {
			logoutUser();
		}

		if (error.response?.status === 403) {
			BrowserHistory.push(RtDashboardOldRouter.getIndexRoute());
		}

		throw new Error(error.message);
	}
};

const parseResponse = <T = any, IncludeFullResponse extends boolean = false>(
	response: AxiosResponse<T, any>,
	includeFullResponse?: IncludeFullResponse
): RequestResponse<T, IncludeFullResponse> => {
	if (includeFullResponse) {
		const parsedResponse = {
			totalCount: getTotalCountHeader(response.headers),
			...response
		};

		return parsedResponse as RequestResponse<T, IncludeFullResponse>;
	}

	return response.data as RequestResponse<T, IncludeFullResponse>;
};

const getBaseHeaders = async (
	removeContentType: boolean
): Promise<Record<string, string>> => {
	const headers: Record<string, string> = {
		'Authorization': returnHeader(),
		'X-RTX-UI': await ManifestChecker.getManifestVersion()
	};

	if (!removeContentType) {
		headers['Content-Type'] = 'application/json';
	}

	return headers;
};

export const handleGetRequest = async <
	T = any,
	IncludeFullResponse extends boolean = false
>(
	apiRoute: IRtxApiRoute,
	initParams?: IInitRequestParams<IncludeFullResponse>
): Promise<RequestResponse<T, IncludeFullResponse>> => {
	const baseUrl = getBaseUrl(apiRoute.path, initParams?.urlParams);
	const headers = await getBaseHeaders(Boolean(initParams?.removeContentType));

	const request = axios.get<T>(baseUrl, {
		headers,
		params: initParams?.queryParams
	});

	return executeRequest<T, IncludeFullResponse>(
		request,
		initParams?.includeFullResponse
	);
};

export const handlePostRequest = async <
	T = any,
	IncludeFullResponse extends boolean = false
>(
	apiRoute: IRtxApiRoute,
	body: object,
	initParams?: IInitRequestParams<IncludeFullResponse>
): Promise<RequestResponse<T, IncludeFullResponse>> => {
	const baseUrl = getBaseUrl(apiRoute.path, initParams?.urlParams);
	const headers = await getBaseHeaders(Boolean(initParams?.removeContentType));

	const request = axios.post<T>(baseUrl, body, {
		headers,
		params: initParams?.queryParams,
		responseType: initParams?.responseType
	});

	return executeRequest<T, IncludeFullResponse>(
		request,
		initParams?.includeFullResponse
	);
};

export const handlePutRequest = async <
	T = any,
	IncludeFullResponse extends boolean = false
>(
	apiRoute: IRtxApiRoute,
	body: object,
	initParams?: IInitRequestParams<IncludeFullResponse>
): Promise<RequestResponse<T, IncludeFullResponse>> => {
	const baseUrl = getBaseUrl(apiRoute.path, initParams?.urlParams);
	const headers = await getBaseHeaders(Boolean(initParams?.removeContentType));

	const request = axios.put<T>(baseUrl, body, {
		headers,
		params: initParams?.queryParams,
		responseType: initParams?.responseType
	});

	return executeRequest<T, IncludeFullResponse>(
		request,
		initParams?.includeFullResponse
	);
};

export const handlePatchRequest = async <
	T = any,
	IncludeFullResponse extends boolean = false
>(
	apiRoute: IRtxApiRoute,
	body: object,
	initParams?: IInitRequestParams<IncludeFullResponse>
): Promise<RequestResponse<T, IncludeFullResponse>> => {
	const baseUrl = getBaseUrl(apiRoute.path, initParams?.urlParams);
	const headers = await getBaseHeaders(Boolean(initParams?.removeContentType));

	const request = axios.patch<T>(baseUrl, body, {
		headers,
		params: initParams?.queryParams,
		responseType: initParams?.responseType
	});

	return executeRequest<T, IncludeFullResponse>(
		request,
		initParams?.includeFullResponse
	);
};

export const handleDeleteRequest = async <
	T = any,
	IncludeFullResponse extends boolean = false
>(
	apiRoute: IRtxApiRoute,
	initParams?: IInitRequestParams<IncludeFullResponse>
): Promise<RequestResponse<T, IncludeFullResponse>> => {
	const baseUrl = getBaseUrl(apiRoute.path, initParams?.urlParams);
	const headers = await getBaseHeaders(Boolean(initParams?.removeContentType));

	const request = axios.delete<T>(baseUrl, {
		headers,
		params: initParams?.queryParams,
		responseType: initParams?.responseType,
		data: initParams?.data
	});

	return executeRequest<T, IncludeFullResponse>(
		request,
		initParams?.includeFullResponse
	);
};
