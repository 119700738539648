import {
	TemplateCprIndexResponse,
	TemplateProfileRequest,
	TollFreeCprIndexResponse,
	TollFreeProfileRequest
} from 'RtModels';
import { useGetCprs } from 'RtUi/app/rt800/Cprs/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCprColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/cpr';
import { useMemo } from 'react';
import {
	CprsRouterClass,
	TemplateCprsRouter,
	TollFreeCprsRouter
} from '../../Cprs.router';

export type CprIndexResponse =
	| TemplateCprIndexResponse
	| TollFreeCprIndexResponse;
export type CprIndexRequest<T extends CprIndexResponse> =
	T extends TemplateCprIndexResponse
		? TemplateProfileRequest
		: TollFreeProfileRequest;

export type IsNumberCpr<T extends CprIndexResponse> =
	T extends TemplateCprIndexResponse ? undefined : true;

interface ICprGridProps<T extends CprIndexResponse> {
	resourceParams: CprIndexRequest<T>;
	enableOutsideTabs?: boolean;
	isNumber?: IsNumberCpr<T>;
	router: CprsRouterClass;
	pageName: string;
}

export const CprGrid = <T extends CprIndexResponse>({
	resourceParams,
	router,
	isNumber,
	pageName,
	enableOutsideTabs = true
}: ICprGridProps<T>) => {
	const { data, isFetching: isLoading } = useGetCprs(
		resourceParams,
		Boolean(isNumber)
	);
	const columns = useMemo(() => getCprColumns(Boolean(isNumber)), [isNumber]);

	return (
		<DataGrid<CprIndexResponse>
			router={router}
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			disableExternalLinks={
				enableOutsideTabs === false || [TollFreeCprsRouter, TemplateCprsRouter]
			}
			loading={isLoading}
			pageName={pageName}
		/>
	);
};
