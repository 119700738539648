/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CallTypes {
    Oneplus = '1+',
    Tf = 'TF',
    Iddd = 'IDDD',
    Did = 'DID',
    Tfterm = 'TFT',
    Itfs = 'ITFS',
    Idid = 'IDID',
    Local = 'LOCAL',
    SmsIb = 'SMSIB',
    SmsOb = 'SMSOB',
    MmsIb = 'MMSIB',
    MmsOb = 'MMSOB',
}