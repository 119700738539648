import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { isNil } from 'lodash-es';
import { Alert } from 'react-bootstrap';

interface DirectionDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const DirectionDataGridColumn = <T = any,>({
	size = 150,
	align = DataGridColumnAlignment.CENTER,
	...config
}: DirectionDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const renderValue = (value: number) => {
		const styles: React.CSSProperties = {
			width: size
		};

		if (align === DataGridColumnAlignment.CENTER) {
			styles.textAlign = 'center';
		}

		if (isNil(value)) {
			return <span></span>;
		}

		const isIngress = value === 1;
		const word = isIngress ? 'Ingress' : 'Egress';
		const color = isIngress ? 'info' : 'primary';

		return (
			<section className="d-flex justify-content-center">
				<Alert
					transition={false}
					style={styles}
					variant={color}
					className="alert-sm mb-0 p-0"
				>
					<span>{word}</span>
				</Alert>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		exportValue: (value: T[keyof T]) => (value ? 'Ingress' : 'Egress'),
		getValue: ({ cell }) => renderValue(cell.getValue<number>())
	});
};
