import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { TabLayoutTab } from 'RtUi/components/ui/TabbedLayout/TabLayoutContext';
import { useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Separator, Submenu, Item } from 'react-contexify';
import { useNavigate } from 'react-router-dom';

interface IInternalDataGridMenuItemProps<T> {
	record: T;
	router: RtUiRouter;
	content?: JSX.Element | null;
}

export const InternalDataGridMenuItem = <T = {},>({
	record,
	router,
	content
}: IInternalDataGridMenuItemProps<T>): JSX.Element => {
	const tabs = router.getTabsFor(record);

	const standardTabs = useMemo(
		() => tabs.filter((tab) => !tab.isAction),
		[tabs]
	);
	const actionTabs = useMemo(() => tabs.filter((tab) => tab.isAction), [tabs]);
	const navigate = useNavigate();

	const goToPath = useCallback(
		(tab?: TabLayoutTab) => {
			const path = router.getProfileRoute(record, tab?.header);
			navigate(path);
		},
		[navigate, record, router]
	);

	const label = useMemo(
		() => (
			<Item onClick={() => goToPath()}>
				<span className="d-flex justify-content-start align-items-center">
					<span>&nbsp;{router.getName()}</span>
				</span>
			</Item>
		),
		[goToPath, router]
	);

	if (!standardTabs.length && !content && !actionTabs.length) {
		return <></>;
	}

	return (
		<Submenu label={label}>
			{standardTabs.map((tab, index) => {
				return (
					<Item key={index} onClick={() => goToPath(tab)}>
						<div
							className="d-flex flex-row"
							style={{ width: '100%', flexShrink: 0, flexGrow: 1 }}
						>
							<div className="flex-grow-1">
								<span>{tab.header}</span>
							</div>
							<div className="align-self-end">
								<Button
									variant="white"
									size="sm"
									className="py-0"
									title={`Open ${tab.header} in a new tab.`}
									target="_blank"
									onClick={(evt) => evt.stopPropagation()}
									href={`${router.getProfileRoute(record, tab.header)}`}
									rel="noreferrer"
								>
									<i className="fas fa-external-link-alt px-2" />
								</Button>
							</div>
						</div>
					</Item>
				);
			})}
			{content && (
				<>
					<Separator />
					{content}
				</>
			)}
			{Boolean(actionTabs.length) && (
				<>
					<Separator />
					{actionTabs.map((tab) => (
						<Item key={tab.header} onClick={() => goToPath(tab)}>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-play-circle" />
								<span>{tab.header}</span>
							</span>
						</Item>
					))}
				</>
			)}
		</Submenu>
	);
};
