import { Rt800ApiRoutes } from 'RtExports/routes';
import { Rt800Router } from '../rt800.router';

class SearchAndReserveRouterClass extends Rt800Router<void, void, {}> {
	constructor() {
		super('Search & Reserve', 'search', undefined, {});

		this.setIndexPermissions([
			...Rt800ApiRoutes.TollFreeSearch.Profile.permissions,
			...Rt800ApiRoutes.TollFreeActionReserve.Create.permissions
		]);
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const SearchAndReserveRouter = new SearchAndReserveRouterClass();
