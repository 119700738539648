import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	RoboscoreParameterProfileRequest,
	RoboscoreParameterIndexResponse,
	RoboscoreParameterUpdateRequest
} from 'RtModels';
import { RtVueApiRoutes } from 'RtExports/routes';

export class RoboscoringResource extends HttpResource<
	RoboscoreParameterIndexResponse,
	RoboscoreParameterIndexResponse
> {
	constructor() {
		super(RtVueApiRoutes.RoboscoreParameters);
	}
	public update(paramId: number, roboscore: RoboscoreParameterUpdateRequest) {
		const urlParams: RoboscoreParameterProfileRequest = { paramId };
		const body = JSON.stringify(roboscore);

		return this.fetchWithRoute<RoboscoreParameterIndexResponse>(
			RtVueApiRoutes.RoboscoreParameters.Update,
			{ body, urlParams }
		);
	}
}
