import { useMemo } from 'react';
import { RateCenterTypes } from 'RtModels';
import { ISelectFormControlProps } from 'RtUi/components/form/SelectFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface IRateCenterTypeSelectProps<isMulti extends boolean>
	extends ISelectFormControlProps<RateCenterTypes, isMulti> {
	isClearable?: boolean;
}

export const RateCenterTypeSelect = <isMulti extends boolean = false>({
	value,
	onChange = () => {},
	required,
	multi,
	isClearable = true,
	displayMode,
	initialOptionId,
	hideLabel,
	label = 'Rate Center Type'
}: IRateCenterTypeSelectProps<isMulti>): JSX.Element => {
	const options = useMemo(() => {
		const typeOptions: Array<ISimpleSelectFormControlOption<RateCenterTypes>> =
			[
				{
					value: RateCenterTypes.COUNTRY,
					label: 'Country'
				},
				{
					value: RateCenterTypes.FIXED,
					label: 'Fixed'
				},
				{
					value: RateCenterTypes.MOBILE,
					label: 'Mobile'
				},
				{
					value: RateCenterTypes.WIRELESS,
					label: 'Wireless'
				},
				{
					value: RateCenterTypes.VOIP,
					label: 'Voip'
				},
				{
					value: RateCenterTypes.RURAL,
					label: 'Rural'
				},
				{
					value: RateCenterTypes.SPECIAL,
					label: 'Special'
				}
			];

		return typeOptions;
	}, []);

	const selectValue: any = useMemo(() => {
		if (!value) {
			return value;
		}

		return multi
			? options.filter((opt) =>
					(value as RateCenterTypes[]).some((val) => opt.value === val)
				)
			: options.find((opt) => opt.value === value);
	}, [value, options, multi]);

	const getNewValue = (newValue: any) => {
		if (!newValue) {
			return newValue;
		}

		return multi ? newValue.map((val: any) => val.value) : newValue.value;
	};

	return (
		<SimpleSelectFormControl<RateCenterTypes, isMulti>
			label={label}
			multi={multi}
			clearable={isClearable}
			required={required}
			displayMode={displayMode}
			value={selectValue}
			hideLabel={hideLabel}
			onChange={(value) => onChange(getNewValue(value))}
			options={options}
			initialOptionId={initialOptionId}
		/>
	);
};
