import {
	SubscriptionNanpUpdateRequest,
	SubscriptionNanpProfileRequest,
	SubscriptionNanpProfileResponse,
	SubscriptionIndexResponse,
	SubscriptionProfileResponse,
	SubscriptionProfileRequest,
	SubscriptionCreateRequest,
	SubscriptionUpdateRequest,
	SubscriptionBillingProfileRequest,
	SubscriptionBillingProfileResponse,
	SubscriptionBillingUpdateRequest,
	SubscriptionRoutingProfileRequest,
	SubscriptionRoutingUpdateRequest,
	SubscriptionRoutingProfileResponse
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class SubscriptionResource extends HttpResource<
	SubscriptionIndexResponse,
	SubscriptionProfileResponse
> {
	constructor() {
		super(RtxSipApiRoutes.Subscriptions);
	}

	public getSubscriptionBilling(subscriptionId: number) {
		const urlParams: SubscriptionBillingProfileRequest = { subscriptionId };
		return this.fetchWithRoute<SubscriptionBillingProfileResponse>(
			RtxSipApiRoutes.SubscriptionBilling.Profile,
			{
				urlParams
			}
		);
	}

	public getSubscriptionRouting(subscriptionId: number) {
		const urlParams: SubscriptionRoutingProfileRequest = { subscriptionId };
		return this.fetchWithRoute<SubscriptionRoutingProfileResponse>(
			RtxSipApiRoutes.SubscriptionRouting.Profile,
			{
				urlParams
			}
		);
	}

	public updateSubscriptionRouting(
		subscriptionId: number,
		updateRequest: SubscriptionRoutingUpdateRequest
	) {
		const body = JSON.stringify(updateRequest);
		const urlParams: SubscriptionRoutingProfileRequest = { subscriptionId };

		return this.fetchWithRoute<SubscriptionRoutingProfileResponse>(
			RtxSipApiRoutes.SubscriptionRouting.Update,
			{
				body,
				urlParams
			}
		);
	}

	public updateSubscriptionBilling(
		subscriptionId: number,
		updateRequest: SubscriptionBillingUpdateRequest
	) {
		const body = JSON.stringify(updateRequest);
		const urlParams: SubscriptionBillingProfileRequest = { subscriptionId };

		return this.fetchWithRoute<SubscriptionBillingProfileResponse>(
			RtxSipApiRoutes.SubscriptionBilling.Update,
			{
				body,
				urlParams
			}
		);
	}

	public getSubscriptionNanp(subscriptionId: number) {
		const urlParams: SubscriptionNanpProfileRequest = { subscriptionId };
		return this.fetchWithRoute<SubscriptionNanpProfileResponse>(
			RtxSipApiRoutes.SubscriptionNanp.Profile,
			{
				urlParams
			}
		);
	}

	public updateSubscriptionNanp(
		subscriptionId: number,
		updateRequest: SubscriptionNanpUpdateRequest
	) {
		const body = JSON.stringify(updateRequest);
		const urlParams: SubscriptionNanpProfileRequest = { subscriptionId };

		return this.fetchWithRoute<SubscriptionNanpProfileResponse>(
			RtxSipApiRoutes.SubscriptionNanp.Update,
			{
				body,
				urlParams
			}
		);
	}

	public update(subscriptionId: number, req: SubscriptionUpdateRequest) {
		const urlParams: SubscriptionProfileRequest = { subscriptionId };
		const body = JSON.stringify(req);

		return this.fetchWithRoute<SubscriptionProfileResponse>(
			RtxSipApiRoutes.Subscriptions.Update,
			{ body, urlParams }
		);
	}

	public create(subscription: SubscriptionCreateRequest) {
		const req: SubscriptionCreateRequest = subscription;
		const body = JSON.stringify(req);

		return this.fetchWithRoute<SubscriptionProfileResponse>(
			RtxSipApiRoutes.Subscriptions.Create,
			{ body }
		);
	}
}
