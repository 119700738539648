import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';

export interface ITollFreeNumber {
	// eslint-disable-next-line id-blacklist
	number: string;
	lastIssued: number;
	lastWorking: number;
}

export type IAvailableAreaCodes =
	| '800'
	| '833'
	| '844'
	| '855'
	| '866'
	| '877'
	| '888';

export const AvailableAreaCodes: IAvailableAreaCodes[] = [
	'800',
	'833',
	'844',
	'855',
	'866',
	'877',
	'888'
];

export class TollFreeNumbersUtils {
	public static TOLL_FREE_REGEXP: RegExp =
		/^(8(00|33|44|55|66|77|88)[2-9]\d{6})$/;

	public static isValid(tollFreeNumber: string): boolean {
		const tfnWithReplacedLetters =
			PhoneNumberFormatter.convertLettersToNumbers(tollFreeNumber);
		const tfnRemoveInternationalCode =
			TollFreeNumbersUtils.tollFreeNumberToReadableString(
				tfnWithReplacedLetters,
				false
			);
		const rawTfn = tfnRemoveInternationalCode.replace(/-/g, '');

		return this.TOLL_FREE_REGEXP.test(rawTfn);
	}

	public static tollFreeNumberToReadableString(
		value: string,
		addDashes = true,
		convertLetters = false,
		keepWildcards = false
	) {
		const regex = keepWildcards ? /[^0-9a-z*&]/gi : /[^0-9a-z]/gi;
		let tfn = value.replace(regex, '');

		if (convertLetters) {
			tfn = PhoneNumberFormatter.convertLettersToNumbers(tfn);
		}

		//Remove international code
		if (tfn.charAt(0) === '1') {
			tfn = tfn.substr(1);
		}

		tfn = tfn.substring(0, 10);

		if (addDashes) {
			if (tfn.length >= 3) {
				const tfnParts = tfn.split('');
				tfnParts.splice(3, 0, '-');
				tfn = tfnParts.join('');
			}

			if (tfn.length >= 7) {
				const tfnParts = tfn.split('');
				tfnParts.splice(7, 0, '-');
				tfn = tfnParts.join('');
			}
		}

		return tfn;
	}

	public static filterTollFreeNumbersList(text: string) {
		const textHas = (char: string) => text.includes(char);
		const hasCommas = textHas(',');
		const hasLineEndings = textHas('\n');
		const hasReturnEndings = textHas('\r');
		const hasSpaceEndings = textHas(' ');
		let possibleTfns: string[] = [];

		if (hasCommas) {
			possibleTfns = text.split(',');
		} else if (hasLineEndings) {
			possibleTfns = text.split('\n');
		} else if (hasReturnEndings) {
			possibleTfns = text.split('\r');
		} else if (hasSpaceEndings) {
			possibleTfns = text.split(' ');
		} else {
			possibleTfns = [text];
		}
		return possibleTfns;
	}

	public filterListOfPossibleTollFreeNumbers(text: string) {
		return TollFreeNumbersUtils.filterTollFreeNumbersList(text).filter(
			(possibleTfn) => {
				return TollFreeNumbersUtils.isValid(possibleTfn);
			}
		);
	}
}
