import { Col, Row } from 'react-bootstrap';
import { HavingFilter, HavingFilterOperator } from 'RtModels';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	IDefaultSelectOption,
	SelectFormControl
} from 'RtUi/components/ui/SelectFormControl';

interface IExpectedParametersControlProps {
	dataFilters?: string[];
	selectedHavingField?: IDefaultSelectOption<string>;
	selectedOperator?: IDefaultSelectOption<HavingFilterOperator>;
	filterValue?: string;
	onChange: <K extends keyof HavingFilter>(
		fieldName: K,
		fieldValues: HavingFilter[K]
	) => void;
	isDisabled?: boolean;
}

export const createOptionsFromList = (values: string[] = []) => {
	return values.map((val) => {
		return {
			label: val,
			value: val
		};
	});
};

export const TriggerFilteringControl = ({
	dataFilters,
	selectedHavingField,
	onChange,
	isDisabled,
	selectedOperator,
	filterValue
}: IExpectedParametersControlProps) => {
	return (
		<Row className="row-sm">
			<Col lg={5}>
				<SelectFormControl<IDefaultSelectOption<string>, 'value'>
					label="Trigger On"
					labelKey="label"
					valueKey="value"
					options={createOptionsFromList(dataFilters)}
					value={selectedHavingField}
					onChange={(selectedValue) => onChange('column', selectedValue.value)}
					displayMode={isDisabled}
				/>
			</Col>
			<Col lg={3}>
				<SelectFormControl<IDefaultSelectOption<HavingFilterOperator>, 'value'>
					label=""
					labelKey="label"
					valueKey="value"
					options={[
						{
							value: HavingFilterOperator.Greater,
							label: 'Is Above'
						}
					]}
					value={selectedOperator}
					onChange={(selectedValue) =>
						onChange('operator', selectedValue.value)
					}
					displayMode={isDisabled}
				/>
			</Col>
			<Col lg={4}>
				<InputFormControl
					label=""
					value={filterValue}
					disabled={isDisabled}
					onChange={(value) => {
						onChange('value', value);
					}}
					displayMode={isDisabled}
				/>
			</Col>
		</Row>
	);
};
