import { noop } from 'lodash-es';
import { useMemo } from 'react';
import {
	RtxUiRadioInput,
	RtxUiRadioInputProps
} from 'RtUi/components/rtx/inputs/Radio/RtxUiRadioInput';

type RtxUiIsActiveInputProps = Omit<
	RtxUiRadioInputProps<number | undefined>,
	'options' | 'label' | 'onChange'
> & {
	hideBothOption?: boolean;
	label?: string;
	onChange?: (value: number | undefined) => void;
};

export const RtxUiIsActiveInput = ({
	hideBothOption,
	label = 'Active',
	onChange = noop,
	...props
}: RtxUiIsActiveInputProps) => {
	const options = useMemo(() => {
		const opts = [
			{
				label: 'Yes',
				value: 1
			},
			{
				label: 'No',
				value: 0
			}
		];

		if (!hideBothOption) {
			return [
				...opts,
				{
					label: 'Both',
					value: undefined
				}
			];
		}

		return opts;
	}, [hideBothOption]);

	return (
		<RtxUiRadioInput
			label={label}
			options={options}
			{...props}
			onChange={(value) => onChange(Number(value))}
		/>
	);
};
