import * as React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
	IUiNotificationSettings,
	IUiNotificationState,
	UiNotification
} from '../../../../../notifications/lib/UiNotification';

interface IDashboardOldStatusIconProps {
	notification: UiNotification;
}

interface IDashboardOldStatusIconState
	extends IUiNotificationSettings,
		IUiNotificationState {}

export class DashboardOldStatusIcon extends React.Component<
	IDashboardOldStatusIconProps,
	IDashboardOldStatusIconState
> {
	public listenerRemoveFn: () => void = () => ({});

	public componentDidMount() {
		const { notification } = this.props;

		this.listenerRemoveFn = notification.onUpdate((settings, state) => {
			this.setState({
				...settings,
				...state
			});
		});
	}

	public componentWillUnmount() {
		this.listenerRemoveFn();
	}

	public render() {
		if (!this.state) {
			return null;
		}

		const { notification } = this.props;
		const { isPinned, isWarning, didFetchFail, issuesCount, issuesWord } =
			this.state;

		if (!isPinned || !notification.userHasAccessToNotification()) {
			return null;
		}

		const bgClassName = isWarning ? 'bg-warning' : 'bg-danger';
		const faCounterClassNames = [
			'fa-layers-counter',
			'fa-layers-counter-top-right',
			bgClassName
		];
		const btnId = notification.getTitle().replace(/\W/g, '');

		return (
			<OverlayTrigger
				placement="bottom"
				overlay={(props) =>
					typeof issuesCount === 'number' ? (
						<Tooltip id={`${btnId}-tooltip`} {...props}>
							{notification.getTitle()} has {issuesCount.toLocaleString()}{' '}
							{issuesWord}
						</Tooltip>
					) : (
						<span></span>
					)
				}
			>
				{({ ref, ...triggerHandler }) => (
					<Button
						ref={ref}
						{...triggerHandler}
						id={btnId}
						className="animate__animated animate__fadeIn"
						onClick={() => notification.openDetailedView()}
					>
						<span className="fa-layers fa-fw">
							{notification.getIcon()}
							{typeof issuesCount === 'number' && issuesCount > 0 && (
								<span className={faCounterClassNames.join(' ')}>
									{issuesCount.toLocaleString()}
								</span>
							)}
							{typeof issuesCount === 'number' && issuesCount === 0 && (
								<span className="fa-layers-counter fa-layers-counter-top-right bg-success">
									<i className="fas fa-check fa-sm" />
								</span>
							)}
							{didFetchFail && (
								<span className="fa-layers-counter fa-layers-counter-top-right bg-warning">
									<i className="fas fa-exclamation-triangle" />
								</span>
							)}
						</span>
					</Button>
				)}
			</OverlayTrigger>
		);
	}
}
