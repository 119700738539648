import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { PromptResource } from 'RtUi/app/rtSip/PromptSegments/lib/resources/PromptResource';
import { Badge } from 'react-bootstrap';
import { PromptIndexResponse } from 'RtModels';
import { PromptSegmentRouter } from 'RtUi/app/rtSip/PromptSegments/PromptSegment.router';

interface IPromptSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<PromptIndexResponse, isMulti> {
	resource?: PromptResource;
}

export class PromptSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	PromptIndexResponse,
	isMulti,
	IPromptSelectProps<isMulti>,
	ISelectFormControlState<PromptIndexResponse>
> {
	public static defaultProps = {
		router: PromptSegmentRouter
	};
	public resourceClass = PromptResource;
	public state: ISelectFormControlState<PromptIndexResponse> = {
		formLabel: 'Prompt',
		valueKey: 'promptId',
		labelKey: 'script'
	};

	constructor(props: IPromptSelectProps<isMulti>) {
		super(props);
		if (this.props.resource) {
			this.resource = this.props.resource;
		}
	}

	public optionRenderer = (record: PromptIndexResponse) => (
		<span>
			<Badge className="text-wrap" bg="light" text="dark">
				{record.label}
			</Badge>
			<span>
				&nbsp;&nbsp;
				{record.script}
			</span>
		</span>
	);
}
