/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationNoteIndexResponse = {
    properties: {
        guardianReconciliationNoteId: {
            type: 'number',
            isRequired: true,
        },
        reconciliationId: {
            type: 'number',
            isRequired: true,
        },
        reconciliationDetailId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        reconciliationNoteType: {
            type: 'GuardianReconciliationNoteTypes',
            isRequired: true,
        },
        note: {
            type: 'string',
            isRequired: true,
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedBy: {
            type: 'number',
            isRequired: true,
        },
        updateTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        createdUserEmail: {
            type: 'string',
            isRequired: true,
        },
    },
};