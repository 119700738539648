import { AccountIndexRequest, AccountIndexResponse } from 'RtModels';
import { AccountRouter } from 'RtUi/app/AccountManagement/Accounts/Account.router';
import { useGetAccounts } from 'RtUi/app/AccountManagement/Accounts/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getAccountsColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/accounts';
import { ensureFullResults } from 'RtUi/utils/http/AxiosHttpRequest';
import { useMemo } from 'react';

interface IAccountsCountryGridProps {
	autoFocusFilter?: boolean;
	resourceParams?: AccountIndexRequest;
}

export const AccountsDataGrid = ({
	resourceParams
}: IAccountsCountryGridProps) => {
	const { data, isFetching: isLoading } = useGetAccounts(
		ensureFullResults(resourceParams)
	);
	const columns = useMemo(() => getAccountsColumns(), []);

	return (
		<DataGrid<AccountIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			router={AccountRouter}
			pageName={'accountManagement_accounts'}
		/>
	);
};
