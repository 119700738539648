import {
	GuardianAlertIndexResponse,
	GuardianAlertRuleIndexRequest
} from 'RtModels';
import { AlertRulesRouter } from 'RtUi/app/rtGuardian/AlertRules/AlertRules.router';
import { useGetGuardianAlertRules } from 'RtUi/app/rtGuardian/AlertRules/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getAlertRulesColumns } from 'RtUi/components/data/DataGrid/configurations/rtGuardian/alertRules';
import { useMemo } from 'react';

interface IAlertRuleGridProp {
	resourceParams?: Partial<GuardianAlertRuleIndexRequest>;
}

export const AlertRuleGrid = ({
	resourceParams
}: IAlertRuleGridProp): JSX.Element => {
	const { data, isFetching: isLoading } =
		useGetGuardianAlertRules(resourceParams);

	const columns = useMemo(() => getAlertRulesColumns(), []);

	return (
		<DataGrid<GuardianAlertIndexResponse>
			data={data?.data}
			loading={isLoading}
			router={AlertRulesRouter}
			pageName="rtGuardian-alertRules"
			columns={columns}
			totalRows={data?.totalCount}
		/>
	);
};
