import { GatewayIndexResponse } from 'RtModels';
import { SipGatewayResource } from 'RtUi/app/rtSip/Connections/lib/resources/SipGatewayResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';
import { DataGridColumnAlignment } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface GatewayPortDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		GatewayIndexResponse,
		T
	> {}

export const GatewayDataGridCache = new ResourceCacheMap<GatewayIndexResponse>(
	new SipGatewayResource(),
	'partitionSipGatewayId',
	'listenFqdn'
);

export const GatewayPortDataGridCache =
	new ResourceCacheMap<GatewayIndexResponse>(
		new SipGatewayResource(),
		'partitionSipGatewayId',
		'listenPort'
	);

export const GatewayPortDataGridColumn = <T = any,>(
	config: GatewayPortDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: GatewayPortDataGridCache,
		align: DataGridColumnAlignment.CENTER,
		size: 120,
		...config
	});
};
