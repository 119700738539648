import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { AosNetwork } from 'Somos/lib/SomosCpr/RtCprV2';

export interface INetworkAreaOfServiceValuesType {
	label: string;
	value: AosNetwork;
	nonCombinational?: boolean;
}

export const AreaOfServiceLabelsMap = new Map<AosNetwork, string>([
	[AosNetwork.Canada, 'Canada'],
	[AosNetwork.Caribbean, 'Caribbean'],
	[AosNetwork.UnitedStates, 'United States (50 states and D.C.)'],
	[AosNetwork.UnitedStatesCanada, 'US and Canada'],
	[AosNetwork.UnitedStatesCaribbean, 'US and Caribbean'],
	[AosNetwork.UnitedStatesCanadaCaribbean, 'US and Canada and Caribbean']
]);

export const LabelsToAreaOfServiceMap = new Map<string, AosNetwork>(
	Array.from(AreaOfServiceLabelsMap.entries()).map(([aos, label]) => [
		label,
		aos
	])
);

export const AreaOfServiceAllValueTypes: INetworkAreaOfServiceValuesType[] = [
	{
		value: AosNetwork.Canada,
		label: AreaOfServiceLabelsMap.get(AosNetwork.Canada)!
	},
	{
		value: AosNetwork.Caribbean,
		label: AreaOfServiceLabelsMap.get(AosNetwork.Caribbean)!
	},
	{
		value: AosNetwork.UnitedStates,
		label: AreaOfServiceLabelsMap.get(AosNetwork.UnitedStates)!,
		nonCombinational: true
	},
	{
		value: AosNetwork.UnitedStatesCanada,
		label: AreaOfServiceLabelsMap.get(AosNetwork.UnitedStatesCanada)!
	},
	{
		value: AosNetwork.UnitedStatesCaribbean,
		label: AreaOfServiceLabelsMap.get(AosNetwork.UnitedStatesCaribbean)!
	},
	{
		value: AosNetwork.UnitedStatesCanadaCaribbean,
		label: AreaOfServiceLabelsMap.get(AosNetwork.UnitedStatesCanadaCaribbean)!,
		nonCombinational: true
	}
];

export class AreaOfServiceValuesResource extends ArrayResource<INetworkAreaOfServiceValuesType> {
	constructor() {
		super('value', AreaOfServiceAllValueTypes);
	}
}
