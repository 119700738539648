import { RtxSipApiRoutes } from 'RtExports/routes';
import { AccountProfileResponse } from 'RtModels';
import { AccountContacts } from 'RtUi/app/AccountManagement/Accounts/lib/components/AccountContacts';
import { AccountWarnings } from 'RtUi/app/AccountManagement/Accounts/lib/components/AccountWarnings';
import { AccountEditForm } from 'RtUi/app/AccountManagement/Accounts/lib/forms/AccountEditForm';
import { CreditLimitEditForm } from 'RtUi/app/AccountManagement/Accounts/lib/forms/CreditLimitEditForm';
import { AccountPortLimitDataGrid } from 'RtUi/app/AccountManagement/Accounts/lib/grids/AccountPortLimitDataGrid';
import { AccountSubscriptionDataGrid } from 'RtUi/app/AccountManagement/Accounts/lib/grids/AccountSubscriptionDataGrid';
import { SubscriptionEditor } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionEditor';
import { CdrExportGrid } from 'RtUi/app/rtSip/CdrExports/lib/grids/CdrExportGrid';
import { ConnectionGrid } from 'RtUi/app/rtSip/Connections/lib/grids/ConnectionGrid';
import { ContactFormEditor } from 'RtUi/app/rtSip/Contacts/lib/forms/ContactFormEditor';
import { InvoiceDataGrid } from 'RtUi/app/rtSip/Invoices/lib/grid/InvoiceDataGrid';
import { PortLimitFormEditor } from 'RtUi/app/rtSip/PortLimits/lib/forms/PortLimitFormEditor';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { AccountRouter } from './Account.router';
import { AccountResource } from './lib/resources/AccountResource';
import { IApplicationContainerProps } from 'RtUi/components/containers/ApplicationContainer';

interface IAccountContainerState {
	account: AccountProfileResponse | undefined;
	activeTab: string;
}

@AccountRouter.getProfileRtUiController()
export class AccountProfileContainer extends ProfileApplicationContainer<
	IApplicationContainerProps,
	IAccountContainerState
> {
	public ActionTabs = {
		CreatePortLimit: 'Create Port Limit',
		AddContact: 'Add Contact',
		AddSubscription: 'Add Subscription'
	};

	public Tabs = AccountRouter.getProfileTabs();

	public state: IAccountContainerState = {
		account: undefined,
		activeTab: this.Tabs.Profile.header
	};

	public async componentDidMount() {
		const accountResource = new AccountResource();
		const accountId = this.getIdParam();

		const account = await accountResource.get(accountId);
		this.setState({ account });
	}

	public componentDidUpdate(
		prevProps: Readonly<IApplicationContainerProps>
	): void {
		if (this.props.params.id !== prevProps.params.id) {
			this.componentDidMount();
		}
	}

	public render() {
		const { account } = this.state;

		if (!account) {
			return null;
		}

		return (
			<TabbedLayout<AccountProfileResponse>
				router={AccountRouter}
				profile={account}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<TwoFADisabledWarning />
					<AccountWarnings
						accountId={account.accountId}
						onAddContact={() => {
							this.setState({ activeTab: this.ActionTabs.AddContact });
						}}
					/>
					<Row>
						<Col lg={8} className="mb-3">
							<Card>
								<Card.Body>
									<div>
										Customer Balance{' '}
										<Badge bg="info" className="p-1 me-1">
											{account.customerBalance || '0.00'}
										</Badge>{' '}
										Vendor Balance{' '}
										<Badge bg="info" className="p-1 me-1">
											{account.vendorBalance || '0.00'}
										</Badge>{' '}
										Combined Balance{' '}
										<Badge bg="info" className="p-1">
											{account.combinedBalance || '0.00'}
										</Badge>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<AccountEditForm profile={account} />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Subscriptions}>
					<Row className="mb-3">
						<Col>
							<AccountSubscriptionDataGrid
								header="Customer Subscriptions"
								accountId={account.accountId}
								pageName={'accountManagement_accountCustomerSubscriptions'}
								isCustomer
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<AccountSubscriptionDataGrid
								header="Vendor Subscriptions"
								accountId={account.accountId}
								pageName={'accountManagement_accountVendorSubscriptions'}
							/>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Connections}>
					<TwoFADisabledWarning />
					<ConnectionGrid resourceParams={{ accountId: account.accountId }} />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.PortLimits}>
					<AccountPortLimitDataGrid accountId={account.accountId} />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Invoices}>
					<InvoiceDataGrid
						resourceParams={{
							accountId: account.accountId
						}}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Contacts}>
					<AccountContacts accountId={account.accountId} />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.CdrExports}>
					<CdrExportGrid
						resourceParams={{
							accountIsIn: [account.accountId]
						}}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					isAction
					header={this.ActionTabs.CreatePortLimit}
					rtRoute={RtxSipApiRoutes.PortLimits.Create}
				>
					<Row>
						<Col lg={4} xl={4}>
							<PortLimitFormEditor
								onSuccess={() => {
									this.setState({ activeTab: this.Tabs.PortLimits.header });
								}}
								accountId={account.accountId}
							/>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.CreditLimits}>
					<CreditLimitEditForm accountId={account.accountId} />
				</TabbedLayoutTab>
				<TabbedLayoutTab
					isAction
					header={this.ActionTabs.AddContact}
					rtRoute={RtxSipApiRoutes.Contacts.Create}
				>
					<Row>
						<Col lg={8}>
							<ContactFormEditor
								onSuccess={() => {
									this.setState({ activeTab: this.Tabs.Contacts.header });
								}}
								accountId={account.accountId}
							/>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					header={this.ActionTabs.AddSubscription}
					isAction
					rtRoute={RtxSipApiRoutes.Subscriptions.Create}
				>
					<SubscriptionEditor
						createMode
						accountId={account.accountId}
						onUpdate={() =>
							this.setState({ activeTab: this.Tabs.Subscriptions.header })
						}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
