import { RateCenterIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

interface IRateCenterSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<RateCenterIndexResponse, IsMulti> {
	showAllOption?: boolean;
}

const rtVueHttp = new RtVueHttp();

export class RateCenterSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	RateCenterIndexResponse,
	IsMulti,
	IRateCenterSelectProps<IsMulti>
> {
	public resourceClass = undefined;
	public resource = new ArrayResource<RateCenterIndexResponse>(
		'rateCenterId',
		[]
	);

	public state: ISelectFormControlState<RateCenterIndexResponse> = {
		formLabel: '',
		valueKey: 'rateCenterId',
		labelKey: 'label'
	};

	public async getData() {
		const rateCenterData = await rtVueHttp.getRateCenterFilter();
		this.resource.setAllData(rateCenterData.data);

		return this.resource.getAll();
	}
}
