import {
	GuardianServiceNumberSubscriptionIndexResponse,
	PortingStatusIndexResponse
} from 'RtModels';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getGuardianServiceNumberSubscriptionColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/serviceNumberSubscriptions';
import { useMemo } from 'react';

interface IGuardianServiceNumberSubscriptionBulkCancelGridProps {
	serviceNumbers: GuardianServiceNumberSubscriptionIndexResponse[];
	portingStatus?: PortingStatusIndexResponse;
	custOrderNumber?: string;
	autoFocusFilter?: boolean;
}

export const GuardianServiceNumberSubscriptionBulkCancelGrid = ({
	serviceNumbers,
	custOrderNumber,
	portingStatus
}: IGuardianServiceNumberSubscriptionBulkCancelGridProps) => {
	const columns = useMemo(
		() =>
			getGuardianServiceNumberSubscriptionColumns(
				portingStatus,
				custOrderNumber
			),
		[custOrderNumber, portingStatus]
	);

	return (
		<DataGrid<GuardianServiceNumberSubscriptionIndexResponse>
			data={serviceNumbers}
			columns={columns}
			pageName={'rtVue_guardianServiceNumberBulkCancel'}
		/>
	);
};
