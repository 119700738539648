import { Col, Row, Alert, Card, Button } from 'react-bootstrap';
import { InvoiceProfileResponse } from 'RtModels';
import { InvoicesRouter } from 'RtUi/app/rtSip/Invoices/Invoices.router';
import { InvoiceSubscriptionGrid } from 'RtUi/app/rtSip/Invoices/lib/grid/InvoiceSubscriptionGrid';
import { InvoiceResource } from 'RtUi/app/rtSip/Invoices/lib/resources/InvoiceResource';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';

interface IInvoicesProfileContainerProps {}

interface IInvoicesProfileContainerState {
	activeTab: string;
	profile?: InvoiceProfileResponse;
	isRerun: boolean;
	isDownloading: boolean;
	operationError?: string;
}

@InvoicesRouter.getProfileRtUiController()
export class InvoicesProfileContainer extends ProfileApplicationContainer<
	IInvoicesProfileContainerProps,
	IInvoicesProfileContainerState
> {
	public Tabs = InvoicesRouter.getProfileTabs();

	public state: IInvoicesProfileContainerState = {
		activeTab: this.Tabs.Profile.header,
		isRerun: false,
		isDownloading: false
	};

	private resource = new InvoiceResource();

	public componentDidMount() {
		const profileId = this.getIdParam();
		this.resource.get(profileId).then((profile) => this.setState({ profile }));
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}

		const canManage = InvoicesRouter.canManageWith(profile);

		return (
			<TabbedLayout<InvoiceProfileResponse>
				router={InvoicesRouter}
				profile={profile}
				canManageFn={() => canManage}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<TwoFADisabledWarning />
					<Row className="mb-3">
						<Col>
							<Card>
								<Card.Body>
									<Button
										variant="outline-primary"
										disabled={this.state.isDownloading}
										onClick={this.downloadInvoice}
									>
										<i className="fas fa-fw fa-download" /> Download Invoice
									</Button>
									<Button
										variant="outline-primary"
										disabled={this.state.isRerun}
										className="ms-2"
										onClick={this.reRunInvoice}
									>
										<i className="fas fa-fw fa-redo" /> Re-Run Invoice
									</Button>
									{this.state.operationError && (
										<Alert variant="danger" className="my-2 d-flex d-row">
											<i className="fas fa-exclamation-circle me-2" />
											{this.state.operationError}
										</Alert>
									)}
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<InvoiceSubscriptionGrid invoiceId={profile.invoiceId} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}

	private reRunInvoice = async () => {
		const { profile } = this.state;

		try {
			this.setState({ isRerun: true, operationError: undefined });

			await this.resource.reRun(profile!.invoiceId);
			this.goToPath(InvoicesRouter.getIndexRoute());
		} catch (err) {
			const operationError = this.getErrorMessage(err);
			this.setState({ operationError });
		} finally {
			this.setState({ isRerun: false });
		}
	};

	private downloadInvoice = async () => {
		const { profile } = this.state;

		try {
			this.setState({ isDownloading: true, operationError: undefined });

			await this.resource.download(profile!.invoiceId);
		} catch (err) {
			const operationError = this.getErrorMessage(err);
			this.setState({ operationError });
		} finally {
			this.setState({ isDownloading: false });
		}
	};

	private getErrorMessage(error: any) {
		if (error instanceof Error) {
			return error.message;
		}

		return String(error);
	}
}
