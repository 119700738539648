export function hasParentWithClassName(
	elem: HTMLElement | null,
	className: string
): boolean {
	if (elem === null) {
		return false;
	}

	if (elem.className.split(' ').includes(className)) {
		return true;
	}

	return hasParentWithClassName(elem.parentElement, className);
}

export function getComputedStyleFromClasses(className: string) {
	const span = document.createElement('span');
	span.style.display = 'none';
	span.className = className;

	document.body.appendChild(span);

	//Must copy otherwise:
	//styles will be removed by removeChild() below
	const computedStyle = { ...window.getComputedStyle(span) };

	document.body.removeChild(span);

	return computedStyle;
}
