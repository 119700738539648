import { isNil } from 'lodash-es';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

const TTL = 60 * 1000; //one minute

export class ResourceCacheMap<T, P = any> {
	private CachedMap: Map<number | string, string> | null = null;
	private IndexDefer: Promise<T[]> | null = null;

	constructor(
		public resource: Pick<
			HttpResource<T>,
			'getAll' | 'userHasIndexPermissions'
		>,
		public idKey: keyof T,
		public valueKey: keyof T,
		public fallbackKey?: keyof T,
		public valueGetter?: (o: T) => any,
		public extraParams?: P
	) {}

	public userHasRequiredPermissions() {
		return this.resource.userHasIndexPermissions();
	}

	public getLabelFor(id: string | number): string {
		return this.CachedMap?.get(id) ?? '';
	}

	public isPopulated(): boolean {
		return this.CachedMap !== null;
	}

	public async warmup() {
		if (!this.isPopulated()) {
			await this.get(0);
		}
	}

	public async get(id: number | string): Promise<string | undefined> {
		if (this.IndexDefer === null) {
			this.IndexDefer = this.resource.getAll(this.extraParams).then((data) => {
				this.CachedMap = new Map<number | string, string>(
					data.map((r) => [
						r[this.idKey] as any,
						this.valueGetter
							? this.valueGetter(r)
							: String(
									isNil(r[this.valueKey]) && this.fallbackKey
										? r[this.fallbackKey]
										: r[this.valueKey]
								)
					])
				);
				return data;
			});

			//Remove cache after TTL
			setTimeout(() => (this.IndexDefer = null), TTL);
		}

		await this.IndexDefer;
		if (this.CachedMap !== null) {
			return this.CachedMap.get(id);
		}
	}
}
