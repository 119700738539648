import { ObjectDataTree } from 'RtUi/app/rtGuardian/AlertHistory/lib/components/ObjectDataTree';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface ConditionsDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const ConditionsDataGridColumn = <T = any,>({
	...config
}: ConditionsDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return DefaultDataGridColumn({
		...config,
		getValue: ({ cell }) => (
			<ObjectDataTree data={cell.getValue<any>()} className="list-unstyled" />
		)
	});
};
