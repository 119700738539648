import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	PortingStatusIndexResponse,
	ServiceNumberCreateRequest,
	ServiceNumberIndexRequest,
	ServiceNumberIndexResponse,
	ServiceNumberProfileResponse,
	ServiceNumberProfileUrlRequest,
	ServiceNumberUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ServiceNumberResource extends HttpResource<
	ServiceNumberIndexResponse,
	ServiceNumberProfileResponse,
	ServiceNumberIndexRequest
> {
	constructor() {
		super(RtCommonApiRoutes.ServiceNumbers);
	}

	public getPortingStatuses() {
		return this.fetchWithRoute<PortingStatusIndexResponse[]>(
			RtCommonApiRoutes.PortingStatus.Index
		);
	}

	public create(req: ServiceNumberCreateRequest) {
		const body = JSON.stringify(req);

		return this.fetchWithRoute<ServiceNumberProfileResponse>(
			RtCommonApiRoutes.ServiceNumbers.Create,
			{
				body
			}
		);
	}

	public update(serviceNumber: string, req: ServiceNumberUpdateRequest) {
		const urlParams: ServiceNumberProfileUrlRequest = {
			serviceNumber
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute<ServiceNumberProfileResponse>(
			RtCommonApiRoutes.ServiceNumbers.Update,
			{
				body,
				urlParams
			}
		);
	}

	public createNew(serviceNumber = ''): ServiceNumberProfileResponse {
		return {
			serviceNumber,
			isActive: 1,
			isPorted: 0,
			cnamReg: 0,
			cnamDip: 0,
			isDirAsst: 0,
			isE911: 0,
			createdTs: new Date()
		};
	}
}
