/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RatePlanRateIndexResponse = {
    properties: {
        rateId: {
            type: 'number',
            isRequired: true,
        },
        rateSheetId: {
            type: 'number',
            isRequired: true,
        },
        rateKey: {
            type: 'string',
            isRequired: true,
        },
        rateSummaryId: {
            type: 'number',
            isRequired: true,
        },
        rateSummary: {
            type: 'string',
            isRequired: true,
        },
        rateCenterId: {
            type: 'number',
            isRequired: true,
        },
        rateCenter: {
            type: 'string',
            isRequired: true,
        },
        iso3: {
            type: 'string',
            isRequired: true,
        },
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        interRate: {
            type: 'number',
            isRequired: true,
        },
        intraRate: {
            type: 'number',
            isRequired: true,
        },
        indetRate: {
            type: 'number',
            isRequired: true,
        },
        localRate: {
            type: 'number',
            isRequired: true,
        },
        durMin: {
            type: 'number',
            isRequired: true,
        },
        durInc: {
            type: 'number',
            isRequired: true,
        },
        conFee: {
            type: 'number',
            isRequired: true,
        },
    },
};