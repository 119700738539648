/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $PeerlessResourceConfigurationExtraParams = {
    properties: {
        customerId: {
            type: 'string',
            isRequired: true,
        },
        routeLabel: {
            type: 'string',
            isRequired: true,
        },
    },
};