/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum FullSummaryTimeDisplay {
    None = 0,
    TimeQuarterly = 1,
    TimeUTCDaily = 2,
    TimeESTDaily = 3,
    TimeHourly = 4,
}