/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrAnalysisCreateResponse = {
    properties: {
        rateKey1: {
            type: 'string',
            isRequired: true,
        },
        rateKey2: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
        },
        country: {
            type: 'string',
            isRequired: true,
        },
        cprNode: {
            type: 'number',
            isRequired: true,
        },
        cprCic: {
            type: 'string',
            isRequired: true,
        },
        interRate: {
            type: 'number',
            isRequired: true,
        },
        intraRate: {
            type: 'number',
            isRequired: true,
        },
        indetRate: {
            type: 'number',
            isRequired: true,
        },
        totalMins: {
            type: 'number',
            isRequired: true,
        },
        totalCost: {
            type: 'number',
            isRequired: true,
        },
    },
};