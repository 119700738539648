/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RouteTrustValidationErrorLevel {
    Notice = 1,
    Warning = 2,
    Error = 3,
    Critical = 9,
}