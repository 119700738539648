import { DirectConnectIndexResponse } from 'RtModels';
import { DirectConnectResource } from 'RtUi/app/rtSip/DirectConnect/lib/resources/DirectConnectResource';
import { useGetDirectConnect } from 'RtUi/app/rtSip/DirectConnect/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getDirectConnectColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/directConnect';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import { Item } from 'react-contexify';

interface IDirectConnectGridProps {
	autoFocusFilter?: boolean;
}

export const DirectConnectGrid = ({}: IDirectConnectGridProps) => {
	const { data, isFetching: isLoading } = useGetDirectConnect();

	const columns = useMemo(() => getDirectConnectColumns(), []);

	return (
		<>
			<Alert variant="info-outline">
				If a SIP client is able to register using the correct SIP user name and
				password, but receives a &quot;Forbidden&quot; response when attempting
				to dial be sure to check if the invite Expiration time below is valid.
				You may need to activate the account to allow it to dial.
			</Alert>
			<DataGrid<DirectConnectIndexResponse>
				data={data?.data}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtSip_directConnect'}
				menuLinks={(record) => (
					<>
						<Item
							onClick={async () => {
								const confirm = await Confirmation.create(
									<div>
										<p>Are you sure you would like to activate?</p>
									</div>
								);

								if (!confirm) {
									return;
								}

								const resource = new DirectConnectResource();
								resource.activate(record.directConnectId);
							}}
						>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-check" />
								<span>Activate</span>
							</span>
						</Item>
						<Item
							onClick={async () => {
								const confirm = await Confirmation.create(
									<div>
										<p>
											A new SIP login password will be generated and displayed
											if you confirm this operation.
										</p>
										<p>
											Are you sure you would like to generate a new password?
										</p>
									</div>
								);

								if (!confirm) {
									return;
								}

								const resource = new DirectConnectResource();
								await resource.resetPassword(record.directConnectId);
							}}
						>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-lock" />
								<span>Reset Password</span>
							</span>
						</Item>
					</>
				)}
				columns={columns}
			/>
		</>
	);
};
