/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $UserPermissionUpdateRequest = {
    properties: {
        permissionIds: {
            type: 'Array',
            isRequired: true,
        },
        portalCarrierIds: {
            type: 'Array',
            isRequired: true,
        },
        entityIds: {
            type: 'Array',
        },
        respOrgIds: {
            type: 'Array',
        },
        defaultRespOrgId: {
            type: 'string',
        },
    },
};