import { FC } from 'react';
import { ServiceNumberLookUpType } from 'RtModels';
import { IFormControlProps } from 'RtUi/components/form/FormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';

type ServiceNumberLookupControlProps = Omit<
	IFormControlProps<ServiceNumberLookUpType>,
	'options'
>;

export const ServiceNumberLookupControl: FC<
	React.PropsWithChildren<ServiceNumberLookupControlProps>
> = (props) => {
	return (
		<RadioFormControl<number>
			label="Direction"
			{...props}
			options={[
				{
					value: ServiceNumberLookUpType.DialedNumber,
					label: 'Dialed Number'
				},
				{
					value: ServiceNumberLookUpType.Ani,
					label: 'ANI'
				}
			]}
		/>
	);
};
