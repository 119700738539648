import { IntegrationErrorMessage } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getIntegrationErrorMessageColumns = (): Array<
	DataGridColumn<IntegrationErrorMessage>
> => [
	LabelDataGridColumn({
		accessorKey: 'label',
		enableEditing: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'integrationErrorMessageId',
		header: 'Integration Error Message Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'integrationId',
		header: 'Integration Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'intMessageType',
		header: 'Int Message Type'
	}),
	DefaultDataGridColumn({
		accessorKey: 'intResponseCode',
		header: 'Int Response Code'
	}),
	DefaultDataGridColumn({
		accessorKey: 'intResponseSummary',
		header: 'Int Response Summary'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isWarning',
		header: 'Warning'
	}),
	DefaultDataGridColumn({
		accessorKey: 'code',
		header: 'Code'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	DateDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	DateDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated'
	})
];
