import { RoutingSummaryIndexRequest } from 'RtModels';
import { useGetRoutingSummary } from 'RtUi/app/rtSip/RoutingSummary/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRoutingSummaryColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/routingSummary';
import { useMemo } from 'react';

interface IRoutingSummaryDataGridProps {
	autoFocusFilter?: boolean;
	resourceParams?: RoutingSummaryIndexRequest;
}

export const RoutingSummaryDataGrid = ({
	resourceParams
}: IRoutingSummaryDataGridProps) => {
	const { data, isFetching: isLoading } = useGetRoutingSummary(resourceParams);
	const columns = useMemo(() => getRoutingSummaryColumns(), []);

	return (
		<DataGrid
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_routingSummary'}
		/>
	);
};
