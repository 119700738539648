import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class CprLblState extends CprLbl {

	public readonly cprNodeTypeId = CprNodeType.State;

	public readonly valueLimit = 255;
	public readonly valueRegExp = /^[A-Z]{2}$/;

	protected readonly possibles = CprLergCache.States;
	protected readonly possiblesByKey = CprLergCache.CprLergByState;

	protected readonly canadaPossibles = CprLergCache.CanadaStates;
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaStatesByKey;

	protected readonly canLabel = true;

}
