//import * as React from 'react';
import { TimezoneIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { TimezoneResource } from '../resources/TimezoneResource';

interface ITimezoneSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<TimezoneIndexResponse, IsMulti> {}
interface ITimezoneSelectState
	extends ISelectFormControlState<TimezoneIndexResponse> {}

export class TimezoneSelectLabel<
	IsMulti extends boolean = false
> extends SelectFormControl<
	TimezoneIndexResponse,
	IsMulti,
	ITimezoneSelectProps<IsMulti>,
	ITimezoneSelectState
> {
	public resourceClass = TimezoneResource;
	public state: ITimezoneSelectState = {
		formLabel: 'Time Zone',
		valueKey: 'label',
		labelKey: 'label'
	};
}
