import * as React from 'react';
import { ProductId } from 'RtModels';
import { ProductIdToNameMap } from 'RtUi/app/rtCommon/Products/utils';
import {
	SimpleSelectFormControl,
	ISimpleSelectFormControlOption,
	ISimpleSelectFormControlProps
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

export type ProductSelectOption = ISimpleSelectFormControlOption<ProductId>;

interface IProductSelectProps
	extends Omit<ISimpleSelectFormControlProps<ProductId, false>, 'options'> {}

export class ProductSelect extends React.Component<IProductSelectProps> {
	public render() {
		const options: Array<ISimpleSelectFormControlOption<ProductId>> = [];

		for (const productId of ProductIdToNameMap.keys()) {
			options.push({
				value: productId,
				label: ProductIdToNameMap.get(productId)!
			});
		}

		return (
			<SimpleSelectFormControl<ProductId> {...this.props} options={options} />
		);
	}
}
