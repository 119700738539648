import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';

import {
	DataGridColumnAlignment,
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';

interface PhoneNumberDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const PhoneNumberDataGridColumn = <T = any,>({
	align = DataGridColumnAlignment.LEFT,
	...config
}: PhoneNumberDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const formatValue = (value: any) => {
		const phoneFormatter = new PhoneNumberFormatter();

		if (!value) {
			return '';
		}

		if (!phoneFormatter.isValid(value)) {
			return value;
		}

		const e164 = phoneFormatter.toE164(value);

		if (e164) {
			return e164;
		}

		const international = phoneFormatter.toInternational(value);

		if (international) {
			return international;
		}

		return value;
	};

	return DefaultDataGridColumn({
		align,
		...config,
		exportValue: (value: any) => `${formatValue(value)}`,
		getValue: ({ cell }) => formatValue(cell.getValue<string>())
	});
};
