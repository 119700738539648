/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExportDestinationUpdateRequest = {
    properties: {
        destinationTypeId: {
            type: 'DestinationTypeId',
        },
        credentials: {
            properties: {
            },
        },
        remotePath: {
            type: 'string',
            isNullable: true,
        },
    },
};