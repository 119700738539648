import { Plugin } from 'flatpickr/dist/types/options';
import * as dateFns from 'date-fns';

export interface ICancelCloseConfig {
	onClick: () => void;
}

function cancelClosePlugin(pluginConfig: ICancelCloseConfig): Plugin {
	let $cancelContainer: HTMLButtonElement;

	return function (fp) {
		return {
			onReady() {
				$cancelContainer = document.createElement('button');
				$cancelContainer.classList.add('btn');
				$cancelContainer.classList.add('float-end');
				$cancelContainer.classList.add('btn-white-alt');
				$cancelContainer.classList.add('mt-2');
				$cancelContainer.classList.add('btn-sm');
				$cancelContainer.innerText = 'Cancel';
				$cancelContainer.tabIndex = -1;

				$cancelContainer.addEventListener('click', (event) => {
					pluginConfig.onClick();
				});
				fp.calendarContainer.appendChild($cancelContainer);

				// this is needed just for prettier display
				fp.jumpToDate(dateFns.subDays(new Date(), 7));
				fp.loadedPlugins.push('cancelClose');
			}
		};
	};
}

export default cancelClosePlugin;
