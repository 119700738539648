import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2';
interface CprLabelNameDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	displayMode: boolean;
	onChange: (value: string, row: CprLbl) => void;
}

export const CprLabelNameDataGridColumn = <T = any,>({
	displayMode,
	onChange,
	size = 150,
	...config
}: CprLabelNameDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const renderValue = (value: string, row: CprLbl) => {
		if (displayMode) {
			return value;
		}

		return (
			<input
				className="form-control"
				value={value}
				onChange={(evt) => {
					const { value } = evt.currentTarget;

					onChange(value.toUpperCase(), row);
				}}
			/>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		exportValue: (value) => value ?? '',
		getValue: ({ cell, row }) =>
			renderValue(cell.getValue<string>(), row.original as any)
	});
};
