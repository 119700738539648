import * as moment from 'moment';
import { Button, Form } from 'react-bootstrap';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Aside } from 'RtUi/components/ui/Aside';
import { RespOrgIndexResponse } from 'RtModels';
import {
	INumberProfile,
	NumberResource
} from 'RtUi/app/rt800/Numbers/lib/resources/NumberResource';
import { NumberRouter } from 'RtUi/app/rt800/Numbers/Number.router';
import { NumberHistoryGrid } from 'RtUi/app/rt800/Numbers/lib/grids/NumberHistoryGrid';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';

interface IHasTfn {
	tfn: string;
}

interface INumberQuickViewAsideProps {
	isOpen: boolean;
	toggle: (isOpen: boolean) => void;
	record: IHasTfn;
}

interface INumberQuickViewAsideState {
	numberProfile?: INumberProfile;
	lastWorkingRespOrg?: RespOrgIndexResponse;
}

export class NumberQuickViewAside extends ApplicationContainer<
	INumberQuickViewAsideProps,
	INumberQuickViewAsideState
> {
	public state: INumberQuickViewAsideState = {};

	public numberResource = new NumberResource();

	public componentDidMount() {
		this.numberResource.get(this.props.record.tfn).then((numberProfile) => {
			this.setState({ numberProfile });
		});
	}

	public goToNumber() {
		const tfnPath = NumberRouter.getProfileRoute(this.props.record.tfn);

		this.goToPath(tfnPath);
	}

	public render() {
		const { numberProfile } = this.state;
		let totalWorkDaysStr = 'N/A';
		let totalWorkDaysSubLabel: string | undefined;

		if (numberProfile && typeof numberProfile.totalWorkingDays === 'number') {
			totalWorkDaysStr = numberProfile.totalWorkingDays.toLocaleString();

			if (numberProfile.totalWorkingDays > 0) {
				const readableTotalWorkDays = moment
					.duration(numberProfile.totalWorkingDays, 'days')
					.humanize();

				totalWorkDaysSubLabel = ` (~${readableTotalWorkDays})`;
			}
		}

		return (
			<Aside isOpen={this.props.isOpen}>
				<header className="mb-3 d-flex justify-content-between align-items-center">
					<a className="h5 mb-0" onClick={() => this.goToNumber()}>
						{TollFreeNumbersUtils.tollFreeNumberToReadableString(
							this.props.record.tfn
						)}
						{!numberProfile && (
							<>
								&nbsp;
								<i className="fas fa-fw fa-cog fa-spin text-muted" />
							</>
						)}
					</a>
					<Button
						type="button"
						variant="light"
						size="sm"
						onClick={() => this.props.toggle(false)}
					>
						<i className="fas fa-fw fa-times" />
					</Button>
				</header>
				{numberProfile && (
					<section>
						<InputFormControl
							label="Last Working Days"
							displayMode
							subLabel={totalWorkDaysSubLabel}
							value={totalWorkDaysStr}
						/>
						<RespOrgSelect
							label="Last RespOrg"
							displayMode
							onChange={(lastWorkingRespOrg) =>
								this.setState({ lastWorkingRespOrg })
							}
							value={this.state.lastWorkingRespOrg}
							initialOptionId={
								numberProfile.lastWorkingRespOrgId
									? numberProfile.lastWorkingRespOrgId
									: undefined
							}
						/>
						<article>
							<Form.Label>History</Form.Label>
							<NumberHistoryGrid
								quickView
								disableFilter
								mobilePagination
								tfn={numberProfile.tfn}
							/>
						</article>
					</section>
				)}
			</Aside>
		);
	}
}
