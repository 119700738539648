/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $BulkQueryRequest = {
    properties: {
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        numberGroupId: {
            type: 'number',
        },
        tfns: {
            type: 'Array',
        },
        scheduledAt: {
            type: 'Date',
            format: 'date-time',
        },
        priority: {
            type: 'number',
        },
        taskLabel: {
            type: 'string',
        },
    },
};