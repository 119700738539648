import * as React from 'react';
import { AccountCreditLimitResource } from 'RtUi/app/AccountManagement/Accounts/lib/resources/AccountCreditLimitResource';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { Form, InputGroup } from 'react-bootstrap';

interface ICreditLimitEditFormProps {
	accountId: number;
}

interface ICreditLimitEditFormState {
	displayMode: boolean;
	isSubmitting: boolean;
	isCustomerLimit: boolean;
	isVendorLimit: boolean;
	isCombinedLimit: boolean;
	customerLimit?: number;
	vendorLimit?: number;
	combinedLimit?: number;
	isLoading: boolean;
	error?: any;
}

export class CreditLimitEditForm extends React.Component<
	ICreditLimitEditFormProps,
	ICreditLimitEditFormState
> {
	public initialState: ICreditLimitEditFormState = {
		displayMode: true,
		isSubmitting: false,
		isCustomerLimit: false,
		isVendorLimit: false,
		isCombinedLimit: false,
		isLoading: true
	};

	public state: ICreditLimitEditFormState = this.initialState;

	public async componentDidMount() {
		const creditLimitResource = new AccountCreditLimitResource(
			this.props.accountId
		);

		const creditLimit = await creditLimitResource.get();

		if (creditLimit) {
			this.initialState = {
				...this.initialState,
				isCustomerLimit: Boolean(creditLimit.customerLimit),
				isVendorLimit: Boolean(creditLimit.vendorLimit),
				isCombinedLimit: Boolean(creditLimit.combinedLimit),
				customerLimit: creditLimit.customerLimit,
				vendorLimit: creditLimit.vendorLimit,
				combinedLimit: creditLimit.combinedLimit,
				isLoading: false
			};

			this.setState(this.initialState);
		}
	}

	public render() {
		const {
			displayMode,
			isCustomerLimit,
			isVendorLimit,
			isCombinedLimit,
			isLoading
		} = this.state;

		if (isLoading) {
			return <Loading />;
		}

		return (
			<RtUiNarrowForm
				isSubmitting={this.state.isSubmitting}
				displayMode={displayMode}
				error={this.state.error}
				onCancel={() => this.setState({ ...this.initialState })}
				onChange={(displayMode) => this.setState({ displayMode })}
				onSubmit={this.update}
			>
				<Form.Group className="mb-3">
					{this.showBoolean(isCustomerLimit) && (
						<BooleanRadioFormControl
							displayMode={displayMode}
							label="Set Customer / Credit Limit"
							onChange={(isCustomerLimit) =>
								this.setState({ isCustomerLimit: Boolean(isCustomerLimit) })
							}
							value={Number(this.state.isCustomerLimit)}
						/>
					)}
					{isCustomerLimit && (
						<>
							<Form.Label>Customer / Credit Limit</Form.Label>
							<InputGroup>
								<InputGroup.Text>$</InputGroup.Text>
								<InputFormControl
									useControlGroup={false}
									onChange={(customerLimit) =>
										this.setState({ customerLimit: Number(customerLimit) })
									}
									placeholder="Type Credit Limit"
									required={isCustomerLimit}
									label=""
									readOnly={displayMode}
									type="number"
									value={this.state.customerLimit?.toString() ?? ''}
								/>
							</InputGroup>
						</>
					)}
				</Form.Group>
				<Form.Group className="mb-3">
					{this.showBoolean(isVendorLimit) && (
						<BooleanRadioFormControl
							displayMode={this.state.displayMode}
							label="Set Vendor / Credit Limit"
							onChange={(isVendorLimit) =>
								this.setState({ isVendorLimit: Boolean(isVendorLimit) })
							}
							value={Number(this.state.isVendorLimit)}
						/>
					)}
					{isVendorLimit && (
						<>
							<Form.Label>Vendor / Credit Limit</Form.Label>
							<InputGroup>
								<InputGroup.Text>$</InputGroup.Text>
								<InputFormControl
									onChange={(vendorLimit) =>
										this.setState({ vendorLimit: Number(vendorLimit) })
									}
									useControlGroup={false}
									label=""
									required={isVendorLimit}
									readOnly={displayMode}
									value={this.state.vendorLimit?.toString() ?? ''}
									type="number"
									placeholder="Type Credit Limit"
								/>
							</InputGroup>
						</>
					)}
				</Form.Group>
				<Form.Group className="mb-3">
					{this.showBoolean(isCombinedLimit) && (
						<BooleanRadioFormControl
							displayMode={this.state.displayMode}
							label="Set Combined Limit"
							onChange={(isCombinedLimit) =>
								this.setState({ isCombinedLimit: Boolean(isCombinedLimit) })
							}
							value={Number(this.state.isCombinedLimit)}
						/>
					)}
					{isCombinedLimit && (
						<>
							<Form.Label>Combined Credit Limits</Form.Label>
							<InputGroup>
								<InputGroup.Text>$</InputGroup.Text>
								<InputFormControl
									onChange={(combinedLimit) =>
										this.setState({ combinedLimit: Number(combinedLimit) })
									}
									label=""
									useControlGroup={false}
									readOnly={displayMode}
									value={this.state.combinedLimit?.toString() ?? ''}
									type="number"
									required={isCombinedLimit}
									placeholder="Type Credit Limit"
								/>
							</InputGroup>
						</>
					)}
				</Form.Group>
			</RtUiNarrowForm>
		);
	}

	private update = async (e: React.FormEvent) => {
		e.preventDefault();

		const creditLimitResource = new AccountCreditLimitResource(
			this.props.accountId
		);

		this.setState({ isSubmitting: true, error: undefined });

		try {
			await creditLimitResource.update({
				customerLimit: Boolean(this.state.isCustomerLimit)
					? this.state.customerLimit
					: undefined,
				vendorLimit: Boolean(this.state.isVendorLimit)
					? this.state.vendorLimit
					: undefined,
				combinedLimit: this.state.isCombinedLimit
					? this.state.combinedLimit
					: undefined
			});

			this.setState({ displayMode: true, isSubmitting: false }, () => {
				this.initialState = this.state;
			});
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	};

	private showBoolean = (value: Boolean) =>
		!this.state.displayMode || (this.state.displayMode && !value);
}
