import { GuardianAlertIndexResponse } from 'RtModels';
import { AlertRulesResource } from 'RtUi/app/rtGuardian/AlertRules/lib/resources/AlertRulesResource';
import {
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

export class AlertRuleSelect<
	isMulti extends boolean = false
> extends SelectFormControl<GuardianAlertIndexResponse, isMulti> {
	public resourceClass = AlertRulesResource;
	public state: ISelectFormControlState<GuardianAlertIndexResponse> = {
		formLabel: 'Alert Rule',
		valueKey: 'guardianAlertId',
		labelKey: 'summary'
	};
}
