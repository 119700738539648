import { RejectDetailsIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { MinutesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MinutesDataGridColumn';
import { RejectCodeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RejectCodeDataGridColumn';

export const getRejectDetailsRevenue = (
	onDrillDown: (row: RejectDetailsIndexResponse, isRevenue: boolean) => void,
	isRevenue: boolean,
	callDateFormat?: string
): Array<DataGridColumn<RejectDetailsIndexResponse>> => [
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		format: callDateFormat
	}),
	DefaultDataGridColumn({
		header: 'Billing Number',
		hiddenIfEmpty: true,
		accessorFn: (row) =>
			row.billingNumber && row.billingNumber !== '' ? row.billingNumber : null
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'trunkGroup',
		header: 'Trunk Group'
	}),
	FileStreamDataGridColumn({
		accessorKey: 'fileStreamId',
		header: 'File Stream'
	}),
	RejectCodeDataGridColumn({
		accessorKey: 'rejectCode',
		header: 'Reject Code'
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalCalls',
		header: 'Total Calls',
		onDrillDown: (e, row) => {
			e.preventDefault();
			onDrillDown(row, isRevenue);
		}
	}),
	MinutesDataGridColumn({
		accessorKey: 'totalMinutes',
		header: 'Total Minutes'
	})
];
