/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ManagedStatus {
    Failing = 'Failing',
    Working = 'Working',
    NoInfo = 'No Info',
    NotAvailable = 'N/A',
}