/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoutingGroupIndexResponse = {
    properties: {
        routingGroupId: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        createdAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        currentRoutingTemplateTypeId: {
            type: 'RoutingTemplateTypes',
            isRequired: true,
        },
    },
};