/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianServiceNumberSubscriptionIndexRequest = {
    properties: {
        guardianServiceNumberSubscriptionId: {
            type: 'number',
        },
        serviceNumber: {
            type: 'string',
        },
        subscriptionId: {
            type: 'number',
        },
        callType: {
            type: 'string',
        },
        serviceTypeId: {
            type: 'number',
        },
        isActive: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        serviceNumbers: {
            type: 'Array',
        },
        accountId: {
            type: 'number',
        },
        custExtRef1: {
            type: 'string',
        },
        custExtRef2: {
            type: 'string',
        },
        custOrderNumber: {
            type: 'string',
        },
        filename: {
            type: 'string',
        },
    },
};