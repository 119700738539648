import { sortBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { RouteDepthIndexRequest, RouteDepthIndexResponse } from 'RtModels';
import { useGetRouteDepth } from 'RtUi/app/rtSip/RouteDepth/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRouteDepthColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/routeDepth';

interface IRouteDepthGridProps {
	autoFocusFilter?: boolean;
	resourceParams?: RouteDepthIndexRequest;
}

export enum RATE_COLUMN {
	INTER_RATE = 'interRate',
	INTRA_RATE = 'intraRate',
	INDET_RATE = 'indetRate'
}

export const RouteDepthGrid = ({
	resourceParams
}: IRouteDepthGridProps): JSX.Element => {
	const [rateColumn, setRateColumn] = useState<RATE_COLUMN>(
		RATE_COLUMN.INTER_RATE
	);
	const [maxDepth, setMaxDepth] = useState<number>(0);
	const { data, isFetching: isLoading } = useGetRouteDepth(resourceParams);
	const mappedData = useMemo(() => {
		if (!data) {
			return [];
		}
		// Group route depth entities by key and subscription
		const keySubscriptionMap = new Map<string, any[]>();
		for (const routeDepth of data) {
			const key = routeDepth.rateCenterId + '_' + routeDepth.rateKey;
			if (keySubscriptionMap.has(key)) {
				const routeDepthArray = keySubscriptionMap.get(key)!;
				routeDepthArray.push(routeDepth);
				keySubscriptionMap.set(key, routeDepthArray);
			} else {
				keySubscriptionMap.set(key, [routeDepth]);
			}
		}

		// Form the new table
		const rows: any[] = [];
		let newMaxDepth = 0;

		for (const entry of keySubscriptionMap.values()) {
			if (entry.length > newMaxDepth) {
				newMaxDepth = entry.length;
			}

			const row: any = {};
			const sortedEntry = sortBy(entry, [rateColumn]);
			for (let i = 0; i < sortedEntry.length; i++) {
				const rd = sortedEntry[i];
				row.rateCenterId = rd.rateCenterId;
				row.rateKey = rd.rateKey;
				row[`subscriptionId_${i}`] = rd.subscriptionId;
				row[`subscriptionRateKey_${i}`] = rd.subscriptionRateKey;
				row[`interRate_${i}`] = rd.interRate;
				row[`intraRate_${i}`] = rd.intraRate;
				row[`indetRate_${i}`] = rd.indetRate;
			}
			rows.push(row);
		}

		setMaxDepth(newMaxDepth);

		return rows;
	}, [data, rateColumn]);

	const columns = useMemo(
		() => getRouteDepthColumns(maxDepth, rateColumn),
		[maxDepth, rateColumn]
	);

	return (
		<>
			<ButtonGroup className="mb-3">
				<Button
					variant="outline-primary"
					onClick={() => setRateColumn(RATE_COLUMN.INTER_RATE)}
					active={rateColumn === RATE_COLUMN.INTER_RATE}
				>
					Interstate/Intl
				</Button>
				<Button
					variant="outline-primary"
					onClick={() => setRateColumn(RATE_COLUMN.INTRA_RATE)}
					active={rateColumn === RATE_COLUMN.INTRA_RATE}
				>
					Intrastate
				</Button>
				<Button
					variant="outline-primary"
					onClick={() => setRateColumn(RATE_COLUMN.INDET_RATE)}
					active={rateColumn === RATE_COLUMN.INDET_RATE}
				>
					Indeterminate
				</Button>
			</ButtonGroup>
			<DataGrid<RouteDepthIndexResponse>
				data={mappedData}
				loading={isLoading}
				pageName="rtSip_routeDepth"
				columns={columns}
			/>
		</>
	);
};
