/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateControlJobType {
    Rerate = 'rerate',
    Dispute = 'dispute',
    Nightly = 'nightly',
}