import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationIndexResponse,
	GuardianReconciliationProfileResponse
} from 'RtModels';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IGuardianReconciliationProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Charges: TabbedLayoutTabProps;
	Usage: TabbedLayoutTabProps;
	AddCharge: TabbedLayoutTabProps;
	AddUsage: TabbedLayoutTabProps;
	UploadCharges: TabbedLayoutTabProps;
	UploadAttachments: TabbedLayoutTabProps;
}

export const GuardianReconciliationProfileContainerTabs: IGuardianReconciliationProfileContainerTabs =
	{
		Profile: {
			header: 'Profile'
		},
		Charges: {
			header: 'Charges'
		},
		Usage: {
			header: 'Usage'
		},
		AddCharge: {
			header: 'Add Charge',
			isAction: true
		},
		AddUsage: {
			header: 'Add Usage',
			isAction: true
		},
		UploadCharges: {
			header: 'Bulk Upload Charges',
			isAction: true
		},
		UploadAttachments: {
			header: 'Upload Attachment',
			isAction: true
		}
	};

export class GuardianReconciliationsRouterClass extends RtVueModuleRouter<
	GuardianReconciliationIndexResponse,
	GuardianReconciliationProfileResponse,
	IGuardianReconciliationProfileContainerTabs
> {
	constructor() {
		super(
			'Reconciliation History',
			'guardianReconciliation',
			'guardianReconciliationId',
			GuardianReconciliationProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.GuardianReconciliations);
	}

	public async globalSearch(search: string) {
		return [];
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileRoute(
		record: GuardianReconciliationIndexResponse | number,
		tabId?: string,
		prefixSectionKey?: boolean
	): string {
		if (
			record instanceof Object &&
			tabId === GuardianReconciliationProfileContainerTabs.Usage.header &&
			record.reconciliationUsageDetailId
		) {
			return `/rtVue/reconciliationUsageDetails/${record.guardianReconciliationId}/${record.reconciliationUsageDetailId}?tab=Summary`;
		}

		return super.getProfileRoute(record, tabId, prefixSectionKey);
	}

	public getIndexLabel(record: GuardianReconciliationIndexResponse) {
		return record.summary ?? String(record.guardianReconciliationId);
	}

	public getProfileLabel(profile: GuardianReconciliationProfileResponse) {
		return profile.summary ?? String(profile.guardianReconciliationId);
	}
}

//IMPORTANT -- must add router to ApplicationRouters
export const GuardianReconciliationsRouter =
	new GuardianReconciliationsRouterClass();
