import { AccountIndexResponse, DataSources } from 'RtModels';
import {
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { MemoryCacheStrategy } from 'RtUi/utils/cache/strategies/MemoryCacheStrategy';

/**
 * @singleton
 */
export class RtVueAccountFilter extends RtVueFilter<AccountIndexResponse> {
	public static getInstance() {
		if (!RtVueAccountFilter.instance) {
			RtVueAccountFilter.instance = new RtVueAccountFilter();
		}

		return RtVueAccountFilter.instance;
	}

	private static instance: RtVueAccountFilter | undefined;
	private static readonly FilterName = 'RtVueAccountFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<AccountIndexResponse>
	> = new MemoryCacheStrategy();

	protected constructor() {
		super(
			RtVueAccountFilter.FilterName,
			DataSources.Accounts,
			RtVueAccountFilter.DataCache
		);
	}

	public getLabelFor(record: AccountIndexResponse): string {
		return record.label;
	}

	public getValueFor(record: AccountIndexResponse): string {
		return String(record.accountId);
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<AccountIndexResponse>
	> {
		return this.vueHttp.getAccountFilter();
	}

	protected getKeywordsFor(record: AccountIndexResponse): string[] {
		const keywords = this.getKeywordsFromPhrase(record.label);

		keywords.push(String(record.accountId));

		return keywords;
	}
}
