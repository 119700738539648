/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianRatingSimulatorProfileRequest = {
    properties: {
        fileStreamId: {
            type: 'number',
            isRequired: true,
        },
        startTime: {
            type: 'string',
        },
        duration: {
            type: 'number',
        },
        ingressTrunkgroupId: {
            type: 'string',
        },
        egressTrunkgroupId: {
            type: 'string',
        },
        originLrn: {
            type: 'string',
        },
        ani: {
            type: 'string',
        },
        dialedNumber: {
            type: 'string',
        },
        termLrn: {
            type: 'string',
        },
        callBlob: {
            type: 'string',
        },
        customerTestingOverride: {
            type: 'string',
        },
    },
};