import { TransactionTypes } from 'RtModels';
import {
	ISimpleSelectFormControlProps,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { Component } from 'react';

export const TRANSACTION_TYPES = [
	{
		label: 'Customer Invoice',
		value: TransactionTypes.TRANS_TYPE_CUST_INVOICE
	},
	{
		label: 'Dispute Credit',
		value: TransactionTypes.TRANS_TYPE_CUST_DISPUTE_CREDIT
	},
	{
		label: 'Customer Offset',
		value: TransactionTypes.TRANS_TYPE_CUST_OFFSET
	},
	{
		label: 'Customer Payment',
		value: TransactionTypes.TRANS_TYPE_CUST_PAYMENT
	},
	{
		label: 'Vendor Invoice',
		value: TransactionTypes.TRANS_TYPE_VEND_INVOICE
	},
	{
		label: 'Vendor Dispute Credit',
		value: TransactionTypes.TRANS_TYPE_VEND_DISPUTE_CREDIT
	},
	{
		label: 'Vendor Offset',
		value: TransactionTypes.TRANS_TYPE_VEND_OFFSET
	},
	{
		label: 'Vendor Payment',
		value: TransactionTypes.TRANS_TYPE_VEND_PAYMENT
	}
];

interface ITransactionTypeSelectProps<T, isMulti extends boolean = false>
	extends Omit<
		ISimpleSelectFormControlProps<T, isMulti>,
		'label' | 'options'
	> {}

export class TransactionTypeSelect<
	isMulti extends boolean = false
> extends Component<
	ITransactionTypeSelectProps<TransactionTypes, isMulti>,
	{}
> {
	public render() {
		return (
			<SimpleSelectFormControl<TransactionTypes, isMulti>
				{...this.props}
				label="Transaction Type"
				options={TRANSACTION_TYPES}
			/>
		);
	}
}
