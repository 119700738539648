import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class TfEnhancedDetailsReportRouterClass extends RtVueReportRouter<
	void,
	void,
	{}
> {
	constructor() {
		super(
			'Toll-Free Enhanced Details Report',
			'tfEnhancedDetails',
			undefined,
			{}
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewTollfreeEnhancedDetail);
	}

	/**
	 * By pass pluralization of name
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const TfEnhancedDetailsReportRouter =
	new TfEnhancedDetailsReportRouterClass();
