import { ActivePortCountryIndexResponse } from 'RtModels';
import { useGetActivePortsCountry } from 'RtUi/app/rtSip/ActivePortsCountry/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getActivePortsCountryColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/activePortsCountry';
import { useMemo } from 'react';

interface IActivePortsCountryGridProps {
	autoFocusFilter?: boolean;
}

export const ActivePortsCountryDataGrid =
	({}: IActivePortsCountryGridProps) => {
		const { data, isFetching: isLoading } = useGetActivePortsCountry();
		const columns = useMemo(() => getActivePortsCountryColumns(), []);

		return (
			<DataGrid<ActivePortCountryIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtSip_activePortsCountry'}
			/>
		);
	};
