import { TaskIndexRequest, TaskIndexResponse } from 'RtModels';
import { fetchTasks, useGetTasks } from 'RtUi/app/rt800/Tasks/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getTasksColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/tasks';
import { useMemo } from 'react';
import { TaskRouter } from '../../Task.router';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';

interface ITasksGridProps {
	resourceParams?: TaskIndexRequest;
	autoFocusFilter?: boolean;
}

export const TasksGrid = ({ resourceParams }: ITasksGridProps) => {
	const { data, isFetching: isLoading } = useGetTasks(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportTasks', resourceParams],
		resourceParams ?? {},
		(params) => fetchTasks(params)
	);
	const columns = useMemo(() => getTasksColumns(), []);

	return (
		<DataGrid<TaskIndexResponse>
			router={TaskRouter}
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			enableExternalExport
			getExternalExportData={fullResultsReq}
			pageName={'rt800_tasks'}
		/>
	);
};
