import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { useMount } from 'react-use';
import { CdrExportControlIndexResponse, CdrExportIndexRequest } from 'RtModels';
import { CdrRecurringExportEditor } from 'RtUi/app/Administration/CdrRecurringExport/lib/forms/CdrRecurringExportEditor';
import { CdrRecurringExportResource } from 'RtUi/app/Administration/CdrRecurringExport/lib/resources/CdrRecurringExportResource';
import { CdrExportGrid } from 'RtUi/app/rtSip/CdrExports/lib/grids/CdrExportGrid';
import { CdrExportDestinationsGrid } from 'RtUi/app/rtVue/CdrRecurringExport/lib/grids/CdrExportDestinationsGrid';
import { VueCdrRecurringExportRouter } from 'RtUi/app/rtVue/CdrRecurringExport/VueCdrRecurringExportRouter';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useReplaceWindowUrl } from 'RtUi/utils/hooks/useReplaceWindowUrl';

export const VueCdrRecurringExportProfileContainer = () => {
	const entityId = useIdParam();
	const [entity, setEntity] = useState<CdrExportControlIndexResponse>();
	const resourceParams: CdrExportIndexRequest = {};
	const [tabs, activeTab, setActiveTab] = useTabs(
		VueCdrRecurringExportRouter.getProfileTabs()
	);

	useReplaceWindowUrl(activeTab.header, resourceParams);

	useMount(async () => {
		const resource = new CdrRecurringExportResource();
		const newEntity = await resource.get(entityId);

		setEntity(newEntity);
	});

	if (!entity) {
		return <Loading />;
	}

	if (!isEmpty(entity)) {
		resourceParams.cdrExportControlId = entity.cdrExportControlId;
	}

	return (
		<TabbedLayout
			router={VueCdrRecurringExportRouter}
			profile={entity}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<CdrRecurringExportEditor
					editMode={entity}
					onUpdate={(newProfile) => {
						setEntity(newProfile);
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Exports}>
				<CdrExportGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.AutomatedTransfer}>
				<CdrExportDestinationsGrid
					cdrExportControlId={entity.cdrExportControlId}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

VueCdrRecurringExportRouter.setProfileRtUiFunctionalComponent(
	VueCdrRecurringExportProfileContainer
);
