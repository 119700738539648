import * as FileSaver from 'file-saver';
import * as React from 'react';
import { RocRequestResource } from 'RtUi/app/rt800/RespOrgChange/lib/resources/RocRequestResource';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiForm, RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { TollFreeNumbersTextArea } from '../../../Numbers/lib/controls/TollFreeNumbersTextArea';
import { Col, Row, Alert, Button } from 'react-bootstrap';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';

interface ICreateLetterOfAgencyProps {
	onSuccess?: () => void;
}

interface ICreateLetterOfAgencyState {
	isCreatingLOA: boolean;
	loaBlob?: Blob;
	tfns: string[];
	error?: any;
	rocRequestResource: RocRequestResource;
	company: {
		companyName: string;
		companyAddress1: string;
		companyAddress2: string;
		city: string;
		state: string;
		zipCode: string;
	};
	contact: {
		name: string;
		title: string;
		phone: string;
		extension: string;
		fax: string;
		email: string;
	};
	respOrgInstructions: string;
	endUserInformation: string;
}

export class CreateLetterOfAgency extends React.Component<
	ICreateLetterOfAgencyProps,
	ICreateLetterOfAgencyState
> {
	public state: ICreateLetterOfAgencyState = {
		isCreatingLOA: false,
		tfns: [],
		error: undefined,
		rocRequestResource: new RocRequestResource(),
		company: {
			companyName: '',
			companyAddress1: '',
			companyAddress2: '',
			city: '',
			state: '',
			zipCode: ''
		},
		contact: {
			name: '',
			title: '',
			phone: '',
			extension: '',
			fax: '',
			email: ''
		},
		respOrgInstructions: '',
		endUserInformation: ''
	};

	public fileUtils = new FileUtils();

	public downloadLOA(loaBlob: Blob) {
		FileSaver.saveAs(loaBlob, `letterOfAgency.pdf`);

		this.setState({ loaBlob });
	}

	public updateCompany<K extends keyof ICreateLetterOfAgencyState['company']>(
		key: K,
		value: ICreateLetterOfAgencyState['company'][K]
	) {
		const { company } = this.state;

		company[key] = value;

		this.setState({ company });
	}

	public updateContact<K extends keyof ICreateLetterOfAgencyState['contact']>(
		key: K,
		value: ICreateLetterOfAgencyState['contact'][K]
	) {
		const { contact } = this.state;

		contact[key] = value;

		this.setState({ contact });
	}

	public createLetterOfAgency(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		const { onSuccess = () => ({}) } = this.props;

		this.setState({
			isCreatingLOA: true,
			loaBlob: undefined,
			error: undefined
		});

		const {
			companyName,
			companyAddress1,
			companyAddress2,
			city,
			state,
			zipCode
		} = this.state.company;
		const { name, title, phone, extension, fax, email } = this.state.contact;
		const { tfns } = this.state;
		const respOrgInstructions = this.state.respOrgInstructions;
		const endUserInformation = this.state.endUserInformation;

		this.state.rocRequestResource
			.createLetterOfAgency(
				tfns,
				companyName,
				companyAddress1,
				companyAddress2,
				city,
				state,
				zipCode,
				name,
				title,
				phone,
				extension,
				fax,
				email,
				respOrgInstructions,
				endUserInformation
			)
			.then((res) =>
				this.fileUtils.getBlobFromBase64EncodedContent(
					res.encodedContent,
					res.mimeType
				)
			)
			.then((loaBlob) => this.downloadLOA(loaBlob))
			.then(() => onSuccess())
			.catch((error) => this.setState({ error }))
			.finally(() => this.setState({ isCreatingLOA: false }));
	}

	public render() {
		const { isCreatingLOA, loaBlob } = this.state;

		return (
			<RtUiWideForm onSubmit={(e) => this.createLetterOfAgency(e)} hideButtons>
				<legend>Contact Info</legend>

				<Row>
					<Col md>
						<InputFormControl
							label="Contact's Full Name"
							required
							onChange={(name) => this.updateContact('name', name)}
							value={this.state.contact.name}
						/>
					</Col>
					<Col md>
						<InputFormControl
							label="Title"
							required
							onChange={(title) => this.updateContact('title', title)}
							value={this.state.contact.title}
						/>
					</Col>
				</Row>

				<Row>
					<Col md>
						<InputFormControl
							label="Phone"
							required
							onChange={(phone) => this.updateContact('phone', phone)}
							value={this.state.contact.phone}
						/>
					</Col>
					<Col md>
						<InputFormControl
							label="Extension"
							onChange={(extension) =>
								this.updateContact('extension', extension)
							}
							value={this.state.contact.extension}
						/>
					</Col>
				</Row>

				<Row>
					<Col md>
						<InputFormControl
							type="email"
							label="Email"
							required
							onChange={(email) => this.updateContact('email', email)}
							value={this.state.contact.email}
						/>
					</Col>
					<Col md={6}>
						<InputFormControl
							label="Fax"
							onChange={(fax) => this.updateContact('fax', fax)}
							value={this.state.contact.fax}
						/>
					</Col>
				</Row>

				<legend>Company Info</legend>
				<InputFormControl
					label="Company Name"
					required
					onChange={(companyName) =>
						this.updateCompany('companyName', companyName)
					}
					value={this.state.company.companyName}
				/>

				<Row>
					<Col md>
						<InputFormControl
							label="Company Address Line 1"
							required
							onChange={(companyAddress1) =>
								this.updateCompany('companyAddress1', companyAddress1)
							}
							value={this.state.company.companyAddress1}
						/>
					</Col>
					<Col md>
						<InputFormControl
							label="Address Line 2"
							onChange={(companyAddress2) =>
								this.updateCompany('companyAddress2', companyAddress2)
							}
							value={this.state.company.companyAddress2}
						/>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<InputFormControl
							label="City"
							required
							onChange={(city) => this.updateCompany('city', city)}
							value={this.state.company.city}
						/>
					</Col>
					<Col md>
						<InputFormControl
							label="State"
							required
							onChange={(state) => this.updateCompany('state', state)}
							value={this.state.company.state}
						/>
					</Col>
					<Col md>
						<InputFormControl
							label="Zip Code"
							required
							onChange={(zipCode) => this.updateCompany('zipCode', zipCode)}
							value={this.state.company.zipCode}
						/>
					</Col>
				</Row>

				<TextAreaFormControl
					label="RespOrg Instructions"
					onChange={(respOrgInstructions) =>
						this.setState({ respOrgInstructions })
					}
					value={this.state.respOrgInstructions}
				/>

				<TextAreaFormControl
					label="End User Instructions"
					onChange={(endUserInformation) =>
						this.setState({ endUserInformation })
					}
					value={this.state.endUserInformation}
				/>

				<TollFreeNumbersTextArea
					required
					onChange={(tfns) => this.setState({ tfns })}
					value={this.state.tfns}
				/>

				{loaBlob && (
					<Alert
						variant="success"
						className="d-flex justify-content-start form-group"
					>
						<i className="fas fa-fw fa-check-circle" />
						<span className="ml-1">
							Your Letter of Agency has been created and saved!
						</span>
					</Alert>
				)}

				<RtUiForm.Footer>
					<Button variant="submit" type="submit" disabled={isCreatingLOA}>
						{isCreatingLOA ? (
							<>
								<i className="fas fa-fw fa-cog fa-spin" />
								<span>&nbsp;Creating...</span>
							</>
						) : (
							<>
								<i className="fas fa-fw fa-plus-circle" />
								<span>&nbsp;Create</span>
							</>
						)}
					</Button>
				</RtUiForm.Footer>

				<FormErrors error={this.state.error} />
			</RtUiWideForm>
		);
	}
}
