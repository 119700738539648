/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ResourceUserIndexResponse = {
    properties: {
        userId: {
            type: 'number',
            isRequired: true,
        },
        firstName: {
            type: 'string',
            isRequired: true,
        },
        lastName: {
            type: 'string',
            isRequired: true,
        },
        emailAddress: {
            type: 'string',
            isRequired: true,
        },
    },
};