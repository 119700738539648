import { RoutingRulesActionModesIndexResponse } from 'RtModels';
import { RoutingRulesActionModeResource } from 'RtUi/app/rtSip/RoutingRules/lib/resources/RoutingRulesActionModeResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface IRoutingRulesActionModeSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<
		RoutingRulesActionModesIndexResponse,
		IsMulti
	> {}

export class RoutingRulesActionModeSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	RoutingRulesActionModesIndexResponse,
	IsMulti,
	IRoutingRulesActionModeSelectProps<IsMulti>
> {
	public resourceClass = RoutingRulesActionModeResource;

	public state: ISelectFormControlState<RoutingRulesActionModesIndexResponse> =
		{
			formLabel: '',
			valueKey: 'routingRuleModeId',
			labelKey: 'label'
		};
}
