import {
	Card,
	Accordion,
	Button,
	useAccordionButton,
	AccordionContext
} from 'react-bootstrap';
import { ContentArticle } from '../ContentArticle';
import { FC, useContext } from 'react';
import clsx from 'clsx';

interface ICollapsibleCardProps {
	header: string | JSX.Element;
	actions?: () => React.ReactNode;
	className?: string;
	headerClassName?: string;
	headerTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	headerIcon?: string;
	closedByDefault?: boolean;
	useCardBody?: boolean;
}

function ContextAwareToggle({
	eventKey,
	callback
}: {
	eventKey: string;
	callback?: (eventKey: string) => void;
}) {
	const currentEventKey = useContext(AccordionContext);

	const decoratedOnClick = useAccordionButton(
		eventKey,
		() => callback && callback(eventKey)
	);

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<Button
			variant="white"
			className="d-none d-lg-inline-block"
			size="sm"
			style={{ cursor: 'pointer' }}
			onClick={decoratedOnClick}
		>
			<i
				className={`fas fa-fw fa-${
					isCurrentEventKey ? 'angle-up' : 'angle-down'
				}`}
			/>
		</Button>
	);
}

export const CollapsibleCard: FC<
	React.PropsWithChildren<ICollapsibleCardProps>
> = ({
	headerTag = '',
	headerClassName = '',
	headerIcon = '',
	header,
	className,
	actions,
	useCardBody = false,
	closedByDefault,
	children
}) => {
	return (
		<ContentArticle>
			<section className={className}>
				<Accordion defaultActiveKey={closedByDefault ? undefined : '0'}>
					<Accordion.Item eventKey="0" className="border-0">
						<Card>
							<Card.Header
								className={clsx(
									'collapsible-card-header',
									'input-group-height',
									'bg-white',
									headerClassName
								)}
							>
								<article
									className={clsx(
										headerTag,
										'd-flex justify-content-between align-items-center m-0'
									)}
								>
									<a className="text-body-size text-dark">
										{headerIcon && (
											<>
												<i className={headerIcon} />
												&nbsp;
											</>
										)}
										{header}
									</a>
									<article className="d-flex align-items-center">
										{actions && actions()}
										<ContextAwareToggle eventKey="0" />
									</article>
								</article>
							</Card.Header>
							<Accordion.Collapse eventKey="0">
								<Accordion.Body>
									{useCardBody ? (
										<Card.Body>{children}</Card.Body>
									) : (
										<>{children}</>
									)}
								</Accordion.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion.Item>
				</Accordion>
			</section>
		</ContentArticle>
	);
};
