import { RtAdminRouter } from '../rtAdmin.router';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { ServerGroupIndexResponse, ServerGroupProfileResponse } from 'RtModels';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IServerGroupRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

const ServerGroupContainerTabs: IServerGroupRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class ServerGroupRouterClass extends RtAdminRouter<
	ServerGroupIndexResponse,
	ServerGroupProfileResponse,
	IServerGroupRouterTabs
> {
	constructor() {
		super(
			'Server groups',
			'serverGroups',
			'serverGroupId',
			ServerGroupContainerTabs
		);

		this.setPermissionsFromApiRoute(RtAdmApiRoutes.ServerGroups);
	}

	public getProfileLabel(server: ServerGroupProfileResponse): string {
		return server.serverGroupId.toString();
	}
}

export const ServerGroupRouter = new ServerGroupRouterClass();
