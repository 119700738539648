import {
	RatePlanCreateRequest,
	RatePlanIndexResponse,
	RatePlanProfileResponse,
	RatePlanProfileUrlRequest,
	RatePlanUpdateRequest
} from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class RatePlanResource extends HttpResource<RatePlanIndexResponse> {
	constructor() {
		super(RtCommonApiRoutes.RatePlan);
	}

	public updateRatePlan(ratePlanId: number, entity: RatePlanUpdateRequest) {
		const urlParams: RatePlanProfileUrlRequest = {
			ratePlanId
		};
		const body = JSON.stringify(entity);

		return this.fetchWithRoute<RatePlanProfileResponse>(
			RtCommonApiRoutes.RatePlan.Update,
			{ body, urlParams }
		);
	}

	public createRatePlan(request: RatePlanCreateRequest) {
		const body = JSON.stringify(request);
		return this.fetchWithRoute<RatePlanProfileResponse>(
			RtCommonApiRoutes.RatePlan.Create,
			{ body }
		);
	}
}
