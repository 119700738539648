//import * as React from 'react';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import {
	CdrExportRetentionResource,
	ICdrExportRetentionIndex
} from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportRetentionResource';

interface ICdrExportRetentionSelectProps
	extends ISelectFormControlProps<ICdrExportRetentionIndex> {}

export class CdrExportRetentionSelect extends SelectFormControl<
	ICdrExportRetentionIndex,
	false,
	ICdrExportRetentionSelectProps
> {
	public resourceClass = CdrExportRetentionResource;
	public state: ISelectFormControlState<ICdrExportRetentionIndex> = {
		formLabel: 'Export Retention',
		valueKey: 'retentionId',
		labelKey: 'retentionName'
	};
}
