/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MetricsIndexSummaryResponse = {
    properties: {
        source: {
            type: 'string',
            isRequired: true,
        },
        integrationId: {
            type: 'number',
            isRequired: true,
        },
        inVendor: {
            type: 'number',
            isRequired: true,
        },
        inBilling: {
            type: 'number',
        },
        inSwitch: {
            type: 'number',
        },
        inBoth: {
            type: 'number',
        },
        isUnique: {
            type: 'number',
        },
    },
};