import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { EntityDataGridCache } from 'RtUi/components/data/DataGrid/columns/EntityDataGridColumn';
import { LcCarrierDataGridCache } from 'RtUi/components/data/DataGrid/columns/LcrCarriersDataGridColumn';
import { RespOrgDataGridCache } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { GenericTypeRender } from 'RtUi/components/ui/GenericTypeRenderer';

interface PeeringValueDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	peeringType: (row: T) => number;
}

export const PeeringValueDataGridColumn = <T = any,>({
	peeringType,
	...config
}: PeeringValueDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: any, data: T) => {
		const type = peeringType(data);

		switch (type) {
			case 1: {
				return (
					<GenericTypeRender
						id={value}
						showIdInLabel={false}
						resourceCacheMap={EntityDataGridCache}
					/>
				);
			}
			case 2: {
				return (
					<div className="d-flex gap-1">
						<span>{value} -</span>
						<GenericTypeRender
							id={value}
							showIdInLabel={false}
							resourceCacheMap={RespOrgDataGridCache}
						/>
					</div>
				);
			}
			case 3: {
				return (
					<GenericTypeRender
						id={value}
						showIdInLabel={false}
						resourceCacheMap={LcCarrierDataGridCache}
					/>
				);
			}
			default: {
				return value;
			}
		}
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: any, data) => getValue(value, data as T),
		getValue: ({ cell, row }) =>
			getValue(cell.getValue<any>(), row.original as T)
	});
};
