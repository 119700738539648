/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TemplateIndexRequest = {
    properties: {
        entityId: {
            type: 'string',
        },
        respOrgId: {
            type: 'string',
        },
        templateName: {
            type: 'string',
        },
        isActive: {
            type: 'number',
        },
        isReserveTemplate: {
            type: 'number',
        },
        isRoutingTemplate: {
            type: 'number',
        },
        isSourceTemplate: {
            type: 'number',
        },
        isTargetTemplate: {
            type: 'number',
        },
        isInUse: {
            type: 'number',
        },
        aosValues: {
            type: 'Array',
        },
        carriers: {
            type: 'Array',
        },
        search: {
            type: 'string',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};