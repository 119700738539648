import { useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
	SipAgentAddressIndexResponse,
	SipAgentAddressProfileResponse
} from 'RtModels';
import { useGetSipAgentAddresses } from 'RtUi/app/AccountManagement/SipAgents/lib/services';
import { SipAgentAddressEditor } from 'RtUi/app/AccountManagement/Switches/lib/forms/SipAgentAddressEditor';
import { SipAgentAddressesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SipAgentAddressesResource';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSipAgentAddressesColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/switches';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';

export interface ISipAgentAddressesGridProps {
	switchId: number;
	autoFocusFilter?: boolean;
}

export const SipAgentAddressesGrid = ({
	switchId
}: ISipAgentAddressesGridProps): JSX.Element => {
	const resource = useMemo(() => new SipAgentAddressesResource(), []);
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetSipAgentAddresses({ switchId });

	const [editingSipAgentAddress, setEditingSipAgentAddress] =
		useState<SipAgentAddressProfileResponse>();

	const [showAddSipAgentAddressAside, setShowAddSipAgentAddressAside] =
		useState<boolean>(false);

	const onClickRowHandler = (row: SipAgentAddressIndexResponse) => {
		setEditingSipAgentAddress(row);
		setShowAddSipAgentAddressAside(true);
	};

	const onSuccessHandler = () => {
		refetch();
		setEditingSipAgentAddress(undefined);
		setShowAddSipAgentAddressAside(false);
	};

	const onCancelHandler = () => {
		setEditingSipAgentAddress(undefined);
		setShowAddSipAgentAddressAside(false);
	};

	const onDeleteHandler = useCallback(
		async (row: SipAgentAddressIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				row.sipAgentAddressId.toString()
			);

			if (!confirm) {
				return;
			}

			await resource.delete(row.sipAgentAddressId);
			refetch();
		},
		[resource, refetch]
	);

	const columns = useMemo(
		() => getSipAgentAddressesColumns(onDeleteHandler),
		[onDeleteHandler]
	);

	return (
		<>
			{showAddSipAgentAddressAside && (
				<Aside isOpen={showAddSipAgentAddressAside} disabledBody>
					<Aside.Header header="Add Switch Address" onClose={onCancelHandler} />
					<Aside.Body>
						<SipAgentAddressEditor
							value={editingSipAgentAddress}
							switchId={switchId}
							onSuccess={onSuccessHandler}
							onCancel={onCancelHandler}
						/>
					</Aside.Body>
				</Aside>
			)}
			<DataGrid<SipAgentAddressIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'accountManagement_sipAgentAddresses'}
				onRowClick={onClickRowHandler}
				disableExport
				headerAction={() => (
					<OverlayTrigger
						overlay={(props) => (
							<Tooltip id={`sip-agent-tooltip`} {...props}>
								Add SIP Agent
							</Tooltip>
						)}
					>
						{({ ref, ...triggerHandler }) => (
							<Button
								ref={ref}
								{...triggerHandler}
								onClick={() => {
									setShowAddSipAgentAddressAside(true);
								}}
								variant="white"
								id="sip-agent"
							>
								<i className="fas fa-fw fa-plus fa-lg" />
							</Button>
						)}
					</OverlayTrigger>
				)}
			/>
		</>
	);
};
