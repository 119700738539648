import {
	toast,
	ToastContainer,
	TypeOptions,
	ToastOptions,
	ToastContent
} from 'react-toastify';
import { UiNotification } from 'RtUi/notifications/lib/UiNotification';
import {
	ApplicationContainer,
	IContainerAppState
} from 'RtUi/components/containers/ApplicationContainer';

export interface INotificationEntity {
	notificationId: number | string;
	effectiveTs: Date;
	expirationTs: Date;
	notificationTypeId: number;
	isUrgent: number;
	title?: string;
	message?: string;
}

export enum ApplicationNotificationTypes {
	Success = 0,
	Info = 1,
	Warning = 2,
	Danger = 3
}

export interface INotification extends INotificationEntity {
	notificationTypeId: ApplicationNotificationTypes;
}

interface INotificationComponentState {}

export class ApplicationNotifications extends ApplicationContainer<
	{},
	INotificationComponentState
> {
	public static ComponentIsMounted = false;

	public static NotificationTypeToToastType: {
		[property in ApplicationNotificationTypes]: TypeOptions;
	} = {
		[ApplicationNotificationTypes.Danger]: 'error',
		[ApplicationNotificationTypes.Success]: 'success',
		[ApplicationNotificationTypes.Info]: 'info',
		[ApplicationNotificationTypes.Warning]: 'warning'
	};

	public static CloseAll() {
		toast.dismiss();
	}

	public static ShowNotification(
		noty: ToastContent,
		notification: INotification,
		options: ToastOptions
	) {
		if (!ApplicationNotifications.ComponentIsMounted) {
			return;
		}

		toast(noty, {
			...{
				hideProgressBar: true,
				toastId: notification.notificationId,
				draggable: false,
				className: `rtNotification rtNotification--${
					ApplicationNotificationTypes[notification.notificationTypeId]
				}`,
				type: ApplicationNotifications.NotificationTypeToToastType[
					notification.notificationTypeId
				]
			},
			...options
		});
	}

	public static openUiNotification(
		uiNotification: UiNotification,
		autoClose?: number
	) {
		const title = uiNotification.getTitle();
		const [settings, state] = uiNotification.getCurrentSettingsAndState();

		if (typeof state.issuesCount !== 'number' || state.issuesCount <= 0) {
			return;
		}

		const message = `${state.issuesCount} ${state.issuesWord} found`;
		const notificationTypeId = settings.isWarning
			? ApplicationNotificationTypes.Warning
			: ApplicationNotificationTypes.Danger;
		//NotificationId based on title -> charCodeArray -> aggregate number
		const notificationId = Number(
			title
				.split('')
				.map((char) => char.charCodeAt(0).toString(10))
				.join('')
		);

		const notification: INotification = {
			notificationId,
			message,
			title,
			notificationTypeId,
			effectiveTs: new Date(),
			expirationTs: new Date(),
			isUrgent: 0
		};

		ApplicationNotifications.openNotification(notification, { autoClose });
	}

	public static openNotification(
		notification: INotification,
		options: ToastOptions = {}
	) {
		let iconClassName = 'rtNotification__heading__icon fas fa-fw fa-sm ';

		switch (notification.notificationTypeId) {
			case ApplicationNotificationTypes.Success:
				iconClassName += 'fa-check';
				break;
			case ApplicationNotificationTypes.Info:
				iconClassName += 'fa-info-circle';
				break;
			case ApplicationNotificationTypes.Warning:
				iconClassName += 'fa-bell';
				break;
			case ApplicationNotificationTypes.Danger:
				iconClassName += 'fa-exclamation';
				break;
			default:
				iconClassName += 'fa-check';
				break;
		}

		const NotyBody = (
			<>
				{notification.title && (
					<h6 className="rtNotification__heading">
						<i className={iconClassName} />
						<b>{notification.title}</b>
					</h6>
				)}
				{notification.message && (
					<div className="mb-0 rtNotification__text">
						{notification.message}
					</div>
				)}
				{!options.hideProgressBar && <div className="noty_progressbar" />}
			</>
		);

		ApplicationNotifications.ShowNotification(NotyBody, notification, options);
	}

	public rtxJwt: string | undefined;
	public state: INotificationComponentState = {};

	public onAppStateChange(appState: IContainerAppState) {
		this.rtxJwt = this.Actions.User.getJwt();
	}

	public componentDidMount() {
		ApplicationNotifications.ComponentIsMounted = true;
	}

	public componentWillUnmount() {
		ApplicationNotifications.ComponentIsMounted = false;

		ApplicationNotifications.CloseAll();
	}

	public render() {
		return <ToastContainer icon={false} />;
	}
}
