/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ServiceNumberTagProfileResponse = {
    properties: {
        serviceNumberTagId: {
            type: 'number',
            isRequired: true,
        },
        serviceNumber: {
            type: 'string',
            isRequired: true,
        },
        tag: {
            type: 'string',
            isRequired: true,
        },
        values: {
            type: 'Array',
            isRequired: true,
        },
    },
};