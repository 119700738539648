import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { RtCprDto, RoutingCacheProfileRequest } from 'RtModels';

export class RoutingCacheHttp {
	public getProfile(params: RoutingCacheProfileRequest) {
		return HttpRequest.fetchWithRoute<RtCprDto>(
			Rt800ApiRoutes.RoutingCache.Profile,
			{ params }
		);
	}
}
