/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExportIndexRequest = {
    properties: {
        cdrExportControlId: {
            type: 'number',
        },
        cdrExportTypeId: {
            type: 'CdrExportTypes',
        },
        cdrExportIntervalId: {
            type: 'CdrExportIntervals',
        },
        cdrSourceTypeId: {
            type: 'CdrExportSourceTypes',
        },
        subscriptionIsIn: {
            type: 'Array',
        },
        cdrSourceValue: {
            type: 'number',
        },
        accountIsIn: {
            type: 'Array',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        startQueryDate: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        begTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
    },
};