import {
	TransactionIndexResponse,
	Transaction,
	TransactionCreateRequest,
	TransactionReversalCreateRequest
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class TransactionResource extends HttpResource<
	TransactionIndexResponse,
	Transaction
> {
	constructor() {
		super(RtxSipApiRoutes.Transactions);
	}

	public create(transaction: TransactionCreateRequest) {
		const body = JSON.stringify(transaction);
		return this.fetchWithRoute<Transaction>(
			RtxSipApiRoutes.Transactions.Create,
			{ body }
		);
	}

	public reverse(
		transactionId: number,
		transactionData: TransactionReversalCreateRequest
	) {
		const body = JSON.stringify(transactionData);
		const urlParams = { transactionId };
		return this.fetchWithRoute<Transaction>(
			RtxSipApiRoutes.TransactionReversal.Create,
			{ body, urlParams }
		);
	}
}
