import { EmailAliasIndexResponse } from 'RtModels';
import { DistributionManagementRouter } from 'RtUi/app/Administration/DistributionManagement/DistributionManagement.router';
import { useGetEmailAliases } from 'RtUi/app/Administration/DistributionManagement/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getEmailAliasesColumns } from 'RtUi/components/data/DataGrid/configurations/administration/emailAliases';
import { useMemo } from 'react';

interface IEmailAliasGridProps {
	onRowClick?: (row: EmailAliasIndexResponse) => void;
	//resourceParams?: any;
	autoFocusFilter?: boolean;
}

export const EmailAliasGrid = ({}: IEmailAliasGridProps) => {
	const { data, isLoading } = useGetEmailAliases();

	const columns = useMemo(() => getEmailAliasesColumns(), []);

	return (
		<DataGrid<EmailAliasIndexResponse>
			router={DistributionManagementRouter}
			data={data?.data}
			columns={columns}
			pageName={'administration-emailAliases'}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
