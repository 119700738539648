import * as React from 'react';
import { TransactionResource } from 'RtUi/app/rtSip/Transactions/lib/resources/TransactionResource';
import {
	Transaction,
	AccountIndexResponse,
	TransactionIndexResponse
} from 'RtModels';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { TRANSACTION_TYPES } from 'RtUi/app/rtSip/Transactions/lib/controls/TransactionTypeSelect';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { Aside } from 'RtUi/components/ui/Aside';
import { Modal } from 'react-bootstrap';
import { Loading } from 'RtUi/components/ui/Loading';
import { cloneDeep, find } from 'lodash-es';

interface ITransactionReverseProps {
	show: boolean;
	toggle: () => void;
	profile: TransactionIndexResponse;
	onReverseSuccess?: (transaction: Transaction) => void;
}

interface ITransactionReverseState extends Transaction {
	isSubmitting: boolean;
	error?: any;
	transactionType?: ISimpleSelectFormControlOption<number>;
	account?: AccountIndexResponse;
	comments: string;
	amount: number;
	externalRef1: string;
	externalRef2: string;
}

export class TransactionReverseAside extends React.Component<
	ITransactionReverseProps,
	ITransactionReverseState
> {
	constructor(props: ITransactionReverseProps) {
		super(props);

		let transaction: TransactionIndexResponse = {
			accountId: 0,
			// @ts-ignore
			transactionType: undefined,
			amount: 0,
			externalRef1: '',
			externalRef2: '',
			comments: ''
		};
		if (props.profile) {
			transaction = cloneDeep(props.profile);
		}

		const preparedState: any = {
			displayMode: Boolean(props.profile),
			isSubmitting: false,
			account: undefined,
			transactionType: find(TRANSACTION_TYPES, {
				value: transaction.transactionTypeId
			}),
			amount: transaction.amount,
			transactionTime: transaction.transactionTime,
			externalRef1: transaction.externalRef1,
			externalRef2: transaction.externalRef2,
			comments: transaction.comments
		};

		this.state = cloneDeep(preparedState);
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const { externalRef1, externalRef2, comments } = this.state;
			const transactionResource = new TransactionResource();
			const { profile } = this.props;
			const { onReverseSuccess = () => ({}) } = this.props;
			const newProfile = await transactionResource.reverse(
				profile.transactionId,
				{
					externalRef1,
					externalRef2,
					comments
				}
			);

			onReverseSuccess(newProfile);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public render() {
		return (
			<Aside isOpen={this.props.show} disabledBody>
				<Aside.Header
					header={this.props.profile.transactionId}
					onClose={() => this.props.toggle()}
				/>
				{this.state.isSubmitting && (
					<div className="mx-auto p-5">
						<Loading internal message="Loading data" />
					</div>
				)}
				<Modal.Body>
					<RtUiForm
						onSubmit={(evt) => this.onSubmit(evt)}
						isSubmitting={this.state.isSubmitting}
						error={this.state.error}
						submitButtonText="Reverse"
						onCancel={() => this.props.toggle()}
					>
						<AccountSelect<false>
							label="Account"
							value={this.state.account}
							onChange={(account) => this.setState({ account })}
							initialOptionId={String(this.props.profile?.accountId)}
							disabled
							displayMode={true}
						/>
						<InputFormControl
							label="Amount"
							value={String(this.state.amount)}
							type="number"
							displayMode={true}
							disabled
						/>
						<InputFormControl
							label="Reference 1"
							onChange={(externalRef1) => this.setState({ externalRef1 })}
							value={this.state.externalRef1}
							displayMode={this.state.isSubmitting}
						/>
						<InputFormControl
							label="Reference 2"
							onChange={(externalRef2) => this.setState({ externalRef2 })}
							value={this.state.externalRef2}
							displayMode={this.state.isSubmitting}
						/>
						<InputFormControl
							label="Comments"
							onChange={(comments) => this.setState({ comments })}
							value={this.state.comments}
							displayMode={this.state.isSubmitting}
						/>
					</RtUiForm>
				</Modal.Body>
			</Aside>
		);
	}
}
