import * as React from 'react';
import { Button } from 'react-bootstrap';
import {
	ManagedEntityProfileResponse,
	ManagedRespOrgProfileResponse,
	RespOrgIndexResponse
} from 'RtModels';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { IManagedResourceClass } from 'RtUi/app/rtAdmin/lib/resources/ManagedResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';

type TManagedMgiProfile =
	| ManagedRespOrgProfileResponse
	| ManagedEntityProfileResponse;

interface IManagedEntityUpdateMgiProps<ProfileType extends TManagedMgiProfile> {
	id: string;
	profile: TManagedMgiProfile;
	resourceClass: IManagedResourceClass<any, ProfileType>;
	onUpdate: (profile: ProfileType) => void;
}

interface IManagedEntityUpdateMgiState {
	respOrg?: RespOrgIndexResponse;
	mgiUserName: string;
	isSubmitting: boolean;
	displayMode: boolean;
	error?: any;
}

export class ManagedEntityUpdateMgi<
	ProfileType extends TManagedMgiProfile
> extends React.Component<
	IManagedEntityUpdateMgiProps<ProfileType>,
	IManagedEntityUpdateMgiState
> {
	public state: IManagedEntityUpdateMgiState = {
		mgiUserName: '',
		isSubmitting: false,
		displayMode: true,
		error: undefined
	};

	public componentDidMount() {
		this.init();
	}

	public init(reset = false) {
		const { mgiUserName = '' } = this.props.profile;

		this.setState({ mgiUserName });

		if (reset) {
			this.setState({
				isSubmitting: false,
				displayMode: true,
				error: undefined
			});
		}
	}

	public submit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const { id, resourceClass } = this.props;
		const { mgiUserName, respOrg } = this.state;

		const managedResource = new resourceClass();

		this.setState({ isSubmitting: true, error: undefined });

		const mgiRespOrgId = respOrg?.respOrgId ?? '';

		managedResource
			.updateMgi(id, mgiUserName, mgiRespOrgId)
			.then((profile) => {
				this.props.onUpdate(profile);

				this.setState({ displayMode: true, mgiUserName });
			})
			.catch((error) => {
				this.setState({ error });
			})
			.finally(() => {
				this.setState({ isSubmitting: false });
			});
	}

	public async delete() {
		const { id, resourceClass } = this.props;

		const managedResource = new resourceClass();

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const profile = await managedResource.deleteApi(id);

			this.setState(
				{ displayMode: true, mgiUserName: '', isSubmitting: false },
				() => this.props.onUpdate(profile)
			);
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	}

	public render() {
		return (
			<RtUiNarrowForm
				header="MGI Credentials"
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onCancel={() => this.init(true)}
				onChange={(displayMode) => this.setState({ displayMode })}
				editModeActions={() => (
					<Button
						type="button"
						variant="outline-danger"
						disabled={this.state.isSubmitting}
						onClick={() => this.delete()}
					>
						Delete Credentials
					</Button>
				)}
				onSubmit={(evt) => this.submit(evt)}
			>
				<InputFormControl
					label="MGI Username"
					required
					displayMode={this.state.displayMode}
					onChange={(mgiUserName) => this.setState({ mgiUserName })}
					value={this.state.mgiUserName}
				/>
				{'mgiRespOrgId' in this.props.profile && (
					<RespOrgSelect
						label="MGI RespOrg"
						required
						displayMode={this.state.displayMode}
						onChange={(respOrg) => this.setState({ respOrg })}
						value={this.state.respOrg}
						initialOptionId={this.props.profile.mgiRespOrgId}
					/>
				)}
			</RtUiNarrowForm>
		);
	}
}
