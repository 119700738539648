/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum AlertType {
    Asr = 1,
    AsrAttempts = 2,
    AsrPdd = 3,
    AsrRoboscore = 4,
    FinancialCost = 5,
    FinancialMargin = 6,
    FinancialRevenue = 7,
    FinancialNegativeMargin = 8,
    FinancialNegativeCallCount = 9,
    NumbersAsr = 10,
    NumbersAttempts = 11,
    NumbersAttemptsGranularDetail = 12,
    NumbersCost = 13,
    NumbersLoopingCalls = 14,
    NumbersMargin = 15,
    NumbersNegativeCallCount = 16,
    NumbersNegativeMargin = 17,
    NumbersRevenue = 18,
    NumbersRoboscore = 19,
    NumbersCometMediaNumberRule = 20,
    CpsMin = 21,
    CpsMax = 22,
    CpsAvg = 23,
    DbLoad = 24,
    FileCount = 25,
    FileCreation = 26,
    AsrSub6 = 27,
    FraudDomestic = 28,
    FraudInternational = 29,
    CdrCount = 30,
    FraudScore = 31,
    AnomalyAlert = 32,
    RoboAlert = 33,
    DnoAlert = 34,
}