import { NaHistory, TollFreeIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DelimiterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DelimiterDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { TollFreeNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeNumberDataGridColumn';
import { TollFreeStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeStatusDataGridColumn';

export const getNumbersColumns = (): Array<
	DataGridColumn<TollFreeIndexResponse>
> => [
	TollFreeNumberDataGridColumn({
		accessorKey: 'tfn',
		header: 'Toll Free Number'
	}),
	...RespOrgColumnsBuilder<TollFreeIndexResponse>({
		accessorKey: 'respOrgId',
		header: 'RespOrg'
	}),
	TollFreeStatusDataGridColumn({
		accessorKey: 'tfnStatusId',
		header: 'Status'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'statusTs',
		header: 'Status Time'
	}),
	DefaultDataGridColumn({
		accessorKey: 'templateName',
		header: 'Template'
	}),
	DelimiterDataGridColumn({
		accessorKey: 'interLataCarriers',
		header: 'Carriers',
		delimiter: ','
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastUpdateTs',
		header: 'Last Update'
	})
];

export const getNumberHistoryColumns = (
	quickView: boolean
): Array<DataGridColumn<NaHistory>> => {
	const statusColumn = TollFreeStatusDataGridColumn<NaHistory>({
		accessorKey: 'tfnStatusId',
		header: 'Status'
	});
	const lastUpdatedColumn = TimeStampDataGridColumn<NaHistory>({
		accessorKey: 'lastUpdateTs',
		header: 'Last Updated'
	});

	const columns: Array<DataGridColumn<NaHistory>> = [
		...RespOrgColumnsBuilder<NaHistory>({
			accessorKey: 'respOrgId',
			header: 'RespOrg'
		})
	];

	if (quickView) {
		return [...columns, statusColumn, lastUpdatedColumn];
	}

	return [
		...columns,
		statusColumn,
		TimeStampDataGridColumn({
			accessorKey: 'statusTs',
			header: 'statusTs'
		}),
		lastUpdatedColumn,
		DefaultDataGridColumn({
			accessorKey: 'logonId',
			header: 'Logon Id'
		})
	];
};
