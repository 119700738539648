import { DataFilterOperator } from 'RtModels';

export const RtVueOperatorColors: { [key: string]: string } = {
	'in': 'rgba(173, 216, 230, 0.78)',
	'not in': 'rgba(240, 128, 128, 0.5)'
};

export function GetRtVueOperatorColor(operator: DataFilterOperator) {
	const color = RtVueOperatorColors[operator];

	if (!color) {
		return RtVueOperatorColors.in;
	}

	return color;
}
