import { PureComponent } from 'react';
import { Button } from 'react-bootstrap';
import {
	IRtUiFormProps,
	RtUiForm,
	RtUiWideForm,
	RtUiNarrowForm
} from 'RtUi/components/ui/RtUiForm';

interface IRtUiSearchForm extends IRtUiFormProps {
	wide?: boolean;
	fullWidth?: boolean;
}

// this should be deprecated

export class RtUiSearchForm extends PureComponent<
	React.PropsWithChildren<IRtUiSearchForm>
> {
	public static defaultProps = {
		wide: false,
		hideButtons: true
	};

	public render() {
		if (this.props.fullWidth) {
			return <RtUiForm {...this.props}>{this.renderBody()}</RtUiForm>;
		}
		if (this.props.wide) {
			return <RtUiWideForm {...this.props}>{this.renderBody()}</RtUiWideForm>;
		}

		return <RtUiNarrowForm {...this.props}>{this.renderBody()}</RtUiNarrowForm>;
	}

	private renderBody() {
		return (
			<>
				{this.props.children}
				<RtUiForm.Footer>
					<Button variant="submit" type="submit">
						<i className="fas fa-fw fa-search fa-sm" />
						<span>&nbsp;Search</span>
					</Button>
				</RtUiForm.Footer>
			</>
		);
	}
}
