import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

// exported definitions
// ======================================================================

export class CprColAreaCode extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.AreaCode;

	public readonly allowOther = true;
	public readonly valueLimit = 3;
	public readonly valueRegExp = /^\d{3}$/;

	public readonly isLergNode: boolean = true;
	public readonly isTreeNode = true;

	protected readonly possibles = CprLergCache.Npas;
	protected readonly possiblesByKey = CprLergCache.CprLergByNpa;

	protected readonly canadaPossibles = CprLergCache.CanadaNpas;
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaNpasByKey;

	protected readonly canLabel = true;

}
