/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionQosProfileResponse = {
    properties: {
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        rateCenterId: {
            type: 'number',
            isRequired: true,
        },
        marginPlanId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        blockTypeId: {
            type: 'number',
            isRequired: true,
        },
        isRoutable: {
            type: 'number',
            isRequired: true,
        },
        isLcrEnabled: {
            type: 'number',
            isRequired: true,
        },
        isRouteDepth: {
            type: 'number',
            isRequired: true,
        },
        sq1: {
            type: 'number',
            isRequired: true,
        },
        sq2: {
            type: 'number',
            isRequired: true,
        },
        sq3: {
            type: 'number',
            isRequired: true,
        },
        sq4: {
            type: 'number',
            isRequired: true,
        },
        sq5: {
            type: 'number',
            isRequired: true,
        },
        sq6: {
            type: 'number',
            isRequired: true,
        },
        sq7: {
            type: 'number',
            isRequired: true,
        },
        sq8: {
            type: 'number',
            isRequired: true,
        },
        sq9: {
            type: 'number',
            isRequired: true,
        },
        cli: {
            type: 'number',
            isRequired: true,
        },
        test: {
            type: 'number',
            isRequired: true,
        },
        t38: {
            type: 'number',
            isRequired: true,
        },
    },
};