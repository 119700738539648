import {
	MetricsTotalsByDateResponse,
	MetricsTotalsByResourceResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { ResourceConnectionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ResourceConnectionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const getMetricsByDayColumns = (): Array<
	DataGridColumn<MetricsTotalsByDateResponse>
> => [
	TimeStampDataGridColumn({
		accessorKey: 'date',
		header: 'Date'
	}),
	IntegerDataGridColumn({
		accessorKey: 'activationQty',
		header: 'Activations',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'cancellationQty',
		header: 'Cancellations',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalActiveQty',
		header: 'Total Active Qty',
		showFooter: true
	})
];

export const getMetricsByResourceColumns = (): Array<
	DataGridColumn<MetricsTotalsByResourceResponse>
> => [
	ResourceConnectionDataGridColumn({
		accessorKey: 'resourceId',
		header: 'Resource'
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalActiveQty',
		header: 'Total Active Qty'
	})
];
