import { ServiceNumberLookUpType } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { isNil } from 'lodash-es';
import { Alert } from 'react-bootstrap';

interface ServiceNumberLookupDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const ServiceNumberLookupDataGridColumn = <T = any,>({
	...config
}: ServiceNumberLookupDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: number) => {
		const styles: React.CSSProperties = {
			width: config.size
		};

		if (config.align === DataGridColumnAlignment.CENTER) {
			styles.textAlign = 'center';
		}

		if (isNil(value)) {
			return <span></span>;
		}

		const isAni = value === ServiceNumberLookUpType.Ani;
		const word = isAni ? 'Ani' : 'Dialed Number';
		const color = isAni ? 'info' : 'primary';

		return (
			<section className="d-flex justify-content-center">
				<Alert
					transition={false}
					style={styles}
					variant={color}
					className="alert-sm mb-0 p-1"
				>
					<span>{word}</span>
				</Alert>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		getValue: ({ cell }) => getValue(cell.getValue<number>())
	});
};
