import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	JobIndexResponse,
	JobProfileResponse,
	JobUpdateRequest,
	JobUpdateResponse
} from 'RtModels';
import { RtxFlowApiRoutes } from 'RtExports/routes';

export class JobResource extends HttpResource<
	JobIndexResponse,
	JobProfileResponse
> {
	constructor() {
		super(RtxFlowApiRoutes.Jobs);
	}

	public update(request: JobUpdateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<JobUpdateResponse>(
			RtxFlowApiRoutes.Jobs.Update,
			{ body }
		);
	}
}
