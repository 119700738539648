import { CprStatus, TollFreeIndexResponse } from 'RtModels';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
import moment from 'moment';
import { useContext } from 'react';

interface IUseGetCprActionsParams {
	cpr: Cpr;
	tfns: TollFreeIndexResponse[];
}

export const useGetCprActions = ({ cpr, tfns }: IUseGetCprActionsParams) => {
	const { cprs, isNumber, isNumberAndHasTemplateAssigned } =
		useContext(CprProfileContext);
	let canCreate = false;
	let canCopy = false;
	let canDelete = false;
	let canEdit = false;
	let canTransfer = false;
	let errorMessage: string | undefined;
	const now = moment();
	const effectiveTs = cpr.getSourceEffectiveTs() ?? now;
	const isFuture = moment(effectiveTs).isAfter(now);
	const hasFailedRouting = cprs.some(
		(route) => route.cprStatusId === CprStatus.Failed
	);
	const hasPendingRouting = cprs.some(
		(route) => route.cprStatusId === CprStatus.Pending
	);
	const isTemplate = !isNumber;
	let canDisconnect = isTemplate && !hasFailedRouting && !hasPendingRouting;

	if (isTemplate && canDisconnect) {
		canDisconnect = tfns.length <= 0;
	}

	switch (cpr.getCprStatus()) {
		case CprStatus.Pending:
			canCopy = true;
			canDelete = isFuture;
			canEdit = isFuture;
			canTransfer = true;
			break;
		case CprStatus.Sending:
			errorMessage = 'Template is in Sending status. Editing is disabled.';
			canCopy = true;
			break;
		case CprStatus.Active:
			canCopy = true;
			break;
		case CprStatus.Old:
			canCopy = true;
			break;
		case CprStatus.Invalid:
			canDelete = isFuture;
			canEdit = isFuture;
			canTransfer = true;
			break;
		case CprStatus.MustCheck:
			canDelete = isFuture;
			canEdit = isFuture;
			canTransfer = true;
			break;
		case CprStatus.Failed:
			canDelete = isFuture;
			canTransfer = true;
			break;
		case CprStatus.Saved:
			canEdit = true;
			break;
		case CprStatus.Disconnect:
			canCopy = !isNumberAndHasTemplateAssigned;
			break;
		default:
			canCreate = true;
	}

	return {
		canCreate,
		canCopy,
		canDelete,
		canEdit,
		canTransfer,
		errorMessage,
		canDisconnect
	};
};
