//import * as React from 'react';
import { BillingEntityIndexResponse } from 'RtModels';
import { BillingEntityResource } from 'RtUi/app/AccountManagement/Accounts/lib/resources/BillingEntityResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface IBillingEntitySelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<BillingEntityIndexResponse, IsMulti> {}
interface IBillingEntitySelectState
	extends ISelectFormControlState<BillingEntityIndexResponse> {}

export class BillingEntitySelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	BillingEntityIndexResponse,
	IsMulti,
	IBillingEntitySelectProps<IsMulti>,
	IBillingEntitySelectState
> {
	public resourceClass = BillingEntityResource;
	public state: IBillingEntitySelectState = {
		formLabel: 'Billing Entity',
		valueKey: 'billingEntityId',
		labelKey: 'companyName'
	};
}
