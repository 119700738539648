import { Rt800ApiRoutes } from 'RtExports/routes';
import { Rt800Router } from '../rt800.router';

class CprSearchRouterClass extends Rt800Router<void, void, {}> {
	constructor() {
		super('CPR Search', 'cprSearch', undefined, {});

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.CprNumbers);
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const CprSearchRouter = new CprSearchRouterClass();
