import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { Integrations, ServiceNumberPortRequestIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';

export const getPortRequestColumns = (): Array<
	DataGridColumn<ServiceNumberPortRequestIndexResponse>
> => [
	PhoneNumberDataGridColumn({
		accessorKey: 'serviceNumber',
		header: 'Service Number'
	}),
	DefaultDataGridColumn({
		header: 'Vendor',
		accessorKey: 'integrationId',
		getValue: ({ cell }) =>
			Integrations[cell.getValue() as any] || (cell.getValue() as any)
	}),
	DefaultDataGridColumn({
		header: 'Type',
		accessorKey: 'isPortIn',
		getValue: ({ cell }) => (cell.getValue() === 0 ? 'Out' : 'In')
	}),
	IntegerDataGridColumn({
		accessorKey: 'pin',
		header: 'Pin'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'requestTs',
		header: 'Request time'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isValid',
		header: 'Valid'
	}),
	DefaultDataGridColumn({
		accessorKey: 'note',
		header: 'Note'
	})
];
