import { ResourceType } from 'RtModels';
import { CarrierConnectResourceRouter } from 'RtUi/app/rtCarrierConnect/Resources/CarrierConnectResource.router';
import { ResourceProfileContainer } from 'RtUi/app/rtCommon/Resources/lib/components/ResourceProfileContainer';

export const CarrierConnectResourceProfileContainer = () => {
	return (
		<ResourceProfileContainer
			excludeResourceTypes={[ResourceType.DidVendor]}
			router={CarrierConnectResourceRouter}
		/>
	);
};

CarrierConnectResourceProfileContainer.displayName =
	'CarrierConnectResourceProfileContainer';

CarrierConnectResourceRouter.setProfileRtUiFunctionalComponent(
	CarrierConnectResourceProfileContainer
);
