import { FC, FormEvent, useState } from 'react';
import {
	GuardianReconciliationMappingProfileResponse,
	GuardianReconciliationTypeIndexResponse,
	GuardianReconciliationMappingRecurringScheduleType
} from 'RtModels';
import { GuardianReconciliationMappingResource } from 'RtUi/app/rtVue/GuardianReconciliationMapping/lib/resources/GuardianReconciliationMappingResource';
import { ReconciliationTypeSelect } from 'RtUi/app/rtVue/ReconciliationType/lib/controls/ReconciliationTypeSelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { GuardianReconciliationMappingRecurringTypeSelect } from 'RtUi/app/rtVue/GuardianReconciliationMapping/lib/controls/GuardianReconciliationMappingRecurringTypeSelect';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface IGuardianReconciliationMappingEditorProps {
	editMode?: GuardianReconciliationMappingProfileResponse;
	createMode?: boolean;
	onUpdate?: (newProfile: GuardianReconciliationMappingProfileResponse) => void;
}

export const GuardianReconciliationMappingEditor: FC<
	React.PropsWithChildren<IGuardianReconciliationMappingEditorProps>
> = ({ editMode, createMode, onUpdate = () => ({}) }) => {
	const [reconType, setReconType] =
		useState<GuardianReconciliationTypeIndexResponse>();
	const [recurringScheduleType, setRecurringScheduleType] = useState<
		| ISimpleSelectFormControlOption<GuardianReconciliationMappingRecurringScheduleType>
		| undefined
	>();

	const reconciliationMappingResource =
		new GuardianReconciliationMappingResource();
	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		editMode,
		createMode,
		createNew: () => reconciliationMappingResource.createNewProfile()
	});
	const { workingCopy } = rtUiFormState;

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			let newProfile: GuardianReconciliationMappingProfileResponse;

			if (createMode) {
				newProfile = await reconciliationMappingResource.create(workingCopy);
			} else {
				newProfile = await reconciliationMappingResource.update(
					workingCopy.guardianReconciliationMappingId,
					workingCopy
				);
			}

			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setDisplayMode(true);

			onUpdate(newProfile);
		} catch (err) {
			rtUiFormMethods.setError(err);
			rtUiFormMethods.setIsSubmitting(false);
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			onSubmit={onSubmit}
			onChange={rtUiFormMethods.setDisplayMode}
			onCancel={() => {
				setReconType(undefined);
				setRecurringScheduleType(undefined);
				rtUiFormMethods.onCancel();
			}}
		>
			<InputFormControl
				label="Label"
				displayMode={rtUiFormState.displayMode}
				value={workingCopy.summary ?? ''}
				onChange={(summary) => rtUiFormMethods.setWorkingCopy({ summary })}
			/>
			<ReconciliationTypeSelect
				value={reconType}
				displayMode={rtUiFormState.displayMode}
				initialOptionId={workingCopy.reconciliationTypeId?.toString()}
				onChange={(newReconType) => {
					rtUiFormMethods.setWorkingCopy({
						reconciliationTypeId: newReconType.guardianReconciliationTypeId
					});
					setReconType(newReconType);
				}}
			/>
			<IsActiveRadioFormControl
				hideBothOption
				displayMode={rtUiFormState.displayMode}
				value={workingCopy.isActive}
				onChange={(isActive) =>
					rtUiFormMethods.setWorkingCopy({
						isActive
					})
				}
			/>
			<InputFormControl
				label="Account Ban Number"
				displayMode={rtUiFormState.displayMode}
				value={workingCopy.carrierBanNumber ?? ''}
				onChange={(carrierBanNumber) =>
					rtUiFormMethods.setWorkingCopy({ carrierBanNumber })
				}
			/>
			<GuardianReconciliationMappingRecurringTypeSelect
				label="Recurring Schedule Type"
				displayMode={rtUiFormState.displayMode}
				value={recurringScheduleType}
				onChange={(recurringType) => {
					setRecurringScheduleType(recurringType);
					rtUiFormMethods.setWorkingCopy({
						recurringScheduleType: recurringType.value
					});
				}}
				initialOptionId={
					workingCopy.recurringScheduleType
						? workingCopy.recurringScheduleType.toString()
						: undefined
				}
			/>
		</RtUiForm>
	);
};
