import { RtxDidApiRoutes } from 'RtExports/routes';
import { NumberIndexRequest, NumberIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchDidNumbers = async (
	queryParams?: NumberIndexRequest
): Promise<FullResponse<NumberIndexResponse[]>> => {
	return handleGetRequest<NumberIndexResponse[], true>(
		RtxDidApiRoutes.Numbers.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetDidNumbers = (queryParams?: NumberIndexRequest) => {
	return useQuery<FullResponse<NumberIndexResponse[]>, Error>(
		[`useGetDidNumbers`, queryParams],
		() => fetchDidNumbers(queryParams)
	);
};

export const fetchDidNumbersCsv = async (
	queryParams?: NumberIndexRequest
): Promise<string> => {
	return handleGetRequest<string>(RtxDidApiRoutes.NumbersCsv.Index, {
		queryParams
	});
};
