import { IFormControlProps } from 'RtUi/components/form/FormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { useState, useEffect, useCallback } from 'react';
import {
	ProtocolTypeSelect,
	IProtocolOption
} from 'RtUi/components/form/UrlInputControl/ProtocolTypeSelect';
import { ProtocolTypesOptions } from 'RtUi/utils/maps';
import { find } from 'lodash-es';

interface IUrlInputFormControlProps extends IFormControlProps<string> {
	onChange: (value: string) => void;
}

const PROTOCOL_SEPARATOR = '://';

export const UrlInputFormControl = (
	props: IUrlInputFormControlProps
): JSX.Element => {
	const { onChange, value, displayMode } = props;
	const [protocol, setProtocol] = useState(ProtocolTypesOptions[0]);
	const [url, setUrl] = useState('');
	const [fullValue, setFullValue] = useState(value || '');

	const getDefaultProtocol = useCallback(() => {
		const splitUrl = value?.split(PROTOCOL_SEPARATOR) || [];
		if (value) {
			if (value.includes(PROTOCOL_SEPARATOR)) {
				const matchingProtocol = find(ProtocolTypesOptions, {
					value: splitUrl[0]
				});
				return matchingProtocol ? matchingProtocol : ProtocolTypesOptions[0];
			}
		}
		return ProtocolTypesOptions[0];
	}, [value]);

	const getDefaultUrlValue = useCallback(() => {
		const splitUrl = value?.split(PROTOCOL_SEPARATOR) || [];
		if (value) {
			if (value.includes(PROTOCOL_SEPARATOR)) {
				return splitUrl[1];
			}
		}
		return value || '';
	}, [value]);

	useEffect(() => {
		if (value) {
			setProtocol(getDefaultProtocol());
			setUrl(getDefaultUrlValue());
			setFullValue(value);
		}
	}, [getDefaultProtocol, getDefaultUrlValue, value]);

	const onURLChange = (urlValue: string) => {
		setUrl(urlValue);
		setFullValue(`${protocol.value}${PROTOCOL_SEPARATOR}${urlValue}`);
		onChange(`${protocol.value}${PROTOCOL_SEPARATOR}${urlValue}`);
	};

	const onTypeChange = (selectedType: IProtocolOption) => {
		setProtocol(selectedType);
		setFullValue(`${selectedType.value}${PROTOCOL_SEPARATOR}${url}`);
		onChange(`${selectedType.value}${PROTOCOL_SEPARATOR}${url}`);
	};

	return (
		<div className="rt-url-input-control">
			<div className="url-protocol-select">
				<ProtocolTypeSelect
					onChange={(selectedType) => onTypeChange(selectedType)}
					clearable={false}
					displayMode={displayMode}
					initialOptionId={ProtocolTypesOptions[0].value}
					value={protocol}
				/>
			</div>
			<div className="url-input">
				<InputFormControl
					displayMode={displayMode}
					value={url}
					onChange={(urlValue) => onURLChange(urlValue)}
					label="URL"
				/>
			</div>
		</div>
	);
};
