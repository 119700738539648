import { DataSources, EntityIndexResponse } from 'RtModels';
import {
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { IndexedDbCacheStrategy } from 'RtUi/utils/cache/strategies/IndexedDbCacheStrategy';

/**
 * @singleton
 */
export class RtVueEntityFilter extends RtVueFilter<EntityIndexResponse> {
	public static getInstance() {
		if (!RtVueEntityFilter.instance) {
			RtVueEntityFilter.instance = new RtVueEntityFilter();
		}

		return RtVueEntityFilter.instance;
	}

	private static instance: RtVueEntityFilter | undefined;
	private static readonly FilterName = 'RtVueEntityFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<EntityIndexResponse>
	> = new IndexedDbCacheStrategy(RtVueEntityFilter.FilterName, 'entityId', [
		'label',
		'*keywords' as keyof EntityIndexResponse
	]);

	protected constructor() {
		super(
			RtVueEntityFilter.FilterName,
			DataSources.Entities,
			RtVueEntityFilter.DataCache
		);
	}

	public getLabelFor(record: EntityIndexResponse): string {
		return record.label;
	}

	public getValueFor(record: EntityIndexResponse): string {
		return record.entityId;
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<EntityIndexResponse>
	> {
		return this.vueHttp.getEntityFilter();
	}

	protected getKeywordsFor(record: EntityIndexResponse): string[] {
		const keywords: string[] = [
			record.entityId,
			...this.getKeywordsFromPhrase(record.label)
		];

		return keywords;
	}
}
