import * as moment from 'moment-timezone';
import {
	RespOrgContactIndexResponse,
	RespOrgIndexResponse,
	RespOrgProfileResponse,
	RespOrgTotalsByNpaResponse,
	RespOrgUpdateRequest,
	RespOrgProfileRequest
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class RespOrgResource extends HttpResource<
	RespOrgIndexResponse,
	RespOrgProfileResponse
> {
	constructor() {
		super(Rt800ApiRoutes.RespOrgs);
	}

	public update(respOrgId: string, label: string, isActive: number) {
		const updateReq: RespOrgUpdateRequest = { label, isActive };
		const body = JSON.stringify(updateReq);
		const urlParams: RespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<RespOrgProfileResponse>(
			Rt800ApiRoutes.RespOrgs.Update,
			{ body, urlParams }
		);
	}

	public getContacts(respOrgId: string) {
		const urlParams: RespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<RespOrgContactIndexResponse[]>(
			Rt800ApiRoutes.RespOrgContact.Index,
			{ urlParams }
		);
	}

	public getTotalsByNpa(respOrgId: string) {
		const urlParams: RespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<RespOrgTotalsByNpaResponse[]>(
			Rt800ApiRoutes.RespOrgTotalsByNpa.Index,
			{ urlParams }
		).then((npaTotalRows) => {
			npaTotalRows.forEach((npaTotalRows) => {
				npaTotalRows.statusTs = moment(npaTotalRows.statusTs).toDate();
			});

			return npaTotalRows;
		});
	}
}
