import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { FallbackHeaderDataGridColumnConfiguration } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import {
	PrecisionDataGridColumn,
	PrecisionDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';

type CostDataGridColumnConfiguration<T> =
	PrecisionDataGridColumnConfiguration<T> &
		FallbackHeaderDataGridColumnConfiguration<T>;

export const CostDataGridColumn = <T = any,>({
	header = 'Cost',
	decimals = 2,
	...config
}: CostDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return PrecisionDataGridColumn({
		header,
		decimals,
		...config
	});
};
