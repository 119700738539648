import {
	RateLoadProfileResponse,
	RateLoadStatus,
	RateLoadStep,
	RatePlanIndexResponse,
	TimezoneIndexResponse
} from 'RtModels';
import {
	RateManagementContainerTabs,
	RateManagementRouter
} from 'RtUi/app/AccountManagement/RateManagement/RateManagement.router';
import { RateManagementStatus } from 'RtUi/app/AccountManagement/RateManagement/libs/components/RateManagementStatus';
import { RateLoadNewRatesDataGrid } from 'RtUi/app/AccountManagement/RateManagement/libs/grids/RateLoadNewRatesGrid';
import { RateLoadOldRatesDataGrid } from 'RtUi/app/AccountManagement/RateManagement/libs/grids/RateLoadOldRatesGrid';
import { RateLoadResource } from 'RtUi/app/AccountManagement/RateManagement/libs/resources/RateLoadResource';
import * as RateLoadUtils from 'RtUi/app/AccountManagement/RateManagement/libs/utils/RateLoadUtils';
import { RatePlanSubscriptionsDataGrid } from 'RtUi/app/AccountManagement/RatePlan/lib/grid/RatePlanSubscriptionsDataGrid';
import { RatePlanSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/RatePlanSelect';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { timestampToReadable } from 'RtUi/utils/maps';
import clsx from 'clsx';
import moment from 'moment';
import * as React from 'react';
import { Card, Col, Nav, Row } from 'react-bootstrap';

enum RateGridTabs {
	NewRates = 'New Rates',
	OldRates = 'Old Rates'
}

interface IRateManagementProfileContainerState {
	activeTab: string;
	activeRateGridTab: RateGridTabs;
	isFileDownloading: boolean;
	isAmendmentDownloading: boolean;
	subscriptionResource: SubscriptionResource;
	profile?: RateLoadProfileResponse;
	timezone?: TimezoneIndexResponse;
	ratePlan?: RatePlanIndexResponse;
}

@RateManagementRouter.getProfileRtUiController()
export class RateManagementProfileContainer extends ProfileApplicationContainer<
	{},
	IRateManagementProfileContainerState
> {
	public Tabs = RateManagementContainerTabs;
	public resource = new RateLoadResource();
	public state: IRateManagementProfileContainerState = {
		activeTab: this.Tabs.Profile.header,
		activeRateGridTab: RateGridTabs.NewRates,
		isFileDownloading: false,
		subscriptionResource: new SubscriptionResource(),
		isAmendmentDownloading: false
	};

	public initTimeoutId: number = -1;
	public rateLoadResource = new RateLoadResource();

	protected haltPolling = true;

	public componentDidMount() {
		this.haltPolling = false;
		this.initPolling();
	}

	public componentWillUnmount() {
		this.haltPolling = true;
		this.clearPollingTimeout();
	}

	public clearPollingTimeout() {
		window.clearTimeout(this.initTimeoutId);
	}

	public async initPolling() {
		const id = this.getIdParam();
		const profile = await this.resource.get(id);

		if (!profile) {
			throw new Error(`Cannot find Rate ${id}`);
		}

		this.clearPollingTimeout();

		if (this.haltPolling) {
			return;
		}

		/**
		 * Refresh profile to see updates
		 */
		this.setState({ profile }, () => {
			if (this.haltPolling) {
				return;
			}

			//profile.rateLoadStepId === RateLoadStep.RateLoading;
			let isRunning = profile.rateLoadStatusId === RateLoadStatus.Running;

			if (!isRunning) {
				const initStages = [
					RateLoadStep.Creating,
					RateLoadStep.Parsing,
					RateLoadStep.Verification,
					RateLoadStep.Importing
				];

				if (profile.rateLoadStepId === RateLoadStep.RateApproval) {
					//Update faster when RateApproval is pending
					isRunning = RateLoadUtils.IsCompletedOk(profile.rateLoadStatusId);
				} else if (initStages.includes(profile.rateLoadStepId)) {
					isRunning = true;
				}
			}
			//10 seconds while running, every half-minute otherwise
			const refreshTimeoutInSeconds = isRunning ? 10 : 30;
			const refreshTimeout = refreshTimeoutInSeconds * 1000;

			this.initTimeoutId = window.setTimeout(
				() => this.initPolling(),
				refreshTimeout
			);

			this.state.subscriptionResource.setGetAllParams({
				ratePlanId: profile.ratePlanId
			});
		});
	}

	public render() {
		const { profile } = this.state;
		const badCompletedStatuses = [
			RateLoadStatus.CompletedWithExceptions,
			RateLoadStatus.CompletedError
		];
		const completedStatuses = [
			RateLoadStatus.CompletedOk,
			...badCompletedStatuses
		];

		if (!profile) {
			return <Loading />;
		}

		let hasAnalysisCompleted = false;
		let hasImportErrored = false;
		let allowAmendmentDownload = false;

		if (profile.rateLoadStepId) {
			if (profile.rateLoadStepId === RateLoadStep.RateAndCodeAnalysis) {
				hasAnalysisCompleted = completedStatuses.includes(
					profile.rateLoadStatusId
				);
			} else if (profile.rateLoadStepId > RateLoadStep.RateAndCodeAnalysis) {
				hasAnalysisCompleted = true;
			}

			allowAmendmentDownload =
				profile.rateLoadStepId === RateLoadStep.RateAmendment &&
				profile.rateLoadStatusId === RateLoadStatus.CompletedOk;

			if (profile.rateLoadStepId === RateLoadStep.Importing) {
				hasImportErrored = badCompletedStatuses.includes(
					profile.rateLoadStatusId
				);
			}
		}

		const shouldShowRateGrid = hasAnalysisCompleted || hasImportErrored;

		return (
			<StandardLayout router={RateManagementRouter} profile={profile}>
				<section>
					<Row>
						<Col xs={{ order: 2 }} lg={{ span: 7, order: 1 }}>
							<Card className="mb-3">
								<Card.Body>
									<Row>
										<Col lg={6}>
											<DatetimeFormControl
												displayMode
												label="Notification Date"
												displayModeTimezone="UTC"
												value={moment(profile.notificationTs)}
											/>
										</Col>
										<Col lg={6}>
											<DatetimeFormControl
												displayMode
												label="Effective Date"
												displayModeTimezone="UTC"
												value={moment(profile.effectiveTs)}
											/>
										</Col>
										<Col lg={6}>
											<DatetimeFormControl
												displayMode
												label="Minimum Effective Date"
												displayModeTimezone="UTC"
												value={
													profile.minEffectiveTs
														? moment(profile.minEffectiveTs)
														: undefined
												}
											/>
										</Col>
										<Col lg={6}>
											<DatetimeFormControl
												displayMode
												label="Maximum Effective Date"
												displayModeTimezone="UTC"
												value={
													profile.minEffectiveTs
														? moment(profile.maxEffectiveTs)
														: undefined
												}
											/>
										</Col>
										<Col lg={6}>
											<InputFormControl
												label="File Parser"
												displayMode
												value={profile.parser ?? undefined}
											/>
										</Col>
										<Col lg={6}>
											<RatePlanSelect
												label="Rate Plan"
												displayMode
												value={this.state.ratePlan}
												initialOptionId={profile.ratePlanId}
												onChange={(ratePlan) => {
													this.setState({ ratePlan });
												}}
											/>
										</Col>
									</Row>
								</Card.Body>
								<Card.Footer className="d-flex justify-content-between">
									{allowAmendmentDownload && (
										<small>
											<a onClick={this.downloadAmendment}>
												{this.state.isAmendmentDownloading && (
													<i className="fas fa-fw fa-spin fa-cog" />
												)}
												Amendment Download
											</a>
										</small>
									)}
									<small>
										<a className="text-muted" onClick={this.downloadRateLoad}>
											{this.state.isFileDownloading && (
												<i className="fas fa-fw fa-spin fa-cog" />
											)}
											Download Original File ({profile.uploadedFileName})
										</a>
									</small>
									<small className="text-muted">
										Uploaded by&nbsp;
										{profile?.userEmail ?? ''} at&nbsp;
										{timestampToReadable(moment(profile.createdTs))}
									</small>
								</Card.Footer>
							</Card>
						</Col>
						<Col xs={{ order: 1 }} lg={{ span: 5, order: 2 }}>
							<RateManagementStatus
								profile={profile}
								onDelete={this.delete}
								onApprove={this.approve}
								onApproveExceptions={() => this.initPolling()}
							/>
							<RatePlanSubscriptionsDataGrid
								resourceParams={{
									ratePlanId: profile.ratePlanId
								}}
							/>
						</Col>
					</Row>
				</section>
				{shouldShowRateGrid && (
					<footer>
						<hr className="my-4" />
						<Card>
							<Nav
								variant="tabs"
								defaultActiveKey={this.state.activeRateGridTab}
								onSelect={(tab) => {
									this.setState({
										activeRateGridTab:
											(tab as RateGridTabs | null) ?? RateGridTabs.NewRates
									});
								}}
							>
								<Nav.Link eventKey={RateGridTabs.NewRates}>
									{RateGridTabs.NewRates}
								</Nav.Link>
								<Nav.Link eventKey={RateGridTabs.OldRates}>
									{RateGridTabs.OldRates}
								</Nav.Link>
							</Nav>
							<section
								className={clsx({
									'd-none':
										this.state.activeRateGridTab !== RateGridTabs.NewRates
								})}
							>
								<RateLoadNewRatesDataGrid rateLoadId={profile.rateLoadId} />
							</section>
							<section
								className={clsx({
									'd-none':
										this.state.activeRateGridTab !== RateGridTabs.OldRates
								})}
							>
								<RateLoadOldRatesDataGrid rateLoadId={profile.rateLoadId} />
							</section>
						</Card>
					</footer>
				)}
			</StandardLayout>
		);
	}

	private downloadRateLoad = async (e: React.MouseEvent) => {
		e.preventDefault();

		const { profile } = this.state;

		if (!profile || this.state.isFileDownloading) {
			return;
		}

		try {
			this.setState({ isFileDownloading: true });

			await this.rateLoadResource.downloadRateLoad(profile.rateLoadId);
		} finally {
			this.setState({ isFileDownloading: false });
		}
	};

	private downloadAmendment = async (e: React.MouseEvent) => {
		e.preventDefault();
		const { profile } = this.state;

		if (!profile || this.state.isAmendmentDownloading) {
			return;
		}

		try {
			this.setState({ isAmendmentDownloading: true });

			await this.rateLoadResource.downloadRateAmendment(profile.rateLoadId);
		} finally {
			this.setState({ isAmendmentDownloading: false });
		}
	};

	private delete = async () => {
		const confirm = await Confirmation.createDelete('all rates');

		if (!confirm) {
			return;
		}

		await this.resource.delete({
			rateLoadId: this.state.profile!.rateLoadId
		});

		this.goToPath(RateManagementRouter.getIndexRoute());
	};

	private approve = async () => {
		const confirm = await Confirmation.create(
			<span>Are you sure you would like to approve and load these rates?</span>
		);

		if (!confirm) {
			return;
		}

		const { rateLoadId } = this.state.profile!;
		await this.resource.approve({ rateLoadId });

		await this.initPolling();
	};
}
