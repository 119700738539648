/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $Cpr = {
    properties: {
        nodeTypes: {
            type: 'Array',
            isRequired: true,
        },
        nodes: {
            type: 'Array',
            isRequired: true,
        },
        labels: {
            type: 'Array',
            isRequired: true,
        },
    },
};