import * as React from 'react';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { UserHttp } from 'RtUi/app/user/lib/Http/UserHttp';
import { Alert } from 'react-bootstrap';
import { UserIndexResponse, UserProfileResponse } from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

interface IRemoveTFAFormProps {
	user: UserIndexResponse;
	onSuccess: (user: UserProfileResponse) => void;
}

interface IRemoveTFAFormState {
	isSubmitting: boolean;
	error?: any;
}

export class RemoveTFAForm extends React.Component<
	IRemoveTFAFormProps,
	IRemoveTFAFormState
> {
	public state: IRemoveTFAFormState = {
		isSubmitting: false,
		error: undefined
	};

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		try {
			this.setState({ isSubmitting: true });

			const userHttp = new UserHttp();
			const { user } = this.props;

			const updatedUser = await userHttp.removeTFA(user.userId);

			this.setState({ isSubmitting: false }, () => {
				HttpResource.showSuccessNotification(
					`Two-factor authentication for the user ${user.firstName} ${user.lastName} has been successfully removed.`
				);
				this.props.onSuccess(updatedUser);
			});
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	}

	public render() {
		const { user } = this.props;

		return (
			<RtUiNarrowForm
				createMode
				displayMode={false}
				submitButtonColor="danger"
				submitButtonText="Yes, Remove TFA"
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onSubmit={(evt) => this.onSubmit(evt)}
			>
				<Alert variant="danger-outline">
					This will remove two-factor authentication for {user.firstName}{' '}
					{user.lastName}. Once this has been done, it can not be undone. Would
					you like to remove two-factor authentication for{' '}
					<b>
						{user.firstName} {user.lastName}
					</b>
					?
				</Alert>
			</RtUiNarrowForm>
		);
	}
}
