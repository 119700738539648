import clsx from 'clsx';
import { uniq } from 'lodash-es';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import { useDebounce, useUpdate } from 'react-use';
import { CprNodeTypeNameMap } from 'RtUi/app/rt800/Cprs/lib/Constants';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import {
	TCprLergSearchForKeyResults,
	TCprLergSearchRequest
} from 'RtUi/app/rt800/Cprs/lib/util/Constants';
import { CprLergHighlightHelper } from 'RtUi/app/rt800/Cprs/lib/util/CprLergHighlightHelper';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2';

interface ICprSearchFormProps {
	searchReq: TCprLergSearchRequest | undefined;
	onChange: (newSearchReq: TCprLergSearchRequest | undefined) => void;
}

export const CprSearchForm: FC<
	React.PropsWithChildren<ICprSearchFormProps>
> = ({ searchReq, onChange }) => {
	const {
		cpr: mgiProfile,
		cprDataGrid,
		cprLabelDataGrid,
		activeTab,
		router
	} = useContext(CprProfileContext);
	const cprLergFilterSearch = new CprLergHighlightHelper();
	const [searchStr, setSearchStr] = useState('');
	const updateComponent = useUpdate();
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [keyResults, setKeyResults] = useState<TCprLergSearchForKeyResults>();
	const searchForDescendants = searchReq?.searchForDescendants ?? false;
	const searchForAscendants = searchReq?.searchForAscendants ?? false;
	const results: Array<[CprNodeType, string]> = [];
	const cics = uniq([
		...(mgiProfile?.getInterLataCarriers() ?? []),
		...(mgiProfile?.getIntraLataCarriers() ?? [])
	]);

	useEffect(() => mgiProfile?.onValidate(updateComponent));

	if (keyResults) {
		for (const [nodeTypeStr, suggestions] of Object.entries(keyResults)) {
			for (const suggestion of suggestions) {
				results.push([nodeTypeStr as CprNodeType, suggestion]);
			}
		}
	}

	useDebounce(
		async () => {
			if (searchStr.startsWith('*')) {
				const labels = mgiProfile?.getCprLabels() ?? [];
				const newKeyResults = cprLergFilterSearch.searchForLabel(
					labels,
					searchStr
				);

				setKeyResults(newKeyResults);
				setDropdownIsOpen(true);
				return;
			}

			const newKeyResults =
				await cprLergFilterSearch.searchForCprKey(searchStr);
			setKeyResults(newKeyResults);
			setDropdownIsOpen(true);
		},
		500,
		[searchStr]
	);

	const setCicAndUpdateSearchReq = useCallback(
		(option: ISimpleSelectFormControlOption<string>) => {
			const newSearchReq =
				typeof searchReq !== 'undefined' ? { ...searchReq } : {};

			if (option?.value) {
				newSearchReq.cic = option?.value;
			} else {
				delete newSearchReq.cic;
			}

			onChange(newSearchReq);
		},
		[searchReq, onChange]
	);

	const replaceFilter = useCallback(
		(key: CprNodeType, value: string) => {
			onChange({
				lerg: {
					key,
					value
				},
				cic: searchReq?.cic,
				searchForDescendants: true
			});
			setSearchStr('');
		},
		[searchReq?.cic, onChange]
	);

	const renderDropdownResult = useCallback(
		(type: CprNodeType, key: string) => (
			<Dropdown.Item
				key={type + key}
				className="d-flex flex-row justify-content-between"
				onClick={() => replaceFilter(type, key)}
			>
				<span>{key}</span>
				<span className="text-muted" style={{ textTransform: 'capitalize' }}>
					{CprNodeTypeNameMap.get(type)}
				</span>
			</Dropdown.Item>
		),
		[replaceFilter]
	);

	const renderFilter = (key: CprNodeType, value: string) => (
		<Button
			key={key + value}
			variant="white-alt"
			type="button"
			className="d-flex justify-content-between me-1"
			style={{ minWidth: 150 }}
			onClick={() => onChange(undefined)}
		>
			<span>{value}&nbsp;&nbsp;</span>
			<span className="text-muted" style={{ textTransform: 'capitalize' }}>
				{CprNodeTypeNameMap.get(key)}&nbsp;
				<i className="fa fa-fw fa-times" />
			</span>
		</Button>
	);

	const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
		evt.stopPropagation();
		evt.preventDefault();

		const firstResult = results[0];

		if (firstResult) {
			replaceFilter(firstResult[0], firstResult[1]);
		}
	};

	const highlightPreviousCprRow = () => {
		const selectedRow = mgiProfile?.selectPreviousHighlightedRow();

		if (!selectedRow || !cprDataGrid.current) {
			return;
		}

		const pageSize = cprDataGrid.current.pageSize;
		const selectedRowIndex = selectedRow.getCprIdx();
		const goToPage = Math.floor(selectedRowIndex / pageSize);

		cprDataGrid.current.updatePage(goToPage);
	};

	const highlightPreviousLabel = () => {
		const selectedLabel = mgiProfile?.selectPreviousHighlightedLabel();

		if (!selectedLabel || !cprLabelDataGrid.current) {
			return;
		}

		const pageSize = cprLabelDataGrid.current.pageSize;
		const selectedLabelIndex = selectedLabel.getCprIdx();
		const goToPage = Math.floor(selectedLabelIndex / pageSize);

		cprLabelDataGrid.current.updatePage(goToPage);
	};

	const highlightNextCprRow = () => {
		const selectedRow = mgiProfile?.selectNextHighlightedRow();

		if (!selectedRow || !cprDataGrid.current) {
			return;
		}

		const pageSize = cprDataGrid.current.pageSize;
		const selectedRowIndex = selectedRow.getCprIdx();
		const goToPage = Math.floor(selectedRowIndex / pageSize);

		cprDataGrid.current.updatePage(goToPage);
	};

	const highlightPreviousNode = () => {
		if (activeTab === router.getProfileTabs().Cpr.header) {
			return highlightPreviousCprRow();
		}

		return highlightPreviousLabel();
	};

	const highlightNextLabel = () => {
		const selectedLabel = mgiProfile?.selectNextHighlightedLabel();

		if (!selectedLabel || !cprLabelDataGrid.current) {
			return;
		}

		const pageSize = cprLabelDataGrid.current.pageSize;
		const selectedLabelIndex = selectedLabel.getCprIdx();
		const goToPage = Math.floor(selectedLabelIndex / pageSize);

		cprLabelDataGrid.current.updatePage(goToPage);
	};

	const highlightNextNode = () => {
		if (activeTab === router.getProfileTabs().Cpr.header) {
			return highlightNextCprRow();
		}

		return highlightNextLabel();
	};

	const getHighlightedNodeIndex = () => {
		if (!mgiProfile) {
			return 0;
		}

		if (activeTab === router.getProfileTabs().Cpr.header) {
			return mgiProfile.getSelectedHighlightedRowIndex();
		}

		return mgiProfile.getSelectedHighlightedLabelIndex();
	};

	const getHighlightedNodeQty = () => {
		if (!mgiProfile) {
			return 0;
		}

		if (activeTab === router.getProfileTabs().Cpr.header) {
			return mgiProfile.getHighlightedRows().length;
		}

		return mgiProfile.getHighlightedLabels().length;
	};

	return (
		<Form className="mb-3 d-flex justify-content-between" onSubmit={onSubmit}>
			<div className="d-flex d-inline-flex">
				<label className="me-2 align-self-center">Highlight</label>
				{searchReq?.lerg &&
					renderFilter(searchReq.lerg.key, searchReq.lerg.value)}
				{!searchReq?.lerg && (
					<Dropdown
						align="end"
						show={dropdownIsOpen && results.length > 0}
						onToggle={() => setDropdownIsOpen(!dropdownIsOpen)}
					>
						<Dropdown.Toggle
							bsPrefix="m-0"
							as="section"
							className="d-flex justify-content-between"
							style={{ width: 180 }}
						>
							<InputFormControl
								label=""
								placeholder="LATA, NPA, State, Label"
								toUpperCase
								useControlGroup={false}
								onChange={setSearchStr}
								value={searchStr}
							/>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{Array.from(results).map(([nodeType, value]) =>
								renderDropdownResult(nodeType, value)
							)}
						</Dropdown.Menu>
					</Dropdown>
				)}
				<section className="ms-1 me-3" style={{ width: 120 }}>
					<SimpleSelectFormControl<string>
						label="CIC"
						placeholder="CIC"
						useControlGroup={false}
						clearable
						onChange={setCicAndUpdateSearchReq}
						value={
							searchReq?.cic
								? { label: searchReq.cic, value: searchReq.cic }
								: undefined
						}
						initialOptionId={searchReq?.cic}
						options={cics.map((cic) => ({ value: cic, label: cic }))}
					/>
				</section>
				{mgiProfile.isHighlighting() && (
					<ButtonGroup className="me-2 align-items-center">
						<Button
							type="button"
							variant="white-alt"
							onClick={() => {
								highlightPreviousNode();
								updateComponent();
							}}
						>
							<i className="fas fa-chevron-left" />
						</Button>
						<span className="px-2">
							{getHighlightedNodeIndex() + 1} of {getHighlightedNodeQty()}
						</span>
						<Button
							type="button"
							variant="white-alt"
							onClick={() => {
								highlightNextNode();
								updateComponent();
							}}
						>
							<i className="fas fa-chevron-right" />
						</Button>
					</ButtonGroup>
				)}
				{searchReq?.lerg && (
					<>
						{searchReq.lerg.key !== CprNodeType.Carrier && (
							<>
								<Button
									type="button"
									variant="white-alt ms-2"
									onClick={() =>
										onChange({
											...searchReq,
											searchForDescendants: !searchForDescendants
										})
									}
								>
									<i
										className={clsx('fa-fw', {
											'fas fa-check-square text-primary': searchForDescendants,
											'far fa-square': !searchForDescendants
										})}
									/>
									<span className="px-2">Children</span>
								</Button>
								<Button
									type="button"
									className="ms-2"
									variant="white-alt"
									onClick={() =>
										onChange({
											...searchReq,
											searchForAscendants: !searchForAscendants
										})
									}
								>
									<i
										className={clsx('fa-fw', {
											'fas fa-check-square text-primary': searchForAscendants,
											'far fa-square': !searchForAscendants
										})}
									/>
									<span className="px-2">Parents</span>
								</Button>
							</>
						)}
					</>
				)}
			</div>
			{searchReq && (
				<section>
					<span className="multi-data-list-item-label multi-data-list-item-label-exact-match me-3 rounded">
						Exact
					</span>
					<span className="multi-data-list-item-label multi-data-list-item-label-child-match me-3 rounded">
						Child
					</span>
					<span className="multi-data-list-item-label multi-data-list-item-label-parent-match me-3 rounded">
						Parent
					</span>
				</section>
			)}
		</Form>
	);
};
