/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FileProcessIndexResponse } from 'RtModels';
import { DownloadButton } from 'RtUi/app/rtVue/GuardianReconciliation/lib/controls/DownloadButton';
import { LrnLookupResource } from 'RtUi/app/rtVue/LrnLookup/lib/resources/LrnLookupResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileUtils } from 'RtUi/utils/file/FileUtils';

interface LrnLookupDownloadDataGridConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const LrnLookupDownloadColumn = <T = any,>({
	size = 100,
	...config
}: LrnLookupDownloadDataGridConfiguration<T>): DataGridColumn<T> => {
	const fileUtils = new FileUtils();

	const renderValue = (record: FileProcessIndexResponse) => {
		if (!record.completedTs || !record.outputFileNameRemote) {
			return '';
		}

		return (
			<DownloadButton
				downloadFn={async () => {
					const resource = new LrnLookupResource();
					const result = await resource.getDownloadLink(record.fileProcessId);

					return fileUtils.downloadFromUrlWithPromise(
						Promise.resolve({
							url: result.signedUrl,
							fileName: result.fileName
						})
					);
				}}
			/>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		getValue: ({ cell }) => renderValue(cell.row.original as any)
	});
};
