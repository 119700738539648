import { LataIndexResponse } from 'RtModels';
import { LataResource } from 'RtUi/app/rtVue/DialCode/lib/resources/LataResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface ILataSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<LataIndexResponse, IsMulti> {}
interface ILataSelectState extends ISelectFormControlState<LataIndexResponse> {}

export class LataSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	LataIndexResponse,
	IsMulti,
	ILataSelectProps<IsMulti>,
	ILataSelectState
> {
	public resourceClass = LataResource;
	public state: ILataSelectState = {
		formLabel: 'Lata',
		valueKey: 'lata',
		labelKey: 'lata'
	};

	public optionRenderer = (option: LataIndexResponse) => {
		return <span>{`${option.lata} (${option.location})`}</span>;
	};
}
