import { MessageType } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DefaultDataGridColumn,
	DataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface MessageTypeDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const MessageTypeDataGridColumn = <T = any,>(
	config: MessageTypeDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	const formattedValue = (value: MessageType[]) => {
		return value
			.map((messageType) => {
				switch (messageType) {
					case MessageType.Email:
						return 'Email';
					case MessageType.Firebase:
						return 'Application Messaging';
					case MessageType.Sms:
						return 'Sms';
					default:
						return messageType;
				}
			})
			.join(', ');
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: MessageType[]) => formattedValue(value),
		getValue: ({ cell }) => (
			<div className="text-monospace">
				{formattedValue(cell.getValue<MessageType[]>())}
			</div>
		)
	});
};
