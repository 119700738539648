import { RtAdminRouter } from '../rtAdmin.router';
import { RtAdmApiRoutes } from 'RtExports/routes';

export class SystemNotificationRouterClass extends RtAdminRouter<
	void,
	void,
	{}
> {
	constructor() {
		super('System Notifications', 'systemNotifications', undefined, {});

		this.setIndexPermissions(RtAdmApiRoutes.Firebase.Create.permissions);
		this.setProfilePermissions(RtAdmApiRoutes.Firebase.Create.permissions);
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const SystemNotificationRouter = new SystemNotificationRouterClass();
