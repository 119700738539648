import AddIcon from '@mui/icons-material/Add';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import RemoveIcon from '@mui/icons-material/Remove';
import { ButtonGroup, IconButton } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import clsx from 'clsx';
import {
	addHours,
	endOfDay,
	format,
	setHours,
	setMinutes,
	startOfDay,
	subHours
} from 'date-fns';
import { get, noop } from 'lodash-es';
import { useEffect, useState } from 'react';

//@ts-ignore
const timeRegex = /(?<hour>\d{2}):(?<minutes>\d{2})/g;
const TIME_FORMAT = 'HH:mm';

interface ITimeFormControlProps {
	value: string | undefined;
	onChange: (value: string) => void;
	className?: string;
	label: string;
}

export const TimeFormControl = ({
	label,
	value,
	className,
	onChange = noop
}: ITimeFormControlProps) => {
	const [internalValue, setInternalValue] = useState<Date>(new Date());

	useEffect(() => {
		const newValue = value && new RegExp(timeRegex).exec(value);
		if (newValue) {
			const hour = get(newValue.groups, 'hour');
			const minutes = get(newValue.groups, 'minutes');

			const initialDate = setHours(new Date(), Number(hour));
			setInternalValue(setMinutes(initialDate, Number(minutes)));
		}
	}, [value]);

	const onChangeHandler = (value: Date | null) => {
		const newValue = value ?? new Date();
		setInternalValue(newValue);
		onChange(format(newValue, TIME_FORMAT));
	};

	return (
		<div className={clsx('d-flex align-items-center', className)}>
			<TimePicker
				sx={{
					'.MuiInputBase-input': {
						padding: '5px 14px'
					}
				}}
				label={label}
				value={internalValue}
				onChange={onChangeHandler}
				format={TIME_FORMAT}
			/>
			<ButtonGroup>
				<IconButton onClick={() => onChangeHandler(startOfDay(internalValue))}>
					<FastRewindIcon />
				</IconButton>
				<IconButton onClick={() => onChangeHandler(endOfDay(internalValue))}>
					<FastForwardIcon />
				</IconButton>

				<IconButton onClick={() => onChangeHandler(subHours(internalValue, 1))}>
					<RemoveIcon />
				</IconButton>

				<IconButton onClick={() => onChangeHandler(addHours(internalValue, 1))}>
					<AddIcon />
				</IconButton>
			</ButtonGroup>
		</div>
	);
};

TimeFormControl.displayName = 'TimeFormControl';
