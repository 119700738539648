/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionBillingProfileResponse = {
    properties: {
        invoiceCycleGroupId: {
            type: 'number',
            isNullable: true,
        },
        timezoneId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        billDays: {
            type: 'number',
        },
        dueDays: {
            type: 'number',
        },
        disputeDays: {
            type: 'number',
        },
        disputeResponseDays: {
            type: 'number',
        },
        rateKeyDeleteDays: {
            type: 'number',
        },
        rateKeyMatchIncreaseDays: {
            type: 'number',
        },
        rateKeyExpandIncreaseDays: {
            type: 'number',
        },
        defaultInterstateRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIntrastateRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIndeterminateRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIncrementSeconds: {
            type: 'number',
            isRequired: true,
        },
        defaultMinimumSeconds: {
            type: 'number',
            isRequired: true,
        },
        ratingParametersPriority: {
            type: 'Array',
            isNullable: true,
        },
        defaultRoundingPrecision: {
            type: 'number',
        },
    },
};