import * as React from 'react';
import * as moment from 'moment-timezone';
import { TransactionResource } from 'RtUi/app/rtSip/Transactions/lib/resources/TransactionResource';
import { Transaction, AccountIndexResponse } from 'RtModels';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { Row, Col } from 'react-bootstrap';
import {
	TransactionTypeSelect,
	TRANSACTION_TYPES
} from 'RtUi/app/rtSip/Transactions/lib/controls/TransactionTypeSelect';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { ProfileReferenceControl } from 'RtUi/app/rtSip/Transactions/lib/controls/ReferenceControl';
import { cloneDeep, find } from 'lodash-es';

interface ITransactionEditorProps {
	profile?: Transaction;
	createMode?: boolean;
	onCreateSuccess?: (res: Transaction) => void;
}

interface ITransactionEditorState extends Transaction {
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
	startDate?: moment.Moment;
	endDate?: moment.Moment;
	transactionType?: ISimpleSelectFormControlOption<number>;
	account?: AccountIndexResponse;
	comments: string;
	amount: number;
	externalRef1: string;
	externalRef2: string;
}

export class TransactionEditor extends React.Component<
	ITransactionEditorProps,
	ITransactionEditorState
> {
	constructor(props: ITransactionEditorProps) {
		super(props);

		let transaction: Transaction = {
			accountId: 0,
			// @ts-ignore
			transactionType: undefined,
			amount: 0,
			transactionTime: new Date(),
			externalRef1: '',
			externalRef2: '',
			comments: ''
		};
		if (props.profile) {
			transaction = cloneDeep(props.profile);
		}

		const preparedState: any = {
			displayMode: Boolean(props.profile),
			isSubmitting: false,
			account: undefined,
			transactionType: find(TRANSACTION_TYPES, {
				value: transaction.transactionTypeId
			}),
			amount: transaction.amount,
			transactionTime: transaction.transactionTime,
			externalRef1: transaction.externalRef1,
			externalRef2: transaction.externalRef2,
			comments: transaction.comments
		};

		this.state = cloneDeep(preparedState);
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const {
				account,
				transactionType,
				amount,
				transactionTime,
				externalRef1,
				externalRef2,
				comments
			} = this.state;
			const transactionResource = new TransactionResource();

			if (this.props.createMode) {
				const { onCreateSuccess = () => ({}) } = this.props;
				const profile = await transactionResource.create({
					accountId: account!.accountId,
					transactionTypeId: transactionType!.value,
					amount: amount ?? 0,
					transactionTime,
					externalRef1,
					externalRef2,
					comments
				});

				onCreateSuccess(profile);
			}
			this.setState({ displayMode: true });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public getLinkLabel() {
		const transactionTypeLabel =
			find(TRANSACTION_TYPES, {
				value: this.props.profile?.transactionTypeId
			})?.label || '';
		return this.props.profile?.isReversal
			? transactionTypeLabel
			: 'Reversal of ' + transactionTypeLabel;
	}

	public render() {
		return (
			<RtUiNarrowForm
				onSubmit={(evt) => this.onSubmit(evt)}
				displayMode={!Boolean(this.props.createMode)}
				createMode={Boolean(this.props.createMode)}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				hideButtons={!this.props.createMode}
			>
				<Row>
					<Col md={6}>
						<AccountSelect<false>
							label="Account"
							value={this.state.account}
							onChange={(account) => this.setState({ account })}
							required
							displayMode={!Boolean(this.props.createMode)}
							initialOptionId={String(this.props.profile?.accountId)}
						/>
						<TransactionTypeSelect
							onChange={(
								transactionType: ISimpleSelectFormControlOption<number>
							) => this.setState({ transactionType })}
							value={this.state.transactionType}
							displayMode={!Boolean(this.props.createMode)}
						/>
						{this.props.profile?.reversedByTransactionId && (
							<ProfileReferenceControl
								label="Reversed by Transaction"
								profileId={this.props.profile?.reversedByTransactionId || 0}
								linkLabel={this.getLinkLabel()}
							/>
						)}
						{this.props.profile?.reversesTransactionId && (
							<ProfileReferenceControl
								label="Reversal of Transaction"
								profileId={this.props.profile?.reversesTransactionId || 0}
								linkLabel={this.getLinkLabel()}
							/>
						)}
						<InputFormControl
							label="Amount"
							onChange={(amount) => this.setState({ amount: Number(amount) })}
							value={String(this.state.amount)}
							type="number"
							displayMode={!Boolean(this.props.createMode)}
						/>
					</Col>
					<Col md={6}>
						<InputFormControl
							label="Reference 1"
							onChange={(externalRef1) => this.setState({ externalRef1 })}
							value={this.state.externalRef1}
							displayMode={!Boolean(this.props.createMode)}
						/>
						<InputFormControl
							label="Reference 2"
							onChange={(externalRef2) => this.setState({ externalRef2 })}
							value={this.state.externalRef2}
							displayMode={!Boolean(this.props.createMode)}
						/>
						<InputFormControl
							label="Comments"
							onChange={(comments) => this.setState({ comments })}
							value={this.state.comments}
							displayMode={!Boolean(this.props.createMode)}
						/>
					</Col>
				</Row>
			</RtUiNarrowForm>
		);
	}
}
