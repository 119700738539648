import { RtUiModule } from '../@RtUi/RtUiDecorators';
import { ManagedEntityRouter } from './ManagedEntities/ManagedEntity.router';
import { ManagedEntityIndexContainer } from './ManagedEntities/ManagedEntity.index';
import { ManagedEntityProfileContainer } from './ManagedEntities/ManagedEntity.profile';
import { ManagedRespOrgProfileContainer } from './ManagedRespOrgs/ManagedRespOrg.profile';
import { ManagedRespOrgRouter } from './ManagedRespOrgs/ManagedRespOrg.router';
import { ManagedRespOrgIndexContainer } from './ManagedRespOrgs/ManagedRespOrg.index';
import { RtAdminRouter } from './rtAdmin.router';
import { FlowProfileContainer } from 'RtUi/app/rtAdmin/Flows/Flow.profile';
import { FlowIndexContainer } from 'RtUi/app/rtAdmin/Flows/Flow.index';
import { FlowRouter } from 'RtUi/app/rtAdmin/Flows/Flow.router';
import { PartitionIndexContainer } from 'RtUi/app/rtAdmin/Partitions/Partition.index';
import { PartitionRouter } from 'RtUi/app/rtAdmin/Partitions/Partition.router';
import { PartitionProfileContainer } from 'RtUi/app/rtAdmin/Partitions/Partition.profile';
import { IntegrationErrorMessagesIndexContainer } from 'RtUi/app/rtAdmin/IntegrationErrorMessages/IntegrationErrorMessages.index';
import { IntegrationErrorMessagesRouter } from 'RtUi/app/rtAdmin/IntegrationErrorMessages/IntegrationErrorMessages.router';
import { SystemNotificationIndexContainer } from 'RtUi/app/rtAdmin/SystemNotifications/SystemNotification.index';
import { ServerRouter } from 'RtUi/app/rtAdmin/Servers/Server.router';
import { ServerIndexContainer } from 'RtUi/app/rtAdmin/Servers/Server.index';
import { ServerProfileContainer } from 'RtUi/app/rtAdmin/Servers/Server.profile';
import { ServerGroupRouter } from 'RtUi/app/rtAdmin/ServerGroups/ServerGroup.router';
import { ServerGroupIndexContainer } from 'RtUi/app/rtAdmin/ServerGroups/ServerGroup.index';
import { ServerGroupProfileContainer } from 'RtUi/app/rtAdmin/ServerGroups/ServerGroup.profile';
import { ServerGroupMembersIndexContainer } from 'RtUi/app/rtAdmin/ServerGroupMembers/ServerGroupMembers.index';
import { ServerGroupMembersProfileContainer } from 'RtUi/app/rtAdmin/ServerGroupMembers/ServerGroupMembers.profile';
import { ServerGroupMembersRouter } from 'RtUi/app/rtAdmin/ServerGroupMembers/ServerGroupMembers.router';

@RtUiModule({
	route: {
		path: `/${RtAdminRouter.SectionKey}`,
		iconClass: 'fa-user-secret',
		name: 'RT/Adm'
	},
	controllers: [
		FlowIndexContainer,
		FlowProfileContainer,
		ManagedEntityProfileContainer,
		ManagedEntityIndexContainer,
		ManagedRespOrgProfileContainer,
		ManagedRespOrgIndexContainer,
		PartitionIndexContainer,
		PartitionProfileContainer,
		IntegrationErrorMessagesIndexContainer,
		SystemNotificationIndexContainer,
		ServerIndexContainer,
		ServerProfileContainer,
		ServerGroupIndexContainer,
		ServerGroupProfileContainer,
		ServerGroupMembersIndexContainer,
		ServerGroupMembersProfileContainer
	],
	routers: [
		FlowRouter,
		ManagedEntityRouter,
		ManagedRespOrgRouter,
		PartitionRouter,
		IntegrationErrorMessagesRouter,
		ServerRouter,
		ServerGroupRouter,
		ServerGroupMembersRouter
	]
})
export class RtAdminModule {}
