import { Component } from 'react';
import { Aside } from 'RtUi/components/ui/Aside';
import { SubscriptionScheduleProfileResponse } from 'RtModels';
import { SubscriptionScheduleFormEditor } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionScheduleFormEditor';

interface IAddScheduleAsideProps {
	subscriptionId: number;
	isOpen: boolean;
	onCancel: () => void;
	onSave: () => void;
	profile?: SubscriptionScheduleProfileResponse;
}

interface IAddScheduleAsideState {}

export class AddScheduleAside extends Component<
	IAddScheduleAsideProps,
	IAddScheduleAsideState
> {
	public render() {
		return (
			<Aside isOpen={this.props.isOpen} disabledBody>
				<Aside.Header
					warning={Boolean(this.props.profile)}
					header={`${this.props.profile ? 'Edit' : 'Create'} Schedule`}
					onClose={() => this.props.onCancel()}
				/>
				<SubscriptionScheduleFormEditor
					profile={this.props.profile}
					subscriptionId={this.props.subscriptionId}
					onSuccess={this.props.onSave}
				/>
			</Aside>
		);
	}
}
