import { Rt800ApiRoutes } from 'RtExports/routes';
import { CprLergIndexRequest, CprLergIndexResponse } from 'RtModels';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2';

export class CprLergHttp {
	public async loadCprLerg() {
		if (CprLergCache.HasCacheBeenInitialized()) {
			return;
		}

		const fiveMillion = 5 * 1000000;
		const params: CprLergIndexRequest = { pageSize: fiveMillion };
		const cprLergRows = await HttpRequest.fetchWithRoute<
			CprLergIndexResponse[]
		>(Rt800ApiRoutes.CprLerg.Index, { params });

		await CprLergCache.Initialize(cprLergRows);
	}
}
