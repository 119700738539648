/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
	AccountIndexResponse,
	ConnectionIndexResponse,
	FileStreamIndexResponse,
	ReconciliationMappingDataSourceField,
	SubscriptionIndexResponse,
	SwitchIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { SwitchesSelect } from 'RtUi/app/AccountManagement/Switches/lib/controls/SwitchesSelect';
import {
	CallTypesSelect,
	ICallTypeSelectOption
} from 'RtUi/app/rtVue/Connections/lib/controls/CallTypesSelect';
import { VueConnectionSelect } from 'RtUi/app/rtVue/Connections/lib/controls/VueConnectionSelect';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import { TrunkGroupSelect } from 'RtUi/app/rtVue/Reconciliation/lib/components/TrunkGroupSelect';
import { AccountDataGridCache } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { CdrConnectionRenderCache } from 'RtUi/components/data/DataGrid/columns/CdrConnectionDataGridColumn';
import { FileStreamRenderCache } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { SubscriptionDataGridCache } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { GenericTypeRender } from 'RtUi/components/ui/GenericTypeRenderer';
import { FC, useEffect, useState } from 'react';
import { useMount } from 'react-use';

interface IGuardianReconciliationMappingFilterValueFacadeProps {
	type: ReconciliationMappingDataSourceField;
	dataSourceValue: string;
	onChange?: (newDataSourceValue: string) => void;
	textDisplayMode?: boolean;
}

export const GuardianReconciliationMappingFilterValueFacadeSelect: FC<
	IGuardianReconciliationMappingFilterValueFacadeProps
> = ({ type, dataSourceValue, onChange = () => ({}), textDisplayMode }) => {
	const [account, setAccount] = useState<AccountIndexResponse>();
	const [switchValue, setSwitch] = useState<SwitchIndexResponse>();
	const [vueConnection, setVueConnection] = useState<ConnectionIndexResponse>();
	const [fileStream, setFileStream] = useState<FileStreamIndexResponse>();
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [trunkGroup, setTrunkGroup] = useState<ConnectionIndexResponse>();
	const [callType, setCallType] = useState<ICallTypeSelectOption>();
	const [externalAccountId, setExternalAccountId] = useState<string>();

	useMount(() => {
		AccountDataGridCache.warmup();
		FileStreamRenderCache.warmup();
		SubscriptionDataGridCache.warmup();
		CdrConnectionRenderCache.warmup();
	});

	useEffect(() => {
		setAccount(undefined);
		setSwitch(undefined);
		setVueConnection(undefined);
		setFileStream(undefined);
		setSubscription(undefined);
		setTrunkGroup(undefined);
		setExternalAccountId('');
	}, [type]);

	const onChangeInternal = (newValue: string) => {
		if (dataSourceValue === newValue) {
			return;
		}

		onChange(newValue);
	};

	if (textDisplayMode) {
		switch (type) {
			case ReconciliationMappingDataSourceField.Account:
				return (
					<GenericTypeRender
						id={Number(dataSourceValue)}
						resourceCacheMap={AccountDataGridCache}
					/>
				);
			case ReconciliationMappingDataSourceField.FileStream:
				return (
					<GenericTypeRender
						id={Number(dataSourceValue)}
						resourceCacheMap={FileStreamRenderCache}
					/>
				);
			case ReconciliationMappingDataSourceField.Subscription:
				return (
					<GenericTypeRender
						id={Number(dataSourceValue)}
						resourceCacheMap={SubscriptionDataGridCache}
					/>
				);
			case ReconciliationMappingDataSourceField.Connection:
				return (
					<GenericTypeRender
						id={Number(dataSourceValue)}
						resourceCacheMap={CdrConnectionRenderCache}
					/>
				);
			// https://trello.com/c/1yzgk5Qz/994-rt-vue-mapping-created-edit-drop-down-changes
			// case ReconciliationMappingDataSourceField.Switch:
			// 	return <SwitchRender id={Number(dataSourceValue)} />;
			default:
				return <span>{dataSourceValue}</span>;
		}
	}

	return (
		<section style={{ display: textDisplayMode ? 'none' : undefined }}>
			{type === ReconciliationMappingDataSourceField.Account && (
				<AccountSelect
					onChange={(newAccount) => {
						onChangeInternal(newAccount.accountId.toString());
						setAccount(newAccount);
					}}
					value={account}
					initialOptionId={dataSourceValue}
				/>
			)}
			{type === ReconciliationMappingDataSourceField.Subscription && (
				<SubscriptionSelect
					onChange={(newSubscription) => {
						onChangeInternal(newSubscription.subscriptionId.toString());
						setSubscription(newSubscription);
					}}
					value={subscription}
					initialOptionId={Number(dataSourceValue)}
				/>
			)}
			{type === ReconciliationMappingDataSourceField.Switch && (
				<SwitchesSelect
					onChange={(newSwitch) => {
						onChangeInternal(newSwitch.switchId.toString());
						setSwitch(newSwitch);
					}}
					value={switchValue}
					initialOptionId={dataSourceValue}
				/>
			)}
			{type === ReconciliationMappingDataSourceField.Connection && (
				<VueConnectionSelect
					onChange={(newVueConnection) => {
						onChangeInternal(newVueConnection.connectionId.toString());
						setVueConnection(newVueConnection);
					}}
					value={vueConnection}
					initialOptionId={dataSourceValue}
				/>
			)}
			{type === ReconciliationMappingDataSourceField.TrunkGroup && (
				<TrunkGroupSelect
					onChange={(newTrunkGroup) => {
						onChangeInternal(newTrunkGroup.trunkGroupId.toString());
						setTrunkGroup(newTrunkGroup);
					}}
					value={trunkGroup}
					initialOptionId={dataSourceValue}
				/>
			)}
			{type === ReconciliationMappingDataSourceField.FileStream && (
				<FileStreamSelect
					onChange={(newFileStream) => {
						onChangeInternal(newFileStream.fileStreamId.toString());
						setFileStream(newFileStream);
					}}
					value={fileStream}
					initialOptionId={dataSourceValue}
				/>
			)}
			{type === ReconciliationMappingDataSourceField.CallType && (
				<CallTypesSelect
					onChange={(callType) => {
						onChangeInternal(callType.callTypeId.toString());
						setCallType(callType);
					}}
					value={callType}
					initialOptionId={dataSourceValue}
				/>
			)}
			{type === ReconciliationMappingDataSourceField.ExternalAccount && (
				<InputFormControl
					label="External Account Id"
					value={externalAccountId}
					onChange={(val) => {
						onChangeInternal(val);
						setExternalAccountId(val);
					}}
				/>
			)}
		</section>
	);
};
