import { RateLoadIndexRequest, RateLoadIndexResponse } from 'RtModels';
import { RateManagementRouter } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.router';
import { useGetRateLoads } from 'RtUi/app/AccountManagement/RateManagement/libs/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRateLoadsColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/rateLoads';
import { useMemo } from 'react';

interface IRateLoadGridProps {
	resourceParams?: RateLoadIndexRequest;
	autoFocusFilter?: boolean;
}

export const RateLoadDataGrid = ({ resourceParams }: IRateLoadGridProps) => {
	const { data, isFetching: isLoading } = useGetRateLoads(resourceParams);
	const columns = useMemo(() => getRateLoadsColumns(), []);

	return (
		<DataGrid<RateLoadIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'accountManagement_rateLoads'}
			router={RateManagementRouter}
		/>
	);
};
