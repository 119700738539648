import { ApplicationState, ApplicationStateType } from 'RtUi/state';
import { IUser } from 'RtUi/state/actions/user/interfaces';
import {
	UiNotification,
	IUiListenerContext,
	IUiNotificationListener
} from './lib/UiNotification';
import { ManagedEntityUiNotification } from 'RtUi/notifications/lib/notifications/ManagedEntityUiNotification';
import { ManagedRespOrgUiNotification } from 'RtUi/notifications/lib/notifications/ManagedRespOrgUIiNotification';
import { RoutingGroupAuditUiNotification } from 'RtUi/notifications/lib/notifications/RoutingGroupAuditUiNotification';
import { TemplateCprAuditUiNotification } from 'RtUi/notifications/lib/notifications/TemplateCprAuditUiNotification';
import { TollFreeCprAuditUiNotification } from 'RtUi/notifications/lib/notifications/TollFreeCprAuditUiNotification';
import { RocUiNotification } from 'RtUi/notifications/lib/notifications/RocUiNotification';
import {
	MessageEventType,
	MessageType,
	NotificationPreferenceIndexResponse,
	NotificationFrequency
} from 'RtModels';
import { MyProfileHttp } from 'RtUi/app/user/MyProfile/lib/Http/MyProfileHttp';
import { isEqual } from 'lodash-es';

/**
 * @singleton
 */
export class UiNotificationsManager {
	public static getInstance() {
		if (!UiNotificationsManager.instance) {
			UiNotificationsManager.instance = new UiNotificationsManager();
		}

		return UiNotificationsManager.instance;
	}

	private static instance: UiNotificationsManager;
	private static readonly ContextName = 'UiNotificationManager';

	private notifications: UiNotification[] = [];
	private notificationUpdateListenerRemoveFns: Array<() => void> = [];
	private lastUser: IUser | null = null;
	private myProfileHttp = new MyProfileHttp();
	private userNotifications: NotificationPreferenceIndexResponse[] = [];
	private userNotificationsDefer:
		| Promise<NotificationPreferenceIndexResponse[]>
		| undefined;

	private constructor() {
		//Refresh statistics when user obj has changed in AppState
		ApplicationState.listen(
			(appState) => this.onAppStateUpdate(appState),
			true,
			true
		);

		this.myProfileHttp.onNotificationsUpdate((userNotifications) => {
			this.userNotifications = userNotifications;

			this.updatePinning();
		});
	}

	/**
	 * Fetches and sets user notifications
	 */
	public async fetchUserNotifications() {
		try {
			this.userNotifications = await this.getAllUserNotifications();
		} catch {
			this.userNotifications = [];
		}

		this.refreshNotifications();
	}

	public getAllUserNotifications() {
		if (!this.userNotificationsDefer) {
			//catch errors and return empty array
			this.userNotificationsDefer = this.myProfileHttp
				.getAllNotifications()
				.catch(() => []);
		}

		return this.userNotificationsDefer;
	}

	public onAppStateUpdate(appState: ApplicationStateType) {
		const newUser = appState.get('user');

		if (!isEqual(this.lastUser, newUser)) {
			this.lastUser = newUser;

			this.fetchUserNotifications();
		}
	}

	public refreshNotifications() {
		this.notifications = [];

		//Remove previous listeners for notification updates
		for (const notificationUpdateListenerRemoveFn of this
			.notificationUpdateListenerRemoveFns) {
			notificationUpdateListenerRemoveFn();
		}

		const possibleNotifications: UiNotification[] = [
			new ManagedEntityUiNotification(),
			new ManagedRespOrgUiNotification(),
			new RoutingGroupAuditUiNotification(),
			new TemplateCprAuditUiNotification(),
			new TollFreeCprAuditUiNotification(),
			new RocUiNotification(MessageEventType.IncomingRespOrgChange),
			new RocUiNotification(MessageEventType.OutgoingRespOrgChange)
		];

		for (const possibleNotification of possibleNotifications) {
			const messageEventType = possibleNotification.getEventType();
			const userHasAccessToEventType =
				this.hasAccessToEventType(messageEventType);
			const userHasAccessToNotification =
				possibleNotification.userHasAccessToNotification();

			if (userHasAccessToNotification && userHasAccessToEventType) {
				const isPinned = this.isPinned(messageEventType);

				this.notifications.push(possibleNotification);

				possibleNotification.updateSetting('isPinned', isPinned);
				possibleNotification.primeIssueCount().then(() => {
					const notificationUpdateListener: IUiNotificationListener = (
						state,
						settings,
						context
					) => {
						const wasFiredFromUiManager =
							context?.source === UiNotificationsManager.ContextName;

						if (!wasFiredFromUiManager) {
							this.updateNotificationsPinning();
						}
					};

					const notificationUpdateListenerRemoveFn =
						possibleNotification.onUpdate(notificationUpdateListener, false);

					this.notificationUpdateListenerRemoveFns.push(
						notificationUpdateListenerRemoveFn
					);
				});
			}
		}
	}

	public async getNotifications(): Promise<UiNotification[]> {
		try {
			await this.getAllUserNotifications();
		} catch {
			/* Try to get User Notifications */
		}

		return [...this.notifications];
	}

	public hasUiNotificationByEventType(eventType: MessageEventType) {
		return (
			this.notifications.findIndex((n) => n.getEventType() === eventType) >= 0
		);
	}

	protected hasAccessToEventType(eventType: MessageEventType) {
		return this.userNotifications.some(
			(userNotification) => userNotification.messageEventTypeId === eventType
		);
	}

	protected isPinned(eventType: MessageEventType) {
		const userNotificationOfEventType =
			this.findUserNotificationByEventType(eventType);

		if (!userNotificationOfEventType) {
			return false;
		}

		const pinnedNotificationIndex =
			userNotificationOfEventType.notifications.findIndex(
				(n) => (n.messageTypeId = MessageType.Firebase)
			);

		return pinnedNotificationIndex >= 0;
	}

	protected findUserNotificationByEventType(eventType: MessageEventType) {
		return this.userNotifications.find(
			(userNotification) => userNotification.messageEventTypeId === eventType
		);
	}

	protected updateNotificationsPinning() {
		for (const notification of this.notifications) {
			const eventType = notification.getEventType();
			const userNotificationOfEventType =
				this.findUserNotificationByEventType(eventType);

			if (!userNotificationOfEventType) {
				return;
			}

			const isPinned = notification.isPinned();
			const pinnedNotificationIndex =
				userNotificationOfEventType.notifications.findIndex(
					(n) => n.messageTypeId === MessageType.Firebase
				);

			if (isPinned && pinnedNotificationIndex < 0) {
				this.myProfileHttp.addNotification({
					messageTypeId: MessageType.Firebase,
					messageEventTypeId: eventType,
					userNotificationFrequencyId: NotificationFrequency.Instant,
					customStartTime: null,
					customEndTime: null,
					scheduleId: null
				});
			} else if (!isPinned && pinnedNotificationIndex >= 0) {
				const notificationToDelete =
					userNotificationOfEventType.notifications[pinnedNotificationIndex];
				// Remove pinned notification
				this.myProfileHttp.deleteNotification({
					userNotificationId: notificationToDelete.userNotificationId
				});
			}
		}
	}

	private updatePinning() {
		for (const notification of this.notifications) {
			const eventType = notification.getEventType();
			const userNotification = this.findUserNotificationByEventType(eventType);

			if (!userNotification) {
				return;
			}

			const pinnedNotificationIndex = userNotification.notifications.findIndex(
				(n) => n.messageTypeId === MessageType.Firebase
			);
			const isPinned = pinnedNotificationIndex >= 0;
			const context: IUiListenerContext = {
				source: UiNotificationsManager.ContextName
			};

			notification.updateSetting('isPinned', isPinned, context);
		}
	}
}
