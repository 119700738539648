import { Col, Row, Button, Form } from 'react-bootstrap';
import {
	GuardianAlertHistoryIndexRequest,
	GuardianAlertIndexResponse,
	GuardianAlertTypeIndexResponse,
	TimeRangePresets
} from 'RtModels';
import { AlertHistoryRouter } from 'RtUi/app/rtGuardian/AlertHistory/AlertHistory.router';
import { AlertDashboard } from 'RtUi/app/rtGuardian/AlertHistory/lib/components/AlertDashboard';
import {
	AutoRefresh,
	shouldRefresh
} from 'RtUi/app/rtGuardian/AlertHistory/lib/components/AutoRefresh';
import { AlertHistoryGrid } from 'RtUi/app/rtGuardian/AlertHistory/lib/grids/AlertHistoryGrid';
import { AlertTypeSelect } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/AlertTypeSelect';
import { TimeRangePresetsToNameMapping } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IInitialResourceParams {
	startUpdatedTs: moment.Moment | undefined;
	endUpdatedTs: moment.Moment | undefined;
	alertType: GuardianAlertTypeIndexResponse | undefined;
	timeRangePreset: TimeRangePresets;
}

interface IAlertHistoryIndexContainerState
	extends ISearchResultsContainerState<GuardianAlertHistoryIndexRequest>,
		IInitialResourceParams {
	activeTab: string;
	alertRule?: GuardianAlertIndexResponse;
}

@AlertHistoryRouter.getIndexRtUiController({
	groupName: 'Alerts',
	orderPriority: 0,
	groupOrderPriority: 90
})
export class AlertHistoryIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IAlertHistoryIndexContainerState,
	GuardianAlertHistoryIndexRequest
> {
	public initParams: IInitialResourceParams = {
		timeRangePreset: TimeRangePresets.Week,
		startUpdatedTs: undefined,
		endUpdatedTs: undefined,
		alertType: undefined
	};

	public state: IAlertHistoryIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	public Tabs = AlertHistoryRouter.getProfileTabs();

	private intervalId: number | undefined;

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public componentDidMount() {
		this.intervalId = window.setInterval(
			() => {
				try {
					if (shouldRefresh('guardian-alert-history')) {
						this.setState({
							resourceParams: {
								...this.state.resourceParams
							}
						});
					}
				} catch (e) {
					console.error('Cannot get page refresh key from local storage.', e);
				}
			},
			15 * 60 * 1000
		); // 15 minutes
	}

	public componentWillUnmount() {
		clearInterval(this.intervalId);
	}

	public getResourceParams(): GuardianAlertHistoryIndexRequest {
		const { startUpdatedTs, endUpdatedTs, timeRangePreset, alertType } =
			this.state;
		const params: GuardianAlertHistoryIndexRequest = {};

		if (timeRangePreset === TimeRangePresets.Custom) {
			if (startUpdatedTs) {
				params.startUpdatedTs = startUpdatedTs.toDate();
			}

			if (endUpdatedTs) {
				params.endUpdatedTs = endUpdatedTs.toDate();
			}
		} else {
			params.timeRangePreset = timeRangePreset;
		}

		if (alertType) {
			params.alertType = alertType.alertTypeId;
		}

		return params;
	}

	public render() {
		return (
			<TabbedLayout
				rightOfHeader={() => (
					<article
						className="text-end"
						style={{ position: 'absolute', right: 0, top: 0 }}
					>
						<AutoRefresh pageName="guardian-alert-history" />
					</article>
				)}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
				router={AlertHistoryRouter}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<Form.Group className="d-flex align-items-center mb-3">
							<Form.Label className="mb-0">Preset</Form.Label>
							{Object.keys(TimeRangePresetsToNameMapping).map(
								// @ts-expect-error https://github.com/Microsoft/TypeScript/issues/28565
								(timeRangePreset: TimeRangePresets) => (
									<Button
										type="button"
										key={timeRangePreset}
										className="px-2 mx-1"
										variant={
											this.state.timeRangePreset === timeRangePreset
												? 'primary'
												: 'link'
										}
										onClick={() =>
											this.setState({
												timeRangePreset,
												startUpdatedTs: undefined,
												endUpdatedTs: undefined
											})
										}
									>
										<span>
											{TimeRangePresetsToNameMapping[timeRangePreset]}
										</span>
									</Button>
								)
							)}
						</Form.Group>
						<Row>
							<Col lg={6}>
								<DatetimeFormControl
									label="Start Date"
									disabled={
										this.state.timeRangePreset !== TimeRangePresets.Custom
									}
									value={this.state.startUpdatedTs}
									onChange={(startUpdatedTs) =>
										this.setState({
											startUpdatedTs
										})
									}
								/>
							</Col>
							<Col lg={6}>
								<DatetimeFormControl
									label="End Date"
									disabled={
										this.state.timeRangePreset !== TimeRangePresets.Custom
									}
									value={this.state.endUpdatedTs}
									onChange={(endUpdatedTs) => this.setState({ endUpdatedTs })}
								/>
							</Col>
						</Row>
						<AlertTypeSelect
							showSystemAlerts
							onChange={(alertType) => this.setState({ alertType })}
							value={this.state.alertType}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<AlertDashboard
						className="mb-3"
						resourceParams={this.state.resourceParams}
					/>
					<AlertHistoryGrid
						pageName="rtGuardian-alertHistory"
						resourceParams={this.state.resourceParams}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
