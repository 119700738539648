/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IdddSearchRequest = {
    properties: {
        iso3: {
            type: 'string',
            isRequired: true,
        },
        countryCode: {
            type: 'string',
            isRequired: true,
        },
        cityCode: {
            type: 'string',
        },
        digits: {
            type: 'string',
        },
        timeoutMs: {
            type: 'string',
        },
        onlyResourceIds: {
            type: 'string',
        },
        omitResourceIds: {
            type: 'string',
        },
        sortMethod: {
            type: 'number',
        },
        searchLimit: {
            type: 'number',
        },
    },
};