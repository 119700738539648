import { ITaskProfile } from 'RtUi/app/rt800/Tasks/Task.profile';
import {
	getIsBeforeScheduled,
	getIsNotCompleteAndAfterScheduled
} from 'RtUi/app/rt800/Tasks/lib/utils';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';

const getRefreshTimeoutInMilliseconds = (task: ITaskProfile) => {
	const { tfnQty } = task;
	const lgTfnQty = 5 * 1000; // 5,000
	const mdTfnQty = 1 * 1000; // 1,000

	if (tfnQty >= lgTfnQty) {
		return 60 * 1000; // 60 seconds
	} else if (tfnQty >= mdTfnQty) {
		return 30 * 1000; // 30 seconds
	}

	return 10 * 1000;
};

export const useRefetchTask = (
	task: ITaskProfile | undefined,
	refetch: () => any
) => {
	const [referenceKey, setReferenceKey] = useState<string>(generateUUID());

	useEffect(() => {
		if (!task) {
			return;
		}

		const isBeforeScheduled = getIsBeforeScheduled();
		const isNotCompleteAndAfterScheduled =
			getIsNotCompleteAndAfterScheduled(task);
		const totalExpectedActivationQty = task.hasActivations
			? task.tfnQty - task.errorQty
			: 0;
		const somosActivationsAreComplete =
			task.totalNumbersActivated >= totalExpectedActivationQty;

		if (isNotCompleteAndAfterScheduled || !somosActivationsAreComplete) {
			const refreshTimeoutInMilliseconds =
				getRefreshTimeoutInMilliseconds(task);

			const timeout = setTimeout(() => {
				setReferenceKey(generateUUID());
				refetch();
			}, refreshTimeoutInMilliseconds);

			return () => clearTimeout(timeout);
		}

		if (!isBeforeScheduled) {
			return;
		}

		const scheduledAt = moment(task.scheduledAt);
		const now = moment();
		const differenceInMs = Math.abs(now.diff(scheduledAt));
		const refreshMs = Math.max(differenceInMs, 500);
		const refreshDuration = moment.duration(refreshMs, 'milliseconds');

		//Only set timeout if it is within a day,
		//otherwise large numbers immediately set it off
		if (refreshDuration.asDays() <= 1) {
			//Refresh once scheduledAt time hits
			const timeout = setTimeout(() => {
				setReferenceKey(generateUUID());
				refetch();
			}, refreshMs);

			return () => clearTimeout(timeout);
		}
	}, [task, refetch, referenceKey]);

	return referenceKey;
};
