import { RtAdminRouter } from '../rtAdmin.router';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { PartitionIndexResponse } from 'RtModels';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IPartitionRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	PartitionUrls: TabbedLayoutTabProps;
	PartitionSipGateways: TabbedLayoutTabProps;
	PartitionEntities: TabbedLayoutTabProps;
	PartitionRespOrgs: TabbedLayoutTabProps;
	CreatePartitionRespOrgs: TabbedLayoutTabProps;
	PartitionBillingEntities: TabbedLayoutTabProps;
	CreatePartitionUrl: TabbedLayoutTabProps;
	CreatePartitionSipGateway: TabbedLayoutTabProps;
	CreatePartitionEntity: TabbedLayoutTabProps;
	CreatePartitionBillingEntity: TabbedLayoutTabProps;
	CreatePartitionRatePlan: TabbedLayoutTabProps;
}

const PartitionContainerTabs: IPartitionRouterTabs = {
	Profile: {
		header: 'Profile'
	},
	PartitionUrls: {
		header: 'URLs'
	},
	PartitionSipGateways: {
		header: 'SIP Gateways'
	},
	PartitionRespOrgs: {
		header: 'RespOrgs'
	},
	PartitionEntities: {
		header: 'Entities'
	},
	CreatePartitionRespOrgs: {
		header: 'Create Partition RespOrg',
		isAction: true
	},
	CreatePartitionUrl: {
		header: 'Create Partition Url',
		isAction: true
	},
	CreatePartitionSipGateway: {
		header: 'Create Partition SIP Gateway',
		isAction: true
	},
	PartitionBillingEntities: {
		header: 'Billing Entities'
	},
	CreatePartitionEntity: {
		header: 'Create Partition Entity',
		isAction: true
	},
	CreatePartitionBillingEntity: {
		header: 'Create Partition Billing Entity',
		isAction: true
	},
	CreatePartitionRatePlan: {
		header: 'Create Partition Rate Plan',
		isAction: true
	}
};

export class PartitionRouterClass extends RtAdminRouter<
	PartitionIndexResponse,
	PartitionIndexResponse,
	IPartitionRouterTabs
> {
	constructor() {
		super('Partitions', 'partitions', 'partitionId', PartitionContainerTabs);

		this.setPermissionsFromApiRoute(RtAdmApiRoutes.Partitions);
	}

	public getProfileLabel(partition: PartitionIndexResponse): string {
		return partition.summary;
	}
}

export const PartitionRouter = new PartitionRouterClass();
