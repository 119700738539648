import { PromptIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getPromptSegmentColumns = (): Array<
	DataGridColumn<PromptIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'promptId'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'script',
		header: 'Script'
	})
];
