/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RateLoadSummaryResponse = {
    properties: {
        interIncreaseQty: {
            type: 'number',
            isRequired: true,
        },
        interDecreaseQty: {
            type: 'number',
            isRequired: true,
        },
        intraIncreaseQty: {
            type: 'number',
            isRequired: true,
        },
        intraDecreaseQty: {
            type: 'number',
            isRequired: true,
        },
        indetIncreaseQty: {
            type: 'number',
            isRequired: true,
        },
        indetDecreaseQty: {
            type: 'number',
            isRequired: true,
        },
        addQty: {
            type: 'number',
            isRequired: true,
        },
        delQty: {
            type: 'number',
            isRequired: true,
        },
        totalQty: {
            type: 'number',
            isRequired: true,
        },
        exceptions: {
            type: 'Array',
            isRequired: true,
        },
    },
};