import { isEqual } from 'lodash-es';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	EntityIndexResponse,
	RespOrgIndexRequest,
	RespOrgIndexResponse
} from 'RtModels';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { useFormInitializer } from 'RtUi/components/rtx/form/hooks/useFormInitializer';
import { RtxUiSearchFormComponent } from 'RtUi/components/rtx/form/RtxUiSearchForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { RespOrgSelect } from './lib/controls/RespOrgSelect';
import { RespOrgGrid } from './lib/grids/RespOrgGrid';
import { RespOrgRouter } from './RespOrg.router';

export const RespOrgIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true }
	});
	const formInitializer = useFormInitializer();
	const [entity, setEntity] = useState<EntityIndexResponse>();
	const [respOrg, setRespOrg] = useState<RespOrgIndexResponse>();
	const [resourceParams, setResourceParams] = useState<RespOrgIndexRequest>({
		isActive: 1,
		isManaged: 1
	});

	const submitHandler = (data: RespOrgIndexRequest) => {
		setResourceParams(data);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={RespOrgRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<Row>
					<Col md={8}>
						<RtxUiSearchFormComponent<RespOrgIndexRequest>
							initializer={formInitializer}
							onSubmit={submitHandler}
							defaultValues={resourceParams}
							onLoadUrlParams={(urlParams) => {
								if (!isEqual(urlParams, resourceParams)) {
									submitHandler(urlParams);
								}
							}}
						>
							{({ control, watch }) => (
								<>
									<Controller
										control={control}
										name="entityId"
										render={({ field: { onChange, value } }) => (
											<EntitySelect
												onChange={(entity) => {
													setEntity(entity);
													onChange(entity?.entityId);
												}}
												value={entity}
												initialOptionId={value}
											/>
										)}
									/>
									<Controller
										control={control}
										name="respOrgId"
										render={({ field: { onChange, value } }) => {
											const isActive = watch('isActive');

											return (
												<RespOrgSelect
													allowAll
													isActive={isActive === 1}
													onChange={(respOrg) => {
														setRespOrg(respOrg);
														onChange(respOrg?.respOrgId);
													}}
													value={respOrg}
													initialOptionId={value}
												/>
											);
										}}
									/>
									<Controller
										control={control}
										name="isActive"
										render={({ field: { onChange, value } }) => (
											<IsActiveRadioFormControl
												label="Status"
												onChange={onChange}
												value={value}
											/>
										)}
									/>
									<Controller
										control={control}
										name="isManaged"
										render={({ field: { onChange, value } }) => (
											<RadioFormControl<number | undefined>
												label="Managed by RouteTrust"
												onChange={onChange}
												value={value}
												options={[
													{ value: 1, label: 'Active' },
													{ value: 0, label: 'Inactive' },
													{ value: undefined, label: 'Both' }
												]}
											/>
										)}
									/>
								</>
							)}
						</RtxUiSearchFormComponent>
					</Col>
				</Row>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<RespOrgGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

RespOrgRouter.setIndexRtUiFunctionalComponent(RespOrgIndexContainer, {
	description:
		'Responsible Organizations (RespOrgs), responsible for managing Toll-Free numbers.'
});
