import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	EmailAliasIndexResponse,
	EmailAliasUpdateRequest,
	EmailAliasProfileUrlRequest,
	EmailAliasProfileResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class EmailAliasResource extends HttpResource<
	EmailAliasIndexResponse,
	EmailAliasIndexResponse
> {
	constructor() {
		super(RtCommonApiRoutes.EmailAlias);
	}

	public create(label: string, emailAddress: string) {
		const req: EmailAliasUpdateRequest = { label, emailAddress };
		const body = JSON.stringify(req);

		return this.fetchWithRoute<EmailAliasProfileResponse>(
			RtCommonApiRoutes.EmailAlias.Create,
			{ body }
		);
	}

	public update(aliasId: number, label: string, emailAddress: string) {
		const req: EmailAliasUpdateRequest = { label, emailAddress };
		const body = JSON.stringify(req);
		const urlParams: EmailAliasProfileUrlRequest = { aliasId };

		return this.fetchWithRoute<EmailAliasProfileResponse>(
			RtCommonApiRoutes.EmailAlias.Update,
			{ body, urlParams }
		);
	}
}
