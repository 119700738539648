/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $QosManagementIndexRequest = {
    properties: {
        accountIds: {
            type: 'Array',
        },
        subscriptionIds: {
            type: 'Array',
        },
        routePlanIds: {
            type: 'Array',
        },
        serviceIds: {
            type: 'Array',
        },
        countryIsoIds: {
            type: 'Array',
        },
        rateCenterIds: {
            type: 'Array',
        },
        rateCenterTypeIds: {
            type: 'Array',
        },
    },
};