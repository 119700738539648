import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationMappingFiltersIndexResponse,
	GuardianReconciliationMappingFiltersProfileResponse
} from 'RtModels';
import { GuardianReconciliationsRouter } from 'RtUi/app/rtVue/GuardianReconciliation/GuardianReconciliation.router';
import {
	GuardianReconciliationMappingProfileContainerTabs,
	GuardianReconciliationMappingsRouter
} from 'RtUi/app/rtVue/GuardianReconciliationMapping/GuardianReconciliationMapping.router';
import { GuardianReconciliationMappingResource } from 'RtUi/app/rtVue/GuardianReconciliationMapping/lib/resources/GuardianReconciliationMappingResource';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import { IRouterBreadcrumb } from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IGuardianReconciliationMappingFilterProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const GuardianReconciliationMappingFilterProfileContainerTabs: IGuardianReconciliationMappingFilterProfileContainerTabs =
	{
		Profile: {
			header: 'Profile'
		}
	};

export class GuardianReconciliationMappingFiltersRouterClass extends RtVueModuleRouter<
	GuardianReconciliationMappingFiltersIndexResponse,
	GuardianReconciliationMappingFiltersProfileResponse,
	IGuardianReconciliationMappingFilterProfileContainerTabs
> {
	constructor() {
		super(
			'Reconciliation Mapping Filter',
			'reconciliationMappingFilter',
			'guardianReconciliationMappingFilterId',
			GuardianReconciliationMappingFilterProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(
			RtVueApiRoutes.GuardianReconciliationMappingFilters
		);
	}

	public getIndexLabel(
		record: GuardianReconciliationMappingFiltersIndexResponse
	) {
		return String(record.guardianReconciliationMappingFilterId);
	}

	public getProfileLabel(
		profile: GuardianReconciliationMappingFiltersProfileResponse
	) {
		return String(profile.guardianReconciliationMappingFilterId);
	}

	public getProfileRouteWithParamNames(prefixSectionKey?: boolean) {
		return this.getProfileRouteWithIds(
			':guardianReconciliationMappingId',
			':id',
			undefined,
			prefixSectionKey
		);
	}

	public getProfileRouteWithIds(
		guardianReconciliationMappingId:
			| number
			| string = ':guardianReconciliationMappingId',
		guardianReconciliationMappingFilterId: number | string = ':id',
		tabId?: string,
		prefixSectionKey = true
	) {
		const profileRoute = super.getIndexRoute(prefixSectionKey);

		return this.getRouteWithTab(
			`${profileRoute}/${guardianReconciliationMappingId}/${guardianReconciliationMappingFilterId}`,
			tabId
		);
	}

	public getProfileRoute(
		record: GuardianReconciliationMappingFiltersIndexResponse,
		tabId?: string,
		prefixSectionKey = true
	) {
		return this.getProfileRouteWithIds(
			record.guardianReconciliationMappingId,
			record.guardianReconciliationMappingFilterId,
			tabId,
			prefixSectionKey
		);
	}

	public getIndexRoute(
		prefixSectionKey = true,
		tabId?: string,
		guardianReconciliationMappingId?: number
	) {
		if (!guardianReconciliationMappingId) {
			guardianReconciliationMappingId =
				this.getGuardianReconciliationMappingIdFromCurrentPath();

			if (!guardianReconciliationMappingId) {
				throw new Error('guardianReconciliationMappingId is required!');
			}
		}

		if (!tabId) {
			tabId = GuardianReconciliationMappingProfileContainerTabs.Results.header;
		}

		return GuardianReconciliationsRouter.getProfileRoute(
			guardianReconciliationMappingId,
			tabId,
			prefixSectionKey
		);
	}

	/**
	 * @override
	 */
	public async getBreadcrumbs(
		currentPath: string,
		profile?: GuardianReconciliationMappingFiltersProfileResponse
	): Promise<IRouterBreadcrumb[]> {
		const guardianReconciliationMappingId =
			this.getGuardianReconciliationMappingIdFromCurrentPath();
		const reconMappingResource = new GuardianReconciliationMappingResource();
		const reconMappingProfile = await reconMappingResource.get(
			guardianReconciliationMappingId
		);

		const breadcrumbs = [
			this.getSectionBreadcrumb(),
			GuardianReconciliationMappingsRouter.getIndexBreadcrumb(),
			GuardianReconciliationMappingsRouter.getProfileBreadcrumb(
				String(guardianReconciliationMappingId),
				reconMappingProfile,
				false,
				GuardianReconciliationMappingProfileContainerTabs.Results.header
			),
			this.getProfileBreadcrumb(
				String(profile?.guardianReconciliationMappingFilterId),
				profile,
				true
			)
		];

		return breadcrumbs;
	}

	protected getGuardianReconciliationMappingIdFromCurrentPath() {
		const currentPath = location.pathname;
		const profilePath = this.getProfileMatch<{
			id: string;
			guardianReconciliationMappingId: string;
		}>(currentPath);
		let guardianReconciliationMappingId: number | undefined;

		if (profilePath) {
			const {
				guardianReconciliationMappingId: guardianReconciliationMappingIdStr
			} = profilePath.params;
			guardianReconciliationMappingId = Number(
				guardianReconciliationMappingIdStr
			);
		}

		return guardianReconciliationMappingId;
	}
}

//IMPORTANT -- must add router to ApplicationRouters
export const GuardianReconciliationMappingFiltersRouter =
	new GuardianReconciliationMappingFiltersRouterClass();
