import { RoutingRulesMatchTypesIndexResponse } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class RoutingRulesMatchTypeResource extends ArrayResource<RoutingRulesMatchTypesIndexResponse> {
	constructor() {
		super('routingRuleMatchTypeId');

		this.setApiRouteForGetAll(RtxSipApiRoutes.RoutingRulesMatchTypes.Index);
	}
}
