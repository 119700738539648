/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum DashboardSectionName {
    Admin = 'Admin',
    Rt800 = 'RT/800',
    RtVue = 'RT/Vue',
    CarrierConnect = 'CarrierConnect',
}