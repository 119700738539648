import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class MonthlyTrending extends RtVueReportRouter<void, void, {}> {
	constructor() {
		//cspell:disable-next-line
		super('Monthly Trending', 'monthlytrending', undefined, {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewMonthlyTrending);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const MonthlyTrendingRouter = new MonthlyTrending();
