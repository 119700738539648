import * as React from 'react';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { Aside } from '.';

export const UncontrolledAsideContext = React.createContext({
	onClose: (): void => undefined
});

interface IUncontrolledAsideProps {
	initOpen?: boolean;
	disabledBody?: boolean;
	header?: string;
	autoClose?: boolean;
	fullscreen?: boolean;
}

interface IUncontrolledAsideState {
	isOpen: boolean;
}

export class UncontrolledAside extends React.Component<
	React.PropsWithChildren<IUncontrolledAsideProps>,
	IUncontrolledAsideState
> {
	public state: IUncontrolledAsideState = {
		isOpen: false
	};

	public removeBrowserListenFn: () => void = () => ({});

	public componentDidMount() {
		if (this.props.initOpen) {
			this.setState({ isOpen: true });
		}

		this.removeBrowserListenFn = BrowserHistory.listen(this.close);
	}

	public componentWillUnmount() {
		this.removeBrowserListenFn();
	}

	public onClickedOutside() {
		const { autoClose } = this.props;

		if (!autoClose) {
			return;
		}

		this.setState({ isOpen: false });
	}

	public render() {
		return (
			<Aside
				isOpen={this.state.isOpen}
				disabledBody={this.props.disabledBody}
				fullscreen={this.props.fullscreen}
				onClickOutside={() => this.onClickedOutside()}
			>
				<UncontrolledAsideContext.Provider
					value={{
						onClose: () => {
							this.setState({ isOpen: false });
						}
					}}
				>
					{this.props.children}
				</UncontrolledAsideContext.Provider>
			</Aside>
		);
	}

	private close = () => {
		this.setState({ isOpen: false });
	};
}
