import { Rt800ApiRoutes } from 'RtExports/routes';
import { TemplateIndexRequest, TemplateIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchTemplates = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: TemplateIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<TemplateIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(Rt800ApiRoutes.Templates.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetTemplates = (queryParams?: TemplateIndexRequest) => {
	return useQuery<FullResponse<TemplateIndexResponse[]>, Error>(
		[`useGetTemplates`],
		() => fetchTemplates(queryParams, true),
		{ enabled: true }
	);
};
