import { ActivePortAccountIndexResponse } from 'RtModels';
import { useGetActivePortsDetail } from 'RtUi/app/rtSip/ActivePortsDetails/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getActivePortsDetailColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/activePortsDetails';
import { useMemo } from 'react';

interface IActivePortsDetailGridProps {
	autoFocusFilter?: boolean;
}

export const ActivePortsDetailDataGrid = ({}: IActivePortsDetailGridProps) => {
	const { data, isFetching: isLoading } = useGetActivePortsDetail();
	const columns = useMemo(() => getActivePortsDetailColumns(), []);

	return (
		<DataGrid<ActivePortAccountIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_activePortsDetail'}
		/>
	);
};
