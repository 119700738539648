import { BirdsEyeIndexResponse, TimeRange } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import moment from 'moment';

const getDaysColumns = (
	timeRange: TimeRange,
	gridType: string
): Array<DataGridColumn<BirdsEyeIndexResponse>> => {
	let columns: Array<DataGridColumn<BirdsEyeIndexResponse>> = [];
	const startDate = moment(timeRange.start).startOf('day').tz('UTC');
	const endDate = moment(timeRange.end).startOf('day').tz('UTC');

	const isEndDateToday = endDate.isSame(moment(), 'day');
	const dayRange = endDate.diff(startDate, 'days') - Number(isEndDateToday);

	for (let i = 0; i <= dayRange; i++) {
		const dayIndex = i + 1;
		let currentDay = i;

		if (isEndDateToday) {
			currentDay += 1;
		}

		const currentDate = moment(endDate)
			.subtract(currentDay, 'days')
			.tz('UTC')
			.format('MM/DD/YYYY');

		columns = columns.concat([
			IntegerDataGridColumn({
				accessorKey: `day${dayIndex}Duration` as keyof BirdsEyeIndexResponse,
				header: `${currentDate} Duration`,
				isHidden: true,
				hiddenIfEmpty: true
			}),
			PrecisionDataGridColumn({
				accessorKey: `day${dayIndex}Value` as keyof BirdsEyeIndexResponse,
				header: `${currentDate} ${gridType === 'vendor' ? 'Cost' : 'Revenue'}`,
				isHidden: gridType === 'customer',
				hiddenIfEmpty: true
			}),
			PrecisionDataGridColumn({
				accessorKey: `day${dayIndex}Margin` as keyof BirdsEyeIndexResponse,
				header: `${currentDate} Margin`,
				isHidden: gridType === 'vendor',
				hiddenIfEmpty: true
			})
		]);
	}

	return columns;
};

export const getBirdEyeColumns = (
	timeRange: TimeRange,
	onClickSubscriptionColumn: (row: BirdsEyeIndexResponse) => void,
	gridType: string
): Array<DataGridColumn<BirdsEyeIndexResponse>> => [
	AccountDataGridColumn({
		accessorKey: 'accountId',
		header: 'Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription',
		hiddenIfEmpty: true,
		onDrillDown: (e, row) => {
			e.preventDefault();
			onClickSubscriptionColumn(row);
		}
	}),
	IntegerDataGridColumn({
		accessorKey: 'externalAccountId',
		header: 'External Account Id',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'externalSubAccountId',
		header: 'External Sub Account Id',
		hiddenIfEmpty: true
	}),
	...getDaysColumns(timeRange, gridType)
];
