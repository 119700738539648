import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationUsageDetailsCreateRequest,
	GuardianReconciliationUsageDetailsIndexResponse,
	GuardianReconciliationUsageDetailsProfileResponse,
	GuardianReconciliationUsageDetailsProfileUrlRequest,
	GuardianReconciliationUsageDetailsUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ReconciliationUsageResource extends HttpResource<
	GuardianReconciliationUsageDetailsIndexResponse,
	GuardianReconciliationUsageDetailsProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianReconciliationUsageDetails);
	}

	public create(request: GuardianReconciliationUsageDetailsCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianReconciliationUsageDetailsProfileResponse>(
			RtVueApiRoutes.GuardianReconciliationUsageDetails.Create,
			{
				body
			}
		);
	}

	public update(
		guardianReconciliationUsageDetailId: number,
		request: GuardianReconciliationUsageDetailsUpdateRequest
	) {
		const body = JSON.stringify(request);
		const urlParams: GuardianReconciliationUsageDetailsProfileUrlRequest = {
			guardianReconciliationUsageDetailId
		};

		return this.fetchWithRoute<GuardianReconciliationUsageDetailsProfileResponse>(
			RtVueApiRoutes.GuardianReconciliationUsageDetails.Update,
			{
				body,
				urlParams
			}
		);
	}

	public createNewProfile(
		reconciliationId: number
	): GuardianReconciliationUsageDetailsProfileResponse {
		return {
			guardianReconciliationUsageDetailId: -1,
			reconciliationId,
			mappingId: 0,
			serviceTypeId: -1,
			carrierInvoiceConnects: null,
			carrierInvoiceDuration: null,
			carrierInvoiceCharge: null,
			rtSrcConnects: null,
			rtSrcDuration: null,
			rtSrcCharge: null,
			customerExpectedConnects: null,
			customerExpectedDuration: null,
			customerExpectedCharge: null,
			rtTgtConnects: null,
			rtTgtDuration: null,
			rtTgtCharge: null,
			disputedAmount: null,
			approvedAmount: null,
			disputeStatus: null,
			disputeId: null,
			disputeFiledTs: null,
			disputeSettlementTs: null,
			assignedTo: null,
			systemGenerated: 1,
			createdBy: -1,
			createdTs: null,
			updatedBy: -1,
			updateTs: null,
			disputeReceivedTs: null,
			escalationTs: null,
			guardianReconciliationStartTs: new Date()
		};
	}
}
