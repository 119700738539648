import { EntityIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { EntityResource } from '../resources/EntityResource';
import { EntityRouter } from 'RtUi/app/rt800/Entities/Entity.router';

interface IEntitySelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<EntityIndexResponse, isMulti> {
	allowAll?: boolean;
	isActive?: boolean | null;
}

export class EntitySelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	EntityIndexResponse,
	isMulti,
	IEntitySelectProps<isMulti>
> {
	public static defaultProps = {
		router: EntityRouter
	};
	public resourceClass = EntityResource;
	public state: ISelectFormControlState<EntityIndexResponse> = {
		formLabel: 'Entity',
		valueKey: 'entityId',
		labelKey: 'label'
	};

	public componentDidMount() {
		this.updateGetAllParams();

		super.componentDidMount();
	}

	public componentDidUpdate(prevProps: IEntitySelectProps<isMulti>) {
		this.updateGetAllParams();

		super.componentDidUpdate(prevProps);
	}

	public optionRenderer = (entity: EntityIndexResponse) => (
		<span>
			{entity.entityId} ({entity.label})
		</span>
	);

	public updateGetAllParams(props = this.props) {
		const { allowAll = false, isActive = true } = props;
		const getAllParams: any = {};

		if (isActive) {
			getAllParams.isActive = 1;
		} else if (isActive === false) {
			getAllParams.isActive = 0;
		}

		if (!allowAll) {
			getAllParams.isManaged = 1;
		}

		this.setGetAllParams(getAllParams);
	}

	public render() {
		const hasPermissions = EntityRouter.hasAccessToIndex();

		if (!hasPermissions) {
			return null;
		}

		return super.render();
	}
}
