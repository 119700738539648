/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipCdrSearchProfileResponse = {
    properties: {
        cdrId: {
            type: 'string',
            isRequired: true,
        },
        invite: {
            type: 'string',
            isRequired: true,
        },
        inDur: {
            type: 'string',
            isRequired: true,
        },
        egDur: {
            type: 'string',
            isRequired: true,
        },
        cp: {
            type: 'string',
            isRequired: true,
        },
        cpStd: {
            type: 'string',
            isRequired: true,
        },
        cpLrn: {
            type: 'string',
            isRequired: true,
        },
        cpIso: {
            type: 'string',
            isRequired: true,
        },
        dn: {
            type: 'string',
            isRequired: true,
        },
        dnStd: {
            type: 'string',
            isRequired: true,
        },
        dnLrn: {
            type: 'string',
            isRequired: true,
        },
        dnIso: {
            type: 'string',
            isRequired: true,
        },
        erci: {
            type: 'string',
            isRequired: true,
        },
        ercs: {
            type: 'string',
            isRequired: true,
        },
        ercid: {
            type: 'string',
            isRequired: true,
        },
        iReleaseCodeId: {
            type: 'number',
            isRequired: true,
        },
        eReleaseCodeId: {
            type: 'number',
            isRequired: true,
        },
        erj: {
            type: 'string',
            isRequired: true,
        },
        ers: {
            type: 'string',
            isRequired: true,
        },
        erss: {
            type: 'string',
            isRequired: true,
        },
        irci: {
            type: 'string',
            isRequired: true,
        },
        iress: {
            type: 'string',
            isRequired: true,
        },
        err: {
            type: 'string',
            isRequired: true,
        },
        eccid: {
            type: 'string',
            isRequired: true,
        },
        ecj: {
            type: 'string',
            isRequired: true,
        },
        ecs: {
            type: 'string',
            isRequired: true,
        },
        ecss: {
            type: 'string',
            isRequired: true,
        },
        eres: {
            type: 'number',
            isRequired: true,
        },
        ecr: {
            type: 'string',
            isRequired: true,
        },
        erd: {
            type: 'number',
            isRequired: true,
        },
        ecd: {
            type: 'string',
            isRequired: true,
        },
        ertc: {
            type: 'number',
            isRequired: true,
        },
        ectc: {
            type: 'string',
            isRequired: true,
        },
        mgn: {
            type: 'string',
            isRequired: true,
        },
        iRevenueCarrierId: {
            type: 'number',
            isRequired: true,
        },
        iRevenueSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        iRevenueJurisdictionId: {
            type: 'number',
            isRequired: true,
        },
        iRevenueDuration: {
            type: 'number',
            isRequired: true,
        },
        iRevenueRateKey: {
            type: 'string',
            isRequired: true,
        },
        iRevenueRate: {
            type: 'number',
            isRequired: true,
        },
        iRevenueRateCharge: {
            type: 'number',
            isRequired: true,
        },
        iCostCarrierId: {
            type: 'number',
            isRequired: true,
        },
        iCostSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        iCostJurisdictionId: {
            type: 'number',
            isRequired: true,
        },
        iCostDuration: {
            type: 'number',
            isRequired: true,
        },
        iCostRateKey: {
            type: 'string',
            isRequired: true,
        },
        iCostRate: {
            type: 'number',
            isRequired: true,
        },
        iCostRateCharge: {
            type: 'number',
            isRequired: true,
        },
        eRevenueCarrierId: {
            type: 'number',
            isRequired: true,
        },
        eRevenueSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        eRevenueJurisdictionId: {
            type: 'number',
            isRequired: true,
        },
        eRevenueDuration: {
            type: 'number',
            isRequired: true,
        },
        eRevenueRateKey: {
            type: 'string',
            isRequired: true,
        },
        eRevenueRate: {
            type: 'number',
            isRequired: true,
        },
        eRevenueRateCharge: {
            type: 'number',
            isRequired: true,
        },
        eCostCarrierId: {
            type: 'number',
            isRequired: true,
        },
        eCostSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        eCostJurisdictionId: {
            type: 'number',
            isRequired: true,
        },
        eCostDuration: {
            type: 'number',
            isRequired: true,
        },
        eCostRateKey: {
            type: 'string',
            isRequired: true,
        },
        eCostRate: {
            type: 'number',
            isRequired: true,
        },
        eCostRateCharge: {
            type: 'number',
            isRequired: true,
        },
        iGatewayId: {
            type: 'number',
            isRequired: true,
        },
        iPeerId: {
            type: 'number',
            isRequired: true,
        },
        iRouteId: {
            type: 'number',
            isRequired: true,
        },
        iInviteTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        iAnswerTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        iReleaseTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        iDuration: {
            type: 'number',
            isRequired: true,
        },
        iCallId: {
            type: 'string',
            isRequired: true,
        },
        iResult: {
            type: 'CallProcessingResults',
        },
        eGatewayId: {
            type: 'number',
            isRequired: true,
        },
        ePeerId: {
            type: 'number',
            isRequired: true,
        },
        eRouteId: {
            type: 'number',
            isRequired: true,
        },
        eInviteTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        eAnswerTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        eReleaseTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        eDuration: {
            type: 'number',
            isRequired: true,
        },
        eCallId: {
            type: 'string',
            isRequired: true,
        },
        eResult: {
            type: 'CallProcessingResults',
        },
        routeAttempts: {
            type: 'Array',
            isRequired: true,
        },
        networkId: {
            type: 'number',
            isRequired: true,
        },
        serverId: {
            type: 'number',
            isRequired: true,
        },
        serverName: {
            type: 'string',
            isRequired: true,
        },
        mediaId: {
            type: 'string',
            isRequired: true,
        },
    },
};