import {
	CprsRouterClass,
	TollFreeCprsRouter
} from 'RtUi/app/rt800/Cprs/Cprs.router';
import { CprActions } from 'RtUi/app/rt800/Cprs/lib/Constants';
import { CprProfileResponse } from 'RtUi/app/rt800/Cprs/lib/components/CprProfileContainer';
import { CprIndexResponse } from 'RtUi/app/rt800/Cprs/lib/grids/CprGrid';
import { FullResponse } from 'RtUi/utils/http/AxiosHttpRequest';
import { Cpr, RoutingCacheTypes } from 'Somos/lib/SomosCpr/RtCprV2';
import { noop } from 'lodash-es';
import { createContext } from 'react';
import { QueryObserverResult } from 'react-query';
import { TollFreeIndexResponse } from '../../../../../../../rtx-public/lib/clients/openapi-typescript-codegen/models/TollFreeIndexResponse';
import { ICprLabelDataGridRef } from 'RtUi/app/rt800/Cprs/lib/grids/CprLabelDataGrid';
import { ICprDataGridRef } from 'RtUi/app/rt800/Cprs/lib/grids/CprDataGrid';

interface ICprProfileContext {
	id: string;
	cprs: CprIndexResponse[];
	updateCpr: (cpr: Cpr) => void;
	refetchCprs: () => Promise<
		QueryObserverResult<FullResponse<CprIndexResponse[]>, Error> | undefined
	>;
	isNumber: boolean;
	isNumberAndHasTemplateAssigned: boolean;
	router: CprsRouterClass;
	refetchCpr: () => Promise<
		QueryObserverResult<CprProfileResponse, Error> | undefined
	>;
	displayMode: boolean;
	setDisplayMode: (value: boolean) => void;
	cpr: Cpr;
	editMode: CprActions | undefined;
	triggerEditMode: (editMode: CprActions | undefined) => void;
	tfns: TollFreeIndexResponse[];
	cprLabelDataGrid: React.RefObject<ICprLabelDataGridRef>;
	cprDataGrid: React.RefObject<ICprDataGridRef>;
	activeTab: string;
	setActiveTab: (header: string) => void;
	resetProfile: () => void;
	checkCadValidity: () => boolean;
}

export const CprProfileContext = createContext<ICprProfileContext>({
	id: '',
	cpr: new Cpr('', RoutingCacheTypes.TollFree, ''),
	updateCpr: noop,
	cprs: [],
	refetchCprs: () => Promise.resolve(undefined),
	isNumber: false,
	isNumberAndHasTemplateAssigned: false,
	router: TollFreeCprsRouter,
	refetchCpr: () => Promise.resolve(undefined),
	displayMode: false,
	setDisplayMode: noop,
	editMode: undefined,
	triggerEditMode: noop,
	tfns: [],
	cprLabelDataGrid: {
		current: {
			data: [],
			pageToIndex: noop,
			pageSize: 0,
			updatePage: noop
		}
	},
	cprDataGrid: {
		current: {
			data: [],
			pageToIndex: noop,
			pageSize: 0,
			updatePage: noop
		}
	},
	activeTab: '',
	setActiveTab: noop,
	resetProfile: noop,
	checkCadValidity: () => false
});
