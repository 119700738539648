import {
	GuardianReconciliationBulkUpdateItem,
	GuardianReconciliationIndexResponse
} from 'RtModels';
import {
	ReconciliationStatusMap,
	ReconciliationTypeMap
} from 'RtUi/app/rtVue/GuardianReconciliation/lib/utils';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { ServiceDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ServiceDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const getGuardianReconciliationColumns = (): Array<
	DataGridColumn<GuardianReconciliationIndexResponse>
> => [
	IntegerDataGridColumn({
		accessorKey: 'guardianReconciliationId',
		header: 'Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Label'
	}),
	DefaultDataGridColumn({
		isHidden: true,
		header: 'Type',
		accessorFn: (record) =>
			record.reconciliationType
				? ReconciliationTypeMap.get(record.reconciliationType as number)
				: ''
	}),
	DefaultDataGridColumn({
		isHidden: true,
		header: 'Status',
		accessorFn: (record) =>
			record.reconciliationStatus
				? ReconciliationStatusMap.get(record.reconciliationStatus as number)
				: ''
	}),
	SubscriptionDataGridColumn({
		isHidden: true,
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	ServiceDataGridColumn({
		isHidden: true,
		accessorKey: 'serviceId',
		header: 'Service'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rtSrcCharges',
		header: 'Source Charges (RouteTrust)'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rtTgtCharges',
		header: 'Target Charges'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'disputedAmount',
		header: 'Disputed Amount'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'approvedAmount',
		header: 'Approved Amount'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updateTs',
		header: 'Updated'
	})
];

export const getGuardianReconciliationBulkUpdateColumns = (): Array<
	DataGridColumn<GuardianReconciliationBulkUpdateItem>
> => [
	DefaultDataGridColumn({
		accessorKey: 'carrierBanNumber',
		header: 'Vendor Ban Number'
	}),
	DateDataGridColumn({
		accessorKey: 'startTs',
		header: 'Invoice Start Date'
	}),
	DateDataGridColumn({
		accessorKey: 'endTs',
		header: 'Invoice End Date'
	}),
	IntegerDataGridColumn({
		accessorKey: 'carrierInvoiceConnects',
		header: 'Invoice Calls'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'carrierInvoiceDuration',
		header: 'Invoice MOU'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'carrierInvoiceCharge',
		header: 'Invoice Cost'
	}),
	IntegerDataGridColumn({
		accessorKey: 'customerExpectedConnects',
		header: 'Expected Calls'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'customerExpectedDuration',
		header: 'Expected MOU'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'customerExpectedCharge',
		header: 'Expected Cost'
	})
];
