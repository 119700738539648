import { AbstractPromptAudioFileUtils } from 'RtUi/utils/file/bin/AbstractPromptAudioFileUtils';
import { AudioFileUtils } from 'RtUi/utils/file/AudioFileUtils';
import { FileUtils } from 'RtUi/utils/file/FileUtils';

export class UiPromptAudioFileUtils extends AbstractPromptAudioFileUtils {
	public audioFileUtils = new AudioFileUtils();
	public fileUtils = new FileUtils();

	/**
	 * Empty implementation. Usage is made for backend
	 * @param file
	 */
	public async isValidPrompt(file: File): Promise<[boolean, string]> {
		return [true, ''];
	}

	/**
	 * Asserts file is within prompt audio file size threshold
	 * @param file
	 */
	public async isValidPromptFileSize(file: File) {
		return file.size <= AbstractPromptAudioFileUtils.PromptFileSizeInBytes;
	}

	/**
	 * Asserts file is correct duration for a prompt audio file
	 * @param file
	 */
	public async isValidPromptFileDuration(file: File) {
		const duration = await this.getDurationFromFile(file);

		return (
			duration <= AbstractPromptAudioFileUtils.PromptFileMaxDurationInSeconds
		);
	}

	/**
	 * Asserts prompt audio file is a wav or mp3 file type
	 * @param file
	 */
	public async isValidPromptFileType(file: File) {
		return this.fileUtils.isFileOfMimeType(
			file,
			...AbstractPromptAudioFileUtils.PromptFileTypes
		);
	}

	/**
	 * Given an audio file, get the duration in seconds of the audio
	 * @param file
	 */
	private getDurationFromFile(file: File) {
		const objectUrl = URL.createObjectURL(file);

		return this.getDuration(objectUrl);
	}

	/**
	 * Given an href address, get the duration in seconds of the audio link
	 * @param srcAddress
	 */
	private getDuration(srcAddress: string) {
		return new Promise<number>((resolve, reject) => {
			const audio = new Audio();

			audio.addEventListener('loadedmetadata', () => resolve(audio.duration), {
				once: true
			});
			audio.addEventListener('error', (event) => reject(event), {
				once: true
			});

			audio.src = srcAddress;
		});
	}
}
