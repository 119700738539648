import { MonthlyTrendingIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PercentDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PercentDataGridColumn';
import dayjs from 'dayjs';

export const getMonthlyTrendColumnsCost = (): Array<
	DataGridColumn<MonthlyTrendingIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dayNameOfWeek',
		header: 'Day of Week',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'usageType',
		header: 'Usage Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'account',
		header: 'Account',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ratecenter',
		header: 'Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'country',
		header: 'Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'state',
		header: 'State',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'commonName',
		header: 'Common Name',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month0Value',
		header: `${dayjs().format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month1Value',
		header: `${dayjs().subtract(1, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month2Value',
		header: `${dayjs().subtract(2, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month3Value',
		header: `${dayjs().subtract(3, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month4Value',
		header: `${dayjs().subtract(4, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month5Value',
		header: `${dayjs().subtract(5, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month6Value',
		header: `${dayjs().subtract(6, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorFn: (row) =>
			String(
				Number(row.month0Value) +
					Number(row.month1Value) +
					Number(row.month2Value) +
					Number(row.month3Value) +
					Number(row.month4Value) +
					Number(row.month5Value) +
					Number(row.month6Value)
			),
		header: 'Totals',
		decimals: 6
	})
];

export const getMonthlyTrendColumnsCpm = (): Array<
	DataGridColumn<MonthlyTrendingIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dayNameOfWeek',
		header: 'Day of Week',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'usageType',
		header: 'Usage Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'account',
		header: 'Account',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ratecenter',
		header: 'Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'country',
		header: 'Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'state',
		header: 'State',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'commonName',
		header: 'Common Name',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month0Value',
		header: `${dayjs().format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month1Value',
		header: `${dayjs().subtract(1, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month2Value',
		header: `${dayjs().subtract(2, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month3Value',
		header: `${dayjs().subtract(3, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month4Value',
		header: `${dayjs().subtract(4, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month5Value',
		header: `${dayjs().subtract(5, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month6Value',
		header: `${dayjs().subtract(6, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorFn: (row) =>
			String(
				Number(row.month0Value) +
					Number(row.month1Value) +
					Number(row.month2Value) +
					Number(row.month3Value) +
					Number(row.month4Value) +
					Number(row.month5Value) +
					Number(row.month6Value)
			),
		header: 'Totals',
		decimals: 6
	})
];

export const getMonthlyTrendColumnsMou = (): Array<
	DataGridColumn<MonthlyTrendingIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dayNameOfWeek',
		header: 'Day of Week',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'usageType',
		header: 'Usage Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'account',
		header: 'Account',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ratecenter',
		header: 'Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'country',
		header: 'Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'state',
		header: 'State',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'commonName',
		header: 'Common Name',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month0Value',
		header: `${dayjs().format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month1Value',
		header: `${dayjs().subtract(1, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month2Value',
		header: `${dayjs().subtract(2, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month3Value',
		header: `${dayjs().subtract(3, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month4Value',
		header: `${dayjs().subtract(4, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month5Value',
		header: `${dayjs().subtract(5, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month6Value',
		header: `${dayjs().subtract(6, 'months').format('MMMM YYYY')}`,
		decimals: 6
	}),
	PrecisionDataGridColumn({
		accessorFn: (row) =>
			String(
				Number(row.month0Value) +
					Number(row.month1Value) +
					Number(row.month2Value) +
					Number(row.month3Value) +
					Number(row.month4Value) +
					Number(row.month5Value) +
					Number(row.month6Value)
			),
		header: 'Totals',
		decimals: 6
	})
];

export const getMonthlyTrendColumnsAsr = (): Array<
	DataGridColumn<MonthlyTrendingIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dayNameOfWeek',
		header: 'Day of Week',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'usageType',
		header: 'Usage Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'account',
		header: 'Account',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ratecenter',
		header: 'Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'country',
		header: 'Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'state',
		header: 'State',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'commonName',
		header: 'Common Name',
		hiddenIfEmpty: true
	}),
	PercentDataGridColumn({
		accessorKey: 'month0Value',
		header: `${dayjs().format('MMMM YYYY')}`
	}),
	PercentDataGridColumn({
		accessorKey: 'month1Value',
		header: `${dayjs().subtract(1, 'months').format('MMMM YYYY')}`
	}),
	PercentDataGridColumn({
		accessorKey: 'month2Value',
		header: `${dayjs().subtract(2, 'months').format('MMMM YYYY')}`
	}),
	PercentDataGridColumn({
		accessorKey: 'month3Value',
		header: `${dayjs().subtract(3, 'months').format('MMMM YYYY')}`
	}),
	PercentDataGridColumn({
		accessorKey: 'month4Value',
		header: `${dayjs().subtract(4, 'months').format('MMMM YYYY')}`
	}),
	PercentDataGridColumn({
		accessorKey: 'month5Value',
		header: `${dayjs().subtract(5, 'months').format('MMMM YYYY')}`
	}),
	PercentDataGridColumn({
		accessorKey: 'month6Value',
		header: `${dayjs().subtract(6, 'months').format('MMMM YYYY')}`
	}),
	PercentDataGridColumn({
		accessorFn: (row) =>
			String(
				Number(row.month0Value) +
					Number(row.month1Value) +
					Number(row.month2Value) +
					Number(row.month3Value) +
					Number(row.month4Value) +
					Number(row.month5Value) +
					Number(row.month6Value)
			),
		header: 'Totals',
		decimals: 6
	})
];

export const getMonthlyTrendColumnsAloc = (): Array<
	DataGridColumn<MonthlyTrendingIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dayNameOfWeek',
		header: 'Day of Week',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'usageType',
		header: 'Usage Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'account',
		header: 'Account',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ratecenter',
		header: 'Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'country',
		header: 'Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'state',
		header: 'State',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'commonName',
		header: 'Common Name',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month0Value',
		header: `${dayjs().format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month1Value',
		header: `${dayjs().subtract(1, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month2Value',
		header: `${dayjs().subtract(2, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month3Value',
		header: `${dayjs().subtract(3, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month4Value',
		header: `${dayjs().subtract(4, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month5Value',
		header: `${dayjs().subtract(5, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorKey: 'month6Value',
		header: `${dayjs().subtract(6, 'months').format('MMMM YYYY')}`
	}),
	PrecisionDataGridColumn({
		accessorFn: (row) =>
			String(
				Number(row.month0Value) +
					Number(row.month1Value) +
					Number(row.month2Value) +
					Number(row.month3Value) +
					Number(row.month4Value) +
					Number(row.month5Value) +
					Number(row.month6Value)
			),
		header: 'Totals',
		decimals: 6
	})
];

export const getMonthlyTrendColumnsDno = (): Array<
	DataGridColumn<MonthlyTrendingIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dayNameOfWeek',
		header: 'Day of Week',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'usageType',
		header: 'Usage Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'account',
		header: 'Account',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ratecenter',
		header: 'Rate Center',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'country',
		header: 'Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'state',
		header: 'State',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'commonName',
		header: 'Common Name',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'month0Value',
		header: `${dayjs().format('MMMM YYYY')}`
	}),
	IntegerDataGridColumn({
		accessorKey: 'month1Value',
		header: `${dayjs().subtract(1, 'months').format('MMMM YYYY')}`
	}),
	IntegerDataGridColumn({
		accessorKey: 'month2Value',
		header: `${dayjs().subtract(2, 'months').format('MMMM YYYY')}`
	}),
	IntegerDataGridColumn({
		accessorKey: 'month3Value',
		header: `${dayjs().subtract(3, 'months').format('MMMM YYYY')}`
	}),
	IntegerDataGridColumn({
		accessorKey: 'month4Value',
		header: `${dayjs().subtract(4, 'months').format('MMMM YYYY')}`
	}),
	IntegerDataGridColumn({
		accessorKey: 'month5Value',
		header: `${dayjs().subtract(5, 'months').format('MMMM YYYY')}`
	}),
	IntegerDataGridColumn({
		accessorKey: 'month6Value',
		header: `${dayjs().subtract(6, 'months').format('MMMM YYYY')}`
	}),
	IntegerDataGridColumn({
		accessorFn: (row) =>
			String(
				Number(row.month0Value) +
					Number(row.month1Value) +
					Number(row.month2Value) +
					Number(row.month3Value) +
					Number(row.month4Value) +
					Number(row.month5Value) +
					Number(row.month6Value)
			),
		header: 'Totals'
	})
];
