/* cSpell:disable */
import { SipCdrSearchIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';
import { ReleaseCodeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ReleaseCodeDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { RateCenterDataGridColumn } from '../../../columns/RateCenterDataGridColumn';

export const getCdrSearchReportColumns = (): Array<
	DataGridColumn<SipCdrSearchIndexResponse>
> => [
	TimeStampDataGridColumn({
		accessorKey: 'invite',
		header: 'Invite'
	}),
	IntegerDataGridColumn({
		accessorKey: 'inDur',
		header: 'In Dur',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'egDur',
		header: 'Eg Dur',
		hiddenIfEmpty: true
	}),
	ReleaseCodeDataGridColumn({
		accessorKey: 'eReleaseCodeId',
		header: 'Egress Release Code',
		hiddenIfEmpty: true
	}),
	ReleaseCodeDataGridColumn({
		accessorKey: 'iReleaseCodeId',
		header: 'Ingress Release Code',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cp',
		header: 'CP',
		isInvisible: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cpStd',
		header: 'CP Std',
		isInvisible: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'cpLrn',
		header: 'CP Lrn',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dn',
		header: 'DN',
		isInvisible: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dnLrn',
		header: 'DN Lrn',
		hiddenIfEmpty: true
	}),
	HeaderDataGridColumn({
		header: 'Calling Party',
		columns: [
			DefaultDataGridColumn({
				accessorKey: 'cp',
				header: 'CP'
			}),
			DefaultDataGridColumn({
				accessorKey: 'cpStd',
				header: 'CP Std'
			}),
			DefaultDataGridColumn({
				accessorKey: 'cpLrn',
				header: 'CP Lrn',
				hiddenIfEmpty: true
			}),
			DefaultDataGridColumn({
				accessorKey: 'cpIso',
				header: 'CP Country'
			}),
			RateCenterDataGridColumn({
				accessorKey: 'irci',
				header: 'CP Rate Center'
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Dialed Number',
		columns: [
			DefaultDataGridColumn({
				accessorKey: 'dn',
				header: 'DN'
			}),
			DefaultDataGridColumn({
				accessorKey: 'dnLrn',
				header: 'DN Lrn'
			}),
			DefaultDataGridColumn({
				accessorKey: 'dnIso',
				header: 'DN Country'
			}),
			RateCenterDataGridColumn({
				accessorKey: 'erci',
				header: 'DN Rate Center'
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Customer',
		columns: [
			SubscriptionDataGridColumn({
				accessorKey: 'ers',
				header: 'Subscription'
			}),
			RateDataGridColumn({
				accessorKey: 'err',
				header: 'Rate',
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Vendor',
		columns: [
			SubscriptionDataGridColumn({
				accessorKey: 'ecs',
				header: 'Subscription'
			}),
			RateDataGridColumn({
				accessorKey: 'ecr',
				header: 'Rate',
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Billing',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'erd',
				header: 'Rev Dur.',
				hiddenIfEmpty: true
			}),
			IntegerDataGridColumn({
				accessorKey: 'ecd',
				header: 'Cost Dur.',
				hiddenIfEmpty: true
			}),
			PrecisionDataGridColumn({
				accessorKey: 'ertc',
				header: 'Revenue',
				hiddenIfEmpty: true
			}),
			PrecisionDataGridColumn({
				accessorKey: 'ectc',
				header: 'Cost',
				hiddenIfEmpty: true
			}),
			PrecisionDataGridColumn({
				accessorKey: 'mgn',
				header: 'Margin',
				hiddenIfEmpty: true
			})
		]
	})
];
