/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CprApprovalIndicator {
    NotApplicable = 'NA',
    Ok = 'OK',
    AwaitingApproval = 'AW',
    ApprovalDenied = 'DN',
    NotRequired = 'NR',
    RejectedEffectiveDate = 'RJ',
    Unknown = 'UN',
}