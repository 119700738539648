import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	CdrSearchIndexRequest,
	CdrSearchUrlRequest,
	ResultIndexResponse,
	SipCdrSearchIndexResponse,
	SipCdrSearchProfileResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	handlePostRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchCallProcessingResults = async (): Promise<ResultIndexResponse[]> => {
	const data = await handleGetRequest(
		RtxSipApiRoutes.CallProcessingResults.Index
	);
	return data;
};

export const useGetCallProcessingResults = () => {
	return useQuery<ResultIndexResponse[], Error>(
		[`getCallProcessingResults`],
		() => {
			return fetchCallProcessingResults();
		}
	);
};

const fetchCdrSearch = async (
	cdrId: string
): Promise<SipCdrSearchProfileResponse> => {
	const urlParams: CdrSearchUrlRequest = {
		cdrId
	};

	const data = await handleGetRequest(RtxSipApiRoutes.CdrSearch.Profile, {
		urlParams
	});
	return data;
};

export const useGetCdrSearch = (cdrId: string) => {
	return useQuery<SipCdrSearchProfileResponse, Error>([`getCdrSearch`], () =>
		fetchCdrSearch(cdrId)
	);
};

const fetchCdrSearchReport = async (
	body: CdrSearchIndexRequest
): Promise<FullResponse<SipCdrSearchIndexResponse[]>> => {
	return handlePostRequest<SipCdrSearchIndexResponse[], true>(
		RtxSipApiRoutes.CdrSearch.Index,
		body,
		{
			includeFullResponse: true
		}
	);
};

export const useGetCdrSearchReport = (body: CdrSearchIndexRequest) => {
	return useQuery<FullResponse<SipCdrSearchIndexResponse[]>, Error>(
		[`useGetCdrSearchReport`, body],
		() => fetchCdrSearchReport(body),
		{ enabled: true }
	);
};
