/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExportDestinationProfileResponse = {
    properties: {
        cdrExportDestinationId: {
            type: 'number',
            isRequired: true,
        },
        destinationTypeId: {
            type: 'DestinationTypeId',
            isRequired: true,
        },
        credentials: {
            properties: {
            },
            isRequired: true,
        },
        remotePath: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
    },
};