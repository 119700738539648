import { RtxSipApiRoutes } from 'RtExports/routes';
import { ActivePortAllIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchActivePortsPartition = async (): Promise<
	FullResponse<ActivePortAllIndexResponse[]>
> => {
	return handleGetRequest<ActivePortAllIndexResponse[], true>(
		RtxSipApiRoutes.ActivePortsAll.Index,
		{
			includeFullResponse: true
		}
	);
};

export const useGetActivePortsPartition = () => {
	return useQuery<FullResponse<ActivePortAllIndexResponse[]>, Error>(
		[`useGetActivePortsPartition`],
		() => fetchActivePortsPartition(),
		{ enabled: true }
	);
};
