import * as React from 'react';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { LcrRateSheetType } from 'RtModels';
import { IRadioButtonOptions } from 'RtUi/components/ui/RadioButtons';

interface IRateSheetTypeRadioFormControlProps {
	value: LcrRateSheetType;
	onChange: (newValue: LcrRateSheetType) => void;
	required?: boolean;
	hideLataOcnOption?: boolean;
	hideDialCodeOption?: boolean;
}

export class RateSheetTypeRadioFormControl extends React.Component<
	IRateSheetTypeRadioFormControlProps,
	{}
> {
	public render() {
		const { hideLataOcnOption = false, hideDialCodeOption = false } =
			this.props;

		const options: Array<IRadioButtonOptions<LcrRateSheetType>> = [
			{ value: LcrRateSheetType.NpaNxx, label: 'NPA/NXX' }
		];

		if (!hideLataOcnOption) {
			options.push({ value: LcrRateSheetType.LataOcn, label: 'LATA/OCN' });
		}

		if (!hideDialCodeOption) {
			options.push({
				value: LcrRateSheetType.DialCode,
				label: 'Dial Code'
			});
		}

		return (
			<RadioFormControl<LcrRateSheetType>
				label="Rate Sheet Type"
				value={this.props.value}
				onChange={this.props.onChange}
				required={this.props.required}
				options={options}
			/>
		);
	}
}
