/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RateLoadProfileResponse = {
    properties: {
        rateLoadId: {
            type: 'number',
            isRequired: true,
        },
        sourceLoadId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        ratePlanId: {
            type: 'number',
            isRequired: true,
        },
        notificationTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        effectiveTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        summary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        rateTypeId: {
            type: 'number',
            isRequired: true,
        },
        rateExpirationId: {
            type: 'RateExpireMode',
            isRequired: true,
        },
        uploadedFileName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        minEffectiveTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        maxEffectiveTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        rateLoadTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        rateLoadStepId: {
            type: 'RateLoadStep',
            isRequired: true,
        },
        rateLoadStatusId: {
            type: 'RateLoadStatus',
            isRequired: true,
        },
        exceptionQty: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        createdUserId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        approvedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        approvedUserId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rateSheetId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rateScheduleId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        parser: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        completedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        userEmail: {
            type: 'string',
        },
    },
};