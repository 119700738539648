/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NanpStateIndexRequest = {
    properties: {
        nanpStateId: {
            type: 'string',
        },
        nanpRegionId: {
            type: 'string',
        },
    },
};