import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianFraudSimulatorProfileRequest,
	GuardianFraudSimulatorResponse
} from 'RtModels';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';

export class FraudSimulatorHttp {
	public simulate(request: GuardianFraudSimulatorProfileRequest) {
		const body = JSON.stringify(request);

		return HttpRequest.fetchWithRoute<GuardianFraudSimulatorResponse>(
			RtVueApiRoutes.GuardianFraudSimulator.Profile,
			{
				body
			}
		);
	}
}
