import { RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { AccountManagementRouter } from 'RtUi/app/AccountManagement/AccountManagement.router';
import { AccountIndexContainer } from 'RtUi/app/AccountManagement/Accounts/Account.index';
import { AccountProfileContainer } from 'RtUi/app/AccountManagement/Accounts/Account.profile';
import { AccountRouter } from 'RtUi/app/AccountManagement/Accounts/Account.router';
import { RateManagementIndexContainer } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.index';
import { RateManagementProfileContainer } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.profile';
import { RateManagementRouter } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.router';
import { RatePlanIndexContainer } from 'RtUi/app/AccountManagement/RatePlan/RatePlan.index';
import { RatePlanProfileContainer } from 'RtUi/app/AccountManagement/RatePlan/RatePlan.profile';
import { RatePlanRouter } from 'RtUi/app/AccountManagement/RatePlan/RatePlan.router';
import { SipAgentIndexContainer } from 'RtUi/app/AccountManagement/SipAgents/SipAgent.index';
import { SipAgentProfileContainer } from 'RtUi/app/AccountManagement/SipAgents/SipAgent.profile';
import { SipAgentRouter } from 'RtUi/app/AccountManagement/SipAgents/SipAgent.router';
import { SipAgentConfigProfileContainer } from 'RtUi/app/AccountManagement/SipAgents/SipAgentConfig.profile';
import { SipAgentConfigRouter } from 'RtUi/app/AccountManagement/SipAgents/SipAgentConfig.router';
import { SubscriptionIndexContainer } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.index';
import { SubscriptionProfileContainer } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.profile';
import { SubscriptionRouter } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.router';
import { SwitchIndexContainer } from 'RtUi/app/AccountManagement/Switches/Switch.index';
import { SwitchProfileContainer } from 'RtUi/app/AccountManagement/Switches/Switch.profile';
import { SwitchRouter } from 'RtUi/app/AccountManagement/Switches/Switch.router';

@RtUiModule({
	route: {
		path: `/${AccountManagementRouter.SectionKey}`,
		iconClass: 'fa-chart-bar',
		name: 'Account Management'
	},
	controllers: [
		AccountIndexContainer,
		AccountProfileContainer,
		SubscriptionIndexContainer,
		SubscriptionProfileContainer,
		RateManagementIndexContainer,
		RateManagementProfileContainer,
		RatePlanIndexContainer,
		RatePlanProfileContainer,
		SwitchProfileContainer,
		SwitchIndexContainer,
		SipAgentIndexContainer,
		SipAgentProfileContainer,
		SipAgentConfigProfileContainer
	],
	routers: [
		AccountRouter,
		SubscriptionRouter,
		RateManagementRouter,
		RatePlanRouter,
		SwitchRouter,
		SipAgentRouter,
		SipAgentConfigRouter
	]
})
export class AccountManagementModule {}
