import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { DashboardIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchDashboard = async (): Promise<
	FullResponse<DashboardIndexResponse[]>
> => {
	return handleGetRequest<DashboardIndexResponse[], true>(
		RtCommonApiRoutes.Dashboard.Index,
		{
			includeFullResponse: true
		}
	);
};

export const useGetDashboard = () => {
	return useQuery<FullResponse<DashboardIndexResponse[]>, Error>(
		[`useGetDashboard`],
		() => fetchDashboard(),
		{ enabled: true }
	);
};
