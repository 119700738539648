/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $BillingEntityIndexResponse = {
    properties: {
        billingEntityId: {
            type: 'number',
            isRequired: true,
        },
        companyName: {
            type: 'string',
            isRequired: true,
        },
        logoFile: {
            type: 'string',
            isRequired: true,
        },
        wireInstructions: {
            type: 'string',
            isRequired: true,
        },
        disputeEmail: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        emailAccountId: {
            type: 'number',
            isRequired: true,
        },
        pdfEmailName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        pdfEmailAddress: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        address: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        ratesEmailAccountId: {
            type: 'number',
            isRequired: true,
        },
        ratesEmailAttachmentPrefix: {
            type: 'string',
            isRequired: true,
        },
        emailInvoicesToCustomer: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        invoicePdfThreshold: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        invoiceEmailTemplate: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        newPeerEmailName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        newPeerEmailAddress: {
            properties: {
            },
            isRequired: true,
            isNullable: true,
        },
    },
};