/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocActionIndexResponse = {
    properties: {
        actionItems: {
            type: 'RocActionItems',
            isRequired: true,
        },
    },
};