import { RtDashboardRouter } from 'RtUi/app/rtCommon/Dashboard/Dashboard.router';
import { DashboardIndexContainer } from 'RtUi/app/rtCommon/Dashboard/Dashboard.index';
import { RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';

@RtUiModule({
	route: {
		iconClass: '',
		name: 'Dashboard Details',
		singleMode: true
	},
	controllers: [DashboardIndexContainer],
	routers: [RtDashboardRouter]
})
export class RtCommonDashboardModule {}
