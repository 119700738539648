/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationNoteIndexRequest = {
    properties: {
        reconciliationId: {
            type: 'number',
        },
        reconciliationDetailId: {
            type: 'number',
            isNullable: true,
        },
        reconciliationNoteType: {
            type: 'GuardianReconciliationNoteTypes',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};