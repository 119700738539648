import { cloneDeep } from 'lodash-es';
import * as React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
	CallflowService,
	ReleaseCodeIndexResponse,
	SubscriptionNanpProfileResponse
} from 'RtModels';
import { ReleaseCodeSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/ReleaseCodeSelect';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { WarningAlert } from 'RtUi/components/ui/WarningAlert';

interface INanpEditFormProps {
	subscriptionId: number;
	serviceId: number;
	onSuccess?: (profile: SubscriptionNanpProfileResponse) => void;
}

interface INanpEditFormState {
	displayMode: boolean;
	isSubmitting: boolean;
	nanp: SubscriptionNanpProfileResponse | null;
	usaTfAniResultId?: ReleaseCodeIndexResponse;
	extendedNanpAniResultId?: ReleaseCodeIndexResponse;
	idddAniResultId?: ReleaseCodeIndexResponse;
	unknownAniResultId?: ReleaseCodeIndexResponse;
	error?: any;
}

export class NanpEditForm extends React.Component<
	INanpEditFormProps,
	INanpEditFormState
> {
	private initialState: INanpEditFormState;
	private sipSubscriptionResource = new SubscriptionResource();

	constructor(props: INanpEditFormProps) {
		super(props);

		this.initialState = {
			displayMode: true,
			isSubmitting: false,
			nanp: null,
			usaTfAniResultId: undefined,
			extendedNanpAniResultId: undefined,
			idddAniResultId: undefined,
			unknownAniResultId: undefined
		};

		this.state = cloneDeep(this.initialState);
	}

	public async componentDidMount() {
		this.setUpInitialState();
	}

	public componentDidUpdate(prevProps: Readonly<INanpEditFormProps>) {
		if (prevProps.subscriptionId !== this.props.subscriptionId) {
			this.setUpInitialState();
		}
	}

	public render() {
		if (!this.state.nanp) {
			return <Loading />;
		}

		const { nanp } = this.state;

		return (
			<>
				<WarningAlert
					text="NANP Settings are for calculating jurisdiction only. They do NOT
						change the ANI written to the CDR and they do NOT change the ANI
						sent to the vendor. If the Default ANI is used, this will override
						the Jurisdiction settings for Unknown/Invalid ANI."
				/>
				<RtUiForm
					displayMode={this.state.displayMode}
					onSubmit={this.onSubmit}
					isSubmitting={this.state.isSubmitting}
					error={this.state.error}
					isInline
					onChange={(displayMode) => this.setState({ displayMode })}
					onCancel={() => {
						this.setState({ ...cloneDeep(this.initialState) });
					}}
				>
					<Row>
						<Col md={6}>
							<Card>
								<Card.Header>
									<h6>Jurisdiction Settings</h6>
								</Card.Header>
								<Card.Body>
									<RadioFormControl<number>
										label="USA Toll-Free ANI Rate"
										displayMode={this.state.displayMode}
										onChange={(usaTfAniJurisdictionId) =>
											this.onChange(
												'usaTfAniJurisdictionId',
												usaTfAniJurisdictionId
											)
										}
										value={nanp.usaTfAniJurisdictionId}
										initialValue={nanp.usaTfAniJurisdictionId}
										options={[
											{ value: 101, label: 'Interstate' },
											{ value: 102, label: 'Intrastate' }
										]}
									/>
									<ReleaseCodeSelect
										label="USA Toll-Free ANI Reject"
										displayMode={this.state.displayMode}
										value={this.state.usaTfAniResultId}
										initialOptionId={
											!nanp.usaTfAniResultId
												? undefined
												: nanp.usaTfAniResultId.toString()
										}
										onChange={(usaTfAniResultId) => {
											this.setState({ usaTfAniResultId });
											this.onChange(
												'usaTfAniResultId',
												usaTfAniResultId.releaseCodeId
											);
										}}
									/>
									<RadioFormControl<number>
										label="Extended NANP ANI Rate"
										displayMode={this.state.displayMode}
										onChange={(extendedNanpAniJurisdictionId) =>
											this.onChange(
												'extendedNanpAniJurisdictionId',
												extendedNanpAniJurisdictionId
											)
										}
										value={nanp.extendedNanpAniJurisdictionId}
										initialValue={nanp.extendedNanpAniJurisdictionId}
										options={[
											{ value: 101, label: 'Interstate' },
											{ value: 102, label: 'Intrastate' }
										]}
									/>
									<ReleaseCodeSelect
										label="Extended NANP ANI Reject"
										displayMode={this.state.displayMode}
										value={this.state.extendedNanpAniResultId}
										initialOptionId={
											!nanp.extendedNanpAniResultId
												? undefined
												: nanp.extendedNanpAniResultId.toString()
										}
										onChange={(extendedNanpAniResultId) => {
											this.setState({ extendedNanpAniResultId });
											this.onChange(
												'extendedNanpAniResultId',
												extendedNanpAniResultId.releaseCodeId
											);
										}}
									/>
									<RadioFormControl<number>
										label="International ANI Rate"
										displayMode={this.state.displayMode}
										onChange={(idddAniJurisdictionId) =>
											this.onChange(
												'idddAniJurisdictionId',
												idddAniJurisdictionId
											)
										}
										value={nanp.idddAniJurisdictionId}
										initialValue={nanp.idddAniJurisdictionId}
										options={[
											{ value: 101, label: 'Interstate' },
											{ value: 102, label: 'Intrastate' }
										]}
									/>
									<ReleaseCodeSelect
										label="International ANI Reject"
										displayMode={this.state.displayMode}
										value={this.state.idddAniResultId}
										initialOptionId={nanp.extendedNanpAniResultId?.toString()}
										onChange={(idddAniResultId) => {
											this.setState({ idddAniResultId });
											this.onChange(
												'idddAniResultId',
												idddAniResultId.releaseCodeId
											);
										}}
									/>
									<RadioFormControl<number>
										label="Unknown / Invalid ANI Rate"
										displayMode={this.state.displayMode}
										onChange={(unknownAniJurisdictionId) =>
											this.onChange(
												'unknownAniJurisdictionId',
												unknownAniJurisdictionId
											)
										}
										value={nanp.unknownAniJurisdictionId}
										initialValue={nanp.unknownAniJurisdictionId}
										options={[
											{ value: 101, label: 'Interstate' },
											{ value: 102, label: 'Intrastate' }
										]}
									/>
									<ReleaseCodeSelect
										label="Unknown / Invalid ANI Reject"
										displayMode={this.state.displayMode}
										value={this.state.unknownAniResultId}
										initialOptionId={
											!nanp.unknownAniResultId
												? undefined
												: nanp.unknownAniResultId.toString()
										}
										onChange={(unknownAniResultId) => {
											this.setState({ unknownAniResultId });
											this.onChange(
												'unknownAniResultId',
												unknownAniResultId.releaseCodeId
											);
										}}
									/>
								</Card.Body>
							</Card>
						</Col>
						<Col md={6}>
							<Card>
								<Card.Header>
									<h6>Default ANI / Override Settings</h6>
								</Card.Header>
								<Card.Body>
									<InputFormControl
										label="Default ANI"
										displayMode={this.state.displayMode}
										value={nanp.defaultAni ?? undefined}
										onChange={(defaultAni) =>
											this.onChange('defaultAni', defaultAni)
										}
									/>
									<RadioFormControl<number>
										label="Use Default ANI"
										displayMode={this.state.displayMode}
										onChange={(defaultAniUseMode) =>
											this.onChange('defaultAniUseMode', defaultAniUseMode)
										}
										value={nanp.defaultAniUseMode}
										initialValue={nanp.defaultAniUseMode}
										options={[
											{ value: 1, label: 'Always' },
											{ value: 2, label: 'Never' },
											{ value: 3, label: 'When Ingress ANI is invalid' }
										]}
									/>
								</Card.Body>
							</Card>
							<Card
								hidden={!this.shouldShowDialedNumberMatching()}
								className="mt-3"
							>
								<Card.Header>
									<h6>Dialed Number Matching</h6>
								</Card.Header>
								<Card.Body>
									<RadioFormControl<number>
										label="Check for NANP Match"
										displayMode={this.state.displayMode}
										onChange={(dialedNumberCheckNanp) =>
											this.onChange(
												'dialedNumberCheckNanp',
												dialedNumberCheckNanp
											)
										}
										value={nanp.dialedNumberCheckNanp}
										initialValue={nanp.dialedNumberCheckNanp}
										options={[
											{ value: 0, label: 'No' },
											{ value: 1, label: 'Yes' }
										]}
									/>
									<RadioFormControl<number>
										label="Prefer NANP Match"
										displayMode={this.state.displayMode}
										onChange={(dialedNumberPreferNanp) =>
											this.onChange(
												'dialedNumberPreferNanp',
												dialedNumberPreferNanp
											)
										}
										value={nanp.dialedNumberPreferNanp}
										initialValue={nanp.dialedNumberPreferNanp}
										options={[
											{ value: 0, label: 'No' },
											{ value: 1, label: 'Yes' }
										]}
									/>
								</Card.Body>
							</Card>
							<Card
								hidden={!this.shouldShowCallPartyMatching()}
								className="mt-3"
							>
								<Card.Header>
									<h6>Calling Party Matching</h6>
								</Card.Header>
								<Card.Body>
									<RadioFormControl<number>
										label="Check for NANP Match"
										displayMode={this.state.displayMode}
										onChange={(callingPartyCheckNanp) =>
											this.onChange(
												'callingPartyCheckNanp',
												callingPartyCheckNanp
											)
										}
										value={nanp.callingPartyCheckNanp}
										initialValue={nanp.callingPartyCheckNanp}
										options={[
											{ value: 0, label: 'No' },
											{ value: 1, label: 'Yes' },
											{ value: 2, label: 'When Dialed Number is NANP' }
										]}
									/>
									<RadioFormControl<number>
										label="Prefer NANP Match"
										displayMode={this.state.displayMode}
										onChange={(callingPartyPreferNanp) =>
											this.onChange(
												'callingPartyPreferNanp',
												callingPartyPreferNanp
											)
										}
										value={nanp.callingPartyPreferNanp}
										initialValue={nanp.callingPartyPreferNanp}
										options={[
											{ value: 0, label: 'No' },
											{ value: 1, label: 'Yes' },
											{ value: 2, label: 'When Dialed Number is NANP' }
										]}
									/>
								</Card.Body>
							</Card>
							<Card className="mt-3">
								<Card.Header>
									<h6>NPDI Settings</h6>
								</Card.Header>
								<Card.Body>
									<RadioFormControl<number>
										label="Send NPDI"
										displayMode={this.state.displayMode}
										onChange={(useNpdi) => this.onChange('useNpdi', useNpdi)}
										value={nanp.useNpdi}
										initialValue={nanp.useNpdi}
										options={[
											{ value: 0, label: 'No' },
											{ value: 1, label: 'Yes' }
										]}
									/>
									<RadioFormControl<number>
										label="NPDI Format"
										displayMode={this.state.displayMode}
										onChange={(npdiFormatMode) =>
											this.onChange('npdiFormatMode', npdiFormatMode)
										}
										value={nanp.npdiFormatMode}
										initialValue={nanp.npdiFormatMode}
										options={[
											{
												value: 1,
												label: 'Standard: RFC4694 (SIP URI User Part)'
											},
											{ value: 2, label: 'Non-Standard (SIP Parameter)' }
										]}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</RtUiForm>
			</>
		);
	}

	private onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const nanp = await this.sipSubscriptionResource.updateSubscriptionNanp(
				this.props.subscriptionId,
				this.state.nanp!
			);

			this.setState({ displayMode: true, isSubmitting: false, nanp }, () => {
				this.initialState = this.state;
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	};

	private onChange = <K extends keyof SubscriptionNanpProfileResponse>(
		key: K,
		value: SubscriptionNanpProfileResponse[K]
	) => {
		if (!this.state.isSubmitting && this.state.nanp) {
			const { nanp } = this.state;
			nanp[key] = value;
			this.setState({ nanp });
		}
	};

	private shouldShowCallPartyMatching() {
		return [
			CallflowService.InboundVendorOrigination,
			CallflowService.InboundCustomerIdentification,
			CallflowService.OutboundCustomerIdentification,
			CallflowService.OutboundVendorTermination
		].includes(this.props.serviceId);
	}

	private shouldShowDialedNumberMatching() {
		return [
			CallflowService.InboundVendorOrigination,
			CallflowService.InboundCustomerIdentification,
			CallflowService.OutboundCustomerIdentification,
			CallflowService.OutboundVendorTermination
		].includes(this.props.serviceId);
	}

	private async setUpInitialState() {
		const profile = await this.sipSubscriptionResource.getSubscriptionNanp(
			this.props.subscriptionId
		);

		this.initialState = {
			...this.initialState,
			nanp: cloneDeep(profile)
		};

		this.setState(cloneDeep(this.initialState));
	}
}
