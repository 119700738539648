import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';

interface IConfirmationProps {
	backdrop?: boolean;
	cancelTextBtnText?: string;
	confirmationBtnText?: string;
	confirmationBtnColor?: string;
}

interface IConfirmationState {
	isOpen: boolean;
	content: React.ReactNode;
}

type ConfirmType = ((value: boolean) => void) | undefined;

export class Confirmation extends React.Component<
	IConfirmationProps,
	IConfirmationState
> {
	public static create(
		content: React.ReactNode,
		props: IConfirmationProps = {}
	) {
		return new Promise<boolean>((resolve, reject) => {
			let confirmationWasOpened = false;
			const confirmationElement = (
				<Confirmation
					{...props}
					ref={async (confirmationInstance: Confirmation | null) => {
						if (confirmationInstance) {
							confirmationWasOpened = true;
							try {
								const confirm = confirmationInstance.show(content);
								resolve(confirm);
							} catch (err) {
								reject(err);
							}
						}
					}}
				/>
			);

			const dummyContainer = document.createElement('div');
			const root = createRoot(dummyContainer);
			root.render(confirmationElement);

			setTimeout(() => {
				if (confirmationWasOpened) {
					return;
				}

				if (confirmationElement instanceof Confirmation) {
					confirmationElement.close();
				}

				const contentElement = <div>{content}</div>;
				const dummyContainer = document.createElement('div');
				const root = createRoot(dummyContainer);
				root.render(contentElement);

				if (contentElement instanceof HTMLElement) {
					const contentStr = contentElement.innerText;
					const confirmation = window.confirm(contentStr);

					resolve(confirmation);
				} else {
					reject('Confirmation: Unable to perform confirmation');
				}
			}, 1000);
		});
	}

	public static createDelete(
		whatToDelete: string,
		fromWhereToDelete = '<span>. </span>'
	) {
		const preparedMarkup = {
			__html: fromWhereToDelete
		};
		const contentElement = (
			<div>
				<span className="lead">
					<span>You are about to delete&nbsp;</span>
					<span className="text-muted">{whatToDelete}</span>
					<span dangerouslySetInnerHTML={preparedMarkup}></span>
					<span>Would you like to continue?</span>
				</span>
			</div>
		);
		return Confirmation.create(contentElement, {
			confirmationBtnText: 'Yes, Delete',
			confirmationBtnColor: 'danger'
		});
	}

	public static createConfirm(warningStatement: string) {
		const contentElement = (
			<div>
				<span className="lead">
					<span>{warningStatement}</span>
					<span>&nbsp;Would you like to continue?</span>
				</span>
			</div>
		);
		return Confirmation.create(contentElement, {
			confirmationBtnText: 'Yes, Continue',
			confirmationBtnColor: 'primary'
		});
	}

	public state: IConfirmationState = {
		isOpen: false,
		content: null
	};

	private confirmResolve: ConfirmType;
	private confirmationPromise: Promise<boolean> | null = null;

	public constructor(props: IConfirmationProps) {
		super(props);
	}

	public close() {
		this.setState({ isOpen: false });
	}

	public show(content: React.ReactNode) {
		this.setState({ isOpen: true, content });

		this.confirmationPromise = new Promise<boolean>((resolve) => {
			this.confirmResolve = resolve;
		});

		return this.confirmationPromise;
	}

	public render() {
		const {
			confirmationBtnText = 'Yes, Continue',
			cancelTextBtnText = 'Cancel',
			confirmationBtnColor = 'submit'
		} = this.props;

		return (
			<Modal
				//cSpell:ignore modalclassname
				modalclassname="modal-confirm"
				show={this.state.isOpen}
				backdrop={this.props.backdrop}
			>
				<Modal.Body>{this.state.content}</Modal.Body>
				<Modal.Footer className="d-flex justify-content-end">
					<Button
						variant="white"
						className="me-2"
						onClick={() => this.submitConfirm(false)}
					>
						{cancelTextBtnText}
					</Button>
					<Button
						variant={confirmationBtnColor}
						onClick={() => this.submitConfirm(true)}
					>
						{confirmationBtnText}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	private submitConfirm(confirm: boolean) {
		this.close();

		if (this.confirmResolve) {
			this.confirmResolve(confirm);
		}

		this.confirmResolve = undefined;
		this.confirmationPromise = null;
	}
}
