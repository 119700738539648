/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RouteTrustValidationError {
    AccountNotFound = 1001,
    AccountNotActive = 1002,
    SubscriptionNotFound = 2001,
    SubscriptionNotActive = 2002,
    Unknown = 9999,
}