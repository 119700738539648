import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface IErrorBoundaryState {
	hasError: boolean;
	errorInfo?: React.ErrorInfo;
	error?: Error;
}

export class PortalErrorBoundary extends React.Component<
	React.PropsWithChildren<{}>,
	IErrorBoundaryState
> {
	public static getDerivedStateFromError() {
		return { hasError: true };
	}

	public state: IErrorBoundaryState = {
		hasError: false
	};

	public componentDidCatch(error: any, errorInfo: React.ErrorInfo) {
		this.setState({
			error,
			errorInfo
		});
	}

	public render() {
		const errorName = this.state.error?.name;
		const generalTitle = 'Something went wrong.';

		if (this.state.hasError) {
			return (
				<Modal backdrop="static" show={this.state.hasError} size="lg">
					<Modal.Header>
						<Modal.Title className="text-danger">
							{errorName ? errorName : generalTitle}
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<h5>
							An unexpected error has occurred. Please click the button below to
							refresh the page. If this issue continues please contact{' '}
							<a href="mailto:support@routetrust.com">support@routetrust.com</a>
						</h5>
						<details style={{ whiteSpace: 'pre-wrap' }}>
							{this.state.error && this.state.error.toString()}
							<br />
							{this.state.errorInfo && this.state.errorInfo.componentStack}
						</details>
					</Modal.Body>

					<Modal.Footer>
						<Button variant="danger" onClick={() => window.location.reload()}>
							<i className="fas fa-redo" /> Reload page
						</Button>
					</Modal.Footer>
				</Modal>
			);
		}

		return this.props.children;
	}
}
