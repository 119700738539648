/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NumbersLiteIndexResponse = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        fileStreamId: {
            type: 'number',
        },
        number: {
            type: 'string',
            isRequired: true,
        },
        customerAccountId: {
            type: 'number',
        },
        customerSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        customerExternalAccountId: {
            type: 'string',
        },
        customerExternalSubAccountId: {
            type: 'string',
        },
        vendorExternalAccountId: {
            type: 'string',
        },
        vendorExternalSubAccountId: {
            type: 'string',
        },
        ingressTrunkGroupId: {
            type: 'string',
            isRequired: true,
        },
        ingressTrunkGroupLabel: {
            type: 'string',
        },
        vendorAccountId: {
            type: 'number',
        },
        vendorSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        egressTrunkGroupId: {
            type: 'string',
            isRequired: true,
        },
        egressTrunkGroupLabel: {
            type: 'string',
        },
        ingressOcn: {
            type: 'string',
        },
        ingressState: {
            type: 'string',
        },
        ingressOcnType: {
            type: 'string',
        },
        ingressRateCenterId: {
            type: 'number',
        },
        ingressCountry: {
            type: 'string',
        },
        attestation: {
            type: 'string',
        },
        cic: {
            type: 'string',
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        attempts: {
            type: 'number',
            isRequired: true,
        },
        connects: {
            type: 'number',
            isRequired: true,
        },
        asr: {
            type: 'number',
            isRequired: true,
        },
        duration: {
            type: 'number',
            isRequired: true,
        },
        aloc: {
            type: 'number',
            isRequired: true,
        },
        sub6: {
            type: 'number',
            isRequired: true,
        },
        sub18: {
            type: 'number',
            isRequired: true,
        },
        sub30: {
            type: 'number',
            isRequired: true,
        },
        pdd: {
            type: 'number',
            isRequired: true,
        },
        revenue: {
            type: 'number',
            isRequired: true,
        },
        cost: {
            type: 'number',
            isRequired: true,
        },
        margin: {
            type: 'number',
            isRequired: true,
        },
        negCallCount: {
            type: 'number',
            isRequired: true,
        },
        negDuration: {
            type: 'number',
            isRequired: true,
        },
        negRevenue: {
            type: 'number',
            isRequired: true,
        },
        negCost: {
            type: 'number',
            isRequired: true,
        },
        negMargin: {
            type: 'number',
            isRequired: true,
        },
        roboScore: {
            type: 'number',
            isRequired: true,
        },
    },
};