import { isEqual } from 'lodash-es';
import * as React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { NavLink } from 'react-router-dom';
import { IRouterBreadcrumb, RtUiRouter } from './RtUiRouter';

interface IRouterBreadcrumbsProps<ProfileType> {
	router: RtUiRouter<any, ProfileType>;
	currentPath: string;
	profile?: ProfileType;
}

interface IRouterBreadcrumbsState {
	breadcrumbs: IRouterBreadcrumb[];
}

export class RouterBreadcrumbs<ProfileType = any> extends React.Component<
	IRouterBreadcrumbsProps<ProfileType>,
	{}
> {
	public state: IRouterBreadcrumbsState = {
		breadcrumbs: []
	};

	public componentDidMount() {
		this.updateBreadcrumbs();
	}

	public componentDidUpdate(prevProps: IRouterBreadcrumbsProps<ProfileType>) {
		if (!isEqual(prevProps, this.props)) {
			this.updateBreadcrumbs();
		}
	}

	public async updateBreadcrumbs() {
		const { router, currentPath, profile } = this.props;
		let breadcrumbs: IRouterBreadcrumb[] = [];

		try {
			//Catch errors from router.getBreadcrumbs. Occurs when paging away sometimes
			breadcrumbs = await router.getBreadcrumbs(currentPath, profile);
		} catch {
			/* no failed logic */
		}

		this.setState({ breadcrumbs });
	}

	public render() {
		const { breadcrumbs } = this.state;

		if (breadcrumbs.length <= 0) {
			const height = 21;
			const width = 400;

			return (
				<article style={{ maxHeight: height, overflow: 'hidden' }}>
					<ContentLoader
						preserveAspectRatio="none"
						height={height}
						width={width}
					>
						<rect x={0} y={0} rx={5} ry={5} width={width} height={height} />
					</ContentLoader>
				</article>
			);
		}

		return (
			<Breadcrumb className="router-breadcrumb">
				<>
					{breadcrumbs.map((breadcrumb, index) => {
						return (
							<Breadcrumb.Item
								linkAs={NavLink}
								linkProps={{ to: breadcrumb.path }}
								key={`${breadcrumb.path}-${index}`}
								active={breadcrumb.isActive}
							>
								{breadcrumb.name}
							</Breadcrumb.Item>
						);
					})}
				</>
			</Breadcrumb>
		);
	}
}
