import { RtxSipApiRoutes } from 'RtExports/routes';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { AdministrationRouter } from 'RtUi/app/Administration/Administration.router';
import { CdrExportIndexResponse } from 'RtModels';

interface ICdrOneTimeExportContainerTabs
	extends IProfileApplicationContainerTabs {}

export const CdrOneTimeExportContainerTabs: ICdrOneTimeExportContainerTabs = {};

class CdrOneTimeExportRouterClass extends AdministrationRouter<
	CdrExportIndexResponse,
	CdrExportIndexResponse,
	ICdrOneTimeExportContainerTabs
> {
	constructor() {
		super(
			'One time CDR Export',
			'cdrExportControlOneTimeScheduler',
			'cdrExportId',
			CdrOneTimeExportContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.CdrExport);
	}

	public getProfileLabel() {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const CdrOneTimeExportRouter = new CdrOneTimeExportRouterClass();
