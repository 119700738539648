/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum SipUserType {
    User = 'user',
    Peer = 'peer',
    Friend = 'freind',
}