import { CountryDialCodeIndexResponse } from 'RtModels';
import { CountryHttp } from 'RtUi/app/user/lib/Http/CountryHttp';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumnConfigurationInstance,
	ResourceDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface CountryDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		CountryDialCodeIndexResponse,
		T
	> {
	header?: string;
}

export const CountryRenderCache =
	new ResourceCacheMap<CountryDialCodeIndexResponse>(
		new CountryHttp().getCountriesResource(),
		'iso3',
		'summary'
	);

export const CountryDataGridColumn = <T = any,>({
	header = 'Country',
	...config
}: CountryDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		header,
		resourceCacheMap: CountryRenderCache,
		...config
	});
};
