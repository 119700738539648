/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoboscoreParameterIndexResponse = {
    properties: {
        paramId: {
            type: 'number',
            isRequired: true,
        },
        parameterName: {
            type: 'string',
        },
        parameterSummary: {
            type: 'string',
        },
        parameterInputValue: {
            type: 'number',
            isRequired: true,
        },
        parameterOutputValue: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            format: 'date-time',
        },
        updatedBy: {
            type: 'number',
        },
        emailAddress: {
            type: 'string',
        },
    },
};