import {
	NumberCreateBillingRequest,
	ResourceType,
	NumberCreateResponse,
	NumberCreateSwitchRequest,
	NumberIndexRequest,
	NumberIndexResponse,
	NumberProfileResponse,
	NumberUpdateRequest,
	ResourceNumberBillingRecord,
	ResourceNumberSwitchRecord,
	NumberProfileRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtxDidApiRoutes } from 'RtExports/routes';
import { ExportTypes } from 'RtUi/components/data/Constants';
import { FileUtils } from 'RtUi/utils/file/FileUtils';

export interface IDidNumberIndex extends NumberIndexResponse {}
//@ts-ignore TODO: RTX_PUBLIC
export interface IDidNumberIndexRequest extends NumberIndexRequest {
	inTypes?: ResourceType[];
	notInTypes?: ResourceType[];
}
export interface IDidNumberProfile extends NumberProfileResponse {}
export interface INumberUpdateRequest extends NumberUpdateRequest {}
export interface INumberCreateResponse extends NumberCreateResponse {}
export interface IDidBillingInventoryRecord
	extends ResourceNumberBillingRecord {}
export interface IDidSwitchInventoryRecord extends ResourceNumberSwitchRecord {}
export interface INumberCreateBillingRequest
	extends NumberCreateBillingRequest {
	numbers: IDidBillingInventoryRecord[];
}
export interface INumberCreateSwitchRequest extends NumberCreateSwitchRequest {
	numbers: IDidSwitchInventoryRecord[];
}

export class DidNumbersResource extends HttpResource<
	IDidNumberIndex,
	IDidNumberProfile
> {
	protected fileUtils = new FileUtils();

	constructor() {
		super(RtxDidApiRoutes.Numbers);
	}

	public update(numberId: string, label: string) {
		const req: INumberUpdateRequest = { label };
		const body = JSON.stringify(req);
		const urlParams: NumberProfileRequest = { numberId };

		return this.fetchWithRoute<IDidNumberProfile>(
			RtxDidApiRoutes.Numbers.Update,
			{ body, urlParams }
		);
	}

	public updateBillingInventory(
		resourceId: number,
		overwrite: 0 | 1,
		numbers: IDidBillingInventoryRecord[]
	) {
		const req: INumberCreateBillingRequest = {
			resourceId,
			overwrite,
			numbers
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute<INumberCreateResponse>(
			RtxDidApiRoutes.NumbersBilling.Create,
			{ body }
		);
	}

	public updateSwitchInventory(
		resourceId: number,
		overwrite: 0 | 1,
		numbers: IDidBillingInventoryRecord[]
	) {
		const req: INumberCreateSwitchRequest = {
			resourceId,
			overwrite,
			numbers
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute<INumberCreateResponse>(
			RtxDidApiRoutes.NumbersSwitch.Create,
			{ body }
		);
	}

	/**
	 *
	 */
	public async export(exportType: ExportTypes, resourceId: number | undefined) {
		if (exportType === 'download') {
			const response = await this.rawFetchWithRoute<string>(
				RtxDidApiRoutes.NumbersCsv.Index,
				{
					params: {
						page: 0,
						pageSize: 100_100_100,
						...(resourceId ? { resourceId } : undefined)
					}
				}
			);
			const csvStr = await response.text();
			const blob = this.fileUtils.createCsvBlob(csvStr);
			await this.fileUtils.saveBlobAs(blob, 'DidNumbers.csv');
		} else {
			const records = await this.getAll();
			await this.fileUtils.copyDataToClipboard(records);
		}
	}
}
