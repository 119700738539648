import { PortsIndexResponseData } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';

export const getPortColumns = (
	callDateFormat?: string
): Array<DataGridColumn<PortsIndexResponseData>> => [
	FileStreamDataGridColumn({
		accessorKey: 'fileStreamId',
		header: 'File Stream',
		hiddenIfEmpty: true
	}),
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		format: callDateFormat
	}),
	IntegerDataGridColumn({
		accessorKey: 'accountId',
		header: 'Account',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'connectionId',
		header: 'Connection Id',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'externalAccountId',
		header: 'External Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'externalSubAccountId',
		header: 'External Sub Account Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'trunkGroupId',
		header: 'Trunk Group Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'trunkGroupLabel',
		header: 'Trunk Group Label',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'direction',
		header: 'Direction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dialCode',
		header: 'Dial Code',
		hiddenIfEmpty: true
	}),
	RateCenterDataGridColumn({
		header: 'Rate Center',
		accessorKey: 'rateCenterId',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'minports',
		header: 'Min Ports',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'maxports',
		header: 'Max Ports',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'avgports',
		header: 'Avg Ports',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		//cSpell:disable-next-line
		accessorKey: 'totalports',
		header: 'Total Ports',
		hiddenIfEmpty: true
	})
];
