import { FC, useMemo } from 'react';
import { RoutingCacheTypes } from 'RtModels';
import * as RoutingCacheUtils from 'RtUi/app/rt800/Cprs/lib/util/RoutingCacheUtils';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface IRadioRoutingCacheControlProps {
	value: RoutingCacheTypes;
	onChange: (newValue: RoutingCacheTypes) => void;
	hiddenTypes?: RoutingCacheTypes[];
	label?: string;
	required?: boolean;
	displayMode?: boolean;
}

export const RadioRoutingCacheControl: FC<
	React.PropsWithChildren<IRadioRoutingCacheControlProps>
> = ({
	value,
	onChange,
	required,
	displayMode,
	hiddenTypes,
	label = 'Routing Profile Type'
}) => {
	const options = useMemo(() => {
		const typeOptions: Array<
			ISimpleSelectFormControlOption<RoutingCacheTypes>
		> = [
			{
				value: RoutingCacheTypes.TollFree,
				label: RoutingCacheUtils.getRoutingCacheTypeName(
					RoutingCacheTypes.TollFree
				)
			},
			{
				value: RoutingCacheTypes.Template,
				label: RoutingCacheUtils.getRoutingCacheTypeName(
					RoutingCacheTypes.Template
				)
			},
			{
				value: RoutingCacheTypes.LcrGenerator,
				label: RoutingCacheUtils.getRoutingCacheTypeName(
					RoutingCacheTypes.LcrGenerator
				)
			}
		];

		if (!hiddenTypes) {
			return typeOptions;
		}

		return typeOptions.filter(
			(typeOption) => !hiddenTypes.includes(typeOption.value)
		);
	}, [hiddenTypes]);

	const selectValue = useMemo(
		() => options.find((opt) => opt.value === value)!,
		[value, options]
	);

	return (
		<SimpleSelectFormControl<RoutingCacheTypes>
			label={label}
			clearable={false}
			required={required}
			displayMode={displayMode}
			value={selectValue}
			onChange={(newValue) => onChange(newValue.value)}
			options={options}
		/>
	);
};
