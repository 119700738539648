//import * as React from 'react';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import {
	ResourceConfigurationIndexResponse,
	ResourceType,
	ResourceIndexRequest
} from 'RtModels';
import { ResourceConfigurationResource } from 'RtUi/app/rtCommon/Resources/lib/resources/ResourceConfigurationResource';

interface IResourceSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<ResourceConfigurationIndexResponse, isMulti> {
	//integrationType?: IntegrationType;
	resourceType?: ResourceType;
	resourceParams?: ResourceIndexRequest;
}

export class ResourceConfigurationSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	ResourceConfigurationIndexResponse,
	isMulti,
	IResourceSelectProps<isMulti>
> {
	public resourceClass = ResourceConfigurationResource;
	public state: ISelectFormControlState<ResourceConfigurationIndexResponse> = {
		formLabel: 'Resource',
		valueKey: 'resourceConfigurationId',
		labelKey: 'label'
	};

	public componentDidMount() {
		this.checkAndSetGetAllParams();

		super.componentDidMount();
	}

	public componentDidUpdate(prevProps: IResourceSelectProps<isMulti>) {
		this.checkAndSetGetAllParams();

		super.componentDidUpdate(prevProps);
	}

	/**
	 * @override
	 */
	public async getData() {
		let resources = await super.getData();
		const { resourceType } = this.props;

		if (typeof resourceType === 'number') {
			resources = resources.filter((r) => r.resourceTypeId === resourceType);
		}

		return resources;
	}

	public checkAndSetGetAllParams() {
		if (this.props.resourceParams) {
			this.setGetAllParams(this.props.resourceParams);
		}
	}
}
