import { FC } from 'react';
import { useBoolean } from 'react-use';
import { Button, ListGroup } from 'react-bootstrap';
import { DataFilter } from 'RtModels';
import { DataFilterOperandLabel } from 'RtUi/app/rtVue/common/lib/components/DataFilterOperandLabel';
import { VueSearchResultButtons } from 'RtUi/app/rtVue/common/lib/components/VueSearchResultButtons';
import {
	IFilterSearchResultSection,
	IFilterSearchResult
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';

const MAX_RESULT_COUNT = 3;

export const VueSearchResultsList: FC<
	React.PropsWithChildren<{
		filterResults: IFilterSearchResultSection;
		filters: DataFilter[];
		toggleOnChange: (filterRes: IFilterSearchResult, key: string) => void;
	}>
> = ({ filterResults, filters, toggleOnChange }) => {
	const [seeAll, toggleSeeAll] = useBoolean(false);

	const results =
		filterResults.results.length > MAX_RESULT_COUNT && !seeAll
			? filterResults.results.slice(0, MAX_RESULT_COUNT)
			: filterResults.results;

	return (
		<>
			<ListGroup variant="flush">
				{results.map((searchResult) => (
					<ListGroup.Item
						key={searchResult.operand.value}
						className="p-0 d-flex justify-content-between align-items-center border-0 mb-4"
					>
						<DataFilterOperandLabel operand={searchResult.operand} />
						<VueSearchResultButtons
							toggleOnChange={toggleOnChange}
							filters={filters}
							filterRes={searchResult}
						/>
					</ListGroup.Item>
				))}
			</ListGroup>
			{filterResults.results.length > MAX_RESULT_COUNT && (
				<div className="text-center">
					<Button
						variant="link"
						onClick={() => {
							toggleSeeAll();
						}}
					>
						See {seeAll ? 'Less' : 'All'}{' '}
						{!seeAll && `(${filterResults.results.length})`}
					</Button>
				</div>
			)}
		</>
	);
};
