import { SipGatewayResource } from 'RtUi/app/rtSip/Connections/lib/resources/SipGatewayResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { GatewayIndexResponse } from 'RtModels';

interface IGatewaySelectProps
	extends ISelectFormControlProps<GatewayIndexResponse> {}

export class GatewaySelect extends SelectFormControl<
	GatewayIndexResponse,
	false,
	IGatewaySelectProps
> {
	public resourceClass = SipGatewayResource;
	public state: ISelectFormControlState<GatewayIndexResponse> = {
		formLabel: 'Gateway',
		valueKey: 'partitionSipGatewayId',
		labelKey: 'listenFqdn'
	};

	constructor(props: IGatewaySelectProps) {
		super(props);
	}

	public optionRenderer = (gateway: GatewayIndexResponse) => {
		return <span>{gateway.listenFqdn}</span>;
	};

	/**
	 * @override
	 */
	public getData() {
		return super.getData().then((records) => {
			return records;
		});
	}
}
