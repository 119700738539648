import { RtAdmApiRoutes } from 'RtExports/routes';
import { ServerCreateRequest, ServerProfileResponse } from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ServerResource extends HttpResource<ServerProfileResponse> {
	constructor() {
		super(RtAdmApiRoutes.Servers);
	}

	public createServer(request: ServerCreateRequest) {
		const body = JSON.stringify(request);
		return this.fetchWithRoute<ServerProfileResponse>(
			RtAdmApiRoutes.Servers.Create,
			{ body }
		);
	}

	public createNew(): ServerCreateRequest {
		return {
			hostName: '',
			publicIpAddress: '',
			privateIpAddress: '',
			networkId: 0,
			isActive: 1,
			isOnline: 1,
			allowConfigRefresh: 1,
			cdrWorkerId: 0
		};
	}
}
