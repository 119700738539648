import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

// exported definitions
// ======================================================================

export class CprColState extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.State;

	public readonly allowOther = true;
	public readonly valueLimit = 4;
	public readonly valueRegExp = /^[A-Z]{2}$/;

	public readonly isLergNode: boolean = true;
	public readonly isTreeNode = true;

	protected readonly possibles = CprLergCache.States;
	protected readonly possiblesByKey = CprLergCache.CprLergByState;

	protected readonly canadaPossibles = CprLergCache.CanadaStates;
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaStatesByKey;

	protected readonly canLabel = true;

}
