import { useCallback, useMemo } from 'react';
import { SipAgentIndexRequest, SipAgentIndexResponse } from 'RtModels';
import { SipAgentsResource } from 'RtUi/app/AccountManagement/SipAgents/lib/resources/SipAgentsResource';
import { useGetSipAgents } from 'RtUi/app/AccountManagement/SipAgents/lib/services';
import { SipAgentRouterClass } from 'RtUi/app/AccountManagement/SipAgents/SipAgent.router';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSipAgentsColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/sipAgents';
import { Confirmation } from 'RtUi/components/form/Confirmation';

export interface ISipAgentsGridProps {
	router: SipAgentRouterClass;
	resourceParams?: SipAgentIndexRequest;
	autoFocusFilter?: boolean;
}

export const SipAgentsGrid = ({
	router,
	resourceParams
}: ISipAgentsGridProps): JSX.Element => {
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetSipAgents(resourceParams);
	const resource = useMemo(() => new SipAgentsResource(), []);

	const onDeleteHandler = useCallback(
		async (row: SipAgentIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				row.sipAgentId.toString()
			);

			if (!confirm) {
				return;
			}

			await resource.delete(row.sipAgentId);
			refetch();
		},
		[refetch, resource]
	);

	const columns = useMemo(
		() => getSipAgentsColumns(onDeleteHandler),
		[onDeleteHandler]
	);

	return (
		<DataGrid<SipAgentIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'accountManagement_sipAgents'}
			router={router}
			disableExport
		/>
	);
};
