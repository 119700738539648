import * as React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

export interface ICheckboxButtonOption<T = string> {
	value: T;
	label?: React.ReactNode;
}

interface ICheckboxButtonsProps<T = string> {
	options: Array<ICheckboxButtonOption<T>>;
	value?: T[];
	name?: string;
	onChange: (value: T[]) => void;
	chunk?: number;
	required?: boolean;
}

export class CheckboxButtons<T = string> extends React.Component<
	ICheckboxButtonsProps<T>,
	{}
> {
	public isSelected(value: T) {
		return this.props.value && this.props.value.some((opt) => opt === value);
	}

	public toggleSelected(value: T) {
		const values = this.props.value ? [...this.props.value] : [];
		const index = values.indexOf(value);

		if (index >= 0) {
			values.splice(index, 1);
		} else {
			values.push(value);
		}

		this.props.onChange(values);
	}

	public render() {
		return (
			<section style={{ position: 'relative' }}>
				<ButtonGroup className="btn-group-checkbox">
					{this.props.options.map((opt) => (
						<Button
							key={String(opt.label ?? opt.value)}
							variant="radio"
							active={this.isSelected(opt.value)}
							onClick={() => this.toggleSelected(opt.value)}
							style={{ whiteSpace: 'nowrap' }}
						>
							<>
								{this.isSelected(opt.value) ? (
									<i className="fas fa-fw fa-check-square text-primary" />
								) : (
									<i className="far fa-fw fa-square" />
								)}
								&nbsp;
								{opt.label ?? opt.value}
							</>
						</Button>
					))}
				</ButtonGroup>
				<input
					type="text"
					required={this.props.required}
					minLength={1}
					name={this.props.name}
					value={this.props.value ? this.props.value.join('') : ''}
					onChange={(e) => void 0}
					style={{
						height: 1,
						width: 0,
						opacity: 0.01,
						position: 'absolute',
						left: '50%',
						bottom: 0
					}}
				/>
			</section>
		);
	}
}
