import { RateCenterTypes } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DefaultDataGridColumn,
	FallbackHeaderDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface RateCenterTypeDataGridColumnConfiguration<T>
	extends FallbackHeaderDataGridColumnConfiguration<T> {}

export const getRateCenterTypeLabel = (value: RateCenterTypes | string) => {
	switch (value) {
		case RateCenterTypes.COUNTRY: {
			return 'Country';
		}
		case RateCenterTypes.FIXED: {
			return 'Fixed';
		}
		case RateCenterTypes.MOBILE: {
			return 'Mobile';
		}
		case RateCenterTypes.RURAL: {
			return 'Rural';
		}
		case RateCenterTypes.SPECIAL: {
			return 'Special';
		}
		case RateCenterTypes.VOIP: {
			return 'Voip';
		}
		case RateCenterTypes.WIRELESS: {
			return 'Wireless';
		}
		default: {
			return value;
		}
	}
};

export const RateCenterTypeDataGridColumn = <T = any,>({
	header = 'Rate Center Type',
	...config
}: RateCenterTypeDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return DefaultDataGridColumn({
		header,
		exportValue: (value: RateCenterTypes) => getRateCenterTypeLabel(value),
		getValue: ({ cell }) =>
			getRateCenterTypeLabel(cell.getValue<RateCenterTypes>()),
		...config
	});
};
