import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

import { PeeringTypeIndexResponse } from 'RtModels';
import { PeeringTypeResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/PeeringTypeResource';

interface IPeeringTypeSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<PeeringTypeIndexResponse, IsMulti> {
	valueKey?: keyof PeeringTypeIndexResponse;
}
interface IPeeringTypeSelectState
	extends ISelectFormControlState<PeeringTypeIndexResponse> {}

export class PeeringTypeSelect<
	IsMulti extends boolean = true
> extends SelectFormControl<
	PeeringTypeIndexResponse,
	IsMulti,
	IPeeringTypeSelectProps<IsMulti>,
	IPeeringTypeSelectState
> {
	public resourceClass = PeeringTypeResource;
	public state: IPeeringTypeSelectState = {
		formLabel: 'Peering Type',
		valueKey: 'peeringTypeId',
		labelKey: 'label'
	};
}
