import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianDnoNumberTypes,
	GuardianDoNotOriginateIndexRequest
} from 'RtModels';
import { DoNotOriginateRouter } from 'RtUi/app/rtVue/DoNotOriginate/DoNotOriginate.router';
import { DoNotOriginateBulkUploadForm } from 'RtUi/app/rtVue/DoNotOriginate/lib/forms/DoNotOriginateBulkUploadForm';
import { DoNotOriginateFormEditor } from 'RtUi/app/rtVue/DoNotOriginate/lib/forms/DoNotOriginateFormEditor';
import { DoNotOriginateGrid } from 'RtUi/app/rtVue/DoNotOriginate/lib/grids/DoNotOriginateGrid';
import { DoNotOriginateNumberTypeToNameMap } from 'RtUi/app/rtVue/DoNotOriginate/lib/utils/Constants';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { SimpleSelectFormControl } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useMemo, useState } from 'react';

export function DoNotOriginateIndexContainer() {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true },
		CreateGuardianDoNotOriginate: {
			header: 'Create Do Not Originate',
			rtRoute: RtVueApiRoutes.GuardianDoNotOriginate.Create,
			isAction: true
		},
		BulkUploadGuardianDoNotOriginate: {
			header: 'Bulk Upload Do Not Originate',
			rtRoute: RtVueApiRoutes.GuardianDoNotOriginateBulk.Create,
			isAction: true
		}
	});

	const [isActive] = useState(1);
	const [ani, setAni] = useState('');
	const [numberType, setNumberType] = useState<any>();
	const [numberTypeSelect, setNumberTypeSelect] = useState<any>(null);

	const resourceParams = useMemo(() => {
		const params: GuardianDoNotOriginateIndexRequest = {};

		if (isActive >= 0) {
			params.isActive = isActive;
		}

		if (ani) {
			params.ani = ani;
		}

		if (numberType) {
			params.numberType = numberType;
		}

		return params;
	}, [isActive, ani, numberType]);

	const searchDoNotOriginate = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		try {
			event.preventDefault();
			setActiveTab('Results');
		} catch (e) {}
	};

	return (
		<TabbedLayout
			router={DoNotOriginateRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm onSubmit={(e) => searchDoNotOriginate(e)}>
					<InputFormControl
						label="ANI"
						type="number"
						onChange={setAni}
						value={ani}
					/>
					<SimpleSelectFormControl<GuardianDnoNumberTypes>
						clearable
						label="Number Type"
						value={numberTypeSelect}
						onChange={(numberTypeSelect) => {
							setNumberTypeSelect(numberTypeSelect);
							setNumberType(numberTypeSelect.value);
						}}
						options={[
							{
								value: GuardianDnoNumberTypes.Dno,
								label: DoNotOriginateNumberTypeToNameMap.get(
									GuardianDnoNumberTypes.Dno
								)!
							},
							{
								value: GuardianDnoNumberTypes.WhiteList,
								label: DoNotOriginateNumberTypeToNameMap.get(
									GuardianDnoNumberTypes.WhiteList
								)!
							}
						]}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<DoNotOriginateGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateGuardianDoNotOriginate}>
				<DoNotOriginateFormEditor
					onSuccess={() => setActiveTab(tabs.Results.header)}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.BulkUploadGuardianDoNotOriginate}>
				<DoNotOriginateBulkUploadForm
					onSuccess={() => setActiveTab(tabs.Results.header)}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
}

DoNotOriginateRouter.setIndexRtUiFunctionalComponent(
	DoNotOriginateIndexContainer,
	{
		groupName: 'Configuration',
		orderPriority: 0,
		groupOrderPriority: 90
	}
);
