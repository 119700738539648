import {
	LcrTaskProfileResponse,
	LcrTaskResultIndexResponse,
	MinuteProfile,
	RespOrgIndexResponse,
	TemplateIndexResponse
} from 'RtModels';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { TemplateRouter } from 'RtUi/app/rt800/Templates/Template.router';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import { LcrMinuteSelect } from 'RtUi/app/rtLco/Minutes/lib/controls/LcrMinutesSelect';
import { LcrTaskResultsGrid } from 'RtUi/app/rtLco/Tasks/lib/grids/LcrTaskResultsGrid';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import * as moment from 'moment-timezone';
import { Col, Row } from 'react-bootstrap';
import { LcrTaskRouter } from './LcrTask.router';
import { LcrTasksResource } from './lib/resources/LcrTasksResource';

interface ILcrTaskContainerState {
	activeTab: string;
	selectedLcrTaskId: string;
	respOrg?: RespOrgIndexResponse;
	template?: TemplateIndexResponse;
	minute?: MinuteProfile;
	selectedLcrTask?: LcrTaskProfileResponse;
	selectedLcrTaskResult?: LcrTaskResultIndexResponse;
	taskCarrierResultDrawerIsOpen?: boolean;
}

const lcrTasksResource = new LcrTasksResource();

@LcrTaskRouter.getProfileRtUiController()
export class LcrTaskProfileContainer extends ProfileApplicationContainer<
	{},
	ILcrTaskContainerState
> {
	public Tabs = LcrTaskRouter.getProfileTabs();

	public state: ILcrTaskContainerState = {
		activeTab: this.Tabs.Results.header,
		selectedLcrTaskId: ''
	};

	public componentDidMount() {
		const selectedLcrTaskId = this.getIdParam();

		lcrTasksResource
			.get(selectedLcrTaskId)
			.then((selectedLcrTask) => this.setState({ selectedLcrTask }));

		this.setState({ selectedLcrTaskId });
	}

	public toggleTaskCarrierResultDrawerIsOpen(
		taskCarrierResultDrawerIsOpen = !this.state.taskCarrierResultDrawerIsOpen
	) {
		this.setState({ taskCarrierResultDrawerIsOpen });
	}

	public render() {
		const { selectedLcrTask } = this.state;

		if (!selectedLcrTask) {
			return <Loading />;
		}

		return (
			<TabbedLayout<LcrTaskProfileResponse>
				router={LcrTaskRouter}
				profile={selectedLcrTask}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<Row>
						<Col lg={8}>
							<RtUiForm hideButtons className="mb-4">
								<Row>
									<Col md>
										<RespOrgSelect
											displayMode
											onChange={(respOrg: RespOrgIndexResponse) =>
												this.setState({ respOrg })
											}
											value={this.state.respOrg}
											initialOptionId={selectedLcrTask.respOrgId}
										/>
									</Col>
									<Col md>
										<TemplateSelect
											displayMode
											onChange={(template) => this.setState({ template })}
											value={this.state.template}
											initialOptionId={selectedLcrTask.templateName}
											linkTo={TemplateRouter.getProfileRoute(
												selectedLcrTask.templateName
											)}
										/>
									</Col>
								</Row>
								<Row>
									<Col md>
										<InputFormControl
											label="LCR Task"
											displayMode
											value={String(selectedLcrTask.lcrTaskId)}
										/>
									</Col>
									<Col md>
										<LcrMinuteSelect
											displayMode
											onChange={(minute) => this.setState({ minute })}
											value={this.state.minute}
											initialOptionId={String(selectedLcrTask.minuteProfileId)}
										/>
									</Col>
								</Row>
								<Row>
									<Col md>
										<PrecisionFormControl
											label="CPR Size Limit"
											displayMode
											value={selectedLcrTask.cprSizeLimit}
										/>
									</Col>
									<Col md>
										<PrecisionFormControl
											label="CPR Row Limit"
											displayMode
											value={selectedLcrTask.cprRowLimit}
										/>
									</Col>
								</Row>
								<Row>
									<Col md>
										<RadioFormControl<number>
											label="Allow as Default Only"
											displayMode
											value={selectedLcrTask.allowAsDefaultOnly}
											options={[
												{ value: 0, label: 'No' },
												{ value: 1, label: 'Yes' }
											]}
										/>
									</Col>
									<Col md>
										<PrecisionFormControl
											label="Skip Savings Limit"
											displayMode
											required
											precision={2}
											value={selectedLcrTask.skipSavingsLimit}
										/>
									</Col>
								</Row>
							</RtUiForm>
						</Col>
						<Col lg={4}>
							<RtUiForm hideButtons className="mb-4">
								<InputFormControl
									label="Label"
									displayMode
									value={selectedLcrTask.taskLabel}
									initialValue={selectedLcrTask.taskLabel}
								/>
								<DatetimeFormControl
									label="Created At"
									displayMode
									value={moment(selectedLcrTask.createdAt)}
								/>
								<DatetimeFormControl
									label="Started At"
									displayMode
									value={
										selectedLcrTask.startedAt
											? moment(selectedLcrTask.startedAt)
											: undefined
									}
								/>
								<DatetimeFormControl
									label="Completed At"
									displayMode
									value={
										selectedLcrTask.completedAt
											? moment(selectedLcrTask.completedAt)
											: undefined
									}
								/>
							</RtUiForm>
							<RtUiForm hideButtons>
								<h6>
									<b>Contact Info</b>
								</h6>
								<InputFormControl
									label="Email"
									displayMode
									value={selectedLcrTask.email}
								/>
							</RtUiForm>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Results}>
					<LcrTaskResultsGrid
						resourceParams={{ lcrTaskId: Number(this.state.selectedLcrTaskId) }}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
