/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DirectConnectUpdateRequest = {
    properties: {
        secret: {
            type: 'string',
            isRequired: true,
        },
        callerIdName: {
            type: 'string',
            isRequired: true,
        },
        callerIdNumber: {
            type: 'string',
            isRequired: true,
        },
        connectionId: {
            type: 'number',
            isRequired: true,
        },
    },
};