import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { LcoApplicationRouter } from 'RtUi/app/rtLco/rtLco.router';
import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	CprRateSheetIndexResponse,
	CprRateSheetProfileResponse
} from 'RtModels';

interface IRateSheetContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Results: TabbedLayoutTabProps;
}

export const RateSheetContainerTabs: IRateSheetContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Results: {
		header: 'Results'
	}
};

class RateSheetRouterClass extends LcoApplicationRouter<
	CprRateSheetIndexResponse,
	CprRateSheetProfileResponse,
	IRateSheetContainerTabs
> {
	constructor() {
		super(
			'Cost Rate Management',
			'cprRateSheets',
			'cprRateSheetId',
			RateSheetContainerTabs
		);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.CprRateSheets);
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: CprRateSheetProfileResponse) {
		return String(profile.cprRateSheetId);
	}
}

export const RateSheetRouter = new RateSheetRouterClass();
