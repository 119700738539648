import * as React from 'react';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { UserHttp } from 'RtUi/app/user/lib/Http/UserHttp';
import { Alert } from 'react-bootstrap';
import { UserIndexResponse } from 'RtModels';

interface IUserAdminPasswordResetFormProps {
	user: UserIndexResponse;
}

interface IUserAdminPasswordResetFormState {
	isSubmitting: boolean;
	error?: any;
	passwordResetWasSent: boolean;
}

export class UserAdminPasswordResetForm extends React.Component<
	IUserAdminPasswordResetFormProps,
	IUserAdminPasswordResetFormState
> {
	public state: IUserAdminPasswordResetFormState = {
		isSubmitting: false,
		error: undefined,
		passwordResetWasSent: false
	};

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		try {
			this.setState({ isSubmitting: true });

			const userHttp = new UserHttp();
			const { user } = this.props;

			const { passwordResetWasSent } = await userHttp.forceResetPassword(
				user.userId,
				user.email
			);

			this.setState({ isSubmitting: false, passwordResetWasSent });
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	}

	public render() {
		const { user } = this.props;
		const { passwordResetWasSent } = this.state;

		if (passwordResetWasSent) {
			return (
				<Alert
					variant="success"
					className="d-flex justify-content-start align-items-start"
					style={{ maxWidth: 600 }}
				>
					<i className="fas fa-check-circle me-2" />
					<section>
						{user.firstName} {user.lastName}&apos;s password has been reset!
					</section>
				</Alert>
			);
		}

		return (
			<RtUiNarrowForm
				createMode
				displayMode={false}
				submitButtonText="Reset Password"
				submitButtonColor="warning"
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onSubmit={(evt) => this.onSubmit(evt)}
			>
				<Alert variant="warning-outline">
					<p>
						Resetting {user.firstName} {user.lastName}&apos;s password will send
						them an email with instructions on how to create a new password to
						login.
						<br />
						<br />
						<b>Warning:</b> {user.firstName} {user.lastName} will{' '}
						<b>not be able to login</b> until they have followed the
						instructions in the email.
					</p>
				</Alert>
			</RtUiNarrowForm>
		);
	}
}
