import {
	CreditLimitProfileResponse,
	CreditLimitProfileRequest,
	CreditLimitUpdateRequest
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class AccountCreditLimitResource extends HttpResource<
	CreditLimitProfileResponse,
	CreditLimitProfileResponse
> {
	constructor(private accountId: number) {
		super();

		const urlParams: CreditLimitProfileRequest = {
			accountId: this.accountId
		};

		this.setApiModule(RtxSipApiRoutes.CreditLimits, urlParams);
	}

	public get() {
		const urlParams: CreditLimitProfileRequest = {
			accountId: this.accountId
		};

		return this.fetchWithRoute<CreditLimitProfileResponse>(
			RtxSipApiRoutes.CreditLimits.Profile,
			{
				urlParams
			}
		);
	}

	public update(creditLimitUpdateRequest: CreditLimitUpdateRequest) {
		const urlParams: CreditLimitProfileRequest = { accountId: this.accountId };

		const body = JSON.stringify(creditLimitUpdateRequest);

		return this.fetchWithRoute<CreditLimitProfileResponse>(
			RtxSipApiRoutes.CreditLimits.Update,
			{
				body,
				urlParams
			}
		);
	}
}
