import { BlockedNumber } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';

export const getBlockedNumbersColumns = (
	removeBlockNumber: (row: BlockedNumber) => void
): Array<DataGridColumn<BlockedNumber>> => [
	DeleteDataGridColumn({
		onClick: (row) => removeBlockNumber(row)
	}),
	PhoneNumberDataGridColumn({
		accessorKey: 'phoneNumber',
		header: 'Phone Number'
	}),
	DefaultDataGridColumn({
		accessorKey: 'iso3',
		header: 'Country Iso'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	BooleanDataGridColumn({
		accessorKey: 'blockAni',
		header: 'Block ANI'
	}),
	BooleanDataGridColumn({
		accessorKey: 'blockDestination',
		header: 'Block Destination'
	})
];
