import { ServerGroupMemberIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';

export const getServerGroupMemberColumns = (): Array<
	DataGridColumn<ServerGroupMemberIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'serverGroupMemberId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'serverId',
		header: 'Server Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'serverGroupId',
		header: 'Server Group Id'
	})
];
