import { isEmpty } from 'lodash-es';
import * as React from 'react';
import { toast } from 'react-toastify';

interface IErrorBoundaryState {
	hasError: boolean;
	errorInfo?: React.ErrorInfo;
	error?: Error;
}

export class ErrorBoundary extends React.Component<
	React.PropsWithChildren<{}>,
	IErrorBoundaryState
> {
	public static getDerivedStateFromError() {
		return { hasError: true };
	}

	public state: IErrorBoundaryState = {
		hasError: false
	};

	public componentDidCatch(error: any, errorInfo: React.ErrorInfo) {
		this.setState({
			error,
			errorInfo
		});
	}

	public render() {
		if (!isEmpty(this.state.error)) {
			toast.error(`Something went wrong. Error: ${this.state.error.message}`);
		}

		return this.props.children;
	}
}
