import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { MessagesSummaryIndexResponse } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getMessagesSummaryColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/messagesSummary';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

interface IMessagesSummaryGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<MessagesSummaryIndexResponse> | null;
	callDateFormat?: string;
	isLoading?: boolean;
}

export const MessagesSummaryGrid = ({
	resource,
	isLoading,
	callDateFormat
}: IMessagesSummaryGridProps) => {
	const { data = [] } = useQuery<
		MessagesSummaryIndexResponse[] | undefined,
		Error
	>([`useGetMessagesSummaryReport`, resource], () => resource?.getAll());
	const columns = useMemo(
		() => getMessagesSummaryColumns(callDateFormat),
		[callDateFormat]
	);

	return (
		<DataGrid<MessagesSummaryIndexResponse>
			pageName="rtVue_messagesSummary"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
