import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import {
	QosManagementIndexRequest,
	QosManagementIndexResponse
} from 'RtModels';
import { QosManagementRouter } from 'RtUi/app/rtSip/QosManagement/QosManagement.router';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useState } from 'react';
import { QosManagementResource } from 'RtUi/app/rtSip/QosManagement/lib/resource/QosManagementResource';
import { RtError } from 'RtUi/utils/errors/RtError';
import { QosManagementBulkUpdateForm } from 'RtUi/app/rtSip/QosManagement/lib/forms/QosManagementBulkUpdateForm';
import { QosManagementSearchForm } from 'RtUi/app/rtSip/QosManagement/lib/forms/QosManagementSearchForm';

export const QosManagementIndexContainer = (): JSX.Element => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<RtError>();
	const [qosManagementRequestData, setQosManagementRequestData] =
		useState<QosManagementIndexRequest>();
	const [qosManagementResponseData, setQosManagementResponseData] =
		useState<QosManagementIndexResponse>();

	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search', isDefault: true },
		Bulk: {
			header: 'Qos Management Bulk Update',
			isHidden: true
		}
	});

	const handleSubmitForm = async (data: QosManagementIndexRequest) => {
		setQosManagementResponseData(undefined);
		setQosManagementRequestData(data);
		setIsSubmitting(true);
		setError(undefined);

		try {
			const resource = new QosManagementResource();
			const response = await resource.get(data);
			if (!response.rateCenterQty && !response.subscriptionQty) {
				setError(new RtError({ message: 'There is not anything to change' }));
				return;
			}

			setQosManagementResponseData(response);
			setActiveTab(tabs.Bulk.header);
		} catch (e: any) {
			setError(e);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onBulkUpdateSuccess = () => {
		setQosManagementResponseData(undefined);
		setQosManagementRequestData(undefined);
		setActiveTab(tabs.Search.header);
	};

	return (
		<TabbedLayout
			router={QosManagementRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<QosManagementSearchForm
					value={qosManagementRequestData}
					onChange={handleSubmitForm}
					error={error}
					isSubmitting={isSubmitting}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Bulk}>
				<QosManagementBulkUpdateForm
					requestData={qosManagementRequestData as QosManagementIndexRequest}
					responseData={qosManagementResponseData as QosManagementIndexResponse}
					onSuccess={onBulkUpdateSuccess}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

QosManagementIndexContainer.displayName = 'QosManagementIndexContainer';
QosManagementRouter.setIndexRtUiFunctionalComponent(
	QosManagementIndexContainer,
	{ groupName: 'Network Ops' }
);
