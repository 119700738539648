import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	LcrScenarioIndexResponse,
	TemplateLcrScenarioIndexRequest
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchLcrScenarios = async (
	queryParams?: TemplateLcrScenarioIndexRequest
): Promise<FullResponse<LcrScenarioIndexResponse[]>> => {
	return handleGetRequest<LcrScenarioIndexResponse[], true>(
		Rt800ApiRoutes.LcrScenarios.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetLcrScenarios = (
	queryParams?: TemplateLcrScenarioIndexRequest
) => {
	return useQuery<FullResponse<LcrScenarioIndexResponse[]>, Error>(
		['getLcrScenarios', queryParams],
		() => fetchLcrScenarios(queryParams)
	);
};
