/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianAlertRecipientIndexResponse = {
    properties: {
        guardianAlertRecipientId: {
            type: 'number',
            isRequired: true,
        },
        guardianAlertId: {
            type: 'number',
            isRequired: true,
        },
        messageTypeIds: {
            type: 'Array',
            isRequired: true,
        },
        userId: {
            properties: {
            },
            isRequired: true,
        },
        emailAliasId: {
            properties: {
            },
            isRequired: true,
        },
        alertTypeId: {
            type: 'number',
            isRequired: true,
        },
    },
};