/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum JobStatusId {
    CREATING = 1,
    WAITING_TO_RUN = 2,
    RUN_REQUESTED = 10,
    RUNNING = 20,
    WAITING_FOR_TASK_MANUAL_COMPLETION = 30,
    WAITING_FOR_RESPONSE = 31,
    COMPLETED_NO_ERROR = 40,
    COMPLETED_WITH_WARNING = 41,
    COMPLETED_WITH_ERROR = 42,
}