import {
	IntegrationTaskTypeIndexResponse,
	TFActionResponse,
	TFActionRequest
} from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { RtxFlowApiRoutes } from 'RtExports/routes';

export class UploadTollFreeCarrierConnect extends ArrayResource<IntegrationTaskTypeIndexResponse> {
	constructor() {
		super('integrationTaskTypeId');
		this.setApiRouteForGetAll(RtxFlowApiRoutes.TollFree.Index);
	}

	public activateTollFree(request: TFActionRequest) {
		const body = JSON.stringify(request);
		return this.fetchWithRoute<TFActionResponse>(
			RtxFlowApiRoutes.TollFree.Create,
			{ body }
		);
	}

	public deleteTollFree(request: TFActionRequest) {
		const body = JSON.stringify(request);
		return this.fetchWithRoute<TFActionResponse>(
			RtxFlowApiRoutes.TollFree.Delete,
			{ body }
		);
	}

	public rerouteTollFree(request: TFActionRequest) {
		const body = JSON.stringify(request);
		return this.fetchWithRoute<TFActionResponse>(
			RtxFlowApiRoutes.TollFree.Update,
			{ body }
		);
	}
}
