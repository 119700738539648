import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianAlertHistoryIndexRequest,
	GuardianAlertHistoryIndexResponse,
	GuardianAlertHistoryNoteIndexResponse
} from 'RtModels';
import { AlertHistoryNoteResource } from 'RtUi/app/rtGuardian/AlertHistory/lib/resources/AlertHistoryNoteResource';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchGuardianAlertHistories = async (
	urlParams?: GuardianAlertHistoryIndexRequest
): Promise<FullResponse<GuardianAlertHistoryIndexResponse[]>> => {
	return handleGetRequest<GuardianAlertHistoryIndexResponse[], true>(
		RtVueApiRoutes.GuardianAlertHistory.Index,
		{
			urlParams,
			includeFullResponse: true
		}
	);
};

export const useGetGuardianAlertHistories = (
	resourceParams?: GuardianAlertHistoryIndexRequest
) => {
	return useQuery<FullResponse<GuardianAlertHistoryIndexResponse[]>, Error>(
		'getGuardianAlertHistory',
		() => fetchGuardianAlertHistories(resourceParams),
		{ enabled: true }
	);
};

const fetchGuardianAlertHistory = async (
	guardianAlertHistoryId: number
): Promise<FullResponse<GuardianAlertHistoryIndexResponse>> => {
	return handleGetRequest<GuardianAlertHistoryIndexResponse, true>(
		RtVueApiRoutes.GuardianAlertHistory.Profile,
		{
			urlParams: {
				guardianAlertHistoryId
			},
			includeFullResponse: true
		}
	);
};

export const useGetGuardianAlertHistory = (guardianAlertHistoryId: number) => {
	return useQuery<FullResponse<GuardianAlertHistoryIndexResponse>, Error>(
		[
			`getGuardianAlertHistory${guardianAlertHistoryId}`,
			guardianAlertHistoryId
		],
		() => fetchGuardianAlertHistory(guardianAlertHistoryId),
		{ enabled: true }
	);
};

const fetchGuardianAlertHistoryNotes = async (
	guardianAlertHistoryId: number
): Promise<GuardianAlertHistoryNoteIndexResponse[]> => {
	const data = await new AlertHistoryNoteResource(
		guardianAlertHistoryId
	).getAll();
	return data;
};

export const useGetGuardianAlertHistoryNotes = (
	guardianAlertHistoryId: number
) => {
	return useQuery<GuardianAlertHistoryNoteIndexResponse[], Error>(
		[
			`getGuardianAlertHistoryNotes${guardianAlertHistoryId}`,
			guardianAlertHistoryId
		],
		() => fetchGuardianAlertHistoryNotes(guardianAlertHistoryId),
		{ enabled: true }
	);
};
