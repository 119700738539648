import { ManifestChecker } from 'RtUi/app/ApplicationShell/lib/components/ManifestChecker';
import {
	ExtractMethods,
	MethodParams,
	WorkerReturnType
} from 'RtUi/workers/lib/types';

declare const RTX_UI_TIMESTAMP: number;

/**
 * RtWorkerProxy is a single instance
 * @singleton
 */
export class RtWebWorkerFacade<FacadeInterface> {
	private webWorker: Worker | undefined;

	/**
	 * Constructor has protected scope to
	 * enable subclasses to use a singleton approach
	 * @param webWorkerName
	 */
	protected constructor(protected webWorkerName: string) {}

	/**
	 * Post a message to the web worker
	 * @param worker
	 * @param methodName
	 * @param params
	 */
	protected postMessageToWebWorker<
		MethodName extends keyof ExtractMethods<FacadeInterface>,
		Params = MethodParams<FacadeInterface, MethodName>,
		PostMessageReturnType = WorkerReturnType<FacadeInterface, MethodName>
	>(methodName: MethodName, params: Params): Promise<PostMessageReturnType> {
		return new Promise<PostMessageReturnType>(async (resolve, reject) => {
			const worker = await this.getWebWorker();
			const messageEventFn = (ev: {
				data: PostMessageReturnType | PromiseLike<PostMessageReturnType>;
			}) => {
				worker.removeEventListener('message', messageEventFn);
				worker.removeEventListener('error', errorEventFn);

				if (ev.data instanceof Promise) {
					ev.data.then(resolve);
				} else {
					resolve(ev.data);
				}
			};
			const errorEventFn = (errorEvt: ErrorEvent) => {
				worker.removeEventListener('message', messageEventFn);
				worker.removeEventListener('error', errorEventFn);

				reject(errorEvt);
			};

			worker.addEventListener('message', messageEventFn, { once: true });
			worker.addEventListener('error', errorEventFn, { once: true });

			worker.postMessage({ methodName, params });
		});
	}

	/**
	 * Get the web worker
	 */
	private async getWebWorker() {
		if (!this.webWorker) {
			const manifestManager = await ManifestChecker.GetInstance();
			const workerAssetName = `${this.webWorkerName}.worker.js`;
			const assetPath = manifestManager.getManifestFor(workerAssetName);

			if (!assetPath) {
				throw Error(`Unable to find worker: ${workerAssetName}`);
			}

			this.webWorker = new Worker(assetPath);
		}

		return this.webWorker;
	}
}
