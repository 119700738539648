import { Fragment, useCallback, useMemo, useState } from 'react';
import { GuardianFraudSystemConfigIndexResponse } from 'RtModels';
import { FraudConfigurationEditor } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/forms/FraudConfigurationEditor';
import { FraudConfigurationResource } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/resources/FraudConfigurationResource';
import { useGetFraudConfiguration } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getFraudConfigurationColumns } from 'RtUi/components/data/DataGrid/configurations/rtGuardian/fraudConfiguration';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';

interface IFraudConfigurationGridProps {
	pageName: string;
}

export const FraudConfigurationGrid = ({
	pageName
}: IFraudConfigurationGridProps): JSX.Element => {
	const [asideIsOpen, setAsideOpen] = useState(false);
	const [selectedRecord, setSelectedRecord] =
		useState<GuardianFraudSystemConfigIndexResponse>();

	const { data, isLoading, refetch } = useGetFraudConfiguration();

	const removeEntity = useCallback(
		async (row: GuardianFraudSystemConfigIndexResponse) => {
			const confirm = await Confirmation.createDelete(row.paramName);

			if (!confirm) {
				return;
			}

			try {
				await new FraudConfigurationResource().delete(
					row.guardianFraudSystemConfigId
				);

				refetch();
			} catch (e) {}
		},
		[refetch]
	);

	const columns = useMemo(
		() => getFraudConfigurationColumns(removeEntity),
		[removeEntity]
	);

	return (
		<>
			{asideIsOpen && (
				<Aside isOpen={asideIsOpen} disabledBody>
					<Fragment key={selectedRecord?.guardianFraudSystemConfigId}>
						<Aside.Header
							warning={Boolean(selectedRecord)}
							header="Configuration"
							onClose={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
						/>
						<FraudConfigurationEditor
							guardianFraudSystemConfigId={
								selectedRecord?.guardianFraudSystemConfigId ?? -1
							}
							editMode={selectedRecord}
							onCancel={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
							onSuccess={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
						/>
					</Fragment>
				</Aside>
			)}
			<DataGrid<GuardianFraudSystemConfigIndexResponse>
				data={data?.data}
				loading={isLoading}
				onRowClick={(row) => {
					setAsideOpen(true);
					setSelectedRecord(row);
				}}
				pageName={pageName}
				columns={columns}
				totalRows={data?.totalCount}
				rowThemeColor={(record) =>
					record.guardianFraudSystemConfigId ===
					selectedRecord?.guardianFraudSystemConfigId
						? RowThemeColor.WARNING
						: undefined
				}
			/>
			<Confirmation />
		</>
	);
};
