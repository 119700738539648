import {
	GuardianReconciliationUsageDetailsIndexResponse,
	GuardianReconciliationUsageDetailsSummaryIndexResponse,
	GuardianReconciliationUsageDialCodeSummaryIndexGrouping,
	GuardianReconciliationUsageDialCodeSummaryIndexResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { safeDivision } from 'RtUi/utils/core/utils';
import { ServiceDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ServiceDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { PercentDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PercentDataGridColumn';
import { IReconciliationDirection } from 'RtUi/app/rtVue/ReconciliationUsage/lib/grids/ReconciliationUsageDialCodeSummaryGrid';

export const getReconciliationUsageColumns = (): Array<
	DataGridColumn<GuardianReconciliationUsageDetailsIndexResponse>
> => [
	IntegerDataGridColumn({
		accessorKey: 'reconciliationId',
		header: 'Reconciliation Id'
	}),
	ServiceDataGridColumn({
		accessorKey: 'serviceTypeId',
		header: 'Service Type'
	}),
	DefaultDataGridColumn({
		accessorKey: 'assignedToUserEmail',
		header: 'Assigned To'
	}),
	BooleanDataGridColumn({
		accessorKey: 'systemGenerated',
		header: 'System Generated'
	}),
	IntegerDataGridColumn({
		accessorKey: 'rtSrcConnects',
		header: 'Source Connects'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rtSrcDuration',
		header: 'Source Duration',
		decimals: 2
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rtSrcCharge',
		header: 'Source Charge'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rtTgtConnects',
		header: 'Target Connects',
		decimals: 2
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rtTgtDuration',
		header: 'Target Duration',
		decimals: 2
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rtTgtCharge',
		header: 'Target Charge'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'disputedAmount',
		header: 'Disputed Amount'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'approvedAmount',
		header: 'Approved Amount'
	}),
	IntegerDataGridColumn({
		accessorKey: 'disputeId',
		header: 'Dispute Id'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'disputeFiledTs',
		header: 'Dispute Filed Date'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'disputeSettlementTs',
		header: 'Dispute Settlement Date'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updateTs',
		header: 'Last Updated'
	})
];

export const getReconciliationUsageDetailsSummaryColumns = (
	grouping?: GuardianReconciliationUsageDialCodeSummaryIndexGrouping
): Array<
	DataGridColumn<GuardianReconciliationUsageDialCodeSummaryIndexResponse>
> => [
	DateDataGridColumn({
		accessorKey: 'timeslot',
		header: 'Time Slot'
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type'
	}),
	...(grouping === GuardianReconciliationUsageDialCodeSummaryIndexGrouping.STATE
		? [
				DefaultDataGridColumn<GuardianReconciliationUsageDialCodeSummaryIndexResponse>(
					{
						accessorKey: 'state',
						header: 'State',
						hiddenIfEmpty: true
					}
				)
			]
		: []),
	...(grouping === GuardianReconciliationUsageDialCodeSummaryIndexGrouping.ISO
		? [
				DefaultDataGridColumn<GuardianReconciliationUsageDialCodeSummaryIndexResponse>(
					{
						accessorKey: 'iso',
						header: 'Country',
						hiddenIfEmpty: true
					}
				)
			]
		: []),
	...(grouping ===
	GuardianReconciliationUsageDialCodeSummaryIndexGrouping.DIALCODE
		? [
				DefaultDataGridColumn<GuardianReconciliationUsageDialCodeSummaryIndexResponse>(
					{
						accessorKey: 'dialCode',
						header: 'Dial Code',
						hiddenIfEmpty: true
					}
				)
			]
		: []),
	DefaultDataGridColumn({
		accessorKey: 'jurisdiction',
		header: 'Jurisdiction',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'durationDiff',
		header: 'Duration Difference Ms',
		decimals: 4,
		hiddenIfEmpty: true
	}),
	HeaderDataGridColumn({
		header: 'Source',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'srcAttempts',
				header: 'Attempts'
			}),
			IntegerDataGridColumn({
				accessorKey: 'srcConnects',
				header: 'Connects'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'srcDuration',
				header: 'Duration Ms',
				decimals: 4
			}),
			PrecisionDataGridColumn({
				accessorKey: 'srcCost',
				header: 'Cost',
				decimals: 4
			}),
			PrecisionDataGridColumn({
				id: 'srcRatePerMinute',
				accessorFn: (record) =>
					safeDivision(record.srcCost as number, record.srcDuration as number),
				header: 'Rate Per Minute',
				decimals: 4
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Target',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'tgtAttempts',
				header: 'Attempts'
			}),
			IntegerDataGridColumn({
				accessorKey: 'tgtConnects',
				header: 'Connects'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'tgtDuration',
				header: 'Duration Ms',
				decimals: 4
			}),
			PrecisionDataGridColumn({
				accessorKey: 'tgtCost',
				header: 'Cost',
				decimals: 4
			}),
			PrecisionDataGridColumn({
				id: 'tgtRatePerMinute',
				accessorFn: (record) =>
					safeDivision(record.tgtCost as number, record.tgtDuration as number),
				header: 'Rate Per Minute',
				decimals: 4
			})
		]
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updateTs',
		header: 'Updated'
	})
];

export const getReconciliationUsageDetailSummaryColumns = (
	onDrillDown: (
		row: GuardianReconciliationUsageDetailsSummaryIndexResponse,
		direction: IReconciliationDirection
	) => void
): Array<
	DataGridColumn<GuardianReconciliationUsageDetailsSummaryIndexResponse>
> => [
	DateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		timezone: 'UTC'
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type'
	}),
	PercentDataGridColumn({
		//It should be matched call count / source call count * 100
		accessorFn: (record) =>
			safeDivision(
				record.matchedConnects as number,
				record.srcConnects as number
			),
		header: 'Match Percentage',
		convertFromDecimal: true,
		hiddenIfEmpty: true,
		decimals: 2
	}),
	HeaderDataGridColumn({
		header: 'Matched',
		columns: [
			PrecisionDataGridColumn({
				accessorKey: 'tgtDuration',
				header: 'Duration',
				decimals: 4
			}),
			PrecisionDataGridColumn({
				accessorKey: 'matchedCost',
				header: 'Cost',
				decimals: 4
			}),
			PrecisionDataGridColumn({
				accessorFn: (record) =>
					safeDivision(
						record.matchedCost as number,
						record.matchedDuration as number
					),
				header: 'CPM',
				decimals: 5
			}),
			IntegerDataGridColumn({
				accessorKey: 'matchedConnects',
				header: 'Call Count'
			}),
			PercentDataGridColumn({
				header: 'Negative Cost Matches',
				convertFromDecimal: true,
				accessorFn: (record) =>
					safeDivision(
						record.costMatchVarianceCategoryNegative as number,
						record.matchedConnects as number
					)
			}),
			PercentDataGridColumn({
				header: 'Exact Cost Matches',
				convertFromDecimal: true,
				accessorFn: (record) =>
					safeDivision(
						record.costMatchVarianceCategoryNone as number,
						record.matchedConnects as number
					)
			}),
			PercentDataGridColumn({
				header: 'Cost Matches Below 5%',
				convertFromDecimal: true,
				accessorFn: (record) =>
					safeDivision(
						record.costMatchVarianceCategoryFive as number,
						record.matchedConnects as number
					)
			}),
			PercentDataGridColumn({
				header: 'Cost Matches Below 10%',
				convertFromDecimal: true,
				accessorFn: (record) =>
					safeDivision(
						record.costMatchVarianceCategoryTen as number,
						record.matchedConnects as number
					)
			}),
			PercentDataGridColumn({
				header: 'Cost Matches Below 25%',
				convertFromDecimal: true,
				accessorFn: (record) =>
					safeDivision(
						record.costMatchVarianceCategoryTwentyFive as number,
						record.matchedConnects as number
					)
			}),
			PercentDataGridColumn({
				header: 'Cost Matches More than 25%',
				convertFromDecimal: true,
				accessorFn: (record) =>
					safeDivision(
						record.costMatchVarianceCategoryHigher as number,
						record.matchedConnects as number
					)
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Source',
		isHidden: true,
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'srcConnects',
				header: 'Call Count',
				onDrillDown: (e, row) => {
					e.preventDefault();
					onDrillDown(row, 'src');
				}
			}),
			PrecisionDataGridColumn({
				accessorKey: 'srcDuration',
				header: 'Duration Ms',
				decimals: 4
			}),
			PrecisionDataGridColumn({
				accessorKey: 'srcCost',
				header: 'Cost',
				decimals: 4
			}),
			PrecisionDataGridColumn({
				accessorFn: (record) =>
					safeDivision(record.srcCost as number, record.srcDuration as number),
				header: 'CPM',
				decimals: 5
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Target',
		isHidden: true,
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'tgtConnects',
				header: 'Call Count',
				onDrillDown: (e, row) => {
					e.preventDefault();
					onDrillDown(row, 'tgt');
				}
			}),
			PrecisionDataGridColumn({
				accessorKey: 'tgtDuration',
				header: 'Duration Ms',
				decimals: 4
			}),
			PrecisionDataGridColumn({
				accessorKey: 'tgtCost',
				header: 'Cost',
				decimals: 4
			}),
			PrecisionDataGridColumn({
				accessorFn: (record) =>
					safeDivision(record.tgtCost as number, record.tgtDuration as number),
				header: 'CPM',
				decimals: 5
			})
		]
	})
];
