import { ScenarioIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getScenarioColumns = (): Array<
	DataGridColumn<ScenarioIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'scenarioId',
		header: 'Scenario'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isEntryPoint',
		header: 'Entry Point'
	})
];
