import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import {
	GuardianConnectionSubscriptionIndexResponse,
	GuardianConnectionSubscriptionProfileResponse
} from 'RtModels';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RtVueApiRoutes } from 'RtExports/routes';
import { IRouterBreadcrumb } from 'RtUi/components/containers/lib/RtUiRouter';
import { ConnectionResource } from 'RtUi/app/rtVue/Connections/lib/resources/ConnectionResource';
import {
	ConnectionContainerTabs,
	ConnectionRouter
} from 'RtUi/app/rtVue/Connections/Connection.router';

interface IConnectionSubscriptionRouterTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const ConnectionSubscriptionTabs: IConnectionSubscriptionRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class ConnectionSubscriptionRouterClass extends RtVueModuleRouter<
	GuardianConnectionSubscriptionIndexResponse,
	GuardianConnectionSubscriptionProfileResponse,
	IConnectionSubscriptionRouterTabs
> {
	constructor() {
		super(
			'Rate Management',
			'connectionSubscription',
			'guardianConnectionSubscriptionId',
			ConnectionSubscriptionTabs
		);

		this.setPermissionsFromApiRoute(
			RtVueApiRoutes.GuardianConnectionSubscriptions
		);
	}

	public getProfileLabel(
		profile: GuardianConnectionSubscriptionProfileResponse
	): string {
		return String(profile.guardianConnectionSubscriptionId);
	}

	public async getBreadcrumbs(
		currentPath: string,
		profile?: GuardianConnectionSubscriptionProfileResponse
	): Promise<IRouterBreadcrumb[]> {
		const connectionId = this.getConnectionIdFromCurrentPath();

		const connectionResource = new ConnectionResource();
		const connection = await connectionResource.get(connectionId);
		const connectionSubscriptionCrumb = ConnectionRouter.getProfileBreadcrumb(
			String(connectionId),
			connection,
			false,
			ConnectionContainerTabs.RatePlanManagement.header
		);

		connectionSubscriptionCrumb.name = 'Rate Plan';

		const breadcrumbs = [
			this.getSectionBreadcrumb(),
			ConnectionRouter.getIndexBreadcrumb(),
			ConnectionRouter.getProfileBreadcrumb(
				String(connectionId),
				connection,
				false,
				'Profile'
			),
			connectionSubscriptionCrumb,
			this.getProfileBreadcrumb(
				String(profile?.guardianConnectionSubscriptionId),
				profile,
				true
			)
		];

		return breadcrumbs;
	}

	public getProfileRouteWithIds(
		guardianConnectionId: number | string = ':connectionId',
		guardianConnectionSubscriptionId: number | string = ':id',
		tabId?: string,
		prefixSectionKey = true
	) {
		const resource = super.getIndexRoute(prefixSectionKey);

		return this.getRouteWithTab(
			`${resource}/${guardianConnectionId}/${guardianConnectionSubscriptionId}`,
			tabId
		);
	}

	public getProfileRoute(
		record: GuardianConnectionSubscriptionIndexResponse,
		tabId?: string,
		prefixSectionKey = true
	) {
		return this.getProfileRouteWithIds(
			record.guardianConnectionId,
			record.guardianConnectionSubscriptionId,
			tabId,
			prefixSectionKey
		);
	}

	protected getConnectionIdFromCurrentPath() {
		const currentPath = location.pathname;
		const profilePath = this.getProfileMatch<{
			id: string;
			connectionId: string;
		}>(currentPath);
		let connectionId: number | undefined;

		if (profilePath) {
			const { connectionId: connectionIdStr } = profilePath.params;
			connectionId = Number(connectionIdStr);
		}

		return connectionId;
	}
}

export const ConnectionSubscriptionRouter =
	new ConnectionSubscriptionRouterClass();
