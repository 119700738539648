/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum SipMethod {
    SipInvite = 'INVITE',
    Bye = 'BYE',
    Cancel = 'CANCEL',
    OneZeroZero = '100',
    OneEightThree = '183',
    TwoZeroZero = '200',
}