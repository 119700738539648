import clsx from 'clsx';
import { isNumber } from 'lodash-es';
import { FC } from 'react';
import { calculatePercentVariance } from 'RtUi/app/rtVue/ReconciliationUsage/lib/utils/VarianceHelpers';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';

interface IPercentVarianceControlGroup {
	label: string;
	rtStat: number | null | undefined;
	detailStat: number | null | undefined;
}

export const PercentVarianceControlGroup: FC<
	React.PropsWithChildren<IPercentVarianceControlGroup>
> = ({ label, rtStat, detailStat }) => {
	if (!isNumber(rtStat) || !isNumber(detailStat)) {
		return null;
	}

	const percentVariance = calculatePercentVariance(rtStat, detailStat);
	const varianceClassNames = clsx({
		'text-muted': percentVariance < 3,
		'text-warning': percentVariance >= 3 && percentVariance < 10,
		'text-danger': percentVariance >= 10
	});
	const varianceBgClassNames = clsx({
		'text-muted fw-bold': percentVariance < 3,
		'bg-warning text-white fw-bold':
			percentVariance >= 3 && percentVariance < 10,
		'bg-danger text-white fw-bold': percentVariance >= 10
	});

	return (
		<ControlGroup
			displayMode
			label={label}
			labelClassName={varianceClassNames}
			valueClassName={varianceBgClassNames}
			value={`${percentVariance.toFixed(2)}%`}
		/>
	);
};
