/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $EmailAccountIndexResponse = {
    properties: {
        emailAccountId: {
            type: 'number',
            isRequired: true,
        },
        name: {
            type: 'string',
            isRequired: true,
        },
        description: {
            type: 'string',
            isRequired: true,
        },
        host: {
            type: 'string',
            isRequired: true,
        },
        protocol: {
            type: 'string',
            isRequired: true,
        },
        useSsl: {
            type: 'number',
            isRequired: true,
        },
        emailAddress: {
            type: 'string',
            isRequired: true,
        },
        displayName: {
            type: 'string',
            isRequired: true,
        },
    },
};