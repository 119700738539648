import { ServerGroupMembersRouter } from 'RtUi/app/rtAdmin/ServerGroupMembers/ServerGroupMembers.router';
import { ServerGroupMembersEditor } from 'RtUi/app/rtAdmin/ServerGroupMembers/lib/forms/ServerGroupMembersEditor';
import { ServerGroupMembersDataGrid } from 'RtUi/app/rtAdmin/ServerGroupMembers/lib/grids/ServerGroupMembersGrid';
import { RtAdminRouter } from 'RtUi/app/rtAdmin/rtAdmin.router';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { FC } from 'react';

export const ServerGroupMembersIndexContainer: FC = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		'Results': { header: 'Results', isDefault: true },
		'Create Server Group Member': {
			header: 'Create Server Group Member',
			isAction: true
		}
	});

	return (
		<TabbedLayout
			router={ServerGroupMembersRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Results}>
				<ServerGroupMembersDataGrid />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs['Create Server Group Member']}>
				<ServerGroupMembersEditor
					onUpdate={() => {
						setActiveTab(tabs.Results.header);
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ServerGroupMembersIndexContainer.displayName =
	'ServerGroupMembersIndexContainer';

ServerGroupMembersRouter.setIndexRtUiFunctionalComponent(
	ServerGroupMembersIndexContainer,
	RtAdminRouter.getServerIndexRtUiControllerProps()
);
