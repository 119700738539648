import {
	OriginationNumberIndexResponse,
	OriginationNumberProfileResponse,
	OriginationNumberDeleteResponse,
	OriginationNumberProfileRequest,
	OriginationNumberBulkCreateRequest,
	OriginationNumbersUpdateRequest,
	OriginationNumberBulkCreateResponse,
	OriginationNumberCreateRequest
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class OriginationNumberResource extends HttpResource<
	OriginationNumberIndexResponse,
	OriginationNumberProfileResponse
> {
	constructor() {
		super(RtxSipApiRoutes.ServiceNumbers);
	}

	public create(req: OriginationNumberCreateRequest) {
		const body = JSON.stringify(req);

		return this.fetchWithRoute<OriginationNumberProfileResponse>(
			RtxSipApiRoutes.ServiceNumbers.Create,
			{
				body
			}
		);
	}

	public bulkCreate(serviceNumber: OriginationNumberBulkCreateRequest) {
		const body = JSON.stringify(serviceNumber);

		return this.fetchWithRoute<OriginationNumberBulkCreateResponse>(
			RtxSipApiRoutes.ServiceNumbersBulk.Create,
			{
				body
			}
		);
	}

	public update(
		serviceNumberId: number,
		serviceNumber: OriginationNumbersUpdateRequest
	) {
		const body = JSON.stringify(serviceNumber);
		const urlParams: OriginationNumberProfileRequest = { serviceNumberId };

		return this.fetchWithRoute<OriginationNumberProfileResponse>(
			RtxSipApiRoutes.ServiceNumbers.Update,
			{
				urlParams,
				body
			}
		);
	}

	public delete(serviceNumberId: number) {
		const urlParams: OriginationNumberProfileRequest = { serviceNumberId };

		return this.fetchWithRoute<OriginationNumberDeleteResponse>(
			RtxSipApiRoutes.ServiceNumbers.Delete,
			{
				urlParams
			}
		);
	}
}
