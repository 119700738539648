/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrCarrierStateProfileRequest = {
    properties: {
        lcrCarrierId: {
            type: 'number',
            isRequired: true,
        },
        nanpStateId: {
            type: 'string',
            isRequired: true,
        },
    },
};