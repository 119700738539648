import { RtVueReportUrlSearchParams } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueReportUrlSearchParams';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';

export abstract class RtVueReportRouter<
	IndexType,
	ProfileType,
	TabType extends IProfileApplicationContainerTabs
> extends RtVueModuleRouter<IndexType, ProfileType, TabType> {
	/**
	 * adds search parameters to RtVueReport
	 */
	public getDrillDownPath(
		...params: Parameters<typeof RtVueReportUrlSearchParams.create>
	) {
		const rtVueReportSearchParams = RtVueReportUrlSearchParams.create(
			...params
		);
		const indexPath = this.getIndexRoute(true);

		return `${indexPath}/?${rtVueReportSearchParams.toString()}`;
	}
}
