/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DownloadIndexRequest = {
    properties: {
        createdBy: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};