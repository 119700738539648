import { DataSources, SearchField, TemplateIndexResponse } from 'RtModels';
import {
	IFilterSearchResultSection,
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { IndexedDbCacheStrategy } from 'RtUi/utils/cache/strategies/IndexedDbCacheStrategy';

/**
 * @singleton
 */
export class RtVueTemplateFilter extends RtVueFilter<TemplateIndexResponse> {
	public static getInstance() {
		if (!RtVueTemplateFilter.instance) {
			RtVueTemplateFilter.instance = new RtVueTemplateFilter();
		}

		return RtVueTemplateFilter.instance;
	}

	private static instance: RtVueTemplateFilter | undefined;
	private static readonly FilterName = 'RtVueTemplateFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<TemplateIndexResponse>
	> = new IndexedDbCacheStrategy(
		RtVueTemplateFilter.FilterName,
		'templateName',
		['label', '*keywords' as keyof TemplateIndexResponse]
	);

	protected constructor() {
		super(
			RtVueTemplateFilter.FilterName,
			DataSources.Templates,
			RtVueTemplateFilter.DataCache
		);
	}

	public async search(
		possibleSearchFields: SearchField[],
		searchStr: string
	): Promise<IFilterSearchResultSection[]> {
		const searchStrWithAst =
			searchStr.charAt(0) !== '*' ? `*${searchStr}` : searchStr;
		return super.search(possibleSearchFields, searchStrWithAst);
	}

	public getLabelFor(record: TemplateIndexResponse): string {
		return record.templateName;
	}

	public getValueFor(record: TemplateIndexResponse): string {
		return record.templateName;
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<TemplateIndexResponse>
	> {
		return this.vueHttp.getTemplateFilter();
	}

	protected getKeywordsFor(record: TemplateIndexResponse): string[] {
		const keywords: string[] = [
			record.templateName,
			...this.getKeywordsFromPhrase(record.label)
		];

		return keywords;
	}
}
