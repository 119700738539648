/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocContactRequest = {
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        title: {
            type: 'string',
            isRequired: true,
        },
        phone: {
            type: 'string',
            isRequired: true,
        },
        extension: {
            type: 'string',
        },
        fax: {
            type: 'string',
        },
        email: {
            type: 'string',
        },
    },
};