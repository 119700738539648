import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	FallbackHeaderDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import {
	PrecisionDataGridColumn,
	PrecisionDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';

type RateDataGridColumnConfiguration<T> = Omit<
	PrecisionDataGridColumnConfiguration<T>,
	'header'
> &
	FallbackHeaderDataGridColumnConfiguration<T>;

export const RateDataGridColumn = <T = any,>({
	header = 'Rate',
	decimals = 6,
	align = DataGridColumnAlignment.RIGHT,
	...config
}: RateDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return PrecisionDataGridColumn({
		header,
		decimals,
		align,
		...config
	});
};
