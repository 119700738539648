import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';

// exported definitions
// ======================================================================

export class CprLblTimeOfDay extends CprLbl {

	public readonly cprNodeTypeId = CprNodeType.Date;

	public readonly valueLimit = 255;
	public readonly valueRegExp = /^(1[0-2]|0?[1-9]):([0-5][0-9])(A|P)|((1[0-2]|0?[1-9]):([0-5][0-9])(A|P)-(1[0-2]|0?[1-9]):([0-5][0-9])(A|P))$/;

	/*
		hh:mmx-hh:mmx where x can be A (am) or P (pm)
		time is standard time and default is central time
	*/

	public isPossibleValue(rawVal: string) {
		// only validating by valueRegExp
		return true;
	}

}
