/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NanpReserveRequestUrl = {
    properties: {
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        nanpNumber: {
            type: 'string',
            isRequired: true,
        },
    },
};