/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationTaskTypeXrefIndexResponse = {
    properties: {
        integrationTaskTypeXrefId: {
            type: 'number',
            isRequired: true,
        },
        integrationId: {
            type: 'number',
            isRequired: true,
        },
        integrationTaskTypeId: {
            type: 'number',
            isRequired: true,
        },
    },
};