/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $AsrSummaryIndexRequest = {
    properties: {
        startDate: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        startTime: {
            type: 'string',
            isRequired: true,
        },
        endTime: {
            type: 'string',
            isRequired: true,
        },
        timeslot: {
            type: 'TimeDisplay',
            isRequired: true,
        },
        customerAccountIds: {
            type: 'Array',
        },
        customerSubscriptionIds: {
            type: 'Array',
        },
        vendorAccountIds: {
            type: 'Array',
        },
        vendorSubscriptionIds: {
            type: 'Array',
        },
        callingPartyStd: {
            type: 'string',
        },
        callingPartyCountryIsoIds: {
            type: 'Array',
        },
        callingPartyRateCenterIds: {
            type: 'Array',
        },
        dialedNumberStd: {
            type: 'string',
        },
        dialedNumberCountryIsoIds: {
            type: 'Array',
        },
        dialedNumberRateCenterIds: {
            type: 'Array',
        },
        columns: {
            type: 'Array',
        },
        minSeconds: {
            type: 'number',
        },
        maxSeconds: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};