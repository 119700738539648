import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { LcrTaskRouter } from 'RtUi/app/rtLco/Tasks/LcrTask.router';
import { LcrTasksGrid } from 'RtUi/app/rtLco/TaskResults/lib/grids/LcrTasksGrid';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { LcrTaskCreate } from 'RtUi/app/rtLco/Tasks/lib/forms/LcrTaskCreate';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import {
	LcrTaskProfileResponse,
	RespOrgIndexResponse,
	TemplateIndexResponse
} from 'RtModels';

interface IInitialResourceParams {
	search: string;
	respOrg: RespOrgIndexResponse | undefined;
	template: TemplateIndexResponse | undefined;
}

interface ILcrScenarioIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@LcrTaskRouter.getIndexRtUiController()
export class LcrTaskIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	ILcrScenarioIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		search: '',
		respOrg: undefined,
		template: undefined
	};

	public state: ILcrScenarioIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { search, respOrg, template } = this.state;

		const resourceParams: any = {};

		if (search) {
			resourceParams.search = search;
		}

		if (respOrg) {
			resourceParams.respOrgId = respOrg.respOrgId;
		}

		if (template) {
			resourceParams.templateName = template.templateName;
		}

		return resourceParams;
	}

	public onLcrTaskCreate(lcrTaskProfile: LcrTaskProfileResponse) {
		const profileTabs = LcrTaskRouter.getProfileTabs();
		const lcrTaskProfilePath = LcrTaskRouter.getProfileRoute(
			lcrTaskProfile.lcrTaskId,
			profileTabs.Profile.header
		);

		this.goToPath(lcrTaskProfilePath);
	}

	public render() {
		return (
			<TabbedLayout
				router={LcrTaskRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<RespOrgSelect
							onChange={(respOrg) => this.setState({ respOrg })}
							value={this.state.respOrg}
						/>
						<TemplateSelect
							onChange={(template) => this.setState({ template })}
							respOrgId={this.state.respOrg?.respOrgId}
							value={this.state.template}
						/>
						<InputFormControl
							label="Search"
							onChange={(search) => this.setState({ search })}
							value={this.state.search}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<LcrTasksGrid
						autoFocusFilter
						resourceParams={this.state.resourceParams}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					header="Create LCO Task"
					rtRoute={Rt800ApiRoutes.LcrTasks.Create}
					isAction
				>
					<LcrTaskCreate
						onCreate={(lcrTaskProfile) => this.onLcrTaskCreate(lcrTaskProfile)}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
