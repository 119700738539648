/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $PortLimitIndexResponse = {
    properties: {
        portLimitId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        accountId: {
            type: 'number',
            isRequired: true,
        },
        countryAbbr: {
            type: 'string',
            isRequired: true,
        },
        mobileMatchModeId: {
            type: 'number',
            isRequired: true,
        },
        ingressPortLimit: {
            type: 'number',
            isRequired: true,
        },
        egressPortLimit: {
            type: 'number',
            isRequired: true,
        },
        auditId: {
            type: 'number',
            isRequired: true,
        },
    },
};