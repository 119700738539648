/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrTaskResultProfileResponse = {
    properties: {
        lcrTaskResultId: {
            type: 'number',
            isRequired: true,
        },
        lcrTaskId: {
            type: 'number',
            isRequired: true,
        },
        lcrScenarioId: {
            type: 'number',
            isRequired: true,
        },
        lcrCarrierId: {
            type: 'number',
            isRequired: true,
        },
        defaultCic: {
            type: 'string',
            isRequired: true,
        },
        minutes: {
            type: 'number',
            isRequired: true,
        },
        charges: {
            type: 'number',
            isRequired: true,
        },
        canadaCic: {
            type: 'string',
            isRequired: true,
        },
        canadaMinutes: {
            type: 'number',
            isRequired: true,
        },
        canadaCharges: {
            type: 'number',
            isRequired: true,
        },
        nonLcrQty: {
            type: 'number',
            isRequired: true,
        },
        nonLcrMinutes: {
            type: 'number',
            isRequired: true,
        },
        nonLcrCharges: {
            type: 'number',
            isRequired: true,
        },
        unknownMinutes: {
            type: 'number',
            isRequired: true,
        },
        unknownCharges: {
            type: 'number',
            isRequired: true,
        },
        totalMinutes: {
            type: 'number',
            isRequired: true,
        },
        totalCharges: {
            type: 'number',
            isRequired: true,
        },
        cprQty: {
            type: 'number',
            isRequired: true,
        },
        labelQty: {
            type: 'number',
            isRequired: true,
        },
        labelValueQty: {
            type: 'number',
            isRequired: true,
        },
        cprSizeEstimate: {
            type: 'number',
            isRequired: true,
        },
        estimatedCost: {
            type: 'number',
            isRequired: true,
        },
        taskLabel: {
            type: 'string',
            isRequired: true,
        },
    },
};