import {
	TemplateCprCloneRequest,
	TemplateCprProfileRequest,
	TemplateCreateRequest,
	TemplateIndexResponse,
	TemplateProfileResponse,
	TemplateScpIndexResponse,
	TemplateUpdateRequest,
	TemplateProfileRequest,
	TemplateNameIsValidRequest,
	TemplateNameIsValidResponse,
	TemplateCprIndexResponse,
	RoutingCacheTypes
} from 'RtModels';
import { CprResource } from 'RtUi/app/rt800/Cprs/lib/resources/CprResource';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { IRequestInitWithParams } from 'RtUi/utils/http/interfaces';

export class TemplateResource extends CprResource<
	TemplateIndexResponse,
	TemplateProfileResponse,
	TemplateCprIndexResponse,
	TemplateScpIndexResponse
> {
	constructor() {
		super(Rt800ApiRoutes.Templates);
	}

	public update(templateName: string, req: TemplateUpdateRequest) {
		const body = JSON.stringify(req);
		const urlParams: TemplateProfileRequest = { templateName };

		return this.fetchWithRoute<TemplateProfileResponse>(
			Rt800ApiRoutes.Templates.Update,
			{ body, urlParams }
		);
	}

	public getReservedTemplates(respOrgId?: string) {
		const extraParams: any = {
			isActive: 1
		};

		if (respOrgId) {
			extraParams.respOrgId = respOrgId;
		}

		return this.getAll(extraParams).then((templates) =>
			templates.filter((t) => t.isReserveTemplate === 1)
		);
	}

	public createTemplate(
		templateCreateReq: TemplateCreateRequest,
		init: IRequestInitWithParams = {}
	) {
		const body = JSON.stringify(templateCreateReq);

		return this.fetchWithRoute<ITaskProfile>(Rt800ApiRoutes.Templates.Create, {
			...init,
			body
		});
	}

	public clone(
		templateName: string,
		effectiveTs: Date,
		targetTypeId: RoutingCacheTypes,
		targetKey: string,
		targetEffectiveTs?: Date
	) {
		const req: TemplateCprCloneRequest = {
			targetTypeId,
			targetKey,
			effectiveTs: targetEffectiveTs
		};

		//Delete if is now/undefined
		if (req.effectiveTs === undefined) {
			delete req.effectiveTs;
		}

		const body = JSON.stringify(req);
		const params: TemplateCprProfileRequest = { effectiveTs };
		const urlParams: TemplateProfileRequest = { templateName };

		return this.fetchWithRoute<ITaskProfile>(
			Rt800ApiRoutes.TemplateCprClone.Update,
			{ body, params, urlParams }
		);
	}

	/**
	 * Tests to see if templateName is valid
	 *
	 * @param templateName
	 * @param respOrgId
	 */
	public async isTemplateNameValid(templateName: string, respOrgId: string) {
		const params: TemplateNameIsValidRequest = { templateName, respOrgId };

		return this.fetchWithRoute<TemplateNameIsValidResponse>(
			Rt800ApiRoutes.TemplateNameIsValid.Profile,
			{ params }
		);
	}
}
