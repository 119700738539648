import { SubscriptionIndexResponse, DataSources } from 'RtModels';
import {
	RtVueFilter,
	IGenericFilterResponse,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { AccountDataGridCache } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { MemoryCacheStrategy } from 'RtUi/utils/cache/strategies/MemoryCacheStrategy';

/**
 * @singleton
 */
export class RtVueSubscriptionFilter extends RtVueFilter<SubscriptionIndexResponse> {
	public static getInstance() {
		if (!RtVueSubscriptionFilter.instance) {
			RtVueSubscriptionFilter.instance = new RtVueSubscriptionFilter();
		}

		return RtVueSubscriptionFilter.instance;
	}

	private static instance: RtVueSubscriptionFilter | undefined;
	private static readonly FilterName = 'RtVueSubscriptionFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<SubscriptionIndexResponse>
	> = new MemoryCacheStrategy();

	protected constructor() {
		super(
			RtVueSubscriptionFilter.FilterName,
			DataSources.Subscriptions,
			RtVueSubscriptionFilter.DataCache
		);
	}

	public getLabelFor(record: SubscriptionIndexResponse): string {
		const accountLabel = AccountDataGridCache.getLabelFor(
			Number(record.accountId)
		);

		if (accountLabel) {
			return `${record.label} (Account: ${accountLabel})`;
		}

		return record.label;
	}

	public getValueFor(record: SubscriptionIndexResponse): string {
		return String(record.subscriptionId);
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<SubscriptionIndexResponse>
	> {
		return this.vueHttp.getSubscriptionFilter();
	}

	protected getKeywordsFor(record: SubscriptionIndexResponse): string[] {
		const keywords = this.getKeywordsFromPhrase(record.label);

		keywords.push(String(record.subscriptionId));

		return keywords;
	}
}
