/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $BirdsEyeIndexResponse = {
    properties: {
        accountId: {
            type: 'number',
        },
        subscriptionId: {
            type: 'number',
        },
        externalAccountId: {
            type: 'string',
            isRequired: true,
        },
        externalSubAccountId: {
            type: 'string',
            isRequired: true,
        },
        day1Duration: {
            type: 'number',
            isRequired: true,
        },
        day1Value: {
            type: 'number',
            isRequired: true,
        },
        day1Margin: {
            type: 'number',
            isRequired: true,
        },
        day2Duration: {
            type: 'number',
            isRequired: true,
        },
        day2Value: {
            type: 'number',
            isRequired: true,
        },
        day2Margin: {
            type: 'number',
            isRequired: true,
        },
        day3Duration: {
            type: 'number',
            isRequired: true,
        },
        day3Value: {
            type: 'number',
            isRequired: true,
        },
        day3Margin: {
            type: 'number',
            isRequired: true,
        },
        day4Duration: {
            type: 'number',
            isRequired: true,
        },
        day4Value: {
            type: 'number',
            isRequired: true,
        },
        day4Margin: {
            type: 'number',
            isRequired: true,
        },
        day5Duration: {
            type: 'number',
            isRequired: true,
        },
        day5Value: {
            type: 'number',
            isRequired: true,
        },
        day5Margin: {
            type: 'number',
            isRequired: true,
        },
        day6Duration: {
            type: 'number',
            isRequired: true,
        },
        day6Value: {
            type: 'number',
            isRequired: true,
        },
        day6Margin: {
            type: 'number',
            isRequired: true,
        },
        day7Duration: {
            type: 'number',
            isRequired: true,
        },
        day7Value: {
            type: 'number',
            isRequired: true,
        },
        day7Margin: {
            type: 'number',
            isRequired: true,
        },
        day8Duration: {
            type: 'number',
            isRequired: true,
        },
        day8Value: {
            type: 'number',
            isRequired: true,
        },
        day8Margin: {
            type: 'number',
            isRequired: true,
        },
        day9Duration: {
            type: 'number',
            isRequired: true,
        },
        day9Value: {
            type: 'number',
            isRequired: true,
        },
        day9Margin: {
            type: 'number',
            isRequired: true,
        },
        day10Duration: {
            type: 'number',
            isRequired: true,
        },
        day10Value: {
            type: 'number',
            isRequired: true,
        },
        day10Margin: {
            type: 'number',
            isRequired: true,
        },
        day11Duration: {
            type: 'number',
            isRequired: true,
        },
        day11Value: {
            type: 'number',
            isRequired: true,
        },
        day11Margin: {
            type: 'number',
            isRequired: true,
        },
        day12Duration: {
            type: 'number',
            isRequired: true,
        },
        day12Value: {
            type: 'number',
            isRequired: true,
        },
        day12Margin: {
            type: 'number',
            isRequired: true,
        },
        day13Duration: {
            type: 'number',
            isRequired: true,
        },
        day13Value: {
            type: 'number',
            isRequired: true,
        },
        day13Margin: {
            type: 'number',
            isRequired: true,
        },
        day14Duration: {
            type: 'number',
            isRequired: true,
        },
        day14Value: {
            type: 'number',
            isRequired: true,
        },
        day14Margin: {
            type: 'number',
            isRequired: true,
        },
        day15Duration: {
            type: 'number',
            isRequired: true,
        },
        day15Value: {
            type: 'number',
            isRequired: true,
        },
        day15Margin: {
            type: 'number',
            isRequired: true,
        },
        day16Duration: {
            type: 'number',
            isRequired: true,
        },
        day16Value: {
            type: 'number',
            isRequired: true,
        },
        day16Margin: {
            type: 'number',
            isRequired: true,
        },
        day17Duration: {
            type: 'number',
            isRequired: true,
        },
        day17Value: {
            type: 'number',
            isRequired: true,
        },
        day17Margin: {
            type: 'number',
            isRequired: true,
        },
        day18Duration: {
            type: 'number',
            isRequired: true,
        },
        day18Value: {
            type: 'number',
            isRequired: true,
        },
        day18Margin: {
            type: 'number',
            isRequired: true,
        },
        day19Duration: {
            type: 'number',
            isRequired: true,
        },
        day19Value: {
            type: 'number',
            isRequired: true,
        },
        day19Margin: {
            type: 'number',
            isRequired: true,
        },
        day20Duration: {
            type: 'number',
            isRequired: true,
        },
        day20Value: {
            type: 'number',
            isRequired: true,
        },
        day20Margin: {
            type: 'number',
            isRequired: true,
        },
        day21Duration: {
            type: 'number',
            isRequired: true,
        },
        day21Value: {
            type: 'number',
            isRequired: true,
        },
        day21Margin: {
            type: 'number',
            isRequired: true,
        },
        day22Duration: {
            type: 'number',
            isRequired: true,
        },
        day22Value: {
            type: 'number',
            isRequired: true,
        },
        day22Margin: {
            type: 'number',
            isRequired: true,
        },
        day23Duration: {
            type: 'number',
            isRequired: true,
        },
        day23Value: {
            type: 'number',
            isRequired: true,
        },
        day23Margin: {
            type: 'number',
            isRequired: true,
        },
        day24Duration: {
            type: 'number',
            isRequired: true,
        },
        day24Value: {
            type: 'number',
            isRequired: true,
        },
        day24Margin: {
            type: 'number',
            isRequired: true,
        },
        day25Duration: {
            type: 'number',
            isRequired: true,
        },
        day25Value: {
            type: 'number',
            isRequired: true,
        },
        day25Margin: {
            type: 'number',
            isRequired: true,
        },
        day26Duration: {
            type: 'number',
            isRequired: true,
        },
        day26Value: {
            type: 'number',
            isRequired: true,
        },
        day26Margin: {
            type: 'number',
            isRequired: true,
        },
        day27Duration: {
            type: 'number',
            isRequired: true,
        },
        day27Value: {
            type: 'number',
            isRequired: true,
        },
        day27Margin: {
            type: 'number',
            isRequired: true,
        },
        day28Duration: {
            type: 'number',
            isRequired: true,
        },
        day28Value: {
            type: 'number',
            isRequired: true,
        },
        day28Margin: {
            type: 'number',
            isRequired: true,
        },
        day29Duration: {
            type: 'number',
            isRequired: true,
        },
        day29Value: {
            type: 'number',
            isRequired: true,
        },
        day29Margin: {
            type: 'number',
            isRequired: true,
        },
        day30Duration: {
            type: 'number',
            isRequired: true,
        },
        day30Value: {
            type: 'number',
            isRequired: true,
        },
        day30Margin: {
            type: 'number',
            isRequired: true,
        },
    },
};