import { IRadioButtonOptions } from 'RtUi/components/ui/RadioButtons';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from '../FormControl';
import { RadioFormControl } from '../RadioFormControl';

interface IIsActiveRadioFormControlProps<HideBothOption extends boolean = false>
	extends IFormControlProps<IsActiveValue<HideBothOption>> {
	activeTooltip?: string;
	hideBothOption?: HideBothOption;
}

type IsActiveValue<HideBothOption extends boolean = false> =
	HideBothOption extends false ? number | undefined : number;

export class IsActiveRadioFormControl<
	HideBothOption extends boolean = false
> extends FormControl<
	number,
	false,
	IIsActiveRadioFormControlProps<HideBothOption>
> {
	public resource = undefined;
	public state: IFormControlState = {
		formLabel: 'Active'
	};

	public render() {
		const {
			label = 'Active',
			activeTooltip,
			hideBothOption = false
		} = this.props;
		const options = [
			{
				value: 1,
				label: 'Active',
				tooltip: activeTooltip,
				disabled: Boolean(this.props.disabled)
			},
			{ value: 0, label: 'Inactive', disabled: Boolean(this.props.disabled) },
			{
				value: undefined,
				label: 'Both',
				disabled: Boolean(this.props.disabled)
			}
		];

		if (hideBothOption) {
			options.pop();
		}

		return (
			<RadioFormControl<IsActiveValue<HideBothOption>>
				label={label}
				displayMode={this.props.displayMode}
				onChange={(isActive) =>
					this.props.onChange && this.props.onChange(isActive)
				}
				value={this.props.value}
				options={
					options as Array<IRadioButtonOptions<IsActiveValue<HideBothOption>>>
				}
			/>
		);
	}
}
