import {
	GuardianUserQueryIndexResponse,
	GuardianUserQueryIndexRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtVueApiRoutes } from 'RtExports/routes';

export class GuardianUserQueryResource extends HttpResource<
	GuardianUserQueryIndexResponse,
	GuardianUserQueryIndexResponse,
	GuardianUserQueryIndexRequest
> {
	// why?. Remove
	public static IsQueryGlobalOrReferenceGlobal(
		query?: GuardianUserQueryIndexResponse
	) {
		if (typeof query === 'undefined') {
			return false;
		}

		return (
			query.isGlobal === 1 ||
			typeof query.globalGuardianUserQueryId === 'number'
		);
	}

	constructor() {
		super(RtVueApiRoutes.GuardianUserQueries);
	}
}
