/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RoutingRuleMatchType {
    FromStd = 100,
    FromRaw = 101,
    FromIso = 102,
    FromRateCenterId = 103,
    FromRateCenterTypeId = 104,
    FromLata = 120,
    FromOcn = 121,
    ToStd = 200,
    ToRaw = 201,
    ToIso = 202,
    ToRateCenterId = 203,
    ToRateCenterTypeId = 204,
    ToLata = 220,
    ToOcn = 221,
    InVendorAccountId = 300,
    InVendorSubscriptionId = 301,
    EgVendorAccountId = 400,
    EgVendorSubscriptionId = 401,
    InCustomerAccountId = 500,
    InCustomerSubscriptionId = 501,
    InCustomerRoutePlanId = 502,
    EgCustomerAccountId = 600,
    EgCustomerSubscriptionId = 601,
    EgCustomerRoutePlanId = 602,
    InSwitchId = 700,
    InGatewayId = 701,
    Tag = 900,
}