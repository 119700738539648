/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianServiceNumberSubscriptionUpdateRequestBulk = {
    properties: {
        endTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        isActive: {
            type: 'number',
        },
        guardianServiceNumberSubscriptionIds: {
            type: 'Array',
            isRequired: true,
        },
        portingStatus: {
            type: 'number',
        },
        decommissionedTs: {
            properties: {
            },
        },
        custOrderNumber: {
            type: 'string',
        },
    },
};