/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileLogTotalsIndexRequest = {
    properties: {
        fileStreamIds: {
            type: 'Array',
            isRequired: true,
        },
        createdTsStart: {
            type: 'Date',
            format: 'date-time',
        },
        createdTsEnd: {
            type: 'Date',
            format: 'date-time',
        },
    },
};