import { castArray, defaults } from 'lodash-es';
import * as moment from 'moment';
import { IRtxApiRoute, Permissions } from 'RtExports/routes';
import {
	ApplicationNotifications,
	ApplicationNotificationTypes,
	INotification
} from 'RtUi/app/ApplicationShell/lib/components/ApplicationNotifications';
import { UserActions } from 'RtUi/state/actions/user';
import { LoopThroughLargeArrayWithoutHaltingUI } from 'RtUi/utils/array';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { MemoryCacheStrategy } from 'RtUi/utils/cache/strategies/MemoryCacheStrategy';
import { IPagingRequest, IPagingResponse } from 'RtUi/utils/core/utils';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { IRequestInitWithParams } from 'RtUi/utils/http/interfaces';
import { ISearchable, ISortable } from 'RtUi/utils/http/resources/interfaces';
import { generateUUID } from './utils';

export interface IResourcePagingResponse<T> extends IPagingResponse<T> {
	count: number;
	dbCount: number;
	resourceCount: number;
	isPartialDataSet: boolean;
}

export interface IProfileAndIndexApiModule {
	Index: IRtxApiRoute;
	Profile: IRtxApiRoute;
}

export class HttpResource<T, ProfileResponse = T, IndexParams = any> {
	public static DEFAULT_NOTIFICATION_TIMEOUT = 2500;

	public static showSuccessNotification(message: string) {
		const notification: INotification = {
			message,
			notificationId: Math.random(),
			title: ApplicationNotificationTypes[ApplicationNotificationTypes.Success],
			notificationTypeId: ApplicationNotificationTypes.Success,
			effectiveTs: new Date(),
			expirationTs: new Date(),
			isUrgent: 0
		};

		ApplicationNotifications.openNotification(notification, {
			autoClose: HttpResource.DEFAULT_NOTIFICATION_TIMEOUT
		});
	}

	protected baseParams = { page: 0, pageSize: 5 * 1000 };
	protected getAllParams: any = {};
	protected totalCount = 0;
	protected recordsCount = 0;
	protected getAllIsPost = false;
	protected uuid = generateUUID();
	protected indexPermission: Permissions[] = [];
	protected profilePermission: Permissions[] = [];
	protected urlParams: IRequestInitWithParams['urlParams'] = {};
	protected resourcePath!: string;
	protected apiModule?: IProfileAndIndexApiModule;
	protected indexesCache: CacheStrategy<T> = new MemoryCacheStrategy<T>(
		moment.duration({ minutes: 5 })
	);
	protected getAllDoNotNotifyOn404Error: boolean | undefined;
	protected notificationVerbs = ['PUT', 'POST', 'DELETE'];
	protected accessor?: (data: any) => T[];

	constructor(apiModule?: IProfileAndIndexApiModule) {
		if (apiModule) {
			this.setApiModule(apiModule);
		}
	}

	public setGetAllParams(params: any) {
		this.getAllParams = params;
	}

	public resetParams() {
		this.getAllParams = {};
	}

	public setAccessor(accessor: (data: any) => T[]) {
		this.accessor = accessor;
	}

	public setGetAllDoNotNotifyOn404Error(
		doNotNotifyOn404Error: boolean | undefined
	) {
		this.getAllDoNotNotifyOn404Error = doNotNotifyOn404Error;
	}

	public getUuid() {
		return this.uuid;
	}

	public generateNewUuid() {
		this.uuid = generateUUID();
	}

	public setIndexesCacheStrategy(cacheStrategy: CacheStrategy<T>) {
		this.indexesCache = cacheStrategy;
	}

	public getAll(extraParams?: IndexParams, resetCache = false): Promise<T[]> {
		const cacheIsStale = this.indexesCache.isCacheStale();

		if (resetCache || cacheIsStale) {
			const init: IRequestInitWithParams = {};

			if (this.getAllDoNotNotifyOn404Error) {
				init.doNotNotifyOn404Error = true;
			}

			const params = defaults(
				{},
				extraParams,
				this.getAllParams,
				this.baseParams
			);

			if (this.getAllIsPost) {
				init.body = JSON.stringify(params);
				init.method = 'POST';
			} else {
				init.params = params;
			}

			init.urlParams = defaults({}, init.urlParams, this.urlParams);
			const getAllPromise = HttpRequest.fetchWithHeaders<T[]>(
				this.resourcePath,
				this.indexPermission,
				init
			).then(([records, headers]) => {
				this.recordsCount = records.length;
				this.totalCount = this.recordsCount;
				const totalHeaderCountStr = headers.get('X-Total-Count');

				if (typeof totalHeaderCountStr === 'string') {
					const totalHeaderCount = Number(totalHeaderCountStr);

					if (!isNaN(totalHeaderCount)) {
						this.totalCount = totalHeaderCount;
					}
				}
				return records;
			});

			this.indexesCache.storeCache(getAllPromise);
		}
		return this.indexesCache.getAll();
	}

	public getAllWithoutCache(
		extraParams?: IndexParams,
		searchables?: Array<ISearchable<T>>
	): Promise<T[]> {
		const params = defaults(
			{},
			extraParams,
			this.getAllParams,
			this.baseParams
		);
		const method = this.getAllIsPost ? 'POST' : 'GET';
		const init: IRequestInitWithParams = { method };

		if (this.getAllDoNotNotifyOn404Error) {
			init.doNotNotifyOn404Error = true;
		}

		if (method === 'POST') {
			init.body = JSON.stringify(params);
		} else {
			init.params = params;
		}

		init.urlParams = defaults({}, init.urlParams, this.urlParams);

		return HttpRequest.fetch<T[]>(
			this.resourcePath,
			this.indexPermission,
			init
		).then((data) =>
			this.searchFilterAndSearch(
				params,
				this.accessor ? this.accessor(data) : data,
				searchables
			)
		);
	}

	public async get(id: any): Promise<ProfileResponse | undefined> {
		const profileRoute = this.getProfileRoute(id);
		const urlParams = this.urlParams;
		const profile = await HttpRequest.fetch<ProfileResponse>(
			profileRoute,
			this.profilePermission,
			{ urlParams }
		);

		return profile;
	}

	public paging(
		pageReq: IPagingRequest<T>,
		searchables?: Array<ISearchable<T>>,
		displayables?: Array<ISortable<T>>
	): Promise<IResourcePagingResponse<T>> {
		const { pageSize = this.baseParams.pageSize } = pageReq;

		return this.getAll().then(async (recs) => {
			const records = this.accessor ? this.accessor(recs) : recs;
			const resourceCount = records.length;
			const recordsToPage = await this.searchFilterAndSearch(
				pageReq,
				records,
				searchables,
				displayables
			);

			//Count
			const count = recordsToPage.length;
			const dbCount = this.getTotalDbCount();
			const isPartialDataSet = count < dbCount;

			//Page
			const data = this.page(pageReq, recordsToPage);
			const pages = Math.ceil(recordsToPage.length / pageSize);

			return {
				data,
				pages,
				count,
				dbCount,
				isPartialDataSet,
				resourceCount
			};
		});
	}

	public isMatch(
		record: any,
		search: string,
		searchables?: Array<ISearchable<T>>
	) {
		if (!searchables || searchables.length <= 0) {
			return false;
		}

		for (let i = 0; i < searchables.length; i++) {
			if (searchables[i].isSearchMatch(record, search)) {
				return true;
			}
		}

		return false;
	}

	public getTotalDbCount() {
		return this.totalCount;
	}

	public setTotalCount(count: number) {
		this.totalCount = count;
	}

	public userHasIndexPermissions(): boolean {
		return this.userHasPermissions(this.indexPermission);
	}

	protected getProfileRoute(id: any) {
		return `${this.resourcePath}/${id}`;
	}

	protected async searchFilterAndSearch(
		pageReq: IPagingRequest<T>,
		records: T[],
		searchables?: Array<ISearchable<T>>,
		sortables?: Array<ISortable<T>>
	) {
		let recordsToPage = [...records];
		const filters = pageReq.filtered ? castArray(pageReq.filtered) : [];
		const sorters = pageReq.sorted ? castArray(pageReq.sorted) : [];
		const searchFilter = filters.find((f) => f.id === 'search');
		const recordsToPageFiltered: any[] = [];

		if (searchFilter) {
			const filteredValues = ['', '"', '""'];
			const searchValues = searchFilter.value
				.split(',')
				.filter((str) => !filteredValues.includes(str));

			if (searchValues.length > 0) {
				await LoopThroughLargeArrayWithoutHaltingUI(recordsToPage, (record) => {
					if (
						searchValues.some((searchValue) =>
							this.isMatch(record, searchValue, searchables)
						)
					) {
						recordsToPageFiltered.push(record);
					}
				});
				recordsToPage = recordsToPageFiltered;
			}
		}

		const firstRecord = recordsToPage[0]; //Sort
		if (firstRecord && sorters.length > 0) {
			recordsToPage.sort(function sort(r1, r2, keys = sorters): number {
				const nextKeys = [...keys];
				const sorter = nextKeys.splice(0, 1).pop();

				if (!sorter) {
					return 0;
				}

				const sortUsingNextKeys = () => sort(r1, r2, nextKeys);
				const columnWithSortId = sortables?.find(
					(col) => col.getIdentifier() === sorter.id
				);

				if (!columnWithSortId) {
					return sortUsingNextKeys();
				}

				const r1Value = columnWithSortId.getSortValue(r1);
				const r2Value = columnWithSortId.getSortValue(r2);
				const r1ValueIsDefined = typeof r1Value !== 'undefined';
				const r2ValueIsDefined = typeof r2Value !== 'undefined';
				let compareValue = 0;

				if (typeof r1Value === 'string' && typeof r2Value === 'string') {
					if (sorter.desc) {
						compareValue = r2Value.localeCompare(r1Value);
					} else {
						compareValue = r1Value.localeCompare(r2Value);
					}
				} else if (typeof r1Value === 'number' && typeof r2Value === 'number') {
					if (sorter.desc) {
						compareValue = r2Value - r1Value;
					} else {
						compareValue = r1Value - r2Value;
					}
				} else if (r1ValueIsDefined && !r2ValueIsDefined) {
					return sorter.desc ? 1 : -1;
				} else if (!r1ValueIsDefined && r2ValueIsDefined) {
					return sorter.desc ? -1 : 1;
				}

				if (compareValue === 0) {
					//check next key for sorting
					return sortUsingNextKeys();
				}

				return compareValue;
			});
		}

		return recordsToPage;
	}

	protected page(pageReq: IPagingRequest<T>, records: T[]) {
		const { page = this.baseParams.page, pageSize = this.baseParams.pageSize } =
			pageReq;

		const startIndex = page * pageSize;
		const endIndex = startIndex + pageSize;

		return records.slice(startIndex, endIndex);
	}

	protected setApiModule(
		apiModule: IProfileAndIndexApiModule,
		urlParams: IRequestInitWithParams['urlParams'] = {}
	) {
		this.apiModule = apiModule;
		this.resourcePath = apiModule.Index.path;
		this.getAllIsPost = apiModule.Index.httpMethod === 'POST';
		this.indexPermission = apiModule.Index.permissions;
		this.profilePermission = apiModule.Profile.permissions;
		this.urlParams = urlParams;
	}

	protected rawFetchWithRoute<T>(
		apiRoute: IRtxApiRoute,
		init: IRequestInitWithParams = {}
	) {
		return HttpRequest.rawFetchWithRoute<T>(apiRoute, init);
	}

	protected fetchWithRoute<T>(
		apiRoute: IRtxApiRoute,
		init: IRequestInitWithParams = {}
	) {
		if (!init.method) {
			init.method = apiRoute.httpMethod;
		}

		init.urlParams = defaults({}, init.urlParams, this.urlParams);

		const fetchResult = HttpRequest.fetch<T>(
			apiRoute.path,
			apiRoute.permissions,
			init
		);
		const shouldTriggerNotification =
			!init.disableNotification &&
			this.notificationVerbs.includes(apiRoute.httpMethod);

		if (shouldTriggerNotification) {
			const message = init.notificationMessage || apiRoute.title;

			return fetchResult.then((result) => {
				HttpResource.showSuccessNotification(message);
				return result;
			});
		}

		return fetchResult;
	}

	protected fetchWithHeadersWithRoute<T>(
		apiRoute: IRtxApiRoute,
		init: IRequestInitWithParams = {}
	) {
		if (!init.method) {
			init.method = apiRoute.httpMethod;
		}

		init.urlParams = defaults({}, init.urlParams, this.urlParams);

		return HttpRequest.fetchWithHeaders<T>(
			apiRoute.path,
			apiRoute.permissions,
			init
		);
	}

	protected userHasPermissions(permissions: Permissions[]) {
		return UserActions.has(...permissions);
	}
}
