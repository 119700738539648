/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationIndexResponse = {
    properties: {
        integrationId: {
            type: 'number',
            isRequired: true,
        },
        integrationTypeId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isDid: {
            type: 'number',
            isRequired: true,
        },
        isIddd: {
            type: 'number',
            isRequired: true,
        },
        isTollFree: {
            type: 'number',
            isRequired: true,
        },
        taskTypeIds: {
            type: 'Array',
            isRequired: true,
        },
    },
};