import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	TrackingGroupIndexResponse,
	TrackingGroupProfileResponse
} from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface ITrackingGroupContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const TrackingGroupContainerTabs: ITrackingGroupContainerTabs = {
	Profile: {
		header: 'Profile'
	}
};

class TrackingGroupRouterClass extends SipApplicationRouter<
	TrackingGroupIndexResponse,
	TrackingGroupProfileResponse,
	ITrackingGroupContainerTabs
> {
	constructor() {
		super(
			'Tracking Group',
			'trackingGroups',
			'trackingGroupId',
			TrackingGroupContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.TrackingGroups);
	}

	// Removed as Per Russ' Request
	// public globalSearch(search: string) {
	// 	return this.defaultGlobalSearch(TrackingGroupResource, search);
	// }

	public getProfileLabel(profile: TrackingGroupProfileResponse) {
		return profile.label;
	}

	public getIndexLabel(record: TrackingGroupIndexResponse): string {
		return record.label;
	}
}

export const TrackingGroupRouter = new TrackingGroupRouterClass();
