import { DidDashboardRouter } from 'RtUi/app/rtDid/Dashboard/DidDashboard.router';
import { MetricTotalsByVendorChart } from 'RtUi/app/rtDid/Dashboard/lib/charts/MetricTotalsByVendorChart';
import { MetricSummary } from 'RtUi/app/rtDid/Dashboard/lib/components/MetricSummary';
import { MetricTotalsByDateDashboardTabs } from 'RtUi/app/rtDid/Dashboard/lib/components/MetricTotalsByDateDashboardTabs';
import { MetricTotalsByVendorGrid } from 'RtUi/app/rtDid/Dashboard/lib/grids/MetricTotalsByVendorGrid';
import { IMetricsTotalsByResource } from 'RtUi/app/rtDid/Dashboard/lib/http/DidMetricHttp';
import { DidMetricTotalsByResourceResource } from 'RtUi/app/rtDid/Dashboard/lib/resources/DidMetricTotalsByResourceResource';
import { DidResourceRouter } from 'RtUi/app/rtDid/Resources/DidResource.router';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { DashboardTabs } from 'RtUi/components/ui/DashboardTabs';
import { DashboardTab } from 'RtUi/components/ui/DashboardTabs/tab';
import { Loading } from 'RtUi/components/ui/Loading';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { ResourceType } from 'RtModels';
import { DidDrilldownRouter } from 'RtUi/app/rtDid/Drilldown/DidDrilldown.router';

interface IDidDashboardIndexContainerState {
	auditTotalsByVendorActiveTab: string;
	didAuditTotalsByVendorResource: DidMetricTotalsByResourceResource | null;
}

@DidDashboardRouter.getIndexRtUiController()
export class DidDashboardIndexContainer extends ApplicationContainer<
	{},
	IDidDashboardIndexContainerState
> {
	public state: IDidDashboardIndexContainerState = {
		auditTotalsByVendorActiveTab: 'chart',
		didAuditTotalsByVendorResource: null
	};

	public componentDidMount() {
		const didAuditTotalsByVendorResource =
			new DidMetricTotalsByResourceResource();

		this.setState({ didAuditTotalsByVendorResource });
	}

	public onAuditVendorTotalClick(auditVendorTotal: IMetricsTotalsByResource) {
		const vendorDashboardTab =
			DidResourceRouter.getProfileTabs().Dashboard.header;
		const vendorDashboardPath = DidResourceRouter.getProfileRoute(
			auditVendorTotal.resourceId,
			vendorDashboardTab
		);

		this.goToPath(vendorDashboardPath);
	}

	public onMetricDrilldownClick(selected: ResourceType[]) {
		const types = selected.join(',');
		const drilldownIndexRoute = DidDrilldownRouter.getIndexRouteWithParams({
			types
		});

		this.goToPath(drilldownIndexRoute);
	}

	public render() {
		const { didAuditTotalsByVendorResource } = this.state;

		if (!didAuditTotalsByVendorResource) {
			return <Loading />;
		}

		return (
			<StandardLayout router={DidDashboardRouter}>
				<MetricSummary
					className="mb-4"
					onDrilldownClick={(selected) => this.onMetricDrilldownClick(selected)}
				/>
				<MetricTotalsByDateDashboardTabs className="mb-4" />
				<DashboardTabs
					className="mb-4"
					header="Active Totals By Vendor"
					activeTab={this.state.auditTotalsByVendorActiveTab}
					onTabChange={(auditTotalsByVendorActiveTab) =>
						this.setState({ auditTotalsByVendorActiveTab })
					}
				>
					<DashboardTab tabId="chart" icon="fas fa-chart-bar">
						<MetricTotalsByVendorChart
							resource={didAuditTotalsByVendorResource}
							onClick={(auditVendorTotal) =>
								this.onAuditVendorTotalClick(auditVendorTotal)
							}
						/>
					</DashboardTab>
					<DashboardTab tabId="grid" icon="fas fa-table">
						<MetricTotalsByVendorGrid
							resource={didAuditTotalsByVendorResource}
						/>
					</DashboardTab>
				</DashboardTabs>
			</StandardLayout>
		);
	}
}
