import { FC, useMemo } from 'react';
import {
	DemoIndexResponse,
	FileProcessIndexRequest,
	FileProcessIndexResponse
} from 'RtModels';
import { useGetLrnLookup } from 'RtUi/app/rtVue/LrnLookup/service';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLrnLookupColumns } from 'RtUi/components/data/DataGrid/configurations/administration/rateSheet/lrnLookup';

interface ILrnLookupGridProps {
	onRowClick?: (row: DemoIndexResponse) => void;
	resourceParams: FileProcessIndexRequest;
	pageName: string;
	router: RtUiRouter;
}

export const LrnLookupGrid: FC<ILrnLookupGridProps> = ({
	resourceParams,
	pageName,
	router
}) => {
	const { data, isLoading } = useGetLrnLookup(resourceParams);

	const columns = useMemo(() => getLrnLookupColumns(), []);

	return (
		<DataGrid<FileProcessIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={pageName}
			router={router}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};

LrnLookupGrid.displayName = 'LrnLookupGrid';
