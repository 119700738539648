import {
	MetricsCountsIndexResponse,
	MetricsIndexSummaryRequest,
	MetricsIndexSummaryResponse,
	MetricsTotalsByResourceResponse
} from 'RtModels';
import { DidMetricCountResource } from 'RtUi/app/rtDid/Dashboard/lib/resources/DidMetricCountResource';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { RtxDidApiRoutes } from 'RtExports/routes';

export interface IMetricsCountsIndex extends MetricsCountsIndexResponse {}
export interface IMetricsTotalsByResource
	extends MetricsTotalsByResourceResponse {}
export interface IMetricsIndexSummary extends MetricsIndexSummaryResponse {}
export interface IMetricsIndexSummaryRequest
	extends MetricsIndexSummaryRequest {}

export class DidMetricsHttp {
	private static metricSummaryCache: Promise<IMetricsIndexSummary[]> | null =
		null;

	public getMetricCountsByNpa() {
		return HttpRequest.fetchWithRoute<IMetricsCountsIndex[]>(
			RtxDidApiRoutes.MetricsByNPA.Index
		);
	}

	public getMetricCountsByState() {
		return HttpRequest.fetchWithRoute<IMetricsCountsIndex[]>(
			RtxDidApiRoutes.MetricsByState.Index
		);
	}

	public getMetricCountsByVendor() {
		return HttpRequest.fetchWithRoute<IMetricsCountsIndex[]>(
			RtxDidApiRoutes.MetricsByResource.Index
		);
	}

	public getMetricSummary() {
		if (!DidMetricsHttp.metricSummaryCache) {
			const params: IMetricsIndexSummaryRequest = {};

			DidMetricsHttp.metricSummaryCache = HttpRequest.fetchWithRoute<
				IMetricsIndexSummary[]
			>(RtxDidApiRoutes.AuditSummary.Index, { params });

			const fiveMinsInMs = 5 * 60 * 1000;
			setTimeout(
				() => (DidMetricsHttp.metricSummaryCache = null),
				fiveMinsInMs
			);
		}

		return DidMetricsHttp.metricSummaryCache;
	}

	public async getMetricCountsResourceByNpa() {
		const auditCountsDefer = this.getMetricCountsByNpa();

		return this.createDidMetricCountResource(auditCountsDefer);
	}

	public async getMetricCountsResourceByState() {
		const auditCountsDefer = this.getMetricCountsByState();

		return this.createDidMetricCountResource(auditCountsDefer);
	}

	public async getMetricCountsResourceByVendor() {
		const auditCountsDefer = this.getMetricCountsByVendor();

		return this.createDidMetricCountResource(auditCountsDefer);
	}

	private async createDidMetricCountResource(
		auditCountsDefer: Promise<IMetricsCountsIndex[]>
	) {
		const auditCounts = await auditCountsDefer;

		return new DidMetricCountResource(auditCounts);
	}
}
