import * as React from 'react';
import { MyProfileHttp } from 'RtUi/app/user/MyProfile/lib/Http/MyProfileHttp';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { UserProfileResponse } from 'RtModels';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { UserActions } from 'RtUi/state/actions/user';

interface IMyProfileTFAFormDeleteProps {
	onUpdate: (newUser: UserProfileResponse) => void;
}

interface IMyProfileTFAFormDeleteState {
	isSubmitting: boolean;
	error?: any;
}

export class MyProfileTFAFormDelete extends React.Component<
	IMyProfileTFAFormDeleteProps,
	IMyProfileTFAFormDeleteState
> {
	public myProfileHttp = new MyProfileHttp();

	public state: IMyProfileTFAFormDeleteState = {
		isSubmitting: false,
		error: undefined
	};

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const confirmation = await Confirmation.create(
			<span>
				You are about to turn off&nbsp;
				<span className="text-muted">Two-Factor Authentication</span>. Would you
				like to continue?
			</span>
		);

		if (!confirmation) {
			return;
		}

		this.setState({ isSubmitting: true, error: undefined });
		try {
			const newUser = await UserActions.disableTFA();
			this.setState({ isSubmitting: false }, () => {
				this.props.onUpdate(newUser);
			});
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	}

	public render() {
		return (
			<RtUiWideForm
				onSubmit={(evt) => this.onSubmit(evt)}
				displayMode={false}
				createMode={true}
				error={this.state.error}
				submitButtonText="Turn Off"
				submitButtonColor="danger"
				isSubmitting={this.state.isSubmitting}
			>
				<section className="mb-3">
					<header>
						<h6 className="d-flex- justify-content-start align-items-center">
							<i className="fas fa-fw fa-exclamation-triangle me-2 text-danger" />
							<b>Turn Off Two-Factor Authentication</b>
						</h6>
					</header>
					<span>
						The following action will reset all devices two-factor
						authentication so a new code will be requested the next time login
						attempted from them.
					</span>
				</section>
			</RtUiWideForm>
		);
	}
}
