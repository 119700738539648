/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $BrandingIndexResponse = {
    properties: {
        logo: {
            type: 'string',
            isRequired: true,
        },
        siteTitle: {
            type: 'string',
            isRequired: true,
        },
        loginTitle: {
            type: 'string',
            isRequired: true,
        },
        forgotPasswordTitle: {
            type: 'string',
            isRequired: true,
        },
        loginLogo: {
            type: 'string',
            isRequired: true,
        },
    },
};