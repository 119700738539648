/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { ServerGroupIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchServerGroups = async (): Promise<
	FullResponse<ServerGroupIndexResponse[]>
> => {
	return handleGetRequest(RtAdmApiRoutes.ServerGroups.Index, {
		includeFullResponse: true
	});
};

export const useGetServerGroups = () => {
	return useQuery<FullResponse<ServerGroupIndexResponse[]>, Error>(
		'getServersGroups',
		() => fetchServerGroups()
	);
};
