import {
	DataFilter,
	DataFilterOperator,
	DataSources,
	GlobalUserQueryIdentifiers,
	RejectDetailsIndexResponse
} from 'RtModels';
import { CdrSearchViewRouter } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.router';
import { RtVueReportUrlSearchParamsCreate } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueReportUrlSearchParams';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRejectDetailsRevenue } from 'RtUi/components/data/DataGrid/configurations/rtVue/rejectDetailsRevenue';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import moment from 'moment';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface IRejectDetailsRevenueCostGridProps {
	autoFocusFilter?: boolean;
	resource?: HttpResource<RejectDetailsIndexResponse> | null;
	isLoading?: boolean;
	isRevenue?: boolean;
	callDateFormat?: string;
}

export function createFilterValue(
	key: string,
	value: string,
	operator: DataFilterOperator = DataFilterOperator.Begins,
	dataSource: DataSources = DataSources.Text
): DataFilter {
	return {
		key,
		operands: [
			{
				dataSource,
				value
			}
		],
		operator
	};
}

function onDrilldown(row: RejectDetailsIndexResponse, isRevenue?: boolean) {
	const drillDownParams: RtVueReportUrlSearchParamsCreate = {
		date: moment(row.callDate).toISOString(),
		fileStreamId: String(row.fileStreamId),
		userQueryIdent: isRevenue
			? GlobalUserQueryIdentifiers.RevenueDetail
			: GlobalUserQueryIdentifiers.CostDetail
	};

	const filters: DataFilter[] = [];

	const callTypesToMatch = ['TF', 'DID', 'ITFS', 'IDID'];
	const matchSetOfCallTypes = callTypesToMatch.includes(row.callType);

	if (matchSetOfCallTypes && isRevenue) {
		drillDownParams.additionalColumns = 'revenue_reject_code';
		drillDownParams.egressTrunkGroupId = row.trunkGroup;
		drillDownParams.dialedNumber = row.billingNumber;

		filters.push(
			createFilterValue(
				'revenue_reject_code',
				row.rejectCode.valueOf().toString()
			)
		);
	} else if (matchSetOfCallTypes && !isRevenue) {
		drillDownParams.ingressTrunkGroupId = row.trunkGroup;
	} else if (!matchSetOfCallTypes && isRevenue) {
		drillDownParams.additionalColumns = 'revenue_reject_code';
		drillDownParams.ingressTrunkGroupId = row.trunkGroup;

		filters.push(
			createFilterValue(
				'revenue_reject_code',
				row.rejectCode.valueOf().toString()
			)
		);
	} else if (!matchSetOfCallTypes && !isRevenue) {
		drillDownParams.egressTrunkGroupId = row.trunkGroup;
	}

	const drillDownPath = CdrSearchViewRouter.getDrillDownPath(
		drillDownParams,
		filters
	);

	ApplicationContainer.openNewTab(drillDownPath);
}

export const RejectDetailsRevenueCostGrid = ({
	resource,
	isLoading,
	isRevenue = false,
	callDateFormat
}: IRejectDetailsRevenueCostGridProps) => {
	const { data = [] } = useQuery<
		RejectDetailsIndexResponse[] | undefined,
		Error
	>([`useGetRejectDetailsRevenueCostReport`, resource], () =>
		resource?.getAll()
	);
	const columns = useMemo(
		() => getRejectDetailsRevenue(onDrilldown, isRevenue, callDateFormat),
		[isRevenue, callDateFormat]
	);

	return (
		<DataGrid<RejectDetailsIndexResponse>
			pageName="rtVue_rejectDetailsRevenueCost"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
