import {
	RoutingRuleMatchType,
	RoutingRuleMatchingResponse,
	RoutingRulesMatchTypesIndexResponse
} from 'RtModels';
import { RoutingRulesMatchTypeResource } from 'RtUi/app/rtSip/RoutingRules/lib/resources/RoutingRulesMatchTypeResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridCache } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { CountryRenderCache } from 'RtUi/components/data/DataGrid/columns/CountryDataGridColumn';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { GatewayDataGridCache } from 'RtUi/components/data/DataGrid/columns/GatewayPortDataGridColumn';
import { RateCenterRenderCache } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { getRateCenterTypeLabel } from 'RtUi/components/data/DataGrid/columns/RateCenterTypeDataGridColumn';
import { RoutePlanDataGridCache } from 'RtUi/components/data/DataGrid/columns/RoutePlanDataGridColumn';
import { SubscriptionDataGridCache } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { SwitchDataGridCache } from 'RtUi/components/data/DataGrid/columns/SwitchDataGridColumn';
import { GenericTypeRender } from 'RtUi/components/ui/GenericTypeRenderer';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';
import { Badge } from 'react-bootstrap';

interface RoutingRuleMatchingDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const RoutingRuleMatchTypeDataGridCache =
	new ResourceCacheMap<RoutingRulesMatchTypesIndexResponse>(
		new RoutingRulesMatchTypeResource(),
		'routingRuleMatchTypeId',
		'label'
	);

const RoutingRuleMatchingPatternRender = (
	matchTypeId: RoutingRuleMatchType,
	value: string
): JSX.Element => {
	switch (matchTypeId) {
		case RoutingRuleMatchType.ToRateCenterId:
		case RoutingRuleMatchType.FromRateCenterId: {
			return (
				<GenericTypeRender<any>
					id={Number(value)}
					showIdInLabel={false}
					resourceCacheMap={RateCenterRenderCache}
				/>
			);
		}
		case RoutingRuleMatchType.ToRateCenterTypeId:
		case RoutingRuleMatchType.FromRateCenterTypeId: {
			return <>{getRateCenterTypeLabel(Number(value))}</>;
		}
		case RoutingRuleMatchType.ToIso:
		case RoutingRuleMatchType.FromIso: {
			return (
				<GenericTypeRender<any>
					id={value}
					showIdInLabel={false}
					resourceCacheMap={CountryRenderCache}
				/>
			);
		}
		case RoutingRuleMatchType.InVendorAccountId:
		case RoutingRuleMatchType.InCustomerAccountId:
		case RoutingRuleMatchType.EgVendorAccountId:
		case RoutingRuleMatchType.EgCustomerAccountId: {
			return (
				<GenericTypeRender<any>
					id={Number(value)}
					showIdInLabel={false}
					resourceCacheMap={AccountDataGridCache}
				/>
			);
		}
		case RoutingRuleMatchType.InVendorSubscriptionId:
		case RoutingRuleMatchType.InCustomerSubscriptionId:
		case RoutingRuleMatchType.EgVendorSubscriptionId:
		case RoutingRuleMatchType.EgCustomerSubscriptionId: {
			return (
				<GenericTypeRender<any>
					id={Number(value)}
					showIdInLabel={false}
					resourceCacheMap={SubscriptionDataGridCache}
				/>
			);
		}
		case RoutingRuleMatchType.InCustomerRoutePlanId:
		case RoutingRuleMatchType.EgCustomerRoutePlanId: {
			return (
				<GenericTypeRender<any>
					id={Number(value)}
					showIdInLabel={false}
					resourceCacheMap={RoutePlanDataGridCache}
				/>
			);
		}
		case RoutingRuleMatchType.InSwitchId: {
			return (
				<GenericTypeRender<any>
					id={Number(value)}
					showIdInLabel={false}
					resourceCacheMap={SwitchDataGridCache}
				/>
			);
		}
		case RoutingRuleMatchType.InGatewayId: {
			return (
				<GenericTypeRender<any>
					id={Number(value)}
					showIdInLabel={false}
					resourceCacheMap={GatewayDataGridCache}
				/>
			);
		}
		default: {
			return <>{value}</>;
		}
	}
};

export const RoutingRuleMatchingDataGridColumn = <T = any,>(
	config: RoutingRuleMatchingDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return DefaultDataGridColumn({
		...config,
		getValue: ({ cell }) => {
			return (
				<div className="d-flex flex-column gap-1">
					{cell
						.getValue<RoutingRuleMatchingResponse[]>()
						.map((matching, index) => (
							<GenericTypeRender<RoutingRulesMatchTypesIndexResponse>
								id={matching.routingRuleMatchTypeId}
								key={index}
								showIdInLabel={false}
								resourceCacheMap={RoutingRuleMatchTypeDataGridCache}
								renderValue={(id, label) => (
									<span>
										<Badge pill bg="secondary" className="me-1">
											{RoutingRuleMatchingPatternRender(
												matching.routingRuleMatchTypeId,
												matching.pattern
											)}
										</Badge>
										({label})
									</span>
								)}
							/>
						))}
				</div>
			);
		}
	});
};
