import clsx from 'clsx';
import { FC, useContext, useEffect } from 'react';
import { useUpdate } from 'react-use';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { CprError, CprErrorType } from 'Somos/lib/SomosCpr/RtCprV2';

export const CprErrorsTabbedCustomerHeader: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const updateComponent = useUpdate();
	const { cpr: routingProfile } = useContext(CprProfileContext);

	useEffect(() => {
		return routingProfile.onValidate(() => {
			updateComponent();
		});
	});

	const routingProfileErrors = routingProfile.getErrors();

	const warningAndNoticeErrors: CprError[] = [];
	const errorAndCriticalErrors: CprError[] = [];
	const warningAndNoticeErrorTypes = [
		CprErrorType.Notice,
		CprErrorType.Warning
	];

	for (const cprError of routingProfileErrors) {
		if (warningAndNoticeErrorTypes.includes(cprError.cprErrorTypeId)) {
			warningAndNoticeErrors.push(cprError);
		} else {
			errorAndCriticalErrors.push(cprError);
		}
	}

	const hasErrors = errorAndCriticalErrors.length > 0;
	const hasWarnings = warningAndNoticeErrors.length > 0;
	const hasWarningsAndNotErrors = hasWarnings && !hasErrors;

	return (
		<span
			className={clsx({
				'text-danger': hasErrors,
				'text-warning': hasWarningsAndNotErrors
			})}
		>
			Validation&nbsp;&nbsp;
			{(hasErrors || hasWarnings) && (
				<small
					className={clsx('rounded text-white px-2', {
						'bg-danger': hasErrors,
						'bg-warning': hasWarningsAndNotErrors
					})}
				>
					{routingProfileErrors.length.toLocaleString()}
				</small>
			)}
		</span>
	);
};
