import * as React from 'react';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IManagedResourceClass } from '../resources/ManagedResource';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { Col, Row, Button } from 'react-bootstrap';

interface IManagedSqlProfile {
	sqlUserName: string;
	sqlPassword?: string;
}

interface IManagedUpdateSqlProps<ProfileType extends IManagedSqlProfile> {
	id: string;
	resourceClass: IManagedResourceClass<any, ProfileType>;
	profile: IManagedSqlProfile;
	onUpdate: (profile: ProfileType) => void;
}

interface IManagedUpdateSqlState extends IManagedSqlProfile {
	sqlUserName: string;
	sqlPassword: string;
	confirmSqlPassword: string;
	isSubmitting: boolean;
	displayMode: boolean;
	error?: any;
	customValidityError?: string;
}

export class ManagedUpdateSql<
	ProfileType extends IManagedSqlProfile
> extends React.Component<
	IManagedUpdateSqlProps<ProfileType>,
	IManagedUpdateSqlState
> {
	public state: IManagedUpdateSqlState = {
		sqlUserName: '',
		sqlPassword: '',
		confirmSqlPassword: '',
		isSubmitting: false,
		displayMode: true,
		error: undefined
	};

	public componentDidMount() {
		this.init();
	}

	public init(reset = false) {
		const { sqlUserName = '', sqlPassword = '' } = this.props.profile;
		const confirmSqlPassword = sqlPassword;

		this.setState({ sqlUserName, sqlPassword, confirmSqlPassword });

		if (reset) {
			this.setState({
				isSubmitting: false,
				displayMode: true,
				error: undefined
			});
		}
	}

	public updatePassword(sqlPassword: string) {
		this.setState({ sqlPassword }, () => this.checkForPasswordsMatching());
	}

	public updatePasswordConfirm(confirmSqlPassword: string) {
		this.setState({ confirmSqlPassword }, () =>
			this.checkForPasswordsMatching()
		);
	}

	public checkForPasswordsMatching() {
		const { confirmSqlPassword, sqlPassword } = this.state;
		let customValidityError = '';

		if (confirmSqlPassword !== sqlPassword) {
			customValidityError = 'Passwords must match.';
		}

		if (this.state.customValidityError !== customValidityError) {
			this.setState({ customValidityError });
		}
	}

	public submit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const { resourceClass, id } = this.props;
		const { sqlPassword, sqlUserName } = this.state;

		const managedResource = new resourceClass();

		this.setState({ isSubmitting: true, error: undefined });

		managedResource
			.updateSql(id, sqlUserName, sqlPassword)
			.then((profile) => {
				this.props.onUpdate(profile);

				this.setState({
					displayMode: true,
					sqlPassword: '',
					confirmSqlPassword: ''
				});
			})
			.catch((error) => {
				this.setState({ error });
			})
			.finally(() => {
				this.setState({ isSubmitting: false });
			});
	}

	public delete() {
		const { resourceClass, id } = this.props;

		const managedResource = new resourceClass();

		this.setState({ isSubmitting: true, error: undefined });

		managedResource
			.deleteSql(id)
			.then((profile) => {
				this.props.onUpdate(profile);

				this.setState({
					displayMode: true,
					sqlUserName: '',
					sqlPassword: '',
					confirmSqlPassword: ''
				});
			})
			.catch((error) => {
				this.setState({ error });
			})
			.finally(() => {
				this.setState({ isSubmitting: false });
			});
	}

	public render() {
		return (
			<RtUiNarrowForm
				header="SQL Credentials"
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onCancel={() => this.init(true)}
				onChange={(displayMode) => this.setState({ displayMode })}
				editModeActions={() => (
					<Button
						type="button"
						variant="outline-danger"
						disabled={this.state.isSubmitting}
						onClick={() => this.delete()}
					>
						Delete Credentials
					</Button>
				)}
				onSubmit={(evt) => this.submit(evt)}
			>
				<InputFormControl
					label="SQL Username"
					required
					displayMode={this.state.displayMode}
					onChange={(sqlUserName) => this.setState({ sqlUserName })}
					value={this.state.sqlUserName}
				/>
				<Row>
					<Col md>
						<InputFormControl
							label="SQL Password"
							required
							type="password"
							displayMode={this.state.displayMode}
							onChange={(sqlPassword) => this.updatePassword(sqlPassword)}
							value={
								this.state.displayMode ? '********' : this.state.sqlPassword
							}
						/>
					</Col>
					{!this.state.displayMode && (
						<Col md>
							<InputFormControl
								label="Confirm SQL Password"
								required
								type="password"
								displayMode={this.state.displayMode}
								customValidityError={this.state.customValidityError}
								onChange={(confirmSqlPassword) =>
									this.updatePasswordConfirm(confirmSqlPassword)
								}
								value={this.state.confirmSqlPassword}
							/>
						</Col>
					)}
				</Row>
			</RtUiNarrowForm>
		);
	}
}
