import { RtDashboardRouter } from 'RtUi/app/rtCommon/Dashboard/Dashboard.router';
import { DashboardDataGrid } from 'RtUi/app/rtCommon/Dashboard/lib/grids/DashboardGrid';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';

export const DashboardIndexContainer = (): JSX.Element => {
	return (
		<StandardLayout router={RtDashboardRouter}>
			<DashboardDataGrid />
		</StandardLayout>
	);
};

RtDashboardRouter.setIndexRtUiFunctionalComponent(DashboardIndexContainer);
