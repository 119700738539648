/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionRoutingProfileResponse = {
    properties: {
        blockTypeId: {
            type: 'number',
            isNullable: true,
        },
        routePlanId: {
            type: 'number',
            isNullable: true,
        },
        defaultSubscriptionId: {
            type: 'number',
            isNullable: true,
        },
        defaultScenarioId: {
            type: 'number',
            isNullable: true,
        },
        translatedNumberStd: {
            type: 'string',
            isNullable: true,
        },
        trackingGroupId: {
            type: 'number',
            isNullable: true,
        },
        defaultIsdnReleaseId: {
            type: 'number',
            isNullable: true,
        },
        defaultIsdnReleaseMs: {
            type: 'number',
            isNullable: true,
        },
        directSubscriptionId: {
            type: 'number',
            isNullable: true,
        },
        sourceSubscriptionId: {
            type: 'number',
            isNullable: true,
        },
        hasServiceNumbers: {
            type: 'number',
        },
    },
};