import clsx from 'clsx';
import { FC, Children } from 'react';
import { Alert } from 'react-bootstrap';

interface IWarningAlertProps {
	text?: string;
	hide?: boolean;
	className?: string;
}

export const WarningAlert: FC<React.PropsWithChildren<IWarningAlertProps>> = ({
	hide,
	text,
	className,
	children
}) => {
	if (hide) {
		return null;
	}

	return (
		<Alert
			variant="warning"
			className={clsx('d-flex justify-content-start', className)}
		>
			<i className="fas fa-fw fa-exclamation-triangle me-2" />
			{Children.count(children) > 0 ? children : <span>{text}</span>}
		</Alert>
	);
};
