import { Settings } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { CprNodeType } from 'RtModels';
import { CprNodeTypeNameMap } from 'RtUi/app/rt800/Cprs/lib/Constants';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
import { difference } from 'lodash-es';
import { useMemo, useState } from 'react';

interface ICprLabelRowIndexDataGridColumnHeaderProps {
	cpr: Cpr;
	updateLabels: () => void;
}

export const CprLabelRowIndexDataGridColumnHeader = ({
	cpr,
	updateLabels
}: ICprLabelRowIndexDataGridColumnHeaderProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const cprLabels = cpr.getCprLabels();
	const cprLabelsLength = cprLabels.length;
	const shownCprNodeTypes = cpr.getCprColNodeTypes();
	const nodeTypes = useMemo(() => Array.from(CprNodeTypeNameMap.keys()), []);
	const notShownNodeTypes = useMemo(
		() => difference(nodeTypes, shownCprNodeTypes),
		[nodeTypes, shownCprNodeTypes]
	);

	const addFirstLabel = (cprNodeTypeId: CprNodeType) => {
		const label = cpr.makeCprLabel(cprNodeTypeId, `*LABEL${cprLabelsLength}`);

		label?.setIsLabelNew(true);
		updateLabels();
	};

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton onClick={handleClick}>
				<Settings />
			</IconButton>
			<Menu
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				disableScrollLock
			>
				{shownCprNodeTypes.map((shownNodeType) => (
					<MenuItem
						key={shownNodeType}
						onClick={() => addFirstLabel(shownNodeType)}
					>
						<i className="fas fa-fw fa-plus-circle text-success" />
						&nbsp;&nbsp;{CprNodeTypeNameMap.get(shownNodeType)}
					</MenuItem>
				))}
				{notShownNodeTypes.map((notShownNodeType) => (
					<MenuItem
						key={notShownNodeType}
						onClick={() => addFirstLabel(notShownNodeType)}
					>
						<i className="fas fa-fw fa-plus-circle text-success" />
						&nbsp;&nbsp;{CprNodeTypeNameMap.get(notShownNodeType)}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
