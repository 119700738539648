/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CallSimulatorRequest = {
    properties: {
        ani: {
            type: 'string',
            isRequired: true,
        },
    },
};