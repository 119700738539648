/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrSearchIndexResponse = {
    properties: {
        vuid: {
            type: 'string',
            isRequired: true,
        },
        row_id: {
            type: 'number',
            isRequired: true,
        },
        ingress_invite_ts: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        ingress_release_ts: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        file_stream_id: {
            type: 'number',
            isRequired: true,
        },
        to_raw: {
            type: 'string',
            isRequired: true,
        },
        fr_raw: {
            type: 'string',
            isRequired: true,
        },
        egress_trunk_group_id: {
            type: 'string',
            isRequired: true,
        },
        ingress_trunk_group_id: {
            type: 'string',
            isRequired: true,
        },
        ingress_connection_id: {
            type: 'number',
            isRequired: true,
        },
        egress_connection_id: {
            type: 'number',
            isRequired: true,
        },
        cdr_record: {
            type: 'string',
            isRequired: true,
        },
        calculated_call_type: {
            type: 'string',
            isRequired: true,
        },
        calculated_jurisdiction: {
            type: 'string',
            isRequired: true,
        },
        ingress_raw_duration_ms: {
            type: 'number',
            isRequired: true,
        },
        ingress_call_id: {
            type: 'string',
            isRequired: true,
        },
        egress_call_id: {
            type: 'string',
            isRequired: true,
        },
        fr_lrn: {
            type: 'string',
            isRequired: true,
        },
        to_lrn: {
            type: 'string',
            isRequired: true,
        },
        attestation: {
            type: 'string',
            isRequired: true,
        },
        file_name: {
            type: 'string',
            isRequired: true,
        },
        is_dest_same: {
            type: 'number',
            isRequired: true,
        },
        ingress_alternate_id: {
            type: 'string',
            isRequired: true,
        },
        ingress_to_ip: {
            type: 'string',
            isRequired: true,
        },
        ingress_fr_ip: {
            type: 'string',
            isRequired: true,
        },
        ingress_cic: {
            type: 'string',
            isRequired: true,
        },
        ingress_answer_ts: {
            type: 'string',
            isRequired: true,
        },
        ingress_progress_ts: {
            type: 'string',
            isRequired: true,
        },
        ingress_result_id: {
            type: 'string',
            isRequired: true,
        },
        ingress_release_id: {
            type: 'number',
            isRequired: true,
        },
        ingress_connect: {
            type: 'number',
            isRequired: true,
        },
        ingress_pdd_ms: {
            type: 'number',
            isRequired: true,
        },
        is_final: {
            type: 'number',
            isRequired: true,
        },
        egress_alternate_id: {
            type: 'string',
            isRequired: true,
        },
        egress_to_ip: {
            type: 'string',
            isRequired: true,
        },
        egress_fr_ip: {
            type: 'string',
            isRequired: true,
        },
        egress_cic: {
            type: 'string',
            isRequired: true,
        },
        egress_invite_ts: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        egress_answer_ts: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        egress_progress_ts: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        egress_release_ts: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        egress_result_id: {
            type: 'string',
            isRequired: true,
        },
        egress_release_id: {
            type: 'number',
            isRequired: true,
        },
        egress_connect: {
            type: 'number',
            isRequired: true,
        },
        egress_raw_duration_ms: {
            type: 'number',
            isRequired: true,
        },
        egress_pdd_ms: {
            type: 'number',
            isRequired: true,
        },
        egress_route_depth: {
            type: 'number',
            isRequired: true,
        },
        egress_route_list: {
            type: 'string',
            isRequired: true,
        },
        to_std: {
            type: 'string',
            isRequired: true,
        },
        fr_jip: {
            type: 'string',
            isRequired: true,
        },
        to_iso: {
            type: 'string',
            isRequired: true,
        },
        to_dial_code: {
            type: 'string',
            isRequired: true,
        },
        to_rate_center_id: {
            type: 'number',
            isRequired: true,
        },
        to_lrn_dial_code: {
            type: 'string',
            isRequired: true,
        },
        fr_std: {
            type: 'string',
            isRequired: true,
        },
        fr_iso: {
            type: 'string',
            isRequired: true,
        },
        fr_dial_code: {
            type: 'string',
            isRequired: true,
        },
        fr_rate_center_id: {
            type: 'number',
            isRequired: true,
        },
        fr_lrn_dial_code: {
            type: 'string',
            isRequired: true,
        },
        ingress_mos: {
            type: 'number',
            isRequired: true,
        },
        ingress_jitter: {
            type: 'number',
            isRequired: true,
        },
        ingress_packets_sent: {
            type: 'number',
            isRequired: true,
        },
        ingress_packets_received: {
            type: 'number',
            isRequired: true,
        },
        ingress_to_is_valid: {
            type: 'number',
            isRequired: true,
        },
        ingress_to_is_dnc: {
            type: 'number',
            isRequired: true,
        },
        ingress_fr_is_valid: {
            type: 'number',
            isRequired: true,
        },
        ingress_fr_is_dno: {
            type: 'number',
            isRequired: true,
        },
        robo_score: {
            type: 'number',
            isRequired: true,
        },
        fraud_score: {
            type: 'number',
            isRequired: true,
        },
        anomaly_score: {
            type: 'number',
            isRequired: true,
        },
        is_dest_detail_same: {
            type: 'number',
            isRequired: true,
        },
        cost_carrier_id: {
            type: 'number',
            isRequired: true,
        },
        cost_subscription_id: {
            type: 'number',
            isRequired: true,
        },
        cost_service_id: {
            type: 'number',
            isRequired: true,
        },
        cost_billing_cycle_id: {
            type: 'string',
            isRequired: true,
        },
        cost_jurisdiction_id: {
            type: 'number',
            isRequired: true,
        },
        cost_billing_number_used: {
            type: 'string',
            isRequired: true,
        },
        cost_juris_precedence: {
            type: 'string',
            isRequired: true,
        },
        cost_rate_sheet_id: {
            type: 'number',
            isRequired: true,
        },
        cost_rate_key: {
            type: 'string',
            isRequired: true,
        },
        cost_rate: {
            type: 'number',
            isRequired: true,
        },
        cost_reject_code: {
            type: 'number',
            isRequired: true,
        },
        cost_duration_ms: {
            type: 'number',
            isRequired: true,
        },
        cost_rate_charge: {
            type: 'number',
            isRequired: true,
        },
        cost_connection_charge: {
            type: 'number',
            isRequired: true,
        },
        cost_other_charge: {
            type: 'number',
            isRequired: true,
        },
        cost_tax_charge: {
            type: 'number',
            isRequired: true,
        },
        cost_total_charge: {
            type: 'number',
            isRequired: true,
        },
        revenue_carrier_id: {
            type: 'number',
            isRequired: true,
        },
        revenue_subscription_id: {
            type: 'number',
            isRequired: true,
        },
        revenue_service_id: {
            type: 'number',
            isRequired: true,
        },
        revenue_billing_cycle_id: {
            type: 'string',
            isRequired: true,
        },
        revenue_jurisdiction_id: {
            type: 'number',
            isRequired: true,
        },
        revenue_billing_number_used: {
            type: 'string',
            isRequired: true,
        },
        revenue_juris_precedence: {
            type: 'string',
            isRequired: true,
        },
        revenue_rate_sheet_id: {
            type: 'number',
            isRequired: true,
        },
        revenue_rate_key: {
            type: 'string',
            isRequired: true,
        },
        revenue_rate: {
            type: 'number',
            isRequired: true,
        },
        revenue_reject_code: {
            type: 'number',
            isRequired: true,
        },
        revenue_duration_ms: {
            type: 'number',
            isRequired: true,
        },
        revenue_rate_charge: {
            type: 'number',
            isRequired: true,
        },
        revenue_connection_charge: {
            type: 'number',
            isRequired: true,
        },
        revenue_other_charge: {
            type: 'number',
            isRequired: true,
        },
        revenue_tax_charge: {
            type: 'number',
            isRequired: true,
        },
        revenue_total_charge: {
            type: 'number',
            isRequired: true,
        },
        total_cost: {
            type: 'number',
            isRequired: true,
        },
        total_revenue: {
            type: 'number',
            isRequired: true,
        },
        total_margin: {
            type: 'number',
            isRequired: true,
        },
        info_digits: {
            type: 'number',
            isRequired: true,
        },
        custom_field1: {
            type: 'string',
            isRequired: true,
        },
        custom_field2: {
            type: 'string',
            isRequired: true,
        },
        custom_field3: {
            type: 'string',
            isRequired: true,
        },
        custom_field4: {
            type: 'string',
            isRequired: true,
        },
        custom_field5: {
            type: 'string',
            isRequired: true,
        },
        cost_billing_ref1: {
            type: 'string',
            isRequired: true,
        },
        cost_billing_ref2: {
            type: 'string',
            isRequired: true,
        },
        cost_billing_ref3: {
            type: 'string',
            isRequired: true,
        },
        cost_billing_ref4: {
            type: 'string',
            isRequired: true,
        },
        cost_xref1: {
            type: 'string',
            isRequired: true,
        },
        cost_xref2: {
            type: 'string',
            isRequired: true,
        },
        cost_currency_type: {
            type: 'string',
            isRequired: true,
        },
        cost_conversationRate: {
            type: 'number',
            isRequired: true,
        },
        revenue_billing_ref1: {
            type: 'string',
            isRequired: true,
        },
        revenue_billing_ref2: {
            type: 'string',
            isRequired: true,
        },
        revenue_billing_ref3: {
            type: 'string',
            isRequired: true,
        },
        revenue_billing_ref4: {
            type: 'string',
            isRequired: true,
        },
        revenue_xref1: {
            type: 'string',
            isRequired: true,
        },
        revenue_xref2: {
            type: 'string',
            isRequired: true,
        },
        revenue_currency_type: {
            type: 'string',
            isRequired: true,
        },
        revenue_conversationRate: {
            type: 'number',
            isRequired: true,
        },
        attestation_has_token: {
            type: 'number',
            isRequired: true,
        },
        attestation_token_url: {
            type: 'string',
            isRequired: true,
        },
        ingress_latency: {
            type: 'number',
            isRequired: true,
        },
        egress_mos: {
            type: 'number',
            isRequired: true,
        },
        egress_jitter: {
            type: 'number',
            isRequired: true,
        },
        egress_packets_sent: {
            type: 'number',
            isRequired: true,
        },
        egress_packets_received: {
            type: 'number',
            isRequired: true,
        },
        egress_latency: {
            type: 'number',
            isRequired: true,
        },
        egress_fas: {
            type: 'number',
            isRequired: true,
        },
    },
};