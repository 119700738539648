import * as moment from 'moment-timezone';
import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import {
	EntityIndexResponse,
	RespOrgIndexResponse,
	TemplateIndexResponse,
	TollFreeStatusIndexResponse
} from 'RtModels';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { TollFreeNumbersTextArea } from './lib/controls/TollFreeNumbersTextArea';
import { TollFreeStatusSelect } from './lib/controls/TollFreeStatusSelect';
import { TollNumberInput } from './lib/controls/TollNumberInput';
import { NumberGrid } from './lib/grids/NumberGrid';
import { NumberRouter } from './Number.router';
interface IInitialResourceParams {
	entity: EntityIndexResponse | undefined;
	respOrg: RespOrgIndexResponse | undefined;
	template: TemplateIndexResponse | undefined;
	tfnStatus: TollFreeStatusIndexResponse[] | undefined;
	availableBeforeTs: moment.Moment | undefined;
	availableAfterTs: moment.Moment | undefined;
	tfns: string[];
	isAssignedToTemplate: number;
	showTimestamps: number;
	showExtendedCpr: number;
	search: string;
	defaultTollFreeStatuses?: string[];
}

interface INumbersContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@NumberRouter.getIndexRtUiController()
export class NumberIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	INumbersContainerState
> {
	public initParams: IInitialResourceParams = {
		entity: undefined,
		respOrg: undefined,
		template: undefined,
		tfnStatus: undefined,
		availableBeforeTs: undefined,
		availableAfterTs: undefined,
		showTimestamps: 0,
		showExtendedCpr: 0,
		isAssignedToTemplate: -1,
		search: '',
		tfns: []
	};

	public state: INumbersContainerState = {
		...this.initParams,
		activeTab: this.tabs.Search,
		resourceParams: {}
	};

	public availableAfterFormControl: DatetimeFormControl | null = null;
	public availableBeforeFormControl: DatetimeFormControl | null = null;

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public onHistoryPathChange(
		currentPath: string,
		searchParams: URLSearchParams
	) {
		if (searchParams.has('tfns')) {
			this.loadNumbersFromPathParam(searchParams.get('tfns')!);
		}
	}

	public loadNumbersFromPathParam(possibleTfnsStr: string) {
		if (possibleTfnsStr) {
			possibleTfnsStr = decodeURIComponent(possibleTfnsStr);
			const possibleTfns = possibleTfnsStr.split(',');

			this.setState({ tfns: possibleTfns }, () => {
				this.setActiveTab(this.tabs.Results);
			});
		}
	}

	public getResourceParams() {
		const resourceParams: any = {};
		const {
			respOrg,
			entity,
			template,
			tfnStatus,
			tfns,
			search,
			showExtendedCpr,
			isAssignedToTemplate
		} = this.state;
		const { showTimestamps, availableAfterTs, availableBeforeTs } = this.state;

		if (tfns.length > 0) {
			resourceParams.tfns = tfns.map((tfn) => tfn.trim());
		}

		if (search) {
			resourceParams.search = search;
		}

		if (template) {
			resourceParams.templateName = template.templateName;
		}

		if (respOrg) {
			resourceParams.respOrgId = respOrg.respOrgId;
		}

		if (entity) {
			resourceParams.entityId = entity.entityId;
		}

		if (template) {
			resourceParams.templateName = template.templateName;
		}

		if (Array.isArray(tfnStatus) && tfnStatus.length > 0) {
			resourceParams.tfnStatusIds = tfnStatus.map((t) => t.tfnStatusId);
		}

		if (isAssignedToTemplate >= 0) {
			resourceParams.isAssignedToTemplate = isAssignedToTemplate;
		}

		if (showExtendedCpr > 0) {
			resourceParams.showExtendedCpr = showExtendedCpr;
		}

		if (showTimestamps > 0) {
			resourceParams.showTimestamps = showTimestamps;
		}

		if (availableAfterTs) {
			resourceParams.availableAfterTs = availableAfterTs.toDate();
		}

		if (availableBeforeTs) {
			resourceParams.availableBeforeTs = availableBeforeTs.toDate();
		}

		return resourceParams;
	}

	public setTemplateUnassignedPreset() {
		this.resetRevertibleParamsToInitial(() => {
			const defaultEntityId = this.getUsersDefaultEntityId();
			const isAssignedToTemplate = 0;

			this.setState({ isAssignedToTemplate, defaultEntityId });
			this.setTollFreeStatuses(['W']);
		});
	}

	public setDroppingSparePreset(
		timeInterval?: 'afterToday' | 'thisMonth' | 'nextMonth'
	) {
		this.resetRevertibleParamsToInitial(() => {
			if (!this.availableAfterFormControl || !this.availableBeforeFormControl) {
				console.error('Cannot find DateTimeFormControls');
				return;
			}

			let availableBeforeDate: moment.Moment | undefined;
			let availableAfterDate: moment.Moment | undefined;
			let isNow = false;

			if (timeInterval === 'afterToday') {
				availableAfterDate = moment().add(1, 'days').startOf('day');
			} else if (timeInterval === 'thisMonth') {
				availableAfterDate = moment().startOf('month');
				availableBeforeDate = moment().endOf('month');
			} else if (timeInterval === 'nextMonth') {
				availableAfterDate = moment().add(1, 'month').startOf('month');
				availableBeforeDate = moment().add(1, 'month').endOf('month');
			} else {
				isNow = true;
			}

			this.availableAfterFormControl.onChangeHandler(availableAfterDate, isNow);
			this.availableBeforeFormControl.onChangeHandler(
				availableBeforeDate,
				isNow
			);

			this.setTollFreeStatuses(['D', 'R', 'T']);
		});
	}

	public setTollFreeStatuses(defaultTollFreeStatuses: string[]) {
		this.setState(
			{ tfnStatus: undefined, defaultTollFreeStatuses: undefined },
			() => {
				this.setState({ defaultTollFreeStatuses });
			}
		);
	}

	public render() {
		const { respOrg } = this.state;
		const respOrgId = respOrg && respOrg.respOrgId;
		const entityId = this.state.entity && this.state.entity.entityId;

		return (
			<TabbedLayout
				router={NumberRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm wide onSubmit={(e) => this.submitAndGoToResults(e)}>
						<Form.Group className="d-flex justify-content-start align-items-center mb-3">
							<b className="me-2 text-muted">Presets:</b>
							<Dropdown className="me-2">
								<Dropdown.Toggle size="sm" variant="outline-primary">
									Dropping Spare
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => this.setDroppingSparePreset('afterToday')}
									>
										After Today
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => this.setDroppingSparePreset('thisMonth')}
									>
										This Month
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => this.setDroppingSparePreset('nextMonth')}
									>
										Next Month
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							<Button
								type="button"
								onClick={() => this.setTemplateUnassignedPreset()}
								variant="outline-primary"
								size="sm"
							>
								Template Unassigned
							</Button>
						</Form.Group>
						<hr />
						<Row>
							<Col xl={6}>
								<EntitySelect
									allowAll
									sortByExactMatch={true}
									onChange={(entity) => this.setState({ entity })}
									value={this.state.entity}
									initialOptionId={this.state.defaultEntityId}
								/>
								<RespOrgSelect
									allowAll
									entityId={entityId}
									onChange={(respOrg) => this.setState({ respOrg })}
									value={this.state.respOrg}
								/>
								<TemplateSelect
									entityId={entityId}
									onChange={(template) => this.setState({ template })}
									value={this.state.template}
									respOrgId={respOrgId}
								/>
								<RadioFormControl<number>
									label="Template Assigned"
									onChange={(isAssignedToTemplate) =>
										this.setState({
											isAssignedToTemplate
										})
									}
									value={this.state.isAssignedToTemplate}
									options={[
										{
											value: 1,
											label: 'Yes',
											tooltip: 'Status="Working" will apply'
										},
										{
											value: 0,
											label: 'No',
											tooltip: 'Status="Working" will apply'
										},
										{ value: -1, label: 'Both' }
									]}
								/>
								<TollFreeStatusSelect<true>
									multi={true}
									hidePending
									onChange={(tfnStatus) => this.setState({ tfnStatus })}
									value={this.state.tfnStatus}
									initialOptionId={this.state.defaultTollFreeStatuses}
								/>
							</Col>
							<Col xl={6}>
								<ControlGroup label="Pattern">
									<TollNumberInput
										onChange={(e) =>
											this.setState({
												search: e.currentTarget.value
											})
										}
										value={this.state.search}
										alwaysShowMask
										required
										allowWildcards={true}
									/>
								</ControlGroup>
								<TollFreeNumbersTextArea
									value={this.state.tfns}
									onChange={(tfns) => this.setState({ tfns })}
								/>
								<Card body className="bg-light">
									<header className="mb-3 text-muted d-flex justify-content-start">
										<i
											className="fas fa-fw fa-info-circle me-2"
											style={{ lineHeight: 1.5 }}
										/>
										<span>Available indicates Spare or becoming Spare</span>
									</header>
									<DatetimeFormControl
										label="Available After"
										ref={(availableAfterFormControl) =>
											(this.availableAfterFormControl =
												availableAfterFormControl)
										}
										onChangeWithIsNow={(availableAfterTs) =>
											this.setState({
												availableAfterTs
											})
										}
										value={this.state.availableAfterTs}
									/>
									<DatetimeFormControl
										label="Available Before"
										ref={(availableBeforeFormControl) =>
											(this.availableBeforeFormControl =
												availableBeforeFormControl)
										}
										onChangeWithIsNow={(availableBeforeTs) =>
											this.setState({
												availableBeforeTs
											})
										}
										value={this.state.availableBeforeTs}
									/>
								</Card>
								{/* <RadioFormControl<number> label="Show Extended CPR"
										onChange={(showExtendedCpr) => this.setState({ showExtendedCpr })}
										value={this.state.showExtendedCpr}
										options={[
											{ value: 1, label: 'Yes' },
											{ value: 0, label: 'No' },
										]} />

									<RadioFormControl<number> label="Show Timestamps"
										onChange={(showTimestamps) => this.setState({ showTimestamps })}
										value={this.state.showTimestamps}
										options={[
											{ value: 1, label: 'Yes' },
											{ value: 0, label: 'No' },
										]} /> */}
							</Col>
						</Row>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<NumberGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>

				<TabbedLayoutHelpContainer tab={this.tabs.Search}>
					Search and manage number inventory across at the entity-level or
					across multiple entities.
				</TabbedLayoutHelpContainer>
			</TabbedLayout>
		);
	}
}
