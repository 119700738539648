import { DataSources, OcnTypeIndexResponse } from 'RtModels';
import {
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { IndexedDbCacheStrategy } from 'RtUi/utils/cache/strategies/IndexedDbCacheStrategy';

/**
 * @singleton
 */
export class RtVueOcnTypeFilter extends RtVueFilter<OcnTypeIndexResponse> {
	public static getInstance() {
		if (!RtVueOcnTypeFilter.instance) {
			RtVueOcnTypeFilter.instance = new RtVueOcnTypeFilter();
		}

		return RtVueOcnTypeFilter.instance;
	}

	private static instance: RtVueOcnTypeFilter | undefined;
	private static readonly FilterName = 'RtVueOcnTypeFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<OcnTypeIndexResponse>
	> = new IndexedDbCacheStrategy(RtVueOcnTypeFilter.FilterName, 'type', [
		'type',
		'*keywords' as keyof OcnTypeIndexResponse
	]);

	protected constructor() {
		super(
			RtVueOcnTypeFilter.FilterName,
			DataSources.OcnType,
			RtVueOcnTypeFilter.DataCache
		);
	}

	public getLabelFor(record: OcnTypeIndexResponse): string {
		return record.type;
	}

	public getValueFor(record: OcnTypeIndexResponse): string {
		return String(record.type);
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<OcnTypeIndexResponse>
	> {
		return this.vueHttp.getOcnTypeFilter();
	}

	protected getKeywordsFor(record: OcnTypeIndexResponse): string[] {
		const keywords = this.getKeywordsFromPhrase(record.type);

		keywords.push(String(record.type));

		return keywords;
	}
}
