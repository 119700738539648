import {
	InvoiceIndexResponse,
	InvoiceSubscription,
	InvoiceSubscriptionProfileResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { MinutesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MinutesDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getInvoicesColumns = (): Array<
	DataGridColumn<InvoiceIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'invoiceId',
		header: 'Invoice Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'accountName',
		header: 'Legal Name'
	}),
	DateDataGridColumn({
		accessorKey: 'invoiceDate',
		header: 'Invoice Date'
	}),
	DefaultDataGridColumn({
		accessorKey: 'invoiceCycleGroupLabel',
		header: 'Cycle Group'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'startTs',
		header: 'Start (GMT)'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'endTs',
		header: 'End (GMT)'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'charges',
		header: 'Charges'
	})
];

export const getInvoicesSubscriptionsColumns = (): Array<
	DataGridColumn<InvoiceSubscription>
> => [
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	IntegerDataGridColumn({
		accessorKey: 'connects',
		header: 'Connects',
		showFooter: true
	}),
	MinutesDataGridColumn({
		header: 'Minutes',
		showFooter: true,
		accessorFn: (item) => item.duration / 60
	}),
	PrecisionDataGridColumn({
		accessorKey: 'charges',
		header: 'Charges',
		showFooter: true
	})
];

export const getInvoicesSubscriptionDetailsColumns = (): Array<
	DataGridColumn<InvoiceSubscriptionProfileResponse>
> => [
	LabelDataGridColumn({
		accessorKey: 'label',
		header: 'Destination'
	}),
	RateDataGridColumn({
		accessorKey: 'rate',
		header: 'Rate'
	}),
	IntegerDataGridColumn({
		accessorKey: 'connects',
		header: 'Connects',
		showFooter: true
	}),
	MinutesDataGridColumn({
		header: 'Minutes',
		showFooter: true,
		accessorFn: (item) => Number(item.duration) / 60
	}),
	PrecisionDataGridColumn({
		accessorKey: 'charges',
		header: 'Charges',
		showFooter: true
	})
];
