/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ScenarioActionType {
    Dial = 1,
    Menu = 2,
    Input = 3,
    GotoScenario = 4,
    GotoRandomScenario = 5,
    SayDigits = 6,
    SayInterval = 7,
    SayNumber = 8,
    SayCurrency = 9,
    SetValue = 10,
    SetEgressRevenue = 11,
    PlayTone = 12,
    TagMatching = 13,
    ResourceTagging = 14,
}