import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	TrendAnalysisIndexRequest,
	TrendAnalysisIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handlePostRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchTrendAnalysis = async (
	body: TrendAnalysisIndexRequest
): Promise<FullResponse<TrendAnalysisIndexResponse[]>> => {
	return handlePostRequest<TrendAnalysisIndexResponse[], true>(
		RtxSipApiRoutes.TrendAnalysis.Index,
		body,
		{
			includeFullResponse: true
		}
	);
};

export const useGetTrendAnalysis = (body: TrendAnalysisIndexRequest) => {
	return useQuery<FullResponse<TrendAnalysisIndexResponse[]>, Error>(
		[`useGetTrendAnalysis`, body],
		() => fetchTrendAnalysis(body),
		{ enabled: true }
	);
};
