import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { Aside } from 'RtUi/components/ui/Aside';
import { ICprBulkCarrierResponse } from 'Somos/lib/SomosCpr/RtCprV2';
import * as pluralize from 'pluralize';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { ICicIndexResponse } from '../controls/CarrierSelect';
import { BulkCarrierChangeForm } from '../forms/BulkCarrierChangeForm';

interface IBulkCarrierUpdateButtonProps {
	className?: string;
}

export const BulkCarrierUpdateButton: FC<
	React.PropsWithChildren<IBulkCarrierUpdateButtonProps>
> = ({ className }) => {
	const {
		cpr: mgiProfile,
		updateCpr,
		isNumberAndHasTemplateAssigned
	} = useContext(CprProfileContext);
	const [asideIsOpen, setAsideIsOpen] = useState(false);
	const [oldCarrier, setOldCarrier] = useState<ICicIndexResponse>();
	const [newCarrier, setNewCarrier] = useState<ICicIndexResponse>();
	const [bulkUpdateReturn, setBulkUpdateReturn] =
		useState<ICprBulkCarrierResponse>();

	const resetComponent = useCallback(() => {
		setAsideIsOpen(false);
		setOldCarrier(undefined);
		setNewCarrier(undefined);
		setBulkUpdateReturn(undefined);
	}, []);

	const bulkUpdate = useCallback(
		(dryRun: boolean): ICprBulkCarrierResponse => {
			if (!oldCarrier || !newCarrier || !mgiProfile) {
				throw new Error('Could not find MgiProfile');
			}

			return mgiProfile.bulkUpdateCarriers(
				oldCarrier.cic,
				newCarrier.cic,
				dryRun
			);
		},
		[mgiProfile, newCarrier, oldCarrier]
	);

	const completeWizard = useCallback(() => {
		bulkUpdate(false);
		updateCpr(mgiProfile);

		resetComponent();
	}, [bulkUpdate, resetComponent, updateCpr, mgiProfile]);

	useEffect(() => {
		if (oldCarrier && newCarrier) {
			const dryRun = bulkUpdate(true);

			setBulkUpdateReturn(dryRun);
		} else {
			setBulkUpdateReturn(undefined);
		}
	}, [oldCarrier, newCarrier, bulkUpdate]);

	const renderSummaryItem = useCallback((arrayLength: number, type: string) => {
		const word = pluralize(type, arrayLength);

		if (arrayLength === 0) {
			return null;
		}

		return (
			<ListGroup.Item>
				<i className="fas fa-fw fa-pencil-alt text-info me-2" />
				{arrayLength.toLocaleString()} {word} will be updated
			</ListGroup.Item>
		);
	}, []);

	if (!mgiProfile) {
		return null;
	}

	return (
		<>
			<Button
				variant="white-alt"
				type="button"
				className={className}
				disabled={isNumberAndHasTemplateAssigned}
				onClick={() => setAsideIsOpen(true)}
			>
				<i className="fas fa-fw fa-edit" />
				<span>&nbsp;Bulk Carrier Update</span>
			</Button>
			<Aside isOpen={asideIsOpen} disabledBody>
				<Aside.Header header="Bulk Carrier Update" onClose={resetComponent} />
				<Card.Body>
					<BulkCarrierChangeForm
						mgiProfile={mgiProfile}
						oldCarrier={oldCarrier}
						newCarrier={newCarrier}
						onChange={(oldCarrier, newCarrier) => {
							setOldCarrier(oldCarrier);
							setNewCarrier(newCarrier);
						}}
					/>
					{bulkUpdateReturn && (
						<section className="d-flex flex-column justify-content-center align-items-center">
							<ListGroup variant="flush" className="lead">
								{renderSummaryItem(bulkUpdateReturn.modifiedCprRowsQty, 'Rows')}
								{renderSummaryItem(
									bulkUpdateReturn.modifiedCprRowsQty,
									'Labels'
								)}
							</ListGroup>
						</section>
					)}
				</Card.Body>
				<Card.Body className="pt-0 text-end">
					<Button
						type="button"
						variant="white"
						className="me-2"
						onClick={resetComponent}
					>
						Cancel
					</Button>
					<Button type="button" variant="submit" onClick={completeWizard}>
						Update Carriers
					</Button>
				</Card.Body>
			</Aside>
		</>
	);
};
