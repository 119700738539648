/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RateLoadErrorIndexResponse = {
    properties: {
        rateLoadErrorId: {
            type: 'number',
            isRequired: true,
        },
        rateLoadId: {
            type: 'number',
            isRequired: true,
        },
        rateLoadErrorTypeId: {
            type: 'RateLoadErrorType',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        rateLoadApprovalTypeId: {
            type: 'RateLoadApprovalTypes',
            isRequired: true,
        },
        qty: {
            type: 'number',
            isRequired: true,
        },
        acknowledgedBy: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        acknowledgedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        allowedApprovalTypes: {
            type: 'Array',
        },
        errorTypeSummary: {
            type: 'string',
        },
        isApprovable: {
            type: 'string',
        },
    },
};