/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum FileTypeDataType {
    OnnetSwitch = 'onnet-switch',
    OnnetRated = 'onnet-rated',
    OffnetRated = 'offnet-rated',
}