import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { IconButton, MenuItem } from '@mui/material';
import { CprNodeTypeNameMap } from 'RtUi/app/rt800/Cprs/lib/Constants';
import { CprCol, CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2';

interface ICprColDataGridColumnHeaderActionsProps {
	cprCol: CprCol;
}

const RandomDecisionTypes = [
	CprNodeType.Date,
	CprNodeType.Percent,
	CprNodeType.DayOfWeek,
	CprNodeType.Time
];

const DecisionTypes = [
	CprNodeType.AreaCode,
	CprNodeType.Lata,
	CprNodeType.NpaNxx,
	CprNodeType.SixDigit,
	CprNodeType.State,
	CprNodeType.TenDigit
];

const ActionTypes = [
	CprNodeType.Carrier,
	CprNodeType.Announcement,
	CprNodeType.TerminatingNumber
];

export const CprColDataGridColumnHeaderActions = ({
	cprCol
}: ICprColDataGridColumnHeaderActionsProps) => {
	const { cpr } = cprCol;
	const cprColumnNodeTypes = cpr.getCprCols().map((col) => col.cprNodeTypeId);

	const renderNodeType = (nodeType: CprNodeType) => {
		const cprNodeName = CprNodeTypeNameMap.get(nodeType) ?? '';

		return (
			<MenuItem
				key={nodeType}
				className="d-flex justify-content-between align-items-center"
				style={{ textTransform: 'none', fontWeight: 400 }}
			>
				<IconButton
					type="button"
					onClick={() => {
						const index = cprCol.getCprIdx();
						cpr.makeCprCol(nodeType, index);
					}}
				>
					<ArrowBack />
				</IconButton>
				<span style={{ width: 100 }} className="text-center text-body-color">
					{cprNodeName}
				</span>
				<IconButton
					type="button"
					onClick={() => {
						const index = cprCol.getCprIdx();
						cpr.makeCprCol(nodeType, index + 1);
					}}
				>
					<ArrowForward />
				</IconButton>
			</MenuItem>
		);
	};

	const decisionTypesLeftToAdd = DecisionTypes.filter(
		(decisionType) => !cprColumnNodeTypes.includes(decisionType)
	).map(renderNodeType);

	const randomTypesLeftToAdd = RandomDecisionTypes.filter(
		(randomType) => !cprColumnNodeTypes.includes(randomType)
	).map(renderNodeType);

	const actionTypesLeftToAdd = ActionTypes.filter(
		(actionType) => !cprColumnNodeTypes.includes(actionType)
	).map(renderNodeType);

	return [
		...decisionTypesLeftToAdd,
		...randomTypesLeftToAdd,
		...actionTypesLeftToAdd,
		<MenuItem
			key="delete"
			onClick={() => cpr.deleteCprCol(cprCol.cprNodeTypeId)}
		>
			<i className="fas fa-trash-alt me-2" />
			Delete Column
		</MenuItem>
	];
};
