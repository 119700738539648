import {
	$SubscriptionScheduleProfileResponse,
	SubscriptionScheduleCreateRequest,
	SubscriptionScheduleProfileResponse,
	TimezoneIndexResponse
} from 'RtModels';
import { SubscriptionScheduleHttp } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionScheduleHttp';
import { TimezoneSelect } from 'RtUi/app/rtCommon/Timezones/lib/controls/TimezoneSelect';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { RtError } from 'RtUi/utils/errors/RtError';
import { startOfDay } from 'date-fns';
import { cloneDeep, isEqual } from 'lodash-es';
import * as moment from 'moment';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';

interface ISubscriptionScheduleFormEditorProps {
	narrow?: boolean;
	profile?: SubscriptionScheduleProfileResponse;
	subscriptionId?: number;
	onSuccess?: (profile: SubscriptionScheduleProfileResponse) => void;
}

interface ISubscriptionScheduleFormEditorState {
	schedule: SubscriptionScheduleCreateRequest;
	displayMode: boolean;
	isSubmitting: boolean;
	timeZone?: TimezoneIndexResponse;
	startDate: moment.Moment | undefined;
	endDate: moment.Moment | undefined;
	startSecond: moment.Moment;
	endSecond: moment.Moment;
	error?: any;
}

export class SubscriptionScheduleFormEditor extends React.Component<
	ISubscriptionScheduleFormEditorProps,
	ISubscriptionScheduleFormEditorState
> {
	public readonly initialState = {
		displayMode: false,
		isSubmitting: false,
		timeZone: undefined,
		startDate: undefined,
		endDate: undefined,
		startSecond: moment().startOf('day'),
		endSecond: moment().year(2099).endOf('day'),
		schedule: {
			subscriptionId: this.props.subscriptionId!,
			timezoneId: -1,
			isSunday: 0,
			isMonday: 0,
			isTuesday: 0,
			isWednesday: 0,
			isThursday: 0,
			isFriday: 0,
			isSaturday: 0,
			startDate: startOfDay(new Date()),
			endDate: null,
			startSecond: 0,
			endSecond: 0
		}
	};

	public state: ISubscriptionScheduleFormEditorState = {
		...this.initialState
	};

	public componentDidMount() {
		this.loadProfileOrDefaultValues();
	}

	public componentDidUpdate(prevProps: ISubscriptionScheduleFormEditorProps) {
		if (!isEqual(prevProps.profile, this.props.profile)) {
			this.loadProfileOrDefaultValues();
		}
	}

	public atLeastOneDateSelected() {
		return (
			this.state.schedule.isSunday !== 0 ||
			this.state.schedule.isMonday !== 0 ||
			this.state.schedule.isTuesday !== 0 ||
			this.state.schedule.isWednesday !== 0 ||
			this.state.schedule.isThursday !== 0 ||
			this.state.schedule.isFriday !== 0 ||
			this.state.schedule.isSaturday !== 0
		);
	}

	private onChange = <K extends keyof SubscriptionScheduleCreateRequest>(
		key: K,
		value: SubscriptionScheduleCreateRequest[K]
	) => {
		if (!this.state.isSubmitting) {
			const { schedule } = this.state;
			schedule[key] = value;
			this.setState({ schedule });
		}
	};

	private getUpdatedResource() {
		const { timeZone, startDate, endDate, startSecond, endSecond } = this.state;
		return {
			...this.state.schedule,
			timezoneId: timeZone?.timezoneId!,
			startDate: startDate?.toDate()!,
			endDate: endDate?.toDate()!,
			startSecond: moment.duration(startSecond.format('HH:mm:ss')).asSeconds(),
			endSecond: moment.duration(endSecond.format('HH:mm:ss')).asSeconds()
		};
	}

	private createOrUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const httpResource = new SubscriptionScheduleHttp();
		let newProfile: SubscriptionScheduleProfileResponse;

		this.setState({ isSubmitting: true, error: undefined });
		const { onSuccess = () => ({}) } = this.props;

		try {
			if (!this.atLeastOneDateSelected()) {
				throw new RtError({
					message: 'Please select one day or more and submit again'
				});
			}

			if (this.props.profile) {
				newProfile = await httpResource.update(
					this.props.profile.subscriptionScheduleId,
					this.getUpdatedResource()
				);
				this.setState({ displayMode: true });
			} else {
				newProfile = await httpResource.create(this.getUpdatedResource());
			}
			this.setState({ displayMode: true });
			onSuccess(newProfile);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	};

	private loadProfileOrDefaultValues() {
		const props = this.props;
		this.setState(cloneDeep(this.initialState));

		let schedule: SubscriptionScheduleCreateRequest = this.state.schedule;
		let startSecond = this.state.startSecond;
		let endSecond = this.state.endSecond;

		if (props.profile) {
			schedule = cloneDeep(props.profile);
			const tsMomentStart = moment.duration(schedule.startSecond, 'seconds');
			const tsMomentEnd = moment.duration(schedule.endSecond, 'seconds');

			startSecond = moment()
				.hours(tsMomentStart.hours())
				.minutes(tsMomentEnd.minutes())
				.seconds(tsMomentStart.seconds());

			endSecond = moment()
				.hours(tsMomentEnd.hours())
				.minutes(tsMomentEnd.minutes())
				.seconds(tsMomentEnd.seconds());
		}

		this.setState({
			schedule,
			startDate: moment(schedule.startDate).isValid()
				? moment(schedule.startDate)
				: undefined,
			endDate: moment(schedule.endDate).isValid()
				? moment(schedule.endDate)
				: undefined,
			startSecond,
			endSecond
		});
	}

	public render() {
		const { schedule } = this.state;
		return (
			<RtForm
				createMode
				noValidate
				narrow={this.props.narrow}
				onSubmit={this.createOrUpdate}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onCancel={() => {
					this.setState({ ...cloneDeep(this.initialState) });
				}}
			>
				<TimezoneSelect
					required={
						$SubscriptionScheduleProfileResponse.properties.timezoneId
							.isRequired
					}
					label="Time Zone"
					displayMode={this.state.displayMode}
					onChange={(timeZone: TimezoneIndexResponse) =>
						this.setState({ timeZone })
					}
					value={this.state.timeZone}
					initialOptionId={
						schedule.timezoneId && schedule.timezoneId !== -1
							? String(schedule.timezoneId)
							: '142'
					}
				/>
				<DatetimeFormControl
					showDateOnly
					appendToBody
					label="Start Date"
					required
					displayMode={this.state.displayMode}
					onChange={(startDate) => this.setState({ startDate })}
					value={this.state.startDate}
				/>
				<DatetimeFormControl
					showDateOnly
					label="End Date"
					appendToBody
					minDate={this.state.startDate}
					displayMode={this.state.displayMode}
					onChange={(endDate) => this.setState({ endDate })}
					value={this.state.endDate}
				/>
				<section className="rdtDateTimeRangeControl">
					<Form.Label>Start Time</Form.Label>
					<Form.Group className="rdtDateTimeRangePresets mb-3">
						<div className="d-lg-flex flat-picker-wrapper w-100">
							<Flatpickr
								options={{
									enableTime: true,
									noCalendar: true,
									enableSeconds: true,
									// eslint-disable-next-line camelcase
									time_24hr: true
								}}
								value={this.state.startSecond.format('HH:mm:ss')}
								className="w-100"
								onChange={(startSecond) => {
									this.setState({ startSecond: moment(startSecond[0]) });
								}}
							/>
						</div>
					</Form.Group>
				</section>
				<section className="rdtDateTimeRangeControl">
					<Form.Label>End Time</Form.Label>
					<Form.Group className="rdtDateTimeRangePresets mb-3">
						<div className="d-lg-flex flat-picker-wrapper w-100">
							<Flatpickr
								options={{
									enableSeconds: true,
									enableTime: true,
									noCalendar: true,
									// eslint-disable-next-line camelcase
									time_24hr: true
								}}
								value={this.state.endSecond.format('HH:mm:ss')}
								className="w-100"
								onChange={(endSecond) => {
									this.setState({ endSecond: moment(endSecond[0]) });
								}}
							/>
						</div>
					</Form.Group>
				</section>
				<Form.Group className="mb-3">
					<CheckboxFormControl
						id="is-sunday"
						displayMode={this.state.displayMode}
						label="Sunday"
						onChange={(value) => {
							this.onChange('isSunday', value ? 1 : 0);
						}}
						value={Boolean(schedule.isSunday)}
					/>
					<CheckboxFormControl
						id="is-monday"
						displayMode={this.state.displayMode}
						label="Monday"
						onChange={(value) => this.onChange('isMonday', value ? 1 : 0)}
						value={Boolean(schedule.isMonday)}
					/>
					<CheckboxFormControl
						id="is-tuesday"
						displayMode={this.state.displayMode}
						label="Tuesday"
						onChange={(value) => this.onChange('isTuesday', value ? 1 : 0)}
						value={Boolean(schedule.isTuesday)}
					/>
					<CheckboxFormControl
						id="id-wednesday"
						displayMode={this.state.displayMode}
						label="Wednesday"
						onChange={(value) => this.onChange('isWednesday', value ? 1 : 0)}
						value={Boolean(schedule.isWednesday)}
					/>
					<CheckboxFormControl
						id="is-thursday"
						displayMode={this.state.displayMode}
						label="Thursday"
						onChange={(value) => this.onChange('isThursday', value ? 1 : 0)}
						value={Boolean(schedule.isThursday)}
					/>
					<CheckboxFormControl
						id="is-friday"
						displayMode={this.state.displayMode}
						label="Friday"
						onChange={(value) => this.onChange('isFriday', value ? 1 : 0)}
						value={Boolean(schedule.isFriday)}
					/>
					<CheckboxFormControl
						id="is-saturday"
						displayMode={this.state.displayMode}
						label="Saturday"
						onChange={(value) => this.onChange('isSaturday', value ? 1 : 0)}
						value={Boolean(schedule.isSaturday)}
					/>
				</Form.Group>
			</RtForm>
		);
	}
}
