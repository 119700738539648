import {
	GuardianServiceNumberSubscriptionIndexResponse,
	GuardianServiceNumberSubscriptionUpdateRequestBulk,
	PortingStatusIndexResponse
} from 'RtModels';
import { PortingStatusSelect } from 'RtUi/app/rtCommon/ServiceNumbers/lib/controls/PortingStatusSelect';
import { GuardianServiceNumberSubscriptionBulkCancelGrid } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/grids/GuardianServiceNumberSubscriptionBulkCancelGrid';
import { GuardianServiceNumberSubscriptionResource } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/resources/GuardianServiceNumberSubscriptionResource';
import { convertDateToUtc } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { FormWizard, FormWizardStep } from 'RtUi/components/form/FormWizard';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtError } from 'RtUi/utils/errors/RtError';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';
import moment from 'moment';
import { FC, useCallback, useState } from 'react';
import { Alert, Button, Card, Col, Modal, Row, Table } from 'react-bootstrap';
import { useToggle } from 'react-use';

type GuardianServiceNumberSubscriptionBulkCancelProps = {
	onUpload?: () => void;
};

export const GuardianServiceNumberSubscriptionBulkCancel: FC<
	React.PropsWithChildren<GuardianServiceNumberSubscriptionBulkCancelProps>
> = ({ onUpload = () => null }) => {
	const [error, setError] = useState<any>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [wizardStep, setWizardStep] = useState<number>(1);
	const [isSamplesModalOpen, toggleIsSamplesModalOpen] = useToggle(false);
	const [isFileProcessing, setIsFileProcessing] = useState<boolean>(false);
	const [file, setFile] = useState<File>();
	const [endTs, setEndTs] = useState<moment.Moment>(
		moment(new Date()).utc().endOf('day')
	);
	const [custOrderNumber, setCustOrderNumber] = useState<string>();
	const [serviceNumbers, setServiceNumbers] = useState<
		GuardianServiceNumberSubscriptionIndexResponse[]
	>([]);
	const [portingStatus, setPortingStatus] =
		useState<PortingStatusIndexResponse>();

	const processFile = useCallback(
		async (file: File) => {
			const fileUtils = new FileUtils();
			const phoneNumberFormatter = new PhoneNumberFormatter();

			try {
				setIsFileProcessing(true);
				setError(undefined);

				const spreadsheet = await fileUtils.fileToSpreadsheet(file);
				const foundServiceNumbers: string[] = [];

				for (const row of spreadsheet) {
					for (const cell of row) {
						const serviceNumber = cell.replace(/\D/g, '');

						if (phoneNumberFormatter.isValid(serviceNumber, true)) {
							foundServiceNumbers.push(serviceNumber);
						}
					}
				}

				if (foundServiceNumbers.length <= 0) {
					throw new RtError({
						message:
							'Unable to find any numbers in spreadsheet that are in the system'
					});
				}

				const resource = new GuardianServiceNumberSubscriptionResource();
				const vueServiceNumbers = await resource.bulkGet({
					serviceNumbers: foundServiceNumbers
				});

				if (vueServiceNumbers.length <= 0) {
					throw new RtError({
						message:
							'Unable to find any numbers in spreadsheet that are in the system'
					});
				}

				for (const vueServiceNumber of vueServiceNumbers) {
					vueServiceNumber.endTs = endTs.toDate();
					vueServiceNumber.isActive = 0;
				}

				setFile(file);
				setServiceNumbers(vueServiceNumbers);
				setIsFileProcessing(false);
			} catch (error) {
				setError(error);
				setIsFileProcessing(false);
			}
		},
		[endTs]
	);

	const setEndTsAndUpdate = async (newEndTs: moment.Moment) => {
		const newEndDate = newEndTs.toDate();

		for (const vueServiceNumber of serviceNumbers) {
			vueServiceNumber.endTs = newEndDate;
		}

		setError(undefined);
		setEndTs(newEndTs);
	};

	const onSubmit = async () => {
		setIsSubmitting(true);
		setError(undefined);

		try {
			const endTsDate = convertDateToUtc(endTs.toDate());
			const guardianServiceNumberSubscriptionIds = serviceNumbers.map(
				({ guardianServiceNumberSubscriptionId }) =>
					guardianServiceNumberSubscriptionId
			);

			const errorMessages: string[] = [];
			for (const record of serviceNumbers) {
				const startTs = moment.utc(record.startTs);

				if (startTs.isAfter(endTs)) {
					errorMessages.push(
						`${record.serviceNumber} start date is after it's end date`
					);
				}
			}

			if (errorMessages.length > 0) {
				throw new RtError({
					messages: errorMessages
				});
			}

			const req: GuardianServiceNumberSubscriptionUpdateRequestBulk = {
				guardianServiceNumberSubscriptionIds,
				endTs: endTsDate,
				isActive: 0,
				decommissionedTs: endTsDate
			};

			if (typeof portingStatus !== 'undefined') {
				req.portingStatus = portingStatus.portingStatusId;
			}

			if (custOrderNumber) {
				req.custOrderNumber = custOrderNumber;
			}

			const resource = new GuardianServiceNumberSubscriptionResource();
			await resource.bulkUpdate(req);

			setFile(undefined);

			onUpload();
		} catch (error) {
			setError(error);
			setIsSubmitting(false);
		}
	};

	return (
		<Row>
			<Col lg={10}>
				<Card>
					<FormWizard
						error={error}
						isSubmitting={isSubmitting}
						currentStep={wizardStep}
						onChange={setWizardStep}
					>
						<FormWizardStep step={1} header="Upload">
							<Row>
								<Col lg={6}>
									<DatetimeFormControl
										label="End Date (UTC)"
										displayMode={false}
										value={endTs}
										required
										onChange={setEndTsAndUpdate}
									/>
								</Col>
								<Col lg={6}>
									<PortingStatusSelect
										subLabel="(optional)"
										onChange={setPortingStatus}
										value={portingStatus}
										initialOptionId={'3' /* Port Out */}
									/>
								</Col>
								<Col lg={6}>
									<InputFormControl
										label="Customer Order Number"
										subLabel="(optional)"
										onChange={setCustOrderNumber}
										value={custOrderNumber}
									/>
								</Col>
							</Row>
							<div className="text-end">
								<Button variant="link" onClick={toggleIsSamplesModalOpen}>
									<span className="text-muted">
										<i className="fas fa-fw fa-info-circle" />
										&nbsp;
										<u>Example File</u>
									</span>
								</Button>
							</div>
							<DragAndDropFileUploader
								required
								accept={FileUtils.AcceptTypes.Spreadsheet}
								processing={isFileProcessing}
								value={file}
								onDrop={processFile}
							/>
						</FormWizardStep>
						<FormWizardStep
							step={2}
							header="Review & Submit"
							disableBody
							onSubmit={onSubmit}
						>
							<Alert variant="info rounded-0 d-flex justify-content-start mb-0">
								<i className="fas fa-fw fa-info-circle me-2" />
								<article>
									Review the data below before submitting. Upload may take
									several minutes depending on the size of the submission.
								</article>
							</Alert>
							<GuardianServiceNumberSubscriptionBulkCancelGrid
								serviceNumbers={serviceNumbers}
								portingStatus={portingStatus}
								custOrderNumber={custOrderNumber}
							/>
						</FormWizardStep>
					</FormWizard>
				</Card>
				<Modal show={isSamplesModalOpen} onHide={toggleIsSamplesModalOpen}>
					<Modal.Body className="bg-light rounded">
						<article className="d-flex justify-content-end mb-2">
							<Button
								variant="white-alt"
								onClick={() => toggleIsSamplesModalOpen(false)}
							>
								<i className="fas fa-times fa-fw fa-lg" />
							</Button>
						</article>
						<Table size="sm" bordered className="bg-white" responsive>
							<thead>
								<tr>
									<th>Service Number</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="text-monospace">123456789</td>
								</tr>
								<tr>
									<td className="text-monospace">8008002244</td>
								</tr>
							</tbody>
						</Table>
					</Modal.Body>
				</Modal>
			</Col>
		</Row>
	);
};
