import { Navbar, Dropdown, ListGroup } from 'react-bootstrap';
import { FirebaseMessageType, FirebaseMessageSeverity } from 'RtModels';
import { DashboardStatus } from 'RtUi/app/rtCommon/DashboardOld/lib/components/DashboardStatus';
import { TaskRouter } from 'RtUi/app/rt800/Tasks/Task.router';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { Aside } from 'RtUi/components/ui/Aside';
import { FirebaseUser } from 'RtUi/state/actions/user/FirebaseUser';
import { GlobalSearch } from './GlobalSearch';
import { NavCreateDropdown } from './NavCreateDropdown';
import {
	FireBaseUserMessageListener,
	IFirebaseUIMessage
} from 'RtUi/state/actions/user/BaseFireBase';
import {
	NotificationGroupItem,
	SeverityIconsMap
} from 'RtUi/app/ApplicationShell/lib/components/NotificationGroupItem';
import { Logo } from 'RtUi/app/public/lib/components/Logo';
import { ComponentController } from 'RtUi/components/ComponentController';
import { MyProfileRouter } from 'RtUi/app/user/MyProfile/MyProfile.router';
import { MouseEvent } from 'react';
import { NavigationHistory } from 'RtUi/app/ApplicationShell/lib/components/NavigationHistory';
import { IRouteMenu } from 'RtUi/app/ApplicationShell/lib/interfaces';

interface ITopNavigationProps {
	routesConfig: IRouteMenu[];
}

interface ITopNavigationState {
	isCreateDropdownOpen: boolean;
	firebaseMessages: IFirebaseUIMessage[];
	bannerAsideIsOpen: boolean;
}

export class TopNavigation extends ApplicationContainer<
	ITopNavigationProps,
	ITopNavigationState
> {
	public state: ITopNavigationState = {
		isCreateDropdownOpen: false,
		firebaseMessages: [],
		bannerAsideIsOpen: false
	};

	public removeFirebaseListenerFn: () => void = () => ({});

	public componentDidMount() {
		const firebaseUser = FirebaseUser.getInstance();

		this.removeFirebaseListenerFn = firebaseUser.onMessages(
			this.onFirebaseMessage,
			[FirebaseMessageType.BannerAlert]
		);
	}

	public componentWillUnmount() {
		this.removeFirebaseListenerFn();
	}

	public goToAlert(message: IFirebaseUIMessage) {
		const profilePath =
			ComponentController.getInstance().getProfilePathFromFirebaseMessage(
				message
			);

		if (!profilePath) {
			return;
		}

		this.goToPath(profilePath);
	}

	public onFirebaseMessage: FireBaseUserMessageListener = (
		firebaseMessages
	) => {
		this.setState({ firebaseMessages });
	};

	public goToNewAction(actionTabLabel: string) {
		const taskActionPath = TaskRouter.getIndexRoute(true, actionTabLabel);

		this.setState({ isCreateDropdownOpen: false });

		this.goToPath(taskActionPath);
	}

	public render() {
		const { firebaseMessages } = this.state;

		return (
			<>
				<div className="bg-brand-gradient">
					<div className="header-container">
						<section className="top-nav">
							<article className="d-flex justify-content-start">
								<div className="d-flex justify-content-center align-items-center left-nav-width">
									<Logo height={24} />
								</div>
								<div className="d-flex justify-content-start align-items-center">
									<GlobalSearch />
									<NavCreateDropdown />
								</div>
							</article>
							<article className="top-nav-right">
								<DashboardStatus />
								<NavigationHistory routesConfig={this.props.routesConfig} />
								<Dropdown align="end">
									<Dropdown.Toggle bsPrefix="p-0">
										<i className="fas fa-fw fa-user" />
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item
											onClick={() =>
												BrowserHistory.push(MyProfileRouter.getIndexRoute())
											}
										>
											My Profile
										</Dropdown.Item>
										<Dropdown.Item onClick={() => this.logout()}>
											Logout
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</article>
						</section>
						<Aside
							disabledBody
							isOpen={this.state.bannerAsideIsOpen}
							onClickOutside={() => this.setState({ bannerAsideIsOpen: false })}
						>
							<Aside.Header
								header={
									<>
										<i className="fas fa-fw fa-exclamation-triangle text-danger" />
										<b>&nbsp;System Alerts</b>
									</>
								}
								onClose={() => this.setState({ bannerAsideIsOpen: false })}
							/>
							<ListGroup variant="flush">
								{firebaseMessages.map((firebaseMessage, index) => (
									<NotificationGroupItem
										key={firebaseMessage.summary + index}
										message={firebaseMessage}
										onClick={() => this.goToAlert(firebaseMessage)}
									/>
								))}
							</ListGroup>
						</Aside>
					</div>
				</div>
				{firebaseMessages.length > 0 && (
					<Navbar
						className={`rtNotification mb-0 d-flex justify-content-between 
					rtNotification--${FirebaseMessageSeverity[firebaseMessages[0].severity]}
					rtNotification--${FirebaseMessageSeverity[firebaseMessages[0].severity]}--fill`}
						onClick={(e: MouseEvent<HTMLElement>) => {
							// was causing <Aside> onClickOutside click trigger
							e.stopPropagation();
							this.setState({ bannerAsideIsOpen: true });
						}}
					>
						<div className="d-flex align-items-baseline">
							<h6 className="rtNotification__heading mb-0 pb-0 pe-1">
								<i
									className={`rtNotification__heading__icon fas fa-fw fa-sm ${SeverityIconsMap.get(
										firebaseMessages[0].severity
									)}`}
								/>
								<b>{firebaseMessages[0].label}</b>
							</h6>
							<ListGroup.Item
								bsPrefix="list-group-item-text"
								className="mb-0 rtNotification__text"
							>
								{firebaseMessages[0].summary}
							</ListGroup.Item>
						</div>

						{firebaseMessages.length > 1 && (
							<a>
								<i className="fas fa-fw fa-exclamation-circle me-1" />
								<b className="me-2">
									{firebaseMessages.length.toLocaleString()} System Alerts!
								</b>
							</a>
						)}
					</Navbar>
				)}
			</>
		);
	}
}
