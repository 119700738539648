/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationResourceUrlRequest = {
    properties: {
        integrationId: {
            type: 'string',
            isRequired: true,
        },
        resourceId: {
            type: 'string',
            isRequired: true,
        },
    },
};