import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { IntegrationsApiRoutes } from 'RtExports/routes';
import {
	ResourceConfigurationIndexResponse,
	ResourceConfigurationUpdateRequest,
	ResourceConfigurationCreateRequest,
	ResourceConfigurationProfileResponse,
	ResourceConfigurationProfileUrlRequest
} from 'RtModels';

export class ResourceConfigurationResource extends HttpResource<
	ResourceConfigurationIndexResponse,
	ResourceConfigurationProfileResponse
> {
	constructor() {
		super(IntegrationsApiRoutes.ResourceConfigurations);
	}

	public create(createReq: ResourceConfigurationCreateRequest) {
		const body = JSON.stringify(createReq);

		return this.fetchWithRoute<ResourceConfigurationIndexResponse>(
			IntegrationsApiRoutes.ResourceConfigurations.Create,
			{ body }
		);
	}

	public update(
		resourceConfigurationId: number,
		updateReq: ResourceConfigurationUpdateRequest
	) {
		const body = JSON.stringify(updateReq);
		const urlParams: ResourceConfigurationProfileUrlRequest = {
			resourceConfigurationId
		};

		return this.fetchWithRoute<ResourceConfigurationIndexResponse>(
			IntegrationsApiRoutes.ResourceConfigurations.Update,
			{ body, urlParams }
		);
	}

	public clone(resourceId: number) {
		const urlParams: Partial<ResourceConfigurationCreateRequest> = {
			resourceId
		};

		return this.fetchWithRoute<ResourceConfigurationIndexResponse>(
			IntegrationsApiRoutes.ResourceConfigurations.Create,
			{ urlParams }
		);
	}
}
