/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ResourceIndexResponse = {
    properties: {
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        resRef1: {
            type: 'string',
            isRequired: true,
        },
        resRef2: {
            type: 'string',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        lastSyncTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        nextSyncTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        lastApiAttemptTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        lastApiSuccessTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        resourceTypeId: {
            type: 'number',
            isRequired: true,
        },
        resourceTypeLabel: {
            type: 'string',
            isRequired: true,
        },
        integrationId: {
            type: 'number',
            isRequired: true,
        },
        integrationTypeId: {
            type: 'number',
            isRequired: true,
        },
        integrationLabel: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        isDefault: {
            type: 'number',
        },
    },
};