import { EntityIndexResponse } from 'RtModels';
import { EntityResource } from 'RtUi/app/rt800/Entities/lib/resources/EntityResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface EntityDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<EntityIndexResponse, T> {}

export const EntityDataGridCache = new ResourceCacheMap<EntityIndexResponse>(
	new EntityResource(),
	'entityId',
	'label'
);

export const EntityDataGridColumn = <T = any,>(
	config: EntityDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: EntityDataGridCache,
		...config
	});
};
