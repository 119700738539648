import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

export class PortViewRouterClass extends RtVueReportRouter<void, void, {}> {
	constructor() {
		super('Port Utilization', 'port', undefined, {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewPorts);
	}

	/**
	 * By pass pluralization of name
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const PortViewRouter = new PortViewRouterClass();
