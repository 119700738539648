/* eslint-disable camelcase */
import { PaginationState } from '@tanstack/react-table';
import {
	UserPageConfiguration,
	UserPageConfigurationParameters,
	UserPageConfigurationParametersSortingOrder
} from 'RtModels';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import {
	MRT_Column,
	MRT_Row,
	MRT_TableInstance,
	MRT_Header,
	MRT_Cell,
	MRT_ColumnDef,
	MRT_RowData
} from 'material-react-table';
import { ReactNode } from 'react';

export interface DataGridColumnInstance<T>
	extends MRT_Column<Record<keyof T, T[keyof T]>> {}

export interface DataGridRowInstance<T>
	extends MRT_Row<Record<keyof T, T[keyof T]>> {}

export interface DataGridTableInstance<T>
	extends MRT_TableInstance<Record<keyof T, T[keyof T]>> {}

export interface FooterProps<T = any> {
	table: DataGridTableInstance<T>;
	footer: MRT_Header<Record<keyof T, T[keyof T]>>;
	column: DataGridColumnInstance<T>;
}

export interface HeaderProps<T = any> {
	table: DataGridTableInstance<T>;
	header: MRT_Header<Record<keyof T, T[keyof T]>>;
	column: DataGridColumnInstance<T>;
}

export interface CellProps<T = any> {
	cell: MRT_Cell<Record<keyof T, T[keyof T]>>;
	renderedCellValue: number | string | ReactNode;
	column: DataGridColumnInstance<T>;
	row: MRT_Row<Record<keyof T, T[keyof T]>>;
	table: DataGridTableInstance<T>;
}

export type DataGridColumnDef<T> = Omit<
	MRT_ColumnDef<Record<keyof T, T[keyof T]>>,
	'accessorKey' | 'header' | 'columns'
> & {
	accessorKey?: keyof T;
	header: string;
	exportDateFormat?: string | ((data: T[]) => string);
	exportDateAsUtc?: boolean;
	exportValue?: (value: any, row?: T) => string | number | Date;
	globalFilterFn?: (value: any, filterValue: string) => boolean;
	isHidden?: boolean;
	isInvisible?: boolean;
	hiddenIfEmpty?: boolean;
	isMulti?: boolean;
};

export interface DataGridColumn<T> extends DataGridColumnDef<T> {
	columns?: Array<DataGridColumnDef<T>>;
}

export interface DataGridUserPageConfiguration
	extends Pick<UserPageConfiguration, 'pageName'> {
	configurationParameters: UserPageConfigurationParameters;
}

export enum RowThemeColor {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	SUCCESS = 'success',
	DANGER = 'danger',
	WARNING = 'warning',
	INFO = 'info',
	LIGHT = 'light',
	DARK = 'dark'
}

export type RowSelectionProps<T = any> =
	| {
			enableRowSelection?: boolean | ((row: T) => boolean);
			selectedRows?: T[];
			onChangeSelectedRows?: (values: T[]) => void;
	  }
	| {
			enableRowSelection: boolean | ((row: T) => boolean);
			selectedRows: T[];
			onChangeSelectedRows: (values: T[]) => void;
	  };

export type ExternalExportProps<T = any> =
	| {
			enableExternalExport?: false;
			getExternalExportData?: () => Promise<T[]>;
	  }
	| {
			enableExternalExport: boolean;
			getExternalExportData: () => Promise<T[]>;
	  };

export type RowOrderingProps<T = any> =
	| {
			enableRowOrdering?: false;
			onRowDragEnd?: (
				draggingRow: DataGridRowInstance<T>,
				hoveredRow: DataGridRowInstance<T>
			) => void;
			getRowId?: (
				originalRow: MRT_RowData,
				index?: number,
				parent?: DataGridRowInstance<MRT_RowData>
			) => string;
	  }
	| {
			enableRowOrdering: boolean;
			onRowDragEnd: (
				draggingRow: DataGridRowInstance<T>,
				hoveredRow: DataGridRowInstance<T>
			) => void;
			getRowId: (
				originalRow: MRT_RowData,
				index?: number,
				parent?: DataGridRowInstance<MRT_RowData>
			) => string;
	  };

export type ColumnOrderingProps<T = any> =
	| {
			enableColumnDragging?: false;
			onColumnDragEnd?: (
				draggingColumn: DataGridColumnInstance<T>,
				hoveredColumn: DataGridColumnInstance<T>
			) => void;
	  }
	| {
			enableColumnDragging: boolean;
			onColumnDragEnd: (
				draggingColumn: DataGridColumnInstance<T>,
				hoveredColumn: DataGridColumnInstance<T>
			) => void;
	  };

type BasePaginationProps = {
	pagination: PaginationState;
	setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
};

export type PaginationProps =
	| BasePaginationProps
	| Partial<BasePaginationProps>;

export interface DataGridBaseProps<T = any> {
	data?: T[];
	disableExport?: boolean;
	disableControls?: boolean;
	columns: Array<DataGridColumn<T>>;
	className?: string;
	router?: RtUiRouter;
	disableExternalLinks?: boolean | RtUiRouter[];
	pageName: string;
	disableConfigurationStore?: boolean;
	onRowClick?: (row: T) => void;
	onRowEdit?: (data: T, changedField: string, newValue: T[keyof T]) => void;
	menuLinks?: (row: T) => JSX.Element | null;
	onClickCreate?: () => void;
	onClickRefetch?: () => void;
	totalRows?: number;
	inlineEdit?: boolean;
	enableHiding?: boolean;
	loading?: boolean;
	manualFilter?: string;
	hasManualFiltering?: boolean;
	setManualFilter?: (value: string) => void;
	headerAction?: (table: DataGridTableInstance<T>) => JSX.Element;
	footerAction?: () => JSX.Element;
	inlineActions?: (row: T) => JSX.Element | undefined;
	enableSorting?: boolean;
	defaultSorting?: UserPageConfigurationParametersSortingOrder[];
	onSortingChange?: (
		sorting: UserPageConfigurationParametersSortingOrder[]
	) => void;
	rowThemeColor?: (row: T) => RowThemeColor | undefined;
	detailPanel?: (row: T) => JSX.Element | null;
	customExportToCsv?: (table: DataGridTableInstance<T>) => Promise<void>;
}
