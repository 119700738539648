import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';

// exported definitions
// ======================================================================

export class CprLblDate extends CprLbl {

	public readonly cprNodeTypeId = CprNodeType.Date;

	public readonly valueLimit = 255;
	public readonly valueRegExp = /^(\d{2})\/(\d{2})|((\d{2})\/(\d{2})\-(\d{2})\/(\d{2}))$/;

	/*
		mm/dd (no year should be entered)
		range may be returned in the form: mm/dd-mm/dd
	*/

	public isPossibleValue(rawVal: string) {
		// only validating by valueRegExp
		return true;
	}

}
