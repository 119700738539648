import { cloneDeep } from 'lodash-es';
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { DropdownOnBody } from 'RtUi/components/ui/DropdownOnBody';
import { MultiDataList } from 'RtUi/components/ui/MultiDataList';
import { MonthDayFormControl } from '../MonthDayFormControl';

interface IMonthDayRangeFormControlProps {
	value: string[];
	onChange: (newValue: string[]) => void;
	className?: string;
	label?: string;
	displayMode?: boolean;
	useTextDisplayMode?: boolean;
	required?: boolean;
}

interface IMonthDayRangeFormControlState {
	isOpen: boolean;
}

export class MonthDayRangeFormControl extends React.Component<
	IMonthDayRangeFormControlProps,
	IMonthDayRangeFormControlState
> {
	public state: IMonthDayRangeFormControlState = {
		isOpen: false
	};

	public selectContainerRef: HTMLElement | null = null;

	public toggleIsOpen(isOpen = !this.state.isOpen) {
		this.setState({ isOpen });
	}

	public updateFrom(newFromMonthDay: string, index: number) {
		const newValue = this.props.value ? cloneDeep(this.props.value) : [];
		const valueToUpdate = newValue[index];
		const valueParts = valueToUpdate.split('-');

		valueParts[0] = newFromMonthDay;

		if (valueParts[0] === valueParts[1]) {
			newValue[index] = valueParts[1];
		} else {
			newValue[index] = valueParts.join('-');
		}

		this.props.onChange(newValue);
	}

	public updateTo(newToMonthDay: string, index: number) {
		const newValue = this.props.value ? cloneDeep(this.props.value) : [];
		const valueToUpdate = newValue[index];
		const valueParts = valueToUpdate.split('-');

		valueParts[1] = newToMonthDay;

		if (valueParts[0] === valueParts[1]) {
			newValue[index] = valueParts[1];
		} else {
			newValue[index] = valueParts.join('-');
		}

		this.props.onChange(newValue);
	}

	public deleteTimeRange(index: number) {
		const newValue = this.props.value ? cloneDeep(this.props.value) : [];

		if (index in newValue) {
			newValue.splice(index, 1);
		}

		this.props.onChange(newValue);
	}

	public addTimeRange() {
		let newValue = this.props.value ? cloneDeep(this.props.value) : [];

		//Remove OTHER values
		newValue = newValue.filter((val) => val !== 'OTHER');

		newValue.push('01/01-01/01');

		this.props.onChange(newValue);
	}

	public setValueToOther() {
		this.props.onChange(['OTHER']);
	}

	public render() {
		const { value = [] } = this.props;

		return (
			<>
				<MultiDataList
					displayMode={this.props.displayMode}
					values={value}
					disableNoSuggestionsPrompt
					className={this.props.className}
					suggestions={[]}
					ref={(selectContainerRef) =>
						(this.selectContainerRef = selectContainerRef)
					}
					onChange={(changedValue) => {
						this.props.onChange(changedValue);
					}}
					onFocus={(ev) => {
						ev.currentTarget.blur();
						this.toggleIsOpen(true);
					}}
				/>
				{this.selectContainerRef && (
					<DropdownOnBody
						toggle={() => this.toggleIsOpen()}
						isOpen={this.state.isOpen}
						selectDOM={this.selectContainerRef}
					>
						<section style={{ minWidth: 350 }}>
							{value.map((monthDayRange, index) => {
								if (monthDayRange === 'OTHER') {
									return null;
								}

								const dayMonthParts = monthDayRange.split('-');
								const firstMonthDay = dayMonthParts[0];
								const secondMonthDay = dayMonthParts[1] ?? dayMonthParts[0];

								return (
									<Form.Group
										key={index}
										className="d-md-flex justify-content-between align-items-center mb-3"
									>
										<article className="me-2 flex-fill">
											<MonthDayFormControl
												useControlGroup={false}
												onChange={(newDayOfWeek) =>
													this.updateFrom(newDayOfWeek, index)
												}
												value={firstMonthDay}
											/>
										</article>
										<article className="me-2">
											<i className="fas fa-fw fa-arrow-right" />
										</article>
										<article className="me-2 flex-fill">
											<MonthDayFormControl
												useControlGroup={false}
												onChange={(newDayOfWeek) =>
													this.updateTo(newDayOfWeek, index)
												}
												value={secondMonthDay}
											/>
										</article>
										<article>
											<Button
												variant="light"
												onClick={() => this.deleteTimeRange(index)}
											>
												<i className="fas fa-fw fa-trash-alt" />
											</Button>
										</article>
									</Form.Group>
								);
							})}
							<article>
								<Button variant="light" onClick={() => this.addTimeRange()}>
									<i className="fas fa-fw fa-plus" />
									<span>&nbsp;Add Month/Day Range</span>
								</Button>
								<Button
									variant="light"
									className="ms-2"
									onClick={() => this.setValueToOther()}
									disabled={value[0] === 'OTHER'}
								>
									<i className="fas fa-fw fa-plus" />
									<span>&nbsp;Set to OTHER</span>
								</Button>
							</article>
						</section>
					</DropdownOnBody>
				)}
			</>
		);
	}
}
