import { Row, Col } from 'react-bootstrap';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { RespOrgRouter } from '../../RespOrg.router';
import { RespOrgResource } from '../resources/RespOrgResource';
import { RespOrgUpdateRequest, RespOrgProfileResponse } from 'RtModels';
import { RtError } from 'RtUi/utils/errors/RtError';
import { Component } from 'react';

interface IRespOrgEditorProps {
	editMode?: RespOrgProfileResponse;
	createMode?: boolean;
	onCreateSuccess?: (res: RespOrgProfileResponse) => void;
	onUpdateSuccess?: (res: RespOrgProfileResponse) => void;
}

interface IRespOrgEditorState extends RespOrgUpdateRequest {
	summary: RespOrgProfileResponse['summary'];
	contactName: RespOrgProfileResponse['contactName'];
	contactNumber: RespOrgProfileResponse['contactNumber'];
	displayMode: boolean;
	isSubmitting: boolean;
	canManage: boolean;
	error?: any;
}

export class RespOrgEditor extends Component<
	IRespOrgEditorProps,
	IRespOrgEditorState
> {
	public state: IRespOrgEditorState = {
		label: '',
		contactName: '',
		contactNumber: '',
		isActive: 0,
		summary: '',
		displayMode: true,
		isSubmitting: false,
		canManage: false,
		error: undefined
	};

	public respOrgsRouter = new RespOrgResource();

	public componentDidMount() {
		const { createMode = false } = this.props;
		const displayMode = !createMode;

		this.setState({ displayMode });

		this.loadEditProfileIfExists();
	}

	public loadEditProfileIfExists(props = this.props) {
		const { editMode } = props;

		if (editMode) {
			const canManage = RespOrgRouter.canManageWith(editMode);
			const { label, isActive, summary, contactName, contactNumber } = editMode;

			this.setState({
				label,
				isActive,
				summary,
				contactName,
				contactNumber,
				canManage
			});
		}
	}

	public submit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		const {
			createMode = false,
			onUpdateSuccess = () => ({}),
			editMode
		} = this.props;

		if (createMode) {
			//todo
		} else if (editMode) {
			const { label, isActive } = this.state;
			const { respOrgId } = editMode;

			this.setState({ isSubmitting: true, error: undefined });

			this.respOrgsRouter
				.update(respOrgId, label, isActive)
				.then((RespOrgProfile) => {
					onUpdateSuccess(RespOrgProfile);
					this.setState({ displayMode: true });
				})
				.catch((error: RtError) => {
					this.setState({ error });
				})
				.finally(() => this.setState({ isSubmitting: false }));
		}
	}

	public render() {
		const { displayMode, isSubmitting, canManage } = this.state;
		const { createMode = false } = this.props;

		return (
			<RtUiForm
				hideButtons={!canManage}
				createMode={createMode}
				onChange={(displayMode) => this.setState({ displayMode })}
				displayMode={displayMode}
				isSubmitting={isSubmitting}
				onSubmit={(e) => this.submit(e)}
			>
				<Row>
					<Col lg>
						<InputFormControl
							label="Label"
							displayMode={displayMode}
							onChange={(label) => this.setState({ label })}
							value={this.state.label}
						/>
					</Col>
					<Col lg>
						<InputFormControl
							label="Summary"
							displayMode
							onChange={(summary) => this.setState({ summary })}
							value={this.state.summary}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg>
						<InputFormControl
							label="Contact Name"
							displayMode
							value={this.state.contactName}
						/>
					</Col>
					<Col lg>
						<InputFormControl
							label="Contact Number"
							type="tel"
							displayMode
							value={this.state.contactNumber}
						/>
					</Col>
				</Row>
				<IsActiveRadioFormControl
					label="Status"
					displayMode={displayMode}
					onChange={(isActive) => this.setState({ isActive })}
					hideBothOption
					value={this.state.isActive}
				/>
			</RtUiForm>
		);
	}
}
