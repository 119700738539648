/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum SipAgentContactUriFormat {
    Custom = 1,
    LocalGw = 10,
    RemoteGw = 20,
    VendorGw = 30,
}