import { IntegrationsApiRoutes } from 'RtExports/routes';
import {
	ResourceConfigurationIndexResponse,
	ResourceConfigurationProfileResponse
} from 'RtModels';
import { RtCarrierConnectModuleRouter } from '../RtCarrierConnect.router';
import {
	IResourceConfigurationProfileContainerTabs,
	ResourceConfigurationProfileContainerTabs
} from 'RtUi/app/rtCommon/ResourceConfigurations/lib/components/ResourceConfigurationsProfileContainer';

export class CarrierConnectResourceConfigurationRouterClass extends RtCarrierConnectModuleRouter<
	ResourceConfigurationIndexResponse,
	ResourceConfigurationProfileResponse,
	IResourceConfigurationProfileContainerTabs
> {
	constructor() {
		super(
			'Resource',
			'resourceConfigurations',
			'resourceConfigurationId',
			ResourceConfigurationProfileContainerTabs(false)
		);

		this.setPermissionsFromApiRoute(IntegrationsApiRoutes.Resources);
	}

	public getIndexLabel(record: ResourceConfigurationIndexResponse) {
		return record.label;
	}

	public getProfileLabel(profile: ResourceConfigurationProfileResponse) {
		return profile.label;
	}
}

export const CarrierConnectResourceConfigurationRouter =
	new CarrierConnectResourceConfigurationRouterClass();
