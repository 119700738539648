import { CdrExportIntervalIndexResponse } from 'RtModels';
import { CdrExportIntervalResource } from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportIntervalResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface CdrExportIntervalDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		CdrExportIntervalIndexResponse,
		T
	> {
	header?: string;
}

export const CdrExportIntervalRenderCache =
	new ResourceCacheMap<CdrExportIntervalIndexResponse>(
		new CdrExportIntervalResource(),
		'intervalId',
		'intervalName'
	);

export const CdrExportIntervalDataGridColumn = <T = any,>({
	header = 'Cdr Export Interval',
	...config
}: CdrExportIntervalDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		header,
		resourceCacheMap: CdrExportIntervalRenderCache,
		...config
	});
};
