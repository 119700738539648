/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TransactionCreateRequest = {
    properties: {
        accountId: {
            type: 'number',
            isRequired: true,
        },
        amount: {
            type: 'number',
            isRequired: true,
        },
        transactionTypeId: {
            type: 'number',
            isRequired: true,
        },
        transactionTime: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        externalRef1: {
            type: 'string',
            isNullable: true,
        },
        externalRef2: {
            type: 'string',
            isNullable: true,
        },
        comments: {
            type: 'string',
            isNullable: true,
        },
    },
};