import { GuardianReconciliationAttachmentIndexResponse } from 'RtModels';
import { DownloadButton } from 'RtUi/app/rtVue/GuardianReconciliation/lib/controls/DownloadButton';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const guardianReconciliationAttachmentColumns = (
	removeEntity: (row: GuardianReconciliationAttachmentIndexResponse) => void,
	onClickDownload: (
		row: GuardianReconciliationAttachmentIndexResponse
	) => Promise<void>
): Array<DataGridColumn<GuardianReconciliationAttachmentIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: removeEntity
	}),
	DefaultDataGridColumn({
		accessorKey: 'fileName',
		header: 'File Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'createdByUserEmail',
		header: 'Uploaded By'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	DefaultDataGridColumn({
		header: 'Download',
		getValue: ({ row }) => (
			<DownloadButton
				downloadFn={() =>
					onClickDownload(
						row.original as GuardianReconciliationAttachmentIndexResponse
					)
				}
			/>
		)
	})
];
