import { ServiceNumberIndexResponse } from 'RtModels';

export const ServiceNumberPropertyToNameMap = new Map<
	keyof ServiceNumberIndexResponse,
	string
>([
	['serviceNumber', 'Service Number'],
	['label', 'Label'],
	['isActive', 'Active'],
	['subscriptionId', 'Subscription'],
	['scenarioId', 'Scenario'],
	['isE911', '911 Assistance'],
	['isDirAsst', 'Directory Listing'],
	['custExtRef1', 'Ext. Account'],
	['custExtRef2', 'Ext. SubAccount'],
	['custExtRef3', 'Ext. Customer End User'],
	['custOrderNumber', 'Ext. Order Number'],
	['updatedTs', 'Last Updated'],
	['translatedNumberStd', 'Translated Number Std'],
	['isPorted', 'Is Ported'],
	['portingStatusId', 'Porting Status'],
	['pin', 'Pin'],
	['notes', 'Notes'],
	['assignedTs', 'Assigned Date'],
	['portedTs', 'Ported Date'],
	['activatedTs', 'Activated Date'],
	['decommissionedTs', 'Decommissioned Date'],
	['cnamReg', 'Cnam Reg'],
	['cnamDip', 'Cnam Dip'],
	['dirAsstName', 'Dir Asst Name'],
	['dirAsstAddress1', 'Dir Asst Address 1'],
	['dirAsstAddress2', 'Dir Asst Address 2'],
	['dirAsstCity', 'Dir Asst City'],
	['dirAsstState', 'Dir Asst State'],
	['dirAsstZip', 'Dir Asst Zip'],
	['e911Name', 'E 911 Name'],
	['e911Address1', 'E 911 Address 1'],
	['e911Address2', 'E 911 Address 2'],
	['e911City', 'E 911 City'],
	['e911State', 'E 911 State'],
	['e911Zip', 'E 911 Zip'],
	['vendExtRef1', 'Vend Ext Ref 1'],
	['vendExtRef2', 'Vend Ext Ref 2'],
	['filename', 'Filename'],
	['createdTs', 'Created Date'],
	['deletedTs', 'Deleted Date']
]);
