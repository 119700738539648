import { RoutePlanIndexResponse } from 'RtModels';
import { RoutePlanResource } from 'RtUi/app/rtSip/RoutePlans/lib/resources/RoutePlanResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface RoutePlanDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		RoutePlanIndexResponse,
		T
	> {}

export const RoutePlanDataGridCache =
	new ResourceCacheMap<RoutePlanIndexResponse>(
		new RoutePlanResource(),
		'routePlanId',
		'label'
	);

export const RoutePlanDataGridColumn = <T = any,>(
	config: RoutePlanDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: RoutePlanDataGridCache,
		...config
	});
};
