import { DefaultSipAgentConfig } from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';

import { HttpRequest } from 'RtUi/utils/http/HttpRequest';

export class SipAgentDefaultConfigResource {
	public getProfile() {
		return HttpRequest.fetchWithRoute<DefaultSipAgentConfig>(
			RtCommonApiRoutes.SipAgentDefaultConfig.Profile
		);
	}
}
