import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	RoutingGroupCreateRequest,
	RoutingGroupIndexResponse,
	RoutingGroupProfileRequest,
	RoutingGroupProfileResponse,
	RoutingGroupTemplateCreateRequest,
	RoutingGroupTemplateIndexResponse,
	RoutingGroupTemplateProfileRequest,
	RoutingGroupUpdateRequest
} from 'RtModels';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class RoutingGroupResource extends HttpResource<
	RoutingGroupIndexResponse,
	RoutingGroupProfileResponse
> {
	constructor() {
		super(Rt800ApiRoutes.RoutingGroups);
	}

	/**
	 * Active new Template on Routing Group
	 * @param routingGroupId
	 * @param routingGroupTemplateId
	 */
	public activate(
		routingGroupId: number,
		routingGroupTemplateId: RoutingGroupTemplateProfileRequest['routingGroupTemplateId']
	) {
		const req: RoutingGroupTemplateProfileRequest = {
			routingGroupTemplateId
		};
		const body = JSON.stringify(req);
		const urlParams: RoutingGroupProfileRequest = { routingGroupId };

		return this.fetchWithRoute<ITaskProfile>(
			Rt800ApiRoutes.RoutingGroupTemplates.Update,
			{ body, urlParams }
		);
	}

	/**
	 * Create Routing Group
	 * @param label
	 * @param respOrgId
	 * @param templates
	 */
	public create(
		label: string,
		respOrgId: string,
		isActive: number,
		templates: RoutingGroupTemplateCreateRequest[]
	) {
		const request: RoutingGroupCreateRequest = {
			label,
			respOrgId,
			templates,
			isActive
		};
		const body = JSON.stringify(request);

		return this.fetchWithRoute<RoutingGroupTemplateIndexResponse>(
			Rt800ApiRoutes.RoutingGroups.Create,
			{ body }
		);
	}

	/**
	 * Update Routing Group
	 * @param label
	 * @param respOrgId
	 * @param templates
	 */
	public update(
		routingGroupId: number,
		label: string,
		respOrgId: string,
		isActive: number,
		templates: RoutingGroupTemplateCreateRequest[]
	) {
		const request: RoutingGroupUpdateRequest = {
			label,
			respOrgId,
			templates,
			isActive
		};
		const body = JSON.stringify(request);
		const urlParams: RoutingGroupProfileRequest = { routingGroupId };

		return this.fetchWithRoute<RoutingGroupTemplateIndexResponse>(
			Rt800ApiRoutes.RoutingGroups.Update,
			{ body, urlParams }
		);
	}
}
