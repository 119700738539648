import React, { FC, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { NumberGroupNumberIndexResponse } from 'RtModels';
import { NumberGroupNumberSelect } from 'RtUi/app/rt800/NumberGroups/lib/controls/NumberGroupNumberSelect';
import {
	NumberGroupNumberUpdateType,
	NumberGroupResource
} from 'RtUi/app/rt800/NumberGroups/lib/resources/NumberGroupResource';
import { TollFreeNumbersTextArea } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumbersTextArea';
import { Aside } from 'RtUi/components/ui/Aside';
import { RadioButtons } from 'RtUi/components/ui/RadioButtons';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface INumberGroupNumbersUpdateAsideProps {
	numberGroupId: number;
	isOpen: boolean;
	toggle: (isOpen: boolean) => void;
	onUpdate: (numberGroupNumbers: NumberGroupNumberIndexResponse[]) => void;
}

export const NumberGroupNumbersUpdateAside: FC<
	React.PropsWithChildren<INumberGroupNumbersUpdateAsideProps>
> = ({ isOpen, numberGroupId, toggle, onUpdate }) => {
	const [formError, setFormError] = useState<any>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [tfnsToRemove, setTfnsToRemove] = useState<
		NumberGroupNumberIndexResponse[]
	>([]);
	const [tfnsToAddOrReplace, setTfnsToAddOrReplace] = useState<string[]>([]);
	const [updateType, setUpdateType] = useState<NumberGroupNumberUpdateType>(
		NumberGroupNumberUpdateType.Add
	);

	const reset = () => {
		setFormError(undefined);
		setIsSubmitting(false);
		setTfnsToRemove([]);
		setTfnsToAddOrReplace([]);
		setUpdateType(NumberGroupNumberUpdateType.Add);
	};

	const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		const numberGroupResource = new NumberGroupResource();
		let tfns = [...tfnsToAddOrReplace];

		if (updateType === NumberGroupNumberUpdateType.Remove) {
			tfns = tfnsToRemove.map((numberGroupNumber) => numberGroupNumber.tfn);
		}

		try {
			setFormError(undefined);
			setIsSubmitting(true);

			const numberGroupNumbers =
				await numberGroupResource.getNumberGroupNumbersUpdate(
					numberGroupId,
					tfns,
					updateType
				);

			reset();
			onUpdate(numberGroupNumbers);
		} catch (err) {
			setFormError(err);
			setIsSubmitting(false);
		}
	};

	return (
		<Aside isOpen={isOpen} disabledBody>
			<Aside.Header
				header="Update Number Group Numbers"
				onClose={() => toggle(false)}
			/>
			<RtUiForm
				displayMode={false}
				onSubmit={onSubmit}
				error={formError}
				isSubmitting={isSubmitting}
				onCancel={() => {
					reset();
					toggle(false);
				}}
			>
				<Alert variant="warning" className="d-flex justify-content-start">
					<i className="fas fa-fw fa-exclamation-triangle me-2" />
					<span>
						Warning &middot; Updates to numbers are final and not reversible.
					</span>
				</Alert>
				<Form.Group className="mb-3">
					<RadioButtons<NumberGroupNumberUpdateType>
						vertical
						value={updateType}
						onChange={setUpdateType}
						options={[
							{
								value: NumberGroupNumberUpdateType.Add,
								label: 'Add Numbers to Existing'
							},
							{
								value: NumberGroupNumberUpdateType.Remove,
								label: 'Remove Numbers from Existing'
							},
							{
								value: NumberGroupNumberUpdateType.Replace,
								label: (
									<span>
										<span>Replace All Numbers&nbsp;&nbsp;</span>
										<i className="fas fa-fw fa-exclamation-triangle text-danger" />
									</span>
								)
							}
						]}
					/>
				</Form.Group>
				{updateType === NumberGroupNumberUpdateType.Remove && (
					<NumberGroupNumberSelect<true>
						multi
						required
						label="Numbers to Remove"
						numberGroupId={numberGroupId}
						value={tfnsToRemove}
						onChange={setTfnsToRemove}
					/>
				)}
				{updateType !== NumberGroupNumberUpdateType.Remove && (
					<TollFreeNumbersTextArea
						required
						label="Toll-Free Numbers"
						allowForUpload
						value={tfnsToAddOrReplace}
						onChange={setTfnsToAddOrReplace}
					/>
				)}
			</RtUiForm>
		</Aside>
	);
};
