import { cloneDeep } from 'lodash-es';
import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { PartitionIndexResponse } from 'RtModels';
import { PartitionResource } from 'RtUi/app/rtAdmin/lib/resources/PartitionResource';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';

interface IPartitionEditFormProps {
	partition?: PartitionIndexResponse;
	onUpdate: (newPartition: PartitionIndexResponse) => void;
}

interface IPartitionEditFormState {
	isSubmitting: boolean;
	editingPartition: PartitionIndexResponse;
	displayMode: boolean;
	error?: any;
}

export class PartitionEditForm extends React.Component<
	IPartitionEditFormProps,
	IPartitionEditFormState
> {
	private partitionResource = new PartitionResource();

	constructor(props: IPartitionEditFormProps) {
		super(props);

		this.state = {
			isSubmitting: false,
			displayMode: Boolean(props.partition),
			editingPartition: props.partition
				? cloneDeep(props.partition)
				: {
						partitionId: -1,
						summary: '',
						prefix: '',
						isActive: 0,
						isRt800: 0,
						isRtDid: 0,
						isRtSip: 0,
						isRtLco: 0,
						isRtVue: 0,
						isRtCarrierConnect: 0,
						isRtGuardian: 0,
						inboundPortLimit: 0,
						kubeRoles: '',
						kubeVersion: '',
						apiEntityId: '',
						apiBasePath: '',
						inProgress: 0,
						localNtxCarrierId: 0,
						exchangeRate: 0,
						isRtLcr: 0,
						mediaServerGroupId: 0,
						remoteNtxCarrierId: 0,
						prefixLong: '',
						altCommonName: ''
					}
		};
	}

	public render() {
		const { editingPartition } = this.state;

		return (
			<RtUiWideForm
				createMode={!Boolean(this.props.partition)}
				displayMode={this.state.displayMode}
				onCancel={this.onCancel}
				isSubmitting={this.state.isSubmitting}
				onSubmit={this.onSubmit}
				error={this.state.error}
				onChange={(displayMode) => this.setState({ displayMode })}
			>
				<Row>
					<Col md={6}>
						{this.props.partition && (
							<InputFormControl
								label="ID"
								readOnly={true}
								displayMode={true}
								value={editingPartition.partitionId.toString()}
							/>
						)}
						<InputFormControl
							label="Prefix"
							onChange={(value) => this.onChange('prefix', value)}
							displayMode={this.state.displayMode}
							value={editingPartition.prefix}
						/>
						<InputFormControl
							label="Summary"
							displayMode={this.state.displayMode}
							onChange={(value) => this.onChange('summary', value)}
							value={editingPartition.summary}
						/>
						<InputFormControl
							onChange={(value) =>
								this.onChange('inboundPortLimit', Number(value))
							}
							label="Inbound Port Limit"
							type="number"
							displayMode={this.state.displayMode}
							value={String(editingPartition.inboundPortLimit)}
						/>
						<InputFormControl
							onChange={(value) => this.onChange('exchangeRate', Number(value))}
							label="Exchange Rate"
							type="number"
							displayMode={this.state.displayMode}
							value={String(editingPartition.exchangeRate)}
						/>
						<InputFormControl
							onChange={(value) =>
								this.onChange('mediaServerGroupId', Number(value))
							}
							label="Media Server Group Id"
							type="number"
							displayMode={this.state.displayMode}
							value={String(editingPartition.mediaServerGroupId)}
						/>
						<InputFormControl
							onChange={(value) =>
								this.onChange('localNtxCarrierId', Number(value))
							}
							label="Local Ntx Carrier Id"
							type="number"
							displayMode={this.state.displayMode}
							value={String(editingPartition.localNtxCarrierId)}
						/>
						<InputFormControl
							onChange={(value) =>
								this.onChange('remoteNtxCarrierId', Number(value))
							}
							label="Remote Ntx Carrier Id"
							type="number"
							displayMode={this.state.displayMode}
							value={String(editingPartition.remoteNtxCarrierId)}
						/>
						<InputFormControl
							onChange={(value) => this.onChange('kubeVersion', value)}
							label="Kubernetes Version"
							type="number"
							displayMode={this.state.displayMode}
							value={editingPartition.kubeVersion || ''}
						/>
						<InputFormControl
							onChange={(value) => this.onChange('kubeRoles', value)}
							label="Kubernetes Roles"
							type="number"
							displayMode={this.state.displayMode}
							value={editingPartition.kubeRoles || ''}
						/>
						<InputFormControl
							label="API Base Path"
							onChange={(value) => this.onChange('apiBasePath', value)}
							displayMode={this.state.displayMode}
							value={editingPartition.apiBasePath || ''}
						/>
						<InputFormControl
							label="API Entity ID"
							onChange={(value) => this.onChange('apiEntityId', value)}
							displayMode={this.state.displayMode}
							value={editingPartition.apiEntityId || ''}
						/>
						<InputFormControl
							label="Prefix Long"
							onChange={(value) => this.onChange('prefixLong', value)}
							displayMode={this.state.displayMode}
							value={editingPartition.prefixLong || ''}
						/>
						<InputFormControl
							label="Alternate Common Name"
							onChange={(value) => this.onChange('altCommonName', value)}
							displayMode={this.state.displayMode}
							value={editingPartition.altCommonName || ''}
						/>
					</Col>
					<Col md={6}>
						<BooleanRadioFormControl
							label="RT/800 Enabled"
							onChange={(value) => this.onChange('isRt800', value)}
							displayMode={this.state.displayMode}
							value={editingPartition.isRt800}
						/>
						<BooleanRadioFormControl
							onChange={(value) => this.onChange('isRtSip', value)}
							label="RT/SIP Enabled"
							displayMode={this.state.displayMode}
							value={editingPartition.isRtSip}
						/>
						<BooleanRadioFormControl
							onChange={(value) => this.onChange('isRtLco', value)}
							label="RT/LCO Enabled"
							displayMode={this.state.displayMode}
							value={editingPartition.isRtLco}
						/>
						<BooleanRadioFormControl
							onChange={(value) => this.onChange('isRtVue', value)}
							label="RT/Vue Enabled"
							displayMode={this.state.displayMode}
							value={editingPartition.isRtVue}
						/>
						<BooleanRadioFormControl
							onChange={(value) => this.onChange('isRtCarrierConnect', value)}
							label="RT/CarrierConnect Enabled"
							displayMode={this.state.displayMode}
							value={editingPartition.isRtCarrierConnect}
						/>
						<BooleanRadioFormControl
							onChange={(value) => this.onChange('isRtLcr', value)}
							label="RT/LCR Enabled"
							displayMode={this.state.displayMode}
							value={editingPartition.isRtLcr}
						/>
						<BooleanRadioFormControl
							onChange={(value) => this.onChange('isActive', value)}
							label="Is Active"
							displayMode={this.state.displayMode}
							value={editingPartition.isActive}
						/>
					</Col>
				</Row>
			</RtUiWideForm>
		);
	}

	private onCancel = () => {
		if (this.props.partition) {
			this.setState({
				editingPartition: cloneDeep(this.props.partition),
				displayMode: true
			});
		}
	};

	private onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		try {
			let newPartition: PartitionIndexResponse;

			if (this.props.partition) {
				newPartition = await this.partitionResource.update(
					this.props.partition.partitionId,
					this.state.editingPartition
				);
			} else {
				newPartition = await this.partitionResource.create(
					this.state.editingPartition
				);
			}

			this.setState({ isSubmitting: false, displayMode: true }, () => {
				this.props.onUpdate(newPartition);
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	};

	private onChange = <K extends keyof PartitionIndexResponse>(
		key: K,
		value: PartitionIndexResponse[K]
	) => {
		if (!this.state.isSubmitting) {
			const { editingPartition } = this.state;

			editingPartition[key] = value;

			this.setState({ editingPartition });
		}
	};
}
