import { PartitionIndexResponse } from 'RtModels';
import { PartitionSelect } from 'RtUi/app/rtAdmin/lib/controls/PartitionSelect';
import { ISelectFormControlState } from 'RtUi/components/form/SelectFormControl';

export class VuePartitionSelect extends PartitionSelect {
	public state: ISelectFormControlState<PartitionIndexResponse> = {
		formLabel: 'Partition',
		valueKey: 'prefix',
		labelKey: 'summary'
	};
}
