import { MgiTaskTypes } from 'RtModels';
import loadable, { Options as LoadableOptions } from '@loadable/component';
import { Permissions } from 'RtExports/routes';
import { IMgiTaskLabelType, IMgiTaskOption } from './interfaces';
import { UserActions } from 'RtUi/state/actions/user';
import { Loading } from 'RtUi/components/ui/Loading';

const loadableOptions: LoadableOptions<any> = {
	fallback: <Loading internal />
};

const ActivateBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/activate'),
	loadableOptions
);
const DisconnectBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/disconnect'),
	loadableOptions
);
const QueryBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/query'),
	loadableOptions
);
const ReRouteBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/reroute'),
	loadableOptions
);
const ReserveBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/reserve'),
	loadableOptions
);
const ReserveQuantityBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/reserveQuantity'),
	loadableOptions
);
const RespOrgChangeBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/resporgChange'),
	loadableOptions
);
const SearchBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/search'),
	loadableOptions
);
const SearchQuantityBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/searchQuantity'),
	loadableOptions
);
const SpareBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/spare'),
	loadableOptions
);
const CprQueryBulkActionForm = loadable(
	() => import('RtUi/app/rt800/Tasks/lib/forms/cprQuery'),
	loadableOptions
);

export const MgiTaskOptions: IMgiTaskOption[] = [
	{
		id: MgiTaskTypes.Activate,
		label: 'Activate',
		component: () => ActivateBulkActionForm,
		numberPermissions: [Permissions.TollFreeNumberActivate],
		bulkPermissions: [Permissions.TollFreeBulkActivate],
		description: ''
	},
	{
		id: MgiTaskTypes.Disconnect,
		label: 'Disconnect',
		component: () => DisconnectBulkActionForm,
		numberPermissions: [Permissions.TollFreeNumberDisconnect],
		bulkPermissions: [Permissions.TollFreeBulkDisconnect],
		description: ''
	},
	{
		id: MgiTaskTypes.Query,
		label: 'Query',
		component: () => QueryBulkActionForm,
		hideInNumber: true,
		numberPermissions: [Permissions.TollFreeNumberQuery],
		bulkPermissions: [Permissions.TollFreeBulkQuery],
		description: (
			<span>
				Refreshes the RespOrg ID and status. <b>Warning:</b> This will not
				update template or routing information.
			</span>
		)
	},
	{
		id: MgiTaskTypes.CprQuery,
		label: 'CPR Query',
		component: () => CprQueryBulkActionForm,
		hideInNumber: true,
		numberPermissions: [Permissions.TollFreeNumberQuery],
		bulkPermissions: [Permissions.TollFreeBulkQuery],
		description: (
			<span>
				Updates the template/routing information for Toll-Free Numbers that you
				have access to. <b>Warning:</b> A CPR Query takes significantly longer
				than a standard Query.
			</span>
		)
	},
	{
		id: MgiTaskTypes.ReRoute,
		label: 'Re Route',
		component: () => ReRouteBulkActionForm,
		numberPermissions: [Permissions.TollFreeNumberReRoute],
		bulkPermissions: [Permissions.TollFreeBulkReRoute],
		description: ''
	},
	{
		id: MgiTaskTypes.Reserve,
		label: 'Reserve (By TFN List)',
		component: () => ReserveBulkActionForm,
		numberPermissions: [Permissions.TollFreeNumberReserve],
		bulkPermissions: [Permissions.TollFreeBulkReserve],
		description: '',
		hideInNumber: true
	},
	{
		id: MgiTaskTypes.ReserveQuantity,
		label: 'Reserve (By Quantity)',
		component: () => ReserveQuantityBulkActionForm,
		numberPermissions: [Permissions.TollFreeNumberReserve],
		bulkPermissions: [Permissions.TollFreeBulkReserve],
		description: '',
		hideInTask: true,
		hideInNumber: true,
		hideInNumberGroup: true
	},
	{
		id: MgiTaskTypes.RespOrgChange,
		label: 'RespOrg Change',
		component: () => RespOrgChangeBulkActionForm,
		numberPermissions: [Permissions.TollFreeNumberRespOrgChangeTask],
		bulkPermissions: [Permissions.TollFreeBulkRespOrgChange],
		description: ''
	},
	{
		id: MgiTaskTypes.Search,
		label: 'Search (By TFN List)',
		component: () => SearchBulkActionForm,
		numberPermissions: [Permissions.TollFreeNumberSearch],
		bulkPermissions: [Permissions.TollFreeBulkSearch],
		description: '',
		hideInNumber: true
	},
	{
		id: MgiTaskTypes.SearchQuantity,
		label: 'Search (By Quantity)',
		component: () => SearchQuantityBulkActionForm,
		numberPermissions: [Permissions.TollFreeNumberSearch],
		bulkPermissions: [Permissions.TollFreeBulkSearch],
		description: '',
		hideInTask: true,
		hideInNumber: true,
		hideInNumberGroup: true
	},
	{
		id: MgiTaskTypes.Spare,
		label: 'Spare',
		component: () => SpareBulkActionForm,
		numberPermissions: [Permissions.TollFreeNumberSpare],
		bulkPermissions: [Permissions.TollFreeBulkSpare],
		description: ''
	}
];

export class MgiTaskOptionsHelper {
	public static getInstance() {
		return MgiTaskOptionsHelper.instance;
	}

	private static instance: MgiTaskOptionsHelper = new MgiTaskOptionsHelper();
	private static readonly ShortReserveLabel = 'Reserve';

	protected constructor() {}

	public getTaskFromLabel(label: IMgiTaskLabelType) {
		return MgiTaskOptions.find((option) => {
			if (option.label === label) {
				return true;
			}

			const isReserve = option.id === MgiTaskTypes.Reserve;
			const labelIsShortReserve =
				MgiTaskOptionsHelper.ShortReserveLabel === label;

			if (isReserve && labelIsShortReserve) {
				return true;
			}

			return false;
		});
	}

	public getNumberOptions() {
		return MgiTaskOptions.filter((option) => !option.hideInNumber).filter(
			(numberOption) => UserActions.has(...numberOption.numberPermissions)
		);
	}

	public getReserveOptionForNumberIfUserHasAccess() {
		return MgiTaskOptions.filter((option) => option.id === MgiTaskTypes.Reserve)
			.map((reserveOption) => {
				//copy so the original reserve does not get changed
				const copyOfResourceOption = { ...reserveOption };
				copyOfResourceOption.label = MgiTaskOptionsHelper.ShortReserveLabel;

				return copyOfResourceOption;
			})
			.filter((copyOfResourceOption) =>
				UserActions.has(...copyOfResourceOption.numberPermissions)
			)
			.pop();
	}

	public getBulkOptions() {
		return MgiTaskOptions.filter((bulkOption) =>
			UserActions.has(...bulkOption.bulkPermissions)
		);
	}

	public getNumberGroupOptions() {
		return MgiTaskOptions.filter((option) => !option.hideInNumberGroup).filter(
			(numberGroupOption) =>
				UserActions.has(...numberGroupOption.bulkPermissions)
		);
	}

	public getTaskOptions() {
		return MgiTaskOptions.filter((option) => !option.hideInTask).filter(
			(numberOption) => UserActions.has(...numberOption.bulkPermissions)
		);
	}

	public getNumberOptionLabels() {
		return this.getNumberOptions().map((ba) => ba.label);
	}

	public getBulkOptionLabels() {
		return this.getBulkOptions().map((ba) => ba.label);
	}

	public getTaskOptionLabels() {
		return this.getTaskOptions().map((ba) => ba.label);
	}

	public getNumberGroupOptionLabels() {
		return this.getNumberGroupOptions().map((ba) => ba.label);
	}
}
