import { RtxSipApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { ContactIndexResponse, ContactIndexRequest } from 'RtModels';

export class AccountContactResource extends ArrayResource<ContactIndexResponse> {
	constructor(private accountId: number) {
		super('contactId');
		const urlParams: ContactIndexRequest = { accountId: this.accountId };

		this.setApiRouteForGetAll(RtxSipApiRoutes.AccountContacts.Index, {
			urlParams
		});
	}
}
