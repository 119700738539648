import {
	ApplicationNotifications,
	ApplicationNotificationTypes,
	INotification
} from 'RtUi/app/ApplicationShell/lib/components/ApplicationNotifications';

declare const DEV_MODE: number;

/**
 * Checks for manifest.json updates
 * If new rtx-ui.js asset is found the user is prompted
 * to "update" which means reload() the webpage to get new assets
 */
export class ManifestChecker {
	public static async GetInstance() {
		if (!ManifestChecker.promise) {
			const promise = new Promise<ManifestChecker>(async (resolve) => {
				const manifestInstance = new ManifestChecker();
				await manifestInstance.detectManifestChanges();

				resolve(manifestInstance);
			});

			ManifestChecker.promise = promise;
		}

		return ManifestChecker.promise;
	}

	public static Start() {
		this.GetInstance();
	}

	protected static promise: Promise<ManifestChecker> | undefined;
	protected static WasUpdatedStorageKey = 'ManifestCheckerStorage::WasUpdated';
	protected lastRtxUiAsset: string | undefined;
	protected version: string | undefined;
	protected latestManifest: Record<string, string> | null = null;

	private constructor() {
		const thirtyMinutesInMs = 30 * 60 * 1000;
		window.setInterval(this.detectManifestChanges, thirtyMinutesInMs);

		document.addEventListener('visibilitychange', this.detectManifestChanges);

		//After 3 seconds check to see if app was updated
		setTimeout(this.detectIfUpdateOccurredAndNotify, 3 * 1000);
	}

	/**
	 * get the path for a given asset
	 */
	public getManifestFor(assetName: string): string | null {
		if (this.latestManifest === null) {
			return null;
		}

		if (!(assetName in this.latestManifest)) {
			return null;
		}

		return this.latestManifest[assetName];
	}

	public static async getManifestVersion() {
		const instance = await ManifestChecker.GetInstance();
		return instance.version ?? '';
	}

	private detectManifestChanges = async () => {
		const notificationId = 'ManifestCheckerUpdateNotification';

		let manifest: Record<string, string>;
		const rtxUiJsAssetKey = 'rtx-ui.js';
		const makeError = (errorMsg: string) => {
			new Error(`${ManifestChecker.name}: ${errorMsg}`);
		};

		try {
			const manifestResponse = await window.fetch('/manifest.json', {
				redirect: 'error',
				cache: 'no-store'
			});

			if (!manifestResponse.ok) {
				throw makeError(`manifest.json not found`);
			}

			const contentType = manifestResponse.headers.get('Content-Type');

			if (contentType && !contentType.includes('json')) {
				throw makeError(`manifest.json is not json`);
			}

			manifest = await manifestResponse.json();

			if (!(rtxUiJsAssetKey in manifest)) {
				throw makeError(`cannot find ${rtxUiJsAssetKey}`);
			}

			this.latestManifest = manifest;
		} catch (err) {
			// eslint-disable-next-line no-console
			return console.log(err);
		}

		const rtxUiAssetFileName = manifest[rtxUiJsAssetKey];

		if (this.lastRtxUiAsset) {
			if (this.lastRtxUiAsset !== rtxUiAssetFileName) {
				this.lastRtxUiAsset = rtxUiAssetFileName;
				this.version = manifest.version;

				//Not needed for development
				if (DEV_MODE) {
					return;
				}

				const notification: INotification = {
					notificationId,
					message:
						'A new version of this app has been released. Click here to update.',
					title: 'App Update Found',
					notificationTypeId: ApplicationNotificationTypes.Info,
					effectiveTs: new Date(),
					expirationTs: new Date(),
					isUrgent: 1
				};

				ApplicationNotifications.openNotification(notification, {
					hideProgressBar: true,
					onClick: this.triggerUpdate
				});
			}
		} else {
			this.lastRtxUiAsset = rtxUiAssetFileName;
			this.version = manifest.version;
		}
	};

	private detectIfUpdateOccurredAndNotify = () => {
		const wasUpdated = localStorage.getItem(
			ManifestChecker.WasUpdatedStorageKey
		);

		if (wasUpdated) {
			localStorage.removeItem(ManifestChecker.WasUpdatedStorageKey);

			//Not needed for development
			if (DEV_MODE) {
				return;
			}

			ApplicationNotifications.openNotification({
				notificationId: 'ManifestCheckerWasUpdatedNotification',
				message: 'Congrats! You are now using the latest version of the app.',
				title: 'App Update Installed',
				notificationTypeId: ApplicationNotificationTypes.Success,
				effectiveTs: new Date(),
				expirationTs: new Date(),
				isUrgent: 0
			});
		}
	};

	/**
	 * Triggers a page reload to refresh assets
	 */
	private triggerUpdate = () => {
		try {
			localStorage.setItem(ManifestChecker.WasUpdatedStorageKey, 'true');
		} catch {
			//meh
		}

		window.location.reload();
	};
}
