import * as React from 'react';
import {
	Col,
	Row,
	Alert,
	Card,
	Badge,
	Button,
	ButtonGroup,
	Form,
	ListGroup,
	OverlayTrigger,
	Tooltip,
	Overlay,
	Popover
} from 'react-bootstrap';
import {
	FlowStepIndexResponse,
	IntegrationTaskType,
	IntegrationTaskTypeIndexResponse,
	ResourceConfigurationIndexResponse
} from 'RtModels';
import { ResourceConfigurationResource } from 'RtUi/app/rtCommon/Resources/lib/resources/ResourceConfigurationResource';
import { IntegrationTaskTypeSelect } from 'RtUi/app/rtCommon/Integrations/controls/IntegrationTaskTypeSelect';
import { FlowStepResourceForm } from 'RtUi/app/rtAdmin/Flows/lib/forms/FlowsStepResourceForm';
import {
	FlowStepResource,
	FlowStepUiResourceTypes,
	IFlowStepResourceUiUpdateRequest,
	IFlowStepUiIndex
} from 'RtUi/app/rtAdmin/Flows/lib/resources/FlowStepResource';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { Aside } from 'RtUi/components/ui/Aside';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import { Loading } from 'RtUi/components/ui/Loading';
import { cloneDeep, groupBy, map } from 'lodash-es';

export interface IFlowStepListProps {
	flowId: number;
}

export interface IFlowStepListState {
	displayMode: boolean;
	isSubmitting: boolean;
	isLoading: boolean;
	error?: any;
	flowSteps: IFlowStepUiIndex[];
	showDeletePopoverForSequence: number | undefined;
	resourceIndexes: ResourceConfigurationIndexResponse[];
	editing?: {
		resource: FlowStepUiResourceTypes;
		flowStep: IFlowStepUiIndex;
	};
	target?: HTMLElement;
}

export class FlowStepList extends React.Component<
	IFlowStepListProps,
	IFlowStepListState
> {
	public state: IFlowStepListState = {
		displayMode: true,
		isSubmitting: false,
		isLoading: true,
		error: undefined,
		flowSteps: [],
		showDeletePopoverForSequence: undefined,
		resourceIndexes: []
	};

	public flowStepsCopy: IFlowStepUiIndex[] = [];
	public flowStepResource!: FlowStepResource;

	constructor(props: IFlowStepListProps) {
		super(props);

		this.flowStepResource = new FlowStepResource(props.flowId);
	}

	public async componentDidMount() {
		const { flowId } = this.props;
		const resourcesResource = new ResourceConfigurationResource();

		const resourceIndexes = await resourcesResource.getAll();
		const flowSteps = await this.flowStepResource.getAll({ flowId });

		flowSteps.sort((fs1, fs2) => fs1.sequence - fs2.sequence);

		this.flowStepsCopy = cloneDeep(flowSteps);

		this.setState({ flowSteps, resourceIndexes, isLoading: false });
	}

	public onCancel() {
		const flowSteps = cloneDeep(this.flowStepsCopy);

		this.setState({ flowSteps, error: undefined });
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const { flowSteps } = this.state;
			const newFlowSteps = await this.flowStepResource.updateSteps(flowSteps);

			this.flowStepsCopy = cloneDeep(newFlowSteps);

			this.setState({ displayMode: true, flowSteps: newFlowSteps });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public swapSequences(oldSequence: number, newSequence: number) {
		const { flowSteps } = this.state;

		const newFlowSteps = flowSteps.map((flowStep) => {
			if (flowStep.sequence === oldSequence) {
				flowStep.sequence = newSequence;
			} else if (flowStep.sequence === newSequence) {
				flowStep.sequence = oldSequence;
			}

			return flowStep;
		});

		this.setState({ flowSteps: newFlowSteps });
	}

	public createNewStep(sequenceIndex: number) {
		const newFlowStep: IFlowStepUiIndex = {
			integrationTaskTypeId: IntegrationTaskType.DATA_INITIALIZATION, //temp
			flowStepId: generateUUID(),
			sequence: sequenceIndex, //temp
			flowId: this.props.flowId,
			omitErrors: 0,
			continueOnErrors: 0,
			label: '',
			summary: '',
			resources: []
		};

		return newFlowStep;
	}

	public addNewStepSequence(sequence: number) {
		const { flowSteps } = this.state;

		const newFlowStep = this.createNewStep(sequence);

		flowSteps.push(newFlowStep);

		this.setState({ flowSteps });
	}

	public initSteps() {
		this.insertNewStep(1);

		this.setState({ displayMode: false });
	}

	public insertNewStep(insertSequenceIndex: number) {
		const { flowSteps } = this.state;

		const newFlowStep = this.createNewStep(insertSequenceIndex);

		for (const flowStep of flowSteps) {
			if (flowStep.sequence >= insertSequenceIndex) {
				flowStep.sequence++;
			}
		}

		flowSteps.push(newFlowStep);

		this.setState({ flowSteps });
	}

	public deleteSequence(deleteSequenceIndex: number) {
		const { flowSteps } = this.state;

		const newFlowSteps = flowSteps
			.filter((flowStep) => flowStep.sequence !== deleteSequenceIndex)
			.map((flowStep) => {
				if (flowStep.sequence >= deleteSequenceIndex) {
					flowStep.sequence--;
				}

				return flowStep;
			});

		this.setState({ flowSteps: newFlowSteps });
	}

	public deleteStep(flowStep: IFlowStepUiIndex) {
		const { flowSteps } = this.state;

		const newFlowSteps = flowSteps.filter((fs) => fs !== flowStep);

		this.setState({ flowSteps: newFlowSteps });
	}

	public addResourceTo(flowStep: IFlowStepUiIndex) {
		const { flowSteps, resourceIndexes } = this.state;

		const defaultResource = resourceIndexes[0];

		if (!defaultResource) {
			throw new Error(
				'AddResource: Unable to find resource with integrationTypeId'
			);
		}

		const resource: IFlowStepResourceUiUpdateRequest = {
			flowStepResourceId: generateUUID(),
			resourceId: defaultResource.resourceId,
			isRequired: 0,
			isDefault: 0
		};

		flowStep.resources.push(resource);

		this.setState({ flowSteps, editing: { flowStep, resource } });
	}

	public renderSequenceHeader(sequence: number) {
		const { flowSteps } = this.state;
		const isFirst = sequence === 1;
		const isLast = flowSteps.length === sequence;
		const addAboveBtnId = `AddAbove${sequence}`;
		const addBelowBtnId = `AddABelow${sequence}`;
		const moveUpBtnId = `MoveUp${sequence}`;
		const moveDownBtnId = `MoveDown${sequence}`;
		const deleteBtnId = `Delete${sequence}`;
		const addBtnId = `Add${sequence}`;

		return (
			<section className="form-group">
				<header className="d-flex justify-content-between align-items-center">
					<header className="h6 mb-0 d-flex align-items-center justify-content-start">
						<b>Step {sequence.toLocaleString()}</b>
					</header>
					{!this.state.displayMode && (
						<ButtonGroup>
							<OverlayTrigger
								placement="right"
								overlay={(props) => (
									<Tooltip id={`${addBtnId}-tooltip`} {...props}>
										Add to Step
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										id={addBtnId}
										variant="white"
										type="button"
										onClick={() => this.addNewStepSequence(sequence)}
									>
										<i className="fas fa-fw fa-plus" />
									</Button>
								)}
							</OverlayTrigger>
							<OverlayTrigger
								placement="right"
								overlay={(props) => (
									<Tooltip id={`${addBelowBtnId}-tooltip`} {...props}>
										Add Step Below
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										id={addBelowBtnId}
										variant="white"
										type="button"
										onClick={() => this.insertNewStep(sequence + 1)}
									>
										<img
											src="/assets/icons/icons8-insert-row-24.png"
											width="24"
											height="24"
										/>
									</Button>
								)}
							</OverlayTrigger>
							<OverlayTrigger
								placement="right"
								overlay={(props) => (
									<Tooltip id={`${addAboveBtnId}-tooltip`} {...props}>
										Add Step Above
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										id={addAboveBtnId}
										variant="white"
										type="button"
										onClick={() => this.insertNewStep(sequence)}
									>
										<img
											src="/assets/icons/icons8-insert-row-above-24.png"
											width="24"
											height="24"
										/>
									</Button>
								)}
							</OverlayTrigger>
							<OverlayTrigger
								placement="right"
								overlay={(props) => (
									<Tooltip id={`${moveUpBtnId}-tooltip`} {...props}>
										Move Step Up
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										id={moveUpBtnId}
										variant="white"
										disabled={isFirst}
										type="button"
										onClick={() => this.swapSequences(sequence, sequence - 1)}
									>
										<i className="fas fa-fw fa-arrow-up" />
									</Button>
								)}
							</OverlayTrigger>
							<OverlayTrigger
								placement="right"
								overlay={(props) => (
									<Tooltip id={`${moveDownBtnId}-tooltip`} {...props}>
										Move Step Down
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										id={moveDownBtnId}
										variant="white"
										disabled={isLast}
										type="button"
										onClick={() => this.swapSequences(sequence, sequence + 1)}
									>
										<i className="fas fa-fw fa-arrow-down" />
									</Button>
								)}
							</OverlayTrigger>
							<OverlayTrigger
								placement="right"
								overlay={(props) => (
									<Tooltip id={`${deleteBtnId}-tooltip`} {...props}>
										Delete Step
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										id={deleteBtnId}
										variant="white"
										type="button"
										onClick={(e) =>
											this.setState({
												showDeletePopoverForSequence: sequence,
												target: e.currentTarget
											})
										}
									>
										<i className="fas fa-fw fa-trash" />
									</Button>
								)}
							</OverlayTrigger>
						</ButtonGroup>
					)}
				</header>
				<Overlay
					key={`popover-action-delete-${this.state.showDeletePopoverForSequence}`}
					target={this.state.target!}
					show={sequence === this.state.showDeletePopoverForSequence}
					placement="bottom-end"
				>
					<Popover
						id={`popover-action-delete-${this.state.showDeletePopoverForSequence}`}
					>
						<Popover.Body>
							<Card.Body>
								<header className="h6 text-muted mb-4">
									Are you sure you would like to delete this flow step?
								</header>
								<section className="d-flex justify-content-end">
									<Button
										type="button"
										variant="white"
										className="me-2"
										onClick={() =>
											this.setState({
												showDeletePopoverForSequence: undefined
											})
										}
									>
										Cancel
									</Button>
									<Button
										type="button"
										variant="danger"
										onClick={() => {
											this.setState({
												showDeletePopoverForSequence: undefined
											});
											this.deleteSequence(sequence);
										}}
									>
										Yes, Delete
									</Button>
								</section>
							</Card.Body>
						</Popover.Body>
					</Popover>
				</Overlay>
			</section>
		);
	}

	public updateFlowStepProp<K extends keyof FlowStepIndexResponse>(
		key: K,
		value: FlowStepIndexResponse[K],
		flowStep: IFlowStepUiIndex
	) {
		const { flowSteps } = this.state;

		flowStep[key] = value;

		this.setState({ flowSteps });
	}

	public updateFlowStepIntegrationTaskType(
		flowStep: IFlowStepUiIndex,
		integrationTaskType: IntegrationTaskTypeIndexResponse
	) {
		const { flowSteps } = this.state;

		flowStep.integrationTaskType = integrationTaskType;
		flowStep.integrationTaskTypeId = integrationTaskType.integrationTaskTypeId;
		this.setState({ flowSteps });
	}

	public updateEditingResourceProp<K extends keyof FlowStepUiResourceTypes>(
		propertyKey: K,
		propertyValue: FlowStepUiResourceTypes[K]
	) {
		const { editing, flowSteps } = this.state;

		if (!editing) {
			return;
		}

		editing.resource[propertyKey] = propertyValue;

		this.setState({ flowSteps });
	}

	public deleteFlowStepResource(
		evt: React.MouseEvent<any, MouseEvent>,
		resource: FlowStepUiResourceTypes
	) {
		const { flowSteps } = this.state;

		evt.stopPropagation();

		for (const flowStep of flowSteps) {
			for (const flowStepResource of flowStep.resources) {
				if (flowStepResource === resource) {
					const resourceIndex = flowStep.resources.indexOf(resource);

					flowStep.resources.splice(resourceIndex, 1);
					break;
				}
			}
		}

		this.setState({ flowSteps });
	}

	public getResourceIndex(resourceId: number) {
		const { resourceIndexes } = this.state;

		return resourceIndexes.find((r) => r.resourceId === resourceId);
	}

	public getResourceName(resourceId: number) {
		const resourceIndex = this.getResourceIndex(resourceId);

		if (resourceIndex) {
			return resourceIndex.label;
		}

		return '';
	}

	public onResourceClick(
		evt: React.MouseEvent<any, MouseEvent>,
		flowStep: IFlowStepUiIndex,
		resource: FlowStepUiResourceTypes
	) {
		evt.stopPropagation();

		if (!this.state.displayMode) {
			this.setState({ editing: { flowStep, resource } });
		}
	}

	public render() {
		const { flowSteps } = this.state;
		const flowStepsBySequence = groupBy(flowSteps, 'sequence');
		const firstLetterCode = 'a'.charCodeAt(0);
		const stepLetterFromIndex = (index: number) =>
			String.fromCharCode(index + firstLetterCode);
		const renderInitStepComponent = (className?: string) => (
			<section className={className}>
				<Button type="button" variant="submit" onClick={() => this.initSteps()}>
					Create Step
				</Button>
			</section>
		);

		if (this.state.isLoading) {
			return <Loading internal />;
		}

		if (flowSteps.length <= 0 && this.state.displayMode) {
			return renderInitStepComponent();
		}

		return (
			<section>
				<RtUiWideForm
					className="rounded"
					displayMode={this.state.displayMode}
					isSubmitting={this.state.isSubmitting}
					error={this.state.error}
					onChange={(displayMode) => this.setState({ displayMode })}
					onCancel={() => this.onCancel()}
					onSubmit={(evt) => this.onSubmit(evt)}
				>
					<section>
						{flowSteps.length <= 0 && (
							<Card.Body className="mb-0">
								<Alert
									variant="warning"
									className="d-flex justify-content-start"
								>
									<i className="fas fa-fw fa-info-circle me-2" />
									<span>All steps have been removed.</span>
								</Alert>
								{renderInitStepComponent()}
							</Card.Body>
						)}
						{map(
							flowStepsBySequence,
							(flowStepSequenceGroup: IFlowStepUiIndex[], key: string) => {
								const sequence = Number(key);

								return (
									<section key={key} className="flow-step-list-component">
										{this.renderSequenceHeader(sequence)}
										<section>
											{flowStepSequenceGroup.map(
												(flowStep: IFlowStepUiIndex, flowStepIndex) => (
													<section
														key={`${key}-${flowStep.flowStepId}`}
														className="flow-step-group-container"
													>
														<section>
															{flowStepSequenceGroup.length > 1 && (
																<header className="h6 d-flex justify-content-start align-items-center">
																	<span className="me-1 text-muted">
																		Step {sequence}
																		{stepLetterFromIndex(flowStepIndex)}
																	</span>
																	{!this.state.displayMode && (
																		<a
																			className="small"
																			onClick={() => this.deleteStep(flowStep)}
																		>
																			(remove)
																		</a>
																	)}
																</header>
															)}
															<Row>
																<Col lg={6}>
																	<IntegrationTaskTypeSelect
																		required
																		hideLabel
																		clearable={false}
																		displayMode={this.state.displayMode}
																		onChange={(integrationTaskType) =>
																			this.updateFlowStepIntegrationTaskType(
																				flowStep,
																				integrationTaskType
																			)
																		}
																		value={flowStep.integrationTaskType}
																		initialOptionId={String(
																			flowStep.integrationTaskTypeId
																		)}
																	/>
																</Col>
																<Col lg={6}>
																	<Form.Group className="mb-3">
																		<CheckboxFormControl
																			label="Continue on Errors"
																			displayMode={this.state.displayMode}
																			onChange={(continueOnErrors) =>
																				this.updateFlowStepProp(
																					'continueOnErrors',
																					continueOnErrors ? 1 : 0,
																					flowStep
																				)
																			}
																			value={flowStep.continueOnErrors === 1}
																		/>
																	</Form.Group>
																	<Form.Group className="mb-3">
																		<CheckboxFormControl
																			label="Omit Errors"
																			displayMode={this.state.displayMode}
																			onChange={(omitErrors) =>
																				this.updateFlowStepProp(
																					'omitErrors',
																					omitErrors ? 1 : 0,
																					flowStep
																				)
																			}
																			value={flowStep.omitErrors === 1}
																		/>
																	</Form.Group>
																</Col>
																<Col xs={12}>
																	<Form.Label>Resources</Form.Label>
																	{flowStep.resources.length > 0 && (
																		<Form.Group className="mb-3">
																			<ListGroup>
																				{flowStep.resources.map(
																					(resource, index) => (
																						<ListGroup.Item
																							action={!this.state.displayMode}
																							onClick={(evt) =>
																								this.onResourceClick(
																									evt,
																									flowStep,
																									resource
																								)
																							}
																							key={`${flowStepIndex}-${index}`}
																							className="d-flex justify-content-between"
																							variant={
																								resource.flowStepResourceId ===
																								this.state.editing?.resource
																									.flowStepResourceId
																									? 'warning'
																									: ''
																							}
																						>
																							<header
																								style={{
																									minWidth: 200,
																									maxWidth: 200
																								}}
																							>
																								{this.state.displayMode && (
																									<span>
																										{this.getResourceName(
																											resource.resourceId
																										)}
																									</span>
																								)}
																								{!this.state.displayMode && (
																									<a>
																										{this.getResourceName(
																											resource.resourceId
																										)}
																									</a>
																								)}
																							</header>
																							<section className="d-flex justify-content-start flex-fill">
																								<Badge
																									bg="light"
																									text="dark"
																									style={{
																										visibility:
																											resource.isDefault === 0
																												? 'hidden'
																												: undefined
																									}}
																								>
																									Default
																								</Badge>
																								<Badge
																									bg="light"
																									text="dark"
																									className="ms-3"
																									style={{
																										visibility:
																											resource.isRequired === 0
																												? 'hidden'
																												: undefined
																									}}
																								>
																									Required
																								</Badge>
																							</section>
																							{!this.state.displayMode && (
																								<Button
																									type="button"
																									size="sm"
																									variant="white"
																									className="py-0 ms-5"
																									onClick={(evt) =>
																										this.deleteFlowStepResource(
																											evt,
																											resource
																										)
																									}
																								>
																									<i className="fas fa-fw fa-times" />
																								</Button>
																							)}
																						</ListGroup.Item>
																					)
																				)}
																			</ListGroup>
																		</Form.Group>
																	)}
																	{!this.state.displayMode && (
																		<Form.Group className="mb-3">
																			<Button
																				type="button"
																				variant="white-alt"
																				onClick={() =>
																					this.addResourceTo(flowStep)
																				}
																			>
																				Add Resource
																			</Button>
																		</Form.Group>
																	)}
																</Col>
															</Row>
														</section>
													</section>
												)
											)}
										</section>
									</section>
								);
							}
						)}
					</section>
				</RtUiWideForm>
				<Aside
					isOpen={Boolean(this.state.editing)}
					disabledBody
					onClickOutside={() => this.setState({ editing: undefined })}
				>
					<Aside.Header
						warning
						header="Flow Step Resource Edit"
						onClose={() => this.setState({ editing: undefined })}
					/>
					{this.state.editing && (
						<Card.Body>
							<FlowStepResourceForm
								key={this.state.editing.resource.flowStepResourceId}
								value={this.state.editing.resource}
								onChange={(propertyKey, propertyValue) =>
									this.updateEditingResourceProp(propertyKey, propertyValue)
								}
							/>
							<footer>
								<Button
									type="button"
									variant="white-alt"
									onClick={() => this.setState({ editing: undefined })}
								>
									Okay, close
								</Button>
							</footer>
						</Card.Body>
					)}
				</Aside>
			</section>
		);
	}
}
