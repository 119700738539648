/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TimeFilter = {
    properties: {
        timeObject: {
            type: 'TimeRange',
            isRequired: true,
        },
        detailLevel: {
            type: 'TimeFilterDetail',
            isRequired: true,
        },
    },
};