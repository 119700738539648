/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationMappingIndexResponse = {
    properties: {
        guardianReconciliationMappingId: {
            type: 'number',
            isRequired: true,
        },
        reconciliationTypeId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedBy: {
            type: 'number',
            isRequired: true,
        },
        carrierBanNumber: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        recurringScheduleType: {
            type: 'GuardianReconciliationMappingRecurringScheduleType',
            isRequired: true,
            isNullable: true,
        },
    },
};