import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ServiceResource } from 'RtUi/app/rtSip/Services/lib/resources/ServiceResource';
import { ServiceIndexResponse } from 'RtModels';

interface IServiceSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<ServiceIndexResponse, IsMulti> {
	showAllOption?: boolean;
}

export class ServiceSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	ServiceIndexResponse,
	IsMulti,
	IServiceSelectProps<IsMulti>
> {
	public resourceClass = ServiceResource;
	public state: ISelectFormControlState<ServiceIndexResponse> = {
		formLabel: 'Service',
		valueKey: 'serviceId',
		labelKey: 'label'
	};

	/**
	 * @override
	 */
	public getData() {
		return super.getData().then((records) => {
			if (this.props.showAllOption) {
				records.splice(0, 0, {
					serviceId: 0,
					label: 'All',
					hasIngressConnections: 0,
					hasEgressConnections: 0,
					isCustomerService: 0,
					isVendorService: 0
				});
			}

			return records;
		});
	}
}
