/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrCarrierRegionUpdateRequest = {
    properties: {
        isActive: {
            type: 'number',
            isRequired: true,
        },
        allowAsDefault: {
            type: 'number',
            isRequired: true,
        },
        defaultInterRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIntraRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIndetRate: {
            type: 'number',
            isRequired: true,
        },
    },
};