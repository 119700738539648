/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TollfreeEnhancedSummaryIndexResponse = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        calculatedTemplate: {
            type: 'string',
            isRequired: true,
        },
        calculatedResporg: {
            type: 'string',
            isRequired: true,
        },
        originalMargin: {
            type: 'number',
            isRequired: true,
        },
        calculatedMargin: {
            type: 'number',
            isRequired: true,
        },
        calculatedCostDurationMs: {
            type: 'number',
            isRequired: true,
        },
        connect: {
            type: 'number',
            isRequired: true,
        },
        originalRevenue: {
            type: 'number',
            isRequired: true,
        },
        originalRevenueDurationMs: {
            type: 'number',
            isRequired: true,
        },
        originalCost: {
            type: 'number',
            isRequired: true,
        },
        originalCostDurationMs: {
            type: 'number',
            isRequired: true,
        },
        calculatedCost: {
            type: 'number',
            isRequired: true,
        },
        ingressToStd: {
            type: 'string',
        },
        ingressFrDialCode: {
            type: 'string',
        },
        ingressTrunkGroup: {
            type: 'string',
        },
        egressTrunkGroup: {
            type: 'string',
        },
        fileStreamId: {
            type: 'number',
        },
        calculatedCic: {
            type: 'string',
            isRequired: true,
        },
        ocn: {
            type: 'string',
        },
        calculatedLossCount: {
            type: 'number',
        },
        calculatedLossMargin: {
            type: 'number',
        },
        aloc: {
            type: 'number',
        },
    },
};