import {
	DataGridRowInstance,
	DataGridTableInstance,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import { getCprLabelsColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/cpr';
import {
	getExportColumns,
	getExportValue
} from 'RtUi/components/data/DataGrid/utils';
import { Cpr, CprLbl } from 'Somos/lib/SomosCpr/RtCprV2';
import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState
} from 'react';
import { Alert } from 'react-bootstrap';
import { PaginationState } from '@tanstack/react-table';
import { ILabelsLAD } from 'RtUi/app/rt800/Cprs/lib/util/Constants';
import DataGrid from 'RtUi/components/data/DataGrid';

interface ICprLabelDataGridProps {
	displayMode: boolean;
	cpr: Cpr;
}

export interface ICprLabelDataGridRef {
	data: ILabelsLAD[];
	pageToIndex: (index: number) => void;
	pageSize: number;
	updatePage: (page: number, pageSize?: number) => void;
}

export const CprLabelDataGrid = forwardRef<
	ICprLabelDataGridRef,
	ICprLabelDataGridProps
>(({ cpr, displayMode }, ref) => {
	const [cprLabels, setCprLabels] = useState<CprLbl[]>([...cpr.getCprLabels()]);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10
	});
	const tableRef = useRef<DataGridTableInstance<CprLbl>>(null);

	const pageToIndex = (index: number) => {
		setPagination((currentState) => {
			const { pageSize } = currentState;
			const pageIndex = Math.floor(index / pageSize);

			return {
				pageIndex,
				pageSize
			};
		});
	};

	const updatePage = (pageIndex: number, newPageSize?: number) => {
		setPagination((currentState) => {
			const { pageSize } = currentState;

			return {
				pageIndex,
				pageSize: newPageSize ?? pageSize
			};
		});
	};

	useEffect(() => {
		return cpr.onValidate(() => {
			const newLabels = [...cpr.getCprLabels()];
			setCprLabels(newLabels);
		});
	});

	useImperativeHandle(ref, () => {
		const data =
			tableRef.current
				?.getPrePaginationRowModel()
				.rows.map((r) => r.original as CprLbl) ?? [];

		const columns = getExportColumns(
			tableRef.current?.getAllColumns() ?? [],
			tableRef.current?.getState().columnOrder ?? []
		);

		const state = tableRef.current?.getState();

		return {
			pageToIndex,
			data: getExportValue(data, columns),
			pageSize: state?.pagination.pageSize ?? 0,
			updatePage
		};
	});

	const updateLabels = useCallback(() => {
		const newLabels = cpr.getCprLabels() ?? [];
		setCprLabels(newLabels);
	}, [cpr]);

	const onDragEnd = (
		draggingRow: DataGridRowInstance<CprLbl>,
		hoveredRow: DataGridRowInstance<CprLbl>
	) => {
		cpr.moveCprLabel(draggingRow.index, hoveredRow.index);
	};

	const columns = useMemo(
		() => getCprLabelsColumns(cpr, displayMode, updateLabels),
		[cpr, displayMode, updateLabels]
	);

	if (!cpr) {
		return null;
	}

	return (
		<DataGrid<CprLbl>
			ref={tableRef}
			pageName="rt800_cprLabels"
			data={cprLabels}
			columns={columns}
			pagination={pagination}
			setPagination={setPagination}
			disableExport={!displayMode}
			disableControls={!displayMode}
			enableSorting={displayMode}
			enableRowOrdering={!displayMode}
			onRowDragEnd={onDragEnd}
			getRowId={(row) => row.uuid}
			rowThemeColor={(label: CprLbl) => {
				if (label.cpr.isSelectedHighlightedLabel(label)) {
					return RowThemeColor.WARNING;
				}

				if (label.isHighlighted()) {
					return RowThemeColor.LIGHT;
				}

				if (label.isNew()) {
					return RowThemeColor.SUCCESS;
				}
			}}
			detailPanel={(label: CprLbl) => {
				const cleanData = label.getAggregatedCleanData();

				if (displayMode || !cleanData) {
					return null;
				}

				const currentValues = label.getRawValues();

				return (
					<Alert
						variant="warning"
						transition={false}
						className="d-flex justify-content-start mb-0"
					>
						<section className="align-self-center me-3">
							<i className="fas fa-lg fa-exclamation-triangle" />
						</section>
						<section>{}</section>
						{!displayMode && (
							<>
								{cleanData.valuesToAdd && cleanData.valuesToAdd.length > 0 && (
									<span>
										<span>Missing values detected.</span>
										<a
											className="ms-2"
											onClick={() => {
												label.setValues([
													...currentValues,
													...cleanData.valuesToAdd!
												]);

												updateLabels();
											}}
										>
											Click here to add {cleanData.valuesToAdd.join(', ')}.
										</a>
									</span>
								)}
								{cleanData.valuesToRemove &&
									cleanData.valuesToRemove.length > 0 && (
										<span>
											<span>Invalid values detected. </span>
											<a
												className="ms-2"
												onClick={() => {
													const newValuesSet = new Set<string>(currentValues);

													for (const valueToRemove of cleanData.valuesToRemove!) {
														newValuesSet.delete(valueToRemove);
													}

													label.setValues(Array.from(newValuesSet));

													updateLabels();
												}}
											>
												Click here to remove{' '}
												{cleanData.valuesToRemove.join(', ')}.
											</a>
										</span>
									)}
							</>
						)}
					</Alert>
				);
			}}
		/>
	);
});
