import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { DidApplicationRouter } from 'RtUi/app/rtDid/rtDid.router';
import {
	IDidNumberIndex,
	IDidNumberProfile,
	DidNumbersResource
} from 'RtUi/app/rtDid/Numbers/lib/resources/DidNumbersResource';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';
import { RtxDidApiRoutes } from 'RtExports/routes';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';

interface IDidNumberProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const DidNumberProfileContainerTabs: IDidNumberProfileContainerTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class DidNumberRouterClass extends DidApplicationRouter<
	IDidNumberIndex,
	IDidNumberProfile,
	IDidNumberProfileContainerTabs
> {
	private phoneFormatter = new PhoneNumberFormatter();

	constructor() {
		super('Number', 'numbers', 'numberId', DidNumberProfileContainerTabs);

		this.setPermissionsFromApiRoute(RtxDidApiRoutes.Numbers);
	}

	/**
	 * Search for DID Numbers
	 *
	 * @param search
	 */
	public async globalSearch(search: string) {
		search = PhoneNumberFormatter.convertLettersToNumbers(search);
		search = search.replace(/[^\d]/g, ''); //remove non-digits
		const isCorrectLength = search.length === 10 || search.length === 11;

		if (!isCorrectLength) {
			return [];
		}

		return this.defaultGlobalSearch(DidNumbersResource, search, 10);
	}

	public getIndexLabel(record: IDidNumberIndex) {
		return this.getLabel(record);
	}

	public getProfileLabel(profile: IDidNumberProfile) {
		return this.getLabel(profile);
	}

	/**
	 * Get Label for IDidNumberIndex or IDidNumberProfile
	 * @param record
	 */
	public getLabel(record: IDidNumberIndex | IDidNumberProfile) {
		let { label } = record;

		if (record.number) {
			const internationalNumber = this.phoneFormatter.toInternational(
				record.number
			);

			if (internationalNumber) {
				//If label is empty
				if (!label) {
					label = internationalNumber;
				} else {
					label = `${label} (${internationalNumber})`;
				}
			}
		}

		return label;
	}
}

export const DidNumberRouter = new DidNumberRouterClass();
