import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	InvoiceIndexRequest,
	InvoiceIndexResponse,
	InvoiceSubscription,
	InvoiceSubscriptionIndexUrlRequest,
	InvoiceSubscriptionProfileResponse,
	InvoiceSubscriptionProfileUrlRequest
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchInvoices = async (
	queryParams?: InvoiceIndexRequest
): Promise<FullResponse<InvoiceIndexResponse[]>> => {
	return handleGetRequest(RtxSipApiRoutes.Invoices.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetInvoices = (queryParams?: InvoiceIndexRequest) => {
	return useQuery<FullResponse<InvoiceIndexResponse[]>, Error>(
		[`getInvoices`, queryParams],
		() => fetchInvoices(queryParams),
		{ enabled: true }
	);
};

const fetchInvoiceSubscriptions = async (
	urlParams?: InvoiceSubscriptionIndexUrlRequest
): Promise<FullResponse<InvoiceSubscription[]>> => {
	return handleGetRequest(RtxSipApiRoutes.InvoiceSubscriptions.Index, {
		urlParams,
		includeFullResponse: true
	});
};

export const useGetInvoiceSubscriptions = (
	urlParams?: InvoiceSubscriptionIndexUrlRequest
) => {
	return useQuery<FullResponse<InvoiceSubscription[]>, Error>(
		[`getInvoiceSubscriptions`, urlParams],
		() => fetchInvoiceSubscriptions(urlParams),
		{ enabled: true }
	);
};

const fetchInvoiceSubscriptionDetails = async (
	urlParams?: InvoiceSubscriptionProfileUrlRequest
): Promise<FullResponse<InvoiceSubscriptionProfileResponse[]>> => {
	return handleGetRequest(RtxSipApiRoutes.InvoiceSubscriptions.Profile, {
		urlParams,
		includeFullResponse: true
	});
};

export const useGetInvoiceSubscriptionDetails = (
	urlParams?: InvoiceSubscriptionProfileUrlRequest
) => {
	return useQuery<FullResponse<InvoiceSubscriptionProfileResponse[]>, Error>(
		[`getInvoiceSubscriptionDetails`, urlParams],
		() => fetchInvoiceSubscriptionDetails(urlParams),
		{ enabled: true }
	);
};
