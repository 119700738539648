import {
	ConnectionIndexResponse,
	DataFilter,
	DataFilterOperator,
	DataPreset,
	DataSources,
	FileStreamIndexResponse,
	GuardianUserQueryIndexResponse,
	HavingFilter,
	PartitionIndexResponse,
	ReportAggregates,
	ReportMetadataProfileResponse,
	Reports,
	RtVueIndexRequest,
	SearchField,
	SubscriptionIndexResponse,
	TimeFilter,
	TimeFilterDetailKeys,
	TimeRange,
	TimeRangePresets,
	UserPageConfigurationParametersSortingOrder
} from 'RtModels';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { PartitionSelect } from 'RtUi/app/rtAdmin/lib/controls/PartitionSelect';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import { TrunkGroupSelect } from 'RtUi/app/rtVue/Reconciliation/lib/components/TrunkGroupSelect';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import { DataSourceSelector } from 'RtUi/app/rtVue/common/lib/components/DataSourceSelector/DataSourceSelector';
import {
	GetTimeFilterDetailsForPresetHours,
	ITimeFilterDetailLevelsHours,
	TimeRangePresetsToNameMapping,
	convertDateToUTC,
	createDateAsUTC
} from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { FlatDateRangePicker } from 'RtUi/app/rtVue/common/lib/components/FlatDateRangePicker/FlatDateRangePicker';
import { VueAdvancedSearchFilters } from 'RtUi/app/rtVue/common/lib/components/VueAdvancedSearchFilters';
import { VUE_ALL_COLUMNS_LABEL } from 'RtUi/app/rtVue/common/lib/components/VueAllColumnsLabel';
import { VueFilterSearchPanel } from 'RtUi/app/rtVue/common/lib/components/VueFilterSearchPanel';
import { VuePartitionSelect } from 'RtUi/app/rtVue/common/lib/components/VuePartitionSelect';
import { VueReportAggregates } from 'RtUi/app/rtVue/common/lib/components/VueReportAggregates';
import { VueSavedQuerySelect } from 'RtUi/app/rtVue/common/lib/components/VueSavedQuerySelect';
import { VueSelectAdditionalColumns } from 'RtUi/app/rtVue/common/lib/components/VueSelectAdditionalColumns';
import { VueStandardFilters } from 'RtUi/app/rtVue/common/lib/components/VueStandardFilters';
import { RtVueOperatorColors } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueOperatorColors';
import { RtVueReportUrlSearchParams } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueReportUrlSearchParams';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	ApplicationContainer,
	IApplicationContainerProps
} from 'RtUi/components/containers/ApplicationContainer';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { RtError } from 'RtUi/utils/errors/RtError';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import { capitalCase } from 'change-case';
import clsx from 'clsx';
import { endOfDay } from 'date-fns';
import {
	cloneDeep,
	debounce,
	find,
	isEmpty,
	uniqBy,
	upperFirst
} from 'lodash-es';
import * as moment from 'moment-timezone';
import { FC } from 'react';
import {
	Alert,
	Button,
	Col,
	Form,
	OverlayTrigger,
	Popover,
	Row,
	Table
} from 'react-bootstrap';

const revenueCostHashMap = new Map<number, string>([
	[1, 'customer'],
	[0, 'vendor']
]);

const EXTERNAL_ACCOUNT_ID_1 = 'ExternalAccountId1';
const EXTERNAL_ACCOUNT_ID_2 = 'ExternalAccountId2';
const EXTERNAL_SUBSCRIPTION_ID_1 = 'ExternalSubscriptionId1';
const EXTERNAL_SUBSCRIPTION_ID_2 = 'ExternalSubscriptionId2';

export type PossibleFilterNames =
	| 'fileStreamId'
	| 'fileStreamId1'
	| 'fileStreamId2'
	| 'trunkGroup1'
	| 'trunkGroup2'
	| 'customerExternalAccountId1'
	| 'customerExternalAccountId2'
	| 'vendorExternalAccountId1'
	| 'vendorExternalAccountId2'
	| 'customerExternalSubscriptionId1'
	| 'customerExternalSubscriptionId2'
	| 'vendorExternalSubscriptionId1'
	| 'vendorExternalSubscriptionId2'
	| 'dataDomains';

const HelpIconComponent: FC<
	React.PropsWithChildren<
		{
			searchFields: SearchField[];
		} & React.HTMLAttributes<HTMLDivElement>
	>
> = ({ searchFields, className }) => {
	return (
		<div className={clsx(className)}>
			<OverlayTrigger
				trigger={['hover', 'focus']}
				placement="right"
				overlay={
					<Popover id="vue-search-results-popover">
						<Popover.Header>Possible search results:</Popover.Header>
						<Popover.Body>
							<Table size="sm">
								<thead>
									<tr>
										<th>Entity</th>
										<th>Minimum Letters to Search</th>
									</tr>
								</thead>
								<tbody>
									{uniqBy(searchFields, 'label')
										.filter((sf) => !sf.hiddenFromUser)
										.sort((searchField1, searchField2) =>
											searchField1.label.localeCompare(searchField2.label)
										)
										.map((searchField) => (
											<tr key={searchField.key}>
												<td>{searchField.label}</td>
												<td>{searchField.minCharacters}</td>
											</tr>
										))}
								</tbody>
							</Table>
						</Popover.Body>
					</Popover>
				}
			>
				{({ ref, ...triggerHandler }) => (
					<Button
						ref={ref}
						{...triggerHandler}
						id="PopoverHelpSearch"
						type="button"
						variant="link"
						style={{ padding: 0 }}
					>
						<i className="far fa-fw fa-question-circle me-1" />
					</Button>
				)}
			</OverlayTrigger>
		</div>
	);
};

export type PossibleAccessorKeys =
	| keyof FileStreamIndexResponse
	| keyof ConnectionIndexResponse
	| keyof SubscriptionIndexResponse
	| string;

export type AdvancedFieldValues = {
	[index: string]: {
		value?: string;
		select?: ISimpleSelectFormControlOption<any>;
	};
};

export interface IRtVueReportDataGridProps {
	onSortingChange: (
		newSorted: UserPageConfigurationParametersSortingOrder[]
	) => void;
	defaultSorting: UserPageConfigurationParametersSortingOrder[];
}

export interface IDefaultTimeRangeConfig {
	defaultDetailLevel?: TimeFilterDetailKeys;
	defaultTimeRangePreset?: TimeRangePresets;
	timeObject?: TimeRange;
}

export interface IDropdown {
	value: string;
	label: string;
}
export interface IRtVueContainerState<T> {
	resource: HttpResource<T> | null;
	areDataFiltersDirty: boolean;
	filters: DataFilter[];
	timeRange: TimeFilter;
	filterBoxKey: string;
	compareToDate?: Date;
	preset?: DataPreset;
	dataSums?: ReportAggregates[];
	reportMetadata?: ReportMetadataProfileResponse;
	partition?: PartitionIndexResponse | undefined;
	fileStreamId?: FileStreamIndexResponse[] | undefined;
	fileStreamId1?: FileStreamIndexResponse[] | undefined;
	fileStreamId2?: FileStreamIndexResponse[] | undefined;
	reportMetadataErrors?: RtError;
	reportErrors?: RtError;
	userQuery?: GuardianUserQueryIndexResponse;
	allColumns: Array<ISimpleSelectFormControlOption<string>>;
	defaultSorting: UserPageConfigurationParametersSortingOrder[];
	additionalColumns: Array<ISimpleSelectFormControlOption<string>>;
	timeRangePresetsKeys: string[];
	noCustomFocusOnInit?: boolean;
	timeRangeDetailsLevels?: ITimeFilterDetailLevelsHours[];
	maxDate?: Date;
	minDate?: Date;
	hideFileStreamSelect?: boolean;
	havingFilter?: HavingFilter[];
	hidePartitionSelect?: boolean;
	showTwoFileStreamSelects?: boolean;
	showTrunkGroups?: boolean;
	trunkGroup1?: ConnectionIndexResponse[];
	trunkGroup2?: ConnectionIndexResponse[];
	advancedFieldValues: AdvancedFieldValues;
	hideDetailsOption?: boolean;
	timeRangeAvailableDetails?: string[];
	isSourceIngress?: number;
	isTargetIngress?: number;
	showTrunkGroupIngressEgressOptions?: boolean;
	externalAccountId1?: string;
	externalAccountId1Radio?: number | null;
	externalSubscription1?: number | SubscriptionIndexResponse;
	externalSubscription1Radio?: number | null;
	externalAccountId2?: string;
	externalAccountId2Radio?: number | null;
	externalSubscription2?: number | SubscriptionIndexResponse;
	externalSubscription2Radio?: number | null;
	customerExternalAccountId1?: string;
	customerExternalAccountId2?: string;
	vendorExternalAccountId1?: string;
	vendorExternalAccountId2?: string;
	customerExternalSubscriptionId1?: number;
	customerExternalSubscriptionId2?: number;
	vendorExternalSubscriptionId1?: number;
	vendorExternalSubscriptionId2?: number;
	reportDomainsValues?: IDropdown[] | [];
	updateDetailOnDateChange?: boolean;
	dataDomains?: string[];
	loadingReport: boolean;
}

/**
 * RtVueReportContainer is the base container to render RtVue Reports
 */
export abstract class RtVueReportContainer<
	T,
	S extends IRtVueContainerState<T> = IRtVueContainerState<T>
> extends ApplicationContainer<IApplicationContainerProps, S> {
	protected static readonly DefaultTimeRangePreset = TimeRangePresets.OneHour;
	private static LastUsedPartition: PartitionIndexResponse | undefined;

	public abstract state: S;

	protected abstract reportId: Reports;
	protected abstract router: RtVueModuleRouter<any, any, any>;

	protected readonly fileStreamKey = 'fileStreamId';
	protected readonly fileStreamKey1 = 'fileStreamId1';
	protected readonly fileStreamKey2 = 'fileStreamId2';
	protected readonly trunkGroupKey1 = 'trunkGroup1';
	protected readonly trunkGroupKey2 = 'trunkGroup2';
	protected isApplyingUserQuery = false;
	protected urlSearchParams = new RtVueReportUrlSearchParams(window.location);

	/**
	 * Debounce calls for half a second
	 */
	private updateReportsDebounce = debounce(
		() => this.updateReportsInternal(),
		500
	);

	/**
	 * Used to make sure the last report is the one rendered
	 */
	private currentReportUuid = generateUUID();

	public componentDidMount() {
		this.loadDataSources();
		this.updateReports();
	}

	/**
	 * get given filter key from filters
	 * @param {keyof RtVueIndexRequest} key
	 * @returns {string | undefined}
	 */
	public getInitialQueryParam(key: keyof RtVueIndexRequest) {
		const filters = this.state.userQuery?.queryParameters[key];
		if (!filters) return undefined;

		return filters.toString();
	}

	/**
	 * getting "aggregates" from URL params
	 * @returns {string[] | undefined}
	 */
	public getDataSums(): ReportAggregates[] | undefined {
		if (this.state.dataSums) {
			return this.state.dataSums;
		}

		if (this.urlSearchParams.has('aggregates')) {
			return this.urlSearchParams.getAggregates()!;
		}

		return undefined;
	}

	/**
	 * Load report metadata for DataSources and SearchFields
	 */
	public async loadDataSources() {
		try {
			this.setState({ reportMetadataErrors: undefined });

			const vueHttp = new RtVueHttp();
			const reportMetadata = await vueHttp.getReportMetadata(this.reportId);
			const { reportDomains } = reportMetadata;
			if (!isEmpty(reportDomains)) {
				const formattedReportDomains = reportDomains.map((report) => {
					return { value: report, label: upperFirst(report) };
				});
				this.setState({ reportDomainsValues: formattedReportDomains });
			}
			const allColumns = reportMetadata?.allColumns?.map((column) => ({
				label: VUE_ALL_COLUMNS_LABEL.has(column)
					? VUE_ALL_COLUMNS_LABEL.get(column)!
					: this.columnNameToReadable(column),
				value: column
			}));
			const additionalColumns = this.getAdditionalColumns(allColumns);
			const preset = this.getPreset(reportMetadata);
			const dataSums = this.getDataSums();
			this.setState({
				reportMetadata,
				allColumns,
				additionalColumns,
				preset,
				dataSums
			});
		} catch (reportMetadataErrors: any) {
			this.setState({ reportMetadataErrors });
		}
	}

	public getPartition() {
		if (this.urlSearchParams.has('partition')) {
			return this.urlSearchParams.get('partition')!;
		}
		return this.getInitialQueryParam('customerTestingOverride');
	}

	/**
	 * parsing URL to set additionalColumns data
	 * @param {Array<ISimpleSelectFormControlOption<string>>} allColumns
	 * @returns {any[] | ISimpleSelectFormControlOption<string>[]}
	 */
	public getAdditionalColumns(
		allColumns?: Array<ISimpleSelectFormControlOption<string>>
	) {
		if (!Array.isArray(allColumns)) {
			return [];
		}

		if (this.state.userQuery?.queryParameters.dataColumns) {
			return allColumns.filter(({ value }) =>
				this.state.userQuery?.queryParameters.dataColumns?.some(
					(dataColumn) => value === dataColumn
				)
			);
		}

		if (!this.urlSearchParams.has('additionalColumns')) {
			return [];
		}
		const additionalColumns = this.urlSearchParams.getAdditionalColumns()!;
		const foundColumns: Array<ISimpleSelectFormControlOption<string>> = [];

		for (const additionalColumn of additionalColumns) {
			const foundColumn = allColumns.find(
				({ value }) => value === additionalColumn
			);

			if (foundColumn) {
				foundColumns.push(foundColumn);
			}
		}

		return foundColumns;
	}

	/**
	 * parsing URL to set filters data
	 * hardcoded for now - some universal mechanism will be needed
	 * when more filters will be applied
	 */
	public getFilters() {
		const filters: DataFilter[] = [];

		//special cases from 'getDrillDownPath'
		if (this.urlSearchParams.has('srcReconciliation')) {
			filters.push({
				key: 'srcReconciliation',
				operands: [
					{
						dataSource: DataSources.Text,
						value: this.urlSearchParams.get('srcReconciliation')!
					}
				],
				operator: DataFilterOperator.Subselect
			});
		}

		if (this.urlSearchParams.has('tgtReconciliation')) {
			filters.push({
				key: 'tgtReconciliation',
				operands: [
					{
						dataSource: DataSources.Text,
						value: this.urlSearchParams.get('tgtReconciliation')!
					}
				],
				operator: DataFilterOperator.Subselect
			});
		}

		if (this.urlSearchParams.has('dialedNumber')) {
			filters.push({
				key: 'to_raw',
				operands: [
					{
						dataSource: DataSources.Text,
						value: this.urlSearchParams.get('dialedNumber')!
					}
				],
				operator: DataFilterOperator.Begins
			});
		}

		if (this.urlSearchParams.has('ingressTrunkGroupId')) {
			filters.push({
				key: 'ingress_trunk_group_id',
				operands: [
					{
						dataSource: DataSources.Text,
						value: this.urlSearchParams.get('ingressTrunkGroupId')!
					}
				],
				operator: DataFilterOperator.In
			});
		}

		if (this.urlSearchParams.has('egressTrunkGroupId')) {
			filters.push({
				key: 'egress_trunk_group_id',
				operands: [
					{
						dataSource: DataSources.Text,
						value: decodeURIComponent(
							this.getSearchParam('egressTrunkGroupId')!
						)
					}
				],
				operator: DataFilterOperator.In
			});
		}
		if (this.urlSearchParams.has('template')) {
			filters.push({
				key: 'calculatedTemplate',
				operands: [
					{
						dataSource: DataSources.Text,
						value: decodeURIComponent(this.getSearchParam('template')!)
					}
				],
				operator: DataFilterOperator.In
			});
		}

		if (this.urlSearchParams.has('filter')) {
			const filtersInSearch = this.urlSearchParams.getFilters() ?? [];

			filters.push(...filtersInSearch);
		}

		if (this.urlSearchParams.has('fileStreamId')) {
			const fileStream = this.getSearchParam('fileStreamId')!.split('+');
			const fileStreamFilter: DataFilter = {
				key: 'fileStreamId',
				operator: DataFilterOperator.In,
				operands: fileStream.map((fs) => ({
					dataSource: DataSources.Text,
					value: fs
				}))
			};

			filters.push(fileStreamFilter);
		}

		if (this.urlSearchParams.has('dataDomains')) {
			const fileStream = this.getSearchParam('dataDomains')!.split('+');
			const fileStreamFilter: DataFilter = {
				key: 'dataDomains',
				operator: DataFilterOperator.In,
				operands: fileStream.map((fs) => ({
					dataSource: DataSources.Text,
					value: fs
				}))
			};

			filters.push(fileStreamFilter);
		}

		return filters;
	}

	public getPreset(reportMetadata: ReportMetadataProfileResponse) {
		if (reportMetadata && reportMetadata.presets) {
			if (this.urlSearchParams.has('preset')) {
				const reportFromUrl = this.urlSearchParams.getPreset()!;

				return reportMetadata.presets.find(
					(report) => report.reportPreset === reportFromUrl
				);
			}
		}

		return undefined;
	}

	public getTimeRange(defaultTimeRangeConfig: IDefaultTimeRangeConfig = {}) {
		const {
			defaultTimeRangePreset = RtVueReportContainer.DefaultTimeRangePreset,
			defaultDetailLevel,
			timeObject
		} = defaultTimeRangeConfig;

		const defaultTimeFilterDetailLevels = GetTimeFilterDetailsForPresetHours(
			defaultTimeRangePreset,
			this.shouldIncludeMonthly()
		);

		const defaultTimeFilterDetail = this.getDefaultDetailLevel(
			defaultTimeFilterDetailLevels,
			defaultDetailLevel
		);

		let start =
			timeObject?.start ??
			moment()
				.subtract(defaultTimeFilterDetail.value, defaultTimeFilterDetail.key)
				.tz('UTC')
				.toDate();
		let end = timeObject?.end ?? moment().tz('UTC').toDate();
		let mode = timeObject?.mode ?? defaultTimeRangePreset;

		if (
			['birdeye', 'fourteenday', 'asrLite'].some((keyword) =>
				window.location.pathname.includes(keyword)
			)
		) {
			end = moment().subtract(1, 'days').tz('UTC').toDate();
			start = moment().subtract(337, 'h').tz('UTC').toDate();
			mode = TimeRangePresets.Custom;
		}

		// for applying drill down form other pages
		if (this.urlSearchParams.has('date')) {
			// no conversion toUTC is needed here, it will be done in rtVueHttp
			const dateIso = this.urlSearchParams.get('date')!;

			start = convertDateToUTC(dateIso);
			end = endOfDay(start);
			mode = TimeRangePresets.Custom;
		}

		if (this.urlSearchParams.has('timeRange')) {
			const dateFromURL = this.urlSearchParams.getTimeRange()!;
			const timeObject: TimeRange = dateFromURL.timeObject;
			if (typeof timeObject.end === 'string') {
				timeObject.end = convertDateToUTC(timeObject.end);
			}
			if (typeof timeObject.start === 'string') {
				timeObject.start = convertDateToUTC(timeObject.start);
			}

			return dateFromURL;
		}

		return {
			timeObject: {
				mode,
				start,
				end
			},
			detailLevel: defaultTimeFilterDetail
		};
	}

	public getDefaultDetailLevel(
		defaultTimeFilterDetailLevels: ITimeFilterDetailLevelsHours[],
		defaultDetailLevel?: TimeFilterDetailKeys
	) {
		const defaultDetailLevelValue = [
			...defaultTimeFilterDetailLevels
		].pop() as ITimeFilterDetailLevelsHours;
		if (!defaultDetailLevel) {
			return defaultDetailLevelValue;
		}

		const defaultTimeDetailLevel = defaultTimeFilterDetailLevels.find(
			(detail) => detail.key === defaultDetailLevel
		);

		return defaultTimeDetailLevel ?? defaultDetailLevelValue;
	}

	/**
	 * Utility method to get the default IRtVueContainerState state
	 */
	public getBaseState(defaultTimeRangeConfig?: IDefaultTimeRangeConfig) {
		const baseState: IRtVueContainerState<T> = {
			resource: null,
			loadingReport: false,
			areDataFiltersDirty: false,
			noCustomFocusOnInit: false,
			filters: this.getFilters(),
			partition: RtVueReportContainer.LastUsedPartition,
			timeRange: this.getTimeRange(defaultTimeRangeConfig),
			additionalColumns: this.getAdditionalColumns(),
			timeRangePresetsKeys: Object.keys(TimeRangePresetsToNameMapping),
			allColumns: [],
			advancedFieldValues: {},
			filterBoxKey: generateUUID(),
			updateDetailOnDateChange: true,
			defaultSorting: []
		};

		return baseState;
	}

	/**
	 * Updates the report preset
	 * @param preset
	 * @param triggerReportUpdate
	 */
	public updatePreset(
		preset: DataPreset | undefined,
		triggerReportUpdate = false
	) {
		if (!this.isApplyingUserQuery) {
			this.setState({ userQuery: undefined });
		}

		const presetValue = preset?.reportPreset ? [preset.reportPreset] : [];
		this.urlSearchParams.setWithArray('preset', presetValue);
		this.updateLocationWithParams(this.urlSearchParams.toString());
		this.setState({ preset }, () => {
			if (triggerReportUpdate) {
				this.updateReports();
			}
		});
	}

	public updateAdditionalColumns(
		additionalColumns: Array<ISimpleSelectFormControlOption<string>>
	) {
		const urlParams = additionalColumns.map((col) => col.value);
		this.urlSearchParams.setWithArray('additionalColumns', urlParams);
		this.updateLocationWithParams(this.urlSearchParams.toString());

		if (!this.isApplyingUserQuery) {
			this.setState({ userQuery: undefined });
		}

		this.setState({ additionalColumns, areDataFiltersDirty: true });
	}

	/**
	 * Updates the report dataSums
	 * @param dataSums
	 * @param triggerReportUpdate
	 */
	public updateDataSums(
		dataSums: ReportAggregates[] | undefined,
		triggerReportUpdate = false
	) {
		if (!this.isApplyingUserQuery) {
			this.setState({ userQuery: undefined });
		}

		this.urlSearchParams.setWithArray('aggregates', dataSums ?? []);
		this.updateLocationWithParams(this.urlSearchParams.toString());

		this.setState({ dataSums }, () => {
			if (triggerReportUpdate) {
				this.updateReports();
			}
		});
	}

	/**
	 * Update data filters based on search result
	 * @param searchResult
	 * @param triggerReportUpdate
	 */
	public updateFilters(filters: DataFilter[], triggerReportUpdate = false) {
		//Remove filter with no operands
		filters = filters.filter((v) => v.operands.length > 0);

		if (!this.isApplyingUserQuery) {
			this.setState({ userQuery: undefined });
		}

		this.urlSearchParams.setDataFilters(filters);
		this.updateLocationWithParams(this.urlSearchParams.toString());

		this.setState({ filters, areDataFiltersDirty: true }, () => {
			if (triggerReportUpdate) {
				this.updateReports();
			}
		});
	}

	/**
	 * Update Time Range and detail level
	 */
	public updateTimeRange(timeRange: TimeFilter) {
		if (!this.isApplyingUserQuery) {
			this.setState({ userQuery: undefined });
		}

		this.urlSearchParams.setTimeRange(timeRange);

		this.updateLocationWithParams(this.urlSearchParams.toString());

		this.setState({ timeRange, areDataFiltersDirty: true }, () => {
			this.storeTimeRange(timeRange);
		});
	}

	/**
	 * Update compare date and detail level
	 * @param compareToDate
	 * @param triggerReportUpdate
	 */
	public updateCompareToDate(
		compareToDate: Date | undefined,
		triggerReportUpdate = false
	) {
		if (!this.isApplyingUserQuery) {
			this.setState({ userQuery: undefined });
		}

		this.setState({ compareToDate }, () => {
			if (triggerReportUpdate) {
				this.updateReports();
			}
		});
	}

	/**
	 * Updates partition
	 * @param partition
	 * @param triggerReportUpdate
	 */
	public updatePartition(
		partition: PartitionIndexResponse | undefined,
		triggerReportUpdate = false
	) {
		const partitionPrefix = partition?.prefix
			? [String(partition?.prefix)]
			: [];

		this.urlSearchParams.setWithArray('partition', partitionPrefix);

		this.updateLocationWithParams(this.urlSearchParams.toString());

		this.setState({ partition }, () => {
			if (triggerReportUpdate) {
				this.updateReports();
			}
		});
	}

	public getSelectedValuesIds(
		selectedItems:
			| FileStreamIndexResponse[]
			| ConnectionIndexResponse[]
			| undefined
			| null
			| SubscriptionIndexResponse[]
			| string[]
			| number[]
	): string[] {
		if (!selectedItems) {
			return [];
		}
		if (selectedItems.length > 0) {
			if ((selectedItems[0] as FileStreamIndexResponse).fileStreamId) {
				return (selectedItems as FileStreamIndexResponse[]).map((fs) =>
					String(fs.fileStreamId)
				);
			}
			if ((selectedItems[0] as ConnectionIndexResponse).connectionId) {
				return (selectedItems as ConnectionIndexResponse[]).map((c) =>
					String(c.connectionId)
				);
			}
		}
		if (
			(selectedItems.length && typeof selectedItems[0] === 'string') ||
			typeof selectedItems[0] === 'number'
		) {
			return selectedItems as string[];
		}
		return [];
	}

	/**
	 * Method for avoiding copy-paste for select boxes updates
	 * @param {FileStreamIndexResponse[] | ConnectionIndexResponse[] | undefined | null} selectedItems - selected in dropdowns data
	 * @param {string} filterName - property used in filters
	 * @param {string} accessor - field name of object to apply to filter
	 * @param {boolean} triggerReportUpdate - should trigger update immediately or not
	 */
	public genericSelectFilterUpdate(
		selectedItems:
			| FileStreamIndexResponse[]
			| ConnectionIndexResponse[]
			| undefined
			| null
			| SubscriptionIndexResponse[]
			| string[]
			| number[],
		filterName: PossibleFilterNames,
		accessor: PossibleAccessorKeys
	) {
		const { filters } = this.state;
		selectedItems = selectedItems === null ? undefined : selectedItems;

		// if no value set
		if (
			typeof selectedItems === 'undefined' ||
			selectedItems === null ||
			(Array.isArray(selectedItems) && selectedItems.length === 0)
		) {
			// finding already set filter...
			const selectedItemsFilterIndex = filters.findIndex(
				(filter) => filter.key === filterName
			);

			// ... and deleting it
			if (selectedItemsFilterIndex >= 0) {
				filters.splice(selectedItemsFilterIndex, 1);
			}
		} else {
			// if filter already set
			let fileStreamFilter = filters.find(
				(filter) => filter.key === filterName
			);

			//Create if not there
			if (!fileStreamFilter) {
				fileStreamFilter = {
					key: filterName,
					operator: DataFilterOperator.In,
					operands: []
				};

				filters.push(fileStreamFilter);
			}

			fileStreamFilter.operands = selectedItems.map(
				(
					item:
						| FileStreamIndexResponse
						| ConnectionIndexResponse
						| SubscriptionIndexResponse
						| string
						| number
				) => {
					return {
						dataSource: DataSources.Text,
						value:
							typeof item !== 'string' && typeof item !== 'number'
								? String(
										item[
											accessor as unknown as keyof (
												| FileStreamIndexResponse
												| ConnectionIndexResponse
												| SubscriptionIndexResponse
												| string
											)
										]
									)
								: String(item)
					};
				}
			);
		}

		const selectedValues = this.getSelectedValuesIds(selectedItems);
		this.urlSearchParams.setWithArray(filterName, selectedValues);
		this.updateLocationWithParams(this.urlSearchParams.toString());

		if (!this.isApplyingUserQuery) {
			this.setState({ userQuery: undefined });
		}

		const userQuery = cloneDeep(this.state.userQuery);

		if (userQuery && userQuery.queryParameters) {
			userQuery.queryParameters.filters = filters;
		}

		this.setState(
			// @ts-expect-error explanation: implicit any because of []
			{
				userQuery,
				[filterName]: selectedItems,
				filters,
				areDataFiltersDirty: true
			}
		);
	}

	public getIdsFromFilter(filters: DataFilter[], filterKey: string) {
		const neededFilter = filters.find((f) => f.key === filterKey);
		let neededIds: string[] = [];

		if (neededFilter) {
			neededIds = neededFilter.operands.map((operand) => operand.value);
		}
		return neededIds;
	}

	/**
	 * Applies a saved user query
	 * @param userQuery
	 */
	public applyUserQuery(
		userQuery: GuardianUserQueryIndexResponse | undefined,
		mergeMode = false
	) {
		if (typeof userQuery === 'undefined' || userQuery === null) {
			this.setState({
				userQuery: undefined,
				filters: [],
				additionalColumns: [],
				dataSums: [],
				fileStreamId: []
			});
			return;
		}

		this.isApplyingUserQuery = true;

		const { queryParameters, queryIdentifier } = userQuery;

		const { dataSums, defaultSorted = [] } = queryParameters;
		const { reportMetadata } = this.state;
		const filters: DataFilter[] = queryParameters.filters
			? cloneDeep(queryParameters.filters)
			: [];
		let preset: DataPreset | undefined;

		if (queryParameters.presets && reportMetadata) {
			const queryPresets = queryParameters.presets[0];
			const presets = reportMetadata.presets.filter((preset) =>
				queryPresets.includes(preset.reportPreset)
			);

			if (presets.length > 0) {
				//We currently only allow for one preset
				preset = presets[0];
			}
		}

		const additionalColumns: Array<ISimpleSelectFormControlOption<string>> = [];

		if (queryParameters.dataColumns) {
			for (const dataColumn of queryParameters.dataColumns) {
				const foundColumn = this.state.allColumns.find(
					(ac) => ac.value === dataColumn
				);

				if (foundColumn) {
					additionalColumns.push(foundColumn);
				}
			}
		}

		let timeRange = queryParameters.timeRange;

		if (mergeMode) {
			//in mergeMode we disregard the timeRange in user query
			timeRange = this.state.timeRange;

			filters.push(
				...this.state.filters.filter((filter) =>
					filters.every((newFilter) => newFilter.key !== filter.key)
				)
			);

			additionalColumns.push(
				...this.state.additionalColumns.filter((column) =>
					additionalColumns.every(
						(newColumn) => newColumn.value !== column.value
					)
				)
			);
		}

		this.setState(
			{
				userQuery,
				timeRange,
				dataSums,
				filters,
				preset,
				additionalColumns,
				defaultSorting: defaultSorted,
				partition: undefined,
				fileStreamId: undefined,
				fileStreamId1: undefined,
				trunkGroup1: undefined,
				fileStreamId2: undefined,
				trunkGroup2: undefined,
				dataDomains: undefined,
				areDataFiltersDirty: true
			},
			() => {
				this.isApplyingUserQuery = false;
				const userQueryIdent = this.urlSearchParams.getUserQueryIdent();

				if (userQueryIdent && userQueryIdent === queryIdentifier) {
					this.updateReports();
				}
			}
		);
	}

	public handleChangeExternalAccountValueFirstGroup(val: string) {
		if (val) {
			this.setState({ externalAccountId1: val });
		} else {
			this.setState({ externalAccountId1: val });
			this.genericSelectFilterUpdate(
				null,
				`${revenueCostHashMap.get(
					this.state.externalAccountId1Radio as number
				)}${EXTERNAL_ACCOUNT_ID_1}` as any,
				`${revenueCostHashMap.get(
					this.state.externalAccountId1Radio as number
				)}${EXTERNAL_ACCOUNT_ID_1}` as any
			);
		}
		this.setState({ externalAccountId1Radio: null });
	}

	public handleChangeExternalAccountValueSecondGroup(val: string) {
		if (val) {
			this.setState({ externalAccountId2: val });
		} else {
			this.setState({ externalAccountId2: val });
			this.genericSelectFilterUpdate(
				null,
				`${revenueCostHashMap.get(
					this.state.externalAccountId2Radio as number
				)}${EXTERNAL_ACCOUNT_ID_2}` as any,
				`${revenueCostHashMap.get(
					this.state.externalAccountId2Radio as number
				)}${EXTERNAL_ACCOUNT_ID_2}` as any
			);
		}
		this.setState({ externalAccountId2Radio: null });
	}

	public handleChangeSubscriptionValueFirstGroup(
		val: SubscriptionIndexResponse
	) {
		if (val) {
			this.setState({ externalSubscription1: val });
		} else {
			this.setState({ externalSubscription1: val });
			this.genericSelectFilterUpdate(
				null,
				`${revenueCostHashMap.get(
					this.state.externalSubscription1Radio as number
				)}${EXTERNAL_SUBSCRIPTION_ID_1}` as any,
				`${revenueCostHashMap.get(
					this.state.externalSubscription1Radio as number
				)}${EXTERNAL_SUBSCRIPTION_ID_1}` as any
			);
			this.setState({ externalSubscription1Radio: null });
		}
	}

	public handleChangeSubscriptionValueSecondGroup(
		val: SubscriptionIndexResponse
	) {
		if (val) {
			this.setState({ externalSubscription2: val });
		} else {
			this.setState({ externalSubscription2: val });
			this.genericSelectFilterUpdate(
				null,
				`${revenueCostHashMap.get(
					this.state.externalSubscription2Radio as number
				)}${EXTERNAL_SUBSCRIPTION_ID_2}` as any,
				`${revenueCostHashMap.get(
					this.state.externalSubscription2Radio as number
				)}${EXTERNAL_SUBSCRIPTION_ID_1}` as any
			);
			this.setState({ externalSubscription2Radio: null });
		}
	}

	public handleRadioAccountGroup1(val: number) {
		this.genericSelectFilterUpdate(
			[this.state.externalAccountId1 as string],
			`${revenueCostHashMap.get(val)}${EXTERNAL_ACCOUNT_ID_1}` as any,
			`${revenueCostHashMap.get(val)}${EXTERNAL_ACCOUNT_ID_1}`
		);
		this.genericSelectFilterUpdate(
			null,
			`${revenueCostHashMap.get(Number(!val))}${EXTERNAL_ACCOUNT_ID_1}` as any,
			`${revenueCostHashMap.get(Number(!val))}${EXTERNAL_ACCOUNT_ID_1}`
		);
	}

	public handleRadioAccountGroup2(val: number) {
		this.setState({ externalAccountId2Radio: val });
		this.genericSelectFilterUpdate(
			[this.state.externalAccountId2 as string],
			`${revenueCostHashMap.get(val)}${EXTERNAL_ACCOUNT_ID_2}` as any,
			`${revenueCostHashMap.get(val)}${EXTERNAL_ACCOUNT_ID_2}`
		);
		this.genericSelectFilterUpdate(
			null,
			`${revenueCostHashMap.get(Number(!val))}${EXTERNAL_ACCOUNT_ID_2}` as any,
			`${revenueCostHashMap.get(Number(!val))}${EXTERNAL_ACCOUNT_ID_2}`
		);
	}

	public handleRadioSubscriptionGroup1(val: number) {
		this.setState({ externalSubscription1Radio: val });
		this.genericSelectFilterUpdate(
			[
				(this.state.externalSubscription1 as SubscriptionIndexResponse)
					.subscriptionId
			],
			`${revenueCostHashMap.get(val)}${EXTERNAL_SUBSCRIPTION_ID_1}` as any,
			`${revenueCostHashMap.get(val)}${EXTERNAL_SUBSCRIPTION_ID_1}`
		);
		this.genericSelectFilterUpdate(
			null,
			`${revenueCostHashMap.get(
				Number(!val)
			)}${EXTERNAL_SUBSCRIPTION_ID_1}` as any,
			`${revenueCostHashMap.get(Number(!val))}${EXTERNAL_SUBSCRIPTION_ID_1}`
		);
	}

	public handleRadioSubscriptionGroup2(val: number) {
		this.setState({ externalSubscription2Radio: val });
		this.genericSelectFilterUpdate(
			[
				(this.state.externalSubscription2 as SubscriptionIndexResponse)
					.subscriptionId
			],
			`${revenueCostHashMap.get(val)}${EXTERNAL_SUBSCRIPTION_ID_2}` as any,
			`${revenueCostHashMap.get(val)}${EXTERNAL_SUBSCRIPTION_ID_2}`
		);
		this.genericSelectFilterUpdate(
			null,
			`${revenueCostHashMap.get(
				Number(!val)
			)}${EXTERNAL_SUBSCRIPTION_ID_2}` as any,
			`${revenueCostHashMap.get(Number(!val))}${EXTERNAL_SUBSCRIPTION_ID_2}`
		);
	}

	public formatDataDomain(filter: DataFilter[]): IDropdown[] | [] {
		const filteredFilter = filter.filter((f) => f.key === 'dataDomains');
		if (isEmpty(filteredFilter)) {
			return [];
		}
		const formattedDataDomains = filteredFilter[0].operands.map((f) => {
			return { value: f.value, label: upperFirst(f.value) };
		});
		return formattedDataDomains;
	}

	/**
	 * Render the page with reportMetadataErrors
	 * @param reportMetadataErrors
	 */
	public renderReportMetadataErrors(reportMetadataErrors: RtError) {
		return (
			<StandardLayout router={this.router}>
				<Alert variant="danger">
					<details>
						<summary>Unable to Load Report</summary>
						<FormErrors error={reportMetadataErrors} />
					</details>
				</Alert>
			</StandardLayout>
		);
	}

	protected onSortedChange = (
		newSorted: UserPageConfigurationParametersSortingOrder[]
	) => {
		this.setState({ defaultSorting: newSorted });
	};

	protected getLocalStorageTimeRangeKey(
		reportId: string = this.reportId
	): string {
		return reportId + '_timeRange';
	}

	protected getCallDateFormat(): string | undefined {
		return this.state.timeRange.detailLevel.key === TimeFilterDetailKeys.Months
			? 'YYYY-MM'
			: undefined;
	}

	protected getTimeRangeFromLocalStorage(
		reportId: string = this.reportId
	): TimeFilter | null {
		try {
			const timeRange = localStorage.getItem(
				this.getLocalStorageTimeRangeKey(reportId)
			);
			const timeRangeParsed: TimeFilter | null =
				timeRange && JSON.parse(timeRange);

			if (timeRangeParsed) {
				timeRangeParsed.timeObject.start = createDateAsUTC(
					timeRangeParsed.timeObject.start
				) as Date;

				timeRangeParsed.timeObject.end = createDateAsUTC(
					timeRangeParsed.timeObject.end
				) as Date;

				return timeRangeParsed;
			}
		} catch (e) {
			console.error('Failed to restore time range from localStorage', e);
		}

		return null;
	}

	/**
	 * Get Resource for reports
	 */
	protected abstract getResource(): Promise<HttpResource<T>>;

	/**
	 * Render reports (chart/grid)
	 * These will be rendered in the main content area
	 */
	protected abstract renderReports(): React.ReactNode;

	/**
	 * Override method to append filters to filter list
	 */
	protected appendToFilters(): React.ReactNode {
		return null;
	}

	/**
	 * Store provided time range into local storage
	 * @param timeRange TimeFilter object
	 */
	protected storeTimeRange(timeRange: TimeFilter) {
		localStorage.setItem(
			this.getLocalStorageTimeRangeKey(),
			JSON.stringify(this.createTimeRangeAsUTC(timeRange))
		);
	}

	/**
	 * Shows loading screen and invokes updateReportsDebounce()
	 * Note: reports will update after the debounce period
	 */
	protected updateReports() {
		this.setState({
			filterBoxKey: generateUUID()
		});

		this.updateReportsDebounce();
	}

	/**
	 * Update report being shown on page
	 * @param timeRange
	 * @param filters
	 */
	private async updateReportsInternal() {
		const updateUuid = generateUUID();
		this.currentReportUuid = updateUuid;

		const updateState: Pick<
			S,
			'resource' | 'reportErrors' | 'areDataFiltersDirty' | 'loadingReport'
		> = {
			loadingReport: true,
			resource: null,
			reportErrors: undefined,
			areDataFiltersDirty: false
		};

		this.setState(updateState, async () => {
			try {
				const resource = await this.getResource();

				if (updateUuid === this.currentReportUuid) {
					this.setState({ resource });
				}
			} catch (reportErrors: any) {
				if (updateUuid === this.currentReportUuid) {
					this.setState({ reportErrors });
				}
			} finally {
				this.setState({ loadingReport: false });
			}
		});
	}

	private columnNameToReadable(columnName: string) {
		return capitalCase(columnName).replace(/Id/g, '').trim();
	}

	private createTimeRangeAsUTC(timeRange: TimeFilter) {
		return {
			...timeRange,
			timeObject: {
				mode: timeRange.timeObject.mode,
				start: createDateAsUTC(timeRange.timeObject.start),
				end: createDateAsUTC(timeRange.timeObject.end)
			}
		};
	}

	public getButtonDisabledConditions = () => {
		if (window.location.pathname.includes('reconciliation')) {
			if (
				this.state.externalAccountId1 &&
				this.state.externalAccountId1Radio == null
			) {
				return true;
			} else if (
				this.state.externalAccountId2 &&
				this.state.externalAccountId2Radio == null
			) {
				return true;
			} else if (
				this.state.externalSubscription1 &&
				this.state.externalSubscription1Radio == null
			) {
				return true;
			} else if (
				this.state.externalSubscription2 &&
				this.state.externalSubscription2Radio == null
			) {
				return true;
			}
			return false;
		}
		return false;
	};

	public shouldIncludeMonthly() {
		return [
			Reports.Asr,
			Reports.AsrLite,
			Reports.Cps,
			Reports.Ports,
			Reports.Financial,
			Reports.Numbers,
			Reports.MessagesDetail,
			Reports.TollfreeEnhanced,
			Reports.RejectSummary,
			Reports.Reconciliation,
			Reports.UsageAuditByDay,
			Reports.NumbersLite
		].includes(this.reportId);
	}

	public render() {
		const { reportMetadata, reportMetadataErrors } = this.state;
		const applyUserQueriesFromLocalStorage =
			localStorage.getItem('applyUserQueries');
		if (applyUserQueriesFromLocalStorage) {
			const parsedQueries = JSON.parse(applyUserQueriesFromLocalStorage);
			this.applyUserQuery(parsedQueries);
			localStorage.setItem('applyUserQueries', '');
		}

		if (reportMetadataErrors) {
			return this.renderReportMetadataErrors(reportMetadataErrors);
		}

		if (!reportMetadata) {
			return <Loading />;
		}

		return (
			<StandardLayout router={this.router}>
				<Row>
					<Col md={11} className="mb-6">
						<FlatDateRangePicker
							timeRangeAvailableDetails={this.state.timeRangeAvailableDetails}
							value={this.state.timeRange}
							timeRangePresetsKeys={this.state.timeRangePresetsKeys}
							onChange={(newValue: TimeFilter) =>
								this.updateTimeRange(newValue)
							}
							maxDate={this.state.maxDate}
							minDate={this.state.minDate}
							updateDetailOnDateChange={this.state.updateDetailOnDateChange}
							shouldUpdateDatePicker={!isEmpty(this.state.userQuery)}
							shouldIncludeMonthly={this.shouldIncludeMonthly()}
							hideDetailsOption={this.state.hideDetailsOption}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={6}>
						<Row>
							<Col lg={12} className="mb-2">
								{PartitionSelect.HasPermissions() &&
									!this.state.hidePartitionSelect && (
										<Form.Group className="d-flex justify-content-start align-items-center mb-3">
											<Form.Label className="mb-0 label-90">
												Partition
											</Form.Label>
											<VuePartitionSelect
												containerClassName="flex-fill"
												style={{ maxWidth: 400 }}
												useControlGroup={false}
												onChange={(partition) =>
													this.updatePartition(partition, true)
												}
												value={this.state.partition}
												initialOptionId={this.getPartition()}
											/>
										</Form.Group>
									)}

								{!this.state.hideFileStreamSelect && (
									<DataSourceSelector
										handleChange={this.genericSelectFilterUpdate.bind(this)}
										dataSourceValues={
											this.state.reportDomainsValues as IDropdown[]
										}
										value={this.state.fileStreamId}
										defaultDataDomains={this.formatDataDomain(
											this.state.filters
										)}
										initialOptionId={this.getIdsFromFilter(
											this.state.filters,
											this.fileStreamKey
										)}
									/>
								)}
								{this.state.showTwoFileStreamSelects && (
									<Row>
										<Col xl={5}>
											<Form.Group className="mb-3">
												<Form.Label className="mb-0 label-90">
													Source File Stream
												</Form.Label>
												<FileStreamSelect<true>
													multi
													required
													isActive
													clearable={false}
													containerClassName="flex-fill"
													useControlGroup={false}
													onChange={(fileStreams) => {
														this.genericSelectFilterUpdate(
															fileStreams,
															'fileStreamId1',
															'fileStreamId'
														);
														this.setState({ fileStreamId1: fileStreams });
													}}
													value={this.state.fileStreamId1}
													closeMenuOnSelect={false}
													initialOptionId={this.getIdsFromFilter(
														this.state.filters,
														this.fileStreamKey1
													)}
												/>
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-0 label-90">
													Source Trunk Group
												</Form.Label>
												<TrunkGroupSelect
													multi
													required
													label="Trunk Group 1"
													useControlGroup={false}
													onChange={(trunkGroups) =>
														this.genericSelectFilterUpdate(
															trunkGroups,
															'trunkGroup1',
															'trunkGroupId'
														)
													}
													value={this.state.trunkGroup1}
													closeMenuOnSelect={false}
													params={{
														fileStreamIds: this.state.fileStreamId1?.map(
															(f) => f.fileStreamId
														)
													}}
													initialOptionId={this.getIdsFromFilter(
														this.state.filters,
														this.trunkGroupKey1
													)}
												/>
											</Form.Group>
											{this.state.showTrunkGroupIngressEgressOptions && (
												<Form.Group>
													<RadioFormControl<number>
														label="Source Ingress/Egress"
														required
														onChange={(isSourceIngress) =>
															this.setState({ isSourceIngress })
														}
														value={this.state.isSourceIngress}
														options={[
															{ value: 1, label: 'Ingress' },
															{ value: 0, label: 'Egress' }
														]}
													/>
												</Form.Group>
											)}
											<div
												style={{ borderTop: '1px solid #C2C6CA' }}
												className="dropdown-divider"
											></div>
											<Form.Group>
												<InputFormControl
													label="External Account Id"
													displayMode={false}
													onChange={(externalAccountId1) =>
														this.handleChangeExternalAccountValueFirstGroup(
															externalAccountId1
														)
													}
													value={this.state.externalAccountId1}
												/>
											</Form.Group>
											{this.state.externalAccountId1 && (
												<>
													<Form.Group>
														<RadioFormControl<number>
															label="Choose the type"
															required={Boolean(this.state.externalAccountId1)}
															onChange={(externalAccountId1Radio) => {
																this.setState({ externalAccountId1Radio });
																this.handleRadioAccountGroup1(
																	externalAccountId1Radio
																);
															}}
															value={
																this.state.externalAccountId1Radio as number
															}
															options={[
																{ value: 1, label: 'Revenue' },
																{ value: 0, label: 'Cost' }
															]}
														/>
													</Form.Group>
													<div
														style={{ borderTop: '1px solid #C2C6CA' }}
														className="dropdown-divider"
													></div>
												</>
											)}

											<Form.Group>
												<SubscriptionSelect<false>
													label="Subscription"
													value={
														this.state
															.externalSubscription1 as SubscriptionIndexResponse
													}
													onChange={(val) =>
														this.handleChangeSubscriptionValueFirstGroup(val)
													}
												/>
											</Form.Group>
											{this.state.externalSubscription1 && (
												<RadioFormControl<number>
													label="Choose the type"
													required={Boolean(this.state.externalSubscription1)}
													onChange={(externalSubscription1Radio) => {
														this.setState({ externalSubscription1Radio });
														this.handleRadioSubscriptionGroup1(
															externalSubscription1Radio
														);
													}}
													value={
														this.state.externalSubscription1Radio as number
													}
													options={[
														{ value: 1, label: 'Revenue' },
														{ value: 0, label: 'Cost' }
													]}
												/>
											)}
										</Col>
										<Col xl={1}>
											<div style={{ minHeight: '100%' }} className="vr"></div>
										</Col>
										<Col xl={5}>
											<Form.Group className="mb-3">
												<Form.Label className="mb-0 label-90">
													Target File Stream
												</Form.Label>
												<FileStreamSelect<true>
													multi
													required
													isActive
													clearable={false}
													containerClassName="flex-fill"
													useControlGroup={false}
													style={{ maxWidth: 350 }}
													onChange={(fileStreams) => {
														this.genericSelectFilterUpdate(
															fileStreams,
															'fileStreamId2',
															'fileStreamId'
														);
														this.setState({ fileStreamId2: fileStreams });
													}}
													value={this.state.fileStreamId2}
													closeMenuOnSelect={false}
													initialOptionId={this.getIdsFromFilter(
														this.state.filters,
														this.fileStreamKey2
													)}
												/>
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-0 label-90">
													Target Trunk Group
												</Form.Label>
												<TrunkGroupSelect
													multi
													style={{ maxWidth: 350 }}
													useControlGroup={false}
													onChange={(trunkGroups) =>
														this.genericSelectFilterUpdate(
															trunkGroups,
															'trunkGroup2',
															'trunkGroupId'
														)
													}
													value={this.state.trunkGroup2}
													closeMenuOnSelect={false}
													params={{
														fileStreamIds: this.state.fileStreamId2?.map(
															(f) => f.fileStreamId
														)
													}}
													initialOptionId={this.getIdsFromFilter(
														this.state.filters,
														this.trunkGroupKey2
													)}
												/>
											</Form.Group>
											{this.state.showTrunkGroupIngressEgressOptions && (
												<Form.Group>
													<RadioFormControl<number>
														label="Target Ingress/Egress"
														required
														onChange={(isTargetIngress) =>
															this.setState({ isTargetIngress })
														}
														value={this.state.isTargetIngress}
														options={[
															{ value: 1, label: 'Ingress' },
															{ value: 0, label: 'Egress' }
														]}
													/>
												</Form.Group>
											)}
											<div
												style={{ borderTop: '1px solid #C2C6CA' }}
												className="dropdown-divider"
											></div>
											<Form.Group>
												<InputFormControl
													label="External Account Id"
													displayMode={false}
													onChange={(externalAccountId2) =>
														this.handleChangeExternalAccountValueSecondGroup(
															externalAccountId2
														)
													}
													value={this.state.externalAccountId2}
												/>
											</Form.Group>
											{this.state.externalAccountId2 && (
												<>
													<Form.Group>
														<RadioFormControl<number>
															label="Choose the type"
															required={Boolean(this.state.externalAccountId2)}
															onChange={(externalAccountId2Radio) => {
																this.setState({ externalAccountId2Radio });
																this.handleRadioAccountGroup2(
																	externalAccountId2Radio
																);
															}}
															value={
																this.state.externalAccountId2Radio as number
															}
															options={[
																{ value: 1, label: 'Revenue' },
																{ value: 0, label: 'Cost' }
															]}
														/>
													</Form.Group>
													<div
														style={{ borderTop: '1px solid #C2C6CA' }}
														className="dropdown-divider"
													></div>
												</>
											)}

											<Form.Group>
												<SubscriptionSelect<false>
													label="Subscription"
													value={
														this.state
															.externalSubscription2 as SubscriptionIndexResponse
													}
													onChange={(val) =>
														this.handleChangeSubscriptionValueSecondGroup(val)
													}
												/>
											</Form.Group>
											{this.state.externalSubscription2 && (
												<RadioFormControl<number>
													label="Choose the type"
													required={Boolean(this.state.externalSubscription2)}
													onChange={(externalSubscription2Radio) => {
														this.setState({ externalSubscription2Radio });
														this.handleRadioSubscriptionGroup2(
															externalSubscription2Radio
														);
													}}
													value={
														this.state.externalSubscription2Radio as number
													}
													options={[
														{ value: 1, label: 'Revenue' },
														{ value: 0, label: 'Cost' }
													]}
												/>
											)}
										</Col>
									</Row>
								)}

								<Row>
									<Col lg={6} xl={12}>
										<VueReportAggregates
											metadata={reportMetadata}
											aggregates={this.state.dataSums}
											onChange={(dataSums) =>
												this.updateDataSums(dataSums, true)
											}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col xl={5}>
						<section className="mb-3">
							<Row className="mb-3">
								<Col md={3} className="align-self-center">
									<Form.Label className="label-90">Saved Queries</Form.Label>
								</Col>
								<Col>
									<VueSavedQuerySelect
										defaultSorting={this.state.defaultSorting}
										reportId={this.reportId}
										filters={this.state.filters}
										timeRange={this.state.timeRange}
										preset={this.state.preset}
										dataSums={this.state.dataSums}
										userQuery={this.state.userQuery}
										additionalColumns={this.state.additionalColumns}
										partition={
											!this.state.hidePartitionSelect
												? this.state.partition
												: undefined
										}
										onChange={(userQuery, mergeMode) => {
											this.applyUserQuery(userQuery, mergeMode);
										}}
										onSave={(userQuery) => {
											this.setState({ userQuery });
										}}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md={3} className="align-self-center">
									<Form.Label className="d-flex mt-1 align-items-center">
										Filters
										{reportMetadata.searchFields.length > 0 && (
											<HelpIconComponent
												className="ml-1"
												searchFields={reportMetadata.searchFields}
											/>
										)}
									</Form.Label>
								</Col>
								<Col>
									<section className="d-flex">
										<div className="p-1 w-100">
											<Form.Label
												className="w-100 text-center rounded-1"
												style={{
													backgroundColor:
														RtVueOperatorColors[DataFilterOperator.In]
												}}
											>
												Include
											</Form.Label>
											<VueFilterSearchPanel
												key={this.state.filterBoxKey}
												filters={this.state.filters}
												searchFields={reportMetadata.searchFields.filter(
													(f) =>
														// Include filter can't have exclude values
														!find(this.state.filters, {
															key: f.key,
															operator: DataFilterOperator.NotIn
														})
												)}
												onChange={(filters) => {
													this.updateFilters(filters, false);
												}}
											/>
										</div>
										<div className="p-1 w-100">
											<Form.Label
												className="w-100 text-center rounded-1"
												style={{
													backgroundColor:
														RtVueOperatorColors[DataFilterOperator.NotIn]
												}}
											>
												Exclude
											</Form.Label>
											<VueFilterSearchPanel
												key={this.state.filterBoxKey}
												filters={this.state.filters}
												searchFields={reportMetadata.searchFields
													.filter((f) => {
														// Exclude filter can't have include values
														const existingDataFilter = find(
															this.state.filters,
															{
																key: f.key
															}
														);
														return (
															!existingDataFilter ||
															existingDataFilter?.operator ===
																DataFilterOperator.NotIn
														);
													})
													.map((sf) => ({
														...sf,
														operator: DataFilterOperator.NotIn
													}))}
												onChange={(filters) => {
													this.updateFilters(filters, false);
												}}
											/>
										</div>
										{reportMetadata.advancedSearchFields?.length > 0 && (
											<VueAdvancedSearchFilters
												filters={this.state.filters}
												advancedSearchFields={
													reportMetadata.advancedSearchFields
												}
												onChange={(filter) => {
													const filtered = this.state.filters.filter(
														(f) =>
															!(
																f.key === filter.key &&
																f.advanced &&
																filter.operator === f.operator
															)
													);
													const isFilled =
														filter.operands.length > 0 &&
														filter.operands[0].value;

													this.updateFilters(
														isFilled ? [...filtered, filter] : filtered
													);
												}}
											/>
										)}
									</section>
								</Col>
							</Row>
							<Row>
								<Col md={3} className="align-self-center">
									<Form.Label className="d-flex align-items-center">
										Add. Columns
									</Form.Label>
								</Col>
								<Col>
									<VueSelectAdditionalColumns
										isMulti={true}
										selectedAdditionalColumns={this.state.additionalColumns}
										allColumns={this.state.allColumns}
										onChange={(columns) => {
											this.updateAdditionalColumns(columns);
										}}
									/>
								</Col>
							</Row>
						</section>
					</Col>
				</Row>
				<Row>
					<Col>
						{!this.state.showTwoFileStreamSelects && (
							<VueStandardFilters
								className="flex-wrap"
								keysBlacklist={[this.fileStreamKey]}
								filters={this.state.filters}
								searchFields={reportMetadata.searchFields}
								advancedSearchFields={reportMetadata.advancedSearchFields}
								onChange={(newFilters) => {
									this.updateFilters(newFilters, false);
								}}
							/>
						)}
					</Col>
				</Row>
				<Row>
					<Col>
						{this.state.areDataFiltersDirty && (
							<Alert variant="warning" className="my-3">
								<span>Looks like your selections have changed. Click</span>
								<Button
									disabled={this.getButtonDisabledConditions()}
									type="button"
									variant="primary"
									size="sm"
									className="mx-2"
									onClick={() => this.updateReports()}
								>
									Update
								</Button>
								<span>to refresh your results.</span>
							</Alert>
						)}
						<FormErrors error={this.state.reportErrors} />
						{!this.state.reportErrors && this.renderReports()}
					</Col>
				</Row>
			</StandardLayout>
		);
	}
}
