import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { CustomOption } from 'RtUi/components/ui/SelectFormControl/components/CustomOption';
import { MenuList } from 'RtUi/components/ui/SelectFormControl/components/MenuList';
import { useCallback, useEffect, useRef } from 'react';
import Select, {
	MultiValue,
	Props,
	PropsValue,
	createFilter
} from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

export type ReactSelectProps<
	OptionType,
	isMulti extends boolean = false
> = Props<OptionType, isMulti> & {
	maxOptions?: number;
};

export interface IDefaultSelectOption<T> {
	value: T;
	label: string;
}

type OptionValue<T, IsMulti extends boolean = false> = IsMulti extends true
	? T[]
	: T;

type OnChangeValue<
	T,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> = IsClearable extends true
	? OptionValue<T, IsMulti> | undefined
	: OptionValue<T, IsMulti>;

type InitialOptionValue<
	T,
	K extends keyof T,
	IsMulti extends boolean = false
> = IsMulti extends true ? Array<T[K]> : T[K];

export type AsyncOptions<T> = {
	options: T[];
};

type AsyncProps<T> =
	| {
			isAsync?: false;
			promiseOptions?: (inputValue: string) => Promise<AsyncOptions<T>>;
	  }
	| {
			isAsync: true;
			promiseOptions: (inputValue: string) => Promise<AsyncOptions<T>>;
	  };

interface ISelectFormControlBaseProps<
	T,
	K extends keyof T,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends Omit<
		ReactSelectProps<T, IsMulti>,
		'onChange' | 'getOptionValue' | 'getOptionLabel'
	> {
	label: string;
	options: T[];
	labelKey: keyof T;
	value: PropsValue<T> | undefined;
	valueKey: K;
	router?: RtUiRouter;
	linkTo?: string;
	onChange: (newValue: OnChangeValue<T, IsMulti, IsClearable>) => void;
	isClearable?: IsClearable;
	initialOptionId?: InitialOptionValue<T, K, IsMulti>;
	displayMode?: boolean;
	appendDropdownToBody?: boolean;
	controlGroupClassName?: string;
	hideLabel?: boolean;
}

type SelectFormControlProps<
	T,
	K extends keyof T,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> = ISelectFormControlBaseProps<T, K, IsMulti, IsClearable> & AsyncProps<T>;

export interface ISelectFormControlInstanceProps<
	T,
	K extends keyof T,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends Omit<
		ISelectFormControlBaseProps<T, K, IsMulti, IsClearable>,
		'labelKey' | 'valueKey' | 'options' | 'label'
	> {
	label?: string;
}

export const SelectFormControl = <
	T,
	K extends keyof T,
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	labelKey,
	valueKey,
	value,
	options,
	onChange = () => {},
	filterOption,
	initialOptionId,
	label,
	displayMode,
	isDisabled,
	formatOptionLabel,
	isMulti,
	router,
	linkTo,
	appendDropdownToBody,
	hideLabel = false,
	placeholder = '',
	maxOptions = 500,
	isAsync = false,
	promiseOptions,
	...props
}: SelectFormControlProps<T, K, IsMulti, IsClearable>) => {
	const initialized = useRef<boolean>(false);

	const onChangeHandler = useCallback(
		(value: T | T[] | undefined) => {
			initialized.current = true;
			onChange(value as OnChangeValue<T, IsMulti>);
		},
		[onChange]
	);

	useEffect(() => {
		if (initialized.current) {
			return;
		}

		const value = isMulti
			? options.filter((opt) =>
					(initialOptionId as Array<T[K]>)?.includes(opt[valueKey])
				)
			: options.find((opt) => opt[valueKey] === initialOptionId);

		if (value) {
			onChangeHandler(value as OnChangeValue<T, IsMulti>);
		}
	}, [
		initialized,
		options,
		initialOptionId,
		valueKey,
		isMulti,
		onChangeHandler
	]);

	const getOptionValue = (newValue: T) => {
		return String(newValue[valueKey]);
	};

	const getOptionLabel = (currentValue: T): string => {
		return `${currentValue[labelKey]}`;
	};

	const selectProps: ReactSelectProps<T, IsMulti> = {
		...props,
		menuPortalTarget: appendDropdownToBody ? document.body : undefined,
		isSearchable: true,
		formatOptionLabel,
		getOptionValue,
		getOptionLabel
	};

	const filterOpt = (option: FilterOptionOption<T>, rawInput: string) => {
		const customFilter = filterOption && filterOption(option, rawInput);

		const defaultFilter = createFilter({ ignoreAccents: false });

		return customFilter || defaultFilter(option, rawInput);
	};

	const getControlLabel = () => {
		if (!value) {
			return '';
		}

		if (isMulti) {
			return (value as MultiValue<T>)
				.map((opt) => getOptionLabel(opt))
				.join(', ');
		}

		const currentValue = value as NonNullable<T>;

		if (formatOptionLabel) {
			return formatOptionLabel(currentValue, {
				context: 'value',
				inputValue: `${currentValue[valueKey]}`,
				selectValue: options
			});
		}

		return <>{currentValue[labelKey] ?? currentValue[valueKey]}</>;
	};

	const getControlRouterLink = () => {
		if (linkTo) {
			return linkTo;
		}

		if (router && router.recordHasAccessToProfile(value)) {
			return router.getProfileRoute(value);
		}

		return undefined;
	};

	return (
		<>
			<ControlGroup
				value={getControlLabel()}
				linkTo={getControlRouterLink()}
				label={label}
				displayMode={displayMode}
				hideLabel={hideLabel}
				required={props.required}
			>
				{isAsync && promiseOptions ? (
					<AsyncPaginate
						loadOptions={promiseOptions}
						components={{ Option: CustomOption as any, MenuList: MenuList }}
						defaultOptions
						value={value ?? null}
						onChange={(newValue) => {
							onChangeHandler(
								(newValue ?? undefined) as OnChangeValue<T, IsMulti>
							);
						}}
						isDisabled={isDisabled ?? displayMode}
						isMulti={isMulti}
						filterOption={filterOpt}
						placeholder={placeholder}
						maxOptions={maxOptions}
						debounceTimeout={500}
						styles={{
							control: (baseStyles) => ({
								...baseStyles,
								minWidth: '180px'
							})
						}}
						{...selectProps}
					/>
				) : (
					<Select<T, IsMulti>
						components={{ Option: CustomOption as any, MenuList: MenuList }}
						value={value ?? null}
						options={options}
						onChange={(newValue) => {
							onChangeHandler(
								(newValue ?? undefined) as OnChangeValue<T, IsMulti>
							);
						}}
						isDisabled={isDisabled ?? displayMode}
						isMulti={isMulti}
						filterOption={filterOpt}
						placeholder={placeholder}
						maxOptions={maxOptions}
						styles={{
							control: (baseStyles) => ({
								...baseStyles,
								minWidth: '180px'
							})
						}}
						{...selectProps}
					/>
				)}
				{options.length > maxOptions && (
					<blockquote className="fst-italic mt-1">
						Displaying {maxOptions} of {options.length}. Type to Search.
					</blockquote>
				)}
			</ControlGroup>
		</>
	);
};
