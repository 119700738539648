import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { LcrTemplatesGrid } from 'RtUi/app/rtLco/Templates/lib/grids/LcrTemplatesGrid';
import { LcrTemplateRouter } from 'RtUi/app/rtLco/Templates/LcrTemplate.router';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import {
	CarrierSelect,
	ICicIndexResponse
} from 'RtUi/app/rt800/Cprs/lib/controls/CarrierSelect';
import {
	LcrTemplateIndexRequest,
	RespOrgIndexResponse,
	EntityIndexResponse,
	TemplateIndexResponse
} from 'RtModels';

interface IInitialResourceParams {
	search: string;
	entity: EntityIndexResponse | undefined;
	respOrg: RespOrgIndexResponse | undefined;
	template: TemplateIndexResponse | undefined;
	carriers?: ICicIndexResponse[];
}

interface ILcrTemplateIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@LcrTemplateRouter.getIndexRtUiController()
export class LcrTemplateIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	ILcrTemplateIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		search: '',
		entity: undefined,
		respOrg: undefined,
		template: undefined
	};

	public state: ILcrTemplateIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { search, entity, respOrg, template } = this.state;

		const resourceParams: LcrTemplateIndexRequest = {};

		if (search) {
			resourceParams.search = search;
		}

		if (entity) {
			resourceParams.entityId = entity.entityId;
		}

		if (respOrg) {
			resourceParams.respOrgId = respOrg.respOrgId;
		}

		if (template) {
			resourceParams.templateName = template.templateName;
		}

		if (this.state.carriers) {
			resourceParams.carriers = this.state.carriers.map(
				(carrier) => carrier.cic
			);
		}

		return resourceParams;
	}

	public render() {
		return (
			<TabbedLayout
				router={LcrTemplateRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<EntitySelect
							onChange={(entity: EntityIndexResponse) =>
								this.setState({ entity })
							}
							value={this.state.entity}
						/>
						<RespOrgSelect
							onChange={(respOrg: RespOrgIndexResponse) =>
								this.setState({ respOrg })
							}
							entityId={this.state.entity?.entityId}
							value={this.state.respOrg}
						/>
						<TemplateSelect
							onChange={(template: TemplateIndexResponse) =>
								this.setState({ template })
							}
							respOrgId={this.state.respOrg?.respOrgId}
							value={this.state.template}
						/>
						<CarrierSelect<true>
							multi
							onChange={(carriers) => this.setState({ carriers })}
							value={this.state.carriers}
						/>
						<InputFormControl
							label="Search"
							onChange={(search) => this.setState({ search })}
							value={this.state.search}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<LcrTemplatesGrid
						autoFocusFilter
						resourceParams={this.state.resourceParams}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
