/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NumberIndexResponse = {
    properties: {
        numberId: {
            type: 'string',
            isRequired: true,
        },
        number: {
            type: 'string',
            isRequired: true,
        },
        country: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        rateCenter: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        resourceLabel: {
            type: 'string',
            isRequired: true,
        },
        integrationId: {
            type: 'number',
            isRequired: true,
        },
        resourceTypeId: {
            type: 'number',
            isRequired: true,
        },
        nonRecurringAmount: {
            type: 'number',
            isRequired: true,
        },
        recurringAmount: {
            type: 'number',
            isRequired: true,
        },
        lastProfiled: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        trunkGroup: {
            type: 'string',
            isRequired: true,
        },
        resRef2: {
            type: 'string',
            isRequired: true,
        },
        translatedNumber: {
            type: 'string',
            isRequired: true,
        },
        accountId: {
            type: 'string',
            isRequired: true,
        },
        orderId: {
            type: 'string',
            isRequired: true,
        },
        status: {
            type: 'string',
            isRequired: true,
        },
        createdAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};