import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { BlockTypeIndexResponse } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';

export class BlockTypeResource extends ArrayResource<BlockTypeIndexResponse> {
	constructor() {
		super('blockTypeId');

		this.setApiRouteForGetAll(RtxSipApiRoutes.BlockTypes.Index);
	}
}
