import clsx from 'clsx';
import * as React from 'react';
import { Button, Card } from 'react-bootstrap';
import * as ReactDOM from 'react-dom';
import { PortalErrorBoundary } from 'RtUi/app/ApplicationShell/lib/components/PortalErrorBoundary';

interface IAsideProps {
	isOpen: boolean;
	onClickOutside?: () => void;
	disabledBody?: boolean;
	header?: string;
	fullscreen?: boolean;
	className?: string;
}

interface IAsideState {
	hasBeenOpened: boolean;
}

export class Aside extends React.Component<
	React.PropsWithChildren<IAsideProps>,
	IAsideState
> {
	public static Header = ({
		onClose,
		header,
		warning,
		children = '',
		closable = true
	}: {
		header: React.ReactNode;
		warning?: boolean;
		children?: React.ReactNode;
		onClose?: () => void;
		closable?: boolean;
	}) => (
		<Card.Header
			className={clsx(
				'd-flex justify-content-between align-items-center',
				warning ? 'list-group-item-warning' : 'bg-light'
			)}
		>
			<h6 className="mb-0">{header}</h6>
			{children}
			{closable && (
				<Button type="button" variant="white" onClick={onClose}>
					<i className="fas fa-fw fa-times" />
				</Button>
			)}
		</Card.Header>
	);

	public static Body = ({ children }: { children?: React.ReactNode }) => (
		<Card.Body>{children}</Card.Body>
	);

	public state: IAsideState = {
		hasBeenOpened: false
	};

	public containerRef: HTMLElement | null = null;
	public portalsElem: HTMLElement;
	public thisElem: HTMLElement;

	constructor(props: IAsideProps) {
		super(props);

		this.portalsElem = document.getElementById('portals') as HTMLElement;
		this.thisElem = document.createElement('section');
	}

	public checkIfClickWasOutsideContainerFn = (evt: MouseEvent) =>
		this.checkIfClickWasOutsideContainer(evt);

	public componentDidMount() {
		this.portalsElem.appendChild(this.thisElem);

		this.checkForOpen();
		this.toggleOverflowStyleOfBody();
	}

	public componentDidUpdate() {
		this.checkForOpen();
		this.toggleOverflowStyleOfBody();
	}

	public componentWillUnmount() {
		window.removeEventListener('click', this.checkIfClickWasOutsideContainerFn);

		document.body.style.removeProperty('overflow');

		this.portalsElem.removeChild(this.thisElem);
	}

	public checkIfClickWasOutsideContainer(evt: MouseEvent) {
		const { onClickOutside: isClickedOutside, isOpen } = this.props;
		const whiteListedClassNames = [
			'react-select__option',
			'SingleDatePicker_picker',
			'input-action'
		];

		if (!isOpen || !isClickedOutside) {
			return;
		}

		const eventTarget = evt.target as HTMLElement | null;

		if (!this.containerRef || !eventTarget) {
			return;
		}

		const hasClass = (element: HTMLElement, className: string) => {
			return element.className.includes(className);
		};

		//Certain elements are removed before the event bubbles to window
		//In those cases, add those to the whitelist
		for (const whiteListedClassName of whiteListedClassNames) {
			let currentElem: HTMLElement | null = eventTarget;

			while (currentElem) {
				if (hasClass(currentElem, whiteListedClassName)) {
					return;
				}

				currentElem = currentElem.parentElement;
			}
		}

		const clickWasInsideContainer = this.containerRef.contains(eventTarget);

		if (!clickWasInsideContainer) {
			isClickedOutside();
		}
	}

	public checkForOpen(props = this.props) {
		const { isOpen } = props;

		if (isOpen) {
			//Set a small timeout to make sure the click that opened the
			//aside does not close the aside
			setTimeout(() => {
				window.addEventListener(
					'click',
					this.checkIfClickWasOutsideContainerFn
				);
			}, 100);
		} else {
			window.removeEventListener(
				'click',
				this.checkIfClickWasOutsideContainerFn
			);
		}

		if (!this.state.hasBeenOpened && props.isOpen === true) {
			this.setState({ hasBeenOpened: true });
		}
	}

	public toggleOverflowStyleOfBody(props = this.props) {
		if (props.isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.removeProperty('overflow');
		}
	}

	public render() {
		const {
			isOpen,
			header,
			fullscreen,
			disabledBody = false,
			className
		} = this.props;
		const { hasBeenOpened } = this.state;
		const classNames = [];
		const bodyClassNames: string[] = [];

		if (isOpen) {
			classNames.push('opened');

			if (!disabledBody) {
				bodyClassNames.push('card-body');
			}

			if (fullscreen) {
				classNames.push('aside-right-fullscreen');
			}
		} else if (hasBeenOpened) {
			//only add closed state if has been opened previously
			classNames.push('closed');
		}

		const aside = (
			<section
				className={clsx('card aside-right', className, classNames.join(' '))}
				ref={(containerRef) => (this.containerRef = containerRef)}
			>
				{header && (
					<Card.Body>
						<h5 className="mb-0">
							<b>{header}</b>
						</h5>
					</Card.Body>
				)}
				<section className={bodyClassNames.join(' ')}>
					{this.props.isOpen && this.props.children}
				</section>
			</section>
		);

		return ReactDOM.createPortal(
			<PortalErrorBoundary>{aside}</PortalErrorBoundary>,
			this.thisElem
		);
	}
}
