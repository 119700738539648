/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TaskProfileResponse = {
    properties: {
        taskId: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        taskTypeId: {
            type: 'number',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        requestedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        scheduledAt: {
            type: 'Date',
            format: 'date-time',
        },
        processedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        priority: {
            type: 'number',
            isRequired: true,
        },
        referenceKey: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        taskLabel: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        tfnQty: {
            type: 'number',
            isRequired: true,
        },
        numberGroupId: {
            type: 'number',
            isRequired: true,
        },
        pendingQty: {
            type: 'number',
            isRequired: true,
        },
        warningQty: {
            type: 'number',
            isRequired: true,
        },
        errorQty: {
            type: 'number',
            isRequired: true,
        },
        apiTotQty: {
            type: 'number',
            isRequired: true,
        },
        apiReqQty: {
            type: 'number',
            isRequired: true,
        },
        apiResQty: {
            type: 'number',
            isRequired: true,
        },
        parameters: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        updatedAt: {
            type: 'Date',
            format: 'date-time',
        },
        completedAt: {
            type: 'Date',
            format: 'date-time',
        },
    },
};