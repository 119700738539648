import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { BlockedNumber } from 'RtModels';
import { BlockNumbersRouter } from 'RtUi/app/rtSip/BlockNumbers/BlockNumbers.router';
import { BlockNumberResource } from 'RtUi/app/rtSip/BlockNumbers/lib/resources/BlockNumbersResource';
import { BlockNumberFormEditor } from 'RtUi/app/rtSip/BlockNumbers/lib/forms/BlockNumberFormEditor';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';

interface IBlockNumbersContainerState {
	blockNumber: BlockedNumber | undefined;
	activeTab: string;
}

@BlockNumbersRouter.getProfileRtUiController()
export class BlockNumbersProfileContainer extends ProfileApplicationContainer<
	{},
	IBlockNumbersContainerState
> {
	public Tabs = BlockNumbersRouter.getProfileTabs();

	public state: IBlockNumbersContainerState = {
		blockNumber: undefined,
		activeTab: this.Tabs.Profile.header
	};

	public componentDidMount() {
		const blockNumberResource = new BlockNumberResource();
		const blockNumberId = this.getIdParam();

		blockNumberResource.get(blockNumberId).then((blockNumber) => {
			this.setState({ blockNumber });
		});
	}

	public render() {
		const { blockNumber } = this.state;

		if (!blockNumber) {
			return null;
		}

		return (
			<TabbedLayout<BlockedNumber>
				router={BlockNumbersRouter}
				profile={blockNumber}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<TwoFADisabledWarning />
					<BlockNumberFormEditor
						profile={blockNumber}
						onUpdate={(newProfile) =>
							this.setState({ blockNumber: newProfile })
						}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
