import {
	CprValidatorDataList,
	CprValidatorDataListTypes
} from 'RtUi/app/rt800/Cprs/lib/controls/CprValidatorDataList';
import { AreaOfServiceLabelsMap } from 'RtUi/app/rt800/Cprs/lib/resources/AreaOfServiceValuesResource';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from 'RtUi/components/form/FormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { AosNetwork, CprValidator } from 'Somos/lib/SomosCpr/RtCprV2';
import { AosColNetwork } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosColNetwork';

export interface ICprValidatorFormControlProps<
	CprValidatorType extends CprValidator
> extends IFormControlProps<
		CprValidatorDataListTypes<CprValidatorType>,
		false
	> {
	label: string;
	validator: CprValidatorType;
}

export class CprValidatorFormControl<
	CprValidatorType extends CprValidator
> extends FormControl<
	CprValidatorDataListTypes<CprValidatorType>,
	false,
	ICprValidatorFormControlProps<CprValidatorType>
> {
	public state: IFormControlState = {
		formLabel: ''
	};
	public resource = undefined;

	public render() {
		const { validator } = this.props;
		let displayValue = validator?.getRawValues().join(', ') ?? '';

		if (validator && validator instanceof AosColNetwork) {
			displayValue = validator
				.getRawValues()
				.map((val) => AreaOfServiceLabelsMap.get(val as AosNetwork) ?? val)
				.join(', ');
		}

		return (
			<ControlGroup
				label={this.state.formLabel}
				value={displayValue}
				displayMode={this.props.displayMode}
				hideFormGroup={this.props.hideFormGroup}
			>
				<CprValidatorDataList<CprValidatorType>
					displayMode={this.props.displayMode}
					onChange={this.onChangeInternal}
					validator={this.props.validator}
				/>
			</ControlGroup>
		);
	}

	private onChangeInternal = (
		newValues: CprValidatorDataListTypes<CprValidatorType>
	) => {
		const { onChange = () => ({}) } = this.props;

		onChange(newValues);
	};
}
