/* eslint-disable max-classes-per-file */
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { ProtocolTypesOptions } from 'RtUi/utils/maps';

export interface IProtocolOption {
	label: string;
	value: string;
}

export class ProtocolResource extends ArrayResource<IProtocolOption> {
	constructor() {
		super('value');
		this.setAllData(ProtocolTypesOptions);
	}
}

interface IProtocolSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<IProtocolOption, isMulti> {}

export class ProtocolTypeSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	IProtocolOption,
	isMulti,
	IProtocolSelectProps<isMulti>
> {
	public resourceClass = ProtocolResource;
	public state: ISelectFormControlState<IProtocolOption> = {
		formLabel: 'URL',
		valueKey: 'value',
		labelKey: 'label'
	};
}
