/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateControlJobStatus {
    Waiting = 'waiting',
    Downloading = 'download',
    Rating = 'rating',
    Uploading = 'uploading',
    Complete = 'complete',
    Error = 'error',
    Cancelled = 'cancelled',
}