/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RejectCodes {
    Successful = 0,
    NoTrunk = 10,
    NoSubscription = 20,
    NoRate = 30,
    Imt = 40,
    BillingNumberNotFound = 50,
    NoCallType = 100,
    Wtf = 999,
}