import { TransactionIndexRequest, TransactionIndexResponse } from 'RtModels';
import { TransactionRouter } from 'RtUi/app/rtSip/Transactions/Transaction.router';
import { TransactionReverseAside } from 'RtUi/app/rtSip/Transactions/lib/forms/TransactionReverseAside';
import { useGetTransactions } from 'RtUi/app/rtSip/Transactions/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getTransactionsColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/transactions';
import { useMemo, useState } from 'react';
import { Item } from 'react-contexify';

interface ITransactionGridProps {
	onRowClick?: (row: TransactionIndexResponse) => void;
	resourceParams?: TransactionIndexRequest;
	autoFocusFilter?: boolean;
}

export const TransactionDataGrid = ({
	resourceParams
}: ITransactionGridProps) => {
	const { data, isFetching: isLoading } = useGetTransactions(resourceParams);
	const columns = useMemo(() => getTransactionsColumns(), []);
	const [isReverseModalOpen, setIsReverseModalOpen] = useState<boolean>(false);
	const [selectedTransaction, setSelectedTransaction] =
		useState<TransactionIndexResponse>();

	const toggleIsReverseModalOpen = (
		isReverseModalOpen: boolean,
		transaction?: TransactionIndexResponse
	) => {
		if (isReverseModalOpen) {
			setSelectedTransaction(transaction);
		}
	};

	const removeSelection = () => {
		setIsReverseModalOpen(false);

		//Wait for modal to collapse
		setTimeout(() => {
			setSelectedTransaction(undefined);
		}, 350);
	};

	const onReverseSuccess = () => {
		setIsReverseModalOpen(false);
	};

	return (
		<>
			<DataGrid
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtSip_transaction'}
				router={TransactionRouter}
				disableExternalLinks
				onRowClick={setSelectedTransaction}
				rowThemeColor={(record) =>
					record.transactionId === selectedTransaction?.transactionId
						? RowThemeColor.WARNING
						: undefined
				}
				menuLinks={(record: TransactionIndexResponse) => {
					//cSpell:disable-next-line
					if (record.isReversible === 1) {
						return (
							<Item onClick={() => toggleIsReverseModalOpen(true, record)}>
								<span className="d-flex justify-content-start align-items-center">
									<i className="fas fa-fw fa-history" />
									<span>
										<span>Reverse</span>
									</span>
								</span>
							</Item>
						);
					}
					return null;
				}}
			/>
			{selectedTransaction && (
				<TransactionReverseAside
					show={isReverseModalOpen}
					toggle={() => removeSelection()}
					profile={selectedTransaction}
					onReverseSuccess={() => onReverseSuccess()}
				/>
			)}
		</>
	);
};
