import { CprApprovalIndicator } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { approvalIndicatorToName } from 'RtUi/utils/maps';

interface SomosApprovalIndicatorDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const SomosApprovalIndicatorDataGridColumn = <T = any,>({
	...config
}: SomosApprovalIndicatorDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: CprApprovalIndicator) => {
		return approvalIndicatorToName(value) ?? '';
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: CprApprovalIndicator) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<CprApprovalIndicator>())
	});
};
