import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { FallbackHeaderDataGridColumnConfiguration } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import {
	BooleanDataGridColumn,
	BooleanDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';

type IsActiveDataGridColumnConfiguration<T> = Omit<
	BooleanDataGridColumnConfiguration<T>,
	'header'
> &
	FallbackHeaderDataGridColumnConfiguration<T>;

export const IsActiveDataGridColumn = <T = any,>({
	header = 'Active',
	size = 100,
	...config
}: IsActiveDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return BooleanDataGridColumn({
		header,
		size,
		...config
	});
};
