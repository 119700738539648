import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	AuditTemplateCprIndexRequest,
	AuditTemplateCprIndexResponse,
	AuditTollFreeCprIndexRequest,
	AuditTollFreeCprIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchAuditTemplateCpr = async (
	queryParams?: AuditTemplateCprIndexRequest
): Promise<FullResponse<AuditTemplateCprIndexResponse[]>> => {
	return handleGetRequest<AuditTemplateCprIndexResponse[], true>(
		Rt800ApiRoutes.AuditTemplateCprs.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetAuditTemplateCpr = (
	queryParams?: AuditTemplateCprIndexRequest
) => {
	return useQuery<FullResponse<AuditTemplateCprIndexResponse[]>, Error>(
		[`useGetAuditTemplateCpr`],
		() => fetchAuditTemplateCpr(queryParams)
	);
};

export const fetchAuditTollFreeCpr = async (
	queryParams?: AuditTollFreeCprIndexRequest
): Promise<FullResponse<AuditTollFreeCprIndexResponse[]>> => {
	return handleGetRequest<AuditTollFreeCprIndexResponse[], true>(
		Rt800ApiRoutes.AuditTollFreeCprs.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetAuditTollFreeCpr = (
	queryParams?: AuditTollFreeCprIndexRequest
) => {
	return useQuery<FullResponse<AuditTollFreeCprIndexResponse[]>, Error>(
		[`useGetAuditTollFreeCpr`],
		() => fetchAuditTollFreeCpr(queryParams)
	);
};
