/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ServerGroupMemberIndexResponse = {
    properties: {
        serverGroupMemberId: {
            type: 'number',
            isRequired: true,
        },
        serverGroupId: {
            type: 'number',
            isRequired: true,
        },
        serverId: {
            type: 'number',
            isRequired: true,
        },
    },
};