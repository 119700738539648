import { ResourceConfigurationIndexResponse } from 'RtModels';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';
import { ResourceConnectionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ResourceConnectionDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';

export const getResourceConfigurationColumns = (): Array<
	DataGridColumn<ResourceConfigurationIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'resourceConfigurationId',
		header: 'ID'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	ResourceConnectionDataGridColumn({
		accessorKey: 'resourceId',
		header: 'Connection Label'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isActive',
		header: 'Active'
	})
];
