import { useState } from 'react';
import { useMount } from 'react-use';
import { CdrExportControlIndexResponse } from 'RtModels';
import { CdrRecurringExportRouter } from 'RtUi/app/Administration/CdrRecurringExport/CdrRecurringExport.router';
import { CdrRecurringExportEditor } from 'RtUi/app/Administration/CdrRecurringExport/lib/forms/CdrRecurringExportEditor';
import { CdrRecurringExportResource } from 'RtUi/app/Administration/CdrRecurringExport/lib/resources/CdrRecurringExportResource';
import { CdrExportGrid } from 'RtUi/app/rtSip/CdrExports/lib/grids/CdrExportGrid';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const CdrRecurringExportProfileContainer = () => {
	const entityId = useIdParam();
	const [entity, setEntity] = useState<CdrExportControlIndexResponse>();
	const [tabs, activeTab, setActiveTab] = useTabs(
		CdrRecurringExportRouter.getProfileTabs()
	);
	useMount(async () => {
		const resource = new CdrRecurringExportResource();
		const newEntity = await resource.get(entityId);

		setEntity(newEntity);
	});

	if (!entity) {
		return <Loading />;
	}

	return (
		<TabbedLayout
			router={CdrRecurringExportRouter}
			profile={entity}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<CdrRecurringExportEditor
					editMode={entity}
					onUpdate={(newProfile) => {
						setEntity(newProfile);
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Exports}>
				<CdrExportGrid
					resourceParams={{
						cdrExportControlId: entity.cdrExportControlId
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

CdrRecurringExportRouter.setProfileRtUiFunctionalComponent(
	CdrRecurringExportProfileContainer
);
