import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	PartitionRespOrgCreateRequest,
	PartitionRespOrgCreateUrlRequest,
	PartitionRespOrgIndexResponse,
	PartitionRespOrgProfileResponse,
	PartitionRespOrgProfileUrlRequest,
	PartitionRespOrgUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class PartitionRespOrgResource extends HttpResource<
	PartitionRespOrgIndexResponse,
	PartitionRespOrgProfileResponse
> {
	constructor() {
		super(RtAdmApiRoutes.PartitionRespOrg);
	}

	public create(partitionId: number, request: PartitionRespOrgCreateRequest) {
		const urlParams: PartitionRespOrgCreateUrlRequest = { partitionId };
		const req: PartitionRespOrgUpdateRequest = request;
		const body = JSON.stringify(req);

		return this.fetchWithRoute<PartitionRespOrgProfileResponse>(
			RtAdmApiRoutes.PartitionRespOrg.Create,
			{ body, urlParams }
		);
	}

	public update(
		partitionId: number,
		partitionRespOrgId: number,
		request: PartitionRespOrgUpdateRequest
	) {
		const urlParams: PartitionRespOrgProfileUrlRequest = {
			partitionId: partitionId,
			partitionRespOrgId: partitionRespOrgId
		};
		const body = JSON.stringify(request);

		return this.fetchWithRoute<PartitionRespOrgProfileResponse>(
			RtAdmApiRoutes.PartitionRespOrg.Update,
			{ body, urlParams }
		);
	}

	public delete(partitionId: number, partitionRespOrgId: number) {
		const urlParams: PartitionRespOrgProfileUrlRequest = {
			partitionId,
			partitionRespOrgId
		};

		return this.fetchWithRoute<PartitionRespOrgProfileResponse>(
			RtAdmApiRoutes.PartitionRespOrg.Delete,
			{ urlParams }
		);
	}
}
