/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { CicIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchCicLookups = async (): Promise<FullResponse<CicIndexResponse[]>> => {
	return handleGetRequest<CicIndexResponse[], true>(Rt800ApiRoutes.Cics.Index, {
		includeFullResponse: true
	});
};

export const useGetCicLookups = () => {
	return useQuery<FullResponse<CicIndexResponse[]>, Error>(
		'getCicLookups',
		() => {
			return fetchCicLookups();
		}
	);
};
