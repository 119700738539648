import { NanpStateIndexResponse } from 'RtModels';
import { LcrCarriersResource } from 'RtUi/app/rtLco/Carriers/lib/resources/LcrCarriersResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface NanpRegionDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		NanpStateIndexResponse,
		T
	> {}

export const NanpRegionDataGridCache =
	new ResourceCacheMap<NanpStateIndexResponse>(
		new LcrCarriersResource().getStatesResource(),
		'nanpStateId',
		'label'
	);

export const NanpRegionDataGridColumn = <T = any,>(
	config: NanpRegionDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: NanpRegionDataGridCache,
		...config
	});
};
