import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { LcrCarrierRouter } from 'RtUi/app/rtLco/Carriers/LcrCarrier.router';
import { LcrCarriersGrid } from 'RtUi/app/rtLco/Carriers/lib/grids/LcrCarriersGrid';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { LcrCarrierEditor } from 'RtUi/app/rtLco/Carriers/lib/components/LcrCarrierEditor';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import {
	RespOrgIndexResponse,
	LcrCarrierProfileResponse,
	TemplateIndexResponse
} from 'RtModels';

interface IInitialResourceParams {
	isActive: number;
	respOrg: RespOrgIndexResponse | undefined;
	template: TemplateIndexResponse | undefined;
}

interface ILcrCarrierIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@LcrCarrierRouter.getIndexRtUiController()
export class LcrCarrierIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	ILcrCarrierIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		isActive: 1,
		respOrg: undefined,
		template: undefined
	};

	public state: ILcrCarrierIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { isActive, respOrg, template } = this.state;

		const resourceParams: any = {};

		if (isActive >= 0) {
			resourceParams.isActive = isActive;
		}

		if (respOrg) {
			resourceParams.respOrgId = respOrg.respOrgId;
		}

		if (template) {
			resourceParams.templateName = template.templateName;
		}

		return resourceParams;
	}

	public onCarrierCreate(carrierProfile: LcrCarrierProfileResponse) {
		const carrierProfilePath = LcrCarrierRouter.getProfileRoute(
			carrierProfile.lcrCarrierId
		);

		this.goToPath(carrierProfilePath);
	}

	public render() {
		return (
			<TabbedLayout
				router={LcrCarrierRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<RespOrgSelect
							onChange={(respOrg: RespOrgIndexResponse) =>
								this.setState({ respOrg })
							}
							value={this.state.respOrg}
						/>
						<TemplateSelect
							onChange={(template) => this.setState({ template })}
							respOrgId={this.state.respOrg?.respOrgId}
							value={this.state.template}
						/>
						<IsActiveRadioFormControl
							hideBothOption
							onChange={(isActive) => this.setState({ isActive })}
							value={this.state.isActive}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<LcrCarriersGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
				<TabbedLayoutTab
					header="Create LCR Account"
					rtRoute={Rt800ApiRoutes.LcrCarriers.Create}
					isAction
				>
					<LcrCarrierEditor
						createMode
						onUpdate={(carrierProfile) => this.onCarrierCreate(carrierProfile)}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
