import {
	GuardianServiceNumberSubscriptionCreateRequestBulkItem,
	GuardianServiceNumberSubscriptionIndexResponse,
	GuardianServiceNumberSubscriptionWithGlobalData,
	PortingStatusIndexResponse
} from 'RtModels';
import { GuardianServiceNumberSubscriptionPropertyToNameMap } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/grids/Constants';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { PortingStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PortingStatusDataGridColumn';
import { ScenarioDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ScenarioDataGridColumn';
import { ServiceNumberLookupDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ServiceNumberLookupDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { UserNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

const getPropertyAndHeaderFor = (
	accessorKey: keyof GuardianServiceNumberSubscriptionWithGlobalData
) => {
	const header =
		GuardianServiceNumberSubscriptionPropertyToNameMap.get(accessorKey) ?? '';

	return { accessorKey, header };
};

export const getServiceNumberSubscriptionColumns = (): Array<
	DataGridColumn<GuardianServiceNumberSubscriptionWithGlobalData>
> => [
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('guardianServiceNumberSubscriptionId'),
		align: DataGridColumnAlignment.CENTER
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('serviceNumber')
	}),
	SubscriptionDataGridColumn({
		...getPropertyAndHeaderFor('subscriptionId')
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('callType')
	}),
	BooleanDataGridColumn({
		...getPropertyAndHeaderFor('isActive')
	}),
	ServiceNumberLookupDataGridColumn({
		...getPropertyAndHeaderFor('directionId')
	}),
	TimeStampDataGridColumn({
		...getPropertyAndHeaderFor('startTs'),
		timezone: 'UTC',
		showTimezone: true
	}),
	TimeStampDataGridColumn({
		...getPropertyAndHeaderFor('endTs'),
		timezone: 'UTC',
		showTimezone: true
	}),
	TimeStampDataGridColumn({
		...getPropertyAndHeaderFor('createdTs')
	}),
	TimeStampDataGridColumn({
		...getPropertyAndHeaderFor('updatedTs')
	}),
	UserNameDataGridColumn({
		...getPropertyAndHeaderFor('updatedBy')
	}),
	ScenarioDataGridColumn({
		...getPropertyAndHeaderFor('scenarioId'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('custExtRef1'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('custExtRef2'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('custExtRef3'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('custOrderNumber'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('translatedNumberStd'),
		isHidden: true
	}),
	BooleanDataGridColumn({
		...getPropertyAndHeaderFor('isPorted'),
		isHidden: true
	}),
	PortingStatusDataGridColumn({
		...getPropertyAndHeaderFor('portingStatusId'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('pin'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('notes'),
		isHidden: true
	}),
	TimeStampDataGridColumn({
		...getPropertyAndHeaderFor('assignedTs'),
		isHidden: true
	}),
	TimeStampDataGridColumn({
		...getPropertyAndHeaderFor('portedTs'),
		isHidden: true
	}),
	TimeStampDataGridColumn({
		...getPropertyAndHeaderFor('activatedTs'),
		isHidden: true
	}),
	TimeStampDataGridColumn({
		...getPropertyAndHeaderFor('decommissionedTs'),
		isHidden: true
	}),
	BooleanDataGridColumn({
		...getPropertyAndHeaderFor('cnamReg'),
		isHidden: true
	}),
	BooleanDataGridColumn({
		...getPropertyAndHeaderFor('cnamDip'),
		isHidden: true
	}),
	BooleanDataGridColumn({
		...getPropertyAndHeaderFor('isDirAsst'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('dirAsstName'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('dirAsstAddress1'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('dirAsstAddress2'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('dirAsstCity'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('dirAsstState'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('dirAsstZip'),
		isHidden: true
	}),
	BooleanDataGridColumn({
		...getPropertyAndHeaderFor('isE911'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('e911Name'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('e911Address1'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('e911Address2'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('e911City'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('e911State'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('e911Zip'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('vendExtRef1'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('vendExtRef2'),
		isHidden: true
	}),
	DefaultDataGridColumn({
		...getPropertyAndHeaderFor('filename'),
		isHidden: true
	})
];

export const getGuardianServiceNumberSubscriptionColumns = (
	portingStatus?: PortingStatusIndexResponse,
	custOrderNumber?: string
): Array<DataGridColumn<GuardianServiceNumberSubscriptionIndexResponse>> => [
	DefaultDataGridColumn({
		accessorKey: 'serviceNumber',
		header: 'Service Number'
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'startTs',
		header: 'Start',
		timezone: 'UTC',
		showTimezone: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'endTs',
		header: 'End',
		timezone: 'UTC',
		showTimezone: true
	}),
	DefaultDataGridColumn({
		header: 'Porting Status',
		id: 'portingStatus',
		accessorFn: () => portingStatus?.label
	}),
	DefaultDataGridColumn({
		header: 'Customer Order Number',
		id: 'custOrderNumber',
		isInvisible: !Boolean(custOrderNumber),
		accessorFn: () => custOrderNumber
	})
];

export const getGuardianServiceNumberSubscriptionCreateColumns = (): Array<
	DataGridColumn<GuardianServiceNumberSubscriptionCreateRequestBulkItem>
> => [
	DefaultDataGridColumn({
		accessorKey: 'serviceNumber',
		header: 'Service Number'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'startTs',
		header: 'Start',
		timezone: 'UTC',
		showTimezone: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'endTs',
		header: 'End',
		timezone: 'UTC',
		showTimezone: true
	}),
	DefaultDataGridColumn({
		header: 'Service Type Id',
		accessorKey: 'serviceTypeId'
	}),
	ServiceNumberLookupDataGridColumn({
		header: 'Direction',
		accessorKey: 'directionId'
	}),
	BooleanDataGridColumn({
		header: 'Is Active',
		accessorKey: 'isActive'
	}),
	ScenarioDataGridColumn({
		header: 'Scenario Id',
		accessorKey: 'scenarioId'
	}),
	DefaultDataGridColumn({
		header: 'Translated Number Std',
		accessorKey: 'translatedNumberStd'
	}),
	BooleanDataGridColumn({
		header: 'Is Ported',
		accessorKey: 'isPorted'
	}),
	PortingStatusDataGridColumn({
		header: 'Porting Status',
		accessorKey: 'portingStatusId'
	}),
	DefaultDataGridColumn({
		header: 'Pin',
		accessorKey: 'pin'
	}),
	DefaultDataGridColumn({
		header: 'Notes',
		accessorKey: 'notes'
	}),
	TimeStampDataGridColumn({
		header: 'Assigned Ts',
		accessorKey: 'assignedTs'
	}),
	TimeStampDataGridColumn({
		header: 'Ported Ts',
		accessorKey: 'portedTs'
	}),
	TimeStampDataGridColumn({
		header: 'Activated Ts',
		accessorKey: 'activatedTs'
	}),
	TimeStampDataGridColumn({
		header: 'Decommissioned Ts',
		accessorKey: 'decommissionedTs'
	}),
	DefaultDataGridColumn({
		header: 'Cnam Reg',
		accessorKey: 'cnamReg'
	}),
	DefaultDataGridColumn({
		header: 'Cnam Dip',
		accessorKey: 'cnamDip'
	}),
	BooleanDataGridColumn({
		header: 'Is Dir Asst',
		accessorKey: 'isDirAsst'
	}),
	DefaultDataGridColumn({
		header: 'Dir Asst Name',
		accessorKey: 'dirAsstName'
	}),
	DefaultDataGridColumn({
		header: 'Dir Asst Address 1',
		accessorKey: 'dirAsstAddress1'
	}),
	DefaultDataGridColumn({
		header: 'Dir Asst Address 2',
		accessorKey: 'dirAsstAddress2'
	}),
	DefaultDataGridColumn({
		header: 'Dir Asst City',
		accessorKey: 'dirAsstCity'
	}),
	DefaultDataGridColumn({
		header: 'Dir Asst State',
		accessorKey: 'dirAsstState'
	}),
	DefaultDataGridColumn({
		header: 'Dir Asst Zip',
		accessorKey: 'dirAsstZip'
	}),
	BooleanDataGridColumn({
		header: 'Is 911',
		accessorKey: 'isE911'
	}),
	DefaultDataGridColumn({
		header: '911 Name',
		accessorKey: 'e911Name'
	}),
	DefaultDataGridColumn({
		header: '911 Address 1',
		accessorKey: 'e911Address1'
	}),
	DefaultDataGridColumn({
		header: '911 Address 2',
		accessorKey: 'e911Address2'
	}),
	DefaultDataGridColumn({
		header: '911 City',
		accessorKey: 'e911City'
	}),
	DefaultDataGridColumn({
		header: '911 State',
		accessorKey: 'e911State'
	}),
	DefaultDataGridColumn({
		header: '911 Zip',
		accessorKey: 'e911Zip'
	}),
	DefaultDataGridColumn({
		header: 'Cust Order Number',
		accessorKey: 'custOrderNumber'
	}),
	DefaultDataGridColumn({
		header: 'Cust Ext Ref 1',
		accessorKey: 'custExtRef1'
	}),
	DefaultDataGridColumn({
		header: 'Cust Ext Ref 2',
		accessorKey: 'custExtRef2'
	}),
	DefaultDataGridColumn({
		header: 'Cust Ext Ref 3',
		accessorKey: 'custExtRef3'
	}),
	DefaultDataGridColumn({
		header: 'Vend Ext Ref 1',
		accessorKey: 'vendExtRef1'
	}),
	DefaultDataGridColumn({
		header: 'Vend Ext Ref 2',
		accessorKey: 'vendExtRef2'
	}),
	TimeStampDataGridColumn({
		header: 'Deleted Ts',
		accessorKey: 'deletedTs'
	})
];
