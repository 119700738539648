import { RtxSipApiRoutes } from 'RtExports/routes';
import { ScenarioIndexResponse, ScenarioProfileResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IScenarioContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	//Actions: ITabbedLayoutTabProps;
}

export const ScenarioContainerTabs: IScenarioContainerTabs = {
	Profile: {
		header: 'Profile'
	}
};

class ScenarioRouterClass extends SipApplicationRouter<
	ScenarioIndexResponse,
	ScenarioProfileResponse,
	IScenarioContainerTabs
> {
	constructor() {
		super('Scenario', 'scenarios', 'scenarioId', ScenarioContainerTabs);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.Scenarios);
	}

	// Removed as Per Russ' Request
	// public globalSearch(search: string) {
	// 	return this.defaultGlobalSearch(ScenarioResource, search);
	// }

	public getProfileLabel(profile: ScenarioProfileResponse) {
		return profile.label;
	}

	public getIndexLabel(record: ScenarioIndexResponse): string {
		return record.label;
	}
}

export const ScenarioRouter = new ScenarioRouterClass();
