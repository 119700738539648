import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
	ScenarioActionForm,
	IScenarioActionFormState
} from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionForm';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { PlaneToneFormControl } from 'RtUi/app/rtSip/Scenarios/lib/controls/PlayToneFormControl';
import { ScenarioActionPlayTone } from 'RtModels';

export class ScenarioActionPlayToneForm extends ScenarioActionForm<ScenarioActionPlayTone> {
	public state: IScenarioActionFormState = {};

	public updateTone(tone: string) {
		const { action } = this.props;

		action.tone = tone;

		this.props.onChange(action);
	}

	public updateDuration(durationMs: number | null) {
		const { action } = this.props;

		if (durationMs !== null) {
			action.durationMs = durationMs;
		}

		this.props.onChange(action);
	}

	public render(): React.ReactNode {
		const { action } = this.props;

		return (
			<Row>
				<Col md>
					<PlaneToneFormControl
						required
						displayMode={this.props.displayMode}
						onChange={(tone) => this.updateTone(tone)}
						value={action.tone}
					/>
				</Col>
				<Col md>
					<PrecisionFormControl
						label="Duration In Milliseconds"
						required
						precision={0}
						displayMode={this.props.displayMode}
						onChange={(durationMs) => this.updateDuration(durationMs)}
						value={action.durationMs}
					/>
				</Col>
			</Row>
		);
	}
}
