import {
	DownloadIndexRequest,
	DownloadIndexResponse,
	DownloadProfileResponse
} from 'RtModels';
import { DownloadsResource } from 'RtUi/app/user/MyProfile/lib/resources/DownloadsResource';
import { useGetUserDownloads } from 'RtUi/app/user/MyProfile/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getUserDownloadColumns } from 'RtUi/components/data/DataGrid/configurations/user';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { useCallback, useMemo } from 'react';

export interface IDownloadsGridProps {
	autoFocusFilter?: boolean;
	resourceParams?: DownloadIndexRequest;
}

const fileUtils = new FileUtils();

export const DownloadsGrid = ({
	resourceParams
}: IDownloadsGridProps): JSX.Element => {
	const downloadFile = useCallback(async (downloadId: number) => {
		const res = (await new DownloadsResource().get(
			downloadId
		)) as DownloadProfileResponse;

		return fileUtils.downloadFromUrlWithPromise(
			Promise.resolve({
				url: res.signedUrl,
				fileName: res.fileName
			})
		);
	}, []);

	const { data, isFetching: isLoading } = useGetUserDownloads(resourceParams);
	const columns = useMemo(
		() => getUserDownloadColumns(downloadFile),
		[downloadFile]
	);

	return (
		<DataGrid<DownloadIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'user_downloads'}
		/>
	);
};
