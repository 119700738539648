import * as React from 'react';
import { Rt800Router } from 'RtUi/app/rt800/rt800.router';
import {
	RtUiRouter,
	SearchConfidenceLevel
} from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { tfnStatusToName } from 'RtUi/utils/maps';
import { IMgiTaskLabelType } from '../Tasks/lib/interfaces';
import { MgiTaskOptionsHelper } from '../Tasks/lib/MgiTaskOptions';
import {
	INumberIndex,
	INumberProfile,
	NumberResource
} from './lib/resources/NumberResource';
import { TollFreeNumbersUtils } from './lib/utils/TollFreeNumbersUtils';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { cloneDeep, padEnd, assign } from 'lodash-es';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';

export interface IMgiTaskLabelTypeTabProps extends TabbedLayoutTabProps {
	header: IMgiTaskLabelType;
}

interface ITabbedNumberContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Trouble: TabbedLayoutTabProps;
	History: TabbedLayoutTabProps;
	Routing: TabbedLayoutTabProps;
}

export const NumberContainerTabs: ITabbedNumberContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Trouble: {
		header: 'Trouble'
	},
	History: {
		header: 'History'
	},
	Routing: {
		header: 'Routing Profiles',
		requireCanManage: true
	},
	Tasks: {
		header: 'Tasks'
	}
};

const SEARCH_REGEX = /^1?(\d{9,})/g;

export class NumberRouterClass extends Rt800Router<
	INumberIndex,
	INumberProfile,
	ITabbedNumberContainerTabs
> {
	public static numberSearchPrefixes = [
		'800',
		'833',
		'844',
		'855',
		'866',
		'877',
		'888'
	];

	public tollFreeNumbersUtils = new TollFreeNumbersUtils();

	constructor() {
		super('Toll-Free Number', 'numbers', 'tfn', NumberContainerTabs);

		this.setProfilePermissions(Rt800ApiRoutes.TollFrees.Profile.permissions);
		this.setIndexPermissions(Rt800ApiRoutes.TollFrees.Index.permissions);
	}

	public globalSearchConfidenceCheck(search: string) {
		const term = PhoneNumberFormatter.convertLettersToNumbers(search)
			.replace(/\D/g, '')
			.replace(SEARCH_REGEX, '$1');

		if (
			NumberRouterClass.numberSearchPrefixes.some((p) => term.startsWith(p))
		) {
			return SearchConfidenceLevel.Certainty;
		}
		return SearchConfidenceLevel.Impossible;
	}

	public async globalSearch(search: string) {
		const term = PhoneNumberFormatter.convertLettersToNumbers(search).replace(
			/\D/g,
			''
		);

		if (!term.match(SEARCH_REGEX)) {
			return Promise.resolve([]);
		}

		const validTfns =
			this.tollFreeNumbersUtils.filterListOfPossibleTollFreeNumbers(term);

		if (validTfns.length > 1) {
			const numberIndexRoute = this.getIndexRoute(true);
			const commaEncoded = encodeURIComponent(',');
			const searchParams = new URLSearchParams({
				tfns: validTfns.join(commaEncoded)
			});
			const href = BrowserHistory.createHref({
				pathname: numberIndexRoute,
				search: searchParams.toString()
			});
			BrowserHistory.push(href);

			return Promise.resolve([]);
		}

		const filledTerm = padEnd(term.replace(SEARCH_REGEX, '$1'), 10, '*');

		const isNumberSearch = NumberRouterClass.numberSearchPrefixes.some(
			(prefix) => filledTerm.startsWith(prefix)
		);

		if (!isNumberSearch) {
			return Promise.resolve([]);
		}

		return this.defaultGlobalSearch(NumberResource, filledTerm, 9);
	}

	/**
	 * @override
	 * @param record
	 * @param details
	 */
	public getSummaryComponentFor(
		record: INumberIndex,
		externalRouters?: RtUiRouter[]
	): React.ReactNode {
		const formattedTfn = TollFreeNumbersUtils.tollFreeNumberToReadableString(
			record.tfn
		);
		const badges: string[] = [];

		if (record.respOrgId) {
			badges.push(record.respOrgId);
		}
		if (record.templateName) {
			badges.push(record.templateName);
		}

		const header = <header>{formattedTfn}</header>;

		return super.createSummaryComponentFor(
			record,
			header,
			badges,
			externalRouters
		);
	}

	/**
	 * Must refresh actions when permissions update
	 * @override
	 * @param record
	 */
	public getProfileTabs(record?: any) {
		const NumberContainerTabsInstance = cloneDeep(NumberContainerTabs);
		const actionTabs = this.getActionTabs(record);

		assign(NumberContainerTabsInstance, actionTabs);

		return NumberContainerTabsInstance;
	}

	public getActionTabs(record?: any): IMgiTaskLabelTypeTabProps[] {
		const actionTabs: IMgiTaskLabelTypeTabProps[] = [];
		const canManage = this.canManageWith(record);
		const mgiTaskOptionsHelperInstance = MgiTaskOptionsHelper.getInstance();

		if (canManage) {
			mgiTaskOptionsHelperInstance
				.getNumberOptionLabels()
				.forEach((numberOptionsLabel) => {
					actionTabs.push({
						header: numberOptionsLabel,
						isAction: true
					});
				});
		}

		if (typeof record === 'object' && 'tfnStatusId' in record) {
			const reserveOption =
				mgiTaskOptionsHelperInstance.getReserveOptionForNumberIfUserHasAccess();
			const tfnStatusId = record.tfnStatusId as string;
			const isSpare = tfnStatusToName(tfnStatusId) === 'Spare';

			if (isSpare && reserveOption) {
				actionTabs.push({
					header: reserveOption.label,
					isAction: true
				});
			}
		}

		return actionTabs;
	}

	public getIndexLabel(record: INumberIndex) {
		const tfn = TollFreeNumbersUtils.tollFreeNumberToReadableString(record.tfn);
		let label = tfn;

		if (record.respOrgId) {
			label += ` (${record.respOrgId})`;
		}

		return label;
	}

	public getProfileLabel(profile: INumberProfile) {
		return TollFreeNumbersUtils.tollFreeNumberToReadableString(profile.tfn);
	}
}

export const NumberRouter = new NumberRouterClass();
