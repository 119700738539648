import { FC, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	EntityIndexResponse,
	RespOrgIndexResponse,
	TemplateCprIndexResponse,
	TemplateIndexRequest
} from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { TemplateCprsRouter } from 'RtUi/app/rt800/Cprs/Cprs.router';
import {
	CarrierSelect,
	ICicIndexResponse
} from 'RtUi/app/rt800/Cprs/lib/controls/CarrierSelect';
import { NetworkAreaOfServiceValuesSelect } from 'RtUi/app/rt800/Cprs/lib/controls/NetworkAreaOfServiceValuesSelect';
import { INetworkAreaOfServiceValuesType } from 'RtUi/app/rt800/Cprs/lib/resources/AreaOfServiceValuesResource';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { CreateTemplate } from 'RtUi/app/rt800/Templates/lib/forms/CreateTemplate';
import { TemplateGrid } from 'RtUi/app/rt800/Templates/lib/grids/TemplateGrid';
import { TemplateRouter } from 'RtUi/app/rt800/Templates/Template.router';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useReplaceWindowUrl } from 'RtUi/utils/hooks/useReplaceWindowUrl';

export const TemplateIndexContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		'Search': { header: 'Search' },
		'Results': { header: 'Results', isDefault: true },
		'Create Template': { header: 'Create Template', isAction: true }
	});

	const [entity, setEntity] = useState<EntityIndexResponse>();
	const [respOrg, setRespOrg] = useState<RespOrgIndexResponse>();
	const [isActive, setIsActive] = useState<number | undefined>(1);
	const [isReserveTemplate, setIsReserveTemplate] = useState<number>(-1);
	const [isRoutingTemplate, setIsRoutingTemplate] = useState<number>(-1);
	const [isSourceTemplate, setIsSourceTemplate] = useState<number>(-1);
	const [isTargetTemplate, setIsTargetTemplate] = useState<number>(-1);
	const [carriers, setCarriers] = useState<ICicIndexResponse[]>();
	const [areaOfServices, setAreaOfServices] =
		useState<INetworkAreaOfServiceValuesType[]>();

	const resourceParams: TemplateIndexRequest = {};

	if (entity !== undefined) {
		resourceParams.entityId = entity.entityId;
	}

	if (respOrg !== undefined) {
		resourceParams.respOrgId = respOrg.respOrgId;
	}

	if (isActive !== undefined) {
		resourceParams.isActive = isActive;
	}

	if (isReserveTemplate >= 0) {
		resourceParams.isReserveTemplate = isReserveTemplate;
	}

	if (isRoutingTemplate >= 0) {
		resourceParams.isRoutingTemplate = isRoutingTemplate;
	}

	if (isSourceTemplate >= 0) {
		resourceParams.isSourceTemplate = isSourceTemplate;
	}

	if (isTargetTemplate >= 0) {
		resourceParams.isTargetTemplate = isTargetTemplate;
	}

	if (carriers) {
		resourceParams.carriers = carriers.map((carrier) => carrier.cic);
	}

	if (areaOfServices) {
		resourceParams.aosValues = areaOfServices.map((aos) => aos.value);
	}

	const goToCpr = (
		templateName: string,
		firstRoute: TemplateCprIndexResponse
	) => {
		const pathToCpr = TemplateCprsRouter.getProfileRoute(firstRoute);
		BrowserHistory.push(pathToCpr);
	};
	useReplaceWindowUrl(activeTab.header, resourceParams);

	return (
		<TabbedLayout
			router={TemplateRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					wide
					onSubmit={(evt) => {
						evt.preventDefault();

						setActiveTab(tabs.Results.header);
					}}
				>
					<Row>
						<Col md>
							<EntitySelect
								onChange={(et) => {
									setEntity(et ?? undefined);
									setRespOrg(undefined);
								}}
								value={entity}
							/>
							<RespOrgSelect
								onChange={(rOrg) => {
									setRespOrg(rOrg ?? undefined);
								}}
								entityId={entity?.entityId}
								value={respOrg}
							/>
							<CarrierSelect<true>
								multi
								onChange={(carriersArray) => {
									setCarriers(carriersArray);
								}}
								value={carriers}
							/>
							<NetworkAreaOfServiceValuesSelect<true>
								label="Areas of Service"
								multi
								onChange={(areaOfServicesArray) => {
									setAreaOfServices(areaOfServicesArray);
								}}
								value={areaOfServices}
							/>
							<IsActiveRadioFormControl
								label="Active"
								onChange={(active) => {
									setIsActive(active);
								}}
								value={isActive}
							/>
						</Col>
						<Col md>
							<BooleanRadioFormControl
								label="Reserve"
								showBothOption
								onChange={(isReserveTemplate) => {
									setIsReserveTemplate(isReserveTemplate);
								}}
								value={isReserveTemplate}
							/>
							<BooleanRadioFormControl
								label="Is LCR (Generated)"
								showBothOption
								onChange={(isRoutingTemplate) => {
									setIsRoutingTemplate(isRoutingTemplate);
								}}
								value={isRoutingTemplate}
							/>
							<BooleanRadioFormControl
								label="Is Source"
								showBothOption
								onChange={(isSourceTemplate) => {
									setIsSourceTemplate(isSourceTemplate);
								}}
								value={isSourceTemplate}
							/>
							<BooleanRadioFormControl
								label="Is Target"
								showBothOption
								onChange={(isTargetTemplate) => {
									setIsTargetTemplate(isTargetTemplate);
								}}
								value={isTargetTemplate}
							/>
						</Col>
					</Row>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<TemplateGrid autoFocusFilter resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs['Create Template']}>
				<CreateTemplate
					onSuccess={(templateName, firstRoute) =>
						goToCpr(templateName, firstRoute)
					}
				/>
			</TabbedLayoutTab>

			<TabbedLayoutHelpContainer tab={tabs.Results.header}>
				<p>
					Status active/inactive refers to RouteTrust status; not the Somos
					status.
				</p>
			</TabbedLayoutHelpContainer>
		</TabbedLayout>
	);
};

TemplateRouter.setIndexRtUiFunctionalComponent(TemplateIndexContainer, {
	description: 'Routing profile housed as a template (TAD) within Somos.'
});
