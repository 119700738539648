import {
	ReconciliationMappingDataSourceType,
	ReconciliationMappingDataSourceField
} from 'RtModels';

const ReconciliationMappingDataSourceFieldToNameMap = new Map<
	ReconciliationMappingDataSourceField,
	string
>([
	[ReconciliationMappingDataSourceField.Account, 'Account'],
	[ReconciliationMappingDataSourceField.Connection, 'Connection'],
	[ReconciliationMappingDataSourceField.FileStream, 'File Stream'],
	[ReconciliationMappingDataSourceField.Subscription, 'Subscription'],
	[ReconciliationMappingDataSourceField.Switch, 'Switch'],
	[ReconciliationMappingDataSourceField.TrunkGroup, 'Trunk Group'],
	[ReconciliationMappingDataSourceField.CallType, 'Call Type'],
	[ReconciliationMappingDataSourceField.ExternalAccount, 'External Account Id']
]);

export const getReconciliationMappingDataSourceFieldName = (
	type: ReconciliationMappingDataSourceField
): string => {
	if (ReconciliationMappingDataSourceFieldToNameMap.has(type)) {
		return ReconciliationMappingDataSourceFieldToNameMap.get(type)!;
	}
	return '';
};

const ReconciliationMappingDataSourceTypeToNameMap = new Map<
	ReconciliationMappingDataSourceType,
	string
>([
	[ReconciliationMappingDataSourceType.Cost, 'Cost'],
	[ReconciliationMappingDataSourceType.Revenue, 'Revenue']
]);

export const getReconciliationMappingDataSourceTypeName = (
	type: ReconciliationMappingDataSourceType
): string => {
	if (
		ReconciliationMappingDataSourceTypeToNameMap.has(
			(type as any).dataSourceType
		)
	) {
		const name = ReconciliationMappingDataSourceTypeToNameMap.get(
			(type as any).dataSourceType
		);
		if (name === 'cost' && (type as any).dataSourceField === 1) {
			return 'Ingress';
		} else if (name !== 'cost' && (type as any).dataSourceField === 1) {
			return 'Egress';
		}
		return name as string;
	}
	return '';
};
