import { Star, StarBorder } from '@mui/icons-material';
import { GuardianUserQueryIndexResponse } from 'RtModels';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import Accordion from 'react-bootstrap/Accordion';
import Checkbox from '@mui/material/Checkbox';
import { isEmpty } from 'lodash-es';

interface CannedReportProps {
	reports: GuardianUserQueryIndexResponse[];
	favoriteReports?: GuardianUserQueryIndexResponse[];
	handleUpdateReportFavoriteStatus: (
		report: GuardianUserQueryIndexResponse
	) => void;
	handleRedirectToReportPage: (report: GuardianUserQueryIndexResponse) => void;
	loading: boolean;
}

const CannedReportsAccordion = (props: CannedReportProps) => {
	const {
		reports,
		handleUpdateReportFavoriteStatus,
		handleRedirectToReportPage,
		favoriteReports,
		loading
	} = props;
	const networkReports = ['asr', 'cps', 'ports', 'asrLite'];
	const financialReports = [
		'financial',
		'numbers',
		'fourteenDay',
		'superSummary',
		'tollfreeEnhanced'
	];
	const regulatoryReports = ['roboscore'];

	const label = { inputProps: { 'aria-label': 'Favorite' } };

	return (
		<Accordion defaultActiveKey="0">
			{!isEmpty(favoriteReports) && (
				<Accordion.Item eventKey="0">
					<Accordion.Header>Favorites</Accordion.Header>
					{favoriteReports &&
						favoriteReports.map((report) => {
							return (
								<Accordion.Body key={Math.random()}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											padding: '5px',
											width: '50%',
											alignItems: 'center'
										}}
									>
										<div
											onClick={() => handleRedirectToReportPage(report)}
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												cursor: 'pointer'
											}}
										>
											<span
												style={{
													fontSize: '16px',
													marginBottom: '5px'
												}}
											>
												{report.label}
											</span>
											<span style={{ fontSize: '12px' }}>
												{(report as any).queryDescription}
											</span>
										</div>
										<Checkbox
											{...label}
											icon={<StarBorder />}
											checkedIcon={<Star />}
											checked={Boolean((report as any).isFavorite)}
											onChange={() => handleUpdateReportFavoriteStatus(report)}
											disabled={loading}
										/>
									</div>
								</Accordion.Body>
							);
						})}
				</Accordion.Item>
			)}

			<Accordion.Item eventKey="1">
				<Accordion.Header>Network</Accordion.Header>
				{/* check logic */}
				{reports
					.filter((report) => networkReports.includes(report.reportId))
					.map((report) => {
						return (
							<Accordion.Body key={Math.random()}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										padding: '5px',
										width: '50%',
										alignItems: 'center'
									}}
								>
									<div
										onClick={() => handleRedirectToReportPage(report)}
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											cursor: 'pointer'
										}}
									>
										<span
											style={{
												fontSize: '16px',
												marginRight: '5px'
											}}
										>
											{report.label}
										</span>
										<i
											className="fas fa-fw fa-info-circle"
											title={(report as any).queryDescription}
										/>
									</div>
									<Checkbox
										{...label}
										icon={<StarBorder />}
										checkedIcon={<Star />}
										checked={(report as any).isFavorite}
										onChange={() => handleUpdateReportFavoriteStatus(report)}
										disabled={loading}
									/>
								</div>
							</Accordion.Body>
						);
					})}
			</Accordion.Item>
			<Accordion.Item eventKey="2">
				<Accordion.Header>Financial</Accordion.Header>
				{reports
					.filter((report) => financialReports.includes(report.reportId))
					.map((report) => {
						return (
							<Accordion.Body key={Math.random()}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										padding: '5px',
										width: '50%',
										alignItems: 'center'
									}}
								>
									<div
										onClick={() => handleRedirectToReportPage(report)}
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											cursor: 'pointer'
										}}
									>
										<span
											style={{
												fontSize: '16px',
												marginRight: '5px'
											}}
										>
											{report.label}
										</span>
										<i
											className="fas fa-fw fa-info-circle"
											title={(report as any).queryDescription}
										/>
									</div>
									<Checkbox
										{...label}
										icon={<StarBorder />}
										checkedIcon={<Star />}
										checked={(report as any).isFavorite}
										onChange={() => handleUpdateReportFavoriteStatus(report)}
										disabled={loading}
									/>
								</div>
							</Accordion.Body>
						);
					})}{' '}
			</Accordion.Item>
			<Accordion.Item eventKey="3">
				<Accordion.Header>Regulatory</Accordion.Header>
				{reports
					.filter((report) => regulatoryReports.includes(report.reportId))
					.map((report) => {
						return (
							<Accordion.Body key={Math.random()}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										padding: '5px',
										width: '50%',
										alignItems: 'center'
									}}
								>
									<div
										onClick={() => handleRedirectToReportPage(report)}
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											cursor: 'pointer'
										}}
									>
										<span
											style={{
												fontSize: '16px',
												marginRight: '5px'
											}}
										>
											{report.label}
										</span>
										<i
											className="fas fa-fw fa-info-circle"
											title={(report as any).queryDescription}
										/>
									</div>
									<Checkbox
										{...label}
										icon={<StarBorder />}
										checkedIcon={<Star />}
										checked={(report as any).isFavorite}
										onChange={() => handleUpdateReportFavoriteStatus(report)}
										disabled={loading}
									/>
								</div>
							</Accordion.Body>
						);
					})}
			</Accordion.Item>
		</Accordion>
	);
};

export default CannedReportsAccordion;
