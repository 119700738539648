import { HTMLAttributes, Component, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Card, Button, Form } from 'react-bootstrap';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import {
	ISubmitFormControlProps,
	SubmitFormControl
} from 'RtUi/components/form/SubmitFormControl';
import {
	IRtUiFormContextData,
	RtUiFormContext
} from 'RtUi/components/ui/RtUiForm/RtUiFormContext';

export interface IRtUiFormProps
	extends Omit<ISubmitFormControlProps, 'displayMode'> {
	displayMode?: boolean;
	header?: string;
	noValidate?: boolean;
	editBtnTitle?: string;
	createMode?: boolean;
	isSubmitting?: boolean;
	hideButtons?: boolean;
	onCancel?: () => void;
	onChange?: (displayMode: boolean) => void;
	disablePadding?: boolean;
	disableBody?: boolean;
	isSubmitDisabled?: boolean;
	onSubmit?: React.FormEventHandler<HTMLFormElement>;
	className?: string;
	isInline?: boolean;
	hasCreateButton?: boolean;
	hasDeleteButton?: boolean;
	handleClickCreate?: (value?: any) => void;
	handleClickDelete?: () => void;
	hasEditButton?: boolean;
}

interface IRtUiFormState {
	wasSubmitted: boolean;
}

export class RtUiForm extends Component<
	React.PropsWithChildren<IRtUiFormProps>,
	IRtUiFormState
> {
	public static defaultProps = {
		displayMode: false
	};

	public static Footer = (props: HTMLAttributes<HTMLElement>) => (
		<footer className="d-flex justify-content-end card-body px-0" {...props}>
			{props.children}
		</footer>
	);

	public state: IRtUiFormState = {
		wasSubmitted: false
	};

	public componentDidUpdate(
		prevProps: Readonly<PropsWithChildren<IRtUiFormProps>>
	): void {
		if (
			prevProps.isSubmitting &&
			!this.props.isSubmitting &&
			!this.props.error
		) {
			this.setState({ wasSubmitted: false });
		}
	}

	public render() {
		if (this.props.disablePadding) {
			return this.renderForm(this.props.className);
		}

		return (
			<Card body={!this.props.disableBody} className={this.props.className}>
				{this.renderForm()}
			</Card>
		);
	}

	protected renderForm(className?: string) {
		const {
			displayMode,
			createMode = false,
			hasEditButton = true
		} = this.props;
		const {
			onChange = () => ({}),
			hideButtons = false,
			editBtnTitle = 'Edit',
			header
		} = this.props;
		const showEditButton = !hideButtons && displayMode && !createMode;
		const showHeader = Boolean(header) || showEditButton;
		const headerStyle: React.CSSProperties = { paddingBottom: '0.5rem' };
		const showSubmitButtons = !hideButtons && !showEditButton;

		if (this.props.disableBody) {
			headerStyle.paddingTop = '0.5rem';
			headerStyle.paddingRight = '1.25rem';
			headerStyle.paddingLeft = '1.25rem';
		} else {
			headerStyle.height = 46;
			headerStyle.marginTop = '-0.5rem';
			headerStyle.marginBottom = '0.5rem';
		}

		const contextData: IRtUiFormContextData = {
			error: this.props.error,
			isInline: this.props.isInline
		};
		const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
			this.setState({ wasSubmitted: true });

			if (this.props.onSubmit) {
				this.props.onSubmit(event);
			} else {
				event.preventDefault();
			}
		};

		return (
			<RtUiFormContext.Provider value={contextData}>
				<Form
					onSubmit={onSubmit}
					className={clsx('rt-ui-form', className, {
						'display-mode': displayMode,
						'edit-mode': !displayMode,
						'was-validated':
							!Boolean(this.props.noValidate) && this.state.wasSubmitted
					})}
				>
					{showHeader && (
						<header
							className={clsx('d-flex', 'border-bottom', {
								'justify-content-between': header && showEditButton,
								'justify-content-end': showEditButton
							})}
							style={headerStyle}
						>
							{header && (
								<section
									className="d-flex align-items-center"
									style={{ height: 35 }}
								>
									<h6 className="mb-0">
										<b>{header}</b>
									</h6>
								</section>
							)}
							{this.props.hasCreateButton && (
								<Button
									style={{ margin: '2px' }}
									type="button"
									variant="light"
									onClick={() =>
										this.props.handleClickCreate &&
										this.props.handleClickCreate()
									}
								>
									<i className="far fa-fw fa-plus" />
									<span>Create</span>
								</Button>
							)}{' '}
							{this.props.hasDeleteButton && hasEditButton && (
								<Button
									style={{ margin: '2px' }}
									type="button"
									variant="light"
									onClick={() =>
										this.props.handleClickDelete &&
										this.props.handleClickDelete()
									}
								>
									<i className="fa fa-trash" />
									<span style={{ marginLeft: '2px' }}>Delete</span>
								</Button>
							)}
							{showEditButton && hasEditButton && (
								<Button
									style={{ margin: '2px' }}
									type="button"
									variant="light"
									onClick={() => onChange(!displayMode)}
								>
									<i className="far fa-fw fa-edit" />
									<span>&nbsp;{editBtnTitle}</span>
								</Button>
							)}
						</header>
					)}
					{this.props.children}
					{!showSubmitButtons && (
						<FormErrors className="mt-3" error={this.props.error} />
					)}
					{showSubmitButtons && (
						<SubmitFormControl
							{...this.props}
							displayMode={Boolean(this.props.displayMode)}
							isSubmitDisabled={this.props.isSubmitDisabled}
							footerClassName={clsx(
								'd-flex',
								'justify-content-end',
								this.props.disableBody ? 'card-body' : 'py-2'
							)}
						/>
					)}
				</Form>
			</RtUiFormContext.Provider>
		);
	}
}
