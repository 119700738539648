import { RateLoadStatus, RateLoadStep } from 'RtModels';
import { GetRateLoadStatus } from 'RtUi/app/AccountManagement/RateManagement/libs/utils/RateLoadUtils';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { has } from 'lodash-es';
import { Alert } from 'react-bootstrap';

interface RateLoadStepDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	statusProperty?: keyof T;
}

export const RateLoadStepDataGridColumn = <T = any,>({
	size,
	align,
	statusProperty,
	...config
}: RateLoadStepDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: RateLoadStep, row: T) => {
		const styles: React.CSSProperties = {
			width: size
		};

		if (align === DataGridColumnAlignment.CENTER) {
			styles.textAlign = 'center';
		}

		let color = 'light';
		let isRunning = false;
		let statusId: RateLoadStatus | undefined;

		if (statusProperty && has(row, statusProperty)) {
			statusId = row[statusProperty] as RateLoadStatus;
			isRunning = statusId === RateLoadStatus.Running;
			const completedOk = statusId === RateLoadStatus.CompletedOk;

			switch (statusId) {
				case RateLoadStatus.Running:
					color = 'light';
					break;
				case RateLoadStatus.CompletedError:
					color = 'danger';
					break;
				case RateLoadStatus.CompletedWithExceptions:
					color = 'info';
					break;
			}

			if (completedOk) {
				if (value === RateLoadStep.RateLoading) {
					color = 'success';
				} else if (value === RateLoadStep.RateAndCodeAnalysis) {
					color = 'info';
				}
			}
		}

		return (
			<section className="d-flex justify-content-center">
				<Alert
					transition={false}
					style={styles}
					variant={color}
					className="alert-sm mb-0 p-0"
				>
					{isRunning && (
						<span>
							<i className="fas fa-fw fa-spin fa-cog" />
							&nbsp;
						</span>
					)}
					<span>{GetRateLoadStatus(value, statusId)}</span>
				</Alert>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: any, row) => {
			const status = row && statusProperty ? row[statusProperty] : undefined;
			return GetRateLoadStatus(value, status as RateLoadStatus | undefined);
		},
		getValue: ({ cell, row }) =>
			getValue(cell.getValue<RateLoadStep>(), row.original as T)
	});
};
