import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationMappingIndexRequest,
	GuardianReconciliationMappingIndexResponse
} from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchReconciliationMapping = async (
	urlParams: GuardianReconciliationMappingIndexRequest
): Promise<GuardianReconciliationMappingIndexResponse[]> => {
	return handleGetRequest(RtVueApiRoutes.GuardianReconciliationMappings.Index, {
		urlParams
	});
};

export const useGetReconciliationMapping = (
	urlParams: GuardianReconciliationMappingIndexRequest = {}
) => {
	const cacheKey = JSON.stringify(urlParams);

	return useQuery<GuardianReconciliationMappingIndexResponse[], Error>(
		[`getReconciliationMapping`, cacheKey],
		() => {
			return fetchReconciliationMapping(urlParams);
		}
	);
};
