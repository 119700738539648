import { useMemo } from 'react';
import { DashboardIndexResponse, DashboardItemStatus } from 'RtModels';
import { useGetDashboard } from 'RtUi/app/rtCommon/Dashboard/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getDashboardColumns } from 'RtUi/components/data/DataGrid/configurations/rtCommon/dashboard';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';

interface IDashboardGridProps {
	autoFocusFilter?: boolean;
}

export const DashboardDataGrid = ({}: IDashboardGridProps) => {
	const { data, isFetching: isLoading } = useGetDashboard();
	const columns = useMemo(() => getDashboardColumns(), []);

	return (
		<DataGrid<DashboardIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			rowThemeColor={(row) => {
				switch (row.status) {
					case DashboardItemStatus.Error: {
						return RowThemeColor.DANGER;
					}
					case DashboardItemStatus.Warning: {
						return RowThemeColor.WARNING;
					}
					default: {
						return undefined;
					}
				}
			}}
			pageName={'rtCommon_Dashboard'}
		/>
	);
};
