/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocGetDocumentResponse = {
    properties: {
        documentId: {
            type: 'string',
            isRequired: true,
        },
        fileName: {
            type: 'string',
            isRequired: true,
        },
        encodedContent: {
            type: 'string',
            isRequired: true,
        },
        notes: {
            type: 'string',
            isRequired: true,
        },
    },
};