import { GuardianServiceNumberSubscriptionWithGlobalData } from 'RtModels';
import { ServiceNumberPropertyToNameMap } from 'RtUi/app/rtCommon/ServiceNumbers/lib/grids/Constants';

export const GuardianServiceNumberSubscriptionPropertyToNameMap = new Map<
	keyof GuardianServiceNumberSubscriptionWithGlobalData,
	string
>([
	['guardianServiceNumberSubscriptionId', 'ID'],
	['serviceNumber', 'Service Number'],
	['subscriptionId', 'Subscription'],
	['callType', 'Call Type'],
	['isActive', 'Active'],
	['directionId', 'Direction'],
	['serviceTypeId', 'Service Type'],
	['startTs', 'Start'],
	['endTs', 'End'],
	['createdTs', 'Created'],
	['updatedTs', 'Updated'],
	['updatedBy', 'Updated By'],
	...ServiceNumberPropertyToNameMap
]);
