/**
 * One-Time Configuration for Components
 * Usage in app/entry.tsx.
 */
import 'react-dates/initialize';
import 'rheostat/initialize';
import * as moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data.min';
//@ts-expect-error
import ReactDatesDefaultTheme from 'react-dates/lib/theme/DefaultTheme';
//@ts-expect-error
import cssInterface from 'react-with-styles-interface-css';
//@ts-expect-error
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
//@ts-expect-error
import RheostatDefaultTheme from 'rheostat/lib/themes/DefaultTheme';

//Allow for react-dates and Rheostat to exist with one-another
ThemedStyleSheet.registerInterface(cssInterface);
ThemedStyleSheet.registerTheme({
	...RheostatDefaultTheme,
	...ReactDatesDefaultTheme
});

interface IMomentWithInnerProps extends moment.Moment {
	_d: any;
}

moment.fn.round = function (precision, key, direction) {
	if (typeof direction === 'undefined') {
		direction = 'round';
	}

	const keys = ['Hours', 'Minutes', 'Seconds', 'Milliseconds'];
	const maxValues = [24, 60, 60, 1000];

	// Capitalize first letter
	key = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();

	// make sure key is plural
	if (!key.includes('s', key.length - 1)) {
		key += 's';
	}
	let value = 0;
	let rounded = false;
	let subRatio = 1;
	let maxValue = 0;

	// eslint-disable-next-line guard-for-in
	for (const i in keys) {
		const k = keys[i];
		if (k === key) {
			// eslint-disable-next-line no-underscore-dangle
			value = (this as IMomentWithInnerProps)._d['get' + key]();
			maxValue = maxValues[i];
			rounded = true;
		} else if (rounded) {
			subRatio *= maxValues[i];
			// eslint-disable-next-line no-underscore-dangle
			value += (this as IMomentWithInnerProps)._d['get' + k]() / subRatio;
			// eslint-disable-next-line no-underscore-dangle
			(this as IMomentWithInnerProps)._d['set' + k](0);
		}
	}

	value = Math[direction](value / precision) * precision;
	value = Math.min(value, maxValue);
	// eslint-disable-next-line no-underscore-dangle
	(this as IMomentWithInnerProps)._d['set' + key](value);

	return this;
};

moment.fn.ceil = function (precision, key) {
	return this.round(precision, key, 'ceil');
};

moment.fn.floor = function (precision, key) {
	return this.round(precision, key, 'floor');
};
