import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	ServiceNumberIndexResponse,
	ServiceNumberProfileResponse
} from 'RtModels';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IGlobalNumberProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const GlobalNumberProfileContainerTabs: IGlobalNumberProfileContainerTabs =
	{
		Profile: {
			header: 'Profile'
		}
	};

export class GlobalNumbersRouterClass extends RtVueModuleRouter<
	ServiceNumberIndexResponse,
	ServiceNumberProfileResponse,
	IGlobalNumberProfileContainerTabs
> {
	constructor() {
		super(
			'Global Numbers',
			'globalNumbers',
			'serviceNumber',
			GlobalNumberProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.ServiceNumbers);
	}

	public getIndexLabel(record: ServiceNumberIndexResponse) {
		return record.label ?? record.serviceNumber;
	}

	public getProfileLabel(profile: ServiceNumberProfileResponse) {
		return profile.label ?? profile.serviceNumber;
	}
}

export const GlobalNumbersRouter = new GlobalNumbersRouterClass();
