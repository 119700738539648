import { RtVueApiRoutes } from 'RtExports/routes';
import { GuardianAlertTypeIndexResponse } from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class GuardianAlertTypesResource extends ArrayResource<GuardianAlertTypeIndexResponse> {
	constructor() {
		super('alertTypeId');

		this.setApiRouteForGetAll(RtVueApiRoutes.GuardianAlertTypes.Index);
	}
}
