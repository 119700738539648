import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DialCodeIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';

export const getDialCodeColumns = (): Array<
	DataGridColumn<DialCodeIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'dialCode',
		header: 'Dial Code'
	}),
	DefaultDataGridColumn({
		accessorKey: 'countryCode',
		header: 'Country Code'
	}),
	DefaultDataGridColumn({
		accessorKey: 'iso3166Alpha3',
		header: 'Country'
	}),
	RateCenterDataGridColumn({
		accessorKey: 'rateCenterId',
		header: 'Rate Center'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DefaultDataGridColumn({
		accessorKey: 'operatingCompanyCode',
		header: 'Operating Company Code'
	}),
	DefaultDataGridColumn({
		accessorKey: 'operatingCompanyName',
		header: 'Operating Company Name'
	}),
	PhoneNumberDataGridColumn({
		accessorKey: 'phoneNumber',
		header: 'Phone Number'
	}),
	DefaultDataGridColumn({
		accessorKey: 'location1',
		header: 'Location 1'
	}),
	DefaultDataGridColumn({
		accessorKey: 'location2',
		header: 'Location 2'
	}),
	DefaultDataGridColumn({
		accessorKey: 'countrySpecific1',
		header: 'Country Specific 1'
	}),
	DefaultDataGridColumn({
		accessorKey: 'countrySpecific2',
		header: 'Country Specific 2'
	})
];
