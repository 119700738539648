import { SystemNotificationForm } from 'RtUi/app/rtAdmin/SystemNotifications/lib/forms/SystemNotificationForm';
import { Aside } from 'RtUi/components/ui/Aside';
import { IFirebaseUIMessage } from 'RtUi/state/actions/user/BaseFireBase';
import { useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useToggle } from 'react-use';

import { useGetSystemNotifications } from 'RtUi/app/rtAdmin/SystemNotifications/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSystemNotificationColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/systemNotifications';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';

export const SystemNotificationsGrid = () => {
	const [selectedRecord, setSelectedRecord] = useState<
		IFirebaseUIMessage | undefined
	>();

	const columns = useMemo(() => getSystemNotificationColumns(), []);
	const [isAsideOpen, toggleAsideOpen] = useToggle(false);

	const { data, isLoading } = useGetSystemNotifications();
	const closeAside = () => {
		toggleAsideOpen();
		setSelectedRecord(undefined);
	};

	const onRowClick = (row: IFirebaseUIMessage) => {
		setSelectedRecord(row);
		if (!selectedRecord) {
			toggleAsideOpen();
		}
	};

	return (
		<>
			<DataGrid<IFirebaseUIMessage>
				defaultSorting={[
					{
						id: 'expireTs',
						desc: true
					}
				]}
				data={data}
				columns={columns}
				pageName={'rtAdmin-systemNotification'}
				onRowClick={onRowClick}
				loading={isLoading}
				rowThemeColor={(record) =>
					record.uuid === selectedRecord?.uuid
						? RowThemeColor.WARNING
						: undefined
				}
				headerAction={() => {
					return (
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id="addNotificationBtn-tooltip" {...props}>
									Add Notification
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									onClick={toggleAsideOpen}
									variant="white"
									id="addNotificationBtn"
								>
									<i className="fas fa-fw fa-plus fa-lg" />
								</Button>
							)}
						</OverlayTrigger>
					);
				}}
			/>
			<Aside isOpen={isAsideOpen} disabledBody>
				{isAsideOpen && (
					<>
						<Aside.Header
							warning={Boolean(selectedRecord)}
							header="Notification"
							onClose={() => closeAside()}
						/>
						<SystemNotificationForm
							profile={selectedRecord}
							onCancel={closeAside}
							onSuccess={closeAside}
						/>
					</>
				)}
			</Aside>
		</>
	);
};
