import {
	CprRateSheetProfileRequest,
	CprRateSheetResultsProfileResponse
} from 'RtModels';
import { useGetCprRateResultsSheets } from 'RtUi/app/rtLco/RateSheets/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCprRateSheetResultsColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/cprRateSheets';
import { useMemo } from 'react';

interface IRateSheetResultsGridProps {
	resourceParams: CprRateSheetProfileRequest;
	rateSheetTypeId: number;
	autoFocusFilter?: boolean;
}

export const RateSheetResultsGrid = ({
	resourceParams,
	rateSheetTypeId
}: IRateSheetResultsGridProps) => {
	const { data, isFetching: isLoading } =
		useGetCprRateResultsSheets(resourceParams);
	const rateKeyLabels = useMemo(() => {
		if (rateSheetTypeId === 2) {
			return ['LATA', 'OCN', 'NPA', 'NXX'];
		}

		return ['NPA', 'NXX', 'LATA', 'OCN'];
	}, [rateSheetTypeId]);

	const columns = useMemo(
		() => getCprRateSheetResultsColumns(rateKeyLabels),
		[rateKeyLabels]
	);

	return (
		<DataGrid<CprRateSheetResultsProfileResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtLco_rateSheetResults'}
		/>
	);
};

RateSheetResultsGrid.displayName = 'RateSheetResultsGrid';
