import { RtVueModuleRouter } from '../RtVue.router';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianRerateControlIndexResponse,
	GuardianRerateControlProfileResponse
} from 'RtModels';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IRerateControlProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const RerateControlProfileContainerTabs: IRerateControlProfileContainerTabs =
	{
		Profile: {
			header: 'Profile'
		}
	};

class RerateControlRouterClass extends RtVueModuleRouter<
	GuardianRerateControlIndexResponse,
	GuardianRerateControlProfileResponse,
	IRerateControlProfileContainerTabs
> {
	constructor() {
		super(
			'Re-rate Control',
			'reratecontrol',
			'jobId',
			RerateControlProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.GuardianRerateControl);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(
		profile: GuardianRerateControlProfileResponse
	): string {
		return profile.summary || profile.jobId.toString();
	}
}

export const RerateControlRouter = new RerateControlRouterClass();
