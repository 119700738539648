import {
	GenericFilterResponse,
	DataSources,
	SearchField,
	DataFilterOperand
} from 'RtModels';
import { LocalStorageCacheStrategy } from 'RtUi/utils/cache/strategies/LocalStorageCacheStrategy';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';

export interface IFilterSearchResult {
	dataSourceFields: SearchField[];
	operand: DataFilterOperand;
}

export interface IFilterSearchResultSection {
	commonName: string;
	results: IFilterSearchResult[];
}

export interface IGenericFilterResponse<T> extends GenericFilterResponse {
	data: T[];
}

interface IFilterResponseMetadata extends Omit<GenericFilterResponse, 'data'> {}

export type TFilterResponseWithKeywords<T> = T & { keywords: string[] };

/**
 * RtVueFilter caches both data and metadata for Filters
 * as well as renders filters in search
 */
export abstract class RtVueFilter<T> {
	protected metadataCache!: CacheStrategy<IFilterResponseMetadata>;
	protected metadata!: IFilterResponseMetadata;
	protected vueHttp = new RtVueHttp();
	protected initializedDefer: Promise<void> | undefined;

	protected constructor(
		protected filterName: string,
		protected dataSource: DataSources,
		protected dataCache: CacheStrategy<TFilterResponseWithKeywords<T>>
	) {
		this.metadataCache = new LocalStorageCacheStrategy(
			`${filterName}-metadata`
		);
	}

	/**
	 * Get filter's common name
	 */
	public getCommonName() {
		return this.metadata.commonName;
	}

	/**
	 * Get filter's mode
	 */
	public getMode() {
		return this.metadata.mode;
	}

	/**
	 * Get filter's reportIncludes
	 */
	public getReportIncludes() {
		return this.metadata.reportIncludes;
	}

	/**
	 * Get filter's DataSource identifier
	 */
	public getDataSource() {
		return this.dataSource;
	}

	/**
	 * Search through records
	 * @param possibleSearchFields
	 * @param searchStr
	 * @returns a tuple of the results and the applicable SearchFields
	 */
	public async search(
		possibleSearchFields: SearchField[],
		searchStr: string
	): Promise<IFilterSearchResultSection[]> {
		await this.initialize();

		const filterDataSource = this.getDataSource();
		const result: IFilterSearchResultSection = {
			commonName: this.getCommonName(),
			results: []
		};

		const applicableSearchFields = possibleSearchFields.filter((searchField) =>
			searchField.dataSources.includes(filterDataSource)
		);

		if (applicableSearchFields.length <= 0) {
			return [result];
		}

		const minCharacters = applicableSearchFields.reduce(
			(prev, field) => Math.min(prev, field.minCharacters),
			applicableSearchFields[0].minCharacters
		);

		const searchMeetsMinRequirement = searchStr.length >= minCharacters;

		if (!searchMeetsMinRequirement) {
			return [result];
		}

		const searchResults = await this.dataCache.search([{ like: searchStr }]);

		result.results = searchResults.map((searchRes) => {
			return {
				dataSourceFields: applicableSearchFields,
				operand: {
					dataSource: filterDataSource,
					value: this.getValueFor(searchRes)
				}
			};
		});

		return [result];
	}

	/**
	 * Get all available data
	 */
	public async getAll(): Promise<Array<TFilterResponseWithKeywords<T>>> {
		await this.initialize();

		return this.dataCache.getAll();
	}

	public userHasIndexPermissions() {
		return true;
	}

	/**
	 * Get label for a record
	 * @param record
	 */
	public abstract getLabelFor(record: T): string;

	/**
	 * Get value for a record
	 * @param record
	 */
	public abstract getValueFor(record: T): string;

	/**
	 * Given the id of a record, return the label
	 * @param value
	 */
	public async getLabelFromValue(value: string) {
		const records = await this.getAll();

		const foundRecord = records.find(
			(record) => this.getValueFor(record) === value
		);

		if (foundRecord) {
			return this.getLabelFor(foundRecord);
		}

		return value;
	}

	/**
	 * Http method to get the filter's response
	 */
	protected abstract getFilterResponse(): Promise<IGenericFilterResponse<T>>;

	/**
	 * Get keywords for a single record for search purposes
	 * @param record
	 */
	protected abstract getKeywordsFor(record: T): string[];

	/**
	 * Initialize caches
	 */
	public async initialize() {
		if (!this.initializedDefer) {
			this.initializedDefer = new Promise<void>(async (resolve) => {
				if (this.dataCache.isCacheStale()) {
					await this.onCacheStale();
				} else {
					const [records, metadata] = await Promise.all([
						this.dataCache.getAll(),
						this.metadataCache.getAll()
					]);

					if (records.length <= 0 || metadata.length <= 0) {
						await this.onCacheStale();
					} else {
						this.metadata = metadata[0];
					}
				}

				resolve();
			});
		}

		return this.initializedDefer;
	}

	/**
	 * Refresh data and metadata caches
	 */
	protected async onCacheStale() {
		const filterRes = await this.getFilterResponse();

		//eagerly set metadata for usage
		this.metadata = {
			mode: filterRes.mode,
			commonName: filterRes.commonName,
			reportIncludes: filterRes.reportIncludes
		};

		const data: Array<TFilterResponseWithKeywords<T>> = filterRes.data.map(
			(record) => {
				return {
					...record,
					keywords: this.getKeywordsFor(record)
				};
			}
		);

		const cacheDefer = this.dataCache.storeCache(data);
		const metaCacheDefer = this.metadataCache.storeCache([this.metadata]);

		await Promise.all([cacheDefer, metaCacheDefer]);
	}

	/**
	 * Utility method to get words from phrase/sentence
	 */
	protected getKeywordsFromPhrase(phrase: any): string[] {
		if (typeof phrase !== 'string') {
			return [];
		}

		const wordParts = phrase.match(/\b(\w+)\b/g);

		if (!wordParts) {
			return [];
		}

		return Array.from(wordParts);
	}
}
