/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationDisputeIndexResponse = {
    properties: {
        label: {
            type: 'string',
            isRequired: true,
        },
        monthNumber1MonthsAgo: {
            type: 'number',
            isRequired: true,
        },
        monthNumber2MonthsAgo: {
            type: 'number',
            isRequired: true,
        },
        monthNumber3MonthsAgo: {
            type: 'number',
            isRequired: true,
        },
        value1MonthAgo: {
            type: 'number',
            isRequired: true,
        },
        value2MonthAgo: {
            type: 'number',
            isRequired: true,
        },
        value3MonthAgo: {
            type: 'number',
            isRequired: true,
        },
    },
};