import { Rt800ApiRoutes } from 'RtExports/routes';
import { MessageEventType } from 'RtModels';
import {
	AuditTollFreeCprResourceType,
	NumberResource
} from 'RtUi/app/rt800/Numbers/lib/resources/NumberResource';
import { DashboardContent } from 'RtUi/app/rtCommon/DashboardOld/lib/components/DashboardContent';
import {
	UiNotification,
	UiNotificationCategoryEnum
} from 'RtUi/notifications/lib/UiNotification';
import { TollFreeCprAuditUiNotificationGrid } from 'RtUi/notifications/lib/grids/TollFreeCprAuditUiNotificationGrid';

export class TollFreeCprAuditUiNotification extends UiNotification {
	protected numberResource = new NumberResource();
	protected tollFreeAuditResource: AuditTollFreeCprResourceType;

	constructor() {
		super(
			UiNotificationCategoryEnum.Rt800,
			Rt800ApiRoutes.AuditTollFreeCprs.Index.permissions,
			'Toll-Free Routing (CPR) Audit',
			MessageEventType.TollFreeRoutingAudit
		);

		this.tollFreeAuditResource =
			this.numberResource.getAuditCprResourceForTollFrees();

		this.tollFreeAuditResource.setGetAllDoNotNotifyOn404Error(true);
	}

	public getIcon() {
		return <i className="fas fa-phone-alt" />;
	}

	protected getDetailedView() {
		return (
			<DashboardContent
				notification={this}
				renderWhenHasErrors={() => <TollFreeCprAuditUiNotificationGrid />}
			/>
		);
	}

	protected getIssueCount(): Promise<number> {
		return this.tollFreeAuditResource
			.getAll()
			.then((records) => records.length);
	}
}
