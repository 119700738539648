/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum FullSummaryColumns {
    CallingPartyStd = 'callingPartyStd',
    CallingPartyCountryIsoId = 'callingPartyCountryIsoId',
    CallingPartyRateCenterId = 'callingPartyRateCenterId',
    CallingPartyRateCenterTypeId = 'callingPartyRateCenterTypeId',
    CallingPartyAttestation = 'callingPartyAttestation',
    CallingPartyAttestationUrl = 'callingPartyAttestationUrl',
    DialedNumberStd = 'dialedNumberStd',
    DialedNumberCountryIsoId = 'dialedNumberCountryIsoId',
    DialedNumberRateCenterId = 'dialedNumberRateCenterId',
    DialedNumberRateCenterTypeId = 'dialedNumberRateCenterTypeId',
    IngressCostAccountId = 'ingressCostAccountId',
    IngressCostSubscriptionId = 'ingressCostSubscriptionId',
    IngressCostRateKey = 'ingressCostRateKey',
    IngressCostRate = 'ingressCostRate',
    IngressCostMinutes = 'ingressCostMinutes',
    IngressCostTotal = 'ingressCostTotal',
    IngressRevenueAccountId = 'ingressRevenueAccountId',
    IngressRevenueSubscriptionId = 'ingressRevenueSubscriptionId',
    IngressRevenueRateKey = 'ingressRevenueRateKey',
    IngressRevenueRate = 'ingressRevenueRate',
    IngressRevenueMinutes = 'ingressRevenueMinutes',
    IngressRevenueTotal = 'ingressRevenueTotal',
    IngressConnectionRemoteIp = 'ingressConnectionRemoteIp',
    IngressConnectionResult = 'ingressConnectionResult',
    EgressCostAccountId = 'egressCostAccountId',
    EgressCostSubscriptionId = 'egressCostSubscriptionId',
    EgressCostRateKey = 'egressCostRateKey',
    EgressCostRate = 'egressCostRate',
    EgressCostMinutes = 'egressCostMinutes',
    EgressCostTotal = 'egressCostTotal',
    EgressRevenueAccountId = 'egressRevenueAccountId',
    EgressRevenueSubscriptionId = 'egressRevenueSubscriptionId',
    EgressRevenueRateKey = 'egressRevenueRateKey',
    EgressRevenueRate = 'egressRevenueRate',
    EgressRevenueMinutes = 'egressRevenueMinutes',
    EgressRevenueTotal = 'egressRevenueTotal',
    EgressRevenueRpc = 'egressRevenueRpc',
    EgressConnectionRemoteIp = 'egressConnectionRemoteIp',
    EgressConnectionResult = 'egressConnectionResult',
}