import { RocNumbersIndexRequest, RocNumbersIndexResponse } from 'RtModels';
import { RocRequestRouter } from 'RtUi/app/rt800/RespOrgChange/RocRequest.router';
import { useGetRocNumbers } from 'RtUi/app/rt800/RespOrgChange/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRocNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/rocRequests';
import { useMemo } from 'react';

interface IRocNumbersDataGridProps {
	resourceParams: RocNumbersIndexRequest;
}

export const RocNumbersDataGrid = ({
	resourceParams
}: IRocNumbersDataGridProps) => {
	const { data, isFetching: isLoading } = useGetRocNumbers(resourceParams);
	const columns = useMemo(() => getRocNumbersColumns(), []);

	return (
		<DataGrid<RocNumbersIndexResponse>
			router={RocRequestRouter}
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rt800_rocNumbers'}
		/>
	);
};
