import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class RejectDetailsCostReportRouterClass extends RtVueReportRouter<
	void,
	void,
	{}
> {
	constructor() {
		super('Reject Cost Details Report', 'rejectsCostDetails', undefined, {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewRejectDetailsCost);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const RejectDetailsCostReportRouter =
	new RejectDetailsCostReportRouterClass();
