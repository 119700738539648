import { ManagedEntityProfileResponse } from 'RtModels';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { FC, useState } from 'react';
import { ManagedEntityRouter } from './ManagedEntity.router';
import { ManagedEntityEditor } from './lib/forms/ManagedEntityEditor';

import { FlowRouter } from 'RtUi/app/rtAdmin/Flows/Flow.router';
import { ManagedEntitySelect } from 'RtUi/app/rtAdmin/ManagedEntities/lib/controls/ManagedEntitySelect';
import { ManagedEntityGrid } from 'RtUi/app/rtAdmin/ManagedEntities/lib/grids/ManagedEntityGrid';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useNavigate } from 'react-router-dom';

export const ManagedEntityIndexContainer: FC = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		'Results': { header: 'Results', isDefault: true },
		'Search': { header: 'Search' },
		'Create Managed Entity': { header: 'Create Managed Entity', isAction: true }
	});

	const navigate = useNavigate();

	const [managedEntity, setManagedEntity] = useState<any>(null);
	const [isActive, setIsActive] = useState<any>(null);
	const [isManaged, setIsManage] = useState<any>(null);
	const [isFailing, setIsFailing] = useState<any>(null);

	const goToProfile = (managedEntity: ManagedEntityProfileResponse) => {
		const managedEntityPath = ManagedEntityRouter.getProfileRoute(
			managedEntity.entityId
		);

		navigate(managedEntityPath);
	};

	const searchManagedEntities = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		try {
			event.preventDefault();
			setActiveTab('Results');
		} catch (e) {}
	};

	return (
		<TabbedLayout
			router={FlowRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm onSubmit={(e) => searchManagedEntities(e)}>
					<ManagedEntitySelect
						onChange={setManagedEntity}
						value={managedEntity}
					/>
					<IsActiveRadioFormControl
						label="Status"
						onChange={setIsActive}
						value={isActive}
					/>
					<RadioFormControl<number | undefined>
						label="Managed by RouteTrust"
						onChange={setIsManage}
						value={isManaged}
						options={[
							{ value: 1, label: 'Active' },
							{ value: 0, label: 'Inactive' },
							{ value: undefined, label: 'Both' }
						]}
					/>
					<BooleanRadioFormControl
						label="Is Failing"
						onChange={setIsFailing}
						value={isFailing}
						showBothOption
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<>
					<ManagedEntityGrid
						resourceParams={{
							entityId: managedEntity?.entityId,
							isActive,
							isManaged,
							isFailing
						}}
					/>
				</>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs['Create Managed Entity']}>
				<ManagedEntityEditor
					createMode
					onCreate={(profile) => goToProfile(profile)}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ManagedEntityIndexContainer.displayName = 'ManagedEntityIndexContainer';

ManagedEntityRouter.setIndexRtUiFunctionalComponent(
	ManagedEntityIndexContainer
);
