export const isValidJson = (value: string): boolean => {
	try {
		JSON.parse(value);
		return true;
	} catch (e) {
		return false;
	}
};

export const parseJson = <T = any>(value: string, fallback?: T): T => {
	if (isValidJson(value)) {
		return JSON.parse(value);
	}

	return fallback || ({} as any);
};
