import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianRerateControlCreateRequest,
	GuardianRerateControlIndexResponse,
	GuardianRerateControlProfileResponse,
	GuardianRerateControlProfileUrlRequest,
	GuardianRerateControlUpdateRequest
} from 'RtModels';
import { createDateAsUTC } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class RerateControlResource extends HttpResource<
	GuardianRerateControlIndexResponse,
	GuardianRerateControlProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianRerateControl);
	}

	public create(request: GuardianRerateControlCreateRequest) {
		request.queryParameters.timeRange.timeObject.start = createDateAsUTC(
			request.queryParameters.timeRange.timeObject.start
		) as Date;
		request.queryParameters.timeRange.timeObject.end = createDateAsUTC(
			request.queryParameters.timeRange.timeObject.end
		) as Date;

		const body = JSON.stringify(request);
		return this.fetchWithRoute<GuardianRerateControlProfileResponse>(
			RtVueApiRoutes.GuardianRerateControl.Create,
			{
				body
			}
		);
	}

	public update(jobId: number, request: GuardianRerateControlUpdateRequest) {
		if (request.queryParameters) {
			request.queryParameters.timeRange.timeObject.start = createDateAsUTC(
				request.queryParameters.timeRange.timeObject.start
			) as Date;
			request.queryParameters.timeRange.timeObject.end = createDateAsUTC(
				request.queryParameters.timeRange.timeObject.end
			) as Date;
		}

		const urlParams: GuardianRerateControlProfileUrlRequest = { jobId };
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianRerateControlProfileResponse>(
			RtVueApiRoutes.GuardianRerateControl.Update,
			{
				body,
				urlParams
			}
		);
	}
}
