/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SwitchUpdateRequest = {
    properties: {
        label: {
            type: 'string',
        },
        isActive: {
            type: 'number',
        },
        switchTypeId: {
            type: 'number',
        },
        switchMajorVersion: {
            type: 'number',
            isNullable: true,
        },
        switchMinorVersion: {
            type: 'number',
            isNullable: true,
        },
        switchPatchVersion: {
            type: 'number',
            isNullable: true,
        },
    },
};