import { FirebaseRouteType } from 'RtModels';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { IFirebaseUIMessage } from 'RtUi/state/actions/user/BaseFireBase';

/**
 * The ComponentController allows for the Application to register information
 * that the component's library may need, such as Routers.
 */
export class ComponentController {
	/**
	 * Singleton implementation
	 */
	public static getInstance() {
		if (!ComponentController.instance) {
			ComponentController.instance = new ComponentController();
		}

		return ComponentController.instance;
	}

	protected static instance: ComponentController;

	protected Routers: RtUiRouter[] = [];

	protected constructor() {}

	/**
	 * Register the App's routers for usage in component library
	 * @param applicationRouters
	 */
	public registerApplicationRouters(applicationRouters: RtUiRouter[]) {
		this.Routers = applicationRouters;
	}

	/**
	 * Get an array of RtUiRouters
	 */
	public getApplicationRouters() {
		return [...this.Routers];
	}

	/**
	 * Get profile route/path given an IFirebaseUIMessage
	 */
	public getProfilePathFromFirebaseMessage(message: IFirebaseUIMessage) {
		if (typeof message.routeType !== 'number' || !message.routeTypeId) {
			return null;
		}
		const router = this.getRouterWithFirebaseRouteType(message.routeType);

		if (!router) {
			return null;
		}

		return router.getProfileRoute(message.routeTypeId);
	}

	/**
	 * Given a FirebaseRouteType, find the router associated with it
	 */
	public getRouterWithFirebaseRouteType(firebaseRouteType: FirebaseRouteType) {
		return this.Routers.find(
			(router) => router.getFirebaseRouteType() === firebaseRouteType
		);
	}

	/**
	 * Given a record, look for properties that would allow for outside routes
	 * @param record `
	 * @param currentRouter
	 */
	public getRoutersWithProfileAccess(record: any, currentRouter?: RtUiRouter) {
		const externalRouters: RtUiRouter[] = [];

		if (this.Routers) {
			const applicationRouters = this.Routers;

			for (const router of applicationRouters) {
				//skip current router
				if (currentRouter && router.getName() === currentRouter.getName()) {
					continue;
				}

				if (router.recordHasAccessToProfile(record)) {
					externalRouters.push(router);
				}
			}
		}

		return externalRouters;
	}
}
