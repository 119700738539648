import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	CprRateSheetIndexRequest,
	CprRateSheetIndexResponse,
	CprRateSheetProfileRequest,
	CprRateSheetResultsProfileRequest,
	CprRateSheetResultsProfileResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchCprRateSheets = async (
	queryParams?: CprRateSheetIndexRequest
): Promise<FullResponse<CprRateSheetIndexResponse[]>> => {
	return handleGetRequest<CprRateSheetIndexResponse[], true>(
		Rt800ApiRoutes.CprRateSheets.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetCprRateSheets = (queryParams?: CprRateSheetIndexRequest) => {
	return useQuery<FullResponse<CprRateSheetIndexResponse[]>, Error>(
		['getCprRateSheets', queryParams],
		() => fetchCprRateSheets(queryParams)
	);
};

const fetchCprRateResultsSheets = async (
	urlParams: CprRateSheetProfileRequest,
	queryParams?: CprRateSheetResultsProfileRequest
): Promise<FullResponse<CprRateSheetResultsProfileResponse[]>> => {
	return handleGetRequest<CprRateSheetResultsProfileResponse[], true>(
		Rt800ApiRoutes.CprRateSheetResults.Profile,
		{
			includeFullResponse: true,
			urlParams,
			queryParams
		}
	);
};

export const useGetCprRateResultsSheets = (
	urlParams: CprRateSheetProfileRequest,
	queryParams?: CprRateSheetResultsProfileRequest
) => {
	return useQuery<FullResponse<CprRateSheetResultsProfileResponse[]>, Error>(
		['getCprRateSheets', urlParams, queryParams],
		() => fetchCprRateResultsSheets(urlParams, queryParams)
	);
};
