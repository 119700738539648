/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileTypeProfileResponse = {
    properties: {
        typeId: {
            type: 'number',
            isRequired: true,
        },
        description: {
            type: 'string',
            isRequired: true,
        },
        formatType: {
            type: 'string',
            isRequired: true,
        },
        header: {
            type: 'number',
            isRequired: true,
        },
        footer: {
            type: 'number',
            isRequired: true,
        },
        delimiter: {
            type: 'string',
            isRequired: true,
        },
        cdrFields: {
            type: 'Array',
            isRequired: true,
        },
    },
};