import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { ActivePortCountryIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IActivePortsCountryRouterTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const ActivePortsCountryContainerTabs: IActivePortsCountryRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class ActivePortsCountryRouterClass extends SipApplicationRouter<
	ActivePortCountryIndexResponse,
	ActivePortCountryIndexResponse,
	IActivePortsCountryRouterTabs
> {
	constructor() {
		super(
			'Active Ports Country',
			'ActivePortsCountry',
			'igCps',
			ActivePortsCountryContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.ActivePortsCountries);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const ActivePortsCountryRouter = new ActivePortsCountryRouterClass();
