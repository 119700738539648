/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionPeeringProfileResponse = {
    properties: {
        subscriptionPeeringId: {
            type: 'number',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        peeringTypeId: {
            type: 'number',
            isRequired: true,
        },
        peeredValue: {
            type: 'string',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        deletedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
    },
};