/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $Rt800ResourceIndexRequest = {
    properties: {
        resourceTypeId: {
            type: 'number',
        },
        isActive: {
            type: 'number',
        },
        isDefault: {
            type: 'number',
        },
        integrationId: {
            type: 'Integrations',
        },
        subscriptionId: {
            type: 'number',
            isNullable: true,
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};