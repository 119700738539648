import { VendorReconciliationIndexResponseData } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getReconciliationColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/reconciliation';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface IReconciliationSummaryGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<VendorReconciliationIndexResponseData> | null;
	isLoading?: boolean;
}

export const ReconciliationDataGrid = ({
	resource,
	isLoading
}: IReconciliationSummaryGridProps) => {
	const { data } = useQuery<
		VendorReconciliationIndexResponseData[] | undefined,
		Error
	>(['getReconciliation', resource], () => resource?.getAll());
	const columns = useMemo(() => getReconciliationColumns(), []);

	return (
		<DataGrid<VendorReconciliationIndexResponseData>
			data={data}
			loading={isLoading}
			pageName="rtVue_reconciliation"
			columns={columns}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
