/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrTemplateProfileResponse = {
    properties: {
        templateName: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        entityId: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        effectiveTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        isActive: {
            type: 'number',
        },
        lcrTaskId: {
            type: 'number',
        },
        interLataCarriers: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        intraLataCarriers: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
    },
};