import { FC, useEffect, useState } from 'react';
import {
	SearchField,
	CallDirectionPreset,
	DataFilter,
	DataFilterOperand,
	DataFilterOperator,
	DataSources
} from 'RtModels';
import { Button, Form } from 'react-bootstrap';
import { DataFilterOperandLabel } from 'RtUi/app/rtVue/common/lib/components/DataFilterOperandLabel';
import { cloneDeep, find } from 'lodash-es';
import clsx from 'clsx';
import { sentenceCase } from 'change-case';
import { GetRtVueOperatorColor } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueOperatorColors';

interface IVueStandardFiltersProps {
	filters: DataFilter[];
	searchFields: SearchField[];
	advancedSearchFields: SearchField[];
	onChange: (newFilters: DataFilter[]) => void;
	keysBlacklist?: string[];
	className?: string;
}

function getEnumKeyByEnumValue(myEnum: any, enumValue: any) {
	const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
	return keys.length > 0 ? keys[0] : null;
}

export const VueStandardFilters: FC<
	React.PropsWithChildren<IVueStandardFiltersProps>
> = ({
	filters,
	keysBlacklist,
	searchFields,
	advancedSearchFields,
	onChange,
	className
}) => {
	const [currentFilters, setCurrentFilters] = useState<DataFilter[]>([]);
	useEffect(() => {
		setCurrentFilters(filters);
	}, [filters]);
	/**
	 * Given a key in a DataFilter, return it's label from this.props.searchFields
	 * @param key
	 */
	const getKeyLabel = (
		{ advanced, key }: DataFilter,
		operator: DataFilterOperator
	) => {
		if (advanced) {
			let advancedFilter = find(advancedSearchFields, { key, operator });

			if (!advancedFilter) {
				advancedFilter = find(advancedSearchFields, { key });
			}

			if (
				advancedFilter &&
				advancedFilter.dataSources.includes(DataSources.Boolean)
			) {
				return advancedFilter.label;
			}

			return `${advancedFilter!.label} ${sentenceCase(
				getEnumKeyByEnumValue(DataFilterOperator, operator) ?? ''
			)} `;
		}

		const searchField = find(searchFields, { key });

		if (!searchField) {
			return sentenceCase(key);
		}

		const directionsToLabel = [
			CallDirectionPreset.Egress,
			CallDirectionPreset.Ingress,
			CallDirectionPreset.Vendor,
			CallDirectionPreset.Customer
		];

		if (directionsToLabel.includes(searchField.direction)) {
			return `${
				!searchField.label.startsWith(searchField.direction)
					? searchField.direction
					: ''
			} ${searchField.label}`;
		}

		return searchField.label;
	};

	const removeValue = (filter: DataFilter, operand: DataFilterOperand) => {
		const filterIndex = currentFilters.indexOf(filter);
		const operandIndex = filter.operands.indexOf(operand);

		if (operandIndex < 0 || filterIndex < 0) {
			return;
		}

		const newFilters = cloneDeep(currentFilters);

		//Remove value from newFilters
		newFilters[filterIndex].operands.splice(operandIndex, 1);
		onChange(newFilters);
	};

	let renderedFilters = currentFilters;
	if (Array.isArray(keysBlacklist)) {
		renderedFilters = renderedFilters.filter(
			(filter) => !keysBlacklist.includes(filter.key)
		);
	}

	if (renderedFilters.length <= 0) {
		return null;
	}

	const isBooleanFilter = (filter: DataFilter) => {
		return filter.operands.some(
			(operand) => operand.dataSource === DataSources.Boolean
		);
	};

	return (
		<section className={clsx('d-flex', className)}>
			{renderedFilters.map((filter) => {
				if (isBooleanFilter(filter)) {
					return (
						<Form.Group key={filter.key} className="me-3 mb-3">
							{filter.operands.map((operand, index) => (
								<Button
									key={operand.value + index}
									variant="white-alt"
									style={{
										backgroundColor: GetRtVueOperatorColor(filter.operator)
									}}
									className="ms-2"
									onClick={() => removeValue(filter, operand)}
								>
									{getKeyLabel(filter, filter.operator)}
									<span>
										&nbsp;&nbsp;
										<i className="fas fa-fw fa-times small" />
									</span>
								</Button>
							))}
						</Form.Group>
					);
				}

				return (
					<Form.Group
						key={`${filter.key}_${filter.operator}`}
						className="me-3 mb-3"
					>
						<Form.Label className="label-90">
							{getKeyLabel(filter, filter.operator)}
							{filter.operator === DataFilterOperator.NotIn ? ' Not ' : ''}:
						</Form.Label>
						{filter.operands.map((operand, index) => (
							<Button
								key={operand.value + index}
								variant="white-alt"
								style={{
									backgroundColor: GetRtVueOperatorColor(filter.operator)
								}}
								className="ms-2"
								onClick={() => removeValue(filter, operand)}
							>
								<DataFilterOperandLabel operand={operand} />
								<span>
									&nbsp;&nbsp;
									<i className="fas fa-fw fa-times small" />
								</span>
							</Button>
						))}
					</Form.Group>
				);
			})}
		</section>
	);
};
