/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationBulkUpdateItem = {
    properties: {
        carrierBanNumber: {
            type: 'string',
            isRequired: true,
        },
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        carrierInvoiceConnects: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        carrierInvoiceDuration: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        carrierInvoiceCharge: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        customerExpectedConnects: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        customerExpectedDuration: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        customerExpectedCharge: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
    },
};