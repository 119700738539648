import {
	GuardianAlertHistoryIndexRequest,
	GuardianAlertHistoryIndexResponse
} from 'RtModels';
import { AlertHistoryRouter } from 'RtUi/app/rtGuardian/AlertHistory/AlertHistory.router';
import { AlertNotesAside } from 'RtUi/app/rtGuardian/AlertHistory/lib/components/AlertNotesAside';
import { useGetGuardianAlertHistories } from 'RtUi/app/rtGuardian/AlertHistory/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import {
	alertHistoryMenuLinks,
	getAlertHistoryColumns
} from 'RtUi/components/data/DataGrid/configurations/rtGuardian/alertHistory';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IAlertHistoryGridProps {
	pageName: string;
	resourceParams?: GuardianAlertHistoryIndexRequest;
}

export const AlertHistoryGrid = ({
	pageName,
	resourceParams
}: IAlertHistoryGridProps): JSX.Element => {
	const { data, isLoading } = useGetGuardianAlertHistories(resourceParams);
	const [selectedRecord, setSelectedRecord] =
		useState<GuardianAlertHistoryIndexResponse>();
	const navigate = useNavigate();

	const onClickNote = (record: GuardianAlertHistoryIndexResponse) => {
		setSelectedRecord(record);
	};

	const columns = useMemo(() => getAlertHistoryColumns(onClickNote), []);

	return (
		<>
			{selectedRecord && (
				<AlertNotesAside
					key={selectedRecord.guardianAlertHistoryId}
					isOpen={Boolean(selectedRecord)}
					selectedRecord={selectedRecord}
					onCancel={() => setSelectedRecord(undefined)}
				/>
			)}
			<DataGrid<GuardianAlertHistoryIndexResponse>
				data={data?.data}
				columns={columns}
				router={AlertHistoryRouter}
				pageName={pageName}
				loading={isLoading}
				menuLinks={(row) => alertHistoryMenuLinks(row, navigate)}
				totalRows={data?.totalCount}
			/>
		</>
	);
};
