/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MessageEventTypeIndexResponse = {
    properties: {
        messageEventTypeId: {
            type: 'number',
            isRequired: true,
        },
        productId: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        isPin: {
            type: 'number',
            isRequired: true,
        },
        isInstant: {
            type: 'number',
            isRequired: true,
        },
        isDaily: {
            type: 'number',
            isRequired: true,
        },
        isWeekly: {
            type: 'number',
            isRequired: true,
        },
        isMonthly: {
            type: 'number',
            isRequired: true,
        },
    },
};