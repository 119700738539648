import { ServerGroupIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';

export const getServerGroupColumns = (): Array<
	DataGridColumn<ServerGroupIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'serverGroupId'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary',
		size: 200
	})
];
