import { cloneDeep, groupBy, intersectionBy, isEqual, map } from 'lodash-es';
import { ChangeEvent, Component } from 'react';
import {
	ProductId,
	UserIndexResponse,
	UserPermissionProfileResponse,
	UserPermissionResponse,
	UserPermissionUpdateRequest,
	RespOrgIndexResponse,
	EntityIndexResponse
} from 'RtModels';
import { ProductResource } from 'RtUi/app/Administration/Users/lib/resources/ProductResource';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { UserHttp } from 'RtUi/app/user/lib/Http/UserHttp';
import { SubmitFormControl } from 'RtUi/components/form/SubmitFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { Col, Row, Alert, Card, Accordion, Form } from 'react-bootstrap';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';

type TGroupedPermissions = {
	productName: string;
	productId: ProductId | null;
	items: UserPermissionResponse[];
};

interface IPermissionEditFormProps {
	editMode: UserIndexResponse;
	currentUserId: number;
}

interface IPermissionEditFormState {
	error?: any;
	groups: TGroupedPermissions[];
	selectedUserPermissions: Set<number>;
	initialSelectedUserPermissions: Set<number>;
	isDirty: boolean;
	isSavingUserPermissions: boolean;
	isLoading: boolean;
	isCustomerPortalSelected: boolean;
	selectedUserPermissionResponse?: UserPermissionProfileResponse;
	collapsedProducts: Set<ProductId | null>;
	//attributes form
	rt800SomosEntities?: EntityIndexResponse[];
	rt800SomosRespOrgs?: RespOrgIndexResponse[];
	rt800SomosDefaultRespOrg?: RespOrgIndexResponse;
	rt800SomosEntitiesDefaultValue?: string[];
	rt800SomosRespOrgsDefaultValue?: string[];
}

export class PermissionEditForm extends Component<
	IPermissionEditFormProps,
	IPermissionEditFormState
> {
	public state: IPermissionEditFormState = {
		selectedUserPermissions: new Set<number>(),
		initialSelectedUserPermissions: new Set<number>(),
		isSavingUserPermissions: false,
		isDirty: false,
		groups: [],
		collapsedProducts: new Set<ProductId | null>(),
		isCustomerPortalSelected: false,
		isLoading: true
	};

	public userHttp = new UserHttp();
	public productResource = new ProductResource();

	private initialUserPermissionProfile!: UserPermissionProfileResponse;

	public async componentDidMount() {
		this.setState({ isLoading: true });

		await this.init(this.props.editMode);

		this.setState({ isLoading: false });
	}

	public togglePermissionGroup(
		permissionGroup: TGroupedPermissions,
		value: boolean
	) {
		const selectedUserPermissions = new Set(this.state.selectedUserPermissions);

		for (const item of permissionGroup.items) {
			if (!value) {
				selectedUserPermissions.delete(item.permissionId);
			} else {
				selectedUserPermissions.add(item.permissionId);
			}
		}

		this.setState({ selectedUserPermissions });
	}

	public renderPermissionCheckbox(item: UserPermissionResponse) {
		const { isCustomerPortalSelected } = this.state;
		let shouldMute = false;

		if (isCustomerPortalSelected) {
			shouldMute = !Boolean(item.isCustomerPortal);
		}

		return (
			<Form.Group className="mb-3" key={`permission_${item.permissionId}`}>
				<Form.Check
					className={shouldMute ? 'text-muted' : ''}
					type="checkbox"
					disabled={shouldMute}
					id={`${item.permissionId}`}
					name={`${item.permissionId}`}
					value={item.permissionId}
					checked={this.state.selectedUserPermissions.has(item.permissionId)}
					onChange={(evt: ChangeEvent<HTMLInputElement>) =>
						this.handlePermissionCheckboxChange(evt, item)
					}
					label={item.label}
				/>
			</Form.Group>
		);
	}

	public renderAttributes(productId: ProductId | null) {
		const { selectedUserPermissionResponse } = this.state;

		if (!selectedUserPermissionResponse) {
			return;
		}

		if (productId === ProductId.RT_800) {
			return (
				<section>
					<EntitySelect<true>
						multi
						label="Entities"
						onChange={(rt800SomosEntities) =>
							this.setState({
								rt800SomosEntities: rt800SomosEntities,
								rt800SomosEntitiesDefaultValue: undefined,
								isDirty: true
							})
						}
						value={this.state.rt800SomosEntities}
						initialOptionId={this.state.rt800SomosEntitiesDefaultValue}
					/>
					<Row>
						<Col xl={6}>
							<RespOrgSelect<true>
								multi
								label="RespOrgs"
								value={this.state.rt800SomosRespOrgs}
								initialOptionId={this.state.rt800SomosRespOrgsDefaultValue}
								onChange={(rt800SomosRespOrgs) =>
									this.setState({
										rt800SomosRespOrgs,
										rt800SomosRespOrgsDefaultValue: undefined,
										isDirty: true
									})
								}
							/>
						</Col>
						<Col xl={6}>
							<RespOrgSelect
								label="Default RespOrg"
								required //required by https://trello.com/c/w6y3UkIQ/591-user-management-issues
								value={this.state.rt800SomosDefaultRespOrg}
								initialOptionId={
									selectedUserPermissionResponse.defaultRespOrgId
								}
								onChange={(rt800SomosDefaultRespOrg) =>
									this.setState({
										rt800SomosDefaultRespOrg,
										isDirty: true
									})
								}
							/>
						</Col>
					</Row>
				</section>
			);
		}

		return null;
	}

	/**
	 * Cancel current state and revert to initial state
	 */
	private onCancel = async () => {
		const selectedUserPermissionResponse = cloneDeep(
			this.initialUserPermissionProfile
		);

		this.setState({
			selectedUserPermissions: this.state.initialSelectedUserPermissions,
			selectedUserPermissionResponse,
			rt800SomosDefaultRespOrg: undefined,
			rt800SomosEntities: undefined,
			rt800SomosRespOrgs: undefined,
			rt800SomosEntitiesDefaultValue: selectedUserPermissionResponse.entityIds,
			rt800SomosRespOrgsDefaultValue: selectedUserPermissionResponse.respOrgIds,
			isDirty: false
		});
	};

	/**
	 * Toggle which available product are collapsed
	 * @param productId
	 */
	private toggleCollapsedProduct(productId: ProductId | null) {
		const { collapsedProducts } = this.state;

		if (collapsedProducts.has(productId)) {
			collapsedProducts.delete(productId);
		} else {
			collapsedProducts.add(productId);
		}

		this.setState({ collapsedProducts });
	}

	/**
	 * Initialize permissions and groups
	 * @param selectedUser
	 */
	private async init(selectedUser: UserIndexResponse) {
		try {
			const [
				products,
				assignablePermissionResponse,
				selectedUserPermissionResponse
			] = await Promise.all([
				this.productResource.getAll(),
				this.userHttp.getUserAssignablePermissions(this.props.currentUserId),
				this.userHttp.getUserPermissions(selectedUser.userId)
			]);

			this.initialUserPermissionProfile = cloneDeep(
				selectedUserPermissionResponse
			);

			const selectedUserPermissions = intersectionBy(
				assignablePermissionResponse,
				selectedUserPermissionResponse.permissions,
				'permissionId'
			);

			const userPermissions = new Set(
				selectedUserPermissions.map((perm) => perm.permissionId)
			);
			const userPermissionsGroupedByProduct = groupBy(
				assignablePermissionResponse,
				'productId'
			);
			const groups: TGroupedPermissions[] = map(
				userPermissionsGroupedByProduct,
				(value, key) => {
					const productId: ProductId | null =
						key === null ? null : (Number(key) as ProductId);

					return {
						productId,
						productName: this.productResource.getProductName(
							products,
							productId
						),
						items: value
					};
				}
			);

			const isCustomerPortalSelected =
				selectedUserPermissionResponse.permissions.some(
					(permission) => permission.isCustomerPortal === 1
				);

			this.setState({
				groups,
				isCustomerPortalSelected,
				selectedUserPermissionResponse,
				selectedUserPermissions: userPermissions,
				initialSelectedUserPermissions: userPermissions,
				rt800SomosEntitiesDefaultValue:
					selectedUserPermissionResponse.entityIds,
				rt800SomosRespOrgsDefaultValue:
					selectedUserPermissionResponse.respOrgIds,
				isDirty: false
			});
		} catch (err) {
			this.setState({ error: err });
		}
	}

	/**
	 * Save permissions
	 * @param e
	 */
	private onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const { selectedUserPermissions, selectedUserPermissionResponse } =
			this.state;

		if (
			!selectedUserPermissions ||
			!this.props.editMode ||
			!selectedUserPermissionResponse
		) {
			return;
		}

		try {
			this.setState({ isSavingUserPermissions: true, error: undefined });

			const {
				rt800SomosDefaultRespOrg,
				rt800SomosRespOrgs,
				rt800SomosEntities
			} = this.state;
			let entityIds: string[] = [];
			let respOrgIds: string[] = [];
			let defaultRespOrgId: string | undefined;

			if (rt800SomosRespOrgs) {
				respOrgIds = rt800SomosRespOrgs.map((respOrg) => respOrg.respOrgId);
			}

			if (rt800SomosEntities) {
				entityIds = rt800SomosEntities.map((entity) => entity.entityId);
			}

			if (rt800SomosDefaultRespOrg) {
				defaultRespOrgId = rt800SomosDefaultRespOrg.respOrgId;
			}

			const updateReq: UserPermissionUpdateRequest = {
				permissionIds: Array.from(selectedUserPermissions),
				portalCarrierIds: selectedUserPermissionResponse.portalCarrierIds ?? [],
				entityIds,
				respOrgIds,
				defaultRespOrgId
			};

			const newSelectedUserPermissionResponse =
				await this.userHttp.updateUserPermissions(
					this.props.editMode.userId,
					updateReq
				);

			this.initialUserPermissionProfile = cloneDeep(
				newSelectedUserPermissionResponse
			);

			this.setState({
				initialSelectedUserPermissions: this.state.selectedUserPermissions,
				selectedUserPermissionResponse: newSelectedUserPermissionResponse,
				isSavingUserPermissions: false,
				isDirty: false
			});
		} catch (err) {
			this.setState({ isSavingUserPermissions: false, error: err });
		}
	};

	/**
	 * Toggle a user's permission
	 */
	private handlePermissionCheckboxChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		item: UserPermissionResponse
	) => {
		const isChecked = event.target.checked;
		const selectedUserPermissions = new Set(this.state.selectedUserPermissions);

		if (!isChecked) {
			selectedUserPermissions.delete(item.permissionId);
		} else {
			selectedUserPermissions.add(item.permissionId);
		}

		const { groups, initialSelectedUserPermissions } = this.state;
		const isDirty = !isEqual(
			selectedUserPermissions,
			initialSelectedUserPermissions
		);
		let isCustomerPortalSelected = false;

		/**
		 * Determine if customer portal permission is selected
		 */
		for (const group of groups) {
			for (const item of group.items) {
				const isSelected = selectedUserPermissions.has(item.permissionId);

				if (isSelected && item.isCustomerPortal === 1) {
					isCustomerPortalSelected = true;
					break;
				}
			}

			if (isCustomerPortalSelected) {
				break;
			}
		}

		if (isCustomerPortalSelected) {
			/**
			 * Remove non-customer portal permissions
			 */
			for (const group of groups) {
				for (const item of group.items) {
					const isSelected = selectedUserPermissions.has(item.permissionId);

					if (isSelected && item.isCustomerPortal !== 1) {
						selectedUserPermissions.delete(item.permissionId);
					}
				}
			}
		}

		this.setState({
			selectedUserPermissions,
			isDirty,
			isCustomerPortalSelected
		});
	};

	private isGroupSelected(permissionGroup: TGroupedPermissions) {
		return permissionGroup.items.every((i) =>
			this.state.selectedUserPermissions.has(i.permissionId)
		);
	}

	public render() {
		const { editMode } = this.props;
		const { isLoading, error } = this.state;

		if (isLoading) {
			return <Loading />;
		}

		if (!error && this.state.groups.length === 0) {
			return <Alert variant="info">No permissions granted.</Alert>;
		}

		const enabledGroups = this.state.groups.filter((group) => {
			const enabledItems = group.items.filter((item) =>
				this.state.selectedUserPermissions.has(item.permissionId)
			);

			return enabledItems.length > 0;
		});

		return (
			<Row>
				<Col xl={4} lg={6}>
					<Card>
						<Card.Header className="collapsible-card-header input-group-height d-flex align-items-center">
							Available Permissions
						</Card.Header>
						<Card.Body>
							{this.state.groups.map((permissionGroup, index) => {
								const { collapsedProducts } = this.state;
								const isCollapsed = collapsedProducts.has(
									permissionGroup.productId
								);

								return (
									<Accordion
										key={permissionGroup.productName}
										defaultActiveKey={
											!isCollapsed
												? String(permissionGroup.productId!)
												: undefined
										}
										className="mb-3"
									>
										<Accordion.Item
											eventKey={String(permissionGroup.productId!)}
										>
											<Accordion.Button
												as="h6"
												className="d-flex justify-content-start align-items-center"
												onClick={() =>
													this.toggleCollapsedProduct(permissionGroup.productId)
												}
											>
												<u className="me-2">
													<b>{permissionGroup.productName}</b>
												</u>
												<CheckboxFormControl
													className="d-flex align-items-center gap-2 m-0"
													value={this.isGroupSelected(permissionGroup)}
													label="Select all"
													onClick={(event) => event.stopPropagation()}
													onChange={(value) =>
														this.togglePermissionGroup(permissionGroup, value)
													}
												/>
											</Accordion.Button>
											<Accordion.Collapse
												eventKey={String(permissionGroup.productId!)}
											>
												<Accordion.Body>
													{permissionGroup.items
														.filter(
															(item) =>
																!this.state.selectedUserPermissions.has(
																	item.permissionId
																)
														)
														.map((item) => this.renderPermissionCheckbox(item))}
												</Accordion.Body>
											</Accordion.Collapse>
										</Accordion.Item>
										{index !== this.state.groups.length - 1 && <hr />}
									</Accordion>
								);
							})}
						</Card.Body>
					</Card>
				</Col>
				<Col xl={6} lg={6}>
					<Form
						onSubmit={(e) => this.onSubmit(e)}
						style={{ position: 'sticky', top: 0 }}
					>
						<Card>
							<Card.Header className="d-flex collapsible-card-header input-group-height mb-3">
								<h6 className="mb-0 align-self-center">
									<b>
										{editMode.firstName} {editMode.lastName}
										&apos;s Current Permissions
									</b>
								</h6>
							</Card.Header>
							{this.state.isCustomerPortalSelected && (
								<Card.Body className="py-0">
									<Alert
										variant="info"
										className="d-flex justify-content-start align-items-start"
									>
										<i className="fas fa-fw fa-exclamation-circle me-2" />
										Customer Portal permission(s) have been selected. The
										user&apos;s permissions are now limited.
									</Alert>
								</Card.Body>
							)}
							{enabledGroups.map((permissionGroup, index) => (
								<Card.Body key={permissionGroup.productName} className="py-0">
									<header className="d-flex align-items-baseline mb-2">
										<h6 className="me-2">
											<b>{permissionGroup.productName}</b>
										</h6>
										<CheckboxFormControl
											value={true}
											label="Remove all"
											onChange={(value) =>
												this.togglePermissionGroup(permissionGroup, value)
											}
										/>
									</header>
									<Form.Group className="mb-3">
										{permissionGroup.items
											.filter((item) =>
												this.state.selectedUserPermissions.has(
													item.permissionId
												)
											)
											.map((item) => this.renderPermissionCheckbox(item))}
									</Form.Group>
									{this.renderAttributes(permissionGroup.productId)}
									{index !== enabledGroups.length - 1 && <hr />}
								</Card.Body>
							))}
							<Card.Body>
								{this.state.groups.length > 0 && (
									<SubmitFormControl
										displayMode={false}
										cancelButtonText="Undo All Changes"
										submitButtonText="Save"
										isCanceledDisabled={!this.state.isDirty}
										isSubmitting={this.state.isSavingUserPermissions}
										error={this.state.error}
										onCancel={this.onCancel}
									/>
								)}
							</Card.Body>
						</Card>
					</Form>
				</Col>
			</Row>
		);
	}
}
