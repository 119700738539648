/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileLogIndexRequest = {
    properties: {
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        fileStreamIds: {
            type: 'Array',
        },
        createdTsStart: {
            type: 'Date',
            format: 'date-time',
        },
        createdTsEnd: {
            type: 'Date',
            format: 'date-time',
        },
        searchFileName: {
            type: 'string',
        },
    },
};