import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { ActivePortsDetailsRouter } from 'RtUi/app/rtSip/ActivePortsDetails/ActivePortsDetails.router';
import { ActivePortsDetailDataGrid } from 'RtUi/app/rtSip/ActivePortsDetails/lib/grid/ActivePortsDetailsDataGrid';

@ActivePortsDetailsRouter.getNetworkReportsIndexRtUiController()
export class ActivePortsDetailsIndexContainer extends ApplicationContainer<
	{},
	{}
> {
	public state = {};

	public render() {
		return (
			<StandardLayout router={ActivePortsDetailsRouter}>
				<ActivePortsDetailDataGrid />
			</StandardLayout>
		);
	}
}
