/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ServerCreateRequest = {
    properties: {
        hostName: {
            type: 'string',
            isRequired: true,
        },
        publicIpAddress: {
            type: 'string',
            isRequired: true,
        },
        privateIpAddress: {
            type: 'string',
            isRequired: true,
        },
        networkId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isOnline: {
            type: 'number',
            isRequired: true,
        },
        allowConfigRefresh: {
            type: 'number',
            isRequired: true,
        },
        cdrWorkerId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
    },
};