import { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
	ResourceConfigurationIndexResponse,
	ScenarioActionResourceTagging
} from 'RtModels';
import { ResourceConfigurationSelect } from 'RtUi/app/rtCommon/ResourceConfigurations/lib/controls/ResourceConfigurationSelect';
import { IScenarioActionFormProps } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionForm';
import { SelectValue } from 'RtUi/components/form/CreatableMultiSelect/CreatableMultiSelect';

export const ScenarioResourceTaggingForm = ({
	action,
	displayMode,
	onChange
}: IScenarioActionFormProps<ScenarioActionResourceTagging>) => {
	const [resourceConfiguration, setResourceConfiguration] =
		useState<ResourceConfigurationIndexResponse>();

	const [attemptTags, setAttemptTags] = useState<MultiValue<SelectValue>>(
		action.attemptTags?.map((t) => ({
			label: t,
			value: t
		})) ?? []
	);
	const [successTags, setSuccessTags] = useState<MultiValue<SelectValue>>(
		action.successTags?.map((t) => ({
			label: t,
			value: t
		})) ?? []
	);
	const [errorTags, setErrorTags] = useState<MultiValue<SelectValue>>(
		action.errorTags?.map((t) => ({
			label: t,
			value: t
		})) ?? []
	);

	const onChangeInternal = <K extends keyof ScenarioActionResourceTagging>(
		key: K,
		val: ScenarioActionResourceTagging[K]
	) => {
		action[key] = val;
		onChange(action);
	};

	return (
		<>
			<Row>
				<Col>
					<ResourceConfigurationSelect
						value={resourceConfiguration}
						onChange={(resourceConfiguration) => {
							setResourceConfiguration(resourceConfiguration);
							onChangeInternal(
								'resourceConfigurationId',
								resourceConfiguration.resourceConfigurationId
							);
						}}
						displayMode={displayMode}
						initialOptionId={String(action.resourceConfigurationId)}
					/>
				</Col>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Attempt Tags</Form.Label>
						<CreatableSelect
							value={attemptTags}
							isMulti
							placeholder=""
							isDisabled={displayMode}
							onChange={(tags) => {
								setAttemptTags(tags);
								onChangeInternal(
									'attemptTags',
									tags.map((t) => t.value)
								);
							}}
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Success Tags</Form.Label>
						<CreatableSelect
							value={successTags}
							isMulti
							placeholder=""
							isDisabled={displayMode}
							onChange={(tags) => {
								setSuccessTags(tags);
								onChangeInternal(
									'successTags',
									tags.map((t) => t.value)
								);
							}}
						/>
					</Form.Group>
				</Col>

				<Col>
					<Form.Group className="mb-3">
						<Form.Label>Error Tags</Form.Label>
						<CreatableSelect
							isMulti
							placeholder=""
							isDisabled={displayMode}
							value={errorTags}
							onChange={(tags) => {
								setErrorTags(tags);
								onChangeInternal(
									'errorTags',
									tags.map((t) => t.value)
								);
							}}
						/>
					</Form.Group>
				</Col>
			</Row>
		</>
	);
};
