import { FileStreamRouter } from 'RtUi/app/rtVue/FileStreams/FileStream.router';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { FileStreamProfileResponse } from 'RtModels';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { FileStreamResource } from 'RtUi/app/rtVue/FileStreams/lib/resource/FileStreamResource';
import { Loading } from 'RtUi/components/ui/Loading';
import { FileStreamEditor } from 'RtUi/app/rtVue/FileStreams/lib/forms/FileStreamEditor';
import { FileLogGrid } from 'RtUi/app/rtVue/FileLog/lib/grids/FileLogGrid';
import moment from 'moment';

interface IFileStreamContainerState {
	activeTab: string;
	isLoading: boolean;
	isEdit: boolean;
	editStep: number;
	fileStream?: FileStreamProfileResponse;
}

@FileStreamRouter.getProfileRtUiController()
export class FileStreamProfileContainer extends ProfileApplicationContainer<
	{},
	IFileStreamContainerState
> {
	public Tabs = FileStreamRouter.getProfileTabs();

	public state: IFileStreamContainerState = {
		activeTab: this.Tabs.Profile.header,
		isLoading: true,
		isEdit: false,
		editStep: 1
	};

	public async componentDidMount() {
		const fileStreamResource = new FileStreamResource();
		const fileStreamId = Number(this.getIdParam());

		this.setState({ isLoading: true });
		const fileStream = await fileStreamResource.get(fileStreamId);
		if (!fileStream) {
			throw new Error('File Stream not found');
		}

		this.setState({ fileStream, isLoading: false });
	}

	public render() {
		if (this.state.isLoading) {
			return <Loading />;
		}

		if (this.state.isEdit) {
			return (
				<TabbedLayout<FileStreamProfileResponse>
					profile={this.state.fileStream}
					activeTab="Edit"
					onTabChange={(activeTab) => this.setState({ activeTab })}
					router={FileStreamRouter}
				>
					<TabbedLayoutTab header={'Edit'}>
						<FileStreamEditor
							currentStep={this.state.editStep}
							displayMode={!this.state.isEdit}
							profile={this.state.fileStream}
							onUpdate={(fileStream) =>
								this.setState({ fileStream, isEdit: false })
							}
							onEdit={() => this.setState({ isEdit: true })}
							onCancel={() => this.setState({ isEdit: false })}
						/>
					</TabbedLayoutTab>
				</TabbedLayout>
			);
		}

		return (
			<TabbedLayout<FileStreamProfileResponse>
				profile={this.state.fileStream}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={FileStreamRouter}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<FileStreamEditor
						currentStep={1}
						displayMode={!this.state.isEdit}
						profile={this.state.fileStream}
						onUpdate={(fileStream) =>
							this.setState({ fileStream, isEdit: false })
						}
						onEdit={() => this.setState({ isEdit: true, editStep: 1 })}
						onCancel={() => this.setState({ isEdit: false })}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Download}>
					<FileStreamEditor
						currentStep={2}
						displayMode={!this.state.isEdit}
						profile={this.state.fileStream}
						onUpdate={(fileStream) =>
							this.setState({ fileStream, isEdit: false })
						}
						onEdit={() => this.setState({ isEdit: true, editStep: 2 })}
						onCancel={() => this.setState({ isEdit: false })}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Reports}>
					<FileStreamEditor
						currentStep={3}
						displayMode={!this.state.isEdit}
						profile={this.state.fileStream}
						onUpdate={(fileStream) =>
							this.setState({ fileStream, isEdit: false })
						}
						onEdit={() => this.setState({ isEdit: true, editStep: 3 })}
						onCancel={() => this.setState({ isEdit: false })}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Admin}>
					<FileStreamEditor
						currentStep={4}
						displayMode={!this.state.isEdit}
						profile={this.state.fileStream}
						onUpdate={(fileStream) =>
							this.setState({ fileStream, isEdit: false })
						}
						onEdit={() => this.setState({ isEdit: true, editStep: 4 })}
						onCancel={() => this.setState({ isEdit: false })}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.CdrFiles}>
					{this.state.fileStream && (
						<FileLogGrid
							resourceParams={{
								fileStreamIds: [this.state.fileStream.fileStreamId],
								createdTsStart: moment()
									.startOf('day')
									.subtract(60, 'days')
									.toDate(),
								createdTsEnd: moment().endOf('day').toDate()
							}}
						/>
					)}
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
