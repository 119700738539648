/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NanpSearchRequest = {
    properties: {
        npa: {
            type: 'string',
        },
        nxx: {
            type: 'string',
        },
        line: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        rateCenter: {
            type: 'string',
        },
        timeoutMs: {
            type: 'string',
        },
        onlyResourceIds: {
            type: 'string',
        },
        omitResourceIds: {
            type: 'string',
        },
        sortMethod: {
            type: 'number',
        },
        searchLimit: {
            type: 'number',
        },
    },
};