import { AosNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { AosLbl } from 'Somos/lib/SomosCpr/RtCprV2/AosLbl/AosLbl';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class AosLblAreaCode extends AosLbl {
	public readonly cprNodeTypeId = undefined;
	public readonly aosNodeTypeId = AosNodeType.AreaCode;

	public readonly valueRegExp = /^\d{3}$/;

	protected readonly possibles = CprLergCache.Npas;
	protected readonly possiblesByKey = CprLergCache.CprLergByNpa;

	protected readonly canadaPossibles = CprLergCache.CanadaNpas;
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaNpasByKey;

}
