import { Plugin } from 'flatpickr/dist/types/options';

function clearPlugin(): Plugin {
	let $clearContainer: HTMLDivElement;
	let $clearButton: HTMLButtonElement;

	return function (fp) {
		return {
			onReady() {
				$clearContainer = document.createElement('div');
				$clearContainer.classList.add('input-group-append');

				$clearButton = document.createElement('button');
				$clearButton.classList.add('btn');
				$clearButton.classList.add('btn-white-alt');
				$clearButton.classList.add('btn-sm');
				$clearButton.innerHTML = '<i class="fas fa-fw fa-times" />';
				$clearButton.tabIndex = -1;

				$clearButton.addEventListener('click', () => {
					fp.clear();
				});
				$clearContainer.appendChild($clearButton);
				fp.element.parentElement!.appendChild($clearContainer);

				// this is needed just for prettier display
				fp.loadedPlugins.push('clearPlugin');
			}
		};
	};
}

export default clearPlugin;
