/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LataIndexResponse = {
    properties: {
        lata: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        country: {
            type: 'string',
            isRequired: true,
        },
        location: {
            type: 'string',
            isRequired: true,
        },
    },
};