/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ActivePortAllIndexResponse = {
    properties: {
        igCps: {
            type: 'number',
            isRequired: true,
        },
        igXps: {
            type: 'number',
            isRequired: true,
        },
        igCur: {
            type: 'number',
            isRequired: true,
        },
        igMin: {
            type: 'number',
            isRequired: true,
        },
        igMax: {
            type: 'number',
            isRequired: true,
        },
        egCps: {
            type: 'number',
            isRequired: true,
        },
        egXps: {
            type: 'number',
            isRequired: true,
        },
        egCur: {
            type: 'number',
            isRequired: true,
        },
        egMin: {
            type: 'number',
            isRequired: true,
        },
        egMax: {
            type: 'number',
            isRequired: true,
        },
        totCps: {
            type: 'number',
            isRequired: true,
        },
        totXps: {
            type: 'number',
            isRequired: true,
        },
        totCur: {
            type: 'number',
            isRequired: true,
        },
        totMin: {
            type: 'number',
            isRequired: true,
        },
        totMax: {
            type: 'number',
            isRequired: true,
        },
        partitionId: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
    },
};