/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $InvoiceProfileResponse = {
    properties: {
        invoiceId: {
            type: 'number',
            isRequired: true,
        },
        accountId: {
            type: 'number',
            isRequired: true,
        },
        invoiceTypeId: {
            type: 'number',
            isRequired: true,
        },
        accountName: {
            type: 'string',
            isRequired: true,
        },
        firstName: {
            type: 'string',
            isRequired: true,
        },
        lastName: {
            type: 'string',
            isRequired: true,
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        invoiceCycleGroupId: {
            type: 'number',
            isRequired: true,
        },
        invoiceDate: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        previousBalance: {
            type: 'number',
            isRequired: true,
        },
        payments: {
            type: 'number',
            isRequired: true,
        },
        pastDue: {
            type: 'number',
            isRequired: true,
        },
        credits: {
            type: 'number',
            isRequired: true,
        },
        debits: {
            type: 'number',
            isRequired: true,
        },
        charges: {
            type: 'number',
            isRequired: true,
        },
        balance: {
            type: 'number',
            isRequired: true,
        },
        totalDue: {
            type: 'number',
            isRequired: true,
        },
        address: {
            type: 'InvoiceAddressResponse',
            isRequired: true,
        },
    },
};