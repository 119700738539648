import { MetricSummary } from 'RtUi/app/rtDid/Dashboard/lib/components/MetricSummary';
import { DidDrilldownRouter } from 'RtUi/app/rtDid/Drilldown/DidDrilldown.router';
import { DidNumbersGrid } from 'RtUi/app/rtDid/Numbers/lib/grids/DidNumbersGrid';
import { IDidNumberIndexRequest } from 'RtUi/app/rtDid/Numbers/lib/resources/DidNumbersResource';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { DashboardTabs } from 'RtUi/components/ui/DashboardTabs';
import { DashboardTab } from 'RtUi/components/ui/DashboardTabs/tab';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import {
	IResourceTypeOption,
	ResourceTypeSelect
} from 'RtUi/app/rtDid/Dashboard/lib/controls/ResourceTypeSelect';
import { ResourceType, ResourceConfigurationIndexResponse } from 'RtModels';
import { GetResourceTypesNotIn } from 'RtUi/app/rtDid/Dashboard/lib/components/types';
import { ResourceConfigurationSelect } from 'RtUi/app/rtCommon/ResourceConfigurations/lib/controls/ResourceConfigurationSelect';

interface IDidDrilldownIndexContainerProps {}

interface IDidDrilldownIndexContainerState {
	resourceParams: IDidNumberIndexRequest;
	activeDashboardTab: string;
	// form
	resource?: ResourceConfigurationIndexResponse;
	inTypeOptions: IResourceTypeOption[];
	notInTypeOptions: IResourceTypeOption[];
}

@DidDrilldownRouter.getIndexRtUiController({ isHidden: true })
export class DidDrilldownIndexContainer extends ApplicationContainer<
	IDidDrilldownIndexContainerProps,
	IDidDrilldownIndexContainerState
> {
	public state: IDidDrilldownIndexContainerState = {
		activeDashboardTab: 'grid',
		resourceParams: {},
		inTypeOptions: [],
		notInTypeOptions: []
	};

	public constructor(props: IDidDrilldownIndexContainerProps) {
		super(props);

		const typesCommaSeparatedStr = this.getSearchParam('types');
		const typesStr = typesCommaSeparatedStr?.split(',') ?? [];
		const inTypes: ResourceType[] = [];

		for (const typeStr of typesStr) {
			const possibleType = Number(typeStr);

			if (possibleType === ResourceType.Billing) {
				inTypes.push(ResourceType.Billing);
			} else if (possibleType === ResourceType.Switch) {
				inTypes.push(ResourceType.Switch);
			} else if (possibleType === ResourceType.DidVendor) {
				inTypes.push(ResourceType.DidVendor);
			}
		}

		this.state.resourceParams.inTypes = inTypes;
		this.state.resourceParams.notInTypes = GetResourceTypesNotIn(inTypes);
	}

	public resetWithInTypes(inTypes: ResourceType[]) {
		const notInTypes = GetResourceTypesNotIn(inTypes);
		const resourceParams: IDidNumberIndexRequest = { inTypes, notInTypes };
		const activeDashboardTab = 'grid';
		const inTypeOptions: IResourceTypeOption[] = [];
		const notInTypeOptions: IResourceTypeOption[] = [];
		const resource = undefined;

		this.setState({
			resourceParams,
			activeDashboardTab,
			resource,
			inTypeOptions,
			notInTypeOptions
		});
	}

	public updateInTypesOptions(inTypeOptions: IResourceTypeOption[] | null) {
		const { resourceParams } = this.state;

		if (inTypeOptions === null) {
			inTypeOptions = [];
		}

		const inTypes = inTypeOptions.map((o) => o.value);
		resourceParams.inTypes = inTypes;

		this.setState({ resourceParams, inTypeOptions });
	}

	public updateNotInTypesOptions(
		notInTypeOptions: IResourceTypeOption[] | null
	) {
		const { resourceParams } = this.state;

		if (notInTypeOptions === null) {
			notInTypeOptions = [];
		}

		const notInTypes = notInTypeOptions.map((o) => o.value);
		resourceParams.notInTypes = notInTypes;

		this.setState({ resourceParams, notInTypeOptions });
	}

	public updateResource(resource?: ResourceConfigurationIndexResponse) {
		const { resourceParams } = this.state;

		if (resource) {
			resourceParams.resourceId = resource.resourceId;
		} else {
			delete resourceParams.resourceId;
		}

		this.setState({ resource, resourceParams });
	}

	public renderFilters() {
		if (this.state.activeDashboardTab !== 'grid') {
			return undefined;
		}

		const { resourceParams } = this.state;
		const resourceParamsStringified = JSON.stringify(resourceParams);
		const defaultInTypes =
			resourceParams.inTypes?.map((resourceTypes) => String(resourceTypes)) ??
			[];
		const defaultNotInTypes =
			resourceParams.notInTypes?.map((resourceTypes) =>
				String(resourceTypes)
			) ?? [];

		return (
			<section className="d-md-flex">
				<ResourceConfigurationSelect
					controlGroupClassName="flex-fill-no-shrink-or-grow mr-md-3"
					onChange={(resource: ResourceConfigurationIndexResponse) =>
						this.updateResource(resource)
					}
					value={this.state.resource}
				/>
				<ResourceTypeSelect<true>
					key={`${resourceParamsStringified}-numbers-in`}
					multi
					label="Numbers in"
					controlGroupClassName="flex-fill-no-shrink-or-grow mr-md-3"
					onChange={(inTypeOptions) => this.updateInTypesOptions(inTypeOptions)}
					value={this.state.inTypeOptions}
					initialOptionId={defaultInTypes}
				/>
				<ResourceTypeSelect<true>
					key={`${resourceParamsStringified}-numbers-not-in`}
					multi
					label="Numbers not in"
					controlGroupClassName="flex-fill-no-shrink-or-grow"
					onChange={(integrationOptions) =>
						this.updateNotInTypesOptions(integrationOptions)
					}
					value={this.state.notInTypeOptions}
					initialOptionId={defaultNotInTypes}
				/>
			</section>
		);
	}

	public render() {
		const { resourceParams } = this.state;
		const resourceParamsStringified = JSON.stringify(resourceParams);

		return (
			<StandardLayout router={DidDrilldownRouter}>
				<DashboardTabs
					className="mb-4"
					activeTab={this.state.activeDashboardTab}
					onTabChange={(activeDashboardTab) =>
						this.setState({ activeDashboardTab })
					}
					filters={this.renderFilters()}
				>
					<DashboardTab tabId="grid" icon="fas fa-table">
						<DidNumbersGrid
							key={resourceParamsStringified}
							resourceParams={this.state.resourceParams}
							autoFocusFilter
						/>
					</DashboardTab>
					<DashboardTab tabId="chart" icon="fas fa-chart-bar">
						<MetricSummary
							onDrilldownClick={(inTypes) => this.resetWithInTypes(inTypes)}
						/>
					</DashboardTab>
				</DashboardTabs>
			</StandardLayout>
		);
	}
}
