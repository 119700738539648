import * as React from 'react';
import clsx from 'clsx';

interface IObjectDataTreeProps {
	data: Record<any, any>;
	className?: string;
}

const PrettyPrintKeyMap = new Map<string, string>([
	//cSpell:ignore ingresssubscriptionid
	['ingresssubscriptionid', 'Ingress Subscription Id']
]);

export const ObjectDataTree: React.FC<
	React.PropsWithChildren<IObjectDataTreeProps>
> = ({ data, className = '' }) => {
	const isArray = Array.isArray(data);

	return (
		<ul className={clsx(className)}>
			{Object.keys(data).map((key, i) => (
				<li key={`${key}_${i}`}>
					{!isArray && <strong>{PrettyPrintKeyMap.get(key) ?? key}</strong>}
					{typeof data[key] !== 'object' && `: ${data[key]}`}
					{typeof data[key] === 'object' && <ObjectDataTree data={data[key]} />}
				</li>
			))}
		</ul>
	);
};
