import * as React from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash-es';
import clsx from 'clsx';
import { RtUiFormContext } from 'RtUi/components/ui/RtUiForm/RtUiFormContext';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface IControlGroupTooltip {
	icon: string;
	text: string;
}

export interface IControlGroupProps {
	label: string;
	sublabel?: string;
	sublabelClassName?: string;
	hideLabel?: boolean;
	hideFormGroup?: boolean;
	errorText?: string;
	value?: React.ReactNode;
	displayEmptyValue?: React.ReactNode;
	displayMode?: boolean;
	className?: string;
	labelClassName?: string;
	valueClassName?: string;
	required?: boolean;
	autoHeight?: boolean;
	linkTo?: string;
	isInvalid?: boolean;
	tooltip?: IControlGroupTooltip;
}

/**
 * ControlGroup is a shared component used across all controls.
 * The class currently shows controls below a label, but in the future this
 * can easily be moved to side-by-side implementation.
 */
export class ControlGroup extends React.PureComponent<
	React.PropsWithChildren<IControlGroupProps>
> {
	public static contextType = RtUiFormContext;
	public declare context: React.ContextType<typeof RtUiFormContext>;

	/**
	 * Render label above the control, and it doesn't work
	 */
	public renderLabel() {
		if (this.props.hideLabel) {
			return null;
		}

		const { label, labelClassName } = this.props;

		return (
			<Form.Label
				className={clsx(
					labelClassName,
					this.context.isInline && 'col col-form-label'
				)}
			>
				{label === '' && <>&nbsp;</>}
				{label && label}
				{this.renderLabelExtras()}
				{this.renderTooltip()}
			</Form.Label>
		);
	}

	public renderTooltip() {
		const { tooltip } = this.props;

		if (!tooltip) {
			return <></>;
		}

		return (
			<OverlayTrigger
				overlay={(props) => (
					<Tooltip id="control-tooltip" {...props}>
						{tooltip.text}
					</Tooltip>
				)}
			>
				{({ ref, ...triggerHandler }) => (
					<i
						ref={ref}
						{...triggerHandler}
						className={`fas fa-fw fa-${tooltip.icon} text-muted ms-1`}
						id="control-tooltip"
					/>
				)}
			</OverlayTrigger>
		);
	}

	/**
	 * Renders all the extra pieces that go next to the label.
	 * This includes sub-labels, required * indicators, and errorText
	 */
	public renderLabelExtras() {
		const {
			label,
			sublabel,
			displayMode,
			value,
			sublabelClassName,
			errorText,
			required,
			isInvalid = false
		} = this.props;

		if (!label && !sublabel && !errorText) {
			return null;
		}

		const valueIsEmpty = isNil(value) || isEmpty(value);
		const sublabelClassNames = ['text-muted'];

		if (sublabelClassName) {
			if (sublabelClassName.startsWith('text-')) {
				sublabelClassNames.pop();
			}

			sublabelClassNames.push(sublabelClassName);
		}

		return (
			<>
				{(!displayMode &&
					required &&
					valueIsEmpty &&
					Boolean(this.props.errorText)) ||
					(isInvalid && (
						<span className="text-danger invalid-feedback">&nbsp;*</span>
					))}
				{/* {!displayMode &&
					required &&
					!valueIsEmpty &&
					!Boolean(this.props.errorText) &&
					!isInvalid && <span className="text-success">&nbsp;*</span>} */}
				{!displayMode && required && (
					<span className="text-danger">&nbsp;*</span>
				)}
				{sublabel && (
					<small className={sublabelClassNames.join(' ')}>
						&nbsp;{sublabel}
					</small>
				)}
			</>
		);
	}

	/**
	 * Render read only version of the control
	 */
	public renderReadonlyControl() {
		const {
			linkTo,
			value,
			valueClassName = '',
			autoHeight,
			displayEmptyValue
		} = this.props;
		const val =
			value && value !== 'N/A' && !displayEmptyValue
				? value
				: displayEmptyValue;
		const style: React.CSSProperties = {};

		return (
			<article
				className={clsx(valueClassName, 'form-control-plaintext', {
					'form-control-plaintext-auto-height': autoHeight
				})}
				style={style}
			>
				{linkTo ? (
					<div className="d-flex justify-content-between align-items-center">
						<Link to={linkTo}>{val}</Link>
						<Button
							variant="white"
							size="sm"
							className="py-0"
							title={`Open in a new tab.`}
							target="_blank"
							href={linkTo}
							rel="noreferrer"
						>
							<i className="fas fa-external-link-alt" />
						</Button>
					</div>
				) : (
					val
				)}
			</article>
		);
	}

	public renderError() {
		if (!this.props.displayMode) {
			return (
				<span className="text-danger invalid-feedback">
					&nbsp;{this.props.errorText}
				</span>
			);
		}
	}

	/**
	 * Render label and the control
	 * If in displayMode, a readonly view is shown.
	 * Otherwise, the actual control (from props.children)
	 */
	public renderControl() {
		const { displayMode } = this.props;

		const control = (
			<>
				{displayMode && this.renderReadonlyControl()}
				{!displayMode && this.props.children}
			</>
		);

		if (this.context.isInline) {
			return (
				<div className={clsx('col')}>
					{this.props.linkTo && this.props.displayMode ? (
						<div className="d-flex align-items-center">
							{this.props.value}
							<Button
								variant="white"
								size="sm"
								className="py-0"
								title={`Open in a new tab.`}
								target="_blank"
								href={this.props.linkTo}
								rel="noreferrer"
							>
								<i className="fas fa-external-link-alt" />
							</Button>
						</div>
					) : this.props.displayMode ? (
						this.props.value
					) : (
						control
					)}
				</div>
			);
		}

		return control;
	}

	public renderLabelAndControl() {
		return (
			<>
				{this.renderLabel()}
				{this.renderControl()}
				{this.props.errorText && this.renderError()}
			</>
		);
	}

	public render() {
		const labelAndControlComponent = this.renderLabelAndControl();
		const className = clsx(this.props.className, {
			'is-invalid': Boolean(this.props.errorText),
			'inline-row is-condensed': this.context.isInline
		});

		if (this.props.hideFormGroup) {
			return labelAndControlComponent;
		}

		return (
			<Form.Group
				className={clsx(className, this.context.isInline && 'row', {
					'mb-3':
						!this.context.isInline ||
						(this.context.isInline && !this.props.displayMode)
				})}
			>
				{labelAndControlComponent}
			</Form.Group>
		);
	}
}
