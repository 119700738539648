import { FlowIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';

export const getFlowColumns = (): Array<DataGridColumn<FlowIndexResponse>> => [
	IdDataGridColumn({
		accessorKey: 'flowId'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	})
];
