import { IntegrationsApiRoutes } from 'RtExports/routes';
import {
	ResourceConfigurationIndexResponse,
	ResourceConfigurationProfileResponse
} from 'RtModels';
import {
	IResourceConfigurationProfileContainerTabs,
	ResourceConfigurationProfileContainerTabs
} from 'RtUi/app/rtCommon/ResourceConfigurations/lib/components/ResourceConfigurationsProfileContainer';
import { DidResourceRouter } from 'RtUi/app/rtDid/Resources/DidResource.router';
import { DidApplicationRouter } from 'RtUi/app/rtDid/rtDid.router';
import { IRouterBreadcrumb } from 'RtUi/components/containers/lib/RtUiRouter';

export class DidResourceConfigurationRouterClass extends DidApplicationRouter<
	ResourceConfigurationIndexResponse,
	ResourceConfigurationProfileResponse,
	IResourceConfigurationProfileContainerTabs
> {
	constructor() {
		super(
			'Resource',
			'resourceConfigurations',
			'resourceConfigurationId',
			ResourceConfigurationProfileContainerTabs(true)
		);

		this.setPermissionsFromApiRoute(IntegrationsApiRoutes.Resources);
	}

	public getIndexLabel(record: ResourceConfigurationIndexResponse) {
		return record.label;
	}

	public async getBreadcrumbs(
		currentPath: string
	): Promise<IRouterBreadcrumb[]> {
		const sectionBreadcrumb = super.getSectionBreadcrumb();
		const didResourceBreadcrumb = DidResourceRouter.getIndexBreadcrumb();
		const resourceBreadcrumb = this.getIndexBreadcrumb(true);

		return [sectionBreadcrumb, didResourceBreadcrumb, resourceBreadcrumb];
	}

	public getProfileLabel(profile: ResourceConfigurationProfileResponse) {
		return profile.label;
	}
}

export const DidResourceConfigurationRouter =
	new DidResourceConfigurationRouterClass();
