/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RateLoadOldRateIndexResponse = {
    properties: {
        rateLoadOldRateId: {
            type: 'string',
            isRequired: true,
        },
        rateLoadId: {
            type: 'number',
            isRequired: true,
        },
        srcRateId: {
            type: 'number',
            isRequired: true,
        },
        srcRateKey: {
            type: 'string',
            isRequired: true,
        },
        srcCountryCode: {
            type: 'string',
            isRequired: true,
        },
        srcIso3: {
            type: 'string',
            isRequired: true,
        },
        srcRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        srcRateSummaryId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        srcStartTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        srcEndTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        srcInterRate: {
            type: 'number',
            isRequired: true,
        },
        srcIntraRate: {
            type: 'number',
            isRequired: true,
        },
        srcIndetRate: {
            type: 'number',
            isRequired: true,
        },
        srcLocalRate: {
            type: 'number',
            isRequired: true,
        },
        srcDipFee: {
            type: 'number',
            isRequired: true,
        },
        srcConFee: {
            type: 'number',
            isRequired: true,
        },
        contractEndTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        useStartTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        useEndTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        matchingRateId: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        rateKeyStateId: {
            type: 'number',
            isRequired: true,
        },
        rateChangeTypeId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rateChangeSummary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        rateLoadErrorTypeId: {
            type: 'RateLoadErrorType',
            isRequired: true,
            isNullable: true,
        },
    },
};