/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ResourceConfigurationIndexRequest = {
    properties: {
        isActive: {
            type: 'number',
        },
        isDefault: {
            type: 'number',
        },
        search: {
            type: 'string',
        },
        resourceId: {
            type: 'number',
        },
        includeResourceTypes: {
            type: 'Array',
        },
        excludeResourceTypes: {
            type: 'Array',
        },
        hasTaskTypeId: {
            type: 'Array',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};