/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $PartitionSipGatewayUpdateRequest = {
    properties: {
        serverId: {
            type: 'number',
            isRequired: true,
        },
        listenPort: {
            type: 'number',
            isRequired: true,
        },
        codecs: {
            type: 'string',
            isRequired: true,
        },
        egressPercent: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        isOnline: {
            type: 'number',
        },
        gwMajorVersion: {
            type: 'number',
            isRequired: true,
        },
        gwMinorVersion: {
            type: 'number',
            isRequired: true,
        },
        gwPatchVersion: {
            type: 'number',
            isRequired: true,
        },
        inboundPortLimit: {
            type: 'number',
            isRequired: true,
        },
        mediaServerGroupId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
    },
};