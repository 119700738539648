//import * as React from 'react';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import {
	IIntegrationOption,
	IntegrationResource
} from 'RtUi/app/rtCommon/Integrations/resources/IntegrationResource';

interface IIntegrationSelectProps
	extends ISelectFormControlProps<IIntegrationOption> {}

export class IntegrationSelect extends SelectFormControl<
	IIntegrationOption,
	false,
	IIntegrationSelectProps
> {
	public resourceClass = IntegrationResource;
	public state: ISelectFormControlState<IIntegrationOption> = {
		formLabel: 'Integration',
		valueKey: 'value',
		labelKey: 'label'
	};
}
