/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TFIndexResponse = {
    properties: {
        tfn: {
            type: 'string',
            isRequired: true,
        },
        taskType: {
            type: 'string',
            isRequired: true,
        },
        taskStatusId: {
            type: 'number',
            isRequired: true,
        },
        taskStatusLabel: {
            type: 'string',
            isRequired: true,
        },
        taskStarted: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        taskCompleted: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        jobId: {
            type: 'string',
            isRequired: true,
        },
        jobStatusId: {
            type: 'number',
            isRequired: true,
        },
        jobStatusLabel: {
            type: 'string',
            isRequired: true,
        },
        jobCompleted: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        priority: {
            type: 'number',
            isRequired: true,
        },
    },
};