/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum Attestation {
    A = 'A',
    B = 'B',
    C = 'C',
}