import { RatePlanIndexRequest, RatePlanIndexResponse } from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { RateManagementRouter } from 'RtUi/app/AccountManagement/RateManagement/RateManagement.router';
import { RatePlanRouter } from 'RtUi/app/AccountManagement/RatePlan/RatePlan.router';
import { useGetRatePlans } from 'RtUi/app/AccountManagement/RatePlan/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRatePlansColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/ratePlans';
import { useMemo } from 'react';
import { Item } from 'react-contexify';

interface IRatePlanGridProps {
	autoFocusFilter?: boolean;
	resourceParams: RatePlanIndexRequest;
}

export const RatePlanDataGrid = ({ resourceParams }: IRatePlanGridProps) => {
	const { data, isFetching: isLoading } = useGetRatePlans(resourceParams, true);
	const columns = useMemo(() => getRatePlansColumns(), []);

	return (
		<DataGrid<RatePlanIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'accountManagement_ratePlans'}
			disableExternalLinks
			router={RatePlanRouter}
			menuLinks={(record) => (
				<>
					<Item
						onClick={() => {
							BrowserHistory.push(
								RateManagementRouter.getIndexRouteWithParams({
									ratePlan: record.ratePlanId
								})
							);
						}}
					>
						<span className="d-flex justify-content-start align-items-center">
							<i className="fas fa-fw fa-search" />
							<span>Rate Import</span>
						</span>
					</Item>
				</>
			)}
		/>
	);
};
