import { useMethods } from 'react-use';

type CreateMethods<M extends AbstractedMethods<T>, T> = (state: T) => {
	[P in keyof M]: M[P];
};

export type WrappedMethods<M extends AbstractedMethods> = {
	[P in keyof M]: (...params: Parameters<M[P]>) => void;
};

export type AbstractedMethods<ReturnType = any> = {
	[key: string]: (...args: any[]) => ReturnType;
};

export const useMethodsTyped = <M extends AbstractedMethods<T>, T>(
	createMethods: CreateMethods<M, T>,
	initialState: T
): [T, WrappedMethods<M>] => {
	return useMethods(createMethods, initialState);
};

//test
// interface ISomeState {
// 	count: number;
// }
// const initialState: ISomeState = {
// 	count: 0
// };

// function createMethods(state: ISomeState) {
// 	return {
// 		reset() {
// 			return initialState;
// 		},
// 		increment(amount = 1) {
// 			return { ...state, count: state.count + amount };
// 		},
// 		decrement(amount = 1) {
// 			return { ...state, count: state.count - amount };
// 		}
// 	};
// }

// const [state, methods] = useMethodsTyped(createMethods, initialState);

// methods.decrement(1);
// methods.increment(2);
// methods.reset();
