import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { useState } from 'react';
import ReactInputMask from 'react-input-mask';

interface INpaNxxInputFormControlProps {
	label?: string;
	value: string;
	displayMode?: boolean;
	required?: boolean;
	onChange: (value: string) => void;
}

const npanxxRegex = /^([2-9]\d{2})(99\d)$/g;

export const NpaNxxInputFormControl = ({
	value,
	label = 'NpaNxx',
	displayMode = false,
	required = false,
	onChange
}: INpaNxxInputFormControlProps): JSX.Element => {
	const [error, setError] = useState<string>();
	const [inputValue, setInputValue] = useState<string>(value);

	const onChangeHandler = (newValue: string) => {
		if (newValue.length > 6) {
			return;
		}

		setInputValue(newValue);
		setError(undefined);

		if (!npanxxRegex.test(newValue)) {
			setError('Invalid value');

			return;
		}

		onChange(newValue);
	};

	return (
		<ControlGroup
			errorText={error}
			label={label}
			value={inputValue}
			hideLabel
			displayMode={displayMode}
			required={required}
			valueClassName="text-monospace form-control-tollfree"
			className={`form-control-tollfree-wrapper ${
				Boolean(error) ? 'is-invalid' : ''
			}`}
		>
			<ReactInputMask
				mask="***-***"
				alwaysShowMask
				value={inputValue}
				onChange={(evt: any) => onChangeHandler(evt.replace(/[\_|\-]/g, ''))}
				required={required}
			>
				<InputFormControl
					label={label}
					hideLabel={Boolean(!label)}
					style={{ width: '110px' }}
				/>
			</ReactInputMask>
		</ControlGroup>
	);
};
