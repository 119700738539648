/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DefaultSipAgentConfig = {
    properties: {
        switchIdGwOnly: {
            type: 'boolean',
            isRequired: true,
        },
        switchIdOmit: {
            type: 'Array',
            isRequired: true,
        },
        switchIdOnly: {
            type: 'Array',
            isRequired: true,
        },
        sendTrying: {
            type: 'number',
            isRequired: true,
        },
        identityRequired: {
            type: 'number',
            isRequired: true,
        },
        stirShaken: {
            type: 'number',
            isRequired: true,
        },
        stirShakenCertificate: {
            type: 'number',
            isRequired: true,
        },
        stirShakenCertificateMs: {
            type: 'number',
            isRequired: true,
        },
        nanpLrnTrustNpdi: {
            type: 'number',
            isRequired: true,
        },
        nanpLrnLookup: {
            type: 'number',
            isRequired: true,
        },
        nanpLrnTimeoutMs: {
            type: 'number',
            isRequired: true,
        },
        doMediation: {
            type: 'number',
            isRequired: true,
        },
        medDefaultSubId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        medNotFoundSubId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        medValues: {
            type: 'Array',
            isRequired: true,
        },
        medSources: {
            type: 'Array',
            isRequired: true,
        },
        medTarget: {
            type: 'SipAgentMedTarget',
            isRequired: true,
        },
        globalBlock: {
            type: 'number',
            isRequired: true,
        },
        globalBlockOmit: {
            type: 'Array',
            isRequired: true,
        },
        globalBlockOnly: {
            type: 'Array',
            isRequired: true,
        },
        partitionBlock: {
            type: 'number',
            isRequired: true,
        },
        partitionBlockOmit: {
            type: 'Array',
            isRequired: true,
        },
        partitionBlockOnly: {
            type: 'Array',
            isRequired: true,
        },
        serviceNumberLookup: {
            type: 'number',
            isRequired: true,
        },
        serviceNumberTranslate: {
            type: 'number',
            isRequired: true,
        },
        applyMp: {
            type: 'number',
            isRequired: true,
        },
        applySq: {
            type: 'number',
            isRequired: true,
        },
        applyRr: {
            type: 'number',
            isRequired: true,
        },
        contactUriFormat: {
            type: 'SipAgentContactUriFormat',
            isRequired: true,
            isNullable: true,
        },
        contactTarget: {
            type: 'SipAgentContactTarget',
            isRequired: true,
            isNullable: true,
        },
        contactValue: {
            type: 'SipAgentContactValue',
            isRequired: true,
            isNullable: true,
        },
        nanpDialingFormat: {
            type: 'SipAgentDialFormat',
            isRequired: true,
        },
        idddDialingFormat: {
            type: 'SipAgentDialFormat',
            isRequired: true,
        },
    },
};