import { RoutingRuleIndexResponse } from 'RtModels';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';
import { RoutingRuleMatchingDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RoutingRuleMatchingDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';

export const getRoutingRulesColumns = (
	removeRoutingRule: (row: RoutingRuleIndexResponse) => void
): Array<DataGridColumn<RoutingRuleIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => removeRoutingRule(row)
	}),
	IdDataGridColumn({
		accessorKey: 'routingRuleId'
	}),
	LabelDataGridColumn({
		accessorKey: 'label',
		header: 'Summary'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	RoutingRuleMatchingDataGridColumn({
		accessorKey: 'matching',
		header: 'Matching'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isLcrAppend',
		header: 'Append LCR'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isCascade',
		header: 'Cascade'
	})
];
