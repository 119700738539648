/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateControlRerateType {
    Rerate = 'rerate',
    Recost = 'recost',
    Both = 'both',
}