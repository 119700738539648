import { useMemo } from 'react';
import { SwitchIndexResponse } from 'RtModels';
import { useGetSwitches } from 'RtUi/app/AccountManagement/Switches/lib/services';
import { IsMultiFormValue } from 'RtUi/components/form/FormControl';
import { ISelectFormControlProps } from 'RtUi/components/form/SelectFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface ISwitchesSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<SwitchIndexResponse, IsMulti> {
	value?: IsMultiFormValue<SwitchIndexResponse, IsMulti>;
	onChange?: (newValue: IsMultiFormValue<SwitchIndexResponse, IsMulti>) => void;
	label?: string;
	required?: boolean;
	isClearable?: boolean;
}

export const SwitchesSelect = <IsMulti extends boolean = false>({
	value,
	onChange = () => {},
	required,
	isClearable = true,
	displayMode,
	label = 'Switch',
	initialOptionId,
	hideLabel,
	multi
}: ISwitchesSelectProps<IsMulti>): JSX.Element => {
	const { data, isLoading } = useGetSwitches();

	const selectData = useMemo(() => data?.data || [], [data]);
	const options: Array<ISimpleSelectFormControlOption<number>> = useMemo(() => {
		return selectData.map((switchData) => ({
			value: switchData.switchId,
			label: switchData.label
		}));
	}, [selectData]);

	const selectValue = useMemo(() => {
		return multi
			? options.filter((opt) =>
					(value as SwitchIndexResponse[])?.some(
						(switchData) => switchData.switchId === opt.value
					)
				)
			: options.find(
					(opt) => opt.value === (value as SwitchIndexResponse)?.switchId
				);
	}, [value, options, multi]);

	const initialOptions = useMemo(
		() =>
			multi
				? (initialOptionId as string[]).map((val) => val.toString())
				: initialOptionId?.toString(),
		[initialOptionId, multi]
	);

	const getMultiValues = (
		values: Array<ISimpleSelectFormControlOption<number>>
	) => {
		const mappedValues = values.map((v) => v.value);

		return selectData.filter((d) => mappedValues.includes(d.switchId));
	};

	const onChangeHandler = (
		newValue: IsMulti extends true
			? Array<ISimpleSelectFormControlOption<number>>
			: ISimpleSelectFormControlOption<number>
	) => {
		const val = multi
			? getMultiValues(
					newValue as Array<ISimpleSelectFormControlOption<number>>
				)
			: selectData.find(
					(d) =>
						(newValue as ISimpleSelectFormControlOption<number>)?.value ===
						d.switchId
				);

		onChange(val as any);
	};

	return (
		<SimpleSelectFormControl<number, IsMulti>
			label={label}
			multi={multi}
			isLoading={isLoading}
			clearable={isClearable}
			required={required}
			displayMode={displayMode}
			value={selectValue as any}
			hideLabel={hideLabel}
			onChange={(val: any) => {
				onChangeHandler(val);
			}}
			options={options}
			initialOptionId={initialOptions as any}
		/>
	);
};
