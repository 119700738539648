import { GuardianReconciliationTypeIndexResponse } from 'RtModels';
import { ReconciliationTypeResource } from 'RtUi/app/rtVue/ReconciliationType/lib/resources/ReconciliationTypeResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface IReconciliationTypeSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<
		GuardianReconciliationTypeIndexResponse,
		IsMulti
	> {}
interface IReconciliationTypeSelectState
	extends ISelectFormControlState<GuardianReconciliationTypeIndexResponse> {}

export class ReconciliationTypeSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	GuardianReconciliationTypeIndexResponse,
	IsMulti,
	IReconciliationTypeSelectProps<IsMulti>,
	IReconciliationTypeSelectState
> {
	public resourceClass = ReconciliationTypeResource;
	public state: IReconciliationTypeSelectState = {
		formLabel: 'Reconciliation Type',
		valueKey: 'guardianReconciliationTypeId',
		labelKey: 'label'
	};
}
