import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { JobStatusId } from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { JobStatusHelper } from 'RtUi/app/rtCarrierConnect/Jobs/bin/JobStatusHelper';
import { castArray } from 'lodash-es';

export interface IJobTaskStatusSelectOption {
	label: string;
	value: JobStatusId;
	disabled: boolean;
}

interface IJobTaskStatusSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<IJobTaskStatusSelectOption, isMulti> {
	enabledJobStatusIds?: JobStatusId[];
}

export class JobTaskStatusSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	IJobTaskStatusSelectOption,
	isMulti,
	IJobTaskStatusSelectProps<isMulti>
> {
	public resourceClass = undefined;
	public state: ISelectFormControlState<IJobTaskStatusSelectOption> = {
		formLabel: 'Status',
		valueKey: 'value',
		labelKey: 'label',
		disabledKey: 'disabled'
	};

	constructor(props: IJobTaskStatusSelectProps<isMulti>) {
		super(props);

		const jobStatusHelper = new JobStatusHelper();
		const { enabledJobStatusIds } = this.props;
		const jobStatusIds: JobStatusId[] = [];

		if (enabledJobStatusIds) {
			jobStatusIds.push(...enabledJobStatusIds);

			if (props.initialOptionId) {
				const addedJobStatsId: JobStatusId = Number(props.initialOptionId);

				if (!jobStatusIds.includes(addedJobStatsId)) {
					jobStatusIds.push(addedJobStatsId);
				}
			}
		} else {
			for (const value of Object.values(JobStatusId)) {
				const possibleEnumValue = Number(value);
				if (!isNaN(possibleEnumValue)) {
					jobStatusIds.push(possibleEnumValue);
				}
			}
		}

		const jobStatusOptions: IJobTaskStatusSelectOption[] = jobStatusIds.map(
			(jobStatus) => {
				return {
					label: jobStatusHelper.getReadableJobStatus(jobStatus),
					value: jobStatus,
					disabled: enabledJobStatusIds
						? !enabledJobStatusIds.includes(jobStatus)
						: false
				};
			}
		);

		jobStatusOptions.sort((s1, s2) => s1.label.localeCompare(s2.label));

		this.resource = new ArrayResource<IJobTaskStatusSelectOption>(
			'value',
			jobStatusOptions
		);
	}

	/**
	 * @override
	 */
	public getCustomerValidityMessage() {
		return 'Please select a completed status';
	}

	/**
	 * @override
	 */
	public getRequiredInputValidationValue() {
		const { enabledJobStatusIds } = this.props;

		if (!enabledJobStatusIds) {
			return '1';
		}

		const options = this.props.value ? castArray(this.props.value) : [];
		const enabledStatusIsSelected = options.every((option) =>
			enabledJobStatusIds.includes(option.value)
		);

		return enabledStatusIsSelected ? '1' : '';
	}
}
