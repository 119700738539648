import { Row, Col } from 'react-bootstrap';
import { GuardianReconciliationProfileResponse } from 'RtModels';
import { GuardianReconciliationEditor } from 'RtUi/app/rtVue/GuardianReconciliation/lib/forms/GuardianReconciliationEditor';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { GuardianReconciliationsRouter } from './GuardianReconciliation.router';
import { GuardianReconciliationGrid } from './lib/grids/GuardianReconciliationGrid';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useNavigate } from 'react-router-dom';
import { useUrlSearchParams } from 'RtUi/components/hooks/useUrlSearchParams';
import { GuardianReconciliationBulkUpdateForm } from 'RtUi/app/rtVue/GuardianReconciliation/lib/forms/GuardianReconciliationBulkUpdateForm';

export const GuardianReconciliationIndexContainer = (): JSX.Element => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Results: { header: 'Results', isDefault: true },
		CreateReconciliation: {
			header: 'Create Manual Reconciliation Record',
			isAction: true
		},
		BulkUpdateReconciliation: {
			header: 'Upload Invoice Usage Data',
			isAction: true
		}
	});

	const navigate = useNavigate();
	const { urlSearchParams } = useUrlSearchParams();

	const goToProfile = (profile: GuardianReconciliationProfileResponse) => {
		const profilePath = GuardianReconciliationsRouter.getProfileRoute(
			profile.guardianReconciliationId
		);

		navigate(profilePath);
	};

	return (
		<TabbedLayout
			router={GuardianReconciliationsRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Results}>
				<GuardianReconciliationGrid
					resourceParams={urlSearchParams}
					pageName="rtVue-guardianReconciliation"
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateReconciliation}>
				<Row>
					<Col lg={8}>
						<GuardianReconciliationEditor createMode onUpdate={goToProfile} />
					</Col>
				</Row>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.BulkUpdateReconciliation}>
				<Row>
					<Col lg={8}>
						<GuardianReconciliationBulkUpdateForm
							onSuccess={() => setActiveTab(tabs.Results.header)}
						/>
					</Col>
				</Row>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

GuardianReconciliationIndexContainer.displayName =
	'GuardianReconciliationIndexContainer';

GuardianReconciliationsRouter.setIndexRtUiFunctionalComponent(
	GuardianReconciliationIndexContainer,
	{
		groupName: 'Reconciliation',
		orderPriority: 3
	}
);
