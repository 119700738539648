/* eslint-disable max-classes-per-file */

import {
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { ROCNumberStatusMap } from 'RtUi/utils/maps';

export type RocStatusType = (typeof ROCNumberStatusMap)[0];

export class RocStatusResource extends ArrayResource<RocStatusType> {
	constructor() {
		super('status', ROCNumberStatusMap);
	}
}

export class RocStatusSelect<
	isMulti extends boolean = false
> extends SelectFormControl<RocStatusType, isMulti> {
	public resourceClass = RocStatusResource;
	public state: ISelectFormControlState<RocStatusType> = {
		formLabel: 'ROC Status',
		valueKey: 'status',
		labelKey: 'label'
	};
}
