import * as React from 'react';
import {
	GuardianAlertHistoryIndexRequest,
	GuardianAlertHistoryDashboardResponse
} from 'RtModels';
import { AlertHistoryResource } from 'RtUi/app/rtGuardian/AlertHistory/lib/resources/AlertHistoryResource';
import { Col, Row, Card } from 'react-bootstrap';
import { ChartWrapper } from 'RtUi/app/rtVue/common/lib/charts/ChartWrapper';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { ChartData } from 'chart.js';
import { ChartJsColors } from 'RtUi/components/charts/Constants';

interface IAlertDashboard {
	resourceParams: GuardianAlertHistoryIndexRequest;
	className?: string;
	height?: number;
}

export const AlertDashboard: React.FC<
	React.PropsWithChildren<IAlertDashboard>
> = ({ resourceParams, className, height = 200 }) => {
	type DashResponseState = GuardianAlertHistoryDashboardResponse | null;
	const [dashboard, setDashboard] = React.useState<DashResponseState>(null);

	React.useEffect(() => {
		const dashParams: GuardianAlertHistoryIndexRequest = { ...resourceParams };
		const alertHistoryResource = new AlertHistoryResource();
		alertHistoryResource.getDashboard(dashParams).then(setDashboard);
	}, [resourceParams]);

	const colors = [
		'#5562A4',
		ChartJsColors.LightOrange,
		'rgb(203,75,75)',
		'rgb(175,216,248)',
		'rgb(77,167,77)',
		'rgb(148, 64, 236)',
		'rgb(189, 155, 51)',
		'rgb(140, 172, 198)'
	];

	const lineData: ChartData = {
		datasets: [
			{
				type: 'line',
				fill: false,
				borderColor: '#5562A4',
				backgroundColor: '#5562A4',
				data: dashboard?.counts.map((r) => r.qty) || []
			}
		],
		labels: dashboard?.counts.map((r) => new Date(r.timeslot))
	};

	const pieData: ChartData = {
		datasets: [
			{
				type: 'pie',
				//@ts-expect-error
				fill: true,
				backgroundColor: colors,
				borderColor: colors,
				data: dashboard?.bySource.map((r) => r.qty) || []
			}
		],
		labels: dashboard?.bySource.map((r) => r.sourceName)
	};

	return (
		<Row>
			<Col lg={6} className={className}>
				<Card body className="h-100">
					{dashboard?.counts && (
						<ChartWrapper
							className="h-100"
							config={{
								type: 'line',
								data: lineData,
								options: {
									maintainAspectRatio: false,
									plugins: {
										legend: { display: false }
									},
									scales: {
										y: {
											beginAtZero: true
										},
										x: {
											type: 'time',
											adapters: {
												date: {
													locale: enUS
												}
											}
										}
									}
								}
							}}
						/>
					)}
				</Card>
			</Col>
			<Col lg={6} className={className}>
				<Card body>
					{dashboard?.bySource && (
						<ChartWrapper
							config={{
								type: 'pie',
								data: pieData,
								options: {
									maintainAspectRatio: false,
									plugins: {
										legend: { display: true }
									}
								}
							}}
						/>
					)}
				</Card>
			</Col>
		</Row>
	);
};
