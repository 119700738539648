import { RatePlanDefaultProfileResponse } from 'RtModels';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';

interface RatePlanDefaultsProps {
	data: RatePlanDefaultProfileResponse | Record<string, never>;
	displayMode: boolean;
	setIsEditing: (value: boolean) => void;
	isLoading: boolean;
	onSubmit: (data: any) => void;
	setModalOpen: (value: boolean) => void;
	error: any;
	handleDelete: (data: any) => void;
}

export const RatePlanDefaultsForm = (props: RatePlanDefaultsProps) => {
	const {
		error,
		data,
		displayMode,
		setIsEditing,
		isLoading,
		onSubmit,
		setModalOpen,
		handleDelete
	} = props;

	const handleSetModalOpen = () => {
		setModalOpen(true);
	};

	const handleDeleteInternal = async () => {
		return handleDelete(data);
	};

	const { control, handleSubmit } = useForm({
		defaultValues: {
			startTs: data?.startTs || undefined,
			ratedIso: data?.ratedIso || null,
			ratedExt: data?.ratedExt || null,
			jurisIso: data?.jurisIso || null,
			jurisExt: data?.jurisExt || null,
			altRatePlanId: data?.altRatePlanId || null,
			inGraceDur: data?.inGraceDur,
			egGraceDur: data?.egGraceDur,
			conFee: data?.conFee,
			interRate: data?.interRate,
			intraRate: data?.intraRate,
			indetRate: data?.indetRate,
			localRate: data?.localRate,
			durMin: data?.durMin,
			durInc: data?.durInc
		}
	});
	return (
		<RtForm
			narrow={true}
			error={error}
			isSubmitting={isLoading}
			displayMode={displayMode}
			onChange={(val) => setIsEditing(val)}
			onSubmit={handleSubmit(onSubmit)}
			createMode={false}
			hasCreateButton={isEmpty(data)}
			hasDeleteButton={!isEmpty(data)}
			hasEditButton={!isEmpty(data)}
			handleClickCreate={handleSetModalOpen}
			handleClickDelete={handleDeleteInternal}
		>
			{!isEmpty(data) && !error ? (
				<>
					<Controller
						control={control}
						name="startTs"
						render={({ field: { onChange, value } }) => (
							<DatetimeFormControl
								displayMode={displayMode}
								label="Start time"
								value={moment(value)}
								onChange={onChange}
								name="startTs"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="ratedIso"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Rate Iso"
								onChange={onChange}
								displayMode={displayMode}
								value={String(value)}
								name="ratedIso"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="ratedExt"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Rated Ext"
								onChange={onChange}
								displayMode={displayMode}
								value={String(value)}
								name="ratedExt"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="jurisIso"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Juris Iso"
								onChange={onChange}
								displayMode={displayMode}
								value={String(value)}
								name="jurisIso"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="jurisExt"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Juris Ext"
								onChange={onChange}
								displayMode={displayMode}
								value={String(value)}
								name="jurisExt"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="altRatePlanId"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Alt Rate Plan id"
								onChange={onChange}
								displayMode={displayMode}
								value={String(value)}
								name="altRatePlanId"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="inGraceDur"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								type="number"
								label="In grace duration"
								onChange={onChange}
								displayMode={displayMode}
								value={String(value)}
								name="inGraceDur"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="egGraceDur"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								type="number"
								label="Eg grace duration"
								onChange={onChange}
								displayMode={displayMode}
								value={String(value)}
								name="egGraceDur"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="conFee"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								type="number"
								label="Connection surcharge"
								onChange={onChange}
								displayMode={displayMode}
								value={String(value)}
								name="conFee"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="intraRate"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								type="number"
								label="Intrastate Rate"
								onChange={onChange}
								displayMode={displayMode}
								value={String(value)}
								name="intraRate"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="indetRate"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								type="number"
								label="Indeterminate Rate"
								onChange={onChange}
								displayMode={displayMode}
								value={String(value)}
								name="indetRate"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="localRate"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								type="number"
								label="Local Rate"
								displayMode={displayMode}
								onChange={onChange}
								value={String(value)}
								name="localRate"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="durMin"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								type="number"
								label="Duration minimum"
								displayMode={displayMode}
								onChange={onChange}
								value={String(value)}
								name="durMin"
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="durInc"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								type="number"
								label="Duration increment"
								displayMode={displayMode}
								onChange={onChange}
								value={String(value)}
								name="durInc"
								required
							/>
						)}
					/>
				</>
			) : (
				<span>Empty rate plan default</span>
			)}
		</RtForm>
	);
};
