/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ManagedRespOrgIndexResponse = {
    properties: {
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        isManaged: {
            type: 'number',
        },
        isApi: {
            type: 'number',
            isRequired: true,
        },
        apiResourceId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        contactName: {
            type: 'string',
            isRequired: true,
        },
        contactNumber: {
            type: 'string',
            isRequired: true,
        },
        mgiUserName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        sqlUserName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        rocUserName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        apiStatus: {
            type: 'ManagedStatus',
        },
        sqlStatus: {
            type: 'ManagedStatus',
        },
        rocStatus: {
            type: 'ManagedStatus',
        },
        lastSqlAttemptTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastSqlSuccessTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastRocAttemptTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastRocSuccessTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastApiAttemptTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastApiSuccessTs: {
            type: 'Date',
            format: 'date-time',
        },
    },
};