import { ForwardedRef, useEffect, useRef } from 'react';

export const useCombinedRefs = <T>(...refs: Array<ForwardedRef<T>>) => {
	const targetRef = useRef(null);

	useEffect(() => {
		refs.forEach((ref) => {
			if (!ref) return;

			if (typeof ref === 'function') {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
};
