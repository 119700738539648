import { FileTypeIndexResponse } from 'RtModels';
import { FileTypeRouter } from 'RtUi/app/rtVue/FileTypes/FileType.router';
import { useGetFileTypes } from 'RtUi/app/rtVue/FileTypes/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getFileTypeColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/fileTypes';
import { useMemo } from 'react';

interface IFileTypeDataGridProps {}

export const FileTypeDataGrid = ({}: IFileTypeDataGridProps) => {
	const { data, isLoading } = useGetFileTypes();
	const columns = useMemo(() => getFileTypeColumns(), []);

	return (
		<DataGrid<FileTypeIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'configureRateSheet-fileTypes'}
			router={FileTypeRouter}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
