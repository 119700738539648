/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianConnectionSubscriptionCreateRequest = {
    properties: {
        guardianConnectionId: {
            type: 'number',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        serviceTypeId: {
            type: 'number',
            isNullable: true,
        },
        directionId: {
            type: 'number',
            isRequired: true,
        },
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        updateOption: {
            type: 'GuardianConnectionUpdateOptions',
        },
        endTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
    },
};