/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ScenarioActionGotoScenario = {
    properties: {
        scenarioActionId: {
            type: 'ScenarioActionType',
            isRequired: true,
        },
        scenarioId: {
            type: 'number',
            isRequired: true,
        },
    },
};