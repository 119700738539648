import { get, isUndefined } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import {
	BlockTypeIndexResponse,
	MarginPlanIndexResponse,
	QosManagementIndexRequest,
	QosManagementIndexResponse,
	QosManagementUpdateRequest
} from 'RtModels';
import { MarginPlanSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/MarginPlanSelect';
import { BlockTypeSelect } from 'RtUi/app/rtSip/BlockTypes/lib/controls/BlockTypeSelect';
import { QosManagementSearchForm } from 'RtUi/app/rtSip/QosManagement/lib/forms/QosManagementSearchForm';
import { QosManagementResource } from 'RtUi/app/rtSip/QosManagement/lib/resource/QosManagementResource';
import { MultipleCheckboxFormControl } from 'RtUi/components/form/MultipleCheckboxFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { SliderFormControl } from 'RtUi/components/form/SliderFormControl';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';

interface SqSelectValue {
	label: string;
	value: number;
}

declare type SqValues = Pick<
	QosManagementUpdateRequest,
	'sq0' | 'sq1' | 'sq2' | 'sq3' | 'sq4' | 'sq5' | 'sq6' | 'sq7' | 'sq8' | 'sq9'
>;
declare type SqKeys = keyof SqValues;

interface IQosManagementBulkUpdateFormProps {
	requestData: QosManagementIndexRequest;
	responseData: QosManagementIndexResponse;
	onSuccess?: () => void;
}

const clearInvalidValues = (
	source: QosManagementUpdateRequest
): QosManagementUpdateRequest => {
	return Object.keys(source).reduce((dest: { [key: string]: any }, curr) => {
		const val = get(source, curr);

		if (!isUndefined(val) && val !== -1) {
			dest[curr] = val;
		}

		return dest;
	}, {});
};

export const QosManagementBulkUpdateForm = ({
	requestData,
	responseData,
	onSuccess = () => {}
}: IQosManagementBulkUpdateFormProps): JSX.Element => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<RtError>();
	const [marginPlan, setMarginPlan] = useState<MarginPlanIndexResponse>();
	const [blockType, setBlockType] = useState<BlockTypeIndexResponse>();
	const [sqSelect, setSqSelect] = useState<Map<SqKeys, SqSelectValue>>(
		new Map()
	);

	const { control, handleSubmit } = useForm<QosManagementUpdateRequest>({
		defaultValues: {
			...requestData,
			test: -1,
			cli: -1,
			t38: -1
		}
	});

	const sqlSelectKeys = useMemo(
		() => [...sqSelect.keys()].sort((prev, curr) => prev.localeCompare(curr)),
		[sqSelect]
	);

	const onChangeSqlSelectHandler = (values: SqKeys[]) => {
		setSqSelect((currentState) => {
			const newMap = values.reduce<Map<SqKeys, SqSelectValue>>((dest, curr) => {
				const value = currentState.get(curr) || {
					label: curr.toUpperCase(),
					value: 0
				};

				dest.set(curr, value);
				return dest;
			}, new Map());

			return newMap;
		});
	};

	const onChangeSqlSliderHandler = (key: SqKeys, value: number) => {
		setSqSelect((currentState) => {
			const newMap = new Map(currentState);

			newMap.set(key, {
				label: key.toLocaleUpperCase(),
				value
			});

			return newMap;
		});
	};

	const onSubmitHandler = async (data: QosManagementUpdateRequest) => {
		const sqObject = Array.from(sqSelect).reduce(
			(dest: SqValues, [key, value]) => {
				dest[key] = value.value;

				return dest;
			},
			{}
		);

		const validatedData = clearInvalidValues(data);

		const request: QosManagementUpdateRequest = {
			...validatedData,
			...sqObject
		};

		setIsSubmitting(true);
		setError(undefined);

		try {
			const resource = new QosManagementResource();
			await resource.update(request);
			onSuccess();
		} catch (e: any) {
			setError(e);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Card bg="warning" className="mb-3">
				<Card.Body>
					Note: Anything set here will be applied to the intersection of&nbsp;
					<span className="text-decoration-underline text-primary">
						{responseData.subscriptionQty} Subscriptions
					</span>
					&nbsp; and&nbsp;
					<span className="text-decoration-underline text-primary">
						{responseData.rateCenterQty} Rate Centers
					</span>
					,&nbsp; for a total of{' '}
					{responseData.subscriptionQty + responseData.rateCenterQty} records
					being changed
				</Card.Body>
			</Card>
			<CollapsibleCard header="Search Criteria" closedByDefault>
				<QosManagementSearchForm value={requestData} displayMode />
			</CollapsibleCard>
			<RtUiForm
				onSubmit={handleSubmit(onSubmitHandler)}
				onCancel={onSuccess}
				error={error}
				isSubmitting={isSubmitting}
			>
				<Row>
					<Col md={8}>
						<Controller
							control={control}
							name="blockTypeId"
							render={({ field: { onChange, value } }) => (
								<BlockTypeSelect
									placeholder="Leave empty for no changes"
									label="Block Type"
									value={blockType}
									onChange={(bt: BlockTypeIndexResponse) => {
										setBlockType(bt);
										onChange(bt ? bt.blockTypeId : undefined);
									}}
									initialOptionId={value?.toString()}
								/>
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<Controller
							control={control}
							name="marginPlanId"
							render={({ field: { onChange, value } }) => (
								<MarginPlanSelect
									placeholder="Leave empty for no changes"
									label="Margin Plan"
									value={marginPlan}
									onChange={(mp: MarginPlanIndexResponse) => {
										setMarginPlan(mp);
										onChange(mp ? mp.marginPlanId : undefined);
									}}
									initialOptionId={value?.toString()}
								/>
							)}
						/>
					</Col>
				</Row>
				<MultipleCheckboxFormControl
					label="Change SQ level"
					options={[
						{ value: 'sq1', label: 'SQ1' },
						{ value: 'sq2', label: 'SQ2' },
						{ value: 'sq3', label: 'SQ3' },
						{ value: 'sq4', label: 'SQ4' },
						{ value: 'sq5', label: 'SQ5' },
						{ value: 'sq6', label: 'SQ6' },
						{ value: 'sq7', label: 'SQ7' },
						{ value: 'sq8', label: 'SQ8' },
						{ value: 'sq9', label: 'SQ9' }
					]}
					value={sqlSelectKeys}
					onChange={onChangeSqlSelectHandler}
				/>
				{sqSelect && (
					<Row className="m-0">
						<Col md={8}>
							{sqlSelectKeys.map((key) => {
								const values = sqSelect.get(key) as SqSelectValue;

								return (
									<SliderFormControl
										key={key}
										label={values.label}
										value={Math.round(values.value * 100)}
										onChange={(value) =>
											onChangeSqlSliderHandler(key, value / 100)
										}
									/>
								);
							})}
						</Col>
					</Row>
				)}
				<Row>
					<Col md={8}>
						<Controller
							control={control}
							name="cli"
							render={({ field: { onChange, value } }) => (
								<RadioFormControl<number>
									label="CLI"
									onChange={onChange}
									value={value}
									options={[
										{ value: 1, label: 'Yes' },
										{ value: 0, label: 'No' },
										{ value: -1, label: 'No Change' }
									]}
								/>
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<Controller
							control={control}
							name="t38"
							render={({ field: { onChange, value } }) => (
								<RadioFormControl<number>
									label="T38"
									onChange={onChange}
									value={value}
									options={[
										{ value: 1, label: 'Yes' },
										{ value: 0, label: 'No' },
										{ value: -1, label: 'No Change' }
									]}
								/>
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<Controller
							control={control}
							name="test"
							render={({ field: { onChange, value } }) => (
								<RadioFormControl<number>
									label="Test"
									onChange={onChange}
									value={value}
									options={[
										{ value: 1, label: 'Yes' },
										{ value: 0, label: 'No' },
										{ value: -1, label: 'No Change' }
									]}
								/>
							)}
						/>
					</Col>
				</Row>
			</RtUiForm>
		</>
	);
};
