import {
	ManagedRespOrgApiUpdateRequest,
	ManagedRespOrgCreateRequest,
	ManagedRespOrgIndexResponse,
	ManagedRespOrgProfileResponse,
	ManagedRespOrgRocUpdateRequest,
	ManagedRespOrgSqlUpdateRequest,
	ManagedRespOrgUpdateRequest,
	ManagedRespOrgProfileRequest,
	ManagedRespOrgMgiUpdateRequest,
	ManagedRespOrgPublicProfileResponse
} from 'RtModels';
import { ManagedResource } from 'RtUi/app/rtAdmin/lib/resources/ManagedResource';
import { RtAdmApiRoutes } from 'RtExports/routes';

export class ManagedRespOrgResource extends ManagedResource<
	ManagedRespOrgIndexResponse,
	ManagedRespOrgProfileResponse
> {
	constructor() {
		super(RtAdmApiRoutes.ManagedRespOrgs);
	}

	public getPublicProfile(urlParams: ManagedRespOrgProfileRequest) {
		return this.fetchWithRoute<ManagedRespOrgPublicProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgsPublic.Profile,
			{
				urlParams
			}
		);
	}

	public create(
		respOrgId: string,
		isActive: number,
		isManaged: number,
		isApi: number,
		contactName: string,
		contactNumber: string,
		apiResourceId: number | null
	) {
		const request: ManagedRespOrgCreateRequest = {
			respOrgId,
			isActive,
			isManaged,
			isApi,
			contactName,
			contactNumber,
			apiResourceId
		};
		const body = JSON.stringify(request);

		return this.fetchWithRoute<ManagedRespOrgProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgs.Create,
			{ body }
		);
	}

	public update(
		respOrgId: string,
		isActive: number,
		isManaged: number,
		isApi: number,
		contactName: string,
		contactNumber: string,
		apiResourceId: number | null
	) {
		const request: ManagedRespOrgUpdateRequest = {
			respOrgId,
			isActive,
			isManaged,
			isApi,
			contactName,
			contactNumber,
			apiResourceId
		};
		const body = JSON.stringify(request);
		const urlParams: ManagedRespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<ManagedRespOrgProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgs.Update,
			{ body, urlParams }
		);
	}

	public updateApi(
		respOrgId: string,
		apiUserName: string,
		apiPassword: string
	) {
		const request: ManagedRespOrgApiUpdateRequest = {
			apiUserName,
			apiPassword
		};
		const body = JSON.stringify(request);
		const urlParams: ManagedRespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<ManagedRespOrgProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgsApi.Update,
			{ body, urlParams }
		);
	}

	public deleteApi(respOrgId: string) {
		const urlParams: ManagedRespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<ManagedRespOrgProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgsApi.Delete,
			{ urlParams }
		);
	}

	public updateRoc(
		respOrgId: string,
		rocUserName: string,
		rocPassword: string
	) {
		const request: ManagedRespOrgRocUpdateRequest = {
			rocUserName,
			rocPassword
		};
		const body = JSON.stringify(request);
		const urlParams: ManagedRespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<ManagedRespOrgProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgsRoc.Update,
			{ body, urlParams }
		);
	}

	public deleteRoc(respOrgId: string) {
		const urlParams: ManagedRespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<ManagedRespOrgProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgsRoc.Delete,
			{ urlParams }
		);
	}

	public updateSql(
		respOrgId: string,
		sqlUserName: string,
		sqlPassword: string
	) {
		const request: ManagedRespOrgSqlUpdateRequest = {
			sqlUserName,
			sqlPassword
		};
		const body = JSON.stringify(request);
		const urlParams: ManagedRespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<ManagedRespOrgProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgsSql.Update,
			{ body, urlParams }
		);
	}

	public deleteSql(respOrgId: string) {
		const urlParams: ManagedRespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<ManagedRespOrgProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgsSql.Delete,
			{ urlParams }
		);
	}

	public updateMgi(respOrgId: string, mgiUserName: string) {
		const request: ManagedRespOrgMgiUpdateRequest = { mgiUserName };
		const body = JSON.stringify(request);
		const urlParams: ManagedRespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<ManagedRespOrgProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgsMgi.Update,
			{ body, urlParams }
		);
	}

	public deleteMgi(respOrgId: string) {
		const urlParams: ManagedRespOrgProfileRequest = { respOrgId };

		return this.fetchWithRoute<ManagedRespOrgProfileResponse>(
			RtAdmApiRoutes.ManagedRespOrgsMgi.Delete,
			{ urlParams }
		);
	}
}
