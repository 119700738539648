import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	RoutingRuleIndexRequest,
	RoutingRuleIndexResponse,
	RoutingRuleProfileRequest,
	RoutingRuleProfileResponse,
	RoutingRulesMatchTypesIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchRoutingRule = async (
	urlParams: RoutingRuleProfileRequest
): Promise<RoutingRuleProfileResponse | undefined> => {
	return handleGetRequest<RoutingRuleProfileResponse>(
		RtxSipApiRoutes.RoutingRules.Profile,
		{
			urlParams
		}
	);
};

export const useGetRoutingRule = (
	routingRuleId: number,
	enabled: boolean = true
) => {
	return useQuery<RoutingRuleProfileResponse | undefined, Error>(
		[`useGetRoutingRule$`, routingRuleId],
		() => fetchRoutingRule({ routingRuleId }),
		{ enabled }
	);
};

const fetchRoutingRules = async (
	queryParams?: RoutingRuleIndexRequest
): Promise<FullResponse<RoutingRuleIndexResponse[]>> => {
	return handleGetRequest<RoutingRuleIndexResponse[], true>(
		RtxSipApiRoutes.RoutingRules.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetRoutingRules = (queryParams?: RoutingRuleIndexRequest) => {
	return useQuery<FullResponse<RoutingRuleIndexResponse[]>, Error>(
		[`useGetRoutingRules`, queryParams],
		() => fetchRoutingRules(queryParams),
		{ enabled: true }
	);
};

const fetchRoutingRuleMatchTypes = async (): Promise<
	RoutingRulesMatchTypesIndexResponse[]
> => {
	return handleGetRequest<RoutingRulesMatchTypesIndexResponse[]>(
		RtxSipApiRoutes.RoutingRulesMatchTypes.Index
	);
};

export const useGetRoutingRuleMatchTypes = () => {
	return useQuery<RoutingRulesMatchTypesIndexResponse[], Error>(
		'useGetRoutingRulesMatchTypes',
		() => fetchRoutingRuleMatchTypes(),
		{ enabled: true }
	);
};
