import {
	SubscriptionScheduleProfileResponse,
	SubscriptionScheduleProfileRequest,
	SubscriptionScheduleUpdateRequest,
	SubscriptionScheduleCreateRequest
} from 'RtModels';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { RtxSipApiRoutes } from 'RtExports/routes';

export class SubscriptionScheduleHttp {
	public get(scheduleId: number): Promise<SubscriptionScheduleProfileResponse> {
		const urlParams: SubscriptionScheduleProfileRequest = { scheduleId };

		return HttpRequest.fetchWithRoute<SubscriptionScheduleProfileResponse>(
			RtxSipApiRoutes.SubscriptionSchedules.Profile,
			{ urlParams }
		);
	}

	public create(schedule: SubscriptionScheduleCreateRequest) {
		const body = JSON.stringify(schedule);

		return HttpRequest.fetchWithRoute<SubscriptionScheduleProfileResponse>(
			RtxSipApiRoutes.SubscriptionSchedules.Create,
			{ body }
		);
	}

	public update(
		scheduleId: number,
		schedule: SubscriptionScheduleUpdateRequest
	) {
		const urlParams: SubscriptionScheduleProfileRequest = { scheduleId };
		const body = JSON.stringify(schedule);

		return HttpRequest.fetchWithRoute<SubscriptionScheduleProfileResponse>(
			RtxSipApiRoutes.SubscriptionSchedules.Update,
			{ body, urlParams }
		);
	}

	public delete(scheduleId: number) {
		const urlParams: SubscriptionScheduleProfileRequest = { scheduleId };
		return HttpRequest.fetchWithRoute(
			RtxSipApiRoutes.SubscriptionSchedules.Delete,
			{
				urlParams
			}
		);
	}
}
