import {
	ISelectFormControlState,
	ISelectFormControlProps,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { FlowIndexResponse } from 'RtModels';
import { FlowResource } from 'RtUi/app/rtAdmin/Flows/lib/resources/FlowResource';
import { FlowRouter } from 'RtUi/app/rtAdmin/Flows/Flow.router';

interface IFlowSelectProps extends ISelectFormControlProps<FlowIndexResponse> {}
interface IFlowSelectState extends ISelectFormControlState<FlowIndexResponse> {}

export class FlowSelect extends SelectFormControl<
	FlowIndexResponse,
	false,
	IFlowSelectProps,
	IFlowSelectState
> {
	public static defaultProps = {
		router: FlowRouter
	};
	public resourceClass = FlowResource;
	public state: IFlowSelectState = {
		formLabel: 'Flow',
		valueKey: 'flowId',
		labelKey: 'label'
	};
}
