/* eslint-disable max-classes-per-file */
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { AosNodeType } from 'Somos/lib/SomosCpr/RtCprV2';

export interface IAreaOfServiceType {
	label: string;
	value: AosNodeType;
}

export const AreaOfServicesOptions: IAreaOfServiceType[] = [
	{ label: 'Area Codes', value: AosNodeType.AreaCode },
	{ label: 'Latas', value: AosNodeType.Lata },
	{ label: 'Network', value: AosNodeType.Network },
	{ label: 'States', value: AosNodeType.State }
];

export class AreaOfServiceResource extends ArrayResource<IAreaOfServiceType> {
	constructor() {
		super('value', AreaOfServicesOptions);
	}
}

interface IAreaOfServiceSelectProps
	extends ISelectFormControlProps<IAreaOfServiceType> {}

export class AreaOfServiceSelect extends SelectFormControl<
	IAreaOfServiceType,
	false,
	IAreaOfServiceSelectProps
> {
	public resourceClass = AreaOfServiceResource;
	public state: ISelectFormControlState<IAreaOfServiceType> = {
		formLabel: 'Area of Service Type',
		valueKey: 'value',
		labelKey: 'label'
	};
}
