import * as React from 'react';
import { Form } from 'react-bootstrap';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { Loading } from '../Loading';

interface IDragAndDropFileUploaderProps {
	accept?: string;
	onDrop: (file: File) => void;
	value: File | undefined;
	required?: boolean;
	processing?: boolean;
	customValidityError?: string;
	label?: string;
	sublabel?: string;
}

interface IDragAndDropFileUploaderState {
	isDraggingOver: boolean;
	isBadMimeType: boolean;
}

export class DragAndDropFileUploader extends React.Component<
	IDragAndDropFileUploaderProps,
	IDragAndDropFileUploaderState
> {
	public state: IDragAndDropFileUploaderState = {
		isDraggingOver: false,
		isBadMimeType: false
	};

	public fileUtils = new FileUtils();
	public inputElementRef = React.createRef<HTMLInputElement>();

	public componentDidMount() {
		this.updateCustomValidityError();
	}

	public componentDidUpdate() {
		this.updateCustomValidityError();
	}

	public updateCustomValidityError() {
		const { customValidityError = '' } = this.props;

		this.inputElementRef.current?.setCustomValidity(customValidityError);
	}

	public onDrop(ev: React.DragEvent<HTMLElement>) {
		ev.preventDefault();
		ev.stopPropagation();

		this.attemptToGrabFile(ev.dataTransfer.files);
	}

	public onFilePick(ev: React.ChangeEvent<HTMLInputElement>) {
		ev.preventDefault();
		ev.stopPropagation();

		this.attemptToGrabFile(ev.target.files);
	}

	public isAcceptedType(type: string | undefined) {
		if (!this.props.accept) {
			return true;
		}

		if (typeof type === 'undefined') {
			return false;
		}

		const acceptTypes = this.props.accept.split(',').map((a) => a.trim());

		return acceptTypes.some((acceptedType) => acceptedType === type);
	}

	public async attemptToGrabFile(fileList: FileList | null) {
		if (fileList === null) {
			return;
		}

		const files = Array.from(fileList);
		const acceptedFiles: File[] = [];

		for (const file of files) {
			const mimeType = await this.fileUtils.getMimeType(file);

			if (this.isAcceptedType(mimeType)) {
				acceptedFiles.push(file);
			}
		}

		if (acceptedFiles.length > 0) {
			const droppedFile = acceptedFiles[0];
			this.props.onDrop(droppedFile);
		}

		this.setState({ isDraggingOver: false, isBadMimeType: false });
	}

	public onDragEnter(ev: React.DragEvent<HTMLElement>) {
		ev.preventDefault();

		const hasAcceptedFile = Array.from(ev.dataTransfer.items).some((item) => {
			const isFileType = item.kind === 'file';
			const satisfiesAccept = this.isAcceptedType(item.type);

			return isFileType && satisfiesAccept;
		});

		this.setState({ isDraggingOver: true, isBadMimeType: !hasAcceptedFile });
	}

	public onDragEnd(ev: React.DragEvent<HTMLElement>) {
		ev.preventDefault();
		ev.stopPropagation();

		this.setState({ isDraggingOver: false, isBadMimeType: false });
	}

	public render() {
		const { value, processing = false, accept } = this.props;
		const { isDraggingOver, isBadMimeType } = this.state;
		const classNames = ['dnd-file-uploader'];
		const isDropSuccess = Boolean(value);

		if (isDraggingOver) {
			classNames.push('dragover');

			if (isBadMimeType) {
				classNames.push('dragover-danger');
			}
		} else if (isDropSuccess) {
			//cSpell:ignore dragsuccess
			classNames.push('dragsuccess');
		}

		return (
			<Form.Group className="mb-3">
				{this.props.label && (
					<Form.Label>
						{this.props.label}
						{this.props.sublabel && (
							<span className="text-muted">{this.props.sublabel}</span>
						)}
					</Form.Label>
				)}
				<section
					style={{ position: 'relative' }}
					className={classNames.join(' ')}
					onDragEnter={(ev) => this.onDragEnter(ev)}
					onDragOver={(ev) => this.onDragEnter(ev)}
					onDragEnd={(ev) => this.onDragEnd(ev)}
					onDragLeave={(ev) => this.onDragEnd(ev)}
					onDrop={(ev) => this.onDrop(ev)}
				>
					{processing ? (
						<>
							<Loading internal message="Processing File" />
						</>
					) : (
						<>
							{(!isDropSuccess || isDraggingOver) && (
								<>
									<span className="h2 me-3">
										<i className="fas fa-lg fa-cloud-upload-alt" />
									</span>
									{isBadMimeType && (
										<span className="text-center dnd-file-uploader-manual">
											File is not the correct format (Accepted Format: {accept})
										</span>
									)}
									{!isBadMimeType && (
										<span className="text-center dnd-file-uploader-manual">
											<span>
												Drag and Drop{' '}
												{this.props.label && (
													<span className="fw-bold">{this.props.label}</span>
												)}
												{!this.props.label && 'File'} Here <br /> or&nbsp;
											</span>
											<a
												style={{
													textDecoration: 'underline'
												}}
											>
												click here
											</a>
											<span>&nbsp;to browse.</span>
											<input
												type="file"
												accept={this.props.accept}
												onChange={(ev) => this.onFilePick(ev)}
											/>
										</span>
									)}
								</>
							)}
							{isDropSuccess && !isDraggingOver && value && (
								<section>
									<article className="h6">
										<i>{value.name}</i> was Uploaded!
									</article>
									<article className="dnd-file-uploader-manual mt-3">
										<a
											className="text-white"
											style={{
												textDecoration: 'underline '
											}}
										>
											Click Here to Select Another File
										</a>
										<input
											type="file"
											accept={this.props.accept}
											onChange={(ev) => this.onFilePick(ev)}
										/>
									</article>
								</section>
							)}
						</>
					)}
					<input
						ref={this.inputElementRef}
						type="text"
						tabIndex={-1}
						required={this.props.required}
						minLength={1}
						onChange={() => void 0}
						value={this.props.value ? '1' : ''}
						style={{
							height: 1,
							width: 0,
							opacity: 0.01,
							position: 'absolute',
							left: '50%',
							bottom: 0
						}}
					/>
				</section>
			</Form.Group>
		);
	}
}
