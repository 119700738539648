import { Row, Col } from 'react-bootstrap';
import { GuardianReconciliationMappingFiltersProfileResponse } from 'RtModels';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { GuardianReconciliationMappingFiltersRouter } from './GuardianReconciliationMappingFilter.router';
import { GuardianReconciliationMappingFilterResource } from './lib/resources/GuardianReconciliationMappingFilterResource';

interface IGuardianReconciliationMappingFilterProfileContainerProps {}

interface IGuardianReconciliationMappingFilterProfileContainerState {
	activeTab: string;
	profile?: GuardianReconciliationMappingFiltersProfileResponse;
}

@GuardianReconciliationMappingFiltersRouter.getProfileRtUiController()
export class GuardianReconciliationMappingFilterProfileContainer extends ProfileApplicationContainer<
	IGuardianReconciliationMappingFilterProfileContainerProps,
	IGuardianReconciliationMappingFilterProfileContainerState
> {
	public Tabs = GuardianReconciliationMappingFiltersRouter.getProfileTabs();

	public state: IGuardianReconciliationMappingFilterProfileContainerState = {
		activeTab: this.Tabs.Profile.header
	};

	public componentDidMount() {
		const profileId = this.getIdParam();
		const resource = new GuardianReconciliationMappingFilterResource();

		resource.get(profileId).then((profile) => this.setState({ profile }));
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}

		const canManage =
			GuardianReconciliationMappingFiltersRouter.canManageWith(profile);

		return (
			<TabbedLayout<GuardianReconciliationMappingFiltersProfileResponse>
				router={GuardianReconciliationMappingFiltersRouter}
				profile={profile}
				canManageFn={() => canManage}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<Row>
						<Col md>
							<InputFormControl
								label="Notes"
								displayMode
								value={profile.notes ?? ''}
							/>
						</Col>
						<Col md></Col>
					</Row>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
