import { Rt800Router } from 'RtUi/app/rt800/rt800.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { EntityResource } from './lib/resources/EntityResource';
import { EntityIndexResponse, EntityProfileResponse } from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { SearchConfidenceLevel } from 'RtUi/components/containers/lib/RtUiRouter';

interface IEntityContainerTabs extends IProfileApplicationContainerTabs {
	Dashboard: TabbedLayoutTabProps;
	Profile: TabbedLayoutTabProps;
	Numbers: TabbedLayoutTabProps;
	RespOrgs: TabbedLayoutTabProps;
	RoutingGroups: TabbedLayoutTabProps;
	Templates: TabbedLayoutTabProps;
}

export const EntityContainerTabs: IEntityContainerTabs = {
	Dashboard: {
		header: 'Dashboard'
	},
	Profile: {
		header: 'Profile'
	},
	Numbers: {
		header: 'Numbers'
	},
	RespOrgs: {
		header: 'RespOrgs'
	},
	Templates: {
		header: 'Templates',
		requireCanManage: true
	},
	RoutingGroups: {
		header: 'Routing Groups',
		requireCanManage: true
	}
};

export class EntityRouterClass extends Rt800Router<
	EntityIndexResponse,
	EntityProfileResponse,
	IEntityContainerTabs
> {
	constructor() {
		super('Entity', 'entities', 'entityId', EntityContainerTabs);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.Entities);
	}

	public globalSearch(search: string) {
		return this.defaultGlobalSearch(EntityResource, search, 2);
	}

	public globalSearchConfidenceCheck() {
		return SearchConfidenceLevel.Possible;
	}

	/**
	 * Override method to check for Entity access rather than RespOrg access
	 * @override
	 * @param record
	 */
	public canManageWith(record?: any) {
		if (!record) {
			return false;
		}

		return this.hasAccessToEntity(record);
	}

	public getIndexLabel(record: EntityIndexResponse) {
		let label = record.entityId;

		if (record.label) {
			label = `${record.label} (${record.entityId})`;
		}

		return label;
	}

	public getProfileLabel(profile: EntityProfileResponse) {
		return profile.label;
	}
}

export const EntityRouter = new EntityRouterClass();
