import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { DirectConnectProfileResponse } from 'RtModels';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { DirectConnectRouter } from 'RtUi/app/rtSip/DirectConnect/DirectConnect.router';
import { DirectConnectResource } from 'RtUi/app/rtSip/DirectConnect/lib/resources/DirectConnectResource';
import { DirectConnectFormEditor } from 'RtUi/app/rtSip/DirectConnect/lib/forms/DirectConnectFormEditor';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Col, Row, Card, Button } from 'react-bootstrap';

interface IDirectConnectContainerState {
	profile: DirectConnectProfileResponse | undefined;
	activeTab: string;
	isActivating: boolean;
	isResettingPassword: boolean;
}

@DirectConnectRouter.getProfileRtUiController()
export class DirectConnectProfileContainer extends ProfileApplicationContainer<
	{},
	IDirectConnectContainerState
> {
	public Tabs = DirectConnectRouter.getProfileTabs();

	public state: IDirectConnectContainerState = {
		profile: undefined,
		activeTab: this.Tabs.Profile.header,
		isActivating: false,
		isResettingPassword: false
	};

	private resource = new DirectConnectResource();

	public componentDidMount() {
		this.resource.get(this.getIdParam()).then((profile) => {
			this.setState({ profile });
		});
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return null;
		}

		return (
			<StandardLayout router={DirectConnectRouter} profile={profile}>
				<Row>
					<Col xs={{ order: 2 }} lg={{ span: 6, order: 1 }} className="mb-3">
						<DirectConnectFormEditor
							profile={profile}
							onSuccess={(profile) => this.setState({ profile })}
						/>
					</Col>
					<Col
						xs={{ order: 1 }}
						lg={{ span: 3, offset: 3, order: 2 }}
						className="mb-3"
					>
						<Card>
							<Card.Header>
								<h6>
									<b>Actions</b>
								</h6>
							</Card.Header>
							<Card.Body>
								<section>
									<Button
										variant="link"
										disabled={this.state.isActivating}
										onClick={() => {
											this.activate(profile);
										}}
									>
										{this.state.isActivating && (
											<i className="fa fa-fw fa-cog fa-spin" />
										)}
										{!this.state.isActivating && (
											<i className="fa fa-fw fa-check" />
										)}{' '}
										Activate
									</Button>
								</section>
								<section>
									<Button
										variant="link"
										disabled={this.state.isResettingPassword}
										onClick={() => {
											this.resetPassword(profile);
										}}
									>
										{this.state.isResettingPassword && (
											<i className="fa fa-fw fa-cog fa-spin" />
										)}
										{!this.state.isResettingPassword && (
											<i className="fa fa-fw fa-lock" />
										)}{' '}
										Reset Password
									</Button>
								</section>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</StandardLayout>
		);
	}

	private async activate(profile: DirectConnectProfileResponse) {
		const confirm = await Confirmation.create(
			<div>
				<p>Are you sure you would like to activate?</p>
			</div>
		);

		if (!confirm) {
			return;
		}

		this.setState({ isActivating: true });
		await this.resource.activate(profile.directConnectId);
		this.setState({ isActivating: false });
	}

	private async resetPassword(profile: DirectConnectProfileResponse) {
		const confirm = await Confirmation.create(
			<div>
				<p>
					A new SIP login password will be generated and displayed if you
					confirm this operation.
				</p>
				<p>Are you sure you would like to generate a new password?</p>
			</div>
		);

		if (!confirm) {
			return;
		}

		this.setState({ isResettingPassword: true });
		await this.resource.resetPassword(profile.directConnectId);
		this.setState({ isResettingPassword: false });
	}
}
