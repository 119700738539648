import { LcrTemplateIndexResponse, LcrTemplateProfileResponse } from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class LcrTemplatesResource extends HttpResource<
	LcrTemplateIndexResponse,
	LcrTemplateProfileResponse
> {
	constructor() {
		super(Rt800ApiRoutes.LcrTemplates);
	}
}
