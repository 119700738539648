import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

// exported definitions
// ======================================================================

export class CprColDate extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.Date;
	public readonly hasTimeZone = true;

	public readonly allowOther = true;
	public readonly valueLimit = 3;
	public readonly valueRegExp = /^(\d{2})\/(\d{2})|((\d{2})\/(\d{2})\-(\d{2})\/(\d{2}))$/;
	public readonly isTreeNode = true;

	/*
		mm/dd (no year should be entered)
		range may be returned in the form: mm/dd-mm/dd
	*/

	public isPossibleValue(rawVal: string) {
		// only validating by valueRegExp
		return true;
	}

}
