import { MonthlyTrendingIndexResponse } from 'RtModels';
import { MonthlyTrendingRouter } from 'RtUi/app/rtVue/MonthlyTrending/MonthlyTrending.router';
import { GraphsComponent } from 'RtUi/app/rtVue/MonthlyTrending/components/Graphs';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { TabLayoutTab } from 'RtUi/components/ui/TabbedLayout/TabLayoutContext';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

interface MonthlyTrendingReportsComponentProps {
	activeTab: TabLayoutTab;
	reportData: MonthlyTrendingIndexResponse[];
	reportingType: number;
	handleChangeTab: (value: string) => void;
	tabs: IProfileApplicationContainerTabs;
	isLoading: boolean;
	mouColumns: Array<DataGridColumn<MonthlyTrendingIndexResponse>>;
	costColumns: Array<DataGridColumn<MonthlyTrendingIndexResponse>>;
	cpmColumns: Array<DataGridColumn<MonthlyTrendingIndexResponse>>;
	cdrType: string;
	networkActiveTab: TabLayoutTab;
	networkTabs: IProfileApplicationContainerTabs;
	handleChangeNetworkTab: (value: string) => void;
	asrColumns: Array<DataGridColumn<MonthlyTrendingIndexResponse>>;
	alocColumns: Array<DataGridColumn<MonthlyTrendingIndexResponse>>;
	dnoColumns: Array<DataGridColumn<MonthlyTrendingIndexResponse>>;
	offnetTabs: IProfileApplicationContainerTabs;
	activeOffnetTabs: TabLayoutTab;
	graphType: string;
	setGraphType: (value: string) => void;
}

export const MonthlyTrendingReportsComponent = (
	props: MonthlyTrendingReportsComponentProps
) => {
	const {
		activeTab,
		reportData,
		reportingType,
		handleChangeTab,
		tabs,
		isLoading,
		mouColumns,
		costColumns,
		cpmColumns,
		cdrType,
		networkActiveTab,
		networkTabs,
		handleChangeNetworkTab,
		asrColumns,
		alocColumns,
		dnoColumns,
		graphType,
		setGraphType
	} = props;

	const customerCondition = cdrType === 'customer' || cdrType === 'vendor';

	const [filter, setFilter] = useState('');
	const [filteredData, setFilteredData] = useState(reportData);

	const filterReportData = useCallback(
		(value: string) => {
			const valueFiltered = reportData?.filter((val) => {
				return Object.values(val).some((a) =>
					String(a).toLowerCase().includes(value.toLowerCase())
				);
			});
			setFilteredData(valueFiltered);
		},
		[reportData]
	);

	useEffect(() => {
		if (filter) {
			filterReportData(filter);
		} else {
			setFilteredData(reportData);
		}
	}, [filter, filterReportData, reportData]);

	return (
		<>
			{reportingType === 0 && customerCondition && (
				<>
					<TabbedLayout
						router={MonthlyTrendingRouter}
						activeTab={activeTab.header}
						onTabChange={(value) => handleChangeTab(value)}
						noBreadCrumbs={true}
					>
						<>
							<TabbedLayoutTab {...tabs.mou}>
								{!isLoading && !isEmpty(reportData) && reportData && (
									<div style={{ marginBottom: '50px' }}>
										<CollapsibleCard header="Graph">
											<GraphsComponent
												graphType={graphType}
												setGraphType={setGraphType}
												reportData={reportData}
											/>
										</CollapsibleCard>
									</div>
								)}

								<DataGrid<MonthlyTrendingIndexResponse>
									data={filteredData}
									loading={isLoading}
									columns={mouColumns}
									pageName="reports-monthlyTrend-mou"
									manualFilter={filter}
									hasManualFiltering={true}
									setManualFilter={setFilter}
								/>
							</TabbedLayoutTab>
							{customerCondition && (
								<>
									<TabbedLayoutTab {...tabs.revenue}>
										{!isLoading && !isEmpty(reportData) && reportData && (
											<div style={{ marginBottom: '50px' }}>
												<CollapsibleCard header="Graph">
													<GraphsComponent
														graphType={graphType}
														setGraphType={setGraphType}
														reportData={reportData}
													/>
												</CollapsibleCard>
											</div>
										)}

										<DataGrid<MonthlyTrendingIndexResponse>
											data={filteredData}
											loading={isLoading}
											columns={costColumns}
											pageName="reports-monthlyTrend-revenue"
											manualFilter={filter}
											hasManualFiltering={true}
											setManualFilter={setFilter}
										/>
									</TabbedLayoutTab>
								</>
							)}
							<TabbedLayoutTab {...tabs.cost}>
								{' '}
								{!isLoading && !isEmpty(reportData) && reportData && (
									<div style={{ marginBottom: '50px' }}>
										<CollapsibleCard header="Graph">
											<GraphsComponent
												graphType={graphType}
												setGraphType={setGraphType}
												reportData={reportData}
											/>
										</CollapsibleCard>
									</div>
								)}
								<DataGrid<MonthlyTrendingIndexResponse>
									data={filteredData}
									loading={isLoading}
									columns={costColumns}
									pageName="reports-monthlyTrend-cost"
									manualFilter={filter}
									hasManualFiltering={true}
									setManualFilter={setFilter}
								/>
							</TabbedLayoutTab>
							{customerCondition && (
								<>
									<TabbedLayoutTab {...tabs.margin}>
										{!isLoading && !isEmpty(reportData) && reportData && (
											<div style={{ marginBottom: '50px' }}>
												<CollapsibleCard header="Graph">
													<GraphsComponent
														graphType={graphType}
														setGraphType={setGraphType}
														reportData={reportData}
													/>
												</CollapsibleCard>
											</div>
										)}

										<DataGrid<MonthlyTrendingIndexResponse>
											data={filteredData}
											loading={isLoading}
											columns={costColumns}
											pageName="reports-monthlyTrend-margin"
											manualFilter={filter}
											hasManualFiltering={true}
											setManualFilter={setFilter}
										/>
									</TabbedLayoutTab>
									<TabbedLayoutTab {...tabs.rpm}>
										{!isLoading && !isEmpty(reportData) && reportData && (
											<div style={{ marginBottom: '50px' }}>
												<CollapsibleCard header="Graph">
													<GraphsComponent
														graphType={graphType}
														setGraphType={setGraphType}
														reportData={reportData}
													/>
												</CollapsibleCard>
											</div>
										)}

										<DataGrid<MonthlyTrendingIndexResponse>
											data={filteredData}
											loading={isLoading}
											columns={cpmColumns}
											pageName="reports-monthlyTrend-rpm"
											manualFilter={filter}
											hasManualFiltering={true}
											setManualFilter={setFilter}
										/>
									</TabbedLayoutTab>
								</>
							)}
							<TabbedLayoutTab {...tabs.cpm}>
								{' '}
								{!isLoading && !isEmpty(reportData) && reportData && (
									<div style={{ marginBottom: '50px' }}>
										<CollapsibleCard header="Graph">
											<GraphsComponent
												graphType={graphType}
												setGraphType={setGraphType}
												reportData={reportData}
											/>
										</CollapsibleCard>
									</div>
								)}
								<DataGrid<MonthlyTrendingIndexResponse>
									data={filteredData}
									loading={isLoading}
									columns={cpmColumns}
									pageName="reports-monthlyTrend-cpm"
									manualFilter={filter}
									hasManualFiltering={true}
									setManualFilter={setFilter}
								/>
							</TabbedLayoutTab>
							{customerCondition && (
								<TabbedLayoutTab {...tabs.mpm}>
									{!isLoading && !isEmpty(reportData) && reportData && (
										<div style={{ marginBottom: '50px' }}>
											<CollapsibleCard header="Graph">
												<GraphsComponent
													graphType={graphType}
													setGraphType={setGraphType}
													reportData={reportData}
												/>
											</CollapsibleCard>
										</div>
									)}
									<DataGrid<MonthlyTrendingIndexResponse>
										data={filteredData}
										loading={isLoading}
										columns={cpmColumns}
										pageName="reports-monthlyTrend-mpm"
										manualFilter={filter}
										hasManualFiltering={true}
										setManualFilter={setFilter}
									/>
								</TabbedLayoutTab>
							)}
						</>
					</TabbedLayout>
				</>
			)}{' '}
			{reportingType === 1 && (
				<TabbedLayout
					router={MonthlyTrendingRouter}
					activeTab={networkActiveTab.header}
					onTabChange={handleChangeNetworkTab}
					noBreadCrumbs={true}
				>
					<>
						<TabbedLayoutTab {...networkTabs.asr}>
							{!isLoading && !isEmpty(reportData) && reportData && (
								<div style={{ marginBottom: '50px' }}>
									<CollapsibleCard header="Graph">
										<GraphsComponent
											graphType={graphType}
											setGraphType={setGraphType}
											reportData={reportData}
										/>
									</CollapsibleCard>
								</div>
							)}

							<DataGrid<MonthlyTrendingIndexResponse>
								data={filteredData}
								loading={isLoading}
								columns={asrColumns}
								pageName="reports-monthlyTrend-asr"
								manualFilter={filter}
								hasManualFiltering={true}
								setManualFilter={setFilter}
							/>
						</TabbedLayoutTab>

						<TabbedLayoutTab {...networkTabs.aloc}>
							{!isLoading && !isEmpty(reportData) && reportData && (
								<div style={{ marginBottom: '50px' }}>
									<CollapsibleCard header="Graph">
										<GraphsComponent
											graphType={graphType}
											setGraphType={setGraphType}
											reportData={reportData}
										/>
									</CollapsibleCard>
								</div>
							)}

							<DataGrid<MonthlyTrendingIndexResponse>
								data={filteredData}
								loading={isLoading}
								columns={alocColumns}
								pageName="reports-monthlyTrend-aloc"
								manualFilter={filter}
								hasManualFiltering={true}
								setManualFilter={setFilter}
							/>
						</TabbedLayoutTab>

						<TabbedLayoutTab {...networkTabs.pdd}>
							{' '}
							{!isLoading && !isEmpty(reportData) && reportData && (
								<div style={{ marginBottom: '50px' }}>
									<CollapsibleCard header="Graph">
										<GraphsComponent
											graphType={graphType}
											setGraphType={setGraphType}
											reportData={reportData}
										/>
									</CollapsibleCard>
								</div>
							)}
							<DataGrid<MonthlyTrendingIndexResponse>
								data={filteredData}
								loading={isLoading}
								columns={alocColumns}
								pageName="reports-monthlyTrend-pdd"
								manualFilter={filter}
								hasManualFiltering={true}
								setManualFilter={setFilter}
							/>
						</TabbedLayoutTab>

						<TabbedLayoutTab {...networkTabs.roboscore}>
							{!isLoading && !isEmpty(reportData) && reportData && (
								<div style={{ marginBottom: '50px' }}>
									<CollapsibleCard header="Graph">
										<GraphsComponent
											graphType={graphType}
											setGraphType={setGraphType}
											reportData={reportData}
										/>
									</CollapsibleCard>
								</div>
							)}

							<DataGrid<MonthlyTrendingIndexResponse>
								data={filteredData}
								loading={isLoading}
								columns={alocColumns}
								pageName="reports-monthlyTrend-roboscore"
								manualFilter={filter}
								hasManualFiltering={true}
								setManualFilter={setFilter}
							/>
						</TabbedLayoutTab>

						<TabbedLayoutTab {...networkTabs.countDno}>
							{!isLoading && !isEmpty(reportData) && reportData && (
								<div style={{ marginBottom: '50px' }}>
									<CollapsibleCard header="Graph">
										<GraphsComponent
											graphType={graphType}
											setGraphType={setGraphType}
											reportData={reportData}
										/>
									</CollapsibleCard>
								</div>
							)}

							<DataGrid<MonthlyTrendingIndexResponse>
								data={filteredData}
								loading={isLoading}
								columns={dnoColumns}
								pageName="reports-monthlyTrend-dno"
								manualFilter={filter}
								hasManualFiltering={true}
								setManualFilter={setFilter}
							/>
						</TabbedLayoutTab>

						<TabbedLayoutTab {...networkTabs.uniqueAnis}>
							{' '}
							{!isLoading && !isEmpty(reportData) && reportData && (
								<div style={{ marginBottom: '50px' }}>
									<CollapsibleCard header="Graph">
										<GraphsComponent
											graphType={graphType}
											setGraphType={setGraphType}
											reportData={reportData}
										/>
									</CollapsibleCard>
								</div>
							)}
							<DataGrid<MonthlyTrendingIndexResponse>
								data={filteredData}
								loading={isLoading}
								columns={dnoColumns}
								pageName="reports-monthlyTrend-uniqueAnis"
								manualFilter={filter}
								hasManualFiltering={true}
								setManualFilter={setFilter}
							/>
						</TabbedLayoutTab>
					</>
				</TabbedLayout>
			)}
			{reportingType === 0 && !customerCondition && (
				<TabbedLayout
					router={MonthlyTrendingRouter}
					activeTab={activeTab.header}
					onTabChange={handleChangeTab}
					noBreadCrumbs={true}
				>
					<TabbedLayoutTab {...tabs.mou}>
						{!isLoading && !isEmpty(reportData) && reportData && (
							<div style={{ marginBottom: '50px' }}>
								<CollapsibleCard header="Graph">
									<GraphsComponent
										graphType={graphType}
										setGraphType={setGraphType}
										reportData={reportData}
									/>
								</CollapsibleCard>
							</div>
						)}

						<DataGrid<MonthlyTrendingIndexResponse>
							data={filteredData}
							loading={isLoading}
							columns={mouColumns}
							pageName="reports-monthlyTrend-mou"
							manualFilter={filter}
							hasManualFiltering={true}
							setManualFilter={setFilter}
						/>
					</TabbedLayoutTab>
					<TabbedLayoutTab {...tabs.cost}>
						{' '}
						{!isLoading && !isEmpty(reportData) && reportData && (
							<div style={{ marginBottom: '50px' }}>
								<CollapsibleCard header="Graph">
									<GraphsComponent
										graphType={graphType}
										setGraphType={setGraphType}
										reportData={reportData}
									/>
								</CollapsibleCard>
							</div>
						)}
						<DataGrid<MonthlyTrendingIndexResponse>
							data={filteredData}
							loading={isLoading}
							columns={costColumns}
							pageName="reports-monthlyTrend-cost"
							manualFilter={filter}
							hasManualFiltering={true}
							setManualFilter={setFilter}
						/>
					</TabbedLayoutTab>
					<TabbedLayoutTab {...tabs.cpm}>
						{' '}
						{!isLoading && !isEmpty(reportData) && reportData && (
							<div style={{ marginBottom: '50px' }}>
								<CollapsibleCard header="Graph">
									<GraphsComponent
										graphType={graphType}
										setGraphType={setGraphType}
										reportData={reportData}
									/>
								</CollapsibleCard>
							</div>
						)}
						<DataGrid<MonthlyTrendingIndexResponse>
							data={filteredData}
							loading={isLoading}
							columns={cpmColumns}
							pageName="reports-monthlyTrend-cpm"
							manualFilter={filter}
							hasManualFiltering={true}
							setManualFilter={setFilter}
						/>
					</TabbedLayoutTab>
				</TabbedLayout>
			)}
		</>
	);
};
