import { TollFreeStatusIndexResponse } from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export interface ITollFreeStatusIndex extends TollFreeStatusIndexResponse {}

export class TollFreeStatusResource extends ArrayResource<ITollFreeStatusIndex> {
	constructor() {
		super('tfnStatusId');

		this.setApiRouteForGetAll(Rt800ApiRoutes.TollFreeStatus.Index);
	}
}
