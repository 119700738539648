/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TaskUpdateRequest = {
    properties: {
        scheduledAt: {
            type: 'Date',
            format: 'date-time',
        },
        priority: {
            type: 'number',
        },
        processedAt: {
            type: 'Date',
            format: 'date-time',
        },
        completedAt: {
            type: 'Date',
            format: 'date-time',
        },
    },
};