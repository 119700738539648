import { FC } from 'react';
import { Accordion, Table } from 'react-bootstrap';

interface IRatingSimulatorResultsProps {
	data: Record<string, any>;
	ignoreProperties?: string[];
}

const renderTableRow = (dataToRender: any, ignoreProperties: string[] = []) => {
	if (!Boolean(dataToRender)) {
		return null;
	}

	return Object.keys(dataToRender).map((resultKey) => {
		if (ignoreProperties.includes(resultKey)) {
			return null;
		}

		if (Array.isArray(dataToRender[resultKey])) {
			const value =
				dataToRender[resultKey].length === 0
					? '[]'
					: dataToRender[resultKey].join(', ');
			return (
				<tr key={resultKey}>
					<td>{resultKey}</td>
					<td>{value}</td>
				</tr>
			);
		}
		if (typeof dataToRender[resultKey] === 'object') {
			return (
				<tr className="is-nested" id={resultKey} key={resultKey}>
					<td colSpan={10} className="px-0">
						<Accordion flush className="accordion-nested">
							<Accordion.Item eventKey={resultKey}>
								<Accordion.Button
									as="span"
									className="d-flex justify-content-start align-items-center"
								>
									<span>{resultKey}</span>
								</Accordion.Button>
								<Accordion.Collapse eventKey={resultKey}>
									<div className="px-3">
										<Table className="w-100">
											{renderTableRow(dataToRender[resultKey])}
										</Table>
									</div>
								</Accordion.Collapse>
							</Accordion.Item>
						</Accordion>
					</td>
				</tr>
			);
		}
		return (
			<tr key={resultKey}>
				<td>{resultKey}</td>
				<td>{dataToRender[resultKey]}</td>
			</tr>
		);
	});
};

export const NestedTable: FC<
	React.PropsWithChildren<IRatingSimulatorResultsProps>
> = ({ data, ignoreProperties }) => {
	return (
		<Table striped>
			<tbody>{renderTableRow(data, ignoreProperties)}</tbody>
		</Table>
	);
};
