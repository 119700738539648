import {
	EntityIndexResponse,
	RespOrgIndexResponse,
	RocActionItem,
	RocIndexRequest,
	RocNumbersIndexRequest
} from 'RtModels';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import { TollFreeNumbersTextArea } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumbersTextArea';
import { CreateLetterOfAgency } from 'RtUi/app/rt800/RespOrgChange/lib/components/CreateLetterOfAgency';
import { RocNumbersDataGrid } from 'RtUi/app/rt800/RespOrgChange/lib/grids/RocNumbersGrid';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { useGetUserDefaults } from 'RtUi/components/hooks/useGetUserDefaults';
import { RtxUiForm } from 'RtUi/components/rtx/form';
import { RtxUiSearchFormComponent } from 'RtUi/components/rtx/form/RtxUiSearchForm';
import { useFormInitializer } from 'RtUi/components/rtx/form/hooks/useFormInitializer';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { startOfDay, subDays } from 'date-fns';
import { isEqual } from 'lodash-es';
import * as moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RespOrgSelect } from '../RespOrgs/lib/controls/RespOrgSelect';
import { RocRequestRouter } from './RocRequest.router';
import { SubmitRocForm } from './lib/components/SubmitRocForm';
import { RocActionsSelect } from './lib/controls/RocActionsSelect';
import { RocStatusSelect, RocStatusType } from './lib/controls/RocStatusSelect';
import { RocRequestsGrid } from './lib/grids/RocRequestsGrid';

export const RocRequestIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true },
		CreateLetterOfAgency: {
			header: 'Create Letter of Agency',
			isAction: true
		},
		SubmitROC: {
			header: 'Submit ROC',
			isAction: true
		}
	});
	const navigate = useNavigate();
	const { entityId } = useGetUserDefaults();
	const formInitializer = useFormInitializer();
	const [entity, setEntity] = useState<EntityIndexResponse>();
	const [respOrg, setRespOrg] = useState<RespOrgIndexResponse>();
	const [rocStatus, setRocStatus] = useState<RocStatusType>();
	const [rocReject, setRocReject] = useState<RocActionItem>();
	const [resourceParams, setResourceParams] = useState<RocIndexRequest>();
	const [numbersResourceParams, setNumbersResourceParams] =
		useState<RocNumbersIndexRequest>();

	useEffect(() => {
		if (entityId) {
			setResourceParams({
				progressId: 2,
				rocRequestTypeId: -1,
				entityId,
				startTs: startOfDay(subDays(new Date(), 30))
			});
		}
	}, [entityId]);

	const parseResourceParams = (
		data: Omit<RocIndexRequest, 'startTs' | 'endTs'>,
		startTs?: Date,
		endTs?: Date
	): RocIndexRequest => {
		return {
			...(startTs && { startTs: new Date(startTs) }),
			...(endTs && { endTs: new Date(endTs) }),
			...data
		};
	};

	const submitHandler = ({ startTs, endTs, ...data }: RocIndexRequest) => {
		setNumbersResourceParams(undefined);
		setResourceParams(parseResourceParams(data, startTs, endTs));
		setActiveTab(tabs.Results.header);
	};

	const submitNumbersHandler = (data: RocNumbersIndexRequest) => {
		setNumbersResourceParams(data);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={RocRequestRouter}
			activeTab={activeTab.header}
			onTabChange={(activeTab) => setActiveTab(activeTab)}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtxUiSearchFormComponent<RocIndexRequest>
					initializer={formInitializer}
					defaultValues={resourceParams}
					onSubmit={submitHandler}
					onLoadUrlParams={({ startTs, endTs, ...urlParams }) => {
						const parsedParams = parseResourceParams(urlParams, startTs, endTs);

						if (!isEqual(parsedParams, resourceParams)) {
							submitHandler(parsedParams);
						}
					}}
				>
					{({ control, setValue }) => (
						<Row>
							<Col lg={6}>
								<Controller
									control={control}
									name="rocTxnId"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											label="ROC Transaction Id"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
								<Controller
									control={control}
									name="entityId"
									render={({ field: { onChange, value } }) => (
										<EntitySelect
											onChange={(entity) => {
												if (entity) {
													setRespOrg(undefined);
													setValue('respOrgId', undefined);
												}
												setEntity(entity);
												onChange(entity?.entityId);
											}}
											value={entity}
											initialOptionId={respOrg ? undefined : value}
											required={!respOrg}
										/>
									)}
								/>
								<Controller
									control={control}
									name="respOrgId"
									render={({ field: { onChange, value } }) => (
										<RespOrgSelect
											onChange={(respOrg) => {
												if (respOrg) {
													setEntity(undefined);
													setValue('entityId', undefined);
												}
												setRespOrg(respOrg);
												onChange(respOrg?.respOrgId);
											}}
											value={respOrg}
											initialOptionId={entity ? undefined : value}
											required={!entity}
										/>
									)}
								/>
								<Controller
									control={control}
									name="statusId"
									render={({ field: { onChange, value } }) => (
										<RocStatusSelect
											onChange={(rocStatus) => {
												setRocStatus(rocStatus);
												onChange(rocStatus.status);
											}}
											value={rocStatus}
											initialOptionId={String(value ?? '')}
										/>
									)}
								/>
								<Controller
									control={control}
									name="rocRequestTypeId"
									render={({ field: { onChange, value } }) => (
										<RadioFormControl<number>
											label="Type"
											onChange={onChange}
											value={value}
											options={[
												{ value: 0, label: 'Incoming' },
												{ value: 1, label: 'Outgoing' },
												{ value: -1, label: 'Both' }
											]}
										/>
									)}
								/>
								<Controller
									control={control}
									name="progressId"
									render={({ field: { onChange, value } }) => (
										<RadioFormControl<number>
											label="Progress"
											onChange={onChange}
											value={value}
											options={[
												{ value: 0, label: 'Open' },
												{ value: 1, label: 'Closed' },
												{ value: 2, label: 'Both' }
											]}
										/>
									)}
								/>
							</Col>
							<Col>
								<Controller
									control={control}
									name="tfn"
									render={({ field: { onChange, value } }) => (
										<TollFreeNumberFormControl
											onChange={(tfn) => onChange(tfn.replace(/\-/g, ''))}
											value={value}
										/>
									)}
								/>
								<Controller
									control={control}
									name="rejectReasonId"
									render={({ field: { onChange, value } }) => (
										<RocActionsSelect
											label="Rejection Status"
											onlyRejection
											onChange={(rocReject) => {
												setRocReject(rocReject);
												onChange(rocReject.code);
											}}
											value={rocReject}
											initialOptionId={String(value ?? '')}
										/>
									)}
								/>
								<Controller
									control={control}
									name="startTs"
									render={({ field: { onChange, value } }) => (
										<DatetimeFormControl
											label="Start Time (Submitted)"
											disableNowPreset
											onChange={(submittedStartTime) =>
												onChange(submittedStartTime?.toDate())
											}
											value={moment(value)}
										/>
									)}
								/>
								<Controller
									control={control}
									name="endTs"
									render={({ field: { onChange, value } }) => (
										<DatetimeFormControl
											label="End Time (Submitted)"
											disableNowPreset
											onChange={(submittedEndTime) =>
												onChange(submittedEndTime?.toDate())
											}
											value={value && moment(value)}
										/>
									)}
								/>
							</Col>
						</Row>
					)}
				</RtxUiSearchFormComponent>
				<RtxUiForm<RocNumbersIndexRequest> onSubmit={submitNumbersHandler}>
					{({ control }) => (
						<>
							<Alert variant="info" className="alert-sm mb-2">
								This search used cached data that is refreshed periodically.
								Your results will show data that may be a few minutes out of
								date
							</Alert>
							<Controller
								control={control}
								name="tfns"
								render={({ field: { onChange, value } }) => (
									<TollFreeNumbersTextArea
										value={value}
										onChange={onChange}
										allowForUpload
										maxLimit={1000}
										showOnlyTfnExamples
										required
									/>
								)}
							/>
							<Controller
								control={control}
								name="lastRocOnly"
								render={({ field: { onChange, value } }) => (
									<CheckboxFormControl
										label="Only Show latest status for each Toll Free"
										value={Boolean(value)}
										onChange={(value) => onChange(Number(value))}
									/>
								)}
							/>
						</>
					)}
				</RtxUiForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				{resourceParams && (
					<>
						{numbersResourceParams ? (
							<RocNumbersDataGrid resourceParams={numbersResourceParams} />
						) : (
							<RocRequestsGrid resourceParams={resourceParams} />
						)}
					</>
				)}
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateLetterOfAgency}>
				<CreateLetterOfAgency />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.SubmitROC}>
				<SubmitRocForm
					onSuccess={(rocTxnId) =>
						navigate(RocRequestRouter.getProfileRoute(rocTxnId))
					}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutHelpContainer tab={tabs.Search.header}>
				<p>Find an open port request.</p>
			</TabbedLayoutHelpContainer>
			<TabbedLayoutHelpContainer tab={tabs.Results.header}>
				<p>List of port requests that are active.</p>
			</TabbedLayoutHelpContainer>
			<TabbedLayoutHelpContainer tab={tabs.CreateLetterOfAgency.header}>
				<p>
					Create a Letter of Agency that must be signed to be submitted for a
					port in.
				</p>
			</TabbedLayoutHelpContainer>
		</TabbedLayout>
	);
};

RocRequestRouter.setIndexRtUiFunctionalComponent(RocRequestIndexContainer, {
	description: 'Manage number porting requests.'
});
