/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum FirebaseMessageType {
    CacheRefresh = 1,
    DataUpdate = 2,
    UserAlert = 3,
    BannerAlert = 4,
}