/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CallflowDirectionId {
    Inbound = 1,
    Outbound = 2,
    Unknown = 3,
}