import moment from 'moment';
import { FC, FormEvent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
	GuardianReconciliationProfileResponse,
	ReconciliationType,
	SubscriptionIndexResponse,
	ServiceIndexResponse
} from 'RtModels';
import { ServiceSelect } from 'RtUi/app/rtSip/Services/lib/controls/ServiceSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { GuardianReconciliationResource } from 'RtUi/app/rtVue/GuardianReconciliation/lib/resources/GuardianReconciliationResource';
import { ReconciliationTypeMap } from 'RtUi/app/rtVue/GuardianReconciliation/lib/utils';
import { CurrencyInputFormControl } from 'RtUi/components/form/CurrencyInputFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IGuardianReconciliationEditorProps {
	editMode?: GuardianReconciliationProfileResponse;
	createMode?: boolean;
	onUpdate?: (newProfile: GuardianReconciliationProfileResponse) => void;
}

export const GuardianReconciliationEditor: FC<
	React.PropsWithChildren<IGuardianReconciliationEditorProps>
> = ({ editMode, createMode, onUpdate = () => ({}) }) => {
	const guardianReconciliationResource = new GuardianReconciliationResource();
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [service, setService] = useState<ServiceIndexResponse>();
	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		editMode,
		createMode,
		createNew: () => guardianReconciliationResource.createNewProfile()
	});
	const { workingCopy } = rtUiFormState;

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			let newProfile: GuardianReconciliationProfileResponse;

			if (createMode) {
				newProfile = await guardianReconciliationResource.create(workingCopy);
			} else {
				newProfile = await guardianReconciliationResource.update(
					workingCopy.guardianReconciliationId,
					workingCopy
				);
			}

			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setDisplayMode(true);

			onUpdate(newProfile);
		} catch (err) {
			rtUiFormMethods.setError(err);
			rtUiFormMethods.setIsSubmitting(false);
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			onSubmit={onSubmit}
			onChange={rtUiFormMethods.setDisplayMode}
			onCancel={() => {
				setSubscription(undefined);
				setService(undefined);
				rtUiFormMethods.onCancel();
			}}
		>
			<Row>
				<Col md={6}>
					<InputFormControl
						label="Label"
						autoHeight
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.summary ?? ''}
						onChange={(summary) => {
							rtUiFormMethods.setWorkingCopy({ summary });
						}}
					/>
				</Col>
				<Col md={6}>
					<RadioFormControl<ReconciliationType>
						label="Type"
						vertical
						displayMode={rtUiFormState.displayMode}
						options={[
							{
								value: ReconciliationType.Charge,
								label: ReconciliationTypeMap.get(ReconciliationType.Charge)
							},
							{
								value: ReconciliationType.SwitchCdr,
								label: ReconciliationTypeMap.get(ReconciliationType.SwitchCdr)
							},
							{
								value: ReconciliationType.VendorCdr,
								label: ReconciliationTypeMap.get(ReconciliationType.VendorCdr)
							}
						]}
						value={workingCopy.reconciliationType ?? undefined}
						onChange={(reconciliationType) =>
							rtUiFormMethods.setWorkingCopy({ reconciliationType })
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<SubscriptionSelect
						displayMode={rtUiFormState.displayMode}
						onChange={(newSub) => {
							rtUiFormMethods.setWorkingCopy({
								subscriptionId: newSub.subscriptionId
							});
							setSubscription(newSub);
						}}
						value={subscription}
						initialOptionId={workingCopy.subscriptionId}
					/>
				</Col>
				<Col md={6}>
					<ServiceSelect
						displayMode={rtUiFormState.displayMode}
						onChange={(newService) => {
							rtUiFormMethods.setWorkingCopy({
								serviceId: newService.serviceId
							});
							setService(newService);
						}}
						value={service}
						initialOptionId={workingCopy.serviceId.toString()}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<InputFormControl
						label="Account BAN Number"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.carrierBanNumber ?? ''}
						onChange={(carrierBanNumber) => {
							rtUiFormMethods.setWorkingCopy({ carrierBanNumber });
						}}
					/>
				</Col>
				<Col md={6}>
					<InputFormControl
						label="Account Invoice Id"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.carrierInvoiceId ?? ''}
						onChange={(carrierInvoiceId) => {
							rtUiFormMethods.setWorkingCopy({ carrierInvoiceId });
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<DatetimeFormControl
						label="Service Start Date"
						disablePresets
						displayMode={rtUiFormState.displayMode}
						displayModeTimezone="UTC"
						onChange={(start) => {
							rtUiFormMethods.setWorkingCopy({ startTs: start?.toDate() });
						}}
						value={
							createMode
								? moment().subtract(1, 'months').startOf('month')
								: moment(workingCopy.startTs)
						}
					/>
				</Col>
				<Col md={6}>
					<DatetimeFormControl
						label="Service End Date"
						disablePresets
						displayMode={rtUiFormState.displayMode}
						displayModeTimezone="UTC"
						onChange={(end) => {
							rtUiFormMethods.setWorkingCopy({ endTs: end?.toDate() });
						}}
						value={
							createMode
								? moment().subtract(1, 'months').endOf('month')
								: moment(workingCopy.endTs)
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<DatetimeFormControl
						label="Follow Up Date"
						disablePresets
						displayMode={rtUiFormState.displayMode}
						onChange={(followup) => {
							rtUiFormMethods.setWorkingCopy({
								followupTs: followup?.toDate()
							});
						}}
						value={
							workingCopy.followupTs ? moment(workingCopy.followupTs) : moment()
						}
					/>
				</Col>
				<Col md={6}>
					<CurrencyInputFormControl
						label="Received Amount"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.receivedAmount}
						onChange={(receivedAmount) => {
							rtUiFormMethods.setWorkingCopy({ receivedAmount });
						}}
					/>
				</Col>
			</Row>
		</RtUiForm>
	);
};
