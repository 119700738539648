/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationUsageDialCodeSummaryIndexRequest = {
    properties: {
        reconciliationId: {
            type: 'number',
        },
        updateTs: {
            type: 'Date',
            format: 'date-time',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        grouping: {
            type: 'GuardianReconciliationUsageDialCodeSummaryIndexGrouping',
        },
    },
};