/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RtCprRowDto = {
    properties: {
        announcement: {
            type: 'Array',
        },
        areaCodes: {
            type: 'Array',
        },
        carrier: {
            type: 'Array',
        },
        dates: {
            type: 'Array',
        },
        daysOfWeek: {
            type: 'Array',
        },
        goto: {
            type: 'Array',
        },
        latas: {
            type: 'Array',
        },
        npaNxxs: {
            type: 'Array',
        },
        percent: {
            type: 'Array',
        },
        sixDigits: {
            type: 'Array',
        },
        states: {
            type: 'Array',
        },
        tenDigits: {
            type: 'Array',
        },
        terminatingNumber: {
            type: 'Array',
        },
        times: {
            type: 'Array',
        },
        switches: {
            type: 'Array',
        },
    },
};