import * as React from 'react';
import {
	IPlayToneOption,
	PlayToneSelect
} from 'RtUi/app/rtSip/Scenarios/lib/controls/PlayToneSelect';

interface IPlaneToneFormControlFormControlProps {
	onChange: (newValue: string) => void;
	value: string;
	displayMode: boolean;
	required?: boolean;
}

interface IPlaneToneFormControlFormControlState {
	playTone?: IPlayToneOption;
}

export class PlaneToneFormControl extends React.Component<
	IPlaneToneFormControlFormControlProps,
	IPlaneToneFormControlFormControlState
> {
	public state: IPlaneToneFormControlFormControlState = {};

	constructor(props: IPlaneToneFormControlFormControlProps) {
		super(props);

		if (props.value !== '' && !PlayToneSelect.isPlayTone(props.value)) {
			this.state.playTone = { value: props.value };
		}
	}

	public onToneChange(playTone?: IPlayToneOption) {
		this.setState({ playTone });

		this.props.onChange(playTone ? playTone.value : '');
	}

	public render() {
		return (
			<PlayToneSelect
				label="Tone"
				required={this.props.required}
				clearable={false}
				creatable
				appendDropdownToBody
				displayMode={this.props.displayMode}
				onChange={(newValue: IPlayToneOption) => this.onToneChange(newValue)}
				value={this.state.playTone}
				initialOptionId={this.props.value}
			/>
		);
	}
}
