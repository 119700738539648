import * as React from 'react';
import {
	LcoSummaryIndexResponse,
	Reports,
	TimeFilterDetailKeys,
	TimeRangePresets,
	RespOrgIndexResponse
} from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { LcoSummaryRouter } from 'RtUi/app/rtVue/LcoSummary/LcoSummary.router';
import { LcoSummaryGrid } from 'RtUi/app/rtVue/LcoSummary/lib/grids/LcoSummaryGrid';
import { TimeFilterDetailLevelsHours } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { Button } from 'react-bootstrap';
import { MinutesRouter } from 'RtUi/app/rtLco/Minutes/Minutes.router';
import { UserActions } from 'RtUi/state/actions/user';
import { Permissions } from 'RtExports/routes';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import ProperInput from 'RtUi/components/form/ProperInput/ProperInput';

interface ILcoSummaryIndexContainerState
	extends IRtVueContainerState<LcoSummaryIndexResponse> {
	isLcoExporting: boolean;
	respOrg: RespOrgIndexResponse | undefined;
	label: string;
}

@LcoSummaryRouter.getIndexRtUiController({
	groupName: 'Toll-Free Enhanced',
	groupId: 'VueTollFreeEnhanced',
	orderPriority: 1,
	groupOrderPriority: 50
})
export class LcoSummaryIndexContainer extends RtVueReportContainer<
	LcoSummaryIndexResponse,
	ILcoSummaryIndexContainerState
> {
	public state = {
		...this.getBaseState({ defaultTimeRangePreset: TimeRangePresets.Custom }),
		timeRangePresetsKeys: [TimeRangePresets.Custom],
		timeRangeDetailsLevels: TimeFilterDetailLevelsHours.filter(
			(time) => time.key === TimeFilterDetailKeys.Days
		),
		noCustomFocusOnInit: true,
		hideDetailsOption: true,
		isLcoExporting: false,
		respOrg: undefined,
		label: ''
	};

	protected reportId = Reports.LcoSummary;
	protected router = LcoSummaryRouter;

	protected async getResource(): Promise<
		HttpResource<LcoSummaryIndexResponse>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		} = this.state;
		const vueHttp = new RtVueHttp();
		const view = await vueHttp.getLcoSummaryView(
			timeRange,
			filters,
			dataSums,
			preset,
			partition,
			additionalColumns?.map((column) => column.value)
		);

		const lcoResource = new ArrayResource<LcoSummaryIndexResponse>(
			'aninpanxx',
			view.data
		);
		lcoResource.setTotalCount(view.totalCount);

		return lcoResource;
	}

	protected async getMinuteProfileId(
		createMinuteProfile: boolean
	): Promise<number> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns,
			respOrg,
			label
		} = this.state;
		const vueHttp = new RtVueHttp();
		const view = await vueHttp.getLcoSummaryView(
			timeRange,
			filters,
			dataSums,
			preset,
			partition,
			additionalColumns?.map((column) => column.value),
			createMinuteProfile,
			(respOrg as unknown as RespOrgIndexResponse)?.respOrgId || undefined,
			label
		);

		return view as unknown as number;
	}

	protected onLcoExport = async () => {
		const confirmation = await Confirmation.create(
			<span>
				After the export is finished, the results will open in a new window.
				Would you like to continue?
			</span>
		);

		if (!confirmation) {
			return;
		}

		this.setState({ isLcoExporting: true });
		const minuteProfileId = await this.getMinuteProfileId(true);
		this.setState({ isLcoExporting: false }, () => {
			ApplicationContainer.openNewTab(
				MinutesRouter.getProfileRoute(minuteProfileId)
			);
		});
	};

	protected renderReports(): React.ReactNode {
		return (
			<>
				<LcoSummaryGrid
					isLoading={this.state.loadingReport}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
				/>
				{UserActions.has(
					Permissions.TollFreeLcrOperator,
					Permissions.TollFreeLcrManager
				) && (
					<div
						style={{ display: 'flex', flexDirection: 'column', width: '20%' }}
					>
						<ProperInput
							label={'label'}
							value={this.state.label}
							onChange={(label) => this.setState({ label })}
						/>

						<RespOrgSelect
							onChange={(respOrg) => this.setState({ respOrg })}
							value={this.state.respOrg}
						/>
						<Button
							disabled={this.state.isLcoExporting}
							onClick={this.onLcoExport}
						>
							{this.state.isLcoExporting && (
								<i className="fas fa-fw fa-cog fa-spin " />
							)}
							Export to LCO
						</Button>
					</div>
				)}
			</>
		);
	}
}
