import * as moment from 'moment-timezone';
import * as React from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { RespOrgIndexResponse } from 'RtModels';
import { TollFreeNumbersTextArea } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumbersTextArea';
import {
	IRocCreateResponse,
	RocRequestResource
} from 'RtUi/app/rt800/RespOrgChange/lib/resources/RocRequestResource';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { EffectiveTimeFormControl } from 'RtUi/components/form/EffectiveTimeFormControl';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { DragAndDropFilesUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFilesUploader';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtUiWideForm, RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ISubmitRocFormProps {
	onSuccess: (rocTxnId: string) => void;
}

interface ISubmitRocFormState {
	respOrg?: RespOrgIndexResponse;
	createRes?: IRocCreateResponse;
	submitErrors?: RtError;
	submitDocumentErrors?: RtError;
	submitting: boolean;
	loaFile: File | null;
	loaFileSizeInKb: number | null;
	loaValue: string;
	effectiveTs: moment.Moment;
	tfns: string[];
	documents: File[] | undefined;
	tooBigDocuments: File[] | undefined;
	notes: string;
	loaCustomValidityMessage?: string;
}

export class SubmitRocForm extends React.Component<
	ISubmitRocFormProps,
	ISubmitRocFormState
> {
	public state: ISubmitRocFormState = {
		loaValue: '',
		submitting: false,
		effectiveTs: moment(),
		tfns: [],
		documents: undefined,
		tooBigDocuments: undefined,
		notes: '',
		loaFile: null,
		loaFileSizeInKb: null
	};

	public async onSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		const { respOrg, tfns, notes, loaFile } = this.state;

		if (!respOrg) {
			return;
		}

		const effectiveTs = this.state.effectiveTs.toDate();
		const rocRequest = new RocRequestResource();

		this.setState({
			submitting: true,
			submitErrors: undefined,
			submitDocumentErrors: undefined
		});

		let createRes: IRocCreateResponse;

		try {
			createRes = await rocRequest.createROC(
				respOrg.respOrgId,
				tfns,
				effectiveTs,
				notes,
				loaFile ?? undefined
			);

			this.setState({ createRes });
		} catch (submitErrors: any) {
			this.setState({ submitErrors, submitting: false });
			return;
		}

		try {
			const { documents } = this.state;

			if (documents && documents.length > 0) {
				for (const document of documents) {
					await rocRequest.createDocument(createRes.rocTxnId, document);
				}
			}
		} catch (submitDocumentErrors: any) {
			this.setState({ submitDocumentErrors, submitting: false });
			return;
		}

		this.setState({ submitting: false }, () => {
			this.props.onSuccess(createRes.rocTxnId);
		});
	}

	public updateLoaFiles(loaFile: File | null) {
		let loaCustomValidityMessage = ''; //empty string clears invalidity
		const fileSizeInBytes = loaFile?.size ?? null;
		const loaFileSizeInKb = fileSizeInBytes ? fileSizeInBytes / 1000 : null;

		if (
			loaFileSizeInKb !== null &&
			loaFileSizeInKb > RocRequestResource.MAX_LOA_FILE_SIZE_IN_KB
		) {
			loaCustomValidityMessage = `File is too large. Must be less than ${RocRequestResource.MAX_LOA_FILE_SIZE_IN_KB.toLocaleString()}kb.`;
		}

		if (this.state.loaCustomValidityMessage !== loaCustomValidityMessage) {
			this.setState({ loaCustomValidityMessage });
		}

		this.setState({ loaFile, loaFileSizeInKb });
	}

	public updateAdditionalDocuments(files: File[] | undefined) {
		if (!files) {
			this.setState({ documents: undefined, tooBigDocuments: undefined });
			return;
		}

		const documents: File[] = [];
		const tooBigDocuments: File[] = [];

		for (const file of files) {
			const fileSizeInKb = file.size / 1000;
			const isTooBig =
				fileSizeInKb > RocRequestResource.MAX_LOA_FILE_SIZE_IN_KB;
			if (isTooBig) {
				tooBigDocuments.push(file);
			} else {
				documents.push(file);
			}
		}

		this.setState({ documents, tooBigDocuments });
	}

	public render() {
		const { submitErrors, submitting, submitDocumentErrors, createRes } =
			this.state;

		return (
			<RtUiWideForm hideButtons onSubmit={(e) => this.onSubmit(e)}>
				<Row>
					<Col md={6}>
						<RespOrgSelect
							label="New RespOrg"
							onChange={(respOrg) => this.setState({ respOrg })}
							value={this.state.respOrg}
							required
						/>
					</Col>
					<Col md={6}>
						<EffectiveTimeFormControl
							onChange={(effectiveTs) => this.setState({ effectiveTs })}
							value={this.state.effectiveTs}
							isAfter={moment()}
							required
						/>
					</Col>
				</Row>

				<TollFreeNumbersTextArea
					onChange={(tfns) => this.setState({ tfns })}
					value={this.state.tfns}
					required
				/>
				<DragAndDropFileUploader
					label="Letter of Agency File (PDF)"
					accept=".pdf,application/pdf"
					customValidityError={this.state.loaCustomValidityMessage}
					value={this.state.loaFile ?? undefined}
					onDrop={(loaFile) => this.updateLoaFiles(loaFile)}
				/>
				<TextAreaFormControl
					label="Notes"
					rows={4}
					onChange={(notes) => this.setState({ notes })}
					value={this.state.notes}
				/>
				<DragAndDropFilesUploader
					label="Additional PDF Documents"
					accept=".pdf,application/pdf"
					sublabel={
						<>
							<span className="text-danger small">
								&nbsp;<u>DO NOT</u> Upload LOA Here
							</span>
							<br />
							<span className="small">
								(To add multiple files at the same time, click the link below
								and select them at the same time)
							</span>
						</>
					}
					onDrop={(documents) => this.updateAdditionalDocuments(documents)}
					value={this.state.documents}
				/>
				{this.state.tooBigDocuments &&
					this.state.tooBigDocuments.length > 0 && (
						<Alert variant="danger">
							<h6>
								The following files are too large. Files must be 2MB or smaller.
							</h6>
							<ul>
								{this.state.tooBigDocuments.map((tooBigDoc) => (
									<li key={tooBigDoc.name}>{tooBigDoc.name}</li>
								))}
							</ul>
						</Alert>
					)}

				<RtUiForm.Footer>
					<Button type="submit" variant="submit" disabled={submitting}>
						<span>Submit&nbsp;</span>
						{submitting ? (
							<i className="fa fa-fw fa-spin fa-cog" />
						) : (
							<i className="fa fa-fw fa-paper-plane" />
						)}
					</Button>
				</RtUiForm.Footer>
				<FormErrors error={submitErrors} />
				<FormErrors
					title={
						<div>
							The ROC was created{' '}
							<a onClick={() => this.props.onSuccess(createRes!.rocTxnId)}>
								here
							</a>
							. However some or all the documents were not able to upload
						</div>
					}
					error={submitDocumentErrors}
				/>
			</RtUiWideForm>
		);
	}
}
