import * as React from 'react';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { SubmitFormControl } from 'RtUi/components/form/SubmitFormControl';
import { RtUiController } from '../@RtUi/RtUiDecorators';
import { UserHttp } from './lib/Http/UserHttp';
import { Link } from 'react-router-dom';
import { Logo } from 'RtUi/app/public/lib/components/Logo';
import { Container, Alert, Card, Button } from 'react-bootstrap';

interface ISendResetPasswordContainerState {
	email: string;
	error?: any;
	isSubmitting: boolean;
	emailSent: boolean;
}

@RtUiController({
	name: 'Send Reset Password',
	path: '/sendResetPassword',
	isHidden: true,
	unauthorizedAccessAllowed: true,
	hideNav: true
})
export class SendResetPasswordContainer extends ApplicationContainer<
	{},
	ISendResetPasswordContainerState
> {
	public state: ISendResetPasswordContainerState = {
		email: '',
		error: undefined,
		isSubmitting: false,
		emailSent: false
	};

	public userHttp = new UserHttp();

	public async submit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		try {
			await this.userHttp.sendResetPassword(this.state.email);

			this.setState({ emailSent: true });
		} catch (error) {
			this.setState({ error });
		}
	}

	public render() {
		const backToLoginComponent = (
			<Link to="/login">
				<Button type="button" variant="link" className="ps-0">
					Back to Login
				</Button>
			</Link>
		);

		return (
			<form className="v-align-container" onSubmit={(evt) => this.submit(evt)}>
				<Container className="animate__animated animate__fadeIn d-flex justify-content-center">
					<Card
						className="border-primary"
						style={{ minWidth: '30vw', maxWidth: 450 }}
					>
						<Card.Header className="bg-brand-gradient p-3 text-white d-flex align-items-center justify-content-start">
							<Logo height={24} />
						</Card.Header>
						<Card.Body>
							<h6 className="lead">Password Reset</h6>
							{this.state.emailSent && (
								<section>
									<Alert
										variant="success"
										className="d-flex justify-content-between"
									>
										<i className="fas fa-fw fa-check-circle me-2" />
										<span>
											An email will be sent to you shortly with instructions on
											how to reset your password.
										</span>
									</Alert>
									{backToLoginComponent}
								</section>
							)}
							{!this.state.emailSent && (
								<>
									<Alert
										variant="info"
										className="d-flex justify-content-between"
									>
										<i className="fas fa-fw fa-info-circle me-2" />
										<span>
											Fill in your email address, click submit, and then an
											email will be sent to you shortly with instructions on how
											to reset your password.
										</span>
									</Alert>
									<InputFormControl
										label="Email"
										required
										onChange={(email) => this.setState({ email })}
										value={this.state.email}
									/>
									<SubmitFormControl
										editModeActions={() => backToLoginComponent}
										createMode
										displayMode={false}
										error={this.state.error}
										isSubmitting={this.state.isSubmitting}
									/>
								</>
							)}
						</Card.Body>
					</Card>
				</Container>
			</form>
		);
	}
}
