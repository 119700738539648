import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	SubscriptionProfileRequest,
	SubscriptionQosIndexResponse,
	SubscriptionQosProfileRequest,
	SubscriptionQosProfileResponse,
	SubscriptionQosUpdateRequest
} from 'RtModels';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class SubscriptionQosResource extends ArrayResource<SubscriptionQosIndexResponse> {
	constructor(private subscriptionId: number) {
		super('subscriptionId');

		const urlParams: SubscriptionProfileRequest = {
			subscriptionId: this.subscriptionId
		};

		this.setApiRouteForGetAll(RtxSipApiRoutes.SubscriptionQos.Index, {
			urlParams
		});
	}

	public getProfile(
		subscriptionId: number,
		rateCenterId: number
	): Promise<SubscriptionQosProfileResponse> {
		const urlParams: SubscriptionQosProfileRequest = {
			subscriptionId,
			rateCenterId
		};

		return HttpRequest.fetchWithRoute<SubscriptionQosProfileResponse>(
			RtxSipApiRoutes.SubscriptionQos.Profile,
			{ urlParams }
		);
	}

	public updateProfile(
		subscriptionId: number,
		rateCenterId: number,
		qos: SubscriptionQosUpdateRequest
	) {
		const urlParams: SubscriptionQosProfileRequest = {
			subscriptionId,
			rateCenterId
		};
		const body = JSON.stringify(qos);

		return HttpRequest.fetchWithRoute<SubscriptionQosProfileResponse>(
			RtxSipApiRoutes.SubscriptionQos.Update,
			{ body, urlParams }
		);
	}
}
