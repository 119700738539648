import { SubscriptionPeeringIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { PeeringTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PeeringTypeDataGridColumn';
import { PeeringValueDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PeeringValueDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const getSubscriptionPeeringColumns = (
	removeEntity: (row: SubscriptionPeeringIndexResponse) => void
): Array<DataGridColumn<SubscriptionPeeringIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: removeEntity
	}),
	IdDataGridColumn({
		accessorKey: 'subscriptionPeeringId'
	}),
	PeeringTypeDataGridColumn({
		accessorKey: 'peeringTypeId',
		header: 'Peering Type'
	}),
	PeeringValueDataGridColumn({
		header: 'Peered Value',
		accessorKey: 'peeredValue',
		peeringType: (row) => row.peeringTypeId
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated'
	})
];
