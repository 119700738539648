import * as React from 'react';
import { ManagedEntityGrid } from 'RtUi/app/rtAdmin/ManagedEntities/lib/grids/ManagedEntityGrid';
import { ManagedEntityResource } from 'RtUi/app/rtAdmin/ManagedEntities/lib/resources/ManagedEntityResource';
import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	UiNotification,
	UiNotificationCategoryEnum
} from 'RtUi/notifications/lib/UiNotification';
import { DashboardContent } from 'RtUi/app/rtCommon/DashboardOld/lib/components/DashboardContent';
import { MessageEventType } from 'RtModels';

export class ManagedEntityUiNotification extends UiNotification {
	protected managedEntityResource = new ManagedEntityResource();

	constructor() {
		super(
			UiNotificationCategoryEnum.RtAdm,
			RtAdmApiRoutes.ManagedEntities.Index.permissions,
			'Managed Entities Audit',
			MessageEventType.ManagedEntitiesAudit
		);

		this.managedEntityResource.setGetAllDoNotNotifyOn404Error(true);
		this.managedEntityResource.setGetAllParams({ isFailing: 1 });
	}

	public getIcon() {
		const letterStyle: React.CSSProperties = {
			fontSize: 11,
			fontWeight: 700,
			position: 'absolute',
			top: 8,
			left: 4
		};

		return (
			<span style={{ position: 'relative' }}>
				<i className="fas fa-circle" />
				<span className="text-dark" style={letterStyle}>
					ME
				</span>
			</span>
		);
	}

	protected getDetailedView() {
		return (
			<DashboardContent
				notification={this}
				renderWhenHasErrors={() => (
					<ManagedEntityGrid resourceParams={{ isFailing: 1 }} />
				)}
			/>
		);
	}

	protected getIssueCount(): Promise<number> {
		return this.managedEntityResource
			.getAll()
			.then((records) => records.length);
	}
}
