/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum BillingCycleGroups {
    Daily = 1,
    BiWeekly3Then4 = 2,
    Weekly = 3,
    BiMonthly1And16 = 4,
    Monthly = 5,
}