import clsx from 'clsx';
import { FC } from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { GuardianFraudSimulatorResponse } from 'RtModels';

interface IFraudSimulatorResultsProps {
	result?: GuardianFraudSimulatorResponse;
}

enum FraudScoresPoints {
	Initial = 0,
	Warning = 50,
	Danger = 75
}

export const FraudSimulatorResults: FC<
	React.PropsWithChildren<IFraudSimulatorResultsProps>
> = ({ result }) => {
	if (!result) {
		return <section>No results so far</section>;
	}

	return (
		<Row>
			<Col lg={10} xl={8}>
				<Card body className="mb-2">
					<div className="d-flex mb-2">
						<h5 className="text-nowrap  me-2">
							<p
								className={clsx('text-nowrap fw-bold', {
									'text-warning':
										result.score >= FraudScoresPoints.Warning &&
										result.score <= FraudScoresPoints.Danger,
									'text-danger': result.score > FraudScoresPoints.Danger
								})}
							>
								Final Score: {result.score}
							</p>
							<p
								className={clsx({
									'text-warning':
										result.calculatedScore >= 50 &&
										result.calculatedScore <= 75,
									'text-danger': result.calculatedScore > 75
								})}
							>
								Calculated Score: {result.calculatedScore}
							</p>
						</h5>
						{Boolean(result.forceAllow) && (
							<div className="me-2">
								<Badge bg="light" text="dark">
									Forced Allow
								</Badge>
							</div>
						)}
						{Boolean(result.block) && (
							<div>
								<Badge bg="light" text="dark">
									Blocked
								</Badge>
							</div>
						)}
					</div>
					<ul>
						{result.summary.map((s, i) => (
							<li key={`summary_${i}`}>{s}</li>
						))}
					</ul>
				</Card>
			</Col>
		</Row>
	);
};
