import { DestinationTypeId } from 'RtModels';
import {
	IDefaultSelectOption,
	ISelectFormControlInstanceProps,
	SelectFormControl
} from 'RtUi/components/ui/SelectFormControl';
import { useMemo } from 'react';

interface ICdrDestinationTypeSelectProps<IsMulti extends boolean = false>
	extends ISelectFormControlInstanceProps<
		IDefaultSelectOption<DestinationTypeId>,
		'value',
		IsMulti
	> {
	label?: string;
}

export const CdrDestinationTypeSelect = <IsMulti extends boolean = false>({
	label = 'Destination Type',
	isClearable = false,
	...props
}: ICdrDestinationTypeSelectProps<IsMulti>) => {
	const options: Array<IDefaultSelectOption<DestinationTypeId>> = useMemo(
		() => [
			{
				label: 'FTP',
				value: DestinationTypeId.Ftp
			},
			{
				label: 'SFTP',
				value: DestinationTypeId.Sftp
			},
			{
				label: 'AWS',
				value: DestinationTypeId.Aws
			}
		],
		[]
	);

	return (
		<SelectFormControl<
			IDefaultSelectOption<DestinationTypeId>,
			'value',
			IsMulti
		>
			label={label}
			valueKey="value"
			labelKey="label"
			isClearable={isClearable}
			options={options}
			{...props}
		/>
	);
};
