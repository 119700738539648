import { ServerGroupIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ServerGroupResource } from 'RtUi/app/rtAdmin/ServerGroups/lib/resource/ServerGroupResource';

interface IServerGroupSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<ServerGroupIndexResponse, isMulti> {}

export class ServerGroupSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	ServerGroupIndexResponse,
	isMulti,
	IServerGroupSelectProps<isMulti>
> {
	public resourceClass = ServerGroupResource;
	public state: ISelectFormControlState<ServerGroupIndexResponse> = {
		formLabel: 'Server Group',
		valueKey: 'serverGroupId',
		labelKey: 'serverGroupId'
	};
}
