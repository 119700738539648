import {
	OriginationNumberProfileResponse,
	ServiceNumberTagIndexResponse
} from 'RtModels';
import { ServiceNumberTagFormEditor } from 'RtUi/app/rtSip/ServiceNumbers/lib/forms/ServiceNumberTagFormEditor';
import { ServiceNumberTagResource } from 'RtUi/app/rtSip/ServiceNumbers/lib/resources/ServiceNumberTagResource';
import { useGetServiceNumberTags } from 'RtUi/app/rtSip/ServiceNumbers/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getServiceNumberTagsColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/serviceNumbers';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { isNil } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IServiceNumberTagGridProps {
	serviceNumber: OriginationNumberProfileResponse;
	autoFocusFilter?: boolean;
}

export const ServiceNumberTagGrid = ({
	serviceNumber
}: IServiceNumberTagGridProps) => {
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetServiceNumberTags({
		serviceNumberId: serviceNumber.serviceNumberId
	});
	const [selectedRecord, setSelectedRecord] =
		useState<ServiceNumberTagIndexResponse>();
	const [isAddAsideOpen, setIsAddAsideOpen] = useState<boolean>(false);
	const deleteTag = useCallback(
		async (row: ServiceNumberTagIndexResponse) => {
			const { serviceNumberTagId, tag } = row;

			const confirm = await Confirmation.createDelete(
				`ServiceNumber Tag: ${tag}`
			);

			if (!confirm) {
				return;
			}

			await new ServiceNumberTagResource().delete(serviceNumberTagId);
			refetch();
		},
		[refetch]
	);

	const columns = useMemo(
		() => getServiceNumberTagsColumns(deleteTag),
		[deleteTag]
	);

	return (
		<>
			<DataGrid<ServiceNumberTagIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtSip_serviceNumberTag'}
				rowThemeColor={(record) =>
					record.serviceNumberTagId === selectedRecord?.serviceNumberTagId
						? RowThemeColor.WARNING
						: undefined
				}
				onRowClick={async (row) => {
					setSelectedRecord(row);
					setIsAddAsideOpen(true);
				}}
				headerAction={() => {
					const isAddAllowed =
						isNil(data?.data.length) || data?.data.length > 0;

					return (
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id={`addTagBtn-tooltip`} {...props}>
									Add Tag
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									disabled={isAddAllowed}
									{...triggerHandler}
									onClick={() => {
										setSelectedRecord(undefined);
										setIsAddAsideOpen(true);
									}}
									variant="white"
									id="addTagBtn"
								>
									<i className="fas fa-fw fa-plus fa-lg" />
								</Button>
							)}
						</OverlayTrigger>
					);
				}}
			/>
			<Aside isOpen={isAddAsideOpen} disabledBody>
				<Aside.Header
					header="Tag"
					onClose={() => {
						setIsAddAsideOpen(false);
						setSelectedRecord(undefined);
					}}
				/>
				<ServiceNumberTagFormEditor
					tag={selectedRecord}
					serviceNumber={serviceNumber.serviceNumber}
					onSuccess={() => {
						setIsAddAsideOpen(false);
						setSelectedRecord(undefined);
						refetch();
					}}
				/>
			</Aside>
			<Confirmation />
		</>
	);
};
