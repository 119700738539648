import { TaskProfileResponse } from 'RtModels';
import { NumberRouter } from 'RtUi/app/rt800/Numbers/Number.router';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/Task.profile';
import { TaskAlertsWarning } from 'RtUi/app/rt800/Tasks/lib/utils/Constants';
import { TemplateRouter } from 'RtUi/app/rt800/Templates/Template.router';
import { DashboardStat } from 'RtUi/components/ui/DashboardStat';
import { DashboardPercentageStat } from 'RtUi/components/ui/DashboardStat/DashboardPercentageStat';
import moment from 'moment';
import {
	OverlayTrigger,
	Tooltip,
	Button,
	Badge,
	ListGroup,
	Row,
	Col
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const renderButtonBadge = (
	title: string,
	numOfCprs: number,
	size: 'lg' | 'sm' | undefined = undefined,
	tooltip?: string,
	useButton = true
) => {
	const showAsWarning = numOfCprs > 0;
	const btnColor = showAsWarning ? 'warning' : 'white-alt';
	const badgeColor = showAsWarning ? 'white' : 'light';
	const btnId = `futureBadge-${title}-${numOfCprs}-${size}`;

	return (
		<>
			{useButton && (
				<OverlayTrigger
					overlay={(props) =>
						tooltip ? (
							<Tooltip id={`${btnId}-tooltip`} {...props}>
								Alternate routing profiles contain routing rules that can be
								applied to the working routing profile when default routing is
								not desired, such as during carrier outages or scheduled
								maintenance windows.
							</Tooltip>
						) : (
							<span></span>
						)
					}
				>
					{({ ref, ...triggerHandler }) => (
						<Button
							ref={ref}
							{...triggerHandler}
							variant={btnColor}
							className="me-3 mb-2 mb-lg-0"
							size={size}
							id={btnId}
						>
							{tooltip && (
								<i className="far fa-fw fa-question-circle me-1 text-muted" />
							)}
							<span className="me-2">Future {title}</span>
							<Badge bg={badgeColor}>{numOfCprs.toLocaleString()}</Badge>
						</Button>
					)}
				</OverlayTrigger>
			)}
			{!useButton && (
				<ListGroup.Item key={title} className="futures-list-group-item">
					<span className="me-2">Future {title}</span>
					<Badge bg={badgeColor}>{numOfCprs.toLocaleString()}</Badge>
				</ListGroup.Item>
			)}
		</>
	);
};

export const getIsBeforeScheduled = (task?: TaskProfileResponse) => {
	if (!task) {
		return false;
	}

	const scheduledAt = moment(task.scheduledAt);
	const now = moment();
	const isCompleted = Boolean(task.completedAt);
	if (isCompleted) {
		return false;
	}
	return scheduledAt.isSameOrAfter(now);
};

export const getIsNotCompleteAndAfterScheduled = (task: ITaskProfile) => {
	const scheduledAt = moment(task.scheduledAt);
	const isCompleted = Boolean(task.completedAt);
	const now = moment();
	if (isCompleted) {
		return false;
	}
	return scheduledAt.isSameOrBefore(now);
};

export const getReferenceProfile = (referenceKey: string) => {
	const isTemplate = referenceKey.startsWith('*');
	let path = '';

	if (isTemplate) {
		path = TemplateRouter.getProfileRoute(referenceKey);
	} else {
		path = NumberRouter.getProfileRoute(referenceKey);
	}

	return path;
};

export const renderSummary = (summary: string) => {
	const summaryTemplateNameRegex = /\((\*.*)\)/g;
	const summaryRegexParts = summaryTemplateNameRegex.exec(summary);

	if (summaryRegexParts) {
		const templateNameWithParens = summaryRegexParts[0];
		const templateName = summaryRegexParts[1];

		summary = summary.replace(templateNameWithParens, '');
		const linkElement = (
			<Link to={getReferenceProfile(templateName)}>{templateName}</Link>
		);

		return (
			<span>
				{summary} ({linkElement})
			</span>
		);
	}

	const tfnRegex = /\((\d*)\)/g;
	const tfnRegexParts = tfnRegex.exec(summary);

	if (tfnRegexParts) {
		const tfnWithParens = tfnRegexParts[0];
		const tfn = tfnRegexParts[1];

		if (TollFreeNumbersUtils.isValid(tfn)) {
			const tfnFormatted =
				TollFreeNumbersUtils.tollFreeNumberToReadableString(tfn);

			summary = summary.replace(tfnWithParens, '');
			const linkElement = (
				<Link to={getReferenceProfile(tfn)}>{tfnFormatted}</Link>
			);

			return (
				<span>
					{summary} ({linkElement})
				</span>
			);
		}
	}

	return summary;
};

export const renderDashboardStats = (
	task: ITaskProfile,
	onClickDashboardErrors: () => void
) => {
	const { apiReqQty, apiResQty, apiTotQty } = task;

	const totalExpectedActivationQty = task.hasActivations
		? task.tfnQty - task.errorQty
		: 0;
	const somosActivationsAreComplete =
		task.totalNumbersActivated >= totalExpectedActivationQty;
	const somosActivationsContent = task.hasActivations ? undefined : 'N/A';
	let activationsDecimalValue =
		task.totalNumbersActivated / totalExpectedActivationQty;
	let somosErrorContent: string | null = null;
	let routeTrustRequestsRatio = 0;
	let somosResponsesRatio = 0;

	if (task.completedAt) {
		//Show completion when completedAt has
		//been manually turned on
		routeTrustRequestsRatio = 1;
		somosResponsesRatio = 1;
	} else if (apiTotQty > 0) {
		routeTrustRequestsRatio = apiReqQty / apiTotQty;
		somosResponsesRatio = apiResQty / apiTotQty;
	}

	if (!task.hasActivations) {
		activationsDecimalValue = 100;
	} else {
		if (somosActivationsAreComplete) {
			const didNotCompleteActivations =
				task.totalNumbersActivated < totalExpectedActivationQty ||
				totalExpectedActivationQty === 0;

			if (didNotCompleteActivations) {
				somosErrorContent = `${task.totalNumbersActivated.toLocaleString()} Activated`;
			}
		}
	}

	const isBeforeScheduled = getIsBeforeScheduled(task);

	return (
		<>
			<Row>
				<Col xs={6} sm={4} md={6} lg={4} className="mb-4">
					{isBeforeScheduled && (
						<DashboardStat
							header="RouteTrust Requests"
							stat="Pending"
							icon="clock"
							iconColor="info"
						/>
					)}
					{!isBeforeScheduled && (
						<DashboardPercentageStat
							header="RouteTrust Requests"
							isRunning
							decimal={routeTrustRequestsRatio}
						/>
					)}
				</Col>
				<Col xs={6} sm={4} md={6} lg={4} className="mb-4">
					{isBeforeScheduled && (
						<DashboardStat
							header="SOMOS Responses"
							stat="Pending"
							icon="clock"
							iconColor="info"
						/>
					)}
					{!isBeforeScheduled && (
						<DashboardPercentageStat
							header="SOMOS Responses"
							isRunning
							decimal={somosResponsesRatio}
						/>
					)}
				</Col>
				<Col xs={6} sm={4} md={6} lg={4} className="mb-4">
					{isBeforeScheduled && (
						<DashboardStat
							header="SOMOS Activations"
							stat="Pending"
							icon="clock"
							iconColor="info"
						/>
					)}
					{!isBeforeScheduled && (
						<>
							{somosErrorContent && (
								<DashboardStat
									header="SOMOS Activations"
									statColor="danger"
									stat={somosErrorContent}
									icon="exclamation-triangle"
									iconColor="danger"
								/>
							)}
							{!somosErrorContent && (
								<DashboardPercentageStat
									header="SOMOS Activations"
									isRunning
									content={somosActivationsContent}
									decimal={activationsDecimalValue}
								/>
							)}
						</>
					)}
				</Col>
			</Row>
			<Row>
				<Col xs={6} sm={4} md={6} lg={4} className="mb-4">
					<DashboardStat
						header="Total Toll-Free Numbers"
						stat={task.tfnQty.toLocaleString()}
						icon="phone-alt"
						iconColor="info"
					/>
				</Col>
				<Col xs={6} sm={4} md={6} lg={4} className="mb-4">
					<DashboardStat
						header="Warnings"
						stat={task.warningQty.toLocaleString()}
						icon="info-circle"
						iconColor="warning"
						tooltip={task.warningQty > 0 ? TaskAlertsWarning : undefined}
						showAs={task.warningQty > 0 ? 'warning' : undefined}
					/>
				</Col>
				<Col xs={6} sm={4} md={6} lg={4} className="mb-4">
					<DashboardStat
						header="Errors"
						stat={task.errorQty.toLocaleString()}
						icon="exclamation-triangle"
						iconColor="danger"
						tooltip={task.errorQty > 0 ? TaskAlertsWarning : undefined}
						showAs={task.errorQty > 0 ? 'danger' : undefined}
						onClick={onClickDashboardErrors}
					/>
				</Col>
			</Row>
		</>
	);
};
