import { useMedia } from 'react-use';

export enum SCREEN_SIZE {
	SMALL,
	MEDIUM,
	LARGE
}

export const useGetScreenSize = () => {
	const isLarge = useMedia('(min-width: 1920px)');
	const isMedium = useMedia('(min-width: 1300px)');

	if (isLarge) {
		return SCREEN_SIZE.LARGE;
	}

	if (isMedium) {
		return SCREEN_SIZE.MEDIUM;
	}

	return SCREEN_SIZE.SMALL;
};
