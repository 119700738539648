import { Rt800ApiRoutes } from 'RtExports/routes';
import { MessageEventType } from 'RtModels';
import {
	AuditTemplateForResourceType,
	NumberResource
} from 'RtUi/app/rt800/Numbers/lib/resources/NumberResource';
import { DashboardContent } from 'RtUi/app/rtCommon/DashboardOld/lib/components/DashboardContent';
import {
	UiNotification,
	UiNotificationCategoryEnum
} from 'RtUi/notifications/lib/UiNotification';
import { TemplateCprAuditUiNotificationGrid } from 'RtUi/notifications/lib/grids/TemplateCprAuditUiNotificationGrid';

export class TemplateCprAuditUiNotification extends UiNotification {
	protected numberResource = new NumberResource();
	protected templateAuditResource: AuditTemplateForResourceType =
		this.numberResource.getAuditCprResourceForTemplates();

	constructor() {
		super(
			UiNotificationCategoryEnum.Rt800,
			Rt800ApiRoutes.AuditTemplateCprs.Index.permissions,
			'Template Routing (CPR) Audit',
			MessageEventType.TemplateRoutingAudit
		);

		this.templateAuditResource.setGetAllDoNotNotifyOn404Error(true);
	}

	public getIcon() {
		return <i className="fas fa-stream" />;
	}

	protected getDetailedView() {
		return (
			<DashboardContent
				notification={this}
				renderWhenHasErrors={() => <TemplateCprAuditUiNotificationGrid />}
			/>
		);
	}

	protected getIssueCount(): Promise<number> {
		return this.templateAuditResource
			.getAll()
			.then((records) => records.length);
	}
}
