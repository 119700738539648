import * as React from 'react';
import { Form } from 'react-bootstrap';
import { ReportMetadataProfileResponse, ReportAggregates } from 'RtModels';
import { CheckboxButtons } from 'RtUi/components/ui/CheckboxButtons';

interface IVueReportAggregatesProps {
	metadata: ReportMetadataProfileResponse;
	aggregates: ReportAggregates[] | undefined;
	onChange: (newAggregates: ReportAggregates[] | undefined) => void;
}

export class VueReportAggregates extends React.Component<
	IVueReportAggregatesProps,
	{}
> {
	public render() {
		const { dataSums = [] } = this.props.metadata;

		if (dataSums.length <= 0) {
			return null;
		}

		return (
			<Form.Group className="d-flex justify-content-start align-items-center mb-3">
				<Form.Label className="label-90 mb-0">Aggregates</Form.Label>
				<CheckboxButtons<ReportAggregates>
					value={this.props.aggregates}
					onChange={(newAggregates) => this.props.onChange(newAggregates)}
					options={dataSums.map((dataSum) => {
						return {
							label: dataSum.summary,
							value: dataSum.dataPreset
						};
					})}
				/>
			</Form.Group>
		);
	}
}
