import { LcrTaskCarrierResultIndexResponse } from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export interface ILcrTaskCarrierResultIndexResponse
	extends LcrTaskCarrierResultIndexResponse {
	estMinutesSpend: number;
}

export class LcrTaskCarrierResultsResource extends ArrayResource<ILcrTaskCarrierResultIndexResponse> {
	constructor(lcrTaskId: number, lcrTaskResultId: number) {
		super('lcrTaskCarrierResultId');

		const urlParams = { lcrTaskId, lcrTaskResultId };

		this.setApiRouteForGetAll(Rt800ApiRoutes.LcrTaskCarrierResults.Index, {
			urlParams
		});
	}

	/**
	 * @override
	 * @param extraParams
	 * @param resetCache
	 */
	public getAll(
		extraParams: any = {},
		resetCache = false
	): Promise<ILcrTaskCarrierResultIndexResponse[]> {
		return super
			.getAll()
			.then((indexes) => this.addComputedIndexProperties(indexes));
	}

	public getAllWithoutCache(
		extraParams: any = {}
	): Promise<ILcrTaskCarrierResultIndexResponse[]> {
		return super
			.getAllWithoutCache(extraParams)
			.then((indexes) => this.addComputedIndexProperties(indexes));
	}

	private addComputedIndexProperties(
		indexes: ILcrTaskCarrierResultIndexResponse[]
	) {
		indexes.forEach((index) => {
			index.estMinutesSpend = index.totalCharges / index.totalMinutes;
		});

		return indexes;
	}
}
