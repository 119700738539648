import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	RocActionIndexResponse,
	RocIndexRequest,
	RocIndexResponse,
	RocNumbersIndexRequest,
	RocNumbersIndexResponse,
	RocProfileRequest,
	RocProfileResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	handlePostRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchRocRequests = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: RocIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<RocIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest<RocIndexResponse[], IncludeFullResponse>(
		Rt800ApiRoutes.Roc.Index,
		{
			queryParams,
			includeFullResponse
		}
	);
};

export const useGetRocRequests = (
	queryParams?: RocIndexRequest,
	enabled: boolean = true
) => {
	return useQuery<FullResponse<RocIndexResponse[]>, Error>(
		[`useGetRocRequests`, queryParams],
		() => fetchRocRequests(queryParams, true),
		{ enabled }
	);
};

export const fetchRocRequest = async (
	urlParams?: RocProfileRequest
): Promise<RocProfileResponse> => {
	return handleGetRequest<RocProfileResponse>(Rt800ApiRoutes.Roc.Profile, {
		urlParams
	});
};

export const useGetRocRequest = (urlParams: RocProfileRequest) => {
	return useQuery<RocProfileResponse, Error>(
		[`useGetRocRequest`, urlParams],
		() => fetchRocRequest(urlParams),
		{ enabled: true }
	);
};

export const fetchRocRequestActions =
	async (): Promise<RocActionIndexResponse> => {
		return handleGetRequest<RocActionIndexResponse>(
			Rt800ApiRoutes.RocAction.Index
		);
	};

export const useGetRocRequestActions = () => {
	return useQuery<RocActionIndexResponse, Error>(
		[`useGetRocRequestActions`],
		() => fetchRocRequestActions(),
		{ enabled: true }
	);
};

const fetchRocNumbers = async (
	bodyParams: RocNumbersIndexRequest
): Promise<FullResponse<RocNumbersIndexResponse[]>> => {
	return handlePostRequest<RocNumbersIndexResponse[], true>(
		Rt800ApiRoutes.RocNumbers.Index,
		bodyParams,
		{
			includeFullResponse: true
		}
	);
};

export const useGetRocNumbers = (bodyParams: RocNumbersIndexRequest) => {
	return useQuery<FullResponse<RocNumbersIndexResponse[]>, Error>(
		[`useGetRocNumbers`, bodyParams],
		() => fetchRocNumbers(bodyParams),
		{ enabled: true }
	);
};
