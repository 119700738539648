import * as React from 'react';
import * as moment from 'moment-timezone';
import { Row, Col } from 'react-bootstrap';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import {
	IDidNumberProfile,
	DidNumbersResource
} from 'RtUi/app/rtDid/Numbers/lib/resources/DidNumbersResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { InternationalNumberFormControl } from 'RtUi/app/rtSip/ServiceNumbers/lib/controls/InternationalNumberFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { ResourceConfigurationIndexResponse } from 'RtModels';
import { ResourceConfigurationSelect } from 'RtUi/app/rtCommon/ResourceConfigurations/lib/controls/ResourceConfigurationSelect';

interface IDidNumberEditorProps {
	editMode: IDidNumberProfile;
	onUpdate: (updatedProfile: IDidNumberProfile) => void;
}

interface IDidNumberEditorState {
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
	label: string;
	vendor?: ResourceConfigurationIndexResponse;
}

export class DidNumberEditor extends React.Component<
	IDidNumberEditorProps,
	IDidNumberEditorState
> {
	public state: IDidNumberEditorState = {
		displayMode: true,
		isSubmitting: false,
		error: undefined,
		label: ''
	};

	public componentDidMount() {
		this.loadFromEditModeProfile();
	}

	public loadFromEditModeProfile() {
		const { label } = this.props.editMode;

		this.setState({ label });
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		let updatedDidNumberProfile: IDidNumberProfile | null = null;

		try {
			const { numberId } = this.props.editMode;
			const { label } = this.state;
			const didNumbersResource = new DidNumbersResource();

			updatedDidNumberProfile = await didNumbersResource.update(
				numberId,
				label
			);

			this.setState({ displayMode: true });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (updatedDidNumberProfile) {
			this.props.onUpdate(updatedDidNumberProfile);
		}
	}

	public onCancel() {
		this.loadFromEditModeProfile();
	}

	public render() {
		const profile = this.props.editMode;

		return (
			<RtUiWideForm
				onCancel={() => this.onCancel()}
				onSubmit={(evt) => this.onSubmit(evt)}
				displayMode={this.state.displayMode}
				onChange={(displayMode) => this.setState({ displayMode })}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
			>
				<Row>
					<Col md={6}>
						<InputFormControl
							label="label"
							displayMode={this.state.displayMode}
							onChange={(label) => this.setState({ label })}
							value={this.state.label}
						/>
						<InternationalNumberFormControl
							label="number"
							displayMode
							value={profile.number}
						/>
						<InternationalNumberFormControl
							label="Translated Number"
							displayMode
							value={profile.translatedNumber}
						/>
						<PrecisionFormControl
							label="Non-Recurring Charge"
							displayMode
							value={profile.nonRecurringAmount}
						/>
						<PrecisionFormControl
							label="Recurring Charge"
							displayMode
							value={profile.recurringAmount}
						/>
					</Col>
					<Col md={6}>
						<ResourceConfigurationSelect
							displayMode
							onChange={(vendor: ResourceConfigurationIndexResponse) =>
								this.setState({ vendor })
							}
							value={this.state.vendor}
							initialOptionId={String(profile.resourceId)}
						/>
						<InputFormControl
							label="status"
							displayMode
							value={profile.status}
						/>
						<DatetimeFormControl
							label="Created"
							displayMode
							value={profile.createdAt ? moment(profile.createdAt) : undefined}
						/>
						<InputFormControl
							label="Trunk Group"
							displayMode
							value={profile.trunkGroup}
						/>
					</Col>
				</Row>
			</RtUiWideForm>
		);
	}
}
