import * as React from 'react';
import { CountryIndexResponse, PortLimitProfileResponse } from 'RtModels';
import { PortLimitResource } from 'RtUi/app/rtSip/PortLimits/lib/resources/PortLimitResource';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IPortLimitFormEditorProps {
	profile?: PortLimitProfileResponse;
	accountId?: number;
	onSuccess?: (profile: PortLimitProfileResponse) => void;
	onCancel?: () => void;
}

interface IPortLimitFormEditorState {
	isSubmitting: boolean;
	ingressUnlimited: boolean;
	egressUnlimited: boolean;
	ingressPortLimit?: number;
	egressPortLimit?: number;
	country?: CountryIndexResponse;
	error?: any;
}

export class PortLimitFormEditor extends React.Component<
	IPortLimitFormEditorProps,
	IPortLimitFormEditorState
> {
	constructor(props: IPortLimitFormEditorProps) {
		super(props);

		this.state = {
			isSubmitting: false,
			ingressUnlimited: Boolean(props.profile?.ingressPortLimit === null),
			egressUnlimited: Boolean(props.profile?.egressPortLimit === null)
		};
	}

	public render() {
		return (
			<RtUiForm
				createMode={!this.props.profile}
				onSubmit={this.createOrUpdate}
				displayMode={false}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onCancel={this.onCancel}
			>
				{!this.props.profile && (
					<CountrySelect
						placeholder="Leave blank for ALL"
						label="Country"
						onChange={(country) => this.setState({ country })}
						value={this.state.country}
						isClearable={false}
					/>
				)}
				<BooleanRadioFormControl
					label="Unlimited Ingress Port Limit"
					showBothOption={false}
					value={Number(this.state.ingressUnlimited)}
					onChange={(val) => {
						this.setState({ ingressUnlimited: Boolean(val) });
					}}
				/>
				{!this.state.ingressUnlimited && (
					<InputFormControl
						type="number"
						label="Ingress Port Limit"
						onChange={(ingressPortLimit) =>
							this.setState({ ingressPortLimit: Number(ingressPortLimit) })
						}
						initialValue={this.props.profile?.ingressPortLimit?.toString()}
						value={this.state.ingressPortLimit?.toString()}
					/>
				)}
				<BooleanRadioFormControl
					label="Unlimited Egress Port Limit"
					showBothOption={false}
					value={Number(this.state.egressUnlimited)}
					onChange={(val) => {
						this.setState({ egressUnlimited: Boolean(val) });
					}}
				/>
				{!this.state.egressUnlimited && (
					<InputFormControl
						type="number"
						label="Egress Port Limit"
						onChange={(egressPortLimit) =>
							this.setState({ egressPortLimit: Number(egressPortLimit) })
						}
						initialValue={this.props.profile?.egressPortLimit?.toString()}
						value={this.state.egressPortLimit?.toString()}
					/>
				)}
			</RtUiForm>
		);
	}

	private onCancel = () => {
		this.setState(
			{
				ingressPortLimit: this.props.profile?.ingressPortLimit,
				egressPortLimit: this.props.profile?.egressPortLimit
			},
			() => {
				if (this.props.onCancel) {
					this.props.onCancel();
				}
			}
		);
	};

	private createOrUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const portLimitResource = new PortLimitResource();
		let newProfile: PortLimitProfileResponse;

		this.setState({ isSubmitting: true, error: undefined });
		const { onSuccess = () => ({}) } = this.props;

		try {
			if (this.props.profile) {
				newProfile = await portLimitResource.update(
					this.props.profile.portLimitId,
					this.state.ingressPortLimit!,
					this.state.egressPortLimit!
				);
			} else {
				newProfile = await portLimitResource.create({
					accountId: this.props.accountId!,
					countryAbbr: this.state.country?.iso3166Alpha3,
					ingressPortLimit: this.state.ingressUnlimited
						? null
						: this.state.ingressPortLimit!,
					egressPortLimit: this.state.egressUnlimited
						? null
						: this.state.egressPortLimit!
				});
			}
			onSuccess(newProfile);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	};
}
