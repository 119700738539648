import { TaskErrorIndexResponse } from 'RtModels';
import { TaskResource } from '../resources/TaskResource';
import { TaskPollPromiseWrapper } from './TaskPollPromiseWrapper';

export class TaskPollWithErrorsPromiseWrapper {
	private isCancelled = false;
	private taskPollPromise!: TaskPollPromiseWrapper;
	private pollPromise!: Promise<TaskErrorIndexResponse[]>;

	constructor(taskId: number, delayInMs?: number) {
		this.pollPromise = new Promise<TaskErrorIndexResponse[]>(
			async (resolve, reject) => {
				let delayPromise = Promise.resolve();

				if (typeof delayInMs === 'number') {
					delayPromise = new Promise((resolve) =>
						setTimeout(resolve, delayInMs)
					);
				}

				try {
					await delayPromise;

					this.taskPollPromise = new TaskPollPromiseWrapper(taskId);

					await this.taskPollPromise.getPromise();

					if (this.isCancelled) {
						return [];
					}

					const errors = await TaskResource.GetTaskErrors(taskId);

					resolve(errors);
				} catch (error) {
					reject(error);
				}
			}
		);
	}

	public cancel() {
		this.isCancelled = true;

		if (this.taskPollPromise) {
			this.taskPollPromise.cancel();
		}
	}

	public getPromise() {
		return this.pollPromise;
	}

	public getTemplate() {
		return this.taskPollPromise.getPromise();
	}
}
