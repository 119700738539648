/* eslint-disable @typescript-eslint/no-unsafe-call */
import { monthNameMap } from 'RtUi/app/rtVue/MonthlyTrending/utils';
import { isEmpty } from 'lodash-es';

export const financialColumns = [
	null,
	{
		value: 'MOU',
		fontWeight: 'bold'
	},
	{
		value: 'Revenue',
		fontWeight: 'bold'
	},
	{
		value: 'Cost',
		fontWeight: 'bold'
	},
	{
		value: 'Margin',
		fontWeight: 'bold'
	},
	{
		value: 'RPM',
		fontWeight: 'bold'
	},
	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},
	{
		value: 'Revenue',
		fontWeight: 'bold'
	},
	{
		value: 'Cost',
		fontWeight: 'bold'
	},
	{
		value: 'Margin',
		fontWeight: 'bold'
	},
	{
		value: 'RPM',
		fontWeight: 'bold'
	},
	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},
	{
		value: 'Revenue',
		fontWeight: 'bold'
	},
	{
		value: 'Cost',
		fontWeight: 'bold'
	},
	{
		value: 'Margin',
		fontWeight: 'bold'
	},
	{
		value: 'RPM',
		fontWeight: 'bold'
	},
	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},
	{
		value: 'Revenue',
		fontWeight: 'bold'
	},
	{
		value: 'Cost',
		fontWeight: 'bold'
	},
	{
		value: 'Margin',
		fontWeight: 'bold'
	},
	{
		value: 'RPM',
		fontWeight: 'bold'
	},
	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},
	{
		value: 'Revenue',
		fontWeight: 'bold'
	},
	{
		value: 'Cost',
		fontWeight: 'bold'
	},
	{
		value: 'Margin',
		fontWeight: 'bold'
	},
	{
		value: 'RPM',
		fontWeight: 'bold'
	},
	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},
	{
		value: 'Revenue',
		fontWeight: 'bold'
	},
	{
		value: 'Cost',
		fontWeight: 'bold'
	},
	{
		value: 'Margin',
		fontWeight: 'bold'
	},
	{
		value: 'RPM',
		fontWeight: 'bold'
	},
	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},
	{
		value: 'Revenue',
		fontWeight: 'bold'
	},
	{
		value: 'Cost',
		fontWeight: 'bold'
	},
	{
		value: 'Margin',
		fontWeight: 'bold'
	},
	{
		value: 'RPM',
		fontWeight: 'bold'
	},
	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MPM',
		fontWeight: 'bold'
	}
];

export const offnetColumns = [
	null,
	{
		value: 'MOU',
		fontWeight: 'bold'
	},

	{
		value: 'Cost',
		fontWeight: 'bold'
	},

	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},

	{
		value: 'Cost',
		fontWeight: 'bold'
	},

	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},

	{
		value: 'Cost',
		fontWeight: 'bold'
	},

	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},

	{
		value: 'Cost',
		fontWeight: 'bold'
	},

	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},

	{
		value: 'Cost',
		fontWeight: 'bold'
	},

	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},

	{
		value: 'Cost',
		fontWeight: 'bold'
	},

	{
		value: 'CPM',
		fontWeight: 'bold'
	},
	{
		value: 'MOU',
		fontWeight: 'bold'
	},

	{
		value: 'Cost',
		fontWeight: 'bold'
	},

	{
		value: 'CPM',
		fontWeight: 'bold'
	}
];

export const financialFirstColumns = [
	{
		value: 'Account',
		fontWeight: 'bold'
	},
	{
		value: `${monthNameMap.get('month0Value')}`,
		fontWeight: 'bold',
		span: 7
	},
	null,
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month1Value')}`,
		fontWeight: 'bold',
		span: 7
	},
	null,
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month2Value')}`,
		fontWeight: 'bold',
		span: 7
	},
	null,
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month3Value')}`,
		fontWeight: 'bold',
		span: 7
	},
	null,
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month4Value')}`,
		fontWeight: 'bold',
		span: 7
	},
	null,
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month5Value')}`,
		fontWeight: 'bold',
		span: 7
	},
	null,
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month6Value')}`,
		fontWeight: 'bold',
		span: 7
	},
	null,
	null,
	null,
	null,
	null,
	null
];

export const offnetFirstColumns = [
	{
		value: 'Account',
		fontWeight: 'bold'
	},
	{
		value: `${monthNameMap.get('month0Value')}`,
		fontWeight: 'bold',
		span: 3
	},
	null,
	null,
	{
		value: `${monthNameMap.get('month1Value')}`,
		fontWeight: 'bold',
		span: 3
	},
	null,
	null,
	{
		value: `${monthNameMap.get('month2Value')}`,
		fontWeight: 'bold',
		span: 3
	},
	null,
	null,
	{
		value: `${monthNameMap.get('month3Value')}`,
		fontWeight: 'bold',
		span: 3
	},
	null,
	null,
	{
		value: `${monthNameMap.get('month4Value')}`,
		fontWeight: 'bold',
		span: 3
	},
	null,
	null,
	{
		value: `${monthNameMap.get('month5Value')}`,
		fontWeight: 'bold',
		span: 3
	},
	null,
	null,
	{
		value: `${monthNameMap.get('month6Value')}`,
		fontWeight: 'bold',
		span: 3
	},
	null,
	null
];

export const networkColumns = [
	null,
	{
		value: 'ASR',
		fontWeight: 'bold'
	},
	{
		value: 'Aloc',
		fontWeight: 'bold'
	},
	{
		value: 'PDD',
		fontWeight: 'bold'
	},
	{
		value: 'Roboscore',
		fontWeight: 'bold'
	},
	{
		value: 'DNO',
		fontWeight: 'bold'
	},
	{
		value: 'Anis',
		fontWeight: 'bold'
	},
	{
		value: 'ASR',
		fontWeight: 'bold'
	},
	{
		value: 'Aloc',
		fontWeight: 'bold'
	},
	{
		value: 'PDD',
		fontWeight: 'bold'
	},
	{
		value: 'Roboscore',
		fontWeight: 'bold'
	},
	{
		value: 'DNO',
		fontWeight: 'bold'
	},
	{
		value: 'Anis',
		fontWeight: 'bold'
	},
	{
		value: 'ASR',
		fontWeight: 'bold'
	},
	{
		value: 'Aloc',
		fontWeight: 'bold'
	},
	{
		value: 'PDD',
		fontWeight: 'bold'
	},
	{
		value: 'Roboscore',
		fontWeight: 'bold'
	},
	{
		value: 'DNO',
		fontWeight: 'bold'
	},
	{
		value: 'Anis',
		fontWeight: 'bold'
	},
	{
		value: 'ASR',
		fontWeight: 'bold'
	},
	{
		value: 'Aloc',
		fontWeight: 'bold'
	},
	{
		value: 'PDD',
		fontWeight: 'bold'
	},
	{
		value: 'Roboscore',
		fontWeight: 'bold'
	},
	{
		value: 'DNO',
		fontWeight: 'bold'
	},
	{
		value: 'Anis',
		fontWeight: 'bold'
	},
	{
		value: 'ASR',
		fontWeight: 'bold'
	},
	{
		value: 'Aloc',
		fontWeight: 'bold'
	},
	{
		value: 'PDD',
		fontWeight: 'bold'
	},
	{
		value: 'Roboscore',
		fontWeight: 'bold'
	},
	{
		value: 'DNO',
		fontWeight: 'bold'
	},
	{
		value: 'Anis',
		fontWeight: 'bold'
	},
	{
		value: 'ASR',
		fontWeight: 'bold'
	},
	{
		value: 'Aloc',
		fontWeight: 'bold'
	},
	{
		value: 'PDD',
		fontWeight: 'bold'
	},
	{
		value: 'Roboscore',
		fontWeight: 'bold'
	},
	{
		value: 'DNO',
		fontWeight: 'bold'
	},
	{
		value: 'Anis',
		fontWeight: 'bold'
	}
];

export const networkFirstColumns = [
	{
		value: 'Account',
		fontWeight: 'bold'
	},
	{
		value: `${monthNameMap.get('month0Value')}`,
		fontWeight: 'bold',
		span: 6
	},
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month1Value')}`,
		fontWeight: 'bold',
		span: 6
	},
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month2Value')}`,
		fontWeight: 'bold',
		span: 6
	},
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month3Value')}`,
		fontWeight: 'bold',
		span: 6
	},
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month4Value')}`,
		fontWeight: 'bold',
		span: 6
	},
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month5Value')}`,
		fontWeight: 'bold',
		span: 6
	},
	null,
	null,
	null,
	null,
	null,
	{
		value: `${monthNameMap.get('month6Value')}`,
		fontWeight: 'bold',
		span: 6
	},
	null,
	null,
	null,
	null,
	null
];

export const generateFirstRow = (additionalColumns: any[], tabs: any[]) => {
	let header: any;
	if (!isEmpty(additionalColumns)) {
		header = additionalColumns.map((column: any) => ({
			value: column.label,
			fontWeight: 'bold'
		}));
	} else {
		header = [{ value: 'Account', fontWeight: 'bold' }];
	}
	for (let month = 0; month < 7; month++) {
		header.push({
			value: `${monthNameMap.get(`month${month}Value`)}`,
			fontWeight: 'bold',
			span: tabs.length
		});
		for (let i = 0; i < tabs.length - 1; i++) {
			header.push(null);
		}
	}
	return header;
};

export const generateSecondRow = (
	additionalColumns: string | any[],
	tabs: any[]
) => {
	const header = new Array(additionalColumns?.length || 1).fill(null);

	for (let month = 0; month < 7; month++) {
		tabs.forEach((tab: any) => {
			header.push({ value: tab, fontWeight: 'bold' });
		});
	}

	return header;
};
