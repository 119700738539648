import { PortingStatusIndexResponse } from 'RtModels';
import { PortingStatusResource } from 'RtUi/app/rtCommon/ServiceNumbers/lib/resources/PortingStatusResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface IPortingStatusSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<PortingStatusIndexResponse, IsMulti> {}
interface IPortingStatusSelectState
	extends ISelectFormControlState<PortingStatusIndexResponse> {}

export class PortingStatusSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	PortingStatusIndexResponse,
	IsMulti,
	IPortingStatusSelectProps<IsMulti>,
	IPortingStatusSelectState
> {
	public resourceClass = PortingStatusResource;
	public state: IPortingStatusSelectState = {
		formLabel: 'Porting Status',
		valueKey: 'portingStatusId',
		labelKey: 'label'
	};
}
