import { RtxSipApiRoutes } from 'RtExports/routes';
import { ActivePortDetailIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchActivePortsSummary = async (): Promise<
	FullResponse<ActivePortDetailIndexResponse[]>
> => {
	return handleGetRequest<ActivePortDetailIndexResponse[], true>(
		RtxSipApiRoutes.ActivePorts.Index,
		{
			includeFullResponse: true
		}
	);
};

export const useGetActivePortsSummary = () => {
	return useQuery<FullResponse<ActivePortDetailIndexResponse[]>, Error>(
		[`useGetActivePortsSummary`],
		() => fetchActivePortsSummary(),
		{ enabled: true }
	);
};
