import { RtVueApiRoutes } from 'RtExports/routes';
import { GuardianReconciliationTopOpenItemsIndexResponse } from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchTopDisputeItemsByPerson = async (
	queryParams?: any
): Promise<GuardianReconciliationTopOpenItemsIndexResponse[]> => {
	const data = await handleGetRequest(
		RtVueApiRoutes.GuardianReconciliationTopOpenItems.Index,
		{
			queryParams
		}
	);

	return data;
};

export const useGetTopDisputeItemsByPerson = (queryParams?: any) => {
	const cacheKey = JSON.stringify(queryParams);

	return useQuery<
		GuardianReconciliationTopOpenItemsIndexResponse[] | undefined,
		Error
	>(
		[`useGetTopDisputeItemsByPerson${cacheKey}`, cacheKey],
		() => fetchTopDisputeItemsByPerson(queryParams),
		{ enabled: true }
	);
};
