import { RtUiModule } from '../@RtUi/RtUiDecorators';
import { CarrierConnectResourceIndexContainer } from './Resources/CarrierConnectResource.index';
import { CarrierConnectResourceProfileContainer } from './Resources/CarrierConnectResource.profile';
import { CarrierConnectResourceRouter } from './Resources/CarrierConnectResource.router';
import { RtCarrierConnectModuleRouter } from './RtCarrierConnect.router';
import { JobIndexContainer } from 'RtUi/app/rtCarrierConnect/Jobs/Job.index';
import { JobRouter } from 'RtUi/app/rtCarrierConnect/Jobs/Job.router';
import { JobProfileContainer } from 'RtUi/app/rtCarrierConnect/Jobs/Job.profile';
import { RtCarrierNumberIndexContainer } from 'RtUi/app/rtCarrierConnect/Numbers/RtCarrierNumber.index';
import { RtCarrierNumberProfileContainer } from 'RtUi/app/rtCarrierConnect/Numbers/RtCarrierNumber.profile';
import { RtCarrierNumberRouter } from 'RtUi/app/rtCarrierConnect/Numbers/RtCarrierNumber.router';
import { CarrierConnectResourceConfigurationProfileContainer } from 'RtUi/app/rtCarrierConnect/ResourceConfigurations/CarrierConnectResourceConfigurations.profile';

@RtUiModule({
	route: {
		path: `/${RtCarrierConnectModuleRouter.SectionKey}`,
		name: 'RT/800 Carrier Connect',
		iconClass: 'fa-network-wired'
	},
	controllers: [
		CarrierConnectResourceIndexContainer,
		CarrierConnectResourceConfigurationProfileContainer,
		CarrierConnectResourceProfileContainer,
		JobIndexContainer,
		JobProfileContainer,
		RtCarrierNumberIndexContainer,
		RtCarrierNumberProfileContainer
	],
	routers: [CarrierConnectResourceRouter, JobRouter, RtCarrierNumberRouter]
})
export class RtCarrierConnectModule {}
