import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { LergIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

export const getLergSomosColumns = (): Array<
	DataGridColumn<LergIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'lata',
		header: 'Lata'
	}),
	DefaultDataGridColumn({
		accessorKey: 'npa',
		header: 'NANP'
	}),
	DefaultDataGridColumn({
		accessorKey: 'nxx',
		header: 'NXX'
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'Op Company Number'
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocnCompany',
		header: 'Op Company Name'
	})
];
