import { RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { AlertHistoryIndexContainer } from 'RtUi/app/rtGuardian/AlertHistory/AlertHistory.index';
import { AlertHistoryProfileContainer } from 'RtUi/app/rtGuardian/AlertHistory/AlertHistory.profile';
import { AlertHistoryRouter } from 'RtUi/app/rtGuardian/AlertHistory/AlertHistory.router';
import { AlertRulesIndexContainer } from 'RtUi/app/rtGuardian/AlertRules/AlertRules.index';
import { AlertRulesProfileContainer } from 'RtUi/app/rtGuardian/AlertRules/AlertRules.profile';
import { AlertRulesRouter } from 'RtUi/app/rtGuardian/AlertRules/AlertRules.router';
import { FraudConfigurationIndexContainer } from 'RtUi/app/rtGuardian/FraudConfiguration/FraudConfiguration.index';
import { FraudConfigurationRouter } from 'RtUi/app/rtGuardian/FraudConfiguration/FraudConfiguration.router';
import { FraudSimulatorIndexContainer } from 'RtUi/app/rtGuardian/FraudSimulator/FraudSimulator.index';
import { FraudSimulatorRouter } from 'RtUi/app/rtGuardian/FraudSimulator/FraudSimulator.router';
import { GuardianApplicationRouter } from 'RtUi/app/rtGuardian/rtGuardian.router';

@RtUiModule({
	route: {
		path: `/${GuardianApplicationRouter.SectionKey}`,
		name: 'RT/Guardian',
		iconClass: 'fa-shield-alt'
	},
	controllers: [
		AlertRulesIndexContainer,
		AlertRulesProfileContainer,
		AlertHistoryIndexContainer,
		AlertHistoryProfileContainer,
		FraudConfigurationIndexContainer,
		FraudSimulatorIndexContainer
	],
	routers: [
		AlertRulesRouter,
		AlertHistoryRouter,
		FraudConfigurationRouter,
		FraudSimulatorRouter
	]
})
export class RtGuardianModule {}
