/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RateCenterIndexRequest = {
    properties: {
        iso3166Alpha3: {
            type: 'string',
        },
        search: {
            type: 'string',
        },
    },
};