/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-classes-per-file */
import {
	IIntegrationConfig,
	IIntegrationConfigs
} from 'RtUi/app/rtCommon/Integrations/resources/Integrations';
import { IntegrationResource } from 'RtUi/app/rtCommon/Integrations/resources/IntegrationResource';
import { DidNumberRouter } from 'RtUi/app/rtDid/Numbers/DidNumber.router';
import {
	DidNumbersResource,
	IDidNumberProfile
} from 'RtUi/app/rtDid/Numbers/lib/resources/DidNumbersResource';
import { IDidResourceProfile } from 'RtUi/app/rtDid/Resources/lib/resources/DidResourceResource';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { DidNumberEditor } from 'RtUi/app/rtDid/Numbers/lib/forms/DidNumberEditor';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';

interface IDidNumberProfileContainerProps {}

interface IDidNumberProfileContainerState {
	activeTab: string;
	didNumberId: string;
	didNumberProfile?: IDidNumberProfile;
	vendor?: IDidResourceProfile;
	integrationConfigs?: IIntegrationConfigs;
	currentConfig?: IIntegrationConfig;
}

export abstract class DidNumberProfileContainerClass extends ProfileApplicationContainer<
	IDidNumberProfileContainerProps,
	IDidNumberProfileContainerState
> {
	protected router: RtUiRouter = DidNumberRouter;

	public Tabs = this.router.getProfileTabs();
	public integrationResource = new IntegrationResource();

	public state: IDidNumberProfileContainerState = {
		activeTab: this.Tabs.Profile.header,
		didNumberId: ''
	};

	public async componentDidMount() {
		const didNumberId = this.getIdParam();
		const didNumbersResource = new DidNumbersResource();
		const didNumberProfile = await didNumbersResource.get(didNumberId);

		this.setState({ didNumberId, didNumberProfile });
	}

	public render() {
		const { didNumberProfile } = this.state;

		if (!didNumberProfile) {
			return <Loading />;
		}

		return (
			<TabbedLayout<IDidNumberProfile>
				router={this.router}
				profile={didNumberProfile}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<DidNumberEditor
						editMode={didNumberProfile}
						onUpdate={(didNumberProfile) => this.setState({ didNumberProfile })}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}

@DidNumberRouter.getProfileRtUiController()
export class DidNumberProfileContainer extends DidNumberProfileContainerClass {}
