import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { FlowIndexResponse } from 'RtModels';
import { RtxFlowApiRoutes } from 'RtExports/routes';
import { RtAdminRouter } from 'RtUi/app/rtAdmin/rtAdmin.router';

interface IFlowProfileContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Steps: TabbedLayoutTabProps;
}

export const FlowProfileContainerTabs: IFlowProfileContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Steps: {
		header: 'Steps'
	}
};

export class FlowRouterClass extends RtAdminRouter<
	FlowIndexResponse,
	FlowIndexResponse,
	IFlowProfileContainerTabs
> {
	constructor() {
		super('Flow', 'flows', 'flowId', FlowProfileContainerTabs);

		this.setPermissionsFromApiRoute(RtxFlowApiRoutes.Flows);
	}

	/**
	 * @override
	 * @param record
	 */
	public getIndexLabel(record: FlowIndexResponse) {
		return record.label;
	}

	/**
	 * @override
	 * @param record
	 */
	public getProfileLabel(profile: FlowIndexResponse) {
		return profile.label;
	}
}

export const FlowRouter = new FlowRouterClass();
