import { union } from 'lodash-es';
import * as React from 'react';
import {
	Alert,
	Button,
	ButtonGroup,
	Form,
	Modal,
	Table
} from 'react-bootstrap';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from 'RtUi/components/form/FormControl';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { FileInputButton } from 'RtUi/components/ui/FileInputButton';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import {
	AvailableAreaCodes,
	TollFreeNumbersUtils
} from '../utils/TollFreeNumbersUtils';

const SampleTFNS = [
	['800-200-0167', 'Working', '11/12/2020 09:00am', '11/12/2020 09:11am'],
	['800-200-0301', 'Working', '12/17/2019 02:30pm', '11/01/2020 04:33pm'],
	['800-200-2237', 'Working', '06/07/2019 08:30am', '11/03/2020 10:05am'],
	['800-200-2590', 'Working', '11/03/2020 10:00am', '03/12/2020 12:11am'],
	['800-200-2740', 'Working', '11/19/1993 02:15pm', '03/12/2020 12:11am'],
	['800-200-2888', 'Working', '03/25/1996 12:45pm', '01/11/2021 03:05pm'],
	['800-200-3078', 'Working', '01/11/2021 03:00pm', '03/12/2020 12:11am'],
	['800-200-4199', 'Working', '09/20/1993 01:15pm', '03/12/2020 12:11am']
];

interface ITollFreeNumbersTextAreaProps
	extends IFormControlProps<string, true> {
	loading?: boolean;
	allowForUpload?: boolean;
	showOnlyTfnExamples?: boolean;
	maxLimit?: number;
}

interface ITollFreeNumbersTextAreaState extends IFormControlState {
	input: string;
	isReachedLimit: boolean;
	isSamplesModalOpen: boolean;
}
export class TollFreeNumbersTextArea extends FormControl<
	string,
	true,
	ITollFreeNumbersTextAreaProps,
	ITollFreeNumbersTextAreaState
> {
	public resource = undefined;
	public state: ITollFreeNumbersTextAreaState = {
		input: '',
		formLabel: 'Toll-Free Numbers',
		isReachedLimit: false,
		isSamplesModalOpen: false
	};

	public inputRef = React.createRef<HTMLInputElement>();
	public deleteWasHit = false;
	public fileUtils = new FileUtils();
	public tollFreeNumbersUtils = new TollFreeNumbersUtils();

	public onListClick() {
		if (this.inputRef && this.inputRef.current) {
			this.inputRef.current.focus();
		}
	}

	public handleInputChange(evt: React.ChangeEvent<HTMLInputElement>) {
		const inputValue = evt.target.value;
		const currentInput = this.state.input;
		const lastCharWasDash =
			currentInput.lastIndexOf('-') === currentInput.length - 1;
		let input = TollFreeNumbersUtils.tollFreeNumberToReadableString(inputValue);

		if (this.deleteWasHit) {
			this.deleteWasHit = false;

			if (lastCharWasDash) {
				input = input.substr(0, input.length - 1);
			}
		}

		this.resetLimit();

		if (TollFreeNumbersUtils.isValid(input)) {
			this.addItem(input);
		} else {
			this.setState({ input });
		}
	}

	public resetLimit() {
		if (this.state.isReachedLimit) {
			this.setState({ isReachedLimit: false });
		}
	}

	public addItem(addItem: string[] | string, resetInput = true) {
		const { value = [] } = this.props;

		if (typeof addItem === 'string') {
			addItem = [addItem];
		}

		if (
			this.props.maxLimit &&
			value.length + addItem.length > this.props.maxLimit
		) {
			this.setState({ isReachedLimit: true });
			return;
		}

		//Remove dashes
		addItem = addItem.map((tfn) =>
			TollFreeNumbersUtils.tollFreeNumberToReadableString(tfn, false, true)
		);
		const newTfns = union(value, addItem);

		if (newTfns.length !== value.length) {
			this.updateConsumer(newTfns);
		}

		if (resetInput) {
			this.setState({ input: '' });
		}
	}

	public removeLastItem() {
		const { value = [] } = this.props;
		const valueCopy = [...value];

		valueCopy.pop();

		this.updateConsumer(valueCopy);
	}

	public handleRemoveItem(index: number) {
		const { value = [] } = this.props;
		const valueCopy = [...value];

		valueCopy.splice(index, 1);

		this.updateConsumer(valueCopy);
	}

	public clearAll() {
		this.setState({ input: '' });

		this.updateConsumer([]);
	}

	public updateConsumer(newValues: string[]) {
		const { onChange = () => ({}) } = this.props;
		const formattedNewValues = newValues.map((v) => v.replace(/\-/g, ''));
		this.resetLimit();
		onChange(formattedNewValues);
	}

	public cancelSubmit(evt: React.KeyboardEvent<HTMLInputElement>) {
		const { value: inputValue } = evt.currentTarget;

		if (evt.key === 'Enter') {
			evt.preventDefault();
		} else if (evt.key === 'Backspace') {
			if (inputValue.length === 0) {
				this.removeLastItem();
				return;
			} else {
				this.deleteWasHit = true;
			}
		}
	}

	public onPaste(evt: React.ClipboardEvent<HTMLInputElement>) {
		const text = evt.clipboardData.getData('text');
		const validTfns =
			this.tollFreeNumbersUtils.filterListOfPossibleTollFreeNumbers(text);

		this.resetLimit();
		this.addItem(validTfns, false);

		evt.preventDefault();
	}

	public processFile(file: File | null) {
		if (!file) {
			return;
		}

		this.resetLimit();
		this.fileUtils
			.spreadsheetFileToJson(file, true)
			.then((spreadsheet) => {
				const validTfns: string[] = [];
				for (const row of spreadsheet) {
					for (const cell of row) {
						const possibleTfn =
							TollFreeNumbersUtils.tollFreeNumberToReadableString(
								cell,
								false,
								true
							);
						if (TollFreeNumbersUtils.isValid(possibleTfn)) {
							validTfns.push(possibleTfn);
						}
					}
				}

				this.addItem(validTfns, false);
			})
			.catch((err: any) => {
				console.error('err:', err);
			});
	}

	public render() {
		const {
			value = [],
			required,
			className = '',
			name,
			displayMode,
			allowForUpload = false
		} = this.props;
		const { formLabel } = this.state;
		const valueLabel = value ? value.join(', ') : '';
		const tfnLength = value.length;
		const isOverThousand = tfnLength > 1000;
		const [firstTfn = ''] = value;
		const overThousandText = `${TollFreeNumbersUtils.tollFreeNumberToReadableString(
			firstTfn
		)} and ${(tfnLength - 1).toLocaleString()} more numbers have been entered.`;

		return (
			<ControlGroup
				label={formLabel}
				value={valueLabel}
				displayMode={displayMode}
				required={this.props.required}
				sublabel={
					this.props.maxLimit ? `max ${this.props.maxLimit}` : undefined
				}
				errorText={
					this.state.isReachedLimit
						? `Warning: Maximum limit of ${this.props.maxLimit} is reached.`
						: ''
				}
			>
				<section style={{ position: 'relative' }}>
					<section
						style={{
							position: 'absolute',
							bottom: '100%',
							right: 0
						}}
					>
						<ButtonGroup>
							{allowForUpload && (
								<>
									<FileInputButton
										size="sm"
										color="white"
										outline
										accept={FileUtils.AcceptTypes.Spreadsheet}
										onChange={(fileBlob) => this.processFile(fileBlob)}
									>
										<i className="fas fa-fw fa-upload" />
										<span>&nbsp;Upload Numbers</span>
									</FileInputButton>
									<Button
										size="sm"
										hidden={tfnLength !== 0}
										variant="link"
										className="ps-0"
										onClick={() => {
											this.setState({ isSamplesModalOpen: true });
										}}
									>
										<span className="text-muted">(Example Upload)</span>
									</Button>
								</>
							)}
							<Button
								hidden={tfnLength === 0}
								size="sm"
								variant="outline-white"
								type="button"
								tabIndex={-1}
								onClick={() => this.clearAll()}
							>
								<i className="fas fa-fw fa-times-circle" />
								<span>&nbsp;Remove All ({value.length})</span>
							</Button>
							{this.props.loading && (
								<Button size="sm" variant="outline-white" type="button">
									<i className="fas fa-fw fa-cog fa-spin" />
									<span>&nbsp;Loading...</span>
								</Button>
							)}
						</ButtonGroup>
					</section>
					{isOverThousand && (
						<Form.Control disabled type="text" value={overThousandText} />
					)}
					{!isOverThousand && (
						<ul
							className={`tollfree-numbers-textarea ${className} ${
								this.state.isReachedLimit ? 'is-invalid' : ''
							}`}
							onClick={() => this.onListClick()}
						>
							{value.map((item, i) => (
								<li key={item + i} onClick={() => this.handleRemoveItem(i)}>
									{TollFreeNumbersUtils.tollFreeNumberToReadableString(item)}
								</li>
							))}
							<li className="tollfree-numbers-textarea-input">
								<input
									type="text"
									ref={this.inputRef}
									value={this.state.input}
									onKeyDown={(e) => this.cancelSubmit(e)}
									onChange={(e) => this.handleInputChange(e)}
									onPaste={(e) => this.onPaste(e)}
									name={name}
									required={required && value.length === 0}
									minLength={12}
								/>
							</li>
						</ul>
					)}
				</section>
				<Modal
					size="lg"
					show={this.state.isSamplesModalOpen}
					onHide={() => {
						this.setState({ isSamplesModalOpen: false });
					}}
				>
					<Modal.Body className="bg-light rounded">
						<div className="d-flex justify-content-end mb-2">
							<Button
								variant="light"
								onClick={() => {
									this.setState({ isSamplesModalOpen: false });
								}}
							>
								<i className="fas fa-times fa-fw fa-lg" />
							</Button>
						</div>
						<Alert variant="info">
							Uploads must be in <strong>Excel</strong> or <strong>CSV</strong>{' '}
							format. All Toll-Free Numbers should be in a{' '}
							<strong>single column</strong> for best results. Additionally,
							make sure that all numbers start with one of the following NPAs:
							<em>{' ' + AvailableAreaCodes.join(', ')}</em>.
						</Alert>
						<CollapsibleCard
							header="Example of Toll Free numbers"
							className="mb-3"
						>
							<Table size="sm" bordered className="bg-white" responsive>
								<thead>
									<tr>
										<th>Toll-Free Number</th>
										{!this.props.showOnlyTfnExamples && (
											<>
												<th>Status</th>
												<th>Status Time</th>
												<th>Last Update</th>
											</>
										)}
									</tr>
								</thead>
								<tbody>
									{SampleTFNS.map((values, index) => (
										<tr key={index}>
											{(this.props.showOnlyTfnExamples
												? [values[0]]
												: values
											).map((value, innerIndex) => (
												<td className="text-monospace" key={innerIndex}>
													{value}
												</td>
											))}
										</tr>
									))}
								</tbody>
							</Table>
						</CollapsibleCard>
					</Modal.Body>
				</Modal>
			</ControlGroup>
		);
	}
}
