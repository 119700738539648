import { Rt800ApiRoutes } from 'RtExports/routes';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { AdministrationRouter } from 'RtUi/app/Administration/Administration.router';
import { CicIndexResponse } from 'RtModels';

interface ICicLookupContainerTabs extends IProfileApplicationContainerTabs {}

export const CicLookupContainerTabs: ICicLookupContainerTabs = {};

class CicLookupRouterClass extends AdministrationRouter<
	CicIndexResponse,
	CicIndexResponse,
	ICicLookupContainerTabs
> {
	constructor() {
		super('CIC Look up', 'cicLookup', 'cic', CicLookupContainerTabs);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.Cics);
		this.setProfilePermissions(null);
	}

	public getProfileLabel(profile: CicIndexResponse) {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const CicLookupRouter = new CicLookupRouterClass();
