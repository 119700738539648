import {
	SipConnection,
	SipConnectionUpdateRequest,
	SipConnectionProfileRequest,
	SipConnectionCreateRequest
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class SipConnectionResource extends HttpResource<
	SipConnection,
	SipConnection
> {
	constructor() {
		super(RtxSipApiRoutes.Connections);
	}

	public update(
		connectionId: number,
		sipConnection: SipConnectionUpdateRequest
	) {
		const urlParams: SipConnectionProfileRequest = { connectionId };
		const body = JSON.stringify(sipConnection);

		return this.fetchWithRoute<SipConnection>(
			RtxSipApiRoutes.Connections.Update,
			{ body, urlParams }
		);
	}

	public create(sipConnection: SipConnectionCreateRequest) {
		const body = JSON.stringify(sipConnection);
		return this.fetchWithRoute<SipConnection>(
			RtxSipApiRoutes.Connections.Create,
			{ body }
		);
	}
}
