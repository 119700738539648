import { LcrTaskIndexResponse, LcrTaskResultIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CostDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CostDataGridColumn';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { LcrCarriersDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LcrCarriersDataGridColumn';
import { LcrScenariosDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LcrScenariosDataGridColumn';
import { MinutesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MinutesDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getLcrTasksColumns = (): Array<
	DataGridColumn<LcrTaskIndexResponse>
> => [
	IdDataGridColumn({
		header: 'LCR Task',
		accessorKey: 'lcrTaskId'
	}),
	LabelDataGridColumn({
		accessorKey: 'taskLabel'
	}),
	...RespOrgColumnsBuilder<LcrTaskIndexResponse>({
		header: 'RespOrg',
		accessorKey: 'respOrgId',
		isHidden: true
	}),
	DefaultDataGridColumn({
		header: 'Template Name',
		accessorKey: 'templateName'
	}),
	IntegerDataGridColumn({
		header: 'Minute Volume',
		accessorKey: 'winningMinutes'
	}),
	DefaultDataGridColumn({
		accessorKey: 'winningDefaultCic',
		header: 'Win CIC'
	}),
	CostDataGridColumn({
		accessorKey: 'winningEstimatedCost',
		header: 'Win Est. Cost'
	}),
	CostDataGridColumn({
		accessorKey: 'winningNonLcrCharges',
		header: 'Win Non LCR Cost'
	}),
	TimeStampDataGridColumn({
		header: 'Completed',
		accessorKey: 'completedAt'
	}),
	PrecisionDataGridColumn({
		header: 'Skip Savings Limit',
		accessorKey: 'skipSavingsLimit'
	}),
	IntegerDataGridColumn({
		header: 'CPR Size Limit',
		accessorKey: 'cprSizeLimit',
		isHidden: true
	}),
	IntegerDataGridColumn({
		header: 'CPR Row Limit',
		accessorKey: 'cprRowLimit',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		header: 'Created',
		accessorKey: 'createdAt',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		header: 'Started',
		accessorKey: 'startedAt',
		isHidden: true
	}),
	DefaultDataGridColumn({ header: 'Email', accessorKey: 'email' })
];

export const getLcrTasksResultsColumns = (): Array<
	DataGridColumn<LcrTaskResultIndexResponse>
> => [
	LcrScenariosDataGridColumn({
		header: 'Lcr Scenario',
		accessorKey: 'lcrScenarioId'
	}),
	LcrCarriersDataGridColumn({
		header: 'Default Account',
		accessorKey: 'lcrCarrierId'
	}),
	DefaultDataGridColumn({
		header: 'Default CIC',
		accessorKey: 'defaultCic',
		align: DataGridColumnAlignment.CENTER
	}),
	DefaultDataGridColumn({
		header: 'Canada CIC',
		accessorKey: 'canadaCic',
		align: DataGridColumnAlignment.CENTER
	}),
	MinutesDataGridColumn({
		header: 'Total Minutes',
		accessorKey: 'totalMinutes'
	}),
	PrecisionDataGridColumn({
		header: 'Total Charges',
		accessorKey: 'totalCharges'
	}),
	PrecisionDataGridColumn({
		header: 'Total CPM',
		decimals: 6,
		accessorFn: (record) =>
			Number(record.totalMinutes) > 0
				? Number(record.totalCharges) / Number(record.totalMinutes)
				: 0
	}),
	IntegerDataGridColumn({
		header: 'Non LCR Minutes',
		accessorKey: 'nonLcrMinutes',
		isHidden: true
	}),
	IntegerDataGridColumn({
		header: 'Non LCR Charges',
		accessorKey: 'nonLcrCharges',
		isHidden: true
	}),
	PrecisionDataGridColumn({
		header: 'Non LCR CPM',
		decimals: 6,
		accessorFn: (record) =>
			Number(record.nonLcrMinutes) > 0
				? Number(record.nonLcrCharges) / Number(record.nonLcrMinutes)
				: 0
	}),
	IntegerDataGridColumn({
		header: 'Known Minutes',
		accessorKey: 'minutes',
		isHidden: true
	}),
	PrecisionDataGridColumn({
		header: 'Known Charges',
		accessorKey: 'charges',
		isHidden: true
	}),
	PrecisionDataGridColumn({
		header: 'Known CPM',
		decimals: 6,
		accessorFn: (record) =>
			record.minutes !== 0
				? (Number(record.charges) / Number(record.minutes)).toFixed(6)
				: 0
	}),
	IntegerDataGridColumn({
		header: 'Unknown Minutes',
		accessorKey: 'unknownMinutes',
		isHidden: true
	}),
	IntegerDataGridColumn({
		header: 'Unknown Charges',
		accessorKey: 'unknownCharges',
		isHidden: true
	}),
	PrecisionDataGridColumn({
		header: 'Unknown CPM',
		decimals: 6,
		accessorFn: (record) =>
			Number(record.unknownMinutes) > 0
				? Number(record.unknownCharges) / Number(record.unknownMinutes)
				: 0
	}),
	IntegerDataGridColumn({
		header: 'Canada Minutes',
		accessorKey: 'canadaMinutes',
		isHidden: true
	}),
	IntegerDataGridColumn({
		header: 'Canada Charges',
		accessorKey: 'canadaCharges',
		isHidden: true
	}),
	PrecisionDataGridColumn({
		header: 'Canada CPM',
		decimals: 6,
		accessorFn: (record) =>
			record.canadaMinutes !== 0
				? Number(record.canadaCharges) / Number(record.canadaMinutes)
				: 0
	})
];
