/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ReconciliationSummaryIndexResponse = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        ratedCallCount: {
            type: 'number',
            isRequired: true,
        },
        ratedDuration: {
            type: 'number',
            isRequired: true,
        },
        ratedRevenue: {
            type: 'number',
            isRequired: true,
        },
        ratedCost: {
            type: 'number',
            isRequired: true,
        },
        ratedMargin: {
            type: 'number',
            isRequired: true,
        },
        costedCallCount: {
            type: 'number',
            isRequired: true,
        },
        costedDuration: {
            type: 'number',
            isRequired: true,
        },
        costedRevenue: {
            type: 'number',
            isRequired: true,
        },
        costedCost: {
            type: 'number',
            isRequired: true,
        },
        costedMargin: {
            type: 'number',
            isRequired: true,
        },
        rawCallCount: {
            type: 'number',
            isRequired: true,
        },
        rawDuration: {
            type: 'number',
            isRequired: true,
        },
        rawRevenue: {
            type: 'number',
            isRequired: true,
        },
        rawCost: {
            type: 'number',
            isRequired: true,
        },
        rawMargin: {
            type: 'number',
            isRequired: true,
        },
    },
};