import { ServiceIndexResponse } from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { RtxSipApiRoutes } from 'RtExports/routes';

export enum ServiceTypeLookup {
	Egress = 'egress',
	Ingress = 'ingress',
	IngressOrEgress = 'either',
	None = 'none'
}

export class ServiceResource extends ArrayResource<ServiceIndexResponse> {
	constructor() {
		super('serviceId');

		this.setApiRouteForGetAll(RtxSipApiRoutes.Services.Index);
	}

	public async getAllOfConnectionType(lookup: ServiceTypeLookup) {
		const allRecords = await this.getAllWithoutCache();
		const filteredRecords: ServiceIndexResponse[] = [];

		for (const record of allRecords) {
			const hasEgress = record.hasEgressConnections === 1;
			const hasIngress = record.hasIngressConnections === 1;
			const hasEither = hasEgress || hasIngress;
			const hasNone = !hasEither;

			if (lookup === ServiceTypeLookup.Egress && hasEgress) {
				filteredRecords.push(record);
				continue;
			}

			if (lookup === ServiceTypeLookup.Ingress && hasIngress) {
				filteredRecords.push(record);
				continue;
			}

			if (lookup === ServiceTypeLookup.IngressOrEgress && hasEither) {
				filteredRecords.push(record);
				continue;
			}

			if (lookup === ServiceTypeLookup.None && hasNone) {
				filteredRecords.push(record);
				continue;
			}
		}

		return filteredRecords;
	}
}
