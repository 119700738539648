/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $Lerg6IndexRequest = {
    properties: {
        id: {
            type: 'number',
        },
        lata: {
            type: 'string',
        },
        lataName: {
            type: 'string',
        },
        npa: {
            type: 'string',
        },
        nxx: {
            type: 'string',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        npanxx: {
            type: 'string',
        },
    },
};