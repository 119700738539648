/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileProcessProfileResponse = {
    properties: {
        fileProcessId: {
            type: 'number',
            isRequired: true,
        },
        fileProcessTypeId: {
            type: 'FileProcessorType',
            isRequired: true,
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        inputFileNameLocal: {
            type: 'string',
            isRequired: true,
        },
        inputFileNameRemote: {
            type: 'string',
            isRequired: true,
        },
        outputFileNameRemote: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        completedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
    },
};