/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CprLergIndexRequest = {
    properties: {
        cprNodeTypeId: {
            type: 'CprNodeType',
        },
        worldValue: {
            type: 'string',
        },
        somosValue: {
            type: 'string',
        },
        pointId: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        lata: {
            type: 'string',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};