import { FileTypeIndexResponse } from 'RtModels';
import { FileTypesResource } from 'RtUi/app/rtVue/FileTypes/lib/resource/FileTypeResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface IFileTypeSelectProps
	extends ISelectFormControlProps<FileTypeIndexResponse> {}

export class FileTypeSelect extends SelectFormControl<
	FileTypeIndexResponse,
	false,
	IFileTypeSelectProps
> {
	public static defaultProps = {
		sortOptionsFn: (a: FileTypeIndexResponse, b: FileTypeIndexResponse) =>
			a.description.localeCompare(b.description)
	};

	public resourceClass = FileTypesResource;
	public state: ISelectFormControlState<FileTypeIndexResponse> = {
		formLabel: 'File Type',
		valueKey: 'fileTypeId',
		labelKey: 'description'
	};
}
