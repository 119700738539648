import * as FileSaver from 'file-saver';
import * as FileType from 'file-type/browser';
import { SpreadsheetWebWorkerFacade } from 'RtUi/workers/lib/spreadsheet/SpreadsheetWebWorkerFacade';
import { ITabbedSpreadsheet } from 'RtUi/workers/lib/spreadsheet/interfaces';

export class FileUtils {
	public static AcceptTypes = {
		Spreadsheet:
			'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv',
		Csv: 'text/csv, .csv'
	};

	public static isCvs(file: File) {
		return /^[^.]+.csv$/.test(file.name);
	}

	public rtWorkerFacadeInstance = SpreadsheetWebWorkerFacade.getInstance();

	public fileToTabbedSpreadsheet<
		T extends ITabbedSpreadsheet = ITabbedSpreadsheet
	>(file: File): Promise<T> {
		return this.rtWorkerFacadeInstance.fileToTabbedSpreadsheet<T>(file);
	}

	public fileToSpreadsheet(
		file: File,
		shouldHaveEmptyHeader = false
	): Promise<string[][]> {
		if (FileUtils.isCvs(file)) {
			return this.rtWorkerFacadeInstance.csvToArray(
				file,
				shouldHaveEmptyHeader
			);
		}

		return this.rtWorkerFacadeInstance.fileToSpreadsheet(
			file,
			shouldHaveEmptyHeader
		);
	}

	public csvToArray(file: File, shouldHaveEmptyHeader = false) {
		return this.rtWorkerFacadeInstance.csvToArray(file, shouldHaveEmptyHeader);
	}

	public dataToCSVString(
		data: Array<Record<string, string | number>>,
		fieldSeparator?: string | undefined
	) {
		if (data.length <= 0) {
			return Promise.resolve('');
		}

		return this.rtWorkerFacadeInstance.dataToCSVString(data, fieldSeparator);
	}

	public saveSpreadsheet(
		tabbedSpreadsheet: ITabbedSpreadsheet,
		fileName: string
	) {
		return this.rtWorkerFacadeInstance.saveSpreadsheet(
			tabbedSpreadsheet,
			fileName
		);
	}

	/**
	 * Given a row from fileToTabbedSpreadsheet, retrieve the empty cells
	 * that do not have corresponding headers
	 * @param row
	 */
	public getEmptyCellValuesFrom(row: any): string[] {
		const emptyCells: string[] = [];
		const isEmptyCellByColumnName = (columnName: string) => {
			return columnName.toLowerCase().startsWith('__empty');
		};

		if (typeof row === 'object') {
			for (const columnName in row) {
				if (columnName in row) {
					if (isEmptyCellByColumnName(columnName) && row[columnName]) {
						emptyCells.push(String(row[columnName]));
					}
				}
			}
		}

		return emptyCells;
	}

	public async downloadFromUrlWithPromise(
		urlPromise: Promise<{ url: string; fileName?: string }>
	) {
		const { url, fileName } = await urlPromise;
		return FileSaver.saveAs(url, fileName);
	}

	public getBlobFromBase64EncodedContent(
		base64EncodedContent: string,
		mimeType?: string
	): Blob {
		const byteCharacters = window.atob(base64EncodedContent);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: mimeType });

		return blob;
	}

	public downloadBase64EncodedContent(
		base64EncodedContent: string,
		fileName: string,
		mimeType?: string
	) {
		const blob = this.getBlobFromBase64EncodedContent(
			base64EncodedContent,
			mimeType
		);

		FileSaver.saveAs(blob, fileName);
	}

	public getBase64EncodedContentFromBlob(blob: Blob) {
		const reader = new FileReader();

		return new Promise<string>((resolve) => {
			reader.addEventListener(
				'load',
				() => {
					const base64WithMeta = reader.result as string;
					const base64Parts = base64WithMeta.split(',');
					//[0] = 'data:application/pdf;base64'
					//[1] = '<base64 encoded string>'
					const base64Content = base64Parts[1];

					resolve(base64Content);
				},
				false
			);

			reader.readAsDataURL(blob);
		});
	}

	public getContentFromFile(file: File) {
		return this.getBase64EncodedContentFromBlob(file).then((encodedContent) =>
			atob(encodedContent)
		);
	}

	public spreadsheetFileToJson(
		file: File,
		shouldHaveEmptyHeader = false
	): Promise<string[][]> {
		const workerFacade = SpreadsheetWebWorkerFacade.getInstance();

		return workerFacade.fileToSpreadsheet(file, shouldHaveEmptyHeader);
	}

	public createCsvBlob(csvStr: string) {
		const blobParts: BlobPart[] = [];

		// don't create an empty csv with a leading BOM
		if (csvStr.trim() !== '') {
			//cspell:ignore ufeff
			// Prepending 'ufeff' allows excel to read the data as utf-8
			// @see https://stackoverflow.com/a/17879474
			blobParts.push('\ufeff', csvStr);
		}

		return new Blob(blobParts, {
			type: 'text/csv;charset=utf-8'
		});
	}

	public async downloadCSV(data: any[], fileName: string) {
		const workerFacade = SpreadsheetWebWorkerFacade.getInstance();

		await workerFacade.dataToCSVString(data).then((csvStr) => {
			const csvBlob = this.createCsvBlob(csvStr);

			this.saveBlobAs(csvBlob, fileName);
		});
	}

	public saveBlobAs(blob: Blob, fileName?: string) {
		FileSaver.saveAs(blob, fileName);
	}

	/**
	 * @param data
	 */
	public async copyDataToClipboard(data: any[]) {
		const workerFacade = SpreadsheetWebWorkerFacade.getInstance();
		const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

		//Firefox will not allow copying to occur by the time a WebWorker returns the result
		//Until permissions API allows for us to request clipboard access, here's a temp fix
		if (isFirefox) {
			this.dangerouslyCopyDataToClipboardUsingMainThread(data);
			return;
		}

		await workerFacade.dataToCSVString(data, '\t').then((csvStr) => {
			this.copyTextToClipboard(csvStr);
		});
	}

	public getMimeType(file: File) {
		return new Promise<string | undefined>((resolve) => {
			if (file.type && typeof file.type === 'string') {
				return resolve(file.type);
			}

			if (typeof FileReader !== 'undefined') {
				const fileReader = new FileReader();

				fileReader.addEventListener('load', async () => {
					if (fileReader.result instanceof ArrayBuffer) {
						const fileType = await FileType.fromBuffer(fileReader.result);

						if (fileType && fileType.mime) {
							return resolve(fileType.mime);
						}
					}

					resolve(undefined);
				});

				fileReader.readAsArrayBuffer(file);

				return;
			}

			resolve(undefined);
		});
	}

	/**
	 * Is given file one of the mime types args
	 * @param file
	 * @param mimeTypes
	 */
	public async isFileOfMimeType(file: File, ...possibleMimeTypes: string[]) {
		const fileType = await this.getMimeType(file);

		if (!fileType) {
			return false;
		}

		const isCorrectType = possibleMimeTypes.includes(fileType);

		return isCorrectType;
	}

	/**
	 * Copy text to clipboard
	 * @param text
	 */
	public copyTextToClipboard(text: string) {
		const fakeElem = getClipboardElement(text);

		fakeElem.select();

		document.execCommand('copy');
	}

	/**
	 * This method does not user WebWorkers to do a copy. Instead uses
	 * the main thread to do so. This method should not be used unless needed.
	 *
	 * Only use case so far: Firefox does not allow copying unless within a user's click and
	 *      								 a small time frame.
	 *
	 * @note May cause UI halting
	 *
	 * @param data
	 */
	private dangerouslyCopyDataToClipboardUsingMainThread(data: any[]) {
		let csvStr = '';
		const headers = Object.keys(data[0]);
		const addCell = (str: string) => (csvStr += `${str}\t`);
		const addNewLine = () => (csvStr += '\n');

		//Add headers
		for (const header of headers) {
			addCell(header);
		}

		addNewLine();

		//Add rows
		for (const row of data) {
			for (const header of headers) {
				addCell(row[header]);
			}

			addNewLine();
		}

		this.copyTextToClipboard(csvStr);
	}
}

const getClipboardElement = (text: string) => {
	const fakeElementId = 'fakeElementClipboard';
	let fakeElem = document.getElementById(
		fakeElementId
	) as HTMLTextAreaElement | null;

	if (!fakeElem) {
		const isRTL = document.documentElement
			? document.documentElement.getAttribute('dir') === 'rtl'
			: false;
		fakeElem = document.createElement('textarea');
		fakeElem.id = fakeElementId;

		// Prevent zooming on iOS
		fakeElem.style.fontSize = '12pt';
		// Reset box model
		fakeElem.style.border = '0';
		fakeElem.style.padding = '0';
		fakeElem.style.margin = '0';
		// Move element out of screen horizontally
		fakeElem.style.position = 'absolute';
		fakeElem.style[isRTL ? 'right' : 'left'] = '-9999px';
		// Move element to the same position vertically
		const yPosition =
			window.pageYOffset ||
			(document.documentElement ? document.documentElement.scrollTop : 0);
		fakeElem.style.top = `${yPosition}px`;

		fakeElem.setAttribute('readonly', '');

		document.body.appendChild(fakeElem);
	}

	fakeElem.value = text;

	return fakeElem;
};
