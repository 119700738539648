import { Dropdown } from 'react-bootstrap';
import { Component } from 'react';

export type TDataGridStatusValueLabel = {
	value: boolean | null;
	label: string;
};

interface IDataGridStatusDropdownProps {
	value: TDataGridStatusValueLabel;
	onChange: (value: TDataGridStatusValueLabel) => void;
}

export class DataGridStatusDropdown extends Component<
	IDataGridStatusDropdownProps,
	{}
> {
	public static Statuses: TDataGridStatusValueLabel[] = [
		{
			value: null,
			label: 'All'
		},
		{ value: true, label: 'Active' },
		{ value: false, label: 'Inactive' }
	];

	public render() {
		return (
			<Dropdown align="end" className="d-none d-lg-flex btn-group-default">
				<Dropdown.Toggle variant="white">
					<span>
						Status:&nbsp;
						{this.props.value.label}
					</span>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{DataGridStatusDropdown.Statuses.map((statusValueLabel) => (
						<Dropdown.Item
							key={`item_${statusValueLabel.label}`}
							onClick={() => this.props.onChange(statusValueLabel)}
						>
							<span>{statusValueLabel.label}</span>
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}
