import * as moment from 'moment-timezone';
import * as React from 'react';
import { Col, Row, Alert, Card, Form } from 'react-bootstrap';
import {
	MgiCprIndexResponse,
	RoutingCacheTypes,
	TemplateIndexResponse
} from 'RtModels';
import { EffectiveTimeFormControl } from 'RtUi/components/form/EffectiveTimeFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { SubmitFormControl } from 'RtUi/components/form/SubmitFormControl';
import { cprStatusIdToName, timestampToReadable } from 'RtUi/utils/maps';
import { TollFreeNumberFormControl } from '../../../Numbers/lib/controls/TollFreeNumberFormControl';
import {
	INumberProfile,
	NumberResource
} from '../../../Numbers/lib/resources/NumberResource';
import { ITaskProfile } from '../../../Tasks/lib/resources/TaskResource';
import { TemplateSelect } from '../../../Templates/lib/controls/TemplateSelect';
import { TemplateResource } from '../../../Templates/lib/resources/TemplateResource';
import { CloneRoutingEffectiveTimestampSelect } from '../controls/CloneRoutingEffectiveSelect';
import { RtError } from 'RtUi/utils/errors/RtError';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { maxBy } from 'lodash-es';

interface ICloneRoutingActionProps {
	onSuccess?: (taskProfile: ITaskProfile) => void;
}

interface ICloneRoutingActionState {
	//source
	cloneSource: RoutingCacheTypes;
	sourceNumber: string;
	sourceCprs: MgiCprIndexResponse[];
	sourceSelectedCpr?: MgiCprIndexResponse;
	sourceTemplate?: TemplateIndexResponse;
	sourceTollFreeNumber?: INumberProfile;
	sourceDefaultTemplateName?: string;
	//target
	cloneTarget: RoutingCacheTypes;
	targetNumber: string;
	targetTemplate?: TemplateIndexResponse;
	targetCpr?: MgiCprIndexResponse;
	//form
	targetRefCustomValidityMessage: string;
	isLoadingSourceCprs: boolean;
	minEffectiveTs: moment.Moment;
	targetEffectiveTs: moment.Moment | undefined;
	targetEffectiveTsIsNow: boolean;
	isSubmitting: boolean;
	error?: any;
}

export class CloneRoutingAction extends React.Component<
	ICloneRoutingActionProps,
	ICloneRoutingActionState
> {
	public state: ICloneRoutingActionState = {
		cloneSource: RoutingCacheTypes.TollFree,
		cloneTarget: RoutingCacheTypes.TollFree,
		sourceNumber: '',
		sourceCprs: [],
		targetNumber: '',
		isLoadingSourceCprs: false,
		targetRefCustomValidityMessage: '',
		targetEffectiveTs: moment(),
		targetEffectiveTsIsNow: true,
		minEffectiveTs: moment(),
		isSubmitting: false,
		error: undefined
	};

	public numberResource = new NumberResource();
	public templateResource = new TemplateResource();
	public targetRef: HTMLInputElement | null = null;

	public updateCloneSource(cloneSource: RoutingCacheTypes) {
		//Reset From controls
		const sourceNumber = '';
		const sourceTemplate = undefined;
		const sourceCprs: MgiCprIndexResponse[] = [];
		const sourceSelectedCpr = undefined;

		this.setState({
			cloneSource,
			sourceNumber,
			sourceTemplate,
			sourceCprs,
			sourceSelectedCpr
		});
	}

	public updateSourceNumber(sourceNumber: string) {
		const formattedTFN = TollFreeNumbersUtils.tollFreeNumberToReadableString(
			sourceNumber,
			false
		);
		const sourceCprs: MgiCprIndexResponse[] = [];
		const sourceSelectedCpr = undefined;

		this.setState({
			sourceNumber,
			sourceCprs,
			sourceSelectedCpr,
			sourceTollFreeNumber: undefined
		});

		if (formattedTFN.length === 10) {
			this.setState({ isLoadingSourceCprs: true });

			this.numberResource
				.get(formattedTFN)
				.then((sourceTollFreeNumber) => {
					if (!sourceTollFreeNumber) {
						return;
					}

					if (!sourceTollFreeNumber.templateName) {
						return this.numberResource
							.getCprResource(formattedTFN)
							.getAll()
							.then((sourceCprs) => this.setState({ sourceCprs }));
					}

					this.setState({ sourceTollFreeNumber });
				})
				.finally(() => this.setState({ isLoadingSourceCprs: false }));
		}
	}

	public updateSourceTemplate(sourceTemplate?: TemplateIndexResponse) {
		const sourceCprs: MgiCprIndexResponse[] = [];
		const sourceSelectedCpr = undefined;

		this.setState({ sourceTemplate, sourceCprs, sourceSelectedCpr });

		if (sourceTemplate) {
			this.setState({ isLoadingSourceCprs: true });

			this.templateResource
				.getCprResource(sourceTemplate.templateName)
				.getAll()
				.then((sourceCprs) => this.setState({ sourceCprs }))
				.finally(() => this.setState({ isLoadingSourceCprs: false }));
		}
	}

	public moveToSourceTemplateName(sourceDefaultTemplateName: string) {
		const cloneSource = RoutingCacheTypes.Template;
		const sourceTollFreeNumber = undefined;

		this.setState({
			sourceDefaultTemplateName,
			cloneSource,
			sourceTollFreeNumber
		});
	}

	public setTargetRef(ref: HTMLInputElement | null) {
		this.targetRef = ref;

		if (this.targetRef) {
			this.targetRef.setCustomValidity(
				this.state.targetRefCustomValidityMessage
			);
		}
	}

	public setTargetRefCustomValidityMessage(
		targetRefCustomValidityMessage: string
	) {
		this.setState({ targetRefCustomValidityMessage });

		if (this.targetRef) {
			this.targetRef.setCustomValidity(targetRefCustomValidityMessage);
		}
	}

	public updateTargetNumber(targetNumber: string) {
		const formattedTFN = TollFreeNumbersUtils.tollFreeNumberToReadableString(
			targetNumber,
			false
		);

		this.setTargetRefCustomValidityMessage('');

		if (
			formattedTFN.length === 10 &&
			TollFreeNumbersUtils.isValid(targetNumber)
		) {
			this.setTargetRefCustomValidityMessage(
				'Validating CPRs... Press "Submit" once this message disappears.'
			);
			//check if any failed/invalid and you can't use that number/template
			this.getMinEffectiveTsFromTfn(formattedTFN);
		}

		this.setState({ targetNumber });
	}

	public updateTargetTemplate(targetTemplate?: TemplateIndexResponse) {
		this.setState({ targetTemplate });

		this.setTargetRefCustomValidityMessage('');

		if (targetTemplate) {
			this.setTargetRefCustomValidityMessage(
				'Validating CPRs... Press "Submit" once this message disappears.'
			);
			this.getMinEffectiveTsFromTemplate(targetTemplate.templateName);
		}
	}

	public async getMinEffectiveTsFromTfn(tfn: string) {
		const cprResource = this.numberResource.getCprResource(tfn);

		try {
			const cprs = await cprResource.getAll();
			this.setMinEffectiveTsFromArray(cprs);
		} catch (error: any) {
			const errorString: string = error.toString();
			const isForbiddenError = errorString.includes('forbidden');
			let validityMessage =
				'Error Loading Toll-Free Number. Please enter a valid number.';

			if (isForbiddenError) {
				validityMessage = errorString;
			}

			this.setTargetRefCustomValidityMessage(validityMessage);
		}
	}

	public getMinEffectiveTsFromTemplate(templateName: string) {
		const cprResource = this.templateResource.getCprResource(templateName);

		cprResource
			.getAll()
			.then((cprs) => this.setMinEffectiveTsFromArray(cprs))
			.catch(() =>
				this.setTargetRefCustomValidityMessage(
					'Error loading template. Please enter a valid template.'
				)
			);
	}

	public setMinEffectiveTsFromArray(cprs: MgiCprIndexResponse[]) {
		const targetCpr = maxBy(cprs, (cpr) => moment(cpr.effectiveTs).valueOf());

		if (targetCpr) {
			const cprStatusName =
				targetCpr && cprStatusIdToName(targetCpr.cprStatusId);
			const isBadCpr =
				cprStatusName === 'Failed' || cprStatusName === 'Invalid';
			const minEffectiveTs = moment(targetCpr.effectiveTs).floor(15, 'minutes');

			this.setState({ minEffectiveTs, targetCpr });

			let targetRefCustomValidityMessage = '';

			if (isBadCpr) {
				const readableEffectiveTs = timestampToReadable(targetCpr.effectiveTs);
				targetRefCustomValidityMessage = `Latest CPR at ${readableEffectiveTs} is not usable. Please select a valid entry.`;
			}

			this.setTargetRefCustomValidityMessage(targetRefCustomValidityMessage);
		} else {
			this.setTargetRefCustomValidityMessage('');
		}
	}

	public submit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		const { onSuccess = () => ({}) } = this.props;
		const { sourceNumber, cloneSource, sourceTemplate, sourceSelectedCpr } =
			this.state;
		const {
			cloneTarget,
			targetNumber,
			targetTemplate,
			targetEffectiveTsIsNow,
			targetEffectiveTs
		} = this.state;
		const targetEffectiveTsDate = targetEffectiveTsIsNow
			? undefined
			: targetEffectiveTs?.toDate();
		let clonePromise: Promise<ITaskProfile> = Promise.reject(
			new RtError({ message: 'Unknown Error Occurred.' })
		);
		const setErrors = (error: RtError) => this.setState({ error });
		let targetKey = '';

		if (!sourceSelectedCpr) {
			setErrors(new RtError({ message: 'You must select a source CPR.' }));
			return;
		}

		if (cloneTarget === RoutingCacheTypes.Template) {
			if (!targetTemplate) {
				setErrors(
					new RtError({
						message: 'You must select a Template source.'
					})
				);
				return;
			}

			targetKey = targetTemplate.templateName;
		} else if (cloneTarget === RoutingCacheTypes.TollFree) {
			const targetTfn = TollFreeNumbersUtils.tollFreeNumberToReadableString(
				targetNumber,
				false
			);

			if (!TollFreeNumbersUtils.isValid(targetTfn)) {
				setErrors(new RtError({ message: 'Target number is malformed.' }));
				return;
			}

			targetKey = targetTfn;
		}

		const { effectiveTs } = sourceSelectedCpr;

		if (cloneSource === RoutingCacheTypes.TollFree) {
			const tfn = TollFreeNumbersUtils.tollFreeNumberToReadableString(
				sourceNumber,
				false
			); //remove dashes
			clonePromise = this.numberResource.clone(
				tfn,
				effectiveTs,
				cloneTarget,
				targetKey,
				targetEffectiveTsDate
			);
		} else if (cloneSource === RoutingCacheTypes.Template) {
			const templateName = sourceTemplate ? sourceTemplate.templateName : '';
			clonePromise = this.templateResource.clone(
				templateName,
				effectiveTs,
				cloneTarget,
				targetKey,
				targetEffectiveTsDate
			);
		}

		clonePromise
			.then((taskProfile) => onSuccess(taskProfile))
			.catch((error: RtError) => this.setState({ error }))
			.finally(() => this.setState({ isSubmitting: false }));
	}

	public render() {
		const { sourceTollFreeNumber } = this.state;

		return (
			<Card body>
				<Form onSubmit={(e) => this.submit(e)}>
					<Row>
						<Col>
							<legend>
								<i className="fas fa-share fa-rotate-270 text-muted" />
								<span>&nbsp;Clone Routing Profile From</span>
							</legend>
							<RadioFormControl<RoutingCacheTypes>
								required
								label=""
								value={this.state.cloneSource}
								onChange={(cloneFrom) => this.updateCloneSource(cloneFrom)}
								options={[
									{
										value: RoutingCacheTypes.TollFree,
										label: 'Toll-Free Number'
									},
									{
										value: RoutingCacheTypes.Template,
										label: 'Template'
									}
									//{ value: CloneFromEnum.LCR, label: 'LCR' },
								]}
								id="rtx-clone-routing-from-radio"
							/>
							{this.state.cloneSource === RoutingCacheTypes.TollFree && (
								<TollFreeNumberFormControl
									required
									alwaysShowMask
									onlyNumbers
									onChange={(sourceNumber) =>
										this.updateSourceNumber(sourceNumber)
									}
									value={this.state.sourceNumber}
									id="rtx-clone-routing-from-tfn"
								/>
							)}
							{sourceTollFreeNumber && sourceTollFreeNumber.templateName && (
								<Alert variant="warning">
									<span>
										{TollFreeNumbersUtils.tollFreeNumberToReadableString(
											sourceTollFreeNumber.tfn
										)}{' '}
										is associated with a template.&nbsp;
									</span>
									<a
										onClick={() =>
											sourceTollFreeNumber.templateName &&
											this.moveToSourceTemplateName(
												sourceTollFreeNumber.templateName
											)
										}
									>
										Click here to use it&apos;s template,{' '}
										{sourceTollFreeNumber.templateName}.
									</a>
								</Alert>
							)}
							{this.state.cloneSource === RoutingCacheTypes.Template && (
								<TemplateSelect
									required
									initialOptionId={this.state.sourceDefaultTemplateName}
									onChange={(sourceTemplate) =>
										this.updateSourceTemplate(sourceTemplate)
									}
									value={this.state.sourceTemplate}
									id="rtx-clone-routing-from-template"
								/>
							)}
							{this.state.cloneSource === RoutingCacheTypes.MgiTask && (
								<>Todo</>
							)}
							<CloneRoutingEffectiveTimestampSelect
								required
								isLoading={this.state.isLoadingSourceCprs}
								options={this.state.sourceCprs}
								onChange={(sourceSelectedCpr) =>
									this.setState({ sourceSelectedCpr })
								}
								value={this.state.sourceSelectedCpr}
								id="rtx-clone-routing-from-timestamp"
							/>
						</Col>
						<Col md={1} className="d-none d-md-flex justify-content-center">
							<section className="border-right border-light"></section>
						</Col>
						<Col md>
							<hr className="d-md-none" />
							<legend>
								<i className="fas fa-share fa-rotate-90 text-muted" />
								<span>&nbsp;Clone Routing Profile To</span>
							</legend>
							<RadioFormControl<RoutingCacheTypes>
								required
								label=""
								value={this.state.cloneTarget}
								onChange={(cloneTo) => this.setState({ cloneTarget: cloneTo })}
								options={[
									{
										value: RoutingCacheTypes.TollFree,
										label: 'Toll-Free Number'
									},
									{
										value: RoutingCacheTypes.Template,
										label: 'Template'
									}
								]}
								id="rtx-clone-routing-to-radio"
							/>
							<section style={{ position: 'relative' }}>
								{this.state.cloneTarget === RoutingCacheTypes.TollFree && (
									<TollFreeNumberFormControl
										required
										alwaysShowMask
										onlyNumbers
										onChange={(targetNumber) =>
											this.updateTargetNumber(targetNumber)
										}
										value={this.state.targetNumber}
										id="rtx-clone-routing-to-tfn"
									/>
								)}
								{this.state.cloneTarget === RoutingCacheTypes.Template && (
									<TemplateSelect
										required
										onChange={(targetTemplate) =>
											this.updateTargetTemplate(targetTemplate)
										}
										value={this.state.targetTemplate}
										id="rtx-clone-routing-from-template"
									/>
								)}
								<input
									type="text"
									ref={(ref) => this.setTargetRef(ref)}
									tabIndex={-1}
									required
									minLength={1}
									onChange={() => void 0}
									value={this.state.targetRefCustomValidityMessage ? '' : '1'}
									style={{
										height: 1,
										width: 0,
										opacity: 0.01,
										position: 'absolute',
										left: '50%',
										bottom: 0
									}}
								/>
							</section>
							<EffectiveTimeFormControl
								required
								isAfter={this.state.minEffectiveTs}
								onChangeWithIsNow={(
									targetEffectiveTs,
									targetEffectiveTsIsNow
								) =>
									this.setState({
										targetEffectiveTs,
										targetEffectiveTsIsNow
									})
								}
								value={this.state.targetEffectiveTs}
								id="rtx-clone-routing-to-timestamp"
							/>
							<SubmitFormControl
								createMode
								error={this.state.error}
								onChange={() => void 0}
								displayMode={false}
								isSubmitting={this.state.isSubmitting}
							/>
						</Col>
					</Row>
				</Form>
			</Card>
		);
	}
}
