import * as moment from 'moment-timezone';
import { IRtxApiRoute, Rt800ApiRoutes } from 'RtExports/routes';
import {
	MgiCprIndexResponse,
	MgiScpIndexResponse,
	TemplateCprDisconnectRequest,
	TemplateCprProfileResponse,
	TemplateCprTransferRequest,
	TemplateCprUpdateRequest,
	TemplateProfileRequest,
	TollFreeCprProfileResponse,
	TollFreeCprTransferRequest,
	TollFreeProfileRequest
} from 'RtModels';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { IRequestInitWithParams } from 'RtUi/utils/http/interfaces';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { CprRouting } from 'Somos/lib/SomosCpr/RtCprV1/CprRouting';
import { SomosCprFactory } from 'Somos/lib/SomosCpr/SomosCprFactory';

export abstract class CprResource<
	IndexResponse = any,
	ProfileResponse = any,
	IMgiCpr extends MgiCprIndexResponse = MgiCprIndexResponse,
	IMgiScpIndex extends MgiScpIndexResponse = MgiScpIndexResponse
> extends HttpResource<IndexResponse, ProfileResponse> {
	public routing(id: string): Promise<IMgiCpr[]> {
		return this.fetchWithCprRoute<IMgiCpr[]>(
			id,
			Rt800ApiRoutes.TollFreeCprs.Index,
			Rt800ApiRoutes.TemplateCprs.Index
		);
	}

	public async cpr(id: string, effectiveTs: string) {
		const params = { effectiveTs };

		const mgiProfile = await this.fetchWithCprRoute<
			TollFreeCprProfileResponse | TemplateCprProfileResponse
		>(
			id,
			Rt800ApiRoutes.TollFreeCprs.Profile,
			Rt800ApiRoutes.TemplateCprs.Profile,
			{ params }
		);

		const interLataCarriers = mgiProfile.interLataCarriers ?? [];
		const intraLataCarriers = mgiProfile.intraLataCarriers ?? [];

		const cprV1: CprRouting = {
			respOrgId: mgiProfile.respOrgId,
			interLataCarriers,
			intraLataCarriers,
			areasOfService: mgiProfile.areasOfService,
			cprStatusId: mgiProfile.cprStatusId,
			approvalIndicator: mgiProfile.approvalIndicator,
			summary: mgiProfile.summary ?? '',
			notes: mgiProfile.notes ?? '',
			contactName: mgiProfile.contactName ?? '',
			contactNumber: mgiProfile.contactNumber ?? '',
			templateName: mgiProfile.templateName,
			cpr: {
				interLataCarriers,
				intraLataCarriers,
				nodeTypes: mgiProfile.cpr?.nodeTypes ?? [],
				nodes: mgiProfile.cpr?.nodes ?? [],
				labels: mgiProfile.cpr?.labels ?? []
			}
		};

		const effectiveDate = new Date(effectiveTs);

		const cprV2 = SomosCprFactory.RtCprV1ToRtCprV2(
			mgiProfile.respOrgId,
			id,
			effectiveDate,
			cprV1
		);

		return cprV2;
	}

	public copyCpr(
		id: string,
		effectiveTs: Date,
		templateCprUpdateReq: TemplateCprUpdateRequest
	) {
		const timestamp = moment(effectiveTs).toISOString();
		const params = { effectiveTs: timestamp };
		const body = JSON.stringify(templateCprUpdateReq);

		return this.fetchWithCprRoute<ITaskProfile>(
			id,
			Rt800ApiRoutes.TollFreeCprCopy.Update,
			Rt800ApiRoutes.TemplateCprCopy.Update,
			{ body, params, disableNotification: true }
		);
	}

	public updateCpr(
		id: string,
		effectiveTs: Date,
		templateCprUpdateReq: TemplateCprUpdateRequest
	) {
		const timestamp = moment(effectiveTs).toISOString();
		const params = { effectiveTs: timestamp };
		const body = JSON.stringify(templateCprUpdateReq);

		return this.fetchWithCprRoute<ITaskProfile>(
			id,
			Rt800ApiRoutes.TollFreeCprs.Update,
			Rt800ApiRoutes.TemplateCprs.Update,
			{ body, params, disableNotification: true }
		);
	}

	public transferCpr(
		id: string,
		sourceEffectiveTs: Date,
		transferReq: TollFreeCprTransferRequest | TemplateCprTransferRequest
	) {
		const params = { effectiveTs: sourceEffectiveTs.toISOString() };
		const body = JSON.stringify(transferReq);

		return this.fetchWithCprRoute<ITaskProfile>(
			id,
			Rt800ApiRoutes.TollFreeCprTransfers.Update,
			Rt800ApiRoutes.TemplateCprTransfers.Update,
			{ body, params, disableNotification: true }
		);
	}

	/**
	 * Currently only for Templates!
	 */
	public disconnectCpr(
		id: string,
		sourceEffectiveTs: Date,
		targetEffectiveTs: Date | undefined
	) {
		const req: TemplateCprDisconnectRequest = {};
		const params = { effectiveTs: sourceEffectiveTs };

		if (targetEffectiveTs) {
			req.effectiveTs = targetEffectiveTs;
		}

		const body = JSON.stringify(req);

		return this.fetchWithCprRoute<ITaskProfile>(
			id,
			Rt800ApiRoutes.TemplateCprDisconnect.Update,
			Rt800ApiRoutes.TemplateCprDisconnect.Update,
			{ body, params, disableNotification: true }
		);
	}

	public deleteCpr(id: string, effectiveTsDate: Date) {
		const effectiveTs = moment(effectiveTsDate).toISOString();
		const params = { effectiveTs };

		return this.fetchWithCprRoute<ITaskProfile>(
			id,
			Rt800ApiRoutes.TollFreeCprs.Delete,
			Rt800ApiRoutes.TemplateCprs.Delete,
			{ params, disableNotification: true }
		);
	}

	public getCprResource(id: string): ArrayResource<IMgiCpr> {
		const params = {};
		const CprResource = new ArrayResource<IMgiCpr>('effectiveTs');
		const isNumbersCprResource = this.isNumbersCprResource();
		const apiRoute = isNumbersCprResource
			? Rt800ApiRoutes.TollFreeCprs.Index
			: Rt800ApiRoutes.TemplateCprs.Index;
		const urlParams: TollFreeProfileRequest & TemplateProfileRequest = {
			tfn: id,
			templateName: id
		};

		CprResource.setApiRouteForGetAll(apiRoute, { urlParams });
		CprResource.setGetAllParams(params);

		return CprResource;
	}

	public getScpsResource(
		id: string,
		effectiveTs: string
	): ArrayResource<IMgiScpIndex> {
		const params = { effectiveTs };
		const ScpsResource = new ArrayResource<IMgiScpIndex>(
			'serviceControlPointId'
		);
		const isNumbersCprResource = this.isNumbersCprResource();
		const apiRoute = isNumbersCprResource
			? Rt800ApiRoutes.TollFreeScp.Index
			: Rt800ApiRoutes.TemplateScps.Index;
		const urlParams: TollFreeProfileRequest & TemplateProfileRequest = {
			tfn: id,
			templateName: id
		};

		ScpsResource.setApiRouteForGetAll(apiRoute, { urlParams });
		ScpsResource.setGetAllParams(params);

		return ScpsResource;
	}

	protected fetchWithCprRoute<T>(
		id: string,
		tollFreeRoute: IRtxApiRoute,
		templateRoute: IRtxApiRoute,
		init: IRequestInitWithParams = {}
	) {
		const isNumbersCprResource = this.isNumbersCprResource();
		const apiRoute = isNumbersCprResource ? tollFreeRoute : templateRoute;

		if (!init.urlParams) {
			const urlParams: TollFreeProfileRequest & TemplateProfileRequest = {
				tfn: id,
				templateName: id
			};
			init.urlParams = urlParams;
		}

		return super.fetchWithRoute<T>(apiRoute, init);
	}

	private isNumbersCprResource() {
		return this.resourcePath.includes('numbers');
	}
}
