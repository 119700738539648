import { InvoiceSubscription } from 'RtModels';
import { InvoiceSubscriptionDetailGrid } from 'RtUi/app/rtSip/Invoices/lib/grid/InvoiceSubscriptionDetail';
import { useGetInvoiceSubscriptions } from 'RtUi/app/rtSip/Invoices/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { SubscriptionDataGridCache } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { getInvoicesSubscriptionsColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/invoices';
import { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface IInvoiceSubscriptionGridProps {
	invoiceId: number;
}

export const InvoiceSubscriptionGrid = ({
	invoiceId
}: IInvoiceSubscriptionGridProps) => {
	const { data, isFetching: isLoading } = useGetInvoiceSubscriptions({
		invoiceId
	});
	const [selectedSubscription, setSelectedSubscription] =
		useState<InvoiceSubscription>();
	const columns = useMemo(() => getInvoicesSubscriptionsColumns(), []);

	return (
		<>
			<DataGrid<InvoiceSubscription>
				loading={isLoading}
				data={data?.data}
				totalRows={data?.totalCount}
				pageName="rtSip_invoicesSubscriptions"
				columns={columns}
				onRowClick={(row) => setSelectedSubscription(row)}
			/>
			{selectedSubscription && (
				<Modal
					show={selectedSubscription !== undefined}
					size="lg"
					onHide={() => setSelectedSubscription(undefined)}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{SubscriptionDataGridCache.getLabelFor(
								selectedSubscription.subscriptionId
							)}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<InvoiceSubscriptionDetailGrid
							resourceParams={{
								invoiceId,
								subscriptionId: selectedSubscription.subscriptionId
							}}
						/>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
};
