import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

export interface BooleanDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const BooleanDataGridColumn = <T = any,>({
	...config
}: BooleanDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const renderValue = (value: boolean) => {
		const label = value ? 'Yes' : 'No';
		const theme = value ? 'success' : 'warning';

		return (
			<section className="d-flex">
				<div
					role="alert"
					style={{ textAlign: 'center' }}
					className={`alert-sm w-100 mb-0 p-0 alert alert-${theme}`}
				>
					<span>{label}</span>
				</div>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: T[keyof T]) => (value ? 'Yes' : 'No'),
		getValue: ({ cell }) => renderValue(cell.getValue<boolean>())
	});
};
