import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface PrecisionMap {
	[key: string]: number;
}

interface DataGridState {
	precisionColumn: PrecisionMap;
}

const initialState: DataGridState = {
	precisionColumn: {}
};

const dataGridSlice = createSlice({
	name: 'DataGrid',
	initialState,
	reducers: {
		setPrecision: (
			state,
			action: PayloadAction<{ key: string; value: number }>
		) => {
			const { key, value } = action.payload;

			state.precisionColumn[key] = value;
		}
	}
});

export const dataGridActions = dataGridSlice.actions;

export default dataGridSlice.reducer;
