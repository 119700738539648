/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoutingRuleActionResponse = {
    properties: {
        priority: {
            type: 'number',
            isRequired: true,
        },
        percentage: {
            type: 'number',
            isRequired: true,
        },
        routingRuleModeId: {
            type: 'RoutingRuleActionMode',
            isRequired: true,
        },
        subscriptions: {
            type: 'Array',
            isRequired: true,
        },
    },
};