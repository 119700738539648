import { FullSummaryIndexRequest, SipFullSummaryIndexResponse } from 'RtModels';
import { useGetFixedRatingSummary } from 'RtUi/app/rtSip/FixedRatingSummary/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getFixedRatingSummaryColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/fixedRatingSummary';
import { useMemo } from 'react';

interface IFixedRatingSummaryGridProps {
	autoFocusFilter?: boolean;
	resourceParams: FullSummaryIndexRequest;
}

export const FixedRatingSummaryDataGrid = ({
	resourceParams
}: IFixedRatingSummaryGridProps) => {
	const { data, isFetching: isLoading } =
		useGetFixedRatingSummary(resourceParams);
	const columns = useMemo(() => getFixedRatingSummaryColumns(), []);

	return (
		<DataGrid<SipFullSummaryIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_fixedRatingSummary'}
		/>
	);
};
