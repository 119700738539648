import { keyBy } from 'lodash-es';
import { useState } from 'react';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

export function useTabs(
	tabs: IProfileApplicationContainerTabs,
	optionalTabs?: IProfileApplicationContainerTabs | null,
	multipleActionTabs?: TabbedLayoutTabProps[]
): [
	IProfileApplicationContainerTabs,
	TabbedLayoutTabProps,
	(newActiveTab: string) => void
] {
	const tabArr = [
		...Object.values(tabs),
		...Object.values(optionalTabs ?? {})
	] as TabbedLayoutTabProps[];
	const [activeTabKey, setActiveTabKey] = useState(() => {
		let defaultTabKey = tabArr[0]?.header ?? '';

		for (const tab of tabArr) {
			if (typeof tab === 'undefined') {
				continue;
			}

			if (tab.isDefault) {
				defaultTabKey = tab.header;
				break;
			}
		}

		return defaultTabKey;
	});

	if (multipleActionTabs && multipleActionTabs?.length > 0) {
		tabArr.push(
			...Object.values(
				keyBy(multipleActionTabs, 'header') as unknown as TabbedLayoutTabProps
			)
		);
	}
	const activeTab = tabArr.find((tab) => tab?.header === activeTabKey)!;

	return [tabs, activeTab, setActiveTabKey];
}
