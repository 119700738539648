import { ServerCreateForm } from 'RtUi/app/rtAdmin/Servers/lib/forms/ServerCreateForm';
import { FC } from 'react';

import {
	ServerRouter,
	ServerRouterClass
} from 'RtUi/app/rtAdmin/Servers/Server.router';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { ServerDataGrid } from 'RtUi/app/rtAdmin/Servers/lib/grids/ServerGrid';

export const ServerIndexContainer: FC = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		'Results': { header: 'Results', isDefault: true },
		'Create Server': { header: 'Create Server', isAction: true }
	});

	return (
		<TabbedLayout
			router={ServerRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Results}>
				<ServerDataGrid />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs['Create Server']}>
				<ServerCreateForm
					onUpdate={() => {
						setActiveTab(tabs.Results.header);
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ServerIndexContainer.displayName = 'ServerIndexContainer';

ServerRouter.setIndexRtUiFunctionalComponent(
	ServerIndexContainer,
	ServerRouterClass.getServerIndexRtUiControllerProps()
);
