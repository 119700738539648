/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RejectDetailsIndexResponse = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        fileStreamId: {
            type: 'number',
            isRequired: true,
        },
        trunkGroup: {
            type: 'string',
            isRequired: true,
        },
        billingNumber: {
            type: 'string',
            isRequired: true,
        },
        rejectCode: {
            type: 'RejectCodes',
            isRequired: true,
        },
        totalCalls: {
            type: 'number',
            isRequired: true,
        },
        totalMinutes: {
            type: 'number',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
    },
};