/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RespOrgTotalsByNpaResponse = {
    properties: {
        800: {
            type: 'number',
            isRequired: true,
        },
        833: {
            type: 'number',
            isRequired: true,
        },
        844: {
            type: 'number',
            isRequired: true,
        },
        855: {
            type: 'number',
            isRequired: true,
        },
        866: {
            type: 'number',
            isRequired: true,
        },
        877: {
            type: 'number',
            isRequired: true,
        },
        888: {
            type: 'number',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        statusTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        total: {
            type: 'number',
            isRequired: true,
        },
    },
};