import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { FileTypeIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';

export const getFileTypeColumns = (): Array<
	DataGridColumn<FileTypeIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'fileTypeId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'description',
		header: 'Description'
	}),
	DefaultDataGridColumn({
		accessorKey: 'formatType',
		header: 'Format Type'
	}),
	DefaultDataGridColumn({
		accessorKey: 'delimiter',
		header: 'Delimiter'
	}),
	DefaultDataGridColumn({
		accessorKey: 'vendor',
		header: 'Vendor'
	}),
	IntegerDataGridColumn({
		accessorKey: 'footer',
		header: 'Footer'
	}),
	DefaultDataGridColumn({
		accessorKey: 'documentUrl',
		header: 'Document URL'
	}),
	DefaultDataGridColumn({
		accessorKey: 'helperClass',
		header: 'Helper Class'
	}),
	DefaultDataGridColumn({
		accessorKey: 'dataType',
		header: 'Data Type'
	})
];
