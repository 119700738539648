import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	LcrMinuteDataRequest,
	LcrMinuteIndexRequest,
	LcrMinuteProfileRequest,
	MinuteProfile,
	MinuteProfileMinute
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	handlePutRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const changeMinutesLabel = (
	entityId: string,
	label: string,
	isActive: number,
	respOrgId: string
) => {
	return handlePutRequest(
		Rt800ApiRoutes.LcrMinutes.Index,
		{ label, isActive, respOrgId },
		{
			urlParams: {
				entityId
			}
		}
	);
};

const fetchMinutes = async (
	queryParams?: LcrMinuteIndexRequest
): Promise<FullResponse<MinuteProfile[]>> => {
	return handleGetRequest<MinuteProfile[], true>(
		Rt800ApiRoutes.LcrMinutes.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetMinutes = (queryParams?: LcrMinuteIndexRequest) => {
	return useQuery<FullResponse<MinuteProfile[]>, Error>(
		['getMinutes', queryParams],
		() => fetchMinutes(queryParams)
	);
};

const fetchMinutesData = async (
	urlParams?: LcrMinuteProfileRequest,
	queryParams?: LcrMinuteDataRequest
): Promise<FullResponse<MinuteProfileMinute[]>> => {
	return handleGetRequest<MinuteProfileMinute[], true>(
		Rt800ApiRoutes.LcrMinutesData.Index,
		{
			includeFullResponse: true,
			urlParams,
			queryParams
		}
	);
};

export const useGetMinutesData = (
	urlParams?: LcrMinuteProfileRequest,
	queryParams?: LcrMinuteDataRequest
) => {
	return useQuery<FullResponse<MinuteProfileMinute[]>, Error>(
		['getMinutes', urlParams, queryParams],
		() => fetchMinutesData(urlParams, queryParams)
	);
};
