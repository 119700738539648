/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionUpdateRequest = {
    properties: {
        label: {
            type: 'string',
            isRequired: true,
        },
        serviceId: {
            type: 'number',
            isRequired: true,
        },
        ratePlanId: {
            type: 'number',
            isNullable: true,
        },
        note: {
            type: 'string',
        },
        externalAccountId: {
            type: 'string',
        },
        externalSubAccountId: {
            type: 'string',
        },
        blockTypeId: {
            type: 'number',
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        startDate: {
            type: 'Date',
            format: 'date-time',
        },
        deactivate: {
            properties: {
            },
            isRequired: true,
        },
        attestation: {
            type: 'Attestation',
            isNullable: true,
        },
    },
};