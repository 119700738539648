import { CdrRouter } from 'RtUi/app/rtSip/CdrExports/CdrExport.router';
import { CdrExportGrid } from 'RtUi/app/rtSip/CdrExports/lib/grids/CdrExportGrid';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import {
	CdrExportIndexRequest,
	CdrExportSourceTypes,
	CdrExportTypes,
	CdrExportIntervals,
	AccountIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import {
	SimpleSelectFormControl,
	ISimpleSelectFormControlOption
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';

interface IInitialResourceParams {
	cdrExportSourceType: CdrExportSourceTypes | undefined;
	cdrExportType: ISimpleSelectFormControlOption<CdrExportTypes | undefined>;
	cdrExportIntervals: CdrExportIntervals | undefined;
	cdrSourceValue?: number;
	begTs?: moment.Moment;
	endTs?: moment.Moment;
}

interface ICdrExportIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {
	resourceParams: CdrExportIndexRequest;
	account?: AccountIndexResponse;
	subscription?: SubscriptionIndexResponse;
}

@CdrRouter.getBusinessOpsIndexRtUiController()
export class CdrExportIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	ICdrExportIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		cdrExportSourceType: undefined,
		cdrExportType: { label: 'All', value: undefined },
		cdrExportIntervals: undefined,
		cdrSourceValue: undefined,
		begTs: undefined,
		endTs: undefined
	};

	public state: ICdrExportIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	public getResourceParams() {
		const {
			cdrExportSourceType,
			cdrExportType,
			cdrExportIntervals,
			begTs,
			endTs
		} = this.state;
		const resourceParams: CdrExportIndexRequest = {};

		if (typeof cdrExportSourceType === 'number') {
			resourceParams.cdrSourceTypeId = cdrExportSourceType;
		}

		if (cdrExportType && typeof cdrExportType.value === 'number') {
			resourceParams.cdrExportTypeId = cdrExportType.value;
		}

		if (typeof cdrExportIntervals === 'number') {
			resourceParams.cdrExportIntervalId = cdrExportIntervals;
		}

		if (begTs) {
			resourceParams.begTs = begTs.toDate();
		}

		if (endTs) {
			resourceParams.endTs = endTs.toDate();
		}

		if (this.state.subscription) {
			resourceParams.cdrSourceValue = this.state.subscription.subscriptionId;
		}

		if (this.state.account) {
			resourceParams.cdrSourceValue = this.state.account.accountId;
		}

		return resourceParams;
	}

	public render() {
		return (
			<TabbedLayout
				router={CdrRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(evt) => this.submitAndGoToResults(evt)}>
						<RadioFormControl<CdrExportSourceTypes | undefined>
							label="Source"
							value={this.state.cdrExportSourceType}
							onChange={(cdrExportSourceType) =>
								this.setState({ cdrExportSourceType })
							}
							options={[
								{ label: 'All', value: undefined },
								{
									label: 'Account',
									value: CdrExportSourceTypes.Account
								},
								//{ label: 'Invoice', value: CdrExportSourceTypes.Invoice },
								{
									label: 'Full',
									value: CdrExportSourceTypes.Full
								},
								{
									label: 'Subscription',
									value: CdrExportSourceTypes.Subscription
								}
							]}
						/>
						<RadioFormControl<CdrExportIntervals | undefined>
							label="Interval"
							value={this.state.cdrExportIntervals}
							onChange={(cdrExportIntervals) =>
								this.setState({ cdrExportIntervals })
							}
							options={[
								{ label: 'All', value: undefined },
								//{ label: 'Invoice', value: CdrExportIntervals.Invoice },
								{
									label: 'Daily',
									value: CdrExportIntervals.Daily
								},
								{
									label: 'Weekly',
									value: CdrExportIntervals.Weekly
								},
								{
									label: 'Monthly',
									value: CdrExportIntervals.Monthly
								}
							]}
						/>
						<SimpleSelectFormControl<CdrExportTypes | undefined>
							label="Type"
							value={this.state.cdrExportType}
							clearable={false}
							onChange={(cdrExportType) => this.setState({ cdrExportType })}
							options={[
								{ label: 'All', value: undefined },
								{
									label: 'Cust Orig Egress Revenue',
									value: CdrExportTypes.CustOrigEgressRevenue
								},
								{
									label: 'Egress Cost',
									value: CdrExportTypes.EgressCost
								},
								{
									label: 'Egress Revenue',
									value: CdrExportTypes.EgressRevenue
								},
								{
									label: 'Full Export',
									value: CdrExportTypes.FullExport
								},
								{
									label: 'Ingress Cost',
									value: CdrExportTypes.IngressCost
								},
								{
									label: 'Ingress Revenue',
									value: CdrExportTypes.IngressRevenue
								},
								{
									label: 'Pwm Shared Usage',
									value: CdrExportTypes.PwmSharedUsage
								}
							]}
						/>
						<DatetimeFormControl
							showDateOnly
							label="Start Date"
							onChange={(begTs) => this.setState({ begTs })}
							value={this.state.begTs}
						/>
						<DatetimeFormControl
							showDateOnly
							label="End Date"
							onChange={(endTs) => this.setState({ endTs })}
							value={this.state.endTs}
						/>
						<fieldset className="border border-light p-3 mb-2 rounded">
							<legend className="h5">Source</legend>
							<SubscriptionSelect
								onChange={(subscription) => {
									this.setState({ subscription, account: undefined });
								}}
								value={this.state.subscription}
							/>
							<AccountSelect
								onChange={(account) => {
									this.setState({ account, subscription: undefined });
								}}
								value={this.state.account}
							/>
						</fieldset>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<CdrExportGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
