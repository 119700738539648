import { TypedEventEmitter } from '../TypedEventEmitter';
import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';

export interface ISpreadsheetParserHeaderMatch {
	columnsFound: SpreadsheetParserColumn[];
	allRequiredColumnsFound: boolean;
	missingRequiredColumns: SpreadsheetParserColumn[];
	headerRowIndex: number;
}

export interface ISpreadsheetParseResult {
	[columnIndex: number]: string;
}

export class SpreadsheetParser extends TypedEventEmitter {
	public static readonly MAX_ROWS_TO_FIND_HEADER = 50;
	public static readonly MAX_ROWS_TO_FIND_EOF = 50;

	protected spreadsheet!: string[][];
	protected columns: SpreadsheetParserColumn[] = [];

	constructor(spreadsheet: string[][]) {
		super();
		//Clone array to make sure floating reference do not get altered
		this.spreadsheet = [...spreadsheet.map((row) => [...row])];
	}

	/**
	 * Adds Column to be found and parsed
	 * @param columnName
	 * @param columnSearchKeywords
	 */
	public addColumn(
		columnName: string,
		columnSearchKeywords: string[],
		required = false
	) {
		const parserColumn = new SpreadsheetParserColumn(
			columnName,
			columnSearchKeywords
		);

		parserColumn.setIsRequired(required);

		this.addParserColumn(parserColumn);
	}

	public addParserColumn(...parserColumns: SpreadsheetParserColumn[]) {
		for (const parserColumn of parserColumns) {
			this.columns.push(parserColumn);

			this.emit(
				'debug',
				`Column ${parserColumn.getColumnName()} added to spreadsheet with search words: ${parserColumn
					.getSearchKeywords()
					.join(', ')}`
			);
		}
	}

	public removeAllParserColumns() {
		this.columns = [];

		this.emit('debug', `Reset parser columns`);
	}

	/**
	 * Return an array of possible header matches within the first 50(SpreadsheetParser.MAX_ROWS_TO_FIND_HEADER) rows
	 */
	public findPossibleHeaderMatches(): ISpreadsheetParserHeaderMatch[] {
		const possibleMatches: ISpreadsheetParserHeaderMatch[] = [];
		const maxRowsToFindHeader = Math.min(
			this.spreadsheet.length - 1,
			SpreadsheetParser.MAX_ROWS_TO_FIND_HEADER
		);

		this.emit(
			'debug',
			`Searching first ${maxRowsToFindHeader} rows in spreadsheet for columns...`
		);

		for (let index = 0; index < maxRowsToFindHeader; index++) {
			const spreadsheetRow = this.spreadsheet[index];

			const possibleMatch = this.getHeaderMatchesForRow(spreadsheetRow, index);

			if (possibleMatch.columnsFound.length > 0) {
				possibleMatches.push(possibleMatch);
			}
		}

		return possibleMatches;
	}

	/**
	 * Given a headerMatch from findPossibleHeaderMatches(), parse the spreadsheet for data values
	 * @param headerMatch
	 */
	public parse(
		headerMatch: ISpreadsheetParserHeaderMatch
	): ISpreadsheetParseResult[] {
		const { headerRowIndex, columnsFound } = headerMatch;
		let maxRowsToFindEof = SpreadsheetParser.MAX_ROWS_TO_FIND_EOF;
		const result: ISpreadsheetParseResult[] = [];
		const columnNames = columnsFound.map((c) => c.getColumnName()).join(', ');

		this.emit(
			'debug',
			`Parsing Spreadsheet for ${columnNames} starting at row ${
				headerRowIndex + 1
			}...`
		);

		for (
			let rowIndex = headerRowIndex + 1;
			rowIndex < this.spreadsheet.length;
			rowIndex++
		) {
			if (maxRowsToFindEof <= 0) {
				this.emit(
					'debug',
					`Row ${rowIndex + 1}: max empty rows has been hit. Exiting search...`
				);
				break;
			}

			const rowResult: ISpreadsheetParseResult = {};
			const spreadsheetRow = this.spreadsheet[rowIndex];

			if (!spreadsheetRow) {
				this.emit('debug', `Row ${rowIndex + 1}: Unable to parse this row.`);
				maxRowsToFindEof--;
				continue;
			}

			let requiredColumnIsMissing = false;

			for (const column of columnsFound) {
				const columnIndex = column.getIndex();
				const spreadsheetCellValue = spreadsheetRow[columnIndex];

				if (typeof spreadsheetCellValue === 'string') {
					rowResult[columnIndex] = spreadsheetCellValue;
				} else if (column.isRequired()) {
					requiredColumnIsMissing = true;
					break;
				}
			}

			//Make sure all required columns are found
			if (requiredColumnIsMissing) {
				this.emit(
					'debug',
					`Row ${
						rowIndex + 1
					}: Unable to parse this row; not all required columns were found.`
				);
				maxRowsToFindEof--;
				continue;
			}

			result.push(rowResult);
		}

		this.emit(
			'debug',
			`Parsing Completed: ${result.length} rows found in spreadsheet.`
		);

		return result;
	}

	/**
	 * Find the index for that column within the headerMatch
	 * @param headerMatch
	 * @param column
	 */
	protected getIndexFor(
		headerMatch: ISpreadsheetParserHeaderMatch,
		column: SpreadsheetParserColumn
	) {
		const { columnsFound } = headerMatch;

		for (const columnFound of columnsFound) {
			if (column.getColumnName() === columnFound.getColumnName()) {
				return columnFound.getIndex();
			}
		}

		return -1;
	}

	/**
	 * Given a spreadsheet row to search against, find possible column matches
	 * @param row
	 * @param headerRowIndex
	 */
	private getHeaderMatchesForRow(
		row: string[],
		rowIndex: number
	): ISpreadsheetParserHeaderMatch {
		const possibleMatch: ISpreadsheetParserHeaderMatch = {
			allRequiredColumnsFound: false,
			columnsFound: [],
			missingRequiredColumns: [],
			headerRowIndex: rowIndex
		};
		const foundColumnIndexes: number[] = [];
		const foundColumnNames: string[] = [];

		row.forEach((cell, colIndex) => {
			//Find first cell match that does not have a taken column Index
			//TODO: smarter approach to find the correct match
			const cellMatches = this.getHeaderMatchesForCell(
				cell,
				rowIndex,
				colIndex
			);
			const preferredCellMatch = cellMatches
				//Remove indexes that have been found already
				.filter(
					(cellMatch) => !foundColumnIndexes.includes(cellMatch.getIndex())
				)
				//Remove columns that have been found already
				.filter(
					(cellMatch) => !foundColumnNames.includes(cellMatch.getColumnName())
				)
				//return first element
				.shift();

			if (preferredCellMatch) {
				this.emit(
					'debug',
					`Row ${rowIndex + 1} Column ${
						preferredCellMatch.getIndex() + 1
					}: Selected "${preferredCellMatch.getColumnName()}"`
				);

				possibleMatch.columnsFound.push(preferredCellMatch);

				foundColumnIndexes.push(preferredCellMatch.getIndex());
				foundColumnNames.push(preferredCellMatch.getColumnName());
			}
		});

		const requiredColumns = this.columns.filter((c) => c.isRequired());
		possibleMatch.missingRequiredColumns = requiredColumns.filter((rc) => {
			return !possibleMatch.columnsFound.some(
				(cf) => cf.getColumnName() === rc.getColumnName()
			);
		});
		possibleMatch.allRequiredColumnsFound =
			possibleMatch.missingRequiredColumns.length <= 0;

		return possibleMatch;
	}

	/**
	 * Given a spreadsheet cell to search against, find possible column matches
	 * @param cell
	 * @param rowIndex
	 */
	private getHeaderMatchesForCell(
		cell: string,
		rowIndex: number,
		colIndex: number
	): SpreadsheetParserColumn[] {
		const columnsFounds: SpreadsheetParserColumn[] = [];
		const cellToLower = cell.toLowerCase().trim();

		for (const column of this.columns) {
			for (const keyword of column.getSearchKeywords()) {
				//modify keyword if necessary here
				if (cellToLower === keyword.toLowerCase()) {
					const foundColumnCopy = column.clone(colIndex);

					this.emit(
						'debug',
						`Row ${rowIndex + 1} Column ${
							colIndex + 1
						}: Found "${keyword}" match for "${cell}"`
					);

					columnsFounds.push(foundColumnCopy);
				}
			}
		}

		return columnsFounds;
	}
}
