import * as React from 'react';
import { Button } from 'react-bootstrap';
import { generateUUID } from 'RtUi/utils/http/resources/utils';

interface IFileInputButtonProps {
	onChange: (file: File | null) => void;
	size?: 'sm' | 'lg';
	block?: boolean;
	accept?: string;
	className?: string;
	color: string;
	disabled?: boolean;
	outline?: boolean;
}

interface IFileInputButtonState {
	guid: string;
}

export class FileInputButton extends React.Component<
	React.PropsWithChildren<IFileInputButtonProps>,
	IFileInputButtonState
> {
	public state: IFileInputButtonState = {
		guid: generateUUID()
	};

	public onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
		const { onChange } = this.props;
		const { target: fileInputElem } = e;

		e.stopPropagation();

		const { files } = fileInputElem;
		const file = files ? files[0] : null;

		onChange(file);

		this.setState({ guid: generateUUID() });
	}

	public render() {
		const { color, disabled, className, accept, outline, size } = this.props;
		const { guid } = this.state; //reset form control to allow for same file selection
		const overlayCss: React.CSSProperties = {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			opacity: 0,
			zIndex: 1,
			cursor: 'pointer',
			width: '100%'
		};

		return (
			<Button
				disabled={disabled}
				size={size}
				variant={`${outline} ? 'outline-' : ''}${color}`}
				className={className}
				style={{ position: 'relative' }}
			>
				{this.props.children}
				<input
					type="file"
					key={guid}
					onChange={(e) => this.onFileChange(e)}
					accept={accept}
					style={overlayCss}
				/>
			</Button>
		);
	}
}
