import { GuardianDoNotOriginateBulkCreateRequestItem } from 'RtModels';
import { DoNotOriginateParser } from 'RtUi/app/rtVue/DoNotOriginate/lib/parsers/DoNotOriginateParser';
import { DoNotOriginateResource } from 'RtUi/app/rtVue/DoNotOriginate/lib/resources/DoNotOriginateResource';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { useCallback, useState } from 'react';

interface IDoNotOriginateBulkUploadFormProps {
	onSuccess: () => void;
}

export const DoNotOriginateBulkUploadForm = ({
	onSuccess
}: IDoNotOriginateBulkUploadFormProps): JSX.Element => {
	const [isFileProcessing, setIsFileProcessing] = useState<boolean>(false);
	const [error, setError] = useState<any>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>();
	const [file, setFile] = useState<File>();
	const [parsingMessages, setParsingMessages] = useState<string[]>([]);
	const [parsedData, setParsedData] =
		useState<GuardianDoNotOriginateBulkCreateRequestItem[]>();

	const processFile = useCallback(
		async (file: File) => {
			const fileUtils = new FileUtils();

			try {
				setIsFileProcessing(true);
				setError(undefined);
				setParsingMessages([]);

				const spreadsheet = await fileUtils.fileToSpreadsheet(file);

				const parser = new DoNotOriginateParser(spreadsheet);
				parser.on('error', (eventMessage) => {
					setError(new RtError({ message: eventMessage }));
				});
				parser.on('info', (eventMessage) => {
					setParsingMessages([...parsingMessages, eventMessage]);
				});

				const data = await parser.parseAndFindDoNotOriginateItems();

				setFile(file);
				setParsedData(data);
				setIsFileProcessing(false);
			} catch (error) {
				setError(error);
				setIsFileProcessing(false);
			}
		},
		[parsingMessages]
	);

	const bulkUpload = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();
		if (!parsedData?.length) {
			return;
		}

		try {
			const resource = new DoNotOriginateResource();
			setIsSubmitting(true);
			setError(undefined);

			await resource.bulkCreate(parsedData);

			setFile(undefined);
			setParsedData(undefined);

			onSuccess();
		} catch (error) {
			setError(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<RtUiNarrowForm
			error={error}
			isSubmitting={isSubmitting}
			onSubmit={bulkUpload}
		>
			<DragAndDropFileUploader
				required
				accept={FileUtils.AcceptTypes.Spreadsheet}
				processing={isFileProcessing}
				value={file}
				onDrop={processFile}
			/>
		</RtUiNarrowForm>
	);
};
