import { CicLookupRouter } from 'RtUi/app/Administration/CicLookup/CicLookup.router';
import { CicLookupGrid } from 'RtUi/app/Administration/CicLookup/lib/grids/CicLookupGrid';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { FC } from 'react';

export const CicLookupIndexContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	return (
		<StandardLayout router={CicLookupRouter}>
			<CicLookupGrid />
		</StandardLayout>
	);
};

CicLookupRouter.setIndexRtUiFunctionalComponent(CicLookupIndexContainer);
