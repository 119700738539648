import {
	LcrCarrierCreateRequest,
	LcrCarrierIndexResponse,
	LcrCarrierProfileResponse,
	LcrCarrierRateDto,
	LcrCarrierRateIndexResponse,
	LcrCarrierRateUpdateRequest,
	LcrCarrierRegionUpdateRequest,
	LcrCarrierStateProfileResponse,
	LcrCarrierStateUpdateRequest,
	LcrCarrierUpdateRequest,
	NanpRegionIndexResponse,
	NanpStateIndexResponse,
	LcrCarrierProfileRequest,
	LcrCarrierStateProfileRequest,
	LcrCarrierRegionProfileRequest,
	LcrRateSheetType
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export type RegionsResource = HttpResource<NanpRegionIndexResponse>;
export type StatesResource = HttpResource<NanpStateIndexResponse>;

export class LcrCarriersResource extends HttpResource<
	LcrCarrierIndexResponse,
	LcrCarrierProfileResponse
> {
	constructor() {
		super(Rt800ApiRoutes.LcrCarriers);
	}

	public create(req: LcrCarrierCreateRequest) {
		const body = JSON.stringify(req);

		return this.fetchWithRoute<LcrCarrierProfileResponse>(
			Rt800ApiRoutes.LcrCarriers.Create,
			{ body }
		);
	}

	public update(lcrCarrierId: number, req: LcrCarrierUpdateRequest) {
		const body = JSON.stringify(req);
		const urlParams: LcrCarrierProfileRequest = { lcrCarrierId };

		return this.fetchWithRoute<LcrCarrierProfileResponse>(
			Rt800ApiRoutes.LcrCarriers.Update,
			{ body, urlParams }
		);
	}

	public getCarrierStates(lcrCarrierId: number) {
		const urlParams: LcrCarrierProfileRequest = { lcrCarrierId };

		return this.fetchWithRoute<LcrCarrierStateProfileResponse[]>(
			Rt800ApiRoutes.LcrCarrierStates.Index,
			{ urlParams }
		);
	}

	public updateCarrierState(
		lcrCarrierId: number,
		nanpStateId: string,
		req: LcrCarrierStateUpdateRequest
	) {
		const body = JSON.stringify(req);
		const urlParams: LcrCarrierStateProfileRequest = {
			lcrCarrierId,
			nanpStateId
		};

		return this.fetchWithRoute<LcrCarrierStateProfileResponse>(
			Rt800ApiRoutes.LcrCarrierStates.Update,
			{ body, urlParams }
		);
	}

	public bulkUpdateCarrierRatesByRegion(
		lcrCarrierId: number,
		nanpRegionId: string,
		req: LcrCarrierRegionUpdateRequest
	) {
		const body = JSON.stringify(req);
		const urlParams: LcrCarrierRegionProfileRequest = {
			lcrCarrierId,
			nanpRegionId
		};

		return this.fetchWithRoute<LcrCarrierStateProfileResponse[]>(
			Rt800ApiRoutes.LcrCarrierRegions.Update,
			{ body, urlParams }
		);
	}

	public bulkUpdateCarrierRates(
		lcrCarrierId: number,
		req: LcrCarrierRegionUpdateRequest
	) {
		const body = JSON.stringify(req);
		const urlParams: LcrCarrierProfileRequest = { lcrCarrierId };

		return this.fetchWithRoute<LcrCarrierStateProfileResponse[]>(
			Rt800ApiRoutes.LcrCarrierBulk.Update,
			{ body, urlParams }
		);
	}

	public getCarrierRates(lcrCarrierId: number) {
		const urlParams: LcrCarrierProfileRequest = { lcrCarrierId };

		return this.fetchWithRoute<LcrCarrierRateIndexResponse[]>(
			Rt800ApiRoutes.LcrCarrierRates.Index,
			{ urlParams }
		);
	}

	public updateCarrierRates(
		lcrCarrierId: number,
		rateSheetTypeId: LcrRateSheetType,
		rates: LcrCarrierRateDto[]
	) {
		const req: LcrCarrierRateUpdateRequest = { rateSheetTypeId, rates };
		const body = JSON.stringify(req);
		const urlParams: LcrCarrierProfileRequest = { lcrCarrierId };

		return this.fetchWithRoute<void>(Rt800ApiRoutes.LcrCarrierRates.Update, {
			body,
			urlParams
		});
	}

	public getCarrierState(lcrCarrierId: number, nanpStateId: string) {
		const urlParams: LcrCarrierStateProfileRequest = {
			lcrCarrierId,
			nanpStateId
		};

		return this.fetchWithRoute<LcrCarrierStateProfileResponse>(
			Rt800ApiRoutes.LcrCarrierStates.Index,
			{ urlParams }
		);
	}

	public getRegionsResource(): RegionsResource {
		const LcrRegionsResource = new ArrayResource<NanpRegionIndexResponse>(
			'nanpRegionId'
		);

		LcrRegionsResource.setApiRouteForGetAll(Rt800ApiRoutes.NanpRegions.Index);

		return LcrRegionsResource;
	}

	public getStatesResource(): StatesResource {
		const LcrStatesResource = new ArrayResource<NanpStateIndexResponse>(
			'nanpStateId'
		);

		LcrStatesResource.setApiRouteForGetAll(Rt800ApiRoutes.NanpStates.Index);

		return LcrStatesResource;
	}
}
