//import * as React from 'react';
import { InvoiceCycleGroup } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { InvoiceCycleGroupResource } from '../resources/InvoiceCycleGroupResource';

interface IInvoiceCycleGroupSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<InvoiceCycleGroup, IsMulti> {}
interface IInvoiceCycleGroupSelectState
	extends ISelectFormControlState<InvoiceCycleGroup> {}

export class InvoiceCycleGroupSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	InvoiceCycleGroup,
	IsMulti,
	IInvoiceCycleGroupSelectProps<IsMulti>,
	IInvoiceCycleGroupSelectState
> {
	public resourceClass = InvoiceCycleGroupResource;
	public state: IInvoiceCycleGroupSelectState = {
		formLabel: 'Invoice Cycle Group',
		valueKey: 'invoiceCycleGroupId',
		labelKey: 'label'
	};
}
