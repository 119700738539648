import {
	SipAgentStatisticIndexRequest,
	SipAgentStatisticIndexResponse
} from 'RtModels';
import { useGetSipAgentStats } from 'RtUi/app/rtLcr/SipAgentStats/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSipAgentStatsColumns } from 'RtUi/components/data/DataGrid/configurations/rtLcr/SipAgentStats';
import { useMemo } from 'react';

interface ISipAgentStatsDataGridProps {
	resourceParams?: SipAgentStatisticIndexRequest;
	pageName: string;
}

export const SipAgentStatsDataGrid = ({
	resourceParams,
	pageName
}: ISipAgentStatsDataGridProps) => {
	const { data, isLoading } = useGetSipAgentStats(resourceParams);
	const columns = useMemo(
		() =>
			getSipAgentStatsColumns(
				Boolean(resourceParams?.showServerInfo),
				Boolean(resourceParams?.showDailyTotals)
			),
		[resourceParams]
	);

	return (
		<DataGrid<SipAgentStatisticIndexResponse>
			data={data?.data}
			loading={isLoading}
			pageName={pageName}
			columns={columns}
			totalRows={data?.totalCount}
		/>
	);
};
