import { FC, useState } from 'react';
import { useMount } from 'react-use';
import { ServiceNumberProfileResponse } from 'RtModels';
import { ServiceNumberEditor } from 'RtUi/app/rtCommon/ServiceNumbers/lib/forms/ServiceNumberEditor';
import { ServiceNumberResource } from 'RtUi/app/rtCommon/ServiceNumbers/lib/resources/ServiceNumberResource';
import { GlobalNumbersRouter } from 'RtUi/app/rtVue/GlobalNumbers/GlobalNumbersRouter';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

export const GlobalNumbersProfile: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const serviceNumber = useIdParam();
	const Tabs = GlobalNumbersRouter.getProfileTabs();
	const [activeTab, setActiveTab] = useState<string>(Tabs.Profile.header);
	const [globalNumber, setGlobalNumber] =
		useState<ServiceNumberProfileResponse>();

	useMount(() => {
		async function getTemplate() {
			const serviceNumberResource = new ServiceNumberResource();
			const serviceNumberRes = await serviceNumberResource.get(serviceNumber);

			setGlobalNumber(serviceNumberRes);
		}

		getTemplate();
	});

	if (!globalNumber) {
		return <Loading />;
	}

	return (
		<TabbedLayout<ServiceNumberProfileResponse>
			router={GlobalNumbersRouter}
			profile={globalNumber}
			activeTab={activeTab}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...Tabs.Profile}>
				<ServiceNumberEditor
					wide
					value={globalNumber}
					onSuccess={setGlobalNumber}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

GlobalNumbersRouter.setProfileRtUiFunctionalComponent(GlobalNumbersProfile);
