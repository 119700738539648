import { RtVueModuleRouter } from '../RtVue.router';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianServiceNumberSubscriptionIndexResponse,
	GuardianServiceNumberSubscriptionProfileResponse
} from 'RtModels';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface GuardianServiceNumberTabsType
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	History: TabbedLayoutTabProps;
	GlobalData: TabbedLayoutTabProps;
}

export const GuardianServiceNumberTabs: GuardianServiceNumberTabsType = {
	Profile: { header: 'Profile' },
	History: { header: 'History' },
	GlobalData: { header: 'Global Data' }
};

export class GuardianServiceNumberSubscriptionRouterClass extends RtVueModuleRouter<
	GuardianServiceNumberSubscriptionIndexResponse,
	GuardianServiceNumberSubscriptionProfileResponse,
	GuardianServiceNumberTabsType
> {
	constructor() {
		super(
			'Service Number Management',
			'serviceNumberSubscriptions',
			'guardianServiceNumberSubscriptionId',
			GuardianServiceNumberTabs
		);

		this.setPermissionsFromApiRoute(
			RtVueApiRoutes.GuardianServiceNumberSubscriptions
		);
	}

	/**
	 * By pass pluralization of name
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getIndexLabel(index: GuardianServiceNumberSubscriptionIndexResponse) {
		return index.serviceNumber;
	}

	public getProfileLabel(
		profile: GuardianServiceNumberSubscriptionProfileResponse
	) {
		return profile.serviceNumber;
	}
}

export const GuardianServiceNumberSubscriptionRouter =
	new GuardianServiceNumberSubscriptionRouterClass();
