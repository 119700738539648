import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { ConnectionSubscriptionRouter } from 'RtUi/app/rtVue/ConnectionSubscription/ConnectionSubscription.router';
import { GuardianConnectionSubscriptionProfileResponse } from 'RtModels';
import { ConnectionSubscriptionResource } from 'RtUi/app/rtVue/ConnectionSubscription/lib/resources/ConnectionSubscriptionResource';
import { ConnectionSubscriptionFormEditor } from 'RtUi/app/rtVue/ConnectionSubscription/lib/forms/ConnectionSubscriptionEditor';

interface IConnectionSubscriptionContainerState {
	profile?: GuardianConnectionSubscriptionProfileResponse;
	activeTab: string;
}

@ConnectionSubscriptionRouter.getProfileRtUiController()
export class ConnectionSubscriptionProfileContainer extends ProfileApplicationContainer<
	{},
	IConnectionSubscriptionContainerState
> {
	public Tabs = ConnectionSubscriptionRouter.getProfileTabs();

	public state: IConnectionSubscriptionContainerState = {
		activeTab: this.Tabs.Profile.header
	};

	public async componentDidMount() {
		const connectionSubscriptionResource = new ConnectionSubscriptionResource();
		const profile = await connectionSubscriptionResource.get(this.getIdParam());
		this.setState({ profile });
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return null;
		}

		return (
			<TabbedLayout<GuardianConnectionSubscriptionProfileResponse>
				router={ConnectionSubscriptionRouter}
				profile={profile}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<ConnectionSubscriptionFormEditor profile={profile} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
