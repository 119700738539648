/**
 * flattenObject( {"a":1, "b": {"c": 3 } })
 * Output: {"a":1, "b.c": 3}
 * @param object any object
 * @param separator that separates nested object properties after flatten
 */
export function flattenObject(object: any, separator: string = '.') {
	return Object.assign(
		{},
		...(function _deep(_object, _path: string[] = []): any {
			return [].concat(
				...Object.keys(_object).map((key) => {
					if (
						typeof _object[key] === 'object' &&
						_object[key] !== null &&
						_object[key] !== undefined
					) {
						return _deep(_object[key], [..._path, ...[key]]);
					}
					return { [[..._path, ...[key]].join(separator)]: _object[key] };
				})
			);
		})(object)
	);
}
