/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TimeRange = {
    properties: {
        mode: {
            type: 'TimeRangePresets',
            isRequired: true,
        },
        start: {
            type: 'Date',
            format: 'date-time',
        },
        end: {
            type: 'Date',
            format: 'date-time',
        },
    },
};