import { RespOrgIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getRespOrgColumns = (): Array<
	DataGridColumn<RespOrgIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'respOrgId',
		header: 'RespOrg Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary (SOMOS)'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	IntegerDataGridColumn({
		accessorKey: 'assigned',
		header: 'Assigned',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'disconnected',
		header: 'Disconnected',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'pending',
		header: 'Pending',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'reserved',
		header: 'Reserved',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'transitional',
		header: 'Transitional',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'unavailable',
		header: 'Unavailable',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'working',
		header: 'Working',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'total',
		header: 'Total',
		showFooter: true
	})
];
