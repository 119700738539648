import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { rocStatusToLabel } from 'RtUi/utils/maps';

interface RocStatusDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const RocStatusDataGridColumn = <T = any,>({
	...config
}: RocStatusDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: number) => {
		return rocStatusToLabel(value) || '';
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<number>())
	});
};
