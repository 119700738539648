/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationConfigLingo = {
    properties: {
        accountNumber: {
            type: 'string',
            isRequired: true,
        },
        serviceTypeId: {
            type: 'number',
            isRequired: true,
        },
        intraLata: {
            type: 'number',
            isRequired: true,
            maximum: 1,
        },
        interLata: {
            type: 'number',
            isRequired: true,
            maximum: 1,
        },
        subscriptionId: {
            type: 'number',
            isNullable: true,
        },
        serviceInstanceId: {
            type: 'number',
            isNullable: true,
        },
        switchId: {
            type: 'number',
            isRequired: true,
        },
        trunkGroupId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        directory: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        routeListId: {
            type: 'number',
            isNullable: true,
        },
        locationCode: {
            type: 'string',
            isNullable: true,
        },
        piccFeeWaiver: {
            type: 'number',
            isNullable: true,
        },
        isAccountCode: {
            type: 'number',
            isNullable: true,
            maximum: 1,
        },
        respOrg: {
            type: 'string',
            isNullable: true,
        },
        overflow: {
            type: 'string',
            isNullable: true,
        },
        noPayPhone: {
            type: 'number',
            isNullable: true,
            maximum: 1,
        },
    },
};