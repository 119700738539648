import { TemplateLcrCarrierAvailableIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { TemplateLcrCarrierAvailable } from 'RtUi/app/rtLco/Templates/lib/resources/TemplateLcrCarrierAvailable';

interface ITemplateLcrCarrierSelectProps
	extends ISelectFormControlProps<TemplateLcrCarrierAvailableIndexResponse> {
	templateName: string;
}

export class TemplateLcrCarrierSelect extends SelectFormControl<
	TemplateLcrCarrierAvailableIndexResponse,
	false,
	ITemplateLcrCarrierSelectProps
> {
	public resourceClass = undefined;
	public state: ISelectFormControlState<TemplateLcrCarrierAvailableIndexResponse> =
		{
			formLabel: 'Account',
			valueKey: 'lcrCarrierId',
			labelKey: 'label',
			placeholder: 'Select Account...'
		};

	constructor(props: ITemplateLcrCarrierSelectProps) {
		super(props);

		this.resource = new TemplateLcrCarrierAvailable(this.props.templateName);
	}
}
