import { RtxSipApiRoutes } from 'RtExports/routes';
import { PromptIndexResponse, PromptProfileResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
interface IPromptSegmentsContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const PromptSegmentsContainerTabs: IPromptSegmentsContainerTabs = {
	Profile: {
		header: 'Profile'
	}
};

class PromptSegmentRouterClass extends SipApplicationRouter<
	PromptIndexResponse,
	PromptProfileResponse,
	IPromptSegmentsContainerTabs
> {
	constructor() {
		super(
			'Prompt Segments',
			'promptSegments',
			'promptId',
			PromptSegmentsContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.Prompts);
	}

	public getProfileLabel(profile: PromptProfileResponse) {
		return profile.label;
	}
}

export const PromptSegmentRouter = new PromptSegmentRouterClass();
