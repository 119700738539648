import { isNumber } from 'lodash-es';

export const calculatePercentVariance = (statToCompare: any, delta: any) => {
	if (
		!isNumber(statToCompare) ||
		Number(statToCompare) === 0 ||
		!isNumber(delta)
	) {
		return 0;
	}

	const difference = Math.abs(statToCompare - delta);
	const variance = difference / statToCompare;
	const percentVariance = 100 * variance;

	return percentVariance;
};
