import { IntegrationErrorMessage } from 'RtModels';
import { IntegrationErrorMessageResource } from 'RtUi/app/rtAdmin/IntegrationErrorMessages/lib/resource/IntegrationErrorMessageResource';
import { IntegrationErrorMessagesArrayResource } from 'RtUi/app/rtAdmin/IntegrationErrorMessages/lib/resource/IntegrationErrorMessagesArrayResource';
import { useGetErrorMessages } from 'RtUi/app/rtAdmin/IntegrationErrorMessages/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getIntegrationErrorMessageColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/integrationErrorMessages';
import { pick } from 'lodash-es';
import { useMemo } from 'react';

interface IIntegrationErrorMessagesDataGridProps {
	resource?: IntegrationErrorMessagesArrayResource | null;
	onRowClick?: (row: IntegrationErrorMessage) => void;
	resourceParams?: any;
	autoFocusFilter?: boolean;
}

export const IntegrationErrorMessagesDataGrid =
	({}: IIntegrationErrorMessagesDataGridProps) => {
		const columns = useMemo(() => getIntegrationErrorMessageColumns(), []);
		const { data, refetch, isLoading } = useGetErrorMessages();

		const onUpdate = async (
			data: IntegrationErrorMessage,
			changedField: any,
			newValue: any
		) => {
			const integrationErrorMessageResource =
				new IntegrationErrorMessageResource();
			const updatedData: IntegrationErrorMessage = {
				...data,
				[changedField]: newValue
			};

			const payload = pick(updatedData, [
				'label',
				'summary',
				'isActive',
				'isWarning'
			]);
			const integrationErrorMessageId = updatedData.integrationErrorMessageId;
			try {
				await integrationErrorMessageResource.update(
					integrationErrorMessageId,
					{
						...payload
					}
				);
				await refetch();
			} catch (e) {}
		};

		return (
			<>
				<DataGrid<IntegrationErrorMessage>
					data={data?.data}
					columns={columns}
					pageName={'rtAdmin-errorMessage'}
					inlineEdit={true}
					onRowEdit={onUpdate}
					loading={isLoading}
					totalRows={data?.totalCount}
				/>
			</>
		);
	};
