import { RtxApiRoutes } from 'RtExports/routes';
import { UserIndexResponse } from 'RtModels';
import { AdministrationRouter } from 'RtUi/app/Administration/Administration.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IAdminUserContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Permissions: TabbedLayoutTabProps;
	PasswordReset: TabbedLayoutTabProps;
	RemoveTFA: TabbedLayoutTabProps;
}

export const AdminUserContainerTabs: IAdminUserContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Permissions: {
		header: 'Permissions',
		rtRoute: RtxApiRoutes.UserPermissions.Update
	},
	PasswordReset: {
		header: 'Password Reset',
		rtRoute: RtxApiRoutes.UserForceResetPassword.Create
	},
	RemoveTFA: {
		header: 'Remove TFA',
		rtRoute: RtxApiRoutes.User2FA.Delete
	}
};

class AdminUserRouterClass extends AdministrationRouter<
	UserIndexResponse,
	UserIndexResponse,
	IAdminUserContainerTabs
> {
	constructor() {
		super('User', 'users', 'userId', AdminUserContainerTabs);

		this.setPermissionsFromApiRoute(RtxApiRoutes.Users);
	}

	public getProfileLabel(profile: UserIndexResponse) {
		return `${profile.firstName} ${profile.lastName}`;
	}
}

export const AdminUserRouter = new AdminUserRouterClass();
