/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ViewCdrIndexResponse = {
    properties: {
        vuid: {
            type: 'string',
            isRequired: true,
        },
        fileStreamId: {
            type: 'number',
            isRequired: true,
        },
        fileName: {
            type: 'string',
            isRequired: true,
        },
        ingressCallId: {
            type: 'string',
            isRequired: true,
        },
        ingressAlternateId: {
            type: 'string',
            isRequired: true,
        },
        ingressConnectionId: {
            type: 'number',
            isRequired: true,
        },
        ingressTrunkGroupId: {
            type: 'string',
            isRequired: true,
        },
        ingressToIp: {
            type: 'string',
            isRequired: true,
        },
        ingressFrIp: {
            type: 'string',
            isRequired: true,
        },
        ingressCic: {
            type: 'string',
            isRequired: true,
        },
        ingressInviteTs: {
            type: 'string',
            isRequired: true,
        },
        ingressAnswerTs: {
            type: 'string',
            isRequired: true,
        },
        ingressProgressTs: {
            type: 'string',
            isRequired: true,
        },
        ingressReleaseTs: {
            type: 'string',
            isRequired: true,
        },
        ingressResultId: {
            type: 'string',
            isRequired: true,
        },
        ingressReleaseId: {
            type: 'number',
            isRequired: true,
        },
        ingressConnect: {
            type: 'number',
            isRequired: true,
        },
        ingressRawDurationMs: {
            type: 'number',
            isRequired: true,
        },
        ingressPddMs: {
            type: 'number',
            isRequired: true,
        },
        ingressIsFinal: {
            type: 'number',
            isRequired: true,
        },
        egressCallId: {
            type: 'string',
            isRequired: true,
        },
        egressAlternateId: {
            type: 'string',
            isRequired: true,
        },
        egressConnectionId: {
            type: 'number',
            isRequired: true,
        },
        egressTrunkGroupId: {
            type: 'string',
            isRequired: true,
        },
        egressToIp: {
            type: 'string',
            isRequired: true,
        },
        egressFrIp: {
            type: 'string',
            isRequired: true,
        },
        egressCic: {
            type: 'string',
            isRequired: true,
        },
        egressInviteTs: {
            type: 'string',
            isRequired: true,
        },
        egressAnswerTs: {
            type: 'string',
            isRequired: true,
        },
        egressProgressTs: {
            type: 'string',
            isRequired: true,
        },
        egressReleaseTs: {
            type: 'string',
            isRequired: true,
        },
        egressResultId: {
            type: 'string',
            isRequired: true,
        },
        egressReleaseId: {
            type: 'number',
            isRequired: true,
        },
        egressConnect: {
            type: 'number',
            isRequired: true,
        },
        egressRawDurationMs: {
            type: 'number',
            isRequired: true,
        },
        egressPddMs: {
            type: 'number',
            isRequired: true,
        },
        egressRouteDepth: {
            type: 'number',
            isRequired: true,
        },
        egressRouteList: {
            type: 'string',
            isRequired: true,
        },
        ingressToRaw: {
            type: 'string',
            isRequired: true,
        },
        ingressToStd: {
            type: 'string',
            isRequired: true,
        },
        ingressToLrn: {
            type: 'string',
            isRequired: true,
        },
        ingressToIso: {
            type: 'string',
            isRequired: true,
        },
        ingressToDialCode: {
            type: 'string',
            isRequired: true,
        },
        ingressToRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        ingressToRateCenterTypeId: {
            type: 'number',
            isRequired: true,
        },
        ingressToLrnDialCode: {
            type: 'string',
            isRequired: true,
        },
        egressToRaw: {
            type: 'string',
            isRequired: true,
        },
        egressToStd: {
            type: 'string',
            isRequired: true,
        },
        egressToLrn: {
            type: 'string',
            isRequired: true,
        },
        egressToIso: {
            type: 'string',
            isRequired: true,
        },
        egressToDialCode: {
            type: 'string',
            isRequired: true,
        },
        egressToRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        egressToRateCenterTypeId: {
            type: 'number',
            isRequired: true,
        },
        egressToLrnDialCode: {
            type: 'string',
            isRequired: true,
        },
        ingressFrRaw: {
            type: 'string',
            isRequired: true,
        },
        ingressFrStd: {
            type: 'string',
            isRequired: true,
        },
        ingressFrLrn: {
            type: 'string',
            isRequired: true,
        },
        ingressFrIso: {
            type: 'string',
            isRequired: true,
        },
        ingressFrDialCode: {
            type: 'string',
            isRequired: true,
        },
        ingressFrRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        ingressFrRateCenterTypeId: {
            type: 'number',
            isRequired: true,
        },
        ingressFrLrnDialCode: {
            type: 'string',
            isRequired: true,
        },
        ingressAiRaw: {
            type: 'string',
            isRequired: true,
        },
        ingressAiStd: {
            type: 'string',
            isRequired: true,
        },
        ingressAiLrn: {
            type: 'string',
            isRequired: true,
        },
        ingressAiIso: {
            type: 'string',
            isRequired: true,
        },
        ingressAiDialCode: {
            type: 'string',
            isRequired: true,
        },
        ingressAiRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        ingressAiRateCenterTypeId: {
            type: 'number',
            isRequired: true,
        },
        ingressAiLrnDialCode: {
            type: 'string',
            isRequired: true,
        },
        ingressMos: {
            type: 'string',
            isRequired: true,
        },
        ingressJitter: {
            type: 'string',
            isRequired: true,
        },
        ingressPacketLossPct: {
            type: 'string',
            isRequired: true,
        },
        egressMos: {
            type: 'string',
            isRequired: true,
        },
        egressJitter: {
            type: 'string',
            isRequired: true,
        },
        egressPacketLossPct: {
            type: 'string',
            isRequired: true,
        },
        ingressToIsValid: {
            type: 'number',
            isRequired: true,
        },
        ingressToIsDnc: {
            type: 'number',
            isRequired: true,
        },
        ingressFrIsValid: {
            type: 'number',
            isRequired: true,
        },
        ingressFrIsDno: {
            type: 'number',
            isRequired: true,
        },
        roboScore: {
            type: 'number',
            isRequired: true,
        },
        fraudScore: {
            type: 'number',
            isRequired: true,
        },
        anomalyScore: {
            type: 'number',
            isRequired: true,
        },
        attestation: {
            type: 'string',
            isRequired: true,
        },
        isDestSame: {
            type: 'number',
            isRequired: true,
        },
        isDestDetailSame: {
            type: 'number',
            isRequired: true,
        },
        ingressCostCarrierId: {
            type: 'string',
            isRequired: true,
        },
        ingressCostSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        ingressCostServiceId: {
            type: 'number',
            isRequired: true,
        },
        ingressCostBillingCycleId: {
            type: 'string',
            isRequired: true,
        },
        ingressCostJurisdictionId: {
            type: 'number',
            isRequired: true,
        },
        ingressCostBillingNumberUsed: {
            type: 'string',
            isRequired: true,
        },
        ingressCostJurisdictionPrecedence: {
            type: 'string',
            isRequired: true,
        },
        ingressCostRateSheetId: {
            type: 'string',
            isRequired: true,
        },
        ingressCostRateKey: {
            type: 'string',
            isRequired: true,
        },
        ingressCostRate: {
            type: 'string',
            isRequired: true,
        },
        ingressCostRejectCode: {
            type: 'string',
            isRequired: true,
        },
        ingressCostDurationMs: {
            type: 'string',
            isRequired: true,
        },
        ingressCostRateCharge: {
            type: 'string',
            isRequired: true,
        },
        ingressCostConnectionCharge: {
            type: 'string',
            isRequired: true,
        },
        ingressCostOtherCharge: {
            type: 'string',
            isRequired: true,
        },
        ingressCostTaxCharge: {
            type: 'string',
            isRequired: true,
        },
        ingressCostTotalCharge: {
            type: 'string',
            isRequired: true,
        },
        egressCostCarrierId: {
            type: 'string',
            isRequired: true,
        },
        egressCostSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        egressCostServiceId: {
            type: 'number',
            isRequired: true,
        },
        egressCostBillingCycleId: {
            type: 'string',
            isRequired: true,
        },
        egressCostJurisdictionId: {
            type: 'number',
            isRequired: true,
        },
        egressCostBillingNumberUsed: {
            type: 'string',
            isRequired: true,
        },
        egressCostJurisdictionPrecedence: {
            type: 'string',
            isRequired: true,
        },
        egressCostRateSheetId: {
            type: 'number',
            isRequired: true,
        },
        egressCostRateKey: {
            type: 'string',
            isRequired: true,
        },
        egressCostRate: {
            type: 'string',
            isRequired: true,
        },
        egressCostRejectCode: {
            type: 'number',
            isRequired: true,
        },
        egressCostDurationMs: {
            type: 'string',
            isRequired: true,
        },
        egressCostRateCharge: {
            type: 'string',
            isRequired: true,
        },
        egressCostConnectionCharge: {
            type: 'string',
            isRequired: true,
        },
        egressCostOtherCharge: {
            type: 'string',
            isRequired: true,
        },
        egressCostTaxCharge: {
            type: 'string',
            isRequired: true,
        },
        egressCostTotalCharge: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueCarrierId: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        ingressRevenueServiceId: {
            type: 'number',
            isRequired: true,
        },
        ingressRevenueBillingCycleId: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueJurisdictionId: {
            type: 'number',
            isRequired: true,
        },
        ingressRevenueBillingNumberUsed: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueJurisdictionPrecedence: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueRateSheetId: {
            type: 'number',
            isRequired: true,
        },
        ingressRevenueRateKey: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueRate: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueRejectCode: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueDurationMs: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueRateCharge: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueConnectionCharge: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueOtherCharge: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueTaxCharge: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueTotalCharge: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueCarrierId: {
            type: 'number',
            isRequired: true,
        },
        egressRevenueSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        egressRevenueServiceId: {
            type: 'number',
            isRequired: true,
        },
        egressRevenueBillingCycleId: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueJurisdictionId: {
            type: 'number',
            isRequired: true,
        },
        egressRevenueBillingNumberUsed: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueJurisdictionPrecedence: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueRateSheetId: {
            type: 'number',
            isRequired: true,
        },
        egressRevenueRateKey: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueRate: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueRejectCode: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueDurationMs: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueRateCharge: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueConnectionCharge: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueOtherCharge: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueTaxCharge: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueTotalCharge: {
            type: 'string',
            isRequired: true,
        },
        ingressMargin: {
            type: 'string',
            isRequired: true,
        },
        egressMargin: {
            type: 'string',
            isRequired: true,
        },
        ingressScenarioId: {
            type: 'number',
            isRequired: true,
        },
        egressScenarioId: {
            type: 'number',
            isRequired: true,
        },
        totalCost: {
            type: 'string',
            isRequired: true,
        },
        totalRevenue: {
            type: 'string',
            isRequired: true,
        },
        totalMargin: {
            type: 'string',
            isRequired: true,
        },
        infoDigits: {
            type: 'number',
            isRequired: true,
        },
        calculatedJurisdiction: {
            type: 'string',
            isRequired: true,
        },
        calculatedCallType: {
            type: 'string',
            isRequired: true,
        },
        customField1: {
            type: 'string',
            isRequired: true,
        },
        customField2: {
            type: 'string',
            isRequired: true,
        },
        customField3: {
            type: 'string',
            isRequired: true,
        },
        customField4: {
            type: 'string',
            isRequired: true,
        },
        customField5: {
            type: 'string',
            isRequired: true,
        },
        cdrRecord: {
            type: 'string',
            isRequired: true,
        },
        loadDateTs: {
            type: 'number',
            isRequired: true,
        },
    },
};