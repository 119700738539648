import {
	CacheStrategy,
	TCacheStrategySearchCriteria
} from 'RtUi/utils/cache/CacheStrategy';
import * as moment from 'moment-timezone';

/**
 * CacheStrategy for localStorage usage
 */
export class LocalStorageCacheStrategy<T> extends CacheStrategy<T> {
	constructor(
		protected collectionName: string,
		timeToLive?: moment.Duration
	) {
		super(timeToLive, collectionName);
	}
	/**
	 * Clear cache from localStorage
	 */
	public async invalidateCacheInternal(): Promise<boolean> {
		localStorage.removeItem(this.collectionName);

		return true;
	}

	/**
	 * get all cache
	 */
	protected async getAllInternal() {
		const recordsStr = localStorage.getItem(this.collectionName);
		const isoPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
		let records: T[] = [];

		if (recordsStr) {
			//Revive ISO DateTime strings
			records = JSON.parse(recordsStr, (key, value) => {
				if (typeof value === 'string' && isoPattern.test(value)) {
					return new Date(value);
				}

				return value;
			});
		}

		return records;
	}

	/**
	 * Uses standard search
	 * @param criteria
	 */
	protected async searchInternal(
		records: T[],
		criteria: Array<TCacheStrategySearchCriteria<T>>
	): Promise<T[]> {
		return this.standardSearch(records, criteria);
	}

	/**
	 * Store records in localStorage
	 * @param records
	 */
	protected async storeCacheInternal(records: T[]): Promise<boolean> {
		try {
			const recordsStr = JSON.stringify(records);

			localStorage.setItem(this.collectionName, recordsStr);
		} catch {
			return false;
		}

		return true;
	}
}
