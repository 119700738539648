import { ResourceIndexRequest, ResourceIndexResponse } from 'RtModels';
import { IClassType } from 'RtUi/app/@RtUi/interfaces';
import { ResourceResource } from 'RtUi/app/rtCommon/Resources/lib/resources/ResourceResource';
import { useGetConnections } from 'RtUi/app/rtDid/Resources/service';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getResourceColumns } from 'RtUi/components/data/DataGrid/configurations/rtDid/resources';
import { useMemo } from 'react';

interface IConnectionGridProps {
	router?: RtUiRouter;
	resourceClass?: IClassType<ResourceResource>;
	onRowClick?: (row: ResourceIndexResponse) => void;
	resourceParams?: ResourceIndexRequest;
	autoFocusFilter?: boolean;
}

export const ConnectionsGrid = ({
	resourceParams,
	router,
	onRowClick
}: IConnectionGridProps): JSX.Element => {
	const { data, isFetching: isLoading } = useGetConnections(resourceParams);
	const columns = useMemo(() => getResourceColumns(), []);

	return (
		<DataGrid<ResourceIndexResponse>
			data={data}
			columns={columns}
			router={router}
			loading={isLoading}
			onRowClick={onRowClick}
			pageName={'rtDid_connections'}
		/>
	);
};
