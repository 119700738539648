import * as JSURL from 'jsurl';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import {
	DataFilter,
	GlobalUserQueryIdentifiers,
	ReportAggregates,
	ReportDataColumnPresets,
	SubscriptionIndexResponse,
	TimeFilter
} from 'RtModels';
import { PossibleFilterNames } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { PartialRecord } from 'RtUi/utils/types';
import { URL_VALUES_SEPARATOR } from 'RtUi/utils/urlParams/UrlParamsUtils';

type VueReportUrlSearchParams = {
	srcReconciliation: string;
	tgtReconciliation: string;
	dialedNumber: string;
	ingressTrunkGroupId: string;
	egressTrunkGroupId: string;
	template: string;
	filter: DataFilter[];
	date: string;
	additionalColumns: string[];
	timeRange: TimeFilter;
	partition: string;
	aggregates: ReportAggregates[];
	preset: ReportDataColumnPresets;
	userQueryIdent: GlobalUserQueryIdentifiers;
	fileStreamId: string;
	fileStreamId1: string;
	fileStreamId2: string;
	trunkGroup1: string;
	trunkGroup2: string;
	customerExternalAccountId1?: string;
	customerExternalAccountId2?: string;
	vendorExternalAccountId1?: string;
	vendorExternalAccountId2?: string;
	customerExternalSubscriptionId1?: SubscriptionIndexResponse;
	customerExternalSubscriptionId2?: SubscriptionIndexResponse;
	vendorExternalSubscriptionId1?: SubscriptionIndexResponse;
	vendorExternalSubscriptionId2?: SubscriptionIndexResponse;
};

export type VueReportUrlSearchParamNames =
	| keyof VueReportUrlSearchParams
	| PossibleFilterNames;

export type RtVueReportUrlSearchParamsCreate = PartialRecord<
	VueReportUrlSearchParamNames,
	string
> & {
	userQueryIdent?: GlobalUserQueryIdentifiers;
};

export class RtVueReportUrlSearchParams {
	public static create(
		searchParams: RtVueReportUrlSearchParamsCreate,
		filters: DataFilter[] = [],
		timeRange?: TimeFilter
	) {
		const instance = new RtVueReportUrlSearchParams();

		for (const [name, value] of Object.entries<string | undefined>(
			searchParams
		)) {
			if (typeof value === 'undefined') {
				continue;
			}

			instance.set(name as VueReportUrlSearchParamNames, value);
		}

		for (const filter of filters) {
			instance.addDataFilter(filter);
		}

		if (timeRange) {
			instance.setTimeRange(timeRange);
		}

		return instance;
	}

	protected urlSearchParams!: URLSearchParams;

	constructor(location?: Location) {
		this.urlSearchParams = new URLSearchParams(location?.search);
	}

	public has(name: VueReportUrlSearchParamNames) {
		return this.urlSearchParams.has(name);
	}

	public get(name: VueReportUrlSearchParamNames) {
		return this.urlSearchParams.get(name);
	}

	public getPreset(): VueReportUrlSearchParams['preset'] | null {
		const preset = this.get('preset');

		if (preset === null) {
			return null;
		}

		return preset as VueReportUrlSearchParams['preset'];
	}

	public getUserQueryIdent():
		| VueReportUrlSearchParams['userQueryIdent']
		| null {
		const userQueryIdent = this.get('userQueryIdent');

		if (userQueryIdent === null) {
			return null;
		}

		return userQueryIdent as VueReportUrlSearchParams['userQueryIdent'];
	}

	public getFilters(): VueReportUrlSearchParams['filter'] | null {
		const filtersStr = this.get('filter');

		if (filtersStr === null) {
			return null;
		}

		return decodeURIComponent(filtersStr)
			.split(URL_VALUES_SEPARATOR)
			.map((filterStr) => JSURL.parse(filterStr));
	}

	public getAggregates(): VueReportUrlSearchParams['aggregates'] | null {
		const aggregatesStr = this.get('aggregates');

		if (aggregatesStr === null) {
			return null;
		}

		return aggregatesStr.split(
			URL_VALUES_SEPARATOR
		) as VueReportUrlSearchParams['aggregates'];
	}

	public getAdditionalColumns():
		| VueReportUrlSearchParams['additionalColumns']
		| null {
		const additionalColumnsStr = this.get('additionalColumns');

		if (additionalColumnsStr === null) {
			return null;
		}

		return additionalColumnsStr
			.split(URL_VALUES_SEPARATOR)
			.map(decodeURIComponent);
	}

	public getTimeRange(): VueReportUrlSearchParams['timeRange'] | null {
		const timeRangeStr = this.get('timeRange');

		if (timeRangeStr === null) {
			return null;
		}

		return JSURL.parse(timeRangeStr) as VueReportUrlSearchParams['timeRange'];
	}

	public addDataFilter(filter: DataFilter) {
		const filterStr = JSURL.stringify(filter) as string;
		let currentFilterStr = this.get('filter');

		if (currentFilterStr) {
			currentFilterStr = currentFilterStr.concat(
				URL_VALUES_SEPARATOR,
				filterStr
			);
		} else {
			currentFilterStr = filterStr;
		}

		this.set('filter', currentFilterStr);
	}

	public setDataFilters(filters: DataFilter[]) {
		this.delete('filter');

		for (const filter of filters) {
			this.addDataFilter(filter);
		}
	}

	public setTimeRange(timeRange: TimeFilter) {
		const copiedTimeRange: any = cloneDeep(timeRange);
		const { start, end } = copiedTimeRange.timeObject;

		const startIsoStr =
			typeof start === 'string' ? start : start?.toISOString();
		const endIsoStr = typeof end === 'string' ? end : end?.toISOString();

		copiedTimeRange.timeObject.start = moment(startIsoStr)
			.utcOffset(0, true)
			.toDate()
			.toISOString();
		copiedTimeRange.timeObject.end = moment(endIsoStr)
			.utcOffset(0, true)
			.toDate()
			.toISOString();

		this.set('timeRange', JSURL.stringify(copiedTimeRange));
	}

	public set(name: VueReportUrlSearchParamNames, value: string) {
		if (name === 'date') {
			value = new Date(value).toISOString().split('T')[0];
		}

		return this.urlSearchParams.set(name, encodeURIComponent(value));
	}

	public setWithArray(name: VueReportUrlSearchParamNames, value: string[]) {
		if (value.length <= 0) {
			return this.delete(name);
		}

		const valueStr = value.join(URL_VALUES_SEPARATOR);

		return this.urlSearchParams.set(name, valueStr);
	}

	public delete(name: VueReportUrlSearchParamNames) {
		return this.urlSearchParams.delete(name);
	}

	public toString() {
		return this.urlSearchParams.toString();
	}

	public getEntries() {
		return this.urlSearchParams.entries();
	}
}
