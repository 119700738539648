import { isEqual } from 'lodash-es';
import * as moment from 'moment';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import {
	CprNumberIndexRequest,
	EntityIndexResponse,
	RespOrgIndexResponse,
	TemplateIndexResponse
} from 'RtModels';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { useFormInitializer } from 'RtUi/components/rtx/form/hooks/useFormInitializer';
import { RtxUiSearchFormComponent } from 'RtUi/components/rtx/form/RtxUiSearchForm';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import {
	CprStatusSelect,
	ICprStatusOption
} from '../Cprs/lib/controls/CprStatusSelect';
import { TollFreeNumbersTextArea } from '../Numbers/lib/controls/TollFreeNumbersTextArea';
import { TollNumberInput } from '../Numbers/lib/controls/TollNumberInput';
import { CprSearchRouter } from './CprSearch.router';
import { CprNumbersGrid } from './lib/grids/CprNumbersGrid';

export const CprSearchIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search', isDefault: true },
		Results: { header: 'Results' }
	});
	const formInitializer = useFormInitializer();
	const [entity, setEntity] = useState<EntityIndexResponse>();
	const [respOrg, setRespOrg] = useState<RespOrgIndexResponse>();
	const [template, setTemplate] = useState<TemplateIndexResponse>();
	const [cprStatuses, setCprStatuses] = useState<ICprStatusOption[]>();
	const [resourceParams, setResourceParams] = useState<CprNumberIndexRequest>(
		{}
	);

	const submitHandler = (data: CprNumberIndexRequest) => {
		setResourceParams(data);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={CprSearchRouter}
			activeTab={activeTab.header}
			onTabChange={(activeTab) => setActiveTab(activeTab)}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtxUiSearchFormComponent<CprNumberIndexRequest>
					initializer={formInitializer}
					onSubmit={submitHandler}
					onLoadUrlParams={(urlParams) => {
						if (!isEqual(urlParams, resourceParams)) {
							submitHandler(urlParams);
						}
					}}
				>
					{({ control }) => (
						<Row>
							<Col xl={6}>
								<Controller
									control={control}
									name="entityId"
									render={({ field: { onChange, value } }) => (
										<EntitySelect
											onChange={(entity) => {
												setEntity(entity);
												onChange(entity?.entityId);
											}}
											value={entity}
											initialOptionId={value}
										/>
									)}
								/>
								<Controller
									control={control}
									name="respOrgId"
									render={({ field: { onChange, value } }) => (
										<RespOrgSelect
											entityId={entity?.entityId}
											onChange={(respOrg) => {
												setRespOrg(respOrg);
												onChange(respOrg?.respOrgId);
											}}
											value={respOrg}
											initialOptionId={value}
										/>
									)}
								/>
								<Controller
									control={control}
									name="templateName"
									render={({ field: { onChange, value } }) => (
										<TemplateSelect
											entityId={entity?.entityId}
											onChange={(template) => {
												setTemplate(template);
												onChange(template?.templateName);
											}}
											value={template}
											respOrgId={respOrg?.respOrgId}
											initialOptionId={value}
										/>
									)}
								/>
								<Controller
									control={control}
									name="cprStatusIds"
									render={({ field: { onChange, value = [] } }) => (
										<CprStatusSelect<true>
											multi={true}
											onChange={(cprStatuses) => {
												setCprStatuses(cprStatuses);
												onChange(cprStatuses?.map((status) => status.value));
											}}
											value={cprStatuses}
											// eslint-disable-next-line @typescript-eslint/no-unsafe-call
											initialOptionId={value.map((v) => String(v))}
										/>
									)}
								/>
								<Controller
									control={control}
									name="isAssignedToTemplate"
									render={({ field: { onChange, value } }) => (
										<RadioFormControl<number>
											label="Template Assigned"
											onChange={onChange}
											value={value}
											options={[
												{
													value: 1,
													label: 'Yes',
													tooltip: 'Status="Working" will apply'
												},
												{
													value: 0,
													label: 'No',
													tooltip: 'Status="Working" will apply'
												},
												{ value: -1, label: 'Both' }
											]}
										/>
									)}
								/>
							</Col>
							<Col xl={6}>
								<Controller
									control={control}
									name="search"
									render={({ field: { onChange, value } }) => (
										<ControlGroup label="Pattern" required>
											<TollNumberInput
												onChange={(e) => onChange(e.currentTarget.value)}
												value={value}
												alwaysShowMask
												required
												allowWildcards={true}
											/>
										</ControlGroup>
									)}
								/>
								<Controller
									control={control}
									name="effectiveAfterTs"
									render={({ field: { onChange, value } }) => (
										<DatetimeFormControl
											label="CPR Effective After"
											disableNowPreset
											onChange={onChange}
											value={moment(value)}
										/>
									)}
								/>
								<Controller
									control={control}
									name="effectiveBeforeTs"
									render={({ field: { onChange, value } }) => (
										<DatetimeFormControl
											label="CPR Effective Before"
											disableNowPreset
											onChange={onChange}
											value={moment(value)}
										/>
									)}
								/>
								<Controller
									control={control}
									name="tfns"
									render={({ field: { onChange, value } }) => (
										<TollFreeNumbersTextArea
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Col>
						</Row>
					)}
				</RtxUiSearchFormComponent>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<CprNumbersGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

CprSearchRouter.setIndexRtUiFunctionalComponent(CprSearchIndexContainer);
