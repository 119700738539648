import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn,
	FallbackHeaderDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { GenericTypeRender } from 'RtUi/components/ui/GenericTypeRenderer';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';
import { isNil } from 'lodash-es';

export interface ResourceDataGridColumnConfigurationInstance<R, T>
	extends Omit<ResourceDataGridColumnConfiguration<R, T>, 'resourceCacheMap'> {}

interface ResourceDataGridColumnConfiguration<R, T>
	extends Omit<
		FallbackHeaderDataGridColumnConfiguration<T>,
		'sortingFn' | 'getValue'
	> {
	resourceCacheMap: ResourceCacheMap<R>;
	header?: string;
	rawValue?: boolean;
}

export const ResourceDataGridColumn = <R extends object, T = any>({
	rawValue = false,
	header = 'Resource',
	size = 250,
	maxSize = 1000,
	align = DataGridColumnAlignment.LEFT,
	resourceCacheMap,
	...config
}: ResourceDataGridColumnConfiguration<R, T>): DataGridColumn<T> => {
	return DefaultDataGridColumn({
		...config,
		header,
		size,
		maxSize,
		align,
		isInvisible:
			config.isInvisible || !resourceCacheMap.userHasRequiredPermissions(),
		globalFilterFn: (value, filterValue) => {
			const columnValue = resourceCacheMap.getLabelFor(value) || String(value);

			return columnValue.toLowerCase().includes(filterValue.toLowerCase());
		},
		filterFn: (row, id, filterValue) => {
			const value = rawValue
				? row.getValue<string>(id)
				: resourceCacheMap.getLabelFor(row.getValue(id));

			return (value || '')
				.toLowerCase()
				.includes(String(filterValue).toLowerCase());
		},
		sortingFn: (rowA, rowB, columnId) => {
			const summaryA = String(rowA.getValue(columnId) ?? '');
			if (!summaryA) {
				return -1;
			}

			const summaryB = String(rowB.getValue(columnId) ?? '');

			if (!summaryB) {
				return -1;
			}

			return summaryA.localeCompare(summaryB);
		},
		exportValue: (value: T[keyof T]) => {
			if (isNil(value)) {
				return '';
			}

			if (rawValue) {
				return String(value);
			}

			return resourceCacheMap.getLabelFor(value as any) || String(value);
		},
		getValue: ({ cell }) => {
			const value = cell.getValue<number>();

			if (isNil(value)) {
				return '';
			}

			if (rawValue) {
				return value;
			}

			return (
				<GenericTypeRender<R>
					id={cell.getValue<number>()}
					showIdInLabel={false}
					resourceCacheMap={resourceCacheMap}
				/>
			);
		}
	});
};
