import { RateLoadStep, RateLoadStatus } from 'RtModels';

//type RateDateInfo = { name: string; icon: string };
type RateLoadStepInfo = {
	activeStatus: string;
	successStatus: string;
	errorStatus: string;
	step: number;
	exceptionStatus?: string;
};

export const RateLoadStepInfoMap = new Map<RateLoadStep, RateLoadStepInfo>([
	[
		RateLoadStep.Creating,
		{
			activeStatus: 'Uploading',
			successStatus: 'Rates File Uploaded',
			errorStatus: 'Unable to upload rates file',
			step: 1
		}
	],
	[
		RateLoadStep.Parsing,
		{
			activeStatus: 'Parsing',
			successStatus: 'Rates Parsed',
			errorStatus: 'Unable to Parse',
			step: 2
		}
	],
	[
		RateLoadStep.Verification,
		{
			activeStatus: 'Verifying',
			successStatus: 'Rates Verified',
			errorStatus: 'Unable to Verify',
			step: 3
		}
	],
	[
		RateLoadStep.Importing,
		{
			activeStatus: 'Importing',
			successStatus: 'Rates Imported',
			errorStatus: 'Unable to Import',
			step: 4
		}
	],
	[
		RateLoadStep.RateAndCodeAnalysis,
		{
			activeStatus: 'Analyzing',
			successStatus: 'Pending Approval',
			errorStatus: 'Error Found',
			exceptionStatus: 'Requires Action',
			step: 5
		}
	],
	[
		RateLoadStep.RateApproval,
		{
			activeStatus: 'Requires Approval',
			successStatus: 'Rates Approved',
			errorStatus: 'Unable to Approve',
			step: 6
		}
	],
	[
		RateLoadStep.RateLoading,
		{
			activeStatus: 'Loading Rates',
			successStatus: 'Rates Loaded Successfully',
			errorStatus: 'Unable to load rates',
			step: 7
		}
	],
	[
		RateLoadStep.RateAmendment,
		{
			activeStatus: 'Creating Rate Amendment',
			successStatus: 'Rate Amendment Successfully Created',
			errorStatus: 'Unable to create Rate Amendment',
			step: 8
		}
	]
]);

export const IsCompletedOk = (status: RateLoadStatus | undefined) => {
	return status === RateLoadStatus.CompletedOk;
};

export const IsCompletedBad = (status: RateLoadStatus | undefined) => {
	const completedBadStatuses = [
		RateLoadStatus.CompletedError,
		RateLoadStatus.CompletedWithExceptions
	];

	if (typeof status === 'undefined') {
		return false;
	}

	return completedBadStatuses.includes(status);
};

export const IsCompletedStatus = (status: RateLoadStatus | undefined) => {
	const completedStatuses = [
		RateLoadStatus.CompletedError,
		RateLoadStatus.CompletedOk,
		RateLoadStatus.CompletedWithExceptions
	];

	if (typeof status === 'undefined') {
		return false;
	}

	return completedStatuses.includes(status);
};

export const GetRateLoadStatus = (
	step: RateLoadStep,
	status?: RateLoadStatus
) => {
	const stepInfo = RateLoadStepInfoMap.get(step);
	let name = stepInfo?.activeStatus ?? RateLoadStep[step];

	if (stepInfo && IsCompletedStatus(status)) {
		name = stepInfo.successStatus;

		if (IsCompletedBad(status)) {
			name = stepInfo.errorStatus;

			if (
				stepInfo.exceptionStatus &&
				status === RateLoadStatus.CompletedWithExceptions
			) {
				name = stepInfo.exceptionStatus;
			}
		}
	}

	return name;
};
