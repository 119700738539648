export type BreakpointType =
	| 'xxxs'
	| 'xxs'
	| 'xs'
	| 'sm'
	| 'md'
	| 'lg'
	| 'xl'
	| 'xxl'
	| 'xxxl';

/**
 * Get breakpoint from bootstrap
 */
export const getBreakpoint = (breakpoint: BreakpointType): number => {
	const bodyComputedStyle = window.getComputedStyle(document.body);
	const property = `--breakpoint-${breakpoint}`;
	const breakpointValue = bodyComputedStyle.getPropertyValue(property);
	const breakpointNumberStr = breakpointValue.replace(/\D/g, '');

	return Number(breakpointNumberStr);
};

/**
 * get width of the current window
 */
export const getViewportWidth = () => {
	const windowWidth = window.innerWidth || document.documentElement.clientWidth;

	return windowWidth;
};

/**
 * get height of the current window
 */
export const getViewportHeight = () => {
	const windowHeight =
		window.innerHeight || document.documentElement.clientHeight;

	return windowHeight;
};

/**
 * Is the current viewport at or BELOW a bootstrap breakpoint
 */
export function isViewportAtOrBelowBreakpoint(breakpoint: BreakpointType) {
	const windowWidth = getViewportWidth();
	const xsViewport = getBreakpoint(breakpoint);

	return windowWidth <= xsViewport;
}

/**
 * Is the current viewport at or ABOVE a bootstrap breakpoint
 */
export function isViewportAtOrAboveBreakpoint(breakpoint: BreakpointType) {
	const windowWidth = getViewportWidth();
	const xsViewport = getBreakpoint(breakpoint);

	return windowWidth >= xsViewport;
}

export const isElementInViewport = (el: HTMLElement) => {
	const rect = el.getBoundingClientRect();
	const viewportWidth = getViewportWidth();
	const viewportHeight = getViewportHeight();

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= viewportHeight &&
		rect.right <= viewportWidth /* or $(window).width() */
	);
};
