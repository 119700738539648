import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationAttachmentCreateRequest,
	GuardianReconciliationAttachmentIndexResponse,
	GuardianReconciliationAttachmentProfileUrlRequest,
	GuardianReconciliationAttachmentProfileResponse
} from 'RtModels';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ReconciliationAttachmentsResource extends HttpResource<
	GuardianReconciliationAttachmentIndexResponse,
	GuardianReconciliationAttachmentProfileResponse
> {
	private fileUtils = new FileUtils();

	constructor() {
		super(RtVueApiRoutes.GuardianReconciliationAttachments);
	}

	public create(
		file: File,
		params: GuardianReconciliationAttachmentCreateRequest
	) {
		const body = new FormData();
		body.append('file', file);

		return this.fetchWithRoute(
			RtVueApiRoutes.GuardianReconciliationAttachments.Create,
			{
				body,
				params,
				removeContentType: true
			}
		);
	}

	public delete(guardianReconciliationAttachmentId: number) {
		const urlParams: GuardianReconciliationAttachmentProfileUrlRequest = {
			guardianReconciliationAttachmentId
		};

		return this.fetchWithRoute(
			RtVueApiRoutes.GuardianReconciliationAttachments.Delete,
			{
				urlParams
			}
		);
	}

	public download(
		guardianReconciliationAttachmentId: number,
		fileName: string
	) {
		const invoiceDefer = this.getResponse(
			guardianReconciliationAttachmentId,
			fileName
		);

		return this.fileUtils.downloadFromUrlWithPromise(invoiceDefer);
	}

	public createNewProfile(
		reconciliationId: number
	): GuardianReconciliationAttachmentCreateRequest {
		return {
			reconciliationId
		};
	}

	private async getResponse(
		guardianReconciliationAttachmentId: number,
		fileName: string
	) {
		const urlParams: GuardianReconciliationAttachmentProfileUrlRequest = {
			guardianReconciliationAttachmentId
		};
		const response =
			await HttpRequest.rawFetchWithRoute<GuardianReconciliationAttachmentProfileUrlRequest>(
				RtVueApiRoutes.GuardianReconciliationAttachments.Profile,
				{ urlParams }
			);

		if (!response.ok) {
			const error: any = await response.json();

			throw error;
		}

		const blob = await response.blob();
		const url = URL.createObjectURL(blob);

		return { url, fileName: fileName };
	}
}
