import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	SipAgentConfigCreateRequest,
	SipAgentConfigDeleteResponse,
	SipAgentConfigIndexResponse,
	SipAgentConfigProfileResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class SipAgentConfigsResource extends HttpResource<
	SipAgentConfigIndexResponse,
	SipAgentConfigProfileResponse
> {
	constructor() {
		super(RtCommonApiRoutes.SipAgentConfigs);
	}

	public getProfile(
		sipAgentConfigId: number
	): Promise<SipAgentConfigProfileResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.SipAgentConfigs.Profile, {
			urlParams: {
				sipAgentConfigId
			}
		});
	}

	public create(
		req: SipAgentConfigCreateRequest
	): Promise<SipAgentConfigProfileResponse> {
		const body = JSON.stringify(req);

		return this.fetchWithRoute(RtCommonApiRoutes.SipAgentConfigs.Create, {
			body
		});
	}

	public update(
		sipAgentConfig: SipAgentConfigProfileResponse
	): Promise<SipAgentConfigProfileResponse> {
		const { sipAgentConfigId, ...req } = sipAgentConfig;
		const urlParams = {
			sipAgentConfigId
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute(RtCommonApiRoutes.SipAgentConfigs.Update, {
			urlParams,
			body
		});
	}

	public delete(
		sipAgentConfigId: number
	): Promise<SipAgentConfigDeleteResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.SipAgentConfigs.Delete, {
			urlParams: {
				sipAgentConfigId
			}
		});
	}
}
