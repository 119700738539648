import { CprNodeType } from 'RtModels';

export type CprActions =
	| 'copy'
	| 'transfer'
	| 'create'
	| 'delete'
	| 'edit'
	| 'disconnect';

export type SomosNodeTypeLabels =
	| 'Area Code'
	| 'Announce'
	| 'Carrier'
	| 'Day of Week'
	| 'Date'
	| 'LATA'
	| 'Exchange'
	| 'Go To'
	| 'Percent'
	| 'Six Digit'
	| 'State'
	| 'Ten Digit'
	| 'Destination Number' //deprecated
	| 'Destination'
	| 'Time'
	| 'Switch'
	| 'Root';

export const CprNodeTypeNameMap = new Map<CprNodeType, SomosNodeTypeLabels>([
	[CprNodeType.AreaCode, 'Area Code'],
	[CprNodeType.Announcement, 'Announce'],
	[CprNodeType.Carrier, 'Carrier'],
	[CprNodeType.DayOfWeek, 'Day of Week'],
	[CprNodeType.Date, 'Date'],
	[CprNodeType.GoTo, 'Go To'],
	[CprNodeType.Lata, 'LATA'],
	[CprNodeType.NpaNxx, 'Exchange'],
	[CprNodeType.Percent, 'Percent'],
	[CprNodeType.SixDigit, 'Six Digit'],
	[CprNodeType.State, 'State'],
	[CprNodeType.TenDigit, 'Ten Digit'],
	[CprNodeType.TerminatingNumber, 'Destination'],
	[CprNodeType.Time, 'Time'],
	[CprNodeType.Switch, 'Switch']
]);

export const NameToCprNodeTypeMap = new Map<SomosNodeTypeLabels, CprNodeType>(
	Array.from(CprNodeTypeNameMap.entries()).map(([nodeType, name]) => [
		name,
		nodeType
	])
);
