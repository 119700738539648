/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TrendAnalysisIndexResponse = {
    properties: {
        country: {
            type: 'string',
            isRequired: true,
        },
        rateCenter: {
            type: 'string',
            isRequired: true,
        },
        countryIsoId: {
            type: 'string',
            isRequired: true,
        },
        egConnects: {
            type: 'number',
            isRequired: true,
        },
        egAttempts: {
            type: 'number',
            isRequired: true,
        },
        inAttempts: {
            type: 'number',
            isRequired: true,
        },
        minutes: {
            type: 'number',
            isRequired: true,
        },
        revenue: {
            type: 'number',
            isRequired: true,
        },
        revenueAccountId: {
            type: 'number',
        },
        revenueSubscriptionId: {
            type: 'number',
        },
        cost: {
            type: 'number',
            isRequired: true,
        },
        margin: {
            type: 'number',
            isRequired: true,
        },
        mpm: {
            type: 'number',
            isRequired: true,
        },
    },
};