import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

import { MarginPlanIndexResponse } from 'RtModels';
import { MarginPlanResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/MarginPlanResource';

interface IMarginPlanSelectProps
	extends ISelectFormControlProps<MarginPlanIndexResponse> {
	valueKey?: keyof MarginPlanIndexResponse;
	placeholder?: string;
}

export class MarginPlanSelect extends SelectFormControl<
	MarginPlanIndexResponse,
	false,
	IMarginPlanSelectProps
> {
	public resourceClass = MarginPlanResource;
	public state: ISelectFormControlState<MarginPlanIndexResponse> = {
		formLabel: 'Margin Plan',
		valueKey: 'marginPlanId',
		labelKey: 'label',
		placeholder: this.props.placeholder
	};
}
