/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ReportMetadataProfileRequest = {
    properties: {
        reportId: {
            type: 'Reports',
            isRequired: true,
        },
    },
};