import { SubscriptionTagProfileResponse } from 'RtModels';
import { SubscriptionTagFormEditor } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionTagFormEditor';
import { Aside } from 'RtUi/components/ui/Aside';
import { useMemo } from 'react';

interface IAddSubscriptionTagAsideProps {
	subscriptionId?: number;
	isOpen: boolean;
	onCancel: () => void;
	onSave: () => void;
	profile?: SubscriptionTagProfileResponse;
	isMatching?: boolean;
	isAssignment?: boolean;
}

export const AddSubscriptionTagAside = (
	props: IAddSubscriptionTagAsideProps
) => {
	const label = useMemo(() => {
		if (props.isMatching) {
			return 'Tag Matching';
		}

		if (props.isAssignment) {
			return 'Tag Assignment';
		}

		return 'Tag';
	}, [props]);

	return (
		<Aside isOpen={props.isOpen} disabledBody>
			<Aside.Header
				warning={Boolean(props.profile)}
				header={label}
				onClose={() => props.onCancel()}
			/>
			<SubscriptionTagFormEditor
				profile={props.profile}
				subscriptionId={props.subscriptionId}
				onSuccess={props.onSave}
				isAssignment={props.isAssignment}
				isMatching={props.isMatching}
			/>
		</Aside>
	);
};
