import {
	SubscriptionTagProfileResponse,
	TagIndexResponse,
	CallTagMode,
	SubscriptionIndexResponse
} from 'RtModels';
import { TagSelect } from 'RtUi/app/rtSip/Scenarios/lib/controls/TagSelect';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { cloneDeep, isEqual } from 'lodash-es';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { Component } from 'react';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Form } from 'react-bootstrap';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { TagTypeSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/TagTypeSelect';
import { SubscriptionTagResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionTagResource';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';

interface ISubscriptionTagFormEditorProps {
	narrow?: boolean;
	profile?: SubscriptionTagProfileResponse;
	subscriptionId?: number;
	onSuccess?: (profile: SubscriptionTagProfileResponse) => void;
	isMatching?: boolean;
	isAssignment?: boolean;
}

interface ISubscriptionTagFormEditorState {
	displayMode: boolean;
	isSubmitting: boolean;
	values: Array<ISimpleSelectFormControlOption<string>>;
	tagModeId: CallTagMode;
	tagModeSelect?: ISimpleSelectFormControlOption<CallTagMode>;
	tagSelect?: TagIndexResponse;
	tag?: string;
	percentage?: number;
	priority?: number;
	priorityError?: string;
	weight?: number;
	error?: any;
	subscription?: SubscriptionIndexResponse;
}

export class SubscriptionTagFormEditor extends Component<
	ISubscriptionTagFormEditorProps,
	ISubscriptionTagFormEditorState
> {
	public readonly initialState: ISubscriptionTagFormEditorState = {
		displayMode: false,
		isSubmitting: false,
		values: [],
		tagModeId: CallTagMode.MatchAny,
		tagSelect: undefined,
		tagModeSelect: undefined,
		percentage: 100,
		priority: 50,
		weight: 10,
		tag: this.props.isAssignment ? 'CUSTOM' : undefined
	};

	public state: ISubscriptionTagFormEditorState = { ...this.initialState };

	public async componentDidMount() {
		this.loadProfileOrDefaultValues();
	}

	public componentDidUpdate(prevProps: ISubscriptionTagFormEditorProps) {
		if (!isEqual(prevProps.profile, this.props.profile)) {
			this.loadProfileOrDefaultValues();
		}
	}

	public render() {
		const initialSubscriptionId =
			this.props.subscriptionId ?? this.props.profile?.subscriptionId;

		return (
			<RtForm
				narrow={this.props.narrow}
				createMode
				onSubmit={this.createOrUpdate}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onCancel={() => {
					this.setState({ ...cloneDeep(this.initialState) });
				}}
			>
				<SubscriptionSelect
					value={this.state.subscription as any}
					onChange={(subscription) => this.setState({ subscription })}
					initialOptionId={initialSubscriptionId}
					serviceIds={this.getServiceIds()}
					displayMode={initialSubscriptionId !== undefined}
				/>
				<TagTypeSelect
					required
					serviceId={this.state.subscription?.serviceId}
					displayMode={
						this.state.displayMode ||
						this.props.isAssignment ||
						Boolean(this.props.profile)
					}
					initialOptionId={this.state.tag}
					onChange={(tagSelect) => this.setState({ tagSelect })}
					value={this.state.tagSelect}
				/>
				<TagSelect<true>
					label="Values"
					multi
					displayMode={this.state.displayMode}
					creatable
					options={[]}
					onChange={(tags) => this.setState({ values: tags })}
					value={this.state.values.sort((a, b) =>
						a.value.localeCompare(b.value)
					)}
				/>
				{!this.props.isAssignment && (
					<>
						<SimpleSelectFormControl<CallTagMode>
							required
							clearable={false}
							label="Tag Mode"
							displayMode={this.state.displayMode}
							value={this.state.tagModeSelect}
							initialOptionId={this.state.tagModeId.toString()}
							onChange={(tagModeSelect) => {
								if (
									[CallTagMode.AllowAny, CallTagMode.BlockAny].includes(
										tagModeSelect.value
									)
								) {
									this.setState({
										priority: undefined,
										weight: undefined
									});
								}
								this.setState({
									tagModeSelect,
									tagModeId: tagModeSelect.value
								});
							}}
							options={[
								{
									value: CallTagMode.MatchAny,
									label: 'Any may Match'
								},
								{
									value: CallTagMode.MatchAll,
									label: 'All Must Match'
								},
								{
									value: CallTagMode.AllowAny,
									label: 'Filter (Include) Any (Match still Required)'
								},
								{
									value: CallTagMode.BlockAny,
									label: 'Block (Exclude) Any'
								}
							]}
						/>
						<InputFormControl
							required
							accept="^[1-9]$|^[1-9][0-9]$|^(100)$"
							label="Active Percent"
							type="number"
							displayMode={this.state.displayMode}
							value={String(this.state.percentage)}
							onChange={(percentage) =>
								this.setState({ percentage: Number(percentage) })
							}
						/>
						<ControlGroup
							label="Match Priority"
							errorText={this.state.priorityError}
							value={String(this.state.priority)}
							isInvalid={Boolean(this.state.priorityError)}
							displayEmptyValue={<span className="text-muted">N/A</span>}
							displayMode={
								this.state.displayMode ||
								[CallTagMode.AllowAny, CallTagMode.BlockAny].includes(
									this.state.tagModeId
								)
							}
						>
							<Form.Control
								type="number"
								value={String(this.state.priority)}
								required
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const priority = Number(e.target.value);
									this.setState({ priorityError: undefined });

									if (
										!/^[1-9]$|^[1-9][0-9]$|^(100)$/g.test(priority.toString())
									) {
										this.setState({ priorityError: 'Invalid Value' });
									}

									this.setState({ priority });
								}}
								isInvalid={Boolean(this.state.priorityError)}
							/>
						</ControlGroup>
						<InputFormControl
							required
							label="Match Weight"
							type="number"
							displayMode={
								this.state.displayMode ||
								[CallTagMode.AllowAny, CallTagMode.BlockAny].includes(
									this.state.tagModeId
								)
							}
							value={String(this.state.weight)}
							onChange={(weight) => this.setState({ weight: Number(weight) })}
							displayEmptyValue={<span className="text-muted">N/A</span>}
						/>
					</>
				)}
			</RtForm>
		);
	}

	private createOrUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (this.state.priorityError) {
			return;
		}

		const subscriptionId =
			this.state.subscription?.subscriptionId ?? this.props.subscriptionId;

		if (!subscriptionId) {
			return;
		}

		const subscriptionTagResource = new SubscriptionTagResource();
		let newProfile: SubscriptionTagProfileResponse;

		this.setState({ isSubmitting: true, error: undefined });
		const { onSuccess = () => ({}) } = this.props;

		try {
			if (this.props.profile) {
				newProfile = await subscriptionTagResource.update(
					this.props.profile.subscriptionTagId,
					{
						...this.props.profile,
						percentage: this.state.percentage!,
						priority: this.state.priority ? this.state.priority : 100,
						weight: this.state.weight ? this.state.weight : 100,
						values: this.state.values.map(
							(tagSelectItems: ISimpleSelectFormControlOption<string>) =>
								tagSelectItems.value
						),
						tagModeId: this.state.tagModeId
					}
				);
				this.setState({ displayMode: true });
			} else {
				newProfile = await subscriptionTagResource.create({
					subscriptionId,
					percentage: this.state.percentage!,
					priority: this.state.priority ? this.state.priority : 100,
					weight: this.state.weight ? this.state.weight : 100,
					tag: this.state.tagSelect!.tag,
					values: this.state.values.map((val) => val.value),
					tagModeId: this.state.tagModeId
				});
			}
			this.setState({ displayMode: true });
			onSuccess(newProfile);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	};

	private loadProfileOrDefaultValues() {
		const props = this.props;

		this.setState(cloneDeep(this.initialState));

		if (props.profile) {
			this.setState({
				tag: props.profile.tag,
				percentage: props.profile.percentage,
				priority: props.profile.priority,
				weight: props.profile.weight,
				tagModeId: props.profile.tagModeId,
				values: props.profile.values.map((tagValue: string) => ({
					value: tagValue,
					label: tagValue
				}))
			});
		}
	}

	private getServiceIds() {
		const { isMatching, isAssignment } = this.props;

		if (isMatching) {
			return [220];
		}

		if (isAssignment) {
			return [110, 120];
		}

		return undefined;
	}
}
