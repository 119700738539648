/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LrnProfileResponse = {
    properties: {
        tn: {
            type: 'string',
            isRequired: true,
        },
        lrn: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        ocn: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        operatingCompanyName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        activated: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        classDpc: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        classSsn: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        lidbDpc: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        lidbSsn: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isvmDpc: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isvmSsn: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        cnamDpc: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        cnamSsn: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        endUserLocation: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        endUserLocationType: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        billingId: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        lnpType: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        downloadReason: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        regionId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
    },
};