import {
	OriginationNumberIndexRequest,
	OriginationNumberIndexResponse
} from 'RtModels';
import { ServiceNumberRouter } from 'RtUi/app/rtSip/ServiceNumbers/ServiceNumber.router';
import { OriginationNumberResource } from 'RtUi/app/rtSip/ServiceNumbers/lib/resources/OriginationNumberResource';
import { useGetServiceNumbers } from 'RtUi/app/rtSip/ServiceNumbers/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getServiceNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/serviceNumbers';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { useCallback, useMemo } from 'react';

interface IServiceNumbersGridProps {
	resourceParams?: OriginationNumberIndexRequest;
}

export const ServiceNumbersGrid = ({
	resourceParams
}: IServiceNumbersGridProps) => {
	const { data, isFetching: isLoading } = useGetServiceNumbers(resourceParams);

	const removeServiceNumber = useCallback(
		async (serviceNumber: OriginationNumberIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				serviceNumber.serviceNumber
			);

			if (!confirm) {
				return;
			}

			const resource = new OriginationNumberResource();
			await resource.delete(serviceNumber.serviceNumberId);
		},
		[]
	);

	const columns = useMemo(
		() => getServiceNumbersColumns(removeServiceNumber),
		[removeServiceNumber]
	);

	return (
		<>
			<DataGrid<OriginationNumberIndexResponse>
				router={ServiceNumberRouter}
				data={data?.data}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtSip_serviceNumbers'}
				columns={columns}
			/>
			<Confirmation />
		</>
	);
};
