import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	FlowIndexResponse,
	FlowCreateRequest,
	FlowUpdateRequest,
	FlowProfileResponse,
	FlowProfileRequest
} from 'RtModels';
import { RtxFlowApiRoutes } from 'RtExports/routes';

export class FlowResource extends HttpResource<
	FlowIndexResponse,
	FlowProfileResponse
> {
	constructor() {
		super(RtxFlowApiRoutes.Flows);
	}

	public create(label: string, summary: string) {
		const req: FlowCreateRequest = { label, summary };
		const body = JSON.stringify(req);

		return this.fetchWithRoute<FlowProfileResponse>(
			RtxFlowApiRoutes.Flows.Create,
			{ body }
		);
	}

	public update(flowId: number, label: string, summary: string) {
		const req: FlowUpdateRequest = { label, summary };
		const body = JSON.stringify(req);
		const urlParams: FlowProfileRequest = { flowId };

		return this.fetchWithRoute<FlowProfileResponse>(
			RtxFlowApiRoutes.Flows.Update,
			{ body, urlParams }
		);
	}
}
