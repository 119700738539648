import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class CprColNpaNxx extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.NpaNxx;

	public readonly allowOther = true;
	public readonly valueLimit = 3;
	public readonly valueRegExp = /^\d{3}$/;

	public readonly isLergNode: boolean = true;
	public readonly isTreeNode = true;

	protected readonly possibles = CprLergCache.NpaNxxs;
	protected readonly possiblesByKey = CprLergCache.CprLergByNpaNxx;

	// no canadaPossibles on purpose
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaNpaNxxsByKey;

	protected readonly canLabel = true;

	/**
	 * @override Checks against NPANXX -- we don't want that
	 */
	public isPossibleValue(rawVal: string) {
		return true;
	}

	/**
	 * @override
	 */
	public getExtValues(getLabelValues: boolean = false): string[] {
		if (getLabelValues && this.label) {
			return this.label.getRawValues();
		}

		if (this.label) {
			return [this.label.getName()];
		}

		const rawValues: string[] = [];

		if (this.cprValues.length === 0) {
			return rawValues;
		}

		if (this.label) {
			return rawValues;
		}

		const npas = this.cprRow.areaCodes.getRawValues();

		if (npas.length === 0) {
			return rawValues;
		}

		if (npas.length > 1 && !this.cprRow.areaCodes.hasCprLabel()) {
			return rawValues;
		}

		// there can only be one (or were in label mode)
		const npa = npas[0];

		for (const cprValue of this.cprValues) {
			const rawVal = cprValue.getValue();
			rawValues.push(`${npa}${rawVal}`);
		}

		return rawValues;
	}

	/**
	 * @override
	 */
	public getPossibles(search: string = '', excludeCurrent: boolean = true, limit: number = 0): string[] {
		const filteredPossibles: string[] = [];

		const npas = this.cprRow.areaCodes.getRawValues(true);

		if (npas.length > 1 && !this.cprRow.areaCodes.hasCprLabel()) {
			// cannot have  NPA/NXX values if there is more than one NPA, unless it's via a label.
			return filteredPossibles;
		}

		let omits: string[] = [];

		if (excludeCurrent) {
			// there can only be one
			const npa = npas[0];
			// omits should be in NPANXX since they are compared to NPANXX possibles
			omits = this.getRawValues().map((rawVal) => `${npa}${rawVal}`);
		}

		// search for labels if string starts with *
		if (search.startsWith('*')) {
			if (!this.cprNodeTypeId) {
				return filteredPossibles;
			}

			for (const label of this.cpr.getCprLabels(this.cprNodeTypeId)) {
				const possible = label.getName();

				if (omits.includes(possible)) {
					continue;
				}

				if (!possible.startsWith(search)) {
					continue;
				}

				filteredPossibles.push(possible);

				if (limit && filteredPossibles.length >= limit) {
					return filteredPossibles;
				}
			}

			return filteredPossibles;
		}

		for (const possible of this.possibles) {
			if (omits.includes(possible)) {
				continue;
			}

			for (const npa of npas) {
				if (!possible.startsWith(`${npa}${search}`)) {
					continue;
				}

				filteredPossibles.push(possible.substring(3, 6));

				if (limit && filteredPossibles.length >= limit) {
					return filteredPossibles;
				}
			}
		}

		return filteredPossibles;
	}

	public validate(): boolean {
		super.validate();

		if (this.cprValues.length === 0) {
			return this.hasErrors();
		}

		if (this.label) {
			return this.hasErrors();
		}

		const npas = this.cprRow.areaCodes.getRawValues(true);

		if (npas.length === 0) {
			this.addError(null, `Cannot have NPANXX value without an NPA column value.`);
			return this.hasErrors();
		}

		if (npas.length > 1 && !this.cprRow.areaCodes.hasCprLabel()) {
			// cannot have NPA/NXX values if there is more than one NPA, unless it's via a label.
			this.addError(null, `NPANXX values can only be set with a single NPA column value.`);
			return this.hasErrors();
		}

		// there can only be one
		const npa = npas[0];

		for (const cprValue of this.cprValues) {
			const rawVal = cprValue.getValue();

			if (rawVal === 'OTHER') {
				continue;
			}

			const isInPossibles = `${npa}${rawVal}` in this.possiblesByKey;

			if (!isInPossibles) {
				this.addError(rawVal, `Invalid value for this NPANXX.`, cprValue, {
					valuesToRemove: [rawVal]
				});
				continue;
			}
		}

		return this.hasErrors();
	}

}
