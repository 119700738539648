import { cloneDeep, isNil } from 'lodash-es';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
	BlockTypeIndexResponse,
	ReleaseCodeIndexResponse,
	RoutePlanIndexResponse,
	ScenarioIndexResponse,
	SubscriptionIndexResponse,
	SubscriptionRoutingProfileResponse,
	TrackingGroupIndexResponse
} from 'RtModels';
import { ReleaseCodeSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/ReleaseCodeSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { SectionHeaders } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionEditor';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { BlockTypeSelect } from 'RtUi/app/rtSip/BlockTypes/lib/controls/BlockTypeSelect';
import { RoutePlanSelect } from 'RtUi/app/rtSip/RoutePlans/lib/controls/RoutePlanSelect';
import { ScenarioSelect } from 'RtUi/app/rtSip/Scenarios/lib/controls/ScenarioSelect';
import { TrackingGroupSelect } from 'RtUi/app/rtSip/TrackingGroups/lib/controls/TrackingGroupSelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtxUiPhoneNumberInput } from 'RtUi/components/rtx/inputs/PhoneNumber/RtxUiPhoneNumberInput';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface ISubscriptionRoutingFormProps {
	subscriptionId: number;
	serviceId: number;
	onSuccess?: (profile: SubscriptionRoutingProfileResponse) => void;
	onCancel?: () => void;
}

interface ISubscriptionRoutingFormState {
	isSubmitting: boolean;
	editingSubscription: SubscriptionRoutingProfileResponse | null;
	displayMode: boolean;
	error?: any;
	defaultSubscription?: SubscriptionIndexResponse;
	sourceSubscription?: SubscriptionIndexResponse;
	defaultScenario?: ScenarioIndexResponse;
	trackingGroup?: TrackingGroupIndexResponse;
	vendorSubscription?: SubscriptionIndexResponse;
	defaultIsdnReleaseId?: ReleaseCodeIndexResponse;
	blockType?: BlockTypeIndexResponse;
	routePlan?: RoutePlanIndexResponse;
}

export class SubscriptionRoutingForm extends React.Component<
	ISubscriptionRoutingFormProps,
	ISubscriptionRoutingFormState
> {
	private initialState: ISubscriptionRoutingFormState;

	constructor(props: ISubscriptionRoutingFormProps) {
		super(props);

		this.initialState = {
			isSubmitting: false,
			displayMode: true,
			editingSubscription: null
		};

		this.state = cloneDeep(this.initialState);
	}

	private onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const { editingSubscription } = this.state;
		const { onSuccess = () => ({}) } = this.props;

		if (!editingSubscription) {
			return;
		}

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const rs = new SubscriptionResource();

			const profile = await rs.updateSubscriptionRouting(
				this.props.subscriptionId,
				{
					blockTypeId: this.state.blockType?.blockTypeId ?? null,
					routePlanId: this.state.routePlan?.routePlanId ?? null,
					defaultSubscriptionId:
						this.state.defaultSubscription?.subscriptionId ?? null,
					hasServiceNumbers: editingSubscription.hasServiceNumbers ?? 0,
					defaultScenarioId: this.state.defaultScenario?.scenarioId ?? null,
					trackingGroupId: this.state.trackingGroup?.trackingGroupId ?? null,
					translatedNumberStd: editingSubscription.translatedNumberStd ?? null,
					defaultIsdnReleaseId:
						editingSubscription.defaultIsdnReleaseId ?? null,
					defaultIsdnReleaseMs:
						editingSubscription.defaultIsdnReleaseMs ?? null,
					directSubscriptionId:
						editingSubscription.directSubscriptionId ?? null,
					sourceSubscriptionId: editingSubscription.sourceSubscriptionId ?? null
				}
			);

			this.setState(
				{
					displayMode: true,
					isSubmitting: false,
					editingSubscription: profile
				},
				() => {
					this.initialState = this.state;
				}
			);

			this.setState({ isSubmitting: false }, () => {
				onSuccess(profile);
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	};

	private onChange = <K extends keyof SubscriptionRoutingProfileResponse>(
		key: K,
		value: SubscriptionRoutingProfileResponse[K]
	) => {
		if (!this.state.isSubmitting && this.state.editingSubscription) {
			const { editingSubscription } = this.state;
			editingSubscription[key] = value;
			this.setState({ editingSubscription });
		}
	};

	public async componentDidMount() {
		this.setUpInitialState();
	}

	public componentDidUpdate(
		prevProps: Readonly<ISubscriptionRoutingFormProps>
	) {
		if (prevProps.subscriptionId !== this.props.subscriptionId) {
			this.setUpInitialState();
		}
	}

	public render() {
		if (!this.state.editingSubscription) {
			return <Loading />;
		}

		const { editingSubscription, displayMode } = this.state;

		return (
			<RtUiForm
				isInline
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				displayMode={this.state.displayMode}
				onSubmit={this.onSubmit}
				onChange={() => {
					this.setState((state) => ({ displayMode: !state.displayMode }));
				}}
				onCancel={() => {
					this.setState({ ...cloneDeep(this.initialState) }, () => {
						if (this.props.onCancel) {
							this.props.onCancel();
						}
					});
				}}
			>
				<Card>
					<Card.Body>
						<Row cols={2}>
							<Col>
								<fieldset className="card card-body mb-0">
									<legend className="card-header">
										<h6>Routing</h6>
									</legend>
									<BlockTypeSelect
										name="blockTypeId"
										displayMode={displayMode}
										onChange={(blockType: BlockTypeIndexResponse) =>
											this.setState({ blockType })
										}
										value={this.state.blockType}
										initialOptionId={editingSubscription.blockTypeId?.toString()}
									/>
									<RoutePlanSelect
										name="routePlanId"
										displayMode={displayMode}
										onChange={(routePlan: RoutePlanIndexResponse) =>
											this.setState({ routePlan })
										}
										value={this.state.routePlan}
										initialOptionId={editingSubscription.routePlanId?.toString()}
										allowedRoutePlanIds={[3, 6]}
									/>
								</fieldset>
							</Col>
							<Col lg={6}>
								<fieldset className="card card-body mb-0">
									<legend className="card-header">
										<h6>{SectionHeaders.directVendorTermination}</h6>
									</legend>
									<SubscriptionSelect
										name="directSubscriptionId"
										label="Direct Routing"
										isActive={1}
										isClearable
										serviceIds={[3]}
										displayMode={displayMode}
										isDisabled={
											this.props.serviceId !== 5 && this.props.serviceId !== 220
										}
										onChange={(vendorSubscription) => {
											this.setState({ vendorSubscription });
											this.onChange(
												'directSubscriptionId',
												vendorSubscription?.subscriptionId
											);
										}}
										value={this.state.vendorSubscription}
										initialOptionId={
											editingSubscription.directSubscriptionId ?? undefined
										}
									/>
								</fieldset>
							</Col>
							<Col lg={6}>
								<fieldset className="card card-body mb-0">
									<legend className="card-header">
										<h6>{SectionHeaders.origination}</h6>
									</legend>
									<SubscriptionSelect
										name="defaultSubscriptionId"
										label="Default Subscription"
										isActive={1}
										serviceIds={[120]}
										displayMode={displayMode}
										isDisabled={this.props.serviceId !== 110}
										onChange={(
											defaultSubscription: SubscriptionIndexResponse
										) => this.setState({ defaultSubscription })}
										value={this.state.defaultSubscription}
										initialOptionId={
											editingSubscription.defaultSubscriptionId ?? undefined
										}
									/>
									<SubscriptionSelect
										name="sourceSubscriptionId"
										label="Clone Subscription"
										isActive={1}
										serviceIds={[120]}
										displayMode={displayMode}
										isDisabled={this.props.serviceId !== 121}
										onChange={(
											sourceSubscription: SubscriptionIndexResponse
										) => {
											this.onChange(
												'sourceSubscriptionId',
												sourceSubscription.subscriptionId
											);
											this.setState({ sourceSubscription });
										}}
										value={this.state.sourceSubscription}
										initialOptionId={
											editingSubscription.sourceSubscriptionId ?? undefined
										}
									/>
									<TrackingGroupSelect
										name="trackingGroupId"
										label="Tracking Group"
										displayMode={displayMode}
										disabled={this.props.serviceId !== 220}
										onChange={(trackingGroup) =>
											this.setState({ trackingGroup })
										}
										value={this.state.trackingGroup}
										initialOptionId={editingSubscription.trackingGroupId?.toString()}
									/>
									<ReleaseCodeSelect
										allowReleaseIds={[1, 17, 18, 28, 34]}
										displayMode={displayMode}
										disabled={this.props.serviceId !== 110}
										label="Default ISDN Rel."
										valueKey="isdnReleaseId"
										value={this.state.defaultIsdnReleaseId}
										initialOptionId={
											!editingSubscription.defaultIsdnReleaseId
												? undefined
												: editingSubscription.defaultIsdnReleaseId.toString()
										}
										onChange={(defaultIsdnReleaseId) => {
											this.setState({ defaultIsdnReleaseId });
											this.onChange(
												'defaultIsdnReleaseId',
												defaultIsdnReleaseId.isdnReleaseId
											);
										}}
									/>
									<InputFormControl
										name="defaultIsdnReleaseMs"
										type="number"
										min={0}
										max={18000}
										label="Default ISDN Rel. ms"
										displayMode={displayMode}
										disabled={this.props.serviceId !== 110}
										value={
											!isNil(editingSubscription.defaultIsdnReleaseMs)
												? String(editingSubscription.defaultIsdnReleaseMs)
												: undefined
										}
										onChange={(value) => {
											this.onChange('defaultIsdnReleaseMs', Number(value));
										}}
									/>
									<BooleanRadioFormControl
										name="hasServiceNumbers"
										label="Service Number Override"
										displayMode={displayMode}
										disabled={this.props.serviceId !== 110}
										value={editingSubscription.hasServiceNumbers}
										onChange={(value) =>
											this.onChange('hasServiceNumbers', value)
										}
									/>
									<ScenarioSelect
										name="defaultScenarioId"
										disabled={
											this.props.serviceId !== 120 &&
											this.props.serviceId !== 121 &&
											this.props.serviceId !== 220
										}
										label="Default Scenario"
										displayMode={displayMode}
										onChange={(defaultScenario) => {
											this.setState({ defaultScenario });
											this.onChange(
												'defaultScenarioId',
												defaultScenario.scenarioId
											);
										}}
										value={this.state.defaultScenario}
										initialOptionId={editingSubscription.defaultScenarioId?.toString()}
									/>
									<RtxUiPhoneNumberInput
										label="Translated Number"
										displayMode={displayMode}
										className="mt-2"
										disabled={
											this.props.serviceId !== 120 &&
											this.props.serviceId !== 121 &&
											this.props.serviceId !== 220
										}
										value={editingSubscription.translatedNumberStd?.toString()}
										onChange={(value) =>
											this.onChange('translatedNumberStd', value)
										}
									/>
								</fieldset>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</RtUiForm>
		);
	}

	private async setUpInitialState() {
		const rs = new SubscriptionResource();
		const editingSubscription = await rs.getSubscriptionRouting(
			this.props.subscriptionId
		);

		this.initialState = {
			...this.initialState,
			editingSubscription
		};

		this.setState(cloneDeep(this.initialState));
	}
}
