/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RatePlanIndexRequest = {
    properties: {
        ratePlanId: {
            type: 'number',
        },
        ratePlan: {
            type: 'string',
            isNullable: true,
        },
        summary: {
            type: 'string',
            isNullable: true,
        },
        isActive: {
            type: 'number',
        },
        subscriptionId: {
            type: 'number',
            isNullable: true,
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        isShared: {
            type: 'number',
        },
        assignableToSubscriptionId: {
            type: 'number',
        },
    },
};