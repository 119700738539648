/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationUsageDetailsIndexResponse = {
    properties: {
        guardianReconciliationUsageDetailId: {
            type: 'number',
            isRequired: true,
        },
        reconciliationId: {
            type: 'number',
            isRequired: true,
        },
        serviceTypeId: {
            type: 'number',
            isRequired: true,
        },
        mappingId: {
            type: 'number',
            isNullable: true,
        },
        carrierInvoiceConnects: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        carrierInvoiceDuration: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        carrierInvoiceCharge: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        customerExpectedConnects: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        customerExpectedDuration: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        customerExpectedCharge: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rtSrcConnects: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rtSrcDuration: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rtSrcCharge: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rtTgtConnects: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rtTgtDuration: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rtTgtCharge: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        disputedAmount: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        approvedAmount: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        disputeStatus: {
            type: 'GuardianReconciliationStatus',
            isRequired: true,
            isNullable: true,
        },
        disputeId: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        disputeFiledTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        disputeSettlementTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        assignedTo: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        systemGenerated: {
            type: 'number',
            isRequired: true,
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        updatedBy: {
            type: 'number',
            isRequired: true,
        },
        updateTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        disputeReceivedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        escalationTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        srcFilestreamIds: {
            type: 'string',
        },
        tgtFilestreamIds: {
            type: 'string',
        },
        guardianReconciliationStartTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        assignedToUserEmail: {
            type: 'string',
        },
    },
};