import { defaults } from 'lodash-es';
import * as moment from 'moment-timezone';
import { IRtxApiRoute } from 'RtExports/routes';
import { HttpRequest } from '../HttpRequest';
import { IRequestInitWithParams } from '../interfaces';
import { HttpResource } from './HttpResource';
import { MemoryCacheStrategy } from 'RtUi/utils/cache/strategies/MemoryCacheStrategy';
import { ISearchable } from 'RtUi/utils/http/resources/interfaces';

export type ArrayResourceUpdateListener<T> = (allData: T[]) => void;

export class ArrayResource<T> extends HttpResource<T> {
	protected composedOfPartialData = false;
	private isArrayBased = false;
	private updateListeners: Array<ArrayResourceUpdateListener<T>> = [];
	private getAllAwait: Promise<void> | null = null;

	constructor(
		private idAttr: keyof T,
		protected allData: T[] = []
	) {
		super();

		const twoDayLongMemoryCache = new MemoryCacheStrategy<T>(
			moment.duration({ day: 2 })
		);

		this.setIndexesCacheStrategy(twoDayLongMemoryCache);

		if (Array.isArray(allData)) {
			this.setAllData(allData);
		}
	}

	/**
	 * Set data for array resource
	 * @param allData
	 */
	public setAllData(allData: T[]) {
		this.allData = allData;
		this.isArrayBased = true;
		this.indexesCache.storeCache(allData);
		this.totalCount = allData.length;
		this.recordsCount = this.totalCount;

		this.invokeUpdateListeners();
	}

	/**
	 * Set GetAllCache manually
	 */
	public setGetAllPromise(getAllPromise: Promise<T[]>) {
		this.isArrayBased = true;

		this.getAllAwait = getAllPromise.then((allData) => {
			this.setAllData(allData);
		});
	}

	/**
	 * Init data from endpoint
	 */
	public setApiRouteForGetAll(
		apiRoute: IRtxApiRoute,
		init?: IRequestInitWithParams
	) {
		const path = HttpRequest.addParamsToPath(apiRoute.path, init);

		if (init?.doNotNotifyOn404Error) {
			this.setGetAllDoNotNotifyOn404Error(true);
		}

		this.getAllIsPost = apiRoute.httpMethod === 'POST';
		this.indexPermission = apiRoute.permissions;
		this.resourcePath = path;
		this.isArrayBased = false;

		const eightHourLongMemoryCache = new MemoryCacheStrategy<T>(
			moment.duration({ minutes: 5 })
		);
		this.setIndexesCacheStrategy(eightHourLongMemoryCache);
	}

	/**
	 * Override to return array if array-based
	 * @param extraParams
	 * @param resetCache
	 */
	public async getAllWithoutCache(
		extraParams: any = {},
		searchables?: Array<ISearchable<T>>
	): Promise<T[]> {
		if (this.getAllAwait) {
			await this.getAllAwait;

			this.getAllAwait = null;
		}

		if (this.isArrayBased) {
			const params = defaults(
				{},
				extraParams,
				this.getAllParams,
				this.baseParams
			);

			return this.searchFilterAndSearch(params, this.allData, searchables);
		}

		return super.getAllWithoutCache(extraParams, searchables);
	}

	/**
	 * Get single resource
	 * @param id
	 */
	public get(id: any): Promise<T | undefined> {
		return this.getAll().then((allData) => {
			return allData.find((data) => data[this.idAttr] === id);
		});
	}

	/**
	 * @override
	 * @param extraParams
	 * @param resetCache
	 */
	public getAll(extraParams: any = {}, resetCache = false): Promise<T[]> {
		if (this.isArrayBased) {
			return this.getAllWithoutCache(extraParams);
		} else if (!this.indexesCache.isCacheStale()) {
			return this.indexesCache.getAll();
		}

		return super.getAll(extraParams, resetCache);
	}

	/**
	 * Update a record (make sure the idAttr has not change)
	 * Note: invokes update listeners
	 * @param updatedRecord
	 */
	public update(updatedRecord: T) {
		const indexAttr = updatedRecord[this.idAttr];

		const foundIndex = this.allData.findIndex(
			(r) => r[this.idAttr] === indexAttr
		);

		if (foundIndex >= 0) {
			this.allData[foundIndex] = updatedRecord;

			this.indexesCache.storeCache(this.allData);

			this.invokeUpdateListeners();
		}
	}

	/**
	 * Listen for ArrayResource updates
	 */
	public onUpdate(listener: ArrayResourceUpdateListener<T>) {
		this.updateListeners.push(listener);

		return () => {
			const indexOfListener = this.updateListeners.findIndex(
				(l) => l === listener
			);

			if (indexOfListener >= 0) {
				this.updateListeners.splice(indexOfListener, 1);
			}
		};
	}

	/**
	 * Invokes listeners
	 */
	private invokeUpdateListeners() {
		this.updateListeners.forEach((updateListener) =>
			updateListener(this.allData)
		);
	}
}
