import { useMemo } from 'react';
import {
	TemplateProfileRequest,
	TemplateScpIndexRequest,
	TemplateScpIndexResponse,
	TollFreeProfileRequest,
	TollFreeScpIndexRequest,
	TollFreeScpIndexResponse
} from 'RtModels';
import { useGetCprScps } from 'RtUi/app/rt800/Cprs/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCprScpsColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/cpr';

export type CprScpsIndexResponse<T extends CprScpsIndexRequest> =
	T extends TemplateProfileRequest
		? TemplateScpIndexResponse
		: TollFreeScpIndexResponse;

export type CprScpsIndexRequest =
	| TemplateProfileRequest
	| TollFreeProfileRequest;

export type CprScpsQueryIndexRequest<T extends CprScpsIndexRequest> =
	T extends TemplateProfileRequest
		? TemplateScpIndexRequest
		: TollFreeScpIndexRequest;

export type IsNumberCpr<T extends CprScpsIndexRequest> =
	T extends TemplateScpIndexRequest ? undefined : true;

interface IScpGridProps<T extends CprScpsIndexRequest> {
	onRowClick?: (row: CprScpsIndexResponse<T>) => void;
	isNumber?: boolean;
	urlParams: CprScpsIndexRequest;
	queryParams: CprScpsQueryIndexRequest<T>;
}

export const ScpGrid = <T extends CprScpsIndexRequest>({
	isNumber,
	urlParams,
	queryParams,
	onRowClick
}: IScpGridProps<T>) => {
	const { data, isFetching: isLoading } = useGetCprScps(
		Boolean(isNumber),
		urlParams,
		queryParams
	);
	const columns = useMemo(() => getCprScpsColumns(), []);

	return (
		<DataGrid<CprScpsIndexResponse<T>>
			data={data?.data}
			columns={columns}
			onRowClick={onRowClick}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName="rt800_cprScps"
		/>
	);
};
