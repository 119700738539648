import { RtxSipApiRoutes } from 'RtExports/routes';
import { CdrExportIndexRequest, CdrExportIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchCdrExport = async (queryParams?: CdrExportIndexRequest) => {
	return handleGetRequest<CdrExportIndexResponse[], true>(
		RtxSipApiRoutes.CdrExport.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetCdrExport = (params?: CdrExportIndexRequest) => {
	return useQuery<FullResponse<CdrExportIndexResponse[]>, Error>(
		['getCdrExport', params],
		() => fetchCdrExport(params)
	);
};
