import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import {
	DataGridRowInstance,
	DataGridTableInstance,
	DataGridColumnInstance,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCprProfileColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/cpr';
import {
	getExportColumns,
	getExportValue
} from 'RtUi/components/data/DataGrid/utils';
import { Cpr, CprNodeType, CprRow, CprCol } from 'Somos/lib/SomosCpr/RtCprV2';
import {
	forwardRef,
	useCallback,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState
} from 'react';
import { PaginationState } from '@tanstack/react-table';
import { IRoutingCPR } from 'RtUi/app/rt800/Cprs/lib/util/Constants';

interface ICprDataGridProps {
	mgiProfile: Cpr;
	displayMode: boolean;
}

const getRowThemeColor = (cprRow: CprRow) => {
	if (cprRow.cpr.isSelectedHighlightedRow(cprRow)) {
		return RowThemeColor.WARNING;
	}

	if (cprRow.isHighlighted()) {
		return RowThemeColor.LIGHT;
	}

	if (cprRow.isNew()) {
		return RowThemeColor.SUCCESS;
	}

	return undefined;
};

export interface ICprDataGridRef {
	data: IRoutingCPR[];
	pageToIndex: (index: number) => void;
	pageSize: number;
	updatePage: (page: number, pageSize?: number) => void;
}

export const CprDataGrid = forwardRef<ICprDataGridRef, ICprDataGridProps>(
	(props, ref) => {
		const { cpr: mgiProfile, displayMode } = useContext(CprProfileContext);
		const [cprCols, setCprCols] = useState<CprCol[]>([]);
		const [cprRows, setCprRows] = useState<CprRow[]>([]);
		const [pagination, setPagination] = useState<PaginationState>({
			pageIndex: 0,
			pageSize: 10
		});
		const tableRef = useRef<DataGridTableInstance<CprRow>>(null);

		useEffect(() => {
			setCprCols(mgiProfile.getCprCols());
			setCprRows(mgiProfile.getCprRows());
		}, [mgiProfile]);

		useEffect(() => {
			return mgiProfile.onValidate(() => {
				const newRows = [...mgiProfile.getCprRows()];
				const newColumns = [...mgiProfile.getCprCols()];
				setCprRows(newRows);
				setCprCols(newColumns);
			});
		}, [mgiProfile]);

		const pageToIndex = (index: number) => {
			setPagination((currentState) => {
				const { pageSize } = currentState;
				const pageIndex = Math.floor(index / pageSize);

				return {
					pageIndex,
					pageSize
				};
			});
		};

		const updatePage = (pageIndex: number, newPageSize?: number) => {
			setPagination((currentState) => {
				const { pageSize } = currentState;

				return {
					pageIndex,
					pageSize: newPageSize ?? pageSize
				};
			});
		};

		useImperativeHandle(ref, () => {
			const data =
				tableRef.current
					?.getPrePaginationRowModel()
					.rows.map((r) => r.original as CprRow) ?? [];

			const columns = getExportColumns(
				tableRef.current?.getAllColumns() ?? [],
				tableRef.current?.getState().columnOrder ?? []
			);

			const state = tableRef.current?.getState();

			return {
				pageToIndex,
				data: getExportValue(data, columns),
				pageSize: state?.pagination.pageSize ?? 0,
				updatePage
			};
		});

		const onAddCprNode = useCallback(
			(decisionType: CprNodeType) => {
				{
					if (!mgiProfile) {
						return;
					}

					mgiProfile.makeCprRow(0, true);

					// Add initial column
					if (cprCols.length === 0) {
						mgiProfile.makeCprCol(decisionType, 0);
						mgiProfile.makeCprCol(CprNodeType.Announcement, 1);

						setCprCols(mgiProfile.getCprCols());
					} else if (!mgiProfile.hasCprCol(decisionType)) {
						mgiProfile.makeCprCol(decisionType, 0);
					}
				}
			},
			[cprCols.length, mgiProfile]
		);

		const columns = useMemo(
			() => getCprProfileColumns(cprCols, displayMode, onAddCprNode),
			[cprCols, displayMode, onAddCprNode]
		);

		const onDragEnd = (
			draggingRow: DataGridRowInstance<CprRow>,
			hoveredRow: DataGridRowInstance<CprRow>
		) => {
			mgiProfile.moveCprRow(draggingRow.index, hoveredRow.index);
		};

		const onColumnDragEnd = (
			draggingColumn: DataGridColumnInstance<CprRow>,
			hoveredColumn: DataGridColumnInstance<CprRow>
		) => {
			mgiProfile.moveCprCol(
				draggingColumn.getIndex() - 2,
				hoveredColumn.getIndex() - 2
			);
		};

		return (
			<DataGrid<CprRow>
				ref={tableRef}
				pageName="rt800_cprProfile"
				data={cprRows}
				columns={columns}
				pagination={pagination}
				setPagination={setPagination}
				inlineEdit={!displayMode}
				rowThemeColor={getRowThemeColor}
				disableExport={!displayMode}
				disableControls={!displayMode}
				enableSorting={displayMode}
				enableColumnDragging={!displayMode}
				onColumnDragEnd={onColumnDragEnd}
				enableRowOrdering={!displayMode}
				onRowDragEnd={onDragEnd}
				getRowId={(originalRow) => originalRow.uuid}
			/>
		);
	}
);
