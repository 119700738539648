import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn,
	FallbackHeaderDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface PortDataGridColumnConfiguration<T>
	extends FallbackHeaderDataGridColumnConfiguration<T> {}

export const PortDataGridColumn = <T = any,>({
	header = 'Port',
	size = 125,
	...config
}: PortDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return DefaultDataGridColumn({
		...config,
		size,
		align: DataGridColumnAlignment.CENTER,
		header,
		exportValue: (value: any) => String(value),
		getValue: ({ cell }) => String(cell.getValue())
	});
};
