import { Rt800ApiRoutes } from 'RtExports/routes';
import { RoutingGroupIndexRequest, RoutingGroupIndexResponse } from 'RtModels';
import {
	fetchRoutingGroups,
	useGetRoutingGroups
} from 'RtUi/app/rt800/RoutingGroups/lib/services';
import { PermissionGate } from 'RtUi/components/auth/PermissionGate';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRoutingGroupColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/routingGroups';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';
import { useMemo } from 'react';
import { RoutingGroupRouter } from '../../RoutingGroup.router';

interface IRoutingGroupsGridProps {
	resourceParams?: RoutingGroupIndexRequest;
}

export const RoutingGroupsGrid = ({
	resourceParams
}: IRoutingGroupsGridProps) => {
	const { data, isFetching: isLoading } = useGetRoutingGroups(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportRoutingGroups', resourceParams],
		resourceParams ?? {},
		(params) => fetchRoutingGroups(params)
	);
	const columns = useMemo(() => getRoutingGroupColumns(), []);

	return (
		<PermissionGate
			permissions={Rt800ApiRoutes.RoutingGroups.Index.permissions}
		>
			<DataGrid<RoutingGroupIndexResponse>
				router={RoutingGroupRouter}
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				enableExternalExport
				getExternalExportData={fullResultsReq}
				pageName={'rt800_RoutingGroups'}
			/>
		</PermissionGate>
	);
};
