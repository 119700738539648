import { useMemo } from 'react';
import { SipAgentIndexResponse } from 'RtModels';
import { useGetSipAgents } from 'RtUi/app/AccountManagement/SipAgents/lib/services';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface ISipAgentsSelectProps {
	value?: SipAgentIndexResponse;
	onChange?: (value?: SipAgentIndexResponse) => void;
	label?: string;
	required?: boolean;
	isClearable?: boolean;
	initialOptionId?: number;
	displayMode?: boolean;
}
export const SipAgentsSelect = ({
	value,
	onChange = () => {},
	label = 'SIP Agents',
	required,
	isClearable = true,
	initialOptionId,
	displayMode = false
}: ISipAgentsSelectProps): JSX.Element => {
	const { data, isLoading } = useGetSipAgents();

	const selectData = useMemo(() => data?.data || [], [data]);
	const options: Array<ISimpleSelectFormControlOption<number>> = useMemo(() => {
		return selectData.map((sipAgent) => ({
			value: sipAgent.sipAgentId,
			label: sipAgent.label
		}));
	}, [selectData]);

	const selectValue = useMemo(() => {
		const currentValue = value?.sipAgentId ?? initialOptionId;
		return options.find((opt) => opt.value === currentValue);
	}, [value, initialOptionId, options]);

	const onChangeHandler = (selectedValue: number | undefined) => {
		const sipAgent = selectData?.find((st) => st.sipAgentId === selectedValue);
		onChange(sipAgent);
	};

	return (
		<SimpleSelectFormControl<number>
			label={label}
			clearable={isClearable}
			isLoading={isLoading}
			required={required}
			value={selectValue}
			onChange={(val) => onChangeHandler(val?.value)}
			options={options}
			initialOptionId={initialOptionId?.toString()}
			displayMode={displayMode}
		/>
	);
};
