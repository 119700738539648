import { Row, Col } from 'react-bootstrap';
import {
	GuardianReconciliationMappingProfileResponse,
	ReconciliationMappingDataSourceDirection
} from 'RtModels';
import { GuardianReconciliationMappingEditor } from 'RtUi/app/rtVue/GuardianReconciliationMapping/lib/forms/GuardianReconciliationMappingEditor';
import { GuardianReconciliationMappingFilterGrid } from 'RtUi/app/rtVue/GuardianReconciliationMappingFilter/lib/grids/GuardianReconciliationMappingFilterGrid';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { GuardianReconciliationMappingsRouter } from './GuardianReconciliationMapping.router';
import { GuardianReconciliationMappingResource } from './lib/resources/GuardianReconciliationMappingResource';

interface IGuardianReconciliationMappingProfileContainerProps {}

interface IGuardianReconciliationMappingProfileContainerState {
	activeTab: string;
	profile?: GuardianReconciliationMappingProfileResponse;
}

@GuardianReconciliationMappingsRouter.getProfileRtUiController()
export class GuardianReconciliationMappingProfileContainer extends ProfileApplicationContainer<
	IGuardianReconciliationMappingProfileContainerProps,
	IGuardianReconciliationMappingProfileContainerState
> {
	public Tabs = GuardianReconciliationMappingsRouter.getProfileTabs();

	public state: IGuardianReconciliationMappingProfileContainerState = {
		activeTab: this.Tabs.Profile.header
	};

	public componentDidMount() {
		const profileId = this.getIdParam();
		const resource = new GuardianReconciliationMappingResource();

		resource.get(profileId).then((profile) => this.setState({ profile }));
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}

		const canManage =
			GuardianReconciliationMappingsRouter.canManageWith(profile);

		return (
			<TabbedLayout<GuardianReconciliationMappingProfileResponse>
				router={GuardianReconciliationMappingsRouter}
				profile={profile}
				canManageFn={() => canManage}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<Row>
						<Col lg={6}>
							<GuardianReconciliationMappingEditor
								editMode={profile}
								onUpdate={(newProfile) =>
									this.setState({ profile: newProfile })
								}
							/>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Filters}>
					<Row>
						<Col lg={6} className="mb-3">
							<GuardianReconciliationMappingFilterGrid
								header="Source"
								dataSourceDirection={
									ReconciliationMappingDataSourceDirection.Source
								}
								guardianReconciliationMappingId={
									profile.guardianReconciliationMappingId
								}
							/>
						</Col>
						<Col lg={6} className="mb-3">
							<GuardianReconciliationMappingFilterGrid
								header="Target"
								dataSourceDirection={
									ReconciliationMappingDataSourceDirection.Target
								}
								guardianReconciliationMappingId={
									profile.guardianReconciliationMappingId
								}
							/>
						</Col>
					</Row>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
