/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CallflowService {
    OutboundVendorTermination = 3,
    OutboundCustomerTermination = 5,
    DirectConnect = 12,
    InboundVendorOrigination = 110,
    InboundCustomerIdentification = 120,
    InboundCustomerIdentificationClone = 121,
    OutboundVendorTermination210 = 210,
    OutboundCustomerIdentification = 220,
}