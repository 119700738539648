/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileLogReDownloadCreateRequest = {
    properties: {
        fileIds: {
            type: 'Array',
            isRequired: true,
        },
    },
};