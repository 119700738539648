import { MinuteProfile, MinuteProfileMinute } from 'RtModels';
import { LcrMinuteRecordType } from 'RtUi/app/rtLco/Minutes/lib/resources/LcrMinutesResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { MinutesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MinutesDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getMinutesColumns = (): Array<DataGridColumn<MinuteProfile>> => [
	IdDataGridColumn({
		accessorKey: 'minuteProfileId'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	...RespOrgColumnsBuilder<MinuteProfile>({
		accessorKey: 'respOrgId',
		header: 'RespOrg'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'startTs',
		header: 'Process Start Time'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'endTs',
		header: 'Process End Time'
	}),
	IntegerDataGridColumn({
		accessorKey: 'interMinutes',
		header: 'InterMins'
	}),
	IntegerDataGridColumn({
		accessorKey: 'intraMinutes',
		header: 'IntraMins'
	}),
	IntegerDataGridColumn({
		accessorKey: 'indetMinutes',
		header: 'IndetMins'
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalMinutes',
		header: 'TotalMins'
	})
];

export const getMinutesDataColumns = (): Array<
	DataGridColumn<MinuteProfileMinute>
> => [
	IdDataGridColumn({
		accessorKey: 'minuteProfileMinuteId'
	}),
	IdDataGridColumn({
		accessorKey: 'minuteProfileId',
		header: 'Minute Profile Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'aniNpa',
		header: 'aniNpa'
	}),
	DefaultDataGridColumn({
		accessorKey: 'aniNxx',
		header: 'aniNxx'
	}),
	DefaultDataGridColumn({
		accessorKey: 'aniLata',
		header: 'aniLata'
	}),
	DefaultDataGridColumn({
		accessorKey: 'aniOcn',
		header: 'aniOcn'
	}),
	DefaultDataGridColumn({
		accessorKey: 'lrnNpa',
		header: 'lrnNpa'
	}),
	DefaultDataGridColumn({
		accessorKey: 'lrnNxx',
		header: 'lrnNxx'
	}),
	DefaultDataGridColumn({
		accessorKey: 'lrnLata',
		header: 'lrnLata'
	}),
	DefaultDataGridColumn({
		accessorKey: 'lrnOcn',
		header: 'lrnOcn'
	}),
	IntegerDataGridColumn({
		accessorKey: 'interConnects',
		header: 'Inter. Connects',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'interMinutes',
		header: 'Inter. Minutes',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'interCharges',
		header: 'Inter. Charges',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'intraConnects',
		header: 'Intra. Connects',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'intraMinutes',
		header: 'Intra. Minutes',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'intraCharges',
		header: 'Intra. Charges',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'indetConnects',
		header: 'Indet. Connects',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'indetMinutes',
		header: 'Indet. Minutes',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'indetCharges',
		header: 'Indet. Charges',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalConnects',
		header: 'Total Connects',
		showFooter: true
	}),
	MinutesDataGridColumn({
		accessorKey: 'totalMinutes',
		header: 'Total Minutes',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'totalCharges',
		header: 'Total Charges',
		showFooter: true
	})
];

export const getMinutesCreateDataGrid = (): Array<
	DataGridColumn<LcrMinuteRecordType>
> => [
	DefaultDataGridColumn({
		accessorKey: 'aniNpaNxx',
		header: 'aniNpaNxx'
	}),
	DefaultDataGridColumn({
		accessorKey: 'lrnNpaNxx',
		header: 'lrnNpaNxx'
	}),
	IntegerDataGridColumn({
		accessorKey: 'interConnects',
		header: 'Inter. Connects'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'interMinutes',
		header: 'Inter. Minutes'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'interCharges',
		header: 'Inter. Charges'
	}),
	IntegerDataGridColumn({
		accessorKey: 'intraConnects',
		header: 'Intra. Connects'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'intraMinutes',
		header: 'Intra. Minutes'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'intraCharges',
		header: 'Intra. Charges'
	}),
	IntegerDataGridColumn({
		accessorKey: 'indetConnects',
		header: 'Indet. Connects'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'indetMinutes',
		header: 'Indet. Minutes'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'indetCharges',
		header: 'Indet. Charges'
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalConnects',
		header: 'Total Connects'
	}),
	MinutesDataGridColumn({
		accessorKey: 'totalMinutes',
		header: 'Total Minutes'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'totalCharges',
		header: 'Total Charges'
	})
];
