import {
	NumberGroupNumberIndexResponse,
	NumberGroupProfileRequest
} from 'RtModels';
import { NumberGroupNumbersUpdateAside } from 'RtUi/app/rt800/NumberGroups/lib/components/NumberGroupNumbersUpdateAside';
import {
	fetchNumberGroupNumbers,
	useGetNumberGroupNumbers
} from 'RtUi/app/rt800/NumberGroups/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { TollFreeNumbersCopyButton } from 'RtUi/components/data/DataGrid/components/TollFreeNumbersCopyButton';
import { getNumberGroupNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/numberGroups';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';
import { useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface INumberGroupsNumbersGridProps {
	resourceParams: NumberGroupProfileRequest;
}

export const NumberGroupsNumbersGrid = ({
	resourceParams
}: INumberGroupsNumbersGridProps) => {
	const { data, isFetching: isLoading } =
		useGetNumberGroupNumbers(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportNumberGroupNumbers', resourceParams],
		{},
		(params) => fetchNumberGroupNumbers(resourceParams, params)
	);
	const columns = useMemo(() => getNumberGroupNumbersColumns(), []);
	const [isNumberGroupNumbersAsideOpen, setIsNumberGroupNumbersAsideOpen] =
		useState<boolean>(false);

	return (
		<>
			<DataGrid<NumberGroupNumberIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rt800_numberGroupNumbers'}
				enableExternalExport
				getExternalExportData={fullResultsReq}
				headerAction={(table) => (
					<>
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id="editNumbers-tooltip" {...props}>
									Edit Toll-Free Numbers
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									id="copyNumbers"
									variant="white-alt"
									onClick={() => setIsNumberGroupNumbersAsideOpen(true)}
								>
									<i className="fas fa-fw fa-pencil-alt" />
								</Button>
							)}
						</OverlayTrigger>
						<TollFreeNumbersCopyButton table={table} numberKey="tfn" />
					</>
				)}
			/>
			<NumberGroupNumbersUpdateAside
				isOpen={isNumberGroupNumbersAsideOpen}
				numberGroupId={resourceParams.numberGroupId}
				toggle={setIsNumberGroupNumbersAsideOpen}
				onUpdate={() => setIsNumberGroupNumbersAsideOpen(false)}
			/>
		</>
	);
};
