import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { sumColumnTotal } from 'RtUi/components/data/DataGrid/utils';
import { isNil } from 'lodash-es';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface TaskQtyDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	showFooter?: boolean;
	headerTooltip: string;
}

export const TaskQtyDataGridColumn = <T = any,>({
	showFooter = false,
	align = DataGridColumnAlignment.RIGHT,
	headerTooltip,
	...config
}: TaskQtyDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: any, isExporting?: boolean) => {
		if (isNil(value)) {
			return '';
		}

		if (isExporting) {
			return Number(value);
		}

		return Number(value).toLocaleString('en-US');
	};

	return DefaultDataGridColumn({
		...config,
		align,
		exportValue: (value: any) => getValue(value, true),
		getValue: ({ cell }) => getValue(cell.getValue()),
		getHeader: () => (
			<OverlayTrigger
				overlay={(props) => <Tooltip {...props}>{headerTooltip}</Tooltip>}
			>
				{({ ref, ...triggerHandler }) => (
					<span ref={ref} {...triggerHandler}>
						{config.header}&nbsp;&nbsp;
						<i className="fas fa-info-circle text-info" />
					</span>
				)}
			</OverlayTrigger>
		),
		...(showFooter && {
			getFooter: (props) => {
				const total = sumColumnTotal(props, (row) =>
					Number(row.getValue(props.column.id))
				);

				if (total === null) {
					return '';
				}

				return total.toLocaleString('en-US');
			}
		})
	});
};
