import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianSubscriptionCicCreateRequest,
	GuardianSubscriptionCicIndexResponse,
	GuardianSubscriptionCicProfileResponse,
	GuardianSubscriptionCicProfileUrlRequest,
	GuardianSubscriptionCicUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class SubscriptionCicResource extends HttpResource<
	GuardianSubscriptionCicIndexResponse,
	GuardianSubscriptionCicProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianSubscriptionCic);
	}

	public create(request: GuardianSubscriptionCicCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianSubscriptionCicProfileResponse>(
			RtVueApiRoutes.GuardianSubscriptionCic.Create,
			{
				body
			}
		);
	}

	public update(
		guardianSubscriptionCicId: number,
		request: GuardianSubscriptionCicUpdateRequest
	) {
		const urlParams: GuardianSubscriptionCicProfileUrlRequest = {
			guardianSubscriptionCicId
		};

		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianSubscriptionCicProfileResponse>(
			RtVueApiRoutes.GuardianSubscriptionCic.Update,
			{
				body,
				urlParams
			}
		);
	}

	public delete(guardianSubscriptionCicId: number) {
		const urlParams: GuardianSubscriptionCicProfileUrlRequest = {
			guardianSubscriptionCicId
		};

		return this.fetchWithRoute<GuardianSubscriptionCicProfileResponse>(
			RtVueApiRoutes.GuardianSubscriptionCic.Delete,
			{ urlParams }
		);
	}
}
