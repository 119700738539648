/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianAlertRuleIndexRequest = {
    properties: {
        isActive: {
            type: 'number',
        },
        summary: {
            type: 'string',
        },
        alertTypeId: {
            type: 'number',
        },
        isSunday: {
            type: 'number',
            isRequired: true,
        },
        isMonday: {
            type: 'number',
            isRequired: true,
        },
        isTuesday: {
            type: 'number',
            isRequired: true,
        },
        isWednesday: {
            type: 'number',
            isRequired: true,
        },
        isThursday: {
            type: 'number',
            isRequired: true,
        },
        isFriday: {
            type: 'number',
            isRequired: true,
        },
        isSaturday: {
            type: 'number',
            isRequired: true,
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};