import { DidApplicationRouter } from 'RtUi/app/rtDid/rtDid.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { RtxDidApiRoutes } from 'RtExports/routes';

interface IDidDashboardProfileContainerTabs
	extends IProfileApplicationContainerTabs {}
export const DidDashboardProfileContainerTabs: IDidDashboardProfileContainerTabs =
	{};

export class DidDashboardRouterClass extends DidApplicationRouter<
	void,
	void,
	IDidDashboardProfileContainerTabs
> {
	constructor() {
		super(
			'Dashboard',
			'didDashboard',
			undefined,
			DidDashboardProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxDidApiRoutes.MetricsByNPA);
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getIndexLabel(record: undefined) {
		return '';
	}

	public getProfileLabel(profile: undefined) {
		return '';
	}
}

export const DidDashboardRouter = new DidDashboardRouterClass();
