import { RtxSipApiRoutes } from 'RtExports/routes';
import { TransactionIndexRequest, TransactionIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchTransactions = async (
	queryParams?: TransactionIndexRequest
): Promise<FullResponse<TransactionIndexResponse[]>> => {
	return handleGetRequest<TransactionIndexResponse[], true>(
		RtxSipApiRoutes.Transactions.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetTransactions = (queryParams?: TransactionIndexRequest) => {
	return useQuery<FullResponse<TransactionIndexResponse[]>, Error>(
		[`useGetTransactions`, queryParams],
		() => fetchTransactions(queryParams),
		{ enabled: true }
	);
};
