import * as React from 'react';
import { DashboardTabContext } from 'RtUi/components/ui/DashboardTabs/context';

interface IDashboardTabProps {
	tabId: string;
	icon: string;
}

export class DashboardTab extends React.PureComponent<
	React.PropsWithChildren<IDashboardTabProps>
> {
	public render() {
		return (
			<DashboardTabContext.Consumer>
				{({ activeTab }) => {
					if (activeTab !== this.props.tabId) {
						return null;
					}

					return this.props.children;
				}}
			</DashboardTabContext.Consumer>
		);
	}
}
