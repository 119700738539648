import { FC } from 'react';
import {
	Mermaid,
	IMermaidProps
} from 'RtUi/app/rtSip/SipTrace/lib/components/Mermaid';

interface ISipTraceChart extends Omit<IMermaidProps, 'onClick'> {
	onClick: (messageId: string) => void;
}

export const SipTraceChart: FC<React.PropsWithChildren<ISipTraceChart>> = (
	props
) => {
	const { onClick } = props;
	return (
		<Mermaid
			{...props}
			onClick={({ target }) => {
				if (!(target && target instanceof SVGRectElement)) {
					return;
				}
				const messageId = target.getAttribute('fill');
				onClick(messageId!);
			}}
		/>
	);
};
