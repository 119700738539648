/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TollfreeEnhancedDetailIndexResponse = {
    properties: {
        vuid: {
            type: 'string',
            isRequired: true,
        },
        fileStreamId: {
            type: 'number',
            isRequired: true,
        },
        callDate: {
            type: 'string',
            isRequired: true,
        },
        ingressConnectionId: {
            type: 'number',
            isRequired: true,
        },
        ingressTrunkGroup: {
            type: 'string',
            isRequired: true,
        },
        egressConnectionId: {
            type: 'number',
            isRequired: true,
        },
        egressTrunkGroup: {
            type: 'string',
            isRequired: true,
        },
        connect: {
            type: 'number',
            isRequired: true,
        },
        rawDurationMs: {
            type: 'number',
            isRequired: true,
        },
        jurisdiction: {
            type: 'string',
            isRequired: true,
        },
        ingressToStd: {
            type: 'string',
            isRequired: true,
        },
        ingressFrStd: {
            type: 'string',
            isRequired: true,
        },
        ingressFrLrn: {
            type: 'string',
            isRequired: true,
        },
        ingressFrDialCode: {
            type: 'string',
            isRequired: true,
        },
        ingressFrRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        ingressFrLrnDialCode: {
            type: 'string',
            isRequired: true,
        },
        originalCic: {
            type: 'string',
            isRequired: true,
        },
        originalRevenue: {
            type: 'number',
            isRequired: true,
        },
        originalRevenueSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        originalRevenueRate: {
            type: 'number',
            isRequired: true,
        },
        originalRevenueDurationMs: {
            type: 'number',
            isRequired: true,
        },
        originalCost: {
            type: 'number',
            isRequired: true,
        },
        originalCostSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        originalCostRate: {
            type: 'number',
            isRequired: true,
        },
        originalCostDurationMs: {
            type: 'number',
            isRequired: true,
        },
        originalMargin: {
            type: 'number',
            isRequired: true,
        },
        calculatedCic: {
            type: 'string',
            isRequired: true,
        },
        calculatedTemplate: {
            type: 'string',
            isRequired: true,
        },
        calculatedResporg: {
            type: 'string',
            isRequired: true,
        },
        calculatedCost: {
            type: 'number',
            isRequired: true,
        },
        calculatedCostSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        calculatedCostRate: {
            type: 'number',
            isRequired: true,
        },
        calculatedCostDurationMs: {
            type: 'number',
            isRequired: true,
        },
        calculatedMargin: {
            type: 'number',
            isRequired: true,
        },
        lessProfitable: {
            type: 'number',
            isRequired: true,
        },
        marginSummary: {
            type: 'string',
            isRequired: true,
        },
    },
};