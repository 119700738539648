import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { NetworkEfficiencyIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface INetworkEfficiencyRouterTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const NetworkEfficiencyContainerTabs: INetworkEfficiencyRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class NetworkEfficiencyRouterClass extends SipApplicationRouter<
	NetworkEfficiencyIndexResponse,
	NetworkEfficiencyIndexResponse,
	INetworkEfficiencyRouterTabs
> {
	constructor() {
		super(
			'Network Efficiency',
			'networkEfficiency',
			'rateCenter',
			NetworkEfficiencyContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.NetworkEfficiency);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const NetworkEfficiencyRouter = new NetworkEfficiencyRouterClass();
