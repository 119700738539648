import { MarginPlanIndexResponse } from 'RtModels';
import { MarginPlanResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/MarginPlanResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface MarginPlanDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		MarginPlanIndexResponse,
		T
	> {}

export const MarginPlanDataGridCache =
	new ResourceCacheMap<MarginPlanIndexResponse>(
		new MarginPlanResource(),
		'marginPlanId',
		'label'
	);

export const MarginPlanDataGridColumn = <T = any,>(
	config: MarginPlanDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: MarginPlanDataGridCache,
		...config
	});
};
