import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { Alert } from 'react-bootstrap';

interface RocTypeDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const RocTypeDataGridColumn = <T = any,>({
	...config
}: RocTypeDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: boolean) => {
		const text = value ? 'Incoming' : 'Outgoing';
		const icon = value
			? 'fa-sign-in-alt fa-rotate-90'
			: 'fa-sign-out-alt fa-rotate-270';
		const color = value ? 'dark' : 'light';

		return (
			<Alert
				transition={false}
				style={{ textAlign: 'center' }}
				variant={color}
				className="alert-sm mb-0 p-0 w-100"
			>
				<span>
					<i className={`fas fa-fw ${icon}`} />
					<span>&nbsp;{text}</span>
				</span>
			</Alert>
		);
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value) => (value ? 'Incoming' : 'Outgoing'),
		getValue: ({ cell }) => getValue(cell.getValue<boolean>())
	});
};
