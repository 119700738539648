import { MarginSummaryIndexRequest } from 'RtModels';
import { useGetMarginSummary } from 'RtUi/app/rtSip/MarginSummaryReport/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getMarginSummaryColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/marginSummary';
import { get, isNil } from 'lodash-es';
import { useMemo } from 'react';

interface IMarginSummaryReportDataGridProps {
	autoFocusFilter?: boolean;
	resourceParams?: MarginSummaryIndexRequest;
}

export const MarginSummaryReportDataGrid = ({
	resourceParams
}: IMarginSummaryReportDataGridProps) => {
	const { data, isFetching: isLoading } = useGetMarginSummary(resourceParams);
	const columns = useMemo(() => getMarginSummaryColumns(), []);

	const mappedData = useMemo(
		() =>
			data?.data.filter((value) =>
				Object.keys(value).every((key) => !isNil(get(value, key)))
			),
		[data]
	);

	return (
		<DataGrid
			data={mappedData}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_marginSummary'}
		/>
	);
};
