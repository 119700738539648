import { SystemNotificationRouter } from 'RtUi/app/rtAdmin/SystemNotifications/SystemNotification.router';
import { SystemNotificationsGrid } from 'RtUi/app/rtAdmin/SystemNotifications/lib/grids/SystemNotificationsGrid';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';

export const SystemNotificationIndexContainer = () => {
	return (
		<StandardLayout router={SystemNotificationRouter}>
			<SystemNotificationsGrid />
		</StandardLayout>
	);
};

SystemNotificationIndexContainer.displayName =
	'SystemNotificationIndexContainer';

SystemNotificationRouter.setIndexRtUiFunctionalComponent(
	SystemNotificationIndexContainer
);
