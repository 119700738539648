import { ResourceProfileResponse, ResourceType } from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { CarrierConnectResourceConfigurationRouter } from 'RtUi/app/rtCarrierConnect/ResourceConfigurations/CarrierConnectResourceConfigurations.router';
import { CarrierConnectResourceRouter } from 'RtUi/app/rtCarrierConnect/Resources/CarrierConnectResource.router';
import { ResourceEditor } from 'RtUi/app/rtCommon/Resources/lib/forms/ResourceEditor';
import { ResourceGrid } from 'RtUi/app/rtCommon/Resources/lib/grids/ResourceGrid';
import { CreateConnectionForm } from 'RtUi/app/rtDid/Resources/forms/CreateConnectionForm';
import { ConnectionsGrid } from 'RtUi/app/rtDid/Resources/lib/grids/ConnectionGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { FC } from 'react';

export const CarrierConnectResourceIndexContainer: FC<{}> = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Resources: { header: 'Resources', isDefault: true },
		Connections: {
			header: 'Connections'
		},
		CreateNewResource: {
			header: 'Create New Resource',
			isAction: true
		},
		CreateNewConnection: {
			header: 'Create New Connection',
			isAction: true
		}
	});

	const goToCarrierConnectResourceProfile = (
		profile: ResourceProfileResponse
	) => {
		const carrierConnectResourceProfilePath =
			CarrierConnectResourceRouter.getProfileRoute(profile.resourceId);

		BrowserHistory.push(carrierConnectResourceProfilePath);
	};

	return (
		<TabbedLayout
			router={CarrierConnectResourceRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Resources}>
				<ResourceGrid
					resourceParams={{
						includeResourceTypes: [ResourceType.CarrierConnect]
					}}
					router={CarrierConnectResourceConfigurationRouter}
					autoFocusFilter
					pageName="rtCarrierConnect_Resources"
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Connections}>
				<ConnectionsGrid
					router={CarrierConnectResourceRouter}
					resourceParams={{
						includeResourceTypes: [ResourceType.CarrierConnect]
					}}
					autoFocusFilter
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateNewResource}>
				<ResourceEditor
					createMode
					excludeResourceTypes={[ResourceType.CarrierConnect]}
					onCreate={(profile: ResourceProfileResponse) =>
						goToCarrierConnectResourceProfile(profile)
					}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateNewConnection}>
				<CreateConnectionForm
					createMode
					excludeResourceTypes={[ResourceType.CarrierConnect]}
					onCreate={(profile: ResourceProfileResponse) =>
						goToCarrierConnectResourceProfile(profile)
					}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

CarrierConnectResourceIndexContainer.displayName =
	'CarrierConnectResourceIndexContainer';

CarrierConnectResourceRouter.setIndexRtUiFunctionalComponent(
	CarrierConnectResourceIndexContainer
);
