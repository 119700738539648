import { ServerGroupMemberIndexResponse } from 'RtModels';
import { ServerGroupMembersRouter } from 'RtUi/app/rtAdmin/ServerGroupMembers/ServerGroupMembers.router';
import { useGetServerGroupsMembers } from 'RtUi/app/rtAdmin/ServerGroupMembers/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getServerGroupMemberColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/serverGroupMembers';
import { FC, useMemo } from 'react';

interface IServerGroupMembersDataGridProps {
	autoFocusFilter?: boolean;
}

export const ServerGroupMembersDataGrid: FC<
	IServerGroupMembersDataGridProps
> = ({}: IServerGroupMembersDataGridProps) => {
	const { data, isLoading } = useGetServerGroupsMembers();

	const columns = useMemo(() => getServerGroupMemberColumns(), []);

	return (
		<DataGrid<ServerGroupMemberIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'rtAdmin-serverGroupMembers'}
			router={ServerGroupMembersRouter}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
