/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TransactionIndexResponse = {
    properties: {
        transactionId: {
            type: 'number',
            isRequired: true,
        },
        accountId: {
            type: 'number',
            isRequired: true,
        },
        transactionTypeId: {
            type: 'number',
            isRequired: true,
        },
        amount: {
            type: 'number',
            isRequired: true,
        },
        appliedAmount: {
            type: 'number',
            isRequired: true,
        },
        externalRef1: {
            type: 'string',
            isRequired: true,
        },
        externalRef2: {
            type: 'string',
            isRequired: true,
        },
        comments: {
            type: 'string',
            isRequired: true,
        },
        transactionTime: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        account: {
            type: 'string',
            isRequired: true,
        },
        type: {
            type: 'string',
            isRequired: true,
        },
        isReversible: {
            type: 'number',
            isRequired: true,
        },
    },
};