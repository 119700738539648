import { RespOrgProfileResponse } from 'RtModels';
import { NumberGrid } from 'RtUi/app/rt800/Numbers/lib/grids/NumberGrid';
import {
	useGetRespOrg,
	useGetRespOrgContacts
} from 'RtUi/app/rt800/RespOrgs/lib/services';
import { RoutingGroupsGrid } from 'RtUi/app/rt800/RoutingGroups/lib/grids/RoutingGroupsGrid';
import { TemplateGrid } from 'RtUi/app/rt800/Templates/lib/grids/TemplateGrid';
import { NpaTotalsLineChart } from 'RtUi/components/charts/NpaTotalsLineChart';
import { IApplicationContainerProps } from 'RtUi/components/containers/ApplicationContainer';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { Col, Row } from 'react-bootstrap';
import { RespOrgRouter } from './RespOrg.router';
import { RespOrgEditor } from './lib/forms/RespOrgEditor';

interface IRespOrgContainerProps extends IApplicationContainerProps {}

export const RespOrgProfileContainer = ({}: IRespOrgContainerProps) => {
	const [tabs, activeTab, setActiveTab] = useTabs(
		RespOrgRouter.getProfileTabs()
	);
	const respOrgId = useIdParam();
	const { data: respOrg, isFetching, refetch } = useGetRespOrg({ respOrgId });
	const { data: contacts = [], isFetching: isFetchingContacts } =
		useGetRespOrgContacts({ respOrgId });

	if (isFetching || isFetchingContacts) {
		return <Loading />;
	}

	const canManage = RespOrgRouter.canManageWith(respOrg);

	return (
		<TabbedLayout<RespOrgProfileResponse>
			router={RespOrgRouter}
			profile={respOrg}
			canManageFn={() => canManage}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Dashboard} isDefault>
				<NpaTotalsLineChart id={respOrgId} type="RespOrg" />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Profile}>
				<Row>
					<Col lg={7}>
						<RespOrgEditor
							editMode={respOrg}
							onUpdateSuccess={() => refetch()}
						/>
					</Col>
					<Col lg={5}>
						{contacts.map((contact) => (
							<CollapsibleCard
								header={contact.contactType}
								useCardBody
								className="mt-xs-4 mt-lg-0"
								key={contact.contactTypeId}
							>
								<Row>
									<Col md={6}>
										<h6>Name</h6>
										<p>{contact.name ? String(contact.name) : ''}</p>
									</Col>
									<Col md={6}>
										<h6>Email</h6>
										<p>{contact.email ? String(contact.email) : ''}</p>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<h6>Phone</h6>
										<p>{contact.phone ? String(contact.phone) : ''}</p>
									</Col>
									<Col md={6}>
										<h6>Fax</h6>
										<p>{contact.fax ? String(contact.fax) : ''}</p>
									</Col>
								</Row>
							</CollapsibleCard>
						))}
					</Col>
				</Row>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Numbers}>
				<NumberGrid resourceParams={{ respOrgId }} />
			</TabbedLayoutTab>
			{canManage && (
				<TabbedLayoutTab {...tabs.Templates}>
					<TemplateGrid resourceParams={{ respOrgId, isActive: 1 }} />
				</TabbedLayoutTab>
			)}
			{canManage && (
				<TabbedLayoutTab {...tabs.RoutingGroups}>
					<RoutingGroupsGrid resourceParams={{ respOrgId, isActive: 1 }} />
				</TabbedLayoutTab>
			)}
		</TabbedLayout>
	);
};

RespOrgRouter.setProfileRtUiFunctionalComponent(RespOrgProfileContainer);
