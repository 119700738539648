import {
	GuardianServiceNumberSubscriptionIndexRequest,
	GuardianServiceNumberSubscriptionUpdateRequestBulk,
	GuardianServiceNumberSubscriptionWithGlobalData,
	PortingStatusIndexResponse
} from 'RtModels';
import { PortingStatusSelect } from 'RtUi/app/rtCommon/ServiceNumbers/lib/controls/PortingStatusSelect';
import { GuardianServiceNumberSubscriptionRouter } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/GuardianServiceNumberSubscription.router';
import { GuardianServiceNumberSubscriptionForm } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/forms/GuardianServiceNumberSubscriptionForm';
import { GuardianServiceNumberSubscriptionResource } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/resources/GuardianServiceNumberSubscriptionResource';
import {
	bulkUpdateServiceNumberSubscription,
	fetchServiceNumberSubscription,
	useGetServiceNumberSubscription
} from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/services';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getServiceNumberSubscriptionColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/serviceNumberSubscriptions';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { Aside } from 'RtUi/components/ui/Aside';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import clsx from 'clsx';
import moment from 'moment';
import { FC, Fragment, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Item } from 'react-contexify';
import { useMutation } from 'react-query';
import { useToggle } from 'react-use';
import DataGrid from 'RtUi/components/data/DataGrid';

interface IPartitionBillingEntityDataGridProps {
	resourceParams: GuardianServiceNumberSubscriptionIndexRequest;
	showActions?: boolean;
	rowThemeColor?: (
		row: GuardianServiceNumberSubscriptionWithGlobalData
	) => RowThemeColor | undefined;
	disableExternalLinks?: boolean;
}

export const GuardianServiceNumberSubscriptionGrid: FC<
	IPartitionBillingEntityDataGridProps
> = ({
	resourceParams,
	rowThemeColor,
	showActions = false,
	disableExternalLinks
}) => {
	const [rowSelection, setRowSelection] = useState<
		GuardianServiceNumberSubscriptionWithGlobalData[]
	>([]);
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetServiceNumberSubscription(resourceParams);
	const { mutateAsync } = useMutation(bulkUpdateServiceNumberSubscription);
	const columns = useMemo(() => getServiceNumberSubscriptionColumns(), []);
	const [portingStatus, setPortingStatus] =
		useState<PortingStatusIndexResponse>();
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setPortingStatus(undefined);
		setIsActive(undefined);
		setEndTs(undefined);
		setShow(false);
	};
	const handleShow = () => setShow(true);
	const [error, setError] = useState<any>();
	const [asideIsOpen, setAsideOpen] = useState<boolean>(false);
	const [isSubmitting, toggleIsSubmitting] = useToggle(false);
	const [isActive, setIsActive] = useState<number>();
	const [endTs, setEndTs] = useState<moment.Moment>();

	const addServiceNumberHandler = () => setAsideOpen(true);

	const cancelServiceNumber = async (
		row: GuardianServiceNumberSubscriptionWithGlobalData
	) => {
		const contentElement = (
			<div className="lead">
				<span>You are about to cancel&nbsp;</span>
				<span className="text-muted">{row.serviceNumber}</span>
				<span>.</span>
				<span>&nbsp;Would you like to continue?</span>
			</div>
		);

		const confirm = await Confirmation.create(contentElement, {
			confirmationBtnText: 'Yes, Cancel',
			confirmationBtnColor: 'warning'
		});

		if (!confirm) {
			return;
		}

		await new GuardianServiceNumberSubscriptionResource().cancel(
			row.guardianServiceNumberSubscriptionId
		);

		refetch();
	};

	const totalRows = Number(data?.totalCount);

	const getExportData = async () => {
		const result = await fetchServiceNumberSubscription({
			...resourceParams,
			pageSize: totalRows
		});
		return result.data;
	};

	const bulkSubmit = async () => {
		try {
			if (!confirm) {
				return;
			}

			const bulkUpdateReq: GuardianServiceNumberSubscriptionUpdateRequestBulk =
				{
					guardianServiceNumberSubscriptionIds: rowSelection.map(
						(r) => r.guardianServiceNumberSubscriptionId
					)
				};

			if (endTs) {
				bulkUpdateReq.endTs = endTs.toDate();
			}

			if (portingStatus) {
				bulkUpdateReq.portingStatus = portingStatus.portingStatusId;
			}

			if (typeof isActive === 'number') {
				bulkUpdateReq.isActive = isActive;
			}

			await mutateAsync(bulkUpdateReq);
		} catch (err) {
			setError(err);
			toggleIsSubmitting();
		}
	};

	return (
		<>
			<DataGrid<GuardianServiceNumberSubscriptionWithGlobalData>
				enableRowSelection
				selectedRows={rowSelection}
				onChangeSelectedRows={setRowSelection}
				pageName="rtVue_serviceNumberSubscriptions"
				data={data?.data}
				loading={isLoading}
				defaultSorting={[
					{ id: 'guardianServiceNumberSubscriptionId', desc: true }
				]}
				router={GuardianServiceNumberSubscriptionRouter}
				disableExternalLinks={disableExternalLinks}
				rowThemeColor={rowThemeColor}
				headerAction={() => {
					if (!showActions) {
						return <></>;
					}

					return (
						<>
							<Button
								variant="primary"
								onClick={() => handleShow()}
								className={clsx({
									'd-none': rowSelection.length === 0
								})}
							>
								<i className="fa-solid fa-fw fa-check-to-slot fa-lg" />
								<span>
									&nbsp;Bulk Update ({rowSelection.length.toLocaleString()})
								</span>
							</Button>
							<Button
								className="ms-2"
								onClick={() => addServiceNumberHandler()}
								variant="light"
							>
								<i className="fas fa-fw fa-plus" />
								<span>&nbsp;Add Service Number</span>
							</Button>
						</>
					);
				}}
				menuLinks={(row) => {
					if (row.isActive === 0) {
						return null;
					}

					return (
						<Item onClick={() => cancelServiceNumber(row)}>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-circle-stop me-2" />
								<span>
									<span>Cancel Service Number</span>
								</span>
							</span>
						</Item>
					);
				}}
				columns={columns}
				totalRows={totalRows}
				enableExternalExport={totalRows > Number(data?.data.length)}
				getExternalExportData={getExportData}
			/>
			<Aside isOpen={asideIsOpen} disabledBody>
				<Fragment>
					<Aside.Header
						header="Service Number Subscription"
						onClose={() => {
							setAsideOpen(false);
						}}
					/>

					{
						<GuardianServiceNumberSubscriptionForm
							createMode
							onCancel={() => {
								setAsideOpen(false);
							}}
							onSuccess={() => {
								setAsideOpen(false);

								refetch();
							}}
						/>
					}
				</Fragment>
			</Aside>
			<Aside isOpen={show} disabledBody>
				<Aside.Header header="Bulk Update" onClose={handleClose} />
				<RtUiForm
					error={error}
					isSubmitting={isSubmitting}
					onCancel={handleClose}
					onSubmit={async (e) => {
						e.preventDefault();

						setError(undefined);
						toggleIsSubmitting();

						await bulkSubmit();

						setIsActive(undefined);
						toggleIsSubmitting();
						handleClose();
						refetch();
					}}
				>
					<IsActiveRadioFormControl
						required
						hideBothOption
						label="Active"
						value={isActive}
						onChange={setIsActive}
					/>
					<DatetimeFormControl
						minDate={moment().endOf('day')}
						label="End Date"
						appendToBody
						disableNowPreset
						onChangeWithIsNow={setEndTs}
						value={endTs}
					/>
					<PortingStatusSelect
						onChange={setPortingStatus}
						value={portingStatus}
					/>
				</RtUiForm>
			</Aside>
		</>
	);
};
