import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { InvoiceIndexResponse, InvoiceProfileResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IInvoicesRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const InvoicesContainerTabs: IInvoicesRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class InvoicesRouterClass extends SipApplicationRouter<
	InvoiceIndexResponse,
	InvoiceProfileResponse,
	IInvoicesRouterTabs
> {
	constructor() {
		super('Invoices', 'invoices', 'invoiceId', InvoicesContainerTabs);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.Invoices);
	}

	public getProfileLabel(profile: InvoiceProfileResponse): string {
		return profile.invoiceId.toString();
	}
}

export const InvoicesRouter = new InvoicesRouterClass();
