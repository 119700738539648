import * as React from 'react';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface IRadioButtonOptions<T = string> {
	value: T;
	label?: React.ReactNode;
	disabled?: boolean;
	tooltip?: string;
}

interface IRadioButtonsProps<T> {
	options: Array<IRadioButtonOptions<T>>;
	append?: boolean;
	btnType?: 'form' | 'radio';
	value?: T;
	name?: string;
	vertical?: boolean;
	required?: boolean;
	disabled?: boolean;
	onChange?: (value: T) => void;
	initialValue?: T;
	id?: string;
}

export class RadioButtons<T = string> extends React.Component<
	IRadioButtonsProps<T>,
	{}
> {
	public static RadioButtonsCounter = 0;

	public radioBtnIndex = RadioButtons.RadioButtonsCounter++;

	public componentDidMount() {
		const { value, initialValue, onChange = () => ({}) } = this.props;

		if (typeof value === 'undefined') {
			if (typeof initialValue !== 'undefined') {
				onChange(initialValue);
			}
		}
	}

	public isSelected(value: T) {
		return this.props.value === value;
	}

	public toggleSelected(value: T) {
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

	public render() {
		const { btnType = 'radio' } = this.props;
		const classNames = ['btn-group-radio'];

		if (this.props.append) {
			classNames.push('btn-group-radio-append');
		}

		const getTargetName = (option: IRadioButtonOptions<T>) =>
			`RadioButton${this.radioBtnIndex}${option.value}`;

		return (
			<>
				<ButtonGroup
					className={classNames.join(' ')}
					vertical={this.props.vertical}
					id={this.props.id}
				>
					{this.props.options.map((option) => {
						const target = getTargetName(option);

						return (
							<OverlayTrigger
								key={String(option.value)}
								overlay={(props) =>
									option.tooltip ? (
										<Tooltip id={`${getTargetName(option)}-tooltip`} {...props}>
											{option.tooltip}
										</Tooltip>
									) : (
										<span></span>
									)
								}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										key={String(option.value)}
										id={target}
										disabled={option.disabled || this.props.disabled}
										variant={btnType}
										className="d-flex justify-content-start align-items-start"
										active={this.isSelected(option.value)}
										onClick={() => this.toggleSelected(option.value)}
									>
										<article>
											{this.isSelected(option.value) ? (
												<i className="fas fa-fw fa-check-circle text-primary" />
											) : (
												<i className="far fa-fw fa-circle" />
											)}
										</article>
										<article className="ml-1">
											<>{Boolean(option.label) ? option.label : option.value}</>
										</article>
										<input
											type="text"
											tabIndex={-1}
											required={this.props.required}
											minLength={1}
											name={this.props.name}
											onChange={() => void 0}
											value={
												typeof this.props.value !== 'undefined'
													? String(this.props.value)
													: ''
											}
											style={{
												height: 1,
												width: 0,
												opacity: 0.01,
												position: 'absolute',
												left: '50%',
												bottom: 0
											}}
										/>
									</Button>
								)}
							</OverlayTrigger>
						);
					})}
				</ButtonGroup>
			</>
		);
	}
}
