/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ScenarioActionMenu = {
    properties: {
        scenarioActionId: {
            type: 'ScenarioActionType',
            isRequired: true,
        },
        promptSegments: {
            type: 'Array',
            isRequired: true,
        },
        digits: {
            type: 'Array',
        },
        timeoutMs: {
            type: 'number',
            isRequired: true,
        },
        variableName: {
            type: 'string',
            isRequired: true,
        },
    },
};