import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	AccountIndexRequest,
	AccountIndexResponse,
	AccountProfileRequest,
	AccountSubscriptionIndexRequest,
	PortLimitIndexRequest,
	PortLimitIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchAccounts = async (
	queryParams?: AccountIndexRequest
): Promise<FullResponse<AccountIndexResponse[]>> => {
	return handleGetRequest<AccountIndexResponse[], true>(
		RtxSipApiRoutes.Accounts.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetAccounts = (queryParams?: AccountIndexRequest) => {
	return useQuery<FullResponse<AccountIndexResponse[]>, Error>(
		[`useGetAccounts`, queryParams],
		() => fetchAccounts(queryParams),
		{ enabled: true }
	);
};

const fetchAccountSubscriptions = async (
	urlParams: AccountProfileRequest,
	queryParams?: AccountSubscriptionIndexRequest
): Promise<FullResponse<SubscriptionIndexResponse[]>> => {
	return handleGetRequest<SubscriptionIndexResponse[], true>(
		RtxSipApiRoutes.AccountSubscriptions.Index,
		{
			includeFullResponse: true,
			queryParams,
			urlParams
		}
	);
};

export const useGetAccountSubscriptions = (
	urlParams: AccountProfileRequest,
	queryParams?: AccountSubscriptionIndexRequest
) => {
	return useQuery<FullResponse<SubscriptionIndexResponse[]>, Error>(
		[`useGetAccountSubscriptions`, urlParams, queryParams],
		() => fetchAccountSubscriptions(urlParams, queryParams),
		{ enabled: true }
	);
};

const fetchAccountPortLimits = async (
	urlParams?: PortLimitIndexRequest
): Promise<FullResponse<PortLimitIndexResponse[]>> => {
	return handleGetRequest<PortLimitIndexResponse[], true>(
		RtxSipApiRoutes.AccountPortLimits.Index,
		{
			includeFullResponse: true,
			urlParams
		}
	);
};

export const useGetAccountPortLimits = (urlParams?: PortLimitIndexRequest) => {
	return useQuery<FullResponse<PortLimitIndexResponse[]>, Error>(
		[`useGetAccountPortLimits`],
		() => fetchAccountPortLimits(urlParams),
		{ enabled: true }
	);
};
