/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NanpReserveRequestQuery = {
    properties: {
        resourceConfigurationId: {
            type: 'number',
            isNullable: true,
        },
        timeoutMs: {
            type: 'number',
        },
    },
};