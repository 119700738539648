import { useMemo } from 'react';
import { AsrSummaryIndexRequest } from 'RtModels';
import { useGetAsrSummary } from 'RtUi/app/rtSip/AsrSummary/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getAsrSummaryColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/asrSummary';

interface IAsrSummaryGridProps {
	resourceParams: AsrSummaryIndexRequest;
}

export const AsrSummaryGrid = ({ resourceParams }: IAsrSummaryGridProps) => {
	const { data, isFetching: isLoading } = useGetAsrSummary(resourceParams);
	const columns = useMemo(
		() => getAsrSummaryColumns(resourceParams.columns ?? []),
		[resourceParams]
	);

	return (
		<DataGrid
			data={data}
			columns={columns}
			loading={isLoading}
			pageName="rtSip-asrSummary"
		/>
	);
};
