import { RtVueApiRoutes } from 'RtExports/routes';
import { SubscriptionCicRouter } from 'RtUi/app/rtVue/SubscriptionCic/SubscriptionCic.router';
import { SubscriptionCicFormEditor } from 'RtUi/app/rtVue/SubscriptionCic/lib/form/SubscriptionCicFormEditor';
import { SubscriptionCicGrid } from 'RtUi/app/rtVue/SubscriptionCic/lib/grids/SubscriptionCicGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const SubscriptionCicIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		SubscriptionCIC: { header: 'SubscriptionCic', isDefault: true },
		CreateRerate: {
			header: 'Create Subscription CIC',
			isAction: true,
			rtRoute: RtVueApiRoutes.GuardianSubscriptionCic.Create
		}
	});

	return (
		<TabbedLayout
			router={SubscriptionCicRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.SubscriptionCIC}>
				<SubscriptionCicGrid />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateRerate}>
				<SubscriptionCicFormEditor
					createMode
					onSuccess={() => setActiveTab(tabs.SubscriptionCIC.header)}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

SubscriptionCicIndexContainer.displayName = 'SubscriptionCicIndexContainer';

SubscriptionCicRouter.setIndexRtUiFunctionalComponent(
	SubscriptionCicIndexContainer,
	{
		groupName: 'Configuration',
		orderPriority: 0,
		groupOrderPriority: 90
	}
);
