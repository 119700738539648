import { Permissions } from 'RtExports/routes';
import {
	AccountIndexResponse,
	CallProcessingResults,
	GatewayIndexResponse,
	RateCenterIndexResponse,
	SipCdrSearchIndexResponse,
	SipCdrSearchProfileResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { ReleaseCodeResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/ReleaseCodeResource';
import { ServerRouter } from 'RtUi/app/rtAdmin/Servers/Server.router';
import { CdrSearchReportRouter } from 'RtUi/app/rtSip/CdrSearchReport/CdrSearchReport.router';
import {
	useGetCallProcessingResults,
	useGetCdrSearch
} from 'RtUi/app/rtSip/CdrSearchReport/lib/services';
import { GatewaySelect } from 'RtUi/app/rtSip/Connections/lib/forms/GatewaySelect';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { SipTraceView } from 'RtUi/app/rtSip/SipTrace/lib/SipTraceView';
import { SipTraceResource } from 'RtUi/app/rtSip/SipTrace/lib/resource/SipTraceResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { UserActions } from 'RtUi/state/actions/user';
import { timestampToReadable } from 'RtUi/utils/maps';
import { isNil } from 'lodash-es';
import { FC, useCallback, useMemo, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useAsync, useMount, useSearchParam } from 'react-use';

export const CdrSearchReportProfileContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const id = useIdParam();
	const tabId = useSearchParam('tabId');

	const { data: cdrProfile, isLoading } = useGetCdrSearch(id);
	const { data: CallProcessingResultsData } = useGetCallProcessingResults();

	const sipTraceResource = useMemo(
		() =>
			cdrProfile &&
			new SipTraceResource({
				startTime: cdrProfile.iInviteTs,
				endTime: cdrProfile.iReleaseTs,
				gatewayId: cdrProfile.iGatewayId
			}),
		[cdrProfile]
	);

	const [tabs, activeTab, setActiveTab] = useTabs(
		CdrSearchReportRouter.getProfileTabs(cdrProfile)
	);

	useMount(() => {
		if (tabId && activeTab.header !== tabId) {
			setActiveTab(tabId);
		}
	});

	const [iRateCenter, setIRateCenter] = useState<RateCenterIndexResponse>();
	const [iVendorAccount, setIVendorAccount] = useState<AccountIndexResponse>();
	const [iVendorSubscription, setIVendorSubscription] =
		useState<SubscriptionIndexResponse>();
	const [eVendorAccount, setEVendorAccount] = useState<AccountIndexResponse>();
	const [eVendorSubscription, setEVendorSubscription] =
		useState<SubscriptionIndexResponse>();
	const [iCustAccount, setICustAccount] = useState<AccountIndexResponse>();
	const [iCustSubscription, setICustSubscription] =
		useState<SubscriptionIndexResponse>();
	const [eCustAccount, setECustAccount] = useState<AccountIndexResponse>();
	const [eCustSubscription, setECustSubscription] =
		useState<SubscriptionIndexResponse>();
	const [iGateway, setIGateway] = useState<GatewayIndexResponse>();
	const [iRemoteGateway, setIRemoteGateway] = useState<GatewayIndexResponse>();
	const [eGateway, setEGateway] = useState<GatewayIndexResponse>();
	const [eRemoteGateway, setERemoteGateway] = useState<GatewayIndexResponse>();

	const releaseCodes = useAsync(async () => {
		const rs = new ReleaseCodeResource();
		return rs.getAll();
	});

	const getReleaseCodeReadable = useCallback(
		(releaseCodeId: number) => {
			if (releaseCodes.loading) {
				return releaseCodeId;
			}

			const rCode = releaseCodes.value?.find(
				(rs) => rs.releaseCodeId === releaseCodeId
			);

			if (rCode?.label) {
				return `${rCode.label} (${releaseCodeId})`;
			}

			return releaseCodeId;
		},
		[releaseCodes]
	);

	if (isLoading) {
		return <Loading />;
	}

	if (!cdrProfile) {
		return <p>Not Found</p>;
	}

	const getCallProcessingResult = (result?: CallProcessingResults): string => {
		if (!isNil(result) && CallProcessingResultsData) {
			const data = CallProcessingResultsData.find(
				(res) => res.resultId === Number(result)
			);

			return String(data?.summary ?? '');
		}

		return String(result ?? '');
	};

	return (
		<TabbedLayout<SipCdrSearchProfileResponse>
			router={CdrSearchReportRouter}
			profile={cdrProfile}
			activeTab={activeTab.header}
			onTabChange={(activeTab) => {
				setActiveTab(activeTab);
			}}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<RtUiForm
					hideButtons
					createMode={false}
					displayMode={true}
					isInline
					className="mb-3"
				>
					<Row>
						<Col lg={6}>
							<fieldset className="card card-body mb-0">
								<legend className="card-header">
									<h6>Calling Party</h6>
								</legend>
								<InputFormControl
									label="Source"
									displayMode
									value={cdrProfile.cp}
								/>
								<InputFormControl
									label="LRN"
									displayMode
									value={cdrProfile.cpLrn}
								/>
								<InputFormControl
									label="Standardized"
									displayMode
									value={cdrProfile.cpStd}
								/>
								<RateCenterSelect
									displayMode={true}
									label="Rate Center"
									//cSpell:ignore irci
									initialOptionId={cdrProfile.irci}
									value={iRateCenter}
									onChange={setIRateCenter}
								/>
							</fieldset>
						</Col>
						<Col lg={6}>
							<fieldset className="card card-body mb-0">
								<legend className="card-header">
									<h6>Dialed Number</h6>
								</legend>
								<InputFormControl
									label="Source"
									displayMode
									value={cdrProfile.dn}
								/>
								<InputFormControl
									label="LRN"
									displayMode
									value={cdrProfile.dnLrn}
								/>
								<InputFormControl
									label="Standardized"
									displayMode
									value={cdrProfile.dnStd}
								/>
								<RateCenterSelect
									displayMode={true}
									label="Rate Center"
									initialOptionId={undefined}
								/>
							</fieldset>
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
							<fieldset className="card card-body mb-0">
								<legend className="card-header">
									<h6>Ingress Signaling</h6>
								</legend>
								<GatewaySelect
									displayMode={true}
									label="Local Gateway"
									initialOptionId={String(cdrProfile.iGatewayId)}
									value={iGateway}
									onChange={setIGateway}
								/>
								<GatewaySelect
									displayMode={true}
									label="Remote Gateway"
									initialOptionId={String(cdrProfile.iPeerId)}
									value={iRemoteGateway}
									onChange={setIRemoteGateway}
								/>
								<InputFormControl
									label="Route"
									displayMode
									value={String(cdrProfile.iRouteId)}
								/>
								<InputFormControl
									label="Invite Timestamp"
									displayMode
									value={timestampToReadable(cdrProfile.iInviteTs)}
								/>
								<InputFormControl
									label="Answer Timestamp"
									displayMode
									value={timestampToReadable(cdrProfile.iAnswerTs)}
								/>
								<InputFormControl
									label="Release Timestamp"
									displayMode
									value={timestampToReadable(cdrProfile.iReleaseTs)}
								/>
								<InputFormControl
									label="Duration"
									displayMode
									value={String(cdrProfile.iDuration) + 's'}
								/>
								<InputFormControl
									label="Result"
									displayMode
									value={
										cdrProfile && getCallProcessingResult(cdrProfile.iResult)
									}
								/>
								<InputFormControl
									label="Call Id"
									displayMode
									value={cdrProfile.iCallId}
									linkTo={
										!tabs.IngressSipTrace.isHidden
											? CdrSearchReportRouter.getProfileRoute(
													{
														cdrId: cdrProfile.cdrId,
														invite: cdrProfile.invite
													} as unknown as SipCdrSearchIndexResponse,
													tabs.IngressSipTrace.header
												)
											: undefined
									}
								/>
							</fieldset>
						</Col>
						<Col lg={6}>
							<fieldset className="card card-body mb-0">
								<legend className="card-header">
									<h6>Egress Signaling</h6>
								</legend>
								<GatewaySelect
									displayMode={true}
									label="Local Gateway"
									initialOptionId={String(cdrProfile.eGatewayId)}
									value={eGateway}
									onChange={setEGateway}
								/>
								<GatewaySelect
									displayMode={true}
									label="Remote Gateway"
									initialOptionId={String(cdrProfile.ePeerId)}
									value={eRemoteGateway}
									onChange={setERemoteGateway}
								/>
								<InputFormControl
									label="Route"
									displayMode
									value={String(cdrProfile.eRouteId)}
								/>
								<InputFormControl
									label="Invite Timestamp"
									displayMode
									value={timestampToReadable(cdrProfile.eInviteTs)}
								/>
								<InputFormControl
									label="Answer Timestamp"
									displayMode
									value={timestampToReadable(cdrProfile.eAnswerTs)}
								/>
								<InputFormControl
									label="Release Timestamp"
									displayMode
									value={timestampToReadable(cdrProfile.eReleaseTs)}
								/>
								<InputFormControl
									label="Duration"
									displayMode
									value={String(cdrProfile.eDuration) + 's'}
								/>
								<InputFormControl
									label="Result"
									displayMode
									value={
										cdrProfile && getCallProcessingResult(cdrProfile.eResult)
									}
								/>
								<InputFormControl
									label="Call Id"
									displayMode
									value={cdrProfile.eCallId}
									linkTo={
										!tabs.EgressSipTrace.isHidden
											? CdrSearchReportRouter.getProfileRoute(
													{
														cdrId: cdrProfile.cdrId,
														invite: cdrProfile.invite
													} as unknown as SipCdrSearchIndexResponse,
													tabs.EgressSipTrace.header
												)
											: undefined
									}
								/>
							</fieldset>
						</Col>
					</Row>
				</RtUiForm>

				{cdrProfile.routeAttempts && cdrProfile.routeAttempts.length > 0 && (
					<Row className="m-3">
						<Col>
							<h5>Vendor Route Attempts</h5>
							<Table>
								<th>Attempt</th>
								<th>Timestamp</th>
								<th>Subscription</th>
								<th>Vendor GW</th>
								<th>Tech Prefix</th>
								<th>Vendor PDD (ms)</th>
								<th>Dialed Number</th>
								<th>Release</th>
								{cdrProfile.routeAttempts.map((routeAttempt, i) => {
									return (
										<tr key={`route_attempt_${i}`}>
											<td>{routeAttempt.aEgrRouteDepth}</td>
											<td>{<>routeAttempt.aEgrInvTs</>}</td>
											<td>{routeAttempt.aEgrCostSubsId}</td>
											<td>{routeAttempt.egrSummary}</td>
											<td>{routeAttempt.egrTechPrefix}</td>
											<td>{routeAttempt.aPostDialDelay}</td>
											<td>{routeAttempt.aEgrNumber}</td>
											<td>
												{!releaseCodes.loading &&
													getReleaseCodeReadable(routeAttempt.aEgrReleaseId)}
											</td>
										</tr>
									);
								})}
							</Table>
						</Col>
					</Row>
				)}

				<RtUiForm hideButtons createMode={false} displayMode={true} isInline>
					<Row>
						<Col lg={6}>
							<fieldset className="card card-body mb-0">
								<legend className="card-header">
									<h6>Ingress Customer Rating</h6>
								</legend>
								<AccountSelect
									displayMode={true}
									value={iVendorAccount}
									onChange={setIVendorAccount}
									initialOptionId={
										cdrProfile.iRevenueCarrierId
											? String(cdrProfile.iRevenueCarrierId)
											: undefined
									}
								/>
								<SubscriptionSelect
									showAccountInfo={false}
									displayMode={true}
									value={iVendorSubscription}
									onChange={setIVendorSubscription}
									initialOptionId={cdrProfile.iRevenueSubscriptionId}
								/>
								<InputFormControl
									label="Jurisdiction"
									displayMode
									value={String(cdrProfile.iRevenueJurisdictionId) || ''}
								/>
								<InputFormControl
									label="Duration"
									displayMode
									value={String(cdrProfile.iRevenueDuration) + 's'}
								/>
								<InputFormControl
									label="Rate Key"
									displayMode
									value={cdrProfile.iRevenueRateKey}
								/>
								<InputFormControl
									label="Rate"
									displayMode
									value={String(cdrProfile.iRevenueRate)}
								/>
								<InputFormControl
									label="Charges"
									displayMode
									value={String(cdrProfile.iRevenueRateCharge)}
								/>
							</fieldset>
						</Col>
						<Col lg={6}>
							<fieldset className="card card-body mb-0">
								<legend className="card-header">
									<h6>Ingress Vendor Rating</h6>
								</legend>
								<AccountSelect
									displayMode={true}
									value={iCustAccount}
									onChange={setICustAccount}
									initialOptionId={
										cdrProfile.iCostCarrierId
											? String(cdrProfile.iCostCarrierId)
											: undefined
									}
								/>
								<SubscriptionSelect
									showAccountInfo={false}
									displayMode={true}
									value={iCustSubscription}
									onChange={setICustSubscription}
									initialOptionId={cdrProfile.iCostSubscriptionId}
								/>
								<InputFormControl
									label="Jurisdiction"
									displayMode
									value={String(cdrProfile.iCostJurisdictionId) || ''}
								/>
								<InputFormControl
									label="Duration"
									displayMode
									value={String(cdrProfile.iCostDuration) + 's'}
								/>
								<InputFormControl
									label="Rate Key"
									displayMode
									value={cdrProfile.iCostRateKey}
								/>
								<InputFormControl
									label="Rate"
									displayMode
									value={String(cdrProfile.iCostRate)}
								/>
								<InputFormControl
									label="Charges"
									displayMode
									value={String(cdrProfile.iCostRateCharge)}
								/>
							</fieldset>
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
							<fieldset className="card card-body mb-0">
								<legend className="card-header">
									<h6>Egress Customer Rating</h6>
								</legend>
								<AccountSelect
									displayMode={true}
									value={eVendorAccount}
									onChange={setEVendorAccount}
									initialOptionId={
										cdrProfile.eRevenueCarrierId
											? String(cdrProfile.eRevenueCarrierId)
											: undefined
									}
								/>
								<SubscriptionSelect
									showAccountInfo={false}
									displayMode={true}
									value={eVendorSubscription}
									onChange={setEVendorSubscription}
									initialOptionId={cdrProfile.eRevenueSubscriptionId}
								/>
								<InputFormControl
									label="Jurisdiction"
									displayMode
									value={String(cdrProfile.eRevenueJurisdictionId) || ''}
								/>
								<InputFormControl
									label="Duration"
									displayMode
									value={String(cdrProfile.eRevenueDuration) + 's'}
								/>
								<InputFormControl
									label="Rate Key"
									displayMode
									value={cdrProfile.eRevenueRateKey}
								/>
								<InputFormControl
									label="Rate"
									displayMode
									value={String(cdrProfile.eRevenueRate)}
								/>
								<InputFormControl
									label="Charges"
									displayMode
									value={String(cdrProfile.eRevenueRateCharge)}
								/>
							</fieldset>
						</Col>
						<Col lg={6}>
							<fieldset className="card card-body mb-0">
								<legend className="card-header">
									<h6>Egress Vendor Rating</h6>
								</legend>
								<AccountSelect
									displayMode={true}
									value={eCustAccount}
									onChange={setECustAccount}
									initialOptionId={
										cdrProfile.eCostCarrierId
											? String(cdrProfile.eCostCarrierId)
											: undefined
									}
								/>
								<SubscriptionSelect
									showAccountInfo={false}
									displayMode={true}
									value={eCustSubscription}
									onChange={setECustSubscription}
									initialOptionId={cdrProfile.eCostSubscriptionId}
								/>
								<InputFormControl
									label="Jurisdiction"
									displayMode
									value={String(cdrProfile.eCostJurisdictionId || '')}
								/>
								<InputFormControl
									label="Duration"
									displayMode
									value={String(cdrProfile.eCostDuration) + 's'}
								/>
								<InputFormControl
									label="Rate Key"
									displayMode
									value={cdrProfile.eCostRateKey}
								/>
								<InputFormControl
									label="Rate"
									displayMode
									value={String(cdrProfile.eCostRate)}
								/>
								<InputFormControl
									label="Charges"
									displayMode
									value={String(cdrProfile.eCostRateCharge)}
								/>
							</fieldset>
						</Col>
					</Row>
					{UserActions.has(Permissions.RtxAdministrator) && (
						<Row>
							<Col lg={6}>
								<fieldset className="card card-body mb-0">
									<legend className="card-header">
										<h6>Network Detail</h6>
									</legend>
									<InputFormControl
										label="Media Id"
										displayMode
										value={String(cdrProfile.mediaId)}
									/>
									<InputFormControl
										label="Server Id"
										displayMode
										value={String(cdrProfile.serverId)}
										linkTo={ServerRouter.getProfileRoute(cdrProfile.serverId)}
									/>
									<InputFormControl
										label="Server Name"
										displayMode
										value={
											cdrProfile.serverName ? String(cdrProfile.serverName) : ''
										}
									/>
								</fieldset>
							</Col>
						</Row>
					)}
				</RtUiForm>
			</TabbedLayoutTab>
			{sipTraceResource && (
				<>
					<TabbedLayoutTab {...tabs.IngressSipTrace}>
						<Row>
							<Col lg={12} xl={8}>
								<Card>
									<Card.Body>
										<SipTraceView
											callId={cdrProfile.iCallId}
											resource={sipTraceResource}
										/>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</TabbedLayoutTab>
					<TabbedLayoutTab {...tabs.EgressSipTrace}>
						<Row>
							<Col lg={12} xl={8}>
								<Card>
									<Card.Body>
										<SipTraceView
											callId={cdrProfile.eCallId}
											resource={sipTraceResource}
										/>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</TabbedLayoutTab>
				</>
			)}
		</TabbedLayout>
	);
};

CdrSearchReportRouter.setProfileRtUiFunctionalComponent(
	CdrSearchReportProfileContainer
);
