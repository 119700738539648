import { defaults, isNil, values } from 'lodash-es';
import * as pluralize from 'pluralize';
import * as React from 'react';
import { matchPath } from 'react-router';
import { IRtxApiRoutes, Permissions } from 'RtExports/routes';
import { FirebaseRouteType } from 'RtModels';
import {
	IRtUiControllerParams,
	RtUiController,
	RtUiFunctionalComponent
} from 'RtUi/app/@RtUi/RtUiDecorators';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { UserActions } from 'RtUi/state/actions/user';
import { IHttpResourceClass } from 'RtUi/utils/http/resources/interfaces';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import { SummaryComponent } from './SummaryComponent';
import { IReportRouteConfig } from 'RtUi/app/ApplicationShell/lib/interfaces';

export enum SearchConfidenceLevel {
	Certainty = 1,
	Probable = 2,
	Possible = 3,
	Unlikely = 4,
	Impossible = 9
}

export interface IRouterBreadcrumb {
	path: string;
	name: string;
	isActive: boolean;
}

interface IProfileMatchParams {
	id: string;
}

export type IRtUiControllerInitialParams = Partial<IRtUiControllerParams> &
	IReportRouteConfig;

export abstract class RtUiRouter<
	IndexType = any,
	ProfileType = any,
	TabType extends
		IProfileApplicationContainerTabs = IProfileApplicationContainerTabs
> {
	protected static readonly ID_PARM = ':id';
	protected profilePermissions: Permissions[] | null = null;
	protected indexPermissions: Permissions[] | null = null;
	protected navMenuPermissions: Permissions[] | null = null;
	protected firebaseRouteType: FirebaseRouteType | null = null;

	private routerId = generateUUID();

	constructor(
		protected sectionKey: string,
		public name: string,
		protected containerKey: string,
		protected propertyKey: IndexType extends void ? undefined : keyof IndexType,
		public profileTabs: TabType
	) {}

	public getRouterId() {
		return this.routerId;
	}

	public getFirebaseRouteType() {
		return this.firebaseRouteType;
	}

	public setFirebaseRouteType(firebaseRouteType: FirebaseRouteType | null) {
		this.firebaseRouteType = firebaseRouteType;
	}

	public async globalSearch(search: string): Promise<IndexType[]> {
		return [];
	}

	public setPermissionsFromApiRoute(
		apiRoutes: Partial<IRtxApiRoutes['anyKey']>
	) {
		if (apiRoutes.Index?.permissions) {
			this.setIndexPermissions(apiRoutes.Index.permissions);
		}

		if (apiRoutes.Index?.menuPermissions) {
			this.setNavMenuPermissions(apiRoutes.Index.menuPermissions);
		}

		if (apiRoutes.Profile?.permissions) {
			this.setProfilePermissions(apiRoutes.Profile.permissions);
		}
	}

	public setProfilePermissions(profilePermissions: Permissions[] | null) {
		if (Array.isArray(profilePermissions) && profilePermissions.length === 0) {
			profilePermissions = null;
		}

		this.profilePermissions = profilePermissions;
	}

	public setIndexPermissions(indexPermissions: Permissions[] | null) {
		if (Array.isArray(indexPermissions) && indexPermissions.length === 0) {
			indexPermissions = null;
		}

		this.indexPermissions = indexPermissions;
	}

	public setNavMenuPermissions(menuPermissions: Permissions[] | null) {
		if (Array.isArray(menuPermissions) && menuPermissions.length === 0) {
			menuPermissions = null;
		}

		this.navMenuPermissions = menuPermissions;
	}

	public getIndexPermissions() {
		return this.indexPermissions ? [...this.indexPermissions] : [];
	}

	public getProfilePermissions() {
		return this.profilePermissions ? [...this.profilePermissions] : [];
	}

	/**
	 * if nav menu permissions are NOT set, then use the index permissions
	 */
	public getNavMenuPermissions() {
		if (this.navMenuPermissions === null) {
			return this.getIndexPermissions();
		}

		return [...this.navMenuPermissions];
	}

	public globalSearchConfidenceCheck(search: string): SearchConfidenceLevel {
		return SearchConfidenceLevel.Impossible;
	}

	public getIndexControllerParams(
		params: IRtUiControllerInitialParams = {},
		prefixSectionKey = false
	): IRtUiControllerParams {
		const defaultControllerParams: IRtUiControllerParams = {
			path: this.getIndexRoute(prefixSectionKey),
			name: this.getPluralName(),
			permissions: this.getNavMenuPermissions()
		};

		const controllerParams = defaults(params, defaultControllerParams);
		return controllerParams;
	}

	public getProfileControllerParams(
		params: IRtUiControllerInitialParams = {},
		prefixSectionKey = false
	): IRtUiControllerParams {
		const defaultControllerParams: IRtUiControllerParams = {
			path: this.getProfileRouteWithParamNames(prefixSectionKey),
			name: this.getName(),
			permissions: this.getProfilePermissions(),
			isHidden: true
		};

		const controllerParams = defaults(params, defaultControllerParams);

		return controllerParams;
	}

	public getIndexRtUiController(params?: IRtUiControllerInitialParams) {
		return RtUiController(this.getIndexControllerParams(params));
	}

	public getProfileRtUiController(params?: IRtUiControllerInitialParams) {
		return RtUiController(this.getProfileControllerParams(params));
	}

	public setIndexRtUiFunctionalComponent(
		RouteComponent: React.ComponentType<React.PropsWithChildren<any>>,
		params?: IRtUiControllerInitialParams
	) {
		return RtUiFunctionalComponent(
			RouteComponent,
			this.getIndexControllerParams(params)
		);
	}

	public setProfileRtUiFunctionalComponent(
		RouteComponent: React.ComponentType<React.PropsWithChildren<any>>,
		params?: IRtUiControllerInitialParams
	) {
		return RtUiFunctionalComponent(
			RouteComponent,
			this.getProfileControllerParams(params)
		);
	}

	public getProfileRouteWithParamNames(prefixSectionKey?: boolean) {
		return this.getProfileRoute(
			RtUiRouter.ID_PARM,
			undefined,
			prefixSectionKey
		);
	}

	public getProfileRoute(
		record: IndexType | string | number,
		tabId?: string,
		prefixSectionKey = true
	) {
		return this.getRouteWithTab(
			this.formProfileRoute(record, tabId, prefixSectionKey),
			tabId
		);
	}

	public getIndexRoute(prefixSectionKey = true, tabId?: string) {
		let route = `/${this.containerKey}`;

		if (prefixSectionKey && this.sectionKey.length > 0) {
			route = `/${this.sectionKey}${route}`;
		}

		return this.getRouteWithTab(route, tabId);
	}

	public getIndexRouteWithParams(
		params: Record<string, string | number>,
		prefixSectionKey = true
	) {
		let route = `/${this.containerKey}`;

		if (prefixSectionKey && this.sectionKey.length > 0) {
			route = `/${this.sectionKey}${route}`;
		}

		return this.getRouteWithParams(route, params);
	}

	public getIndexMatch(path: string) {
		const queryIndex = path.indexOf('?');
		//remove qs from path for matching
		if (queryIndex > 0) {
			path = path.substring(0, queryIndex);
		}

		const indexRoute = this.getIndexControllerParams({}, true);

		return matchPath(indexRoute, path);
	}

	public getProfileMatch<
		ProfileParamsType extends IProfileMatchParams = IProfileMatchParams
	>(path: string) {
		const queryIndex = path.indexOf('?');
		//remove qs from path for matching
		if (queryIndex > 0) {
			path = path.substring(0, queryIndex);
		}

		const profileRoute = this.getProfileControllerParams({}, true);

		return matchPath(profileRoute, path);
	}

	public async getBreadcrumbs(
		currentPath: string,
		profile?: ProfileType
	): Promise<IRouterBreadcrumb[]> {
		const breadcrumbs: IRouterBreadcrumb[] = [];
		const isProfileMatch = this.getProfileMatch(currentPath);
		const isIndexMatch = this.getIndexMatch(currentPath);

		if (isIndexMatch || isProfileMatch) {
			const isIndexBreadcrumbActive = !isProfileMatch;
			const indexBreadcrumb = this.getIndexBreadcrumb(isIndexBreadcrumbActive);

			breadcrumbs.push(indexBreadcrumb);
		}

		if (isProfileMatch?.params.id && profile) {
			const { id } = isProfileMatch.params;
			const profileBreadCrumb = this.getProfileBreadcrumb(id, profile, true);

			breadcrumbs.push(profileBreadCrumb);
		}
		return breadcrumbs;
	}

	public getProfileBreadcrumb(
		id: string,
		profile?: ProfileType,
		isActive = true,
		tabId?: string
	): IRouterBreadcrumb {
		const path = this.getProfileRoute(id, tabId);
		const name = profile ? this.getProfileLabel(profile) : id;

		return { path, name, isActive };
	}

	public getIndexBreadcrumb(isActive = false): IRouterBreadcrumb {
		const path = this.getIndexRoute();
		const name = this.getPluralName();

		return { path, name, isActive };
	}

	public hasAccessToIndex() {
		const userHasPermissions = UserActions.has(...this.getIndexPermissions());

		return userHasPermissions;
	}

	public hasAccessToProfile() {
		const userHasPermissions = UserActions.has(...this.getProfilePermissions());

		return userHasPermissions;
	}

	public recordHasAccessToProfile(record: any) {
		if (record === null || typeof record === 'undefined') {
			return false;
		}

		// 1. Check permissions
		if (Array.isArray(this.profilePermissions)) {
			const userHasPermissions = UserActions.has(
				...this.getProfilePermissions()
			);

			if (!userHasPermissions) {
				return false;
			}
		}

		// 2. If propertyKey is undefined, then profile is not accessible
		if (typeof this.propertyKey === 'undefined') {
			return false;
		}

		// 3. If propertyKey is in record and not nil, then profile is accessible
		if (this.propertyKey.toString() in record) {
			return !isNil(record[this.propertyKey]);
		}

		return false;
	}

	public getName() {
		return this.name;
	}

	public getPluralName(count?: number, inclusive?: boolean) {
		return pluralize(this.name, count, inclusive);
	}

	public abstract getProfileLabel(profile: ProfileType): string;

	public getProfileTabs(profile?: ProfileType): TabType {
		return this.profileTabs;
	}

	public getIndexLabel(record: IndexType) {
		if (!this.propertyKey) {
			return '';
		}

		//@ts-ignore
		return String(record[this.propertyKey]);
	}

	public getSummaryComponentFor(
		record: IndexType,
		externalRouters: RtUiRouter[]
	): React.ReactNode {
		const label = <>{this.getIndexLabel(record)}</>;

		return this.createSummaryComponentFor(record, label, [], externalRouters);
	}

	public canManageWith(record?: any) {
		if (!record) {
			return false;
		}

		return this.hasAccessToRespOrg(record);
	}

	public hasAccessToRespOrg<T extends { respOrgId: string }>(
		record: T | string
	) {
		return UserActions.hasAccessToRespOrg(record);
	}

	public hasAccessToEntity<T extends { entityId: string }>(record: T | string) {
		return UserActions.hasAccessToEntity(record);
	}

	public getTabsFor(record?: any): TabbedLayoutTabProps[] {
		const tabs = values(this.getProfileTabs(record));

		return tabs.filter((tab) => {
			const { requireCanManage, rtRoute, permissions, isHidden } = tab;

			if (isHidden) {
				return !isHidden;
			}

			if (permissions) {
				return UserActions.has(...permissions);
			}

			if (rtRoute) {
				return UserActions.has(...rtRoute.permissions);
			}

			if (requireCanManage) {
				return this.canManageWith(record);
			}

			return true;
		});
	}

	protected getRouteWithTab(route: string, tabId?: string) {
		const params: Record<string, string | number> = {};

		if (tabId) {
			params.tab = tabId;
		}

		return this.getRouteWithParams(route, params);
	}

	protected getRouteWithParams(
		route: string,
		params: Record<string, string | number>
	) {
		let routeConcatCharacter = '?';

		for (const paramKey of Object.keys(params)) {
			const paramValue = encodeURIComponent(params[paramKey]);

			route += `${routeConcatCharacter}${paramKey}=${paramValue}`;
			routeConcatCharacter = '&';
		}

		return route;
	}

	protected formProfileRoute(
		record: IndexType | string | number,
		tabId?: string,
		prefixSectionKey?: boolean
	) {
		let containerId = '';

		if (typeof record === 'string' || typeof record === 'number') {
			containerId = String(record);
		} else if (typeof record === 'object') {
			if (!this.propertyKey) {
				throw new Error(`propertyKey does not exist`);
			}

			if (!record || !(this.propertyKey.toString() in record)) {
				throw new Error(
					`Record does not container ${this.propertyKey.toString()}`
				);
			}

			//@ts-ignore
			containerId = String(record[this.propertyKey]);
		} else {
			throw new Error(`Record must be an object or string`);
		}

		let route = `/${this.containerKey}`;

		if (prefixSectionKey && this.sectionKey) {
			route = `/${this.sectionKey}${route}`;
		}

		route = `${route}/${containerId}`;

		return route;
	}

	protected createSummaryComponentFor(
		record: IndexType,
		header: JSX.Element,
		badges: Array<string | Promise<string>> = [],
		externalRouters: RtUiRouter[] = []
	): React.ReactNode {
		return (
			<SummaryComponent<IndexType>
				record={record}
				header={header}
				badges={badges}
				externalRouters={externalRouters}
			/>
		);
	}

	protected async defaultGlobalSearch(
		resourceClass: IHttpResourceClass<IndexType>,
		search: string,
		minLength = 3
	) {
		if (search.length < minLength) {
			return [];
		}
		const resource = new resourceClass();
		const pageSize = 5;

		return resource.getAll({ pageSize, search });
	}
}
