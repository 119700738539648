import { FC } from 'react';
import { IFilterSearchResult } from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { DataFilter } from 'RtModels';
import { IFilterSearchResults } from 'RtUi/app/rtVue/common/lib/http/RtVueFilterSearch';
import { VueSearchResultsList } from 'RtUi/app/rtVue/common/lib/components/VueSearchResultsList';

interface IVueSearchResults {
	searchResults: IFilterSearchResults | undefined;
	filters: DataFilter[];
	toggleOnChange: (filterRes: IFilterSearchResult, key: string) => void;
}

export const VueSearchResults: FC<
	React.PropsWithChildren<IVueSearchResults>
> = ({ searchResults, filters, toggleOnChange }) => {
	if (!searchResults) {
		return null;
	}

	const searchResultFiltersWithResults = searchResults.filters.filter(
		(searchFilter) => searchFilter.results.length > 0
	);

	searchResultFiltersWithResults.sort((f1, f2) =>
		f1.commonName.localeCompare(f2.commonName)
	);

	return (
		<>
			{searchResultFiltersWithResults.length > 0 ? (
				<section className="custom-popup-area p-2 border border-1 rounded">
					{searchResultFiltersWithResults.map((filterResults, i) => (
						<section key={`${filterResults.commonName}_${i}}`}>
							<h6 className="mb-0">
								<b>{filterResults.commonName}</b>
							</h6>
							<VueSearchResultsList
								filterResults={filterResults}
								filters={filters}
								toggleOnChange={toggleOnChange}
							/>
						</section>
					))}
				</section>
			) : null}
		</>
	);
};
