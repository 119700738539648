import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

// exported definitions
// ======================================================================

export class CprColPercent extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.Percent;

	public readonly valueLimit = 1;
	// @TODO verify allowOther
	public readonly allowOther = true;

	//only allow 1 - 99 values
	public readonly valueRegExp = /^([1-9][0-9]?)$/;

	public isPossibleValue(rawVal: string) {
		// only validating by valueRegExp
		return true;
	}

	public getValue(): number | null {
		if (this.cprValues.length !== 1) {
			return null;
		}
		return parseInt(this.cprValues[0].getValue(), 10);
	}

}
