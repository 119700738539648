/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ScenarioActionResourceTagging = {
    properties: {
        scenarioActionId: {
            type: 'ScenarioActionType',
            isRequired: true,
        },
        resourceConfigurationId: {
            type: 'number',
            isRequired: true,
        },
        scenarioId: {
            type: 'number',
            isRequired: true,
        },
        attemptTags: {
            type: 'Array',
        },
        successTags: {
            type: 'Array',
        },
        errorTags: {
            type: 'Array',
        },
    },
};