import { Rt800ApiRoutes } from 'RtExports/routes';
import { TemplateIndexResponse, TemplateProfileResponse } from 'RtModels';
import { Rt800Router } from 'RtUi/app/rt800/rt800.router';
import { SearchConfidenceLevel } from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { TemplateResource } from './lib/resources/TemplateResource';

interface ITemplateContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Numbers: TabbedLayoutTabProps;
	Routing: TabbedLayoutTabProps;
}

export const TemplateContainerTabs: ITemplateContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Numbers: {
		header: 'Numbers'
	},
	Routing: {
		header: 'Routing Profiles',
		rtRoute: Rt800ApiRoutes.TemplateCprs.Index
	}
};

class TemplatesRouterClass extends Rt800Router<
	TemplateIndexResponse,
	TemplateProfileResponse,
	ITemplateContainerTabs
> {
	constructor() {
		super('Template', 'templates', 'templateName', TemplateContainerTabs);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.Templates);
	}

	public globalSearch(search: string) {
		return this.defaultGlobalSearch(TemplateResource, search);
	}

	public globalSearchConfidenceCheck() {
		return SearchConfidenceLevel.Possible;
	}

	/**
	 * Assure that access to this router is not mistaken for an LCR Task;
	 * assert that 'lcrTaskId' is not in the record;
	 * @override
	 * @param record
	 */
	public recordHasAccessToProfile(record: any) {
		const baseAccess = super.recordHasAccessToProfile(record);

		if (!baseAccess) {
			return false;
		}

		const hasLcrTaskId = 'lcrTaskId' in record;

		return !hasLcrTaskId;
	}

	public getProfileLabel(profile: TemplateProfileResponse) {
		return profile.templateName;
	}
}

export const TemplateRouter = new TemplatesRouterClass();
