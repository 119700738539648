import moment from 'moment';
import { Component } from 'react';
import {
	FireBaseAdditionalDataResourceType,
	FirebaseCreateRequest,
	FirebaseMessageSeverity,
	FirebaseMessageType,
	FirebaseTargetType,
	PartitionIndexResponse,
	ProductId,
	UserIndexResponse
} from 'RtModels';
import { PartitionSelect } from 'RtUi/app/rtAdmin/lib/controls/PartitionSelect';
import { ProductSelect } from 'RtUi/app/rtAdmin/SystemNotifications/lib/controls/ProductSelectFormControl';
import { SystemNotificationHttp } from 'RtUi/app/rtAdmin/SystemNotifications/lib/http/SystemNotificationHttp';
import { AlertRuleSelect } from 'RtUi/app/rtGuardian/AlertHistory/lib/controls/AlertRuleSelect';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { FirebaseUser } from 'RtUi/state/actions/user/FirebaseUser';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import { IFirebaseUIMessage } from 'RtUi/state/actions/user/BaseFireBase';

interface ISystemNotificationFormProps {
	profile?: IFirebaseUIMessage;
	onCancel?: () => void;
	onSuccess?: () => void;
	narrow?: boolean;
}

interface ISystemNotificationFormState extends FirebaseCreateRequest {
	isSubmitting: boolean;
	error?: any;
	severityOption:
		| ISimpleSelectFormControlOption<FirebaseMessageSeverity>
		| undefined;
	partitionOption: PartitionIndexResponse | undefined;
	userOption: UserIndexResponse | undefined;
	productOption: ISimpleSelectFormControlOption<ProductId> | undefined;
	expireTs: moment.Moment;
	productSelectKey: string;
	severitySelectKey: string;
	linkedResource?: ISimpleSelectFormControlOption<FireBaseAdditionalDataResourceType>;
	linkToSelectValue?: any;
}

export class SystemNotificationForm extends Component<
	ISystemNotificationFormProps,
	ISystemNotificationFormState
> {
	private static readonly InitialState: ISystemNotificationFormState = {
		isSubmitting: false,
		error: undefined,
		messageType: FirebaseMessageType.UserAlert,
		severity: FirebaseMessageSeverity.Info,
		label: '',
		summary: '',
		content: '',
		targetType: FirebaseTargetType.Global,
		targetTypeId: 0,
		expireHours: 1,
		severityOption: undefined,
		partitionOption: undefined,
		userOption: undefined,
		productOption: undefined,
		expireTs: moment().add(1, 'hour'),
		productSelectKey: 'productSelectKey',
		severitySelectKey: 'severitySelectKey',
		additionalData: undefined
	};

	public readonly linkedResourceMap: Map<
		FireBaseAdditionalDataResourceType,
		() => JSX.Element
	> = new Map([
		[
			FireBaseAdditionalDataResourceType.AlertRule,
			() => (
				<AlertRuleSelect
					key="select"
					label="Alert Rule"
					onChange={(alertRule) => {
						this.setState({
							linkToSelectValue: alertRule,
							additionalData: {
								resourceType: FireBaseAdditionalDataResourceType.AlertRule,
								resourceId: alertRule?.guardianAlertId.toString()
							}
						});
					}}
					value={this.state?.linkToSelectValue}
					initialOptionId={this.props.profile?.additionalData?.resourceId.toString()}
				/>
			)
		]
	]);

	public state: ISystemNotificationFormState = {
		...SystemNotificationForm.InitialState
	};

	public componentDidMount() {
		this.loadProfileOrDefaultValues();
	}

	public componentDidUpdate(prevProps: ISystemNotificationFormProps) {
		if (prevProps.profile !== this.props.profile) {
			this.loadProfileOrDefaultValues();
		}
	}

	public render() {
		const { onCancel = () => {} } = this.props;

		return (
			<RtForm
				narrow={this.props.narrow}
				createMode={!this.props.profile}
				onSubmit={this.onSubmit}
				displayMode={false}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onCancel={onCancel}
			>
				{!this.props.profile && (
					<RadioFormControl<FirebaseTargetType>
						required
						label="Scope"
						value={this.state.targetType}
						onChange={(targetType) => this.setState({ targetType })}
						options={[
							{
								value: FirebaseTargetType.Global,
								label: 'Global'
							},
							{
								value: FirebaseTargetType.Partition,
								label: 'Partition'
							},
							{
								value: FirebaseTargetType.Product,
								label: 'Product'
							},
							{
								value: FirebaseTargetType.User,
								label: 'User'
							},
							{
								value: FirebaseTargetType.Login,
								label: 'Login'
							}
						]}
					/>
				)}
				{this.state.targetType !== FirebaseTargetType.Login && (
					<RadioFormControl<FirebaseMessageType>
						required
						label="Message Type"
						value={this.state.messageType}
						onChange={(messageType) => this.setState({ messageType })}
						options={[
							{
								value: FirebaseMessageType.UserAlert,
								label: 'User Alert'
							},
							{
								value: FirebaseMessageType.BannerAlert,
								label: 'System Alert'
							}
						]}
						initialValue={this.props.profile?.messageType}
					/>
				)}
				{this.props.profile && (
					<InputFormControl
						label="Database Path"
						required
						displayMode={true}
						value={this.props.profile.databasePath}
					/>
				)}
				{this.state.targetType === FirebaseTargetType.Partition && (
					<PartitionSelect
						required
						value={this.state.partitionOption}
						onChange={(partitionOption) =>
							this.setState({
								partitionOption,
								targetTypeId: partitionOption.partitionId
							})
						}
					/>
				)}
				{this.state.targetType === FirebaseTargetType.User && (
					<UserSelect
						required
						value={this.state.userOption}
						onChange={(userOption) =>
							this.setState({
								userOption,
								targetTypeId: userOption.userId
							})
						}
					/>
				)}
				{this.state.targetType !== FirebaseTargetType.Login && (
					<ProductSelect
						key={this.state.productSelectKey}
						required
						clearable={false}
						label="Product"
						value={this.state.productOption}
						onChange={(productOption) => this.updateProduct(productOption)}
						initialOptionId={
							this.props.profile
								? this.props.profile?.productId.toString()
								: undefined
						}
					/>
				)}
				<SimpleSelectFormControl<FirebaseMessageSeverity>
					required
					key={this.state.severitySelectKey}
					clearable={false}
					label="Severity"
					initialOptionId={String(this.state.severity)}
					value={this.state.severityOption}
					onChange={(severityOption) => this.updateSeverity(severityOption)}
					options={[
						{
							value: FirebaseMessageSeverity.Info,
							label: 'Informational Message'
						},
						{
							value: FirebaseMessageSeverity.Success,
							label: 'Successful Message'
						},
						{
							value: FirebaseMessageSeverity.Minor,
							label: 'Minor Incident'
						},
						{
							value: FirebaseMessageSeverity.Major,
							label: 'Major Incident'
						},
						{
							value: FirebaseMessageSeverity.Critical,
							label: 'Critical Incident'
						}
					]}
				/>
				<InputFormControl
					label="Label"
					required
					value={this.state.label}
					onChange={(label) => this.setState({ label })}
					initialValue={this.props.profile?.label}
				/>
				<InputFormControl
					label="Summary"
					required
					subLabel="(the message users see)"
					value={this.state.summary}
					onChange={(summary) => this.setState({ summary })}
				/>
				{this.state.targetType !== FirebaseTargetType.Login && (
					<TextAreaFormControl
						label="Content"
						value={this.state.content}
						onChange={(content) => this.setState({ content })}
					/>
				)}
				{this.state.targetType !== FirebaseTargetType.Login && (
					<SimpleSelectFormControl<FireBaseAdditionalDataResourceType>
						label="Link to"
						initialOptionId={this.state.additionalData?.resourceType?.toString()}
						value={this.state.linkedResource}
						onChange={(linkedResource) => {
							const additionalData = linkedResource
								? {
										resourceType: linkedResource?.value,
										resourceId: ''
									}
								: null;
							this.setState({
								additionalData,
								linkedResource
							});
						}}
						options={[
							{
								value: FireBaseAdditionalDataResourceType.AlertRule,
								label: 'Alert Rule'
							}
						]}
					/>
				)}
				{this.state.linkedResource &&
					this.linkedResourceMap.get(this.state.linkedResource.value)!()}
				<DatetimeFormControl
					label="Expire"
					appendToBody
					required
					disableNowPreset
					onChange={(expireTs) => this.setState({ expireTs })}
					value={this.state.expireTs}
				/>
			</RtForm>
		);
	}

	public updateSeverity(
		severityOption: ISimpleSelectFormControlOption<FirebaseMessageSeverity>
	) {
		const severity = severityOption.value;

		this.setState({ severityOption, severity });
	}

	public updateProduct(
		productOption: ISimpleSelectFormControlOption<ProductId>
	) {
		this.setState({ productOption });

		if (this.state.targetType === FirebaseTargetType.Product) {
			this.setState({ targetTypeId: productOption.value });
		}
	}

	public onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		const { onSuccess = () => {} } = this.props;
		const systemNotificationHttp = new SystemNotificationHttp();
		const request: FirebaseCreateRequest = {
			productId: this.state.productOption?.value,
			messageType: this.state.messageType,
			severity: this.state.severity,
			label: this.state.label,
			summary: this.state.summary,
			content: this.state.content,
			targetType: this.state.targetType,
			targetTypeId: this.state.targetTypeId,
			expireHours: Math.round(
				moment.duration(this.state.expireTs.diff(moment())).asHours()
			),
			additionalData: this.state.additionalData
		};

		try {
			this.setState({ isSubmitting: true });

			if (this.props.profile) {
				await FirebaseUser.getInstance().updateMessage({
					...this.props.profile,
					...request,
					expireTs: this.state.expireTs,
					userHasRead: false
				});
			} else {
				await systemNotificationHttp.create(request);
			}

			const initialState = {
				...SystemNotificationForm.InitialState
			};

			HttpResource.showSuccessNotification(
				`Notification ${this.props.profile ? 'Updated' : 'Created'}`
			);

			this.setState(initialState, onSuccess);
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	};

	private loadProfileOrDefaultValues() {
		const props = this.props;
		this.setState({
			...SystemNotificationForm.InitialState,
			productSelectKey: `prod_select_${generateUUID()}`,
			severitySelectKey: `sev_select_${generateUUID()}`,
			expireTs: moment().add(1, 'hour')
		});

		if (props.profile) {
			this.setState({
				content: props.profile.content,
				messageType: props.profile.messageType,
				label: props.profile.label,
				summary: props.profile.summary,
				severity: props.profile.severity,
				productId: props.profile.productId,
				additionalData: props.profile.additionalData
			});
		}
	}
}
