import React from 'react';
import { Card } from 'react-bootstrap';
import { useUuid } from 'RtUi/components/hooks/useUuid';
import { Aside } from 'RtUi/components/ui/Aside';

export const StandardLayoutHelpContainer: React.FC<
	React.PropsWithChildren<unknown>
> = ({ children }) => {
	const [showHelp, setShowHelp] = React.useState<boolean>();
	const [uuid] = useUuid();
	if (!children) {
		return null;
	}

	return (
		<React.Fragment key={uuid}>
			{!showHelp && (
				<a className="help-container-toggle" onClick={() => setShowHelp(true)}>
					<span className="d-flex align-items-center">
						<i className="fas fa-fw fa-angle-double-left ml-1 me-1" />
						<span>Help</span>
					</span>
				</a>
			)}
			<Aside disabledBody isOpen={showHelp ?? false}>
				<Aside.Header
					onClose={() => setShowHelp(false)}
					header={
						<h5 className="mb-0">
							<i className="fas fa-fw fa-question-circle link-color me-2" />
							<span>Help</span>
						</h5>
					}
				></Aside.Header>
				<Card.Body>{children}</Card.Body>
			</Aside>
		</React.Fragment>
	);
};
