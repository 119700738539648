import { Rt800ApiRoutes } from 'RtExports/routes';
import { NumberGroupIndexRequest, NumberGroupIndexResponse } from 'RtModels';
import { NumberGroupRouter } from 'RtUi/app/rt800/NumberGroups/NumberGroup.router';
import { NumberGroupEditor } from 'RtUi/app/rt800/NumberGroups/lib/forms/NumberGroupEditor';
import {
	fetchNumberGroups,
	useGetNumberGroups
} from 'RtUi/app/rt800/NumberGroups/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { CreateHeaderActionAside } from 'RtUi/components/data/DataGrid/components/CreateHeaderActionAside';
import { getNumberGroupColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/numberGroups';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

interface INumberGroupsGridProps {
	resourceParams?: NumberGroupIndexRequest;
}

export const NumberGroupsGrid = ({
	resourceParams
}: INumberGroupsGridProps) => {
	const { data, isFetching: isLoading } = useGetNumberGroups(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportNumberGroups', resourceParams],
		resourceParams ?? {},
		(params) => fetchNumberGroups(params)
	);
	const columns = useMemo(() => getNumberGroupColumns(), []);
	const navigate = useNavigate();

	const goToNumberProfile = (numberGroupId: number) => {
		const numberProfilePath = NumberGroupRouter.getProfileRoute(numberGroupId);

		navigate(numberProfilePath);
	};

	return (
		<DataGrid<NumberGroupIndexResponse>
			router={NumberGroupRouter}
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rt800_numberGroups'}
			enableExternalExport
			getExternalExportData={fullResultsReq}
			headerAction={() => (
				<CreateHeaderActionAside
					isLoading={false}
					router={NumberGroupRouter}
					permissions={Rt800ApiRoutes.NumberGroups.Create.permissions}
				>
					<NumberGroupEditor
						onSuccess={(profile) => goToNumberProfile(profile.numberGroupId)}
					/>
				</CreateHeaderActionAside>
			)}
		/>
	);
};
