import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	UserPageConfiguration,
	UserPageConfigurationIndexRequest,
	UserPageConfigurationUpdateRequest
} from 'RtModels';
import {
	handleGetRequest,
	handlePutRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

interface IUserConfigurationUpdatePayload {
	pageName: string;
	body: UserPageConfigurationUpdateRequest;
}

export const postUserConfiguration = async (
	payload: IUserConfigurationUpdatePayload
) => {
	const { pageName, body } = payload;
	const urlParams: UserPageConfigurationIndexRequest = { pageName };

	return handlePutRequest(
		RtCommonApiRoutes.UserPageConfiguration.Update,
		body,
		{
			urlParams
		}
	);
};

const fetchUserConfiguration = async (
	urlParams: UserPageConfigurationIndexRequest
): Promise<UserPageConfiguration | undefined> => {
	return handleGetRequest(RtCommonApiRoutes.UserPageConfiguration.Index, {
		urlParams
	});
};

export const useGetUserConfiguration = (
	urlParams: UserPageConfigurationIndexRequest,
	enabled: boolean = true
) => {
	return useQuery<UserPageConfiguration | undefined, Error>(
		[`getUserConfiguration`, urlParams],
		() => fetchUserConfiguration(urlParams),
		{ enabled }
	);
};
