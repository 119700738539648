import { Settings } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { CprNodeType } from 'RtModels';
import { CprNodeTypeNameMap } from 'RtUi/app/rt800/Cprs/lib/Constants';
import { useState } from 'react';

const decisionTypes = [
	CprNodeType.AreaCode,
	CprNodeType.Lata,
	CprNodeType.NpaNxx,
	CprNodeType.SixDigit,
	CprNodeType.State,
	CprNodeType.TenDigit
];

interface ICprRowIndexDataGridColumnHeaderProps {
	onAddCprNode: (decisionTypes: CprNodeType) => void;
}

export const CprRowIndexDataGridColumnHeader = ({
	onAddCprNode
}: ICprRowIndexDataGridColumnHeaderProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton onClick={handleClick}>
				<Settings />
			</IconButton>
			<Menu
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				disableScrollLock
			>
				{decisionTypes.map((decisionType) => (
					<MenuItem
						key={decisionType}
						onClick={() => {
							onAddCprNode(decisionType);
						}}
					>
						<i className="fas fa-fw fa-plus-circle text-success" />
						&nbsp;&nbsp;{CprNodeTypeNameMap.get(decisionType)}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
