/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ResourceCreateRequest = {
    properties: {
        data: {
            type: 'AvoxiResourceCreateRequest | BandwidthResourceCreateRequest | BlacklistAllianceResourceCreateRequest | BlitzResourceCreateRequest | Call48ResourceCreateRequest | DidWwResourceCreateRequest | EndstreamResourceCreateRequest | FlowrouteResourceCreateRequest | FractelResourceCreateRequest | InteliquentResourceCreateRequest | InteliquentResourceCreateRequest | LayeredResourceCreateRequest | LingoResourceCreateRequest | LumenApiResourceCreateRequest | MyHealthAngelResourceCreateRequest | NusoResourceCreateRequest | PeerlessResourceCreateRequest | PlivoResourceCreateRequest | SkyetelResourceCreateRequest | TeleApiResourceCreateRequest | TelnyxResourceCreateRequest | ThinqResourceCreateRequest | ThreeEightTwoResourceCreateRequest | ThreeThreeResourceCreateRequest | TwilioResourceCreateRequest | V1VoipResourceCreateRequest | VerizonResourceCreateRequest | VitcomResourceCreateRequest | VitelityResourceCreateRequest | VoipInnovationsResourceCreateRequest | VoipMsResourceCreateRequest | VoxboneResourceCreateRequest | WestResourceCreateRequest',
            isRequired: true,
        },
    },
};