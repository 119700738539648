import { RateLoadNewRateIndexResponse } from 'RtModels';
import { useGetRateLoadNewRates } from 'RtUi/app/AccountManagement/RateManagement/libs/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getRateLoadNewRatesColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/rateLoads';
import { useMemo } from 'react';

type IRateLoadNewRatesGridProps = {
	rateLoadId: number;
	autoFocusFilter?: boolean;
};

export const RateLoadNewRatesDataGrid = ({
	rateLoadId
}: IRateLoadNewRatesGridProps) => {
	const { data, isFetching: isLoading } = useGetRateLoadNewRates({
		rateLoadId
	});
	const columns = useMemo(() => getRateLoadNewRatesColumns(), []);

	return (
		<DataGrid<RateLoadNewRateIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			rowThemeColor={(record) =>
				record.rateLoadErrorTypeId !== null ? RowThemeColor.DANGER : undefined
			}
			pageName={'accountManagement_rateLoadNewRates'}
		/>
	);
};
