import * as React from 'react';
import { MarginSummaryReportRouter } from 'RtUi/app/rtSip/MarginSummaryReport/MarginSummaryReport.router';
import {
	MarginSummaryReportForm,
	IMarginSummaryReportFormState
} from 'RtUi/app/rtSip/MarginSummaryReport/lib/forms/MarginSummaryReportForm';
import {
	SearchResultsContainer,
	ISearchResultsContainerState
} from 'RtUi/components/containers/SearchResultsContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { MarginSummaryReportDataGrid } from 'RtUi/app/rtSip/MarginSummaryReport/lib/grids/MarginSummaryReportDataGrid';
import { MarginSummaryIndexRequest, CallflowDirectionId } from 'RtModels';
import moment from 'moment';

interface IMarginSummaryReportIndexContainerState
	extends ISearchResultsContainerState {
	formData: IMarginSummaryReportFormState;
}

@MarginSummaryReportRouter.getBusinessReportIndexRtUiController()
export class MarginSummaryReportIndexContainer extends SearchResultsContainer<
	{},
	{},
	IMarginSummaryReportIndexContainerState
> {
	public now: moment.Moment = moment();

	public state: IMarginSummaryReportIndexContainerState = {
		activeTab: this.tabs.Search,
		resourceParams: {},
		formData: {
			direction: CallflowDirectionId.Inbound,
			startDate: moment().startOf('day'),
			endDate: moment().endOf('day')
		}
	};

	public Tabs = MarginSummaryReportRouter.getProfileTabs();

	public initParams = {};

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { formData } = this.state;

		const params: MarginSummaryIndexRequest = {
			startTs: formData.startDate!.format('YYYY-MM-DD HH:mm:ss') as any,
			endTs: formData.endDate!.format('YYYY-MM-DD HH:mm:ss') as any,
			directionId: formData.direction
		};

		if (formData.costAccounts) {
			params.costAccountIds = formData.costAccounts.map(
				(customer) => customer.accountId
			);
		}

		if (formData.costSubscriptions) {
			params.costSubscriptionIds = formData.costSubscriptions.map(
				(subscription) => subscription.subscriptionId
			);
		}

		if (formData.revenueSubscriptions) {
			params.revenueSubscriptionIds = formData.revenueSubscriptions.map(
				(subscription) => subscription.subscriptionId
			);
		}

		if (formData.revenueAccounts) {
			params.revenueAccountIds = formData.revenueAccounts.map(
				(customer) => customer.accountId
			);
		}

		if (formData.countries) {
			params.countryIsoIds = formData.countries.map(
				(country) => country.iso3166Alpha3
			);
		}

		if (formData.rateCenter) {
			params.rateCenterIds = formData.rateCenter.map(
				(rateCenter) => rateCenter.rateCenterId
			);
		}

		if (formData.columns) {
			params.columns = formData.columns.map((column) => column.value);
		}

		return params;
	}

	public onSubmit(
		e: React.FormEvent<HTMLFormElement>,
		formData: IMarginSummaryReportFormState
	) {
		e.preventDefault();
		this.setState({ formData }, () => {
			this.setActiveTab(this.tabs.Results);
		});
	}

	public render() {
		return (
			<TabbedLayout
				router={MarginSummaryReportRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<MarginSummaryReportForm
						initialState={this.state.formData}
						onSubmit={(e, formData) => this.onSubmit(e, formData)}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<MarginSummaryReportDataGrid
						resourceParams={this.state.resourceParams}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
