import { cloneDeep } from 'lodash-es';
import * as React from 'react';
import {
	BlockTypeIndexResponse,
	MarginPlanIndexResponse,
	RateCenterIndexResponse,
	SubscriptionIndexResponse,
	SubscriptionProfileResponse,
	SubscriptionQosMatch,
	SubscriptionQosProfileResponse
} from 'RtModels';
import {
	customerServiceTypes,
	vendorServiceTypes
} from 'RtUi/app/AccountManagement/Subscriptions/lib/constants';
import { MarginPlanSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/MarginPlanSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { SubscriptionQosResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionQosResource';
import { BlockTypeSelect } from 'RtUi/app/rtSip/BlockTypes/lib/controls/BlockTypeSelect';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { SliderFormControl } from 'RtUi/components/form/SliderFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IQosEditFormProps {
	resource: SubscriptionQosResource;
	rateCenterId: number;
	subscription: SubscriptionProfileResponse;
	onSuccess?: (profile: SubscriptionQosProfileResponse) => void;
	onCancel?: () => void;
}

interface IQosEditFormState {
	isSubmitting: boolean;
	qos: SubscriptionQosProfileResponse | null;
	subscription?: SubscriptionIndexResponse;
	rateCenter?: RateCenterIndexResponse;
	marginPlan?: MarginPlanIndexResponse;
	match: SubscriptionQosMatch;
	blockType?: BlockTypeIndexResponse;
	error?: any;
}

export class QosEditForm extends React.Component<
	IQosEditFormProps,
	IQosEditFormState
> {
	private initialState: IQosEditFormState;

	constructor(props: IQosEditFormProps) {
		super(props);

		this.initialState = {
			isSubmitting: false,
			match: SubscriptionQosMatch.AllRateCentersInCountry,
			qos: null
		};

		this.state = cloneDeep(this.initialState);
	}

	public async componentDidMount() {
		this.setUpInitialState();
	}

	public async componentDidUpdate(prevProps: Readonly<IQosEditFormProps>) {
		if (
			prevProps.subscription !== this.props.subscription ||
			prevProps.rateCenterId !== this.props.rateCenterId
		) {
			this.setUpInitialState();
		}
	}

	public render() {
		if (!this.state.qos) {
			return <Loading />;
		}

		const { qos } = this.state;

		return (
			<RtUiForm
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onSubmit={this.onSubmit}
				onCancel={() => {
					this.setState({ ...cloneDeep(this.initialState) }, () => {
						if (this.props.onCancel) {
							this.props.onCancel();
						}
					});
				}}
			>
				<h5>Rate Center</h5>
				<hr />
				<SubscriptionSelect
					required
					displayMode
					onChange={(subscription: SubscriptionIndexResponse) =>
						this.setState({ subscription })
					}
					value={this.state.subscription}
					initialOptionId={qos.subscriptionId}
				/>
				<RateCenterSelect
					required
					label="Rate Center"
					initialOptionId={String(qos.rateCenterId)}
					onChange={(rateCenter) => this.setState({ rateCenter })}
					value={this.state.rateCenter}
				/>
				<RadioFormControl<number>
					label="Apply Changes To"
					value={this.state.match}
					onChange={(match) => this.setState({ match })}
					required
					initialValue={SubscriptionQosMatch.AllRateCentersInCountry}
					options={[
						{
							value: SubscriptionQosMatch.CurrentRateCenterOnly,
							label: 'This rate center only'
						},
						{
							value: SubscriptionQosMatch.AllRateCentersOfSameTypeInCountry,
							label: 'All rate centers of the same type in this country'
						},
						{
							value: SubscriptionQosMatch.AllRateCentersInCountry,
							label: 'All rate centers in this country'
						},
						{
							value: SubscriptionQosMatch.AllRateCentersInAllCountries,
							label: 'All rate centers in all countries'
						}
					]}
				/>
				{this.shouldShowCustomerFields() && (
					<>
						<MarginPlanSelect
							onChange={(marginPlan) => this.setState({ marginPlan })}
							initialOptionId={String(qos.marginPlanId)}
							value={this.state.marginPlan}
						/>
					</>
				)}
				{this.shouldShowVendorFields() && (
					<>
						<BooleanRadioFormControl
							label="Is Routable "
							onChange={(isRoutable) => this.onChange('isRoutable', isRoutable)}
							value={qos.isRoutable}
						/>
						<BooleanRadioFormControl
							label="Is In Default LCR"
							onChange={(isLcrEnabled) =>
								this.onChange('isLcrEnabled', isLcrEnabled)
							}
							value={qos.isLcrEnabled}
						/>
						<BooleanRadioFormControl
							label="Include in Route Depth"
							onChange={(isRouteDepth) =>
								this.onChange('isRouteDepth', isRouteDepth)
							}
							value={qos.isRouteDepth}
						/>
					</>
				)}
				<BlockTypeSelect
					initialOptionId={String(qos.blockTypeId)}
					value={this.state.blockType}
					onChange={(blockType: BlockTypeIndexResponse) =>
						this.setState({ blockType })
					}
				/>
				<h5 className="pt-3">QoS Matching</h5>
				<hr />
				<SliderFormControl
					label="SQ1"
					onChange={(sq1) => this.onChange('sq1', sq1 / 100)}
					value={Math.round(qos.sq1 * 100)}
				/>
				<SliderFormControl
					label="SQ2"
					onChange={(sq2) => this.onChange('sq2', sq2 / 100)}
					value={Math.round(qos.sq2 * 100)}
				/>
				<SliderFormControl
					label="SQ3"
					onChange={(sq3) => this.onChange('sq3', sq3 / 100)}
					value={Math.round(qos.sq3 * 100)}
				/>
				<SliderFormControl
					label="SQ4"
					onChange={(sq4) => this.onChange('sq4', sq4 / 100)}
					value={Math.round(qos.sq4 * 100)}
				/>
				<SliderFormControl
					label="SQ5"
					onChange={(sq5) => this.onChange('sq5', sq5 / 100)}
					value={Math.round(qos.sq5 * 100)}
				/>
				<SliderFormControl
					label="SQ6"
					onChange={(sq6) => this.onChange('sq6', sq6 / 100)}
					value={Math.round(qos.sq6 * 100)}
				/>
				<SliderFormControl
					label="SQ7"
					onChange={(sq7) => this.onChange('sq7', sq7 / 100)}
					value={Math.round(qos.sq7 * 100)}
				/>
				<SliderFormControl
					label="SQ8"
					onChange={(sq8) => this.onChange('sq8', sq8 / 100)}
					value={Math.round(qos.sq8 * 100)}
				/>
				<SliderFormControl
					label="SQ9"
					onChange={(sq9) => this.onChange('sq9', sq9 / 100)}
					value={Math.round(qos.sq9 * 100)}
				/>
				<h5 className="pt-3">Feature Matching</h5>
				<hr />
				<BooleanRadioFormControl
					label="CLI"
					onChange={(cli) => this.onChange('cli', cli)}
					value={qos.cli}
				/>
				<BooleanRadioFormControl
					label="T38"
					onChange={(t38) => this.onChange('t38', t38)}
					value={qos.t38}
				/>
				<BooleanRadioFormControl
					label="Test"
					onChange={(test) => this.onChange('test', test)}
					value={qos.test}
				/>
			</RtUiForm>
		);
	}

	private onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const { qos, subscription, rateCenter, marginPlan } = this.state;
		const { onSuccess = () => ({}) } = this.props;

		if (!qos || !subscription || !rateCenter) {
			return;
		}

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const profile = await this.props.resource.updateProfile(
				this.props.subscription.subscriptionId,
				this.props.rateCenterId,
				{
					rateCenterId: rateCenter.rateCenterId,
					marginPlanId: marginPlan?.marginPlanId || qos.marginPlanId,
					sq1: qos.sq1,
					sq2: qos.sq2,
					sq3: qos.sq3,
					sq4: qos.sq4,
					sq5: qos.sq5,
					sq6: qos.sq6,
					sq7: qos.sq7,
					sq8: qos.sq8,
					sq9: qos.sq9,
					cli: qos.cli,
					test: qos.test,
					t38: qos.t38,
					filter: this.state.match,
					isRoutable: qos.isRoutable,
					isLcrEnabled: qos.isLcrEnabled,
					isRouteDepth: qos.isRouteDepth
				}
			);

			this.setState({ isSubmitting: false }, () => {
				onSuccess(profile);
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	};

	private onChange = <K extends keyof SubscriptionQosProfileResponse>(
		key: K,
		value: SubscriptionQosProfileResponse[K]
	) => {
		if (!this.state.isSubmitting && this.state.qos) {
			const { qos } = this.state;
			qos[key] = value;
			this.setState({ qos });
		}
	};

	private shouldShowVendorFields() {
		return (
			this.state.subscription &&
			vendorServiceTypes.includes(this.state.subscription.serviceId)
		);
	}

	private shouldShowCustomerFields() {
		return (
			this.state.subscription &&
			customerServiceTypes.includes(this.state.subscription.serviceId)
		);
	}

	private async setUpInitialState() {
		const qos = await this.props.resource.getProfile(
			this.props.subscription.subscriptionId,
			this.props.rateCenterId
		);

		this.initialState = {
			...this.initialState,
			subscription: this.props.subscription as any,
			qos
		};

		this.setState(cloneDeep(this.initialState));
	}
}
