import { Rt800ApiRoutes } from 'RtExports/routes';
import { LcrTemplateIndexResponse, LcrTemplateProfileResponse } from 'RtModels';
import { LcoApplicationRouter } from 'RtUi/app/rtLco/rtLco.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
interface ILcrTemplateContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Carriers: TabbedLayoutTabProps;
	Scenarios: TabbedLayoutTabProps;
}

export const LcrTemplateContainerTabs: ILcrTemplateContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Carriers: {
		header: 'Carriers'
	},
	Scenarios: {
		header: 'Scenarios'
	}
};

export class LcrTemplateRouterClass extends LcoApplicationRouter<
	LcrTemplateIndexResponse,
	LcrTemplateProfileResponse,
	ILcrTemplateContainerTabs
> {
	constructor() {
		super(
			'LCO Template',
			'lcrTemplates',
			'templateName',
			LcrTemplateContainerTabs
		);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.LcrTemplates);
	}

	/**
	 * Make sure record has templateName and lcrTaskId,
	 * so a template record will not show the incorrect context menu item
	 * @override
	 * @param record
	 */
	public recordHasAccessToProfile(record: any) {
		const hasBaseAccess = super.recordHasAccessToProfile(record);

		if (!hasBaseAccess) {
			return false;
		}

		//make sure templateName and lcrTaskId are within the record
		return 'lcrTaskId' in record;
	}

	public getProfileLabel(profile: LcrTemplateProfileResponse) {
		return profile.templateName;
	}
}

export const LcrTemplateRouter = new LcrTemplateRouterClass();
