import { RtCommonApiRoutes } from 'RtExports/routes';
import { SwitchTypeIndexResponse } from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class SwitchTypesResource extends ArrayResource<SwitchTypeIndexResponse> {
	constructor() {
		super('switchTypeId');

		this.setApiRouteForGetAll(RtCommonApiRoutes.SwitchTypes.Index);
	}
}
