/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionTagProfileResponse = {
    properties: {
        subscriptionTagId: {
            type: 'number',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        tag: {
            type: 'string',
            isRequired: true,
        },
        tagModeId: {
            type: 'CallTagMode',
            isRequired: true,
        },
        percentage: {
            type: 'number',
            isRequired: true,
        },
        priority: {
            type: 'number',
            isRequired: true,
        },
        weight: {
            type: 'number',
            isRequired: true,
        },
        minValue: {
            type: 'string',
            isRequired: true,
        },
        maxValue: {
            type: 'string',
            isRequired: true,
        },
        tagMode: {
            type: 'string',
            isRequired: true,
        },
        values: {
            type: 'Array',
            isRequired: true,
        },
    },
};