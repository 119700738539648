import { EventEmitter } from 'events';

export type TypedEventEmitterListener = (eventMessage: string) => void;
export type StandardEventEmitterTypes = 'info' | 'error' | 'debug';

export class TypedEventEmitter<
	EventTypes extends string = StandardEventEmitterTypes
> {
	private eventEmitter = new EventEmitter();

	public on(eventType: EventTypes, listener: TypedEventEmitterListener) {
		this.eventEmitter.on(eventType, listener);

		return () => this.removeListener(eventType, listener);
	}

	public removeListener(
		eventType: EventTypes,
		listener: TypedEventEmitterListener
	) {
		this.eventEmitter.removeListener(eventType, listener);
	}

	public removeAllListeners() {
		this.eventEmitter.removeAllListeners();
	}

	protected emit(eventType: EventTypes, eventMessage: string) {
		this.eventEmitter.emit(eventType, eventMessage);
	}
}
