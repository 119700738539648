import { TaskErrorIndexResponse } from 'RtModels';
import { useGetTaskErrors } from 'RtUi/app/rt800/Tasks/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getTaskErrorsColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/tasks';
import { useMemo } from 'react';

interface ITaskErrorsGridProps {
	taskId: number;
}

export const TaskErrorsGrid = ({ taskId }: ITaskErrorsGridProps) => {
	const { data } = useGetTaskErrors({ taskId });

	const columns = useMemo(() => getTaskErrorsColumns(), []);

	return (
		<DataGrid<TaskErrorIndexResponse>
			data={data?.data}
			totalRows={data?.totalCount}
			columns={columns}
			pageName={'rt800_taskErrors'}
		/>
	);
};
