import {
	GuardianAlertRecipient,
	GuardianAlertRecipientIndexResponse,
	GuardianAlertRecipientCreateRequest,
	GuardianAlertRecipientProfileRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtVueApiRoutes } from 'RtExports/routes';

export class AlertsRecipientsResource extends HttpResource<
	GuardianAlertRecipientIndexResponse,
	GuardianAlertRecipient
> {
	constructor() {
		super();

		this.setApiModule(RtVueApiRoutes.GuardianAlertsRecipients);
	}

	public create(request: GuardianAlertRecipientCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianAlertRecipient>(
			RtVueApiRoutes.GuardianAlertsRecipients.Create,
			{ body }
		);
	}

	public update(
		guardianAlertRecipientId: number,
		request: GuardianAlertRecipientCreateRequest
	) {
		const urlParams: GuardianAlertRecipientProfileRequest = {
			guardianAlertRecipientId
		};
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianAlertRecipient>(
			RtVueApiRoutes.GuardianAlertsRecipients.Update,
			{ body, urlParams }
		);
	}

	public delete(guardianAlertRecipientId: number) {
		const urlParams: GuardianAlertRecipientProfileRequest = {
			guardianAlertRecipientId
		};

		return this.fetchWithRoute(RtVueApiRoutes.GuardianAlertsRecipients.Delete, {
			urlParams
		});
	}

	public createNewProfile(guardianAlertId: number): GuardianAlertRecipient {
		return {
			guardianAlertRecipientId: -1,
			guardianAlertId: guardianAlertId,
			messageTypeIds: [],
			userId: null,
			emailAliasId: null
		};
	}
}
