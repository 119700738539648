/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CallDirectionPreset {
    Ingress = 'Ingress',
    Egress = 'Egress',
    Either = 'Either',
    Customer = 'Customer',
    Vendor = 'Vendor',
}