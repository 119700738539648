import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianAlertHistoryIndexRequest,
	GuardianAlertHistoryDashboardResponse,
	GuardianAlertHistoryIndexResponse,
	AlertType
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class AlertHistoryResource extends HttpResource<GuardianAlertHistoryIndexResponse> {
	public static readonly FraudAlertTypes = [
		AlertType.FraudDomestic,
		AlertType.FraudInternational
	];

	public static isFraudAlertType(alertType: AlertType) {
		return AlertHistoryResource.FraudAlertTypes.includes(alertType);
	}

	public static isFraudAlertHistory(record: GuardianAlertHistoryIndexResponse) {
		return (
			record?.failureData?.length &&
			AlertHistoryResource.isFraudAlertType(record.alertRuleId)
		);
	}

	constructor() {
		super(RtVueApiRoutes.GuardianAlertHistory);
	}

	public getDashboard(params: GuardianAlertHistoryIndexRequest = {}) {
		return this.fetchWithRoute<GuardianAlertHistoryDashboardResponse>(
			RtVueApiRoutes.GuardianAlertHistoryDashboard.Index,
			{
				params
			}
		);
	}
}
