/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianAlertCreateRequest = {
    properties: {
        alertTypeId: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        timeRange: {
            type: 'AlertTimeRange',
            isRequired: true,
        },
        resourceIds: {
            type: 'string',
            isNullable: true,
        },
        filters: {
            type: 'Array',
            isNullable: true,
        },
        rules: {
            type: 'Array',
            isRequired: true,
        },
        having: {
            type: 'Array',
        },
        dataColumns: {
            type: 'Array',
            isRequired: true,
        },
        guardianAlertActionIds: {
            type: 'GuardianAlertAction',
            isRequired: true,
            isNullable: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        runIntervalQty: {
            type: 'number',
            isRequired: true,
        },
        runIntervalType: {
            type: 'AlertInterval',
            isRequired: true,
        },
    },
};