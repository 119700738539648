import {
	GuardianAlertIndexResponse,
	GuardianAlertProfileResponse,
	GuardianAlertProfileRequest,
	GuardianAlertUpdateRequest,
	GuardianAlertCreateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtVueApiRoutes } from 'RtExports/routes';

export class AlertRulesResource extends HttpResource<
	GuardianAlertIndexResponse,
	GuardianAlertProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianAlerts);
	}

	public update(
		guardianAlertId: number,
		alertRuleUpdateRequest: GuardianAlertUpdateRequest
	) {
		const urlParams: GuardianAlertProfileRequest = { guardianAlertId };

		const body = JSON.stringify(alertRuleUpdateRequest);

		return this.fetchWithRoute<GuardianAlertProfileResponse>(
			RtVueApiRoutes.GuardianAlerts.Update,
			{
				body,
				urlParams
			}
		);
	}

	public create(alertRuleCreateRequest: GuardianAlertCreateRequest) {
		const body = JSON.stringify(alertRuleCreateRequest);

		return this.fetchWithRoute<GuardianAlertProfileResponse>(
			RtVueApiRoutes.GuardianAlerts.Create,
			{
				body
			}
		);
	}
}
