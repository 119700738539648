import { DataSources, RateCenterIndexResponse } from 'RtModels';
import Dexie from 'dexie';
import {
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { IndexedDbCacheStrategy } from 'RtUi/utils/cache/strategies/IndexedDbCacheStrategy';
import { V2LabelInsteadOfSummaryMigration } from 'RtUi/app/rtVue/common/lib/filters/migrations/RtVueRateCenterFilter/V2LabelInsteadOfSummary.migration';

/**
 * @singleton
 */
export class RtVueRateCenterFilter extends RtVueFilter<RateCenterIndexResponse> {
	public static readonly FilterName = 'RtVueRateCenterFilter';
	public static getInstance() {
		if (!RtVueRateCenterFilter.instance) {
			RtVueRateCenterFilter.instance = new RtVueRateCenterFilter();
		}

		return RtVueRateCenterFilter.instance;
	}

	private static instance: RtVueRateCenterFilter | undefined;
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<RateCenterIndexResponse>
	> = new IndexedDbCacheStrategy(
		RtVueRateCenterFilter.FilterName,
		'rateCenterId',
		['label', '*keywords' as keyof RateCenterIndexResponse],
		(indexedDb: Dexie, storeSchema: string[]) => {
			V2LabelInsteadOfSummaryMigration(indexedDb, storeSchema, this.FilterName);
		}
	);

	protected constructor() {
		super(
			RtVueRateCenterFilter.FilterName,
			DataSources.RateCenters,
			RtVueRateCenterFilter.DataCache
		);
	}

	public getLabelFor(record: RateCenterIndexResponse): string {
		return record.label;
	}

	public getValueFor(record: RateCenterIndexResponse): string {
		return String(record.rateCenterId);
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<RateCenterIndexResponse>
	> {
		return this.vueHttp.getRateCenterFilter();
	}

	protected getKeywordsFor(record: RateCenterIndexResponse): string[] {
		const keywords = this.getKeywordsFromPhrase(record.label);

		keywords.push(String(record.rateCenterId));

		return keywords;
	}
}
