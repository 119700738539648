import { getNumberFrom, parsePhoneNumber } from 'awesome-phonenumber';

export const nanpRegions = [
	'US',
	'CA',
	'AG',
	'AI',
	'AS',
	'BB',
	'BM',
	'BS',
	'DM',
	'DO',
	'GD',
	'GU',
	'JM',
	'KN',
	'KY',
	'LC',
	'MP',
	'MS',
	'PR',
	'SX',
	'TC',
	'TT',
	'VC',
	'VG',
	'VI'
];

export class PhoneNumberFormatter {
	public static convertLettersToNumbers(phoneNumberStr: string): string {
		return (
			phoneNumberStr
				.toLowerCase()
				.replace(/[abc]/g, '2')
				.replace(/[def]/g, '3')
				.replace(/[ghi]/g, '4')
				.replace(/[jkl]/g, '5')
				.replace(/[mno]/g, '6')
				//cSpell:disable-next-line
				.replace(/[pqrs]/g, '7')
				.replace(/[tuv]/g, '8')
				//cSpell:disable-next-line
				.replace(/[wxyz]/g, '9')
		);
	}

	public toPhoneNumber(phoneNumberStr: string) {
		if (!phoneNumberStr.startsWith('+')) {
			phoneNumberStr = `+${phoneNumberStr}`;
		}

		return parsePhoneNumber(phoneNumberStr);
	}

	public toE164(phoneNumberStr: string) {
		if (!phoneNumberStr.startsWith('+')) {
			phoneNumberStr = `+${phoneNumberStr}`;
		}

		const phoneNumber = parsePhoneNumber(phoneNumberStr);

		if (!phoneNumber.valid) {
			return;
		}

		return getNumberFrom(phoneNumber, 'e164').number;
	}

	public toInternational(phoneNumberStr: string): string | undefined {
		if (!phoneNumberStr.startsWith('+')) {
			phoneNumberStr = `+${phoneNumberStr}`;
		}

		const phoneNumber = this.toPhoneNumber(phoneNumberStr);

		if (!phoneNumber.valid) {
			return;
		}

		return getNumberFrom(phoneNumber, 'international').number;
	}

	public isValid(phoneNumberStr: string, addNanpCharacters = false): boolean {
		if (addNanpCharacters) {
			if (phoneNumberStr.length === 10) {
				phoneNumberStr = `+1${phoneNumberStr}`;
			}
		}

		const phoneNumber = this.toPhoneNumber(phoneNumberStr);

		return phoneNumber.valid;
	}
}
