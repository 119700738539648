import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { DemoProfileResponse, DemoIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IActivePortsPartitionRouterTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const ActivePortsPartitionContainerTabs: IActivePortsPartitionRouterTabs =
	{
		Profile: {
			header: 'Profile'
		}
	};

export class ActivePortsPartitionRouterClass extends SipApplicationRouter<
	DemoIndexResponse,
	DemoProfileResponse,
	IActivePortsPartitionRouterTabs
> {
	constructor() {
		super(
			'Active Ports Partition',
			'ActivePortsPartition',
			'value',
			ActivePortsPartitionContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.ActivePortsAll);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const ActivePortsPartitionRouter = new ActivePortsPartitionRouterClass();
