/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ServiceIndexResponse = {
    properties: {
        serviceId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        hasIngressConnections: {
            type: 'number',
            isRequired: true,
        },
        hasEgressConnections: {
            type: 'number',
            isRequired: true,
        },
        isCustomerService: {
            type: 'number',
            isRequired: true,
        },
        isVendorService: {
            type: 'number',
            isRequired: true,
        },
    },
};