import { RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { LcrCarrierIndexContainer } from 'RtUi/app/rtLco/Carriers/LcrCarrier.index';
import { LcrCarrierProfileContainer } from 'RtUi/app/rtLco/Carriers/LcrCarrier.profile';
import { LcrCarrierRouter } from 'RtUi/app/rtLco/Carriers/LcrCarrier.router';
import { LcoApplicationRouter } from 'RtUi/app/rtLco/rtLco.router';
import { LcrScenarioIndexContainer } from 'RtUi/app/rtLco/Scenarios/LcrScenario.index';
import { LcrScenarioProfileContainer } from 'RtUi/app/rtLco/Scenarios/LcrScenario.profile';
import { LcrScenarioRouter } from 'RtUi/app/rtLco/Scenarios/LcrScenario.router';
import { LcrTaskResultProfileContainer } from 'RtUi/app/rtLco/TaskResults/LcrTaskResult.profile';
import { LcrTaskResultRouter } from 'RtUi/app/rtLco/TaskResults/LcrTaskResult.router';
import { LcrTaskIndexContainer } from 'RtUi/app/rtLco/Tasks/LcrTask.index';
import { LcrTaskProfileContainer } from 'RtUi/app/rtLco/Tasks/LcrTask.profile';
import { LcrTaskRouter } from 'RtUi/app/rtLco/Tasks/LcrTask.router';
import { LcrTemplateIndexContainer } from 'RtUi/app/rtLco/Templates/LcrTemplate.index';
import { LcrTemplateProfileContainer } from 'RtUi/app/rtLco/Templates/LcrTemplate.profile';
import { LcrTemplateRouter } from 'RtUi/app/rtLco/Templates/LcrTemplate.router';
import { RateSheetIndexContainer } from 'RtUi/app/rtLco/RateSheets/RateSheet.index';
import { RateSheetProfileContainer } from 'RtUi/app/rtLco/RateSheets/RateSheet.profile';
import { MinutesIndexContainer } from 'RtUi/app/rtLco/Minutes/Minutes.index';
import { MinutesProfileContainer } from 'RtUi/app/rtLco/Minutes/Minutes.profile';
import { CarriersRatesRouter } from 'RtUi/app/rtLco/CarriersRates/CarriersRates.router';
import { CarriersRatesIndexContainer } from 'RtUi/app/rtLco/CarriersRates/CarriersRates.index';

@RtUiModule({
	route: {
		path: `/${LcoApplicationRouter.SectionKey}`,
		iconClass: 'fa-dollar-sign',
		name: 'RT/800 Least Cost Orig.',
		defaultOpen: false
	},
	controllers: [
		LcrCarrierIndexContainer,
		LcrCarrierProfileContainer,
		RateSheetIndexContainer,
		RateSheetProfileContainer,
		LcrTaskIndexContainer,
		LcrTaskProfileContainer,
		LcrTaskResultProfileContainer,
		LcrScenarioIndexContainer,
		LcrScenarioProfileContainer,
		LcrTemplateIndexContainer,
		LcrTemplateProfileContainer,
		MinutesIndexContainer,
		MinutesProfileContainer,
		CarriersRatesIndexContainer
	],
	routers: [
		LcrCarrierRouter,
		LcrScenarioRouter,
		LcrTaskResultRouter,
		LcrTaskRouter,
		LcrTemplateRouter,
		CarriersRatesRouter
	]
})
export class RtLcoModule {}
