/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ReconciliationType {
    VendorCdr = 1,
    SwitchCdr = 2,
    Charge = 3,
}