/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoutingRuleScheduleResponse = {
    properties: {
        tz: {
            type: 'string',
            isRequired: true,
        },
        frTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        toTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        frSec: {
            type: 'number',
            isRequired: true,
        },
        toSec: {
            type: 'number',
            isRequired: true,
        },
        isSun: {
            type: 'number',
            isRequired: true,
        },
        isMon: {
            type: 'number',
            isRequired: true,
        },
        isTue: {
            type: 'number',
            isRequired: true,
        },
        isWed: {
            type: 'number',
            isRequired: true,
        },
        isThu: {
            type: 'number',
            isRequired: true,
        },
        isFri: {
            type: 'number',
            isRequired: true,
        },
        isSat: {
            type: 'number',
            isRequired: true,
        },
    },
};