/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $EmailAliasUpdateRequest = {
    properties: {
        label: {
            type: 'string',
        },
        emailAddress: {
            type: 'string',
        },
    },
};