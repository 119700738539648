import {
	DataFilter,
	DataFilterOperator,
	DataSources,
	NumbersLiteIndexResponse,
	Reports,
	TimeFilterDetailKeys,
	TimeRangePresets
} from 'RtModels';
import { CdrSearchViewRouter } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.router';
import { ViewNumbersLiteRouter } from 'RtUi/app/rtVue/NumbersLite/ViewNumbersLite.router';
import { VueNumbersLiteDataGrid } from 'RtUi/app/rtVue/NumbersLite/lib/grids/VueNumbersLiteDataGrid';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { RtVueReportUrlSearchParamsCreate } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueReportUrlSearchParams';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import moment from 'moment';
import * as React from 'react';

@ViewNumbersLiteRouter.getIndexRtUiController({
	groupName: 'Business Reports',
	groupId: 'VueBusinessReports',
	orderPriority: 2,
	groupOrderPriority: 20
})
export class ViewNumbersLiteIndexContainer extends RtVueReportContainer<NumbersLiteIndexResponse> {
	public state: IRtVueContainerState<NumbersLiteIndexResponse> = {
		...this.getBaseState({
			defaultTimeRangePreset: TimeRangePresets.ThirtyDays,
			defaultDetailLevel: TimeFilterDetailKeys.Days,
			timeObject: {
				mode: TimeRangePresets.Custom,
				start: moment().subtract(1, 'days').startOf('day').toDate(),
				end: moment().subtract(1, 'days').endOf('day').toDate()
			}
		}),
		timeRangePresetsKeys: [
			TimeRangePresets.Week,
			TimeRangePresets.LastMonth,
			TimeRangePresets.Custom
		],
		timeRangeAvailableDetails: [
			TimeFilterDetailKeys.Days,
			TimeFilterDetailKeys.Months
		],
		dataSums: []
	};

	protected reportId = Reports.NumbersLite;
	protected router = ViewNumbersLiteRouter;

	protected async getResource(): Promise<
		HttpResource<NumbersLiteIndexResponse>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		} = this.state;

		const indexes = await new RtVueHttp().getNumbersLite(
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		);

		const resource = new ArrayResource('callDate', indexes.data);
		resource.setTotalCount(indexes.totalCount);

		return resource;
	}

	protected async getExportData(): Promise<NumbersLiteIndexResponse[]> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		} = this.state;

		const indexes = await new RtVueHttp().getNumbersLite(
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns,
			undefined,
			undefined,
			250_000
		);

		return indexes.data;
	}

	protected onDrillDown = (row: NumbersLiteIndexResponse, key: string) => {
		const drillDownParams: RtVueReportUrlSearchParamsCreate = {
			date: row.callDate
		};

		const operands = [
			{
				dataSource: DataSources.Text,
				value: row.number
			}
		];

		const filter: DataFilter = {
			key,
			operands,
			operator: DataFilterOperator.Begins
		};

		const filters: DataFilter[] = [filter];

		const drillDownPath = CdrSearchViewRouter.getDrillDownPath(
			drillDownParams,
			filters,
			this.getTimeRange()
		);

		ApplicationContainer.openNewTab(drillDownPath);
	};

	protected renderReports(): React.ReactNode {
		return (
			<>
				<VueNumbersLiteDataGrid
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
					callDateFormat={this.getCallDateFormat()}
					onDrillDown={this.onDrillDown}
					isLoading={this.state.loadingReport}
					getExportData={() => this.getExportData()}
				/>
			</>
		);
	}
}
