/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianServiceNumberSubscriptionBulkUpdateResponse,
	GuardianServiceNumberSubscriptionIndexRequest,
	GuardianServiceNumberSubscriptionIndexRequestBulk,
	GuardianServiceNumberSubscriptionIndexResponse,
	GuardianServiceNumberSubscriptionUpdateRequestBulk,
	GuardianServiceNumberSubscriptionWithGlobalData
} from 'RtModels';
import { convertDateToUTC } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import {
	FullResponse,
	handleGetRequest,
	handlePatchRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

export const fetchServiceNumberSubscription = async (
	queryParams?: GuardianServiceNumberSubscriptionIndexRequest
): Promise<FullResponse<GuardianServiceNumberSubscriptionWithGlobalData[]>> => {
	return handleGetRequest(
		RtVueApiRoutes.GuardianServiceNumberSubscriptions.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetServiceNumberSubscription = (
	params?: GuardianServiceNumberSubscriptionIndexRequest
) => {
	const cacheKey = JSON.stringify(params);

	return useQuery<
		FullResponse<GuardianServiceNumberSubscriptionWithGlobalData[]>,
		Error
	>(
		[`useGetServiceNumberSubscription${cacheKey}`, cacheKey],
		() => fetchServiceNumberSubscription(params),
		{ enabled: true }
	);
};

export const fetchGuardianServiceNumberSubscriptions = async (
	queryParams?: GuardianServiceNumberSubscriptionIndexRequestBulk
): Promise<FullResponse<GuardianServiceNumberSubscriptionIndexResponse[]>> => {
	return handleGetRequest<
		GuardianServiceNumberSubscriptionIndexResponse[],
		true
	>(RtVueApiRoutes.GuardianServiceNumberSubscriptionsBulk.Index, {
		includeFullResponse: true,
		queryParams
	});
};

export const useGetGuardianServiceNumberSubscriptions = (
	queryParams?: GuardianServiceNumberSubscriptionIndexRequestBulk
) => {
	return useQuery<
		FullResponse<GuardianServiceNumberSubscriptionIndexResponse[]>,
		Error
	>([`useGetGuardianServiceNumberSubscriptions`], () =>
		fetchGuardianServiceNumberSubscriptions(queryParams)
	);
};

export const bulkUpdateServiceNumberSubscription = (
	request: GuardianServiceNumberSubscriptionUpdateRequestBulk
) => {
	if (request.decommissionedTs) {
		request.decommissionedTs = convertDateToUTC(request.decommissionedTs);
	}
	if (request.endTs) {
		request.endTs = convertDateToUTC(request.endTs);
	}

	return handlePatchRequest<GuardianServiceNumberSubscriptionBulkUpdateResponse>(
		RtVueApiRoutes.GuardianServiceNumberSubscriptionsBulk.Update,
		request
	);
};
