/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TFActionRequest = {
    properties: {
        jobName: {
            type: 'string',
            isNullable: true,
        },
        resourceConfigurationId: {
            type: 'Array',
        },
        resourceId: {
            type: 'Array',
        },
        tfns: {
            type: 'Array',
            isRequired: true,
        },
        priority: {
            type: 'number',
        },
        scheduledAt: {
            type: 'Date',
            format: 'date-time',
        },
    },
};