import {
	ServiceNumberIndexRequest,
	ServiceNumberIndexResponse
} from 'RtModels';
import {
	fetchGlobalNumbers,
	useGetGlobalNumbers
} from 'RtUi/app/rtVue/GlobalNumbers/lib/service';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getGlobalNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rtCommon/globalNumbers';
import { useMemo } from 'react';

type ServiceNumberGridProps = {
	router?: RtUiRouter<ServiceNumberIndexResponse>;
	resourceParams?: ServiceNumberIndexRequest;
	pageName: string;
};

export const ServiceNumberGrid = ({
	resourceParams,
	router,
	pageName
}: ServiceNumberGridProps) => {
	const { data, isLoading } = useGetGlobalNumbers(resourceParams);

	const columns = useMemo(() => getGlobalNumbersColumns(), []);
	const totalRows = Number(data?.totalCount);

	const getExportData = async () => {
		const result = await fetchGlobalNumbers({
			...resourceParams,
			pageSize: totalRows
		});
		return result.data;
	};

	return (
		<DataGrid<ServiceNumberIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={pageName}
			router={router}
			loading={isLoading}
			totalRows={totalRows}
			enableExternalExport={totalRows > Number(data?.data.length)}
			getExternalExportData={getExportData}
		/>
	);
};
