import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { TemplateResource } from '../resources/TemplateResource';
import { TemplateIndexRequest, TemplateIndexResponse } from 'RtModels';
import { TemplateRouter } from 'RtUi/app/rt800/Templates/Template.router';

interface ITemplateSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<TemplateIndexResponse, isMulti> {
	respOrgId?: string;
	entityId?: string;
	isActive?: boolean;
	isRoutingTemplate?: boolean;
	isInUse?: boolean;
}

export class TemplateSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	TemplateIndexResponse,
	isMulti,
	ITemplateSelectProps<isMulti>,
	ISelectFormControlState<TemplateIndexResponse>
> {
	public static defaultProps = {
		router: TemplateRouter
	};
	public resourceClass = TemplateResource;
	public state: ISelectFormControlState<TemplateIndexResponse> = {
		formLabel: 'Template Name',
		valueKey: 'templateName',
		labelKey: 'templateName'
	};

	public componentDidMount() {
		super.componentDidMount();
		this.checkAndSetGetAllParams();
	}

	public componentDidUpdate(prevProps: ITemplateSelectProps<isMulti>) {
		// this.checkAndSetGetAllParams();

		super.componentDidUpdate(prevProps);
	}

	public checkAndSetGetAllParams() {
		const getAllParams: TemplateIndexRequest = {};
		const {
			isActive = true,
			entityId,
			respOrgId,
			isRoutingTemplate,
			isInUse
		} = this.props;

		if (isActive) {
			getAllParams.isActive = 1;
		} else if (isActive === false) {
			getAllParams.isActive = 0;
		}

		if (typeof isInUse === 'boolean') {
			getAllParams.isInUse = isInUse ? 1 : 0;
		}

		if (entityId) {
			getAllParams.entityId = entityId;
		}

		if (respOrgId) {
			getAllParams.respOrgId = respOrgId;
		}

		if (isRoutingTemplate) {
			getAllParams.isRoutingTemplate = 1;
		}

		this.setGetAllParams(getAllParams);
	}
}
