import {
	InvoiceSubscriptionProfileResponse,
	InvoiceSubscriptionProfileUrlRequest
} from 'RtModels';
import { useGetInvoiceSubscriptionDetails } from 'RtUi/app/rtSip/Invoices/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getInvoicesSubscriptionDetailsColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/invoices';
import { useMemo } from 'react';

interface IInvoiceSubscriptionDetailGridProps {
	resourceParams?: InvoiceSubscriptionProfileUrlRequest;
}

export const InvoiceSubscriptionDetailGrid = ({
	resourceParams
}: IInvoiceSubscriptionDetailGridProps) => {
	const { data, isFetching: isLoading } =
		useGetInvoiceSubscriptionDetails(resourceParams);
	const columns = useMemo(() => getInvoicesSubscriptionDetailsColumns(), []);

	return (
		<DataGrid<InvoiceSubscriptionProfileResponse>
			loading={isLoading}
			data={data?.data}
			totalRows={data?.totalCount}
			pageName="rtSip_invoicesSubscriptionDetails"
			columns={columns}
		/>
	);
};
