import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { IntegrationsApiRoutes } from 'RtExports/routes';
import {
	ResourceIndexResponse,
	ResourceProfileResponse,
	ResourceCreateRequest,
	ResourceUpdateRequest,
	ResourceProfileRequest
} from 'RtModels';

export class ResourceResource extends HttpResource<
	ResourceIndexResponse,
	ResourceProfileResponse
> {
	constructor() {
		super(IntegrationsApiRoutes.ResourcesNew);
	}

	/**
	 * Create Resource
	 * @param createReq
	 */
	public create(createReq: ResourceCreateRequest) {
		const body = JSON.stringify(createReq);

		return this.fetchWithRoute<ResourceProfileResponse>(
			IntegrationsApiRoutes.ResourcesNew.Create,
			{ body }
		);
	}

	/**
	 * Update resource
	 * @param resourceId
	 * @param updateReq
	 */
	public update(resourceId: number, updateReq: ResourceUpdateRequest) {
		const body = JSON.stringify(updateReq);
		const urlParams: ResourceProfileRequest = { resourceId };

		return this.fetchWithRoute<ResourceProfileResponse>(
			IntegrationsApiRoutes.ResourcesNew.Update,
			{ body, urlParams }
		);
	}

	public clone(resourceId: number) {
		const urlParams: ResourceProfileRequest = { resourceId };

		return this.fetchWithRoute<ResourceProfileResponse>(
			IntegrationsApiRoutes.ResourcesNew.Create,
			{ urlParams }
		);
	}
}
