import { GuardianAlertHistoryFailureData } from 'RtModels';
import DataGrid from 'RtUi/components/data/DataGrid';
import {
	getAlertHistoryFailureDataColumns,
	alertHistoryFailureDataMenuLinks
} from 'RtUi/components/data/DataGrid/configurations/rtGuardian/alertHistory';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

interface IAlertHistoryFailureDataGridProps {
	data: GuardianAlertHistoryFailureData[];
	totalCount?: number;
}

export const AlertHistoryFailureDataGrid = ({
	data,
	totalCount
}: IAlertHistoryFailureDataGridProps): JSX.Element => {
	const navigate = useNavigate();
	const columns = useMemo(() => getAlertHistoryFailureDataColumns(), []);

	return (
		<DataGrid<GuardianAlertHistoryFailureData>
			data={data}
			pageName="rtGuardian-alertHistoryProfile"
			menuLinks={(row) => alertHistoryFailureDataMenuLinks(row, navigate)}
			columns={columns}
			totalRows={totalCount}
		/>
	);
};
