import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { SipCallSimulatorResponseDto } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface ICallSimulatorRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const CallSimulatorContainerTabs: ICallSimulatorRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

class CallSimulatorRouterClass extends SipApplicationRouter<
	SipCallSimulatorResponseDto,
	SipCallSimulatorResponseDto,
	ICallSimulatorRouterTabs
> {
	constructor() {
		super(
			'Call Simulator',
			'CallSimulator',
			'rate',
			CallSimulatorContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.SipCallSimulator);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const CallSimulatorRouter = new CallSimulatorRouterClass();
