/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExport = {
    properties: {
        cdrExportId: {
            type: 'number',
            isRequired: true,
        },
        statusId: {
            type: 'CdrExportStatusId',
            isRequired: true,
        },
        cdrExportControlId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        cdrExportIntervalId: {
            type: 'CdrExportIntervals',
            isRequired: true,
        },
        cdrExportTypeId: {
            type: 'number',
            isRequired: true,
        },
        serviceId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        cdrSourceTypeId: {
            type: 'CdrExportSourceTypes',
            isRequired: true,
            isNullable: true,
        },
        cdrSourceValue: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        referenceId: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isConnected: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        zipFormat: {
            type: 'ZipFormat',
            isRequired: true,
        },
        fileLocationId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        region: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        bucket: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        fileName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        qty: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        begTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        startedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        completedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};