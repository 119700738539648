import { BlockTypeIndexResponse } from 'RtModels';
import { BlockTypeResource } from 'RtUi/app/rtSip/BlockTypes/lib/resources/BlockTypeResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface BlockTypeDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		BlockTypeIndexResponse,
		T
	> {}

export const BlockTypeDataGridCache =
	new ResourceCacheMap<BlockTypeIndexResponse>(
		new BlockTypeResource(),
		'blockTypeId',
		'label'
	);

export const BlockTypeDataGridColumn = <T = any,>(
	config: BlockTypeDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: BlockTypeDataGridCache,
		...config
	});
};
