/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianFraudSimulatorProfileRequest = {
    properties: {
        fileStreamId: {
            type: 'number',
            isRequired: true,
        },
        startTime: {
            type: 'string',
        },
        callType: {
            type: 'string',
        },
        duration: {
            type: 'number',
        },
        ingressTrunkgroupId: {
            type: 'string',
        },
        egressTrunkgroupId: {
            type: 'string',
        },
        ani: {
            type: 'string',
        },
        dialedNumber: {
            type: 'string',
        },
    },
};