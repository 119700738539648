import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';
import { OriginationNumberCreateRequest } from 'RtModels';
import { SpreadsheetParserEmitValidation } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserEmitValidation';

export class ServiceNumberSpreadsheetParser extends SpreadsheetParserEmitValidation {
	public labelColumn = new SpreadsheetParserColumn('label', ['Label']);
	public serviceNumberColumn = new SpreadsheetParserColumn('serviceNumber', [
		'Service Number'
	])
		.setIsRequired(true)
		.setIsPhoneNumber();
	public translatedNumberColumn = new SpreadsheetParserColumn(
		'translatedNumber',
		['Translated Number']
	)
		.setIsRequired(false)
		.setIsPhoneNumber();

	constructor(spreadsheet: string[][]) {
		super(spreadsheet);

		this.addParserColumn(
			this.labelColumn,
			this.serviceNumberColumn,
			this.translatedNumberColumn
		);
	}

	public parseServiceNumberRecords(): OriginationNumberCreateRequest[] {
		const serviceNumbers: OriginationNumberCreateRequest[] = [];
		this.unknownFieldsMap = new Map<string, number>();

		const possibleHeaderMatches = this.findPossibleHeaderMatches();
		const possibleHeaderMatch = possibleHeaderMatches[0];

		const rows = this.parse(possibleHeaderMatch);

		const labelColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.labelColumn
		);
		const serviceNumberColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.serviceNumberColumn
		);
		const translatedNumberColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.translatedNumberColumn
		);

		for (const [i, row] of rows.entries()) {
			const rowIndex = i + possibleHeaderMatch.headerRowIndex + 1;

			const label = row[labelColumnIndex];
			const serviceNumber = this.emitValidationCheck(
				'serviceNumber',
				row[serviceNumberColumnIndex],
				rowIndex
			);
			const translatedNumber = this.emitValidationCheck(
				'translatedNumber',
				row[translatedNumberColumnIndex],
				rowIndex
			);

			const record: OriginationNumberCreateRequest = {
				label,
				serviceNumber,
				translatedNumber: translatedNumber || null,
				subscriptionId: null,
				scenarioId: null
			};

			serviceNumbers.push(record);
		}

		this.emitErrorMessage();

		return serviceNumbers;
	}
}
