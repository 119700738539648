import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { LcrCarriersResource } from '../resources/LcrCarriersResource';
import { NanpRegionIndexResponse } from 'RtModels';

interface INanpRegionSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<NanpRegionIndexResponse, isMulti> {}

export class NanpRegionSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	NanpRegionIndexResponse,
	isMulti,
	INanpRegionSelectProps<isMulti>
> {
	public resourceClass = undefined;
	public state: ISelectFormControlState<NanpRegionIndexResponse> = {
		formLabel: 'Region',
		valueKey: 'nanpRegionId',
		labelKey: 'label'
	};

	public componentDidMount() {
		const lcrCarriersResource = new LcrCarriersResource();
		this.resource = lcrCarriersResource.getRegionsResource();

		super.componentDidMount();
	}
}
