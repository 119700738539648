import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Link, TextField } from '@mui/material';
import {
	AreaOfServiceLabelsMap,
	LabelsToAreaOfServiceMap
} from 'RtUi/app/rt800/Cprs/lib/resources/AreaOfServiceValuesResource';
import { RtxUiChip } from 'RtUi/components/rtx/ui/Chip/RtxUiChip';
import {
	AosNetwork,
	CprCol,
	CprLbl,
	CprValidator,
	CprValue,
	CprValueHighlightType
} from 'Somos/lib/SomosCpr/RtCprV2';
import { AosColNetwork } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosColNetwork';
import clsx from 'clsx';
import { noop, take, takeRight } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

const valuesToLabels = (
	validator: CprValidator,
	values: string[] | undefined = []
) => {
	let labels = [...values];

	if (validator instanceof AosColNetwork) {
		labels = labels.map(
			(val) => AreaOfServiceLabelsMap.get(val as AosNetwork) ?? val
		);
	}

	return labels;
};

const labelsToValues = (validator: CprValidator, labels: string[]) => {
	let values = [...labels];

	if (validator instanceof AosColNetwork) {
		values = values.map((val) => LabelsToAreaOfServiceMap.get(val) ?? val);
	}

	return values;
};

interface ICprColDataGridColumnInputProps {
	validator: CprCol | CprLbl;
	shownLimit?: number;
	onUpdate?: () => void;
}

export const CprValuesInput = ({
	validator,
	shownLimit,
	onUpdate = noop
}: ICprColDataGridColumnInputProps) => {
	const [values, setValues] = useState<string[]>(validator.getRawValues());
	const [inputValue, setInputValue] = useState<string>('');
	const [showAll, setShowAll] = useState<boolean>(false);

	useEffect(() => {
		return validator.cpr.onValidate(() => {
			setValues(validator.getRawValues());
		});
	});

	const suggestions = useMemo(() => {
		const possibles = validator.getPossibles(inputValue, true, 8);
		const possibleLabels = valuesToLabels(validator, possibles);

		if (validator.allowOther) {
			possibleLabels.push('OTHER');
		}

		return possibleLabels;
	}, [validator, inputValue]);

	const getHighlightStyle = (node: CprValue | CprLbl) => {
		if (node instanceof CprLbl && !node.existsOnCpr()) {
			return 'invalid';
		}

		if (node.hasErrors()) {
			return 'invalid';
		}

		if (node.isHighlighted()) {
			switch (node.getHighlightedTypeId()) {
				case CprValueHighlightType.Child:
					return 'child-match';
				case CprValueHighlightType.Parent:
					return 'parent-match';
				case CprValueHighlightType.Exact:
					return 'exact-match';
			}
		}
	};

	const getLabelStyle = (value: string) => {
		if (value.startsWith('*') && validator instanceof CprCol) {
			const label = validator.getCprLabel();

			if (label) {
				return getHighlightStyle(label);
			}
		}

		const cprValue = validator
			.getValues()
			.find((cprValue) => value === cprValue.getValue());

		if (cprValue) {
			return getHighlightStyle(cprValue);
		}
	};

	const onChange = (newValue: string[]) => {
		setValues(newValue);

		validator.cpr.validate();
		onUpdate();
	};

	return (
		<Autocomplete
			disableClearable
			className="flex-grow-1 Cpr-Input"
			options={suggestions}
			freeSolo
			value={values}
			multiple
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderTags={(value, props) => {
				const shouldHideValues =
					shownLimit !== undefined && value.length > shownLimit;
				const displayedValues =
					shouldHideValues && !showAll ? takeRight(value, shownLimit) : value;

				return (
					<>
						{shouldHideValues && (
							<>
								{displayedValues.length !== value.length && (
									<Link
										component="button"
										variant="body2"
										className="Cpr-Value-Display-Link"
										onClick={() => {
											setShowAll(true);
										}}
									>
										<strong>
											Click to show all ({value.length - displayedValues.length}{' '}
											more)
										</strong>
									</Link>
								)}
								{showAll && (
									<Link
										component="button"
										className="Cpr-Value-Display-Link"
										variant="body2"
										onClick={() => {
											setShowAll(false);
										}}
									>
										<strong>Show less</strong>
									</Link>
								)}
							</>
						)}
						{displayedValues.map((option, index) => (
							<RtxUiChip
								label={option}
								{...props({ index })}
								key={index}
								deleteIcon={<ClearIcon />}
								className={clsx('Cpr-Value', getLabelStyle(option))}
							/>
						))}
					</>
				);
			}}
			onChange={(event: any, newValue: string[]) => {
				const firstValue: string | undefined = newValue[0];
				if (firstValue?.startsWith('*') && validator instanceof CprCol) {
					const selectedLabel = validator.cpr.getCprLabel(firstValue);

					if (selectedLabel) {
						validator.setCprLabel(selectedLabel, false);
						onChange(newValue);
					}

					return;
				}
				const limit = validator.valueLimit ?? 255;
				const limitedValues = take(newValue, limit);
				const newValues = labelsToValues(validator, limitedValues);

				onChange(newValues);
				validator.setValues(newValues);
			}}
			renderInput={(params) => <TextField sx={{ minSize: 10 }} {...params} />}
		/>
	);
};
