import { RtxSipApiRoutes } from 'RtExports/routes';
import { AccountIndexResponse, AccountProfileResponse } from 'RtModels';
import { AccountManagementRouter } from 'RtUi/app/AccountManagement/AccountManagement.router';
import { SearchConfidenceLevel } from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { AccountResource } from './lib/resources/AccountResource';

interface IAccountContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Subscriptions: TabbedLayoutTabProps;
	Connections: TabbedLayoutTabProps;
	PortLimits: TabbedLayoutTabProps;
	Invoices: TabbedLayoutTabProps;
	Contacts: TabbedLayoutTabProps;
	CdrExports: TabbedLayoutTabProps;
	CreditLimits: TabbedLayoutTabProps;
}

export const AccountContainerTabs: IAccountContainerTabs = {
	Profile: { header: 'Profile' },
	Subscriptions: { header: 'Subscriptions' },
	Connections: { header: 'Connections' },
	PortLimits: { header: 'Port Limits' },
	Invoices: { header: 'Invoices' },
	Contacts: { header: 'Contacts' },
	CdrExports: { header: 'CDR Exports' },
	CreditLimits: { header: 'Credit Limits' }
};

class AccountRouterClass extends AccountManagementRouter<
	AccountIndexResponse,
	AccountProfileResponse,
	IAccountContainerTabs
> {
	constructor() {
		super('Account', 'accounts', 'accountId', AccountContainerTabs);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.Accounts);
	}

	public globalSearchConfidenceCheck() {
		return SearchConfidenceLevel.Possible;
	}

	public globalSearch(search: string) {
		return this.defaultGlobalSearch(AccountResource, search);
	}

	public getProfileLabel(profile: AccountProfileResponse) {
		return profile.label;
	}

	public getIndexLabel(record: AccountIndexResponse): string {
		return record.label;
	}
}

export const AccountRouter = new AccountRouterClass();
