import { ResourceType } from 'RtModels';
import { CarrierConnectResourceConfigurationRouter } from 'RtUi/app/rtCarrierConnect/ResourceConfigurations/CarrierConnectResourceConfigurations.router';
import { ResourceConfigurationProfileContainer } from 'RtUi/app/rtCommon/ResourceConfigurations/lib/components/ResourceConfigurationsProfileContainer';

export const CarrierConnectResourceConfigurationProfileContainer = () => {
	return (
		<ResourceConfigurationProfileContainer
			excludeResourceTypes={[ResourceType.DidVendor]}
			router={CarrierConnectResourceConfigurationRouter}
		/>
	);
};

CarrierConnectResourceConfigurationRouter.setProfileRtUiFunctionalComponent(
	CarrierConnectResourceConfigurationProfileContainer
);
