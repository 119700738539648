import { clone } from 'lodash-es';
import * as React from 'react';
import ReactInputMask from 'react-input-mask';

export interface ITollNumberInputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	bsSize?: 'lg' | 'sm';
	allowWildcards?: boolean;
	onlyNumbers?: boolean;
	inputRef?: React.Ref<HTMLInputElement>;
	alwaysShowMask?: boolean;
}

const defaultPattern =
	'([A-Za-z0-9]{3}-[A-Za-z0-9]{3}-[A-Za-z0-9]{4}|___-___-____)';
const numberPattern = '([0-9]{3}-[0-9]{3}-[0-9]{4}|___-___-____)';
const wildcardPattern = '[A-Za-z0-9\\*]{3}-[A-Za-z0-9\\*]{3}-[A-Za-z0-9\\*]{4}';

export class TollNumberInput extends React.Component<
	ITollNumberInputProps,
	{}
> {
	public inputElem: HTMLInputElement | null = null;
	public validationMessage = '';

	public componentDidMount() {
		this.updateValidationMessage(this.props.allowWildcards);
	}

	public componentDidUpdate() {
		this.updateValidationMessage();
	}

	public updateValidationMessage(allowWildcards = this.props.allowWildcards) {
		this.validationMessage = 'Format required: 999-999-9999';
		if (allowWildcards) {
			this.validationMessage += '; the * character is allowed as a wildcard';
		}

		if (this.inputElem) {
			const currentValue = String(this.inputElem.value).replace(/[\_|\-]/g, '');

			if (this.inputElem.validity.patternMismatch) {
				this.inputElem.setCustomValidity(this.validationMessage);
			} else if (this.props.required && currentValue.length <= 0) {
				this.inputElem.setCustomValidity('Please fill out this field');
			} else {
				this.inputElem.setCustomValidity('');
			}
		}
	}

	public listenForInput() {
		if (this.inputElem) {
			this.inputElem.addEventListener('input onchange onpaste', (e) =>
				this.updateValidationMessage()
			);
		}
	}

	public setInputRef(inputElem: HTMLInputElement | null) {
		this.inputElem = inputElem;
		this.listenForInput();

		if (typeof this.props.inputRef === 'function') {
			this.props.inputRef(inputElem);
		}
	}

	public render() {
		const props: ITollNumberInputProps = clone(this.props);
		const maskChar = props.allowWildcards ? '*' : '_';
		props.className = props.className || '';
		props.className = ' text-monospace form-control form-control-tollfree';
		props.pattern = defaultPattern;

		if (props.onlyNumbers) {
			props.pattern = numberPattern;
		} else if (props.allowWildcards) {
			props.pattern = wildcardPattern;
		}

		if (props.bsSize) {
			props.className += ` form-control-${props.bsSize}`;
		}

		delete props.bsSize;
		delete props.allowWildcards;
		props.inputRef = (ref) => this.setInputRef(ref);

		return (
			<ReactInputMask
				mask="***-***-****"
				maskPlaceholder={maskChar}
				placeholder="***-***-****"
				{...props}
			/>
		);
	}
}
