import { Chip, ChipProps } from '@mui/material';

type RtxUiChipProps = ChipProps;

export const RtxUiChip = ({ children, sx, ...props }: RtxUiChipProps) => {
	return (
		<Chip
			sx={{
				...sx,
				'padding': '2px',
				'border': '1px solid #e9ecef',
				'borderRadius': '5px',
				'marginRight': '5px',
				'marginBottom': '2px',
				'backgroundColor': 'white',
				'fontFamily': `"Roboto', "Helvetica", 'Arial", sans-serif`,
				'height': '25px',
				'fontSize': '12px',
				'.MuiChip-label': {
					padding: '0 5px'
				},
				'.MuiChip-deleteIcon': {
					fontSize: '12px',
					margin: '0 5px 0 0'
				},
				'&.exact-match': {
					backgroundColor: '#ffc107',
					color: '#FFF'
				},
				'&.parent-match': {
					backgroundColor: '#17a2b8',
					color: '#FFF'
				},
				'&.invalid': {
					backgroundColor: '#dc3545',
					color: '#FFF'
				}
			}}
			{...props}
		>
			{children}
		</Chip>
	);
};
