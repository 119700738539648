import * as React from 'react';
import { useAsyncFn } from 'react-use';
import { Alert, Table } from 'react-bootstrap';
import { RateLoadProfileResponse, RateLoadStep } from 'RtModels';
import { RateLoadResource } from 'RtUi/app/AccountManagement/RateManagement/libs/resources/RateLoadResource';

interface IRateLoadApprovalSummaryProps {
	profile: RateLoadProfileResponse;
}

export const RateLoadApprovalSummary: React.FC<
	React.PropsWithChildren<IRateLoadApprovalSummaryProps>
> = ({ profile }) => {
	const [rateLoadSummaryState, fetchRateLoadSummary] = useAsyncFn(() => {
		const rateLoadResource = new RateLoadResource();
		return rateLoadResource.getAnalysisSummary({
			rateLoadId: profile.rateLoadId
		});
	});

	React.useEffect(() => {
		const stepOnAnalysisOrHigherStep =
			profile.rateLoadStepId >= RateLoadStep.RateAndCodeAnalysis;

		const hasFetchedSummary =
			rateLoadSummaryState.loading ||
			rateLoadSummaryState.value ||
			rateLoadSummaryState.error;

		if (stepOnAnalysisOrHigherStep && !hasFetchedSummary) {
			fetchRateLoadSummary();
		}
	}, [profile.rateLoadStepId, fetchRateLoadSummary, rateLoadSummaryState]);

	const rateLoadSummary = rateLoadSummaryState.value;

	if (!rateLoadSummary) {
		return null;
	}

	const numberToLocaleString = (
		num: Number | null | undefined,
		defaultVal = '0'
	) => {
		if (num === null || typeof num === 'undefined') {
			return defaultVal;
		}

		return num.toLocaleString();
	};

	const calculateUnchangedAndToLocaleString = (
		increases: number,
		decreases: number
	) => {
		const { addQty, totalQty } = rateLoadSummary;
		const unchangedValue = totalQty - (increases + decreases + addQty);

		return numberToLocaleString(unchangedValue);
	};

	return (
		<section>
			<header className="d-flex justify-content-between mb-3">
				<span>{numberToLocaleString(rateLoadSummary.addQty)} Rates Added</span>
				<span>·</span>
				<span>
					{numberToLocaleString(rateLoadSummary.delQty)} Rates Deleted
				</span>
				<span>·</span>
				<span>{numberToLocaleString(rateLoadSummary.totalQty)} Total</span>
			</header>
			<hr />
			<section>
				<Table borderless>
					<thead>
						<tr className="text-muted">
							<th></th>
							<th className="text-end">
								Increases <i className="fas fa-fw fa-arrow-up" />
							</th>
							<th className="text-end">
								Decreases <i className="fas fa-fw fa-arrow-down" />
							</th>
							<th className="text-end">Unchanged</th>
						</tr>
						<tr>
							<td className="text-muted">Inter/Intl</td>
							<td className="text-end">
								{numberToLocaleString(rateLoadSummary.interIncreaseQty)}
							</td>
							<td className="text-end">
								{numberToLocaleString(rateLoadSummary.interDecreaseQty)}
							</td>
							<td className="text-end">
								{calculateUnchangedAndToLocaleString(
									rateLoadSummary.interIncreaseQty,
									rateLoadSummary.interDecreaseQty
								)}
							</td>
						</tr>
						<tr>
							<td className="text-muted">Intra</td>
							<td className="text-end">
								{numberToLocaleString(rateLoadSummary.intraIncreaseQty)}
							</td>
							<td className="text-end">
								{numberToLocaleString(rateLoadSummary.intraDecreaseQty)}
							</td>
							<td className="text-end">
								{calculateUnchangedAndToLocaleString(
									rateLoadSummary.intraIncreaseQty,
									rateLoadSummary.intraDecreaseQty
								)}
							</td>
						</tr>
						<tr>
							<td className="text-muted">Indet</td>
							<td className="text-end">
								{numberToLocaleString(rateLoadSummary.indetIncreaseQty)}
							</td>
							<td className="text-end">
								{numberToLocaleString(rateLoadSummary.indetDecreaseQty)}
							</td>
							<td className="text-end">
								{calculateUnchangedAndToLocaleString(
									rateLoadSummary.indetIncreaseQty,
									rateLoadSummary.indetDecreaseQty
								)}
							</td>
						</tr>
					</thead>
				</Table>
			</section>
			{rateLoadSummary.exceptions.length > 0 && (
				<footer>
					<Alert variant="danger">
						<header>
							<h6>The following exceptions were found:</h6>
						</header>
						<ul>
							{rateLoadSummary.exceptions.map((exception, index) => (
								<li key={index} style={{ textTransform: 'lowercase' }}>
									{exception.qty.toLocaleString()} rates have{' '}
									{exception.description}
								</li>
							))}
						</ul>
					</Alert>
				</footer>
			)}
		</section>
	);
};
