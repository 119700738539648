import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	SwitchAddressCreateRequest,
	SwitchAddressDeleteResponse,
	SwitchAddressIndexResponse,
	SwitchAddressProfileResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class SwitchAddressesResource extends HttpResource<
	SwitchAddressIndexResponse,
	SwitchAddressProfileResponse
> {
	constructor() {
		super(RtCommonApiRoutes.SwitchAddresses);
	}

	public getProfile(
		switchAddressId: number
	): Promise<SwitchAddressProfileResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.SwitchAddresses.Profile, {
			urlParams: {
				switchAddressId
			}
		});
	}

	public create(
		req: SwitchAddressCreateRequest
	): Promise<SwitchAddressProfileResponse> {
		const body = JSON.stringify(req);

		return this.fetchWithRoute(RtCommonApiRoutes.SwitchAddresses.Create, {
			body
		});
	}

	public update(
		switchAddress: SwitchAddressProfileResponse
	): Promise<SwitchAddressProfileResponse> {
		const { switchAddressId, ...req } = switchAddress;
		const urlParams = {
			switchAddressId
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute(RtCommonApiRoutes.SwitchAddresses.Update, {
			urlParams,
			body
		});
	}

	public delete(switchAddressId: number): Promise<SwitchAddressDeleteResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.SwitchAddresses.Delete, {
			urlParams: {
				switchAddressId
			}
		});
	}
}
