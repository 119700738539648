import type { AosLbl } from 'Somos/lib/SomosCpr/RtCprV2/AosLbl/AosLbl';
import type { Cpr } from 'Somos/lib/SomosCpr/RtCprV2/Cpr';
import { CprErrorType, CprNodeType, type CprSection, CprValueHighlightType, type ICprLerg, type IValidatorCleanResponse } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprError, type ICprErrorCleanData } from 'Somos/lib/SomosCpr/RtCprV2/CprError';
import type { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';
import { CprValue } from 'Somos/lib/SomosCpr/RtCprV2/CprValue';
import { v4 as uuidV4 } from 'uuid';

// exported definitions
// ======================================================================

export abstract class CprValidator {

	public readonly uuid: string;

	public readonly abstract cprSectionId: CprSection;
	public readonly abstract cprNodeTypeId: CprNodeType | undefined;

	public readonly isArray: boolean = false;
	public readonly valueLimit: number = 1;
	public readonly valueRegExp: RegExp | null = null;
	public readonly allowOther: boolean = false;

	public readonly isLergNode: boolean = false;
	public readonly isTreeNode: boolean = false;
	public readonly isTermNode: boolean = false;

	public readonly cpr: Cpr;

	protected cprIdx: number = 0; // can be row or column index

	protected readonly possibles: string[] = [];
	protected readonly possiblesByKey: Record<string, ICprLerg> = {};

	protected readonly canadaPossibles: string[] = [];
	protected readonly canadaPossiblesByKey: { [key: string]: ICprLerg; } = {};

	protected cprValues: CprValue[] = [];
	protected cprValuesByKey: { [value: string]: CprValue; } = {};

	protected readonly canLabel: boolean = false;
	protected label: CprLbl | AosLbl | null = null;

	protected errors: CprError[] = [];

	// set by validate()
	protected canadaHits: string[] = [];
	protected canadaMisses: string[] = [];

	protected highlightTypeId: CprValueHighlightType | undefined;

	protected parent?: CprValidator;

	public constructor(cpr: Cpr, cprIdx: number, uuid: string | undefined = undefined) {
		this.cpr = cpr;
		this.cprIdx = cprIdx;
		this.uuid = uuid || uuidV4();
	}

	public getCprNodeType() {
		return this.cprNodeTypeId;
	}

	public isHighlighted() {
		return typeof this.highlightTypeId !== 'undefined';
	}

	public getHighlightedTypeId() {
		return this.highlightTypeId;
	}

	public setHighlightTypeId(highlightTypeId: CprValueHighlightType | undefined) {
		if (highlightTypeId === undefined) {
			this.highlightTypeId = highlightTypeId;
			return;
		}

		// don't override if it's already Exact.
		if (this.highlightTypeId === CprValueHighlightType.Exact) {
			return;
		}

		this.highlightTypeId = highlightTypeId;
	}

	public getAggregatedCleanData() {
		const errors = this.getErrors();

		return CprError.AggregateCleanData(errors);
	}

	public clean(): IValidatorCleanResponse {
		const cleanData = this.getAggregatedCleanData();

		const response: IValidatorCleanResponse = {
			removedQty: 0,
			addedQty: 0,
			wasRemoved: false
		};

		if (typeof cleanData === 'undefined') {
			return response;
		}

		const cprValuesToRemove: CprValue[] = [];

		for (const cprValue of this.cprValues) {
			const value = cprValue.getValue();

			if (cleanData.valuesToRemove?.includes(value)) {
				cprValuesToRemove.push(cprValue);
			}
		}

		for (const cprValueToRemove of cprValuesToRemove) {
			this.removeValue(cprValueToRemove);
			response.removedQty++;
		}

		if (cleanData.valuesToAdd && cleanData.valuesToAdd.length > 0) {
			this.addValues(cleanData.valuesToAdd);
			response.addedQty += cleanData.valuesToAdd.length;
		}

		return response;
	}

	public getCanadaPossibles(): string[] {
		return this.canadaPossibles;
	}

	public hasCanada(): boolean {
		return (this.canadaHits.length > 0);
	}

	public isOther(): boolean {
		if (this.label) {
			return false;
		}

		if (this.cprValues.length !== 1) {
			return false;
		}

		if (this.cprValues[0].getValue() === 'OTHER') {
			return true;
		}

		return false;
	}

	public makeError(cprErrorTypeId: CprErrorType, raw: string | null, msg: string, cprValue?: CprValue, cleanData?: ICprErrorCleanData) {
		const newCprError = new CprError(cprErrorTypeId, this.cprSectionId, this.cprIdx, raw, msg);

		newCprError.setCleanData(cleanData);

		this.errors.push(newCprError);
		cprValue?.makeError(cprErrorTypeId, this.getCprIdx(), msg);

		if (this.parent) {
			this.parent.makeError(cprErrorTypeId, raw, msg, cprValue, cleanData);
		} else {
			this.cpr.addError(newCprError);
		}

		return newCprError;
	}

	public addNotice(raw: string | null, msg: string, cprValue?: CprValue, cleanData?: ICprErrorCleanData) {
		return this.makeError(CprErrorType.Notice, raw, msg, cprValue, cleanData);
	}

	public addError(raw: string | null, msg: string, cprValue?: CprValue, cleanData?: ICprErrorCleanData) {
		return this.makeError(CprErrorType.Error, raw, msg, cprValue, cleanData);
	}

	public addCriticalError(raw: string | null, msg: string, cprValue?: CprValue, cleanData?: ICprErrorCleanData) {
		return this.makeError(CprErrorType.Critical, raw, msg, cprValue, cleanData);
	}

	public addWarning(raw: string | null, msg: string, cprValue?: CprValue, cleanData?: ICprErrorCleanData) {
		return this.makeError(CprErrorType.Warning, raw, msg, cprValue, cleanData);
	}

	public getErrors(): CprError[] {
		return this.errors;
	}

	public hasErrors(type?: CprErrorType): boolean {
		if (!type) {
			return this.errors.length > 0;
		}

		for (const error of this.errors) {
			if (error.cprErrorTypeId === type) {
				return true;
			}
		}

		return false;
	}

	public getCprIdx(): number {
		return this.cprIdx;
	}

	public setCprIdx(newIdx: number): number {
		return this.cprIdx = newIdx;
	}

	public getExtValues(getLabelValues: boolean = false): string[] {
		return this.getRawValues(getLabelValues);
	}

	public getRawValues(getLabelValues: boolean = false): string[] {
		if (getLabelValues && this.label) {
			return this.label.getRawValues();
		}

		if (this.label) {
			return [this.label.getName()];
		}

		const rawValues: string[] = [];

		for (const cprValue of this.cprValues) {
			rawValues.push(cprValue.getValue());
		}

		return rawValues;
	}

	public reset() {
		this.errors = [];
	}

	/**
	 * resets possibles and possiblesByKey where they are calculated.
	 * DO NOT override resetPossibles unless the values are dynamic based on other selections.
	 */
	public resetPossibles() {
		// DO NOT override resetPossibles unless the values are dynamic based on other selections.
	}

	public removeValue(cprValue: CprValue): boolean {
		const value = cprValue.getValue();

		if (!this.cprValuesByKey.hasOwnProperty(value)) {
			return false;
		}

		const indexOfCprValue = this.cprValues.indexOf(cprValue);

		if (indexOfCprValue < 0) {
			return false;
		}

		this.cprValues.splice(indexOfCprValue, 1);
		delete this.cprValuesByKey[value];

		this.cpr.validate();

		return this.hasErrors();
	}

	public getValues(): CprValue[] {
		return this.cprValues.slice();
	}

	public getValueQty(): number {
		return this.cprValues.length;
	}

	public hasValues(): boolean {
		return this.cprValues.length > 0;
	}

	public setValues(newValues: string[]): boolean {
		// force upperCase and sort before comparisons.
		const useValues = [];

		for (const tmpValue of newValues) {
			if (typeof tmpValue !== 'string') {
				useValues.push(tmpValue);
				continue;
			}
			useValues.push(tmpValue.trim().toUpperCase());
		}

		/*
		const oldValues = this.getRawValues();

		// compare useValues with oldValues (both sorted at this point).
		if (oldValues.length === useValues.length) {
			if (oldValues.join(',') === useValues.join(',')) {
				return this.hasErrors();
			}
		}
		*/

		this.cprValues = [];
		this.cprValuesByKey = {};

		for (const newValue of useValues) {
			// intentionally pass doValidation as false in this loop.
			this.addValue(newValue);
		}

		// DO NOT override resetPossibles unless the values are dynamic based on other selections.
		this.resetPossibles();

		this.cpr.validate();

		return this.hasErrors();
	}

	public matchesValue(value: string): boolean {
		return (value in this.cprValuesByKey);
	}

	public validate(): boolean {
		this.errors = [];

		this.canadaHits = [];
		this.canadaMisses = [];

		let cprValueIdx = -1;

		this.setHighlightTypeId(undefined);

		for (const cprValue of this.cprValues) {
			cprValueIdx++;

			cprValue.reset();

			const rawVal = cprValue.getValue();

			if (rawVal === 'OTHER') {
				if (this.allowOther) {
					if (cprValueIdx > 0) {
						this.addError(rawVal, `OTHER must be the only value.`, cprValue);
					}
					continue;
				}
				this.addError(rawVal, `OTHER is not allowed here.`, cprValue, {
					valuesToRemove: [rawVal]
				});
				continue;
			}

			if (this.valueRegExp && !this.valueRegExp.test(rawVal)) {
				this.addError(rawVal, `Invalid value format.`, cprValue, {
					valuesToRemove: [rawVal]
				});
				continue;
			}

			if (!this.isPossibleValue(rawVal)) {
				this.addError(rawVal, this.getUnknownValueMessage(), cprValue, {
					valuesToRemove: [rawVal]
				});

				continue;
			}

			let isInAos: boolean = true;

			switch (this.cprNodeTypeId) {
				case CprNodeType.AreaCode:
					isInAos = this.cpr.isInAos(`AC:${rawVal}`);
					break;
				case CprNodeType.Lata:
					isInAos = this.cpr.isInAos(`LT:${rawVal.substring(0, 3)}`);
					break;
				case CprNodeType.NpaNxx:
					if (rawVal.length === 3) {
						// this is an exchange -- do not check
						break;
					}
					isInAos = this.cpr.isInAos(`AC:${rawVal.substring(0, 3)}`);
					break;
				case CprNodeType.SixDigit:
				case CprNodeType.TenDigit:
					isInAos = this.cpr.isInAos(`AC:${rawVal.substring(0, 3)}`);
					break;
				case CprNodeType.State:
					isInAos = this.cpr.isInAos(`ST:${rawVal}`);
					break;
			}

			if (!isInAos) {
				this.addError(rawVal, `Value is not in Area of Service.`, cprValue, {
					valuesToRemove: [rawVal]
				});
			}

			if (this.canadaPossibles.length === 0 && (rawVal in this.canadaPossiblesByKey)) {
				this.addError(rawVal, `Canada values are not allowed here.`, cprValue, {
					valuesToRemove: [rawVal]
				});
			}

			if (this.canadaPossibles.length > 0) {
				if (rawVal in this.canadaPossiblesByKey) {
					this.canadaHits.push(rawVal);
				}
			}

			const useHighlightTypeId = this.shouldHighlightValue(rawVal);
			this.setHighlightTypeId(useHighlightTypeId);
			cprValue.setHighlightTypeId(useHighlightTypeId);
		}

		if (this.canadaPossibles.length > 0 && this.canadaHits.length > 0) {
			for (const canVal of this.canadaPossibles) {
				if (this.canadaHits.includes(canVal)) {
					continue;
				}

				this.canadaMisses.push(canVal);
			}

			//checks to make sure that a validator can add that many canada misses -- if it does not
			//then don't say they are missing. This is the case for CprCols.
			const canAddCanadaMisses = (
				this.canadaMisses.length > 0 && this.valueLimit >= this.canadaMisses.length
			);

			if (canAddCanadaMisses) {
				this.addError(null, `Missing Canada value.`, undefined, {
					valuesToAdd: this.canadaMisses
				});
			}
		}

		// set over limit value, only if there are no other errors (we're only setting this for clean to work)
		if (this.cprValues.length > this.valueLimit) {
			if (this.valueLimit === 1) {
				this.addError(null, `Only ${this.valueLimit} value is allowed.`);
			} else {
				this.addError(null, `Only ${this.valueLimit} values are allowed.`);
			}
		}

		return this.hasErrors();
	}

	public shouldHighlightValue(rawVal: string) {
		if (this.cprNodeTypeId) {
			return this.cpr.shouldHighlightValue(this.cprNodeTypeId, rawVal);
		}

		return undefined;
	}

	public isPossibleValue(rawVal: string) {
		return rawVal in this.possiblesByKey;
	}

	public getUnknownValueMessage() {
		return 'Unknown value.';
	}

	public getPossibles(search: string = '', excludeCurrent: boolean = true, limit: number = 0): string[] {
		const filteredPossibles: string[] = [];

		const omits = (excludeCurrent) ? this.getRawValues() : [];

		// search for labels if string starts with *
		if (search.startsWith('*')) {
			if (!this.cprNodeTypeId) {
				return filteredPossibles;
			}

			for (const label of this.cpr.getCprLabels(this.cprNodeTypeId)) {
				const possible = label.getName();

				if (omits.includes(possible)) {
					continue;
				}

				if (!possible.startsWith(search)) {
					continue;
				}

				filteredPossibles.push(possible);

				if (limit && filteredPossibles.length >= limit) {
					break;
				}
			}

			return filteredPossibles;
		}

		for (const possible of this.possibles) {
			if (omits.includes(possible)) {
				continue;
			}

			if (!possible.startsWith(search)) {
				continue;
			}

			filteredPossibles.push(possible);

			if (limit && filteredPossibles.length >= limit) {
				break;
			}
		}

		return filteredPossibles;
	};

	protected addValue(newValue: string): boolean {
		// force upperCase (redundant if .setValues was used)
		newValue = newValue.trim().toUpperCase();

		if (!newValue) {
			return false;
		}

		if (this.cprValuesByKey.hasOwnProperty(newValue)) {
			return false;
		}

		const cprValue = new CprValue(this.cprSectionId, newValue);

		this.cprValues.push(cprValue);
		this.cprValuesByKey[newValue] = cprValue;

		this.cprValues.sort((val1, val2) => {
			return val1.getValue().localeCompare(val2.getValue());
		});

		return this.hasErrors();
	}

	protected addValues(newValues: string[]): boolean {
		let added: boolean = false;

		for (const newValue of newValues) {
			added = this.addValue(newValue) || added;
		}

		return added;
	}

	protected setParent(newParent: CprValidator) {
		this.parent = newParent;
	}
}
