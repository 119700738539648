import { ConnectionIndexResponse } from 'RtModels';
import { ConnectionRouter } from 'RtUi/app/rtVue/Connections/Connection.router';
import { ConnectionResource } from 'RtUi/app/rtVue/Connections/lib/resources/ConnectionResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

interface IVueConnectionSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<ConnectionIndexResponse, IsMulti> {}
interface IVueConnectionSelectState
	extends ISelectFormControlState<ConnectionIndexResponse> {}

export class VueConnectionSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	ConnectionIndexResponse,
	IsMulti,
	IVueConnectionSelectProps<IsMulti>,
	IVueConnectionSelectState
> {
	public static defaultProps = {
		router: ConnectionRouter
	};
	public resourceClass = ConnectionResource;
	public state: IVueConnectionSelectState = {
		formLabel: 'Connection',
		valueKey: 'connectionId',
		labelKey: 'fileStreamName'
	};

	public filterOption(
		option: FilterOptionOption<ConnectionIndexResponse>,
		rawInput: string
	): boolean {
		const connection = option.data;
		const hasValue = rawInput.length > 0;

		if (hasValue && connection.trunkGroupLabel) {
			return connection.trunkGroupLabel
				.toLowerCase()
				.includes(rawInput.toLowerCase());
		}

		if (hasValue && connection.trunkGroupId) {
			return connection.trunkGroupId
				.toLowerCase()
				.includes(rawInput.toLowerCase());
		}

		if (hasValue && connection.peerAddress) {
			return connection.peerAddress
				.toLowerCase()
				.includes(rawInput.toLowerCase());
		}

		return super.filterOption(option, rawInput);
	}

	public optionRenderer = (connection: ConnectionIndexResponse) => {
		if (connection.trunkGroupLabel) {
			return <span>{connection.trunkGroupLabel}</span>;
		}

		if (connection.trunkGroupId) {
			return <span>{connection.trunkGroupId}</span>;
		}

		if (connection.peerAddress) {
			return <span>{connection.peerAddress}</span>;
		}

		return <span>{connection.connectionId}</span>;
	};
}
