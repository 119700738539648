import {
	GuardianAlertRecipient,
	GuardianFraudAlertRecipientCreateRequest,
	GuardianFraudAlertRecipientProfileRequest,
	GuardianFraudAlertRecipientIndexResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtVueApiRoutes } from 'RtExports/routes';

export class FraudRecipientsResource extends HttpResource<
	GuardianFraudAlertRecipientIndexResponse,
	GuardianAlertRecipient
> {
	constructor() {
		super();

		this.setApiModule(RtVueApiRoutes.GuardianFraudAlertsRecipients);
	}

	public create(request: GuardianFraudAlertRecipientCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianAlertRecipient>(
			RtVueApiRoutes.GuardianFraudAlertsRecipients.Create,
			{ body }
		);
	}

	public update(
		userId: number,
		request: GuardianFraudAlertRecipientCreateRequest
	) {
		const urlParams: GuardianFraudAlertRecipientProfileRequest = {
			userId
		};
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianAlertRecipient>(
			RtVueApiRoutes.GuardianFraudAlertsRecipients.Update,
			{ body, urlParams }
		);
	}

	public delete(userId: number) {
		const urlParams: GuardianFraudAlertRecipientProfileRequest = {
			userId
		};

		return this.fetchWithRoute(
			RtVueApiRoutes.GuardianFraudAlertsRecipients.Delete,
			{
				urlParams
			}
		);
	}

	public createNewProfile(): GuardianFraudAlertRecipientCreateRequest {
		return {
			messageTypeIds: [],
			alertType: [],
			userId: null,
			emailAliasId: null
		};
	}
}
