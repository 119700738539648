/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationUsageDialCodeSummaryProfileResponse = {
    properties: {
        guardianReconciliationUsageDialCodeSummaryId: {
            type: 'number',
            isRequired: true,
        },
        timeslot: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        jurisdiction: {
            type: 'string',
            isRequired: true,
        },
        reconciliationId: {
            type: 'number',
            isRequired: true,
        },
        srcAttempts: {
            type: 'number',
            isRequired: true,
        },
        srcConnects: {
            type: 'number',
            isRequired: true,
        },
        srcDuration: {
            type: 'number',
            isRequired: true,
        },
        srcCost: {
            type: 'number',
            isRequired: true,
        },
        tgtAttempts: {
            type: 'number',
            isRequired: true,
        },
        tgtConnects: {
            type: 'number',
            isRequired: true,
        },
        tgtDuration: {
            type: 'number',
            isRequired: true,
        },
        tgtCost: {
            type: 'number',
            isRequired: true,
        },
        updateTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        durationDiff: {
            type: 'number',
            isRequired: true,
        },
        dialCode: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        iso: {
            type: 'string',
            isRequired: true,
        },
    },
};