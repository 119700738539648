import * as React from 'react';
import { NumberGroupProfileResponse, RespOrgIndexResponse } from 'RtModels';
import { TollFreeNumbersTextArea } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumbersTextArea';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { RtError } from 'RtUi/utils/errors/RtError';
import { timestampToReadable } from 'RtUi/utils/maps';
import { NumberGroupResource } from '../resources/NumberGroupResource';

export interface INumberGroupEditorProps {
	profile?: NumberGroupProfileResponse;
	narrow?: boolean;
	onSuccess?: (profile: NumberGroupProfileResponse) => void;
}

interface INumberGroupEditorState {
	label: string;
	tfns: string[];
	isActive: number;
	isSubmitting: boolean;
	loadingTfns: boolean;
	displayMode: boolean;
	respOrg?: RespOrgIndexResponse;
	respOrgId?: RespOrgIndexResponse['respOrgId'];
	error?: any;
}

export class NumberGroupEditor extends React.Component<
	INumberGroupEditorProps,
	INumberGroupEditorState
> {
	public state: INumberGroupEditorState = {
		loadingTfns: false,
		label: '',
		tfns: [],
		isActive: 1,
		isSubmitting: false,
		displayMode: false,
		error: undefined
	};

	public numberGroupResource = new NumberGroupResource();

	public componentDidMount() {
		const { profile } = this.props;
		const displayMode = Boolean(profile);

		this.setState({ displayMode });

		if (profile) {
			this.loadProfile(profile);
		}
	}

	public loadProfile(profile: NumberGroupProfileResponse) {
		const { label, respOrgId, isActive } = profile;

		this.setState({
			respOrgId,
			label,
			isActive,
			respOrg: undefined,
			error: undefined
		});
	}

	public createOrUpdate(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		const { profile } = this.props;
		const { respOrg, label, isActive, tfns } = this.state;

		if (!respOrg) {
			return;
		}

		const { respOrgId } = respOrg;
		const numberGroupResource = new NumberGroupResource();

		this.setState({ isSubmitting: true, error: undefined });

		let createOrUpdatePromise: Promise<NumberGroupProfileResponse> =
			Promise.reject();

		if (profile) {
			//Update Mode
			const tfnsUpdate = tfns.length > 0 ? tfns : undefined;

			createOrUpdatePromise = numberGroupResource.updateNumberGroup(
				profile.numberGroupId,
				label,
				isActive,
				respOrgId,
				tfnsUpdate
			);
		} else {
			//Create Mode
			createOrUpdatePromise = numberGroupResource.createNumberGroup(
				label,
				isActive,
				respOrgId,
				tfns
			);
		}

		createOrUpdatePromise
			.then((newProfile) => {
				const { onSuccess = () => ({}) } = this.props;

				onSuccess(newProfile);

				if (profile) {
					//Update Mode
					this.setState({ displayMode: true });
				}
			})
			.catch((error: RtError) => this.setState({ error }))
			.finally(() => this.setState({ isSubmitting: false }));
	}

	public onCancel() {
		const { profile } = this.props;

		if (profile) {
			this.loadProfile(profile);
		}

		this.setState({ displayMode: true });
	}

	public render() {
		const { respOrgId, respOrg, error } = this.state;
		const createMode = !this.props.profile;
		const currentRespOrgId = respOrg ? respOrg.respOrgId : '';
		const defaultAndCurrentRespOrgKey = respOrgId + currentRespOrgId;

		return (
			<RtForm
				narrow={this.props.narrow}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				createMode={createMode}
				error={error}
				onChange={(displayMode) => this.setState({ displayMode })}
				onSubmit={(e) => this.createOrUpdate(e)}
			>
				{this.props.profile && (
					<>
						<InputFormControl
							label="Created At"
							displayMode
							value={timestampToReadable(this.props.profile.createdAt)}
						/>
						<InputFormControl
							label="Created By"
							displayMode
							value={this.props.profile.createdByEmailAddress}
						/>
						<InputFormControl
							label="Last Updated"
							displayMode
							value={timestampToReadable(this.props.profile.updatedAt)}
						/>
					</>
				)}

				<InputFormControl
					label="Label"
					required
					displayMode={this.state.displayMode}
					onChange={(label) => this.setState({ label })}
					value={this.state.label}
				/>
				<RespOrgSelect
					required
					key={'RespOrgSelect' + defaultAndCurrentRespOrgKey}
					initialOptionId={respOrgId}
					displayMode={this.state.displayMode}
					onChange={(respOrg) => this.setState({ respOrg })}
					value={this.state.respOrg}
				/>
				<IsActiveRadioFormControl
					label="Status"
					displayMode={this.state.displayMode}
					hideBothOption
					onChange={(isActive) => this.setState({ isActive })}
					value={this.state.isActive}
				/>
				{!this.state.displayMode && (
					<TollFreeNumbersTextArea
						key={'TollFreeNumbersTextArea' + defaultAndCurrentRespOrgKey}
						label={!createMode ? 'Toll-Free Numbers (optional)' : undefined}
						required={createMode}
						allowForUpload
						loading={this.state.loadingTfns}
						displayMode={this.state.displayMode}
						onChange={(tfns) => this.setState({ tfns })}
						value={this.state.tfns}
					/>
				)}
			</RtForm>
		);
	}
}
