import { Rt800ApiRoutes } from 'RtExports/routes';
import { LcoApplicationRouter } from 'RtUi/app/rtLco/rtLco.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { LcrMinuteIndexResponse } from 'RtModels';

interface ITrafficProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Search: TabbedLayoutTabProps;
	Profile: TabbedLayoutTabProps;
	Edit: TabbedLayoutTabProps;
}

export const TrafficProfileContainerTabs: ITrafficProfileContainerTabs = {
	Search: {
		header: 'Search'
	},
	Profile: {
		header: 'Profile',
		isDefault: true
	},
	Edit: {
		header: 'Edit Label'
	}
};

export class MinutesRouterClass extends LcoApplicationRouter<
	LcrMinuteIndexResponse,
	LcrMinuteIndexResponse,
	ITrafficProfileContainerTabs
> {
	constructor() {
		super(
			'Traffic Profile',
			'trafficProfile',
			'minuteProfileId',
			TrafficProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.LcrMinutes);
	}

	public getProfileLabel(profile: LcrMinuteIndexResponse) {
		return profile.label;
	}
}

export const MinutesRouter = new MinutesRouterClass();
