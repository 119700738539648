import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { LcrMinuteProfileRequest } from 'RtModels';

export class LcrMinutesDataExportResource {
	public getAll(minuteProfileId: number) {
		const urlParams: LcrMinuteProfileRequest = {
			minuteProfileId
		};

		return HttpRequest.fetchWithRoute<number>(
			Rt800ApiRoutes.LcrMinutesDataExportCsv.Index,
			{
				urlParams
			}
		);
	}
}
