import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { ApplicationRouter } from 'RtUi/app/ApplicationShell/lib/components/ApplicationRouter';
import store from 'RtUi/store';

interface IAppProvidersProps {
	children?: ReactNode;
}

export const AppProviders = ({ children }: IAppProvidersProps) => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				retry: false
			}
		}
	});

	return (
		<QueryClientProvider client={queryClient}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Provider store={store}>
					<ApplicationRouter history={BrowserHistory}>
						{children}
					</ApplicationRouter>
				</Provider>
			</LocalizationProvider>
		</QueryClientProvider>
	);
};
