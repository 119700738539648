import { SipConnection } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DirectionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DirectionDataGridColumn';
import { FqdnDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FqdnDataGridColumn';
import { GatewayPortDataGridColumn } from 'RtUi/components/data/DataGrid/columns/GatewayPortDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { PortDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PortDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const getConnectionsColumns = (): Array<
	DataGridColumn<SipConnection>
> => [
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DirectionDataGridColumn({
		accessorKey: 'directionId',
		header: 'Direction'
	}),
	FqdnDataGridColumn({
		accessorKey: 'localAddress',
		header: 'Local IP (FQDN)'
	}),
	GatewayPortDataGridColumn({
		accessorKey: 'gatewayId',
		header: 'Local Port'
	}),
	FqdnDataGridColumn({
		accessorKey: 'hostAddress',
		header: 'Remote IP (FQDN)'
	}),
	PortDataGridColumn({
		accessorKey: 'hostPort',
		header: 'Remote Port'
	}),
	DefaultDataGridColumn({
		accessorKey: 'techPrefix',
		header: 'Tech Prefix'
	}),
	DefaultDataGridColumn({
		accessorKey: 'nanpFormat',
		header: 'NANP Format'
	}),
	DefaultDataGridColumn({
		accessorKey: 'idddFormat',
		header: 'IDDD Format'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'deletedTs',
		header: 'Deleted'
	})
];
