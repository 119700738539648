import { type CprErrorType, type CprSection, CprValueHighlightType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprError, type ICprErrorCleanData } from 'Somos/lib/SomosCpr/RtCprV2/CprError';
import { v4 as uuidV4 } from 'uuid';

// exported definitions
// ======================================================================

export class CprValue {

	public readonly cprSectionId: CprSection;
	public readonly uuid: string;

	protected value: string;
	protected highlightTypeId: CprValueHighlightType | undefined;

	protected errors: CprError[] = [];

	public constructor(cprSectionId: CprSection, value: string, uuid: string | undefined = undefined) {
		this.cprSectionId = cprSectionId;
		this.value = value;
		this.uuid = uuid || uuidV4();
	}

	public isHighlighted() {
		return typeof this.highlightTypeId !== 'undefined';
	}

	public getHighlightedTypeId() {
		return this.highlightTypeId;
	}

	public setHighlightTypeId(highlightTypeId: CprValueHighlightType | undefined) {
		if (highlightTypeId === undefined) {
			this.highlightTypeId = highlightTypeId;
			return;
		}

		// don't override if it's already Exact.
		if (this.highlightTypeId === CprValueHighlightType.Exact) {
			return;
		}

		this.highlightTypeId = highlightTypeId;
	}

	public makeError(cprErrorTypeId: CprErrorType, cprIdx: number, message: string, cleanData?: ICprErrorCleanData) {
		const newError = new CprError(cprErrorTypeId, this.cprSectionId, cprIdx, this.value, message);

		newError.setCleanData(cleanData);

		this.addError(newError);
	}

	public addError(cprError: CprError) {
		this.errors.push(cprError);
	}

	public hasErrors(): boolean {
		return this.errors.length > 0;
	}

	public getValue(): string {
		return this.value;
	}

	public setValue(newValue: string) {
		this.value = newValue.trim().toUpperCase();
	}

	public reset() {
		this.errors = [];
	}

}
