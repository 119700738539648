import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	PartitionCreateRequest,
	PartitionIndexResponse,
	PartitionProfileUrlRequest,
	PartitionUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class PartitionResource extends HttpResource<PartitionIndexResponse> {
	constructor() {
		super(RtAdmApiRoutes.Partitions);
	}

	public create(partition: PartitionCreateRequest) {
		const body = JSON.stringify(partition);

		return this.fetchWithRoute<PartitionIndexResponse>(
			RtAdmApiRoutes.Partitions.Create,
			{ body }
		);
	}

	public update(partitionId: number, partition: PartitionUpdateRequest) {
		const urlParams: PartitionProfileUrlRequest = { partitionId };
		const body = JSON.stringify(partition);

		return this.fetchWithRoute<PartitionIndexResponse>(
			RtAdmApiRoutes.Partitions.Update,
			{ body, urlParams }
		);
	}
}
