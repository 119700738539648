import * as React from 'react';
import * as moment from 'moment-timezone';
import { Card } from 'react-bootstrap';
import { DidMetricTotalsByDateResource } from 'RtUi/app/rtDid/Dashboard/lib/resources/DidMetricTotalsByDateResource';
import { MetricsTotalsByDateResponse } from 'RtModels';
import { ChartWrapper } from 'RtUi/app/rtVue/common/lib/charts/ChartWrapper';
import { ChartData, ChartDataset } from 'chart.js';

interface IMetricTotalsByDateChartProps {
	resource: DidMetricTotalsByDateResource;
}

interface IMetricTotalsByDateChartState {
	records: MetricsTotalsByDateResponse[];
}

export class MetricTotalsByDateChart extends React.Component<
	IMetricTotalsByDateChartProps,
	IMetricTotalsByDateChartState
> {
	public state: IMetricTotalsByDateChartState = {
		records: []
	};

	public componentDidMount() {
		this.getRecords();
	}

	public componentDidUpdate(prevProps: IMetricTotalsByDateChartProps) {
		if (prevProps.resource.getUuid() !== this.props.resource.getUuid()) {
			this.getRecords();
		}
	}

	public async getRecords() {
		const records = await this.props.resource.getAll();

		this.setState({ records });
	}

	public render() {
		const { records } = this.state;
		const datasets: ChartDataset[] = [
			{
				type: 'line',
				fill: false,
				borderColor: '#5562A4',
				backgroundColor: '#5562A4',
				data: records.map((r) => r.totalActiveQty)
			}
		];
		const labels: string[] = records.map((r) =>
			moment(r.date).format('MM/DD/YYYY')
		);
		const data: ChartData = { datasets, labels };

		return (
			<Card body>
				{records.length !== 0 && (
					<ChartWrapper
						key="metric-by-date"
						config={{
							data,
							options: {
								height: 200,
								responsive: true,
								maintainAspectRatio: false,
								plugins: {
									legend: { display: false }
								},
								scales: {
									y: {
										// @ts-expect-error
										height: 200,
										beginAtZero: false,
										ticks: {
											// @ts-expect-error
											callback: ({ label }) => label
										},
										scaleLabel: {
											display: true,
											labelString: datasets[0].label
										}
									}
								}
							}
						}}
					/>
				)}
				{/* this hack it needed to keep the same card height if no chard */}
				{records.length === 0 && <div style={{ height: '200px' }}></div>}
			</Card>
		);
	}
}
