import { IntegrationsApiRoutes } from 'RtExports/routes';
import {
	ResourceConfigurationIndexRequest,
	ResourceConfigurationIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchResourceConfiguration = async (
	queryParams?: ResourceConfigurationIndexRequest
): Promise<FullResponse<ResourceConfigurationIndexResponse[]>> => {
	return handleGetRequest<ResourceConfigurationIndexResponse[], true>(
		IntegrationsApiRoutes.ResourceConfigurations.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetResourceConfiguration = (
	queryParams?: ResourceConfigurationIndexRequest
) => {
	return useQuery<FullResponse<ResourceConfigurationIndexResponse[]>, Error>(
		[`useGetResourceConfiguration`],
		() => fetchResourceConfiguration(queryParams)
	);
};
