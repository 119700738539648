/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileProcessCreateRequest = {
    properties: {
        fileProcessTypeId: {
            type: 'FileProcessorType',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        parameters: {
            properties: {
            },
        },
    },
};