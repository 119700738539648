import moment from 'moment';
import { FC, FormEvent, useState } from 'react';
import { useMount } from 'react-use';
import * as dateFns from 'date-fns';
import {
	GuardianServiceNumberSubscriptionProfileResponse,
	ServiceNumberCreateRequest,
	SubscriptionIndexResponse
} from 'RtModels';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { NumberRouter } from 'RtUi/app/rt800/Numbers/Number.router';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { ServiceNumberLookupControl } from 'RtUi/app/rtVue/common/lib/controls/ServiceNumberLookupControl';
import {
	CallTypesSelect,
	ICallTypeSelectOption
} from 'RtUi/app/rtVue/Connections/lib/controls/CallTypesSelect';
import { GuardianServiceNumberSubscriptionResource } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/resources/GuardianServiceNumberSubscriptionResource';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { useNavigate } from 'react-router-dom';

interface IGuardianServiceNumberSubscriptionEditorProps {
	narrow?: boolean;
	onSuccess?: (
		newProfile: GuardianServiceNumberSubscriptionProfileResponse
	) => void;
	onCancel?: () => void;
	editMode?: GuardianServiceNumberSubscriptionProfileResponse;
	createMode?: boolean;
}

export const GuardianServiceNumberSubscriptionForm: FC<
	IGuardianServiceNumberSubscriptionEditorProps
> = ({
	onSuccess = () => ({}),
	onCancel = () => ({}),
	createMode = false,
	editMode,
	narrow
}) => {
	const resource = new GuardianServiceNumberSubscriptionResource();
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [callType, setCallType] = useState<ICallTypeSelectOption>();
	const [disableWholeForm, setDisableWholeForm] = useState(false);
	const [disableSubscriptionEdit, setDisableSubscriptionEdit] = useState(false);
	const navigate = useNavigate();

	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		createMode,
		editMode,
		createNew: () => resource.createNewProfile()
	});
	const { workingCopy } = rtUiFormState;

	useMount(() => {
		if (!editMode || !editMode.endTs) {
			return;
		}

		//If endTs passed then disable whole form
		//If endTs is there, then you cannot edit subscription
		const now = new Date();
		const endTs =
			//just incase API sends back date-string
			typeof editMode.endTs === 'string'
				? dateFns.parseISO(editMode.endTs)
				: editMode.endTs;

		if (dateFns.isBefore(endTs, now)) {
			setDisableWholeForm(true);
		} else {
			setDisableSubscriptionEdit(true);
		}
	});

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		const submitAction = Boolean(editMode) ? 'Updating' : 'Creating';
		const confirmation = await Confirmation.createConfirm(
			`${submitAction} a service number can have billing and rating side effects.`
		);

		if (!confirmation) {
			return;
		}

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			let newProfile: GuardianServiceNumberSubscriptionProfileResponse;
			if (editMode) {
				newProfile = await resource.update(
					editMode.guardianServiceNumberSubscriptionId,
					workingCopy
				);
			} else {
				newProfile = await resource.create(workingCopy);
			}
			rtUiFormMethods.setDisplayMode(true);
			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.replaceWorkingCopy(newProfile);

			const confirm = await Confirmation.create(
				<span>
					<span>
						Do you want to create a Global Service number from the Service
						Number that you just created?
					</span>
				</span>,
				{
					confirmationBtnText: 'Yes',
					cancelTextBtnText: 'No'
				}
			);

			if (confirm) {
				const globalMetadata: Partial<ServiceNumberCreateRequest> = {
					serviceNumber: newProfile.serviceNumber,
					subscriptionId: newProfile.subscriptionId
				};

				return navigate(
					`/rtVue/globalNumbers?create=${JSON.stringify(globalMetadata)}`
				);
			}

			onSuccess(newProfile);
		} catch (err) {
			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setError(err);
		}
	};

	let serviceNumberLinkTo: string | undefined;

	if (workingCopy?.serviceNumber) {
		const hasRt800TollFreePermissions = NumberRouter.hasAccessToProfile();
		const isTollFree = TollFreeNumbersUtils.isValid(workingCopy.serviceNumber);

		if (hasRt800TollFreePermissions && isTollFree) {
			serviceNumberLinkTo = NumberRouter.getProfileRoute(
				workingCopy.serviceNumber
			);
		}
	}

	const startTsMoment = moment.utc(rtUiFormState.workingCopy.startTs);
	const endTsMoment = rtUiFormState.workingCopy.endTs
		? moment.utc(rtUiFormState.workingCopy.endTs)
		: moment().utc().endOf('day');

	return (
		<RtForm
			{...rtUiFormState}
			hideButtons={disableWholeForm}
			narrow={narrow}
			onChange={rtUiFormMethods.setDisplayMode}
			onSubmit={onSubmit}
			onCancel={() => {
				rtUiFormMethods.onCancel();
				onCancel();
			}}
		>
			<InputFormControl
				required
				type="text"
				pattern="[0-9]*"
				displayMode={
					Boolean(rtUiFormState.editMode) || rtUiFormState.displayMode
				}
				label="Service Number"
				onChange={(serviceNumber) => {
					rtUiFormMethods.setWorkingCopy({
						serviceNumber: serviceNumber.trim()
					});
				}}
				value={workingCopy?.serviceNumber}
				linkTo={serviceNumberLinkTo}
			/>

			<SubscriptionSelect
				required
				displayMode={disableSubscriptionEdit || rtUiFormState.displayMode}
				onChange={(newSub) => {
					rtUiFormMethods.setWorkingCopy({
						subscriptionId: newSub.subscriptionId
					});
					setSubscription(newSub);
				}}
				value={subscription}
				initialOptionId={
					workingCopy?.subscriptionId !== -1
						? workingCopy?.subscriptionId
						: undefined
				}
			/>
			<CallTypesSelect
				required
				displayMode={rtUiFormState.displayMode}
				onChange={(callType) => {
					setCallType(callType);
					rtUiFormMethods.setWorkingCopy({ callType: callType.callTypeId });
				}}
				value={callType}
				initialOptionId={workingCopy.callType}
			/>
			<IsActiveRadioFormControl
				hideBothOption
				displayMode={rtUiFormState.displayMode}
				value={workingCopy?.isActive}
				onChange={(isActive) => {
					rtUiFormMethods.setWorkingCopy({ isActive });
				}}
			/>
			<ServiceNumberLookupControl
				required
				displayMode={rtUiFormState.displayMode}
				value={workingCopy?.directionId}
				onChange={(directionId) =>
					rtUiFormMethods.setWorkingCopy({ directionId })
				}
			/>
			<DatetimeFormControl
				label="Start Date (UTC)"
				displayMode={rtUiFormState.displayMode}
				value={startTsMoment}
				onChange={(scheduleStartTs) =>
					rtUiFormMethods.setWorkingCopy({ startTs: scheduleStartTs?.toDate() })
				}
				appendToBody
			/>
			<DatetimeFormControl
				label="End Date (UTC)"
				displayMode={rtUiFormState.displayMode}
				value={endTsMoment}
				minDate={rtUiFormState.workingCopy.endTs ? startTsMoment : undefined}
				onChangeWithIsNow={(scheduleEndTs) =>
					rtUiFormMethods.setWorkingCopy({ endTs: scheduleEndTs?.toDate() })
				}
				appendToBody
			/>
		</RtForm>
	);
};
