/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationRequestObjectExtended = {
    properties: {
        integrationRequestUuid: {
            type: 'string',
            isRequired: true,
        },
        integrationRequestType: {
            type: 'IntegrationRequestObjectType',
            isRequired: true,
        },
        ref1: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        ref2: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        dto: {
            properties: {
            },
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        deletedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        integrationRequestTypeLabel: {
            type: 'string',
            isRequired: true,
        },
    },
};