import {
	IProfileApplicationContainerTabs,
	ProfileApplicationContainer
} from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import {
	TabbedLayoutTabProps,
	TabbedLayout,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { UserIndexResponse } from 'RtModels';
import { MyProfileRouter } from './MyProfile.router';
import { MyProfilePasswordUpdateForm } from 'RtUi/app/user/MyProfile/lib/forms/MyProfilePasswordUpdateForm';
import { MyProfileCommunicationPreferencesForm } from 'RtUi/app/user/MyProfile/lib/forms/MyProfileCommunicationPreferencesForm';
import { MyProfileForm } from 'RtUi/app/user/MyProfile/lib/forms/MyProfileForm';
import { MyProfileHttp } from 'RtUi/app/user/MyProfile/lib/Http/MyProfileHttp';
import { MyProfileTFAForm } from 'RtUi/app/user/MyProfile/lib/forms/MyProfileTFAForm';
import { MyProfileTFAFormDelete } from 'RtUi/app/user/MyProfile/lib/forms/MyProfileTFAFormDelete';
import { DownloadsGrid } from 'RtUi/app/user/MyProfile/lib/grids/DownloadsGrid';
import { Permissions } from 'RtExports/routes';
import { FileSharingGrid } from 'RtUi/app/user/MyProfile/lib/grids/FileSharingGrid';

interface IMyProfileContainerState {
	user: UserIndexResponse | null;
	activeTab: string;
	tfaWasTurnedOn: boolean;
}

interface IMyProfileContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	UpdatePassword: TabbedLayoutTabProps;
	CommunicationPreferences: TabbedLayoutTabProps;
}

@MyProfileRouter.getIndexRtUiController()
export class MyProfileContainer extends ProfileApplicationContainer<
	{},
	IMyProfileContainerState
> {
	public Tabs: IMyProfileContainerTabs = {
		Profile: {
			header: 'Profile'
		},
		UpdatePassword: {
			header: 'Update Password'
		},
		CommunicationPreferences: {
			header: 'Communication Preferences'
		},
		tfa: {
			header: 'Two-Factor Authentication'
		},
		Downloads: {
			header: 'Downloads'
		},
		FileSharing: {
			header: 'File Sharing',
			permissions: [Permissions.Basic]
		}
	};

	public state: IMyProfileContainerState = {
		user: null,
		activeTab: this.Tabs.Profile.header,
		tfaWasTurnedOn: false
	};

	public myProfileHttp = new MyProfileHttp();

	public async componentDidMount() {
		const user = await this.myProfileHttp.getProfile();

		this.setState({ user });
	}

	public render() {
		const { user } = this.state;
		const userStringified = JSON.stringify(user);

		if (user === null) {
			return <Loading />;
		}

		return (
			<TabbedLayout<UserIndexResponse>
				router={MyProfileRouter}
				profile={user}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) =>
					this.setState({ activeTab, tfaWasTurnedOn: false })
				}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<MyProfileForm
						key={userStringified}
						user={user}
						onUpdate={(newUser) => this.setState({ user: newUser })}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.UpdatePassword}>
					<MyProfilePasswordUpdateForm
						onUpdate={(newUser) => this.setState({ user: newUser })}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.CommunicationPreferences}>
					<MyProfileCommunicationPreferencesForm />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.tfa}>
					{user.twoFactorAuthType === 1 && (
						<MyProfileTFAFormDelete
							onUpdate={(newUser) =>
								this.setState({
									user: newUser,
									tfaWasTurnedOn: false
								})
							}
						/>
					)}
					{user.twoFactorAuthType === 0 && (
						<MyProfileTFAForm
							user={user}
							onUpdate={(newUser) =>
								this.setState({
									user: newUser,
									tfaWasTurnedOn: true
								})
							}
						/>
					)}
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Downloads}>
					<DownloadsGrid />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.FileSharing}>
					<FileSharingGrid />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
