import * as React from 'react';
import * as moment from 'moment-timezone';
import { DashboardTabs } from 'RtUi/components/ui/DashboardTabs';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { DashboardTab } from 'RtUi/components/ui/DashboardTabs/tab';
import { MetricTotalsByDateChart } from 'RtUi/app/rtDid/Dashboard/lib/charts/MetricTotalsByDateChart';
import { MetricTotalsByDateGrid } from 'RtUi/app/rtDid/Dashboard/lib/grids/MetricTotalsByDateGrid';
import { DidMetricTotalsByDateResource } from 'RtUi/app/rtDid/Dashboard/lib/resources/DidMetricTotalsByDateResource';
import { Loading } from 'RtUi/components/ui/Loading';

interface IMetricTotalsByDateDashboardTabsProps {
	className?: string;
	resourceId?: number;
}

interface IMetricTotalsByDateDashboardTabsState {
	auditTotalsByDateActiveTab: string;
	startDate: moment.Moment | null;
	endDate: moment.Moment | null;
	focusedDate: FocusedInputShape | null;
	didAuditTotalsByDateResource: DidMetricTotalsByDateResource | null;
}

export class MetricTotalsByDateDashboardTabs extends React.Component<
	IMetricTotalsByDateDashboardTabsProps,
	IMetricTotalsByDateDashboardTabsState
> {
	public state: IMetricTotalsByDateDashboardTabsState = {
		auditTotalsByDateActiveTab: 'chart',
		didAuditTotalsByDateResource: null,
		startDate: null,
		endDate: null,
		focusedDate: null
	};

	public componentDidMount() {
		const startMoment = moment().subtract(1, 'month').startOf('day');
		const endMoment = moment().startOf('day');

		this.onDatesChange(startMoment, endMoment);
	}

	public onDatesChange(
		startDate: moment.Moment | null,
		endDate: moment.Moment | null
	) {
		this.setState({ startDate, endDate });

		if (startDate === null || endDate === null) {
			return;
		}
		const { resourceId } = this.props;
		const didAuditTotalsByDateResource = new DidMetricTotalsByDateResource(
			startDate,
			endDate,
			resourceId
		);

		this.setState({ didAuditTotalsByDateResource });
	}

	public render() {
		const { didAuditTotalsByDateResource, startDate, endDate } = this.state;
		const { resourceId } = this.props;

		if (!didAuditTotalsByDateResource) {
			return <Loading />;
		}

		if (!resourceId || startDate === null || endDate === null) {
			return;
		}

		return (
			<DashboardTabs
				className={this.props.className}
				header={
					<header className="d-flex justify-content-start align-items-center">
						<b className="me-2">Active Totals By Date</b>
						<DateRangePicker
							isDayBlocked={() => false}
							isOutsideRange={() => false}
							startDate={this.state.startDate}
							startDateId="audit_totals_by_date_start"
							endDate={this.state.endDate}
							endDateId="audit_totals_by_date_end"
							//start and endDate throw error from moment-timezone
							//solution: use any
							onDatesChange={({ startDate, endDate }) =>
								this.onDatesChange(startDate as any, endDate as any)
							}
							focusedInput={this.state.focusedDate}
							hideKeyboardShortcutsPanel
							keepOpenOnDateSelect={false}
							onFocusChange={(focusedDate) => this.setState({ focusedDate })}
						/>
					</header>
				}
				activeTab={this.state.auditTotalsByDateActiveTab}
				onTabChange={(auditTotalsByDateActiveTab) =>
					this.setState({ auditTotalsByDateActiveTab })
				}
			>
				<DashboardTab tabId="chart" icon="fas fa-chart-line">
					<MetricTotalsByDateChart resource={didAuditTotalsByDateResource} />
				</DashboardTab>
				<DashboardTab tabId="grid" icon="fas fa-table">
					<MetricTotalsByDateGrid
						resourceId={resourceId}
						resourceParams={{
							startDate: startDate?.toDate(),
							endDate: endDate?.toDate()
						}}
					/>
				</DashboardTab>
			</DashboardTabs>
		);
	}
}
