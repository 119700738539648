import * as React from 'react';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from 'RtUi/components/form/FormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';
import ReactInputMask from 'react-input-mask';

interface IInternationalNumberFormControlState extends IFormControlState {}

interface IInternationalNumberFormControlProps
	extends IFormControlProps<string, false> {
	useControlGroup?: boolean;
	autoFocus?: boolean;
}

export class InternationalNumberFormControl extends FormControl<
	string,
	false,
	IInternationalNumberFormControlProps,
	IInternationalNumberFormControlState
> {
	public resource = undefined;
	public phoneNumberFormatter = new PhoneNumberFormatter();

	public state: IInternationalNumberFormControlState = {
		formLabel: 'International Number'
	};

	public inputRef: HTMLInputElement | null = null;
	public phoneFormatter = new PhoneNumberFormatter();

	public componentDidMount() {
		super.componentDidMount();

		if (this.props.autoFocus && this.inputRef) {
			this.inputRef.focus();
		}
	}

	public onChangeInternal(evt: React.ChangeEvent<HTMLInputElement>) {
		let { value } = evt.currentTarget;
		const { onChange = () => ({}) } = this.props;

		value = value.replace(/\D/g, '');

		if (this.inputRef && this.props.required) {
			const isCorrectLength = value.length === 11;
			const internationalNumber = this.phoneFormatter.toInternational(value);
			let customValidityMessage = '';

			if (!isCorrectLength) {
				customValidityMessage = 'Phone Number must be 11 digits in length';
			} else if (!internationalNumber) {
				customValidityMessage = 'Phone Number must be an international number';
			}

			this.inputRef.setCustomValidity(customValidityMessage);
		}

		onChange(value);
	}

	public getValueDependentProps() {
		let mask = '+9999-999-9999';
		let placeholder = '+_ ___-___-____';
		let minLength = 10;

		const { value } = this.props;
		const isValueSet = Boolean(value);
		if (isValueSet && value![0] !== '1') {
			mask = '+99 ' + '9'.repeat(16);
			placeholder = '';
			minLength = 8;
		}
		return {
			mask,
			placeholder,
			minLength
		};
	}

	public render() {
		const { value, displayMode, useControlGroup = true } = this.props;
		const { formLabel } = this.state;

		const formattedValue = value
			? this.phoneFormatter.toInternational(value)
			: '';
		const control = (
			<ReactInputMask
				{...this.getValueDependentProps()}
				className="form-control"
				inputRef={(inputRef) => (this.inputRef = inputRef)}
				onChange={(evt) => this.onChangeInternal(evt)}
				value={value}
			/>
		);

		if (!useControlGroup) {
			return control;
		}

		return (
			<ControlGroup
				label={formLabel}
				value={formattedValue}
				displayMode={displayMode}
				required={this.props.required}
				valueClassName="form-control-tollfree"
			>
				{control}
			</ControlGroup>
		);
	}
}
