import { ResourceType } from 'RtModels';
import { DidResourceConfigurationRouter } from 'RtUi/app/rtDid/ResourceConfigurations/DidResourceConfigurations.router';
import { ResourceConfigurationProfileContainer } from 'RtUi/app/rtCommon/ResourceConfigurations/lib/components/ResourceConfigurationsProfileContainer';

export const DidResourceConfigurationProfileContainer = () => {
	return (
		<ResourceConfigurationProfileContainer
			excludeResourceTypes={[ResourceType.CarrierConnect]}
			router={DidResourceConfigurationRouter}
			isDid
		/>
	);
};

DidResourceConfigurationRouter.setProfileRtUiFunctionalComponent(
	DidResourceConfigurationProfileContainer
);
