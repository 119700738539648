/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TemplateLcrCarrierCreateRequest = {
    properties: {
        respOrgCarrierId: {
            type: 'number',
            isRequired: true,
        },
        allowAsDefault: {
            type: 'number',
            isRequired: true,
        },
        allowAsCanada: {
            type: 'number',
            isRequired: true,
        },
    },
};