import { useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
	SwitchAddressIndexResponse,
	SwitchAddressProfileResponse
} from 'RtModels';
import { SwitchAddressEditor } from 'RtUi/app/AccountManagement/Switches/lib/forms/SwitchAddressEditor';
import { SwitchAddressesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SwitchAddressesResource';
import { useGetSwitchAddresses } from 'RtUi/app/AccountManagement/Switches/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSwitchAddressesColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/switches';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';

export interface ISwitchAddressesGridProps {
	switchId: number;
	autoFocusFilter?: boolean;
}

export const SwitchAddressesGrid = ({
	switchId
}: ISwitchAddressesGridProps): JSX.Element => {
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetSwitchAddresses({ switchId });
	const resource = useMemo(() => new SwitchAddressesResource(), []);
	const [editingSwitchAddress, setEditingSwitchAddress] =
		useState<SwitchAddressProfileResponse>();
	const [showAddSwitchAddressAside, setShowAddSwitchAddressAside] =
		useState<boolean>(false);

	const onClickRowHandler = (row: SwitchAddressIndexResponse) => {
		setEditingSwitchAddress(row);
		setShowAddSwitchAddressAside(true);
	};

	const onSuccessHandler = () => {
		refetch();
		setEditingSwitchAddress(undefined);
		setShowAddSwitchAddressAside(false);
	};

	const onCancelHandler = () => {
		setEditingSwitchAddress(undefined);
		setShowAddSwitchAddressAside(false);
	};

	const onDeleteHandler = useCallback(
		async (row: SwitchAddressIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				row.switchAddressId.toString()
			);

			if (!confirm) {
				return;
			}

			await resource.delete(row.switchAddressId);
			refetch();
		},
		[refetch, resource]
	);

	const columns = useMemo(
		() => getSwitchAddressesColumns(onDeleteHandler),
		[onDeleteHandler]
	);

	return (
		<>
			{showAddSwitchAddressAside && (
				<Aside isOpen={showAddSwitchAddressAside} disabledBody>
					<Aside.Header header="Add Switch Address" onClose={onCancelHandler} />
					<Aside.Body>
						<SwitchAddressEditor
							value={editingSwitchAddress}
							switchId={switchId}
							onSuccess={onSuccessHandler}
							onCancel={onCancelHandler}
						/>
					</Aside.Body>
				</Aside>
			)}
			<DataGrid<SwitchAddressIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'accountManagement_switchAddresses'}
				onRowClick={onClickRowHandler}
				disableExport
				headerAction={() => (
					<OverlayTrigger
						overlay={(props) => (
							<Tooltip id={`firewall-tooltip`} {...props}>
								Add Firewall Config
							</Tooltip>
						)}
					>
						{({ ref, ...triggerHandler }) => (
							<Button
								ref={ref}
								{...triggerHandler}
								onClick={() => {
									setShowAddSwitchAddressAside(true);
								}}
								variant="white"
								id="firewall"
							>
								<i className="fas fa-fw fa-plus fa-lg" />
							</Button>
						)}
					</OverlayTrigger>
				)}
			/>
		</>
	);
};
