import { useMemo } from 'react';
import { components, MultiValueProps } from 'react-select';
import { SipAgentMedValue } from 'RtModels';
import { IsMultiFormValue } from 'RtUi/components/form/FormControl';
import { ISelectFormControlProps } from 'RtUi/components/form/SelectFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface ISipAgentMedValueSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<SipAgentMedValue, IsMulti> {
	value?: IsMultiFormValue<SipAgentMedValue, IsMulti>;
	onChange?: (newValue: IsMultiFormValue<SipAgentMedValue, IsMulti>) => void;
	label?: string;
	required?: boolean;
	isClearable?: boolean;
}

export const SipAgentMedValueSelect = <IsMulti extends boolean = false>({
	value,
	onChange = () => {},
	required,
	isClearable = true,
	displayMode,
	label = 'SIP Agent Med Value',
	initialOptionId,
	multi
}: ISipAgentMedValueSelectProps<IsMulti>): JSX.Element => {
	const options: Array<ISimpleSelectFormControlOption<number>> = useMemo(() => {
		return [
			{
				label: 'Fqdn',
				value: SipAgentMedValue.Fqdn
			},
			{
				label: 'MedDefaultSubId',
				value: SipAgentMedValue.MedDefaultSubId
			},
			{
				label: 'MedNotFoundSubId',
				value: SipAgentMedValue.MedNotFoundSubId
			},
			{
				label: 'ParamOtg',
				value: SipAgentMedValue.ParamOtg
			},
			{
				label: 'ParamTgrp',
				value: SipAgentMedValue.ParamTgrp
			}
		];
	}, []);

	const selectValue = useMemo(() => {
		return multi
			? options.filter((opt) =>
					(value as SipAgentMedValue[])?.includes(opt.value)
				)
			: options.find((opt) => opt.value === value);
	}, [value, options, multi]);

	const initialOptions = useMemo(
		() =>
			multi
				? (initialOptionId as string[]).map((val) => val.toString())
				: initialOptionId?.toString(),
		[initialOptionId, multi]
	);

	const onChangeHandler = (
		newValue: IsMulti extends true
			? Array<ISimpleSelectFormControlOption<number>>
			: ISimpleSelectFormControlOption<number>
	) => {
		const val = multi
			? (newValue as Array<ISimpleSelectFormControlOption<number>>)?.map(
					(v) => v.value
				)
			: (newValue as ISimpleSelectFormControlOption<number>)?.value;

		onChange(val as any);
	};

	const MultiValue = (
		props: MultiValueProps<ISimpleSelectFormControlOption<number>, IsMulti>
	) => (
		<components.MultiValue {...props}>
			{`${props.index + 1}. ${props.data.label}`}
		</components.MultiValue>
	);

	return (
		<SimpleSelectFormControl<number, IsMulti>
			label={label}
			multi={multi}
			clearable={isClearable}
			required={required}
			displayMode={displayMode}
			value={selectValue as any}
			onChange={(val: any) => {
				onChangeHandler(val);
			}}
			options={options}
			initialOptionId={initialOptions as any}
			components={{ MultiValue }}
		/>
	);
};
