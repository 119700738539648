/* eslint-disable max-classes-per-file */

import {
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { TaskResource } from './resources/TaskResource';
import { TaskTypeIndexResponse } from 'RtModels';

class TaskTypeArrayResource extends ArrayResource<TaskTypeIndexResponse> {
	constructor() {
		super('taskTypeId');

		const taskResource = new TaskResource();
		const getAllPromise = taskResource.getTaskTypes();

		this.setGetAllPromise(getAllPromise);
	}
}

export class TaskTypeSelect<
	isMulti extends boolean = false
> extends SelectFormControl<TaskTypeIndexResponse, isMulti> {
	public resourceClass = TaskTypeArrayResource;
	public state: ISelectFormControlState<TaskTypeIndexResponse> = {
		formLabel: 'Task Type',
		valueKey: 'taskTypeId',
		labelKey: 'label'
	};
}
