import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	NaHistory,
	TollFreeIndexRequest,
	TollFreeIndexResponse,
	TollFreeProfileRequest
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest,
	handlePostRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchNumbers = async <IncludeFullResponse extends boolean = false>(
	bodyParams: TollFreeIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<TollFreeIndexResponse[], IncludeFullResponse>> => {
	return handlePostRequest(Rt800ApiRoutes.TollFrees.Index, bodyParams, {
		includeFullResponse
	});
};

export const useGetNumbers = (
	bodyParams: TollFreeIndexRequest,
	enabled: boolean = true
) => {
	return useQuery<FullResponse<TollFreeIndexResponse[]>, Error>(
		[`useGetNumbers`],
		() => fetchNumbers(bodyParams, true),
		{ enabled }
	);
};

export const fetchNumberHistory = async (
	urlParams: TollFreeProfileRequest
): Promise<FullResponse<NaHistory[]>> => {
	return handleGetRequest<NaHistory[], true>(
		Rt800ApiRoutes.TollFreeHistory.Index,
		{
			urlParams,
			includeFullResponse: true
		}
	);
};

export const useGetNumberHistory = (
	urlParams: TollFreeProfileRequest,
	enabled: boolean = true
) => {
	return useQuery<FullResponse<NaHistory[]>, Error>(
		[`useGetNumberHistory`],
		() => fetchNumberHistory(urlParams),
		{ enabled }
	);
};
