import { useMemo } from 'react';
import { QueryClient, QueryKey } from 'react-query';
import {
	ensureFullResults,
	PageSizeParams
} from 'RtUi/utils/http/AxiosHttpRequest';

export const useGetFullResults = <T = any,>(
	queryKey: QueryKey,
	pageSizeParams: PageSizeParams,
	callback: (params: PageSizeParams) => Promise<T[]>
) => {
	const queryClient = useMemo(() => new QueryClient(), []);

	return () =>
		queryClient.fetchQuery(
			queryKey,
			() => callback(ensureFullResults(pageSizeParams)),
			{
				staleTime: 30000
			}
		);
};
