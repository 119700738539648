import { AosLbl } from 'Somos/lib/SomosCpr/RtCprV2/AosLbl/AosLbl';
import { AosNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class AosLblLata extends AosLbl {

	public readonly cprNodeTypeId = undefined;
	public readonly aosNodeTypeId = AosNodeType.Lata;

	public readonly valueRegExp = /^\d{3}$/;

	protected readonly possibles = Object.keys(CprLergCache.CprLergByLata);
	protected readonly possiblesByKey = CprLergCache.CprLergByLata;

	protected readonly canadaPossibles = CprLergCache.CanadaLatas;
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaLatasByKey;

	protected readonly canLabel = true;

}
