import {
	DataGridColumn,
	FooterProps
} from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { isNil } from 'lodash-es';

type AverageFooterConfiguration<T> =
	| {
			showFooter?: false;
			duration?: (rows: T[]) => number;
			connections?: (rows: T[]) => number;
	  }
	| {
			showFooter: true;
			duration: (rows: T[]) => number;
			connections: (rows: T[]) => number;
	  };

type AverageCallDurationDataGridColumnConfiguration<T> =
	AverageFooterConfiguration<T> &
		DataGridColumnConfiguration<T> & {
			decimals?: number;
		};

export const AverageCallDurationDataGridColumn = <T = any,>({
	decimals = 1,
	duration,
	connections,
	showFooter = false,
	align = DataGridColumnAlignment.RIGHT,
	...config
}: AverageCallDurationDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: any, isExporting?: boolean) => {
		if (isNil(value)) {
			return '';
		}

		if (isExporting) {
			return Number(value);
		}

		return `${Number(value).toFixed(decimals)}`;
	};

	const getFooter = ({ table }: FooterProps<T>) => {
		if (!(duration && connections)) {
			return '';
		}

		const rows = table
			.getPrePaginationRowModel()
			.rows.map((row) => row.original as T);

		const dividend = duration(rows);
		const divisor = connections(rows);

		const total = dividend / divisor;

		if (isNil(total) || isNaN(total)) {
			return '';
		}

		return <>{`${total.toFixed(decimals)}`}</>;
	};

	return DefaultDataGridColumn({
		...config,
		align,
		exportValue: (value: any) => getValue(value, true),
		getValue: ({ cell }) => getValue(cell.getValue()),
		...(showFooter && {
			getFooter: (props) => getFooter(props)
		})
	});
};
