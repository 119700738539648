/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $Partition = {
    properties: {
        partitionId: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        prefix: {
            type: 'string',
            isRequired: true,
        },
        prefixLong: {
            type: 'string',
            isRequired: true,
        },
        altCommonName: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        inProgress: {
            type: 'number',
            isRequired: true,
        },
        isRt800: {
            type: 'number',
            isRequired: true,
        },
        isRtDid: {
            type: 'number',
            isRequired: true,
        },
        isRtSip: {
            type: 'number',
            isRequired: true,
        },
        isRtLco: {
            type: 'number',
            isRequired: true,
        },
        isRtVue: {
            type: 'number',
            isRequired: true,
        },
        isRtCarrierConnect: {
            type: 'number',
            isRequired: true,
        },
        isRtGuardian: {
            type: 'number',
            isRequired: true,
        },
        isRtLcr: {
            type: 'number',
            isRequired: true,
        },
        inboundPortLimit: {
            type: 'number',
            isRequired: true,
        },
        mediaServerGroupId: {
            type: 'number',
            isRequired: true,
        },
        localNtxCarrierId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        remoteNtxCarrierId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        exchangeRate: {
            type: 'number',
            isRequired: true,
        },
        kubeVersion: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        kubeRoles: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        apiEntityId: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        apiBasePath: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
    },
};