/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NumbersIndexResponseData = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        hour: {
            type: 'string',
            isRequired: true,
        },
        minute: {
            type: 'string',
            isRequired: true,
        },
        number: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        connectionId: {
            type: 'number',
            isRequired: true,
        },
        accountId: {
            type: 'number',
        },
        externalAccountId: {
            type: 'number',
        },
        externalSubAccountId: {
            type: 'number',
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        ingressTrunkGroupId: {
            type: 'string',
            isRequired: true,
        },
        ingressTrunkGroupLabel: {
            type: 'string',
            isRequired: true,
        },
        egressTrunkGroupId: {
            type: 'string',
            isRequired: true,
        },
        egressTrunkGroupLabel: {
            type: 'string',
            isRequired: true,
        },
        dialCode: {
            type: 'number',
            isRequired: true,
        },
        rateCenterId: {
            type: 'number',
            isRequired: true,
        },
        attempts: {
            type: 'number',
            isRequired: true,
        },
        connects: {
            type: 'number',
            isRequired: true,
        },
        asr: {
            type: 'number',
            isRequired: true,
        },
        duration: {
            type: 'number',
            isRequired: true,
        },
        aloc: {
            type: 'number',
            isRequired: true,
        },
        sub6: {
            type: 'number',
            isRequired: true,
        },
        sub18: {
            type: 'number',
            isRequired: true,
        },
        sub30: {
            type: 'number',
            isRequired: true,
        },
        pdd: {
            type: 'number',
            isRequired: true,
        },
        revenue: {
            type: 'number',
            isRequired: true,
        },
        cost: {
            type: 'number',
            isRequired: true,
        },
        margin: {
            type: 'number',
            isRequired: true,
        },
        negCallCount: {
            type: 'number',
            isRequired: true,
        },
        negDuration: {
            type: 'number',
            isRequired: true,
        },
        negRevenue: {
            type: 'number',
            isRequired: true,
        },
        negCost: {
            type: 'number',
            isRequired: true,
        },
        negMargin: {
            type: 'number',
            isRequired: true,
        },
        roboScore: {
            type: 'number',
            isRequired: true,
        },
        fileStreamId: {
            type: 'number',
        },
        ocn: {
            type: 'string',
        },
        ocnType: {
            type: 'string',
        },
        company: {
            type: 'string',
        },
        direction: {
            type: 'string',
        },
    },
};