import {
	ISubscriptionSelectProps,
	SubscriptionSelect
} from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';

interface IVendorOriginationSubscriptionSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends Omit<ISubscriptionSelectProps<IsMulti, IsClearable>, 'serviceIds'> {}

export const VendorOriginationSubscriptionSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>(
	props: IVendorOriginationSubscriptionSelectProps<IsMulti, IsClearable>
): JSX.Element => {
	const serviceIds = [110];

	return SubscriptionSelect({ ...props, serviceIds });
};
