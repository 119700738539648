import { format } from 'date-fns';
import { useState } from 'react';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { Permissions } from 'RtExports/routes';
import { JobStatusId, JobTaskIndexResponse } from 'RtModels';
import { JobTaskEditor } from 'RtUi/app/rtCarrierConnect/Jobs/lib/forms/JobTaskEditor';
import { JobTaskResource } from 'RtUi/app/rtCarrierConnect/Jobs/lib/resources/JobTaskResource';
import { useGetJobsTaskRequests } from 'RtUi/app/rtCarrierConnect/Jobs/lib/services';
import { Aside } from 'RtUi/components/ui/Aside';
import { Loading } from 'RtUi/components/ui/Loading';
import { UserActions } from 'RtUi/state/actions/user';

interface IJobTaskUpdateAsideProps {
	isOpen: boolean;
	jobTask: JobTaskIndexResponse;
	jobTaskResource: JobTaskResource;
	onCancel: () => void;
	onUpdate: () => void;
}

enum TabTypes {
	SUMMARY = 'Summary',
	OBJECTS = 'Request Objects'
}

export const JobTaskUpdateAside = ({
	isOpen,
	jobTask,
	onCancel,
	onUpdate,
	jobTaskResource
}: IJobTaskUpdateAsideProps) => {
	const [activeTab, setActiveTab] = useState(TabTypes.SUMMARY);

	const { data: jobTaskRequests, isFetching: isLoading } =
		useGetJobsTaskRequests(
			{ jobTaskId: jobTask.taskId },
			UserActions.has(Permissions.RtxAdministrator)
		);

	return (
		<Aside isOpen={isOpen} disabledBody>
			<Aside.Header
				warning
				header={
					<>
						<b>Job Task</b>
						{jobTask.taskStatusId ===
							JobStatusId.WAITING_FOR_TASK_MANUAL_COMPLETION && (
							<b>&nbsp;Edit</b>
						)}
					</>
				}
				onClose={() => onCancel()}
			/>
			<Aside.Body>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<Nav
							variant="tabs"
							defaultActiveKey={activeTab}
							onSelect={(tab) => setActiveTab(tab as TabTypes)}
						>
							<Nav.Link eventKey={TabTypes.SUMMARY}>
								{TabTypes.SUMMARY}
							</Nav.Link>
							{jobTaskRequests && (
								<Nav.Link eventKey={TabTypes.OBJECTS}>
									{TabTypes.OBJECTS}
								</Nav.Link>
							)}
						</Nav>
						<Card.Body>
							{activeTab === TabTypes.SUMMARY && (
								<JobTaskEditor
									jobTaskResource={jobTaskResource}
									jobTask={jobTask}
									onCancel={() => onCancel()}
									onUpdate={() => onUpdate()}
								/>
							)}
							{activeTab === TabTypes.OBJECTS && jobTaskRequests && (
								<div className="d-flex flex-column gap-2">
									{[...jobTaskRequests.requestObjects].map((request, index) => (
										<Row key={index}>
											<Col>
												<p className="m-0 mb-2 fw-bold">
													{request.integrationRequestTypeLabel} (
													{format(
														request.updatedTs ?? request.createdTs,
														'yyyy-MM-dd hh:mm:ss aa'
													)}
													)
												</p>
												<ReactJson
													src={request.dto}
													displayDataTypes={false}
													name={false}
													enableClipboard={true}
													collapsed={1}
													collapseStringsAfterLength={30}
												/>
											</Col>
										</Row>
									))}
								</div>
							)}
						</Card.Body>
					</>
				)}
			</Aside.Body>
		</Aside>
	);
};
