/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationConfigInteliquent = {
    properties: {
        trunkGroup: {
            type: 'string',
            isRequired: true,
        },
        routingLabel: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        allowedRespOrgIds: {
            type: 'string',
        },
    },
};