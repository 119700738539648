import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';

// exported definitions
// ======================================================================

export class CprLblDayOfWeek extends CprLbl {

	public readonly cprNodeTypeId = CprNodeType.Date;

	public readonly valueLimit = 255;
	public readonly valueRegExp = /^(SU|M|TU|W|TH|F|SA)|(SU|M|TU|W|TH|F|SA)-(SU|M|TU|W|TH|F|SA)$/;

	/*
		SU = Sunday
		M = Monday
		TU = Tuesday
		W = Wednesday
		TH = Thursday
		F = Friday
		SA = Saturday
		range may be returned in the form: SU-TU
	*/

	public isPossibleValue(rawVal: string) {
		// only validating by valueRegExp
		return true;
	}

}
