import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	RoutingSummaryIndexRequest,
	RoutingSummaryIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchRoutingSummary = async (
	queryParams?: RoutingSummaryIndexRequest
): Promise<FullResponse<RoutingSummaryIndexResponse[]>> => {
	return handleGetRequest<RoutingSummaryIndexResponse[], true>(
		RtxSipApiRoutes.RoutingSummary.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetRoutingSummary = (
	queryParams?: RoutingSummaryIndexRequest
) => {
	return useQuery<FullResponse<RoutingSummaryIndexResponse[]>, Error>(
		[`useGetRoutingSummary`, queryParams],
		() => fetchRoutingSummary(queryParams),
		{ enabled: true }
	);
};
