/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { CicIndexResponse } from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { castArray, isEqual } from 'lodash-es';
import { AccountRouter } from 'RtUi/app/AccountManagement/Accounts/Account.router';

export interface ICicIndexResponse {
	cic: string;
	label?: string;
}

export class CarrierResource extends HttpResource<CicIndexResponse> {
	constructor() {
		super(Rt800ApiRoutes.Cics);
	}
}

interface ICarrierSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<ICicIndexResponse, isMulti> {
	cics?: string[];
	cicsToHighlight?: string[];
	useTextDisplayMode?: boolean;
}

export class CarrierSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	ICicIndexResponse,
	isMulti,
	ICarrierSelectProps<isMulti>
> {
	public static defaultProps = {
		router: AccountRouter
	};

	public resourceClass = CarrierResource;
	public state: ISelectFormControlState<ICicIndexResponse> = {
		formLabel: 'Carriers',
		valueKey: 'cic',
		labelKey: 'cic',
		isInvalid: (cicIndex) => this.isInvalid(cicIndex),
		getLabelCssProperties: (cicIndex) => this.getLabelCssProperties(cicIndex)
	};

	public componentDidMount() {
		this.updateCics();

		super.componentDidMount();
	}

	public componentDidUpdate(prevProps: ICarrierSelectProps<isMulti>) {
		if (!isEqual(prevProps.cics, this.props.cics)) {
			this.updateCics();
			this.reloadOptionsOnFocus();
		}

		super.componentDidUpdate(prevProps);
	}

	public updateCics() {
		const { cics } = this.props;

		if (cics) {
			const limitedCics: ICicIndexResponse[] = cics.map((cic) => ({
				cic
			}));

			this.resource = new ArrayResource<ICicIndexResponse>('cic', limitedCics);
		}
	}

	public isInvalid(cicIndex: ICicIndexResponse) {
		if (typeof cicIndex.cic === 'undefined') {
			return false;
		}

		const { cics } = this.props;
		//const lergDictionary = LergDictionary.getInstance();
		let isValid = true;

		if (cics) {
			isValid = cics.includes(cicIndex.cic);
		} else {
			//isValid = lergDictionary.validateCarrier(cicIndex.cic);
		}

		return !isValid;
	}

	public getLabelCssProperties(cicIndex: ICicIndexResponse) {
		const { cicsToHighlight } = this.props;
		const styles: React.CSSProperties = {};

		if (typeof cicIndex.cic === 'undefined') {
			return styles;
		}

		if (cicsToHighlight && cicsToHighlight.includes(cicIndex.cic)) {
			styles.background = '#17a2b8';
			styles.color = '#FFF';
		}

		return styles;
	}

	public render() {
		const { displayMode, useTextDisplayMode, value } = this.props;

		if (displayMode && useTextDisplayMode) {
			const values = value ? castArray(value) : [];
			const valuesLength = values.length;

			return values.map((option, index) => {
				const isInvalid = this.isInvalid(option);
				const isLastElement = index === valuesLength - 1;
				const className = isInvalid ? 'text-danger' : '';
				let cssStyles: React.CSSProperties | undefined;

				if (!isInvalid) {
					cssStyles = this.getLabelCssProperties(option);
					cssStyles.paddingRight = '1em';
					cssStyles.paddingLeft = '1em';
				}

				return (
					<span
						className={className}
						style={cssStyles}
						key={option.cic + index}
					>
						{option.label ?? option.cic}
						{!isLastElement && ','}
					</span>
				);
			});
		}

		return super.render();
	}
}
