import * as React from 'react';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import {
	IRtUiFormContextData,
	RtUiFormContext
} from 'RtUi/components/ui/RtUiForm/RtUiFormContext';
import { RtError } from 'RtUi/utils/errors/RtError';
import { cloneDeep, isEqual } from 'lodash-es';

export type IsMultiFormValue<
	T,
	isMulti extends boolean = false
> = isMulti extends true ? T[] : T;

export interface IFormControlProps<T, isMulti extends boolean = false> {
	displayMode?: boolean;
	className?: string;
	subLabel?: string;
	name?: string;
	required?: boolean;
	disabled?: boolean;
	value?: IsMultiFormValue<T, isMulti> | undefined;
	hideLabel?: boolean;
	hideFormGroup?: boolean;
	onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
	onChange?: (value: IsMultiFormValue<T, isMulti>) => void;
	onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
	label?: string;
	id?: string;
}

export interface IFormControlState {
	formLabel: string;
	getAllParams?: any;
}

export interface IFormControlIndexCache<T> {
	resourceName: string;
	getAllParams: any;
	records: Promise<T[]>;
}

export abstract class FormControl<
	T,
	isMulti extends boolean = false,
	P extends IFormControlProps<T, isMulti> = IFormControlProps<T, isMulti>,
	S extends IFormControlState = IFormControlState
> extends React.Component<P, S> {
	public abstract resource?: HttpResource<T>;
	public abstract state: S;

	public static contextType = RtUiFormContext;

	/**
	 * IndexCache methods allow for form controls with different resources to
	 * share index results for a short 1 second time span. This way, if there
	 * are 12 select controls of the same resource type all doing an index
	 * request with the same params, they can share the same request instead
	 * of sending 12 requests to API
	 */
	private static IndexCache: Array<IFormControlIndexCache<any>> = [];

	/**
	 * Sets an Index cache to be used for one second.
	 * Caches will be set based on resourceName and index params.
	 * ArrayResource classes will not be cached.
	 *
	 * @param resourceName
	 * @param getAllParams
	 * @param records
	 */
	private static SetIndexCache<T>(
		resource: HttpResource<T>,
		getAllParams: any,
		records: Promise<T[]>
	) {
		const resourceName = resource.constructor.name;
		const indexCache: IFormControlIndexCache<T> = {
			resourceName,
			getAllParams,
			records
		};

		// Do not cache Array Resource results
		if (resource instanceof ArrayResource) {
			return;
		}

		FormControl.IndexCache.push(indexCache);

		const oneSecondsInMs = 1 * 1000;
		const cacheTimeoutInMs = oneSecondsInMs;

		//Remove cache after 1 second
		setTimeout(() => {
			const indexOfCache = FormControl.IndexCache.findIndex(
				(ic) => ic === indexCache
			);

			if (indexOfCache >= 0) {
				FormControl.IndexCache.splice(indexOfCache, 1);
			}
		}, cacheTimeoutInMs);
	}

	/**
	 * Get IndexCache if exists
	 * @param resource
	 * @param getAllParams
	 */
	private static GetIndexCache<T>(
		resource: HttpResource<T>,
		getAllParams: any
	): Promise<T[]> | undefined {
		const resourceName = resource.constructor.name;
		const possibleCacheIndex = FormControl.IndexCache.findIndex(
			(indexCache) => indexCache.resourceName === resourceName
		);

		if (possibleCacheIndex >= 0) {
			const possibleCache = FormControl.IndexCache[possibleCacheIndex];

			if (isEqual(getAllParams, possibleCache.getAllParams)) {
				return possibleCache.records;
			}
		}

		return;
	}

	//@ts-ignore
	public context!: IRtUiFormContextData | undefined;
	private previousContext: IRtUiFormContextData | undefined;
	private currentErrors: string[] | undefined;

	public componentDidMount() {
		this.updateLabelIfRequired();
	}

	public componentDidUpdate(prevProps: Readonly<P>) {
		this.updateLabelIfRequired();
		this.updateCurrentFormError();
	}

	/**
	 * Get form error from RtUiFormContext
	 */
	public getCurrentFormErrors() {
		return this.currentErrors;
	}

	/**
	 * Clear form error from RtUiFormContext
	 */
	public clearCurrentFormErrors() {
		this.currentErrors = undefined;
	}

	public updateLabelIfRequired() {
		const { label } = this.props;
		const { formLabel } = this.state;

		if (typeof label === 'string' && label !== formLabel) {
			this.setState({ formLabel: label });
		}
	}

	/**
	 * Gets Data based on Props from Resource
	 */
	public getData() {
		return new Promise<T[]>((resolve) => {
			this.setState((state) => {
				if (this.resource) {
					const { getAllParams = {} } = state;
					const indexCache = FormControl.GetIndexCache<T>(
						this.resource,
						getAllParams
					);
					if (indexCache) {
						resolve(indexCache);
						return null;
					}

					const getAllPromise = this.resource.getAll(getAllParams, true);
					FormControl.SetIndexCache(this.resource, getAllParams, getAllPromise);

					resolve(getAllPromise);

					return null;
				}

				throw new Error('No Resource Specified for this FormControl');
			});
		});
	}

	/**
	 * Send onChange event to consumer
	 */
	public triggerOnChange(value: isMulti extends true ? T[] : T) {
		const onChangeKludge: any = this.props.onChange; //TODO

		if (typeof onChangeKludge === 'function') {
			onChangeKludge(value);
		}

		//clear form error
		this.clearCurrentFormErrors();
	}

	public abstract render(): React.ReactNode;

	/**
	 * Update currentError given the RtUiFormContext. This should be invoked
	 * every componentDidUpdate to make sure new errors have not been created.
	 */
	private updateCurrentFormError() {
		const propName: string | undefined = this.props.name;

		if (!propName || isEqual(this.context, this.previousContext)) {
			return;
		}

		this.previousContext = cloneDeep(this.context);

		const validationError = RtError.GetErrorForProperty(
			this.context?.error,
			propName
		);

		if (!validationError) {
			return;
		}

		this.currentErrors = Object.values(validationError.constraints);
		// setting class field do not cause component rerender.
		// Other option can be to keep errors in state
		this.forceUpdate();
	}
}
