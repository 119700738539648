/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionIndexRequest = {
    properties: {
        search: {
            type: 'string',
        },
        isActive: {
            type: 'number',
        },
        serviceId: {
            type: 'CallflowService',
        },
        serviceIds: {
            type: 'Array',
        },
        accountId: {
            type: 'number',
        },
        resourceId: {
            type: 'number',
        },
        externalAccountId: {
            type: 'string',
        },
        ratePlanId: {
            type: 'number',
        },
        isCustomer: {
            type: 'number',
        },
    },
};