/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CountryCodeIndexResponse = {
    properties: {
        countryCode: {
            type: 'string',
            isRequired: true,
        },
        iso3166Alpha3: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
        },
    },
};