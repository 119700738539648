// eslint-disable-next-line max-classes-per-file
import * as moment from 'moment-timezone';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { Badge } from 'react-bootstrap';
import { FormatOptionLabelContext } from 'react-select';

export interface ITimeZoneResourceOption {
	timeZone: string;
	offset: number;
	isCurrent: boolean;
}

class TimeZoneResource extends ArrayResource<ITimeZoneResourceOption> {
	constructor() {
		super('timeZone');

		const options: ITimeZoneResourceOption[] = [];
		const usersTimeZone = moment.tz.guess();

		for (const timeZone of moment.tz.names()) {
			const offset = moment.tz(timeZone).utcOffset();

			options.push({
				timeZone,
				offset,
				isCurrent: usersTimeZone === timeZone
			});
		}

		options.sort((r1, r2) => {
			if (r1.isCurrent) {
				return -1;
			} else if (r2.isCurrent) {
				return +1;
			}

			return r1.offset - r2.offset;
		});

		this.setAllData(options);
	}
}

interface ITimeZoneSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<ITimeZoneResourceOption, IsMulti> {}
interface ITimeZoneSelectState
	extends ISelectFormControlState<ITimeZoneResourceOption> {}

export class TimeZoneSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	ITimeZoneResourceOption,
	IsMulti,
	ITimeZoneSelectProps<IsMulti>,
	ITimeZoneSelectState
> {
	public resourceClass = TimeZoneResource;
	public state: ITimeZoneSelectState = {
		formLabel: 'Time Zone',
		valueKey: 'timeZone',
		labelKey: 'timeZone'
	};

	public optionRenderer = (
		record: ITimeZoneResourceOption,
		context: FormatOptionLabelContext
	) => {
		const offsetInHours = record.offset === 0 ? 0 : record.offset / 60;

		const timeZoneComp = (
			<section className="d-flex justify-content-between">
				<span className="me-2">{record.timeZone}</span>
				{record.isCurrent && context === 'menu' && (
					<Badge bg="light" text="dark" className="ms-2">
						Current
					</Badge>
				)}
				<span>
					{offsetInHours > 0 ? '+' : ''}
					{offsetInHours.toString()}:00 GMT
				</span>
			</section>
		);

		return timeZoneComp;
	};
}
