import {
	LcrCarrierIndexResponse,
	LcrCarrierRateIndexResponse,
	LcrCarrierStateProfileResponse
} from 'RtModels';
import { ILcrCarrierRateDtoExtended } from 'RtUi/app/rtLco/Carriers/lib/bin/CarrierRatesSpreadsheetParser';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { LcrCarriersDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LcrCarriersDataGridColumn';
import { NanpRegionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/NanpRegionDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getCarrierColumns = (): Array<
	DataGridColumn<LcrCarrierIndexResponse>
> => [
	LcrCarriersDataGridColumn({
		header: 'LCR Account',
		accessorKey: 'lcrCarrierId'
	}),
	...RespOrgColumnsBuilder<LcrCarrierIndexResponse>({
		header: 'RespOrg',
		accessorKey: 'respOrgId'
	}),
	DefaultDataGridColumn({
		header: 'CIC',
		accessorKey: 'cic'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	TimeStampDataGridColumn({
		header: 'Last Rates Upload',
		accessorKey: 'lastRatesUploadTs'
	}),
	BooleanDataGridColumn({
		header: 'Allow As Default',
		accessorKey: 'allowAsDefault'
	}),
	BooleanDataGridColumn({
		header: 'Allow As Canada',
		accessorKey: 'allowAsCanada'
	}),
	RateDataGridColumn({
		header: 'Inter. Rate',
		accessorKey: 'defaultInterRate'
	}),
	RateDataGridColumn({
		header: 'Intra. Rate',
		accessorKey: 'defaultIntraRate'
	}),
	RateDataGridColumn({
		header: 'Indet. Rate',
		accessorKey: 'defaultIndetRate'
	}),
	DefaultDataGridColumn({
		header: 'RateSheet Type',
		accessorKey: 'rateSheetTypeId'
	}),
	TimeStampDataGridColumn({
		header: 'Created At',
		accessorKey: 'createdAt'
	}),
	TimeStampDataGridColumn({
		header: 'Updated At',
		accessorKey: 'updatedAt'
	})
];

export const getCarrierRatingSetupColumns = (): Array<
	DataGridColumn<LcrCarrierStateProfileResponse>
> => [
	NanpRegionDataGridColumn({
		accessorKey: 'nanpRegionId',
		header: 'Region'
	}),
	NanpRegionDataGridColumn({
		accessorKey: 'nanpStateId',
		header: 'State'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	BooleanDataGridColumn({
		accessorKey: 'allowAsDefault',
		header: 'Use Default Rates'
	}),
	RateDataGridColumn({
		accessorKey: 'defaultInterRate',
		header: 'Inter. Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'defaultIntraRate',
		header: 'Intra. Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'defaultIndetRate',
		header: 'Indet. Rate'
	})
];

export const getCarrierRatesColumns = (
	rateKeyHeader1: string,
	rateKeyHeader2: string
): Array<DataGridColumn<LcrCarrierRateIndexResponse>> => [
	DefaultDataGridColumn({
		accessorKey: 'rateKey',
		header: 'Rate Key'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateKey1',
		header: rateKeyHeader1
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateKey2',
		header: rateKeyHeader2
	}),
	RateDataGridColumn({
		accessorKey: 'interRate',
		header: 'Inter. Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'intraRate',
		header: 'Intra. Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'indetRate',
		header: 'Indet. Rate'
	})
];

export const getCarrierRatesDtoColumns = (
	rateKeyHeader1: string,
	rateKeyHeader2: string
): Array<DataGridColumn<ILcrCarrierRateDtoExtended>> => [
	DefaultDataGridColumn({
		accessorKey: 'rateKey1',
		header: rateKeyHeader1
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateKey2',
		header: rateKeyHeader2
	}),
	RateDataGridColumn({
		accessorKey: 'interRate',
		header: 'Inter. Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'intraRate',
		header: 'Intra. Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'indetRate',
		header: 'Indet. Rate'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	})
];
