import { SipAgentConfigEditor } from 'RtUi/app/AccountManagement/SipAgents/lib/forms/SipAgentConfigEditor';
import { useGetSipAgentConfig } from 'RtUi/app/AccountManagement/SipAgents/lib/services';
import { SipAgentConfigRouter } from 'RtUi/app/AccountManagement/SipAgents/SipAgentConfig.router';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';

export const SipAgentConfigProfileContainer = (): JSX.Element => {
	const entityId = useIdParam();
	const { data, isLoading, refetch } = useGetSipAgentConfig(Number(entityId));

	if (isLoading) {
		return <Loading />;
	}

	if (!data) {
		return <p>Something went wrong!</p>;
	}

	return (
		<StandardLayout profile={data} router={SipAgentConfigRouter}>
			<SipAgentConfigEditor value={data} onSuccess={() => refetch()} />
		</StandardLayout>
	);
};

SipAgentConfigProfileContainer.displayName = 'SipAgentConfigProfileContainer';

SipAgentConfigRouter.setProfileRtUiFunctionalComponent(
	SipAgentConfigProfileContainer
);
