import { SipAgentConfigIndexResponse, SipAgentIndexResponse } from 'RtModels';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { FqdnDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FqdnDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';
import { PortDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PortDataGridColumn';
import { SipAgentMedSourceDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SipAgentMedSourceDataGridColumn';
import { SipAgentMedValueDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SipAgentMedValueDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';

export const getSipAgentsColumns = (
	onDeleteHandler: (row: SipAgentIndexResponse) => void
): Array<DataGridColumn<SipAgentIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => onDeleteHandler(row)
	}),
	IdDataGridColumn({
		accessorKey: 'sipAgentId'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	FqdnDataGridColumn({
		accessorKey: 'fqdn'
	}),
	PortDataGridColumn({
		accessorKey: 'port'
	})
];

export const getSipAgentConfigsColumns = (
	onDeleteHandler: (row: SipAgentConfigIndexResponse) => void
): Array<DataGridColumn<SipAgentConfigIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => onDeleteHandler(row)
	}),
	IdDataGridColumn({
		accessorKey: 'sipAgentConfigId'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	SipAgentMedSourceDataGridColumn({
		header: 'Mediation Source',
		accessorKey: 'sipAgentConfig',
		id: 'medSources'
	}),
	SipAgentMedValueDataGridColumn({
		header: 'Mediation Value',
		accessorKey: 'sipAgentConfig',
		id: 'medValues'
	})
];
