import { get } from 'lodash-es';
import { useMemo } from 'react';
import { DownloadTypes } from 'RtModels';
import { IsMultiFormValue } from 'RtUi/components/form/FormControl';
import { ISelectFormControlProps } from 'RtUi/components/form/SelectFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface IDownloadOptionSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<DownloadTypes, IsMulti> {
	value?: IsMultiFormValue<DownloadTypes, IsMulti>;
	onChange?: (newValue: IsMultiFormValue<DownloadTypes, IsMulti>) => void;
	label?: string;
	required?: boolean;
	isClearable?: boolean;
}

export const DownloadOptionSelect = <IsMulti extends boolean = false>({
	value,
	onChange = () => {},
	required,
	isClearable = true,
	displayMode,
	label = 'Download Option',
	initialOptionId,
	multi
}: IDownloadOptionSelectProps<IsMulti>): JSX.Element => {
	const excludedDownloadTypes: DownloadTypes[] = useMemo(
		() => [DownloadTypes.DigitalOcean],
		[]
	);

	const options: Array<ISimpleSelectFormControlOption<DownloadTypes>> =
		useMemo(() => {
			return Object.keys(DownloadTypes)
				.filter(
					(key) => !excludedDownloadTypes.includes(get(DownloadTypes, key))
				)
				.map((key) => ({
					label: key,
					value: get(DownloadTypes, key)
				}));
		}, [excludedDownloadTypes]);

	const selectValue = useMemo(() => {
		return multi
			? options.filter((opt) => (value as DownloadTypes[])?.includes(opt.value))
			: options.find((opt) => opt.value === value);
	}, [value, options, multi]);

	const initialOptions = useMemo(
		() =>
			multi
				? (initialOptionId as string[]).map((val) => val.toString())
				: initialOptionId?.toString(),
		[initialOptionId, multi]
	);

	const onChangeHandler = (
		newValue: IsMulti extends true
			? Array<ISimpleSelectFormControlOption<DownloadTypes>>
			: ISimpleSelectFormControlOption<DownloadTypes>
	) => {
		const val = multi
			? (newValue as Array<ISimpleSelectFormControlOption<DownloadTypes>>)?.map(
					(v) => v.value
				)
			: (newValue as ISimpleSelectFormControlOption<DownloadTypes>)?.value;

		onChange(val as any);
	};

	return (
		<SimpleSelectFormControl<DownloadTypes, IsMulti>
			label={label}
			multi={multi}
			clearable={isClearable}
			required={required}
			displayMode={displayMode}
			value={selectValue as any}
			onChange={(val: any) => {
				onChangeHandler(val);
			}}
			options={options}
			initialOptionId={initialOptions as any}
		/>
	);
};
