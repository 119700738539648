import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	OriginationNumberIndexResponse,
	OriginationNumberProfileResponse
} from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import {
	RtUiRouter,
	SearchConfidenceLevel
} from 'RtUi/components/containers/lib/RtUiRouter';
import { ScenarioRenderCache } from 'RtUi/components/data/DataGrid/columns/ScenarioDataGridColumn';
import { SubscriptionDataGridCache } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';
import * as React from 'react';
import { OriginationNumberResource } from './lib/resources/OriginationNumberResource';

interface IServiceNumberContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Tags: TabbedLayoutTabProps;
}

export const ServiceNumberContainerTabs: IServiceNumberContainerTabs = {
	Profile: { header: 'Profile' },
	Tags: { header: 'Tags' }
};

const SEARCH_REGEX = /^1?(\d{9,})/g;

class ServiceNumbersRouterClass extends SipApplicationRouter<
	OriginationNumberIndexResponse,
	OriginationNumberProfileResponse,
	IServiceNumberContainerTabs
> {
	public phoneFormatter = new PhoneNumberFormatter();

	constructor() {
		super(
			'Service Number',
			'serviceNumbers',
			'serviceNumberId',
			ServiceNumberContainerTabs
		);

		ScenarioRenderCache.warmup();
		SubscriptionDataGridCache.warmup();
		this.setProfilePermissions(
			RtxSipApiRoutes.ServiceNumbers.Profile.permissions
		);
		this.setIndexPermissions(RtxSipApiRoutes.ServiceNumbers.Index.permissions);
	}

	/**
	 * @override
	 */
	public getIndexLabel(record: OriginationNumberIndexResponse) {
		const formattedServiceNumber = this.phoneFormatter.toInternational(
			record.serviceNumber
		);

		if (formattedServiceNumber) {
			return formattedServiceNumber;
		}

		return record.serviceNumber;
	}

	/**
	 * @override
	 * @param record
	 * @param externalRouters
	 */
	public getSummaryComponentFor(
		record: OriginationNumberIndexResponse,
		externalRouters: RtUiRouter[]
	): React.ReactNode {
		const label = <>{this.getIndexLabel(record)}</>;
		const badges: Array<string | Promise<string>> = [];

		if (record.translatedNumber) {
			const formattedTranslateNumber = this.phoneFormatter.toInternational(
				record.translatedNumber
			);

			if (formattedTranslateNumber) {
				badges.push(formattedTranslateNumber);
			} else {
				badges.push(record.translatedNumber);
			}
		}

		if (record.subscriptionId) {
			const subscriptionPromise = Promise.resolve(
				SubscriptionDataGridCache.getLabelFor(record.subscriptionId)
			);

			badges.push(subscriptionPromise);
		}

		if (record.scenarioId) {
			const scenarioPromise = Promise.resolve(
				ScenarioRenderCache.getLabelFor(record.scenarioId)
			);

			badges.push(scenarioPromise);
		}

		return this.createSummaryComponentFor(
			record,
			label,
			badges,
			externalRouters
		);
	}

	public globalSearch(search: string) {
		const term = PhoneNumberFormatter.convertLettersToNumbers(search).replace(
			/\D/g,
			''
		);

		if (!term.match(SEARCH_REGEX)) {
			return Promise.resolve([]);
		}

		return this.defaultGlobalSearch(
			OriginationNumberResource,
			term.replace(SEARCH_REGEX, '1$1'),
			9
		);
	}

	public globalSearchConfidenceCheck() {
		return SearchConfidenceLevel.Possible;
	}

	public getProfileLabel(profile: OriginationNumberProfileResponse) {
		const formattedServiceNumber =
			this.phoneFormatter.toInternational(profile.serviceNumber) ||
			profile.serviceNumber;

		return formattedServiceNumber;
	}
}

export const ServiceNumberRouter = new ServiceNumbersRouterClass();
