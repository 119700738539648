import { FC } from 'react';
import { GuardianReconciliationMappingRecurringScheduleType } from 'RtModels';
import {
	ISimpleSelectFormControlProps,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

export const GuardianReconciliationMappingRecurringTypeSelect: FC<
	React.PropsWithChildren<
		Omit<
			ISimpleSelectFormControlProps<
				GuardianReconciliationMappingRecurringScheduleType,
				false
			>,
			'options'
		>
	>
> = (props) => (
	<SimpleSelectFormControl<GuardianReconciliationMappingRecurringScheduleType>
		{...props}
		options={[
			{
				value: GuardianReconciliationMappingRecurringScheduleType.Weekly,
				label: 'Weekly'
			},
			{
				value: GuardianReconciliationMappingRecurringScheduleType.Monthly,
				label: 'Monthly'
			},
			{
				value: GuardianReconciliationMappingRecurringScheduleType.BiMonthly,
				label: 'Bimonthly'
			}
		]}
	/>
);
