import * as React from 'react';
import { LcrRateSheetType } from 'RtModels';
import {
	CarrierRateSheetTerminalErrorTypes,
	CarrierRatesSpreadsheetParser,
	ICarrierRatesSheetDryRunReport,
	ILcrCarrierRateDtoExtended
} from 'RtUi/app/rtLco/Carriers/lib/bin/CarrierRatesSpreadsheetParser';
import { ICarrierRatesParseConfig } from 'RtUi/app/rtLco/Carriers/lib/bin/interfaces';
import { LcrCarrierRatesDtoGrid } from 'RtUi/app/rtLco/Carriers/lib/grids/LcrCarrierRatesDtoGrid';
import { LcrCarriersResource } from 'RtUi/app/rtLco/Carriers/lib/resources/LcrCarriersResource';
import { FancyFormCheckbox } from 'RtUi/components/form/FancyFormCheckbox';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtError } from 'RtUi/utils/errors/RtError';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import {
	Alert,
	Card,
	Col,
	Nav,
	Row,
	Button,
	Form,
	ListGroup,
	Table,
	Modal
} from 'react-bootstrap';

interface IUploadCarrierRatesFormProps {
	lcrCarrierId: number;
	onUpdate?: (newRateSheetTypeId: LcrRateSheetType) => void;
}

interface ISpreadsheetLogEntry {
	message: string;
	type: 'error' | 'info' | 'debug';
}

interface IUploadCarrierRatesFormState {
	error?: any;
	spreadsheetLogEntries: ISpreadsheetLogEntry[];
	showVerbose: boolean;
	autofillIntraWithBestMatch: boolean;
	autofillIndetWithBestMatch: boolean;
	autoFillOcn: boolean;
	isSubmitting: boolean;
	oneRatePerRateSummary: boolean;
	allowMultipleRatesPerRateCenter: boolean;
	ratesFile: File | undefined;
	processingRatesFile: boolean;
	disableWizardActions: boolean;
	isSamplesModalOpen: boolean;
	wizardStep: number;
	rates: ILcrCarrierRateDtoExtended[];
	ratesSpreadsheet: string[][];
	dryRunReport?: ICarrierRatesSheetDryRunReport;
	step1Errors?: RtError;
	detectedSpreadsheetType: LcrRateSheetType | undefined;
	rateSheetType: ICarrierRatesParseConfig['rateSheetType'];
	rateSheetTypeId: LcrRateSheetType | undefined;
}

export class UploadCarrierRatesForm extends React.Component<
	IUploadCarrierRatesFormProps,
	IUploadCarrierRatesFormState
> {
	public state: IUploadCarrierRatesFormState = {
		error: undefined,
		spreadsheetLogEntries: [],
		isSubmitting: false,
		autofillIntraWithBestMatch: true,
		autofillIndetWithBestMatch: true,
		showVerbose: false,
		autoFillOcn: true,
		oneRatePerRateSummary: false,
		allowMultipleRatesPerRateCenter: false,
		ratesFile: undefined,
		processingRatesFile: false,
		isSamplesModalOpen: false,
		wizardStep: 1,
		disableWizardActions: true,
		rates: [],
		step1Errors: undefined,
		detectedSpreadsheetType: undefined,
		ratesSpreadsheet: [],
		rateSheetType: 'auto',
		rateSheetTypeId: undefined
	};

	public lcrCarriersResource = new LcrCarriersResource();
	public carrierRatesSpreadsheetParser!: CarrierRatesSpreadsheetParser;
	public fileUtils = new FileUtils();

	private readonly npaNxxSampleData: string[][] = [
		['201', '200', '0.014740', '0.014740', '0.014740'],
		['201', '200', '0.017110', '0.017110', '0.017110'],
		['201', '200', '0.008610', '0.008610', '0.008610']
	];

	private readonly lataOcnSampleData: string[][] = [
		['120', '0003', '0.042914', '0.042914', '0.042914'],
		['120', '0004', '0.087858', '0.087858', '0.087858'],
		['120', '0010', '0.027377', '0.027377', '0.027377']
	];

	public toggleIsSamplesModalOpen(
		isSamplesModalOpen = !this.state.isSamplesModalOpen
	) {
		this.setState({ isSamplesModalOpen });
	}

	public pushSpreadsheetMessage(
		type: ISpreadsheetLogEntry['type'],
		message: string
	) {
		const { showVerbose } = this.state;

		if (type === 'debug' && !showVerbose) {
			return;
		}

		this.setState(({ spreadsheetLogEntries }) => {
			//spreadsheetLogEntries.splice(0, 0, { message, type });
			spreadsheetLogEntries.push({ message, type });

			return { spreadsheetLogEntries };
		});
	}

	public submitStep2(e: React.FormEvent<HTMLFormElement>) {
		//const { lcrCarrierId } = this.props;
		const { ratesFile } = this.state;

		e.preventDefault();

		if (!ratesFile) {
			return;
		}

		this.setState({
			error: undefined,
			spreadsheetLogEntries: [],
			isSubmitting: true
		});

		this.pushSpreadsheetMessage('debug', 'Downloading Spreadsheet...');

		this.fileUtils
			.spreadsheetFileToJson(ratesFile)
			.then((spreadsheet) => {
				this.pushSpreadsheetMessage(
					'debug',
					'Spreadsheet Successfully Downloaded.'
				);

				let hasErrors = false;
				const {
					autofillIntraWithBestMatch,
					autofillIndetWithBestMatch,
					autoFillOcn,
					rateSheetType,
					oneRatePerRateSummary
				} = this.state;
				this.carrierRatesSpreadsheetParser = new CarrierRatesSpreadsheetParser(
					spreadsheet,
					{
						autofillIntraWithBestMatch,
						autofillIndetWithBestMatch,
						autoFillOcn,
						rateSheetType,
						oneRatePerRateSummary
					}
				);

				this.carrierRatesSpreadsheetParser.on('error', (eventMessage) => {
					hasErrors = true;
					this.pushSpreadsheetMessage('error', eventMessage);
				});

				this.carrierRatesSpreadsheetParser.on('info', (eventMessage) =>
					this.pushSpreadsheetMessage('info', eventMessage)
				);
				this.carrierRatesSpreadsheetParser.on('debug', (eventMessage) =>
					this.pushSpreadsheetMessage('debug', eventMessage)
				);

				const rateSheetTypeId =
					this.carrierRatesSpreadsheetParser.assertRateSheetType();
				const rates = this.carrierRatesSpreadsheetParser.getCarrierRates();

				this.carrierRatesSpreadsheetParser.removeAllListeners();

				if (hasErrors || !rateSheetTypeId) {
					throw []; //throw empty array to state.errors
				} else {
					this.setState({ spreadsheetLogEntries: [] });
				}

				this.setState({
					rates,
					rateSheetTypeId,
					wizardStep: 3,
					isSubmitting: false
				});
			})
			.catch((error) => this.setState({ error }))
			.finally(() => this.setState({ isSubmitting: false }));
	}

	public uploadCarrierRates = async (e: React.FormEvent<HTMLFormElement>) => {
		const { lcrCarrierId } = this.props;
		const { rateSheetTypeId, rates } = this.state;

		e.preventDefault();

		if (!rateSheetTypeId) {
			return Promise.reject();
		}

		this.pushSpreadsheetMessage('info', 'Uploading New Rates...');
		this.setState({ isSubmitting: true, error: undefined });

		try {
			const { onUpdate = () => ({}) } = this.props;
			await this.lcrCarriersResource.updateCarrierRates(
				lcrCarrierId,
				rateSheetTypeId,
				rates
			);
			this.setState({ isSubmitting: false }, () => {
				onUpdate(rateSheetTypeId);
			});
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	};

	public renderSampleTable(
		rateSheetName: 'NPA/NXX' | 'LATA/OCN',
		sampleData: string[][]
	) {
		return (
			<CollapsibleCard
				header={`Example of ${rateSheetName} Spreadsheet`}
				className="mb-3"
			>
				<Table size="sm" bordered className="bg-white">
					<thead>
						<tr>
							{rateSheetName === 'NPA/NXX' && (
								<>
									<th>NPA</th>
									<th>NXX</th>
								</>
							)}
							{rateSheetName === 'LATA/OCN' && (
								<>
									<th>LATA</th>
									<th>OCN</th>
								</>
							)}
							<th>Inter. Rate</th>
							<th>Intra. Rate</th>
							<th>Indet. Rate</th>
						</tr>
					</thead>
					<tbody>
						{sampleData.map((values, index) => (
							<tr key={index}>
								{values.map((value, innerIndex) => (
									<td className="text-monospace" key={innerIndex}>
										{value}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</CollapsibleCard>
		);
	}

	public submitStep1(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		this.setState({ wizardStep: 2 });
	}

	public async setRatesFile(ratesFile: File) {
		this.pushSpreadsheetMessage('debug', 'Downloading Spreadsheet...');

		this.setState({
			step1Errors: undefined,
			dryRunReport: undefined,
			ratesFile: undefined,
			disableWizardActions: true,
			ratesSpreadsheet: [],
			detectedSpreadsheetType: undefined
		});
		this.setState({ processingRatesFile: true });

		try {
			const ratesSpreadsheet =
				await this.fileUtils.spreadsheetFileToJson(ratesFile);
			const dryRunReport =
				CarrierRatesSpreadsheetParser.GetDryRunReport(ratesSpreadsheet);
			const step1TerminalErrors: string[] = [];

			for (const terminalErrorKey in dryRunReport.terminalErrors) {
				if (terminalErrorKey in dryRunReport.terminalErrors) {
					const errors =
						dryRunReport.terminalErrors[
							terminalErrorKey as CarrierRateSheetTerminalErrorTypes
						];

					if (errors) {
						step1TerminalErrors.push(...errors);
					}
				}
			}

			if (step1TerminalErrors.length > 0) {
				throw new RtError({ messages: step1TerminalErrors });
			}

			this.setState({
				disableWizardActions: false,
				ratesFile,
				ratesSpreadsheet,
				dryRunReport
			});
		} catch (step1Errors: any) {
			this.setState({ step1Errors });
		} finally {
			this.setState({ processingRatesFile: false });
		}
	}

	public renderStep1() {
		return (
			<Form onSubmit={(e) => this.submitStep1(e)}>
				<a
					className="px-4 pt-4 d-flex justify-content-end"
					onClick={() => this.toggleIsSamplesModalOpen(true)}
				>
					<small className="text-muted">
						<i className="fas fa-fw fa-info-circle" />
						<u>Example Files</u>
					</small>
				</a>
				<DragAndDropFileUploader
					processing={this.state.processingRatesFile}
					accept={FileUtils.AcceptTypes.Spreadsheet}
					onDrop={(ratesFile) => this.setRatesFile(ratesFile)}
					value={this.state.ratesFile}
				/>
				<FormErrors error={this.state.step1Errors} className="p-4" />
				{this.renderWizardSteps()}
			</Form>
		);
	}

	public renderStep2() {
		const { dryRunReport } = this.state;

		if (!dryRunReport) {
			return null;
		}

		const hasNonTerminalErrors =
			Object.keys(dryRunReport.nonTerminalErrors).length > 0;
		const {
			OcnNotFilled = [],
			NoIntraFound = [],
			NoIndetFound = [],
			OneRatePerRateSummary = []
		} = dryRunReport.nonTerminalErrors;

		return (
			<Form onSubmit={(e) => this.submitStep2(e)}>
				{hasNonTerminalErrors && (
					<Alert
						variant="warning"
						className="mb-0 rounded-0 justify-content-start align-items-center"
					>
						<i className="fas fa-fw fa-exclamation-triangle me-2" />
						<span>
							The following are required to be resolved before continuing:
						</span>
					</Alert>
				)}
				<Card.Body>
					{!hasNonTerminalErrors && (
						<Alert
							variant="success"
							className="mb-0 justify-content-start align-items-center"
						>
							<i className="fas fa-fw fa-info-circle me-2" />
							<span>
								There is nothing to review for this rate sheet! Please click
								Continue.
							</span>
						</Alert>
					)}
					<Row>
						{OcnNotFilled.length > 0 && (
							<Col xl={6} className="mb-3">
								<FancyFormCheckbox
									required
									header={`${OcnNotFilled.length.toLocaleString()} OCNs were not prefixed with zeroes`}
									label="Enable OCN Prefixing"
									value={this.state.autoFillOcn}
									onChange={(autoFillOcn) => this.setState({ autoFillOcn })}
								/>
							</Col>
						)}
						{NoIntraFound.length > 0 && (
							<Col xl={6} className="mb-3">
								<FancyFormCheckbox
									required
									header={`${NoIntraFound.length.toLocaleString()} Intra. Rates were not found`}
									label="Use Respective Inter. Rates"
									value={this.state.autofillIntraWithBestMatch}
									onChange={(autofillIntraWithBestMatch) =>
										this.setState({
											autofillIntraWithBestMatch
										})
									}
								/>
							</Col>
						)}
						{NoIndetFound.length > 0 && (
							<Col xl={6} className="mb-3">
								<FancyFormCheckbox
									required
									header={`${NoIndetFound.length.toLocaleString()} Indet. Rates were not found`}
									label="Use Respective Intra. or Inter. Rates"
									value={this.state.autofillIndetWithBestMatch}
									onChange={(autofillIndetWithBestMatch) =>
										this.setState({
											autofillIndetWithBestMatch
										})
									}
								/>
							</Col>
						)}
						{OneRatePerRateSummary.length > 0 && (
							<Col xl={6} className="mb-3">
								<FancyFormCheckbox
									required
									header={`${OneRatePerRateSummary.length.toLocaleString()} Rates were found with identical summaries`}
									label="Allow Rates with Identical Summaries"
									value={!this.state.oneRatePerRateSummary}
									onChange={(disabledOneRatePerRateSummary) =>
										this.setState({
											oneRatePerRateSummary: !disabledOneRatePerRateSummary
										})
									}
								/>
							</Col>
						)}
					</Row>
				</Card.Body>
				{this.renderSpreadsheetMessage()}
				{this.renderWizardSteps()}
			</Form>
		);
	}

	public renderStep3() {
		const { rateSheetTypeId, rates } = this.state;

		if (!rateSheetTypeId) {
			return null;
		}

		return (
			<Form onSubmit={this.uploadCarrierRates}>
				<section className="table-info text-info">
					<section className="p-3 d-flex justify-content-start">
						<article>
							<i className="fas fa-fw fa-info-circle me-2" />
						</article>
						<article>
							Review the uploaded rates below before submitting.
						</article>
					</section>
				</section>
				<LcrCarrierRatesDtoGrid
					disableCardAppearance
					disableCollapse
					rates={rates}
					rateSheetTypeId={rateSheetTypeId}
				/>
				<FormErrors error={this.state.error} className="p-4" />
				{this.renderWizardSteps('Submit')}
			</Form>
		);
	}

	public renderSpreadsheetMessage() {
		const { spreadsheetLogEntries } = this.state;

		if (spreadsheetLogEntries.length <= 0) {
			return null;
		}

		return (
			<article
				className="list-group list-group-flush mb-3"
				style={{ maxHeight: 300, overflowY: 'auto' }}
			>
				{spreadsheetLogEntries.map((spreadsheetLogEntry) => {
					const { type } = spreadsheetLogEntry;
					let color = 'light';

					if (type === 'error') {
						color = 'danger';
					} else if (type === 'info') {
						color = 'success';
					}

					return (
						<ListGroup.Item
							key={spreadsheetLogEntry.message}
							className="rounded-0"
							color={color}
						>
							{spreadsheetLogEntry.message}
						</ListGroup.Item>
					);
				})}
			</article>
		);
	}

	public renderWizardSteps(nextStepText = 'Continue') {
		const { wizardStep, isSubmitting, disableWizardActions } = this.state;
		const previousStep = wizardStep - 1;

		return (
			<Card.Body className="d-flex justify-content-around px-md-5">
				{wizardStep !== 1 && (
					<Button
						size="lg"
						variant="light"
						className="flex-fill mx-md-5 mx-2"
						style={{ maxWidth: 300 }}
						disabled={isSubmitting || disableWizardActions}
						onClick={() => this.setState({ wizardStep: previousStep })}
					>
						{/* <i className="fas fa-fw fa-angle-left" /> */}
						<span>&nbsp;Back</span>
					</Button>
				)}
				<Button
					type="submit"
					size="lg"
					variant="primary"
					className="flex-fill mx-md-5 mx-2"
					disabled={isSubmitting || disableWizardActions}
					style={{ maxWidth: 300 }}
				>
					<span>{nextStepText}&nbsp;</span>
					{!isSubmitting && <i className="fas fa-fw fa-long-arrow-alt-right" />}
					{isSubmitting && <i className="fas fa-fw fa-cog fa-spin" />}
				</Button>
			</Card.Body>
		);
	}

	public render() {
		const { wizardStep } = this.state;
		return (
			<section>
				<Row className="g-0">
					<Col xl={10} className="mb-4">
						<section>
							<Card style={{ overflowX: 'hidden' }}>
								<Card.Header className="d-none d-md-block">
									<Nav className="nav-steps d-md-flex justify-content-between">
										<Nav.Item>
											<Nav.Link active={wizardStep === 1}>1. Upload</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link active={wizardStep === 2}>2. Review</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link active={wizardStep === 3}>3. Submit</Nav.Link>
										</Nav.Item>
									</Nav>
								</Card.Header>
								{wizardStep === 1 && (
									<section className="subtleSlideInRight">
										{this.renderStep1()}
									</section>
								)}
								{wizardStep === 2 && (
									<section className="subtleSlideInRight">
										{this.renderStep2()}
									</section>
								)}
								{wizardStep === 3 && (
									<section className="subtleSlideInRight">
										{this.renderStep3()}
									</section>
								)}
							</Card>
						</section>
					</Col>
				</Row>
				<Modal
					show={this.state.isSamplesModalOpen}
					size="lg"
					onHide={() => this.toggleIsSamplesModalOpen()}
				>
					<Modal.Body className="bg-light rounded">
						<article className="d-flex justify-content-end mb-2">
							<Button
								variant="light"
								onClick={() => this.toggleIsSamplesModalOpen(false)}
							>
								<i className="fas fa-times fa-fw fa-lg" />
							</Button>
						</article>
						{this.renderSampleTable('NPA/NXX', this.npaNxxSampleData)}
						{this.renderSampleTable('LATA/OCN', this.lataOcnSampleData)}
					</Modal.Body>
				</Modal>
			</section>
		);
	}
}
