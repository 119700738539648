import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

export class MessagesDetailRouterClass extends RtVueReportRouter<
	void,
	void,
	{}
> {
	constructor() {
		super('SMS/MMS Lookup', 'messagesDetail', undefined, {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewMessagesDetail);
	}

	/**
	 * By pass pluralization of name
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const MessagesDetailRouter = new MessagesDetailRouterClass();
