import { NumbersLiteIndexResponse } from 'RtModels';
import { AccountRouter } from 'RtUi/app/AccountManagement/Accounts/Account.router';
import { SubscriptionRouter } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.router';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getNumbersLiteColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/numbersLite';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { Item, Submenu } from 'react-contexify';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface IVueNumbersLiteDataGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<NumbersLiteIndexResponse> | null;
	callDateFormat?: string;
	isLoading?: boolean;
	onDrillDown: (row: NumbersLiteIndexResponse, key: string) => void;
	getExportData: () => Promise<NumbersLiteIndexResponse[]>;
}

export const VueNumbersLiteDataGrid = ({
	resource,
	callDateFormat,
	isLoading,
	onDrillDown,
	getExportData
}: IVueNumbersLiteDataGridProps) => {
	const navigate = useNavigate();
	const { data = [] } = useQuery<NumbersLiteIndexResponse[] | undefined, Error>(
		[`useGetNumbersLiteReport`, resource],
		() => resource?.getAll()
	);
	const columns = useMemo(
		() => getNumbersLiteColumns(callDateFormat),
		[callDateFormat]
	);

	const totalRows = Number(resource?.getTotalDbCount());

	return (
		<DataGrid<NumbersLiteIndexResponse>
			pageName="rtVue_numbersLite"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={totalRows}
			menuLinks={(row) => (
				<>
					{row.number !== undefined && (
						<Submenu label="CDR Search">
							<Item onClick={() => onDrillDown(row, 'fr_std')}>
								<span className="d-flex justify-content-start align-items-center">
									<span>Calling Party</span>
								</span>
							</Item>
							<Item onClick={() => onDrillDown(row, 'to_std')}>
								<span className="d-flex justify-content-start align-items-center">
									<span>Dialed Number</span>
								</span>
							</Item>
						</Submenu>
					)}
					{row.customerAccountId !== undefined && (
						<Item
							onClick={() =>
								navigate(
									AccountRouter.getProfileRoute(String(row.customerAccountId))
								)
							}
						>
							<span className="d-flex justify-content-start align-items-center">
								<span>Customer Account</span>
							</span>
						</Item>
					)}
					{row.customerExternalAccountId !== undefined && (
						<Item
							onClick={() =>
								navigate(
									AccountRouter.getProfileRoute(
										String(row.customerExternalAccountId)
									)
								)
							}
						>
							<span className="d-flex justify-content-start align-items-center">
								<span>Customer External Account</span>
							</span>
						</Item>
					)}
					{row.customerExternalSubAccountId !== undefined && (
						<Item
							onClick={() =>
								navigate(
									AccountRouter.getProfileRoute(
										String(row.customerExternalSubAccountId)
									)
								)
							}
						>
							<span className="d-flex justify-content-start align-items-center">
								<span>Customer External Sub Account</span>
							</span>
						</Item>
					)}
					{row.vendorAccountId !== undefined && (
						<Item
							onClick={() =>
								navigate(
									AccountRouter.getProfileRoute(String(row.vendorAccountId))
								)
							}
						>
							<span className="d-flex justify-content-start align-items-center">
								<span>Vendor Account</span>
							</span>
						</Item>
					)}
					{row.vendorExternalAccountId !== undefined && (
						<Item
							onClick={() =>
								navigate(
									AccountRouter.getProfileRoute(
										String(row.vendorExternalAccountId)
									)
								)
							}
						>
							<span className="d-flex justify-content-start align-items-center">
								<span>Vendor External Account</span>
							</span>
						</Item>
					)}
					{row.vendorExternalSubAccountId !== undefined && (
						<Item
							onClick={() =>
								navigate(
									AccountRouter.getProfileRoute(
										String(row.vendorExternalSubAccountId)
									)
								)
							}
						>
							<span className="d-flex justify-content-start align-items-center">
								<span>Vendor External Sub Account</span>
							</span>
						</Item>
					)}
					{row.customerSubscriptionId !== undefined && (
						<Item
							onClick={() =>
								navigate(
									SubscriptionRouter.getProfileRoute(row.customerSubscriptionId)
								)
							}
						>
							<span className="d-flex justify-content-start align-items-center">
								<span>Customer Subscription</span>
							</span>
						</Item>
					)}
					{row.vendorSubscriptionId !== undefined && (
						<Item
							onClick={() =>
								navigate(
									SubscriptionRouter.getProfileRoute(row.vendorSubscriptionId)
								)
							}
						>
							<span className="d-flex justify-content-start align-items-center">
								<span>Vendor Subscription</span>
							</span>
						</Item>
					)}
				</>
			)}
			enableExternalExport
			getExternalExportData={getExportData}
		/>
	);
};
