/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $EntityResLimitProfileResponse = {
    properties: {
        lastUpdateTs: {
            properties: {
            },
            isRequired: true,
        },
        pctWorkNums: {
            properties: {
            },
            isRequired: true,
        },
        numResLimit: {
            properties: {
            },
            isRequired: true,
        },
        enableBlocking: {
            properties: {
            },
            isRequired: true,
        },
        resTimeMins: {
            properties: {
            },
            isRequired: true,
        },
        extLockoutMins: {
            properties: {
            },
            isRequired: true,
        },
        lockoutCleanup: {
            properties: {
            },
            isRequired: true,
        },
        numAllocInd: {
            properties: {
            },
            isRequired: true,
        },
        pctSpareLimit: {
            properties: {
            },
            isRequired: true,
        },
        entity: {
            properties: {
            },
            isRequired: true,
        },
        maxResLimit: {
            properties: {
            },
            isRequired: true,
        },
        resNumCnt: {
            properties: {
            },
            isRequired: true,
        },
        workingNumCnt: {
            properties: {
            },
            isRequired: true,
        },
        remainingRes: {
            properties: {
            },
            isRequired: true,
        },
        refreshedTs: {
            properties: {
            },
            isRequired: true,
        },
        dtLastUpdated: {
            properties: {
            },
            isRequired: true,
        },
        updateId: {
            properties: {
            },
            isRequired: true,
        },
        transToSpareTm: {
            properties: {
            },
            isRequired: true,
        },
        resToSpareTm: {
            properties: {
            },
            isRequired: true,
        },
        recVersionId: {
            properties: {
            },
            isRequired: true,
        },
    },
};