import {
	GuardianReconciliationUsageDetailsIndexRequest,
	GuardianReconciliationUsageDetailsIndexResponse
} from 'RtModels';
import { ReconciliationUsageRouter } from 'RtUi/app/rtVue/ReconciliationUsage/ReconciliationUsage.router';
import { useGetGuardianReconciliationUsageDetails } from 'RtUi/app/rtVue/ReconciliationUsage/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getReconciliationUsageColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/reconciliationUsageDetails';
import { FC, useMemo } from 'react';

interface IReconciliationUsageDetailsGridProps {
	resourceParams?: GuardianReconciliationUsageDetailsIndexRequest;
	autoFocusFilter?: boolean;
}

export const ReconciliationUsageGrid: FC<
	React.PropsWithChildren<IReconciliationUsageDetailsGridProps>
> = ({ resourceParams }) => {
	const { data, isFetching: isLoading } =
		useGetGuardianReconciliationUsageDetails(resourceParams);
	const columns = useMemo(() => getReconciliationUsageColumns(), []);

	return (
		<DataGrid<GuardianReconciliationUsageDetailsIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			router={ReconciliationUsageRouter}
			pageName={'rtCommon_ReconciliationUsage'}
		/>
	);
};
