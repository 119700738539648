import {
	DataSources,
	SearchField,
	FilterModesEnum,
	FilterReportsEnum
} from 'RtModels';
import {
	RtVueFilter,
	IGenericFilterResponse,
	TFilterResponseWithKeywords,
	IFilterSearchResultSection
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { MemoryCacheStrategy } from 'RtUi/utils/cache/strategies/MemoryCacheStrategy';

/**
 * @singleton
 */
export class RtVueTextFilter extends RtVueFilter<SearchField> {
	public static DialCodeFilterRegexp = new RegExp(/\d+/, 'g');

	public static getInstance() {
		if (!this.instance) {
			this.instance = new RtVueTextFilter();
		}

		return this.instance;
	}

	private static instance: RtVueTextFilter | undefined;
	private static readonly FilterName = 'RtVueTextFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<SearchField>
	> = new MemoryCacheStrategy();

	protected constructor() {
		super(
			RtVueTextFilter.FilterName,
			DataSources.Text,
			RtVueTextFilter.DataCache
		);
	}

	public getLabelFor(record: SearchField): string {
		return record.label;
	}

	public getValueFor(record: SearchField): string {
		return String(record.key);
	}

	/**
	 * @override
	 */
	public async getLabelFromValue(value: string) {
		return value;
	}

	/**
	 * RtVueTextFilter is different from other RtVueFilters. It searches through multiple
	 * SearchFields to return a set of results with different common names.
	 *
	 * @override
	 * @param possibleSearchFields
	 * @param criteria
	 * @returns a tuple of the results and the applicable SearchFields
	 */
	public async search(
		possibleSearchFields: SearchField[],
		searchStr: string
	): Promise<IFilterSearchResultSection[]> {
		let textSearchFields = possibleSearchFields.filter((searchField) =>
			searchField.dataSources.includes(DataSources.Text)
		);

		// If search can't be dial code, do not include it into the search
		if (!RtVueTextFilter.DialCodeFilterRegexp.test(searchStr)) {
			textSearchFields = textSearchFields.filter(
				(searchField) => searchField.label !== 'Dial Code'
			);
		}

		const searchStrLength = searchStr.length;
		const searchSections: IFilterSearchResultSection[] = [];

		for (const textSearchField of textSearchFields) {
			if (searchStrLength < textSearchField.minCharacters) {
				continue;
			}

			const { min, max } = textSearchField;
			if (typeof min === 'number' || typeof max === 'number') {
				const searchNum = Number(searchStr);

				if (isNaN(searchNum)) {
					continue;
				}

				const doesNotMeetMinReq = typeof min === 'number' && searchNum < min;
				const doesNotMeetMaxReq = typeof max === 'number' && searchNum > max;

				if (doesNotMeetMinReq || doesNotMeetMaxReq) {
					continue;
				}
			}

			searchSections.push({
				commonName: textSearchField.label,
				results: [
					{
						dataSourceFields: [textSearchField],
						operand: {
							dataSource: this.getDataSource(),
							value: searchStr
						}
					}
				]
			});
		}

		return searchSections;
	}

	/**
	 * Note: This is a no-op to satisfy RtVueFilter
	 */
	protected async getFilterResponse(): Promise<
		IGenericFilterResponse<SearchField>
	> {
		const filterRes: IGenericFilterResponse<SearchField> = {
			mode: FilterModesEnum.Global,
			reportIncludes: FilterReportsEnum.All,
			dataSource: undefined,
			commonName: 'placeholder//todo',
			data: []
		};

		return filterRes;
	}

	protected getKeywordsFor(record: SearchField): string[] {
		return [];
	}
}
