import { CallflowService } from 'RtModels';

export const vendorServiceTypes = [CallflowService.OutboundVendorTermination];

export const customerServiceTypes = [
	CallflowService.OutboundCustomerTermination,
	CallflowService.DirectConnect,
	CallflowService.InboundCustomerIdentification,
	CallflowService.OutboundCustomerIdentification
];
