/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MinuteProfile = {
    properties: {
        minuteProfileId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        createdByEmailAddress: {
            type: 'string',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        filename: {
            type: 'string',
            isRequired: true,
        },
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        interMinutes: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        intraMinutes: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        indetMinutes: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        totalMinutes: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
    },
};