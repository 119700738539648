/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianDoNotOriginate = {
    properties: {
        ani: {
            type: 'string',
            isRequired: true,
        },
        active: {
            type: 'number',
        },
        numberType: {
            type: 'GuardianDnoNumberTypes',
            isRequired: true,
        },
        created: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        updated: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};