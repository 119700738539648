import { Permissions } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

export class AsrLiteViewRouterClass extends RtVueReportRouter<void, void, {}> {
	constructor() {
		super('ASR Lite', 'asrLite', undefined, {});

		this.setIndexPermissions([Permissions.VueOperator]);
	}

	/**
	 * By pass pluralization of name
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const AsrLiteViewRouter = new AsrLiteViewRouterClass();
