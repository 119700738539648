import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	RatePlanIndexResponse,
	ServiceIndexResponse,
	SubscriptionIndexRequest,
	SubscriptionProfileResponse
} from 'RtModels';
import { RatePlanSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/RatePlanSelect';
import { SubscriptionEditor } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionEditor';
import { SubscriptionDataGrid } from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/SubscriptionGrid';
import { ServiceSelect } from 'RtUi/app/rtSip/Services/lib/controls/ServiceSelect';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { SubscriptionRouter } from './Subscription.router';

interface IInitialResourceParams {
	search: string;
	ratePlan?: RatePlanIndexResponse;
	isActive?: number;
}

interface ISubscriptionsContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {
	isActive?: number;
	serviceType?: ServiceIndexResponse;
	serviceLabels: string;
}

@SubscriptionRouter.getIndexRtUiController({
	description: 'Retrieve a list of Subscriptions.',
	orderPriority: 0
})
export class SubscriptionIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	ISubscriptionsContainerState
> {
	public initParams: IInitialResourceParams = {
		search: '',
		isActive: 1
	};

	public state: ISubscriptionsContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {},
		serviceLabels: ''
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { search, isActive, serviceType, ratePlan } = this.state;
		const resourceParams: SubscriptionIndexRequest = {};

		if (search) {
			resourceParams.search = search;
		}

		if (isActive !== -1) {
			resourceParams.isActive = isActive;
		}

		if (ratePlan) {
			resourceParams.ratePlanId = ratePlan.ratePlanId;
		}

		if (serviceType) {
			resourceParams.serviceIds = [serviceType.serviceId];
		}

		return resourceParams;
	}

	public onSubscriptionCreate(subscription: SubscriptionProfileResponse) {
		const subscriptionProfilePath = SubscriptionRouter.getProfileRoute(
			subscription.subscriptionId
		);
		this.goToPath(subscriptionProfilePath);
	}

	public render() {
		return (
			<>
				<TabbedLayout
					router={SubscriptionRouter}
					activeTab={this.state.activeTab}
					onTabChange={(activeTab) => this.setActiveTab(activeTab)}
				>
					<TabbedLayoutTab header={this.tabs.Search}>
						<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
							<InputFormControl
								label="Search"
								onChange={(search) => this.setState({ search })}
								value={this.state.search}
							/>
							<ServiceSelect
								label="Service"
								onChange={(serviceType) => {
									this.setState({ serviceType });
								}}
								value={this.state.serviceType}
							/>
							<RatePlanSelect
								label="Rate Plan"
								onChange={(ratePlan: RatePlanIndexResponse) =>
									this.setState({ ratePlan: ratePlan })
								}
								value={this.state.ratePlan}
							/>
							<BooleanRadioFormControl
								label="Active"
								showBothOption
								onChange={(isActive) => {
									this.setState({ isActive });
								}}
								value={this.state.isActive}
							/>
						</RtUiSearchForm>
					</TabbedLayoutTab>
					<TabbedLayoutTab header={this.tabs.Results}>
						<SubscriptionDataGrid
							resourceParams={this.state.resourceParams}
							autoFocusFilter
						/>
					</TabbedLayoutTab>
					<TabbedLayoutTab
						isAction
						header="Create Subscription"
						rtRoute={RtxSipApiRoutes.Subscriptions.Create}
					>
						<SubscriptionEditor
							createMode
							onUpdate={(newSubscription) =>
								this.onSubscriptionCreate(newSubscription)
							}
						/>
					</TabbedLayoutTab>
				</TabbedLayout>
			</>
		);
	}
}
