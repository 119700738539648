/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationConfigCommon = {
    properties: {
        resourceTypeId: {
            type: 'ResourceType',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isDefault: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        integrationId: {
            type: 'Integrations',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isNullable: true,
        },
        protocol: {
            type: 'string',
            isNullable: true,
        },
        host: {
            type: 'string',
            isNullable: true,
        },
        port: {
            type: 'number',
            isNullable: true,
        },
        path: {
            type: 'string',
            isNullable: true,
        },
        user: {
            type: 'string',
            isNullable: true,
        },
        password: {
            type: 'string',
            isNullable: true,
        },
        weight: {
            type: 'number',
        },
        costPerTn: {
            type: 'number',
        },
    },
};