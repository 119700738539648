import { TimeFilterDetailKeys, UsageAuditByDayIndexResponse } from 'RtModels';
import { ReconciliationUsageResource } from 'RtUi/app/rtVue/ReconciliationUsage/lib/resources/ReconciliationUsageDetailsResource';
import DataGrid from 'RtUi/components/data/DataGrid';
import { usageAuditByDayColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/usageAuditByDay';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

interface IUsageAuditByDayGridProps {
	resource: HttpResource<
		UsageAuditByDayIndexResponse,
		UsageAuditByDayIndexResponse,
		any
	>;
	isLoading?: boolean;
	selectedDetailLevel?: TimeFilterDetailKeys;
	onUpdate?: () => void;
	callDateFormat?: string;
}

export const UsageAuditByDayGrid = ({
	resource,
	isLoading,
	callDateFormat,
	selectedDetailLevel,
	onUpdate = () => {}
}: IUsageAuditByDayGridProps): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);

	const { data } = useQuery<UsageAuditByDayIndexResponse[], Error>(
		['getUsageAuditByDay', resource],
		() => resource.getAll(),
		{ enabled: true }
	);

	const onUpdateInvoiceCharge = useCallback(
		async (usageDetailId: number, newValue: number) => {
			setLoading(true);
			const resource = new ReconciliationUsageResource();

			try {
				const usageDetail = await resource.get(usageDetailId);

				if (!usageDetail || usageDetail.carrierInvoiceCharge === newValue) {
					return;
				}

				await resource.update(usageDetailId, {
					...usageDetail,
					carrierInvoiceCharge: newValue
				});

				onUpdate();
			} catch (e) {
			} finally {
				setLoading(false);
			}
		},
		[onUpdate]
	);

	const columns = useMemo(
		() =>
			usageAuditByDayColumns(
				onUpdateInvoiceCharge,
				callDateFormat,
				selectedDetailLevel
			),
		[callDateFormat, selectedDetailLevel, onUpdateInvoiceCharge]
	);

	return (
		<DataGrid<UsageAuditByDayIndexResponse>
			data={data}
			loading={loading || isLoading}
			columns={columns}
			pageName="rtVue-usageAuditByDay"
			totalRows={resource.getTotalDbCount()}
		/>
	);
};
