import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	SwitchCreateRequest,
	SwitchProfileResponse,
	SwitchTypeIndexResponse
} from 'RtModels';
import { SwitchTypesSelect } from 'RtUi/app/AccountManagement/Switches/lib/controls/SwitchTypesSelect';
import { SwitchesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SwitchesResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ISwitchEditorFormProps {
	value?: SwitchProfileResponse;
	createMode?: boolean;
	onSuccess?: (value: SwitchProfileResponse) => void;
	onCancel?: () => void;
}

export const SwitchEditorForm = ({
	value,
	createMode = false,
	onSuccess = () => {},
	onCancel = () => {}
}: ISwitchEditorFormProps): JSX.Element => {
	const [switchType, setSwitchType] = useState<SwitchTypeIndexResponse>();
	const [isEditing, setIsEditing] = useState<boolean>(createMode);
	const [error, setError] = useState<RtError>();
	const {
		control,
		handleSubmit,
		formState: { isSubmitting }
	} = useForm<SwitchProfileResponse>({
		defaultValues: value
	});

	const onSubmitHandler = async (
		data: SwitchCreateRequest | SwitchProfileResponse
	) => {
		const resource = new SwitchesResource();
		setError(undefined);

		try {
			let result: SwitchProfileResponse;
			if (createMode) {
				result = await resource.create(data as SwitchCreateRequest);
			} else {
				result = await resource.update(data as SwitchProfileResponse);
				setIsEditing(false);
			}

			onSuccess(result);
		} catch (e: any) {
			setError(new RtError(e));
		}
	};

	return (
		<RtUiForm
			error={error}
			isSubmitting={isSubmitting}
			displayMode={!isEditing}
			onChange={(val) => setIsEditing(!val)}
			onSubmit={handleSubmit(onSubmitHandler)}
			onCancel={onCancel}
		>
			<Controller
				control={control}
				name="label"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Label"
						value={value}
						onChange={onChange}
						required
						displayMode={!isEditing}
					/>
				)}
			/>
			<Controller
				control={control}
				name="switchTypeId"
				render={({ field: { onChange, value } }) => (
					<SwitchTypesSelect
						value={switchType}
						onChange={(val) => {
							setSwitchType(val);
							onChange(val?.switchTypeId);
						}}
						isClearable={false}
						required
						displayMode={!isEditing}
						initialOptionId={value}
					/>
				)}
			/>
			<Controller
				control={control}
				name="isActive"
				render={({ field: { onChange, value } }) => (
					<IsActiveRadioFormControl
						value={value}
						onChange={onChange}
						hideBothOption
						displayMode={!isEditing}
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="switchMajorVersion"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Major Version"
						type="number"
						min={0}
						value={value?.toString() ?? ''}
						onChange={(val) => onChange(Number(val))}
						displayMode={!isEditing}
					/>
				)}
			/>
			<Controller
				control={control}
				name="switchMinorVersion"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Major Version"
						type="number"
						min={0}
						value={value?.toString() ?? ''}
						onChange={(val) => onChange(Number(val))}
						displayMode={!isEditing}
					/>
				)}
			/>
			<Controller
				control={control}
				name="switchPatchVersion"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Major Version"
						type="number"
						min={0}
						value={value?.toString() ?? ''}
						onChange={(val) => onChange(Number(val))}
						displayMode={!isEditing}
					/>
				)}
			/>
		</RtUiForm>
	);
};
