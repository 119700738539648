/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianAlertTypeIndexResponse = {
    properties: {
        alertTypeId: {
            type: 'number',
            isRequired: true,
        },
        productId: {
            type: 'ProductId',
            isRequired: true,
        },
        integrationTypeId: {
            type: 'number',
            isRequired: true,
        },
        requireResourceId: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        source: {
            type: 'string',
            isRequired: true,
        },
        allowedDataFilters: {
            type: 'Array',
            isRequired: true,
            isNullable: true,
        },
        allowedDataColumns: {
            type: 'Array',
            isRequired: true,
            isNullable: true,
        },
        allowedHavingFields: {
            type: 'Array',
            isRequired: true,
            isNullable: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        systemAlert: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
    },
};