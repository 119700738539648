import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import {
	SubscriptionIndexResponse,
	ScenarioIndexResponse,
	OriginationNumberProfileResponse,
	OriginationNumberCreateRequest
} from 'RtModels';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';
import { InternationalNumberFormControl } from 'RtUi/app/rtSip/ServiceNumbers/lib/controls/InternationalNumberFormControl';
import { ScenarioSelect } from 'RtUi/app/rtSip/Scenarios/lib/controls/ScenarioSelect';
import { OriginationNumberResource } from 'RtUi/app/rtSip/ServiceNumbers/lib/resources/OriginationNumberResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { ServiceNumberSpreadsheetParser } from 'RtUi/app/rtSip/ServiceNumbers/lib/parsers/ServiceNumberSpreadsheetParser';
import { RtError } from 'RtUi/utils/errors/RtError';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { Component } from 'react';
import { Table } from 'react-bootstrap';
import { CustomerOriginationSubscriptionSelect } from 'RtUi/app/rtSip/ServiceNumbers/lib/controls/CustomerOriginationSubscriptionSelect';
import { isEqual } from 'lodash-es';

interface IServiceNumberFormEditorProps {
	profile?: OriginationNumberProfileResponse;
	onUpdate?: (profile: OriginationNumberProfileResponse) => void;
	bulk?: boolean;
}

interface IServiceNumberFormEditorState {
	displayMode: boolean;
	isSubmitting: boolean;
	label: string;
	serviceNumber?: string;
	translatedNumber?: string;
	subscription?: SubscriptionIndexResponse;
	scenario?: ScenarioIndexResponse;
	processingFile: boolean;
	parsedServiceNumbers?: OriginationNumberCreateRequest[];
	file?: File;
	error?: any;
}

export class ServiceNumberFormEditor extends Component<
	IServiceNumberFormEditorProps,
	IServiceNumberFormEditorState
> {
	public phoneNumberFormatter = new PhoneNumberFormatter();

	constructor(props: IServiceNumberFormEditorProps) {
		super(props);

		this.state = {
			displayMode: false,
			label: '',
			isSubmitting: false,
			processingFile: false
		};
	}

	public componentDidMount(): void {
		this.setState({
			displayMode: Boolean(this.props.profile),
			label: this.props.profile?.label ?? '',
			serviceNumber: this.props.profile?.serviceNumber,
			translatedNumber: this.props.profile?.translatedNumber
		});
	}

	public componentDidUpdate(
		prevProps: Readonly<IServiceNumberFormEditorProps>
	): void {
		if (!isEqual(prevProps.profile, this.props.profile)) {
			this.componentDidMount();
		}
	}

	private onCancel = () => {
		this.setState({
			serviceNumber: this.props.profile?.serviceNumber,
			translatedNumber: this.props.profile?.translatedNumber,
			label: this.props.profile?.label ?? '',
			subscription: undefined,
			scenario: undefined
		});
	};

	private processFile = async (file: File) => {
		const fileUtils = new FileUtils();

		try {
			this.setState({ processingFile: true, error: undefined });
			const spreadsheet = await fileUtils.fileToSpreadsheet(file);
			const parser = new ServiceNumberSpreadsheetParser(spreadsheet);
			parser.on('error', (eventMessage) => {
				this.setState(() => ({
					error: new RtError({ message: eventMessage })
				}));
			});
			const parsedServiceNumbers = parser.parseServiceNumberRecords();
			this.setState({
				parsedServiceNumbers,
				file: file,
				processingFile: false
			});
		} catch (error) {
			this.setState({ error, processingFile: false });
		}
	};

	private createOrUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const serviceNumberResource = new OriginationNumberResource();
		let newProfile: OriginationNumberProfileResponse;

		this.setState({ isSubmitting: true, error: undefined });
		const { onUpdate = () => ({}) } = this.props;

		try {
			if (
				this.state.parsedServiceNumbers &&
				this.state.parsedServiceNumbers.length > 1
			) {
				let serviceNumbers = this.state.parsedServiceNumbers;

				if (this.state.scenario || this.state.subscription) {
					serviceNumbers = this.state.parsedServiceNumbers.map((sn) => ({
						...sn,
						subscriptionId: this.state.subscription?.subscriptionId || null,
						scenarioId: this.state.scenario?.scenarioId || null
					}));
				}

				await serviceNumberResource.bulkCreate({
					serviceNumbers
				});
			} else {
				const updateCreateRequest = {
					label: this.state.label,
					serviceNumber: this.state.serviceNumber!,
					translatedNumber: this.state.translatedNumber || null,
					subscriptionId: this.state.subscription?.subscriptionId || null,
					scenarioId: this.state.scenario?.scenarioId || null
				};

				if (this.props.profile) {
					newProfile = await serviceNumberResource.update(
						this.props.profile.serviceNumberId,
						updateCreateRequest
					);
					this.setState({ displayMode: true });
				} else {
					newProfile = await serviceNumberResource.create(updateCreateRequest);
				}
			}

			this.setState(
				{
					displayMode: true,
					parsedServiceNumbers: undefined,
					file: undefined
				},
				() => {
					onUpdate(newProfile);
				}
			);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	};

	public render() {
		return (
			<>
				{this.props.bulk && (
					<CollapsibleCard
						header="Example of Service Numbers Spreadsheet"
						className="ps-0 col-lg-6 col-xl-6"
						closedByDefault
					>
						<Table size="sm" bordered className="bg-white mb-0" responsive>
							<thead>
								<tr>
									<th>Service Number</th>
									<th>Translated Number</th>
									<th>Label</th>
								</tr>
							</thead>
							<tbody>
								{[
									['18007013001', '', 'Optional Label'],
									['+15124268100', '+15122930933', ''],
									[
										'+18558166192',
										'+52 55 5228 1818',
										'International must have plus'
									]
								].map((values, index) => (
									<tr key={index}>
										{values.map((value, innerIndex) => (
											<td className="text-monospace" key={innerIndex}>
												{value}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</Table>
					</CollapsibleCard>
				)}
				<RtUiNarrowForm
					createMode={!this.props.profile}
					onSubmit={this.createOrUpdate}
					displayMode={this.state.displayMode}
					isSubmitting={this.state.isSubmitting}
					error={this.state.error}
					onChange={(displayMode) => this.setState({ displayMode })}
					onCancel={this.onCancel}
				>
					{!this.props.bulk && (
						<>
							<InternationalNumberFormControl
								label="Service Number"
								displayMode={Boolean(this.props.profile?.serviceNumberId)}
								value={this.state.serviceNumber}
								onChange={(serviceNumber) => this.setState({ serviceNumber })}
							/>
							<InternationalNumberFormControl
								label="Translated Number"
								displayMode={this.state.displayMode}
								value={this.state.translatedNumber}
								onChange={(translatedNumber) =>
									this.setState({ translatedNumber })
								}
							/>
						</>
					)}
					<CustomerOriginationSubscriptionSelect
						isClearable
						displayMode={this.state.displayMode}
						value={this.state.subscription}
						onChange={(subscription) => this.setState({ subscription })}
						initialOptionId={this.props.profile?.subscriptionId}
					/>
					<ScenarioSelect
						clearable
						displayMode={this.state.displayMode}
						value={this.state.scenario}
						onChange={(scenario) => this.setState({ scenario })}
						initialOptionId={this.props.profile?.scenarioId?.toString()}
					/>
					{!this.props.bulk && (
						<InputFormControl
							label="Label"
							displayMode={this.state.displayMode}
							value={this.state.label}
							onChange={(label) => this.setState({ label })}
						/>
					)}
					{this.props.bulk && (
						<>
							<DragAndDropFileUploader
								required
								accept={FileUtils.AcceptTypes.Spreadsheet}
								processing={this.state.processingFile}
								value={this.state.file}
								onDrop={(file) => this.processFile(file)}
							/>
							{this.state.parsedServiceNumbers && (
								<h4 className="text-center">
									{this.state.parsedServiceNumbers.length} records are ready to
									be submitted
								</h4>
							)}
						</>
					)}
				</RtUiNarrowForm>
			</>
		);
	}
}
