import { useMemo } from 'react';
import {
	GuardianReconciliationUsageDialCodeSummaryIndexRequest,
	GuardianReconciliationUsageDialCodeSummaryIndexResponse
} from 'RtModels';
import { getReconciliationUsageDetailsSummaryColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/reconciliationUsageDetails';
import DataGrid from 'RtUi/components/data/DataGrid';
import { useGetReconciliationUsageDialCodeSummary } from 'RtUi/app/rtVue/ReconciliationUsage/lib/services';

export type IReconciliationDirection = 'src' | 'tgt';

interface IReconciliationUsageDetailsSummaryGridProps {
	resourceParams: GuardianReconciliationUsageDialCodeSummaryIndexRequest;
}

export const ReconciliationUsageDialCodeSummaryGrid = ({
	resourceParams
}: IReconciliationUsageDetailsSummaryGridProps) => {
	const { data, isFetching } =
		useGetReconciliationUsageDialCodeSummary(resourceParams);

	const gridColumns = useMemo(
		() => getReconciliationUsageDetailsSummaryColumns(resourceParams.grouping),
		[resourceParams]
	);

	return (
		<DataGrid<GuardianReconciliationUsageDialCodeSummaryIndexResponse>
			pageName="rtVue_ReconciliationUsageDetails"
			data={data}
			loading={isFetching}
			columns={gridColumns}
		/>
	);
};
