import { FC, useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import {
	GuardianReconciliationAttachmentIndexRequest,
	GuardianReconciliationAttachmentIndexResponse
} from 'RtModels';
import {
	deleteGuardianReconciliationAttachment,
	downloadGuardianReconciliationAttachment,
	useGetGuardianReconciliationAttachments
} from 'RtUi/app/rtVue/GuardianReconciliation/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { guardianReconciliationAttachmentColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/guardianReconciliationAttachment';
import { Confirmation } from 'RtUi/components/form/Confirmation';

interface IReconciliationAttachmentsProps {
	resourceParams?: GuardianReconciliationAttachmentIndexRequest;
	onDelete: () => void;
}

export const ReconciliationAttachmentsGrid: FC<
	React.PropsWithChildren<IReconciliationAttachmentsProps>
> = ({ resourceParams, onDelete }) => {
	const { data, isFetching: isLoading } =
		useGetGuardianReconciliationAttachments(resourceParams);

	const { mutateAsync: deleteAttachment } = useMutation(
		deleteGuardianReconciliationAttachment
	);
	const { mutateAsync: downloadFile } = useMutation(
		downloadGuardianReconciliationAttachment
	);

	const removeEntity = useCallback(
		async (row: GuardianReconciliationAttachmentIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				row.guardianReconciliationAttachmentId.toString()
			);

			if (!confirm) {
				return;
			}

			await deleteAttachment(row.guardianReconciliationAttachmentId);
			onDelete();
		},
		[onDelete, deleteAttachment]
	);

	const onClickDownload = useCallback(
		async (row: GuardianReconciliationAttachmentIndexResponse) => {
			await downloadFile({
				guardianReconciliationAttachmentId:
					row.guardianReconciliationAttachmentId,
				fileName: row.fileName!
			});
		},
		[downloadFile]
	);

	const columns = useMemo(
		() =>
			guardianReconciliationAttachmentColumns(removeEntity, onClickDownload),
		[removeEntity, onClickDownload]
	);

	return (
		<DataGrid<GuardianReconciliationAttachmentIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'rtVue_reconciliationAttachments'}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
