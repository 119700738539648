import { RoboscoreParameterIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const getRoboscoreConfigurationColumns = (): Array<
	DataGridColumn<RoboscoreParameterIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'paramId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'parameterName',
		header: 'Parameter Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'parameterSummary',
		header: 'Parameter Summary'
	}),
	IntegerDataGridColumn({
		accessorKey: 'parameterInputValue',
		header: 'Parameter Input Value'
	}),
	IntegerDataGridColumn({
		accessorKey: 'parameterOutputValue',
		header: 'Parameter Output Value'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created At'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated At'
	}),
	IntegerDataGridColumn({
		accessorKey: 'updatedBy',
		header: 'Updated By'
	})
];
