import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import {
	LcrScenarioContainerTabs,
	LcrScenarioRouter
} from './LcrScenario.router';
import {
	ILcrScenarioProfileResponse,
	LcrScenariosResource
} from './lib/resources/LcrScenariosResource';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { EntityIndexResponse, RespOrgIndexResponse } from 'RtModels';
import { Col, Row } from 'react-bootstrap';

interface ILcrScenarioContainerState {
	lcrScenarioId: string;
	activeTab: string;
	respOrg?: RespOrgIndexResponse;
	entity?: EntityIndexResponse;
	lcrScenario?: ILcrScenarioProfileResponse;
}

@LcrScenarioRouter.getProfileRtUiController()
export class LcrScenarioProfileContainer extends ProfileApplicationContainer<
	{},
	ILcrScenarioContainerState
> {
	public Tabs = LcrScenarioRouter.getProfileTabs();
	public state: ILcrScenarioContainerState = {
		lcrScenarioId: '',
		activeTab: LcrScenarioContainerTabs.Profile.header
	};

	public componentDidMount() {
		const lcrScenariosResource = new LcrScenariosResource();
		const lcrScenarioId = this.getIdParam();

		this.setState({ lcrScenarioId });

		lcrScenariosResource
			.get(lcrScenarioId)
			.then((lcrScenario) => this.setState({ lcrScenario }));
	}

	public render() {
		const { lcrScenario } = this.state;

		if (!lcrScenario) {
			return <Loading />;
		}

		return (
			<TabbedLayout<ILcrScenarioProfileResponse>
				router={LcrScenarioRouter}
				profile={lcrScenario}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<RtUiWideForm hideButtons onSubmit={(ev) => ev.preventDefault()}>
						<Row>
							<Col md={6}>
								<InputFormControl
									label="Scenario Id"
									displayMode
									value={String(lcrScenario.lcrScenarioId)}
								/>
							</Col>
							<Col md={6}>
								<IsActiveRadioFormControl
									displayMode
									hideBothOption
									value={lcrScenario.isActive}
								/>
							</Col>
							<Col md={6}>
								<RadioFormControl<number>
									label="Allow as Default Only"
									displayMode
									value={lcrScenario.allowAsDefaultOnly}
									options={[
										{ value: 0, label: 'No' },
										{ value: 1, label: 'Yes' }
									]}
								/>
							</Col>
							<Col md={6}>
								<InputFormControl
									label="CPR Node Types"
									displayMode
									value={lcrScenario.cprNodeTypes.join(', ')}
								/>
							</Col>
						</Row>
					</RtUiWideForm>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
