import { FC, useState } from 'react';
import { useMount } from 'react-use';
import { ServerProfileResponse } from 'RtModels';
import { ServerCreateForm } from 'RtUi/app/rtAdmin/Servers/lib/forms/ServerCreateForm';
import { ServerResource } from 'RtUi/app/rtAdmin/Servers/lib/resource/ServerResource';
import { ServerRouter } from 'RtUi/app/rtAdmin/Servers/Server.router';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

interface IServerProfileContainer {}

export const ServerProfileContainer: FC<IServerProfileContainer> = () => {
	const entityId = useIdParam();
	const [tabs, activeTab, setActiveTab] = useTabs(
		ServerRouter.getProfileTabs()
	);
	const [entity, setEntity] = useState<ServerProfileResponse>();
	useMount(async () => {
		const resource = new ServerResource();
		const newEntity = await resource.get(entityId);

		setEntity(newEntity);
	});

	const canManageWithRecord = ServerRouter.canManageWith(entity);

	if (!entity) {
		return <Loading />;
	}

	return (
		<TabbedLayout<ServerProfileResponse>
			router={ServerRouter}
			profile={entity}
			canManageFn={() => canManageWithRecord}
			activeTab={activeTab.header}
			onTabChange={(at) => {
				setActiveTab(at);
			}}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<ServerCreateForm editMode={entity} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ServerProfileContainer.displayName = 'ServerProfileContainer';

ServerRouter.setProfileRtUiFunctionalComponent(ServerProfileContainer);
