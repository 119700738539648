import { Row, Col } from 'react-bootstrap';
import {
	GuardianReconciliationNoteTypes,
	GuardianReconciliationProfileResponse
} from 'RtModels';
import { GuardianReconciliationEditor } from 'RtUi/app/rtVue/GuardianReconciliation/lib/forms/GuardianReconciliationEditor';
import { ReconciliationUsageEditor } from 'RtUi/app/rtVue/ReconciliationUsage/lib/forms/ReconciliationUsageEditor';
import { ReconciliationUsageGrid } from 'RtUi/app/rtVue/ReconciliationUsage/lib/grids/ReconciliationUsageDetailsGrid';
import { ReconciliationUsageRouter } from 'RtUi/app/rtVue/ReconciliationUsage/ReconciliationUsage.router';
import { ReconciliationNotes } from 'RtUi/app/rtVue/ReconciliationNote/lib/components/ReconciliationNotes';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { GuardianReconciliationsRouter } from './GuardianReconciliation.router';
import { GuardianReconciliationResource } from './lib/resources/GuardianReconciliationResource';
import { ReconciliationChargesEditor } from 'RtUi/app/rtVue/ReconciliationCharge/lib/forms/ReconciliationChargesEditor';
import { ReconciliationChargeGrid } from 'RtUi/app/rtVue/ReconciliationCharge/lib/grids/ReconciliationChargesGrid';
import { ReconciliationChargesRouter } from 'RtUi/app/rtVue/ReconciliationCharge/ReconciliationCharges.router';
import { ReconciliationChargesBulkUploadForm } from 'RtUi/app/rtVue/ReconciliationCharge/lib/forms/ReconciliationChargesBulkUploadForm';
import { ReconciliationAttachmentsUploadForm } from 'RtUi/app/rtVue/GuardianReconciliation/lib/forms/ReconciliationAttachmentsUploadForm';
import { ReconciliationAttachmentsGrid } from 'RtUi/app/rtVue/GuardianReconciliation/lib/grids/ReconciliationAttachmentsGrid';
import { ReconciliationAttachmentsResource } from 'RtUi/app/rtVue/GuardianReconciliation/lib/resources/ReconciliationAttachmentsResource';

interface IGuardianReconciliationProfileContainerProps {}

interface IGuardianReconciliationProfileContainerState {
	activeTab: string;
	profile?: GuardianReconciliationProfileResponse;
	attachmentsResource: ReconciliationAttachmentsResource;
}

@GuardianReconciliationsRouter.getProfileRtUiController()
export class GuardianReconciliationProfileContainer extends ProfileApplicationContainer<
	IGuardianReconciliationProfileContainerProps,
	IGuardianReconciliationProfileContainerState
> {
	public Tabs = GuardianReconciliationsRouter.getProfileTabs();

	public state: IGuardianReconciliationProfileContainerState = {
		activeTab: this.Tabs.Profile.header,
		attachmentsResource: new ReconciliationAttachmentsResource()
	};

	public async componentDidMount() {
		const profileId = this.getIdParam();
		const resource = new GuardianReconciliationResource();

		const profile = await resource.get(profileId);
		this.setState({
			profile
		});
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}

		const canManage = GuardianReconciliationsRouter.canManageWith(profile);

		return (
			<TabbedLayout<GuardianReconciliationProfileResponse>
				router={GuardianReconciliationsRouter}
				profile={profile}
				canManageFn={() => canManage}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<Row>
						<Col lg={8} className="mb-2">
							<GuardianReconciliationEditor
								editMode={profile}
								onUpdate={(newProfile) =>
									this.setState({ profile: newProfile })
								}
							/>
							<div className="mt-4">
								<h5>Attachments</h5>
								<ReconciliationAttachmentsGrid
									resourceParams={{
										reconciliationId: profile.guardianReconciliationId
									}}
									onDelete={() => {
										this.setState({
											attachmentsResource:
												new ReconciliationAttachmentsResource()
										});
									}}
								/>
							</div>
						</Col>
						<Col lg={4}>
							<ReconciliationNotes
								reconciliationId={profile.guardianReconciliationId}
								reconciliationNoteType={GuardianReconciliationNoteTypes.Parent}
							/>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Charges}>
					<ReconciliationChargeGrid
						resourceParams={{
							reconciliationId: profile.guardianReconciliationId
						}}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Usage}>
					<ReconciliationUsageGrid
						resourceParams={{
							reconciliationId: profile.guardianReconciliationId
						}}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.AddUsage}>
					<Row>
						<Col lg={8}>
							<ReconciliationUsageEditor
								createMode
								initialReconciliationId={profile.guardianReconciliationId}
								onUpdate={(profile) => {
									this.goToPath(
										ReconciliationUsageRouter.getProfileRoute(profile)
									);
								}}
							/>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.AddCharge}>
					<Row>
						<Col lg={8}>
							<ReconciliationChargesEditor
								createMode
								initialReconciliationId={profile.guardianReconciliationId}
								onUpdate={(profile) => {
									this.goToPath(
										ReconciliationChargesRouter.getProfileRoute(profile)
									);
								}}
							/>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.UploadCharges}>
					<ReconciliationChargesBulkUploadForm
						reconciliationId={profile.guardianReconciliationId}
						onSave={() => {
							this.setState({ activeTab: this.Tabs.Charges.header });
						}}
						onCancel={() => {
							this.setState({ activeTab: this.Tabs.Charges.header });
						}}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.UploadAttachments}>
					<ReconciliationAttachmentsUploadForm
						reconciliationId={profile.guardianReconciliationId}
						onSuccess={() => {
							this.setState({
								activeTab: this.Tabs.Profile.header,
								attachmentsResource: new ReconciliationAttachmentsResource()
							});
						}}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
