/**
 *
 */
export abstract class AbstractPromptAudioFileUtils<FileType = File> {
	public static readonly PromptFileTypes = [
		'audio/mp3',
		'audio/mpeg',
		'audio/x-mpeg-3',
		'audio/wave',
		'audio/wav',
		'audio/x-wav',
		'audio/x-pn-wav'
	];
	public static readonly PromptFileMaxDurationInSeconds = 300; // 300 seconds
	public static readonly PromptFileSizeInMegaBytes = 5; // 5mb
	public static readonly PromptFileSizeInBytes = 5 * 1000000; // 5mb

	public async isValidatePrompt(file: FileType): Promise<[boolean, string]> {
		const result = await this.isValidPrompt(file);
		return Promise.resolve(result);
	}

	/**
	 * Validates Prompt Audio files:
	 * 1. Is wav or mp3 file
	 * 2. Is file <= than 5mb
	 * 3. Is duration <= 15 seconds
	 * @param file
	 */
	public async isValidatePromptFile(
		file: FileType
	): Promise<[boolean, string | undefined]> {
		const isValidPromptFileType = await this.isValidPromptFileType(file);

		if (!isValidPromptFileType) {
			return [false, 'Files must be wav or mp3 format.'];
		}

		const isValidPromptFileSize = await this.isValidPromptFileSize(file);

		if (!isValidPromptFileSize) {
			return [
				false,
				`Files must be ${AbstractPromptAudioFileUtils.PromptFileSizeInMegaBytes}mb or less.`
			];
		}

		const isValidPromptFileDuration =
			await this.isValidPromptFileDuration(file);

		if (!isValidPromptFileDuration) {
			return [
				false,
				`Files must be ${AbstractPromptAudioFileUtils.PromptFileMaxDurationInSeconds} seconds or less.`
			];
		}

		return [true, undefined];
	}

	/**
	 * Asserts prompt audio file is valid wav
	 * @param file
	 */
	public abstract isValidPrompt(file: FileType): Promise<[boolean, string]>;

	/**
	 * Asserts file is within prompt audio file size threshold
	 * @param file
	 */
	public abstract isValidPromptFileSize(file: FileType): Promise<boolean>;

	/**
	 * Asserts file is correct duration for a prompt audio file
	 * @param file
	 */
	public abstract isValidPromptFileDuration(file: FileType): Promise<boolean>;

	/**
	 * Asserts prompt audio file is a wav or mp3 file type
	 * @param file
	 */
	public abstract isValidPromptFileType(file: FileType): Promise<boolean>;
}
