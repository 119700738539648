/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $QosManagementUpdateRequest = {
    properties: {
        accountIds: {
            type: 'Array',
        },
        subscriptionIds: {
            type: 'Array',
        },
        routePlanIds: {
            type: 'Array',
        },
        serviceIds: {
            type: 'Array',
        },
        countryIsoIds: {
            type: 'Array',
        },
        rateCenterIds: {
            type: 'Array',
        },
        rateCenterTypeIds: {
            type: 'Array',
        },
        marginPlanId: {
            type: 'number',
            isNullable: true,
        },
        blockTypeId: {
            type: 'number',
            isNullable: true,
        },
        sq0: {
            type: 'number',
        },
        sq1: {
            type: 'number',
        },
        sq2: {
            type: 'number',
        },
        sq3: {
            type: 'number',
        },
        sq4: {
            type: 'number',
        },
        sq5: {
            type: 'number',
        },
        sq6: {
            type: 'number',
        },
        sq7: {
            type: 'number',
        },
        sq8: {
            type: 'number',
        },
        sq9: {
            type: 'number',
        },
        cli: {
            type: 'number',
        },
        test: {
            type: 'number',
        },
        t38: {
            type: 'number',
        },
    },
};