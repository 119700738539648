import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { RoutingRuleIndexResponse, RoutingRuleProfileResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IRoutingRuleRouterTabs extends IProfileApplicationContainerTabs {}

export const RoutingRuleContainerTabs: IRoutingRuleRouterTabs = {};

export class RoutingRuleRouterClass extends SipApplicationRouter<
	RoutingRuleIndexResponse,
	RoutingRuleProfileResponse,
	IRoutingRuleRouterTabs
> {
	constructor() {
		super(
			'Routing Rules',
			'routingRules',
			'routingRuleId',
			RoutingRuleContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.RoutingRules);
	}

	public getProfileLabel(profile: RoutingRuleProfileResponse): string {
		return profile.label;
	}

	public getPluralName() {
		return this.getName();
	}
}

export const RoutingRuleRouter = new RoutingRuleRouterClass();
