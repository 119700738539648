import {
	NumberGroupIndexResponse,
	NumberGroupNumberIndexResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DelimiterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DelimiterDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { TollFreeNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeNumberDataGridColumn';
import { TollFreeStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeStatusDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getNumberGroupColumns = (): Array<
	DataGridColumn<NumberGroupIndexResponse>
> => [
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	...RespOrgColumnsBuilder<NumberGroupIndexResponse>({
		accessorKey: 'respOrgId',
		header: 'RespOrg'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isActive',
		header: 'Active'
	}),
	TollFreeNumberDataGridColumn({
		accessorKey: 'tfn',
		header: 'Toll-Free Number'
	}),
	IntegerDataGridColumn({
		accessorKey: 'tfnQty',
		header: 'TFN Qty',
		showFooter: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'createdByEmailAddress',
		header: 'Created By'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdAt',
		header: 'Created Time'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedAt',
		header: 'Updated Time'
	})
];

export const getNumberGroupNumbersColumns = (): Array<
	DataGridColumn<NumberGroupNumberIndexResponse>
> => [
	TollFreeNumberDataGridColumn({
		accessorKey: 'tfn',
		header: 'Toll-Free Number'
	}),
	...RespOrgColumnsBuilder<NumberGroupNumberIndexResponse>({
		accessorKey: 'respOrgId',
		header: 'RespOrg'
	}),
	TollFreeStatusDataGridColumn({
		accessorKey: 'tfnStatusId',
		header: 'Status'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'statusTs',
		header: 'Status Time'
	}),
	DefaultDataGridColumn({
		accessorKey: 'templateName',
		header: 'Template'
	}),
	DelimiterDataGridColumn({
		accessorKey: 'interLataCarriers',
		header: 'Carriers',
		delimiter: ','
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastUpdateTs',
		header: 'Last Update'
	})
];
