/* eslint-disable no-unused-expressions */
import { RoboscoreIndexResponse } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRoboscoreColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/roboscore';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface IRoboscoreDataGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<RoboscoreIndexResponse> | null;
	isLoading?: boolean;
	callDateFormat?: string;
	onDrilldown?: (
		row: RoboscoreIndexResponse,
		key: keyof RoboscoreIndexResponse
	) => void;
}

export const RoboscoreDataGrid = ({
	resource,
	isLoading,
	onSortingChange: onSortedChange,
	defaultSorting: defaultSorted,
	onDrilldown,
	callDateFormat
}: IRoboscoreDataGridProps) => {
	const { data = [] } = useQuery<RoboscoreIndexResponse[] | undefined, Error>(
		[`useGetPortReport`, resource],
		() => resource?.getAll()
	);
	const columns = useMemo(
		() => getRoboscoreColumns(callDateFormat, onDrilldown),
		[callDateFormat, onDrilldown]
	);

	return (
		<DataGrid<RoboscoreIndexResponse>
			pageName="rtVue_roboscore"
			onSortingChange={onSortedChange}
			defaultSorting={defaultSorted}
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
