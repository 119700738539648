import {
	AuditTemplateCprIndexResponse,
	AuditTollFreeCprIndexResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { SomosApprovalIndicatorDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SomosApprovalIndicatorDataGridColumn';
import { SomosCprStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SomosCprStatus';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { TollFreeNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeNumberDataGridColumn';

export const getAuditTemplateCprColumns = (): Array<
	DataGridColumn<AuditTemplateCprIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'templateName',
		header: 'Template Name'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'effectiveTs',
		header: 'Effective Timestamp'
	}),
	SomosCprStatusDataGridColumn({
		accessorKey: 'cprStatusId',
		header: 'CPR Status'
	}),
	SomosApprovalIndicatorDataGridColumn({
		accessorKey: 'approvalIndicator',
		header: 'Approval Indicator'
	}),
	IntegerDataGridColumn({
		accessorKey: 'tfnQty',
		header: 'TFN Qty',
		showFooter: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'warningQty',
		header: 'Warning Qty'
	}),
	IntegerDataGridColumn({
		accessorKey: 'errorQty',
		header: 'Error Qty'
	})
];

export const getAuditTollFreeCprColumns = (): Array<
	DataGridColumn<AuditTollFreeCprIndexResponse>
> => [
	TollFreeNumberDataGridColumn({
		accessorKey: 'tfn',
		header: 'Toll-Free Number'
	}),
	...RespOrgColumnsBuilder<AuditTollFreeCprIndexResponse>({
		accessorKey: 'respOrgId',
		header: 'RespOrg'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'effectiveTs',
		header: 'Effective Timestamp'
	}),
	SomosCprStatusDataGridColumn({
		accessorKey: 'cprStatusId',
		header: 'CPR Status'
	}),
	SomosApprovalIndicatorDataGridColumn({
		accessorKey: 'approvalIndicator',
		header: 'Approval Indicator'
	})
];
