import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianAlertHistoryNoteIndexUrlRequest,
	GuardianAlertHistoryNoteCreateRequest,
	GuardianAlertHistoryNoteIndexResponse,
	GuardianAlertHistoryNoteProfileRequest
} from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class AlertHistoryNoteResource extends ArrayResource<GuardianAlertHistoryNoteIndexResponse> {
	constructor(private guardianAlertHistoryId: number) {
		super('guardianAlertHistoryNoteId');

		const urlParams: GuardianAlertHistoryNoteIndexUrlRequest = {
			guardianAlertHistoryId: this.guardianAlertHistoryId
		};

		this.setApiRouteForGetAll(RtVueApiRoutes.GuardianAlertHistoryNotes.Index, {
			urlParams
		});
	}

	public create(request: GuardianAlertHistoryNoteCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianAlertHistoryNoteIndexResponse>(
			RtVueApiRoutes.GuardianAlertHistoryNotes.Create,
			{ body, disableNotification: true }
		);
	}

	public get(guardianAlertHistoryNoteId: number) {
		const urlParams: GuardianAlertHistoryNoteProfileRequest = {
			guardianAlertHistoryNoteId
		};

		return this.fetchWithRoute<GuardianAlertHistoryNoteIndexResponse>(
			RtVueApiRoutes.GuardianAlertHistoryNotes.Profile,
			{ urlParams }
		);
	}
}
