import { ServerGroupIndexResponse } from 'RtModels';
import { ServerGroupRouter } from 'RtUi/app/rtAdmin/ServerGroups/ServerGroup.router';
import { useGetServerGroups } from 'RtUi/app/rtAdmin/ServerGroups/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getServerGroupColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/serverGroups';
import { useMemo } from 'react';

interface IServerGroupDataGridProps {
	autoFocusFilter?: boolean;
}

export const ServerGroupDataGrid = ({}: IServerGroupDataGridProps) => {
	const { data, isLoading } = useGetServerGroups();

	const columns = useMemo(() => getServerGroupColumns(), []);

	return (
		<DataGrid<ServerGroupIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'rtAdmin-serverGroup'}
			router={ServerGroupRouter}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
