import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class CprLblTenDigit extends CprLbl {

	public readonly cprNodeTypeId = CprNodeType.TenDigit;

	public readonly valueLimit = 255;
	public readonly valueRegExp = /^\d{10}$/;

	protected readonly canLabel = true;

	/**
	 * @override
	 */
	 public isPossibleValue(rawVal: string) {
		const npaNxx = rawVal.substring(0, 6);

		return npaNxx in CprLergCache.CprLergByNpaNxx;
	}

	/**
	 * @override
	 */
	 public getUnknownValueMessage(): string {
		return 'Unknown NPA/NXX value.';
	}

	/**
	 * @override
	 */
	 public shouldHighlightValue(rawVal: string) {
		const tenDigitMatch = this.cpr.shouldHighlightValue(this.cprNodeTypeId, rawVal);

		if (tenDigitMatch) {
			return tenDigitMatch;
		}

		const npaNxx = rawVal.substring(0, 6);
		const npa = npaNxx.substring(0, 3);

		const npaNxxMatch = this.cpr.shouldHighlightValue(CprNodeType.NpaNxx, npaNxx);

		if (npaNxxMatch) {
			return npaNxxMatch;
		}

		const npaMatch = this.cpr.shouldHighlightValue(CprNodeType.AreaCode, npa);

		if (npaMatch) {
			return npaMatch;
	  }

		return undefined;
	}
}
