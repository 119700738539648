import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { ExportCprButton } from 'RtUi/app/rt800/Cprs/lib/components/ExportCprButton';
import { ImportCprBtn } from 'RtUi/app/rt800/Cprs/lib/components/ImportCprBtn';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { useGetCprActions } from 'RtUi/app/rt800/Cprs/lib/hooks/useGetCprActions';
import { UserActions } from 'RtUi/state/actions/user';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
interface ICprEditorActionsProps {
	cpr: Cpr;
}

export const CprEditorActions = ({ cpr }: ICprEditorActionsProps) => {
	const { router, tfns, triggerEditMode } = useContext(CprProfileContext);

	const { canCopy, canDelete, canEdit, canTransfer, canDisconnect } =
		useGetCprActions({ cpr, tfns });

	const copyPermissions = router.getCopyPermissions();
	const editPermissions = router.getEditPermissions();
	const transferPermissions = router.getTransferPermissions();
	const deletePermissions = router.getDeletePermissions();
	const disconnectPermissions = router.getDisconnectPermissions();

	const hasCopyPermissions = UserActions.has(...copyPermissions);
	const hasEditPermissions = UserActions.has(...editPermissions);
	const hasTransferPermissions = UserActions.has(...transferPermissions);
	const hasDeletePermissions = UserActions.has(...deletePermissions);
	const hasDisconnectPermissions = UserActions.has(...disconnectPermissions);

	const actionClasses = ['me-2', 'btn-hover', 'mb-2'];
	const actionClassName = actionClasses.join(' ');
	const actionStyles: React.CSSProperties = { minWidth: 100 };

	return (
		<section className="d-lg-flex justify-content-between align-items-center mt-4">
			<div>
				<ImportCprBtn style={actionStyles} className={actionClassName} />
				<ExportCprButton className={actionClassName} style={actionStyles} />
			</div>
			<div>
				{hasCopyPermissions && (
					<Button
						disabled={!canCopy}
						type="button"
						variant="primary"
						style={actionStyles}
						className={actionClassName}
						onClick={() => triggerEditMode('copy')}
					>
						<i className="fas fa-fw fa-copy" />

						<span>&nbsp;Copy</span>
					</Button>
				)}
				{hasEditPermissions && (
					<Button
						disabled={!canEdit}
						type="button"
						variant="primary"
						style={actionStyles}
						className={actionClassName}
						onClick={() => triggerEditMode('edit')}
					>
						<i className="fas fa-fw fa-pen" />

						<span>&nbsp;Edit</span>
					</Button>
				)}
				{hasTransferPermissions && (
					<Button
						disabled={!canTransfer}
						type="button"
						variant="primary"
						style={actionStyles}
						className={actionClassName}
						onClick={() => triggerEditMode('transfer')}
					>
						<i className="fas fa-fw fa-exchange-alt" />

						<span>&nbsp;Transfer</span>
					</Button>
				)}
				{hasDisconnectPermissions && router.getType() === 'template' && (
					<Button
						disabled={!canDisconnect}
						type="button"
						variant="primary"
						style={actionStyles}
						className={actionClassName}
						onClick={() => triggerEditMode('disconnect')}
					>
						<i className="fas fa-fw fa-plug" />

						<span>&nbsp;Disconnect</span>
					</Button>
				)}
				{hasDeletePermissions && (
					<Button
						disabled={!canDelete}
						type="button"
						variant="primary"
						style={actionStyles}
						className={actionClassName}
						onClick={() => triggerEditMode('delete')}
					>
						<i className="fas fa-fw fa-trash-alt" />

						<span>&nbsp;Delete</span>
					</Button>
				)}
			</div>
		</section>
	);
};
