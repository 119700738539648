export class AudioFileUtils {
	/**
	 * Given an audio file, get the duration in seconds of the audio
	 * @param file
	 */
	public getDurationFromFile(file: File) {
		const objectUrl = URL.createObjectURL(file);

		return this.getDuration(objectUrl);
	}

	/**
	 * Given an href address, get the duration in seconds of the audio link
	 * @param srcAddress
	 */
	public getDuration(srcAddress: string) {
		return new Promise<number>((resolve, reject) => {
			const audio = new Audio();

			audio.addEventListener('loadedmetadata', () => resolve(audio.duration), {
				once: true
			});
			audio.addEventListener('error', (event) => reject(event), {
				once: true
			});

			audio.src = srcAddress;
		});
	}
}
