import { memo, useEffect, useRef } from 'react';

interface IAutoHeightProps {
	className?: string;
	effectTimeoutInMs?: number;
	children: React.ReactNode;
}

const PREV_HEIGHT_ATTR_NAME = 'data-react-auto-height-start-value';
const updateHeight = (el: HTMLDivElement) => {
	// find descendants created by nested AutoHeights and adjust future height of current element by the future heights of children
	// skip for first render
	let adjustBy = 0;

	if (el.style.height) {
		el.setAttribute(PREV_HEIGHT_ATTR_NAME, el.style.height);

		const descendants = Array.from(el.firstElementChild?.children ?? []);

		for (let child of descendants) {
			const prevHeight = child.getAttribute(PREV_HEIGHT_ATTR_NAME);

			if (prevHeight) {
				if (child.firstElementChild) {
					child = child.firstElementChild; // skip the outer wrapper
				}

				adjustBy += child.scrollHeight - parseInt(prevHeight, 10);
			}

			if (child.children && child.children.length) {
				descendants.push(...Array.from(child.children));
			}
		}
	}

	const scrollHeight = el.firstElementChild?.scrollHeight ?? 0;
	const newHeight = scrollHeight + adjustBy;

	el.style.height = `${newHeight}px`;
};

const AutoHeight = ({
	children,
	className,
	effectTimeoutInMs = 15
}: IAutoHeightProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const classNames = ['react-auto-height', className];

	useEffect(() => {
		const { current: el } = ref;

		if (!el) {
			return;
		}

		// Wait for 15milliseconds for render to occur
		setTimeout(() => updateHeight(el), effectTimeoutInMs);
	});

	// inner div used in el.firstChild
	return (
		<div ref={ref} className={classNames.join(' ')}>
			<div>{children}</div>
		</div>
	);
};

export default memo(AutoHeight);
