import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { capitalCase } from 'change-case';
import {
	IInputFormControlProps,
	InputFormControl
} from 'RtUi/components/form/InputFormControl';

type AddressFieldsData = {
	address1: string;
	address2: string;
	city: string;
	state: string;
	zip: string;
};

type AddressFieldsProps = AddressFieldsData & {
	onChange?: (updatedFields: AddressFieldsData) => void;
	displayMode?: boolean;
};

export const AddressFields: FC<React.PropsWithChildren<AddressFieldsProps>> = (
	props
) => {
	const getInputFormControlPropsFor = <K extends keyof AddressFieldsData>(
		key: K
	): IInputFormControlProps => {
		return {
			displayMode: props.displayMode,
			value: props[key],
			label: capitalCase(key),
			onChange: (value) => {
				if (!props.onChange) {
					return;
				}

				props.onChange({
					address1: props.address1,
					address2: props.address2,
					city: props.city,
					state: props.state,
					zip: props.zip,
					[key]: value //updates the new value
				});
			}
		};
	};

	const makeRow = (...keys: Array<keyof AddressFieldsData>) => {
		const colSize = Math.floor(12 / keys.length);

		return (
			<Row>
				{keys.map((key) => (
					<Col lg={colSize} key={key}>
						<InputFormControl {...getInputFormControlPropsFor(key)} />
					</Col>
				))}
			</Row>
		);
	};

	return (
		<fieldset>
			{makeRow('address1', 'address2')}
			{makeRow('city', 'state', 'zip')}
		</fieldset>
	);
};
