import * as moment from 'moment-timezone';
import * as React from 'react';
import { EntityResource } from 'RtUi/app/rt800/Entities/lib/resources/EntityResource';
import { RespOrgResource } from 'RtUi/app/rt800/RespOrgs/lib/resources/RespOrgResource';
import {
	RespOrgTotalsByNpaResponse,
	EntityTotalsByNpaResponse
} from 'RtModels';
import { groupBy, meanBy } from 'lodash-es';
import { ChartWrapper } from 'RtUi/app/rtVue/common/lib/charts/ChartWrapper';
import { ChartConfiguration, ChartData, ChartDataset, Color } from 'chart.js';
import { Card, Col, Row } from 'react-bootstrap';
import { ChartJsColors } from 'RtUi/components/charts/Constants';

interface INpaTotalsLineChartProps {
	id: string;
	type: 'RespOrg' | 'Entity';
}

interface INpaTotalsLineChartState {
	linearProps?: ChartConfiguration;
}

const NpaToColorMapping: { [index: number]: Color } = {
	800: ChartJsColors.LightOrange,
	833: 'rgb(175,216,248)',
	844: 'rgb(203,75,75)',
	855: 'rgb(77,167,77)',
	866: 'rgb(148, 64, 236)',
	877: 'rgb(189, 155, 51)',
	888: 'rgb(140, 172, 198)'
};

const chartOptions: ChartConfiguration = {
	responsive: true,
	plugins: {
		//@ts-expect-error
		legend: {
			position: 'bottom'
		},
		tooltip: {
			mode: 'index',
			intersect: false,
			callbacks: {
				label: (context: any) => {
					const { datasetIndex, raw } = context;
					// eslint-disable-next-line no-underscore-dangle
					const chartConfig = context.chart.config._config;
					const dataSetPoint =
						typeof datasetIndex === 'number'
							? chartConfig.data.datasets[datasetIndex]
							: undefined;
					let customLabel =
						dataSetPoint && dataSetPoint.label ? dataSetPoint.label : '';

					if (customLabel) {
						customLabel += ': ';
					}

					const yData = typeof raw === 'number' ? raw : 0;

					customLabel += Math.round(yData).toLocaleString();

					return customLabel;
				}
			}
		}
	},
	scales: {
		y: {
			display: true,
			beginAtZero: false,
			title: {
				display: true,
				text: 'NPA Totals'
			}
		}
	}
};

export class NpaTotalsLineChart extends React.Component<
	INpaTotalsLineChartProps,
	INpaTotalsLineChartState
> {
	public state: INpaTotalsLineChartState = {};

	public componentIsMounted = false;

	public componentDidMount() {
		const { id, type } = this.props;

		if (type === 'Entity') {
			const resource = new EntityResource();
			resource
				.getTotalsByNpa(id)
				.then((npaRows) => this.loadChartData(npaRows));
		} else {
			const resource = new RespOrgResource();
			resource
				.getTotalsByNpa(id)
				.then((npaRows) => this.loadChartData(npaRows));
		}

		this.componentIsMounted = true;
	}

	public componentWillUnmount() {
		this.componentIsMounted = false;
	}

	public loadChartData<
		T extends RespOrgTotalsByNpaResponse | EntityTotalsByNpaResponse
	>(npaRows: T[]) {
		if (!this.componentIsMounted) {
			return;
		}

		const datasets: ChartDataset[] = [];
		let labels: string[] = [];

		if (npaRows.length > 0) {
			const npaKeys = Object.keys(npaRows[0]).filter((key) =>
				/^[0-9]{3}$/.test(key)
			);
			const npasByMonth = groupBy(npaRows, (npaRow) =>
				//cSpell:disable-next-line
				moment(npaRow.statusTs).format("MMM'YYYY")
			);
			const npaMonths = Object.keys(npasByMonth);

			npaKeys.forEach((npaKey) => {
				const npaMonthlyAverage = npaMonths.map((npaMonthKey) => {
					return Number(
						meanBy(
							npasByMonth[npaMonthKey],
							//@ts-expect-error
							(npaMonthRow) => npaMonthRow[npaKey]
						).toFixed(2)
					);
				});

				const npaDataSet: ChartDataset = {
					type: 'line',
					label: npaKey,
					backgroundColor: NpaToColorMapping[Number(npaKey)],
					fill: false,
					borderColor: NpaToColorMapping[Number(npaKey)],
					data: npaMonthlyAverage
				};

				datasets.push(npaDataSet);
			});

			labels = npaMonths;
		}

		const data: ChartData = { datasets, labels };

		const linearProps: ChartConfiguration = {
			type: 'line',
			data,
			options: chartOptions as any
		};
		this.setState({ linearProps });
	}

	public render() {
		const { linearProps } = this.state;

		if (!linearProps) {
			return null;
		}

		return (
			<Row>
				<Col xl={8} lg={10}>
					<Card body>
						<ChartWrapper config={linearProps} />
					</Card>
				</Col>
			</Row>
		);
	}
}
