import {
	TrackingGroupCreateRequest,
	TrackingGroupIndexResponse,
	TrackingGroupProfileResponse,
	TrackingGroupUpdateRequest,
	TrackingGroupProfileRequest
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class TrackingGroupResource extends HttpResource<
	TrackingGroupIndexResponse,
	TrackingGroupProfileResponse
> {
	constructor() {
		super(RtxSipApiRoutes.TrackingGroups);
	}

	public create(
		label: string,
		playLimit: number,
		dialLimit: number,
		expirationSeconds: number
	) {
		const req: TrackingGroupCreateRequest = {
			label,
			playLimit,
			dialLimit,
			expirationSeconds
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute<TrackingGroupProfileResponse>(
			RtxSipApiRoutes.TrackingGroups.Create,
			{ body }
		);
	}

	public update(
		trackingGroupId: number,
		label: string,
		playLimit: number,
		dialLimit: number,
		expirationSeconds: number
	) {
		const req: TrackingGroupUpdateRequest = {
			label,
			playLimit,
			dialLimit,
			expirationSeconds
		};
		const body = JSON.stringify(req);
		const urlParams: TrackingGroupProfileRequest = { trackingGroupId };

		return this.fetchWithRoute<TrackingGroupProfileResponse>(
			RtxSipApiRoutes.TrackingGroups.Update,
			{ body, urlParams }
		);
	}
}
