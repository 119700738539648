import { FC, useState } from 'react';
import { useMount } from 'react-use';
import { TemplateCprIndexResponse, TemplateProfileResponse } from 'RtModels';
import { CprGrid } from 'RtUi/app/rt800/Cprs/lib/grids/CprGrid';
import { NumberGrid } from 'RtUi/app/rt800/Numbers/lib/grids/NumberGrid';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { EditTemplate } from './lib/forms/EditTemplate';
import { TemplateResource } from './lib/resources/TemplateResource';
import { TemplateRouter } from './Template.router';
import { TemplateCprsRouter } from 'RtUi/app/rt800/Cprs/Cprs.router';

const templateResource = new TemplateResource();

export const TemplateProfileContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const templateId = useIdParam();

	const Tabs = TemplateRouter.getProfileTabs();
	const [activeTab, setActiveTab] = useState<string>(Tabs.Profile.header);
	const [template, setTemplate] = useState<TemplateProfileResponse>();

	useMount(() => {
		async function getTemplate() {
			const fetchedTemplate = await templateResource.get(templateId);
			setTemplate(fetchedTemplate);
		}

		getTemplate();
	});

	if (!template) {
		return null;
	}

	const templateName = template.templateName;

	return (
		<TabbedLayout<TemplateProfileResponse>
			router={TemplateRouter}
			profile={template}
			activeTab={activeTab}
			onTabChange={(activeTab) => {
				setActiveTab(activeTab);
			}}
		>
			<TabbedLayoutTab {...Tabs.Profile}>
				<EditTemplate
					template={template}
					onSuccess={(newTemplate) => {
						setTemplate(newTemplate);
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...Tabs.Numbers}>
				<NumberGrid resourceParams={{ templateName }} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...Tabs.Routing}>
				<CprGrid<TemplateCprIndexResponse>
					router={TemplateCprsRouter}
					resourceParams={{ templateName }}
					pageName="rt800_TemplatesProfileCpr"
					enableOutsideTabs={false}
				/>
			</TabbedLayoutTab>

			<TabbedLayoutHelpContainer tab={Tabs.Profile.header}>
				<dl>
					<dt>Summary</dt>
					<dd className="text-muted mb-2">
						User defined Summary description of the Template. This will be seen
						in the Activate and Re Route pull down menus. NOTE: This field is
						initially populated from the Description field inside Somos. The
						Description field in Somos is not modified when Summary is edited
						here.
					</dd>
					<dt>RespOrg</dt>
					<dd className="text-muted mb-2">
						{' '}
						this is the RespOrg used to restrict user access. If a user does not
						have permission to access the RespOrg defined, this template will
						not appear as an option for that user.
					</dd>
					<dt>Active/Inactive</dt>
					<dd className="text-muted mb-2">
						Inactive Templates will disappear from view for all users by
						default. Inactive templates are NOT deleted from Somos. Users can
						view Inactive Templates in Template view by modifying the search
						parameters by expanding the Templates: Search Criteria [+] on the
						top of the Templates view screen.
					</dd>
					<dt>Reserve</dt>
					<dd className="text-muted mb-2">
						Selecting Yes will allow this template to appear in the individual
						number search results pull down menu.
					</dd>
					<dt>Routing (LCR)</dt>
					<dd className="text-muted mb-2">
						Reserved for internal use related to future development on LCR
						Template create.
					</dd>
					<dt>Source</dt>
					<dd className="text-muted mb-2">
						Selecting Yes allows users to use this template a ‘working’ template
						in a Routing Group.
					</dd>
					<dt>Target</dt>
					<dd className="text-muted mb-2">
						Selecting Yes is required for this template to appear in the Re
						Route pull down menu. This is also required if this template is
						being used as a Alternate Routing Scenario for a Routing Group.
					</dd>
				</dl>
			</TabbedLayoutHelpContainer>
		</TabbedLayout>
	);
};

TemplateRouter.setProfileRtUiFunctionalComponent(TemplateProfileContainer);
