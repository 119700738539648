/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { IntegrationsApiRoutes } from 'RtExports/routes';
import {
	ResourceConfigurationProfileResponse,
	ResourceConfigurationProfileUrlRequest,
	ResourceConfigurationUpdateRequest,
	ResourceIndexRequest,
	ResourceIndexResponse,
	ResourceProfileRequest,
	ResourceProfileResponse
} from 'RtModels';
import { ResourceConfigurationResource } from 'RtUi/app/rtCommon/Resources/lib/resources/ResourceConfigurationResource';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';

type DataObject = {
	resourceId: number;
	data: ResourceConfigurationUpdateRequest;
};

const fetchResourceProfile = async (
	urlParams: ResourceProfileRequest
): Promise<ResourceProfileResponse | undefined> => {
	return handleGetRequest<ResourceProfileResponse>(
		IntegrationsApiRoutes.ResourcesNew.Profile,
		{
			urlParams
		}
	);
};

const fetchResourceConfigurationProfile = async (
	urlParams: ResourceConfigurationProfileUrlRequest
): Promise<ResourceConfigurationProfileResponse | undefined> => {
	return handleGetRequest<ResourceConfigurationProfileResponse>(
		IntegrationsApiRoutes.ResourceConfigurations.Profile,
		{
			urlParams
		}
	);
};

const fetchResources = async (
	queryParams?: ResourceIndexRequest
): Promise<ResourceIndexResponse[]> => {
	return handleGetRequest<ResourceIndexResponse[]>(
		IntegrationsApiRoutes.ResourcesNew.Index,
		{
			queryParams
		}
	);
};

export const putDidResource = async (dataObject: DataObject) => {
	const { resourceId, data } = dataObject;
	return new ResourceConfigurationResource().update(resourceId, data);
};

export const useGetResourceConfiguration = (
	urlParams: ResourceConfigurationProfileUrlRequest
) => {
	return useQuery<ResourceConfigurationProfileResponse | undefined>(
		[`useGetResourceConfiguration`, urlParams],
		() => fetchResourceConfigurationProfile(urlParams)
	);
};

export const useGetResource = (urlParams: ResourceProfileRequest) => {
	return useQuery<ResourceProfileResponse | undefined>(
		[`useGetResource`, urlParams],
		() => fetchResourceProfile(urlParams)
	);
};

export const useGetConnections = (queryParams?: ResourceIndexRequest) => {
	return useQuery<ResourceIndexResponse[], Error>(
		[`useGetConnections`, queryParams],
		() => fetchResources(queryParams)
	);
};
