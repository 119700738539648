import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ResourceResource } from '../../../../rtCommon/Resources/lib/resources/ResourceResource';
import {
	ResourceType,
	ResourceIndexRequest,
	ResourceProfileResponse
} from 'RtModels';

interface IResourceConnectionSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<ResourceProfileResponse, isMulti> {
	resourceType?: ResourceType;
	resourceParams?: ResourceIndexRequest;
}

export class ResourceConnectionSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	ResourceProfileResponse,
	isMulti,
	IResourceConnectionSelectProps<isMulti>
> {
	public resourceClass: any = ResourceResource;
	public state: ISelectFormControlState<ResourceProfileResponse> = {
		formLabel: 'Connection',
		valueKey: 'resourceId',
		labelKey: 'label'
	};

	public componentDidMount() {
		this.checkAndSetGetAllParams();

		super.componentDidMount();
	}

	public componentDidUpdate(
		prevProps: IResourceConnectionSelectProps<isMulti>
	) {
		this.checkAndSetGetAllParams();

		super.componentDidUpdate(prevProps);
	}

	/**
	 * @override
	 */
	public async getData() {
		let resources = await super.getData();
		const { resourceType } = this.props;

		if (typeof resourceType === 'number') {
			resources = resources.filter((r) => r.resourceTypeId === resourceType);
		}

		return resources;
	}

	public checkAndSetGetAllParams() {
		if (this.props.resourceParams) {
			this.setGetAllParams(this.props.resourceParams);
		}
	}
}
