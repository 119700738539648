import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	SipAgentAddressCreateRequest,
	SipAgentAddressDeleteResponse,
	SipAgentAddressIndexResponse,
	SipAgentAddressProfileResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class SipAgentAddressesResource extends HttpResource<
	SipAgentAddressIndexResponse,
	SipAgentAddressProfileResponse
> {
	constructor() {
		super(RtCommonApiRoutes.SipAgentAddresses);
	}

	public getProfile(
		sipAgentAddressId: number
	): Promise<SipAgentAddressProfileResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.SipAgentAddresses.Profile, {
			urlParams: {
				sipAgentAddressId
			}
		});
	}

	public create(
		req: SipAgentAddressCreateRequest
	): Promise<SipAgentAddressProfileResponse> {
		const body = JSON.stringify(req);

		return this.fetchWithRoute(RtCommonApiRoutes.SipAgentAddresses.Create, {
			body
		});
	}

	public update(
		sipAgentAddress: SipAgentAddressProfileResponse
	): Promise<SipAgentAddressProfileResponse> {
		const { sipAgentAddressId, ...req } = sipAgentAddress;
		const urlParams = {
			sipAgentAddressId
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute(RtCommonApiRoutes.SipAgentAddresses.Update, {
			urlParams,
			body
		});
	}

	public delete(
		sipAgentAddressId: number
	): Promise<SipAgentAddressDeleteResponse> {
		return this.fetchWithRoute(RtCommonApiRoutes.SipAgentAddresses.Delete, {
			urlParams: {
				sipAgentAddressId
			}
		});
	}
}
