import * as moment from 'moment-timezone';
import {
	EntityIndexResponse,
	EntityProfileResponse,
	EntityTotalsByNpaResponse,
	EntityUpdateRequest,
	EntityProfileRequest
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class EntityResource extends HttpResource<
	EntityIndexResponse,
	EntityProfileResponse
> {
	constructor() {
		super(Rt800ApiRoutes.Entities);
	}

	public update(entityId: string, label: string, isActive: number) {
		const entityUpdateRequest: EntityUpdateRequest = { label, isActive };
		const body = JSON.stringify(entityUpdateRequest);
		const urlParams: EntityProfileRequest = { entityId };

		return this.fetchWithRoute<EntityProfileResponse>(
			Rt800ApiRoutes.Entities.Update,
			{ body, urlParams }
		);
	}

	public getTotalsByNpa(entityId: string) {
		const urlParams: EntityProfileRequest = { entityId };

		return this.fetchWithRoute<EntityTotalsByNpaResponse[]>(
			Rt800ApiRoutes.EntityTotalsByNpa.Index,
			{ urlParams }
		).then((npaTotalRows) => {
			npaTotalRows.forEach((npaTotalRows) => {
				npaTotalRows.statusTs = moment(npaTotalRows.statusTs).toDate();
			});

			return npaTotalRows;
		});
	}
}
