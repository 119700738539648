/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DownloadIndexResponse = {
    properties: {
        downloadId: {
            type: 'number',
            isRequired: true,
        },
        downloadTypeId: {
            type: 'DownloadTypeId',
            isRequired: true,
        },
        fileLocationId: {
            type: 'FileLocation',
            isRequired: true,
        },
        region: {
            type: 'string',
            isRequired: true,
        },
        bucket: {
            type: 'string',
            isRequired: true,
        },
        fileName: {
            type: 'string',
            isRequired: true,
        },
        rowQty: {
            type: 'number',
            isRequired: true,
        },
        expiresTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};