import { take } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { useUpdate } from 'react-use';
import {
	AreaOfServiceLabelsMap,
	LabelsToAreaOfServiceMap
} from 'RtUi/app/rt800/Cprs/lib/resources/AreaOfServiceValuesResource';
import { DaysOfWeekRangeFormControl } from 'RtUi/components/form/DaysOfWeekRangeFormControl';
import { MonthDayRangeFormControl } from 'RtUi/components/form/MonthDayRangeFormControl';
import { TimeRangeFormControl } from 'RtUi/components/form/TimeRangeFormControl';
import {
	MultiDataList,
	TGetLabelStyleFn
} from 'RtUi/components/ui/MultiDataList';
import {
	AosNetwork,
	CprCol,
	CprColDate,
	CprColDayOfWeek,
	CprColPercent,
	CprColTimeOfDay,
	CprLbl,
	CprValidator,
	CprValue,
	CprValueHighlightType
} from 'Somos/lib/SomosCpr/RtCprV2';
import { AosColNetwork } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosColNetwork';

export type CprValidatorDataListTypes<CprValidatorType extends CprValidator> =
	CprValidatorType extends CprCol ? string[] | CprLbl : string[];

interface ICprValidatorDataListProps<CprValidatorType extends CprValidator> {
	validator: CprValidatorType;
	displayMode?: boolean;
	onChange?: (newValues: CprValidatorDataListTypes<CprValidatorType>) => void;
	className?: string;
	shownValuesLimit?: number;
}

const valuesToLabels = (
	validator: CprValidator,
	values: string[] | undefined = []
) => {
	let labels = [...values];

	if (validator instanceof AosColNetwork) {
		labels = labels.map(
			(val) => AreaOfServiceLabelsMap.get(val as AosNetwork) ?? val
		);
	}

	return labels;
};

const labelsToValues = (validator: CprValidator, labels: string[]) => {
	let values = [...labels];

	if (validator instanceof AosColNetwork) {
		values = values.map((val) => LabelsToAreaOfServiceMap.get(val) ?? val);
	}

	return values;
};

export function CprValidatorDataList<CprValidatorType extends CprValidator>({
	validator,
	displayMode,
	className,
	shownValuesLimit,
	onChange = () => ({})
}: ICprValidatorDataListProps<CprValidatorType>) {
	const isAosNetworkCol = validator instanceof AosColNetwork;
	const updateComponent = useUpdate();
	const values = validator.getRawValues();
	const labels = valuesToLabels(validator, values);

	// Update component when cpr has updated
	useEffect(() => {
		return validator.cpr.onValidate(() => {
			updateComponent();
		});
	});

	const suggestionsFn = (inputStr: string, hasFocus: boolean) => {
		if (!hasFocus) {
			return [];
		}

		const possibles = validator.getPossibles(inputStr, true, 8);
		const possibleLabels = valuesToLabels(validator, possibles);

		if (validator.allowOther) {
			possibleLabels.push('OTHER');
		}

		return possibleLabels;
	};

	const onChangeInternal = useCallback(
		(newValues: string[]) => {
			if (validator instanceof CprCol) {
				const firstValue: string | undefined = newValues[0];

				if (firstValue?.startsWith('*')) {
					const selectedLabel = validator.cpr.getCprLabel(firstValue);

					if (selectedLabel) {
						onChange(
							selectedLabel as CprValidatorDataListTypes<CprValidatorType>
						);
					}

					updateComponent();

					return;
				}
			}

			const limit = validator.valueLimit ?? 255;
			newValues = take(newValues, limit);
			newValues = labelsToValues(validator, newValues);

			onChange(newValues as CprValidatorDataListTypes<CprValidatorType>);
			updateComponent();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onChange, updateComponent, validator, validator.uuid]
	);

	const getHighlightStyle = (
		node: CprValue | CprLbl | undefined
	): ReturnType<TGetLabelStyleFn> => {
		if (!node) {
			return undefined;
		}

		if (node instanceof CprLbl && !node.existsOnCpr()) {
			return 'invalid';
		}

		if (node.hasErrors()) {
			return 'invalid';
		}

		if (node.isHighlighted()) {
			switch (node.getHighlightedTypeId()) {
				case CprValueHighlightType.Child:
					return 'child-match';
				case CprValueHighlightType.Parent:
					return 'parent-match';
				case CprValueHighlightType.Exact:
					return 'exact-match';
			}
		}
	};

	const getLabelStyle: TGetLabelStyleFn = (value: string) => {
		if (value.startsWith('*') && validator instanceof CprCol) {
			const label = validator.getCprLabel();

			if (label) {
				return getHighlightStyle(label);
			}
		}

		for (const cprValue of validator.getValues()) {
			if (cprValue.getValue() === value) {
				return getHighlightStyle(cprValue);
			}
		}
	};

	const onPaste = (clipboardData: string) => {
		if (validator.valueRegExp) {
			//Split on non-digit characters
			const possibles = clipboardData.split(/(\D)/);
			const matches: string[] = [];

			for (const possible of possibles) {
				// test to see if value can be a possible match
				if (validator.valueRegExp.test(possible)) {
					matches.push(possible);
				}
			}

			if (matches.length <= 0) {
				return;
			}

			onChangeInternal([...values, ...matches]);
		}
	};

	const onBlur = (
		evt: React.FocusEvent<HTMLInputElement>,
		setInputText: (newText: string) => void
	) => {
		if (validator instanceof CprColPercent) {
			if (evt.target.value) {
				const possiblePercentage = Number(evt.target.value);

				setInputText('');

				if (!isNaN(possiblePercentage)) {
					onChangeInternal([String(possiblePercentage)]);
				}
			}
		}
	};

	let inputType = 'text';
	let inputStep: number | undefined;
	let inputMax: number | undefined;
	let inputMin: number | undefined;

	if (validator instanceof CprColTimeOfDay) {
		return (
			<TimeRangeFormControl
				value={values}
				className={className}
				displayMode={displayMode}
				onChange={onChangeInternal}
			/>
		);
	}

	if (validator instanceof CprColDate) {
		return (
			<MonthDayRangeFormControl
				value={values}
				className={className}
				displayMode={displayMode}
				onChange={onChangeInternal}
			/>
		);
	}

	if (validator instanceof CprColDayOfWeek) {
		return (
			<DaysOfWeekRangeFormControl
				value={values}
				className={className}
				displayMode={displayMode}
				onChange={onChangeInternal}
			/>
		);
	}

	if (validator instanceof CprColTimeOfDay) {
		return (
			<TimeRangeFormControl
				value={values}
				className={className}
				displayMode={displayMode}
				onChange={onChangeInternal}
			/>
		);
	}

	if (validator instanceof CprColPercent) {
		inputType = 'number';
		inputMax = 99;
		inputMin = 1;
		inputStep = 1;
	}

	return (
		<MultiDataList
			inputType={inputType}
			inputStep={inputStep}
			inputMax={inputMax}
			inputMin={inputMin}
			upperCaseValueOnCreate={!isAosNetworkCol}
			shownValuesLimit={shownValuesLimit}
			className={className}
			getLabelStyle={getLabelStyle}
			displayMode={displayMode}
			onPaste={onPaste}
			onBlur={onBlur}
			onChange={onChangeInternal}
			suggestions={suggestionsFn}
			values={labels}
		/>
	);
}
