import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

// exported definitions
// ======================================================================

export class CprColGoTo extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.GoTo;

	public readonly allowOther = false;
	public readonly valueLimit = 1;
	public readonly valueRegExp = /^[S|M]/;

	public getValue(): string | null {
		if (this.cprValues.length !== 1) {
			return null;
		}
		return this.cprValues[0].getValue();
	}

	public isPossibleValue(rawVal: string) {
		// only validating by valueRegExp
		return true;
	}

}
