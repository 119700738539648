import { Close, ManageHistory, Save } from '@mui/icons-material';
import {
	Box,
	CircularProgress,
	IconButton,
	Popover,
	Tooltip
} from '@mui/material';
import { useState } from 'react';

interface IConfigurationStoreActionsProps {
	isDefaultConfigurationApplied: boolean;
	handleSaveConfigurations: () => Promise<void>;
	handleResetConfigurations: () => Promise<void>;
	isLoadingConfiguration?: boolean;
	isSavingConfiguration?: boolean;
}

export const ConfigurationStoreActions = ({
	isDefaultConfigurationApplied,
	handleResetConfigurations,
	handleSaveConfigurations,
	isLoadingConfiguration = false,
	isSavingConfiguration = false
}: IConfigurationStoreActionsProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const isLoading = isSavingConfiguration || isLoadingConfiguration;

	const getMainButton = () => {
		if (!isDefaultConfigurationApplied) {
			return (
				<Tooltip title="Manage stored configurations" arrow>
					<IconButton
						type="button"
						className="h-100"
						color="info"
						onClick={handleClick}
					>
						<ManageHistory />
					</IconButton>
				</Tooltip>
			);
		}

		return (
			<Tooltip title="Store configurations" arrow>
				<IconButton
					type="button"
					color="default"
					onClick={() => handleSaveConfigurations()}
					disabled={isSavingConfiguration}
				>
					<Save />
				</IconButton>
			</Tooltip>
		);
	};

	return (
		<>
			<Box sx={{ position: 'relative' }}>
				{getMainButton()}
				{isLoading && (
					<CircularProgress
						size={40}
						color="secondary"
						sx={{
							position: 'absolute',
							zIndex: 1,
							inset: 0
						}}
					/>
				)}
			</Box>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				<Box>
					<Tooltip title="Update stored configurations" arrow placement="right">
						<IconButton
							type="button"
							color="info"
							onClick={() => {
								handleClose();
								handleSaveConfigurations();
							}}
							disabled={isSavingConfiguration}
						>
							<Save />
						</IconButton>
					</Tooltip>
				</Box>
				<Box>
					<Tooltip title="Reset stored configurations" arrow placement="right">
						<IconButton
							type="button"
							color="default"
							onClick={() => {
								handleClose();
								handleResetConfigurations();
							}}
							disabled={isSavingConfiguration}
						>
							<Close />
						</IconButton>
					</Tooltip>
				</Box>
			</Popover>
		</>
	);
};
