/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum DashboardTaskId {
    RouteGroupAudit = 1,
    TemplateRoutingCprAudit = 2,
    TollFreeRoutingCprAudit = 3,
    IncomingRoc = 4,
    OutgoingRoc = 5,
    ManagedEntities = 6,
    ManagedRespOrgs = 7,
    FileStreamFileDownloadAudit = 8,
    FileStreamFileProcessAudit = 9,
    CarrierConnectLongJob = 10,
}