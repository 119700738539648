import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class MessagesSummaryRouterClass extends RtVueReportRouter<void, void, {}> {
	constructor() {
		super('SMS Summary', 'messagesSummary', undefined, {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewMessagesSummary);
	}

	/**
	 * By pass pluralization of name
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const MessagesSummaryRouter = new MessagesSummaryRouterClass();
