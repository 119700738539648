/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianUserCannedReportIndexRequest,
	GuardianUserQueryIndexResponse
} from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';

const fetchCannedReports = async (
	queryParams?: GuardianUserCannedReportIndexRequest
): Promise<GuardianUserQueryIndexResponse[]> => {
	return handleGetRequest(RtVueApiRoutes.GuardianCannedQueries.Index, {
		queryParams
	});
};

export const useGetCannedReports = (
	queryParams?: GuardianUserCannedReportIndexRequest
) => {
	return useQuery<GuardianUserQueryIndexResponse[], Error>(
		`getCannedReports`,
		() => {
			return fetchCannedReports(queryParams);
		}
	);
};
