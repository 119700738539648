import { FC, useContext } from 'react';
import { TabPane } from 'react-bootstrap';
import { useMount } from 'react-use';
import {
	TabLayoutContext,
	TabLayoutTab
} from 'RtUi/components/ui/TabbedLayout/TabLayoutContext';
import { UserActions } from 'RtUi/state/actions/user';

export type TabbedLayoutTabProps = TabLayoutTab;

export const TabbedLayoutTab: FC<
	React.PropsWithChildren<TabbedLayoutTabProps>
> = (props) => {
	const tabLayoutContext = useContext(TabLayoutContext);

	useMount(() => {
		tabLayoutContext.addTab(props);
	});

	if (props.rtRoute) {
		if (!UserActions.has(...props.rtRoute.permissions)) {
			return null;
		}
	} else if (props.permissions) {
		if (!UserActions.has(...props.permissions)) {
			return null;
		}
	}

	if (!props.alwaysRender) {
		if (props.header !== tabLayoutContext.activeTabHeader) {
			return null;
		}
	}

	return (
		<TabPane key={props.header} eventKey={props.header}>
			{props.children}
		</TabPane>
	);
};
