import { TrackingGroupEditor } from 'RtUi/app/rtSip/TrackingGroups/lib/forms/TrackingGroupEditor';
import { TrackingGroupGrid } from 'RtUi/app/rtSip/TrackingGroups/lib/grids/TrackingGroupGrid';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { TrackingGroupRouter } from './TrackingGroup.router';
import { TrackingGroupProfileResponse } from 'RtModels';

interface IInitialResourceParams {
	search: string;
}

interface ITrackingGroupsContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@TrackingGroupRouter.getBusinessOpsIndexRtUiController()
export class TrackingGroupIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	ITrackingGroupsContainerState
> {
	public initParams: IInitialResourceParams = {
		search: ''
	};

	public state: ITrackingGroupsContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { search } = this.state;
		const resourceParams: any = {};

		if (search) {
			resourceParams.search = search;
		}

		return resourceParams;
	}

	public goToTrackingGroupProfile(trackingGroup: TrackingGroupProfileResponse) {
		const trackingGroupProfilePath =
			TrackingGroupRouter.getProfileRoute(trackingGroup);

		this.goToPath(trackingGroupProfilePath);
	}

	public render() {
		return (
			<TabbedLayout
				router={TrackingGroupRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<InputFormControl
							label="Search"
							onChange={(search) => this.setState({ search })}
							value={this.state.search}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<TrackingGroupGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
				<TabbedLayoutTab header="Create Tracking Group" isAction>
					<TrackingGroupEditor
						createMode
						onCreateSuccess={(trackingGroup) =>
							this.goToTrackingGroupProfile(trackingGroup)
						}
					/>
				</TabbedLayoutTab>

				<TabbedLayoutHelpContainer tab={this.tabs.Results}>
					<p>
						Access to Routing Groups disaster recovery tool are based on your
						RespOrg id permissions.
					</p>
				</TabbedLayoutHelpContainer>
			</TabbedLayout>
		);
	}
}
