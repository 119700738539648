import { CprStatus, TaskNumberIndexResponse } from 'RtModels';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/Task.profile';
import { TaskNumberAside } from 'RtUi/app/rt800/Tasks/lib/components/TaskNumberAside';
import { useGetTaskNumberFutureCprs } from 'RtUi/app/rt800/Tasks/lib/service';
import { renderButtonBadge } from 'RtUi/app/rt800/Tasks/lib/utils';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { TollFreeNumbersCopyButton } from 'RtUi/components/data/DataGrid/components/TollFreeNumbersCopyButton';
import { getTaskNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/tasks';
import { useMemo, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Item } from 'react-contexify';

interface ITaskNumbersGridProps {
	task: ITaskProfile;
	taskNumbers: TaskNumberIndexResponse[];
	totalCount: number;
}

export const TaskNumbersGrid = ({
	task,
	taskNumbers,
	totalCount
}: ITaskNumbersGridProps) => {
	const { data: futureCprs = [] } = useGetTaskNumberFutureCprs({
		taskId: task.taskId
	});
	const [selectedResource, setSelectedResource] =
		useState<TaskNumberIndexResponse>();
	const [isAsideOpen, setIsAsideOpen] = useState<boolean>(false);

	const columns = useMemo(
		() => getTaskNumbersColumns(futureCprs, task.hasActivations),
		[futureCprs, task]
	);

	const renderNumbersStats = () => {
		const pendingFutureCprs = futureCprs.filter(
			(futureCpr) => futureCpr.cprStatusId === CprStatus.Pending
		);
		const sendingFutureCprs = futureCprs.filter(
			(futureCpr) => futureCpr.cprStatusId === CprStatus.Sending
		);
		const mustCheckFutureCprs = futureCprs.filter(
			(futureCpr) => futureCpr.cprStatusId === CprStatus.MustCheck
		);
		const invalidFutureCprs = futureCprs.filter(
			(futureCpr) => futureCpr.cprStatusId === CprStatus.Invalid
		);
		const failedFutureCprs = futureCprs.filter(
			(futureCpr) => futureCpr.cprStatusId === CprStatus.Failed
		);

		return (
			<>
				<article className="mb-4 d-lg-flex align-items-center flex-wrap">
					{renderButtonBadge(
						'Pending',
						pendingFutureCprs.length,
						undefined,
						'Metric may not be up to date; updated every 15 minutes.'
					)}
					{renderButtonBadge('Sending', sendingFutureCprs.length)}
					{renderButtonBadge('Must Check', mustCheckFutureCprs.length)}
					{renderButtonBadge('Invalid', invalidFutureCprs.length)}
					{renderButtonBadge('Failed', failedFutureCprs.length)}
				</article>
			</>
		);
	};

	const getFutures = (cprStatus: CprStatus) => {
		if (!selectedResource) {
			return [];
		}
		const { tfn } = selectedResource;

		const futureCprsForTfn = futureCprs.filter(
			(futureCpr) => futureCpr.tfn === tfn
		);
		return futureCprsForTfn.filter(
			(futureCpr) => futureCpr.cprStatusId === cprStatus
		);
	};

	return (
		<>
			{renderNumbersStats()}
			<DataGrid<TaskNumberIndexResponse>
				pageName="rt800_TaskNumbers"
				data={taskNumbers}
				totalRows={totalCount}
				columns={columns}
				rowThemeColor={(record) =>
					record.tfn === selectedResource?.tfn
						? RowThemeColor.WARNING
						: undefined
				}
				menuLinks={(resource) => (
					<Item
						onClick={() => {
							setSelectedResource(resource);
							setIsAsideOpen(true);
						}}
					>
						<span className="d-flex justify-content-start align-items-center">
							<i className="fas fa-fw fa-bolt" />
							<span>
								<span>Quick Number View</span>
							</span>
						</span>
					</Item>
				)}
				headerAction={(table) => (
					<TollFreeNumbersCopyButton table={table} numberKey="tfn" />
				)}
			/>
			{selectedResource && (
				<TaskNumberAside
					profile={selectedResource}
					isOpen={isAsideOpen}
					onCancel={() => {
						setSelectedResource(undefined);
						setIsAsideOpen(false);
					}}
					futuresContent={
						<ListGroup
							style={{ display: 'inline-grid' }}
							className="mb-2 flexy-items-list"
						>
							{renderButtonBadge(
								'Pending',
								getFutures(CprStatus.Pending).length,
								'sm',
								undefined,
								false
							)}
							{renderButtonBadge(
								'Sending',
								getFutures(CprStatus.Sending).length,
								'sm',
								undefined,
								false
							)}
							{renderButtonBadge(
								'Must Check',
								getFutures(CprStatus.MustCheck).length,
								'sm',
								undefined,
								false
							)}
							{renderButtonBadge(
								'Invalid',
								getFutures(CprStatus.Invalid).length,
								'sm',
								undefined,
								false
							)}
							{renderButtonBadge(
								'Failed',
								getFutures(CprStatus.Failed).length,
								'sm',
								undefined,
								false
							)}
						</ListGroup>
					}
				/>
			)}
		</>
	);
};
