/* eslint-disable @typescript-eslint/no-unsafe-call */
import { CprNodeTypeNameMap } from 'RtUi/app/rt800/Cprs/lib/Constants';
import { CprAreasOfService } from 'RtUi/app/rt800/Cprs/lib/components/CprAreasOfService';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import { EffectiveTimeFormControl } from 'RtUi/components/form/EffectiveTimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
import * as moment from 'moment-timezone';
import { forwardRef, useContext, useEffect } from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	OverlayTrigger,
	Row,
	Tooltip
} from 'react-bootstrap';
import { useUpdate } from 'react-use';
import { CarrierSelect } from '../controls/CarrierSelect';
import {
	TemplateContainerTabs,
	TemplateRouter
} from 'RtUi/app/rt800/Templates/Template.router';
import { useNavigate } from 'react-router-dom';

interface IConfigAdminDataProps {
	mgiProfile: Cpr;
	quickView?: boolean;
	nextEffectiveTs?: moment.Moment;
}

export const ConfigAdminData = forwardRef<
	HTMLFormElement,
	IConfigAdminDataProps
>(({ quickView, nextEffectiveTs, mgiProfile }, formRef) => {
	const summaryMaxLength = 40;
	const {
		editMode,
		isNumberAndHasTemplateAssigned: isPad,
		displayMode
	} = useContext(CprProfileContext);
	const updateComponent = useUpdate();
	const isInEditMode = editMode === 'edit';
	const isCopyOrTransfer =
		editMode === 'copy' || editMode === 'transfer' || editMode === 'disconnect';
	const targetEffectiveTs = mgiProfile.getTargetEffectiveTs();
	const navigate = useNavigate();

	let effectiveTsNowSubLabel: string | undefined;

	if (nextEffectiveTs) {
		const nextEffectiveTsStr = nextEffectiveTs.format('h:mma');
		effectiveTsNowSubLabel = `(Next effective time is ${nextEffectiveTsStr})`;
	}

	useEffect(() => {
		return mgiProfile.onValidate(() => {
			updateComponent();
		});
	});

	const summaryLength = mgiProfile.getSummary().length ?? 0;
	const notesLength = mgiProfile.getNotes().length ?? 0;
	const interLataCics = mgiProfile
		?.getInterLataCarriers()
		.map((cic) => ({ cic }));
	const intraLataCics = mgiProfile
		?.getIntraLataCarriers()
		.map((cic) => ({ cic }));
	const targetEffectiveTsDisplayMode = isInEditMode ? true : displayMode;

	if (!mgiProfile) {
		return null;
	}

	const sourceEffectiveMoment = moment(mgiProfile.getSourceEffectiveTs());

	const goToTemplateProfileRouting = () => {
		const templateName = mgiProfile?.getSourceTemplateName();

		if (!templateName) {
			return;
		}

		const templateRoute = TemplateRouter.getProfileRoute(
			templateName,
			TemplateContainerTabs.Routing.header
		);

		navigate(templateRoute);
	};

	return (
		<form
			ref={formRef}
			className="was-validated"
			onSubmit={(evt) => evt.preventDefault()}
		>
			{isPad && (
				<Alert variant="info" className="d-flex justify-content-start">
					<i className="fas fa-fw fa-info-circle me-2" />
					<span>
						This number is assigned to the template&nbsp;
						<a
							style={{ textDecoration: 'underline' }}
							onClick={() => goToTemplateProfileRouting()}
						>
							{mgiProfile.getSourceTemplateName()}
						</a>
						. Click the template&apos;s name to see it&apos;s routing profile.
					</span>
				</Alert>
			)}
			<Row>
				<Col lg={quickView ? 12 : 6}>
					<Card body={!quickView} className="mb-3">
						<EffectiveTimeFormControl
							label="Source Effective Timestamp"
							displayMode
							value={sourceEffectiveMoment}
						/>
						{isCopyOrTransfer && (
							<EffectiveTimeFormControl
								required
								label="Target Effective Timestamp"
								onChangeWithIsNow={(effectiveTsMoment, effectiveTsIsNow) => {
									if (effectiveTsIsNow) {
										mgiProfile.setTargetEffectiveTs(null);
									} else {
										mgiProfile.setTargetEffectiveTs(
											effectiveTsMoment?.toDate() ?? null
										);
									}

									updateComponent();
								}}
								displayMode={targetEffectiveTsDisplayMode}
								initAsNow={targetEffectiveTs === null}
								isAfter={moment()}
								value={
									targetEffectiveTs ? moment(targetEffectiveTs) : undefined
								}
								subLabel={!displayMode ? effectiveTsNowSubLabel : undefined}
							/>
						)}
						{!isPad && (
							<section className="d-flex justify-content-between">
								{!displayMode && (
									<article className="d-flex flex-column align-self-center me-3 mt-3">
										<i className="fas fa-lg fa-fw fa-rotate-270 fa-level-down-alt mb-3 ms-3" />
										<OverlayTrigger
											placement="right"
											overlay={(props) => (
												<Tooltip id="carrier-lock-btn-tooltip" {...props}>
													<span>
														Linked carriers will stay in sync.
														<br />
														Not linked carriers can have different values
													</span>
												</Tooltip>
											)}
										>
											{({ ref, ...triggerHandler }) => (
												<Button
													ref={ref}
													{...triggerHandler}
													className="border-dark"
													variant="outline-white"
													id="carrier-lock-btn"
													onClick={() => mgiProfile.toggleCarriersInSync()}
												>
													<i
														className={`fas fa-fw fa-${
															mgiProfile.areCarriersInSync() ? '' : 'un'
														}link`}
													/>
												</Button>
											)}
										</OverlayTrigger>
										<i className="fas fa-lg fa-fw fa-rotate-90 fa-level-up-alt mt-3 ms-3" />
									</article>
								)}
								<article className="flex-fill">
									<CarrierSelect<true>
										required
										multi
										label="InterLata Carriers"
										className="text-monospace"
										creatable
										displayMode={displayMode}
										disabled={displayMode}
										onChange={(interLataCarriers) =>
											mgiProfile.setInterLataCarriers(
												interLataCarriers.map(({ cic }: any) => cic)
											)
										}
										value={interLataCics}
									/>
									<CarrierSelect<true>
										required
										multi
										label="IntraLata Carriers"
										className="text-monospace"
										creatable
										displayMode={displayMode}
										disabled={displayMode}
										onChange={(intraLataCarriers) =>
											mgiProfile.setIntraLataCarriers(
												intraLataCarriers.map(({ cic }: any) => cic)
											)
										}
										value={intraLataCics}
									/>
								</article>
							</section>
						)}
						<Row>
							<Col md={6}>
								<InputFormControl
									label="Status"
									displayMode={true}
									value={mgiProfile.getCprStatusLabel()}
								/>
							</Col>
							<Col md={6}>
								<InputFormControl
									label="Approval"
									displayMode={true}
									value={mgiProfile.getApprovalIndicator()}
								/>
							</Col>
						</Row>
					</Card>
					<Card body={!quickView}>
						<Row>
							<Col md={6}>
								<InputFormControl
									label="Contact Name"
									displayMode={displayMode}
									onChange={(contactName) => {
										mgiProfile.setContactName(contactName);
										updateComponent();
									}}
									value={mgiProfile.getContactName()}
								/>
							</Col>
							<Col md={6}>
								<TollFreeNumberFormControl
									label="Contact Number"
									displayMode={displayMode}
									onlyNumbers
									onChange={(contactNumber) => {
										mgiProfile.setContactNumber(contactNumber);
										updateComponent();
									}}
									value={mgiProfile.getContactNumber()}
								/>
							</Col>
						</Row>

						<TextAreaFormControl
							label="Somos Description"
							displayMode={isPad ? true : displayMode}
							subLabel={`${summaryMaxLength - summaryLength} characters left`}
							onChange={(summary) => {
								mgiProfile.setSummary(summary);
								updateComponent();
							}}
							value={mgiProfile.getSummary()}
							maxLength={summaryMaxLength}
						/>
						<TextAreaFormControl
							label="Somos Notes"
							displayMode={isPad ? true : displayMode}
							subLabel={`${summaryMaxLength - notesLength} characters left`}
							onChange={(notes) => {
								mgiProfile.setNotes(notes);
								updateComponent();
							}}
							value={mgiProfile.getNotes()}
							maxLength={summaryMaxLength}
						/>
					</Card>
				</Col>
				{!isPad && (
					<Col lg={quickView ? 12 : 6}>
						<Card body={!quickView} className="mb-3">
							{quickView && (
								<InputFormControl
									label="CPR Columns"
									displayMode
									value={mgiProfile
										.getCprColNodeTypes()
										.map((nodeType) => CprNodeTypeNameMap.get(nodeType)!)
										.join(', ')}
								/>
							)}
							<h6 className="text-muted">Areas of Service</h6>
							<CprAreasOfService
								/* Quick view does not have mgi context */
								mgiProfile={quickView ? mgiProfile : undefined}
							/>
						</Card>
					</Col>
				)}
			</Row>
		</form>
	);
});
