/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $AsrLiteIndexResponse = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        customerAccountId: {
            type: 'number',
        },
        customerSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        customerExternalAccountId: {
            type: 'string',
        },
        customerExternalSubAccountId: {
            type: 'string',
        },
        vendorExternalAccountId: {
            type: 'string',
        },
        vendorExternalSubAccountId: {
            type: 'string',
        },
        ingressTrunkGroup: {
            type: 'string',
            isRequired: true,
        },
        ingressTrunkGroupLabel: {
            type: 'string',
        },
        vendorAccountId: {
            type: 'number',
        },
        vendorSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        egressTrunkGroup: {
            type: 'string',
            isRequired: true,
        },
        egressTrunkGroupLabel: {
            type: 'string',
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        ingressReleaseId: {
            type: 'number',
        },
        egressReleaseId: {
            type: 'number',
        },
        egressResultId: {
            type: 'string',
        },
        ingressResultId: {
            type: 'string',
        },
        ingressCountry: {
            type: 'string',
        },
        egressCountry: {
            type: 'string',
        },
        attempts: {
            type: 'number',
        },
        connects: {
            type: 'number',
            isRequired: true,
        },
        duration: {
            type: 'number',
            isRequired: true,
        },
        asr: {
            type: 'number',
        },
        aloc: {
            type: 'number',
        },
        roboscore: {
            type: 'number',
        },
        fraudscore: {
            type: 'number',
        },
        anomalyscore: {
            type: 'number',
        },
        routeAdvances: {
            type: 'number',
        },
        actualAsr: {
            type: 'number',
        },
        sub6: {
            type: 'number',
        },
    },
};