/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocActionItems = {
    properties: {
        approved: {
            type: 'RocActionItem',
            isRequired: true,
        },
        approveOnDueDate: {
            type: 'RocActionItem',
            isRequired: true,
        },
        cancelOnDueDate: {
            type: 'RocActionItem',
            isRequired: true,
        },
        customerNameMismatch: {
            type: 'RocActionItem',
            isRequired: true,
        },
        customerAddressMismatch: {
            type: 'RocActionItem',
            isRequired: true,
        },
        customerSignatureDateInvalid: {
            type: 'RocActionItem',
            isRequired: true,
        },
        customerSignatureMissing: {
            type: 'RocActionItem',
            isRequired: true,
        },
        customerSignatureUnauthorized: {
            type: 'RocActionItem',
            isRequired: true,
        },
        invalidRequest: {
            type: 'RocActionItem',
            isRequired: true,
        },
        loaIsIllegible: {
            type: 'RocActionItem',
            isRequired: true,
        },
        loaIsMissingOrInvalid: {
            type: 'RocActionItem',
            isRequired: true,
        },
        loaIsOutdated: {
            type: 'RocActionItem',
            isRequired: true,
        },
        respOrgMismatch: {
            type: 'RocActionItem',
            isRequired: true,
        },
        respOrgNotInControl: {
            type: 'RocActionItem',
            isRequired: true,
        },
        tollFreeIsBundledOrShared: {
            type: 'RocActionItem',
            isRequired: true,
        },
        tollFreeIsMissingFromLoa: {
            type: 'RocActionItem',
            isRequired: true,
        },
        removeDialNumbers: {
            type: 'RocActionItem',
            isRequired: true,
        },
        autoRejectedBySubmitter: {
            type: 'RocActionItem',
            isRequired: true,
        },
        tollFreeNumberInSpare: {
            type: 'RocActionItem',
            isRequired: true,
        },
        tollFreeNumberInUnavailable: {
            type: 'RocActionItem',
            isRequired: true,
        },
        tollFreeNumberInPortRestrictedRespOrg: {
            type: 'RocActionItem',
            isRequired: true,
        },
        dialNumberAlreadyInYourOrg: {
            type: 'RocActionItem',
            isRequired: true,
        },
        autoRejectedByHelpDesk: {
            type: 'RocActionItem',
            isRequired: true,
        },
    },
};