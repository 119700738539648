import {
	DirectConnectCreateRequest,
	DirectConnectIndexResponse,
	DirectConnectProfileRequest,
	DirectConnectProfileResponse,
	DirectConnectUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtxSipApiRoutes } from 'RtExports/routes';

export class DirectConnectResource extends HttpResource<
	DirectConnectIndexResponse,
	DirectConnectProfileResponse
> {
	constructor() {
		super(RtxSipApiRoutes.DirectConnect);
	}

	public create(request: DirectConnectCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<DirectConnectProfileResponse>(
			RtxSipApiRoutes.DirectConnect.Create,
			{ body }
		);
	}

	public update(directConnectId: number, request: DirectConnectUpdateRequest) {
		const body = JSON.stringify(request);
		const urlParams: DirectConnectProfileRequest = { directConnectId };

		return this.fetchWithRoute<DirectConnectProfileResponse>(
			RtxSipApiRoutes.DirectConnect.Update,
			{ body, urlParams }
		);
	}

	public activate(directConnectId: number) {
		const urlParams: DirectConnectProfileRequest = { directConnectId };

		return this.fetchWithRoute<DirectConnectProfileResponse>(
			RtxSipApiRoutes.DirectConnectActivate.Update,
			{
				urlParams,
				notificationMessage: 'Record has been activated successfully!'
			}
		);
	}

	public resetPassword(directConnectId: number) {
		const urlParams: DirectConnectProfileRequest = { directConnectId };

		return this.fetchWithRoute<DirectConnectProfileResponse>(
			RtxSipApiRoutes.DirectConnectResetPassword.Update,
			{
				urlParams,
				notificationMessage: 'Your password has been reset successfully!'
			}
		);
	}
}
