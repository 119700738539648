import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class RejectDetailsRevenueReportRouterClass extends RtVueReportRouter<
	void,
	void,
	{}
> {
	constructor() {
		super(
			'Reject Revenue Details Report',
			'rejectsRevenueDetails',
			undefined,
			{}
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewRejectDetailsRevenue);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const RejectDetailsRevenueReportRouter =
	new RejectDetailsRevenueReportRouterClass();
