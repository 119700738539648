import { RejectSummaryIndexResponse } from 'RtModels';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRejectSuspenseColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/rejectSuspense';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface IRejectDetailsGridProps {
	resource?: HttpResource<RejectSummaryIndexResponse> | null;
	isLoading?: boolean;
	onRevenueDrillDown: (row: RejectSummaryIndexResponse) => void;
	onCostCountDrillDown: (row: RejectSummaryIndexResponse) => void;
	callDateFormat?: string;
}

export const RejectDetailsGrid = ({
	resource,
	isLoading,
	onRevenueDrillDown,
	onCostCountDrillDown,
	callDateFormat
}: IRejectDetailsGridProps) => {
	const { data } = useQuery<RejectSummaryIndexResponse[] | undefined, Error>(
		['getRejectDetails', resource],
		() => resource?.getAll()
	);

	const columns = useMemo(
		() =>
			getRejectSuspenseColumns(
				onRevenueDrillDown,
				onCostCountDrillDown,
				callDateFormat
			),
		[onCostCountDrillDown, onRevenueDrillDown, callDateFormat]
	);

	return (
		<DataGrid<RejectSummaryIndexResponse>
			loading={isLoading}
			pageName="rtVue_rejects"
			data={data}
			columns={columns}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
