/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianFraudListUpdateRequest = {
    properties: {
        summary: {
            type: 'string',
        },
        dataSourceField: {
            type: 'string',
        },
        dataSourceValue: {
            type: 'string',
        },
        destinationType: {
            type: 'string',
        },
        destinationValue: {
            type: 'string',
            isNullable: true,
        },
        block: {
            type: 'number',
        },
        globalRule: {
            type: 'number',
        },
        isActive: {
            type: 'number',
        },
    },
};