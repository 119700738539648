import { RtCommonApiRoutes } from 'RtExports/routes';
import { EmailAliasIndexResponse, EmailAliasProfileResponse } from 'RtModels';
import { AdministrationRouter } from 'RtUi/app/Administration/Administration.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface EmailAliasIndexResponseContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const EmailAliasProfileContainerTabs: EmailAliasIndexResponseContainerTabs =
	{
		Profile: {
			header: 'Profile'
		}
	};

export class DistributionManagementRouterClass extends AdministrationRouter<
	EmailAliasIndexResponse,
	EmailAliasProfileResponse,
	EmailAliasIndexResponseContainerTabs
> {
	constructor() {
		super(
			'Distribution Management',
			'emailAliases',
			'aliasId',
			EmailAliasProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.EmailAlias);
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getIndexLabel(record: EmailAliasIndexResponse) {
		return record.label;
	}

	public getProfileLabel(profile: EmailAliasProfileResponse) {
		return profile.label;
	}
}

export const DistributionManagementRouter =
	new DistributionManagementRouterClass();
