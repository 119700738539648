/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ContactUpdateRequest = {
    properties: {
        firstName: {
            type: 'string',
            isRequired: true,
        },
        lastName: {
            type: 'string',
            isRequired: true,
        },
        emailAddress: {
            type: 'string',
            isRequired: true,
        },
        address1: {
            type: 'string',
        },
        address2: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        postalCode: {
            type: 'string',
        },
        countryId: {
            type: 'number',
            isRequired: true,
        },
        homePhone: {
            type: 'string',
        },
        workPhone: {
            type: 'string',
        },
        mobilePhone: {
            type: 'string',
        },
        isBilling: {
            type: 'number',
        },
        isMailing: {
            type: 'number',
        },
        isRates: {
            type: 'number',
        },
        isSales: {
            type: 'number',
        },
        isNoc: {
            type: 'number',
        },
        isProvisioning: {
            type: 'number',
        },
    },
};