import { UserIndexResponse } from 'RtModels';
import { WarningErrorListDataGrid } from 'RtUi/app/rt800/Cprs/lib/grids/WarningErrorListGrid';
import { useGetTemplateIvr } from 'RtUi/app/rt800/Cprs/lib/services';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { flattenDeep } from 'lodash-es';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

interface IInstantValidationFormProps {
	templateName: string;
	effectiveTs: Date;
}

export const InstantValidationForm = ({
	templateName,
	effectiveTs
}: IInstantValidationFormProps): JSX.Element => {
	const [user, setUser] = useState<UserIndexResponse>();
	const { data, isLoading } = useGetTemplateIvr(
		{ templateName },
		{ effectiveTs }
	);

	const warnErrorList = useMemo(() => flattenDeep(data?.warnErrorList), [data]);

	if (isLoading) {
		return <Loading />;
	}

	if (!data) {
		return <p>Not Found!</p>;
	}

	return (
		<>
			<Row>
				<Col md={6}>
					<InputFormControl
						label="Status"
						value={data.status ?? ''}
						displayMode
					/>
				</Col>
				<Col md={6}>
					<DatetimeFormControl
						label="EffectiveTs"
						value={moment(data.effectiveTs)}
						displayMode
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<InputFormControl
						label="Resp Org Id"
						value={data.respOrgId ?? ''}
						displayMode
					/>
				</Col>
				<Col md={6}>
					<InputFormControl
						label="Result"
						value={data.result ?? ''}
						displayMode
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<DatetimeFormControl
						label="Req Effective Ts"
						value={moment(data.reqEffectiveTs)}
						displayMode
					/>
				</Col>
				<Col md={6}>
					<DatetimeFormControl
						label="Last Active Ts"
						value={moment(data.lastActiveTs)}
						displayMode
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<InputFormControl
						label="Error Quantity"
						value={String(data.errorQty ?? 0)}
						displayMode
					/>
				</Col>
				<Col md={6}>
					<InputFormControl
						label="Warning Quantity"
						value={String(data.warningQty ?? 0)}
						displayMode
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<UserSelect
						value={user}
						onChange={setUser}
						initialOptionId={data.lastUser ?? undefined}
						displayMode
					/>
				</Col>
				<Col md={6}>
					<DatetimeFormControl
						label="Last Update Ts"
						value={moment(data.lastUpdateTs)}
						displayMode
					/>
				</Col>
			</Row>
			<Row>
				<h5 className="mt-3 mb-3 fw-bold">Error List</h5>
				<WarningErrorListDataGrid
					data={warnErrorList}
					pageName="rt800_cdrInstantValidation"
				/>
			</Row>
		</>
	);
};
