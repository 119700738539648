/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TrackingGroupIndexRequest = {
    properties: {
        search: {
            type: 'string',
        },
    },
};