import { GuardianReconciliationMappingIndexResponse } from 'RtModels';
import { GuardianReconciliationMappingsRouter } from 'RtUi/app/rtVue/GuardianReconciliationMapping/GuardianReconciliationMapping.router';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { GuardianReconciliationMappingResource } from '../resources/GuardianReconciliationMappingResource';

interface IGuardianReconciliationMappingSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<
		GuardianReconciliationMappingIndexResponse,
		IsMulti
	> {}
interface IGuardianReconciliationMappingSelectState
	extends ISelectFormControlState<GuardianReconciliationMappingIndexResponse> {}

export class GuardianReconciliationMappingSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	GuardianReconciliationMappingIndexResponse,
	IsMulti,
	IGuardianReconciliationMappingSelectProps<IsMulti>,
	IGuardianReconciliationMappingSelectState
> {
	public static defaultProps = {
		router: GuardianReconciliationMappingsRouter
	};
	public resourceClass = GuardianReconciliationMappingResource;
	public state: IGuardianReconciliationMappingSelectState = {
		formLabel: 'Reconciliation Mapping',
		valueKey: 'guardianReconciliationMappingId',
		labelKey: 'summary'
	};
}
