import { LergIndexRequest, LergIndexResponse } from 'RtModels';
import { useGetLergSomosData } from 'RtUi/app/rtVue/DialCode/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLergSomosColumns } from 'RtUi/components/data/DataGrid/configurations/administration/rateSheet/lergSomos';
import { useMemo } from 'react';

interface ISomosLergGridProps {
	resourceParams?: LergIndexRequest;
	autoFocusFilter?: boolean;
	header?: React.ReactNode;
}

export const SomosLergGrid = ({ resourceParams }: ISomosLergGridProps) => {
	const { data: lergSomosData, isLoading: lergSomosLoading } =
		useGetLergSomosData(resourceParams);

	const lergSomosGridColumns = useMemo(() => getLergSomosColumns(), []);

	return (
		<DataGrid<LergIndexResponse>
			data={lergSomosData?.data}
			columns={lergSomosGridColumns}
			pageName={'rateSheet-dialCode-lergSomos'}
			loading={lergSomosLoading}
			totalRows={lergSomosData?.totalCount}
		/>
	);
};
