import * as React from 'react';
import {
	Reports,
	TimeFilterDetailKeys,
	TimeRangePresets,
	UsageAuditByDayIndexResponse
} from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { UsageAuditByDayGrid } from 'RtUi/app/rtVue/UsageAuditByDayReport/lib/grids/UsageAuditByDayGrid';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { UsageAuditByDayRouter } from 'RtUi/app/rtVue/UsageAuditByDayReport/UsageAuditByDay.router';
import { GetTimeFilterDetailsForPresetHours } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { Loading } from 'RtUi/components/ui/Loading';

@UsageAuditByDayRouter.getReconciliationIndexRtUiController({
	orderPriority: 3
})
export class UsageAuditByDay extends RtVueReportContainer<UsageAuditByDayIndexResponse> {
	protected reportId = (Reports as any).UsageAuditByDay;
	protected router = UsageAuditByDayRouter;

	public defaultRangePreset: TimeRangePresets = TimeRangePresets.Week;

	public readonly DefaultTimeRange = GetTimeFilterDetailsForPresetHours(
		this.defaultRangePreset,
		true
	).find((timeRange) => timeRange.key === TimeFilterDetailKeys.Months);

	public state: IRtVueContainerState<UsageAuditByDayIndexResponse> = {
		...this.getBaseState({
			defaultTimeRangePreset: this.defaultRangePreset,
			defaultDetailLevel: TimeFilterDetailKeys.Months
		}),
		updateDetailOnDateChange: false,
		timeRangePresetsKeys: [
			TimeRangePresets.Week,
			TimeRangePresets.LastMonth,
			TimeRangePresets.Custom
		],
		timeRangeAvailableDetails: [
			TimeFilterDetailKeys.Days,
			TimeFilterDetailKeys.Months
		],
		noCustomFocusOnInit: true
	};

	protected async getResource(): Promise<
		HttpResource<UsageAuditByDayIndexResponse>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		} = this.state;

		const vueHttp = new RtVueHttp();
		const usageAudits = await vueHttp.getUsageAuditFilter(
			timeRange,
			filters,
			dataSums,
			preset,
			partition,
			additionalColumns?.map((column) => column.value)
		);
		const usageAuditResource = new ArrayResource<UsageAuditByDayIndexResponse>(
			'callDate',
			usageAudits
		);

		return usageAuditResource;
	}

	protected renderReports(): React.ReactNode {
		const {
			resource,
			timeRange: { detailLevel }
		} = this.state;

		if (!resource) {
			return <Loading />;
		}

		return (
			<UsageAuditByDayGrid
				isLoading={this.state.loadingReport}
				resource={resource}
				selectedDetailLevel={detailLevel.key}
				callDateFormat={this.getCallDateFormat()}
			/>
		);
	}
}
