import { FC } from 'react';
import { Aside } from 'RtUi/components/ui/Aside';
import { PortLimitFormEditor } from 'RtUi/app/rtSip/PortLimits/lib/forms/PortLimitFormEditor';
import { PortLimitProfileResponse } from 'RtModels';

import { Card } from 'react-bootstrap';

interface IAddPortLimitAsideProps {
	accountId: number;
	profile?: PortLimitProfileResponse;
	isOpen: boolean;
	onCancel: () => void;
	onSave: () => void;
}

export const AddPortLimitAside: FC<
	React.PropsWithChildren<IAddPortLimitAsideProps>
> = ({ isOpen, onCancel, accountId, profile, onSave }) => {
	return (
		<Aside isOpen={isOpen} disabledBody>
			<Aside.Header
				warning={Boolean(profile)}
				header={`${profile ? 'Edit' : 'Create'} Port Limit`}
				onClose={onCancel}
			/>
			<Card.Body>
				<PortLimitFormEditor
					profile={profile}
					accountId={accountId}
					onSuccess={onSave}
					onCancel={onCancel}
				/>
			</Card.Body>
		</Aside>
	);
};
