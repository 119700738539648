import { TimezoneIndexResponse } from 'RtModels';
import { TimezoneResource } from 'RtUi/app/rtCommon/Timezones/lib/resources/TimezoneResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface TimezoneDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		TimezoneIndexResponse,
		T
	> {}

export const TimezoneRenderCache = new ResourceCacheMap<TimezoneIndexResponse>(
	new TimezoneResource(),
	'timezoneId',
	'label'
);

export const TimezoneDataGridColumn = <T = any,>({
	...config
}: TimezoneDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: TimezoneRenderCache,
		...config
	});
};
