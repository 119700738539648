/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocDocumentIndexResponse = {
    properties: {
        documentId: {
            type: 'number',
            isRequired: true,
        },
        isLoa: {
            type: 'boolean',
            isRequired: true,
        },
        fileTitle: {
            type: 'string',
            isRequired: true,
        },
        notes: {
            type: 'string',
            isRequired: true,
        },
    },
};