import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	QosManagementIndexRequest,
	QosManagementIndexResponse,
	QosManagementUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class QosManagementResource extends HttpResource<QosManagementIndexResponse> {
	constructor() {
		super();
	}

	public get(data: QosManagementIndexRequest) {
		const body = JSON.stringify(data);

		return this.fetchWithRoute<QosManagementIndexResponse>(
			RtxSipApiRoutes.QosManagement.Index,
			{
				body
			}
		);
	}

	public update(data: QosManagementUpdateRequest) {
		const body = JSON.stringify(data);

		return this.fetchWithRoute<QosManagementIndexResponse>(
			RtxSipApiRoutes.QosManagement.Update,
			{
				body
			}
		);
	}
}
