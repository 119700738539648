import {
	CdrExportControlIndexRequest,
	CdrExportControlIndexResponse
} from 'RtModels';
import { useGetRecurringExports } from 'RtUi/app/Administration/CdrRecurringExport/lib/service';
import { ICdrExportTypeIndex } from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportTypeResource';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRecurringExportColumns } from 'RtUi/components/data/DataGrid/configurations/administration/recurringExport';
import { useMemo } from 'react';

interface ICdrRecurringExportGridProps {
	router: RtUiRouter;
	resourceParams?: CdrExportControlIndexRequest;
	cdrExportType?: ICdrExportTypeIndex;
}

export const CdrRecurringExportGrid = ({
	router,
	cdrExportType
}: ICdrRecurringExportGridProps) => {
	const { data, isLoading } = useGetRecurringExports({
		cdrExportTypeId: cdrExportType?.exportTypeId
	});

	const columns = useMemo(() => getRecurringExportColumns(), []);

	return (
		<DataGrid<CdrExportControlIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'configureRateSheet-cdrRecurringExport'}
			router={router}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
