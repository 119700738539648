import { RtVueApiRoutes } from 'RtExports/routes';
import { TermSummaryIndexResponseData } from 'RtModels';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class TermSummaryRouterClass extends RtVueReportRouter<
	TermSummaryIndexResponseData,
	void,
	{}
> {
	constructor() {
		super('Term Summary', 'termSummary', 'callDate', {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewTermSummary);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const TermSummaryRouter = new TermSummaryRouterClass();
