import { TaskTypeIndexResponse } from 'RtModels';
import { TaskResource } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface TaskTypeDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		TaskTypeIndexResponse,
		T
	> {}

export const TaskTypeRenderCache = new ResourceCacheMap<TaskTypeIndexResponse>(
	new TaskResource().getTaskTypeResource(),
	'taskTypeId',
	'label'
);

export const TaskTypeDataGridColumn = <T = any,>({
	...config
}: TaskTypeDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: TaskTypeRenderCache,
		...config
	});
};
