/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $UserAuditIndexResponse = {
    properties: {
        auditTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        name: {
            type: 'string',
            isRequired: true,
        },
        changeType: {
            type: 'string',
            isRequired: true,
        },
        objectType: {
            type: 'string',
            isRequired: true,
        },
        recordId: {
            type: 'number',
            isRequired: true,
        },
        changes: {
            type: 'string',
            isRequired: true,
        },
    },
};