import * as moment from 'moment-timezone';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import {
	GuardianConnectionSubscriptionProfileResponse,
	GuardianConnectionUpdateOptions,
	SubscriptionIndexResponse
} from 'RtModels';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { NO_TZ_FORMAT } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { DirectionFormControl } from 'RtUi/app/rtVue/common/lib/controls/DirectionFormControl';
import {
	CallTypesSelect,
	ICallTypeSelectOption
} from 'RtUi/app/rtVue/Connections/lib/controls/CallTypesSelect';
import { ConnectionSubscriptionResource } from 'RtUi/app/rtVue/ConnectionSubscription/lib/resources/ConnectionSubscriptionResource';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';

interface IConnectionSubscriptionEditorProps {
	connectionId?: number;
	profile?: GuardianConnectionSubscriptionProfileResponse;
	onSuccess?: (profile: GuardianConnectionSubscriptionProfileResponse) => void;
}
interface IConnectionSubscriptionEditorState {
	isSubmitting: boolean;
	displayMode: boolean;
	guardianConnectionId: number;
	subscription?: SubscriptionIndexResponse;
	callType?: ICallTypeSelectOption;
	directionId: number;
	startDate: moment.Moment;
	endDate?: moment.Moment;
	isActive: number;
	updateOption: GuardianConnectionUpdateOptions;
	error?: any;
}

export class ConnectionSubscriptionFormEditor extends Component<
	IConnectionSubscriptionEditorProps,
	IConnectionSubscriptionEditorState
> {
	private resource = new ConnectionSubscriptionResource();

	constructor(props: IConnectionSubscriptionEditorProps) {
		super(props);

		this.state = this.getInitialState(props);
		if (this.props.profile) {
			(this.state as any).isActive = this.props.profile.isActive;
		}
	}

	public render() {
		const createMode = !this.props.profile;

		return (
			<>
				<RtUiNarrowForm
					createMode={createMode}
					isSubmitting={this.state.isSubmitting}
					displayMode={this.state.displayMode}
					error={this.state.error}
					onChange={(displayMode) => this.setState({ displayMode })}
					onCancel={this.onCancel}
					onSubmit={this.onSubmit}
				>
					<InputFormControl
						required
						label="Connection ID"
						type="number"
						displayMode
						onChange={(guardianConnectionId) => {
							this.setState({
								guardianConnectionId: Number(guardianConnectionId)
							});
						}}
						value={String(this.state.guardianConnectionId)}
					/>
					<SubscriptionSelect
						required
						displayMode={this.state.displayMode}
						onChange={(subscription) => this.setState({ subscription })}
						value={this.state.subscription}
						initialOptionId={this.props.profile?.subscriptionId}
					/>
					<CallTypesSelect
						required
						onChange={(callType) => {
							this.setState({ callType });
						}}
						clearable={true}
						displayMode={this.state.displayMode}
						value={this.state.callType}
						initialOptionId={
							this.props.profile ? this.props.profile.callType : undefined
						}
					/>
					<DirectionFormControl
						callType={this.state.callType?.callTypeId}
						displayMode={this.state.displayMode}
						onChange={(directionId) => this.setState({ directionId })}
						value={this.state.directionId}
						required
					/>
					<DatetimeFormControl
						required={true}
						showDateOnly
						label="Start"
						displayMode={this.state.displayMode}
						onChange={(startDate) => {
							this.setState({ startDate });
						}}
						value={this.state.startDate}
					/>
					<DatetimeFormControl
						showDateOnly
						label="End"
						displayMode={this.state.displayMode}
						onChange={(endDate) => this.setState({ endDate })}
						value={this.state.endDate}
					/>
					<IsActiveRadioFormControl
						hideBothOption={true}
						label="Active"
						onChange={(isActive) => this.setState({ isActive })}
						displayMode={this.state.displayMode}
						value={this.state.isActive}
					/>
					{!this.state.displayMode && (
						<Alert variant="warning">
							<RadioFormControl<GuardianConnectionUpdateOptions>
								displayMode={this.state.displayMode}
								label="Apply Changes To"
								value={this.state.updateOption}
								onChange={(updateOption) => this.setState({ updateOption })}
								options={[
									{
										value: GuardianConnectionUpdateOptions.ThisTrunkGroupOnly,
										label: 'This trunk group only'
									},
									{
										value:
											GuardianConnectionUpdateOptions.AllTrunkGroupsInThisSwitch,
										label:
											'All trunk groups in this switch with this trunk group number'
									},
									{
										value: GuardianConnectionUpdateOptions.AllTrunkGroups,
										label:
											'All trunk groups with this number across ALL switches (dangerous)'
									}
								]}
							/>
						</Alert>
					)}
				</RtUiNarrowForm>
				<Confirmation />
			</>
		);
	}

	private getInitialState(
		props: IConnectionSubscriptionEditorProps
	): IConnectionSubscriptionEditorState {
		return {
			isSubmitting: false,
			displayMode: Boolean(props.profile),
			directionId: props.profile ? props.profile.directionId : 1,
			guardianConnectionId: props.connectionId
				? props.connectionId
				: props.profile?.guardianConnectionId!,
			startDate: props.profile
				? moment(props.profile.startTs).hour(0).minute(0).second(0)
				: moment(new Date()).hour(0).minute(0).second(0),
			endDate:
				props.profile &&
				moment(props.profile?.endTs).hour(23).minute(59).second(59),
			isActive: 1,
			updateOption: GuardianConnectionUpdateOptions.ThisTrunkGroupOnly
		};
	}

	private createConnectionSubscription = async () => {
		const { onSuccess = () => {} } = this.props;

		try {
			let profile: GuardianConnectionSubscriptionProfileResponse;
			this.setState({ isSubmitting: true, error: undefined });

			const updateRequest = {
				guardianConnectionId: this.state.guardianConnectionId,
				subscriptionId: this.state.subscription?.subscriptionId!,
				directionId: this.state.directionId,
				callType: this.state.callType?.callTypeId!,
				startTs: this.state.startDate
					.set({ hour: 0, minute: 0, second: 0 })
					.format(NO_TZ_FORMAT) as unknown as Date,
				isActive: this.state.isActive,
				endTs: this.state.endDate
					? (this.state.endDate
							.set({ hour: 23, minute: 59, second: 59 })
							.format(NO_TZ_FORMAT) as unknown as Date)
					: undefined,
				updateOption: this.state.updateOption
			};

			if (!this.props.profile) {
				profile = await this.resource.create(updateRequest);
			} else {
				profile = await this.resource.update(
					this.props.profile.guardianConnectionSubscriptionId,
					updateRequest
				);
			}
			this.setState({ displayMode: true, isSubmitting: false }, () => {
				onSuccess(profile);
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	};

	private onCancel = () => {
		this.setState(this.getInitialState(this.props));
	};

	private onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (
			this.state.isActive &&
			this.state.updateOption !==
				GuardianConnectionUpdateOptions.ThisTrunkGroupOnly
		) {
			this.setState({ isSubmitting: true });
			const recordsCount = await this.resource.getConnectionSubsCount({
				guardianConnectionId: this.state.guardianConnectionId,
				updateOption: this.state.updateOption
			});
			const confirm = await Confirmation.create(
				<span>
					<p>
						This Rate Plan is set to active. This means all other Rate Plans
						with the same combination of Connection, Subscription and Call Type
						will be deactivated.
					</p>
					<b>
						<p>{`${recordsCount + 1} records will be updated!`}</p>
					</b>
					<p>Would you like to continue?</p>
				</span>
			);

			if (!confirm) {
				this.setState({ isSubmitting: false });
				return;
			}
		}

		this.createConnectionSubscription();
	};
}
