import moment from 'moment';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { CdrOneTimeExportResource } from 'RtUi/app/Administration/CdrOneTimeExport/lib/resources/CdrOneTimeExportResource';
import { CdrExportRetentionSelect } from 'RtUi/app/rtSip/CdrExports/lib/controls/CdrExportRetentionSelect';
import { CdrExportTypeSelect } from 'RtUi/app/rtSip/CdrExports/lib/controls/CdrExportTypeSelect';
import { ICdrExportRetentionIndex } from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportRetentionResource';
import { ICdrExportTypeIndex } from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportTypeResource';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import {
	AccountIndexResponse,
	CdrExportSourceTypes,
	SubscriptionIndexResponse
} from 'RtModels';
import { CdrExportTypeIdsControl } from 'RtUi/app/rtSip/CdrExports/lib/controls/CdrExportTypeIdsRadioFormControl';

export const CdrOneTimeExportForm: FC<React.PropsWithChildren> = () => {
	const navigate = useNavigate();
	const yesterdayDate = moment().subtract(1, 'days');
	const [cdrExportType, setCdrExportType] = useState<ICdrExportTypeIndex>();
	const [cdrRetention, setCdrRetention] = useState<ICdrExportRetentionIndex>();
	const [cdrSourceTypeId, setCdrSourceTypeId] = useState<
		CdrExportSourceTypes | undefined
	>();
	const [cdrSourceValue, setCdrSourceValue] = useState<number>();
	const [cdrAccount, setCdrAccount] = useState<AccountIndexResponse>();
	const [cdrSubscription, setCdrSubscription] =
		useState<SubscriptionIndexResponse>();
	const [isConnected, setIsConnected] = useState<number>();
	const [startDateTime, setStartDateTime] = useState(
		yesterdayDate.clone().startOf('day')
	);
	const [endDateTime, setEndDateTime] = useState(
		yesterdayDate.clone().endOf('day')
	);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [error, setError] = useState<any>();

	const maxDate = startDateTime.clone().add(90, 'days');

	const submitHandler = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();
		setIsSubmitting(true);
		setError(undefined);

		const cdrOneTimeExportResource = new CdrOneTimeExportResource();

		if (!cdrExportType || !isConnected || !cdrRetention) {
			return;
		}

		try {
			await cdrOneTimeExportResource.create(
				cdrExportType.exportTypeId,
				isConnected,
				cdrRetention.retentionId,
				startDateTime.toDate(),
				endDateTime.toDate(),
				cdrSourceTypeId,
				cdrSourceValue
			);

			navigate('/rtSip/cdrExports');
		} catch (e) {
			setError(e);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<RtUiNarrowForm
			createMode={true}
			isSubmitting={isSubmitting}
			error={error}
			onSubmit={submitHandler}
		>
			<CdrExportTypeSelect
				value={cdrExportType}
				required
				onChange={(value) => setCdrExportType(value)}
			/>
			<CdrExportRetentionSelect
				value={cdrRetention}
				required
				onChange={(value) => setCdrRetention(value)}
			/>
			<CdrExportTypeIdsControl
				label="Filter Type"
				noneOption={true}
				value={cdrSourceTypeId}
				onChange={(value) => {
					setCdrSourceTypeId(value);
					setCdrSourceValue(undefined);
				}}
			/>
			{cdrSourceTypeId === CdrExportSourceTypes.Account && (
				<AccountSelect
					required
					showAllOption
					onChange={(account) => {
						setCdrAccount(account);
						setCdrSourceValue(account.accountId);
					}}
					value={cdrAccount}
				/>
			)}
			{cdrSourceTypeId === CdrExportSourceTypes.Subscription && (
				<SubscriptionSelect
					required
					showAllOption
					onChange={(subscription) => {
						setCdrSubscription(subscription);
						setCdrSourceValue(subscription.subscriptionId);
					}}
					value={cdrSubscription}
				/>
			)}
			<RadioFormControl<number>
				label="Is Connected"
				value={isConnected}
				required
				options={[
					{ value: 1, label: 'Yes' },
					{ value: 0, label: 'No' }
				]}
				onChange={(value) => setIsConnected(value)}
			/>
			<DatetimeFormControl
				label="Start Time"
				required
				value={startDateTime}
				onChange={(value) => setStartDateTime(value)}
			/>
			<DatetimeFormControl
				label="End Time"
				required
				value={endDateTime}
				minDate={startDateTime}
				maxDate={maxDate}
				onChange={(value) => setEndDateTime(value)}
			/>
		</RtUiNarrowForm>
	);
};
