import { type AosLbl } from 'Somos/lib/SomosCpr/RtCprV2/AosLbl/AosLbl';
import { type AosNodeType, CprSection } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprValidator } from 'Somos/lib/SomosCpr/RtCprV2/CprValidator';

// exported definitions
// ======================================================================

export abstract class AosCol extends CprValidator {

	public abstract readonly aosNodeTypeId: AosNodeType;
	public readonly cprSectionId = CprSection.CprSectionAos;

	protected label: AosLbl | null = null;

	public getAosLabel(): AosLbl | null {
		return this.label;
	}

	public hasAosLabel(): boolean {
		return (this.label) ? true : false;
	}

	public setAosLabel(newLabel: AosLbl, doValidation: boolean = true): boolean {
		this.cprValues = [];
		this.label = newLabel;
		this.cpr.validate();
		return this.hasErrors();
	}

	public hasValues(useLabel: boolean = false): boolean {
		if (this.label) {
			return (useLabel) ? this.label.hasValues() : false;
		}
		return this.cprValues.length > 0;
	}

	public setValues(newValues: string[]): boolean {
		if (this.label) {
			this.label = null;
		}

		// pause cpr validation until after setAosLerg
		const doValidation = this.cpr.doValidation();

		if (doValidation) {
			this.cpr.setValidation(false);
		}

		// always call super.setValues, do not set values directly.
		super.setValues(newValues);

		this.cpr.setAosLerg();

		// DO NOT override resetPossbiles unless the values are dynamic based on other selections.
		this.resetPossibles();

		if (doValidation) {
			this.cpr.setValidation(doValidation);
			this.cpr.validate();
		}

		return this.hasErrors();
	}

	public validate(): boolean {
		if (this.label) {
			if (this.cprValues.length > 0) {
				this.addError(null, `A value that contains a label cannot contain other values.`);
			}

			return this.label.validate(); // validNestedValidation
		}

		// call super.validate before doing extra validation
		return super.validate(); // validNestedValidation
	}

	protected addValue(newValue: string, doValidation: boolean = true): boolean {
		newValue = newValue.trim().toUpperCase();

		if (newValue.indexOf('*') !== 0) {
			// not a label
			// always call super.setValues, do not set values directly.
			return super.addValue(newValue);
		}

		if (!this.canLabel) {
			return false;
		}

		let label = this.cpr.getAosLabel(newValue);

		if (label) {
			return this.setAosLabel(label, doValidation);
		}

		label = this.cpr.makeAosLabel(this.aosNodeTypeId, newValue);

		if (!label) {
			return false;
		}

		return this.setAosLabel(label, doValidation);
	}

}
