import { FC, useEffect, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import {
	DataFilter,
	DataFilterOperator,
	DataSources,
	SearchField
} from 'RtModels';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface IVueAdvancedSearchFilters {
	filters: DataFilter[];
	advancedSearchFields: SearchField[];
	onChange?: (filter: DataFilter) => void;
}

interface IVueAdvancedSearchValue extends SearchField {
	value: string;
	select?: ISimpleSelectFormControlOption<DataFilterOperator>;
}

const operatorOptions: Array<
	ISimpleSelectFormControlOption<DataFilterOperator>
> = [
	{
		label: 'Greater Than',
		value: DataFilterOperator.GreaterThan
	},
	{
		label: 'Less Than',
		value: DataFilterOperator.LessThan
	}
];

export const VueAdvancedSearchFilters: FC<
	React.PropsWithChildren<IVueAdvancedSearchFilters>
> = ({ filters, advancedSearchFields, onChange = () => null }) => {
	const [advancedFieldValues, setAdvancedFieldValues] =
		useState<Map<string, IVueAdvancedSearchValue>>();

	useEffect(() => {
		const map = new Map(
			advancedSearchFields.map((asv) => {
				const key = `${asv.key}_${asv.label}`;
				const value: IVueAdvancedSearchValue = { ...asv, value: '' };

				return [key, value];
			})
		);

		setAdvancedFieldValues(map);
	}, [advancedSearchFields]);

	useEffect(() => {
		setAdvancedFieldValues((currentState) => {
			const currentValues = new Map(currentState);
			let updated = false;

			[...currentValues].forEach(([key, value]) => {
				const filter = filters.find((filter) => filter.key === value.key);
				const currentValue = currentValues.get(key) as IVueAdvancedSearchValue;
				if (
					!filter &&
					(currentValue.value !== '' || currentValue.select !== undefined)
				) {
					updated = true;
					currentValue.value = '';
					currentValue.select = undefined;
				}
			});

			if (updated) {
				return currentValues;
			}

			return currentState;
		});
	}, [filters, advancedFieldValues]);

	const onChangeFilterHandler = (
		value: string,
		complexKey: string,
		asv: SearchField,
		dataSource: DataSources = DataSources.Text
	) => {
		setAdvancedFieldValues((currentFields) => {
			const advancedValuesCopy = new Map(currentFields);
			const currentField = advancedValuesCopy.get(
				complexKey
			) as IVueAdvancedSearchValue;
			currentField.value = value;

			const filter: DataFilter = {
				key: asv.key,
				operator:
					currentField.select?.value ||
					currentField.operator ||
					DataFilterOperator.GreaterThan,
				operands: [{ value, dataSource }],
				advanced: true
			};

			onChange(filter);

			return advancedValuesCopy;
		});
	};

	const onChangeRangeFilterHandler = (
		value: ISimpleSelectFormControlOption<DataFilterOperator>,
		complexKey: string,
		asv: SearchField
	) => {
		setAdvancedFieldValues((currentFields) => {
			const advancedValuesCopy = new Map(currentFields);
			const currentField = advancedValuesCopy.get(
				complexKey
			) as IVueAdvancedSearchValue;
			currentField.select = value;

			const filter: DataFilter = {
				key: asv.key,
				operator: currentField.select.value,
				operands: [
					{
						value: currentField.value || '',
						dataSource: DataSources.Text
					}
				],
				advanced: true
			};

			onChange(filter);

			return advancedValuesCopy;
		});
	};

	const onClearFilterHandler = (complexKey: string, asv: SearchField) => {
		setAdvancedFieldValues((currentFields) => {
			const advancedValuesCopy = new Map(currentFields);
			const currentField = advancedValuesCopy.get(
				complexKey
			) as IVueAdvancedSearchValue;
			currentField.select = undefined;
			currentField.value = '';

			const filter: any = {
				key: asv.key,
				operator: undefined,
				operands: [
					{
						value: '',
						dataSource: DataSources.Text
					}
				],
				advanced: true
			};

			onChange(filter);

			return advancedValuesCopy;
		});
	};

	return (
		<Dropdown align="end">
			<Dropdown.Toggle bsPrefix="m-0" variant="link">
				Advanced Filters
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item style={{ textAlign: 'right' }}>
					<i className="fas fa-fw fa-times" />
				</Dropdown.Item>
				<Form className="p-4" style={{ minWidth: 450 }}>
					{advancedFieldValues &&
						[...advancedFieldValues].map(([complexKey, asv]) => {
							const isBoolean = asv.dataSources.includes(DataSources.Boolean);
							if (isBoolean) {
								return (
									<CheckboxFormControl
										key={complexKey}
										label={asv.label}
										value={Boolean(asv.value)}
										onChange={(value) => {
											const filterValue = value ? '1' : '';
											onChangeFilterHandler(
												filterValue,
												complexKey,
												asv,
												DataSources.Boolean
											);
										}}
									/>
								);
							}

							return (
								<div key={complexKey} className="d-flex">
									<InputFormControl
										label={asv.label}
										value={asv.value}
										onChange={(value) =>
											onChangeFilterHandler(value, complexKey, asv)
										}
									/>
									{asv.allowedOperators && (
										<div className="flex-grow-1 ms-2">
											<SimpleSelectFormControl<DataFilterOperator>
												label=""
												value={asv.select}
												clearable={false}
												onChange={(value) =>
													onChangeRangeFilterHandler(value, complexKey, asv)
												}
												initialOptionId={
													asv.select ? asv.select.value : undefined
												}
												options={operatorOptions}
											/>
										</div>
									)}
									<div className="d-flex justify-content-center align-items-center mt-2">
										<Button
											size="sm"
											variant="light"
											className="ms-1"
											onClick={() => onClearFilterHandler(complexKey, asv)}
										>
											<i className="fas fa-fw fa-times" />
										</Button>
									</div>
								</div>
							);
						})}
				</Form>
			</Dropdown.Menu>
		</Dropdown>
	);
};
