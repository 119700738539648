/* eslint-disable @typescript-eslint/no-unused-vars */
import { components } from 'react-select';

interface CustomOptionProps {
	children: JSX.Element;
}

export const CustomOption = ({ children, ...props }: CustomOptionProps) => {
	const { onMouseMove, onMouseOver, ...rest } = (props as any).innerProps;
	const newProps = { ...props, innerProps: rest };
	return (
		<components.Option {...(newProps as any)}>{children}</components.Option>
	);
};
