import {
	AlertType,
	GuardianFraudAlertRecipientIndexResponse,
	GuardianFraudListIndexRequest,
	GuardianFraudListIndexResponse,
	GuardianFraudSystemConfigIndexResponse
} from 'RtModels';
import {
	FraudDataSourceField,
	FraudDestinationType
} from 'RtUi/app/rtGuardian/FraudConfiguration/lib/forms/FraudConfigurationListEditor';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { MessageTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MessageTypeDataGridColumn';
import { RateCenterRenderCache } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { SubscriptionDataGridCache } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { UserNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';
import { capitalCase } from 'change-case';

export const getFraudConfigurationColumns = (
	removeEntity: (row: GuardianFraudSystemConfigIndexResponse) => void
): Array<DataGridColumn<GuardianFraudSystemConfigIndexResponse>> => {
	return [
		DeleteDataGridColumn({
			onClick: (row) => removeEntity(row)
		}),
		DefaultDataGridColumn({
			accessorKey: 'paramName',
			header: 'Option'
		}),
		DefaultDataGridColumn({
			accessorKey: 'summary',
			header: 'Summary'
		}),
		IsActiveDataGridColumn({
			accessorKey: 'isActive'
		}),
		UserNameDataGridColumn({
			accessorKey: 'updatedBy',
			header: 'Updated by'
		}),
		TimeStampDataGridColumn({
			accessorKey: 'updatedTs',
			header: 'Updated Date'
		})
	];
};

export const getFraudConfigurationListColumns = (
	removeEntity: (row: GuardianFraudListIndexResponse) => void,
	resourceParams: GuardianFraudListIndexRequest
): Array<DataGridColumn<GuardianFraudListIndexResponse>> => {
	return [
		DeleteDataGridColumn({
			onClick: (row) => removeEntity(row)
		}),
		DefaultDataGridColumn({
			accessorKey: 'summary',
			header: 'Summary'
		}),
		DefaultDataGridColumn({
			accessorKey: 'dataSourceField',
			header: 'Field',
			isInvisible: Boolean(resourceParams.globalRule)
		}),
		DefaultDataGridColumn({
			header: 'Value',
			accessorFn: (row) => {
				switch (row.dataSourceField) {
					case FraudDataSourceField.Subscription: {
						return SubscriptionDataGridCache.getLabelFor(
							Number(row.dataSourceValue)
						);
					}
					case FraudDataSourceField.TrunkGroup: {
						return Number(row.dataSourceValue);
					}
					default: {
						return row.dataSourceValue;
					}
				}
			},
			isInvisible: Boolean(resourceParams.globalRule)
		}),
		DefaultDataGridColumn({
			header: 'Destination Type',
			accessorFn: (row) => capitalCase(String(row.destinationType))
		}),
		DefaultDataGridColumn({
			header: 'Destination Value',
			accessorFn: (row) => {
				if (row.destinationType === FraudDestinationType.RateCenter) {
					return RateCenterRenderCache.getLabelFor(
						Number(row.destinationValue)
					);
				}

				return row.destinationValue;
			}
		}),
		IsActiveDataGridColumn({
			accessorKey: 'isActive'
		}),
		UserNameDataGridColumn({
			accessorKey: 'updatedBy',
			header: 'Updated'
		}),
		TimeStampDataGridColumn({
			accessorKey: 'updatedTs',
			header: 'Updated'
		})
	];
};

export const getFraudRecipientsColumns = (
	removeEntity: (row: GuardianFraudAlertRecipientIndexResponse) => void
): Array<DataGridColumn<GuardianFraudAlertRecipientIndexResponse>> => {
	return [
		DeleteDataGridColumn({
			onClick: (row) => {
				removeEntity(row);
			}
		}),
		UserNameDataGridColumn({
			accessorKey: 'userId',
			header: 'User'
		}),
		MessageTypeDataGridColumn({
			accessorKey: 'messageTypeIds',
			header: 'Message Type'
		}),
		DefaultDataGridColumn({
			accessorKey: 'alertTypeIds',
			header: 'Alert Types',
			getValue: ({ cell }) => (
				<div className="text-monospace">
					{cell
						.getValue<AlertType[]>()
						.map((at) => {
							switch (Number(at)) {
								case AlertType.FraudDomestic:
									return 'Domestic';
								case AlertType.FraudInternational:
									return 'International';
								default:
									return at;
							}
						})
						.join(', ')}
				</div>
			)
		})
	];
};
