/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SomosTplIvrProfileResDto = {
    properties: {
        effectiveTs: {
            properties: {
            },
            isRequired: true,
        },
        status: {
            properties: {
            },
            isRequired: true,
        },
        respOrgId: {
            properties: {
            },
            isRequired: true,
        },
        reqEffectiveTs: {
            properties: {
            },
            isRequired: true,
        },
        result: {
            properties: {
            },
            isRequired: true,
        },
        lastActiveTs: {
            properties: {
            },
            isRequired: true,
        },
        errorQty: {
            type: 'number',
            isRequired: true,
        },
        warningQty: {
            type: 'number',
            isRequired: true,
        },
        warnErrorList: {
            type: 'Array',
            isRequired: true,
        },
        lastUpdateTs: {
            properties: {
            },
            isRequired: true,
        },
        lastUser: {
            properties: {
            },
            isRequired: true,
        },
    },
};