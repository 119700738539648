import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CicIndexResponse } from 'RtModels';
import { EntityIdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/EntityIdDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getCicLookupColumns = (): Array<
	DataGridColumn<CicIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'cic',
		header: 'Cic'
	}),
	DefaultDataGridColumn({
		accessorKey: 'acna',
		header: 'Acna'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	EntityIdDataGridColumn({
		accessorKey: 'entity'
	})
];
