import { AccountIndexResponse } from 'RtModels';
import { AccountResource } from 'RtUi/app/AccountManagement/Accounts/lib/resources/AccountResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface AccountDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		AccountIndexResponse,
		T
	> {}

export const AccountDataGridCache = new ResourceCacheMap<AccountIndexResponse>(
	new AccountResource(),
	'accountId',
	'label'
);

export const AccountDataGridColumn = <T = any,>(
	config: AccountDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: AccountDataGridCache,
		...config
	});
};
