import { PeeringTypeIndexResponse } from 'RtModels';
import { PeeringTypeResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/PeeringTypeResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface PeeringTypeDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		PeeringTypeIndexResponse,
		T
	> {}

export const PeeringTypeDataGridCache =
	new ResourceCacheMap<PeeringTypeIndexResponse>(
		new PeeringTypeResource(),
		'peeringTypeId',
		'label'
	);

export const PeeringTypeDataGridColumn = <T = any,>(
	config: PeeringTypeDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: PeeringTypeDataGridCache,
		...config
	});
};
