/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipTraceIndexResponseData = {
    properties: {
        aliasDst: {
            type: 'string',
            isRequired: true,
        },
        aliasSrc: {
            type: 'string',
            isRequired: true,
        },
        callid: {
            type: 'string',
            isRequired: true,
        },
        captureId: {
            type: 'string',
            isRequired: true,
        },
        correlation_id: {
            type: 'string',
            isRequired: true,
        },
        create_date: {
            type: 'number',
            isRequired: true,
        },
        cseq: {
            type: 'string',
            isRequired: true,
        },
        dbnode: {
            type: 'string',
            isRequired: true,
        },
        dstIp: {
            type: 'string',
            isRequired: true,
        },
        dstPort: {
            type: 'number',
            isRequired: true,
        },
        from_tag: {
            type: 'string',
            isRequired: true,
        },
        from_user: {
            type: 'string',
            isRequired: true,
        },
        id: {
            type: 'number',
            isRequired: true,
        },
        method: {
            type: 'number',
            isRequired: true,
        },
        node: {
            type: 'string',
            isRequired: true,
        },
        payloadType: {
            type: 'number',
            isRequired: true,
        },
        protocol: {
            type: 'number',
            isRequired: true,
        },
        protocolFamily: {
            type: 'number',
            isRequired: true,
        },
        ruri_domain: {
            type: 'string',
            isRequired: true,
        },
        ruri_user: {
            type: 'string',
            isRequired: true,
        },
        sid: {
            type: 'string',
            isRequired: true,
        },
        srcIp: {
            type: 'string',
            isRequired: true,
        },
        srcPort: {
            type: 'number',
            isRequired: true,
        },
        table: {
            type: 'string',
            isRequired: true,
        },
        timeSeconds: {
            type: 'number',
            isRequired: true,
        },
        timeUseconds: {
            type: 'number',
            isRequired: true,
        },
        to_user: {
            type: 'string',
            isRequired: true,
        },
        user_agent: {
            type: 'string',
            isRequired: true,
        },
        uuid: {
            type: 'number',
            isRequired: true,
        },
    },
};