import 'react-dates/initialize';
import * as moment from 'moment-timezone';
import {
	TimeFilterDetail,
	TimeFilterDetailKeys,
	TimeRangePresets
} from 'RtModels';
import * as dateFns from 'date-fns';

export type TTimeRangePresetsToNameMapping = {
	[key in TimeRangePresets]?: string;
};

export type TTimeRangePresetsOverlay = {
	[key in TimeRangePresets]?: string;
};
const HOURS_IN_DAY = 24;
const HOURS_IN_THREE_DAYS = HOURS_IN_DAY * 3;
const HOURS_IN_WORKING_WEEK = HOURS_IN_DAY * 6;
const HOURS_IN_WEEK = HOURS_IN_DAY * 7;
const HOURS_IN_MONTH = HOURS_IN_DAY * 30;

export const NO_TZ_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

export const TimeRangePresetsToNameMapping: TTimeRangePresetsToNameMapping = {
	[TimeRangePresets.OneHour]: '1h',
	[TimeRangePresets.FourHours]: '4h',
	[TimeRangePresets.Day]: '1d',
	[TimeRangePresets.ThreeDays]: '3d',
	[TimeRangePresets.Week]: '1w',
	[TimeRangePresets.ThirtyDays]: '30d',
	[TimeRangePresets.LastMonth]: 'Lm',
	[TimeRangePresets.MonthToDate]: 'MTD',
	[TimeRangePresets.Custom]: 'Custom'
};

export const TimeRangePresetsOverlay: TTimeRangePresetsOverlay = {
	[TimeRangePresets.LastMonth]: 'Last Month',
	[TimeRangePresets.MonthToDate]: 'Month to date'
};

export interface ITimeFilterDetailLevelsHours extends TimeFilterDetail {
	label: string;
	key: TimeFilterDetailKeys;
	value: number;
	minDuration?: number;
	maxDuration?: number;
}

/**
 * Details levels list
 * min and maxDuration in hours
 * @type {({label: "1 Minute"; value: 1; key: TimeFilterDetailKeys.Minutes; maxDuration: 1} | {label: "5 Minute"; value: 5; key: TimeFilterDetailKeys.Minutes; maxDuration: number} | {label: "15 Minute"; value: 15; key: TimeFilterDetailKeys.Minutes; maxDuration: number} | {minDuration: 3; label: "1 Hour"; value: 1; key: TimeFilterDetailKeys.Hours; maxDuration: number} | {minDuration: number; label: "1 Day"; value: 1; key: TimeFilterDetailKeys.Days; maxDuration: number} | {minDuration: number; label: "Monthly"; value: 1; key: TimeFilterDetailKeys.Months; maxDuration: number})[]}
 */
export const TimeFilterDetailLevelsHours: ITimeFilterDetailLevelsHours[] = [
	{
		key: TimeFilterDetailKeys.Minutes,
		value: 1,
		label: '1 Minute',
		maxDuration: 1
	} as const,
	{
		key: TimeFilterDetailKeys.Minutes,
		value: 5,
		label: '5 Minute',
		maxDuration: HOURS_IN_DAY
	} as const,
	{
		key: TimeFilterDetailKeys.Minutes,
		value: 15,
		label: '15 Minute',
		// not HOURS_IN_WEEK because of preset transitioning to end yesterday
		maxDuration: HOURS_IN_THREE_DAYS - 1
	} as const,
	{
		key: TimeFilterDetailKeys.Hours,
		value: 1,
		label: '1 Hour',
		minDuration: 3,
		// not HOURS_IN_WEEK because of preset transitioning to end yesterday
		maxDuration: HOURS_IN_WORKING_WEEK
	} as const,
	{
		key: TimeFilterDetailKeys.Days,
		value: 1,
		label: '1 Day',
		minDuration: HOURS_IN_DAY - 1,
		maxDuration: Number.MAX_SAFE_INTEGER
	} as const,
	{
		key: TimeFilterDetailKeys.Months,
		value: 1,
		label: 'Monthly',
		minDuration: HOURS_IN_WEEK,
		maxDuration: Number.MAX_SAFE_INTEGER
	} as const
];

/**
 * Given a preset, return the time filters available
 * @param preset
 */
export const GetTimeFilterDetailsForPresetHours = (
	preset: TimeRangePresets,
	shouldIncludeMonthly = false
): ITimeFilterDetailLevelsHours[] => {
	switch (preset) {
		case TimeRangePresets.OneHour:
			return GetTimeFilterDetailsForDurationInHours(1);
		case TimeRangePresets.FourHours:
			return GetTimeFilterDetailsForDurationInHours(4);
		case TimeRangePresets.Day:
			return GetTimeFilterDetailsForDurationInHours(HOURS_IN_DAY);
		case TimeRangePresets.ThreeDays:
			return GetTimeFilterDetailsForDurationInHours(
				HOURS_IN_THREE_DAYS,
				shouldIncludeMonthly
			);
		case TimeRangePresets.Week:
			return GetTimeFilterDetailsForDurationInHours(
				HOURS_IN_WEEK,
				shouldIncludeMonthly
			);
		case TimeRangePresets.ThirtyDays:
			return GetTimeFilterDetailsForDurationInHours(
				HOURS_IN_MONTH,
				shouldIncludeMonthly
			);
		case TimeRangePresets.LastMonth: {
			return GetTimeFilterDetailsForDurationInHours(
				HOURS_IN_MONTH,
				shouldIncludeMonthly
			);
		}
		case TimeRangePresets.MonthToDate: {
			return GetTimeFilterDetailsForDurationInHours(
				HOURS_IN_MONTH,
				shouldIncludeMonthly
			);
		}
		default:
			return GetTimeFilterDetailsForDurationInHours(1);
	}
};

/**
 * Given a duration, return the time filters available
 * @param duration
 */
export const GetTimeFilterDetailsForDurationInHours = (
	durationHours: number,
	shouldIncludeMonthly = false
): ITimeFilterDetailLevelsHours[] => {
	return TimeFilterDetailLevelsHours.filter((timeFilterDetailLevel) => {
		const { maxDuration, minDuration } = timeFilterDetailLevel;
		const doesNotMeetMaxAssertion = maxDuration && durationHours > maxDuration;
		const doesNotMeetMinAssertion = minDuration && durationHours < minDuration;
		const isNotWithinTimeLimits =
			doesNotMeetMaxAssertion || doesNotMeetMinAssertion;

		if (timeFilterDetailLevel.key === 'month' && shouldIncludeMonthly) {
			return true;
		}

		if (isNotWithinTimeLimits) {
			return false;
		}

		if (
			!shouldIncludeMonthly &&
			timeFilterDetailLevel.key === TimeFilterDetailKeys.Months
		) {
			return false;
		}

		return true;
	});
};

/**
 * Converts literal to actual time representation
 * @param {typeof DATE_TIME_RANGES[number]} DateTimeRangeValue
 * @returns {null | moment.Moment}
 */
export const getMomentTimeFromDateTimeRange = (
	DateTimeRangeValue: TimeRangePresets
) => {
	const now = moment();
	const numericPart = (DateTimeRangeValue.match(/^[0-9]{1,3}/) || '')[0];
	const literalPart = DateTimeRangeValue.split(numericPart)[1];

	return now.subtract(numericPart, literalPart as moment.DurationInputArg2);
};

/**
 * subtract number of hours from now
 * @param {TimeRangePresets} presetValue
 * @returns {Date}
 */
export const getStartDateFromPreset = (presetValue: TimeRangePresets) => {
	if (presetValue === TimeRangePresets.Day) {
		return dateFns.startOfYesterday();
	}
	const now = new Date();
	const numericPart = Number((presetValue.match(/^[0-9]{1,3}/) || '')[0]);

	return dateFns.subHours(now, numericPart);
};

/**
 * for 1d, 3d, 7d and 30d use yesterday as end
 */
export const getEndDateFromPreset = (presetValue: TimeRangePresets) => {
	if (
		[
			TimeRangePresets.Day,
			TimeRangePresets.ThreeDays,
			TimeRangePresets.Week,
			TimeRangePresets.ThirtyDays
		].includes(presetValue)
	) {
		return dateFns.endOfYesterday();
	}
	return new Date();
};

export const createDateAsUTC = (date?: Date | string) => {
	if (typeof date === 'string') {
		return date;
	}

	let dt = date;
	if (!dt) {
		dt = new Date();
	}

	return convertDateToUtc(dt);
};

/**
 * Use a date created by the browser (which is in the user's timezone) and
 * convert it to be a date with the UTC offset.
 *
 * For instance, given a date of 2021-11-01T05:00:00.000Z in -5 timezone (Austin)
 * the function will return 2021-11-01T00:00:00.000Z (still in -5 timezone)
 *
 * This helps the frontend normalize dates that user selects to UTC for the backend
 */
export const convertDateToUtc = (date: Date) => {
	return new Date(
		Date.UTC(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds()
		)
	);
};

export const convertDateToUTC = (date: Date | string) => {
	if (typeof date === 'string') {
		date = new Date(date);
	}
	return new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
		date.getUTCSeconds(),
		date.getUTCMilliseconds()
	);
};
