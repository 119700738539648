import { ServerProfileResponse } from 'RtModels';
import { ServerRouter } from 'RtUi/app/rtAdmin/Servers/Server.router';
import { useGetServers } from 'RtUi/app/rtAdmin/Servers/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getServerColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/servers';
import { useMemo } from 'react';

interface IServerDataGridProps {
	autoFocusFilter?: boolean;
}

export const ServerDataGrid = ({}: IServerDataGridProps) => {
	const { data, isLoading } = useGetServers();

	const columns = useMemo(() => getServerColumns(), []);

	return (
		<DataGrid<ServerProfileResponse>
			data={data?.data}
			columns={columns}
			pageName={'rtAdmin-server'}
			router={ServerRouter}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
