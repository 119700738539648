import { Rt800ApiRoutes, Permissions } from 'RtExports/routes';
import { Rt800Router } from 'RtUi/app/rt800/rt800.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import {
	IRocIndexResponse,
	IRocProfileResponse
} from './lib/resources/RocRequestResource';
import { SearchConfidenceLevel } from 'RtUi/components/containers/lib/RtUiRouter';
import { fetchRocRequests } from 'RtUi/app/rt800/RespOrgChange/lib/services';
import { UserActions } from 'RtUi/state/actions/user';

interface IRocRequestContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Numbers: TabbedLayoutTabProps;
	Documents: TabbedLayoutTabProps;
}

export const RocRequestContainerTabs: IRocRequestContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Numbers: {
		header: 'Numbers'
	},
	Documents: {
		header: 'Documents'
	}
};

class RocRequestRouterClass extends Rt800Router<
	IRocIndexResponse,
	IRocProfileResponse,
	IRocRequestContainerTabs
> {
	constructor() {
		super(
			'RespOrg Change (ROC)',
			'rocRequests',
			'rocTxnId',
			RocRequestContainerTabs
		);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.Roc);
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: IRocProfileResponse) {
		const directionText = profile.isIncoming ? 'Incoming' : 'Outgoing';

		return `${directionText}: ${profile.rocTxnId}`;
	}

	public globalSearchConfidenceCheck() {
		return SearchConfidenceLevel.Possible;
	}

	public globalSearch(search: string) {
		if (!UserActions.has(Permissions.TollFreeNumberRespOrgChangeRoc)) {
			return Promise.resolve([]);
		}

		return fetchRocRequests({ search }, false);
	}
}

export const RocRequestRouter = new RocRequestRouterClass();
