import { LcrMinuteDataRequest, MinuteProfileMinute } from 'RtModels';
import {
	ApplicationNotificationTypes,
	ApplicationNotifications,
	INotification
} from 'RtUi/app/ApplicationShell/lib/components/ApplicationNotifications';
import { LcrMinutesDataExportResource } from 'RtUi/app/rtLco/Minutes/lib/resources/LcrMinutesDataExportResource';
import { useGetMinutesData } from 'RtUi/app/rtLco/Minutes/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getMinutesDataColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/minutes';
import { useMemo } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ILcrMinutesGridProps {
	autoFocusFilter?: boolean;
	minuteProfileId: number;
	resourceParams?: LcrMinuteDataRequest;
}

export const LcrMinutesDataGrid = ({
	minuteProfileId,
	resourceParams
}: ILcrMinutesGridProps) => {
	const { data, isFetching: isLoading } = useGetMinutesData(
		{ minuteProfileId },
		{ pageSize: 1_000_000, ...resourceParams }
	);
	const columns = useMemo(() => getMinutesDataColumns(), []);

	const exportToEmail = async () => {
		const exportResource = new LcrMinutesDataExportResource();

		try {
			await exportResource.getAll(minuteProfileId);
			const notification: INotification = {
				message: 'A link to your report will be emailed to you when complete.',
				title: 'Export will be emailed',
				notificationId: `Lcr-Minutes-${Math.random()}`,
				notificationTypeId: ApplicationNotificationTypes.Info,
				effectiveTs: new Date(),
				expirationTs: new Date(),
				isUrgent: 0
			};

			ApplicationNotifications.openNotification(notification, {
				autoClose: 10_000
			});
		} catch (e) {}
	};

	return (
		<DataGrid<MinuteProfileMinute>
			pageName="rtLco_minutesProfile"
			data={data?.data}
			totalRows={data?.totalCount}
			loading={isLoading}
			disableExport
			headerAction={() => (
				<OverlayTrigger
					overlay={(props) => (
						<Tooltip id="export-tooltip" {...props}>
							Export to Email
						</Tooltip>
					)}
				>
					{({ ref, ...triggerHandler }) => (
						<Button
							ref={ref}
							{...triggerHandler}
							variant="white"
							id="export"
							onClick={exportToEmail}
						>
							<i className="fas fa-envelope fa-lg" />
						</Button>
					)}
				</OverlayTrigger>
			)}
			columns={columns}
		/>
	);
};
