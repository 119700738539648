import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { TimeFilter, TollfreeEnhancedSummaryIndexResponse } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getTollFreeEnhancedColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/tollFreeEnhanced';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

interface ITfEnhancedReportGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<TollfreeEnhancedSummaryIndexResponse> | null;
	isLoading?: boolean;
	onDrillDown: (row: TollfreeEnhancedSummaryIndexResponse) => void;
	timeRange: TimeFilter;
}

export const TfEnhancedReportGrid = ({
	resource,
	isLoading,
	onDrillDown,
	timeRange
}: ITfEnhancedReportGridProps) => {
	const { data = [] } = useQuery<
		TollfreeEnhancedSummaryIndexResponse[] | undefined,
		Error
	>([`useGetTollFreeEnhancedReport`, resource], () => resource?.getAll());
	const columns = useMemo(
		() => getTollFreeEnhancedColumns(timeRange, onDrillDown),
		[timeRange, onDrillDown]
	);

	return (
		<DataGrid<TollfreeEnhancedSummaryIndexResponse>
			defaultSorting={[
				{
					id: 'originalCostDurationMs',
					desc: true
				}
			]}
			pageName="rtVue_tollFreeEnhanced"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
