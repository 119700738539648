import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { IMetricsTotalsByResource } from 'RtUi/app/rtDid/Dashboard/lib/http/DidMetricHttp';
import { RtxDidApiRoutes } from 'RtExports/routes';

export class DidMetricTotalsByResourceResource extends ArrayResource<IMetricsTotalsByResource> {
	constructor() {
		super('resourceId');

		this.setApiRouteForGetAll(RtxDidApiRoutes.MetricsByResource.Index);
	}
}
