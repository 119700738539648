import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	FileProcessCreateRequest,
	FileProcessFileProfileResponse,
	FileProcessIndexResponse,
	FileProcessProfileResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ConvertRateSheetResource extends HttpResource<
	FileProcessIndexResponse,
	FileProcessProfileResponse
> {
	constructor() {
		super(RtCommonApiRoutes.FileProcess);
	}

	public create(params: FileProcessCreateRequest, file: File) {
		const body = new FormData();
		body.append('file', file);

		for (const [key, value] of Object.entries(params)) {
			body.append(key, value);
		}

		return this.fetchWithRoute<FileProcessProfileResponse>(
			RtCommonApiRoutes.FileProcess.Create,
			{ body, removeContentType: true }
		);
	}

	public getDownloadLink(fileProcessId: number) {
		return this.fetchWithRoute<FileProcessFileProfileResponse>(
			RtCommonApiRoutes.FileProcessFile.Profile,
			{
				urlParams: { fileProcessId }
			}
		);
	}
}
