//cSpell:disable
export enum AzureAccessMethod {
	SasToken = 'sasToken',
	ConnectionString = 'connectionString'
}

export enum FTPTLSConnectMethod {
	True = 'true',
	False = 'false',
	Implicit = 'implicit'
}

export interface IAWSCredentials {
	username: string;
	password: string;
	arns: string[];
}

export interface IAzureCredentials {
	accessMethod: AzureAccessMethod;
}

export interface IAzureSasCredentials extends IAzureCredentials {
	sasToken: string;
}

export interface IAzureConnectionCredentials extends IAzureCredentials {
	connectionString: string;
}

export interface IFTPCredentials {
	username: string;
	password: string;
	host: string;
	port: number;
}

export interface IBrowserCrendentials extends IFTPCredentials {}

export interface IFTPTLSCredentials extends IFTPCredentials {
	connectMethod: FTPTLSConnectMethod;
}

export interface ISFTPCredentials extends IFTPCredentials {
	privateKey?: string;
}

export type IFTPCredentialsFieldConfig = {
	[key in keyof IFTPCredentials]?: IFieldConfig;
};

interface IFieldConfig {
	defaultValue?: string;
	required?: boolean;
}
