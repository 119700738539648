import * as React from 'react';
import { ResourceType } from 'RtModels';

export interface ISelectedSummariesContext {
	selected: ResourceType[];
	toggleSelected: (selected: ResourceType[]) => void;
	onClick: (selected: ResourceType[]) => void;
}
export const SelectedSummariesContext =
	React.createContext<ISelectedSummariesContext>({
		selected: [],
		toggleSelected: () => ({}),
		onClick: () => ({})
	});
