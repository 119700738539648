import { TollFreeSearchRequest, TollFreeSearchResponse } from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { flatten, padEnd, padStart } from 'lodash-es';

export interface ITollFreeSearchTFNResponse {
	tfn: string;
	matchedTfn: string;
	isSuggestion: boolean;
}

export type ITollFreeSearchTFNResource =
	ArrayResource<ITollFreeSearchTFNResponse>;

interface ISearchSingleExtraParams {
	sequential?: boolean;
	allowSuggestions?: boolean;
}

export enum ADVANCED_FILTER {
	NPA888 = '888',
	NPA877 = '877',
	NPA866 = '866',
	NPA855 = '855',
	NPA844 = '844',
	NPA833 = '833',
	NPA800 = '800',
	Contiguous = 'Contiguous',
	AllowSuggestions = 'Allow Suggestions'
}

const NPAFilters = [
	ADVANCED_FILTER.NPA888,
	ADVANCED_FILTER.NPA877,
	ADVANCED_FILTER.NPA866,
	ADVANCED_FILTER.NPA855,
	ADVANCED_FILTER.NPA844,
	ADVANCED_FILTER.NPA833,
	ADVANCED_FILTER.NPA800
];

export class TollFreeSearchResource extends ArrayResource<TollFreeSearchResponse> {
	public static search(
		pattern: TollFreeSearchRequest['pattern'],
		advancedFilters: ADVANCED_FILTER[],
		maxQuantity = 10
	): ITollFreeSearchTFNResource {
		const requests: Array<Promise<ITollFreeSearchTFNResponse[]>> = [];
		const { searchSingle } = TollFreeSearchResource;
		const sequential = advancedFilters.includes(ADVANCED_FILTER.Contiguous);
		const allowSuggestions = advancedFilters.includes(
			ADVANCED_FILTER.AllowSuggestions
		);
		const extraParams = {
			sequential,
			allowSuggestions
		};

		if (pattern.length >= 7) {
			const searchPattern = padStart(pattern, 10, '*');
			//const singleQueryAmount = 10; //If only search, limit to 10 results
			requests.push(searchSingle(searchPattern, maxQuantity, extraParams));
		} else {
			let queryAmount = 0;
			const isPatternLengthLte7 = pattern.length <= 7;
			const isPatternLengthLte6 = pattern.length <= 6;
			const isPatternLengthLte5 = pattern.length <= 5;

			if (isPatternLengthLte7) {
				queryAmount++;
			}
			if (isPatternLengthLte6) {
				queryAmount++;
			}
			if (isPatternLengthLte5) {
				queryAmount++;
			}

			const properQuantity = Math.floor(maxQuantity / queryAmount);
			const minimumQuantity = 5;
			const queryQuantity = Math.max(minimumQuantity, properQuantity);

			if (isPatternLengthLte7) {
				//Make sure to hit 10 results
				const firstQueryQty = queryQuantity === 3 ? 4 : queryQuantity;
				const searchPattern = padStart(pattern, 10, '*');
				requests.push(searchSingle(searchPattern, firstQueryQty, extraParams));
			}

			if (isPatternLengthLte6) {
				let searchPattern = padStart(pattern, 9, '*');
				searchPattern = padEnd(searchPattern, 10, '*');
				requests.push(searchSingle(searchPattern, queryQuantity, extraParams));
			}

			if (isPatternLengthLte5) {
				let searchPattern = padStart(pattern, 8, '*');
				searchPattern = padEnd(searchPattern, 10, '*');
				requests.push(searchSingle(searchPattern, queryQuantity, extraParams));
			}
		}

		let allDataPromise = Promise.all(requests).then(flatten);

		const npaFilters = advancedFilters.filter((filter) =>
			NPAFilters.includes(filter)
		);
		if (npaFilters.length) {
			allDataPromise = allDataPromise.then((data) => {
				return data.filter(({ tfn }) => {
					return npaFilters.some((filter) => tfn.includes(filter));
				});
			});
		}

		const tollFreeSearchResource: ITollFreeSearchTFNResource =
			new ArrayResource('tfn');

		tollFreeSearchResource.setGetAllPromise(allDataPromise);

		return tollFreeSearchResource;
	}

	private static searchSingle(
		pattern: string,
		quantity: number,
		extraParams?: ISearchSingleExtraParams
	): Promise<ITollFreeSearchTFNResponse[]> {
		//API does not like lowercase
		const upperCasePattern = pattern.toUpperCase();
		const encodedPattern = encodeURIComponent(upperCasePattern);
		const params: TollFreeSearchRequest = {
			pattern: encodedPattern,
			quantity,
			sequential: Number(extraParams?.sequential),
			allowSuggestions: Number(extraParams?.allowSuggestions)
		};

		return (
			HttpRequest.fetchWithRoute<TollFreeSearchResponse>(
				Rt800ApiRoutes.TollFreeSearch.Profile,
				{ params }
			)
				.then((res) => {
					const tfns = TollFreeSearchResource.mapTfns(pattern, res.tfns);
					const suggestions = (res.suggestions || []).reduce(
						(result: ITollFreeSearchTFNResponse[], suggestion) => {
							const { matchedPattern, tfns } = suggestion;
							const mappedResult = TollFreeSearchResource.mapTfns(
								matchedPattern,
								tfns,
								true
							);
							result = result.concat(mappedResult);

							return result;
						},
						[]
					);

					return tfns.concat(suggestions);
				})
				//safe fail-over
				.catch(() => [])
		);
	}

	private static mapTfns(
		pattern: string,
		tfns: string[],
		isSuggestion: boolean = false
	): ITollFreeSearchTFNResponse[] {
		const patternParts = pattern.split('');

		return tfns.map((tfn) => {
			const tfnParts = tfn.split('');
			const matchedTfn = patternParts
				.map((patternPart, index) => {
					const isLetter = patternPart.match(/[a-z]/i);

					if (isLetter || isSuggestion) {
						return patternPart;
					}

					return tfnParts[index];
				})
				.join('');

			return { tfn, matchedTfn, isSuggestion };
		});
	}

	constructor() {
		super('tfns');

		this.setApiRouteForGetAll(Rt800ApiRoutes.TollFreeSearch.Profile);
	}
}
