import { NetworkEfficiencyIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AverageCallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AverageCallDurationDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';

export const getNetworkEfficiencyColumns = (): Array<
	DataGridColumn<NetworkEfficiencyIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'subscription',
		header: 'Subscription',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'account',
		header: 'Account',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'country',
		header: 'Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateCenter',
		header: 'rateCenter',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'routeDepth',
		header: 'Route Depth',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'attempts',
		header: 'Attempts'
	}),
	IntegerDataGridColumn({
		accessorKey: 'connects',
		header: 'Connects'
	}),
	IntegerDataGridColumn({
		accessorKey: 'minutes',
		header: 'Minutes'
	}),
	AverageCallDurationDataGridColumn({
		accessorKey: 'acr',
		header: 'ACD'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'adr',
		header: 'ADR'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'ner',
		header: 'NER'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'pdd',
		header: 'PDD'
	})
];
