import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { RoutePlanIndexResponse } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';

export class RoutePlanResource extends ArrayResource<RoutePlanIndexResponse> {
	constructor() {
		super('routePlanId');

		this.setApiRouteForGetAll(RtxSipApiRoutes.RoutePlans.Index);
	}
}
