import * as React from 'react';
import { Aside } from 'RtUi/components/ui/Aside';
import { JobTasksBulkUploader } from 'RtUi/app/rtCarrierConnect/Jobs/lib/forms/JobTasksBulkUploader';
import { JobTaskResource } from 'RtUi/app/rtCarrierConnect/Jobs/lib/resources/JobTaskResource';
import { GenericTypeRender } from 'RtUi/components/ui/GenericTypeRenderer';
import { ResourceConnectionDataGridCache } from 'RtUi/components/data/DataGrid/columns/ResourceConnectionDataGridColumn';

interface IJobTasksBulkUploaderAsideProps {
	isOpen: boolean;
	resourceId: number;
	stepNumber: number;
	jobTaskResource: JobTaskResource;
	onClose: () => void;
	onUploadSuccess: () => void;
}

export class JobTasksBulkUploaderAside extends React.PureComponent<IJobTasksBulkUploaderAsideProps> {
	constructor(props: IJobTasksBulkUploaderAsideProps) {
		super(props);
		ResourceConnectionDataGridCache.warmup();
	}
	public render() {
		return (
			<Aside isOpen={this.props.isOpen} disabledBody fullscreen>
				<Aside.Header
					warning
					header={
						<>
							<span>Step {this.props.stepNumber}.&nbsp;</span>
							<GenericTypeRender
								id={this.props.resourceId}
								resourceCacheMap={ResourceConnectionDataGridCache}
							/>
							<span>&nbsp;Bulk Update</span>
						</>
					}
					onClose={() => this.props.onClose()}
				/>
				<section>
					<JobTasksBulkUploader
						jobTaskResource={this.props.jobTaskResource}
						onSuccess={() => this.props.onUploadSuccess()}
					/>
				</section>
			</Aside>
		);
	}
}
