import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';

class RtDashboardOldRouterClass extends RtUiRouter<void, void, {}> {
	constructor() {
		super('', 'Dashboard', 'dashboard', undefined, {});
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const RtDashboardOldRouter = new RtDashboardOldRouterClass();
