import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import {
	IDropdown,
	PossibleFilterNames,
	PossibleAccessorKeys
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { isEmpty } from 'lodash-es';
import {
	ConnectionIndexResponse,
	FileStreamIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';

interface IDataSourceSelectorProps {
	handleChange: (
		selectedItems:
			| FileStreamIndexResponse[]
			| ConnectionIndexResponse[]
			| undefined
			| null
			| SubscriptionIndexResponse[]
			| string[]
			| number[],
		filterName: PossibleFilterNames,
		accessor: PossibleAccessorKeys
	) => void;
	value: FileStreamIndexResponse[] | undefined;
	initialOptionId: string[];
	dataSourceValues: IDropdown[] | [];
	defaultDataDomains: IDropdown[];
}

export const DataSourceSelector = (
	props: IDataSourceSelectorProps
): JSX.Element => {
	const {
		handleChange,
		value,
		initialOptionId,
		dataSourceValues,
		defaultDataDomains
	} = props;

	const DATA_SOURCE_LABEL = 'Data Source';
	const FILE_STREAM_LABEL = 'File Stream';
	const ADVANCED_LABEL = 'Advanced';
	const BASIC_LABEL = 'Basic';

	const [advanced, setAdvanced] = useState<boolean>(false);
	const [label, setLabel] = useState<string>(DATA_SOURCE_LABEL);
	const [buttonLabel, setButtonLabel] = useState<string>(ADVANCED_LABEL);

	const handleUnsetPreviousData = (type: PossibleFilterNames) => {
		handleChange(null, type, type);
	};

	const handleSetAdvanced = () => {
		setAdvanced(!advanced);
		handleUnsetPreviousData('dataDomains');
		handleUnsetPreviousData('fileStreamId');
	};

	const handleDataSourceChange = (value: IDropdown[] | []) => {
		if (isEmpty(value)) {
			handleChange(null, 'dataDomains', 'dataDomains');
		} else {
			const formattedValue = value.map((v) => v.value);
			handleChange(formattedValue, 'dataDomains', 'dataDomains');
		}
	};

	useEffect(() => {
		if (advanced) {
			setLabel(FILE_STREAM_LABEL);
			setButtonLabel(BASIC_LABEL);
		} else {
			setLabel(DATA_SOURCE_LABEL);
			setButtonLabel(ADVANCED_LABEL);
		}
	}, [advanced]);

	useEffect(() => {
		if (!isEmpty(initialOptionId) && !isEmpty(defaultDataDomains)) {
			handleUnsetPreviousData('dataDomains');
			handleUnsetPreviousData('fileStreamId');
		}
	});

	useEffect(() => {
		if (!isEmpty(initialOptionId) && !advanced) {
			setAdvanced(true);
		}
	}, [initialOptionId, advanced]);

	useEffect(() => {
		if (!isEmpty(defaultDataDomains) && advanced) {
			setAdvanced(false);
		}
	}, [defaultDataDomains, advanced]);

	return (
		<Form.Group className="d-flex justify-content-start align-items-center mb-3">
			<Form.Label className="mb-0 label-90">{label}</Form.Label>
			<div
				className="select-form-control flex-fill"
				style={{ maxWidth: '400px' }}
			>
				{!advanced ? (
					<Select
						placeholder={null}
						isMulti
						className="react-select  is-multi"
						classNamePrefix="react-select"
						closeMenuOnSelect={false}
						onChange={(value) => {
							handleDataSourceChange(value as IDropdown[]);
						}}
						options={dataSourceValues as unknown as IDropdown[]}
						value={defaultDataDomains}
					/>
				) : (
					<FileStreamSelect<true>
						multi
						containerClassName="flex-fill"
						style={{ maxWidth: 400 }}
						closeMenuOnSelect={false}
						useControlGroup={false}
						onChange={(fileStreams) => {
							handleChange(fileStreams, 'fileStreamId', 'fileStreamId');
						}}
						value={value}
						initialOptionId={initialOptionId}
					/>
				)}
			</div>
			<button className="px-2 btn btn-link" onClick={() => handleSetAdvanced()}>
				<span>{buttonLabel}</span>
			</button>
		</Form.Group>
	);
};
