/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationErrorMessageCreateRequest = {
    properties: {
        messages: {
            type: 'IntegrationErrorMessageCreateRequestRecord',
            isRequired: true,
        },
    },
};