/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum TrendAnalysisColumns {
    CountryIsoId = 'countryIsoId',
    CustRateSummary = 'custRateSummary',
    VendRateSummary = 'vendRateSummary',
    TimeSlotDaily = 'timeSlotDaily',
    TimeSlotHourly = 'timeSlotHourly',
    SummaryMode = 'summaryMode',
    DetailMode = 'detailMode',
}