type IteratorType<T, R> = (element: T, index: number) => R;

interface IFindNonUniqueEntriesConfig {
	stopAt?: number;
}

export const MapLargeArrayWithoutHaltingUI = <T, ReturnType>(
	largeArr: T[],
	iterator: (record: T, index: number) => ReturnType | Promise<ReturnType>
): Promise<ReturnType[]> => {
	const mappedResponses: any[] = [];
	const lengthOfBatchBeforeTimeout = 250;

	return new Promise<any[]>((resolve, reject) => {
		const lengthOfArray = largeArr.length;
		let startTime = performance.now();

		const executeIteratorOn = async (index: number) => {
			if (index < lengthOfArray) {
				try {
					let response = iterator(largeArr[index], index);

					if (response) {
						if (response instanceof Promise) {
							response = await response;
						}

						mappedResponses.push(response);
					}
				} catch (e) {
					reject(e);
				}

				const currentTime = performance.now();
				const batchDeltaTime = currentTime - startTime;
				const batchCompleted = batchDeltaTime >= lengthOfBatchBeforeTimeout;
				const startNext = () => executeIteratorOn(index + 1);

				if (batchCompleted) {
					setTimeout(() => {
						startTime = performance.now();
						startNext();
					}, 1);
				} else {
					startNext();
				}
			} else {
				resolve(mappedResponses);
			}
		};

		executeIteratorOn(0);
	});
};

export const LoopThroughLargeArrayWithoutHaltingUI = <T>(
	largeArr: T[],
	iterator: IteratorType<T, void>,
	maxTimePerChunk: number = 150
) => {
	const now = () => new Date().getTime();
	return new Promise<void>((resolve, reject) => {
		let index = 0;
		const executeIterator = () => {
			const startTime = now();

			while (index < largeArr.length && now() - startTime <= maxTimePerChunk) {
				try {
					iterator(largeArr[index], index);
					++index;
				} catch (e) {
					reject(e);
				}
			}

			if (index < largeArr.length) {
				setTimeout(executeIterator, 1);
			} else {
				resolve();
			}
		};

		executeIterator();
	});
};

export const FindNonUniqueEntries = <T>(
	arr: T[],
	iterator: IteratorType<T, string>,
	config: IFindNonUniqueEntriesConfig = {}
) => {
	const uniqueMap = new Map<string, true>();
	const nonUniqueIds: string[] = [];
	const { stopAt = Number.MAX_SAFE_INTEGER } = config;

	for (let i = 0; i < arr.length; i++) {
		const elem = arr[i];
		const uniqueId = iterator(elem, i);

		if (uniqueMap.has(uniqueId)) {
			nonUniqueIds.push(uniqueId);

			if (nonUniqueIds.length >= stopAt) {
				break;
			}
		} else {
			uniqueMap.set(uniqueId, true);
		}
	}

	return nonUniqueIds;
};
