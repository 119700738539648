import * as React from 'react';
import * as moment from 'moment-timezone';
import { TrackingGroupResource } from 'RtUi/app/rtSip/TrackingGroups/lib/resources/TrackingGroupResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import {
	TrackingGroupProfileResponse,
	TrackingGroupUpdateRequest
} from 'RtModels';

interface ITrackingGroupEditorProps {
	editMode?: TrackingGroupProfileResponse;
	createMode?: boolean;
	onCreateSuccess?: (res: TrackingGroupProfileResponse) => void;
	onUpdateSuccess?: (res: TrackingGroupProfileResponse) => void;
}

interface ITrackingGroupEditorState extends TrackingGroupUpdateRequest {
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
	customExpirationSeconds: number;
}

export class TrackingGroupEditor extends React.Component<
	ITrackingGroupEditorProps,
	ITrackingGroupEditorState
> {
	public readonly oneHourInSeconds = 60 * 60;

	public readonly expirationMap = new Map<string, number>([
		['1h', this.oneHourInSeconds],
		['4h', 4 * this.oneHourInSeconds],
		['1d', 24 * this.oneHourInSeconds],
		['1w', 7 * 24 * this.oneHourInSeconds]
	]);

	public state: ITrackingGroupEditorState = {
		label: '',
		playLimit: 0,
		dialLimit: 0,
		expirationSeconds: this.oneHourInSeconds,
		displayMode: true,
		isSubmitting: false,
		error: undefined,
		customExpirationSeconds: 900
	};

	constructor(props: ITrackingGroupEditorProps) {
		super(props);

		if (props.editMode) {
			this.state.label = props.editMode.label;
			this.state.playLimit = props.editMode.playLimit;
			this.state.dialLimit = props.editMode.dialLimit;

			if (this.isCustomExpiration(props.editMode.expirationSeconds)) {
				this.state.expirationSeconds = props.editMode.expirationSeconds;
			} else {
				this.state.expirationSeconds = 0;
				this.state.customExpirationSeconds = props.editMode.expirationSeconds;
			}
		} else if (props.createMode) {
			this.state.displayMode = false;
		}
	}

	public onCancel() {
		const { editMode } = this.props;
		const label = editMode?.label ?? '';
		const playLimit = editMode?.playLimit ?? 0;
		const dialLimit = editMode?.dialLimit ?? 0;

		let expirationSeconds = editMode?.expirationSeconds ?? 0;
		let customExpirationSeconds = this.props.editMode?.expirationSeconds ?? 900;

		if (!this.isCustomExpiration(expirationSeconds)) {
			customExpirationSeconds = expirationSeconds;
			expirationSeconds = 0;
		}

		this.setState({
			label,
			playLimit,
			dialLimit,
			expirationSeconds,
			customExpirationSeconds
		});
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const {
				label,
				dialLimit,
				playLimit,
				expirationSeconds,
				customExpirationSeconds
			} = this.state;
			const trackingGroupResource = new TrackingGroupResource();

			if (this.props.createMode) {
				const { onCreateSuccess = () => ({}) } = this.props;
				const profile = await trackingGroupResource.create(
					label,
					playLimit,
					dialLimit,
					expirationSeconds || customExpirationSeconds
				);

				onCreateSuccess(profile);
			} else if (this.props.editMode) {
				const { onUpdateSuccess = () => ({}) } = this.props;
				const { trackingGroupId } = this.props.editMode;
				const profile = await trackingGroupResource.update(
					trackingGroupId,
					label,
					playLimit,
					dialLimit,
					expirationSeconds || customExpirationSeconds
				);

				onUpdateSuccess(profile);
			}

			this.setState({ displayMode: true });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public render() {
		return (
			<RtUiNarrowForm
				onSubmit={(evt) => this.onSubmit(evt)}
				displayMode={this.state.displayMode}
				createMode={Boolean(this.props.createMode)}
				isSubmitting={this.state.isSubmitting}
				onChange={(displayMode) => this.setState({ displayMode })}
				error={this.state.error}
				onCancel={() => this.onCancel()}
			>
				<InputFormControl
					label="Label"
					displayMode={this.state.displayMode}
					value={this.state.label}
					onChange={(label) => this.setState({ label })}
				/>
				<PrecisionFormControl
					label="Play Limit"
					displayMode={this.state.displayMode}
					precision={0}
					value={this.state.playLimit}
					onChange={(playLimit) => {
						playLimit = playLimit ?? 0;
						this.setState({ playLimit });
					}}
				/>
				<PrecisionFormControl
					label="Dial Limit"
					displayMode={this.state.displayMode}
					precision={0}
					value={this.state.dialLimit}
					onChange={(dialLimit) => {
						dialLimit = dialLimit ?? 0;
						this.setState({ dialLimit });
					}}
				/>
				<RadioFormControl
					label="Expiration"
					displayMode={this.state.displayMode}
					value={this.state.expirationSeconds}
					onChange={(expirationSeconds: number) =>
						this.setState({ expirationSeconds })
					}
					options={[
						{
							value: this.expirationMap.get('1h')!,
							label: '1 Hour'
						},
						{
							value: this.expirationMap.get('4h')!,
							label: '4 Hours'
						},
						{ value: this.expirationMap.get('1d')!, label: '1 Day' },
						{
							value: this.expirationMap.get('1w')!,
							label: '1 Week'
						},
						{
							value: 0,
							label: `${
								this.state.displayMode
									? this.getReadableExpirationSeconds()
									: 'Custom'
							}`
						}
					]}
				/>
				{!this.state.displayMode && this.state.expirationSeconds === 0 && (
					<InputFormControl
						label={'Expiration (seconds)'}
						displayMode={this.state.displayMode}
						type="number"
						min={1}
						value={String(this.state.customExpirationSeconds)}
						onChange={(expirationSeconds) =>
							this.setState({
								customExpirationSeconds: Number(expirationSeconds)
							})
						}
					/>
				)}
			</RtUiNarrowForm>
		);
	}

	private getReadableExpirationSeconds() {
		return moment
			.duration({ seconds: this.state.customExpirationSeconds })
			.humanize();
	}

	private isCustomExpiration(value: number) {
		return Array.from(this.expirationMap.values()).includes(value);
	}
}
