import { RtVueModuleRouter } from '../RtVue.router';
import { RtVueApiRoutes } from 'RtExports/routes';
import { FileTypeIndexResponse } from 'RtModels';

export class FileTypeRouterClass extends RtVueModuleRouter<
	FileTypeIndexResponse,
	FileTypeIndexResponse,
	{}
> {
	constructor() {
		super('File Types', 'fileTypes', 'fileTypeId', {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.FileTypes);
	}

	public getProfileLabel(profile: FileTypeIndexResponse): string {
		return profile.description;
	}
}

export const FileTypeRouter = new FileTypeRouterClass();
