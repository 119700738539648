/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ServerGroupProfileResponse = {
    properties: {
        serverGroupId: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isMedia: {
            type: 'number',
            isRequired: true,
        },
    },
};