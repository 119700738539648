import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	OriginationNumberIndexRequest,
	OriginationNumberProfileResponse
} from 'RtModels';
import { ServiceNumberFormEditor } from 'RtUi/app/rtSip/ServiceNumbers/lib/forms/ServiceNumberFormEditor';
import { ServiceNumbersGrid } from 'RtUi/app/rtSip/ServiceNumbers/lib/grids/ServiceNumberDataGrid';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { ServiceNumberRouter } from './ServiceNumber.router';

interface IInitialResourceParams {
	search?: string;
}

interface IServiceNumbersContainerState
	extends ISearchResultsContainerState<OriginationNumberIndexRequest>,
		IInitialResourceParams {
	isAddAsideOpen: boolean;
}

@ServiceNumberRouter.getNetworkOpsIndexRtUiController()
export class ServiceNumberIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IServiceNumbersContainerState
> {
	public initParams: IInitialResourceParams = {
		search: ''
	};

	public state: IServiceNumbersContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {},
		isAddAsideOpen: false
	};

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { search } = this.state;
		const resourceParams: OriginationNumberIndexRequest = {};

		if (search) {
			resourceParams.search = search;
		}

		return resourceParams;
	}

	public render() {
		return (
			<TabbedLayout
				router={ServiceNumberRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<InputFormControl
							label="Search"
							onChange={(search) => this.setState({ search })}
							value={this.state.search}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<ServiceNumbersGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
				<TabbedLayoutTab
					rtRoute={RtxSipApiRoutes.ServiceNumbers.Create}
					header="Create Service Number"
					isAction
				>
					<ServiceNumberFormEditor
						onUpdate={(profile: OriginationNumberProfileResponse) =>
							this.goToPath(ServiceNumberRouter.getProfileRoute(profile))
						}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					rtRoute={RtxSipApiRoutes.ServiceNumbers.Create}
					header="Bulk Create Service Number"
					isAction
				>
					<ServiceNumberFormEditor
						bulk
						onUpdate={() => {
							this.setState({ activeTab: this.tabs.Results });
						}}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
