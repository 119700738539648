import { RocActionItems } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { values } from 'lodash-es';

interface RocRejectReasonsDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	actions: RocActionItems;
}

export const RocRejectReasonsDataGridColumn = <T = any,>({
	actions,
	size = 350,
	...config
}: RocRejectReasonsDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getExportValue = (rejectReasonIds: number[]) => {
		return (
			values(actions)
				.filter(({ rejectReasonId = -1 }) =>
					rejectReasonIds.includes(rejectReasonId)
				)
				.map(
					(rejectRocAction) =>
						`${rejectRocAction.label} (${rejectRocAction.code})`
				)
				//Comma separated join
				.join(', ')
		);
	};

	const getValue = (rejectReasonIds: number[]) => {
		return (
			<ul className="mb-0 ps-3">
				{values(actions)
					.filter(({ rejectReasonId = -1 }) =>
						rejectReasonIds.includes(rejectReasonId)
					)
					.map((rejectRocAction, key) => (
						<li
							key={key}
						>{`${rejectRocAction.label} (${rejectRocAction.code})`}</li>
					))}
			</ul>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		exportValue: (value) => getExportValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<number[]>())
	});
};
