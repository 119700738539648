import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import {
	TemplateLcrScenarioAvailableIndexResponse,
	TemplateLcrScenarioAvailableIndexRequest
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';

export class TemplateLcrScenarioAvailable extends ArrayResource<TemplateLcrScenarioAvailableIndexResponse> {
	constructor(templateName: string) {
		super('lcrScenarioId');

		const urlParams: TemplateLcrScenarioAvailableIndexRequest = {
			templateName
		};
		this.setApiRouteForGetAll(
			Rt800ApiRoutes.TemplateLcrScenariosAvailable.Index,
			{ urlParams }
		);
	}
}
