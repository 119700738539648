import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	SubscriptionTagIndexResponse,
	SubscriptionTagProfileResponse,
	SubscriptionTagProfileRequest,
	SubscriptionTagCreateRequest,
	SubscriptionTagUpdateRequest
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';

export class SubscriptionTagResource extends HttpResource<
	SubscriptionTagIndexResponse,
	SubscriptionTagProfileResponse
> {
	constructor() {
		super(RtxSipApiRoutes.SubscriptionsTag);
	}

	public create(createRequest: SubscriptionTagCreateRequest) {
		const body = JSON.stringify(createRequest);

		return this.fetchWithRoute<SubscriptionTagProfileResponse>(
			RtxSipApiRoutes.SubscriptionsTag.Create,
			{
				body
			}
		);
	}

	public update(
		subscriptionTagId: number,
		updateRequest: SubscriptionTagUpdateRequest
	) {
		const urlParams: SubscriptionTagProfileRequest = { subscriptionTagId };
		const body = JSON.stringify(updateRequest);

		return this.fetchWithRoute<SubscriptionTagProfileResponse>(
			RtxSipApiRoutes.SubscriptionsTag.Update,
			{
				body,
				urlParams
			}
		);
	}

	public delete(subscriptionTagId: number) {
		const urlParams: SubscriptionTagProfileRequest = { subscriptionTagId };
		return this.fetchWithRoute(RtxSipApiRoutes.SubscriptionsTag.Delete, {
			urlParams
		});
	}
}
