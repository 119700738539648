import {
	RejectDetailsIndexResponse,
	ReportAggregates,
	Reports,
	TimeFilterDetailKeys,
	TimeRangePresets
} from 'RtModels';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { RejectDetailsRevenueReportRouter } from 'RtUi/app/rtVue/RejectDetailsRevenueReport/RejectDetailsRevenueReport.router';
import { RejectDetailsRevenueCostGrid } from 'RtUi/app/rtVue/RejectSuspenseReport/grids/RejectDetailsRevenueCostGrid';
import { IApplicationContainerProps } from 'RtUi/components/containers/ApplicationContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

@RejectDetailsRevenueReportRouter.getIndexRtUiController({
	groupName: 'Rating',
	isHidden: true
})
export class RejectDetailsRevenueReportIndexContainer extends RtVueReportContainer<RejectDetailsIndexResponse> {
	public state: IRtVueContainerState<RejectDetailsIndexResponse>;

	protected reportId = Reports.RejectRevenue;
	protected router = RejectDetailsRevenueReportRouter;

	constructor(props: IApplicationContainerProps) {
		super(props);

		const baseState = this.getBaseState();
		let timeRange = this.getTimeRangeFromLocalStorage(Reports.RejectSummary);

		if (!timeRange) {
			timeRange = this.getTimeRangeFromLocalStorage();
		}

		if (!timeRange) {
			timeRange = baseState.timeRange;
		}

		this.state = {
			...baseState,
			timeRange,
			timeRangePresetsKeys: [TimeRangePresets.Custom],
			timeRangeAvailableDetails: [
				TimeFilterDetailKeys.Days,
				TimeFilterDetailKeys.Months
			],
			dataSums: [ReportAggregates.Margin]
		};
	}

	protected async getResource(): Promise<
		HttpResource<RejectDetailsIndexResponse>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns,
			havingFilter
		} = this.state;

		const vueHttp = new RtVueHttp();
		const { data, totalCount } = await vueHttp.getRejectRevenueDetailsView(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			additionalColumns?.map((column) => column.value),
			havingFilter
		);
		const resource = new ArrayResource<RejectDetailsIndexResponse>(
			'callDate',
			data
		);
		resource.setTotalCount(totalCount);

		return resource;
	}

	protected renderReports(): React.ReactNode {
		return (
			<RejectDetailsRevenueCostGrid
				isRevenue
				isLoading={this.state.loadingReport}
				callDateFormat={this.getCallDateFormat()}
				resource={this.state.resource}
			/>
		);
	}
}
