import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import {
	RoboscoringContainerTabs,
	RoboscoringRouter
} from 'RtUi/app/rtVue/Roboscoring/Roboscoring.router';
import { RoboscoringResource } from 'RtUi/app/rtVue/Roboscoring/lib/resources/RoboscoringResource';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { RoboscoringEditor } from 'RtUi/app/rtVue/Roboscoring/lib/forms/RoboscoringEditor';
import { RoboscoreParameterIndexResponse } from 'RtModels';

interface IRoboscoringProfileState {
	activeTab: string;
	resource: RoboscoringResource;
	profile?: RoboscoreParameterIndexResponse;
	error?: any;
}

@RoboscoringRouter.getProfileRtUiController()
export class RoboscoringProfileContainer extends ApplicationContainer<
	{},
	IRoboscoringProfileState
> {
	public Tabs = RoboscoringRouter.getProfileTabs();

	public state: IRoboscoringProfileState = {
		activeTab: RoboscoringContainerTabs.Profile.header,
		resource: new RoboscoringResource()
	};

	public async componentDidMount() {
		try {
			const profile = await this.state.resource.get(this.getIdParam());
			this.setState({ profile });
		} catch (error) {
			this.setState({ error });
		}
	}

	public render() {
		const { profile, activeTab, error } = this.state;

		if (error) {
			throw error;
		}

		if (!profile) {
			return <Loading />;
		}

		return (
			<TabbedLayout<RoboscoreParameterIndexResponse>
				activeTab={activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={RoboscoringRouter}
				profile={profile}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<RoboscoringEditor editMode={profile} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
