import {
	LcrTaskCreateRequest,
	LcrTaskIndexResponse,
	LcrTaskProfileResponse
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class LcrTasksResource extends HttpResource<
	LcrTaskIndexResponse,
	LcrTaskProfileResponse
> {
	constructor() {
		super(Rt800ApiRoutes.LcrTasks);
	}

	public create(
		respOrgId: string,
		templateName: string,
		allowAsDefaultOnly: number,
		minuteProfileId: number,
		skipSavingsLimit: number,
		cprSizeLimit: number,
		cprRowLimit: number,
		taskLabel: string
	) {
		const req: LcrTaskCreateRequest = {
			respOrgId,
			templateName,
			allowAsDefaultOnly,
			minuteProfileId,
			skipSavingsLimit,
			cprSizeLimit,
			cprRowLimit,
			taskLabel
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute<LcrTaskProfileResponse>(
			Rt800ApiRoutes.LcrTasks.Create,
			{ body }
		);
	}
}
