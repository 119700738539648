import { ResourceType } from 'RtModels';

/**
 * Get an array of all values from ResourceType
 * Note: Excludes ResourceType.CarrierConnect
 */
export const GetResourceTypeValues = () => {
	const allTypes: ResourceType[] = [];

	for (const resourceTypeEnumKeyOrValue of Object.values(ResourceType)) {
		if (typeof resourceTypeEnumKeyOrValue !== 'number') {
			continue;
		}
		if (resourceTypeEnumKeyOrValue === ResourceType.CarrierConnect) {
			continue;
		}

		allTypes.push(resourceTypeEnumKeyOrValue);
	}

	return allTypes;
};

/**
 * Get all integration types that are not in argument "inTypes"
 * @param inTypes
 */
export const GetResourceTypesNotIn = (inTypes: ResourceType[]) => {
	const notInTypes: ResourceType[] = [];

	for (const resourceTypeEnumKeyOrValue of GetResourceTypeValues()) {
		const isNotInType = !inTypes.includes(resourceTypeEnumKeyOrValue);

		if (isNotInType) {
			notInTypes.push(resourceTypeEnumKeyOrValue);
		}
	}

	return notInTypes;
};

/**
 * Get name of integration type.
 * Note: if type is not found, an empty string is returned
 * @param inTypes
 */
export const GetResourceTypeName = (type: ResourceType) => {
	let typeName = '';

	if (type === ResourceType.Billing) {
		typeName = 'Billing';
	} else if (type === ResourceType.Switch) {
		typeName = 'Switch';
	} else if (type === ResourceType.DidVendor) {
		typeName = 'Vendor';
	} else if (type === ResourceType.CarrierConnect) {
		typeName = 'Carrier Connect';
	}

	return typeName;
};
