import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import {
	SubscriptionTagIndexResponse,
	SubscriptionTagProfileResponse
} from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface ITagAssignmentRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const TagAssignmentContainerTabs: ITagAssignmentRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class TagAssignmentRouterClass extends SipApplicationRouter<
	SubscriptionTagIndexResponse,
	SubscriptionTagProfileResponse,
	ITagAssignmentRouterTabs
> {
	constructor() {
		super(
			'Tag Assignment',
			'tagAssignment',
			'subscriptionId',
			TagAssignmentContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.Tags);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}

	public recordHasAccessToProfile() {
		return false;
	}
}

export const TagAssignmentRouter = new TagAssignmentRouterClass();
