import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import {
	CarrierSelect,
	ICicIndexResponse
} from 'RtUi/app/rt800/Cprs/lib/controls/CarrierSelect';
import { LcrCarriersResource } from '../resources/LcrCarriersResource';
import {
	RespOrgIndexResponse,
	LcrCarrierProfileResponse,
	LcrCarrierCreateRequest,
	LcrCarrierUpdateRequest
} from 'RtModels';
import {
	PrecisionFormControl,
	PrecisionFormControlTypes
} from 'RtUi/components/form/PrecisionInputFormControl';

interface ILcrCarrierEditorProps {
	createMode?: boolean;
	editMode?: LcrCarrierProfileResponse;
	onUpdate: (carrierProfile: LcrCarrierProfileResponse) => void;
}

interface ILcrCarrierEditorState {
	respOrgId: string;
	label: string;
	cic: string;
	allowAsDefault: number;
	allowAsCanada: number;
	defaultInterRate: number;
	defaultIntraRate: number;
	defaultIndetRate: number;
	defaultNpa: string;
	defaultNxx: string;
	isActive: number;
	//form
	displayMode: boolean;
	selectedCarrier?: ICicIndexResponse;
	selectedRespOrg?: RespOrgIndexResponse;
	error?: any;
	isSubmitting: boolean;
}

export class LcrCarrierEditor extends React.Component<
	ILcrCarrierEditorProps,
	ILcrCarrierEditorState
> {
	public state: ILcrCarrierEditorState = {
		respOrgId: '',
		label: '',
		cic: '',
		defaultNpa: '',
		defaultNxx: '',
		allowAsDefault: 1,
		allowAsCanada: 1,
		defaultInterRate: 1,
		defaultIntraRate: 1,
		defaultIndetRate: 1,
		isActive: 1,
		displayMode: false,
		error: undefined,
		isSubmitting: false
	};

	public componentDidMount() {
		this.loadProfileIfExists();
	}

	public loadProfileIfExists() {
		const { editMode: lcrCarrierProfile } = this.props;

		if (lcrCarrierProfile) {
			const displayMode = true;
			const {
				respOrgId,
				label,
				cic,
				allowAsDefault,
				allowAsCanada,
				defaultInterRate,
				defaultIntraRate,
				defaultIndetRate,
				isActive,
				defaultNpa,
				defaultNxx
			} = lcrCarrierProfile;

			this.setState({
				respOrgId,
				label,
				cic,
				allowAsDefault,
				allowAsCanada,
				defaultInterRate,
				defaultIntraRate,
				defaultIndetRate,
				displayMode,
				isActive,
				defaultNpa,
				defaultNxx
			});
		}
	}

	public async submit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		const { createMode, editMode } = this.props;
		const {
			selectedRespOrg,
			selectedCarrier,
			label,
			allowAsDefault,
			allowAsCanada,
			defaultInterRate,
			defaultIntraRate,
			defaultIndetRate,
			isActive,
			defaultNpa,
			defaultNxx
		} = this.state;

		if (!selectedRespOrg || !selectedCarrier) {
			return;
		}

		this.setState({ error: undefined, isSubmitting: true });

		const lcrCarriersResource = new LcrCarriersResource();
		const respOrgId = selectedRespOrg.respOrgId;
		const cic = selectedCarrier.cic;

		try {
			let updatedCarrierProfile: LcrCarrierProfileResponse | undefined;
			const createReq: LcrCarrierCreateRequest = {
				respOrgId,
				cic,
				label,
				allowAsDefault,
				allowAsCanada,
				defaultInterRate,
				defaultIntraRate,
				defaultIndetRate,
				defaultNpa,
				defaultNxx
			};

			if (createMode) {
				updatedCarrierProfile = await lcrCarriersResource.create(createReq);
			} else if (editMode) {
				const { lcrCarrierId } = editMode;
				const updateReq: LcrCarrierUpdateRequest = {
					...createReq,
					isActive
				};

				updatedCarrierProfile = await lcrCarriersResource.update(
					lcrCarrierId,
					updateReq
				);
			}

			this.setState({ isSubmitting: false, displayMode: true });

			if (updatedCarrierProfile) {
				this.props.onUpdate(updatedCarrierProfile);
			}
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	}

	public onCancel() {
		this.loadProfileIfExists();

		this.setState({
			selectedCarrier: undefined,
			selectedRespOrg: undefined
		});
	}

	public precisionToNumber(onChangeValue: PrecisionFormControlTypes) {
		if (onChangeValue === null || onChangeValue < 0) {
			onChangeValue = 0;
		}

		return onChangeValue;
	}

	public render() {
		const { displayMode } = this.state;

		return (
			<RtUiWideForm
				isSubmitting={this.state.isSubmitting}
				createMode={this.props.createMode}
				onCancel={() => this.onCancel()}
				displayMode={displayMode}
				onChange={(displayMode) => this.setState({ displayMode })}
				onSubmit={(e) => this.submit(e)}
				error={this.state.error}
			>
				<Row>
					<Col lg={6}>
						<InputFormControl
							label="Label"
							displayMode={displayMode}
							required
							onChange={(label) => this.setState({ label })}
							value={this.state.label}
						/>
						<RespOrgSelect
							displayMode={displayMode}
							required
							onChange={(selectedRespOrg) => this.setState({ selectedRespOrg })}
							value={this.state.selectedRespOrg}
							initialOptionId={this.state.respOrgId}
						/>
						<PrecisionFormControl
							label="Default Inter."
							displayMode={displayMode}
							required
							min={0}
							step={0.000001}
							onChange={(defaultInterRate) =>
								this.setState({
									defaultInterRate: this.precisionToNumber(defaultInterRate)
								})
							}
							value={this.state.defaultInterRate}
							precision={6}
							name="defaultInterRate"
						/>
						<PrecisionFormControl
							label="Default Intra."
							displayMode={displayMode}
							required
							min={0}
							step={0.000001}
							onChange={(defaultIntraRate) =>
								this.setState({
									defaultIntraRate: this.precisionToNumber(defaultIntraRate)
								})
							}
							value={this.state.defaultIntraRate}
							precision={6}
							name="defaultIntraRate"
						/>
						<PrecisionFormControl
							label="Default Indet."
							displayMode={displayMode}
							required
							min={0}
							step={0.000001}
							onChange={(defaultIndetRate) =>
								this.setState({
									defaultIndetRate: this.precisionToNumber(defaultIndetRate)
								})
							}
							value={this.state.defaultIndetRate}
							precision={6}
							name="defaultIndetRate"
						/>
						{this.props.editMode?.lastRatesUploadedEmail && (
							<InputFormControl
								label="Last Updated by"
								displayMode
								value={this.props.editMode.lastRatesUploadedEmail}
							/>
						)}
					</Col>
					<Col lg={6}>
						<CarrierSelect
							label="CIC"
							displayMode={displayMode}
							required
							onChange={(selectedCarrier: ICicIndexResponse) =>
								this.setState({ selectedCarrier })
							}
							value={this.state.selectedCarrier}
							initialOptionId={this.state.cic}
						/>
						<InputFormControl
							type="text"
							label="Default NPA"
							displayMode={displayMode}
							required
							maxLength={3}
							minLength={3}
							removeNonNumericCharacters
							onChange={(defaultNpa) => this.setState({ defaultNpa })}
							value={this.state.defaultNpa}
							name="defaultNpa"
						/>
						<InputFormControl
							type="text"
							label="Default NXX"
							displayMode={displayMode}
							required
							maxLength={3}
							minLength={3}
							removeNonNumericCharacters
							onChange={(defaultNxx) => this.setState({ defaultNxx })}
							value={this.state.defaultNxx}
							name="defaultNxx"
						/>
						<BooleanRadioFormControl
							label="Allow as Default"
							displayMode={displayMode}
							required
							onChange={(allowAsDefault) => this.setState({ allowAsDefault })}
							value={this.state.allowAsDefault}
							name="allowAsDefault"
						/>
						<BooleanRadioFormControl
							label="Allow as Canada"
							displayMode={displayMode}
							required
							onChange={(allowAsCanada) => this.setState({ allowAsCanada })}
							value={this.state.allowAsCanada}
							name="allowAsCanada"
						/>
						{!this.props.createMode && (
							<IsActiveRadioFormControl
								required
								hideBothOption
								displayMode={displayMode}
								onChange={(isActive) => this.setState({ isActive })}
								value={this.state.isActive}
							/>
						)}
					</Col>
				</Row>
			</RtUiWideForm>
		);
	}
}
