//import * as React from 'react';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import {
	CdrExportIntervalResource,
	ICdrExportIntervalIndex
} from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportIntervalResource';

interface ICdrExportIntervalSelectProps
	extends ISelectFormControlProps<ICdrExportIntervalIndex> {}

export class CdrExportIntervalSelect extends SelectFormControl<
	ICdrExportIntervalIndex,
	false,
	ICdrExportIntervalSelectProps
> {
	public resourceClass = CdrExportIntervalResource;
	public state: ISelectFormControlState<ICdrExportIntervalIndex> = {
		formLabel: 'Export Interval',
		valueKey: 'intervalId',
		labelKey: 'intervalName'
	};
}
