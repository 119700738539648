import { useState } from 'react';
import { generateUniqueReactKey } from 'RtUi/utils/http/resources/utils';

/**
 * returns a unique id that can be used for key props
 * the second item returns generates a new unique id on invoke
 */
export const useUuid = (): [string, () => void] => {
	const [uniqueId, setUniqueId] = useState<string>(generateUniqueReactKey());
	const generateNewUniqueId = () => setUniqueId(generateUniqueReactKey());

	return [uniqueId, generateNewUniqueId];
};
