import moment from 'moment';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	AccountIndexResponse,
	CallflowDirectionId,
	CountryIndexResponse,
	MarginSummaryColumns,
	RateCenterIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';

export interface IMarginSummaryReportFormProps {
	initialState?: IMarginSummaryReportFormState;
	onSubmit: (
		e: React.FormEvent<HTMLFormElement>,
		formData: IMarginSummaryReportFormState
	) => void;
}

export interface IMarginSummaryReportFormState {
	startDate?: moment.Moment;
	endDate?: moment.Moment;
	direction: CallflowDirectionId;
	costAccounts?: AccountIndexResponse[];
	costSubscriptions?: SubscriptionIndexResponse[];
	revenueAccounts?: AccountIndexResponse[];
	revenueSubscriptions?: SubscriptionIndexResponse[];
	countries?: CountryIndexResponse[];
	rateCenter?: RateCenterIndexResponse[];
	columns?: Array<ISimpleSelectFormControlOption<MarginSummaryColumns>>;
}

export class MarginSummaryReportForm extends React.Component<
	IMarginSummaryReportFormProps,
	IMarginSummaryReportFormState
> {
	constructor(props: IMarginSummaryReportFormProps) {
		super(props);

		if (props.initialState) {
			this.state = props.initialState;
		} else {
			const now = moment();
			this.state = {
				direction: CallflowDirectionId.Inbound,
				startDate: now.startOf('day'),
				endDate: now.endOf('day')
			};
		}
	}

	public render() {
		return (
			<RtUiSearchForm wide onSubmit={(e) => this.props.onSubmit(e, this.state)}>
				<Row>
					<Col md={6}>
						<DatetimeFormControl
							required
							label="Start Date"
							showDateOnly={true}
							value={this.state.startDate}
							onChange={(startDate) =>
								this.setState({
									startDate: moment(startDate)
										.set(`hour`, 0)
										.set('minutes', 0)
										.set('seconds', 0)
								})
							}
						/>
						<DatetimeFormControl
							required
							label="End Date"
							showDateOnly={true}
							value={this.state.endDate}
							onChange={(endDate) =>
								this.setState({
									endDate: moment(endDate).endOf('day')
								})
							}
						/>
						<RadioFormControl<CallflowDirectionId>
							required
							label="Direction"
							value={this.state.direction}
							options={[
								{ value: CallflowDirectionId.Inbound, label: 'Inbound' },
								{ value: CallflowDirectionId.Outbound, label: 'Outbound' }
							]}
							onChange={(direction) => this.setState({ direction })}
						/>
						<CountrySelect<true>
							isMulti
							label="Countries"
							value={this.state.countries}
							onChange={(countries) => this.setState({ countries })}
						/>
						<RateCenterSelect<true>
							label="Rate Center"
							value={this.state.rateCenter}
							onChange={(rateCenter) => this.setState({ rateCenter })}
							multi
						/>
					</Col>
					<Col md={6}>
						<AccountSelect<true>
							label="Cost accounts"
							value={this.state.costAccounts}
							onChange={(costAccounts) => this.setState({ costAccounts })}
							multi
						/>
						<SubscriptionSelect<true>
							label="Cost subscriptions"
							value={this.state.costSubscriptions}
							onChange={(costSubscriptions) =>
								this.setState({ costSubscriptions })
							}
							isMulti
						/>
						<AccountSelect<true>
							label="Revenue accounts"
							value={this.state.revenueAccounts}
							onChange={(revenueAccounts) => this.setState({ revenueAccounts })}
							multi
						/>
						<SubscriptionSelect<true>
							label="Revenue subscriptions"
							value={this.state.revenueSubscriptions}
							onChange={(revenueSubscriptions) =>
								this.setState({ revenueSubscriptions })
							}
							isMulti
						/>

						<SimpleSelectFormControl<MarginSummaryColumns, true>
							label="Columns"
							multi
							clearable={true}
							onChange={(columns) => this.setState({ columns })}
							value={this.state.columns}
							options={[
								{
									label: 'Cost Account',
									value: MarginSummaryColumns.CostAccountId
								},
								{
									label: 'Cost Subscription',
									value: MarginSummaryColumns.CostSubscriptionId
								},
								{
									label: 'Country Iso',
									value: MarginSummaryColumns.CountryIsoId
								},
								{
									label: 'Rate Center Id',
									value: MarginSummaryColumns.RateCenterId
								},
								{
									label: 'Revenue Account',
									value: MarginSummaryColumns.RevenueAccountId
								},
								{
									label: 'Revenue Subscription',
									value: MarginSummaryColumns.RevenueSubscriptionId
								}
							]}
						/>
					</Col>
				</Row>
			</RtUiSearchForm>
		);
	}
}
