import { FinancialIndexResponseData } from 'RtModels';
import { VueTotalsLineChartAbstract } from 'RtUi/app/rtVue/common/lib/charts/VueTotalsLineChartAbstract';
import { ChartJsColors } from 'RtUi/components/charts/Constants';

export class FinancialTotalsLineChart extends VueTotalsLineChartAbstract<FinancialIndexResponseData> {
	protected async getChartData() {
		const financialChartData: any = {};
		const { resource } = this.props;

		if (!resource) {
			return financialChartData;
		}

		const financialIndexes = await resource.getAll();
		financialIndexes.sort((a, b) => {
			return a.callDate < b.callDate ? -1 : 1;
		});

		financialChartData.data = {};
		financialChartData.data.datasets = [];
		const labelsAndProperties: Array<
			[string, keyof FinancialIndexResponseData, string]
		> = [
			['Revenue', 'revenue', ChartJsColors.LightOrange],
			['Cost', 'cost', 'rgb(175,216,248)'],
			['Margin', 'margin', 'rgb(203,75,75)']
		];

		financialChartData.data.labels = this.getLabels(financialIndexes);

		for (const [label, property, color] of labelsAndProperties) {
			const data: any = [];

			for (const financialIndex of financialIndexes) {
				let value = 0;

				if (property in financialIndex) {
					value = parseInt(String(financialIndex[property]), 10);
				}

				data.push(value);
			}

			financialChartData.data.datasets.push({
				type: 'line',
				label,
				data,
				fill: false,
				borderColor: color,
				backgroundColor: color,
				lineTension: 0.4
			});
		}

		return financialChartData;
	}
}
