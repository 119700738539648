import { PartitionEntityIndexRequest, PartitionIndexResponse } from 'RtModels';
import { PartitionRouter } from 'RtUi/app/rtAdmin/Partitions/Partition.router';
import { useGetPartitions } from 'RtUi/app/rtAdmin/Partitions/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getPartitionColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/partitions';
import { useMemo } from 'react';

interface IPartitionDataGridProps {
	resourceParams?: PartitionEntityIndexRequest;
	autoFocusFilter?: boolean;
}

export const PartitionDataGrid = ({}: IPartitionDataGridProps) => {
	const { data, isLoading } = useGetPartitions();

	const columns = useMemo(() => getPartitionColumns(), []);

	return (
		<DataGrid<PartitionIndexResponse>
			data={data?.data}
			columns={columns}
			router={PartitionRouter}
			pageName={'rtAdmin-partitions'}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
