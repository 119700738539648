/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationTopOpenItemsIndexResponse = {
    properties: {
        chargeType: {
            type: 'string',
            isRequired: true,
        },
        serviceDetail: {
            type: 'string',
            isRequired: true,
        },
        disputedAmount: {
            type: 'number',
            isRequired: true,
        },
        assignedTo: {
            type: 'number',
            isRequired: true,
        },
        assignedToEmailAddress: {
            type: 'string',
            isRequired: true,
        },
    },
};