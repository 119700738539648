import { LcrTaskResultProfileResponse } from 'RtModels';
import { LcrTaskCarrierResultsGrid } from 'RtUi/app/rtLco/TaskResults/lib/grids/LcrTaskResultsGrid';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { TaskRouter } from '../../rt800/Tasks/Task.router';
import { ITaskProfile } from '../../rt800/Tasks/lib/resources/TaskResource';
import { LcrTaskResultRouter } from './LcrTaskResult.router';
import { LcrTaskResultActivateForm } from './lib/forms/LcrTaskResultActivateForm';
import { LcrTaskResultsResource } from './lib/resources/LcrTaskResultsResource';

interface ILcrTaskResultContainerState {
	activeTab: string;
	lcrTaskResultId: number;
	lcrTaskId: number;
	lcrTaskResult?: LcrTaskResultProfileResponse;
}

@LcrTaskResultRouter.getProfileRtUiController()
export class LcrTaskResultProfileContainer extends ProfileApplicationContainer<
	{},
	ILcrTaskResultContainerState
> {
	public Tabs = LcrTaskResultRouter.getProfileTabs();

	public state: ILcrTaskResultContainerState = {
		activeTab: this.Tabs.Profile.header,
		lcrTaskResultId: -1,
		lcrTaskId: -1
	};

	public componentDidMount() {
		const lcrTaskResultId = parseInt(this.getIdParam(), 10);
		const lcrTaskIdStr = this.getUrlParam('lcrTaskId');

		if (!lcrTaskIdStr) {
			return;
		}

		const lcrTaskId = parseInt(lcrTaskIdStr, 10);
		const lcrTaskResultsResource = new LcrTaskResultsResource(lcrTaskId);

		lcrTaskResultsResource.get(lcrTaskResultId).then((lcrTaskResult) => {
			this.setState({ lcrTaskResult });
		});

		this.setState({
			lcrTaskId,
			lcrTaskResultId
		});
	}

	public onActivate(taskProfile: ITaskProfile) {
		const taskProfilePath = TaskRouter.getProfileRoute(taskProfile.taskId);

		this.goToPath(taskProfilePath);
	}

	public render() {
		const { lcrTaskResult, lcrTaskId, lcrTaskResultId } = this.state;

		if (!lcrTaskResult) {
			return <Loading />;
		}

		return (
			<TabbedLayout<LcrTaskResultProfileResponse>
				router={LcrTaskResultRouter}
				profile={lcrTaskResult}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<LcrTaskCarrierResultsGrid
						resourceParams={{ lcrTaskId, lcrTaskResultId }}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Activate}>
					<LcrTaskResultActivateForm
						lcrTaskId={this.state.lcrTaskId}
						lcrTaskResultId={this.state.lcrTaskResultId}
						onSuccess={(taskProfile) => this.onActivate(taskProfile)}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
