/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RoutingTemplateTypes {
    Generic = 1,
    Working = 2,
    Default = 3,
    Alternate = 4,
}