/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationUsageDetailsIndexRequest = {
    properties: {
        guardianReconciliationUsageDetailId: {
            type: 'number',
        },
        reconciliationId: {
            type: 'number',
        },
        serviceTypeId: {
            type: 'number',
        },
        mappingId: {
            type: 'number',
            isNullable: true,
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        fromStartTs: {
            type: 'Date',
            format: 'date-time',
        },
        endStartTs: {
            type: 'Date',
            format: 'date-time',
        },
    },
};