import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	NetworkEfficiencyIndexRequest,
	NetworkEfficiencyIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handlePostRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchNetworkEfficiency = async (
	body: NetworkEfficiencyIndexRequest
): Promise<FullResponse<NetworkEfficiencyIndexResponse[]>> => {
	return handlePostRequest<NetworkEfficiencyIndexResponse[], true>(
		RtxSipApiRoutes.NetworkEfficiency.Index,
		body,
		{
			includeFullResponse: true
		}
	);
};

export const useGetNetworkEfficiency = (
	body: NetworkEfficiencyIndexRequest
) => {
	return useQuery<FullResponse<NetworkEfficiencyIndexResponse[]>, Error>(
		[`useGetNetworkEfficiency`, body],
		() => fetchNetworkEfficiency(body),
		{ enabled: true }
	);
};
