import { Rt800Router } from 'RtUi/app/rt800/rt800.router';
import { IMgiTaskLabelType } from './lib/interfaces';
import { MgiTaskOptionsHelper } from './lib/MgiTaskOptions';
import { ITaskIndexResponse } from './lib/resources/TaskResource';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { assign, cloneDeep } from 'lodash-es';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/Task.profile';

interface IMgiTaskLabelTypeTabs {
	header: IMgiTaskLabelType;
}

interface ITabbedTaskContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Alerts: TabbedLayoutTabProps;
	Numbers: TabbedLayoutTabProps;
}

export const TaskContainerTabs: ITabbedTaskContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Alerts: {
		header: 'Alerts'
	},
	Numbers: {
		header: 'Numbers'
	}
};

class TaskRouterClass extends Rt800Router<
	ITaskIndexResponse,
	ITaskProfile,
	ITabbedTaskContainerTabs
> {
	constructor() {
		super('Task', 'tasks', 'taskId', TaskContainerTabs);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.Tasks);
	}

	public getIndexLabel(record: ITaskIndexResponse) {
		return record.summary;
	}

	public getProfileTabs(profile: ITaskProfile) {
		const profileTabs = cloneDeep(TaskContainerTabs);

		if (profile.hasNumbers) {
			const actionTabs = this.getActionTabs(profile);
			assign(profileTabs, actionTabs);
		}

		return profileTabs;
	}

	//Must refresh actions when permissions update
	public getActionTabs(profile: ITaskProfile): IMgiTaskLabelTypeTabs[] {
		if (!profile.hasNumbers) {
			return [];
		}

		return MgiTaskOptionsHelper.getInstance()
			.getTaskOptionLabels()
			.map((taskOptionLabel) => ({
				header: taskOptionLabel,
				isAction: true
			}));
	}

	public getIndexMgiTaskOptions() {
		const mgiTaskOptionsHelperInstance = MgiTaskOptionsHelper.getInstance();
		const mgiTaskOptions = mgiTaskOptionsHelperInstance.getBulkOptions();

		return mgiTaskOptions;
	}

	public getIndexMgiTaskOptionTabs() {
		const mgiTaskOptionsHelperInstance = MgiTaskOptionsHelper.getInstance();
		const mgiTaskOptions = mgiTaskOptionsHelperInstance.getBulkOptions();

		const tabsFromOptions: IProfileApplicationContainerTabs = {};

		for (const mgiTaskOption of mgiTaskOptions) {
			tabsFromOptions[mgiTaskOption.label] = {
				header: mgiTaskOption.label,
				isAction: true
			};
		}

		return tabsFromOptions;
	}

	public getProfileLabel(profile: ITaskProfile) {
		const { referenceKey } = profile;
		let label = profile.summary;
		const isRoutingCopy = label.toLowerCase().includes('routing copy');
		const formattedReferenceKey = referenceKey
			? this.getFormattedReferenceKey(referenceKey)
			: '';

		if (isRoutingCopy && referenceKey) {
			label += ` (${formattedReferenceKey})`;
		}

		return label;
	}

	public getFormattedReferenceKey(referenceKey: string) {
		const isTemplate = referenceKey.startsWith('*');

		if (isTemplate) {
			return referenceKey;
		}

		return TollFreeNumbersUtils.tollFreeNumberToReadableString(referenceKey);
	}
}

export const TaskRouter = new TaskRouterClass();
