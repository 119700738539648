import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RtCarrierConnectModuleRouter } from '../RtCarrierConnect.router';
import { LingoApiRoutes } from 'RtExports/routes';
import { JobIndexResponse, JobProfileResponse } from 'RtModels';

interface IJobProfileContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const JobProfileContainerTabs: IJobProfileContainerTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class JobRouterClass extends RtCarrierConnectModuleRouter<
	JobIndexResponse,
	JobProfileResponse,
	IJobProfileContainerTabs
> {
	constructor() {
		super('Job', 'jobs', 'jobId', JobProfileContainerTabs);

		this.setPermissionsFromApiRoute(LingoApiRoutes.Jobs);
	}

	public getIndexLabel(record: JobIndexResponse) {
		return record.jobLabel;
	}

	public getProfileLabel(profile: JobProfileResponse) {
		return profile.label;
	}
}

export const JobRouter = new JobRouterClass();
