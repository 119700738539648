import {
	CheckboxButtons,
	ICheckboxButtonOption
} from 'RtUi/components/ui/CheckboxButtons';
import {
	FormControl,
	IFormControlProps
} from 'RtUi/components/form/FormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';

export interface IMultipleCheckboxFormControlProps<T>
	extends IFormControlProps<T, true> {
	options: Array<ICheckboxButtonOption<T>>;
	initialValue?: T[];
}

export class MultipleCheckboxFormControl<T = string[]> extends FormControl<
	T,
	true,
	IMultipleCheckboxFormControlProps<T>
> {
	public resource = undefined;
	public state = {
		formLabel: this.props.label || ''
	};

	public componentDidMount() {
		const { value, initialValue, onChange = () => ({}) } = this.props;

		if (typeof value === 'undefined') {
			if (typeof initialValue !== 'undefined') {
				onChange(initialValue);
			}
		}
	}

	public render() {
		const { options, className, label, value, displayMode } = this.props;

		if (options.length <= 0) {
			return null;
		}

		return (
			<ControlGroup
				className={className}
				label={label || ''}
				value={<>value</>}
				displayMode={displayMode}
				required={this.props.required}
			>
				<CheckboxButtons
					options={options}
					value={value}
					name={this.props.name}
					onChange={(value) => this.triggerOnChange(value)}
					required={this.props.required}
				/>
			</ControlGroup>
		);
	}
}
