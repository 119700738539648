import {
	IdddSearchRequest,
	IdddSearchResponse,
	NanpIndexResponse,
	NanpSearchRequest,
	NanpSearchResponse,
	ResourceConfigurationProfileResponse
} from 'RtModels';
import {
	postReserveNumbers,
	useGetNanpNumbers
} from 'RtUi/app/rtDid/Search/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getDidNanpNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rtDid/search';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

export type DidNanpIndexRequest = NanpSearchRequest | IdddSearchRequest;

export type DidNanpIndexResponse<T extends DidNanpIndexRequest> =
	T extends NanpSearchRequest
		? NanpSearchResponse & NanpIndexResponse
		: IdddSearchResponse;

export type IsNanp<T extends DidNanpIndexRequest> = T extends IdddSearchRequest
	? undefined
	: true;

interface IDidNumbersGridProps<T extends DidNanpIndexRequest> {
	header?: React.ReactNode;
	onRowClick?: (row: DidNanpIndexResponse<T>) => void;
	resourceParams: DidNanpIndexRequest;
	autoFocusFilter?: boolean;
	hidePagination?: boolean;
	disableFilter?: boolean;
	isNanp: IsNanp<T>;
	configurations: ResourceConfigurationProfileResponse[];
}

export const DidNanpNumbersGrid = <T extends DidNanpIndexRequest>({
	resourceParams,
	isNanp,
	configurations,
	header
}: IDidNumbersGridProps<T>) => {
	const { data, isFetching } = useGetNanpNumbers(
		resourceParams,
		Boolean(isNanp)
	);
	const columns = useMemo(
		() => getDidNanpNumbersColumns<T>(Boolean(isNanp)),
		[isNanp]
	);
	const numbersConfiguration = useMemo(() => new Map<string, number>(), []);
	const navigate = useNavigate();

	const [selectedNumbers, setSelectedNumbers] = useState<
		Array<DidNanpIndexResponse<T>>
	>([]);
	const [openModal, setModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const { mutateAsync, isLoading } = useMutation(postReserveNumbers);

	const getDidNumber = (didNumber: DidNanpIndexResponse<T>) => {
		if (isNanp) {
			return didNumber.nanpNumber;
		}

		return (didNumber as IdddSearchResponse).did;
	};

	const handleReserveNumbers = async () => {
		const payload = Array.from(numbersConfiguration.entries())
			.filter(([didNumber]) =>
				selectedNumbers.some((n) => getDidNumber(n) === didNumber)
			)
			.map(([did, resourceConfigurationId]) => {
				return {
					did,
					resourceConfigurationId
				};
			});

		try {
			const data = await mutateAsync({ numbers: payload });
			const { jobIds } = data;
			if (jobIds.length > 1) {
				navigate('/rtCarrierConnect/jobs');
			} else {
				navigate(`/rtCarrierConnect/jobs/${jobIds[0]}?tab=Profile`);
			}
		} catch (e) {
			setModalOpen(false);
			setError((e as any).message);
		}
	};

	return (
		<>
			<FormErrors error={error} />
			<Modal backdrop="static" show={openModal} size="lg">
				<Modal.Header closeButton onHide={() => setModalOpen(false)}>
					<Modal.Title>Reserve Numbers</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ maxHeight: '300px', overflow: 'scroll' }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '5px'
						}}
					>
						<span style={{ fontWeight: 'bolder', marginBottom: '0.5rem' }}>
							Number
						</span>
						<span
							style={{
								fontWeight: 'bolder',
								marginBottom: '0.5rem',
								marginRight: '11rem'
							}}
						>
							Resource Configuration
						</span>
					</div>
					{selectedNumbers.map((numbers) => {
						const did = getDidNumber(numbers);

						return (
							<div
								key={did}
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '5px'
								}}
							>
								<span style={{ marginTop: '1.5rem' }}>{did}</span>
								<Form.Select
									style={{ width: 350 }}
									value={numbersConfiguration.get(did)}
									onChange={(e) =>
										numbersConfiguration.set(did, Number(e.target.value))
									}
								>
									<>
										<option>Choose a configuration</option>
										{configurations
											.filter((c) => c.resourceId === numbers.resourceId)
											.map((configuration) => {
												return (
													<option
														key={configuration.label}
														value={configuration.resourceConfigurationId}
													>
														{configuration.label}
													</option>
												);
											})}
									</>
								</Form.Select>
							</div>
						);
					})}
				</Modal.Body>
				<Modal.Footer
					style={{ backgroundColor: 'white', justifyContent: 'flex-start' }}
				>
					<Button
						disabled={isLoading}
						variant="white"
						className="me-2"
						onClick={() => setModalOpen(false)}
					>
						Close
					</Button>
					<Button
						disabled={isLoading}
						variant="submit"
						onClick={() => handleReserveNumbers()}
					>
						{isLoading ? (
							<i className="fas fa-cog fa-cog fa-spin" />
						) : (
							<i className={`fas fa-fw fa-check`} />
						)}
						Submit
					</Button>
				</Modal.Footer>
			</Modal>
			<p>{header}</p>
			<DataGrid<DidNanpIndexResponse<T>>
				data={data?.data as Array<DidNanpIndexResponse<T>> | undefined}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isFetching}
				pageName={'rtDid_nanpNumbers'}
				onChangeSelectedRows={setSelectedNumbers}
			/>
			{!isEmpty(selectedNumbers) && (
				<Button
					onClick={() => setModalOpen(true)}
					variant="submit"
					type="submit"
					className="mb-2"
				>
					Reserve Numbers
				</Button>
			)}
		</>
	);
};
