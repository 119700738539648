import { TrackingGroupIndexResponse } from 'RtModels';
import { TrackingGroupResource } from 'RtUi/app/rtSip/TrackingGroups/lib/resources/TrackingGroupResource';
import { TrackingGroupRouter } from 'RtUi/app/rtSip/TrackingGroups/TrackingGroup.router';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface ITrackingGroupSelectProps
	extends ISelectFormControlProps<TrackingGroupIndexResponse> {
	allowAll?: boolean;
}

export class TrackingGroupSelect extends SelectFormControl<
	TrackingGroupIndexResponse,
	false,
	ITrackingGroupSelectProps
> {
	public static defaultProps = {
		router: TrackingGroupRouter
	};
	public resourceClass = TrackingGroupResource;
	public state: ISelectFormControlState<TrackingGroupIndexResponse> = {
		formLabel: 'TrackingGroup',
		valueKey: 'trackingGroupId',
		labelKey: 'label'
	};

	public componentDidMount() {
		const { allowAll = false } = this.props;
		const getAllParams: any = { isActive: 1 };

		if (!allowAll) {
			getAllParams.isManaged = 1;
		}

		this.setGetAllParams(getAllParams);

		super.componentDidMount();
	}

	public optionRenderer = (trackingGroup: TrackingGroupIndexResponse) => (
		<span>
			{trackingGroup.trackingGroupId} ({trackingGroup.label})
		</span>
	);
}
