import { PortLimitIndexResponse } from 'RtModels';
import { AddPortLimitAside } from 'RtUi/app/AccountManagement/Accounts/lib/components/AddPortLimitAside';
import { useGetAccountPortLimits } from 'RtUi/app/AccountManagement/Accounts/lib/services';
import { PortLimitResource } from 'RtUi/app/rtSip/PortLimits/lib/resources/PortLimitResource';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getAccountPortLimitsColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/accounts';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Item } from 'react-contexify';

interface IAccountPortLimitGridProps {
	accountId: number;
	autoFocusFilter?: boolean;
}

export const AccountPortLimitDataGrid = ({
	accountId
}: IAccountPortLimitGridProps) => {
	const [selectedProfile, setSelectedProfile] =
		useState<PortLimitIndexResponse>();
	const [isAddAsideOpen, setIsAddAsideOpen] = useState<boolean>(false);

	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetAccountPortLimits({
		accountId
	});

	const columns = useMemo(() => getAccountPortLimitsColumns(), []);

	const deletePortLimit = async (row: PortLimitIndexResponse) => {
		const portLimitId = row.portLimitId;

		const confirm = await Confirmation.createDelete(
			`Port Limit with ID: ${portLimitId}`
		);
		if (!confirm) {
			return;
		}

		await new PortLimitResource().delete(portLimitId);
		refetch();
	};

	return (
		<>
			<DataGrid<PortLimitIndexResponse>
				disableExternalLinks
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				pageName={'accountManagement_accountPortLimits'}
				loading={isLoading}
				headerAction={() => {
					return (
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id={`addPortLimitsBtn-tooltip`} {...props}>
									Create Port Limit
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									onClick={() => setIsAddAsideOpen(true)}
									variant="white"
									id="addPortLimitsBtn"
								>
									<i className="fas fa-fw fa-plus fa-lg" />
								</Button>
							)}
						</OverlayTrigger>
					);
				}}
				menuLinks={(record) => (
					<>
						<Item
							onClick={() => {
								setSelectedProfile(record);
								setIsAddAsideOpen(true);
							}}
						>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-pen" />
								<span>Edit</span>
							</span>
						</Item>
						<Item onClick={async () => deletePortLimit(record)}>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-trash" />
								<span>Delete</span>
							</span>
						</Item>
					</>
				)}
			/>
			<AddPortLimitAside
				profile={selectedProfile}
				accountId={accountId}
				isOpen={isAddAsideOpen}
				onCancel={() => {
					setSelectedProfile(undefined);
					setIsAddAsideOpen(false);
				}}
				onSave={() => {
					setSelectedProfile(undefined);
					setIsAddAsideOpen(false);
				}}
			/>
			<Confirmation />
		</>
	);
};
