import { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Permissions } from 'RtExports/routes';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { Aside } from 'RtUi/components/ui/Aside';
import { UserActions } from 'RtUi/state/actions/user';

type CreateHeaderActionAsideProps = {
	isLoading: boolean;
	router: RtUiRouter;
	permissions?: Permissions[];
};

export const CreateHeaderActionAside: FC<
	React.PropsWithChildren<CreateHeaderActionAsideProps>
> = ({ isLoading, router, permissions = [], children }) => {
	const [isAsideOpen, setIsAsideOpen] = useState(false);

	const userHasAccess = UserActions.has(...permissions);

	if (!userHasAccess) {
		return null;
	}

	return (
		<>
			<Button
				variant="light"
				disabled={isLoading}
				onClick={() => setIsAsideOpen(true)}
			>
				<i className="fas fa-plus" />
			</Button>
			<Aside disabledBody isOpen={isAsideOpen}>
				<Aside.Header
					header={`Create ${router.getName()}`}
					onClose={() => setIsAsideOpen(false)}
				/>
				<section key={isLoading ? 'loaded' : 'not-loaded'}>{children}</section>
			</Aside>
		</>
	);
};
