import { GuardianReconciliationMappingFiltersIndexResponse } from 'RtModels';
import { GuardianReconciliationMappingFilterValueFacadeSelect } from 'RtUi/app/rtVue/GuardianReconciliationMappingFilter/lib/controls/GuardianReconciliationMappingFilterValueFacadeSelect';
import {
	getReconciliationMappingDataSourceFieldName,
	getReconciliationMappingDataSourceTypeName
} from 'RtUi/app/rtVue/GuardianReconciliationMappingFilter/lib/utils';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';

export const getGuardianReconciliationMappingFilterColumns = (
	removeEntity: (row: GuardianReconciliationMappingFiltersIndexResponse) => void
): Array<DataGridColumn<GuardianReconciliationMappingFiltersIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: removeEntity
	}),
	DefaultDataGridColumn({
		accessorFn: ({ dataSourceField }) =>
			getReconciliationMappingDataSourceFieldName(dataSourceField),
		header: 'Field'
	}),
	DefaultDataGridColumn({
		accessorFn: (data) =>
			getReconciliationMappingDataSourceTypeName(data as any),
		header: 'Type'
	}),
	DefaultDataGridColumn({
		id: 'value',
		header: 'Value',
		getValue: ({ row: { original: record } }) => (
			<GuardianReconciliationMappingFilterValueFacadeSelect
				textDisplayMode
				type={record.dataSourceField}
				dataSourceValue={record.dataSourceValue}
			/>
		)
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DefaultDataGridColumn({
		accessorKey: 'notes',
		header: 'Notes'
	})
];
