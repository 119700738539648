/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianRerateControlUpdateRequest = {
    properties: {
        summary: {
            type: 'string',
            isNullable: true,
        },
        queryParameters: {
            type: 'RerateQueryParameters',
        },
        jobStatus: {
            type: 'RateControlJobStatus',
        },
        jobType: {
            type: 'RateControlJobType',
        },
        rerateType: {
            type: 'RateControlRerateType',
        },
    },
};