import { ReconciliationType, GuardianReconciliationStatus } from 'RtModels';

export const ReconciliationTypeMap = new Map<ReconciliationType, string>([
	[ReconciliationType.Charge, 'Charge'],
	[ReconciliationType.VendorCdr, 'Vendor CDR'],
	[ReconciliationType.SwitchCdr, 'Switch CDR']
]);

export const ReconciliationStatusMap = new Map<
	GuardianReconciliationStatus,
	string
>([
	[GuardianReconciliationStatus.Pending, 'Pending'],
	[GuardianReconciliationStatus.Submitted, 'Submitted'],
	[GuardianReconciliationStatus.VendorApproved, 'Vendor Approved'],
	[GuardianReconciliationStatus.VendorRejected, 'Vendor Rejected'],
	[GuardianReconciliationStatus.Void, 'Void'],
	[GuardianReconciliationStatus.Completed, 'Completed']
]);
