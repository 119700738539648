import * as React from 'react';
import { Card } from 'react-bootstrap';
import {
	CircularProgressbar,
	buildStyles,
	CircularProgressbarWithChildren
} from 'react-circular-progressbar';

interface IDashboardPercentageStatProps {
	header: string;
	percentage?: number;
	isRunning?: boolean;
	content?: string;
	decimal?: number;
	small?: boolean;
	statColor?: string;
	showAs?: 'danger' | 'warning';
}

interface IDashboardPercentageStatState {
	rotation: number;
}

export class DashboardPercentageStat extends React.Component<
	IDashboardPercentageStatProps,
	IDashboardPercentageStatState
> {
	public state: IDashboardPercentageStatState = {
		rotation: 0
	};

	public componentIsMounted = false;

	public componentDidMount() {
		this.componentIsMounted = true;

		this.initRotation();
	}

	public componentWillUnmount() {
		this.componentIsMounted = false;
	}

	public initRotation() {
		if (!this.componentIsMounted) {
			return;
		}

		let { rotation } = this.state;

		rotation += 0.01;

		if (rotation > 1) {
			rotation = 0;
		}

		this.setState({ rotation }, () => {
			setTimeout(() => this.initRotation(), 10);
		});
	}

	public render() {
		const { statColor, showAs, small = false } = this.props;
		let { content, percentage, decimal } = this.props;
		const statClassNames = ['mb-0'];
		const cardBodyClasses = ['rounded'];
		let roundedPercentage = 0;

		if (typeof percentage === 'number') {
			if (isNaN(percentage)) {
				percentage = 0;
			}

			roundedPercentage = Math.min(Math.round(percentage), 100);
		} else if (typeof decimal === 'number') {
			if (isNaN(decimal)) {
				decimal = 0;
			}

			const decimalPercentage = decimal * 100;
			roundedPercentage = Math.min(Math.round(decimalPercentage), 100);
		}

		const percentageLocaleStr = roundedPercentage.toLocaleString() + '%';

		if (!content) {
			content = percentageLocaleStr;
		}

		if (statColor) {
			statClassNames.push(`text-${statColor}`);
		}

		if (showAs) {
			cardBodyClasses.push(`table-${showAs}`);
		}

		if (small) {
			cardBodyClasses.push('py-2');
		}

		return (
			<Card>
				<Card.Body className={cardBodyClasses.join(' ')}>
					<section className="d-flex justify-content-between align-items-center">
						<header>
							<h6
								className="mb-1 text-muted small"
								style={{ textTransform: 'uppercase' }}
							>
								{this.props.header}
							</h6>
							<h5
								className={statClassNames.join(' ')}
								style={{ fontWeight: 700 }}
							>
								{content}
							</h5>
						</header>
						{roundedPercentage >= 100 && (
							<footer>
								<i className="fas fa-fw fa-lg fa-check-circle text-success" />
							</footer>
						)}
						{roundedPercentage < 100 && (
							<footer style={{ flexBasis: '38px' }}>
								<CircularProgressbarWithChildren
									value={roundedPercentage}
									text={percentageLocaleStr}
									styles={buildStyles({
										textSize: 28,
										textColor: '#17a2b8',
										pathColor: '#17a2b8',
										strokeLinecap: 'butt',
										backgroundColor: 'transparent'
									})}
								>
									{this.props.isRunning && (
										<div style={{ width: '85%' }}>
											<CircularProgressbar
												value={7}
												styles={buildStyles({
													rotation: this.state.rotation,
													pathColor: '#17a2b8',
													strokeLinecap: 'butt',
													trailColor: 'transparent'
												})}
											/>
										</div>
									)}
								</CircularProgressbarWithChildren>
							</footer>
						)}
					</section>
				</Card.Body>
			</Card>
		);
	}
}
