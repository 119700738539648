/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtVueApiRoutes } from 'RtExports/routes';
import { FileStreamIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchFileStreams = async (): Promise<
	FullResponse<FileStreamIndexResponse[]>
> => {
	return handleGetRequest<FileStreamIndexResponse[], true>(
		RtVueApiRoutes.FileStreams.Index,
		{
			includeFullResponse: true
		}
	);
};

export const useGetFileStreams = () => {
	return useQuery<FullResponse<FileStreamIndexResponse[]>, Error>(
		'getFileStreams',
		() => {
			return fetchFileStreams();
		}
	);
};
