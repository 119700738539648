import { createContext } from 'react';
import { IRtxApiRoute, Permissions } from 'RtExports/routes';

export type TabLayoutTab = {
	header: string;
	customHeader?: React.ReactElement;
	requireCanManage?: any;
	rtRoute?: IRtxApiRoute;
	permissions?: Permissions[];
	isAction?: boolean;
	isDisabled?: boolean;
	alwaysRender?: boolean;
	isHidden?: boolean;
	isDefault?: boolean;
	customOnClick?: (toggleTabFn: Function) => void;
};

export type TabLayoutContextType = {
	activeTabHeader: string;
	tabs: TabLayoutTab[];
	addTab: (newTab: TabLayoutTab) => void;
	canManageFn?: () => boolean;
};

export const TabLayoutContext = createContext<TabLayoutContextType>({
	activeTabHeader: '',
	tabs: [],
	addTab: () => ({}),
	canManageFn: () => true
});
