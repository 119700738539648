import * as React from 'react';
import { PromptResource } from 'RtUi/app/rtSip/PromptSegments/lib/resources/PromptResource';
import { ScenarioIndexResponse, SubscriptionIndexResponse } from 'RtModels';
import {
	ScenarioAction,
	ScenarioResource
} from 'RtUi/app/rtSip/Scenarios/lib/resources/ScenarioResource';

export interface IScenarioActionFormProps<
	ConcreteScenarioAction extends ScenarioAction
> {
	action: ConcreteScenarioAction;
	onChange: (newAction: ConcreteScenarioAction) => void;
	displayMode: boolean;
}

export interface IScenarioActionFormState {
	subscription?: SubscriptionIndexResponse;
	scenario?: ScenarioIndexResponse;
}

export abstract class ScenarioActionForm<
	ConcreteScenarioAction extends ScenarioAction,
	ScenarioActionFormState extends
		IScenarioActionFormState = IScenarioActionFormState
> extends React.Component<
	IScenarioActionFormProps<ConcreteScenarioAction>,
	ScenarioActionFormState
> {
	public abstract state: ScenarioActionFormState;

	protected readonly serviceIds = [220];

	//Share PromptResource between all digits
	protected promptResource = new PromptResource();

	//Share ScenarioResource between all digits
	protected scenarioResource = new ScenarioResource();
}
