/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ServiceNumberTagIndexResponse = {
    properties: {
        serviceNumberTagId: {
            type: 'number',
            isRequired: true,
        },
        serviceNumber: {
            type: 'string',
            isRequired: true,
        },
        tag: {
            type: 'string',
            isRequired: true,
        },
        minValue: {
            type: 'string',
            isRequired: true,
        },
        maxValue: {
            type: 'string',
            isRequired: true,
        },
        valueQty: {
            type: 'number',
            isRequired: true,
        },
    },
};