import { DestinationTypeId } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DefaultDataGridColumn,
	DataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface CdrExportDestinationTypeDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const CdrExportDestinationTypeDataGridColumn = <T = any,>(
	config: CdrExportDestinationTypeDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	const formattedValue = (value: DestinationTypeId) => {
		switch (value) {
			case DestinationTypeId.Ftp:
				return 'FTP';
			case DestinationTypeId.Aws:
				return 'AWS';
			case DestinationTypeId.Sftp:
				return 'SFTP';
			default:
				return value;
		}
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: DestinationTypeId) => formattedValue(value),
		getValue: ({ cell }) => (
			<div className="text-monospace">
				{formattedValue(cell.getValue<DestinationTypeId>())}
			</div>
		)
	});
};
