/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocLoaCreateRequest = {
    properties: {
        tfns: {
            type: 'Array',
            isRequired: true,
        },
        companyName: {
            type: 'string',
            isRequired: true,
        },
        companyAddress1: {
            type: 'string',
            isRequired: true,
        },
        companyAddress2: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        zipCode: {
            type: 'string',
        },
        respOrgInstructions: {
            type: 'string',
        },
        endUserInformation: {
            type: 'string',
        },
        contact: {
            type: 'RocContactRequest',
        },
    },
};