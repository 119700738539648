import {
	GuardianReconciliationUsageDetailsProfileResponse,
	GuardianReconciliationUsageDialCodeSummaryIndexGrouping
} from 'RtModels';
import { ReconciliationUsageDialCodeSummaryGrid } from 'RtUi/app/rtVue/ReconciliationUsage/lib/grids/ReconciliationUsageDialCodeSummaryGrid';
import { useState } from 'react';
import { Nav } from 'react-bootstrap';

interface IReconciliationUsageDetailsProps {
	profile: GuardianReconciliationUsageDetailsProfileResponse;
}

export const ReconciliationUsageDetails = ({
	profile
}: IReconciliationUsageDetailsProps): JSX.Element => {
	const [activeTab, setActiveTab] =
		useState<GuardianReconciliationUsageDialCodeSummaryIndexGrouping>(
			GuardianReconciliationUsageDialCodeSummaryIndexGrouping.DIALCODE
		);

	return (
		<>
			<Nav
				variant="tabs"
				defaultActiveKey={activeTab}
				onSelect={(tab) =>
					tab &&
					setActiveTab(
						tab as GuardianReconciliationUsageDialCodeSummaryIndexGrouping
					)
				}
			>
				<Nav.Link
					eventKey={GuardianReconciliationUsageDialCodeSummaryIndexGrouping.ISO}
				>
					Country
				</Nav.Link>
				<Nav.Link
					eventKey={
						GuardianReconciliationUsageDialCodeSummaryIndexGrouping.STATE
					}
				>
					State
				</Nav.Link>
				<Nav.Link
					eventKey={
						GuardianReconciliationUsageDialCodeSummaryIndexGrouping.DIALCODE
					}
				>
					Dial Code
				</Nav.Link>
			</Nav>
			<div className="mt-4">
				<ReconciliationUsageDialCodeSummaryGrid
					resourceParams={{
						reconciliationId: profile.reconciliationId,
						grouping: activeTab
					}}
				/>
			</div>
		</>
	);
};
