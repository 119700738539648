/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipAgentAddressProfileRequest = {
    properties: {
        sipAgentAddressId: {
            type: 'number',
            isRequired: true,
        },
    },
};