import {
	GuardianFraudListCreateRequest,
	GuardianFraudListIndexResponse,
	GuardianFraudListProfileResponse,
	GuardianFraudListProfileUrlRequest,
	GuardianFraudListUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { RtVueApiRoutes } from 'RtExports/routes';
import { FraudDestinationType } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/forms/FraudConfigurationListEditor';

export class FraudConfigurationListResource extends HttpResource<
	GuardianFraudListIndexResponse,
	GuardianFraudListProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianFraudList);
	}

	public update(
		guardianFraudListId: number,
		updateRequest: GuardianFraudListUpdateRequest
	) {
		const urlParams: GuardianFraudListProfileUrlRequest = {
			guardianFraudListId
		};

		const body = JSON.stringify(updateRequest);

		return this.fetchWithRoute<GuardianFraudListProfileResponse>(
			RtVueApiRoutes.GuardianFraudList.Update,
			{
				body,
				urlParams
			}
		);
	}

	public create(createRequest: GuardianFraudListCreateRequest) {
		const body = JSON.stringify(createRequest);

		return this.fetchWithRoute<GuardianFraudListProfileResponse>(
			RtVueApiRoutes.GuardianFraudList.Create,
			{
				body
			}
		);
	}

	public delete(guardianFraudListId: number) {
		const urlParams: GuardianFraudListProfileUrlRequest = {
			guardianFraudListId
		};

		return this.fetchWithRoute(RtVueApiRoutes.GuardianFraudList.Delete, {
			urlParams
		});
	}

	public createNewProfile(
		globalRule: number,
		block: number
	): GuardianFraudListCreateRequest {
		return {
			summary: '',
			dataSourceField: '',
			dataSourceValue: '',
			destinationType: FraudDestinationType.DialCode,
			destinationValue: '',
			block: block,
			globalRule: globalRule,
			isActive: 1
		};
	}
}
