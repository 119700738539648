/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $PartitionSipGateway = {
    properties: {
        partitionSipGatewayId: {
            type: 'number',
            isRequired: true,
        },
        partitionId: {
            type: 'number',
            isRequired: true,
        },
        sipGatewayTypeId: {
            type: 'number',
            isRequired: true,
        },
        cloudServerId: {
            type: 'number',
            isRequired: true,
        },
        serverId: {
            type: 'number',
            isRequired: true,
        },
        listenFqdn: {
            type: 'string',
            isRequired: true,
        },
        listenPort: {
            type: 'number',
            isRequired: true,
        },
        codecs: {
            type: 'string',
            isRequired: true,
        },
        context: {
            type: 'string',
            isRequired: true,
        },
        isAnchored: {
            type: 'number',
            isRequired: true,
        },
        egressPercent: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isOnline: {
            type: 'number',
            isRequired: true,
        },
        gwMajorVersion: {
            type: 'number',
            isRequired: true,
        },
        gwMinorVersion: {
            type: 'number',
            isRequired: true,
        },
        gwPatchVersion: {
            type: 'number',
            isRequired: true,
        },
        gwJson: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        inboundPortLimit: {
            type: 'number',
            isRequired: true,
        },
        mediaServerGroupId: {
            type: 'number',
            isRequired: true,
        },
        isDirty: {
            type: 'number',
        },
        reloadHash: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        reloadHashTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        currentInboundCount: {
            type: 'number',
            isRequired: true,
        },
        currentInboundCountTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};