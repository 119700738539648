import {
	CallProcessingResults,
	RateableJurisdictions,
	SipCallSimulatorResponseDtoRateable
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { capitalCase } from 'change-case';

export const getCallSimulatorColumns = (
	srcUseRate: number
): Array<DataGridColumn<SipCallSimulatorResponseDtoRateable>> => [
	IntegerDataGridColumn({
		accessorKey: 'finPos',
		header: 'Final Pos'
	}),
	IntegerDataGridColumn({
		accessorKey: 'lcrPos',
		header: 'LCR Pos'
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	DefaultDataGridColumn({
		header: 'Jurisdiction',
		accessorFn: (record) =>
			record.jurisdictionId
				? String(RateableJurisdictions[record.jurisdictionId as number])
				: ''
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateKey',
		header: 'Rate Key'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rate',
		header: 'Rate',
		decimals: 6
	}),
	PrecisionDataGridColumn({
		header: 'Margin',
		decimals: 6,
		accessorFn: (record) => Number(srcUseRate) - Number(record.rate)
	}),
	DefaultDataGridColumn({
		accessorKey: 'errors',
		header: 'Result',
		getValue: ({ row }) => {
			const value = row.original as SipCallSimulatorResponseDtoRateable;

			if (!value.errors || value.errors.length === 0) {
				return <div className="text-success">Success</div>;
			}

			const errorMessages = value.errors
				.filter((e) => CallProcessingResults[e])
				.map((e) => CallProcessingResults[e]);

			if (errorMessages.length > 0) {
				return (
					<div className="text-danger">
						{errorMessages.map((e) => {
							return <div key={e}>{capitalCase(e.replace(/\_/, ' '))}</div>;
						})}
					</div>
				);
			}

			return <div className="text-danger">Unknown Error</div>;
		}
	})
];
