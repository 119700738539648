/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DialCodeIndexResponse = {
    properties: {
        dialCodeId: {
            type: 'number',
            isRequired: true,
        },
        dialCode: {
            type: 'string',
            isRequired: true,
        },
        countryCode: {
            type: 'string',
            isRequired: true,
        },
        iso3166Alpha3: {
            type: 'string',
            isRequired: true,
        },
        rateCenterId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        location1: {
            properties: {
            },
            isRequired: true,
        },
        location2: {
            properties: {
            },
            isRequired: true,
        },
        countrySpecific1: {
            properties: {
            },
            isRequired: true,
        },
        countrySpecific2: {
            properties: {
            },
            isRequired: true,
        },
        operatingCompanyCode: {
            properties: {
            },
            isRequired: true,
        },
        phoneNumber: {
            properties: {
            },
            isRequired: true,
        },
        operatingCompanyName: {
            type: 'string',
            isRequired: true,
        },
    },
};