import { Action, BrowserHistory, Location } from 'history';
import {
	FC,
	PropsWithChildren,
	ReactNode,
	useLayoutEffect,
	useState
} from 'react';
import { Router } from 'react-router-dom';

interface IApplicationRouterProps {
	basename?: string;
	children?: ReactNode;
	history: BrowserHistory;
}

interface IApplicationRouterState {
	action: Action;
	location: Location;
}

export const ApplicationRouter: FC<
	PropsWithChildren<IApplicationRouterProps>
> = ({ basename, children, history }) => {
	const [state, setState] = useState<IApplicationRouterState>({
		action: history.action,
		location: history.location
	});

	useLayoutEffect(() => history.listen(setState), [history]);

	return (
		<Router
			basename={basename}
			location={state.location}
			navigationType={state.action}
			navigator={history}
		>
			{children}
		</Router>
	);
};
