import * as React from 'react';
import { Aside } from 'RtUi/components/ui/Aside';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { TemplateLcrCarrierResource } from 'RtUi/app/rtLco/Templates/lib/resources/TemplateLcrCarrierResource';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { TemplateLcrCarrierSelect } from 'RtUi/app/rtLco/Templates/controls/TemplateLcrCarrierSelect';
import { TemplateLcrCarrierAvailableIndexResponse } from 'RtModels';

interface ILcrCarriersAddTemplateAsideProps {
	isOpen: boolean;
	templateName: string;
	respOrgId: string;
	onCancel: () => void;
	onBeforeSave: () => void;
	onSave: () => void;
}

interface ILcrCarriersAddTemplateAsideState {
	isSubmitting?: boolean;
	error?: any;
	lcrCarrier?: TemplateLcrCarrierAvailableIndexResponse;
	allowAsDefault: number;
	allowAsCanada: number;
}

export class LcrCarriersAddTemplateAside extends React.Component<
	ILcrCarriersAddTemplateAsideProps,
	ILcrCarriersAddTemplateAsideState
> {
	public initialState: ILcrCarriersAddTemplateAsideState = {
		lcrCarrier: undefined,
		allowAsCanada: 0,
		allowAsDefault: 0,
		isSubmitting: false,
		error: undefined
	};

	public state = this.initialState;

	public render() {
		return (
			<Aside isOpen={this.props.isOpen}>
				<header className="h3 d-flex justify-content-between">
					<span>Add Account to template</span>
					<a
						className="text-dark"
						onClick={() =>
							this.setState(this.initialState, this.props.onCancel)
						}
					>
						<i className="fas fa-fw fa-times" />
					</a>
				</header>

				<hr className="mb-0 " />

				<RtUiForm
					displayMode={false}
					disablePadding
					isSubmitting={this.state.isSubmitting}
					error={this.state.error}
					onCancel={() => this.setState(this.initialState, this.props.onCancel)}
					onSubmit={async (e) => {
						e.preventDefault();

						await this.addLcrCarrier();
					}}
				>
					<InputFormControl
						label="Template Name"
						readOnly={true}
						displayMode={true}
						value={this.props.templateName}
					/>
					<TemplateLcrCarrierSelect
						required
						templateName={this.props.templateName}
						onChange={(lcrCarrier) =>
							this.setState({
								lcrCarrier,
								allowAsDefault: lcrCarrier.allowAsDefault,
								allowAsCanada: lcrCarrier.allowAsCanada
							})
						}
						value={this.state.lcrCarrier}
					/>
					<BooleanRadioFormControl
						label="Allow as default"
						onChange={(value) => this.setState({ allowAsDefault: value })}
						displayMode={false}
						value={this.state.allowAsDefault}
					/>
					<BooleanRadioFormControl
						label="Allow as Canada"
						onChange={(value) => this.setState({ allowAsCanada: value })}
						displayMode={false}
						value={this.state.allowAsCanada}
					/>
				</RtUiForm>
			</Aside>
		);
	}

	private async addLcrCarrier() {
		if (!this.state.lcrCarrier) {
			return;
		}

		try {
			this.props.onBeforeSave();
			const templateLcrCarrierResource = new TemplateLcrCarrierResource(
				this.props.templateName
			);
			this.setState({ isSubmitting: true, error: undefined });
			await templateLcrCarrierResource.addToTemplate(
				this.props.templateName,
				this.state.lcrCarrier.lcrCarrierId,
				this.state.allowAsDefault,
				this.state.allowAsCanada
			);
			this.setState(this.initialState, this.props.onSave);
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	}
}
