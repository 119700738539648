import { RateExpireMode } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

const getRateExpireModeText = (rateExpireMode: RateExpireMode) => {
	if (rateExpireMode === RateExpireMode.Atoz) {
		return 'A to Z';
	}
	return RateExpireMode[rateExpireMode];
};

interface RateLoadExpireModeDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const RateLoadExpireModeDataGridColumn = <T = any,>({
	...config
}: RateLoadExpireModeDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: RateExpireMode) => {
		return <span>{getRateExpireModeText(value)}</span>;
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: any) => getRateExpireModeText(value),
		getValue: ({ cell }) => getValue(cell.getValue<RateExpireMode>())
	});
};
