import ImmutableMap from 'RtUi/utils/immutable/ImmutableMap';
import { IUser } from './actions/user/interfaces';

export interface IApplicationState {
	user: IUser;
}

export type ApplicationStateType = ImmutableMap<IApplicationState>;
export type ApplicationStateListener = (appState: ApplicationStateType) => void;
export type ApplicationStateRemoveListenerFN = () => void;

class ApplicationStateClass {
	private state: ApplicationStateType;
	private listeners: ApplicationStateListener[] = [];

	constructor(init: IApplicationState) {
		this.state = new ImmutableMap(init);
	}

	/**
	 * Get copy of entire data set
	 */
	public clone() {
		return this.state.clone();
	}

	/**
	 * get aspect of state
	 * @param key
	 */
	public get<K extends keyof IApplicationState>(key: K) {
		return this.state.get(key);
	}

	/**
	 * Listen for state changes
	 * @param listener
	 * @param immediate
	 */
	public listen(
		listener: ApplicationStateListener,
		immediate = true,
		pushFirst = false
	): ApplicationStateRemoveListenerFN {
		if (pushFirst) {
			this.listeners.splice(0, 0, listener);
		} else {
			this.listeners.push(listener);
		}

		if (immediate) {
			listener(this.state);
		}

		return () => {
			const index = this.listeners.indexOf(listener);

			if (index >= 0) {
				this.listeners.splice(index, 1);
			}
		};
	}

	/**
	 * Update app state and invoke all Container's onAppStateChange() methods
	 * @param updatedSubState
	 */
	public update<K extends keyof IApplicationState>(
		updatedSubState: Pick<IApplicationState, K>
	) {
		this.state = this.state.update(updatedSubState);

		this.listeners.forEach((listener) => listener(this.state));
	}
}

export const ApplicationState = new ApplicationStateClass({
	user: {
		emailAddress: null,
		loginInfo: null,
		isInitialized: false
	}
});
