/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MonthlyTrendingIndexResponse = {
    properties: {
        key: {
            type: 'string',
            isRequired: true,
        },
        dayNameOfWeek: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        usageType: {
            type: 'string',
            isRequired: true,
        },
        account: {
            type: 'string',
            isRequired: true,
        },
        ratecenter: {
            type: 'string',
            isRequired: true,
        },
        country: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        ocn: {
            type: 'string',
            isRequired: true,
        },
        commonName: {
            type: 'string',
            isRequired: true,
        },
        month0Value: {
            type: 'number',
            isRequired: true,
        },
        month1Value: {
            type: 'number',
            isRequired: true,
        },
        month2Value: {
            type: 'number',
            isRequired: true,
        },
        month3Value: {
            type: 'number',
            isRequired: true,
        },
        month4Value: {
            type: 'number',
            isRequired: true,
        },
        month5Value: {
            type: 'number',
            isRequired: true,
        },
        month6Value: {
            type: 'number',
            isRequired: true,
        },
    },
};