/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum DataSources {
    Accounts = 'carriers',
    Subscriptions = 'subscriptions',
    RateCenters = 'rateCenters',
    IsdnCodes = 'isdnCodes',
    SipCodes = 'sipCodes',
    Countries = 'countries',
    CallType = 'callTypes',
    RespOrgs = 'respOrgs',
    Entities = 'entities',
    Templates = 'templates',
    Boolean = 'boolean',
    Text = 'text',
    Ocn = 'ocn',
    CarrierCommonName = 'carrierCommonName',
    OcnType = 'ocnType',
}