import { TransactionTypes } from 'RtModels';
import { TRANSACTION_TYPES } from 'RtUi/app/rtSip/Transactions/lib/controls/TransactionTypeSelect';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { find } from 'lodash-es';

interface TransactionTypeDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const TransactionTypeDataGridColumn = <T = any,>({
	...config
}: TransactionTypeDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: TransactionTypes) => {
		return find(TRANSACTION_TYPES, { value })!.label;
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: TransactionTypes) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<TransactionTypes>())
	});
};
