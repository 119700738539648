/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { IntegrationsApiRoutes, RtxDidApiRoutes } from 'RtExports/routes';
import {
	IdddSearchRequest,
	IdddSearchResponse,
	NanpSearchRequest,
	NanpSearchResponse,
	ResourceConfigurationProfileResponse,
	ResourceIndexResponse,
	RtDidActivateRequest,
	RtDidActivateResponse
} from 'RtModels';
import { ResourceResource } from 'RtUi/app/rtCommon/Resources/lib/resources/ResourceResource';
import {
	DidNanpIndexRequest,
	DidNanpIndexResponse
} from 'RtUi/app/rtDid/Search/lib/grids/DidNanpNumbersGrid';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
const fetchResources = async (
	extraParams: any,
	queryName: string
): Promise<ResourceIndexResponse[]> => {
	const defaultValue = queryName.includes('available') ? 0 : 1;

	const data = new ResourceResource().getAll({
		isActive: 1,
		isDefault: defaultValue,
		...extraParams
	});
	return data;
};

const fetchConfigurations = async (): Promise<
	ResourceConfigurationProfileResponse[]
> => {
	return handleGetRequest(
		IntegrationsApiRoutes.ResourceConfigurationsIndexed.Index
	);
};

export const postReserveNumbers = async (
	payload: RtDidActivateRequest
): Promise<RtDidActivateResponse> => {
	const body = JSON.stringify(payload);

	return HttpRequest.fetchWithRoute(RtxDidApiRoutes.Activate.Create, {
		body
	});
};

export const useGetResources = (queryName: string, extraParams: any) => {
	return useQuery<ResourceIndexResponse[], Error>(
		[`${queryName}`, extraParams, queryName],
		() => {
			return fetchResources(extraParams, queryName);
		},
		{ enabled: false }
	);
};

export const useGetConfigurations = () => {
	return useQuery<ResourceConfigurationProfileResponse[], Error>(
		'getConfigurations',
		() => {
			return fetchConfigurations();
		}
	);
};

const fetchNanpNumbers = async (
	queryParams: NanpSearchRequest
): Promise<FullResponse<NanpSearchResponse[]>> => {
	return handleGetRequest<NanpSearchResponse[], true>(
		RtxDidApiRoutes.NanpSearch.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

const fetchIdd = async (
	queryParams: IdddSearchRequest
): Promise<FullResponse<IdddSearchResponse[]>> => {
	return handleGetRequest<IdddSearchResponse[], true>(
		RtxDidApiRoutes.IdddSearch.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetNanpNumbers = <T extends DidNanpIndexRequest>(
	queryParams: T,
	isNanp: boolean
) => {
	return useQuery<FullResponse<Array<DidNanpIndexResponse<T>>>, Error>(
		['useGetNanpNumbers', queryParams],
		() => {
			if (isNanp) {
				return fetchNanpNumbers(queryParams) as Promise<
					FullResponse<Array<DidNanpIndexResponse<T>>>
				>;
			}

			return fetchIdd(queryParams as IdddSearchRequest) as Promise<
				FullResponse<Array<DidNanpIndexResponse<T>>>
			>;
		}
	);
};
