import dayjs from 'dayjs';

export const monthNameMap = new Map<string, string>([
	['month0Value', `${dayjs().format('MMMM YYYY')}`],
	['month1Value', `${dayjs().subtract(1, 'months').format('MMMM YYYY')}`],
	['month2Value', `${dayjs().subtract(2, 'months').format('MMMM YYYY')}`],
	['month3Value', `${dayjs().subtract(3, 'months').format('MMMM YYYY')}`],
	['month4Value', `${dayjs().subtract(4, 'months').format('MMMM YYYY')}`],
	['month5Value', `${dayjs().subtract(5, 'months').format('MMMM YYYY')}`],
	['month6Value', `${dayjs().subtract(6, 'months').format('MMMM YYYY')}`]
]);
