import { RtxApiRoutes } from 'RtExports/routes';
import { UserAuditIndexRequest, UserAuditIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchUsersAudit = async (
	queryParams?: UserAuditIndexRequest
): Promise<FullResponse<UserAuditIndexResponse[]>> => {
	return handleGetRequest<UserAuditIndexResponse[], true>(
		RtxApiRoutes.UserAudit.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetUsersAudit = (queryParams?: UserAuditIndexRequest) => {
	return useQuery<FullResponse<UserAuditIndexResponse[]>, Error>(
		[`useGetUsersAudit`],
		() => fetchUsersAudit(queryParams)
	);
};
