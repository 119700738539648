import { MgiTaskTypes, TaskProfileResponse } from 'RtModels';
import moment from 'moment';
import { useMemo } from 'react';
import { Alert } from 'react-bootstrap';

interface ITaskWarningProps {
	profile: TaskProfileResponse;
}

export const TaskWarning = ({ profile }: ITaskWarningProps) => {
	switch (profile.taskTypeId) {
		case MgiTaskTypes.Search:
		case MgiTaskTypes.SearchQuantity: {
			return <SearchTaskWarning completedAt={profile.completedAt} />;
		}
		case MgiTaskTypes.Reserve:
		case MgiTaskTypes.ReserveQuantity: {
			return <ReserveTaskWarning completedAt={profile.completedAt} />;
		}
		default: {
			return <></>;
		}
	}
};

interface ITaskWarningChildProps {
	completedAt?: Date;
}

const SearchTaskWarning = ({ completedAt }: ITaskWarningChildProps) => {
	const variant = useMemo(() => {
		const minutesOld = moment().diff(moment(completedAt), 'minutes');

		return minutesOld < 5 ? 'warning' : 'danger';
	}, [completedAt]);

	return (
		<Alert variant={variant} className="mt-3">
			<div className="d-flex gap-2 align-items-center">
				<p className="m-0">
					Search Tasks place a temporary hold (approximately 5 minutes) on the
					returned Toll-Free Numbers. Unless Reserved these numbers will be
					automatically returned to the Spare/Available pool, and may be
					assigned to another RespOrg.
				</p>
			</div>
		</Alert>
	);
};

const ReserveTaskWarning = ({ completedAt }: ITaskWarningChildProps) => {
	const variant = useMemo(() => {
		const daysOld = moment().diff(moment(completedAt), 'days');

		return daysOld < 45 ? 'warning' : 'danger';
	}, [completedAt]);

	return (
		<Alert variant={variant} className="mt-3">
			<div className="d-flex gap-2 align-items-center">
				<p className="m-0">
					Message: Reserved Toll-Free Numbers are held for 45 days, and
					automatically returned to the Spare/Available pool when this time
					expires.
				</p>
			</div>
		</Alert>
	);
};
