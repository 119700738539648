import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	MarginSummaryIndexRequest,
	MarginSummaryIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchMarginSummary = async (
	queryParams?: MarginSummaryIndexRequest
): Promise<FullResponse<MarginSummaryIndexResponse[]>> => {
	return handleGetRequest<MarginSummaryIndexResponse[], true>(
		RtxSipApiRoutes.MarginSummary.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetMarginSummary = (
	queryParams?: MarginSummaryIndexRequest
) => {
	return useQuery<FullResponse<MarginSummaryIndexResponse[]>, Error>(
		[`useGetMarginSummary`, queryParams],
		() => fetchMarginSummary(queryParams),
		{ enabled: true }
	);
};
