/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NanpIndexRequest = {
    properties: {
        onlyResourceIds: {
            type: 'string',
        },
        omitResourceIds: {
            type: 'string',
        },
        npa: {
            type: 'Array',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};