/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RtCprLblDto = {
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        cprNodeTypeId: {
            type: 'CprNodeType',
            isRequired: true,
        },
        values: {
            type: 'Array',
            isRequired: true,
        },
    },
};