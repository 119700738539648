import { TollfreeEnhancedDetailIndexResponse } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getTollFreeEnhancedDetailColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/tollFreeEnhancedDetail';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface ITfEnhancedDetailsReportGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<TollfreeEnhancedDetailIndexResponse> | null;
	isLoading?: boolean;
}

export const TfEnhancedDetailsReportGrid = ({
	resource,
	isLoading
}: ITfEnhancedDetailsReportGridProps) => {
	const { data = [] } = useQuery<
		TollfreeEnhancedDetailIndexResponse[] | undefined,
		Error
	>([`useGetTollFreeEnhancedDetailReport`, resource], () => resource?.getAll());
	const columns = useMemo(() => getTollFreeEnhancedDetailColumns(), []);

	return (
		<DataGrid<TollfreeEnhancedDetailIndexResponse>
			pageName="rtVue_tollFreeEnhancedDetail"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
