import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	SipCallSimulatorProfileRequest,
	SipCallSimulatorResponseDto
} from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchCallSimulator = async (
	queryParams?: SipCallSimulatorProfileRequest
): Promise<SipCallSimulatorResponseDto> => {
	return handleGetRequest<SipCallSimulatorResponseDto>(
		RtxSipApiRoutes.SipCallSimulator.Profile,
		{
			queryParams
		}
	);
};

export const useGetCallSimulator = (
	queryParams?: SipCallSimulatorProfileRequest
) => {
	return useQuery<SipCallSimulatorResponseDto, Error>(
		[`useGetCallSimulator`],
		() => fetchCallSimulator(queryParams)
	);
};
