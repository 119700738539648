/* eslint-disable max-classes-per-file */

import * as React from 'react';
import { Card, Nav, Button } from 'react-bootstrap';
import { FormErrors } from 'RtUi/components/form/FormErrors';

interface IFormWizardProps {
	currentStep: number;
	onChange: (newStep: number) => void;
	isSubmitting?: boolean;
	error?: any;
	disableWizardActions?: boolean;
	disableContinueAction?: boolean;
	hideWizardActions?: boolean;
}

export interface IFormWizardStepProps {
	step: number;
	header: string;
	disableBody?: boolean;
	onSubmit?: () => Promise<any | boolean> | void;
	submitText?: string;
}

export class FormWizardStep extends React.PureComponent<
	React.PropsWithChildren<IFormWizardStepProps>
> {
	public render() {
		const wizardStepClassNames: string[] = [];

		if (!this.props.disableBody) {
			wizardStepClassNames.push('card-body');
		}

		return (
			<section className={wizardStepClassNames.join(' ')}>
				{this.props.children}
			</section>
		);
	}
}

export class FormWizard extends React.PureComponent<
	React.PropsWithChildren<IFormWizardProps>
> {
	public getCurrentAndAllSteps(): [
		FormWizardStep | undefined,
		FormWizardStep[]
	] {
		const steps: FormWizardStep[] = [];
		let currentWizardStep: FormWizardStep | undefined;

		React.Children.map(this.props.children, (child: any) => {
			const formWizardStep = child as FormWizardStep;

			if (formWizardStep) {
				steps.push(formWizardStep);

				if (formWizardStep.props.step === this.props.currentStep) {
					currentWizardStep = child;
				}
			}
		});

		return [currentWizardStep, steps];
	}

	public renderWizardSteps(nextStepText = 'Continue') {
		const {
			currentStep,
			isSubmitting,
			disableWizardActions,
			disableContinueAction
		} = this.props;
		const previousStep = currentStep - 1;
		const wizardStepClassNames = [
			'd-flex',
			'justify-content-around',
			'px-md-5'
		];

		return (
			<Card.Body className={wizardStepClassNames.join(' ')}>
				{currentStep !== 1 && (
					<Button
						size="lg"
						variant="light"
						className="flex-fill mx-md-5 mx-2"
						type="button"
						style={{ maxWidth: 300 }}
						disabled={isSubmitting || disableWizardActions}
						onClick={() => this.props.onChange(previousStep)}
					>
						{/* <i className="fas fa-fw fa-angle-left" /> */}
						<span>&nbsp;Back</span>
					</Button>
				)}
				<Button
					type="submit"
					size="lg"
					variant="primary"
					className="flex-fill mx-md-5 mx-2"
					disabled={
						isSubmitting || disableWizardActions || disableContinueAction
					}
					style={{ maxWidth: 300 }}
				>
					<span>{nextStepText}&nbsp;</span>
					{!isSubmitting && <i className="fas fa-fw fa-long-arrow-alt-right" />}
					{isSubmitting && <i className="fas fa-fw fa-cog fa-spin" />}
				</Button>
			</Card.Body>
		);
	}

	public async onSubmit(
		evt: React.FormEvent<HTMLFormElement>,
		currentWizardStep: FormWizardStep
	) {
		evt.preventDefault();
		evt.stopPropagation();

		const [, wizardSteps] = this.getCurrentAndAllSteps();
		const { onSubmit } = currentWizardStep.props;
		const goToNextStep = () => {
			const indexOfCurrentStep = wizardSteps.indexOf(currentWizardStep);
			const nextStep = wizardSteps[indexOfCurrentStep + 1];

			if (nextStep) {
				this.props.onChange(nextStep.props.step);
			}
		};

		if (onSubmit) {
			const onSubmitReturn = onSubmit();

			if (onSubmitReturn && 'then' in onSubmitReturn) {
				onSubmitReturn.then((possibleBoolean) => {
					if (typeof possibleBoolean === 'boolean') {
						//Do not go to next step if false is sent
						if (possibleBoolean === true) {
							goToNextStep();
						}
					} else {
						goToNextStep();
					}
				});
				return;
			}
		} else {
			goToNextStep();
		}
	}

	public render() {
		const [currentWizardStep, wizardSteps] = this.getCurrentAndAllSteps();
		const activePage = React.Children.toArray(this.props.children)[
			this.props.currentStep - 1
		];

		return (
			<Card>
				{!this.props.hideWizardActions && (
					<Card.Header className="d-none d-md-block">
						<Nav className="nav-steps d-md-flex justify-content-between">
							{wizardSteps.map((wizardStep) => (
								<Nav.Item key={wizardStep.props.step}>
									<Nav.Link
										active={this.props.currentStep === wizardStep.props.step}
									>
										{wizardStep.props.step}.&nbsp;
										{wizardStep.props.header}
									</Nav.Link>
								</Nav.Item>
							))}
							{/* Add Spacing for 2 Steps */}
							{wizardSteps.length === 2 && <span className="px-5">&nbsp;</span>}
						</Nav>
					</Card.Header>
				)}
				{currentWizardStep && (
					<form
						autoComplete="off"
						onSubmit={(evt) => this.onSubmit(evt, currentWizardStep)}
					>
						{activePage}
						<FormErrors error={this.props.error} />
						{!this.props.hideWizardActions &&
							this.renderWizardSteps(currentWizardStep.props.submitText)}
					</form>
				)}
			</Card>
		);
	}
}
