/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	RatePlanDefaultCreateRequest,
	RatePlanDefaultProfileResponse,
	RatePlanDefaultProfileUrlRequest,
	RatePlanDefaultUpdateRequest,
	RatePlanIndexRequest,
	RatePlanIndexResponse
} from 'RtModels';
import {
	handleDeleteRequest,
	handleGetRequest,
	handlePostRequest,
	handlePutRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchRatePlanDefaults = async (
	urlParams: RatePlanDefaultProfileUrlRequest
): Promise<RatePlanDefaultProfileResponse> => {
	return handleGetRequest(RtCommonApiRoutes.RatePlanDefaults.Profile, {
		urlParams
	});
};

export const useGetRatePlanDefaults = (
	urlParams: RatePlanDefaultProfileUrlRequest
) => {
	return useQuery<RatePlanDefaultProfileResponse, Error>(
		['getRatePlanDefaults', urlParams],
		() => {
			return fetchRatePlanDefaults(urlParams);
		}
	);
};

const fetchRatePlans = async <IncludeFullResponse extends boolean = false>(
	queryParams?: RatePlanIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<RatePlanIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(RtCommonApiRoutes.RatePlan.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetRatePlans = <IncludeFullResponse extends boolean = false>(
	queryParams?: RatePlanIndexRequest,
	includeFullResponse?: IncludeFullResponse
) => {
	return useQuery<
		RequestResponse<RatePlanIndexResponse[], IncludeFullResponse>,
		Error
	>(['getRatePlanDefaults', queryParams], () => {
		return fetchRatePlans(queryParams, includeFullResponse);
	});
};

export const postRatePlanDefault = async (
	body: RatePlanDefaultCreateRequest
) => {
	return handlePostRequest(RtCommonApiRoutes.RatePlanDefaults.Create, body);
};

interface IPutRatePlanDefaults {
	urlParams: RatePlanDefaultProfileUrlRequest;
	bodyParams: RatePlanDefaultUpdateRequest;
}

export const putRatePlanDefaults = async (payload: IPutRatePlanDefaults) => {
	const { urlParams, bodyParams } = payload;

	return handlePutRequest(
		RtCommonApiRoutes.RatePlanDefaults.Update,
		bodyParams,
		{
			urlParams
		}
	);
};

export const deleteRatePlanDefaults = async (
	urlParams: RatePlanDefaultProfileUrlRequest
) => {
	return handleDeleteRequest(RtCommonApiRoutes.RatePlanDefaults.Delete, {
		urlParams
	});
};
