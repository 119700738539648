import {
	SpreadsheetParser,
	ISpreadsheetParserHeaderMatch
} from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParser';
import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';

/**
 * Base parser for Billing and Switch
 */
export abstract class DidInventorySpreadsheetParser<
	InventoryRecordType
> extends SpreadsheetParser {
	public NumberColumn = new SpreadsheetParserColumn('Number', [
		'number',
		'tfn',
		'DID'
	]).setIsRequired(true);
	public LabelColumn = new SpreadsheetParserColumn('Label', [
		'label',
		'summary',
		'description'
	]);
	public StartColumn = new SpreadsheetParserColumn('Start Date', [
		'start',
		'begin'
	]);
	public EndColumn = new SpreadsheetParserColumn('End Date', ['end', 'cancel']);
	public AccountColumn = new SpreadsheetParserColumn('Account Number', [
		'account',
		'customer',
		'cust',
		'account Num',
		'customer Number',
		'cust Num'
	]);
	public RecurringColumn = new SpreadsheetParserColumn('Recurring Amount', [
		'mrc',
		'recurring amount',
		'recurring charge'
	]);
	public NonRecurringColumn = new SpreadsheetParserColumn(
		'Non-Recurring Amount',
		[
			'nrc',
			'nonRecurring Amount',
			'non-Recurring Amount',
			'nonRecurring Charge',
			'non-Recurring Charge'
		]
	);

	public getInventoryRecords(): InventoryRecordType[] {
		this.removeAllParserColumns();
		this.addParserColumn(...this.getParserColumns());

		return this.internalGetInventoryRecords();
	}

	/**
	 * Finds the best headerMatch and prints out any found errors
	 */
	protected getBestHeaderMatch(): ISpreadsheetParserHeaderMatch | undefined {
		const headerMatches = this.findPossibleHeaderMatches();

		if (headerMatches.length <= 0) {
			const requiredColumnNamesStr = this.columns
				.filter((c) => c.isRequired())
				.map((c) => c.getColumnName())
				.join(', ');

			this.emit(
				'error',
				`Could not find required columns: ${requiredColumnNamesStr}`
			);

			return undefined;
		}

		const headerMatch: ISpreadsheetParserHeaderMatch = headerMatches[0];

		if (!headerMatch.allRequiredColumnsFound) {
			const missingRequiredColumnNamesStr = headerMatch.missingRequiredColumns
				.map((c) => c.getColumnName())
				.join(', ');

			this.emit(
				'error',
				`Could not find the following required columns: ${missingRequiredColumnNamesStr}`
			);

			return undefined;
		}

		return headerMatch;
	}

	//Columns used for actual parsing
	protected abstract getParserColumns(): SpreadsheetParserColumn[];
	protected abstract internalGetInventoryRecords(): InventoryRecordType[];
}
