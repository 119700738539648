import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export interface IPlayToneOption {
	value: string;
}

interface IVariableSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<IPlayToneOption, isMulti> {}

const PlayToneOptions: IPlayToneOption[] = [{ value: 'ring' }];

export class PlayToneSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	IPlayToneOption,
	isMulti,
	IVariableSelectProps<isMulti>
> {
	public static isPlayTone(possibleVariable: string) {
		return PlayToneOptions.some(
			(variableOption) => variableOption.value === possibleVariable
		);
	}

	public resourceClass = undefined;
	public state: ISelectFormControlState<IPlayToneOption> = {
		formLabel: 'Play Tone',
		valueKey: 'value',
		labelKey: 'value'
	};

	public componentDidMount() {
		this.resource = new ArrayResource<IPlayToneOption>('value', [
			...PlayToneOptions
		]);

		super.componentDidMount();
	}
}
