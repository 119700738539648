import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { RateSheetRouter } from 'RtUi/app/rtLco/RateSheets/RateSheet.router';
import { RateSheetGrid } from 'RtUi/app/rtLco/RateSheets/lib/grids/RateSheetGrid';
import { CreateRateSheetForm } from 'RtUi/app/rtLco/RateSheets/lib/forms/CreateRateSheetForm';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { CprRateSheetProfileResponse } from 'RtModels';

interface IInitialResourceParams {
	isDeleted: number;
}

interface IRateSheetGeneratorIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@RateSheetRouter.getIndexRtUiController()
export class RateSheetIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IRateSheetGeneratorIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		isDeleted: 0
	};

	public state: IRateSheetGeneratorIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const resourceParams: any = {};

		if (this.state.isDeleted >= 0) {
			resourceParams.isDeleted = this.state.isDeleted;
		}

		return resourceParams;
	}

	public onCreate(profile: CprRateSheetProfileResponse) {
		const profilePath = RateSheetRouter.getProfileRoute(profile.cprRateSheetId);

		this.goToPath(profilePath);
	}

	public render() {
		return (
			<TabbedLayout
				router={RateSheetRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(evt) => this.submitAndGoToResults(evt)}>
						<BooleanRadioFormControl
							label="Show Deleted"
							showBothOption
							onChange={(isDeleted) => this.setState({ isDeleted })}
							value={this.state.isDeleted}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<RateSheetGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
				<TabbedLayoutTab header="Create Cost Rate" isAction>
					<CreateRateSheetForm onCreate={(profile) => this.onCreate(profile)} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
