/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrCarrierRateUpdateRequest = {
    properties: {
        rateSheetTypeId: {
            type: 'LcrRateSheetType',
            isRequired: true,
        },
        rates: {
            type: 'Array',
            isRequired: true,
        },
    },
};