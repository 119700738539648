import { FC, ReactNode } from 'react';
import { CallflowDirectionId, CallTypes } from 'RtModels';
import { IFormControlProps } from 'RtUi/components/form/FormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';

type DirectionFromControlProps = IFormControlProps<
	CallflowDirectionId,
	false
> & {
	callType?: CallTypes;
};

export const DirectionFormControl: FC<
	React.PropsWithChildren<DirectionFromControlProps>
> = (props) => {
	const ingressCustomers = [
		CallTypes.Iddd,
		CallTypes.Oneplus,
		CallTypes.Tfterm,
		CallTypes.Local
	];

	let ingressLabel: ReactNode = 'Ingress';
	let egressLabel: ReactNode = 'Egress';

	if (props.callType) {
		const isIngressCustomer = ingressCustomers.includes(props.callType);
		const ingressAppend = isIngressCustomer ? 'customer' : 'vendor';
		const egressAppend = !isIngressCustomer ? 'customer' : 'vendor';

		ingressLabel = (
			<span>
				<span>Ingress</span>
				<span className="text-muted">&nbsp;({ingressAppend})</span>
			</span>
		);

		egressLabel = (
			<span>
				<span>Egress</span>
				<span className="text-muted">&nbsp;({egressAppend})</span>
			</span>
		);
	}

	return (
		<RadioFormControl<number>
			label="Direction"
			{...props}
			options={[
				{
					value: CallflowDirectionId.Inbound,
					label: ingressLabel
				},
				{
					value: CallflowDirectionId.Outbound,
					label: egressLabel
				}
			]}
		/>
	);
};
