import { LcrScenarioIndexResponse } from 'RtModels';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';
import { LcrScenariosDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LcrScenariosDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';

export const getLcrScenariosColumns = (): Array<
	DataGridColumn<LcrScenarioIndexResponse>
> => [
	LcrScenariosDataGridColumn({
		header: 'LCR Scenario',
		accessorKey: 'lcrScenarioId'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	BooleanDataGridColumn({
		header: 'Allow As Default Only',
		accessorKey: 'allowAsDefaultOnly'
	})
];
