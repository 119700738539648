import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	ScenarioIndexRequest,
	ScenarioIndexResponse,
	ScenarioProfileRequest,
	ScenarioProfileResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchSipScenario = async (
	urlParams: ScenarioProfileRequest
): Promise<ScenarioProfileResponse | undefined> => {
	return handleGetRequest(RtxSipApiRoutes.Scenarios.Profile, {
		urlParams
	});
};

export const useGetSipScenario = (scenarioId: number) => {
	return useQuery<ScenarioProfileResponse | undefined, Error>(
		[`getSipScenario`, scenarioId],
		() => fetchSipScenario({ scenarioId }),
		{ enabled: Boolean(scenarioId) }
	);
};

const fetchSipScenarios = async (queryParams?: ScenarioIndexRequest) => {
	return handleGetRequest<ScenarioIndexResponse[], true>(
		RtxSipApiRoutes.Scenarios.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetSipScenarios = (params?: ScenarioIndexRequest) => {
	return useQuery<FullResponse<ScenarioIndexResponse[]>, Error>(
		['getSipScenarios', params],
		() => fetchSipScenarios(params)
	);
};
