/* eslint-disable max-classes-per-file */
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { RtVueApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { CallTypeFilterIndexResponse, CallTypes } from 'RtModels';

export interface ICallTypeSelectOption {
	callTypeId: CallTypes;
}

export class CallTypesResource extends ArrayResource<ICallTypeSelectOption> {
	constructor() {
		super('callTypeId');
	}

	public async getAll(): Promise<ICallTypeSelectOption[]> {
		const res = await this.fetchWithRoute<CallTypeFilterIndexResponse>(
			RtVueApiRoutes.CallTypeFilter.Index
		);

		return res.data;
	}
}

interface ICallTypesSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<ICallTypeSelectOption, isMulti> {}

export class CallTypesSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	ICallTypeSelectOption,
	isMulti,
	ICallTypesSelectProps<isMulti>
> {
	public resourceClass = CallTypesResource;
	public state: ISelectFormControlState<ICallTypeSelectOption> = {
		formLabel: 'Call Types',
		valueKey: 'callTypeId',
		labelKey: 'callTypeId'
	};
}
