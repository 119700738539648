import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { ButtonDataGridColumColumn } from 'RtUi/components/data/DataGrid/columns/ButtonDataGridColumn';
import { FallbackHeaderDataGridColumnConfiguration } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface DeleteDataGridColumnConfiguration<T>
	extends FallbackHeaderDataGridColumnConfiguration<T> {
	onClick: (row: T) => void;
	overlay?: string;
}

export const DeleteDataGridColumn = <T = any,>({
	onClick,
	id = 'delete',
	header = '',
	size = 50,
	overlay = 'Delete',
	...config
}: DeleteDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return ButtonDataGridColumColumn({
		id,
		header,
		icon: 'fa-trash',
		overlay,
		size,
		...config,
		onClick
	});
};
