//import * as React from 'react';
import { BlockTypeIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { BlockTypeResource } from '../resources/BlockTypeResource';

interface IBlockTypeSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<BlockTypeIndexResponse, IsMulti> {
	placeholder?: string;
}
interface IBlockTypeSelectState
	extends ISelectFormControlState<BlockTypeIndexResponse> {}

export class BlockTypeSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	BlockTypeIndexResponse,
	IsMulti,
	IBlockTypeSelectProps<IsMulti>,
	IBlockTypeSelectState
> {
	public resourceClass = BlockTypeResource;
	public state: IBlockTypeSelectState = {
		formLabel: 'Block Type',
		valueKey: 'blockTypeId',
		labelKey: 'label',
		placeholder: this.props.placeholder
	};
}
