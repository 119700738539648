import { LcrScenarioIndexResponse, LcrScenarioProfileResponse } from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export interface ILcrScenarioIndexResponse extends LcrScenarioIndexResponse {}
export interface ILcrScenarioProfileResponse
	extends LcrScenarioProfileResponse {}

export class LcrScenariosResource extends HttpResource<
	ILcrScenarioIndexResponse,
	ILcrScenarioProfileResponse
> {
	constructor() {
		super(Rt800ApiRoutes.LcrScenarios);
	}
}
