/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NumberCreateResponse = {
    properties: {
        recordsProcessed: {
            type: 'number',
            isRequired: true,
        },
        recordsAdded: {
            type: 'number',
            isRequired: true,
        },
        recordsUpdated: {
            type: 'number',
            isRequired: true,
        },
        recordsDeleted: {
            type: 'number',
            isRequired: true,
        },
    },
};