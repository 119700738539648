import {
	CountryIndexResponse,
	RateCenterIndexResponse,
	RouteDepthIndexRequest
} from 'RtModels';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { RouteDepthGrid } from 'RtUi/app/rtSip/RouteDepth/lib/grid/RouteDepthGrid';
import { RouteDepthRouter } from 'RtUi/app/rtSip/RouteDepth/RouteDepth.router';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IRouteDepthIndexContainerState
	extends ISearchResultsContainerState<RouteDepthIndexRequest> {
	countries?: CountryIndexResponse[];
	rateCenters?: RateCenterIndexResponse[];
	rateKey?: string;
	serviceQuality?: Array<ISimpleSelectFormControlOption<number>>;
	allInternational?: number;
}
@RouteDepthRouter.getBusinessOpsIndexRtUiController()
export class RouteDepthIndexContainer extends SearchResultsContainer<
	{},
	{},
	IRouteDepthIndexContainerState,
	RouteDepthIndexRequest
> {
	public state: IRouteDepthIndexContainerState = {
		activeTab: this.tabs.Search,
		resourceParams: {}
	};

	public Tabs = RouteDepthRouter.getProfileTabs();

	public initParams = {};

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const {
			countries,
			rateCenters,
			rateKey,
			serviceQuality,
			allInternational
		} = this.state;
		const params: RouteDepthIndexRequest = {};

		const countriesSet = countries !== undefined && countries.length > 0;
		const rateCentersSet = rateCenters !== undefined && rateCenters.length > 0;
		const rateKeySet = rateKey !== undefined && rateKey !== '';
		const serviceQualitySet =
			serviceQuality !== undefined && serviceQuality.length > 0;
		const allInternationalSet = allInternational === 1;

		if (countriesSet && !allInternationalSet) {
			params.iso3166Alpha3s = countries.map((country) => country.iso3166Alpha3);
		}

		if (rateCentersSet) {
			params.rateCenterIds = rateCenters.map(
				(rateCenter) => rateCenter.rateCenterId
			);
		}

		if (rateKeySet) {
			params.rateKey = rateKey;
		}

		if (serviceQualitySet) {
			params.serviceQualityIds = serviceQuality.map((sq) => sq.value);
		}

		if (allInternationalSet) {
			params.allInternational = allInternational;
		}

		return params;
	}

	public render() {
		return (
			<TabbedLayout
				activeTab={this.state.activeTab}
				router={RouteDepthRouter}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<CountrySelect<true>
							isMulti
							required={
								!this.state.rateCenters &&
								!this.state.rateKey &&
								!this.state.allInternational
							}
							label="Countries"
							value={this.state.countries}
							onChange={(countries) => this.setState({ countries })}
						/>
						<RateCenterSelect<true>
							required={
								!this.state.countries &&
								!this.state.rateKey &&
								!this.state.allInternational
							}
							label="Rate Center"
							value={this.state.rateCenters}
							onChange={(rateCenters) => this.setState({ rateCenters })}
							multi
						/>
						<InputFormControl
							required={
								!this.state.countries &&
								!this.state.rateCenters &&
								!this.state.allInternational
							}
							label="Rate Key"
							displayMode={false}
							onChange={(rateKey) => this.setState({ rateKey })}
							value={this.state.rateKey}
						/>
						<SimpleSelectFormControl<number, true>
							multi
							label="Service Quality"
							clearable={true}
							onChange={(serviceQuality) => this.setState({ serviceQuality })}
							value={this.state.serviceQuality}
							options={[...Array(9).keys()].map((i) => ({
								value: i + 1,
								label: `SQ${i + 1}`
							}))}
						/>
						<CheckboxFormControl
							label="All International"
							value={Boolean(this.state.allInternational)}
							onChange={(allInternational) =>
								this.setState({ allInternational: Number(allInternational) })
							}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					header={this.tabs.Results}
					isDisabled={
						!this.state.countries &&
						!this.state.rateCenters &&
						!this.state.rateKey
					}
				>
					<RouteDepthGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
