import { UrlPartition } from 'RtModels';
import { PartitionUrlEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionUrlEditor';
import { useGetPartitionUrls } from 'RtUi/app/rtAdmin/Partitions/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getPartitionUrlColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/partitions';
import { Aside } from 'RtUi/components/ui/Aside';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Item } from 'react-contexify';

interface IPartitionUrlsDataGridProps {
	partitionId: number;
	autoFocusFilter?: boolean;
}

export const PartitionUrlsDataGrid = ({
	partitionId
}: IPartitionUrlsDataGridProps) => {
	const [asideIsOpen, setAsideIsOpen] = useState<boolean>(false);
	const [selectedRecord, setSelectedRecord] = useState<UrlPartition>();
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetPartitionUrls({ partitionId });
	const columns = useMemo(() => getPartitionUrlColumns(), []);

	return (
		<>
			<DataGrid<UrlPartition>
				data={data?.data}
				loading={isLoading}
				pageName="rtAdmin-partitionUrls"
				menuLinks={(record) => (
					<Item
						onClick={() => {
							setSelectedRecord(record);
							setAsideIsOpen(true);
						}}
					>
						<span className="d-flex justify-content-start align-items-center">
							<i className="fas fa-fw fa-pen" />
							<span>Edit</span>
						</span>
					</Item>
				)}
				columns={columns}
			/>
			<Aside isOpen={asideIsOpen}>
				{selectedRecord && (
					<React.Fragment key={selectedRecord.urlPartitionId}>
						<header className="d-flex justify-content-between align-items-center mb-3">
							<h5 className="mb-0">
								<b>Partition URL - {selectedRecord.url}</b>
							</h5>
							<Button
								type="button"
								variant="light"
								onClick={() => setAsideIsOpen(false)}
							>
								<i className="fas fa-fw fa-times" />
							</Button>
						</header>
						<PartitionUrlEditor
							disablePadding
							partitionId={partitionId}
							editMode={selectedRecord}
							onCancel={() => {
								setAsideIsOpen(false);
								setSelectedRecord(undefined);
							}}
							onUpdate={() => {
								setAsideIsOpen(false);
								setSelectedRecord(undefined);
								refetch();
							}}
						/>
					</React.Fragment>
				)}
			</Aside>
		</>
	);
};
