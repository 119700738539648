import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { RoutingRuleIndexRequest, RoutingRuleIndexResponse } from 'RtModels';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';
import { RoutingRuleRouter } from 'RtUi/app/rtSip/RoutingRules/RoutingRule.router';
import { RoutingRuleDataGrid } from 'RtUi/app/rtSip/RoutingRules/lib/grids/RoutingRuleDataGrid';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useNavigate } from 'react-router-dom';
import { RoutingRuleCreateForm } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleCreateForm';
import { useState } from 'react';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { Controller, useForm } from 'react-hook-form';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';

export const RoutingRuleIndexContainer = (): JSX.Element => {
	const navigate = useNavigate();
	const [resourceParams, setResourceParams] = useState<RoutingRuleIndexRequest>(
		{
			isActive: 1
		}
	);

	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true },
		Create: { header: 'Create Routing Rule', isAction: true }
	});

	const goToProfile = (id: number) => {
		const profilePath = RoutingRuleRouter.getProfileRoute(id);
		navigate(profilePath);
	};

	const { control, handleSubmit } = useForm<RoutingRuleIndexRequest>({
		defaultValues: resourceParams
	});

	const handleSubmitForm = (data: RoutingRuleIndexRequest) => {
		const { isActive, label } = data;
		const params: RoutingRuleIndexRequest = {};

		if (label) {
			params.label = label;
		}

		if (isActive !== undefined && isActive !== -1) {
			params.isActive = isActive;
		}

		setResourceParams(params);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout<RoutingRuleIndexResponse>
			activeTab={activeTab.header}
			onTabChange={(tab) => setActiveTab(tab)}
			router={RoutingRuleRouter}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm onSubmit={handleSubmit(handleSubmitForm)}>
					<Controller
						control={control}
						name="label"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Summary"
								value={value}
								onChange={onChange}
							/>
						)}
					/>
					<Controller
						control={control}
						name="isActive"
						render={({ field: { onChange, value } }) => (
							<IsActiveRadioFormControl value={value} onChange={onChange} />
						)}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<TwoFADisabledWarning />
				<RoutingRuleDataGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Create}>
				<RoutingRuleCreateForm
					onCreate={(profile) => goToProfile(profile.routingRuleId)}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

RoutingRuleRouter.setIndexRtUiFunctionalComponent(RoutingRuleIndexContainer, {
	groupName: 'Network Ops',
	orderPriority: 5
});
