import { LcoSummaryIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { MinutesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MinutesDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';

export const getLcoExportColumns = (): Array<
	DataGridColumn<LcoSummaryIndexResponse>
> => [
	//cSpell:disable
	DefaultDataGridColumn({
		accessorKey: 'aninpanxx',
		header: 'aninpanxx'
	}),
	DefaultDataGridColumn({
		accessorKey: 'lrnnpanxx',
		header: 'lrnnpanxx'
	}),
	IntegerDataGridColumn({
		accessorKey: 'interconnects',
		header: 'interconnects'
	}),
	IntegerDataGridColumn({
		accessorKey: 'interminutes',
		header: 'interminutes'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'intercharges',
		header: 'intercharges'
	}),
	IntegerDataGridColumn({
		accessorKey: 'intraconnects',
		header: 'intraconnects'
	}),
	IntegerDataGridColumn({
		accessorKey: 'intraminutes',
		header: 'intraminutes'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'intracharges',
		header: 'intracharges'
	}),
	IntegerDataGridColumn({
		accessorKey: 'indetconnects',
		header: 'indetconnects'
	}),
	IntegerDataGridColumn({
		accessorKey: 'indetminutes',
		header: 'indetminutes'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'indetcharges',
		header: 'indetcharges'
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalconnects',
		header: 'totalconnects'
	}),
	MinutesDataGridColumn({
		accessorKey: 'totalminutes',
		header: 'totalminutes'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'totalcharges',
		header: 'totalcharges'
	})
	//cSpell:enable
];
