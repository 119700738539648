import { merge } from 'lodash-es';
import { memo, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import {
	DefaultSipAgentConfig,
	SipAgentContactTarget,
	SipAgentContactUriFormat,
	SipAgentContactValue,
	SipAgentDialFormat,
	SipAgentMedSource,
	SipAgentMedTarget,
	SipAgentMedValue,
	SubscriptionIndexResponse,
	SwitchIndexResponse
} from 'RtModels';
import { GlobalBlockSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/GlobalBlockSelect';
import { PartitionBlockSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/PartitionBlockSelect';
import { SipAgentContactTargetSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentContactTargetSelect';
import { SipAgentContactUriFormatSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentContactUriFormatSelect';
import { SipAgentContactValueSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentContactValueSelect';
import { SipAgentDialFormatSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentDialFormatSelect';
import { SipAgentMedSourceSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentMedSourceSelect';
import { SipAgentMedTargetSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentMedTargetSelect';
import { SipAgentMedValueSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentMedValueSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { SwitchesSelect } from 'RtUi/app/AccountManagement/Switches/lib/controls/SwitchesSelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';

declare type SipAgentConfigPropertyKey = keyof DefaultSipAgentConfig;
declare type SipAgentConfigPropertyValue =
	DefaultSipAgentConfig[SipAgentConfigPropertyKey];

interface ISipAgentConfigPropertyRendererProps {
	propKey: SipAgentConfigPropertyKey;
	value: SipAgentConfigPropertyValue;
	onChange: (value: SipAgentConfigPropertyValue) => void;
	displayMode?: boolean;
}

const SipAgentConfigPropertyRenderer = memo(
	({
		propKey,
		value,
		onChange,
		displayMode = false
	}: ISipAgentConfigPropertyRendererProps): JSX.Element => {
		const [medDefaultSubId, setMedDefaultSubId] =
			useState<SubscriptionIndexResponse>();
		const [medNotFoundSubId, setMedNotFoundSubId] =
			useState<SubscriptionIndexResponse>();
		const [switchIdOmit, setSwitchIdOmit] = useState<SwitchIndexResponse[]>();
		const [switchIdOnly, setSwitchIdOnly] = useState<SwitchIndexResponse[]>();
		const [medValues, setMedValues] = useState<SipAgentMedValue[]>();
		const [medSources, setMedSources] = useState<SipAgentMedSource[]>();
		const [medTarget, setMedTarget] = useState<SipAgentMedTarget>();
		const [contactUriFormat, setContactUriFormat] =
			useState<SipAgentContactUriFormat>();
		const [globalBlockOmit, setGlobalBlockOmit] = useState<number[]>();
		const [globalBlockOnly, setGlobalBlockOnly] = useState<number[]>();
		const [partitionBlockOmit, setPartitionBlockOmit] = useState<number[]>();
		const [partitionBlockOnly, setPartitionBlockOnly] = useState<number[]>();
		const [contactTarget, setContactTarget] = useState<SipAgentContactTarget>();
		const [contactValue, setContactValue] = useState<SipAgentContactValue>();
		const [nanpDialingFormat, setNanpDialingFormat] =
			useState<SipAgentDialFormat>();
		const [idddDialingFormat, setIdddDialingFormat] =
			useState<SipAgentDialFormat>();

		switch (propKey) {
			case 'switchIdGwOnly':
				return (
					<BooleanRadioFormControl
						label="Restrict Switch matching to match Gateway"
						value={Number(value)}
						onChange={(val) => onChange(Boolean(val))}
						displayMode={displayMode}
					/>
				);
			case 'switchIdOmit':
				return (
					<SwitchesSelect
						label="Omit the following Switch(es)"
						value={switchIdOmit}
						onChange={(val) => {
							setSwitchIdOmit(val);
							onChange(val ? val.map((v) => v.switchId) : []);
						}}
						initialOptionId={(value as number[]).map((val) => val.toString())}
						multi={true}
						displayMode={displayMode}
					/>
				);
			case 'switchIdOnly':
				return (
					<SwitchesSelect
						label="Only use the following Switch(es)"
						value={switchIdOnly}
						onChange={(val) => {
							setSwitchIdOnly(val);
							onChange(val ? val.map((v) => v.switchId) : []);
						}}
						initialOptionId={(value as number[]).map((val) => val.toString())}
						multi={true}
						displayMode={displayMode}
					/>
				);
			case 'sendTrying':
				return (
					<BooleanRadioFormControl
						label="Send Trying"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'identityRequired':
				return (
					<BooleanRadioFormControl
						label="STIR/Shaken Identity Required"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'stirShaken':
				return (
					<BooleanRadioFormControl
						label="Validate STIR/Shaken Identity (local)"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'stirShakenCertificate':
				return (
					<BooleanRadioFormControl
						label="Validate STIR/Shaken Certificate (full)"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'stirShakenCertificateMs':
				return (
					<InputFormControl
						label="STIR/Shaken Certificate Validation Timeout (ms)"
						type="number"
						min={0}
						value={String(value)}
						onChange={(val) => onChange(Number(val))}
						displayMode={displayMode}
					/>
				);
			case 'nanpLrnTrustNpdi':
				return (
					<BooleanRadioFormControl
						label="Trust LRN (NPDI) in SIP message."
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'nanpLrnLookup':
				return (
					<BooleanRadioFormControl
						label="Perform LRN Lookup (if necessary)"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'nanpLrnTimeoutMs':
				return (
					<InputFormControl
						label="LRN Lookup Timeout (ms)"
						type="number"
						min={0}
						value={String(value)}
						onChange={(val) => onChange(Number(val))}
						displayMode={displayMode}
					/>
				);
			case 'doMediation':
				return (
					<BooleanRadioFormControl
						label="Do Mediation (Identify Customer)"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'medDefaultSubId':
				return (
					<SubscriptionSelect
						label="Mediation Default Subscription"
						value={medDefaultSubId}
						onChange={(val) => {
							setMedDefaultSubId(val);
							onChange(val ? val.subscriptionId : null);
						}}
						initialOptionId={value ? Number(value) : undefined}
						displayMode={displayMode}
					/>
				);
			case 'medNotFoundSubId':
				return (
					<SubscriptionSelect
						label="Mediation Not Found Subscription"
						value={medNotFoundSubId}
						onChange={(val) => {
							setMedNotFoundSubId(val);
							onChange(val ? val.subscriptionId : null);
						}}
						initialOptionId={value ? Number(value) : undefined}
						displayMode={displayMode}
					/>
				);
			case 'medValues':
				return (
					<SipAgentMedValueSelect<true>
						multi
						label="Mediation Values"
						value={medValues}
						onChange={(val) => {
							setMedValues(val);
							onChange(val ?? []);
						}}
						initialOptionId={(value as number[]).map((val) => val.toString())}
						displayMode={displayMode}
					/>
				);
			case 'medSources':
				return (
					<SipAgentMedSourceSelect<true>
						multi
						label="Mediation Sources"
						value={medSources}
						onChange={(val) => {
							setMedSources(val);
							onChange(val ?? []);
						}}
						initialOptionId={(value as number[]).map((val) => val.toString())}
						displayMode={displayMode}
					/>
				);
			case 'medTarget':
				return (
					<SipAgentMedTargetSelect
						label="Mediation Target"
						value={medTarget}
						onChange={(val) => {
							setMedTarget(val);
							onChange(val ?? null);
						}}
						initialOptionId={value?.toString()}
						displayMode={displayMode}
					/>
				);
			case 'globalBlock':
				return (
					<BooleanRadioFormControl
						label="Perform Global Block Checks"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'globalBlockOmit':
				return (
					<GlobalBlockSelect<true>
						multi
						label="Omit Global Block List(s)"
						value={globalBlockOmit}
						onChange={(val) => {
							setGlobalBlockOmit(val);
							onChange(val ?? []);
						}}
						initialOptionId={(value as number[]).map((val) => val.toString())}
						displayMode={displayMode}
					/>
				);
			case 'globalBlockOnly':
				return (
					<GlobalBlockSelect<true>
						multi
						label="Only Check Global Block List(s)"
						value={globalBlockOnly}
						onChange={(val) => {
							setGlobalBlockOnly(val);
							onChange(val ?? []);
						}}
						initialOptionId={(value as number[]).map((val) => val.toString())}
						displayMode={displayMode}
					/>
				);
			case 'partitionBlock':
				return (
					<BooleanRadioFormControl
						label="Perform Partition Block Checks"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'partitionBlockOmit':
				return (
					<PartitionBlockSelect<true>
						multi
						label="Omit Partition Block List(s)"
						value={partitionBlockOmit}
						onChange={(val) => {
							setPartitionBlockOmit(val);
							onChange(val ?? []);
						}}
						initialOptionId={(value as number[]).map((val) => val.toString())}
						displayMode={displayMode}
					/>
				);
			case 'partitionBlockOnly':
				return (
					<PartitionBlockSelect<true>
						multi
						label="Only Check Partition Block List(s)"
						value={partitionBlockOnly}
						onChange={(val) => {
							setPartitionBlockOnly(val);
							onChange(val ?? []);
						}}
						initialOptionId={(value as number[]).map((val) => val.toString())}
						displayMode={displayMode}
					/>
				);
			case 'serviceNumberLookup':
				return (
					<BooleanRadioFormControl
						label="Service Number Lookup"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'serviceNumberTranslate':
				return (
					<BooleanRadioFormControl
						label="Translate Service Number (Contact URI is modified)"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'contactUriFormat':
				return (
					<SipAgentContactUriFormatSelect
						label="Contact URI Format"
						value={contactUriFormat}
						onChange={(val) => {
							setContactUriFormat(val);
							onChange(val ?? null);
						}}
						initialOptionId={value?.toString()}
						displayMode={displayMode}
					/>
				);
			case 'contactTarget':
				return (
					<SipAgentContactTargetSelect
						label="Contact Target"
						value={contactTarget}
						onChange={(val) => {
							setContactTarget(val);
							onChange(val ?? null);
						}}
						initialOptionId={value?.toString()}
						displayMode={displayMode}
					/>
				);
			case 'contactValue':
				return (
					<SipAgentContactValueSelect
						label="Contact URI Value"
						value={contactValue}
						onChange={(val) => {
							setContactValue(val);
							onChange(val ?? null);
						}}
						initialOptionId={value?.toString()}
						displayMode={displayMode}
					/>
				);
			case 'nanpDialingFormat':
				return (
					<SipAgentDialFormatSelect
						label="NANP Dialing Format"
						value={nanpDialingFormat}
						onChange={(val) => {
							setNanpDialingFormat(val);
							onChange(val);
						}}
						isClearable={false}
						initialOptionId={value?.toString()}
						displayMode={displayMode}
					/>
				);
			case 'idddDialingFormat':
				return (
					<SipAgentDialFormatSelect
						label="IDDD Dialing Format"
						value={idddDialingFormat}
						onChange={(val) => {
							setIdddDialingFormat(val);
							onChange(val);
						}}
						isClearable={false}
						initialOptionId={value?.toString()}
						displayMode={displayMode}
					/>
				);
			case 'applyMp':
				return (
					<BooleanRadioFormControl
						label="Apply Margin Plan"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'applySq':
				return (
					<BooleanRadioFormControl
						label="Apply QoS (Service Quality Matching)"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			case 'applyRr':
				return (
					<BooleanRadioFormControl
						label="Apply Routing Rules"
						value={Number(value)}
						onChange={onChange}
						displayMode={displayMode}
					/>
				);
			default:
				return <p>Uncaught Property!</p>;
		}
	}
);

interface ISipAgentConfigPropertyEditorProps {
	value?: DefaultSipAgentConfig;
	onChange: (value: DefaultSipAgentConfig) => void;
	defaultConfig: DefaultSipAgentConfig;
	displayMode?: boolean;
}

const mediationGroup: SipAgentConfigPropertyKey[] = [
	'doMediation',
	'medDefaultSubId',
	'medNotFoundSubId',
	'medSources',
	'medTarget',
	'medValues'
];

const stirShakenGroup: SipAgentConfigPropertyKey[] = [
	'identityRequired',
	'stirShaken',
	'stirShakenCertificate',
	'stirShakenCertificateMs'
];

const blockingGroup: SipAgentConfigPropertyKey[] = [
	'globalBlock',
	'globalBlockOmit',
	'globalBlockOnly',
	'partitionBlock',
	'partitionBlockOmit',
	'partitionBlockOnly'
];

const routingGroup: SipAgentConfigPropertyKey[] = [
	'applyMp',
	'applySq',
	'applyRr'
];

interface IGroupedSipAgentProperties {
	General: SipAgentConfigPropertyKey[];
	Mediation: SipAgentConfigPropertyKey[];
	StirShaken: SipAgentConfigPropertyKey[];
	Blocking: SipAgentConfigPropertyKey[];
	Routing: SipAgentConfigPropertyKey[];
}

export const SipAgentConfigPropertyEditor = ({
	value,
	onChange,
	defaultConfig,
	displayMode = false
}: ISipAgentConfigPropertyEditorProps): JSX.Element => {
	const initialValue = useMemo(
		() => merge(defaultConfig, value),
		[value, defaultConfig]
	);

	const [config, setConfig] = useState(initialValue);
	const properties = useMemo(() => {
		return Object.keys(initialValue).reduce<IGroupedSipAgentProperties>(
			(dest, current) => {
				const currentKey = current as SipAgentConfigPropertyKey;

				if (mediationGroup.includes(currentKey)) {
					dest.Mediation.push(currentKey);
					return dest;
				}

				if (stirShakenGroup.includes(currentKey)) {
					dest.StirShaken.push(currentKey);
					return dest;
				}

				if (blockingGroup.includes(currentKey)) {
					dest.Blocking.push(currentKey);
					return dest;
				}

				if (routingGroup.includes(currentKey)) {
					dest.Routing.push(currentKey);
					return dest;
				}

				dest.General.push(currentKey);
				return dest;
			},
			{
				General: [],
				Mediation: [],
				StirShaken: [],
				Blocking: [],
				Routing: []
			}
		);
	}, [initialValue]);

	const keys = useMemo(
		() =>
			Object.keys(properties).filter(
				(key) => properties[key as keyof IGroupedSipAgentProperties].length > 0
			),
		[properties]
	);

	useDebounce(() => onChange(config), 100, [config]);

	const onChangeHandler = (
		key: SipAgentConfigPropertyKey,
		val: SipAgentConfigPropertyValue
	) => {
		setConfig((currentState) => {
			const newState = {
				...currentState,
				[key]: val
			};

			return newState;
		});
	};

	return (
		<div className="d-flex flex-column gap-3 pt-3">
			{keys.map((key) => {
				const group = properties[key as keyof IGroupedSipAgentProperties];
				return (
					<div key={key}>
						<p className="border-bottom border-dark fw-bold mb-3 p-0">{key}</p>
						{group.map((current, index) => (
							<SipAgentConfigPropertyRenderer
								key={index}
								propKey={current}
								value={initialValue[current]}
								onChange={(val) => onChangeHandler(current, val)}
								displayMode={displayMode}
							/>
						))}
					</div>
				);
			})}
		</div>
	);
};
