import { EntityIndexRequest, EntityIndexResponse } from 'RtModels';
import { EntityRouter } from 'RtUi/app/rt800/Entities/Entity.router';
import {
	fetchEntities,
	useGetEntities
} from 'RtUi/app/rt800/Entities/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getEntitiesColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/entities';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';
import { useMemo } from 'react';

interface IEntityGridProps {
	resourceParams: EntityIndexRequest;
}

export const EntityGrid = ({ resourceParams }: IEntityGridProps) => {
	const { data, isFetching: isLoading } = useGetEntities(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportEntities', resourceParams],
		resourceParams,
		(params) => fetchEntities(params)
	);
	const columns = useMemo(() => getEntitiesColumns(), []);

	return (
		<DataGrid<EntityIndexResponse>
			data={data?.data}
			router={EntityRouter}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			enableExternalExport
			getExternalExportData={fullResultsReq}
			pageName={'rt800_entities'}
		/>
	);
};
