import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	LcrCarrierRatesLookupIndexRequest,
	LcrCarrierRatesLookupIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchCarrierRates = async (
	queryParams?: LcrCarrierRatesLookupIndexRequest
): Promise<FullResponse<LcrCarrierRatesLookupIndexResponse[]>> => {
	return handleGetRequest<LcrCarrierRatesLookupIndexResponse[], true>(
		Rt800ApiRoutes.LcrCarrierRatesLookup.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetCarrierRates = (
	queryParams?: LcrCarrierRatesLookupIndexRequest
) => {
	return useQuery<FullResponse<LcrCarrierRatesLookupIndexResponse[]>, Error>(
		['getCarrierRates', queryParams],
		() => fetchCarrierRates(queryParams)
	);
};
