import clsx from 'clsx';
import { castArray, isEmpty, isEqual } from 'lodash-es';
import * as React from 'react';
import Select, {
	createFilter,
	FormatOptionLabelContext,
	FormatOptionLabelMeta,
	GroupBase,
	OnChangeValue,
	Props,
	StylesConfig
} from 'react-select';
import Creatable from 'react-select/creatable';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { IHttpResourceClass } from 'RtUi/utils/http/resources/interfaces';
import { getComputedStyleFromClasses } from 'RtUi/utils/react/DomHelpers';
import { ControlGroup } from '../../ui/ControlGroup';
import {
	FormControl,
	IFormControlProps,
	IFormControlState,
	IsMultiFormValue
} from '../FormControl';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { CustomOption } from 'RtUi/components/ui/SelectFormControl/components/CustomOption';

type ReactSelectProps<OptionType, isMulti extends boolean = false> = Props<
	OptionType,
	isMulti
> &
	Props<OptionType, isMulti>;
export interface ISelectFormControlState<T = { label: string; value: string }>
	extends IFormControlState {
	options?: T[];
	filteredOptions?: T[];
	filterValue?: string;
	isLoading?: boolean;
	valueKey?: string; // default 'value'
	labelKey?: string; // default 'label'
	disabledKey?: string; // no default
	placeholder?: string; // default 'Select <formLabel>...'
	isInvalid?: (record: T) => boolean;
	getLabelCssProperties?: (record: T) => React.CSSProperties;
	isMounted?: boolean;
}

export interface ISelectFormControlProps<T, isMulti extends boolean = false>
	extends IFormControlProps<T, isMulti> {
	creatable?: boolean;
	clearable?: boolean;
	isLoading?: boolean;
	containerClassName?: string;
	controlGroupClassName?: string;
	appendDropdownToBody?: boolean;
	upperCaseOnCreate?: boolean;
	promptTextCreator?: (label: string) => string;
	disabled?: boolean;
	closeMenuOnSelect?: boolean; //default true
	useControlGroup?: boolean;
	limit?: number;
	onFocus?: () => void;
	style?: React.CSSProperties;
	containerRef?: (containerRef: HTMLElement | null) => void;
	initialOptionId?: IsMultiFormValue<string, isMulti>;
	useInitialOptionIdOnUpdate?: boolean;
	multi?: isMulti;
	linkTo?: string;
	autoHeight?: boolean;
	valueClassName?: string;
	sortByExactMatch?: boolean;
	sortOptionsFn?: (a: T, b: T) => number;
	router?: RtUiRouter<any, T>;
	openMenuOnClick?: boolean;
	openMenuOnFocus?: boolean;
	rawOptions?: any[];
	components?: Partial<SelectComponents<T, isMulti, GroupBase<T>>>;
	optionRenderer?: (
		record: T,
		context: FormatOptionLabelContext
	) => React.ReactNode;
	filterOption?: (
		option: FilterOptionOption<T>,
		rawInput: string,
		filterOptionRef: (
			option: FilterOptionOption<T>,
			rawInput: string
		) => boolean
	) => boolean;
}

const DEFAULT_VALUE_KEY = 'value';
const DEFAULT_LABEL_KEY = 'label';

export abstract class SelectFormControl<
	T,
	isMulti extends boolean = false,
	P extends ISelectFormControlProps<T, isMulti> = ISelectFormControlProps<
		T,
		isMulti
	>,
	S extends ISelectFormControlState<T> = ISelectFormControlState<T>
> extends FormControl<T, isMulti, P, S> {
	public static getInvalidStyle() {
		return SelectFormControl.getStyle('bg-danger text-white');
	}

	public static getExactMatchStyle() {
		return SelectFormControl.getStyle('bg-warning text-white');
	}

	public static getDescendantMatchStyle() {
		return SelectFormControl.getStyle('table-info');
	}

	public static getAscendantMatchStyle() {
		return SelectFormControl.getStyle('bg-info text-white');
	}

	protected static getStyle(className: string): React.CSSProperties {
		const computedStyle = getComputedStyleFromClasses(className);

		return {
			backgroundColor: computedStyle.backgroundColor,
			color: computedStyle.color
		};
	}

	public abstract state: S;
	public abstract resourceClass?: IHttpResourceClass<T>;
	public resource: HttpResource<T> | undefined;
	public hasLoadedOptions = false;
	public containerRef: HTMLElement | null = null;

	public optionRenderer?: (
		record: T,
		context: FormatOptionLabelContext
	) => React.ReactNode;

	private clearNextInputChange = false;
	private requiredInputElem: HTMLInputElement | null = null;
	private getOptionsDefer: Promise<T[]> | null = null;

	public componentDidMount() {
		super.componentDidMount();

		this.setState({ isMounted: true });

		if (!this.resource && this.resourceClass) {
			this.resource = new this.resourceClass();
		}

		if (!this.resource) {
			throw new Error('Must contain resource!');
		}

		this.checkForDefault();
	}

	public componentDidUpdate(prevProps: Readonly<P>) {
		super.componentDidUpdate(prevProps);

		const { useInitialOptionIdOnUpdate = true } = prevProps;

		const propsChanged = !isEqual(this.props, prevProps);

		if (this.props.initialOptionId !== prevProps.initialOptionId) {
			this.loadOptions(true);
		}

		if (useInitialOptionIdOnUpdate && propsChanged) {
			this.checkForDefault();
		}
	}

	public componentWillUnmount() {
		this.setState({ isMounted: false });
	}

	public setGetAllParams(getAllParams: any = {}) {
		//Delete undefined values
		Object.keys(getAllParams).forEach((getAllParam) => {
			if (typeof getAllParams[getAllParam] === 'undefined') {
				delete getAllParams[getAllParam];
			}
		});

		if (this.state.isMounted) {
			let shouldUpdate = false;

			this.setState(
				(state) => {
					const areEqualParams = isEqual(getAllParams, state.getAllParams);
					shouldUpdate = !areEqualParams && Boolean(this.state.isMounted);

					if (!shouldUpdate) {
						return null;
					}

					return { getAllParams };
				},
				() => {
					if (shouldUpdate) {
						this.reloadOptionsOnFocus();
					}
				}
			);
		} else {
			this.state.getAllParams = getAllParams;
		}
	}

	public setContainerRef(newContainerRef: HTMLElement | null) {
		const { containerRef } = this.props;

		if (containerRef) {
			containerRef(newContainerRef);
		}

		this.containerRef = newContainerRef;

		if (this.containerRef) {
			this.containerRef.onpaste = (evt) => this.onPaste(evt);
		}
	}

	public checkForDefault() {
		const {
			initialOptionId,
			onChange = () => ({}),
			multi = false,
			displayMode,
			value
		} = this.props;
		const { valueKey = DEFAULT_VALUE_KEY, options } = this.state;
		const valueIsEmpty = isEmpty(value);

		if (options && typeof initialOptionId !== 'undefined' && valueIsEmpty) {
			let defaultOption: T | T[] | undefined;
			let optionFound = false;

			if (multi) {
				defaultOption = options.filter((o) =>
					initialOptionId.includes(String(o[valueKey as keyof T]))
				);
				optionFound = defaultOption.length > 0;
			} else {
				defaultOption = options.find(
					(o) => String(o[valueKey as keyof T]) === initialOptionId
				);
				optionFound = typeof defaultOption !== 'undefined';
			}

			if (optionFound) {
				this.setState({ options }, () => {
					const { value } = this.props;
					let valueIsUndefined = typeof value === 'undefined';

					if (!valueIsUndefined && multi) {
						valueIsUndefined = Array.isArray(value) && value.length <= 0;
					}

					//null value indicates removal of value
					if (valueIsUndefined) {
						const onChangeKludge: any = onChange; //todo createSelectContainer
						onChangeKludge(defaultOption);
					}
				});
			}
		} else if (!options && typeof initialOptionId !== 'undefined') {
			this.loadOptions();
		} else if (!options && displayMode) {
			this.loadOptions();
		}
	}

	public reloadOptionsOnFocus() {
		this.hasLoadedOptions = false;
	}

	public loadOptions(forceUpdate: boolean = false) {
		if (!this.state.isMounted && this.getOptionsDefer) {
			return this.getOptionsDefer;
		}

		if ((forceUpdate || !this.hasLoadedOptions) && !this.state.isLoading) {
			this.hasLoadedOptions = true;
			this.setState({ isLoading: true });

			this.getOptionsDefer = this.getData().then((options) => {
				if (this.props.sortOptionsFn) {
					options.sort(this.props.sortOptionsFn);
				}

				if (this.state.isMounted) {
					this.setState({ isLoading: false, options }, () => {
						this.checkForDefault();
						this.updateFilteredOptions();
					});
				}

				return options;
			});
		}

		return this.getOptionsDefer;
	}

	public getControlLabel() {
		const {
			valueKey = DEFAULT_VALUE_KEY,
			labelKey = DEFAULT_LABEL_KEY,
			options = []
		} = this.state;
		const { initialOptionId, value } = this.props;
		let selectedLabel: React.ReactNode = castArray(initialOptionId).join(', ');

		if (Array.isArray(value)) {
			selectedLabel = castArray(value)
				.map((v) => {
					const selected = options.find(
						(o) => o[valueKey as keyof T] === v[valueKey]
					);
					if (selected) {
						return selected[labelKey as keyof T];
					} else if (v[labelKey]) {
						return v[labelKey];
					} else {
						return v[valueKey];
					}
				})
				.filter((v) => v !== null)
				.join(', ');
		} else if (value) {
			const selectedOption = value
				? // @ts-expect-error
					options.find((o) => o[valueKey] === value[valueKey])
				: undefined;

			if (selectedOption && this.optionRenderer) {
				selectedLabel = this.optionRenderer(selectedOption, 'value');
			} else if (selectedOption && selectedOption[labelKey as keyof T]) {
				//@ts-expect-error
				selectedLabel = selectedOption[labelKey as keyof T];
				// @ts-expect-error
			} else if (value[labelKey]) {
				// @ts-expect-error
				selectedLabel = value[labelKey];
			} else {
				// @ts-expect-error
				selectedLabel = value[valueKey];
			}

			return selectedLabel;
		}

		return selectedLabel;
	}

	public triggerOnChange(value?: OnChangeValue<T, isMulti>) {
		const { limit, multi = false, upperCaseOnCreate = false } = this.props;

		if (value instanceof Array && typeof limit === 'number') {
			//NO further updates allowed
			if (limit < value.length) {
				return;
			}
		}

		if (upperCaseOnCreate) {
			const { valueKey = DEFAULT_VALUE_KEY } = this.state;

			const upperCaseValue = (data: any | null) => {
				if (data !== null && typeof data[valueKey] === 'string') {
					data[valueKey] = data[valueKey].toUpperCase();
				}

				return data;
			};

			if (value instanceof Array) {
				//@ts-ignore
				value = value.map(upperCaseValue);
			} else if (multi) {
				//@ts-ignore
				value = [];
			} else {
				value = upperCaseValue(value);
			}
		}

		return super.triggerOnChange(value as any);
	}

	public onInputChange(filterValue: string) {
		if (this.clearNextInputChange) {
			this.clearNextInputChange = false;

			filterValue = '';
		}

		this.setState({ filterValue });

		if (!this.props.sortByExactMatch) {
			return filterValue;
		}

		if (filterValue) {
			const options = this.state.options || [];
			const { valueKey = DEFAULT_VALUE_KEY } = this.state;
			const filteredOptions = [...options].sort((a: T, b: T) => {
				const aMatch =
					`${(a as any)[valueKey]}`
						.toLowerCase()
						.indexOf(filterValue.toLowerCase()) === 0;

				const bMatch =
					`${(b as any)[valueKey]}`
						.toLowerCase()
						.indexOf(filterValue.toLowerCase()) === 0;

				return Number(bMatch) - Number(aMatch);
			});

			this.setState({ filteredOptions });
		} else {
			this.setState({ filteredOptions: this.state.options });
		}

		return filterValue;
	}

	public updateFilteredOptions(
		options = this.state.options || [],
		filterValue = this.state.filterValue || ''
	) {
		if (!filterValue) {
			this.setState({ filteredOptions: options });
		}

		const { valueKey = DEFAULT_VALUE_KEY, labelKey = DEFAULT_LABEL_KEY } =
			this.state;
		const filterValueSearchStr = filterValue.toLowerCase();
		const isMatch = (searchStr: string) =>
			searchStr.toLowerCase().includes(filterValueSearchStr);

		const filteredOptions = options.filter((option) => {
			// @ts-expect-error
			const label = String(option[labelKey]);
			// @ts-expect-error
			const value = String(option[valueKey]);

			if (isMatch(label)) {
				return true;
			}
			if (isMatch(value)) {
				return true;
			}

			return false;
		});

		this.setState({ filteredOptions });
	}

	public onFocus() {
		const { onFocus } = this.props;

		if (onFocus) {
			onFocus();
		}

		return this.loadOptions();
	}

	public onPaste(ev: ClipboardEvent) {
		const { creatable, multi } = this.props;

		if (!creatable || !multi || !ev.clipboardData) {
			return;
		}

		const pastedData = ev.clipboardData.getData('text');
		const returnRegex = /[\t|\n|\r,]/;
		const pastedDataPieces = pastedData
			.split(returnRegex)
			.map((data) => data.trim());

		if (pastedDataPieces.length > 0) {
			const { valueKey = DEFAULT_VALUE_KEY, labelKey = DEFAULT_LABEL_KEY } =
				this.state;
			const { value = [] } = this.props;

			const newRecords = pastedDataPieces.map((pastedData) => {
				const newRecord = {
					[valueKey]: pastedData,
					[labelKey]: pastedData
				};

				//@ts-ignore
				return newRecord as T;
			});

			//@ts-ignore -- already checked that value was multi
			const newOptions = uniqBy([...value, ...newRecords], valueKey);

			this.clearNextInputChange = true;

			//@ts-ignore
			this.triggerOnChange(newOptions);
		}
	}

	public getStylesConfig(): StylesConfig<T, boolean> {
		const { isInvalid = () => false, getLabelCssProperties = () => {} } =
			this.state;

		return {
			menuPortal: (base) => ({ ...base, zIndex: 999 }),
			multiValueLabel: (styles, { data }) => {
				if (data) {
					const cssAttrsFromSubclass = getLabelCssProperties(data);
					const isRecordInvalid = isInvalid(data);

					if (cssAttrsFromSubclass) {
						Object.assign(styles, cssAttrsFromSubclass);
					}

					if (isRecordInvalid) {
						Object.assign(styles, SelectFormControl.getInvalidStyle());
					}
				}

				return styles;
			}
		};
	}

	public filterOption(option: FilterOptionOption<T>, rawInput: string) {
		const defaultFilter = createFilter({ ignoreAccents: false });

		return defaultFilter(option, rawInput);
	}

	public getCustomerValidityMessage(): string | undefined {
		return;
	}

	/**
	 * Returns if the form input has fulfilled the required prop
	 */
	public getRequiredInputValidationValue(): '1' | '' {
		return !isEmpty(this.props.value) ? '1' : '';
	}

	public setRequiredInputElem(requiredInputElem: HTMLInputElement | null) {
		this.requiredInputElem = requiredInputElem;

		if (!this.requiredInputElem) {
			return;
		}

		const requiredInputValidationValue = this.getRequiredInputValidationValue();
		const customerValidityMessage = this.getCustomerValidityMessage();

		if (requiredInputValidationValue === '' && customerValidityMessage) {
			this.requiredInputElem.setCustomValidity(customerValidityMessage);
		} else {
			this.requiredInputElem.setCustomValidity('');
		}
	}

	/**
	 * Override to dictate what creatable options are valid
	 * @param inputValue
	 * @param value
	 * @param options
	 */
	public isValidNewOption(inputValue: string) {
		return typeof inputValue === 'string' && inputValue.length > 0;
	}

	/**
	 * Default new option creation. Please override where it makes sense.
	 * @param inputValue
	 * @param optionLabel
	 */
	public getNewOptionData(inputValue: string): T {
		const { valueKey = DEFAULT_VALUE_KEY, labelKey = DEFAULT_LABEL_KEY } =
			this.state;

		const newOption: T = {
			[valueKey]: inputValue,
			[labelKey]: inputValue
		} as any;

		return newOption;
	}

	public render(): React.ReactNode {
		const {
			isLoading: stateIsLoading = false,
			valueKey = DEFAULT_VALUE_KEY,
			labelKey = DEFAULT_LABEL_KEY,
			formLabel
		} = this.state;
		const { placeholder = '' } = this.state;
		const { filteredOptions = [] } = this.state;
		const {
			value,
			name,
			required,
			className,
			displayMode,
			multi,
			useControlGroup = true,
			creatable = false,
			clearable = true,
			appendDropdownToBody = false,
			containerClassName = '',
			optionRenderer,
			filterOption
		} = this.props;
		let { disabled = false } = this.props;
		const controlLabel = this.getControlLabel();
		const classNames = ['react-select', className];
		const isLoading =
			typeof this.props.isLoading === 'boolean'
				? this.props.isLoading
				: stateIsLoading;

		if (multi) {
			classNames.push('is-multi');
		}

		if (!useControlGroup && displayMode) {
			classNames.push('hide-container');
			disabled = true;
		}

		let formatOptionLabel:
			| ((option: T, labelMeta: FormatOptionLabelMeta<T>) => React.ReactNode)
			| undefined;

		const optionRendererRef = optionRenderer || this.optionRenderer;

		if (optionRendererRef) {
			formatOptionLabel = (record, metaLabel) =>
				optionRendererRef(record, metaLabel.context);
		}

		const commonProps: ReactSelectProps<T, isMulti> = {
			classNamePrefix: 'react-select',
			className: classNames.join(' '),
			noOptionsMessage: () => (isLoading ? 'Loading...' : 'No Results Found'),
			onFocus: () => this.onFocus(),
			isLoading: isLoading,
			// @ts-expect-error
			getOptionLabel: (record: T) => record[labelKey] || record[valueKey],
			// @ts-expect-error
			getOptionValue: (record: T) => String(record[valueKey]),
			formatOptionLabel: formatOptionLabel,
			isClearable: clearable,
			isDisabled: disabled,
			name: name,
			closeMenuOnSelect: this.props.closeMenuOnSelect,
			placeholder: placeholder,
			options: !isEmpty(this.props.rawOptions)
				? this.props.rawOptions
				: filteredOptions,
			filterOption: filterOption
				? (option, inputValue) =>
						filterOption(option, inputValue, this.filterOption.bind(this))
				: this.filterOption.bind(this),
			menuPortalTarget: appendDropdownToBody ? document.body : undefined,
			onInputChange: (inputValue: any) => this.onInputChange(inputValue),
			onChange: (option: any) => this.triggerOnChange(option),
			value: value ? value : null,
			isMulti: multi,
			required: required,
			styles: this.getStylesConfig(),
			id: this.props.id,
			openMenuOnClick: this.props.openMenuOnClick,
			openMenuOnFocus: this.props.openMenuOnFocus,
			components: this.props.components
		};

		if (this.state.disabledKey) {
			const { disabledKey } = this.state;
			commonProps.isOptionDisabled = (record: T) =>
				// @ts-expect-error
				record[disabledKey] === true;
		}

		const { style = {} } = this.props;
		const containerStyles: React.CSSProperties = {
			position: 'relative',
			...style
		};

		const inputValidationValue = this.getRequiredInputValidationValue();
		const errorText = this.getCurrentFormErrors()?.join(', ') || '';
		const isInvalid =
			(required && inputValidationValue === '') || (name && Boolean(errorText));
		const createSelectContainer = (...children: React.ReactNode[]) => {
			return (
				<div
					className={clsx('select-form-control', containerClassName, {
						'is-invalid': isInvalid
					})}
					style={containerStyles}
					ref={(ref) => this.setContainerRef(ref)}
				>
					{children}
					<input
						ref={(elem) => this.setRequiredInputElem(elem)}
						tabIndex={-1}
						autoComplete="off"
						onChange={() => ({})} //noop for validation purposes
						style={{
							opacity: 0,
							height: 1,
							width: 0,
							position: 'absolute',
							left: '25%',
							bottom: 0
						}}
						value={inputValidationValue}
						required={required}
					/>
				</div>
			);
		};

		let selectElement = createSelectContainer(
			<Select<T, isMulti>
				key={`${this.props.label}_${this.props.id}_${this.props.name}`}
				components={{ Option: CustomOption as any }}
				{...commonProps}
			/>
		);

		if (creatable) {
			selectElement = createSelectContainer(
				<Creatable
					{...commonProps}
					allowCreateWhileLoading
					isValidNewOption={(inputValue: string) =>
						this.isValidNewOption(inputValue)
					}
					getNewOptionData={(inputValue: any) =>
						this.getNewOptionData(inputValue)
					}
					formatCreateLabel={this.props.promptTextCreator}
				/>
			);
		}
		let { linkTo } = this.props;

		if (
			!linkTo &&
			this.props.router?.recordHasAccessToProfile(this.props.value)
		) {
			linkTo = this.props.router.getProfileRoute(this.props.value);
		}

		return (
			<ControlGroup
				errorText={errorText}
				autoHeight={this.props.autoHeight}
				label={formLabel}
				hideLabel={this.props.hideLabel || !useControlGroup}
				hideFormGroup={!useControlGroup}
				value={controlLabel}
				displayMode={displayMode}
				sublabel={this.props.subLabel}
				required={required}
				linkTo={linkTo}
				className={this.props.controlGroupClassName}
				valueClassName={this.props.valueClassName}
				isInvalid={isInvalid}
			>
				{selectElement}
			</ControlGroup>
		);
	}
}
