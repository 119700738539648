import { RtCommonApiRoutes } from 'RtExports/routes';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { DialCodeIndexResponse } from 'RtModels';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

interface IDialCodeContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const DialCodeContainerTabs: IDialCodeContainerTabs = {
	Profile: {
		header: 'Profile'
	}
};

class DialCodeRouterClass extends RtVueReportRouter<
	DialCodeIndexResponse,
	DialCodeIndexResponse,
	IDialCodeContainerTabs
> {
	constructor() {
		super('Dial Code', 'dialcodes', 'dialCodeId', DialCodeContainerTabs);

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.DialCodes);
	}

	public getProfileLabel(profile: DialCodeIndexResponse) {
		return String(profile.dialCodeId);
	}
}

export const DialCodeRouter = new DialCodeRouterClass();
