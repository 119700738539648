import { RtxSipApiRoutes } from 'RtExports/routes';
import { SipTraceIndexRequest, SipTraceIndexResponse } from 'RtModels';

import {
	FullResponse,
	handlePostRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchSipTrace = async (
	body: SipTraceIndexRequest
): Promise<FullResponse<SipTraceIndexResponse>> => {
	return handlePostRequest<SipTraceIndexResponse, true>(
		RtxSipApiRoutes.SipTrace.Index,
		body,
		{
			includeFullResponse: true
		}
	);
};

export const useGetSipTrace = (body: SipTraceIndexRequest) => {
	return useQuery<FullResponse<SipTraceIndexResponse>, Error>(
		[`useGetSipTrace`, body],
		() => fetchSipTrace(body),
		{ enabled: true }
	);
};
