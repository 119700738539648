import { AsrLiteIndexResponse } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getAsrLiteColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/asrLite';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface IAsrDataGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<AsrLiteIndexResponse> | null;
	isLoading?: boolean;
	callDateFormat?: string;
	onDrilldown?: (
		row: AsrLiteIndexResponse,
		key: keyof AsrLiteIndexResponse
	) => void;
}

export const AsrLiteDataGrid = ({
	resource,
	isLoading,
	onSortingChange: onSortedChange,
	defaultSorting: defaultSorted,
	callDateFormat
}: IAsrDataGridProps) => {
	const { data } = useQuery<AsrLiteIndexResponse[] | undefined, Error>(
		[`useAsrLiteReport`, resource],
		() => resource?.getAll()
	);
	const columns = useMemo(
		() => getAsrLiteColumns(callDateFormat),
		[callDateFormat]
	);

	return (
		<DataGrid<AsrLiteIndexResponse>
			pageName="rtVue_asrLite"
			data={data}
			columns={columns}
			loading={isLoading}
			onSortingChange={onSortedChange}
			defaultSorting={defaultSorted}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
