/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $JobProfileResponse = {
    properties: {
        jobId: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        flowId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        userId: {
            type: 'number',
            isRequired: true,
        },
        requestedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        scheduledTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        processedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        isLive: {
            type: 'number',
            isRequired: true,
        },
        priority: {
            type: 'number',
            isRequired: true,
        },
        ref1: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        ref2: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        totalQty: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        parameters: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        emailList: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        jobStatusId: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        completedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        steps: {
            type: 'Array',
            isRequired: true,
        },
    },
};