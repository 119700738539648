import {
	LcrTemplateProfileResponse,
	TemplateLcrCarrierIndexRequest,
	TemplateLcrCarrierIndexResponse
} from 'RtModels';
import { LcrCarriersAddTemplateAside } from 'RtUi/app/rtLco/Carriers/lib/components/LcrCarriersAddTemplateAside';
import { LcrTemplateRouter } from 'RtUi/app/rtLco/Templates/LcrTemplate.router';
import {
	removeCarrierFromTemplate,
	useGetLcrTemplateCarriers
} from 'RtUi/app/rtLco/Templates/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLcrTemplateCarriersColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/lcrTemplates';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { noop } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMutation } from 'react-query';

interface ILcrTemplateCarriersGridProps {
	lcrTemplate: LcrTemplateProfileResponse;
	resourceParams: TemplateLcrCarrierIndexRequest;
	autoFocusFilter?: boolean;
}

export const LcrTemplateCarriersGrid = ({
	resourceParams,
	lcrTemplate
}: ILcrTemplateCarriersGridProps) => {
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetLcrTemplateCarriers(resourceParams);
	const { mutateAsync } = useMutation(removeCarrierFromTemplate);
	const [isAddCarrierAsideOpen, setIsAddCarrierAsideOpen] =
		useState<boolean>(false);

	const removeCarrier = useCallback(
		async (carrier: TemplateLcrCarrierIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				carrier.label,
				`<span> from </span>
			<span class="text-muted">${resourceParams.templateName}</span>. `
			);

			if (!confirm) {
				return;
			}

			await mutateAsync({
				templateName: resourceParams.templateName,
				templateLcrCarrierId: carrier.templateLcrCarrierId
			});

			refetch();
		},
		[resourceParams, mutateAsync, refetch]
	);

	const columns = useMemo(
		() => getLcrTemplateCarriersColumns(removeCarrier),
		[removeCarrier]
	);

	return (
		<>
			<DataGrid<TemplateLcrCarrierIndexResponse>
				pageName="rtLco-templateCarriers"
				loading={isLoading}
				data={data?.data}
				router={LcrTemplateRouter}
				totalRows={data?.totalCount}
				columns={columns}
				headerAction={() => {
					return (
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id={`addCarrierToTemplateBtn-tooltip`} {...props}>
									Add Account to Template
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									onClick={() => setIsAddCarrierAsideOpen(true)}
									variant="white"
									id="addCarrierToTemplateBtn"
								>
									<i className="fas fa-fw fa-plus fa-lg" />
								</Button>
							)}
						</OverlayTrigger>
					);
				}}
			/>
			<Confirmation />
			<LcrCarriersAddTemplateAside
				onBeforeSave={noop}
				isOpen={isAddCarrierAsideOpen}
				onCancel={() => setIsAddCarrierAsideOpen(false)}
				templateName={resourceParams.templateName}
				respOrgId={lcrTemplate.respOrgId}
				onSave={() => {
					setIsAddCarrierAsideOpen(false);
					refetch();
				}}
			/>
		</>
	);
};
