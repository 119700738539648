import { format } from 'date-fns';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { FormEvent } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import {
	ConnectionIndexResponse,
	FileStreamIndexResponse,
	GuardianFraudSimulatorProfileRequest,
	GuardianFraudSimulatorResponse
} from 'RtModels';
import { E164NumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/E164NumberFormControl';
import { FraudSimulatorRouter } from 'RtUi/app/rtGuardian/FraudSimulator/FraudSimulator.router';
import { FraudSimulatorResults } from 'RtUi/app/rtGuardian/FraudSimulator/lib/components/FraudSimulatorResults';
import { FraudSimulatorHttp } from 'RtUi/app/rtGuardian/FraudSimulator/lib/http/FraudSimulatorHttp';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import { TrunkGroupSelect } from 'RtUi/app/rtVue/Reconciliation/lib/components/TrunkGroupSelect';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RtUiWideForm, RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { urlFormat } from 'RtUi/utils/urlFormat/urlFormat';

interface IFraudSimulatorContainerState {
	isSubmitting: boolean;
	error?: any;
	simulationResult?: GuardianFraudSimulatorResponse;
	fileStreamId?: string;
	fileStream?: FileStreamIndexResponse;
	ani?: string;
	dialedNumber?: string;
	ingressTrunkGroup?: ConnectionIndexResponse;
	egressTrunkGroup?: ConnectionIndexResponse;
	startTime?: moment.Moment;
	ingressTrunkGroupId?: string | null;
	egressTrunkGroupId?: string | null;
}

@FraudSimulatorRouter.getFraudIndexRtUiController()
export class FraudSimulatorIndexContainer extends ApplicationContainer<
	{},
	IFraudSimulatorContainerState
> {
	public state: IFraudSimulatorContainerState = {
		error: undefined,
		isSubmitting: false,
		startTime: moment()
	};

	public resource = new FraudSimulatorHttp();

	public componentDidMount() {
		const fileStreamId = this.getSearchParam('fileStreamId');
		const dialedNumber = this.getSearchParam('dialedNumber') ?? '';
		const ani = this.getSearchParam('ani') ?? '';
		const startTime = moment(this.getSearchParam('startTime'));
		const ingressTrunkGroupId = this.getSearchParam('originTrunkGroup');
		const egressTrunkGroupId = this.getSearchParam('termTrunkGroup');

		if (fileStreamId) {
			this.setState(
				{
					fileStreamId,
					dialedNumber,
					ani,
					startTime,
					ingressTrunkGroupId,
					egressTrunkGroupId
				},
				async () => {
					try {
						this.setState({ isSubmitting: true, error: undefined });

						const requestBody: GuardianFraudSimulatorProfileRequest = {
							fileStreamId: Number(fileStreamId),
							startTime: this.state.startTime
								? format(startTime.toDate(), 'HH:mm:ss')
								: undefined,
							ingressTrunkgroupId: ingressTrunkGroupId ?? undefined,
							egressTrunkgroupId: egressTrunkGroupId ?? undefined,
							dialedNumber,
							ani
						};

						const simulationResult = await this.resource.simulate(requestBody);
						this.setState({
							isSubmitting: false,
							simulationResult
						});
					} catch (error) {
						this.setState({ isSubmitting: false, error });
					}
				}
			);
		}
	}

	public async onSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		this.setState({ isSubmitting: true });

		try {
			this.setState({ isSubmitting: true, error: undefined });

			const requestBody: GuardianFraudSimulatorProfileRequest = {
				fileStreamId: this.state.fileStream!.fileStreamId,
				startTime: this.state.startTime
					? format(this.state.startTime.toDate(), 'HH:mm:ss')
					: undefined,
				ingressTrunkgroupId: this.state.ingressTrunkGroup?.trunkGroupId,
				egressTrunkgroupId: this.state.egressTrunkGroup?.trunkGroupId,
				dialedNumber: this.state.dialedNumber,
				ani: this.state.ani
			};

			if (!isEmpty(requestBody)) {
				window.history.replaceState(
					null,
					'RouteTrust',
					`?${urlFormat(requestBody)}`
				);
			}

			const simulationResult = await this.resource.simulate(requestBody);
			this.setState({
				isSubmitting: false,
				simulationResult
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	}

	public render() {
		return (
			<StandardLayout router={FraudSimulatorRouter}>
				<RtUiWideForm
					noValidate
					disablePadding
					disableBody
					hideButtons
					error={this.state.error}
					isSubmitting={this.state.isSubmitting}
					onSubmit={(e) => this.onSubmit(e)}
				>
					<Row>
						<Col>
							<Alert variant="info-outline" className="alert-sm">
								<p className="mb-0">
									Dialed Number must be in E164 format. Be sure to add a
									number&rsquo;s country code. For example, north american
									numbers a &ldquo;+1&rdquo; should be entered first.
								</p>
							</Alert>
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
							<E164NumberFormControl
								required
								label="Dialed Number"
								onChange={(dialedNumber) => {
									this.setState({ dialedNumber });
								}}
								value={this.state.dialedNumber}
								name="dialedNumber"
							/>
						</Col>
						<Col lg={6}>
							<E164NumberFormControl
								label="ANI"
								onChange={(ani) => {
									this.setState({ ani });
								}}
								loose
								value={this.state.ani}
								name="ani"
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
							<FileStreamSelect
								isActive
								required
								label="File Stream"
								value={this.state.fileStream}
								onChange={(fileStream) => this.setState({ fileStream })}
								initialOptionId={this.state.fileStreamId}
							/>
						</Col>
						<Col lg={6}>
							<DatetimeFormControl
								label="Start Time"
								disableNowPreset
								required
								onChange={(startTime) => this.setState({ startTime })}
								value={this.state.startTime}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
							<TrunkGroupSelect
								required
								label="Orig Trunk Group"
								onChange={(ingressTrunkGroup) => {
									this.setState({ ingressTrunkGroup });
								}}
								value={this.state.ingressTrunkGroup}
								params={{
									fileStreamIds: this.state.fileStream?.fileStreamId
										? [this.state.fileStream?.fileStreamId]
										: []
								}}
								initialOptionId={this.state.ingressTrunkGroupId ?? undefined}
							/>
						</Col>
						<Col lg={6}>
							<TrunkGroupSelect
								label="Term Trunk Group"
								onChange={(egressTrunkGroup) => {
									this.setState({ egressTrunkGroup });
								}}
								value={this.state.egressTrunkGroup}
								params={{
									fileStreamIds: this.state.fileStream?.fileStreamId
										? [this.state.fileStream?.fileStreamId]
										: []
								}}
								initialOptionId={this.state.egressTrunkGroupId ?? undefined}
							/>
						</Col>
					</Row>
					<RtUiForm.Footer>
						<Button
							type="submit"
							variant="submit"
							disabled={this.state.isSubmitting}
						>
							{this.state.isSubmitting ? (
								<i className="fas fa-cog fa-cog fa-spin" />
							) : (
								<i className="fas fa-fw fa-play-circle fa-sm" />
							)}
							<span>&nbsp;Simulate</span>
						</Button>
					</RtUiForm.Footer>
				</RtUiWideForm>
				{this.state.simulationResult && !this.state.isSubmitting && (
					<FraudSimulatorResults result={this.state.simulationResult} />
				)}
			</StandardLayout>
		);
	}
}
