import { TermSummaryIndexResponseData } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getTermSummaryReportColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/termSummary';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface ITermSummaryGridProps extends IRtVueReportDataGridProps {
	isLoading?: boolean;
	resource: HttpResource<TermSummaryIndexResponseData> | null;
}

export const TermSummaryGrid = ({
	resource,
	isLoading
}: ITermSummaryGridProps) => {
	const { data = [] } = useQuery<
		TermSummaryIndexResponseData[] | undefined,
		Error
	>([`useGetTermSummaryReport`, resource], () => resource?.getAll());
	const columns = useMemo(() => getTermSummaryReportColumns(), []);

	return (
		<DataGrid<TermSummaryIndexResponseData>
			pageName="rtVue_termReport"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
