import { DashboardIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';

export const getDashboardColumns = (): Array<
	DataGridColumn<DashboardIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'sectionName',
		header: 'Section'
	}),
	DefaultDataGridColumn({
		accessorKey: 'dashboardTaskLabel',
		header: 'Task Label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'totalItemQty',
		header: 'Total Qty'
	}),
	DefaultDataGridColumn({
		accessorKey: 'totalItemLabel',
		header: 'Total Label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'warningItemQty',
		header: 'Warning Qty'
	}),
	DefaultDataGridColumn({
		accessorKey: 'warningItemLabel',
		header: 'Warning Label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'errorItemQty',
		header: 'Error Qty'
	}),
	DefaultDataGridColumn({
		accessorKey: 'errorItemLabel',
		header: 'Error Label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'data',
		header: 'Data'
	})
];
