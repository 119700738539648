import { Rt800ApiRoutes } from 'RtExports/routes';
import { LcoApplicationRouter } from 'RtUi/app/rtLco/rtLco.router';
import {
	ILcrScenarioIndexResponse,
	ILcrScenarioProfileResponse
} from './lib/resources/LcrScenariosResource';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface ILcrScenarioContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const LcrScenarioContainerTabs: ILcrScenarioContainerTabs = {
	Profile: { header: 'Profile' }
};

export class LcrScenarioRouterClass extends LcoApplicationRouter<
	ILcrScenarioIndexResponse,
	ILcrScenarioProfileResponse,
	ILcrScenarioContainerTabs
> {
	constructor() {
		super(
			'Scenario',
			'lcrScenarios',
			'lcrScenarioId',
			LcrScenarioContainerTabs
		);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.LcrScenarios);
	}

	public getProfileLabel(profile: ILcrScenarioProfileResponse) {
		return profile.label;
	}
}

export const LcrScenarioRouter = new LcrScenarioRouterClass();
