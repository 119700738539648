/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RtDidSearchRequest = {
    properties: {
        iso3: {
            type: 'string',
            isRequired: true,
        },
        dialCode: {
            type: 'string',
        },
        digits: {
            type: 'string',
        },
        npa: {
            type: 'string',
        },
        nxx: {
            type: 'string',
        },
        line: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        rateCenter: {
            type: 'string',
        },
        timeoutMs: {
            type: 'string',
        },
        onlyResourceIds: {
            type: 'Array',
        },
        sortMethod: {
            type: 'number',
        },
        searchLimit: {
            type: 'number',
        },
    },
};