/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	CdrExportControlIndexRequest,
	CdrExportControlIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchRecurringExports = async (
	queryParams?: CdrExportControlIndexRequest
): Promise<FullResponse<CdrExportControlIndexResponse[]>> => {
	return handleGetRequest<CdrExportControlIndexResponse[], true>(
		RtxSipApiRoutes.CdrExportControl.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetRecurringExports = (
	params?: CdrExportControlIndexRequest
) => {
	return useQuery<FullResponse<CdrExportControlIndexResponse[]>, Error>(
		[`getRecurringExports`, params],
		() => fetchRecurringExports(params),
		{ enabled: true }
	);
};
