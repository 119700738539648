import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { RtxApiRoutes } from 'RtExports/routes';
import { BrandingIndexResponse } from 'RtModels';

export class PublicHttp {
	private static BrandingDefer: Promise<BrandingIndexResponse> | undefined;
	private static readonly BrandingFallback: BrandingIndexResponse = {
		logo: '/assets/images/h-logo.png',
		siteTitle: 'RouteTrust',
		loginTitle: 'RouteTrust Login',
		forgotPasswordTitle: 'RouteTrust Password Recovery',
		loginLogo: '/assets/images/h-logo.png'
	};

	/**
	 * Get branding information
	 */
	public async getBranding(useCache = true) {
		if (!PublicHttp.BrandingDefer || !useCache) {
			PublicHttp.BrandingDefer =
				HttpRequest.fetchWithRoute<BrandingIndexResponse>(
					RtxApiRoutes.Branding.Index,
					{
						doNotNotifyOn404Error: true
					}
				).catch((error) => {
					if (error?.pOrigin?.statusCode === 302) {
						window.location.replace(error.pOrigin.message);
					}

					return PublicHttp.BrandingFallback;
				});
		}

		return PublicHttp.BrandingDefer;
	}
}
