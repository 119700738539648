import { useMemo } from 'react';
import {
	TrackingGroupIndexRequest,
	TrackingGroupIndexResponse
} from 'RtModels';
import { useGetTrackingGroup } from 'RtUi/app/rtSip/TrackingGroups/lib/services';
import { TrackingGroupRouter } from 'RtUi/app/rtSip/TrackingGroups/TrackingGroup.router';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getTrackingGroupColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/trackingGroup';

interface ITrackingGroupGridProps {
	resourceParams?: TrackingGroupIndexRequest;
	autoFocusFilter?: boolean;
}

export const TrackingGroupGrid = ({
	resourceParams
}: ITrackingGroupGridProps) => {
	const { data, isFetching: isLoading } = useGetTrackingGroup(resourceParams);

	const columns = useMemo(() => getTrackingGroupColumns(), []);

	return (
		<DataGrid<TrackingGroupIndexResponse>
			router={TrackingGroupRouter}
			data={data?.data}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_trackingGroup'}
			columns={columns}
		/>
	);
};
