import { ServerProfileResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { FqdnDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FqdnDataGridColumn';

export const getServerColumns = (): Array<
	DataGridColumn<ServerProfileResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'serverId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'hostName',
		header: 'Host Name'
	}),
	FqdnDataGridColumn({
		accessorKey: 'publicIpAddress',
		header: 'Public Ip Address'
	}),
	FqdnDataGridColumn({
		accessorKey: 'privateIpAddress',
		header: 'Private Ip Address'
	}),
	DefaultDataGridColumn({
		accessorKey: 'networkId',
		header: 'Network Id'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isOnline',
		header: 'Online'
	}),
	BooleanDataGridColumn({
		accessorKey: 'allowConfigRefresh',
		header: 'Allow Config Refresh'
	}),
	DefaultDataGridColumn({
		accessorKey: 'cdrWorkerId',
		header: 'CDR Worker Id'
	}),
	DateDataGridColumn({
		accessorKey: 'createdTime',
		header: 'Created'
	}),
	DefaultDataGridColumn({
		accessorKey: 'updatedTime',
		header: 'Updated'
	})
];
