import {
	RateLoadErrorType,
	RateLoadIndexResponse,
	RateLoadNewRateIndexResponse,
	RateLoadOldRateIndexResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';
import { RateLoadExpireModeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateLoadExpireModeDataGridColumn';
import { RateLoadStepDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateLoadStepDataGridColumn';
import { RatePlanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RatePlanDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { sentenceCase } from 'change-case';

export const getRateLoadsColumns = (): Array<
	DataGridColumn<RateLoadIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'rateLoadId'
	}),
	IdDataGridColumn({
		accessorKey: 'rateSheetId',
		header: 'Rate sheet Id',
		isHidden: true
	}),
	RatePlanDataGridColumn({
		accessorKey: 'ratePlanId',
		header: 'Rate Plan'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	RateLoadStepDataGridColumn({
		accessorKey: 'rateLoadStepId',
		statusProperty: 'rateLoadStatusId',
		header: 'Status'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'notificationTs',
		header: 'Effective Time',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'effectiveTs',
		header: 'Notification'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'rateLoadTs',
		header: 'Last Updated'
	}),
	RateLoadExpireModeDataGridColumn({
		accessorKey: 'rateExpirationId',
		header: 'Rate Expiration'
	}),
	DefaultDataGridColumn({
		accessorKey: 'userEmail',
		header: 'Created By'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	DefaultDataGridColumn({
		accessorKey: 'uploadedFileName',
		header: 'Original File Name',
		isHidden: true
	})
];

export const getRateLoadNewRatesColumns = (): Array<
	DataGridColumn<RateLoadNewRateIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'useRateKey',
		header: 'Rate Key'
	}),
	DefaultDataGridColumn({
		accessorKey: 'useCountryCode',
		header: 'Country Code',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'useIso3',
		header: 'ISO',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'useRateCenterId',
		header: 'Rate Center Id',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'useSummary',
		header: 'Summary'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'srcStartTs',
		header: 'Src Start Ts'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'contractStartTs',
		header: 'Contract Start Ts',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'useStartTs',
		header: 'Start Ts'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'contractEndTs',
		header: 'Contract End Ts',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'useEndTs',
		header: 'End Ts',
		isHidden: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'useDurMin',
		header: 'Dur Min',
		isHidden: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'useDurInc',
		header: 'Dur Inc',
		isHidden: true
	}),
	RateDataGridColumn({
		accessorKey: 'srcInterRate',
		header: 'Inter Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'srcIntraRate',
		header: 'Intra Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'srcIndetRate',
		header: 'Indet Rate'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateKeyStateId',
		header: 'Rate Key State',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateChangeTypeId',
		header: 'Rate Change Type',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateChangeSummary',
		header: 'Rate Change Summary'
	}),
	DefaultDataGridColumn({
		header: 'Rate Load Error',
		accessorFn: (record) =>
			record.rateLoadErrorTypeId
				? sentenceCase(RateLoadErrorType[record.rateLoadErrorTypeId as any])
				: ''
	}),
	RateDataGridColumn({
		accessorKey: 'interDiffAmt',
		header: 'Inter Diff Amt'
	}),
	RateDataGridColumn({
		accessorKey: 'intraDiffAmt',
		header: 'Intra Diff Amt'
	}),
	RateDataGridColumn({
		accessorKey: 'indetDiffAmt',
		header: 'Indet Diff Amt'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'interDiffPct',
		header: 'Inter Diff Pct'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'intraDiffPct',
		header: 'Intra Diff Pct'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'indetDiffPct',
		header: 'Indet Diff Pct'
	}),
	DefaultDataGridColumn({
		accessorKey: 'srcRateKey',
		header: 'Src Rate Key',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'srcNpa',
		header: 'Src Npa',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'srcNxx',
		header: 'Src Nxx',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'srcLata',
		header: 'Src Lata',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'srcOcn',
		header: 'Src Ocn',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'srcState',
		header: 'Src State',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'srcSummary',
		header: 'Src Summary',
		isHidden: true
	}),
	RateDataGridColumn({
		accessorKey: 'srcDipFee',
		header: 'Src Dip Fee',
		isHidden: true
	}),
	RateDataGridColumn({
		accessorKey: 'srcConFee',
		header: 'Src Con Fee',
		isHidden: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'srcDurMin',
		header: 'Src Duration Min',
		isHidden: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'srcDurInc',
		header: 'Src Duration Inc',
		isHidden: true
	})
];

export const getRateLoadOldRatesColumns = (): Array<
	DataGridColumn<RateLoadOldRateIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'srcRateKey',
		header: 'Rate Key'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'srcStartTs',
		header: 'Old Start Ts',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'srcEndTs',
		header: 'Src End Ts'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'contractEndTs',
		header: 'Contract End Ts'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'useEndTs',
		header: 'End Ts'
	}),
	RateDataGridColumn({
		accessorKey: 'srcInterRate',
		header: 'Inter Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'srcIntraRate',
		header: 'Intra Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'srcIndetRate',
		header: 'Indet Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'srcDipFee',
		header: 'Dip Fee',
		isHidden: true
	}),
	RateDataGridColumn({
		accessorKey: 'srcConFee',
		header: 'Con Fee',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateKeyStateId',
		header: 'Rate Key State',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateChangeTypeId',
		header: 'Rate Change Type',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'rateChangeSummary',
		header: 'Rate Change Summary'
	}),
	DefaultDataGridColumn({
		header: 'Rate Load Error',
		accessorFn: (record) =>
			record.rateLoadErrorTypeId
				? sentenceCase(RateLoadErrorType[record.rateLoadErrorTypeId as any])
				: ''
	})
];
