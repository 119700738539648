/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtVueApiRoutes } from 'RtExports/routes';
import { RoboscoreParameterIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchRoboscoreConfigurations = async (): Promise<
	FullResponse<RoboscoreParameterIndexResponse[]>
> => {
	return handleGetRequest(RtVueApiRoutes.RoboscoreParameters.Index, {
		includeFullResponse: true
	});
};

export const useGetRoboscoreConfigurations = () => {
	return useQuery<FullResponse<RoboscoreParameterIndexResponse[]>, Error>(
		'getRoboscoreConfigurations',
		() => {
			return fetchRoboscoreConfigurations();
		}
	);
};
