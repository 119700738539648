import { createRef, HTMLAttributes, PureComponent } from 'react';

interface IOutsideClickDetector extends HTMLAttributes<HTMLDivElement> {
	onClickOutside: () => void;
}

export default class OutsideClickDetector extends PureComponent<IOutsideClickDetector> {
	public wrapperRef = createRef<HTMLDivElement>();

	public componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	public componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	public handleClickOutside = (event: MouseEvent) => {
		if (
			this.wrapperRef &&
			!this.wrapperRef.current?.contains(event.target as any)
		) {
			this.props.onClickOutside();
		}
	};

	public render() {
		const { onClickOutside, ...restProps } = this.props;
		return (
			<div {...restProps} ref={this.wrapperRef}>
				{this.props.children}
			</div>
		);
	}
}
