/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SearchField = {
    properties: {
        key: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        direction: {
            type: 'CallDirectionPreset',
            isRequired: true,
        },
        minCharacters: {
            type: 'number',
            isRequired: true,
        },
        dataSources: {
            type: 'Array',
            isRequired: true,
        },
        operator: {
            type: 'DataFilterOperator',
            isRequired: true,
        },
        min: {
            type: 'number',
        },
        max: {
            type: 'number',
        },
        hiddenFromUser: {
            type: 'boolean',
        },
        advanced: {
            type: 'boolean',
        },
        allowedOperators: {
            type: 'Array',
        },
    },
};