import { union } from 'lodash-es';
import * as React from 'react';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
import { CarrierSelect, ICicIndexResponse } from '../controls/CarrierSelect';

interface IBulkCarrierChangeFormProps {
	mgiProfile: Cpr;
	oldCarrier: ICicIndexResponse | undefined;
	newCarrier: ICicIndexResponse | undefined;
	onChange: (
		oldCarrier: ICicIndexResponse | undefined,
		newCarrier: ICicIndexResponse | undefined
	) => void;
}

interface IBulkCarrierChangeFormState {
	isSubmitting: boolean;
	oldCics?: string[];
}

export class BulkCarrierChangeForm extends React.Component<
	IBulkCarrierChangeFormProps,
	IBulkCarrierChangeFormState
> {
	public state: IBulkCarrierChangeFormState = {
		isSubmitting: false
	};

	public componentDidMount() {
		this.initOldCics();
	}

	public initOldCics() {
		const { mgiProfile } = this.props;
		const interLataCarriers = mgiProfile.getInterLataCarriers();
		const intraLataCarriers = mgiProfile.getIntraLataCarriers();

		const oldCics = union(interLataCarriers, intraLataCarriers);

		if (oldCics.length > 0) {
			this.setState({ oldCics });
		}
	}

	public render() {
		return (
			<section className="d-lg-flex justify-content-between">
				<article className="flex-fill me-5">
					<CarrierSelect
						label="Old Carrier"
						required
						cics={this.state.oldCics}
						onChange={(oldCarrier) =>
							this.props.onChange(oldCarrier, this.props.newCarrier)
						}
						value={this.props.oldCarrier}
					/>
				</article>
				<article className="flex-fill">
					<CarrierSelect
						label="New Carrier"
						required
						onChange={(newCarrier) =>
							this.props.onChange(this.props.oldCarrier, newCarrier)
						}
						value={this.props.newCarrier}
					/>
				</article>
			</section>
		);
	}
}
