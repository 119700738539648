import {
	CacheStrategy,
	TCacheStrategySearchCriteria
} from 'RtUi/utils/cache/CacheStrategy';

/**
 * CacheStrategy for memory usage
 */
export class MemoryCacheStrategy<T> extends CacheStrategy<T> {
	//In memory storage
	private records: T[] = [];

	/**
	 * Clear cache from memory
	 */
	protected async invalidateCacheInternal(): Promise<boolean> {
		this.records = [];

		return true;
	}

	/**
	 * get all cache
	 */
	protected async getAllInternal() {
		return this.records;
	}

	/**
	 * Uses standard search
	 * @param criteria
	 */
	protected async searchInternal(
		records: T[],
		criteria: Array<TCacheStrategySearchCriteria<T>>
	): Promise<T[]> {
		return this.standardSearch(records, criteria);
	}

	/**
	 * Store records in memory
	 * @param records
	 */
	protected async storeCacheInternal(records: T[]): Promise<boolean> {
		try {
			this.records = records;
		} catch {
			return false;
		}

		return true;
	}
}
