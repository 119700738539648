import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class CprLblAreaCode extends CprLbl {

	public readonly cprNodeTypeId = CprNodeType.AreaCode;

	public readonly valueLimit = 255;
	public readonly valueRegExp = /^\d{3}$/;

	protected readonly possibles = CprLergCache.Npas;
	protected readonly possiblesByKey = CprLergCache.CprLergByNpa;

	protected readonly canadaPossibles = CprLergCache.CanadaNpas;
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaNpasByKey;

}
