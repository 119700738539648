import { LcrTemplateIndexRequest, LcrTemplateIndexResponse } from 'RtModels';
import { useGetLcrTemplates } from 'RtUi/app/rtLco/Templates/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLcrTemplatesColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/lcrTemplates';
import { useMemo } from 'react';
import { LcrTemplateRouter } from '../../LcrTemplate.router';

interface ILcrTemplatesGridProps {
	autoFocusFilter?: boolean;
	resourceParams?: LcrTemplateIndexRequest;
}

export const LcrTemplatesGrid = ({
	resourceParams
}: ILcrTemplatesGridProps) => {
	const { data, isFetching: isLoading } = useGetLcrTemplates(resourceParams);
	const columns = useMemo(() => getLcrTemplatesColumns(), []);

	return (
		<DataGrid<LcrTemplateIndexResponse>
			pageName="rtLco-lcrTemplates"
			loading={isLoading}
			data={data?.data}
			router={LcrTemplateRouter}
			totalRows={data?.totalCount}
			columns={columns}
		/>
	);
};
