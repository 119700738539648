/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrCarrierIndexResponse = {
    properties: {
        lcrCarrierId: {
            type: 'number',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        cic: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        allowAsDefault: {
            type: 'number',
            isRequired: true,
        },
        allowAsCanada: {
            type: 'number',
            isRequired: true,
        },
        defaultNpa: {
            type: 'string',
            isRequired: true,
        },
        defaultNxx: {
            type: 'string',
            isRequired: true,
        },
        defaultInterRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIntraRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIndetRate: {
            type: 'number',
            isRequired: true,
        },
        rateSheetTypeId: {
            type: 'number',
            isRequired: true,
        },
        createdAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        lastRatesUploadTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        lastRatesUploadedEmail: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
    },
};