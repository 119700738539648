import { CprStatus } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { scpStatusIdToName } from 'RtUi/utils/maps';

interface SomosScpStatusDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const SomosScpStatusDataGridColumn = <T = any,>({
	...config
}: SomosScpStatusDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: CprStatus) => {
		return scpStatusIdToName(value) ?? '';
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: CprStatus) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<CprStatus>())
	});
};
