import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { Product, ProductId } from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { find } from 'lodash-es';

export class ProductResource extends ArrayResource<Product> {
	constructor() {
		super('productId');

		this.setApiRouteForGetAll(RtCommonApiRoutes.Products.Index);
	}

	public getProductName(
		products: readonly Product[],
		productId: ProductId | null
	): string {
		const product = find(products, ['productId', productId]);
		return product ? product.summary : 'Profile';
	}
}
