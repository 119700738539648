/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianServiceNumberSubscriptionWithGlobalData = {
    properties: {
        guardianServiceNumberSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        serviceNumber: {
            type: 'string',
            isRequired: true,
        },
        originationNumberId: {
            type: 'number',
            isNullable: true,
        },
        subscriptionId: {
            type: 'number',
            isNullable: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        serviceTypeId: {
            type: 'number',
            isRequired: true,
        },
        directionId: {
            type: 'ServiceNumberLookUpType',
            isRequired: true,
        },
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        updatedBy: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isNullable: true,
        },
        scenarioId: {
            type: 'number',
            isNullable: true,
        },
        translatedNumberStd: {
            type: 'string',
            isNullable: true,
        },
        isPorted: {
            type: 'number',
            isRequired: true,
        },
        portingStatusId: {
            type: 'number',
            isNullable: true,
        },
        pin: {
            type: 'string',
            isNullable: true,
        },
        notes: {
            type: 'string',
            isNullable: true,
        },
        assignedTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        portedTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        activatedTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        decommissionedTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        cnamReg: {
            type: 'number',
            isRequired: true,
        },
        cnamDip: {
            type: 'number',
            isRequired: true,
        },
        isDirAsst: {
            type: 'number',
            isRequired: true,
        },
        dirAsstName: {
            type: 'string',
            isNullable: true,
        },
        dirAsstAddress1: {
            type: 'string',
            isNullable: true,
        },
        dirAsstAddress2: {
            type: 'string',
            isNullable: true,
        },
        dirAsstCity: {
            type: 'string',
            isNullable: true,
        },
        dirAsstState: {
            type: 'string',
            isNullable: true,
        },
        dirAsstZip: {
            type: 'string',
            isNullable: true,
        },
        isE911: {
            type: 'number',
            isRequired: true,
        },
        e911Name: {
            type: 'string',
            isNullable: true,
        },
        e911Address1: {
            type: 'string',
            isNullable: true,
        },
        e911Address2: {
            type: 'string',
            isNullable: true,
        },
        e911City: {
            type: 'string',
            isNullable: true,
        },
        e911State: {
            type: 'string',
            isNullable: true,
        },
        e911Zip: {
            type: 'string',
            isNullable: true,
        },
        custOrderNumber: {
            type: 'string',
            isNullable: true,
        },
        custExtRef1: {
            type: 'string',
            isNullable: true,
        },
        custExtRef2: {
            type: 'string',
            isNullable: true,
        },
        custExtRef3: {
            type: 'string',
            isNullable: true,
        },
        vendExtRef1: {
            type: 'string',
            isNullable: true,
        },
        vendExtRef2: {
            type: 'string',
            isNullable: true,
        },
        filename: {
            type: 'string',
        },
        deletedTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
    },
};