import NumericInput from 'react-numeric-input';
import { ControlGroup } from '../../ui/ControlGroup';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from '../FormControl';
import clsx from 'clsx';

export type PrecisionFormControlTypes = number | null;

export interface IPrecisionFormControlProps
	extends IFormControlProps<PrecisionFormControlTypes> {
	label?: string;
	disabled?: boolean;
	readOnly?: boolean;
	defaultValue?: number;
	max?: number;
	min?: number;
	step?: number;
	precision?: number;
	hideLabel?: boolean;
	allowCurrency?: boolean;
	hideFormGroup?: boolean;
	onChangeExtended?: (
		value: PrecisionFormControlTypes,
		strValue: string,
		inputElement: HTMLInputElement
	) => void;
	format?: (value: PrecisionFormControlTypes) => string;
	errorText?: string;
	readonlyFormat?: (value: string) => string;
}

interface IPrecisionFormControlState extends IFormControlState {}

export class PrecisionFormControl extends FormControl<
	PrecisionFormControlTypes,
	false,
	IPrecisionFormControlProps,
	IPrecisionFormControlState
> {
	public static ToPrecisionLocaleString(value: number, precision: number = 0) {
		let numStr = value.toFixed(precision);

		const decimalPointIndex = numStr.indexOf('.');

		if (decimalPointIndex > 0) {
			const numberBeforeDecimalStr = numStr.substring(0, decimalPointIndex);
			const numberBeforeDecimal = Number(numberBeforeDecimalStr);
			const numberAfterDecimalStr = numStr.substring(decimalPointIndex);

			if (!isNaN(numberBeforeDecimal)) {
				const beforeDecimalLocale = numberBeforeDecimal.toLocaleString();

				numStr = beforeDecimalLocale + numberAfterDecimalStr;
			}
		} else {
			numStr = Number(numStr).toLocaleString();
		}

		return numStr;
	}

	public resource = undefined;
	public state: IPrecisionFormControlState = {
		formLabel: ''
	};

	public componentDidMount() {
		super.componentDidMount();
		const { value, defaultValue, onChange } = this.props;

		if (!value && defaultValue && onChange) {
			onChange(defaultValue);
		}
	}

	public removeNonNumericCharacters(str: string) {
		return str.replace(/[^0-9]/g, '');
	}

	public triggerOnChangeEvents(
		value: PrecisionFormControlTypes,
		strValue: string,
		inputElement: HTMLInputElement
	) {
		const noop = () => ({});
		const { onChange = noop, onChangeExtended = noop } = this.props;

		onChange(value);
		onChangeExtended(value, strValue, inputElement);
		this.clearCurrentFormErrors();
	}

	public handleOnInput(evt: React.FormEvent<HTMLInputElement>) {
		const { onChange = () => {}, allowCurrency } = this.props;
		if (allowCurrency) {
			onChange(this.parseCurrency(evt.currentTarget.value));
		}
	}

	public render() {
		const {
			className = '',
			displayMode,
			name,
			readonlyFormat,
			hideLabel,
			hideFormGroup,
			precision
		} = this.props;
		let { value } = this.props;
		let valueStr = '';
		const errorText = this.getCurrentFormErrors()?.join(', ');
		const { formLabel } = this.state;
		const inputValue = typeof value === 'number' ? value : 0;

		if (value === null) {
			value = 0;
		}

		if (displayMode) {
			valueStr = PrecisionFormControl.ToPrecisionLocaleString(
				Number(value),
				precision
			);
			if (readonlyFormat) {
				valueStr = readonlyFormat(valueStr);
			}
		}

		return (
			<ControlGroup
				label={formLabel}
				value={valueStr}
				displayMode={displayMode}
				required={this.props.required}
				errorText={errorText}
				hideLabel={hideLabel}
				hideFormGroup={hideFormGroup}
			>
				<NumericInput
					className={clsx(className, 'form-control text-monospace', {
						'is-invalid': name && Boolean(errorText)
					})}
					style={false}
					disabled={this.props.disabled}
					name={name}
					strict
					readOnly={this.props.readOnly}
					required={this.props.required}
					max={this.props.max}
					min={this.props.min}
					step={this.props.step}
					format={this.props.format}
					precision={this.props.precision}
					onInput={this.handleOnInput.bind(this)}
					onChange={(newValue, strValue, inputElement) =>
						this.triggerOnChangeEvents(newValue, strValue, inputElement)
					}
					onKeyDown={(evt) => {
						//If a user types in a period, move their cursor to after the period
						//for ease of use
						if (
							evt.code.toLowerCase() !== 'period' ||
							!(evt.target instanceof HTMLInputElement)
						) {
							return;
						}

						evt.preventDefault();
						evt.stopPropagation();

						const currentValue = evt.target.value;
						const dotIndex = currentValue.indexOf('.');

						if (dotIndex >= 0) {
							evt.target.selectionStart = dotIndex + 1;
							evt.target.selectionEnd = dotIndex + 1;
						}
					}}
					value={inputValue}
				/>
			</ControlGroup>
		);
	}

	private parseCurrency(originalValue: string) {
		const match = /^\$?(\d+(?:\.\d+)*(?:\,\d+)?)\$?$/g.exec(originalValue);
		let value = match && match[1];

		if (!value) {
			return Number(originalValue);
		}

		const validators = [
			/^(?:\d{1,3})(?:\.\d{3}?)+$/g,
			/^\d+,\d+$/g,
			/^(?:\d{1,3}\.?)+,\d+$/g
		];

		if (validators.some((v) => value?.match(v))) {
			value = value.replace('.', '').replace(',', '.');
		}

		return Number(value);
	}
}
