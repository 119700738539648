import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { TrendAnalysisIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface ITrendAnalysisRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const TrendAnalysisContainerTabs: ITrendAnalysisRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class TrendAnalysisRouterClass extends SipApplicationRouter<
	TrendAnalysisIndexResponse,
	TrendAnalysisIndexResponse,
	ITrendAnalysisRouterTabs
> {
	constructor() {
		super(
			'Trend Analysis',
			'trendAnalysis',
			'revenueAccountId',
			TrendAnalysisContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.TrendAnalysis);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const TrendAnalysisRouter = new TrendAnalysisRouterClass();
