import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { CdrRecurringExportRouter } from 'RtUi/app/Administration/CdrRecurringExport/CdrRecurringExport.router';
import { DistributionManagementRouter } from 'RtUi/app/Administration/DistributionManagement/DistributionManagement.router';
import { EmailAliasEditor } from 'RtUi/app/Administration/DistributionManagement/lib/forms/EmailAliasEditor';
import { EmailAliasGrid } from 'RtUi/app/Administration/DistributionManagement/lib/grids/EmailAliasGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const DistributionManagementIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		EmailAliases: { header: 'Email Aliases' },
		CreateEmailAlias: {
			header: 'Create Email Alias',
			isAction: true
		}
	});

	return (
		<TabbedLayout
			router={CdrRecurringExportRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.EmailAliases}>
				<EmailAliasGrid />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateEmailAlias}>
				<EmailAliasEditor
					createMode
					onUpdate={(emailAliasProfile) =>
						BrowserHistory.push(
							DistributionManagementRouter.getProfileRoute(emailAliasProfile)
						)
					}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

DistributionManagementRouter.setIndexRtUiFunctionalComponent(
	DistributionManagementIndexContainer
);
