import * as React from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import clsx from 'clsx';
import { generateShortUUID } from 'RtUi/utils/http/resources/utils';

interface IDashboardStatProps extends React.HTMLAttributes<HTMLDivElement> {
	header: string;
	stat: string | JSX.Element;
	tooltip?: string;
	disableValueWrap?: boolean;
	statColor?: string;
	icon?: string;
	iconColor?: string;
	iconSpin?: boolean;
	showAs?: 'danger' | 'warning';
	onClick?: () => void;
}

export class DashboardStat extends React.Component<IDashboardStatProps, {}> {
	public uuid = generateShortUUID().replace(/\D/g, '');
	public render() {
		const {
			icon,
			statColor,
			showAs,
			iconColor = 'muted',
			iconSpin = false
		} = this.props;
		const tooltipId = `DashStat${this.uuid}`;

		const content = (
			<Card
				className={clsx(this.props.className, {
					'shadow-on-hover': this.props.onClick
				})}
			>
				<Card.Body className={clsx('rounded', showAs && `card-body-${showAs}`)}>
					<section
						className={clsx(
							'd-flex justify-content-between align-items-center',
							{
								'no-wrap': this.props.disableValueWrap,
								'clickable': this.props.onClick
							}
						)}
						onClick={this.props.onClick}
					>
						<header>
							<h6
								className="mb-1 text-muted small"
								style={{ textTransform: 'uppercase' }}
							>
								{this.props.header}
							</h6>
							<h5
								className={clsx(['mb-0', statColor && `text-${statColor}`])}
								style={{
									fontWeight: 700
								}}
							>
								{this.props.stat}
							</h5>
						</header>
						{icon && (
							<footer>
								<i
									className={clsx(
										'fas',
										'fa-fw',
										'fa-lg',
										`text-${iconColor}`,
										`fa-${icon}`,
										{ 'fa-spin': iconSpin }
									)}
								/>
							</footer>
						)}
					</section>
				</Card.Body>
			</Card>
		);

		if (!this.props.tooltip) {
			return content;
		}

		return (
			<OverlayTrigger
				overlay={(props) => (
					<Tooltip id={`${tooltipId}-tooltip`} {...props}>
						{this.props.tooltip}
					</Tooltip>
				)}
			>
				{({ ref, ...triggerHandler }) => (
					<article ref={ref} {...triggerHandler} id={tooltipId}>
						{content}
					</article>
				)}
			</OverlayTrigger>
		);
	}
}
