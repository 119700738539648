import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { RouterBreadcrumbs } from 'RtUi/components/containers/lib/RouterBreadcrumbs';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';

export interface IStandardLayoutProps<ProfileType> {
	router: RtUiRouter<any, ProfileType>;
	profile?: ProfileType;
	rightOfHeader?: () => React.ReactNode;
	appendToHeader?: React.ReactNode;
	noBreadCrumbs?: Boolean;
}

export function StandardLayout<ProfileType = any>({
	router,
	profile,
	rightOfHeader,
	appendToHeader,
	children,
	noBreadCrumbs
}: PropsWithChildren<IStandardLayoutProps<ProfileType>>) {
	const currentPath = location.pathname; //asdf

	return (
		<section className="page">
			{!noBreadCrumbs && (
				<header
					style={{ position: 'relative' }}
					className={clsx('d-flex justify-content-between', {
						'mb-4': !appendToHeader
					})}
				>
					<section key={currentPath}>
						<RouterBreadcrumbs
							currentPath={currentPath}
							router={router}
							profile={profile}
						/>
					</section>
					<section className="align-self-center">
						{rightOfHeader && rightOfHeader()}
					</section>
				</header>
			)}

			{appendToHeader}
			<section className="animate__animated animate__fadeIn">
				{children}
			</section>
		</section>
	);
}
