import { RoutingCacheTypes } from 'RtModels';
import { $enum } from 'ts-enum-util';

export const getRoutingCacheTypeName = (
	type: RoutingCacheTypes | undefined
): string => {
	return $enum.mapValue(type).with({
		[RoutingCacheTypes.Template]: 'Template',
		[RoutingCacheTypes.TollFree]: 'Toll-Free Number',
		[RoutingCacheTypes.LcrGenerator]: 'LCO Task Result',
		[RoutingCacheTypes.MgiTask]: 'MGI Task',
		[$enum.handleUndefined]: 'Unspecified'
	});
};
