import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DefaultDataGridColumn,
	DataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { get, isObject } from 'lodash-es';

interface JSONDataGridConfiguration<T> extends DataGridColumnConfiguration<T> {}

const buildListItem = (value: any, key?: string): JSX.Element => {
	return (
		<li>
			{key && <b>{key}:&nbsp;</b>}
			{value}
		</li>
	);
};

const buildListItems = (value: any, key?: string): JSX.Element => {
	if (isObject(value)) {
		return (
			<ul className="ps-3">
				{key && <h3>{key}</h3>}
				{Object.keys(value).map((subKey) =>
					buildListItems(subKey, get(value, subKey))
				)}
			</ul>
		);
	}

	if (Array.isArray(value)) {
		return (
			<ul className="ps-3">
				{key && <h3>{key}</h3>}
				{value.map((value) => buildListItems(value))}
			</ul>
		);
	}

	return buildListItem(value, key);
};

const buildList = (value: any): JSX.Element => {
	const listItems = Object.keys(value).map((key) =>
		buildListItems(get(value, key), key)
	);
	return <ul className="ps-3">{listItems}</ul>;
};

const getDisplayValue = (value: string) => {
	try {
		const data = JSON.parse(value);
		return data;
	} catch (e) {
		return '';
	}
};

export const JSONDataGridColumn = <T = any,>(
	config: JSONDataGridConfiguration<T>
): DataGridColumn<T> => {
	return DefaultDataGridColumn({
		...config,
		getValue: ({ cell }) =>
			buildList(getDisplayValue(cell.getValue() as string))
	});
};
