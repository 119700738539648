/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ResourceNumber = {
    properties: {
        resourceNumberId: {
            type: 'string',
            isRequired: true,
        },
        resourceNumberStd: {
            type: 'string',
            isRequired: true,
        },
        country: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        rateCenter: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        resourceNumberType: {
            type: 'number',
            isRequired: true,
        },
        carrierId: {
            type: 'number',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        profiledTs: {
            type: 'number',
            isRequired: true,
        },
        resConnectionId: {
            type: 'number',
            isRequired: true,
        },
        resTranslatedNumber: {
            type: 'number',
            isRequired: true,
        },
        resAccountId: {
            type: 'number',
            isRequired: true,
        },
        resOrderId: {
            type: 'string',
            isRequired: true,
        },
        resRef1: {
            type: 'string',
            isRequired: true,
        },
        resRef2: {
            type: 'string',
            isRequired: true,
        },
        resStatus: {
            type: 'string',
            isRequired: true,
        },
        resCreatedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        resUpdatedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        resDeletedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        recurringAmount: {
            type: 'number',
            isRequired: true,
        },
        nonRecurringAmount: {
            type: 'number',
            isRequired: true,
        },
        createdAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        deletedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        indexRef: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};