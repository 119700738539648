import { cloneDeep, padStart } from 'lodash-es';
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { DropdownOnBody } from 'RtUi/components/ui/DropdownOnBody';
import { MultiDataList } from 'RtUi/components/ui/MultiDataList';
import { InputFormControl } from '../InputFormControl';
//import { SomosTimeRangeTypes } from 'RtUi/components/form/TimeRangeFormControl/interfaces';

interface ITimeRangeFormControlProps {
	value: string[];
	onChange: (newValue: string[]) => void;
	className?: string;
	label?: string;
	displayMode?: boolean;
	required?: boolean;
}

interface ITimeRangeFormControlState {
	isOpen: boolean;
}

export class TimeRangeFormControl extends React.Component<
	ITimeRangeFormControlProps,
	ITimeRangeFormControlState
> {
	public state: ITimeRangeFormControlState = {
		isOpen: false
	};

	public selectContainerRef: HTMLElement | null = null;

	public toggleIsOpen(isOpen = !this.state.isOpen) {
		this.setState({ isOpen });
	}

	public updateFromTime(newFromTime: string, index: number) {
		const newValue = this.props.value ? cloneDeep(this.props.value) : [];

		const valueToUpdate = newValue[index];
		const valueParts = valueToUpdate.split('-');
		valueParts[0] = this.militaryToSomos(newFromTime);

		if (valueParts[0] === valueParts[1]) {
			newValue[index] = newFromTime;
		} else {
			newValue[index] = valueParts.join('-');
		}

		this.props.onChange(newValue);
	}

	public updateToTime(newToTime: string, index: number) {
		const newValue = this.props.value ? cloneDeep(this.props.value) : [];

		const valueToUpdate = newValue[index];
		const valueParts = valueToUpdate.split('-');
		valueParts[1] = this.militaryToSomos(newToTime);

		if (valueParts[0] === valueParts[1]) {
			newValue[index] = newToTime;
		} else {
			newValue[index] = valueParts.join('-');
		}

		this.props.onChange(newValue);
	}

	public deleteTimeRange(index: number) {
		const newValue = this.props.value ? cloneDeep(this.props.value) : [];

		if (index in newValue) {
			newValue.splice(index, 1);
		}

		this.props.onChange(newValue);
	}

	public militaryToSomos(militaryTime: string) {
		const timeParts = militaryTime.split(':');
		const minutes = Number(timeParts[1]);
		let hours = Number(timeParts[0]);
		let amOrPm: 'A' | 'P' = 'A';

		if (hours > 12) {
			amOrPm = 'P';
			hours = hours - 12;
		}

		return `${this.padTime(hours)}:${this.padTime(minutes)}${amOrPm}`;
	}

	public padTime(minutesOrHours: number) {
		const str = String(minutesOrHours);

		return padStart(str, 2, '0');
	}

	public somosToMilitary(somosTime: string) {
		const amOrPm = somosTime.substr(-1, 1) as 'A' | 'P';
		const somosTimeWithoutAmPm = somosTime.substr(0, somosTime.length - 1);
		const timeParts = somosTimeWithoutAmPm.split(':');
		const minutes = Number(timeParts[1]);
		let hours = Number(timeParts[0]);

		if (amOrPm === 'P') {
			hours = hours + 12;
		}

		return `${this.padTime(hours)}:${this.padTime(minutes)}`;
	}

	public addTimeRange() {
		let newValue = this.props.value ? cloneDeep(this.props.value) : [];

		//Remove other values
		newValue = newValue.filter((val) => val !== 'OTHER');

		newValue.push('01:00A');

		this.props.onChange(newValue);
	}

	public setValueToOther() {
		this.props.onChange(['OTHER']);
	}

	public render() {
		const { displayMode, value = [] } = this.props;

		return (
			<>
				<MultiDataList
					displayMode={displayMode}
					values={value}
					disableNoSuggestionsPrompt
					className={this.props.className}
					suggestions={[]}
					onChange={(changedValue) => {
						this.props.onChange(changedValue);
					}}
					ref={(selectContainerRef) =>
						(this.selectContainerRef = selectContainerRef)
					}
					onFocus={(ev) => {
						ev.currentTarget.blur();
						this.toggleIsOpen(true);
					}}
				/>
				{this.selectContainerRef && (
					<DropdownOnBody
						toggle={() => this.toggleIsOpen()}
						isOpen={this.state.isOpen}
						selectDOM={this.selectContainerRef}
					>
						<section style={{ minWidth: 350 }}>
							{value.map((timeRange, index) => {
								if (timeRange === 'OTHER') {
									return null;
								}
								const timeParts = timeRange.split('-');
								const fromMilitaryTime = this.somosToMilitary(timeParts[0]);
								const toMilitaryTime = timeParts[1]
									? this.somosToMilitary(timeParts[1])
									: fromMilitaryTime;

								return (
									<Form.Group
										className="d-md-flex justify-content-between align-items-center mb-3"
										key={index}
									>
										<article className="me-2">
											{this.state.isOpen && (
												<InputFormControl
													type="time"
													label="Start Time"
													autoFocus
													useControlGroup={false}
													onChange={(newFromTime) =>
														this.updateFromTime(newFromTime, index)
													}
													value={fromMilitaryTime}
												/>
											)}
										</article>
										<article className="me-2">
											<i className="fas fa-fw fa-arrow-right" />
										</article>
										<article className="me-2">
											<InputFormControl
												type="time"
												label="End Time"
												useControlGroup={false}
												onChange={(newToTime) =>
													this.updateToTime(newToTime, index)
												}
												value={toMilitaryTime}
											/>
										</article>
										<article>
											<Button
												variant="light"
												onClick={() => this.deleteTimeRange(index)}
											>
												<i className="fas fa-fw fa-trash-alt" />
											</Button>
										</article>
									</Form.Group>
								);
							})}
							<article>
								<Button
									variant="light"
									onClick={() => this.addTimeRange()}
									disabled={value.length >= 1 && value[0] !== 'OTHER'}
								>
									<i className="fas fa-fw fa-plus" />
									<span>&nbsp;Add Time Range</span>
								</Button>
								<Button
									variant="light"
									className="ms-2"
									onClick={() => this.setValueToOther()}
									disabled={value[0] === 'OTHER'}
								>
									<i className="fas fa-fw fa-plus" />
									<span>&nbsp;Set to OTHER</span>
								</Button>
							</article>
						</section>
					</DropdownOnBody>
				)}
			</>
		);
	}
}
