/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CdrExportTypes {
    EgressCost = 1,
    EgressRevenue = 2,
    IngressCost = 3,
    IngressRevenue = 4,
    PwmSharedUsage = 5,
    CustOrigEgressRevenue = 6,
    FullExport = 7,
    Invoice = 8,
    VueFullExport = 9,
    VueBillingCycleExport = 10,
    RevIoIngressRevenue = 11,
    RevIoEgressRevenue = 12,
    TwilioReExport = 13,
    NcPgCustom1 = 14,
    ReUploader = 15,
    BandwidthBillingDownloader = 16,
}