/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum GuardianReconciliationNoteTypes {
    Parent = 'parent',
    Charge = 'charge',
    Usage = 'usage',
}