/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	CountryDialCodeIndexResponse,
	CountryIndexRequest,
	CountryIndexResponse
} from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';

export const fetchCountries = async (
	queryParams?: CountryIndexRequest
): Promise<CountryIndexResponse[]> => {
	return handleGetRequest<CountryIndexResponse[], false>(
		RtCommonApiRoutes.Countries.Index,
		{
			queryParams
		}
	);
};

export const useGetCountries = (queryParams?: CountryIndexRequest) => {
	return useQuery<CountryIndexResponse[], Error>(
		[`useGetCountries`, queryParams],
		() => fetchCountries(queryParams)
	);
};

export const fetchCountriesDialCodes = async (): Promise<
	CountryDialCodeIndexResponse[]
> => {
	return handleGetRequest<CountryDialCodeIndexResponse[], false>(
		RtCommonApiRoutes.CountryDialCodes.Index
	);
};

export const useGetCountriesDialCodes = () => {
	return useQuery<CountryDialCodeIndexResponse[], Error>(
		[`useGetCountriesDialCodes`],
		() => fetchCountriesDialCodes()
	);
};
