import Dexie from 'dexie';
import { difference, uniqBy } from 'lodash-es';
import * as moment from 'moment-timezone';
import {
	CacheStrategy,
	TCacheStrategySearchCriteria
} from 'RtUi/utils/cache/CacheStrategy';

export class IndexedDbCacheStrategy<T> extends CacheStrategy<T> {
	protected indexedDb!: Dexie;
	protected indexedTable!: Dexie.Table<T, number>;

	constructor(
		protected dbName: string,
		protected primaryKey: keyof T,
		protected secondaryKeys: Array<keyof T> = [],
		protected migrate: (indexedDb: Dexie, storeSchema: string[]) => void = () =>
			null
	) {
		super(moment.duration(1, 'month'), dbName);

		this.indexedDb = new Dexie(`IndexedDbCacheStrategyDb-${this.dbName}`);
		let storeSchema = String(this.primaryKey);

		if (this.secondaryKeys.length > 0) {
			storeSchema += ',' + this.secondaryKeys.join(',');
		}

		this.indexedDb.version(1).stores({
			[this.dbName]: storeSchema
		});

		migrate(this.indexedDb, storeSchema.split(','));

		this.indexedDb.open().catch((err) => {
			console.error(err);
		});

		this.indexedTable = this.indexedDb.table<T, number>(this.dbName);
	}

	/**
	 * Search through records
	 * @override
	 * @param criteria
	 */
	public async search(
		criteria: Array<TCacheStrategySearchCriteria<T>>
	): Promise<T[]> {
		if (criteria.length === 0) {
			return [];
		}

		let criteriaToSearchAgainst = this.sortCriteriaBasedOnBestSearch(criteria);
		const indexedKeys = [this.primaryKey, ...this.secondaryKeys];
		let foundRecords: T[] = [];
		let wasAbleToUseIndexedDbSearch = false;

		//Criteria with indexed keys or no keys can be passed to DB
		const dbSearchCriteria = criteriaToSearchAgainst.filter(
			(c) => !c.keyToSearchAgainst || indexedKeys.includes(c.keyToSearchAgainst)
		);
		criteriaToSearchAgainst = difference(
			criteriaToSearchAgainst,
			dbSearchCriteria
		);

		// Do multiple searches with criteria that is index-able
		for (const dbSearchSingleCriteria of dbSearchCriteria) {
			const { keyToSearchAgainst } = dbSearchSingleCriteria;
			const whereClausesIndexes = keyToSearchAgainst
				? [keyToSearchAgainst]
				: [...indexedKeys];

			for (const whereClausesKey of whereClausesIndexes) {
				let whereClausesIndex = whereClausesKey.toString();
				const multiEntrySyntax = '*';
				let isMultiEntry = false;

				if (whereClausesIndex.startsWith(multiEntrySyntax)) {
					//remove multiEntrySyntax
					isMultiEntry = true;
					whereClausesIndex = whereClausesIndex.substr(1);
				}

				const whereClause = this.indexedTable.where(String(whereClausesIndex));
				let searchCollection: Dexie.Collection<T, number> | undefined;

				if (dbSearchSingleCriteria.exact) {
					searchCollection = whereClause.equals(dbSearchSingleCriteria.exact);
				} else if (dbSearchSingleCriteria.like) {
					searchCollection = whereClause.startsWithIgnoreCase(
						dbSearchSingleCriteria.like
					);
				} else if (typeof dbSearchSingleCriteria.lessThan === 'number') {
					searchCollection = whereClause.below(dbSearchSingleCriteria.lessThan);
				} else if (typeof dbSearchSingleCriteria.moreThan === 'number') {
					searchCollection = whereClause.above(dbSearchSingleCriteria.moreThan);
				}

				if (searchCollection) {
					if (isMultiEntry) {
						searchCollection = searchCollection.distinct();
					}

					const newRecords = await searchCollection.toArray();

					foundRecords.push(...newRecords);

					wasAbleToUseIndexedDbSearch = true;
				}
			}
		}

		//If unable to preform search, return empty results
		if (!wasAbleToUseIndexedDbSearch) {
			console.error('Unable to perform IndexedDb search.');

			return [];
		}

		//Make sure there are no dupes
		foundRecords = uniqBy(foundRecords, (r) => r[this.primaryKey]);

		return this.standardSearch(foundRecords, criteriaToSearchAgainst);
	}

	protected async invalidateCacheInternal(): Promise<boolean> {
		try {
			await this.indexedTable.clear();
		} catch {
			return false;
		}

		return true;
	}

	protected searchInternal(
		records: T[],
		criteria: Array<TCacheStrategySearchCriteria<T>>
	): Promise<T[]> {
		throw new Error('Unused method. Use search()');
	}

	protected async getAllInternal(): Promise<T[]> {
		const records = await this.indexedTable.toArray();

		return records;
	}

	protected async storeCacheInternal(records: T[]): Promise<boolean> {
		try {
			const beforeUnloadListener = (evt: BeforeUnloadEvent) => {
				// Cancel the event as stated by the standard.
				evt.preventDefault();

				alert('Changes you made may not be saved.');

				// Chrome requires returnValue to be set.
				evt.returnValue = '';
			};

			window.addEventListener('beforeunload', beforeUnloadListener);

			await this.indexedTable.bulkPut(records);

			window.removeEventListener('beforeunload', beforeUnloadListener);
		} catch (err) {
			console.error(err);
			return false;
		}

		return true;
	}

	/**
	 * Sort criteria based on which search would be the most effective on indexedDb
	 * @param criteria
	 */
	private sortCriteriaBasedOnBestSearch(
		criteria: Array<TCacheStrategySearchCriteria<T>>
	) {
		const sortedCriteria = [...criteria];
		const indexedKeys = [this.primaryKey, ...this.secondaryKeys];
		const calculateCriteriaScore = (
			criteria: TCacheStrategySearchCriteria<T>
		) => {
			let points = 0;
			let multiplier = 1;

			if (!criteria.keyToSearchAgainst) {
				multiplier = 2;
			} else if (indexedKeys.includes(criteria.keyToSearchAgainst)) {
				multiplier = 3;
			}

			if (criteria.exact) {
				points += 4 * multiplier;
			} else if (criteria.like) {
				points += 3 * multiplier;
			} else if (typeof criteria.moreThan === 'number') {
				points += 2 * multiplier;
			} else if (typeof criteria.lessThan === 'number') {
				points += multiplier;
			}

			return points;
		};

		sortedCriteria.sort(
			(c1, c2) => calculateCriteriaScore(c1) - calculateCriteriaScore(c2)
		);

		return sortedCriteria;
	}
}
