import { RtUiForm, RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PartitionRespOrgProfileResponse } from 'RtModels';
import { cloneDeep } from 'lodash-es';
import { PartitionRespOrgResource } from 'RtUi/app/rtAdmin/Partitions/lib/resource/PartitionRespOrgResource';
import { FC, useState } from 'react';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { IRtUiFormProps } from 'RtUi/components/ui/RtUiForm/RtUiForm';

interface IPartitionRespOrgEditorProps {
	onSuccess: (resource: PartitionRespOrgProfileResponse) => void;
	partitionId: number;
	editMode?: PartitionRespOrgProfileResponse;
	onCancel?: () => void;
	createMode?: boolean;
	narrow?: boolean;
	disablePadding?: boolean;
}

const resource = new PartitionRespOrgResource();

export const PartitionRespOrgEditor: FC<
	React.PropsWithChildren<IPartitionRespOrgEditorProps>
> = ({
	narrow,
	disablePadding,
	editMode,
	onSuccess,
	partitionId,
	onCancel = () => null,
	createMode = false
}) => {
	const [error, setError] = useState();
	const [displayMode, setDisplayMode] = useState<boolean>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [editingEntity, setEditingEntity] =
		useState<PartitionRespOrgProfileResponse>(
			editMode
				? cloneDeep(editMode)
				: {
						isDefault: 0,
						respOrgId: '',
						partitionId: partitionId,
						partitionRespOrgId: 0
					}
		);

	const onChange = <K extends keyof PartitionRespOrgProfileResponse>(
		key: K,
		value: PartitionRespOrgProfileResponse[K]
	) => {
		if (!isSubmitting) {
			const updated = {
				[key]: value
			};
			setEditingEntity({
				...editingEntity,
				...updated
			});
		}
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setIsSubmitting(true);
		setError(undefined);

		try {
			let newEntity: PartitionRespOrgProfileResponse;

			if (createMode) {
				newEntity = await resource.create(partitionId, {
					partitionId: partitionId,
					respOrgId: editingEntity.respOrgId,
					isDefault: editingEntity.isDefault
				});
			} else {
				newEntity = await resource.update(
					editingEntity.partitionId,
					editingEntity.partitionRespOrgId,
					{
						partitionId: editingEntity.partitionId,
						isDefault: editingEntity.isDefault,
						respOrgId: editingEntity.respOrgId
					}
				);
			}
			setIsSubmitting(false);
			onSuccess(newEntity);
		} catch (err: any) {
			setIsSubmitting(false);
			setError(err);
		}
	};

	const onCancelInternal = () => {
		setEditingEntity(cloneDeep(editMode!));
		onCancel();
	};

	let RtUiFormComponent: React.ComponentClass<
		React.PropsWithChildren<IRtUiFormProps>
	> = RtUiForm;
	if (narrow) {
		RtUiFormComponent = RtUiNarrowForm;
	}

	return (
		<RtUiFormComponent
			disablePadding={disablePadding}
			onCancel={onCancelInternal}
			displayMode={displayMode}
			isSubmitting={isSubmitting}
			createMode={createMode}
			onSubmit={onSubmit}
			error={error}
			onChange={(displayMode) => {
				setDisplayMode(!displayMode);
			}}
		>
			{!createMode && (
				<InputFormControl
					label="ID"
					readOnly={true}
					displayMode={true}
					value={String(editingEntity.partitionRespOrgId)}
				/>
			)}
			{!createMode && (
				<InputFormControl
					label="Partition Id"
					required
					displayMode={true}
					value={String(editingEntity.partitionId)}
				/>
			)}
			<InputFormControl
				required
				label="RespOrg Id"
				displayMode={displayMode}
				onChange={(value) => onChange('respOrgId', value)}
				value={editingEntity.respOrgId}
			/>
			<BooleanRadioFormControl
				required
				onChange={(value) => onChange('isDefault', value)}
				label="Default"
				displayMode={displayMode}
				value={editingEntity.isDefault}
			/>
		</RtUiFormComponent>
	);
};
