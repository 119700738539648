/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationTypeProfileResponse = {
    properties: {
        guardianReconciliationTypeId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        reconciliationType: {
            type: 'GuardianReconciliationTypes',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        updateTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedBy: {
            type: 'number',
            isRequired: true,
        },
    },
};