import { FC, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useMount } from 'react-use';
import {
	RateLoadApprovalTypes,
	RateLoadErrorIndexResponse,
	RateLoadErrorType
} from 'RtModels';
import { RateLoadResource } from 'RtUi/app/AccountManagement/RateManagement/libs/resources/RateLoadResource';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

type RateLoadErrorResolutionProps = {
	rateLoadId: number;
	errors: RateLoadErrorIndexResponse[];
	onCancel: () => void;
	onSuccess: () => void;
};

export const RateLoadErrorReconcile: FC<
	React.PropsWithChildren<RateLoadErrorResolutionProps>
> = ({ rateLoadId, errors, onSuccess, onCancel }) => {
	const [submitErrors, setSubmitErrors] = useState<any>();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [updatedErrors, setUpdateErrors] = useState<
		RateLoadErrorIndexResponse[]
	>([]);

	useMount(() => {
		setUpdateErrors(errors.map((error) => ({ ...error })));
	});

	const onSubmit = async () => {
		const resource = new RateLoadResource();
		setSubmitErrors(undefined);
		setIsSubmitting(true);

		try {
			await resource.approveExceptions(rateLoadId, {
				updates: updatedErrors
			});

			onSuccess();
			setIsSubmitting(false);
		} catch (err) {
			setSubmitErrors(err);
			setIsSubmitting(false);
		}
	};

	return (
		<RtUiForm
			isSubmitting={isSubmitting}
			error={submitErrors}
			onCancel={onCancel}
			submitButtonText="Reconcile and Approve"
			onSubmit={(evt) => {
				evt.preventDefault();
				onSubmit();
			}}
		>
			{updatedErrors.map((err) => {
				let selected:
					| ISimpleSelectFormControlOption<RateLoadApprovalTypes>
					| undefined = {
					label:
						err.allowedApprovalTypes?.find(
							(type) =>
								type.rateLoadApprovalTypeId === err.rateLoadApprovalTypeId
						)?.label ?? '',
					value: err.rateLoadApprovalTypeId
				};

				if (err.rateLoadApprovalTypeId <= 1) {
					selected = undefined;
				}

				if (
					err.allowedApprovalTypes?.length === 1 &&
					err.rateLoadErrorTypeId !==
						RateLoadErrorType.StartTsViolatesNotificationRules
				) {
					return (
						<section key={err.rateLoadErrorId}>
							<Form.Group className="mb-3">
								<Form.Label>{err.summary}</Form.Label>
								<CheckboxFormControl
									id={String(err.rateLoadErrorId)}
									required
									label={err.allowedApprovalTypes[0].label}
									subLabel={
										err.qty > 1
											? `(${err.qty.toLocaleString()} found)`
											: undefined
									}
									onChange={(option) => {
										let updatedError: RateLoadErrorIndexResponse;
										if (option && err.allowedApprovalTypes) {
											updatedError = {
												...err,
												rateLoadApprovalTypeId:
													err.allowedApprovalTypes[0].rateLoadApprovalTypeId
											};
										} else {
											updatedError = {
												...err,
												rateLoadApprovalTypeId: 0
											};
										}

										const newUpdatedErrors = updatedErrors.map((error) =>
											error.rateLoadErrorId === updatedError.rateLoadErrorId
												? updatedError
												: error
										);

										setUpdateErrors(newUpdatedErrors);
									}}
								/>
							</Form.Group>
						</section>
					);
				}

				return (
					<section key={err.rateLoadErrorId}>
						<SimpleSelectFormControl<RateLoadApprovalTypes>
							required
							label={err.summary}
							subLabel={
								err.qty > 1 ? `(${err.qty.toLocaleString()} found)` : undefined
							}
							onChange={(option) => {
								const updatedError: RateLoadErrorIndexResponse = {
									...err,
									rateLoadApprovalTypeId: option.value
								};

								const newUpdatedErrors = updatedErrors.map((error) =>
									error.rateLoadErrorId === updatedError.rateLoadErrorId
										? updatedError
										: error
								);

								setUpdateErrors(newUpdatedErrors);
							}}
							value={selected}
							options={
								err.allowedApprovalTypes?.map((allowedApprovalType) => ({
									label: allowedApprovalType.label,
									value: allowedApprovalType.rateLoadApprovalTypeId
								})) ?? []
							}
						/>
					</section>
				);
			})}
		</RtUiForm>
	);
};
