import { RtxSipApiRoutes } from 'RtExports/routes';
import { CdrExportControlIndexResponse } from 'RtModels';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

export interface IVueCdrRecurringExportContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Exports: TabbedLayoutTabProps;
	AutomatedTransfer: TabbedLayoutTabProps;
}

export const VueCdrRecurringExportContainerTabs: IVueCdrRecurringExportContainerTabs =
	{
		Profile: {
			header: 'Profile'
		},
		Exports: {
			header: 'Exports'
		},
		AutomatedTransfer: {
			header: 'Automated Transfer'
		}
	};

export class VueCdrRecurringExportRouterClass extends RtVueModuleRouter<
	CdrExportControlIndexResponse,
	CdrExportControlIndexResponse,
	IVueCdrRecurringExportContainerTabs
> {
	constructor() {
		super(
			'CDR Export Setup',
			'cdrExportControl',
			'cdrExportControlId',
			VueCdrRecurringExportContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.CdrExportControl);
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: CdrExportControlIndexResponse) {
		return String(profile.cdrExportControlId);
	}
}

export const VueCdrRecurringExportRouter =
	new VueCdrRecurringExportRouterClass();
