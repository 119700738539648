import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { OriginationNumberResource } from './lib/resources/OriginationNumberResource';
import { ServiceNumberRouter } from './ServiceNumber.router';
import { ServiceNumberFormEditor } from 'RtUi/app/rtSip/ServiceNumbers/lib/forms/ServiceNumberFormEditor';
import { ServiceNumberTagGrid } from 'RtUi/app/rtSip/ServiceNumbers/lib/grids/ServiceNumberTagGrid';
import { OriginationNumberProfileResponse } from 'RtModels';
import { IApplicationContainerProps } from 'RtUi/components/containers/ApplicationContainer';

interface IServiceNumberContainerState {
	sipServiceNumber: OriginationNumberProfileResponse | undefined;
	activeTab: string;
}

@ServiceNumberRouter.getProfileRtUiController()
export class ServiceNumberProfileContainer extends ProfileApplicationContainer<
	IApplicationContainerProps,
	IServiceNumberContainerState
> {
	public Tabs = ServiceNumberRouter.getProfileTabs();

	public state: IServiceNumberContainerState = {
		sipServiceNumber: undefined,
		activeTab: this.Tabs.Profile.header
	};

	public componentDidMount() {
		const sipServiceNumberResource = new OriginationNumberResource();
		const sipId = this.getIdParam();

		sipServiceNumberResource.get(sipId).then((sipServiceNumber) => {
			this.setState({ sipServiceNumber });
		});
	}

	public componentDidUpdate(
		prevProps: Readonly<IApplicationContainerProps>
	): void {
		if (this.props.params.id !== prevProps.params.id) {
			this.componentDidMount();
		}
	}

	public render() {
		const { sipServiceNumber } = this.state;

		if (!sipServiceNumber) {
			return <Loading />;
		}

		return (
			<TabbedLayout<OriginationNumberProfileResponse>
				router={ServiceNumberRouter}
				profile={sipServiceNumber}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<ServiceNumberFormEditor profile={sipServiceNumber} />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Tags}>
					<ServiceNumberTagGrid serviceNumber={sipServiceNumber} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
