import { LcrCarrierRatesLookupIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { LcrCarriersDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LcrCarriersDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';

export const getCarrierRatesColumns = (): Array<
	DataGridColumn<LcrCarrierRatesLookupIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'rateKey',
		header: 'Rate Key'
	}),
	LcrCarriersDataGridColumn({
		accessorKey: 'lcrCarrierId',
		header: 'Account'
	}),
	RateDataGridColumn({
		accessorKey: 'interRate',
		header: 'Inter. Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'intraRate',
		header: 'Intra. Rate'
	}),
	RateDataGridColumn({
		accessorKey: 'indetRate',
		header: 'Indet. Rate'
	})
];
