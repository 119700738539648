import { Rt800ApiRoutes, RtCommonApiRoutes } from 'RtExports/routes';
import {
	CallSimulatorRequest,
	CallSimulatorResponse,
	LrnProfileRequest,
	LrnProfileResponse,
	RoutingCacheTypes,
	TemplateProfileRequest,
	TollFreeProfileRequest
} from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export interface ICallSimulatorParams {
	type: RoutingCacheTypes;
	ani: string;
	simulateValue: string;
}

export interface IAniLrnProfile {
	aniProfile?: LrnProfileResponse;
	lrnProfile?: LrnProfileResponse;
}

const fetchLrnProfile = async (
	urlParams: LrnProfileRequest
): Promise<LrnProfileResponse | undefined> => {
	return handleGetRequest<LrnProfileResponse | undefined>(
		RtCommonApiRoutes.NanpNpac.Profile,
		{ urlParams }
	);
};

export const useGetAniLrnProfile = (ani: string) => {
	return useQuery<IAniLrnProfile, Error>(
		[`useGetAniLrnProfile`, ani],
		async () => {
			const aniProfile = await fetchLrnProfile({ nanpNumber: ani });

			if (!aniProfile?.lrn) {
				return { aniProfile };
			}

			const lrnProfile = await fetchLrnProfile({ nanpNumber: aniProfile.lrn });

			return {
				aniProfile,
				lrnProfile
			};
		}
	);
};

const fetchTemplateSimulator = async (
	urlParams: TemplateProfileRequest,
	queryParams: CallSimulatorRequest
): Promise<CallSimulatorResponse[]> => {
	return handleGetRequest<CallSimulatorResponse[]>(
		Rt800ApiRoutes.TemplateSimulator.Index,
		{ urlParams, queryParams }
	);
};

const fetchTollFreeSimulator = async (
	urlParams: TollFreeProfileRequest,
	queryParams: CallSimulatorRequest
): Promise<CallSimulatorResponse[]> => {
	return handleGetRequest<CallSimulatorResponse[]>(
		Rt800ApiRoutes.TollFreeSimulator.Index,
		{ urlParams, queryParams }
	);
};

export const useGetCallSimulator = (params: ICallSimulatorParams) => {
	return useQuery<CallSimulatorResponse[], Error>(
		[`useGetCallSimulator`, params],
		async () => {
			const { type, simulateValue, ani } = params;
			const isTfnType = type === RoutingCacheTypes.TollFree;
			const queryParams: CallSimulatorRequest = { ani: ani.replace(/\D/g, '') };

			if (isTfnType) {
				return fetchTollFreeSimulator({ tfn: simulateValue }, queryParams);
			}

			return fetchTemplateSimulator(
				{ templateName: simulateValue },
				queryParams
			);
		}
	);
};
