import { CdrSearchViewIndexContainer } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.index';
import { ConnectionIndexContainer } from 'RtUi/app/rtVue/Connections/Connection.index';
import { ConnectionProfileContainer } from 'RtUi/app/rtVue/Connections/Connection.profile';
import { ConnectionSubscriptionProfileContainer } from 'RtUi/app/rtVue/ConnectionSubscription/ConnectionSubscription.profile';
import { ConnectionSubscriptionRouter } from 'RtUi/app/rtVue/ConnectionSubscription/ConnectionSubscription.router';
import { DoNotOriginateIndexContainer } from 'RtUi/app/rtVue/DoNotOriginate/DoNotOriginate.index';
import { DoNotOriginateProfileContainer } from 'RtUi/app/rtVue/DoNotOriginate/DoNotOriginate.profile';
import { DoNotOriginateRouter } from 'RtUi/app/rtVue/DoNotOriginate/DoNotOriginate.router';
import { FileLogIndexContainer } from 'RtUi/app/rtVue/FileLog/FileLog.index';
import { FileStreamIndexContainer } from 'RtUi/app/rtVue/FileStreams/FileStream.index';
import { FileStreamProfileContainer } from 'RtUi/app/rtVue/FileStreams/FileStream.profile';
import { FileStreamRouter } from 'RtUi/app/rtVue/FileStreams/FileStream.router';
import { FileTypeIndexContainer } from 'RtUi/app/rtVue/FileTypes/FileType.index';
import { FileTypeRouter } from 'RtUi/app/rtVue/FileTypes/FileType.router';
import { FinancialViewIndexContainer } from 'RtUi/app/rtVue/FinancialView/FinancialView.index';
import { GuardianReconciliationIndexContainer } from 'RtUi/app/rtVue/GuardianReconciliation/GuardianReconciliation.index';
import { GuardianReconciliationProfileContainer } from 'RtUi/app/rtVue/GuardianReconciliation/GuardianReconciliation.profile';
import { GuardianReconciliationsRouter } from 'RtUi/app/rtVue/GuardianReconciliation/GuardianReconciliation.router';
import { GuardianReconciliationDashboardIndexContainer } from 'RtUi/app/rtVue/GuardianReconciliationDashboard/GuardianReconciliationDashboard.index';
import { GuardianReconciliationDashboardRouter } from 'RtUi/app/rtVue/GuardianReconciliationDashboard/GuardianReconciliationDashboard.router';
import { GuardianReconciliationMappingIndexContainer } from 'RtUi/app/rtVue/GuardianReconciliationMapping/GuardianReconciliationMapping.index';
import { GuardianReconciliationMappingProfileContainer } from 'RtUi/app/rtVue/GuardianReconciliationMapping/GuardianReconciliationMapping.profile';
import { GuardianReconciliationMappingsRouter } from 'RtUi/app/rtVue/GuardianReconciliationMapping/GuardianReconciliationMapping.router';
import { GuardianReconciliationMappingFilterProfileContainer } from 'RtUi/app/rtVue/GuardianReconciliationMappingFilter/GuardianReconciliationMappingFilter.profile';
import { GuardianServiceNumberSubscriptionIndex } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/GuardianServiceNumberSubscription.index';
import { GuardianServiceNumberSubscriptionRouter } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/GuardianServiceNumberSubscription.router';
import { LcoSummaryIndexContainer } from 'RtUi/app/rtVue/LcoSummary/LcoSummary.index';
import { RatingSimulatorIndexContainer } from 'RtUi/app/rtVue/RatingSimulator/RatingSimulator.index';
import { RatingSimulatorRouter } from 'RtUi/app/rtVue/RatingSimulator/RatingSimulator.router';
import { ReconciliationIndexContainer } from 'RtUi/app/rtVue/Reconciliation/Reconciliation.index';
import { ReconciliationRouter } from 'RtUi/app/rtVue/Reconciliation/Reconciliation.router';
import { ReconciliationChargesProfileContainer } from 'RtUi/app/rtVue/ReconciliationCharge/ReconciliationCharges.profile';
import { ReconciliationChargesRouter } from 'RtUi/app/rtVue/ReconciliationCharge/ReconciliationCharges.router';
import { ReconciliationUsageProfileContainer } from 'RtUi/app/rtVue/ReconciliationUsage/ReconciliationUsage.profile';
import { ReconciliationUsageRouter } from 'RtUi/app/rtVue/ReconciliationUsage/ReconciliationUsage.router';
import { RejectSuspenseReportIndexContainer } from 'RtUi/app/rtVue/RejectSuspenseReport/RejectSuspenseReport.index';
import { RejectSuspenseReportRouter } from 'RtUi/app/rtVue/RejectSuspenseReport/RejectSuspenseReport.router';
import { RerateControlIndexContainer } from 'RtUi/app/rtVue/RerateControl/RerateControl.index';
import { RerateControlProfileContainer } from 'RtUi/app/rtVue/RerateControl/RerateControl.profile';
import { RerateControlRouter } from 'RtUi/app/rtVue/RerateControl/RerateControl.router';
import { RoboscoringIndexContainer } from 'RtUi/app/rtVue/Roboscoring/Roboscoring.index';
import { RoboscoringProfileContainer } from 'RtUi/app/rtVue/Roboscoring/Roboscoring.profile';
import { SubscriptionCicIndexContainer } from 'RtUi/app/rtVue/SubscriptionCic/SubscriptionCic.index';
import { SubscriptionCicProfileContainer } from 'RtUi/app/rtVue/SubscriptionCic/SubscriptionCic.profile';
import { SubscriptionCicRouter } from 'RtUi/app/rtVue/SubscriptionCic/SubscriptionCic.router';
import { TfEnhancedReportIndexContainer } from 'RtUi/app/rtVue/TfEnhancedReport/TfEnhancedReport.index';
import { TfEnhancedReportRouter } from 'RtUi/app/rtVue/TfEnhancedReport/TfEnhancedReport.router';
import { TfEnhancedDetailsReportIndexContainer } from 'RtUi/app/rtVue/TfEnhancedDetailsReport/TfEnhancedDetailsReport.index';
import { TfEnhancedDetailsReportRouter } from 'RtUi/app/rtVue/TfEnhancedDetailsReport/TfEnhancedDetailsReport.router';
import { RtUiModule } from '../@RtUi/RtUiDecorators';
import { AsrViewIndexContainer } from './AsrView/AsrView.index';
import { CpsViewIndexContainer } from './CpsView/CpsView.index';
import { VueNumberViewIndexContainer } from './NumbersView/VueNumberView.index';
import { PortViewIndexContainer } from './PortsView/PortView.index';
import { RtVueModuleRouter } from './RtVue.router';
import { GuardianServiceNumberSubscriptionProfile } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/GuardianServiceNumberSubscription.profile';
import { GlobalNumberIndex } from 'RtUi/app/rtVue/GlobalNumbers/GlobalNumbers.index';
import { GlobalNumbersProfile } from 'RtUi/app/rtVue/GlobalNumbers/GlobalNumbers.profile';
import { PortRequestIndexContainer } from 'RtUi/app/rtVue/PortRequest/PortRequest.index';
import { PortRequestRouter } from 'RtUi/app/rtVue/PortRequest/PortRequest.router';
import { RejectDetailsCostReportRouter } from 'RtUi/app/rtVue/RejectDetailsCostReport/RejectDetailsCostReport.router';
import { RejectDetailsCostReportIndexContainer } from 'RtUi/app/rtVue/RejectDetailsCostReport/RejectDetailsCostReport.index';
import { RejectDetailsRevenueReportIndexContainer } from 'RtUi/app/rtVue/RejectDetailsRevenueReport/RejectDetailsRevenueReport.index';
import { RejectDetailsRevenueReportRouter } from 'RtUi/app/rtVue/RejectDetailsRevenueReport/RejectDetailsRevenueReport.router';
import { LrnLookupIndexContainer } from 'RtUi/app/rtVue/LrnLookup/LrnLookup.index';
import { LrnLookupRouter } from 'RtUi/app/rtVue/LrnLookup/LrnLookup.router';
import { DialCodeIndexContainer } from 'RtUi/app/rtVue/DialCode/DialCode.index';
import { DialCodeRouter } from 'RtUi/app/rtVue/DialCode/DialCode.router';
import { VueCdrRecurringExportRouter } from 'RtUi/app/rtVue/CdrRecurringExport/VueCdrRecurringExportRouter';
import { VueCdrRecurringExportIndexContainer } from 'RtUi/app/rtVue/CdrRecurringExport/VueCdrRecurringExport.index';
import { VueCdrRecurringExportProfileContainer } from 'RtUi/app/rtVue/CdrRecurringExport/VueCdrRecurringExport.profile';
import { ConvertRateSheetRouter } from 'RtUi/app/rtVue/ConvertRateSheet/ConvertRateSheet.router';
import { ConvertRateSheetIndexContainer } from 'RtUi/app/rtVue/ConvertRateSheet/ConvertRateSheet.index';
import { FourteenDayIndexContainer } from 'RtUi/app/rtVue/14DayReport/14DayReport.index';
import { FourteenDayReportRouter } from 'RtUi/app/rtVue/14DayReport/14DayReport.router';
import { UsageAuditByDay } from 'RtUi/app/rtVue/UsageAuditByDayReport/UsageAuditByDayReport.index';
import { UsageAuditByDayRouter } from 'RtUi/app/rtVue/UsageAuditByDayReport/UsageAuditByDay.router';
import { BirdEyeReport } from 'RtUi/app/rtVue/BirdsEyeViewReport/BirdEyeReport.index';
import { BirdEyeReportRouter } from 'RtUi/app/rtVue/BirdsEyeViewReport/BirdEyeReport.router';
import { AsrLiteViewIndexContainer } from 'RtUi/app/rtVue/AsrLiteView/AsrLiteView.index';
import { AsrLiteViewRouter } from 'RtUi/app/rtVue/AsrLiteView/AsrLiteView.router';
import { RoboscoreIndexContainer } from 'RtUi/app/rtVue/Roboscore/Roboscore.index';
import { RoboscoreRouter } from 'RtUi/app/rtVue/Roboscore/Roboscore.router';
import { CannedReportIndexContainer } from 'RtUi/app/rtVue/CannedReports/CannedReports.index';
import { CannedReportRouter } from 'RtUi/app/rtVue/CannedReports/CannedReports.router';
import { MonthlyTrendingIndexContainer } from 'RtUi/app/rtVue/MonthlyTrending/MonthlyTrending.index';
import { MonthlyTrendingRouter } from 'RtUi/app/rtVue/MonthlyTrending/MonthlyTrending.router';
import { ViewNumbersLiteIndexContainer } from 'RtUi/app/rtVue/NumbersLite/ViewNumbersLite.index';
import { ViewNumbersLiteRouter } from 'RtUi/app/rtVue/NumbersLite/ViewNumbersLite.router';
import { TermSummaryRouter } from 'RtUi/app/rtVue/TermSummaryReport/TermSummaryReport.router';
import { TermSummaryIndexContainer } from 'RtUi/app/rtVue/TermSummaryReport/TermSummaryReport.index';
import { MessagesDetailIndexContainer } from 'RtUi/app/rtVue/MessagesDetail/MessagesDetail.index';
import { MessagesDetailRouter } from 'RtUi/app/rtVue/MessagesDetail/MessagesDetail.router';
import { MessagesSummaryRouter } from 'RtUi/app/rtVue/MessagesSummary/MessagesSummary.router';
import { MessagesSummaryIndexContainer } from 'RtUi/app/rtVue/MessagesSummary/MessagesSummary.index';

@RtUiModule({
	route: {
		path: `/${RtVueModuleRouter.SectionKey}`,
		name: 'RT/Vue',
		iconClass: 'fa-chart-line',
		defaultOpen: false,
		doNotExpandChildren: true
	},
	controllers: [
		AsrViewIndexContainer,
		AsrLiteViewIndexContainer,
		CpsViewIndexContainer,
		VueNumberViewIndexContainer,
		PortViewIndexContainer,
		FinancialViewIndexContainer,
		CdrSearchViewIndexContainer,
		FileStreamIndexContainer,
		FileStreamProfileContainer,
		FileTypeIndexContainer,
		ConnectionIndexContainer,
		ConnectionProfileContainer,
		RoboscoringIndexContainer,
		RoboscoringProfileContainer,
		LcoSummaryIndexContainer,
		FileLogIndexContainer,
		DoNotOriginateIndexContainer,
		DoNotOriginateProfileContainer,
		RerateControlIndexContainer,
		RerateControlProfileContainer,
		RejectSuspenseReportIndexContainer,
		RoboscoreIndexContainer,
		ConnectionSubscriptionProfileContainer,
		SubscriptionCicIndexContainer,
		SubscriptionCicProfileContainer,
		TfEnhancedReportIndexContainer,
		TfEnhancedDetailsReportIndexContainer,
		RatingSimulatorIndexContainer,
		ReconciliationIndexContainer,
		GuardianReconciliationIndexContainer,
		GuardianReconciliationProfileContainer,
		ReconciliationUsageProfileContainer,
		ReconciliationChargesProfileContainer,
		GuardianReconciliationMappingIndexContainer,
		GuardianReconciliationMappingProfileContainer,
		GuardianReconciliationMappingFilterProfileContainer,
		GuardianReconciliationDashboardIndexContainer,
		GuardianServiceNumberSubscriptionIndex,
		GuardianServiceNumberSubscriptionProfile,
		GlobalNumberIndex,
		GlobalNumbersProfile,
		PortRequestIndexContainer,
		RejectDetailsCostReportIndexContainer,
		RejectDetailsRevenueReportIndexContainer,
		LrnLookupIndexContainer,
		ConvertRateSheetIndexContainer,
		DialCodeIndexContainer,
		UsageAuditByDay,
		VueCdrRecurringExportIndexContainer,
		VueCdrRecurringExportProfileContainer,
		FourteenDayIndexContainer,
		BirdEyeReport,
		CannedReportIndexContainer,
		MonthlyTrendingIndexContainer,
		ViewNumbersLiteIndexContainer,
		TermSummaryIndexContainer,
		MessagesDetailIndexContainer,
		MessagesSummaryIndexContainer
	],
	routers: [
		FileStreamRouter,
		AsrLiteViewRouter,
		RoboscoreRouter,
		FileTypeRouter,
		DoNotOriginateRouter,
		RerateControlRouter,
		RejectSuspenseReportRouter,
		ConnectionSubscriptionRouter,
		SubscriptionCicRouter,
		TfEnhancedReportRouter,
		TfEnhancedDetailsReportRouter,
		TermSummaryRouter,
		RatingSimulatorRouter,
		RatingSimulatorRouter,
		ReconciliationRouter,
		GuardianReconciliationsRouter,
		ReconciliationUsageRouter,
		ReconciliationChargesRouter,
		GuardianReconciliationMappingsRouter,
		GuardianReconciliationDashboardRouter,
		GuardianServiceNumberSubscriptionRouter,
		PortRequestRouter,
		RejectDetailsCostReportRouter,
		RejectDetailsRevenueReportRouter,
		LrnLookupRouter,
		ConvertRateSheetRouter,
		DialCodeRouter,
		VueCdrRecurringExportRouter,
		FourteenDayReportRouter,
		UsageAuditByDayRouter,
		VueCdrRecurringExportRouter,
		BirdEyeReportRouter,
		CannedReportRouter,
		MonthlyTrendingRouter,
		ViewNumbersLiteRouter,
		MessagesDetailRouter,
		MessagesSummaryRouter
	]
})
export class RtVueModule {}
