import { isEqual } from 'lodash-es';
import * as React from 'react';
import { Permissions } from 'RtExports/routes';
import { UserActions } from 'RtUi/state/actions/user';

interface IPermissionGateProps {
	showOnForbidden?: boolean;
	permissions: Permissions[];
}

interface IPermissionGateState {
	hasPermission: boolean;
}

export class PermissionGate extends React.Component<
	React.PropsWithChildren<IPermissionGateProps>,
	IPermissionGateState
> {
	public state: IPermissionGateState = {
		hasPermission: false
	};

	public componentDidMount() {
		this.checkOnPermissions();
	}

	public componentDidUpdate(prevProps: IPermissionGateProps) {
		if (!isEqual(prevProps.permissions, this.props.permissions)) {
			this.checkOnPermissions();
		}
	}

	public checkOnPermissions() {
		const hasPermission = UserActions.has(...this.props.permissions);

		if (hasPermission !== this.state.hasPermission) {
			this.setState({ hasPermission });
		}
	}

	public render() {
		const { showOnForbidden = false } = this.props;
		const { hasPermission } = this.state;
		const showElement = showOnForbidden ? !hasPermission : hasPermission;

		if (showElement) {
			return this.props.children;
		}

		return null;
	}
}
