import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { Lerg6IndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

export const getLerg6Columns = (): Array<
	DataGridColumn<Lerg6IndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'lata',
		header: 'Lata'
	}),
	DefaultDataGridColumn({
		accessorKey: 'npa',
		header: 'NANP'
	}),
	DefaultDataGridColumn({
		accessorKey: 'nxx',
		header: 'NXX'
	}),
	DefaultDataGridColumn({
		accessorKey: 'blockId',
		header: 'Block Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'administrativeOcn',
		header: 'Adm. Ocn'
	}),
	DefaultDataGridColumn({
		accessorKey: 'operatingCompanyNumber',
		header: 'Op Company Number'
	}),
	DefaultDataGridColumn({
		accessorKey: 'operatingCompanyName',
		header: 'Op Company Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'linesFrom',
		header: 'Lines From'
	}),
	DefaultDataGridColumn({
		accessorKey: 'linesTo',
		header: 'Lines To'
	}),
	DefaultDataGridColumn({
		accessorKey: 'switch',
		header: 'Switch'
	}),
	DefaultDataGridColumn({
		accessorKey: 'endOffice',
		header: 'End Office'
	}),
	DefaultDataGridColumn({
		accessorKey: 'accessTandem',
		header: 'Access Tandem'
	})
];
