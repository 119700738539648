import { RtCommonApiRoutes, RtxApiRoutes } from 'RtExports/routes';
import {
	MessageEventTypeIndexResponse,
	MyProfilePasswordUpdateRequest,
	MyProfileUpdateRequest,
	NotificationPreferenceCreateRequest,
	NotificationPreferenceDeleteRequest,
	NotificationPreferenceIndexResponse,
	UserIndexResponse
} from 'RtModels';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { generateUUID } from 'RtUi/utils/http/resources/utils';

type NotificationPreferenceListener = (
	notificationPreferences: NotificationPreferenceIndexResponse[]
) => void;

export class MyProfileHttp {
	protected static PreferencesDefer: Promise<
		NotificationPreferenceIndexResponse[]
	> | null = null;
	protected static UpdateListeners: NotificationPreferenceListener[] = [];
	protected static UpdateGuid = generateUUID();

	protected static GetAllNotifications() {
		if (!MyProfileHttp.PreferencesDefer) {
			MyProfileHttp.PreferencesDefer = HttpRequest.fetchWithRoute<
				NotificationPreferenceIndexResponse[]
			>(RtxApiRoutes.MyProfileNotifications.Index);
		}

		return MyProfileHttp.PreferencesDefer;
	}

	/**
	 * Get a user's profile
	 * @param userId
	 * @param updateRequest
	 */
	public getProfile() {
		return HttpRequest.fetchWithRoute<UserIndexResponse>(
			RtxApiRoutes.MyProfile.Profile
		);
	}

	/**
	 * Update a user's profile
	 * @param userId
	 * @param updateRequest
	 */
	public updateProfile(
		smsPhone: string | null,
		mobilePhone: string | null,
		officePhone: string | null
	) {
		const updateRequest: MyProfileUpdateRequest = {
			smsPhone,
			mobilePhone,
			officePhone
		};
		const body = JSON.stringify(updateRequest);

		return HttpRequest.fetchWithRoute<UserIndexResponse>(
			RtxApiRoutes.MyProfile.Update,
			{ body }
		);
	}

	/**
	 * Update a user's password
	 * @param currentPassword
	 * @param newPassword
	 */
	public updateUsersPassword(currentPassword: string, newPassword: string) {
		const req: MyProfilePasswordUpdateRequest = {
			currentPassword,
			newPassword
		};
		const body = JSON.stringify(req);

		return HttpRequest.fetchWithRoute<UserIndexResponse>(
			RtxApiRoutes.MyProfilePassword.Update,
			{ body }
		);
	}

	/**
	 * Get all Notification Preferences
	 */
	public getAllNotifications() {
		return MyProfileHttp.GetAllNotifications();
	}

	public getNotificationTypes() {
		return HttpRequest.fetchWithRoute<MessageEventTypeIndexResponse[]>(
			RtCommonApiRoutes.NotificationTypes.Index
		);
	}

	/**
	 * Add Notification Preferences
	 */
	public addNotification(createReq: NotificationPreferenceCreateRequest) {
		const body = JSON.stringify(createReq);

		const newNotificationPreferencesDefer = HttpRequest.fetchWithRoute<
			NotificationPreferenceIndexResponse[]
		>(RtxApiRoutes.MyProfileNotifications.Create, { body });

		this.triggerListeners(newNotificationPreferencesDefer);

		return newNotificationPreferencesDefer;
	}

	/**
	 * Delete Notification Preferences
	 */
	public deleteNotification(deleteReq: NotificationPreferenceDeleteRequest) {
		const urlParams = deleteReq;
		const newNotificationPreferencesDefer = HttpRequest.fetchWithRoute<
			NotificationPreferenceIndexResponse[]
		>(RtxApiRoutes.MyProfileNotifications.Delete, { urlParams });

		this.triggerListeners(newNotificationPreferencesDefer);

		return newNotificationPreferencesDefer;
	}

	/**
	 * Notification preferences listener
	 * @returns remove listener function
	 */
	public onNotificationsUpdate(
		listener: (
			notificationPreferences: NotificationPreferenceIndexResponse[]
		) => void
	) {
		MyProfileHttp.UpdateListeners.push(listener);

		return () => {
			const indexOfListener = MyProfileHttp.UpdateListeners.indexOf(listener);

			if (indexOfListener >= 0) {
				MyProfileHttp.UpdateListeners.splice(indexOfListener);
			}
		};
	}

	/**
	 * Notify listeners of new notification preferences
	 */
	protected async triggerListeners(
		newNotificationPreferencesDefer: Promise<
			NotificationPreferenceIndexResponse[]
		>
	) {
		//Update defer for future usage
		MyProfileHttp.PreferencesDefer = newNotificationPreferencesDefer;
		const currentUpdateGuid = generateUUID();
		MyProfileHttp.UpdateGuid = currentUpdateGuid;

		const newNotificationPreferences = await newNotificationPreferencesDefer;

		if (currentUpdateGuid !== MyProfileHttp.UpdateGuid) {
			//A more recent update is in flight
			return;
		}

		for (const listener of MyProfileHttp.UpdateListeners) {
			try {
				listener(newNotificationPreferences);
			} catch (err) {
				console.error(err);
			}
		}
	}
}
