import { FormEvent, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import {
	EntityIndexResponse,
	PeeringTypeIndexResponse,
	RespOrgIndexResponse,
	SubscriptionIndexResponse,
	SubscriptionPeeringCreateRequest
} from 'RtModels';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import {
	CarrierSelect,
	ICicIndexResponse
} from 'RtUi/app/rt800/Cprs/lib/controls/CarrierSelect';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { PeeringTypeSelect } from 'RtUi/app/rtLcr/SubscriptionPeering/controls/PeeringTypeSelect';
import {
	createSubscriptionPeering,
	updateSubscriptionPeering
} from 'RtUi/app/rtLcr/SubscriptionPeering/services';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface ISubscriptionPeeringEditorProps {
	onSuccess?: () => void;
	onCancel?: () => void;
	editMode?: SubscriptionPeeringCreateRequest;
	subscriptionPeeringId?: number;
	subscriptionId?: number;
}

const createNew = (): SubscriptionPeeringCreateRequest => {
	return {
		subscriptionId: -1,
		peeringTypeId: 1,
		peeredValue: ''
	};
};

export const SubscriptionPeeringEditor = ({
	onSuccess = () => ({}),
	onCancel = () => ({}),
	editMode,
	subscriptionPeeringId,
	subscriptionId
}: ISubscriptionPeeringEditorProps) => {
	const [peeredValue, setPeeredValue] = useState<
		RespOrgIndexResponse | EntityIndexResponse | ICicIndexResponse | undefined
	>();
	const [peeringType, setPeeringType] = useState<PeeringTypeIndexResponse>();
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const { mutateAsync: updateSubscription } = useMutation(
		updateSubscriptionPeering
	);
	const { mutateAsync: createSubscription } = useMutation(
		createSubscriptionPeering
	);

	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		editMode,
		createNew
	});

	const initialSubscription = useMemo(() => {
		if (editMode) {
			return editMode.subscriptionId;
		}

		return subscriptionId;
	}, [editMode, subscriptionId]);

	const getPeeredValue = (peeringType: PeeringTypeIndexResponse) => {
		if (peeringType.peeringTypeId === 1) {
			return (peeredValue as EntityIndexResponse).entityId;
		} else if (peeringType.peeringTypeId === 2) {
			return (peeredValue as RespOrgIndexResponse).respOrgId;
		} else if (peeringType.peeringTypeId === 3) {
			return (peeredValue as ICicIndexResponse).cic;
		}

		return '';
	};

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			if (editMode && subscriptionPeeringId) {
				await updateSubscription({
					subscriptionPeeringId,
					updatedSubscriptionPeering: {
						peeredValue: getPeeredValue(peeringType!)
					}
				});
			} else {
				await createSubscription({
					subscriptionId: subscription!.subscriptionId,
					peeringTypeId: peeringType?.peeringTypeId ?? 1,
					peeredValue: getPeeredValue(peeringType!)
				});
			}
			rtUiFormMethods.setIsSubmitting(false);
			onSuccess();
		} catch (err) {
			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setError(err);
		}
	};

	const renderPeeredValueSelect = () => {
		switch (peeringType?.peeringTypeId) {
			case 1:
				return (
					<EntitySelect
						allowAll
						onChange={(entity) => setPeeredValue(entity)}
						value={peeredValue as EntityIndexResponse}
						initialOptionId={editMode?.peeredValue}
					/>
				);
			case 2:
				return (
					<RespOrgSelect
						allowAll
						onChange={(respOrg) => setPeeredValue(respOrg)}
						value={peeredValue as RespOrgIndexResponse}
						initialOptionId={editMode?.peeredValue}
					/>
				);
			case 3:
				return (
					<CarrierSelect
						label="CIC"
						onChange={(selectedCarrier: ICicIndexResponse) =>
							setPeeredValue(selectedCarrier)
						}
						value={peeredValue as ICicIndexResponse}
						initialOptionId={editMode?.peeredValue}
					/>
				);
			default:
				return <></>;
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			displayMode={false}
			onSubmit={onSubmit}
			onCancel={() => {
				rtUiFormMethods.onCancel();
				onCancel();
			}}
		>
			<SubscriptionSelect
				required
				displayMode={Boolean(subscriptionId)}
				onChange={(subscription) => {
					setSubscription(subscription);
				}}
				value={subscription}
				initialOptionId={initialSubscription}
			/>
			<PeeringTypeSelect<false>
				multi={false}
				value={peeringType}
				initialOptionId={editMode?.peeringTypeId.toString()}
				disabled={Boolean(editMode)}
				onChange={(type) => {
					setPeeringType(type);
					setPeeredValue(undefined);
				}}
			/>
			{renderPeeredValueSelect()}
		</RtUiForm>
	);
};
