import { Permissions } from 'RtExports/routes';
import {
	AccountIndexResponse,
	PortLimitIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { BlockTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BlockTypeDataGridColumn';
import { CountryDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CountryDataGridColumn';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RoutePlanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RoutePlanDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { TimezoneDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimezoneDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { UserActions } from 'RtUi/state/actions/user';

export const getAccountsColumns = (): Array<
	DataGridColumn<AccountIndexResponse>
> => {
	const columns: Array<DataGridColumn<AccountIndexResponse>> = [
		IdDataGridColumn({
			accessorKey: 'accountId',
			header: 'Account Id'
		}),
		LabelDataGridColumn({
			accessorKey: 'label'
		}),
		IsActiveDataGridColumn({
			accessorKey: 'isActive'
		})
	];

	if (UserActions.has(Permissions.PartitionBusinessOperations)) {
		return columns.concat([
			HeaderDataGridColumn({
				header: 'Balances',
				columns: [
					PrecisionDataGridColumn({
						accessorKey: 'customerBalance',
						header: 'Customer',
						showBlankIfEmpty: true,
						hiddenIfEmpty: true
					}),
					PrecisionDataGridColumn({
						accessorKey: 'vendorBalance',
						header: 'Vendor',
						showBlankIfEmpty: true,
						hiddenIfEmpty: true
					}),
					PrecisionDataGridColumn({
						accessorKey: 'combinedBalance',
						header: 'Combined',
						showBlankIfEmpty: true,
						hiddenIfEmpty: true
					})
				]
			}),
			HeaderDataGridColumn({
				header: 'Limits',
				columns: [
					PrecisionDataGridColumn({
						accessorKey: 'customerLimit',
						header: 'Customer',
						showBlankIfEmpty: true,
						hiddenIfEmpty: true
					}),
					PrecisionDataGridColumn({
						accessorKey: 'vendorLimit',
						header: 'Vendor',
						showBlankIfEmpty: true,
						hiddenIfEmpty: true
					}),
					PrecisionDataGridColumn({
						accessorKey: 'combinedLimit',
						header: 'Combined',
						showBlankIfEmpty: true,
						hiddenIfEmpty: true
					})
				]
			})
		]);
	}

	return columns;
};

export const getAccountsSubscriptionsColumns = (
	isCustomer: boolean
): Array<DataGridColumn<SubscriptionIndexResponse>> => [
	LabelDataGridColumn({
		accessorKey: 'label',
		header: 'Subscription'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	RoutePlanDataGridColumn({
		accessorKey: 'routePlanId',
		header: 'Route Plan',
		isInvisible: !isCustomer
	}),
	TimeStampDataGridColumn({
		header: 'Last Rate Load',
		accessorKey: 'lastRateLoadTs'
	}),
	BlockTypeDataGridColumn({
		accessorKey: 'blockTypeId',
		header: 'Block Type'
	}),
	TimezoneDataGridColumn({
		accessorKey: 'timezoneId',
		header: 'Time-Zone'
	})
];

export const getAccountPortLimitsColumns = (): Array<
	DataGridColumn<PortLimitIndexResponse>
> => [
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	CountryDataGridColumn({
		accessorKey: 'countryAbbr',
		header: 'Country'
	}),
	IntegerDataGridColumn({
		accessorKey: 'ingressPortLimit',
		header: 'Ingress port limit'
	}),
	IntegerDataGridColumn({
		accessorKey: 'egressPortLimit',
		header: 'Egress port limit'
	})
];
