import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import {
	IStateOption,
	GetStates
} from 'RtUi/app/rtCommon/States/lib/resources/States';

export class StatesResource extends ArrayResource<IStateOption> {
	constructor() {
		super('abbreviation', GetStates());
	}
}
