import { ResourceNumberBillingRecord } from 'RtModels';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getDidBillingInventoryColumns } from 'RtUi/components/data/DataGrid/configurations/rtDid/numbers';
import { useMemo } from 'react';

interface IDidBillingInventoryGridProps {
	records: ResourceNumberBillingRecord[];
	autoFocusFilter?: boolean;
	disableCardAppearance?: boolean;
	disableCollapse?: boolean;
}

export const DidBillingInventoryGrid = ({
	records
}: IDidBillingInventoryGridProps) => {
	const columns = useMemo(() => getDidBillingInventoryColumns(), []);

	return (
		<DataGrid<ResourceNumberBillingRecord>
			data={records}
			columns={columns}
			pageName={'rtDid_billingInventory'}
		/>
	);
};
