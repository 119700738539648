import {
	GuardianAlertTypeIndexRequest,
	GuardianAlertTypeIndexResponse
} from 'RtModels';
import { GuardianAlertTypesResource } from 'RtUi/app/rtGuardian/AlertRules/lib/resources/GuardianAlertTypesResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface AlertTypeDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		GuardianAlertTypeIndexResponse,
		T
	> {}

const AlertTypeRenderCache = new ResourceCacheMap<
	GuardianAlertTypeIndexResponse,
	GuardianAlertTypeIndexRequest
>(
	new GuardianAlertTypesResource(),
	'alertTypeId',
	'summary',
	'source',
	(record) => `${record.source} - ${record.summary}`,
	{ showSystemAlerts: 1 }
);

export const AlertTypeDataGridColumn = <T = any,>(
	config: AlertTypeDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: AlertTypeRenderCache,
		...config
	});
};
