import { InvoiceIndexRequest, InvoiceIndexResponse } from 'RtModels';
import { InvoicesRouter } from 'RtUi/app/rtSip/Invoices/Invoices.router';
import { useGetInvoices } from 'RtUi/app/rtSip/Invoices/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getInvoicesColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/invoices';
import { useMemo } from 'react';

interface IInvoiceDataGridProps {
	resourceParams?: InvoiceIndexRequest;
}

export const InvoiceDataGrid = ({ resourceParams }: IInvoiceDataGridProps) => {
	const { data, isFetching: isLoading } = useGetInvoices(resourceParams);
	const columns = useMemo(() => getInvoicesColumns(), []);

	return (
		<DataGrid<InvoiceIndexResponse>
			loading={isLoading}
			data={data?.data}
			totalRows={data?.totalCount}
			pageName="rtSip_invoices"
			columns={columns}
			router={InvoicesRouter}
		/>
	);
};
