import { TemplateCprProfileResponse, TemplateProfileRequest } from 'RtModels';
import { CprProfileContainer } from 'RtUi/app/rt800/Cprs/lib/components/CprProfileContainer';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { TemplateCprsRouter } from './Cprs.router';

export const TemplateCprsProfileContainer = () => {
	const id = useIdParam();
	const resourceParams: TemplateProfileRequest = {
		templateName: id
	};
	return (
		<CprProfileContainer<TemplateCprProfileResponse>
			router={TemplateCprsRouter}
			requestParams={resourceParams}
		/>
	);
};

TemplateCprsRouter.setProfileRtUiFunctionalComponent(
	TemplateCprsProfileContainer
);
