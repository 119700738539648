/* eslint-disable max-classes-per-file */
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { CprNodeType } from 'RtModels';
import { CprNodeTypeNameMap } from 'RtUi/app/rt800/Cprs/lib/Constants';

export interface CprNodeTypeOption {
	name: string;
	nodeType: CprNodeType;
}

export class NodeTypesResource extends ArrayResource<CprNodeTypeOption> {
	public static Options = Array.from(CprNodeTypeNameMap.entries()).map(
		([nodeType, name]) => ({
			name,
			nodeType
		})
	);

	public static getOptionFor(nodeType: CprNodeType) {
		return NodeTypesResource.Options.find(
			(option) => option.nodeType === nodeType
		);
	}

	constructor(limitCprCreateNodeTypes?: CprNodeType[]) {
		super('nodeType');

		const options = NodeTypesResource.Options.filter((option) => {
			if (limitCprCreateNodeTypes) {
				return limitCprCreateNodeTypes.includes(option.nodeType);
			}

			return true;
		});

		this.setAllData(options);
	}
}

interface ICprNodeTypeSelectProps
	extends ISelectFormControlProps<CprNodeTypeOption> {
	limitCprCreateNodeTypes?: CprNodeType[];
}

export class CprNodeTypeSelect extends SelectFormControl<
	CprNodeTypeOption,
	false,
	ICprNodeTypeSelectProps
> {
	public resourceClass = undefined;
	public state: ISelectFormControlState<CprNodeTypeOption> = {
		formLabel: 'CPR Node Type',
		valueKey: 'nodeType',
		labelKey: 'name'
	};

	public componentDidMount() {
		const { limitCprCreateNodeTypes } = this.props;

		this.resource = new NodeTypesResource(limitCprCreateNodeTypes);
		super.componentDidMount();
	}
}
