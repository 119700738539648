import { Component } from 'react';
import { Card, Button } from 'react-bootstrap';
import {
	GuardianAlertHistoryIndexResponse,
	GuardianAlertHistoryNoteIndexResponse
} from 'RtModels';
import { AlertHistoryNotesControl } from 'RtUi/app/rtGuardian/AlertHistory/lib/controls/AlertHistoryNotesControl';
import { AlertHistoryNoteResource } from 'RtUi/app/rtGuardian/AlertHistory/lib/resources/AlertHistoryNoteResource';
import { Aside } from 'RtUi/components/ui/Aside';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IAlertNotesAsideProps {
	isOpen: boolean;
	onCancel: () => void;
	selectedRecord: GuardianAlertHistoryIndexResponse;
}

interface IAlertNotesAsideState {
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
	notes?: GuardianAlertHistoryNoteIndexResponse[];
}

export class AlertNotesAside extends Component<
	IAlertNotesAsideProps,
	IAlertNotesAsideState
> {
	public state: IAlertNotesAsideState = {
		displayMode: true,
		isSubmitting: false
	};

	public async componentDidMount() {
		const id = this.props.selectedRecord.guardianAlertHistoryId;
		const notes = await new AlertHistoryNoteResource(id).getAll();
		this.setState({ notes });
	}

	public render() {
		return (
			<>
				<Aside
					disabledBody
					isOpen={this.props.isOpen}
					onClickOutside={() => this.props.onCancel()}
				>
					<Card.Header
						className="list-group-item-warning d-flex justify-content-between align-items-center"
						style={{ position: 'sticky', top: 0, zIndex: 1 }}
					>
						<h6 className="mb-0">
							<b>Alert History Notes</b>
						</h6>
						<Button variant="white-alt" onClick={() => this.props.onCancel()}>
							<i className="fas fa-fw fa-times" />
						</Button>
					</Card.Header>
					<RtUiForm
						disableBody
						createMode={false}
						hideButtons={true}
						displayMode={this.state.displayMode}
						isSubmitting={this.state.isSubmitting}
						error={this.state.error}
					>
						<AlertHistoryNotesControl
							key={
								this.props.selectedRecord?.guardianAlertHistoryId +
								String(this.state.notes?.length)
							}
							isSubmitting={this.state.isSubmitting}
							notes={this.state.notes || []}
							onNoteCreate={this.onNoteCreate}
						/>
					</RtUiForm>
				</Aside>
			</>
		);
	}

	private onNoteCreate = async (note: string): Promise<Boolean> => {
		if (!note.trim()) {
			return false;
		}

		const { guardianAlertHistoryId } = this.props.selectedRecord;
		this.setState({ error: undefined, isSubmitting: true });

		try {
			const alertHistoryNoteResource = new AlertHistoryNoteResource(
				guardianAlertHistoryId
			);

			const newNote = await alertHistoryNoteResource.create({
				guardianAlertHistoryId,
				note
			});

			this.setState({
				isSubmitting: false,
				notes: this.state.notes ? [newNote, ...this.state.notes] : [newNote]
			});
			return true;
		} catch (error) {
			this.setState({ error, isSubmitting: false });
			return false;
		}
	};
}
