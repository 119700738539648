/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum DataFilterOperator {
    Between = 'between',
    In = 'in',
    NotIn = 'not in',
    Begins = 'begins',
    NotLike = 'notlike',
    Contains = 'contains',
    Subselect = 'subselect',
    NotSubselect = 'notsubselect',
    LessThan = 'lessthan',
    GreaterThan = 'greaterthan',
    OrMatch = 'ormatch',
}