import { PartitionIndexResponse } from 'RtModels';
import { PartitionRouter } from 'RtUi/app/rtAdmin/Partitions/Partition.router';
import { PartitionBillingEntityEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionBillingEntityEditor';
import { PartitionEditForm } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionEditForm';
import { PartitionEntityEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionEntityEditor';
import { PartitionRatePlanEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionRatePlanEditor';
import { PartitionRespOrgEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionRespOrgEditor';
import { PartitionSipGatewayEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionSipGatewayEditor';
import { PartitionUrlEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionUrlEditor';
import { PartitionBillingEntityDataGrid } from 'RtUi/app/rtAdmin/Partitions/lib/grids/PartitionBillingEntityGrid';
import { PartitionEntityDataGrid } from 'RtUi/app/rtAdmin/Partitions/lib/grids/PartitionEntityGrid';
import { PartitionRespOrgDataGrid } from 'RtUi/app/rtAdmin/Partitions/lib/grids/PartitionRespOrgDataGrid';
import { PartitionSipGatewayDataGrid } from 'RtUi/app/rtAdmin/Partitions/lib/grids/PartitionSipGatewayDataGrid';
import { PartitionUrlsDataGrid } from 'RtUi/app/rtAdmin/Partitions/lib/grids/PartitionUrlsDataGrid';
import { PartitionResource } from 'RtUi/app/rtAdmin/lib/resources/PartitionResource';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { Alert } from 'react-bootstrap';

interface IPartitionProfileContainerState {
	activeTab: string;
	isLoading: boolean;
	partition?: PartitionIndexResponse;
}

@PartitionRouter.getProfileRtUiController()
export class PartitionProfileContainer extends ProfileApplicationContainer<
	{},
	IPartitionProfileContainerState
> {
	public Tabs = PartitionRouter.getProfileTabs();

	public state: IPartitionProfileContainerState = {
		activeTab: this.Tabs.Profile.header,
		isLoading: true
	};

	private partitionResource = new PartitionResource();

	public async componentDidMount() {
		const partitionId = Number(this.getIdParam());

		this.setState({ isLoading: true });
		const partition = await this.partitionResource.get(partitionId);
		if (!partition) {
			throw new Error('Partition not found');
		}

		this.setState({ partition, isLoading: false });
	}

	public render() {
		if (this.state.isLoading) {
			return <Loading />;
		}

		if (!this.state.partition) {
			return <Alert variant="danger">Partition not found.</Alert>;
		}

		return (
			<TabbedLayout<PartitionIndexResponse>
				profile={this.state.partition}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={PartitionRouter}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<PartitionEditForm
						partition={this.state.partition}
						onUpdate={(newPartition) =>
							this.setState({ partition: newPartition })
						}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.PartitionUrls}>
					<PartitionUrlsDataGrid
						autoFocusFilter
						partitionId={this.state.partition.partitionId}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.PartitionSipGateways}>
					<PartitionSipGatewayDataGrid
						autoFocusFilter
						partitionId={this.state.partition.partitionId}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.PartitionEntities}>
					<PartitionEntityDataGrid
						autoFocusFilter
						partitionId={this.state.partition.partitionId}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.PartitionRespOrgs}>
					<PartitionRespOrgDataGrid
						autoFocusFilter
						partitionId={this.state.partition.partitionId}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.PartitionBillingEntities}>
					<PartitionBillingEntityDataGrid
						autoFocusFilter
						partitionId={this.state.partition.partitionId}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.CreatePartitionUrl}>
					<PartitionUrlEditor
						createMode
						partitionId={this.state.partition.partitionId}
						onUpdate={() => this.openRouteIndexPage()}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.CreatePartitionSipGateway}>
					<PartitionSipGatewayEditor
						createMode
						partitionId={this.state.partition.partitionId}
						onUpdate={() => this.openRouteIndexPage()}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.CreatePartitionEntity}>
					<PartitionEntityEditor
						createMode
						partitionId={this.state.partition.partitionId}
						onSuccess={() => {
							this.setState({
								activeTab: this.Tabs.PartitionEntities.header
							});
						}}
						narrow={true}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.CreatePartitionRespOrgs}>
					<PartitionRespOrgEditor
						createMode
						narrow
						partitionId={this.state.partition.partitionId}
						onSuccess={() => {
							this.setState({
								activeTab: this.Tabs.PartitionRespOrgs.header
							});
						}}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.CreatePartitionBillingEntity}>
					<PartitionBillingEntityEditor
						createMode
						narrow={true}
						partitionId={this.state.partition.partitionId}
						onUpdate={() => {
							this.setState({
								activeTab: this.Tabs.PartitionBillingEntities.header
							});
						}}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.CreatePartitionRatePlan}>
					<PartitionRatePlanEditor
						createMode
						narrow={true}
						onUpdate={() => {
							this.setState({
								activeTab: this.Tabs.PartitionRatePlans.header
							});
						}}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}

	private openRouteIndexPage = () => {
		this.goToPath(PartitionRouter.getIndexRoute(true));
	};
}
