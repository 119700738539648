import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { LataIndexResponse } from 'RtModels';

export class LataResource extends ArrayResource<LataIndexResponse> {
	constructor() {
		super('lata');

		this.setApiRouteForGetAll(RtCommonApiRoutes.NanpLata.Index);
	}
}
