import { RtCommonApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class PortRequestRouterClass extends RtVueReportRouter<void, void, {}> {
	constructor() {
		super('Port Request', 'portRequest', undefined, {});

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.PortRequest);
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const PortRequestRouter = new PortRequestRouterClass();
