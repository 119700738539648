/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoutingRuleCreateRequest = {
    properties: {
        label: {
            type: 'string',
            isRequired: true,
        },
        notes: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isActive: {
            type: 'number',
        },
        isLcrAppend: {
            type: 'number',
        },
        isCascade: {
            type: 'number',
        },
        matching: {
            type: 'Array',
            isRequired: true,
        },
        actions: {
            type: 'Array',
            isRequired: true,
        },
        schedule: {
            type: 'Array',
            isRequired: true,
        },
    },
};