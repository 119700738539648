//import * as React from 'react';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { IntegrationTaskTypeResource } from 'RtUi/app/rtCommon/Integrations/resources/IntegrationTaskTypeResource';
import { IntegrationTaskTypeIndexResponse } from 'RtModels';

interface IIntegrationTaskTypeSelectProps
	extends ISelectFormControlProps<IntegrationTaskTypeIndexResponse> {
	showUnassignableToFlow?: boolean;
}

export class IntegrationTaskTypeSelect extends SelectFormControl<
	IntegrationTaskTypeIndexResponse,
	false,
	IIntegrationTaskTypeSelectProps
> {
	public resourceClass = IntegrationTaskTypeResource;
	public state: ISelectFormControlState<IntegrationTaskTypeIndexResponse> = {
		formLabel: 'Integration Task Type',
		valueKey: 'integrationTaskTypeId',
		labelKey: 'label'
	};

	/**
	 * @override
	 */
	public async getData() {
		let integrationTaskTypes = await super.getData();

		if (!this.props.showUnassignableToFlow) {
			integrationTaskTypes = integrationTaskTypes.filter(
				(itt) => itt.canAssignToFlow === 1
			);
		}

		return integrationTaskTypes;
	}
}
