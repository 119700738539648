/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianAlertHistoryFailureData = {
    properties: {
        failureTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        failureKey: {
            type: 'string',
            isRequired: true,
        },
        failureValue: {
            type: 'string',
            isRequired: true,
        },
        vuid: {
            type: 'string',
            isRequired: true,
        },
        rateCenterId: {
            type: 'number',
            isRequired: true,
        },
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        timeFilter: {
            type: 'TimeFilter',
            isRequired: true,
        },
        filters: {
            type: 'Array',
            isRequired: true,
        },
    },
};