import * as React from 'react';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import {
	ManagedRespOrgCreateRequest,
	ManagedRespOrgProfileResponse,
	Rt800ResourceIndexResponse
} from 'RtModels';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { ManagedRespOrgResource } from 'RtUi/app/rtAdmin/ManagedRespOrgs/lib/resources/ManagedRespOrgResource';
import { Col, Row } from 'react-bootstrap';
import { Rt800ApiResourceSelect } from 'RtUi/app/rtAdmin/lib/components/Rt800ApiResourceSelect';

interface IManagedRespOrgEditorProps {
	createMode?: boolean;
	editMode?: ManagedRespOrgProfileResponse;
	onCreate?: (managedRespOrg: ManagedRespOrgProfileResponse) => void;
	onUpdate?: (managedRespOrg: ManagedRespOrgProfileResponse) => void;
}

interface IManagedRespOrgEditorState extends ManagedRespOrgCreateRequest {
	displayMode: boolean;
	isSubmitting: boolean;
	apiResource?: Rt800ResourceIndexResponse;
	error?: any;
}

export class ManagedRespOrgEditor extends React.Component<
	IManagedRespOrgEditorProps,
	IManagedRespOrgEditorState
> {
	public state: IManagedRespOrgEditorState = {
		respOrgId: '',
		contactName: '',
		contactNumber: '',
		isActive: 1,
		isManaged: 0,
		isApi: 0,
		displayMode: true,
		isSubmitting: false,
		error: undefined
	};

	public componentIsMounted = false;

	public componentDidMount() {
		this.componentIsMounted = true;

		this.init();
	}

	public init(reset = false) {
		const { editMode, createMode } = this.props;

		if (editMode) {
			const {
				respOrgId,
				contactName,
				contactNumber,
				isActive,
				isManaged,
				isApi
			} = editMode;

			this.setState({
				respOrgId,
				contactName,
				contactNumber,
				isActive,
				isManaged,
				isApi
			});
		} else if (createMode) {
			this.setState({ displayMode: false });
		}

		if (reset) {
			this.setState({
				displayMode: true,
				error: undefined,
				apiResource: undefined
			});
		}
	}

	public componentWillUnmount() {
		this.componentIsMounted = false;
	}

	public submit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const managedRespOrgResource = new ManagedRespOrgResource();

		this.setState({ isSubmitting: true, error: undefined });

		const {
			respOrgId,
			isActive,
			isManaged,
			isApi,
			contactName,
			contactNumber,
			apiResourceId
		} = this.state;
		let updateOrCreatePromise: Promise<ManagedRespOrgProfileResponse>;

		if (this.props.createMode) {
			updateOrCreatePromise = managedRespOrgResource.create(
				respOrgId,
				isActive,
				isManaged,
				isApi,
				contactName,
				contactNumber,
				apiResourceId || null
			);
		} else {
			updateOrCreatePromise = managedRespOrgResource.update(
				respOrgId,
				isActive,
				isManaged,
				isApi,
				contactName,
				contactNumber,
				apiResourceId || null
			);
		}

		updateOrCreatePromise
			.then((ManagedRespOrg) => {
				if (this.props.createMode) {
					const { onCreate = () => ({}) } = this.props;

					onCreate(ManagedRespOrg);
				} else {
					const { onUpdate = () => ({}) } = this.props;

					onUpdate(ManagedRespOrg);

					this.setState({ displayMode: true });
				}
			})
			.catch((error) => {
				this.setState({ error });
			})
			.finally(() => {
				if (this.componentIsMounted) {
					this.setState({ isSubmitting: false });
				}
			});
	}

	public render() {
		return (
			<RtUiWideForm
				onCancel={() => this.init(true)}
				displayMode={this.state.displayMode}
				onChange={(displayMode) => this.setState({ displayMode })}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				createMode={this.props.createMode}
				onSubmit={(evt) => this.submit(evt)}
			>
				<Row>
					<Col md={6}>
						<InputFormControl
							label="RespOrg Id"
							required
							displayMode={
								Boolean(this.props.editMode) ? true : this.state.displayMode
							}
							onChange={(respOrgId) =>
								this.setState({
									respOrgId: respOrgId.toUpperCase()
								})
							}
							minLength={5}
							value={this.state.respOrgId}
						/>
					</Col>
					<Col md={6}>
						<IsActiveRadioFormControl
							required
							hideBothOption
							displayMode={this.state.displayMode}
							onChange={(isActive) => this.setState({ isActive })}
							value={this.state.isActive}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<InputFormControl
							label="Contact Name"
							required
							displayMode={this.state.displayMode}
							onChange={(contactName) => this.setState({ contactName })}
							value={this.state.contactName}
						/>
					</Col>
					<Col md={6}>
						<TollFreeNumberFormControl
							label="Contact Number"
							required
							onlyNumbers
							enforceAreCodeAndPrefixRules
							displayMode={this.state.displayMode}
							onChange={(contactNumber) => this.setState({ contactNumber })}
							value={this.state.contactNumber}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<BooleanRadioFormControl
							label="Managed"
							required
							displayMode={this.state.displayMode}
							onChange={(isManaged) => this.setState({ isManaged })}
							value={this.state.isManaged}
						/>
					</Col>
					<Col md={6}>
						<Rt800ApiResourceSelect
							value={this.state.apiResource}
							displayMode={this.state.displayMode}
							useInitialOptionIdOnUpdate={false}
							initialOptionId={this.props.editMode?.apiResourceId?.toString()}
							onChange={(apiResource: Rt800ResourceIndexResponse | null) =>
								this.setState({
									apiResource: apiResource ?? undefined,
									isApi: Boolean(apiResource) ? 1 : 0,
									apiResourceId: apiResource?.resourceId ?? null
								})
							}
						/>
					</Col>
				</Row>
			</RtUiWideForm>
		);
	}
}
