/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ManagedRespOrgUpdateRequest = {
    properties: {
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isManaged: {
            type: 'number',
            isRequired: true,
        },
        isApi: {
            type: 'number',
            isRequired: true,
        },
        contactName: {
            type: 'string',
            isRequired: true,
        },
        contactNumber: {
            type: 'string',
            isRequired: true,
        },
        apiResourceId: {
            type: 'number',
            isNullable: true,
        },
    },
};