import { useEffect, useState } from 'react';
import { ApplicationState, IApplicationState } from 'RtUi/state';
import ImmutableMap from 'RtUi/utils/immutable/ImmutableMap';

export const useAppState = () => {
	const currentAppState: ImmutableMap<IApplicationState> =
		ApplicationState.clone();

	const [appState, setAppState] =
		useState<ImmutableMap<IApplicationState>>(currentAppState);

	useEffect(() => ApplicationState.listen(setAppState), []);

	return appState;
};
