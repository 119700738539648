import { DataSources, RespOrgIndexResponse, SearchField } from 'RtModels';
import {
	IFilterSearchResultSection,
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { IndexedDbCacheStrategy } from 'RtUi/utils/cache/strategies/IndexedDbCacheStrategy';

/**
 * @singleton
 */
export class RtVueRespOrgFilter extends RtVueFilter<RespOrgIndexResponse> {
	public static getInstance() {
		if (!RtVueRespOrgFilter.instance) {
			RtVueRespOrgFilter.instance = new RtVueRespOrgFilter();
		}

		return RtVueRespOrgFilter.instance;
	}

	private static instance: RtVueRespOrgFilter | undefined;
	private static readonly FilterName = 'RtVueRespOrgFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<RespOrgIndexResponse>
	> = new IndexedDbCacheStrategy(RtVueRespOrgFilter.FilterName, 'respOrgId', [
		'label',
		'*keywords' as keyof RespOrgIndexResponse
	]);

	protected constructor() {
		super(
			RtVueRespOrgFilter.FilterName,
			DataSources.RespOrgs,
			RtVueRespOrgFilter.DataCache
		);
	}

	public async search(
		possibleSearchFields: SearchField[],
		searchStr: string
	): Promise<IFilterSearchResultSection[]> {
		// Omit '*' when searching
		return super.search(possibleSearchFields, searchStr.replace(/^\*/, ''));
	}

	public getLabelFor(record: RespOrgIndexResponse): string {
		return `${record.label} (${record.respOrgId})`;
	}

	public getValueFor(record: RespOrgIndexResponse): string {
		return record.respOrgId;
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<RespOrgIndexResponse>
	> {
		return this.vueHttp.getRespOrgFilter();
	}

	protected getKeywordsFor(record: RespOrgIndexResponse): string[] {
		const keywords: string[] = [
			record.respOrgId,
			...this.getKeywordsFromPhrase(record.label)
		];

		return keywords;
	}
}
