/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipCallSimulatorResponseDtoRateable = {
    properties: {
        accountId: {
            type: 'number',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        rateId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rateKey: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        rate: {
            type: 'number',
            isRequired: true,
        },
        jurisdictionId: {
            type: 'RateableJurisdictions',
        },
        interRate: {
            type: 'number',
            isRequired: true,
        },
        intraRate: {
            type: 'number',
            isRequired: true,
        },
        indetRate: {
            type: 'number',
            isRequired: true,
        },
        localRate: {
            type: 'number',
            isRequired: true,
        },
        rateCenterId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        conFee: {
            type: 'number',
            isRequired: true,
        },
        dipFee: {
            type: 'number',
            isRequired: true,
        },
        lcrPos: {
            type: 'number',
            isRequired: true,
        },
        finPos: {
            type: 'number',
            isRequired: true,
        },
        errors: {
            type: 'Array',
        },
    },
};