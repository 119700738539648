/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationMappingFiltersUpdateRequest = {
    properties: {
        dataSourceDirection: {
            type: 'ReconciliationMappingDataSourceDirection',
        },
        dataSourceField: {
            type: 'ReconciliationMappingDataSourceField',
        },
        dataSourceType: {
            type: 'ReconciliationMappingDataSourceType',
        },
        dataSourceValue: {
            type: 'string',
        },
        filters: {
            properties: {
            },
            isNullable: true,
        },
        notes: {
            type: 'string',
            isNullable: true,
        },
        isActive: {
            type: 'number',
        },
    },
};