import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { UserIndexResponse } from 'RtModels';

class MyProfileRouterClass extends RtUiRouter<
	UserIndexResponse,
	UserIndexResponse,
	{}
> {
	constructor() {
		super('', 'My Profile', 'myProfile', 'userId', {});
	}

	public getProfileRoute(
		record: UserIndexResponse | string | number,
		tabId?: string,
		prefixSectionKey = true
	) {
		return this.getIndexRoute(prefixSectionKey, tabId);
	}

	public getIndexRtUiController() {
		return this.getProfileRtUiController();
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: UserIndexResponse): string {
		return `${profile.firstName} ${profile.lastName}`;
	}
}

export const MyProfileRouter = new MyProfileRouterClass();
