import {
	CallSimulatorResponse,
	LrnProfileResponse,
	RoutingCacheTypes
} from 'RtModels';
import {
	ICallSimulatorParams,
	useGetAniLrnProfile,
	useGetCallSimulator
} from 'RtUi/app/rt800/CallSimulator/lib/services';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getCallSimulatorColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/callSimulator';
import * as RoutingCacheUtils from 'RtUi/app/rt800/Cprs/lib/util/RoutingCacheUtils';
import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { TemplateRouter } from 'RtUi/app/rt800/Templates/Template.router';
import { NumberRouter } from 'RtUi/app/rt800/Numbers/Number.router';
import { Item } from 'react-contexify';
import { useNavigate } from 'react-router-dom';

interface ICallSimulatorGridProps {
	simulatorParam: ICallSimulatorParams;
	onClickSimulator: (ani: string, aniLrnProfiles: LrnProfileResponse[]) => void;
}

const getSimulatedValueFormatted = (type: RoutingCacheTypes, value: string) => {
	if (type === RoutingCacheTypes.TollFree) {
		return TollFreeNumbersUtils.tollFreeNumberToReadableString(value);
	}

	return value;
};

export const CallSimulatorGrid = ({
	simulatorParam,
	onClickSimulator
}: ICallSimulatorGridProps) => {
	const { data: aniLrnProfile = {} } = useGetAniLrnProfile(simulatorParam.ani);
	const { data, isFetching: isLoading } = useGetCallSimulator(simulatorParam);
	const navigate = useNavigate();

	const columns = useMemo(() => getCallSimulatorColumns(), []);

	return (
		<>
			<h5 className="mb-4">
				Simulating&nbsp;
				<span className="text-muted">
					{TollFreeNumbersUtils.tollFreeNumberToReadableString(
						simulatorParam.ani
					)}
					&nbsp;
				</span>
				with {RoutingCacheUtils.getRoutingCacheTypeName(simulatorParam.type)}
				&nbsp;
				<span className="text-muted">
					{getSimulatedValueFormatted(
						simulatorParam.type,
						simulatorParam.simulateValue
					)}
				</span>
			</h5>
			<DataGrid<CallSimulatorResponse>
				data={data}
				columns={columns}
				loading={isLoading}
				pageName={'rt800_callSimulator'}
				headerAction={() => {
					const { aniProfile, lrnProfile } = aniLrnProfile;

					if (!aniProfile) {
						return (
							<Button variant="white" disabled>
								ANI/LRN Info Not Available
							</Button>
						);
					}

					return (
						<Button
							variant="info"
							className="text-white"
							onClick={() => {
								onClickSimulator(simulatorParam.ani, [
									aniProfile,
									...(lrnProfile ? [lrnProfile] : [])
								]);
							}}
						>
							ANI/LRN
						</Button>
					);
				}}
				rowThemeColor={(record) =>
					record.cprTimeline === 0 ? RowThemeColor.SUCCESS : undefined
				}
				menuLinks={(resource) => {
					const isTemplate = resource.tgtCprKey.charAt(0) === '*';
					const router = isTemplate ? TemplateRouter : NumberRouter;
					const routingKey = router.getProfileTabs().Routing.header;
					const profileRoutingPath = router.getProfileRoute(
						resource.tgtCprKey,
						routingKey
					);

					return (
						<Item onClick={() => navigate(profileRoutingPath)}>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-link" />
								<span>Go to {router.getName()}&apos;s Routing</span>
							</span>
						</Item>
					);
				}}
			/>
		</>
	);
};
