/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $UsageAuditByDayIndexResponse = {
    properties: {
        reconciliationId: {
            type: 'number',
            isRequired: true,
        },
        callDate: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        jurisdiction: {
            type: 'string',
            isRequired: true,
        },
        mappingDescription: {
            type: 'string',
            isRequired: true,
        },
        ban: {
            type: 'string',
            isRequired: true,
        },
        reconciliationSummary: {
            type: 'string',
            isRequired: true,
        },
        srcAttempts: {
            type: 'number',
            isRequired: true,
        },
        srcConnects: {
            type: 'number',
            isRequired: true,
        },
        srcDuration: {
            type: 'number',
            isRequired: true,
        },
        srcCost: {
            type: 'number',
            isRequired: true,
        },
        srcCpm: {
            type: 'number',
            isRequired: true,
        },
        tgtAttempts: {
            type: 'number',
            isRequired: true,
        },
        tgtConnects: {
            type: 'number',
            isRequired: true,
        },
        tgtDuration: {
            type: 'number',
            isRequired: true,
        },
        tgtCost: {
            type: 'number',
            isRequired: true,
        },
        tgtCpm: {
            type: 'number',
            isRequired: true,
        },
        matchedAttempts: {
            type: 'number',
            isRequired: true,
        },
        matchedConnects: {
            type: 'number',
            isRequired: true,
        },
        matchedDuration: {
            type: 'number',
            isRequired: true,
        },
        matchedCost: {
            type: 'number',
            isRequired: true,
        },
        matchedPercent: {
            type: 'number',
            isRequired: true,
        },
        reconciliationUsageDetailId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        carrierInvoiceCharge: {
            type: 'number',
            isRequired: true,
        },
    },
};