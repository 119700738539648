import {
	SubscriptionPeeringIndexRequest,
	SubscriptionPeeringIndexResponse
} from 'RtModels';
import { SubscriptionPeeringEditor } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/SubscriptionPeeringEditor';
import {
	deleteSubscriptionPeering,
	useGetSubscriptionPeering
} from 'RtUi/app/rtLcr/SubscriptionPeering/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSubscriptionPeeringColumns } from 'RtUi/components/data/DataGrid/configurations/rtLcr/subscriptionPeering';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { noop } from 'lodash-es';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMutation } from 'react-query';

interface ISubscriptionPeeringGridProps {
	resourceParams?: SubscriptionPeeringIndexRequest;
	onUpdate?: () => void;
}

export const SubscriptionPeeringGrid = ({
	resourceParams,
	onUpdate = noop
}: ISubscriptionPeeringGridProps) => {
	const [asideIsOpen, setAsideOpen] = useState(false);
	const { data, isFetching, refetch } =
		useGetSubscriptionPeering(resourceParams);
	const { mutateAsync: deleteSubscription } = useMutation(
		deleteSubscriptionPeering
	);
	const [selectedRecord, setSelectedRecord] =
		useState<SubscriptionPeeringIndexResponse>();

	const removeEntity = useCallback(
		async (row: SubscriptionPeeringIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				`${row.subscriptionPeeringId} - ${row.peeredValue}`
			);

			if (!confirm) {
				return;
			}

			await deleteSubscription(row.subscriptionPeeringId);
			refetch();
			onUpdate();
		},
		[deleteSubscription, refetch, onUpdate]
	);

	const columns = useMemo(
		() => getSubscriptionPeeringColumns(removeEntity),
		[removeEntity]
	);

	return (
		<>
			<DataGrid<SubscriptionPeeringIndexResponse>
				data={data?.data}
				totalRows={data?.totalCount}
				loading={isFetching}
				pageName="rtLcr-SubscriptionPeering"
				onRowClick={(row) => {
					setAsideOpen(true);
					setSelectedRecord(row);
				}}
				headerAction={() => {
					return (
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id={`subscription-peering-tooltip`} {...props}>
									Create Subscription Peering
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									onClick={() => {
										setAsideOpen(true);
									}}
									variant="white"
									id="subscriptionPeering"
								>
									<i className="fas fa-fw fa-plus fa-lg" />
								</Button>
							)}
						</OverlayTrigger>
					);
				}}
				columns={columns}
			/>
			<Aside isOpen={asideIsOpen} disabledBody>
				<Fragment key={selectedRecord?.subscriptionPeeringId}>
					<Aside.Header
						warning={Boolean(selectedRecord)}
						header={
							Boolean(selectedRecord)
								? 'Update subscription peering'
								: 'Add subscription peering'
						}
						onClose={() => {
							setAsideOpen(false);
							setSelectedRecord(undefined);
						}}
					/>
					<SubscriptionPeeringEditor
						subscriptionPeeringId={selectedRecord?.subscriptionPeeringId ?? -1}
						subscriptionId={resourceParams?.subscriptionId}
						editMode={selectedRecord}
						onCancel={() => {
							setAsideOpen(false);
							setSelectedRecord(undefined);
						}}
						onSuccess={() => {
							setAsideOpen(false);
							setSelectedRecord(undefined);
						}}
					/>
				</Fragment>
			</Aside>
		</>
	);
};
