import { useMemo } from 'react';
import {
	ManagedEntityIndexRequest,
	ManagedEntityIndexResponse
} from 'RtModels';
import { useGetManagedEntities } from 'RtUi/app/rtAdmin/ManagedEntities/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getManagedEntitiesColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/managedEntities';
import { ManagedEntityRouter } from '../../ManagedEntity.router';

interface IManagedEntityGridProps {
	onRowClick?: (row: ManagedEntityIndexResponse) => void;
	resourceParams?: ManagedEntityIndexRequest;
}

export const ManagedEntityGrid = ({
	resourceParams
}: IManagedEntityGridProps) => {
	const { data, isLoading } = useGetManagedEntities(resourceParams);

	const columns = useMemo(() => getManagedEntitiesColumns(), []);

	return (
		<DataGrid
			data={data?.data}
			columns={columns}
			router={ManagedEntityRouter}
			pageName={'rtAdmin-managedEntities'}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
