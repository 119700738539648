import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IReportRoute } from 'RtUi/app/@RtUi/RtUiDecorators';

interface ReportRoutesMap {
	[key: string]: IReportRoute[];
}

interface ReportRoutesState {
	routesMap: ReportRoutesMap;
}

const initialState: ReportRoutesState = {
	routesMap: {}
};

const reportRoutesSlice = createSlice({
	name: 'ReportRoutes',
	initialState,
	reducers: {
		setRoutes: (state, action: PayloadAction<ReportRoutesMap>) => {
			state.routesMap = action.payload;
		}
	}
});

export const reportRoutesActions = reportRoutesSlice.actions;

export default reportRoutesSlice.reducer;
