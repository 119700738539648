import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { Alert, Card } from 'react-bootstrap';
import { ResourceType } from 'RtModels';
import { SelectedSummariesContext } from 'RtUi/app/rtDid/Dashboard/lib/components/context';

interface IMetricSummaryItemProps {
	resourceTypes: ResourceType[];
	color: 'danger' | 'warning' | 'success';
	isLoading?: boolean;
	className?: string;
}

export class MetricSummaryItem extends React.PureComponent<
	React.PropsWithChildren<IMetricSummaryItemProps>
> {
	public getCardClassNames(selected: ResourceType[]) {
		const { className = '', resourceTypes: summaryTypes } = this.props;
		const cardClassNames = [className, 'shadow-on-hover'];

		if (selected.length === summaryTypes.length) {
			const isMatch = summaryTypes.every((summaryType) =>
				selected.includes(summaryType)
			);

			if (isMatch) {
				cardClassNames.push('shadow');
			}
		}

		return cardClassNames.join(' ');
	}

	public render() {
		if (this.props.isLoading) {
			return (
				<section className={this.props.className}>
					<ContentLoader preserveAspectRatio="none" height={46} width={640}>
						<rect x={0} y={0} rx={5} ry={5} width={640} height={46} />
					</ContentLoader>
				</section>
			);
		}

		const { color } = this.props;
		const iconClassNames = ['fas', 'fa-fw'];

		if (color === 'danger') {
			iconClassNames.push('fa-exclamation');
		} else if (color === 'warning') {
			iconClassNames.push('fa-exclamation');
		} else if (color === 'success') {
			iconClassNames.push('fa-check');
		}

		return (
			<SelectedSummariesContext.Consumer>
				{({ selected, toggleSelected, onClick }) => (
					<Card
						className={this.getCardClassNames(selected)}
						onMouseOver={() => toggleSelected(this.props.resourceTypes)}
						onMouseOut={() => toggleSelected([])}
						onClick={() => onClick(this.props.resourceTypes)}
						style={{ cursor: 'pointer' }}
					>
						<article className="d-flex justify-content-start">
							<Alert
								className="mb-0 me-3"
								transition={false}
								variant={this.props.color}
								style={{
									margin: '-1px 0 -1px -1px',
									borderTopRightRadius: 0,
									borderBottomRightRadius: 0
								}}
							>
								<i className={iconClassNames.join(' ')} />
							</Alert>
							<article className="flex-fill align-self-center">
								<div className={color === 'danger' ? 'text-danger' : ''}>
									{this.props.children}
								</div>
							</article>
						</article>
					</Card>
				)}
			</SelectedSummariesContext.Consumer>
		);
	}
}
