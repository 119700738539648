import {
	FileLogRequest,
	FileLogResponse
} from 'RtUi/app/rtVue/FileLog/lib/grids/FileLogGrid';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const getFileLogsColumns = <T extends FileLogRequest>(
	showOnlyTotals: boolean
): Array<DataGridColumn<FileLogResponse<T>>> => [
	DefaultDataGridColumn({
		accessorKey: 'fileId',
		header: 'File Id',
		isInvisible: showOnlyTotals
	}),
	DefaultDataGridColumn({
		accessorKey: 'fileName',
		header: 'File Name',
		isInvisible: showOnlyTotals
	}),
	FileStreamDataGridColumn({
		accessorKey: 'fileStreamId',
		header: 'File Stream'
	}),
	IntegerDataGridColumn({
		accessorKey: 'recordCount',
		header: 'Record Count'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created',
		timezone: 'UTC',
		isInvisible: showOnlyTotals
	})
];
