import { CallTypeIndexResponse, DataSources } from 'RtModels';
import {
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { MemoryCacheStrategy } from 'RtUi/utils/cache/strategies/MemoryCacheStrategy';

/**
 * @singleton
 */
export class RtVueCallTypeFilter extends RtVueFilter<CallTypeIndexResponse> {
	public static getInstance() {
		if (!RtVueCallTypeFilter.instance) {
			RtVueCallTypeFilter.instance = new RtVueCallTypeFilter();
		}

		return RtVueCallTypeFilter.instance;
	}

	private static instance: RtVueCallTypeFilter | undefined;
	private static readonly FilterName = 'RtVueCallTypeFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<CallTypeIndexResponse>
	> = new MemoryCacheStrategy();

	protected constructor() {
		super(
			RtVueCallTypeFilter.FilterName,
			DataSources.CallType,
			RtVueCallTypeFilter.DataCache
		);
	}

	public getLabelFor(record: CallTypeIndexResponse): string {
		return record.callTypeId;
	}

	public getValueFor(record: CallTypeIndexResponse): string {
		return String(record.callTypeId);
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<CallTypeIndexResponse>
	> {
		return this.vueHttp.getCallTypeFilter();
	}

	protected getKeywordsFor(record: CallTypeIndexResponse): string[] {
		return [record.callTypeId];
	}
}
