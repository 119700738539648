/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TaskErrorIndexResponse = {
    properties: {
        taskErrorId: {
            type: 'number',
            isRequired: true,
        },
        taskId: {
            type: 'number',
            isRequired: true,
        },
        routingCacheTypeId: {
            type: 'number',
            isRequired: true,
        },
        routingCacheKey: {
            type: 'string',
            isRequired: true,
        },
        responseId: {
            type: 'number',
            isRequired: true,
        },
        isWarning: {
            type: 'number',
            isRequired: true,
        },
        responseValue: {
            type: 'string',
            isRequired: true,
        },
    },
};