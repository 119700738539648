import { GuardianReconciliationMappingIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { UserNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';

export const getReconciliationMappingColumns = (): Array<
	DataGridColumn<GuardianReconciliationMappingIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'guardianReconciliationMappingId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'label'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated'
	}),
	UserNameDataGridColumn({
		accessorKey: 'updatedBy',
		header: 'Updated By'
	})
];
