import { RoutingRuleScheduleResponse, TimezoneIndexResponse } from 'RtModels';
import { TimezoneSelect } from 'RtUi/app/rtCommon/Timezones/lib/controls/TimezoneSelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import moment from 'moment';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import { Col, Form, Row } from 'react-bootstrap';

interface IRoutingRulesSchedulesEditorProps {
	schedule: RoutingRuleScheduleResponse;
	onChange: (schedule: RoutingRuleScheduleResponse) => void;
	onCancel?: () => void;
}

export const RoutingRulesSchedulesEditor = ({
	schedule,
	onChange,
	onCancel
}: IRoutingRulesSchedulesEditorProps): JSX.Element => {
	const [timezone, setTimezone] = useState<TimezoneIndexResponse>();
	const { control, handleSubmit } = useForm<RoutingRuleScheduleResponse>({
		defaultValues: schedule
	});

	const onChangeHandler = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();
		return handleSubmit(onChange)(e);
	};

	return (
		<RtUiForm onSubmit={onChangeHandler} onCancel={onCancel}>
			<Controller
				control={control}
				name="tz"
				render={({ field: { onChange, value } }) => (
					<TimezoneSelect
						value={timezone}
						valueKey="label"
						onChange={(val) => {
							setTimezone(val);
							onChange(val.label);
						}}
						initialOptionId={value}
					/>
				)}
			/>
			<Controller
				control={control}
				name="frTs"
				render={({ field: { onChange, value } }) => (
					<DatetimeFormControl
						showDateOnly
						label="From Date"
						required
						onChange={(date) => onChange(date.toDate())}
						value={moment(value)}
					/>
				)}
			/>
			<Controller
				control={control}
				name="toTs"
				render={({ field: { onChange, value } }) => (
					<DatetimeFormControl
						showDateOnly
						label="To Date"
						required
						onChange={(date) => onChange(date.toDate())}
						value={moment(value)}
					/>
				)}
			/>
			<Controller
				control={control}
				name="frSec"
				render={({ field: { onChange, value } }) => (
					<section className="rdtDateTimeRangeControl">
						<Form.Label>From Time</Form.Label>
						<Form.Group className="rdtDateTimeRangePresets mb-3">
							<div className="d-lg-flex flat-picker-wrapper w-100">
								<Flatpickr
									options={{
										altInput: true,
										enableTime: true,
										noCalendar: true,
										dateFormat: 'H:i',
										// eslint-disable-next-line camelcase
										time_24hr: true
									}}
									value={value}
									className="w-100"
									onChange={(startSecond) => onChange(startSecond[0].getTime())}
								/>
							</div>
						</Form.Group>
					</section>
				)}
			/>
			<Controller
				control={control}
				name="toSec"
				render={({ field: { onChange, value } }) => (
					<section className="rdtDateTimeRangeControl">
						<Form.Label>To Time</Form.Label>
						<Form.Group className="rdtDateTimeRangePresets mb-3">
							<div className="d-lg-flex flat-picker-wrapper w-100">
								<Flatpickr
									options={{
										altInput: true,
										enableTime: true,
										noCalendar: true,
										dateFormat: 'H:i',
										// eslint-disable-next-line camelcase
										time_24hr: true
									}}
									value={value}
									className="w-100"
									onChange={(startSecond) => onChange(startSecond[0].getTime())}
								/>
							</div>
						</Form.Group>
					</section>
				)}
			/>
			<Row>
				<Col md={6}>
					<Controller
						control={control}
						name="isSun"
						render={({ field: { onChange, value } }) => (
							<BooleanRadioFormControl
								label="Is Sunday?"
								required
								value={value}
								onChange={(val) => onChange(val)}
							/>
						)}
					/>
				</Col>
				<Col md={6}>
					<Controller
						control={control}
						name="isMon"
						render={({ field: { onChange, value } }) => (
							<BooleanRadioFormControl
								label="Is Monday?"
								required
								value={value}
								onChange={(val) => onChange(val)}
							/>
						)}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<Controller
						control={control}
						name="isTue"
						render={({ field: { onChange, value } }) => (
							<BooleanRadioFormControl
								label="Is Tuesday?"
								required
								value={value}
								onChange={(val) => onChange(val)}
							/>
						)}
					/>
				</Col>
				<Col md={6}>
					<Controller
						control={control}
						name="isWed"
						render={({ field: { onChange, value } }) => (
							<BooleanRadioFormControl
								label="Is Wednesday?"
								required
								value={value}
								onChange={(val) => onChange(val)}
							/>
						)}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<Controller
						control={control}
						name="isThu"
						render={({ field: { onChange, value } }) => (
							<BooleanRadioFormControl
								label="Is Thursday?"
								required
								value={value}
								onChange={(val) => onChange(val)}
							/>
						)}
					/>
				</Col>
				<Col md={6}>
					<Controller
						control={control}
						name="isFri"
						render={({ field: { onChange, value } }) => (
							<BooleanRadioFormControl
								label="Is Friday?"
								required
								value={value}
								onChange={(val) => onChange(val)}
							/>
						)}
					/>
				</Col>
			</Row>
			<Controller
				control={control}
				name="isSat"
				render={({ field: { onChange, value } }) => (
					<BooleanRadioFormControl
						label="Is Saturday?"
						required
						value={value}
						onChange={(val) => onChange(val)}
					/>
				)}
			/>
		</RtUiForm>
	);
};
