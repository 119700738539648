import { ActivePortDetailIndexResponse } from 'RtModels';
import { useGetActivePortsSummary } from 'RtUi/app/rtSip/ActivePortsSummary/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getActivePortsSummaryColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/activePortsSummary';
import { useMemo } from 'react';

interface IActivePortsSummaryGridProps {
	autoFocusFilter?: boolean;
}

export const ActivePortsSummaryDataGrid =
	({}: IActivePortsSummaryGridProps) => {
		const { data, isFetching: isLoading } = useGetActivePortsSummary();
		const columns = useMemo(() => getActivePortsSummaryColumns(), []);

		return (
			<DataGrid<ActivePortDetailIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtSip_activePortsSummary'}
			/>
		);
	};
