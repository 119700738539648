import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { BlockedNumber } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { BlockNumberResource } from 'RtUi/app/rtSip/BlockNumbers/lib/resources/BlockNumbersResource';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';

interface IBlockNumbersRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const BlockNumbersContainerTabs: IBlockNumbersRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class BlockNumbersRouterClass extends SipApplicationRouter<
	BlockedNumber,
	BlockedNumber,
	IBlockNumbersRouterTabs
> {
	constructor() {
		super(
			'Blocked Numbers',
			'blockedNumbers',
			'blockedNumberId',
			BlockNumbersContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.BlockedNumbers);
	}

	public async globalSearch(search: string) {
		search = TollFreeNumbersUtils.tollFreeNumberToReadableString(
			search,
			false,
			true
		);

		const hasCorrectLengthForSearch = [10, 11].includes(search.length);

		if (hasCorrectLengthForSearch) {
			return this.defaultGlobalSearch(BlockNumberResource, search);
		}

		return [];
	}

	public getIndexLabel(index: BlockedNumber): string {
		const phoneFormatter = new PhoneNumberFormatter();
		const formattedNumber = phoneFormatter.toInternational(index.phoneNumber);

		return formattedNumber ?? index.phoneNumber;
	}

	public getProfileLabel(profile: BlockedNumber): string {
		return this.getIndexLabel(profile);
	}

	public getPluralName() {
		return this.getName();
	}
}

export const BlockNumbersRouter = new BlockNumbersRouterClass();
