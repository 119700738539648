
import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class CprColTenDigit extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.TenDigit;

	public readonly allowOther = true;
	public readonly valueLimit = 1;
	public readonly valueRegExp = /^\d{10}$/;

	public readonly isLergNode: boolean = false; // @TODO maybe true
	public readonly isTreeNode = true;

	protected readonly canLabel = true;

	/**
	 * @override
	 */
	public isPossibleValue(rawVal: string) {
		const npaNxx = rawVal.substring(0, 6);

		return npaNxx in CprLergCache.CprLergByNpaNxx;
	}

	/**
	 * @override
	 */
	public getUnknownValueMessage(): string {
		return 'Unknown NPA/NXX value.';
	}

	/**
	 * @override
	 */
	 public shouldHighlightValue(rawVal: string) {
		const tenDigitMatch = this.cpr.shouldHighlightValue(this.cprNodeTypeId, rawVal);

		if (tenDigitMatch) {
			return tenDigitMatch;
		}

		const npaNxx = rawVal.substring(0, 6);
		const npa = npaNxx.substring(0, 3);

		const npaNxxMatch = this.cpr.shouldHighlightValue(CprNodeType.NpaNxx, npaNxx);

		if (npaNxxMatch) {
			return npaNxxMatch;
		}

		const npaMatch = this.cpr.shouldHighlightValue(CprNodeType.AreaCode, npa);

		if (npaMatch) {
			return npaMatch;
		}

		return undefined;
	}

	public getPossibles(search: string, excludeCurrent: boolean = true, limit: number = 0): string[] {
		if (search.startsWith('*')) {
			return super.getPossibles(search, excludeCurrent, limit);
		}

		const filteredPossibles: string[] = [];
		// @TODO possible NPA or NPA/NXX matching
		return filteredPossibles;
	};

}
