/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DirectConnectProfileRequest = {
    properties: {
        directConnectId: {
            type: 'number',
            isRequired: true,
        },
    },
};