import { cloneDeep, difference, isEmpty, keys } from 'lodash-es';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { urlFormat } from 'RtUi/utils/urlFormat/urlFormat';

export interface ISearchResultsContainerState<ResourceParamsType = any> {
	activeTab: string;
	resourceParams: ResourceParamsType;
	defaultRespOrgId?: string;
	defaultEntityId?: string;
}

export abstract class SearchResultsContainer<
	PropType,
	RevertibleParams,
	StateType extends ISearchResultsContainerState<ResourceParamsType> &
		RevertibleParams,
	ResourceParamsType = any
> extends ApplicationContainer<PropType, StateType> {
	public tabs = {
		Search: 'Search',
		Results: 'Results',
		Actions: 'Actions'
	};

	public abstract initParams: RevertibleParams;
	public abstract state: StateType;

	public abstract getResourceParams(
		defaultEntityId: string | undefined,
		defaultRespOrgId: string | undefined
	): ResourceParamsType;

	protected pollForUsersDefaultsAndUpdateResourceParams() {
		const defaultEntityId = this.getUsersDefaultEntityId();
		const defaultRespOrgId = this.getUsersDefaultRespOrgId();

		this.setState({ defaultEntityId, defaultRespOrgId });

		this.updateResourceParams();
	}

	protected setActiveTab(activeTab: string) {
		this.updateResourceParams(() => {
			this.setState({ activeTab });
		});
	}

	protected resetRevertibleParamsToInitial(callback?: () => void) {
		const activeTab = this.state.activeTab;
		const keysInState = keys(this.state);
		const keysInInitState = keys(this.initParams);
		const keysDefinedInInitState = keysInInitState.filter(
			// @ts-expect-error
			(key) => typeof this.initParams[key] !== 'undefined'
		);
		const keysToRemoveFromState = difference(
			keysInState,
			keysDefinedInInitState
		);

		//Remove keys that have been defined
		// @ts-expect-error
		const initStateWithRemovals: Partial<StateType> = cloneDeep(
			this.initParams
		);
		keysToRemoveFromState.forEach((possibleKeyToRemove) => {
			// @ts-expect-error
			initStateWithRemovals[possibleKeyToRemove] = undefined;
		});

		initStateWithRemovals.activeTab = activeTab;

		//@ts-ignore -- TODO better typings with R and S needed
		this.setState(initStateWithRemovals, () => {
			this.updateResourceParams(callback);
		});
	}

	protected submitAndGoToResults(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		this.setActiveTab(this.tabs.Results);
	}

	protected updateResourceParams(callback?: () => void) {
		const resourceParams = this.internalGetResourceParams();
		if (!isEmpty(resourceParams)) {
			window.history.replaceState(
				null,
				'RouteTrust',
				`?${urlFormat(resourceParams)}`
			);
		}

		this.setState({ resourceParams }, callback);
	}

	/**
	 * WARNING: Only use within a container's constructor
	 */
	protected updateResourceParamsInConstructor() {
		const resourceParams = this.internalGetResourceParams();

		if (!this.state) {
			// TODO -- fix assertion
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			this.state = {} as StateType;
		}

		this.state.resourceParams = resourceParams;
	}

	private internalGetResourceParams() {
		const defaultEntityId = this.getUsersDefaultEntityId();
		const defaultRespOrgId = this.getUsersDefaultRespOrgId();
		const resourceParams = this.getResourceParams(
			defaultEntityId,
			defaultRespOrgId
		);

		return resourceParams;
	}
}
