/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum SipAgentMedValue {
    Fqdn = 1,
    ParamOtg = 10,
    ParamTgrp = 20,
    MedDefaultSubId = 90,
    MedNotFoundSubId = 91,
}