import {
	ITollfreeFilter,
	SearchAndReserveFiltersGrid
} from 'RtUi/app/rt800/SearchAndReserve/lib/grids/SearchAndReserveFiltersGrid';
import { TemplateResource } from 'RtUi/app/rt800/Templates/lib/resources/TemplateResource';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { UserActions } from 'RtUi/state/actions/user';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';
import { useState } from 'react';
import { Accordion, Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { SearchAndReserveRouter } from './SearchAndReserve.router';
import { ADVANCED_FILTER } from './lib/resources/TollFreeSearchResource';

export const SearchAndReserveIndexContainer = () => {
	const [filters, setFilters] = useState<ITollfreeFilter[]>([]);
	const [advancedFilters, setAdvancedFilters] = useState<ADVANCED_FILTER[]>([]);
	const [searchText, setSearchText] = useState<string>('');

	const usersDefaultRespOrg = UserActions.getDefaultRespOrgId();

	const { data: reservedTemplates = [] } = useQuery(
		[`getSearchAndReserveFiltersGrid`, usersDefaultRespOrg],
		() => {
			const templateResource = new TemplateResource();
			return templateResource.getReservedTemplates(usersDefaultRespOrg);
		}
	);

	const updateSearchText = (value: string) => {
		//Remove Non-word, non-digit, non-wildcard characters
		const searchText = value.replace(/[^\w\d\&\*]/g, '');

		setSearchText(searchText);
	};

	const applyAdvancedFilter = (
		advancedFilter: ADVANCED_FILTER,
		value: boolean
	) => {
		setAdvancedFilters((currentState) => {
			let advancedFilters = [...currentState];

			if (value) {
				advancedFilters.push(advancedFilter);
			} else {
				advancedFilters = advancedFilters.filter(
					(filter) => filter !== advancedFilter
				);
			}

			return advancedFilters;
		});
	};

	const onSearch = () => {
		const searchInput = searchText;
		const searchValue = searchInput.replace(/[\_]/g, '*').replace(/[\-]/g, '');
		const searchedNumber =
			PhoneNumberFormatter.convertLettersToNumbers(searchInput);
		const filter: ITollfreeFilter = {
			uuid: generateUUID(),
			searchInput,
			advancedFilters,
			searchValue,
			searchedNumber
		};

		//Set and Open once Rendered
		setFilters((currentState) => {
			const newState = [...currentState];
			newState.splice(0, 0, filter);

			return newState;
		});
	};

	return (
		<StandardLayout router={SearchAndReserveRouter}>
			<Row>
				<Col lg={10} xl={8}>
					<Alert variant="info-outline">
						<p className="mb-0">
							Enter any alphanumeric or wildcard value. Wildcard symbols are
							asterisks (*) and ampersands (&). The asterisk wildcard indicates
							any number. While an ampersand indicates any repeated number.
						</p>
					</Alert>
					{filters.length > 0 && (
						<>
							<Alert variant="warning">
								<p className="mb-0">
									Search Tasks place a temporary hold (approximately 5 minutes)
									on the returned Toll-Free Numbers. Unless Reserved these
									numbers will be automatically returned to the Spare/Available
									pool, and may be assigned to another RespOrg.
								</p>
							</Alert>
							<Alert variant="warning">
								<p className="mb-0">
									Reserved Toll-Free Numbers are held for 45 days, and
									automatically returned to the Spare/Available pool when this
									time expires.
								</p>
							</Alert>
						</>
					)}
					<Form
						className="d-flex align-items-center"
						onSubmit={(e) => {
							e.preventDefault();
							onSearch();
						}}
					>
						<article
							className="d-flex flex-fill"
							style={{ position: 'relative' }}
						>
							<i
								className="fa fa-fw fa-search text-muted"
								style={{
									position: 'absolute',
									userSelect: 'none',
									left: 7,
									top: 12
								}}
							/>
							<InputFormControl
								label=""
								type="text"
								required
								className="me-2 flex-fill"
								style={{
									paddingLeft: 'calc(15px + 1em)'
								}}
								minLength={2}
								placeholder="Search Pattern (E.g. 888****&&&)"
								useControlGroup={false}
								onChange={updateSearchText}
								autoFocus
								value={searchText}
							/>
						</article>
						<Button variant="submit" type="submit">
							Search
						</Button>
					</Form>
					<Accordion className="mt-3">
						<Accordion.Item eventKey="advanced-filters">
							<Accordion.Button
								as="h4"
								className="d-flex justify-content-start align-items-center"
							>
								Advanced Filters
							</Accordion.Button>
							<Accordion.Collapse eventKey="advanced-filters">
								<div className="m-4">
									<div className="d-flex align-items-center gap-3">
										<span>NPA:</span>
										<CheckboxFormControl
											label="800"
											value={advancedFilters.includes(ADVANCED_FILTER.NPA800)}
											onChange={(value) =>
												applyAdvancedFilter(ADVANCED_FILTER.NPA800, value)
											}
										/>
										<CheckboxFormControl
											label="888"
											value={advancedFilters.includes(ADVANCED_FILTER.NPA888)}
											onChange={(value) =>
												applyAdvancedFilter(ADVANCED_FILTER.NPA888, value)
											}
										/>
										<CheckboxFormControl
											label="877"
											value={advancedFilters.includes(ADVANCED_FILTER.NPA877)}
											onChange={(value) =>
												applyAdvancedFilter(ADVANCED_FILTER.NPA877, value)
											}
										/>
										<CheckboxFormControl
											label="866"
											value={advancedFilters.includes(ADVANCED_FILTER.NPA866)}
											onChange={(value) =>
												applyAdvancedFilter(ADVANCED_FILTER.NPA866, value)
											}
										/>
										<CheckboxFormControl
											label="855"
											value={advancedFilters.includes(ADVANCED_FILTER.NPA855)}
											onChange={(value) =>
												applyAdvancedFilter(ADVANCED_FILTER.NPA855, value)
											}
										/>
										<CheckboxFormControl
											label="844"
											value={advancedFilters.includes(ADVANCED_FILTER.NPA844)}
											onChange={(value) =>
												applyAdvancedFilter(ADVANCED_FILTER.NPA844, value)
											}
										/>
										<CheckboxFormControl
											label="833"
											value={advancedFilters.includes(ADVANCED_FILTER.NPA833)}
											onChange={(value) =>
												applyAdvancedFilter(ADVANCED_FILTER.NPA833, value)
											}
										/>
									</div>
									<CheckboxFormControl
										label="Contiguous"
										className="mt-2"
										value={advancedFilters.includes(ADVANCED_FILTER.Contiguous)}
										onChange={(value) =>
											applyAdvancedFilter(ADVANCED_FILTER.Contiguous, value)
										}
										disabled={advancedFilters.includes(
											ADVANCED_FILTER.AllowSuggestions
										)}
									/>
									<CheckboxFormControl
										label="Allow Suggestions"
										className="mt-2"
										value={advancedFilters.includes(
											ADVANCED_FILTER.AllowSuggestions
										)}
										onChange={(value) =>
											applyAdvancedFilter(
												ADVANCED_FILTER.AllowSuggestions,
												value
											)
										}
										disabled={advancedFilters.includes(
											ADVANCED_FILTER.Contiguous
										)}
									/>
								</div>
							</Accordion.Collapse>
						</Accordion.Item>
					</Accordion>
					{filters.map((filter) => (
						<section className="mt-5" key={filter.uuid}>
							<SearchAndReserveFiltersGrid
								filter={filter}
								reservedTemplates={reservedTemplates}
							/>
						</section>
					))}
				</Col>
			</Row>
		</StandardLayout>
	);
};

SearchAndReserveRouter.setIndexRtUiFunctionalComponent(
	SearchAndReserveIndexContainer,
	{
		description:
			'Search for single number from spare using vanity or wildcard values.'
	}
);
