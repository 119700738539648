/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum TransactionTypes {
    TRANS_TYPE_CUST_INVOICE = 1,
    TRANS_TYPE_CUST_DISPUTE_CREDIT = 2,
    TRANS_TYPE_CUST_OFFSET = 3,
    TRANS_TYPE_CUST_PAYMENT = 4,
    TRANS_TYPE_VEND_INVOICE = 5,
    TRANS_TYPE_VEND_DISPUTE_CREDIT = 6,
    TRANS_TYPE_VEND_OFFSET = 7,
    TRANS_TYPE_VEND_PAYMENT = 8,
}