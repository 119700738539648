import {
	CprNumberIndexResponse,
	TaskErrorIndexResponse,
	TaskIndexResponse,
	TaskNumberIndexResponse
} from 'RtModels';
import { TaskAlertsWarning } from 'RtUi/app/rt800/Tasks/lib/utils/Constants';
import {
	DataGridColumn,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FutureCprsDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FutureCprsDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { SomosCprStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SomosCprStatus';
import { TaskErrorWarningDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TaskErrorWarningDataGridColumn';
import { TaskQtyDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TaskQtyDataGridColumn';
import { TaskTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TaskTypeDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { TollFreeNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeNumberDataGridColumn';
import { timestampToReadable } from 'RtUi/utils/maps';
import moment from 'moment';

const isAfterCache = new Map<string, boolean>();

const isAfterNow = (scheduledAt?: string | Date) => {
	if (!scheduledAt) {
		return false;
	}

	if (typeof scheduledAt !== 'string') {
		scheduledAt = scheduledAt.toISOString();
	}

	if (!isAfterCache.has(scheduledAt)) {
		const isAfterNowValue = moment(scheduledAt).isAfter(moment());

		isAfterCache.set(scheduledAt, isAfterNowValue);
	}

	return isAfterCache.get(scheduledAt);
};

export const getTasksColumns = (): Array<DataGridColumn<TaskIndexResponse>> => [
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	TaskTypeDataGridColumn({ accessorKey: 'taskTypeId' }),
	...RespOrgColumnsBuilder<TaskIndexResponse>({
		accessorKey: 'respOrgId',
		header: 'RespOrg'
	}),
	DefaultDataGridColumn({
		accessorKey: 'taskLabel',
		header: 'Label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'referenceKey',
		header: 'Reference'
	}),
	DefaultDataGridColumn({
		accessorKey: 'scheduledAt',
		header: 'Scheduled At',
		highlightCell: ({ cell }) =>
			isAfterNow(cell.getValue<string>()) ? RowThemeColor.INFO : undefined,
		getValue: ({ cell }) => (
			<span className="d-flex justify-content-start align-items-center gap-2">
				{timestampToReadable(cell.getValue<string>())}
				{isAfterNow(cell.getValue<string>()) && (
					<i className="fas fa-fw fa-clock text-info" />
				)}
			</span>
		)
	}),
	IntegerDataGridColumn({
		accessorKey: 'tfnQty',
		header: 'TFN Qty'
	}),
	TaskQtyDataGridColumn({
		accessorKey: 'warningQty',
		header: 'Warning Qty',
		headerTooltip: TaskAlertsWarning
	}),
	TaskQtyDataGridColumn({
		accessorKey: 'errorQty',
		header: 'Error Qty',
		headerTooltip: TaskAlertsWarning,
		highlightCell: ({ cell }) =>
			cell.getValue<number>() > 0 ? RowThemeColor.DANGER : undefined
	}),
	TimeStampDataGridColumn({
		accessorKey: 'processedAt',
		header: 'Processed At'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'completedAt',
		header: 'Completed At'
	}),
	DefaultDataGridColumn({
		accessorKey: 'email',
		header: 'E-Mail'
	})
];

export const getTaskNumbersColumns = (
	futureCprs: CprNumberIndexResponse[],
	hasActivations: boolean
): Array<DataGridColumn<TaskNumberIndexResponse>> => {
	const columns: Array<DataGridColumn<TaskNumberIndexResponse>> = [
		TollFreeNumberDataGridColumn({
			accessorKey: 'tfn',
			header: 'Toll-Free Number'
		}),
		FutureCprsDataGridColumn({
			header: 'Future CPRs',
			id: 'futureCPRs',
			accessorKey: 'tfn',
			futureCprs
		}),
		DefaultDataGridColumn({
			accessorKey: 'responseId',
			header: 'Response'
		}),
		DefaultDataGridColumn({
			accessorKey: 'responseLabel',
			header: 'Message'
		}),
		...RespOrgColumnsBuilder<TaskNumberIndexResponse>({
			accessorKey: 'respOrgId',
			header: 'RespOrg'
		}),
		DefaultDataGridColumn({
			accessorKey: 'numberStatusLabel',
			header: 'Status'
		}),
		DefaultDataGridColumn({
			accessorKey: 'templateName',
			header: 'Template'
		}),
		TimeStampDataGridColumn({
			accessorKey: 'effectiveTs',
			header: 'Effective'
		})
	];

	const lastUpdatedColumn = TimeStampDataGridColumn<TaskNumberIndexResponse>({
		accessorKey: 'lastUpdateTs',
		header: 'Last Update'
	});

	if (!hasActivations) {
		return [...columns, lastUpdatedColumn];
	}

	return [
		...columns,
		TimeStampDataGridColumn({
			accessorKey: 'activationTs',
			header: 'Activation'
		}),
		SomosCprStatusDataGridColumn({
			accessorKey: 'cprStatusId',
			header: 'CPR Status'
		}),
		lastUpdatedColumn
	];
};

export const getTaskErrorsColumns = (): Array<
	DataGridColumn<TaskErrorIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'taskErrorId',
		header: 'ID',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'responseId',
		header: 'Response Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'routingCacheKey',
		header: 'Reference'
	}),
	TaskErrorWarningDataGridColumn({
		accessorKey: 'isWarning',
		header: 'Type'
	}),
	DefaultDataGridColumn({
		accessorKey: 'responseValue',
		header: 'Response Value'
	})
];
