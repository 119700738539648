/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TemplateCprIndexResponse = {
    properties: {
        effectiveTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        cprStatusId: {
            type: 'number',
            isRequired: true,
        },
        approvalIndicator: {
            type: 'string',
            isRequired: true,
        },
        scpAuditSuccessQty: {
            type: 'number',
            isRequired: true,
        },
        scpAuditFailureQty: {
            type: 'number',
            isRequired: true,
        },
        scpFailureQty: {
            type: 'number',
            isRequired: true,
        },
        scpOtherQty: {
            type: 'number',
            isRequired: true,
        },
        scpQueuedQty: {
            type: 'number',
            isRequired: true,
        },
        scpSuccessQty: {
            type: 'number',
            isRequired: true,
        },
        scpTotalQty: {
            type: 'number',
            isRequired: true,
        },
        areasOfService: {
            type: 'string',
        },
        interLataCarriers: {
            type: 'string',
            isRequired: true,
        },
        intraLataCarriers: {
            type: 'string',
            isRequired: true,
        },
        templateName: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
        },
    },
};