import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import {
	DirectConnectIndexResponse,
	DirectConnectProfileResponse
} from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IDirectConnectRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const DirectConnectContainerTabs: IDirectConnectRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class DirectConnectRouterClass extends SipApplicationRouter<
	DirectConnectIndexResponse,
	DirectConnectProfileResponse,
	IDirectConnectRouterTabs
> {
	constructor() {
		super(
			'Direct Connect',
			'directConnect',
			'directConnectId',
			DirectConnectContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.DirectConnect);
	}

	public getProfileLabel(profile: DirectConnectProfileResponse): string {
		return profile.callerIdName;
	}

	public getPluralName() {
		return this.getName();
	}
}

export const DirectConnectRouter = new DirectConnectRouterClass();
