/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CdrExportStatusId {
    Creating = 10,
    ReadyToRun = 20,
    Running = 30,
    ReportRequested = 32,
    Completed = 40,
    Deleted = 50,
    Errored = 60,
}