import { UiNotificationsManager } from 'RtUi/notifications/UiNotificationsManager';
import {
	UiNotification,
	UiNotificationCategoryEnum
} from 'RtUi/notifications/lib/UiNotification';
import { DashboardOldCard } from 'RtUi/app/rtCommon/DashboardOld/lib/components/DashboardCard';
import { RtDashboardOldRouter } from 'RtUi/app/rtCommon/DashboardOld/DashboardOld.router';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { useMemo, useState } from 'react';
import { Accordion, Alert, Row } from 'react-bootstrap';
import { useMount } from 'react-use';
import { camelCase } from 'lodash-es';

export const DashboardOldIndexContainer = (): JSX.Element => {
	const [notifications, setNotifications] = useState<UiNotification[]>([]);
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const uiNotificationsManager = useMemo(
		() => UiNotificationsManager.getInstance(),
		[]
	);

	useMount(async () => {
		const newNotifications = await uiNotificationsManager.getNotifications();

		setNotifications(newNotifications);
		setHasLoaded(true);
	});

	const renderCategory = (enumKey: keyof typeof UiNotificationCategoryEnum) => {
		const category = UiNotificationCategoryEnum[enumKey];
		const notificationsByCategory = notifications
			.filter((notification) => notification.getCategory() === category)
			.filter((notification) => notification.userHasAccessToNotification());

		if (notificationsByCategory.length <= 0) {
			return null;
		}

		return (
			<Accordion.Item eventKey={enumKey} key={enumKey}>
				<Accordion.Header>
					<span className="fw-bolder">{category}</span>
				</Accordion.Header>
				<Accordion.Body>
					<Row>
						{notificationsByCategory.map((selectedNotification) => (
							<DashboardOldCard
								key={camelCase(selectedNotification.getTitle())}
								notification={selectedNotification}
							/>
						))}
					</Row>
				</Accordion.Body>
			</Accordion.Item>
		);
	};

	const renderNotificationsByCategory = () => {
		const categories = Object.keys(UiNotificationCategoryEnum);
		return (
			<Accordion defaultActiveKey={categories} alwaysOpen>
				{categories.map((enumKey) =>
					renderCategory(enumKey as keyof typeof UiNotificationCategoryEnum)
				)}
			</Accordion>
		);
	};

	return (
		<StandardLayout router={RtDashboardOldRouter}>
			{renderNotificationsByCategory()}
			{hasLoaded && notifications.length <= 0 && (
				<Alert variant="info" className="d-flex justify-content-start">
					<i className="fas fa-fw fa-info-circle me-2" />
					<span>You have nothing to review!</span>
				</Alert>
			)}
		</StandardLayout>
	);
};

RtDashboardOldRouter.setIndexRtUiFunctionalComponent(
	DashboardOldIndexContainer
);
