import { SwitchTypeIndexResponse } from 'RtModels';
import { SwitchTypesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SwitchTypesResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface SwitchTypeDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		SwitchTypeIndexResponse,
		T
	> {}

export const SwitchTypeDataGridCache =
	new ResourceCacheMap<SwitchTypeIndexResponse>(
		new SwitchTypesResource(),
		'switchTypeId',
		'label'
	);

export const SwitchTypeDataGridColumn = <T = any,>(
	config: SwitchTypeDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: SwitchTypeDataGridCache,
		...config
	});
};
