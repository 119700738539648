import { useQuery } from 'react-query';
import { LrnResource } from 'RtUi/app/rtVue/DialCode/lib/resources/LrnResource';
import {
	DialCodeIndexRequest,
	DialCodeIndexResponse,
	Lerg6IndexRequest,
	Lerg6IndexResponse,
	LergIndexRequest,
	LergIndexResponse,
	LrnProfileResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { RtCommonApiRoutes } from 'RtExports/routes';

const fetchLrnData = async (nanpNumber: string) => {
	const data = await new LrnResource().getProfile(nanpNumber);

	return data;
};

export const useGetLrnData = (nanpNumber: string) => {
	return useQuery<LrnProfileResponse, Error>('getLrnData', () =>
		fetchLrnData(nanpNumber)
	);
};

const fetchDialCodeData = async (queryParams?: DialCodeIndexRequest) => {
	return handleGetRequest<DialCodeIndexResponse[], true>(
		RtCommonApiRoutes.DialCodes.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetDialCodeData = (params?: DialCodeIndexRequest) => {
	return useQuery<FullResponse<DialCodeIndexResponse[]>, Error>(
		['getDialCodeData', params],
		() => fetchDialCodeData(params)
	);
};

const getLerg6Data = async (queryParams?: Lerg6IndexRequest) => {
	return handleGetRequest<Lerg6IndexResponse[], true>(
		RtCommonApiRoutes.NanpLerg6.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetLerg6Data = (params?: Lerg6IndexRequest) => {
	return useQuery<FullResponse<Lerg6IndexResponse[]>, Error>(
		['getLerg6Data', params],
		() => getLerg6Data(params)
	);
};

const getLergSomosData = async (queryParams?: LergIndexRequest) => {
	return handleGetRequest<LergIndexResponse[], true>(
		RtCommonApiRoutes.NanpLerg.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetLergSomosData = (params?: LergIndexRequest) => {
	return useQuery<FullResponse<LergIndexResponse[]>, Error>(
		['getLergSomosData', params],
		() => getLergSomosData(params)
	);
};
