import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	TemplateLcrCarrierIndexResponse,
	TemplateLcrCarrierIndexRequest,
	TemplateLcrCarrierCreateRequest,
	TemplateLcrCarrierDeleteResponse
} from 'RtModels';

export class TemplateLcrCarrierResource extends HttpResource<
	TemplateLcrCarrierIndexResponse,
	TemplateLcrCarrierIndexResponse
> {
	constructor(templateName: string) {
		super();

		const urlParams: TemplateLcrCarrierIndexRequest = { templateName };
		this.setApiModule(Rt800ApiRoutes.TemplateLcrCarriers, urlParams);
	}

	public addToTemplate(
		templateName: string,
		lcrCarrierId: number,
		allowAsDefault: number,
		allowAsCanada: number
	) {
		const req: TemplateLcrCarrierCreateRequest = {
			respOrgCarrierId: lcrCarrierId,
			allowAsDefault,
			allowAsCanada
		};
		const urlParams: TemplateLcrCarrierIndexRequest = { templateName };
		const body = JSON.stringify(req);

		return this.fetchWithRoute<TemplateLcrCarrierIndexResponse>(
			Rt800ApiRoutes.TemplateLcrCarriers.Create,
			{
				body,
				urlParams
			}
		);
	}

	public removeFromTemplate(
		templateName: string,
		templateLcrCarrierId: number
	) {
		const urlParams = { templateName, templateLcrCarrierId };

		return this.fetchWithRoute<TemplateLcrCarrierDeleteResponse>(
			Rt800ApiRoutes.TemplateLcrCarriers.Delete,
			{
				urlParams
			}
		);
	}
}
