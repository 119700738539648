import { JobTaskIndexResponse, JobTaskRecord } from 'RtModels';
import { JobTaskResource } from 'RtUi/app/rtCarrierConnect/Jobs/lib/resources/JobTaskResource';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getJobTaskRecordColumns } from 'RtUi/components/data/DataGrid/configurations/rtCarrierConnect/jobs';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

interface IJobTaskRecordGridProps {
	resource: ArrayResource<JobTaskRecord> | null;
	jobTaskResource: JobTaskResource;
	autoFocusFilter?: boolean;
}

export interface IJobTasksHashMap {
	[referenceKey: string]: JobTaskIndexResponse;
}

export const JobTaskRecordGrid = ({
	jobTaskResource,
	resource
}: IJobTaskRecordGridProps) => {
	const { data, isFetching: isLoading } = useQuery(
		['rtCarrierConnect_jobTaskRecord'],
		() => resource?.getAll()
	);
	const [jobTasksMap, setJobTasksMap] = useState<IJobTasksHashMap>({});

	useEffect(() => {
		const setHashMap = async () => {
			const jobTasks = await jobTaskResource.getAll();
			const jobTasksMap: IJobTasksHashMap = {};

			for (const jobTask of jobTasks) {
				jobTasksMap[jobTask.referenceKey] = jobTask;
			}

			setJobTasksMap(jobTasksMap);
		};

		setHashMap();
	}, [jobTaskResource]);

	const columns = useMemo(
		() => getJobTaskRecordColumns(jobTasksMap),
		[jobTasksMap]
	);

	return (
		<DataGrid<JobTaskRecord>
			data={data}
			columns={columns}
			loading={isLoading}
			pageName={'rtCommon_jobTaskRecord'}
		/>
	);
};
