import { CprNodeType, CprSection } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { type CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';
import type { CprRow } from 'Somos/lib/SomosCpr/RtCprV2/CprRow';
import { CprValidator } from 'Somos/lib/SomosCpr/RtCprV2/CprValidator';
import { type CprValue } from 'Somos/lib/SomosCpr/RtCprV2/CprValue';

// exported definitions
// ======================================================================

export abstract class CprCol extends CprValidator {

	public readonly abstract cprNodeTypeId: CprNodeType;
	public readonly cprSectionId = CprSection.CprSectionCpr;
	public readonly hasTimeZone: boolean = false;

	protected readonly cprRow: CprRow;
	protected label: CprLbl | null = null;

	protected timeZone: string | null = null;

	public constructor(cprRow: CprRow, cprIdx: number, uuid: string | undefined = undefined) {
		super(cprRow.cpr, cprIdx, uuid);
		this.cprRow = cprRow;

		this.setParent(this.cprRow);
	}

	public clean() {
		if (this.label) {
			if (this.hasLabelThatNoLongerExistsOnCpr()) {
				this.label = null;
			}
		}

		return super.clean();
	}

	public getCprLabel(): CprLbl | null {
		return this.label;
	}

	public hasCprLabel(): boolean {
		return (this.label) ? true : false;
	}

	public setCprLabel(newLabel: CprLbl, doValidation: boolean = true): boolean {
		this.cprValues = [];
		this.cprValuesByKey = {};
		this.label = newLabel;

		this.cpr.validate();

		return this.hasErrors();
	}

	public reset() {
		super.reset();
		this.label = null;
		this.cprValues = [];
		this.cprValuesByKey = {};
	}

	public setTimeZone(rawTz: string | null) {
		this.timeZone = rawTz;
	}

	public getTimeZone(): string | null {
		return this.timeZone;
	}

	public hasValues(useLabel: boolean = false): boolean {
		if (this.label) {
			return (useLabel) ? this.label.hasValues() : false;
		}
		return this.cprValues.length > 0;
	}

	public matchesValue(value: string): boolean {
		if (this.label) {
			return this.label.matchesValue(value);
		}
		return super.matchesValue(value);
	}

	public setValues(newValues: string[]): boolean {
		if (!this.cpr.hasCprCol(this.cprNodeTypeId)) {
			console.error('Invalid_CprNodeType');
			return false;
		}

		if (this.label) {
			this.label = null;
		}

		// always call super.setValues, do not set values directly.
		return super.setValues(newValues);
	}

	public validate(): boolean {
		this.errors = [];

		if (this.label) {
			if (this.cprValues.length > 0) {
				this.addError(null, `A value that contains a label cannot contain other values.`);
			}

			this.label.validate(); // validNestedValidation

			return this.label.hasErrors();
		}

		const cprValue: CprValue | null = this.getValues()[0];
		const rawVal = cprValue?.getValue() ?? '';
		const isOtherValue = rawVal === 'OTHER';

		// an "OTHER" value shouldn't follow an empty col
		if (isOtherValue) {
			const currentRowIdx = this.cprRow.getCprIdx();
			const previousRow = this.cpr.getCprRowByIndex(currentRowIdx - 1);
			const previousRowSameCol = previousRow?.getCprCol(this.cprNodeTypeId);
			const isPreviousRowSameColEmpty = previousRowSameCol &&
				previousRowSameCol.getRawValues().length <= 0;

			if (isPreviousRowSameColEmpty) {
				this.addError(cprValue.getValue(), `OTHER is not allowed here.`, cprValue, {
					valuesToRemove: [rawVal]
				});

				return this.hasErrors();
			}
		}

		// call super.validate before doing extra validation
		return super.validate(); // validNestedValidation
	}

	/**
	 * Checks to make sure the label attached is still
	 * attached to the Cpr as well. In cases where the label
	 * was removed on the Cpr, it will not be auto-removed.
	 */
	public hasLabelThatNoLongerExistsOnCpr() {
		if (!this.label) {
			return false;
		}

		const labelName = this.label.getName();
		const cprHasLabel = this.cpr.getCprLabel(labelName);

		return cprHasLabel === null;
	}

	public bulkUpdateCarriers(oldCic: string, newCic: string, dryRun: boolean): number {
		if (this.label || this.cprNodeTypeId !== CprNodeType.Carrier) {
			return 0;
		}

		let numberOfNodesAffected = 0;

		for (const cprValue of this.cprValues) {
			const value = cprValue.getValue();

			if (value === oldCic) {
				numberOfNodesAffected++;

				if (!dryRun) {
					cprValue.setValue(newCic);
				}
			}
		}

		return numberOfNodesAffected;
	}

	/**
	 * Updates nodes of a given nodeType with a value.
	 * Fill Mode: does not replace values, just fills empty ones.
	 * Replace Mode: replaces all values (even ones with values).
	 *
	 * Note: fill and replace will not alter values if the previous
	 * nodeType value is empty.
	 */
	public updateColValuesDownward(mode: 'fill' | 'replace') {
		const valuesOfCurrentCol = this.getRawValues() ?? [];
		const isValueOther = (val: string[] | undefined) =>
			Array.isArray(val) && val[0] === 'OTHER';
		const isEmptyOrNil = (val: string[] | undefined) =>
			Array.isArray(val) && val.length <= 0;

		const rowIndex = this.cprRow.getCprIdx();
		const colIndex = this.getCprIdx();
		const previousNodeType = this.cprRow
			.getCprColByIdx(colIndex - 1)?.cprNodeTypeId ?? undefined;

		for (const cprRow of this.cpr.getCprRows()) {
			if (cprRow.getCprIdx() <= rowIndex) {
				continue;
			}

			const cprCol = cprRow.getCprCol(this.cprNodeTypeId);

			if (!cprCol) {
				continue;
			}

			const currentValIsEmptyOrNil = isEmptyOrNil(
				cprCol.getRawValues()
			);

			if (mode === 'fill' && !currentValIsEmptyOrNil) {
				continue;
			}

			if (previousNodeType) {
				const prevCprCol = cprRow.getCprCol(previousNodeType);
				const prevValues = prevCprCol?.getRawValues();

				if (isValueOther(prevValues) || isEmptyOrNil(prevValues)) {
					break;
				}
			}

			cprCol.setValues(valuesOfCurrentCol);
		}
	}

	protected addValue(newValue: string, doValidation: boolean = true): boolean {
		if (!this.cpr.hasCprCol(this.cprNodeTypeId)) {
			throw new Error('Invalid_CprNodeType');
		}

		newValue = newValue.trim().toUpperCase();

		if (newValue.indexOf('*') !== 0) {
			// not a label
			// always call super.setValues, do not set values directly.
			return super.addValue(newValue);
		}

		if (!this.canLabel) {
			return false;
		}

		let label = this.cpr.getCprLabel(newValue);

		if (label) {
			return this.setCprLabel(label, doValidation);
		}

		label = this.cpr.makeCprLabel(this.cprNodeTypeId, newValue);

		if (!label) {
			return false;
		}

		return this.setCprLabel(label, doValidation);
	}
}

