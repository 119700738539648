/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateLoadErrorType {
    MissingRateKey = 1001,
    InvalidRateKey = 1002,
    DuplicateRateKey = 1003,
    InvalidNpa = 1101,
    InvalidNxx = 1102,
    InvalidLata = 1103,
    InvalidOcn = 1104,
    InvalidState = 1105,
    InvalidRate = 1201,
    UnknownDialCode = 2001,
    UnknownCountryCode = 2002,
    UnknownRateCenter = 2003,
    UnknownIso3 = 2004,
    StartTsViolatesNotificationRules = 3001,
    RateBelowMinimum = 3002,
    RateAboveMaximum = 3003,
    RateIsZero = 3004,
    GenericNotice = 3005,
    InvalidRateSheet = 4000,
}