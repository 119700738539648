/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DialCodeIndexRequest = {
    properties: {
        dialCode: {
            type: 'string',
        },
        isActive: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        search: {
            type: 'string',
        },
        iso3166Alpha3s: {
            type: 'Array',
        },
        rateCenterIds: {
            type: 'Array',
        },
        countrySpecific: {
            type: 'string',
        },
    },
};