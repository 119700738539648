import { CloneRoutingRouter } from 'RtUi/app/rt800/Routing/CloneRouting.router';
import { CloneRoutingAction } from 'RtUi/app/rt800/Routing/lib/components/CloneRoutingAction';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { TaskRouter } from 'RtUi/app/rt800/Tasks/Task.router';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';

@CloneRoutingRouter.getIndexRtUiController()
export class CloneRoutingIndexContainer extends ApplicationContainer<{}, {}> {
	public state: {} = {};

	public routeToTaskProfile(taskProfile: ITaskProfile) {
		const taskProfileRoute = TaskRouter.getProfileRoute(taskProfile.taskId);

		this.goToPath(taskProfileRoute);
	}

	public render() {
		return (
			<StandardLayout router={CloneRoutingRouter}>
				<CloneRoutingAction
					onSuccess={(taskProfile) => this.routeToTaskProfile(taskProfile)}
				/>
			</StandardLayout>
		);
	}
}
