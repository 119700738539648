import { NumberGroupNumberIndexResponse } from 'RtModels';
import { NumberGroupResource } from 'RtUi/app/rt800/NumberGroups/lib/resources/NumberGroupResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface INumberGroupNumberSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<NumberGroupNumberIndexResponse, isMulti> {
	numberGroupId: number;
}

export class NumberGroupNumberSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	NumberGroupNumberIndexResponse,
	isMulti,
	INumberGroupNumberSelectProps<isMulti>
> {
	public resourceClass = undefined;
	public state: ISelectFormControlState<NumberGroupNumberIndexResponse> = {
		formLabel: 'Number Group Number',
		valueKey: 'tfn',
		labelKey: 'tfn'
	};

	constructor(props: INumberGroupNumberSelectProps<isMulti>, context: any) {
		super(props, context);

		const numberGroupResource = new NumberGroupResource();
		const numberGroupNumberResource =
			numberGroupResource.getNumberGroupNumbersResource(
				this.props.numberGroupId
			);

		this.resource = numberGroupNumberResource;
	}
}
