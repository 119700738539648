import { DidResourceRouter } from './DidResource.router';

import { ResourceType } from 'RtModels';
import { ResourceProfileContainer } from 'RtUi/app/rtCommon/Resources/lib/components/ResourceProfileContainer';

export const DidResourceProfileContainer = (): JSX.Element => {
	return (
		<ResourceProfileContainer
			excludeResourceTypes={[ResourceType.CarrierConnect]}
			router={DidResourceRouter}
			isDid
		/>
	);
};

DidResourceRouter.setProfileRtUiFunctionalComponent(
	DidResourceProfileContainer
);
