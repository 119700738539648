import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2';
import { useEffect } from 'react';
import { useUpdate } from 'react-use';
import clsx from 'clsx';

interface ICprColDataGridColumnHeaderProps {
	cprCol: CprCol;
	header: string;
}

export const CprColDataGridColumnHeader = ({
	cprCol,
	header
}: ICprColDataGridColumnHeaderProps) => {
	const updateComponent = useUpdate();
	const { cpr } = cprCol;
	useEffect(() => cpr.onValidate(updateComponent));

	return (
		<span
			className={clsx({
				'text-danger': cprCol.hasErrors()
			})}
		>
			{header}
			{cprCol.hasErrors() && (
				<i className="fas fa-fw fa-exclamation-triangle ms-2" />
			)}
		</span>
	);
};
