import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	DownloadIndexRequest,
	DownloadIndexResponse,
	FileShareCreateRequest,
	FileShareFileProfileResponse,
	FileShareIndexRequest,
	FileShareIndexResponse,
	FileShareProfileResponse,
	FileShareProfileUrlRequest
} from 'RtModels';
import {
	FullResponse,
	handleDeleteRequest,
	handleGetRequest,
	handlePostRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchUserDownloads = async (
	queryParams?: DownloadIndexRequest
): Promise<FullResponse<DownloadIndexResponse[]>> => {
	return handleGetRequest<DownloadIndexResponse[], true>(
		RtCommonApiRoutes.Downloads.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetUserDownloads = (queryParams?: DownloadIndexRequest) => {
	return useQuery<FullResponse<DownloadIndexResponse[]>, Error>(
		[`useGetUserDownloads`],
		() => fetchUserDownloads(queryParams)
	);
};

export const fetchUserFileSharing = async (
	queryParams?: FileShareIndexRequest
): Promise<FullResponse<FileShareIndexResponse[]>> => {
	return handleGetRequest<FileShareIndexResponse[], true>(
		RtCommonApiRoutes.FileShare.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetUserFileSharing = (queryParams?: FileShareIndexRequest) => {
	return useQuery<FullResponse<FileShareIndexResponse[]>, Error>(
		[`useGetUserFileSharing`],
		() => fetchUserFileSharing(queryParams)
	);
};

interface ICreateFileShareParams {
	body: FileShareCreateRequest;
	file: File;
}

export const createFileShare = ({ file, body }: ICreateFileShareParams) => {
	const bodyParams = new FormData();
	bodyParams.append('file', file);

	for (const [key, value] of Object.entries(body)) {
		bodyParams.append(key, value);
	}

	return handlePostRequest<FileShareProfileResponse>(
		RtCommonApiRoutes.FileShare.Create,
		bodyParams,
		{
			removeContentType: true
		}
	);
};

export const downloadFileShare = (urlParams: FileShareProfileUrlRequest) => {
	return handleGetRequest<FileShareFileProfileResponse>(
		RtCommonApiRoutes.FileShareFile.Profile,
		{
			urlParams
		}
	);
};

export const deleteFileShare = (urlParams: FileShareProfileUrlRequest) => {
	return handleDeleteRequest<FileShareFileProfileResponse>(
		RtCommonApiRoutes.FileShare.Delete,
		{
			urlParams
		}
	);
};
