import { CprValueHighlightType } from 'Somos/lib/SomosCpr/RtCprV2';
import type { Cpr } from 'Somos/lib/SomosCpr/RtCprV2/Cpr';
import { CprNodeType, CprSection } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { type ICprErrorCleanData } from 'Somos/lib/SomosCpr/RtCprV2/CprError';
import { CprValidator } from 'Somos/lib/SomosCpr/RtCprV2/CprValidator';

// exported definitions
// ======================================================================

export abstract class CprLbl extends CprValidator {

	public readonly abstract cprNodeTypeId: CprNodeType;

	public readonly cprSectionId = CprSection.CprSectionCprLabel;

	protected name: string;

	protected isLabelNew = false;

	public constructor(cpr: Cpr, name: string, uuid: string | undefined = undefined) {
		super(cpr, 0, uuid);
		this.name = name;
	}

	public setIsLabelNew(isLabelNew: boolean) {
		this.isLabelNew = isLabelNew;
	}

	public isNew() {
		return this.isLabelNew;
	}

	public updateNodeTypeId(nodeType: CprNodeType) {
		const labelName = this.getName();
		const values = this.getRawValues();

		this.cpr.deleteCprLabel(this.name);

		const newLbl = this.cpr.makeCprLabel(nodeType, labelName, this.cprIdx);

		if (!newLbl) {
			return false;
		}

		for (const value of values) {
			newLbl.addValue(value);
		}

		this.cpr.validate();

		return true;
	}

	public getName(): string {
		return this.name;
	}

	public setName(newName: string): boolean {
		if (this.cpr.hasCprLabel(newName)) {
			return false;
		}

		this.cpr.updateCprLabelByKey(this.getName(), newName);

		this.name = newName;

		this.cpr.validate();

		return true;
	}

	public bulkUpdateCarriers(oldCic: string, newCic: string, dryRun: boolean): number {
		if (this.cprNodeTypeId !== CprNodeType.Carrier) {
			return 0;
		}

		let numberOfNodesAffected = 0;

		for (const cprValue of this.cprValues) {
			const value = cprValue.getValue();

			if (value === oldCic) {
				numberOfNodesAffected++;

				if (!dryRun) {
					cprValue.setValue(newCic);
				}
			}
		}

		return numberOfNodesAffected;
	}

	public existsOnCpr() {
		return this.cpr.hasCprLabel(
			this.getName()
		);
	}

	public clean(deleteIfEmpty = false) {
		const response = super.clean();

		if (deleteIfEmpty && this.getRawValues().length <= 0) {
			this.cpr.deleteCprLabel(this.getName());
			response.wasRemoved = true;
		}

		return response;
	}

	public validate() {
		// call super.validate before doing extra validation
		super.validate(); // validNestedValidation

		this.setHighlightTypeId(undefined);

		if (!this.existsOnCpr()) {
			const labelName = this.getName();
			const errorMessage = `Label ${labelName} no longer exists`;
			const cleanData: ICprErrorCleanData = { valuesToRemove: [labelName] };

			this.addError(null, errorMessage, undefined, cleanData);
		}

		if (this.cpr.isHighlighting()) {
			const highlightValue = this.cpr.getHighlightValue();

			if (highlightValue?.startsWith('*')) {
				if (highlightValue === this.name) {
					this.setHighlightTypeId(CprValueHighlightType.Exact);
					return this.hasErrors();
				}
			}

			for (const cprValue of this.cprValues) {
				if (cprValue.isHighlighted()) {
					this.setHighlightTypeId(cprValue.getHighlightedTypeId());
					break;
				}
			}
		}

		return this.hasErrors();
	}

}
