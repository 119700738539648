/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ReconciliationDetailsIndexResponse = {
    properties: {
        vuidSource: {
            type: 'string',
            isRequired: true,
        },
        fileStreamIdSource: {
            type: 'number',
            isRequired: true,
        },
        vuidTarget: {
            type: 'string',
            isRequired: true,
        },
        fileStreamIdTarget: {
            type: 'number',
            isRequired: true,
        },
        ingressInviteTsSource: {
            type: 'string',
            isRequired: true,
        },
        ingressInviteTsTarget: {
            type: 'string',
            isRequired: true,
        },
        ingressConnectionIdSource: {
            type: 'number',
            isRequired: true,
        },
        ingressTrunkGroupSource: {
            type: 'string',
            isRequired: true,
        },
        egressConnectionIdSource: {
            type: 'number',
            isRequired: true,
        },
        egressTrunkGroupSource: {
            type: 'string',
            isRequired: true,
        },
        connectSource: {
            type: 'number',
            isRequired: true,
        },
        jurisdictionSource: {
            type: 'VueJurisdictions',
            isRequired: true,
        },
        jurisdictionTarget: {
            type: 'VueJurisdictions',
            isRequired: true,
        },
        ingressFromStdSource: {
            type: 'string',
            isRequired: true,
        },
        ingressFromLrnSource: {
            type: 'string',
            isRequired: true,
        },
        ingressToStdSource: {
            type: 'string',
            isRequired: true,
        },
        ingressToLrnSource: {
            type: 'string',
            isRequired: true,
        },
        rawDurationMsSource: {
            type: 'number',
            isRequired: true,
        },
        rawDurationMsTarget: {
            type: 'number',
            isRequired: true,
        },
        costedDurationMsSource: {
            type: 'number',
            isRequired: true,
        },
        costedDurationMsTarget: {
            type: 'number',
            isRequired: true,
        },
        totalCostSource: {
            type: 'string',
            isRequired: true,
        },
        totalCostTarget: {
            type: 'string',
            isRequired: true,
        },
        totalRevenueSource: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'CallTypes',
            isRequired: true,
        },
        customAuditField1: {
            type: 'string',
            isRequired: true,
        },
        customAuditField2: {
            type: 'string',
            isRequired: true,
        },
        costMatchVariance: {
            type: 'number',
            isRequired: true,
        },
        matchCondition: {
            type: 'string',
            isRequired: true,
        },
        matchVariation: {
            type: 'string',
            isRequired: true,
        },
        mappingId: {
            type: 'number',
            isRequired: true,
        },
        reconciliationId: {
            type: 'number',
            isRequired: true,
        },
        timeslot: {
            type: 'string',
            isRequired: true,
        },
        loadDateTs: {
            type: 'number',
            isRequired: true,
        },
    },
};