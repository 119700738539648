import { useBrowserEffect } from 'RtUi/app/@RtUi/lib/hooks';
import { IRouteMenu } from 'RtUi/app/ApplicationShell/lib/interfaces';
import { Action, Location } from 'history';
import { debounce, last } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

interface IHistoryItem {
	route: string;
	label: string;
}

interface INavigationHistoryProps {
	routesConfig: IRouteMenu[];
}

const buildRoute = (location: Location): string => {
	const route = new URL(window.location.origin);
	route.pathname = location.pathname;
	route.hash = location.hash;
	route.search = location.search;

	return route.href.replace(window.location.origin, '');
};

const getRouteName = (routes: IRouteMenu[], path: string) => {
	return routes.reduce((result, config) => {
		const route = config.routes.find((route) => route.path === path);
		if (route) {
			return `${config.name} - ${route.name}`;
		}

		return result;
	}, '');
};

export const NavigationHistory = ({
	routesConfig
}: INavigationHistoryProps): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();

	const currentRoute = useMemo(() => buildRoute(location), [location]);
	const [history, setHistory] = useState<IHistoryItem[]>([
		{
			label: getRouteName(routesConfig, location.pathname),
			route: currentRoute
		}
	]);

	useBrowserEffect(
		debounce(({ action, location }) => {
			if (action !== Action.Push) {
				return;
			}

			setHistory((currentState) => {
				const newState = [...currentState];
				const route = buildRoute(location);

				if (last(newState)?.route !== route) {
					newState.unshift({
						label: getRouteName(routesConfig, location.pathname),
						route
					});
				}

				return newState.slice(0, 6);
			});
		}, 50)
	);

	const items = useMemo(() => history.slice(1), [history]);

	return (
		<Dropdown align="end">
			<Dropdown.Toggle bsPrefix="p-0" disabled={!items.length}>
				<i className="fas fa-fw fa-clock" />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Header>Navigation History</Dropdown.Header>
				{items.map((item, index) => (
					<Dropdown.Item
						key={`${index}-${item.label}`}
						onClick={() => navigate(item.route)}
					>
						{item.label}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};
