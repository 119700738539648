import { JobStatusId, JobTaskIndexResponse } from 'RtModels';
import { Alert } from 'react-bootstrap';

type TJobStatusToReadableMap = {
	[key in JobStatusId]: {
		readable: string;
		color: 'success' | 'warning' | 'danger' | 'light' | 'primary';
		//extendedReadable?: (index: JobTaskIndexResponse) => string;
	};
};

const JobStatusToReadableMap: TJobStatusToReadableMap = {
	[JobStatusId.CREATING]: {
		readable: 'Creating',
		color: 'light'
	},
	[JobStatusId.WAITING_TO_RUN]: {
		readable: 'Waiting to Run',
		color: 'light'
	},
	[JobStatusId.RUN_REQUESTED]: {
		readable: 'Requested',
		color: 'light'
	},
	[JobStatusId.RUNNING]: {
		readable: 'Running...',
		color: 'light'
	},
	[JobStatusId.WAITING_FOR_TASK_MANUAL_COMPLETION]: {
		readable: 'Required Manual Completion',
		color: 'primary'
	},
	[JobStatusId.WAITING_FOR_RESPONSE]: {
		readable: 'Waiting for Response...',
		color: 'light'
	},
	[JobStatusId.COMPLETED_NO_ERROR]: {
		readable: 'Completed',
		color: 'success'
	},
	[JobStatusId.COMPLETED_WITH_WARNING]: {
		readable: 'Completed with Warning',
		color: 'warning'
	},
	[JobStatusId.COMPLETED_WITH_ERROR]: {
		readable: 'Completed with Error',
		color: 'danger'
	}
};

export class JobStatusHelper {
	/**
	 * Type-Guard for JobStatusId enum
	 * @param value
	 */
	public isJobStatusEnum(value: any): value is JobStatusId {
		return typeof value === 'number' && value in JobStatusId;
	}

	/**
	 * Get readable string from JobStatusId
	 * @param jobStatus
	 */
	public getReadableJobStatus(jobStatus: JobStatusId) {
		const readableJobTask = JobStatusToReadableMap[jobStatus];

		return readableJobTask.readable;
	}

	/**
	 * Get rendered status of a JobStatusId
	 * @param jobStatus
	 */
	public renderJobStatus(jobStatus: JobStatusId) {
		const readableJobTask = JobStatusToReadableMap[jobStatus];

		return (
			<section className="d-flex justify-content-center">
				<Alert
					transition={false}
					style={{ textAlign: 'center' }}
					variant={readableJobTask.color}
					className="alert-sm mb-0 py-0"
				>
					<span>{readableJobTask.readable}</span>
				</Alert>
			</section>
		);
	}

	/**
	 * Returns warningId or errorId if jobTask.taskStatusId indicates to
	 * @param jobTask
	 */
	public getWarningOrErrorResponse(jobTask: JobTaskIndexResponse) {
		if (JobStatusId.COMPLETED_WITH_ERROR === jobTask.taskStatusId) {
			return jobTask.errorId;
		} else if (JobStatusId.COMPLETED_WITH_WARNING === jobTask.taskStatusId) {
			return jobTask.warningId;
		}

		return '';
	}
}
