import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

interface IPasswordFormControl {
	allowViewPassword?: boolean;
	required?: boolean;
	label?: string;
	onChange: (val: string) => void;
	value?: string;
	displayMode?: boolean;
}

export const PasswordFormControl = (
	props: IPasswordFormControl
): JSX.Element => {
	const typeHashMap = new Map<boolean, string>([
		[true, 'password'],
		[false, 'text']
	]);
	const {
		allowViewPassword = true,
		required,
		label,
		onChange,
		value,
		displayMode
	} = props;
	const [booleanType, setType] = useState(true);

	const className = `fa-solid ${!booleanType ? 'fa-eye-slash' : 'fa-eye'}`;

	return (
		<>
			<Form.Label htmlFor="password-input">{label}</Form.Label>
			<InputGroup className="mb-3">
				<Form.Control
					onChange={(e) => onChange(e.target.value)}
					value={value || ''}
					required={required || false}
					type={typeHashMap.get(booleanType)}
					id="password-input"
					disabled={displayMode}
				/>
				{allowViewPassword && (
					<InputGroup.Text
						style={{ cursor: 'pointer' }}
						onClick={() => setType(!booleanType)}
					>
						<i className={className}></i>
					</InputGroup.Text>
				)}
			</InputGroup>
		</>
	);
};

PasswordFormControl.displayName = 'PasswordFormControl';
