import { ResourceProfileResponse, ResourceType } from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { JobGrid } from 'RtUi/app/rtCarrierConnect/Jobs/lib/grids/JobGrid';
import { ResourceUsersList } from 'RtUi/app/rtCommon/Resources/lib/components/ResourceUsersList';
import { MetricTotalsByDateDashboardTabs } from 'RtUi/app/rtDid/Dashboard/lib/components/MetricTotalsByDateDashboardTabs';
import { DidNumbersGrid } from 'RtUi/app/rtDid/Numbers/lib/grids/DidNumbersGrid';
import { CreateConnectionForm } from 'RtUi/app/rtDid/Resources/forms/CreateConnectionForm';
import { useGetResource } from 'RtUi/app/rtDid/Resources/service';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export interface IResourceProfileContainerTabs
	extends IProfileApplicationContainerTabs {}

export const ResourceProfileContainerTabs = (
	isDid: boolean
): IResourceProfileContainerTabs => ({
	...(isDid && {
		Dashboard: {
			header: 'Dashboard'
		}
	}),
	Profile: { header: 'Profile', isDefault: true },
	...(isDid && {
		Numbers: {
			header: 'Numbers'
		}
	}),
	...(!isDid && {
		Jobs: { header: 'Jobs' }
	}),
	...(isDid && {
		Users: {
			header: 'Users'
		}
	})
});

interface IResourceProfileContainerProps {
	isDid?: boolean;
	includeResourceTypes?: ResourceType[];
	excludeResourceTypes?: ResourceType[];
	router: RtUiRouter;
}

export const ResourceProfileContainer = ({
	isDid = false,
	excludeResourceTypes,
	router
}: IResourceProfileContainerProps) => {
	const entityId = useIdParam();
	const [tabs, activeTab, setActiveTab] = useTabs(
		ResourceProfileContainerTabs(isDid)
	);

	const { data, isLoading } = useGetResource({
		resourceId: Number(entityId)
	});

	if (isLoading) {
		return <Loading />;
	}

	if (!data) {
		return <p>Not found!</p>;
	}

	const returnJobTabResourceParams = () => {
		return { resourceId: [Number(entityId)] };
	};

	return (
		<TabbedLayout<ResourceProfileResponse>
			profile={data}
			router={router}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			{isDid && (
				<TabbedLayoutTab {...tabs.Dashboard}>
					<MetricTotalsByDateDashboardTabs
						className="mb-4"
						resourceId={data.resourceId}
					/>
				</TabbedLayoutTab>
			)}
			<TabbedLayoutTab {...tabs.Profile}>
				<CreateConnectionForm
					editMode={data}
					onCopy={(resource) => {
						BrowserHistory.push(router.getProfileRoute(resource.resourceId));
					}}
					excludeResourceTypes={excludeResourceTypes}
				/>
			</TabbedLayoutTab>
			{isDid && (
				<TabbedLayoutTab {...tabs.Numbers}>
					<DidNumbersGrid
						key={entityId}
						resourceParams={{ resourceId: Number(entityId) }}
					/>
				</TabbedLayoutTab>
			)}
			{!isDid && (
				<TabbedLayoutTab {...tabs.Jobs}>
					<JobGrid resourceParams={returnJobTabResourceParams()} />
				</TabbedLayoutTab>
			)}
			{isDid && (
				<TabbedLayoutTab {...tabs.Users}>
					<ResourceUsersList resourceId={Number(entityId)} />
				</TabbedLayoutTab>
			)}
		</TabbedLayout>
	);
};
