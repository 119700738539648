import { AsrIndexResponseData } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getAsrColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/asr';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface IAsrDataGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<AsrIndexResponseData> | null;
	callDateFormat?: string;
	isLoading?: boolean;
	onDrilldown: (
		row: AsrIndexResponseData,
		key: keyof AsrIndexResponseData
	) => void;
}

export const AsrDataGrid = ({
	resource,
	onSortingChange: onSortedChange,
	defaultSorting: defaultSorted,
	onDrilldown,
	isLoading,
	callDateFormat
}: IAsrDataGridProps) => {
	const { data } = useQuery<AsrIndexResponseData[] | undefined, Error>(
		[`useAsrReport`, resource],
		() => resource?.getAll()
	);
	const columns = useMemo(
		() => getAsrColumns(onDrilldown, callDateFormat),
		[onDrilldown, callDateFormat]
	);

	return (
		<DataGrid<AsrIndexResponseData>
			pageName="rtVue_asr"
			data={data}
			columns={columns}
			loading={isLoading}
			onSortingChange={onSortedChange}
			defaultSorting={defaultSorted}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
