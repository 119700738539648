/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcoSummaryIndexResponse = {
    properties: {
        aninpanxx: {
            type: 'string',
            isRequired: true,
        },
        lrnnpanxx: {
            type: 'string',
            isRequired: true,
        },
        interconnects: {
            type: 'number',
            isRequired: true,
        },
        interminutes: {
            type: 'number',
            isRequired: true,
        },
        intercharges: {
            type: 'number',
            isRequired: true,
        },
        intraconnects: {
            type: 'number',
            isRequired: true,
        },
        intraminutes: {
            type: 'number',
            isRequired: true,
        },
        intracharges: {
            type: 'number',
            isRequired: true,
        },
        indetconnects: {
            type: 'number',
            isRequired: true,
        },
        indetminutes: {
            type: 'number',
            isRequired: true,
        },
        indetcharges: {
            type: 'number',
            isRequired: true,
        },
        totalconnects: {
            type: 'number',
            isRequired: true,
        },
        totalminutes: {
            type: 'number',
            isRequired: true,
        },
        totalcharges: {
            type: 'number',
            isRequired: true,
        },
    },
};