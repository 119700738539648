/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DirectConnectIndexResponse = {
    properties: {
        directConnectId: {
            type: 'number',
            isRequired: true,
        },
        name: {
            type: 'string',
            isRequired: true,
        },
        callerId: {
            type: 'string',
            isRequired: true,
        },
        callerIdName: {
            type: 'string',
            isRequired: true,
        },
        callerIdNumber: {
            type: 'string',
            isRequired: true,
        },
        inviteExpiration: {
            type: 'number',
            isRequired: true,
        },
        connectionId: {
            type: 'number',
            isRequired: true,
        },
        egressRoute: {
            type: 'string',
            isRequired: true,
        },
        updatedBy: {
            type: 'string',
            isRequired: true,
        },
        ipPort: {
            type: 'string',
            isRequired: true,
        },
        userAgent: {
            type: 'string',
            isRequired: true,
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        userEmail: {
            type: 'string',
            isRequired: true,
        },
    },
};