import { useMemo } from 'react';
import {
	LcrCarrierRatesLookupIndexRequest,
	LcrCarrierRatesLookupIndexResponse
} from 'RtModels';
import { LcrCarrierRouter } from 'RtUi/app/rtLco/Carriers/LcrCarrier.router';
import { useGetCarrierRates } from 'RtUi/app/rtLco/CarriersRates/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCarrierRatesColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/carriersRates';

interface ICarriersRatesGridProps {
	resourceParams?: LcrCarrierRatesLookupIndexRequest;
}

export const CarriersRatesGrid = ({
	resourceParams
}: ICarriersRatesGridProps) => {
	const { data, isFetching: isLoading } = useGetCarrierRates(resourceParams);
	const columns = useMemo(() => getCarrierRatesColumns(), []);

	return (
		<DataGrid<LcrCarrierRatesLookupIndexResponse>
			pageName="rtLco-carrierRates"
			loading={isLoading}
			data={data?.data}
			router={LcrCarrierRouter}
			totalRows={data?.totalCount}
			columns={columns}
		/>
	);
};

CarriersRatesGrid.displayName = 'CarriersRatesGrid';
