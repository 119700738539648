import { TrackingGroupIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getTrackingGroupColumns = (): Array<
	DataGridColumn<TrackingGroupIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'trackingGroupId',
		header: 'Tracking Group Id'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'playLimit',
		header: 'Play Limit'
	}),
	DefaultDataGridColumn({
		accessorKey: 'dialLimit',
		header: 'Dial Limit'
	}),
	IntegerDataGridColumn({
		accessorKey: 'expirationSeconds',
		header: 'Expiration',
		humanize: 'seconds',
		titleCase: true
	})
];
