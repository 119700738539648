import * as React from 'react';
import { Card, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
	IUiNotificationSettings,
	IUiNotificationState,
	UiNotification
} from '../../../../../notifications/lib/UiNotification';

interface IDashboardOldCardProps {
	notification: UiNotification;
}

interface IDashboardOldCardState
	extends IUiNotificationState,
		IUiNotificationSettings {}

export class DashboardOldCard extends React.Component<
	IDashboardOldCardProps,
	IDashboardOldCardState
> {
	public listenerRemoveFn: () => void = () => ({});

	public componentDidMount() {
		const { notification } = this.props;

		this.listenerRemoveFn = notification.onUpdate((settings, state) => {
			this.setState({
				...state,
				...settings
			});
		});
	}

	public componentWillUnmount() {
		this.listenerRemoveFn();
	}

	public render() {
		if (!this.state) {
			return null;
		}

		const { notification } = this.props;

		if (!notification.userHasAccessToNotification()) {
			return null;
		}

		const header = notification.getTitle();
		const {
			isFetching,
			issuesCount,
			isWarning,
			isPinned,
			issuesWord,
			didFetchFail
		} = this.state;
		const isOkay = !isFetching && issuesCount === 0;
		const headerClassNames = [
			'py-2',
			'px-3',
			'd-flex',
			'justify-content-start'
		];
		const iconClassNames = ['fas', 'fa-fw', 'align-self-center', 'me-2'];
		let content: JSX.Element = <></>;
		const pinBtnId = 'dash-card-pin-' + header.replace(/\W/g, '');
		const expandBtnId = 'dash-card-expand-' + header.replace(/\W/g, '');

		if (isFetching) {
			headerClassNames.push('bg-light', 'text-muted');
			iconClassNames.push('fa-cog', 'fa-spin');
			content = <span>&nbsp;Refreshing</span>;
		} else if (didFetchFail) {
			headerClassNames.push('bg-warning', 'text-white');
			iconClassNames.push('fa-exclamation-circle');
			content = <span>&nbsp;Failed to Retrieve</span>;
		} else if (
			isWarning &&
			typeof issuesCount === 'number' &&
			issuesCount > 0
		) {
			headerClassNames.push('bg-warning', 'text-white');
			iconClassNames.push('fa-exclamation-circle');
			content = (
				<span>
					{issuesCount.toLocaleString()} {issuesWord}
				</span>
			);
		} else if (isOkay) {
			headerClassNames.push('bg-success', 'text-white');
			iconClassNames.push('fa-check-circle');
			content = <span>No {issuesWord}</span>;
		} else if (typeof issuesCount === 'number' && issuesCount > 0) {
			headerClassNames.push('bg-danger', 'text-white');
			iconClassNames.push('fa-exclamation-triangle');
			content = (
				<span>
					{issuesCount.toLocaleString()} {issuesWord}
				</span>
			);
		}

		return (
			<Col lg={4} className="mb-4">
				<Card>
					<Card.Header
						className={headerClassNames.join(' ')}
						onClick={() => notification.openDetailedView()}
						style={{ cursor: 'pointer' }}
					>
						<i className={iconClassNames.join(' ')} />
						<span>{header}</span>
					</Card.Header>
					<Card.Body>{content}</Card.Body>
					<Card.Footer className="d-flex justify-content-between px-1 py-1">
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id="pin-unpin-tooltip" {...props}>
									{isPinned && 'Unpin from Header'}
									{!isPinned && 'Pin to Header'}
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									variant="white"
									className={isPinned ? 'text-primary' : 'text-muted'}
									id={pinBtnId}
									onClick={() =>
										notification.updateSetting('isPinned', !isPinned)
									}
								>
									<i className="fas fa-fw fa-thumbtack" />
								</Button>
							)}
						</OverlayTrigger>
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id="expand-view-tooltip" {...props}>
									Expand View
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									variant="white"
									id={expandBtnId}
									onClick={() => notification.openDetailedView()}
								>
									<i className="fas fa-fw fa-expand fa-lg" />
								</Button>
							)}
						</OverlayTrigger>
					</Card.Footer>
				</Card>
			</Col>
		);
	}
}
