export class SpreadsheetParserColumn {
	private columnIndex: number = -1;
	private required = false;
	private minLength: number | undefined;
	private defaultValue: number | string | undefined;
	private isInteger: boolean = false;
	private isDecimal: boolean = false;
	private isCurrency: boolean = false;
	private isDate: boolean = false;
	private isPhoneNumber: boolean = false;
	private examples: string[] = [];

	constructor(
		private columnName: string,
		private columnSearchKeywords: string[] = []
	) {
		if (!columnSearchKeywords.includes(columnName)) {
			this.columnSearchKeywords.push(columnName);
		}
	}

	public setExamples(...examples: string[]) {
		this.examples = examples;
		return this;
	}

	public getExamples() {
		return [...this.examples];
	}

	public clone(columnIndex: number = -1) {
		const newColumn = new SpreadsheetParserColumn(this.getColumnName(), [
			...this.columnSearchKeywords
		]);

		newColumn.setIndex(columnIndex);
		newColumn.setIsRequired(this.isRequired());

		return newColumn;
	}

	public getColumnName() {
		return this.columnName;
	}

	public getSearchKeywords() {
		return [...this.columnSearchKeywords];
	}

	public getIndex() {
		return this.columnIndex;
	}

	public isRequired() {
		return this.required;
	}

	public setIndex(index: number): this {
		this.columnIndex = index;

		return this;
	}

	public setIsRequired(required: boolean): this {
		this.required = required;

		return this;
	}

	public setMinLength(minLength: number): this {
		this.minLength = minLength;

		return this;
	}

	public getMinLength(): number | undefined {
		return this.minLength;
	}

	public setDefaultValue(defaultValue: number | string): this {
		this.defaultValue = defaultValue;

		return this;
	}

	public getDefaultValue() {
		return this.defaultValue;
	}

	public getIsInteger() {
		return this.isInteger;
	}

	public setIsInteger() {
		this.isInteger = true;

		return this;
	}

	public getIsCurrency() {
		return this.isCurrency;
	}

	public setIsCurrency() {
		this.isCurrency = true;

		return this;
	}

	public getIsDecimal() {
		return this.isDecimal;
	}

	public setIsDecimal() {
		this.isDecimal = true;

		return this;
	}

	public getIsDate() {
		return this.isDate;
	}

	public setIsDate() {
		this.isDate = true;

		return this;
	}

	public getIsPhoneNumber() {
		return this.isPhoneNumber;
	}

	public setIsPhoneNumber() {
		this.isPhoneNumber = true;

		return this;
	}
}
