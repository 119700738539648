import * as React from 'react';
import { RtVueFilterSearch } from 'RtUi/app/rtVue/common/lib/http/RtVueFilterSearch';
import { DataFilterOperand } from 'RtModels';

interface IDataFilterOperandLabelProps {
	operand: DataFilterOperand;
}

interface IDataFilterOperandLabelState {
	label: string;
}

export class DataFilterOperandLabel extends React.Component<
	IDataFilterOperandLabelProps,
	IDataFilterOperandLabelState
> {
	private static LabelCache = new Map<string, string>();

	constructor(props: IDataFilterOperandLabelProps) {
		super(props);

		this.state = {
			label: props.operand.value
		};
	}

	public async componentDidMount() {
		const { value, dataSource } = this.props.operand;

		const cacheKey = `${dataSource}-${value}`;

		if (DataFilterOperandLabel.LabelCache.has(cacheKey)) {
			const label = DataFilterOperandLabel.LabelCache.get(cacheKey);

			if (label) {
				this.setState({ label });
				return;
			} else {
				//Should not be empty
				DataFilterOperandLabel.LabelCache.delete(cacheKey);
			}
		}
		const filterSearchInstance = RtVueFilterSearch.getInstance();
		const label = await filterSearchInstance.getLabelFromValue(
			dataSource,
			value
		);

		DataFilterOperandLabel.LabelCache.set(cacheKey, label);

		this.setState({ label });
	}

	public render() {
		return this.state.label;
	}
}
