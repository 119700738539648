import { Rt800ApiRoutes } from 'RtExports/routes';
import { LcoApplicationRouter } from 'RtUi/app/rtLco/rtLco.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { LcrCarrierIndexResponse, LcrCarrierProfileResponse } from 'RtModels';

interface ILcrCarrierContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	RatingSetup: TabbedLayoutTabProps;
	Rates: TabbedLayoutTabProps;
	UploadRates: TabbedLayoutTabProps;
}

export const LcrCarrierContainerTabs: ILcrCarrierContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	RatingSetup: {
		header: 'Rating Setup'
	},
	Rates: {
		header: 'Rates'
	},
	UploadRates: {
		header: 'Upload Rates',
		isAction: true
	}
};

export class LcrCarrierRouterClass extends LcoApplicationRouter<
	LcrCarrierIndexResponse,
	LcrCarrierProfileResponse,
	ILcrCarrierContainerTabs
> {
	constructor() {
		super('Carrier', 'lcrCarriers', 'lcrCarrierId', LcrCarrierContainerTabs);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.LcrCarriers);
	}

	public getProfileLabel(profile: LcrCarrierProfileResponse) {
		return profile.label;
	}
}

export const LcrCarrierRouter = new LcrCarrierRouterClass();
