/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DecisionMatrix = {
    properties: {
        function: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        outcome: {
            type: 'string',
            isRequired: true,
        },
    },
};