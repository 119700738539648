import clsx from 'clsx';
import moment from 'moment';
import { FC, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	GuardianServiceNumberSubscriptionProfileResponse,
	PortingStatusIndexResponse,
	ScenarioIndexResponse,
	ServiceNumberCreateRequest,
	ServiceNumberProfileResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { PortingStatusSelect } from 'RtUi/app/rtCommon/ServiceNumbers/lib/controls/PortingStatusSelect';
import { ServiceNumberPropertyToNameMap } from 'RtUi/app/rtCommon/ServiceNumbers/lib/grids/Constants';
import { ServiceNumberResource } from 'RtUi/app/rtCommon/ServiceNumbers/lib/resources/ServiceNumberResource';
import { ScenarioSelect } from 'RtUi/app/rtSip/Scenarios/lib/controls/ScenarioSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { AddressFields } from 'RtUi/components/form/AddressFields';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Controller, useForm } from 'react-hook-form';
import { RtError } from 'RtUi/utils/errors/RtError';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';

type ServiceNumberEditorProps = {
	value?:
		| ServiceNumberProfileResponse
		| GuardianServiceNumberSubscriptionProfileResponse;
	createMode?: boolean;
	narrow?: boolean;
	wide?: boolean;
	onSuccess?: (updatedMetadata: ServiceNumberProfileResponse) => void;
	onCancel?: () => void;
};

export const ServiceNumberEditor: FC<
	React.PropsWithChildren<ServiceNumberEditorProps>
> = ({
	value,
	createMode = false,
	onSuccess = () => {},
	onCancel = () => {},
	wide = false,
	narrow = false
}) => {
	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { isSubmitting }
	} = useForm<ServiceNumberProfileResponse>({
		defaultValues: value
	});
	const [isEditing, setIsEditing] = useState<boolean>(createMode);
	const [error, setError] = useState<RtError>();
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [scenario, setScenario] = useState<ScenarioIndexResponse>();
	const [portingStatus, setPortingStatus] =
		useState<PortingStatusIndexResponse>();

	const [
		isDirAsst,
		dirAsstAddress1,
		dirAsstAddress2,
		dirAsstCity,
		dirAsstState,
		dirAsstZip,
		isE911,
		e911Address1,
		e911Address2,
		e911City,
		e911State,
		e911Zip
	] = watch([
		'isDirAsst',
		'dirAsstAddress1',
		'dirAsstAddress2',
		'dirAsstCity',
		'dirAsstState',
		'dirAsstZip',
		'isE911',
		'e911Address1',
		'e911Address2',
		'e911City',
		'e911State',
		'e911Zip'
	]);

	const onSubmit = async (
		data: ServiceNumberCreateRequest | ServiceNumberProfileResponse
	) => {
		if (value && value.subscriptionId !== data.subscriptionId) {
			const confirmation = await Confirmation.createConfirm(
				`Updating a service number can have billing and rating side effects.`
			);

			if (!confirmation) {
				return;
			}
		}

		setError(undefined);

		try {
			let updatedMetadata: ServiceNumberProfileResponse;
			const serviceNumberResource = new ServiceNumberResource();

			if (!createMode) {
				updatedMetadata = await serviceNumberResource.update(
					data.serviceNumber,
					data
				);
			} else {
				updatedMetadata = await serviceNumberResource.create(data);
			}

			setIsEditing(false);
			onSuccess(updatedMetadata);
		} catch (err: any) {
			setError(new RtError(err));
		}
	};

	return (
		<RtForm
			narrow={narrow}
			wide={wide}
			noValidate
			error={error}
			isSubmitting={isSubmitting}
			header={value?.serviceNumber}
			displayMode={!isEditing}
			onChange={(val) => setIsEditing(!val)}
			onCancel={onCancel}
			onSubmit={handleSubmit(onSubmit)}
		>
			<header>
				<legend>Profile</legend>
			</header>
			<Row>
				<Col lg={6}>
					<Controller
						control={control}
						name="label"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Label"
								displayMode={!isEditing}
								value={value ?? ''}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="isActive"
						render={({ field: { onChange, value } }) => (
							<IsActiveRadioFormControl
								displayMode={!isEditing}
								hideBothOption
								value={value}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<Controller
						control={control}
						name="subscriptionId"
						render={({ field: { onChange, value } }) => (
							<SubscriptionSelect
								displayMode={!isEditing}
								onChange={(newSub) => {
									setSubscription(newSub);
									onChange(newSub.subscriptionId);
								}}
								value={subscription}
								initialOptionId={value ?? undefined}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="pin"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="PIN"
								displayMode={!isEditing}
								value={value ?? ''}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<Controller
						control={control}
						name="cnamReg"
						render={({ field: { onChange, value } }) => (
							<BooleanRadioFormControl
								label="CNAM Reg"
								displayMode={!isEditing}
								value={value}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="cnamDip"
						render={({ field: { onChange, value } }) => (
							<BooleanRadioFormControl
								label="CNAM Dip"
								displayMode={!isEditing}
								value={value}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
			</Row>
			<header>
				<hr />
				<legend>Routing</legend>
			</header>
			<Row>
				<Col lg={6}>
					<Controller
						control={control}
						name="scenarioId"
						render={({ field: { onChange, value } }) => (
							<ScenarioSelect
								displayMode={!isEditing}
								onChange={(newScenario) => {
									setScenario(newScenario);
									onChange(newScenario.scenarioId);
								}}
								value={scenario}
								initialOptionId={value?.toString()}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="translatedNumberStd"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="DNIS"
								displayMode={!isEditing}
								value={value ?? ''}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
			</Row>
			<header>
				<hr />
				<legend>Porting</legend>
			</header>
			<Row>
				<Col lg={6}>
					<Controller
						control={control}
						name="isPorted"
						render={({ field: { onChange, value } }) => (
							<BooleanRadioFormControl
								label="Is Ported"
								displayMode={!isEditing}
								value={value}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="portingStatusId"
						render={({ field: { onChange, value } }) => (
							<PortingStatusSelect
								displayMode={!isEditing}
								value={portingStatus}
								initialOptionId={value?.toString()}
								onChange={(newPortingStatus: PortingStatusIndexResponse) => {
									setPortingStatus(newPortingStatus);
									onChange(newPortingStatus?.portingStatusId);
								}}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="portedTs"
						render={({ field: { onChange, value } }) => (
							<DatetimeFormControl
								label="Ported Date"
								displayMode={!isEditing}
								disableNowPreset
								onChangeWithIsNow={(newPortedTs) =>
									onChange(newPortedTs?.toDate())
								}
								value={moment(value)}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="activatedTs"
						render={({ field: { onChange, value } }) => (
							<DatetimeFormControl
								label="Activated Date"
								displayMode={!isEditing}
								disableNowPreset
								onChangeWithIsNow={(newActivatedTs) =>
									onChange(newActivatedTs?.toDate())
								}
								value={moment(value)}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="assignedTs"
						render={({ field: { onChange, value } }) => (
							<DatetimeFormControl
								label="Assigned Date"
								displayMode={!isEditing}
								disableNowPreset
								onChangeWithIsNow={(newAssignedTs) =>
									onChange(newAssignedTs?.toDate())
								}
								value={moment(value)}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="decommissionedTs"
						render={({ field: { onChange, value } }) => (
							<DatetimeFormControl
								label="Decommissioned Date"
								displayMode={!isEditing}
								disableNowPreset
								onChangeWithIsNow={(newDecommissionedTs) =>
									onChange(newDecommissionedTs?.toDate())
								}
								value={moment(value)}
							/>
						)}
					/>
				</Col>
			</Row>
			<header>
				<hr />
				<legend>External</legend>
			</header>
			<Row>
				<Col lg={6}>
					<Controller
						control={control}
						name="custExtRef1"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label={
									ServiceNumberPropertyToNameMap.get('custExtRef1') ??
									'Customer External Reference 1'
								}
								displayMode={!isEditing}
								value={value ?? ''}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="custExtRef2"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label={
									ServiceNumberPropertyToNameMap.get('custExtRef2') ??
									'Customer External Reference 2'
								}
								displayMode={!isEditing}
								value={value ?? ''}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="custExtRef3"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label={
									ServiceNumberPropertyToNameMap.get('custExtRef3') ??
									'Customer External Reference 3'
								}
								displayMode={!isEditing}
								value={value ?? ''}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<Controller
						control={control}
						name="vendExtRef1"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Vendor Name"
								displayMode={!isEditing}
								value={value ?? ''}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="vendExtRef2"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="APN or 911 Vendor"
								displayMode={!isEditing}
								value={value ?? ''}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<Controller
						control={control}
						name="custOrderNumber"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Customer Order Number"
								displayMode={!isEditing}
								value={value ?? ''}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
				<Col lg={6}>
					<Controller
						control={control}
						name="filename"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Uploaded Filename"
								displayMode
								value={value ?? ''}
								onChange={onChange}
							/>
						)}
					/>
				</Col>
			</Row>
			<hr />
			<header className="d-flex justify-content-between align-items-center">
				<legend
					className={clsx({
						'mb-0': isDirAsst !== 1
					})}
				>
					Directory Assistance
				</legend>
				<Controller
					control={control}
					name="isDirAsst"
					render={({ field: { onChange, value } }) => (
						<CheckboxFormControl
							label="Enabled"
							className="flex-grow-1"
							inline
							displayMode={!isEditing}
							id="dirAsstEnabled"
							value={Boolean(value)}
							onChange={(val) => onChange(Number(val))}
						/>
					)}
				/>
			</header>

			{isDirAsst === 1 && (
				<>
					<Row>
						<Col lg={6}>
							<Controller
								control={control}
								name="dirAsstName"
								render={({ field: { onChange, value } }) => (
									<InputFormControl
										label="Name"
										displayMode={!isEditing}
										value={value ?? ''}
										onChange={onChange}
									/>
								)}
							/>
						</Col>
					</Row>
					<AddressFields
						displayMode={!isEditing}
						address1={dirAsstAddress1 ?? ''}
						address2={dirAsstAddress2 ?? ''}
						city={dirAsstCity ?? ''}
						state={dirAsstState ?? ''}
						zip={dirAsstZip ?? ''}
						onChange={(updatedDirAssistAddress) => {
							setValue('dirAsstAddress1', updatedDirAssistAddress.address1);
							setValue('dirAsstAddress2', updatedDirAssistAddress.address2);
							setValue('dirAsstCity', updatedDirAssistAddress.city);
							setValue('dirAsstState', updatedDirAssistAddress.state);
							setValue('dirAsstZip', updatedDirAssistAddress.zip);
						}}
					/>
				</>
			)}
			<hr />
			<header className="d-flex justify-content-between align-items-center">
				<legend
					className={clsx({
						'mb-0': isE911 !== 1
					})}
				>
					Emergency Assistance (911)
				</legend>
				<Controller
					control={control}
					name="isE911"
					render={({ field: { onChange, value } }) => (
						<CheckboxFormControl
							label="Enabled"
							inline
							displayMode={!isEditing}
							value={Boolean(value)}
							onChange={(val) => onChange(Number(val))}
						/>
					)}
				/>
			</header>

			{isE911 === 1 && (
				<>
					<Row>
						<Col lg={6}>
							<Controller
								control={control}
								name="e911Name"
								render={({ field: { onChange, value } }) => (
									<InputFormControl
										label="Name"
										displayMode={!isEditing}
										value={value ?? ''}
										onChange={onChange}
									/>
								)}
							/>
						</Col>
					</Row>
					<AddressFields
						displayMode={!isEditing}
						address1={e911Address1 ?? ''}
						address2={e911Address2 ?? ''}
						city={e911City ?? ''}
						state={e911State ?? ''}
						zip={e911Zip ?? ''}
						onChange={(updatedE911Address) => {
							setValue('e911Address1', updatedE911Address.address1);
							setValue('e911Address2', updatedE911Address.address2);
							setValue('e911City', updatedE911Address.city);
							setValue('e911State', updatedE911Address.state);
							setValue('e911Zip', updatedE911Address.zip);
						}}
					/>
				</>
			)}
			<hr />
			<Controller
				control={control}
				name="notes"
				render={({ field: { onChange, value } }) => (
					<TextAreaFormControl
						label="Notes"
						displayMode={!isEditing}
						onChange={onChange}
						value={value ?? undefined}
					/>
				)}
			/>
		</RtForm>
	);
};
