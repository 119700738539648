/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationConfigLumenApi = {
    properties: {
        customerNumber: {
            type: 'string',
            isRequired: true,
        },
        productId: {
            type: 'string',
            isRequired: true,
        },
        serviceLocationId: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        allowedRespOrgIds: {
            type: 'string',
        },
        btn: {
            type: 'string',
            isRequired: true,
        },
        switchId: {
            type: 'string',
            isRequired: true,
        },
        trunkGroup: {
            type: 'string',
            isRequired: true,
        },
        sharedCallTree: {
            type: 'string',
            isRequired: true,
        },
        contactName: {
            type: 'string',
            isRequired: true,
        },
        contactNumber: {
            type: 'string',
            isRequired: true,
        },
    },
};