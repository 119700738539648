import { FC } from 'react';
import { WarningAlert } from '../WarningAlert';

interface IContentArticleProps {
	header?: string;
	showContent?: boolean;
	showWarning?: boolean;
	warningText?: string;
}

export const ContentArticle: FC<
	React.PropsWithChildren<IContentArticleProps>
> = (props) => {
	const { showWarning = false, header } = props;
	const { showContent = !showWarning, warningText = `No ${header} Found` } =
		props;

	return (
		<article className="mb-4">
			<header>
				{props.header && <h5>{header}</h5>}
				<WarningAlert text={warningText} hide={!showWarning} />
			</header>
			{showContent && props.children}
		</article>
	);
};
