import {
	NumberFormatter,
	NumbersFormatRegex
} from 'RtUi/utils/number/NumberFormatter';

const CURRENCY_REGEX = new RegExp(
	`[$\s]*${NumbersFormatRegex.source}[$\s]*`,
	'g'
);

export class CurrencyFormatter {
	public static toCurrency(
		locales: string | string[],
		currency: string,
		value: number
	): string {
		const formatter = new Intl.NumberFormat(locales, {
			style: 'currency',
			currency
		});

		return formatter.format(value);
	}

	/**
	 * matches '$14,000.00', '$14.000,20' or '14.000,20$'
	 * Output: 14000.20
	 * @param value string
	 */
	public static toNumber(value: string | number): number {
		const regex = new RegExp(CURRENCY_REGEX);
		const parsedValue = String(value);
		const matchedValue = regex.exec(parsedValue);

		const validatedValue = matchedValue ? matchedValue.input : '';

		return NumberFormatter.toNumber(validatedValue);
	}

	public static isCurrency(value: string | number): boolean {
		const regex = new RegExp(CURRENCY_REGEX);
		const parsedValue = String(value);
		const matchedValue = regex.exec(parsedValue);

		return Boolean(matchedValue);
	}
}
