/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrAnalysisCreateRequest = {
    properties: {
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        minuteProfileId: {
            type: 'number',
            isRequired: true,
        },
        templateName: {
            type: 'string',
        },
        tfn: {
            type: 'string',
        },
        cprEffectiveTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        cicMapping: {
            type: 'Array',
            isRequired: true,
        },
    },
};