import {
	DataGridColumn,
	DataGridTableInstance
} from 'RtUi/components/data/DataGrid/types';
import { CprLabelRowIndexDataGridButton } from 'RtUi/components/data/DataGrid/columns/CprLabelRowIndexDataGridColumn/components/CprLabelRowIndexDataGridButton';
import { CprLabelRowIndexDataGridColumnHeader } from 'RtUi/components/data/DataGrid/columns/CprLabelRowIndexDataGridColumn/components/CprLabelRowIndexDataGridColumnHeader';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';

interface CprLabelRowIndexDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	cpr: Cpr;
	displayMode: boolean;
	updateLabels: () => void;
}

export const CprLabelRowIndexDataGridColumn = <T = any,>({
	cpr,
	displayMode,
	updateLabels,
	...config
}: CprLabelRowIndexDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (index: number) => {
		return index + 1;
	};

	const renderHeader = (table: DataGridTableInstance<T>) => {
		const data = table
			.getPrePaginationRowModel()
			.rows.map((row) => row.original);

		if (displayMode || data.length > 0) {
			return null;
		}

		return (
			<CprLabelRowIndexDataGridColumnHeader
				cpr={cpr}
				updateLabels={updateLabels}
			/>
		);
	};

	return DefaultDataGridColumn({
		...config,
		enableEditing: !displayMode,
		exportValue: (value) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<number>()),
		getHeader: ({ table }) => renderHeader(table),
		Edit: ({ cell, row }) => (
			<CprLabelRowIndexDataGridButton
				cpr={cpr}
				label={row.original as any}
				index={cell.getValue<number>()}
				updateLabels={updateLabels}
			/>
		)
	});
};
