import * as React from 'react';
import {
	FileStreamIndexResponse,
	GuardianRatingSimulatorResponse,
	GuardianRatingSimulatorProfileRequest,
	ConnectionIndexResponse
} from 'RtModels';
import { RatingSimulatorRouter } from 'RtUi/app/rtVue/RatingSimulator/RatingSimulator.router';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { RtUiWideForm, RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import * as moment from 'moment';
import { RatingSimulatorHttp } from 'RtUi/app/rtVue/RatingSimulator/lib/http/RatingSimulatorHttp';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { RatingSimulatorResults } from 'RtUi/app/rtVue/RatingSimulator/lib/components/RatingSimulatorResults';
import { Col, Row, Button } from 'react-bootstrap';
import { TrunkGroupSelect } from 'RtUi/app/rtVue/Reconciliation/lib/components/TrunkGroupSelect';
import { isEmpty } from 'lodash-es';
import { urlFormat } from 'RtUi/utils/urlFormat/urlFormat';

enum RatingSimulateBy {
	callParameters = 'callParameters',
	file = 'file'
}

type SimulateByTypes = RatingSimulateBy;

interface IInitialResourceParams {
	simulateBy: SimulateByTypes;
	fileStreamId?: string;
	fileStream?: FileStreamIndexResponse;
	phoneNumber?: string;
	dialedNumber?: string;
	origLRN?: string;
	termLRN?: string;
	ingressTrunkGroupId?: string;
	egressTrunkGroupId?: string;
	duration?: number;
	startTime?: moment.Moment;
	callBlob?: string;
}

interface IRatingSimulatorContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {
	isSubmitting: boolean;
	error?: any;
	simulationResult?: GuardianRatingSimulatorResponse;
	numberInputsErrors?: { [key: string]: string };
	origTrunkGroup?: ConnectionIndexResponse;
	termTrunkGroup?: ConnectionIndexResponse;
}

@RatingSimulatorRouter.getIndexRtUiController({
	groupName: 'Rating',
	orderPriority: 3,
	groupOrderPriority: 30
})
export class RatingSimulatorIndexContainer extends SearchResultsContainer<
	{},
	{},
	IRatingSimulatorContainerState
> {
	public initParams = {
		simulateBy: RatingSimulateBy.callParameters,
		fileStream: undefined,
		phoneNumber: '',
		dialedNumber: ''
	};

	public state: IRatingSimulatorContainerState = {
		resourceParams: {},
		activeTab: this.tabs.Search,
		error: undefined,
		isSubmitting: false,
		numberInputsErrors: {},
		...this.initParams
	};

	public resource = new RatingSimulatorHttp();

	constructor(props: any) {
		super(props);

		const fsId = this.getSearchParam('fileStreamId');

		if (fsId) {
			this.state.simulateBy = RatingSimulateBy.file;
			this.state.fileStreamId = fsId;
			this.state.callBlob = localStorage.getItem('cdrRecord') ?? '';
		}
	}

	public async componentDidMount() {
		const fsId = this.getSearchParam('fileStreamId');
		if (fsId && this.state.fileStreamId) {
			try {
				this.setState({ isSubmitting: true, error: undefined });
				const requestBody = {
					fileStreamId: Number(this.state.fileStreamId),
					callBlob: this.state.callBlob!
				};
				const simulationResult = await this.resource.simulate(requestBody);
				this.setState({
					isSubmitting: false,
					simulationResult
				});

				this.submitAndGoToResults(document.createEvent('event') as any);
			} catch (error) {
				this.setState({ isSubmitting: false, error });
			}
		}
	}

	public getResourceParams() {
		return {};
	}

	public async onSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		this.setState({ isSubmitting: true });

		try {
			this.setState({ isSubmitting: true, error: undefined });

			let requestBody: GuardianRatingSimulatorProfileRequest;

			if (this.state.simulateBy === RatingSimulateBy.callParameters) {
				requestBody = {
					fileStreamId: this.state.fileStream!.fileStreamId,
					startTime: this.state.startTime?.utcOffset(0, true).toISOString(),
					duration: this.state.duration,
					ingressTrunkgroupId: this.state.ingressTrunkGroupId,
					egressTrunkgroupId: this.state.egressTrunkGroupId,
					originLrn: this.state.origLRN,
					dialedNumber: this.state.dialedNumber,
					ani: this.state.phoneNumber,
					termLrn: this.state.termLRN
				};
			} else {
				requestBody = {
					fileStreamId: this.state.fileStream!.fileStreamId,
					callBlob: this.state.callBlob!
				};
			}
			if (!isEmpty(requestBody)) {
				window.history.replaceState(
					null,
					'RouteTrust',
					`?${urlFormat(requestBody)}`
				);
			}

			const simulationResult = await this.resource.simulate(requestBody);
			this.setState({
				isSubmitting: false,
				simulationResult
			});

			this.submitAndGoToResults(e);
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	}

	public validateInputs(name: string) {
		const { phoneNumber, dialedNumber, origLRN, termLRN } = this.state;
		const errorMessages = { ...this.state.numberInputsErrors };
		errorMessages[name] = '';
		switch (name) {
			case 'dialedNumber':
			case 'termLRN':
				errorMessages.dialedNumber = '';
				errorMessages.termLRN = '';
				const errorMessageDialedTerm = `You cannot fill in both "Dialed Number" && "Term LRN" fields`;
				if (Boolean(dialedNumber) && Boolean(termLRN)) {
					errorMessages.dialedNumber = errorMessageDialedTerm;
					errorMessages.termLRN = errorMessageDialedTerm;
				}
				break;
			case 'origLRN':
			case 'phoneNumber':
				errorMessages.origLRN = '';
				errorMessages.phoneNumber = '';
				const errorMessageOrigPhone = `You cannot fill in both "Orig LRN && "Number" fields`;
				if (Boolean(origLRN) && Boolean(phoneNumber)) {
					errorMessages.origLRN = errorMessageOrigPhone;
					errorMessages.phoneNumber = errorMessageOrigPhone;
				}
				break;
		}
		this.setState({ numberInputsErrors: errorMessages });
	}

	public renderCallParameters() {
		return (
			<Col xl={12}>
				<Row>
					<Col lg={12}>
						<Row>
							<Col xl={6}>
								<TollFreeNumberFormControl
									label="Number"
									onChange={(phoneNumber) => {
										this.setState({ phoneNumber }, () => {
											this.validateInputs('phoneNumber');
										});
									}}
									value={this.state.phoneNumber}
									name="phoneNumber"
									errorText={this.state.numberInputsErrors?.phoneNumber}
									className={
										Boolean(this.state.numberInputsErrors?.phoneNumber)
											? 'is-invalid'
											: ''
									}
								/>
							</Col>
							<Col lg={6}>
								<TollFreeNumberFormControl
									label="Dialed Number"
									onChange={(dialedNumber) => {
										this.setState({ dialedNumber }, () => {
											this.validateInputs('dialedNumber');
										});
									}}
									value={this.state.dialedNumber}
									name="dialedNumber"
									errorText={this.state.numberInputsErrors?.dialedNumber}
									className={
										Boolean(this.state.numberInputsErrors?.dialedNumber)
											? 'is-invalid'
											: ''
									}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={6}>
								<TollFreeNumberFormControl
									label="Orig LRN"
									onChange={(origLRN) => {
										this.setState({ origLRN }, () => {
											this.validateInputs('origLRN');
										});
									}}
									value={this.state.origLRN}
									name="origLRN"
									errorText={this.state.numberInputsErrors?.origLRN}
									className={
										Boolean(this.state.numberInputsErrors?.origLRN)
											? 'is-invalid'
											: ''
									}
								/>
							</Col>
							<Col lg={6}>
								<TollFreeNumberFormControl
									label="Term LRN"
									onChange={(termLRN) => {
										this.setState({ termLRN }, () => {
											this.validateInputs('termLRN');
										});
									}}
									value={this.state.termLRN}
									name="termLRN"
									errorText={this.state.numberInputsErrors?.termLRN}
									className={
										Boolean(this.state.numberInputsErrors?.termLRN)
											? 'is-invalid'
											: ''
									}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={6}>
								<TrunkGroupSelect
									clearable={false}
									label="Orig Trunk Group"
									autoHeight
									disabled={!this.state.fileStream}
									onChange={(origTrunkGroup) => {
										this.setState({
											origTrunkGroup,
											ingressTrunkGroupId: origTrunkGroup.trunkGroupId
										});
									}}
									params={{
										directionId: 1,
										fileStreamIds: this.state.fileStream?.fileStreamId
											? [Number(this.state.fileStream.fileStreamId)]
											: []
									}}
									value={this.state.origTrunkGroup}
								/>
							</Col>
							<Col lg={6}>
								<TrunkGroupSelect
									clearable={false}
									label="Term Trunk Group"
									autoHeight
									disabled={!this.state.fileStream}
									onChange={(termTrunkGroup) => {
										this.setState({
											termTrunkGroup,
											egressTrunkGroupId: termTrunkGroup.trunkGroupId
										});
									}}
									params={{
										directionId: 2,
										fileStreamIds: this.state.fileStream?.fileStreamId
											? [Number(this.state.fileStream.fileStreamId)]
											: []
									}}
									value={this.state.termTrunkGroup}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={6}>
								<DatetimeFormControl
									required={true}
									label="Start Time"
									disableNowPreset
									onChange={(startTime) => this.setState({ startTime })}
									value={this.state.startTime}
								/>
							</Col>
							<Col lg={6}>
								<InputFormControl
									min={1}
									required={true}
									label="Duration"
									type="number"
									onChange={(duration) =>
										this.setState({ duration: Number(duration) })
									}
									value={String(this.state.duration)}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		);
	}

	public renderResultsFormContent() {}

	public render() {
		return (
			<TabbedLayout
				router={RatingSimulatorRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiWideForm
						className="rating-simulator-form"
						hideButtons
						error={this.state.error}
						isSubmitting={this.state.isSubmitting}
						onSubmit={(e) => this.onSubmit(e)}
					>
						<Row>
							<Col xl={12}>
								<RadioFormControl<SimulateByTypes>
									label="Simulate by"
									value={this.state.simulateBy}
									onChange={(simulateBy) => this.setState({ simulateBy })}
									options={[
										{
											value: RatingSimulateBy.callParameters,
											label: 'Call Parameters'
										},
										{
											value: RatingSimulateBy.file,
											label: 'Paste CDR from file'
										}
									]}
								/>
								<FileStreamSelect
									isActive
									required
									label="File Stream"
									initialOptionId={this.state.fileStreamId}
									value={this.state.fileStream}
									onChange={(fileStream) => {
										this.setState({
											fileStream,
											termTrunkGroup: undefined,
											origTrunkGroup: undefined,
											egressTrunkGroupId: undefined,
											ingressTrunkGroupId: undefined
										});
									}}
								/>
							</Col>
							{this.state.simulateBy === RatingSimulateBy.callParameters &&
								this.renderCallParameters()}
							{this.state.simulateBy === RatingSimulateBy.file && (
								<Col xl={12}>
									<TextAreaFormControl
										rows={6}
										label="Whole CDR"
										onChange={(callBlob) => this.setState({ callBlob })}
										value={this.state.callBlob}
									/>
								</Col>
							)}
						</Row>
						<RtUiForm.Footer>
							<Button
								variant="submit"
								disabled={this.state.isSubmitting}
								type="submit"
							>
								{this.state.isSubmitting ? (
									<i className="fas fa-cog fa-cog fa-spin" />
								) : (
									<i className="fas fa-fw fa-play-circle fa-sm" />
								)}
								<span>&nbsp;Simulate</span>
							</Button>
						</RtUiForm.Footer>
					</RtUiWideForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<RatingSimulatorResults result={this.state.simulationResult} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
