/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ScenarioActionIndexResponse = {
    properties: {
        scenarioActionId: {
            type: 'ScenarioActionType',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
    },
};