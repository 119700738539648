import clsx from 'clsx';
import hotkeys from 'hotkeys-js';
import { debounce, throttle, take } from 'lodash-es';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { Alert, Nav, Button } from 'react-bootstrap';
import { ComponentController } from 'RtUi/components/ComponentController';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import {
	RtUiRouter,
	SearchConfidenceLevel
} from 'RtUi/components/containers/lib/RtUiRouter';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import { $enum } from 'ts-enum-util';

interface IGlobalSearchProps {
	closeNavFn?: () => void;
}

interface IGlobalSearchResult<T> {
	router: RtUiRouter<T, any>;
	results: T[];
}

interface IGlobalSearchRecentlyViewed<T> {
	router: RtUiRouter<T, any>;
	result: T;
}

interface IGlobalSearchState {
	search: string;
	isSearching: boolean;
	searchResults: Array<IGlobalSearchResult<any>> | null;
	recentlyViewed: Array<IGlobalSearchRecentlyViewed<any>> | null;
	openAside: boolean | undefined;
	asideGlobalLeftOffset: number;
}

export class GlobalSearch extends ApplicationContainer<
	IGlobalSearchProps,
	IGlobalSearchState
> {
	public setAsideGlobalOffset = throttle(() => {
		if (this.inputRef.current?.inputElementRef.current) {
			this.setState({
				asideGlobalLeftOffset:
					this.inputRef.current.inputElementRef.current?.getBoundingClientRect()
						.x
			});
		}
	}, 100);

	public state: IGlobalSearchState = {
		search: '',
		isSearching: false,
		searchResults: null,
		recentlyViewed: null,
		openAside: undefined,
		asideGlobalLeftOffset: 0
	};

	public debounceGlobalSearch = debounce(() => this.globalSearch(), 750);
	public containerRef: HTMLElement | null = null;
	public navLinkRef: HTMLElement | null = null;
	public inputRef = React.createRef<InputFormControl>();
	public currentGlobalSearchUuid = generateUUID();
	public checkIfClickWasOutsideContainerFn = (evt: MouseEvent) =>
		this.checkIfClickWasOutsideContainer(evt);

	public componentDidMount() {
		hotkeys('ctrl+s', (evt) => this.toggleAside(true, evt));
		hotkeys('esc', (evt) => this.toggleAside(false, evt));
		//allow hotkeys to be fired anywhere (input, select, etc)
		hotkeys.filter = () => true;

		setTimeout(() => {
			//Wait for first page to load before calculating offset
			this.setAsideGlobalOffset();
		}, 500);

		window.addEventListener('click', this.checkIfClickWasOutsideContainerFn);
		window.addEventListener('resize', this.setAsideGlobalOffset);
	}

	public componentWillUnmount() {
		window.removeEventListener('click', this.checkIfClickWasOutsideContainerFn);
		window.removeEventListener('resize', this.setAsideGlobalOffset);
	}

	public toggleAside(openAside = !this.state.openAside, evt?: KeyboardEvent) {
		if (evt) {
			evt.preventDefault();
		}

		if (openAside !== this.state.openAside) {
			if (openAside === false) {
				this.reset();
			} else {
				this.inputRef.current?.focus();

				this.setState({ openAside });
			}
		}
	}

	public checkIfClickWasOutsideContainer(evt: MouseEvent) {
		const eventTarget = evt.target as HTMLElement | null;

		if (
			!this.containerRef ||
			!this.navLinkRef ||
			!this.state.openAside ||
			!eventTarget
		) {
			return;
		}

		const clickWasInsideContainer = this.containerRef.contains(eventTarget);
		const clickWasInsideNavLink = this.navLinkRef.contains(eventTarget);

		if (!(clickWasInsideContainer || clickWasInsideNavLink)) {
			this.reset();
		}
	}

	public async globalSearch() {
		const search = this.state.search.trim();

		if (search === '') {
			this.setState({ searchResults: null });
			return;
		}

		const searchUuid = generateUUID();
		const searchResults: Array<IGlobalSearchResult<any>> = [];
		this.currentGlobalSearchUuid = searchUuid;
		const isMostRecentSearch = () =>
			searchUuid === this.currentGlobalSearchUuid;

		this.setState({
			openAside: true,
			isSearching: true,
			searchResults
		});

		const componentController = ComponentController.getInstance();
		const routers = componentController.getApplicationRouters();
		const routerToSearch: RtUiRouter[] = [];

		const confidence: { [index: number]: RtUiRouter[] } = {};
		for (const confidenceLevel of $enum(SearchConfidenceLevel).getValues()) {
			confidence[confidenceLevel] = [];
		}

		for (const router of routers) {
			const level = router.globalSearchConfidenceCheck(search);
			if (level !== SearchConfidenceLevel.Impossible) {
				confidence[level].push(router);
			}
		}

		for (const [_, confidentRouters] of Object.entries(confidence)) {
			if (confidentRouters.length > 0) {
				routerToSearch.push(...confidentRouters);
			}
		}

		const allSearches = routerToSearch.map(async (router) => {
			let results: any[];
			let successful = true;

			try {
				results = await router.globalSearch(search);

				if (!Array.isArray(results)) {
					throw new Error(
						`${router.getName()} did not return an array of results`
					);
				}
			} catch (err) {
				console.error(err);

				successful = false;
				results = [];
			}

			if (isMostRecentSearch() && results && results.length > 0) {
				searchResults.push({ router, results });
				this.setState({ searchResults });
			}

			return successful;
		});

		const successResponses = await Promise.all(allSearches);

		if (isMostRecentSearch()) {
			const hits = successResponses.filter((sr) => sr === true).length;
			const misses = successResponses.length - hits;
			console.warn(
				`Global Search for "${search}": ${hits} hits · ${misses} misses`
			);
			this.setState({ isSearching: false });
		}
	}

	public onHistoryPathChange() {
		this.reset();
	}

	public reset() {
		const { closeNavFn = () => ({}) } = this.props;

		//Stop search results promises from running
		this.currentGlobalSearchUuid = generateUUID();

		this.setState({
			openAside: false,
			search: '',
			isSearching: false,
			searchResults: null
		});

		closeNavFn();
	}

	public updateSearch(newSearchStr: string) {
		if (newSearchStr !== this.state.search) {
			this.setState({ search: newSearchStr }, () =>
				this.debounceGlobalSearch()
			);
		}
	}

	public renderContainer<T>(router: RtUiRouter<T, any>, results: T[]) {
		if (results.length <= 0) {
			return null;
		}

		const componentControllerInstance = ComponentController.getInstance();

		const onNavLinkClick = (result: T) => {
			let recentlyViewed = this.state.recentlyViewed ?? [];

			recentlyViewed.splice(0, 0, {
				result,
				router
			});

			recentlyViewed = take(recentlyViewed, 5);

			this.setState({ recentlyViewed });

			this.reset();

			const profileRoute = router.getProfileRoute(result);

			this.goToPath(profileRoute);
		};

		let resultsListItems: JSX.Element[] = [];

		try {
			resultsListItems = results?.map((result) => {
				const externalRouters =
					componentControllerInstance.getRoutersWithProfileAccess(
						result,
						router
					);
				const label = router.getIndexLabel(result);
				const summaryComponent = router.getSummaryComponentFor(
					result,
					externalRouters
				);

				return (
					<Nav.Link
						key={label}
						as="span"
						style={{ cursor: 'pointer' }}
						className="px-0"
						onClick={() => onNavLinkClick(result)}
						tabIndex={-1}
					>
						{summaryComponent}
					</Nav.Link>
				);
			});
		} catch (err) {
			console.error(err);
			console.error(
				`Unable to render results for ${router.getName()}. See above.`
			);

			resultsListItems = [];
		}

		return (
			<article key={router.getRouterId()} className="mb-3">
				<h6 className="mb-1">
					<b>{router.getPluralName()}</b>
				</h6>

				<div className="list-group">{resultsListItems}</div>
			</article>
		);
	}

	public renderRecentlyViewed(
		recentlyViewed: Array<IGlobalSearchRecentlyViewed<any>>
	) {
		if (recentlyViewed.length <= 0) {
			return null;
		}

		const componentControllerInstance = ComponentController.getInstance();

		const onNavLinkClick = ({
			result,
			router
		}: IGlobalSearchRecentlyViewed<any>) => {
			this.reset();

			const profileRoute = router.getProfileRoute(result);

			this.goToPath(profileRoute);
		};

		let resultsListItems: JSX.Element[] = [];

		try {
			resultsListItems = recentlyViewed.map(({ result, router }) => {
				const externalRouters =
					componentControllerInstance.getRoutersWithProfileAccess(
						result,
						router
					);
				const label = router.getIndexLabel(result);
				const summaryComponent = router.getSummaryComponentFor(
					result,
					externalRouters
				);

				return (
					<Nav.Link
						key={label}
						as="span"
						style={{ cursor: 'pointer' }}
						className="px-0"
						onClick={() => onNavLinkClick({ result, router })}
						tabIndex={-1}
					>
						{summaryComponent}
					</Nav.Link>
				);
			});
		} catch (err) {
			resultsListItems = [];
		}

		return (
			<article className="mb-3">
				<h6 className="mb-1">
					<b>Recently Viewed</b>
				</h6>

				<div className="list-group">{resultsListItems}</div>
			</article>
		);
	}

	public renderSkeleton(className?: string) {
		return (
			<ContentLoader
				preserveAspectRatio="none"
				height={500}
				width={359}
				className={className}
			>
				<rect x={0} y={0} rx={5} ry={5} width={210} height={17} />

				{[0, 1, 2].map((num) => {
					const yCoordinate = 72 * num + 5 + 20;

					return (
						<React.Fragment key={num}>
							<rect
								x={0}
								y={yCoordinate}
								rx={5}
								ry={5}
								width={360}
								height={62}
							/>
						</React.Fragment>
					);
				})}
			</ContentLoader>
		);
	}

	public render() {
		const { openAside, isSearching, searchResults, recentlyViewed } =
			this.state;
		const noResultsFound =
			!isSearching && searchResults && searchResults.length <= 0;

		return (
			<>
				<header
					className="globalSearch-toolbar"
					ref={(navLinkRef) => (this.navLinkRef = navLinkRef)}
				>
					<span>
						{isSearching && <i className="fas fa-fw fa-cog fa-spin" />}
						{!isSearching && <i className="fas fa-fw fa-search" />}
					</span>
					<InputFormControl
						label=""
						placeholder="Global Search"
						useControlGroup={false}
						ref={this.inputRef}
						onFocus={() => {
							if (recentlyViewed && recentlyViewed.length > 0) {
								this.toggleAside(true);
							}
						}}
						onChange={(searchStr) => this.updateSearch(searchStr)}
						value={this.state.search}
					/>
				</header>
				<section ref={(containerRef) => (this.containerRef = containerRef)}>
					<section
						style={{
							left: this.state.asideGlobalLeftOffset
						}}
						className={clsx('aside-left', openAside ? 'opened' : 'closed')}
					>
						<header className="text-end">
							<Button
								variant="light"
								type="button"
								onClick={() => this.reset()}
								tabIndex={-1}
							>
								<i className="fas fa-fw fa-times text-dark" />
							</Button>
						</header>
						{!searchResults &&
							recentlyViewed &&
							this.renderRecentlyViewed(recentlyViewed)}
						{noResultsFound && (
							<Alert variant="info" className="mt-3">
								<i className="fas fa-fw fa-info-circle" />
								<span>&nbsp;No results found.</span>
							</Alert>
						)}

						{searchResults &&
							searchResults.map((searchResult) =>
								this.renderContainer(searchResult.router, searchResult.results)
							)}

						{isSearching && this.renderSkeleton()}
					</section>
				</section>
			</>
		);
	}
}
