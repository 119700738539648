import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import {
	SipCdrSearchIndexResponse,
	SipCdrSearchProfileResponse
} from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { isNil } from 'lodash-es';

interface ICdrSearchReportRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const getCdrSearchReportContainerTabs = (
	profile?: SipCdrSearchProfileResponse
): ICdrSearchReportRouterTabs => ({
	Profile: {
		header: 'Profile'
	},
	IngressSipTrace: {
		header: 'Ingress SIP Trace',
		rtRoute: RtxSipApiRoutes.SipTrace.Profile,
		isHidden: !(
			!isNil(profile?.iInviteTs) &&
			!isNil(profile?.iCallId) &&
			!isNil(profile?.iGatewayId)
		)
	},
	EgressSipTrace: {
		header: 'Egress SIP Trace',
		rtRoute: RtxSipApiRoutes.SipTrace.Profile,
		isHidden: !(
			!isNil(profile?.iInviteTs) &&
			!isNil(profile?.iCallId) &&
			!isNil(profile?.iGatewayId)
		)
	}
});

export class CdrSearchReportRouterClass extends SipApplicationRouter<
	SipCdrSearchIndexResponse,
	SipCdrSearchProfileResponse,
	ICdrSearchReportRouterTabs
> {
	constructor() {
		super(
			'CDR Search',
			'cdrSearch',
			'cdrId',
			getCdrSearchReportContainerTabs()
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.CdrSearch);
	}

	public getProfileLabel(profile: SipCdrSearchProfileResponse): string {
		return String(profile.cdrId);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileRoute(
		record: SipCdrSearchIndexResponse,
		tabId?: string,
		prefixSectionKey: boolean = true
	): string {
		const route = this.formProfileRoute(record, tabId, prefixSectionKey);
		const params: Record<string, string | number> = {};

		if (tabId) {
			params.tabId = tabId;
		}

		return this.getRouteWithParams(route, params);
	}

	public getProfileTabs(
		profile?: SipCdrSearchProfileResponse
	): ICdrSearchReportRouterTabs {
		return getCdrSearchReportContainerTabs(profile);
	}
}

export const CdrSearchReportRouter = new CdrSearchReportRouterClass();
