import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { isValidFqdn } from 'RtUi/app/AccountManagement/Switches/lib/utils';
import { IFormControlProps } from 'RtUi/components/form/FormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';

interface IFqdnInputFormControlProps extends IFormControlProps<string> {}

export const FqdnInputFormControl = ({
	value = '',
	disabled,
	required,
	label = '',
	onChange = () => {},
	displayMode
}: IFqdnInputFormControlProps): JSX.Element => {
	const [error, setError] = useState<string>();
	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const currentValue = e.target.value;
		setError('');

		if (!isValidFqdn(currentValue)) {
			setError('invalid value');
		}

		onChange(currentValue);
	};

	return (
		<ControlGroup
			label={label}
			errorText={error}
			value={value}
			isInvalid={Boolean(error)}
			displayMode={displayMode}
		>
			<Form.Control
				type="text"
				value={value}
				disabled={disabled}
				required={required}
				onChange={onChangeHandler}
				isInvalid={Boolean(error)}
			/>
		</ControlGroup>
	);
};
