import { FC } from 'react';
import { SipConnection } from 'RtModels';
import { SipConnectionEditor } from 'RtUi/app/rtSip/Connections/lib/forms/SipConnectionEditor';
import { Aside } from 'RtUi/components/ui/Aside';

interface ICreateConnectionAsideProps {
	subscriptionId: number;
	isOpen: boolean;
	onCancel: () => void;
	onSave: (profile: SipConnection) => void;
	profile?: SipConnection;
}

export const CreateConnectionAside: FC<
	React.PropsWithChildren<ICreateConnectionAsideProps>
> = ({ isOpen, profile, onCancel, subscriptionId, onSave }) => {
	return (
		<Aside isOpen={isOpen} disabledBody>
			<Aside.Header
				warning={Boolean(profile)}
				header={`${profile ? 'Edit' : 'Create'} Connection`}
				onClose={onCancel}
			/>
			<SipConnectionEditor
				key={profile?.sipConnectionId}
				createMode={!Boolean(profile)}
				editMode={profile}
				subscriptionId={subscriptionId}
				onUpdateSuccess={onSave}
				onCancel={onCancel}
			/>
		</Aside>
	);
};

CreateConnectionAside.displayName = 'CreateConnectionAside';
