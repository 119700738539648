/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CdrExportIntervals {
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Invoice = 4,
    BillingCycle = 5,
    Manual = 6,
    Minute = 7,
}