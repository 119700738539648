/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianRerateControlProfileResponse = {
    properties: {
        jobId: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        uploadId: {
            type: 'string',
            isRequired: true,
        },
        queryParameters: {
            type: 'RerateQueryParameters',
            isRequired: true,
        },
        jobStatus: {
            type: 'RateControlJobStatus',
            isRequired: true,
        },
        jobType: {
            type: 'RateControlJobType',
            isRequired: true,
        },
        rerateType: {
            type: 'RateControlRerateType',
            isRequired: true,
        },
        initialCallCount: {
            type: 'number',
            isRequired: true,
        },
        initialMinutes: {
            type: 'number',
            isRequired: true,
        },
        initialRevenue: {
            type: 'number',
            isRequired: true,
        },
        initialCost: {
            type: 'number',
            isRequired: true,
        },
        completedCallCount: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        completedMinutes: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        completedRevenue: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        completedCost: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
    },
};