import { noop } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Item } from 'react-contexify';
import { SipConnection, SipConnectionIndexRequest } from 'RtModels';
import { CreateConnectionAside } from 'RtUi/app/rtSip/Connections/components/CreateConnectionAside';
import {
	fetchSipConnection,
	useGetSipConnection
} from 'RtUi/app/rtSip/Connections/lib/services';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getConnectionsColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/connections';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';
import { UserActions } from 'RtUi/state/actions/user';

interface ConnectionGridProps {
	router?: RtUiRouter;
	resourceParams?: SipConnectionIndexRequest;
	autoFocusFilter?: boolean;
	onUpdate?: () => void;
}

export const ConnectionGrid = ({
	resourceParams,
	router,
	onUpdate = noop
}: ConnectionGridProps) => {
	const [isAsideOpen, setIsAsideOpen] = useState<boolean>(false);
	const [selectedResource, setSelectedResource] = useState<SipConnection>();
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetSipConnection(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportSipConnections', resourceParams],
		resourceParams ?? {},
		(params) => fetchSipConnection(params)
	);

	const connectionsGridColumns = useMemo(() => getConnectionsColumns(), []);

	return (
		<>
			<DataGrid<SipConnection>
				router={router}
				data={data?.data}
				columns={connectionsGridColumns}
				pageName={'rtSip_connections'}
				loading={isLoading}
				totalRows={data?.totalCount}
				enableExternalExport
				getExternalExportData={fullResultsReq}
				rowThemeColor={(record) =>
					selectedResource?.sipConnectionId === record?.sipConnectionId
						? RowThemeColor.WARNING
						: undefined
				}
				menuLinks={(record) => {
					return (
						<Item
							onClick={() => {
								setSelectedResource(record);
								setIsAsideOpen(true);
							}}
						>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-bolt" />
								<span>
									<span>Edit SIP Connection</span>
								</span>
							</span>
						</Item>
					);
				}}
				headerAction={() => {
					if (!UserActions.isTFAEnabled()) {
						return <></>;
					}

					return (
						<Button onClick={() => setIsAsideOpen(true)} variant="light">
							<i className="fas fa-fw fa-plus fa-lg" />
							&nbsp;Create Connection
						</Button>
					);
				}}
			/>
			<CreateConnectionAside
				profile={selectedResource}
				subscriptionId={resourceParams?.subscriptionId!}
				isOpen={isAsideOpen}
				onCancel={() => {
					setIsAsideOpen(false);
					setSelectedResource(undefined);
				}}
				onSave={() => {
					setIsAsideOpen(false);
					setSelectedResource(undefined);
					refetch();
					onUpdate();
				}}
			/>
		</>
	);
};

ConnectionGrid.displayName = 'ConnectionGrid';
