/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GatewayIndexResponse = {
    properties: {
        partitionSipGatewayId: {
            type: 'number',
            isRequired: true,
        },
        sipGatewayTypeId: {
            type: 'number',
            isRequired: true,
        },
        listenFqdn: {
            type: 'string',
            isRequired: true,
        },
        listenPort: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};