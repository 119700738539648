import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType, type ICprLerg } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import type { CprRow } from 'Somos/lib/SomosCpr/RtCprV2/CprRow';

// exported definitions
// ======================================================================

export class CprColTerminatingNumber extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.TerminatingNumber;

	public readonly valueLimit = 1;
	public readonly valueRegExp = /^(\d{10}|#DIAL)$/;

	public readonly isTermNode = true;

	// remove readonly property from possibles and possiblesByKey
	protected possibles: string[] = [];
	protected possiblesByKey: Record<string, ICprLerg> = {};

	public constructor(cprRow: CprRow, cprIdx: number, uuid: string | undefined = undefined) {
		super(cprRow, cprIdx, uuid);
		this.resetPossibles();
	}

	public getPossibles(search: string, excludeCurrent: boolean = false, limit: number = 0): string[] {
		const possible = (this.cpr.routingCacheKey.indexOf('*') === 0) ? '#DIAL' : this.cpr.routingCacheKey;
		return [possible];
	};

	public getValue(): string | null {
		if (this.cprValues.length !== 1) {
			return null;
		}
		return this.cprValues[0].getValue();
	}

	public resetPossibles() {
		// DO NOT override resetPossbiles unless the values are dynamic based on other selections.

		this.possibles = [];
		this.possiblesByKey = {};

		const possible = (this.cpr.routingCacheKey.indexOf('*') === 0) ? '#DIAL' : this.cpr.routingCacheKey;

		const asCprLerg: ICprLerg = {
			cprNodeTypeId: CprNodeType.TerminatingNumber, // @TODO not really used
			worldValue: possible,
			somosValue: possible,
			pointId: '',
			state: '',
			lata: '',
			ocn: ''
		};

		this.possiblesByKey[possible] = asCprLerg;
	}

}
