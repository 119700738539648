/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MetricsCountsIndexResponse = {
    properties: {
        value: {
            type: 'string',
            isRequired: true,
        },
        count: {
            type: 'number',
            isRequired: true,
        },
    },
};