/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileTypeIndexResponse = {
    properties: {
        fileTypeId: {
            type: 'number',
            isRequired: true,
        },
        description: {
            type: 'string',
            isRequired: true,
        },
        formatType: {
            type: 'string',
            isRequired: true,
        },
        footer: {
            type: 'number',
            isRequired: true,
        },
        delimiter: {
            type: 'string',
            isRequired: true,
        },
        vendor: {
            type: 'string',
            isRequired: true,
        },
        documentUrl: {
            type: 'string',
            isRequired: true,
        },
        helperClass: {
            type: 'string',
            isRequired: true,
        },
        dataType: {
            type: 'string',
            isRequired: true,
        },
    },
};