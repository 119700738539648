import { Rt800ApiRoutes } from 'RtExports/routes';
import { LcrCarrierRatesLookupIndexResponse } from 'RtModels';
import { LcoApplicationRouter } from 'RtUi/app/rtLco/rtLco.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';

interface ICarriersRatesContainerTabs
	extends IProfileApplicationContainerTabs {}

export const CarriersRatesContainerTabs: ICarriersRatesContainerTabs = {};

export class CarriersRatesRouterClass extends LcoApplicationRouter<
	LcrCarrierRatesLookupIndexResponse,
	{},
	ICarriersRatesContainerTabs
> {
	constructor() {
		super(
			'Carrier Rates',
			'carriersRates',
			'lcrCarrierId',
			CarriersRatesContainerTabs
		);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.LcrCarrierRatesLookup);
	}

	public getProfileLabel() {
		return '';
	}
}

export const CarriersRatesRouter = new CarriersRatesRouterClass();
