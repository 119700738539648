import { cloneDeep } from 'lodash-es';
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { DropdownOnBody } from 'RtUi/components/ui/DropdownOnBody';
import { MultiDataList } from 'RtUi/components/ui/MultiDataList';
import { DaysOfWeekSelect } from './DaysOfWeekSelect';
import { DaysOfWeekTypes, getDayOfWeek } from './interfaces';

interface IDaysOfWeekRangeFormControlProps {
	value: string[];
	onChange: (newValue: string[]) => void;
	className?: string;
	label?: string;
	displayMode?: boolean;
	required?: boolean;
}

interface IDaysOfWeekRangeFormControlState {
	isOpen: boolean;
}

export class DaysOfWeekRangeFormControl extends React.Component<
	IDaysOfWeekRangeFormControlProps,
	IDaysOfWeekRangeFormControlState
> {
	public state: IDaysOfWeekRangeFormControlState = {
		isOpen: false
	};

	public selectContainerRef: HTMLElement | null = null;

	public toggleIsOpen(isOpen = !this.state.isOpen) {
		this.setState({ isOpen });
	}

	public updateFromDayOfWeek(
		newFromDayOfTheWeek: DaysOfWeekTypes,
		index: number
	) {
		const newValue = this.props.value ? cloneDeep(this.props.value) : [];
		const valueToUpdate = newValue[index];
		const valueParts = valueToUpdate.split('-');

		valueParts[0] = newFromDayOfTheWeek;

		if (valueParts[0] === valueParts[1]) {
			newValue[index] = valueParts[1] as DaysOfWeekTypes;
		} else {
			newValue[index] = valueParts.join('-') as DaysOfWeekTypes;
		}

		this.props.onChange(newValue);
	}

	public updateToDayOfWeek(newToDayOfTheWeek: DaysOfWeekTypes, index: number) {
		const newValue = this.props.value ? cloneDeep(this.props.value) : [];
		const valueToUpdate = newValue[index];
		const valueParts = valueToUpdate.split('-');

		valueParts[1] = newToDayOfTheWeek;

		if (valueParts[0] === valueParts[1]) {
			newValue[index] = valueParts[1] as DaysOfWeekTypes;
		} else {
			newValue[index] = valueParts.join('-') as DaysOfWeekTypes;
		}

		this.props.onChange(newValue);
	}

	public deleteTimeRange(index: number) {
		const newValue = this.props.value ? cloneDeep(this.props.value) : [];

		if (index in newValue) {
			newValue.splice(index, 1);
		}

		this.props.onChange(newValue);
	}

	public addTimeRange() {
		let newValue = this.props.value ? cloneDeep(this.props.value) : [];

		//Remove other values
		newValue = newValue.filter((val) => val !== 'OTHER');

		newValue.push('SU');

		this.props.onChange(newValue);
	}

	public setValueToOther() {
		this.props.onChange(['OTHER']);
	}

	public render() {
		const { value = [] } = this.props;

		return (
			<>
				<MultiDataList
					required={this.props.required}
					displayMode={this.props.displayMode}
					values={value}
					disableNoSuggestionsPrompt
					className={this.props.className}
					suggestions={[]}
					onChange={(changedValue) => {
						/** noop */
					}}
					ref={(selectContainerRef) =>
						(this.selectContainerRef = selectContainerRef)
					}
					onFocus={() => this.toggleIsOpen(true)}
				/>
				{this.selectContainerRef && (
					<DropdownOnBody
						toggle={() => this.toggleIsOpen()}
						isOpen={this.state.isOpen}
						selectDOM={this.selectContainerRef}
					>
						<section style={{ minWidth: 350 }}>
							{value.map((dayOfWeekRange, index) => {
								if (dayOfWeekRange === 'OTHER') {
									return null;
								}

								const dayMonthParts = dayOfWeekRange.split(
									'-'
								) as DaysOfWeekTypes[];
								const dayOfWeekTo = dayMonthParts[0];
								const dayOfWeekFrom = dayMonthParts[1] ?? dayMonthParts[0];

								return (
									<Form.Group
										className="d-md-flex justify-content-between align-items-center mb-3"
										key={index}
									>
										<article className="me-2 flex-fill">
											<DaysOfWeekSelect
												clearable={false}
												useControlGroup={false}
												onChange={(newDayOfWeek) =>
													this.updateFromDayOfWeek(newDayOfWeek.key, index)
												}
												key={dayOfWeekTo}
												value={getDayOfWeek(dayOfWeekTo)}
											/>
										</article>
										<article className="me-2">
											<i className="fas fa-fw fa-arrow-right" />
										</article>
										<article className="me-2 flex-fill">
											<DaysOfWeekSelect
												clearable={false}
												useControlGroup={false}
												onChange={(newDayOfWeek) =>
													this.updateToDayOfWeek(newDayOfWeek.key, index)
												}
												key={dayOfWeekFrom}
												value={getDayOfWeek(dayOfWeekFrom)}
											/>
										</article>
										<article>
											<Button
												variant="light"
												onClick={() => this.deleteTimeRange(index)}
											>
												<i className="fas fa-fw fa-trash-alt" />
											</Button>
										</article>
									</Form.Group>
								);
							})}
							<article>
								<Button variant="light" onClick={() => this.addTimeRange()}>
									<i className="fas fa-fw fa-plus" />
									<span>&nbsp;Add Day Range</span>
								</Button>
								<Button
									variant="light"
									className="ms-2"
									onClick={() => this.setValueToOther()}
									disabled={value[0] === 'OTHER'}
								>
									<i className="fas fa-fw fa-plus" />
									<span>&nbsp;Set to OTHER</span>
								</Button>
							</article>
						</section>
					</DropdownOnBody>
				)}
			</>
		);
	}
}
