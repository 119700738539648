import { RespOrgIndexResponse } from 'RtModels';
import { RespOrgResource } from 'RtUi/app/rt800/RespOrgs/lib/resources/RespOrgResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface RespOrgDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		RespOrgIndexResponse,
		T
	> {}

export const RespOrgDataGridCache = new ResourceCacheMap<RespOrgIndexResponse>(
	new RespOrgResource(),
	'respOrgId',
	'label'
);

export const RespOrgDataGridColumn = <T = any,>(
	config: RespOrgDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: RespOrgDataGridCache,
		...config
	});
};

export const RespOrgColumnsBuilder = <T = any,>(
	config: RespOrgDataGridColumnConfiguration<T>
): Array<DataGridColumn<T>> => {
	return [
		DefaultDataGridColumn<T>({
			id: 'respOrgIdCol',
			accessorKey: config.accessorKey,
			header: 'RespOrg Id'
		}),
		ResourceDataGridColumn({
			resourceCacheMap: RespOrgDataGridCache,
			...config
		})
	];
};
