import { PeeringTypeIndexResponse } from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class PeeringTypeResource extends ArrayResource<PeeringTypeIndexResponse> {
	constructor() {
		super('peeringTypeId');

		this.setApiRouteForGetAll(RtCommonApiRoutes.PeeringTypes.Index);
	}
}
