/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $AuthorizationResponse = {
    properties: {
        rtxJwt: {
            type: 'string',
            isRequired: true,
        },
        userId: {
            type: 'number',
            isRequired: true,
        },
        partitionId: {
            type: 'number',
            isRequired: true,
        },
        partitionUserId: {
            type: 'number',
            isRequired: true,
        },
        fireToken: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        permissions: {
            type: 'Array',
            isRequired: true,
        },
        products: {
            type: 'Array',
            isRequired: true,
        },
        defaultEntityId: {
            type: 'string',
        },
        defaultRespOrgId: {
            type: 'string',
            isRequired: true,
        },
        tfaEnabled: {
            type: 'boolean',
            isRequired: true,
        },
        tfaValidated: {
            type: 'boolean',
            isRequired: true,
        },
    },
};