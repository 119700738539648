import { TermSummaryIndexResponseData } from 'RtModels';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PercentDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PercentDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDurationDataGridColumn';

export const getTermSummaryReportColumns = (): Array<
	DataGridColumn<TermSummaryIndexResponseData>
> => [
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date'
	}),
	DefaultDataGridColumn({
		accessorKey: 'dialcode',
		header: 'Dial Code',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'jurisdiction',
		header: 'Jurisdiction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ocn',
		header: 'OCN',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'accountId',
		header: 'Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'connects',
		header: 'Connects',
		hiddenIfEmpty: true
	}),
	CallDurationDataGridColumn({
		accessorKey: 'duration',
		header: 'Duration'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue',
		header: 'Revenue',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost',
		header: 'Cost',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'margin',
		header: 'Margin',
		hiddenIfEmpty: true
	}),
	PercentDataGridColumn({
		accessorKey: 'marginPercent',
		header: 'Margin Percent',
		decimals: 2,
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rpm',
		header: 'RPM',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cpm',
		header: 'CPM',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'mpm',
		header: 'MPM',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'rejectCount',
		header: 'Reject Count',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rejectDuration',
		header: 'Reject Duration',
		decimals: 4,
		hiddenIfEmpty: true
	})
];
