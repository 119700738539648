import { debounce } from 'lodash-es';

export class PromiseUtils {
	public delay(delayInMs: number) {
		return new Promise((resolve) => setTimeout(resolve, delayInMs));
	}
}

export function asyncDebounce<ArgumentsType extends unknown[], ReturnType>(
	func: (...args: ArgumentsType) => Promise<ReturnType>,
	wait: number
): (...args: ArgumentsType) => Promise<ReturnType> {
	const debounced = debounce((resolve, reject, args) => {
		func(...args)
			.then(resolve)
			.catch(reject);
	}, wait);
	return (...args: ArgumentsType) =>
		new Promise((resolve, reject) => {
			debounced(resolve, reject, args);
		});
}
