import { RocActionIndexResponse, RocActionItems } from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { cloneDeep, values } from 'lodash-es';

export class RocRequestHttp {
	private static RocActionsCache: Promise<RocActionIndexResponse> | undefined;

	public async getActions(): Promise<RocActionItems> {
		if (!RocRequestHttp.RocActionsCache) {
			RocRequestHttp.RocActionsCache =
				HttpRequest.fetchWithRoute<RocActionIndexResponse>(
					Rt800ApiRoutes.RocAction.Index
				);
		}

		const response = await RocRequestHttp.RocActionsCache;

		//Clone to remove Decline, Decline, Decline error
		return cloneDeep(response.actionItems);
	}

	public getRejectReasonActions = (
		actions: RocActionItems,
		rejectReasonIds: number[]
	) => {
		return (
			values(actions)
				//Get a list of actions with a rejectReasonId from args
				.filter(({ rejectReasonId = -1 }) =>
					rejectReasonIds.includes(rejectReasonId)
				)
		);
	};

	public getRejectReasonsToString = (
		actions: RocActionItems,
		rejectReasonIds: number[]
	) => {
		return (
			this.getRejectReasonActions(actions, rejectReasonIds)
				//Map those to readable string
				.map(
					(rejectRocAction) =>
						`${rejectRocAction.label} (${rejectRocAction.code})`
				)
				//Comma separated join
				.join(', ')
		);
	};

	public getSomosRejectReasonsToString = (
		actions: RocActionItems,
		rejectReasonIds: number[]
	) => {
		return (
			this.getRejectReasonActions(actions, rejectReasonIds)
				//Map those to readable string
				.map((rejectRocAction) => `${rejectRocAction.rejectLabel}`)
				//Comma separated join
				.join(', ')
		);
	};
}
