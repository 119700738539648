/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExportTypeIndexResponse = {
    properties: {
        exportTypeId: {
            type: 'number',
            isRequired: true,
        },
        exportTypeName: {
            type: 'string',
            isRequired: true,
        },
        exportTypeDescription: {
            type: 'string',
            isRequired: true,
        },
    },
};