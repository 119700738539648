import {
	GuardianRerateControlIndexResponse,
	RateControlRerateType,
	RerateQueryParameters
} from 'RtModels';
import { RerateTypeNameMap } from 'RtUi/app/rtVue/RerateControl/lib/utils/Constants';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { RerateControlQueryDataGridColumColumn } from 'RtUi/components/data/DataGrid/columns/RerateControlQueryDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { UserNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';

export const getRerateControlGridColumns = (): Array<
	DataGridColumn<GuardianRerateControlIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'jobId',
		header: 'Job Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	DateDataGridColumn({
		id: 'timeObject.start',
		accessorFn: (record) =>
			(record.queryParameters as RerateQueryParameters).timeRange.timeObject
				.start,
		header: 'Query Start'
	}),
	DateDataGridColumn({
		id: 'timeObject.end',
		accessorFn: (record) =>
			(record.queryParameters as RerateQueryParameters).timeRange.timeObject
				.end,
		header: 'Query End'
	}),
	RerateControlQueryDataGridColumColumn({
		accessorKey: 'queryParameters',
		header: 'Query Parameters'
	}),
	DefaultDataGridColumn({
		accessorKey: 'jobStatus',
		header: 'Job Status'
	}),
	DefaultDataGridColumn({
		accessorKey: 'jobType',
		header: 'Job Type'
	}),
	DefaultDataGridColumn({
		header: 'Rerate Type',
		accessorFn: (record) =>
			RerateTypeNameMap.get(record.rerateType as RateControlRerateType)
	}),
	HeaderDataGridColumn({
		header: 'Initial',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'initialCallCount',
				header: 'Call Count'
			}),
			IntegerDataGridColumn({
				accessorKey: 'initialMinutes',
				header: 'Minutes'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'initialRevenue',
				header: 'Revenue'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'initialCost',
				header: 'Cost',
				decimals: 4,
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Completed',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'completedCallCount',
				header: 'Call Count'
			}),
			IntegerDataGridColumn({
				accessorKey: 'completedMinutes',
				header: 'Minutes'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'completedRevenue',
				header: 'Revenue'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'completedCost',
				header: 'Cost',
				decimals: 4,
				hiddenIfEmpty: true
			}),
			UserNameDataGridColumn({
				accessorKey: 'createdBy',
				header: 'Created By'
			}),
			TimeStampDataGridColumn({
				accessorKey: 'createdTs',
				header: 'Created'
			}),
			TimeStampDataGridColumn({
				accessorKey: 'updatedTs',
				header: 'Last Updated'
			})
		]
	})
];
