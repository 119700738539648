import { createBrowserHistory } from 'history';
import { LogoutPath } from 'RtUi/app/user/lib/Constants';

export const BrowserHistory = createBrowserHistory();

export const pushPath = (
	path: string,
	searchParams?: URLSearchParams,
	callback?: () => void
) => {
	if (searchParams) {
		const concatChar = path.includes('?') ? '&' : '?';
		path += concatChar + searchParams.toString();
	}

	if (callback) {
		const removeListenFn = BrowserHistory.listen(() => {
			removeListenFn();
			callback();
		});
	}

	BrowserHistory.push(path);
};

export const logoutUser = () => {
	pushPath(LogoutPath);
};

export const createPath = (path: string, searchParams?: URLSearchParams) => {
	const url = new URL(`${window.location.origin}${path}`);
	searchParams?.forEach((value, key) => {
		url.searchParams.append(key, value);
	});

	return `${url.pathname}${url.search}`;
};
