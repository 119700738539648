/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtVueApiRoutes } from 'RtExports/routes';
import { GuardianSubscriptionCicIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchSubscriptionCIC = async (): Promise<
	FullResponse<GuardianSubscriptionCicIndexResponse[]>
> => {
	return handleGetRequest(RtVueApiRoutes.GuardianSubscriptionCic.Index, {
		includeFullResponse: true
	});
};

export const useGetSubscriptionCIC = () => {
	return useQuery<FullResponse<GuardianSubscriptionCicIndexResponse[]>, Error>(
		'getSubscriptionCIC',
		() => {
			return fetchSubscriptionCIC();
		}
	);
};
