import { FC, FormEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ReconciliationAttachmentsResource } from 'RtUi/app/rtVue/GuardianReconciliation/lib/resources/ReconciliationAttachmentsResource';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';

interface IReconciliationAttachmentsUploadFormProps {
	onSuccess?: () => void;
	reconciliationId: number;
}

export const ReconciliationAttachmentsUploadForm: FC<
	React.PropsWithChildren<IReconciliationAttachmentsUploadFormProps>
> = ({ onSuccess = () => ({}), reconciliationId }) => {
	const reconciliationAttachmentsResource =
		new ReconciliationAttachmentsResource();
	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		createMode: true,
		createNew: () =>
			reconciliationAttachmentsResource.createNewProfile(reconciliationId)
	});
	const [attachment, setAttachment] = useState<File>();
	const { workingCopy } = rtUiFormState;

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		if (!attachment) {
			return;
		}

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			await reconciliationAttachmentsResource.create(attachment, workingCopy);
			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setDisplayMode(true);
			onSuccess();
		} catch (err) {
			rtUiFormMethods.setError(err);
			rtUiFormMethods.setIsSubmitting(false);
		}
	};

	return (
		<RtUiNarrowForm
			{...rtUiFormState}
			onSubmit={onSubmit}
			onCancel={() => {
				setAttachment(undefined);
				rtUiFormMethods.onCancel();
			}}
		>
			<Form.Group className="mb-3">
				<Form.Label>Attachment</Form.Label>
				<DragAndDropFileUploader
					required
					onDrop={(attachmentDropped) => {
						setAttachment(attachmentDropped);
					}}
					value={attachment}
				/>
			</Form.Group>
		</RtUiNarrowForm>
	);
};
