import {
	MessagesDetailIndexResponse,
	Reports,
	TimeFilterDetailKeys
} from 'RtModels';
import { MessagesDetailRouter } from 'RtUi/app/rtVue/MessagesDetail/MessagesDetail.router';
import { MessagesDetailDataGrid } from 'RtUi/app/rtVue/MessagesDetail/lib/grids/MessagesDetailDataGrid';
import { TimeFilterDetailLevelsHours } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import * as React from 'react';

@MessagesDetailRouter.getIndexRtUiController({
	groupName: 'Messaging',
	groupId: 'VueMessaging',
	orderPriority: 2,
	groupOrderPriority: 30
})
export class MessagesDetailIndexContainer extends RtVueReportContainer<MessagesDetailIndexResponse> {
	public state: IRtVueContainerState<MessagesDetailIndexResponse> = {
		...this.getBaseState(),
		timeRangeDetailsLevels: TimeFilterDetailLevelsHours.filter(
			(time) => time.key === TimeFilterDetailKeys.Months
		),
		dataSums: []
	};

	protected reportId = Reports.MessagesDetail;
	protected router = MessagesDetailRouter;

	protected async getResource(): Promise<
		HttpResource<MessagesDetailIndexResponse>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		} = this.state;

		const indexes = await new RtVueHttp().getMessagesDetail(
			timeRange,
			filters,
			dataSums,
			preset,
			partition,
			additionalColumns?.map((column) => column.value)
		);

		const resource = new ArrayResource('vuid', indexes.data);
		resource.setTotalCount(indexes.totalCount);

		return resource;
	}

	protected renderReports(): React.ReactNode {
		return (
			<>
				<MessagesDetailDataGrid
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
					isLoading={this.state.loadingReport}
				/>
			</>
		);
	}
}
