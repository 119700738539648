/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocIndexResponse = {
    properties: {
        rocTxnId: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        requestingRespOrgId: {
            type: 'string',
            isRequired: true,
        },
        submittingRespOrgId: {
            type: 'string',
            isRequired: true,
        },
        isIncoming: {
            type: 'boolean',
            isRequired: true,
        },
        submittedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        dueTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        dueEndOfDay: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        firstTfn: {
            type: 'string',
            isRequired: true,
        },
        totalQty: {
            type: 'number',
            isRequired: true,
        },
        approvedQty: {
            type: 'number',
            isRequired: true,
        },
        declinedQty: {
            type: 'number',
            isRequired: true,
        },
        overdueQty: {
            type: 'number',
            isRequired: true,
        },
        expiredQty: {
            type: 'number',
            isRequired: true,
        },
        otherQty: {
            type: 'number',
            isRequired: true,
        },
        pendingQty: {
            type: 'number',
            isRequired: true,
        },
    },
};