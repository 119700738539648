/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TemplateLcrScenarioIndexResponse = {
    properties: {
        templateLcrScenarioId: {
            type: 'number',
            isRequired: true,
        },
        lcrScenarioId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        allowAsDefaultOnly: {
            type: 'number',
            isRequired: true,
        },
    },
};