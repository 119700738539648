import * as React from 'react';
import { Alert } from 'react-bootstrap';
import {
	LcrTaskProfileResponse,
	MinuteProfile,
	RespOrgIndexResponse,
	TemplateIndexResponse
} from 'RtModels';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import { LcrMinuteSelect } from 'RtUi/app/rtLco/Minutes/lib/controls/LcrMinutesSelect';
import { LcrMinutesResource } from 'RtUi/app/rtLco/Minutes/lib/resources/LcrMinutesResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { LcrTasksResource } from '../resources/LcrTasksResource';

interface ILcrTaskCreateProps {
	onCreate: (lcrTaskProfile: LcrTaskProfileResponse) => void;
}

interface ILcrTaskCreateState {
	respOrg?: RespOrgIndexResponse;
	template?: TemplateIndexResponse;
	allowAsDefaultOnly: number;
	minuteIndex?: MinuteProfile;
	skipSavingsLimit: number;
	cprSizeLimit: number;
	cprRowLimit: number;
	//form
	isSubmitting: boolean;
	taskLabel: string;
	error?: any;
}

export class LcrTaskCreate extends React.Component<
	ILcrTaskCreateProps,
	ILcrTaskCreateState
> {
	public state: ILcrTaskCreateState = {
		allowAsDefaultOnly: 0,
		skipSavingsLimit: 0,
		cprSizeLimit: 182000,
		cprRowLimit: 990,
		isSubmitting: false,
		error: undefined,
		taskLabel: ''
	};

	public submit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		const {
			respOrg,
			template,
			allowAsDefaultOnly,
			minuteIndex,
			skipSavingsLimit,
			cprSizeLimit,
			cprRowLimit,
			taskLabel
		} = this.state;

		//Should not be undefined -- form validation should require them
		if (!respOrg || !template || !minuteIndex) {
			return;
		}

		const lcrTasksResource = new LcrTasksResource();
		const { respOrgId } = respOrg;
		const { templateName } = template;
		const { minuteProfileId } = minuteIndex;

		this.setState({ isSubmitting: true, error: undefined });

		lcrTasksResource
			.create(
				respOrgId,
				templateName,
				allowAsDefaultOnly,
				minuteProfileId,
				skipSavingsLimit,
				cprSizeLimit,
				cprRowLimit,
				taskLabel
			)
			.then((lcrTaskProfile: LcrTaskProfileResponse) => {
				this.props.onCreate(lcrTaskProfile);
			})
			.catch((error) => this.setState({ error }))
			.finally(() => this.setState({ isSubmitting: false }));
	}

	public render() {
		return (
			<RtUiNarrowForm
				displayMode={false}
				createMode
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onSubmit={(e) => this.submit(e)}
			>
				<InputFormControl
					label="Label"
					onChange={(taskLabel) => {
						this.setState({ taskLabel });
					}}
					value={this.state.taskLabel}
				/>
				<RespOrgSelect
					required
					onChange={(respOrg: RespOrgIndexResponse) =>
						this.setState({ respOrg })
					}
					value={this.state.respOrg}
				/>
				<TemplateSelect
					required
					entityId={
						this.state.respOrg ? this.state.respOrg.entityId : undefined
					}
					onChange={(template: TemplateIndexResponse) =>
						this.setState({ template })
					}
					isRoutingTemplate={true}
					value={this.state.template}
				/>
				<LcrMinuteSelect
					onChange={(minuteIndex) => this.setState({ minuteIndex })}
					value={this.state.minuteIndex}
					resourceParams={{
						isComplete: 1
					}}
				/>
				{this.renderMinuteWarning()}
				<PrecisionFormControl
					label="Skip Savings Limit"
					required
					step={0.01}
					precision={2}
					onChange={(skipSavingsLimit) => {
						if (skipSavingsLimit !== null) {
							this.setState({ skipSavingsLimit });
						}
					}}
					value={this.state.skipSavingsLimit}
				/>
				<InputFormControl
					type="number"
					label="CPR Size Limit"
					max={200000}
					min={170000}
					step={1000}
					onChange={(cprSizeLimitStr) =>
						this.setState({ cprSizeLimit: Number(cprSizeLimitStr) })
					}
					value={String(this.state.cprSizeLimit)}
				/>
				<InputFormControl
					type="number"
					label="CPR Row Limit"
					subLabel="(Max 999)"
					max={999}
					min={500}
					step={1}
					onChange={(cprRowLimitStr) =>
						this.setState({ cprRowLimit: Number(cprRowLimitStr) })
					}
					value={String(this.state.cprRowLimit)}
				/>
				<RadioFormControl<number>
					label="Allow as Default Only"
					required
					onChange={(allowAsDefaultOnly) =>
						this.setState({ allowAsDefaultOnly })
					}
					value={this.state.allowAsDefaultOnly}
					options={[
						{ value: 0, label: 'No' },
						{ value: 1, label: 'Yes' }
					]}
				/>
			</RtUiNarrowForm>
		);
	}

	public renderMinuteWarning() {
		const { minuteIndex } = this.state;

		if (!minuteIndex) {
			return null;
		}

		if (!LcrMinutesResource.IsMismatchOrZeroMinutes(minuteIndex)) {
			return null;
		}

		return (
			<Alert variant="warning">
				<i className="fas fa-fw fa-exclamation-circle" />
				&nbsp;&nbsp;LCR Minute mismatch or zero minutes.
			</Alert>
		);
	}
}
