import { FourteenDayIndexResponse } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getFourteenDayColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/fourteenday';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface FourteenDayReportGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<FourteenDayIndexResponse> | null;
	isLoading?: boolean;
	callDateFormat?: string;
	onDrillDown?: (row: FourteenDayIndexResponse) => void;
	onClickRevenueColumn: (row: FourteenDayIndexResponse) => void;
	onClickCostColumn: (row: FourteenDayIndexResponse) => void;
	onClickMarginColumn: (row: FourteenDayIndexResponse) => void;
	onClickASRColumn: (row: FourteenDayIndexResponse) => void;
}

export const FourteenDayReportGrid = ({
	resource,
	isLoading,
	onClickRevenueColumn,
	onClickCostColumn,
	onClickMarginColumn,
	onClickASRColumn,
	callDateFormat,
	onDrillDown
}: FourteenDayReportGridProps) => {
	const { data } = useQuery<FourteenDayIndexResponse[] | undefined, Error>(
		[`useGetFourteenDays$`, resource],
		() => resource?.getAll()
	);

	const columns = useMemo(
		() =>
			getFourteenDayColumns(
				onClickRevenueColumn,
				onClickCostColumn,
				onClickMarginColumn,
				onClickASRColumn,
				callDateFormat,
				onDrillDown
			),
		[
			onClickRevenueColumn,
			onClickCostColumn,
			onClickMarginColumn,
			onClickASRColumn,
			callDateFormat,
			onDrillDown
		]
	);

	return (
		<DataGrid<FourteenDayIndexResponse>
			pageName="rtVue_fourteenday"
			data={data}
			loading={isLoading}
			columns={columns}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
