import { ResourceIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getResourceColumns = (): Array<
	DataGridColumn<ResourceIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'resourceId',
		header: 'ID'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'integrationLabel',
		header: 'Integration'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isActive',
		header: 'Active'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isDefault',
		header: 'Default'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastSyncTs',
		header: 'Last Sync'
	})
];
