/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileShareIndexResponse = {
    properties: {
        fileShareId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        userId: {
            type: 'number',
            isRequired: true,
        },
        localFileName: {
            type: 'string',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        fileSize: {
            type: 'string',
            isRequired: true,
        },
        emailAddress: {
            type: 'string',
            isRequired: true,
        },
        createdBy: {
            type: 'string',
            isRequired: true,
        },
        partition: {
            type: 'string',
            isRequired: true,
        },
        canDelete: {
            type: 'boolean',
            isRequired: true,
        },
    },
};