import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { FallbackHeaderDataGridColumnConfiguration } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import {
	PrecisionDataGridColumn,
	PrecisionDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';

type CallDurationDataGridColumnConfiguration<T> =
	PrecisionDataGridColumnConfiguration<T> &
		FallbackHeaderDataGridColumnConfiguration<T>;

export const CallDurationDataGridColumn = <T = any,>({
	header = 'Duration',
	decimals = 1,
	hiddenIfEmpty = true,
	...config
}: CallDurationDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return PrecisionDataGridColumn({
		header,
		decimals,
		hiddenIfEmpty,
		...config
	});
};
