/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CallSimulatorResponse = {
    properties: {
        srcCprKey: {
            type: 'string',
            isRequired: true,
        },
        srcCprTypeId: {
            type: 'number',
            isRequired: true,
        },
        srcCprEffectiveTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        srcCprStatusId: {
            type: 'number',
            isRequired: true,
        },
        srcApprovalIndicator: {
            type: 'string',
            isRequired: true,
        },
        tgtCprKey: {
            type: 'string',
            isRequired: true,
        },
        tgtCprTypeId: {
            type: 'number',
            isRequired: true,
        },
        tgtCprEffectiveTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        tgtCprStatusId: {
            type: 'number',
            isRequired: true,
        },
        tgtApprovalIndicator: {
            type: 'string',
            isRequired: true,
        },
        cprTimeline: {
            type: 'number',
            isRequired: true,
        },
        cic: {
            type: 'string',
        },
        percent: {
            type: 'number',
            isRequired: true,
        },
        cprRowIdx: {
            type: 'number',
        },
    },
};