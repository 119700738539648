import { useMemo } from 'react';
import { SwitchAddressIndexResponse } from 'RtModels';
import { useGetSwitchAddresses } from 'RtUi/app/AccountManagement/Switches/lib/services';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface ISwitchAddressesSelectProps {
	value?: SwitchAddressIndexResponse;
	onChange?: (value?: SwitchAddressIndexResponse) => void;
	switchId?: number;
	label?: string;
	required?: boolean;
	isClearable?: boolean;
	initialOptionId?: number;
	displayMode?: boolean;
}
export const SwitchAddressesSelect = ({
	value,
	onChange = () => {},
	switchId,
	label = 'Switch Types',
	required,
	isClearable = true,
	initialOptionId,
	displayMode = false
}: ISwitchAddressesSelectProps): JSX.Element => {
	const { data, isLoading } = useGetSwitchAddresses({ switchId });

	const selectData = useMemo(() => data?.data || [], [data]);
	const options: Array<ISimpleSelectFormControlOption<number>> = useMemo(() => {
		return selectData.map((switchAddress) => ({
			value: switchAddress.switchAddressId,
			label: switchAddress.label
		}));
	}, [selectData]);

	const selectValue = useMemo(() => {
		const currentValue = value?.switchAddressId ?? initialOptionId;
		return options.find((opt) => opt.value === currentValue);
	}, [value, initialOptionId, options]);

	const onChangeHandler = (selectedValue: number | undefined) => {
		const switchAddress = selectData?.find(
			(st) => st.switchAddressId === selectedValue
		);
		onChange(switchAddress);
	};

	return (
		<SimpleSelectFormControl<number>
			label={label}
			clearable={isClearable}
			isLoading={isLoading}
			required={required}
			value={selectValue}
			onChange={(val) => onChangeHandler(val?.value)}
			options={options}
			initialOptionId={initialOptionId?.toString()}
			displayMode={displayMode}
		/>
	);
};
