import { DataSources, OcnCarrierCommonNameIndexResponse } from 'RtModels';
import {
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { IndexedDbCacheStrategy } from 'RtUi/utils/cache/strategies/IndexedDbCacheStrategy';

/**
 * @singleton
 */
export class RtVueCarrierCommonNameFilter extends RtVueFilter<OcnCarrierCommonNameIndexResponse> {
	public static getInstance() {
		if (!RtVueCarrierCommonNameFilter.instance) {
			RtVueCarrierCommonNameFilter.instance =
				new RtVueCarrierCommonNameFilter();
		}

		return RtVueCarrierCommonNameFilter.instance;
	}

	private static instance: RtVueCarrierCommonNameFilter | undefined;
	private static readonly FilterName = 'RtVueCarrierCommonNameFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<OcnCarrierCommonNameIndexResponse>
	> = new IndexedDbCacheStrategy(
		RtVueCarrierCommonNameFilter.FilterName,
		'commonName',
		['*keywords' as keyof OcnCarrierCommonNameIndexResponse]
	);

	protected constructor() {
		super(
			RtVueCarrierCommonNameFilter.FilterName,
			DataSources.CarrierCommonName,
			RtVueCarrierCommonNameFilter.DataCache
		);
	}

	public getLabelFor(record: OcnCarrierCommonNameIndexResponse): string {
		return record.commonName;
	}

	public getValueFor(record: OcnCarrierCommonNameIndexResponse): string {
		return String(record.commonName);
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<OcnCarrierCommonNameIndexResponse>
	> {
		return this.vueHttp.getCarrierCommonNameIndexResponse();
	}

	protected getKeywordsFor(
		record: OcnCarrierCommonNameIndexResponse
	): string[] {
		const keywords = this.getKeywordsFromPhrase(record.commonName);

		keywords.push(String(record.commonName));

		return keywords;
	}
}
