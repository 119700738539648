import { RtVueApiRoutes } from 'RtExports/routes';
import {
	ReconciliationDetailsUrlRequest,
	ReconciliationDetailsIndexRequest,
	ReconciliationDetailsIndexResponse,
	ViewReconciliationUnmatchedCdrsProfileResponse,
	ViewCdrIndexRequest
} from 'RtModels';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';

export class ViewReconciliationHttp {
	public getDetails(
		reconciliationId: number,
		bodyParams: ReconciliationDetailsIndexRequest
	) {
		const body = JSON.stringify(bodyParams);
		const urlParams: ReconciliationDetailsUrlRequest = {
			reconciliationId: String(reconciliationId)
		};

		return HttpRequest.fetchWithRoute<ReconciliationDetailsIndexResponse[]>(
			RtVueApiRoutes.ViewReconciliation.Index,
			{
				urlParams,
				body
			}
		);
	}

	public getUnmatchedCdrs(
		reconciliationId: number,
		bodyParams: ViewCdrIndexRequest
	) {
		const body = JSON.stringify(bodyParams);
		const urlParams: ReconciliationDetailsUrlRequest = {
			reconciliationId: String(reconciliationId)
		};

		return HttpRequest.fetchWithRoute<ViewReconciliationUnmatchedCdrsProfileResponse>(
			RtVueApiRoutes.ViewReconciliationUnmatchedCdrs.Profile,
			{
				urlParams,
				body
			}
		);
	}
}
