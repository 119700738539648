import { AosLbl } from 'Somos/lib/SomosCpr/RtCprV2/AosLbl/AosLbl';
import { AosNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class AosLblState extends AosLbl {

	public readonly cprNodeTypeId = undefined;
	public readonly aosNodeTypeId = AosNodeType.State;

	public readonly valueLimit = 4;
	public readonly valueRegExp = /^[A-Z]{2}$/;

	protected readonly possibles = CprLergCache.States;
	protected readonly possiblesByKey = CprLergCache.CprLergByState;

	protected readonly canadaPossibles = CprLergCache.CanadaStates;
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaStatesByKey;

	protected readonly canLabel = true;

}
