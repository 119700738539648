import { FlowIndexRequest, FlowIndexResponse } from 'RtModels';
import { FlowRouter } from 'RtUi/app/rtAdmin/Flows/Flow.router';
import { useGetFlows } from 'RtUi/app/rtAdmin/Flows/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getFlowColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/flow';
import { useMemo } from 'react';

export interface IFlowGridProps {
	resourceParams?: FlowIndexRequest;
}

export const FlowGrid = ({ resourceParams }: IFlowGridProps) => {
	const { data, isLoading } = useGetFlows(resourceParams);
	const columns = useMemo(() => getFlowColumns(), []);

	return (
		<DataGrid<FlowIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'rtAdmin-flows'}
			router={FlowRouter}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
