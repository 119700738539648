/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipTraceProfileRequest = {
    properties: {
        gatewayId: {
            type: 'number',
            isRequired: true,
        },
        startTime: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTime: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        callId: {
            type: 'string',
            isRequired: true,
        },
    },
};