import pluralize from 'pluralize';
import { createRoot } from 'react-dom/client';
import { Permissions } from 'RtExports/routes';
import { UncontrolledAside } from 'RtUi/components/ui/Aside/Uncontrolled';
import { UserActions } from 'RtUi/state/actions/user';
import { MessageEventType } from 'RtModels';
import { clone, defaults, startCase } from 'lodash-es';
import { AppProviders } from 'RtUi/app/AppProviders';

export enum UiNotificationCategoryEnum {
	Rt800 = 'RT/800',
	RtSip = 'RT/SIP',
	RtAdm = 'Admin'
}

export interface IUiNotificationSettings {
	refreshTimeInMinutes: number;
	isPinned: boolean;
	isWarning: boolean;
	timeoutInMs: number;
	showNotificationOnlyOnInit: boolean;
}

export interface IUiNotificationState {
	issuesCount?: number;
	issuesWord: string;
	didFetchFail: boolean;
	isFetching: boolean;
}

export interface IUiListenerContext {
	source: string;
}
export type IUiNotificationListener = (
	settings: IUiNotificationSettings,
	state: IUiNotificationState,
	context?: IUiListenerContext
) => void;

export abstract class UiNotification {
	private issuesWord = 'issue';
	private issuesCount = NaN;

	private settings: IUiNotificationSettings = {
		isWarning: false,
		isPinned: true,
		refreshTimeInMinutes: 5,
		timeoutInMs: 5000,
		showNotificationOnlyOnInit: true
	};

	private state: IUiNotificationState = {
		didFetchFail: false,
		issuesWord: this.getIssueWord(0, true),
		isFetching: true
	};

	private UiNotificationListeners: IUiNotificationListener[] = [];
	private hasNotificationBeenShown = false;

	constructor(
		private notificationCategory: UiNotificationCategoryEnum,
		private permissions: Permissions[],
		private title: string,
		private eventType: MessageEventType,
		overrideSettings: Partial<IUiNotificationSettings> = {}
	) {
		this.settings = defaults(overrideSettings, this.settings);

		this.loadSettingsFromStorage();
	}

	public userHasAccessToNotification() {
		return UserActions.has(...this.permissions);
	}

	public getEventType() {
		return this.eventType;
	}

	public isPinned() {
		return this.settings.isPinned;
	}

	public getCategory() {
		return this.notificationCategory;
	}

	public getTitle() {
		return this.title;
	}

	public getIssueWord(count?: number, titleCase = false) {
		let issuesWordCopy = this.issuesWord;

		if (titleCase) {
			issuesWordCopy = startCase(issuesWordCopy);
		}

		return pluralize(issuesWordCopy, count);
	}

	public updateSetting<K extends keyof IUiNotificationSettings>(
		settingName: K,
		settingValue: IUiNotificationSettings[K],
		context?: IUiListenerContext
	) {
		this.settings[settingName] = settingValue;

		this.fireListeners(context);

		try {
			const storageKeyName = this.getStorageKeyName();
			const settingsForStorageStr = JSON.stringify(this.settings);

			localStorage.setItem(storageKeyName, settingsForStorageStr);
		} catch {
			/** We Tried */
		}
	}

	public onUpdate(listener: IUiNotificationListener, fireImmediately = true) {
		const [settingsCopy, stateCopy] = this.getCurrentSettingsAndState();

		this.UiNotificationListeners.push(listener);

		if (fireImmediately) {
			listener(settingsCopy, stateCopy);
		}

		return () => {
			const indexOf = this.UiNotificationListeners.indexOf(listener);

			if (indexOf >= 0) {
				this.UiNotificationListeners.splice(indexOf, 1);
			}
		};
	}

	public getCurrentSettingsAndState(): [
		IUiNotificationSettings,
		IUiNotificationState
	] {
		const settingsCopy = clone(this.settings);
		const stateCopy: IUiNotificationState = {
			...this.state
		};

		if (!isNaN(this.issuesCount)) {
			stateCopy.issuesWord = this.getIssueWord(this.issuesCount, true);
			stateCopy.issuesCount = this.issuesCount;
		} else {
			stateCopy.issuesWord = this.getIssueWord(0, true);
			stateCopy.issuesCount = undefined;
		}

		return [settingsCopy, stateCopy];
	}

	public openDetailedView() {
		const asideElement = this.getAsideElement();

		const detailedViewAside = (
			<UncontrolledAside disabledBody initOpen fullscreen autoClose>
				<AppProviders>{this.getDetailedView()}</AppProviders>
			</UncontrolledAside>
		);
		const root = createRoot(asideElement);
		root.render(detailedViewAside);
	}

	public primeIssueCount() {
		try {
			return this.refreshIssueCount();
		} catch {
			/* Attempt to prime count */
		}

		return Promise.resolve();
	}

	public abstract getIcon(): JSX.Element;
	protected abstract getDetailedView(): JSX.Element;
	protected abstract getIssueCount(): Promise<number>;

	protected getAsideElement() {
		const asideElementId = 'UiNotification-Aside';
		let uiAsideContainer = document.getElementById(asideElementId);

		if (uiAsideContainer) {
			document.body.removeChild(uiAsideContainer);
		}

		//Cleanup DOM
		const asideElements = document.querySelectorAll('.aside-right');

		Array.from(asideElements).forEach((asideElem) => {
			if (asideElem.parentNode) {
				asideElem.parentNode.removeChild(asideElem);
			}
		});

		uiAsideContainer = document.createElement('section');
		uiAsideContainer.id = asideElementId;

		document.body.appendChild(uiAsideContainer);

		return uiAsideContainer;
	}

	protected updateIssuesWord(issuesWord: string) {
		this.issuesWord = issuesWord;
	}

	protected fireListeners(context?: IUiListenerContext) {
		const [settingsCopy, stateCopy] = this.getCurrentSettingsAndState();

		for (const listener of this.UiNotificationListeners) {
			listener(settingsCopy, stateCopy, context);
		}
	}

	protected refreshIssueCount(): Promise<void> {
		const hasAccess = this.userHasAccessToNotification();

		this.state.isFetching = true;

		this.fireListeners();

		if (!hasAccess) {
			this.issuesCount = NaN;
			this.state.didFetchFail = false;

			this.fireListeners();

			return Promise.resolve();
		}

		return this.getIssueCount()
			.then((issuesCount) => {
				this.issuesCount = issuesCount;
				this.state.didFetchFail = false;

				if (
					this.hasNotificationBeenShown &&
					this.settings.showNotificationOnlyOnInit
				) {
					return;
				}
				// https://trello.com/c/aXbxcBiR/329-turn-off-flashy-stuff-when-you-login
				// ApplicationNotifications.openUiNotification(this, this.settings.timeoutInMs);
				this.hasNotificationBeenShown = true;
			})
			.catch(() => {
				this.issuesCount = NaN;
				this.state.didFetchFail = true;
			})
			.finally(() => {
				this.state.isFetching = false;

				this.fireListeners();

				const refreshTimeInMs = this.settings.refreshTimeInMinutes * 60 * 1000;

				setTimeout(() => this.refreshIssueCount(), refreshTimeInMs);
			});
	}

	private getStorageKeyName() {
		const className = this.constructor.name;
		const title = this.getTitle();
		return `${className}-${title}-settings`;
	}

	private loadSettingsFromStorage() {
		const storageKeyName = this.getStorageKeyName();
		const settingsFromStorageStr = localStorage.getItem(storageKeyName);

		if (settingsFromStorageStr !== null) {
			try {
				const settingsFromStorage: IUiNotificationSettings = JSON.parse(
					settingsFromStorageStr
				);

				if (
					typeof settingsFromStorage === 'object' &&
					settingsFromStorage !== null
				) {
					this.settings = defaults(settingsFromStorage, this.settings);
				}
			} catch {
				/* well we tried */
			}
		}
	}
}
