/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TollFreeQueryResponse = {
    properties: {
        tfn: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
        },
        tfnStatusId: {
            type: 'string',
        },
        respOrgName: {
            type: 'string',
        },
        contactName: {
            type: 'string',
        },
        contactNumber: {
            type: 'string',
        },
        lastStatusTs: {
            type: 'Date',
            format: 'date-time',
        },
    },
};