import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
	ScenarioActionForm,
	IScenarioActionFormState
} from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionForm';
import { ToPhoneNumberFormControl } from 'RtUi/app/rtSip/Scenarios/lib/controls/ToPhoneNumberFormControl';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { ScenarioActionDial, SubscriptionIndexResponse } from 'RtModels';

export class ScenarioActionDialForm extends ScenarioActionForm<ScenarioActionDial> {
	public state: IScenarioActionFormState = {};

	public updateSubscription(subscription: SubscriptionIndexResponse) {
		const { action } = this.props;

		action.subscriptionId = subscription.subscriptionId;

		this.setState({ subscription });
		this.props.onChange(action);
	}

	public updateToPhoneNumber(toPhoneNumber: string) {
		const { action } = this.props;

		action.toPhoneNumber = toPhoneNumber;

		this.props.onChange(action);
	}

	public render(): React.ReactNode {
		const { action } = this.props;

		return (
			<Row>
				<Col md>
					<SubscriptionSelect
						label="Subscription"
						required
						appendDropdownToBody
						serviceIds={this.serviceIds}
						displayMode={this.props.displayMode}
						onChange={(subscription: SubscriptionIndexResponse) =>
							this.updateSubscription(subscription)
						}
						value={this.state.subscription}
						initialOptionId={action.subscriptionId}
					/>
				</Col>
				<Col md>
					<ToPhoneNumberFormControl
						required
						displayMode={this.props.displayMode}
						onChange={(toPhoneNumber) =>
							this.updateToPhoneNumber(toPhoneNumber)
						}
						value={action.toPhoneNumber}
					/>
				</Col>
			</Row>
		);
	}
}
