/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianFraudAlertRecipientIndexRequest = {
    properties: {
        guardianAlertId: {
            type: 'number',
        },
        guardianAlertTypes: {
            type: 'Array',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};