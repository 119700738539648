import { Rt800ApiRoutes } from 'RtExports/routes';
import { TemplateIndexResponse } from 'RtModels';
import { NumberQuickViewAside } from 'RtUi/app/rt800/Numbers/lib/components/NumberQuickViewAside';
import { NumberResource } from 'RtUi/app/rt800/Numbers/lib/resources/NumberResource';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import {
	ADVANCED_FILTER,
	ITollFreeSearchTFNResponse,
	TollFreeSearchResource
} from 'RtUi/app/rt800/SearchAndReserve/lib/resources/TollFreeSearchResource';
import { TaskAction } from 'RtUi/app/rt800/Tasks/lib/components/TaskAction';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { TaskPollPromiseWrapper } from 'RtUi/app/rt800/Tasks/lib/utils/TaskPollPromiseWrapper';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getSearchAndReservedColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/searchAndReserve';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { UserActions } from 'RtUi/state/actions/user';
import { RtError } from 'RtUi/utils/errors/RtError';
import { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Item, Submenu } from 'react-contexify';
import { useQuery } from 'react-query';

export interface ITollfreeFilter {
	uuid: string;
	searchedNumber: string;
	advancedFilters: ADVANCED_FILTER[];
	searchInput: string;
	searchValue: string;
}

export interface ITollFreeReserveStatus {
	isSubmitting: boolean;
	isSuccess: boolean;
	error?: any;
}

export interface ITollFreeReserveActivated {
	[tfn: string]: ITollFreeReserveStatus;
}

interface ISearchAndReserveFiltersGridProps {
	filter: ITollfreeFilter;
	reservedTemplates: TemplateIndexResponse[];
}

export const SearchAndReserveFiltersGrid = ({
	filter,
	reservedTemplates
}: ISearchAndReserveFiltersGridProps) => {
	const [quickViewAsideIsOpen, setQuickViewAsideIsOpen] =
		useState<boolean>(false);
	const [selectedRecord, setSelectedRecord] =
		useState<ITollFreeSearchTFNResponse>();
	const [taskAsideIsOpen, setTaskAsideIsOpen] = useState<boolean>(false);
	const [reserved, setReserved] = useState<ITollFreeReserveActivated>({});
	const [activated, setActivated] = useState<ITollFreeReserveActivated>({});
	const [taskPollWrappers, setTaskPollWrappers] = useState<
		TaskPollPromiseWrapper[]
	>([]);
	const usersDefaultRespOrg = UserActions.getDefaultRespOrgId();

	const { data, isLoading } = useQuery<ITollFreeSearchTFNResponse[], Error>(
		[`getSearchAndReserveFiltersGrid`, filter],
		() =>
			TollFreeSearchResource.search(
				filter.searchValue,
				filter.advancedFilters
			).getAll()
	);

	const columns = useMemo(
		() => getSearchAndReservedColumns(reserved, activated),
		[reserved, activated]
	);

	const setReservedInfoFor = (
		tfn: string,
		isSubmitting: boolean,
		isSuccess: boolean,
		error?: RtError
	) => {
		setReserved((currentState) => {
			const newState = { ...currentState };

			newState[tfn] = { isSubmitting, isSuccess, error };

			return newState;
		});
	};

	const addTaskPollWrapper = (taskPollWrapper: TaskPollPromiseWrapper) => {
		setTaskPollWrappers((currentState) => {
			const newState = [...currentState];
			newState.push(taskPollWrapper);

			return newState;
		});

		return () => {
			const indexOf = taskPollWrappers.indexOf(taskPollWrapper);

			if (indexOf >= 0) {
				setTaskPollWrappers((currentState) => {
					const newState = [...currentState];
					newState.splice(indexOf, 1);

					return newState;
				});
			}
		};
	};

	const setActivatedInfoFor = (
		tfn: string,
		isSubmitting: boolean,
		isSuccess: boolean,
		error?: RtError
	) => {
		setActivated((currentState) => {
			const newState = { ...currentState };

			newState[tfn] = { isSubmitting, isSuccess, error };

			return newState;
		});
	};

	const activate = async (
		record: ITollFreeSearchTFNResponse,
		templateName: string
	) => {
		const numberResource = new NumberResource();
		const { tfn } = record;

		setActivatedInfoFor(tfn, true, false);

		try {
			const activateTask = await numberResource.activate(tfn, templateName);
			const taskPollWrapper = new TaskPollPromiseWrapper(activateTask.taskId);
			const removeTaskPollWrapper = addTaskPollWrapper(taskPollWrapper);

			const task = await taskPollWrapper.getPromise();

			if (task?.errorQty && task.errorQty > 0) {
				throw new RtError({ message: 'Task has errors' });
			}

			removeTaskPollWrapper();

			setActivatedInfoFor(tfn, false, true);
		} catch (error: any) {
			setActivatedInfoFor(tfn, false, false, error);
		}
	};

	const reserveWithTask = async (
		record: ITollFreeSearchTFNResponse,
		reserveTaskDefer: Promise<ITaskProfile>,
		templateName?: string
	) => {
		const { tfn } = record;

		setReservedInfoFor(tfn, true, false);
		setTaskAsideIsOpen(false);

		try {
			const reserveTask = await reserveTaskDefer;
			const taskPollWrapper = new TaskPollPromiseWrapper(reserveTask.taskId);
			const removeTaskPollWrapper = addTaskPollWrapper(taskPollWrapper);

			const task = await taskPollWrapper.getPromise();

			if (task?.errorQty && task.errorQty > 0) {
				throw new RtError({ message: 'Task has errors' });
			}

			removeTaskPollWrapper();

			setReservedInfoFor(tfn, false, true);

			if (templateName) {
				activate(record, templateName);
			}
		} catch (error: any) {
			setReservedInfoFor(tfn, false, false, error);
		}
	};

	const reserve = async (
		record: ITollFreeSearchTFNResponse,
		templateName?: string
	) => {
		const numberResource = new NumberResource();
		const { tfn } = record;
		const tfnReadable = TollFreeNumbersUtils.tollFreeNumberToReadableString(
			record.tfn
		);

		const confirmation = await Confirmation.create(
			<span>
				You are about to reserve the number
				<span className="text-muted" style={{ whiteSpace: 'nowrap' }}>
					&nbsp;{tfnReadable}
				</span>
				{templateName && (
					<span>
						&nbsp;and activate on{' '}
						<span className="text-muted">{templateName}</span>
					</span>
				)}
				. Would you like to continue?
			</span>
		);

		if (!confirmation) {
			return;
		}

		setReservedInfoFor(tfn, true, false);

		try {
			const reserveTaskDefer = numberResource.reserve(tfn);

			reserveWithTask(record, reserveTaskDefer, templateName);
		} catch (error: any) {
			setReservedInfoFor(tfn, false, false, error as RtError);
		}
	};

	return (
		<>
			<Card>
				<Card.Header>
					<b>
						<p className="mb-0">
							Search Pattern:&nbsp;{filter.searchInput}&nbsp;(
							{filter.searchedNumber})
						</p>
						{Boolean(filter.advancedFilters.length) && (
							<p className="mt-1 mb-0">
								Advanced Filters:&nbsp;
								{filter.advancedFilters.join(', ')}
							</p>
						)}
					</b>
				</Card.Header>
				<Card.Body>
					<DataGrid<ITollFreeSearchTFNResponse>
						data={data}
						columns={columns}
						loading={isLoading}
						pageName={'rt800_searchAndReserveFilters'}
						disableExport
						rowThemeColor={(record) =>
							record.isSuggestion ? RowThemeColor.WARNING : undefined
						}
						menuLinks={(row) => {
							const canViewProfile = UserActions.has(
								...Rt800ApiRoutes.TollFrees.Profile.permissions
							);
							if (!canViewProfile) {
								return null;
							}

							return (
								<>
									{canViewProfile && (
										<Item
											onClick={() => {
												setSelectedRecord(row);
												setQuickViewAsideIsOpen(true);
											}}
										>
											<span className="d-flex justify-content-start align-items-center">
												<i className="fas fa-fw fa-bolt" />
												<span>
													<span>Quick Toll-Free Number View</span>
												</span>
											</span>
										</Item>
									)}
									<Item onClick={() => reserve(row)}>
										<span className="d-flex justify-content-start align-items-center">
											<i className="fas fa-fw fa-play-circle" />
											<span>
												<span>Reserve Only</span>
												<span className="text-muted">
													&nbsp;(
													{usersDefaultRespOrg})
												</span>
											</span>
										</span>
									</Item>
									<Submenu
										arrow=""
										disabled={reservedTemplates.length < 0}
										label={
											<span className="d-flex justify-content-start align-items-center">
												<i className="fas fa-fw fa-play-circle" />
												<span>
													<span>Reserve + Activate</span>
													<span className="text-muted">
														&nbsp;(
														{usersDefaultRespOrg})
													</span>
												</span>
											</span>
										}
									>
										{reservedTemplates.map((reserveTemplate) => (
											<Item
												key={reserveTemplate.templateName}
												onClick={() =>
													reserve(row, reserveTemplate.templateName)
												}
											>
												<span className="d-flex justify-content-start align-items-center">
													<i className="fas fa-fw fa-play-circle" />
													<span>Template: {reserveTemplate.templateName}</span>
												</span>
											</Item>
										))}
									</Submenu>
									<Item
										onClick={() => {
											setSelectedRecord(row);
											setTaskAsideIsOpen(true);
										}}
									>
										<span className="d-flex justify-content-start align-items-center">
											<i className="fas fa-fw fa-play-circle" />
											<span>Create Reserve Task</span>
										</span>
									</Item>
								</>
							);
						}}
					/>
				</Card.Body>
			</Card>
			<Aside isOpen={taskAsideIsOpen}>
				{selectedRecord && (
					<>
						<legend>
							Reserve Task for{' '}
							{TollFreeNumbersUtils.tollFreeNumberToReadableString(
								selectedRecord.matchedTfn
							)}
						</legend>
						<TaskAction
							type="Reserve (By TFN List)"
							key={selectedRecord.matchedTfn}
							disablePadding
							singleNumberMode
							disablePathToTaskOnSuccess
							onCancel={() => {
								setTaskAsideIsOpen(false);
								setSelectedRecord(undefined);
							}}
							onSubmit={(taskDefer) =>
								reserveWithTask(selectedRecord, taskDefer)
							}
							defaultTollFreeNumbers={[selectedRecord.tfn]}
						/>
					</>
				)}
			</Aside>
			{selectedRecord && quickViewAsideIsOpen && (
				<NumberQuickViewAside
					key={selectedRecord.tfn}
					record={selectedRecord}
					isOpen={quickViewAsideIsOpen}
					toggle={setQuickViewAsideIsOpen}
				/>
			)}
		</>
	);
};
