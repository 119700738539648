/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $AccountProfileResponse = {
    properties: {
        accountId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        companyName: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        billingEntityId: {
            type: 'number',
            isRequired: true,
        },
        timezoneId: {
            type: 'number',
            isRequired: true,
        },
        mediaServerGroupId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rateAmendmentTypeId: {
            type: 'RateAmendmentType',
        },
        rateAmendmentSubjectAtoZ: {
            type: 'string',
            isRequired: true,
        },
        rateAmendmentSubjectCountry: {
            type: 'string',
            isRequired: true,
        },
        salesAgent: {
            type: 'string',
            isRequired: true,
        },
        salesAgentId: {
            type: 'number',
            isRequired: true,
        },
        serviceAgent: {
            type: 'string',
            isRequired: true,
        },
        serviceAgentId: {
            type: 'number',
            isRequired: true,
        },
        customerBalance: {
            type: 'number',
            isRequired: true,
        },
        vendorBalance: {
            type: 'number',
            isRequired: true,
        },
        combinedBalance: {
            type: 'number',
            isRequired: true,
        },
    },
};