/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationChargeDetailUpdateRequest = {
    properties: {
        reconciliationId: {
            type: 'number',
        },
        invoiceItemId: {
            type: 'string',
        },
        internalItemId: {
            type: 'string',
            isNullable: true,
        },
        internalItemLabel: {
            type: 'string',
            isNullable: true,
        },
        recurring: {
            type: 'number',
        },
        carrierInvoiceCharge: {
            type: 'number',
            isNullable: true,
        },
        expectedCharge: {
            type: 'number',
            isNullable: true,
        },
        carrierInvoiceChargeDetail: {
            type: 'number',
            isNullable: true,
        },
        rtCharge: {
            type: 'number',
            isNullable: true,
        },
        disputedAmount: {
            type: 'number',
            isNullable: true,
        },
        approvedAmount: {
            type: 'number',
            isNullable: true,
        },
        disputeStatus: {
            type: 'GuardianReconciliationStatus',
            isNullable: true,
        },
        disputeId: {
            type: 'string',
            isNullable: true,
        },
        disputeFiledTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        disputeSettlementTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        assignedTo: {
            type: 'number',
            isNullable: true,
        },
        systemGenerated: {
            type: 'number',
        },
        disputeReceivedTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        escalationTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
    },
};