import { cloneDeep } from 'lodash-es';
import { useEffect } from 'react';
import { IRtUiFormProps } from 'RtUi/components/ui/RtUiForm/RtUiForm';
import {
	useMethodsTyped,
	WrappedMethods
} from 'RtUi/utils/hooks/useMethodsTyped';

type IRtUiFormPropsWithoutBehavior = Omit<
	IRtUiFormProps,
	'onSubmit' | 'onChange'
>;

interface UseRtUiFormInit<T> extends IRtUiFormPropsWithoutBehavior {
	createNew: () => T;
	editMode?: T;
	workingCopy?: T;
}

interface UseRtUiFormState<T> extends UseRtUiFormInit<T> {
	workingCopy: T;
}

function getRtUiFormMethods<T>() {
	return (init: UseRtUiFormState<T>) => ({
		setIsSubmitting(isSubmitting: boolean) {
			return { ...init, isSubmitting };
		},
		setError(error: any | undefined) {
			return { ...init, error };
		},
		setWorkingCopy(partial: Partial<T>) {
			const newWorkingCopy: T = {
				...init.workingCopy,
				...partial
			};

			return { ...init, workingCopy: newWorkingCopy };
		},
		replaceWorkingCopy(newCopy: T) {
			return { ...init, workingCopy: newCopy, editMode: newCopy };
		},
		setDisplayMode(displayMode: boolean) {
			return { ...init, displayMode };
		},
		updateCache(editMode: T) {
			return { ...init, editMode };
		},
		onCancel() {
			const workingCopy = init.editMode
				? cloneDeep(init.editMode)
				: init.createNew();

			return { ...init, workingCopy, displayMode: true };
		}
	});
}

export function useRtUiFormEditor<EditorType>(
	init: UseRtUiFormInit<EditorType>
) {
	let workingCopy = init.workingCopy;

	if (!workingCopy) {
		workingCopy = init.editMode ? cloneDeep(init.editMode) : init.createNew();
	}

	const state: UseRtUiFormState<EditorType> = { ...init, workingCopy };

	if (typeof init.displayMode === 'boolean') {
		state.displayMode = init.displayMode;
	} else if (init.createMode) {
		state.displayMode = false;
	} else {
		state.displayMode = true;
	}

	const methods = getRtUiFormMethods<EditorType>();

	type UseMethodsEditorReturnType = [
		UseRtUiFormState<EditorType>,
		//Types make sure updateProperty generics work
		WrappedMethods<ReturnType<typeof methods>>
	];

	const [editorState, editorMethods] = useMethodsTyped(
		methods,
		state
	) as UseMethodsEditorReturnType;

	useEffect(() => {
		if (init.editMode) {
			editorMethods.updateCache(init.editMode);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [init.editMode]);

	return [editorState, editorMethods] as UseMethodsEditorReturnType;
}
