import clsx from 'clsx';
import * as React from 'react';
import { RtUiFormContext } from 'RtUi/components/ui/RtUiForm/RtUiFormContext';
import { ControlGroup } from '../../ui/ControlGroup';
import { IRadioButtonOptions, RadioButtons } from '../../ui/RadioButtons';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from '../FormControl';

interface IRadioFormControlProps<T> extends IFormControlProps<T, false> {
	options: Array<IRadioButtonOptions<T>>;
	label: string;
	btnType?: 'radio' | 'form';
	vertical?: boolean;
	initialValue?: T;
	highlighted?: boolean;
}

interface IRadioFormControlState extends IFormControlState {}

export class RadioFormControl<T = string> extends FormControl<
	T,
	false,
	IRadioFormControlProps<T>,
	IRadioFormControlState
> {
	public static contextType = RtUiFormContext;
	public declare context: React.ContextType<typeof RtUiFormContext>;

	public resource = undefined;
	public state: IRadioFormControlState = {
		formLabel: ''
	};

	public componentDidMount() {
		const formLabel = this.props.label;

		this.setState({ formLabel });

		super.componentDidMount();
	}

	public render() {
		const {
			value,
			highlighted,
			onChange = () => ({}),
			displayMode
		} = this.props;
		const { formLabel } = this.state;
		const currentOption = this.props.options.find((o) => o.value === value);
		let displayValue: React.ReactNode = String(value);

		let className = this.props.className;
		let labelClassName = '';

		if (highlighted) {
			className = clsx(className, 'border border-danger rounded-1');
			labelClassName = 'text-danger';
		}

		if (currentOption && currentOption.label) {
			displayValue = currentOption.label;
		}

		const radioButtonsElement = (
			<RadioButtons
				vertical={this.props.vertical}
				initialValue={this.props.initialValue}
				btnType={this.props.btnType}
				required={this.props.required}
				name={this.props.name}
				options={this.props.options}
				value={value}
				disabled={this.props.disabled}
				onChange={onChange}
				id={this.props.id}
			/>
		);

		return (
			<ControlGroup
				className={className}
				hideLabel={this.props.hideLabel}
				hideFormGroup={this.props.hideFormGroup}
				label={formLabel}
				labelClassName={labelClassName}
				value={displayValue}
				displayMode={displayMode}
				required={this.props.required}
			>
				{radioButtonsElement}
			</ControlGroup>
		);
	}
}
