import DataGrid from 'RtUi/components/data/DataGrid';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { useMemo } from 'react';

interface IWarningErrorListDataGridProps {
	pageName: string;
	data?: any[];
}

export const WarningErrorListDataGrid = ({
	pageName,
	data
}: IWarningErrorListDataGridProps): JSX.Element => {
	const columns = useMemo(
		() => [
			DefaultDataGridColumn({
				accessorKey: 'errMsg',
				header: 'Message'
			}),
			DefaultDataGridColumn({
				accessorKey: 'errCode',
				header: 'Code'
			}),
			DefaultDataGridColumn({
				accessorKey: 'errLvl',
				header: 'Level'
			})
		],
		[]
	);

	return (
		<DataGrid data={data} pageName={pageName} disableExport columns={columns} />
	);
};
