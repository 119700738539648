import { RoboscoreParameterIndexResponse } from 'RtModels';
import { RoboscoringRouter } from 'RtUi/app/rtVue/Roboscoring/Roboscoring.router';
import { useGetRoboscoreConfigurations } from 'RtUi/app/rtVue/Roboscoring/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRoboscoreConfigurationColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/roboscoreConfiguration';
import { useMemo } from 'react';

interface IRoboscoringGridProps {
	autoFocusFilter?: boolean;
}

export const RoboscoringGrid = ({}: IRoboscoringGridProps) => {
	const { data, isLoading } = useGetRoboscoreConfigurations();

	const columns = useMemo(() => getRoboscoreConfigurationColumns(), []);

	return (
		<DataGrid<RoboscoreParameterIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'configureRateSheet-roboscoreConfigurations'}
			router={RoboscoringRouter}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
