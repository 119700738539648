import {
	DataFilter,
	DataFilterOperator,
	DataSources,
	ReportAggregates,
	Reports,
	TollfreeEnhancedSummaryIndexResponse
} from 'RtModels';
import { RtVueReportContainer } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { TfEnhancedTotalsLineChart } from 'RtUi/app/rtVue/TfEnhancedReport/lib/charts/TfEnhancedTotalsLineChart';
import { TfEnhancedReportGrid } from 'RtUi/app/rtVue/TfEnhancedReport/lib/grids/TfEnhancedReportGrid';
import { TfEnhancedReportRouter } from 'RtUi/app/rtVue/TfEnhancedReport/TfEnhancedReport.router';
import { TfEnhancedDetailsReportRouter } from 'RtUi/app/rtVue/TfEnhancedDetailsReport/TfEnhancedDetailsReport.router';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { createFilterValue } from 'RtUi/app/rtVue/RejectSuspenseReport/grids/RejectDetailsRevenueCostGrid';
import moment from 'moment';

@TfEnhancedReportRouter.getIndexRtUiController({
	groupName: 'Toll-Free Enhanced',
	groupId: 'VueTollFreeEnhanced',
	groupOrderPriority: 50
})
export class TfEnhancedReportIndexContainer extends RtVueReportContainer<TollfreeEnhancedSummaryIndexResponse> {
	public state = {
		...this.getBaseState(),
		dataSums: [ReportAggregates.Asr]
	};

	protected reportId = Reports.TollfreeEnhanced;
	protected router = TfEnhancedReportRouter;

	protected async getResource(): Promise<
		HttpResource<TollfreeEnhancedSummaryIndexResponse>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns,
			havingFilter
		} = this.state;

		const vueHttp = new RtVueHttp();
		const { data, totalCount } = await vueHttp.getTfEnhancedSummaryView(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			additionalColumns?.map((column) => column.value),
			havingFilter
		);
		const resource = new ArrayResource<TollfreeEnhancedSummaryIndexResponse>(
			'calculatedTemplate',
			data
		);
		resource.setTotalCount(totalCount);

		return resource;
	}

	protected onDrillDown = (row: TollfreeEnhancedSummaryIndexResponse) => {
		const filters: DataFilter[] = [];
		const params: any = {
			...Object.fromEntries(this.urlSearchParams.getEntries()),
			date: moment(row.callDate).toISOString(),
			template: row.calculatedTemplate
		};

		if (row.calculatedCic) {
			filters.push(
				createFilterValue(
					'calculatedCic',
					row.calculatedCic.valueOf().toString()
				)
			);
		}

		if (row.ingressTrunkGroup) {
			filters.push(
				createFilterValue(
					'ingressTrunkGroup',
					row.ingressTrunkGroup.valueOf().toString()
				)
			);
		}

		if (row.calculatedLossCount) {
			filters.push(
				createFilterValue(
					'calculatedLossCount',
					row.calculatedLossCount.valueOf().toString(),
					DataFilterOperator.Begins,
					DataSources.Boolean
				)
			);
		}

		this.goToPath(
			TfEnhancedDetailsReportRouter.getDrillDownPath(params, filters)
		);
	};

	protected renderReports(): React.ReactNode {
		return (
			<>
				<TfEnhancedTotalsLineChart resource={this.state.resource} />
				<TfEnhancedReportGrid
					isLoading={this.state.loadingReport}
					timeRange={this.state.timeRange}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					onDrillDown={this.onDrillDown}
					resource={this.state.resource}
				/>
			</>
		);
	}
}
