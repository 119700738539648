import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianRatingSimulatorResponse,
	GuardianRatingSimulatorProfileRequest
} from 'RtModels';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';

class RatingSimulatorRouterClass extends RtVueModuleRouter<
	GuardianRatingSimulatorResponse,
	GuardianRatingSimulatorProfileRequest,
	{}
> {
	constructor() {
		super('Rating Simulator', 'ratingSimulator', 'cdr', {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.GuardianRatingSimulator);
	}

	public getProfileLabel(profile: GuardianRatingSimulatorProfileRequest) {
		return '';
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}
}

export const RatingSimulatorRouter = new RatingSimulatorRouterClass();
