import { defaults, padStart } from 'lodash-es';
import { LcrCarrierRateDto, LcrRateSheetType } from 'RtModels';
import { CarrierRatesLataOcnParser } from 'RtUi/app/rtLco/Carriers/lib/bin/CarrierRatesLataOcnParser';
import { CarrierRatesNpaNxxParser } from 'RtUi/app/rtLco/Carriers/lib/bin/CarrierRatesNpaNxxParser';
import {
	CarrierRatesParser,
	ICarrierRateParserClass
} from 'RtUi/app/rtLco/Carriers/lib/bin/CarrierRatesParser';
import { ICarrierRatesParseConfig } from 'RtUi/app/rtLco/Carriers/lib/bin/interfaces';
import { FindNonUniqueEntries } from 'RtUi/utils/array';
import { SpreadsheetParser } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParser';
import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';

export interface ILcrCarrierRateDtoExtended extends LcrCarrierRateDto {
	summary: string;
}

export type ValidateFnReturnType = true | string[];

export type CarrierRateSheetNonTerminalErrorTypes =
	| 'OcnNotFilled'
	| 'NoIntraFound'
	| 'NoIndetFound'
	| 'OneRatePerRateSummary';
export type CarrierRateSheetTerminalErrorTypes =
	| 'DuplicateKeys'
	| 'MalformedIntraRate'
	| 'NoRateFound'
	| 'NoRateSheetTypeFound';

export interface ICarrierRatesSheetDryRunReport {
	nonTerminalErrors: {
		[key in CarrierRateSheetNonTerminalErrorTypes]?: string[];
	};
	terminalErrors: {
		[key in CarrierRateSheetTerminalErrorTypes]?: string[];
	};
}

export class CarrierRatesSpreadsheetParser extends SpreadsheetParser {
	public static readonly MIN_NPA = 201;
	public static readonly MAX_NPA = 999;

	public static GetDryRunReport(spreadsheet: string[][]) {
		const maxTerminalErrors = 5;
		let totalTerminalErrors = 0;
		const dryRunConfig = defaults(
			{},
			{ ...CarrierRatesSpreadsheetParser.DryRunCarrierRatesParseConfigDefault }
		);
		const dryRunParser = new CarrierRatesSpreadsheetParser(
			spreadsheet,
			dryRunConfig
		);
		const unableToStartDryRunError = ['Rate Sheet type was not found'];
		const dryRunReport: ICarrierRatesSheetDryRunReport = {
			nonTerminalErrors: {},
			terminalErrors: {}
		};

		//If no error channel, errors are thrown
		dryRunParser.on('error', () => ({}));

		dryRunParser.assertRateSheetType();

		const pushNonTerminalErrorFilled = (
			type: CarrierRateSheetNonTerminalErrorTypes,
			message: string
		) => {
			if (typeof dryRunReport.nonTerminalErrors[type] !== 'undefined') {
				//@ts-ignore
				dryRunReport.nonTerminalErrors[type].push(message);
			} else {
				dryRunReport.nonTerminalErrors[type] = [message];
			}
		};

		const pushTerminalErrorFilled = (
			type: CarrierRateSheetTerminalErrorTypes,
			message: string
		): boolean => {
			totalTerminalErrors++;

			if (typeof dryRunReport.terminalErrors[type] !== 'undefined') {
				//@ts-ignore
				dryRunReport.terminalErrors[type].push(message);
			} else {
				dryRunReport.terminalErrors[type] = [message];
			}

			return totalTerminalErrors >= maxTerminalErrors;
		};

		if (!dryRunParser.matchedCarrierRatesParser) {
			dryRunReport.terminalErrors.NoRateSheetTypeFound =
				unableToStartDryRunError;
			return dryRunReport;
		}

		const matchedCarrierRatesParser = dryRunParser.matchedCarrierRatesParser;
		const headerMatch = matchedCarrierRatesParser.getHeaderMatch();
		const rateSheetType = matchedCarrierRatesParser.getRateSheetType();
		const ratesheetTypeName = dryRunParser.rateSheetTypeToName(rateSheetType);
		//const headerRowIndex = headerMatch ? headerMatch.headerRowIndex : 0;

		if (!headerMatch) {
			dryRunReport.terminalErrors.NoRateSheetTypeFound =
				unableToStartDryRunError;
			return dryRunReport;
		}

		const { headerRowIndex } = headerMatch;
		const duplicateRateKeys: string[] = [];
		const duplicateRatesAndSummaries: string[] = [];
		const stopAfterNumberOfDupesFound = maxTerminalErrors - totalTerminalErrors;
		const spreadsheetRows = dryRunParser.getAndAssertUniqueSpreadsheetRows(
			matchedCarrierRatesParser,
			duplicateRateKeys,
			true,
			duplicateRatesAndSummaries,
			stopAfterNumberOfDupesFound
		);

		if (duplicateRateKeys.length > 0) {
			for (const duplicateRateKey of duplicateRateKeys) {
				const maxErrorsHit = pushTerminalErrorFilled(
					'DuplicateKeys',
					`Found Duplicate Record for ${ratesheetTypeName} "${duplicateRateKey}"`
				);

				dryRunParser.removeAllListeners();

				if (maxErrorsHit) {
					return dryRunReport;
				}
			}
		}

		if (duplicateRatesAndSummaries.length > 0) {
			for (const duplicateRatesAndSummary of duplicateRatesAndSummaries) {
				pushNonTerminalErrorFilled(
					'OneRatePerRateSummary',
					`Found Duplicate Record for ${duplicateRatesAndSummary}`
				);
			}
		}

		if (rateSheetType === LcrRateSheetType.LataOcn) {
			for (
				let rowIndex = headerRowIndex + 1;
				rowIndex < spreadsheetRows.length;
				rowIndex++
			) {
				const spreadsheetRow = spreadsheetRows[headerRowIndex];
				const spreadsheetRowIndex = rowIndex + 1; //1-based
				const ocn = matchedCarrierRatesParser.getRateKey2ForRow(spreadsheetRow);
				const uniqueId =
					matchedCarrierRatesParser.getUniqueIdentifier(spreadsheetRow);
				const ocnHasCorrectCharacters =
					dryRunParser.validateStringIsAlphaNumeric(ocn);
				const ocnIsValid =
					dryRunParser.validateOcn(ocn, spreadsheetRowIndex) === true;
				const ocnNeedsToBePrefixed =
					ocnHasCorrectCharacters && ocn.length > 0 && ocn.length < 4;

				if (ocnIsValid) {
					continue;
				} else if (ocnNeedsToBePrefixed) {
					pushNonTerminalErrorFilled(
						'OcnNotFilled',
						`OCN for ${uniqueId} requires to be prefixed before processing`
					);
				} else {
					//TODO
				}
			}
		}

		for (
			let rowIndex = headerRowIndex + 1;
			rowIndex < spreadsheetRows.length;
			rowIndex++
		) {
			const spreadsheetRow = spreadsheetRows[headerRowIndex];
			const spreadsheetRowIndex = rowIndex + 1; //1-based
			const interRateStr =
				matchedCarrierRatesParser.getInterRateForRow(spreadsheetRow);
			const intraRateStr = matchedCarrierRatesParser.getIntraRateForRow(
				spreadsheetRow,
				false
			);
			const indetRateStr = matchedCarrierRatesParser.getIndetRateForRow(
				spreadsheetRow,
				false
			);
			const uniqueId =
				matchedCarrierRatesParser.getUniqueIdentifier(spreadsheetRow);
			const interRateIsValid =
				dryRunParser.validateRate(
					interRateStr,
					spreadsheetRowIndex,
					'Inter'
				) === true;
			//const intraRateIsValid = dryRunParser.validateRate(intraRateStr, spreadsheetRowIndex, 'Intra') === true;
			//const indetRateIsValid = dryRunParser.validateRate(indetRateStr, spreadsheetRowIndex, 'Indet') === true;

			if (!interRateIsValid) {
				const maxErrorsHit = pushTerminalErrorFilled(
					'MalformedIntraRate',
					`Inter. Rate for ${uniqueId} is malformed`
				);

				dryRunParser.removeAllListeners();

				if (maxErrorsHit) {
					return dryRunReport;
				}
			}

			if (intraRateStr === '') {
				pushNonTerminalErrorFilled(
					'NoIntraFound',
					`Intra. Rate for ${uniqueId} not found.`
				);
			}

			if (indetRateStr === '') {
				pushNonTerminalErrorFilled(
					'NoIndetFound',
					`Indet. Rate for ${uniqueId} not found.`
				);
			}
		}

		dryRunParser.removeAllListeners();

		return dryRunReport;
	}

	private static readonly CarrierRatesParseConfigDefault: ICarrierRatesParseConfig =
		{
			oneRatePerRateSummary: true,
			rateSheetType: 'auto',
			autoFillOcn: true,
			autofillIntraWithBestMatch: false,
			autofillIndetWithBestMatch: false
		};

	private static readonly DryRunCarrierRatesParseConfigDefault: ICarrierRatesParseConfig =
		{
			oneRatePerRateSummary: false,
			rateSheetType: 'auto',
			autoFillOcn: true,
			autofillIntraWithBestMatch: true,
			autofillIndetWithBestMatch: true
		};

	private matchedCarrierRatesParser: CarrierRatesParser | null = null;
	private config: ICarrierRatesParseConfig = {
		...CarrierRatesSpreadsheetParser.CarrierRatesParseConfigDefault
	};
	private CarrierRatesParsers: CarrierRatesParser[] = [];

	constructor(
		spreadsheet: string[][],
		configuration: Partial<ICarrierRatesParseConfig> = {}
	) {
		super(spreadsheet);

		this.config = defaults(
			{},
			configuration,
			CarrierRatesSpreadsheetParser.CarrierRatesParseConfigDefault
		);
	}

	public getRateSheetType() {
		if (!this.matchedCarrierRatesParser) {
			return undefined;
		}

		return this.matchedCarrierRatesParser.getRateSheetType();
	}

	public rateSheetTypeToName(
		ratesheetType: ICarrierRatesParseConfig['rateSheetType']
	) {
		if (ratesheetType === LcrRateSheetType.NpaNxx) {
			return 'NPA/NXX';
		} else if (ratesheetType === LcrRateSheetType.LataOcn) {
			return 'LATA/OCN';
		} else if (ratesheetType === LcrRateSheetType.DialCode) {
			return 'Dial Code';
		} else if (ratesheetType === 'auto') {
			return 'NPA/NXX, LATA/OCN, or Dial Code';
		}

		return '';
	}

	public getCarrierRates() {
		const rates: ILcrCarrierRateDtoExtended[] = [];

		if (!this.matchedCarrierRatesParser) {
			this.emit('debug', 'Unable to get carrier rates. Exiting...');
			return rates;
		}

		const matchedCarrierRatesParser = this.matchedCarrierRatesParser;
		const headerMatch = matchedCarrierRatesParser.getHeaderMatch();
		const headerRowIndex = headerMatch ? headerMatch.headerRowIndex : 0;
		const ratesheetTypeName = this.rateSheetTypeToName(
			matchedCarrierRatesParser.getRateSheetType()
		);

		if (!headerMatch) {
			return [];
		}

		this.emit('debug', `Validating spreadsheet as ${ratesheetTypeName}.`);

		const uniqueResults = this.getAndAssertUniqueSpreadsheetRows(
			matchedCarrierRatesParser
		);
		const rateSheetType = matchedCarrierRatesParser.getRateSheetType();

		//Auto fill OCN with 0's
		if (this.config.autoFillOcn && rateSheetType === LcrRateSheetType.LataOcn) {
			uniqueResults.forEach((uniqueResult) => {
				const ocnIndex = matchedCarrierRatesParser.getRateKey2Index();

				uniqueResult[ocnIndex] = padStart(uniqueResult[ocnIndex], 4, '0');
			});
		}

		for (let index = 0; index < uniqueResults.length; index++) {
			const row = uniqueResults[index];
			const rateKey1 = this.matchedCarrierRatesParser.getRateKey1ForRow(row);
			const rateKey2 = this.matchedCarrierRatesParser.getRateKey2ForRow(row);
			const interRateStr =
				this.matchedCarrierRatesParser.getInterRateForRow(row);
			const intraRateStr =
				this.matchedCarrierRatesParser.getIntraRateForRow(row);
			const indetRateStr =
				this.matchedCarrierRatesParser.getIndetRateForRow(row);
			const summary = this.matchedCarrierRatesParser.getSummaryForRow(row);

			//Validation
			const spreadsheetIndex = headerRowIndex + index;
			const rowErrors = this.getErrorsFromProspectiveCarrierRates(
				spreadsheetIndex,
				rateSheetType,
				rateKey1,
				rateKey2,
				interRateStr,
				intraRateStr,
				indetRateStr
			);

			if (rowErrors.length > 0) {
				rowErrors.map((rowError) => this.emit('error', rowError));
			} else {
				const interRate = Number(interRateStr);
				const intraRate = Number(intraRateStr);
				const indetRate = Number(indetRateStr);

				rates.push({
					rateKey1,
					rateKey2,
					interRate,
					intraRate,
					indetRate,
					summary
				});
			}
		}

		this.emit(
			'debug',
			`${rates.length.toLocaleString()} rows have been successfully validated.`
		);
		this.emit('debug', 'Validation completed.');

		return rates;
	}

	/**
	 * Returns the rate sheet type for given spreadsheet,
	 * if a rate sheet type cannot be found an array of errors will be returned
	 */
	public assertRateSheetType() {
		//reset
		this.matchedCarrierRatesParser = null;
		this.removeAllParserColumns();

		const { rateSheetType: configRateSheetType } = this.config;
		const isConfigRateSheetAll = configRateSheetType === 'auto';
		const configRateSheetName = this.rateSheetTypeToName(configRateSheetType);
		const allColumns: SpreadsheetParserColumn[] = [];
		const addParserIfRateSheetTypeApplies = (
			CarrierRateParserClass: ICarrierRateParserClass
		) => {
			const carrierRateParser = new CarrierRateParserClass(this.config);
			const parserRateSheetType = carrierRateParser.getRateSheetType();

			if (isConfigRateSheetAll || configRateSheetType === parserRateSheetType) {
				this.CarrierRatesParsers.push(carrierRateParser);

				allColumns.push(...carrierRateParser.getRequiredAndOptionalColumns());
			}
		};

		//Ordered
		addParserIfRateSheetTypeApplies(CarrierRatesNpaNxxParser);
		addParserIfRateSheetTypeApplies(CarrierRatesLataOcnParser);
		//API does not support dial code yet
		//addParserIfRateSheetTypeApplies(CarrierRatesDialCodeParser);
		//end of ordering

		const uniqueColumns = CarrierRatesParser.GetUniqueColumns(allColumns);
		this.addParserColumn(...uniqueColumns);

		const possibleHeaderMatches = this.findPossibleHeaderMatches();

		for (const carrierRatesParser of this.CarrierRatesParsers) {
			const passedHeaderMatch = carrierRatesParser.testHeaderMatches(
				possibleHeaderMatches
			);

			if (passedHeaderMatch) {
				carrierRatesParser.setHeaderMatch(passedHeaderMatch);
				this.matchedCarrierRatesParser = carrierRatesParser;
				break;
			}
		}

		if (!this.matchedCarrierRatesParser) {
			this.emit(
				'error',
				`Could not find ${configRateSheetName} headers in spreadsheet`
			);

			return undefined;
		}

		return this.matchedCarrierRatesParser.getRateSheetType();
	}

	/**
	 *
	 * @param carrierRatesParser
	 * @param outputDuplicateRateKeys
	 * @param checkForOneRatePerSummary
	 * @param outputDuplicateSummaryKeys
	 */
	private getAndAssertUniqueSpreadsheetRows(
		carrierRatesParser: CarrierRatesParser,
		outputDuplicateRateKeys: string[] = [],
		checkForOneRatePerSummary = this.config.oneRatePerRateSummary,
		outputDuplicateSummaryKeys: string[] = [],
		stopAt?: number
	) {
		const headerMatch = carrierRatesParser.getHeaderMatch();

		if (headerMatch === null) {
			return [];
		}

		const results = super.parse(headerMatch);
		const nonUniqueIds = FindNonUniqueEntries(
			results,
			carrierRatesParser.getUniqueIdentifier.bind(carrierRatesParser),
			{ stopAt }
		);

		if (nonUniqueIds.length > 0) {
			nonUniqueIds.forEach((rateKeyUniqueId) =>
				outputDuplicateRateKeys.push(rateKeyUniqueId)
			);

			this.emit(
				'error',
				`${nonUniqueIds.length.toLocaleString()} Records were found to have identical rate keys`
			);
			return results;
		}

		if (checkForOneRatePerSummary && carrierRatesParser.hasSummaryColumn()) {
			const nonUniqueIds = FindNonUniqueEntries(
				results,
				carrierRatesParser.getUniqueSummaryIdentifier.bind(carrierRatesParser)
			);

			if (nonUniqueIds.length > 0) {
				nonUniqueIds.forEach((rateKeyUniqueId) =>
					outputDuplicateSummaryKeys.push(rateKeyUniqueId)
				);

				this.emit(
					'error',
					`${nonUniqueIds.length.toLocaleString()} Records were found to have identical Summaries and Rates`
				);
			}
		}

		return results;
	}

	private getErrorsFromProspectiveCarrierRates(
		index: number,
		rateSheetTypeId: LcrRateSheetType,
		rateKey1: string,
		rateKey2: string,
		interRateStr: string,
		intraRateStr: string,
		indetRateStr: string
	) {
		const errors: string[] = [];
		const spreadsheetIndex = index + 1; // +1 for 1-based number system like excel
		let rateKey1Validation: ValidateFnReturnType = true;
		let rateKey2Validation: ValidateFnReturnType = true;
		const pushErrorsFromValidation = (validation: ValidateFnReturnType) => {
			if (validation !== true) {
				errors.push(...validation);
			}
		};

		if (rateSheetTypeId === LcrRateSheetType.NpaNxx) {
			rateKey1Validation = this.validateNpa(rateKey1, spreadsheetIndex);
			rateKey2Validation = this.validateNxx(rateKey2, spreadsheetIndex);
		} else if (rateSheetTypeId === LcrRateSheetType.LataOcn) {
			rateKey1Validation = this.validateLata(rateKey1, spreadsheetIndex);
			rateKey2Validation = this.validateOcn(rateKey2, spreadsheetIndex);
		} else if (rateSheetTypeId === LcrRateSheetType.DialCode) {
			//TODO
		}

		const interRateValidation = this.validateRate(
			interRateStr,
			spreadsheetIndex,
			'Inter'
		);
		const intraRateValidation = this.validateRate(
			intraRateStr,
			spreadsheetIndex,
			'Intra'
		);
		const indetRateValidation = this.validateRate(
			indetRateStr,
			spreadsheetIndex,
			'Indet'
		);

		pushErrorsFromValidation(rateKey1Validation);
		pushErrorsFromValidation(rateKey2Validation);
		pushErrorsFromValidation(interRateValidation);
		pushErrorsFromValidation(intraRateValidation);
		pushErrorsFromValidation(indetRateValidation);

		return errors;
	}

	private getValidationError(error: string, spreadsheetIndex: number) {
		const errorPrefix = `Error found at row ${spreadsheetIndex.toLocaleString()}: `;

		return `${errorPrefix}${error}`;
	}

	private validateStringIsDigits(str: string, length?: number) {
		const isStrAllDigits = (str: string) => /^\d+$/.test(str);
		let isValid = isStrAllDigits(str);

		if (isValid && typeof length === 'number') {
			isValid = str.length === length;
		}

		return isValid;
	}

	private validateStringIsAlphaNumeric(str: string, length?: number) {
		const isStrAllAlphaNumeric = (str: string) => /^[\d\w]+$/.test(str);
		let isValid = isStrAllAlphaNumeric(str);

		if (isValid && typeof length === 'number') {
			isValid = str.length === length;
		}

		return isValid;
	}

	private validateNpa(
		npa: string,
		spreadsheetIndex: number
	): ValidateFnReturnType {
		const npaNumber = Number(npa);
		const { MIN_NPA, MAX_NPA } = CarrierRatesSpreadsheetParser;

		if (!isNaN(npaNumber) && npaNumber >= MIN_NPA && npaNumber <= MAX_NPA) {
			return true;
		}

		const error = this.getValidationError(
			`NPA "${npa}" is not between ${MIN_NPA} and ${MAX_NPA}`,
			spreadsheetIndex
		);

		return [error];
	}

	private validateNxx(
		nxx: string,
		spreadsheetIndex: number
	): ValidateFnReturnType {
		const isValid = this.validateStringIsDigits(nxx, 3);

		if (isValid) {
			return true;
		}

		const error = this.getValidationError(
			`NXX "${nxx}" is not in three digit format`,
			spreadsheetIndex
		);

		return [error];
	}

	private validateLata(
		lata: string,
		spreadsheetIndex: number
	): ValidateFnReturnType {
		const isValid = this.validateStringIsDigits(lata, 3);

		if (isValid) {
			return true;
		}

		const error = this.getValidationError(
			`LATA "${lata}" is not in three digit format`,
			spreadsheetIndex
		);

		return [error];
	}

	private validateOcn(
		ocn: string,
		spreadsheetIndex: number
	): ValidateFnReturnType {
		const isValid = this.validateStringIsAlphaNumeric(ocn, 4);

		if (isValid) {
			return true;
		}

		const error = this.getValidationError(
			`OCN "${ocn}" is not composed of four alphanumeric characters`,
			spreadsheetIndex
		);

		return [error];
	}

	private validateRate(
		rate: string,
		spreadsheetIndex: number,
		rateType: 'Indet' | 'Inter' | 'Intra'
	): ValidateFnReturnType {
		const rateNumber = Number(rate);
		const isNumber = !isNaN(rateNumber);
		const isPositive = rateNumber > 0;

		if (isNumber && isPositive) {
			return true;
		}

		const error = this.getValidationError(
			`${rateType}. Rate "${rate}" must be a number more than zero`,
			spreadsheetIndex
		);

		return [error];
	}
}
