import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	SomosTplIvrProfileResDto,
	TemplateCprDisconnectRequest,
	TemplateCprIndexResponse,
	TemplateCprProfileRequest,
	TemplateCprProfileResponse,
	TemplateCprTransferRequest,
	TemplateCprUpdateRequest,
	TemplateProfileRequest,
	TemplateScpIndexRequest,
	TemplateScpIndexResponse,
	TollFreeCprIndexResponse,
	TollFreeCprProfileRequest,
	TollFreeCprProfileResponse,
	TollFreeCprTransferRequest,
	TollFreeProfileRequest,
	TollFreeScpIndexRequest,
	TollFreeScpIndexResponse
} from 'RtModels';
import {
	CprProfileRequest,
	CprProfileResponse,
	CprQueryProfileRequest
} from 'RtUi/app/rt800/Cprs/lib/components/CprProfileContainer';
import {
	CprIndexRequest,
	CprIndexResponse
} from 'RtUi/app/rt800/Cprs/lib/grids/CprGrid';
import {
	CprScpsIndexRequest,
	CprScpsIndexResponse,
	CprScpsQueryIndexRequest
} from 'RtUi/app/rt800/Cprs/lib/grids/ScpGrid';
import {
	FullResponse,
	handleDeleteRequest,
	handleGetRequest,
	handlePostRequest,
	handlePutRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import moment from 'moment';
import { useQuery } from 'react-query';

const fetchTemplateIvr = async (
	urlParams: TemplateProfileRequest,
	queryParams: TemplateCprProfileRequest
): Promise<SomosTplIvrProfileResDto> => {
	return handleGetRequest(Rt800ApiRoutes.TemplateIvr.Profile, {
		urlParams,
		queryParams
	});
};

export const useGetTemplateIvr = (
	urlParams: TemplateProfileRequest,
	queryParams: TemplateCprProfileRequest
) => {
	return useQuery<SomosTplIvrProfileResDto, Error>(
		[`getTemplateIvr`, urlParams, queryParams],
		() => {
			return fetchTemplateIvr(urlParams, queryParams);
		}
	);
};

const fetchTollFreeCprs = (urlParams: TollFreeProfileRequest) => {
	return handleGetRequest<TollFreeCprIndexResponse[], true>(
		Rt800ApiRoutes.TollFreeCprs.Index,
		{
			urlParams,
			includeFullResponse: true
		}
	);
};

const fetchTemplateCprs = (urlParams: TemplateProfileRequest) => {
	return handleGetRequest<TemplateCprIndexResponse[], true>(
		Rt800ApiRoutes.TemplateCprs.Index,
		{
			urlParams,
			includeFullResponse: true
		}
	);
};

export const useGetCprs = <T extends CprIndexResponse>(
	urlParams: CprIndexRequest<T>,
	isNumber: boolean
) => {
	return useQuery<FullResponse<CprIndexResponse[]>, Error>(
		['useGetCprs', urlParams],
		() => {
			if (isNumber) {
				return fetchTollFreeCprs(urlParams as TollFreeProfileRequest);
			}

			return fetchTemplateCprs(urlParams as TemplateProfileRequest);
		}
	);
};

const fetchTollFreeCpr = (
	urlParams: TollFreeProfileRequest,
	queryParams: TollFreeCprProfileRequest
) => {
	return handleGetRequest<TollFreeCprProfileResponse>(
		Rt800ApiRoutes.TollFreeCprs.Profile,
		{
			urlParams,
			queryParams
		}
	);
};

const fetchTemplateCpr = (
	urlParams: TemplateProfileRequest,
	queryParams: TemplateCprProfileRequest
) => {
	return handleGetRequest<TemplateCprProfileResponse>(
		Rt800ApiRoutes.TemplateCprs.Profile,
		{
			urlParams,
			queryParams
		}
	);
};

export const useGetCpr = <T extends CprProfileResponse>(
	isNumber: boolean,
	urlParams: CprProfileRequest<T>,
	queryParams: CprQueryProfileRequest<T>
) => {
	return useQuery<CprProfileResponse, Error>(
		['useGetCpr', urlParams, queryParams],
		() => {
			if (isNumber) {
				return fetchTollFreeCpr(
					urlParams as TollFreeProfileRequest,
					queryParams
				);
			}

			return fetchTemplateCpr(urlParams as TemplateProfileRequest, queryParams);
		},
		{
			refetchOnWindowFocus: false
		}
	);
};

const fetchTollFreeCprScps = (
	urlParams: TollFreeProfileRequest,
	queryParams: TollFreeScpIndexRequest
) => {
	return handleGetRequest<TollFreeScpIndexResponse[], true>(
		Rt800ApiRoutes.TollFreeScp.Index,
		{
			urlParams,
			queryParams,
			includeFullResponse: true
		}
	);
};

const fetchTemplateCprScps = (
	urlParams: TemplateProfileRequest,
	queryParams: TemplateScpIndexRequest
) => {
	return handleGetRequest<TemplateScpIndexResponse[], true>(
		Rt800ApiRoutes.TemplateScps.Index,
		{
			urlParams,
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetCprScps = <T extends CprScpsIndexRequest>(
	isNumber: boolean,
	urlParams: T,
	queryParams: CprScpsQueryIndexRequest<T>
) => {
	return useQuery<FullResponse<Array<CprScpsIndexResponse<T>>>, Error>(
		['useGetCprScps', urlParams],
		() => {
			if (isNumber) {
				return fetchTollFreeCprScps(
					urlParams as TollFreeProfileRequest,
					queryParams
				);
			}

			return fetchTemplateCprScps(
				urlParams as TemplateProfileRequest,
				queryParams
			);
		}
	);
};

interface IEditCprParams<T = any> {
	id: string;
	effectiveTs: Date;
	req: T;
	isNumber: boolean;
}

export const copyCpr = (params: IEditCprParams<TemplateCprUpdateRequest>) => {
	const { id, effectiveTs, req: templateCprUpdateReq, isNumber } = params;
	const timestamp = moment(effectiveTs).toISOString();
	const queryParams = { effectiveTs: timestamp };

	if (isNumber) {
		const urlParams: TollFreeProfileRequest = { tfn: id };

		return handlePostRequest(
			Rt800ApiRoutes.TollFreeCprCopy.Update,
			templateCprUpdateReq,
			{
				urlParams,
				queryParams
			}
		);
	}
	const urlParams: TemplateProfileRequest = { templateName: id };

	return handlePostRequest(
		Rt800ApiRoutes.TemplateCprCopy.Update,
		templateCprUpdateReq,
		{
			urlParams,
			queryParams
		}
	);
};

export const updateCpr = (params: IEditCprParams<TemplateCprUpdateRequest>) => {
	const { id, effectiveTs, req: templateCprUpdateReq, isNumber } = params;
	const timestamp = moment(effectiveTs).toISOString();
	const queryParams = { effectiveTs: timestamp };

	if (isNumber) {
		const urlParams: TollFreeProfileRequest = { tfn: id };

		return handlePutRequest(
			Rt800ApiRoutes.TollFreeCprs.Update,
			templateCprUpdateReq,
			{
				urlParams,
				queryParams
			}
		);
	}
	const urlParams: TemplateProfileRequest = { templateName: id };

	return handlePutRequest(
		Rt800ApiRoutes.TemplateCprs.Update,
		templateCprUpdateReq,
		{
			urlParams,
			queryParams
		}
	);
};

export const transferCpr = (
	params: IEditCprParams<
		TollFreeCprTransferRequest | TemplateCprTransferRequest
	>
) => {
	const { id, effectiveTs, req: templateCprUpdateReq, isNumber } = params;
	const timestamp = moment(effectiveTs).toISOString();
	const queryParams = { effectiveTs: timestamp };

	if (isNumber) {
		const urlParams: TollFreeProfileRequest = { tfn: id };

		return handlePostRequest(
			Rt800ApiRoutes.TollFreeCprTransfers.Update,
			templateCprUpdateReq,
			{
				urlParams,
				queryParams
			}
		);
	}
	const urlParams: TemplateProfileRequest = { templateName: id };

	return handlePostRequest(
		Rt800ApiRoutes.TemplateCprTransfers.Update,
		templateCprUpdateReq,
		{
			urlParams,
			queryParams
		}
	);
};

interface IDisconnectCpr {
	id: string;
	sourceEffectiveTs: Date;
	targetEffectiveTs: Date | undefined;
}

/**
 * Currently only for Templates!
 */
export const disconnectCpr = (params: IDisconnectCpr) => {
	const { id, sourceEffectiveTs, targetEffectiveTs } = params;
	const urlParams: TemplateProfileRequest = { templateName: id };
	const queryParams: TemplateCprProfileRequest = {
		effectiveTs: sourceEffectiveTs
	};
	const body: TemplateCprDisconnectRequest = {};

	if (targetEffectiveTs) {
		body.effectiveTs = targetEffectiveTs;
	}

	return handlePostRequest(Rt800ApiRoutes.TemplateCprDisconnect.Update, body, {
		urlParams,
		queryParams
	});
};

export const deleteCpr = (
	params: Pick<IEditCprParams, 'id' | 'effectiveTs' | 'isNumber'>
) => {
	const { id, effectiveTs, isNumber } = params;
	const timestamp = moment(effectiveTs).toISOString();
	const queryParams = { effectiveTs: timestamp };

	if (isNumber) {
		const urlParams: TollFreeProfileRequest = { tfn: id };

		return handleDeleteRequest(Rt800ApiRoutes.TollFreeCprs.Delete, {
			urlParams,
			queryParams
		});
	}
	const urlParams: TemplateProfileRequest = { templateName: id };

	return handleDeleteRequest(Rt800ApiRoutes.TemplateCprs.Delete, {
		urlParams,
		queryParams
	});
};
