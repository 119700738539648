/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DataFilter = {
    properties: {
        key: {
            type: 'string',
            isRequired: true,
        },
        operator: {
            type: 'DataFilterOperator',
            isRequired: true,
        },
        operands: {
            type: 'Array',
            isRequired: true,
        },
        advanced: {
            type: 'boolean',
        },
        alternateKeys: {
            type: 'boolean',
        },
    },
};