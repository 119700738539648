/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipFullSummaryIndexResponse = {
    properties: {
        timeslot: {
            type: 'string',
            isRequired: true,
        },
        callingPartyStd: {
            type: 'string',
            isRequired: true,
        },
        callingPartyCountry: {
            type: 'string',
            isRequired: true,
        },
        callingPartyRateCenter: {
            type: 'string',
            isRequired: true,
        },
        callingPartyRateCenterType: {
            type: 'string',
            isRequired: true,
        },
        callingPartyAttestation: {
            type: 'string',
            isRequired: true,
        },
        callingPartyAttestationUrl: {
            type: 'string',
            isRequired: true,
        },
        dialedNumberStd: {
            type: 'string',
            isRequired: true,
        },
        dialedNumberCountry: {
            type: 'string',
            isRequired: true,
        },
        dialedNumberRateCenter: {
            type: 'string',
            isRequired: true,
        },
        dialedNumberRateCenterType: {
            type: 'string',
            isRequired: true,
        },
        ingressCostAccount: {
            type: 'string',
            isRequired: true,
        },
        ingressCostSubscription: {
            type: 'string',
            isRequired: true,
        },
        ingressCostRateKey: {
            type: 'string',
            isRequired: true,
        },
        ingressCostRate: {
            type: 'number',
            isRequired: true,
        },
        ingressCostMinutes: {
            type: 'number',
            isRequired: true,
        },
        ingressCostTotal: {
            type: 'number',
            isRequired: true,
        },
        ingressRevenueAccount: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueRateKey: {
            type: 'string',
            isRequired: true,
        },
        ingressRevenueRate: {
            type: 'number',
            isRequired: true,
        },
        ingressRevenueMinutes: {
            type: 'number',
            isRequired: true,
        },
        ingressRevenueTotal: {
            type: 'number',
            isRequired: true,
        },
        ingressRevenueSubscription: {
            type: 'string',
            isRequired: true,
        },
        ingressConnectionRemoteIp: {
            type: 'string',
            isRequired: true,
        },
        ingressConnectionResult: {
            type: 'string',
            isRequired: true,
        },
        egressCostAccount: {
            type: 'string',
            isRequired: true,
        },
        egressCostSubscription: {
            type: 'string',
            isRequired: true,
        },
        egressCostRateKey: {
            type: 'string',
            isRequired: true,
        },
        egressCostRate: {
            type: 'string',
            isRequired: true,
        },
        egressCostMinutes: {
            type: 'number',
            isRequired: true,
        },
        egressCostTotal: {
            type: 'number',
            isRequired: true,
        },
        egressRevenueAccount: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueSubscription: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueRateKey: {
            type: 'string',
            isRequired: true,
        },
        egressRevenueRate: {
            type: 'number',
            isRequired: true,
        },
        egressRevenueMinutes: {
            type: 'number',
            isRequired: true,
        },
        egressRevenueTotal: {
            type: 'number',
            isRequired: true,
        },
        egressConnectionRemoteIp: {
            type: 'string',
            isRequired: true,
        },
        egressConnectionResult: {
            type: 'string',
            isRequired: true,
        },
        inAtt: {
            type: 'number',
            isRequired: true,
        },
        inCon: {
            type: 'number',
            isRequired: true,
        },
        cpUnq: {
            type: 'number',
            isRequired: true,
        },
        dnUnq: {
            type: 'number',
            isRequired: true,
        },
        inRaw: {
            type: 'number',
            isRequired: true,
        },
        inAcd: {
            type: 'number',
            isRequired: true,
        },
        egAtt: {
            type: 'number',
            isRequired: true,
        },
        egCon: {
            type: 'number',
            isRequired: true,
        },
        egRaw: {
            type: 'number',
            isRequired: true,
        },
        egAcd: {
            type: 'number',
            isRequired: true,
        },
    },
};