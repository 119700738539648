import { PropsWithChildren, useCallback, useState } from 'react';
import { Tab, TabContent } from 'react-bootstrap';
import { useMount } from 'react-use';
import {
	TabLayoutContext,
	TabLayoutTab
} from 'RtUi/components/ui/TabbedLayout/TabLayoutContext';
import { Tabs } from 'RtUi/components/ui/TabbedLayout/Tabs';
import { IStandardLayoutProps, StandardLayout } from '../StandardLayout';

export interface ITabbedLayoutProps<ProfileType>
	extends IStandardLayoutProps<ProfileType> {
	activeTab: string;
	onTabChange: (activeTab: string) => void;
	actionsHeader?: string;
	canManageFn?: () => boolean;
	backBtnTooltip?: string;
	appendToHeader?: React.ReactNode;
	rightOfHeader?: () => React.ReactNode;
	onBackClick?: () => void;
	noBreadCrumbs?: Boolean;
}

export interface ITabbedLayoutState {
	tabs: TabLayoutTab[];
}

export function TabbedLayout<ProfileType = any>(
	props: PropsWithChildren<ITabbedLayoutProps<ProfileType>>
) {
	const {
		activeTab,
		onTabChange,
		rightOfHeader,
		children,
		appendToHeader,
		actionsHeader = 'Actions',
		backBtnTooltip = 'Back',
		onBackClick,
		canManageFn,
		noBreadCrumbs
	} = props;

	const [tabs, setTabs] = useState<TabLayoutTab[]>([]);
	const addTab = useCallback((newTab: TabLayoutTab) => {
		setTabs((prevTabs) => [...prevTabs, newTab]);
	}, []);

	useMount(() => {
		const urlSearchParams = new URLSearchParams(location.search);
		const urlTabName = urlSearchParams.get('tab');

		if (typeof urlTabName === 'string') {
			onTabChange(urlTabName);
		}
	});

	return (
		<TabLayoutContext.Provider
			value={{
				activeTabHeader: activeTab,
				tabs,
				addTab,
				canManageFn
			}}
		>
			<Tab.Container activeKey={activeTab}>
				<StandardLayout
					{...props}
					rightOfHeader={rightOfHeader}
					noBreadCrumbs={noBreadCrumbs}
					appendToHeader={
						<>
							{appendToHeader}
							<Tabs
								onTabChange={onTabChange}
								backBtnTooltip={backBtnTooltip}
								onBackClick={onBackClick}
								actionsHeader={actionsHeader}
							/>
						</>
					}
				>
					<TabContent>{children}</TabContent>
				</StandardLayout>
			</Tab.Container>
		</TabLayoutContext.Provider>
	);
}
