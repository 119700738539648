import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	ServerGroupMemberCreateRequest,
	ServerGroupMemberIndexResponse,
	ServerGroupMemberProfileResponse,
	ServerGroupMemberProfileUrlRequest,
	ServerGroupMemberUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ServerGroupMembersResource extends HttpResource<
	ServerGroupMemberIndexResponse,
	ServerGroupMemberProfileResponse
> {
	constructor() {
		super(RtAdmApiRoutes.ServerGroupMembers);
	}

	public create(request: ServerGroupMemberCreateRequest) {
		const body = JSON.stringify(request);
		return this.fetchWithRoute<ServerGroupMemberProfileResponse>(
			RtAdmApiRoutes.ServerGroupMembers.Create,
			{ body }
		);
	}

	public update(
		serverGroupMemberId: number,
		request: ServerGroupMemberUpdateRequest
	) {
		const urlParams: ServerGroupMemberProfileUrlRequest = {
			serverGroupMemberId
		};
		const body = JSON.stringify(request);

		return this.fetchWithRoute<ServerGroupMemberProfileResponse>(
			RtAdmApiRoutes.ServerGroupMembers.Update,
			{ body, urlParams }
		);
	}

	public createNew(): ServerGroupMemberCreateRequest {
		return {
			serverGroupId: -1,
			serverId: -1
		};
	}
}
