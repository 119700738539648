/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NanpDidActivateResDto = {
    properties: {
        resourceNumber: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        carrierId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        profiledTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        resConnectionId: {
            type: 'number',
            isRequired: true,
        },
        resTranslatedNumber: {
            type: 'string',
            isRequired: true,
        },
        resAccountId: {
            type: 'string',
            isRequired: true,
        },
        resOrderId: {
            type: 'string',
            isRequired: true,
        },
        resRef1: {
            type: 'string',
            isRequired: true,
        },
        resRef2: {
            type: 'string',
            isRequired: true,
        },
        resStatus: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        resCreatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        resUpdatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        resDeletedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        resObject: {
            properties: {
            },
            isRequired: true,
            isNullable: true,
        },
    },
};