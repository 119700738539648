/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocNumbersIndexResponse = {
    properties: {
        tfn: {
            type: 'string',
            isRequired: true,
        },
        syncTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        rocTxnId: {
            type: 'string',
            isRequired: true,
        },
        outRespOrgId: {
            type: 'string',
            isRequired: true,
        },
        inRespOrgId: {
            type: 'string',
            isRequired: true,
        },
        rocStatus: {
            type: 'string',
            isRequired: true,
        },
        rocStatusTs: {
            type: 'Date',
            format: 'date-time',
        },
        rejectReason: {
            type: 'number',
            isRequired: true,
        },
        rejectReasonLabel: {
            type: 'string',
            isRequired: true,
        },
        changeContactName: {
            type: 'string',
            isRequired: true,
        },
        changeContactPhone: {
            type: 'string',
            isRequired: true,
        },
        changeContactEmail: {
            type: 'string',
            isRequired: true,
        },
    },
};