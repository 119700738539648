/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ViewReconciliationUnmatchedCdrsProfileResponse = {
    properties: {
        unmatchedFromTarget: {
            type: 'Array',
            isRequired: true,
        },
        unmatchedFromSource: {
            type: 'Array',
            isRequired: true,
        },
    },
};