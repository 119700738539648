import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';
import { GuardianReconciliationChargeDetailCreateRequest } from 'RtModels';
import { SpreadsheetParserEmitValidation } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserEmitValidation';

export class ReconciliationChargesSpreadsheetParser extends SpreadsheetParserEmitValidation {
	public invoiceItemIdColumn = new SpreadsheetParserColumn('invoice_item_id', [
		'invoice_item_id',
		'invoice id',
		'invoiceId'
	]).setIsRequired(true);
	public internalItemIdColumn = new SpreadsheetParserColumn(
		'internal_item_id',
		['internal_item_id', 'item id', 'itemId']
	).setIsRequired(true);
	public internalItemLabelColumn = new SpreadsheetParserColumn(
		'internal_item_label',
		['internal_item_label', 'label', 'name', 'summary', 'description']
	).setIsRequired(true);
	public recurringColumn = new SpreadsheetParserColumn('recurring', [
		'recurring',
		'mrc',
		'nrc'
	]).setIsRequired(true);
	public carrierInvoiceChargeColumn = new SpreadsheetParserColumn(
		'carrier_invoice_charge',
		['carrier_invoice_charge', 'carrier invoice charge', 'invoice charge']
	)
		.setIsRequired(true)
		.setIsDecimal();
	public expectedChargeColumn = new SpreadsheetParserColumn('expected_charge', [
		'expected_charge',
		'expected charge'
	])
		.setIsRequired(true)
		.setIsDecimal();
	public invoiceChargeDetailColumn = new SpreadsheetParserColumn(
		'carrier_invoice_charge_detail',
		['carrier_invoice_charge_detail', 'invoice detail']
	)
		.setIsRequired(true)
		.setIsDecimal();

	constructor(
		private reconciliationId: number,
		spreadsheet: string[][]
	) {
		super(spreadsheet);

		this.addParserColumn(
			this.invoiceItemIdColumn,
			this.internalItemIdColumn,
			this.internalItemLabelColumn,
			this.recurringColumn,
			this.carrierInvoiceChargeColumn,
			this.expectedChargeColumn,
			this.invoiceChargeDetailColumn
		);
	}

	public parseRecords(): GuardianReconciliationChargeDetailCreateRequest[] {
		const mcrNumbers: GuardianReconciliationChargeDetailCreateRequest[] = [];
		this.unknownFieldsMap = new Map<string, number>();

		const possibleHeaderMatches = this.findPossibleHeaderMatches();
		const possibleHeaderMatch = possibleHeaderMatches[0];

		const rows = this.parse(possibleHeaderMatch);

		const invoiceItemIdColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.invoiceItemIdColumn
		);
		const internalItemIdColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.internalItemIdColumn
		);
		const internalItemLabelColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.internalItemLabelColumn
		);
		const recurringColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.recurringColumn
		);
		const carrierInvoiceChargeColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.carrierInvoiceChargeColumn
		);
		const expectedChargeColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.expectedChargeColumn
		);
		const invoiceChargeDetailColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.invoiceChargeDetailColumn
		);

		for (const [i, row] of rows.entries()) {
			const rowIndex = i + possibleHeaderMatch.headerRowIndex + 1;

			const invoiceItemId = this.emitValidationCheck(
				'invoice_item_id',
				row[invoiceItemIdColumnIndex],
				rowIndex
			);
			const internalItemId = this.emitValidationCheck(
				'internal_item_id',
				row[internalItemIdColumnIndex],
				rowIndex
			);
			const internalItemLabel = this.emitValidationCheck(
				'internal_item_label',
				row[internalItemLabelColumnIndex],
				rowIndex
			);
			const recurring = this.emitValidationCheck(
				'recurring',
				row[recurringColumnIndex],
				rowIndex
			);
			const carrierInvoiceCharge = this.emitValidationCheck(
				'carrier_invoice_charge',
				row[carrierInvoiceChargeColumnIndex],
				rowIndex
			);
			const expectedCharge = this.emitValidationCheck(
				'expected_charge',
				row[expectedChargeColumnIndex],
				rowIndex
			);
			const carrierInvoiceChargeDetail = this.emitValidationCheck(
				'carrier_invoice_charge_detail',
				row[invoiceChargeDetailColumnIndex],
				rowIndex
			);

			let recurringNumber = Number(recurring);
			if (isNaN(recurringNumber)) {
				recurringNumber = ['yes', 'y'].includes(recurring?.toLowerCase())
					? 1
					: 0;
			}

			const record: GuardianReconciliationChargeDetailCreateRequest = {
				reconciliationId: this.reconciliationId,
				invoiceItemId,
				internalItemId,
				internalItemLabel,
				assignedTo: -1,
				systemGenerated: 0,
				recurring: recurringNumber,
				carrierInvoiceCharge,
				expectedCharge,
				carrierInvoiceChargeDetail,
				rtCharge: null,
				disputedAmount: null,
				approvedAmount: null,
				disputeStatus: null,
				disputeId: null,
				disputeFiledTs: null,
				disputeSettlementTs: null,
				disputeReceivedTs: null,
				escalationTs: null
			};

			mcrNumbers.push(record);
		}

		this.emitErrorMessage();

		return mcrNumbers;
	}
}
