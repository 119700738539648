/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum FileProcessorType {
    LrnLookup = 1,
    MinuteProfile = 2,
    LataOcnConversion = 3,
}