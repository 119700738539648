import { FC, useState } from 'react';
import { useMount } from 'react-use';
import { SipConnection } from 'RtModels';
import { SipConnectionResource } from 'RtUi/app/rtSip/Connections/lib/resources/SipConnectionResource';
import { SipConnectionEditor } from 'RtUi/app/rtSip/Connections/lib/forms/SipConnectionEditor';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { ConnectionRouter } from './Connection.router';
import { Loading } from 'RtUi/components/ui/Loading';

export const ConnectionProfileContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const connectionId = useIdParam();
	const [profile, setProfile] = useState<SipConnection>();

	useMount(async () => {
		const resource = new SipConnectionResource();
		const fetchedProfile = await resource.get(connectionId);

		setProfile(fetchedProfile);
	});

	if (!profile) {
		return <Loading />;
	}

	return (
		<StandardLayout<SipConnection> router={ConnectionRouter} profile={profile}>
			<SipConnectionEditor editMode={profile} onUpdateSuccess={setProfile} />
		</StandardLayout>
	);
};

ConnectionRouter.setProfileRtUiFunctionalComponent(ConnectionProfileContainer);
