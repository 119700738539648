import * as moment from 'moment-timezone';
import * as React from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
	AreaOfService,
	CprLabel,
	CprNode,
	CprNodeType,
	MgiCprIndexResponse,
	RespOrgIndexResponse,
	RoutingCacheTypes,
	TaskErrorIndexResponse,
	TemplateCprIndexResponse,
	TemplateCreateRequest
} from 'RtModels';
import {
	CarrierSelect,
	ICicIndexResponse
} from 'RtUi/app/rt800/Cprs/lib/controls/CarrierSelect';
import {
	CprNodeTypeOption,
	CprNodeTypeSelect
} from 'RtUi/app/rt800/Cprs/lib/controls/CprNodeTypeSelect';
import { NetworkAreaOfServiceValuesSelect } from 'RtUi/app/rt800/Cprs/lib/controls/NetworkAreaOfServiceValuesSelect';
import { CprLergHttp } from 'RtUi/app/rt800/Cprs/lib/Http/CprLergHttp';
import { INetworkAreaOfServiceValuesType } from 'RtUi/app/rt800/Cprs/lib/resources/AreaOfServiceValuesResource';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { TaskPollWithErrorsPromiseWrapper } from 'RtUi/app/rt800/Tasks/lib/utils/TaskPollWithErrorsPromiseWrapper';
import { TaskRouter } from 'RtUi/app/rt800/Tasks/Task.router';
import { ManagedRespOrgResource } from 'RtUi/app/rtAdmin/ManagedRespOrgs/lib/resources/ManagedRespOrgResource';
import { EffectiveTimeFormControl } from 'RtUi/components/form/EffectiveTimeFormControl';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { FormWizard, FormWizardStep } from 'RtUi/components/form/FormWizard';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { RtError } from 'RtUi/utils/errors/RtError';
import { PromiseUtils } from 'RtUi/utils/promises';
import { Cpr, CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2';
import { SomosCprFactory } from 'Somos/lib/SomosCpr/SomosCprFactory';
import { SelectCprToCopyFrom } from '../../../Cprs/lib/components/SelectCprToCopyFrom';
import { TemplateResource } from '../resources/TemplateResource';

interface ICreateTemplateProps {
	onSuccess: (
		templateName: string,
		firstRoute: TemplateCprIndexResponse
	) => void;
}

interface ICreateTemplateState {
	respOrg?: RespOrgIndexResponse;
	defaultCarrier?: ICicIndexResponse;
	cprNodeTypeOption?: CprNodeTypeOption;
	effectiveTs: moment.Moment;
	effectiveTsIsNow: boolean;
	alternativeCarriers: ICicIndexResponse[];
	areaOfServiceTypes: INetworkAreaOfServiceValuesType[];
	aosTypeId: number;
	templateName: string;
	summary: string;
	notes: string;
	contactName: string;
	contactNumber: string;
	isSubmitting: boolean;
	isPolling: boolean;
	routingSetup: RoutingCacheTypes | 'new';
	selectedMgiProfile?: Cpr;
	taskErrors?: TaskErrorIndexResponse[];
	taskWithErrors?: ITaskProfile;
	wizardStep: number;
	isLoadingSourceCprs: boolean;
	sourceCprs: MgiCprIndexResponse[];
	formErrors?: RtError;
	excludeHawaii: boolean;
	excludeAlaska: boolean;
	excludePuertoRico: boolean;
	templateNameCustomValidityError?: string;
}

export class CreateTemplate extends React.Component<
	ICreateTemplateProps,
	ICreateTemplateState
> {
	public state: ICreateTemplateState = {
		templateName: '',
		summary: '',
		notes: '',
		contactName: '',
		contactNumber: '',
		alternativeCarriers: [],
		areaOfServiceTypes: [],
		formErrors: undefined,
		effectiveTs: moment(),
		effectiveTsIsNow: true,
		aosTypeId: 6, // Network
		isSubmitting: false,
		isPolling: false,
		wizardStep: 1,
		isLoadingSourceCprs: false,
		sourceCprs: [],
		routingSetup: 'new',
		excludeHawaii: false,
		excludeAlaska: false,
		excludePuertoRico: false
	};

	public summaryMaxLength = 40;
	public nowMoment = moment();
	public templateResource = new TemplateResource();
	public pollPromiseWrapper: TaskPollWithErrorsPromiseWrapper | null = null;
	public promiseUtils = new PromiseUtils();

	public componentWillUnmount() {
		if (this.pollPromiseWrapper) {
			this.pollPromiseWrapper.cancel();
		}
	}

	public updateWizardStep(wizardStep: number) {
		this.setState({ wizardStep });
	}

	public async onStep2Submit(): Promise<Boolean> {
		const { selectedMgiProfile, routingSetup } = this.state;
		const copyRoutingTypes: Array<ICreateTemplateState['routingSetup']> = [
			RoutingCacheTypes.Template,
			RoutingCacheTypes.TollFree
		];
		const routingIsCopy = copyRoutingTypes.includes(routingSetup);

		if (routingIsCopy) {
			if (selectedMgiProfile) {
				return this.createTemplate(selectedMgiProfile);
			} else {
				this.setState({ isSubmitting: true });

				await this.promiseUtils.delay(500);

				return this.onStep2Submit();
			}
		}

		return Promise.resolve(true);
	}

	public async createTemplate(selectedMgiProfile?: Cpr) {
		const { aosTypeId } = this.state;

		const {
			respOrg,
			defaultCarrier,
			cprNodeTypeOption,
			alternativeCarriers,
			areaOfServiceTypes,
			templateName,
			summary,
			effectiveTs: effectiveTsMoment,
			effectiveTsIsNow
		} = this.state;

		if (typeof respOrg === 'undefined') {
			throw new RtError({ message: 'RespOrg must be defined.' });
		}

		const templateResource = new TemplateResource();
		const respOrgId = respOrg.respOrgId;
		const effectiveTs = effectiveTsMoment.toDate();
		const interLataCarriers: string[] = [];
		const intraLataCarriers: string[] = [];
		const areasOfService: AreaOfService[] = [];
		const nodeTypes: CprNodeType[] = [];
		const labels: CprLabel[] = [];
		const nodes: CprNode[] = [];

		//Copy from selected mgiProfile
		if (selectedMgiProfile) {
			const oldCpr = SomosCprFactory.RtCprV2ToRtCprV1(selectedMgiProfile);

			interLataCarriers.push(...selectedMgiProfile.getInterLataCarriers());
			intraLataCarriers.push(...selectedMgiProfile.getIntraLataCarriers());

			if (oldCpr.cpr) {
				const { nodes: mgiProfileNodes = [], labels: mgiProfileLabels = [] } =
					oldCpr.cpr;
				const { nodeTypes: mgiProfileNodeTypes = [] } = oldCpr.cpr;

				nodeTypes.push(...mgiProfileNodeTypes);
				nodes.push(...mgiProfileNodes);
				labels.push(...mgiProfileLabels);
			}

			// Updating terminating numbers from TFN to #DIAL
			if (this.state.routingSetup === RoutingCacheTypes.TollFree) {
				for (const node of nodes) {
					if (node.terminatingNumber) {
						node.terminatingNumber = '#DIAL';
					}
				}
			}

			if (Array.isArray(oldCpr.areasOfService)) {
				areasOfService.push(...oldCpr.areasOfService);
			}
		} else {
			if (typeof defaultCarrier === 'undefined') {
				throw new Error('Default Carrier must be defined.');
			}

			if (typeof cprNodeTypeOption === 'undefined') {
				throw new Error('Node Type must be defined.');
			}

			this.setState({
				isSubmitting: true,
				taskErrors: undefined,
				taskWithErrors: undefined,
				formErrors: undefined
			});

			const cprNodeType = cprNodeTypeOption.nodeType;
			const { excludeHawaii, excludeAlaska, excludePuertoRico } = this.state;
			const hasExclusion = excludeHawaii || excludeAlaska || excludePuertoRico;

			nodeTypes.push(
				cprNodeType,
				CprNodeType.Carrier,
				CprNodeType.TerminatingNumber
			);

			if (hasExclusion) {
				nodeTypes.push(CprNodeType.Announcement);
			}

			areasOfService.push({
				aosTypeId,
				aosValues: areaOfServiceTypes.map((aosType) => aosType.value)
			});

			interLataCarriers.push(
				defaultCarrier.cic,
				...alternativeCarriers.map((ac) => ac.cic)
			);
			intraLataCarriers.push(...interLataCarriers);

			const addCanadaRow = async (cprNodeType: CprNodeType) => {
				const labelName = '*CANADA';
				const cprLergHttpClient = new CprLergHttp();
				await cprLergHttpClient.loadCprLerg();

				switch (cprNodeType) {
					case CprNodeType.AreaCode:
						labels.push({
							name: labelName,
							nodeType: CprNodeType.AreaCode,
							values: [...CprLergCache.CanadaNpas]
						});
						nodes.push({
							cic: defaultCarrier.cic,
							areaCodes: [labelName],
							terminatingNumber: '#DIAL'
						});
						break;
					case CprNodeType.Lata:
						labels.push({
							name: labelName,
							nodeType: CprNodeType.Lata,
							values: [...CprLergCache.CanadaLatas]
						});
						nodes.push({
							cic: defaultCarrier.cic,
							latas: [labelName],
							terminatingNumber: '#DIAL'
						});
						break;
					case CprNodeType.State:
						labels.push({
							name: labelName,
							nodeType: CprNodeType.State,
							values: [...CprLergCache.CanadaStates]
						});
						nodes.push({
							cic: defaultCarrier.cic,
							states: [labelName],
							terminatingNumber: '#DIAL'
						});
						break;
				}
			};

			const addStateRow = (cprNodeType: CprNodeType, state: string) => {
				const labelName = '*' + state;
				const isStateExcluded =
					(state === 'AK' && excludeAlaska) ||
					(state === 'HI' && excludeHawaii) ||
					(state === 'PR' && excludePuertoRico);

				switch (cprNodeType) {
					case CprNodeType.AreaCode:
						switch (state) {
							case 'AK': // Alaska
								labels.push({
									name: labelName,
									nodeType: CprNodeType.AreaCode,
									values: ['907']
								});
								break;
							case 'HI': // Hawaii
								labels.push({
									name: labelName,
									nodeType: CprNodeType.AreaCode,
									values: ['808']
								});
								break;
							case 'PR': // Puerto Rico
								labels.push({
									name: labelName,
									nodeType: CprNodeType.AreaCode,
									values: ['787', '939']
								});
								break;
						}

						if (isStateExcluded) {
							nodes.push({
								areaCodes: [labelName],
								announcement: 'OBA'
							});
						} else {
							nodes.push({
								cic: defaultCarrier.cic,
								areaCodes: [labelName],
								terminatingNumber: '#DIAL'
							});
						}

						break;
					case CprNodeType.Lata:
						switch (state) {
							case 'AK': // Alaska
								labels.push({
									name: labelName,
									nodeType: CprNodeType.Lata,
									values: ['832']
								});
								break;
							case 'HI': // Hawaii
								labels.push({
									name: labelName,
									nodeType: CprNodeType.Lata,
									values: ['834']
								});
								break;
							case 'PR': // Puerto Rico
								labels.push({
									name: labelName,
									nodeType: CprNodeType.Lata,
									values: ['820']
								});
								break;
						}

						if (isStateExcluded) {
							nodes.push({
								latas: [labelName],
								announcement: 'OBA'
							});
						} else {
							nodes.push({
								cic: defaultCarrier.cic,
								latas: [labelName],
								terminatingNumber: '#DIAL'
							});
						}

						break;
					case CprNodeType.State:
						labels.push({
							name: labelName,
							nodeType: CprNodeType.State,
							values: [state]
						});

						if (isStateExcluded) {
							nodes.push({
								states: [labelName],
								announcement: 'OBA'
							});
						} else {
							nodes.push({
								cic: defaultCarrier.cic,
								states: [labelName],
								terminatingNumber: '#DIAL'
							});
						}
						break;
				}
			};

			for (const areaOfServiceType of areaOfServiceTypes) {
				switch (areaOfServiceType.value) {
					case 'CN':
						await addCanadaRow(cprNodeType);
						break;
					case 'CR':
						addStateRow(cprNodeType, 'PR');
						break;
					case 'US':
						addStateRow(cprNodeType, 'AK');
						addStateRow(cprNodeType, 'HI');
						break;
					case 'XA':
						await addCanadaRow(cprNodeType);
						addStateRow(cprNodeType, 'AK');
						addStateRow(cprNodeType, 'HI');
						break;
					case 'XB':
						addStateRow(cprNodeType, 'AK');
						addStateRow(cprNodeType, 'HI');
						addStateRow(cprNodeType, 'PR');
						break;
					case 'XC':
						await addCanadaRow(cprNodeType);
						addStateRow(cprNodeType, 'AK');
						addStateRow(cprNodeType, 'HI');
						addStateRow(cprNodeType, 'PR');
						break;
				}
			}

			const node: CprNode = {
				cic: defaultCarrier.cic,
				terminatingNumber: '#DIAL'
			};

			switch (cprNodeType) {
				case CprNodeType.AreaCode:
					node.areaCodes = ['OTHER'];
					break;
				case CprNodeType.Lata:
					node.latas = ['OTHER'];
					break;
				case CprNodeType.State:
					node.states = ['OTHER'];
					break;
			}

			nodes.push(node);
		}

		const { notes, contactNumber, contactName } = this.state;
		const templateCreateRequest: TemplateCreateRequest = {
			cpr: { nodeTypes, nodes, labels },
			templateName,
			respOrgId,
			effectiveTs,
			areasOfService,
			summary,
			interLataCarriers,
			intraLataCarriers,
			notes,
			contactName,
			contactNumber
		};

		if (selectedMgiProfile) {
			templateCreateRequest.referenceKey =
				selectedMgiProfile.getSourceReferenceKey();
		}

		//Remove effectiveTs if is set to NOW
		if (effectiveTsIsNow) {
			delete templateCreateRequest.effectiveTs;
		}

		let wasSuccessful = false;

		try {
			const { onSuccess } = this.props;
			const task = await templateResource.createTemplate(
				templateCreateRequest,
				{
					disableNotification: true
				}
			);

			this.setState({ isSubmitting: false, isPolling: true });

			this.pollPromiseWrapper = new TaskPollWithErrorsPromiseWrapper(
				task.taskId
			);
			const taskErrors = await this.pollPromiseWrapper.getPromise();

			this.pollPromiseWrapper = null; //cleanup

			if (taskErrors.length <= 0) {
				const routes = await templateResource.routing(templateName);
				const firstRoute: TemplateCprIndexResponse | undefined = routes[0];

				if (!firstRoute) {
					throw `${templateName} was created, however no routes were found. Visit the template for more info.`;
				}

				wasSuccessful = true;
				onSuccess(templateName, firstRoute);
			} else {
				this.setState({ taskErrors, taskWithErrors: task });
			}
		} catch (error: any) {
			this.setState({ formErrors: error });
		} finally {
			this.setState({ isPolling: false, isSubmitting: false });
		}

		return wasSuccessful;
	}

	public updateTemplateName(templateName: string) {
		const { respOrg } = this.state;
		const removeInvalidCharsFromTemplateNameStr = (str: string) =>
			str.replace(/[^\w\d\-]/g, '');
		templateName = templateName.toUpperCase();

		if (templateName.startsWith('*')) {
			const templateNameWithoutAsterisk = templateName.substr(1);
			const cleanedTemplateNameWithoutAsterisk =
				removeInvalidCharsFromTemplateNameStr(templateNameWithoutAsterisk);
			templateName = `*${cleanedTemplateNameWithoutAsterisk}`;
		} else {
			templateName = removeInvalidCharsFromTemplateNameStr(templateName);
		}

		if (respOrg) {
			const { respOrgId } = respOrg;
			const starAndRespOrgIdFirstTwoChars = `*${respOrgId[0]}${respOrgId[1]}`;
			const isValidTemplateName = templateName.startsWith(
				starAndRespOrgIdFirstTwoChars
			);
			const templateNameCustomValidityError = isValidTemplateName
				? ''
				: `Template Name must start with ${starAndRespOrgIdFirstTwoChars}`;

			if (
				this.state.templateNameCustomValidityError !==
				templateNameCustomValidityError
			) {
				this.setState({ templateNameCustomValidityError });
			}
		}

		this.setState({ templateName });
	}

	public async updateRespOrg(respOrg?: RespOrgIndexResponse) {
		const respOrgId = respOrg?.respOrgId;
		let contactName = '';
		let contactNumber = '';

		if (respOrgId) {
			const managedRespOrgResource = new ManagedRespOrgResource();

			try {
				const publicProfile = await managedRespOrgResource.getPublicProfile({
					respOrgId
				});

				contactName = publicProfile.contactName;
				contactNumber = publicProfile.contactNumber;
			} catch {
				console.error('Unable to retrieve Public Profile of a RespOrg.');
			}
		}

		this.setState({ respOrg, contactName, contactNumber }, () =>
			this.checkIfTemplateNameShouldBeAutoFilled()
		);
	}

	public checkIfTemplateNameShouldBeAutoFilled() {
		const { respOrg } = this.state;

		if (respOrg) {
			const { templateName } = this.state;
			const templatePrefix = '*' + respOrg.respOrgId.substr(0, 2) + '-';

			if (!templateName.startsWith(templatePrefix)) {
				this.updateTemplateName(templatePrefix);
			}
		}
	}

	public async assertTemplateNameIsUnique() {
		this.setState({ isSubmitting: true, formErrors: undefined });
		let isSuccessful = true;

		try {
			const { templateName, respOrg } = this.state;

			if (!respOrg) {
				throw new RtError({
					message: `You must select a RespOrg to continue.`
				});
			}

			const templateNameValidResponse =
				await this.templateResource.isTemplateNameValid(
					templateName,
					respOrg.respOrgId
				);

			if (templateNameValidResponse.exists) {
				throw new RtError({
					message: `${templateName} is already in use. Type in a unique name to continue.`
				});
			}

			if (!templateNameValidResponse.isValid) {
				throw new RtError({
					message: `${templateName} is not a valid template name.`
				});
			}
		} catch (formErrors: any) {
			isSuccessful = false;
			this.setState({ formErrors });
		} finally {
			this.setState({ isSubmitting: false });
		}

		return isSuccessful;
	}

	public renderErrors() {
		const { taskErrors, taskWithErrors } = this.state;

		if (this.state.formErrors) {
			return <FormErrors error={this.state.formErrors} />;
		}

		if (taskWithErrors && taskErrors) {
			const pathToTask = TaskRouter.getProfileRoute(taskWithErrors.taskId);

			return (
				<footer className="form-group">
					<Alert variant="danger">
						<header className="d-flex justify-content-start align-items-center">
							<i className="fas fa-fw fa-exclamation-triangle me-2" />
							<article>
								<b>Unable to Create Template.&nbsp;</b>
								<span>
									Errors are shown below. For further details click{' '}
									<Link to={pathToTask}>here</Link>.
								</span>
							</article>
						</header>
						<ol className="mb-0">
							{taskErrors.map(({ responseValue }, index) => (
								<li key={index}>
									<span>{responseValue}</span>
								</li>
							))}
						</ol>
					</Alert>
				</footer>
			);
		}

		return null;
	}

	public render() {
		const { areaOfServiceTypes, isSubmitting, isPolling } = this.state;
		const shouldDisableControls = isSubmitting || isPolling;
		const aosIncludesUsa = areaOfServiceTypes.some((aosType) =>
			['US', 'XA', 'XB', 'XC'].includes(aosType.value)
		);
		// const aosIncludesCanada = areaOfServiceTypes.some((aosType) => ['CN', 'XA', 'XC'].indexOf(aosType.value) >= 0);
		const aosIncludesCaribbean = areaOfServiceTypes.some((aosType) =>
			['CR', 'XB', 'XC'].includes(aosType.value)
		);
		const routingIsCopy =
			this.state.routingSetup === RoutingCacheTypes.Template ||
			this.state.routingSetup === RoutingCacheTypes.TollFree;

		return (
			<Row>
				<Col lg={10} xl={8}>
					<FormWizard
						currentStep={this.state.wizardStep}
						isSubmitting={isSubmitting || isPolling}
						onChange={(wizardStep) => this.updateWizardStep(wizardStep)}
					>
						<FormWizardStep
							step={1}
							header="Info"
							onSubmit={() => this.assertTemplateNameIsUnique()}
						>
							<Row>
								<Col lg={6}>
									<RespOrgSelect
										required
										autoSelectIfSingleOption
										onChange={(respOrg: RespOrgIndexResponse) =>
											this.updateRespOrg(respOrg)
										}
										value={this.state.respOrg}
									/>
								</Col>
								<Col lg={6}>
									<InputFormControl
										label="Template Name"
										required
										customValidityError={
											this.state.templateNameCustomValidityError
										}
										maxLength={15}
										minLength={5}
										readOnly={shouldDisableControls}
										onChange={(templateName) =>
											this.updateTemplateName(templateName)
										}
										value={this.state.templateName}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg={6}>
									<TextAreaFormControl
										label="Somos Description"
										required
										subLabel={`(${
											this.summaryMaxLength - this.state.summary.length
										} characters left)`}
										onChange={(summary) => this.setState({ summary })}
										value={this.state.summary}
										maxLength={this.summaryMaxLength}
									/>
								</Col>
								<Col lg={6}>
									<TextAreaFormControl
										label="Somos Notes"
										subLabel={`(${
											this.summaryMaxLength - this.state.notes.length
										} characters left)`}
										onChange={(notes) => this.setState({ notes })}
										value={this.state.notes}
										maxLength={this.summaryMaxLength}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg={6}>
									<InputFormControl
										label="Contact Name"
										onChange={(contactName) => this.setState({ contactName })}
										value={this.state.contactName}
									/>
								</Col>
								<Col lg={6}>
									<TollFreeNumberFormControl
										label="Contact Number"
										onlyNumbers
										onChange={(contactNumber) =>
											this.setState({ contactNumber })
										}
										value={this.state.contactNumber}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg={6}>
									<EffectiveTimeFormControl
										isAfter={this.nowMoment}
										required
										onChangeWithIsNow={(effectiveTs, effectiveTsIsNow) => {
											this.setState({
												effectiveTs,
												effectiveTsIsNow
											});
										}}
										value={this.state.effectiveTs}
									/>
								</Col>
							</Row>
							{this.renderErrors()}
						</FormWizardStep>
						<FormWizardStep
							step={2}
							header="Routing"
							submitText={routingIsCopy ? 'Create' : undefined}
							onSubmit={() => this.onStep2Submit()}
						>
							<RadioFormControl<ICreateTemplateState['routingSetup']>
								label="Routing Setup"
								required
								value={this.state.routingSetup}
								onChange={(routingSetup) => this.setState({ routingSetup })}
								options={[
									{ value: 'new', label: 'New Routing' },
									{
										value: RoutingCacheTypes.Template,
										label: 'Copy From Existing Template'
									},
									{
										value: RoutingCacheTypes.TollFree,
										label: 'Copy From Existing Number'
									}
								]}
							/>
							{routingIsCopy && (
								<SelectCprToCopyFrom
									required
									cloneSource={this.state.routingSetup as RoutingCacheTypes}
									onCprSelect={(selectedMgiProfile) =>
										this.setState({ selectedMgiProfile })
									}
									onCloneSourceChange={(routingSetup) =>
										this.setState({ routingSetup })
									}
								/>
							)}
							{this.state.routingSetup === 'new' && (
								<Row className="subtleSlideInRight">
									<Col lg={6}>
										<InputFormControl
											label="Area of Service Type"
											required
											disabled
											value={'Network'}
										/>
									</Col>
									<Col lg={6}>
										<CprNodeTypeSelect
											label="Initial Node Type"
											required
											limitCprCreateNodeTypes={[
												CprNodeType.AreaCode,
												CprNodeType.Lata,
												CprNodeType.State
											]}
											onChange={(cprNodeTypeOption) =>
												this.setState({ cprNodeTypeOption })
											}
											value={this.state.cprNodeTypeOption}
										/>
									</Col>
									<Col lg={6}>
										<NetworkAreaOfServiceValuesSelect<true>
											multi
											label="Area of Service Value"
											required
											onChange={(areaOfServiceTypes) => {
												if (!areaOfServiceTypes) {
													areaOfServiceTypes = [];
												}

												this.setState({
													areaOfServiceTypes,
													excludeHawaii: false,
													excludeAlaska: false,
													excludePuertoRico: false
												});
											}}
											value={this.state.areaOfServiceTypes}
										/>
									</Col>
									{(aosIncludesUsa || aosIncludesCaribbean) && (
										<Col lg={6}>
											<Form.Group className="mb-3">
												<Form.Label>
													Area of Service Value Exclusions
												</Form.Label>
												{aosIncludesUsa && (
													<>
														<Form.Check
															id="exclude-hawaii"
															checked={this.state.excludeHawaii}
															label="Exclude Hawaii"
															onChange={(evt) =>
																this.setState({
																	excludeHawaii: evt.target.checked
																})
															}
														/>
														<Form.Check
															id="exclude-alaska"
															checked={this.state.excludeAlaska}
															label="Exclude Alaska"
															onChange={(evt) =>
																this.setState({
																	excludeAlaska: evt.target.checked
																})
															}
														/>
													</>
												)}
												{aosIncludesCaribbean && (
													<Form.Check
														id="exclude-puerto-rico"
														checked={this.state.excludePuertoRico}
														label="Exclude Puerto Rico"
														onChange={(evt) =>
															this.setState({
																excludePuertoRico: evt.target.checked
															})
														}
													/>
												)}
											</Form.Group>
										</Col>
									)}
								</Row>
							)}

							{this.renderErrors()}
						</FormWizardStep>
						<FormWizardStep
							step={3}
							header="Account"
							submitText="Create"
							onSubmit={() => this.createTemplate()}
						>
							<Row className="subtleSlideInRight">
								<Col lg={6}>
									<CarrierSelect
										label="Default Carrier"
										required
										onChange={(defaultCarrier: ICicIndexResponse) =>
											this.setState({ defaultCarrier })
										}
										value={this.state.defaultCarrier}
									/>
								</Col>
								<Col lg={6}>
									<article className="subtleSlideInRight">
										<CarrierSelect<true>
											multi
											label="Additional Carriers (optional)"
											onChange={(alternativeCarriers) =>
												this.setState({
													alternativeCarriers
												})
											}
											value={this.state.alternativeCarriers}
										/>
									</article>
								</Col>
							</Row>

							{this.renderErrors()}
						</FormWizardStep>
					</FormWizard>
				</Col>
			</Row>
		);
	}
}
