/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExportIndexResponse = {
    properties: {
        cdrExportId: {
            type: 'number',
            isRequired: true,
        },
        exportTypeName: {
            type: 'string',
            isRequired: true,
        },
        exportTypeId: {
            type: 'number',
            isRequired: true,
        },
        serviceId: {
            type: 'number',
            isRequired: true,
        },
        sourceTypeName: {
            type: 'string',
            isRequired: true,
        },
        sourceTypeId: {
            type: 'number',
            isRequired: true,
        },
        cdrSourceValue: {
            type: 'number',
            isRequired: true,
        },
        referenceId: {
            type: 'number',
            isRequired: true,
        },
        isConnected: {
            type: 'number',
            isRequired: true,
        },
        fileName: {
            type: 'string',
            isRequired: true,
        },
        qty: {
            type: 'number',
            isRequired: true,
        },
        timezone: {
            type: 'string',
            isRequired: true,
        },
        begTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        cdrExportIntervalId: {
            type: 'number',
            isRequired: true,
        },
        cdrExportRetentionId: {
            type: 'number',
            isRequired: true,
        },
        serviceName: {
            type: 'string',
            isRequired: true,
        },
        statusId: {
            type: 'number',
            isRequired: true,
        },
    },
};