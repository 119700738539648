/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { ServiceNumberPortRequestIndexResponse } from 'RtModels';
import {
	handleGetRequest,
	FullResponse
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchPortRequest = async (
	queryParams?: any
): Promise<FullResponse<ServiceNumberPortRequestIndexResponse[]>> => {
	return handleGetRequest<ServiceNumberPortRequestIndexResponse[], true>(
		RtCommonApiRoutes.PortRequest.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetPortRequest = (params?: any) => {
	return useQuery<FullResponse<ServiceNumberPortRequestIndexResponse[]>, Error>(
		'getPortRequest',
		() => {
			return fetchPortRequest(params);
		}
	);
};
