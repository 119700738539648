import { DataSources, IsdnCodeIndexResponse } from 'RtModels';
import {
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { IndexedDbCacheStrategy } from 'RtUi/utils/cache/strategies/IndexedDbCacheStrategy';

/**
 * @singleton
 */
export class RtVueIsdnFilter extends RtVueFilter<IsdnCodeIndexResponse> {
	public static getInstance() {
		if (!RtVueIsdnFilter.instance) {
			RtVueIsdnFilter.instance = new RtVueIsdnFilter();
		}

		return RtVueIsdnFilter.instance;
	}

	private static instance: RtVueIsdnFilter | undefined;
	private static readonly FilterName = 'RtVueIsdnFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<IsdnCodeIndexResponse>
	> = new IndexedDbCacheStrategy(RtVueIsdnFilter.FilterName, 'code', [
		'label',
		'*keywords' as keyof IsdnCodeIndexResponse
	]);

	protected constructor() {
		super(
			RtVueIsdnFilter.FilterName,
			DataSources.IsdnCodes,
			RtVueIsdnFilter.DataCache
		);
	}

	public getLabelFor(record: IsdnCodeIndexResponse): string {
		if (String(record.code) === record.label) {
			return record.label;
		}

		return `${record.label} (${record.code})`;
	}

	public getValueFor(record: IsdnCodeIndexResponse): string {
		return String(record.code);
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<IsdnCodeIndexResponse>
	> {
		return this.vueHttp.getISDNFilter();
	}

	protected getKeywordsFor(record: IsdnCodeIndexResponse): string[] {
		const keywords: string[] = [String(record.code)];

		const labelParts = this.getKeywordsFromPhrase(record.label);

		keywords.push(...labelParts);

		return keywords;
	}
}
