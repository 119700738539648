import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	BillingEntityIndexResponse,
	BillingEntityProfileResponse,
	EmailAccountIndexResponse
} from 'RtModels';
import { cloneDeep } from 'lodash-es';
import { PartitionBillingEntityResource } from 'RtUi/app/rtAdmin/Partitions/lib/resource/PartitionBillingEntityResource';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { FC, default as React, useState, ChangeEvent } from 'react';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { EmailAccountSelect } from 'RtUi/app/rtAdmin/Partitions/lib/controls/EmailAccountSelect';
import { Form } from 'react-bootstrap';

interface IPartitionBillingEntityEditorProps {
	onUpdate: (updatedResource: BillingEntityIndexResponse) => void;
	disablePadding?: boolean;
	partitionId: number;
	editMode?: BillingEntityProfileResponse;
	onCancel?: () => void;
	createMode?: boolean;
	narrow?: boolean;
}

const resource = new PartitionBillingEntityResource();

const SINGLE_LINE_LENGTH = 64;
const NUMBER_OF_ROWS = 5;
const INSTRUCTIONS_LENGTH = SINGLE_LINE_LENGTH * NUMBER_OF_ROWS; //5 lines with 64 char limit

export const PartitionBillingEntityEditor: FC<
	React.PropsWithChildren<IPartitionBillingEntityEditorProps>
> = ({
	narrow,
	editMode,
	onUpdate,
	onCancel = () => null,
	createMode = false
}) => {
	const [error, setError] = useState();
	const [displayMode, setDisplayMode] = useState<boolean>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [emailInvoicesToCustomer, setEmailInvoicesToCustomer] =
		useState<number>(0);
	const [wireInstructionValidationError, setWireInstructionValidationError] =
		useState('');
	const [ratesEmailAccount, setRatesEmailAccount] =
		useState<EmailAccountIndexResponse>();
	const [fileSelected, setFileSelected] = useState<File>();
	const [emailAccount, setEmailAccount] = useState<EmailAccountIndexResponse>();
	const [editingEntity, setEditingEntity] =
		useState<BillingEntityProfileResponse>(
			editMode
				? cloneDeep(editMode)
				: {
						billingEntityId: 0,
						companyName: '',
						logoFile: '',
						wireInstructions: '',
						disputeEmail: '',
						emailAccountId: 0,
						pdfEmailName: '',
						pdfEmailAddress: '',
						address: '',
						summary: '',
						ratesEmailAccountId: 0,
						ratesEmailAttachmentPrefix: '',
						emailInvoicesToCustomer: 0,
						invoicePdfThreshold: 0,
						invoiceEmailTemplate: '',
						newPeerEmailName: '',
						newPeerEmailAddress: ''
					}
		);

	const onChange = <K extends keyof BillingEntityProfileResponse>(
		key: K,
		value: BillingEntityProfileResponse[K]
	) => {
		if (!isSubmitting) {
			const updated = {
				[key]: value
			};
			setEditingEntity({
				...editingEntity,
				...updated
			});
		}
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setIsSubmitting(true);
		setError(undefined);

		try {
			let newEntity: BillingEntityProfileResponse;

			if (createMode) {
				newEntity = await resource.createPartitionBillingEntity(
					editingEntity,
					fileSelected!
				);
			} else {
				newEntity = await resource.updatePartitionBillingEntity(
					editingEntity.billingEntityId,
					editingEntity,
					fileSelected
				);
			}
			setIsSubmitting(false);
			onUpdate(newEntity);
		} catch (err: any) {
			setIsSubmitting(false);
			setError(err);
		}
	};

	const onCancelInternal = () => {
		setEditingEntity(cloneDeep(editMode!));
		onCancel();
	};

	const validateWireInstructions = (value: string) => {
		const splitValue = value.split(/[\r\n]/);
		const overflowLines = splitValue.filter((singleLine) => {
			return singleLine.length > SINGLE_LINE_LENGTH;
		});
		let errorMessage = '';
		const numberOfRows = splitValue.length;
		if (
			numberOfRows > 0 &&
			numberOfRows <= NUMBER_OF_ROWS &&
			overflowLines.length !== 0
		) {
			errorMessage = `${overflowLines.join(
				', '
			)} are too long. Keep length to ${SINGLE_LINE_LENGTH} characters.`;
		}
		if (numberOfRows > NUMBER_OF_ROWS) {
			errorMessage = `The number of lines is limited to ${NUMBER_OF_ROWS}`;
		}
		setWireInstructionValidationError(errorMessage);
	};

	return (
		<RtForm
			narrow={narrow}
			createMode={createMode}
			disablePadding={!createMode}
			onCancel={onCancelInternal}
			isSubmitting={isSubmitting}
			displayMode={displayMode}
			onSubmit={onSubmit}
			error={error}
			onChange={(displayMode) => {
				setDisplayMode(!displayMode);
			}}
		>
			{!createMode && (
				<InputFormControl
					label="ID"
					readOnly={true}
					displayMode={true}
					value={String(editingEntity.billingEntityId)}
				/>
			)}
			<InputFormControl
				label="Company name"
				displayMode={displayMode}
				value={String(editingEntity.companyName)}
				onChange={(value) => onChange('companyName', value)}
				name="companyName"
			/>
			<InputFormControl
				label="Summary"
				displayMode={displayMode}
				value={String(editingEntity.summary)}
				onChange={(value) => onChange('summary', value)}
				name="summary"
			/>
			<InputFormControl
				label="Address"
				displayMode={displayMode}
				value={String(editingEntity.address)}
				onChange={(value) => onChange('address', value)}
				name="address"
			/>
			<InputFormControl
				type="email"
				label="Dispute Email"
				displayMode={displayMode}
				value={String(editingEntity.disputeEmail)}
				onChange={(value) => onChange('disputeEmail', value)}
				name="disputeEmail"
			/>
			<EmailAccountSelect
				label="Email Account Id"
				value={emailAccount}
				initialOptionId={String(editingEntity.emailAccountId)}
				onChange={(emailAccount) => {
					setEmailAccount(emailAccount);
					onChange('emailAccountId', Number(emailAccount.emailAccountId));
				}}
			/>
			<InputFormControl
				label="PDF Email Name"
				displayMode={displayMode}
				value={String(editingEntity.pdfEmailName)}
				onChange={(value) => onChange('pdfEmailName', value)}
				name="pdfEmailName"
			/>
			<InputFormControl
				type="email"
				label="PDF Email Address"
				displayMode={displayMode}
				value={String(editingEntity.pdfEmailAddress)}
				onChange={(value) => onChange('pdfEmailAddress', value)}
				name="pdfEmailAddress"
			/>
			{editingEntity.logoFileUrl && (
				<Form.Group controlId="logoFile" className="mb-3">
					<Form.Label>Existing logo file</Form.Label>
					<div>
						<img
							alt="Billing Entity Logo"
							src={`${editingEntity.logoFileUrl}`}
							width={128}
						/>
					</div>
				</Form.Group>
			)}
			<Form.Group controlId="logoFile" className="mb-3">
				<Form.Label>Upload Logo File</Form.Label>
				<Form.Control
					accept="image/jpeg"
					type="file"
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						const fileList = e.target.files;

						if (!fileList) {
							return;
						}

						// No larger then 50kb
						if (fileList[0].size > 50_000) {
							return;
						}

						setFileSelected(fileList[0]);
					}}
				/>
			</Form.Group>
			<TextAreaFormControl
				label="Wire Instructions"
				displayMode={displayMode}
				subLabel={`(${
					INSTRUCTIONS_LENGTH - editingEntity.wireInstructions.length
				} characters left)`}
				onChange={(value) => {
					onChange('wireInstructions', value);
					validateWireInstructions(value);
				}}
				value={editingEntity.wireInstructions}
				maxLength={INSTRUCTIONS_LENGTH}
				rows={NUMBER_OF_ROWS}
				name="wireInstructions"
				errorText={wireInstructionValidationError}
				className={Boolean(wireInstructionValidationError) ? 'is-invalid' : ''}
			/>
			<EmailAccountSelect
				label="Rates Email Account Id"
				value={ratesEmailAccount}
				initialOptionId={String(editingEntity.ratesEmailAccountId)}
				onChange={(ratesEmailAccount) => {
					setRatesEmailAccount(ratesEmailAccount);
					onChange(
						'ratesEmailAccountId',
						Number(ratesEmailAccount.emailAccountId)
					);
				}}
			/>
			<InputFormControl
				label="Rates Email Attachment Prefix"
				displayMode={displayMode}
				value={String(editingEntity.ratesEmailAttachmentPrefix)}
				onChange={(value) => onChange('ratesEmailAttachmentPrefix', value)}
				name="ratesEmailAttachmentPrefix"
			/>
			<BooleanRadioFormControl
				label="Email Invoices To Customer"
				value={emailInvoicesToCustomer}
				onChange={(value) => {
					setEmailInvoicesToCustomer(value);
					onChange('emailInvoicesToCustomer', value);
				}}
				name="emailInvoicesToCustomer"
			/>
			<InputFormControl
				label="Invoice PDF Threshold"
				type="number"
				displayMode={displayMode}
				value={String(editingEntity.invoicePdfThreshold)}
				onChange={(value) => onChange('invoicePdfThreshold', Number(value))}
				name="invoicePdfThreshold"
			/>
			<InputFormControl
				label="New Peer Email Name"
				displayMode={displayMode}
				value={String(editingEntity.newPeerEmailName)}
				onChange={(value) => onChange('newPeerEmailName', value)}
				name="newPeerEmailName"
			/>
			<InputFormControl
				label="New Peer Email Address"
				displayMode={displayMode}
				value={String(editingEntity.newPeerEmailAddress)}
				onChange={(value) => onChange('newPeerEmailAddress', value)}
				name="newPeerEmailAddress"
			/>
		</RtForm>
	);
};
