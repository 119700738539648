import { MessagesSummaryIndexResponse } from 'RtModels';
import { VueTotalsLineChartAbstract } from 'RtUi/app/rtVue/common/lib/charts/VueTotalsLineChartAbstract';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { ChartJsColors } from 'RtUi/components/charts/Constants';

export class MessagesSummaryChart extends VueTotalsLineChartAbstract<MessagesSummaryIndexResponse> {
	protected async getChartData() {
		const { resource } = this.props;

		if (!resource) {
			return {};
		}

		const indexes = await resource.getAll();
		indexes.sort((a, b) => {
			return a.callDate < b.callDate ? -1 : 1;
		});

		const chartData: ChartConfiguration = {
			type: 'line',
			data: {
				datasets: []
			}
		};
		const labelsAndProperties: Array<
			[string, keyof MessagesSummaryIndexResponse, string]
		> = [
			['Attempts', 'attempts', ChartJsColors.LightOrange],
			['Billable', 'billable', 'rgb(175,216,248)']
		];

		chartData.data.labels = this.getLabels(indexes);

		for (const [label, property, color] of labelsAndProperties) {
			const data: ChartDataset[] = [];

			for (const idx of indexes) {
				let value = 0;

				if (property in idx) {
					value = parseInt(String(idx[property]), 10);
				}
				//@ts-expect-error
				data.push(value);
			}

			chartData.data.datasets.push({
				type: 'line',
				label,
				//@ts-expect-error
				data,
				fill: false,
				borderColor: color,
				backgroundColor: color,
				lineTension: 0.4
			});
		}

		return chartData;
	}
}
