import * as React from 'react';
import { TransactionDataGrid } from 'RtUi/app/rtSip/Transactions/lib/grids/TransactionDataGrid';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { TransactionRouter } from './Transaction.router';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';
import {
	TransactionSearchForm,
	ITransactionFormState
} from 'RtUi/app/rtSip/Transactions/lib/forms/TransactionSearchForm';
import { TransactionIndexRequest, Transaction } from 'RtModels';
import { TransactionEditor } from 'RtUi/app/rtSip/Transactions/lib/forms/TransactionEditor';
import moment from 'moment';

interface ITransactionsContainerState extends ISearchResultsContainerState {
	formData: ITransactionFormState;
}

@TransactionRouter.getInvoicingIndexRtUiController()
export class TransactionIndexContainer extends SearchResultsContainer<
	{},
	{},
	ITransactionsContainerState
> {
	public initParams = {};

	public now: moment.Moment = moment();

	public state: ITransactionsContainerState = {
		activeTab: this.tabs.Results,
		resourceParams: {},
		formData: {
			startDate: this.now.startOf('day'),
			endDate: this.now.endOf('day')
		}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { formData } = this.state;

		const params: TransactionIndexRequest = {
			startDate: formData.startDate!.toDate(),
			endDate: formData.endDate!.toDate(),
			gteAmount: formData.gteAmount || 100,
			lteAmount: formData.lteAmount || 0,
			comments: formData.comments,
			isReversed: formData.isReversed,
			isReversal: formData.isReversal
		};

		if (formData.accounts) {
			params.accountId = formData.accounts.map((account) => account.accountId);
		}

		if (formData.transactionTypes) {
			params.transactionTypeId = formData.transactionTypes.map(
				(transactionType) => transactionType.value
			);
		}
		return params;
	}

	public onSubmit(
		e: React.FormEvent<HTMLFormElement>,
		formData: ITransactionFormState
	) {
		e.preventDefault();
		this.setState({ formData }, () => {
			this.setActiveTab(this.tabs.Results);
		});
	}

	public goToTransactionProfile(transaction: Transaction) {
		const transactionProfilePath = TransactionRouter.getProfileRoute(
			transaction as any
		);

		this.goToPath(transactionProfilePath);
	}

	public render() {
		return (
			<TabbedLayout
				router={TransactionRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<TransactionSearchForm
						initialState={this.state.formData}
						onSubmit={(e, formData) => this.onSubmit(e, formData)}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<TwoFADisabledWarning />
					<TransactionDataGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
				<TabbedLayoutTab header="Create Transaction" isAction>
					<TransactionEditor
						createMode
						onCreateSuccess={(transaction) =>
							this.goToTransactionProfile(transaction)
						}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
