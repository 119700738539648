import { IntegrationsApiRoutes } from 'RtExports/routes';
import { ResourceIndexResponse, ResourceProfileResponse } from 'RtModels';
import { RtCarrierConnectModuleRouter } from '../RtCarrierConnect.router';
import {
	IResourceProfileContainerTabs,
	ResourceProfileContainerTabs
} from 'RtUi/app/rtCommon/Resources/lib/components/ResourceProfileContainer';

export class CarrierConnectResourceRouterClass extends RtCarrierConnectModuleRouter<
	ResourceIndexResponse,
	ResourceProfileResponse,
	IResourceProfileContainerTabs
> {
	constructor() {
		super(
			'Resource',
			'resources',
			'resourceId',
			ResourceProfileContainerTabs(false)
		);

		this.setPermissionsFromApiRoute(IntegrationsApiRoutes.Resources);
	}

	public getIndexLabel(record: ResourceIndexResponse) {
		return record.label;
	}

	public getProfileLabel(profile: ResourceProfileResponse) {
		return profile.label;
	}
}

export const CarrierConnectResourceRouter =
	new CarrierConnectResourceRouterClass();
