import { find } from 'lodash-es';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { Transaction, TransactionIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { TRANSACTION_TYPES } from 'RtUi/app/rtSip/Transactions/lib/controls/TransactionTypeSelect';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface ITransactionContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const TransactionContainerTabs: ITransactionContainerTabs = {
	Profile: { header: 'Profile' }
};

class TransactionRouterClass extends SipApplicationRouter<
	TransactionIndexResponse,
	Transaction,
	ITransactionContainerTabs
> {
	constructor() {
		super(
			'Transactions',
			'transactions',
			'transactionId',
			TransactionContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.Transactions);
	}

	public getProfileLabel(profile: Transaction) {
		const transactionType = find(TRANSACTION_TYPES, {
			value: profile.transactionTypeId
		});
		let transactionTypeLabel = transactionType ? transactionType.label : '';
		if (profile.isReversal) {
			transactionTypeLabel = 'Reversal of ' + transactionTypeLabel;
		}
		return `${transactionTypeLabel} (${profile.transactionId})`;
	}

	public getSipTransactionIdFromCurrentPath() {
		const currentPath = location.pathname;
		const profilePath = this.getProfileMatch<{
			id: string;
			transactionId: string;
		}>(currentPath);

		let transactionId: number | undefined;

		if (profilePath) {
			const { id } = profilePath.params;
			transactionId = Number(id);
		}

		return transactionId;
	}
}

export const TransactionRouter = new TransactionRouterClass();
