import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { ActivePortsCountryRouter } from 'RtUi/app/rtSip/ActivePortsCountry/ActivePortsCountry.router';
import { ActivePortsCountryDataGrid } from 'RtUi/app/rtSip/ActivePortsCountry/lib/grid/ActivePortsCountryDataGrid';

@ActivePortsCountryRouter.getNetworkReportsIndexRtUiController()
export class ActivePortsCountryIndexContainer extends ApplicationContainer<
	{},
	{}
> {
	public state = {};

	public render() {
		return (
			<StandardLayout router={ActivePortsCountryRouter}>
				<ActivePortsCountryDataGrid />
			</StandardLayout>
		);
	}
}
