import { RtCommonApiRoutes } from 'RtExports/routes';
import { PortingStatusIndexResponse } from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class PortingStatusResource extends ArrayResource<PortingStatusIndexResponse> {
	constructor() {
		super('portingStatusId');

		this.setApiRouteForGetAll(RtCommonApiRoutes.PortingStatus.Index);
	}
}
