import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RoutingSummaryIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IRoutingSummaryRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const RoutingSummaryContainerTabs: IRoutingSummaryRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class RoutingSummaryRouterClass extends SipApplicationRouter<
	RoutingSummaryIndexResponse,
	RoutingSummaryIndexResponse,
	IRoutingSummaryRouterTabs
> {
	constructor() {
		super(
			'Routing Summary',
			'routingSummary',
			'asr',
			RoutingSummaryContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.RoutingSummary);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const RoutingSummaryRouter = new RoutingSummaryRouterClass();
