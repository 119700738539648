import {
	ResourceConfigurationProfileResponse,
	ResourceProfileResponse,
	ResourceType
} from 'RtModels';
import { BuildResourceSpecificFieldsArray } from 'RtUi/app/rtCommon/Resources/lib/forms/ResourceSpecificFields';
import { ResourceConfigurationResource } from 'RtUi/app/rtCommon/Resources/lib/resources/ResourceConfigurationResource';
import { ResourceConnectionSelect } from 'RtUi/app/rtDid/Resources/lib/controls/ResourceConnectionSelect';
import {
	putDidResource,
	useGetConnections
} from 'RtUi/app/rtDid/Resources/service';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

interface IResourceEditorProps {
	createMode?: boolean;
	editMode?: ResourceConfigurationProfileResponse;
	excludeResourceTypes?: ResourceType[];
	resourceType?: ResourceType;
	onCreate?: (profile: ResourceProfileResponse) => void;
	onUpdate?: (profile: ResourceProfileResponse) => void;
	onCopy?: (profile: ResourceProfileResponse) => void;
}

export const ResourceEditor = (props: IResourceEditorProps): JSX.Element => {
	const { editMode } = props;
	const [editView, setEditView] = useState(Boolean(editMode));
	const [error, setError] = useState<null | Error>(null);
	const { data: allConnections, refetch } = useGetConnections();

	const { mutateAsync } = useMutation(putDidResource);

	const {
		control,
		handleSubmit,
		watch,

		formState: { isSubmitting },
		setValue
	} = useForm();

	const handleSubmitDidResources = async (data: any) => {
		const ResourceClass = new ResourceConfigurationResource();
		const payload = { ...data };

		payload.resourceId = data.resourceId.resourceId || data.resourceId.value;
		payload.resourceTypeId = window.location.pathname.includes('rtDid') ? 3 : 4;

		if (data.configuration?.allowedRespOrgIds) {
			payload.configuration.allowedRespOrgIds =
				data.configuration?.allowedRespOrgIds.map(
					(val: { value: string; label: string }) => val.value
				);
		}

		if (isEmpty(data.configuration)) {
			delete payload.configuration;
		}
		try {
			if (!editMode) {
				await ResourceClass.create(payload);
				const activePage = window.location.pathname.includes('rtDid')
					? 'resource'
					: 'resources';
				window.location.href = `${activePage}`;
			} else {
				await mutateAsync({
					resourceId: editMode.resourceConfigurationId,
					data: payload
				});
				setEditView(!editView);
			}
		} catch (e) {
			setError(e as Error);
		}
	};

	const resourceId = watch('resourceId');
	const isActive = watch('isActive');
	const isDefault = watch('isDefault');

	const resetFormValues = useCallback(
		(value: any) => {
			setValue('label', value.label);
			setValue('isActive', value.isActive);
			setValue('isDefault', value.isDefault);
			setValue('configuration', value.configuration);
		},
		[setValue]
	);

	useEffect(() => {
		if (isActive === undefined) {
			setValue('isActive', 1);
		}
	}, [isActive, setValue]);

	useEffect(() => {
		if (isDefault === undefined) {
			setValue('isDefault', 1);
		}
	}, [isDefault, setValue]);

	useEffect(() => {
		if (!isEmpty(resourceId)) {
			setError(null);
		}
	}, [resourceId]);

	useEffect(() => {
		if (editMode) {
			refetch();
			resetFormValues(editMode);
		}
	}, [editMode, refetch, resetFormValues]);

	useEffect(() => {
		if (!isEmpty(allConnections) && editMode) {
			const integrationId =
				allConnections &&
				allConnections.filter(
					(val) => val.resourceId === editMode?.resourceId
				)[0].integrationId;
			setValue('resourceId', {
				value: editMode?.resourceId,
				label: '',
				integrationId
			});
		}
	}, [allConnections, editMode, editMode?.resourceId, setValue]);

	return (
		<Row>
			<Col lg={6}>
				<Card body>
					<Form
						className={'rt-ui-form display-mode'}
						onSubmit={handleSubmit(handleSubmitDidResources)}
					>
						{editView && (
							<header
								style={{
									display: 'flex',
									borderBottom: '1px solid #e9ecef',
									justifyContent: 'flex-end',
									paddingBottom: '2px',
									marginBottom: '2px'
								}}
							>
								<Button
									type="button"
									variant="light"
									onClick={() => setEditView(!editView)}
								>
									<i className="far fa-fw fa-edit" />
									<span>&nbsp;Edit</span>
								</Button>
							</header>
						)}
						<Controller
							control={control}
							name="label"
							render={({ field: { onChange, value } }) => (
								<InputFormControl
									label="label"
									required
									displayMode={editView}
									onChange={onChange}
									value={value}
								/>
							)}
						/>

						{!editMode && (
							<Controller
								control={control}
								name="resourceId"
								render={({ field: { onChange, value } }) => (
									<ResourceConnectionSelect
										label="Connection"
										required
										disabled={editView}
										displayMode={editView}
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						)}

						<Controller
							control={control}
							name="isActive"
							render={({ field: { onChange, value } }) => (
								<IsActiveRadioFormControl
									required
									disabled={editView}
									hideBothOption
									displayMode={editView}
									onChange={onChange}
									value={value}
								/>
							)}
						/>
						<Controller
							control={control}
							name="isDefault"
							render={({ field: { onChange, value } }) => (
								<RadioFormControl<number>
									label="Default"
									disabled={editView}
									required
									displayMode={editView}
									onChange={onChange}
									value={value}
									options={[
										{ value: 1, label: 'Yes' },
										{ value: 0, label: 'No' }
									]}
								/>
							)}
						/>
						{!isEmpty(resourceId) && (
							<>
								<BuildResourceSpecificFieldsArray
									control={control}
									integrationId={resourceId.integrationId}
									disabled={editView}
									setValue={setValue}
									editMode={editMode}
									type={
										window.location.pathname.includes('rtDid') ? 'did' : 'tf'
									}
								/>
							</>
						)}
						{error && <FormErrors className="mt-3" error={error} />}

						{!editView && editMode && (
							<Button
								type="button"
								variant="white"
								className="me-3"
								onClick={() => setEditView(!editView)}
								disabled={isSubmitting}
							>
								<span>&nbsp;Cancel</span>
							</Button>
						)}
						{!editView && (
							<Button
								className="btn-submit"
								type="submit"
								disabled={isSubmitting}
							>
								{isSubmitting ? (
									<i className="fas fa-cog fa-cog fa-spin" />
								) : (
									<i className={`fas fa-fw fa-check`} />
								)}
								<span>&nbsp;Submit</span>
							</Button>
						)}
					</Form>
				</Card>
			</Col>
		</Row>
	);
};
