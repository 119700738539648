import { LcrMinuteIndexRequest, MinuteProfile } from 'RtModels';
import { MinutesRouter } from 'RtUi/app/rtLco/Minutes/Minutes.router';
import { LcrMinutesResource } from 'RtUi/app/rtLco/Minutes/lib/resources/LcrMinutesResource';
import { useGetMinutes } from 'RtUi/app/rtLco/Minutes/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getMinutesColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/minutes';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { useMemo } from 'react';
import { Item } from 'react-contexify';

interface ILcrMinutesGridProps {
	autoFocusFilter?: boolean;
	resourceParams?: LcrMinuteIndexRequest;
}

export const LcrMinutesGrid = ({ resourceParams }: ILcrMinutesGridProps) => {
	const { data, isFetching: isLoading } = useGetMinutes(resourceParams);
	const columns = useMemo(() => getMinutesColumns(), []);

	return (
		<>
			<DataGrid<MinuteProfile>
				data={data?.data}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName="rtLco_Minutes"
				router={MinutesRouter}
				rowThemeColor={(record) => {
					if (LcrMinutesResource.IsMismatchOrZeroMinutes(record)) {
						return RowThemeColor.DANGER;
					}
				}}
				columns={columns}
				menuLinks={(selectedResource) => {
					const lb = selectedResource.isActive ? 'Deactivate' : 'Activate';

					return (
						<Item
							onClick={async () => {
								const confirmation = await Confirmation.create(
									<span>
										<span>
											You are about to <strong>{lb}</strong>{' '}
										</span>
										<span className="text-muted">
											{selectedResource.label}.{' '}
										</span>
										<span>Would you like to continue?</span>
									</span>
								);

								if (!confirmation) {
									return;
								}

								const resource = new LcrMinutesResource();
								await resource.update(selectedResource.minuteProfileId, {
									isActive: selectedResource.isActive ? 0 : 1,
									label: selectedResource.label,
									respOrgId: selectedResource.respOrgId
								});
							}}
						>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-bolt" />
								<span>
									<span>{lb}</span>
								</span>
							</span>
						</Item>
					);
				}}
			/>
			<Confirmation />
		</>
	);
};
