import { isNil } from 'lodash-es';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { BlockedNumber, BlockedNumberIndexRequest } from 'RtModels';
import { BlockNumbersRouter } from 'RtUi/app/rtSip/BlockNumbers/BlockNumbers.router';
import { BlockNumberCreateForm } from 'RtUi/app/rtSip/BlockNumbers/lib/forms/BlockNumbersCreateForm';
import { BlockNumberGrid } from 'RtUi/app/rtSip/BlockNumbers/lib/grid/BlockNumberGrid';
import { InternationalNumberFormControl } from 'RtUi/app/rtSip/ServiceNumbers/lib/controls/InternationalNumberFormControl';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';

interface IInitialResourceParams {
	phoneNumber?: string;
	search?: string;
	isActive?: number;
}

interface IBlockNumbersIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {
	activeTab: string;
}

@BlockNumbersRouter.getNetworkOpsIndexRtUiController()
export class BlockNumbersIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IBlockNumbersIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		search: '',
		isActive: undefined,
		phoneNumber: ''
	};

	public state: IBlockNumbersIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { isActive, search, phoneNumber } = this.state;

		const resourceParams: BlockedNumberIndexRequest = {};

		if (!isNil(isActive)) {
			resourceParams.isActive = isActive;
		}

		if (search) {
			resourceParams.search = search;
		}

		if (phoneNumber) {
			resourceParams.phoneNumber = phoneNumber;
		}

		return resourceParams;
	}

	public goToProfile(id: number) {
		const profilePath = BlockNumbersRouter.getProfileRoute(id);

		this.goToPath(profilePath);
	}

	public render() {
		return (
			<TabbedLayout<BlockedNumber>
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={BlockNumbersRouter}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<InputFormControl
							label="Search"
							onChange={(search) => {
								this.setState({ search });
							}}
							value={this.state.search}
						/>
						<InternationalNumberFormControl
							required
							label="Phone Number"
							value={this.state.phoneNumber}
							onChange={(phoneNumber) => this.setState({ phoneNumber })}
						/>
						<IsActiveRadioFormControl
							hideBothOption
							label="Active"
							onChange={(isActive) => this.setState({ isActive })}
							value={this.state.isActive}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<TwoFADisabledWarning />
					<BlockNumberGrid
						autoFocusFilter
						resourceParams={this.state.resourceParams}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					rtRoute={RtxSipApiRoutes.BlockedNumbers.Create}
					header="Create Blocked Number"
					isAction
				>
					<BlockNumberCreateForm
						onUpdate={() => {
							this.setState({ activeTab: this.tabs.Results });
						}}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
