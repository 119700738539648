import { RtxSipApiRoutes } from 'RtExports/routes';
import { AsrSummaryIndexRequest, AsrSummaryIndexResponse } from 'RtModels';
import { handlePostRequest } from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchAsrSummary = async (
	body: AsrSummaryIndexRequest
): Promise<AsrSummaryIndexResponse[]> => {
	return handlePostRequest(RtxSipApiRoutes.AsrSummary.Index, body);
};

export const useGetAsrSummary = (body: AsrSummaryIndexRequest) => {
	const cacheKey = JSON.stringify(body);

	return useQuery<AsrSummaryIndexResponse[], Error>(
		[`getAsrSummary`, cacheKey],
		() => {
			return fetchAsrSummary(body);
		}
	);
};
