import { AosCol } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosCol';
import { AosNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class AosColLata extends AosCol {

	public readonly cprNodeTypeId = undefined;
	public readonly aosNodeTypeId = AosNodeType.Lata;

	public readonly valueLimit = 34;
	public readonly valueRegExp = /^\d{3}$/;

	public readonly isLergNode: boolean = false;

	protected readonly possibles = CprLergCache.Latas;
	protected readonly possiblesByKey = CprLergCache.CprLergByLata;

	protected readonly canadaPossibles = CprLergCache.CanadaLatas;
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaLatasByKey;

	protected readonly canLabel = true;

}
