import { GuardianReconciliationBulkUpdateItem } from 'RtModels';
import { GuardianReconciliationsRouter } from 'RtUi/app/rtVue/GuardianReconciliation/GuardianReconciliation.router';
import { useMemo } from 'react';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getGuardianReconciliationBulkUpdateColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/guardianReconciliation';

interface IGuardianReconciliationBulkUpdateGridProps {
	items: GuardianReconciliationBulkUpdateItem[];
	pageName: string;
}

export const GuardianReconciliationBulkUpdateGrid = ({
	items,
	pageName
}: IGuardianReconciliationBulkUpdateGridProps) => {
	const columns = useMemo(
		() => getGuardianReconciliationBulkUpdateColumns(),
		[]
	);

	return (
		<DataGrid<GuardianReconciliationBulkUpdateItem>
			data={items}
			pageName={pageName}
			router={GuardianReconciliationsRouter}
			columns={columns}
		/>
	);
};
