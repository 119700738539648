/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CprLergIndexResponse = {
    properties: {
        cprNodeTypeId: {
            type: 'CprNodeType',
            isRequired: true,
        },
        worldValue: {
            type: 'string',
            isRequired: true,
        },
        somosValue: {
            type: 'string',
            isRequired: true,
        },
        pointId: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        lata: {
            type: 'string',
            isRequired: true,
        },
        ocn: {
            type: 'string',
            isRequired: true,
        },
    },
};