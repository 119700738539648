import * as React from 'react';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { EntityRouter } from '../../Entity.router';
import { EntityResource } from '../resources/EntityResource';
import { EntityProfileResponse, EntityUpdateRequest } from 'RtModels';
import { RtError } from 'RtUi/utils/errors/RtError';

interface IEntityEditorProps {
	editMode?: EntityProfileResponse;
	createMode?: boolean;
	onCreateSuccess?: (res: EntityProfileResponse) => void;
	onUpdateSuccess?: (res: EntityProfileResponse) => void;
}

interface IEntityEditorState extends EntityUpdateRequest {
	summary: EntityProfileResponse['summary'];
	displayMode: boolean;
	isSubmitting: boolean;
	canManage: boolean;
	error?: any;
}

export class EntityEditor extends React.Component<
	IEntityEditorProps,
	IEntityEditorState
> {
	public state: IEntityEditorState = {
		label: '',
		isActive: 0,
		summary: '',
		displayMode: true,
		isSubmitting: false,
		canManage: false,
		error: undefined
	};

	public entityResource = new EntityResource();

	public componentDidMount() {
		const { createMode = false } = this.props;
		const displayMode = !createMode;

		this.setState({ displayMode });

		this.loadEditProfileIfExists();
	}

	public loadEditProfileIfExists(props = this.props) {
		const { editMode } = props;

		if (editMode) {
			const canManage = EntityRouter.canManageWith(editMode);
			const { label, isActive, summary } = editMode;

			this.setState({ label, isActive, summary, canManage });
		}
	}

	public submit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		const {
			createMode = false,
			onUpdateSuccess = () => ({}),
			editMode
		} = this.props;

		if (createMode) {
			//todo
		} else if (editMode) {
			const { label, isActive } = this.state;
			const { entityId } = editMode;

			this.setState({ isSubmitting: true, error: undefined });

			this.entityResource
				.update(entityId, label, isActive)
				.then((entityProfile) => {
					onUpdateSuccess(entityProfile);
					this.setState({ displayMode: true });
				})
				.catch((error: RtError) => {
					this.setState({ error });
				})
				.finally(() => this.setState({ isSubmitting: false }));
		}
	}

	public render() {
		const { displayMode, isSubmitting, canManage } = this.state;
		const { createMode = false } = this.props;

		return (
			<RtUiNarrowForm
				createMode={createMode}
				onChange={(displayMode) => this.setState({ displayMode })}
				displayMode={displayMode}
				isSubmitting={isSubmitting}
				onSubmit={(e) => this.submit(e)}
				hideButtons={!createMode || !canManage}
			>
				<InputFormControl
					label="Label"
					displayMode={displayMode}
					onChange={(label) => this.setState({ label })}
					value={this.state.label}
				/>
				<InputFormControl
					label="Summary"
					displayMode
					onChange={(summary) => this.setState({ summary })}
					value={this.state.summary}
				/>
				<IsActiveRadioFormControl
					label="Entity Status"
					displayMode={displayMode}
					activeTooltip="Active with Somos"
					hideBothOption
					onChange={(isActive) => this.setState({ isActive })}
					value={this.state.isActive}
				/>
			</RtUiNarrowForm>
		);
	}
}
