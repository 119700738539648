import { useMemo } from 'react';
import { IsMultiFormValue } from 'RtUi/components/form/FormControl';
import { ISelectFormControlProps } from 'RtUi/components/form/SelectFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface IGlobalBlockSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<number, IsMulti> {
	value?: IsMultiFormValue<number, IsMulti>;
	onChange?: (newValue: IsMultiFormValue<number, IsMulti>) => void;
	label?: string;
	required?: boolean;
	isClearable?: boolean;
}

export const GlobalBlockSelect = <IsMulti extends boolean = false>({
	value,
	onChange = () => {},
	required,
	isClearable = true,
	displayMode,
	label = 'Global Block',
	initialOptionId,
	multi
}: IGlobalBlockSelectProps<IsMulti>): JSX.Element => {
	const options: Array<ISimpleSelectFormControlOption<number>> = useMemo(() => {
		return [];
	}, []);

	const selectValue = useMemo(() => {
		return multi
			? options.filter((opt) => (value as number[])?.includes(opt.value))
			: options.find((opt) => opt.value === value);
	}, [value, options, multi]);

	const initialOptions = useMemo(
		() =>
			multi
				? (initialOptionId as string[]).map((val) => val.toString())
				: initialOptionId?.toString(),
		[initialOptionId, multi]
	);

	const onChangeHandler = (
		newValue: IsMulti extends true
			? Array<ISimpleSelectFormControlOption<number>>
			: ISimpleSelectFormControlOption<number>
	) => {
		const val = multi
			? (newValue as Array<ISimpleSelectFormControlOption<number>>)?.map(
					(v) => v.value
				)
			: (newValue as ISimpleSelectFormControlOption<number>)?.value;

		onChange(val as any);
	};

	return (
		<SimpleSelectFormControl<number, IsMulti>
			label={label}
			multi={multi}
			clearable={isClearable}
			required={required}
			displayMode={displayMode}
			value={selectValue as any}
			onChange={(val: any) => {
				onChangeHandler(val);
			}}
			options={options}
			initialOptionId={initialOptions as any}
		/>
	);
};
