import { useLocalStorage } from 'react-use';
import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';

interface IAutoRefreshProps
	extends Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'> {
	pageName: string;
}

const AutoRefresh: FC<React.PropsWithChildren<IAutoRefreshProps>> = ({
	pageName,
	style,
	className
}) => {
	const [isAutoRefreshStorageVal, setIsAutoRefreshStorageVal] =
		useLocalStorage<boolean>(getRefreshKey(pageName), false);
	const valueText = isAutoRefreshStorageVal ? 'On' : 'Off';

	return (
		<Form.Group style={style} className={clsx(className, 'mb-3')}>
			<Form.Label>Automatic Page Refresh</Form.Label>
			<br />
			<Form.Check
				type="switch"
				inline
				checked={isAutoRefreshStorageVal}
				id="auto-fresh-input"
				onChange={() => setIsAutoRefreshStorageVal(!isAutoRefreshStorageVal)}
				label={valueText}
				title="Every 15 minutes"
			/>
		</Form.Group>
	);
};

const getRefreshKey = (pageName: string): string =>
	`auto-refresh-page-${pageName}`;

const shouldRefresh = (pageName: string): boolean =>
	localStorage.getItem(getRefreshKey('guardian-alert-history')) === 'true';

export { AutoRefresh, shouldRefresh };
