import {
	$SubscriptionProfileResponse,
	AccountIndexResponse,
	Attestation,
	CallflowService,
	InvoiceCycleGroup,
	JurisPrecedenceOptions,
	RatePlanIndexResponse,
	ReleaseCodeIndexResponse,
	RoutePlanIndexResponse,
	ScenarioIndexResponse,
	ServiceIndexResponse,
	SubscriptionCreateRequest,
	SubscriptionIndexResponse,
	SubscriptionProfileResponse,
	SubscriptionUpdateRequest,
	TimezoneIndexResponse,
	TrackingGroupIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { AttestationSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/AttestationSelect';
import { RatePlanSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/RatePlanSelect';
import { RatingPrecedenceSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/RatingPrecedenceSelect';
import { ReleaseCodeSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/ReleaseCodeSelect';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { TimezoneSelect } from 'RtUi/app/rtCommon/Timezones/lib/controls/TimezoneSelect';
import { VendorTerminationSubscriptionSelect } from 'RtUi/app/rtSip/CallSimulator/lib/controls/VendorTerminationSubscriptionSelect';
import { InvoiceCycleGroupSelect } from 'RtUi/app/rtSip/Invoices/lib/controls/InvoiceCycleGroupSelect';
import { RoutePlanSelect } from 'RtUi/app/rtSip/RoutePlans/lib/controls/RoutePlanSelect';
import { ScenarioSelect } from 'RtUi/app/rtSip/Scenarios/lib/controls/ScenarioSelect';
import { CustomerOriginationSubscriptionSelect } from 'RtUi/app/rtSip/ServiceNumbers/lib/controls/CustomerOriginationSubscriptionSelect';
import { ServiceSelect } from 'RtUi/app/rtSip/Services/lib/controls/ServiceSelect';
import { TrackingGroupSelect } from 'RtUi/app/rtSip/TrackingGroups/lib/controls/TrackingGroupSelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { RtxUiForm } from 'RtUi/components/rtx/form';
import { RtxUiPhoneNumberInput } from 'RtUi/components/rtx/inputs/PhoneNumber/RtxUiPhoneNumberInput';
import { RtError } from 'RtUi/utils/errors/RtError';
import { isNil } from 'lodash-es';
import * as moment from 'moment';
import { useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

export enum SectionHeaders {
	profile = 'Profile',
	billing = 'Billing',
	origination = 'Origination (DID / Toll-Free)',
	directVendorTermination = 'Direct Vendor Termination'
}

enum DeactivatedServices {
	All,
	Connections,
	Rates,
	None
}

interface ISubscriptionEditorProps {
	onUpdate: (newSubscription: SubscriptionProfileResponse) => void;
	disablePadding?: boolean;
	editMode?: SubscriptionProfileResponse;
	onCancel?: () => void;
	createMode?: boolean;
	accountId?: number;
}

const getInitialSubscription = (
	accountId?: number
): SubscriptionProfileResponse => ({
	label: '',
	isActive: 1,
	note: null,
	billDays: 7,
	dueDays: 3,
	disputeDays: 30,
	disputeResponseDays: 30,
	rateKeyDeleteDays: 7,
	rateKeyMatchIncreaseDays: 7,
	rateKeyExpandIncreaseDays: 7,
	accountId: accountId ? accountId : -1,
	serviceId: -1,
	// Weekly
	invoiceCycleGroupId: 3,
	trackingGroupId: null,
	// UTC
	timezoneId: 436,
	subscriptionId: 0,
	startDate: new Date(),
	translatedNumberStd: null,
	defaultInterstateRate: 0,
	defaultIntrastateRate: 0,
	defaultIndeterminateRate: 0,
	defaultIncrementSeconds: 6,
	defaultMinimumSeconds: 6,
	defaultRoundingPrecision: 8,
	sipConnectionsCount: 0,
	ratePlansCount: 0
});

export const SubscriptionEditor = ({
	createMode,
	editMode,
	accountId,
	onUpdate
}: ISubscriptionEditorProps) => {
	const [account, setAccount] = useState<AccountIndexResponse>();
	const [serviceType, setServiceType] = useState<ServiceIndexResponse>();
	const [ratePlan, setRatePlan] = useState<RatePlanIndexResponse>();
	const [routePlan, setRoutePlan] = useState<RoutePlanIndexResponse>();
	const [timezone, setTimezone] = useState<TimezoneIndexResponse>();
	const [invoiceCycleGroup, setInvoiceCycleGroup] =
		useState<InvoiceCycleGroup>();
	const [ratingParametersPriority, setRatingParametersPriority] =
		useState<Array<ISimpleSelectFormControlOption<JurisPrecedenceOptions>>>();
	const [attestation, setAttestation] = useState<Attestation>();
	const [defaultSubscription, setDefaultSubscription] =
		useState<SubscriptionIndexResponse>();
	const [sourceSubscription, setSourceSubscription] =
		useState<SubscriptionIndexResponse>();
	const [trackingGroup, setTrackingGroup] =
		useState<TrackingGroupIndexResponse>();
	const [defaultIsdnRelease, setDefaultIsdnRelease] =
		useState<ReleaseCodeIndexResponse>();
	const [defaultScenario, setDefaultScenario] =
		useState<ScenarioIndexResponse>();
	const [vendorSubscription, setVendorSubscription] =
		useState<SubscriptionIndexResponse>();
	const [displayMode, setDisplayMode] = useState<boolean>(Boolean(editMode));
	const [deactivatedServices, setDeactivatedServices] =
		useState<DeactivatedServices>();
	const [isNewRatePlan, setIsNewRatePlan] = useState<boolean>();
	const [error, setError] = useState<RtError>();

	const getDeactivateConfirmMessage = (
		sipConnectionsCount: number,
		ratePlansCount: number
	): string => {
		const sipConnectionsMessage = `${sipConnectionsCount} SIP Connections`;
		const ratePlansMessage =
			ratePlansCount === 1
				? `${ratePlansCount} Rates`
				: 'Rates will NOT be deactivated because this is a shared rate plan';

		if (sipConnectionsCount && !ratePlansCount) {
			return `${sipConnectionsMessage} will be deactivated!`;
		}

		if (!sipConnectionsCount && ratePlansCount) {
			return ratePlansCount === 1
				? `${ratePlansMessage} will be deactivated`
				: ratePlansMessage;
		}

		return ratePlansCount === 1
			? `${sipConnectionsMessage} and ${ratePlansMessage} will be deactivated!`
			: `${sipConnectionsMessage} will be turned off. ${ratePlansMessage}`;
	};

	const onSubmit = async (data: SubscriptionProfileResponse) => {
		const deactivating =
			!data.isActive &&
			![undefined, DeactivatedServices.None].includes(deactivatedServices);

		if (deactivating && deactivatedServices === DeactivatedServices.All) {
			const deactivatedMessage = getDeactivateConfirmMessage(
				data.sipConnectionsCount,
				data.ratePlansCount
			);

			const confirm = await Confirmation.create(
				<>
					<p>You are trying to deactivate all the services in this account.</p>
					<b className="d-block mb-3">{deactivatedMessage}</b>
					<p>Do you want to continue?</p>
				</>
			);

			if (!confirm) {
				return;
			}
		}

		try {
			let newSubscription: SubscriptionProfileResponse;
			setError(undefined);
			const subscriptionResource = new SubscriptionResource();

			if (createMode) {
				newSubscription = await subscriptionResource.create(
					data as SubscriptionCreateRequest
				);
				onUpdate(newSubscription);
			} else {
				const updateParams: SubscriptionUpdateRequest = {
					label: data.label,
					serviceId: data.serviceId,
					ratePlanId: data.ratePlanId,
					note: data.note ?? undefined,
					externalAccountId: data.externalAccountId ?? undefined,
					externalSubAccountId: data.externalSubAccountId ?? undefined,
					deactivate: {
						ackConnections: Number(
							deactivating &&
								[
									DeactivatedServices.Connections,
									DeactivatedServices.All
								].includes(deactivatedServices as DeactivatedServices)
						),
						ackRates: Number(
							deactivating &&
								[DeactivatedServices.Rates, DeactivatedServices.All].includes(
									deactivatedServices as DeactivatedServices
								)
						)
					},
					isActive: data.isActive,
					blockTypeId: data.blockTypeId ?? undefined,
					attestation: data.attestation ?? null,
					startDate: data.startDate
				};

				newSubscription = await subscriptionResource.update(
					data.subscriptionId,
					updateParams
				);
			}
			setDisplayMode(true);
			onUpdate(newSubscription);
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<RtxUiForm<SubscriptionProfileResponse>
			defaultValues={editMode || getInitialSubscription(accountId)}
			onSubmit={onSubmit}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			createMode={createMode}
			displayMode={displayMode}
			onEdit={() => setDisplayMode(false)}
			onCancel={() => setDisplayMode(true)}
		>
			{({ control, getValues, setValue }) => {
				const [isActive, sipConnectionsCount, ratePlansCount] = getValues([
					'isActive',
					'sipConnectionsCount',
					'ratePlansCount'
				]);

				const displayDeactivateServicesRadioButtons =
					!displayMode &&
					!isActive &&
					(sipConnectionsCount !== 0 || ratePlansCount !== 0);

				return (
					<Row col={2}>
						<Col lg={createMode ? 6 : 10}>
							<fieldset className="card card-body mb-0">
								<legend className="card-header">
									<h6>{SectionHeaders.profile}</h6>
								</legend>
								<Controller
									control={control}
									name="accountId"
									render={({ field: { onChange, value } }) => (
										<AccountSelect<false>
											label="Account"
											value={account}
											onChange={(account) => {
												setAccount(account);
												onChange(account.accountId);
											}}
											displayMode={!Boolean(createMode)}
											initialOptionId={String(value)}
											required={
												$SubscriptionProfileResponse.properties.accountId
													.isRequired
											}
										/>
									)}
								/>
								<Controller
									control={control}
									name="label"
									render={({ field: { onChange, value = '' } }) => (
										<InputFormControl
											label="Label"
											displayMode={displayMode}
											value={value}
											onChange={(value) => onChange(value ?? '')}
											required={
												$SubscriptionProfileResponse.properties.label.isRequired
											}
										/>
									)}
								/>
								{!Boolean(createMode) && (
									<>
										<Controller
											control={control}
											name="isActive"
											render={({ field: { onChange, value } }) => (
												<IsActiveRadioFormControl
													displayMode={displayMode}
													value={value}
													hideBothOption
													onChange={onChange}
													required={
														$SubscriptionProfileResponse.properties.isActive
															.isRequired
													}
												/>
											)}
										/>
										{displayDeactivateServicesRadioButtons && (
											<RadioFormControl<DeactivatedServices>
												label="Deactivated Services"
												vertical
												value={deactivatedServices}
												onChange={setDeactivatedServices}
												required
												highlighted
												options={[
													{
														value: DeactivatedServices.None,
														label: 'None',
														tooltip:
															'The subscription will be deactivated but the related Connections and Rates will continue to be active'
													},
													{
														value: DeactivatedServices.All,
														label: 'All',
														tooltip:
															'The subscription will be deactivated along with the related Connections and Rates'
													},
													{
														value: DeactivatedServices.Connections,
														label: 'Connections',
														tooltip:
															'The subscription will be deactivated with the related Connections but the Rates will continue to be active'
													},
													{
														value: DeactivatedServices.Rates,
														label: 'Rates',
														tooltip:
															'the subscription will be deactivated with the related Rates but the Connections will continue to be active'
													}
												]}
											/>
										)}
									</>
								)}
								<Controller
									control={control}
									name="serviceId"
									render={({ field: { onChange, value } }) => (
										<ServiceSelect
											label="Service Type"
											clearable={false}
											displayMode={displayMode || !Boolean(createMode)}
											onChange={(serviceType) => {
												setServiceType(serviceType);
												onChange(serviceType.serviceId);

												if (
													serviceType.serviceId !==
													CallflowService.InboundVendorOrigination
												) {
													setDefaultSubscription(undefined);
													setValue('defaultSubscriptionId', undefined);

													setValue('defaultIsdnReleaseId', undefined);
													setDefaultIsdnRelease(undefined);

													setValue('defaultIsdnReleaseMs', undefined);
													setValue('hasServiceNumbers', undefined);
												}

												if (
													serviceType.serviceId !==
													CallflowService.InboundCustomerIdentificationClone
												) {
													setValue('sourceSubscriptionId', undefined);
													setSourceSubscription(undefined);
												}

												if (
													serviceType.serviceId !==
													CallflowService.OutboundCustomerIdentification
												) {
													setValue('trackingGroupId', undefined);
													setTrackingGroup(undefined);
													setValue('translatedNumberStd', null);
												}

												if (
													![
														CallflowService.OutboundCustomerTermination,
														CallflowService.OutboundCustomerIdentification
													].includes(serviceType.serviceId)
												) {
													setValue('directSubscriptionId', undefined);
													setVendorSubscription(undefined);
												}

												if (
													![
														CallflowService.InboundCustomerIdentification,
														CallflowService.InboundCustomerIdentificationClone,
														CallflowService.OutboundCustomerIdentification
													].includes(serviceType.serviceId)
												) {
													setValue('defaultScenarioId', undefined);
													setDefaultScenario(undefined);
												}
											}}
											value={serviceType}
											initialOptionId={String(value)}
											required={Boolean(createMode)}
										/>
									)}
								/>
								{Boolean(createMode) && (
									<RadioFormControl<boolean>
										label="Rate Plan"
										onChange={setIsNewRatePlan}
										value={isNewRatePlan}
										required
										options={[
											{ value: true, label: 'Create a New Rate Plan' },
											{ value: false, label: 'Use a Shared Rate Plan' }
										]}
									/>
								)}
								{(isNewRatePlan === false || Boolean(editMode)) && (
									<Controller
										control={control}
										name="ratePlanId"
										render={({ field: { onChange, value } }) => (
											<RatePlanSelect
												required={isNewRatePlan === false}
												label={`${Boolean(createMode) ? '' : 'Rate Plan'}`}
												displayMode={displayMode}
												onChange={(ratePlan: RatePlanIndexResponse) => {
													setRatePlan(ratePlan);
													onChange(ratePlan.ratePlanId);
												}}
												value={ratePlan}
												isShared={isNewRatePlan === false || undefined}
												assignableToSubscriptionId={editMode?.subscriptionId}
												initialOptionId={value ?? undefined}
											/>
										)}
									/>
								)}
								<Controller
									control={control}
									name="note"
									render={({ field: { onChange, value } }) => (
										<TextAreaFormControl
											label="Notes"
											displayMode={displayMode}
											value={value ?? ''}
											onChange={onChange}
										/>
									)}
								/>
								{!Boolean(createMode) && (
									<Controller
										control={control}
										name="lastRateLoadTs"
										render={({ field: { value } }) => (
											<DatetimeFormControl
												label="Last Rate Load"
												displayMode
												value={value ? moment(value) : undefined}
											/>
										)}
									/>
								)}
								{Boolean(createMode) && (
									<Controller
										control={control}
										name="routePlanId"
										render={({ field: { onChange, value } }) => (
											<RoutePlanSelect
												name="routePlanId"
												displayMode={displayMode}
												onChange={(routePlan: RoutePlanIndexResponse) => {
													setRoutePlan(routePlan);
													onChange(routePlan.routePlanId);
												}}
												required={
													serviceType &&
													[
														CallflowService.OutboundCustomerTermination,
														CallflowService.InboundCustomerIdentification,
														CallflowService.InboundCustomerIdentificationClone,
														CallflowService.OutboundCustomerIdentification
													].includes(serviceType.serviceId)
												}
												value={routePlan}
												initialOptionId={String(value)}
												allowedRoutePlanIds={[3, 6]}
											/>
										)}
									/>
								)}
								<Controller
									control={control}
									name="startDate"
									render={({ field: { onChange, value } }) => (
										<DatetimeFormControl
											name=""
											showDateOnly
											label="Start Date"
											displayMode={displayMode}
											required
											value={moment(value)}
											onChange={(value) => onChange(value.toDate())}
										/>
									)}
								/>
								<Controller
									control={control}
									name="externalAccountId"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											label="External Account Id"
											displayMode={displayMode}
											value={value ?? ''}
											onChange={(value) => onChange(value ?? '')}
										/>
									)}
								/>
								<Controller
									control={control}
									name="externalSubAccountId"
									render={({ field: { onChange, value } }) => (
										<InputFormControl
											label="External Sub Account Id"
											displayMode={displayMode}
											value={value ?? ''}
											onChange={(value) => onChange(value ?? '')}
										/>
									)}
								/>
								<Controller
									control={control}
									name="attestation"
									render={({ field: { onChange, value } }) => (
										<AttestationSelect
											displayMode={displayMode}
											onChange={(attestation: Attestation) => {
												setAttestation(attestation);
												onChange(attestation ?? null);
											}}
											value={attestation}
											initialOptionId={String(value ?? '')}
										/>
									)}
								/>
							</fieldset>
						</Col>
						{createMode && (
							<>
								<Col lg={6}>
									<fieldset className="card card-body mb-0">
										<legend className="card-header">
											<h6>{SectionHeaders.origination}</h6>
										</legend>
										<Controller
											control={control}
											name="defaultSubscriptionId"
											render={({ field: { onChange, value } }) => (
												<CustomerOriginationSubscriptionSelect
													label="Default Subscription"
													isActive={1}
													displayMode={displayMode}
													isDisabled={
														serviceType?.serviceId !==
														CallflowService.InboundVendorOrigination
													}
													onChange={(defaultSubscription) => {
														setDefaultSubscription(defaultSubscription);
														onChange(defaultSubscription.subscriptionId);
													}}
													required={
														serviceType &&
														CallflowService.InboundVendorOrigination ===
															serviceType.serviceId
													}
													value={defaultSubscription}
													initialOptionId={value ?? undefined}
												/>
											)}
										/>
										<Controller
											control={control}
											name="sourceSubscriptionId"
											render={({ field: { onChange, value } }) => (
												<CustomerOriginationSubscriptionSelect
													label="Clone Subscription"
													isActive={1}
													displayMode={displayMode}
													isDisabled={
														serviceType?.serviceId !==
														CallflowService.InboundCustomerIdentificationClone
													}
													required={
														serviceType?.serviceId ===
														CallflowService.InboundCustomerIdentificationClone
													}
													onChange={(sourceSubscription) => {
														setSourceSubscription(sourceSubscription);
														onChange(sourceSubscription.subscriptionId);
													}}
													value={sourceSubscription}
													initialOptionId={value ?? undefined}
												/>
											)}
										/>
										<Controller
											control={control}
											name="trackingGroupId"
											render={({ field: { onChange, value } }) => (
												<TrackingGroupSelect
													label="Tracking Group"
													displayMode={displayMode}
													disabled={
														serviceType?.serviceId !==
														CallflowService.OutboundCustomerIdentification
													}
													onChange={(trackingGroup) => {
														setTrackingGroup(trackingGroup);
														onChange(trackingGroup.trackingGroupId);
													}}
													value={trackingGroup}
													initialOptionId={String(value)}
												/>
											)}
										/>
										<Controller
											control={control}
											name="defaultIsdnReleaseId"
											render={({ field: { onChange, value } }) => (
												<ReleaseCodeSelect
													allowReleaseIds={[1, 17, 18, 28, 34]}
													displayMode={displayMode}
													disabled={
														serviceType?.serviceId !==
														CallflowService.InboundVendorOrigination
													}
													label="Default ISDN Rel."
													valueKey="isdnReleaseId"
													value={defaultIsdnRelease}
													initialOptionId={String(value) ?? undefined}
													onChange={(defaultIsdnReleaseId) => {
														setDefaultIsdnRelease(defaultIsdnReleaseId);
														onChange(defaultIsdnReleaseId.isdnReleaseId);
													}}
												/>
											)}
										/>
										<Controller
											control={control}
											name="defaultIsdnReleaseMs"
											render={({ field: { onChange, value } }) => (
												<InputFormControl
													type="number"
													min={0}
													max={18000}
													label="Default ISDN Rel. ms"
													displayMode={displayMode}
													disabled={
														CallflowService.InboundVendorOrigination !==
														serviceType?.serviceId
													}
													value={!isNil(value) ? String(value) : undefined}
													onChange={(value) => {
														onChange(Number(value));
													}}
												/>
											)}
										/>
										<Controller
											control={control}
											name="hasServiceNumbers"
											render={({ field: { onChange, value } }) => (
												<BooleanRadioFormControl
													label="Service Number Override"
													displayMode={displayMode}
													disabled={
														serviceType?.serviceId !==
														CallflowService.InboundVendorOrigination
													}
													value={value}
													onChange={(value) => onChange(value)}
												/>
											)}
										/>
										<Controller
											control={control}
											name="defaultScenarioId"
											render={({ field: { onChange, value } }) => (
												<ScenarioSelect
													label="Default Scenario"
													displayMode={displayMode}
													disabled={
														!(
															serviceType &&
															[
																CallflowService.InboundCustomerIdentification,
																CallflowService.InboundCustomerIdentificationClone,
																CallflowService.OutboundCustomerIdentification
															].includes(serviceType.serviceId)
														)
													}
													required={
														serviceType &&
														[
															CallflowService.InboundCustomerIdentification,
															CallflowService.InboundCustomerIdentificationClone
														].includes(serviceType.serviceId)
													}
													onChange={(defaultScenario) => {
														onChange(defaultScenario.scenarioId);
														setDefaultScenario(defaultScenario);
													}}
													value={defaultScenario}
													initialOptionId={
														!isNil(value) ? String(value) : undefined
													}
												/>
											)}
										/>
										<Controller
											control={control}
											name="translatedNumberStd"
											render={({ field: { onChange, value } }) => (
												<RtxUiPhoneNumberInput
													label="Translated Number"
													displayMode={displayMode}
													disabled={
														CallflowService.OutboundCustomerIdentification !==
														serviceType?.serviceId
													}
													value={value ?? ''}
													onChange={(value) => onChange(value)}
												/>
											)}
										/>
									</fieldset>
									<fieldset className="card card-body mb-0">
										<legend className="card-header">
											<h6>{SectionHeaders.directVendorTermination}</h6>
										</legend>
										<Controller
											control={control}
											name="directSubscriptionId"
											render={({ field: { onChange, value } }) => (
												<VendorTerminationSubscriptionSelect
													label="Direct Routing"
													isActive={1}
													displayMode={displayMode}
													isDisabled={
														!(
															serviceType &&
															[
																CallflowService.OutboundCustomerTermination,
																CallflowService.OutboundCustomerIdentification
															].includes(serviceType.serviceId)
														)
													}
													isClearable
													onChange={(vendorSubscription) => {
														setVendorSubscription(vendorSubscription);
														onChange(vendorSubscription?.subscriptionId);
													}}
													value={vendorSubscription}
													initialOptionId={value ?? undefined}
												/>
											)}
										/>
									</fieldset>
								</Col>
								<Col>
									<Accordion className="mb-4">
										<Accordion.Item eventKey="0">
											<Accordion.Header>
												<b>{SectionHeaders.billing}</b>
											</Accordion.Header>
											<Accordion.Body>
												<Row>
													<Col lg={6}>
														<Controller
															control={control}
															name="invoiceCycleGroupId"
															render={({ field: { onChange, value } }) => (
																<InvoiceCycleGroupSelect
																	label="Invoice Cycle Group"
																	displayMode={displayMode}
																	onChange={(
																		invoiceCycleGroup: InvoiceCycleGroup
																	) => {
																		setInvoiceCycleGroup(invoiceCycleGroup);
																		onChange(
																			invoiceCycleGroup.invoiceCycleGroupId
																		);
																	}}
																	value={invoiceCycleGroup}
																	initialOptionId={
																		value ? String(value) : undefined
																	}
																/>
															)}
														/>
														<Controller
															control={control}
															name="billDays"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={0}
																	required={Boolean(editMode)}
																	label="Bill Days"
																	displayMode={displayMode}
																	value={value}
																	onChange={onChange}
																/>
															)}
														/>
														<Controller
															control={control}
															name="disputeDays"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={0}
																	required={Boolean(editMode)}
																	label="Dispute Days"
																	displayMode={displayMode}
																	value={value}
																	onChange={onChange}
																/>
															)}
														/>
														<Controller
															control={control}
															name="rateKeyDeleteDays"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={0}
																	required={Boolean(editMode)}
																	label="Rate Key Delete Days"
																	displayMode={displayMode}
																	value={value}
																	onChange={onChange}
																/>
															)}
														/>
														<Controller
															control={control}
															name="rateKeyExpandIncreaseDays"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={0}
																	required={Boolean(editMode)}
																	label="Rate Key Expand Increase Days"
																	displayMode={displayMode}
																	value={value}
																	onChange={onChange}
																/>
															)}
														/>
														<Controller
															control={control}
															name="defaultMinimumSeconds"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={0}
																	label="Default Minimum Seconds"
																	displayMode={displayMode}
																	value={value}
																	onChange={onChange}
																/>
															)}
														/>
														<Controller
															control={control}
															name="defaultIncrementSeconds"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={0}
																	label="Default Increment Seconds"
																	displayMode={displayMode}
																	value={value}
																	onChange={onChange}
																/>
															)}
														/>
														<Controller
															control={control}
															name="defaultRoundingPrecision"
															render={({ field: { onChange, value = 0 } }) => (
																<InputFormControl
																	displayMode={displayMode}
																	label="Default Rounding Precision"
																	value={String(value)}
																	onChange={(value) => onChange(Number(value))}
																/>
															)}
														/>
													</Col>
													<Col lg={6}>
														<Controller
															control={control}
															name="timezoneId"
															render={({ field: { onChange, value } }) => (
																<TimezoneSelect
																	required={Boolean(editMode)}
																	displayMode={displayMode}
																	onChange={(
																		timezone: TimezoneIndexResponse
																	) => {
																		setTimezone(timezone);
																		onChange(timezone.timezoneId);
																	}}
																	value={timezone}
																	initialOptionId={String(value)}
																/>
															)}
														/>
														<Controller
															control={control}
															name="dueDays"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={0}
																	required={Boolean(editMode)}
																	label="Due Days"
																	displayMode={displayMode}
																	value={value}
																	onChange={onChange}
																/>
															)}
														/>
														<Controller
															control={control}
															name="disputeResponseDays"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={0}
																	required={Boolean(editMode)}
																	label="Dispute Response Days"
																	displayMode={displayMode}
																	value={value}
																	onChange={(value) => onChange(Number(value))}
																/>
															)}
														/>
														<Controller
															control={control}
															name="rateKeyMatchIncreaseDays"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={0}
																	required={Boolean(editMode)}
																	label="Rate Key Match Increase Days"
																	displayMode={displayMode}
																	value={value}
																	onChange={(value) => onChange(Number(value))}
																/>
															)}
														/>
														<Controller
															control={control}
															name="defaultInterstateRate"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={6}
																	label="Default Interstate Rate"
																	displayMode={displayMode}
																	value={value}
																	onChange={(value) => onChange(Number(value))}
																/>
															)}
														/>
														<Controller
															control={control}
															name="defaultIntrastateRate"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={6}
																	label="Default Intrastate Rate"
																	displayMode={displayMode}
																	value={value}
																	onChange={(value) => onChange(Number(value))}
																/>
															)}
														/>
														<Controller
															control={control}
															name="defaultIndeterminateRate"
															render={({ field: { onChange, value } }) => (
																<PrecisionFormControl
																	precision={6}
																	label="Default Indeterminate Rate"
																	displayMode={displayMode}
																	value={value}
																	onChange={(value) => onChange(Number(value))}
																/>
															)}
														/>
														<Controller
															control={control}
															name="ratingParametersPriority"
															render={({ field: { onChange, value } }) => (
																<RatingPrecedenceSelect<true>
																	multi
																	label="Rating Precedence"
																	displayMode={displayMode}
																	initialOptionId={value?.map((v) => String(v))}
																	value={ratingParametersPriority}
																	onChange={(ratingPrecedence) => {
																		setRatingParametersPriority(
																			ratingPrecedence
																		);
																		onChange(
																			ratingPrecedence.map((v) => v.value)
																		);
																	}}
																/>
															)}
														/>
													</Col>
												</Row>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Col>
							</>
						)}
					</Row>
				);
			}}
		</RtxUiForm>
	);
};
