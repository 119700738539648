/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ConnectionCreateRequest = {
    properties: {
        trunkGroupId: {
            type: 'string',
        },
        trunkGroupLabel: {
            type: 'string',
        },
        gatewayAddress: {
            type: 'string',
        },
        gatewayPort: {
            type: 'string',
        },
        peerAddress: {
            type: 'string',
        },
        peerPort: {
            type: 'string',
        },
        techPrefix: {
            type: 'string',
        },
        fileStreamIds: {
            type: 'Array',
            isRequired: true,
        },
        externalId: {
            type: 'string',
        },
        switchId: {
            type: 'number',
        },
        summary: {
            type: 'string',
        },
        isActive: {
            type: 'number',
        },
        carrierId: {
            type: 'string',
        },
        portLimit: {
            type: 'number',
        },
        cpsLimit: {
            type: 'number',
        },
        subscriptionId: {
            type: 'number',
        },
        externalAccountId: {
            type: 'string',
        },
        isImt: {
            type: 'number',
        },
        timezoneOverride: {
            type: 'string',
        },
        isPeering: {
            type: 'number',
        },
        trunkType: {
            type: 'GuardianConnectionTrunkType',
            isNullable: true,
        },
        trafficType: {
            type: 'TrafficTypes',
            isNullable: true,
        },
        attestationOverride: {
            type: 'Attestation',
            isNullable: true,
        },
        createParent: {
            type: 'boolean',
        },
    },
};