import { CprRateSheetIndexRequest, CprRateSheetIndexResponse } from 'RtModels';
import { useGetCprRateSheets } from 'RtUi/app/rtLco/RateSheets/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCprRateSheetColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/cprRateSheets';
import { useMemo } from 'react';
import { RateSheetRouter } from '../../RateSheet.router';

interface IRateSheetGridProps {
	resourceParams?: CprRateSheetIndexRequest;
}

export const RateSheetGrid = ({ resourceParams }: IRateSheetGridProps) => {
	const { data, isFetching: isLoading } = useGetCprRateSheets(resourceParams);
	const columns = useMemo(() => getCprRateSheetColumns(), []);

	return (
		<DataGrid<CprRateSheetIndexResponse>
			pageName="rtLco-cprRateSheets"
			loading={isLoading}
			data={data?.data}
			router={RateSheetRouter}
			totalRows={data?.totalCount}
			columns={columns}
		/>
	);
};
