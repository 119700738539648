import { isEqual } from 'lodash-es';
import {
	SelectFormControl,
	ISelectFormControlProps,
	ISelectFormControlState
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export interface ISimpleSelectFormControlOption<T> {
	label: string;
	value: T;
}

export interface ISimpleSelectFormControlProps<T, isMulti extends boolean>
	extends ISelectFormControlProps<ISimpleSelectFormControlOption<T>, isMulti> {
	label: string;
	options?: Array<ISimpleSelectFormControlOption<T>>;
	placeholder?: string;
}

export class SimpleSelectFormControl<
	T,
	isMulti extends boolean = false,
	SimpleSelectProps extends ISimpleSelectFormControlProps<
		T,
		isMulti
	> = ISimpleSelectFormControlProps<T, isMulti>
> extends SelectFormControl<
	ISimpleSelectFormControlOption<T>,
	isMulti,
	SimpleSelectProps
> {
	public resourceClass = undefined;
	public state: ISelectFormControlState<ISimpleSelectFormControlOption<T>> = {
		formLabel: '',
		valueKey: 'value',
		labelKey: 'label'
	};

	constructor(props: SimpleSelectProps) {
		super(props);

		this.updateOptions();
	}

	public componentDidUpdate(prevProps: SimpleSelectProps) {
		super.componentDidUpdate(prevProps);

		this.updatePlaceholder();

		if (!isEqual(prevProps.options, this.props.options)) {
			this.updateOptions();
			this.reloadOptionsOnFocus();
		}

		if (prevProps.initialOptionId !== this.props.initialOptionId) {
			this.checkForDefault();
		}
	}

	public componentDidMount() {
		super.componentDidMount();

		this.updatePlaceholder();
	}

	public updateOptions() {
		this.resource = new ArrayResource<ISimpleSelectFormControlOption<T>>(
			'value',
			this.props.options
		);
	}

	public updatePlaceholder() {
		const { placeholder } = this.props;

		if (this.state.placeholder !== placeholder) {
			this.setState({ placeholder });
		}
	}
}
