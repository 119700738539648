import { EmailAliasProfileResponse } from 'RtModels';
import { DistributionManagementRouter } from 'RtUi/app/Administration/DistributionManagement/DistributionManagement.router';
import { EmailAliasEditor } from 'RtUi/app/Administration/DistributionManagement/lib/forms/EmailAliasEditor';
import { EmailAliasResource } from 'RtUi/app/Administration/DistributionManagement/lib/resources/EmailAliasResource';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IDistributionManagementProfileContainerProps {}

interface IDistributionManagementProfileContainerState {
	activeTab: string;
	emailAlias?: EmailAliasProfileResponse;
}

@DistributionManagementRouter.getProfileRtUiController()
export class DistributionManagementProfileContainer extends ProfileApplicationContainer<
	IDistributionManagementProfileContainerProps,
	IDistributionManagementProfileContainerState
> {
	public Tabs = DistributionManagementRouter.getProfileTabs();

	public state: IDistributionManagementProfileContainerState = {
		activeTab: this.Tabs.Profile.header
	};

	public componentDidMount() {
		const aliasId = this.getIdParam();
		const emailAliasResource = new EmailAliasResource();

		emailAliasResource
			.get(aliasId)
			.then((emailAlias) => this.setState({ emailAlias }));
	}

	public render() {
		const { emailAlias } = this.state;

		if (!emailAlias) {
			return <Loading />;
		}

		const canManage = DistributionManagementRouter.canManageWith(emailAlias);

		return (
			<TabbedLayout<EmailAliasProfileResponse>
				router={DistributionManagementRouter}
				profile={emailAlias}
				canManageFn={() => canManage}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<EmailAliasEditor
						editMode={emailAlias}
						onUpdate={(emailAlias) => this.setState({ emailAlias })}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
