/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TimeFilterDetail = {
    properties: {
        key: {
            type: 'TimeFilterDetailKeys',
            isRequired: true,
        },
        value: {
            type: 'number',
            isRequired: true,
        },
    },
};