/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $PromptProfileRequest = {
    properties: {
        promptId: {
            type: 'number',
            isRequired: true,
        },
    },
};