import { FC, useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
	GuardianDoNotOriginateIndexRequest,
	GuardianDoNotOriginateIndexResponse
} from 'RtModels';
import { DoNotOriginateRouter } from 'RtUi/app/rtVue/DoNotOriginate/DoNotOriginate.router';
import { DoNotOriginateResource } from 'RtUi/app/rtVue/DoNotOriginate/lib/resources/DoNotOriginateResource';
import { useGetDoNotOriginate } from 'RtUi/app/rtVue/DoNotOriginate/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { doNotOriginateColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/doNotOriginate';
import { Confirmation } from 'RtUi/components/form/Confirmation';

interface IDoNotOriginateGridProps {
	header?: React.ReactNode;
	resourceParams?: GuardianDoNotOriginateIndexRequest;
	autoFocusFilter?: boolean;
}

export const DoNotOriginateGrid: FC<
	React.PropsWithChildren<IDoNotOriginateGridProps>
> = ({ resourceParams }: IDoNotOriginateGridProps) => {
	const { data, refetch, isLoading } = useGetDoNotOriginate(resourceParams);

	const [rowSelection, setRowSelection] = useState<
		GuardianDoNotOriginateIndexResponse[]
	>([]);

	const onClickBulkDeleteHandler = async () => {
		const selectedRows = rowSelection.map((d) => d.ani);
		const confirm = await Confirmation.createDelete(selectedRows.join(', '));

		if (!confirm) {
			return;
		}

		await new DoNotOriginateResource().bulkDelete(selectedRows);
		await refetch();
	};

	const removeEntity = useCallback(
		async (row: GuardianDoNotOriginateIndexResponse) => {
			const ani = row.ani;

			const confirm = await Confirmation.createDelete(ani);

			if (!confirm) {
				return;
			}

			await new DoNotOriginateResource().delete(ani);
		},
		[]
	);

	const columns = useMemo(
		() => doNotOriginateColumns(removeEntity),
		[removeEntity]
	);

	return (
		<>
			<DataGrid<GuardianDoNotOriginateIndexResponse>
				data={data?.data}
				columns={columns}
				pageName={'rtVue-doNotOriginate'}
				router={DoNotOriginateRouter}
				loading={isLoading}
				enableRowSelection
				selectedRows={rowSelection}
				onChangeSelectedRows={setRowSelection}
				totalRows={data?.totalCount}
				headerAction={() => (
					<OverlayTrigger
						overlay={(props) => (
							<Tooltip id={`deleteBulk-tooltip`} {...props}>
								Delete Selected
							</Tooltip>
						)}
					>
						{({ ref, ...triggerHandler }) => (
							<Button
								ref={ref}
								{...triggerHandler}
								variant="primary"
								onClick={onClickBulkDeleteHandler}
								id="deleteBulk"
								disabled={!rowSelection.length}
							>
								<i className="fa-solid fa-fw fa-trash fa-lg" />
							</Button>
						)}
					</OverlayTrigger>
				)}
			/>
			<Confirmation />
		</>
	);
};
