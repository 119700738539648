import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationAttachmentIndexRequest,
	GuardianReconciliationAttachmentIndexResponse,
	GuardianReconciliationAttachmentProfileUrlRequest,
	GuardianReconciliationIndexRequest,
	GuardianReconciliationIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleDeleteRequest,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import FileSaver from 'file-saver';
import { useQuery } from 'react-query';

const fetchGuardianReconciliations = async (
	urlParams?: GuardianReconciliationIndexRequest
): Promise<FullResponse<GuardianReconciliationIndexResponse[]>> => {
	return handleGetRequest<GuardianReconciliationIndexResponse[], true>(
		RtVueApiRoutes.GuardianReconciliations.Index,
		{
			urlParams,
			includeFullResponse: true
		}
	);
};

export const useGetGuardianReconciliations = (
	resourceParams?: GuardianReconciliationIndexRequest
) => {
	const cacheKey = resourceParams ? JSON.stringify(resourceParams) : '';

	return useQuery<FullResponse<GuardianReconciliationIndexResponse[]>, Error>(
		[`getGuardianReconciliations${cacheKey}`, cacheKey],
		() => fetchGuardianReconciliations(resourceParams),
		{ enabled: true }
	);
};

const fetchGuardianReconciliationsAttachment = async (
	queryParams?: GuardianReconciliationAttachmentIndexRequest
): Promise<FullResponse<GuardianReconciliationAttachmentIndexResponse[]>> => {
	return handleGetRequest<
		GuardianReconciliationAttachmentIndexResponse[],
		true
	>(RtVueApiRoutes.GuardianReconciliationAttachments.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetGuardianReconciliationAttachments = (
	queryParams?: GuardianReconciliationAttachmentIndexRequest
) => {
	return useQuery<
		FullResponse<GuardianReconciliationAttachmentIndexResponse[]>,
		Error
	>(
		[`getGuardianReconciliationsAttachment`, queryParams],
		() => fetchGuardianReconciliationsAttachment(queryParams),
		{ enabled: true }
	);
};

export const deleteGuardianReconciliationAttachment = (
	guardianReconciliationAttachmentId: number
) => {
	const urlParams: GuardianReconciliationAttachmentProfileUrlRequest = {
		guardianReconciliationAttachmentId
	};

	return handleDeleteRequest(
		RtVueApiRoutes.GuardianReconciliationAttachments.Delete,
		{
			urlParams
		}
	);
};

interface IDownloadGuardianReconciliationAttachmentParams {
	guardianReconciliationAttachmentId: number;
	fileName: string;
}

export const downloadGuardianReconciliationAttachment = async ({
	guardianReconciliationAttachmentId,
	fileName
}: IDownloadGuardianReconciliationAttachmentParams) => {
	const urlParams: GuardianReconciliationAttachmentProfileUrlRequest = {
		guardianReconciliationAttachmentId
	};
	const response = await handleGetRequest(
		RtVueApiRoutes.GuardianReconciliationAttachments.Profile,
		{ urlParams, responseType: 'blob' }
	);

	const url = URL.createObjectURL(new Blob([response.data]));

	return FileSaver.saveAs(url, fileName);
};
