/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateableJurisdictions {
    Interstate = 1,
    Intrastate = 2,
    Indeterminate = 3,
    Local = 4,
}