import { FC, useState } from 'react';
import { useMount } from 'react-use';
import { ServerGroupProfileResponse } from 'RtModels';
import { ServerGroupEditor } from 'RtUi/app/rtAdmin/ServerGroups/lib/forms/ServerGroupEditor';
import { ServerGroupResource } from 'RtUi/app/rtAdmin/ServerGroups/lib/resource/ServerGroupResource';
import { ServerGroupRouter } from 'RtUi/app/rtAdmin/ServerGroups/ServerGroup.router';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const ServerGroupProfileContainer: FC = () => {
	const entityId = useIdParam();
	const [tabs, activeTab, setActiveTab] = useTabs(
		ServerGroupRouter.getProfileTabs()
	);
	const [entity, setEntity] = useState<ServerGroupProfileResponse>();
	useMount(async () => {
		const resource = new ServerGroupResource();
		const newEntity = await resource.get(entityId);

		setEntity(newEntity);
	});

	const canManageWithRecord = ServerGroupRouter.canManageWith(entity);

	if (!entity) {
		return <Loading />;
	}

	return (
		<TabbedLayout<ServerGroupProfileResponse>
			router={ServerGroupRouter}
			profile={entity}
			canManageFn={() => canManageWithRecord}
			activeTab={activeTab.header}
			onTabChange={(at) => {
				setActiveTab(at);
			}}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<ServerGroupEditor editMode={entity} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ServerGroupProfileContainer.displayName = 'ServerGroupProfileContainer';

ServerGroupRouter.setProfileRtUiFunctionalComponent(
	ServerGroupProfileContainer
);
