/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum SubscriptionQosMatch {
    CurrentRateCenterOnly = 1,
    AllRateCentersOfSameTypeInCountry = 2,
    AllRateCentersInCountry = 3,
    AllRateCentersInAllCountries = 4,
}