import { CpsIndexResponseData } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCpsColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/cps';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
interface ICpsDataGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<CpsIndexResponseData> | null;
	isLoading?: boolean;
	callDateFormat?: string;
	onDrillDown: (row: CpsIndexResponseData) => void;
}

export const CpsDataGrid = ({
	resource,
	isLoading,
	callDateFormat,
	onDrillDown
}: ICpsDataGridProps) => {
	const { data } = useQuery<CpsIndexResponseData[] | undefined, Error>(
		[`useGetCpsReport`, resource],
		() => resource?.getAll()
	);
	const columns = useMemo(
		() => getCpsColumns(onDrillDown, callDateFormat),
		[onDrillDown, callDateFormat]
	);

	return (
		<DataGrid<CpsIndexResponseData>
			pageName="rtVue_cps"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
