/* eslint-disable max-classes-per-file */
import { CprStatus } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { MgiCprStatusLabels } from 'RtUi/utils/maps';

export interface ICprStatusOption {
	label: string;
	value: number;
}

export class CprStatusResource extends ArrayResource<ICprStatusOption> {
	constructor() {
		super('value');

		const options: ICprStatusOption[] = Object.keys(MgiCprStatusLabels).map(
			(statusKey) => {
				const cprStatus = Number(statusKey) as CprStatus;

				return {
					value: cprStatus,
					label: MgiCprStatusLabels[cprStatus]
				};
			}
		);

		options.sort((o1, o2) => o1.label.localeCompare(o2.label));

		this.setAllData(options);
	}
}

interface ICprStatusSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<ICprStatusOption, isMulti> {}

export class CprStatusSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	ICprStatusOption,
	isMulti,
	ICprStatusSelectProps<isMulti>
> {
	public resourceClass = CprStatusResource;
	public state: ISelectFormControlState<ICprStatusOption> = {
		formLabel: 'CPR Status',
		valueKey: 'value',
		labelKey: 'label'
	};
}
