import {
	CallDirectionPreset,
	DataFilterOperator,
	DataSources,
	FilterModesEnum,
	FilterReportsEnum,
	SearchField
} from 'RtModels';
import {
	IFilterSearchResultSection,
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { MemoryCacheStrategy } from 'RtUi/utils/cache/strategies/MemoryCacheStrategy';

type RtVueDirectionIndexResponse = {
	direction: string;
};

/**
 * @singleton
 */
export class RtVueDirectionFilter extends RtVueFilter<RtVueDirectionIndexResponse> {
	public static getInstance() {
		if (!RtVueDirectionFilter.instance) {
			RtVueDirectionFilter.instance = new RtVueDirectionFilter();
		}

		return RtVueDirectionFilter.instance;
	}

	private static instance: RtVueDirectionFilter | undefined;
	private static readonly FilterName = 'RtVueDirectionFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<RtVueDirectionIndexResponse>
	> = new MemoryCacheStrategy();

	protected constructor() {
		super(
			RtVueDirectionFilter.FilterName,
			DataSources.Text,
			RtVueDirectionFilter.DataCache
		);
	}

	public getLabelFor(record: RtVueDirectionIndexResponse): string {
		return '';
	}

	public getValueFor(record: RtVueDirectionIndexResponse): string {
		return '';
	}

	public async search(
		possibleSearchFields: SearchField[],
		searchStr: string
	): Promise<IFilterSearchResultSection[]> {
		const result: IFilterSearchResultSection = {
			commonName: 'Direction',
			results: []
		};

		if (searchStr.toLowerCase() === 'to') {
			result.results = [
				{
					dataSourceFields: [
						{
							key: 'direction',
							label: CallDirectionPreset.Ingress,
							direction: CallDirectionPreset.Either,
							minCharacters: 2,
							dataSources: [DataSources.Text],
							operator: DataFilterOperator.In
						}
					],
					operand: {
						dataSource: DataSources.Text,
						value: CallDirectionPreset.Ingress
					}
				}
			];

			return [result];
		}

		if (searchStr.toLowerCase() === 'from') {
			result.results = [
				{
					dataSourceFields: [
						{
							key: 'direction',
							label: CallDirectionPreset.Egress,
							direction: CallDirectionPreset.Either,
							minCharacters: 2,
							dataSources: [DataSources.Text],
							operator: DataFilterOperator.In
						}
					],
					operand: {
						dataSource: DataSources.Text,
						value: CallDirectionPreset.Egress
					}
				}
			];
		}

		return [result];
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<RtVueDirectionIndexResponse>
	> {
		return Promise.resolve({
			commonName: 'Direction',
			dataSource: DataSources.Text,
			mode: FilterModesEnum.Global,
			reportIncludes: FilterReportsEnum.All,
			data: [
				{
					direction: 'to'
				},
				{
					direction: 'from'
				}
			]
		});
	}

	protected getKeywordsFor(record: RtVueDirectionIndexResponse): string[] {
		return [];
	}
}
