import { RtCommonApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class LrnLookupRouterClass extends RtVueReportRouter<void, void, {}> {
	constructor() {
		super('Lrn Lookup', 'LrnLookup', undefined, {});

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.FileProcess);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const LrnLookupRouter = new LrnLookupRouterClass();
