import { TagIndexResponse } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class TagResource extends ArrayResource<TagIndexResponse> {
	constructor() {
		super('tag');

		this.setApiRouteForGetAll(RtxSipApiRoutes.Tags.Index);
	}
}
