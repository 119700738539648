import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn,
	FallbackHeaderDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { isNil } from 'lodash-es';

interface IdDataGridColumnConfiguration<T>
	extends FallbackHeaderDataGridColumnConfiguration<T> {}

export const IdDataGridColumn = <T = any,>({
	header = 'Id',
	align = DataGridColumnAlignment.RIGHT,
	...config
}: IdDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: any) => {
		if (isNil(value)) {
			return '';
		}

		return Number(value);
	};

	return DefaultDataGridColumn({
		...config,
		header,
		align,
		exportValue: (value: any) => `${getValue(value)}`,
		getValue: ({ cell }) => getValue(cell.getValue())
	});
};
