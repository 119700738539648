import { MessagesDetailIndexResponse } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getMessagesDetailColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/messagesDetail';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface IMessagesDetailDataGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<MessagesDetailIndexResponse> | null;
	isLoading?: boolean;
}

export const MessagesDetailDataGrid = ({
	resource,
	isLoading
}: IMessagesDetailDataGridProps) => {
	const { data = [] } = useQuery<
		MessagesDetailIndexResponse[] | undefined,
		Error
	>([`useGetMessagesDetailReport`, resource], () => resource?.getAll());

	const columns = useMemo(() => getMessagesDetailColumns(), []);

	return (
		<DataGrid<MessagesDetailIndexResponse>
			pageName="rtVue_messagesDetail"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
