/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum GuardianReconciliationMappingRecurringScheduleType {
    Weekly = 'weekly',
    Monthly = 'monthly',
    BiMonthly = 'bimonthly',
}