import { AosCol } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosCol';
import { AosNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class AosColState extends AosCol {

	public readonly cprNodeTypeId = undefined;
	public readonly aosNodeTypeId = AosNodeType.State;

	public readonly valueLimit = 46;
	public readonly valueRegExp = /^[A-Z]{2}$/;

	public readonly isLergNode: boolean = false;

	protected readonly possibles = CprLergCache.States;
	protected readonly possiblesByKey = CprLergCache.CprLergByState;

	protected readonly canadaPossibles = CprLergCache.CanadaStates;
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaStatesByKey;

	protected readonly canLabel = true;

}
