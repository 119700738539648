/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RatePlanRateIndexRequest = {
    properties: {
        rateKey: {
            properties: {
            },
            isNullable: true,
        },
        rateCenterId: {
            properties: {
            },
            isNullable: true,
        },
        iso3166Alpha3: {
            type: 'string',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        requestType: {
            type: 'number',
        },
    },
};