import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationChargeDetailIndexResponse,
	GuardianReconciliationChargeDetailProfileResponse,
	GuardianReconciliationChargeDetailCreateRequest,
	GuardianReconciliationChargeDetailUpdateRequest,
	GuardianReconciliationChargeDetailProfileUrlRequest,
	GuardianReconciliationChargeDetailBulkCreateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class ReconciliationChargesResource extends HttpResource<
	GuardianReconciliationChargeDetailIndexResponse,
	GuardianReconciliationChargeDetailProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianReconciliationChargeDetail);
	}

	public create(request: GuardianReconciliationChargeDetailCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianReconciliationChargeDetailProfileResponse>(
			RtVueApiRoutes.GuardianReconciliationChargeDetail.Create,
			{
				body
			}
		);
	}

	public update(
		guardianReconciliationChargeDetailId: number,
		request: GuardianReconciliationChargeDetailUpdateRequest
	) {
		const body = JSON.stringify(request);
		const urlParams: GuardianReconciliationChargeDetailProfileUrlRequest = {
			guardianReconciliationChargeDetailId
		};

		return this.fetchWithRoute<GuardianReconciliationChargeDetailProfileResponse>(
			RtVueApiRoutes.GuardianReconciliationChargeDetail.Update,
			{
				body,
				urlParams
			}
		);
	}

	public upload(request: GuardianReconciliationChargeDetailBulkCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<
			GuardianReconciliationChargeDetailProfileResponse[]
		>(RtVueApiRoutes.GuardianReconciliationChargeDetailBulk.Create, {
			body
		});
	}

	public createNewProfile(
		reconciliationId: number
	): GuardianReconciliationChargeDetailProfileResponse {
		return {
			reconciliationId,
			assignedTo: 0,
			chargeType: 1,
			carrierInvoiceCharge: null,
			carrierInvoiceChargeDetail: null,
			guardianReconciliationChargeDetailId: 1,
			recurring: 0,
			rtCharge: null,
			systemGenerated: 0,
			internalItemId: '',
			internalItemLabel: '',
			expectedCharge: 0,
			disputedAmount: 0,
			approvedAmount: 0,
			disputeStatus: 1,
			disputeId: '',
			disputeFiledTs: null,
			disputeSettlementTs: null,
			createdTs: null,
			updateTs: null,
			invoiceItemId: '',
			createdBy: 0,
			updatedBy: 0,
			disputeReceivedTs: null,
			escalationTs: null
		};
	}
}
