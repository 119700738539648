import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import {
	RoboscoreParameterIndexResponse,
	RoboscoreParameterProfileRequest
} from 'RtModels';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RtVueApiRoutes } from 'RtExports/routes';

interface IRoboscoringRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const RoboscoringContainerTabs: IRoboscoringRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class RoboscoringRouterClass extends RtVueModuleRouter<
	RoboscoreParameterIndexResponse,
	RoboscoreParameterProfileRequest,
	IRoboscoringRouterTabs
> {
	constructor() {
		super(
			'Roboscore Configuration',
			'Roboscoring',
			'paramId',
			RoboscoringContainerTabs
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.RoboscoreParameters);
	}

	public getProfileLabel(profile: RoboscoreParameterIndexResponse): string {
		return String(profile.parameterName);
	}
}

export const RoboscoringRouter = new RoboscoringRouterClass();
