import * as React from 'react';

interface ILoadingProps {
	internal?: boolean;
	message?: string;
	skipGrace?: boolean;
}

interface ILoadingState {
	graceCompleted: boolean;
}

export class Loading extends React.Component<ILoadingProps, ILoadingState> {
	public static readonly GRACE_IN_MS = 350;

	public state: ILoadingState = {
		graceCompleted: false
	};

	public isComponentMounted = false;

	constructor(props: ILoadingProps) {
		super(props);

		if (this.props.skipGrace) {
			this.state.graceCompleted = true;
		}
	}

	public componentDidMount() {
		this.isComponentMounted = true;

		if (!this.state.graceCompleted) {
			setTimeout(() => this.setGraceCompleted(), Loading.GRACE_IN_MS);
		}
	}

	public componentWillUnmount() {
		this.isComponentMounted = false;
	}

	public setGraceCompleted() {
		if (!this.isComponentMounted) {
			return;
		}

		this.setState({ graceCompleted: true });
	}

	public render() {
		const { graceCompleted } = this.state;

		if (!graceCompleted) {
			return null;
		}

		const { internal = false, message = 'Loading' } = this.props;
		const loadingElem = (
			<h2 className="text-primary">
				<i className="fas fa-fw fa-cog fa-spin" />
				<span>&nbsp;{message}</span>
			</h2>
		);

		if (internal) {
			return loadingElem;
		}

		return (
			<header className="page">
				<section className="subtleSlideInRight">{loadingElem}</section>
			</header>
		);
	}
}
