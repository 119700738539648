import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';

// exported definitions
// ======================================================================

export class CprLblNpaNxx extends CprLbl {

	public readonly cprNodeTypeId = CprNodeType.NpaNxx;

	public readonly valueLimit = 255;
	public readonly valueRegExp = /^\d{6}$/;

	protected readonly possibles = CprLergCache.NpaNxxs;
	protected readonly possiblesByKey = CprLergCache.CprLergByNpaNxx;

	// no canadaPossibles on purpose
	protected readonly canadaPossiblesByKey = CprLergCache.CanadaNpaNxxsByKey;

	protected readonly canLabel = true;
}
