import { SipConnection } from 'RtModels';
import { ConnectionRouter } from 'RtUi/app/rtSip/Connections/Connection.router';
import { SipConnectionResource } from 'RtUi/app/rtSip/Connections/lib/resources/SipConnectionResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface IConnectionSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<SipConnection, IsMulti> {}

export class ConnectionSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	SipConnection,
	IsMulti,
	IConnectionSelectProps<IsMulti>
> {
	public static defaultProps = {
		router: ConnectionRouter
	};
	public resourceClass = SipConnectionResource;
	public state: ISelectFormControlState<SipConnection> = {
		formLabel: 'Connection',
		valueKey: 'sipConnectionId',
		labelKey: 'label'
	};

	public optionRenderer = (connection: SipConnection) => {
		if (connection.sipConnectionId === 0) {
			return connection.sipConnectionId;
		}

		return (
			<span>
				{connection.hostAddress}:{connection.hostPort} (
				{connection.sipConnectionId})
			</span>
		);
	};
}
