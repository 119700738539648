/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocActionRequest = {
    properties: {
        entityId: {
            type: 'string',
        },
        respOrgId: {
            type: 'string',
        },
        rocTxnId: {
            type: 'string',
            isRequired: true,
        },
        rejectNote: {
            type: 'string',
        },
        rocActionId: {
            type: 'string',
            isRequired: true,
        },
        tfns: {
            type: 'Array',
            isRequired: true,
        },
    },
};