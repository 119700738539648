import * as React from 'react';
import {
	DirectConnectProfileResponse,
	SipConnection,
	UserIndexResponse,
	UserProfileResponse
} from 'RtModels';
import { ConnectionSelect } from 'RtUi/app/rtSip/DirectConnect/lib/controls/ConnectionSelect';
import { DirectConnectResource } from 'RtUi/app/rtSip/DirectConnect/lib/resources/DirectConnectResource';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';

interface IDirectConnectFormEditorProps {
	wide?: boolean;
	narrow?: boolean;
	profile?: DirectConnectProfileResponse;
	onSuccess?: (profile: DirectConnectProfileResponse) => void;
}

interface IDirectConnectFormEditorState {
	displayMode: boolean;
	isSubmitting: boolean;
	callerIdName?: string;
	callerIdNumber?: string;
	sipConnection?: SipConnection;
	user?: UserIndexResponse;
	sipConnectionId?: number;
	userId?: number;
	password?: string;
	error?: any;
}

export class DirectConnectFormEditor extends React.Component<
	IDirectConnectFormEditorProps,
	IDirectConnectFormEditorState
> {
	constructor(props: IDirectConnectFormEditorProps) {
		super(props);

		const editMode = Boolean(props.profile);

		this.state = {
			displayMode: editMode,
			isSubmitting: false,
			password: editMode ? props.profile?.secret : undefined,
			callerIdName: editMode ? props.profile?.callerIdName : 'Anonymous',
			callerIdNumber: editMode ? props.profile?.callerIdNumber : 'anonymous',
			sipConnectionId: editMode ? props.profile?.connectionId : undefined
		};
	}

	public render() {
		return (
			<RtForm
				wide={this.props.wide}
				narrow={this.props.narrow}
				createMode={!this.props.profile}
				onSubmit={this.createOrUpdate}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onChange={(displayMode) => this.setState({ displayMode })}
				onCancel={this.onCancel}
			>
				{this.props.profile && (
					<InputFormControl
						value={this.state.password}
						label="SIP Password"
						displayMode
					/>
				)}
				{!this.props.profile && (
					<UserSelect
						required
						value={this.state.user}
						onChange={(user: UserProfileResponse) => {
							this.setState({
								user,
								userId: user.userId
							});
						}}
						displayMode={this.state.displayMode}
						initialOptionId={
							this.state.userId ? this.state.userId.toString() : undefined
						}
					/>
				)}
				<InputFormControl
					required
					label="Caller-ID Name"
					displayMode={this.state.displayMode}
					onChange={(callerIdName) => {
						this.setState({ callerIdName });
					}}
					value={this.state.callerIdName}
				/>
				<InputFormControl
					required
					type="number"
					disableAutoFormat
					label="Caller-ID Number"
					displayMode={this.state.displayMode}
					onChange={(callerIdNumber) =>
						this.setState({ callerIdNumber: callerIdNumber })
					}
					value={this.state.callerIdNumber?.toString()}
				/>
				<ConnectionSelect
					required
					label="Connection"
					displayMode={this.state.displayMode}
					onChange={(sipConnection: SipConnection) => {
						this.setState({
							sipConnection,
							sipConnectionId: sipConnection.sipConnectionId
						});
					}}
					value={this.state.sipConnection}
					initialOptionId={
						this.state.sipConnectionId
							? this.state.sipConnectionId.toString()
							: undefined
					}
				/>
			</RtForm>
		);
	}

	private onCancel = () => {
		const { props } = this;
		const editMode = Boolean(props.profile);

		this.setState({
			displayMode: editMode,
			isSubmitting: false,
			password: editMode ? props.profile?.secret : undefined,
			callerIdName: editMode ? props.profile?.callerIdName : 'Anonymous',
			callerIdNumber: editMode ? props.profile?.callerIdNumber : 'anonymous',
			sipConnectionId: editMode ? props.profile?.connectionId : undefined,
			sipConnection: undefined,
			user: undefined
		});
	};

	private createOrUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const resource = new DirectConnectResource();
		let newProfile: DirectConnectProfileResponse;

		this.setState({ isSubmitting: true, error: undefined });
		const { onSuccess = () => ({}) } = this.props;

		try {
			if (this.props.profile) {
				newProfile = await resource.update(this.props.profile.directConnectId, {
					callerIdName: this.state.callerIdName!,
					callerIdNumber: this.state.callerIdNumber!,
					connectionId: this.state.sipConnectionId!,
					secret: ''
				});
				this.setState({ displayMode: true });
			} else {
				newProfile = await resource.create({
					userId: this.state.userId!,
					callerIdName: this.state.callerIdName!,
					callerIdNumber: this.state.callerIdNumber!,
					connectionId: this.state.sipConnectionId!
				});
			}

			this.setState({ displayMode: true });
			onSuccess(newProfile);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	};
}
