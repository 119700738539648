import { RoutingRuleContext } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleEditor';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { useContext } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

interface IRoutingRuleProfileEditorProps {
	displayMode?: boolean;
}

export const RoutingRuleProfileEditor = ({
	displayMode = false
}: IRoutingRuleProfileEditorProps): JSX.Element => {
	const { value, onChange } = useContext(RoutingRuleContext);

	return (
		<Card>
			<Card.Body>
				<Row>
					<Col className="d-flex gap-3">
						<Form.Label className="pt-2">Summary</Form.Label>
						<InputFormControl
							label=""
							hideLabel
							controlGroupClassName="flex-grow-1"
							displayMode={displayMode}
							onChange={(label) => onChange('label', label)}
							value={value?.label ?? ''}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex gap-2">
						<Form.Label className="pt-2">Is Active</Form.Label>
						<BooleanRadioFormControl
							label=""
							hideLabel
							displayMode={displayMode}
							onChange={(isActive) => onChange('isActive', isActive)}
							value={value?.isActive ?? 0}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex gap-2">
						<Form.Label className="pt-2">Is Cascade</Form.Label>
						<BooleanRadioFormControl
							label=""
							hideLabel
							displayMode={displayMode}
							onChange={(isCascade) => onChange('isCascade', isCascade)}
							value={value?.isCascade ?? 0}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex gap-2">
						<Form.Label className="pt-2">LCR Append</Form.Label>
						<BooleanRadioFormControl
							label=""
							hideLabel
							displayMode={displayMode}
							onChange={(isLcrAppend) => onChange('isLcrAppend', isLcrAppend)}
							value={value?.isLcrAppend ?? 0}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex gap-3">
						<Form.Label className="pt-2">Notes</Form.Label>
						<TextAreaFormControl
							label=""
							hideLabel
							hideFormGroup
							displayMode={displayMode}
							onChange={(notes) => onChange('notes', notes)}
							value={value?.notes ?? ''}
						/>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};
