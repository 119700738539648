import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationUsageDetailsIndexResponse,
	GuardianReconciliationUsageDetailsProfileResponse
} from 'RtModels';
import {
	GuardianReconciliationProfileContainerTabs,
	GuardianReconciliationsRouter
} from 'RtUi/app/rtVue/GuardianReconciliation/GuardianReconciliation.router';
import { GuardianReconciliationResource } from 'RtUi/app/rtVue/GuardianReconciliation/lib/resources/GuardianReconciliationResource';
import { IRouterBreadcrumb } from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';

interface IReconciliationUsageDetailsProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	UsageDetails: TabbedLayoutTabProps;
	Dispute: TabbedLayoutTabProps;
	Details: TabbedLayoutTabProps;
}

export const ReconciliationUsageDetailsProfileContainerTabs: IReconciliationUsageDetailsProfileContainerTabs =
	{
		UsageDetails: {
			header: 'Summary'
		},
		Dispute: {
			header: 'Dispute Info'
		},
		Details: {
			header: 'Details'
		}
	};

export class ReconciliationUsageRouterClass extends RtVueModuleRouter<
	GuardianReconciliationUsageDetailsIndexResponse,
	GuardianReconciliationUsageDetailsProfileResponse,
	IReconciliationUsageDetailsProfileContainerTabs
> {
	constructor() {
		super(
			'Reconciliation Usage',
			'reconciliationUsageDetails',
			'guardianReconciliationUsageDetailId',
			ReconciliationUsageDetailsProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(
			RtVueApiRoutes.GuardianReconciliationUsageDetails
		);
	}

	public async getBreadcrumbs(
		currentPath: string,
		profile?: GuardianReconciliationUsageDetailsProfileResponse
	): Promise<IRouterBreadcrumb[]> {
		const reconciliationId = this.getReconciliationIdFromCurrentPath();

		const resource = new GuardianReconciliationResource();
		const reconciliation = await resource.get(reconciliationId);
		const crumb = GuardianReconciliationsRouter.getProfileBreadcrumb(
			String(reconciliationId),
			reconciliation,
			false,
			GuardianReconciliationProfileContainerTabs.Usage.header
		);

		crumb.name = GuardianReconciliationProfileContainerTabs.Usage.header;

		const breadcrumbs = [
			this.getSectionBreadcrumb(),
			GuardianReconciliationsRouter.getIndexBreadcrumb(),
			GuardianReconciliationsRouter.getProfileBreadcrumb(
				String(reconciliationId),
				reconciliation,
				false,
				GuardianReconciliationProfileContainerTabs.Profile.header
			),
			crumb,
			this.getProfileBreadcrumb(
				String(profile?.guardianReconciliationUsageDetailId),
				profile,
				true
			)
		];

		return breadcrumbs;
	}

	public getProfileLabel(
		profile: GuardianReconciliationUsageDetailsProfileResponse
	) {
		return profile.guardianReconciliationUsageDetailId.toString();
	}

	public getProfileRouteWithIds(
		guardianReconciliationId: number | string = ':guardianReconciliationId',
		usageId: number | string = ':id',
		tabId?: string,
		prefixSectionKey = true
	) {
		const route = super.getIndexRoute(prefixSectionKey);

		return this.getRouteWithTab(
			`${route}/${guardianReconciliationId}/${usageId}`,
			tabId
		);
	}

	public getProfileRoute(
		record: GuardianReconciliationUsageDetailsIndexResponse,
		tabId?: string,
		prefixSectionKey = true
	) {
		return this.getProfileRouteWithIds(
			record.reconciliationId,
			record.guardianReconciliationUsageDetailId,
			tabId,
			prefixSectionKey
		);
	}

	protected getReconciliationIdFromCurrentPath() {
		const currentPath = location.pathname;
		const profilePath = this.getProfileMatch<{
			id: string;
			guardianReconciliationId: string;
		}>(currentPath);
		let guardianReconciliationId: number | undefined;

		if (profilePath) {
			const { guardianReconciliationId: guardianReconciliationIdStr } =
				profilePath.params;
			guardianReconciliationId = Number(guardianReconciliationIdStr);
		}

		return guardianReconciliationId;
	}
}

export const ReconciliationUsageRouter = new ReconciliationUsageRouterClass();
