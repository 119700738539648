import { LrnProfileResponse } from 'RtModels';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCallSimulatorAniLrnColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/callSimulator';
import { useMemo } from 'react';

interface ICallSimulatorAniLrnGrid {
	data?: LrnProfileResponse[];
}

export const CallSimulatorAniLrnGrid = ({ data }: ICallSimulatorAniLrnGrid) => {
	const columns = useMemo(() => getCallSimulatorAniLrnColumns(), []);

	return (
		<DataGrid<LrnProfileResponse>
			data={data}
			columns={columns}
			pageName={'rt800_callSimulatorAniLrn'}
		/>
	);
};
