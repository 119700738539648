import { DataFilter } from 'RtModels';
import { CriteriaOptions } from 'RtUi/app/rtVue/RerateControl/lib/controls/CriteriaControl';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface RerateControlQueryDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const RerateControlQueryDataGridColumColumn = <T = any,>({
	...config
}: RerateControlQueryDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (row: { filters: DataFilter[] }) => {
		const { filters } = row;
		return (
			<ul>
				{filters.map((filter, i) => (
					<li key={`${filter}_${i}`}>
						{`${
							CriteriaOptions.find((f) => f.value === filter.key)?.label ||
							filter.key
						}: ${filter.operands.map((v) => v.value).join(',')}`}
					</li>
				))}
			</ul>
		);
	};

	const getExportValue = (row: { filters: DataFilter[] }) => {
		const query: { filters: DataFilter[] } = row;
		const { filters } = query;

		return filters
			.map((filter) => {
				const value = filter.operands.map((v) => v.value).join(',');

				if (!value) {
					return '';
				}

				const label =
					CriteriaOptions.find((f) => f.value === filter.key)?.label ??
					filter.key;

				return `${label}: ${value}`;
			})
			.join('\n');
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: any) => getExportValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<any>())
	});
};
