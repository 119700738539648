import * as FileSaver from 'file-saver';
import { ISpreadSheetWorker, ITabbedSpreadsheet } from './interfaces';
import { RtWebWorkerFacade } from 'RtUi/workers/RtWebWorkerFacade';

/**
 * SpreadsheetWebWorkerFacade uses Spreadsheet.worker to do XLSX/spreadsheet work
 * @singleton
 */
export class SpreadsheetWebWorkerFacade extends RtWebWorkerFacade<ISpreadSheetWorker> {
	/**
	 * Get singleton instance
	 */
	public static getInstance() {
		if (!SpreadsheetWebWorkerFacade.instance) {
			SpreadsheetWebWorkerFacade.instance = new SpreadsheetWebWorkerFacade(
				'Spreadsheet'
			);
		}

		return SpreadsheetWebWorkerFacade.instance;
	}

	//singleton instance
	private static instance: SpreadsheetWebWorkerFacade;

	public fileToTabbedSpreadsheet<
		T extends ITabbedSpreadsheet = ITabbedSpreadsheet
	>(file: File): Promise<T> {
		return this.postMessageToWebWorker('fileToTabbedSpreadsheet', [file]);
	}

	public fileToSpreadsheet(
		file: File,
		shouldHaveEmptyHeader = false
	): Promise<string[][]> {
		return this.postMessageToWebWorker('fileToSpreadsheet', [
			file,
			shouldHaveEmptyHeader
		]);
	}

	public csvToArray(
		file: File,
		shouldHaveEmptyHeader = false
	): Promise<string[][]> {
		return this.postMessageToWebWorker('csvToArray', [
			file,
			shouldHaveEmptyHeader
		]);
	}

	public dataToCSVString(
		data: Array<Record<string, string | number>>,
		fieldSeparator?: string
	): Promise<string> {
		return this.postMessageToWebWorker('dataToCSVString', [
			data,
			fieldSeparator
		]);
	}

	public async saveSpreadsheet(
		tabbedSpreadsheet: ITabbedSpreadsheet,
		fileName: string | undefined
	): Promise<Blob> {
		const spreadsheetStr = await this.postMessageToWebWorker(
			'exportToWorkbook',
			[tabbedSpreadsheet]
		);

		const blob = new Blob([spreadsheetStr], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		});

		FileSaver.saveAs(blob, fileName);

		return blob;
	}
}
