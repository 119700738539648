import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';

interface RatePlanDefaultsProps {
	isLoading: boolean;
	onSubmit: (data: any) => void;
}

export const RatePlanDefaultsCreateForm = (props: RatePlanDefaultsProps) => {
	const { isLoading, onSubmit } = props;

	const { control, handleSubmit } = useForm({
		defaultValues: {
			startTs: moment(new Date()),
			ratedIso: '',
			ratedExt: '',
			jurisIso: '',
			jurisExt: '',
			altRatePlanId: '',
			inGraceDur: '',
			egGraceDur: '',
			conFee: '',
			interRate: '',
			intraRate: '',
			indetRate: '',
			localRate: '',
			durMin: '',
			durInc: ''
		}
	});
	return (
		<RtForm
			narrow={true}
			isSubmitting={isLoading}
			displayMode={false}
			onSubmit={handleSubmit(onSubmit)}
			createMode={true}
			hasCreateButton={true}
			hasDeleteButton={true}
		>
			<Controller
				control={control}
				name="startTs"
				render={({ field: { onChange, value } }) => (
					<DatetimeFormControl
						label="Start time"
						value={value ? moment(value) : moment(new Date())}
						onChange={onChange}
						name="startTs"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="ratedIso"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Rate Iso"
						onChange={onChange}
						value={String(value)}
						name="ratedIso"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="ratedExt"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Rated Ext"
						onChange={onChange}
						value={String(value)}
						name="ratedExt"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="jurisIso"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Juris Iso"
						onChange={onChange}
						value={String(value)}
						name="jurisIso"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="jurisExt"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Juris Ext"
						onChange={onChange}
						value={String(value)}
						name="jurisExt"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="altRatePlanId"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Alt Rate Plan id"
						onChange={onChange}
						value={String(value)}
						name="altRatePlanId"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="inGraceDur"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						type="number"
						label="In grace duration"
						onChange={onChange}
						value={String(value)}
						name="inGraceDur"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="egGraceDur"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						type="number"
						label="Eg grace duration"
						onChange={onChange}
						value={String(value)}
						name="egGraceDur"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="conFee"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						type="number"
						label="Connection surcharge"
						onChange={onChange}
						value={String(value)}
						name="conFee"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="interRate"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						type="number"
						label="Inter Rate"
						onChange={onChange}
						value={String(value)}
						name="interRate"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="intraRate"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						type="number"
						label="Intrastate Rate"
						onChange={onChange}
						value={String(value)}
						name="intraRate"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="indetRate"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						type="number"
						label="Indeterminate Rate"
						onChange={onChange}
						value={String(value)}
						name="indetRate"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="localRate"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						type="number"
						label="Local Rate"
						onChange={onChange}
						value={String(value)}
						name="localRate"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="durMin"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						type="number"
						label="Duration minimum"
						onChange={onChange}
						value={String(value)}
						name="durMin"
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="durInc"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						type="number"
						label="Duration increment"
						onChange={onChange}
						value={String(value)}
						name="durInc"
						required
					/>
				)}
			/>
		</RtForm>
	);
};
