import { TollFreeIndexRequest, TollFreeIndexResponse } from 'RtModels';
import {
	ApplicationNotificationTypes,
	ApplicationNotifications,
	INotification
} from 'RtUi/app/ApplicationShell/lib/components/ApplicationNotifications';
import { TollFreesExportResource } from 'RtUi/app/rt800/Numbers/lib/resources/TollFreesExportResource';
import {
	fetchNumbers,
	useGetNumbers
} from 'RtUi/app/rt800/Numbers/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { TollFreeNumbersCopyButton } from 'RtUi/components/data/DataGrid/components/TollFreeNumbersCopyButton';
import { getNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/numbers';
import { useMemo } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NumberRouter } from '../../Number.router';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';

interface INumberGridProps {
	resourceParams: TollFreeIndexRequest;
}

export const NumberGrid = ({ resourceParams }: INumberGridProps) => {
	const { data, isFetching: isLoading } = useGetNumbers(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportTollFreeNumbers', resourceParams],
		resourceParams,
		(params) => fetchNumbers(params)
	);
	const columns = useMemo(() => getNumbersColumns(), []);

	const exportToEmail = async () => {
		const exportResource = new TollFreesExportResource();

		try {
			await exportResource.getAll(resourceParams);
			const notification: INotification = {
				message: 'A link to your report will be emailed to you when complete.',
				title: 'Export will be emailed',
				notificationId: `Toll-Free-Numbers-Export-${Math.random()}`,
				notificationTypeId: ApplicationNotificationTypes.Info,
				effectiveTs: new Date(),
				expirationTs: new Date(),
				isUrgent: 0
			};

			ApplicationNotifications.openNotification(notification, {
				autoClose: 10_000
			});
		} catch (e) {}
	};

	return (
		<DataGrid<TollFreeIndexResponse>
			data={data?.data}
			router={NumberRouter}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			enableExternalExport
			getExternalExportData={fullResultsReq}
			pageName={'rt800_Numbers'}
			headerAction={(table) => (
				<>
					<TollFreeNumbersCopyButton table={table} numberKey="tfn" />
					<OverlayTrigger
						overlay={(props) => (
							<Tooltip id="export-tooltip" {...props}>
								Export to Email
							</Tooltip>
						)}
					>
						{({ ref, ...triggerHandler }) => (
							<Button
								ref={ref}
								{...triggerHandler}
								variant="white"
								id="export"
								onClick={() => exportToEmail()}
								disabled={isLoading}
							>
								<i className="fas fa-envelope fa-lg" />
							</Button>
						)}
					</OverlayTrigger>
				</>
			)}
		/>
	);
};
