import { Component } from 'react';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Col, Row, Form } from 'react-bootstrap';

interface IMinMaxControlProps {
	onChange: (amount: number, isGreater: boolean) => void;
	minAmount?: number;
	maxAmount?: number;
	minAmountWarning?: string;
	maxAmountWarning?: string;
}

export class MinMaxControl extends Component<IMinMaxControlProps, {}> {
	public render() {
		return (
			<Row>
				<Col md={6}>
					<Form.Label>Min Amount</Form.Label>
					<InputFormControl
						label="Min Amount"
						onChange={(gteAmount) =>
							this.props.onChange(Number(gteAmount), true)
						}
						value={String(this.props.minAmount)}
						type="number"
						formTextDanger={this.props.minAmountWarning}
						max={this.props.maxAmount}
						min={0}
						removeNonNumericCharacters
						useControlGroup={false}
					/>
				</Col>
				<Col md={6}>
					<Form.Label>Max Amount</Form.Label>
					<InputFormControl
						label="Max Amount"
						onChange={(lteAmount) =>
							this.props.onChange(Number(lteAmount), false)
						}
						value={String(this.props.maxAmount)}
						type="number"
						formTextDanger={this.props.maxAmountWarning}
						min={this.props.minAmount}
						removeNonNumericCharacters
						useControlGroup={false}
					/>
				</Col>
			</Row>
		);
	}
}
