import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';

export interface IVariableOption {
	value: string;
}

interface IVariableSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<IVariableOption, isMulti> {}

const VariableOptions: IVariableOption[] = [
	{ value: '%_cdr.dialed_number_std_%' },
	{ value: '%_cdr.translated_number_std_%' }
];

export class VariableSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	IVariableOption,
	isMulti,
	IVariableSelectProps<isMulti>
> {
	public static isVariable(possibleVariable: string) {
		return VariableOptions.some(
			(variableOption) => variableOption.value === possibleVariable
		);
	}

	public resourceClass = undefined;
	public state: ISelectFormControlState<IVariableOption> = {
		formLabel: 'Variable',
		valueKey: 'value',
		labelKey: 'value'
	};

	public constructor(props: IVariableSelectProps<isMulti>) {
		super(props);

		this.resource = new ArrayResource<IVariableOption>('value', [
			...VariableOptions
		]);
	}

	/**
	 * Make sure custom inputs are all numeric
	 * @override
	 * @param inputValue
	 * @param value
	 * @param options
	 */
	public isValidNewOption(inputValue: string) {
		const phoneFormatter = new PhoneNumberFormatter();
		const isVariable = VariableSelect.isVariable(inputValue);

		if (isVariable) {
			return true;
		}

		const isValid = phoneFormatter.isValid(inputValue);

		return isValid;
	}
}
