import { DataSources, SipCodeIndexResponse } from 'RtModels';
import {
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { IndexedDbCacheStrategy } from 'RtUi/utils/cache/strategies/IndexedDbCacheStrategy';

/**
 * @singleton
 */
export class RtVueSipFilter extends RtVueFilter<SipCodeIndexResponse> {
	public static getInstance() {
		if (!RtVueSipFilter.instance) {
			RtVueSipFilter.instance = new RtVueSipFilter();
		}

		return RtVueSipFilter.instance;
	}

	private static instance: RtVueSipFilter | undefined;
	private static readonly FilterName = 'RtVueSipFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<SipCodeIndexResponse>
	> = new IndexedDbCacheStrategy(RtVueSipFilter.FilterName, 'code', [
		'label',
		'*keywords' as keyof SipCodeIndexResponse
	]);

	protected constructor() {
		super(
			RtVueSipFilter.FilterName,
			DataSources.SipCodes,
			RtVueSipFilter.DataCache
		);
	}

	public getLabelFor(record: SipCodeIndexResponse): string {
		if (String(record.code) === record.label) {
			return record.label;
		}

		return `${record.label} (${record.code})`;
	}

	public getValueFor(record: SipCodeIndexResponse): string {
		return String(record.code);
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<SipCodeIndexResponse>
	> {
		return this.vueHttp.getSIPFilter();
	}

	protected getKeywordsFor(record: SipCodeIndexResponse): string[] {
		const keywords = this.getKeywordsFromPhrase(record.label);

		keywords.push(String(record.code));

		return keywords;
	}
}
