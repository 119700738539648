import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationMappingFiltersIndexRequest,
	GuardianReconciliationMappingFiltersIndexResponse
} from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchReconciliationMappingFilters = async (
	queryParams: GuardianReconciliationMappingFiltersIndexRequest
): Promise<GuardianReconciliationMappingFiltersIndexResponse[]> => {
	return handleGetRequest(
		RtVueApiRoutes.GuardianReconciliationMappingFilters.Index,
		{
			queryParams
		}
	);
};

export const useGetReconciliationMappingFilters = (
	queryParams: GuardianReconciliationMappingFiltersIndexRequest = {}
) => {
	const cacheKey = JSON.stringify(queryParams);

	return useQuery<GuardianReconciliationMappingFiltersIndexResponse[], Error>(
		[`getReconciliationMappingFilters`, cacheKey],
		() => {
			return fetchReconciliationMappingFilters(queryParams);
		}
	);
};
