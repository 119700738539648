import { CarrierRatesParser } from 'RtUi/app/rtLco/Carriers/lib/bin/CarrierRatesParser';
import { ICarrierRatesParseConfig } from 'RtUi/app/rtLco/Carriers/lib/bin/interfaces';
import {
	ISpreadsheetParseResult,
	ISpreadsheetParserHeaderMatch
} from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParser';
import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';
import { LcrRateSheetType } from 'RtModels';

export class CarrierRatesNpaNxxParser extends CarrierRatesParser {
	public readonly NpaColumn = this.createParserColumn('NPA', [
		'npa',
		'areacode',
		'area code'
	]).setIsRequired(true);
	public readonly NxxColumn = this.createParserColumn('NXX', [
		'nxx',
		'exchange'
	]).setIsRequired(true);
	public readonly NpaNxxColumn = this.createParserColumn('NPANXX', ['npanxx']);

	public readonly RequiredColumns = [
		this.NpaColumn,
		this.NxxColumn,
		this.InterRateColumn
	];

	private isInNpaNxxConfiguration = false;

	constructor(config: ICarrierRatesParseConfig) {
		super(LcrRateSheetType.NpaNxx, config);
	}

	public getRateKey1Index(): number {
		if (this.isInNpaNxxConfiguration) {
			return this.getColumnIndexFor(this.NpaNxxColumn);
		}

		return this.getColumnIndexFor(this.NpaColumn);
	}

	public getRateKey2Index(): number {
		if (this.isInNpaNxxConfiguration) {
			return this.getColumnIndexFor(this.NpaNxxColumn);
		}

		return this.getColumnIndexFor(this.NxxColumn);
	}

	/**
	 * @override
	 */
	public getRateKey1ForRow(row: ISpreadsheetParseResult): string {
		const rateKey1 = super.getRateKey1ForRow(row);

		if (this.isInNpaNxxConfiguration) {
			//get npa from npanxx value
			return rateKey1.substr(0, 3);
		}

		return rateKey1;
	}

	/**
	 * @override
	 */
	public getRateKey2ForRow(row: ISpreadsheetParseResult): string {
		const rateKey1 = super.getRateKey2ForRow(row);

		if (this.isInNpaNxxConfiguration) {
			//get nxx from npanxx value
			return rateKey1.substr(3, 3);
		}

		return rateKey1;
	}

	/**
	 * @override
	 */
	public getRequiredAndOptionalColumns(): SpreadsheetParserColumn[] {
		const baseRequiredAndOptionalColumns =
			super.getRequiredAndOptionalColumns();

		return [
			this.NpaNxxColumn.clone(), //assure it gets searched first
			...baseRequiredAndOptionalColumns
		];
	}

	/**
	 * @override
	 */
	public setHeaderMatch(headerMatch: ISpreadsheetParserHeaderMatch) {
		this.isInNpaNxxConfiguration = this.testNpaNxxColumn(headerMatch);

		super.setHeaderMatch(headerMatch);
	}

	/**
	 * @override
	 * @param headerMatch
	 */
	public testHeaderMatch(headerMatch: ISpreadsheetParserHeaderMatch) {
		const hasRequiredColumns = super.testHeaderMatch(headerMatch);

		if (hasRequiredColumns) {
			return true;
		}

		return this.testNpaNxxColumn(headerMatch);
	}

	private testNpaNxxColumn(headerMatch: ISpreadsheetParserHeaderMatch) {
		const { columnsFound } = headerMatch;
		const columnsFoundNames = columnsFound.map((columnFound) =>
			columnFound.getColumnName()
		);
		const requiredNpaNxxColumns = [
			this.NpaNxxColumn.clone(),
			this.InterRateColumn.clone()
		];

		if (!this.config.autofillIntraWithBestMatch) {
			requiredNpaNxxColumns.push(this.IntraRateColumn.clone());
		}

		if (!this.config.autofillIndetWithBestMatch) {
			requiredNpaNxxColumns.push(this.IndetRateColumn.clone());
		}

		const requiredColumnNames = requiredNpaNxxColumns.map(
			(requiredNpaNxxColumn) => requiredNpaNxxColumn.getColumnName()
		);

		return requiredColumnNames.every((requiredColumnName) =>
			columnsFoundNames.includes(requiredColumnName)
		);
	}
}
