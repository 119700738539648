/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianSubscriptionCicIndexResponse = {
    properties: {
        guardianSubscriptionCicId: {
            type: 'number',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        cic: {
            type: 'string',
            isRequired: true,
        },
        lcoCarrierId: {
            type: 'number',
            isRequired: true,
        },
        priority: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        createdBy: {
            type: 'number',
            isRequired: true,
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};