/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LumenApiResourceConfigurationExtraParams = {
    properties: {
        billingTelephoneNumber: {
            type: 'string',
            isRequired: true,
        },
        routeName: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        allowedRespOrgIds: {
            type: 'Array',
            isRequired: true,
        },
        areaOfService: {
            type: 'string',
            isRequired: true,
        },
        emailAddress: {
            type: 'string',
            isRequired: true,
        },
        customerNumber: {
            type: 'string',
            isRequired: true,
        },
        billingAccountNumber: {
            type: 'string',
            isRequired: true,
        },
        didProductId: {
            properties: {
            },
            isRequired: true,
        },
        didCustomerServiceName: {
            type: 'string',
            isRequired: true,
        },
    },
};