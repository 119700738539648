import { SipAgentIndexResponse } from 'RtModels';
import { SipAgentsResource } from 'RtUi/app/AccountManagement/SipAgents/lib/resources/SipAgentsResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface SipAgentDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		SipAgentIndexResponse,
		T
	> {}

export const SipAgentDataGridCache =
	new ResourceCacheMap<SipAgentIndexResponse>(
		new SipAgentsResource(),
		'sipAgentId',
		'label'
	);

export const SipAgentDataGridColumn = <T = any,>(
	config: SipAgentDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: SipAgentDataGridCache,
		...config
	});
};
