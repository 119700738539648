import clsx from 'clsx';
import { cloneDeep, isEqual } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DataFilter, DataFilterOperator, DataSources } from 'RtModels';
import { ParametersFilteringControl } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/ParametersFilteringControl';

interface IParametersFilteringControlList {
	allowedDataFilters: string[] | null;
	displayMode?: boolean;
	alertRules: DataFilter[] | undefined;
	onChange: (alertRules: DataFilter[]) => void;
}

const filterTemplate: DataFilter = {
	key: '',
	operator: DataFilterOperator.In,
	operands: [
		{
			dataSource: DataSources.Text,
			value: ''
		}
	]
};

export const ParametersFilteringControlList: FC<
	React.PropsWithChildren<IParametersFilteringControlList>
> = ({ alertRules, displayMode, allowedDataFilters, onChange }) => {
	const [rules, setRules] = useState<DataFilter[]>([]);

	useEffect(() => {
		if (alertRules && !isEqual(alertRules, rules)) {
			setRules(alertRules ?? []);
		}
	}, [alertRules, rules]);

	const onChangeHandler = (filterValue: DataFilter, i: number) => {
		setRules((currentState) => {
			const copyRules = currentState.map((rule) => cloneDeep(rule));
			copyRules.splice(i, 1, filterValue);
			onChange(copyRules);

			return copyRules;
		});
	};

	const onClickHandler = (i: number) => {
		setRules((currentState) => {
			const copyRules = currentState.map((rule) => cloneDeep(rule));
			copyRules.splice(i, 1);
			onChange(copyRules);

			return copyRules;
		});
	};

	return (
		<>
			<hr />
			{rules.map((parameterFilter, i) => {
				return (
					<div key={`${parameterFilter.key}_${i}`} className="d-flex">
						<ParametersFilteringControl
							className="flex-fill"
							hideLabel={i !== 0}
							dataFilters={allowedDataFilters ?? undefined}
							value={parameterFilter}
							onChange={(filterValue) => onChangeHandler(filterValue, i)}
							isDisabled={displayMode}
						/>
						<Button
							title="Delete Criteria"
							aria-label="Delete Criteria"
							variant="outline-danger"
							size="sm"
							className={clsx('mb-3 ms-2 align-self-end p-2', {
								invisible: displayMode
							})}
							onClick={() => onClickHandler(i)}
						>
							<i className="fas fa-fw fa-times" />
						</Button>
					</div>
				);
			})}
			{!displayMode && (
				<div className="d-flex mb-3">
					<Button
						size="sm"
						variant="outline-primary"
						className="w-25"
						onClick={() => {
							const copyRules = [...rules, cloneDeep(filterTemplate)];
							setRules(copyRules);
							onChange(copyRules);
						}}
					>
						+ Add Criteria
					</Button>
				</div>
			)}
			<hr />
		</>
	);
};
