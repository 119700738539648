import { RespOrgIndexRequest, RespOrgIndexResponse } from 'RtModels';
import {
	fetchRespOrgs,
	useGetRespOrgs
} from 'RtUi/app/rt800/RespOrgs/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRespOrgColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/respOrgs';
import { useMemo } from 'react';
import { RespOrgRouter } from '../../RespOrg.router';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';

interface IRespOrgGridProps {
	onRowClick?: (row: RespOrgIndexResponse) => void;
	resourceParams: RespOrgIndexRequest;
}

export const RespOrgGrid = ({
	resourceParams,
	onRowClick
}: IRespOrgGridProps) => {
	const { data, isFetching: isLoading } = useGetRespOrgs(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportRespOrg', resourceParams],
		resourceParams,
		(params) => fetchRespOrgs(params)
	);
	const columns = useMemo(() => getRespOrgColumns(), []);

	return (
		<DataGrid<RespOrgIndexResponse>
			router={RespOrgRouter}
			data={data?.data}
			columns={columns}
			enableExternalExport
			getExternalExportData={fullResultsReq}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rt800_respOrgs'}
			onRowClick={onRowClick}
		/>
	);
};
