import * as React from 'react';
import * as d3Selection from 'd3-selection';
// @ts-expect-error
import * as venn from 'venn.js';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import ContentLoader from 'react-content-loader';
import { isEqual } from 'lodash-es';
import { createRoot } from 'react-dom/client';

export interface IVennSet {
	sets: string[];
	size: number;
	metric: number;
}

interface IVennProps<VennDataType extends IVennSet = IVennSet> {
	sets: VennDataType[];
	selectedSets?: string[];
	isLoading?: boolean;
	onMouseOver?: (data: VennDataType) => void;
	onMouseOut?: (data: VennDataType) => void;
	onClick?: (data: VennDataType) => void;
}

export class Venn<
	VennDataType extends IVennSet = IVennSet
> extends React.Component<IVennProps<VennDataType>> {
	public guid = generateUUID();
	public instanceClassName = `venn-${this.guid}`;
	public vennArticle:
		| d3Selection.Selection<d3Selection.BaseType, IVennSet[], HTMLElement, void>
		| undefined;
	public height = 375;
	public width = 400;

	private readonly dropShadowId = `venn-${this.guid}-drop-shadow`;

	public componentDidMount() {
		this.updateDatum();
		this.updateSelectedAreas();
	}

	public componentDidUpdate(prevProps: IVennProps<VennDataType>) {
		const setsChanged = !isEqual(this.props.sets, prevProps.sets);
		const loadingChanged = this.props.isLoading !== prevProps.isLoading;

		if (setsChanged || loadingChanged) {
			this.updateDatum();
		}

		if (!isEqual(this.props.selectedSets, prevProps.selectedSets)) {
			this.updateSelectedAreas();
		}
	}

	public getFilters() {
		return (
			<filter id={this.dropShadowId} height="130%">
				<feGaussianBlur
					in="SourceAlpha"
					stdDeviation="5"
					result="blur"
				></feGaussianBlur>
				<feOffset in="blur" dx="1" dy="4" result="offsetBlur"></feOffset>
				<feMerge>
					<feMergeNode in="offsetBlur"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
			</filter>
		);
	}

	public updateSelectedAreas() {
		const { selectedSets: selectedSetNames = [] } = this.props;
		const vennAreaNodes = this.vennArticle?.selectAll('.venn-area').nodes();
		let selectedBaseType: d3Selection.BaseType | undefined;
		let selectedData: IVennSet | undefined;

		if (vennAreaNodes) {
			for (const vennAreaNode of vennAreaNodes) {
				const vennAreaNodeSelection = d3Selection.select(vennAreaNode);
				const data: IVennSet = vennAreaNodeSelection.data()[0] as IVennSet;

				this.removeHighlightForBaseType(vennAreaNode, data);

				if (data.sets.length === selectedSetNames.length) {
					const isSelected = data.sets.every((setName) =>
						selectedSetNames.includes(setName)
					);

					if (isSelected) {
						selectedBaseType = vennAreaNode;
						selectedData = data;
					}
				}
			}
		}

		if (selectedBaseType && selectedData) {
			this.addHighlightForBaseType(selectedBaseType, selectedData);
		}
	}

	public updateDatum() {
		this.vennArticle = d3Selection.select(`.${this.instanceClassName}`);
		this.vennArticle.select('*').remove();

		if (this.props.isLoading) {
			return;
		}

		const vennChart = venn.VennDiagram().height(this.height).width(this.width);
		this.vennArticle.datum(this.props.sets).call(vennChart);

		const vennArticleSvgElement = this.vennArticle.select('svg');

		vennArticleSvgElement
			.attr('preserveAspectRatio', 'xMinYMin meet')
			.attr('height', `${this.height}`)
			.attr('width', `${this.width}`)
			.attr('viewBox', `0 0 ${this.width} ${this.height}`);

		const definitionsDomElement = vennArticleSvgElement.append('defs');
		const definitionsElement = this.getFilters();

		const root = createRoot(definitionsDomElement.node()!);
		root.render(definitionsElement);

		const vennCircleNodes = this.vennArticle.selectAll('.venn-circle').nodes();

		for (const vennCircleNode of vennCircleNodes) {
			const vennCircleNodeSelection = d3Selection.select(vennCircleNode);
			const data: IVennSet = vennCircleNodeSelection.data()[0] as IVennSet;
			const textNode = vennCircleNodeSelection.select('text');
			const tspan = textNode.selectAll('tspan');

			if (this.props.onClick) {
				vennCircleNodeSelection.attr('cursor', 'pointer');
			}

			tspan.style('font-weight', '600');

			textNode
				.append('tspan')
				.attr('dy', '1.5em')
				.attr('x', tspan.attr('x'))
				.text(data.metric.toLocaleString());

			textNode.selectAll('tspan').style('fill', '#242d35');
		}

		const vennIntersectionNodes = this.vennArticle
			.selectAll('.venn-intersection')
			.nodes();

		for (const vennIntersectionNode of vennIntersectionNodes) {
			const vennIntersectionNodeSelection =
				d3Selection.select(vennIntersectionNode);
			const data: IVennSet =
				vennIntersectionNodeSelection.data()[0] as IVennSet;
			const textNode = vennIntersectionNodeSelection.select('text');
			const tspan = textNode.selectAll('tspan');

			if (this.props.onClick) {
				vennIntersectionNodeSelection.attr('cursor', 'pointer');
			}

			textNode.style('fill', '#242d35');

			tspan.text(data.metric.toLocaleString());
		}

		// const onMouseOver = (baseType: d3.BaseType, data: IVennSet) => this.addHighlightForBaseType(baseType, data);
		// const onMouseOut = (baseType: d3.BaseType, data: IVennSet) => this.removeHighlightForBaseType(baseType, data);
		const { onMouseOver, onMouseOut, onClick } = this.props;

		this.vennArticle
			.selectAll('.venn-area')
			.on('click', function (data: VennDataType) {
				if (onClick) {
					onClick(data);
				}
			})
			.on('mouseover', function (data: VennDataType) {
				if (onMouseOver) {
					onMouseOver(data);
				}
			})
			.on('mouseout', function (data: VennDataType) {
				if (onMouseOut) {
					onMouseOut(data);
				}
			});
	}

	public addHighlightForBaseType(
		baseType: d3Selection.BaseType,
		data: IVennSet
	) {
		// sort all the areas relative to the current item
		venn.sortAreas(this.vennArticle, data);

		// highlight the current path
		d3Selection
			.select(baseType)
			.select('path')
			.style('stroke-width', 3)
			.style('fill-opacity', data.sets.length === 1 ? 0.2 : 0.2)
			.style('filter', `url("#${this.dropShadowId}")`)
			.style('stroke-opacity', 1);
	}

	public removeHighlightForBaseType(
		baseType: d3Selection.BaseType,
		data: IVennSet
	) {
		d3Selection
			.select(baseType)
			.select('path')
			.style('stroke-width', 0)
			.style('fill-opacity', data.sets.length === 1 ? 0.25 : 0)
			.style('filter', null)
			.style('stroke-opacity', 0);
	}

	public render() {
		const classNames = [this.instanceClassName];

		if (this.props.isLoading) {
			return (
				<ContentLoader
					preserveAspectRatio="none"
					height={this.height}
					width={this.width}
				>
					<path d="M 135.24518879033315 243.5789455088386 m -116.4210544911614 0 a 116.4210544911614 116.4210544911614 0 1 0 232.8421089823228 0 a 116.4210544911614 116.4210544911614 0 1 0 -232.8421089823228 0" />
					<path d="M 264.75481120966685 243.5789455088386 m -116.4210544911614 0 a 116.4210544911614 116.4210544911614 0 1 0 232.8421089823228 0 a 116.4210544911614 116.4210544911614 0 1 0 -232.8421089823228 0" />
					<path d="M 199.99968921465103 131.42105449116139 m -116.4210544911614 0 a 116.4210544911614 116.4210544911614 0 1 0 232.8421089823228 0 a 116.4210544911614 116.4210544911614 0 1 0 -232.8421089823228 0" />
					<path d="M 83.83377904399171 139.12451105664428 A 116.4210544911614 116.4210544911614 0 0 1 251.41109896099246 235.87548894335572 A 116.4210544911614 116.4210544911614 0 0 1 83.83377904399171 139.12451105664428" />
				</ContentLoader>
			);
		}

		return <article className={classNames.join(' ')} />;
	}
}
