import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { AverageCallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AverageCallDurationDataGridColumn';
import { CountryDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CountryDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { MinutesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MinutesDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { RateCenterTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterTypeDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';
import { RateKeyDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateKeyDataGridColumn';
import { SipFullSummaryIndexResponse } from 'RtModels';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { sumBy } from 'lodash-es';

export const getFullSummaryColumns = (
	displayTime: boolean
): Array<DataGridColumn<SipFullSummaryIndexResponse>> => [
	DateDataGridColumn({
		accessorKey: 'timeslot',
		header: 'Time Slot',
		hiddenIfEmpty: true,
		format: displayTime ? 'YYYY-MM-DD hh:mm:ss A' : 'YYYY-MM-DD',
		exportDateFormat: displayTime ? 'yyyy-mm-dd hh:mm:ss AM/PM' : 'yyyy-mm-dd'
	}),
	HeaderDataGridColumn({
		header: 'Calling Party',
		columns: [
			CountryDataGridColumn({
				accessorKey: 'callingPartyCountry',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			RateCenterDataGridColumn({
				accessorKey: 'callingPartyRateCenter',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			RateCenterTypeDataGridColumn({
				accessorKey: 'callingPartyRateCenterType',
				hiddenIfEmpty: true
			}),
			PhoneNumberDataGridColumn({
				header: 'CP Std',
				accessorKey: 'callingPartyStd',
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Attestation Detail',
		columns: [
			DefaultDataGridColumn({
				header: 'Attestation',
				accessorKey: 'callingPartyAttestation',
				hiddenIfEmpty: true
			}),
			DefaultDataGridColumn({
				header: 'URL',
				accessorKey: 'callingPartyAttestationUrl',
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Dialed Number',
		columns: [
			CountryDataGridColumn({
				accessorKey: 'dialedNumberCountry',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			RateCenterDataGridColumn({
				accessorKey: 'dialedNumberRateCenter',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			RateCenterTypeDataGridColumn({
				accessorKey: 'dialedNumberRateCenterType',
				hiddenIfEmpty: true
			}),
			PhoneNumberDataGridColumn({
				header: 'DN Std',
				accessorKey: 'dialedNumberStd',
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Ingress Cost',
		columns: [
			AccountDataGridColumn({
				header: 'Summary',
				accessorKey: 'ingressCostAccount',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			SubscriptionDataGridColumn({
				accessorKey: 'ingressCostSubscription',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			RateKeyDataGridColumn({
				accessorKey: 'ingressCostRateKey',
				hiddenIfEmpty: true
			}),
			RateDataGridColumn({
				accessorKey: 'ingressCostRate',
				hiddenIfEmpty: true
			}),
			MinutesDataGridColumn({
				accessorKey: 'ingressCostMinutes',
				showFooter: true,
				hiddenIfEmpty: true
			}),
			PrecisionDataGridColumn({
				header: 'Total',
				accessorKey: 'ingressCostTotal',
				showFooter: true,
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Ingress Revenue',
		columns: [
			AccountDataGridColumn({
				header: 'Summary',
				accessorKey: 'ingressRevenueAccount',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			SubscriptionDataGridColumn({
				accessorKey: 'ingressRevenueSubscription',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			RateKeyDataGridColumn({
				accessorKey: 'ingressRevenueRateKey',
				hiddenIfEmpty: true
			}),
			RateDataGridColumn({
				accessorKey: 'ingressRevenueRate',
				hiddenIfEmpty: true
			}),
			MinutesDataGridColumn({
				accessorKey: 'ingressRevenueMinutes',
				showFooter: true,
				hiddenIfEmpty: true
			}),
			PrecisionDataGridColumn({
				header: 'Total',
				accessorKey: 'ingressRevenueTotal',
				showFooter: true,
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Ingress Connection',
		columns: [
			DefaultDataGridColumn({
				header: 'Remote IP',
				accessorKey: 'ingressConnectionRemoteIp',
				hiddenIfEmpty: true
			}),
			DefaultDataGridColumn({
				header: 'Result',
				accessorKey: 'ingressConnectionResult',
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Ingress Totals',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'inAtt',
				header: 'Attempts',
				showFooter: true
			}),
			IntegerDataGridColumn({
				accessorKey: 'inCon',
				header: 'Connects',
				showFooter: true
			}),
			IntegerDataGridColumn({
				accessorKey: 'cpUnq',
				header: 'CP Unq'
			}),
			IntegerDataGridColumn({
				accessorKey: 'dnUnq',
				header: 'DN Unq'
			}),
			MinutesDataGridColumn({
				accessorKey: 'inRaw',
				header: 'Raw Min',
				showFooter: true
			}),
			AverageCallDurationDataGridColumn({
				accessorKey: 'inAcd',
				header: 'ACD',
				showFooter: true,
				connections: (rows) => sumBy(rows, 'inCon'),
				duration: (rows) => sumBy(rows, 'inRaw')
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Egress Revenue',
		columns: [
			AccountDataGridColumn({
				header: 'Summary',
				accessorKey: 'egressRevenueAccount',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			SubscriptionDataGridColumn({
				accessorKey: 'egressRevenueSubscription',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			RateKeyDataGridColumn({
				accessorKey: 'egressRevenueRateKey',
				hiddenIfEmpty: true
			}),
			RateDataGridColumn({
				accessorKey: 'egressRevenueRate',
				hiddenIfEmpty: true
			}),
			MinutesDataGridColumn({
				accessorKey: 'egressRevenueMinutes',
				hiddenIfEmpty: true,
				showFooter: true
			}),
			PrecisionDataGridColumn({
				header: 'Total',
				accessorKey: 'egressRevenueTotal',
				hiddenIfEmpty: true,
				showFooter: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Egress Cost',
		columns: [
			AccountDataGridColumn({
				header: 'Summary',
				accessorKey: 'egressCostAccount',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			SubscriptionDataGridColumn({
				accessorKey: 'egressCostSubscription',
				hiddenIfEmpty: true,
				rawValue: true
			}),
			RateKeyDataGridColumn({
				accessorKey: 'egressCostRateKey',
				hiddenIfEmpty: true
			}),
			RateDataGridColumn({
				accessorKey: 'egressCostRate',
				hiddenIfEmpty: true
			}),
			MinutesDataGridColumn({
				accessorKey: 'egressCostMinutes',
				hiddenIfEmpty: true,
				showFooter: true
			}),
			PrecisionDataGridColumn({
				header: 'Total',
				accessorKey: 'egressCostTotal',
				hiddenIfEmpty: true,
				showFooter: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Egress Connection',
		columns: [
			DefaultDataGridColumn({
				header: 'Remote IP',
				accessorKey: 'egressConnectionRemoteIp',
				hiddenIfEmpty: true
			}),
			DefaultDataGridColumn({
				header: 'Result',
				accessorKey: 'egressConnectionResult',
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Egress Totals',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'egAtt',
				header: 'Attempts',
				showFooter: true
			}),
			IntegerDataGridColumn({
				accessorKey: 'egCon',
				header: 'Connects',
				showFooter: true
			}),
			PrecisionDataGridColumn({
				accessorKey: 'egRaw',
				header: 'Raw Min',
				showFooter: true
			}),
			AverageCallDurationDataGridColumn({
				accessorKey: 'egAcd',
				header: 'ACD',
				showFooter: true,
				connections: (rows) => sumBy(rows, 'egCon'),
				duration: (rows) => sumBy(rows, 'egRaw')
			})
		]
	})
];
