import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	LcrTemplateIndexRequest,
	LcrTemplateIndexResponse,
	TemplateLcrCarrierIndexRequest,
	TemplateLcrCarrierIndexResponse,
	TemplateLcrCarrierProfileRequest,
	TemplateLcrScenarioIndexRequest,
	TemplateLcrScenarioIndexResponse,
	TemplateLcrScenarioProfileRequest
} from 'RtModels';
import {
	FullResponse,
	handleDeleteRequest,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchLcrTemplates = async (
	queryParams?: LcrTemplateIndexRequest
): Promise<FullResponse<LcrTemplateIndexResponse[]>> => {
	return handleGetRequest<LcrTemplateIndexResponse[], true>(
		Rt800ApiRoutes.LcrTemplates.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetLcrTemplates = (queryParams?: LcrTemplateIndexRequest) => {
	return useQuery<FullResponse<LcrTemplateIndexResponse[]>, Error>(
		['getLcrTemplates', queryParams],
		() => fetchLcrTemplates(queryParams)
	);
};

const fetchLcrTemplateCarriers = async (
	urlParams?: TemplateLcrCarrierIndexRequest
): Promise<FullResponse<TemplateLcrCarrierIndexResponse[]>> => {
	return handleGetRequest<TemplateLcrCarrierIndexResponse[], true>(
		Rt800ApiRoutes.TemplateLcrCarriers.Index,
		{
			includeFullResponse: true,
			urlParams
		}
	);
};

export const useGetLcrTemplateCarriers = (
	urlParams?: TemplateLcrCarrierIndexRequest
) => {
	return useQuery<FullResponse<TemplateLcrCarrierIndexResponse[]>, Error>(
		['getLcrTemplateCarriers', urlParams],
		() => fetchLcrTemplateCarriers(urlParams)
	);
};

const fetchLcrTemplateScenarios = async (
	urlParams?: TemplateLcrScenarioIndexRequest
): Promise<FullResponse<TemplateLcrScenarioIndexResponse[]>> => {
	return handleGetRequest<TemplateLcrScenarioIndexResponse[], true>(
		Rt800ApiRoutes.TemplateLcrScenarios.Index,
		{
			includeFullResponse: true,
			urlParams
		}
	);
};

export const useGetLcrTemplateScenarios = (
	urlParams?: TemplateLcrScenarioIndexRequest
) => {
	return useQuery<FullResponse<TemplateLcrScenarioIndexResponse[]>, Error>(
		['getLcrTemplateScenarios', urlParams],
		() => fetchLcrTemplateScenarios(urlParams)
	);
};

export const removeCarrierFromTemplate = (
	urlParams: TemplateLcrCarrierProfileRequest
) => {
	return handleDeleteRequest(Rt800ApiRoutes.TemplateLcrCarriers.Delete, {
		urlParams
	});
};

export const removeScenarioFromTemplate = (
	urlParams: TemplateLcrScenarioProfileRequest
) => {
	return handleDeleteRequest(Rt800ApiRoutes.TemplateLcrScenarios.Delete, {
		urlParams
	});
};
