import { FC, FormEventHandler, useState } from 'react';
import {
	ServerGroupIndexResponse,
	ServerGroupMemberProfileResponse,
	ServerProfileResponse
} from 'RtModels';
import { ServerGroupSelect } from 'RtUi/app/rtAdmin/ServerGroupMembers/lib/controls/ServerGroupSelect';
import { ServerSelect } from 'RtUi/app/rtAdmin/ServerGroupMembers/lib/controls/ServerSelect';
import { ServerGroupMembersResource } from 'RtUi/app/rtAdmin/ServerGroupMembers/lib/resource/ServerGroupMembersResource';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';

interface IServerGroupMembersEditorProps {
	editMode?: ServerGroupMemberProfileResponse;
	onUpdate?: (updatedMetadata: ServerGroupMemberProfileResponse) => void;
	onCancel?: () => void;
}

export const ServerGroupMembersEditor: FC<IServerGroupMembersEditorProps> = ({
	onUpdate,
	editMode,
	onCancel
}) => {
	const rs = new ServerGroupMembersResource();

	const [formState, formMethods] = useRtUiFormEditor({
		createMode: !editMode,
		editMode,
		createNew: () => rs.createNew()
	});
	const [server, setServer] = useState<ServerProfileResponse>();
	const [serverGroup, setServerGroup] = useState<ServerGroupIndexResponse>();

	const onSubmit: FormEventHandler<HTMLFormElement> = async (evt) => {
		evt.preventDefault();

		formMethods.setError(undefined);
		formMethods.setIsSubmitting(true);

		try {
			let updatedMetadata: ServerGroupMemberProfileResponse;

			if (editMode) {
				updatedMetadata = await rs.update(
					editMode.serverGroupMemberId,
					formState.workingCopy
				);
			} else {
				updatedMetadata = await rs.create(formState.workingCopy);
			}
			if (onUpdate) {
				onUpdate(updatedMetadata);
			}

			formMethods.setIsSubmitting(false);
			formMethods.setDisplayMode(true);
		} catch (err) {
			formMethods.setIsSubmitting(false);
			formMethods.setError(err);
		}
	};

	return (
		<RtUiNarrowForm
			{...formState}
			onSubmit={onSubmit}
			onChange={formMethods.setDisplayMode}
			onCancel={() => {
				formMethods.onCancel();

				if (onCancel) {
					onCancel();
				}
			}}
		>
			<ServerSelect
				value={server}
				displayMode={formState.displayMode}
				onChange={(v) => {
					setServer(v);
					formMethods.setWorkingCopy({ serverId: v.serverId });
				}}
				initialOptionId={
					formState.workingCopy.serverId
						? String(formState.workingCopy.serverId)
						: undefined
				}
			/>
			<ServerGroupSelect
				value={serverGroup}
				displayMode={formState.displayMode}
				onChange={(v) => {
					setServerGroup(v);
					formMethods.setWorkingCopy({ serverGroupId: v.serverGroupId });
				}}
				initialOptionId={
					formState.workingCopy.serverGroupId
						? String(formState.workingCopy.serverGroupId)
						: undefined
				}
			/>
		</RtUiNarrowForm>
	);
};
