/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { IntegrationErrorMessage } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchErrorMessages = async (): Promise<
	FullResponse<IntegrationErrorMessage[]>
> => {
	return handleGetRequest(RtAdmApiRoutes.IntegrationErrorMessages.Index, {
		includeFullResponse: true
	});
};

export const useGetErrorMessages = () => {
	return useQuery<FullResponse<IntegrationErrorMessage[]>, Error>(
		'getErrorMessage',
		() => fetchErrorMessages()
	);
};
