import { ResourceIndexResponse } from 'RtModels';
import { ResourceResource } from 'RtUi/app/rtCommon/Resources/lib/resources/ResourceResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface ResourceConnectionDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		ResourceIndexResponse,
		T
	> {}

export const ResourceConnectionDataGridCache =
	new ResourceCacheMap<ResourceIndexResponse>(
		new ResourceResource(),
		'resourceId',
		'label'
	);

export const ResourceConnectionDataGridColumn = <T = any,>(
	config: ResourceConnectionDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: ResourceConnectionDataGridCache,
		...config
	});
};
