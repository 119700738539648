import { ChartConfiguration } from 'chart.js';
import moment from 'moment';
import { FC, useState } from 'react';
import { GuardianReconciliationUsageDetailsIndexResponse } from 'RtModels';
import { ChartWrapper } from 'RtUi/app/rtVue/common/lib/charts/ChartWrapper';
import { calculatePercentVariance } from 'RtUi/app/rtVue/ReconciliationUsage/lib/utils/VarianceHelpers';
import { ChartJsColors } from 'RtUi/components/charts/Constants';
import { DashboardTabs } from 'RtUi/components/ui/DashboardTabs';
import { DashboardTab } from 'RtUi/components/ui/DashboardTabs/tab';

interface IGuardianUsageMonthlyChartProps {
	className?: string;
	records: GuardianReconciliationUsageDetailsIndexResponse[];
}

type ChartTabNames = 'Charges' | 'Duration' | 'Variance' | 'Connects';
type ChartTabsType = Record<
	ChartTabNames,
	{
		tabName: ChartTabNames;
		icon: string;
		valueFormatter: (value: string | number) => string;
		datasets: Array<{
			label: string;
			color: string;
			hidden?: true;
			accessor: (
				record: GuardianReconciliationUsageDetailsIndexResponse
			) => number;
		}>;
	}
>;

const ChartTabs: ChartTabsType = {
	Charges: {
		tabName: 'Charges',
		icon: 'fas fa-dollar-sign',
		valueFormatter: (value) => {
			const valueNum = Number(String(value).replace(/\,/g, ''));
			const currencyStr = valueNum.toFixed(2);
			const currencyNum = Number(currencyStr);

			return `$${currencyNum.toLocaleString()}`;
		},
		datasets: [
			{
				label: 'RT Charges',
				color: ChartJsColors.DarkGreen,
				accessor: (record) => record.rtSrcCharge ?? 0
			},
			{
				label: 'Detail Charges',
				color: ChartJsColors.LightGreen,
				accessor: (record) => record.rtTgtCharge ?? 0
			}
		]
	},
	Duration: {
		tabName: 'Duration',
		icon: 'far fa-hourglass',
		valueFormatter: (value) => {
			const valueNum = Number(String(value).replace(/\,/g, ''));

			return valueNum.toLocaleString();
		},
		datasets: [
			{
				label: 'RT Duration',
				color: ChartJsColors.DarkBlue,
				accessor: (record) => record.rtSrcDuration ?? 0
			},
			{
				label: 'Detail Duration',
				color: ChartJsColors.LightBlue,
				accessor: (record) => record.rtTgtDuration ?? 0
			}
		]
	},
	Connects: {
		tabName: 'Connects',
		icon: 'fas fa-plug',
		valueFormatter: (value) => {
			const valueNum = Number(String(value).replace(/\,/g, ''));

			return valueNum.toLocaleString();
		},
		datasets: [
			{
				label: 'RT Connects',
				color: ChartJsColors.DarkOrange,
				accessor: (record) => record.rtSrcConnects ?? 0
			},
			{
				label: 'Detail Connects',
				color: ChartJsColors.LightOrange,
				accessor: (record) => record.rtTgtConnects ?? 0
			}
		]
	},
	Variance: {
		tabName: 'Variance',
		icon: 'fas fa-chart-line',
		valueFormatter: (value) => {
			const valueNum = Number(String(value).replace(/\,/g, ''));

			return `${valueNum.toLocaleString()}%`;
		},
		datasets: [
			{
				label: 'Charge Variance (%)',
				color: ChartJsColors.DarkGreen,
				accessor: (record) =>
					calculatePercentVariance(record.rtSrcCharge, record.rtTgtCharge)
			},
			{
				label: 'Duration Variance (%)',
				color: ChartJsColors.DarkBlue,
				hidden: true,
				accessor: (record) =>
					calculatePercentVariance(record.rtSrcDuration, record.rtTgtDuration)
			},
			{
				label: 'Connects Variance (%)',
				color: ChartJsColors.DarkOrange,
				hidden: true,
				accessor: (record) =>
					calculatePercentVariance(record.rtSrcConnects, record.rtTgtConnects)
			}
		]
	}
};

/**
 * Converts utc date to current tz
 * This is used for formatting purposes
 */
const makeDateFromUtc = (utc: Date | string) => {
	const utcMoment = moment.tz(utc, 'UTC');

	return new Date(utcMoment.year(), utcMoment.month(), utcMoment.date());
};

/**
 * Format utc date as thought it's the current tz
 */
const formatDateFromUtc = (date: Date | string, format: string) => {
	const pretendDate = makeDateFromUtc(date);

	return moment(pretendDate).format(format);
};

export const ReconciliationUsageMonthlyChart: FC<
	React.PropsWithChildren<IGuardianUsageMonthlyChartProps>
> = ({ records, className }) => {
	const [activeTab, setActiveTab] = useState<ChartTabNames>('Charges');

	const labels = records.map((record) =>
		makeDateFromUtc(record.guardianReconciliationStartTs)
	);

	if (labels.length > 0 && labels.length < 6) {
		let firstLabel = labels[0];

		while (labels.length < 6) {
			const fillerMonth = moment(firstLabel)
				.subtract(1, 'month')
				.startOf('month');

			firstLabel = fillerMonth.toDate();

			labels.splice(0, 0, firstLabel);
		}
	}

	const usageDetailsChartData: ChartConfiguration = {
		type: 'line',
		data: {
			datasets: [],
			labels
		},
		options: {
			maintainAspectRatio: true,
			aspectRatio: 3.5,
			responsive: true,
			plugins: {
				tooltip: {
					mode: 'index',
					intersect: false,
					bodyFont: {
						size: 14,
						weight: 'bold',
						lineHeight: 1.5
					},
					callbacks: {
						title: (tooltipItems) => {
							// ex. Jul 1, 2021, 12:00:00 a.m.
							const firstTooltip = tooltipItems[0].raw as {
								x: Date;
								y: number;
							};

							//remove timestamp
							return formatDateFromUtc(firstTooltip.x, 'MMM YYYY');
						},
						label: (tooltipItem) => {
							const { valueFormatter } = ChartTabs[activeTab];

							return ` ${tooltipItem.dataset.label} - ${valueFormatter(
								tooltipItem.formattedValue
							)}`;
						}
					}
				}
			},
			scales: {
				x: {
					type: 'time',
					time: {
						unit: 'month'
					},
					ticks: {
						callback: (value) => {
							return formatDateFromUtc(
								//@ts-ignore
								value,
								'MMM YYYY'
							);
						}
					}
				},
				y: {
					min: 0,
					ticks: {
						callback: (value) => ChartTabs[activeTab].valueFormatter(value)
					}
				}
			}
		}
	};

	for (const { label, color, accessor, hidden } of ChartTabs[activeTab]
		.datasets) {
		const data: Array<{ x: Date; y: number }> = [];

		for (const record of records) {
			const value = Number(accessor(record));

			data.push({
				y: value,
				x: makeDateFromUtc(record.guardianReconciliationStartTs)
			});
		}

		usageDetailsChartData.data.datasets.push({
			label,
			//@ts-ignore
			data,
			hidden,
			fill: false,
			borderColor: color,
			backgroundColor: color
			//lineTension: 0.4
		});
	}

	return (
		<section className={className} key={records.length}>
			<h4>Monthly Variance</h4>
			<DashboardTabs
				activeTab={activeTab}
				onTabChange={(newTab: any) => setActiveTab(newTab)}
			>
				{Object.values(ChartTabs).map((chartTab) => (
					<DashboardTab
						key={chartTab.tabName}
						tabId={chartTab.tabName}
						icon={chartTab.icon}
					>
						<ChartWrapper config={usageDetailsChartData} />
					</DashboardTab>
				))}
			</DashboardTabs>
		</section>
	);
};
