/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipAgentAddressCreateRequest = {
    properties: {
        sipAgentId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        sipAgentConfigId: {
            type: 'number',
            isRequired: true,
        },
        switchAddressId: {
            type: 'number',
            isRequired: true,
        },
    },
};