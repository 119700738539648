/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $IntegrationRequest = {
    properties: {
        integrationRequestUuid: {
            type: 'string',
            isRequired: true,
        },
        integrationTaskTypeId: {
            type: 'number',
            isRequired: true,
        },
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        resourceConfiguration: {
            properties: {
            },
            isRequired: true,
        },
        ref1Ts: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        ref2Int: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        ref3Str: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        jobStatusId: {
            type: 'JobStatusId',
            isRequired: true,
        },
        requestedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        completedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
    },
};