import * as React from 'react';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { SubmitFormControl } from 'RtUi/components/form/SubmitFormControl';
import { RtUiController } from '../@RtUi/RtUiDecorators';
import { UserHttp } from './lib/Http/UserHttp';
import { Logo } from 'RtUi/app/public/lib/components/Logo';
import { Container, Alert, Card, Button } from 'react-bootstrap';
import { LoginPath } from 'RtUi/app/user/lib/Constants';

interface INewUserContainerState {
	token: string;
	newPassword: string;
	confirmNewPassword: string;
	showInputErrors: boolean;
	error?: any;
	isSubmitting: boolean;
	passwordWasUpdated: boolean;
}

@RtUiController({
	name: 'Onboarding for New Users',
	path: '/newUser',
	isHidden: true,
	unauthorizedAccessAllowed: true,
	hideNav: true
})
export class NewUserContainer extends ApplicationContainer<
	{},
	INewUserContainerState
> {
	public state: INewUserContainerState = {
		token: '',
		newPassword: '',
		confirmNewPassword: '',
		showInputErrors: false,
		error: undefined,
		isSubmitting: false,
		passwordWasUpdated: false
	};

	public userHttp = new UserHttp();

	public componentDidMount() {
		const possibleToken = this.getSearchParam('token');

		if (possibleToken) {
			this.setState({ token: possibleToken });
		}
	}

	public async submit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		this.setState({ showInputErrors: true, error: undefined });

		if (!this.doNewPasswordsMatch()) {
			return;
		}

		this.setState({ isSubmitting: true });

		try {
			const { token, newPassword, confirmNewPassword } = this.state;
			const response = await this.userHttp.resetPassword(
				token,
				newPassword,
				confirmNewPassword
			);
			const passwordWasUpdated = response.passwordWasResetSuccessfully;

			this.setState({ passwordWasUpdated });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public doNewPasswordsMatch() {
		const { newPassword, confirmNewPassword } = this.state;

		return newPassword === confirmNewPassword;
	}

	public render() {
		const { showInputErrors } = this.state;
		const confirmNewPasswordError =
			showInputErrors && !this.doNewPasswordsMatch()
				? 'Does not match New Password'
				: undefined;

		return (
			<form className="v-align-container" onSubmit={(evt) => this.submit(evt)}>
				<Container className="animate__animated animate__fadeIn d-flex justify-content-center">
					<Card
						className="border-primary"
						style={{ minWidth: '30vw', maxWidth: 450 }}
					>
						<Card.Header className="bg-brand-gradient p-3 text-white d-flex align-items-center justify-content-start">
							<Logo height={24} />
						</Card.Header>
						<Card.Body>
							{!this.state.passwordWasUpdated && (
								<>
									<h6 className="lead">New User</h6>
									<Alert
										variant="info"
										className="d-flex justify-content-between"
									>
										<i className="fas fa-fw fa-info-circle me-2" />
										<span>
											If your Email Token is not filled in below, <br />
											please copy and paste the one sent to your email.
										</span>
									</Alert>
									<InputFormControl
										label="Email Token"
										required
										type="text"
										minLength={8}
										onChange={(token) => this.setState({ token })}
										value={this.state.token}
									/>
									<InputFormControl
										label="New Password"
										required
										type="password"
										autoFocus
										onChange={(newPassword) => this.setState({ newPassword })}
										value={this.state.newPassword}
									/>
									<InputFormControl
										label="Confirm New Password"
										required
										type="password"
										formTextDanger={confirmNewPasswordError}
										onChange={(confirmNewPassword) =>
											this.setState({
												confirmNewPassword
											})
										}
										value={this.state.confirmNewPassword}
									/>
									<SubmitFormControl
										displayMode={false}
										createMode={true}
										error={this.state.error}
										isSubmitting={this.state.isSubmitting}
									/>
								</>
							)}
							{this.state.passwordWasUpdated && (
								<>
									<Alert
										variant="success"
										className="d-flex justify-content-start align-items-center h5"
									>
										<i className="fas fa-fw fa-envelope me-2" />
										<span>Password was updated!</span>
									</Alert>
									<section className="d-flex justify-content-end">
										<Button
											variant="primary"
											size="lg"
											onClick={() => this.goToPath(LoginPath)}
										>
											Go to Login
										</Button>
									</section>
								</>
							)}
						</Card.Body>
					</Card>
				</Container>
			</form>
		);
	}
}
