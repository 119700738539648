/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RouteDepthIndexResponse = {
    properties: {
        iso3166Alpha3: {
            type: 'string',
        },
        rateCenterId: {
            type: 'number',
        },
        rateKey: {
            type: 'number',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
        },
        subscriptionRateKey: {
            type: 'number',
            isRequired: true,
        },
        interRate: {
            type: 'number',
            isRequired: true,
        },
        intraRate: {
            type: 'number',
            isRequired: true,
        },
        indetRate: {
            type: 'number',
            isRequired: true,
        },
        dipFee: {
            type: 'number',
            isRequired: true,
        },
        connectionFee: {
            type: 'number',
            isRequired: true,
        },
    },
};