import {
	ServiceNumberTagProfileResponse,
	TagIndexResponse,
	OriginationNumberProfileResponse,
	ServiceNumberTagIndexResponse
} from 'RtModels';
import { TagSelect } from 'RtUi/app/rtSip/Scenarios/lib/controls/TagSelect';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { cloneDeep, isEqual } from 'lodash-es';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { Component } from 'react';
import { ServiceNumberTagResource } from 'RtUi/app/rtSip/ServiceNumbers/lib/resources/ServiceNumberTagResource';
import { TagTypeSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/TagTypeSelect';

interface IServiceNumberTagFormEditorProps {
	narrow?: boolean;
	tag?: ServiceNumberTagIndexResponse;
	serviceNumber: string;
	onSuccess?: (profile: ServiceNumberTagProfileResponse) => void;
}

interface IServiceNumberTagFormEditorState {
	displayMode: boolean;
	isSubmitting: boolean;
	values: Array<ISimpleSelectFormControlOption<string>>;
	tag?: string;
	profile?: ServiceNumberTagProfileResponse;
	tagSelect?: TagIndexResponse;
	error?: any;
	serviceNumber?: OriginationNumberProfileResponse;
}

export class ServiceNumberTagFormEditor extends Component<
	IServiceNumberTagFormEditorProps,
	IServiceNumberTagFormEditorState
> {
	public readonly initialState: IServiceNumberTagFormEditorState = {
		displayMode: false,
		isSubmitting: false,
		values: []
	};

	public state: IServiceNumberTagFormEditorState = { ...this.initialState };

	public async componentDidMount() {
		this.loadProfileOrDefaultValues();
	}

	public componentDidUpdate(prevProps: IServiceNumberTagFormEditorProps) {
		if (!isEqual(prevProps.tag, this.props.tag)) {
			this.loadProfileOrDefaultValues();
		}
	}

	public render() {
		return (
			<RtForm
				narrow={this.props.narrow}
				createMode
				onSubmit={this.createOrUpdate}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onCancel={() => {
					this.setState({ ...cloneDeep(this.initialState) });
				}}
			>
				<TagTypeSelect
					required
					displayMode
					initialOptionId="CUSTOM"
					value={this.state.tagSelect}
				/>
				<TagSelect<true>
					label="Values"
					multi
					displayMode={this.state.displayMode}
					creatable
					options={[]}
					onChange={(tags) => this.setState({ values: tags })}
					value={this.state.values}
				/>
			</RtForm>
		);
	}

	private createOrUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const serviceNumberTagResource = new ServiceNumberTagResource();
		let newProfile: ServiceNumberTagProfileResponse;

		this.setState({ isSubmitting: true, error: undefined });
		const { onSuccess = () => ({}) } = this.props;

		try {
			if (this.state.profile) {
				newProfile = await serviceNumberTagResource.update(
					this.state.profile.serviceNumberTagId,
					{
						...this.state.profile,
						values: this.state.values.map(
							(tagSelectItems: ISimpleSelectFormControlOption<string>) =>
								tagSelectItems.value
						)
					}
				);
				this.setState({ displayMode: true });
			} else {
				newProfile = await serviceNumberTagResource.create({
					serviceNumber: this.props.serviceNumber,
					tag: 'CUSTOM',
					values: this.state.values.map((val) => val.value)
				});
			}
			this.setState({ displayMode: true });
			onSuccess(newProfile);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	};

	private async loadProfileOrDefaultValues() {
		const props = this.props;

		this.setState(cloneDeep(this.initialState));

		if (props.tag) {
			const profile = await new ServiceNumberTagResource().get(
				props.tag.serviceNumberTagId
			);

			if (!profile) {
				return;
			}

			this.setState({
				tag: profile.tag,
				values: profile.values.map((tagValue: string) => ({
					value: tagValue,
					label: tagValue
				}))
			});
		}
	}
}
