import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { CdrRecurringExportEditor } from 'RtUi/app/Administration/CdrRecurringExport/lib/forms/CdrRecurringExportEditor';
import { CdrRecurringExportGrid } from 'RtUi/app/Administration/CdrRecurringExport/lib/grids/CdrRecurringExportGrid';
import { CdrExportTypeSelect } from 'RtUi/app/rtSip/CdrExports/lib/controls/CdrExportTypeSelect';
import { ICdrExportTypeIndex } from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportTypeResource';
import { VueCdrRecurringExportRouter } from 'RtUi/app/rtVue/CdrRecurringExport/VueCdrRecurringExportRouter';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useState } from 'react';

export const VueCdrRecurringExportIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true },
		CreateCdrRecurringExport: {
			header: 'Create CDR Configuration',
			isAction: true
		}
	});
	const [cdrExportType, setCdrExportType] = useState<ICdrExportTypeIndex>();

	const searchRecurringExport = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		try {
			event.preventDefault();
			setActiveTab('Results');
		} catch (e) {}
	};

	return (
		<TabbedLayout
			router={VueCdrRecurringExportRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					onSubmit={(evt) => {
						searchRecurringExport(evt);
					}}
				>
					<CdrExportTypeSelect
						onChange={(value) => {
							setCdrExportType(value);
						}}
						value={cdrExportType}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<CdrRecurringExportGrid
					router={VueCdrRecurringExportRouter}
					cdrExportType={cdrExportType}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateCdrRecurringExport}>
				<CdrRecurringExportEditor
					createMode
					onCreate={(profile) => {
						BrowserHistory.push(
							VueCdrRecurringExportRouter.getProfileRoute(profile)
						);
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

VueCdrRecurringExportRouter.setIndexRtUiFunctionalComponent(
	VueCdrRecurringExportIndexContainer,
	{
		groupName: 'Configuration',
		orderPriority: 0,
		groupOrderPriority: 90
	}
);
