import { IdddSearchResponse, NanpIndexResponse } from 'RtModels';
import {
	DidNanpIndexRequest,
	DidNanpIndexResponse
} from 'RtUi/app/rtDid/Search/lib/grids/DidNanpNumbersGrid';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';
import { ResourceConnectionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ResourceConnectionDataGridColumn';

export const getDidNanpNumbersColumns = <T extends DidNanpIndexRequest>(
	isNanp: boolean
): Array<DataGridColumn<DidNanpIndexResponse<T>>> => {
	const columns: Array<DataGridColumn<DidNanpIndexResponse<T>>> = [
		ResourceConnectionDataGridColumn({
			accessorKey: 'resourceId',
			header: 'Resource'
		}),
		DefaultDataGridColumn({
			accessorKey: 'weight',
			header: 'Weight'
		})
	];

	if (isNanp) {
		return [
			...columns,
			PhoneNumberDataGridColumn<NanpIndexResponse>({
				accessorKey: 'nanpNumber',
				header: 'Number'
			}),
			DefaultDataGridColumn<NanpIndexResponse>({
				accessorKey: 'lata',
				header: 'Lata'
			}),
			DefaultDataGridColumn<NanpIndexResponse>({
				accessorKey: 'ocn',
				header: 'Ocn'
			}),
			DefaultDataGridColumn<NanpIndexResponse>({
				accessorKey: 'state',
				header: 'State'
			})
		] as Array<DataGridColumn<DidNanpIndexResponse<T>>>;
	}

	return [
		...columns,
		DefaultDataGridColumn<IdddSearchResponse>({
			accessorKey: 'did',
			header: 'Did'
		}),
		DefaultDataGridColumn<IdddSearchResponse>({
			accessorKey: 'costPerTn',
			header: 'Cost Per Tn'
		})
	] as Array<DataGridColumn<DidNanpIndexResponse<T>>>;
};
