import { CprNumberIndexRequest, CprNumberIndexResponse } from 'RtModels';
import { CprSearchRouter } from 'RtUi/app/rt800/CprSearch/CprSearch.router';
import {
	fetchCprNumber,
	useGetCprNumber
} from 'RtUi/app/rt800/CprSearch/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { TollFreeNumbersCopyButton } from 'RtUi/components/data/DataGrid/components/TollFreeNumbersCopyButton';
import { getCprSearchColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/cprSearch';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';
import { timestampToReadable } from 'RtUi/utils/maps';
import { useMemo } from 'react';
import { Item } from 'react-contexify';
import { useNavigate } from 'react-router-dom';
import {
	TemplateCprsRouter,
	TollFreeCprsRouter
} from '../../../Cprs/Cprs.router';

interface ICprNumbersGridProps {
	resourceParams: CprNumberIndexRequest;
}

export const CprNumbersGrid = ({ resourceParams }: ICprNumbersGridProps) => {
	const { data, isFetching: isLoading } = useGetCprNumber(resourceParams);
	const fullResultsReq = useGetFullResults(
		['exportCprNumbers', resourceParams],
		resourceParams,
		(params) => fetchCprNumber(params)
	);
	const columns = useMemo(() => getCprSearchColumns(), []);
	const navigate = useNavigate();

	const goToCpr = (tfn: string, effectiveTs: Date) => {
		const cprPath = TollFreeCprsRouter.getProfileRouteByIdAndEffectiveTs(
			tfn,
			new Date(effectiveTs).toISOString()
		);

		navigate(cprPath);
	};

	return (
		<DataGrid<CprNumberIndexResponse>
			data={data?.data}
			router={CprSearchRouter}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rt800_CprNumber'}
			enableExternalExport
			getExternalExportData={fullResultsReq}
			disableExternalLinks={[TemplateCprsRouter, TollFreeCprsRouter]}
			headerAction={(table) => (
				<TollFreeNumbersCopyButton table={table} numberKey="tfn" />
			)}
			menuLinks={(record) => {
				const { effectiveTs, tfn } = record;

				if (!effectiveTs) {
					return null;
				}

				return (
					<>
						<Item onClick={() => goToCpr(tfn, effectiveTs)}>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-search" />
								<span>Routing Profile {timestampToReadable(effectiveTs)}</span>
							</span>
						</Item>
						<Item
							onClick={() => {
								navigate(TollFreeCprsRouter.getProfileRoute(record));
							}}
						>
							<span className="d-flex justify-content-start align-items-center">
								<i className="fas fa-fw fa-search" />
								<span>{TollFreeCprsRouter.getName()}</span>
							</span>
						</Item>
					</>
				);
			}}
		/>
	);
};
