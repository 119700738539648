import { FC, FormEvent, useState } from 'react';
import {
	GuardianReconciliationMappingFiltersProfileResponse,
	ReconciliationMappingDataSourceDirection,
	ReconciliationMappingDataSourceField,
	ReconciliationMappingDataSourceType
} from 'RtModels';
import { GuardianReconciliationMappingFilterValueFacadeSelect } from 'RtUi/app/rtVue/GuardianReconciliationMappingFilter/lib/controls/GuardianReconciliationMappingFilterValueFacadeSelect';
import { GuardianReconciliationMappingFilterResource } from 'RtUi/app/rtVue/GuardianReconciliationMappingFilter/lib/resources/GuardianReconciliationMappingFilterResource';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IGuardianReconciliationMappingFiltersEditorProps {
	guardianReconciliationMappingId: number;
	dataSourceDirection: ReconciliationMappingDataSourceDirection;
	editMode?: GuardianReconciliationMappingFiltersProfileResponse;
	createMode?: boolean;
	displayMode?: boolean;
	onCancel?: () => void;
	onUpdate?: (
		newProfile: GuardianReconciliationMappingFiltersProfileResponse
	) => void;
}

export const GuardianReconciliationMappingFiltersEditor: FC<
	React.PropsWithChildren<IGuardianReconciliationMappingFiltersEditorProps>
> = ({
	guardianReconciliationMappingId,
	dataSourceDirection,
	editMode,
	createMode,
	displayMode,
	onUpdate = () => ({}),
	onCancel = () => ({})
}) => {
	const [reconType, setReconType] =
		useState<
			ISimpleSelectFormControlOption<ReconciliationMappingDataSourceType>
		>();
	const [fieldType, setFieldType] =
		useState<
			ISimpleSelectFormControlOption<ReconciliationMappingDataSourceField>
		>();

	const reconciliationMappingResource =
		new GuardianReconciliationMappingFilterResource();
	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		displayMode,
		editMode,
		createMode,
		createNew: () =>
			reconciliationMappingResource.createNewProfile({
				guardianReconciliationMappingId,
				dataSourceDirection
			})
	});
	const { workingCopy } = rtUiFormState;

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			let newProfile: GuardianReconciliationMappingFiltersProfileResponse;

			if (createMode) {
				newProfile = await reconciliationMappingResource.create(workingCopy);
			} else {
				newProfile = await reconciliationMappingResource.update(
					workingCopy.guardianReconciliationMappingFilterId,
					workingCopy
				);
			}

			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setDisplayMode(true);

			onUpdate(newProfile);
		} catch (err) {
			rtUiFormMethods.setError(err);
			rtUiFormMethods.setIsSubmitting(false);
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			onSubmit={onSubmit}
			onChange={rtUiFormMethods.setDisplayMode}
			isCanceledDisabled
			onCancel={() => {
				setReconType(undefined);
				setFieldType(undefined);
				rtUiFormMethods.onCancel();
				onCancel();
			}}
		>
			<SimpleSelectFormControl<ReconciliationMappingDataSourceField>
				label="Field"
				value={fieldType}
				onChange={(newFieldType) => {
					setFieldType(newFieldType);
					if (workingCopy.dataSourceField !== newFieldType.value) {
						rtUiFormMethods.setWorkingCopy({
							dataSourceField: newFieldType.value,
							dataSourceValue: ''
						});
						if (newFieldType.value === 1) {
							setReconType({
								value: 'ingress',
								label: 'Ingress'
							} as any);
							rtUiFormMethods.setWorkingCopy({
								dataSourceType: 'ingress'
							});
						} else {
							setReconType({
								value: 'cost',
								label: 'Cost'
							} as any);
							rtUiFormMethods.setWorkingCopy({
								dataSourceType: 'cost'
							});
						}
					}
				}}
				initialOptionId={workingCopy.dataSourceField.toString()}
				options={[
					{
						value: ReconciliationMappingDataSourceField.Account,
						label: 'Account'
					},
					{
						value: ReconciliationMappingDataSourceField.Subscription,
						label: 'Subscription'
					},
					// https://trello.com/c/1yzgk5Qz/994-rt-vue-mapping-created-edit-drop-down-changes
					// {
					// 	value: ReconciliationMappingDataSourceField.Switch,
					// 	label: 'Switch'
					// },
					{
						value: ReconciliationMappingDataSourceField.TrunkGroup,
						label: 'Trunk Group'
					},
					{
						value: ReconciliationMappingDataSourceField.FileStream,
						label: 'File Stream'
					},
					{
						value: ReconciliationMappingDataSourceField.CallType,
						label: 'Call Type'
					},
					{
						value: ReconciliationMappingDataSourceField.ExternalAccount,
						label: 'External Account Id'
					}
				]}
			/>
			<SimpleSelectFormControl<ReconciliationMappingDataSourceType>
				label="Type"
				value={reconType}
				onChange={(newReconType) => {
					setReconType(newReconType);
					if (workingCopy.dataSourceType !== newReconType.value) {
						rtUiFormMethods.setWorkingCopy({
							dataSourceType: newReconType.value
						});
					}
				}}
				initialOptionId={workingCopy.dataSourceType.toString()}
				options={[
					{
						value:
							fieldType &&
							fieldType.value ===
								ReconciliationMappingDataSourceField.TrunkGroup
								? ReconciliationMappingDataSourceType.Ingress
								: ReconciliationMappingDataSourceType.Cost,
						label:
							fieldType &&
							fieldType.value ===
								ReconciliationMappingDataSourceField.TrunkGroup
								? 'Ingress'
								: 'Cost'
					},
					{
						value:
							fieldType &&
							fieldType.value ===
								ReconciliationMappingDataSourceField.TrunkGroup
								? ReconciliationMappingDataSourceType.Egress
								: ReconciliationMappingDataSourceType.Revenue,
						label:
							fieldType &&
							fieldType.value ===
								ReconciliationMappingDataSourceField.TrunkGroup
								? 'Egress'
								: 'Revenue'
					}
				]}
			/>
			<GuardianReconciliationMappingFilterValueFacadeSelect
				type={workingCopy.dataSourceField}
				dataSourceValue={workingCopy.dataSourceValue}
				onChange={(dataSourceValue) =>
					rtUiFormMethods.setWorkingCopy({ dataSourceValue })
				}
			/>
			<IsActiveRadioFormControl
				hideBothOption
				displayMode={rtUiFormState.displayMode}
				value={workingCopy.isActive}
				onChange={(isActive) => rtUiFormMethods.setWorkingCopy({ isActive })}
			/>
			<RadioFormControl<string>
				label="Operator"
				onChange={(operand) => {
					rtUiFormMethods.setWorkingCopy({
						guardianReconciliationMappingOperand: operand
					});
				}}
				displayMode={rtUiFormState.displayMode}
				value={(workingCopy as any).guardianReconciliationMappingOperand}
				options={[
					{ value: 'IN', label: 'In' },
					{ value: 'NOT IN', label: 'Not In' }
				]}
			/>
			<TextAreaFormControl
				label="Notes"
				displayMode={rtUiFormState.displayMode}
				value={workingCopy.notes ?? ''}
				onChange={(notes) => rtUiFormMethods.setWorkingCopy({ notes })}
			/>
		</RtUiForm>
	);
};
