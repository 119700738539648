import { RejectSummaryIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RejectCodeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RejectCodeDataGridColumn';

export const getRejectSuspenseColumns = (
	onRevenueDrillDown: (row: RejectSummaryIndexResponse) => void,
	onCostCountDrillDown: (row: RejectSummaryIndexResponse) => void,
	callDateFormat?: string
): Array<DataGridColumn<RejectSummaryIndexResponse>> => [
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		format: callDateFormat
	}),
	FileStreamDataGridColumn({
		accessorKey: 'file_stream_id',
		header: 'File Stream',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingress_trunk_group_id',
		header: 'Ingress Trunk Group Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egress_trunk_group_id',
		header: 'Egress Trunk Group Id',
		hiddenIfEmpty: true
	}),
	RejectCodeDataGridColumn({
		accessorKey: 'revenue_reject_code',
		header: 'Revenue Reject Code',
		hiddenIfEmpty: true
	}),
	RejectCodeDataGridColumn({
		accessorKey: 'cost_reject_code',
		header: 'Cost Reject Code',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'calculated_call_type',
		header: 'calculated Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'rejectRevenueCallCount',
		header: 'Reject Revenue Call Count',
		onDrillDown: (e, row) => {
			e.preventDefault();
			onRevenueDrillDown(row);
		}
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rejectRevenueMinutes',
		header: 'Reject Minutes'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rejectCostCallCount',
		header: 'Reject Cost Calls Count',
		onDrillDown: (e, row) => {
			e.preventDefault();
			onCostCountDrillDown(row);
		}
	}),
	PrecisionDataGridColumn({
		accessorKey: 'rejectCostMinutes',
		header: 'Reject Cost Minutes'
	}),
	CallDateDataGridColumn({
		accessorKey: 'minCallDate',
		header: 'Min Call Date',
		hiddenIfEmpty: true
	}),
	CallDateDataGridColumn({
		accessorKey: 'maxCallDate',
		header: 'Max Call Date',
		hiddenIfEmpty: true
	})
];
