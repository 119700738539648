import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { Loading } from 'RtUi/components/ui/Loading';
import { GuardianDoNotOriginate } from 'RtModels';
import { DoNotOriginateRouter } from 'RtUi/app/rtVue/DoNotOriginate/DoNotOriginate.router';
import { DoNotOriginateResource } from 'RtUi/app/rtVue/DoNotOriginate/lib/resources/DoNotOriginateResource';
import { DoNotOriginateFormEditor } from 'RtUi/app/rtVue/DoNotOriginate/lib/forms/DoNotOriginateFormEditor';

interface IDoNotOriginateContainerState {
	activeTab: string;
	isLoading: boolean;
	profile?: GuardianDoNotOriginate;
}

@DoNotOriginateRouter.getProfileRtUiController()
export class DoNotOriginateProfileContainer extends ProfileApplicationContainer<
	{},
	IDoNotOriginateContainerState
> {
	public Tabs = DoNotOriginateRouter.getProfileTabs();

	public state: IDoNotOriginateContainerState = {
		activeTab: this.Tabs.Profile.header,
		isLoading: true
	};

	public async componentDidMount() {
		const resource = new DoNotOriginateResource();
		const resourceId = Number(this.getIdParam());

		this.setState({ isLoading: true });
		const profile = await resource.get(resourceId);
		if (!profile) {
			throw new Error('Profile was not found.');
		}

		this.setState({ profile, isLoading: false });
	}

	public render() {
		if (this.state.isLoading) {
			return <Loading />;
		}

		return (
			<TabbedLayout<GuardianDoNotOriginate>
				profile={this.state.profile}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={DoNotOriginateRouter}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<DoNotOriginateFormEditor
						profile={this.state.profile}
						onSuccess={(profile) => this.setState({ profile })}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
