/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianConnectionSubscriptionCountIndexRequest = {
    properties: {
        guardianConnectionId: {
            type: 'number',
            isRequired: true,
        },
        updateOption: {
            type: 'GuardianConnectionUpdateOptions',
            isRequired: true,
        },
    },
};