import * as React from 'react';
import { ControlGroup } from '../../ui/ControlGroup';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from '../FormControl';
import { Form } from 'react-bootstrap';

interface ITextAreaFormControlProps extends IFormControlProps<string> {
	disabled?: boolean;
	label: string;
	subLabel?: string;
	maxLength?: number;
	hideLabel?: boolean;
	hideFormGroup?: boolean;
	toUpperCase?: boolean;
	errorText?: string;
	customValidityError?: string;
	rows?: number;
}

interface ITextAreaFormControlState extends IFormControlState {}

export class TextAreaFormControl extends FormControl<
	string,
	false,
	ITextAreaFormControlProps,
	ITextAreaFormControlState
> {
	public resource = undefined;
	public state: ITextAreaFormControlState = {
		formLabel: ''
	};

	public selectionTimeout: number | undefined;
	public inputElementRef = React.createRef<HTMLTextAreaElement>();

	public componentDidUpdate() {
		this.updateCustomValidityError();
	}

	public updateCustomValidityError() {
		const { customValidityError = '' } = this.props;

		this.inputElementRef.current?.setCustomValidity(customValidityError);
	}

	public onChange(evt: React.ChangeEvent<HTMLTextAreaElement>) {
		let { value } = evt.target;

		if (this.props.toUpperCase) {
			value = value.toUpperCase();
		}

		if (this.props.onChange) {
			this.props.onChange(value);
		}

		const caretStart = evt.target.selectionStart;
		const caretEnd = evt.target.selectionEnd;

		// https://github.com/facebook/react/issues/955
		// React issue that doesn't keep cursor on correct index
		if (value.length !== caretStart) {
			if (typeof this.selectionTimeout === 'number') {
				window.clearTimeout(this.selectionTimeout);

				this.selectionTimeout = undefined;
			}

			this.selectionTimeout = window.setTimeout(() => {
				evt.target.setSelectionRange(caretStart, caretEnd);
			}, 15);
		}
	}

	public render() {
		return (
			<ControlGroup
				autoHeight
				errorText={this.props.errorText}
				hideLabel={this.props.hideLabel}
				hideFormGroup={this.props.hideFormGroup}
				label={this.state.formLabel}
				displayMode={this.props.displayMode}
				sublabel={this.props.subLabel}
				required={this.props.required}
				value={this.props.value}
			>
				<Form.Control
					as="textarea"
					ref={this.inputElementRef}
					disabled={this.props.disabled}
					className={`form-control ${this.props.className}`}
					value={this.props.value}
					name={this.props.name}
					rows={this.props.rows}
					maxLength={this.props.maxLength}
					onChange={(evt) => this.onChange(evt as any)}
					required={this.props.required}
				/>
			</ControlGroup>
		);
	}
}
