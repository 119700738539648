import { Col, Row, Card } from 'react-bootstrap';
import { TollFreeCprIndexResponse } from 'RtModels';
import { CprGrid } from 'RtUi/app/rt800/Cprs/lib/grids/CprGrid';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { TasksGrid } from 'RtUi/app/rt800/Tasks/lib/grids/TasksGrid';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { AdminDataInfo } from 'RtUi/components/ui/AdminDataInfo';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { tfnStatusToName } from 'RtUi/utils/maps';
import { TaskAction } from '../Tasks/lib/components/TaskAction';
import { NumberHistoryGrid } from './lib/grids/NumberHistoryGrid';
import {
	INumberProfile,
	INumberTroubleProfile,
	ITollFreeQuery,
	NumberResource
} from './lib/resources/NumberResource';
import { IMgiTaskLabelTypeTabProps, NumberRouter } from './Number.router';
import { IApplicationContainerProps } from 'RtUi/components/containers/ApplicationContainer';
import { TollFreeCprsRouter } from 'RtUi/app/rt800/Cprs/Cprs.router';

interface INumberContainerProps extends IApplicationContainerProps {}

interface INumberContainerState {
	tfn: string;
	activeTab: string;
	numberResource: NumberResource;
	numberProfile?: INumberProfile;
	troubleProfile?: INumberTroubleProfile;
	queryProfile?: ITollFreeQuery;
	actionTabs: IMgiTaskLabelTypeTabProps[];
}

@NumberRouter.getProfileRtUiController()
export class NumberProfileContainer extends ProfileApplicationContainer<
	INumberContainerProps,
	INumberContainerState
> {
	public Tabs = NumberRouter.getProfileTabs();

	public state: INumberContainerState = {
		tfn: '',
		numberResource: new NumberResource(),
		activeTab: this.Tabs.Profile.header,
		actionTabs: []
	};

	public queryIsInit = false;
	public troubleAndQueryIsInit = false;
	public isSpare = false;

	public componentDidUpdate(prevProps: Readonly<INumberContainerProps>) {
		if (prevProps.params !== this.props.params) {
			this.initData();
		}
	}

	public async componentDidMount() {
		this.initData();
	}

	public initTroubleAndQuery() {
		if (!this.troubleAndQueryIsInit) {
			this.troubleAndQueryIsInit = true;
			const { numberResource, tfn } = this.state;
			const queryPromise = numberResource.query(tfn);
			const troublePromise = !this.isSpare
				? numberResource.trouble(tfn)
				: Promise.resolve(undefined);

			Promise.all([queryPromise, troublePromise]).then(
				([queryProfile, troubleProfile]) => {
					this.setState({ queryProfile, troubleProfile });
				}
			);
		}
	}

	public onTabChange(activeTab: string) {
		if (activeTab === this.Tabs.Trouble.header) {
			this.initTroubleAndQuery();
		}

		this.setState({ activeTab });
	}

	public render() {
		const { tfn, numberProfile, troubleProfile, queryProfile, actionTabs } =
			this.state;

		if (!numberProfile) {
			return <Loading />;
		}

		const canManageNumber = numberProfile.respOrgId
			? this.Actions.User.hasAccessToRespOrgId(numberProfile.respOrgId)
			: false;

		return (
			<TabbedLayout<INumberProfile>
				router={NumberRouter}
				profile={numberProfile}
				canManageFn={() => canManageNumber}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.onTabChange(activeTab)}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<AdminDataInfo profile={numberProfile} />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.History}>
					<NumberHistoryGrid tfn={tfn} />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Trouble}>
					{!troubleProfile && <Loading internal />}
					{troubleProfile && (
						<Card body>
							<>
								<Row>
									<Col md={6}>
										<InputFormControl
											label="Summary"
											displayMode
											value={troubleProfile.summary}
										/>
									</Col>
									<Col md={6}>
										<InputFormControl
											label="Trouble Number"
											displayMode
											value={TollFreeNumbersUtils.tollFreeNumberToReadableString(
												troubleProfile.troubleNumber || ''
											)}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<InputFormControl
											label="RespOrg"
											displayMode
											value={troubleProfile.respOrgId}
										/>
									</Col>
									{queryProfile && queryProfile.tfnStatusId && (
										<Col md={6}>
											<InputFormControl
												label="Status"
												displayMode
												value={tfnStatusToName(queryProfile.tfnStatusId)}
											/>
										</Col>
									)}
								</Row>
							</>
						</Card>
					)}
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Tasks}>
					<TasksGrid
						resourceParams={{
							referenceKey: tfn,
							scheduledAfterTs: new Date('2000-01-01')
						}}
					/>
				</TabbedLayoutTab>
				{canManageNumber && (
					<TabbedLayoutTab {...this.Tabs.Routing}>
						<CprGrid<TollFreeCprIndexResponse>
							router={TollFreeCprsRouter}
							resourceParams={{ tfn }}
							isNumber
							pageName="rt800_numbersProfileCpr"
						/>
					</TabbedLayoutTab>
				)}
				{actionTabs.map((actionTab) => {
					return (
						<TabbedLayoutTab {...actionTab} key={actionTab.header}>
							<Row>
								<Col md={6}>
									<TaskAction
										type={actionTab.header}
										singleNumberMode
										defaultRespOrgId={
											numberProfile.respOrgId
												? numberProfile.respOrgId
												: undefined
										}
										defaultTollFreeNumbers={[numberProfile.tfn]}
									/>
								</Col>
							</Row>
						</TabbedLayoutTab>
					);
				})}
			</TabbedLayout>
		);
	}

	private async initData() {
		const tfn = this.getIdParam();

		this.setState({ tfn, numberProfile: undefined });
		try {
			const numberProfile = await this.state.numberResource.get(tfn);

			this.isSpare =
				typeof numberProfile === 'object' && numberProfile.tfnStatusId === 'S';
			const actionTabs = NumberRouter.getActionTabs(numberProfile);

			this.setState({ numberProfile, actionTabs });
		} catch (e) {}
	}
}
