/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $AsrIndexResponseData = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        hour: {
            type: 'string',
        },
        minute: {
            type: 'string',
        },
        ingressConnectionId: {
            type: 'number',
        },
        customerAccountId: {
            type: 'number',
        },
        customerExternalAccountId: {
            type: 'number',
        },
        customerExternalSubAccountId: {
            type: 'number',
        },
        customerSubscriptionId: {
            type: 'number',
        },
        ingressTrunkGroup: {
            type: 'string',
        },
        ingressTrunkGroupLabel: {
            type: 'string',
        },
        ingressReleaseCode: {
            type: 'number',
        },
        ingressPdd: {
            type: 'number',
        },
        ingressJitter: {
            type: 'number',
        },
        ingressMos: {
            type: 'number',
        },
        ingressPacketsSent: {
            type: 'number',
        },
        ingressPacketsReceived: {
            type: 'number',
        },
        egressPdd: {
            type: 'number',
        },
        egressJitter: {
            type: 'number',
        },
        egressMos: {
            type: 'number',
        },
        egressPacketsSent: {
            type: 'number',
        },
        egressPacketsReceived: {
            type: 'number',
        },
        ingressCountry: {
            type: 'string',
        },
        ingressRateCenterId: {
            type: 'number',
        },
        egressConnectionId: {
            type: 'number',
        },
        vendorAccountId: {
            type: 'number',
        },
        vendorSubscriptionId: {
            type: 'number',
        },
        egressTrunkGroup: {
            type: 'string',
        },
        egressTrunkGroupLabel: {
            type: 'string',
        },
        egressReleaseCode: {
            type: 'number',
        },
        egressRateCenterId: {
            type: 'number',
        },
        egressCountry: {
            type: 'string',
        },
        dialCode: {
            type: 'number',
        },
        callType: {
            type: 'string',
        },
        attestation: {
            type: 'string',
        },
        attempts: {
            type: 'number',
        },
        connects: {
            type: 'number',
        },
        unanswered: {
            type: 'number',
        },
        answeredByRelease: {
            type: 'number',
        },
        asr: {
            type: 'number',
        },
        duration: {
            type: 'number',
        },
        aloc: {
            type: 'number',
        },
        sub6: {
            type: 'number',
        },
        sub18: {
            type: 'number',
        },
        sub30: {
            type: 'number',
        },
        jitter: {
            type: 'number',
        },
        pdd: {
            type: 'number',
        },
        fas: {
            type: 'number',
        },
        mos: {
            type: 'number',
        },
        isDestSame: {
            type: 'number',
        },
        isDestDetailSame: {
            type: 'number',
        },
        toIsDnc: {
            type: 'number',
        },
        frIsValid: {
            type: 'number',
        },
        frIsDno: {
            type: 'number',
        },
        countUniqueAnis: {
            type: 'number',
        },
        roboScore: {
            type: 'number',
        },
        fraudScore: {
            type: 'number',
        },
        countTokens: {
            type: 'number',
        },
        fileStreamId: {
            type: 'number',
        },
        ocn: {
            type: 'string',
        },
        ocnType: {
            type: 'string',
        },
        company: {
            type: 'string',
        },
        datetimeVal: {
            type: 'string',
        },
    },
};