import { RtxSipApiRoutes } from 'RtExports/routes';
import { FullSummaryIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IFixedRatingSummaryRouterTabs
	extends IProfileApplicationContainerTabs {
	Search: TabbedLayoutTabProps;
	Results: TabbedLayoutTabProps;
}

export const FixedRatingSummaryContainerTabs: IFixedRatingSummaryRouterTabs = {
	Search: {
		header: 'Search'
	},
	Results: {
		header: 'Results'
	}
};

export class FixedRatingSummaryRouterClass extends SipApplicationRouter<
	FullSummaryIndexResponse,
	FullSummaryIndexResponse,
	IFixedRatingSummaryRouterTabs
> {
	constructor() {
		super(
			'Fixed Rating Summary',
			'fixedRatingSummary',
			'callDate',
			FixedRatingSummaryContainerTabs
		);

		this.setIndexPermissions(RtxSipApiRoutes.FixedRating.Index.permissions);
		this.setProfilePermissions(RtxSipApiRoutes.FixedRating.Index.permissions);
	}

	public getProfileLabel(profile: FullSummaryIndexResponse): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const FixedRatingSummaryRouter = new FixedRatingSummaryRouterClass();
