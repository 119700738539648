/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $AccountUpdateRequest = {
    properties: {
        isActive: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        companyName: {
            type: 'string',
            isRequired: true,
        },
        billingEntityId: {
            type: 'number',
            isRequired: true,
        },
        timezoneId: {
            type: 'number',
            isRequired: true,
        },
        salesUserId: {
            type: 'number',
            isRequired: true,
        },
        serviceUserId: {
            type: 'number',
            isRequired: true,
        },
        rateAmendmentTypeId: {
            type: 'RateAmendmentType',
        },
        rateAmendmentSubjectAtoZ: {
            type: 'string',
            isRequired: true,
        },
        rateAmendmentSubjectCountry: {
            type: 'string',
            isRequired: true,
        },
    },
};