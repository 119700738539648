/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionValidationIndexResponse = {
    properties: {
        errorId: {
            type: 'RouteTrustValidationError',
            isRequired: true,
        },
        errorLevelId: {
            type: 'RouteTrustValidationErrorLevel',
            isRequired: true,
        },
        propKey: {
            type: 'string',
        },
        message: {
            type: 'string',
            isRequired: true,
        },
    },
};