import { FC, FormEventHandler } from 'react';
import { ServerGroupProfileResponse } from 'RtModels';
import { ServerGroupResource } from 'RtUi/app/rtAdmin/ServerGroups/lib/resource/ServerGroupResource';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';

interface IServerGroupEditorProps {
	editMode?: ServerGroupProfileResponse;
	onUpdate?: (updatedMetadata: ServerGroupProfileResponse) => void;
	onCancel?: () => void;
}

export const ServerGroupEditor: FC<IServerGroupEditorProps> = ({
	onUpdate,
	editMode,
	onCancel
}) => {
	const serverGroupResource = new ServerGroupResource();

	const [formState, formMethods] = useRtUiFormEditor({
		createMode: !editMode,
		editMode,
		createNew: () => serverGroupResource.createNew()
	});
	const { workingCopy } = formState;

	const onSubmit: FormEventHandler<HTMLFormElement> = async (evt) => {
		evt.preventDefault();

		formMethods.setError(undefined);
		formMethods.setIsSubmitting(true);

		try {
			let updatedMetadata: ServerGroupProfileResponse;

			if (editMode) {
				updatedMetadata = await serverGroupResource.update(
					editMode.serverGroupId,
					formState.workingCopy
				);
			} else {
				updatedMetadata = await serverGroupResource.create(
					formState.workingCopy
				);
			}
			if (onUpdate) {
				onUpdate(updatedMetadata);
			}

			formMethods.setIsSubmitting(false);
			formMethods.setDisplayMode(true);
		} catch (err) {
			formMethods.setIsSubmitting(false);
			formMethods.setError(err);
		}
	};

	return (
		<RtUiWideForm
			{...formState}
			onSubmit={onSubmit}
			onChange={formMethods.setDisplayMode}
			onCancel={() => {
				formMethods.onCancel();

				if (onCancel) {
					onCancel();
				}
			}}
		>
			<InputFormControl
				label="Summary"
				displayMode={formState.displayMode}
				value={workingCopy.summary}
				onChange={(summary) => {
					formMethods.setWorkingCopy({ summary });
				}}
			/>
			<BooleanRadioFormControl
				label="Active"
				showBothOption={false}
				onChange={(isActive) => {
					formMethods.setWorkingCopy({ isActive });
				}}
				value={workingCopy.isActive}
			/>
			<BooleanRadioFormControl
				label="Media"
				showBothOption={false}
				onChange={(isMedia) => {
					formMethods.setWorkingCopy({ isMedia });
				}}
				value={workingCopy.isMedia}
			/>
		</RtUiWideForm>
	);
};
