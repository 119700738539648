import {
	LcrTaskCarrierResultIndexRequest,
	LcrTaskCarrierResultIndexResponse
} from 'RtModels';
import { useGetLcrTaskCarrierResults } from 'RtUi/app/rtLco/TaskResults/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLcrTaskCarrierResultsColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/lcrTaskResults';
import { useMemo } from 'react';

interface ILcrTaskCarrierResultsGridProps {
	resourceParams: LcrTaskCarrierResultIndexRequest;
	autoFocusFilter?: boolean;
}

export const LcrTaskCarrierResultsGrid = ({
	resourceParams
}: ILcrTaskCarrierResultsGridProps) => {
	const { data, isFetching: isLoading } =
		useGetLcrTaskCarrierResults(resourceParams);
	const columns = useMemo(() => getLcrTaskCarrierResultsColumns(), []);

	return (
		<DataGrid<LcrTaskCarrierResultIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtLco_lcrTaskCarrierResults'}
		/>
	);
};
