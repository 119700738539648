import { TimeFilterDetailKeys, UsageAuditByDayIndexResponse } from 'RtModels';
import { calculatePercentVariance } from 'RtUi/app/rtVue/ReconciliationUsage/lib/utils/VarianceHelpers';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { ColorPercentageDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ColorPercentageDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PercentDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PercentDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';

export const usageAuditByDayColumns = (
	handleUpdate: (usageDetailId: number, newValue: number) => void,
	callDateFormat?: string,
	selectedDetailLevel?: TimeFilterDetailKeys
): Array<DataGridColumn<UsageAuditByDayIndexResponse>> => [
	IdDataGridColumn({
		accessorKey: 'reconciliationId',
		header: 'Id',
		onDrillDown: (e, row) => {
			e.preventDefault();
			ApplicationContainer.openNewTab(
				`/rtVue/reconciliationUsageDetails/${row.reconciliationId}/${row.reconciliationUsageDetailId}?tab=Summary`
			);
		}
	}),
	DateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Date',
		format: callDateFormat
	}),
	DefaultDataGridColumn({
		accessorKey: 'reconciliationSummary',
		header: 'Reconciliation Summary',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'mappingDescription',
		header: 'Description'
	}),
	DefaultDataGridColumn({
		accessorKey: 'ban',
		header: 'Ban',
		isHidden: true
	}),
	HeaderDataGridColumn({
		header: 'Source (RouteTrust)',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'srcConnects',
				header: 'Connects',
				isHidden: true
			}),
			PrecisionDataGridColumn({
				accessorKey: 'srcDuration',
				header: 'Duration',
				decimals: 2,
				isHidden: true
			}),
			PrecisionDataGridColumn({
				accessorKey: 'srcCost',
				header: 'Cost'
			}),
			PrecisionDataGridColumn({
				decimals: 6,
				accessorKey: 'srcCpm',
				header: 'Cpm',
				isHidden: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Target (Vendor)',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'tgtConnects',
				header: 'Connects',
				isHidden: true
			}),
			PrecisionDataGridColumn({
				accessorKey: 'tgtDuration',
				header: 'Duration',
				decimals: 2,
				isHidden: true
			}),
			PrecisionDataGridColumn({
				accessorKey: 'tgtCost',
				header: 'Cost'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'tgtCpm',
				header: 'Cpm',
				decimals: 6,
				isHidden: true
			})
		]
	}),
	ColorPercentageDataGridColumn({
		accessorFn: (row: any) =>
			calculatePercentVariance(row.srcDuration, row.tgtDuration),
		header: 'MOU Variance',
		isHidden: true
	}),
	ColorPercentageDataGridColumn({
		accessorFn: (row: any) =>
			calculatePercentVariance(row.srcCost, row.tgtCost),
		header: 'Cost Variance',
		isHidden: true
	}),
	PercentDataGridColumn({
		accessorKey: 'matchedPercent',
		header: 'Matched Percent',
		isHidden: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'carrierInvoiceCharge',
		header: 'Invoice Usage Cost',
		isHidden: selectedDetailLevel === TimeFilterDetailKeys.Days,
		enableEditing: selectedDetailLevel !== TimeFilterDetailKeys.Days,
		muiEditTextFieldProps: ({ row: { original } }) => ({
			type: 'number',
			InputProps: {
				inputProps: {
					step: 0.01
				}
			},
			onBlur: (event) => {
				const targetValue = Number(event.target.value);
				if (targetValue !== original.carrierInvoiceCharge) {
					handleUpdate(
						original.reconciliationUsageDetailId as any,
						targetValue
					);
				}
			}
		})
	})
];
