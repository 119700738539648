/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ProductId {
    RT_800 = 1,
    RT_DID = 2,
    RT_SIP = 3,
    RT_LCO = 4,
    RT_ADM = 5,
    RT_VUE = 6,
    RT_CARRIER_CONNECT = 7,
    RT_COMMON = 8,
    RT_GUARDIAN = 9,
    RT_LCR = 10,
}