import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	LcrCarrierIndexRequest,
	LcrCarrierIndexResponse,
	LcrCarrierProfileRequest,
	LcrCarrierRateIndexRequest,
	LcrCarrierRateIndexResponse,
	LcrCarrierStateProfileResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchCarriers = async (
	queryParams?: LcrCarrierIndexRequest
): Promise<FullResponse<LcrCarrierIndexResponse[]>> => {
	return handleGetRequest<LcrCarrierIndexResponse[], true>(
		Rt800ApiRoutes.LcrCarriers.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetCarriers = (queryParams?: LcrCarrierIndexRequest) => {
	return useQuery<FullResponse<LcrCarrierIndexResponse[]>, Error>(
		['getCarriers', queryParams],
		() => fetchCarriers(queryParams)
	);
};

const fetchRatingSetup = async (
	urlParams?: LcrCarrierProfileRequest
): Promise<FullResponse<LcrCarrierStateProfileResponse[]>> => {
	return handleGetRequest<LcrCarrierStateProfileResponse[], true>(
		Rt800ApiRoutes.LcrCarrierStates.Index,
		{
			includeFullResponse: true,
			urlParams
		}
	);
};

export const useGetRatingSetup = (urlParams?: LcrCarrierProfileRequest) => {
	return useQuery<FullResponse<LcrCarrierStateProfileResponse[]>, Error>(
		['getRatingSetup', urlParams],
		() => fetchRatingSetup(urlParams)
	);
};

const fetchCarrierRates = async (
	urlParams?: LcrCarrierProfileRequest,
	queryParams?: LcrCarrierRateIndexRequest
): Promise<FullResponse<LcrCarrierRateIndexResponse[]>> => {
	return handleGetRequest<LcrCarrierRateIndexResponse[], true>(
		Rt800ApiRoutes.LcrCarrierRates.Index,
		{
			includeFullResponse: true,
			urlParams,
			queryParams
		}
	);
};

export const useGetCarrierRates = (
	urlParams?: LcrCarrierProfileRequest,
	queryParams?: LcrCarrierRateIndexRequest
) => {
	return useQuery<FullResponse<LcrCarrierRateIndexResponse[]>, Error>(
		['getCarrierRate', urlParams, queryParams],
		() => fetchCarrierRates(urlParams, queryParams)
	);
};
