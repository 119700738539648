import moment from 'moment';
import { FC, FormEvent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
	GuardianReconciliationChargeDetailProfileResponse,
	GuardianReconciliationStatus,
	UserIndexResponse
} from 'RtModels';
import { ReconciliationChargesResource } from 'RtUi/app/rtVue/ReconciliationCharge/lib/resources/ReconciliationChargesResource';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { GuardianReconciliationStatusSelect } from 'RtUi/app/rtVue/GuardianReconciliation/lib/controls/GuardianReconciliationStatusSelect';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { CurrencyInputFormControl } from 'RtUi/components/form/CurrencyInputFormControl';

interface IReconciliationChargeEditorProps {
	editMode?: GuardianReconciliationChargeDetailProfileResponse;
	createMode?: boolean;
	onUpdate?: (
		newProfile: GuardianReconciliationChargeDetailProfileResponse
	) => void;
	initialReconciliationId?: number;
}

export const ReconciliationChargesEditor: FC<
	React.PropsWithChildren<IReconciliationChargeEditorProps>
> = ({
	editMode,
	createMode,
	onUpdate = () => ({}),
	initialReconciliationId
}) => {
	const resource = new ReconciliationChargesResource();
	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		editMode,
		createMode,
		createNew: () => resource.createNewProfile(initialReconciliationId ?? -1)
	});
	const [assignedTo, setAssignedTo] = useState<UserIndexResponse>();
	const [disputeStatus, setDisputeStatus] = useState<
		ISimpleSelectFormControlOption<GuardianReconciliationStatus> | undefined
	>();

	const { workingCopy } = rtUiFormState;

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			let newProfile: GuardianReconciliationChargeDetailProfileResponse;

			if (createMode) {
				newProfile = await resource.create(workingCopy);
			} else {
				newProfile = await resource.update(
					workingCopy.guardianReconciliationChargeDetailId,
					workingCopy
				);
			}

			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setDisplayMode(true);

			onUpdate(newProfile);
		} catch (err) {
			rtUiFormMethods.setError(err);
			rtUiFormMethods.setIsSubmitting(false);
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			onSubmit={onSubmit}
			onChange={rtUiFormMethods.setDisplayMode}
			onCancel={() => {
				setAssignedTo(undefined);
				setDisputeStatus(undefined);
				rtUiFormMethods.onCancel();
			}}
		>
			<Row>
				<Col lg={12}>
					<UserSelect
						displayMode={rtUiFormState.displayMode}
						label="Assigned To"
						onChange={(assignedTo) => {
							setAssignedTo(assignedTo);
							rtUiFormMethods.setWorkingCopy({
								assignedTo: assignedTo?.userId
							});
						}}
						value={assignedTo}
						initialOptionId={
							workingCopy.assignedTo
								? workingCopy.assignedTo.toString()
								: undefined
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputFormControl
						label="Internal Item Id"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.internalItemId?.toString()}
						onChange={(internalItemId) => {
							rtUiFormMethods.setWorkingCopy({ internalItemId });
						}}
					/>
				</Col>
				<Col>
					<InputFormControl
						autoHeight
						label="Internal Item Label"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.internalItemLabel?.toString()}
						onChange={(internalItemLabel) => {
							rtUiFormMethods.setWorkingCopy({ internalItemLabel });
						}}
					/>
				</Col>
			</Row>
			<hr />
			<Row className="align-items-end row-eq-height mt-4 mb-4">
				<Col className="border-right">
					<CurrencyInputFormControl
						label="Account Invoice Charge"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.carrierInvoiceCharge}
						onChange={(carrierInvoiceCharge) => {
							rtUiFormMethods.setWorkingCopy({ carrierInvoiceCharge });
						}}
					/>
				</Col>
				<Col className="border-right">
					<CurrencyInputFormControl
						label="Account Invoice Charge Detail"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.carrierInvoiceChargeDetail}
						onChange={(carrierInvoiceChargeDetail) => {
							rtUiFormMethods.setWorkingCopy({ carrierInvoiceChargeDetail });
						}}
					/>
				</Col>
				<Col className="border-right">
					<CurrencyInputFormControl
						label="Expected Charge"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.expectedCharge}
						onChange={(expectedCharge) => {
							rtUiFormMethods.setWorkingCopy({ expectedCharge });
						}}
					/>
				</Col>
				<Col className="border-right">
					<InputFormControl
						label="Invoice Item Id"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.invoiceItemId?.toString()}
						onChange={(invoiceItemId) => {
							rtUiFormMethods.setWorkingCopy({ invoiceItemId });
						}}
					/>
				</Col>
				<Col>
					<BooleanRadioFormControl
						label="Recurring"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.recurring}
						onChange={(recurring) => {
							rtUiFormMethods.setWorkingCopy({ recurring });
						}}
					/>
				</Col>
				{!createMode && (
					<Col className="border-start">
						<InputFormControl
							type="number"
							label="RT Charge"
							displayMode={true}
							value={workingCopy.rtCharge?.toString()}
						/>
					</Col>
				)}
			</Row>
			<hr />
			<Row>
				<Col lg={6}>
					<GuardianReconciliationStatusSelect
						label="Dispute Status"
						displayMode={rtUiFormState.displayMode}
						value={disputeStatus}
						onChange={(disputeStatus) => {
							setDisputeStatus(disputeStatus);
							rtUiFormMethods.setWorkingCopy({
								disputeStatus: disputeStatus.value
							});
						}}
						initialOptionId={
							workingCopy.disputeStatus
								? workingCopy.disputeStatus.toString()
								: undefined
						}
					/>
					<InputFormControl
						type="number"
						label="Dispute Id"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.disputeId?.toString()}
						onChange={(disputeId) => {
							rtUiFormMethods.setWorkingCopy({ disputeId });
						}}
					/>
					<CurrencyInputFormControl
						label="Disputed Amount"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.disputedAmount}
						onChange={(disputedAmount) => {
							rtUiFormMethods.setWorkingCopy({ disputedAmount });
						}}
					/>
					<DatetimeFormControl
						label="Dispute Filed Date"
						disablePresets
						displayMode={rtUiFormState.displayMode}
						onChange={(disputeFiledTs) => {
							rtUiFormMethods.setWorkingCopy({
								disputeFiledTs: disputeFiledTs?.toDate()
							});
						}}
						value={
							workingCopy.disputeFiledTs
								? moment(workingCopy.disputeFiledTs)
								: undefined
						}
					/>
				</Col>
				<Col lg={6}>
					<CurrencyInputFormControl
						label="Approved Amount"
						displayMode={rtUiFormState.displayMode}
						value={workingCopy.approvedAmount}
						onChange={(approvedAmount) => {
							rtUiFormMethods.setWorkingCopy({ approvedAmount });
						}}
					/>
					<DatetimeFormControl
						label="Dispute Settlement Date"
						disablePresets
						displayMode={rtUiFormState.displayMode}
						onChange={(disputeSettlementTs) => {
							rtUiFormMethods.setWorkingCopy({
								disputeSettlementTs: disputeSettlementTs?.toDate()
							});
						}}
						value={
							workingCopy.disputeSettlementTs
								? moment(workingCopy.disputeSettlementTs)
								: undefined
						}
					/>
					<DatetimeFormControl
						label="Dispute Received Date"
						disablePresets
						displayMode={rtUiFormState.displayMode}
						onChangeWithIsNow={(disputeReceivedTs) => {
							rtUiFormMethods.setWorkingCopy({
								disputeReceivedTs: disputeReceivedTs?.toDate() ?? null
							});
						}}
						value={
							workingCopy.disputeReceivedTs
								? moment(workingCopy.disputeReceivedTs)
								: undefined
						}
					/>
					<DatetimeFormControl
						label="Escalation Date"
						disablePresets
						displayMode={rtUiFormState.displayMode}
						onChange={(escalationTs) => {
							rtUiFormMethods.setWorkingCopy({
								escalationTs: escalationTs?.toDate()
							});
						}}
						value={
							workingCopy.escalationTs
								? moment(workingCopy.escalationTs)
								: undefined
						}
					/>
				</Col>
			</Row>
		</RtUiForm>
	);
};
