import { setMonth, format } from 'date-fns';
import { FC } from 'react';
import { useAsync } from 'react-use';
import { GuardianReconciliationDisputeAmountResource } from 'RtUi/app/rtVue/GuardianReconciliationDashboard/lib/resources/GuardianReconciliationDisputeAmountResource';
import { GuardianReconciliationDisputeCountResource } from 'RtUi/app/rtVue/GuardianReconciliationDashboard/lib/resources/GuardianReconciliationDisputeCountResource';
import { formatToCurrency } from 'RtUi/components/form/CurrencyInputFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { ChartWrapper } from 'RtUi/app/rtVue/common/lib/charts/ChartWrapper';

// Dispute Filled, Dispute Received, Dispute Settlement
const colors = ['#ff4560', '#feb019', '#00e396'];

const createMonthsNamesFromNumbers = (monthNumbers: number[]): string[] =>
	Array.from<number, string>(monthNumbers, (mn) =>
		format(setMonth(new Date(), mn - 1), 'LLL')
	);

export const DisputeByMonthChart: FC<
	React.PropsWithChildren<{
		precision?: number;
		formatAsCurrency?: boolean;
		resource:
			| GuardianReconciliationDisputeCountResource
			| GuardianReconciliationDisputeAmountResource;
	}>
> = ({ resource, precision = 0, formatAsCurrency = false }) => {
	const state = useAsync(async () => {
		const result = await resource.getAllWithoutCache();
		return result;
	}, []);

	if (state.loading) {
		return <Loading />;
	}

	const result = state.value!;

	return (
		<ChartWrapper
			config={{
				plugins: {
					// @ts-expect-error
					tooltips: {
						enabled: true,
						mode: 'single',
						callbacks: {
							// @ts-expect-error
							label: function (tooltipItems, data) {
								return `${data.datasets![tooltipItems.datasetIndex!].label}: ${
									formatAsCurrency
										? formatToCurrency(tooltipItems.value).toString()
										: tooltipItems.value
								}`;
							}
						}
					}
				},
				data: {
					labels: createMonthsNamesFromNumbers([
						result[0].monthNumber3MonthsAgo,
						result[0].monthNumber2MonthsAgo,
						result[0].monthNumber1MonthsAgo
					]),
					datasets: result.map((v, i) => ({
						type: 'line',
						label: v.label,
						data: [v.value3MonthAgo, v.value2MonthAgo, v.value1MonthAgo],
						backgroundColor: colors[i]
					}))
				}
			}}
		/>
	);
};
