import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface HeaderDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	columns: Array<DataGridColumnConfiguration<T>>;
}

export const HeaderDataGridColumn = <T = any,>({
	columns,
	...config
}: HeaderDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return DefaultDataGridColumn({
		...config,
		columns
	});
};
