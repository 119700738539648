import { CdrExportDestinationIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { CdrExportDestinationTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CdrExportDestinationTypeDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { JSONDataGridColumn } from 'RtUi/components/data/DataGrid/columns/JSONDataGridColumn';
import { isValidJson } from 'RtUi/utils/json';

export const getCdrExportControlDestinationColumns = (
	onDelete: (row: CdrExportDestinationIndexResponse) => void
): Array<DataGridColumn<CdrExportDestinationIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: onDelete
	}),
	CdrExportDestinationTypeDataGridColumn({
		accessorKey: 'destinationTypeId',
		header: 'Destination Type'
	}),
	JSONDataGridColumn({
		header: 'Credentials',
		accessorFn: (row) => {
			const credentials = JSON.stringify(row.credentials);
			return isValidJson(credentials) ? credentials : undefined;
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'remotePath',
		header: 'Remote Path'
	})
];
