import * as React from 'react';
import * as moment from 'moment';

interface ITimeFromNowProps {
	value: moment.Moment;
	className?: string;
	withoutSuffix?: boolean;
}

interface ITimeFromNowState {
	fromNowText: string;
}

export class TimeFromNow extends React.Component<
	ITimeFromNowProps,
	ITimeFromNowState
> {
	public timeoutId = -1;
	public state: ITimeFromNowState = {
		fromNowText: ''
	};

	public componentDidMount() {
		this.updateFromNowText();
	}

	public updateFromNowText() {
		const { value, withoutSuffix } = this.props;

		const fromNowText = value.fromNow(withoutSuffix);

		this.setState({ fromNowText });

		const now = moment();
		const diffInMinutes = now.diff(value, 'minutes');
		let timeoutInSeconds = 60; // minute

		if (diffInMinutes > 60) {
			timeoutInSeconds = 60 * 60; // hour
		}

		const timeoutInMs = timeoutInSeconds * 1000;

		this.timeoutId = window.setTimeout(
			() => this.updateFromNowText(),
			timeoutInMs
		);
	}

	public componentWillUnmount() {
		window.clearTimeout(this.timeoutId);
	}

	public render() {
		return (
			<span className={this.props.className}>{this.state.fromNowText}</span>
		);
	}
}
