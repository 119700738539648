import { NumberGrid } from 'RtUi/app/rt800/Numbers/lib/grids/NumberGrid';
import { RespOrgGrid } from 'RtUi/app/rt800/RespOrgs/lib/grids/RespOrgGrid';
import { RoutingGroupsGrid } from 'RtUi/app/rt800/RoutingGroups/lib/grids/RoutingGroupsGrid';
import { TemplateGrid } from 'RtUi/app/rt800/Templates/lib/grids/TemplateGrid';
import { NpaTotalsLineChart } from 'RtUi/components/charts/NpaTotalsLineChart';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { EntityRouter } from './Entity.router';
import { EntityEditor } from './lib/forms/EntityEditor';
import { EntityResource } from './lib/resources/EntityResource';
import { EntityProfileResponse } from 'RtModels';
import { FC, useState } from 'react';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { useMount } from 'react-use';

export const EntityProfileContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const entityId = useIdParam();
	const [tabs, activeTab, setActiveTab] = useTabs(
		EntityRouter.getProfileTabs()
	);
	const [entity, setEntity] = useState<EntityProfileResponse>();
	useMount(async () => {
		const resource = new EntityResource();
		const newEntity = await resource.get(entityId);

		setEntity(newEntity);
	});

	if (!entity) {
		return null;
	}

	const canManageWithRecord = EntityRouter.canManageWith(entity);

	return (
		<TabbedLayout<EntityProfileResponse>
			router={EntityRouter}
			profile={entity}
			canManageFn={() => canManageWithRecord}
			activeTab={activeTab.header}
			onTabChange={(at) => {
				setActiveTab(at);
			}}
		>
			<TabbedLayoutTab {...tabs.Dashboard}>
				<NpaTotalsLineChart id={entityId} type="Entity" />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Profile}>
				<EntityEditor
					editMode={entity}
					onUpdateSuccess={(newEntity) => {
						setEntity(newEntity);
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Numbers}>
				<NumberGrid resourceParams={{ entityId }} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.RespOrgs}>
				<RespOrgGrid resourceParams={{ entityId }} />
			</TabbedLayoutTab>
			{canManageWithRecord && (
				<TabbedLayoutTab {...tabs.Templates}>
					<TemplateGrid resourceParams={{ entityId, isActive: 1 }} />
				</TabbedLayoutTab>
			)}
			{canManageWithRecord && (
				<TabbedLayoutTab {...tabs.RoutingGroups}>
					<RoutingGroupsGrid resourceParams={{ entityId, isActive: 1 }} />
				</TabbedLayoutTab>
			)}
		</TabbedLayout>
	);
};

EntityRouter.setProfileRtUiFunctionalComponent(EntityProfileContainer);
