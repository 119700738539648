import { LcrRateSheetType } from 'RtModels';
import { ILcrCarrierRateDtoExtended } from 'RtUi/app/rtLco/Carriers/lib/bin/CarrierRatesSpreadsheetParser';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCarrierRatesDtoColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/carriers';
import { useMemo } from 'react';

interface ILcrCarrierRatesDtoGridProps {
	rateSheetTypeId: LcrRateSheetType;
	rates: ILcrCarrierRateDtoExtended[];
	disableCardAppearance?: boolean;
	disableCollapse?: boolean;
}

export const LcrCarrierRatesDtoGrid = ({
	rateSheetTypeId,
	rates
}: ILcrCarrierRatesDtoGridProps) => {
	const { rateKeyHeader1, rateKeyHeader2 } = useMemo(() => {
		const keys = {
			rateKeyHeader1: 'NPA',
			rateKeyHeader2: 'NXX'
		};

		if (rateSheetTypeId === 2) {
			return {
				rateKeyHeader1: 'LATA',
				rateKeyHeader2: 'OCN'
			};
		}

		return keys;
	}, [rateSheetTypeId]);

	const columns = useMemo(
		() => getCarrierRatesDtoColumns(rateKeyHeader1, rateKeyHeader2),
		[rateKeyHeader1, rateKeyHeader2]
	);

	return (
		<DataGrid<ILcrCarrierRateDtoExtended>
			pageName="rtLco-carriersProfileRatesDto"
			data={rates}
			columns={columns}
		/>
	);
};
