import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface TollFreeNumberDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	isSuggestion?: (record: T) => boolean;
}

export const TollFreeNumberDataGridColumn = <T = any,>({
	isSuggestion = () => false,
	...config
}: TollFreeNumberDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: string, record: T) => {
		return TollFreeNumbersUtils.tollFreeNumberToReadableString(
			value ?? '',
			true,
			false,
			isSuggestion(record)
		);
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value, row) => getValue(value, row as T),
		getValue: ({ cell, row }) =>
			getValue(cell.getValue<string>(), row.original as T)
	});
};
