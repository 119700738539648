import { GuardianReconciliationChargeDetailCreateRequest } from 'RtModels';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getReconciliationChargeCreateColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/reconciliationCharges';
import { useMemo } from 'react';

interface IReconciliationChargesCreateDataGridProps {
	data: GuardianReconciliationChargeDetailCreateRequest[];
}

export const ReconciliationChargesCreateDataGrid = ({
	data
}: IReconciliationChargesCreateDataGridProps) => {
	const columns = useMemo(() => getReconciliationChargeCreateColumns(), []);

	return (
		<DataGrid<GuardianReconciliationChargeDetailCreateRequest>
			data={data}
			columns={columns}
			pageName={'rtVue_reconciliationChargesCreate'}
		/>
	);
};
