import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { Integrations } from 'RtModels';

export interface IIntegrationOption {
	value: Integrations;
	label: string;
}

export class IntegrationResource extends ArrayResource<IIntegrationOption> {
	constructor() {
		super('value');

		const integrationKeys = Object.keys(Integrations)
			.filter((possibleKey) => isNaN(Number(possibleKey)))
			.sort((k1, k2) => k1.localeCompare(k2));

		const records = integrationKeys.map((integrationKey) => {
			const label = integrationKey;
			const value = Integrations[integrationKey as keyof typeof Integrations];

			return { value, label };
		});

		this.setAllData(records);
	}
}
