import { VendorReconciliationIndexResponseData } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';

export const getReconciliationColumns = (): Array<
	DataGridColumn<VendorReconciliationIndexResponseData>
> => [
	DateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date'
	}),
	HeaderDataGridColumn({
		header: 'Call Type',
		columns: [
			DefaultDataGridColumn({
				accessorKey: 'callType',
				header: 'Call Type'
			}),
			DefaultDataGridColumn({
				accessorKey: 'callType2',
				header: 'Call Type 2'
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Call Count',
		columns: [
			IntegerDataGridColumn({
				accessorKey: 'callCount',
				header: 'Call Count'
			}),
			IntegerDataGridColumn({
				accessorKey: 'callCount2',
				header: 'Call Count 2'
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Jurisdiction',
		columns: [
			DefaultDataGridColumn({
				accessorKey: 'jurisdiction',
				header: 'Jurisdiction'
			}),
			DefaultDataGridColumn({
				accessorKey: 'jurisdiction2',
				header: 'Jurisdiction 2'
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Cost',
		columns: [
			PrecisionDataGridColumn({
				accessorKey: 'cost',
				header: 'Cost'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'cost2',
				header: 'Cost 2'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'costDiff',
				header: 'Cost Diff'
			}),
			PrecisionDataGridColumn({
				header: 'Cost %',
				accessorFn: (row) => {
					const cost = Number(row.cost);
					const cost2 = Number(row.cost2);
					return ((cost - cost2) / cost) * 100;
				}
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Duration Ms',
		columns: [
			PrecisionDataGridColumn({
				accessorKey: 'duration',
				header: 'Duration'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'duration2',
				header: 'Duration 2'
			}),
			PrecisionDataGridColumn({
				accessorKey: 'durationDiff',
				header: 'Duration Diff'
			}),
			PrecisionDataGridColumn({
				header: 'Duration %',
				accessorFn: (row) => {
					const duration = Number(row.duration);
					const duration2 = Number(row.duration2);

					return ((duration - duration2) / duration) * 100;
				}
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Ingress Trunk Group',
		columns: [
			DefaultDataGridColumn({
				accessorKey: 'ingressTrunkGroup',
				header: 'Ingress Trunk Group'
			}),
			DefaultDataGridColumn({
				accessorKey: 'ingressTrunkGroup2',
				header: 'Ingress Trunk Group 2'
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Egress Trunk Group',
		columns: [
			DefaultDataGridColumn({
				accessorKey: 'egressTrunkGroup',
				header: 'Egress Trunk Group'
			}),
			DefaultDataGridColumn({
				accessorKey: 'egressTrunkGroup2',
				header: 'Egress Trunk Group 2'
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Ingress Rate Center',
		columns: [
			RateCenterDataGridColumn({
				accessorKey: 'ingressRateCenterId',
				header: 'Ingress Rate Center',
				hiddenIfEmpty: true
			}),
			DefaultDataGridColumn({
				accessorKey: 'ingressRateCenterId2',
				header: 'Ingress Rate Center 2',
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Egress Rate Center',
		columns: [
			RateCenterDataGridColumn({
				accessorKey: 'egressRateCenterId',
				header: 'Egress Rate Center',
				hiddenIfEmpty: true
			}),
			DefaultDataGridColumn({
				accessorKey: 'egressRateCenterId2',
				header: 'Egress Rate Center 2',
				hiddenIfEmpty: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Dial Code',
		columns: [
			DefaultDataGridColumn({
				accessorKey: 'dialCode',
				header: 'Dial Code',
				hiddenIfEmpty: true
			}),
			DefaultDataGridColumn({
				accessorKey: 'dialCode2',
				header: 'Dial Code 2',
				hiddenIfEmpty: true
			})
		]
	})
];
