import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import * as React from 'react';
import {
	getEndDateFromPreset,
	getStartDateFromPreset,
	GetTimeFilterDetailsForDurationInHours,
	GetTimeFilterDetailsForPresetHours,
	ITimeFilterDetailLevelsHours,
	TimeFilterDetailLevelsHours,
	TimeRangePresetsOverlay,
	TimeRangePresetsToNameMapping
} from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
	TimeFilter,
	TimeFilterDetail,
	TimeFilterDetailKeys,
	TimeRangePresets
} from 'RtModels';
import cancelClosePlugin from 'RtUi/app/rtVue/common/lib/components/FlatDateRangePicker/plugins/cancelClose';
import customConfirmDatePlugin from 'RtUi/app/rtVue/common/lib/components/FlatDateRangePicker/plugins/customConfirmDate';
import { SimpleSelectFormControl } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { cloneDeep, find, isEqual } from 'lodash-es';
import * as dateFns from 'date-fns';
import moment from 'moment';

interface IFlatDateRangePickerProps {
	value: TimeFilter;
	onChange: (value: TimeFilter) => void;
	timeRangePresetsKeys: string[];
	timeRangeAvailableDetails?: string[];
	maxDate?: Date;
	minDate?: Date;
	shouldUpdateDatePicker?: boolean;
	updateDetailOnDateChange?: boolean;
	hideDetailsOption?: boolean;
	displayMode?: boolean;
	shouldIncludeMonthly?: boolean;
}

interface IFlatDateRangePickerState {
	presetValue: TimeRangePresets;
	startDate?: string;
	endDate?: string;
	detailLevel: ITimeFilterDetailLevelsHours;
	handleDetailLevelChange?: (detailLevel: ITimeFilterDetailLevelsHours) => void;
	detailLevelValues: ITimeFilterDetailLevelsHours[];
	previousPreset: TimeRangePresets;
	isEndDateSelected?: boolean;
	previousStart?: string;
	previousEnd?: string;
	isCancel?: boolean;
	isSubmit?: boolean;
}

interface IDatesChangeConfig {
	startDate?: Date;
	endDate?: Date;
}

export class FlatDateRangePicker extends React.Component<
	IFlatDateRangePickerProps,
	IFlatDateRangePickerState
> {
	public dateRangePicker = React.createRef<Flatpickr>();
	public dateRangePickerReadonly = React.createRef<Flatpickr>();

	public state: IFlatDateRangePickerState = this.initiateState(true);

	// filling in the start date from props/url
	public getInitialStartDate(startDate?: Date | string): string | undefined {
		if (!startDate) {
			return undefined;
		}

		if (typeof startDate === 'string') {
			return startDate;
		}
		// bullet-proof check for instanceof Date
		if (typeof startDate.getMonth === 'function') {
			return startDate.toISOString();
		}
		// other cases
		if (typeof startDate === 'object') {
			return getStartDateFromPreset(
				this.props.value.timeObject.mode
			).toISOString();
		}
		return undefined;
	}

	// filling in the start date from props/url or now
	public getInitialEndDate(endDate?: Date | string): string | undefined {
		if (!endDate) {
			return undefined;
		}

		if (typeof endDate === 'string') {
			return endDate;
		}
		// other cases
		if (typeof endDate === 'object') {
			return endDate.toISOString();
		}
		return undefined;
	}

	/**
	 *  this method sets the initial state on first render and
	 * is also used for properly applying saved queries
	 * @param {boolean} isFirstInitiation - is used for Saved Query apply
	 * @returns {IFlatDateRangePickerState}
	 */
	public initiateState(isFirstInitiation = false): IFlatDateRangePickerState {
		const startFromProps = this.props.value.timeObject.start;
		const endFromProps = this.props.value.timeObject.end;
		// this condition is needed to pass through situations other than initial render and user query apply once
		const shouldTakeValueFromProps =
			isFirstInitiation || this.props.shouldUpdateDatePicker;
		// if no end date set - use now
		const isEndDateSet = Boolean(endFromProps);
		const parsedStartDate = isFirstInitiation
			? startFromProps
			: dateFns.parseISO(this.state.startDate!);
		const parsedEndDate = isFirstInitiation
			? endFromProps
			: dateFns.parseISO(this.state.endDate!);

		let startDate = startFromProps;
		let endDate = isEndDateSet ? endFromProps : new Date();

		if (!shouldTakeValueFromProps) {
			startDate = parsedStartDate;
			endDate = parsedEndDate;
		}

		// needed for granularity dropdowns calculations
		//@ts-ignore TODO should be date but string works (endDate)
		const durationInHours = dateFns.differenceInHours(endDate, startDate);
		// logics for saved queries
		if (this.props.shouldUpdateDatePicker) {
			this.dateRangePicker.current?.flatpickr.setDate([
				String(startDate).replace('Z', 'GMT+0000'),
				String(endDate).replace('Z', 'GMT+0000')
			]);
			this.dateRangePickerReadonly.current?.flatpickr.setDate([
				String(startDate).replace('Z', 'GMT+0000'),
				String(endDate).replace('Z', 'GMT+0000')
			]);
		}
		// not changing the existing state
		return cloneDeep({
			presetValue: this.props.value.timeObject.mode,
			startDate: this.getInitialStartDate(startDate),
			endDate: this.getInitialEndDate(endDate),
			detailLevel: this.getDetailLevelLabel(this.props.value.detailLevel),

			detailLevelValues: this.getDetailsLevelValuesFiltered(
				GetTimeFilterDetailsForDurationInHours(
					durationInHours,
					this.props.shouldIncludeMonthly
				)
			),
			previousPreset: this.props.value.timeObject.mode,
			isEndDateSelected: false,
			// same as actual
			previousStart: this.getInitialStartDate(startDate),
			previousEnd: this.getInitialEndDate(endDate),
			isCancel: false,
			isSubmit: false
		});
	}

	// this is needed for saved queries
	public componentDidUpdate(prevProps: Readonly<IFlatDateRangePickerProps>) {
		const newTimeObj = this.props.value.timeObject;
		const prevTimeObj = prevProps.value.timeObject;
		const hasStartChanged = newTimeObj.start !== prevTimeObj.start;
		const hasEndChanged = newTimeObj.end !== prevTimeObj.end;

		// check is needed for saved queries check
		if (
			(hasStartChanged || hasEndChanged) &&
			this.props.shouldUpdateDatePicker
		) {
			this.setState(this.initiateState(false));
		}
	}

	/**
	 * getting the list of granularity detail levels for the selected one
	 * @param {TimeFilterDetail} detailLevel
	 * @returns {TimeFilterDetail}
	 */
	public getDetailLevelLabel(detailLevel: TimeFilterDetail) {
		return TimeFilterDetailLevelsHours.find((detailLevelItem) => {
			return (
				detailLevelItem.key === detailLevel.key &&
				detailLevelItem.value === detailLevel.value
			);
		})!;
	}

	public getDetailsLevelValuesFiltered(
		detailsValues: ITimeFilterDetailLevelsHours[]
	) {
		if (!this.props.timeRangeAvailableDetails) {
			return detailsValues;
		}

		return detailsValues.filter((dv) =>
			this.props.timeRangeAvailableDetails?.includes(dv.key)
		);
	}

	/**
	 * used in FlatPicker - native DOM component
	 * @returns {string}
	 */
	public getSubmitBtn() {
		return `<button class="btn btn-submit btn-sm float-end m-2">
				<i class="fas fa-check fa-sm"></i><span>&nbsp;Update</span>
			</button>`;
	}

	/**
	 * handler of preset values selections - 1h, 1d, 3d etc
	 * @param {TimeRangePresets} presetValue
	 * @param {boolean} triggerOnChange - determining whether the network request should be performed
	 */
	public handlePresetChange(presetValue: TimeRangePresets) {
		let startDate = this.state.startDate
			? dateFns.parseISO(this.state.startDate)
			: undefined;
		let endDate = this.state.endDate
			? dateFns.parseISO(this.state.endDate)
			: undefined;

		const previousPreset = this.state.presetValue;
		const isCustomPresetSelected = presetValue === TimeRangePresets.Custom;

		// when switching to custom - it's needed to repopulate all values
		if (isCustomPresetSelected && endDate && startDate) {
			const duration = dateFns.differenceInHours(endDate, startDate);
			this.dateRangePicker.current?.flatpickr.open();

			const detailLevelValues = this.getDetailsLevelValuesFiltered(
				GetTimeFilterDetailsForDurationInHours(
					duration,
					this.props.shouldIncludeMonthly
				)
			);
			const detailLevel = detailLevelValues[detailLevelValues.length - 1];

			this.props.onChange({
				timeObject: {
					mode: TimeRangePresets.Custom
				},
				detailLevel
			});

			this.setState({
				previousPreset,
				detailLevelValues,
				presetValue,
				detailLevel,
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString()
			});

			return;
		}

		const detailLevelValues = this.getDetailsLevelValuesFiltered(
			GetTimeFilterDetailsForPresetHours(
				presetValue,
				this.props.shouldIncludeMonthly
			)
		);

		const monthDetailLevel = detailLevelValues.find(
			(detail) => detail.key === TimeFilterDetailKeys.Months
		);

		const isLastMonthPresetSelected =
			presetValue === TimeRangePresets.LastMonth;

		if (isLastMonthPresetSelected && monthDetailLevel) {
			this.props.onChange({
				timeObject: {
					mode: TimeRangePresets.LastMonth
				},
				detailLevel: monthDetailLevel
			});
			this.setState({
				previousPreset,
				detailLevelValues,
				presetValue,
				detailLevel: monthDetailLevel,
				startDate: startDate ? startDate.toISOString() : undefined,
				endDate: endDate ? endDate.toISOString() : undefined
			});

			return;
		}

		const monthToDatePresetSelected =
			presetValue === TimeRangePresets.MonthToDate;

		if (monthToDatePresetSelected && monthDetailLevel) {
			this.props.onChange({
				timeObject: {
					mode: TimeRangePresets.MonthToDate
				},
				detailLevel: monthDetailLevel
			});

			this.setState({
				previousPreset,
				detailLevelValues,
				presetValue,
				detailLevel: monthDetailLevel,
				startDate: startDate ? startDate.toISOString() : undefined,
				endDate: endDate ? endDate.toISOString() : undefined
			});

			return;
		}

		// by default - the biggest detail level is set
		let detailLevel = detailLevelValues[detailLevelValues.length - 1];

		if (
			[
				TimeRangePresets.ThreeDays,
				TimeRangePresets.Week,
				TimeRangePresets.LastMonth,
				TimeRangePresets.MonthToDate
			].includes(presetValue)
		) {
			detailLevel = detailLevelValues.find(
				(detailLevelValue) => detailLevelValue.key === TimeFilterDetailKeys.Days
			)!;
		}

		// standard date extraction for normal case
		endDate = getEndDateFromPreset(presetValue);
		startDate = getStartDateFromPreset(presetValue);

		// for 1h and 4h - endDate is right now
		if (
			[TimeRangePresets.OneHour, TimeRangePresets.FourHours].includes(
				presetValue
			)
		) {
			endDate = new Date();
		}

		// for 3d 1w and 30d - start form the beginning of the day 00:00 -- 23:59
		if (
			[
				TimeRangePresets.Day,
				TimeRangePresets.ThreeDays,
				TimeRangePresets.Week,
				TimeRangePresets.ThirtyDays,
				TimeRangePresets.Custom
			].includes(presetValue)
		) {
			startDate = dateFns.startOfDay(startDate);
			endDate = dateFns.endOfDay(new Date());
		}

		this.setState({
			previousPreset,
			detailLevelValues,
			presetValue,
			detailLevel,
			startDate: startDate ? startDate.toISOString() : undefined,
			endDate: endDate ? endDate.toISOString() : undefined
		});

		this.props.onChange({
			timeObject: {
				mode: presetValue,
				start: startDate,
				end: endDate
			},
			detailLevel: {
				key: detailLevel.key,
				value: detailLevel.value
			}
		});
	}

	/**
	 * Handling detail level change
	 * @param {string} detailLevel
	 */
	public handleDetailLevelChange(detailLevel: string) {
		const neededDetailLevel = find(TimeFilterDetailLevelsHours, {
			label: detailLevel
		});

		if (neededDetailLevel) {
			const { key, value } = neededDetailLevel;
			const timeFilter = cloneDeep(this.props.value);
			timeFilter.detailLevel = {
				key,
				value
			};

			this.setState({ detailLevel: neededDetailLevel }, () => {
				this.props.onChange(timeFilter);
			});
		}
	}

	/**
	 * on each range change it's needed to update the available details level
	 * it's inner method, used only for this component, does not expose data outside
	 * - for time range of one hour do not show '1d' and '1h' details levels
	 * - time range -'1d' - do not show '1d' details
	 * - time range more than week - do not show '5min', '10min' and '15min' details levels
	 */
	public updateDetailDropdown(callback?: () => void) {
		const duration = dateFns.differenceInHours(
			dateFns.parseISO(this.state.endDate!),
			dateFns.parseISO(this.state.startDate!)
		);
		const detailLevelValues = this.getDetailsLevelValuesFiltered(
			GetTimeFilterDetailsForDurationInHours(
				duration,
				this.props.shouldIncludeMonthly
			)
		);
		const detailLevel =
			detailLevelValues.find((detail) =>
				isEqual(detail, this.state.detailLevel)
			) ?? detailLevelValues[detailLevelValues.length - 1];
		this.setState({ detailLevelValues, detailLevel }, callback);
	}

	/**
	 * Handling any date select change when Custom preset is selected
	 * @param {config} IDatesChangeConfig
	 */
	public handleDatesChange(config: IDatesChangeConfig) {
		// we do not want changes is not submit button hit
		// if (!this.state.isSubmit) {
		// 	return;
		// }
		const { updateDetailOnDateChange = true } = this.props;
		let { startDate, endDate } = config;
		const isEndDateSet = Boolean(endDate);
		startDate = startDate
			? dateFns.startOfDay(startDate)
			: dateFns.parseISO(this.state.startDate!);
		endDate = isEndDateSet
			? dateFns.endOfDay(endDate!)
			: dateFns.endOfDay(startDate);

		const startISO = startDate.toISOString();
		const endISO = endDate.toISOString();
		const momentStart = moment(startDate);
		const momentEnd = moment(endDate);
		if (updateDetailOnDateChange && momentEnd.diff(momentStart, 'days') >= 7) {
			this.handleDetailLevelChange('Monthly');
		}

		this.setState(
			{
				startDate: startISO,
				endDate: endISO,
				isEndDateSelected: isEndDateSet,
				// updating previously selected values
				previousStart: startISO,
				previousEnd: endISO,
				isCancel: false,
				isSubmit: false
			},
			() => {
				this.updateDetailDropdown(() => {
					this.props.onChange({
						timeObject: {
							mode: TimeRangePresets.Custom,
							start: startDate,
							end: endDate
						},
						detailLevel: {
							key: this.state.detailLevel.key,
							value: this.state.detailLevel.value
						}
					});
				});
			}
		);
	}

	/**
	 * Render utils for displaying one of preset values
	 * @param {} preset
	 * @returns {any}
	 */
	public renderPreset(preset: TimeRangePresets) {
		const { presetValue } = this.state;
		const isActive = presetValue === preset;
		// @ts-expect-error - Custom vs custom
		let presetName = TimeRangePresets[preset];
		if (preset in TimeRangePresetsToNameMapping) {
			presetName = TimeRangePresetsToNameMapping[preset];
		}

		const button = (
			<Button
				type="button"
				key={preset}
				className="px-2"
				variant={isActive ? 'white-alt' : 'link'}
				onClick={() => {
					this.handlePresetChange(preset);
				}}
			>
				<span>{presetName}</span>
			</Button>
		);

		if (preset in TimeRangePresetsOverlay) {
			return (
				<OverlayTrigger
					placement="auto"
					overlay={<Tooltip>{TimeRangePresetsOverlay[preset]}</Tooltip>}
				>
					{button}
				</OverlayTrigger>
			);
		}

		return button;
	}

	/**
	 * handler of FlatPicker Cancel click
	 */
	public onCancelClose() {
		this.setState(
			{
				isCancel: true,
				isSubmit: false
			},
			() => {
				const flatpickr = this.dateRangePicker?.current?.flatpickr;
				if (this.state.previousPreset === TimeRangePresets.Custom) {
					flatpickr!.setDate([
						dateFns.parseISO(this.state.previousStart!),
						dateFns.parseISO(this.state.previousEnd!)
					]);
				} else {
					this.handlePresetChange(this.state.previousPreset);
				}
				flatpickr!.close();
			}
		);
	}

	/**
	 * resetting cancel and submit on flatpickr open
	 */
	public onOpen() {
		this.setState({
			isCancel: false,
			isSubmit: false
		});
	}

	/**
	 * handler of Update button click in FlatPicker
	 * @param {Date[]} selectedDates
	 */
	public onDateSubmit(selectedDates: Date[]) {
		this.setState({
			isSubmit: true,
			previousPreset: TimeRangePresets.Custom
		});
		this.handleDatesChange({
			startDate: selectedDates[0],
			// if no end selected - set end as start
			endDate: selectedDates[1] ? selectedDates[1] : undefined
		});
	}

	public getFlatPickrProps(): DateTimePickerProps {
		const { startDate, endDate } = this.state;
		const commonProps = {
			ref: this.props.displayMode
				? this.dateRangePickerReadonly
				: this.dateRangePicker,
			onOpen: () => this.onOpen(),
			disabled: this.props.displayMode,
			options: {
				now: new Date(),
				// this hack makes it bullet-proof to UTC conversions
				maxDate:
					this.props.maxDate && dateFns.isValid(this.props.maxDate)
						? this.props.maxDate.toISOString().replace('Z', 'GMT+0000')
						: dateFns.endOfDay(new Date()),
				minDate:
					this.props.minDate && dateFns.isValid(this.props.minDate)
						? this.props.minDate.toISOString().replace('Z', 'GMT+0000')
						: undefined,
				mode: 'range',
				altInput: true,
				altFormat: 'Y-m-d',
				showMonths: 2,
				closeOnSelect: false,
				//@ts-ignore
				locale: {
					firstDayOfWeek: 1
				},
				ignoredFocusElements: [window.document.body]
			}
		};

		if (!this.props.displayMode) {
			//@ts-ignore
			commonProps.options.plugins = [
				//@ts-ignore
				new cancelClosePlugin({
					onClick: () => this.onCancelClose()
				}),
				//@ts-ignore
				new customConfirmDatePlugin({
					confirmText: '',
					showAlways: false,
					confirmIcon: this.getSubmitBtn(),
					onClick: (selectedDates) => {
						this.onDateSubmit(selectedDates);
					}
				})
			];
		}

		if (startDate && endDate) {
			//@ts-ignore
			commonProps.options.defaultDate = [
				// this is a brute hack for library internal engine creating the new Date() from
				// passed ISO string here https://github.com/flatpickr/flatpickr/blob/master/src/utils/dates.ts#L76
				startDate.replace('Z', 'GMT+0000'),
				endDate.replace('Z', 'GMT+0000')
			];
		}
		//@ts-ignore explanation defines mode: 'range' as string not like one of predefined strings
		return commonProps;
	}

	public render() {
		const { presetValue, detailLevel, detailLevelValues } = this.state;
		return (
			<section className="rdtDateTimeRangeControl">
				<Form.Group className="rdtDateTimeRangePresets mb-3">
					{this.props.timeRangePresetsKeys.length > 1 &&
						!this.props.displayMode &&
						this.props.timeRangePresetsKeys.map(
							//https://github.com/Microsoft/TypeScript/issues/28565
							//@ts-expect-error
							(preset: TimeRangePresets, i: number) =>
								i !== this.props.timeRangePresetsKeys.length - 1 ? (
									this.renderPreset(preset)
								) : (
									<span className="pe-2" key={preset}>
										{this.renderPreset(preset)}
									</span>
								)
						)}
					{presetValue === TimeRangePresets.Custom && (
						<div className="d-lg-flex flat-picker-wrapper">
							<Flatpickr {...this.getFlatPickrProps()} />
						</div>
					)}
					{!this.props.hideDetailsOption && (
						<SimpleSelectFormControl<string | number>
							hideLabel
							label=""
							useControlGroup={false}
							clearable={false}
							onChange={(value) => this.handleDetailLevelChange(value.label)}
							value={detailLevel}
							options={detailLevelValues.map((detailLevelItem) => {
								return {
									label: detailLevelItem.label,
									value: detailLevelItem.label
								};
							})}
						/>
					)}
				</Form.Group>
			</section>
		);
	}
}
