import { RtVueApiRoutes } from 'RtExports/routes';
import { FirebaseRouteType, GuardianAlertIndexResponse } from 'RtModels';
import { AlertHistoryResource } from 'RtUi/app/rtGuardian/AlertHistory/lib/resources/AlertHistoryResource';
import { GuardianApplicationRouter } from 'RtUi/app/rtGuardian/rtGuardian.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IAlertRulesRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	History: TabbedLayoutTabProps;
	Recipients: TabbedLayoutTabProps;
	Schedules: TabbedLayoutTabProps;
}

const AlertRulesContainerTabs: IAlertRulesRouterTabs = {
	Profile: {
		header: 'Profile'
	},
	History: {
		header: 'History'
	},
	Recipients: {
		header: 'Recipients'
	},
	Schedules: {
		header: 'Schedules'
	}
};

class AlertRulesRouterClass extends GuardianApplicationRouter<
	GuardianAlertIndexResponse,
	GuardianAlertIndexResponse,
	IAlertRulesRouterTabs
> {
	constructor() {
		super(
			'Alert Rules',
			'alertRules',
			'guardianAlertId',
			AlertRulesContainerTabs
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.GuardianAlerts);

		this.setFirebaseRouteType(FirebaseRouteType.GuardianAlert);
	}

	public getProfileLabel(profile: GuardianAlertIndexResponse): string {
		return profile.summary;
	}

	public recordHasAccessToProfile(record: any) {
		//Don't show Fraud alert history records
		if (AlertHistoryResource.isFraudAlertHistory(record)) {
			return false;
		}

		return super.recordHasAccessToProfile(record);
	}
}

export const AlertRulesRouter = new AlertRulesRouterClass();
