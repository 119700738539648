import * as React from 'react';
import {
	Reports,
	FourteenDayIndexResponse,
	TimeRangePresets,
	TimeFilterDetailKeys,
	RtxReportId
} from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { FourteenDayReportGrid } from 'RtUi/app/rtVue/14DayReport/lib/grids/14DayReportGrid';
import { FourteenDayReportRouter } from 'RtUi/app/rtVue/14DayReport/14DayReport.router';
import { TimeFilterDetailLevelsHours } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import moment from 'moment';
import { ReportRouteGroup } from 'RtUi/app/ApplicationShell/lib/interfaces';

interface FourteenDayIndexContainerState
	extends IRtVueContainerState<FourteenDayIndexResponse> {}

@FourteenDayReportRouter.getIndexRtUiController({
	orderPriority: 2,
	groupOrderPriority: 20,
	isReport: true,
	reportId: RtxReportId.RtVueFourteenDay,
	reportName: '14 days report',
	reportGroupName: ReportRouteGroup.NetworkReports
})
export class FourteenDayIndexContainer extends RtVueReportContainer<
	FourteenDayIndexResponse,
	FourteenDayIndexContainerState
> {
	protected reportId = Reports.FourteenDay;
	protected router = FourteenDayReportRouter;

	private static REVENUE_COLUMN_ADDITIONAL_COLUMNS = [
		'customerSubscriptionId',
		'customerAccountId'
	];

	private static COST_COLUMN_ADDITIONAL_COLUMNS = [
		'vendorSubscriptionId',
		'vendorAccountId'
	];

	private static MARGIN_COLUMN_ADDITIONAL_COLUMNS = [
		'vendorAccountId',
		'customerAccountId',
		'callType'
	];

	public state = {
		...this.getBaseState({ defaultTimeRangePreset: TimeRangePresets.Day }),
		dataSums: ['margin'] as any,
		timeRangePresetsKeys: [
			TimeRangePresets.Week,
			TimeRangePresets.ThirtyDays,
			TimeRangePresets.Custom
		],
		timeRangeDetailsLevels: TimeFilterDetailLevelsHours.filter(
			(time) => time.key === TimeFilterDetailKeys.Days
		),
		noCustomFocusOnInit: true,
		hideDetailsOption: true,
		hideFileStreamSelect: true
	};

	protected async getResource(): Promise<
		HttpResource<FourteenDayIndexResponse>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns,
			havingFilter
		} = this.state;
		const vueHttp = new RtVueHttp();
		const fourteenDayIndexes = await vueHttp.getFourteenDayView(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			additionalColumns?.map((column) => column.value),
			havingFilter
		);
		const financeResource = new ArrayResource<FourteenDayIndexResponse>(
			'callDate',
			fourteenDayIndexes.data
		);

		financeResource.setTotalCount(fourteenDayIndexes.totalCount);

		return financeResource;
	}

	public onClickRevenueColumn = (row: FourteenDayIndexResponse) => {
		this.updateTimeObject(row.callDate);
		const additionalColumns = this.state.allColumns.filter((column) =>
			FourteenDayIndexContainer.REVENUE_COLUMN_ADDITIONAL_COLUMNS.includes(
				column.value
			)
		);

		this.setState(
			{
				additionalColumns
			},
			() => {
				this.updateReports();
			}
		);
	};

	public onClickCostColumn = (row: FourteenDayIndexResponse) => {
		this.updateTimeObject(row.callDate);
		const additionalColumns = this.state.allColumns.filter((column) =>
			FourteenDayIndexContainer.COST_COLUMN_ADDITIONAL_COLUMNS.includes(
				column.value
			)
		);

		this.setState(
			{
				additionalColumns
			},
			() => {
				this.updateReports();
			}
		);
	};

	public onClickMarginColumn = (row: FourteenDayIndexResponse) => {
		this.updateTimeObject(row.callDate);
		const additionalColumns = this.state.allColumns.filter((column) =>
			FourteenDayIndexContainer.MARGIN_COLUMN_ADDITIONAL_COLUMNS.includes(
				column.value
			)
		);

		this.setState(
			{
				additionalColumns
			},
			() => {
				this.updateReports();
			}
		);
	};

	public onClickASRColumn = (row: FourteenDayIndexResponse) => {
		this.updateTimeObject(row.callDate);
		window.open(
			`asr${window?.location?.search ? `${window.location.search}` : ''}`,
			'_blank'
		);
	};

	public updateTimeObject(dateString: string) {
		this.state.timeRange.timeObject.start = moment(dateString).toDate();
		this.state.timeRange.timeObject.end = moment(dateString)
			.endOf('day')
			.toDate();
	}

	protected renderReports(): React.ReactNode {
		return (
			<>
				<FourteenDayReportGrid
					isLoading={this.state.loadingReport}
					onClickASRColumn={this.onClickASRColumn}
					onClickMarginColumn={this.onClickMarginColumn}
					onClickCostColumn={this.onClickCostColumn}
					onClickRevenueColumn={this.onClickRevenueColumn}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
					callDateFormat={this.getCallDateFormat()}
				/>
			</>
		);
	}
}
