import * as React from 'react';
import { Button } from 'react-bootstrap';
import { ProductId } from 'RtModels';
import { ProductIdToNameMap } from 'RtUi/app/rtCommon/Products/utils';
import { UserActions } from 'RtUi/state/actions/user';

interface IProductFilterProps {
	value: ProductId[];
	onChange: (newValue: ProductId[]) => void;
}

export class ProductFilter extends React.Component<IProductFilterProps> {
	public render() {
		const usersProductIds = UserActions.getUsersProducts();
		const options = usersProductIds.sort().map((productId) => ({
			label: ProductIdToNameMap.get(productId),
			value: productId
		}));

		const getColor = (values: ProductId[], isAllOption = false) => {
			const { value } = this.props;
			const hasAllValues = values.every((productId) =>
				value.includes(productId)
			);

			const showPrimary = value.length === 1 || isAllOption;

			if (showPrimary && hasAllValues) {
				return 'primary';
			}

			return 'link';
		};

		return (
			<>
				{options.map((option) => (
					<Button
						key={option.value}
						variant={getColor([option.value])}
						onClick={() => this.props.onChange([option.value])}
					>
						{option.label}
					</Button>
				))}
				<Button
					variant={getColor(usersProductIds, true)}
					onClick={() =>
						this.props.onChange(UserActions.getUsersProducts(true))
					}
				>
					Show All
				</Button>
			</>
		);
	}
}
