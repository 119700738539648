import { useCallback, useMemo } from 'react';
import {
	SipAgentConfigIndexRequest,
	SipAgentConfigIndexResponse
} from 'RtModels';
import { SipAgentConfigsResource } from 'RtUi/app/AccountManagement/SipAgents/lib/resources/SipAgentConfigsResource';
import { useGetSipAgentConfigs } from 'RtUi/app/AccountManagement/SipAgents/lib/services';
import { SipAgentConfigRouterClass } from 'RtUi/app/AccountManagement/SipAgents/SipAgentConfig.router';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSipAgentConfigsColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/sipAgents';
import { Confirmation } from 'RtUi/components/form/Confirmation';

export interface ISipAgentConfigsGridProps {
	router: SipAgentConfigRouterClass;
	resourceParams?: SipAgentConfigIndexRequest;
	autoFocusFilter?: boolean;
}

export const SipAgentConfigsGrid = ({
	router,
	resourceParams
}: ISipAgentConfigsGridProps): JSX.Element => {
	const resource = useMemo(() => new SipAgentConfigsResource(), []);
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetSipAgentConfigs(resourceParams);

	const onDeleteHandler = useCallback(
		async (row: SipAgentConfigIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				row.sipAgentConfigId.toString()
			);

			if (!confirm) {
				return;
			}

			await resource.delete(row.sipAgentConfigId);
			refetch();
		},
		[refetch, resource]
	);

	const columns = useMemo(
		() => getSipAgentConfigsColumns(onDeleteHandler),
		[onDeleteHandler]
	);

	return (
		<DataGrid<SipAgentConfigIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'accountManagement_sipAgentConfigs'}
			router={router}
			disableExport
		/>
	);
};
