import { BlockedNumber, BlockedNumberIndexRequest } from 'RtModels';
import { BlockNumbersRouter } from 'RtUi/app/rtSip/BlockNumbers/BlockNumbers.router';
import { BlockNumberResource } from 'RtUi/app/rtSip/BlockNumbers/lib/resources/BlockNumbersResource';
import { useGetBlockedNumbers } from 'RtUi/app/rtSip/BlockNumbers/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getBlockedNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/blockedNumbers';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { useCallback, useMemo } from 'react';

interface IBlockNumberGridProps {
	resourceParams?: BlockedNumberIndexRequest;
	autoFocusFilter?: boolean;
}

export const BlockNumberGrid = ({ resourceParams }: IBlockNumberGridProps) => {
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetBlockedNumbers(resourceParams);

	const removeBlockNumber = useCallback(
		async (blockNumber: BlockedNumber) => {
			const confirm = await Confirmation.createDelete(blockNumber.phoneNumber);

			if (!confirm) {
				return;
			}

			const resource = new BlockNumberResource();
			await resource.delete(blockNumber.blockedNumberId);
			refetch();
		},
		[refetch]
	);

	const columns = useMemo(
		() => getBlockedNumbersColumns(removeBlockNumber),
		[removeBlockNumber]
	);

	return (
		<>
			<DataGrid<BlockedNumber>
				data={data?.data}
				router={BlockNumbersRouter}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtSip_blockedNumbers'}
				columns={columns}
			/>
			<Confirmation />
		</>
	);
};
