import { CdrExportRetentionIndexResponse } from 'RtModels';
import { CdrExportRetentionResource } from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportRetentionResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface CdrExportRetentionDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		CdrExportRetentionIndexResponse,
		T
	> {
	header?: string;
}

export const CdrExportRetentionRenderCache =
	new ResourceCacheMap<CdrExportRetentionIndexResponse>(
		new CdrExportRetentionResource(),
		'retentionId',
		'retentionName'
	);

export const CdrExportRetentionDataGridColumn = <T = any,>({
	header = 'Cdr Export Retention',
	...config
}: CdrExportRetentionDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		header,
		resourceCacheMap: CdrExportRetentionRenderCache,
		...config
	});
};
