/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FirebaseAdditionalDataType = {
    properties: {
        resourceType: {
            type: 'FireBaseAdditionalDataResourceType',
            isRequired: true,
        },
        resourceId: {
            type: 'string',
            isRequired: true,
        },
    },
};