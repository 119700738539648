import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn,
	FallbackHeaderDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { isNil } from 'lodash-es';

interface EntityIdDataGridColumnConfiguration<T>
	extends FallbackHeaderDataGridColumnConfiguration<T> {}

export const EntityIdDataGridColumn = <T = any,>({
	size = 120,
	header = 'Entity Id',
	align = DataGridColumnAlignment.CENTER,
	...config
}: EntityIdDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: any) => {
		if (isNil(value)) {
			return '';
		}

		return value;
	};

	return DefaultDataGridColumn({
		...config,
		size,
		header,
		align,
		exportValue: (value: any) => `${getValue(value)}`,
		getValue: ({ cell }) => getValue(cell.getValue())
	});
};
