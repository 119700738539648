import * as React from 'react';
import {
	IVariableOption,
	VariableSelect
} from 'RtUi/app/rtSip/Scenarios/lib/controls/VariableSelect';

interface IToPhoneNumberFormControlProps {
	onChange: (newValue: string) => void;
	value: string;
	displayMode: boolean;
	required?: boolean;
}

interface IToPhoneNumberFormControlState {
	variable?: IVariableOption;
}

export class ToPhoneNumberFormControl extends React.Component<
	IToPhoneNumberFormControlProps,
	IToPhoneNumberFormControlState
> {
	public state: IToPhoneNumberFormControlState = {};

	constructor(props: IToPhoneNumberFormControlProps) {
		super(props);

		//Add value to variable if custom
		if (props.value !== '' && !VariableSelect.isVariable(props.value)) {
			this.state.variable = { value: props.value };
		}
	}

	public onVariableChange(variable?: IVariableOption) {
		if (variable) {
			const isVariable = VariableSelect.isVariable(variable.value);

			if (!isVariable) {
				//Remove non-digits for custom input
				variable.value = variable.value.replace(/\D/g, '');
			}
		}

		this.setState({ variable });

		this.props.onChange(variable ? variable.value : '');
	}

	public render() {
		return (
			<VariableSelect
				label="To Phone Number"
				required={this.props.required}
				appendDropdownToBody
				clearable={false}
				creatable
				displayMode={this.props.displayMode}
				onChange={(newValue: IVariableOption) =>
					this.onVariableChange(newValue)
				}
				initialOptionId={this.props.value}
				value={this.state.variable}
			/>
		);
	}
}
