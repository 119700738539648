import { useCallback, useMemo } from 'react';
import { SwitchIndexRequest, SwitchIndexResponse } from 'RtModels';
import { SwitchesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SwitchesResource';
import { useGetSwitches } from 'RtUi/app/AccountManagement/Switches/lib/services';
import { SwitchRouterClass } from 'RtUi/app/AccountManagement/Switches/Switch.router';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSwitchesColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/switches';
import { Confirmation } from 'RtUi/components/form/Confirmation';

export interface ISwitchesGridProps {
	router: SwitchRouterClass;
	resourceParams?: SwitchIndexRequest;
	autoFocusFilter?: boolean;
}

export const SwitchesGrid = ({
	router,
	resourceParams
}: ISwitchesGridProps): JSX.Element => {
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetSwitches(resourceParams);
	const resource = useMemo(() => new SwitchesResource(), []);

	const onDeleteHandler = useCallback(
		async (row: SwitchIndexResponse) => {
			const confirm = await Confirmation.createDelete(row.switchId.toString());

			if (!confirm) {
				return;
			}

			await resource.delete(row.switchId);
			refetch();
		},
		[refetch, resource]
	);

	const columns = useMemo(
		() => getSwitchesColumns(onDeleteHandler),
		[onDeleteHandler]
	);

	return (
		<DataGrid<SwitchIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'accountManagement_switches'}
			router={router}
			disableExport
		/>
	);
};
