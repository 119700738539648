/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum LcrRateSheetType {
    NpaNxx = 1,
    LataOcn = 2,
    DialCode = 3,
}