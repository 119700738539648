import { EntityIndexRequest, EntityIndexResponse } from 'RtModels';
import { EntityGrid } from 'RtUi/app/rt800/Entities/lib/grids/EntityGrid';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useReplaceWindowUrl } from 'RtUi/utils/hooks/useReplaceWindowUrl';
import { FC, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { EntityRouter } from './Entity.router';
import { EntitySelect } from './lib/controls/EntitySelect';

export const EntityIndexContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true }
	});
	const [isActive, setIsActive] = useState<number | undefined>(1);
	const [isManaged, setIsManaged] = useState<number | undefined>(1);
	const [entity, setEntity] = useState<EntityIndexResponse>();
	const resourceParams: EntityIndexRequest = {};
	let entitiesLimitedToIsActive: boolean | null = null;

	if (entity) {
		resourceParams.entityId = entity.entityId;
	}

	if (isActive !== undefined) {
		resourceParams.isActive = isActive;
		entitiesLimitedToIsActive = isActive === 1;
	}

	if (isManaged !== undefined) {
		resourceParams.isManaged = isManaged;
	}

	useReplaceWindowUrl(activeTab.header, resourceParams);

	return (
		<TabbedLayout
			router={EntityRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					onSubmit={(evt) => {
						evt.preventDefault();

						setActiveTab(tabs.Results.header);
					}}
				>
					<EntitySelect
						allowAll
						name="entityId"
						isActive={entitiesLimitedToIsActive}
						onChange={setEntity}
						value={entity}
					/>

					<IsActiveRadioFormControl
						label="Entity Status"
						activeTooltip="Active with Somos"
						onChange={setIsActive}
						value={isActive}
					/>
					<RadioFormControl<number | undefined>
						label="Managed by RouteTrust"
						onChange={setIsManaged}
						value={isManaged}
						options={[
							{ value: 1, label: 'Yes' },
							{ value: 0, label: 'No' },
							{ value: undefined, label: 'Both' }
						]}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<EntityGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>

			<TabbedLayoutHelpContainer tab={tabs.Search.header}>
				<p>
					Entities are the first two characters of a RespOrg id as defined by
					Somos.
				</p>
			</TabbedLayoutHelpContainer>
			<TabbedLayoutHelpContainer tab={tabs.Results.header}>
				<p>
					Some of all numbers across all RespOrg ids within this entity with
					this status.
				</p>
				<Alert variant="warning">
					<i className="fas fa-fw fa-sticky-note" />
					<span>
						&nbsp;Totals include all statuses and may not match equal column
						values.
					</span>
				</Alert>
			</TabbedLayoutHelpContainer>
		</TabbedLayout>
	);
};

EntityRouter.setIndexRtUiFunctionalComponent(EntityIndexContainer, {
	description: 'Entities responsible for managing Toll-Free numbers.'
});
