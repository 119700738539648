import { RtVueApiRoutes } from 'RtExports/routes';
import { GuardianFileLog } from 'RtModels';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IFileLogProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const FileLogProfileContainerTabs: IFileLogProfileContainerTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class FileLogRouterClass extends RtVueModuleRouter<
	GuardianFileLog,
	GuardianFileLog,
	IFileLogProfileContainerTabs
> {
	constructor() {
		super('File Log', 'fileLog', 'fileId', FileLogProfileContainerTabs);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.FileLogs);
	}

	public getProfileLabel(profile: GuardianFileLog): string {
		return profile.fileName;
	}

	public getIndexLabel(profile: GuardianFileLog): string {
		return profile.fileName;
	}
}

export const FileLogRouter = new FileLogRouterClass();
