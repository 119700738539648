/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianFileLog = {
    properties: {
        fileId: {
            type: 'number',
            isRequired: true,
        },
        fileName: {
            type: 'string',
            isRequired: true,
        },
        recordCount: {
            type: 'number',
            isNullable: true,
        },
        goodRows: {
            type: 'number',
            isNullable: true,
        },
        routeAdvances: {
            type: 'number',
            isNullable: true,
        },
        fileSizeBytes: {
            type: 'number',
        },
        uuid: {
            type: 'string',
        },
        fileStreamId: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        downloadStartTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        downloadEndTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        processedStartTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        processedEndTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        archiveStartTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        archiveEndTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        reDownload: {
            type: 'number',
            isRequired: true,
        },
        reArchive: {
            type: 'number',
            isRequired: true,
        },
        reprocessedTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
    },
};