/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ReconciliationMappingDataSourceType {
    Cost = 'cost',
    Revenue = 'revenue',
    Ingress = 'ingress',
    Egress = 'egress',
}