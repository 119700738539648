import { CprRateSheetProfileResponse } from 'RtModels';
import { LcrCarrierRouter } from 'RtUi/app/rtLco/Carriers/LcrCarrier.router';
import { MinutesRouter } from 'RtUi/app/rtLco/Minutes/Minutes.router';
import {
	RateSheetContainerTabs,
	RateSheetRouter
} from 'RtUi/app/rtLco/RateSheets/RateSheet.router';
import { RateSheetResultsGrid } from 'RtUi/app/rtLco/RateSheets/lib/grids/RateSheetResultsGrid';
import { RateSheetResource } from 'RtUi/app/rtLco/RateSheets/lib/resources/RateSheetResource';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { LcMinutesDataGridCache } from 'RtUi/components/data/DataGrid/columns/LcrMinutesDataGridColumn';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { timestampToReadable } from 'RtUi/utils/maps';
import { Alert, Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface IRateSheetGeneratorProfileContainerState {
	activeTab: string;
	isDeleting: boolean;
	profile?: CprRateSheetProfileResponse;
	minutesTuple?: Array<[string, string]>;
}

export type RateSheetCarrierType = {
	label: string;
	cic: string;
	respOrgCarrierId: number;
};

@RateSheetRouter.getProfileRtUiController()
export class RateSheetProfileContainer extends ProfileApplicationContainer<
	{},
	IRateSheetGeneratorProfileContainerState
> {
	public Tabs = RateSheetContainerTabs;
	public state: IRateSheetGeneratorProfileContainerState = {
		activeTab: this.Tabs.Profile.header,
		isDeleting: false
	};

	constructor(props: any) {
		super(props);
		LcMinutesDataGridCache.warmup();
	}

	public async componentDidMount() {
		const rateSheetResource = new RateSheetResource();
		const rateSheetId = Number(this.getIdParam());

		const profile = await rateSheetResource.get(rateSheetId);

		if (!profile) {
			return;
		}

		const minutesTuple = await Promise.all(
			profile.minuteProfileIds.map(
				async (minuteId): Promise<[string, string]> => {
					const label =
						(await LcMinutesDataGridCache.getLabelFor(Number(minuteId))) || '';
					return [minuteId, label];
				}
			)
		);

		this.setState({ profile, minutesTuple });
	}

	public async deleteRateSheet() {
		const { profile } = this.state;

		if (!profile) {
			return;
		}

		this.setState({ isDeleting: true });

		const rateSheetResource = new RateSheetResource();

		try {
			const newProfile = await rateSheetResource.delete(profile.cprRateSheetId);

			this.setState({
				profile: newProfile,
				activeTab: this.Tabs.Profile.header
			});
		} finally {
			this.setState({ isDeleting: false });
		}
	}

	public goToIndexRoute() {
		const indexRoute = RateSheetRouter.getIndexRoute();

		this.goToPath(indexRoute);
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}
		const hasTaskId = profile.taskId !== null && profile.taskId !== undefined;

		return (
			<TabbedLayout<CprRateSheetProfileResponse>
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={RateSheetRouter}
				profile={profile}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<Row>
						<Col md={8}>
							{profile.deletedAt && (
								<Alert
									variant="warning"
									className="d-flex justify-content-start"
								>
									<article>
										<i className="fas fa-fw fa-info-circle" />
										&nbsp;
									</article>
									<article>
										This rate sheet has been deleted.{' '}
										<a onClick={() => this.goToIndexRoute()}>
											Click here to go back Rate Sheets list
										</a>
										.
									</article>
								</Alert>
							)}
							<Card body>
								<Row>
									<Col md={6}>
										<InputFormControl
											label="ID"
											displayMode
											value={String(profile.cprRateSheetId)}
										/>
									</Col>
									<Col md={6}>
										<InputFormControl
											label="Key"
											displayMode
											value={profile.routingCacheKey}
										/>
									</Col>
								</Row>
								{hasTaskId && (
									<Row>
										<Col md={6}>
											<InputFormControl
												label="Task Id"
												displayMode
												value={String(profile.taskId)}
											/>
										</Col>
									</Row>
								)}
								<Row>
									<Col md={6}>
										<InputFormControl
											label="Match Qty"
											displayMode
											value={(profile.cprMatchQty ?? 0).toLocaleString()}
										/>
									</Col>
									<Col md={6}>
										<InputFormControl
											label="Unknown Qty"
											displayMode
											value={(profile.cprUnknownQty ?? 0).toLocaleString()}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<InputFormControl
											label="Effective At"
											displayMode
											value={timestampToReadable(profile.effectiveTs)}
										/>
									</Col>
									<Col md={6}>
										<InputFormControl
											label="Updated At"
											displayMode
											value={timestampToReadable(profile.updatedAt)}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<InputFormControl
											label="Completed At"
											displayMode
											value={timestampToReadable(profile.completedAt)}
										/>
									</Col>
									<Col md={6}>
										<InputFormControl
											label="Deleted At"
											displayMode
											value={timestampToReadable(profile.deletedAt)}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col>
							<Card body>
								<h6>Carriers</h6>
								{profile.respOrgCarriers.map((respOrgCarrier: unknown) => {
									const carrier: RateSheetCarrierType =
										respOrgCarrier as RateSheetCarrierType;
									return (
										<p key={`${carrier.respOrgCarrierId}-carrier`}>
											<Link
												to={LcrCarrierRouter.getProfileRoute(
													carrier.respOrgCarrierId
												)}
											>
												{carrier.label}
											</Link>
											<Badge pill bg="light" text="dark" className="ms-2">
												{carrier.cic}
											</Badge>
										</p>
									);
								})}
								<h6>Minutes</h6>
								{this.state.minutesTuple?.map((minutes) => {
									const [id, label] = minutes;
									return (
										<p key={`${id}-carrier`}>
											<Link to={MinutesRouter.getProfileRoute(id)}>
												{label}
											</Link>
										</p>
									);
								})}
							</Card>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Results}>
					<RateSheetResultsGrid
						rateSheetTypeId={profile.rateSheetTypeId}
						resourceParams={{ cprRateSheetId: profile.cprRateSheetId }}
					/>
				</TabbedLayoutTab>
				{!profile.deletedAt && (
					<TabbedLayoutTab header="Delete Rate Sheet" isAction>
						<Row>
							<Col md={8}>
								<Card body>
									<header>
										<Alert variant="warning">
											By deleting this rate sheet, it will not longer be usable
											or viewable to any users. Click &quot;Delete&quot; below
											to continue.
										</Alert>
									</header>
									<footer className="d-flex justify-content-end">
										<Button
											variant="white"
											className="me-2"
											onClick={() =>
												this.setState({
													activeTab: this.Tabs.Profile.header
												})
											}
										>
											Cancel
										</Button>
										<Button
											variant="danger"
											disabled={this.state.isDeleting}
											onClick={() => this.deleteRateSheet()}
										>
											{this.state.isDeleting && (
												<i className="fas fa-fw fa-cog fa-spin" />
											)}
											{!this.state.isDeleting && (
												<i className="fas fa-fw fa-trash" />
											)}
											<span>&nbsp;Delete Rate Sheet</span>
										</Button>
									</footer>
								</Card>
							</Col>
						</Row>
					</TabbedLayoutTab>
				)}
			</TabbedLayout>
		);
	}
}
