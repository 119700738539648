import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	BillingEntityProfileResponse,
	BillingEntityUpdateRequest,
	BillingEntityProfileUrlRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class PartitionBillingEntityResource extends HttpResource<BillingEntityProfileResponse> {
	constructor() {
		super(RtCommonApiRoutes.BillingEntity);
	}

	public updatePartitionBillingEntity(
		billingEntityId: number,
		billingEntity: BillingEntityUpdateRequest,
		file?: File
	) {
		const urlParams: BillingEntityProfileUrlRequest = {
			billingEntityId
		};

		const body = new FormData();
		if (file) {
			body.append('file', file);
		}

		return this.fetchWithRoute<BillingEntityProfileResponse>(
			RtCommonApiRoutes.BillingEntity.Update,
			{ body, urlParams, params: billingEntity, removeContentType: true }
		);
	}

	public createPartitionBillingEntity(
		params: BillingEntityUpdateRequest,
		file: File
	) {
		const body = new FormData();
		body.append('file', file);

		return this.fetchWithRoute<BillingEntityProfileResponse>(
			RtCommonApiRoutes.BillingEntity.Create,
			{ body, params, removeContentType: true }
		);
	}
}
