import {
	DownloadIndexResponse,
	DownloadTypeId,
	FileShareIndexResponse,
	UserAuditIndexResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { ButtonDataGridColumColumn } from 'RtUi/components/data/DataGrid/columns/ButtonDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { UserNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getUsersAuditColumns = (): Array<
	DataGridColumn<UserAuditIndexResponse>
> => [
	TimeStampDataGridColumn({
		accessorKey: 'auditTs',
		header: 'Time'
	}),
	DefaultDataGridColumn({
		accessorKey: 'name',
		header: "User's Name"
	}),
	DefaultDataGridColumn({
		accessorKey: 'changeType',
		header: 'Change Type'
	}),
	DefaultDataGridColumn({
		accessorKey: 'objectType',
		header: 'Object Type'
	}),
	IntegerDataGridColumn({
		accessorKey: 'recordId',
		header: 'Record Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'changes',
		header: 'Changes'
	})
];

export const getUserDownloadColumns = (
	downloadFile: (downloadId: number) => Promise<void>
): Array<DataGridColumn<DownloadIndexResponse>> => [
	ButtonDataGridColumColumn({
		header: 'Download',
		id: 'download',
		accessorKey: 'downloadId',
		onClick: (row) => downloadFile(row.downloadId),
		icon: 'fa-download'
	}),
	IdDataGridColumn({
		accessorKey: 'downloadId'
	}),
	DefaultDataGridColumn({
		header: 'Download Type',
		accessorFn: (row) => {
			switch (row.downloadTypeId) {
				case DownloadTypeId.TollFrees:
					return 'Toll Free';
				case DownloadTypeId.TrafficProfile:
					return 'Traffic Profile';
			}
		}
	}),
	DefaultDataGridColumn({
		accessorKey: 'fileName',
		header: 'File Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'rowQty',
		header: 'Quantity'
	}),
	DateDataGridColumn({
		accessorKey: 'expiresTs',
		header: 'Expires At'
	}),
	UserNameDataGridColumn({
		accessorKey: 'createdBy',
		header: 'Created By'
	})
];

export const getUserFileSharingColumns = (): Array<
	DataGridColumn<FileShareIndexResponse>
> => [
	LabelDataGridColumn({
		accessorKey: 'label',
		header: 'Label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'localFileName',
		header: 'File Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'fileSize',
		header: 'File Size'
	}),
	DefaultDataGridColumn({
		accessorKey: 'partition',
		header: 'Partition',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'createdBy',
		header: 'Created By'
	}),
	DefaultDataGridColumn({
		accessorKey: 'emailAddress',
		header: 'Email Address'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created Ts'
	})
];
