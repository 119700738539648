/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileShareProfileResponse = {
    properties: {
        fileShareId: {
            type: 'number',
            isRequired: true,
        },
        fileShareLocationId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        partitionId: {
            type: 'number',
            isRequired: true,
        },
        userId: {
            type: 'number',
            isRequired: true,
        },
        remoteFileName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        fileSizeBytes: {
            type: 'number',
            isRequired: true,
        },
        localFileName: {
            type: 'string',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        deletedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
    },
};