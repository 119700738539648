import { Component } from 'react';
import { Aside } from 'RtUi/components/ui/Aside';
import { QosEditForm } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/QosEditForm';
import { SubscriptionQosResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionQosResource';
import { SubscriptionProfileResponse } from 'RtModels';

interface IEditQosSubscriptionAsideProps {
	subscription?: SubscriptionProfileResponse;
	rateCenterId?: number;
	isOpen: boolean;
	onCancel: () => void;
	onSave: () => void;
}

export class EditQosSubscriptionAside extends Component<
	IEditQosSubscriptionAsideProps,
	{}
> {
	public render() {
		return (
			<Aside isOpen={this.props.isOpen} disabledBody>
				<Aside.Header
					warning
					header="Subscription QoS Settings"
					onClose={() => this.props.onCancel()}
				/>
				{this.props.subscription && this.props.rateCenterId && (
					<QosEditForm
						resource={
							new SubscriptionQosResource(
								this.props.subscription.subscriptionId
							)
						}
						subscription={this.props.subscription}
						rateCenterId={this.props.rateCenterId}
						onSuccess={this.props.onSave}
						onCancel={this.props.onCancel}
					/>
				)}
			</Aside>
		);
	}
}
