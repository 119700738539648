/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateLoadStep {
    Creating = 1,
    Parsing = 10,
    Verification = 20,
    Importing = 30,
    RateAndCodeAnalysis = 40,
    ApproveExceptions = 45,
    RateApproval = 50,
    RateLoading = 60,
    RateAmendment = 65,
    Deleting = 70,
}