import { UserActions } from 'RtUi/state/actions/user';
import { useEffect, useState } from 'react';

export const useGetUserDefaults = () => {
	const [entityId, setEntityId] = useState<string>();
	const [respOrgId, setRespOrgId] = useState<string>();
	const user = UserActions;

	useEffect(() => {
		setEntityId(user.getDefaultEntityId());
		setRespOrgId(user.getDefaultRespOrgId());
	}, [user]);

	return {
		entityId,
		respOrgId
	};
};
