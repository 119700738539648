import * as React from 'react';
import { ScenarioResource } from 'RtUi/app/rtSip/Scenarios/lib/resources/ScenarioResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { ScenarioProfileResponse } from 'RtModels';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IScenarioEditorProps {
	profile?: ScenarioProfileResponse;
	onSuccess?: (profile: ScenarioProfileResponse) => void;
	disablePadding?: boolean;
	disableBody?: boolean;
}

interface IScenarioEditorState {
	label: string;
	summary: string;
	isEntryPoint: number;
	isSubmitting: boolean;
	displayMode: boolean;
	error?: any;
}

export class ScenarioEditor extends React.Component<
	IScenarioEditorProps,
	IScenarioEditorState
> {
	public state: IScenarioEditorState = {
		label: '',
		summary: '',
		isEntryPoint: 0,
		isSubmitting: false,
		displayMode: false,
		error: undefined
	};

	public componentDidMount() {
		const { profile } = this.props;
		const displayMode = Boolean(profile);

		this.setState({ displayMode });

		if (profile) {
			this.loadProfile(profile);
		}
	}

	public loadProfile(profile: ScenarioProfileResponse) {
		const { label, summary, isEntryPoint } = profile;

		this.setState({ label, summary, isEntryPoint, error: undefined });
	}

	public async createOrUpdate(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const { profile } = this.props;
		const { label, summary, isEntryPoint } = this.state;
		const sipScenarioResource = new ScenarioResource();

		this.setState({ isSubmitting: true, error: undefined });

		const { onSuccess = () => ({}) } = this.props;
		let newProfile: ScenarioProfileResponse;

		try {
			if (profile) {
				// Update Mode
				const actionsUpdate = profile.actions || [];
				newProfile = await sipScenarioResource.updateScenario(
					profile.scenarioId,
					label,
					summary,
					isEntryPoint,
					actionsUpdate
				);
			} else {
				// Create Mode
				newProfile = await sipScenarioResource.createScenario(
					label,
					summary,
					isEntryPoint,
					[]
				);
			}

			onSuccess(newProfile);

			this.setState({ displayMode: true });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public onCancel() {
		const { profile } = this.props;

		if (profile) {
			this.loadProfile(profile);
		}

		this.setState({ displayMode: true });
	}

	public render() {
		const createMode = !this.props.profile;

		return (
			<RtUiForm
				disableBody={this.props.disableBody}
				disablePadding={this.props.disablePadding}
				onSubmit={(e) => this.createOrUpdate(e)}
				createMode={createMode}
				error={this.state.error}
				displayMode={this.state.displayMode}
				onCancel={() => this.onCancel()}
				isSubmitting={this.state.isSubmitting}
				onChange={(displayMode) => this.setState({ displayMode })}
			>
				<InputFormControl
					label="Label"
					displayMode={this.state.displayMode}
					onChange={(label) => this.setState({ label })}
					value={this.state.label}
				/>
				<InputFormControl
					label="Summary"
					displayMode={this.state.displayMode}
					onChange={(summary) => this.setState({ summary })}
					value={this.state.summary}
				/>
				<IsActiveRadioFormControl
					label="Entry Point"
					displayMode={this.state.displayMode}
					activeTooltip="Entry Point"
					hideBothOption
					onChange={(isEntryPoint) => this.setState({ isEntryPoint })}
					value={this.state.isEntryPoint}
				/>
			</RtUiForm>
		);
	}
}
