import { RtCommonApiRoutes } from 'RtExports/routes';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';

class RtDashboardRouterClass extends RtUiRouter<void, void, {}> {
	constructor() {
		super('', 'Dashboard Details', 'dashboardDetails', undefined, {});

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.Dashboard);
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const RtDashboardRouter = new RtDashboardRouterClass();
