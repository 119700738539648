import { RtDemoApiRoutes } from 'RtExports/routes';
import {
	GuardianDoNotOriginateIndexResponse,
	GuardianDoNotOriginate
} from 'RtModels';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IDoNotOriginateContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const DoNotOriginateContainerTabs: IDoNotOriginateContainerTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class DoNotOriginateRouterClass extends RtVueModuleRouter<
	GuardianDoNotOriginateIndexResponse,
	GuardianDoNotOriginate,
	IDoNotOriginateContainerTabs
> {
	constructor() {
		super(
			'Do Not Originate',
			'doNotOriginate',
			'ani',
			DoNotOriginateContainerTabs
		);

		this.setPermissionsFromApiRoute(RtDemoApiRoutes.Demos);
	}

	public getIndexLabel(record: GuardianDoNotOriginateIndexResponse) {
		return record.ani;
	}

	public getProfileLabel(profile: GuardianDoNotOriginate) {
		return profile.ani;
	}

	public getPluralName() {
		return this.getName();
	}
}

export const DoNotOriginateRouter = new DoNotOriginateRouterClass();
