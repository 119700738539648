import { set } from 'date-fns';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import {
	AccountIndexResponse,
	AsrSummaryColumns,
	AsrSummaryIndexRequest,
	CountryIndexResponse,
	RateCenterIndexResponse,
	SubscriptionIndexResponse,
	TimeDisplay
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { AsrSummaryRouter } from 'RtUi/app/rtSip/AsrSummary/AsrSummary.router';
import { AsrSummaryGrid } from 'RtUi/app/rtSip/AsrSummary/lib/grids/AsrSummaryGrid';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { MultipleCheckboxFormControl } from 'RtUi/components/form/MultipleCheckboxFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { TimeFormControl } from 'RtUi/components/form/TimeFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';

interface IInitialResourceParams {
	startDate: Date;
	startTime: string;
	endTime: string;
	timeslot: TimeDisplay;
}

interface IAsrSummaryIndexContainerState
	extends ISearchResultsContainerState<AsrSummaryIndexRequest>,
		IInitialResourceParams {
	customerAccounts?: AccountIndexResponse[];
	customerSubscriptions?: SubscriptionIndexResponse[];
	customerDetail?: AsrSummaryColumns[];
	customerSignalingDetail?: AsrSummaryColumns[];
	ratingDetail?: AsrSummaryColumns[];
	customerNumberBegins?: number;
	customerCountries?: CountryIndexResponse[];
	customerRateCenter?: RateCenterIndexResponse[];
	customerDisplayDetail?: AsrSummaryColumns[];
	minSeconds?: number;
	maxSeconds?: number;
	vendorAccounts?: AccountIndexResponse[];
	vendorSubscriptions?: SubscriptionIndexResponse[];
	vendorDetail?: AsrSummaryColumns[];
	vendorSignalingDetail?: AsrSummaryColumns[];
	vendorRatingDetail?: AsrSummaryColumns[];
	vendorNumberBegins?: number;
	vendorCountries?: CountryIndexResponse[];
	vendorRateCenter?: RateCenterIndexResponse[];
	vendorDisplayDetail?: AsrSummaryColumns[];
}

@AsrSummaryRouter.getNetworkReportsIndexRtUiController()
export class AsrSummaryIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IAsrSummaryIndexContainerState,
	AsrSummaryIndexRequest
> {
	public initParams: IInitialResourceParams = {
		startDate: set(new Date(), { hours: 0, minutes: 0, seconds: 0 }),
		startTime: '00:00:00',
		endTime: '23:59:59',
		timeslot: TimeDisplay.None
	};

	public state: IAsrSummaryIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Search,
		customerDetail: [AsrSummaryColumns.CustomerSubscriptionId],
		vendorDisplayDetail: [AsrSummaryColumns.DialedNumberCountryIsoId],
		resourceParams: {
			startDate: set(new Date(), { hours: 0, minutes: 0, seconds: 0 }),
			startTime: '00:00:00',
			endTime: '23:59:59',
			timeslot: TimeDisplay.None
		}
	};

	public Tabs = AsrSummaryRouter.getProfileTabs();

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams(): AsrSummaryIndexRequest {
		const {
			startDate,
			startTime,
			endTime,
			timeslot,
			customerAccounts,
			customerSubscriptions,
			customerDetail,
			customerSignalingDetail,
			ratingDetail,
			customerNumberBegins,
			customerCountries,
			customerRateCenter,
			customerDisplayDetail,
			minSeconds,
			maxSeconds,
			vendorAccounts,
			vendorSubscriptions,
			vendorDetail,
			vendorSignalingDetail,
			vendorRatingDetail,
			vendorNumberBegins,
			vendorCountries,
			vendorRateCenter,
			vendorDisplayDetail
		} = this.state;

		const columns: AsrSummaryColumns[] = [];

		const params: AsrSummaryIndexRequest = {
			startDate,
			startTime,
			endTime,
			timeslot
		};

		if (customerAccounts) {
			params.customerAccountIds = customerAccounts.map(
				(account) => account.accountId
			);
		}

		if (customerSubscriptions) {
			params.customerSubscriptionIds = customerSubscriptions.map(
				(subscription) => subscription.subscriptionId
			);
		}

		if (vendorAccounts) {
			params.vendorAccountIds = vendorAccounts.map(
				(account) => account.accountId
			);
		}

		if (vendorSubscriptions) {
			params.vendorSubscriptionIds = vendorSubscriptions.map(
				(subscription) => subscription.subscriptionId
			);
		}

		if (customerNumberBegins) {
			params.callingPartyStd = String(customerNumberBegins);
		}

		if (customerCountries) {
			params.callingPartyCountryIsoIds = customerCountries.map(
				(country) => country.iso3166Alpha3
			);
		}

		if (customerRateCenter) {
			params.callingPartyRateCenterIds = customerRateCenter.map(
				(rateCenter) => rateCenter.rateCenterId
			);
		}

		if (vendorNumberBegins) {
			params.dialedNumberStd = String(vendorNumberBegins);
		}

		if (vendorCountries) {
			params.dialedNumberCountryIsoIds = vendorCountries.map(
				(country) => country.iso3166Alpha3
			);
		}

		if (vendorRateCenter) {
			params.dialedNumberRateCenterIds = vendorRateCenter.map(
				(rateCenter) => rateCenter.rateCenterId
			);
		}

		if (minSeconds) {
			params.minSeconds = minSeconds;
		}

		if (maxSeconds) {
			params.maxSeconds = maxSeconds;
		}

		if (customerDetail) {
			columns.push(...customerDetail);
		}

		if (customerSignalingDetail) {
			columns.push(...customerSignalingDetail);
		}

		if (ratingDetail) {
			columns.push(...ratingDetail);
		}

		if (customerDisplayDetail) {
			columns.push(...customerDisplayDetail);
		}

		if (vendorDetail) {
			columns.push(...vendorDetail);
		}

		if (vendorSignalingDetail) {
			columns.push(...vendorSignalingDetail);
		}

		if (vendorRatingDetail) {
			columns.push(...vendorRatingDetail);
		}

		if (vendorDisplayDetail) {
			columns.push(...vendorDisplayDetail);
		}

		params.columns = columns;

		return params;
	}

	public render() {
		return (
			<TabbedLayout
				router={AsrSummaryRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<TwoFADisabledWarning />
					<RtUiSearchForm wide onSubmit={(e) => this.submitAndGoToResults(e)}>
						<Row>
							<Col md={6}>
								<h5>Date and Time</h5>
								<hr />
								<DatetimeFormControl
									required
									label="Date"
									value={moment(this.state.startDate)}
									onChange={(startDate) =>
										this.setState({ startDate: startDate.toDate() })
									}
									showDateOnly={true}
								/>
								<TimeFormControl
									label="Start Time"
									className="mb-3"
									onChange={(startTime) =>
										this.setState({
											startTime
										})
									}
									value={this.state.startTime}
								/>
								<TimeFormControl
									label="End Time"
									onChange={(endTime) =>
										this.setState({
											endTime
										})
									}
									value={this.state.endTime}
								/>
								<RadioFormControl<TimeDisplay>
									label="Time Display"
									onChange={(timeslot) => this.setState({ timeslot })}
									value={this.state.timeslot}
									options={[
										{ value: TimeDisplay.None, label: 'None' },
										{ value: TimeDisplay.TimeQuarterly, label: '15 minutes' },
										{ value: TimeDisplay.TimeHourly, label: 'Hourly' }
									]}
								/>
							</Col>
							<Col md={6}>
								<h5>Miscellaneous</h5>
								<hr />
								<InputFormControl
									type="number"
									value={this.state.minSeconds?.toString()}
									label="Min. Seconds"
									onChange={(minSeconds) =>
										this.setState({ minSeconds: Number(minSeconds) })
									}
								/>
								<InputFormControl
									type="number"
									value={this.state.maxSeconds?.toString()}
									label="Max. Seconds"
									onChange={(maxSeconds) =>
										this.setState({ maxSeconds: Number(maxSeconds) })
									}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<h5>Customer</h5>
								<hr />
								<AccountSelect<true>
									label="Carriers"
									value={this.state.customerAccounts}
									onChange={(customerAccounts) =>
										this.setState({ customerAccounts })
									}
									multi
								/>
								<SubscriptionSelect<true>
									label="Subscriptions"
									value={this.state.customerSubscriptions}
									onChange={(customerSubscriptions) =>
										this.setState({ customerSubscriptions })
									}
									isMulti
								/>
								<MultipleCheckboxFormControl
									options={[
										{
											label: 'Account',
											value: AsrSummaryColumns.CustomerAccountId
										},
										{
											label: 'Subscription',
											value: AsrSummaryColumns.CustomerSubscriptionId
										},
										{
											label: 'Extended Stats',
											value: AsrSummaryColumns.ExtendedStats
										}
									]}
									value={this.state.customerDetail}
									label="Customer Detail"
									onChange={(customerDetail) => {
										this.setState({ customerDetail });
									}}
								/>
								<MultipleCheckboxFormControl
									options={[
										{
											label: 'Remote',
											value: AsrSummaryColumns.CustomerRemoteIp
										},
										{
											label: 'Result',
											value: AsrSummaryColumns.CustomerResult
										},
										{
											label: 'Local IP',
											value: AsrSummaryColumns.CustomerLocalIp
										}
									]}
									value={this.state.customerSignalingDetail}
									label="Signaling Detail"
									onChange={(customerSignalingDetail) => {
										this.setState({ customerSignalingDetail });
									}}
								/>
								<MultipleCheckboxFormControl
									options={[
										{
											label: 'Rate Key',
											value: AsrSummaryColumns.CustomerRateKey
										},
										{
											label: 'Rate',
											value: AsrSummaryColumns.CustomerRate
										}
									]}
									value={this.state.ratingDetail}
									label="Rating Detail"
									onChange={(ratingDetail) => {
										this.setState({ ratingDetail });
									}}
								/>
							</Col>
							<Col md={6}>
								<h5>Vendor</h5>
								<hr />
								<AccountSelect<true>
									label="Vendors"
									value={this.state.vendorAccounts}
									onChange={(vendorAccounts) =>
										this.setState({ vendorAccounts })
									}
									multi
								/>
								<SubscriptionSelect<true>
									label="Subscriptions"
									value={this.state.vendorSubscriptions}
									onChange={(vendorSubscriptions) =>
										this.setState({ vendorSubscriptions })
									}
									isMulti
								/>
								<MultipleCheckboxFormControl
									options={[
										{
											label: 'Account',
											value: AsrSummaryColumns.VendorAccountId
										},
										{
											label: 'Subscription',
											value: AsrSummaryColumns.VendorSubscriptionId
										}
									]}
									value={this.state.vendorDetail}
									label="Vendor Detail"
									onChange={(vendorDetail) => {
										this.setState({ vendorDetail });
									}}
								/>
								<MultipleCheckboxFormControl
									options={[
										{
											label: 'Remote IP',
											value: AsrSummaryColumns.VendorRemoteIp
										},
										{
											label: 'Result',
											value: AsrSummaryColumns.VendorResult
										},
										{
											label: 'Local IP',
											value: AsrSummaryColumns.VendorLocalIp
										}
									]}
									value={this.state.vendorSignalingDetail}
									label="Signaling Detail"
									onChange={(vendorSignalingDetail) => {
										this.setState({ vendorSignalingDetail });
									}}
								/>
								<MultipleCheckboxFormControl
									options={[
										{
											label: 'Rate Key',
											value: AsrSummaryColumns.VendorRateKey
										},
										{
											label: 'Rate',
											value: AsrSummaryColumns.VendorRate
										}
									]}
									value={this.state.vendorRatingDetail}
									label="Rating Detail"
									onChange={(vendorRatingDetail) => {
										this.setState({ vendorRatingDetail });
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<h5>Calling Party</h5>
								<hr />
								<InputFormControl
									type="number"
									value={this.state.customerNumberBegins?.toString()}
									label="Number (begins)"
									onChange={(numberBegins) =>
										this.setState({
											customerNumberBegins: Number(numberBegins)
										})
									}
								/>
								<CountrySelect<true>
									isMulti
									label="Countries"
									value={this.state.customerCountries}
									onChange={(customerCountries) =>
										this.setState({ customerCountries })
									}
								/>
								<RateCenterSelect<true>
									multi
									label="Rate Centers"
									value={this.state.customerRateCenter}
									onChange={(customerRateCenter) =>
										this.setState({ customerRateCenter })
									}
								/>
								<MultipleCheckboxFormControl
									options={[
										{
											label: 'Country',
											value: AsrSummaryColumns.CallingPartyCountryIsoId
										},
										{
											label: 'Rate Center',
											value: AsrSummaryColumns.CallingPartyRateCenterId
										}
									]}
									value={this.state.customerDisplayDetail}
									label="Display Detail"
									onChange={(customerDisplayDetail) => {
										this.setState({ customerDisplayDetail });
									}}
								/>
							</Col>
							<Col md={6}>
								<h5>Destination</h5>
								<hr />
								<InputFormControl
									type="number"
									value={this.state.vendorNumberBegins?.toString()}
									label="Number (begins)"
									onChange={(numberBegins) =>
										this.setState({
											vendorNumberBegins: Number(numberBegins)
										})
									}
								/>
								<CountrySelect<true>
									isMulti
									label="Countries"
									value={this.state.vendorCountries}
									onChange={(vendorCountries) =>
										this.setState({ vendorCountries })
									}
								/>
								<RateCenterSelect<true>
									multi
									label="Rate Centers"
									value={this.state.vendorRateCenter}
									onChange={(vendorRateCenter) =>
										this.setState({ vendorRateCenter })
									}
								/>
								<MultipleCheckboxFormControl
									options={[
										{
											label: 'Country',
											value: AsrSummaryColumns.DialedNumberCountryIsoId
										},
										{
											label: 'Rate Center',
											value: AsrSummaryColumns.DialedNumberRateCenterId
										}
									]}
									value={this.state.vendorDisplayDetail}
									label="Display Detail"
									onChange={(vendorDisplayDetail) => {
										this.setState({ vendorDisplayDetail });
									}}
								/>
							</Col>
						</Row>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<AsrSummaryGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
