import * as moment from 'moment-timezone';
import { CprApprovalIndicator, CprStatus } from 'RtModels';
import { IRadioButtonOptions } from 'RtUi/components/ui/RadioButtons';

interface INumberKeyValue<ValueType = string> {
	[key: number]: ValueType;
}

export const CprApprovalIndicatorLabels: {
	[cprStatus in CprApprovalIndicator]: string;
} = {
	[CprApprovalIndicator.NotApplicable]: 'Not Applicable',
	[CprApprovalIndicator.Ok]: 'Ok',
	[CprApprovalIndicator.AwaitingApproval]: 'Awaiting Approval',
	[CprApprovalIndicator.ApprovalDenied]: 'Approval Denied',
	[CprApprovalIndicator.NotRequired]: 'Not Required',
	[CprApprovalIndicator.RejectedEffectiveDate]: 'Rejected Effective Date',
	[CprApprovalIndicator.Unknown]: 'Unknown'
};

export const MgiCprStatusLabels: { [cprStatus in CprStatus]: string } = {
	[CprStatus.Saved]: 'Saved',
	[CprStatus.Pending]: 'Pending',
	[CprStatus.Sending]: 'Sending',
	[CprStatus.Active]: 'Active',
	[CprStatus.Old]: 'Old',
	[CprStatus.Invalid]: 'Invalid',
	[CprStatus.Disconnect]: 'Disconnect',
	[CprStatus.MustCheck]: 'Must Check',
	[CprStatus.Failed]: 'Failed',
	[CprStatus.Hold]: 'Hold',
	[CprStatus.Unknown]: 'Unknown'
};

export const MgiScpStatus: INumberKeyValue = {
	0: 'Outside AOS',
	1: 'Accepted',
	2: 'Rejected (Syntax)',
	3: 'Rejected (Does not Exist)',
	4: 'Rejected (Too Large)',
	5: 'Rejected (SCP Application)',
	6: 'Rejected (Effective Date)',
	7: 'Future',
	8: 'Queued (SCP Overloaded)',
	9: 'Queued (SCP Unavailable)',
	10: 'Queued (SCP No Response)',
	11: 'Sending (Response)',
	12: 'Loading (SCP Loaded)',
	13: 'Rejected (SCP Load)',
	14: 'Audit (Records match)',
	15: 'Audit (Does not Exist)',
	16: 'Audit (Does not Match)',
	17: 'Audit (No Match Memory)',
	18: 'Audit (In Disk, Not Memory)',
	19: 'Audit (No Entry Memory)',
	20: 'Audit (Mismatched RespOrgId)',
	21: 'Audit (Inconsistent Effective Date)',
	22: 'Audit (Failed: SCP Overloaded)',
	23: 'Audit (Failed: SCP Unavailable)',
	24: 'Audit (Failed: SCP No Response)',
	25: 'Audit (Failed: SCP No Templates)',
	99: 'Other'
};

export enum MgiTollFreeStatus {
	A = 'Assigned',
	D = 'Disconnected',
	P = 'Pending',
	R = 'Reserved',
	S = 'Spare',
	T = 'Transitional',
	U = 'Unavailable',
	W = 'Working',
	Y = 'WhyDoesThisExist'
}

const TFNTaskStatusMap: { [key: string]: string[] } = {
	'Activate': ['A', 'D', 'R', 'T'],
	'Disconnect': ['W'],
	'Query': ['*'],
	'Re-Route': ['A', 'W'],
	'RespOrg Change': ['A', 'D', 'R', 'T', 'W'],
	'Spare': ['D', 'T']
};

export enum RocNumberStatusEnum {
	Pending = 0,
	PortProcessing = 1,
	Ported = 2,
	Declined = 3,
	Expired = 4,
	HDR = 5,
	Invalid = 6,
	PortFailed = 7,
	Overdue = 8,
	DueDateApproval = 9
}

//cSpell:ignore IROC
interface IROCNumberStatus {
	status: RocNumberStatusEnum;
	label: string;
	cancelable: boolean;
	info: string;
	icon: string;
	color: string;
}

export const ROCNumberStatusMap: IROCNumberStatus[] = [
	{
		status: RocNumberStatusEnum.Pending,
		label: 'Pending',
		cancelable: true,
		info: 'The Dial number is still un-processed.',
		icon: 'fas fa-clock',
		color: 'info'
	},
	{
		status: RocNumberStatusEnum.PortProcessing,
		label: 'Port Processing',
		cancelable: true,
		info: 'The Dial number has been sent to MGI.',
		icon: 'fas fa-clock',
		color: 'info'
	},
	{
		status: RocNumberStatusEnum.Ported,
		label: 'Ported',
		cancelable: false,
		info: 'The Dial number has been ported from the controlling RespOrg to the submitting RespOrgs.',
		icon: 'fas fa-thumbs-up',
		color: 'success'
	},
	{
		status: RocNumberStatusEnum.Declined,
		label: 'Declined',
		cancelable: false,
		info: 'The request for that dial number has been declined by either the submitter itself or the controlling RespOrg.',
		icon: 'fas fa-ban',
		color: 'warning'
	},
	{
		status: RocNumberStatusEnum.Expired,
		label: 'Expired',
		cancelable: false,
		info: 'If request is left un-processed for 2 working days, the request is expired automatically.',
		icon: 'fas fa-ban',
		color: 'warning'
	},
	{
		status: RocNumberStatusEnum.HDR,
		label: 'HDR',
		cancelable: false,
		info: 'The request is escalated to the Help Desk via a RespOrg Help Desk Intervention request.',
		icon: 'fas fa-clock',
		color: ''
	},
	{
		status: RocNumberStatusEnum.Invalid,
		label: 'Invalid',
		cancelable: true,
		info: 'In any case if the dial number is invalid.',
		icon: 'fas fa-ban',
		color: 'warning'
	},
	{
		status: RocNumberStatusEnum.PortFailed,
		label: 'Port Failed | Failure Description',
		cancelable: false,
		info: 'The failure description is the reason for port failure received from the MGI system. Possible values are identified in section 6.1.1.',
		icon: 'fas fa-ban',
		color: 'warning'
	},
	{
		status: RocNumberStatusEnum.Overdue,
		label: 'Overdue',
		cancelable: true,
		info: 'If the request Due Date is older than current date than status is updated to Overdue automatically.',
		icon: 'fas fa-ban',
		color: 'warning'
	},
	{
		status: RocNumberStatusEnum.DueDateApproval,
		label: 'Due Date Approval',
		cancelable: true,
		info: 'This status shows that dial number is due for processing and will be auto approved just before the status becomes Overdue.',
		icon: 'fas fa-thumbs-up',
		color: 'info'
	}
];

export type IPriorityOptionValue = 1 | 2 | 3 | 99;
export type IPriorityRadioButtonOptions =
	IRadioButtonOptions<IPriorityOptionValue>;

export const PriorityOptions: IPriorityRadioButtonOptions[] = [
	{ label: 'High', value: 1, tooltip: 'Typically Tasks of 5,000 or Less' },
	{
		label: 'Medium (Default)',
		value: 2,
		tooltip: 'Large Tasks that Can Wait Behind Higher Priority Tasks'
	},
	{
		label: 'Low',
		value: 3,
		tooltip:
			'Extremely Large Tasks that may take Several Hours or Days to Complete'
	},
	{
		label: 'Low (when idle)',
		value: 99,
		tooltip: 'Only one task per Resource will be executed at once'
	}
];

export type SpareOptionValueT = 46 | 60 | 90 | 120;
export type SpareOptionsRadioButtonOptionsT =
	IRadioButtonOptions<SpareOptionValueT>;

export const SpareOptions: SpareOptionsRadioButtonOptionsT[] = [
	{ label: '45D', value: 46 },
	{ label: '60D', value: 60 },
	{ label: '90D', value: 90 },
	{ label: '120D', value: 120 }
];

export const tfnTaskStatusToName = (statusCharacter: string) => {
	return Object.keys(TFNTaskStatusMap).find((tfnName) => {
		const statuses = TFNTaskStatusMap[tfnName];
		const hasStatus = statuses.some(
			(statusChar) => statusCharacter === statusChar
		);

		return hasStatus;
	});
};

export const approvalIndicatorToName = (
	ai: CprApprovalIndicator | undefined
) => {
	if (!ai) {
		return undefined;
	}

	return CprApprovalIndicatorLabels[ai];
};

export const cprStatusIdToName = (statusId: CprStatus | undefined) => {
	if (!statusId) {
		return undefined;
	}

	return MgiCprStatusLabels[statusId];
};

export const scpStatusIdToName = (statusId: number) => {
	return MgiScpStatus[statusId] ?? statusId;
};

export const tfnStatusToName = (tfnStatusId: string) => {
	if (tfnStatusId in MgiTollFreeStatus) {
		return MgiTollFreeStatus[tfnStatusId as keyof typeof MgiTollFreeStatus];
	}

	return tfnStatusId;
};

export const timestampToReadable = (
	timestamp: string | Date | moment.Moment | undefined | null,
	timezone?: string
) => {
	if (timestamp !== null) {
		let tsMoment = moment(timestamp);

		if (timezone) {
			tsMoment = tsMoment.tz(timezone);
		}

		if (tsMoment.isValid()) {
			return tsMoment.format('MM/DD/YYYY hh:mma');
		}
	}

	return '';
};

export const dateToReadable = (
	date: string | Date | moment.Moment | undefined | null,
	showTime = false,
	timezone?: string,
	isMilitaryTimeFormat?: boolean
) => {
	if (date !== null) {
		let tsMoment = moment(date);
		if (timezone === 'UTC') {
			tsMoment = moment.utc(date);
		}

		if (timezone) {
			tsMoment = tsMoment.tz(timezone);
		}

		if (tsMoment.isValid()) {
			let format = 'YYYY-MM-DD';

			if (showTime) {
				if (isMilitaryTimeFormat) {
					format += ' HH:mm:ss';
				} else {
					format += ' h:mma';
				}
			}

			return tsMoment.format(format);
		}
	}

	return '';
};

export const timeToReadable = (date: number | undefined | null) => {
	if (date !== null) {
		const tsMoment = moment.duration(date, 'seconds');

		if (tsMoment.isValid()) {
			return moment()
				.hours(tsMoment.hours())
				.seconds(tsMoment.seconds())
				.minutes(tsMoment.minutes())
				.format('HH:mm:ss');
		}
	}

	return '';
};

export const rocStatusToLabel = (status: number) => {
	const rocStatus = ROCNumberStatusMap.find((s) => s.status === status);

	if (rocStatus) {
		return rocStatus.label;
	}

	return null;
};

export const rocStatusToIcon = (status: number) => {
	const rocStatus = ROCNumberStatusMap.find((s) => s.status === status);

	if (rocStatus) {
		return rocStatus.icon;
	}

	return null;
};

export const rocStatusToColor = (status: number) => {
	const rocStatus = ROCNumberStatusMap.find((s) => s.status === status);

	if (rocStatus) {
		return rocStatus.color;
	}

	return null;
};

export const mapPriorityToLabel = (priority: number = -1) => {
	const foundOption = PriorityOptions.find((po) => po.value === priority);
	let label: React.ReactNode = '';

	if (foundOption && foundOption.label) {
		label = foundOption.label;
	}

	return label;
};

export const ProtocolTypesOptions = [
	{ label: 'https', value: 'https' },
	{ label: 'http', value: 'http' },
	{ label: 'ftp', value: 'ftp' }
];
