import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Item, Submenu } from 'react-contexify';
import { useNavigate } from 'react-router-dom';

interface IDataGridMenuItemProps<T> {
	record: T;
	router: RtUiRouter;
}

export const ExternalDataGridMenuItem = <T = {},>({
	record,
	router
}: IDataGridMenuItemProps<T>): JSX.Element => {
	const routerName = router.getName();
	const navigate = useNavigate();
	const tabsToRender = router.getTabsFor(record);
	const actionTabsToRender = tabsToRender.filter(
		(tab) => tab.isAction === true
	);
	const standardTabsToRender = tabsToRender.filter((tab) => !tab.isAction);

	const goToRecordPath = useCallback(
		(tabHeader?: string) => {
			const path = router.getProfileRoute(record, tabHeader);

			navigate(path);
		},
		[navigate, record, router]
	);

	const label = useMemo(
		() => (
			<Item onClick={() => goToRecordPath()}>
				<span className="d-flex justify-content-start align-items-center">
					<span>&nbsp;{routerName}</span>
				</span>
			</Item>
		),
		[goToRecordPath, routerName]
	);

	if (standardTabsToRender.length === 0 && actionTabsToRender.length === 0) {
		return <></>;
	}

	return (
		<>
			<Submenu label={label}>
				{standardTabsToRender.map((tab) => (
					<Item key={tab.header} onClick={() => goToRecordPath(tab.header)}>
						<div
							className="d-flex flex-row"
							style={{ width: '100%', flexShrink: 0, flexGrow: 1 }}
						>
							<div className="flex-grow-1">
								<span>{tab.header}</span>
							</div>
							<div className="align-self-end">
								<Button
									variant="white"
									size="sm"
									className="py-0"
									title={`Open ${tab.header} in a new tab.`}
									target="_blank"
									onClick={(evt) => evt.stopPropagation()}
									href={`${router.getProfileRoute(record, tab.header)}`}
									rel="noreferrer"
								>
									<i className="fas fa-external-link-alt px-2" />
								</Button>
							</div>
						</div>
					</Item>
				))}
			</Submenu>
		</>
	);
};
