import { IRtxApiRoute, Permissions } from 'RtExports/routes';
import { RtError } from 'RtUi/utils/errors/RtError';
import { IRequestInitWithParams } from './interfaces';
import { ManifestChecker } from 'RtUi/app/ApplicationShell/lib/components/ManifestChecker';

declare const RTX_API_URL: string; //Defined in .env file

interface ITypedResponse<T> extends Response {
	json(): Promise<T>;
}

export class HttpRequest {
	public static async addDefaultsToRequestInit(
		baseInit: IRequestInitWithParams
	): Promise<IRequestInitWithParams> {
		const init: IRequestInitWithParams = {
			method: 'GET',
			credentials: 'include',
			...baseInit
		};

		if (!init.headers) {
			init.headers = {};
		}

		if (!Boolean(init.removeContentType)) {
			init.headers['Content-Type'] = 'application/json';
		}

		if (HttpRequest.AuthorizationHeaderValue) {
			init.headers.authorization = HttpRequest.AuthorizationHeaderValue;
		}

		init.headers['X-RTX-UI'] = await ManifestChecker.getManifestVersion();

		return init;
	}

	public static GetUserDoesNotHavePermissionsDeferReject(
		permissions: Permissions[]
	) {
		return Promise.reject([
			`Missing User Permission(s) ${permissions.join('::')}`
		]);
	}

	public static userHasPermissions(permissions: Permissions[]) {
		if (permissions.length === 0) {
			return true;
		}

		return HttpRequest.UserPermissions.some((permission) =>
			permissions.includes(permission)
		);
	}

	// this is not updating it
	public static updateAuthToken(token: string) {
		HttpRequest.AuthorizationHeaderValue = `Bearer ${token}`;
	}

	public static updateUserPermissions(permissions: Permissions[]) {
		HttpRequest.UserPermissions = permissions;
	}

	public static fetchWithRoute<U>(
		apiRoute: IRtxApiRoute,
		init: IRequestInitWithParams = {}
	) {
		if (!init.method) {
			init.method = apiRoute.httpMethod;
		}

		return this.fetch<U>(apiRoute.path, apiRoute.permissions, init);
	}

	public static async fetch<U>(
		path: string,
		permissions: Permissions[],
		init: IRequestInitWithParams = {}
	): Promise<U> {
		const userHasPermissionsForFetch =
			HttpRequest.userHasPermissions(permissions);

		if (!userHasPermissionsForFetch) {
			return HttpRequest.GetUserDoesNotHavePermissionsDeferReject(permissions);
		}

		init = await HttpRequest.addDefaultsToRequestInit(init);
		path = HttpRequest.getPath(path, init);

		const res = await HttpRequest.fetchWithErrorHandling(path, init);

		if (res.ok) {
			if (res.status === 204) {
				const returnVal = undefined as unknown as U;

				return returnVal;
			}

			const response = (await res.json()) as Promise<U>;

			return response;
		} else {
			throw res;
		}
	}

	public static async rawFetchWithRoute<T>(
		apiRoute: IRtxApiRoute,
		init: IRequestInitWithParams = {}
	) {
		const userHasPermissionsForFetch = HttpRequest.userHasPermissions(
			apiRoute.permissions
		);

		if (!userHasPermissionsForFetch) {
			return HttpRequest.GetUserDoesNotHavePermissionsDeferReject(
				apiRoute.permissions
			);
		}

		if (!init.method) {
			init.method = apiRoute.httpMethod;
		}

		const path = HttpRequest.getPath(apiRoute.path, init);
		init = await HttpRequest.addDefaultsToRequestInit(init);

		return this.fetchPromise<T>(path, init);
	}

	/**
	 * Return both fetch request and headers
	 */
	public static async fetchWithHeaders<U>(
		path: string,
		permissions: Permissions[],
		init: IRequestInitWithParams = {}
	): Promise<[U, Headers]> {
		const userHasPermissionsForFetch =
			HttpRequest.userHasPermissions(permissions);

		if (!userHasPermissionsForFetch) {
			return HttpRequest.GetUserDoesNotHavePermissionsDeferReject(permissions);
		}

		init = await HttpRequest.addDefaultsToRequestInit(init);
		path = HttpRequest.getPath(path, init);
		let headers: Headers = new Headers();

		try {
			const res = await HttpRequest.fetchWithErrorHandling(path, init);
			headers = res.headers;

			if (res.ok) {
				if (res.status === 204) {
					const returnVal = undefined as unknown as U;

					return [returnVal, headers];
				}

				const json = (await res.json()) as U;
				const response: [U, Headers] = [json, headers];

				return response;
			} else {
				throw res;
			}
		} catch (errors) {
			throw errors;
		}
	}

	/**
	 * Invoke Unauthorized Listeners
	 */
	public static onUnauthorizedFetch(listener: (response: Response) => void) {
		this.unauthorizedListeners.push(listener);

		return () => {
			const index = this.unauthorizedListeners.indexOf(listener);

			if (index >= 0) {
				this.unauthorizedListeners.splice(index, 1);
			}
		};
	}

	public static onNotFoundFetch(listener: (response: Response) => void) {
		this.notFoundListeners.push(listener);

		return () => {
			const index = this.notFoundListeners.indexOf(listener);

			if (index >= 0) {
				this.notFoundListeners.splice(index, 1);
			}
		};
	}

	/**
	 * Transmogrify Response to an array of errors (strings).
	 */
	public static httpErrorToReadableMessage(res: Response) {
		return RtError.createFromResponse(res);
	}

	/**
	 * Get api url specified by environment variable
	 */
	public static getApiEndpoint() {
		let apiPath = RTX_API_URL;

		if (RTX_API_URL === 'DYNAMIC') {
			const { protocol, host } = location;
			apiPath = `${protocol}//${host}/api`;
		}

		return apiPath;
	}

	/**
	 * Get endpoint path given an IRequestInitWithParams
	 *
	 * Note: adds IRequestInitWithParams.params manually
	 */
	public static getPath(path: string, init: IRequestInitWithParams) {
		path = this.getApiEndpoint() + path;
		path = this.addParamsToPath(path, init);

		return path;
	}

	/**
	 * Add search and url params to path
	 * @param path
	 * @param init
	 */
	public static addParamsToPath(
		path: string,
		init: IRequestInitWithParams = {}
	) {
		//Add search params
		if (init.params) {
			const { params } = init;
			const pathHasQuestionMark = path.includes('?');
			let concatPunctuation = pathHasQuestionMark ? '&' : '?';

			for (const key of Object.keys(params)) {
				const value = params[key];
				let valueStr = String(value);

				if (Array.isArray(value)) {
					valueStr = value.map((v) => `${key}[]=${v}`).join('&');

					path += `${concatPunctuation}${valueStr}`;

					//increment from ? to &
					concatPunctuation = '&';
					continue;
				} else if (value instanceof Date) {
					//OW MY GOD, WHY
					valueStr = value.toISOString();
				}

				path += `${concatPunctuation}${key}=${valueStr}`;

				//increment from ? to &
				concatPunctuation = '&';
			}
		}

		//add url params
		if (init.urlParams) {
			for (const urlParamKey of Object.keys(init.urlParams)) {
				const urlParam = init.urlParams[urlParamKey];

				path = path.replace(`:${urlParamKey}`, String(urlParam));
			}
		}

		return path;
	}

	/**
	 * Get header value given a headerName or default to defaultValue if does not exist
	 * @param headers
	 * @param headerName
	 * @param defaultValue
	 */
	public static getHeaderValue(
		headers: Headers,
		headerName: string,
		defaultValue: string
	) {
		if (headers.has(headerName)) {
			return headers.get(headerName)!;
		}

		return defaultValue;
	}

	public static AuthorizationHeaderValue = '';
	private static UserPermissions: Permissions[] = [];
	private static unauthorizedListeners: Array<(response: Response) => void> =
		[];
	private static notFoundListeners: Array<(response: Response) => void> = [];

	/**
	 * Transform window.fetch to an actual promise.Why window fetch? Why not something more robust?
	 *
	 * Must be done to allow for .finally to work on Edge browsers. See: https://github.com/zloirock/core-js/issues/178#issuecomment-192081350.
	 * @param input
	 * @param init
	 */
	private static async fetchPromise<T>(
		input: Request | string,
		init?: RequestInit
	) {
		return new Promise<ITypedResponse<T>>((resolve, reject) => {
			window.fetch(input, init).then(resolve).catch(reject);
		});
		// await axios.get()
	}

	/**
	 *
	 * @param path
	 * @param init
	 */
	private static async fetchWithErrorHandling<T>(
		path: string,
		init?: IRequestInitWithParams
	) {
		const res = await this.fetchPromise<T>(path, init);

		await HttpRequest.throwIfResponseIsError(res, init);

		return res;
	}

	/**
	 * Handle error(s) from fetch response if fetch failed
	 */
	private static async throwIfResponseIsError(
		res: Response,
		init?: IRequestInitWithParams
	) {
		const hasError = res.status >= 400 || !res.ok;

		if (!hasError) {
			return;
		}

		const isUnauthorized = res.status === 401;
		const notFound = res.status === 404;

		if (isUnauthorized) {
			for (const unauthorizedListener of this.unauthorizedListeners) {
				unauthorizedListener(res);
			}
		}

		const shouldNotifyOn404 = init?.doNotNotifyOn404Error ? false : true;

		if (notFound && shouldNotifyOn404) {
			for (const notFoundListener of this.notFoundListeners) {
				notFoundListener(res);
			}
		}

		const error = await RtError.createFromResponse(res);

		throw error;
	}
}
