import {
	GuardianReconciliationMappingIndexRequest,
	GuardianReconciliationMappingIndexResponse
} from 'RtModels';
import { GuardianReconciliationMappingsRouter } from 'RtUi/app/rtVue/GuardianReconciliationMapping/GuardianReconciliationMapping.router';
import { useGetReconciliationMapping } from 'RtUi/app/rtVue/GuardianReconciliationMapping/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getReconciliationMappingColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/reconciliationMapping';
import { useMemo } from 'react';

interface IGuardianReconciliationMappingGridProps {
	onRowClick?: (row: GuardianReconciliationMappingIndexResponse) => void;
	resourceParams?: GuardianReconciliationMappingIndexRequest;
	autoFocusFilter?: boolean;
}

export const GuardianReconciliationMappingGrid = ({
	resourceParams,
	onRowClick
}: IGuardianReconciliationMappingGridProps) => {
	const { data, isFetching: isLoading } =
		useGetReconciliationMapping(resourceParams);
	const columns = useMemo(() => getReconciliationMappingColumns(), []);

	return (
		<DataGrid
			router={GuardianReconciliationMappingsRouter}
			data={data}
			loading={isLoading}
			pageName="rtVue_ReconciliationMapping"
			columns={columns}
			onRowClick={onRowClick}
		/>
	);
};
