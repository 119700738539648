/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationMappingFiltersIndexResponse = {
    properties: {
        guardianReconciliationMappingFilterId: {
            type: 'number',
            isRequired: true,
        },
        guardianReconciliationMappingId: {
            type: 'number',
            isRequired: true,
        },
        guardianReconciliationMappingOperand: {
            type: 'string',
            isRequired: true,
        },
        dataSourceDirection: {
            type: 'ReconciliationMappingDataSourceDirection',
            isRequired: true,
        },
        dataSourceField: {
            type: 'ReconciliationMappingDataSourceField',
            isRequired: true,
        },
        dataSourceType: {
            type: 'ReconciliationMappingDataSourceType',
            isRequired: true,
        },
        dataSourceValue: {
            type: 'string',
            isRequired: true,
        },
        filters: {
            properties: {
            },
            isRequired: true,
            isNullable: true,
        },
        notes: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
    },
};