/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationMappingFiltersIndexRequest = {
    properties: {
        guardianReconciliationMappingId: {
            type: 'number',
        },
        guardianReconciliationMappingOperand: {
            type: 'string',
        },
        dataSourceDirection: {
            type: 'ReconciliationMappingDataSourceDirection',
        },
        dataSourceField: {
            type: 'ReconciliationMappingDataSourceField',
        },
        isActive: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};