import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { DidApplicationRouter } from 'RtUi/app/rtDid/rtDid.router';
import { RtxDidApiRoutes } from 'RtExports/routes';

export class DidSearchRouterClass extends DidApplicationRouter<
	void,
	void,
	IProfileApplicationContainerTabs
> {
	constructor() {
		super('Search', 'search', undefined, {});

		this.setPermissionsFromApiRoute(RtxDidApiRoutes.NanpSearch);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const DidSearchRouter = new DidSearchRouterClass();
