import {
	GuardianFraudListIndexRequest,
	GuardianFraudListIndexResponse
} from 'RtModels';
import { FraudConfigurationListEditor } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/forms/FraudConfigurationListEditor';
import { FraudConfigurationListResource } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/resources/FraudConfigurationListResource';
import { useGetFraudConfigurationList } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getFraudConfigurationListColumns } from 'RtUi/components/data/DataGrid/configurations/rtGuardian/fraudConfiguration';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { Fragment, useMemo, useState } from 'react';

export interface IFraudConfigurationListGridProps {
	pageName: string;
	resourceParams: GuardianFraudListIndexRequest;
}

export const FraudConfigurationListGrid = ({
	resourceParams,
	pageName
}: IFraudConfigurationListGridProps): JSX.Element => {
	const { data, isLoading } = useGetFraudConfigurationList(resourceParams);
	const [asideIsOpen, setAsideOpen] = useState(false);
	const [selectedRecord, setSelectedRecord] =
		useState<GuardianFraudListIndexResponse>();

	const removeEntity = async (row: GuardianFraudListIndexResponse) => {
		const confirm = await Confirmation.createDelete(row.summary);

		if (!confirm) {
			return;
		}

		await new FraudConfigurationListResource().delete(row.guardianFraudListId);
	};

	const columns = useMemo(
		() => getFraudConfigurationListColumns(removeEntity, resourceParams),
		[resourceParams]
	);

	return (
		<>
			{asideIsOpen && (
				<Aside isOpen={asideIsOpen} disabledBody>
					<Fragment key={selectedRecord?.guardianFraudListId}>
						<Aside.Header
							warning={Boolean(selectedRecord)}
							header="Configuration"
							onClose={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
						/>
						<FraudConfigurationListEditor
							global={Boolean(resourceParams.globalRule)}
							block={Boolean(resourceParams.block)}
							guardianFraudListId={selectedRecord?.guardianFraudListId ?? -1}
							editMode={selectedRecord}
							onCancel={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
							onSuccess={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
						/>
					</Fragment>
				</Aside>
			)}
			<DataGrid<GuardianFraudListIndexResponse>
				data={data?.data}
				loading={isLoading}
				onRowClick={(row) => {
					setAsideOpen(true);
					setSelectedRecord(row);
				}}
				pageName={pageName}
				onClickCreate={() => setAsideOpen(true)}
				columns={columns}
				totalRows={data?.totalCount}
				rowThemeColor={(record) =>
					record.guardianFraudListId === selectedRecord?.guardianFraudListId
						? RowThemeColor.WARNING
						: undefined
				}
			/>
			<Confirmation />
		</>
	);
};
