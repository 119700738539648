import { FC, FormEvent, useState } from 'react';
import {
	GuardianAlertRecipient,
	MessageType,
	UserIndexResponse
} from 'RtModels';
import { AlertsRecipientsResource } from 'RtUi/app/rtGuardian/AlertRules/lib/resources/AlertsRecipientsResource';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IReconciliationAttachmentsUploadFormProps {
	onSuccess?: () => void;
	onCancel?: () => void;
	editMode?: GuardianAlertRecipient;
	guardianAlertId: number;
}

export const AlertsRecipientsEditor: FC<
	React.PropsWithChildren<IReconciliationAttachmentsUploadFormProps>
> = ({
	onSuccess = () => ({}),
	onCancel = () => ({}),
	editMode,
	guardianAlertId
}) => {
	const resource = new AlertsRecipientsResource();
	const [selectedUser, setSelectedUser] = useState<UserIndexResponse>();
	const [messageTypes, setMessageTypes] =
		useState<Array<ISimpleSelectFormControlOption<MessageType>>>();
	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		editMode,
		createNew: () => resource.createNewProfile(guardianAlertId)
	});
	const { workingCopy } = rtUiFormState;

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			if (editMode) {
				await resource.update(editMode.guardianAlertRecipientId, workingCopy);
			} else {
				await resource.create(workingCopy);
			}
			rtUiFormMethods.setIsSubmitting(false);
			onSuccess();
		} catch (err) {
			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setError(err);
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			displayMode={false}
			onSubmit={onSubmit}
			onCancel={() => {
				setSelectedUser(undefined);
				rtUiFormMethods.onCancel();
				onCancel();
			}}
		>
			<UserSelect
				required
				value={selectedUser}
				initialOptionId={String(workingCopy?.userId)}
				clearable={false}
				onChange={(u) => {
					setSelectedUser(u);
					rtUiFormMethods.setWorkingCopy({ userId: u.userId });
				}}
			/>
			<SimpleSelectFormControl<MessageType, true>
				required
				multi
				label="Message Type"
				options={[
					{
						label: 'Email',
						value: MessageType.Email
					},
					{
						label: 'Application Messaging',
						value: MessageType.Firebase
					}
				]}
				value={messageTypes}
				onChange={(mt) => {
					setMessageTypes(mt);
					rtUiFormMethods.setWorkingCopy({
						messageTypeIds: mt.map((m: { value: any }) => m.value)
					});
				}}
				initialOptionId={workingCopy?.messageTypeIds.map((mt) => String(mt))}
			/>
		</RtUiForm>
	);
};
