import {
	ConnectionIndexResponse,
	ConnectionProfileResponse,
	ConnectionIndexRequest
} from 'RtModels';
import { RtVueApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class TrunkGroupResource extends HttpResource<
	ConnectionIndexResponse,
	ConnectionProfileResponse,
	ConnectionIndexRequest
> {
	constructor() {
		super(RtVueApiRoutes.Connections);
	}
}
