import {
	RateLoadIndetMethod,
	RatePlanIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { getRateLoadIndetMethodLabel } from 'RtUi/app/AccountManagement/RatePlan/lib/util';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { ServiceDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ServiceDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getRatePlansColumns = (): Array<
	DataGridColumn<RatePlanIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'ratePlanId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isShared',
		header: 'Shared'
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	IntegerDataGridColumn({
		accessorKey: 'rateKeyDeleteDays',
		header: 'Delete Days'
	}),
	IntegerDataGridColumn({
		accessorKey: 'rateKeyMatchIncreaseDays',
		header: 'Match Increase Days'
	}),
	IntegerDataGridColumn({
		accessorKey: 'rateKeyExpandIncreaseDays',
		header: 'Expand Increase Days'
	}),
	DefaultDataGridColumn({
		accessorKey: 'indetMethod',
		header: 'Indet Method',
		getValue: ({ cell }) =>
			getRateLoadIndetMethodLabel(cell.getValue<RateLoadIndetMethod>())
	}),
	TimeStampDataGridColumn({
		accessorKey: 'lastRateLoadTs',
		header: 'Last Rate Load'
	})
];

export const getRatePlanSubscriptionsColumns = (): Array<
	DataGridColumn<SubscriptionIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'subscriptionId'
	}),
	AccountDataGridColumn({
		accessorKey: 'accountId',
		header: 'Account'
	}),
	LabelDataGridColumn({
		accessorKey: 'label',
		header: 'Subscription'
	}),
	ServiceDataGridColumn({
		accessorKey: 'serviceId',
		header: 'Service'
	})
];
