import { CountryIndexResponse, DataSources } from 'RtModels';
import {
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { IndexedDbCacheStrategy } from 'RtUi/utils/cache/strategies/IndexedDbCacheStrategy';

/**
 * @singleton
 */
export class RtVueCountryFilter extends RtVueFilter<CountryIndexResponse> {
	public static getInstance() {
		if (!RtVueCountryFilter.instance) {
			RtVueCountryFilter.instance = new RtVueCountryFilter();
		}

		return RtVueCountryFilter.instance;
	}

	private static instance: RtVueCountryFilter | undefined;
	private static readonly FilterName = 'RtVueCountryFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<CountryIndexResponse>
	> = new IndexedDbCacheStrategy(
		RtVueCountryFilter.FilterName,
		'iso3166Alpha3',
		['label', '*keywords' as keyof CountryIndexResponse]
	);

	protected constructor() {
		super(
			RtVueCountryFilter.FilterName,
			DataSources.Countries,
			RtVueCountryFilter.DataCache
		);
	}

	public getLabelFor(record: CountryIndexResponse): string {
		return record.label;
	}

	public getValueFor(record: CountryIndexResponse): string {
		return String(record.iso3166Alpha3);
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<CountryIndexResponse>
	> {
		return this.vueHttp.getCountryFilter();
	}

	protected getKeywordsFor(record: CountryIndexResponse): string[] {
		const keywords: string[] = [String(record.iso3166Alpha3)];

		const labelParts = this.getKeywordsFromPhrase(record.label);

		keywords.push(...labelParts);

		return keywords;
	}
}
