import {
	GuardianReconciliationBulkUpdateItem,
	GuardianReconciliationIndexResponse
} from 'RtModels';
import { GuardianReconciliationBulkUpdateGrid } from 'RtUi/app/rtVue/GuardianReconciliation/lib/grids/GuardianReconciliationBulkUpdateGrid';
import { GuardianReconciliationSpreadsheetParser } from 'RtUi/app/rtVue/GuardianReconciliation/lib/parsers/GuardianReconciliationSpreadSheetParser';
import { GuardianReconciliationBulkResource } from 'RtUi/app/rtVue/GuardianReconciliation/lib/resources/GuardianReconciliationBulkResource';
import { FormWizard, FormWizardStep } from 'RtUi/components/form/FormWizard';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtError } from 'RtUi/utils/errors/RtError';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { useToggle } from 'react-use';

type GuardianReconciliationUpdateItems = [
	GuardianReconciliationBulkUpdateItem[],
	GuardianReconciliationBulkUpdateItem[]
];

interface IGuardianReconciliationBulkUpdateFormProps {
	onSuccess?: () => void;
}

export const GuardianReconciliationBulkUpdateForm = ({
	onSuccess = () => {}
}: IGuardianReconciliationBulkUpdateFormProps): JSX.Element => {
	const [wizardStep, setWizardStep] = useState(1);
	const [isSamplesModalOpen, toggleIsSamplesModalOpen] = useToggle(false);
	const [error, setError] = useState<RtError>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [file, setFile] = useState<File>();
	const [processingFile, setProcessingFile] = useState<boolean>();
	const [requestItems, setRequestItems] = useState<
		GuardianReconciliationBulkUpdateItem[]
	>([]);
	const [guardianReconciliationItems, setGuardianReconciliationItems] =
		useState<GuardianReconciliationIndexResponse[]>();
	const resource = useMemo(() => new GuardianReconciliationBulkResource(), []);

	const [foundReconciliationItems, notFoundReconciliationItems] =
		useMemo(() => {
			return requestItems.reduce<GuardianReconciliationUpdateItems>(
				([foundItems, notFoundItems], currentItem) => {
					const foundItem = (guardianReconciliationItems || []).some(
						(guardianItem) => {
							const isSameStart = moment(guardianItem.startTs)
								.utc()
								.isSame(moment(currentItem.startTs).utc(), 'date');
							const isSameEnd = moment(guardianItem.endTs)
								.utc()
								.isSame(moment(currentItem.endTs).utc(), 'date');

							return (
								guardianItem.carrierBanNumber ===
									currentItem.carrierBanNumber &&
								isSameStart &&
								isSameEnd
							);
						}
					);

					if (foundItem) {
						foundItems.push(currentItem);

						return [foundItems, notFoundItems];
					}

					notFoundItems.push(currentItem);

					return [foundItems, notFoundItems];
				},
				[[], []]
			);
		}, [guardianReconciliationItems, requestItems]);

	const processFile = async (newFile: File) => {
		setError(undefined);
		setProcessingFile(true);

		const fileUtils = new FileUtils();
		const spreadsheet = await fileUtils.fileToSpreadsheet(newFile);

		const parser = new GuardianReconciliationSpreadsheetParser(spreadsheet);
		parser.on('error', (eventMessage) => {
			setError(new RtError({ message: eventMessage }));
		});
		const parsedGuardianReconciliationItems =
			parser.parseGuardianReconciliationIndexRecords();

		setFile(newFile);
		setProcessingFile(false);
		setRequestItems(parsedGuardianReconciliationItems);
	};

	const onSubmitFileHandler = async () => {
		setGuardianReconciliationItems(undefined);
		setError(undefined);
		setIsSubmitting(true);
		try {
			const items = requestItems.map(
				({ carrierBanNumber, startTs, endTs }) => ({
					carrierBanNumber,
					startTs,
					endTs
				})
			);

			const itemsToUpdate = await resource.getAll({ items });
			if (!itemsToUpdate.length) {
				setError(
					new RtError({ message: 'Not found any Reconciliation to update' })
				);
				return;
			}

			setGuardianReconciliationItems(itemsToUpdate);
			setWizardStep(2);
		} catch (e: any) {
			setError(e);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onSubmitHandler = async () => {
		setError(undefined);
		setIsSubmitting(true);

		try {
			await resource.update({ items: foundReconciliationItems });
			onSuccess();
		} catch (error: any) {
			setError(new RtError(error));
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Modal
				show={isSamplesModalOpen}
				onHide={toggleIsSamplesModalOpen}
				size="xl"
			>
				<Modal.Body className="bg-light rounded">
					<article className="d-flex justify-content-end mb-2">
						<Button
							variant="white-alt"
							onClick={() => toggleIsSamplesModalOpen(false)}
						>
							<i className="fas fa-times fa-fw fa-lg" />
						</Button>
					</article>
					<Table size="sm" bordered className="bg-white" responsive>
						<thead>
							<tr>
								<th>Vendor Ban Number</th>
								<th>Invoice Start Date</th>
								<th>Invoice End Date</th>
								<th>Invoice Calls</th>
								<th>Invoice MOU</th>
								<th>Invoice Cost</th>
								<th>Expected Calls</th>
								<th>Expected MOU</th>
								<th>Expected Cost</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="text-monospace">123456789</td>
								<td className="text-monospace">2023-04-01</td>
								<td className="text-monospace">2023-04-21</td>
								<td className="text-monospace">2</td>
								<td className="text-monospace">3.2</td>
								<td className="text-monospace">20.4</td>
								<td className="text-monospace">5</td>
								<td className="text-monospace">12.4</td>
								<td className="text-monospace">50.3</td>
							</tr>
							<tr>
								<td className="text-monospace">123467789</td>
								<td className="text-monospace">2022-06-01</td>
								<td className="text-monospace">2023-06-11</td>
								<td className="text-monospace">5</td>
								<td className="text-monospace">7.2</td>
								<td className="text-monospace">10.4</td>
								<td className="text-monospace">2</td>
								<td className="text-monospace">2.4</td>
								<td className="text-monospace">0.3</td>
							</tr>
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
			<FormWizard
				currentStep={wizardStep}
				isSubmitting={isSubmitting}
				error={error}
				onChange={(newStep) =>
					setWizardStep((currentState) => Math.min(currentState, newStep))
				}
				disableContinueAction={!file || Boolean(error)}
			>
				<FormWizardStep
					step={1}
					header="Upload File"
					onSubmit={onSubmitFileHandler}
				>
					<div className="text-end">
						<Button variant="link" onClick={toggleIsSamplesModalOpen}>
							<span className="text-muted">
								<i className="fas fa-fw fa-info-circle" />
								&nbsp;
								<u>Example File</u>
							</span>
						</Button>
					</div>
					<DragAndDropFileUploader
						required
						processing={processingFile}
						accept={FileUtils.AcceptTypes.Spreadsheet}
						onDrop={processFile}
						value={file}
					/>
				</FormWizardStep>
				<FormWizardStep
					step={2}
					header="Validate Records"
					onSubmit={onSubmitHandler}
					submitText="Submit"
				>
					{Boolean(foundReconciliationItems.length) && (
						<>
							<h6 className="mb-3">Records to Update</h6>
							<GuardianReconciliationBulkUpdateGrid
								items={foundReconciliationItems}
								pageName="rtVue-guardianReconciliation"
							/>
						</>
					)}
					{Boolean(notFoundReconciliationItems.length) && (
						<>
							<h6 className="mb-3 mt-3">Unmatched Records</h6>
							<GuardianReconciliationBulkUpdateGrid
								items={notFoundReconciliationItems}
								pageName="rtVue-guardianReconciliation"
							/>
						</>
					)}
				</FormWizardStep>
			</FormWizard>
		</>
	);
};
