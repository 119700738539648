import { PortingStatusIndexResponse } from 'RtModels';
import { PortingStatusResource } from 'RtUi/app/rtCommon/ServiceNumbers/lib/resources/PortingStatusResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface PortingStatusDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		PortingStatusIndexResponse,
		T
	> {}

export const PortingStatusDataGridCache =
	new ResourceCacheMap<PortingStatusIndexResponse>(
		new PortingStatusResource(),
		'portingStatusId',
		'label'
	);

export const PortingStatusDataGridColumn = <T = any,>(
	config: PortingStatusDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: PortingStatusDataGridCache,
		...config
	});
};
