import moment from 'moment';
import { useState, FC, useEffect } from 'react';
import * as pluralize from 'pluralize';
import { Alert, Card, Button } from 'react-bootstrap';
import {
	RateLoadErrorIndexResponse,
	RateLoadProfileResponse,
	RateLoadStatus,
	RateLoadStep,
	UserIndexResponse
} from 'RtModels';
import { RateLoadApprovalSummary } from 'RtUi/app/AccountManagement/RateManagement/libs/components/RateLoadApprovalSummary';
import * as RateLoadUtils from 'RtUi/app/AccountManagement/RateManagement/libs/utils/RateLoadUtils';
import { UserHttp } from 'RtUi/app/user/lib/Http/UserHttp';
import { timestampToReadable } from 'RtUi/utils/maps';
import { RateLoadResource } from 'RtUi/app/AccountManagement/RateManagement/libs/resources/RateLoadResource';
import { Aside } from 'RtUi/components/ui/Aside';
import { RateLoadErrorReconcile } from 'RtUi/app/AccountManagement/RateManagement/libs/components/RateLoadErrorReconcile';

type RateManagementStatusProps = {
	profile: RateLoadProfileResponse;
	onDelete: () => void;
	onApprove: () => void;
	onApproveExceptions: () => void;
};

export const RateManagementStatus: FC<
	React.PropsWithChildren<RateManagementStatusProps>
> = ({ profile, onDelete, onApprove, onApproveExceptions }) => {
	const [showErrorResolution, setShowErrorResolution] = useState(false);
	const [approvedBy, setApprovedBy] = useState<UserIndexResponse | undefined>();
	const [errors, setErrors] = useState<RateLoadErrorIndexResponse[]>();
	const isRunningStatus = profile.rateLoadStatusId === RateLoadStatus.Running;
	const rateLoadStepInfo = RateLoadUtils.RateLoadStepInfoMap.get(
		profile.rateLoadStepId
	);
	let cardHeader = RateLoadUtils.GetRateLoadStatus(
		profile.rateLoadStepId,
		profile.rateLoadStatusId
	);
	let cardHeaderIcon: string | undefined;
	let alertStyle: string | undefined;
	let errorAlertStyle = 'warning';
	let alertText = profile.summary;
	const cardHeaderClasses = ['d-flex justify-content-between'];
	const completedOk = RateLoadUtils.IsCompletedOk(profile.rateLoadStatusId);
	const completedWithExceptions =
		profile.rateLoadStatusId === RateLoadStatus.CompletedWithExceptions;
	const completedBad = RateLoadUtils.IsCompletedBad(profile.rateLoadStatusId);
	const isOnStep = (step: RateLoadStep) => profile.rateLoadStepId === step;
	const unapprovedErrors =
		errors?.filter((err) => err.rateLoadApprovalTypeId < 2) ?? [];
	const unapprovedErrorsLength = unapprovedErrors.length ?? 0;

	if (completedOk) {
		alertStyle = 'success';
		cardHeaderIcon = 'check-circle';
	} else if (completedBad) {
		alertStyle = 'warning';
		cardHeaderIcon = 'exclamation-triangle';

		if (profile.rateLoadStatusId === RateLoadStatus.CompletedError) {
			alertStyle = 'danger';
			errorAlertStyle = 'danger';
		}
	}

	if (isOnStep(RateLoadStep.RateApproval)) {
		if (completedOk) {
			alertText = 'Rates will be loaded soon. No further action is required.';
			alertStyle = 'info';
		}
	} else if (isOnStep(RateLoadStep.RateAndCodeAnalysis)) {
		if (completedOk) {
			alertStyle = 'info';
			cardHeaderIcon = 'exclamation-circle';
			alertText = 'Review the summary and rates below before approving.';
		} else if (completedWithExceptions) {
			alertStyle = 'info';
			cardHeader = 'Action Required';
			alertText =
				'Exceptions were found during the analysis. Be sure to review the rates before selecting an option below to resolve.';
		}
	} else if (isOnStep(RateLoadStep.Importing)) {
		if (completedOk) {
			alertStyle = 'info';
			alertText = 'Rates to be analyzed soon. Currently no action is required.';
		} else if (completedBad) {
			alertText =
				'Errors were found during the import process. Review the errors below to troubleshoot further. ';

			if (unapprovedErrorsLength >= 20) {
				alertText +=
					'Please note that there may be more errors in your sheet than listed below.';
			}
		}
	}

	if (alertStyle) {
		cardHeaderClasses.push(`bg-${alertStyle}`, 'text-white');
	}

	const updateRateLoadErrors = () => {
		const rateLoadResource = new RateLoadResource();
		rateLoadResource.getErrors(profile.rateLoadId).then(setErrors);
	};

	useEffect(() => {
		updateRateLoadErrors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile.rateLoadStatusId, profile.rateLoadStepId]);

	useEffect(() => {
		if (profile.approvedUserId) {
			const userHttp = new UserHttp();

			userHttp.getUser(profile.approvedUserId).then(setApprovedBy);
		}
	}, [profile.approvedUserId]);

	return (
		<>
			<Card className="mb-3">
				<Card.Header className={cardHeaderClasses.join(' ')}>
					<span>
						{cardHeaderIcon && (
							<i className={`fas fa-fw fa-${cardHeaderIcon} me-2`} />
						)}
						{cardHeader}
						{isRunningStatus && (
							<i className="fas fa-fw fa-cog text-info fa-spin ms-2" />
						)}
					</span>
					<span>Step {rateLoadStepInfo?.step ?? '?'} of 8</span>
				</Card.Header>
				{alertText && (
					<Alert
						variant={alertStyle}
						className="d-flex justify-content-start rounded-0 mb-0"
					>
						{alertText}
					</Alert>
				)}
				{unapprovedErrors.length > 0 && (
					<Alert variant={errorAlertStyle} className="mb-0 rounded-0">
						<h6>
							The following {unapprovedErrorsLength.toLocaleString()}{' '}
							{pluralize('notice', unapprovedErrorsLength)} must be reconciled:
						</h6>
						<ul
							className="mb-0"
							// 21 is the size of every li -- show 10 at a time
							style={{ maxHeight: 21 * 10, overflowY: 'auto' }}
						>
							{unapprovedErrors.map((unapprovedError, index) => (
								<li key={unapprovedError.summary + index}>
									<section className="d-flex justify-content-start">
										<span>{unapprovedError.summary}</span>
										{unapprovedError.qty > 1 && (
											<span className="ms-1">
												({unapprovedError.qty.toLocaleString()} found)
											</span>
										)}
									</section>
								</li>
							))}
						</ul>
					</Alert>
				)}
				{profile.rateLoadStepId >= RateLoadStep.RateApproval && (
					<Card.Footer className="text-end">
						<small className="text-muted">
							Approved by&nbsp;
							{approvedBy?.email ?? ''} at&nbsp;
							{timestampToReadable(moment(profile.createdTs))}
						</small>
					</Card.Footer>
				)}
				{profile.rateLoadStepId === RateLoadStep.RateAndCodeAnalysis && (
					<>
						{profile.rateLoadStatusId === RateLoadStatus.CompletedOk && (
							<Card.Body>
								<RateLoadApprovalSummary profile={profile} />
								<Button variant="light" className="me-2" onClick={onApprove}>
									<i className="fas fa-fw fa-cloud-upload-alt text-success" />
									&nbsp;&nbsp;Approve and Load Rates
								</Button>
								<Button variant="light" onClick={onDelete}>
									<i className="fas fa-fw fa-trash-alt text-danger" />
									&nbsp;&nbsp;Delete Rates
								</Button>
							</Card.Body>
						)}
						{profile.rateLoadStatusId ===
							RateLoadStatus.CompletedWithExceptions && (
							<Card.Body>
								<section>
									{errors?.every((err) => err.isApprovable) && (
										<Button
											variant="light"
											className="me-2"
											onClick={() => setShowErrorResolution(true)}
										>
											<i className="fas fa-fw fa-check-circle text-success" />
											&nbsp;Reconcile and Approve
										</Button>
									)}
									<Button variant="light" onClick={onDelete}>
										<i className="fas fa-fw fa-times-circle text-danger" />
										&nbsp;Delete
									</Button>
								</section>
							</Card.Body>
						)}
					</>
				)}
				{profile.rateLoadStatusId === RateLoadStatus.CompletedError && (
					<Card.Body>
						<section>
							<Button variant="light" onClick={onDelete}>
								<i className="fas fa-fw fa-times text-danger" />
								&nbsp;Delete Rates
							</Button>
						</section>
					</Card.Body>
				)}
			</Card>
			<Aside
				disabledBody
				isOpen={showErrorResolution}
				onClickOutside={() => setShowErrorResolution(false)}
			>
				<Aside.Header
					onClose={() => setShowErrorResolution(false)}
					header={<h6 className="mb-0">Reconcile Errors</h6>}
				/>
				<Alert variant="info" className="mb-0 rounded-0">
					The RouteTrust Rate Loading system is dynamic and powerful, importing
					rates from multiple file formats and layouts automatically. This
					enables users to upload almost any Rate Sheet - without editing or
					formatting it first (if you have a common format that does not work,
					let us know). With this simplicity comes the need for some minor
					validation. Please audit and approve the detected warnings below.
				</Alert>
				<RateLoadErrorReconcile
					key={String(showErrorResolution)}
					rateLoadId={profile.rateLoadId}
					errors={errors ?? []}
					onCancel={() => setShowErrorResolution(false)}
					onSuccess={() => {
						setShowErrorResolution(false);
						updateRateLoadErrors();
						onApproveExceptions();
					}}
				/>
			</Aside>
		</>
	);
};
