import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RtAdminRouter } from '../rtAdmin.router';
import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	ManagedRespOrgIndexResponse,
	ManagedRespOrgProfileResponse
} from 'RtModels';

interface IManagedRespOrgContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Credentials: TabbedLayoutTabProps;
}

export const ManagedRespOrgContainerTabs: IManagedRespOrgContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Credentials: {
		header: 'Credentials'
	}
};

export class ManagedRespOrgRouterClass extends RtAdminRouter<
	ManagedRespOrgIndexResponse,
	ManagedRespOrgProfileResponse,
	IManagedRespOrgContainerTabs
> {
	constructor() {
		super(
			'Managed RespOrg',
			'managedRespOrgs',
			'respOrgId',
			ManagedRespOrgContainerTabs
		);

		this.setPermissionsFromApiRoute(RtAdmApiRoutes.ManagedRespOrgs);
	}

	public getProfileLabel(profile: ManagedRespOrgProfileResponse) {
		return profile.respOrgId;
	}
}

export const ManagedRespOrgRouter = new ManagedRespOrgRouterClass();
