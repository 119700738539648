import { CdrExportDestinationIndexResponse } from 'RtModels';
import { CdrAutomatedTransferForm } from 'RtUi/app/rtVue/CdrRecurringExport/lib/forms/CdrAutomatedTransferForm';
import {
	deleteCdrDestinationTypeSelect,
	useGetCdrExportDestinations
} from 'RtUi/app/rtVue/CdrRecurringExport/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCdrExportControlDestinationColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/cdrExportControl';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMutation } from 'react-query';

interface ICdrExportDestinationsGridProps {
	cdrExportControlId: number;
}

export const CdrExportDestinationsGrid = ({
	cdrExportControlId
}: ICdrExportDestinationsGridProps) => {
	const [isAsideOpen, setIsAsideOpen] = useState<boolean>(false);
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetCdrExportDestinations({
		cdrExportControlId
	});

	const { mutateAsync } = useMutation(deleteCdrDestinationTypeSelect);

	const onDelete = useCallback(
		async (row: CdrExportDestinationIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				String(row.cdrExportDestinationId)
			);

			if (!confirm) {
				return;
			}

			await mutateAsync({
				cdrExportDestinationId: row.cdrExportDestinationId,
				cdrExportId: cdrExportControlId
			});

			refetch();
		},
		[cdrExportControlId, mutateAsync, refetch]
	);

	const columns = useMemo(
		() => getCdrExportControlDestinationColumns(onDelete),
		[onDelete]
	);
	const [selectedRecord, setSelectedRecord] =
		useState<CdrExportDestinationIndexResponse>();

	const onCancelTransferForm = () => {
		setIsAsideOpen(false);
		setSelectedRecord(undefined);
	};

	return (
		<>
			<DataGrid<CdrExportDestinationIndexResponse>
				data={data?.data}
				columns={columns}
				onRowClick={(record) => {
					setIsAsideOpen(true);
					setSelectedRecord(record);
				}}
				headerAction={() => {
					return (
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id={`addBtn-tooltip`} {...props}>
									Create Cdr Export Destination
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									onClick={() => setIsAsideOpen(true)}
									variant="white"
									id="addBtn"
								>
									<i className="fas fa-fw fa-plus fa-lg" />
								</Button>
							)}
						</OverlayTrigger>
					);
				}}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtVue_cdrExportControlDestination'}
			/>
			<Aside isOpen={isAsideOpen} disabledBody>
				<Aside.Header
					warning
					header="Cdr Export Destination"
					onClose={onCancelTransferForm}
				/>
				<CdrAutomatedTransferForm
					profile={selectedRecord}
					cdrExportControlId={cdrExportControlId}
					onSubmit={() => {
						setIsAsideOpen(false);
						setSelectedRecord(undefined);
						refetch();
					}}
					onCancel={onCancelTransferForm}
				/>
			</Aside>
		</>
	);
};
