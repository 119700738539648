import { clone } from 'lodash-es';
import * as moment from 'moment-timezone';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Permissions } from 'RtExports/routes';
import {
	GuardianUserQueryCreateRequest,
	GuardianUserQueryDeleteRequest,
	GuardianUserQueryIndexResponse,
	GuardianUserQueryUpdateRequest,
	PartitionIndexResponse,
	RtVueIndexRequest,
	ScheduleRepeatIntervalType
} from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { GuardianUserQueryResource } from 'RtUi/app/rtVue/common/lib/resources/GuardianUserQueryResource';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { UserActions } from 'RtUi/state/actions/user';

interface IGuardianUserQueryEditorProps {
	currentQueryParameters: RtVueIndexRequest;
	editMode: GuardianUserQueryIndexResponse;
	onChange: (newUserQuery: GuardianUserQueryIndexResponse) => void;
	onCancel: () => void;
	onDelete: () => void;
	partition?: PartitionIndexResponse | undefined;
}

interface IGuardianUserQueryEditorState {
	userQuery: GuardianUserQueryIndexResponse;
	displayMode: boolean;
	isSubmitting?: boolean;
	error?: any;
	//form
	scheduledIntervalTypeOption?: ISimpleSelectFormControlOption<ScheduleRepeatIntervalType>;
	scheduledStartDate?: moment.Moment;
}

export class GuardianUserQueryEditor extends React.Component<
	IGuardianUserQueryEditorProps,
	IGuardianUserQueryEditorState
> {
	constructor(props: IGuardianUserQueryEditorProps) {
		super(props);

		const userQuery = clone(this.props.editMode);
		const scheduledStartDate = userQuery.scheduleStartDate
			? moment(userQuery.scheduleStartDate)
			: undefined;

		this.state = {
			displayMode: false,
			isSubmitting: false,
			error: undefined,
			userQuery,
			scheduledStartDate
		};
	}

	public updateUserQuery<K extends keyof GuardianUserQueryIndexResponse>(
		key: K,
		value: GuardianUserQueryIndexResponse[K]
	) {
		const { userQuery } = this.state;

		userQuery[key] = value;

		this.setState({ userQuery });
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const rtVueHttp = new RtVueHttp();
		const { userQuery } = this.state;
		const { queryDescription = '' } = userQuery;

		if (this.props.partition) {
			userQuery.queryParameters.customerTestingOverride =
				this.props.partition.prefix;
		}

		this.setState({ isSubmitting: true, error: undefined });

		try {
			let newUserQuery: GuardianUserQueryIndexResponse;
			const isRtxAdmin = UserActions.has(Permissions.RtxAdministrator);

			if (!isRtxAdmin && userQuery.queryType === 'global') {
				const createReq: GuardianUserQueryCreateRequest = {
					reportId: userQuery.reportId,
					queryParameters: userQuery.queryParameters,
					queryDescription,
					isDefaultView: userQuery.isDefaultView,
					isEmail: userQuery.isEmail,
					isActive: userQuery.isActive,
					label: userQuery.label,
					summary: userQuery.summary,
					scheduleStartDate: userQuery.scheduleStartDate,
					scheduleRepeatIntervalTypeId: userQuery.scheduleRepeatIntervalTypeId,
					isGlobal: 0,
					globalGuardianUserQueryId: userQuery.guardianUserQueryId,
					queryType: userQuery.queryType,
					isFavorite: userQuery.isFavorite
				};

				newUserQuery =
					await rtVueHttp.createGuardianUserQueryProfile(createReq);
			} else {
				const updateReq: GuardianUserQueryUpdateRequest = {
					...userQuery,
					queryDescription,
					queryParameters: this.props.currentQueryParameters
				};

				newUserQuery = await rtVueHttp.updateGuardianUserQueryProfile(
					userQuery.guardianUserQueryId,
					updateReq
				);
			}

			this.setState({ isSubmitting: false }, () => {
				this.props.onChange(newUserQuery);
			});
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	}

	public async onDelete() {
		const rtVueHttp = new RtVueHttp();
		const { userQuery } = this.state;

		const confirmation = await Confirmation.createDelete(userQuery.label || '');

		if (!confirmation) {
			return;
		}

		try {
			const deleteParams: GuardianUserQueryDeleteRequest = {
				isGlobal: userQuery.isGlobal
			};

			await rtVueHttp.deleteGuardianUserQueryProfile(
				userQuery.guardianUserQueryId,
				deleteParams
			);
		} catch (error) {
			console.error(error);
		}

		this.props.onDelete();
	}

	public updatesScheduledStartDate(
		scheduledStartDate: moment.Moment | undefined
	) {
		const { userQuery } = this.state;

		userQuery.scheduleStartDate = scheduledStartDate
			? scheduledStartDate.toDate()
			: undefined;

		this.setState({ scheduledStartDate, userQuery });
	}

	public updateScheduledIntervalTypeOption(
		scheduledIntervalTypeOption?: ISimpleSelectFormControlOption<ScheduleRepeatIntervalType>
	) {
		const { userQuery } = this.state;

		userQuery.scheduleRepeatIntervalTypeId = scheduledIntervalTypeOption
			? scheduledIntervalTypeOption.value
			: undefined;

		this.setState({ scheduledIntervalTypeOption, userQuery });
	}

	public render() {
		const { userQuery } = this.state;
		const isRtxAdmin = UserActions.has(Permissions.RtxAdministrator);
		const showScheduleControls =
			userQuery.queryType === 'user' || userQuery.queryType === 'shared';

		const currentUser = UserActions.getUserId();
		const displayMode = currentUser !== userQuery.userId;

		// why?
		// if (userQuery.isGlobal === 1) {
		// 	//only non-admins can schedule globals
		// 	showScheduleControls = !isRtxAdmin;
		// }

		return (
			<RtUiForm
				disableBody
				disablePadding
				onCancel={this.props.onCancel}
				onSubmit={(evt) => this.onSubmit(evt)}
				hideButtons={displayMode}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onChange={(displayMode) => this.setState({ displayMode })}
			>
				<header className="d-flex justify-content-end">
					{!(userQuery.queryType === 'global' && isRtxAdmin) &&
						!displayMode && (
							<Button
								type="button"
								variant="link"
								className="text-danger"
								onClick={() => this.onDelete()}
							>
								Delete Saved Query
							</Button>
						)}
				</header>
				<InputFormControl
					label="Label"
					disabled={
						!isRtxAdmin &&
						GuardianUserQueryResource.IsQueryGlobalOrReferenceGlobal(userQuery)
					}
					displayMode={displayMode}
					onChange={(label) => this.updateUserQuery('label', label)}
					value={userQuery.label}
				/>
				<InputFormControl
					label="Description"
					disabled={
						!isRtxAdmin &&
						GuardianUserQueryResource.IsQueryGlobalOrReferenceGlobal(userQuery)
					}
					onChange={(queryDescription) =>
						this.updateUserQuery('queryDescription', queryDescription)
					}
					displayMode={displayMode}
					value={userQuery?.queryDescription || ''}
				/>
				<IsActiveRadioFormControl
					hideBothOption
					onChange={(isActive) => this.updateUserQuery('isActive', isActive)}
					value={userQuery.isActive}
					displayMode={displayMode}
				/>
				<BooleanRadioFormControl
					label="Default View"
					onChange={(isDefaultView) =>
						this.updateUserQuery('isDefaultView', isDefaultView)
					}
					displayMode={displayMode}
					value={userQuery.isDefaultView}
				/>
				{showScheduleControls && (
					<>
						<BooleanRadioFormControl
							label="Schedule Email Report"
							onChange={(isEmail) => this.updateUserQuery('isEmail', isEmail)}
							displayMode={displayMode}
							value={userQuery.isEmail}
						/>
						{userQuery.isEmail === 1 && (
							<>
								<DatetimeFormControl
									label="Scheduled Start Date"
									required
									appendToBody
									disableNowPreset
									disablePresets
									minDate={moment()}
									onChangeWithIsNow={(scheduledStartDate) =>
										this.updatesScheduledStartDate(scheduledStartDate)
									}
									displayMode={displayMode}
									value={this.state.scheduledStartDate}
								/>
								<SimpleSelectFormControl<ScheduleRepeatIntervalType>
									required
									label="Scheduled Interval"
									onChange={(scheduledIntervalTypeOption) =>
										this.updateScheduledIntervalTypeOption(
											scheduledIntervalTypeOption
										)
									}
									displayMode={displayMode}
									value={this.state.scheduledIntervalTypeOption}
									initialOptionId={
										userQuery.scheduleRepeatIntervalTypeId
											? userQuery.scheduleRepeatIntervalTypeId.toString()
											: undefined
									}
									options={[
										{
											value: ScheduleRepeatIntervalType.Day,
											label: 'Day'
										},
										{
											value: ScheduleRepeatIntervalType.Week,
											label: 'Week'
										},
										{
											value: ScheduleRepeatIntervalType.Month,
											label: 'Month'
										}
									]}
								/>
							</>
						)}
					</>
				)}
			</RtUiForm>
		);
	}
}
