/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum AosNodeType {
    AreaCode = 'AC',
    Lata = 'LT',
    Network = 'NT',
    State = 'ST',
}