import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

class LcoSummaryRouterClass extends RtVueReportRouter<void, void, {}> {
	constructor() {
		super('LCO Export', 'lcoExport', undefined, {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.ViewLcoSummary);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(): string {
		return '';
	}
}

export const LcoSummaryRouter = new LcoSummaryRouterClass();
