import { SipCdrSearchIndexResponse } from 'RtModels';
import { CdrSearchReportRouter } from 'RtUi/app/rtSip/CdrSearchReport/CdrSearchReport.router';
import { useGetCdrSearchReport } from 'RtUi/app/rtSip/CdrSearchReport/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCdrSearchReportColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/cdrSearch';
import { useMemo } from 'react';

interface ICdrSearchReportGridProps {
	autoFocusFilter?: boolean;
	resourceParams?: any;
}

export const CdrSearchReportGrid = ({
	resourceParams
}: ICdrSearchReportGridProps) => {
	const { data, isFetching: isLoading } = useGetCdrSearchReport(resourceParams);
	const columns = useMemo(() => getCdrSearchReportColumns(), []);

	return (
		<DataGrid<SipCdrSearchIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_cdrSearchReport'}
			router={CdrSearchReportRouter}
		/>
	);
};
