import {
	IInputFormControlProps,
	InputFormControl
} from 'RtUi/components/form/InputFormControl';

export const PortInputFormControl = (
	props: IInputFormControlProps
): JSX.Element => {
	return (
		<InputFormControl
			{...props}
			type="number"
			max={65535}
			min={0}
			disableAutoFormat
		/>
	);
};
