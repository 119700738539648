/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum MarginSummaryColumns {
    CostAccountId = 'costAccountId',
    CostSubscriptionId = 'costSubscriptionId',
    RevenueAccountId = 'revenueAccountId',
    RevenueSubscriptionId = 'revenueSubscriptionId',
    CountryIsoId = 'countryIsoId',
    RateCenterId = 'rateCenterId',
}