import { SpreadsheetParser } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParser';
import { SpreadsheetParserColumn } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParserColumn';
import { JobTaskRecord } from 'RtModels';

/**
 * Job Task Spreadsheet Parser
 */
export class JobTaskSpreadsheetParser extends SpreadsheetParser {
	public ReferenceColumn = new SpreadsheetParserColumn('Reference', [
		'reference',
		'number',
		'tfn',
		'DID'
	]).setIsRequired(true);
	public ErrorTextColumn = new SpreadsheetParserColumn('Error', [
		'error',
		'response'
	]);

	constructor(spreadsheet: string[][]) {
		super(spreadsheet);

		this.addParserColumn(this.ReferenceColumn, this.ErrorTextColumn);
	}

	/**
	 * Parse spreadsheet and return JobTaskRecords found
	 */
	public parseAndFindJobTaskRecords(): JobTaskRecord[] {
		const jobTasks: JobTaskRecord[] = [];

		const possibleHeaderMatches = this.findPossibleHeaderMatches();
		const possibleHeaderMatch = possibleHeaderMatches[0];

		const rows = this.parse(possibleHeaderMatch);
		const referenceColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.ReferenceColumn
		);
		const errorTextColumnIndex = this.getIndexFor(
			possibleHeaderMatch,
			this.ErrorTextColumn
		);

		for (const row of rows) {
			const referenceKey = row[referenceColumnIndex];
			const errorText = row[errorTextColumnIndex] ?? '';

			const jobTask: JobTaskRecord = { referenceKey, errorText };

			jobTasks.push(jobTask);
		}

		return jobTasks;
	}
}
