import { MonthlyTrendingIndexResponse } from 'RtModels';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import Select from 'react-select';

interface MonthlyTrendingReportsComponentProps {
	reportData: MonthlyTrendingIndexResponse[];
	graphType: any;
	setGraphType: (value: any) => void;
}

export const GraphsComponent = (
	props: MonthlyTrendingReportsComponentProps
) => {
	const { reportData, graphType, setGraphType } = props;

	const graphLabels = [
		`${dayjs().format('MMMM YYYY')}`,
		`${dayjs().subtract(1, 'months').format('MMMM YYYY')}`,
		`${dayjs().subtract(2, 'months').format('MMMM YYYY')}`,
		`${dayjs().subtract(3, 'months').format('MMMM YYYY')}`,
		`${dayjs().subtract(4, 'months').format('MMMM YYYY')}`,
		`${dayjs().subtract(5, 'months').format('MMMM YYYY')}`
	];

	const graphColors = [
		'#FF6633',
		'#FFB399',
		'#FF33FF',
		'#FFFF99',
		'#00B3E6',
		'#E6B333',
		'#3366E6',
		'#999966',
		'#99FF99',
		'#B34D4D',
		'#80B300',
		'#809900',
		'#E6B3B3',
		'#6680B3',
		'#66991A',
		'#FF99E6',
		'#CCFF1A',
		'#FF1A66',
		'#E6331A',
		'#33FFCC',
		'#66994D',
		'#B366CC',
		'#4D8000',
		'#B33300',
		'#CC80CC',
		'#66664D',
		'#991AFF',
		'#E666FF',
		'#4DB3FF',
		'#1AB399',
		'#E666B3',
		'#33991A',
		'#CC9999',
		'#B3B31A',
		'#00E680',
		'#4D8066',
		'#809980',
		'#E6FF80',
		'#1AFF33',
		'#999933',
		'#FF3380',
		'#CCCC00',
		'#66E64D',
		'#4D80CC',
		'#9900B3',
		'#E64D66',
		'#4DB380',
		'#FF4D4D',
		'#99E6E6',
		'#6666FF'
	];

	let month1Value = 0;
	let month2Value = 0;
	let month3Value = 0;
	let month4Value = 0;
	let month5Value = 0;
	let month6Value = 0;

	reportData.forEach((val) => {
		month1Value += val.month1Value;
		month2Value += val.month2Value;
		month3Value += val.month3Value;
		month4Value += val.month4Value;
		month5Value += val.month5Value;
		month6Value += val.month6Value;
	});

	const doughnutData = [
		month1Value,
		month2Value,
		month3Value,
		month4Value,
		month5Value,
		month6Value
	];

	return (
		<>
			<Col xl={6} style={{ marginBottom: '10px' }}>
				<Row style={{ marginBottom: '5px' }}>
					<span>Choose which graph to see</span>
				</Row>
				<Row style={{ maxWidth: '50%' }}>
					<Select
						onChange={setGraphType}
						value={graphType}
						options={[
							{ value: 'line', label: 'Line graph' },
							{ value: 'bar', label: 'Bar Graph' },
							{ value: 'pie', label: 'Pie Chart' }
						]}
					/>
				</Row>
			</Col>
			<div style={{ height: '350px', marginTop: '20px' }}>
				{graphType.value === 'line' && (
					<Line
						height={100}
						options={{
							maintainAspectRatio: false,
							responsive: true,
							plugins: {
								legend: {
									position: 'bottom' as const
								}
							}
						}}
						datasetIdKey={'graphData'}
						width={100}
						data={{
							labels: graphLabels,
							datasets: reportData.reverse().map(({ key, ...rest }, index) => {
								return {
									label: key,
									data: Object.values(rest),
									borderColor: graphColors[index],
									backgroundColor: graphColors[index]
								};
							})
						}}
					/>
				)}
				{graphType.value === 'bar' && (
					<Bar
						height={100}
						options={{
							maintainAspectRatio: false,
							responsive: true,
							plugins: {
								legend: {
									position: 'bottom' as const
								}
							}
						}}
						datasetIdKey={'graphData'}
						width={100}
						data={{
							labels: graphLabels,
							datasets: reportData.reverse().map(({ key, ...rest }, index) => {
								return {
									label: key,
									data: Object.values(rest),
									borderColor: graphColors[index],
									backgroundColor: graphColors[index]
								};
							})
						}}
					/>
				)}
				{graphType.value === 'pie' && (
					<Doughnut
						height={100}
						options={{
							maintainAspectRatio: false,
							responsive: true,
							plugins: {
								legend: {
									position: 'bottom' as const
								}
							}
						}}
						datasetIdKey={'graphData'}
						width={100}
						data={{
							labels: graphLabels,
							datasets: [
								{
									data: doughnutData
								}
							]
						}}
					/>
				)}
			</div>
		</>
	);
};
