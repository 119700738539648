/* cSpell:disable */
import { FC, useMemo } from 'react';
import {
	FullSummaryIndexRequest,
	FullSummaryTimeDisplay,
	SipFullSummaryIndexResponse
} from 'RtModels';
import { useGetFullSummaryReport } from 'RtUi/app/rtSip/FullSummaryReport/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getFullSummaryColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/fullSummary';

interface IFullSummaryReportGridProps {
	autoFocusFilter?: boolean;
	resourceParams: FullSummaryIndexRequest;
	pageName: string;
}

export const FullSummaryReportGrid: FC<IFullSummaryReportGridProps> = ({
	resourceParams,
	pageName
}) => {
	const { data, isFetching } = useGetFullSummaryReport(resourceParams);
	const columns = useMemo(
		() =>
			getFullSummaryColumns(
				resourceParams.timeslot === FullSummaryTimeDisplay.TimeQuarterly ||
					resourceParams.timeslot === FullSummaryTimeDisplay.TimeHourly
			),
		[resourceParams]
	);

	return (
		<DataGrid<SipFullSummaryIndexResponse>
			data={data}
			columns={columns}
			loading={isFetching}
			pageName={pageName}
			enableHiding={false}
		/>
	);
};

FullSummaryReportGrid.displayName = 'FullSummaryReportGrid';
