/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtAdmApiRoutes } from 'RtExports/routes';
import { ServerGroupMemberIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchServerGroupsMembers = async (): Promise<
	FullResponse<ServerGroupMemberIndexResponse[]>
> => {
	return handleGetRequest(RtAdmApiRoutes.ServerGroupMembers.Index, {
		includeFullResponse: true
	});
};

export const useGetServerGroupsMembers = () => {
	return useQuery<FullResponse<ServerGroupMemberIndexResponse[]>, Error>(
		'getServersGroupMembers',
		() => {
			return fetchServerGroupsMembers();
		}
	);
};
