import { useState } from 'react';
import { useInterval } from 'react-use';
import clsx from 'clsx';

interface IClock {
	className?: string;
}

export const Clock: React.FC<React.PropsWithChildren<IClock>> = (props) => {
	const { className = '' } = props;
	const [currentTime, setCurrentTime] = useState(new Date());

	useInterval(() => {
		const now = new Date();
		setCurrentTime(now);
	}, 1000);

	const utcTime = currentTime.toISOString();
	// time example 2020-12-17T10:00:00Z, next separating date and time
	const utcTimeFormatted = `${utcTime.substr(0, 10)} ${utcTime.substr(
		11,
		8
	)} UTC`;

	return <div className={clsx(className)}>{utcTimeFormatted}</div>;
};
