import {
	BillingEntityIndexResponse,
	BillingEntityProfileResponse
} from 'RtModels';
import { PartitionBillingEntityEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionBillingEntityEditor';
import { PartitionBillingEntityResource } from 'RtUi/app/rtAdmin/Partitions/lib/resource/PartitionBillingEntityResource';
import { useGetPartitionBilling } from 'RtUi/app/rtAdmin/Partitions/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getPartitionBillingColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/partitions';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { Fragment, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Item } from 'react-contexify';

interface IPartitionBillingEntityDataGridProps {
	partitionId: number;
	autoFocusFilter?: boolean;
}

export const PartitionBillingEntityDataGrid = ({
	partitionId
}: IPartitionBillingEntityDataGridProps) => {
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetPartitionBilling({ partitionId });
	const columns = useMemo(() => getPartitionBillingColumns(), []);

	const [asideIsOpen, setAsideOpen] = useState<boolean>(false);
	const [selectedRecord, setSelectedRecord] =
		useState<BillingEntityProfileResponse>();

	return (
		<>
			<DataGrid<BillingEntityIndexResponse>
				data={data?.data}
				loading={isLoading}
				pageName="rtAdmin-partitionBilling"
				disableExternalLinks
				menuLinks={(record) => (
					<Item
						onClick={async () => {
							const rec = await new PartitionBillingEntityResource().get(
								record.billingEntityId
							);
							setAsideOpen(true);
							setSelectedRecord(rec);
						}}
					>
						<span className="d-flex justify-content-start align-items-center">
							<i className="fas fa-fw fa-pen" />
							<span>Edit</span>
						</span>
					</Item>
				)}
				columns={columns}
			/>
			<Aside isOpen={asideIsOpen}>
				{selectedRecord && (
					<Fragment key={selectedRecord?.billingEntityId}>
						<header className="d-flex justify-content-between align-items-center mb-3">
							<h5 className="mb-0">
								<b>Entity - {selectedRecord.billingEntityId}</b>
							</h5>
							<Button
								type="button"
								variant="light"
								onClick={() => {
									setAsideOpen(false);
								}}
							>
								<i className="fas fa-fw fa-times" />
							</Button>
						</header>
						<PartitionBillingEntityEditor
							narrow={false}
							disablePadding
							partitionId={partitionId}
							editMode={selectedRecord}
							onCancel={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
							onUpdate={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
								refetch();
							}}
						/>
					</Fragment>
				)}
			</Aside>
			<Confirmation />
		</>
	);
};
