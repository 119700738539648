import { DataSources, SearchField } from 'RtModels';
import { RtVueIsdnFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueIsdnFilter';
import { RtVueRateCenterFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueRateCenterFilter';
import { RtVueSipFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueSipFilter';
import { RtVueSubscriptionFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueSubscriptionFilter';
import {
	IFilterSearchResultSection,
	RtVueFilter
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { RtVueAccountFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueAccountFilter';
import { RtVueCountryFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueCountryFilter';
import { RtVueCallTypeFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueCallTypeFilter';
import { RtVueTextFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueTextFilter';
import { RtVueEntityFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueEntityFilter';
import { RtVueRespOrgFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueRespOrgFilter';
import { RtVueTemplateFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueTemplateFilter';
import { RtVueOcnFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueOcnFilter';
import { RtVueCarrierCommonNameFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueCarrierCommonNameFilter';
import { RtVueOcnTypeFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueOcnTypeFilter';
import { UserActions } from 'RtUi/state/actions/user';
import { Permissions } from 'RtExports/routes';
import { RtVueDirectionFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueDirectionFilter';

export interface IFilterSearchResults {
	searchStr: string;
	filters: IFilterSearchResultSection[];
}

/**
 * FilterSearch uses CacheStrategies from FiltersHttp
 * to do an extensive search.
 *
 * Usage:
 * const vueFilterSearch = new RtVueFilterSearch();
 * vueFilterSearch.onResult((searchResponse) => <callback code here>);
 * vueFilterSearch.search(<searchStr>)
 *
 * onResult will be fired every time a result has been returned,
 * even returns that may be old. Be sure to filter based on the
 * searchStr returned in searchResponse.
 *
 * @singleton
 */
export class RtVueFilterSearch {
	/**
	 * Singleton method
	 */
	public static getInstance() {
		if (!RtVueFilterSearch.instance) {
			RtVueFilterSearch.instance = new RtVueFilterSearch();
		}

		return RtVueFilterSearch.instance;
	}

	/**
	 * Start filters initialization
	 */
	public static async primeCache() {
		if (!UserActions.has(Permissions.VueOperator)) {
			return;
		}

		const initializeDefers = RtVueFilterSearch.getInstance()
			.getFilters()
			.map((vueFilter) => vueFilter.initialize());

		await Promise.all([initializeDefers]);
	}

	private static instance: RtVueFilterSearch | null = null;

	//enforce singleton
	private constructor() {}

	/**
	 * Exhaustive search within Filters
	 */
	public async search(searchStr: string, searchFields: SearchField[]) {
		const availableSearchFilters = searchFields.filter(
			(sf) => !sf.hiddenFromUser
		);
		const filters = this.getFilters(availableSearchFilters);

		const searchResults = await Promise.all(
			filters.map((f) => f.search(availableSearchFilters, searchStr))
		);

		return { searchStr, filters: searchResults.flat(2) };
	}

	/**
	 * From a datasource, find it's label given it's value
	 * @param value the value from a DataFilterOperand
	 * @param dataSource the datasource that should have that value
	 */
	public async getLabelFromValue(dataSource: DataSources, value: string) {
		const filters = this.getFilters();
		const filterWithDataSource = filters.find(
			(filter) => filter.getDataSource() === dataSource
		);

		if (!filterWithDataSource) {
			return '';
		}

		return filterWithDataSource.getLabelFromValue(value);
	}

	/**
	 * Get a list of filters to run searches against
	 * Also used for figuring out labels.
	 *
	 * Any and all filters added to this list will show
	 * up in the RtVue report Filters Search
	 */
	public getFilters(searchFields?: SearchField[]) {
		let filters: Array<RtVueFilter<any>> = [
			RtVueCallTypeFilter.getInstance(),
			RtVueCountryFilter.getInstance(),
			RtVueAccountFilter.getInstance(),
			RtVueIsdnFilter.getInstance(),
			RtVueSipFilter.getInstance(),
			RtVueRateCenterFilter.getInstance(),
			RtVueSubscriptionFilter.getInstance(),
			RtVueEntityFilter.getInstance(),
			RtVueRespOrgFilter.getInstance(),
			RtVueTemplateFilter.getInstance(),
			RtVueTextFilter.getInstance(),
			RtVueOcnFilter.getInstance(),
			RtVueCarrierCommonNameFilter.getInstance(),
			RtVueOcnTypeFilter.getInstance(),
			RtVueDirectionFilter.getInstance()
		];

		if (searchFields) {
			const enabledDataSources = new Set<DataSources>();

			for (const searchField of searchFields) {
				for (const dataSource of searchField.dataSources) {
					enabledDataSources.add(dataSource);
				}
			}

			filters = filters.filter((filter) =>
				enabledDataSources.has(filter.getDataSource())
			);
		}

		return filters;
	}
}
