import { RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { ActivePortsCountryIndexContainer } from 'RtUi/app/rtSip/ActivePortsCountry/ActivePortsCountry.index';
import { ActivePortsCountryRouter } from 'RtUi/app/rtSip/ActivePortsCountry/ActivePortsCountry.router';
import { ActivePortsDetailsIndexContainer } from 'RtUi/app/rtSip/ActivePortsDetails/ActivePortsDetails.index';
import { ActivePortsDetailsRouter } from 'RtUi/app/rtSip/ActivePortsDetails/ActivePortsDetails.router';
import { ActivePortsPartitionIndexContainer } from 'RtUi/app/rtSip/ActivePortsPartition/ActivePortsPartition.index';
import { ActivePortsPartitionRouter } from 'RtUi/app/rtSip/ActivePortsPartition/ActivePortsPartition.router';
import { ActivePortsSummaryIndexContainer } from 'RtUi/app/rtSip/ActivePortsSummary/ActivePortsSummary.index';
import { ActivePortsSummaryRouter } from 'RtUi/app/rtSip/ActivePortsSummary/ActivePortsSummary.router';
import { AsrSummaryIndexContainer } from 'RtUi/app/rtSip/AsrSummary/AsrSummary.index';
import { AsrSummaryRouter } from 'RtUi/app/rtSip/AsrSummary/AsrSummary.router';
import { BlockNumbersIndexContainer } from 'RtUi/app/rtSip/BlockNumbers/BlockNumbers.index';
import { BlockNumbersProfileContainer } from 'RtUi/app/rtSip/BlockNumbers/BlockNumbers.profile';
import { BlockNumbersRouter } from 'RtUi/app/rtSip/BlockNumbers/BlockNumbers.router';
import { CallSimulatorIndexContainer } from 'RtUi/app/rtSip/CallSimulator/CallSimulator.index';
import { CallSimulatorRouter } from 'RtUi/app/rtSip/CallSimulator/CallSimulator.router';
import { CdrExportIndexContainer } from 'RtUi/app/rtSip/CdrExports/CdrExport.index';
import { CdrSearchReportIndexContainer } from 'RtUi/app/rtSip/CdrSearchReport/CdrSearchReport.index';
import { CdrSearchReportProfileContainer } from 'RtUi/app/rtSip/CdrSearchReport/CdrSearchReport.profile';
import { CdrSearchReportRouter } from 'RtUi/app/rtSip/CdrSearchReport/CdrSearchReport.router';
import { ConnectionIndexContainer } from 'RtUi/app/rtSip/Connections/Connection.index';
import { ConnectionProfileContainer } from 'RtUi/app/rtSip/Connections/Connection.profile';
import { ConnectionRouter } from 'RtUi/app/rtSip/Connections/Connection.router';
import { DirectConnectIndexContainer } from 'RtUi/app/rtSip/DirectConnect/DirectConnect.index';
import { DirectConnectProfileContainer } from 'RtUi/app/rtSip/DirectConnect/DirectConnect.profile';
import { DirectConnectRouter } from 'RtUi/app/rtSip/DirectConnect/DirectConnect.router';
import { FixedRatingSummaryIndexContainer } from 'RtUi/app/rtSip/FixedRatingSummary/FixedRatingSummary.index';
import { FixedRatingSummaryRouter } from 'RtUi/app/rtSip/FixedRatingSummary/FixedRatingSummary.router';
import { FullSummaryReportIndexContainer } from 'RtUi/app/rtSip/FullSummaryReport/FullSummaryReport.index';
import { FullSummaryReportRouter } from 'RtUi/app/rtSip/FullSummaryReport/FullSummaryReport.router';
import { InvoicesIndexContainer } from 'RtUi/app/rtSip/Invoices/Invoices.index';
import { InvoicesProfileContainer } from 'RtUi/app/rtSip/Invoices/Invoices.profile';
import { InvoicesRouter } from 'RtUi/app/rtSip/Invoices/Invoices.router';
import { MarginSummaryReportIndexContainer } from 'RtUi/app/rtSip/MarginSummaryReport/MarginSummaryReport.index';
import { MarginSummaryReportRouter } from 'RtUi/app/rtSip/MarginSummaryReport/MarginSummaryReport.router';
import { NetworkEfficiencyIndexContainer } from 'RtUi/app/rtSip/NetworkEfficiency/NetworkEfficiency.index';
import { NetworkEfficiencyRouter } from 'RtUi/app/rtSip/NetworkEfficiency/NetworkEfficiency.router';
import { PromptSegmentIndexContainer } from 'RtUi/app/rtSip/PromptSegments/PromptSegment.index';
import { PromptSegmentProfileContainer } from 'RtUi/app/rtSip/PromptSegments/PromptSegment.profile';
import { PromptSegmentRouter } from 'RtUi/app/rtSip/PromptSegments/PromptSegment.router';
import { QosManagementIndexContainer } from 'RtUi/app/rtSip/QosManagement/QosManagement.index';
import { RouteDepthIndexContainer } from 'RtUi/app/rtSip/RouteDepth/RouteDepth.index';
import { RouteDepthRouter } from 'RtUi/app/rtSip/RouteDepth/RouteDepth.router';
import { RoutingRuleIndexContainer } from 'RtUi/app/rtSip/RoutingRules/RoutingRule.index';
import { RoutingRuleProfileContainer } from 'RtUi/app/rtSip/RoutingRules/RoutingRule.profile';
import { RoutingSummaryIndexContainer } from 'RtUi/app/rtSip/RoutingSummary/RoutingSummary.index';
import { SipTraceIndexContainer } from 'RtUi/app/rtSip/SipTrace/SipTrace.index';
import { TagAssignmentIndexContainer } from 'RtUi/app/rtSip/TagAssignment/TagAssignment.index';
import { TagAssignmentRouter } from 'RtUi/app/rtSip/TagAssignment/TagAssignment.router';
import { TagMatchingIndexContainer } from 'RtUi/app/rtSip/TagMatching/TagMatching.index';
import { TagMatchingRouter } from 'RtUi/app/rtSip/TagMatching/TagMatching.router';
import { TrackingGroupIndexContainer } from 'RtUi/app/rtSip/TrackingGroups/TrackingGroup.index';
import { TrackingGroupProfileContainer } from 'RtUi/app/rtSip/TrackingGroups/TrackingGroup.profile';
import { TransactionIndexContainer } from 'RtUi/app/rtSip/Transactions/Transaction.index';
import { TransactionProfileContainer } from 'RtUi/app/rtSip/Transactions/Transaction.profile';
import { TransactionRouter } from 'RtUi/app/rtSip/Transactions/Transaction.router';
import { TrendAnalysisIndexContainer } from 'RtUi/app/rtSip/TrendAnalysis/TrendAnalysis.index';
import { TrendAnalysisRouter } from 'RtUi/app/rtSip/TrendAnalysis/TrendAnalysis.router';
import { SipApplicationRouter } from './rtSip.router';
import { ScenariosContainer } from './Scenarios/Scenario.index';
import { ScenarioContainer } from './Scenarios/Scenario.profile';
import { ScenarioRouter } from './Scenarios/Scenario.router';
import { ServiceNumberIndexContainer } from './ServiceNumbers/ServiceNumber.index';
import { ServiceNumberProfileContainer } from './ServiceNumbers/ServiceNumber.profile';
import { ServiceNumberRouter } from './ServiceNumbers/ServiceNumber.router';
import { TrackingGroupRouter } from './TrackingGroups/TrackingGroup.router';

@RtUiModule({
	route: {
		path: `/${SipApplicationRouter.SectionKey}`,
		iconClass: 'fa-microphone',
		name: 'RT/SIP Softswitch',
		defaultOpen: false,
		doNotExpandChildren: true
	},
	controllers: [
		CdrExportIndexContainer,
		CdrSearchReportIndexContainer,
		CdrSearchReportProfileContainer,
		PromptSegmentProfileContainer,
		PromptSegmentIndexContainer,
		ScenariosContainer,
		ScenarioContainer,
		ServiceNumberIndexContainer,
		ServiceNumberProfileContainer,
		TrackingGroupIndexContainer,
		TrackingGroupProfileContainer,
		FullSummaryReportIndexContainer,
		ConnectionIndexContainer,
		ConnectionProfileContainer,
		InvoicesIndexContainer,
		InvoicesProfileContainer,
		RouteDepthIndexContainer,
		TagMatchingIndexContainer,
		TagAssignmentIndexContainer,
		FixedRatingSummaryIndexContainer,
		MarginSummaryReportIndexContainer,
		TrendAnalysisIndexContainer,
		BlockNumbersIndexContainer,
		BlockNumbersProfileContainer,
		CallSimulatorIndexContainer,
		DirectConnectIndexContainer,
		ActivePortsSummaryIndexContainer,
		ActivePortsCountryIndexContainer,
		ActivePortsDetailsIndexContainer,
		ActivePortsPartitionIndexContainer,
		SipTraceIndexContainer,
		RoutingSummaryIndexContainer,
		QosManagementIndexContainer,
		TransactionIndexContainer,
		TransactionProfileContainer,
		NetworkEfficiencyIndexContainer,
		RoutingRuleIndexContainer,
		RoutingRuleProfileContainer,
		DirectConnectProfileContainer,
		AsrSummaryIndexContainer
	],
	routers: [
		CdrSearchReportRouter,
		PromptSegmentRouter,
		ScenarioRouter,
		ServiceNumberRouter,
		TrackingGroupRouter,
		ConnectionRouter,
		FullSummaryReportRouter,
		InvoicesRouter,
		RouteDepthRouter,
		TagMatchingRouter,
		TagAssignmentRouter,
		FixedRatingSummaryRouter,
		MarginSummaryReportRouter,
		TrendAnalysisRouter,
		BlockNumbersRouter,
		CallSimulatorRouter,
		DirectConnectRouter,
		ActivePortsSummaryRouter,
		ActivePortsCountryRouter,
		ActivePortsDetailsRouter,
		ActivePortsPartitionRouter,
		TransactionRouter,
		NetworkEfficiencyRouter,
		AsrSummaryRouter
	]
})
export class RtSipModule {}
