import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	RoutingCacheTypes,
	TaskErrorIndexResponse,
	TaskIndexResponse,
	TaskProfileRequest,
	TaskProfileResponse,
	TaskTypeIndexResponse,
	TaskUpdateRequest
} from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export interface ITaskProfile extends TaskProfileResponse {
	hasNumbers: boolean;
	hasActivations: boolean;
	parsedParameters: ITaskProfileParameters;
}
export interface ITaskIndexResponse extends TaskIndexResponse {
	hasNumbers: boolean;
}

export interface ITaskProfileParameters {
	sourceTypeId?: RoutingCacheTypes;
	sourceKey?: number | string;
	sourceKeyParent?: number | string;
}
export type TaskErrorResource = ArrayResource<TaskErrorIndexResponse>;

export type TaskTypeResource = HttpResource<TaskTypeIndexResponse>;

export class TaskResource extends HttpResource<
	ITaskIndexResponse,
	ITaskProfile
> {
	public static GetTaskErrors(taskId: number) {
		const taskResource = new TaskResource();

		return taskResource.getErrorsFromTask(taskId);
	}

	protected static TaskTypesPromise: Promise<TaskTypeIndexResponse[]> | null =
		null;

	constructor() {
		super(Rt800ApiRoutes.Tasks);
	}

	public getTaskTypes() {
		if (TaskResource.TaskTypesPromise === null) {
			TaskResource.TaskTypesPromise = this.fetchWithRoute<
				TaskTypeIndexResponse[]
			>(Rt800ApiRoutes.TaskTypes.Index);
		}

		return TaskResource.TaskTypesPromise;
	}

	public getTaskTypeResource(): TaskTypeResource {
		const resource = new ArrayResource<TaskTypeIndexResponse>('taskTypeId');
		resource.setApiRouteForGetAll(Rt800ApiRoutes.TaskTypes.Index);
		return resource;
	}

	public update(taskId: string | number, request: TaskUpdateRequest) {
		const body = JSON.stringify(request);
		taskId = Number(taskId);
		const urlParams: TaskProfileRequest = { taskId };

		return this.fetchWithRoute<ITaskProfile>(Rt800ApiRoutes.Tasks.Update, {
			body,
			urlParams
		});
	}

	public getErrorsFromTask(taskId: number) {
		const urlParams: TaskProfileRequest = { taskId };

		return this.fetchWithRoute<TaskErrorIndexResponse[]>(
			Rt800ApiRoutes.TaskErrors.Index,
			{ urlParams }
		);
	}
}
