/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoboscoreIndexResponse = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        ani: {
            type: 'string',
        },
        customerAccountId: {
            type: 'number',
        },
        customerSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        customerExternalAccountId: {
            type: 'string',
        },
        customerExternalSubAccountId: {
            type: 'string',
        },
        vendorExternalAccountId: {
            type: 'string',
        },
        vendorExternalSubAccountId: {
            type: 'string',
        },
        ingressTrunkGroup: {
            type: 'string',
            isRequired: true,
        },
        ingressTrunkGroupLabel: {
            type: 'string',
        },
        vendorAccountId: {
            type: 'number',
        },
        vendorSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        egressTrunkGroup: {
            type: 'string',
            isRequired: true,
        },
        egressTrunkGroupLabel: {
            type: 'string',
        },
        honeypotCampaign: {
            type: 'string',
        },
        attempts: {
            type: 'number',
        },
        connects: {
            type: 'number',
            isRequired: true,
        },
        duration: {
            type: 'number',
            isRequired: true,
        },
        countAttestationA: {
            type: 'number',
            isRequired: true,
        },
        countAttestationB: {
            type: 'number',
            isRequired: true,
        },
        countAttestationC: {
            type: 'number',
            isRequired: true,
        },
        countAttestationF: {
            type: 'number',
            isRequired: true,
        },
        countNoAttestation: {
            type: 'number',
            isRequired: true,
        },
        hastoken: {
            type: 'number',
        },
        countUniqueAnis: {
            type: 'number',
        },
        countUniqueDialedNumbers: {
            type: 'number',
        },
        countInvalidDn: {
            type: 'number',
        },
        countDnc: {
            type: 'number',
        },
        countAniNotValid: {
            type: 'number',
        },
        isDestDetailSame: {
            type: 'number',
        },
        countAniDno: {
            type: 'number',
        },
        count404: {
            type: 'number',
        },
        mediaFraudIps: {
            type: 'number',
        },
        stirShakenSigners: {
            type: 'number',
        },
        totalReputationScore: {
            type: 'number',
        },
        countHoneyPot: {
            type: 'number',
        },
        totalRoboScore: {
            type: 'number',
        },
        totalFraudScore: {
            type: 'number',
        },
        totalAnomalyScore: {
            type: 'number',
        },
        roboscore: {
            type: 'number',
        },
        reputationscore: {
            type: 'number',
        },
        ingressState: {
            type: 'string',
        },
        ingressOcn: {
            type: 'string',
        },
        ingressOcnType: {
            type: 'string',
        },
        commonName: {
            type: 'string',
        },
    },
};