import { Rt800ApiRoutes } from 'RtExports/routes';
import { LcrMinuteIndexRequest, RespOrgIndexResponse } from 'RtModels';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { MinutesRouter } from 'RtUi/app/rtLco/Minutes/Minutes.router';
import { AddTrafficProfile } from 'RtUi/app/rtLco/Minutes/lib/components/AddTrafficProfile';
import { LcrMinutesGrid } from 'RtUi/app/rtLco/Minutes/lib/grids/LcrMinutesGrid';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IInitialResourceParams {
	isActive?: number;
	isComplete?: number;
	respOrg: RespOrgIndexResponse | undefined;
}
interface IMinutesIndexContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@MinutesRouter.getIndexRtUiController()
export class MinutesIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IMinutesIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		respOrg: undefined,
		isActive: -1,
		isComplete: -1
	};

	public state: IMinutesIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	public TabsHeaders = {
		CreateTrafficProfile: 'Create Traffic Profile'
	};

	public constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { isActive, respOrg, isComplete } = this.state;

		const resourceParams: LcrMinuteIndexRequest = {};

		if (isActive !== -1) {
			resourceParams.isActive = isActive;
		}

		if (isComplete !== -1) {
			resourceParams.isComplete = isComplete;
		}

		if (respOrg) {
			resourceParams.respOrgId = respOrg.respOrgId;
		}

		return resourceParams;
	}

	public render() {
		return (
			<TabbedLayout
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={MinutesRouter}
				activeTab={this.state.activeTab}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<RespOrgSelect
							onChange={(respOrg: RespOrgIndexResponse) =>
								this.setState({ respOrg })
							}
							value={this.state.respOrg}
						/>
						<IsActiveRadioFormControl
							onChange={(isActive) => this.setState({ isActive })}
							value={this.state.isActive}
						/>
						<BooleanRadioFormControl
							showBothOption
							label="Complete"
							onChange={(isComplete) => this.setState({ isComplete })}
							value={this.state.isComplete}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<LcrMinutesGrid
						resourceParams={this.state.resourceParams}
						autoFocusFilter
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					isAction
					header={this.TabsHeaders.CreateTrafficProfile}
					rtRoute={Rt800ApiRoutes.LcrMinutes.Create}
				>
					<AddTrafficProfile
						onSave={() => {
							this.setState({ activeTab: this.tabs.Results });
						}}
						onCancel={() => {
							this.setState({ activeTab: this.tabs.Results });
						}}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
