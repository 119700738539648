import { UserIndexResponse } from 'RtModels';
import { UserHttp } from 'RtUi/app/user/lib/Http/UserHttp';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface UserNameDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<UserIndexResponse, T> {}

export const UserNameRenderCache = new ResourceCacheMap<UserIndexResponse>(
	new UserHttp().getUsersResource(),
	'userId',
	'firstName',
	'userId',
	(user) => `${user.firstName} ${user.lastName}`
);

export const UserNameDataGridColumn = <T = any,>(
	config: UserNameDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: UserNameRenderCache,
		...config
	});
};
