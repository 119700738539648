import { RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { TemplateIndexContainer } from 'RtUi/app/rt800/Templates/Template.index';
import { TemplateProfileContainer } from 'RtUi/app/rt800/Templates/Template.profile';
import { TemplateRouter } from 'RtUi/app/rt800/Templates/Template.router';

@RtUiModule({
	controllers: [TemplateIndexContainer, TemplateProfileContainer],
	routers: [TemplateRouter]
})
export class TemplateModule {}
