/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ScenarioActionTagMatching = {
    properties: {
        scenarioActionId: {
            type: 'ScenarioActionType',
            isRequired: true,
        },
        tagValues: {
            type: 'Array',
        },
        preserveTags: {
            type: 'boolean',
        },
    },
};