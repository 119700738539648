import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { IRtUiFormProps, RtUiForm } from 'RtUi/components/ui/RtUiForm/RtUiForm';

export class RtUiNarrowForm extends React.Component<
	React.PropsWithChildren<IRtUiFormProps>,
	{}
> {
	public render() {
		return (
			<Row className={this.props.className}>
				<Col lg={6} xl={6}>
					<RtUiForm {...this.props} className="">
						{this.props.children}
					</RtUiForm>
				</Col>
			</Row>
		);
	}
}
