/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum FirebaseMessageSeverity {
    Info = 1,
    Success = 2,
    Minor = 3,
    Major = 4,
    Critical = 5,
}