import { uniq } from 'lodash-es';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { Rt800Router } from '../rt800.router';

class CallSimulatorRouterClass extends Rt800Router<void, void, {}> {
	constructor() {
		super('Call Simulator', 'callSimulator', undefined, {});

		const indexPermissions = uniq([
			...Rt800ApiRoutes.TemplateSimulator.Index.permissions,
			...Rt800ApiRoutes.TollFreeSimulator.Index.permissions
		]);

		this.setIndexPermissions(indexPermissions);
	}
	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const CallSimulatorRouter = new CallSimulatorRouterClass();
