import { FC, useState } from 'react';
import { useMount } from 'react-use';
import {
	GuardianServiceNumberSubscriptionProfileResponse,
	ServiceNumberProfileResponse
} from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { ServiceNumberEditor } from 'RtUi/app/rtCommon/ServiceNumbers/lib/forms/ServiceNumberEditor';
import { ServiceNumberResource } from 'RtUi/app/rtCommon/ServiceNumbers/lib/resources/ServiceNumberResource';
import { GuardianServiceNumberSubscriptionRouter } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/GuardianServiceNumberSubscription.router';
import { GuardianServiceNumberSubscriptionForm } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/forms/GuardianServiceNumberSubscriptionForm';
import { GuardianServiceNumberSubscriptionGrid } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/grids/GuardianServiceNumberSubscriptionGrid';
import { GuardianServiceNumberSubscriptionResource } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/resources/GuardianServiceNumberSubscriptionResource';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const GuardianServiceNumberSubscriptionProfile: FC<{}> = () => {
	const id = useIdParam();
	const [guardianServiceNumber, setGuardianServiceNumber] =
		useState<GuardianServiceNumberSubscriptionProfileResponse>();
	const [serviceNumber, setServiceNumber] =
		useState<ServiceNumberProfileResponse>();
	const [tabs, activeTab, setActiveTab] = useTabs(
		GuardianServiceNumberSubscriptionRouter.getProfileTabs()
	);

	useMount(async () => {
		const resource = new GuardianServiceNumberSubscriptionResource();
		const guardianServiceNumber = await resource.get(id);

		setGuardianServiceNumber(guardianServiceNumber);

		const serviceNumberResource = new ServiceNumberResource();
		const serviceNumberRes = await serviceNumberResource.get(
			guardianServiceNumber?.serviceNumber
		);

		setServiceNumber(serviceNumberRes);
	});

	if (!guardianServiceNumber || !serviceNumber) {
		return <Loading />;
	}

	return (
		<TabbedLayout<GuardianServiceNumberSubscriptionProfileResponse>
			profile={guardianServiceNumber}
			router={GuardianServiceNumberSubscriptionRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<GuardianServiceNumberSubscriptionForm
					narrow
					editMode={guardianServiceNumber}
					onSuccess={(newProfile) => {
						if (
							newProfile.guardianServiceNumberSubscriptionId !==
							guardianServiceNumber.guardianServiceNumberSubscriptionId
						) {
							const newProfilePath =
								GuardianServiceNumberSubscriptionRouter.getProfileRoute(
									newProfile
								);

							return BrowserHistory.push(newProfilePath);
						}

						setGuardianServiceNumber(newProfile);
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.History}>
				<GuardianServiceNumberSubscriptionGrid
					rowThemeColor={(row) => {
						if (
							row.guardianServiceNumberSubscriptionId ===
							guardianServiceNumber.guardianServiceNumberSubscriptionId
						) {
							return RowThemeColor.WARNING;
						}

						return undefined;
					}}
					resourceParams={{
						serviceNumber: guardianServiceNumber.serviceNumber
					}}
					disableExternalLinks
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.GlobalData}>
				<ServiceNumberEditor wide value={serviceNumber} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

GuardianServiceNumberSubscriptionRouter.setProfileRtUiFunctionalComponent(
	GuardianServiceNumberSubscriptionProfile
);
