/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianAlertHistoryIndexResponse = {
    properties: {
        guardianAlertHistoryId: {
            type: 'number',
            isRequired: true,
        },
        alertRuleId: {
            type: 'number',
            isRequired: true,
        },
        alertKey: {
            type: 'string',
            isRequired: true,
        },
        lookupInfo: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        lastTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        failureQty: {
            type: 'number',
            isRequired: true,
        },
        failureData: {
            type: 'Array',
            isRequired: true,
        },
        statusId: {
            type: 'AlertStatus',
            isRequired: true,
        },
        statusUntilNextTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        flowId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        alertSummary: {
            type: 'string',
            isRequired: true,
        },
        ruleSummary: {
            type: 'string',
            isRequired: true,
        },
    },
};