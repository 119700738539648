import { ResourceProfileResponse, ResourceType } from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { ResourceEditor } from 'RtUi/app/rtCommon/Resources/lib/forms/ResourceEditor';
import { ResourceGrid } from 'RtUi/app/rtCommon/Resources/lib/grids/ResourceGrid';
import { DidResourceConfigurationRouter } from 'RtUi/app/rtDid/ResourceConfigurations/DidResourceConfigurations.router';
import { DidResourceRouter } from 'RtUi/app/rtDid/Resources/DidResource.router';
import { CreateConnectionForm } from 'RtUi/app/rtDid/Resources/forms/CreateConnectionForm';
import { ConnectionsGrid } from 'RtUi/app/rtDid/Resources/lib/grids/ConnectionGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const DidResourceIndexContainer = (): JSX.Element => {
	const [tabs, activeTab, setActiveTab] = useTabs(
		{
			Resources: { header: 'Resources', isDefault: true },
			Connections: { header: 'Connections' }
		},
		null,
		[
			{ header: 'Create DID Resources', isAction: true },
			{ header: 'Create Resource Connection', isAction: true }
		]
	);

	const goToDidResourceProfile = (profile: ResourceProfileResponse) => {
		const didResourceProfilePath = DidResourceRouter.getProfileRoute(
			profile.resourceId
		);

		BrowserHistory.push(didResourceProfilePath);
	};

	return (
		<TabbedLayout
			router={DidResourceRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Resources}>
				<ResourceGrid
					resourceParams={{
						excludeResourceTypes: [ResourceType.CarrierConnect]
					}}
					router={DidResourceConfigurationRouter}
					autoFocusFilter
					pageName="rtDid_Resources"
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Connections}>
				<ConnectionsGrid
					router={DidResourceRouter}
					resourceParams={{
						excludeResourceTypes: [ResourceType.CarrierConnect]
					}}
					autoFocusFilter
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab isAction header="Create DID Resources">
				<ResourceEditor
					createMode
					excludeResourceTypes={[ResourceType.CarrierConnect]}
					onCreate={(profile: ResourceProfileResponse) =>
						goToDidResourceProfile(profile)
					}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab isAction header="Create Resource Connection">
				<CreateConnectionForm
					createMode
					excludeResourceTypes={[ResourceType.CarrierConnect]}
					onCreate={(profile: ResourceProfileResponse) =>
						goToDidResourceProfile(profile)
					}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

DidResourceRouter.setIndexRtUiFunctionalComponent(DidResourceIndexContainer);
