/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DashboardProfileUrlRequest = {
    properties: {
        dashboardTaskId: {
            type: 'DashboardTaskId',
            isRequired: true,
        },
    },
};