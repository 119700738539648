import * as React from 'react';
import { FlowCreateRequest, FlowProfileResponse } from 'RtModels';
import { FlowResource } from 'RtUi/app/rtAdmin/Flows/lib/resources/FlowResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';

interface IFlowEditorProps {
	createMode?: boolean;
	editMode?: FlowProfileResponse;
	onCreateOrUpdate: (managedEntity: FlowProfileResponse) => void;
}

interface IFlowEditorState extends FlowCreateRequest {
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
}

export class FlowEditor extends React.Component<
	IFlowEditorProps,
	IFlowEditorState
> {
	public state: IFlowEditorState = {
		displayMode: true,
		isSubmitting: false,
		error: undefined,
		//forms
		label: '',
		summary: ''
	};

	public componentIsMounted = false;

	public componentDidMount() {
		this.componentIsMounted = true;

		this.init();
	}

	public init(reset = false) {
		const { editMode, createMode } = this.props;

		if (editMode) {
			const { label, summary } = editMode;

			this.setState({ label, summary });
		} else if (createMode) {
			this.setState({ displayMode: false });
		}

		if (reset) {
			this.setState({ displayMode: true, error: undefined });
		}
	}

	public componentWillUnmount() {
		this.componentIsMounted = false;
	}

	public async submit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const flowResource = new FlowResource();

		this.setState({ isSubmitting: true, error: undefined });

		const { summary, label } = this.state;
		let updateOrCreatePromise: Promise<FlowProfileResponse>;

		if (this.props.editMode) {
			updateOrCreatePromise = flowResource.update(
				this.props.editMode.flowId,
				label,
				summary
			);
		} else {
			updateOrCreatePromise = flowResource.create(label, summary);
		}

		let flowProfile: FlowProfileResponse | undefined;

		try {
			flowProfile = await updateOrCreatePromise;
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (this.props.editMode) {
			this.setState({ displayMode: true });
		}

		if (flowProfile) {
			this.props.onCreateOrUpdate(flowProfile);
		}
	}

	public render() {
		return (
			<RtUiNarrowForm
				onSubmit={(evt) => this.submit(evt)}
				onCancel={() => this.init(true)}
				displayMode={this.state.displayMode}
				onChange={(displayMode) => this.setState({ displayMode })}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				createMode={this.props.createMode}
			>
				<InputFormControl
					label="Label"
					required
					displayMode={this.state.displayMode}
					onChange={(label) => this.setState({ label })}
					value={this.state.label}
				/>
				<InputFormControl
					label="Summary"
					required
					displayMode={this.state.displayMode}
					onChange={(summary) => this.setState({ summary })}
					value={this.state.summary}
				/>
			</RtUiNarrowForm>
		);
	}
}
