/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ServerProcessIndexResponse = {
    properties: {
        serverProcessId: {
            type: 'number',
            isRequired: true,
        },
        processName: {
            type: 'string',
            isRequired: true,
        },
        key: {
            type: 'string',
            isRequired: true,
        },
        nagiosKey: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        hostname: {
            type: 'string',
            isRequired: true,
        },
        pid: {
            type: 'number',
            isRequired: true,
        },
        lastCheckTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        nextCheckTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        lastStartTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        lastEndTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        maxRunSecs: {
            type: 'number',
            isRequired: true,
        },
        data: {
            properties: {
            },
            isRequired: true,
            isNullable: true,
        },
        errorsSinceBoot: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        overdueSecs: {
            type: 'number',
            isRequired: true,
        },
        longSecs: {
            type: 'number',
            isRequired: true,
        },
        isFailing: {
            type: 'number',
            isRequired: true,
        },
        errorMsg: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
    },
};