/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MyProfileUpdateRequest = {
    properties: {
        officePhone: {
            type: 'string',
            isNullable: true,
        },
        mobilePhone: {
            type: 'string',
            isNullable: true,
        },
        smsPhone: {
            type: 'string',
            isNullable: true,
        },
    },
};