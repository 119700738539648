import { ScenarioActionTagMatching } from 'RtModels';
import { TagSelectOption } from 'RtUi/app/rtSip/Scenarios/lib/controls/TagSelect';
import {
	IScenarioActionFormProps,
	IScenarioActionFormState,
	ScenarioActionForm
} from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioActionForm';
import CreatableMultiSelect from 'RtUi/components/form/CreatableMultiSelect';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';

interface SelectValue {
	label: string;
	value: string;
}
interface IScenarioActionTagMatchingFormState extends IScenarioActionFormState {
	tags: SelectValue[];
}

export class ScenarioActionTagMatchingForm extends ScenarioActionForm<
	ScenarioActionTagMatching,
	IScenarioActionTagMatchingFormState
> {
	public state: IScenarioActionTagMatchingFormState = {
		tags: []
	};

	constructor(props: IScenarioActionFormProps<ScenarioActionTagMatching>) {
		super(props);

		const { tagValues = [] } = props.action;

		//Map new tags
		this.state.tags = tagValues.map((tagValue) => {
			return {
				value: tagValue,
				label: tagValue
			};
		});
	}

	public updateTags(tags: TagSelectOption[] | null) {
		const { action } = this.props;

		tags = tags ?? [];

		action.tagValues = tags.map((tag) => tag.value);

		this.setState({ tags });

		this.props.onChange(action);
	}

	public render() {
		return (
			<>
				<CreatableMultiSelect
					label="Tags"
					value={this.state.tags}
					onChange={(tags) => this.updateTags(tags as any)}
					disabled={this.props.displayMode}
				/>
				<RadioFormControl<boolean | undefined>
					label="Preserve Tags"
					displayMode={this.props.displayMode}
					value={this.props.action.preserveTags}
					options={[
						{ value: true, label: 'Yes' },
						{ value: false, label: 'No' },
						{ value: undefined, label: 'Neither' }
					]}
					onChange={(value) => {
						const { action } = this.props;

						if (typeof value === 'undefined' && 'preserveTags' in action) {
							delete action.preserveTags;
						} else {
							action.preserveTags = value;
						}

						this.props.onChange(action);
					}}
				/>
			</>
		);
	}
}
