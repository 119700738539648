import * as React from 'react';
import {
	Alert,
	Badge,
	Button,
	Form,
	InputGroup,
	ListGroup,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';
import { ResourceUserIndexResponse, UserIndexResponse } from 'RtModels';
import { ResourceUsersResource } from 'RtUi/app/rtCommon/Resources/lib/resources/ResourceUsersResource';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Loading } from 'RtUi/components/ui/Loading';

interface IResourceUsersListProps {
	resourceId: number;
}

interface IResourceUsersListState {
	resource: ResourceUsersResource;
	users: ResourceUserIndexResponse[];
	isSubmitting: boolean;
	isLoading: boolean;
	error?: any;
	usersBeingAdded?: UserIndexResponse[];
	userBeingRemoved?: ResourceUserIndexResponse;
}

export class ResourceUsersList extends React.Component<
	IResourceUsersListProps,
	IResourceUsersListState
> {
	constructor(props: IResourceUsersListProps) {
		super(props);

		this.state = {
			resource: new ResourceUsersResource(props.resourceId),
			users: [],
			isSubmitting: false,
			isLoading: false,
			error: undefined
		};
	}

	public async componentDidMount() {
		try {
			this.setState({ isLoading: true });
			await this.loadUsers();
		} finally {
			this.setState({ isLoading: false });
		}
	}

	public async loadUsers() {
		const users = await this.state.resource.getAllWithoutCache();

		this.setState({ users });
	}

	public async addUser(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const { resource, usersBeingAdded } = this.state;

		if (!usersBeingAdded) {
			return;
		}

		try {
			this.setState({ error: undefined, isSubmitting: true });

			let newUsers: ResourceUserIndexResponse[] = [];

			for (const userBeingAdded of usersBeingAdded) {
				newUsers = await resource.create(userBeingAdded.userId);
			}

			this.setState({ usersBeingAdded: undefined, users: newUsers });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public async removeUser(user: ResourceUserIndexResponse) {
		const { resource } = this.state;

		const confirm = await Confirmation.create(
			<span>
				<span>You are about to remove&nbsp;</span>
				<span className="text-muted">
					{user.firstName} {user.lastName}&apos;s&nbsp;
				</span>
				<span>access to this resource. Would you like to continue?</span>
			</span>
		);

		if (!confirm) {
			return;
		}

		try {
			this.setState({
				error: undefined,
				isSubmitting: true,
				userBeingRemoved: user
			});

			const newUsers = await resource.delete(user.userId);

			this.setState({ users: newUsers });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false, userBeingRemoved: undefined });
		}
	}

	public onUserSelectChange(usersBeingAdded: UserIndexResponse[]) {
		this.setState({ usersBeingAdded, error: undefined });
	}

	public renderUserListGroupItem(user: ResourceUserIndexResponse) {
		const { userBeingRemoved } = this.state;
		const btnId = `btn-user-remove-${user.userId}`;
		const listGroupItemClassNames = [
			'd-flex',
			'justify-content-between',
			'align-items-center'
		];
		const userIsBeingRemoved = userBeingRemoved?.userId === user.userId;

		if (userIsBeingRemoved) {
			listGroupItemClassNames.push('text-danger');
		}

		return (
			<ListGroup.Item
				key={user.userId}
				className={listGroupItemClassNames.join(' ')}
			>
				<section>
					<span className="d-inline-block me-2">
						{user.firstName} {user.lastName}
					</span>
					<Badge bg="light" text="dark">
						{user.emailAddress}
					</Badge>
				</section>
				<section>
					<OverlayTrigger
						placement="right"
						overlay={(props) => (
							<Tooltip id={`${btnId}-tooltip`} {...props}>
								Remove User
							</Tooltip>
						)}
					>
						{({ ref, ...triggerHandler }) => (
							<Button
								ref={ref}
								{...triggerHandler}
								type="button"
								variant="white"
								size="sm"
								id={btnId}
								disabled={userIsBeingRemoved}
								onClick={() => this.removeUser(user)}
							>
								<i className="fas fa-fw fa-times" />
							</Button>
						)}
					</OverlayTrigger>
				</section>
			</ListGroup.Item>
		);
	}

	public render() {
		const { users, isLoading } = this.state;

		if (isLoading) {
			return <Loading internal />;
		}

		return (
			<section style={{ maxWidth: 600 }}>
				{users.length > 0 && (
					<Alert variant="warning" className="d-flex justify-content-start">
						<i className="fas fa-fw fa-info-circle me-2" />
						<span>The following users have access to this resource.</span>
					</Alert>
				)}
				{users.length <= 0 && (
					<Alert variant="warning" className="d-flex justify-content-start">
						<i className="fas fa-fw fa-info-circle me-2" />
						<span>There are no users with access to this resource.</span>
					</Alert>
				)}
				{users.length > 0 && (
					<ListGroup className="mb-4">
						{users.map((user) => this.renderUserListGroupItem(user))}
					</ListGroup>
				)}
				<Form onSubmit={(evt) => this.addUser(evt)}>
					<Form.Group className="mb-3">
						<Form.Label>Add Users</Form.Label>
						<InputGroup>
							<UserSelect<true>
								required
								label="Add Users"
								placeholder="Select Users"
								//className="w-100"
								useControlGroup={false}
								multi
								hideUsersByIds={this.state.users.map((u) => u.userId)}
								closeMenuOnSelect={false}
								onChange={(usersBeingAdded) =>
									this.onUserSelectChange(usersBeingAdded)
								}
								value={this.state.usersBeingAdded}
							/>
							<Button type="submit" disabled={this.state.isSubmitting}>
								<span>Submit</span>
								{this.state.isSubmitting && (
									<>
										<span>ting&nbsp;</span>
										<i className="fas fa-fw fa-spin fa-cog" />
									</>
								)}
							</Button>
						</InputGroup>
					</Form.Group>
					<FormErrors error={this.state.error} />
				</Form>
			</section>
		);
	}
}
