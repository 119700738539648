import { JobIndexRequest, JobIndexResponse, JobStatusId } from 'RtModels';
import { AdminUserRouter } from 'RtUi/app/Administration/Users/AdminUser.router';
import { JobRouter } from 'RtUi/app/rtCarrierConnect/Jobs/Job.router';
import {
	IJobTaskStatusSelectOption,
	JobTaskStatusSelect
} from 'RtUi/app/rtCarrierConnect/Jobs/lib/controls/JobTaskStatusSelect';
import { JobResource } from 'RtUi/app/rtCarrierConnect/Jobs/lib/resources/JobResource';
import { useGetJobs } from 'RtUi/app/rtCarrierConnect/Jobs/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getJobsColumns } from 'RtUi/components/data/DataGrid/configurations/rtCarrierConnect/jobs';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import clsx from 'clsx';
import { FC, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useToggle } from 'react-use';

interface IJobGridProps {
	onRowClick?: (row: JobIndexResponse) => void;
	resourceParams?: JobIndexRequest;
	autoFocusFilter?: boolean;
}

export const JobGrid: FC<React.PropsWithChildren<IJobGridProps>> = ({
	resourceParams
}) => {
	const completedJobStatuses = [
		JobStatusId.COMPLETED_NO_ERROR,
		JobStatusId.COMPLETED_WITH_ERROR,
		JobStatusId.COMPLETED_WITH_WARNING
	];
	const { data, isFetching: isLoading } = useGetJobs(resourceParams);
	const columns = useMemo(() => getJobsColumns(), []);
	const [selectedJobs, setSelectedJobs] = useState<JobIndexResponse[]>([]);
	const [isSubmitting, toggleIsSubmitting] = useToggle(false);
	const [error, setError] = useState<any>();
	const [selectedStatus, setSelectedStatus] =
		useState<IJobTaskStatusSelectOption>();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const bulkSubmit = async () => {
		try {
			const resource = new JobResource();
			await resource.update({
				jobIds: selectedJobs.map((job) => job.jobId),
				jobStatusId: selectedStatus!.value
			});
			setSelectedJobs([]);
		} catch (err) {
			setError(err);
			toggleIsSubmitting();
		}
	};

	const handleModalClick = async () => {
		setError(undefined);
		toggleIsSubmitting();
		await bulkSubmit();
		toggleIsSubmitting();
		handleClose();
	};

	return (
		<>
			<Confirmation />
			<DataGrid<JobIndexResponse>
				router={JobRouter}
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				onChangeSelectedRows={setSelectedJobs}
				disableExternalLinks={[AdminUserRouter]}
				enableRowSelection={(row) =>
					!completedJobStatuses.includes(row.jobStatusId)
				}
				headerAction={() => {
					return (
						<Button
							variant="white-alt"
							onClick={() => handleShow()}
							className={clsx({
								'd-none': selectedJobs.length === 0
							})}
							id="bulkUpdateJob"
						>
							Bulk Update
						</Button>
					);
				}}
				pageName={'rtCarrierConnect_jobs'}
			/>
			<Modal backdrop="static" show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title>Bulk Update Jobs</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<RtUiForm
						disableBody
						disablePadding
						error={error}
						hideButtons
						onSubmit={(e) => {
							e.preventDefault();
						}}
					>
						<JobTaskStatusSelect
							required
							label="Select new status"
							clearable={false}
							enabledJobStatusIds={[
								JobStatusId.COMPLETED_WITH_ERROR,
								JobStatusId.COMPLETED_NO_ERROR
							]}
							value={selectedStatus}
							onChange={(jobTaskStatus) => {
								setSelectedStatus(jobTaskStatus);
							}}
							initialOptionId={String(JobStatusId.COMPLETED_NO_ERROR)}
						/>
					</RtUiForm>
				</Modal.Body>
				<Modal.Footer>
					<Button
						disabled={isSubmitting}
						variant="white"
						className="me-2"
						onClick={handleClose}
					>
						Close
					</Button>
					<Button
						disabled={isSubmitting}
						variant="submit"
						onClick={() => handleModalClick()}
					>
						{isSubmitting && <i className="fas fa-fw fa-cog fa-spin me-2" />}
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
