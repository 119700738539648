/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum SortDirection {
    Asc = 'ASC',
    Desc = 'DESC',
    None = '',
}