import { FileStreamIndexResponse } from 'RtModels';
import { FileStreamRouter } from 'RtUi/app/rtVue/FileStreams/FileStream.router';
import { useGetFileStreams } from 'RtUi/app/rtVue/FileStreams/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getFileStreamsColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/fileStreams';
import { useMemo } from 'react';

interface IFileStreamDataGridProps {}

export const FileStreamDataGrid = ({}: IFileStreamDataGridProps) => {
	const { data, isLoading } = useGetFileStreams();

	const columns = useMemo(() => getFileStreamsColumns(), []);

	return (
		<DataGrid<FileStreamIndexResponse>
			data={data?.data}
			columns={columns}
			defaultSorting={[{ id: 'description', desc: false }]}
			pageName={'configureRateSheet-fileStreams'}
			router={FileStreamRouter}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
