import { CprNumberIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DelimiterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DelimiterDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { SomosApprovalIndicatorDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SomosApprovalIndicatorDataGridColumn';
import { SomosCprStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SomosCprStatus';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { TollFreeNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeNumberDataGridColumn';

export const getCprSearchColumns = (): Array<
	DataGridColumn<CprNumberIndexResponse>
> => [
	TollFreeNumberDataGridColumn({
		accessorKey: 'tfn',
		header: 'Toll-Free Number'
	}),
	...RespOrgColumnsBuilder<CprNumberIndexResponse>({
		accessorKey: 'respOrgId',
		header: 'RespOrg'
	}),
	SomosCprStatusDataGridColumn({
		accessorKey: 'cprStatusId',
		header: 'CPR Status'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'effectiveTs',
		header: 'Effective At'
	}),
	DefaultDataGridColumn({
		accessorKey: 'templateName',
		header: 'Template'
	}),
	DelimiterDataGridColumn({
		accessorKey: 'interLataCarriers',
		header: 'Carriers'
	}),
	DelimiterDataGridColumn({
		accessorKey: 'aosValues',
		header: 'AOS Values'
	}),
	SomosApprovalIndicatorDataGridColumn({
		accessorKey: 'approvalIndicator',
		header: 'Approval'
	}),
	IntegerDataGridColumn({
		accessorKey: 'scpQueuedQty',
		header: 'SCP Queued'
	}),
	IntegerDataGridColumn({
		accessorKey: 'scpFailureQty',
		header: 'SCP Failure'
	}),
	IntegerDataGridColumn({
		accessorKey: 'scpOtherQty',
		header: 'SCP Other'
	}),
	IntegerDataGridColumn({
		accessorKey: 'scpSuccessQty',
		header: 'SCP Success'
	}),
	IntegerDataGridColumn({
		accessorKey: 'scpTotalQty',
		header: 'SCP Total'
	})
];
