/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TaskIndexResponse = {
    properties: {
        taskId: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        number: {
            type: 'string',
            isRequired: true,
        },
        taskTypeId: {
            type: 'number',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        requestedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        scheduledAt: {
            type: 'Date',
            format: 'date-time',
        },
        processedAt: {
            type: 'Date',
            format: 'date-time',
        },
        priority: {
            type: 'number',
        },
        referenceKey: {
            type: 'string',
            isRequired: true,
        },
        taskLabel: {
            type: 'string',
            isRequired: true,
        },
        tfnQty: {
            type: 'number',
            isRequired: true,
        },
        pendingQty: {
            type: 'number',
            isRequired: true,
        },
        warningQty: {
            type: 'number',
            isRequired: true,
        },
        errorQty: {
            type: 'number',
            isRequired: true,
        },
        completedAt: {
            type: 'Date',
            format: 'date-time',
        },
    },
};