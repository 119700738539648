import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianRatingSimulatorResponse,
	GuardianRatingSimulatorProfileRequest
} from 'RtModels';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';

export class RatingSimulatorHttp {
	public simulate(request: GuardianRatingSimulatorProfileRequest) {
		const body = JSON.stringify(request);

		return HttpRequest.fetchWithRoute<GuardianRatingSimulatorResponse>(
			RtVueApiRoutes.GuardianRatingSimulator.Profile,
			{
				body
			}
		);
	}
}
