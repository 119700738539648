/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianAlertHistoryNoteIndexResponse = {
    properties: {
        guardianAlertHistoryNoteId: {
            type: 'number',
            isRequired: true,
        },
        guardianAlertHistoryId: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        userId: {
            type: 'number',
            isRequired: true,
        },
        note: {
            type: 'string',
            isRequired: true,
        },
        userEmail: {
            type: 'string',
            isRequired: true,
        },
    },
};