/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoutingRuleMatchingResponse = {
    properties: {
        routingRuleMatchTypeId: {
            type: 'RoutingRuleMatchType',
            isRequired: true,
        },
        pattern: {
            type: 'string',
            isRequired: true,
        },
        isOmit: {
            type: 'number',
            isRequired: true,
        },
    },
};