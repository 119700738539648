/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $PartitionRespOrgIndexResponse = {
    properties: {
        partitionRespOrgId: {
            type: 'number',
            isRequired: true,
        },
        partitionId: {
            type: 'number',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        isDefault: {
            type: 'number',
            isRequired: true,
        },
    },
};