import { LcrMinuteIndexRequest, MinuteProfile } from 'RtModels';
import { MinutesRouter } from 'RtUi/app/rtLco/Minutes/Minutes.router';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { LcrMinutesResource } from '../resources/LcrMinutesResource';

interface ILcrMinuteSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<MinuteProfile, isMulti> {
	resourceParams?: LcrMinuteIndexRequest;
}

export class LcrMinuteSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	MinuteProfile,
	isMulti,
	ILcrMinuteSelectProps<isMulti>
> {
	public static defaultProps = {
		router: MinutesRouter
	};
	public resourceClass = LcrMinutesResource;
	public state: ISelectFormControlState<MinuteProfile> = {
		formLabel: 'LCR Minute',
		valueKey: 'minuteProfileId',
		labelKey: 'label'
	};

	public componentDidMount() {
		this.updateGetAllParams();

		super.componentDidMount();
	}

	public componentDidUpdate(prevProps: ILcrMinuteSelectProps<isMulti>) {
		this.updateGetAllParams();

		super.componentDidUpdate(prevProps);
	}

	public updateGetAllParams() {
		const { resourceParams = {} } = this.props;

		this.setGetAllParams({ isActive: 1, ...resourceParams });
	}
}
