import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { AsrSummaryColumns, AsrSummaryIndexResponse } from 'RtModels';
import { AverageCallDurationDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AverageCallDurationDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { MinutesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MinutesDataGridColumn';
import { PercentDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PercentDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { sumBy } from 'lodash-es';

/* cSpell:disable */

export const getAsrSummaryColumns = (
	columns: AsrSummaryColumns[]
): Array<DataGridColumn<AsrSummaryIndexResponse>> => {
	return [
		DateDataGridColumn({
			header: 'Time Slot',
			accessorKey: 'timeslot',
			format: 'YYYY-MM-DD hh:mm:ss A',
			exportDateFormat: 'yyyy-mm-dd hh:mm:ss AM/PM',
			hiddenIfEmpty: true
		}),
		HeaderDataGridColumn({
			header: 'Customer',
			columns: [
				AccountDataGridColumn({
					accessorKey: 'ercs',
					hiddenIfEmpty: true,
					header: 'Summary'
				}),
				SubscriptionDataGridColumn({
					accessorKey: 'erss',
					hiddenIfEmpty: true,
					header: 'Subscription'
				}),
				DefaultDataGridColumn({
					header: 'In. Remote IP',
					accessorKey: 'inps',
					hiddenIfEmpty: true
				}),
				DefaultDataGridColumn({
					header: 'Result',
					accessorKey: 'irel',
					hiddenIfEmpty: true
				}),
				DefaultDataGridColumn({
					header: 'Local IP',
					accessorKey: 'igw',
					hiddenIfEmpty: true
				}),
				DefaultDataGridColumn({
					header: 'Rate Key',
					accessorKey: 'errk',
					hiddenIfEmpty: true
				}),
				RateDataGridColumn({
					header: 'Rate',
					accessorKey: 'errr',
					hiddenIfEmpty: true
				})
			]
		}),
		HeaderDataGridColumn({
			header: columns.includes(AsrSummaryColumns.ExtendedStats)
				? 'Customer Extended Stats'
				: 'Customer Attempts',
			columns: [
				IntegerDataGridColumn({
					accessorKey: 'erAtt',
					header: 'Atts',
					showFooter: true
				}),
				IntegerDataGridColumn({
					accessorKey: 'erInv',
					header: 'Invalid'
				}),
				IntegerDataGridColumn({
					accessorKey: 'erUnq',
					header: 'Unique',
					showFooter: true
				}),
				IntegerDataGridColumn({
					accessorKey: 'erLop',
					header: 'Loop',
					showFooter: true
				}),
				IntegerDataGridColumn({
					accessorKey: 'erLmt',
					header: 'Limit',
					showFooter: true
				}),
				IntegerDataGridColumn({
					accessorKey: 'erBsy',
					header: 'Busy',
					hiddenIfEmpty: true,
					showFooter: true
				}),
				IntegerDataGridColumn({
					accessorKey: 'erCng',
					header: 'Congested',
					hiddenIfEmpty: true,
					showFooter: true
				}),
				IntegerDataGridColumn({
					accessorKey: 'egCon',
					header: 'Cons',
					hiddenIfEmpty: true,
					showFooter: true
				}),
				MinutesDataGridColumn({
					accessorKey: 'rawMin',
					header: 'Raw min',
					hiddenIfEmpty: true,
					showFooter: true
				}),
				MinutesDataGridColumn({
					accessorKey: 'billMin',
					header: 'Bill min',
					hiddenIfEmpty: true,
					showFooter: true
				}),
				IntegerDataGridColumn({
					accessorKey: 'erPDD',
					header: 'PDD',
					hiddenIfEmpty: true
				}),
				PercentDataGridColumn({
					accessorKey: 'erAsr',
					header: 'ASR',
					hiddenIfEmpty: true,
					showFooter: true
				}),
				AverageCallDurationDataGridColumn({
					accessorKey: 'erAcd',
					header: 'ACD',
					hiddenIfEmpty: true,
					showFooter: true,
					connections: (rows) => sumBy(rows, 'egCon'),
					duration: (rows) => sumBy(rows, 'rawMin')
				})
			]
		}),
		HeaderDataGridColumn({
			header: 'Origination',
			columns: [
				DefaultDataGridColumn({
					header: 'Country',
					accessorKey: 'igcs',
					hiddenIfEmpty: true
				}),
				RateCenterDataGridColumn({
					header: 'Rate Center',
					accessorKey: 'igrcs',
					hiddenIfEmpty: true
				})
			]
		}),
		HeaderDataGridColumn({
			header: 'Destination',
			columns: [
				DefaultDataGridColumn({
					header: 'Country',
					accessorKey: 'egcs',
					hiddenIfEmpty: true
				}),
				RateCenterDataGridColumn({
					header: 'Rate Center',
					accessorKey: 'egrcs',
					hiddenIfEmpty: true
				})
			]
		}),
		HeaderDataGridColumn({
			header: 'Vendor',
			columns: [
				AccountDataGridColumn({
					accessorKey: 'eccs',
					hiddenIfEmpty: true,
					header: 'Summary'
				}),
				SubscriptionDataGridColumn({
					accessorKey: 'ecss',
					hiddenIfEmpty: true,
					header: 'Subscription'
				}),
				DefaultDataGridColumn({
					header: 'In. Remote IP',
					accessorKey: 'egps',
					hiddenIfEmpty: true
				}),
				DefaultDataGridColumn({
					header: 'Result',
					accessorKey: 'erel',
					hiddenIfEmpty: true
				}),
				DefaultDataGridColumn({
					header: 'Local IP',
					accessorKey: 'egw',
					hiddenIfEmpty: true
				}),
				DefaultDataGridColumn({
					header: 'Rate Key',
					accessorKey: 'ecrk',
					hiddenIfEmpty: true
				}),
				RateDataGridColumn({
					header: 'Rate',
					accessorKey: 'ecrr',
					hiddenIfEmpty: true
				})
			]
		}),
		HeaderDataGridColumn({
			header: 'Vendor Stats',
			columns: [
				IntegerDataGridColumn({
					accessorKey: 'ecAtt',
					header: 'Atts',
					showFooter: true
				}),
				IntegerDataGridColumn({
					accessorKey: 'ecBsy',
					header: 'Busy',
					showFooter: true
				}),
				IntegerDataGridColumn({
					accessorKey: 'ecCon',
					header: 'Congested',
					showFooter: true
				}),
				IntegerDataGridColumn({
					accessorKey: 'ecPDD',
					header: 'PDD'
				}),
				PrecisionDataGridColumn({
					accessorKey: 'ecAsr',
					header: 'ASR',
					showFooter: true
				}),
				AverageCallDurationDataGridColumn({
					accessorKey: 'ecAcd',
					header: 'ACD',
					showFooter: true,
					connections: (rows) => sumBy(rows, 'ecCon'),
					duration: (rows) => sumBy(rows, 'ecMin')
				})
			]
		}),
		HeaderDataGridColumn({
			header: 'Charges and Margin',
			columns: [
				PrecisionDataGridColumn({
					accessorKey: 'erChg',
					header: 'Customer',
					showFooter: true
				}),
				PrecisionDataGridColumn({
					accessorKey: 'ecChg',
					header: 'Vendor',
					showFooter: true
				}),
				PrecisionDataGridColumn({
					accessorKey: 'mrgn',
					header: 'Margin',
					showFooter: true
				}),
				PercentDataGridColumn({
					accessorKey: 'mpct',
					header: 'Percent',
					showFooter: true
				})
			]
		})
	];
};
