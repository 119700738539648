import { FC } from 'react';
import { ContactIndexResponse } from 'RtModels';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

type ContactIsType = Pick<
	ContactIndexResponse,
	'isBilling' | 'isMailing' | 'isNoc' | 'isProvisioning' | 'isRates' | 'isSales'
>;

export type ContactIsTypeKeys = keyof ContactIsType;

interface IContactIsTypeProps {
	contacts: ContactIndexResponse[];
	value?: ContactIsTypeKeys;
	onChange?: (newValue: ContactIsTypeKeys) => void;
}

export const ContactIsTypeSelect: FC<
	React.PropsWithChildren<IContactIsTypeProps>
> = ({ contacts, value, onChange = () => ({}) }) => {
	const numberOfContactsWithFilter = (filter: ContactIsTypeKeys) => {
		return contacts.reduce((currentValue, contact) => {
			const value = contact[filter];

			if (typeof value === 'number' && value > 0) {
				return currentValue + 1;
			}

			return currentValue;
		}, 0);
	};

	const createOptionForContactIsType = (
		label: string,
		filter: ContactIsTypeKeys
	): ISimpleSelectFormControlOption<ContactIsTypeKeys> => {
		const numberOfContacts = numberOfContactsWithFilter(filter);

		return {
			label: `${label} (${numberOfContacts.toLocaleString()})`,
			value: filter
		};
	};

	const options = [
		createOptionForContactIsType('Billing', 'isBilling'),
		createOptionForContactIsType('Mailing', 'isMailing'),
		createOptionForContactIsType('Rates', 'isRates'),
		createOptionForContactIsType('Sales', 'isSales'),
		createOptionForContactIsType('Network Operations', 'isNoc'),
		createOptionForContactIsType('Provisioning', 'isProvisioning')
	];

	const optionValue = options.find((option) => option.value === value);

	return (
		<SimpleSelectFormControl<ContactIsTypeKeys>
			label="Filter"
			placeholder="Filter"
			useControlGroup={false}
			value={optionValue}
			onChange={(option) => onChange(option?.value)}
			options={options}
		/>
	);
};
