import {
	BrowserHistory,
	logoutUser,
	pushPath
} from 'RtUi/app/@RtUi/lib/browser';
import {
	ApplicationState,
	ApplicationStateRemoveListenerFN,
	IApplicationState
} from 'RtUi/state';
import { UserActions } from 'RtUi/state/actions/user';
import ImmutableMap from 'RtUi/utils/immutable/ImmutableMap';
import { Listener } from 'history';
import { cloneDeep, difference, keys } from 'lodash-es';
import * as React from 'react';
import { Params } from 'react-router-dom';

export interface IApplicationContainerProps {
	params: Readonly<Params<string>>;
}
export type UnregisterListenCallback = () => void;
export type IContainerAppState = ImmutableMap<IApplicationState>;

/**
 * Containers are React Components with access to AppState
 */
export abstract class ApplicationContainer<
	P = IApplicationContainerProps,
	S = {}
> extends React.Component<P, S> {
	public abstract state: S;

	public static openNewTab(path: string) {
		const base = `${location.protocol}//${location.host}`;
		const url = new URL(path, base);

		window.open(url, '_blank');
	}

	public Actions = {
		User: UserActions
	};

	private lastAppState: ImmutableMap<IApplicationState> =
		ApplicationState.clone();
	private initState: S | undefined;

	private instanceComponentDidMount: (() => void) | undefined;

	constructor(props: P) {
		super(props);

		const noop = () => {
			/**/
		};
		this.instanceComponentDidMount =
			typeof this.componentDidMount === 'function'
				? this.componentDidMount.bind(this)
				: undefined;
		const componentWillUnmount =
			typeof this.componentWillUnmount === 'function'
				? this.componentWillUnmount.bind(this)
				: noop;
		let removeStateListener: ApplicationStateRemoveListenerFN = noop;
		let removeHistoryListener: UnregisterListenCallback = noop;

		//Wrap instance's componentDidMount for AppState listening
		this.componentDidMount = () => {
			this.initState = cloneDeep(this.state);

			removeStateListener = ApplicationState.listen((appState) => {
				this.lastAppState = appState;
				this.onAppStateChange(appState);
			});

			const onLocationChange: Listener = ({ location }) => {
				const searchParams = new URLSearchParams(location.search);

				this.onHistoryPathChange(location.pathname, searchParams);
			};

			onLocationChange(BrowserHistory);

			removeHistoryListener = BrowserHistory.listen(onLocationChange);

			if (this.instanceComponentDidMount) {
				this.instanceComponentDidMount();
			}
		};

		//Wrap instance's componentWillUnmount for removing AppState listening
		this.componentWillUnmount = () => {
			componentWillUnmount();
			removeHistoryListener();
			removeStateListener();
		};
	}

	public getAppHistory() {
		return BrowserHistory;
	}

	/**
	 * DO NOT CALL THIS METHOD! Only used when overridden.
	 */
	public onHistoryPathChange(
		path: string,
		searchParams: URLSearchParams
	): void {
		/**/
	}

	/**
	 * DO NOT CALL THIS METHOD! Only used when overridden.
	 */
	public onAppStateChange(appState: IContainerAppState): void {
		/**/
	}

	/**
	 * Returns a path if found, else null
	 */
	public checkForOldPathInHash() {
		const hash = decodeURIComponent(location.hash.substr(1));

		if (hash.startsWith('/')) {
			return hash;
		}

		return null;
	}

	/**
	 * Go to new path
	 * @param path
	 * @param pathParams
	 */
	public goToPath(
		path: string,
		searchParams?: URLSearchParams,
		callback?: () => void
	) {
		pushPath(path, searchParams, callback);
	}

	/**
	 * Get current path of browser
	 */
	public getCurrentPath() {
		return BrowserHistory.location.pathname;
	}

	/**
	 * Get URL Params
	 */
	public getSearchParams() {
		const { search } = BrowserHistory.location;
		const searchParams = new URLSearchParams(search);

		return searchParams;
	}

	/**
	 * Get Single URL Params
	 */
	public getSearchParam(key: string) {
		const searchParams = this.getSearchParams();
		let pathValue = searchParams.get(key);

		if (pathValue) {
			pathValue = decodeURIComponent(pathValue);
		}

		return pathValue;
	}

	/**
	 * Create ref given a path
	 */
	public createRef(pathname: string) {
		return BrowserHistory.createHref({ pathname });
	}

	/**
	 * Logs user out of the application
	 */
	public logout() {
		logoutUser();
	}

	/**
	 * Get User's Default RespOrgId
	 */
	public getUsersDefaultRespOrgId(): string | undefined {
		if (this.lastAppState) {
			const user = this.lastAppState.get('user');

			if (user) {
				return user.defaultRespOrgId;
			}
		}
	}

	/**
	 * Get User's Default Entity
	 */
	public getUsersDefaultEntityId(): string | undefined {
		if (this.lastAppState) {
			const user = this.lastAppState.get('user');

			if (user) {
				return user.defaultEntityId;
			}
		}
	}

	/**
	 * Get current param id in location object
	 */
	public getIdParam() {
		const props: IApplicationContainerProps = this.props as any;

		return props.params?.id ?? '';
	}

	/**
	 *
	 * @param paramName
	 */
	public getUrlParam(paramName: string) {
		const props: IApplicationContainerProps = this.props as any;

		return props.params?.[paramName] ?? '';
	}

	/**
	 * Resets component with initial state (first state when componentDidMount is called)
	 * Calls componentDidMount after setting new state
	 */
	public resetComponent(callback?: () => void) {
		//if (ApplicationContainer.isHotReload) { return; }

		const keysInState = keys(this.state);
		const keysInInitState = keys(this.initState);
		const keysToRemoveFromState = difference(
			keysInState,
			keysInInitState
		).filter(
			(possibleKeyToRemove) => !(possibleKeyToRemove in keysInInitState)
		);

		//Remove keys that have been defined
		const initStateWithRemovals = cloneDeep(this.initState);
		keysToRemoveFromState.forEach((possibleKeyToRemove) => {
			// @ts-expect-error
			initStateWithRemovals[possibleKeyToRemove] = undefined;
		});

		this.setState(initStateWithRemovals!, () => {
			//ApplicationContainer.isHotReload = false;

			if (this.instanceComponentDidMount) {
				this.instanceComponentDidMount();
			}

			window.scrollTo(0, 0);

			if (callback) {
				callback();
			}
		});
	}

	public updateLocationWithParams(searchParams: string) {
		const currentPath = location.pathname;
		BrowserHistory.push(`${currentPath.split('?')[0]}?${searchParams}`);
	}
}
