import * as React from 'react';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { Row, Col } from 'react-bootstrap';
import { AccountIndexResponse } from 'RtModels';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { TransactionTypeSelect } from 'RtUi/app/rtSip/Transactions/lib/controls/TransactionTypeSelect';
import { MinMaxControl } from 'RtUi/app/rtSip/Transactions/lib/controls/MinMaxControl';

export interface ITransactionFormProps {
	initialState?: ITransactionFormState;
	onSubmit: (
		e: React.FormEvent<HTMLFormElement>,
		formData: ITransactionFormState
	) => void;
}

export interface ITransactionFormState {
	startDate?: moment.Moment;
	endDate?: moment.Moment;
	transactionTypes?: Array<ISimpleSelectFormControlOption<number>>;
	accounts?: AccountIndexResponse[];
	comments?: string;
	gteAmount?: number;
	lteAmount?: number;
	isReversed?: number;
	isReversal?: number;
	gteAmountWarning?: string;
	lteAmountWarning?: string;
}

export class TransactionSearchForm extends React.Component<
	ITransactionFormProps,
	ITransactionFormState
> {
	constructor(props: ITransactionFormProps) {
		super(props);
		if (props.initialState) {
			this.state = props.initialState;
		} else {
			this.state = {
				isReversed: 0,
				isReversal: 0
			};
		}
	}

	public onAmountChange(amount: number, isGreater: boolean) {
		const numAmount = Number(amount) || 0;
		const currentState = { ...this.state };

		currentState.gteAmountWarning = '';
		currentState.lteAmountWarning = '';

		if (isGreater) {
			if (numAmount > Number(this.state.lteAmount)) {
				currentState.gteAmountWarning =
					'"Min Amount" cannot be greater than "Max Amount"';
			}
			currentState.gteAmount = numAmount;
		} else {
			if (numAmount < Number(this.state.gteAmount)) {
				currentState.lteAmountWarning =
					'"Max Amount" cannot be less than "Min Amount"';
			}
			currentState.lteAmount = numAmount;
		}
		this.setState(currentState);
	}

	public render() {
		return (
			<RtUiSearchForm wide onSubmit={(e) => this.props.onSubmit(e, this.state)}>
				<Row>
					<Col md={6}>
						<AccountSelect<true>
							label="accountId"
							value={this.state.accounts}
							onChange={(accounts) => this.setState({ accounts })}
							multi
						/>
						<TransactionTypeSelect<true>
							onChange={(transactionTypes) =>
								this.setState({ transactionTypes })
							}
							value={this.state.transactionTypes}
						/>
						<InputFormControl
							label="Comments"
							onChange={(comments) => this.setState({ comments })}
							value={this.state.comments}
						/>
						<MinMaxControl
							minAmount={this.state.gteAmount}
							maxAmount={this.state.lteAmount}
							onChange={(amount, isGreater) =>
								this.onAmountChange(amount, isGreater)
							}
							minAmountWarning={this.state.gteAmountWarning}
							maxAmountWarning={this.state.lteAmountWarning}
						/>
					</Col>
					<Col md={6}>
						<BooleanRadioFormControl
							label="Is Reversed"
							onChange={(isReversed) => this.setState({ isReversed })}
							value={this.state.isReversed}
						/>
						<BooleanRadioFormControl
							label="Is Reversal"
							onChange={(isReversal) => this.setState({ isReversal })}
							value={this.state.isReversal}
						/>
						<DatetimeFormControl
							required
							label="Start Date"
							showDateOnly={true}
							value={this.state.startDate}
							onChange={(startDate) => this.setState({ startDate })}
						/>
						<DatetimeFormControl
							required
							label="End Date"
							showDateOnly={true}
							value={this.state.endDate}
							onChange={(endDate) => this.setState({ endDate })}
						/>
					</Col>
				</Row>
			</RtUiSearchForm>
		);
	}
}
