import { FC, FormEventHandler } from 'react';
import { ServerProfileResponse } from 'RtModels';
import { ServerResource } from 'RtUi/app/rtAdmin/Servers/lib/resource/ServerResource';
import { IpAddressFormControl } from 'RtUi/app/rtVue/Connections/lib/controls/IpAddressFormControl';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';

interface IServerCreateFormProps {
	editMode?: ServerProfileResponse;
	onUpdate?: (updatedMetadata: ServerProfileResponse) => void;
	onCancel?: () => void;
}

export const ServerCreateForm: FC<IServerCreateFormProps> = ({
	onUpdate,
	editMode,
	onCancel
}) => {
	const serverResource = new ServerResource();

	const [formState, formMethods] = useRtUiFormEditor({
		createMode: !editMode,
		editMode,
		createNew: () => serverResource.createNew()
	});
	const { workingCopy } = formState;

	const onSubmit: FormEventHandler<HTMLFormElement> = async (evt) => {
		evt.preventDefault();

		formMethods.setError(undefined);
		formMethods.setIsSubmitting(true);

		try {
			const updatedMetadata = await serverResource.createServer(
				formState.workingCopy
			);

			if (onUpdate) {
				onUpdate(updatedMetadata);
			}

			formMethods.setIsSubmitting(false);
			formMethods.setDisplayMode(true);
		} catch (err) {
			formMethods.setIsSubmitting(false);
			formMethods.setError(err);
		}
	};

	return (
		<RtUiWideForm
			{...formState}
			displayMode={formState.displayMode}
			hideButtons={Boolean(editMode)}
			onSubmit={onSubmit}
			onCancel={() => {
				formMethods.onCancel();

				if (onCancel) {
					onCancel();
				}
			}}
		>
			<InputFormControl
				label="Host Name"
				displayMode={formState.displayMode}
				value={workingCopy.hostName}
				onChange={(hostName) => {
					formMethods.setWorkingCopy({ hostName });
				}}
			/>
			<IpAddressFormControl
				label="Public Ip Address"
				displayMode={formState.displayMode}
				value={workingCopy.publicIpAddress ?? ''}
				onChange={(publicIpAddress) => {
					formMethods.setWorkingCopy({ publicIpAddress });
				}}
			/>
			<IpAddressFormControl
				label="Private Ip Address"
				displayMode={formState.displayMode}
				value={workingCopy.privateIpAddress ?? ''}
				onChange={(privateIpAddress) => {
					formMethods.setWorkingCopy({ privateIpAddress });
				}}
			/>
			<InputFormControl
				label="Network Id"
				displayMode={formState.displayMode}
				type="number"
				value={workingCopy.networkId.toString() ?? 0}
				onChange={(networkId) => {
					formMethods.setWorkingCopy({ networkId: Number(networkId) });
				}}
			/>
			<BooleanRadioFormControl
				label="Active"
				showBothOption={false}
				onChange={(isActive) => {
					formMethods.setWorkingCopy({ isActive });
				}}
				value={workingCopy.isActive}
			/>
			<BooleanRadioFormControl
				label="Online"
				showBothOption={false}
				onChange={(isOnline) => {
					formMethods.setWorkingCopy({ isOnline });
				}}
				value={formState.workingCopy.isOnline}
			/>
			<BooleanRadioFormControl
				label="Allow config refresh"
				showBothOption={false}
				onChange={(allowConfigRefresh) => {
					formMethods.setWorkingCopy({ allowConfigRefresh });
				}}
				value={formState.workingCopy.allowConfigRefresh}
			/>
			<InputFormControl
				label="Cdr Worker Id"
				displayMode={formState.displayMode}
				type="number"
				value={String(formState.workingCopy.cdrWorkerId)}
				onChange={(cdrWorkerId) => {
					formMethods.setWorkingCopy({ cdrWorkerId: Number(cdrWorkerId) });
				}}
			/>
		</RtUiWideForm>
	);
};
