import { JobStatusId } from 'RtModels';
import { JobStatusHelper } from 'RtUi/app/rtCarrierConnect/Jobs/bin/JobStatusHelper';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

interface JobTaskStatusDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const JobTaskStatusDataGridColumn = <T = any,>({
	size = 300,
	...config
}: JobTaskStatusDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const jobStatusHelper = new JobStatusHelper();

	const exportValue = (value: JobStatusId) => {
		if (!jobStatusHelper.isJobStatusEnum(value)) {
			return '';
		}

		return jobStatusHelper.getReadableJobStatus(value);
	};

	const formattedValue = (value: JobStatusId) => {
		if (!jobStatusHelper.isJobStatusEnum(value)) {
			return 'Unknown Status';
		}

		return jobStatusHelper.renderJobStatus(value);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		exportValue: (value) => exportValue(value as JobStatusId),
		getValue: ({ cell }) => (
			<div className="text-monospace">
				{formattedValue(cell.getValue<JobStatusId>())}
			</div>
		)
	});
};
