/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtAdmApiRoutes, RtCommonApiRoutes } from 'RtExports/routes';
import {
	BillingEntityIndexRequest,
	BillingEntityIndexResponse,
	PartitionEntityIndexRequest,
	PartitionEntityIndexResponse,
	PartitionIndexResponse,
	PartitionRespOrgIndexRequest,
	PartitionRespOrgIndexResponse,
	PartitionSipGateway,
	PartitionSipGatewayIndexRequest,
	UrlPartition,
	UrlPartitionIndexRequest
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchPartitions = async (): Promise<
	FullResponse<PartitionIndexResponse[]>
> => {
	return handleGetRequest(RtAdmApiRoutes.Partitions.Index, {
		includeFullResponse: true
	});
};

export const useGetPartitions = () => {
	return useQuery<FullResponse<PartitionIndexResponse[]>, Error>(
		'getPartitions',
		() => {
			return fetchPartitions();
		}
	);
};

const fetchPartitionBilling = async (
	queryParams?: BillingEntityIndexRequest
): Promise<FullResponse<BillingEntityIndexResponse[]>> => {
	return handleGetRequest(RtCommonApiRoutes.BillingEntity.Index, {
		includeFullResponse: true,
		queryParams
	});
};

export const useGetPartitionBilling = (
	queryParams?: BillingEntityIndexRequest
) => {
	return useQuery<FullResponse<BillingEntityIndexResponse[]>, Error>(
		['getPartitionBilling', queryParams],
		() => {
			return fetchPartitionBilling(queryParams);
		}
	);
};

const fetchPartitionUrls = async (
	queryParams?: UrlPartitionIndexRequest
): Promise<FullResponse<UrlPartition[]>> => {
	return handleGetRequest(RtAdmApiRoutes.UrlPartitions.Index, {
		includeFullResponse: true,
		queryParams
	});
};

export const useGetPartitionUrls = (queryParams?: UrlPartitionIndexRequest) => {
	return useQuery<FullResponse<UrlPartition[]>, Error>(
		['getPartitionUrls', queryParams],
		() => {
			return fetchPartitionUrls(queryParams);
		}
	);
};

const fetchPartitionSipGateways = async (
	queryParams?: PartitionSipGatewayIndexRequest
): Promise<FullResponse<PartitionSipGateway[]>> => {
	return handleGetRequest(RtAdmApiRoutes.PartitionSipGateway.Index, {
		includeFullResponse: true,
		queryParams
	});
};

export const useGetPartitionSipGateways = (
	queryParams?: PartitionSipGatewayIndexRequest
) => {
	return useQuery<FullResponse<PartitionSipGateway[]>, Error>(
		['getPartitionSipGateways', queryParams],
		() => {
			return fetchPartitionSipGateways(queryParams);
		}
	);
};

const fetchPartitionEntities = async (
	queryParams?: PartitionEntityIndexRequest
): Promise<FullResponse<PartitionEntityIndexResponse[]>> => {
	return handleGetRequest(RtAdmApiRoutes.PartitionEntities.Index, {
		includeFullResponse: true,
		queryParams
	});
};

export const useGetPartitionEntities = (
	queryParams?: PartitionEntityIndexRequest
) => {
	return useQuery<FullResponse<PartitionEntityIndexResponse[]>, Error>(
		['getPartitionEntities', queryParams],
		() => {
			return fetchPartitionEntities(queryParams);
		}
	);
};

const fetchPartitionRespOrgs = async (
	queryParams?: PartitionRespOrgIndexRequest
): Promise<FullResponse<PartitionRespOrgIndexResponse[]>> => {
	return handleGetRequest(RtAdmApiRoutes.PartitionRespOrg.Index, {
		includeFullResponse: true,
		queryParams
	});
};

export const useGetPartitionRespOrgs = (
	queryParams?: PartitionRespOrgIndexRequest
) => {
	return useQuery<FullResponse<PartitionRespOrgIndexResponse[]>, Error>(
		['getPartitionRespOrgs', queryParams],
		() => {
			return fetchPartitionRespOrgs(queryParams);
		}
	);
};
