import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { SipTraceIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface ISipTraceRouterTabs extends IProfileApplicationContainerTabs {}

export const SipTraceContainerTabs: ISipTraceRouterTabs = {};

export class SipTraceRouterClass extends SipApplicationRouter<
	SipTraceIndexResponse,
	SipTraceIndexResponse,
	ISipTraceRouterTabs
> {
	constructor() {
		//TODO fix 'total' value
		super('SIP Trace', 'sipTrace', 'total', SipTraceContainerTabs);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.SipTrace);
	}

	public getProfileLabel(profile: SipTraceIndexResponse): string {
		//TODO fix 'total' value
		return profile.total.toString();
	}

	public getPluralName() {
		return this.getName();
	}
}

export const SipTraceRouter = new SipTraceRouterClass();
