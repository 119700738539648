import { PortsIndexResponseData } from 'RtModels';
import { VueTotalsLineChartAbstract } from 'RtUi/app/rtVue/common/lib/charts/VueTotalsLineChartAbstract';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { ChartJsColors } from 'RtUi/components/charts/Constants';

export class PortTotalsLineChart extends VueTotalsLineChartAbstract<PortsIndexResponseData> {
	public async getChartData() {
		const { resource } = this.props;

		if (!resource) {
			return {};
		}

		const portIndexes = await resource.getAll();
		portIndexes.sort((a, b) => {
			return a.callDate < b.callDate ? -1 : 1;
		});

		const portChartData: ChartConfiguration = {
			type: 'line',
			data: {
				datasets: []
			}
		};
		const labelsAndProperties: Array<
			[string, keyof PortsIndexResponseData, string]
		> = [
			//cSpell:ignore minports, maxports
			['Min Ports', 'minports', ChartJsColors.LightBlue],
			['Max Ports', 'maxports', 'rgb(203,75,75)']
		];

		portChartData.data.labels = this.getLabels(portIndexes);

		for (const [label, property, color] of labelsAndProperties) {
			const data: ChartDataset[] = [];

			for (const portIndex of portIndexes) {
				let value = 0;

				if (property in portIndex) {
					value = parseInt(String(portIndex[property]), 10);
				}
				//@ts-expect-error
				data.push(value);
			}

			portChartData.data.datasets.push({
				type: 'line',
				label,
				//@ts-expect-error
				data,
				fill: false,
				borderColor: color,
				backgroundColor: color,
				lineTension: 0.4
			});
		}

		return portChartData;
	}
}
