/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $InvoiceAddressResponse = {
    properties: {
        firstName: {
            type: 'string',
            isRequired: true,
        },
        lastName: {
            type: 'string',
            isRequired: true,
        },
        address1: {
            type: 'string',
            isRequired: true,
        },
        address2: {
            type: 'string',
            isRequired: true,
        },
        city: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        postalCode: {
            type: 'string',
            isRequired: true,
        },
        country: {
            type: 'string',
            isRequired: true,
        },
    },
};