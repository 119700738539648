import { RtxSipApiRoutes } from 'RtExports/routes';
import { FullSummaryIndexRequest, SipFullSummaryIndexResponse } from 'RtModels';
import {
	FullResponse,
	handlePostRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchFixedRatingSummary = async (
	body: FullSummaryIndexRequest
): Promise<FullResponse<SipFullSummaryIndexResponse[]>> => {
	return handlePostRequest<SipFullSummaryIndexResponse[], true>(
		RtxSipApiRoutes.FixedRating.Index,
		body,
		{
			includeFullResponse: true
		}
	);
};

export const useGetFixedRatingSummary = (body: FullSummaryIndexRequest) => {
	return useQuery<FullResponse<SipFullSummaryIndexResponse[]>, Error>(
		[`useGetFixedRatingSummary`, body],
		() => fetchFixedRatingSummary(body),
		{ enabled: true }
	);
};
