/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianFraudListIndexRequest = {
    properties: {
        guardianFraudListId: {
            type: 'number',
        },
        block: {
            type: 'number',
        },
        globalRule: {
            type: 'number',
        },
        isActive: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};