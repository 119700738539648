import { CprLergLata, CprLergPoint, CprNodeType, type ICprLerg } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

/**
 * WARNING: This is consumed by the UI which only can use things within src/somos
 * if you import anything outside of that the build will break
 */
export class CprLergCache {

	public static CprLerg: ICprLerg[] = [];

	public static CprLergByLata: Record<string, ICprLerg> = {};
	public static CprLergByNpa: Record<string, ICprLerg> = {};
	public static CprLergByNpaNxx: Record<string, ICprLerg> = {};
	public static CprLergByPoint: Record<string, ICprLerg> = {};
	public static CprLergByState: Record<string, ICprLerg> = {};

	public static Latas: string[] = [];
	public static Npas: string[] = [];
	public static NpaNxxs: string[] = [];
	public static States: string[] = [];

	public static CanadaLatas: string[] = [];
	public static CanadaLatasByKey: Record<string, ICprLerg> = {};

	public static CanadaNpas: string[] = [];
	public static CanadaNpasByKey: Record<string, ICprLerg> = {};

	public static CanadaNpaNxxs: string[] = [];
	public static CanadaNpaNxxsByKey: Record<string, ICprLerg> = {};

	public static CanadaStates: string[] = [];
	public static CanadaStatesByKey: Record<string, ICprLerg> = {};

	public static Initialize(cprLerg: ICprLerg[]): boolean {
		CprLergCache.CprLerg = [];
		CprLergCache.CprLergByLata = {};
		CprLergCache.CprLergByNpa = {};
		CprLergCache.CprLergByNpaNxx = {};
		CprLergCache.CprLergByPoint = {};
		CprLergCache.CprLergByState = {};
		CprLergCache.Latas = [];
		CprLergCache.Npas = [];
		CprLergCache.NpaNxxs = [];
		CprLergCache.States = [];
		CprLergCache.CanadaLatas = [];
		CprLergCache.CanadaLatasByKey = {};
		CprLergCache.CanadaNpas = [];
		CprLergCache.CanadaNpasByKey = {};
		CprLergCache.CanadaNpaNxxs = [];
		CprLergCache.CanadaNpaNxxsByKey = {};
		CprLergCache.CanadaStates = [];
		CprLergCache.CanadaStatesByKey = {};

		for (const row of cprLerg) {
			CprLergCache.CprLerg.push(row);

			if (!(row.pointId in CprLergCache.CprLergByPoint)) {
				CprLergCache.CprLergByPoint[row.pointId] = row;
			}

			switch (row.cprNodeTypeId) {
				case CprNodeType.AreaCode:
					if (!(row.somosValue in CprLergCache.CprLergByNpa)) {
						CprLergCache.CprLergByNpa[row.somosValue] = row;
						CprLergCache.Npas.push(row.somosValue);
					}
					break;
				case CprNodeType.Lata:
					if (!(row.somosValue in CprLergCache.CprLergByLata)) {
						CprLergCache.CprLergByLata[row.somosValue] = row;
						CprLergCache.Latas.push(row.somosValue);
					}
					break;
				case CprNodeType.SixDigit:
					if (!(row.somosValue in CprLergCache.CprLergByNpaNxx)) {
						CprLergCache.CprLergByNpaNxx[row.somosValue] = row;
						CprLergCache.NpaNxxs.push(row.somosValue);
					}
					break;
				case CprNodeType.State:
					if (!(row.somosValue in CprLergCache.CprLergByState)) {
						CprLergCache.CprLergByState[row.somosValue] = row;
						CprLergCache.States.push(row.somosValue);
					}
					break;
			}

			if (row.lata !== CprLergLata.Canada && row.pointId !== CprLergPoint.Canada) {
				continue;
			}

			if (row.somosValue.substring(0, 2) === 'XX') {
				continue;
			}

			switch (row.cprNodeTypeId) {
				case CprNodeType.AreaCode:
					if (!(row.somosValue in CprLergCache.CanadaNpasByKey)) {
						CprLergCache.CanadaNpasByKey[row.somosValue] = row;
						CprLergCache.CanadaNpas.push(row.somosValue);
					}
					break;
				case CprNodeType.Lata:
					if (!(CprLergLata.Canada in CprLergCache.CanadaLatasByKey)) {
						CprLergCache.CanadaLatasByKey[CprLergLata.Canada] = row;
						CprLergCache.CanadaLatas.push(CprLergLata.Canada);
					}
					break;
				case CprNodeType.SixDigit:
					if (!(row.somosValue in CprLergCache.CanadaNpaNxxsByKey)) {
						CprLergCache.CanadaNpaNxxsByKey[row.somosValue] = row;
						CprLergCache.CanadaNpaNxxs.push(row.somosValue);
					}
					break;
				case CprNodeType.State:
					if (!(row.somosValue in CprLergCache.CanadaStatesByKey)) {
						CprLergCache.CanadaStatesByKey[row.somosValue] = row;
						CprLergCache.CanadaStates.push(row.somosValue);
					}
					break;
			}
		}

		CprLergCache.HasBeenInitialized = true;

		return true;
	}

	/***
	 * Check if the ani number is present in dictionary
	 * @param ani 6 digit number where first 3 letters are npa and the next 3 is nxx
	 * @return true if the value exist in the dictionary
	 */
	public static DoesAniNumberExist(ani: string = ''): boolean {
		const aniHasCorrectLength = ani.length >= 6;
		const npaNxx = ani.slice(0, 6);

		return (
			aniHasCorrectLength &&
			npaNxx in CprLergCache.CprLergByNpaNxx
		);
	}

	public static HasCacheBeenInitialized() {
		return CprLergCache.HasBeenInitialized;
	}

	protected static HasBeenInitialized = false;

}
