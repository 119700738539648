import { Col, Row } from 'react-bootstrap';
import {
	DataFilter,
	DataFilterOperator,
	DataSources,
	GuardianReconciliationNoteTypes,
	GuardianReconciliationUsageDetailsProfileResponse,
	GuardianReconciliationUsageDetailsSummaryIndexResponse
} from 'RtModels';
import { RtVueReportUrlSearchParamsCreate } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueReportUrlSearchParams';
import { ReconciliationNotes } from 'RtUi/app/rtVue/ReconciliationNote/lib/components/ReconciliationNotes';
import { EasyButtonContainer } from 'RtUi/app/rtVue/ReconciliationUsage/lib/components/EasyButtonContainer';
import { ReconciliationUsageBreakdown } from 'RtUi/app/rtVue/ReconciliationUsage/lib/components/ReconciliationUsageBreakdown';
import {
	DisputeControlsMode,
	ReconciliationUsageEditor
} from 'RtUi/app/rtVue/ReconciliationUsage/lib/forms/ReconciliationUsageEditor';
import { ReconciliationUsageDetailsSummaryGrid } from 'RtUi/app/rtVue/ReconciliationUsage/lib/grids/ReconciliationUsageDetailsSummaryGrid';
import { IReconciliationDirection } from 'RtUi/app/rtVue/ReconciliationUsage/lib/grids/ReconciliationUsageDialCodeSummaryGrid';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { ReconciliationUsageResource } from './lib/resources/ReconciliationUsageDetailsResource';
import { ReconciliationUsageRouter } from './ReconciliationUsage.router';
import { ReconciliationUsageDetails } from 'RtUi/app/rtVue/ReconciliationUsage/lib/components/ReconciliationUsageDetails';

interface IReconciliationUsageProfileContainerProps {}

interface IReconciliationUsageProfileContainerState {
	activeTab: string;
	profile?: GuardianReconciliationUsageDetailsProfileResponse;
}

@ReconciliationUsageRouter.getProfileRtUiController()
export class ReconciliationUsageProfileContainer extends ProfileApplicationContainer<
	IReconciliationUsageProfileContainerProps,
	IReconciliationUsageProfileContainerState
> {
	public Tabs = ReconciliationUsageRouter.getProfileTabs();

	public state: IReconciliationUsageProfileContainerState = {
		activeTab: this.Tabs.UsageDetails.header
	};

	public async componentDidMount() {
		const profileId = this.getIdParam();
		const usageResource = new ReconciliationUsageResource();
		const profile = await usageResource.get(profileId);

		this.setState({ profile });
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}

		return (
			<TabbedLayout<GuardianReconciliationUsageDetailsProfileResponse>
				router={ReconciliationUsageRouter}
				profile={profile}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.UsageDetails}>
					<Row>
						<Col xl={9}>
							<ReconciliationUsageEditor
								editMode={profile}
								disputeControlsMode={DisputeControlsMode.Hide}
								onUpdate={(newProfile) =>
									this.setState({ profile: newProfile })
								}
							/>
						</Col>
						<Col xl={3}>
							<ReconciliationNotes
								fullHeight
								reconciliationId={profile.reconciliationId}
								reconciliationNoteType={GuardianReconciliationNoteTypes.Usage}
								reconciliationDetailId={
									profile.guardianReconciliationUsageDetailId
								}
							/>
						</Col>
					</Row>
					<ReconciliationUsageBreakdown className="my-5" profile={profile} />
					<ReconciliationUsageDetailsSummaryGrid
						className="mt-5"
						onDrillDown={this.onDrillDown}
						resourceParams={{
							reconciliationId: profile.reconciliationId
						}}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Details}>
					<ReconciliationUsageDetails profile={profile} />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Dispute}>
					<EasyButtonContainer className="mb-5" profile={profile} />
					<ReconciliationUsageEditor
						editMode={profile}
						disputeControlsMode={DisputeControlsMode.HideEverythingElse}
						onUpdate={(newProfile) => this.setState({ profile: newProfile })}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
	protected onDrillDown = (
		row: GuardianReconciliationUsageDetailsSummaryIndexResponse,
		direction: IReconciliationDirection
	) => {
		const fileStreamIds = this.state.profile!.srcFilestreamIds!;
		const reconciliationId = this.state.profile?.reconciliationId;

		const filters: DataFilter[] = [
			{
				key: 'callType',
				operands: [
					{
						dataSource: DataSources.CallType,
						value: row.callType
					}
				],
				operator: DataFilterOperator.In
			},
			{
				key: 'fileStreamId',
				operands: fileStreamIds.split(',').map((fsId) => ({
					dataSource: DataSources.Text,
					value: fsId
				})),
				operator: DataFilterOperator.In
			}
		];

		const searchParams: RtVueReportUrlSearchParamsCreate = {
			date: row.callDate.toISOString()
		};

		if (typeof reconciliationId === 'number') {
			const reconciliationIdStr = String(reconciliationId);
			if (direction === 'src') {
				searchParams.srcReconciliation = reconciliationIdStr;
			}
			if (direction === 'tgt') {
				searchParams.tgtReconciliation = reconciliationIdStr;
			}
		}

		// this.goToPath(
		// 	ReconciliationSummaryRouter.getDrillDownPath(searchParams, filters)
		// );
	};
}
