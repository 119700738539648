/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SubscriptionScheduleUpdateRequest = {
    properties: {
        timezoneId: {
            type: 'number',
            isRequired: true,
        },
        isSunday: {
            type: 'number',
            isRequired: true,
        },
        isMonday: {
            type: 'number',
            isRequired: true,
        },
        isTuesday: {
            type: 'number',
            isRequired: true,
        },
        isWednesday: {
            type: 'number',
            isRequired: true,
        },
        isThursday: {
            type: 'number',
            isRequired: true,
        },
        isFriday: {
            type: 'number',
            isRequired: true,
        },
        isSaturday: {
            type: 'number',
            isRequired: true,
        },
        startDate: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endDate: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        startSecond: {
            type: 'number',
            isRequired: true,
        },
        endSecond: {
            type: 'number',
            isRequired: true,
        },
    },
};