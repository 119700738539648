import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

// exported definitions
// ======================================================================

export class CprColDayOfWeek extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.DayOfWeek;
	public readonly hasTimeZone = true;

	public readonly allowOther = true;
	public readonly valueLimit = 3;
	public readonly valueRegExp = /^(SU|M|TU|W|TH|F|SA)|(SU|M|TU|W|TH|F|SA)-(SU|M|TU|W|TH|F|SA)$/;
	public readonly isTreeNode = true;

	/*
		SU = Sunday
		M = Monday
		TU = Tuesday
		W = Wednesday
		TH = Thursday
		F = Friday
		SA = Saturday
		range may be returned in the form: SU-TU
	*/

	public isPossibleValue(rawVal: string) {
		// only validating by valueRegExp
		return true;
	}

}
