/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CprRateSheetIndexResponse = {
    properties: {
        cprRateSheetId: {
            type: 'number',
            isRequired: true,
        },
        rateSheetTypeId: {
            type: 'number',
            isRequired: true,
        },
        routingCacheTypeId: {
            type: 'number',
            isRequired: true,
        },
        routingCacheKey: {
            type: 'string',
            isRequired: true,
        },
        effectiveTs: {
            type: 'Date',
            format: 'date-time',
        },
        respOrgCarrierIds: {
            type: 'string',
            isRequired: true,
        },
        minuteProfileIds: {
            type: 'string',
            isRequired: true,
        },
        cprUnknownQty: {
            type: 'number',
            isRequired: true,
        },
        cprMatchQty: {
            type: 'number',
            isRequired: true,
        },
        unratedQty: {
            type: 'number',
            isRequired: true,
        },
        taskId: {
            type: 'number',
            isRequired: true,
        },
        startedAt: {
            type: 'Date',
            format: 'date-time',
        },
        completedAt: {
            type: 'Date',
            format: 'date-time',
        },
        createdAt: {
            type: 'Date',
            format: 'date-time',
        },
        updatedAt: {
            type: 'Date',
            format: 'date-time',
        },
    },
};