import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { DirectConnectRouter } from 'RtUi/app/rtSip/DirectConnect/DirectConnect.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import {
	TabbedLayoutTabProps,
	TabbedLayout,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { DirectConnectGrid } from 'RtUi/app/rtSip/DirectConnect/lib/grids/DirectConnectGrid';
import { DirectConnectFormEditor } from 'RtUi/app/rtSip/DirectConnect/lib/forms/DirectConnectFormEditor';
import { DirectConnectProfileResponse } from 'RtModels';

interface IDirectConnectIndexContainerTabs
	extends IProfileApplicationContainerTabs {
	DirectConnections: TabbedLayoutTabProps;
}

interface IDirectConnectIndexContainerState {
	activeTab: string;
}

@DirectConnectRouter.getNetworkOpsIndexRtUiController()
export class DirectConnectIndexContainer extends ApplicationContainer<
	{},
	IDirectConnectIndexContainerState
> {
	public Tabs: IDirectConnectIndexContainerTabs = {
		DirectConnections: {
			header: 'Direct Connect'
		}
	};

	public state: IDirectConnectIndexContainerState = {
		activeTab: this.Tabs.DirectConnections.header
	};

	public goToProfile(profile: DirectConnectProfileResponse) {
		const profilePath = DirectConnectRouter.getProfileRoute(
			profile.directConnectId
		);

		this.goToPath(profilePath);
	}

	public render() {
		return (
			<TabbedLayout
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				router={DirectConnectRouter}
			>
				<TabbedLayoutTab header={this.Tabs.DirectConnections.header}>
					<DirectConnectGrid />
				</TabbedLayoutTab>
				<TabbedLayoutTab isAction header="Add Direct Connection">
					<DirectConnectFormEditor
						onSuccess={(profile) => this.goToProfile(profile)}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
