/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateLoadApprovalTypes {
    Pending = 0,
    Denied = 1,
    Approved = 2,
    ApprovedContractTs = 10,
    ApprovedNotificationTs = 11,
    ApprovedEffectiveTs = 12,
    ApprovedLoadTs = 13,
    ApprovedTodayTs = 14,
}