import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	UrlPartition,
	UrlPartitionUpdateRequest,
	UrlPartitionCreateRequest,
	UrlPartitionCreateUrlRequest,
	UrlPartitionProfileRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class PartitionUrlsResource extends HttpResource<UrlPartition> {
	constructor() {
		super(RtAdmApiRoutes.UrlPartitions);
	}

	public createPartitionUrl(partitionUrl: UrlPartition) {
		const urlParams: UrlPartitionCreateUrlRequest = {
			partitionId: partitionUrl.partitionId
		};
		const req: UrlPartitionCreateRequest = partitionUrl;
		const body = JSON.stringify(req);

		return this.fetchWithRoute<UrlPartition>(
			RtAdmApiRoutes.UrlPartitions.Create,
			{ body, urlParams }
		);
	}

	public updatePartitionUrl(partitionUrl: UrlPartition) {
		const urlParams: UrlPartitionProfileRequest = {
			urlPartitionId: partitionUrl.urlPartitionId,
			partitionId: partitionUrl.partitionId
		};
		const bodyReq: UrlPartitionUpdateRequest = partitionUrl;
		const body = JSON.stringify(bodyReq);

		return this.fetchWithRoute<UrlPartition>(
			RtAdmApiRoutes.UrlPartitions.Update,
			{ body, urlParams }
		);
	}
}
