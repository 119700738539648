import { CallSimulatorResponse, LrnProfileResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { SomosApprovalIndicatorDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SomosApprovalIndicatorDataGridColumn';
import { SomosCprStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SomosCprStatus';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { TollFreeNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeNumberDataGridColumn';

export const getCallSimulatorColumns = (): Array<
	DataGridColumn<CallSimulatorResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'srcCprKey',
		header: 'Source',
		isHidden: true
	}),
	DefaultDataGridColumn({
		header: 'Carrier CIC',
		accessorKey: 'cic'
	}),
	IntegerDataGridColumn({
		header: 'CPR Row',
		accessorKey: 'cprRowIdx'
	}),
	DefaultDataGridColumn({
		header: 'Percent',
		accessorKey: 'percent',
		getValue: ({ cell }) => {
			const value = cell.getValue<number>();

			if (!value) {
				return <></>;
			}

			return (
				<section className="d-flex justify-content-center">
					<div
						role="alert"
						style={{ width: '80px', textAlign: 'center' }}
						className={`alert-sm mb-0 p-0 alert alert-danger`}
					>
						<span>{`${Number(value)} %`}</span>
					</div>
				</section>
			);
		}
	}),
	TimeStampDataGridColumn({
		accessorKey: 'srcCprEffectiveTs',
		header: 'Source Timestamp',
		isHidden: true
	}),
	SomosCprStatusDataGridColumn({
		accessorKey: 'srcCprStatusId',
		header: 'Source Status',
		isHidden: true
	}),
	SomosApprovalIndicatorDataGridColumn({
		accessorKey: 'srcApprovalIndicator',
		header: 'Source Approval',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'tgtCprKey',
		header: 'Matching CPR'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'tgtCprEffectiveTs',
		header: 'Matching Timestamp'
	}),
	SomosCprStatusDataGridColumn({
		accessorKey: 'tgtCprStatusId',
		header: 'Matching Status',
		isHidden: true
	}),
	SomosApprovalIndicatorDataGridColumn({
		accessorKey: 'tgtApprovalIndicator',
		header: 'Matching Approval',
		isHidden: true
	})
];

export const getCallSimulatorAniLrnColumns = (): Array<
	DataGridColumn<LrnProfileResponse>
> => [
	TollFreeNumberDataGridColumn({
		header: 'Telephone Number',
		accessorKey: 'tn'
	}),
	TollFreeNumberDataGridColumn({
		header: 'LRN',
		accessorKey: 'lrn'
	}),
	TimeStampDataGridColumn({
		header: 'Activated',
		accessorKey: 'activated'
	}),
	DefaultDataGridColumn({
		header: 'OCN',
		accessorKey: 'ocn'
	}),
	DefaultDataGridColumn({
		header: 'Class Dpc',
		accessorKey: 'classDpc'
	}),
	DefaultDataGridColumn({
		header: 'Class Ssn',
		accessorKey: 'classSsn'
	}),
	DefaultDataGridColumn({
		header: 'Lidb Dpc',
		accessorKey: 'lidbDpc'
	}),
	DefaultDataGridColumn({
		header: 'Lidb Ssn',
		accessorKey: 'lidbSsn'
	}),
	DefaultDataGridColumn({
		header: 'Isvm Dpc',
		accessorKey: 'isvmDpc'
	}),
	DefaultDataGridColumn({
		header: 'Isvm Ssn',
		accessorKey: 'isvmSsn'
	}),
	DefaultDataGridColumn({
		header: 'Cnam Dpc',
		accessorKey: 'cnamDpc'
	}),
	DefaultDataGridColumn({
		header: 'Cnam Ssn',
		accessorKey: 'cnamSsn'
	}),
	DefaultDataGridColumn({
		header: 'End User Location',
		accessorKey: 'endUserLocation'
	}),
	DefaultDataGridColumn({
		header: 'End User Location Type',
		accessorKey: 'endUserLocationType'
	}),
	DefaultDataGridColumn({
		header: 'Billing Id',
		accessorKey: 'billingId'
	}),
	DefaultDataGridColumn({
		header: 'Lnp Type',
		accessorKey: 'lnpType'
	}),
	DefaultDataGridColumn({
		header: 'Download Reason',
		accessorKey: 'downloadReason'
	}),
	DefaultDataGridColumn({
		header: 'Region Id',
		accessorKey: 'regionId'
	})
];
