import * as moment from 'moment-timezone';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import {
	LcrTemplateContainerTabs,
	LcrTemplateRouter
} from './LcrTemplate.router';
import { LcrTemplatesResource } from './lib/resources/LcrTemplatesResource';
import { LcrTemplateScenariosGrid } from 'RtUi/app/rtLco/Templates/lib/grids/LcrTemplateScenariosGrid';
import { LcrTemplateCarriersGrid } from 'RtUi/app/rtLco/Templates/lib/grids/LcrTemplateCarriersGrid';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import {
	LcrTemplateProfileResponse,
	RespOrgIndexResponse,
	EntityIndexResponse
} from 'RtModels';
import { Col, Row } from 'react-bootstrap';

interface ILcrTemplateContainerState {
	templateName: string;
	activeTab: string;
	respOrg?: RespOrgIndexResponse;
	entity?: EntityIndexResponse;
	lcrTemplate?: LcrTemplateProfileResponse;
}

@LcrTemplateRouter.getProfileRtUiController({
	path: LcrTemplateRouter.getProfileRouteWithParamNames(false)
})
export class LcrTemplateProfileContainer extends ProfileApplicationContainer<
	{},
	ILcrTemplateContainerState
> {
	public Tabs = LcrTemplateRouter.getProfileTabs();
	public state: ILcrTemplateContainerState = {
		templateName: '',
		activeTab: LcrTemplateContainerTabs.Profile.header
	};

	public componentDidMount() {
		const lcrTemplatesResource = new LcrTemplatesResource();
		const templateName = this.getIdParam();

		this.setState({ templateName });

		lcrTemplatesResource
			.get(templateName)
			.then((lcrTemplate) => this.setState({ lcrTemplate }));
	}

	public render() {
		const { lcrTemplate } = this.state;

		if (!lcrTemplate) {
			return <Loading />;
		}

		return (
			<TabbedLayout<LcrTemplateProfileResponse>
				router={LcrTemplateRouter}
				profile={lcrTemplate}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<RtUiWideForm hideButtons onSubmit={(ev) => ev.preventDefault()}>
						<Row>
							<Col md={6}>
								<EntitySelect
									displayMode
									onChange={(entity: EntityIndexResponse) =>
										this.setState({ entity })
									}
									value={this.state.entity}
									initialOptionId={lcrTemplate.entityId}
								/>
							</Col>
							<Col md={6}>
								<RespOrgSelect
									displayMode
									onChange={(respOrg: RespOrgIndexResponse) =>
										this.setState({ respOrg })
									}
									value={this.state.respOrg}
									initialOptionId={lcrTemplate.respOrgId}
								/>
							</Col>
							<Col md={6}>
								<InputFormControl
									label="LCR Task"
									displayMode
									value={String(lcrTemplate.lcrTaskId)}
								/>
							</Col>
							<Col md={6}>
								<InputFormControl
									label="Label"
									displayMode
									value={lcrTemplate.label}
								/>
							</Col>
							<Col md={6}>
								<DatetimeFormControl
									label="Effective Timestamp"
									displayMode
									value={moment(lcrTemplate.effectiveTs)}
								/>
							</Col>
							<Col md={6}>
								<IsActiveRadioFormControl
									displayMode
									hideBothOption
									value={lcrTemplate.isActive}
								/>
							</Col>
							<Col md={6}>
								<InputFormControl
									label="interLataCarriers"
									displayMode
									value={String(lcrTemplate.interLataCarriers)
										.split(',')
										.join(', ')}
								/>
							</Col>
							<Col md={6}>
								<InputFormControl
									label="intraLataCarriers"
									displayMode
									value={String(lcrTemplate.intraLataCarriers)
										.split(',')
										.join(', ')}
								/>
							</Col>
						</Row>
					</RtUiWideForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Carriers}>
					<LcrTemplateCarriersGrid
						resourceParams={{
							templateName: lcrTemplate.templateName
						}}
						lcrTemplate={lcrTemplate}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Scenarios}>
					<LcrTemplateScenariosGrid
						resourceParams={{
							templateName: lcrTemplate.templateName
						}}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
