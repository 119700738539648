import { PartitionRespOrgIndexResponse } from 'RtModels';
import { PartitionRespOrgEditor } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionRespOrgEditor';
import { PartitionRespOrgResource } from 'RtUi/app/rtAdmin/Partitions/lib/resource/PartitionRespOrgResource';
import { useGetPartitionRespOrgs } from 'RtUi/app/rtAdmin/Partitions/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getPartitionRespOrgsColumns } from 'RtUi/components/data/DataGrid/configurations/rtAdm/partitions';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Item } from 'react-contexify';

interface IPartitionRespOrgDataGridProps {
	partitionId: number;
	autoFocusFilter?: boolean;
}

const resource = new PartitionRespOrgResource();

export const PartitionRespOrgDataGrid = ({
	partitionId
}: IPartitionRespOrgDataGridProps) => {
	const [isAsideOpen, setAsideOpen] = useState<boolean>(false);
	const [selectedRecord, setSelectedRecord] =
		useState<PartitionRespOrgIndexResponse>();

	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetPartitionRespOrgs({ partitionId });

	const removeEntity = useCallback(
		async (row: PartitionRespOrgIndexResponse) => {
			const confirm = await Confirmation.createDelete(row.respOrgId);

			if (!confirm) {
				return;
			}

			await resource.delete(row.partitionId, row.partitionRespOrgId);
			refetch();
		},
		[refetch]
	);

	const columns = useMemo(
		() => getPartitionRespOrgsColumns(removeEntity),
		[removeEntity]
	);

	return (
		<>
			<DataGrid<PartitionRespOrgIndexResponse>
				data={data?.data}
				loading={isLoading}
				pageName="rtAdmin-partitionRespOrgs"
				menuLinks={(record) => (
					<Item
						onClick={() => {
							setAsideOpen(true);
							setSelectedRecord(record);
						}}
					>
						<span className="d-flex justify-content-start align-items-center">
							<i className="fas fa-fw fa-pen" />
							<span>Edit</span>
						</span>
					</Item>
				)}
				columns={columns}
			/>
			<Aside isOpen={isAsideOpen}>
				{selectedRecord && (
					<Fragment key={selectedRecord.partitionRespOrgId}>
						<header className="d-flex justify-content-between align-items-center mb-3">
							<h5 className="mb-0">
								<b>Entity - {selectedRecord.partitionRespOrgId}</b>
							</h5>
							<Button
								type="button"
								variant="light"
								onClick={() => {
									setAsideOpen(false);
								}}
							>
								<i className="fas fa-fw fa-times" />
							</Button>
						</header>
						<PartitionRespOrgEditor
							disablePadding={true}
							partitionId={partitionId}
							editMode={selectedRecord}
							onCancel={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
							onSuccess={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
								refetch();
							}}
						/>
					</Fragment>
				)}
			</Aside>
			<Confirmation />
		</>
	);
};
