import {
	LcrTaskIndexResponse,
	LcrTaskResultIndexResponse,
	RoutingCacheTypes
} from 'RtModels';
import { CprActions } from 'RtUi/app/rt800/Cprs/lib/Constants';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { useGetCprActions } from 'RtUi/app/rt800/Cprs/lib/hooks/useGetCprActions';
import { LcrTaskResultSelect } from 'RtUi/app/rtLco/TaskResults/lib/controls/LcrTaskResultSelect';
import { LcoTaskSelect } from 'RtUi/app/rtLco/Tasks/lib/controls/LcoTaskSelect';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
import { FC, useContext, useState } from 'react';
import {
	IMgiProfileImportTypes,
	MgiProfileImporter
} from '../util/MgiProfileImporter';

interface IImportCprFormProps {
	onSuccess: () => void;
	onCancel: () => void;
	btnStyle?: React.CSSProperties;
	btnClassName?: string;
}

export const ImportCprForm: FC<React.PropsWithChildren<IImportCprFormProps>> = (
	props
) => {
	const mgiProfileImporter = new MgiProfileImporter();
	const { editMode, cpr, tfns, updateCpr, triggerEditMode } =
		useContext(CprProfileContext);
	const { canCopy, canEdit, canTransfer } = useGetCprActions({ cpr, tfns });
	const [file, setFile] = useState<File | undefined>();
	const [error, setError] = useState<any>(undefined);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [importType, setImportType] =
		useState<IMgiProfileImportTypes>('routetrust');
	const [fileAccept, setFileAccept] = useState<string>(
		mgiProfileImporter.getMimeTypeAcceptStrFor('routetrust')
	);
	const [importAction, setImportAction] = useState<CprActions | undefined>(
		editMode
	);
	const [lcrTask, setLcrTask] = useState<LcrTaskIndexResponse | undefined>();
	const [lcrTaskResult, setLcrTaskResult] = useState<
		LcrTaskResultIndexResponse | undefined
	>();

	const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		if (!importAction) {
			return;
		}

		setError(undefined);
		setIsSubmitting(true);

		let importDefer: Promise<Cpr> | undefined;

		try {
			const respOrgId = cpr.getSourceRespOrgId();
			const referenceKey = cpr.getSourceReferenceKey();
			const sourceEffectiveTs = cpr.getSourceEffectiveTs();
			const routingProfileType = cpr.getRoutingProfileType();
			const cprStatus = cpr.getCprStatus();
			const approvalIndicator = cpr.getApprovalIndicator();

			if (file) {
				if (importType === '8ms') {
					importDefer = mgiProfileImporter.importFileWith8MsFormat(
						file,
						respOrgId,
						referenceKey,
						sourceEffectiveTs,
						routingProfileType,
						cprStatus,
						approvalIndicator
					);
				} else if (importType === 'routetrust') {
					importDefer = mgiProfileImporter.importFileWithRouteTrustFormat(
						file,
						respOrgId,
						referenceKey,
						sourceEffectiveTs,
						routingProfileType,
						cprStatus,
						approvalIndicator
					);
				}
			} else if (importType === 'lco') {
				if (!lcrTaskResult || !lcrTask || !lcrTask.startedAt) {
					return;
				}

				importDefer = mgiProfileImporter.importFileWithRoutingCache(
					{
						routingCacheTypeId: RoutingCacheTypes.LcrGenerator,
						routingCacheKey: lcrTaskResult.lcrTaskResultId.toString(),
						effectiveTs: lcrTask.startedAt
					},
					respOrgId,
					referenceKey,
					sourceEffectiveTs,
					routingProfileType,
					cprStatus,
					approvalIndicator
				);
			}

			if (!importDefer) {
				return;
			}

			const newMgiProfile = await importDefer;

			setIsSubmitting(false);
			updateCpr(newMgiProfile);
			triggerEditMode(importAction);

			props.onSuccess();
		} catch (error) {
			setIsSubmitting(false);
			setError(error);
		}
	};

	const updateImportType = (importType: IMgiProfileImportTypes) => {
		const fileAccept = mgiProfileImporter.getMimeTypeAcceptStrFor(importType);

		setImportType(importType);
		setFileAccept(fileAccept);
		setFile(undefined);
	};

	return (
		<RtUiForm
			onCancel={props.onCancel}
			displayMode={false}
			onSubmit={onSubmit}
			submitButtonText="Import"
			error={error}
			isSubmitting={isSubmitting}
		>
			<RadioFormControl<IMgiProfileImportTypes>
				label="Import Type"
				required
				value={importType}
				onChange={updateImportType}
				options={[
					{ label: 'RouteTrust Format', value: 'routetrust' },
					{ label: 'LCO Task', value: 'lco' },
					{
						label: '8MS Format',
						value: '8ms',
						disabled: true,
						tooltip: 'Work in Progress'
					}
				]}
			/>
			{!editMode && (
				<RadioFormControl<CprActions>
					label="Import Action"
					required
					value={importAction}
					onChange={setImportAction}
					options={[
						{ label: 'Copy', value: 'copy', disabled: !canCopy },
						{ label: 'Edit', value: 'edit', disabled: !canEdit },
						{
							label: 'Transfer',
							value: 'transfer',
							disabled: !canTransfer
						}
					]}
				/>
			)}
			{importType !== 'lco' && (
				<DragAndDropFileUploader
					required
					accept={fileAccept}
					value={file}
					onDrop={setFile}
				/>
			)}
			{importType === 'lco' && (
				<>
					<LcoTaskSelect
						subLabel="(in chronological order)"
						required
						hideIncomplete
						onChange={(lcrTask) => {
							setLcrTask(lcrTask);
							setLcrTaskResult(undefined);
						}}
						value={lcrTask}
					/>
					{lcrTask && (
						<LcrTaskResultSelect
							key={lcrTask.lcrTaskId}
							required
							lcrTaskId={lcrTask.lcrTaskId}
							onChange={setLcrTaskResult}
							value={lcrTaskResult}
						/>
					)}
				</>
			)}
		</RtUiForm>
	);
};
