import { GuardianReconciliationTopOpenItemsIndexResponse } from 'RtModels';
import { useGetTopDisputeItemsByPerson } from 'RtUi/app/rtVue/GuardianReconciliationDashboard/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getTopDisputeItemsByPersonColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/reconciliationDashboard';
import { useMemo } from 'react';

interface ITopDisputeItemsByPersonGridProps {}

export const TopDisputeItemsByPersonGrid =
	({}: ITopDisputeItemsByPersonGridProps) => {
		const { data, isFetching: isLoading } = useGetTopDisputeItemsByPerson();
		const columns = useMemo(() => getTopDisputeItemsByPersonColumns(), []);

		return (
			<DataGrid<GuardianReconciliationTopOpenItemsIndexResponse>
				data={data}
				loading={isLoading}
				pageName="rtVue_dashboard"
				columns={columns}
			/>
		);
	};
