import { CprStatus } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { cprStatusIdToName } from 'RtUi/utils/maps';

interface SomosCprStatusDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const SomosCprStatusDataGridColumn = <T = any,>({
	...config
}: SomosCprStatusDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: CprStatus) => {
		return cprStatusIdToName(value) ?? '';
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: CprStatus) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<CprStatus>())
	});
};
