import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { PartitionIndexResponse } from 'RtModels';
import { FlowRouter } from 'RtUi/app/rtAdmin/Flows/Flow.router';
import { PartitionEditForm } from 'RtUi/app/rtAdmin/Partitions/lib/forms/PartitionEditForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { PartitionRouter } from './Partition.router';
import { PartitionDataGrid } from 'RtUi/app/rtAdmin/Partitions/lib/grids/PartitionDataGrid';

export const PartitionIndexContainer: FC = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		'Partitions': { header: 'Partitions', isDefault: true },
		'Create Partition': {
			header: 'Create Partition',
			isAction: true
		}
	});

	const navigate = useNavigate();

	const goToProfile = (profile: PartitionIndexResponse) => {
		const profilePath = PartitionRouter.getProfileRoute(profile.partitionId);

		navigate(profilePath);
	};

	return (
		<TabbedLayout
			router={FlowRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Partitions}>
				<PartitionDataGrid />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs['Create Partition']}>
				<PartitionEditForm
					onUpdate={(profile) => {
						goToProfile(profile);
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

PartitionIndexContainer.displayName = 'PartitionIndexContainer';

PartitionRouter.setIndexRtUiFunctionalComponent(PartitionIndexContainer);
