import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	SipAgentConfigCreateRequest,
	SipAgentConfigProfileResponse
} from 'RtModels';
import { SipAgentConfigControl } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentConfigControl';
import { SipAgentConfigsResource } from 'RtUi/app/AccountManagement/SipAgents/lib/resources/SipAgentConfigsResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ISipAgentConfigEditorProps {
	value?: SipAgentConfigProfileResponse;
	createMode?: boolean;
	onSuccess?: () => void;
	onCancel?: () => void;
}

export const SipAgentConfigEditor = ({
	value,
	createMode = false,
	onSuccess = () => {},
	onCancel = () => {}
}: ISipAgentConfigEditorProps): JSX.Element => {
	const resource = useMemo(() => new SipAgentConfigsResource(), []);
	const [isEditing, setIsEditing] = useState<boolean>(createMode);
	const [error, setError] = useState<RtError>();
	const {
		control,
		handleSubmit,
		formState: { isSubmitting }
	} = useForm<SipAgentConfigProfileResponse>({
		defaultValues: value
	});

	const onSubmitHandler = async (
		data: SipAgentConfigCreateRequest | SipAgentConfigProfileResponse
	) => {
		setError(undefined);

		try {
			if (value) {
				await resource.update(data as SipAgentConfigProfileResponse);
				setIsEditing(false);
			} else {
				await resource.create(data as SipAgentConfigCreateRequest);
			}
			onSuccess();
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<RtUiNarrowForm
			error={error}
			isSubmitting={isSubmitting}
			displayMode={!isEditing}
			onChange={(val) => setIsEditing(!val)}
			onSubmit={handleSubmit(onSubmitHandler)}
			onCancel={onCancel}
		>
			<Controller
				control={control}
				name="label"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Label"
						value={value}
						onChange={onChange}
						required
						displayMode={!isEditing}
					/>
				)}
			/>
			<Controller
				control={control}
				name="isActive"
				render={({ field: { onChange, value } }) => (
					<IsActiveRadioFormControl
						value={value}
						onChange={onChange}
						hideBothOption
						displayMode={!isEditing}
					/>
				)}
			/>
			<Controller
				control={control}
				name="sipAgentConfig"
				render={({ field: { onChange, value } }) => (
					<SipAgentConfigControl
						value={value}
						onChange={onChange}
						displayMode={!isEditing}
					/>
				)}
			/>
		</RtUiNarrowForm>
	);
};
