/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum NotificationFrequency {
    Instant = 1,
    Daily = 2,
    Weekly = 3,
    Monthly = 4,
}