import { AccountManagementRouter } from 'RtUi/app/AccountManagement/AccountManagement.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { SwitchIndexResponse, SwitchProfileResponse } from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';

interface ISwitchProfileContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const SwitchProfileContainerTabs: ISwitchProfileContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	FileStreams: {
		header: 'File Streams'
	},
	Firewall: {
		header: 'Firewall'
	},
	SipAgents: {
		header: 'SIP Agents'
	}
};

export class SwitchRouterClass extends AccountManagementRouter<
	SwitchIndexResponse,
	SwitchProfileResponse,
	ISwitchProfileContainerTabs
> {
	constructor() {
		super('Switches', 'switches', 'switchId', SwitchProfileContainerTabs);

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.Switches);
	}

	public getIndexLabel(record: SwitchIndexResponse) {
		return record.label;
	}

	public getProfileLabel(profile: SwitchProfileResponse) {
		return profile.label;
	}
}

export const SwitchRouter = new SwitchRouterClass();
