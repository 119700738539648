/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import {
	AccountIndexResponse,
	CountryIndexResponse,
	QosManagementIndexRequest,
	RateCenterIndexResponse,
	RateCenterTypes,
	RoutePlanIndexResponse,
	ServiceIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { RateCenterTypeSelect } from 'RtUi/app/rtSip/QosManagement/lib/controls/RateCenterTypeSelect';
import { RoutePlanSelect } from 'RtUi/app/rtSip/RoutePlans/lib/controls/RoutePlanSelect';
import { ServiceSelect } from 'RtUi/app/rtSip/Services/lib/controls/ServiceSelect';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IQosManagementSearchFormProps {
	value?: QosManagementIndexRequest;
	onChange?: (value: QosManagementIndexRequest) => void;
	displayMode?: boolean;
	isSubmitting?: boolean;
	error?: any;
}

export const QosManagementSearchForm = ({
	value,
	onChange = () => {},
	displayMode = false,
	isSubmitting,
	error
}: IQosManagementSearchFormProps) => {
	const [subscriptions, setSubscriptions] =
		useState<SubscriptionIndexResponse[]>();
	const [accounts, setAccounts] = useState<AccountIndexResponse[]>();
	const [services, setServices] = useState<ServiceIndexResponse[]>();
	const [routePlans, setRoutePlans] = useState<RoutePlanIndexResponse[]>();

	const [rateCenters, setRateCenters] = useState<RateCenterIndexResponse[]>();
	const [countries, setCountries] = useState<CountryIndexResponse[]>();
	const [rateCenterTypes, setRateCenterTypes] = useState<RateCenterTypes[]>();

	const { control, handleSubmit } = useForm<QosManagementIndexRequest>({
		defaultValues: value
	});

	return (
		<RtUiForm
			isSubmitting={isSubmitting}
			error={error}
			onSubmit={handleSubmit(onChange)}
			hideButtons={displayMode}
			createMode
		>
			<Row>
				<Col md={6}>
					<h3>Subscriptions</h3>
					<Controller
						control={control}
						name="accountIds"
						render={({ field: { onChange, value } }) => (
							<AccountSelect
								label="Accounts"
								multi
								value={accounts}
								onChange={(accounts: AccountIndexResponse[]) => {
									setAccounts(accounts);
									onChange(accounts.map((acc) => acc.accountId));
								}}
								displayMode={displayMode}
								initialOptionId={value?.map((val) => val.toString())}
							/>
						)}
					/>
					<Controller
						control={control}
						name="subscriptionIds"
						render={({ field: { onChange, value } }) => (
							<SubscriptionSelect<true>
								label="Subscriptions"
								isMulti
								value={subscriptions}
								onChange={(subs: SubscriptionIndexResponse[]) => {
									setSubscriptions(subs);
									onChange(
										subs.map((subscription) => subscription.subscriptionId)
									);
								}}
								displayMode={displayMode}
								initialOptionId={value}
							/>
						)}
					/>
					<Controller
						control={control}
						name="serviceIds"
						render={({ field: { onChange, value } }) => (
							<ServiceSelect
								label="Services"
								multi
								value={services}
								onChange={(services: ServiceIndexResponse[]) => {
									setServices(services);
									onChange(services.map((service) => service.serviceId));
								}}
								displayMode={displayMode}
								initialOptionId={value?.map((val) => val.toString())}
							/>
						)}
					/>
					<Controller
						control={control}
						name="routePlanIds"
						render={({ field: { onChange, value } }) => (
							<RoutePlanSelect
								label="Route Plans"
								multi
								value={routePlans}
								onChange={(rPlans: RoutePlanIndexResponse[]) => {
									setRoutePlans(rPlans);
									onChange(rPlans.map((routePlan) => routePlan.routePlanId));
								}}
								displayMode={displayMode}
								initialOptionId={value?.map((val) => val.toString())}
							/>
						)}
					/>
				</Col>
				<Col md={6}>
					<h3>Rate Centers</h3>
					<Controller
						control={control}
						name="countryIsoIds"
						render={({ field: { onChange, value } }) => (
							<CountrySelect<true>
								label="Country ISO's"
								isMulti
								value={countries}
								onChange={(ctr) => {
									setCountries(ctr);
									onChange(ctr.map((country) => country.iso3166Alpha3));
								}}
								displayMode={displayMode}
								initialOptionId={value}
							/>
						)}
					/>
					<Controller
						control={control}
						name="rateCenterTypeIds"
						render={({ field: { onChange, value } }) => (
							<RateCenterTypeSelect<true>
								label="Rate Center Types"
								multi
								value={rateCenterTypes}
								onChange={(rtCenters: RateCenterTypes[]) => {
									setRateCenterTypes(rtCenters);
									onChange(rtCenters);
								}}
								displayMode={displayMode}
								initialOptionId={value?.map((val) => val.toString())}
							/>
						)}
					/>
					<Controller
						control={control}
						name="rateCenterIds"
						render={({ field: { onChange, value } }) => (
							<RateCenterSelect
								label="Rate Center"
								multi
								value={rateCenters}
								onChange={(rtCenters: RateCenterIndexResponse[]) => {
									setRateCenters(rtCenters);
									onChange(rtCenters.map((rtCenter) => rtCenter.rateCenterId));
								}}
								displayMode={displayMode}
								initialOptionId={value?.map((val) => val.toString())}
							/>
						)}
					/>
				</Col>
			</Row>
		</RtUiForm>
	);
};
