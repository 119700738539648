import { RateLoadIndetMethod } from 'RtModels';
import { getRateLoadIndetMethodLabel } from 'RtUi/app/AccountManagement/RatePlan/lib/util';
import {
	IDefaultSelectOption,
	ISelectFormControlInstanceProps,
	SelectFormControl
} from 'RtUi/components/ui/SelectFormControl';
import { useMemo } from 'react';

interface IRateLoadIndetMethodSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends ISelectFormControlInstanceProps<
		IDefaultSelectOption<RateLoadIndetMethod>,
		'value',
		IsMulti,
		IsClearable
	> {
	label?: string;
}

export const RateLoadIndetMethodSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>({
	label = 'Indet Method',
	...props
}: IRateLoadIndetMethodSelectProps<IsMulti, IsClearable>) => {
	const options: Array<IDefaultSelectOption<RateLoadIndetMethod>> = useMemo(
		() => [
			{
				label: getRateLoadIndetMethodLabel(RateLoadIndetMethod.InterRate),
				value: RateLoadIndetMethod.InterRate
			},
			{
				label: getRateLoadIndetMethodLabel(RateLoadIndetMethod.IntraRate),
				value: RateLoadIndetMethod.IntraRate
			},
			{
				label: getRateLoadIndetMethodLabel(RateLoadIndetMethod.MaxInterIntra),
				value: RateLoadIndetMethod.MaxInterIntra
			}
		],
		[]
	);

	return (
		<SelectFormControl<
			IDefaultSelectOption<RateLoadIndetMethod>,
			'value',
			IsMulti,
			IsClearable
		>
			label={label}
			valueKey="value"
			labelKey="label"
			options={options}
			{...props}
		/>
	);
};
