/* eslint-disable max-classes-per-file */
import { AuthorizationResponse } from 'RtModels';

export interface IUser {
	emailAddress: string | null;
	loginInfo: AuthorizationResponse | null;
	defaultRespOrgId?: string;
	defaultEntityId?: string;
	isInitialized: boolean;
}

export class TFAChallengeError extends Error {
	constructor(message: string) {
		super(message);

		// Set the prototype explicitly.
		Object.setPrototypeOf(this, new.target.prototype);
	}
}

export class LoginAttemptsError extends Error {
	private deltaAttemptsToShowError = 2;

	constructor(
		private loginAttempts: number,
		private maxLoginAttempts: number
	) {
		super('E-Mail and/or password are incorrect');

		// Set the prototype explicitly.
		Object.setPrototypeOf(this, new.target.prototype);

		const shouldShowAttemptsError =
			maxLoginAttempts - this.deltaAttemptsToShowError <= loginAttempts;
		const attemptsLeft = maxLoginAttempts - loginAttempts;

		if (loginAttempts >= maxLoginAttempts) {
			this.message =
				'Your account has been locked out. See your administrator to unlock.';
		} else if (shouldShowAttemptsError) {
			this.message = `Login has failed ${loginAttempts} times. You have ${attemptsLeft} login attempts left before your account is locked.`;
		}
	}

	public getLoginAttempts() {
		return this.loginAttempts;
	}

	public getMaxLoginAttempts() {
		return this.maxLoginAttempts;
	}
}
