import { ScenarioProfileResponse } from 'RtModels';
import { ScenarioActionsList } from 'RtUi/app/rtSip/Scenarios/lib/components/ScenarioActionsList';
import { ScenarioEditor } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioEditor';
import { Loading } from 'RtUi/components/ui/Loading';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { ScenarioRouter } from './Scenario.router';
import { useGetSipScenario } from 'RtUi/app/rtSip/Scenarios/lib/service';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useEffect, useState } from 'react';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';

export const ScenarioContainer = (): JSX.Element => {
	const sipId = useIdParam();
	const [sipScenario, setSipScenario] = useState<ScenarioProfileResponse>();
	const { data } = useGetSipScenario(Number(sipId));

	useEffect(() => setSipScenario(data), [data]);

	return (
		<>
			{!sipScenario ? (
				<Loading />
			) : (
				<StandardLayout router={ScenarioRouter}>
					<Tabs>
						<TabList>
							<Tab>Actions</Tab>
							<Tab>Label</Tab>
						</TabList>
						<TabPanel>
							<ScenarioActionsList
								profile={sipScenario}
								onUpdate={(scenario) => setSipScenario(scenario)}
							/>
						</TabPanel>
						<TabPanel>
							<ScenarioEditor
								profile={sipScenario}
								onSuccess={(scenario) => setSipScenario(scenario)}
							/>
						</TabPanel>
					</Tabs>
				</StandardLayout>
			)}
		</>
	);
};

ScenarioContainer.displayName = 'ScenarioContainer';
ScenarioRouter.setProfileRtUiFunctionalComponent(ScenarioContainer);
