import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TransactionRouter } from 'RtUi/app/rtSip/Transactions/Transaction.router';
import { TransactionResource } from 'RtUi/app/rtSip/Transactions/lib/resources/TransactionResource';
import { Transaction } from 'RtModels';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { TwoFADisabledWarning } from 'RtUi/components/ui/WarningAlert/TwoFADisabledWarning';
import { TransactionEditor } from 'RtUi/app/rtSip/Transactions/lib/forms/TransactionEditor';

interface ITransactionContainerState {
	profile: Transaction | undefined;
	activeTab: string;
}

@TransactionRouter.getProfileRtUiController()
export class TransactionProfileContainer extends ProfileApplicationContainer<
	{},
	ITransactionContainerState
> {
	public Tabs = TransactionRouter.getProfileTabs();

	public state: ITransactionContainerState = {
		profile: undefined,
		activeTab: this.Tabs.Profile.header
	};

	public componentDidMount() {
		const transactionResource = new TransactionResource();
		const transactionId = this.getIdParam();

		transactionResource.get(transactionId).then((profile) => {
			this.setState({ profile });
		});
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return null;
		}

		return (
			<StandardLayout<Transaction> router={TransactionRouter} profile={profile}>
				<TwoFADisabledWarning />
				<TransactionEditor profile={profile} />
			</StandardLayout>
		);
	}
}
