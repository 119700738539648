import * as React from 'react';
import { Card, Form } from 'react-bootstrap';

interface IFancyFormCheckboxProps {
	header: string;
	label: string;
	value: boolean;
	onChange: (newValue: boolean) => void;
	required?: boolean;
}

export class FancyFormCheckbox extends React.Component<
	IFancyFormCheckboxProps,
	{}
> {
	public render() {
		return (
			<Card className={this.props.value ? 'border-success' : 'border-danger'}>
				<section className="d-flex justify-content-start align-items-stretch">
					<article
						className={
							'd-flex p-2 ' + (this.props.value ? 'bg-success' : 'bg-danger')
						}
					>
						<div className="align-self-center">
							{this.props.value && (
								<i className="fas fa-fw fa-lg fa-check-circle text-white" />
							)}
							{!this.props.value && (
								<i className="fas fa-fw fa-lg fa-exclamation-triangle text-white" />
							)}
						</div>
					</article>
					<article className="card-body">
						<h6>{this.props.header}</h6>
						<Form.Check
							inline
							className={this.props.value ? 'text-success' : 'text-danger'}
							required={this.props.required}
							checked={this.props.value}
							onChange={({ target: { checked } }) =>
								this.props.onChange(checked)
							}
							label={
								<span style={{ userSelect: 'none' }}>{this.props.label}</span>
							}
						></Form.Check>
					</article>
				</section>
			</Card>
		);
	}
}
