import { Fragment, useCallback, useMemo, useState } from 'react';
import {
	GuardianAlertScheduleIndexRequest,
	GuardianAlertScheduleIndexResponse
} from 'RtModels';
import { AlertsScheduleEditor } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/AlertsScheduleEditor';
import { AlertScheduleResource } from 'RtUi/app/rtGuardian/AlertRules/lib/resources/AlertScheduleResource';
import { useGetGuardianAlertSchedules } from 'RtUi/app/rtGuardian/AlertRules/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getAlertRuleSchedulesColumns } from 'RtUi/components/data/DataGrid/configurations/rtGuardian/alertRules';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';

interface IAlertScheduleGridGridProps {
	resourceParams: GuardianAlertScheduleIndexRequest;
	pageName: string;
}

export const AlertScheduleGrid = ({
	resourceParams,
	pageName
}: IAlertScheduleGridGridProps): JSX.Element => {
	const [asideIsOpen, setAsideOpen] = useState(false);
	const [selectedRecord, setSelectedRecord] =
		useState<GuardianAlertScheduleIndexResponse>();

	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetGuardianAlertSchedules(resourceParams);

	const removeEntity = useCallback(
		async (row: GuardianAlertScheduleIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				row.guardianAlertScheduleId.toString()
			);

			if (!confirm) {
				return;
			}

			await new AlertScheduleResource().delete(row.guardianAlertScheduleId);
			refetch();
		},
		[refetch]
	);

	const onRowClick = async (row: GuardianAlertScheduleIndexResponse) => {
		const profile = await new AlertScheduleResource().get(
			row.guardianAlertScheduleId
		);

		setAsideOpen(true);
		setSelectedRecord(profile);
	};

	const onSuccess = () => {
		setAsideOpen(false);
		setSelectedRecord(undefined);
		refetch();
	};

	const onCancel = () => {
		setAsideOpen(false);
		setSelectedRecord(undefined);
	};

	const columns = useMemo(
		() => getAlertRuleSchedulesColumns(removeEntity),
		[removeEntity]
	);

	return (
		<>
			{asideIsOpen && (
				<Aside isOpen={asideIsOpen} disabledBody>
					<Fragment key={selectedRecord?.guardianAlertScheduleId}>
						<Aside.Header
							warning={Boolean(selectedRecord)}
							header="Schedule"
							onClose={onCancel}
						/>
						<AlertsScheduleEditor
							guardianAlertId={resourceParams.guardianAlertId!}
							editMode={selectedRecord}
							createMode={!selectedRecord}
							onCancel={onCancel}
							onSuccess={onSuccess}
						/>
					</Fragment>
				</Aside>
			)}
			<DataGrid<GuardianAlertScheduleIndexResponse>
				loading={isLoading}
				pageName={pageName}
				data={data?.data}
				onRowClick={onRowClick}
				columns={columns}
				onClickCreate={() => setAsideOpen(true)}
				totalRows={data?.totalCount}
				rowThemeColor={(record) => {
					return record.guardianAlertScheduleId ===
						selectedRecord?.guardianAlertScheduleId
						? RowThemeColor.WARNING
						: undefined;
				}}
			/>
			<Confirmation />
		</>
	);
};
