import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { AsrSummaryIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IAsrSummaryRouterTabs extends IProfileApplicationContainerTabs {}

export const AsrSummaryContainerTabs: IAsrSummaryRouterTabs = {};

export class AsrSummaryRouterClass extends SipApplicationRouter<
	AsrSummaryIndexResponse,
	AsrSummaryIndexResponse,
	IAsrSummaryRouterTabs
> {
	constructor() {
		super('ASR Summary', 'asrSummary', 'igcs', AsrSummaryContainerTabs);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.AsrSummary);
	}

	public getProfileLabel(profile: AsrSummaryIndexResponse): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const AsrSummaryRouter = new AsrSummaryRouterClass();
