import { Rt800ResourceIndexResponse } from 'RtModels';
import { Rt800ApiResource } from 'RtUi/app/rtAdmin/lib/resources/Rt800ApiResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface IRt800ApiResourceSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<Rt800ResourceIndexResponse, IsMulti> {}

interface IRt800ApiResourceSelectState
	extends ISelectFormControlState<Rt800ResourceIndexResponse> {}

export class Rt800ApiResourceSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	Rt800ResourceIndexResponse,
	IsMulti,
	IRt800ApiResourceSelectProps<IsMulti>,
	IRt800ApiResourceSelectState
> {
	public resourceClass = Rt800ApiResource;
	public state: IRt800ApiResourceSelectState = {
		formLabel: 'API Resource',
		valueKey: 'resourceId',
		labelKey: 'label'
	};
}
