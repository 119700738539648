import { CarrierRatesParser } from './CarrierRatesParser';
import { ICarrierRatesParseConfig } from './interfaces';
import { ISpreadsheetParseResult } from 'RtUi/utils/file/SpreadsheetParser/SpreadsheetParser';
import { LcrRateSheetType } from 'RtModels';

export class CarrierRatesLataOcnParser extends CarrierRatesParser {
	public readonly LataColumn = this.createParserColumn('LATA', [
		'lata'
	]).setIsRequired(true);
	public readonly OcnColumn = this.createParserColumn('OCN', [
		'ocn',
		'operating company'
	]).setIsRequired(true);

	public readonly RequiredColumns = [
		this.LataColumn,
		this.OcnColumn,
		this.InterRateColumn
	];

	constructor(config: ICarrierRatesParseConfig) {
		super(LcrRateSheetType.LataOcn, config);
	}

	public getRateKey1Index(): number {
		return this.getColumnIndexFor(this.LataColumn);
	}

	public getRateKey2Index(): number {
		return this.getColumnIndexFor(this.OcnColumn);
	}

	public getSummaryForRow(row: ISpreadsheetParseResult): string {
		const summary = super.getSummaryForRow(row);

		if (summary) {
			return summary;
		}

		const lata = this.getRateKey1ForRow(row);
		const ocn = this.getRateKey2ForRow(row);

		return `${lata}-${ocn}`;
	}
}
