import { RateLoadIndetMethod } from 'RtModels';

export const getRateLoadIndetMethodLabel = (
	indet: RateLoadIndetMethod | null
) => {
	switch (indet) {
		case RateLoadIndetMethod.InterRate: {
			return 'Inter Rate';
		}
		case RateLoadIndetMethod.IntraRate: {
			return 'Intra Rate';
		}
		case RateLoadIndetMethod.MaxInterIntra: {
			return 'Max Inter Intra';
		}
		default: {
			return '';
		}
	}
};
