import * as moment from 'moment';
import { FC, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
	EntityIndexResponse,
	NumberGroupIndexResponse,
	RespOrgIndexResponse,
	TaskIndexRequest,
	TaskTypeIndexResponse,
	TemplateIndexResponse
} from 'RtModels';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { NumberGroupSelect } from 'RtUi/app/rt800/NumberGroups/lib/controls/NumberGroupSelect';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { IMgiTaskLabelType } from 'RtUi/app/rt800/Tasks/lib/interfaces';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { TaskAction } from './lib/components/TaskAction';
import { TasksGrid } from './lib/grids/TasksGrid';
import { TaskTypeSelect } from './lib/TaskTypeSelect';
import { TaskRouter } from './Task.router';
import { useReplaceWindowUrl } from 'RtUi/utils/hooks/useReplaceWindowUrl';

type ReferencedTypeT = 'tfn' | 'templateName';

export const TaskIndexContainer: FC<React.PropsWithChildren<unknown>> = () => {
	const tabsFromOptions = useMemo(
		() => TaskRouter.getIndexMgiTaskOptionTabs(),
		[]
	);

	const [tabs, activeTab, setActiveTab] = useTabs(
		{
			Search: { header: 'Search' },
			Results: { header: 'Results', isDefault: true }
		},
		tabsFromOptions
	);

	const [referencedBy, setReferencedBy] = useState<ReferencedTypeT>('tfn');
	const [entitySearch, setEntitySearch] = useState<EntityIndexResponse>();
	const [respOrgSearch, setRespOrgSearch] = useState<RespOrgIndexResponse>();
	const [scheduledAfterTs, setScheduledAfterTs] = useState<moment.Moment>(
		moment().subtract(14, 'days').startOf('day')
	);
	const [scheduledBeforeTs, setScheduledBeforeTs] = useState<moment.Moment>();
	const [taskTypes, setTaskTypes] = useState<TaskTypeIndexResponse[]>([]);
	const [tfn, setTfn] = useState<string>();
	const [template, setTemplate] = useState<TemplateIndexResponse>();
	const [numberGroup, setNumberGroup] = useState<NumberGroupIndexResponse>();

	const resourceParams: TaskIndexRequest = {};

	if (entitySearch) {
		resourceParams.entityId = entitySearch.entityId;
	}

	if (respOrgSearch) {
		resourceParams.respOrgId = respOrgSearch.respOrgId;
	}

	if (numberGroup) {
		resourceParams.numberGroupId = numberGroup.numberGroupId;
	}

	if (taskTypes.length > 0) {
		resourceParams.taskTypeIds = taskTypes.map(
			(taskType) => taskType.taskTypeId
		);
	}

	if (referencedBy) {
		if (referencedBy === 'tfn' && tfn) {
			resourceParams.referenceKey = tfn.replace(/\-/g, '');
		}

		if (referencedBy === 'templateName' && template) {
			resourceParams.referenceKey = template.templateName;
		}
	}

	if (scheduledAfterTs) {
		resourceParams.scheduledAfterTs = scheduledAfterTs.toDate();
	}

	if (scheduledBeforeTs) {
		resourceParams.scheduledBeforeTs = scheduledBeforeTs.toDate();
	}

	const tabsFromOptionsValue = Object.values(tabsFromOptions);
	useReplaceWindowUrl(activeTab.header, resourceParams);

	return (
		<TabbedLayout
			router={TaskRouter}
			actionsHeader="Create New Task"
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					wide
					onSubmit={(evt) => {
						evt.preventDefault();

						setActiveTab(tabs.Results.header);
					}}
				>
					<Row>
						<Col lg>
							<EntitySelect
								onChange={(entitySearch) => {
									setEntitySearch(entitySearch);
								}}
								value={entitySearch}
							/>
							<RespOrgSelect
								onChange={(respOrgSearch) => {
									setRespOrgSearch(respOrgSearch);
								}}
								value={respOrgSearch}
							/>
							<RadioFormControl<ReferencedTypeT>
								label="Referenced By"
								value={referencedBy}
								onChange={(referencedBy) => {
									setReferencedBy(referencedBy);
								}}
								options={[
									{
										value: 'tfn',
										label: 'Toll-Free Number'
									},
									{
										value: 'templateName',
										label: 'Template'
									}
								]}
							/>
							{referencedBy === 'tfn' && (
								<TollFreeNumberFormControl
									onChange={(tfn) => {
										setTfn(tfn);
									}}
									value={tfn || ''}
								/>
							)}
							{referencedBy === 'templateName' && (
								<TemplateSelect
									onChange={(template) => {
										setTemplate(template);
									}}
									value={template}
								/>
							)}
						</Col>
						<Col lg>
							<TaskTypeSelect<true>
								multi
								onChange={(taskTypes) => {
									setTaskTypes(taskTypes);
								}}
								value={taskTypes}
							/>
							<NumberGroupSelect
								onChange={(numberGroup) => {
									setNumberGroup(numberGroup);
								}}
								value={numberGroup}
							/>
							<DatetimeFormControl
								label="Scheduled After"
								onChangeWithIsNow={(scheduledAfterTs) => {
									setScheduledAfterTs(scheduledAfterTs!);
								}}
								value={scheduledAfterTs}
								disablePresets
							/>
							<DatetimeFormControl
								label="Scheduled Before"
								onChangeWithIsNow={(scheduledBeforeTs) => {
									setScheduledBeforeTs(scheduledBeforeTs);
								}}
								value={scheduledBeforeTs}
								disablePresets
							/>
						</Col>
					</Row>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<TasksGrid autoFocusFilter resourceParams={resourceParams} />
			</TabbedLayoutTab>
			{tabsFromOptionsValue.map((actionTabAction) => (
				<TabbedLayoutTab
					header={actionTabAction.header}
					isAction
					key={actionTabAction.header}
				>
					<Row>
						<TaskAction type={actionTabAction.header as IMgiTaskLabelType} />
					</Row>
				</TabbedLayoutTab>
			))}
		</TabbedLayout>
	);
};

TaskRouter.setIndexRtUiFunctionalComponent(TaskIndexContainer, {
	description: 'Managed the lifecycle of a Toll-Free from search to spare.'
});
