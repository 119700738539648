import { FC, useState } from 'react';
import { UserIndexResponse, UserProfileResponse } from 'RtModels';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { Alert, Button, Form, ProgressBar } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import { generateShortUUID } from 'RtUi/utils/http/resources/utils';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { useHarmonicIntervalFn } from 'react-use';
import { UserActions } from 'RtUi/state/actions/user';
import { formatDuration, intervalToDuration } from 'date-fns';

type MyProfileTFAFormProps = {
	user: UserIndexResponse;
	onUpdate: (newUser: UserProfileResponse) => void;
};

const refreshTtlMs = 180_000;

export const MyProfileTFAForm: FC<
	React.PropsWithChildren<MyProfileTFAFormProps>
> = ({ user, onUpdate }) => {
	const [totpCode, setTotpCode] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [error, setError] = useState<any>();
	const [refreshTime, setRefreshTime] = useState(refreshTtlMs);
	const [isQrCodeExpired, setQrCodeExpired] = useState<boolean>(false);
	const [tfaSecret, setTfaSecret] = useState(
		generateShortUUID({ noPaddingChar: true })
	);

	useHarmonicIntervalFn(() => {
		if (refreshTime <= 0) {
			setQrCodeExpired(true);
		} else {
			setRefreshTime(refreshTime - 1000);
		}
	}, 1000);

	const refreshQrCode = () => {
		setTfaSecret(generateShortUUID({ noPaddingChar: true }));
		setTotpCode('');
		setRefreshTime(refreshTtlMs);
		setQrCodeExpired(false);
	};

	const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		setIsSubmitting(true);
		setError(undefined);

		try {
			const newUser = await UserActions.enableTFA({
				tfaSecret,
				totpCode
			});

			setTotpCode('');
			setIsSubmitting(false);
			onUpdate(newUser);
		} catch (error) {
			setError(error);
			setIsSubmitting(false);
		}
	};

	return (
		<RtUiWideForm
			onSubmit={onSubmit}
			displayMode={false}
			createMode={true}
			error={error}
			submitButtonText="Turn On"
			isSubmitting={isSubmitting}
		>
			<Alert variant="info">
				<i className="fas fa-fw fa-info-circle" />
				<span>
					&nbsp;&nbsp;Two-Factor Authentication provides another layer of
					security for your account.
				</span>
			</Alert>
			<section className="mb-3">
				In addition to your email address and password, you&apos;ll need to
				enter a code displayed in an app on your phone.
			</section>
			<section className="mb-3">
				<header className="mb-1">
					<b>1.&nbsp;</b> You&apos;ll need to install a two-factor
					authentication application on your smartphone or tablet. Example
					applications include, but are not limited to:
				</header>
				<ul>
					<li>
						<span>Google Authenticator&nbsp;</span>(
						<a
							className="hyperlink"
							target="_blank"
							rel="noreferrer"
							href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
						>
							Android Link
						</a>
						&nbsp;/&nbsp;
						<a
							className="hyperlink"
							target="_blank"
							rel="noreferrer"
							href="https://itunes.apple.com/app/google-authenticator/id388497605"
						>
							Apple Link
						</a>
						)
					</li>
					<li>
						<span>Authy&nbsp;</span>(
						<a
							className="hyperlink"
							target="_blank"
							rel="noreferrer"
							href="https://play.google.com/store/apps/details?id=com.authy.authy"
						>
							Android Link
						</a>
						&nbsp;/&nbsp;
						<a
							className="hyperlink"
							target="_blank"
							rel="noreferrer"
							href="https://itunes.apple.com/app/authy/id494168017"
						>
							Apple Link
						</a>
						)
					</li>
					<li>
						Any other Time-based One-Time Password (TOTP) RFC6238-compliant
						application
					</li>
				</ul>
			</section>
			<section className="mb-3">
				<b>2.&nbsp;</b>Scan the QR Code™ using your app. The code displayed is
				your personal code. No two people are issued the same code. This ensures
				that you, and only you, are able to enter the proper code, when
				required. If you cannot scan the QR Code™, enter the text code below.
			</section>
			<section className="mb-3 d-flex justify-content-start align-items-center">
				{isQrCodeExpired && (
					<>
						<Button
							type="button"
							variant="danger"
							onClick={() => {
								refreshQrCode();
							}}
						>
							Create new QR Code
						</Button>
						<div className="ms-3 d-flex flex-column">
							<strong className="text-danger">QR Code™ has expired</strong>
						</div>
					</>
				)}
				{!isQrCodeExpired && (
					<>
						<QRCodeCanvas
							value={`otpauth://totp/RouteTrust:${user.email}?issuer=RouteTrust&secret=${tfaSecret}`}
						/>
						<div className="ms-3 d-flex flex-column">
							<strong className="mb-4">
								{/* Chunk the tfa for ease of use */}
								Text Code: {tfaSecret.match(/.{1,4}/g)?.join(' ')}
							</strong>
							<strong className="mb-1 text-warning">
								QR Code™ will refresh every 3 minutes for security purposes.
							</strong>
							<section className="w-100">
								<ProgressBar
									variant="warning"
									now={refreshTime}
									max={refreshTtlMs}
									label={formatDuration(
										intervalToDuration({ start: 0, end: refreshTime })
									)}
								/>
							</section>
						</div>
					</>
				)}
			</section>
			<section className="mb-3">
				<b>3.&nbsp;</b>Type the code displayed in your app in the box below to
				confirm setup.
			</section>
			<Form.Group className="d-flex mb-3">
				<InputFormControl
					label="6-Digit code:"
					autoFocus
					hideLabel={true}
					displayMode={false}
					required={true}
					minLength={6}
					maxLength={6}
					onChange={setTotpCode}
					value={totpCode}
				/>
			</Form.Group>
		</RtUiWideForm>
	);
};
