import { BirdsEyeIndexResponse, TimeRange } from 'RtModels';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { getBirdEyeColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/birdeye';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import DataGrid from 'RtUi/components/data/DataGrid';

interface BirdReportGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<BirdsEyeIndexResponse> | null;
	isLoading?: boolean;
	timeRange: TimeRange;
	onClickSubscriptionColumn: (row: BirdsEyeIndexResponse) => void;
	gridType: string;
}

export const BirdReportGrid = ({
	resource,
	isLoading,
	timeRange,
	onClickSubscriptionColumn,
	gridType
}: BirdReportGridProps) => {
	const { data } = useQuery<BirdsEyeIndexResponse[] | undefined, Error>(
		[`useGetBirdeyeReport`, resource],
		() => resource?.getAll()
	);

	const [columns, setColumns] = useState<
		Array<DataGridColumn<BirdsEyeIndexResponse>>
	>([]);

	const currentResource = useRef<HttpResource<BirdsEyeIndexResponse> | null>(
		null
	);

	useEffect(() => {
		if (currentResource.current !== resource) {
			currentResource.current = resource;
			const newColumns = getBirdEyeColumns(
				timeRange,
				onClickSubscriptionColumn,
				gridType
			);
			setColumns(newColumns);
		}
	}, [
		resource,
		currentResource,
		timeRange,
		onClickSubscriptionColumn,
		gridType
	]);

	return (
		<DataGrid<BirdsEyeIndexResponse>
			data={data}
			loading={isLoading}
			pageName="rtVue_birdeye"
			columns={columns}
			totalRows={resource?.getTotalDbCount()}
		/>
	);
};
