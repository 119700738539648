import * as React from 'react';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { CdrExportSourceTypes } from 'RtModels';

interface ICdrExportTypeIdsControlProps {
	value: CdrExportSourceTypes | undefined;
	onChange: (newValue: CdrExportSourceTypes | undefined) => void;
	disableOptions?: CdrExportSourceTypes[];
	label?: string;
	required?: boolean;
	displayMode?: boolean;
	noneOption?: boolean;
}

interface ICdrExportTypeOptions {
	value: CdrExportSourceTypes | undefined;
	label?: string;
	disabled?: boolean;
}

export class CdrExportTypeIdsControl extends React.Component<
	ICdrExportTypeIdsControlProps,
	{}
> {
	public render() {
		const {
			label = 'CDR Type',
			disableOptions,
			noneOption = false
		} = this.props;

		const options: ICdrExportTypeOptions[] = [
			{
				value: CdrExportSourceTypes.Account,
				label: 'Account',
				disabled: disableOptions?.includes(CdrExportSourceTypes.Account)
			},
			//{ value: CdrExportSourceTypes.Invoice, label: 'Invoice' },
			{
				value: CdrExportSourceTypes.Subscription,
				label: 'Subscription',
				disabled: disableOptions?.includes(CdrExportSourceTypes.Subscription)
			}
		];

		if (noneOption) {
			options.unshift({
				value: undefined,
				label: 'None'
			});
		}

		return (
			<RadioFormControl<CdrExportSourceTypes | undefined>
				label={label}
				required={this.props.required}
				displayMode={this.props.displayMode}
				value={this.props.value}
				onChange={(newValue) => this.props.onChange(newValue)}
				options={options}
			/>
		);
	}
}
