/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $BlockedNumberUpdateRequest = {
    properties: {
        phoneNumber: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        blockAni: {
            type: 'number',
            isRequired: true,
        },
        blockDestination: {
            type: 'number',
            isRequired: true,
        },
    },
};