import React from 'react';
import { Form } from 'react-bootstrap';

interface ProperInputProps {
	label: string;
	onChange?: (value: string) => void;
	value: any;
	disabled?: boolean;
	style?: React.CSSProperties;
}

const ProperInput = (props: ProperInputProps) => {
	const { label, onChange, value, disabled = false, style = {} } = props;

	return (
		<div className="mb-3" style={{ margin: '5px', ...style }}>
			<Form.Label>{label}</Form.Label>
			<Form.Control
				disabled={disabled}
				onChange={(e) => onChange && onChange(e.target.value)}
				value={value}
			/>
		</div>
	);
};

export default ProperInput;
