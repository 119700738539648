import { extend, cloneDeep } from 'lodash-es';

/**
 * Immutable Map
 */
export default class ImmutableMap<IMap> {
	constructor(private data: IMap) {}

	/**
	 * Get a copy of an attribute from given key
	 * @param key
	 */
	public get<K extends keyof IMap>(key: K): IMap[K] {
		const singleData = this.data[key];

		return this.cloneIfExists(singleData);
	}

	/**
	 * Get copy of nested value
	 * @param key
	 * @param innerKey
	 */
	public getNested<K extends keyof IMap, L extends keyof IMap[K]>(
		key: K,
		innerKey: L
	): IMap[K][L] {
		const singleData = this.data[key][innerKey];

		return this.cloneIfExists(singleData);
	}

	/**
	 * Get copy of nested nested value
	 * @param key
	 * @param innerKey
	 */
	public getNestedNested<
		K extends keyof IMap,
		L extends keyof IMap[K],
		M extends keyof IMap[K][L]
	>(key: K, innerKey: L, innerInnerKey: M): IMap[K][L][M] {
		const singleData = this.data[key][innerKey][innerInnerKey];

		return this.cloneIfExists(singleData);
	}

	public getNative<K extends keyof IMap>(key: K): IMap[K] {
		return this.data[key];
	}

	/**
	 * Get several keys attributes at once
	 * @param keys
	 */
	public getSome<K extends keyof IMap>(...keys: K[]): Pick<IMap, K> {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const subState = {} as Pick<IMap, K>;

		keys.forEach((key) => (subState[key] = this.get(key)));

		return subState;
	}

	/**
	 * Returns a new Map with the updated key/value
	 * @param updatedSubState
	 */
	public update<K extends keyof IMap>(
		updatedSubState: Pick<IMap, K>
	): ImmutableMap<IMap> {
		const newData = extend({}, this.data, updatedSubState);

		return new ImmutableMap(newData);
	}

	/**
	 * Copy entire data set
	 */
	public clone() {
		const newDataSet = this.cloneIfExists(this.data);

		return new ImmutableMap(newDataSet);
	}

	/**
	 * Returns a deep clone for objects, the value otherwise
	 * @param data
	 */
	private cloneIfExists<T>(data: T) {
		if (typeof data === 'object' && data !== null) {
			data = cloneDeep(data);
		}

		return data;
	}
}
