/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipTraceIndexRequest = {
    properties: {
        gatewayId: {
            type: 'number',
            isRequired: true,
        },
        startTime: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTime: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        sipFromUser: {
            type: 'string',
        },
        sipToUser: {
            type: 'string',
        },
        srcIp: {
            type: 'string',
        },
        dstIp: {
            type: 'string',
        },
        sipMethod: {
            type: 'SipMethod',
        },
        callId: {
            type: 'string',
        },
        limit: {
            type: 'number',
        },
        orLogic: {
            type: 'boolean',
        },
    },
};