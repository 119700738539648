import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';

export interface TaskErrorWarningDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const TaskErrorWarningDataGridColumn = <T = any,>({
	size = 80,
	...config
}: TaskErrorWarningDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const renderValue = (value: boolean) => {
		const label = value ? 'Warning' : 'Error';
		const theme = value ? 'warning' : 'danger';

		return (
			<section className="d-flex">
				<div
					role="alert"
					style={{ width: '60px', textAlign: 'center' }}
					className={`alert-sm mb-0 p-0 alert alert-${theme}`}
				>
					<span>{label}</span>
				</div>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		exportValue: (value: T[keyof T]) => (value ? 'Yes' : 'No'),
		getValue: ({ cell }) => renderValue(cell.getValue<boolean>())
	});
};
