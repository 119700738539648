import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { FileProcessIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { UserNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';
import { LrnLookupDownloadColumn } from 'RtUi/components/data/DataGrid/columns/LrnLookupDownloadDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';

export const getLrnLookupColumns = (): Array<
	DataGridColumn<FileProcessIndexResponse>
> => [
	LrnLookupDownloadColumn({
		header: 'Download'
	}),
	IdDataGridColumn({
		accessorKey: 'fileProcessId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'inputFileNameLocal',
		header: 'Input File Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'outputFileNameRemote',
		header: 'Output file name'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'completedTs',
		header: 'Completed'
	}),
	UserNameDataGridColumn({
		accessorKey: 'createdBy',
		header: 'Created By'
	})
];
