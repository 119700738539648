import {
	DataFilter,
	DataFilterOperator,
	DataSources,
	NumbersIndexResponseData,
	ReportAggregates,
	Reports
} from 'RtModels';
import { CdrSearchViewRouter } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.router';
import { VueNumberViewRouter } from 'RtUi/app/rtVue/NumbersView/VueNumberView.router';
import { VueNumberDataGrid } from 'RtUi/app/rtVue/NumbersView/lib/grids/VueNumberDataGrid';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { RtVueReportUrlSearchParamsCreate } from 'RtUi/app/rtVue/common/lib/containers/lib/RtVueReportUrlSearchParams';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import * as React from 'react';

@VueNumberViewRouter.getIndexRtUiController({
	groupName: 'Business Reports',
	groupId: 'VueBusinessReports',
	orderPriority: 2,
	groupOrderPriority: 20
})
export class VueNumberViewIndexContainer extends RtVueReportContainer<NumbersIndexResponseData> {
	public state: IRtVueContainerState<NumbersIndexResponseData> = {
		...this.getBaseState(),
		dataSums: [ReportAggregates.Asr]
	};

	protected reportId = Reports.Numbers;
	protected router = VueNumberViewRouter;

	protected async getResource(): Promise<
		HttpResource<NumbersIndexResponseData>
	> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		} = this.state;

		const vueHttp = new RtVueHttp();
		const { data, totalCount } = await vueHttp.getNumbersDetails(
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		);

		const resource = new ArrayResource('callDate', data.data);
		resource.setTotalCount(totalCount);

		return resource;
	}

	protected async getExportData(): Promise<NumbersIndexResponseData[]> {
		const {
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns
		} = this.state;

		const indexes = await new RtVueHttp().getNumbersDetails(
			timeRange,
			filters,
			partition,
			preset,
			dataSums,
			additionalColumns,
			undefined,
			undefined,
			250_000
		);

		return indexes.data.data;
	}

	protected onDrillDown = (row: NumbersIndexResponseData, key: string) => {
		const drillDownParams: RtVueReportUrlSearchParamsCreate = {
			date: row.callDate
		};

		if (row.fileStreamId) {
			drillDownParams.fileStreamId = String(row.fileStreamId);
		}

		const operands = [
			{
				dataSource: DataSources.Text,
				value: row.number
			}
		];

		const filter: DataFilter = {
			key,
			operands,
			operator: DataFilterOperator.Begins
		};

		const filters: DataFilter[] = [filter];

		const drillDownPath = CdrSearchViewRouter.getDrillDownPath(
			drillDownParams,
			filters,
			this.getTimeRange()
		);

		ApplicationContainer.openNewTab(drillDownPath);
	};

	protected renderReports(): React.ReactNode {
		return (
			<>
				<VueNumberDataGrid
					isLoading={this.state.loadingReport}
					defaultSorting={this.state.defaultSorting}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
					callDateFormat={this.getCallDateFormat()}
					onDrillDown={this.onDrillDown}
					getExportData={() => this.getExportData()}
				/>
			</>
		);
	}
}
