/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianFraudSimulatorResponse = {
    properties: {
        score: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'Array',
            isRequired: true,
        },
        forceAllow: {
            type: 'number',
            isRequired: true,
        },
        block: {
            type: 'number',
            isRequired: true,
        },
        calculatedScore: {
            type: 'number',
            isRequired: true,
        },
    },
};