/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TollFreeSearchRequest = {
    properties: {
        respOrgId: {
            type: 'string',
        },
        pattern: {
            type: 'string',
            isRequired: true,
        },
        quantity: {
            type: 'number',
            maximum: 10,
            minimum: 1,
        },
        sequential: {
            type: 'number',
            maximum: 1,
        },
        allowSuggestions: {
            type: 'number',
            maximum: 1,
        },
    },
};