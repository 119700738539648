/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/type-annotation-spacing */
import { MouseEvent, FC, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import {
	DataFilter,
	DataFilterOperator,
	DataSources,
	FileStreamIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { createOptionsFromList } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/ParametersFilteringControl';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

export const CriteriaOptions = createOptionsFromList([
	['Cost subscription', 'cost_subscription_id'],
	['Revenue Subscription', 'revenue_subscription_id'],
	['Egress Trunk Group', 'egress_trunk_group_id'],
	['Ingress Trunk Group', 'ingress_trunk_group_id'],
	['File Name', 'file_name'],
	['File Stream', 'file_stream_id'],
	['Dialed Number', 'to_raw']
]);

interface ICriteriaControlProps {
	displayMode: boolean;
	filter: DataFilter;
	onChange: (filter: DataFilter) => void;
	onDelete: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const CriteriaControl: FC<
	React.PropsWithChildren<ICriteriaControlProps>
> = ({ displayMode, filter, onChange, onDelete }) => {
	const [criteriaFieldOption, setCriteriaFieldOption] = useState<
		ISimpleSelectFormControlOption<string>
	>(CriteriaOptions.filter((c) => c.value === filter.key)[0]);
	const [selectedCriteriaOperator, setSelectedCriteriaOperator] =
		useState<ISimpleSelectFormControlOption<DataFilterOperator>>();
	const [criteriaValue, setCriteriaValue] = useState<string | string[]>(
		filter.operands.length > 1
			? filter.operands.map((op) => op.value)
			: filter.operands[0]
				? filter.operands[0].value
				: ''
	);
	const [subscriptionsSelect, setSubscriptionsSelect] =
		useState<SubscriptionIndexResponse[]>();
	const [fileStreamsSelect, setFileStreamsSelect] =
		useState<FileStreamIndexResponse[]>();

	const getOnChangeObject = () => ({
		key: criteriaFieldOption?.value ?? filter.key,
		operator: selectedCriteriaOperator?.value ?? filter.operator
	});

	const renderControl = () => {
		if (
			criteriaFieldOption?.value &&
			criteriaFieldOption.value.toLowerCase().includes('subscription')
		) {
			return (
				<SubscriptionSelect<true>
					isMulti
					required
					hideLabel
					label=""
					displayMode={displayMode}
					onChange={(subscriptions) => {
						onChange({
							...getOnChangeObject(),
							operands: subscriptions.map((subscription: any) => ({
								dataSource: DataSources.Text,
								value: String(subscription.subscriptionId)
							}))
						});
						setSubscriptionsSelect(subscriptions);
					}}
					value={subscriptionsSelect}
					initialOptionId={(criteriaValue as string[]).map((c) => Number(c))}
				/>
			);
		}

		if (
			criteriaFieldOption?.value &&
			criteriaFieldOption.value.toLowerCase().includes('file_stream')
		) {
			return (
				<FileStreamSelect<true>
					autoHeight
					label=""
					multi
					hideLabel
					displayMode={displayMode}
					onChange={(fileStreams) => {
						onChange({
							...getOnChangeObject(),
							operands: fileStreams.map((fileStream: any) => ({
								dataSource: DataSources.Text,
								value: String(fileStream.fileStreamId)
							}))
						});
						setFileStreamsSelect(fileStreams);
					}}
					value={fileStreamsSelect}
					initialOptionId={criteriaValue as string[]}
				/>
			);
		}

		return (
			<SimpleSelectFormControl<string, true>
				label=""
				creatable
				multi
				required
				hideLabel
				placeholder="Type and select value"
				openMenuOnClick={false}
				openMenuOnFocus={false}
				value={createOptionsFromList(
					Array.isArray(criteriaValue)
						? criteriaValue
						: criteriaValue
							? [criteriaValue]
							: undefined
				)}
				onChange={(criteria) => {
					onChange({
						...getOnChangeObject(),
						operands: criteria.map((val: any) => ({
							dataSource: DataSources.Text,
							value: String(val.value)
						}))
					});
					setCriteriaValue(criteria.map((c: any) => c.value));
				}}
				displayMode={displayMode}
			/>
		);
	};

	return (
		<Row className="row-sm form-group">
			<Col lg={4}>
				<SimpleSelectFormControl<string, false>
					className="parameter-select"
					key="data-filters"
					label=""
					hideLabel
					required
					options={CriteriaOptions}
					value={criteriaFieldOption}
					onChange={(criteriaFieldOptionValue) => {
						onChange({
							...getOnChangeObject(),
							operands: [
								{
									dataSource: DataSources.Text,
									value: ''
								}
							],
							key: criteriaFieldOptionValue.value
						});
						setCriteriaValue('');
						setCriteriaFieldOption(criteriaFieldOptionValue);
					}}
					initialOptionId={filter.key}
					displayMode={displayMode}
				/>
			</Col>
			<Col lg={3}>
				<SimpleSelectFormControl<DataFilterOperator, false>
					className="parameter-select"
					key="having-fields"
					label=""
					hideLabel
					clearable={false}
					options={[
						{
							label: 'In',
							value: DataFilterOperator.In
						}
					]}
					displayMode={displayMode}
					value={selectedCriteriaOperator}
					onChange={(selectedCriteriaOperatorValue) => {
						onChange({
							...getOnChangeObject(),
							operands: [
								{
									dataSource: DataSources.Text,
									value: criteriaValue as string
								}
							],
							operator: selectedCriteriaOperatorValue.value
						});
						setSelectedCriteriaOperator(selectedCriteriaOperatorValue);
					}}
					initialOptionId={filter.operator}
				/>
			</Col>
			<Col lg={4}>{renderControl()}</Col>
			<Col lg={1}>
				{!displayMode && (
					<Button
						size="sm"
						variant="white-alt"
						className="mt-1"
						onClick={onDelete}
					>
						<i className="fas fa-fw fa-trash" />
					</Button>
				)}
			</Col>
		</Row>
	);
};
