import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import {
	CdrExportTypeResource,
	ICdrExportTypeIndex
} from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportTypeResource';

interface ICdrExportTypeSelectProps
	extends ISelectFormControlProps<ICdrExportTypeIndex> {}

export class CdrExportTypeSelect extends SelectFormControl<
	ICdrExportTypeIndex,
	false,
	ICdrExportTypeSelectProps
> {
	public resourceClass = CdrExportTypeResource;
	public state: ISelectFormControlState<ICdrExportTypeIndex> = {
		formLabel: 'Export Type',
		valueKey: 'exportTypeId',
		labelKey: 'exportTypeName'
	};

	public optionRenderer = (option: ICdrExportTypeIndex) => (
		<span>{option.exportTypeDescription}</span>
	);
}
