import { TransactionIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { TransactionTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TransactionTypeDataGridColumn';

export const getTransactionsColumns = (): Array<
	DataGridColumn<TransactionIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'transactionId',
		header: 'Trans. Id'
	}),
	DateDataGridColumn({
		accessorKey: 'transactionTime',
		header: 'Trans. Time'
	}),
	DateDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Posted Time'
	}),
	AccountDataGridColumn({
		accessorKey: 'accountId',
		header: 'Account'
	}),
	TransactionTypeDataGridColumn({
		accessorKey: 'transactionTypeId',
		header: 'Type'
	}),
	IntegerDataGridColumn({
		accessorKey: 'amount',
		header: 'Amount'
	}),
	IntegerDataGridColumn({
		accessorKey: 'appliedAmount',
		header: 'Applied'
	}),
	DefaultDataGridColumn({
		accessorKey: 'externalRef1',
		header: 'Reference 1'
	}),
	DefaultDataGridColumn({
		accessorKey: 'externalRef2',
		header: 'Reference 2'
	}),
	DefaultDataGridColumn({
		accessorKey: 'comments',
		header: 'Comments'
	})
];
