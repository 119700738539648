/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TollFreeProfileResponse = {
    properties: {
        tfn: {
            type: 'string',
            isRequired: true,
        },
        statusTs: {
            type: 'Date',
            format: 'date-time',
        },
        tfnStatusId: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
        },
        templateName: {
            type: 'string',
        },
        interLataCarriers: {
            type: 'string',
        },
        intraLataCarriers: {
            type: 'string',
        },
        effectiveTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastUpdateTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastAssignTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastDisconnectTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastPendingTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastReserveTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastSpareTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastTransitionalTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastUnavailableTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastWorkingTs: {
            type: 'Date',
            format: 'date-time',
        },
        lastWorkingRespOrgId: {
            type: 'string',
        },
        lastWorkingDays: {
            type: 'number',
        },
        totalWorkingDays: {
            type: 'number',
        },
        availableTs: {
            type: 'Date',
            format: 'date-time',
        },
    },
};