import { Button } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';

interface ISubmitButtonProps {
	variant?: ButtonVariant;
	disabled?: boolean;
	isSubmitting?: boolean;
	icon?: string;
	text?: string;
	onClick?: () => void;
}

export const SubmitButton = ({
	variant = 'submit',
	disabled = false,
	isSubmitting = false,
	icon = 'fa-check',
	text = 'Submit',
	onClick
}: ISubmitButtonProps) => {
	const currentIcon = isSubmitting ? (
		<i className="fas fa-cog fa-cog fa-spin" />
	) : (
		<i className={`fas fa-fw ${icon}`} />
	);

	return (
		<Button
			variant={variant}
			className="btn-submit"
			type="submit"
			disabled={disabled}
			onClick={onClick}
		>
			{currentIcon}
			<span>&nbsp;{text}</span>
		</Button>
	);
};
