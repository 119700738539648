/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipConnectionCreateRequest = {
    properties: {
        gatewayId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        hostAddress: {
            type: 'string',
            isRequired: true,
        },
        hostCidr: {
            type: 'string',
            isRequired: true,
        },
        hostPort: {
            type: 'number',
            isRequired: true,
        },
        techPrefix: {
            type: 'string',
            isRequired: true,
        },
        nanpFormat: {
            type: 'string',
            isRequired: true,
        },
        idddFormat: {
            type: 'string',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        mediaServerGroupId: {
            type: 'number',
            isNullable: true,
        },
    },
};