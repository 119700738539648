import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { FileStreamIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { JSONDataGridColumn } from 'RtUi/components/data/DataGrid/columns/JSONDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';

export const getFileStreamsColumns = (): Array<
	DataGridColumn<FileStreamIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'fileStreamId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'description',
		header: 'Description'
	}),
	DefaultDataGridColumn({
		accessorKey: 'fileLabel',
		header: 'File Label'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	IdDataGridColumn({
		accessorKey: 'fileTypeId',
		header: 'File Type Id'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isDownload',
		header: 'Is Download'
	}),
	DefaultDataGridColumn({
		accessorKey: 'downloadRemoteProtocol',
		header: 'Download Remote Protocol'
	}),
	JSONDataGridColumn({
		accessorKey: 'downloadRemoteCredentials',
		header: 'Download Remote Credentials'
	}),
	DefaultDataGridColumn({
		accessorKey: 'downloadRemotePath',
		header: 'Download Remote Path'
	}),
	IntegerDataGridColumn({
		accessorKey: 'downloadRemoteDelete',
		header: 'Download Remote Delete'
	}),
	DefaultDataGridColumn({
		accessorKey: 'downloadCompressionType',
		header: 'Download Compression Type'
	}),
	DefaultDataGridColumn({
		accessorKey: 'downloadFileRegex',
		header: 'Download File Regex'
	}),
	DefaultDataGridColumn({
		accessorKey: 'downloadLocalPath',
		header: 'Download Local Path'
	}),
	DefaultDataGridColumn({
		accessorKey: 'workingLocalPath',
		header: 'Working Local Path'
	}),
	DefaultDataGridColumn({
		accessorKey: 'workingOutputPath',
		header: 'Working Output Path'
	}),
	DefaultDataGridColumn({
		accessorKey: 'topicRawData',
		header: 'Topic Raw Data'
	}),
	IntegerDataGridColumn({
		accessorKey: 'topicRawDataPeakCps',
		header: 'Topic Raw Data Peak Cps'
	}),
	DefaultDataGridColumn({
		accessorKey: 'topicTransformedData',
		header: 'Topic Transformed Data'
	}),
	DefaultDataGridColumn({
		accessorKey: 'outputType',
		header: 'Output Type'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isLoadRaw',
		header: 'Is Load Raw'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isNetworkReporting',
		header: 'Is Network Reporting'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isFinancialReporting',
		header: 'Is Financial Reporting'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isFraud',
		header: 'Is Fraud'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isArchived',
		header: 'Is Archived'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isEnhancedTfReporting',
		header: 'TF Enhanced'
	}),
	DefaultDataGridColumn({
		accessorKey: 'archiveBucket',
		header: 'Archive Bucket'
	}),
	IntegerDataGridColumn({
		accessorKey: 'archiveDelay',
		header: 'Archive Delay'
	}),
	IntegerDataGridColumn({
		accessorKey: 'cdrRetention',
		header: 'Cdr Retention'
	}),
	IntegerDataGridColumn({
		accessorKey: 'reportingRetention',
		header: 'Reporting Retention'
	}),
	IntegerDataGridColumn({
		accessorKey: 'archiveRetention',
		header: 'Archive Retention'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updateTs',
		header: 'Last Updated'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'pauseTs',
		header: 'Pause'
	})
];
