import isEmpty from 'lodash-es/isEmpty';
import { useEffect } from 'react';
import {
	ResourceIndexRequest,
	ServiceNumberPortRequestIndexRequest
} from 'RtModels';
import { urlFormat } from 'RtUi/utils/urlFormat/urlFormat';

export const useReplaceWindowUrl = (
	activeTab: any,
	resourceParams: ResourceIndexRequest | ServiceNumberPortRequestIndexRequest
) => {
	return useEffect(() => {
		if (activeTab === 'Results' && !isEmpty(resourceParams)) {
			window.history.replaceState(
				null,
				'RouteTrust',
				`?${urlFormat(resourceParams)}`
			);
		}
	}, [activeTab, resourceParams]);
};
