import { ResourceNumberSwitchRecord } from 'RtModels';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getDidSwitchInventoryColumns } from 'RtUi/components/data/DataGrid/configurations/rtDid/numbers';
import { useMemo } from 'react';

interface IDidSwitchInventoryGridProps {
	records: ResourceNumberSwitchRecord[];
	autoFocusFilter?: boolean;
	disableCardAppearance?: boolean;
	disableCollapse?: boolean;
}

export const DidSwitchInventoryGrid = ({
	records
}: IDidSwitchInventoryGridProps) => {
	const columns = useMemo(() => getDidSwitchInventoryColumns(), []);

	return (
		<DataGrid<ResourceNumberSwitchRecord>
			data={records}
			columns={columns}
			pageName={'rtDid_switchInventory'}
		/>
	);
};
