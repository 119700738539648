import { useMemo } from 'react';
import { SwitchTypeIndexResponse } from 'RtModels';
import { useGetSwitchTypes } from 'RtUi/app/AccountManagement/Switches/lib/services';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface ISwitchTypesSelectProps {
	value?: SwitchTypeIndexResponse;
	onChange?: (value?: SwitchTypeIndexResponse) => void;
	label?: string;
	required?: boolean;
	isClearable?: boolean;
	initialOptionId?: number;
	displayMode?: boolean;
}
export const SwitchTypesSelect = ({
	value,
	onChange = () => {},
	label = 'Switch Types',
	required,
	isClearable = true,
	initialOptionId,
	displayMode = false
}: ISwitchTypesSelectProps): JSX.Element => {
	const { data, isLoading } = useGetSwitchTypes();

	const selectData = useMemo(() => data || [], [data]);
	const options: Array<ISimpleSelectFormControlOption<number>> = useMemo(() => {
		return selectData.map((switchType) => ({
			value: switchType.switchTypeId,
			label: switchType.label
		}));
	}, [selectData]);

	const selectValue = useMemo(() => {
		const currentValue = value?.switchTypeId ?? initialOptionId;
		return options.find((opt) => opt.value === currentValue);
	}, [value, initialOptionId, options]);

	const onChangeHandler = (selectedValue: number | undefined) => {
		const switchType = data?.find((st) => st.switchTypeId === selectedValue);
		onChange(switchType);
	};

	return (
		<SimpleSelectFormControl<number>
			label={label}
			clearable={isClearable}
			isLoading={isLoading}
			required={required}
			value={selectValue}
			onChange={(val) => onChangeHandler(val?.value)}
			options={options}
			initialOptionId={initialOptionId?.toString()}
			displayMode={displayMode}
		/>
	);
};
