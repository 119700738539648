import { DefaultSipAgentConfig } from 'RtModels';
import { SipAgentConfigPropertyEditor } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentConfigPropertyEditor';
import { useGetSipAgentDefaultConfig } from 'RtUi/app/AccountManagement/SipAgents/lib/services';
import { Loading } from 'RtUi/components/ui/Loading';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';

interface ISipAgentConfigControlProps {
	value: DefaultSipAgentConfig;
	displayMode?: boolean;
	onChange: (value: string) => void;
}

const valueValidator = (value: any, defaultConfig?: DefaultSipAgentConfig) => {
	try {
		const config = JSON.parse(value);
		if (isEmpty(config)) {
			return defaultConfig;
		}
		return config;
	} catch (e) {
		return defaultConfig;
	}
};

export const SipAgentConfigControl = ({
	value,
	displayMode = false,
	onChange
}: ISipAgentConfigControlProps): JSX.Element => {
	const [config, setConfig] = useState<DefaultSipAgentConfig>();
	const { data: defaultConfig, isLoading } = useGetSipAgentDefaultConfig();

	useEffect(() => {
		setConfig(valueValidator(value));
	}, [value, defaultConfig]);

	if (isLoading) {
		return <Loading></Loading>;
	}

	if (!defaultConfig) {
		return <p>Something went wrong</p>;
	}

	const onChangeHandler = (value: DefaultSipAgentConfig) => {
		setConfig(value);
		onChange(JSON.stringify(value));
	};

	return (
		<SipAgentConfigPropertyEditor
			value={config}
			onChange={onChangeHandler}
			defaultConfig={defaultConfig}
			displayMode={displayMode}
		/>
	);
};
