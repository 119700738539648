import {
	ResourceUserIndexResponse,
	ResourceUserIndexRequest,
	ResourceUserCreateRequest
} from 'RtModels';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { IntegrationsApiRoutes } from 'RtExports/routes';

export class ResourceUsersResource extends ArrayResource<ResourceUserIndexResponse> {
	constructor(protected resourceId: number) {
		super('userId');

		const urlParams: ResourceUserIndexRequest = { resourceId };

		this.setApiRouteForGetAll(IntegrationsApiRoutes.ResourceUsers.Index, {
			urlParams
		});
	}

	public create(userId: number) {
		const resourceId = this.resourceId;
		const urlParams: ResourceUserCreateRequest = { resourceId, userId };

		return this.fetchWithRoute<ResourceUserIndexResponse[]>(
			IntegrationsApiRoutes.ResourceUsers.Create,
			{ urlParams }
		);
	}

	public delete(userId: number) {
		const resourceId = this.resourceId;
		const urlParams: ResourceUserCreateRequest = { resourceId, userId };

		return this.fetchWithRoute<ResourceUserIndexResponse[]>(
			IntegrationsApiRoutes.ResourceUsers.Delete,
			{ urlParams }
		);
	}
}
