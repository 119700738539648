import {
	AuditTemplateCprIndexRequest,
	AuditTemplateCprIndexResponse
} from 'RtModels';
import { TollFreeCprsRouter } from 'RtUi/app/rt800/Cprs/Cprs.router';
import { TemplateRouter } from 'RtUi/app/rt800/Templates/Template.router';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getAuditTemplateCprColumns } from 'RtUi/components/data/DataGrid/configurations/notification';
import { useGetAuditTemplateCpr } from 'RtUi/notifications/lib/services';
import { useMemo } from 'react';

interface ITemplateCprAuditUiNotificationGridProps {
	resourceParams?: AuditTemplateCprIndexRequest;
}

export const TemplateCprAuditUiNotificationGrid = ({
	resourceParams
}: ITemplateCprAuditUiNotificationGridProps) => {
	const { data, isFetching: isLoading } =
		useGetAuditTemplateCpr(resourceParams);
	const columns = useMemo(() => getAuditTemplateCprColumns(), []);

	return (
		<DataGrid<AuditTemplateCprIndexResponse>
			data={data?.data}
			columns={columns}
			router={TemplateRouter}
			totalRows={data?.totalCount}
			loading={isLoading}
			disableExternalLinks={[TollFreeCprsRouter]}
			pageName={'notification_CprAudit'}
		/>
	);
};
