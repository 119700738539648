import { useMemo } from 'react';
import { Item } from 'react-contexify';
import {
	AuditTollFreeCprIndexRequest,
	AuditTollFreeCprIndexResponse
} from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import {
	TemplateCprsRouter,
	TollFreeCprsRouter
} from 'RtUi/app/rt800/Cprs/Cprs.router';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getAuditTollFreeCprColumns } from 'RtUi/components/data/DataGrid/configurations/notification';
import { useGetAuditTollFreeCpr } from 'RtUi/notifications/lib/services';

interface ITollFreeCprAuditUiNotificationGridProps {
	resourceParams?: AuditTollFreeCprIndexRequest;
}

export const TollFreeCprAuditUiNotificationGrid = ({
	resourceParams
}: ITollFreeCprAuditUiNotificationGridProps) => {
	const { data, isFetching: isLoading } =
		useGetAuditTollFreeCpr(resourceParams);
	const columns = useMemo(() => getAuditTollFreeCprColumns(), []);

	return (
		<DataGrid<AuditTollFreeCprIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			disableExternalLinks={[TemplateCprsRouter, TollFreeCprsRouter]}
			pageName={'notification_TollFreeCprAudit'}
			menuLinks={(record) => (
				<Item
					onClick={() => {
						BrowserHistory.push(TollFreeCprsRouter.getProfileRoute(record));
					}}
				>
					<span className="d-flex justify-content-start align-items-center">
						<i className="fas fa-fw fa-search" />
						<span>
							<span>{TollFreeCprsRouter.getName()}</span>
						</span>
					</span>
				</Item>
			)}
		/>
	);
};
