import { RateControlRerateType, RejectCodes } from 'RtModels';
import { sentenceCase } from 'change-case';

export const RerateTypeNameMap = new Map<RateControlRerateType, string>([
	[RateControlRerateType.Rerate, 'Re-rate'],
	[RateControlRerateType.Recost, 'Re-cost'],
	[RateControlRerateType.Both, 'Both']
]);

export function rejectCodeToLabel(rejectCode: any) {
	const rc = rejectCode;
	if (rc in RejectCodes) {
		return `${sentenceCase(
			RejectCodes[rc as keyof typeof RejectCodes].toString()
		)} (${rc})`;
	}

	return rc;
}
