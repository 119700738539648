import { GuardianRerateControlProfileResponse } from 'RtModels';
import { RerateControlFormEditor } from 'RtUi/app/rtVue/RerateControl/lib/forms/RerateControlFormEditor';
import { RerateControlResource } from 'RtUi/app/rtVue/RerateControl/lib/resources/RerateControlResource';
import { RerateControlRouter } from 'RtUi/app/rtVue/RerateControl/RerateControl.router';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IRerateControlProfileContainerProps {}

interface IRerateControlProfileContainerState {
	activeTab: string;
	profile?: GuardianRerateControlProfileResponse;
}

@RerateControlRouter.getProfileRtUiController()
export class RerateControlProfileContainer extends ProfileApplicationContainer<
	IRerateControlProfileContainerProps,
	IRerateControlProfileContainerState
> {
	public Tabs = RerateControlRouter.getProfileTabs();

	public state: IRerateControlProfileContainerState = {
		activeTab: this.Tabs.Profile.header
	};

	public componentDidMount() {
		const profileId = this.getIdParam();
		const resource = new RerateControlResource();

		resource.get(profileId).then((profile) => this.setState({ profile }));
	}

	public render() {
		const { profile } = this.state;

		if (!profile) {
			return <Loading />;
		}

		const canManage = RerateControlRouter.canManageWith(profile);

		return (
			<TabbedLayout<GuardianRerateControlProfileResponse>
				router={RerateControlRouter}
				profile={profile}
				canManageFn={() => canManage}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<RerateControlFormEditor profile={profile} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
