import {
	LcrTemplateIndexResponse,
	TemplateLcrCarrierIndexResponse,
	TemplateLcrScenarioIndexResponse
} from 'RtModels';
import { EntityIdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/EntityIdDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { DelimiterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DelimiterDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { RateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getLcrTemplatesColumns = (): Array<
	DataGridColumn<LcrTemplateIndexResponse>
> => [
	DefaultDataGridColumn({
		header: 'Template Name',
		accessorKey: 'templateName'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	EntityIdDataGridColumn({
		accessorKey: 'entityId'
	}),
	TimeStampDataGridColumn({
		header: 'Effective Timestamp',
		accessorKey: 'effectiveTs'
	}),
	DelimiterDataGridColumn({
		accessorKey: 'interLataCarriers',
		header: 'Carriers in Production',
		delimiter: ','
	}),
	DelimiterDataGridColumn({
		accessorKey: 'carriersToBeAnalyzed',
		header: 'Carriers to be Analyzed',
		delimiter: ','
	}),
	DelimiterDataGridColumn({
		accessorKey: 'scenariosNames',
		header: 'Scenarios',
		delimiter: ','
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DefaultDataGridColumn({
		header: 'LCR Task',
		accessorKey: 'lcrTaskId'
	})
];

export const getLcrTemplateCarriersColumns = (
	removeCarrierFromTemplate: (row: TemplateLcrCarrierIndexResponse) => void
): Array<DataGridColumn<TemplateLcrCarrierIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: removeCarrierFromTemplate
	}),
	IdDataGridColumn({
		accessorKey: 'templateLcrCarrierId'
	}),
	DefaultDataGridColumn({
		header: 'Cic',
		accessorKey: 'cic'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	...RespOrgColumnsBuilder<TemplateLcrCarrierIndexResponse>({
		header: 'RespOrg',
		accessorKey: 'respOrgId'
	}),
	TimeStampDataGridColumn({
		header: 'Last Rates Upload',
		accessorKey: 'lastRatesUploadTs'
	}),
	BooleanDataGridColumn({
		header: 'Allow As Default',
		accessorKey: 'allowAsDefault'
	}),
	BooleanDataGridColumn({
		header: 'Allow As Canada',
		accessorKey: 'allowAsCanada'
	}),
	RateDataGridColumn({
		header: 'Inter. Rate',
		accessorKey: 'defaultInterRate'
	}),
	RateDataGridColumn({
		header: 'Intra. Rate',
		accessorKey: 'defaultIntraRate'
	}),
	RateDataGridColumn({
		header: 'Indet. Rate',
		accessorKey: 'defaultIndetRate'
	}),
	DefaultDataGridColumn({
		header: 'RateSheet Type',
		accessorKey: 'rateSheetTypeId'
	}),
	TimeStampDataGridColumn({
		header: 'Created At',
		accessorKey: 'createdAt'
	}),
	TimeStampDataGridColumn({
		header: 'Updated At',
		accessorKey: 'updatedAt'
	})
];

export const getLcrTemplateScenariosColumns = (
	removeScenarioFromTemplate: (row: TemplateLcrScenarioIndexResponse) => void
): Array<DataGridColumn<TemplateLcrScenarioIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: removeScenarioFromTemplate
	}),
	IdDataGridColumn({
		accessorKey: 'templateLcrScenarioId'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	BooleanDataGridColumn({
		header: 'Allow As Default Only',
		accessorKey: 'allowAsDefaultOnly'
	})
];
