import {
	NetworkEfficiencyIndexRequest,
	NetworkEfficiencyIndexResponse
} from 'RtModels';
import { useGetNetworkEfficiency } from 'RtUi/app/rtSip/NetworkEfficiency/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getNetworkEfficiencyColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/networkEfficiency';
import { useMemo } from 'react';

interface INetworkEfficiencyGridProps {
	autoFocusFilter?: boolean;
	resourceParams: NetworkEfficiencyIndexRequest;
}

export const NetworkEfficiencyDataGrid = ({
	resourceParams
}: INetworkEfficiencyGridProps) => {
	const { data, isFetching: isLoading } =
		useGetNetworkEfficiency(resourceParams);
	const columns = useMemo(() => getNetworkEfficiencyColumns(), []);

	return (
		<DataGrid<NetworkEfficiencyIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_networkEfficiency'}
		/>
	);
};
