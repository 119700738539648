import { IntegrationErrorMessagesDataGrid } from 'RtUi/app/rtAdmin/IntegrationErrorMessages/lib/grids/IntegrationErrorMessagesDataGrid';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { IntegrationErrorMessagesRouter } from './IntegrationErrorMessages.router';

export const IntegrationErrorMessagesIndexContainer = () => {
	return (
		<StandardLayout router={IntegrationErrorMessagesRouter}>
			<IntegrationErrorMessagesDataGrid />
		</StandardLayout>
	);
};

IntegrationErrorMessagesIndexContainer.displayName =
	'IntegrationErrorMessagesIndexContainer';

IntegrationErrorMessagesRouter.setIndexRtUiFunctionalComponent(
	IntegrationErrorMessagesIndexContainer
);
