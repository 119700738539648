/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
	ConnectionIndexRequest,
	ConnectionProfileResponse,
	FileStreamIndexResponse,
	SubscriptionIndexResponse,
	SwitchIndexResponse
} from 'RtModels';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { SwitchesSelect } from 'RtUi/app/AccountManagement/Switches/lib/controls/SwitchesSelect';
import { ConnectionRouter } from 'RtUi/app/rtVue/Connections/Connection.router';
import { IpAddressFormControl } from 'RtUi/app/rtVue/Connections/lib/controls/IpAddressFormControl';
import { ConnectionEditor } from 'RtUi/app/rtVue/Connections/lib/forms/ConnectionEditor';
import { ConnectionGrid } from 'RtUi/app/rtVue/Connections/lib/grids/ConnectionGrid';
import { FileStreamSelect } from 'RtUi/app/rtVue/FileStreams/lib/controls/FileStreamSelect';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtxUiSearchFormComponent } from 'RtUi/components/rtx/form/RtxUiSearchForm';
import { useFormInitializer } from 'RtUi/components/rtx/form/hooks/useFormInitializer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

export const ConnectionIndexContainer = (): JSX.Element => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search', isDefault: true },
		Results: { header: 'Results' },
		CreateConnection: { header: 'Create Connection', isAction: true }
	});
	const initializer = useFormInitializer();
	const [fileStreams, setFileStreams] = useState<FileStreamIndexResponse[]>();
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [guardianSwitch, setGuardianSwitch] = useState<SwitchIndexResponse>();

	const [resourceParams, setResourceParams] =
		useState<ConnectionIndexRequest>();

	const goToPath = (path: string) => {
		BrowserHistory.push(path);
	};

	const onCreateSuccess = (connections: ConnectionProfileResponse[]) => {
		if (connections.length === 1) {
			const profilePath = ConnectionRouter.getProfileRoute(
				connections[0].connectionId
			);

			return goToPath(profilePath);
		}

		setActiveTab(tabs.Results.header);
	};

	const onCancel = () => {
		setActiveTab(tabs.Results.header);
	};

	const handleSubmit = (data: ConnectionIndexRequest) => {
		setResourceParams(data);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={ConnectionRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtxUiSearchFormComponent<ConnectionIndexRequest>
					defaultValues={resourceParams}
					initializer={initializer}
					onLoadUrlParams={handleSubmit}
					onSubmit={handleSubmit}
				>
					{({ control }) => (
						<>
							<Controller
								control={control}
								name="search"
								render={({ field: { onChange, value = '' } }) => (
									<OverlayTrigger
										overlay={(props) => (
											<Tooltip id={`search-button-tooltip`} {...props}>
												This will search all the fields below at the same time.
												it could return multiple matches
											</Tooltip>
										)}
									>
										{({ ref, ...triggerHandler }) => (
											<InputFormControl
												label="Global Search"
												ref={ref}
												{...triggerHandler}
												onChange={onChange}
												value={value}
											/>
										)}
									</OverlayTrigger>
								)}
							/>
							<Controller
								control={control}
								name="fileStreamIds"
								render={({ field: { onChange, value } }) => (
									<FileStreamSelect<true>
										multi
										isActive
										label="File Stream"
										value={fileStreams}
										onChange={(val) => {
											setFileStreams(val);
											onChange(val?.map((v) => v.fileStreamId));
										}}
										initialOptionId={value?.map((v) => String(v))}
										closeMenuOnSelect={false}
										clearable
									/>
								)}
							/>
							<Controller
								control={control}
								name="subscriptionId"
								render={({ field: { onChange, value } }) => (
									<SubscriptionSelect
										label="Subscription"
										value={subscription}
										isClearable
										onChange={(val) => {
											setSubscription(val);
											onChange(val?.subscriptionId);
										}}
										initialOptionId={value}
									/>
								)}
							/>
							<Controller
								control={control}
								name="connectionId"
								render={({ field: { onChange, value } }) => (
									<InputFormControl
										type="number"
										label="Connection ID"
										value={String(value ?? '')}
										onChange={(connectionIdStr) => {
											onChange(
												connectionIdStr !== '' ? Number(connectionIdStr) : ''
											);
										}}
									/>
								)}
							/>
							<Controller
								control={control}
								name="trunkGroupId"
								render={({ field: { onChange, value } }) => (
									<InputFormControl
										label="Trunk Group ID"
										value={value}
										onChange={onChange}
									/>
								)}
							/>
							<Controller
								control={control}
								name="trunkGroupLabel"
								render={({ field: { onChange, value } }) => (
									<InputFormControl
										label="Trunk Group Label"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
							<Controller
								control={control}
								name="switchId"
								render={({ field: { onChange, value } }) => (
									<SwitchesSelect
										label="Switch"
										isClearable
										value={guardianSwitch}
										onChange={(val) => {
											setGuardianSwitch(val);
											onChange(val?.switchId);
										}}
										initialOptionId={String(value ?? '')}
									/>
								)}
							/>
							<Controller
								control={control}
								name="gatewayAddress"
								render={({ field: { onChange, value } }) => (
									<IpAddressFormControl
										label="Gateway IP Address"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
							<Controller
								control={control}
								name="peerAddress"
								render={({ field: { onChange, value } }) => (
									<IpAddressFormControl
										label="Peer IP Address"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
							<Controller
								control={control}
								name="isImt"
								render={({ field: { onChange, value } }) => (
									<CheckboxFormControl
										label="Is IMT"
										value={Boolean(value)}
										onChange={(isImt) => onChange(Number(isImt))}
									/>
								)}
							/>
							<Controller
								control={control}
								name="isPeering"
								render={({ field: { onChange, value } }) => (
									<CheckboxFormControl
										label="Is Peering"
										value={Boolean(value)}
										onChange={(isPeering) => onChange(Number(isPeering))}
									/>
								)}
							/>
						</>
					)}
				</RtxUiSearchFormComponent>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<ConnectionGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateConnection}>
				<ConnectionEditor<true>
					displayMode={false}
					onUpdate={onCreateSuccess}
					onCancel={onCancel}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ConnectionIndexContainer.displayName = 'ConnectionIndexContainer';

ConnectionRouter.setIndexRtUiFunctionalComponent(ConnectionIndexContainer, {
	groupName: 'Configuration',
	orderPriority: 0,
	groupOrderPriority: 90
});
