/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RatePlanIndexResponse = {
    properties: {
        ratePlanId: {
            type: 'number',
            isRequired: true,
        },
        ratePlan: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isShared: {
            type: 'number',
            isRequired: true,
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rateKeyDeleteDays: {
            type: 'number',
            isRequired: true,
        },
        rateKeyMatchIncreaseDays: {
            type: 'number',
            isRequired: true,
        },
        rateKeyExpandIncreaseDays: {
            type: 'number',
            isRequired: true,
        },
        indetMethod: {
            type: 'RateLoadIndetMethod',
            isRequired: true,
            isNullable: true,
        },
        lastRateLoadTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        lastMigrationTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
    },
};