import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	TrackingGroupIndexRequest,
	TrackingGroupIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchTrackingGroup = async (queryParams?: TrackingGroupIndexRequest) => {
	return handleGetRequest<TrackingGroupIndexResponse[], true>(
		RtxSipApiRoutes.TrackingGroups.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetTrackingGroup = (params?: TrackingGroupIndexRequest) => {
	return useQuery<FullResponse<TrackingGroupIndexResponse[]>, Error>(
		['getTrackingGroup', params],
		() => fetchTrackingGroup(params)
	);
};
