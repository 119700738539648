import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import {
	AccountIndexResponse,
	CountryIndexResponse,
	SubscriptionIndexResponse,
	TrendAnalysisColumns,
	TrendAnalysisIndexRequest
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { TrendAnalysisDataGrid } from 'RtUi/app/rtSip/TrendAnalysis/lib/grids/TrendAnalysisDataGrid';
import { TrendAnalysisRouter } from 'RtUi/app/rtSip/TrendAnalysis/TrendAnalysis.router';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IInitialResourceParams {
	startTs: moment.Moment;
	endTs: moment.Moment;
	countries?: CountryIndexResponse[];
	costAccounts?: AccountIndexResponse[];
	costSubscriptions?: SubscriptionIndexResponse[];
	revenueAccounts?: AccountIndexResponse[];
	revenueSubscriptions?: SubscriptionIndexResponse[];
	timeSlotSize: TrendAnalysisColumns;
	dataDisplayMode: TrendAnalysisColumns;
	regionDetail: TrendAnalysisColumns | undefined;
}

interface ITrendAnalysisIndexContainerState
	extends ISearchResultsContainerState<TrendAnalysisIndexRequest>,
		IInitialResourceParams {}

@TrendAnalysisRouter.getBusinessReportIndexRtUiController()
export class TrendAnalysisIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	ITrendAnalysisIndexContainerState,
	TrendAnalysisIndexRequest
> {
	//why this is setup in two different places?
	public initParams: IInitialResourceParams = {
		startTs: moment().startOf('day') as any,
		endTs: moment().endOf('day') as any,
		timeSlotSize: TrendAnalysisColumns.TimeSlotDaily,
		dataDisplayMode: TrendAnalysisColumns.SummaryMode,
		regionDetail: TrendAnalysisColumns.CountryIsoId
	};

	public state: ITrendAnalysisIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Search,
		resourceParams: {
			startTs: moment().startOf('day') as any,
			endTs: moment().endOf('day') as any
		}
	};

	public Tabs = TrendAnalysisRouter.getProfileTabs();

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams(): TrendAnalysisIndexRequest {
		const {
			startTs,
			endTs,
			costAccounts,
			costSubscriptions,
			revenueSubscriptions,
			revenueAccounts,
			countries,
			timeSlotSize,
			dataDisplayMode,
			regionDetail
		} = this.state;

		const params: TrendAnalysisIndexRequest = {
			startTs: startTs.format('YYYY-MM-DD HH:mm:ss') as any,
			endTs: endTs.format('YYYY-MM-DD HH:mm:ss') as any
		};

		if (costAccounts) {
			params.costAccountIds = costAccounts.map(
				(customer) => customer.accountId
			);
		}

		if (costSubscriptions) {
			params.costSubscriptionIds = costSubscriptions.map(
				(subscription) => subscription.subscriptionId
			);
		}

		if (revenueSubscriptions) {
			params.revenueSubscriptionIds = revenueSubscriptions.map(
				(subscription) => subscription.subscriptionId
			);
		}

		if (revenueAccounts) {
			params.revenueAccountIds = revenueAccounts.map(
				(customer) => customer.accountId
			);
		}

		if (countries) {
			params.countryIsoIds = countries.map((country) => country.iso3166Alpha3);
		}

		params.columns = [timeSlotSize, dataDisplayMode];

		if (regionDetail) {
			params.columns.push(regionDetail);
		}

		return params;
	}

	public render() {
		return (
			<TabbedLayout
				router={TrendAnalysisRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm wide onSubmit={(e) => this.submitAndGoToResults(e)}>
						<Row>
							<Col md={6}>
								<DatetimeFormControl
									required
									label="Start Date"
									showDateOnly={true}
									value={this.state.startTs}
									onChange={(startTs) =>
										this.setState({
											startTs: moment(startTs)
												.set(`hour`, 0)
												.set('minutes', 0)
												.set('seconds', 0)
										})
									}
								/>
								<DatetimeFormControl
									required
									label="End Date"
									showDateOnly={true}
									value={this.state.endTs}
									onChange={(endTs) =>
										this.setState({
											endTs: moment(endTs).endOf('day')
										})
									}
								/>
								<CountrySelect<true>
									isMulti
									label="Countries"
									value={this.state.countries}
									onChange={(countries) => this.setState({ countries })}
								/>
								<AccountSelect<true>
									label="Cost accounts"
									value={this.state.costAccounts}
									onChange={(costAccounts) => this.setState({ costAccounts })}
									multi
								/>
								<SubscriptionSelect<true>
									label="Cost subscriptions"
									value={this.state.costSubscriptions}
									onChange={(costSubscriptions) =>
										this.setState({ costSubscriptions })
									}
									isMulti
								/>
							</Col>
							<Col md={6}>
								<RadioFormControl<TrendAnalysisColumns>
									required
									label="Time Slot Size"
									onChange={(timeSlotSize) => this.setState({ timeSlotSize })}
									value={this.state.timeSlotSize}
									options={[
										{
											value: TrendAnalysisColumns.TimeSlotDaily,
											label: 'Daily'
										},
										{
											value: TrendAnalysisColumns.TimeSlotHourly,
											label: 'Hourly'
										}
									]}
								/>
								<RadioFormControl<TrendAnalysisColumns>
									required
									label="Data Display Mode"
									onChange={(dataDisplayMode) =>
										this.setState({ dataDisplayMode })
									}
									value={this.state.dataDisplayMode}
									options={[
										{
											value: TrendAnalysisColumns.SummaryMode,
											label: 'Summary'
										},
										{
											value: TrendAnalysisColumns.DetailMode,
											label: 'Export Detail'
										}
									]}
								/>
								<RadioFormControl<TrendAnalysisColumns | undefined>
									required
									label="Region Detail"
									value={this.state.regionDetail}
									onChange={(regionDetail) => this.setState({ regionDetail })}
									options={[
										{
											value: undefined,
											label: 'None'
										},
										{
											value: TrendAnalysisColumns.CountryIsoId,
											label: 'Country'
										},
										{
											value: TrendAnalysisColumns.CustRateSummary,
											label: 'Customer Rate Summary'
										},
										{
											value: TrendAnalysisColumns.VendRateSummary,
											label: 'Vendor Rate Summary'
										}
									]}
								/>
								<AccountSelect<true>
									label="Revenue accounts"
									value={this.state.revenueAccounts}
									onChange={(revenueAccounts) =>
										this.setState({ revenueAccounts })
									}
									multi
								/>
								<SubscriptionSelect<true>
									label="Revenue subscriptions"
									value={this.state.revenueSubscriptions}
									onChange={(revenueSubscriptions) =>
										this.setState({ revenueSubscriptions })
									}
									isMulti
								/>
							</Col>
						</Row>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<TrendAnalysisDataGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
