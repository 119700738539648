import ReactInputMask from 'react-input-mask';
import { ControlGroup } from '../../ui/ControlGroup';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from '../FormControl';

interface IMonthDayFormControlProps extends IFormControlProps<string> {
	label?: string;
	alwaysShowMask?: boolean;
	useControlGroup?: boolean;
}

interface IMondayDayFormControlState extends IFormControlState {}

export class MonthDayFormControl extends FormControl<
	string,
	false,
	IMonthDayFormControlProps,
	IMondayDayFormControlState
> {
	public resource = undefined;
	public state: IMondayDayFormControlState = {
		formLabel: 'Month Day'
	};

	public componentDidMount() {
		const formLabel = this.props.label;

		if (formLabel) {
			this.setState({ formLabel });
		}
	}

	public render() {
		const {
			value = '',
			onChange = () => ({}),
			alwaysShowMask = true,
			displayMode
		} = this.props;
		let { className = '' } = this.props;
		const { formLabel } = this.state;

		className += ' text-monospace form-control';

		const inputElem = (
			<ReactInputMask
				alwaysShowMask={alwaysShowMask}
				required={this.props.required}
				name={this.props.name}
				className={className}
				onChange={(e) => onChange(e.currentTarget.value)}
				mask="99/99"
				maskPlaceholder="_"
				value={this.props.value}
			/>
		);

		if (!this.props.useControlGroup) {
			return inputElem;
		}

		return (
			<ControlGroup
				label={formLabel}
				value={value}
				displayMode={displayMode}
				required={this.props.required}
			>
				{inputElem}
			</ControlGroup>
		);
	}
}
