import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	PartitionSipGateway,
	PartitionSipGatewayCreateRequest,
	PartitionSipGatewayUpdateRequest,
	PartitionSipGatewayProfileRequest,
	PartitionSipGatewayCreateUrlRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class PartitionSipGatewayResource extends HttpResource<PartitionSipGateway> {
	constructor() {
		super(RtAdmApiRoutes.PartitionSipGateway);
	}

	public createPartitionSipGateway(partitionSipGateway: PartitionSipGateway) {
		const urlParams: PartitionSipGatewayCreateUrlRequest = {
			partitionId: partitionSipGateway.partitionId
		};
		const req: PartitionSipGatewayCreateRequest = partitionSipGateway;
		const body = JSON.stringify(req);

		return this.fetchWithRoute<PartitionSipGateway>(
			RtAdmApiRoutes.PartitionSipGateway.Create,
			{ body, urlParams }
		);
	}

	public updatePartitionSipGateway(partitionSipGateway: PartitionSipGateway) {
		const urlParams: PartitionSipGatewayProfileRequest = {
			partitionId: partitionSipGateway.partitionId,
			partitionSipGatewayId: partitionSipGateway.partitionSipGatewayId
		};
		const bodyReq: PartitionSipGatewayUpdateRequest = partitionSipGateway;
		const body = JSON.stringify(bodyReq);

		return this.fetchWithRoute<PartitionSipGateway>(
			RtAdmApiRoutes.PartitionSipGateway.Update,
			{ body, urlParams }
		);
	}
}
