/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum AlertStatus {
    Active = 10,
    Paused = 20,
    Closed = 30,
}