/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum SipAgentMedSource {
    RequestUri = 1,
    FromHeader = 2,
    PAssertedIdentityHeader = 3,
}