import { GuardianReconciliationDisputeIndexResponse } from 'RtModels';
import { RtVueApiRoutes } from 'RtExports/routes';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class GuardianReconciliationDisputeAmountResource extends ArrayResource<GuardianReconciliationDisputeIndexResponse> {
	constructor() {
		super('label');

		this.setApiRouteForGetAll(
			RtVueApiRoutes.GuardianReconciliationDisputeAmount.Index
		);
	}
}
