import { Permissions } from 'RtExports/routes';
import { RtVueReportRouter } from 'RtUi/app/rtVue/common/lib/routers/RtVueReportRouter';

export class BirdEyeReportRouterClass extends RtVueReportRouter<
	void,
	void,
	{}
> {
	constructor() {
		super('Birds Eye Report', 'birdeye', undefined, {});

		this.setIndexPermissions([Permissions.VueOperator]);
	}

	/**
	 * By pass pluralization of name
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const BirdEyeReportRouter = new BirdEyeReportRouterClass();
