/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianFraudListIndexResponse = {
    properties: {
        guardianFraudListId: {
            type: 'number',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        dataSourceField: {
            type: 'string',
            isRequired: true,
        },
        dataSourceValue: {
            type: 'string',
            isRequired: true,
        },
        destinationType: {
            type: 'string',
            isRequired: true,
        },
        destinationValue: {
            type: 'string',
            isNullable: true,
        },
        block: {
            type: 'number',
            isRequired: true,
        },
        globalRule: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        createdBy: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        updatedBy: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        updatedTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
    },
};