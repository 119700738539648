import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { cloneDeep } from 'lodash-es';
import { FC, useState } from 'react';
import {
	GuardianSubscriptionCicCreateRequest,
	GuardianSubscriptionCicProfileResponse,
	LcrCarrierIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { SubscriptionCicResource } from 'RtUi/app/rtVue/SubscriptionCic/lib/resources/SubscriptionCicResource';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import {
	CarrierSelect,
	ICicIndexResponse
} from 'RtUi/app/rt800/Cprs/lib/controls/CarrierSelect';
import { LcrCarrierSelect } from 'RtUi/app/rtLco/Carriers/lib/controls/LcrCarrierSelect';

interface ISubscriptionCicEditorProps {
	onSuccess?: (resource: GuardianSubscriptionCicProfileResponse) => void;
	editMode?: GuardianSubscriptionCicProfileResponse;
	onCancel?: () => void;
	createMode?: boolean;
}

const resource = new SubscriptionCicResource();

export const SubscriptionCicFormEditor: FC<
	React.PropsWithChildren<ISubscriptionCicEditorProps>
> = ({ editMode, onSuccess, onCancel = () => null, createMode = false }) => {
	const [error, setError] = useState();
	const [displayMode, setDisplayMode] = useState<boolean>(!createMode);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [cic, setCic] = useState<ICicIndexResponse>();
	const [carrier, setCarrier] = useState<LcrCarrierIndexResponse>();
	const [editingEntity, setEditingEntity] =
		useState<GuardianSubscriptionCicCreateRequest>(
			editMode
				? cloneDeep(editMode)
				: {
						subscriptionId: -1,
						cic: '',
						lcoCarrierId: -1,
						priority: 0
					}
		);

	const onChange = <K extends keyof GuardianSubscriptionCicProfileResponse>(
		key: K,
		value: GuardianSubscriptionCicProfileResponse[K]
	) => {
		if (!isSubmitting) {
			const updated = {
				[key]: value
			};
			setEditingEntity({
				...editingEntity,
				...updated
			});
		}
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setIsSubmitting(true);
		setError(undefined);

		try {
			let newEntity: GuardianSubscriptionCicProfileResponse;

			if (createMode) {
				newEntity = await resource.create(editingEntity);
			} else {
				newEntity = await resource.update(
					editMode!.guardianSubscriptionCicId,
					editingEntity
				);
			}
			setDisplayMode(true);
			if (onSuccess) {
				onSuccess(newEntity);
			}
		} catch (err: any) {
			setError(err);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onCancelInternal = () => {
		setEditingEntity(cloneDeep(editMode!));
		setDisplayMode(true);
		setSubscription(undefined);
		setCarrier(undefined);
		setCic(undefined);
		onCancel();
	};

	return (
		<RtUiNarrowForm
			onCancel={onCancelInternal}
			displayMode={displayMode}
			isSubmitting={isSubmitting}
			createMode={createMode}
			onSubmit={onSubmit}
			error={error}
			onChange={() => {
				setDisplayMode(!displayMode);
			}}
		>
			<SubscriptionSelect
				required
				displayMode={displayMode}
				onChange={(subscription) => {
					setSubscription(subscription);
					onChange('subscriptionId', subscription.subscriptionId);
				}}
				value={subscription}
				initialOptionId={editMode ? editingEntity.subscriptionId : undefined}
				name="subscriptionId"
			/>
			<CarrierSelect
				label="CIC"
				displayMode={displayMode}
				required
				onChange={(selectedCarrier) => {
					setCic(selectedCarrier);
					onChange('cic', selectedCarrier.cic);
				}}
				value={cic}
				initialOptionId={editMode ? String(editingEntity.cic) : undefined}
			/>
			<LcrCarrierSelect
				required
				displayMode={displayMode}
				label="LCO Account"
				onChange={(lcrCarrier: LcrCarrierIndexResponse) => {
					setCarrier(lcrCarrier);
					onChange('lcoCarrierId', lcrCarrier.lcrCarrierId);
				}}
				value={carrier}
				initialOptionId={
					editMode ? String(editingEntity.lcoCarrierId) : undefined
				}
			/>
			<InputFormControl
				required
				type="number"
				min={0}
				label="Priority"
				displayMode={displayMode}
				onChange={(value) => onChange('priority', Number(value))}
				value={String(editingEntity.priority)}
				name="priority"
			/>
		</RtUiNarrowForm>
	);
};
