import pluralize from 'pluralize';
import { FC, useContext, useState } from 'react';
import { Button, Card, ListGroup, Nav } from 'react-bootstrap';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { Aside } from 'RtUi/components/ui/Aside';
import { ICprCleanResponse } from 'Somos/lib/SomosCpr/RtCprV2';

interface ICprCleanAsideProps {
	isOpen: boolean;
	toggle: (isOpen: boolean) => void;
}

export const CprCleanAside: FC<
	React.PropsWithChildren<ICprCleanAsideProps>
> = ({ isOpen, toggle }) => {
	const [wizardStep, setWizardStep] = useState(1);
	const [cleanData, setCleanData] = useState<ICprCleanResponse>();
	const [removeEmptyLabels, setRemoveEmptyLabels] = useState(true);
	const [removeUnusedLabels, setRemoveUnusedLabels] = useState(false);
	const { cpr, updateCpr } = useContext(CprProfileContext);
	let wasAnythingCleaned = true;

	if (cleanData) {
		const changedQty = Object.values(cleanData).reduce(
			(prev, current) => prev + current,
			0
		);

		wasAnythingCleaned = changedQty > 0;
	}

	const submitClean = () => {
		const cleanRes = cpr.clean({ removeEmptyLabels, removeUnusedLabels });
		setCleanData(cleanRes);
		setWizardStep(2);
	};
	const close = () => {
		toggle(false);

		updateCpr(cpr);
	};

	const createSummaryItem = (
		qty: number | undefined,
		type: string,
		label: 'Modified' | 'Removed'
	) => {
		if (typeof qty === 'undefined' || qty <= 0) {
			return null;
		}

		const word = pluralize(type, qty);
		const wasWere = pluralize('was', qty);
		const icon = label === 'Modified' ? 'broom' : 'trash-alt';
		const iconColor = label === 'Modified' ? 'success' : 'warning';

		return (
			<ListGroup.Item>
				<i className={`fas fa-fw fa-${icon} text-${iconColor} me-2`} />
				{qty.toLocaleString()} {word} {wasWere} {label}
			</ListGroup.Item>
		);
	};

	const wizardButtons = () => {
		const isOnFirstStep = wizardStep === 1;
		const submitText = isOnFirstStep ? 'Clean' : 'Close';

		return (
			<Card.Body className="d-flex justify-content-around px-md-5">
				{isOnFirstStep && (
					<Button
						type="button"
						size="lg"
						variant="white"
						onClick={() => toggle(false)}
					>
						Cancel
					</Button>
				)}
				<Button
					type="submit"
					size="lg"
					variant={isOnFirstStep ? 'primary' : 'white-alt'}
					className="flex-fill mx-md-5 mx-2"
					style={{ maxWidth: 300 }}
				>
					<span>{submitText}&nbsp;</span>
					{isOnFirstStep && <i className="fas fa-fw fa-long-arrow-alt-right" />}
				</Button>
			</Card.Body>
		);
	};

	return (
		<Aside isOpen={isOpen} disabledBody>
			<Aside.Header header="CPR Clean" closable={false} />
			<section>
				<Card.Header className="d-none d-md-block bg-white">
					<Nav className="nav-steps d-md-flex justify-content-between">
						<Nav.Item>
							<Nav.Link active={wizardStep === 1}>Config & Submit</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link active={wizardStep === 2}>Summary</Nav.Link>
						</Nav.Item>
					</Nav>
				</Card.Header>
				<section>
					{wizardStep === 1 && (
						<form
							className="subtleSlideInRight"
							onSubmit={(ev) => {
								ev.preventDefault();
								submitClean();
							}}
						>
							<Card.Body>
								<RadioFormControl<boolean>
									label="Remove Empty Labels"
									options={[
										{ value: false, label: 'No' },
										{ value: true, label: 'Yes' }
									]}
									onChange={setRemoveEmptyLabels}
									value={removeEmptyLabels}
								/>
								<RadioFormControl<boolean>
									label="Remove Unused Labels"
									options={[
										{ value: false, label: 'No' },
										{ value: true, label: 'Yes' }
									]}
									onChange={setRemoveUnusedLabels}
									value={removeUnusedLabels}
								/>
							</Card.Body>
							{wizardButtons()}
						</form>
					)}
					{wizardStep === 2 && (
						<form
							className="subtleSlideInRight"
							onSubmit={(ev) => {
								ev.preventDefault();
								close();
							}}
						>
							<section className="d-flex flex-column justify-content-center align-items-center">
								<h3>Summary</h3>
								<ListGroup variant="flush" className="py-3 lead">
									{!wasAnythingCleaned && (
										<ListGroup.Item>Nothing to Clean</ListGroup.Item>
									)}
									{wasAnythingCleaned && (
										<>
											{createSummaryItem(
												cleanData?.modifiedCprLabelsQty,
												'Label',
												'Modified'
											)}
											{createSummaryItem(
												cleanData?.modifiedCprRowsQty,
												'Row',
												'Modified'
											)}
											{createSummaryItem(
												cleanData?.removedCprLabelsQty,
												'Label',
												'Removed'
											)}
											{createSummaryItem(
												cleanData?.removedCprRowsQty,
												'Row',
												'Removed'
											)}
											{createSummaryItem(
												cleanData?.removedInterCarriersQty,
												'InterLata Carrier',
												'Removed'
											)}
											{createSummaryItem(
												cleanData?.removedIntraCarriersQty,
												'IntraLata Carrier',
												'Removed'
											)}
										</>
									)}
								</ListGroup>
							</section>
							{wizardButtons()}
						</form>
					)}
				</section>
			</section>
		</Aside>
	);
};
