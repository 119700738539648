import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationMappingIndexResponse,
	GuardianReconciliationMappingProfileResponse
} from 'RtModels';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IGuardianReconciliationMappingProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Filters: TabbedLayoutTabProps;
}

export const GuardianReconciliationMappingProfileContainerTabs: IGuardianReconciliationMappingProfileContainerTabs =
	{
		Profile: {
			header: 'Profile'
		},
		Filters: {
			header: 'Filters'
		}
	};

export class GuardianReconciliationMappingsRouterClass extends RtVueModuleRouter<
	GuardianReconciliationMappingIndexResponse,
	GuardianReconciliationMappingProfileResponse,
	IGuardianReconciliationMappingProfileContainerTabs
> {
	constructor() {
		super(
			'Reconciliation Setup',
			'reconciliationMapping',
			'guardianReconciliationMappingId',
			GuardianReconciliationMappingProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(
			RtVueApiRoutes.GuardianReconciliationMappings
		);
	}

	public getIndexLabel(record: GuardianReconciliationMappingIndexResponse) {
		return record.summary ?? record.guardianReconciliationMappingId.toString();
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(
		profile: GuardianReconciliationMappingProfileResponse
	) {
		return (
			profile.summary ?? profile.guardianReconciliationMappingId.toString()
		);
	}
}

//IMPORTANT -- must add router to ApplicationRouters
export const GuardianReconciliationMappingsRouter =
	new GuardianReconciliationMappingsRouterClass();
