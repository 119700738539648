import {
	TagAssignmentIndexRequest,
	TagAssignmentIndexResponse,
	TagIndexResponse
} from 'RtModels';
import { TagTypeSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/TagTypeSelect';
import { TagDataGrid } from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/TagDataGrid';
import { TagAssignmentRouter } from 'RtUi/app/rtSip/TagAssignment/TagAssignment.router';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';

interface IInitialResourceParams {
	value: string;
	tag: TagIndexResponse | undefined;
}

interface ITagAssignmentIndexContainerState
	extends ISearchResultsContainerState<TagAssignmentIndexRequest>,
		IInitialResourceParams {}

@TagAssignmentRouter.getBusinessOpsIndexRtUiController()
export class TagAssignmentIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	ITagAssignmentIndexContainerState
> {
	public initParams: IInitialResourceParams = {
		tag: undefined,
		value: ''
	};

	public state: ITagAssignmentIndexContainerState = {
		activeTab: this.tabs.Results,
		resourceParams: {},
		...this.initParams
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { tag, value } = this.state;
		const resourceParams: TagAssignmentIndexRequest = {};

		if (tag?.tag) {
			resourceParams.tag = tag.tag;
		}

		if (value.length > 0) {
			resourceParams.tagVal = value;
		}

		return resourceParams;
	}

	public render() {
		return (
			<TabbedLayout
				router={TagAssignmentRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<TagTypeSelect
							onChange={(tag) => this.setState({ tag })}
							value={this.state.tag}
						/>
						<InputFormControl
							label="Tag value"
							value={this.state.value}
							onChange={(value) => this.setState({ value })}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<TagDataGrid<TagAssignmentIndexResponse>
						requestParams={this.state.resourceParams}
						isAssignment
						pageName={'rtSip_tagAssignment'}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
