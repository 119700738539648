import { FC, useState } from 'react';
import { useMount } from 'react-use';
import { ServerGroupMemberProfileResponse } from 'RtModels';
import { ServerGroupMembersEditor } from 'RtUi/app/rtAdmin/ServerGroupMembers/lib/forms/ServerGroupMembersEditor';
import { ServerGroupMembersResource } from 'RtUi/app/rtAdmin/ServerGroupMembers/lib/resource/ServerGroupMembersResource';
import { ServerGroupMembersRouter } from 'RtUi/app/rtAdmin/ServerGroupMembers/ServerGroupMembers.router';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const ServerGroupMembersProfileContainer: FC = () => {
	const entityId = useIdParam();
	const [tabs, activeTab, setActiveTab] = useTabs(
		ServerGroupMembersRouter.getProfileTabs()
	);
	const [entity, setEntity] = useState<ServerGroupMemberProfileResponse>();
	useMount(async () => {
		const resource = new ServerGroupMembersResource();
		const newEntity = await resource.get(entityId);

		setEntity(newEntity);
	});

	const canManageWithRecord = ServerGroupMembersRouter.canManageWith(entity);

	if (!entity) {
		return <Loading />;
	}

	return (
		<TabbedLayout<ServerGroupMemberProfileResponse>
			router={ServerGroupMembersRouter}
			profile={entity}
			canManageFn={() => canManageWithRecord}
			activeTab={activeTab.header}
			onTabChange={(at) => {
				setActiveTab(at);
			}}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<ServerGroupMembersEditor editMode={entity} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

ServerGroupMembersProfileContainer.displayName =
	'ServerGroupMembersProfileContainer';

ServerGroupMembersRouter.setProfileRtUiFunctionalComponent(
	ServerGroupMembersProfileContainer
);
