import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from 'RtUi/components/form/FormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PhoneNumberFormatter } from 'RtUi/utils/phone/PhoneNumberFormatter';
import { inRange } from 'lodash-es';

interface IE164NumberFormControlState extends IFormControlState {
	errorText?: string;
}

interface IE164NumberFormControlProps extends IFormControlProps<string, false> {
	loose?: boolean; //allow for non e164 compliant inputs
	label: string;
}

export class E164NumberFormControl extends FormControl<
	string,
	false,
	IE164NumberFormControlProps,
	IE164NumberFormControlState
> {
	public resource = undefined;
	public state: IE164NumberFormControlState = {
		formLabel: 'Number'
	};

	public onChangeInternal(value: string) {
		const { onChange = () => ({}) } = this.props;

		this.setState({ errorText: undefined });

		if (this.props.loose) {
			onChange(value);
			return;
		}

		if (!value.startsWith('+')) {
			value = `+${value}`;
		}

		const phoneNumberFormatter = new PhoneNumberFormatter();
		const awesomePhone = phoneNumberFormatter.toPhoneNumber(value);

		const isSatelliteNumber =
			inRange(value.length, 12, 15) &&
			['+881', '+882', '+883'].some((n) => value.startsWith(n));

		if (!awesomePhone.possible && !isSatelliteNumber) {
			this.setState({
				errorText: `${this.props.label} must be in E164 format.`
			});
		}

		onChange(value);
	}

	public render() {
		const { value = '' } = this.props;
		const phoneNumberFormatter = new PhoneNumberFormatter();
		const possibleE164 = phoneNumberFormatter.toE164(value);
		const formattedValue = possibleE164 ?? value;

		return (
			<InputFormControl
				id={this.props.id}
				customValidityError={this.state.errorText}
				label={this.props.label}
				displayMode={this.props.displayMode}
				className="text-monospace form-control form-control-tollfree"
				onChange={(newValue) => this.onChangeInternal(newValue)}
				required={this.props.required}
				name={this.props.name}
				value={formattedValue}
			/>
		);
	}
}
