import {
	TCprLergSearchForKeyResults,
	TLergLookups
} from 'RtUi/app/rt800/Cprs/lib/util/Constants';
import { CprLbl, CprLergCache, CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2';

export class CprLergHighlightHelper {
	//cSpell:ignore ABCDEFGHIJKLMNOPQRSTUVWXYZ
	public static readonly Alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	public static readonly Digits = '0123456789';

	constructor() {
		if (!CprLergCache.HasCacheBeenInitialized()) {
			console.warn('CprLergCache has not been initialized');
		}
	}

	/**
	 * Search for labels via their names
	 * NOTE: searchStr should be all upper case
	 */
	public searchForLabel(
		labels: CprLbl[],
		searchStr: string,
		maxResults = 5
	): TCprLergSearchForKeyResults {
		const cprKeyResult: TCprLergSearchForKeyResults = {};
		let foundCount = 0;

		for (const label of labels) {
			if (foundCount >= maxResults) {
				break;
			}
			const labelName = label.getName();

			if (labelName.includes(searchStr)) {
				const labelCprNodeType = label.getCprNodeType();

				if (!labelCprNodeType) {
					continue;
				}

				const suggestions = cprKeyResult[labelCprNodeType] ?? [];

				suggestions.push(labelName);

				cprKeyResult[labelCprNodeType] = suggestions;

				foundCount++;
			}
		}

		return cprKeyResult;
	}

	/**
	 * Given a searchStr, search for cics, states, latas, npas, or npanxxs.
	 */
	public async searchForCprKey(
		searchStr: string
	): Promise<TCprLergSearchForKeyResults> {
		searchStr = searchStr.trim();
		const searchStrings = [searchStr];
		const lookups = this.getLookups();
		const cprKeyResult: TCprLergSearchForKeyResults = {};

		if (/^\d{4}$/.test(searchStr)) {
			cprKeyResult[CprNodeType.Carrier] = [searchStr];
		}

		//Pad searches for state lookups
		if (searchStr.length === 1 && /\w/.test(searchStr)) {
			for (const character of CprLergHighlightHelper.Alphabet) {
				searchStrings.push(`${searchStr}${character}`);
			}
		}

		//Pad searches for lata/npa/npanxx lookups
		if ([2, 5].includes(searchStr.length)) {
			const hasTwoDigits = /^\d\d$/.test(searchStr);
			const hasFiveDigits = /^\d\d\d\d\d$/.test(searchStr);
			const shouldSearchForOneMoreDigit = hasTwoDigits || hasFiveDigits;

			if (shouldSearchForOneMoreDigit) {
				for (const digit of CprLergHighlightHelper.Digits) {
					searchStrings.push(`${searchStr}${digit}`);
				}
			}
		}

		for (const keyOfRes in lookups) {
			if (keyOfRes in lookups) {
				const keyNodeType = keyOfRes as CprNodeType;
				const lookup = lookups[keyNodeType]!;

				for (const searchWord of searchStrings) {
					if (searchWord in lookup) {
						if (!(keyOfRes in cprKeyResult)) {
							cprKeyResult[keyNodeType] = [];
						}

						cprKeyResult[keyNodeType]!.push(searchWord);
					}
				}
			}
		}

		return cprKeyResult;
	}

	public getSearchables() {
		return [
			CprNodeType.AreaCode,
			CprNodeType.SixDigit,
			CprNodeType.NpaNxx,
			CprNodeType.Lata,
			CprNodeType.State,
			CprNodeType.Carrier
		];
	}

	private getLookups(): TLergLookups {
		return {
			[CprNodeType.State]: CprLergCache.CprLergByState,
			[CprNodeType.Lata]: CprLergCache.CprLergByLata,
			[CprNodeType.AreaCode]: CprLergCache.CprLergByNpa,
			[CprNodeType.SixDigit]: CprLergCache.CprLergByNpaNxx,
			[CprNodeType.NpaNxx]: CprLergCache.CprLergByNpaNxx
		};
	}
}
