/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateExpireMode {
    Atoz = 1,
    Exact = 2,
    Country = 3,
    ExactPlus = 4,
}