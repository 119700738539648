import { TrendAnalysisIndexRequest } from 'RtModels';
import { useGetTrendAnalysis } from 'RtUi/app/rtSip/TrendAnalysis/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getTrendAnalysisColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/trendAnalysis';
import { useMemo } from 'react';

interface ITrendAnalysisDataGridProps {
	autoFocusFilter?: boolean;
	resourceParams: TrendAnalysisIndexRequest;
}

export const TrendAnalysisDataGrid = ({
	resourceParams
}: ITrendAnalysisDataGridProps) => {
	const { data, isFetching: isLoading } = useGetTrendAnalysis(resourceParams);
	const columns = useMemo(() => getTrendAnalysisColumns(), []);

	return (
		<DataGrid
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_trendAnalysis'}
		/>
	);
};
