//import * as React from 'react';
import { ManagedRespOrgIndexResponse } from 'RtModels';
import { ManagedRespOrgRouter } from 'RtUi/app/rtAdmin/ManagedRespOrgs/ManagedRespOrg.router';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ManagedRespOrgResource } from '../resources/ManagedRespOrgResource';

interface IManagedRespOrgSelectSelectProps
	extends ISelectFormControlProps<ManagedRespOrgIndexResponse> {
	// allowAll?: boolean;
	// isActive?: boolean | null;
	// entityId?: string;
	// autoSelectIfSingleOption?: boolean;
}

export class ManagedRespOrgSelect extends SelectFormControl<
	ManagedRespOrgIndexResponse,
	false,
	IManagedRespOrgSelectSelectProps
> {
	public static defaultProps = {
		router: ManagedRespOrgRouter
	};
	public resourceClass = ManagedRespOrgResource;
	public state: ISelectFormControlState<ManagedRespOrgIndexResponse> = {
		formLabel: 'Managed RespOrg',
		valueKey: 'respOrgId',
		labelKey: 'respOrgId'
	};
}
