import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianAlertRecipientIndexRequest,
	GuardianFraudAlertRecipientIndexResponse,
	GuardianFraudListIndexRequest,
	GuardianFraudListIndexResponse,
	GuardianFraudSystemConfigIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchFraudConfiguration = async (): Promise<
	FullResponse<GuardianFraudSystemConfigIndexResponse[]>
> => {
	return handleGetRequest<GuardianFraudSystemConfigIndexResponse[], true>(
		RtVueApiRoutes.GuardianFraudSystemConfig.Index,
		{
			includeFullResponse: true
		}
	);
};

export const useGetFraudConfiguration = () => {
	return useQuery<
		FullResponse<GuardianFraudSystemConfigIndexResponse[]>,
		Error
	>('getFraudConfiguration', fetchFraudConfiguration, { enabled: true });
};

const fetchFraudConfigurationList = async (
	urlParams: GuardianFraudListIndexRequest
): Promise<FullResponse<GuardianFraudListIndexResponse[]>> => {
	return handleGetRequest<GuardianFraudListIndexResponse[], true>(
		RtVueApiRoutes.GuardianFraudList.Index,
		{
			urlParams,
			includeFullResponse: true
		}
	);
};

export const useGetFraudConfigurationList = (
	resourceParams: GuardianFraudListIndexRequest
) => {
	return useQuery<FullResponse<GuardianFraudListIndexResponse[]>, Error>(
		'getFraudConfigurationList',
		() => fetchFraudConfigurationList(resourceParams),
		{ enabled: true }
	);
};

const fetchFraudRecipients = async (
	urlParams?: GuardianAlertRecipientIndexRequest
): Promise<FullResponse<GuardianFraudAlertRecipientIndexResponse[]>> => {
	return handleGetRequest<GuardianFraudAlertRecipientIndexResponse[], true>(
		RtVueApiRoutes.GuardianFraudAlertsRecipients.Index,
		{
			urlParams,
			includeFullResponse: true
		}
	);
};

export const useGetFraudRecipients = (
	resourceParams?: GuardianAlertRecipientIndexRequest
) => {
	return useQuery<
		FullResponse<GuardianFraudAlertRecipientIndexResponse[]>,
		Error
	>('useGetFraudRecipients', () => fetchFraudRecipients(resourceParams), {
		enabled: true
	});
};
