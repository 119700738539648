import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import {
	AreaOfServiceAllValueTypes,
	AreaOfServiceValuesResource,
	INetworkAreaOfServiceValuesType
} from '../resources/AreaOfServiceValuesResource';

interface INetworkAreaOfServiceValuesSelectProps<
	isMulti extends boolean = false
> extends ISelectFormControlProps<INetworkAreaOfServiceValuesType, isMulti> {
	useControlGroup?: boolean;
}

export class NetworkAreaOfServiceValuesSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	INetworkAreaOfServiceValuesType,
	isMulti,
	INetworkAreaOfServiceValuesSelectProps<isMulti>
> {
	public resourceClass = undefined;
	public resource = new AreaOfServiceValuesResource();
	public state: ISelectFormControlState<INetworkAreaOfServiceValuesType> = {
		formLabel: 'Network Value',
		valueKey: 'value',
		labelKey: 'label'
	};

	public optionRenderer = (aosType: INetworkAreaOfServiceValuesType) => {
		let { label } = aosType;

		if (!label) {
			const valueType = AreaOfServiceAllValueTypes.find(
				({ value }) => value === aosType.value
			);

			if (valueType) {
				label = valueType.label;
			}
		}

		return <span>{label}</span>;
	};

	public triggerOnChange(
		value: isMulti extends true
			? INetworkAreaOfServiceValuesType[]
			: INetworkAreaOfServiceValuesType
	) {
		if (value instanceof Array) {
			const nonCombinationalValues = value.filter(
				(v: INetworkAreaOfServiceValuesType) => v.nonCombinational
			);
			const nonCombinationalValue = nonCombinationalValues.pop();

			if (nonCombinationalValue) {
				//@ts-ignore
				value = [nonCombinationalValue]; //get last one selected
			}
		}

		return super.triggerOnChange(value);
	}
}
