import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SipAgentCreateRequest, SipAgentProfileResponse } from 'RtModels';
import { SipAgentsResource } from 'RtUi/app/AccountManagement/SipAgents/lib/resources/SipAgentsResource';
import { FqdnInputFormControl } from 'RtUi/components/form/FqdnInputFormControl';
import { isValidFqdn } from 'RtUi/app/AccountManagement/Switches/lib/utils';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';
import { PortInputFormControl } from 'RtUi/components/form/PortFormControl';

interface ISipAgentEditorProps {
	value?: SipAgentProfileResponse;
	createMode?: boolean;
	onSuccess?: () => void;
	onCancel?: () => void;
}

export const SipAgentEditor = ({
	value,
	createMode = false,
	onSuccess = () => {},
	onCancel = () => {}
}: ISipAgentEditorProps): JSX.Element => {
	const resource = useMemo(() => new SipAgentsResource(), []);
	const [isEditing, setIsEditing] = useState<boolean>(createMode);
	const [error, setError] = useState<RtError>();
	const {
		control,
		handleSubmit,
		formState: { isSubmitting }
	} = useForm<SipAgentProfileResponse>({
		defaultValues: value
	});

	const onSubmitHandler = async (
		data: SipAgentCreateRequest | SipAgentProfileResponse
	) => {
		setError(undefined);

		if (!isValidFqdn(data.fqdn)) {
			setError(new RtError({ message: 'Invalid FQDN!' }));
			return;
		}

		try {
			if (value) {
				await resource.update(data as SipAgentProfileResponse);
			} else {
				await resource.create(data as SipAgentCreateRequest);
			}
			onSuccess();
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<RtUiNarrowForm
			error={error}
			isSubmitting={isSubmitting}
			displayMode={!isEditing}
			onChange={(val) => setIsEditing(!val)}
			onSubmit={handleSubmit(onSubmitHandler)}
			onCancel={onCancel}
		>
			<Controller
				control={control}
				name="label"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Label"
						value={value}
						onChange={onChange}
						required
						displayMode={!isEditing}
					/>
				)}
			/>
			<Controller
				control={control}
				name="fqdn"
				render={({ field: { onChange, value } }) => (
					<FqdnInputFormControl
						label="FQDN"
						value={value}
						onChange={onChange}
						required
						displayMode={!isEditing}
					/>
				)}
			/>
			<Controller
				control={control}
				name="port"
				render={({ field: { onChange, value } }) => (
					<PortInputFormControl
						label="Port"
						value={String(value)}
						onChange={(val) => onChange(Number(val))}
						displayMode={!isEditing}
					/>
				)}
			/>
			<Controller
				control={control}
				name="isActive"
				render={({ field: { onChange, value } }) => (
					<IsActiveRadioFormControl
						value={value}
						onChange={onChange}
						hideBothOption
						displayMode={!isEditing}
					/>
				)}
			/>
		</RtUiNarrowForm>
	);
};
