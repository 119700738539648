import * as React from 'react';

export const BeautifulNight: React.FC<
	React.PropsWithChildren<unknown>
> = () => {
	return (
		<div className="beautifulNight">
			<div className="star s1"></div>
			<div className="star s2"></div>
			<div className="star s3"></div>
			<div className="star s4"></div>
			<div className="star s5"></div>
			<div className="star s6"></div>
			<div className="star s7"></div>
			<div className="moon1"></div>
			<div className="moon"></div>
			<div className="shooting"></div>
			<div className="mountains1">
				<div className="m1"></div>
				<div className="m2"></div>
				<div className="m3"></div>
				<div className="m4"></div>
			</div>
			<div className="land">
				<div className="windmill">
					<div className="light"></div>
					<div className="door"></div>
					<div className="top"></div>
					<div className="blades">
						<div className="bl bl1"></div>
						<div className="bl bl2"></div>
						<div className="bl bl3"></div>
						<div className="bl bl4"></div>
					</div>
				</div>
				<div className="tree"></div>
			</div>
		</div>
	);
};
