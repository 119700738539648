import moment from 'moment';
import React, { FC, memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useGetLrnData } from 'RtUi/app/rtVue/DialCode/lib/services';

interface ILrnGridProps {
	nanpNumber: string;
}

export const LrnGrid: FC<React.PropsWithChildren<ILrnGridProps>> = memo(
	({ nanpNumber }) => {
		const { data, isLoading } = useGetLrnData(nanpNumber);

		if (isLoading) {
			return <></>;
		}

		if (!data) {
			return (
				<div className="d-flex p-5 align-items-center justify-content-center gap-3">
					<i className="fas fa-2x fa-info-circle" />
					<p className="text-center mb-0 fs-2 fw-semibold">Lrn not found</p>
				</div>
			);
		}

		let activatedDate: string | undefined;
		if (data.activated) {
			activatedDate = moment(data.activated).format('YYYY-MM-DD');
		}

		return (
			<div className="p-4">
				<Row>
					<Col md={6}>
						<span className="fw-bolder fs-5">tn</span>
						<div>{data.tn}</div>
					</Col>
					<Col md={6}>
						<span className="fw-bolder fs-5">lrn</span>
						<div>{data.lrn}</div>
					</Col>
				</Row>
				<Row className="pt-4">
					<Col md={6}>
						<span className="fw-bolder fs-5">Op Company Number</span>
						<div>{data.ocn}</div>
					</Col>
					<Col md={6}>
						<span className="fw-bolder fs-5">Op Company Name</span>
						<div>{data.operatingCompanyName}</div>
					</Col>
				</Row>
				{activatedDate && (
					<Row className="pt-4">
						<Col md={6}>
							<span className="fw-bolder fs-5">Activated</span>
							<div>{activatedDate}</div>
						</Col>
					</Row>
				)}
			</div>
		);
	}
);
