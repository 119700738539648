import { LcrTaskCarrierResultIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { LcrCarriersDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LcrCarriersDataGridColumn';
import { MinutesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MinutesDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { meanBy } from 'lodash-es';

export const getLcrTaskCarrierResultsColumns = (): Array<
	DataGridColumn<LcrTaskCarrierResultIndexResponse>
> => [
	LcrCarriersDataGridColumn({
		header: 'LCR Account',
		accessorKey: 'lcrCarrierId'
	}),
	DefaultDataGridColumn({
		header: 'CIC',
		accessorKey: 'cic',
		align: DataGridColumnAlignment.CENTER
	}),
	MinutesDataGridColumn({
		header: 'Total Minutes',
		accessorKey: 'totalMinutes',
		showFooter: true
	}),
	PrecisionDataGridColumn({
		header: 'Total Charges',
		accessorKey: 'totalCharges',
		showFooter: true
	}),
	DefaultDataGridColumn({
		header: 'Total CPM',
		accessorFn: (record) =>
			Number(record.totalMinutes) > 0
				? (Number(record.totalCharges) / Number(record.totalMinutes)).toFixed(6)
				: 0,
		getFooter: ({ table }) => {
			const rows = table
				.getPrePaginationRowModel()
				.rows.map((row) => row.original as LcrTaskCarrierResultIndexResponse);

			return String(
				(meanBy(rows, 'totalCharges') / meanBy(rows, 'totalMinutes')).toFixed(6)
			);
		}
	}),
	IntegerDataGridColumn({
		header: 'Non LCR Minutes',
		accessorKey: 'nonLcrMinutes',
		isHidden: true
	}),
	IntegerDataGridColumn({
		header: 'Non LCR Charges',
		accessorKey: 'nonLcrCharges',
		isHidden: true
	}),
	PrecisionDataGridColumn({
		header: 'Non LCR CPM',
		decimals: 6,
		accessorFn: (record) =>
			Number(record.nonLcrMinutes) > 0
				? Number(record.nonLcrCharges) / Number(record.nonLcrMinutes)
				: 0
	}),
	IntegerDataGridColumn({
		header: 'Known Minutes',
		accessorKey: 'minutes',
		isHidden: true
	}),
	IntegerDataGridColumn({
		header: 'Known Charges',
		accessorKey: 'charges',
		isHidden: true
	}),
	PrecisionDataGridColumn({
		header: 'Known CPM',
		decimals: 6,
		accessorFn: (record) =>
			Number(record.minutes) > 0
				? Number(record.charges) / Number(record.minutes)
				: 0
	}),
	IntegerDataGridColumn({
		header: 'Unknown Minutes',
		accessorKey: 'unknownMinutes',
		isHidden: true
	}),
	IntegerDataGridColumn({
		header: 'Unknown Charges',
		accessorKey: 'unknownCharges',
		isHidden: true
	}),
	PrecisionDataGridColumn({
		header: 'Unknown CPM',
		decimals: 6,
		accessorFn: (record) =>
			Number(record.unknownMinutes) > 0
				? Number(record.unknownCharges) / Number(record.unknownMinutes)
				: 0
	}),
	IntegerDataGridColumn({
		header: 'Canada Minutes',
		accessorKey: 'canadaMinutes',
		isHidden: true
	}),
	IntegerDataGridColumn({
		header: 'Canada Charges',
		accessorKey: 'canadaCharges',
		isHidden: true
	}),
	PrecisionDataGridColumn({
		header: 'Canada CPM',
		decimals: 6,
		accessorFn: (record) =>
			Number(record.canadaMinutes) > 0
				? Number(record.canadaCharges) / Number(record.canadaMinutes)
				: 0
	})
];
