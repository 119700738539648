import { isEqual } from 'lodash-es';
import * as moment from 'moment-timezone';
import { MgiCprIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { cprStatusIdToName, timestampToReadable } from 'RtUi/utils/maps';

interface IMgiCprIndexOption extends MgiCprIndexResponse {
	disabled?: boolean;
}

interface ICloneRoutingEffectiveTimestampSelectProps
	extends ISelectFormControlProps<IMgiCprIndexOption> {
	options: MgiCprIndexResponse[];
}

export class CloneRoutingEffectiveTimestampSelect extends SelectFormControl<
	IMgiCprIndexOption,
	false,
	ICloneRoutingEffectiveTimestampSelectProps
> {
	public resourceClass = undefined;
	public state: ISelectFormControlState<IMgiCprIndexOption> = {
		formLabel: 'Effective Timestamp for Routing',
		placeholder: 'Select Effective Timestamp...',
		valueKey: 'value',
		labelKey: 'value'
	};

	public componentDidMount() {
		this.updateOptions(this.props.options);

		super.componentDidMount();
	}

	public componentDidUpdate(
		prevProps: ICloneRoutingEffectiveTimestampSelectProps
	) {
		const currentOptions = this.props.options;
		const prevOptions = prevProps.options;

		if (!isEqual(currentOptions, prevOptions)) {
			this.updateOptions(currentOptions);
		}

		super.componentDidUpdate(prevProps);
	}

	public optionRenderer = (option: MgiCprIndexResponse) => {
		const momentVal = moment(option.effectiveTs);
		const readableStr = timestampToReadable(momentVal);
		const cprStatusName = cprStatusIdToName(option.cprStatusId);

		return (
			<span>
				{readableStr} ({cprStatusName})
			</span>
		);
	};

	public updateOptions(options: MgiCprIndexResponse[]) {
		const resourceOptions: IMgiCprIndexOption[] = options.map((option) => {
			const cprStatusName = cprStatusIdToName(option.cprStatusId);
			const disabled =
				cprStatusName === 'Failed' || cprStatusName === 'Invalid';

			return { ...option, disabled };
		});

		this.resource = new ArrayResource<IMgiCprIndexOption>(
			'effectiveTs',
			resourceOptions
		);
		this.reloadOptionsOnFocus();
		this.loadOptions();
	}
}
