import { RtCommonApiRoutes, RtxSipApiRoutes } from 'RtExports/routes';
import {
	RatePlanProfileUrlRequest,
	RatePlanRateIndexRequest,
	RatePlanRateIndexResponse,
	ServiceIndexRequest,
	ServiceIndexResponse,
	SubscriptionIndexRequest,
	SubscriptionIndexResponse,
	SubscriptionProfileRequest,
	SubscriptionQosIndexRequest,
	SubscriptionQosIndexResponse,
	SubscriptionScheduleIndexRequest,
	SubscriptionScheduleIndexResponse,
	SubscriptionScheduleIndexUrlRequest,
	SubscriptionTagIndexRequest,
	SubscriptionTagIndexResponse,
	TagAssignmentIndexRequest,
	TagMatchingIndexRequest,
	TagMatchingIndexResponse,
	SubscriptionValidationIndexResponse,
	SubscriptionProfileResponse
} from 'RtModels';
import {
	IsAssignment,
	IsMatching,
	TagRequest,
	TagResponse
} from 'RtUi/app/AccountManagement/Subscriptions/lib/grids/TagDataGrid';
import {
	FullResponse,
	handleGetRequest,
	RequestResponse
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchSubscriptions = async <
	IncludeFullResponse extends boolean = false
>(
	queryParams?: SubscriptionIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<
	RequestResponse<SubscriptionIndexResponse[], IncludeFullResponse>
> => {
	return handleGetRequest(RtxSipApiRoutes.Subscriptions.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetSubscriptions = (queryParams?: SubscriptionIndexRequest) => {
	return useQuery<FullResponse<SubscriptionIndexResponse[]>, Error>(
		[`useGetSubscriptions`, queryParams],
		() => fetchSubscriptions(queryParams, true),
		{ enabled: true }
	);
};

const fetchSubscription = async (
	urlParams: SubscriptionProfileRequest
): Promise<SubscriptionProfileResponse> => {
	return handleGetRequest(RtxSipApiRoutes.Subscriptions.Profile, {
		urlParams
	});
};

export const useGetSubscription = (urlParams: SubscriptionProfileRequest) => {
	return useQuery<SubscriptionProfileResponse, Error>(
		[`useGetSubscription`, urlParams],
		() => fetchSubscription(urlParams),
		{ enabled: true }
	);
};

const fetchSubscriptionValidation = async (
	urlParams: SubscriptionProfileRequest
): Promise<SubscriptionValidationIndexResponse[]> => {
	return handleGetRequest(RtxSipApiRoutes.SubscriptionValidate.Index, {
		urlParams
	});
};

export const useGetSubscriptionValidation = (
	urlParams: SubscriptionProfileRequest
) => {
	return useQuery<SubscriptionValidationIndexResponse[], Error>(
		[`useGetSubscriptionValidation`, urlParams],
		() => fetchSubscriptionValidation(urlParams),
		{ enabled: true }
	);
};

const fetchServices = async <IncludeFullResponse extends boolean = false>(
	queryParams?: ServiceIndexRequest,
	includeFullResponse?: IncludeFullResponse
): Promise<RequestResponse<ServiceIndexResponse[], IncludeFullResponse>> => {
	return handleGetRequest(RtxSipApiRoutes.Services.Index, {
		queryParams,
		includeFullResponse
	});
};

export const useGetServices = (
	queryParams?: ServiceIndexRequest,
	enabled: boolean = true
) => {
	return useQuery<FullResponse<ServiceIndexResponse[]>, Error>(
		[`useGetServices`, queryParams],
		() => fetchServices(queryParams, true),
		{ enabled }
	);
};

const fetchSubscriptionQos = async (
	urlParams: SubscriptionProfileRequest,
	queryParams?: SubscriptionQosIndexRequest
): Promise<FullResponse<SubscriptionQosIndexResponse[]>> => {
	return handleGetRequest(RtxSipApiRoutes.SubscriptionQos.Index, {
		urlParams,
		queryParams,
		includeFullResponse: true
	});
};

export const useGetSubscriptionQos = (
	urlParams: SubscriptionProfileRequest,
	queryParams?: SubscriptionQosIndexRequest
) => {
	return useQuery<FullResponse<SubscriptionQosIndexResponse[]>, Error>(
		[`useGetSubscriptionQos`, urlParams, queryParams],
		() => fetchSubscriptionQos(urlParams, queryParams),
		{ enabled: true }
	);
};

const fetchRatePlans = async (
	urlParams: RatePlanProfileUrlRequest,
	queryParams?: RatePlanRateIndexRequest
): Promise<FullResponse<RatePlanRateIndexResponse[]>> => {
	return handleGetRequest(RtCommonApiRoutes.RatePlanRates.Index, {
		urlParams,
		queryParams,
		includeFullResponse: true
	});
};

export const useRatePlans = (
	urlParams: RatePlanProfileUrlRequest,
	queryParams?: RatePlanRateIndexRequest,
	enabled: boolean = true
) => {
	return useQuery<FullResponse<RatePlanRateIndexResponse[]>, Error>(
		[`useGetRatePlans`, urlParams, queryParams],
		() => fetchRatePlans(urlParams, queryParams),
		{ enabled }
	);
};

const fetchSubscriptionSchedules = async (
	urlParams: SubscriptionScheduleIndexUrlRequest,
	queryParams?: SubscriptionScheduleIndexRequest
): Promise<FullResponse<SubscriptionScheduleIndexResponse[]>> => {
	return handleGetRequest(RtxSipApiRoutes.Schedules.Index, {
		urlParams,
		queryParams,
		includeFullResponse: true
	});
};

export const useGetSubscriptionSchedules = (
	urlParams: SubscriptionScheduleIndexUrlRequest,
	queryParams?: SubscriptionScheduleIndexRequest
) => {
	return useQuery<FullResponse<SubscriptionScheduleIndexResponse[]>, Error>(
		[`useGetSubscriptionSchedules`, urlParams, queryParams],
		() => fetchSubscriptionSchedules(urlParams, queryParams),
		{ enabled: true }
	);
};

const fetchSubscriptionTags = async (
	urlParams: SubscriptionProfileRequest
): Promise<FullResponse<SubscriptionTagIndexResponse[]>> => {
	return handleGetRequest(RtxSipApiRoutes.SubscriptionTags.Index, {
		urlParams,
		includeFullResponse: true
	});
};

const fetchTagMatching = async (queryParams?: TagMatchingIndexRequest) => {
	return handleGetRequest<TagMatchingIndexResponse[], true>(
		RtxSipApiRoutes.TagMatching.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

const fetchTagAssignment = async (
	queryParams?: SubscriptionTagIndexRequest
) => {
	return handleGetRequest<SubscriptionTagIndexResponse[], true>(
		RtxSipApiRoutes.TagAssignment.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetTags = <T extends TagResponse>(
	urlParams: TagRequest<T>,
	isMatching?: IsMatching<T>,
	isAssignment?: IsAssignment<T>
) => {
	return useQuery<FullResponse<TagResponse[]>, Error>(
		[`useGetSubscriptionTags`, urlParams],
		() => {
			if (isMatching) {
				return fetchTagMatching(urlParams as TagMatchingIndexRequest);
			}

			if (isAssignment) {
				return fetchTagAssignment(urlParams as TagAssignmentIndexRequest);
			}

			return fetchSubscriptionTags(urlParams as SubscriptionProfileRequest);
		},
		{ enabled: true }
	);
};
