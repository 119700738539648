import { Fragment, useCallback, useMemo, useState } from 'react';
import {
	GuardianAlertRecipient,
	GuardianAlertRecipientIndexRequest
} from 'RtModels';
import { AlertsRecipientsEditor } from 'RtUi/app/rtGuardian/AlertRules/lib/forms/AlertsRecipientsEditor';
import { AlertsRecipientsResource } from 'RtUi/app/rtGuardian/AlertRules/lib/resources/AlertsRecipientsResource';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { getAlertRuleRecipientsColumns } from 'RtUi/components/data/DataGrid/configurations/rtGuardian/alertRules';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { useGetGuardianAlertRecipients } from 'RtUi/app/rtGuardian/AlertRules/lib/services';
import { UserNameRenderCache } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';
import { useMount } from 'react-use';

interface IAlertsRecipientsGridProps {
	resourceParams: GuardianAlertRecipientIndexRequest;
	pageName: string;
}

export const AlertsRecipientsGrid = ({
	resourceParams,
	pageName
}: IAlertsRecipientsGridProps): JSX.Element => {
	const [asideIsOpen, setAsideOpen] = useState(false);
	const [selectedRecord, setSelectedRecord] =
		useState<GuardianAlertRecipient>();

	useMount(() => {
		UserNameRenderCache.warmup();
	});

	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetGuardianAlertRecipients(resourceParams);

	const removeEntity = useCallback(
		async (row: GuardianAlertRecipient) => {
			const alertsRecipientsResource = new AlertsRecipientsResource();
			alertsRecipientsResource.setGetAllParams({
				guardianAlertId: resourceParams.guardianAlertId
			});
			const confirm = await Confirmation.createDelete(
				UserNameRenderCache.getLabelFor(row.userId)
			);

			if (!confirm) {
				return;
			}

			await alertsRecipientsResource.delete(row.guardianAlertRecipientId);
			refetch();
		},
		[resourceParams, refetch]
	);

	const columns = useMemo(
		() => getAlertRuleRecipientsColumns(removeEntity),
		[removeEntity]
	);

	return (
		<>
			{asideIsOpen && (
				<Aside isOpen={asideIsOpen} disabledBody>
					<Fragment key={selectedRecord?.guardianAlertRecipientId}>
						<Aside.Header
							warning={Boolean(selectedRecord)}
							header="Recipient"
							onClose={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
						/>
						<AlertsRecipientsEditor
							guardianAlertId={resourceParams.guardianAlertId!}
							editMode={selectedRecord}
							onCancel={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
							onSuccess={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
								refetch();
							}}
						/>
					</Fragment>
				</Aside>
			)}
			<DataGrid<GuardianAlertRecipient>
				data={data?.data}
				loading={isLoading}
				pageName={pageName}
				onRowClick={(row) => {
					setAsideOpen(true);
					setSelectedRecord(row);
				}}
				onClickCreate={() => setAsideOpen(true)}
				columns={columns}
				totalRows={data?.totalCount}
				rowThemeColor={(record) => {
					return record.guardianAlertRecipientId ===
						selectedRecord?.guardianAlertRecipientId
						? RowThemeColor.WARNING
						: undefined;
				}}
			/>
			<Confirmation />
		</>
	);
};
