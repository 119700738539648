import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export abstract class ManagedResource<Index, Profile> extends HttpResource<
	Index,
	Profile
> {
	public abstract create(
		id: string,
		isActive: number,
		isManaged: number,
		isApi: number,
		contactName: string,
		contactNumber: string,
		apiResourceId: number | null
	): Promise<Profile>;
	public abstract update(
		id: string,
		isActive: number,
		isManaged: number,
		isApi: number,
		contactName: string,
		contactNumber: string,
		apiResourceId: number | null
	): Promise<Profile>;
	public abstract updateApi(
		id: string,
		apiUserName: string,
		apiPassword: string
	): Promise<Profile>;
	public abstract deleteApi(id: string): Promise<Profile>;
	public abstract updateRoc(
		id: string,
		rocUserName: string,
		rocPassword: string
	): Promise<Profile>;
	public abstract deleteRoc(id: string): Promise<Profile>;
	public abstract updateSql(
		id: string,
		sqlUserName: string,
		sqlPassword: string
	): Promise<Profile>;
	public abstract deleteSql(id: string): Promise<Profile>;
	public abstract updateMgi(
		id: string,
		mgiUserName: string,
		mgiRespOrg: string
	): Promise<Profile>;
	public abstract deleteMgi(id: string): Promise<Profile>;
}

export type IManagedResourceClass<
	Index = any,
	Profile = any
> = new () => ManagedResource<Index, Profile>;
