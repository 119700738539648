import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from 'RtUi/components/form/FormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

interface IIpAddressFormControlState extends IFormControlState {
	customValidityError: string;
}

interface IIpAddressFormControlProps extends IFormControlProps<string, false> {
	label: string;
	useControlGroup?: boolean;
	autoFocus?: boolean;
}

const ipv4Maybe =
	/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

export class IpAddressFormControl extends FormControl<
	string,
	false,
	IIpAddressFormControlProps,
	IIpAddressFormControlState
> {
	public resource?: HttpResource<string, string>;
	public state: IIpAddressFormControlState = {
		formLabel: '',
		customValidityError: ''
	};

	public inputRef: HTMLInputElement | null = null;

	public componentDidMount() {
		super.componentDidMount();

		if (this.props.autoFocus && this.inputRef) {
			this.inputRef.focus();
		}
	}

	public componentDidUpdate(prevProps: IIpAddressFormControlProps) {
		super.componentDidUpdate(prevProps);

		if (prevProps.required !== this.props.required) {
			this.setState({ customValidityError: '' });
		}
	}

	public onChangeInternal(value: string) {
		const { onChange = () => ({}) } = this.props;

		value = value.replace(/[^\d\.]/g, '');
		let customValidityError = '';

		if (this.props.required) {
			if (!ipv4Maybe.test(value)) {
				customValidityError =
					"Must be a valid IP format. E.g '123.123.123.123'";
			}
		}

		if (this.state.customValidityError !== customValidityError) {
			this.setState({ customValidityError });
		}

		onChange(value);
	}

	public render() {
		return (
			<InputFormControl
				{...this.props}
				value={this.props.value}
				displayMode={this.props.displayMode}
				required={this.props.required}
				customValidityError={this.state.customValidityError}
				onChange={(evt) => this.onChangeInternal(evt)}
			/>
		);
	}
}
