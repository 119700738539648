/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianDoNotOriginateIndexRequest,
	GuardianDoNotOriginateIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchDoNotOriginate = async (
	queryParams?: GuardianDoNotOriginateIndexRequest
): Promise<FullResponse<GuardianDoNotOriginateIndexResponse[]>> => {
	return handleGetRequest<GuardianDoNotOriginateIndexResponse[], true>(
		RtVueApiRoutes.GuardianDoNotOriginate.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetDoNotOriginate = (
	params?: GuardianDoNotOriginateIndexRequest
) => {
	return useQuery<FullResponse<GuardianDoNotOriginateIndexResponse[]>, Error>(
		['getDoNotOriginate', params],
		() => {
			return fetchDoNotOriginate(params);
		}
	);
};
