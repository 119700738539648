import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { FC, default as React, useState } from 'react';
import {
	RateLoadIndetMethod,
	RatePlanIndexResponse,
	RatePlanProfileResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { RatePlanResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/RatePlanResource';
import { RateLoadIndetMethodSelect } from 'RtUi/app/rtAdmin/Partitions/lib/controls/RateLoadIndetMethod';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtForm } from 'RtUi/components/ui/RtUiForm/RtForm';
import { IDefaultSelectOption } from 'RtUi/components/ui/SelectFormControl';

interface IPartitionRatePlanEditorProps {
	onUpdate: (updatedResource: RatePlanIndexResponse) => void;
	disablePadding?: boolean;
	editMode?: RatePlanProfileResponse;
	onCancel?: () => void;
	createMode?: boolean;
	narrow?: boolean;
	displayModes?: boolean;
}

const resource = new RatePlanResource();

export const PartitionRatePlanEditor: FC<
	React.PropsWithChildren<IPartitionRatePlanEditorProps>
> = ({
	narrow,
	editMode,
	onUpdate,
	onCancel = () => null,
	createMode = false,
	displayModes = false
}) => {
	const [error, setError] = useState();
	const [displayMode, setDisplayMode] = useState<boolean>(displayModes);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [indetMethod, setIndetMethod] =
		useState<IDefaultSelectOption<RateLoadIndetMethod>>();
	const [editingEntity, setEditingEntity] = useState<
		Partial<RatePlanProfileResponse>
	>(
		editMode
			? cloneDeep(editMode)
			: {
					ratePlanId: 0,
					ratePlan: '',
					summary: '',
					isActive: 1,
					subscriptionId: 0,
					rateKeyDeleteDays: 0,
					rateKeyMatchIncreaseDays: 0,
					rateKeyExpandIncreaseDays: 0,
					lastRateLoadTs: null,
					indetMethod: null
				}
	);

	const onChange = <K extends keyof RatePlanProfileResponse>(
		key: K,
		value: RatePlanProfileResponse[K]
	) => {
		if (!isSubmitting) {
			const updated = {
				[key]: value
			};
			setEditingEntity({
				...editingEntity,
				...updated
			});
		}
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setIsSubmitting(true);
		setError(undefined);

		try {
			let newEntity: RatePlanProfileResponse;

			if (editingEntity.isShared) {
				editingEntity.subscriptionId = null;
			}

			if (createMode) {
				newEntity = await resource.createRatePlan(
					editingEntity as RatePlanProfileResponse
				);
			} else {
				newEntity = await resource.updateRatePlan(
					editingEntity.ratePlanId!,
					editingEntity
				);
			}
			setIsSubmitting(false);
			onUpdate(newEntity);
			setDisplayMode(true);
		} catch (err: any) {
			setIsSubmitting(false);
			setError(err);
		}
	};

	const onCancelInternal = () => {
		setEditingEntity(cloneDeep(editMode!));
		setDisplayMode(true);
		onCancel();
	};

	return (
		<RtForm
			narrow={narrow}
			createMode={createMode}
			disablePadding={!createMode}
			onCancel={onCancelInternal}
			isSubmitting={isSubmitting}
			displayMode={displayMode}
			onSubmit={onSubmit}
			error={error}
			onChange={(dMode) => {
				setDisplayMode(dMode);
			}}
		>
			{!createMode && (
				<InputFormControl
					label="ID"
					readOnly={true}
					displayMode={true}
					value={String(editingEntity.ratePlanId)}
					required
				/>
			)}
			<InputFormControl
				label="Rate Plan"
				displayMode={displayMode}
				value={String(editingEntity.ratePlan ?? '')}
				onChange={(value) => onChange('ratePlan', value)}
				name="ratePlan"
			/>
			<InputFormControl
				label="Summary"
				displayMode={displayMode}
				value={String(editingEntity.summary)}
				onChange={(value) => onChange('summary', value)}
				name="summary"
				required
			/>
			<IsActiveRadioFormControl
				hideBothOption={true}
				displayMode={displayMode}
				value={editingEntity.isActive}
				onChange={(value) => onChange('isActive', value)}
			/>
			<BooleanRadioFormControl
				label="Shared"
				showBothOption={false}
				displayMode={displayMode}
				value={editingEntity.isShared}
				required
				onChange={(value) => onChange('isShared', value)}
			/>
			{editingEntity.isShared === 0 && (
				<SubscriptionSelect
					displayMode={displayMode}
					value={subscription}
					initialOptionId={Number(editingEntity.subscriptionId)}
					required
					onChange={(value) => {
						setSubscription(value);
						onChange('subscriptionId', Number(value.subscriptionId));
					}}
				/>
			)}
			<InputFormControl
				type="number"
				label="Delete Dates"
				displayMode={displayMode}
				value={String(editingEntity.rateKeyDeleteDays)}
				onChange={(value) => onChange('rateKeyDeleteDays', Number(value))}
				name="rateKeyDeleteDays"
			/>
			<InputFormControl
				type="number"
				label="Match Increase Days"
				displayMode={displayMode}
				value={String(editingEntity.rateKeyMatchIncreaseDays)}
				onChange={(value) =>
					onChange('rateKeyMatchIncreaseDays', Number(value))
				}
				name="rateKeyMatchIncreaseDays"
			/>
			<InputFormControl
				type="number"
				label="Expand Increase Days"
				displayMode={displayMode}
				value={String(editingEntity.rateKeyExpandIncreaseDays)}
				onChange={(value) =>
					onChange('rateKeyExpandIncreaseDays', Number(value))
				}
				name="rateKeyExpandIncreaseDays"
			/>
			<RateLoadIndetMethodSelect
				value={indetMethod}
				isClearable
				onChange={(indetMethodOpt) => {
					setIndetMethod(indetMethodOpt ?? undefined);
					onChange('indetMethod', indetMethodOpt?.value ?? null);
				}}
				displayMode={displayMode}
				initialOptionId={editingEntity.indetMethod ?? undefined}
			/>
			{editMode && (
				<DatetimeFormControl
					displayMode
					label="Last Rate Load"
					value={
						editingEntity.lastRateLoadTs
							? moment(editingEntity.lastRateLoadTs)
							: undefined
					}
					name="lastRateLoadTs"
				/>
			)}
		</RtForm>
	);
};
