import Dexie from 'dexie';

export function V2LabelInsteadOfSummaryMigration(
	indexedDb: Dexie,
	storeSchema: string[],
	filterName: string
): void {
	indexedDb
		.version(2)
		.stores({
			[filterName]: [...storeSchema, 'summary'].join(',')
		})
		.upgrade((tx) => {
			return tx
				.table(filterName)
				.toCollection()
				.modify((rateCenter) => {
					// Since code is already pushed check if we dealing with cache or not
					if (rateCenter.summary) {
						rateCenter.label = rateCenter.summary;
						delete rateCenter.summary;
					}
				});
		});
}
