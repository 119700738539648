import { DirectConnectIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { PortDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PortDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { dateToReadable } from 'RtUi/utils/maps';
import { addSeconds, isAfter } from 'date-fns';

export const getDirectConnectColumns = (): Array<
	DataGridColumn<DirectConnectIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'name',
		header: 'Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'userEmail',
		header: 'Email'
	}),
	DefaultDataGridColumn({
		accessorKey: 'callerIdName',
		header: 'Caller Id Name'
	}),
	DefaultDataGridColumn({
		accessorKey: 'callerIdNumber',
		header: 'Caller Id Number'
	}),
	IdDataGridColumn({
		accessorKey: 'connectionId',
		header: 'Connection Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressRoute',
		header: 'Egress Route'
	}),
	DefaultDataGridColumn({
		accessorKey: 'userAgent',
		header: 'User Agent'
	}),
	PortDataGridColumn({
		accessorKey: 'ipPort',
		header: 'IP Port'
	}),
	DefaultDataGridColumn({
		header: 'Invite Expiration',
		accessorFn: (record) => {
			const expirationDate = addSeconds(
				new Date(record.updatedTs),
				record.inviteExpiration as number
			);
			return isAfter(new Date(), expirationDate)
				? 'Expired'
				: dateToReadable(expirationDate, true);
		}
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated'
	}),
	DefaultDataGridColumn({
		accessorKey: 'updatedBy',
		header: 'Updated By'
	})
];
