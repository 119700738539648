import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { FileLogReDownloadCreateRequest, GuardianFileLog } from 'RtModels';
import { RtVueApiRoutes } from 'RtExports/routes';

export class FileLogResource extends ArrayResource<GuardianFileLog> {
	constructor() {
		super('fileId');

		this.setApiRouteForGetAll(RtVueApiRoutes.FileLogs.Index);
	}

	public reProcess(request: FileLogReDownloadCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute(RtVueApiRoutes.FileLogsReDownload.Create, {
			body
		});
	}
}
