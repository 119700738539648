/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum SipAgentContactValue {
    VueTrunkGroupId = 20,
    VueTrunkGroupLabel = 21,
    VueTrunkExternalId = 22,
}