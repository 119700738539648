import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { RoutingTemplateTypes } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';

export interface RoutingTemplateDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const RoutingTemplateDataGridColumn = <T = any,>({
	size = 120,
	...config
}: RoutingTemplateDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: RoutingTemplateTypes) => {
		switch (value) {
			case RoutingTemplateTypes.Default: {
				return 'Default';
			}
			case RoutingTemplateTypes.Alternate: {
				return 'Alternate';
			}
			case RoutingTemplateTypes.Generic: {
				return 'Generic';
			}
			case RoutingTemplateTypes.Working: {
				return 'Primary';
			}
			default: {
				return 'Unknown';
			}
		}
	};

	const renderValue = (value: RoutingTemplateTypes) => {
		const label = getValue(value);

		return (
			<section className="d-flex">
				<div
					role="alert"
					style={{ textAlign: 'center' }}
					className={`alert-sm mb-0 p-0 alert alert-info w-100`}
				>
					<span>{label}</span>
				</div>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		exportValue: (value: any) => getValue(value),
		getValue: ({ cell }) => renderValue(cell.getValue<RoutingTemplateTypes>())
	});
};
