import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	SwitchAddressCreateRequest,
	SwitchAddressProfileResponse
} from 'RtModels';
import { FqdnInputFormControl } from 'RtUi/components/form/FqdnInputFormControl';
import { SwitchAddressesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SwitchAddressesResource';
import { isValidFqdn } from 'RtUi/app/AccountManagement/Switches/lib/utils';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';
import { PortInputFormControl } from 'RtUi/components/form/PortFormControl';

interface ISwitchAddressEditorProps {
	value?: SwitchAddressProfileResponse;
	switchId: number;
	onSuccess?: () => void;
	onCancel?: () => void;
}

export const SwitchAddressEditor = ({
	value,
	switchId,
	onSuccess = () => {},
	onCancel = () => {}
}: ISwitchAddressEditorProps) => {
	const resource = useMemo(() => new SwitchAddressesResource(), []);

	const [error, setError] = useState<RtError>();
	const {
		control,
		formState: { isSubmitting },
		handleSubmit
	} = useForm<SwitchAddressCreateRequest>({
		defaultValues: {
			...value,
			switchId
		}
	});

	const onSubmitHandler = async (
		data: SwitchAddressCreateRequest | SwitchAddressProfileResponse
	) => {
		setError(undefined);

		if (!isValidFqdn(data.fqdn)) {
			setError(new RtError({ message: 'Invalid FQDN!' }));
			return;
		}

		try {
			if (value) {
				await resource.update(data as SwitchAddressProfileResponse);
			} else {
				await resource.create(data as SwitchAddressCreateRequest);
			}
			onSuccess();
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<RtUiForm
			onSubmit={handleSubmit(onSubmitHandler)}
			onCancel={onCancel}
			isSubmitting={isSubmitting}
			error={error}
		>
			<Controller
				control={control}
				name="label"
				render={({ field: { onChange, value } }) => (
					<InputFormControl label="Label" value={value} onChange={onChange} />
				)}
			/>
			<Controller
				control={control}
				name="fqdn"
				render={({ field: { onChange, value } }) => (
					<FqdnInputFormControl
						label="FQDN"
						value={value}
						onChange={onChange}
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="port"
				render={({ field: { onChange, value } }) => (
					<PortInputFormControl
						label="Port"
						value={String(value)}
						onChange={(val) => onChange(Number(val))}
					/>
				)}
			/>
			<Controller
				control={control}
				name="isActive"
				render={({ field: { onChange, value } }) => (
					<IsActiveRadioFormControl
						value={value}
						onChange={onChange}
						hideBothOption
					/>
				)}
			/>
		</RtUiForm>
	);
};
