import { FieldValues } from 'react-hook-form';
import { IRtxUiFormProps, RtxUiForm } from 'RtUi/components/rtx/form';
import { SubmitButton } from 'RtUi/components/rtx/form/components/SubmitButton';

type IRtxUiSearchFormProps<T extends FieldValues> = Omit<
	IRtxUiFormProps<T, true>,
	'createMode' | 'submitButton' | 'useUrlParams'
>;

export const RtxUiSearchFormComponent = <T extends FieldValues>(
	props: IRtxUiSearchFormProps<T>
) => {
	return (
		<RtxUiForm<T, true>
			{...props}
			createMode
			submitButton={<SubmitButton text="Search" icon="fa-search fa-sm" />}
			useUrlParams
		>
			{props.children}
		</RtxUiForm>
	);
};
