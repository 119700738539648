import { TaskNumberIndexResponse } from 'RtModels';
import { NumberRouter } from 'RtUi/app/rt800/Numbers/Number.router';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { RespOrgRender } from 'RtUi/app/rt800/RespOrgs/lib/grids/RespOrgRender';
import { Aside } from 'RtUi/components/ui/Aside';
import { timestampToReadable } from 'RtUi/utils/maps';
import { FC, ReactChild } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAsync } from 'react-use';

interface ITaskNumberAside {
	isOpen: boolean;
	onCancel: () => void;
	futuresContent: ReactChild;
	profile: TaskNumberIndexResponse;
}

export const TaskNumberAside: FC<React.PropsWithChildren<ITaskNumberAside>> = ({
	profile,
	isOpen,
	onCancel,
	futuresContent
}) => {
	const respOrgLabel = useAsync(async () => {
		const label = RespOrgRender.getLabelFor(profile.respOrgId);
		if (label) {
			return label;
		}

		// This will populate the cache
		await RespOrgRender.GetRespOrg(profile.respOrgId);
		return RespOrgRender.getLabelFor(profile.respOrgId);
	}, [profile.respOrgId]);
	const prettierTfn = TollFreeNumbersUtils.tollFreeNumberToReadableString(
		profile.tfn
	);

	return (
		<Aside isOpen={isOpen} disabledBody>
			<Card>
				<Aside.Header
					warning
					header={
						<>
							Toll-Free Number
							<Link
								to={NumberRouter.getProfileRoute(profile.tfn)}
								className="float-end ml-1"
							>
								{prettierTfn}
							</Link>
						</>
					}
					onClose={() => onCancel()}
				/>
				<Card.Body>
					{Boolean(profile.responseId) && (
						<Row>
							<Col xl={12}>
								<h6>Response</h6>
								<p>{profile.responseId}</p>
							</Col>
						</Row>
					)}
					{Boolean(profile.responseLabel) && (
						<Row>
							<Col xl={12}>
								<h6>Message</h6>
								<p>{profile.responseLabel}</p>
							</Col>
						</Row>
					)}
					<Row>
						<Col xl={12}>
							<h6>RespOrg</h6>
							<p>
								{respOrgLabel.loading
									? 'Loading...'
									: `${respOrgLabel.value} (${profile.respOrgId})`}
							</p>
						</Col>
					</Row>
					{Boolean(profile.numberStatusLabel) && (
						<Row>
							<Col xl={12}>
								<h6>Status</h6>
								<p>{profile.numberStatusLabel}</p>
							</Col>
						</Row>
					)}
					{Boolean(profile.templateName) && (
						<Row>
							<Col xl={12}>
								<h6>Template</h6>
								<p>{profile.templateName}</p>
							</Col>
						</Row>
					)}
					<Row>
						<Col xl={12}>
							<h6>Effective</h6>
							<p>{timestampToReadable(profile.effectiveTs)}</p>
						</Col>
					</Row>
					<Row>
						<Col xl={12}>
							<h6>Last Update</h6>
							<p>{timestampToReadable(profile.lastUpdateTs)}</p>
						</Col>
					</Row>
					<Row>
						<Col xl={12}>{futuresContent}</Col>
					</Row>
				</Card.Body>
			</Card>
		</Aside>
	);
};
TaskNumberAside.displayName = 'TaskNumberAside';
