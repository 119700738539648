import { defaults } from 'lodash-es';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationMappingFiltersCreateRequest,
	GuardianReconciliationMappingFiltersIndexResponse,
	GuardianReconciliationMappingFiltersProfileResponse,
	GuardianReconciliationMappingFiltersProfileUrlRequest,
	GuardianReconciliationMappingFiltersUpdateRequest,
	ReconciliationMappingDataSourceDirection,
	ReconciliationMappingDataSourceField,
	ReconciliationMappingDataSourceType
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class GuardianReconciliationMappingFilterResource extends HttpResource<
	GuardianReconciliationMappingFiltersIndexResponse,
	GuardianReconciliationMappingFiltersProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianReconciliationMappingFilters);
	}

	public create(request: GuardianReconciliationMappingFiltersCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianReconciliationMappingFiltersProfileResponse>(
			RtVueApiRoutes.GuardianReconciliationMappingFilters.Create,
			{
				body
			}
		);
	}

	public update(
		guardianReconciliationMappingFilterId: number,
		request: GuardianReconciliationMappingFiltersUpdateRequest
	) {
		const body = JSON.stringify(request);
		const urlParams: GuardianReconciliationMappingFiltersProfileUrlRequest = {
			guardianReconciliationMappingFilterId
		};

		return this.fetchWithRoute<GuardianReconciliationMappingFiltersProfileResponse>(
			RtVueApiRoutes.GuardianReconciliationMappingFilters.Update,
			{
				body,
				urlParams
			}
		);
	}

	public delete(guardianReconciliationMappingFilterId: number) {
		const urlParams: GuardianReconciliationMappingFiltersProfileUrlRequest = {
			guardianReconciliationMappingFilterId
		};

		return this.fetchWithRoute<void>(
			RtVueApiRoutes.GuardianReconciliationMappingFilters.Delete,
			{
				urlParams
			}
		);
	}

	public createNewProfile(
		init: Partial<GuardianReconciliationMappingFiltersProfileResponse> = {}
	): any {
		const profile: any = {
			guardianReconciliationMappingFilterId: -1,
			guardianReconciliationMappingId: -1,
			dataSourceDirection: ReconciliationMappingDataSourceDirection.Source,
			dataSourceType: ReconciliationMappingDataSourceType.Cost,
			dataSourceField: ReconciliationMappingDataSourceField.Account,
			dataSourceValue: '',
			filters: {},
			notes: '',
			isActive: 1,
			guardianReconciliationMappingOperand: 'IN'
		};

		return defaults(init, profile);
	}
}
