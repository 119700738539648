import { FileUpload } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import {
	JobStatusId,
	JobTaskIndexRequest,
	JobTaskIndexResponse
} from 'RtModels';
import { JobTaskUpdateAside } from 'RtUi/app/rtCarrierConnect/Jobs/lib/components/JobTaskUpdateAside';
import { JobTasksBulkUploaderAside } from 'RtUi/app/rtCarrierConnect/Jobs/lib/components/JobTasksBulkUploaderAside';
import { JobTaskResource } from 'RtUi/app/rtCarrierConnect/Jobs/lib/resources/JobTaskResource';
import { useGetJobTasks } from 'RtUi/app/rtCarrierConnect/Jobs/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getTasksColumns } from 'RtUi/components/data/DataGrid/configurations/rtCarrierConnect/jobs';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { useMemo, useState } from 'react';
import { Item } from 'react-contexify';

interface IJobTaskGridProps {
	jobStepId: string;
	stepNumber: number;
	resourceId: number;
	onUpdate: () => void;
	isManageable: boolean;
	disableCollapse?: boolean;
	removeCardHeaderXPadding?: boolean;
	onRowClick?: (row: JobTaskIndexResponse) => void;
	resourceParams?: JobTaskIndexRequest;
	autoFocusFilter?: boolean;
	mobilePagination?: boolean;
}

export const JobTaskGrid = ({
	jobStepId,
	resourceId,
	stepNumber,
	onUpdate,
	onRowClick,
	isManageable
}: IJobTaskGridProps) => {
	const [showBulkUpdateForm, setShowBulkUpdateForm] = useState<boolean>(false);
	const [selectedJobTask, setSelectedJobTask] =
		useState<JobTaskIndexResponse>();
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetJobTasks({
		jobStepId,
		resourceId
	});
	const columns = useMemo(() => getTasksColumns(), []);
	const jobTaskResource = new JobTaskResource({ jobStepId, resourceId });

	const onJobTaskUpdate = () => {
		refetch();
		setSelectedJobTask(undefined);

		onUpdate();
	};

	const renderJobTaskMenuItem = (jobTask: JobTaskIndexResponse) => {
		const isWaitingForManualCompletion =
			JobStatusId.WAITING_FOR_TASK_MANUAL_COMPLETION === jobTask.taskStatusId;

		return (
			<Item key={jobTask.taskId} onClick={() => setSelectedJobTask(jobTask)}>
				<span className="d-flex justify-content-start align-items-center">
					{isWaitingForManualCompletion && (
						<>
							<i className="fas fa-fw fa-pen" />
							<span>Edit</span>
						</>
					)}
					{!isWaitingForManualCompletion && (
						<>
							<i className="fas fa-fw fa-search" />
							<span>View</span>
						</>
					)}
				</span>
			</Item>
		);
	};

	return (
		<>
			<DataGrid<JobTaskIndexResponse>
				disableExternalLinks
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				onRowClick={onRowClick}
				pageName={'rtCarrierConnect_jobTasks'}
				menuLinks={renderJobTaskMenuItem}
				rowThemeColor={(jobTask) =>
					jobTask.taskId === selectedJobTask?.taskId
						? RowThemeColor.WARNING
						: undefined
				}
				headerAction={() => {
					if (!isManageable) {
						return <></>;
					}

					return (
						<Tooltip title="Bulk Update" arrow>
							<IconButton
								type="button"
								color="default"
								onClick={() => setShowBulkUpdateForm(true)}
							>
								<FileUpload />
							</IconButton>
						</Tooltip>
					);
				}}
			/>
			<JobTasksBulkUploaderAside
				resourceId={resourceId}
				isOpen={showBulkUpdateForm}
				stepNumber={stepNumber}
				jobTaskResource={jobTaskResource}
				onClose={() => setShowBulkUpdateForm(false)}
				onUploadSuccess={() => onJobTaskUpdate()}
			/>
			{selectedJobTask && (
				<JobTaskUpdateAside
					isOpen={Boolean(selectedJobTask)}
					jobTask={selectedJobTask}
					jobTaskResource={jobTaskResource}
					onCancel={() => setSelectedJobTask(undefined)}
					onUpdate={() => onJobTaskUpdate()}
				/>
			)}
		</>
	);
};
