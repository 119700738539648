import { RtxSipApiRoutes } from 'RtExports/routes';
import { ServiceProfileRequest, ServiceProfileResponse } from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchServiceType = (urlParams: ServiceProfileRequest) => {
	return handleGetRequest<ServiceProfileResponse>(
		RtxSipApiRoutes.Services.Profile,
		{
			urlParams
		}
	);
};

export const useGetServiceType = (urlParams: ServiceProfileRequest) => {
	const cacheKey = JSON.stringify(urlParams);

	return useQuery<ServiceProfileResponse, Error>(
		[`getServiceType`, cacheKey],
		() => fetchServiceType(urlParams)
	);
};
