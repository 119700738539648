/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	SubscriptionPeeringCreateRequest,
	SubscriptionPeeringIndexRequest,
	SubscriptionPeeringIndexResponse,
	SubscriptionPeeringProfileRequest,
	SubscriptionPeeringProfileResponse,
	SubscriptionPeeringUpdateRequest
} from 'RtModels';
import {
	FullResponse,
	handleDeleteRequest,
	handleGetRequest,
	handlePostRequest,
	handlePutRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchSubscriptionPeering = async (
	queryParams?: SubscriptionPeeringIndexRequest
): Promise<FullResponse<SubscriptionPeeringIndexResponse[]>> => {
	return handleGetRequest<SubscriptionPeeringIndexResponse[], true>(
		RtxSipApiRoutes.SubscriptionPeering.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetSubscriptionPeering = (
	queryParams?: SubscriptionPeeringIndexRequest
) => {
	return useQuery<FullResponse<SubscriptionPeeringIndexResponse[]>, Error>(
		['getSubscriptionPeering', queryParams],
		() => fetchSubscriptionPeering(queryParams)
	);
};

export const deleteSubscriptionPeering = (subscriptionPeeringId: number) => {
	const urlParams: SubscriptionPeeringProfileRequest = {
		subscriptionPeeringId
	};

	return handleDeleteRequest(RtxSipApiRoutes.SubscriptionPeering.Delete, {
		urlParams
	});
};

export const createSubscriptionPeering = (
	createRequest: SubscriptionPeeringCreateRequest
) => {
	return handlePostRequest<SubscriptionPeeringProfileResponse>(
		RtxSipApiRoutes.SubscriptionPeering.Create,
		createRequest
	);
};

interface IUpdateSubscriptionPeeringParams {
	subscriptionPeeringId: number;
	updatedSubscriptionPeering: SubscriptionPeeringUpdateRequest;
}

export const updateSubscriptionPeering = ({
	subscriptionPeeringId,
	updatedSubscriptionPeering
}: IUpdateSubscriptionPeeringParams) => {
	const urlParams: SubscriptionPeeringProfileRequest = {
		subscriptionPeeringId
	};

	return handlePutRequest<SubscriptionPeeringUpdateRequest>(
		RtxSipApiRoutes.SubscriptionPeering.Update,
		updatedSubscriptionPeering,
		{ urlParams }
	);
};
