import { uniq } from 'lodash-es';
import { RtVueApiRoutes } from 'RtExports/routes';
import { RtVueModuleRouter } from 'RtUi/app/rtVue/RtVue.router';

class GuardianReconciliationDashboardClass extends RtVueModuleRouter<
	void,
	void,
	{}
> {
	constructor() {
		super('Dashboard', 'dashboard', undefined, {});

		const indexPermissions = uniq([
			...RtVueApiRoutes.GuardianReconciliationTopOpenItems.Index.permissions,
			...RtVueApiRoutes.GuardianReconciliations.Index.permissions
		]);

		this.setIndexPermissions(indexPermissions);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(profile: void): string {
		return '';
	}
}

export const GuardianReconciliationDashboardRouter =
	new GuardianReconciliationDashboardClass();
