import {
	GuardianAlertRecipientIndexRequest,
	GuardianFraudAlertRecipientIndexResponse
} from 'RtModels';
import { FraudRecipientsEditor } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/forms/FraudRecipientsEditor';
import { FraudRecipientsResource } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/resources/FraudRecipientsResource';
import { useGetFraudRecipients } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { UserNameRenderCache } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';
import { getFraudRecipientsColumns } from 'RtUi/components/data/DataGrid/configurations/rtGuardian/fraudConfiguration';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { Fragment, useMemo, useState } from 'react';
import { useMount } from 'react-use';

interface IFraudConfigurationRecipientsGridProps {
	resourceParams?: GuardianAlertRecipientIndexRequest;
	pageName: string;
}

export const FraudConfigurationRecipientsGrid = ({
	resourceParams,
	pageName
}: IFraudConfigurationRecipientsGridProps): JSX.Element => {
	const [asideIsOpen, setAsideOpen] = useState(false);
	const [selectedRecord, setSelectedRecord] =
		useState<GuardianFraudAlertRecipientIndexResponse>();

	useMount(() => {
		UserNameRenderCache.warmup();
	});

	const { data, isLoading } = useGetFraudRecipients(resourceParams);

	const removeEntity = async (
		row: GuardianFraudAlertRecipientIndexResponse
	) => {
		const confirm = await Confirmation.createDelete(
			UserNameRenderCache.getLabelFor(row.userId)
		);

		if (!confirm) {
			return;
		}

		await new FraudRecipientsResource().delete(row.userId);
	};

	const columns = useMemo(() => getFraudRecipientsColumns(removeEntity), []);

	return (
		<>
			{asideIsOpen && (
				<Aside isOpen={asideIsOpen} disabledBody>
					<Fragment key={selectedRecord?.userId}>
						<Aside.Header
							warning={Boolean(selectedRecord)}
							header="Recipient"
							onClose={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
						/>
						<FraudRecipientsEditor
							editMode={selectedRecord}
							onCancel={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
							onSuccess={() => {
								setAsideOpen(false);
								setSelectedRecord(undefined);
							}}
						/>
					</Fragment>
				</Aside>
			)}
			<DataGrid<GuardianFraudAlertRecipientIndexResponse>
				data={data?.data}
				loading={isLoading}
				pageName={pageName}
				onRowClick={(row) => {
					setAsideOpen(true);
					setSelectedRecord(row);
				}}
				onClickCreate={() => setAsideOpen(true)}
				columns={columns}
				totalRows={data?.totalCount}
				rowThemeColor={(record) =>
					record.userId === selectedRecord?.userId
						? RowThemeColor.WARNING
						: undefined
				}
			/>
			<Confirmation />
		</>
	);
};
