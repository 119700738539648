/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $DashboardIndexResponse = {
    properties: {
        dashboardTaskId: {
            type: 'DashboardTaskId',
            isRequired: true,
        },
        sectionName: {
            type: 'DashboardSectionName',
            isRequired: true,
        },
        dashboardTaskLabel: {
            type: 'string',
            isRequired: true,
        },
        totalItemQty: {
            type: 'number',
            isRequired: true,
        },
        totalItemLabel: {
            type: 'string',
            isRequired: true,
        },
        warningItemQty: {
            type: 'number',
            isRequired: true,
        },
        warningItemLabel: {
            type: 'string',
            isRequired: true,
        },
        errorItemQty: {
            type: 'number',
            isRequired: true,
        },
        errorItemLabel: {
            type: 'string',
            isRequired: true,
        },
        status: {
            type: 'DashboardItemStatus',
            isRequired: true,
        },
        data: {
            type: 'Array',
            isRequired: true,
        },
    },
};