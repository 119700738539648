import { generateUUID } from 'RtUi/utils/http/resources/utils';
import clsx from 'clsx';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Rnd } from 'react-rnd';

interface IMessageModal {
	content: React.ReactNode;
	title: string;
	show: boolean;
	close: () => void;
	positionShift?: number;
}

interface IAxis {
	x: number;
	y: number;
}

const getWindowWidthAndHeight = () => {
	const w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName('body')[0],
		windowWidth = w.innerWidth || e.clientWidth || g.clientWidth,
		windowHeight = w.innerHeight || e.clientHeight || g.clientHeight;
	return {
		windowWidth,
		windowHeight: windowHeight
	};
};

const getPosition = (
	id: string,
	initialX: number,
	initialY: number
): { x: number; y: number } => {
	const currentModal = document.getElementById(id);

	if (!currentModal) {
		return { x: initialX, y: initialY };
	}

	const samePositionModal = [
		...document.getElementsByClassName(
			`messages-modal d-flex x-${initialX} y-${initialY}`
		)
	].filter((element) => {
		return element.id !== id;
	});

	if (samePositionModal.length) {
		return getPosition(id, initialX + 30, initialY + 30);
	}

	return { x: initialX, y: initialY };
};

const MODAL_WIDTH = 650;
const MODAL_HEIGHT = 450;

export const MessageModal: FC<React.PropsWithChildren<IMessageModal>> = ({
	content,
	title,
	show,
	close = () => null
}) => {
	const [showModal, setShowModal] = useState<boolean>();
	const rnd = useRef<Rnd>(null);
	const [axis, setAxis] = useState<IAxis>({ x: 0, y: 0 });
	const id = useMemo(() => generateUUID(), []);

	useEffect(() => {
		setShowModal(show);
		if (!show) {
			return;
		}

		const { windowWidth, windowHeight } = getWindowWidthAndHeight();

		const initialX = Math.round(Math.max((windowWidth - MODAL_WIDTH) / 2, 0));
		const initialY = Math.round(Math.max((windowHeight - MODAL_HEIGHT) / 2, 0));

		const { x, y } = getPosition(id, initialX, initialY);

		setAxis({ x, y });
		rnd?.current?.updatePosition({ x, y });
	}, [show, id]);

	const handleClose = () => {
		setShowModal(false);
		close();
	};

	return (
		<Rnd
			id={id}
			ref={rnd}
			bounds="window"
			className={clsx(
				showModal ? 'd-flex' : 'd-none',
				'messages-modal',
				`x-${axis.x}`,
				`y-${axis.y}`,
				'flex-column',
				'rounded',
				'border',
				'shadow',
				'rnd-modal'
			)}
			enableResizing={{ bottomRight: true }}
			dragHandleClassName="modal-header"
			default={{
				x: 0,
				y: 0,
				width: MODAL_WIDTH,
				height: MODAL_HEIGHT
			}}
		>
			<Modal.Header
				className="bg-white justify-content-between p-2"
				closeButton
				onHide={handleClose}
			>
				<h6 className="mb-0">{title}</h6>
			</Modal.Header>
			<Modal.Body className="bg-white overflow-auto">{content}</Modal.Body>
		</Rnd>
	);
};
