/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipCdrSearchIndexResponse = {
    properties: {
        cdrId: {
            type: 'string',
            isRequired: true,
        },
        invite: {
            type: 'string',
            isRequired: true,
        },
        inDur: {
            type: 'string',
            isRequired: true,
        },
        egDur: {
            type: 'string',
            isRequired: true,
        },
        cp: {
            type: 'string',
            isRequired: true,
        },
        cpStd: {
            type: 'string',
            isRequired: true,
        },
        cpLrn: {
            type: 'string',
            isRequired: true,
        },
        cpIso: {
            type: 'string',
            isRequired: true,
        },
        dn: {
            type: 'string',
            isRequired: true,
        },
        dnStd: {
            type: 'string',
            isRequired: true,
        },
        dnLrn: {
            type: 'string',
            isRequired: true,
        },
        dnIso: {
            type: 'string',
            isRequired: true,
        },
        erci: {
            type: 'string',
            isRequired: true,
        },
        ercs: {
            type: 'string',
            isRequired: true,
        },
        ercid: {
            type: 'string',
            isRequired: true,
        },
        iReleaseCodeId: {
            type: 'number',
            isRequired: true,
        },
        eReleaseCodeId: {
            type: 'number',
            isRequired: true,
        },
        erj: {
            type: 'string',
            isRequired: true,
        },
        ers: {
            type: 'string',
            isRequired: true,
        },
        erss: {
            type: 'string',
            isRequired: true,
        },
        irci: {
            type: 'string',
            isRequired: true,
        },
        iress: {
            type: 'string',
            isRequired: true,
        },
        err: {
            type: 'string',
            isRequired: true,
        },
        eccid: {
            type: 'string',
            isRequired: true,
        },
        ecj: {
            type: 'string',
            isRequired: true,
        },
        ecs: {
            type: 'string',
            isRequired: true,
        },
        ecss: {
            type: 'string',
            isRequired: true,
        },
        eres: {
            type: 'number',
            isRequired: true,
        },
        ecr: {
            type: 'string',
            isRequired: true,
        },
        erd: {
            type: 'number',
            isRequired: true,
        },
        ecd: {
            type: 'string',
            isRequired: true,
        },
        ertc: {
            type: 'number',
            isRequired: true,
        },
        ectc: {
            type: 'string',
            isRequired: true,
        },
        mgn: {
            type: 'string',
            isRequired: true,
        },
    },
};