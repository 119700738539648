import { RtVueApiRoutes } from 'RtExports/routes';
import { FileStreamRouter } from 'RtUi/app/rtVue/FileStreams/FileStream.router';
import { FileStreamEditor } from 'RtUi/app/rtVue/FileStreams/lib/forms/FileStreamEditor';
import { FileStreamDataGrid } from 'RtUi/app/rtVue/FileStreams/lib/grids/FileStreamDataGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useNavigate } from 'react-router-dom';

export function FileStreamIndexContainer() {
	const navigate = useNavigate();
	const [tabs, activeTab, setActiveTab] = useTabs({
		'File Streams': { header: 'File Streams', isDefault: true },
		'Create File Stream': {
			header: 'Create File Stream',
			isAction: true,
			rtRoute: RtVueApiRoutes.FileStreams.Create
		}
	});

	return (
		<TabbedLayout
			router={FileStreamRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs['File Streams']}>
				<FileStreamDataGrid />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs['Create File Stream']}>
				<FileStreamEditor
					displayMode={false}
					onUpdate={(newFileStream) => {
						navigate(
							FileStreamRouter.getProfileRoute(newFileStream.fileStreamId)
						);
					}}
					onCancel={() => {
						setActiveTab(tabs['File Streams'].header);
					}}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
}

FileStreamRouter.setIndexRtUiFunctionalComponent(FileStreamIndexContainer, {
	groupName: 'Configuration',
	orderPriority: 0,
	groupOrderPriority: 90
});
