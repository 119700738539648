import { Permissions, Rt800ApiRoutes } from 'RtExports/routes';
import { Rt800Router } from 'RtUi/app/rt800/rt800.router';
import {
	IRouterBreadcrumb,
	RtUiRouter
} from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { timestampToReadable } from 'RtUi/utils/maps';
import { NumberRouter } from '../Numbers/Number.router';
import { TemplateRouter } from '../Templates/Template.router';
import { TemplateResource } from 'RtUi/app/rt800/Templates/lib/resources/TemplateResource';
import { NumberResource } from 'RtUi/app/rt800/Numbers/lib/resources/NumberResource';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
import {
	TollFreeCprIndexResponse,
	TemplateCprIndexResponse,
	CprNumberIndexResponse
} from 'RtModels';

interface ICprsContainerTabs extends IProfileApplicationContainerTabs {
	Admin: TabbedLayoutTabProps;
	Cpr: TabbedLayoutTabProps;
	Lad: TabbedLayoutTabProps;
	Scp: TabbedLayoutTabProps;
	Validation: TabbedLayoutTabProps;
	InstantValidation: TabbedLayoutTabProps;
}

export const CprsContainerTabs: ICprsContainerTabs = {
	Admin: { header: 'Admin' },
	Cpr: { header: 'CPR' },
	Lad: { header: 'Labels' },
	Scp: { header: 'SCP' },
	Validation: { header: 'Validation' },
	InstantValidation: { header: 'Instant Validation' }
};

export type CprsRouterTypes = 'template' | 'number';

export class CprsRouterClass extends Rt800Router<Cpr, Cpr, ICprsContainerTabs> {
	constructor(
		routerName: string,
		protected cprType: CprsRouterTypes,
		protected parentRouter: Rt800Router<any, any, any>,
		protected profilePermissions: Permissions[],
		protected copyPermissions: Permissions[],
		protected editPermissions: Permissions[],
		protected transferPermissions: Permissions[],
		protected deletePermissions: Permissions[],
		protected disconnectPermissions: Permissions[] = []
	) {
		super(routerName, 'cprs', 'routingCacheKey', CprsContainerTabs);

		this.setProfilePermissions(profilePermissions);
	}

	public getType() {
		return this.cprType;
	}

	public getCopyPermissions() {
		return this.copyPermissions;
	}

	public getEditPermissions() {
		return this.editPermissions;
	}

	public getTransferPermissions() {
		return this.transferPermissions;
	}

	public getDeletePermissions() {
		return this.deletePermissions;
	}

	public getDisconnectPermissions() {
		return this.disconnectPermissions;
	}

	public getProfileRoute(
		record:
			| Cpr
			| TollFreeCprIndexResponse
			| TemplateCprIndexResponse
			| CprNumberIndexResponse
			| string
			| number,
		tabId?: string,
		prefixSectionKey?: boolean
	) {
		let effectiveTs = ':effectiveTs'; //for routing purposes
		let id = ':id';

		if (record instanceof Cpr) {
			effectiveTs = record.getSourceEffectiveTs()!.toISOString();
			id = record.getSourceReferenceKey();
		} else if (typeof record === 'object') {
			effectiveTs =
				typeof record.effectiveTs === 'string'
					? record.effectiveTs
					: record.effectiveTs?.toISOString() ?? '';

			if (this.cprType === 'number') {
				if ('tfn' in record) {
					id = record.tfn;
				}
			} else {
				if ('templateName' in record) {
					id = record.templateName!;
				}
			}
		}

		return this.getProfileRouteByIdAndEffectiveTs(
			id,
			effectiveTs,
			tabId,
			prefixSectionKey
		);
	}

	public getProfileRouteByIdAndEffectiveTs(
		id: string,
		effectiveTs: string,
		tabId?: string,
		prefixSectionKey?: boolean
	) {
		const route = `${this.getIndexRoute(prefixSectionKey)}/${
			this.cprType
		}/${id}/${effectiveTs}`;

		return this.getRouteWithTab(route, tabId);
	}

	/**
	 * @override
	 */
	public recordHasAccessToProfile(record: any) {
		const hasEffectiveTS = 'effectiveTs' in record;
		const hasCprStatusId =
			'cprStatusId' in record && typeof record.cprStatusId === 'number';

		if (!hasEffectiveTS || !hasCprStatusId) {
			return false;
		}

		let hasIdentifier = false;

		if (this.cprType === 'number') {
			hasIdentifier = 'tfn' in record;
		} else if (this.cprType === 'template') {
			hasIdentifier = 'templateName' in record;
		}

		return hasIdentifier;
	}

	public getProfileLabel(profile: Cpr) {
		const effectiveTsReadable = timestampToReadable(
			profile.getSourceEffectiveTs()
		);

		return `Routing Profile for ${effectiveTsReadable}`;
	}

	public async getBreadcrumbs(
		currentPath: string,
		profile?: Cpr
	): Promise<IRouterBreadcrumb[]> {
		const sectionBreadcrumb = super.getSectionBreadcrumb();
		const breadcrumbs = [sectionBreadcrumb];

		if (profile) {
			let id: string;
			let router: RtUiRouter;
			let parentProfile: any | undefined;

			if (this.cprType === 'template') {
				const templateCprProfile = profile;
				const templateResource = new TemplateResource();
				id = templateCprProfile.routingCacheKey;
				router = TemplateRouter;
				parentProfile = await templateResource.get(id);
			} else {
				const numberCprProfile = profile;
				const numberResource = new NumberResource();
				id = numberCprProfile.routingCacheKey;
				router = NumberRouter;
				parentProfile = await numberResource.get(id);
			}

			const referencedIndexBreadcrumb = router.getIndexBreadcrumb(false);
			const referencedProfileBreadcrumb = router.getProfileBreadcrumb(
				id,
				parentProfile,
				false
			);
			const profileBreadcrumb = this.getProfileBreadcrumb(id, profile);

			breadcrumbs.push(
				referencedIndexBreadcrumb,
				referencedProfileBreadcrumb,
				profileBreadcrumb
			);
		}

		return breadcrumbs;
	}

	public getParentProfileRoute(id: string, tab?: string) {
		return this.parentRouter.getProfileRoute(id, tab);
	}
}

export const TollFreeCprsRouter = new CprsRouterClass(
	'Toll-Free Routing Profile',
	'number',
	NumberRouter,
	Rt800ApiRoutes.TollFreeCprs.Index.permissions,
	Rt800ApiRoutes.TollFreeCprCopy.Update.permissions,
	Rt800ApiRoutes.TollFreeCprs.Update.permissions,
	Rt800ApiRoutes.TollFreeCprs.Update.permissions,
	Rt800ApiRoutes.TollFreeCprs.Delete.permissions
);
export const TemplateCprsRouter = new CprsRouterClass(
	'Template Routing Profile',
	'template',
	TemplateRouter,
	Rt800ApiRoutes.TemplateCprs.Index.permissions,
	Rt800ApiRoutes.TemplateCprCopy.Update.permissions,
	Rt800ApiRoutes.TemplateCprs.Update.permissions,
	Rt800ApiRoutes.TemplateCprs.Update.permissions,
	Rt800ApiRoutes.TemplateCprs.Delete.permissions,
	Rt800ApiRoutes.TemplateCprDisconnect.Update.permissions
);
