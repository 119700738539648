import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	ServiceNumberTagIndexResponse,
	ServiceNumberTagProfileResponse,
	ServiceNumberTagCreateRequest,
	ServiceNumberTagUpdateRequest,
	ServiceNumberTagProfileUrlRequest
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';

export class ServiceNumberTagResource extends HttpResource<
	ServiceNumberTagIndexResponse,
	ServiceNumberTagProfileResponse
> {
	constructor() {
		super(RtxSipApiRoutes.ServiceNumberTag);
	}

	public create(createRequest: ServiceNumberTagCreateRequest) {
		const body = JSON.stringify(createRequest);

		return this.fetchWithRoute<ServiceNumberTagProfileResponse>(
			RtxSipApiRoutes.ServiceNumberTag.Create,
			{
				body
			}
		);
	}

	public update(
		serviceNumberTagId: number,
		updateRequest: ServiceNumberTagUpdateRequest
	) {
		const urlParams: ServiceNumberTagProfileUrlRequest = { serviceNumberTagId };
		const body = JSON.stringify(updateRequest);

		return this.fetchWithRoute<ServiceNumberTagProfileResponse>(
			RtxSipApiRoutes.ServiceNumberTag.Update,
			{
				body,
				urlParams
			}
		);
	}

	public delete(serviceNumberTagId: number) {
		const urlParams: ServiceNumberTagProfileUrlRequest = { serviceNumberTagId };
		return this.fetchWithRoute(RtxSipApiRoutes.ServiceNumberTag.Delete, {
			urlParams
		});
	}
}
