/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum OuterSelectOperator {
    Division = '/',
    Multiplication = '*',
    Sum = 'Sum',
    DivAndSum = 'OuterOnly',
    OuterOnly = 'SpecificFormula',
}