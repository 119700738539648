import {
	PeeringTypeIndexResponse,
	SubscriptionPeeringIndexRequest
} from 'RtModels';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { FC, useState } from 'react';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { SubscriptionPeeringRouter } from 'RtUi/app/rtLcr/SubscriptionPeering/SubscriptionPeering.router';
import { SubscriptionPeeringGrid } from 'RtUi/app/rtLcr/SubscriptionPeering/grids/SubscriptionPeeringGrid';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PeeringTypeSelect } from 'RtUi/app/rtLcr/SubscriptionPeering/controls/PeeringTypeSelect';
import { useReplaceWindowUrl } from 'RtUi/utils/hooks/useReplaceWindowUrl';

export const SubscriptionPeeringIndexContainer: FC = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true }
	});
	const [isActive, setIsActive] = useState<number | undefined>(1);
	const [searchStr, setSearchStr] = useState('');
	const [peeringType, setPeeringType] = useState<PeeringTypeIndexResponse>();

	const resourceParams: SubscriptionPeeringIndexRequest = {};

	if (isActive !== undefined) {
		resourceParams.isActive = isActive;
	}

	if (searchStr) {
		resourceParams.search = searchStr;
	}

	if (peeringType) {
		resourceParams.peeringTypeId = peeringType.peeringTypeId;
	}

	useReplaceWindowUrl(activeTab.header, resourceParams);

	return (
		<TabbedLayout
			router={SubscriptionPeeringRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					onSubmit={(evt) => {
						evt.preventDefault();

						setActiveTab(tabs.Results.header);
					}}
				>
					<InputFormControl
						label="Search"
						onChange={(val) => {
							setSearchStr(val);
						}}
						value={searchStr}
					/>
					<PeeringTypeSelect<false>
						multi={false}
						value={peeringType}
						onChange={(type) => {
							setPeeringType(type);
						}}
					/>
					<IsActiveRadioFormControl
						label="Status"
						onChange={(isActive) => {
							setIsActive(isActive);
						}}
						value={isActive}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab header={tabs.Results.header}>
				<SubscriptionPeeringGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

SubscriptionPeeringIndexContainer.displayName =
	'SubscriptionPeeringIndexContainer';

SubscriptionPeeringRouter.setIndexRtUiFunctionalComponent(
	SubscriptionPeeringIndexContainer
);
