/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RateLoadStatus {
    Scheduled = 1,
    Running = 5,
    CompletedOk = 10,
    CompletedWithExceptions = 11,
    CompletedError = 20,
}