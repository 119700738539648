/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MessagesSummaryIndexResponse = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        customerAccountId: {
            type: 'number',
        },
        customerSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        customerExternalAccountId: {
            type: 'string',
        },
        customerExternalSubAccountId: {
            type: 'string',
        },
        vendorExternalAccountId: {
            type: 'string',
        },
        vendorExternalSubAccountId: {
            type: 'string',
        },
        ingressTrunkGroup: {
            type: 'string',
            isRequired: true,
        },
        vendorAccountId: {
            type: 'number',
        },
        vendorSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        egressTrunkGroup: {
            type: 'string',
            isRequired: true,
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        ingressRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        ingressCountry: {
            type: 'string',
        },
        egressRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        egressCountry: {
            type: 'string',
        },
        attempts: {
            type: 'number',
        },
        billable: {
            type: 'number',
            isRequired: true,
        },
        revenue: {
            type: 'number',
            isRequired: true,
        },
        cost: {
            type: 'number',
            isRequired: true,
        },
        margin: {
            type: 'number',
            isRequired: true,
        },
        direction: {
            type: 'string',
        },
        campaignId: {
            type: 'string',
        },
        dlrCode: {
            type: 'string',
        },
        jurisdiction: {
            type: 'string',
        },
        messageStatus: {
            type: 'string',
        },
        totalSegments: {
            type: 'number',
            isRequired: true,
        },
        messageLength: {
            type: 'number',
            isRequired: true,
        },
    },
};