import {
	GuardianSwitchFileStreamIndexResponse,
	GuardianSwitchFileStreamProfileResponse
} from 'RtModels';
import { GuardianSwitchFileStreamEditor } from 'RtUi/app/AccountManagement/Switches/lib/forms/GuardianSwitchFileStreamEditor';
import { GuardianSwitchFileStreamsResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/GuardianSwitchFileStreamsResource';
import { useGetSwitchFileStreams } from 'RtUi/app/AccountManagement/Switches/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getSwitchFileStreamsColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/switches';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { Aside } from 'RtUi/components/ui/Aside';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';

export interface IGuardianSwitchFileStreamsGridProps {
	switchId: number;
	autoFocusFilter?: boolean;
}

export const GuardianSwitchFileStreamsGrid = ({
	switchId
}: IGuardianSwitchFileStreamsGridProps): JSX.Element => {
	const resource = useMemo(() => new GuardianSwitchFileStreamsResource(), []);
	const [editingGuardianSwitchFileStream, setEditingGuardianSwitchFileStream] =
		useState<GuardianSwitchFileStreamProfileResponse>();
	const [showAddFileStreamAside, setShowAddFileStreamAside] =
		useState<boolean>(false);

	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetSwitchFileStreams({ switchId });
	const switchFileStreams = useMemo(() => data?.data, [data]);

	const onClickRowHandler = (row: GuardianSwitchFileStreamProfileResponse) => {
		setEditingGuardianSwitchFileStream(row);
		setShowAddFileStreamAside(true);
	};

	const onSuccessHandler = async () => {
		setShowAddFileStreamAside(false);
		toast.success('Successfully added file streams');
		await refetch();
	};

	const onCancelHandler = () => {
		setShowAddFileStreamAside(false);
	};

	const onDeleteHandler = useCallback(
		async (row: GuardianSwitchFileStreamIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				row.guardianSwitchFileStreamId.toString()
			);

			if (!confirm) {
				return;
			}

			await resource.delete(row.guardianSwitchFileStreamId);
			await refetch();
		},
		[refetch, resource]
	);

	const columns = useMemo(
		() => getSwitchFileStreamsColumns(onDeleteHandler),
		[onDeleteHandler]
	);

	return (
		<>
			{showAddFileStreamAside &&
				!isEmpty(switchFileStreams) &&
				switchFileStreams && (
					<Aside isOpen={showAddFileStreamAside} disabledBody>
						<Aside.Header header="Add File Stream" onClose={onCancelHandler} />
						<Aside.Body>
							<GuardianSwitchFileStreamEditor
								value={editingGuardianSwitchFileStream}
								switchId={switchId}
								onSuccess={onSuccessHandler}
								onCancel={onCancelHandler}
								existingFileStream={switchFileStreams}
							/>
						</Aside.Body>
					</Aside>
				)}
			<DataGrid<GuardianSwitchFileStreamIndexResponse>
				data={switchFileStreams}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'accountManagement_switchFileStreams'}
				disableExport
				onRowClick={onClickRowHandler}
				headerAction={() => (
					<OverlayTrigger
						overlay={(props) => (
							<Tooltip id={`fileStream-tooltip`} {...props}>
								Create File stream Configuration
							</Tooltip>
						)}
					>
						{({ ref, ...triggerHandler }) => (
							<Button
								ref={ref}
								{...triggerHandler}
								onClick={() => {
									setShowAddFileStreamAside(true);
								}}
								variant="white"
								id="fileStream"
							>
								<i className="fas fa-fw fa-plus fa-lg" />
							</Button>
						)}
					</OverlayTrigger>
				)}
			/>
		</>
	);
};
