//import * as React from 'react';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ResourceResource } from 'RtUi/app/rtCommon/Resources/lib/resources/ResourceResource';
import {
	ResourceType,
	ResourceIndexRequest,
	ResourceIndexResponse
} from 'RtModels';

interface IResourceSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<ResourceIndexResponse, isMulti> {
	//integrationType?: IntegrationType;
	resourceType?: ResourceType;
	resourceParams?: ResourceIndexRequest;
}

export class ResourceSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	ResourceIndexResponse,
	isMulti,
	IResourceSelectProps<isMulti>
> {
	public resourceClass = ResourceResource;
	public state: ISelectFormControlState<ResourceIndexResponse> = {
		formLabel: 'Resource',
		valueKey: 'resourceId',
		labelKey: 'label'
	};

	public componentDidMount() {
		this.checkAndSetGetAllParams();

		super.componentDidMount();
	}

	public componentDidUpdate(prevProps: IResourceSelectProps<isMulti>) {
		this.checkAndSetGetAllParams();

		super.componentDidUpdate(prevProps);
	}

	/**
	 * @override
	 */
	public async getData() {
		let resources = await super.getData();
		const { resourceType } = this.props;

		if (typeof resourceType === 'number') {
			resources = resources.filter((r) => r.resourceTypeId === resourceType);
		}

		return resources;
	}

	public checkAndSetGetAllParams() {
		if (this.props.resourceParams) {
			this.setGetAllParams(this.props.resourceParams);
		}
	}
}
