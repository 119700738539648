import { Fragment } from 'react';
import ContentLoader from 'react-content-loader';

interface SkeletonProps {
	columns: any[];
	data: any[];
}

const Skeleton = (props: SkeletonProps) => {
	const { columns, data } = props;

	let currentX = 5;
	let currentY = 0;

	const createSkeletonColumn = (index: number, internalHeight: number) => {
		const width = 100;
		const rectElem = (
			<rect
				key={index}
				x={currentX}
				y={currentY}
				rx="5"
				ry="5"
				width={width}
				height={internalHeight}
			/>
		);

		currentX += width + 4;

		return rectElem;
	};

	const createSkeletonRow = (
		rowIndex: number,
		internalHeight: number,
		outerHeight: number
	) => {
		const heightDelta = outerHeight - internalHeight;
		const halfHeightDelta = heightDelta / 2;
		//used as top padding
		currentY += heightDelta / 2;

		const rowOfColumns = columns.map((_, index) =>
			createSkeletonColumn(index, internalHeight)
		);

		currentY += outerHeight - halfHeightDelta; //total height minus top padding added above
		currentX = 5; //reset x-coordinate

		return <Fragment key={rowIndex}>{rowOfColumns}</Fragment>;
	};

	const width: number = 1500;

	const className = `apply-data-height-265`;

	return (
		<ContentLoader
			preserveAspectRatio="none"
			height={265}
			width={width}
			className={className}
		>
			{/* DataGrid header */}
			{createSkeletonRow(-1, 29, 41)}
			{/* DataGrid rows */}
			{data.map((_, index) => createSkeletonRow(index, 15, 33))}
		</ContentLoader>
	);
};

export default Skeleton;
