import { uniqBy } from 'lodash-es';
import { FC, Fragment, useContext } from 'react';
import { Nav, NavDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
	TabLayoutContext,
	TabLayoutTab
} from 'RtUi/components/ui/TabbedLayout/TabLayoutContext';
import { UserActions } from 'RtUi/state/actions/user';

export interface ITabs {
	actionsHeader?: string;
	backBtnTooltip?: string;
	onBackClick?: () => void;
	onTabChange: (activeTab: string) => void;
}

function getAvailableTabs(tabs: TabLayoutTab[], canManageFn?: () => boolean) {
	return tabs.filter((tab) => {
		if (tab.rtRoute) {
			if (!UserActions.has(...tab.rtRoute.permissions)) {
				return false;
			}
		} else if (tab.permissions) {
			if (!UserActions.has(...tab.permissions)) {
				return false;
			}
		}

		if (tab.requireCanManage) {
			return canManageFn ? canManageFn() : false;
		}

		return true;
	});
}

export const Tabs: FC<ITabs> = ({
	onBackClick,
	backBtnTooltip,
	actionsHeader,
	onTabChange
}) => {
	const {
		tabs,
		canManageFn,
		activeTabHeader: activeTab
	} = useContext(TabLayoutContext);

	const availableTabs = uniqBy(getAvailableTabs(tabs, canManageFn), 'header');
	const actions = availableTabs.filter((tab) => tab.isAction);
	const selectedAction = actions.find((action) => action.header === activeTab);
	const toggleTab = (activeTabParam: string) => {
		const sameTab = activeTab === activeTabParam;

		if (!sameTab) {
			onTabChange(activeTabParam);
		}
	};

	const onClickTabHandler = async (tab: TabLayoutTab) => {
		if (tab.customOnClick) {
			return tab.customOnClick(() => toggleTab(tab.header));
		}

		toggleTab(tab.header);
	};

	const isActionSelected = Boolean(selectedAction);

	return (
		<Nav variant="tabs" className="my-4">
			{onBackClick && (
				<Nav.Item style={{ minWidth: 'unset' }}>
					<OverlayTrigger
						placement="bottom-start"
						overlay={(props) => (
							<Tooltip id="backToNav-tooltip" {...props}>
								{backBtnTooltip}
							</Tooltip>
						)}
					>
						{({ ref, ...triggerHandler }) => (
							<Nav.Link
								ref={ref}
								{...triggerHandler}
								id="backToNav"
								className="nav-link-back"
								onClick={() => onBackClick()}
							>
								<i className="fas fa-chevron-left" />
							</Nav.Link>
						)}
					</OverlayTrigger>
				</Nav.Item>
			)}
			{availableTabs
				.filter((tab) => !tab.isAction)
				.map((tab) => {
					if (tab.isHidden) {
						return <Fragment key={tab.header}></Fragment>;
					}
					const shouldUseCustomHeader = Boolean(tab.customHeader);

					return (
						<Nav.Item key={tab.header}>
							<Nav.Link
								active={activeTab === tab.header}
								disabled={tab.isDisabled}
								onClick={() => onClickTabHandler(tab)}
							>
								{shouldUseCustomHeader && tab.customHeader}
								{!shouldUseCustomHeader && tab.header}
							</Nav.Link>
						</Nav.Item>
					);
				})}
			{actions.length > 0 && (
				<Nav.Item key="Actions">
					<NavDropdown
						className={isActionSelected ? 'active' : ''}
						id="actions"
						active={actions.some((action) => action.header === activeTab)}
						title={
							<>
								<span>{actionsHeader}</span>
								{selectedAction && <span>:&nbsp;{selectedAction.header}</span>}
								<span>&nbsp;</span>
							</>
						}
					>
						{actions.map((child) => (
							<NavDropdown.Item
								eventKey={child.header}
								key={child.header}
								disabled={child.isDisabled}
								onClick={() => onClickTabHandler(child)}
							>
								{child.header}
							</NavDropdown.Item>
						))}
					</NavDropdown>
				</Nav.Item>
			)}
		</Nav>
	);
};
