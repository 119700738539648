/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum SipAgentMedTarget {
    VueFqdn = 1,
    VueTrunkGroupId = 20,
    VueTrunkGroupLabel = 21,
    VueTrunkExternalId = 22,
}