import clsx from 'clsx';
import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';
import ContentLoader from 'react-content-loader';

interface IGenericDashboardProps {
	className?: string;
}

interface IDashboardCardHeaderProps extends IGenericDashboardProps {
	header: React.ReactChild;
	subheader?: React.ReactChild;
	tooltip?: React.ReactChild;
}

interface IDashboardCardStatProps extends IGenericDashboardProps {
	isLoading?: boolean;
	header?: React.ReactChild;
	subheader?: React.ReactChild;
	variant?: Variant;
	tooltip?: React.ReactChild;
}

export const DashboardCard: FC<
	React.PropsWithChildren<IGenericDashboardProps>
> = ({ className, children }) => {
	return (
		<section className={clsx('dashboard-card', className)}>{children}</section>
	);
};

export const DashboardCardRow: FC<
	React.PropsWithChildren<IGenericDashboardProps>
> = ({ className, children }) => {
	return (
		<section className={clsx('dashboard-card-row', className)}>
			{children}
		</section>
	);
};

export const DashboardCardStatHeader: FC<
	React.PropsWithChildren<IDashboardCardHeaderProps>
> = ({ className, header, subheader, tooltip }) => {
	return (
		<section className={clsx('dashboard-card-stat-header', className)}>
			{header}
			{tooltip && (
				<OverlayTrigger
					overlay={(props) => <Tooltip {...props}>{tooltip}</Tooltip>}
				>
					{({ ref, ...triggerHandler }) => (
						<span ref={ref} {...triggerHandler} className="align-self-center">
							&nbsp;
							<i className="fas fa-info-circle text-muted fa-sm fs-6" />
						</span>
					)}
				</OverlayTrigger>
			)}
			{subheader && (
				<section className="dashboard-card-stat-subheader">{subheader}</section>
			)}
		</section>
	);
};

export const DashboardCardStat: FC<
	React.PropsWithChildren<IDashboardCardStatProps>
> = ({ className, header, subheader, variant, tooltip, isLoading }) => {
	if (isLoading) {
		tooltip = undefined;
		variant = 'light';
		const height = '1rem';
		const width = '12rem';
		subheader = (
			<ContentLoader
				preserveAspectRatio="none"
				height={height}
				width={width}
				className={className}
			>
				<rect x={0} y={0} rx={5} ry={5} width={width} height={height} />
			</ContentLoader>
		);
	}

	const containerClassName = 'dashboard-card-stat';
	const variantContainerClassName = variant
		? `${containerClassName}-${variant}`
		: undefined;

	return (
		<section
			className={clsx(containerClassName, variantContainerClassName, className)}
		>
			{header && (
				<DashboardCardStatHeader
					header={header}
					subheader={subheader}
					tooltip={tooltip}
				/>
			)}
		</section>
	);
};
