import {
	FileProcessFileProfileResponse,
	InvoiceIndexResponse,
	InvoiceProfileRequest,
	InvoiceProfileResponse,
	InvoiceRerunResponse
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { FileUtils } from 'RtUi/utils/file/FileUtils';

export class InvoiceResource extends HttpResource<
	InvoiceIndexResponse,
	InvoiceProfileResponse
> {
	public fileUtils = new FileUtils();

	constructor() {
		super(RtxSipApiRoutes.Invoices);
	}

	public async reRun(invoiceId: number) {
		const urlParams: InvoiceProfileRequest = { invoiceId };

		return this.fetchWithRoute<InvoiceRerunResponse>(
			RtxSipApiRoutes.InvoiceRerun.Create,
			{
				urlParams,
				notificationMessage: 'Invoice Scheduled to be Re-Run.'
			}
		);
	}

	public async download(invoiceId: number) {
		const invoiceDefer = await this.getInvoiceResponse(invoiceId);

		return this.fileUtils.downloadFromUrlWithPromise(
			Promise.resolve({
				url: invoiceDefer.signedUrl,
				fileName: invoiceDefer.fileName
			})
		);
	}

	private getInvoiceResponse(invoiceId: number) {
		const urlParams: InvoiceProfileRequest = { invoiceId };
		return this.fetchWithRoute<FileProcessFileProfileResponse>(
			RtxSipApiRoutes.InvoiceDownload.Profile,
			{
				urlParams
			}
		);
	}
}
