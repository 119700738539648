/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	FileStreamIndexResponse,
	GuardianSwitchFileStreamCreateRequest,
	GuardianSwitchFileStreamIndexRequest,
	GuardianSwitchFileStreamIndexResponse,
	SwitchAddressIndexRequest,
	SwitchAddressIndexResponse,
	SwitchIndexRequest,
	SwitchIndexResponse,
	SwitchProfileResponse,
	SwitchTypeIndexResponse
} from 'RtModels';
import { GuardianSwitchFileStreamsResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/GuardianSwitchFileStreamsResource';
import { SwitchesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SwitchesResource';
import { SwitchTypesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SwitchTypesResource';
import { FileStreamResource } from 'RtUi/app/rtVue/FileStreams/lib/resource/FileStreamResource';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchSwitchTypes = async (): Promise<SwitchTypeIndexResponse[]> => {
	const data = await new SwitchTypesResource().getAll();
	return data;
};

export const useGetSwitchTypes = () => {
	return useQuery<SwitchTypeIndexResponse[], Error>(
		'getSwitchTypes',
		fetchSwitchTypes,
		{ enabled: true }
	);
};

export const postFileStreams = async (
	payload: GuardianSwitchFileStreamCreateRequest
) => {
	return await new GuardianSwitchFileStreamsResource().create(payload);
};

const fetchAllFileStreams = async (): Promise<FileStreamIndexResponse[]> => {
	const data = new FileStreamResource().getAll();
	return data;
};

export const useGetFileAllFileStreams = () => {
	return useQuery<any, any>('Get all file streams', () =>
		fetchAllFileStreams()
	);
};

const fetchSwitchAddresses = async (
	queryParams: SwitchAddressIndexRequest
): Promise<FullResponse<SwitchAddressIndexResponse[]>> => {
	return handleGetRequest(RtCommonApiRoutes.SwitchAddresses.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetSwitchAddresses = (
	queryParams: SwitchAddressIndexRequest
) => {
	return useQuery<FullResponse<SwitchAddressIndexResponse[]>, Error>(
		[`getSwitchAddress`, queryParams],
		() => fetchSwitchAddresses(queryParams),
		{ enabled: true }
	);
};

export const fetchSwitchFileStreams = async (
	queryParams?: GuardianSwitchFileStreamIndexRequest
): Promise<FullResponse<GuardianSwitchFileStreamIndexResponse[]>> => {
	return handleGetRequest(RtCommonApiRoutes.GuardianSwitchFileStream.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetSwitchFileStreams = (
	queryParams?: GuardianSwitchFileStreamIndexRequest
) => {
	return useQuery<FullResponse<GuardianSwitchFileStreamIndexResponse[]>, Error>(
		[`getFileStreams`, queryParams],
		() => fetchSwitchFileStreams(queryParams),
		{ enabled: true }
	);
};

const fetchSwitch = async (id: number): Promise<SwitchProfileResponse> => {
	const data = await new SwitchesResource().getProfile(id);
	return data;
};

export const useGetSwitch = (switchId: number) => {
	return useQuery<SwitchProfileResponse, Error>(
		[`getSwitch${switchId}`, switchId],
		() => fetchSwitch(switchId),
		{ enabled: true }
	);
};

const fetchSwitches = async (
	queryParams?: SwitchIndexRequest
): Promise<FullResponse<SwitchIndexResponse[]>> => {
	return handleGetRequest(RtCommonApiRoutes.Switches.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetSwitches = (queryParams?: SwitchIndexRequest) => {
	return useQuery<FullResponse<SwitchIndexResponse[]>, Error>(
		['getSwitches', queryParams],
		() => fetchSwitches(queryParams),
		{
			enabled: true
		}
	);
};
