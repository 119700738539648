import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	ConnectionIndexResponse,
	ConnectionProfileResponse,
	ConnectionUpdateRequest,
	ConnectionProfileRequest,
	ConnectionCreateRequest
} from 'RtModels';
import { RtVueApiRoutes } from 'RtExports/routes';

export class ConnectionResource extends HttpResource<
	ConnectionIndexResponse,
	ConnectionProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.Connections);
	}

	public create(connection: ConnectionCreateRequest) {
		const body = JSON.stringify(connection);
		return this.fetchWithRoute<ConnectionProfileResponse[]>(
			RtVueApiRoutes.Connections.Create,
			{ body }
		);
	}

	public update(connectionId: number, connection: ConnectionUpdateRequest) {
		const urlParams: ConnectionProfileRequest = { connectionId };
		const body = JSON.stringify(connection);

		return this.fetchWithRoute<ConnectionProfileResponse>(
			RtVueApiRoutes.Connections.Update,
			{ body, urlParams }
		);
	}
}
