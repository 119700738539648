/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CallTagMode {
    MatchAny = 1,
    MatchAll = 2,
    AllowAny = 3,
    BlockAny = 4,
}