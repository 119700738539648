import {
	AccountIndexResponse,
	AccountProfileResponse,
	AccountUpdateRequest,
	AccountProfileRequest,
	AccountCreateRequest
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class AccountResource extends HttpResource<
	AccountIndexResponse,
	AccountProfileResponse
> {
	constructor() {
		super(RtxSipApiRoutes.Accounts);
	}

	public create(accountCreateRequest: AccountCreateRequest) {
		const body = JSON.stringify(accountCreateRequest);

		return this.fetchWithRoute<AccountProfileResponse>(
			RtxSipApiRoutes.Accounts.Create,
			{
				body
			}
		);
	}

	public update(accountId: number, accountUpdateRequest: AccountUpdateRequest) {
		const urlParams: AccountProfileRequest = { accountId };

		const body = JSON.stringify(accountUpdateRequest);

		return this.fetchWithRoute<AccountProfileResponse>(
			RtxSipApiRoutes.Accounts.Update,
			{
				body,
				urlParams
			}
		);
	}
}
