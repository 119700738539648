import {
	ServiceNumberPortRequestIndexRequest,
	ServiceNumberPortRequestIndexResponse
} from 'RtModels';
import { PortRequestRouter } from 'RtUi/app/rtVue/PortRequest/PortRequest.router';
import { useGetPortRequest } from 'RtUi/app/rtVue/PortRequest/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getPortRequestColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/portRequests';
import { useMemo } from 'react';

interface IPortRequestDataGridProps {
	resourceParams?: ServiceNumberPortRequestIndexRequest;
}

export const PortRequestDataGrid = ({
	resourceParams
}: IPortRequestDataGridProps) => {
	const { data, isLoading } = useGetPortRequest(resourceParams);

	const columns = useMemo(() => getPortRequestColumns(), []);

	return (
		<DataGrid<ServiceNumberPortRequestIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'configureRateSheet-portRequest'}
			router={PortRequestRouter}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
