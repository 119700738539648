import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';
import { UserProfileEditor } from 'RtUi/app/user/lib/forms/UserProfileEditor';
import { UsersAuditGrid } from 'RtUi/app/user/lib/grids/UsersAuditGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { AdminUserRouter } from './AdminUser.router';

import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

import { UserGrid } from 'RtUi/app/user/lib/grids/UserGrid';

export const AdminUserIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Users: { header: 'Users', isDefault: true },
		Audit: { header: 'Audit' },
		CreateNewUser: {
			header: 'Create New User',
			isAction: true
		}
	});

	return (
		<TabbedLayout
			router={AdminUserRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Users}>
				<UserGrid />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Audit}>
				<UsersAuditGrid />
			</TabbedLayoutTab>
			<TabbedLayoutTab header="Create New User" isAction={true}>
				<UserProfileEditor
					displayMode={false}
					createMode={true}
					onUpdate={(profile) =>
						BrowserHistory.push(`/users/${profile.userId}`)
					}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

AdminUserRouter.setIndexRtUiFunctionalComponent(AdminUserIndexContainer);
