import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	PartitionEntityProfileResponse,
	PartitionEntityUpdateRequest,
	PartitionEntityCreateRequest,
	PartitionEntityCreateUrlRequest,
	PartitionEntityProfileUrlRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class PartitionEntityResource extends HttpResource<PartitionEntityProfileResponse> {
	constructor() {
		super(RtAdmApiRoutes.PartitionEntities);
	}

	public createPartitionEntity(
		partitionId: number,
		request: PartitionEntityCreateRequest
	) {
		const urlParams: PartitionEntityCreateUrlRequest = {
			partitionId
		};
		const body = JSON.stringify(request);
		return this.fetchWithRoute<PartitionEntityProfileResponse>(
			RtAdmApiRoutes.PartitionEntities.Create,
			{ body, urlParams }
		);
	}

	public updatePartitionEntity(
		partitionId: number,
		partitionEntityId: number,
		partitionEntityRequest: PartitionEntityUpdateRequest
	) {
		const urlParams: PartitionEntityProfileUrlRequest = {
			partitionId,
			partitionEntityId
		};

		const bodyReq: PartitionEntityUpdateRequest = {
			entityId: partitionEntityRequest.entityId
		};

		const body = JSON.stringify(bodyReq);

		return this.fetchWithRoute<PartitionEntityProfileResponse>(
			RtAdmApiRoutes.PartitionEntities.Update,
			{ body, urlParams }
		);
	}

	public delete(partitionId: number, partitionEntityId: number) {
		const urlParams: PartitionEntityProfileUrlRequest = {
			partitionId,
			partitionEntityId
		};

		return this.fetchWithRoute<PartitionEntityProfileResponse>(
			RtAdmApiRoutes.PartitionEntities.Delete,
			{ urlParams }
		);
	}
}
