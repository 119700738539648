import {
	GuardianConnectionSubscriptionIndexRequest,
	GuardianConnectionSubscriptionIndexResponse
} from 'RtModels';
import { ConnectionSubscriptionRouter } from 'RtUi/app/rtVue/ConnectionSubscription/ConnectionSubscription.router';
import { useGetConnectionSubscriptions } from 'RtUi/app/rtVue/Connections/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getConnectionSubscriptionsColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/connections';
import { FC, useMemo } from 'react';

interface IConnectionSubscriptionGridProps {
	resourceParams?: GuardianConnectionSubscriptionIndexRequest;
	autoFocusFilter?: boolean;
}

export const ConnectionSubscriptionGrid: FC<
	React.PropsWithChildren<IConnectionSubscriptionGridProps>
> = ({ resourceParams }) => {
	const { data, isFetching: isLoading } =
		useGetConnectionSubscriptions(resourceParams);
	const columns = useMemo(() => getConnectionSubscriptionsColumns(), []);

	return (
		<DataGrid<GuardianConnectionSubscriptionIndexResponse>
			router={ConnectionSubscriptionRouter}
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			defaultSorting={[{ id: 'startTs', desc: true }]}
			pageName={'rtVue_connectionSubscription'}
		/>
	);
};
