/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ResourceProfileResponse = {
    properties: {
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        resourceTypeId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isDefault: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        integrationId: {
            type: 'number',
            isRequired: true,
        },
        resRef1: {
            type: 'string',
        },
        resRef2: {
            type: 'string',
        },
        subscriptionId: {
            type: 'number',
            isNullable: true,
        },
        protocol: {
            type: 'string',
            isNullable: true,
        },
        host: {
            type: 'string',
            isNullable: true,
        },
        port: {
            type: 'number',
            isNullable: true,
        },
        path: {
            type: 'string',
            isNullable: true,
        },
        user: {
            type: 'string',
            isNullable: true,
        },
        password: {
            type: 'string',
            isNullable: true,
        },
        weight: {
            type: 'number',
            isRequired: true,
        },
        costPerTn: {
            type: 'number',
        },
        lastSyncTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        nextSyncTs: {
            type: 'Date',
            isNullable: true,
            format: 'date-time',
        },
        createdTs: {
            type: 'Date',
            format: 'date-time',
        },
        updatedTs: {
            type: 'Date',
            format: 'date-time',
        },
    },
};