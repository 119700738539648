import { Row, Col } from 'react-bootstrap';
import { UserIndexResponse } from 'RtModels';
import { PermissionEditForm } from 'RtUi/app/Administration/Users/lib/forms/PermissionEditForm';
import { RemoveTFAForm } from 'RtUi/app/Administration/Users/lib/forms/RemoveTFAForm';
import { UserAdminPasswordResetForm } from 'RtUi/app/Administration/Users/lib/forms/UserAdminPasswordResetForm';
import { UserProfileEditor } from 'RtUi/app/user/lib/forms/UserProfileEditor';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { timestampToReadable } from 'RtUi/utils/maps';
import { UserHttp } from '../../user/lib/Http/UserHttp';
import { AdminUserRouter } from './AdminUser.router';

interface IAdminUserContainerState {
	activeTab: string;
	selectedUser: UserIndexResponse | undefined;
}

@AdminUserRouter.getProfileRtUiController()
export class AdminUserProfileContainer extends ProfileApplicationContainer<
	{},
	IAdminUserContainerState
> {
	public Tabs = AdminUserRouter.getProfileTabs();

	public state: IAdminUserContainerState = {
		activeTab: this.Tabs.Profile.header,
		selectedUser: undefined
	};

	public userHttp = new UserHttp();

	public async componentDidMount() {
		const userId = Number(this.getIdParam());
		const selectedUser = await this.userHttp.getUser(userId);

		if (selectedUser) {
			this.setState({ selectedUser });
		}
	}

	public render() {
		const { selectedUser } = this.state;

		if (!selectedUser) {
			return <Loading />;
		}

		return (
			<TabbedLayout<UserIndexResponse>
				router={AdminUserRouter}
				profile={selectedUser}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				onBackClick={() => this.goToPath(AdminUserRouter.getIndexRoute(true))}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<Row>
						<Col md={8}>
							<UserProfileEditor
								disablePadding
								editMode={selectedUser}
								onUpdate={(newUser) => this.setState({ selectedUser: newUser })}
							/>
						</Col>
						<Col md={4}>
							<CollapsibleCard
								className="mb-3"
								header="Administration"
								useCardBody={true}
							>
								<Row>
									<Col md={12}>
										<InputFormControl
											label="Bad Password Count"
											displayMode={true}
											value={String(selectedUser.badPasswordCount)}
										/>
										<InputFormControl
											label="Last Bad Password time"
											displayMode={true}
											value={
												selectedUser.badPasswordTime
													? timestampToReadable(selectedUser.badPasswordTime)
													: ''
											}
										/>
										<InputFormControl
											label="Last Login"
											displayMode={true}
											value={
												selectedUser.lastLoginTs
													? timestampToReadable(selectedUser.lastLoginTs)
													: ''
											}
										/>
										<InputFormControl
											label="Two Factor Authentication"
											displayMode={true}
											value={
												selectedUser.twoFactorAuthType === 1 ? 'On' : 'Off'
											}
										/>
										<InputFormControl
											label="Created"
											displayMode={true}
											value={
												selectedUser.createdTs
													? timestampToReadable(selectedUser.createdTs)
													: ''
											}
										/>
										<InputFormControl
											label="Email Verified"
											displayMode={true}
											value={
												selectedUser.emailAuthorizationTs
													? timestampToReadable(
															selectedUser.emailAuthorizationTs
														)
													: ''
											}
										/>
										<InputFormControl
											label="Updated"
											displayMode={true}
											value={
												selectedUser.updatedTs
													? timestampToReadable(selectedUser.updatedTs)
													: ''
											}
										/>
									</Col>
								</Row>
							</CollapsibleCard>
						</Col>
					</Row>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Permissions}>
					<PermissionEditForm
						editMode={selectedUser}
						currentUserId={this.Actions.User.getUserId()!}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.PasswordReset}>
					<UserAdminPasswordResetForm user={selectedUser} />
				</TabbedLayoutTab>
				<TabbedLayoutTab
					{...this.Tabs.RemoveTFA}
					isHidden={selectedUser.twoFactorAuthType !== 1}
				>
					<RemoveTFAForm
						user={selectedUser}
						onSuccess={(updatedUser) => {
							this.setState({
								selectedUser: updatedUser,
								activeTab: this.Tabs.Profile.header
							});
						}}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
