import { RouteDepthIndexResponse } from 'RtModels';
import { RATE_COLUMN } from 'RtUi/app/rtSip/RouteDepth/lib/grid/RouteDepthGrid';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { RateCenterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RateCenterDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';

export const getRouteDepthColumns = (
	maxDepth: number,
	rateColumn: RATE_COLUMN
): Array<DataGridColumn<RouteDepthIndexResponse>> => {
	const columns: Array<DataGridColumn<any>> = [
		HeaderDataGridColumn({
			header: 'Location',
			columns: [
				RateCenterDataGridColumn({
					accessorKey: 'rateCenterId',
					header: 'Rate Center'
				}),
				DefaultDataGridColumn({
					accessorKey: 'rateKey',
					header: 'Rate Key'
				})
			]
		})
	];

	for (let i = 0; i < maxDepth; i++) {
		columns.push(
			HeaderDataGridColumn({
				header: `Route Depth ${i + 1}`,
				columns: [
					SubscriptionDataGridColumn({
						accessorKey: `subscriptionId_${i}`,
						header: `Subscription`
					}),
					DefaultDataGridColumn({
						accessorKey: `subscriptionRateKey_${i}`,
						header: `Key`
					}),
					PrecisionDataGridColumn({
						accessorKey: `${rateColumn}_${i}`,
						header: `Rate`,
						decimals: 4
					})
				]
			})
		);
	}

	return columns;
};
