import {
	GuardianSwitchFileStreamIndexResponse,
	SipAgentAddressIndexResponse,
	SwitchAddressIndexResponse,
	SwitchIndexResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { FileStreamDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FileStreamDataColumn';
import { FqdnDataGridColumn } from 'RtUi/components/data/DataGrid/columns/FqdnDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { PortDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PortDataGridColumn';
import { SipAgentConfigLabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SipAgentConfigLabelDataGridColumn';
import { SipAgentDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SipAgentDataGridColumn';
import { SwitchAddressDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SwitchAddressDataGridColumn';
import { SwitchTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SwitchTypeDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getSwitchesColumns = (
	onDeleteHandler: (row: SwitchIndexResponse) => void
): Array<DataGridColumn<SwitchIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => onDeleteHandler(row)
	}),
	IdDataGridColumn({
		accessorKey: 'switchId'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	SwitchTypeDataGridColumn({
		accessorKey: 'switchTypeId',
		header: 'Type'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DefaultDataGridColumn({
		accessorKey: 'switchMajorVersion',
		header: 'Major Version',
		align: DataGridColumnAlignment.CENTER
	}),
	DefaultDataGridColumn({
		accessorKey: 'switchMinorVersion',
		header: 'Minor Version',
		align: DataGridColumnAlignment.CENTER
	}),
	DefaultDataGridColumn({
		accessorKey: 'switchPatchVersion',
		header: 'Patch Version',
		align: DataGridColumnAlignment.CENTER
	})
];

export const getSwitchFileStreamsColumns = (
	onDeleteHandler: (row: GuardianSwitchFileStreamIndexResponse) => void
): Array<DataGridColumn<GuardianSwitchFileStreamIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => onDeleteHandler(row)
	}),
	IdDataGridColumn({
		accessorKey: 'guardianSwitchFileStreamId'
	}),
	FileStreamDataGridColumn({
		header: 'File Stream',
		accessorKey: 'fileStreamId'
	})
];

export const getSipAgentAddressesColumns = (
	onDeleteHandler: (row: SipAgentAddressIndexResponse) => void
): Array<DataGridColumn<SipAgentAddressIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => onDeleteHandler(row)
	}),
	IdDataGridColumn({
		accessorKey: 'sipAgentAddressId'
	}),
	SwitchAddressDataGridColumn({
		accessorKey: 'switchAddressId',
		header: 'Switch Address'
	}),
	SipAgentDataGridColumn({
		accessorKey: 'sipAgentId',
		header: 'SIP Agent'
	}),
	SipAgentConfigLabelDataGridColumn({
		accessorKey: 'sipAgentConfigId',
		header: 'SIP Agent Config'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	})
];

export const getSwitchAddressesColumns = (
	onDeleteHandler: (row: SwitchAddressIndexResponse) => void
): Array<DataGridColumn<SwitchAddressIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => onDeleteHandler(row)
	}),
	IdDataGridColumn({
		accessorKey: 'switchAddressId'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	FqdnDataGridColumn({
		accessorKey: 'fqdn'
	}),
	PortDataGridColumn({
		accessorKey: 'port'
	})
];
