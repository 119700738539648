/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum NetworkEfficiencyColumns {
    RevenueAccountId = 'revenueAccountId',
    RevenueSubscriptionId = 'revenueSubscriptionId',
    CountryIsoId = 'countryIsoId',
    RateCenterId = 'rateCenterId',
    RouteDepth = 'routeDepth',
}