/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocGetDocumentRequest = {
    properties: {
        documentId: {
            type: 'number',
            isRequired: true,
        },
        isLoa: {
            type: 'boolean',
        },
    },
};