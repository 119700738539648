import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { CdrExportRetentionIndexResponse } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';

export interface ICdrExportRetentionIndex
	extends CdrExportRetentionIndexResponse {}

export class CdrExportRetentionResource extends ArrayResource<ICdrExportRetentionIndex> {
	constructor() {
		super('retentionId');

		this.setApiRouteForGetAll(RtxSipApiRoutes.CdrExportRetentions.Index);
	}
}
