/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExportControlIndexResponse = {
    properties: {
        cdrExportControlId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        sdrSourceTypeId: {
            type: 'number',
            isRequired: true,
        },
        cdrSourceTypeName: {
            type: 'string',
            isRequired: true,
        },
        cdrSourceValue: {
            type: 'string',
            isRequired: true,
        },
        cdrExportServiceId: {
            type: 'number',
            isRequired: true,
        },
        cdrExportTypeId: {
            type: 'number',
            isRequired: true,
        },
        cdrExportTypeName: {
            type: 'string',
            isRequired: true,
        },
        serviceId: {
            type: 'number',
            isRequired: true,
        },
        serviceName: {
            type: 'string',
            isRequired: true,
        },
        cdrExportIntervalId: {
            type: 'number',
            isRequired: true,
        },
        cdrExportIntervalName: {
            type: 'string',
            isRequired: true,
        },
        cdrRetentionId: {
            type: 'number',
            isRequired: true,
        },
        cdrRetentionName: {
            type: 'string',
            isRequired: true,
        },
        isConnected: {
            type: 'number',
            isRequired: true,
        },
        lastReferenceId: {
            type: 'string',
            isRequired: true,
        },
        nextReferenceId: {
            type: 'string',
            isRequired: true,
        },
        lastAttemptTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        lastSuccessTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        nextRunTimeTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        cdrSourceTypeId: {
            type: 'number',
            isRequired: true,
        },
    },
};