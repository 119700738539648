/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum GuardianConnectionUpdateOptions {
    ThisTrunkGroupOnly = 0,
    AllTrunkGroupsInThisSwitch = 1,
    AllTrunkGroups = 2,
}