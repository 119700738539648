import {
	IRouterBreadcrumb,
	IRtUiControllerInitialParams,
	RtUiRouter
} from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';

export abstract class RtVueModuleRouter<
	IndexType,
	ProfileType,
	TabType extends IProfileApplicationContainerTabs
> extends RtUiRouter<IndexType, ProfileType, TabType> {
	public static readonly SectionKey = 'rtVue';

	constructor(
		name: string,
		containerKey: string,
		propertyKey: IndexType extends void ? undefined : keyof IndexType,
		profileTabs: TabType
	) {
		super(
			RtVueModuleRouter.SectionKey,
			name,
			containerKey,
			propertyKey,
			profileTabs
		);
	}

	/**
	 * Prepend section breadcrumb
	 * @param currentPath
	 * @param profile
	 */
	public async getBreadcrumbs(
		currentPath: string,
		profile?: ProfileType
	): Promise<IRouterBreadcrumb[]> {
		const breadcrumbs = await super.getBreadcrumbs(currentPath, profile);

		return [this.getSectionBreadcrumb(), ...breadcrumbs];
	}

	public getReconciliationIndexRtUiController(
		params: IRtUiControllerInitialParams = {}
	) {
		return this.getIndexRtUiController({
			...params,
			groupName: 'Reconciliation',
			groupId: 'Reconciliation',
			groupOrderPriority: 40
		});
	}

	/**
	 * Section's breadcrumb
	 */
	protected getSectionBreadcrumb() {
		const sectionBreadcrumb: IRouterBreadcrumb = {
			name: 'RT/Vue',
			path: '',
			isActive: true
		};

		return sectionBreadcrumb;
	}
}
