/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TermSummaryIndexResponseData = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        dialcode: {
            type: 'string',
            isRequired: true,
        },
        jurisdiction: {
            type: 'string',
            isRequired: true,
        },
        ocn: {
            type: 'string',
        },
        accountId: {
            type: 'number',
        },
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        connects: {
            type: 'number',
            isRequired: true,
        },
        duration: {
            type: 'number',
            isRequired: true,
        },
        revenue: {
            type: 'number',
            isRequired: true,
        },
        cost: {
            type: 'number',
            isRequired: true,
        },
        margin: {
            type: 'number',
            isRequired: true,
        },
        marginPercent: {
            type: 'number',
            isRequired: true,
        },
        rpm: {
            type: 'number',
            isRequired: true,
        },
        cpm: {
            type: 'number',
            isRequired: true,
        },
        mpm: {
            type: 'number',
            isRequired: true,
        },
        rejectCount: {
            type: 'number',
            isRequired: true,
        },
        rejectDuration: {
            type: 'number',
            isRequired: true,
        },
    },
};