import { Alert } from 'react-bootstrap';
import { LcrCarrierProfileResponse, LcrRateSheetType } from 'RtModels';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { LcrCarrierRouter } from './LcrCarrier.router';
import { LcrCarrierEditor } from './lib/components/LcrCarrierEditor';
import { UploadCarrierRatesForm } from './lib/forms/UploadCarrierRatesForm';
import { LcrCarrierRatesGrid } from './lib/grids/LcrCarrierRatesGrid';
import { LcrCarrierRatesSetupGrid } from './lib/grids/LcrCarrierRatesSetupGrid';
import { LcrCarriersResource } from './lib/resources/LcrCarriersResource';
import { timestampToReadable } from 'RtUi/utils/maps';

interface ILcrCarrierContainerProps {}

interface ILcrCarrierContainerState {
	activeTab: string;
	lcrCarrierId: number;
	lcrCarrier?: LcrCarrierProfileResponse;
}

@LcrCarrierRouter.getProfileRtUiController()
export class LcrCarrierProfileContainer extends ProfileApplicationContainer<
	ILcrCarrierContainerProps,
	ILcrCarrierContainerState
> {
	public Tabs = LcrCarrierRouter.getProfileTabs();
	public state: ILcrCarrierContainerState = {
		activeTab: this.Tabs.Profile.header,
		lcrCarrierId: NaN
	};

	public componentDidMount() {
		this.loadLcrCarrier();
	}

	public loadLcrCarrier() {
		const lcrCarriersResource = new LcrCarriersResource();
		const lcrCarrierIdStr = this.getIdParam();
		const lcrCarrierId = parseInt(lcrCarrierIdStr, 10);

		lcrCarriersResource
			.get(lcrCarrierId)
			.then((lcrCarrier) => this.setState({ lcrCarrier }));

		this.setState({ lcrCarrierId });
	}

	public onNewRatesUploaded(rateSheetTypeId: LcrRateSheetType) {
		const { lcrCarrier } = this.state;

		if (lcrCarrier) {
			lcrCarrier.rateSheetTypeId = rateSheetTypeId;

			this.setState({ lcrCarrier });
		}

		this.setState({ activeTab: this.Tabs.Rates.header });
	}

	public render() {
		const { lcrCarrier } = this.state;

		if (!lcrCarrier) {
			return <Loading />;
		}

		return (
			<TabbedLayout<LcrCarrierProfileResponse>
				router={LcrCarrierRouter}
				profile={lcrCarrier}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
				onBackClick={() => this.goToPath(LcrCarrierRouter.getIndexRoute(true))}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<LcrCarrierEditor
						editMode={this.state.lcrCarrier}
						onUpdate={(newLcrCarrier) =>
							this.setState({ lcrCarrier: newLcrCarrier })
						}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.RatingSetup}>
					<LcrCarrierRatesSetupGrid lcrCarrierId={lcrCarrier.lcrCarrierId} />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.Rates}>
					{lcrCarrier.lastRatesUploadTs && (
						<Alert
							variant="success"
							className="d-inline-flex justify-content-start align-items-center p-1"
						>
							<i className="fas fa-fw fa-clock me-1" />
							Last Updated on{' '}
							{timestampToReadable(lcrCarrier.lastRatesUploadTs)}
						</Alert>
					)}
					<LcrCarrierRatesGrid
						lcrCarrierId={lcrCarrier.lcrCarrierId}
						rateSheetTypeId={lcrCarrier.rateSheetTypeId}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab {...this.Tabs.UploadRates}>
					<UploadCarrierRatesForm
						lcrCarrierId={lcrCarrier.lcrCarrierId}
						onUpdate={(rateSheetTypeId) =>
							this.onNewRatesUploaded(rateSheetTypeId)
						}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
