import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from '../FormControl';
import { RadioFormControl } from '../RadioFormControl';
import { IRadioButtonOptions } from 'RtUi/components/ui/RadioButtons';

interface IBooleanRadioFormControlProps extends IFormControlProps<number> {
	label: string;
	showBothOption?: boolean;
	customOption?: IRadioButtonOptions<number>;
}

export class BooleanRadioFormControl extends FormControl<
	number,
	false,
	IBooleanRadioFormControlProps
> {
	public resource = undefined;
	public state: IFormControlState = {
		formLabel: ''
	};

	public render() {
		const options: Array<IRadioButtonOptions<number>> = [
			{ value: 1, label: 'Yes' },
			{ value: 0, label: 'No' }
		];

		if (this.props.showBothOption) {
			options.push({ value: -1, label: 'Both' });
		}

		if (this.props.customOption) {
			options.push(this.props.customOption);
		}

		return (
			<RadioFormControl<number>
				label={this.props.label}
				hideLabel={this.props.hideLabel}
				displayMode={this.props.displayMode}
				onChange={(newValue) =>
					this.props.onChange && this.props.onChange(newValue)
				}
				disabled={this.props.disabled}
				value={this.props.value}
				options={options}
				required={this.props.required}
			/>
		);
	}
}
