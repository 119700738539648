import { GuardianAlertTypeIndexResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { GuardianAlertTypesResource } from '../resources/GuardianAlertTypesResource';

interface IAlertTypeSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<GuardianAlertTypeIndexResponse, IsMulti> {
	showSystemAlerts?: boolean;
}

export class AlertTypeSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	GuardianAlertTypeIndexResponse,
	IsMulti,
	IAlertTypeSelectProps<IsMulti>
> {
	public resourceClass = GuardianAlertTypesResource;
	public state: ISelectFormControlState<GuardianAlertTypeIndexResponse> = {
		formLabel: 'Alert Type',
		valueKey: 'alertTypeId',
		labelKey: 'summary'
	};

	constructor(props: IAlertTypeSelectProps<IsMulti>) {
		super(props);

		if (props.showSystemAlerts) {
			this.setGetAllParams({ showSystemAlerts: 1 });
		}
	}

	public optionRenderer = (record: GuardianAlertTypeIndexResponse) => {
		return (
			<span>
				{record.source}&nbsp;-&nbsp;
				{record.summary}
			</span>
		);
	};
}
