import { RtxSipApiRoutes } from 'RtExports/routes';
import { RouteDepthIndexRequest, RouteDepthIndexResponse } from 'RtModels';
import { handleGetRequest } from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchRouteDepth = async (
	queryParams?: RouteDepthIndexRequest
): Promise<RouteDepthIndexResponse[]> => {
	const data = await handleGetRequest(RtxSipApiRoutes.RouteDepth.Index, {
		queryParams
	});
	return data;
};

export const useGetRouteDepth = (queryParams?: RouteDepthIndexRequest) => {
	return useQuery<RouteDepthIndexResponse[], Error>('getRouteDepth', () =>
		fetchRouteDepth(queryParams)
	);
};
