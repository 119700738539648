/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CpsIndexResponse = {
    properties: {
        data: {
            type: 'Array',
            isRequired: true,
        },
        compareTo: {
            type: 'CpsCompareToData',
        },
    },
};