import { RtVueModuleRouter } from '../RtVue.router';
import { FileStreamIndexResponse, FileStreamProfileResponse } from 'RtModels';
import { RtVueApiRoutes, Permissions } from 'RtExports/routes';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IFileStreamRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Download: TabbedLayoutTabProps;
	Reports: TabbedLayoutTabProps;
	Admin: TabbedLayoutTabProps;
}

const FileStreamContainerTabs: IFileStreamRouterTabs = {
	Profile: {
		header: 'Profile'
	},
	Download: {
		header: 'Download'
	},
	Reports: {
		header: 'Reports'
	},
	Admin: {
		header: 'Admin',
		permissions: [Permissions.RtxAdministrator]
	},
	CdrFiles: {
		header: 'CDR Files'
	}
};

export class FileStreamRouterClass extends RtVueModuleRouter<
	FileStreamIndexResponse,
	FileStreamProfileResponse,
	IFileStreamRouterTabs
> {
	constructor() {
		super(
			'File Streams',
			'fileStreams',
			'fileStreamId',
			FileStreamContainerTabs
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.FileStreams);
	}

	public getProfileLabel(profile: FileStreamProfileResponse): string {
		return profile.description;
	}
}

export const FileStreamRouter = new FileStreamRouterClass();
