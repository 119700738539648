import clsx from 'clsx';
import * as moment from 'moment-timezone';
import { Alert, Form, InputGroup } from 'react-bootstrap';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from 'RtUi/components/form/FormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';

interface ITimeFormControlProps
	extends IFormControlProps<moment.Moment, false> {
	label: string;
	timeValue: moment.Moment;
	onTimeChange: (value: moment.Moment) => void;
	minHours?: number;
	minMinutes?: number;
	maxHours?: number;
	maxMinutes?: number;
	formControl?: boolean;
}

interface ITimeFormControlState extends IFormControlState {
	timeValue: moment.Moment;
}

type FieldSignifierType = 'hour' | 'minute';

/**
 * Creates a custom component for time manipulation.
 *  Has three/two independent inputs:
 *  - number input 0-24 for hours,
 *  - number input 0-60 for minutes
 *
 */
export class TimeFormControl extends FormControl<
	moment.Moment,
	false,
	ITimeFormControlProps,
	ITimeFormControlState
> {
	public resource = undefined;
	public state: ITimeFormControlState = {
		formLabel: 'Time',
		timeValue: this.props.timeValue
	};

	/**
	 * Handles time change with conditional Meridiem handling, otherwise sets time as is
	 * @param {FieldSignifierType} fieldSignifier
	 * @param {string} value
	 */
	public setTimeValue(fieldSignifier: FieldSignifierType, value: string) {
		let preparedTimeValue = this.props.timeValue;
		preparedTimeValue = preparedTimeValue.set(fieldSignifier, Number(value));
		this.props.onTimeChange(preparedTimeValue);
	}

	public render() {
		const {
			label,
			disabled,
			displayMode,
			className,
			required,
			minHours,
			minMinutes,
			maxHours,
			maxMinutes
		} = this.props;
		const { timeValue } = this.props;
		const isMinHoursReached = timeValue.hours() === minHours;
		const isMaxHoursReached = timeValue.hours() === maxHours;
		return (
			<ControlGroup
				label={label}
				value={timeValue.format('hh:mm a')}
				displayMode={displayMode}
				className={className}
				required={required}
			>
				<section className={clsx({ rdtTimeSection: !this.props.formControl })}>
					{disabled && (
						<Alert variant="info" className="d-flex justify-content-start">
							<i className="fas fa-fw fa-info-circle me-1" />
							<span>Select a date first</span>
						</Alert>
					)}
					<article className="d-flex justify-content-center align-items-center">
						<InputGroup>
							<Form.Control
								type="number"
								min={minHours ? minHours : 0}
								max={maxHours ? maxHours : 23}
								value={timeValue.format('HH')}
								disabled={disabled}
								onChange={(evt) => this.setTimeValue('hour', evt.target.value)}
							/>
							<Form.Control
								type="number"
								min={isMinHoursReached ? minMinutes : 0}
								max={isMaxHoursReached ? maxMinutes : 60}
								value={timeValue.format('mm')}
								onChange={(evt) =>
									this.setTimeValue('minute', evt.target.value)
								}
								disabled={disabled}
							/>
						</InputGroup>
					</article>
				</section>
			</ControlGroup>
		);
	}
}
