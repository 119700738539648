import { AccountIndexRequest, AccountIndexResponse } from 'RtModels';
import { AccountRouter } from 'RtUi/app/AccountManagement/Accounts/Account.router';
import { AccountResource } from 'RtUi/app/AccountManagement/Accounts/lib/resources/AccountResource';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';

interface IAccountSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<AccountIndexResponse, IsMulti> {
	showAllOption?: boolean;
	isActive?: number;
}

export class AccountSelect<
	IsMulti extends boolean = false
> extends SelectFormControl<
	AccountIndexResponse,
	IsMulti,
	IAccountSelectProps<IsMulti>
> {
	public static defaultProps = {
		router: AccountRouter
	};
	public resourceClass = AccountResource;
	public state: ISelectFormControlState<AccountIndexResponse> = {
		formLabel: 'Account',
		valueKey: 'accountId',
		labelKey: 'label'
	};

	constructor(props: IAccountSelectProps<IsMulti>) {
		super(props);
		const { isActive = 1 } = props;
		const getAllParams: AccountIndexRequest = { isActive };

		this.setGetAllParams(getAllParams);
	}

	/**
	 * @override
	 */
	public getData() {
		return super.getData().then((records) => {
			if (this.props.showAllOption) {
				records.splice(0, 0, {
					label: 'All',
					//filler
					accountId: -1,
					isActive: -1,
					customerBalance: -1,
					vendorBalance: -1,
					combinedBalance: -1,
					customerLimit: -1,
					vendorLimit: -1,
					combinedLimit: -1,
					companyName: ''
				});
			}

			return records;
		});
	}
}
