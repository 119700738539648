import { RateCenterIndexResponse } from 'RtModels';
import { RtVueRateCenterFilter } from 'RtUi/app/rtVue/common/lib/filters/RtVueRateCenterFilter';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface RateCenterDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		RateCenterIndexResponse,
		T
	> {
	header?: string;
}

export const RateCenterRenderCache =
	new ResourceCacheMap<RateCenterIndexResponse>(
		RtVueRateCenterFilter.getInstance(),
		'rateCenterId',
		'label'
	);

export const RateCenterDataGridColumn = <T = any,>({
	header = 'Rate Center',
	...config
}: RateCenterDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		header,
		resourceCacheMap: RateCenterRenderCache,
		...config
	});
};
