/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExportControlUpdateRequest = {
    properties: {
        isActive: {
            type: 'number',
            isRequired: true,
        },
        cdrSourceTypeId: {
            type: 'number',
            isRequired: true,
        },
        cdrSourceValue: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        cdrExportServiceId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        cdrExportIntervalId: {
            type: 'number',
            isRequired: true,
        },
        cdrExportRetentionId: {
            type: 'number',
            isRequired: true,
        },
        isConnected: {
            type: 'number',
            isRequired: true,
        },
        nextRunTimeTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};