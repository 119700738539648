import * as React from 'react';
import { FormWizard, FormWizardStep } from 'RtUi/components/form/FormWizard';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { JobTaskSpreadsheetParser } from 'RtUi/app/rtCarrierConnect/Jobs/bin/JobTaskSpreadsheetParser';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { JobTaskRecord, JobTaskUpdateResponse } from 'RtModels';
import { JobTaskRecordGrid } from 'RtUi/app/rtCarrierConnect/Jobs/lib/grids/JobTaskRecordGrid';
import { JobTaskResource } from 'RtUi/app/rtCarrierConnect/Jobs/lib/resources/JobTaskResource';

interface IJobTasksBulkUploaderProps {
	jobTaskResource: JobTaskResource;
	onSuccess: (response: JobTaskUpdateResponse) => void;
}

interface IJobTasksBulkUploaderState {
	currentStep: number;
	error?: any;
	processingFile?: boolean;
	jobTaskRecordResource: ArrayResource<JobTaskRecord> | null;
	isSubmitting?: boolean;
	file?: File;
}

export class JobTasksBulkUploader extends React.Component<
	IJobTasksBulkUploaderProps,
	IJobTasksBulkUploaderState
> {
	public state: IJobTasksBulkUploaderState = {
		currentStep: 1,
		isSubmitting: false,
		processingFile: false,
		jobTaskRecordResource: null,
		error: undefined
	};

	public async processFile(file: File) {
		const fileUtils = new FileUtils();

		this.setState({
			processingFile: true,
			isSubmitting: true,
			file: undefined,
			jobTaskRecordResource: null
		});

		try {
			const spreadsheet = await fileUtils.fileToSpreadsheet(file);
			const jobTaskSpreadsheetParser = new JobTaskSpreadsheetParser(
				spreadsheet
			);
			const jobTaskRecords =
				jobTaskSpreadsheetParser.parseAndFindJobTaskRecords();
			const jobTaskRecordResource = new ArrayResource<JobTaskRecord>(
				'referenceKey',
				jobTaskRecords
			);

			this.setState({ currentStep: 2, file, jobTaskRecordResource });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ processingFile: false, isSubmitting: false });
		}
	}

	public async bulkUpload() {
		const { jobTaskRecordResource } = this.state;
		const { jobTaskResource } = this.props;

		if (!jobTaskRecordResource) {
			return;
		}

		this.setState({ isSubmitting: true, error: undefined });

		let response: JobTaskUpdateResponse | undefined;

		try {
			const jobTaskRecords = await jobTaskRecordResource.getAll();

			response = await jobTaskResource.bulkUpdate(jobTaskRecords);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (response) {
			this.props.onSuccess(response);
		}
	}

	public render() {
		return (
			<FormWizard
				currentStep={this.state.currentStep}
				onChange={(currentStep) => this.setState({ currentStep })}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
			>
				<FormWizardStep step={1} header="Upload File">
					<DragAndDropFileUploader
						required
						accept={FileUtils.AcceptTypes.Spreadsheet}
						processing={this.state.processingFile}
						onDrop={(file) => this.processFile(file)}
						value={this.state.file}
					/>
				</FormWizardStep>
				<FormWizardStep
					step={2}
					disableBody
					onSubmit={() => this.bulkUpload()}
					header="Review & Submit"
					submitText="Submit"
				>
					<section className="table-info text-info">
						<section className="p-3 d-flex justify-content-start">
							<article>
								<i className="fas fa-fw fa-info-circle me-2" />
							</article>
							<article>
								Review the job references below before submitting.
							</article>
						</section>
					</section>
					<JobTaskRecordGrid
						resource={this.state.jobTaskRecordResource}
						jobTaskResource={this.props.jobTaskResource}
					/>
				</FormWizardStep>
			</FormWizard>
		);
	}
}
