import { CdrExportIndexRequest, CdrExportIndexResponse } from 'RtModels';
import { CdrExportResource } from 'RtUi/app/rtSip/CdrExports/lib/resources/CdrExportResource';
import { useGetCdrExport } from 'RtUi/app/rtSip/CdrExports/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getCdrExportsColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/cdrExports';
import { useCallback, useMemo, useState } from 'react';

interface ICdrExportGridProps {
	resourceParams?: CdrExportIndexRequest;
	autoFocusFilter?: boolean;
}

export const CdrExportGrid = ({ resourceParams }: ICdrExportGridProps) => {
	const [downloadingCdrExportIds, setDownloadingCdrExportIds] = useState<
		number[]
	>([]);

	const { data, isFetching: isLoading } = useGetCdrExport(resourceParams);

	const downloadCdr = useCallback(async (cdrExportId: number) => {
		setDownloadingCdrExportIds((currentState) => {
			const newState = [...currentState];
			newState.push(cdrExportId);
			return newState;
		});

		try {
			const cdrExportResource = new CdrExportResource();
			await cdrExportResource.downloadCdr(cdrExportId);
		} finally {
			setDownloadingCdrExportIds((currentState) => {
				const newState = [...currentState].filter(
					(exportId) => exportId !== cdrExportId
				);
				return newState;
			});
		}
	}, []);

	const isDownloading = useCallback(
		(cdrExportId: number) => downloadingCdrExportIds.includes(cdrExportId),
		[downloadingCdrExportIds]
	);

	const columns = useMemo(
		() => getCdrExportsColumns(isDownloading, downloadCdr),
		[isDownloading, downloadCdr]
	);

	return (
		<DataGrid<CdrExportIndexResponse>
			data={data?.data}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_cdrExport'}
			columns={columns}
		/>
	);
};
