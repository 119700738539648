/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileStreamProfileResponse = {
    properties: {
        fileStreamId: {
            type: 'number',
            isRequired: true,
        },
        description: {
            type: 'string',
            isRequired: true,
        },
        fileLabel: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        fileTypeId: {
            type: 'number',
            isRequired: true,
        },
        guardianSwitchId: {
            type: 'number',
            isRequired: true,
        },
        isDownload: {
            type: 'number',
            isRequired: true,
        },
        downloadRemoteProtocol: {
            type: 'DownloadTypes',
        },
        downloadRemoteCredentials: {
            type: 'string',
            isRequired: true,
        },
        downloadRemotePath: {
            type: 'string',
            isRequired: true,
        },
        downloadRemoteDelete: {
            type: 'number',
            isRequired: true,
        },
        downloadRemoteMovePath: {
            type: 'string',
            isRequired: true,
        },
        downloadCompressionType: {
            type: 'string',
            isRequired: true,
        },
        downloadFileRegex: {
            type: 'string',
        },
        downloadLocalPath: {
            type: 'string',
            isRequired: true,
        },
        workingLocalPath: {
            type: 'string',
            isRequired: true,
        },
        workingOutputPath: {
            type: 'string',
            isRequired: true,
        },
        topicRawData: {
            type: 'string',
            isRequired: true,
        },
        topicRawDataPeakCps: {
            type: 'number',
            isRequired: true,
        },
        topicTransformedData: {
            type: 'string',
            isRequired: true,
        },
        outputType: {
            type: 'string',
            isRequired: true,
        },
        isLoadRaw: {
            type: 'number',
            isRequired: true,
        },
        isNetworkReporting: {
            type: 'number',
            isRequired: true,
        },
        isFinancialReporting: {
            type: 'number',
            isRequired: true,
        },
        isFraud: {
            type: 'number',
            isRequired: true,
        },
        isRating: {
            type: 'number',
            isRequired: true,
        },
        isCosting: {
            type: 'number',
            isRequired: true,
        },
        isVueCpsPorts: {
            type: 'number',
            isRequired: true,
        },
        isArchived: {
            type: 'number',
            isRequired: true,
        },
        archiveBucket: {
            type: 'string',
        },
        archiveDelay: {
            type: 'number',
            isRequired: true,
        },
        cdrRetention: {
            type: 'number',
            isRequired: true,
        },
        reportingRetention: {
            type: 'number',
            isRequired: true,
        },
        archiveRetention: {
            type: 'number',
            isRequired: true,
        },
        createdTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updateTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        pauseTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        timezone: {
            type: 'string',
        },
        accountNumber: {
            type: 'string',
        },
        isLrnDip: {
            type: 'number',
            isRequired: true,
        },
        isReconciliation: {
            type: 'number',
            isRequired: true,
        },
        isEnhancedTfReporting: {
            type: 'number',
            isRequired: true,
        },
        workingFileRegex: {
            type: 'string',
            isNullable: true,
        },
        downloadFrequency: {
            type: 'number',
            isRequired: true,
        },
        notificationUsers: {
            type: 'string',
            isNullable: true,
        },
        alertUsers: {
            type: 'string',
            isNullable: true,
        },
        notificationLevel: {
            type: 'string',
            isRequired: true,
        },
        downloadLocalTempPath: {
            type: 'string',
            isRequired: true,
        },
        origSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        termSubscriptionId: {
            type: 'number',
            isRequired: true,
        },
        createConnectionsByNewIp: {
            type: 'number',
            isRequired: true,
        },
        extraParams: {
            properties: {
            },
            isNullable: true,
        },
    },
};