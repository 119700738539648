/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FlowrouteResourceConfiguration = {
    properties: {
        resourceId: {
            type: 'number',
            isRequired: true,
        },
        resourceTypeId: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isDefault: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        configuration: {
            properties: {
            },
            isRequired: true,
        },
    },
};