import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { timestampToReadable } from 'RtUi/utils/maps';
import moment from 'moment';

interface TimeStampDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	timezone?: string;
	showTimezone?: boolean;
}

const getExportFormat = (timezone: string, showTimezone: boolean) => {
	const format = 'dd/mm/yyyy hh:mm:ss AM/PM';

	if (!(timezone && showTimezone)) {
		return format;
	}

	const tz = moment.tz.zone(timezone);

	if (!tz) {
		return format;
	}

	return `${format} ${tz.name}`;
};

export const TimeStampDataGridColumn = <T = any,>({
	timezone = '',
	showTimezone = false,
	...config
}: TimeStampDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: string) => {
		const displayValue = timestampToReadable(value, timezone);

		if (!(timezone && showTimezone)) {
			return displayValue;
		}

		const tz = moment.tz.zone(timezone);

		if (!tz) {
			return displayValue;
		}

		return `${displayValue} ${tz.name}`;
	};

	return DefaultDataGridColumn({
		...config,
		exportDateFormat: getExportFormat(timezone, showTimezone),
		exportValue: (value: string) => {
			if (!moment(value).isValid()) {
				return '';
			}

			return new Date(value);
		},
		getValue: ({ cell }) => getValue(cell.getValue<string>())
	});
};
