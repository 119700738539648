import { isEmpty } from '@firebase/util';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
	CountryDialCodeIndexResponse,
	ResourceIndexResponse,
	SearchSortMethod
} from 'RtModels';
import { StatesSelect } from 'RtUi/app/rtCommon/States/lib/controls/StatesSelect';
import { IStateOption } from 'RtUi/app/rtCommon/States/lib/resources/States';
import { DidSearchRouter } from 'RtUi/app/rtDid/Search/DidSearch.router';
import {
	DidNanpIndexRequest,
	DidNanpNumbersGrid
} from 'RtUi/app/rtDid/Search/lib/grids/DidNanpNumbersGrid';
import {
	useGetConfigurations,
	useGetResources
} from 'RtUi/app/rtDid/Search/service';
import { CountryDialCodeSelect } from 'RtUi/app/user/lib/controls/CountryDialCodeSelect';
import DoubleListBox from 'RtUi/components/form/DoubleListBox';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import ProperInput from 'RtUi/components/form/ProperInput';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { generateShortUUID } from 'RtUi/utils/http/resources/utils';

interface DidSearchContainerSearchCriteria {
	npa: string;
	nxx: string;
	line: string;
	state: string;
	cityCode: string;
	iso3: string;
	countryCode: string;
	digits: string;
}

interface DidSearchContainerSearch {
	resourceParams: DidNanpIndexRequest;
	criteria: DidSearchContainerSearchCriteria;
	error?: any;
}

export const DidSearchContainer = () => {
	const [searches, setSearches] = useState<DidSearchContainerSearch>();
	const [searchSortMethod, setSearchMethod] = useState<SearchSortMethod | -1>(
		-1
	);
	const [stateOption, setStateOption] = useState<IStateOption | null>(null);

	const [country, setCountry] = useState<CountryDialCodeIndexResponse>();
	const [countryCode, setCountryCode] = useState<string>('');
	const [iso3, setIso3] = useState<string>('');
	const [cityCode, setCityCode] = useState<string>('');
	const [digits, setDigits] = useState<string>('');
	const [isNanp, setIsNanp] = useState<null | number>(null);
	const [otherParams, setOtherParams] = useState({});
	const [npa, setNpa] = useState<string>('');
	const [nxx, setNxx] = useState<string>('');
	const [line, setLine] = useState<string>('');
	const [state, setState] = useState<string>('');
	const [error] = useState<null | Error>(null);
	const [isLoading] = useState<boolean>(false);
	const [pageSize, setPageSize] = useState<number>(10);

	const { data: configurationsResponse } = useGetConfigurations();

	const { data: availableResourcesData, refetch: fetchAvailable } =
		useGetResources('availableResources', otherParams);
	const { data: selectedResourcesData, refetch: fetchSelected } =
		useGetResources('selectedResources', otherParams);

	const [availableResources, setAvailableResources] = useState<
		ResourceIndexResponse[]
	>(availableResourcesData ?? []);
	const [selectedResources, setSelectedResources] = useState<
		ResourceIndexResponse[]
	>(selectedResourcesData ?? []);

	const searchCriteriaExists =
		Boolean(npa) ||
		Boolean(nxx) ||
		Boolean(line) ||
		Boolean(stateOption) ||
		Boolean(iso3) ||
		Boolean(countryCode) ||
		Boolean(cityCode) ||
		Boolean(digits);

	const setCountryValues = (country: CountryDialCodeIndexResponse) => {
		setAvailableResources([]);
		setSelectedResources([]);
		setCountry(country);
		setCountryCode(country.countryCode);
		setIso3(country.iso3);
		setIsNanp(Number(country.isNanp));
		if (Number(country.isNanp)) {
			setOtherParams({ isNanp: 1 });
		} else {
			setOtherParams({ isIddd: 1 });
		}
	};

	useEffect(() => {
		if (!isEmpty(otherParams)) {
			fetchAvailable();
			fetchSelected();
		}
	}, [fetchAvailable, fetchSelected, otherParams]);

	const handleSetState = (stateOption: any) => {
		setStateOption(stateOption);
		setState(stateOption?.abbreviation || '');
	};

	const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		const resourceParams: any = {};

		if (pageSize) {
			resourceParams.searchLimit = pageSize;
		}

		if (npa !== '' && isNanp) {
			resourceParams.npa = npa;
		}
		if (nxx !== '' && isNanp) {
			resourceParams.nxx = nxx;
		}
		if (line !== '' && isNanp) {
			resourceParams.line = line;
		}
		if (state !== '' && isNanp) {
			resourceParams.state = state;
		}
		if (cityCode !== '' && !isNanp) {
			resourceParams.cityCode = cityCode;
		}
		if (countryCode !== '' && !isNanp) {
			resourceParams.countryCode = countryCode;
		}
		if (iso3 !== '' && iso3 && !isNanp) {
			resourceParams.iso3 = iso3;
		}
		if (digits !== '' && !isNanp) {
			resourceParams.digits = digits;
		}
		if (selectedResources.length) {
			resourceParams.onlyResourceIds = selectedResources
				.map((r) => r.resourceId)
				.join(',');
		}
		if (searchSortMethod !== -1) {
			resourceParams.sortMethod = searchSortMethod;
		}

		const search: DidSearchContainerSearch = {
			criteria: { npa, nxx, line, state, countryCode, iso3, cityCode, digits },
			error: undefined,
			resourceParams
		};

		setSearches(search);
	};

	const renderSearchResult = (searchResult: DidSearchContainerSearch) => {
		const { npa, nxx, line, state, cityCode, countryCode, digits } =
			searchResult.criteria;
		const headers: string[] = [];

		if (npa && isNanp) {
			headers.push(`NPA ${npa}`);
		}

		if (nxx && isNanp) {
			headers.push(`NXX ${nxx}`);
		}

		if (line && isNanp) {
			headers.push(`Line ${line}`);
		}

		if (state && isNanp) {
			headers.push(`State ${state}`);
		}

		if (countryCode && !isNanp) {
			headers.push(`Country ${countryCode}`);
		}

		if (cityCode && !isNanp) {
			headers.push(`City ${cityCode}`);
		}

		if (digits && !isNanp) {
			headers.push(`Digits ${digits}`);
		}

		const header = headers.join(' · ');

		if (error) {
			return (
				<Card body className="mb-3">
					<section>
						<header className="h6 d-flex justify-content-start align-items-center">
							<i className="fas fa-fw fa-exclamation-triangle text-danger me-2" />
							<b>{header}</b>
						</header>
						<article>
							<FormErrors error={error} />
						</article>
					</section>
				</Card>
			);
		}

		const key = generateShortUUID();
		return (
			<>
				{!isLoading && configurationsResponse?.length && (
					<section className="mb-3" key={key}>
						<DidNanpNumbersGrid
							configurations={configurationsResponse}
							isNanp={isNanp ? true : undefined}
							header={header}
							resourceParams={searchResult.resourceParams}
							disableFilter
							hidePagination
						/>
					</section>
				)}
			</>
		);
	};

	useEffect(() => {
		if (availableResourcesData?.length) {
			setAvailableResources(
				availableResourcesData.map((val) => {
					return { ...val, value: val.resourceId };
				})
			);
		}
	}, [availableResourcesData, countryCode]);

	useEffect(() => {
		if (selectedResourcesData?.length) {
			setSelectedResources(
				selectedResourcesData.map((val) => {
					return { ...val, value: val.resourceId };
				})
			);
		}
	}, [selectedResourcesData, countryCode]);

	return (
		<StandardLayout router={DidSearchRouter}>
			<Row>
				<Col lg={10} xl={8}>
					<Form onSubmit={(evt) => onSubmit(evt)}>
						<CountryDialCodeSelect
							label="Country"
							onChange={(country) => setCountryValues(country)}
							value={country}
						/>
						{country && (
							<>
								<div>
									<DoubleListBox
										left={availableResources as any}
										right={selectedResources as any}
										setLeft={setAvailableResources}
										setRight={setSelectedResources}
									/>
									<RadioFormControl<SearchSortMethod | -1>
										label="Search Priority"
										value={searchSortMethod}
										onChange={(val) => {
											setSearchMethod(val);
										}}
										options={[
											{ label: 'None', value: -1 },
											{ label: 'Cost', value: SearchSortMethod.Cost },
											{
												label: 'Weighted Priority',
												value: SearchSortMethod.Weighting
											}
										]}
									/>
								</div>
								{isNanp ? (
									<div className="d-flex align-items-end">
										<ProperInput
											label="NPA"
											value={npa}
											onChange={(npa) => setNpa(npa)}
										/>
										<ProperInput
											label="NXX"
											value={nxx}
											onChange={(nxx) => setNxx(nxx)}
										/>
										<ProperInput
											label="Line"
											value={line}
											onChange={(line) => setLine(line)}
										/>
										<StatesSelect
											style={{
												width: 200,
												marginBottom: '0px !important'
											}}
											label="State"
											value={stateOption as IStateOption}
											onChange={(stateOption) => handleSetState(stateOption)}
										/>
									</div>
								) : (
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										<ProperInput
											label="Country Code"
											disabled
											style={{ width: 250 }}
											value={countryCode}
										/>
										<ProperInput
											label="City Code"
											value={cityCode}
											style={{ width: 250 }}
											onChange={(cityCode) => setCityCode(cityCode)}
										/>
										<ProperInput
											label="Contains Digits"
											style={{ width: 250 }}
											value={digits}
											onChange={(digits) => setDigits(digits)}
										/>
									</div>
								)}
								<div
									style={{
										maxWidth: 400,
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center'
									}}
									className="mb-2"
								>
									<span style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>
										Records per page
									</span>
									<Form.Control
										onChange={(e) => setPageSize(Number(e.target.value))}
										value={pageSize}
										maxLength={3}
									/>
								</div>
								<Form.Group
									className="mb-3"
									style={{ position: 'relative', marginTop: '30px' }}
								>
									<input
										type="text"
										key={String(searchCriteriaExists)}
										ref={(input) =>
											input &&
											input.setCustomValidity(
												searchCriteriaExists
													? ''
													: 'No Search Criteria Entered.'
											)
										}
										style={{
											height: 1,
											width: 0,
											opacity: 0.01,
											position: 'absolute',
											left: '25%',
											bottom: 0
										}}
										required={!searchCriteriaExists}
										defaultValue={searchCriteriaExists ? '1' : ''}
									/>

									{isLoading ? (
										<Loading />
									) : (
										<Button variant="submit" type="submit" className="mb-2">
											Search
										</Button>
									)}
								</Form.Group>
							</>
						)}
					</Form>
					{!isEmpty(searches as any) &&
						renderSearchResult(searches as DidSearchContainerSearch)}
				</Col>
			</Row>
		</StandardLayout>
	);
};

DidSearchRouter.setIndexRtUiFunctionalComponent(DidSearchContainer);
