import { CprNodeType } from 'RtModels';
import { CprNodeTypeNameMap } from 'RtUi/app/rt800/Cprs/lib/Constants';
import {
	CprNodeTypeSelect,
	NodeTypesResource
} from 'RtUi/app/rt800/Cprs/lib/controls/CprNodeTypeSelect';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { CprLbl } from 'Somos/lib/SomosCpr/RtCprV2';
import { noop } from 'lodash-es';

const possibleLabelNodeTypes = [
	CprNodeType.AreaCode,
	CprNodeType.Date,
	CprNodeType.DayOfWeek,
	CprNodeType.Lata,
	CprNodeType.NpaNxx,
	CprNodeType.SixDigit,
	CprNodeType.State,
	CprNodeType.TenDigit,
	CprNodeType.Time
];

interface CprNodeTypeDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	displayMode: boolean;
	onUpdate?: (label: CprLbl, nodeType: CprNodeType) => void;
}

export const CprNodeTypeDataGridColumn = <T = any,>({
	displayMode,
	size = 150,
	onUpdate = noop,
	...config
}: CprNodeTypeDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: CprNodeType, label: CprLbl) => {
		return CprNodeTypeNameMap.get(label.cprNodeTypeId) ?? String(value);
	};

	const renderValue = (value: CprNodeType, label: CprLbl) => {
		const option = NodeTypesResource.getOptionFor(value);

		if (displayMode) {
			return getValue(value, label);
		}

		return (
			<CprNodeTypeSelect
				appendDropdownToBody
				useControlGroup={false}
				clearable={false}
				limitCprCreateNodeTypes={possibleLabelNodeTypes}
				onChange={({ nodeType }) => onUpdate(label, nodeType)}
				value={option}
			/>
		);
	};

	return DefaultDataGridColumn({
		...config,
		size,
		exportValue: (value: CprNodeType, row) => getValue(value, row as any),
		getValue: ({ cell, row }) =>
			renderValue(cell.getValue<CprNodeType>(), row.original as any)
	});
};
