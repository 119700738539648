/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrCarrierCreateRequest = {
    properties: {
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        cic: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        allowAsDefault: {
            type: 'number',
            isRequired: true,
        },
        allowAsCanada: {
            type: 'number',
            isRequired: true,
        },
        defaultNpa: {
            type: 'string',
            isRequired: true,
        },
        defaultNxx: {
            type: 'string',
            isRequired: true,
        },
        defaultInterRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIntraRate: {
            type: 'number',
            isRequired: true,
        },
        defaultIndetRate: {
            type: 'number',
            isRequired: true,
        },
    },
};