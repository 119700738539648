import { SubscriptionIndexResponse } from 'RtModels';
import { useGetAccountSubscriptions } from 'RtUi/app/AccountManagement/Accounts/lib/services';
import { SubscriptionRouter } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.router';
import DataGrid from 'RtUi/components/data/DataGrid';
import { useMemo, useState } from 'react';
import {
	DataGridStatusDropdown,
	TDataGridStatusValueLabel
} from '../components/DataGridStatusDropdown';
import { getAccountsSubscriptionsColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/accounts';

interface IAccountSubscriptionGridProps {
	accountId: number;
	pageName: string;
	isCustomer?: boolean;
	header?: string;
	autoFocusFilter?: boolean;
}

export const AccountSubscriptionDataGrid = ({
	accountId,
	pageName,
	isCustomer = false,
	header
}: IAccountSubscriptionGridProps) => {
	const [selectedStatus, setSelectedStatus] =
		useState<TDataGridStatusValueLabel>(DataGridStatusDropdown.Statuses[0]);
	const { data, isFetching: isLoading } = useGetAccountSubscriptions(
		{
			accountId
		},
		{
			...(selectedStatus.value !== null && {
				isActive: Number(selectedStatus.value)
			}),
			isCustomer: Number(isCustomer)
		}
	);
	const columns = useMemo(
		() => getAccountsSubscriptionsColumns(isCustomer),
		[isCustomer]
	);

	return (
		<>
			{header && <h5 className="mb-3 fw-bold">{header}</h5>}
			<DataGrid<SubscriptionIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				router={SubscriptionRouter}
				pageName={pageName}
				headerAction={() => (
					<DataGridStatusDropdown
						value={selectedStatus}
						onChange={setSelectedStatus}
					/>
				)}
			/>
		</>
	);
};
