import { INumberProfile } from 'RtUi/app/rt800/Numbers/lib/resources/NumberResource';
import { tfnStatusToName, timestampToReadable } from 'RtUi/utils/maps';
import { FC } from 'react';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';

interface IAdminDataInfoProps {
	profile: INumberProfile;
}

export const AdminDataInfo: FC<
	React.PropsWithChildren<IAdminDataInfoProps>
> = ({ profile }) => {
	return (
		<RtUiNarrowForm hideButtons isInline>
			<RespOrgSelect displayMode initialOptionId={profile.respOrgId} />
			<InputFormControl
				label="Last Update"
				value={timestampToReadable(profile.lastUpdateTs)}
				displayMode
			/>
			<InputFormControl
				label="Status"
				value={profile.tfnStatusId ? tfnStatusToName(profile.tfnStatusId) : ''}
				displayMode
			/>
			<InputFormControl
				label="Last Working"
				value={timestampToReadable(profile.lastWorkingTs)}
				displayMode
			/>
			<InputFormControl
				label="Status Timestamp"
				value={timestampToReadable(profile.statusTs)}
				displayMode
			/>
			<hr />
			<InputFormControl
				label="Last Reserved"
				value={timestampToReadable(profile.lastReserveTs)}
				displayMode
			/>
			<InputFormControl
				label="Last Pending"
				value={timestampToReadable(profile.lastPendingTs)}
				displayMode
			/>
			<InputFormControl
				label="Last Spare"
				value={timestampToReadable(profile.lastSpareTs)}
				displayMode
			/>
			<InputFormControl
				label="Last Transitional"
				value={timestampToReadable(profile.lastTransitionalTs)}
				displayMode
			/>
			<InputFormControl
				label="Last Unavailable"
				value={timestampToReadable(profile.lastUnavailableTs)}
				displayMode
			/>
			{profile.lastWorkingRespOrgId && (
				<InputFormControl
					label="Last  Working RespOrg"
					value={profile.lastWorkingRespOrgId}
					displayMode
				/>
			)}
			<InputFormControl
				label="Last Working Days"
				value={
					profile.lastWorkingDays ? String(profile.lastWorkingDays) : 'N/A'
				}
				displayMode
			/>
			<InputFormControl
				label="Available Time"
				value={timestampToReadable(profile.availableTs)}
				displayMode
			/>
		</RtUiNarrowForm>
	);
};

AdminDataInfo.displayName = 'AdminDataInfo';
