import {
	LcrTaskResultIndexRequest,
	LcrTaskResultIndexResponse
} from 'RtModels';
import { LcrTaskResultRouter } from 'RtUi/app/rtLco/TaskResults/LcrTaskResult.router';
import { useGetLcrTasksResults } from 'RtUi/app/rtLco/Tasks/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLcrTasksResultsColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/lcrTasks';
import { useMemo } from 'react';

interface ILcrTaskResultsGridProps {
	resourceParams: LcrTaskResultIndexRequest;
}

export const LcrTaskResultsGrid = ({
	resourceParams
}: ILcrTaskResultsGridProps) => {
	const { data, isFetching: isLoading } = useGetLcrTasksResults(resourceParams);
	const columns = useMemo(() => getLcrTasksResultsColumns(), []);

	return (
		<DataGrid<LcrTaskResultIndexResponse>
			data={data?.data}
			columns={columns}
			router={LcrTaskResultRouter}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtLc_TaskResults'}
		/>
	);
};
