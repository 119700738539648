/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipTraceIndexResponse = {
    properties: {
        data: {
            type: 'Array',
            isRequired: true,
        },
        keys: {
            type: 'Array',
            isRequired: true,
        },
        total: {
            type: 'number',
            isRequired: true,
        },
    },
};