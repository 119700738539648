import { CprValuesInput } from 'RtUi/app/rt800/Cprs/lib/controls/CprValuesInput';
import { CprLabelValuesList } from 'RtUi/components/data/DataGrid/columns/CprLabelValuesDataGridColumn/components/CprLabelValuesList';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { noop } from 'lodash-es';

interface CprLabelValuesDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	displayMode: boolean;
	onUpdate?: () => void;
}

export const CprLabelValuesDataGridColumn = <T = any,>({
	displayMode,
	size = 500,
	maxSize = 800,
	onUpdate = noop,
	...config
}: CprLabelValuesDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: string[]) => {
		return <CprLabelValuesList value={value} shownLimit={25} />;
	};

	return DefaultDataGridColumn({
		...config,
		enableEditing: !displayMode,
		size,
		maxSize,
		exportValue: (value: string[]) => value.join(', '),
		getValue: ({ cell }) => getValue(cell.getValue<string[]>()),
		Edit: ({ row }) => (
			<CprValuesInput
				validator={row.original as any}
				onUpdate={onUpdate}
				shownLimit={25}
			/>
		)
	});
};
