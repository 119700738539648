/* cspell:ignore xmark */
import {
	RouteTrustValidationErrorLevel,
	SubscriptionProfileResponse
} from 'RtModels';
import { useGetSubscriptionValidation } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import { useEffect, useMemo, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';

interface ISubscriptionValidationProps {
	profile: SubscriptionProfileResponse;
}

interface AlertStyling {
	variant: Variant;
	icon: string;
}

const getStyling = (
	errorLevelId: RouteTrustValidationErrorLevel
): AlertStyling => {
	switch (errorLevelId) {
		case RouteTrustValidationErrorLevel.Notice: {
			return {
				variant: 'primary',
				icon: 'fa-info-circle'
			};
		}
		case RouteTrustValidationErrorLevel.Warning: {
			return {
				variant: 'warning',
				icon: 'fa-exclamation-triangle'
			};
		}
		case RouteTrustValidationErrorLevel.Error: {
			return {
				variant: 'danger',
				icon: 'fa-circle-xmark'
			};
		}
		case RouteTrustValidationErrorLevel.Critical: {
			return {
				variant: 'dark',
				icon: 'fa-circle-exclamation'
			};
		}
	}
};

export const SubscriptionValidation = ({
	profile
}: ISubscriptionValidationProps) => {
	const { data, isFetching, refetch } = useGetSubscriptionValidation({
		subscriptionId: profile.subscriptionId
	});
	const values = useMemo(() => data || [], [data]);
	const currentRef = useRef<SubscriptionProfileResponse>(profile);

	useEffect(() => {
		if (profile !== currentRef.current) {
			currentRef.current = profile;
			refetch();
		}
	}, [profile, refetch]);

	if (!values.length || isFetching) {
		return <></>;
	}

	return (
		<div className="d-flex flex-column gap-2 mt-4">
			{values.map((value, index) => {
				const styling = getStyling(value.errorLevelId);
				return (
					<Alert key={index} variant={styling.variant} className="m-0 p-2">
						<div className="d-flex gap-2 align-items-center">
							<i className={`fas fa-fw ${styling.icon}`} />
							<p className="m-0">{value.message}</p>
						</div>
					</Alert>
				);
			})}
		</div>
	);
};
