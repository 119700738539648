import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import {
	ITollFreeStatusIndex,
	TollFreeStatusResource
} from '../resources/TollFreeStatusResource';

interface ITollFreeStatusSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<ITollFreeStatusIndex, isMulti> {
	hidePending?: boolean;
}

export class TollFreeStatusSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	ITollFreeStatusIndex,
	isMulti,
	ITollFreeStatusSelectProps<isMulti>
> {
	public resourceClass = TollFreeStatusResource;
	public state: ISelectFormControlState<ITollFreeStatusIndex> = {
		formLabel: 'Toll-Free Status',
		valueKey: 'tfnStatusId'
	};

	public optionRenderer = (TollFreeStatus: ITollFreeStatusIndex) => (
		<span>{TollFreeStatus.label}</span>
	);

	/**
	 * @override
	 * @param option
	 * @param rawInput
	 */
	public filterOption(
		option: FilterOptionOption<ITollFreeStatusIndex>,
		rawInput: string
	) {
		if (this.props.hidePending) {
			if (option.value === 'P') {
				return false;
			}
		}

		return super.filterOption(option, rawInput);
	}
}
