/* eslint-disable camelcase */
import { ColumnOrderState, VisibilityState } from '@tanstack/react-table';
import {
	DataGridColumn,
	DataGridColumnDef,
	DataGridColumnInstance,
	FooterProps,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import { SpreadsheetWebWorkerFacade } from 'RtUi/workers/lib/spreadsheet/SpreadsheetWebWorkerFacade';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { cloneDeep, isNil, sumBy } from 'lodash-es';
import { MRT_Row } from 'material-react-table';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

export const sumColumnTotal = <T = any>(
	props: FooterProps<T>,
	accessorFn: (row: MRT_Row<Record<keyof T, T[keyof T]>>) => number
) => {
	const { table } = props;
	const currentData = table.getPrePaginationRowModel().rows;

	if (!currentData.length) {
		return null;
	}

	const total = sumBy(currentData, accessorFn);

	if (isNil(total)) {
		return 0;
	}

	return total;
};

export const getColumnValue = <T>(data: T, column: DataGridColumn<T>) => {
	if (column.accessorKey) {
		return data[column.accessorKey];
	}

	return column.accessorFn && column.accessorFn(data);
};

const filterHiddenColumns = <T = any>(
	columns: Array<DataGridColumnInstance<T>>
) => {
	return columns.reduce<Array<DataGridColumnInstance<T>>>((dest, column) => {
		const { columns: subColumns, ...parentColumn } = column;

		const displayParent = column.getIsVisible();

		if (!displayParent) {
			return dest;
		}

		if (!subColumns?.length) {
			dest.push(parentColumn);
			return dest;
		}

		const displaySubColumns = subColumns.filter((subColumn) =>
			subColumn.getIsVisible()
		);

		if (!displaySubColumns.length) {
			return dest;
		}

		dest.push({
			columns: displaySubColumns,
			...parentColumn
		});

		return dest;
	}, []);
};

const sortColumns = <T = any>(
	columns: Array<DataGridColumnInstance<T>>,
	order: string[]
) => {
	return columns
		.reduce<Array<DataGridColumnInstance<T>>>((dest, column) => {
			const { columns: subColumns, ...parentColumn } = column;

			if (!subColumns?.length) {
				dest.push(parentColumn);
				return dest;
			}

			dest = dest.concat(
				subColumns.map((subColumn) => {
					const newColumn = cloneDeep(subColumn);
					newColumn.columnDef.header = `${parentColumn.columnDef.header} ${subColumn.columnDef.header}`;

					return newColumn;
				})
			);

			return dest;
		}, [])
		.sort((prev, current) => {
			const prevId = prev.parent?.id ?? prev.id;
			const currId = current.parent?.id ?? current.id;

			return order.indexOf(prevId) - order.indexOf(currId);
		});
};

export const getExportColumns = <T = any>(
	columns: Array<DataGridColumnInstance<T>>,
	columnOrder: string[]
) => {
	const displayedColumns = filterHiddenColumns(columns);

	return sortColumns(displayedColumns, columnOrder);
};

const getClipboardElement = (text: string) => {
	const fakeElementId = 'fakeElementClipboard';
	let fakeElem = document.getElementById(
		fakeElementId
	) as HTMLTextAreaElement | null;

	if (!fakeElem) {
		const isRTL = document.documentElement
			? document.documentElement.getAttribute('dir') === 'rtl'
			: false;
		fakeElem = document.createElement('textarea');
		fakeElem.id = fakeElementId;

		// Prevent zooming on iOS
		fakeElem.style.fontSize = '12pt';
		// Reset box model
		fakeElem.style.border = '0';
		fakeElem.style.padding = '0';
		fakeElem.style.margin = '0';
		// Move element out of screen horizontally
		fakeElem.style.position = 'absolute';
		fakeElem.style[isRTL ? 'right' : 'left'] = '-9999px';
		// Move element to the same position vertically
		const yPosition =
			window.pageYOffset ||
			(document.documentElement ? document.documentElement.scrollTop : 0);
		fakeElem.style.top = `${yPosition}px`;

		fakeElem.setAttribute('readonly', '');

		document.body.appendChild(fakeElem);
	}

	fakeElem.value = text;

	return fakeElem;
};

const copyTextToClipboard = (text: string) => {
	const fakeElem = getClipboardElement(text);

	fakeElem.select();

	document.execCommand('copy');
};

const dangerouslyCopyDataToClipboardUsingMainThread = (data: any[]) => {
	let csvStr = '';
	const headers = Object.keys(data[0]);
	const addCell = (str: string) => (csvStr += `${str}\t`);
	const addNewLine = () => (csvStr += '\n');

	for (const header of headers) {
		addCell(header);
	}

	addNewLine();

	for (const row of data) {
		for (const header of headers) {
			addCell(row[header]);
		}

		addNewLine();
	}

	copyTextToClipboard(csvStr);
};

export const copyDataToClipboard = async <T = any>(
	data: T[],
	columns: Array<DataGridColumnInstance<T>>
) => {
	const value = getExportValue(data, columns);
	const workerFacade = SpreadsheetWebWorkerFacade.getInstance();
	const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

	if (isFirefox) {
		await dangerouslyCopyDataToClipboardUsingMainThread(value);
	}

	await workerFacade.dataToCSVString(value, '\t').then((csvStr) => {
		copyTextToClipboard(csvStr);
	});
};

export const getExportValue = <T>(
	data: T[],
	columns: Array<DataGridColumnInstance<T>>
) => {
	return data.reduce<any[]>((dest, d) => {
		const result: Map<string, any> = new Map();
		for (const column of columns) {
			const columnDef = column.columnDef as DataGridColumn<T>;

			const value = columnDef.exportValue
				? columnDef.exportValue(getColumnValue(d, columnDef), d)
				: getColumnValue(d, columnDef);
			result.set(columnDef.header, value);
		}

		if (result.size) {
			dest.push(Object.fromEntries(result));
		}

		return dest;
	}, []);
};

const getExportHeaders = <T = any>(
	columns: Array<DataGridColumnInstance<T>>
) => {
	return columns.reduce<string[]>((dest, column) => {
		const { columns = [], ...parentColumn } = column;

		const columnDef = parentColumn.columnDef as DataGridColumn<T>;
		if (!columns.length) {
			dest.push(columnDef.header);
			return dest;
		}

		const subColumns = columns.map(
			(c) => `${columnDef.header} ${c.columnDef.header}`
		);

		dest = dest.concat(subColumns);
		return dest;
	}, []);
};

export const exportToCsv = <T = any>(
	data: T[],
	columns: Array<DataGridColumnInstance<T>>
) => {
	const csvOptions = mkConfig({
		fieldSeparator: ',',
		quoteStrings: true,
		quoteCharacter: '"',
		decimalSeparator: '.',
		useBom: true,
		useKeysAsHeaders: false,
		columnHeaders: getExportHeaders(columns)
	});

	const csv = generateCsv(csvOptions)(getExportValue(data, columns));

	download(csvOptions)(csv);
};

const createExcelWorkSheet = <T = any>(
	data: T[],
	columns: Array<DataGridColumnInstance<T>>
): XLSX.WorkBook => {
	const value = getExportValue(data, columns);
	const exportFormats = columns.reduce<Array<[number, string]>>(
		(dest, { columnDef }, index) => {
			const { exportDateFormat: exportFormat } =
				columnDef as DataGridColumnDef<T>;

			if (exportFormat) {
				const format =
					exportFormat instanceof Function ? exportFormat(data) : exportFormat;
				dest.push([index, format]);
			}

			return dest;
		},
		[]
	);

	const workbook = XLSX.utils.book_new();
	const exportAsUtc = columns.some(
		(c) => (c.columnDef as DataGridColumnDef<T>).exportDateAsUtc
	);
	const sheet = XLSX.utils.json_to_sheet(value, { UTC: exportAsUtc });

	if (!exportFormats) {
		XLSX.utils.book_append_sheet(workbook, sheet, 'Exported Data');
		return workbook;
	}

	for (const formatConfig of exportFormats) {
		const [key, format] = formatConfig;
		const columnLetterIndex = String.fromCharCode(key + 65);
		const indexes = Object.keys(sheet).filter(
			(key) => key.startsWith(columnLetterIndex) && sheet[key].t !== 's'
		);

		for (const index of indexes) {
			sheet[index].z = format;
		}
	}
	XLSX.utils.book_append_sheet(workbook, sheet, 'Exported Data');

	return workbook;
};

export const exportToExcel = async <T = any>(
	data: T[],
	columns: Array<DataGridColumnInstance<T>>
) => {
	const workbook = createExcelWorkSheet(data, columns);
	const spreadsheetStr = XLSX.write(workbook, { type: 'buffer' });

	const blob = new Blob([spreadsheetStr], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	});

	FileSaver.saveAs(blob, 'download.xlsx');
};

export const mapInitialColumnOrder = <T>(
	currentState: ColumnOrderState,
	displayedColumns: Array<DataGridColumn<T>>
) => {
	const newColumns = displayedColumns.reduce<string[]>((dest, column) => {
		const { id, accessorKey, header, columns } = column;

		const baseId = id ?? accessorKey ?? header;
		dest.push(baseId as string);

		if (columns) {
			const childColumns = columns.map(
				(c) => (c.id ?? c.accessorKey ?? c.header) as string
			);

			dest = dest.concat(childColumns);
		}

		return dest;
	}, []);

	currentState.forEach((column, index) => {
		if (column.startsWith('mrt-')) {
			newColumns.splice(Number(index), 0, column);
		}
	});

	return newColumns;
};

export const mapColumnOrder = <T>(
	currentState: ColumnOrderState,
	displayedColumns: Array<DataGridColumn<T>>,
	configuration: ColumnOrderState = []
) => {
	if (configuration.length === 0) {
		return mapInitialColumnOrder(currentState, displayedColumns);
	}

	const newColumns = [...configuration];

	currentState.forEach((column, index) => {
		if (column.startsWith('mrt-')) {
			newColumns.splice(Number(index), 0, column);
		}
	});

	return newColumns;
};

const isEmptyColumn = <T>(data: T[], column: DataGridColumn<T>) => {
	return data.every((row) => isNil(getColumnValue(row, column)));
};

const hideColumn = <T>(
	data: T[],
	column: DataGridColumnDef<T>
): VisibilityState => {
	const result: VisibilityState = {};
	const key = (column.accessorKey as string) ?? column.header;
	const isEmpty = column.hiddenIfEmpty && isEmptyColumn(data, column);

	if (!isEmpty && !column.isHidden) {
		return result;
	}

	result[key] = false;

	return result;
};

export const getColumnsVisibility = <T>(
	data: T[],
	columns: Array<DataGridColumn<T>>,
	currentVisibilityState?: VisibilityState
): VisibilityState => {
	if (currentVisibilityState) {
		return currentVisibilityState;
	}

	return columns.reduce<VisibilityState>(
		(dest, column) => {
			const { columns = [], ...parentColumn } = column;

			const hideParentColumn = hideColumn(data, parentColumn);

			if (hideParentColumn) {
				dest = Object.assign(dest, hideParentColumn);
			}

			if (!columns.length) {
				return dest;
			}

			for (const childColumn of columns) {
				const hideChildColumn = hideColumn(data, childColumn);
				dest = Object.assign(dest, hideChildColumn);
			}

			return dest;
		},
		{
			'mrt-row-expand': false
		}
	);
};

export const getThemeColor = (
	theme: RowThemeColor | undefined
): string | undefined => {
	switch (theme) {
		case RowThemeColor.PRIMARY: {
			return '#d6d2f8';
		}
		case RowThemeColor.SECONDARY: {
			return '#ebedef';
		}
		case RowThemeColor.SUCCESS: {
			return '#d5f1de';
		}
		case RowThemeColor.DANGER: {
			return '#f8d7da';
		}
		case RowThemeColor.WARNING: {
			return '#fdeed3';
		}
		case RowThemeColor.INFO: {
			return '#d6eaf8';
		}
		case RowThemeColor.LIGHT: {
			return '#ebedef';
		}
		case RowThemeColor.DARK: {
			return '#4f5d73';
		}
		default: {
			return undefined;
		}
	}
};
