/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum GuardianReconciliationUsageDialCodeSummaryIndexGrouping {
    DIALCODE = 'dialCode',
    STATE = 'state',
    ISO = 'iso',
}