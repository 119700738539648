import {
	ManagedEntityIndexResponse,
	ManagedEntityProfileResponse
} from 'RtModels';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RtAdminRouter } from '../rtAdmin.router';
import { RtAdmApiRoutes } from 'RtExports/routes';

interface IManagedEntityContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Credentials: TabbedLayoutTabProps;
}

export const ManagedEntityContainerTabs: IManagedEntityContainerTabs = {
	Profile: {
		header: 'Profile'
	},
	Credentials: {
		header: 'Credentials'
	}
};

export class ManagedEntityRouterClass extends RtAdminRouter<
	ManagedEntityIndexResponse,
	ManagedEntityProfileResponse,
	IManagedEntityContainerTabs
> {
	constructor() {
		super(
			'Managed Entity',
			'managedEntities',
			'entityId',
			ManagedEntityContainerTabs
		);

		this.setPermissionsFromApiRoute(RtAdmApiRoutes.ManagedEntities);
	}

	public getProfileLabel(profile: ManagedEntityProfileResponse) {
		return profile.entityId;
	}
}

export const ManagedEntityRouter = new ManagedEntityRouterClass();
