import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { SubscriptionResource } from './lib/resources/SubscriptionsResource';
import {
	CallflowService,
	SubscriptionIndexResponse,
	SubscriptionProfileResponse
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	IRouterBreadcrumb,
	SearchConfidenceLevel
} from 'RtUi/components/containers/lib/RtUiRouter';
import { AccountManagementRouter } from 'RtUi/app/AccountManagement/AccountManagement.router';
import { fetchServiceType } from 'RtUi/app/rtSip/Services/lib/services';

interface ISubscriptionContainerTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
	Billing: TabbedLayoutTabProps;
	Routing: TabbedLayoutTabProps;
	Qos: TabbedLayoutTabProps;
	Nanp: TabbedLayoutTabProps;
	Rates: TabbedLayoutTabProps;
	Schedule: TabbedLayoutTabProps;
	Tags: TabbedLayoutTabProps;
	Connections: TabbedLayoutTabProps;
	CdrExports: TabbedLayoutTabProps;
	VueConnections: TabbedLayoutTabProps;
	NumberPeering: TabbedLayoutTabProps;
}

export const getSubscriptionContainerTabs = (
	serviceId?: number
): ISubscriptionContainerTabs => ({
	Profile: { header: 'Profile' },
	Billing: { header: 'Billing' },
	Routing: { header: 'Routing' },
	Qos: { header: 'QOS Settings' },
	Nanp: { header: 'NANP Settings' },
	Rates: { header: 'Rates' },
	Schedule: {
		header: 'Schedule',
		isHidden:
			serviceId === undefined ||
			![CallflowService.OutboundCustomerIdentification].includes(serviceId)
	},
	Tags: {
		header: 'Tags',
		isHidden:
			serviceId === undefined ||
			![
				CallflowService.InboundVendorOrigination,
				CallflowService.InboundCustomerIdentification,
				CallflowService.OutboundCustomerIdentification
			].includes(serviceId)
	},
	Connections: {
		header: 'SIP Connections',
		isHidden:
			serviceId === undefined ||
			[
				CallflowService.InboundCustomerIdentification,
				CallflowService.OutboundCustomerIdentification
			].includes(serviceId)
	},
	CdrExports: { header: 'CDR Exports' },
	VueConnections: { header: 'Vue Connections' },
	NumberPeering: { header: 'Number Peering' }
});

class SubscriptionRouterClass extends AccountManagementRouter<
	SubscriptionIndexResponse,
	SubscriptionProfileResponse,
	ISubscriptionContainerTabs
> {
	constructor() {
		super(
			'Subscription',
			'subscriptions',
			'subscriptionId',
			getSubscriptionContainerTabs()
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.Subscriptions);
	}

	public async getBreadcrumbs(
		currentPath: string,
		profile?: SubscriptionProfileResponse
	): Promise<IRouterBreadcrumb[]> {
		const breadcrumbs = await super.getBreadcrumbs(currentPath, profile);
		const result = [this.getSectionBreadcrumb(), ...breadcrumbs];

		if (!profile?.serviceId) {
			return result;
		}

		try {
			const serviceType = await fetchServiceType({
				serviceId: profile.serviceId
			});

			const breadcrumb = result.find((b) =>
				b.path.includes(
					`/accountManagement/subscriptions/${profile.subscriptionId}`
				)
			);

			if (breadcrumb) {
				breadcrumb.name = `${breadcrumb.name} - ${serviceType.label}`;
			}
		} catch (e) {}

		return result;
	}

	public globalSearchConfidenceCheck() {
		return SearchConfidenceLevel.Possible;
	}

	public globalSearch(search: string) {
		return this.defaultGlobalSearch(SubscriptionResource, search);
	}

	public getProfileLabel(profile: SubscriptionProfileResponse) {
		return `${profile.label} (${profile.subscriptionId})`;
	}

	public getIndexLabel(record: SubscriptionIndexResponse): string {
		return record.label;
	}

	public recordHasAccessToProfile(record: any): boolean {
		const parentAccess = super.recordHasAccessToProfile(record);

		return parentAccess || (record && 'sipConnectionId' in record);
	}

	public getProfileTabs(
		profile?: SubscriptionProfileResponse
	): ISubscriptionContainerTabs {
		return getSubscriptionContainerTabs(profile?.serviceId);
	}
}

export const SubscriptionRouter = new SubscriptionRouterClass();
