import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { LcrTasksResource } from 'RtUi/app/rtLco/Tasks/lib/resources/LcrTasksResource';
import { LcrTaskIndexResponse } from 'RtModels';
import { timestampToReadable } from 'RtUi/utils/maps';
import { Badge } from 'react-bootstrap';
import { LcrTaskRouter } from 'RtUi/app/rtLco/Tasks/LcrTask.router';

interface ILcoTaskSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<LcrTaskIndexResponse, isMulti> {
	templateName?: string;
	hideIncomplete?: boolean;
}

export class LcoTaskSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	LcrTaskIndexResponse,
	isMulti,
	ILcoTaskSelectProps<isMulti>
> {
	public static defaultProps = {
		router: LcrTaskRouter
	};
	public resourceClass = LcrTasksResource;
	public state: ISelectFormControlState<LcrTaskIndexResponse> = {
		formLabel: 'LCO Task',
		valueKey: 'lcrTaskId',
		labelKey: 'templateName'
	};

	constructor(props: ILcoTaskSelectProps<isMulti>) {
		super(props);

		if (props.templateName) {
			this.setGetAllParams({ templateName: props.templateName });
		}
	}

	public optionRenderer = (record: LcrTaskIndexResponse) => (
		<span className="d-flex justify-content-start align-items-center">
			<span className="me-2">{record.lcrTaskId}</span>
			<Badge bg="light" text="dark" className="me-2">
				{timestampToReadable(record.completedAt)}
			</Badge>
			<Badge bg="light" text="dark">
				{record.templateName}
			</Badge>
		</span>
	);

	/**
	 * @override
	 */
	public async getData() {
		let records = await super.getData();

		if (this.props.hideIncomplete) {
			records = records.filter((record) => Boolean(record.completedAt));
		}

		return records;
	}
}
