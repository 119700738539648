import {
	CdrExportDestinationCreateRequest,
	CdrExportDestinationIndexResponse,
	DestinationTypeId
} from 'RtModels';
import { CdrDestinationTypeSelect } from 'RtUi/app/rtVue/CdrRecurringExport/lib/controls/CdrDestinationTypeSelect';
import {
	postCdrDestinationTypeSelect,
	updateCdrDestinationTypeSelect
} from 'RtUi/app/rtVue/CdrRecurringExport/lib/services';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { JSONEditor } from 'RtUi/components/ui/JsonEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { IDefaultSelectOption } from 'RtUi/components/ui/SelectFormControl';
import { RtError } from 'RtUi/utils/errors/RtError';
import { noop } from 'lodash-es';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

interface ICdrAutomatedTransferFormProps {
	cdrExportControlId: number;
	profile?: CdrExportDestinationIndexResponse;
	onSubmit?: () => void;
	onCancel?: () => void;
}

type CdrAutomatedTransferFormData =
	| CdrExportDestinationCreateRequest
	| CdrExportDestinationIndexResponse;

export const CdrAutomatedTransferForm = ({
	cdrExportControlId,
	profile,
	onSubmit = noop,
	onCancel
}: ICdrAutomatedTransferFormProps) => {
	const [error, setError] = useState<RtError>();
	const [cdrDestinationType, setCdrDestinationType] =
		useState<IDefaultSelectOption<DestinationTypeId>>();
	const {
		control,
		handleSubmit,
		formState: { isSubmitting }
	} = useForm<CdrAutomatedTransferFormData>({
		defaultValues: {
			cdrExportId: cdrExportControlId,
			...profile
		}
	});

	const { mutateAsync: create } = useMutation(postCdrDestinationTypeSelect);
	const { mutateAsync: update } = useMutation(updateCdrDestinationTypeSelect);

	const onSubmitHandler = async (data: CdrAutomatedTransferFormData) => {
		setError(undefined);
		try {
			if (profile) {
				const { cdrExportDestinationId, ...body } =
					data as CdrExportDestinationIndexResponse;
				await update({ urlParams: { cdrExportDestinationId }, body });
			} else {
				await create(data as CdrExportDestinationCreateRequest);
			}

			onSubmit();
		} catch (e: any) {
			setError(new RtError(e));
		}
	};

	return (
		<RtUiForm
			onSubmit={handleSubmit(onSubmitHandler)}
			isSubmitting={isSubmitting}
			error={error}
			onCancel={onCancel}
		>
			<Controller
				control={control}
				name="destinationTypeId"
				render={({ field: { onChange, value } }) => (
					<CdrDestinationTypeSelect
						required
						value={cdrDestinationType}
						onChange={(newVal) => {
							setCdrDestinationType(newVal);
							onChange(newVal.value);
						}}
						initialOptionId={value}
					/>
				)}
			/>
			<Controller
				control={control}
				name="credentials"
				render={({ field: { onChange, value } }) => (
					<JSONEditor
						label="Credentials (JSON blob)"
						value={value}
						onChange={onChange}
						required
					/>
				)}
			/>
			<Controller
				control={control}
				name="remotePath"
				render={({ field: { onChange, value } }) => (
					<InputFormControl
						label="Remote Path"
						value={value ?? ''}
						onChange={onChange}
					/>
				)}
			/>
		</RtUiForm>
	);
};
