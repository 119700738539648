import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ReleaseCodeIndexResponse } from 'RtModels';
import { ReleaseCodeResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/ReleaseCodeResource';

interface IReleaseCodeSelectProps
	extends ISelectFormControlProps<ReleaseCodeIndexResponse> {
	valueKey?: keyof ReleaseCodeIndexResponse;
	allowReleaseIds?: number[];
}

export class ReleaseCodeSelect extends SelectFormControl<
	ReleaseCodeIndexResponse,
	false,
	IReleaseCodeSelectProps
> {
	public resourceClass = ReleaseCodeResource;
	public state: ISelectFormControlState<ReleaseCodeIndexResponse> = {
		formLabel: '',
		valueKey: 'releaseCodeId',
		labelKey: 'label',
		placeholder: 'Leave blank for none'
	};

	public async getData() {
		const data = (await super.getData()) as any[];
		const { allowReleaseIds } = this.props;

		if (allowReleaseIds && allowReleaseIds.length > 0) {
			return data.filter((record) =>
				allowReleaseIds.includes(record.releaseCodeId)
			);
		}

		return data;
	}
}
