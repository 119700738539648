/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ExtraFileStreamParams = {
    properties: {
        useTrunkGroupLabel: {
            type: 'boolean',
        },
        addOffnetTrunkGroups: {
            type: 'boolean',
        },
        replaceQuotes: {
            type: 'boolean',
        },
        versionNumber: {
            type: 'string',
        },
    },
};