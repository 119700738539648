import * as React from 'react';
import {
	VendorReconciliationIndexResponseData,
	Reports,
	TimeRangePresets,
	TimeFilterDetailKeys,
	ReportAggregates,
	DataFilter
} from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { ReconciliationRouter } from 'RtUi/app/rtVue/Reconciliation/Reconciliation.router';
import { ReconciliationDataGrid } from 'RtUi/app/rtVue/Reconciliation/lib/grids/ReconciliationDataGrid';
import { endOfYesterday, startOfYesterday } from 'date-fns';
import { TimeFilterDetailLevelsHours } from 'RtUi/app/rtVue/common/lib/components/DateTimeFilterRange/utilities';
import { capitalize } from 'lodash-es';

const getSourceTargetDirection = (
	filters: DataFilter[],
	keyToCheck: string,
	isIngress: boolean
) =>
	filters.map((f) =>
		f.key === keyToCheck
			? { ...f, key: `${isIngress ? 'ingress' : 'egress'}${capitalize(f.key)}` }
			: f
	);
@ReconciliationRouter.getReconciliationIndexRtUiController({
	orderPriority: 4
})
export class ReconciliationIndexContainer extends RtVueReportContainer<VendorReconciliationIndexResponseData> {
	public state: IRtVueContainerState<VendorReconciliationIndexResponseData> = {
		...this.getBaseState({
			defaultTimeRangePreset: TimeRangePresets.ThirtyDays,
			timeObject: {
				mode: TimeRangePresets.Custom,
				start: startOfYesterday(),
				end: endOfYesterday()
			}
		}),
		dataSums: [ReportAggregates.Margin],
		timeRangePresetsKeys: [TimeRangePresets.Custom],
		timeRangeDetailsLevels: TimeFilterDetailLevelsHours.filter(
			(time) => time.key === TimeFilterDetailKeys.Days
		),
		noCustomFocusOnInit: true,
		hidePartitionSelect: true,
		hideFileStreamSelect: true,
		showTwoFileStreamSelects: true,
		showTrunkGroupIngressEgressOptions: true
	};

	protected reportId = Reports.Reconciliation;
	protected router = ReconciliationRouter;

	protected async getResource(): Promise<
		HttpResource<VendorReconciliationIndexResponseData>
	> {
		const {
			timeRange,
			partition,
			preset,
			dataSums,
			additionalColumns,
			isSourceIngress,
			isTargetIngress
		} = this.state;

		const filters = getSourceTargetDirection(
			getSourceTargetDirection(
				this.state.filters,
				'trunkGroup1',
				isSourceIngress === 1
			),
			'trunkGroup2',
			isTargetIngress === 1
		);

		const vueHttp = new RtVueHttp();
		const {
			data: { data },
			totalCount
		} = await vueHttp.getReconciliationView(
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			additionalColumns?.map((column) => column.value)
		);

		const resource = new ArrayResource<VendorReconciliationIndexResponseData>(
			'callDate',
			data
		);
		resource.setTotalCount(totalCount);

		return resource;
	}

	protected renderReports(): React.ReactNode {
		return (
			<ReconciliationDataGrid
				isLoading={this.state.loadingReport}
				defaultSorting={this.state.defaultSorting}
				onSortingChange={this.onSortedChange}
				resource={this.state.resource}
			/>
		);
	}
}
