import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import {
	GuardianConnectionSubscriptionCountIndexRequest,
	GuardianConnectionSubscriptionCreateRequest,
	GuardianConnectionSubscriptionIndexResponse,
	GuardianConnectionSubscriptionProfileResponse,
	GuardianConnectionSubscriptionProfileUrlRequest,
	GuardianConnectionSubscriptionUpdateRequest
} from 'RtModels';
import { RtVueApiRoutes } from 'RtExports/routes';

export class ConnectionSubscriptionResource extends HttpResource<
	GuardianConnectionSubscriptionIndexResponse,
	GuardianConnectionSubscriptionProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianConnectionSubscriptions);
	}

	public create(request: GuardianConnectionSubscriptionCreateRequest) {
		const body = JSON.stringify(request);
		return this.fetchWithRoute<GuardianConnectionSubscriptionProfileResponse>(
			RtVueApiRoutes.GuardianConnectionSubscriptions.Create,
			{ body }
		);
	}

	public update(
		guardianConnectionSubscriptionId: number,
		request: GuardianConnectionSubscriptionUpdateRequest
	) {
		const urlParams: GuardianConnectionSubscriptionProfileUrlRequest = {
			guardianConnectionSubscriptionId
		};
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianConnectionSubscriptionProfileResponse>(
			RtVueApiRoutes.GuardianConnectionSubscriptions.Update,
			{ body, urlParams }
		);
	}

	public getConnectionSubsCount(
		params: GuardianConnectionSubscriptionCountIndexRequest
	) {
		return this.fetchWithRoute<number>(
			RtVueApiRoutes.GuardianConnectionSubscriptionsCount.Index,
			{ params }
		);
	}
}
