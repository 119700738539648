import { RtUiModule } from 'RtUi/app/@RtUi/RtUiDecorators';
import { Rt800Module } from './rt800/rt800.module';
import { RtAdminModule } from './rtAdmin/rtAdmin.module';
import { RtSipModule } from './rtSip/rtSip.module';
import { RtUserModule } from './user/user.module';
import { RtLcoModule } from 'RtUi/app/rtLco/rtLco.module';
import { AdministrationModule } from 'RtUi/app/Administration/Administration.module';
import { RtDidModule } from 'RtUi/app/rtDid/rtDid.module';
import { RtCarrierConnectModule } from 'RtUi/app/rtCarrierConnect/RtCarrierConnect.module';
import { RtVueModule } from 'RtUi/app/rtVue/RtVue.module';
import { RtGuardianModule } from 'RtUi/app/rtGuardian/rtGuardian.module';
import { AccountManagementModule } from 'RtUi/app/AccountManagement/AccountManagement.module';
import { RtLcrModule } from 'RtUi/app/rtLcr/rtLcr.module';
import { RtCommonDashboardModule } from 'RtUi/app/rtCommon/Dashboard/Dashboard.module';
import { RtCommonDashboardOldModule } from 'RtUi/app/rtCommon/DashboardOld/DashboardOld.module';

@RtUiModule({
	imports: [
		RtCommonDashboardModule,
		RtCommonDashboardOldModule,
		AccountManagementModule,
		Rt800Module,
		RtCarrierConnectModule,
		RtLcoModule,
		RtSipModule,
		RtLcrModule,
		RtDidModule,
		AdministrationModule,
		RtUserModule,
		RtVueModule,
		RtGuardianModule,
		RtAdminModule
	]
})
export class RtUiAppModule {}
