/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NanpStateIndexResponse = {
    properties: {
        nanpStateId: {
            type: 'string',
            isRequired: true,
        },
        nanpRegionId: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
    },
};