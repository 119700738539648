/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TaskTypeIndexResponse = {
    properties: {
        taskTypeId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        hasNumbers: {
            type: 'number',
            isRequired: true,
        },
        hasActivationTs: {
            type: 'number',
            isRequired: true,
        },
    },
};