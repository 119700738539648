import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { Badge, Button } from 'react-bootstrap';
import { LcrTaskResultsResource } from 'RtUi/app/rtLco/TaskResults/lib/resources/LcrTaskResultsResource';
import { FormatOptionLabelContext } from 'react-select/dist/declarations/src/Select';
import { LcrTaskResultIndexResponse } from 'RtModels';
import { LcrTaskResultRouter } from 'RtUi/app/rtLco/TaskResults/LcrTaskResult.router';
import { ScenarioRenderCache } from 'RtUi/components/data/DataGrid/columns/ScenarioDataGridColumn';
import { GenericTypeRender } from 'RtUi/components/ui/GenericTypeRenderer';

interface ILcrTaskResultSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<LcrTaskResultIndexResponse, isMulti> {
	lcrTaskId: number;
}

export class LcrTaskResultSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	LcrTaskResultIndexResponse,
	isMulti,
	ILcrTaskResultSelectProps<isMulti>
> {
	public static defaultProps = {
		router: LcrTaskResultRouter
	};
	public resourceClass = undefined;
	public state: ISelectFormControlState<LcrTaskResultIndexResponse> = {
		formLabel: 'LCO Task Result',
		valueKey: 'lcrTaskResultId',
		labelKey: 'lcrTaskResultId'
	};

	constructor(props: ILcrTaskResultSelectProps<isMulti>) {
		super(props);

		this.resource = new LcrTaskResultsResource(props.lcrTaskId);
		ScenarioRenderCache.warmup();
	}

	public optionRenderer = (
		record: LcrTaskResultIndexResponse,
		context: FormatOptionLabelContext
	) => {
		const lcrScenarioRender = (
			<span className="d-flex justify-content-start">
				<span className="me-2">{record.lcrTaskResultId}</span>
				<GenericTypeRender
					id={record.lcrScenarioId}
					resourceCacheMap={ScenarioRenderCache}
				/>
			</span>
		);

		if (context === 'value') {
			return lcrScenarioRender;
		}

		return (
			<section>
				<header className="mb-2">{lcrScenarioRender}</header>
				<section className="d-flex justify-content-start align-items-center">
					<Button type="button" size="sm" variant="light" className="me-2">
						<span>CIC&nbsp;</span>
						<Badge bg="white" text="dark">
							{record.defaultCic}
						</Badge>
					</Button>
					<Button type="button" size="sm" variant="light" className="me-2">
						<span>Charges&nbsp;</span>
						<Badge bg="white" text="dark">
							{record.charges.toLocaleString()}
						</Badge>
					</Button>
				</section>
			</section>
		);
	};
}
