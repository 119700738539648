import { configureStore } from '@reduxjs/toolkit';
import DataGridReducer from './reducers/DataGridReducers';
import ReportRoutesReducer from 'RtUi/store/reducers/ReportRoutesReducer';

const store = configureStore({
	reducer: {
		dataGrid: DataGridReducer,
		reportRoutes: ReportRoutesReducer
	}
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
