import { RtxSipApiRoutes } from 'RtExports/routes';
import { PromptIndexRequest, PromptIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchPromptSegment = async (queryParams?: PromptIndexRequest) => {
	return handleGetRequest<PromptIndexResponse[], true>(
		RtxSipApiRoutes.Prompts.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetPromptSegment = (params?: PromptIndexRequest) => {
	return useQuery<FullResponse<PromptIndexResponse[]>, Error>(
		['getPromptSegment', params],
		() => fetchPromptSegment(params)
	);
};
