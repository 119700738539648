import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianFraudSimulatorResponse,
	GuardianFraudSimulatorProfileRequest
} from 'RtModels';
import { GuardianApplicationRouter } from 'RtUi/app/rtGuardian/rtGuardian.router';

class FraudSimulatorRouterClass extends GuardianApplicationRouter<
	GuardianFraudSimulatorResponse,
	GuardianFraudSimulatorProfileRequest,
	{}
> {
	constructor() {
		super('Fraud Simulator', 'fraudSimulator', 'score', {});

		this.setPermissionsFromApiRoute(RtVueApiRoutes.GuardianFraudSimulator);
	}

	public getProfileLabel(profile: GuardianFraudSimulatorProfileRequest) {
		return '';
	}

	/**
	 * @override
	 */
	public getPluralName() {
		return this.getName();
	}
}

export const FraudSimulatorRouter = new FraudSimulatorRouterClass();
