import { cloneDeep } from 'lodash-es';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { UserIndexResponse } from 'RtModels';
import { MyProfileHttp } from 'RtUi/app/user/MyProfile/lib/Http/MyProfileHttp';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';

interface IMyProfileFormProps {
	user: UserIndexResponse;
	onUpdate: (updatedUser: UserIndexResponse) => void;
}

interface IMyProfileFormState {
	user: UserIndexResponse | null;
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
}

export class MyProfileForm extends React.Component<
	IMyProfileFormProps,
	IMyProfileFormState
> {
	public myProfileHttp = new MyProfileHttp();

	public state: IMyProfileFormState = {
		user: null,
		displayMode: true,
		isSubmitting: false,
		error: undefined
	};

	public componentDidMount() {
		const user = cloneDeep(this.props.user);

		this.setState({ user });
	}

	public async updateUser(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const { user } = this.state;

		if (!user) {
			return;
		}

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const { smsPhone = '', mobilePhone = '', officePhone = '' } = user;
			const newUser = await this.myProfileHttp.updateProfile(
				smsPhone,
				mobilePhone,
				officePhone
			);

			this.setState({ user: newUser, displayMode: true });

			this.props.onUpdate(newUser);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public updateUserProp<K extends keyof UserIndexResponse>(
		prop: K,
		value: UserIndexResponse[K],
		onlyDigits = false
	) {
		const { user } = this.state;

		if (!user) {
			return;
		}

		if (onlyDigits && typeof value === 'string') {
			value = value.replace(/\D/g, '') as any;
		}

		user[prop] = value;

		this.setState({ user });
	}

	public onUpdateCancel() {
		const resetUser = cloneDeep(this.props.user);

		this.setState({ user: resetUser, error: undefined });
	}

	public render() {
		const { user } = this.state;

		if (user === null) {
			return <Loading />;
		}

		return (
			<RtUiWideForm
				error={this.state.error}
				onCancel={() => this.onUpdateCancel()}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				onChange={(displayMode) => this.setState({ displayMode })}
				onSubmit={(evt) => this.updateUser(evt)}
			>
				<Row>
					<Col md={6}>
						<InputFormControl
							label="First Name"
							key={String(this.state.displayMode)}
							displayMode
							value={user.firstName}
						/>
					</Col>
					<Col md={6}>
						<InputFormControl
							label="Last Name"
							displayMode
							value={user.lastName}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<InputFormControl label="Email" displayMode value={user.email} />
					</Col>
					<Col md={6}>
						<InputFormControl
							label="SMS Phone"
							autoFocus
							maxLength={10}
							minLength={10}
							displayMode={this.state.displayMode}
							onChange={(smsPhone) =>
								this.updateUserProp('smsPhone', smsPhone, true)
							}
							value={user.smsPhone ?? ''}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<InputFormControl
							label="Mobile Phone"
							displayMode={this.state.displayMode}
							onChange={(mobilePhone) =>
								this.updateUserProp('mobilePhone', mobilePhone, true)
							}
							maxLength={10}
							minLength={10}
							value={user.mobilePhone ?? ''}
						/>
					</Col>
					<Col md={6}>
						<InputFormControl
							label="Office Phone"
							maxLength={10}
							minLength={10}
							displayMode={this.state.displayMode}
							onChange={(officePhone) =>
								this.updateUserProp('officePhone', officePhone, true)
							}
							value={user.officePhone ?? ''}
						/>
					</Col>
				</Row>
			</RtUiWideForm>
		);
	}
}
