import { NumbersIndexResponseData } from 'RtModels';
import { AccountRouter } from 'RtUi/app/AccountManagement/Accounts/Account.router';
import { SubscriptionRouter } from 'RtUi/app/AccountManagement/Subscriptions/Subscription.router';
import { IRtVueReportDataGridProps } from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getNumbersDetailColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/numbersDetail';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { useMemo } from 'react';
import { Item, Submenu } from 'react-contexify';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface IVueNumberDataGridProps extends IRtVueReportDataGridProps {
	resource: HttpResource<NumbersIndexResponseData> | null;
	isLoading?: boolean;
	callDateFormat?: string;
	onDrillDown: (row: NumbersIndexResponseData, key: string) => void;
	getExportData: () => Promise<NumbersIndexResponseData[]>;
}

export const VueNumberDataGrid = ({
	resource,
	isLoading,
	callDateFormat,
	onDrillDown,
	getExportData
}: IVueNumberDataGridProps) => {
	const navigate = useNavigate();
	const { data = [] } = useQuery<NumbersIndexResponseData[] | undefined, Error>(
		[`useGetNumbersDetailReport`, resource],
		() => resource?.getAll()
	);
	const columns = useMemo(
		() => getNumbersDetailColumns(callDateFormat),
		[callDateFormat]
	);

	return (
		<DataGrid<NumbersIndexResponseData>
			pageName="rtVue_numbersDetail"
			data={data}
			columns={columns}
			loading={isLoading}
			totalRows={resource?.getTotalDbCount()}
			menuLinks={(row) => (
				<>
					{row.number !== undefined && (
						<Submenu label="CDR Search">
							<Item onClick={() => onDrillDown(row, 'fr_std')}>
								<span className="d-flex justify-content-start align-items-center">
									<span>Calling Party</span>
								</span>
							</Item>
							<Item onClick={() => onDrillDown(row, 'to_std')}>
								<span className="d-flex justify-content-start align-items-center">
									<span>Dialed Number</span>
								</span>
							</Item>
						</Submenu>
					)}
					{row.accountId !== undefined && (
						<Item
							onClick={() =>
								navigate(AccountRouter.getProfileRoute(String(row.accountId)))
							}
						>
							<span className="d-flex justify-content-start align-items-center">
								<span>Account</span>
							</span>
						</Item>
					)}
					{row.subscriptionId !== undefined && (
						<Item
							onClick={() =>
								navigate(SubscriptionRouter.getProfileRoute(row.subscriptionId))
							}
						>
							<span className="d-flex justify-content-start align-items-center">
								<span>Subscription</span>
							</span>
						</Item>
					)}
				</>
			)}
			enableExternalExport
			getExternalExportData={getExportData}
		/>
	);
};
