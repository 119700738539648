/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $EventNotification = {
    properties: {
        userNotificationId: {
            type: 'number',
            isRequired: true,
        },
        messageTypeId: {
            type: 'MessageType',
            isRequired: true,
        },
        userNotificationFrequencyId: {
            type: 'NotificationFrequency',
            isRequired: true,
        },
        customStartTime: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        customEndTime: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        scheduleId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
    },
};