import { ServerProfileResponse } from 'RtModels';
import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { ServerResource } from 'RtUi/app/rtAdmin/Servers/lib/resource/ServerResource';

interface IServerSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<ServerProfileResponse, isMulti> {
	labelKey?: keyof ServerProfileResponse;
}

export class ServerSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	ServerProfileResponse,
	isMulti,
	IServerSelectProps<isMulti>
> {
	public resourceClass = ServerResource;
	public state: ISelectFormControlState<ServerProfileResponse> = {
		formLabel: 'Server',
		valueKey: 'serverId',
		labelKey: this.props.labelKey ?? 'serverId'
	};
}
