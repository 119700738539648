import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import {
	Cpr,
	CprErrorType,
	CprLbl,
	CprNodeType
} from 'Somos/lib/SomosCpr/RtCprV2';
import clsx from 'clsx';
import { useState } from 'react';

interface ICprLabelRowIndexDataGridButtonProps {
	cpr: Cpr;
	label: CprLbl;
	index: number;
	updateLabels: () => void;
}

export const CprLabelRowIndexDataGridButton = ({
	cpr,
	label,
	index,
	updateLabels
}: ICprLabelRowIndexDataGridButtonProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const cprLabels = cpr.getCprLabels();
	const cprLabelsLength = cprLabels.length;

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const addLabel = (index: number) => {
		let attempts = 0;
		const maxAttempts = 10;

		while (++attempts <= maxAttempts) {
			const newCprLabel = cpr.makeCprLabel(
				CprNodeType.AreaCode,
				`*LABEL${cprLabelsLength + attempts}`,
				index,
				true
			);

			if (newCprLabel) {
				break;
			}
		}

		updateLabels();
	};

	return (
		<>
			<Button
				className={clsx({
					'text-danger border-danger':
						label.hasErrors(CprErrorType.Error) ||
						label.hasErrors(CprErrorType.Critical),
					'text-warning border-warning': label.hasErrors(CprErrorType.Warning)
				})}
				endIcon={<KeyboardArrowDown />}
				onClick={handleClick}
			>
				{index + 1}
			</Button>
			<Menu
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				disableScrollLock
			>
				<MenuItem
					onClick={() => {
						addLabel(index);
						handleClose();
					}}
				>
					<i className="fas fa-arrow-up" />
					&nbsp;&nbsp;Add Label Above
				</MenuItem>
				<MenuItem
					onClick={() => {
						addLabel(index + 1);
						handleClose();
					}}
				>
					<i className="fas fa-arrow-down" />
					&nbsp;&nbsp;Add Label Below
				</MenuItem>
				<MenuItem
					onClick={() => {
						cpr.deleteCprLabel(label.getName());
						handleClose();
						updateLabels();
					}}
				>
					<i className="fas fa-fw fa-trash-alt" />
					&nbsp;&nbsp;Delete Label
				</MenuItem>
			</Menu>
		</>
	);
};
