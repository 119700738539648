import { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { CprNodeType, type ICprLerg } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import type { CprRow } from 'Somos/lib/SomosCpr/RtCprV2/CprRow';

// exported definitions
// ======================================================================

export class CprColCarrier extends CprCol {

	public readonly cprNodeTypeId = CprNodeType.Carrier;

	public readonly valueLimit = 1;
	public readonly valueRegExp = /^\d{4}$/;

	public readonly isTermNode: boolean = true;

	// remove readonly property from possibles and possiblesByKey
	protected possibles: string[] = [];
	protected possiblesByKey: Record<string, ICprLerg> = {};

	public constructor(cprRow: CprRow, cprIdx: number, uuid: string | undefined = undefined) {
		super(cprRow, cprIdx, uuid);
		this.resetPossibles();
	}

	public resetPossibles() {
		// DO NOT override resetPossbiles unless the values are dynamic based on other selections.

		this.possibles = [];
		this.possiblesByKey = {};

		// will likey have duplicate values
		const allCics = this.cpr.getInterLataCarriers().concat(this.cpr.getInterLataCarriers());

		for (const possible of allCics) {
			// skip duplicate values
			if (possible in this.possiblesByKey) {
				continue;
			}

			const asCprLerg: ICprLerg = {
				cprNodeTypeId: CprNodeType.Carrier, // @TODO not really used
				worldValue: possible,
				somosValue: possible,
				pointId: '',
				state: '',
				lata: '',
				ocn: ''
			};

			this.possiblesByKey[possible] = asCprLerg;
			this.possibles.push(possible);
		}
	}

	public getValue(): string | null {
		if (this.cprValues.length !== 1) {
			return null;
		}
		return this.cprValues[0].getValue();
	}

}
