import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { MarginSummaryIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IMarginSummaryReportRouterTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const MarginSummaryReportContainerTabs: IMarginSummaryReportRouterTabs =
	{
		Profile: {
			header: 'Profile'
		}
	};

export class MarginSummaryReportRouterClass extends SipApplicationRouter<
	MarginSummaryIndexResponse,
	MarginSummaryIndexResponse,
	IMarginSummaryReportRouterTabs
> {
	constructor() {
		super(
			'Margin Summary',
			'marginSummaryReport',
			'costAccountId',
			MarginSummaryReportContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.MarginSummary);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const MarginSummaryReportRouter = new MarginSummaryReportRouterClass();
