import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutingRuleIndexRequest, RoutingRuleIndexResponse } from 'RtModels';
import { RoutingRuleResource } from 'RtUi/app/rtSip/RoutingRules/lib/resources/RoutingRuleResource';
import { useGetRoutingRules } from 'RtUi/app/rtSip/RoutingRules/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRoutingRulesColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/routingRule';
import { Confirmation } from 'RtUi/components/form/Confirmation';

interface IRoutingRuleDataGridProps {
	resourceParams?: RoutingRuleIndexRequest;
	autoFocusFilter?: boolean;
}

export const RoutingRuleDataGrid = ({
	resourceParams
}: IRoutingRuleDataGridProps): JSX.Element => {
	const navigate = useNavigate();

	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetRoutingRules(resourceParams);

	const removeRoutingRule = useCallback(
		async (routingRule: RoutingRuleIndexResponse) => {
			const confirm = await Confirmation.createDelete(routingRule.label);

			if (!confirm) {
				return;
			}

			const resource = new RoutingRuleResource();
			await resource.delete(routingRule.routingRuleId);

			refetch();
		},
		[refetch]
	);

	const columns = useMemo(
		() => getRoutingRulesColumns(removeRoutingRule),
		[removeRoutingRule]
	);

	const goToProfile = (id: number) => navigate(`/rtSip/routingRules/${id}`);

	return (
		<>
			<DataGrid<RoutingRuleIndexResponse>
				data={data?.data}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtSip_routingRules'}
				columns={columns}
				onRowClick={(row) => goToProfile(row.routingRuleId)}
			/>
			<Confirmation />
		</>
	);
};
