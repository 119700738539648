import {
	ISubscriptionSelectProps,
	SubscriptionSelect
} from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';

interface IVendorTerminationSubscriptionSelectProps<
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
> extends Omit<ISubscriptionSelectProps<IsMulti, IsClearable>, 'serviceIds'> {}

export const VendorTerminationSubscriptionSelect = <
	IsMulti extends boolean = false,
	IsClearable extends boolean = false
>(
	props: IVendorTerminationSubscriptionSelectProps<IsMulti, IsClearable>
): JSX.Element => {
	const serviceIds = [3];

	return SubscriptionSelect({ ...props, serviceIds });
};
