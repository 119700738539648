import { TabbedLayoutTabProps } from '../ui/TabbedLayout';
import { ApplicationContainer } from './ApplicationContainer';

export interface IProfileApplicationContainerTabs {
	[key: string]: TabbedLayoutTabProps;
}

export abstract class ProfileApplicationContainer<
	P,
	S
> extends ApplicationContainer<P, S> {
	public abstract state: S;
	public abstract Tabs: IProfileApplicationContainerTabs;
}
