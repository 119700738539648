export const NumbersFormatRegex =
	/^(?<natural>-?(?:\d{1,3}[,]?)+)(?:[.]\d+)?$/g;

export class NumberFormatter {
	/**
	 * matches '14000.20' or '14,000.20'
	 * Output: 14000.20
	 * @param value string
	 */
	public static toNumber(value: string | number): number {
		const parsedValue = String(value);
		const matchedValue = new RegExp(`${NumbersFormatRegex.source}$`).exec(
			parsedValue
		);
		if (!matchedValue || !matchedValue.groups) {
			return Number(value);
		}

		const { natural = '' } = matchedValue.groups;

		const naturalValue = natural.replace(/[,.]/g, '');

		return Number(parsedValue.replace(natural, naturalValue));
	}

	public static isInteger(value: string | number): boolean {
		const parsedValue = String(value);
		const matchedValue = new RegExp(`${NumbersFormatRegex.source}$`).exec(
			parsedValue
		);

		return Boolean(
			matchedValue?.groups?.natural && !matchedValue?.groups?.decimal
		);
	}

	public static isDecimal(value: string | number): boolean {
		const parsedValue = String(value);
		const matchedValue = new RegExp(`${NumbersFormatRegex.source}$`).exec(
			parsedValue
		);

		return Boolean(matchedValue);
	}
}
