/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum AlertInterval {
    Minutes = 1,
    Hours = 2,
    Days = 3,
    Weeks = 4,
    Months = 5,
}