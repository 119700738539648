import { DataSources, OcnIndexResponse } from 'RtModels';
import {
	IGenericFilterResponse,
	RtVueFilter,
	TFilterResponseWithKeywords
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { CacheStrategy } from 'RtUi/utils/cache/CacheStrategy';
import { IndexedDbCacheStrategy } from 'RtUi/utils/cache/strategies/IndexedDbCacheStrategy';

/**
 * @singleton
 */
export class RtVueOcnFilter extends RtVueFilter<OcnIndexResponse> {
	public static getInstance() {
		if (!RtVueOcnFilter.instance) {
			RtVueOcnFilter.instance = new RtVueOcnFilter();
		}

		return RtVueOcnFilter.instance;
	}

	private static instance: RtVueOcnFilter | undefined;
	private static readonly FilterName = 'RtVueOcnFilter';
	private static readonly DataCache: CacheStrategy<
		TFilterResponseWithKeywords<OcnIndexResponse>
	> = new IndexedDbCacheStrategy(RtVueOcnFilter.FilterName, 'ocn', [
		'ocn',
		'*keywords' as keyof OcnIndexResponse
	]);

	protected constructor() {
		super(RtVueOcnFilter.FilterName, DataSources.Ocn, RtVueOcnFilter.DataCache);
	}

	public getLabelFor(record: OcnIndexResponse): string {
		return record.ocn;
	}

	public getValueFor(record: OcnIndexResponse): string {
		return String(record.ocn);
	}

	protected getFilterResponse(): Promise<
		IGenericFilterResponse<OcnIndexResponse>
	> {
		return this.vueHttp.getOcnFilter();
	}

	protected getKeywordsFor(record: OcnIndexResponse): string[] {
		return [String(record.ocn)];
	}
}
