/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TemplateCprProfileResponse = {
    properties: {
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        effectiveTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        cprStatusId: {
            type: 'CprStatus',
            isRequired: true,
        },
        approvalIndicator: {
            type: 'CprApprovalIndicator',
            isRequired: true,
        },
        company: {
            type: 'string',
        },
        summary: {
            type: 'string',
        },
        notes: {
            type: 'string',
        },
        contactName: {
            type: 'string',
            isRequired: true,
        },
        contactNumber: {
            type: 'string',
            isRequired: true,
        },
        templateName: {
            type: 'string',
            isRequired: true,
        },
        areasOfService: {
            type: 'Array',
        },
        interLataCarriers: {
            type: 'Array',
        },
        intraLataCarriers: {
            type: 'Array',
        },
        cpr: {
            type: 'Cpr',
        },
    },
};