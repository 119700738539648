import { useCallback, useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

export interface IGenericTypeRenderProps<T> {
	id: number | string;
	resourceCacheMap: ResourceCacheMap<T>;
	showIdInLabel?: boolean;
	onLabelUpdate?: (label: string | undefined) => string | void;
	renderValue?: (id: string | number, label?: string) => JSX.Element;
}

export const GenericTypeRender = <T extends object>(
	props: IGenericTypeRenderProps<T>
) => {
	const [label, setLabel] = useState<string>();
	const [showIdInternal, setShowIdInternal] = useState<boolean>(true);
	const [isLabelLoading, setIsLabelLoading] = useState<boolean>(true);
	const lastId = useRef<number | string | undefined>();
	const componentIsMounted = useRef<boolean>(false);

	const updateLabel = useCallback(async () => {
		const { id, resourceCacheMap, onLabelUpdate } = props;

		if (lastId.current !== id && componentIsMounted.current) {
			if (!resourceCacheMap.isPopulated()) {
				// wait for 5 seconds until label load, if no show id
				setTimeout(() => setIsLabelLoading(false), 5000);
			}
			setIsLabelLoading(true);
			const label = await resourceCacheMap.get(id);
			setLabel(label);
			setIsLabelLoading(false);

			if (onLabelUpdate) {
				const labelReturned = onLabelUpdate(label);
				if (labelReturned && labelReturned !== label) {
					setLabel(labelReturned);
					setShowIdInternal(false);
				}
			}
			lastId.current = id;
		}
	}, [props]);

	useEffect(() => {
		componentIsMounted.current = true;
		updateLabel();
		return () => {
			componentIsMounted.current = false;
		};
	});

	const { id, showIdInLabel, renderValue } = props;

	const loader = (
		<ContentLoader preserveAspectRatio="none" height={10}>
			<rect x={0} y={0} rx="5" ry="5" height={10} width={150} />
		</ContentLoader>
	);

	if (isLabelLoading) {
		return loader;
	}

	if (renderValue) {
		return renderValue(id, label);
	}

	if (label && label !== 'null') {
		return (
			<span>
				{label} {showIdInLabel && showIdInternal && `(${id})`}
			</span>
		);
	}

	return <span>{id}</span>;
};

GenericTypeRender.defaultProps = {
	showIdInLabel: true
};
