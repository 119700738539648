/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $OriginationNumberBulkCreateResponse = {
    properties: {
        insExeQty: {
            type: 'number',
            isRequired: true,
        },
        insRowQty: {
            type: 'number',
            isRequired: true,
        },
        addRowQty: {
            type: 'number',
            isRequired: true,
        },
        updRowQty: {
            type: 'number',
            isRequired: true,
        },
    },
};