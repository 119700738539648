import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { UserIndexResponse } from 'RtModels';
import { MyProfileHttp } from 'RtUi/app/user/MyProfile/lib/Http/MyProfileHttp';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';

interface IMyProfilePasswordUpdateFormProps {
	onUpdate?: (updatedUser: UserIndexResponse) => void;
}

interface IMyProfilePasswordUpdateFormState {
	currentPassword: string;
	newPassword: string;
	confirmNewPassword: string;
	//form
	passwordWasUpdated: boolean;
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
}

export class MyProfilePasswordUpdateForm extends React.Component<
	IMyProfilePasswordUpdateFormProps,
	IMyProfilePasswordUpdateFormState
> {
	public myProfileHttp = new MyProfileHttp();
	public state: IMyProfilePasswordUpdateFormState = {
		displayMode: false,
		isSubmitting: false,
		passwordWasUpdated: false,
		error: undefined,
		currentPassword: '',
		newPassword: '',
		confirmNewPassword: ''
	};

	public onCancel() {
		this.setState({
			displayMode: false,
			isSubmitting: false,
			passwordWasUpdated: false,
			error: undefined,
			currentPassword: '',
			newPassword: '',
			confirmNewPassword: ''
		});
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		this.setState({ error: undefined, isSubmitting: true });

		let newUser: UserIndexResponse | null = null;

		try {
			const { newPassword, confirmNewPassword, currentPassword } = this.state;

			if (newPassword !== confirmNewPassword) {
				throw new Error('"New Password" and "Confirm New Password" must match');
			}

			newUser = await this.myProfileHttp.updateUsersPassword(
				currentPassword,
				newPassword
			);

			this.setState({ passwordWasUpdated: true });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (newUser) {
			const { onUpdate = () => ({}) } = this.props;

			onUpdate(newUser);
		}
	}

	public render() {
		if (this.state.passwordWasUpdated) {
			return (
				<Alert
					variant="success"
					className="d-flex justify-content-start"
					style={{ maxWidth: 600 }}
				>
					<i className="fas fa-fw fa-check-circle me-2" />
					<span>Password was updated!</span>
				</Alert>
			);
		}

		return (
			<RtUiNarrowForm
				onCancel={() => this.onCancel()}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onSubmit={(evt) => this.onSubmit(evt)}
				onChange={(displayMode) => this.setState({ displayMode })}
				displayMode={this.state.displayMode}
			>
				<InputFormControl
					type="password"
					label="Current Password"
					required
					displayMode={this.state.displayMode}
					onChange={(currentPassword) => this.setState({ currentPassword })}
					value={this.state.currentPassword}
				/>
				<InputFormControl
					type="password"
					label="New Password"
					required
					displayMode={this.state.displayMode}
					onChange={(newPassword) => this.setState({ newPassword })}
					value={this.state.newPassword}
				/>
				<InputFormControl
					type="password"
					label="Confirm New Password"
					required
					displayMode={this.state.displayMode}
					onChange={(confirmNewPassword) =>
						this.setState({ confirmNewPassword })
					}
					value={this.state.confirmNewPassword}
				/>
			</RtUiNarrowForm>
		);
	}
}
