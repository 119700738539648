/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RtCprDto = {
    properties: {
        routingCacheTypeId: {
            type: 'RoutingCacheTypes',
            isRequired: true,
        },
        routingCacheKey: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        sourceRespOrgId: {
            type: 'string',
            isRequired: true,
        },
        sourceEffectiveTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        sourceRecVersionId: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        sourceTemplateName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        targetEffectiveTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        targetTemplateName: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        targetRespOrgId: {
            type: 'string',
            isNullable: true,
        },
        contactName: {
            type: 'string',
            isRequired: true,
        },
        contactNumber: {
            type: 'string',
            isRequired: true,
        },
        notes: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        company: {
            type: 'string',
            isRequired: true,
        },
        daylightSavings: {
            type: 'string',
            isRequired: true,
        },
        timeZone: {
            type: 'string',
            isRequired: true,
        },
        cprStatusId: {
            type: 'CprStatus',
            isRequired: true,
        },
        approvalIndicator: {
            type: 'CprApprovalIndicator',
            isRequired: true,
        },
        interLataCarriers: {
            type: 'Array',
            isRequired: true,
        },
        intraLataCarriers: {
            type: 'Array',
            isRequired: true,
        },
        lineQty: {
            type: 'number',
            isRequired: true,
        },
        aosLbls: {
            type: 'Array',
            isRequired: true,
        },
        aosCols: {
            type: 'Array',
            isRequired: true,
        },
        cprLbls: {
            type: 'Array',
            isRequired: true,
        },
        cprCols: {
            type: 'Array',
            isRequired: true,
        },
        cprRows: {
            type: 'Array',
            isRequired: true,
        },
    },
};