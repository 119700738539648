/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrTaskProfileResponse = {
    properties: {
        lcrTaskId: {
            type: 'number',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        templateName: {
            type: 'string',
            isRequired: true,
        },
        allowAsDefaultOnly: {
            type: 'number',
            isRequired: true,
        },
        minuteProfileId: {
            type: 'number',
            isRequired: true,
        },
        skipSavingsLimit: {
            type: 'number',
            isRequired: true,
        },
        cprRowLimit: {
            type: 'number',
            isRequired: true,
        },
        cprSizeLimit: {
            type: 'number',
            isRequired: true,
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        startedAt: {
            type: 'Date',
            format: 'date-time',
        },
        completedAt: {
            type: 'Date',
            format: 'date-time',
        },
        createdAt: {
            type: 'Date',
            format: 'date-time',
        },
        winningLcrTaskResultId: {
            type: 'number',
        },
        winningLcrScenarioId: {
            type: 'number',
        },
        winningLcrCarrierId: {
            type: 'number',
        },
        winningDefaultCic: {
            type: 'string',
        },
        winningNonLcrCharges: {
            type: 'number',
            isRequired: true,
        },
        winningMinutes: {
            type: 'number',
            isRequired: true,
        },
        winningEstimatedCost: {
            type: 'number',
            isRequired: true,
        },
        taskLabel: {
            type: 'string',
            isRequired: true,
        },
    },
};