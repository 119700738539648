import * as moment from 'moment';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	CprRateSheetProfileResponse,
	LcrCarrierIndexResponse,
	LcrRateSheetType,
	LcrTaskIndexResponse,
	LcrTaskResultIndexResponse,
	MinuteProfile,
	RespOrgIndexResponse,
	RoutingCacheTypes
} from 'RtModels';
import { SelectCprToCopyFrom } from 'RtUi/app/rt800/Cprs/lib/components/SelectCprToCopyFrom';
import { RadioRoutingCacheControl } from 'RtUi/app/rt800/Cprs/lib/controls/RadioRoutingCacheControl';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { LcrCarrierSelect } from 'RtUi/app/rtLco/Carriers/lib/controls/LcrCarrierSelect';
import { LcrMinuteSelect } from 'RtUi/app/rtLco/Minutes/lib/controls/LcrMinutesSelect';
import { RateSheetTypeRadioFormControl } from 'RtUi/app/rtLco/RateSheets/lib/controls/RateSheetTypeRadioFormControl';
import { RateSheetResource } from 'RtUi/app/rtLco/RateSheets/lib/resources/RateSheetResource';
import { LcrTaskResultSelect } from 'RtUi/app/rtLco/TaskResults/lib/controls/LcrTaskResultSelect';
import { LcrTaskCarrierResultsResource } from 'RtUi/app/rtLco/TaskResults/lib/resources/LcrTaskCarrierResultsResource';
import { LcoTaskSelect } from 'RtUi/app/rtLco/Tasks/lib/controls/LcoTaskSelect';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';

interface ICicToCarrierMapping {
	cic: string;
	lcrCarrier?: LcrCarrierIndexResponse;
	initialOptionId?: number;
}

interface ICreateRateSheetFormProps {
	onCreate: (profile: CprRateSheetProfileResponse) => void;
}

interface ICreateRateSheetFormState {
	lcrMinutes: MinuteProfile[];
	rateSheetTypeId: LcrRateSheetType;
	routingCacheTypeId: RoutingCacheTypes;
	cicMapping: ICicToCarrierMapping[];
	isLoadingCpr: boolean;
	isSubmitting: boolean;
	error?: any;
	respOrg?: RespOrgIndexResponse;
	selectedMgiProfile?: Cpr;
	lcrTask?: LcrTaskIndexResponse;
	lcrTaskResult?: LcrTaskResultIndexResponse;
}

export class CreateRateSheetForm extends React.Component<
	ICreateRateSheetFormProps,
	ICreateRateSheetFormState
> {
	public state: ICreateRateSheetFormState = {
		routingCacheTypeId: RoutingCacheTypes.Template,
		cicMapping: [],
		lcrMinutes: [],
		rateSheetTypeId: LcrRateSheetType.NpaNxx,
		isSubmitting: false,
		isLoadingCpr: false,
		error: undefined
	};

	public async createAnalysis(evt: React.FormEvent) {
		evt.preventDefault();

		const { respOrg, lcrMinutes, selectedMgiProfile, lcrTaskResult } =
			this.state;

		if (!respOrg || !lcrMinutes || !(selectedMgiProfile || lcrTaskResult)) {
			return;
		}

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const { rateSheetTypeId, routingCacheTypeId } = this.state;
			const rateSheetResource = new RateSheetResource();
			const minuteProfileIds = lcrMinutes.map(
				(lcrMinute) => lcrMinute.minuteProfileId
			);

			const effectiveTs =
				selectedMgiProfile?.getSourceEffectiveTs() ??
				moment(this.state.lcrTask!.startedAt).toDate();

			const respOrgCarrierIds: number[] = [];
			const respOrgId = respOrg.respOrgId;
			let routingCacheKey: string = '';

			for (const cicMap of this.state.cicMapping) {
				if (!cicMap.lcrCarrier) {
					throw new Error('Must assign a carrier for all CICs');
				}

				respOrgCarrierIds.push(cicMap.lcrCarrier.lcrCarrierId);
			}

			if (routingCacheTypeId === RoutingCacheTypes.TollFree) {
				routingCacheKey = selectedMgiProfile!.getSourceReferenceKey();
			} else if (routingCacheTypeId === RoutingCacheTypes.Template) {
				routingCacheKey = selectedMgiProfile!.getSourceReferenceKey();
			} else if (routingCacheTypeId === RoutingCacheTypes.LcrGenerator) {
				if (!this.state.lcrTaskResult) {
					throw new Error('Must specify an LCO Task Result');
				}

				routingCacheKey = this.state.lcrTaskResult.lcrTaskResultId.toString();
			}

			const profile = await rateSheetResource.create(
				respOrgId,
				rateSheetTypeId,
				routingCacheTypeId,
				routingCacheKey,
				effectiveTs,
				respOrgCarrierIds,
				minuteProfileIds
			);

			this.props.onCreate(profile);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public recalculateCicMapping(selectedMgiProfile: Cpr | undefined) {
		let cicMapping: ICicToCarrierMapping[] = [];

		if (selectedMgiProfile) {
			const interLataCarriers = selectedMgiProfile.getInterLataCarriers();
			const intraLataCarriers = selectedMgiProfile.getIntraLataCarriers();
			const cics = new Set<string>([
				...interLataCarriers,
				...intraLataCarriers
			]);
			cicMapping = Array.from(cics).map((cic) => ({ cic }));
		}

		this.setState({ selectedMgiProfile, cicMapping });
	}

	public async onLcoResultSelect(lcrTaskResult: LcrTaskResultIndexResponse) {
		const lcrTaskResultCarriersResource = new LcrTaskCarrierResultsResource(
			lcrTaskResult.lcrTaskId,
			lcrTaskResult.lcrTaskResultId
		);

		const lcrTaskResultCarriers = await lcrTaskResultCarriersResource.getAll();
		const cicMapping = lcrTaskResultCarriers.map((trc) => ({
			cic: trc.cic,
			initialOptionId: trc.lcrCarrierId
		}));

		this.setState({ lcrTaskResult, cicMapping });
	}

	public updateCicMapping(cic: string, lcrCarrier?: LcrCarrierIndexResponse) {
		const { cicMapping } = this.state;
		const cicMapIndex = cicMapping.findIndex((cicMap) => cicMap.cic === cic);

		if (cicMapIndex < 0) {
			return;
		}

		cicMapping[cicMapIndex].lcrCarrier = lcrCarrier;

		this.setState({ cicMapping });
	}

	public render() {
		const respOrgId = this.state.respOrg
			? this.state.respOrg.respOrgId
			: undefined;
		const respOrgIdKey = respOrgId || 'N/A';
		const isLcoSelected =
			RoutingCacheTypes.LcrGenerator === this.state.routingCacheTypeId;
		const isCprRoutingCache = [
			RoutingCacheTypes.Template,
			RoutingCacheTypes.TollFree
		].includes(this.state.routingCacheTypeId);

		return (
			<RtUiWideForm
				displayMode={false}
				createMode
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onSubmit={(evt) => this.createAnalysis(evt)}
			>
				<RateSheetTypeRadioFormControl
					required
					hideDialCodeOption
					hideLataOcnOption
					onChange={(rateSheetTypeId) => this.setState({ rateSheetTypeId })}
					value={this.state.rateSheetTypeId}
				/>
				<Row>
					<Col md>
						<RespOrgSelect
							required
							onChange={(respOrg: RespOrgIndexResponse) =>
								this.setState({
									respOrg,
									lcrMinutes: [],
									lcrTask: undefined,
									lcrTaskResult: undefined,
									cicMapping: []
								})
							}
							value={this.state.respOrg}
						/>
					</Col>
					<Col md>
						<LcrMinuteSelect<true>
							multi
							required
							key={respOrgIdKey}
							resourceParams={{
								respOrgId
							}}
							onChange={(lcrMinutes) => {
								this.setState({
									lcrMinutes,
									lcrTask: undefined,
									lcrTaskResult: undefined,
									cicMapping: []
								});
							}}
							value={this.state.lcrMinutes}
						/>
					</Col>
				</Row>
				<RadioRoutingCacheControl
					label="Routing Source"
					required
					value={this.state.routingCacheTypeId}
					onChange={(routingCacheTypeId) =>
						this.setState({
							routingCacheTypeId,
							cicMapping: [],
							selectedMgiProfile: undefined,
							lcrTask: undefined,
							lcrTaskResult: undefined
						})
					}
				/>
				{isCprRoutingCache && (
					<SelectCprToCopyFrom
						required
						key={respOrgIdKey}
						respOrgId={respOrgId}
						cloneSource={this.state.routingCacheTypeId}
						onCprSelect={(selectedMgiProfile) =>
							this.recalculateCicMapping(selectedMgiProfile)
						}
						onCloneSourceChange={(routingCacheTypeId) =>
							this.setState({ routingCacheTypeId })
						}
						onLoadingChange={(isLoadingCpr) => this.setState({ isLoadingCpr })}
					/>
				)}
				{isLcoSelected && (
					<Row>
						<Col lg={6}>
							<LcoTaskSelect
								subLabel="(in chronological order)"
								required
								hideIncomplete
								clearable={false}
								onChange={(lcrTask) => {
									this.setState({
										lcrTask,
										lcrTaskResult: undefined,
										cicMapping: []
									});
								}}
								value={this.state.lcrTask}
							/>
						</Col>
						<Col lg={6}>
							{this.state.lcrTask && (
								<LcrTaskResultSelect
									key={this.state.lcrTask.lcrTaskId}
									required
									clearable={false}
									lcrTaskId={this.state.lcrTask.lcrTaskId}
									onChange={(lcrTaskResult) => {
										this.onLcoResultSelect(lcrTaskResult);
									}}
									value={this.state.lcrTaskResult}
								/>
							)}
						</Col>
					</Row>
				)}
				<section className="p-3 bg-light rounded">
					<h6>
						<b>Trace CICs to Carriers</b>
					</h6>
					{!this.state.isLoadingCpr && this.state.cicMapping.length <= 0 && (
						<p className="text-muted mb-0">
							Fill in the form above to continue.
						</p>
					)}
					{this.state.isLoadingCpr && (
						<h6
							className="text-muted d-flex-justify-content-start"
							style={{ position: 'relative' }}
						>
							<i className="fas fa-fw fa-cog fa-spin me-1" />
							<span>Loading CICs...</span>
							<input
								type="text"
								ref={(inputElem) =>
									inputElem && inputElem.setCustomValidity('Loading CICs...')
								}
								required
								value={''}
								onChange={() => ({})}
								style={{
									height: 1,
									width: 0,
									opacity: 0.01,
									position: 'absolute',
									left: '10%',
									bottom: 0
								}}
							/>
						</h6>
					)}
					<Row>
						{respOrgId &&
							!this.state.isLoadingCpr &&
							this.state.cicMapping.map((cicMap) => (
								<Col md={6} key={cicMap.cic}>
									<LcrCarrierSelect
										required
										label={`Account for ${cicMap.cic}`}
										cic={cicMap.cic}
										respOrgId={respOrgId}
										onChange={(lcrCarrier: LcrCarrierIndexResponse) =>
											this.updateCicMapping(cicMap.cic, lcrCarrier)
										}
										value={cicMap.lcrCarrier}
										initialOptionId={cicMap.initialOptionId?.toString()}
									/>
								</Col>
							))}
					</Row>
				</section>
			</RtUiWideForm>
		);
	}
}
