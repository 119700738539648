import { ReleaseCodeIndexResponse } from 'RtModels';
import { ReleaseCodeResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/ReleaseCodeResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface ReleaseCodeDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		ReleaseCodeIndexResponse,
		T
	> {}

export const ReleaseCodeDataGridCache =
	new ResourceCacheMap<ReleaseCodeIndexResponse>(
		new ReleaseCodeResource(),
		'releaseCodeId',
		'label'
	);

export const ReleaseCodeDataGridColumn = <T = any,>(
	config: ReleaseCodeDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: ReleaseCodeDataGridCache,
		...config
	});
};
