import * as React from 'react';
import { EmailAliasProfileResponse } from 'RtModels';
import { EmailAliasResource } from 'RtUi/app/Administration/DistributionManagement/lib/resources/EmailAliasResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';

interface IEmailAliasEditorProps {
	createMode?: boolean;
	editMode?: EmailAliasProfileResponse;
	onUpdate: (updatedProfile: EmailAliasProfileResponse) => void;
}

interface IEmailAliasEditorState {
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
	//form
	label: string;
	emailAddress: string;
}

export class EmailAliasEditor extends React.Component<
	IEmailAliasEditorProps,
	IEmailAliasEditorState
> {
	public state: IEmailAliasEditorState = {
		displayMode: true,
		isSubmitting: false,
		error: undefined,
		label: '',
		emailAddress: ''
	};

	public componentDidMount() {
		this.loadIfCreateMode();
		this.loadIfEditModeProfile();
	}

	public loadIfCreateMode() {
		if (this.props.createMode) {
			this.setState({ displayMode: false });
		}
	}

	public loadIfEditModeProfile() {
		const { editMode } = this.props;

		if (editMode) {
			const { label, emailAddress } = editMode;

			this.setState({ label, emailAddress });
		}
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		let updatedEmailAliasProfile: EmailAliasProfileResponse | null = null;

		try {
			const { createMode, editMode } = this.props;
			const { label, emailAddress } = this.state;
			const emailAliasResource = new EmailAliasResource();

			if (createMode) {
				updatedEmailAliasProfile = await emailAliasResource.create(
					label,
					emailAddress
				);
			} else if (editMode) {
				const { aliasId } = editMode;

				updatedEmailAliasProfile = await emailAliasResource.update(
					aliasId,
					label,
					emailAddress
				);

				this.setState({ displayMode: true });
			}
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (updatedEmailAliasProfile) {
			this.props.onUpdate(updatedEmailAliasProfile);
		}
	}

	public onCancel() {
		this.loadIfEditModeProfile();
	}

	public render() {
		return (
			<RtUiNarrowForm
				onCancel={() => this.onCancel()}
				onSubmit={(evt) => this.onSubmit(evt)}
				displayMode={this.state.displayMode}
				onChange={(displayMode) => this.setState({ displayMode })}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
			>
				{this.props.editMode && (
					<InputFormControl
						label="ID"
						displayMode
						onChange={() => ({})}
						value={String(this.props.editMode.aliasId)}
					/>
				)}
				<InputFormControl
					label="Label"
					displayMode={this.state.displayMode}
					required
					onChange={(label) => this.setState({ label })}
					value={this.state.label}
				/>
				<InputFormControl
					label="Email"
					type="email"
					displayMode={this.state.displayMode}
					required
					onChange={(emailAddress) => this.setState({ emailAddress })}
					value={this.state.emailAddress}
				/>
			</RtUiNarrowForm>
		);
	}
}
