import { RtVueApiRoutes } from 'RtExports/routes';
import { FirebaseRouteType, GuardianAlertHistoryIndexResponse } from 'RtModels';
import { GuardianApplicationRouter } from 'RtUi/app/rtGuardian/rtGuardian.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IAlertHistoryRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

const AlertHistoryContainerTabs: IAlertHistoryRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

class AlertHistoryRouterClass extends GuardianApplicationRouter<
	GuardianAlertHistoryIndexResponse,
	GuardianAlertHistoryIndexResponse,
	IAlertHistoryRouterTabs
> {
	constructor() {
		super(
			'Alert History',
			'alertHistory',
			'guardianAlertHistoryId',
			AlertHistoryContainerTabs
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.GuardianAlertHistory);

		this.setFirebaseRouteType(FirebaseRouteType.GuardianAlertHistory);
	}

	public getProfileLabel(profile: GuardianAlertHistoryIndexResponse): string {
		return profile.summary;
	}

	public getPluralName() {
		return this.getName();
	}
}

export const AlertHistoryRouter = new AlertHistoryRouterClass();
