/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationNoteCreateRequest = {
    properties: {
        reconciliationId: {
            type: 'number',
            isRequired: true,
        },
        reconciliationDetailId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        reconciliationNoteType: {
            type: 'GuardianReconciliationNoteTypes',
            isRequired: true,
        },
        note: {
            type: 'string',
            isRequired: true,
        },
    },
};