import { LcrTaskIndexRequest, LcrTaskIndexResponse } from 'RtModels';
import { useGetLcrTasks } from 'RtUi/app/rtLco/TaskResults/lib/services';
import { LcrTaskRouter } from 'RtUi/app/rtLco/Tasks/LcrTask.router';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLcrTasksColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/lcrTasks';
import { useMemo } from 'react';

interface ILcrTasksGridProps {
	autoFocusFilter?: boolean;
	resourceParams?: LcrTaskIndexRequest;
}

export const LcrTasksGrid = ({ resourceParams }: ILcrTasksGridProps) => {
	const { data, isFetching: isLoading } = useGetLcrTasks(resourceParams);
	const columns = useMemo(() => getLcrTasksColumns(), []);

	return (
		<DataGrid<LcrTaskIndexResponse>
			pageName="rtLco-tasks"
			loading={isLoading}
			data={data?.data}
			router={LcrTaskRouter}
			totalRows={data?.totalCount}
			columns={columns}
		/>
	);
};
