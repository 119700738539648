import { ConnectionIndexRequest, ConnectionIndexResponse } from 'RtModels';
import { VueConnectionEditorForm } from 'RtUi/app/AccountManagement/Subscriptions/lib/forms/VueConnectionEditorForm';
import { ConnectionRouter } from 'RtUi/app/rtVue/Connections/Connection.router';
import { useGetConnections } from 'RtUi/app/rtVue/Connections/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getVueConnectionColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/subscriptions';
import { Aside } from 'RtUi/components/ui/Aside';
import { noop } from 'lodash-es';
import { FC, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IConnectionGridProps {
	autoFocusFilter?: boolean;
	resourceParams?: ConnectionIndexRequest;
	onUpdate?: () => void;
	subscriptionId: number;
}

export const VueConnectionGrid: FC<IConnectionGridProps> = ({
	resourceParams,
	subscriptionId,
	onUpdate = noop
}) => {
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetConnections(resourceParams);

	const columns = useMemo(() => getVueConnectionColumns(), []);

	const [showAddConnectionAside, setShowAddConnectionAside] =
		useState<boolean>(false);

	const onSuccessHandler = () => {
		setShowAddConnectionAside(false);
		refetch();
		onUpdate();
	};

	const onCancelHandler = () => {
		setShowAddConnectionAside(false);
	};

	return (
		<>
			{showAddConnectionAside && (
				<Aside
					isOpen={showAddConnectionAside}
					disabledBody
					className="overflow-visible"
				>
					<Aside.Header header="Add Connection" onClose={onCancelHandler} />
					<Aside.Body>
						<VueConnectionEditorForm
							subscriptionId={subscriptionId}
							createMode
							onCancel={onCancelHandler}
							onSuccess={onSuccessHandler}
						/>
					</Aside.Body>
				</Aside>
			)}
			<DataGrid<ConnectionIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'accountManagement_subscriptionConnections'}
				router={ConnectionRouter}
				headerAction={() => (
					<OverlayTrigger
						overlay={(props) => (
							<Tooltip id={`connection-tooltip`} {...props}>
								Assign existing connection to subscription
							</Tooltip>
						)}
					>
						{({ ref, ...triggerHandler }) => (
							<Button
								ref={ref}
								{...triggerHandler}
								onClick={() => {
									setShowAddConnectionAside(true);
								}}
								variant="white"
								id="connection"
							>
								<i className="fas fa-fw fa-plus fa-lg" />
							</Button>
						)}
					</OverlayTrigger>
				)}
				defaultSorting={[
					{ id: 'createdTs', desc: true },
					{ id: 'connectionId', desc: true }
				]}
			/>
		</>
	);
};
