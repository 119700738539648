import {
	ManagedEntityApiUpdateRequest,
	ManagedEntityCreateRequest,
	ManagedEntityIndexResponse,
	ManagedEntityMgiUpdateRequest,
	ManagedEntityProfileResponse,
	ManagedEntityRocUpdateRequest,
	ManagedEntitySqlUpdateRequest,
	ManagedEntityUpdateRequest,
	ManagedEntityProfileRequest
} from 'RtModels';
import { ManagedResource } from 'RtUi/app/rtAdmin/lib/resources/ManagedResource';
import { RtAdmApiRoutes } from 'RtExports/routes';

export class ManagedEntityResource extends ManagedResource<
	ManagedEntityIndexResponse,
	ManagedEntityProfileResponse
> {
	constructor() {
		super(RtAdmApiRoutes.ManagedEntities);
	}

	public create(
		entityId: string,
		isActive: number,
		isManaged: number,
		isApi: number,
		contactName: string,
		contactNumber: string,
		apiResourceId: number | null
	) {
		const request: ManagedEntityCreateRequest = {
			entityId,
			isActive,
			isManaged,
			isApi,
			contactName,
			contactNumber,
			apiResourceId
		};
		const body = JSON.stringify(request);

		return this.fetchWithRoute<ManagedEntityProfileResponse>(
			RtAdmApiRoutes.ManagedEntities.Create,
			{ body }
		);
	}

	public update(
		entityId: string,
		isActive: number,
		isManaged: number,
		isApi: number,
		contactName: string,
		contactNumber: string,
		apiResourceId: number | null
	) {
		const request: ManagedEntityUpdateRequest = {
			entityId,
			isActive,
			isManaged,
			isApi,
			contactName,
			contactNumber,
			apiResourceId
		};
		const body = JSON.stringify(request);
		const urlParams: ManagedEntityProfileRequest = { entityId };

		return this.fetchWithRoute<ManagedEntityProfileResponse>(
			RtAdmApiRoutes.ManagedEntities.Update,
			{ body, urlParams }
		);
	}

	public updateApi(entityId: string, apiUserName: string, apiPassword: string) {
		const request: ManagedEntityApiUpdateRequest = {
			apiUserName,
			apiPassword
		};
		const body = JSON.stringify(request);
		const urlParams: ManagedEntityProfileRequest = { entityId };

		return this.fetchWithRoute<ManagedEntityProfileResponse>(
			RtAdmApiRoutes.ManagedEntitiesApi.Update,
			{ body, urlParams }
		);
	}

	public deleteApi(entityId: string) {
		const urlParams: ManagedEntityProfileRequest = { entityId };

		return this.fetchWithRoute<ManagedEntityProfileResponse>(
			RtAdmApiRoutes.ManagedEntitiesApi.Delete,
			{ urlParams }
		);
	}

	public updateRoc(entityId: string, rocUserName: string, rocPassword: string) {
		const request: ManagedEntityRocUpdateRequest = {
			rocUserName,
			rocPassword
		};
		const body = JSON.stringify(request);
		const urlParams: ManagedEntityProfileRequest = { entityId };

		return this.fetchWithRoute<ManagedEntityProfileResponse>(
			RtAdmApiRoutes.ManagedEntitiesRoc.Update,
			{ body, urlParams }
		);
	}

	public deleteRoc(entityId: string) {
		const urlParams: ManagedEntityProfileRequest = { entityId };

		return this.fetchWithRoute<ManagedEntityProfileResponse>(
			RtAdmApiRoutes.ManagedEntitiesRoc.Delete,
			{ urlParams }
		);
	}

	public updateSql(entityId: string, sqlUserName: string, sqlPassword: string) {
		const request: ManagedEntitySqlUpdateRequest = {
			sqlUserName,
			sqlPassword
		};
		const body = JSON.stringify(request);
		const urlParams: ManagedEntityProfileRequest = { entityId };

		return this.fetchWithRoute<ManagedEntityProfileResponse>(
			RtAdmApiRoutes.ManagedEntitiesSql.Update,
			{ body, urlParams }
		);
	}

	public deleteSql(entityId: string) {
		const urlParams: ManagedEntityProfileRequest = { entityId };

		return this.fetchWithRoute<ManagedEntityProfileResponse>(
			RtAdmApiRoutes.ManagedEntitiesSql.Delete,
			{ urlParams }
		);
	}

	public updateMgi(
		entityId: string,
		mgiUserName: string,
		mgiRespOrgId: string
	) {
		const request: ManagedEntityMgiUpdateRequest = {
			mgiUserName,
			mgiRespOrgId
		};
		const body = JSON.stringify(request);
		const urlParams: ManagedEntityProfileRequest = { entityId };

		return this.fetchWithRoute<ManagedEntityProfileResponse>(
			RtAdmApiRoutes.ManagedEntitiesMgi.Update,
			{ body, urlParams }
		);
	}

	public deleteMgi(entityId: string) {
		const urlParams: ManagedEntityProfileRequest = { entityId };

		return this.fetchWithRoute<ManagedEntityProfileResponse>(
			RtAdmApiRoutes.ManagedEntitiesMgi.Delete,
			{ urlParams }
		);
	}
}
