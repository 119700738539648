import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { FileStreamIndexRequest, FileStreamIndexResponse } from 'RtModels';
import { FileStreamResource } from 'RtUi/app/rtVue/FileStreams/lib/resource/FileStreamResource';
import { FileStreamRouter } from 'RtUi/app/rtVue/FileStreams/FileStream.router';

interface IFileStreamSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<FileStreamIndexResponse, isMulti> {
	isActive?: boolean;
}

export class FileStreamSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	FileStreamIndexResponse,
	isMulti,
	IFileStreamSelectProps<isMulti>
> {
	public static defaultProps = {
		router: FileStreamRouter
	};
	public resourceClass = FileStreamResource;
	public state: ISelectFormControlState<FileStreamIndexResponse> = {
		formLabel: 'File Stream',
		valueKey: 'fileStreamId',
		labelKey: 'description'
	};

	constructor(props: IFileStreamSelectProps<isMulti>) {
		super(props);

		const params: FileStreamIndexRequest = {};

		if (typeof props.isActive === 'boolean') {
			params.isActive = props.isActive ? 1 : 0;
		}

		this.setGetAllParams(params);
	}

	/**
	 * For usage in refs to set new values based on Ids
	 * @param fileStreamIds
	 */
	public async setByIds(
		fileStreamIds: isMulti extends true ? number[] : number,
		callback?: () => void
	) {
		const options = await this.loadOptions();

		if (!options) {
			return;
		}

		if (Array.isArray(fileStreamIds)) {
			const newValues = options.filter((option) =>
				fileStreamIds.includes(option.fileStreamId)
			);

			//@ts-ignore
			this.triggerOnChange(newValues);
		} else {
			const newValue = options.find(
				(option) => fileStreamIds === option.fileStreamId
			);

			//@ts-ignore
			this.triggerOnChange(newValue);
		}

		if (callback) {
			callback();
		}
	}
}
