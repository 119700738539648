/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ServiceNumberPortRequestIndexResponse = {
    properties: {
        serviceNumberPortRequestId: {
            type: 'number',
            isRequired: true,
        },
        serviceNumber: {
            type: 'string',
            isRequired: true,
        },
        integrationId: {
            type: 'number',
            isRequired: true,
        },
        isPortIn: {
            type: 'number',
            isRequired: true,
        },
        pin: {
            type: 'string',
            isRequired: true,
        },
        requestTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        isValid: {
            type: 'number',
            isRequired: true,
        },
        note: {
            type: 'string',
            isNullable: true,
        },
    },
};