import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FormErrors } from '../FormErrors';

export interface ISubmitFormControlProps {
	displayMode: boolean;
	editModeActions?: () => React.ReactNode;
	displayModeActions?: () => React.ReactNode;
	createMode?: boolean;
	cancelButtonText?: string;
	submitButtonText?: string;
	submitButtonIcon?: string;
	isCanceledDisabled?: boolean;
	isSubmitDisabled?: boolean;
	isSubmitting?: boolean;
	submitButtonColor?: string;
	error?: any;
	onCancel?: () => void;
	onChange?: (displayMode: boolean) => void;
	successMessage?: string;
	footerClassName?: string;
}

export class SubmitFormControl extends React.Component<
	ISubmitFormControlProps,
	{}
> {
	public static defaultProps = {
		cancelButtonText: 'Cancel',
		submitButtonText: 'Submit',
		submitButtonIcon: 'fa-check',
		isCanceledDisabled: false,
		isSubmitDisabled: false,
		submitButtonColor: 'submit',
		footerClassName: ''
	};

	public render() {
		const {
			displayMode,
			isSubmitting: submitting = false,
			createMode = false,
			onCancel = () => ({})
		} = this.props;
		const {
			onChange = () => ({}),
			editModeActions = () => null,
			displayModeActions = () => null
		} = this.props;

		const footerClassName = `d-flex justify-content-between card-body px-0 pb-0 ${this.props.footerClassName}`;

		const onInternalCancel = () => {
			onChange(!displayMode);

			onCancel();
		};

		return (
			<>
				<FormErrors className="mt-3" error={this.props.error} />
				<footer className={footerClassName}>
					{this.props.successMessage && (
						<Alert
							variant="success"
							className="alert-sm mb-0 d-flex align-items-center me-3"
						>
							{this.props.successMessage}
						</Alert>
					)}
					<article>
						{!displayMode && editModeActions()}
						{displayMode && displayModeActions()}
					</article>
					<article>
						{displayMode && !createMode ? (
							<Button
								type="button"
								variant="submit"
								className="bg-warning text-white border-warning"
								onClick={() => onChange(!displayMode)}
							>
								<i className="fas fa-fw fa-edit" />
								<span>&nbsp;Edit</span>
							</Button>
						) : (
							<>
								{!createMode && (
									<Button
										type="button"
										variant="white"
										className="me-3"
										onClick={() => onInternalCancel()}
										disabled={submitting || this.props.isCanceledDisabled}
									>
										<span>&nbsp;{this.props.cancelButtonText}</span>
									</Button>
								)}
								<Button
									variant={this.props.submitButtonColor}
									className="btn-submit"
									type="submit"
									disabled={submitting || this.props.isSubmitDisabled}
								>
									{submitting ? (
										<i className="fas fa-cog fa-cog fa-spin" />
									) : (
										<i className={`fas fa-fw ${this.props.submitButtonIcon}`} />
									)}
									<span>&nbsp;{this.props.submitButtonText}</span>
								</Button>
							</>
						)}
					</article>
				</footer>
			</>
		);
	}
}
