/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum GuardianReconciliationStatus {
    Pending = 1,
    Submitted = 2,
    VendorApproved = 3,
    VendorRejected = 4,
    Void = 5,
    VendorEscalated = 6,
    Completed = 10,
}