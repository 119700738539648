import { ServiceNumberIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { PhoneNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PhoneNumberDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { ScenarioDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ScenarioDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getGlobalNumbersColumns = (): Array<
	DataGridColumn<ServiceNumberIndexResponse>
> => [
	PhoneNumberDataGridColumn({
		accessorKey: 'serviceNumber',
		header: 'Service Number'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	ScenarioDataGridColumn({
		accessorKey: 'scenarioId',
		header: 'Scenario'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isE911',
		header: '911 Assistance'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isDirAsst',
		header: 'Directory Listing'
	}),
	DefaultDataGridColumn({
		accessorKey: 'custExtRef1',
		header: 'Ext. Account'
	}),
	DefaultDataGridColumn({
		accessorKey: 'custExtRef2',
		header: 'Ext. SubAccount'
	}),
	DefaultDataGridColumn({
		accessorKey: 'custExtRef3',
		header: 'Ext. Customer End User'
	}),
	DefaultDataGridColumn({
		accessorKey: 'custOrderNumber',
		header: 'Ext. Order Number'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Last Updated'
	})
];
