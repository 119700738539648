import { noop } from 'lodash-es';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

interface IPageSizeDropdownProps {
	value?: number;
	onChange?: (numberOfRecords: number) => void;
}

const dataSizeOptions = [250, 2500, 5000, 10000, 50000];

export const PageSizeDropdown = ({
	value = 5000,
	onChange = noop
}: IPageSizeDropdownProps) => {
	const [selectedPageSize, setSelectedPageSize] = useState<number>(value);

	const onChangeSelectedDataSize = (dataSize: number) => {
		setSelectedPageSize(dataSize);
		onChange(dataSize);
	};

	return (
		<Dropdown align="end" className="d-none d-lg-flex btn-group-default">
			<Dropdown.Toggle variant="white">
				<span>
					Records to load:&nbsp;
					{selectedPageSize.toLocaleString()}
				</span>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{dataSizeOptions.map((dataSizeOption) => (
					<Dropdown.Item
						active={dataSizeOption === selectedPageSize}
						key={'dataSize' + dataSizeOption}
						onClick={() => onChangeSelectedDataSize(dataSizeOption)}
					>
						<span>{dataSizeOption} Records loaded</span>
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};
