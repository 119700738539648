import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { Rt800ResourceIndexResponse } from 'RtModels';

export class Rt800ApiResource extends ArrayResource<Rt800ResourceIndexResponse> {
	constructor() {
		super('resourceId');

		this.setApiRouteForGetAll(Rt800ApiRoutes.Resources.Index);
	}
}
