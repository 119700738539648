import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { Aside } from 'RtUi/components/ui/Aside';
import { LcrCarriersResource } from '../resources/LcrCarriersResource';
import { LcrCarrierStateProfileResponse } from 'RtModels';
import { RtError } from 'RtUi/utils/errors/RtError';
import { isEqual } from 'lodash-es';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { NanpRegionDataGridCache } from 'RtUi/components/data/DataGrid/columns/NanpRegionDataGridColumn';

interface IUpdateCarrierStateRateAsideProps {
	lcrCarrierId: number;
	profile?: LcrCarrierStateProfileResponse;
	onCancel?: () => void;
	onUpdate?: (newStateProfile: LcrCarrierStateProfileResponse) => void;
}

interface IUpdateCarrierStateRateAsideState {
	label: string;
	isSubmitting: boolean;
	error?: any;
	defaultInterRate: number;
	defaultIntraRate: number;
	defaultIndetRate: number;
	isActive: number;
	allowAsDefault: number;
}

export class UpdateCarrierStateRateAside extends React.Component<
	IUpdateCarrierStateRateAsideProps,
	IUpdateCarrierStateRateAsideState
> {
	constructor(props: IUpdateCarrierStateRateAsideProps) {
		super(props);
		NanpRegionDataGridCache.warmup();
	}
	public state: IUpdateCarrierStateRateAsideState = {
		label: '',
		defaultInterRate: 1,
		defaultIntraRate: 1,
		defaultIndetRate: 1,
		isActive: 0,
		allowAsDefault: 0,
		isSubmitting: false,
		error: undefined
	};

	public lcrCarriersResource = new LcrCarriersResource();

	public componentDidMount() {
		this.loadProfile();
	}

	public componentDidUpdate(prevProps: IUpdateCarrierStateRateAsideProps) {
		if (!isEqual(prevProps.profile, this.props.profile)) {
			this.loadProfile();
		}
	}

	public loadProfile() {
		const { profile } = this.props;

		if (profile) {
			const {
				defaultInterRate,
				defaultIntraRate,
				defaultIndetRate,
				isActive,
				allowAsDefault
			} = profile;

			const label = NanpRegionDataGridCache.getLabelFor(profile.nanpStateId);

			this.setState({
				defaultInterRate,
				defaultIntraRate,
				defaultIndetRate,
				isActive,
				allowAsDefault,
				label
			});
		}
	}

	public submitUpdate(e: React.FormEvent<HTMLFormElement>) {
		const { profile, lcrCarrierId } = this.props;

		e.preventDefault();

		if (!profile) {
			return;
		}

		this.setState({ isSubmitting: true, error: undefined });

		const { nanpStateId } = profile;
		const {
			defaultInterRate,
			defaultIntraRate,
			defaultIndetRate,
			isActive,
			allowAsDefault
		} = this.state;

		this.lcrCarriersResource
			.updateCarrierState(lcrCarrierId, nanpStateId, {
				defaultInterRate,
				defaultIntraRate,
				defaultIndetRate,
				isActive,
				allowAsDefault
			})
			.then((newStateProfile) => {
				const { onUpdate = () => ({}) } = this.props;

				onUpdate(newStateProfile);
			})
			.catch((error: RtError) => this.setState({ error }))
			.finally(() => this.setState({ isSubmitting: false }));
	}

	public render() {
		const { profile, onCancel = () => ({}) } = this.props;
		const { label } = this.state;
		const asideIsOpen = typeof profile !== 'undefined';

		return (
			<Aside isOpen={asideIsOpen} disabledBody>
				<Aside.Header header={`Update ${label}`} onClose={onCancel} />
				<Aside.Body>
					<Form onSubmit={(e) => this.submitUpdate(e)}>
						<IsActiveRadioFormControl
							label="Active"
							onChange={(isActive) => this.setState({ isActive })}
							hideBothOption
							value={this.state.isActive}
						/>
						<BooleanRadioFormControl
							label="Use Default Rates"
							onChange={(allowAsDefault) => this.setState({ allowAsDefault })}
							value={this.state.allowAsDefault}
						/>
						<PrecisionFormControl
							label="Inter. Rate"
							onChange={(defaultInterRate) => {
								if (defaultInterRate !== null) {
									this.setState({ defaultInterRate });
								}
							}}
							value={this.state.defaultInterRate}
							precision={6}
						/>
						<PrecisionFormControl
							label="Intra. Rate"
							onChange={(defaultIntraRate) => {
								if (defaultIntraRate !== null) {
									this.setState({ defaultIntraRate });
								}
							}}
							value={this.state.defaultIntraRate}
							precision={6}
						/>
						<PrecisionFormControl
							label="Indet. Rate"
							onChange={(defaultIndetRate) => {
								if (defaultIndetRate !== null) {
									this.setState({ defaultIndetRate });
								}
							}}
							value={this.state.defaultIndetRate}
							precision={6}
						/>
						<Form.Group className="d-flex justify-content-end mb-3">
							<Button
								variant="light"
								className="me-2"
								disabled={this.state.isSubmitting}
								onClick={() => onCancel()}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								type="submit"
								disabled={this.state.isSubmitting}
							>
								{this.state.isSubmitting && (
									<i className="fas fa-fw fa-spin fa-cog" />
								)}
								{!this.state.isSubmitting && (
									<i className="fas fa-fw fa-check" />
								)}
								<span>&nbsp;Update</span>
							</Button>
						</Form.Group>
						<FormErrors error={this.state.error} />
					</Form>
				</Aside.Body>
			</Aside>
		);
	}
}
