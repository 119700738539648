/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum TimeDisplay {
    None = 0,
    TimeQuarterly = 1,
    TimeHourly = 2,
}