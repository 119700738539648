import * as React from 'react';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PartitionSipGateway, $PartitionSipGateway } from 'RtModels';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { PartitionSipGatewayResource } from 'RtUi/app/rtAdmin/Partitions/lib/resource/PartitionSipGatewayResource';
import { cloneDeep } from 'lodash-es';
import { PortInputFormControl } from 'RtUi/components/form/PortFormControl';

type TPartitionSipGateway = Omit<
	PartitionSipGateway,
	'createdTs' | 'updatedTs' | 'currentInboundCountTs'
>;

interface IPartitionSipGatewayEditorProps {
	onUpdate: (updatedResource: PartitionSipGateway) => void;
	disablePadding?: boolean;
	partitionId: number;
	editMode?: PartitionSipGateway;
	onCancel?: () => void;
	createMode?: boolean;
}

interface IPartitionSipGatewayEditorState {
	isSaving: boolean;
	editingSipGateway: TPartitionSipGateway;
	displayMode: boolean;
	error?: any;
}

export class PartitionSipGatewayEditor extends React.Component<
	IPartitionSipGatewayEditorProps,
	IPartitionSipGatewayEditorState
> {
	public static defaultProps = {
		onCancel: () => null,
		createMode: false,
		disablePadding: false
	};

	private partitionSipGatewayResource = new PartitionSipGatewayResource();

	constructor(props: IPartitionSipGatewayEditorProps) {
		super(props);

		let editingSipGateway: TPartitionSipGateway;

		if (props.editMode) {
			editingSipGateway = cloneDeep(props.editMode);
		} else {
			editingSipGateway = {
				gwJson: null,
				isAnchored: 0,
				context: '',
				reloadHash: '',
				reloadHashTs: new Date(),
				partitionSipGatewayId: 0,
				serverId: 0,
				partitionId: this.props.partitionId,
				sipGatewayTypeId: 1,
				listenFqdn: '',
				listenPort: 0,
				codecs: '',
				egressPercent: 0,
				isActive: 0,
				isOnline: 0,
				gwMajorVersion: 0,
				gwMinorVersion: 0,
				gwPatchVersion: 0,
				inboundPortLimit: 0,
				currentInboundCount: 0,
				mediaServerGroupId: 0,
				cloudServerId: 0
			};
		}

		this.state = {
			isSaving: false,
			editingSipGateway,
			displayMode: false
		};
	}

	public render() {
		const { editingSipGateway } = this.state;

		return (
			<RtUiForm
				createMode={this.props.createMode}
				disablePadding={this.props.disablePadding}
				onCancel={this.onCancel}
				isSubmitting={this.state.isSaving}
				displayMode={this.state.displayMode}
				onSubmit={this.onSubmit}
				error={this.state.error}
			>
				{!this.props.createMode && (
					<InputFormControl
						label="ID"
						readOnly={true}
						displayMode={true}
						value={String(editingSipGateway.partitionSipGatewayId)}
					/>
				)}
				<InputFormControl
					required={$PartitionSipGateway.properties.partitionId.isRequired}
					label="Partition Id"
					type="number"
					onChange={(value) => this.onChange('partitionId', Number(value))}
					displayMode={this.state.displayMode}
					value={String(editingSipGateway.partitionId)}
				/>
				<InputFormControl
					required={$PartitionSipGateway.properties.serverId.isRequired}
					label="Server Id"
					type="number"
					onChange={(value) => this.onChange('serverId', Number(value))}
					displayMode={this.state.displayMode}
					value={String(editingSipGateway.serverId)}
				/>
				<PortInputFormControl
					required={$PartitionSipGateway.properties.listenPort.isRequired}
					label="Listen Port"
					onChange={(value) => this.onChange('listenPort', Number(value))}
					displayMode={this.state.displayMode}
					value={String(editingSipGateway.listenPort)}
				/>
				<InputFormControl
					required={$PartitionSipGateway.properties.codecs.isRequired}
					label="Codecs"
					displayMode={this.state.displayMode}
					onChange={(value) => this.onChange('codecs', value)}
					value={editingSipGateway.codecs || ''}
				/>
				<InputFormControl
					required={$PartitionSipGateway.properties.egressPercent.isRequired}
					label="Egress Percent"
					type="number"
					onChange={(value) => this.onChange('egressPercent', Number(value))}
					displayMode={this.state.displayMode}
					value={String(editingSipGateway.egressPercent)}
				/>
				<BooleanRadioFormControl
					required={$PartitionSipGateway.properties.isActive.isRequired}
					onChange={(value) => this.onChange('isActive', value)}
					label="Is Active"
					displayMode={this.state.displayMode}
					value={editingSipGateway.isActive}
				/>
				<BooleanRadioFormControl
					required={$PartitionSipGateway.properties.isOnline.isRequired}
					onChange={(value) => this.onChange('isOnline', value)}
					label="Is Online"
					displayMode={this.state.displayMode}
					value={editingSipGateway.isOnline}
				/>
				<InputFormControl
					required={$PartitionSipGateway.properties.gwMajorVersion.isRequired}
					label="Major Version"
					type="number"
					onChange={(value) => this.onChange('gwMajorVersion', Number(value))}
					displayMode={this.state.displayMode}
					value={String(editingSipGateway.gwMajorVersion)}
				/>
				<InputFormControl
					required={$PartitionSipGateway.properties.gwMinorVersion.isRequired}
					label="Minor Version"
					type="number"
					onChange={(value) => this.onChange('gwMinorVersion', Number(value))}
					displayMode={this.state.displayMode}
					value={String(editingSipGateway.gwMinorVersion)}
				/>
				<InputFormControl
					required={$PartitionSipGateway.properties.gwPatchVersion.isRequired}
					label="Patch Version"
					type="number"
					onChange={(value) => this.onChange('gwPatchVersion', Number(value))}
					displayMode={this.state.displayMode}
					value={String(editingSipGateway.gwPatchVersion)}
				/>
				<InputFormControl
					required={$PartitionSipGateway.properties.inboundPortLimit.isRequired}
					label="Inbound Port Limit"
					type="number"
					onChange={(value) => this.onChange('inboundPortLimit', Number(value))}
					displayMode={this.state.displayMode}
					value={String(editingSipGateway.inboundPortLimit)}
				/>
			</RtUiForm>
		);
	}

	private onCancel = () => {
		const editingSipGateway = cloneDeep(
			this.props.editMode
		) as unknown as TPartitionSipGateway;

		this.setState({ editingSipGateway }, () => {
			this.props.onCancel!();
		});
	};

	private onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.setState({ isSaving: true, error: undefined });

		try {
			let newSipGateway: PartitionSipGateway;
			if (this.props.createMode) {
				newSipGateway =
					await this.partitionSipGatewayResource.createPartitionSipGateway(
						this.state.editingSipGateway as PartitionSipGateway
					);
			} else {
				newSipGateway =
					await this.partitionSipGatewayResource.updatePartitionSipGateway(
						this.state.editingSipGateway as PartitionSipGateway
					);
			}

			this.setState({ isSaving: false }, () => {
				this.props.onUpdate(newSipGateway);
			});
		} catch (error) {
			this.setState({ isSaving: false, error });
		}
	};

	private onChange = <K extends keyof TPartitionSipGateway>(
		key: K,
		value: TPartitionSipGateway[K]
	) => {
		if (!this.state.isSaving) {
			const { editingSipGateway } = this.state;

			editingSipGateway[key] = value;

			this.setState({ editingSipGateway });
		}
	};
}
