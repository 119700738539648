import { Rt800Router } from 'RtUi/app/rt800/rt800.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RespOrgResource } from './lib/resources/RespOrgResource';
import { RespOrgIndexResponse, RespOrgProfileResponse } from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { SearchConfidenceLevel } from 'RtUi/components/containers/lib/RtUiRouter';

interface IRespOrgContainerTabs extends IProfileApplicationContainerTabs {
	Dashboard: TabbedLayoutTabProps;
	Profile: TabbedLayoutTabProps;
	Numbers: TabbedLayoutTabProps;
	Templates: TabbedLayoutTabProps;
	RoutingGroups: TabbedLayoutTabProps;
}

export const RespOrgContainerTabs: IRespOrgContainerTabs = {
	Dashboard: {
		header: 'Dashboard'
	},
	Profile: {
		header: 'Profile'
	},
	Numbers: {
		header: 'Numbers'
	},
	Templates: {
		header: 'Templates',
		requireCanManage: true
	},
	RoutingGroups: {
		header: 'Routing Groups',
		requireCanManage: true
	}
};

export class RespOrgRouterClass extends Rt800Router<
	RespOrgIndexResponse,
	RespOrgProfileResponse,
	IRespOrgContainerTabs
> {
	constructor() {
		super('RespOrg', 'respOrgs', 'respOrgId', RespOrgContainerTabs);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.RespOrgs);
	}

	public globalSearch(search: string) {
		return this.defaultGlobalSearch(RespOrgResource, search, 2);
	}

	public globalSearchConfidenceCheck() {
		return SearchConfidenceLevel.Possible;
	}

	public getIndexLabel(record: RespOrgIndexResponse) {
		let label = record.respOrgId;

		if (record.label) {
			label = `${record.label} (${record.respOrgId})`;
		}

		return label;
	}

	public getProfileLabel(profile: RespOrgProfileResponse) {
		return `${profile.label} (${profile.respOrgId})`;
	}
}

export const RespOrgRouter = new RespOrgRouterClass();
