import { FC, FormEvent, useState } from 'react';
import {
	GuardianFraudSystemConfigCreateRequest,
	TimezoneIndexResponse
} from 'RtModels';
import { TimezoneSelectLabel } from 'RtUi/app/rtCommon/Timezones/lib/controls/TimezoneSelectLabel';
import { FraudConfigurationResource } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/resources/FraudConfigurationResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';

import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IFraudConfigurationEditorProps {
	onSuccess?: () => void;
	onCancel?: () => void;
	editMode?: GuardianFraudSystemConfigCreateRequest;
	guardianFraudSystemConfigId?: number;
}

export const FraudConfigurationEditor: FC<
	React.PropsWithChildren<IFraudConfigurationEditorProps>
> = ({
	onSuccess = () => ({}),
	onCancel = () => ({}),
	editMode,
	guardianFraudSystemConfigId
}) => {
	const resource = new FraudConfigurationResource();
	const [timeZone, setTimeZone] = useState<TimezoneIndexResponse>();

	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		editMode,
		createNew: () => resource.createNewProfile()
	});
	const { workingCopy } = rtUiFormState;

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			if (editMode) {
				await resource.update(guardianFraudSystemConfigId!, workingCopy);
			} else {
				await resource.create(workingCopy);
			}
			rtUiFormMethods.setIsSubmitting(false);
			onSuccess();
		} catch (err) {
			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setError(err);
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			displayMode={false}
			onSubmit={onSubmit}
			onCancel={() => {
				rtUiFormMethods.onCancel();
				onCancel();
			}}
		>
			<InputFormControl
				displayMode
				label="Option"
				value={workingCopy.paramName ?? ''}
				onChange={(paramName) => {
					rtUiFormMethods.setWorkingCopy({ paramName });
				}}
			/>
			<InputFormControl
				displayMode
				label="Summary"
				value={workingCopy.summary ?? ''}
				onChange={(summary) => {
					rtUiFormMethods.setWorkingCopy({ summary });
				}}
			/>
			{workingCopy.paramName === 'tz' && (
				<TimezoneSelectLabel
					label="Time Zone"
					onChange={(timeZone) => {
						setTimeZone(timeZone);
						rtUiFormMethods.setWorkingCopy({
							paramValue: timeZone.label
						});
					}}
					value={timeZone}
					initialOptionId={workingCopy.paramValue}
				/>
			)}
			<IsActiveRadioFormControl
				onChange={(isActive) => {
					rtUiFormMethods.setWorkingCopy({ isActive });
				}}
				hideBothOption
				value={workingCopy.isActive ?? true}
			/>
		</RtUiForm>
	);
};
