import {
	getApps as firebaseGetApps,
	initializeApp as firebaseInitializeApp
} from 'firebase/app';
import { BaseFireBase } from 'RtUi/state/actions/user/BaseFireBase';
import { User as FirebaseAuthUser } from 'firebase/auth';

/**
 * Class for Firebase usage
 * @singleton
 */
export class FirebasePublic extends BaseFireBase {
	/**
	 * Singleton method
	 */
	public static getInstance() {
		if (!FirebasePublic.instance) {
			FirebasePublic.instance = new FirebasePublic();
		}

		return FirebasePublic.instance;
	}

	private static instance: FirebasePublic | null = null;

	private constructor() {
		super();

		const firebaseApps = firebaseGetApps();

		if (firebaseApps.length > 0) {
			this.firebaseApp = firebaseApps[0];
		} else {
			this.firebaseApp = firebaseInitializeApp(this.firebaseConfig);
		}

		this.isInitializedDefer = new Promise<FirebaseAuthUser | null>(
			(resolve) => {
				resolve(null);
			}
		);
	}

	protected getDataBasePaths() {
		return ['login/messages/'];
	}
}
