/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum TimeRangePresets {
    Custom = 'custom',
    OneHour = '1h',
    FourHours = '4h',
    Day = '24h',
    ThreeDays = '72h',
    Week = '168h',
    ThirtyDays = '720h',
    LastMonth = 'Lm',
    MonthToDate = 'Mtd',
    Public = 'public',
}