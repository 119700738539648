import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { InvoiceCycleGroup } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';

export class InvoiceCycleGroupResource extends ArrayResource<InvoiceCycleGroup> {
	constructor() {
		super('invoiceCycleGroupId');

		this.setApiRouteForGetAll(RtxSipApiRoutes.InvoiceCycleGroups.Index);
	}
}
