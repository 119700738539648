import {
	ScenarioCreateRequest,
	ScenarioIndexResponse,
	ScenarioProfileResponse,
	ScenarioUpdateRequest,
	ScenarioProfileRequest
} from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export type ScenarioAction = ScenarioCreateRequest['actions'][0];

export class ScenarioResource extends HttpResource<
	ScenarioIndexResponse,
	ScenarioProfileResponse
> {
	constructor() {
		super(RtxSipApiRoutes.Scenarios);
	}

	public createScenario(
		label: string,
		summary: string,
		isEntryPoint: number,
		actions: ScenarioCreateRequest['actions']
	) {
		const req: ScenarioCreateRequest = {
			label,
			summary,
			isEntryPoint,
			actions
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute<ScenarioProfileResponse>(
			RtxSipApiRoutes.Scenarios.Create,
			{ body }
		);
	}

	public updateScenario(
		scenarioId: number | string,
		label: string,
		summary: string,
		isEntryPoint: number,
		actions: ScenarioUpdateRequest['actions']
	) {
		const req: ScenarioUpdateRequest = {
			label,
			summary,
			isEntryPoint,
			actions
		};
		const body = JSON.stringify(req);
		scenarioId = Number(scenarioId);
		const urlParams: ScenarioProfileRequest = { scenarioId };

		return this.fetchWithRoute<ScenarioProfileResponse>(
			RtxSipApiRoutes.Scenarios.Update,
			{ body, urlParams }
		);
	}
}
