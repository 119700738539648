import { Rt800ApiRoutes } from 'RtExports/routes';
import { Rt800Router } from 'RtUi/app/rt800/rt800.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import {
	RoutingGroupIndexResponse,
	RoutingGroupProfileResponse
} from 'RtModels';

interface IRoutingGroupContainerTabs extends IProfileApplicationContainerTabs {
	Routing: TabbedLayoutTabProps;
	Profile: TabbedLayoutTabProps;
	Numbers: TabbedLayoutTabProps;
	History: TabbedLayoutTabProps;
}

export const RoutingGroupContainerTabs: IRoutingGroupContainerTabs = {
	Routing: {
		header: 'Routing'
	},
	Profile: {
		header: 'Profile'
	},
	Numbers: {
		header: 'Numbers'
	},
	History: {
		header: 'History'
	}
};

class RoutingGroupRouterClass extends Rt800Router<
	RoutingGroupIndexResponse,
	RoutingGroupProfileResponse,
	IRoutingGroupContainerTabs
> {
	constructor() {
		super(
			'Routing Group',
			'routingGroups',
			'routingGroupId',
			RoutingGroupContainerTabs
		);

		this.setPermissionsFromApiRoute(Rt800ApiRoutes.RoutingGroups);
	}

	public getIndexLabel(record: RoutingGroupIndexResponse) {
		return record.summary;
	}

	public getProfileLabel(profile: RoutingGroupProfileResponse) {
		return profile.summary;
	}
}

export const RoutingGroupRouter = new RoutingGroupRouterClass();
