import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationUsageDetailsSummaryIndexResponse,
	GuardianReconciliationUsageDetailsSummaryIndexRequest
} from 'RtModels';

import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export class ReconciliationUsageDetailsSummaryResource extends ArrayResource<GuardianReconciliationUsageDetailsSummaryIndexResponse> {
	constructor(private reconciliationId = 0) {
		super('guardianReconciliationUsageDetailSummaryId');
		const urlParams: GuardianReconciliationUsageDetailsSummaryIndexRequest = {
			reconciliationId: this.reconciliationId
		};

		this.setApiRouteForGetAll(
			RtVueApiRoutes.GuardianReconciliationUsageDetailsSummary.Index,
			{
				urlParams
			}
		);
	}
	/**
	 * @override
	 */
	public getAll(
		extraParams: any = {},
		resetCache = false
	): Promise<GuardianReconciliationUsageDetailsSummaryIndexResponse[]> {
		return super.getAll({ ...extraParams, pageSize: 250 }, resetCache);
	}
}
