import moment from 'moment';
import { FC, useCallback, useState } from 'react';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { GuardianServiceNumberSubscriptionCreateRequestBulkItem } from 'RtModels';
import { GuardianServiceNumberCreateGrid } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/grids/GuardianServiceNumberCreateGrid';
import { GuardianServiceNumberSubscriptionSpreadsheetParser } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/parsers/GuardianServiceNumberSubscriptionSpreadsheetParser';
import { GuardianServiceNumberSubscriptionResource } from 'RtUi/app/rtVue/GuardianServiceNumberSubscription/lib/resources/GuardianServiceNumberSubscriptionResource';
import { FormWizard, FormWizardStep } from 'RtUi/components/form/FormWizard';
import { DragAndDropFileUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFileUploader';
import { RtError } from 'RtUi/utils/errors/RtError';
import { FileUtils } from 'RtUi/utils/file/FileUtils';

interface IGuardianServiceNumberSubscriptionBulkCreateProps {
	onUpload?: () => void;
}

export const GuardianServiceNumberSubscriptionBulkCreate: FC<
	React.PropsWithChildren<IGuardianServiceNumberSubscriptionBulkCreateProps>
> = ({ onUpload = () => null }) => {
	const [error, setError] = useState<any>();
	const [parsingMessages, setParsingMessages] = useState<string[]>([]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [wizardStep, setWizardStep] = useState<number>(1);
	const [isFileProcessing, setIsFileProcessing] = useState<boolean>(false);
	const [file, setFile] = useState<File>();
	const [parsedData, setParsedData] =
		useState<GuardianServiceNumberSubscriptionCreateRequestBulkItem[]>();

	const processFile = useCallback(
		async (file: File) => {
			const fileUtils = new FileUtils();

			try {
				setIsFileProcessing(true);
				setError(undefined);
				setParsingMessages([]);

				const spreadsheet = await fileUtils.fileToSpreadsheet(file);

				const parser = new GuardianServiceNumberSubscriptionSpreadsheetParser(
					spreadsheet
				);
				parser.on('error', (eventMessage) => {
					setError(new RtError({ message: eventMessage }));
				});
				parser.on('info', (eventMessage) => {
					setParsingMessages([...parsingMessages, eventMessage]);
				});

				const data = await parser.parseGuardianServiceNumberRecord(file.name);

				setFile(file);
				setParsedData(data);
				setIsFileProcessing(false);
			} catch (error) {
				setError(error);
				setIsFileProcessing(false);
			}
		},
		[parsingMessages]
	);

	const bulkUpload = async () => {
		if (!parsedData?.length) {
			return; //TODO
		}

		try {
			const resource = new GuardianServiceNumberSubscriptionResource();
			setIsSubmitting(true);
			setError(undefined);

			for (const record of parsedData) {
				if (!record.endTs) {
					continue;
				}

				const startTs = moment.utc(record.startTs);
				const endTs = moment.utc(record.endTs);

				if (startTs.isAfter(endTs)) {
					throw new RtError({
						message: `${record.serviceNumber} start is after it's end date`
					});
				}
			}

			await resource.bulkCreate({
				serviceNumbers: parsedData
			});
			setFile(undefined);
			setParsedData(undefined);

			onUpload();
		} catch (error) {
			setError(error);
			setIsSubmitting(false);
		}
	};

	return (
		<Row>
			<Col lg={10}>
				<Card>
					<FormWizard
						error={error}
						isSubmitting={isSubmitting}
						currentStep={wizardStep}
						disableWizardActions={Boolean(error)}
						onChange={(wizardStep) => {
							setWizardStep(wizardStep);
							setIsSubmitting(false);
						}}
					>
						<FormWizardStep step={1} header={'Upload'}>
							<div className="text-end">
								<Button
									variant="link"
									onClick={() => {
										GuardianServiceNumberSubscriptionSpreadsheetParser.downloadExampleFile();
									}}
								>
									<span className="text-muted">
										<i className="fas fa-fw fa-cloud-download me-2" />
										<span>Download Example File</span>
									</span>
								</Button>
							</div>
							<DragAndDropFileUploader
								required
								accept={FileUtils.AcceptTypes.Spreadsheet}
								processing={isFileProcessing}
								value={file}
								onDrop={processFile}
							/>
						</FormWizardStep>
						<FormWizardStep
							step={2}
							header={'Review & Submit'}
							disableBody
							onSubmit={bulkUpload}
						>
							<Alert
								variant="info"
								className="rounded-0 mb-0 d-flex justify-content-start align-items-center"
							>
								<i className="fas fa-fw fa-info-circle me-2" />
								<section>
									Review the data below before submitting. Upload may take
									several minutes depending on the size of the submission.
								</section>
							</Alert>
							{parsingMessages.length > 0 && (
								<Alert
									variant="warning"
									className="rounded-0 mb-0 d-flex justify-content-start align-items-center"
								>
									<i className="fas fa-fw fa-exclamation-circle me-2" />
									<ul className="list-unstyled mb-0 text-warning">
										{parsingMessages.map((parsingMessage) => (
											<li key={parsingMessage}>{parsingMessage}</li>
										))}
									</ul>
								</Alert>
							)}
							<GuardianServiceNumberCreateGrid data={parsedData} />
						</FormWizardStep>
					</FormWizard>
				</Card>
			</Col>
		</Row>
	);
};
