import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { ApplicationContainer } from 'RtUi/components/containers/ApplicationContainer';
import { RerateControlGrid } from 'RtUi/app/rtVue/RerateControl/lib/grids/RerateControlGrid';
import { RerateControlRouter } from 'RtUi/app/rtVue/RerateControl/RerateControl.router';
import { RtVueApiRoutes } from 'RtExports/routes';
import { RerateControlFormEditor } from 'RtUi/app/rtVue/RerateControl/lib/forms/RerateControlFormEditor';

interface IRerateControlContainerState {
	activeTab: string;
}

@RerateControlRouter.getIndexRtUiController({
	groupName: 'Rating',
	orderPriority: 1,
	groupOrderPriority: 40
})
export class RerateControlIndexContainer extends ApplicationContainer<
	{},
	IRerateControlContainerState
> {
	public TabsHeaders = {
		RerateControl: 'Re-rate Control',
		CreateRerate: 'Create Re-rate'
	};

	public Tabs = RerateControlRouter.getProfileTabs();

	public state: IRerateControlContainerState = {
		activeTab: this.TabsHeaders.RerateControl
	};

	public render() {
		return (
			<TabbedLayout
				router={RerateControlRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab header={this.TabsHeaders.RerateControl}>
					<RerateControlGrid />
				</TabbedLayoutTab>
				<TabbedLayoutTab
					isAction
					header={this.TabsHeaders.CreateRerate}
					rtRoute={RtVueApiRoutes.GuardianRerateControl.Create}
				>
					<RerateControlFormEditor
						onSuccess={() =>
							this.setState({ activeTab: this.TabsHeaders.RerateControl })
						}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
