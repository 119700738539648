import { sortBy } from 'lodash-es';
import { FC } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { DataFilter, CallDirectionPreset, SearchField } from 'RtModels';
import { IFilterSearchResult } from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';

const sortedMapping: { [key in CallDirectionPreset]: number } = {
	[CallDirectionPreset.Ingress]: 3,
	[CallDirectionPreset.Customer]: 3,
	[CallDirectionPreset.Vendor]: 2,
	[CallDirectionPreset.Egress]: 2,
	[CallDirectionPreset.Either]: 1
};

export const VueSearchResultButtons: FC<
	React.PropsWithChildren<{
		filterRes: IFilterSearchResult;
		filters: DataFilter[];
		toggleOnChange: (filterRes: IFilterSearchResult, key: string) => void;
	}>
> = ({ filterRes, filters, toggleOnChange }) => {
	const isValueInFilters = (value: string, key: string) => {
		const filterWithKey = filters.find((filter) => filter.key === key);

		if (!filterWithKey) {
			return false;
		}
		return filterWithKey.operands.some((operand) => operand.value === value);
	};

	const sortedFields = sortBy(
		filterRes.dataSourceFields,
		(f1) => sortedMapping[f1.direction]
	);

	const getBtnColor = (key: string) => {
		const hasBeenAdded = isValueInFilters(filterRes.operand.value, key);

		return hasBeenAdded ? 'primary' : 'white-alt';
	};

	const getSearchFieldLabel = (field: SearchField) => {
		let label: string = field.direction;

		if (field.direction === CallDirectionPreset.Either) {
			label = 'Apply';
		}

		return label;
	};

	return (
		<ButtonGroup size="sm" className="align-self-center">
			{sortedFields.map((field) => (
				<Button
					type="button"
					key={field.key + field.direction}
					variant={getBtnColor(field.key)}
					onClick={() => toggleOnChange(filterRes, field.key)}
				>
					{(field.direction === CallDirectionPreset.Ingress ||
						field.direction === CallDirectionPreset.Customer) && (
						<i className="fas fa-share fa-rotate-90" />
					)}
					{(field.direction === CallDirectionPreset.Egress ||
						field.direction === CallDirectionPreset.Vendor) && (
						<i className="fas fa-share fa-rotate-270" />
					)}
					<span>&nbsp;{getSearchFieldLabel(field)}</span>
				</Button>
			))}
		</ButtonGroup>
	);
};
