/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocActionItem = {
    properties: {
        code: {
            type: 'string',
            isRequired: true,
        },
        rejectReasonId: {
            type: 'number',
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        rejectLabel: {
            type: 'string',
            isRequired: true,
        },
        isRejection: {
            type: 'boolean',
            isRequired: true,
        },
        systemUseOnly: {
            type: 'boolean',
            isRequired: true,
        },
        isIncoming: {
            type: 'boolean',
            isRequired: true,
        },
        isOutgoing: {
            type: 'boolean',
            isRequired: true,
        },
    },
};