import { components, GroupBase, MenuListProps } from 'react-select';
import { ReactSelectProps } from 'RtUi/components/ui/SelectFormControl';

type IMenuListProps<T, IsMulti extends boolean = false> = MenuListProps<
	T,
	IsMulti,
	GroupBase<T>
> & {
	selectProps: ReactSelectProps<T, IsMulti>;
};

export const MenuList = <T, IsMulti extends boolean = false>({
	children,
	...props
}: IMenuListProps<T, IsMulti>) => {
	return (
		<components.MenuList<T, IsMulti, GroupBase<T>> {...props}>
			{
				Array.isArray(children)
					? children.slice(0, props.selectProps.maxOptions) // Options
					: children // NoOptionsLabel
			}
		</components.MenuList>
	);
};
