/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NumberIndexRequest = {
    properties: {
        resourceId: {
            type: 'number',
        },
        numberType: {
            type: 'number',
            maximum: 2,
            minimum: 1,
        },
        inTypes: {
            type: 'Array',
        },
        notInTypes: {
            type: 'Array',
        },
        npa: {
            type: 'string',
        },
        label: {
            type: 'string',
        },
        connectionId: {
            type: 'string',
        },
        resRef1: {
            type: 'string',
        },
        accountId: {
            type: 'string',
        },
        orderId: {
            type: 'string',
        },
        status: {
            type: 'string',
        },
        search: {
            type: 'string',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};