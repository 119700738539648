import {
	GuardianReconciliationChargeDetailCreateRequest,
	GuardianReconciliationChargeDetailIndexResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const getReconciliationChargeColumns = (): Array<
	DataGridColumn<GuardianReconciliationChargeDetailIndexResponse>
> => [
	PrecisionDataGridColumn({
		accessorKey: 'disputedAmount',
		header: 'Disputed Amount'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'approvedAmount',
		header: 'Approved Amount'
	}),
	IntegerDataGridColumn({
		accessorKey: 'disputeId',
		header: 'Dispute Id'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'disputeFiledTs',
		header: 'Dispute Filed'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'disputeSettlementTs',
		header: 'Dispute Settlement'
	}),
	DefaultDataGridColumn({
		accessorKey: 'assignedToUserEmail',
		header: 'Assigned To'
	}),
	BooleanDataGridColumn({
		accessorKey: 'systemGenerated',
		header: 'System Generated'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updateTs',
		header: 'Last Updated'
	})
];

export const getReconciliationChargeCreateColumns = (): Array<
	DataGridColumn<GuardianReconciliationChargeDetailCreateRequest>
> => [
	DefaultDataGridColumn({
		accessorKey: 'invoiceItemId',
		header: 'Invoice Item Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'internalItemId',
		header: 'Internal Item Id'
	}),
	DefaultDataGridColumn({
		accessorKey: 'internalItemLabel',
		header: 'Internal Item Label'
	}),
	DefaultDataGridColumn({
		accessorKey: 'recurring',
		header: 'Recurring'
	}),
	DefaultDataGridColumn({
		accessorKey: 'carrierInvoiceCharge',
		header: 'Account Invoice Charge'
	}),
	DefaultDataGridColumn({
		accessorKey: 'expectedCharge',
		header: 'Expected Charge'
	}),
	DefaultDataGridColumn({
		accessorKey: 'carrierInvoiceChargeDetail',
		header: 'Account Invoice Charge Detail'
	})
];
