import {
	GuardianAlertIndexResponse,
	GuardianAlertRecipient,
	GuardianAlertScheduleIndexResponse
} from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AlertTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AlertTypeDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { ConditionsDataGridColumn } from 'RtUi/components/data/DataGrid/columns/ConditionsDataGridColumn';
import { DateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DateDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { MessageTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/MessageTypeDataGridColumn';
import { UserNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/UserNameDataGridColumn';

export const getAlertRulesColumns = (): Array<
	DataGridColumn<GuardianAlertIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'guardianAlertId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Summary'
	}),
	AlertTypeDataGridColumn({
		accessorKey: 'alertTypeId',
		header: 'Alert Type'
	}),
	ConditionsDataGridColumn({
		accessorKey: 'rules',
		header: 'Conditions'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DateDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	DateDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated'
	})
];

export const getAlertRuleRecipientsColumns = (
	removeEntity: (row: GuardianAlertRecipient) => void
): Array<DataGridColumn<GuardianAlertRecipient>> => [
	DeleteDataGridColumn({
		onClick: (row) => {
			removeEntity(row);
		}
	}),
	IdDataGridColumn({
		accessorKey: 'guardianAlertRecipientId'
	}),
	UserNameDataGridColumn({
		accessorKey: 'userId',
		header: 'User'
	}),
	MessageTypeDataGridColumn({
		accessorKey: 'messageTypeIds',
		header: 'Message Type'
	})
];

export const getAlertRuleSchedulesColumns = (
	removeEntity: (row: GuardianAlertScheduleIndexResponse) => void
): Array<DataGridColumn<GuardianAlertScheduleIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: (row) => {
			removeEntity(row);
		}
	}),
	IdDataGridColumn({
		accessorKey: 'guardianAlertScheduleId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'timezone',
		header: 'Time-Zone'
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),
	DefaultDataGridColumn({
		accessorKey: 'startTime',
		header: 'Start Time'
	}),
	DefaultDataGridColumn({
		accessorKey: 'endTime',
		header: 'End Time'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isSunday',
		header: 'Sun'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isMonday',
		header: 'Mon'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isTuesday',
		header: 'Tue'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isWednesday',
		header: 'Wed'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isThursday',
		header: 'Thr'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isFriday',
		header: 'Fri'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isSaturday',
		header: 'Sat'
	})
];
