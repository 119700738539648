import { RtVueModuleRouter } from '../RtVue.router';
import { RtVueApiRoutes } from 'RtExports/routes';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import {
	GuardianSubscriptionCicIndexResponse,
	GuardianSubscriptionCicProfileResponse
} from 'RtModels';

interface ISubscriptionCicProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const SubscriptionCicProfileContainerTabs: ISubscriptionCicProfileContainerTabs =
	{
		Profile: {
			header: 'Profile'
		}
	};

class SubscriptionCicRouterClass extends RtVueModuleRouter<
	GuardianSubscriptionCicIndexResponse,
	GuardianSubscriptionCicProfileResponse,
	ISubscriptionCicProfileContainerTabs
> {
	constructor() {
		super(
			'Subscription CIC',
			'subscriptionCic',
			'guardianSubscriptionCicId',
			SubscriptionCicProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtVueApiRoutes.GuardianSubscriptionCic);
	}

	public getPluralName() {
		return this.getName();
	}

	public getProfileLabel(
		profile: GuardianSubscriptionCicProfileResponse
	): string {
		return profile.cic;
	}
}

export const SubscriptionCicRouter = new SubscriptionCicRouterClass();
