import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	GuardianSwitchFileStreamCreateRequest,
	GuardianSwitchFileStreamIndexResponse,
	GuardianSwitchFileStreamProfileRequest,
	GuardianSwitchFileStreamProfileResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class GuardianSwitchFileStreamsResource extends HttpResource<
	GuardianSwitchFileStreamIndexResponse,
	GuardianSwitchFileStreamProfileResponse
> {
	constructor() {
		super(RtCommonApiRoutes.GuardianSwitchFileStream);
	}

	public getProfile(
		switchId: number
	): Promise<GuardianSwitchFileStreamProfileResponse> {
		return this.fetchWithRoute(
			RtCommonApiRoutes.GuardianSwitchFileStream.Profile,
			{
				urlParams: {
					switchId
				}
			}
		);
	}

	public create(req: GuardianSwitchFileStreamCreateRequest) {
		const body = JSON.stringify(req);

		return this.fetchWithRoute(
			RtCommonApiRoutes.GuardianSwitchFileStream.Create,
			{
				body
			}
		);
	}

	public update(
		guardianSwitch: GuardianSwitchFileStreamProfileResponse
	): Promise<GuardianSwitchFileStreamProfileResponse> {
		const { guardianSwitchFileStreamId, ...req } = guardianSwitch;
		const urlParams = {
			guardianSwitchFileStreamId
		};
		const body = JSON.stringify(req);

		return this.fetchWithRoute(
			RtCommonApiRoutes.GuardianSwitchFileStream.Update,
			{
				urlParams,
				body
			}
		);
	}

	public delete(guardianSwitchFileStreamId: number) {
		const urlParams: GuardianSwitchFileStreamProfileRequest = {
			guardianSwitchFileStreamId
		};

		return this.fetchWithRoute<GuardianSwitchFileStreamProfileResponse>(
			RtCommonApiRoutes.GuardianSwitchFileStream.Delete,
			{ urlParams }
		);
	}
}
