/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ManagedEntityIndexRequest = {
    properties: {
        entityId: {
            type: 'string',
        },
        search: {
            type: 'string',
        },
        isActive: {
            type: 'number',
        },
        isManaged: {
            type: 'number',
        },
        isFailing: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};