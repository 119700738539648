import { FC, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useMount } from 'react-use';
import { NumberGroupProfileResponse } from 'RtModels';
import { NumberGroupsNumbersGrid } from 'RtUi/app/rt800/NumberGroups/lib/grids/NumberGroupsNumbersGrid';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { TaskAction } from '../Tasks/lib/components/TaskAction';
import { NumberGroupEditor } from './lib/forms/NumberGroupEditor';
import { NumberGroupResource } from './lib/resources/NumberGroupResource';
import { NumberGroupRouter } from './NumberGroup.router';

export const NumberGroupProfileContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const numberGroupId = useIdParam();
	const Tabs = NumberGroupRouter.getProfileTabs();
	const [activeTab, setActiveTab] = useState<string>(Tabs.Profile.header);
	const [numberGroup, setNumberGroup] = useState<NumberGroupProfileResponse>();

	useMount(() => {
		const numberGroupResource = new NumberGroupResource();
		async function getNumberGroup() {
			const nb = await numberGroupResource.get(numberGroupId);
			setNumberGroup(nb);
		}

		getNumberGroup();
	});

	if (!numberGroup) {
		return null;
	}

	return (
		<TabbedLayout<NumberGroupProfileResponse>
			router={NumberGroupRouter}
			profile={numberGroup}
			activeTab={activeTab}
			onTabChange={(activeTab) => {
				setActiveTab(activeTab);
			}}
		>
			<TabbedLayoutTab {...Tabs.Profile}>
				<NumberGroupEditor
					narrow
					profile={numberGroup}
					onSuccess={(newNumberGroup) => {
						setNumberGroup(newNumberGroup);
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...Tabs.Numbers}>
				<NumberGroupsNumbersGrid
					resourceParams={{ numberGroupId: numberGroup.numberGroupId }}
				/>
			</TabbedLayoutTab>
			{NumberGroupRouter.getActionTabs().map((actionTab) => (
				<TabbedLayoutTab {...actionTab} key={actionTab.header}>
					<Row>
						<Col md={6}>
							<TaskAction
								defaultNumberGroupId={numberGroup.numberGroupId}
								defaultRespOrgId={numberGroup.respOrgId}
								type={actionTab.header}
							/>
						</Col>
					</Row>
				</TabbedLayoutTab>
			))}

			<TabbedLayoutHelpContainer tab={Tabs.Profile.header}>
				<p>
					Based on permissions you may have the ability to modify summary
					description RespOrg, and status.
				</p>
				<Alert variant="warning">
					<i className="fas fa-fw fa-sticky-note" />
					<span>
						&nbsp;User access to this Number Group is restricted based on the
						RespOrg id you select.
					</span>
				</Alert>
			</TabbedLayoutHelpContainer>
			<TabbedLayoutHelpContainer tab={Tabs.Audit.header}>
				<p>Your defined list of numbers to perform tasks against.</p>
			</TabbedLayoutHelpContainer>
			<TabbedLayoutHelpContainer tab={Tabs.Profile.header}>
				<p>Create a snapshot of number statuses for future comparison use.</p>
			</TabbedLayoutHelpContainer>
		</TabbedLayout>
	);
};

NumberGroupRouter.setProfileRtUiFunctionalComponent(
	NumberGroupProfileContainer
);
