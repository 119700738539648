/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FirebaseMessages = {
    properties: {
        __api_prop__: {
            properties: {
            },
            isRequired: true,
        },
    },
};