import {
	IRouterBreadcrumb,
	RtUiRouter
} from 'RtUi/components/containers/lib/RtUiRouter';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';

export abstract class RtCarrierConnectModuleRouter<
	IndexType,
	ProfileType,
	TabType extends IProfileApplicationContainerTabs
> extends RtUiRouter<IndexType, ProfileType, TabType> {
	public static readonly SectionKey = 'rtCarrierConnect';

	constructor(
		name: string,
		containerKey: string,
		propertyKey: IndexType extends void ? undefined : keyof IndexType,
		profileTabs: TabType
	) {
		super(
			RtCarrierConnectModuleRouter.SectionKey,
			name,
			containerKey,
			propertyKey,
			profileTabs
		);
	}

	/**
	 * Prepend section breadcrumb
	 * @param currentPath
	 * @param profile
	 */
	public async getBreadcrumbs(
		currentPath: string,
		profile?: ProfileType
	): Promise<IRouterBreadcrumb[]> {
		const resourcePath = localStorage.getItem('activeTab');
		let breadcrumbs = await super.getBreadcrumbs(currentPath, profile);
		if (resourcePath === 'Connections') {
			breadcrumbs = [
				{
					isActive: true,
					name: 'Connections',
					path: '/rtCarrierConnect/resources'
				}
			];
		}
		return [this.getSectionBreadcrumb(), ...breadcrumbs];
	}

	/**
	 * Section's breadcrumb
	 */
	protected getSectionBreadcrumb() {
		const sectionBreadcrumb: IRouterBreadcrumb = {
			name: 'RT/800 Carrier Connect',
			path: '',
			isActive: true
		};

		return sectionBreadcrumb;
	}
}
