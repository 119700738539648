/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { FileProcessIndexRequest, FileProcessIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchLrnLookup = async (
	queryParams: FileProcessIndexRequest
): Promise<FullResponse<FileProcessIndexResponse[]>> => {
	return handleGetRequest(RtCommonApiRoutes.FileProcess.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetLrnLookup = (queryParams: FileProcessIndexRequest) => {
	return useQuery<FullResponse<FileProcessIndexResponse[]>, Error>(
		[`getLrnLookup`, queryParams],
		() => fetchLrnLookup(queryParams),
		{ enabled: true }
	);
};
