import { SearchField, DataFilterOperator, DataFilter } from 'RtModels';
import {
	IFilterSearchResults,
	RtVueFilterSearch
} from 'RtUi/app/rtVue/common/lib/http/RtVueFilterSearch';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IFilterSearchResult } from 'RtUi/app/rtVue/common/lib/http/RtVueFilter';
import { VueSearchResults } from 'RtUi/app/rtVue/common/lib/components/VueSearchResults';
import { cloneDeep, find, isNil } from 'lodash-es';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { Component } from 'react';
import { asyncDebounce } from 'RtUi/utils/promises';
import OutsideClickDetector from 'RtUi/app/rtVue/common/lib/components/OutsideClickDetector';
import clsx from 'clsx';

interface IVueFilterSearchPanelProps {
	filters: DataFilter[];
	searchFields: SearchField[];
	onChange: (value: DataFilter[]) => void;
	className?: string;
}

interface IVueFilterSearchPanelState {
	search: string;
	searchResults: IFilterSearchResults | undefined;
	error?: any;
}

export class VueFilterSearchPanel extends Component<
	IVueFilterSearchPanelProps,
	IVueFilterSearchPanelState
> {
	public state: IVueFilterSearchPanelState = {
		search: '',
		searchResults: undefined
	};

	/**
	 * User for searching
	 */
	public vueFilterSearch = RtVueFilterSearch.getInstance();

	private search = asyncDebounce(
		(searchStr: string, searchFields: SearchField[]) =>
			this.vueFilterSearch.search(searchStr, searchFields),
		150
	);

	/**
	 * Handling search input change here
	 * @param {string} search
	 * @returns {Promise<void>}
	 */
	public async updateInputSearch(search: string) {
		if (this.state.error) {
			this.setState({ error: undefined });
		}

		if (search.length <= 0) {
			this.setState({ searchResults: undefined });
		}

		this.setState(() => ({ search }));
		const results = await this.search(search, this.props.searchFields);
		this.setState({ searchResults: results });
	}

	/**
	 * Toggles a filter result when clicked.
	 * @param filterRes
	 * @param key
	 */
	public toggleOnChange = (filterRes: IFilterSearchResult, key: string) => {
		const newFilters = cloneDeep(this.props.filters);
		const filterToUpdate = newFilters.find((v) => v.key === key);
		const searchField = find(this.props.searchFields, { key });

		if (filterToUpdate) {
			const operandIndex = filterToUpdate.operands.findIndex(
				(operand) => operand.value === filterRes.operand.value
			);

			if (operandIndex >= 0) {
				//Remove
				filterToUpdate.operands.splice(operandIndex, 1);
			} else {
				//Add
				filterToUpdate.operands.push(filterRes.operand);
			}
		} else {
			const operator = searchField?.operator ?? DataFilterOperator.In;

			newFilters.push({
				key,
				operator,
				operands: [filterRes.operand]
			});
		}

		this.props.onChange(newFilters);
		this.setState({ searchResults: undefined });
	};

	public render() {
		return (
			<OutsideClickDetector
				className={clsx('position-relative', this.props.className)}
				onClickOutside={() => {
					this.setState({ searchResults: undefined });
				}}
			>
				<RtUiForm
					hideButtons
					disablePadding
					disableBody
					noValidate
					error={this.state.error}
				>
					<InputFormControl
						label=""
						placeholder="E.g. Moved Permanently"
						useControlGroup={false}
						onFocus={() => {
							if (!isNil(this.state.search)) {
								this.updateInputSearch(this.state.search);
							}
						}}
						onChange={(searchStr) => this.updateInputSearch(searchStr)}
						value={this.state.search}
					/>
				</RtUiForm>
				<VueSearchResults
					searchResults={this.state.searchResults}
					filters={this.props.filters}
					toggleOnChange={(filters, key) => {
						this.toggleOnChange(filters, key);
						this.setState({ search: '' });
					}}
				/>
			</OutsideClickDetector>
		);
	}
}
