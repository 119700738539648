/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RateLoadNewRateIndexResponse = {
    properties: {
        rateLoadNewRateId: {
            type: 'number',
            isRequired: true,
        },
        rateLoadId: {
            type: 'number',
            isRequired: true,
        },
        srcRateKey: {
            type: 'string',
            isRequired: true,
        },
        srcNpa: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        srcNxx: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        srcLata: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        srcOcn: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        srcState: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        srcSummary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        srcInterRate: {
            type: 'number',
            isRequired: true,
        },
        srcIntraRate: {
            type: 'number',
            isRequired: true,
        },
        srcIndetRate: {
            type: 'number',
            isRequired: true,
        },
        srcLocalRate: {
            type: 'number',
            isRequired: true,
        },
        srcDipFee: {
            type: 'number',
            isRequired: true,
        },
        srcConFee: {
            type: 'number',
            isRequired: true,
        },
        srcDurMin: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        srcDurInc: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        srcStartTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        srcEndTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        contractStartTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        contractEndTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        useRateKey: {
            type: 'string',
            isRequired: true,
        },
        useCountryCode: {
            type: 'string',
            isRequired: true,
        },
        useIso3: {
            type: 'string',
            isRequired: true,
        },
        useRateCenterId: {
            type: 'number',
            isRequired: true,
        },
        useSummary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        useSummaryId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        useStartTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        useStartTsSourceId: {
            type: 'number',
            isRequired: true,
        },
        useEndTs: {
            type: 'Date',
            isRequired: true,
            isNullable: true,
            format: 'date-time',
        },
        useEndTsSourceId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        useDurMin: {
            type: 'number',
            isRequired: true,
        },
        useDurInc: {
            type: 'number',
            isRequired: true,
        },
        useRateDiffAmt: {
            type: 'number',
            isRequired: true,
        },
        useRateDiffPct: {
            type: 'number',
            isRequired: true,
        },
        matchingRateId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rateKeyStateId: {
            type: 'number',
            isRequired: true,
        },
        rateChangeTypeId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        rateChangeSummary: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        interDiffAmt: {
            type: 'number',
            isRequired: true,
        },
        interDiffPct: {
            type: 'number',
            isRequired: true,
        },
        intraDiffAmt: {
            type: 'number',
            isRequired: true,
        },
        intraDiffPct: {
            type: 'number',
            isRequired: true,
        },
        indetDiffAmt: {
            type: 'number',
            isRequired: true,
        },
        indetDiffPct: {
            type: 'number',
            isRequired: true,
        },
        localDiffAmt: {
            type: 'number',
            isRequired: true,
        },
        localDiffPct: {
            type: 'number',
            isRequired: true,
        },
        rateLoadErrorTypeId: {
            type: 'RateLoadErrorType',
            isRequired: true,
            isNullable: true,
        },
    },
};