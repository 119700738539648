/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrTaskCarrierResultIndexResponse = {
    properties: {
        lcrTaskCarrierResultId: {
            type: 'number',
            isRequired: true,
        },
        lcrTaskResultId: {
            type: 'number',
            isRequired: true,
        },
        lcrCarrierId: {
            type: 'number',
            isRequired: true,
        },
        cic: {
            type: 'string',
            isRequired: true,
        },
        minutes: {
            type: 'number',
            isRequired: true,
        },
        charges: {
            type: 'number',
            isRequired: true,
        },
        canadaMinutes: {
            type: 'number',
            isRequired: true,
        },
        canadaCharges: {
            type: 'number',
            isRequired: true,
        },
        nonLcrMinutes: {
            type: 'number',
            isRequired: true,
        },
        nonLcrCharges: {
            type: 'number',
            isRequired: true,
        },
        unknownMinutes: {
            type: 'number',
            isRequired: true,
        },
        unknownCharges: {
            type: 'number',
            isRequired: true,
        },
        totalMinutes: {
            type: 'number',
            isRequired: true,
        },
        totalCharges: {
            type: 'number',
            isRequired: true,
        },
    },
};