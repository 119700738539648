import {
	SubscriptionScheduleIndexResponse,
	SubscriptionScheduleProfileResponse
} from 'RtModels';
import { AddScheduleAside } from 'RtUi/app/AccountManagement/Subscriptions/lib/components/AddScheduleAside';
import { SubscriptionScheduleHttp } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionScheduleHttp';
import { useGetSubscriptionSchedules } from 'RtUi/app/AccountManagement/Subscriptions/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getSubscriptionScheduleColumns } from 'RtUi/components/data/DataGrid/configurations/accountManagement/subscriptions';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { WarningAlert } from 'RtUi/components/ui/WarningAlert';
import { noop } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ISubscriptionScheduleGridProps {
	subscriptionId: number;
	autoFocusFilter?: boolean;
	onUpdate?: () => void;
}

export const SubscriptionScheduleDataGrid = ({
	subscriptionId,
	onUpdate = noop
}: ISubscriptionScheduleGridProps) => {
	const [isAddAsideOpen, setIsAddAsideOpen] = useState<boolean>(false);
	const [selected, setSelected] =
		useState<SubscriptionScheduleProfileResponse>();
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetSubscriptionSchedules({ subscriptionId });

	const deleteSchedule = useCallback(
		async (row: SubscriptionScheduleIndexResponse) => {
			const { subscriptionScheduleId } = row;

			const confirm = await Confirmation.createDelete(
				'selected subscription schedule'
			);

			if (!confirm) {
				return;
			}

			await new SubscriptionScheduleHttp().delete(subscriptionScheduleId);
			refetch();
			onUpdate();
		},
		[refetch, onUpdate]
	);

	const columns = useMemo(
		() => getSubscriptionScheduleColumns(deleteSchedule),
		[deleteSchedule]
	);

	return (
		<>
			<WarningAlert text="Schedules are ONLY enforced during the execution of a a Tag Matching Scenario." />
			<DataGrid<SubscriptionScheduleIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'accountManagement_subscriptionSchedules'}
				rowThemeColor={(record) =>
					record.subscriptionScheduleId === selected?.subscriptionScheduleId
						? RowThemeColor.WARNING
						: undefined
				}
				onRowClick={async (row) => {
					const profile = await new SubscriptionScheduleHttp().get(
						row.subscriptionScheduleId
					);

					setSelected(profile);
					setIsAddAsideOpen(true);
				}}
				headerAction={() => {
					return (
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id={`addResourceBtn-tooltip`} {...props}>
									Create Schedule
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									onClick={() => setIsAddAsideOpen(true)}
									variant="white"
									id="addResourceBtn"
								>
									<i className="fas fa-fw fa-plus fa-lg" />
								</Button>
							)}
						</OverlayTrigger>
					);
				}}
			/>
			<AddScheduleAside
				subscriptionId={subscriptionId}
				profile={selected}
				isOpen={isAddAsideOpen}
				onCancel={() => {
					setIsAddAsideOpen(false);
					setSelected(undefined);
				}}
				onSave={() => {
					setIsAddAsideOpen(false);
					setSelected(undefined);
					refetch();
					onUpdate();
				}}
			/>
		</>
	);
};
