/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoutingSummaryIndexRequest = {
    properties: {
        startDate: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endDate: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        revenueAccountId: {
            type: 'Array',
        },
        revenueSubscriptionId: {
            type: 'Array',
        },
        costAccountId: {
            type: 'Array',
        },
        costSubscriptionId: {
            type: 'Array',
        },
        destinationCountries: {
            type: 'Array',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};