/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $InvoiceSubscriptionProfileResponse = {
    properties: {
        subscriptionId: {
            type: 'number',
            isRequired: true,
        },
        serviceId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        rate: {
            type: 'number',
            isRequired: true,
        },
        connects: {
            type: 'number',
            isRequired: true,
        },
        duration: {
            type: 'number',
            isRequired: true,
        },
        charges: {
            type: 'number',
            isRequired: true,
        },
        margin: {
            type: 'number',
            isRequired: true,
        },
    },
};