/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum RocRequestTypeId {
    Future = 1,
    Emergency = 2,
    Expedite = 3,
}