import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { TimezoneIndexResponse } from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';

export class TimezoneResource extends ArrayResource<TimezoneIndexResponse> {
	constructor() {
		super('timezoneId');

		this.setApiRouteForGetAll(RtCommonApiRoutes.Timezone.Index);
	}
}
