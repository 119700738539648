/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipCallSimulatorResponseDtoPhoneNumber = {
    properties: {
        src: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        std: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        lrn: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        subscriber: {
            type: 'string',
            isRequired: true,
        },
        iso: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        rateCenterId: {
            type: 'number',
            isRequired: true,
            isNullable: true,
        },
        isIddd: {
            type: 'boolean',
            isRequired: true,
        },
        isNanp: {
            type: 'boolean',
            isRequired: true,
        },
        isExtendedNanp: {
            type: 'boolean',
            isRequired: true,
        },
        isTollFree: {
            type: 'boolean',
            isRequired: true,
        },
        lata: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        subLata: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        ocn: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        city: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        state: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isValid: {
            type: 'boolean',
            isRequired: true,
        },
    },
};