import { throttle } from 'lodash-es';
import { Component, createRef, RefObject } from 'react';
import { hasParentWithClassName } from 'RtUi/utils/react/DomHelpers';

interface IDropdownOnBodyProps {
	selectDOM: HTMLElement;
	isOpen: boolean;
	toggle: () => void;
}

interface IDropdownOnBodyState {
	alignTop: boolean;
}

export class DropdownOnBody extends Component<
	React.PropsWithChildren<IDropdownOnBodyProps>,
	IDropdownOnBodyState
> {
	public state: IDropdownOnBodyState = {
		alignTop: false
	};

	public dropdownBodyRef: RefObject<HTMLDivElement>;
	public handScrollFN = throttle(() => this.handleScroll(), 3, {
		leading: false
	});

	constructor(props: IDropdownOnBodyProps) {
		super(props);

		this.dropdownBodyRef = createRef();
	}

	public componentDidMount() {
		window.addEventListener('scroll', this.handScrollFN, true);
		window.addEventListener('resize', this.handScrollFN, true);
		window.addEventListener('mousedown', this.handleClick, true);

		this.checkIfSelectMenuIsInViewport();
	}

	public componentWillUnmount() {
		window.removeEventListener('scroll', this.handScrollFN, true);
		window.removeEventListener('resize', this.handScrollFN, true);
		window.removeEventListener('mousedown', this.handleClick, true);
	}

	public handleScroll() {
		// just need to re-render on scroll so that the menu is not stuck in mid-air while the select input scrolls off-screen
		this.forceUpdate(() => this.checkIfSelectMenuIsInViewport());
	}

	public handleClick = (e: MouseEvent) => {
		if (!this.props.isOpen) {
			return;
		}

		const clickedTarget = e.target as HTMLElement;
		const wasTargetSelectedDOM = this.props.selectDOM === clickedTarget;
		const wasTargetWithinDropdown = hasParentWithClassName(
			clickedTarget,
			'dropdown-on-body-container'
		);

		if (!wasTargetSelectedDOM && !wasTargetWithinDropdown) {
			this.props.toggle();
		}
	};

	public checkIfSelectMenuIsInViewport() {
		if (!this.dropdownBodyRef.current) {
			return;
		}

		const boundingRect = this.dropdownBodyRef.current.getBoundingClientRect();
		const viewportHeight =
			window.innerHeight || document.documentElement.clientHeight;

		//Otherwise it will cause an infinite loop
		if (boundingRect.height > viewportHeight / 2) {
			return;
		}

		if (this.state.alignTop) {
			if (boundingRect.top < 0) {
				this.setState({ alignTop: false });
			}
		} else {
			if (boundingRect.bottom > viewportHeight) {
				this.setState({ alignTop: true });
			}
		}
	}

	public getTopDimension(selectDomBoundingRec: ClientRect | DOMRect) {
		const { alignTop } = this.state;
		const top = selectDomBoundingRec.bottom - 1;

		if (alignTop && this.dropdownBodyRef.current) {
			const selectHeight = 34;
			const selectMenuRect =
				this.dropdownBodyRef.current.getBoundingClientRect();
			return top - selectMenuRect.height - selectHeight;
		}

		return top;
	}

	public render() {
		const { selectDOM, isOpen } = this.props;
		const rect = selectDOM.getBoundingClientRect();
		const computedStyle = window.getComputedStyle(selectDOM); // inherit as many styles as you want from the parent select
		const fontSize = computedStyle.getPropertyValue('font-size');
		const lineHeight = computedStyle.getPropertyValue('line-height');
		const containerClassNames = ['dropdown-on-body-container'];
		const styles: React.CSSProperties = {
			fontSize,
			lineHeight,
			left: rect.left,
			//width: rect.width,
			top: this.getTopDimension(rect)
		};

		if (isOpen) {
			containerClassNames.push('show');
		}

		return (
			<div
				className={containerClassNames.join(' ')}
				style={styles}
				ref={this.dropdownBodyRef}
			>
				{this.props.children}
			</div>
		);
	}
}
