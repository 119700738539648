import { SipAgentConfigIndexResponse } from 'RtModels';
import { SipAgentConfigsResource } from 'RtUi/app/AccountManagement/SipAgents/lib/resources/SipAgentConfigsResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface SipAgentConfigLabelDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		SipAgentConfigIndexResponse,
		T
	> {}

export const SipAgentConfigLabelDataGridCache =
	new ResourceCacheMap<SipAgentConfigIndexResponse>(
		new SipAgentConfigsResource(),
		'sipAgentConfigId',
		'label'
	);

export const SipAgentConfigLabelDataGridColumn = <T = any,>(
	config: SipAgentConfigLabelDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: SipAgentConfigLabelDataGridCache,
		...config
	});
};
