/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CprRateSheetResultsProfileResponse = {
    properties: {
        id: {
            type: 'number',
            isRequired: true,
        },
        cprRateSheetId: {
            type: 'number',
            isRequired: true,
        },
        rateKey: {
            type: 'string',
            isRequired: true,
        },
        rateKey1: {
            type: 'string',
            isRequired: true,
        },
        rateKey2: {
            type: 'string',
            isRequired: true,
        },
        rateKey3: {
            type: 'string',
            isRequired: true,
        },
        rateKey4: {
            type: 'string',
            isRequired: true,
        },
        state: {
            type: 'string',
            isRequired: true,
        },
        country: {
            type: 'string',
            isRequired: true,
        },
        cprRowIndex: {
            type: 'number',
            isRequired: true,
        },
        cic: {
            type: 'string',
            isRequired: true,
        },
        interRate: {
            type: 'number',
            isRequired: true,
        },
        intraRate: {
            type: 'number',
            isRequired: true,
        },
        indetRate: {
            type: 'number',
            isRequired: true,
        },
        interCost: {
            type: 'number',
            isRequired: true,
        },
        intraCost: {
            type: 'number',
            isRequired: true,
        },
        indetCost: {
            type: 'number',
            isRequired: true,
        },
        totalMins: {
            type: 'number',
            isRequired: true,
        },
        totalCost: {
            type: 'number',
            isRequired: true,
        },
        costPerMinute: {
            type: 'number',
            isRequired: true,
        },
    },
};