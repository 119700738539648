/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TrackingGroupCreateRequest = {
    properties: {
        label: {
            type: 'string',
            isRequired: true,
        },
        playLimit: {
            type: 'number',
            isRequired: true,
        },
        dialLimit: {
            type: 'number',
            isRequired: true,
        },
        expirationSeconds: {
            type: 'number',
            isRequired: true,
        },
    },
};