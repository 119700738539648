import {
	AccountIndexResponse,
	CountryIndexResponse,
	NetworkEfficiencyColumns,
	NetworkEfficiencyIndexRequest,
	RateCenterIndexResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { AccountSelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/AccountSelect';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { NetworkEfficiencyRouter } from 'RtUi/app/rtSip/NetworkEfficiency/NetworkEfficiency.router';
import { NetworkEfficiencyDataGrid } from 'RtUi/app/rtSip/NetworkEfficiency/lib/grids/NetworkEfficiencyDataGrid';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { SliderFormControl } from 'RtUi/components/form/SliderFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

interface IInitialResourceParams {
	startTs: moment.Moment;
	endTs: moment.Moment;
	countries?: CountryIndexResponse[];
	revenueAccounts?: AccountIndexResponse[];
	revenueSubscriptions?: SubscriptionIndexResponse[];
	rateCenters?: RateCenterIndexResponse[];
	columns?: Array<ISimpleSelectFormControlOption<NetworkEfficiencyColumns>>;
	minimumAttempts?: number;
	nerBelow: number;
}

interface INetworkEfficiencyIndexContainerState
	extends ISearchResultsContainerState<NetworkEfficiencyIndexRequest>,
		IInitialResourceParams {}

@NetworkEfficiencyRouter.getNetworkReportsIndexRtUiController()
export class NetworkEfficiencyIndexContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	INetworkEfficiencyIndexContainerState,
	NetworkEfficiencyIndexRequest
> {
	public initParams: IInitialResourceParams = {
		startTs: moment(),
		endTs: moment().endOf('day'),
		minimumAttempts: 25,
		nerBelow: 90
	};

	public state: INetworkEfficiencyIndexContainerState = {
		...this.initParams,
		activeTab: this.tabs.Search,
		resourceParams: {
			startTs: startOfDay(new Date()),
			endTs: endOfDay(new Date())
		}
	};

	public Tabs = NetworkEfficiencyRouter.getProfileTabs();

	constructor(props: {}) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams(): NetworkEfficiencyIndexRequest {
		const {
			startTs,
			endTs,
			revenueSubscriptions,
			revenueAccounts,
			countries,
			rateCenters,
			columns,
			minimumAttempts,
			nerBelow
		} = this.state;

		const params: NetworkEfficiencyIndexRequest = {
			startTs: startTs.toDate(),
			endTs: endTs.toDate()
		};

		if (revenueSubscriptions) {
			params.revenueSubscriptionIds = revenueSubscriptions.map(
				(subscription) => subscription.subscriptionId
			);
		}

		if (revenueAccounts) {
			params.revenueAccountIds = revenueAccounts.map(
				(account) => account.accountId
			);
		}

		if (rateCenters) {
			params.rateCenterIds = rateCenters.map(
				(rateCenter) => rateCenter.rateCenterId
			);
		}

		if (countries) {
			params.countryIsoIds = countries.map((country) => country.iso3166Alpha3);
		}

		if (columns) {
			params.columns = columns.map((column) => column.value);
		}

		if (minimumAttempts) {
			params.minimumAttempts = minimumAttempts;
		}

		params.nerBelow = nerBelow;

		return params;
	}

	public render() {
		return (
			<TabbedLayout
				router={NetworkEfficiencyRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm wide onSubmit={(e) => this.submitAndGoToResults(e)}>
						<Row>
							<Col md={6}>
								<DatetimeFormControl
									required
									label="Start Date"
									showDateOnly={true}
									value={this.state.startTs}
									onChange={(startTs) => this.setState({ startTs })}
								/>
								<DatetimeFormControl
									required
									label="End Date"
									showDateOnly={true}
									value={this.state.endTs}
									onChange={(endTs) => this.setState({ endTs })}
								/>
								<SimpleSelectFormControl<NetworkEfficiencyColumns, true>
									label="Columns"
									multi
									required
									clearable={true}
									onChange={(columns) => this.setState({ columns })}
									value={this.state.columns}
									options={[
										{
											label: 'Account',
											value: NetworkEfficiencyColumns.RevenueAccountId
										},
										{
											label: 'Subscription',
											value: NetworkEfficiencyColumns.RevenueSubscriptionId
										},
										{
											label: 'Route Depth',
											value: NetworkEfficiencyColumns.RouteDepth
										},
										{
											label: 'Country',
											value: NetworkEfficiencyColumns.CountryIsoId
										},
										{
											label: 'Rate Center',
											value: NetworkEfficiencyColumns.RateCenterId
										}
									]}
								/>
								<InputFormControl
									type="number"
									label="Minimum Attempts"
									onChange={(minimumAttempts) =>
										this.setState({
											minimumAttempts: Number(minimumAttempts)
										})
									}
									value={String(this.state.minimumAttempts)}
								/>
								<SliderFormControl
									label="NER Below"
									onChange={(nerBelow) => this.setState({ nerBelow })}
									value={this.state.nerBelow}
								/>
							</Col>
							<Col md={6}>
								<AccountSelect<true>
									label="Vendors"
									value={this.state.revenueAccounts}
									onChange={(revenueAccounts) =>
										this.setState({ revenueAccounts })
									}
									multi
								/>
								<SubscriptionSelect<true>
									label="Subscriptions"
									value={this.state.revenueSubscriptions}
									onChange={(revenueSubscriptions) =>
										this.setState({ revenueSubscriptions })
									}
									isMulti
								/>
								<CountrySelect<true>
									isMulti
									label="Countries"
									value={this.state.countries}
									onChange={(countries) => this.setState({ countries })}
								/>
								<RateCenterSelect<true>
									multi
									label="Rate Centers"
									value={this.state.rateCenters}
									onChange={(rateCenters) => this.setState({ rateCenters })}
								/>
							</Col>
						</Row>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<NetworkEfficiencyDataGrid
						resourceParams={this.state.resourceParams}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
