import { useAppSelector } from 'RtUi/store/hooks';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';

interface IPrecisionDataGridRendererProps {
	value: any;
	columnId: string;
	showBlankIfEmpty: boolean;
	convertFromDecimal: boolean;
	template: string;
}

export const PrecisionDataGridRenderer = ({
	value,
	columnId,
	showBlankIfEmpty,
	convertFromDecimal,
	template
}: IPrecisionDataGridRendererProps) => {
	const precisionMap = useAppSelector(
		(state) => state.dataGrid.precisionColumn
	);

	const precision = useMemo(
		() => precisionMap[columnId] ?? 2,
		[precisionMap, columnId]
	);

	const currentValue = useMemo(() => {
		if (isNil(value) || (!value && showBlankIfEmpty)) {
			return '';
		}

		if (convertFromDecimal) {
			return template.replace(
				':value:',
				(value * 100).toFixed(precision).toLocaleString()
			);
		}

		const parsedValue = Number(value).toLocaleString('en-US', {
			maximumFractionDigits: precision,
			minimumFractionDigits: precision
		});

		return template.replace(':value:', parsedValue);
	}, [precision, convertFromDecimal, value, showBlankIfEmpty, template]);

	return <>{currentValue}</>;
};
