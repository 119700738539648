/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipTraceProfileResponseData = {
    properties: {
        alias: {
            properties: {
            },
            isRequired: true,
        },
        callData: {
            type: 'Array',
            isRequired: true,
        },
        hosts: {
            properties: {
            },
            isRequired: true,
        },
        messages: {
            type: 'Array',
            isRequired: true,
        },
    },
};