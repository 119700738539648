import { RoutingGroupIndexResponse } from 'RtModels';
import { RoutingTemplateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/RoutingTemplateDataGridColumn';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';

export const getRoutingGroupColumns = (): Array<
	DataGridColumn<RoutingGroupIndexResponse>
> => [
	...RespOrgColumnsBuilder<RoutingGroupIndexResponse>({
		accessorKey: 'respOrgId',
		header: 'RespOrg'
	}),
	DefaultDataGridColumn({
		accessorKey: 'summary',
		header: 'Label'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isActive',
		header: 'Active'
	}),
	RoutingTemplateDataGridColumn({
		accessorKey: 'currentRoutingTemplateTypeId',
		header: 'DR Mode'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedAt',
		header: 'Updated Time'
	})
];
