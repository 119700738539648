import { useParams } from 'react-router-dom';

/**
 * returns id param in location route
 */
export const useIdParam = (): string => {
	const { id } = useParams<{ id: string }>();

	return id || '';
};
