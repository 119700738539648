import {
	TemplateLcrScenarioIndexRequest,
	TemplateLcrScenarioIndexResponse
} from 'RtModels';
import { LcrScenariosAddTemplateAside } from 'RtUi/app/rtLco/Scenarios/components/LcrScenariosAddTemplateAside';
import {
	removeScenarioFromTemplate,
	useGetLcrTemplateScenarios
} from 'RtUi/app/rtLco/Templates/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLcrTemplateScenariosColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/lcrTemplates';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { noop } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useMutation } from 'react-query';

interface ILcrScenariosGridProps {
	resourceParams: TemplateLcrScenarioIndexRequest;
	autoFocusFilter?: boolean;
}

export const LcrTemplateScenariosGrid = ({
	resourceParams
}: ILcrScenariosGridProps) => {
	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetLcrTemplateScenarios(resourceParams);
	const [isAddScenarioAsideOpen, setIsAddScenarioAsideOpen] =
		useState<boolean>(false);
	const { mutateAsync } = useMutation(removeScenarioFromTemplate);

	const removeScenario = useCallback(
		async (carrier: TemplateLcrScenarioIndexResponse) => {
			const confirm = await Confirmation.createDelete(
				carrier.label,
				`<span> from </span>
			<span class="text-muted">${resourceParams.templateName}</span>. `
			);

			if (!confirm) {
				return;
			}

			await mutateAsync({
				templateName: resourceParams.templateName,
				templateLcrScenarioId: carrier.templateLcrScenarioId
			});

			refetch();
		},
		[resourceParams, mutateAsync, refetch]
	);

	const columns = useMemo(
		() => getLcrTemplateScenariosColumns(removeScenario),
		[removeScenario]
	);

	return (
		<>
			<DataGrid<TemplateLcrScenarioIndexResponse>
				pageName="rtLco-templateScenarios"
				loading={isLoading}
				data={data?.data}
				totalRows={data?.totalCount}
				columns={columns}
				headerAction={() => {
					return (
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id={`addScenarioToTemplateBtn-tooltip`} {...props}>
									Add Scenario to Template
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									onClick={() => setIsAddScenarioAsideOpen(true)}
									variant="white"
									id="addScenarioToTemplateBtn"
								>
									<i className="fas fa-fw fa-plus fa-lg" />
								</Button>
							)}
						</OverlayTrigger>
					);
				}}
			/>
			<LcrScenariosAddTemplateAside
				isOpen={isAddScenarioAsideOpen}
				onCancel={() => setIsAddScenarioAsideOpen(false)}
				templateName={resourceParams.templateName}
				onBeforeSave={noop}
				onSave={() => {
					refetch();
					setIsAddScenarioAsideOpen(false);
				}}
			/>
		</>
	);
};
