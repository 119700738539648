import {
	GuardianReconciliationChargeDetailIndexRequest,
	GuardianReconciliationChargeDetailIndexResponse
} from 'RtModels';
import { ReconciliationChargesRouter } from 'RtUi/app/rtVue/ReconciliationCharge/ReconciliationCharges.router';
import { useGetGuardianReconciliationChargeDetail } from 'RtUi/app/rtVue/ReconciliationCharge/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getReconciliationChargeColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/reconciliationCharges';
import { FC, useMemo } from 'react';

interface IReconciliationChargeDetailsGridProps {
	resourceParams?: GuardianReconciliationChargeDetailIndexRequest;
	autoFocusFilter?: boolean;
}

export const ReconciliationChargeGrid: FC<
	React.PropsWithChildren<IReconciliationChargeDetailsGridProps>
> = ({ resourceParams }) => {
	const { data, isFetching: isLoading } =
		useGetGuardianReconciliationChargeDetail(resourceParams);
	const columns = useMemo(() => getReconciliationChargeColumns(), []);

	return (
		<DataGrid<GuardianReconciliationChargeDetailIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			router={ReconciliationChargesRouter}
			pageName={'rtVue_ReconciliationCharge'}
		/>
	);
};
