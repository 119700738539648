import { MessagesSummaryIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { AccountDataGridColumn } from 'RtUi/components/data/DataGrid/columns/AccountDataGridColumn';
import { CallDateDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CallDateDataGridColumn';
import { CostDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CostDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';

export const getMessagesSummaryColumns = (
	callDateFormat?: string
): Array<DataGridColumn<MessagesSummaryIndexResponse>> => [
	CallDateDataGridColumn({
		accessorKey: 'callDate',
		header: 'Call Date',
		hiddenIfEmpty: true,
		format: callDateFormat
	}),
	DefaultDataGridColumn({
		accessorKey: 'callType',
		header: 'Call Type',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'direction',
		header: 'Direction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'campaignId',
		header: 'Campaign Id',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'dlrCode',
		header: 'Dlr Code',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'jurisdiction',
		header: 'Jurisdiction',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'messageStatus',
		header: 'Message Status',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'customerAccountId',
		header: 'Customer Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'customerSubscriptionId',
		header: 'Customer Subscription',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'customerExternalAccountId',
		header: 'Customer External Account',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'customerExternalSubAccountId',
		header: 'Customer External Sub Account',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorAccountId',
		header: 'Vendor Account',
		hiddenIfEmpty: true
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'vendorSubscriptionId',
		header: 'Vendor Subscription',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorExternalAccountId',
		header: 'Vendor External Account',
		hiddenIfEmpty: true
	}),
	AccountDataGridColumn({
		accessorKey: 'vendorExternalSubAccountId',
		header: 'Vendor External Sub Account',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressTrunkGroup',
		header: 'Ingress Trunk Group',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'ingressCountry',
		header: 'Ingress Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressCountry',
		header: 'Egress Country',
		hiddenIfEmpty: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'egressTrunkGroup',
		header: 'Egress Trunk Group',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'attempts',
		header: 'Attempts',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'billable',
		header: 'Billable',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue',
		header: 'Revenue',
		decimals: 4,
		hiddenIfEmpty: true
	}),
	CostDataGridColumn({
		accessorKey: 'cost',
		header: 'Cost',
		hiddenIfEmpty: true
	}),
	PrecisionDataGridColumn({
		accessorKey: 'margin',
		header: 'Margin',
		decimals: 4,
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'totalSegments',
		header: 'Total Segments',
		hiddenIfEmpty: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'messageLength',
		header: 'Message Length',
		hiddenIfEmpty: true
	})
];
