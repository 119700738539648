import type { Cpr } from 'Somos/lib/SomosCpr/RtCprV2/Cpr';
import { type AosNodeType, CprSection } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { CprValidator } from 'Somos/lib/SomosCpr/RtCprV2/CprValidator';

// exported definitions
// ======================================================================

export abstract class AosLbl extends CprValidator {

	public readonly cprSectionId = CprSection.CprSectionCprLabel;

	public abstract readonly aosNodeTypeId: AosNodeType;

	protected name: string;

	public constructor(cpr: Cpr, name: string, uuid: string | undefined = undefined) {
		super(cpr, 0, uuid);
		this.name = name;
	}

	public getName(): string {
		return this.name;
	}

	public setName(newName: string): boolean {
		if (this.cpr.hasAosLabel(newName)) {
			return false;
		}

		const newLbl = this.cpr.makeAosLabel(this.aosNodeTypeId, newName);

		if (!newLbl) {
			return false;
		}

		for (const cprVal of this.cprValues) {
			newLbl.addValue(cprVal.getValue());
		}

		return this.cpr.deleteAosLabel(this.name);
	}

}
