import * as React from 'react';
import {
	AccountProfileResponse,
	RateAmendmentType,
	TimezoneIndexResponse,
	$AccountProfileResponse,
	UserIndexResponse,
	AccountUpdateRequest,
	AccountCreateRequest,
	BillingEntityIndexResponse
} from 'RtModels';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { Row, Col } from 'react-bootstrap';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { AccountResource } from 'RtUi/app/AccountManagement/Accounts/lib/resources/AccountResource';
import { TimezoneSelect } from 'RtUi/app/rtCommon/Timezones/lib/controls/TimezoneSelect';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import { BillingEntitySelect } from 'RtUi/app/AccountManagement/Accounts/lib/controls/BillingEntitySelect';
import { cloneDeep } from 'lodash-es';

interface IAccountEditFormProps {
	profile?: AccountProfileResponse;
	onSuccess?: (profile: AccountProfileResponse) => void;
}

interface IAccountEditFormState {
	account: Partial<AccountProfileResponse>;
	displayMode: boolean;
	isSubmitting: boolean;
	timeZone?: TimezoneIndexResponse;
	salesAgent?: UserIndexResponse;
	serviceAgent?: UserIndexResponse;
	billingEntity?: BillingEntityIndexResponse;
	error?: any;
}

export class AccountEditForm extends React.Component<
	IAccountEditFormProps,
	IAccountEditFormState
> {
	private readonly initialState: IAccountEditFormState;

	constructor(props: IAccountEditFormProps) {
		super(props);

		let account: Partial<AccountProfileResponse>;

		if (props.profile) {
			account = cloneDeep(props.profile);
		} else {
			account = {
				isActive: 1,
				rateAmendmentSubjectAtoZ: '%carrier Rate Amendment %id [%subscription]',
				rateAmendmentSubjectCountry:
					'%carrier Rate Amendment %id [%subscription]',
				rateAmendmentTypeId: RateAmendmentType.AtoZ
			};
		}

		this.initialState = {
			displayMode: Boolean(this.props.profile),
			isSubmitting: false,
			timeZone: undefined,
			salesAgent: undefined,
			serviceAgent: undefined,
			account
		};

		this.state = cloneDeep(this.initialState);
	}

	private update = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const accountResource = new AccountResource();
		this.setState({ isSubmitting: true, error: undefined });
		const { onSuccess = () => ({}) } = this.props;

		const updateObject = {
			...this.state.account,
			salesUserId: this.state.salesAgent ? this.state.salesAgent.userId : null,
			serviceUserId: this.state.serviceAgent
				? this.state.serviceAgent.userId
				: null,
			isActive: !this.props.profile ? 1 : this.state.account.isActive!,
			timezoneId: this.state.timeZone!.timezoneId,
			billingEntityId: this.state.billingEntity?.billingEntityId
		};

		let newProfile: AccountProfileResponse;
		try {
			if (this.props.profile) {
				newProfile = await accountResource.update(
					this.props.profile.accountId,
					updateObject as AccountUpdateRequest
				);
			} else {
				newProfile = await accountResource.create(
					updateObject as AccountCreateRequest
				);
			}

			this.setState({ displayMode: true, isSubmitting: false }, () => {
				onSuccess(newProfile);
			});
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	};

	private onChange = <K extends keyof AccountProfileResponse>(
		key: K,
		value: AccountProfileResponse[K]
	) => {
		if (!this.state.isSubmitting) {
			const { account } = this.state;
			account[key] = value;
			this.setState({ account });
		}
	};

	private onCancel = () => {
		this.setState({ ...cloneDeep(this.initialState) });
	};

	public render() {
		const { account } = this.state;

		return (
			<RtUiWideForm
				createMode={!this.props.profile}
				onSubmit={this.update}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onChange={(displayMode) => this.setState({ displayMode })}
				onCancel={this.onCancel}
			>
				<Row>
					<Col md={6}>
						<h5 className="mb-4">Identification</h5>
						<InputFormControl
							required={
								$AccountProfileResponse.properties.companyName.isRequired
							}
							label="Company name"
							displayMode={this.state.displayMode}
							value={account.companyName}
							onChange={(value) => this.onChange('companyName', value)}
						/>
						<InputFormControl
							required={$AccountProfileResponse.properties.label.isRequired}
							label="Label"
							displayMode={this.state.displayMode}
							value={account.label}
							onChange={(value) => this.onChange('label', value)}
						/>
						{this.props.profile && (
							<IsActiveRadioFormControl
								label="Active"
								required
								hideBothOption
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('isActive', value)}
								value={account.isActive}
							/>
						)}

						<BillingEntitySelect
							required
							displayMode={this.state.displayMode}
							value={this.state.billingEntity}
							initialOptionId={
								account.billingEntityId ? String(account.billingEntityId) : '1'
							}
							onChange={(billingEntity: BillingEntityIndexResponse) =>
								this.setState({ billingEntity })
							}
						/>
						<TimezoneSelect
							required={
								$AccountProfileResponse.properties.timezoneId.isRequired
							}
							label="Billing Time Zone"
							displayMode={this.state.displayMode}
							onChange={(timeZone: TimezoneIndexResponse) =>
								this.setState({ timeZone })
							}
							value={this.state.timeZone}
							initialOptionId={
								account.timezoneId ? String(account.timezoneId) : '436'
							}
						/>
					</Col>
					<Col md={6}>
						<h5 className="mb-4">Communications</h5>
						<RadioFormControl<RateAmendmentType>
							label="Rate Amendment Email Type"
							displayMode={this.state.displayMode}
							onChange={(value) => this.onChange('rateAmendmentTypeId', value)}
							value={account.rateAmendmentTypeId}
							options={[
								{
									value: RateAmendmentType.AtoZ,
									label: 'A to Z'
								},
								{ value: RateAmendmentType.Country, label: 'Country' }
							]}
						/>
					</Col>
				</Row>
			</RtUiWideForm>
		);
	}
}
