import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationChargeDetailIndexRequest,
	GuardianReconciliationChargeDetailIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchGuardianReconciliationChargeDetail = async (
	queryParams?: GuardianReconciliationChargeDetailIndexRequest
): Promise<FullResponse<GuardianReconciliationChargeDetailIndexResponse[]>> => {
	return handleGetRequest<
		GuardianReconciliationChargeDetailIndexResponse[],
		true
	>(RtVueApiRoutes.GuardianReconciliationChargeDetail.Index, {
		includeFullResponse: true,
		queryParams
	});
};

export const useGetGuardianReconciliationChargeDetail = (
	queryParams?: GuardianReconciliationChargeDetailIndexRequest
) => {
	return useQuery<
		FullResponse<GuardianReconciliationChargeDetailIndexResponse[]>,
		Error
	>([`useGetGuardianReconciliationChargeDetail`, queryParams], () =>
		fetchGuardianReconciliationChargeDetail(queryParams)
	);
};
