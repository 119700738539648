import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { SwitchIndexRequest, SwitchTypeIndexResponse } from 'RtModels';
import { SwitchTypesSelect } from 'RtUi/app/AccountManagement/Switches/lib/controls/SwitchTypesSelect';
import { SwitchEditorForm } from 'RtUi/app/AccountManagement/Switches/lib/forms/SwitchEditor';
import { SwitchesGrid } from 'RtUi/app/AccountManagement/Switches/lib/grids/SwitchesGrid';
import { SwitchRouter } from 'RtUi/app/AccountManagement/Switches/Switch.router';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const SwitchIndexContainer = (): JSX.Element => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true },
		Create: { header: 'Create Switch', isAction: true }
	});

	const [switchType, setSwitchType] = useState<SwitchTypeIndexResponse>();
	const [resourceParams, setResourceParams] = useState<SwitchIndexRequest>({
		isActive: 1
	});

	const { control, handleSubmit } = useForm<SwitchIndexRequest>({
		defaultValues: resourceParams
	});

	const handleSubmitForm = (data: SwitchIndexRequest) => {
		const { switchTypeId, isActive } = data;
		const params: SwitchIndexRequest = {};

		if (switchTypeId) {
			params.switchTypeId = switchTypeId;
		}

		if (isActive !== -1) {
			params.isActive = isActive;
		}

		setResourceParams(params);
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={SwitchRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm onSubmit={handleSubmit(handleSubmitForm)}>
					<Controller
						control={control}
						name="switchTypeId"
						render={({ field: { onChange, value } }) => (
							<SwitchTypesSelect
								value={switchType}
								onChange={(val) => {
									setSwitchType(val);
									onChange(val?.switchTypeId);
								}}
								initialOptionId={value}
							/>
						)}
					/>
					<Controller
						control={control}
						name="isActive"
						render={({ field: { onChange, value } }) => (
							<IsActiveRadioFormControl value={value} onChange={onChange} />
						)}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<SwitchesGrid router={SwitchRouter} resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Create}>
				<Row>
					<Col md={6}>
						<SwitchEditorForm
							onSuccess={() => setActiveTab(tabs.Results.header)}
							onCancel={() => setActiveTab(tabs.Results.header)}
							createMode
						/>
					</Col>
				</Row>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

SwitchIndexContainer.displayName = 'SwitchIndexContainer';

SwitchRouter.setIndexRtUiFunctionalComponent(SwitchIndexContainer, {
	groupName: 'Operations',
	orderPriority: 1
});
