/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FC, FormEvent, useState } from 'react';
import {
	AlertType,
	GuardianFraudAlertRecipientCreateRequest,
	GuardianFraudAlertRecipientIndexResponse,
	MessageType,
	UserIndexResponse
} from 'RtModels';
import { FraudRecipientsResource } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/resources/FraudRecipientsResource';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IFraudRecipientsEditorProps {
	onSuccess?: () => void;
	onCancel?: () => void;
	editMode?:
		| GuardianFraudAlertRecipientIndexResponse
		| GuardianFraudAlertRecipientCreateRequest;
}

export const FraudRecipientsEditor: FC<
	React.PropsWithChildren<IFraudRecipientsEditorProps>
> = ({ onSuccess = () => ({}), onCancel = () => ({}), editMode }) => {
	const resource = new FraudRecipientsResource();
	const [selectedUser, setSelectedUser] = useState<UserIndexResponse>();
	const [messageTypes, setMessageTypes] =
		useState<Array<ISimpleSelectFormControlOption<MessageType>>>();
	const [alertType, setAlertTypes] =
		useState<Array<ISimpleSelectFormControlOption<AlertType>>>();
	const [rtUiFormState, rtUiFormMethods] =
		useRtUiFormEditor<GuardianFraudAlertRecipientCreateRequest>({
			//@ts-expect-error could be one of two types, but makes no difference here
			editMode,
			createNew: () => resource.createNewProfile()
		});
	const { workingCopy } = rtUiFormState;

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			if (editMode) {
				await resource.update(editMode.userId, workingCopy);
			} else {
				await resource.create(workingCopy);
			}
			rtUiFormMethods.setIsSubmitting(false);
			onSuccess();
		} catch (err) {
			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setError(err);
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			displayMode={false}
			onSubmit={onSubmit}
			onCancel={() => {
				setSelectedUser(undefined);
				rtUiFormMethods.onCancel();
				onCancel();
			}}
		>
			<UserSelect
				required
				displayMode={Boolean(editMode)}
				value={selectedUser}
				initialOptionId={String(workingCopy?.userId)}
				onChange={(u) => {
					setSelectedUser(u);
					rtUiFormMethods.setWorkingCopy({ userId: u.userId });
				}}
			/>
			<SimpleSelectFormControl<MessageType, true>
				required
				multi
				label="Message Type"
				options={[
					{
						label: 'Email',
						value: MessageType.Email
					},
					{
						label: 'Application Messaging',
						value: MessageType.Firebase
					}
				]}
				value={messageTypes}
				onChange={(mt) => {
					setMessageTypes(mt);
					rtUiFormMethods.setWorkingCopy({
						messageTypeIds: mt.map((m: any) => m.value)
					});
				}}
				initialOptionId={workingCopy?.messageTypeIds.map((mt) => String(mt))}
			/>
			<SimpleSelectFormControl<AlertType, true>
				required
				multi
				label="Alert Type"
				options={[
					{
						label: 'International',
						value: AlertType.FraudInternational
					},
					{
						label: 'Domestic',
						value: AlertType.FraudDomestic
					}
				]}
				value={alertType}
				onChange={(at) => {
					setAlertTypes(at);
					rtUiFormMethods.setWorkingCopy({
						alertType: at.map((a: any) => a.value)
					});
				}}
				initialOptionId={(
					workingCopy as unknown as GuardianFraudAlertRecipientIndexResponse
				)?.alertTypeIds?.map((at) => String(at))}
			/>
		</RtUiForm>
	);
};
