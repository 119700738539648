import { CprRow } from 'Somos/lib/SomosCpr/RtCprV2/CprRow';

// exported definitions
// ======================================================================

export class AllRow extends CprRow {

	public validate(): boolean {
		this.errors = [];
		return this.hasErrors();
	}

}
