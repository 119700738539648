import { RtCarrierNumberRouter } from 'RtUi/app/rtCarrierConnect/Numbers/RtCarrierNumber.router';
import { DidNumberIndexContainerClass } from 'RtUi/app/rtDid/Numbers/DidNumber.index';
import { DidNumberRouter } from 'RtUi/app/rtDid/Numbers/DidNumber.router';

@RtCarrierNumberRouter.getIndexRtUiController()
export class RtCarrierNumberIndexContainer extends DidNumberIndexContainerClass {
	protected router = RtCarrierNumberRouter;
	protected disableReferences = [DidNumberRouter];

	protected numberType: number = 2;
}
