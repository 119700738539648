import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { EmailAccountIndexResponse } from 'RtModels';
import { RtAdmApiRoutes } from 'RtExports/routes';

export class EmailAccountArrayResource extends ArrayResource<EmailAccountIndexResponse> {
	constructor() {
		super('emailAccountId');
		this.setApiRouteForGetAll(RtAdmApiRoutes.EmailAccounts.Index);
	}
}
