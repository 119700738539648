import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	SubscriptionPeeringProfileResponse,
	SubscriptionPeeringIndexResponse
} from 'RtModels';
import { LcrApplicationRouter } from 'RtUi/app/rtLcr/rtLcr.router';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface ISubscriptionPeeringProfileContainerTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const SubscriptionPeeringProfileContainerTabs: ISubscriptionPeeringProfileContainerTabs =
	{
		Profile: {
			header: 'Profile'
		}
	};

export class SubscriptionPeeringRouterClass extends LcrApplicationRouter<
	SubscriptionPeeringIndexResponse,
	SubscriptionPeeringProfileResponse,
	ISubscriptionPeeringProfileContainerTabs
> {
	constructor() {
		super(
			'Subscription Peering',
			'SubscriptionPeering',
			'subscriptionPeeringId',
			SubscriptionPeeringProfileContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.SubscriptionPeering);
	}

	public getProfileLabel(profile: SubscriptionPeeringProfileResponse) {
		return profile.subscriptionPeeringId.toString();
	}
}

export const SubscriptionPeeringRouter = new SubscriptionPeeringRouterClass();
