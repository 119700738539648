import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { MgiTollFreeStatus } from 'RtUi/utils/maps';

interface TollFreeStatusDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const TollFreeStatusDataGridColumn = <T = any,>({
	...config
}: TollFreeStatusDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: string) => {
		return MgiTollFreeStatus[value as keyof typeof MgiTollFreeStatus];
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value) => getValue(value),
		getValue: ({ cell }) => getValue(cell.getValue<string>())
	});
};
