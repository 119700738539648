import { noop } from 'lodash-es';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
	$SipConnection,
	GatewayIndexResponse,
	SipConnection,
	SubscriptionIndexResponse
} from 'RtModels';
import { SubscriptionSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/SubscriptionSelect';
import { useGetServicesOfConnectionType } from 'RtUi/app/AccountManagement/Subscriptions/lib/hooks/useGetServicesOfConnectionType';
import { GatewaySelect } from 'RtUi/app/rtSip/Connections/lib/forms/GatewaySelect';
import { SipConnectionResource } from 'RtUi/app/rtSip/Connections/lib/resources/SipConnectionResource';
import { ServiceTypeLookup } from 'RtUi/app/rtSip/Services/lib/resources/ServiceResource';
import { FqdnInputFormControl } from 'RtUi/components/form/FqdnInputFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { PortInputFormControl } from 'RtUi/components/form/PortFormControl';
import { RtxUiForm } from 'RtUi/components/rtx/form';
import { Loading } from 'RtUi/components/ui/Loading';
import { UserActions } from 'RtUi/state/actions/user';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ISipConnectionEditorProps {
	onUpdateSuccess: (res: SipConnection) => void;
	onCancel?: () => void;
	editMode?: SipConnection;
	createMode?: boolean;
	subscriptionId?: number;
	hideButtons?: boolean;
}

const getServiceTypeLookup = (directionId?: number) => {
	if (directionId === undefined) {
		return undefined;
	}

	return directionId === 1
		? ServiceTypeLookup.Ingress
		: ServiceTypeLookup.Egress;
};

export const SipConnectionEditor = ({
	editMode,
	createMode,
	subscriptionId,
	onCancel,
	onUpdateSuccess = noop
}: ISipConnectionEditorProps) => {
	const [error, setError] = useState<RtError>();
	const [subscription, setSubscription] = useState<SubscriptionIndexResponse>();
	const [gateway, setGateway] = useState<GatewayIndexResponse>();
	const serviceLookup = getServiceTypeLookup(editMode?.directionId);

	const { services, isLoading } = useGetServicesOfConnectionType(serviceLookup);
	const tfaEnabled = UserActions.isTFAEnabled();
	const displayMode = !tfaEnabled;

	if (isLoading) {
		return <Loading />;
	}

	const onSubmit = async (data: SipConnection) => {
		try {
			const {
				isActive,
				nanpFormat,
				idddFormat,
				hostAddress,
				hostPort,
				techPrefix,
				gatewayId,
				subscriptionId
			} = data;

			if (!subscriptionId) {
				return;
			}

			const connectionResource = new SipConnectionResource();

			let updatedProfile: SipConnection | undefined;

			if (!editMode) {
				updatedProfile = await connectionResource.create({
					isActive,
					hostCidr: `${hostAddress}/32`,
					nanpFormat,
					idddFormat,
					subscriptionId,
					gatewayId,
					hostAddress: String(hostAddress),
					hostPort,
					techPrefix
				});
			} else {
				const { sipConnectionId } = editMode;
				updatedProfile = await connectionResource.update(sipConnectionId, {
					isActive,
					nanpFormat,
					idddFormat,
					subscriptionId
				});
			}

			onUpdateSuccess(updatedProfile);
		} catch (error: any) {
			setError(error);
		}
	};

	return (
		<RtxUiForm<SipConnection>
			defaultValues={{
				mediaServerGroupId: 0,
				hostAddress: '',
				hostCidr: '',
				hostPort: 5060,
				techPrefix: '',
				nanpFormat: '[TP][CC][SUBSCRIBER]',
				idddFormat: '[TP][CC][SUBSCRIBER]',
				isActive: 1,
				gatewayId: 0,
				directionId: 1,
				createdTs: new Date(),
				subscriptionId,
				updatedTs: new Date(),
				deletedTs: new Date(),
				auditId: 0,
				sipConnectionId: 0,
				...editMode
			}}
			onSubmit={onSubmit}
			displayMode={false}
			createMode={createMode}
			error={
				error && {
					name: 'root',
					error: {
						message: error.message
					}
				}
			}
			onCancel={onCancel}
			hideButtons={!tfaEnabled}
		>
			{({ control }) => (
				<>
					<Controller
						control={control}
						name="subscriptionId"
						render={({ field: { onChange, value } }) => (
							<SubscriptionSelect
								required
								displayMode={displayMode}
								showAllOption={false}
								serviceIds={services.map((s) => s.serviceId)}
								onChange={(subscription: SubscriptionIndexResponse) => {
									setSubscription(subscription);
									onChange(subscription.subscriptionId);
								}}
								value={subscription}
								initialOptionId={value}
							/>
						)}
					/>
					<Controller
						control={control}
						name="gatewayId"
						render={({ field: { onChange, value } }) => (
							<GatewaySelect
								displayMode={!createMode}
								clearable={false}
								value={gateway}
								onChange={(gateway) => {
									setGateway(gateway);
									onChange(gateway.partitionSipGatewayId);
								}}
								initialOptionId={value ? String(value) : undefined}
								required={$SipConnection.properties.gatewayId.isRequired}
							/>
						)}
					/>
					<Controller
						control={control}
						name="hostAddress"
						render={({ field: { onChange, value } }) => (
							<FqdnInputFormControl
								label="Host Address"
								displayMode={!createMode}
								value={value}
								required={$SipConnection.properties.hostAddress.isRequired}
								onChange={onChange}
							/>
						)}
					/>
					{!createMode && (
						<Controller
							control={control}
							name="hostCidr"
							render={({ field: { onChange, value } }) => (
								<FqdnInputFormControl
									label="Host CIDR"
									displayMode={!createMode}
									value={value}
									required={$SipConnection.properties.hostCidr.isRequired}
									onChange={onChange}
								/>
							)}
						/>
					)}
					<Controller
						control={control}
						name="hostPort"
						render={({ field: { onChange, value } }) => (
							<PortInputFormControl
								label="Host Port"
								displayMode={!createMode}
								value={String(value)}
								required={$SipConnection.properties.hostPort.isRequired}
								onChange={(hostPort) => onChange(Number(hostPort))}
							/>
						)}
					/>
					<Controller
						control={control}
						name="techPrefix"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="Tech Prefix"
								displayMode={!createMode}
								value={value}
								//Can be empty string so technically not required
								//required={$SipConnection.properties.techPrefix.isRequired}
								onChange={(techPrefix) => onChange(techPrefix.trim())}
							/>
						)}
					/>
					<Controller
						control={control}
						name="nanpFormat"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="NANP Format"
								displayMode={displayMode}
								value={value}
								onChange={onChange}
								required={$SipConnection.properties.nanpFormat.isRequired}
							/>
						)}
					/>
					<Controller
						control={control}
						name="idddFormat"
						render={({ field: { onChange, value } }) => (
							<InputFormControl
								label="IDDD Format"
								displayMode={displayMode}
								value={value}
								onChange={onChange}
								required={$SipConnection.properties.idddFormat.isRequired}
							/>
						)}
					/>
					{!createMode && (
						<Controller
							control={control}
							name="isActive"
							render={({ field: { onChange, value } }) => (
								<IsActiveRadioFormControl
									label="Active"
									hideBothOption
									displayMode={displayMode}
									onChange={onChange}
									value={value}
								/>
							)}
						/>
					)}
				</>
			)}
		</RtxUiForm>
	);
};
