/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	ServiceNumberIndexRequest,
	ServiceNumberIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

export const fetchGlobalNumbers = async (
	queryParams?: ServiceNumberIndexRequest
): Promise<FullResponse<ServiceNumberIndexResponse[]>> => {
	return handleGetRequest<ServiceNumberIndexResponse[], true>(
		RtCommonApiRoutes.ServiceNumbers.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetGlobalNumbers = (params?: ServiceNumberIndexRequest) => {
	return useQuery<FullResponse<ServiceNumberIndexResponse[]>, Error>(
		['getGlobalNumbers', params],
		() => {
			return fetchGlobalNumbers(params);
		}
	);
};
