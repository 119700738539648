import { CdrRecurringExportIndexContainer } from 'RtUi/app/Administration/CdrRecurringExport/CdrRecurringExport.index';
import { CdrRecurringExportProfileContainer } from 'RtUi/app/Administration/CdrRecurringExport/CdrRecurringExport.profile';
import { CdrRecurringExportRouter } from 'RtUi/app/Administration/CdrRecurringExport/CdrRecurringExport.router';
import { RtUiModule } from '../@RtUi/RtUiDecorators';
import { AdminUserProfileContainer } from './Users/AdminUser.profile';
import { AdminUserRouter } from './Users/AdminUser.router';
import { AdminUserIndexContainer } from './Users/AdminUser.index';
import { DistributionManagementProfileContainer } from 'RtUi/app/Administration/DistributionManagement/DistributionManagement.profile';
import { DistributionManagementRouter } from 'RtUi/app/Administration/DistributionManagement/DistributionManagement.router';
import { DistributionManagementIndexContainer } from 'RtUi/app/Administration/DistributionManagement/DistributionManagement.index';
import { CicLookupRouter } from 'RtUi/app/Administration/CicLookup/CicLookup.router';
import { CicLookupIndexContainer } from 'RtUi/app/Administration/CicLookup/CicLookup.index';
import { CdrOneTimeExportRouter } from 'RtUi/app/Administration/CdrOneTimeExport/CdrOneTimeExport.router';
import { CdrOneTimeExportIndexContainer } from 'RtUi/app/Administration/CdrOneTimeExport/CdrOneTimeExport.index';

@RtUiModule({
	route: {
		path: '',
		iconClass: 'fa-user-shield',
		name: 'Administration'
	},
	controllers: [
		CdrRecurringExportIndexContainer,
		CdrRecurringExportProfileContainer,
		DistributionManagementIndexContainer,
		DistributionManagementProfileContainer,
		AdminUserProfileContainer,
		AdminUserIndexContainer,
		CicLookupIndexContainer,
		CdrOneTimeExportIndexContainer
	],
	routers: [
		CdrRecurringExportRouter,
		DistributionManagementRouter,
		AdminUserRouter,
		CicLookupRouter,
		CdrOneTimeExportRouter
	]
})
export class AdministrationModule {}
