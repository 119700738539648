import { UserIndexResponse } from 'RtModels';
import { AdminUserRouter } from 'RtUi/app/Administration/Users/AdminUser.router';
import { useGetAdminUsers } from 'RtUi/app/Administration/Users/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getAdminUserColumns } from 'RtUi/components/data/DataGrid/configurations/administration/adminUser';
import { ReactNode, useMemo } from 'react';

interface IUserGridProps {
	additionalContentMenuItems?: (row: UserIndexResponse) => ReactNode;
}

export const UserGrid = ({}: IUserGridProps) => {
	const { data, isLoading } = useGetAdminUsers();

	const columns = useMemo(() => getAdminUserColumns(), []);

	return (
		<DataGrid<UserIndexResponse>
			data={data?.data}
			columns={columns}
			pageName={'administration-adminUser'}
			router={AdminUserRouter}
			loading={isLoading}
			totalRows={data?.totalCount}
		/>
	);
};
