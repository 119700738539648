import { IRtxApiRoute } from 'RtExports/routes';
import { ApplicationState, IApplicationState } from 'RtUi/state';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';
import { IRequestInitWithParams } from 'RtUi/utils/http/interfaces';

export class BaseActions {
	/**
	 * Http Request method
	 * @param path
	 * @param method
	 * @param init
	 */
	public fetch<T = any>(apiRoute: IRtxApiRoute, init?: IRequestInitWithParams) {
		return HttpRequest.fetchWithRoute<T>(apiRoute, init);
	}

	/**
	 * Update app state and invoke all Container's onAppStateChange() methods
	 * @param updatedSubState
	 */
	protected updateState<K extends keyof IApplicationState>(
		updatedSubState: Pick<IApplicationState, K>
	) {
		ApplicationState.update(updatedSubState);
	}

	/**
	 * Get aspect of state
	 * @param key
	 */
	protected getStateAspect<K extends keyof IApplicationState>(key: K) {
		return ApplicationState.get(key);
	}
}
