/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { cloneDeep, isUndefined } from 'lodash-es';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	$UserProfileResponse,
	CountryIndexResponse,
	EntityIndexResponse,
	ProductId,
	RespOrgIndexResponse,
	UserCreateRequest,
	UserIndexResponse,
	UserPermissionProfileResponse,
	UserPermissionUpdateRequest,
	UserProfileResponse,
	UserUpdateRequest
} from 'RtModels';
import { EntitySelect } from 'RtUi/app/rt800/Entities/lib/controls/EntitySelect';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';
import { CountryHttp } from '../Http/CountryHttp';
import { UserHttp } from '../Http/UserHttp';

interface IUserProfileEditorProps {
	onUpdate?: (updatedUser: UserProfileResponse) => void;
	displayMode?: boolean;
	createMode?: boolean;
	editMode?: UserProfileResponse;
	disablePadding?: boolean;
	disableBody?: boolean;
}

interface IUserProfileInterface
	extends Omit<UserProfileResponse, 'userId' | 'timezoneId'> {
	userId?: number;
	timezoneId?: number;
}

interface IUserCopiedPermissions extends UserPermissionProfileResponse {
	user: UserIndexResponse;
	rt800SomosEntities?: EntityIndexResponse[];
	rt800SomosRespOrgs?: RespOrgIndexResponse[];
	rt800SomosDefaultRespOrg?: RespOrgIndexResponse;
	hasRT800Permissions: boolean;
}

interface IUserProfileEditorState {
	user: IUserProfileInterface;
	displayMode: boolean;
	isSubmitting: boolean;
	countries: CountryIndexResponse[];
	copiedPermissions?: IUserCopiedPermissions;
	selectedCountry?: CountryIndexResponse;
	emailValidityError?: string;
	error?: any;
}

export class UserProfileEditor extends React.Component<
	IUserProfileEditorProps,
	IUserProfileEditorState
> {
	public userHttp = new UserHttp();
	public countryHttp = new CountryHttp();
	public userCopy!: UserProfileResponse;
	//from https://github.com/skaterdav85/validatorjs/blob/edfb0417af1c6049650d190815f19981f6cee3e4/src/rules.js
	public emailRegExp =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	public state: IUserProfileEditorState = {
		user: {
			email: '',
			firstName: '',
			lastName: '',
			iso3166Alpha3: '',
			officePhone: '',
			mobilePhone: '',
			smsPhone: '',
			isActive: 1,
			isLocked: 0,
			twoFactorAuthType: 0,
			badPasswordCount: 0,
			badTwoFactorCount: 0
		},
		displayMode: true,
		isSubmitting: false,
		error: undefined,
		countries: [],
		selectedCountry: undefined
	};

	public componentDidMount() {
		this.loadCommon();
		this.loadIfCreateMode();
		this.loadIfEditModeProfile();
	}

	public loadCommon() {
		this.countryHttp.getCountries().then((countriesData) => {
			this.setState({ countries: countriesData });
		});
	}

	public loadIfCreateMode() {
		if (this.props.createMode) {
			this.setState({ displayMode: false });
		}
	}

	public loadIfEditModeProfile() {
		const { createMode, editMode } = this.props;

		if (!createMode && editMode) {
			this.userCopy = cloneDeep(editMode);
			this.setState({ user: editMode });
		}
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		this.setState({ isSubmitting: true, error: undefined });

		const { user } = this.state;
		const { createMode, editMode } = this.props;
		let newUser: UserProfileResponse | undefined;

		try {
			let requestData = {
				...user
			};

			if (createMode) {
				requestData = {
					...user,
					isActive: 1,
					isLocked: 0
				};
				newUser = await this.userHttp.createUser(
					requestData as UserCreateRequest
				);
			} else {
				if (editMode) {
					newUser = await this.userHttp.updateUser(
						editMode.userId,
						requestData as UserUpdateRequest
					);
				} else {
					console.error('something went wrong, probably invalid user data');
					throw new RtError({
						message: 'something went wrong, please try refreshing the page'
					});
				}
			}

			if (!isUndefined(newUser)) {
				this.userCopy = cloneDeep(newUser);
				this.setState({ user: this.userCopy, displayMode: true });

				if (createMode && this.state.copiedPermissions) {
					const {
						permissions,
						portalCarrierIds,
						rt800SomosEntities,
						rt800SomosRespOrgs,
						rt800SomosDefaultRespOrg
					} = this.state.copiedPermissions;
					const permissionsReq: UserPermissionUpdateRequest = {
						permissionIds: permissions.map((p) => p.permissionId),
						portalCarrierIds: portalCarrierIds ?? [],
						entityIds: rt800SomosEntities?.map((e) => e.entityId) ?? [],
						respOrgIds: rt800SomosRespOrgs?.map((e) => e.respOrgId) ?? [],
						defaultRespOrgId: rt800SomosDefaultRespOrg?.respOrgId
					};
					await this.userHttp.updateUserPermissions(
						newUser.userId,
						permissionsReq
					);
				}
			} else {
				this.setState({
					isSubmitting: false,
					error: new RtError({ message: 'No user data returned' })
				});
				throw new RtError({ message: 'No user data returned' });
			}
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (this.props.onUpdate && !isUndefined(newUser)) {
			this.props.onUpdate(newUser);
		}
	}

	public updateUserProp<K extends keyof UserIndexResponse>(
		prop: K,
		value: UserIndexResponse[K],
		onlyDigits = false
	) {
		const { user } = this.state;

		if (onlyDigits && typeof value === 'string') {
			value = value.replace(/\D/g, '') as any;
		}
		try {
			user[prop] = value;
			this.setState({ user }, () => this.checkForEmailValidity());
		} catch (error) {
			this.setState({ error });
		}
	}

	public onUpdateCancel() {
		const resetUser = cloneDeep(this.userCopy);

		this.setState({ user: resetUser, error: undefined });
	}

	/**
	 * Needed to prepare the "iso3166Alpha3" user property to select option format
	 * @param {} countryData
	 */
	public updateCountry(countryData: CountryIndexResponse) {
		const { user } = this.state;

		try {
			user.iso3166Alpha3 = countryData.iso3166Alpha3;
			this.setState({
				user,
				selectedCountry: countryData
			});
		} catch (error) {
			this.setState({ error });
		}
	}

	public onCancel() {
		this.loadIfEditModeProfile();
	}

	public checkForEmailValidity() {
		const { user } = this.state;

		let emailValidityError = '';

		if (!this.emailRegExp.test(user.email)) {
			emailValidityError = 'Email format is invalid.';
		}

		if (this.state.emailValidityError !== emailValidityError) {
			this.setState({ emailValidityError });
		}
	}

	public copyUserPermissions(user: UserIndexResponse) {
		if (!user) {
			this.setState({ copiedPermissions: undefined });
			return;
		}
		this.userHttp.getUserPermissions(user.userId).then((profile) => {
			const copiedPermissions: IUserCopiedPermissions = {
				user,
				...profile,
				hasRT800Permissions: profile.permissions.some(
					(p) => p.productId === ProductId.RT_800
				)
			};
			this.setState({ copiedPermissions });
		});
	}

	public render() {
		const { user, selectedCountry, displayMode, isSubmitting, error } =
			this.state;
		const { createMode, editMode } = this.props;

		if (editMode && user.userId !== editMode.userId) {
			return <Loading />;
		}

		return (
			<RtUiForm
				error={error}
				onCancel={() => this.onUpdateCancel()}
				displayMode={displayMode}
				createMode={createMode}
				isSubmitting={isSubmitting}
				onChange={(displayMode) => this.setState({ displayMode })}
				onSubmit={(evt) => this.onSubmit(evt)}
			>
				<Row>
					<Col lg={6}>
						<InputFormControl
							label="First Name"
							required={$UserProfileResponse.properties.firstName.isRequired}
							key={String(displayMode)}
							autoFocus
							displayMode={displayMode}
							onChange={(firstName) =>
								this.updateUserProp('firstName', firstName)
							}
							value={user?.firstName}
						/>
					</Col>
					<Col lg={6}>
						<InputFormControl
							label="Last Name"
							displayMode={displayMode}
							required={$UserProfileResponse.properties.lastName.isRequired}
							onChange={(lastName) => this.updateUserProp('lastName', lastName)}
							value={user?.lastName}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<InputFormControl
							type="email"
							label="Email"
							required={createMode}
							displayMode={createMode ? displayMode : true}
							onChange={(email) => this.updateUserProp('email', email)}
							value={user?.email}
							customValidityError={this.state.emailValidityError}
						/>
					</Col>
					<Col lg={6}>
						<InputFormControl
							label="SMS Phone"
							maxLength={10}
							minLength={10}
							displayMode={displayMode}
							onChange={(smsPhone) =>
								this.updateUserProp('smsPhone', smsPhone, true)
							}
							value={user?.smsPhone ?? ''}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<InputFormControl
							label="Mobile Phone"
							displayMode={displayMode}
							onChange={(mobilePhone) =>
								this.updateUserProp('mobilePhone', mobilePhone, true)
							}
							maxLength={10}
							minLength={10}
							value={user?.mobilePhone ?? ''}
						/>
					</Col>
					<Col lg={6}>
						<InputFormControl
							label="Office Phone"
							maxLength={10}
							minLength={10}
							displayMode={displayMode}
							onChange={(officePhone) =>
								this.updateUserProp('officePhone', officePhone, true)
							}
							value={user?.officePhone ?? ''}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<CountrySelect
							required={
								$UserProfileResponse.properties.iso3166Alpha3.isRequired
							}
							displayMode={displayMode}
							placeholder="Select country"
							label="Country"
							onChange={(countryData) => this.updateCountry(countryData)}
							value={selectedCountry}
							initialOptionId={user?.iso3166Alpha3 || 'USA'}
							isClearable={false}
						/>
					</Col>
					<Col lg={6}>
						<IsActiveRadioFormControl
							label="Active"
							displayMode={displayMode}
							onChange={(isActive) => this.updateUserProp('isActive', isActive)}
							hideBothOption
							value={user?.isActive}
						/>
					</Col>
				</Row>
				<Row>
					{createMode && (
						<Col lg={6}>
							<UserSelect
								label="Copy Permissions from:"
								displayMode={this.state.displayMode}
								onChange={(user: UserIndexResponse) =>
									this.copyUserPermissions(user)
								}
								value={this.state.copiedPermissions?.user}
							/>
							{this.state.copiedPermissions?.hasRT800Permissions && (
								<section>
									<EntitySelect<true>
										multi
										label="Entities"
										initialOptionId={this.state.copiedPermissions.entityIds}
										onChange={(rt800SomosEntities) =>
											this.setState((state) => {
												const copiedPermissions = state.copiedPermissions;
												if (copiedPermissions) {
													copiedPermissions.rt800SomosEntities =
														rt800SomosEntities;
												}
												return {
													...state,
													copiedPermissions
												};
											})
										}
										value={this.state.copiedPermissions.rt800SomosEntities}
									/>
									<Row>
										<Col xl={6}>
											<RespOrgSelect<true>
												multi
												label="RespOrgs"
												initialOptionId={
													this.state.copiedPermissions.respOrgIds
												}
												value={this.state.copiedPermissions.rt800SomosRespOrgs}
												onChange={(rt800SomosRespOrgs) =>
													this.setState((state) => {
														const copiedPermissions = state.copiedPermissions;
														if (copiedPermissions) {
															copiedPermissions.rt800SomosRespOrgs =
																rt800SomosRespOrgs;
														}
														return {
															...state,
															copiedPermissions
														};
													})
												}
											/>
										</Col>
										<Col xl={6}>
											<RespOrgSelect
												label="Default RespOrg"
												required
												initialOptionId={
													this.state.copiedPermissions.defaultRespOrgId
												}
												value={
													this.state.copiedPermissions.rt800SomosDefaultRespOrg
												}
												onChange={(rt800SomosDefaultRespOrg) =>
													this.setState((state) => {
														const copiedPermissions = state.copiedPermissions;
														if (copiedPermissions) {
															copiedPermissions.rt800SomosDefaultRespOrg =
																rt800SomosDefaultRespOrg;
														}

														return {
															...state,
															copiedPermissions
														};
													})
												}
											/>
										</Col>
									</Row>
								</section>
							)}
						</Col>
					)}
					<Col lg={createMode ? 6 : { span: 6, offset: 6 }}>
						<BooleanRadioFormControl
							onChange={(isLocked) => this.updateUserProp('isLocked', isLocked)}
							label="Locked"
							displayMode={displayMode}
							value={user?.isLocked}
						/>
					</Col>
				</Row>
			</RtUiForm>
		);
	}
}
