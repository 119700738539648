import {
	FraudConfigurationContainerTabs,
	FraudConfigurationRouter
} from 'RtUi/app/rtGuardian/FraudConfiguration/FraudConfiguration.router';
import { FraudConfigurationGrid } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/grids/FraudConfigurationGrid';
import { FraudConfigurationListGrid } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/grids/FraudConfigurationListGrid';
import { FraudConfigurationRecipientsGrid } from 'RtUi/app/rtGuardian/FraudConfiguration/lib/grids/FraudConfigurationRecipientsGrid';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const FraudConfigurationIndexContainer = (): JSX.Element => {
	const [tabs, activeTab, setActiveTab] = useTabs(
		FraudConfigurationContainerTabs
	);

	return (
		<TabbedLayout
			router={FraudConfigurationRouter}
			activeTab={activeTab.header}
			onTabChange={(activeTab) => setActiveTab(activeTab)}
		>
			<TabbedLayoutTab {...tabs.FraudConfigurations}>
				<FraudConfigurationGrid pageName="rtGuardian_fraudConfiguration" />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.FraudAllowList}>
				<FraudConfigurationListGrid
					pageName="rtGuardian_fraudConfigurationFraudAllowList"
					resourceParams={{ block: 0 }}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.FraudBlockList}>
				<FraudConfigurationListGrid
					pageName="rtGuardian_fraudConfigurationFraudBlockList"
					resourceParams={{ block: 1, globalRule: 0 }}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.GlobalBlockList}>
				<FraudConfigurationListGrid
					pageName="rtGuardian_fraudConfigurationGlobalBlockList"
					resourceParams={{ block: 1, globalRule: 1 }}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Recipients}>
				<FraudConfigurationRecipientsGrid pageName="rtGuardian_fraudConfigurationRecipients" />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

FraudConfigurationIndexContainer.displayName =
	'FraudConfigurationIndexContainer';

FraudConfigurationRouter.setIndexRtUiFunctionalComponent(
	FraudConfigurationIndexContainer,
	{
		groupName: 'Fraud',
		orderPriority: 1
	}
);
