import * as React from 'react';
import { Badge, Card, Dropdown } from 'react-bootstrap';
import { RtUiRouter } from './RtUiRouter';

interface ISummaryComponentProps<T> {
	record: T;
	header: React.ReactNode;
	badges: Array<string | Promise<string>>;
	externalRouters: RtUiRouter[];
}

interface ISummaryComponentState {
	renderedBadges: string[];
}

export class SummaryComponent<T> extends React.Component<
	ISummaryComponentProps<T>,
	ISummaryComponentState
> {
	public state: ISummaryComponentState = {
		renderedBadges: []
	};

	public componentDidMount() {
		const { badges } = this.props;

		for (const possibleBadge of badges) {
			if (typeof possibleBadge === 'string') {
				this.addRenderedBadge(possibleBadge);
			} else {
				possibleBadge.then((badge) => this.addRenderedBadge(badge));
			}
		}
	}

	public addRenderedBadge(badge: string) {
		if (badge === '') {
			return;
		}

		this.setState(({ renderedBadges }) => {
			if (renderedBadges.includes(badge)) {
				return;
			}

			renderedBadges.push(badge);

			return { renderedBadges };
		});
	}

	public render() {
		const { record, header, externalRouters } = this.props;
		const { renderedBadges } = this.state;

		return (
			<Card className="bg-light p-2">
				<section className="d-flex justify-content-between align-items-center">
					<section>
						<a>{header}</a>
						<article className="small">
							{renderedBadges.map((badge, index) => (
								<Badge bg="primary" className="me-2 mt-1" key={badge + index}>
									{badge}
								</Badge>
							))}
						</article>
					</section>
					<section>
						{externalRouters.length > 0 && (
							<Dropdown
								align="end"
								onClick={(evt: any) => evt.stopPropagation()}
							>
								<Dropdown.Toggle
									bsPrefix="m-0"
									variant="light"
									className="rounded"
								>
									<i className="fas fa-angle-down" />
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{externalRouters.map((externalRouter) => (
										<Dropdown.Item
											key={externalRouter.getName()}
											onClick={() =>
												(location.href =
													'#' + externalRouter.getProfileRoute(record))
											}
										>
											<span>{externalRouter.getName()}</span>
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						)}
					</section>
				</section>
			</Card>
		);
	}
}
