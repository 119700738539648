import { Col, Row } from 'react-bootstrap';
import { SwitchEditorForm } from 'RtUi/app/AccountManagement/Switches/lib/forms/SwitchEditor';
import { GuardianSwitchFileStreamsGrid } from 'RtUi/app/AccountManagement/Switches/lib/grids/GuardianSwitchFileStreamGrid';
import { SipAgentAddressesGrid } from 'RtUi/app/AccountManagement/Switches/lib/grids/SipAgentAddressesGrid';
import { SwitchAddressesGrid } from 'RtUi/app/AccountManagement/Switches/lib/grids/SwitchAddressesGrid';
import { useGetSwitch } from 'RtUi/app/AccountManagement/Switches/lib/services';
import { SwitchRouter } from 'RtUi/app/AccountManagement/Switches/Switch.router';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const SwitchProfileContainer = (): JSX.Element => {
	const entityId = useIdParam();
	const { data, isLoading, refetch } = useGetSwitch(Number(entityId));

	const [tabs, activeTab, setActiveTab] = useTabs(
		SwitchRouter.getProfileTabs()
	);

	if (isLoading) {
		return <Loading />;
	}

	if (!data) {
		return <p>Something went wrong!</p>;
	}

	return (
		<TabbedLayout
			profile={data}
			router={SwitchRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Profile}>
				<Row>
					<Col md={6}>
						<SwitchEditorForm value={data} onSuccess={() => refetch()} />
					</Col>
				</Row>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.FileStreams}>
				<GuardianSwitchFileStreamsGrid switchId={data.switchId} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Firewall}>
				<SwitchAddressesGrid switchId={data.switchId} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.SipAgents}>
				<SipAgentAddressesGrid switchId={data.switchId} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

SwitchProfileContainer.displayName = 'SwitchProfileContainer';

SwitchRouter.setProfileRtUiFunctionalComponent(SwitchProfileContainer);
