import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { UserHttp } from '../Http/UserHttp';
import { UserIndexResponse } from 'RtModels';
import { Badge } from 'react-bootstrap';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

interface IUserSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<UserIndexResponse, isMulti> {
	hideUsersByIds?: number[];
	placeholder?: string;
}

const userHttp = new UserHttp();

export class UserSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	UserIndexResponse,
	isMulti,
	IUserSelectProps<isMulti>
> {
	public resourceClass = undefined;
	public resource = userHttp.getUsersResource();
	public state: ISelectFormControlState<UserIndexResponse> = {
		formLabel: 'User',
		valueKey: 'userId',
		labelKey: 'email'
	};

	constructor(props: IUserSelectProps<isMulti>) {
		super(props);

		this.state.placeholder = props.placeholder;
	}

	public optionRenderer = (user: UserIndexResponse) => (
		<section>
			<span className="d-inline-block me-2">
				{user.firstName} {user.lastName}
			</span>
			<Badge bg="light" text="dark">
				{user.email}
			</Badge>
		</section>
	);

	/**
	 * @override
	 * @param value
	 */
	public triggerOnChange(
		value: isMulti extends true ? UserIndexResponse[] : UserIndexResponse
	) {
		super.triggerOnChange(value);

		this.reloadOptionsOnFocus();
	}

	public filterOption(
		option: FilterOptionOption<UserIndexResponse>,
		rawInput: string
	): boolean {
		const { email, lastName, firstName } = option.data;
		return (
			[email, lastName, firstName].some((value) =>
				value.toLowerCase().includes(rawInput.toLowerCase())
			) || super.filterOption(option, rawInput)
		);
	}

	/**
	 * @override
	 */
	public async getData() {
		let users = await super.getData();
		const { hideUsersByIds } = this.props;

		users.sort((u1, u2) => u1.firstName.localeCompare(u2.firstName));

		if (hideUsersByIds) {
			users = users.filter((user) => !hideUsersByIds.includes(user.userId));
		}

		return users;
	}
}
