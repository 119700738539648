import * as React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import {
	LcrCarrierStateProfileResponse,
	NanpRegionIndexResponse
} from 'RtModels';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { FormErrors } from 'RtUi/components/form/FormErrors';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { Aside } from 'RtUi/components/ui/Aside';
import { RtError } from 'RtUi/utils/errors/RtError';
import { NanpRegionSelect } from '../controls/NanpRegionSelect';
import { LcrCarriersResource } from '../resources/LcrCarriersResource';

interface IBulkUpdateCarrierByRegionBtnProps {
	lcrCarrierId: number;
	onUpdate?: (states: LcrCarrierStateProfileResponse[]) => void;
}

interface IBulkUpdateCarrierByRegionBtnState {
	asideIsOpen: boolean;
	bulkSelectedRegion?: NanpRegionIndexResponse;
	bulkUpdateErrors?: RtError;
	isSubmittingBulkUpdate: boolean;
	bulkInterRate: number;
	bulkIntraRate: number;
	bulkIndetRate: number;
	isActive: number;
	allowAsDefault: number;
}

export class BulkUpdateCarrierByRegionBtn extends React.Component<
	IBulkUpdateCarrierByRegionBtnProps,
	IBulkUpdateCarrierByRegionBtnState
> {
	public state: IBulkUpdateCarrierByRegionBtnState = {
		asideIsOpen: false,
		isSubmittingBulkUpdate: false,
		bulkUpdateErrors: undefined,
		bulkInterRate: 1,
		bulkIntraRate: 1,
		bulkIndetRate: 1,
		isActive: 0,
		allowAsDefault: 0
	};

	public lcrCarriersResource = new LcrCarriersResource();

	public toggleBulkUpdatePopover(asideIsOpen = !this.state.asideIsOpen) {
		if (!asideIsOpen) {
			const bulkSelectedRegion = undefined;
			const bulkInterRate = 1;
			const bulkIntraRate = 1;
			const bulkIndetRate = 1;
			const isActive = 0;
			const allowAsDefault = 0;

			this.setState({
				bulkSelectedRegion,
				bulkInterRate,
				bulkIntraRate,
				bulkIndetRate,
				isActive,
				allowAsDefault
			});
		}

		this.setState({ asideIsOpen });
	}

	public submitBulkUpdate(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		const { lcrCarrierId } = this.props;
		const {
			bulkSelectedRegion,
			bulkInterRate,
			bulkIntraRate,
			bulkIndetRate,
			isActive,
			allowAsDefault
		} = this.state;

		if (!bulkSelectedRegion) {
			return;
		}

		this.setState({
			isSubmittingBulkUpdate: true,
			bulkUpdateErrors: undefined
		});

		const { nanpRegionId } = bulkSelectedRegion;
		this.lcrCarriersResource
			.bulkUpdateCarrierRatesByRegion(lcrCarrierId, nanpRegionId, {
				isActive,
				allowAsDefault,
				defaultInterRate: bulkInterRate,
				defaultIntraRate: bulkIntraRate,
				defaultIndetRate: bulkIndetRate
			})
			.then((states) => {
				const { onUpdate = () => ({}) } = this.props;

				onUpdate(states);

				this.toggleBulkUpdatePopover(false);
			})
			.catch((bulkUpdateErrors: RtError) => this.setState({ bulkUpdateErrors }))
			.finally(() => this.setState({ isSubmittingBulkUpdate: false }));
	}

	public render() {
		return (
			<>
				<Button
					variant="light"
					className="me-2"
					id="BulkUpdateTrigger"
					onClick={() => this.toggleBulkUpdatePopover()}
				>
					<i className="far fa-clone fa-fw fa-lg" />
					<span>&nbsp;Bulk Update By Region</span>
				</Button>
				<Aside isOpen={this.state.asideIsOpen} disabledBody>
					<Aside.Header
						header="Bulk Update by Region"
						onClose={() => this.toggleBulkUpdatePopover(false)}
					/>

					<Alert
						variant="info"
						className="rounded-0 d-flex justify-content-start mb-0"
					>
						<i className="fas fa-info-circle me-1" />
						<span>
							Note: All states within the selected region will be updated.
						</span>
					</Alert>
					<Aside.Body>
						<Form onSubmit={(e) => this.submitBulkUpdate(e)}>
							<IsActiveRadioFormControl
								label="Active"
								onChange={(isActive) => this.setState({ isActive })}
								hideBothOption
								value={this.state.isActive}
							/>
							<BooleanRadioFormControl
								label="Use Default Rates"
								onChange={(allowAsDefault) => this.setState({ allowAsDefault })}
								value={this.state.allowAsDefault}
							/>
							<NanpRegionSelect
								onChange={(bulkSelectedRegion: NanpRegionIndexResponse) =>
									this.setState({ bulkSelectedRegion })
								}
								value={this.state.bulkSelectedRegion}
							/>
							<PrecisionFormControl
								label="New Inter. Rate"
								onChange={(bulkInterRate) => {
									if (bulkInterRate !== null) {
										this.setState({ bulkInterRate });
									}
								}}
								value={this.state.bulkInterRate}
								precision={6}
							/>
							<PrecisionFormControl
								label="New Intra. Rate"
								onChange={(bulkIntraRate) => {
									if (bulkIntraRate !== null) {
										this.setState({ bulkIntraRate });
									}
								}}
								value={this.state.bulkIntraRate}
								precision={6}
							/>
							<PrecisionFormControl
								label="New Indet. Rate"
								onChange={(bulkIndetRate) => {
									if (bulkIndetRate !== null) {
										this.setState({ bulkIndetRate });
									}
								}}
								value={this.state.bulkIndetRate}
								precision={6}
							/>
							<Form.Group className="d-flex justify-content-end mb-3">
								<Button
									variant="light"
									className="me-2"
									disabled={this.state.isSubmittingBulkUpdate}
									onClick={() => this.toggleBulkUpdatePopover(false)}
								>
									<span>Cancel</span>
								</Button>
								<Button
									variant="primary"
									disabled={this.state.isSubmittingBulkUpdate}
									type="submit"
								>
									{this.state.isSubmittingBulkUpdate && (
										<i className="fas fa-cog fa-cog fa-spin" />
									)}
									{!this.state.isSubmittingBulkUpdate && (
										<i className="fa fa-fw fa-check" />
									)}
									<span>&nbsp;Update</span>
								</Button>
							</Form.Group>
							<FormErrors error={this.state.bulkUpdateErrors} />
						</Form>
					</Aside.Body>
				</Aside>
			</>
		);
	}
}
