import {
	SipMethod,
	SipTraceIndexRequest,
	SipTraceIndexResponseData
} from 'RtModels';
import { SipTraceDetailsAside } from 'RtUi/app/rtSip/SipTrace/lib/components/SipTraceDetailsAside';
import { useGetSipTrace } from 'RtUi/app/rtSip/SipTrace/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { RowThemeColor } from 'RtUi/components/data/DataGrid/types';
import { getSipTraceColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/sipTrace';
import { FC, useMemo, useState } from 'react';

interface ISipTraceDataGridProps {
	resourceParams: SipTraceIndexRequest;
}

function getRowColor(row: SipTraceIndexResponseData): RowThemeColor {
	if (
		String(row.method) === SipMethod.SipInvite ||
		String(row.method) === SipMethod.Bye ||
		String(row.method) === 'ACK'
	) {
		return RowThemeColor.SUCCESS;
	}

	if (String(row.method) === 'CANCEL') {
		return RowThemeColor.PRIMARY;
	}

	if (String(row.method)[0] === '1') {
		return RowThemeColor.INFO;
	}

	if (String(row.method)[0] === '2') {
		return RowThemeColor.SUCCESS;
	}

	if (String(row.method)[0] === '3') {
		return RowThemeColor.WARNING;
	}

	return RowThemeColor.DANGER;
}

export const SipTraceDataGrid: FC<
	React.PropsWithChildren<ISipTraceDataGridProps>
> = ({ resourceParams }) => {
	const [selectedRow, setSelectedRow] = useState<SipTraceIndexResponseData>();
	const { data, isFetching: isLoading } = useGetSipTrace(resourceParams);
	const columns = useMemo(() => getSipTraceColumns(), []);
	const mappedData = useMemo(() => data?.data.data, [data]);

	return (
		<>
			<DataGrid<SipTraceIndexResponseData>
				data={mappedData}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtSip_sipTrace'}
				onRowClick={(selectedRow) => {
					setSelectedRow(selectedRow);
				}}
				rowThemeColor={(record) =>
					selectedRow?.uuid === record.uuid
						? RowThemeColor.WARNING
						: getRowColor(record)
				}
				columns={columns}
			/>
			{selectedRow && (
				<SipTraceDetailsAside
					show={Boolean(selectedRow)}
					toggle={() => {
						setSelectedRow(undefined);
					}}
					resourceParams={resourceParams}
					//cSpell:disable-next-line
					callId={selectedRow.callid}
				/>
			)}
		</>
	);
};
