import { type CprNodeType } from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';

export class CprTreeNode {

	public readonly cprNodeTypeId: CprNodeType | null;
	public readonly key: string;
	public readonly cprRowIdx: number;

	// public readonly children: CprTreeNode[] = [];
	public readonly childrenByKey: Record<string, CprTreeNode> = {};

	// public readonly values: string[] = [];
	public readonly valuesByKey: Record<string, number> = {};

	// need these, cic and terminatingNumber, or announcement
	protected cic: string | null = null;
	protected terminatingNumber: string | null = null;
	protected announcement: string | null = null;

	public constructor(cprNodeTypeId: CprNodeType | null, key: string, cprRowIdx: number) {
		this.cprNodeTypeId = cprNodeTypeId;
		this.key = key;
		this.cprRowIdx = cprRowIdx;
	}

	public addChild(child: CprTreeNode): boolean {
		if (child.key in this.childrenByKey) {
			return false;
		}

		// this.children.push(child);
		this.childrenByKey[child.key] = child;

		return true;
	}

	public getChild(childKey: string): CprTreeNode | null {
		if (childKey in this.childrenByKey) {
			return this.childrenByKey[childKey];
		}

		return null;
	}

	public hasChild(childKey: string) {
		return (childKey in this.childrenByKey);
	}

	public addValue(value: string, cprRowIdx: number): boolean {
		if (value in this.valuesByKey) {
			return false;
		}

		// this.values.push(value);
		this.valuesByKey[value] = cprRowIdx;

		return true;
	}

	public getValueIdx(value: string): number | null {
		if (value in this.valuesByKey) {
			return this.valuesByKey[value];
		}

		return null;
	}

	public hasValue(value: string) {
		return (value in this.valuesByKey);
	}

	public setCic(cic: string | null) {
		this.cic = cic;
	}

	public setTerminatingNumber(terminatingNumber: string | null) {
		this.terminatingNumber = terminatingNumber;
	}

	public setAnnouncement(announcement: string | null) {
		this.announcement = announcement;
	}

}
