import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { CdrExportTypeIndexResponse } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';

export interface ICdrExportTypeIndex extends CdrExportTypeIndexResponse {}

export class CdrExportTypeResource extends ArrayResource<ICdrExportTypeIndex> {
	constructor() {
		super('exportTypeId');

		this.setApiRouteForGetAll(RtxSipApiRoutes.CdrExportTypes.Index);
	}
}
