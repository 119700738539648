/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipAgentStatisticIndexRequest = {
    properties: {
        sipAgentId: {
            type: 'number',
        },
        timeslot: {
            type: 'Date',
            format: 'date-time',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
        showServerInfo: {
            type: 'number',
        },
        showDailyTotals: {
            type: 'number',
        },
    },
};