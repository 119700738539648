import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DefaultDataGridColumn,
	FallbackHeaderDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { isNil } from 'lodash-es';

interface RateKeyDataGridColumnConfiguration<T>
	extends FallbackHeaderDataGridColumnConfiguration<T> {
	header?: string;
}

export const RateKeyDataGridColumn = <T = any,>({
	header = 'Rate Key',
	...config
}: RateKeyDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: any) => {
		if (isNil(value)) {
			return '';
		}

		return value;
	};

	return DefaultDataGridColumn({
		header,
		...config,
		exportValue: (value: any) => `${getValue(value)}`,
		getValue: ({ cell }) => getValue(cell.getValue())
	});
};
