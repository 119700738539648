import { IconButton, MenuItem, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAppDispatch, useAppSelector } from 'RtUi/store/hooks';
import { dataGridActions } from 'RtUi/store/reducers/DataGridReducers';

interface IPrecisionDataGridColumnMenuItemProps {
	columnId: string;
	onChange: (newValue: number) => void;
}

export const PrecisionDataGridColumnMenuItem = ({
	columnId,
	onChange
}: IPrecisionDataGridColumnMenuItemProps) => {
	const dispatch = useAppDispatch();
	const precisionMap = useAppSelector(
		(state) => state.dataGrid.precisionColumn
	);
	const value = useMemo(
		() => precisionMap[columnId] ?? 2,
		[precisionMap, columnId]
	);

	const [internalValue, setInternalValue] = useState<number>(value);

	const onChangeInternalValue = (
		evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setInternalValue(Number(evt.target.value));
	};

	const onChangeHandler = (newValue: number) => {
		dispatch(dataGridActions.setPrecision({ key: columnId, value: newValue }));
		onChange(newValue);
	};

	return (
		<MenuItem>
			<TextField
				label="Precision"
				type="number"
				value={internalValue}
				onChange={onChangeInternalValue}
				onKeyUp={(evt) => {
					if (evt.key === 'Enter') {
						onChangeHandler(internalValue);
					}
				}}
			/>
			<IconButton onClick={() => onChangeHandler(internalValue)}>
				<ArrowForwardIosIcon />
			</IconButton>
		</MenuItem>
	);
};
