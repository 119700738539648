/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ConnectionIndexRequest = {
    properties: {
        connectionId: {
            type: 'number',
        },
        fileStreamIds: {
            type: 'Array',
        },
        search: {
            type: 'string',
        },
        gatewayAddress: {
            type: 'string',
        },
        peerAddress: {
            type: 'string',
        },
        trunkGroupId: {
            type: 'string',
        },
        trunkGroupLabel: {
            type: 'string',
        },
        summary: {
            type: 'string',
        },
        externalId: {
            type: 'string',
        },
        switchId: {
            type: 'number',
        },
        directionId: {
            type: 'number',
        },
        subscriptionId: {
            type: 'number',
        },
        isImt: {
            type: 'number',
        },
        isPeering: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};