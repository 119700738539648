import { ActivePortAllIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { HeaderDataGridColumn } from 'RtUi/components/data/DataGrid/columns/HeaderDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';

export const getActivePortsPartitionColumns = (): Array<
	DataGridColumn<ActivePortAllIndexResponse>
> => [
	HeaderDataGridColumn({
		header: ' ',
		columns: [
			DefaultDataGridColumn({
				accessorKey: 'summary',
				header: 'Partition'
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Customer',
		columns: [
			PrecisionDataGridColumn({
				accessorKey: 'igCps',
				header: 'CPS',
				showFooter: true
			}),
			IntegerDataGridColumn({
				accessorKey: 'igCur',
				header: 'Ports',
				showFooter: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Vendor',
		columns: [
			PrecisionDataGridColumn({
				accessorKey: 'egCps',
				header: 'CPS',
				showFooter: true
			}),
			IntegerDataGridColumn({
				accessorKey: 'egCur',
				header: 'Ports',
				showFooter: true
			})
		]
	}),
	HeaderDataGridColumn({
		header: 'Total',
		columns: [
			PrecisionDataGridColumn({
				accessorKey: 'totCps',
				header: 'CPS',
				showFooter: true
			}),
			IntegerDataGridColumn({
				accessorKey: 'totCur',
				header: 'Ports',
				showFooter: true
			})
		]
	})
];
