import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { DemoProfileResponse, DemoIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IActivePortsSummaryRouterTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const ActivePortsSummaryContainerTabs: IActivePortsSummaryRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class ActivePortsSummaryRouterClass extends SipApplicationRouter<
	DemoIndexResponse,
	DemoProfileResponse,
	IActivePortsSummaryRouterTabs
> {
	constructor() {
		super(
			'Active Ports Summary',
			'ActivePortsSummary',
			'value',
			ActivePortsSummaryContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.ActivePorts);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const ActivePortsSummaryRouter = new ActivePortsSummaryRouterClass();
