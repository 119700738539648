import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { isValidJson } from 'RtUi/utils/json';
import { useMemo, useState } from 'react';
import { Form, Nav } from 'react-bootstrap';
import ReactJson from 'react-json-view';

enum TabTypes {
	json = 'JSON',
	editor = 'Editor'
}

//cSpell:ignore IJSON
interface IJSONEditorProps {
	label: string;
	value: object;
	onChange: (value: object) => void;
	displayMode?: boolean;
	customValidationError?: string;
	required?: boolean;
}

export const JSONEditor = ({
	label,
	value,
	onChange,
	required,
	customValidationError,
	displayMode
}: IJSONEditorProps) => {
	const [activeTab, setActiveTab] = useState(TabTypes.editor);
	const [inputValue, setInputValue] = useState<string>(
		value ? JSON.stringify(value, null, 0) : ''
	);

	const isInvalid = useMemo(
		() => inputValue && !isValidJson(inputValue),
		[inputValue]
	);

	const JSONError = () => {
		if (isInvalid) {
			return 'Invalid JSON input!';
		}

		if (customValidationError) {
			return customValidationError;
		}

		return undefined;
	};

	const JSONRenderer = () => {
		if (isInvalid) {
			return <p className="text-danger">Invalid JSON input!</p>;
		}

		return (
			<div className="mt-2">
				<ReactJson
					src={value}
					displayDataTypes={false}
					name={false}
					enableClipboard={false}
					collapsed={2}
				/>
			</div>
		);
	};

	if (displayMode) {
		return (
			<>
				<Form.Label>{label}</Form.Label>
				{JSONRenderer()}
			</>
		);
	}

	return (
		<>
			<Form.Label>{label}</Form.Label>
			<Nav
				variant="tabs"
				defaultActiveKey={activeTab}
				onSelect={(eventKey) =>
					setActiveTab((eventKey as TabTypes) ?? TabTypes.editor)
				}
			>
				<Nav.Link eventKey={TabTypes.editor}>{TabTypes.editor} View</Nav.Link>
				<Nav.Link eventKey={TabTypes.json}>{TabTypes.json} View</Nav.Link>
			</Nav>
			{activeTab === TabTypes.editor && (
				<TextAreaFormControl
					label=""
					hideLabel
					value={inputValue}
					onChange={(newValue) => {
						setInputValue(newValue);
						if (isValidJson(newValue)) {
							onChange(JSON.parse(newValue));
						}
					}}
					required={required}
					className="mt-1"
					customValidityError={JSONError()}
					rows={3}
				/>
			)}
			{activeTab === TabTypes.json && JSONRenderer()}
		</>
	);
};
