import { cloneDeep } from 'lodash-es';
import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import {
	$ContactCreateRequest,
	$ContactUpdateRequest,
	Contact,
	ContactCreateRequest,
	ContactIndexResponse,
	CountryIndexResponse
} from 'RtModels';
import { ContactResource } from 'RtUi/app/rtSip/Contacts/lib/resources/ContactResource';
import { CountrySelect } from 'RtUi/app/user/lib/controls/CountrySelect';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IRadioButtonOptions } from 'RtUi/components/ui/RadioButtons';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface IContactFormEditorProps {
	profile?: ContactIndexResponse;
	accountId?: number;
	onSuccess?: (profile: ContactIndexResponse) => void;
	onDelete?: () => void;
}

interface IContactFormEditorState {
	contact: ContactCreateRequest;
	displayMode: boolean;
	isSubmitting: boolean;
	isDeleting: boolean;
	selectedCountry?: CountryIndexResponse;
	error?: any;
}

export class ContactFormEditor extends React.Component<
	IContactFormEditorProps,
	IContactFormEditorState
> {
	private readonly initialState: IContactFormEditorState;

	constructor(props: IContactFormEditorProps) {
		super(props);

		let contact: ContactCreateRequest;

		if (props.profile) {
			contact = cloneDeep(props.profile);
		} else {
			contact = {
				firstName: '',
				lastName: '',
				emailAddress: '',
				address1: '',
				address2: '',
				city: '',
				state: '',
				postalCode: '',
				countryId: 218,
				homePhone: '',
				mobilePhone: '',
				workPhone: '',
				accountId: props.accountId!,
				isBilling: 0,
				isMailing: 0,
				isRates: 0,
				isSales: 0,
				isNoc: 0,
				isProvisioning: 0
			};
		}

		this.initialState = {
			displayMode: Boolean(props.profile),
			isSubmitting: false,
			isDeleting: false,
			contact
		};

		this.state = cloneDeep(this.initialState);
	}

	public render() {
		const primaryOption: IRadioButtonOptions<number> = {
			value: 2,
			label: (
				<span>
					Primary&nbsp;
					<small style={{ opacity: 0.5 }}>
						<i className="fas fa-fw fa-star text-muted" />
					</small>
				</span>
			)
		};
		const { contact } = this.state;
		const createMode = !this.props.profile;
		const $schema = this.props.profile
			? $ContactUpdateRequest
			: $ContactCreateRequest;

		return (
			<RtUiForm
				createMode={createMode}
				onSubmit={this.createOrUpdate}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				onChange={(displayMode) => this.setState({ displayMode })}
				onCancel={() => {
					this.setState({ ...cloneDeep(this.initialState) });
				}}
				editModeActions={() => {
					if (createMode) {
						return;
					}

					return (
						<Button
							type="button"
							variant="white"
							onClick={this.delete}
							disabled={this.state.isDeleting}
						>
							{this.state.isDeleting && (
								<i className="fas fa-fw fa-cog fa-spin" />
							)}
							{!this.state.isDeleting && (
								<i className="fas fa-fw fa-trash-alt" />
							)}
							&nbsp;Delete Contact
						</Button>
					);
				}}
			>
				<section>
					<Row>
						<Col lg={4}>
							<InputFormControl
								required={$schema.properties.firstName.isRequired}
								label="First Name"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('firstName', value)}
								value={contact.firstName}
							/>
						</Col>
						<Col lg={4}>
							<InputFormControl
								required={$schema.properties.lastName.isRequired}
								label="Last Name"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('lastName', value)}
								value={contact.lastName}
							/>
						</Col>
						<Col lg={4}>
							<InputFormControl
								required={
									$ContactCreateRequest.properties.emailAddress.isRequired
								}
								type="email"
								label="Email Address"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('emailAddress', value)}
								value={contact.emailAddress}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={4}>
							<InputFormControl
								label="Work Phone"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('workPhone', value)}
								value={contact.workPhone}
							/>
						</Col>
						<Col lg={4}>
							<InputFormControl
								label="Mobile Phone"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('mobilePhone', value)}
								value={contact.mobilePhone}
							/>
						</Col>
						<Col lg={4}>
							<InputFormControl
								label="Home Phone"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('homePhone', value)}
								value={contact.homePhone}
							/>
						</Col>
					</Row>
					<section className="mt-3">
						<h5 className="mb-0">Address</h5>
						<hr />
						<Row>
							<Col lg={4}>
								<InputFormControl
									label="Address Line 1"
									displayMode={this.state.displayMode}
									onChange={(value) => this.onChange('address1', value)}
									value={contact.address1}
								/>
							</Col>
							<Col lg={4}>
								<InputFormControl
									label="Address Line 2"
									displayMode={this.state.displayMode}
									onChange={(value) => this.onChange('address2', value)}
									value={contact.address2}
								/>
							</Col>
							<Col lg={4}>
								<InputFormControl
									label="City"
									displayMode={this.state.displayMode}
									onChange={(value) => this.onChange('city', value)}
									value={contact.city}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={4}>
								<InputFormControl
									label="State"
									displayMode={this.state.displayMode}
									onChange={(value) => this.onChange('state', value)}
									value={contact.state}
								/>
							</Col>
							<Col lg={4}>
								<InputFormControl
									label="Zip/Postal Code"
									displayMode={this.state.displayMode}
									onChange={(value) => this.onChange('postalCode', value)}
									value={contact.postalCode}
								/>
							</Col>
							<Col lg={4}>
								<CountrySelect
									valueKey="id"
									required={$schema.properties.countryId.isRequired}
									displayMode={this.state.displayMode}
									placeholder="Select country"
									label="Country"
									onChange={(selectedCountry) => {
										this.setState({ selectedCountry });
									}}
									value={this.state.selectedCountry}
									initialOptionId={contact.countryId}
									isClearable={false}
								/>
							</Col>
						</Row>
					</section>
				</section>
				<section className="mt-3">
					<h5 className="mb-0">Contact For</h5>
					<hr />
					<Row>
						<Col lg={4}>
							<BooleanRadioFormControl
								label="Billing"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('isBilling', value)}
								value={contact.isBilling ?? 0}
								customOption={{ ...primaryOption }}
							/>
						</Col>
						<Col lg={4}>
							<BooleanRadioFormControl
								label="Mailing"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('isMailing', value)}
								value={contact.isMailing ?? 0}
								customOption={{ ...primaryOption }}
							/>
						</Col>
						<Col lg={4}>
							<BooleanRadioFormControl
								label="Rates"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('isRates', value)}
								value={contact.isRates ?? 0}
								customOption={{ ...primaryOption }}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={4}>
							<BooleanRadioFormControl
								label="Sales"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('isSales', value)}
								value={contact.isSales ?? 0}
								customOption={{ ...primaryOption }}
							/>
						</Col>
						<Col lg={4}>
							<BooleanRadioFormControl
								label="Network Operations"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('isNoc', value)}
								value={contact.isNoc ?? 0}
								customOption={{ ...primaryOption }}
							/>
						</Col>
						<Col lg={4}>
							<BooleanRadioFormControl
								label="Provisioning"
								displayMode={this.state.displayMode}
								onChange={(value) => this.onChange('isProvisioning', value)}
								value={contact.isProvisioning ?? 0}
								customOption={{ ...primaryOption }}
							/>
						</Col>
					</Row>
				</section>
			</RtUiForm>
		);
	}

	private createOrUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const contactResource = new ContactResource();
		let newProfile: Contact;

		this.setState({ isSubmitting: true, error: undefined });
		const { onSuccess = () => ({}) } = this.props;

		try {
			if (this.props.profile) {
				newProfile = await contactResource.update(
					this.props.profile.contactId,
					{
						...this.state.contact,
						countryId: Number(this.state.selectedCountry?.id ?? 0)
					}
				);
			} else {
				newProfile = await contactResource.create({
					...this.state.contact,
					countryId: Number(this.state.selectedCountry?.id ?? 0)
				});
			}
			this.setState({ displayMode: true, isSubmitting: false });
			onSuccess(newProfile);
		} catch (error) {
			this.setState({ error, isSubmitting: false });
		}
	};

	private delete = async () => {
		const { profile, onDelete } = this.props;

		if (!profile) {
			return;
		}

		const confirm = await Confirmation.createDelete(
			`the contact for ${profile.firstName} ${profile.lastName} `
		);

		if (!confirm) {
			return;
		}

		try {
			const contactResource = new ContactResource();

			this.setState({ isDeleting: true });

			await contactResource.delete(profile.contactId);

			if (onDelete) {
				onDelete();
			}
		} catch (error) {
			this.setState({ error, isDeleting: false });
		}
	};

	private onChange = <K extends keyof ContactCreateRequest>(
		key: K,
		value: ContactCreateRequest[K]
	) => {
		if (!this.state.isSubmitting) {
			const { contact } = this.state;
			contact[key] = value;
			this.setState({ contact });
		}
	};
}
