import clsx from 'clsx';
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { Loading } from '../Loading';

interface IDragAndDropFilesUploaderProps {
	accept: string;
	onDrop: (files: File[]) => void;
	value: File[] | undefined;
	label?: string;
	sublabel?: React.ReactNode;
	required?: boolean;
	processing?: boolean;
}

interface IDragAndDropFilesUploaderState {
	isDraggingOver: boolean;
}

export class DragAndDropFilesUploader extends React.Component<
	IDragAndDropFilesUploaderProps,
	IDragAndDropFilesUploaderState
> {
	public state: IDragAndDropFilesUploaderState = {
		isDraggingOver: false
	};

	public fileUtils = new FileUtils();

	public onDrop(ev: React.DragEvent<HTMLElement>) {
		ev.preventDefault();
		ev.stopPropagation();

		this.attemptToGrabFiles(ev.dataTransfer.files);
	}

	public onFilePick(
		ev: React.ChangeEvent<HTMLInputElement>,
		appendToExisting: boolean = false
	) {
		ev.preventDefault();
		ev.stopPropagation();

		this.attemptToGrabFiles(ev.target.files, appendToExisting);
	}

	public async attemptToGrabFiles(
		fileList: FileList | null,
		appendToExisting: boolean = false
	) {
		const { accept, onDrop } = this.props;
		const acceptTypes = accept.split(',').map((a) => a.trim());
		const isCorrectTypeFn = (acceptType: string) =>
			acceptTypes.some((a) => a === acceptType);

		if (fileList === null) {
			return;
		}

		const returnFiles: File[] = [];
		const fileArray = Array.from(fileList);

		for (const file of fileArray) {
			const mimeType = await this.fileUtils.getMimeType(file);

			if (mimeType && isCorrectTypeFn(mimeType)) {
				returnFiles.push(file);
			}
		}

		this.setState({ isDraggingOver: false });

		if (returnFiles.length > 0) {
			if (appendToExisting && this.props.value) {
				onDrop([...this.props.value, ...returnFiles]);
			} else {
				onDrop(returnFiles);
			}
		}
	}

	public onDragEnter(ev: React.DragEvent<HTMLElement>) {
		ev.preventDefault();

		this.setState({ isDraggingOver: true });
	}

	public onDragEnd(ev: React.DragEvent<HTMLElement>) {
		ev.preventDefault();
		ev.stopPropagation();

		this.setState({ isDraggingOver: false });
	}

	public render() {
		const { value, processing = false } = this.props;
		const { isDraggingOver } = this.state;
		const isDropSuccess = value && value.length > 0;

		return (
			<Form.Group className="mb-3">
				{this.props.label && (
					<Form.Label>
						{this.props.label}
						{typeof this.props.sublabel === 'string' && (
							<span className="text-muted">{this.props.sublabel}</span>
						)}
						{React.isValidElement(this.props.sublabel) && this.props.sublabel}
					</Form.Label>
				)}
				<section
					style={{ position: 'relative' }}
					className={clsx(
						'dnd-file-uploader',
						isDraggingOver && 'dragover',
						////cSpell:disable-next-line
						isDropSuccess && 'dragsuccess'
					)}
					onDragEnter={(ev) => this.onDragEnter(ev)}
					onDragOver={(ev) => this.onDragEnter(ev)}
					onDragEnd={(ev) => this.onDragEnd(ev)}
					onDragLeave={(ev) => this.onDragEnd(ev)}
					onDrop={(ev) => this.onDrop(ev)}
				>
					{processing ? (
						<>
							<Loading internal message="Processing Files" />
						</>
					) : (
						<>
							{(!isDropSuccess || isDraggingOver) && (
								<>
									<span className="h2 me-3">
										<i className="fas fa-lg fa-cloud-upload-alt" />
									</span>
									<span className="text-center dnd-file-uploader-manual">
										<span>
											Drag and Drop{' '}
											{this.props.label && (
												<span className="fw-bold">{this.props.label}</span>
											)}
											{!this.props.label && 'Files'} Here <br /> or&nbsp;
										</span>
										<a>click here</a>
										<span>&nbsp;to browse.</span>
										<input
											type="file"
											accept={this.props.accept}
											multiple
											onChange={(ev) => this.onFilePick(ev)}
										/>
									</span>
								</>
							)}
							{isDropSuccess && !isDraggingOver && value && (
								<section className="w-100">
									<article className="h6">
										{value.length <= 3 && (
											<>
												<h6>Files Uploaded:</h6>
												<ul>
													{value.map((singleValue) => (
														<li key={singleValue.name}>
															<i>{singleValue.name}</i>{' '}
															<a
																href="#"
																className="ms-1"
																aria-label="Remove file"
																onClick={(e) => {
																	e.preventDefault();

																	this.props.onDrop(
																		this.props.value!.filter(
																			(f) => f.name !== singleValue.name
																		)
																	);
																}}
															>
																{/*cSpell:disable-next-line*/}
																<i className="fa-solid fa-circle-xmark"></i>
															</a>
														</li>
													))}
												</ul>
											</>
										)}
										{value.length > 3 && (
											<h6>{value.length.toLocaleString()} Files Uploaded.</h6>
										)}
									</article>
									<article className="dnd-file-uploader-manual mt-3">
										<Button variant="outline-white" className="text-white">
											<i className="fa-solid fa-file-circle-plus"></i> Add more
											files
											<input
												style={{ width: 'auto' }}
												type="file"
												multiple
												accept={this.props.accept}
												onChange={(ev) => {
													this.onFilePick(ev, true);
												}}
											/>
										</Button>
									</article>
								</section>
							)}
						</>
					)}
					<input
						type="text"
						tabIndex={-1}
						required={this.props.required}
						minLength={1}
						onChange={() => void 0}
						value={this.props.value ? '1' : ''}
						style={{
							height: 1,
							width: 0,
							opacity: 0.01,
							position: 'absolute',
							left: '50%',
							bottom: 0
						}}
					/>
				</section>
			</Form.Group>
		);
	}
}
