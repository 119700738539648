import ClearIcon from '@mui/icons-material/Clear';
import { Link } from '@mui/material';
import { takeRight } from 'lodash-es';
import { useState } from 'react';
import { RtxUiChip } from 'RtUi/components/rtx/ui/Chip/RtxUiChip';

interface ICprLabelValuesListProps {
	value: string[];
	shownLimit?: number;
}

export const CprLabelValuesList = ({
	value,
	shownLimit
}: ICprLabelValuesListProps) => {
	const [showAll, setShowAll] = useState<boolean>(false);

	const shouldHideValues =
		shownLimit !== undefined && value.length > shownLimit;
	const displayedValues =
		shouldHideValues && !showAll ? takeRight(value, shownLimit) : value;

	return (
		<div className="p-1 d-flex flex-wrap gap-1">
			{shouldHideValues && (
				<>
					{displayedValues.length !== value.length && (
						<Link
							component="button"
							variant="body2"
							className="Cpr-Value-Display-Link"
							onClick={() => {
								setShowAll(true);
							}}
						>
							<strong>
								Click to show all ({value.length - displayedValues.length} more)
							</strong>
						</Link>
					)}
					{showAll && (
						<Link
							component="button"
							className="Cpr-Value-Display-Link"
							variant="body2"
							onClick={() => {
								setShowAll(false);
							}}
						>
							<strong>Show less</strong>
						</Link>
					)}
				</>
			)}
			{displayedValues.map((option, index) => (
				<RtxUiChip
					label={option}
					key={index}
					deleteIcon={<ClearIcon />}
					className="Cpr-Value"
				/>
			))}
		</div>
	);
};
