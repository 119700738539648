import { startOfDay } from 'date-fns';
import FileSaver from 'file-saver';
import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	RateLoadCreateRequest,
	RateLoadErrorIndexRequest,
	RateLoadErrorIndexResponse,
	RateLoadFileProfileResponse,
	RateLoadIndexResponse,
	RateLoadProfileResponse,
	RateLoadProfileUrlRequest,
	RateLoadErrorUpdateRequest,
	RateLoadAmendmentProfileResponse,
	RateLoadSummaryResponse
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class RateLoadResource extends HttpResource<
	RateLoadIndexResponse,
	RateLoadProfileResponse
> {
	constructor() {
		super(RtCommonApiRoutes.RateLoads);
	}

	public create(file: File, params: RateLoadCreateRequest) {
		const notificationStartOfDay = startOfDay(params.notificationTs);
		const notificationStartOfDayUtc = new Date(
			Date.UTC(
				notificationStartOfDay.getFullYear(),
				notificationStartOfDay.getMonth(),
				notificationStartOfDay.getDate(),
				notificationStartOfDay.getHours(),
				notificationStartOfDay.getMinutes(),
				notificationStartOfDay.getSeconds()
			)
		);

		const effectiveStartOfDay = startOfDay(params.effectiveTs);
		const effectiveStartOfDayUtc = new Date(
			Date.UTC(
				effectiveStartOfDay.getFullYear(),
				effectiveStartOfDay.getMonth(),
				effectiveStartOfDay.getDate(),
				effectiveStartOfDay.getHours(),
				effectiveStartOfDay.getMinutes(),
				effectiveStartOfDay.getSeconds()
			)
		);

		params.notificationTs = notificationStartOfDayUtc;
		params.effectiveTs = effectiveStartOfDayUtc;

		const body = new FormData();
		body.append('file', file);

		for (const [key, value] of Object.entries(params)) {
			body.append(key, value);
		}

		return this.fetchWithRoute<RateLoadProfileResponse>(
			RtCommonApiRoutes.RateLoads.Create,
			{
				body,
				removeContentType: true
			}
		);
	}

	public delete(urlParams: RateLoadProfileUrlRequest) {
		return this.fetchWithRoute<void>(RtCommonApiRoutes.RateLoads.Delete, {
			urlParams
		});
	}

	public approve(urlParams: RateLoadProfileUrlRequest) {
		return this.fetchWithRoute(RtCommonApiRoutes.RateLoadApprove.Update, {
			urlParams
		});
	}

	public getErrors(rateLoadId: number, params: RateLoadErrorIndexRequest = {}) {
		const urlParams: RateLoadProfileUrlRequest = { rateLoadId };

		return this.fetchWithRoute<RateLoadErrorIndexResponse[]>(
			RtCommonApiRoutes.RateLoadErrors.Index,
			{
				params,
				urlParams
			}
		);
	}

	public approveExceptions(
		rateLoadId: number,
		bodyParams: RateLoadErrorUpdateRequest
	) {
		const body = JSON.stringify(bodyParams);
		const urlParams: RateLoadProfileUrlRequest = {
			rateLoadId
		};

		return this.fetchWithRoute<RateLoadErrorIndexResponse[]>(
			RtCommonApiRoutes.RateLoadApproveExceptions.Update,
			{
				body,
				urlParams
			}
		);
	}

	public getAnalysisSummary(urlParams: RateLoadProfileUrlRequest) {
		return this.fetchWithRoute<RateLoadSummaryResponse>(
			RtCommonApiRoutes.RateLoadSummary.Index,
			{ urlParams }
		);
	}

	public async downloadRateLoad(rateLoadId: number) {
		const urlParams: RateLoadProfileUrlRequest = { rateLoadId };
		const { signedUrl, fileName } =
			await this.fetchWithRoute<RateLoadFileProfileResponse>(
				RtCommonApiRoutes.RateLoadFile.Profile,
				{
					urlParams
				}
			);

		const response = await fetch(signedUrl, {
			method: 'GET',
			mode: 'cors',
			credentials: 'omit'
		});

		if (response.ok) {
			const blob = await response.blob();
			const blobUrl = URL.createObjectURL(blob);
			FileSaver.saveAs(blobUrl, fileName);
		} else {
			const text = await response.text();

			throw new Error(text);
		}
	}

	public async downloadRateAmendment(rateLoadId: number) {
		const urlParams: RateLoadProfileUrlRequest = { rateLoadId };
		const { signedUrl, fileName } =
			await this.fetchWithRoute<RateLoadAmendmentProfileResponse>(
				RtCommonApiRoutes.RateLoadAmendment.Profile,
				{
					urlParams
				}
			);

		const response = await fetch(signedUrl, {
			method: 'GET',
			mode: 'cors',
			credentials: 'omit'
		});

		if (response.ok) {
			const blob = await response.blob();
			const blobUrl = URL.createObjectURL(blob);
			FileSaver.saveAs(blobUrl, fileName);
		} else {
			const text = await response.text();

			throw new Error(text);
		}
	}
}
