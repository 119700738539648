/* eslint-disable @typescript-eslint/type-annotation-spacing */
import { capitalize, cloneDeep } from 'lodash-es';
import React from 'react';
import { Card } from 'react-bootstrap';
import {
	InvoiceCycleGroup,
	JurisPrecedenceOptions,
	SubscriptionBillingProfileResponse,
	TimezoneIndexResponse
} from 'RtModels';
import { TimezoneSelect } from 'RtUi/app/rtCommon/Timezones/lib/controls/TimezoneSelect';
import { InvoiceCycleGroupSelect } from 'RtUi/app/rtSip/Invoices/lib/controls/InvoiceCycleGroupSelect';
import { RatingPrecedenceSelect } from 'RtUi/app/AccountManagement/Subscriptions/lib/controls/RatingPrecedenceSelect';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

interface ISubscriptionBillingFormProps {
	subscriptionId: number;
	onSuccess?: (profile: SubscriptionBillingProfileResponse) => void;
	onCancel?: () => void;
}

interface ISubscriptionBillingFormState {
	isSubmitting: boolean;
	displayMode: boolean;
	editingSubscription: SubscriptionBillingProfileResponse | null;
	invoiceCycleGroup?: InvoiceCycleGroup;
	timeZone?: TimezoneIndexResponse;
	ratingPrecedence?: Array<
		ISimpleSelectFormControlOption<JurisPrecedenceOptions>
	>;
	error?: any;
}

export class SubscriptionBillingForm extends React.Component<
	ISubscriptionBillingFormProps,
	ISubscriptionBillingFormState
> {
	private initialState: ISubscriptionBillingFormState;

	constructor(props: ISubscriptionBillingFormProps) {
		super(props);

		this.initialState = {
			isSubmitting: false,
			displayMode: true,
			editingSubscription: null
		};

		this.state = cloneDeep(this.initialState);
	}

	public componentDidMount() {
		this.setUpInitialState();
	}

	public componentDidUpdate(
		prevProps: Readonly<ISubscriptionBillingFormProps>
	) {
		if (prevProps.subscriptionId !== this.props.subscriptionId) {
			this.setUpInitialState();
		}
	}

	private onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const { editingSubscription } = this.state;
		const { onSuccess = () => ({}) } = this.props;

		if (!editingSubscription) {
			return;
		}

		this.setState({ isSubmitting: true, error: undefined });

		try {
			const rs = new SubscriptionResource();

			const profile = await rs.updateSubscriptionBilling(
				this.props.subscriptionId,
				{
					invoiceCycleGroupId: this.state.invoiceCycleGroup
						? this.state.invoiceCycleGroup.invoiceCycleGroupId
						: editingSubscription.invoiceCycleGroupId,
					timezoneId: this.state.timeZone
						? this.state.timeZone.timezoneId
						: editingSubscription.timezoneId!,
					billDays: editingSubscription.billDays!,
					dueDays: editingSubscription.dueDays!,
					disputeDays: editingSubscription.disputeDays!,
					disputeResponseDays: editingSubscription.disputeResponseDays!,
					rateKeyDeleteDays: editingSubscription.rateKeyDeleteDays!,
					rateKeyMatchIncreaseDays:
						editingSubscription.rateKeyMatchIncreaseDays!,
					rateKeyExpandIncreaseDays:
						editingSubscription.rateKeyExpandIncreaseDays!,
					defaultInterstateRate: editingSubscription.defaultInterstateRate,
					defaultIntrastateRate: editingSubscription.defaultIntrastateRate,
					defaultIndeterminateRate:
						editingSubscription.defaultIndeterminateRate,
					defaultIncrementSeconds: editingSubscription.defaultIncrementSeconds,
					defaultMinimumSeconds: editingSubscription.defaultMinimumSeconds,
					ratingParametersPriority:
						editingSubscription.ratingParametersPriority,
					defaultRoundingPrecision: editingSubscription.defaultRoundingPrecision
				}
			);

			this.setState(
				{
					displayMode: true,
					isSubmitting: false,
					editingSubscription: profile
				},
				() => {
					this.initialState = this.state;
				}
			);

			this.setState({ isSubmitting: false }, () => {
				onSuccess(profile);
			});
		} catch (error) {
			this.setState({ isSubmitting: false, error });
		}
	};

	private onChange = <K extends keyof SubscriptionBillingProfileResponse>(
		key: K,
		value: SubscriptionBillingProfileResponse[K]
	) => {
		if (!this.state.isSubmitting && this.state.editingSubscription) {
			const { editingSubscription } = this.state;
			editingSubscription[key] = value;
			this.setState({ editingSubscription });
		}
	};

	public render() {
		if (!this.state.editingSubscription) {
			return <Loading />;
		}

		const { editingSubscription, displayMode } = this.state;

		return (
			<RtUiForm
				isInline
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				displayMode={this.state.displayMode}
				onSubmit={this.onSubmit}
				onChange={() => {
					this.setState((state) => ({ displayMode: !state.displayMode }));
				}}
				onCancel={() => {
					this.setState({ ...cloneDeep(this.initialState) }, () => {
						if (this.props.onCancel) {
							this.props.onCancel();
						}
					});
				}}
			>
				<Card>
					<Card.Body>
						<InvoiceCycleGroupSelect
							name="invoiceCycleGroupId"
							label="Invoice Cycle Group"
							displayMode={displayMode}
							onChange={(invoiceCycleGroup: InvoiceCycleGroup) =>
								this.setState({ invoiceCycleGroup })
							}
							value={this.state.invoiceCycleGroup}
							initialOptionId={editingSubscription.invoiceCycleGroupId?.toString()}
						/>
						<PrecisionFormControl
							name="billDays"
							precision={0}
							required
							label="Bill Days"
							displayMode={displayMode}
							value={editingSubscription.billDays}
							onChange={(value) => this.onChange('billDays', Number(value))}
						/>
						<PrecisionFormControl
							name="disputeDays"
							precision={0}
							required
							label="Dispute Days"
							displayMode={displayMode}
							value={editingSubscription.disputeDays}
							onChange={(value) => this.onChange('disputeDays', Number(value))}
						/>
						<PrecisionFormControl
							name="rateKeyDeleteDays"
							precision={0}
							required
							label="Rate Key Delete Days"
							displayMode={displayMode}
							value={editingSubscription.rateKeyDeleteDays}
							onChange={(value) =>
								this.onChange('rateKeyDeleteDays', Number(value))
							}
						/>
						<PrecisionFormControl
							name="rateKeyExpandIncreaseDays"
							precision={0}
							required
							label="Rate Key Expand Increase Days"
							displayMode={displayMode}
							value={editingSubscription.rateKeyExpandIncreaseDays}
							onChange={(value) =>
								this.onChange('rateKeyExpandIncreaseDays', Number(value))
							}
						/>
						<PrecisionFormControl
							name="defaultMinimumSeconds"
							precision={0}
							label="Default Minimum Seconds"
							displayMode={displayMode}
							value={editingSubscription.defaultMinimumSeconds}
							onChange={(value) =>
								this.onChange('defaultMinimumSeconds', Number(value))
							}
						/>
						<PrecisionFormControl
							name="defaultIncrementSeconds"
							precision={0}
							label="Default Increment Seconds"
							displayMode={displayMode}
							value={editingSubscription.defaultIncrementSeconds}
							onChange={(value) =>
								this.onChange('defaultIncrementSeconds', Number(value))
							}
						/>
						<InputFormControl
							name="defaultRoundingPrecision"
							displayMode={displayMode}
							label="Default Rounding Precision"
							value={editingSubscription.defaultRoundingPrecision?.toString()}
							onChange={(value) =>
								this.onChange('defaultRoundingPrecision', Number(value))
							}
						/>
						<TimezoneSelect
							name="timezoneId"
							required
							displayMode={displayMode}
							onChange={(timeZone: TimezoneIndexResponse) =>
								this.setState({ timeZone })
							}
							value={this.state.timeZone}
							initialOptionId={String(editingSubscription.timezoneId)}
						/>
						<PrecisionFormControl
							name="dueDays"
							precision={0}
							required
							label="Due Days"
							displayMode={displayMode}
							value={editingSubscription.dueDays}
							onChange={(value) => this.onChange('dueDays', Number(value))}
						/>
						<PrecisionFormControl
							name="disputeResponseDays"
							precision={0}
							required
							label="Dispute Response Days"
							displayMode={displayMode}
							value={editingSubscription.disputeResponseDays}
							onChange={(value) =>
								this.onChange('disputeResponseDays', Number(value))
							}
						/>
						<PrecisionFormControl
							name="rateKeyMatchIncreaseDays"
							precision={0}
							required
							label="Rate Key Match Increase Days"
							displayMode={displayMode}
							value={editingSubscription.rateKeyMatchIncreaseDays}
							onChange={(value) =>
								this.onChange('rateKeyMatchIncreaseDays', Number(value))
							}
						/>
						<PrecisionFormControl
							name="defaultInterstateRate"
							precision={6}
							label="Default Interstate Rate"
							displayMode={displayMode}
							value={editingSubscription.defaultInterstateRate}
							onChange={(value) =>
								this.onChange('defaultInterstateRate', Number(value))
							}
						/>
						<PrecisionFormControl
							name="defaultIntrastateRate"
							precision={6}
							label="Default Intrastate Rate"
							displayMode={displayMode}
							value={editingSubscription.defaultIntrastateRate}
							onChange={(value) =>
								this.onChange('defaultIntrastateRate', Number(value))
							}
						/>
						<PrecisionFormControl
							name="defaultIndeterminateRate"
							precision={6}
							label="Default Indeterminate Rate"
							displayMode={displayMode}
							value={editingSubscription.defaultIndeterminateRate}
							onChange={(value) =>
								this.onChange('defaultIndeterminateRate', Number(value))
							}
						/>
						{displayMode && (
							<InputFormControl
								label="Rating Precedence"
								displayMode
								value={editingSubscription.ratingParametersPriority
									?.map((v) => capitalize(v))
									.join(',')}
							/>
						)}
						{!displayMode && (
							<RatingPrecedenceSelect<true>
								multi
								label="Rating Precedence"
								displayMode={displayMode}
								initialOptionId={
									editingSubscription?.ratingParametersPriority
										? editingSubscription.ratingParametersPriority
										: undefined
								}
								value={this.state.ratingPrecedence}
								onChange={(ratingPrecedence) => {
									this.setState({ ratingPrecedence });
									this.onChange(
										'ratingParametersPriority',
										ratingPrecedence.map((v: any) => v.value)
									);
								}}
							/>
						)}
					</Card.Body>
				</Card>
			</RtUiForm>
		);
	}

	private async setUpInitialState() {
		const rs = new SubscriptionResource();
		const editingSubscription = await rs.getSubscriptionBilling(
			this.props.subscriptionId
		);

		this.initialState = {
			...this.initialState,
			editingSubscription
		};

		this.setState(cloneDeep(this.initialState));
	}
}
