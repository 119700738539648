import { values } from 'lodash-es';
import * as moment from 'moment-timezone';
import {
	DatetimeFormControl,
	IDatetimeFormControlProps
} from '../DatetimeFormControl';
import { FormControl } from '../FormControl';

interface IEffectiveTimeFormControlProps<IsRequired extends boolean = false>
	extends IDatetimeFormControlProps<IsRequired> {
	isAfter?: moment.Moment;
	appendToBody?: boolean;
	initAsNow?: boolean;
	disablePresets?: boolean;
	hideControlGroup?: boolean;
	initAtFurthermostPreset?: boolean;
	subLabel?: string;
}

export class EffectiveTimeFormControl<
	IsRequired extends boolean = false
> extends FormControl<
	moment.Moment,
	false,
	IEffectiveTimeFormControlProps<IsRequired>
> {
	public resource = undefined;
	public state = {
		formLabel: 'Effective Time'
	};

	public componentDidMount() {
		super.componentDidMount();

		const { initAtFurthermostPreset = false } = this.props;

		if (initAtFurthermostPreset) {
			const { onChangeWithIsNow, onChange } = this.props;
			const presets = DatetimeFormControl.GetDatetimeFormControlPresets();
			const presetValues = values(presets);
			const furthestDate = moment.max(presetValues);

			if (onChange) {
				onChange(furthestDate);
			}

			if (onChangeWithIsNow) {
				onChangeWithIsNow(furthestDate, false);
			}
		}
	}

	public render() {
		const { initAtFurthermostPreset = false } = this.props;
		const { initAsNow = !initAtFurthermostPreset } = this.props;

		return (
			<DatetimeFormControl
				{...this.props}
				label={this.props.label ?? this.state.formLabel}
				initAsNow={initAsNow}
				minutesStep={15}
			/>
		);
	}
}
