/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	FileLogIndexRequest,
	FileLogTotalsIndexRequest,
	FileLogTotalsIndexResponse,
	GuardianFileLog
} from 'RtModels';
import {
	FileLogRequest,
	FileLogResponse
} from 'RtUi/app/rtVue/FileLog/lib/grids/FileLogGrid';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchFileLogs = async (
	queryParams?: FileLogIndexRequest
): Promise<FullResponse<GuardianFileLog[]>> => {
	return handleGetRequest(RtVueApiRoutes.FileLogs.Index, {
		queryParams,
		includeFullResponse: true
	});
};

const fetchFileLogTotals = async (
	queryParams?: FileLogTotalsIndexRequest
): Promise<FullResponse<FileLogTotalsIndexResponse[]>> => {
	return handleGetRequest(RtVueApiRoutes.FileLogTotals.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetFileLogs = <T extends FileLogRequest>(params?: T) => {
	return useQuery<FullResponse<Array<FileLogResponse<T>>>, Error>(
		['getFileLogs', params],
		() => {
			if (params?.fileStreamIds && params.fileStreamIds?.length > 1) {
				const { fileStreamIds, createdTsStart, createdTsEnd } = params;

				return fetchFileLogTotals({
					fileStreamIds,
					createdTsStart,
					createdTsEnd
				});
			}
			return fetchFileLogs(params) as any;
		}
	);
};
