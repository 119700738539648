/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $JobIndexRequest = {
    properties: {
        resourceId: {
            type: 'Array',
        },
        resourceConfigurationId: {
            type: 'Array',
        },
        jobId: {
            type: 'string',
            maxLength: 36,
            minLength: 36,
        },
        scheduledBeforeTs: {
            type: 'Date',
            format: 'date-time',
        },
        scheduledAfterTs: {
            type: 'Date',
            format: 'date-time',
        },
        submittedBy: {
            type: 'number',
            minimum: 1,
        },
        contains: {
            type: 'string',
        },
        search: {
            type: 'string',
            minLength: 4,
        },
        includeResourceStatusId: {
            type: 'Array',
        },
        excludeResourceStatusId: {
            type: 'Array',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
        },
    },
};