import {
	RoutingCacheTypes,
	TemplateCprProfileRequest,
	TemplateCprProfileResponse,
	TemplateProfileRequest,
	TollFreeCprProfileRequest,
	TollFreeCprProfileResponse,
	TollFreeProfileRequest
} from 'RtModels';
import { CprsRouterClass } from 'RtUi/app/rt800/Cprs/Cprs.router';
import { CprActions } from 'RtUi/app/rt800/Cprs/lib/Constants';
import { CprLergHttp } from 'RtUi/app/rt800/Cprs/lib/Http/CprLergHttp';
import { ConfigAdminData } from 'RtUi/app/rt800/Cprs/lib/components/ConfigAdminData';
import { CprEditorActionForm } from 'RtUi/app/rt800/Cprs/lib/components/CprEditorActionForm';
import { CprErrorsAlert } from 'RtUi/app/rt800/Cprs/lib/components/CprErrorsAlert';
import { CprErrorsTabbedCustomerHeader } from 'RtUi/app/rt800/Cprs/lib/components/CprErrorsTabbedCustomHeader';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';
import { CprSearchForm } from 'RtUi/app/rt800/Cprs/lib/forms/CprSearchForm';
import { InstantValidationForm } from 'RtUi/app/rt800/Cprs/lib/forms/InstantValidationForm';
import {
	CprDataGrid,
	ICprDataGridRef
} from 'RtUi/app/rt800/Cprs/lib/grids/CprDataGrid';
import {
	CprLabelDataGrid,
	ICprLabelDataGridRef
} from 'RtUi/app/rt800/Cprs/lib/grids/CprLabelDataGrid';
import { ScpGrid } from 'RtUi/app/rt800/Cprs/lib/grids/ScpGrid';
import { useGetCpr, useGetCprs } from 'RtUi/app/rt800/Cprs/lib/services';
import {
	getCprV2,
	getNextApproximateNowDateTime
} from 'RtUi/app/rt800/Cprs/lib/util';
import { TCprLergSearchRequest } from 'RtUi/app/rt800/Cprs/lib/util/Constants';
import { useGetNumbers } from 'RtUi/app/rt800/Numbers/lib/services';
import { Loading } from 'RtUi/components/ui/Loading';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
import { clone } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useMount, useUpdate } from 'react-use';

export type CprProfileResponse =
	| TemplateCprProfileResponse
	| TollFreeCprProfileResponse;

export type CprProfileRequest<T extends CprProfileResponse> =
	T extends TemplateCprProfileResponse
		? TemplateProfileRequest
		: TollFreeProfileRequest;

export type CprQueryProfileRequest<T extends CprProfileResponse> =
	T extends TemplateCprProfileResponse
		? TemplateCprProfileRequest
		: TollFreeCprProfileRequest;

export type IsNumberCpr<T extends CprProfileResponse> =
	T extends TemplateCprProfileResponse ? undefined : true;

interface ICprProfileContainerProps<T extends CprProfileResponse> {
	router: CprsRouterClass;
	requestParams: CprProfileRequest<T>;
	isNumber?: IsNumberCpr<T>;
}

export const CprProfileContainer = <T extends CprProfileResponse>({
	router,
	requestParams,
	isNumber
}: ICprProfileContainerProps<T>) => {
	const [tabs, activeTab, setActiveTab] = useTabs(router.getProfileTabs());
	const navigate = useNavigate();
	const { id = '', effectiveTs = '' } = useParams();
	const updateComponent = useUpdate();
	const [editMode, setEditMode] = useState<CprActions>();
	const [displayMode, setDisplayMode] = useState<boolean>(true);
	const [isLergReady, setIsLergReady] = useState<boolean>(false);
	const cadFormRef = useRef<HTMLFormElement>(null);
	const [generalCadError, setGeneralCadError] = useState<string>();
	const [highlightFilter, setHighlightFilter] =
		useState<TCprLergSearchRequest>();
	const [mgiProfile, setMgiProfile] = useState<Cpr>();

	const {
		data,
		isFetching: isLoading,
		refetch
	} = useGetCpr(isNumber === true, requestParams, {
		effectiveTs: new Date(effectiveTs)
	});

	const { data: cprs, refetch: refetchCprs } = useGetCprs(
		requestParams,
		isNumber === true
	);

	useMount(async () => {
		const cprLergHttpClient = new CprLergHttp();
		await cprLergHttpClient.loadCprLerg();
		setIsLergReady(true);
	});

	useEffect(() => {
		if (data && isLergReady) {
			setDisplayMode(true);
			setEditMode(undefined);
			setMgiProfile(getCprV2(id, clone(data), effectiveTs));
		}
	}, [id, data, effectiveTs, isLergReady]);

	const { data: tfns } = useGetNumbers({ templateName: id }, !isNumber);
	const routing = useMemo(() => cprs?.data ?? [], [cprs]);
	const possibleNextEffectiveTimestamp = useMemo(
		() => getNextApproximateNowDateTime(routing),
		[routing]
	);
	const isNumberAndHasTemplateAssigned = useMemo(() => {
		return (
			mgiProfile?.routingCacheTypeId === RoutingCacheTypes.TollFree &&
			mgiProfile?.getSourceTemplateName() !== null
		);
	}, [mgiProfile]);

	const cprLabelDataGrid = useRef<ICprLabelDataGridRef>(null);
	const cprDataGrid = useRef<ICprDataGridRef>(null);

	if (isLoading || !mgiProfile) {
		return <Loading />;
	}

	const goBackToParentsRouting = () => {
		const path = router.getParentProfileRoute(id, 'Routing Profiles');
		navigate(path);
	};

	const updateHighlighter = (highlightFilter?: TCprLergSearchRequest) => {
		mgiProfile.setHighlightChildren(
			highlightFilter?.searchForDescendants ?? false
		);
		mgiProfile.setHighlightParents(
			highlightFilter?.searchForAscendants ?? false
		);

		mgiProfile.setHighlightValue(
			highlightFilter?.lerg?.key,
			highlightFilter?.lerg?.value,
			highlightFilter?.cic
		);

		const selectedCprRow = mgiProfile.getSelectedHighlightedRow()?.getCprIdx();
		if (selectedCprRow !== undefined && cprDataGrid.current) {
			cprDataGrid.current.pageToIndex(selectedCprRow);
		}

		const selectedCprLabelRow = mgiProfile
			.getSelectedHighlightedLabel()
			?.getCprIdx();

		if (selectedCprLabelRow !== undefined && cprLabelDataGrid.current) {
			cprLabelDataGrid.current.pageToIndex(selectedCprLabelRow);
		}

		setHighlightFilter(highlightFilter);
	};

	const triggerEditMode = (editMode: CprActions | undefined) => {
		if (!mgiProfile) {
			return;
		}
		const displayMode = editMode === 'delete' || editMode === undefined;
		setDisplayMode(displayMode);
		setEditMode(editMode);
	};

	const updateCpr = (cpr: Cpr) => {
		setMgiProfile(cpr);
		checkCadValidity();
		updateComponent();
	};

	const checkCadValidity = () => {
		const isValid = cadFormRef.current?.checkValidity();

		if (!isValid) {
			setGeneralCadError(
				'Please fill in the missing fields below before submitting.'
			);
			setActiveTab(tabs.Admin.header);
		} else {
			setGeneralCadError(undefined);
		}

		return Boolean(isValid);
	};

	const resetProfile = () => {
		if (!data) {
			return;
		}
		setMgiProfile(getCprV2(id, clone(data), effectiveTs));
		setDisplayMode(true);
		setEditMode(undefined);
	};

	const cprSearchFormComponent = (
		<CprSearchForm searchReq={highlightFilter} onChange={updateHighlighter} />
	);

	return (
		<CprProfileContext.Provider
			value={{
				id,
				cpr: mgiProfile,
				updateCpr,
				cprs: cprs?.data ?? [],
				isNumberAndHasTemplateAssigned,
				isNumber: Boolean(isNumber),
				refetchCpr: refetch,
				router,
				displayMode,
				setDisplayMode,
				refetchCprs,
				editMode,
				triggerEditMode,
				tfns: tfns?.data ?? [],
				cprDataGrid,
				cprLabelDataGrid,
				activeTab: activeTab.header,
				setActiveTab,
				resetProfile,
				checkCadValidity
			}}
		>
			<TabbedLayout<Cpr>
				router={router}
				profile={mgiProfile}
				activeTab={activeTab.header}
				onTabChange={setActiveTab}
				onBackClick={!displayMode ? undefined : goBackToParentsRouting}
				backBtnTooltip="Back to Routing Profiles"
				appendToHeader={
					<CprEditorActionForm
						cpr={mgiProfile}
						goBackToParentsRouting={goBackToParentsRouting}
					/>
				}
			>
				<TabbedLayoutTab {...tabs.Admin} alwaysRender>
					{generalCadError && (
						<Alert variant="danger d-flex justify-content-start align-items-start">
							<i className="fas fa-fw fa-exclamation-triangle me-2" />
							<span>{generalCadError}</span>
						</Alert>
					)}
					<ConfigAdminData
						ref={cadFormRef}
						mgiProfile={mgiProfile}
						nextEffectiveTs={possibleNextEffectiveTimestamp}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					{...tabs.Cpr}
					alwaysRender
					isHidden={isNumberAndHasTemplateAssigned}
				>
					{cprSearchFormComponent}
					<CprDataGrid
						ref={cprDataGrid}
						mgiProfile={mgiProfile}
						displayMode={displayMode}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					{...tabs.Lad}
					alwaysRender
					isHidden={isNumberAndHasTemplateAssigned}
				>
					{cprSearchFormComponent}
					<CprLabelDataGrid
						ref={cprLabelDataGrid}
						cpr={mgiProfile}
						displayMode={displayMode}
					/>
				</TabbedLayoutTab>
				<TabbedLayoutTab
					{...tabs.Validation}
					isHidden={isNumberAndHasTemplateAssigned}
					customHeader={<CprErrorsTabbedCustomerHeader />}
				>
					<CprErrorsAlert />
				</TabbedLayoutTab>
				<TabbedLayoutTab {...tabs.Scp} isHidden={!displayMode}>
					<ScpGrid
						isNumber={isNumber}
						urlParams={requestParams}
						queryParams={{ effectiveTs: new Date(effectiveTs) }}
					/>
				</TabbedLayoutTab>
				{!isNumber && (
					<TabbedLayoutTab {...tabs.InstantValidation} alwaysRender>
						<InstantValidationForm
							templateName={id}
							effectiveTs={new Date(effectiveTs)}
						/>
					</TabbedLayoutTab>
				)}
			</TabbedLayout>
		</CprProfileContext.Provider>
	);
};
