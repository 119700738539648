import { LcrScenarioIndexResponse } from 'RtModels';
import { LcrScenariosResource } from 'RtUi/app/rtLco/Scenarios/lib/resources/LcrScenariosResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface LcrScenarioDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		LcrScenarioIndexResponse,
		T
	> {}

export const LcrScenarioDataGridCache =
	new ResourceCacheMap<LcrScenarioIndexResponse>(
		new LcrScenariosResource(),
		'lcrScenarioId',
		'label'
	);

export const LcrScenariosDataGridColumn = <T = any,>(
	config: LcrScenarioDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: LcrScenarioDataGridCache,
		...config
	});
};
