import { Button } from 'react-bootstrap';

export function CheckboxButton(props: {
	name: string;
	active: boolean;
	onClick: () => void;
	disabled?: boolean;
}) {
	return (
		<Button
			type="button"
			variant="white"
			disabled={props.disabled}
			onClick={props.onClick}
		>
			{props.active ? (
				<i className="fas fa-fw fa-check-square text-primary" />
			) : (
				<i className="far fa-fw fa-square" />
			)}
			<span>&nbsp;{props.name}</span>
		</Button>
	);
}
