import {
	RoutingRuleMatchType,
	RoutingRuleMatchingResponse,
	RoutingRulesMatchTypesIndexResponse
} from 'RtModels';
import { RoutingRuleMatchPatternFormControl } from 'RtUi/app/rtSip/RoutingRules/lib/controls/RoutingRuleMatchPatternFormControl';
import { RoutingRuleMatchTypeSelect } from 'RtUi/app/rtSip/RoutingRules/lib/controls/RoutingRuleMatchTypeSelect';
import { RoutingRuleContext } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleEditor';
import { CheckboxFormControl } from 'RtUi/components/form/CheckboxFormControl';
import { useContext, useState } from 'react';
import {
	Button,
	Card,
	Col,
	OverlayTrigger,
	Row,
	Tooltip
} from 'react-bootstrap';

interface IRoutingRuleMatchingEditorProps {
	displayMode?: boolean;
}

export const RoutingRuleMatchingEditor = ({
	displayMode
}: IRoutingRuleMatchingEditorProps): JSX.Element => {
	const [routingRuleMatchTypeSelect, setRoutingRuleMatchTypeSelect] =
		useState<Map<number, RoutingRulesMatchTypesIndexResponse>>();
	const {
		value: { matching },
		onChange
	} = useContext(RoutingRuleContext);
	const [value, setValue] = useState<RoutingRuleMatchingResponse[]>(matching);

	const onChangeHandler = <K extends keyof RoutingRuleMatchingResponse>(
		index: number,
		key: K,
		val: RoutingRuleMatchingResponse[K]
	) => {
		setValue((currentState) => {
			const newValue = [...currentState];
			newValue[index][key] = val;
			onChange('matching', newValue);

			return newValue;
		});
	};

	const onChangeRoutingRuleMatchTypeHandler = (
		index: number,
		value: RoutingRulesMatchTypesIndexResponse
	) => {
		setRoutingRuleMatchTypeSelect((currentState) => {
			const newState = new Map(currentState);
			newState.set(index, value);

			onChangeHandler(
				index,
				'routingRuleMatchTypeId',
				value.routingRuleMatchTypeId
			);

			return newState;
		});
	};

	const addMatching = () => {
		setValue((currentState) => {
			const newValue = [...currentState];
			newValue.push({
				routingRuleMatchTypeId: RoutingRuleMatchType.FromIso,
				pattern: '',
				isOmit: 0
			});

			onChange('matching', newValue);
			return newValue;
		});
	};

	const removeMatching = (index: number) => {
		setValue((currentState) => {
			const newValue = [...currentState];
			newValue.splice(index, 1);

			onChange('matching', newValue);
			return newValue;
		});
	};

	return (
		<Card>
			<Card.Header>
				<div className="d-flex justify-content-end">
					<Button onClick={() => addMatching()} disabled={displayMode}>
						<i className="fas fa-fw fa-plus fa-lg" />
						&nbsp;Add Matching
					</Button>
				</div>
			</Card.Header>
			<Card.Body>
				{!value.length && <p>Not any matching rule added yet!</p>}
				{value.map((matchingValue, index) => (
					<Row key={index}>
						<Col md={11}>
							<Row>
								<Col md={4}>
									<RoutingRuleMatchTypeSelect
										hideLabel
										value={routingRuleMatchTypeSelect?.get(index)}
										required
										isClearable={false}
										displayMode={displayMode}
										onChange={(matchType) =>
											onChangeRoutingRuleMatchTypeHandler(index, matchType)
										}
										initialOptionId={matchingValue.routingRuleMatchTypeId}
										appendDropdownToBody
									/>
								</Col>
								<Col md={7}>
									<RoutingRuleMatchPatternFormControl
										hideLabel
										matchType={matchingValue.routingRuleMatchTypeId}
										value={matchingValue.pattern}
										required
										displayMode={displayMode}
										onChange={(pattern) =>
											onChangeHandler(index, 'pattern', pattern)
										}
									/>
								</Col>
								<Col md={1}>
									<CheckboxFormControl
										label="Omit"
										displayMode={displayMode}
										value={Boolean(matchingValue.isOmit ?? 0)}
										onChange={(isOmit) =>
											onChangeHandler(index, 'isOmit', Number(isOmit))
										}
									/>
								</Col>
							</Row>
						</Col>
						<Col
							md={1}
							className="d-flex align-items-center justify-content-end"
						>
							<OverlayTrigger
								overlay={(props) => (
									<Tooltip id={`matching-tooltip`} {...props}>
										Remove Matching
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										variant="danger"
										disabled={displayMode}
										onClick={() => removeMatching(index)}
									>
										<i className="fas fa-fw fa-trash fa-md" />
									</Button>
								)}
							</OverlayTrigger>
						</Col>
					</Row>
				))}
			</Card.Body>
		</Card>
	);
};
