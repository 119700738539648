/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FileStreamUpdateRequest = {
    properties: {
        description: {
            type: 'string',
            maxLength: 255,
        },
        fileLabel: {
            type: 'string',
            maxLength: 25,
        },
        isActive: {
            type: 'number',
            maximum: 1,
        },
        fileTypeId: {
            type: 'number',
        },
        isDownload: {
            type: 'number',
            maximum: 1,
        },
        downloadRemoteProtocol: {
            type: 'DownloadTypes',
        },
        downloadRemoteCredentials: {
            type: 'string',
            maxLength: 500,
        },
        downloadRemotePath: {
            type: 'string',
            maxLength: 500,
        },
        downloadRemoteDelete: {
            type: 'number',
            maximum: 1,
        },
        downloadCompressionType: {
            type: 'string',
            maxLength: 10,
        },
        downloadFileRegex: {
            type: 'string',
            maxLength: 250,
        },
        downloadLocalPath: {
            type: 'string',
            maxLength: 250,
        },
        workingLocalPath: {
            type: 'string',
            maxLength: 255,
        },
        workingOutputPath: {
            type: 'string',
            maxLength: 255,
        },
        topicRawData: {
            type: 'string',
            maxLength: 50,
        },
        topicRawDataPeakCps: {
            type: 'number',
        },
        topicTransformedData: {
            type: 'string',
            maxLength: 50,
        },
        outputType: {
            type: 'string',
            maxLength: 25,
        },
        isLoadRaw: {
            type: 'number',
            maximum: 1,
        },
        isNetworkReporting: {
            type: 'number',
            maximum: 1,
        },
        isFinancialReporting: {
            type: 'number',
            maximum: 1,
        },
        isFraud: {
            type: 'number',
            maximum: 1,
        },
        isArchived: {
            type: 'number',
            maximum: 1,
        },
        archiveBucket: {
            type: 'string',
            maxLength: 255,
        },
        archiveDelay: {
            type: 'number',
        },
        cdrRetention: {
            type: 'number',
        },
        reportingRetention: {
            type: 'number',
        },
        archiveRetention: {
            type: 'number',
        },
        timezone: {
            type: 'string',
        },
        workingFileRegex: {
            type: 'string',
            isNullable: true,
        },
        downloadFrequency: {
            type: 'number',
        },
        notificationUsers: {
            type: 'string',
            isNullable: true,
        },
        alertUsers: {
            type: 'string',
            isNullable: true,
        },
        notificationLevel: {
            type: 'string',
        },
        downloadLocalTempPath: {
            type: 'string',
        },
        extraParams: {
            type: 'ExtraFileStreamParams',
        },
    },
};