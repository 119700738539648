import clsx from 'clsx';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import moment from 'moment';
import { FC, useCallback, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { GuardianReconciliationUsageDetailsProfileResponse } from 'RtModels';
import { GuardianReconciliationResource } from 'RtUi/app/rtVue/GuardianReconciliation/lib/resources/GuardianReconciliationResource';
import { ViewReconciliationHttp } from 'RtUi/app/rtVue/ReconciliationUsage/lib/http/ViewReconciliationHttp';
import { FileUtils } from 'RtUi/utils/file/FileUtils';

interface IEasyButtonContainerProps {
	profile: GuardianReconciliationUsageDetailsProfileResponse;
	className?: string;
}

export const EasyButtonContainer: FC<
	React.PropsWithChildren<IEasyButtonContainerProps>
> = ({ profile, className }) => {
	const [isRunning, setIsRunning] = useState(false);

	const downloadEasyReport = useCallback(async () => {
		setIsRunning(true);

		const fileUtils = new FileUtils();
		const reconHttp = new ViewReconciliationHttp();
		const startTs = moment.tz(profile.guardianReconciliationStartTs, 'UTC');
		const month = startTs.format('MM'); //two digit is required
		const year = startTs.format('YYYY');

		try {
			const highMatchReconDetails = await reconHttp.getDetails(
				profile.reconciliationId,
				{
					month,
					year,
					moreThanMatchVariance: 10
				}
			);

			const unmatchedCdrs = await reconHttp.getUnmatchedCdrs(
				profile.reconciliationId,
				{
					month,
					year
				}
			);

			const zip = new JSZip();
			const resource = new GuardianReconciliationResource();
			const reconciliation = await resource.get(profile.reconciliationId);

			if (!reconciliation) {
				throw new Error('Unable to generate report: reconciliation not found');
			}

			const reportName = `Easy Report - ${reconciliation.summary} Usage`;
			const zipEasyFolder = zip.folder(reportName);

			if (!zipEasyFolder) {
				throw new Error('Unable to generate report');
			}

			const addDataToZipFolder = async (records: any[], fileName: string) => {
				const csv = await fileUtils.dataToCSVString(records as any);
				const csvBlob = fileUtils.createCsvBlob(csv);

				zipEasyFolder.file(fileName, csvBlob);
			};

			await addDataToZipFolder(
				highMatchReconDetails,
				'Reconciliation High Match Variance.csv'
			);
			await addDataToZipFolder(
				unmatchedCdrs.unmatchedFromSource,
				'Unmatched Source CDRs.csv'
			);
			await addDataToZipFolder(
				unmatchedCdrs.unmatchedFromTarget,
				'Unmatched Target CDRs.csv'
			);

			const zipBlob = await zip.generateAsync({ type: 'blob' });

			FileSaver.saveAs(zipBlob, `${reportName}.zip`);
		} catch (err) {
			console.error(err);
		}

		setIsRunning(false);
	}, [profile]);

	return (
		<section className={className}>
			<Alert variant="info">
				<p>
					The disparity analysis makes it <b>easy</b> to figure out where things
					are not matching up.
				</p>
				<Button
					className="d-flex justify-content-start align-items-center"
					variant="white-alt"
					disabled={isRunning}
					onClick={downloadEasyReport}
				>
					<i
						className={clsx('me-2 fas fa-fw', {
							'fa-play-circle text-success': !isRunning,
							'fa-cog fa-spin': isRunning
						})}
					/>
					{isRunning ? 'Generating Easy Report...' : 'Get the Easy Report'}
				</Button>
			</Alert>
		</section>
	);
};
