/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum CdrSearchColumns {
    ShowRates = 'showRates',
    ShowBilling = 'showBilling',
    ShowLrn = 'showLrn',
}