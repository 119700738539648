import { ITollFreeReserveActivated } from 'RtUi/app/rt800/SearchAndReserve/lib/grids/SearchAndReserveFiltersGrid';
import { ITollFreeSearchTFNResponse } from 'RtUi/app/rt800/SearchAndReserve/lib/resources/TollFreeSearchResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { TollFreeNumberDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeNumberDataGridColumn';
import { TollFreeReservedActivatedStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TollFreeReservedStatusDataGridColumn';

export const getSearchAndReservedColumns = (
	reserve: ITollFreeReserveActivated,
	activated: ITollFreeReserveActivated
): Array<DataGridColumn<ITollFreeSearchTFNResponse>> => [
	TollFreeNumberDataGridColumn({
		header: 'Matched Pattern',
		accessorKey: 'matchedTfn',
		isSuggestion: (record) => record.isSuggestion
	}),
	TollFreeNumberDataGridColumn({
		header: 'Toll-Free Number',
		accessorKey: 'tfn'
	}),
	TollFreeReservedActivatedStatusDataGridColumn({
		accessorKey: 'tfn',
		header: 'Reserved Status',
		id: 'reservedStatus',
		status: reserve
	}),
	TollFreeReservedActivatedStatusDataGridColumn({
		accessorKey: 'tfn',
		header: 'Activated Status',
		id: 'activatedStatus',
		status: activated
	})
];
