import { useMemo } from 'react';
import { Attestation } from 'RtModels';
import { IsMultiFormValue } from 'RtUi/components/form/FormControl';
import { ISelectFormControlProps } from 'RtUi/components/form/SelectFormControl';
import {
	ISimpleSelectFormControlOption,
	SimpleSelectFormControl
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';

interface IAttestationSelectProps<IsMulti extends boolean>
	extends ISelectFormControlProps<Attestation, IsMulti> {
	value?: IsMultiFormValue<Attestation, IsMulti>;
	onChange?: (newValue: IsMultiFormValue<Attestation, IsMulti>) => void;
	label?: string;
	required?: boolean;
	isClearable?: boolean;
}

export const AttestationSelect = <IsMulti extends boolean = false>({
	value,
	onChange = () => {},
	required,
	isClearable = true,
	displayMode,
	label = 'Attestation',
	initialOptionId,
	multi,
	autoHeight,
	hideLabel
}: IAttestationSelectProps<IsMulti>): JSX.Element => {
	const options: Array<ISimpleSelectFormControlOption<string>> = useMemo(() => {
		return [
			{
				label: 'A',
				value: Attestation.A
			},
			{
				label: 'B',
				value: Attestation.B
			},
			{
				label: 'C',
				value: Attestation.C
			}
		];
	}, []);

	const selectValue = useMemo(() => {
		return multi
			? options.filter((opt) =>
					(value as Attestation[])?.includes(opt.value as Attestation)
				)
			: options.find((opt) => opt.value === value);
	}, [value, options, multi]);

	const initialOptions = useMemo(
		() =>
			multi
				? (initialOptionId as string[]).map((val) => val.toString())
				: initialOptionId?.toString(),
		[initialOptionId, multi]
	);

	const onChangeHandler = (
		newValue: IsMulti extends true
			? Array<ISimpleSelectFormControlOption<string>>
			: ISimpleSelectFormControlOption<string>
	) => {
		const val = multi
			? (newValue as Array<ISimpleSelectFormControlOption<string>>)?.map(
					(v) => v.value
				)
			: (newValue as ISimpleSelectFormControlOption<string>)?.value;

		onChange(val as any);
	};

	return (
		<SimpleSelectFormControl<string, IsMulti>
			label={label}
			multi={multi}
			autoHeight={autoHeight}
			hideLabel={hideLabel}
			clearable={isClearable}
			required={required}
			displayMode={displayMode}
			value={selectValue as any}
			onChange={(val: any) => {
				onChangeHandler(val);
			}}
			options={options}
			initialOptionId={initialOptions as any}
		/>
	);
};
