import { ScenarioIndexResponse } from 'RtModels';
import { ScenarioResource } from 'RtUi/app/rtSip/Scenarios/lib/resources/ScenarioResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface ScenarioDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		ScenarioIndexResponse,
		T
	> {}

export const ScenarioRenderCache = new ResourceCacheMap<ScenarioIndexResponse>(
	new ScenarioResource(),
	'scenarioId',
	'label'
);

export const ScenarioDataGridColumn = <T = any,>(
	config: ScenarioDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: ScenarioRenderCache,
		...config
	});
};
