import { RtAdminRouter } from '../rtAdmin.router';
import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	ServerGroupMemberIndexResponse,
	ServerGroupMemberProfileResponse
} from 'RtModels';
import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';

interface IServerGroupMembersRouterTabs
	extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

const ServerGroupMembersContainerTabs: IServerGroupMembersRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class ServerGroupMembersRouterClass extends RtAdminRouter<
	ServerGroupMemberIndexResponse,
	ServerGroupMemberProfileResponse,
	IServerGroupMembersRouterTabs
> {
	constructor() {
		super(
			'Server group members',
			'serverGroupMembers',
			'serverGroupMemberId',
			ServerGroupMembersContainerTabs
		);

		this.setPermissionsFromApiRoute(RtAdmApiRoutes.ServerGroupMembers);
	}

	public getProfileLabel(server: ServerGroupMemberProfileResponse): string {
		return server.serverGroupMemberId.toString();
	}
}

export const ServerGroupMembersRouter = new ServerGroupMembersRouterClass();
