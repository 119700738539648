import {
	CellProps,
	DataGridColumn,
	FooterProps,
	HeaderProps,
	RowThemeColor
} from 'RtUi/components/data/DataGrid/types';
import { getThemeColor } from 'RtUi/components/data/DataGrid/utils';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Button } from 'react-bootstrap';

export interface FallbackHeaderDataGridColumnConfiguration<T>
	extends Omit<DataGridColumnConfiguration<T>, 'header'> {
	header?: string;
}

export interface DataGridColumnConfiguration<T>
	extends Omit<DataGridColumn<T>, 'Cell' | 'Footer'> {
	align?: DataGridColumnAlignment;
	titleCase?: boolean;
	className?: (row: T) => string;
	highlightCell?: (cell: CellProps<T>) => RowThemeColor | undefined;
	customWrapper?: (cell: CellProps<T>, value: ReactNode) => JSX.Element;
	onDrillDown?: (e: React.MouseEvent, row: T) => void;
}

export enum DataGridColumnAlignment {
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right',
	JUSTIFY = 'justify'
}

interface DefaultDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	getValue?: (cell: CellProps<T>) => ReactNode;
	getHeader?: (cell: HeaderProps<T>) => ReactNode;
	getFooter?: (props: FooterProps<T>) => ReactNode;
}

export const DefaultDataGridColumn = <T = any,>({
	align = DataGridColumnAlignment.LEFT,
	titleCase = false,
	getValue = ({ cell }) => cell.getValue<any>(),
	className,
	getFooter,
	getHeader,
	highlightCell,
	onDrillDown,
	customWrapper,
	...config
}: DefaultDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const defaults: Partial<DataGridColumn<T>> = {
		enableEditing: false,
		exportValue: (value: any) => value ?? '',
		muiTableBodyCellProps: (props) => ({
			align,
			sx: {
				...(highlightCell && {
					backgroundColor: getThemeColor(highlightCell(props as CellProps<T>))
				})
			}
		}),
		muiTableFooterCellProps: {
			align
		},
		muiFilterTextFieldProps: {
			label: '',
			placeholder: ''
		}
	};

	const configuration: DataGridColumn<T> = {
		...defaults,
		...config
	};

	const headerSize = configuration.header.length * 6.25 + 65;
	configuration.size = configuration.size ?? headerSize;

	const Wrapper = (
		cell: CellProps<T>,
		className: string,
		styles: React.CSSProperties
	) => {
		if (customWrapper) {
			return customWrapper(cell, getValue(cell));
		}

		return (
			<span className={clsx('d-block w-100', className)} style={styles}>
				{getValue(cell)}
			</span>
		);
	};

	configuration.Cell = (cell) => {
		const styles: React.CSSProperties = {};
		let classNameString = '';

		if (titleCase) {
			styles.textTransform = 'capitalize';
		}

		if (className) {
			classNameString = className(cell.row.original as T);
		}

		if (!onDrillDown) {
			return Wrapper(cell, classNameString, styles);
		}

		return (
			<Button
				variant="link"
				size="sm"
				className="p-0 ml-1"
				onClick={(e) => {
					onDrillDown(e, cell.row.original as T);
				}}
			>
				{Wrapper(cell, classNameString, styles)}
			</Button>
		);
	};

	if (getFooter) {
		configuration.Footer = (props) => <span>{getFooter(props)}</span>;
	}

	if (getHeader) {
		configuration.Header = (props) => <span>{getHeader(props)}</span>;
	}

	return configuration;
};
