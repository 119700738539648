/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ReconciliationMappingDataSourceField {
    TrunkGroup = 1,
    Connection = 2,
    Subscription = 3,
    Account = 4,
    Switch = 5,
    FileStream = 6,
    CallType = 7,
    ExternalAccount = 8,
}