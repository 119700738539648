import { ScenarioEditor } from 'RtUi/app/rtSip/Scenarios/lib/forms/ScenarioEditor';
import { ScenarioDataGrid } from 'RtUi/app/rtSip/Scenarios/lib/grids/ScenarioDataGrid';
import {
	ISearchResultsContainerState,
	SearchResultsContainer
} from 'RtUi/components/containers/SearchResultsContainer';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { ScenarioRouter } from './Scenario.router';

interface IInitialResourceParams {
	search: string;
}

interface IScenariosContainerState
	extends ISearchResultsContainerState,
		IInitialResourceParams {}

@ScenarioRouter.getBusinessOpsIndexRtUiController()
export class ScenariosContainer extends SearchResultsContainer<
	{},
	IInitialResourceParams,
	IScenariosContainerState
> {
	public initParams: IInitialResourceParams = {
		search: ''
	};

	public state: IScenariosContainerState = {
		...this.initParams,
		activeTab: this.tabs.Results,
		resourceParams: {}
	};

	constructor(props: any) {
		super(props);

		this.updateResourceParamsInConstructor();
	}

	public getResourceParams() {
		const { search } = this.state;
		const resourceParams: any = {};

		if (search) {
			resourceParams.search = search;
		}

		return resourceParams;
	}

	public goToScenario(scenarioId: number) {
		const scenarioPath = ScenarioRouter.getProfileRoute(scenarioId);

		this.goToPath(scenarioPath);
	}

	public render() {
		return (
			<TabbedLayout
				router={ScenarioRouter}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setActiveTab(activeTab)}
			>
				<TabbedLayoutTab header={this.tabs.Search}>
					<RtUiSearchForm onSubmit={(e) => this.submitAndGoToResults(e)}>
						<InputFormControl
							label="Search"
							onChange={(search) => this.setState({ search })}
							value={this.state.search}
						/>
					</RtUiSearchForm>
				</TabbedLayoutTab>
				<TabbedLayoutTab header={this.tabs.Results}>
					<ScenarioDataGrid resourceParams={this.state.resourceParams} />
				</TabbedLayoutTab>
				<TabbedLayoutTab header="Create Scenario" isAction>
					<ScenarioEditor
						onSuccess={(newScenario) =>
							this.goToScenario(newScenario.scenarioId)
						}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
