/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum VueJurisdictions {
    Interstate = 'Inter',
    Intrastate = 'Intra',
    International = 'IDDD',
    Indeterminate = 'Indet',
}