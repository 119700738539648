import { ConnectionIndexResponse } from 'RtModels';
import { ConnectionResource } from 'RtUi/app/rtVue/Connections/lib/resources/ConnectionResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface CdrConnectionDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		ConnectionIndexResponse,
		T
	> {}

export const CdrConnectionRenderCache =
	new ResourceCacheMap<ConnectionIndexResponse>(
		new ConnectionResource(),
		'connectionId',
		'summary',
		'trunkGroupId'
	);

export const CdrConnectionDataGridColumn = <T = any,>(
	config: CdrConnectionDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: CdrConnectionRenderCache,
		...config
	});
};
