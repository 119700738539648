/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipCdrSearchRouteAttempts = {
    properties: {
        aEgrRouteDepth: {
            type: 'number',
            isRequired: true,
        },
        aEgrInvTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        aEgrCostSubsId: {
            type: 'number',
            isRequired: true,
        },
        egrSummary: {
            type: 'string',
            isRequired: true,
        },
        eggHostAddress: {
            type: 'string',
            isRequired: true,
        },
        egpHostAddress: {
            type: 'string',
            isRequired: true,
        },
        egrTechPrefix: {
            type: 'string',
            isRequired: true,
        },
        aEgrNumber: {
            type: 'string',
            isRequired: true,
        },
        aPostDialDelay: {
            type: 'string',
            isRequired: true,
        },
        aEgrReleaseId: {
            type: 'number',
            isRequired: true,
        },
        rSummary: {
            type: 'string',
            isRequired: true,
        },
        aEgrCostRate: {
            type: 'number',
            isRequired: true,
        },
    },
};