import * as React from 'react';
import { PromptResource } from 'RtUi/app/rtSip/PromptSegments/lib/resources/PromptResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { TextAreaFormControl } from 'RtUi/components/form/TextAreaFormControl';
import { RtUiForm, RtUiNarrowForm } from 'RtUi/components/ui/RtUiForm';
import { UiPromptAudioFileUtils } from 'RtUi/app/rtSip/PromptSegments/lib/utils/UiPromptAudioFileUtils';
import { PromptProfileResponse } from 'RtModels';
import { IRtUiFormProps } from 'RtUi/components/ui/RtUiForm/RtUiForm';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { Form } from 'react-bootstrap';

interface IPromptEditorProps {
	editMode?: PromptProfileResponse;
	createMode?: boolean;
	disablePadding?: boolean;
	disableBody?: boolean;
	narrow?: boolean;
	onUpdate?: (newProfile: PromptProfileResponse) => void;
	onCreate?: (newProfile: PromptProfileResponse) => void;
}

interface IPromptEditorState {
	label: string;
	script: string;
	isActive: number;
	file: File | null;
	fileStr: string;
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
	invalidPromptFileMessage?: string;
}

export class PromptEditor extends React.Component<
	IPromptEditorProps,
	IPromptEditorState
> {
	public promptAudioFileUtils = new UiPromptAudioFileUtils();
	public promptFileTypes = UiPromptAudioFileUtils.PromptFileTypes.join(',');

	public state: IPromptEditorState = {
		label: '',
		script: '',
		file: null,
		fileStr: '',
		displayMode: true,
		isSubmitting: false,
		error: undefined,
		isActive: 1
	};

	public componentDidMount() {
		this.init();
	}

	public init() {
		if (this.props.editMode) {
			const { label, script, isActive } = this.props.editMode;

			this.setState({ label, script, isActive });
		} else if (this.props.createMode) {
			this.setState({ label: '', script: '', displayMode: false, isActive: 1 });
		}
	}

	public onCancel() {
		this.init();

		this.setState({ displayMode: true, error: undefined, isActive: 1 });
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();
		evt.stopPropagation();

		const promptResource = new PromptResource();
		const { label, script, file, isActive } = this.state;

		this.setState({ isSubmitting: true, error: undefined });

		let newProfile: PromptProfileResponse | null = null;

		try {
			if (this.props.editMode) {
				const { promptId } = this.props.editMode;
				newProfile = await promptResource.update(
					promptId,
					label,
					script,
					isActive
				);
			} else if (this.props.createMode) {
				if (!file) {
					throw new Error('File must be uploaded');
				}

				newProfile = await promptResource.create(label, isActive, file);
			}

			this.setState({ displayMode: true });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (newProfile) {
			const { onUpdate = () => ({}), onCreate = () => ({}) } = this.props;
			const updateHandler = this.props.editMode ? onUpdate : onCreate;

			updateHandler(newProfile);
		}
	}

	public updateFile(e: React.ChangeEvent<HTMLInputElement>) {
		const { files: fileList } = e.target;

		// this.setState({ error: undefined });

		if (!fileList) {
			this.setState({ file: null });

			return;
		}

		this.setState({ invalidPromptFileMessage: undefined });

		const files = Array.from(fileList);
		const file = files[0];

		this.setState({ file });
	}

	public async updatePromptFile(fileList: FileList | null) {
		this.setState({ error: undefined });

		if (!fileList) {
			this.setState({ file: null });

			return;
		}

		this.setState({ invalidPromptFileMessage: undefined });

		const files = Array.from(fileList);
		const file = files[0];
		const [isValidPromptFile, invalidPromptFileMessage] =
			await this.promptAudioFileUtils.isValidatePromptFile(file);

		this.setState({ file });

		if (!isValidPromptFile) {
			this.setState({ invalidPromptFileMessage });
		}
	}

	public render() {
		let RtUiFormComponent: React.ComponentClass<
			React.PropsWithChildren<IRtUiFormProps>
		> = RtUiForm;

		if (this.props.narrow) {
			RtUiFormComponent = RtUiNarrowForm;
		}

		return (
			<RtUiFormComponent
				createMode={this.props.createMode}
				disableBody={this.props.disableBody}
				disablePadding={this.props.disablePadding}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				onChange={(displayMode) => this.setState({ displayMode })}
				error={this.state.error}
				onCancel={() => this.onCancel()}
				onSubmit={(evt) => this.onSubmit(evt)}
			>
				{this.props.editMode && (
					<InputFormControl
						label="ID"
						displayMode
						value={String(this.props.editMode.promptId)}
					/>
				)}
				<InputFormControl
					label="Label"
					required={!this.props.createMode}
					subLabel={this.props.createMode ? '(optional)' : undefined}
					maxLength={255}
					displayMode={this.state.displayMode}
					onChange={(label) => this.setState({ label })}
					value={this.state.label}
				/>
				<IsActiveRadioFormControl
					hideBothOption
					displayMode={this.state.displayMode}
					onChange={(isActive) => this.setState({ isActive })}
					value={this.state.isActive}
				/>
				{this.props.createMode && (
					// <InputFormControl
					// 	label="Prompt Audio"
					// 	type="file"
					// 	displayMode={this.state.displayMode}
					// 	accept={this.promptFileTypes}
					// 	onFilesChange={(fileList) => this.updatePromptFile(fileList)}
					// 	onChange={(fileStr) => this.setState({ fileStr })}
					// 	value={this.state.fileStr}
					// 	formTextDanger={this.state.invalidPromptFileMessage}
					// />
					<Form.Control
						type="file"
						onChange={(e) => this.updateFile(e as any)}
						accept={this.promptFileTypes}
					/>
				)}
				{this.props.editMode && (
					<TextAreaFormControl
						label="Script"
						required
						rows={5}
						maxLength={1024}
						displayMode={this.state.displayMode}
						onChange={(script) => this.setState({ script })}
						value={this.state.script}
					/>
				)}
			</RtUiFormComponent>
		);
	}
}
