import moment from 'moment';
import { FC, FormEvent, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	GuardianReconciliationStatus,
	GuardianReconciliationUsageDetailsProfileResponse,
	UserIndexResponse,
	ServiceIndexResponse
} from 'RtModels';
import { ServiceSelect } from 'RtUi/app/rtSip/Services/lib/controls/ServiceSelect';
import { GuardianReconciliationStatusSelect } from 'RtUi/app/rtVue/GuardianReconciliation/lib/controls/GuardianReconciliationStatusSelect';
import { PercentVarianceControlGroup } from 'RtUi/app/rtVue/ReconciliationUsage/lib/components/PercentVarianceControlGroup';
import { ReconciliationUsageResource } from 'RtUi/app/rtVue/ReconciliationUsage/lib/resources/ReconciliationUsageDetailsResource';
import { UserSelect } from 'RtUi/app/user/lib/controls/UserSelect';
import { CurrencyInputFormControl } from 'RtUi/components/form/CurrencyInputFormControl';
import { DatetimeFormControl } from 'RtUi/components/form/DatetimeFormControl';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { PrecisionFormControl } from 'RtUi/components/form/PrecisionInputFormControl';
import { ISimpleSelectFormControlOption } from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { useRtUiFormEditor } from 'RtUi/components/hooks/useRtUiFormEditor';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';

export enum DisputeControlsMode {
	Hide = 'hidden',
	HideEverythingElse = 'hide-everything-else'
}

interface IReconciliationUsageEditorProps {
	editMode?: GuardianReconciliationUsageDetailsProfileResponse;
	createMode?: boolean;
	onUpdate?: (
		newProfile: GuardianReconciliationUsageDetailsProfileResponse
	) => void;
	initialReconciliationId?: number;
	disputeControlsMode?: DisputeControlsMode;
}

export const ReconciliationUsageEditor: FC<
	React.PropsWithChildren<IReconciliationUsageEditorProps>
> = ({
	editMode,
	createMode,
	disputeControlsMode,
	onUpdate = () => ({}),
	initialReconciliationId
}) => {
	const resource = new ReconciliationUsageResource();
	const [rtUiFormState, rtUiFormMethods] = useRtUiFormEditor({
		editMode,
		createMode,
		createNew: () => resource.createNewProfile(initialReconciliationId ?? -1)
	});
	const [assignedTo, setAssignedTo] = useState<UserIndexResponse>();
	const [disputeStatus, setDisputeStatus] = useState<
		ISimpleSelectFormControlOption<GuardianReconciliationStatus> | undefined
	>();
	const [serviceType, setServiceType] = useState<ServiceIndexResponse>();
	const { workingCopy } = rtUiFormState;
	const shouldShowDisputeControls =
		!disputeControlsMode ||
		disputeControlsMode === DisputeControlsMode.HideEverythingElse;
	const showOtherControls =
		!disputeControlsMode ||
		disputeControlsMode !== DisputeControlsMode.HideEverythingElse;

	const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();

		rtUiFormMethods.setError(undefined);
		rtUiFormMethods.setIsSubmitting(true);

		try {
			let newProfile: GuardianReconciliationUsageDetailsProfileResponse;

			if (createMode) {
				newProfile = await resource.create(workingCopy);
			} else {
				newProfile = await resource.update(
					workingCopy.guardianReconciliationUsageDetailId,
					workingCopy
				);
			}

			rtUiFormMethods.setIsSubmitting(false);
			rtUiFormMethods.setDisplayMode(true);

			onUpdate(newProfile);
		} catch (err) {
			rtUiFormMethods.setError(err);
			rtUiFormMethods.setIsSubmitting(false);
		}
	};

	return (
		<RtUiForm
			{...rtUiFormState}
			onSubmit={onSubmit}
			onChange={rtUiFormMethods.setDisplayMode}
			onCancel={() => {
				setAssignedTo(undefined);
				setDisputeStatus(undefined);
				setServiceType(undefined);
				rtUiFormMethods.onCancel();
			}}
		>
			{shouldShowDisputeControls && (
				<>
					<Row>
						<Col lg={6}>
							<UserSelect
								displayMode={rtUiFormState.displayMode}
								label="Assigned To"
								onChange={(assignedTo) => {
									setAssignedTo(assignedTo);
									rtUiFormMethods.setWorkingCopy({
										assignedTo: assignedTo.userId
									});
								}}
								value={assignedTo}
								initialOptionId={workingCopy.assignedTo?.toString()}
							/>
						</Col>
						<Col lg={6}>
							<ServiceSelect
								name="serviceId"
								label="Service Type"
								displayMode={rtUiFormState.displayMode}
								onChange={(serviceType) => {
									setServiceType(serviceType);
									rtUiFormMethods.setWorkingCopy({
										serviceTypeId: serviceType.serviceId
									});
								}}
								value={serviceType}
								initialOptionId={
									workingCopy?.serviceTypeId !== -1
										? workingCopy?.serviceTypeId.toString()
										: undefined
								}
								required={true}
							/>
						</Col>
					</Row>
					<hr />
				</>
			)}
			{showOtherControls && (
				<>
					<Row className="align-items-end row-eq-height">
						<Col>
							<InputFormControl
								type="number"
								label="Invoice Connects"
								min={0}
								displayMode={rtUiFormState.displayMode}
								value={workingCopy.carrierInvoiceConnects?.toString()}
								onChange={(carrierInvoiceConnects) => {
									rtUiFormMethods.setWorkingCopy({
										carrierInvoiceConnects: Number(carrierInvoiceConnects)
									});
								}}
								tooltip={{
									icon: 'info-circle',
									text: 'This can be found by opening the Invoice PDF from your vendor'
								}}
							/>
							<PrecisionFormControl
								step={0.01}
								min={0}
								precision={2}
								label="Invoice Duration"
								displayMode={rtUiFormState.displayMode}
								value={workingCopy.carrierInvoiceDuration}
								onChange={(carrierInvoiceDuration) => {
									rtUiFormMethods.setWorkingCopy({
										carrierInvoiceDuration: Number(carrierInvoiceDuration)
									});
								}}
							/>
							<CurrencyInputFormControl
								min={0}
								label="Invoice Charge"
								displayMode={rtUiFormState.displayMode}
								value={workingCopy.carrierInvoiceCharge}
								onChange={(carrierInvoiceCharge) => {
									rtUiFormMethods.setWorkingCopy({ carrierInvoiceCharge });
								}}
							/>
						</Col>
						<Col>
							<InputFormControl
								type="number"
								min={0}
								label="Expected Connects"
								displayMode={rtUiFormState.displayMode}
								value={workingCopy.customerExpectedConnects?.toString()}
								onChange={(customerExpectedConnects) => {
									rtUiFormMethods.setWorkingCopy({
										customerExpectedConnects: Number(customerExpectedConnects)
									});
								}}
								tooltip={{
									icon: 'info-circle',
									text: 'Enter data from another reporting or rating engine for this time frame'
								}}
							/>
							<InputFormControl
								type="number"
								min={0}
								label="Expected Duration"
								displayMode={rtUiFormState.displayMode}
								value={workingCopy.customerExpectedDuration?.toString()}
								onChange={(customerExpectedDuration) => {
									rtUiFormMethods.setWorkingCopy({
										customerExpectedDuration: Number(customerExpectedDuration)
									});
								}}
							/>
							<CurrencyInputFormControl
								min={0}
								label="Expected Charge"
								displayMode={rtUiFormState.displayMode}
								value={workingCopy.customerExpectedCharge}
								onChange={(customerExpectedCharge) => {
									rtUiFormMethods.setWorkingCopy({ customerExpectedCharge });
								}}
							/>
						</Col>
						{!createMode && (
							<>
								<Col>
									<InputFormControl
										type="number"
										label="Source Connects (RouteTrust)"
										displayMode
										value={workingCopy.rtSrcConnects?.toString()}
									/>
									<InputFormControl
										type="number"
										label="SRC Duration"
										displayMode
										value={workingCopy.rtSrcDuration?.toString()}
									/>
									<CurrencyInputFormControl
										label="SRC Charge"
										displayMode
										value={workingCopy.rtSrcCharge}
									/>
								</Col>
								<Col>
									<InputFormControl
										type="number"
										min={0}
										label="Target Connects (Vendor)"
										displayMode
										value={workingCopy.rtTgtConnects?.toString()}
										onChange={(rtTgtConnects) =>
											rtUiFormMethods.setWorkingCopy({
												rtTgtConnects: Number(rtTgtConnects)
											})
										}
									/>
									<InputFormControl
										type="number"
										min={0}
										label="Target Duration"
										displayMode
										value={workingCopy.rtTgtDuration?.toString()}
										onChange={(rtTgtDuration) =>
											rtUiFormMethods.setWorkingCopy({
												rtTgtDuration: Number(rtTgtDuration)
											})
										}
									/>
									<CurrencyInputFormControl
										label="Target Charge"
										displayMode
										value={workingCopy.rtTgtCharge}
										onChange={(rtTgtCharge) =>
											rtUiFormMethods.setWorkingCopy({ rtTgtCharge })
										}
									/>
								</Col>
								<Col>
									<PercentVarianceControlGroup
										label="Connects Variance"
										rtStat={workingCopy.rtSrcConnects}
										detailStat={workingCopy.rtTgtConnects}
									/>
									<PercentVarianceControlGroup
										label="Duration Variance"
										rtStat={workingCopy.rtSrcDuration}
										detailStat={workingCopy.rtTgtDuration}
									/>
									<PercentVarianceControlGroup
										label="Charge Variance"
										rtStat={workingCopy.rtSrcCharge}
										detailStat={workingCopy.rtTgtCharge}
									/>
								</Col>
							</>
						)}
					</Row>
				</>
			)}
			{shouldShowDisputeControls && showOtherControls && <hr />}
			{shouldShowDisputeControls && (
				<Row>
					<Col lg={6}>
						<GuardianReconciliationStatusSelect
							label="Dispute Status"
							displayMode={rtUiFormState.displayMode}
							value={disputeStatus}
							onChange={(disputeStatus) => {
								setDisputeStatus(disputeStatus);
								rtUiFormMethods.setWorkingCopy({
									disputeStatus: disputeStatus.value
								});
							}}
							initialOptionId={
								workingCopy.disputeStatus
									? workingCopy.disputeStatus.toString()
									: undefined
							}
						/>
						<CurrencyInputFormControl
							label="Disputed Amount"
							displayMode={rtUiFormState.displayMode}
							value={workingCopy.disputedAmount}
							onChange={(disputedAmount) => {
								rtUiFormMethods.setWorkingCopy({ disputedAmount });
							}}
						/>
						<DatetimeFormControl
							label="Dispute Filed Date"
							disablePresets
							displayMode={rtUiFormState.displayMode}
							onChange={(disputeFiledTs) => {
								rtUiFormMethods.setWorkingCopy({
									disputeFiledTs: disputeFiledTs?.toDate()
								});
							}}
							value={
								workingCopy.disputeFiledTs
									? moment(workingCopy.disputeFiledTs)
									: undefined
							}
						/>
						<DatetimeFormControl
							label="Escalation Date"
							disablePresets
							displayMode={rtUiFormState.displayMode}
							onChange={(escalationTs) => {
								rtUiFormMethods.setWorkingCopy({
									escalationTs: escalationTs?.toDate()
								});
							}}
							value={
								workingCopy.escalationTs
									? moment(workingCopy.escalationTs)
									: undefined
							}
						/>
					</Col>
					<Col lg={6}>
						<CurrencyInputFormControl
							label="Approved Amount"
							displayMode={rtUiFormState.displayMode}
							value={workingCopy.approvedAmount}
							onChange={(approvedAmount) => {
								rtUiFormMethods.setWorkingCopy({ approvedAmount });
							}}
						/>
						<DatetimeFormControl
							label="Dispute Settlement Date"
							disablePresets
							displayMode={rtUiFormState.displayMode}
							onChange={(disputeSettlementTs) => {
								rtUiFormMethods.setWorkingCopy({
									disputeSettlementTs: disputeSettlementTs?.toDate()
								});
							}}
							value={
								workingCopy.disputeSettlementTs
									? moment(workingCopy.disputeSettlementTs)
									: undefined
							}
						/>
						<DatetimeFormControl
							label="Dispute Received Date"
							disablePresets
							minDate={moment(workingCopy.disputeSettlementTs)}
							displayMode={rtUiFormState.displayMode}
							onChangeWithIsNow={(disputeReceivedTs) => {
								rtUiFormMethods.setWorkingCopy({
									disputeReceivedTs: disputeReceivedTs?.toDate() ?? null
								});
							}}
							value={
								workingCopy.disputeReceivedTs
									? moment(workingCopy.disputeReceivedTs)
									: undefined
							}
						/>
					</Col>
				</Row>
			)}
		</RtUiForm>
	);
};
