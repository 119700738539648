/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-return-await */
import { data } from 'autoprefixer';
import { capitalCase } from 'change-case';
import { useQueries, useQuery } from 'react-query';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	MonthlyTrendingIndexResponse,
	ReportMetadataProfileRequest,
	ReportMetadataProfileResponse,
	Reports,
	RtVueIndexRequest,
	RtVueMonthlyTrendingProfileRequest
} from 'RtModels';
import { VUE_ALL_COLUMNS_LABEL } from 'RtUi/app/rtVue/common/lib/components/VueAllColumnsLabel';
import {
	handleGetRequest,
	handlePostRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

export const fetchAllMonthlyTrendReports = async (
	queryParams: Omit<RtVueMonthlyTrendingProfileRequest, 'timeRange'>
): Promise<any> => {
	return handleGetRequest(RtVueApiRoutes.ViewMonthlyTrending.Profile, {
		queryParams
	});
};

export const fetchMonthlyTrendReports = async (
	bodyParams: RtVueIndexRequest
): Promise<MonthlyTrendingIndexResponse[]> => {
	return handlePostRequest(
		RtVueApiRoutes.ViewMonthlyTrending.Index,
		bodyParams
	);
};

const fetchMonthlyTrendReportsParams = async (
	urlParams: ReportMetadataProfileRequest
): Promise<any[]> => {
	const response = await handleGetRequest<ReportMetadataProfileResponse>(
		RtVueApiRoutes.ReportMetadata.Profile,
		{
			urlParams
		}
	);

	const allColumns = response.allColumns.map((column) => ({
		label: VUE_ALL_COLUMNS_LABEL.has(column)
			? VUE_ALL_COLUMNS_LABEL.get(column)!
			: capitalCase(column).replace(/Id/g, '').trim(),
		value: column
	}));

	return allColumns;
};

export const useGetMonthlyTrendReports = () => {
	return useQuery<any[], Error>([`getMonthlyTrending`], () => {
		return fetchMonthlyTrendReportsParams({
			reportId: Reports.MonthlyTrending
		});
	});
};

export const useGetAllMonthlyTrendReports = (
	reportType: string,
	dataColumns?: any[]
) => {
	return useQuery<any, Error>(
		['getAllMonthlyTrendingReports', reportType, data],
		() => {
			return fetchAllMonthlyTrendReports({ reportType, dataColumns });
		},
		{ enabled: false }
	);
};

export const useGetAllMonthlyTrendReportsTogether = (dataColumns?: any[]) => {
	return useQueries<any>([
		{
			queryKey: [`getAllMonthlyTrendingReports-customer`, dataColumns],
			queryFn: () => {
				return fetchAllMonthlyTrendReports({
					reportType: 'customer',
					dataColumns
				});
			},
			options: { enabled: false }
		},
		{
			queryKey: [`getAllMonthlyTrendingReports-vendor`, dataColumns],
			queryFn: () => {
				return fetchAllMonthlyTrendReports({
					reportType: 'vendor',
					dataColumns
				});
			},
			options: { enabled: false }
		},
		{
			queryKey: [`getAllMonthlyTrendingReports-offnet`, dataColumns],
			queryFn: () => {
				return fetchAllMonthlyTrendReports({
					reportType: 'offnet',
					dataColumns
				});
			},
			options: { enabled: false }
		}
	]);
};
