/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $GuardianReconciliationUsageDetailsSummaryProfileResponse = {
    properties: {
        guardianReconciliationUsageDetailSummaryId: {
            type: 'number',
            isRequired: true,
        },
        callDate: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        callType: {
            type: 'string',
            isRequired: true,
        },
        jurisdiction: {
            type: 'string',
            isRequired: true,
        },
        reconciliationId: {
            type: 'number',
            isRequired: true,
        },
        srcAttempts: {
            type: 'number',
            isRequired: true,
        },
        srcConnects: {
            type: 'number',
            isRequired: true,
        },
        srcDuration: {
            type: 'number',
            isRequired: true,
        },
        srcCost: {
            type: 'number',
            isRequired: true,
        },
        tgtAttempts: {
            type: 'number',
            isRequired: true,
        },
        tgtConnects: {
            type: 'number',
            isRequired: true,
        },
        tgtDuration: {
            type: 'number',
            isRequired: true,
        },
        tgtCost: {
            type: 'number',
            isRequired: true,
        },
        matchedAttempts: {
            type: 'number',
            isRequired: true,
        },
        matchedConnects: {
            type: 'number',
            isRequired: true,
        },
        matchedDuration: {
            type: 'number',
            isRequired: true,
        },
        matchedCost: {
            type: 'number',
            isRequired: true,
        },
        costMatchVarianceCategoryNegative: {
            type: 'number',
            isRequired: true,
        },
        costMatchVarianceCategoryNone: {
            type: 'number',
            isRequired: true,
        },
        costMatchVarianceCategoryFive: {
            type: 'number',
            isRequired: true,
        },
        costMatchVarianceCategoryTen: {
            type: 'number',
            isRequired: true,
        },
        costMatchVarianceCategoryTwentyFive: {
            type: 'number',
            isRequired: true,
        },
        costMatchVarianceCategoryHigher: {
            type: 'number',
            isRequired: true,
        },
        updateTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};