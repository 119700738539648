import { RtxSipApiRoutes } from 'RtExports/routes';
import { ActivePortAccountIndexResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchActivePortsDetail = async (): Promise<
	FullResponse<ActivePortAccountIndexResponse[]>
> => {
	return handleGetRequest<ActivePortAccountIndexResponse[], true>(
		RtxSipApiRoutes.ActivePortsAccounts.Index,
		{
			includeFullResponse: true
		}
	);
};

export const useGetActivePortsDetail = () => {
	return useQuery<FullResponse<ActivePortAccountIndexResponse[]>, Error>(
		[`useGetActivePortsDetail`],
		() => fetchActivePortsDetail(),
		{ enabled: true }
	);
};
