/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum TrafficTypes {
    commercial = 'commercial',
    retail = 'retail',
    wholesale = 'wholesale',
    dialer = 'dialer',
    misdial = 'misdial',
}