import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { TagMatchingIndexResponse } from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface ITagMatchingRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const TagMatchingContainerTabs: ITagMatchingRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class TagMatchingRouterClass extends SipApplicationRouter<
	TagMatchingIndexResponse,
	TagMatchingIndexResponse,
	ITagMatchingRouterTabs
> {
	constructor() {
		super(
			'Tag Matching',
			'tagMatching',
			'subscriptionTagId',
			TagMatchingContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.TagMatching);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}

	public recordHasAccessToProfile() {
		return false;
	}
}

export const TagMatchingRouter = new TagMatchingRouterClass();
