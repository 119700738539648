import { cloneDeep, uniq } from 'lodash-es';
import clsx from 'clsx';
import { Row, Col } from 'react-bootstrap';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { TemplateSelect } from 'RtUi/app/rt800/Templates/lib/controls/TemplateSelect';
import { TemplateResource } from 'RtUi/app/rt800/Templates/lib/resources/TemplateResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { RoutingGroupRouter } from '../../RoutingGroup.router';
import { RoutingGroupResource } from '../resources/RoutingGroupResource';
import {
	RespOrgIndexResponse,
	RoutingGroupProfileResponse,
	RoutingGroupTemplateCreateRequest,
	RoutingGroupTemplateIndexResponse,
	RoutingTemplateTypes,
	TemplateIndexResponse
} from 'RtModels';
import { RtError } from 'RtUi/utils/errors/RtError';
import { Component } from 'react';
import { BrowserHistory } from 'RtUi/app/@RtUi/lib/browser';

interface ICreateRoutingGroupProps {
	createMode?: boolean;
	onCreateSuccess?: (res: RoutingGroupTemplateIndexResponse) => void;
	onUpdateSuccess?: () => void;
	editMode?: RoutingGroupProfileResponse;
}

interface ICreateRoutingGroupState {
	editMode: Partial<RoutingGroupProfileResponse>;
	respOrg: RespOrgIndexResponse | undefined;
	workingTemplate: TemplateIndexResponse | undefined;
	defaultTemplate: TemplateIndexResponse | undefined;
	alternateTemplates: TemplateIndexResponse[];
	label: string;
	isActive: number;
	displayMode: boolean;
	submitting: boolean;
	templatesAreDistinct: boolean;
	error?: any;
}

export class RoutingGroupEditor extends Component<
	ICreateRoutingGroupProps,
	ICreateRoutingGroupState
> {
	public state: ICreateRoutingGroupState = {
		respOrg: undefined,
		workingTemplate: undefined,
		defaultTemplate: undefined,
		alternateTemplates: [],
		label: '',
		isActive: 1,
		displayMode: true,
		submitting: false,
		templatesAreDistinct: true,
		editMode: this.props.editMode
			? cloneDeep(this.props.editMode)
			: {
					routingGroupId: -1,
					summary: ''
				}
	};

	protected workingTemplateCache: TemplateIndexResponse | undefined;

	public componentDidMount() {
		this.loadProfileIfExists();
	}

	public onCancel = () => {
		this.setState({
			editMode: cloneDeep(this.props.editMode!),
			error: undefined,
			templatesAreDistinct: true,
			workingTemplate: undefined,
			defaultTemplate: undefined,
			alternateTemplates: [],
			respOrg: undefined
		});

		this.loadProfileIfExists();
	};

	public async loadProfileIfExists(props = this.props) {
		const { editMode } = props;

		if (editMode) {
			const { isActive } = editMode;
			this.setState({ isActive });

			await this.loadWorkingTemplate();
		}
	}

	public async loadWorkingTemplate() {
		const { editMode } = this.state;

		if (!editMode) {
			return;
		}

		const workingTemplateDefault = editMode.templates?.find(
			(template) =>
				template.routingTemplateTypeId === RoutingTemplateTypes.Working
		);

		if (workingTemplateDefault) {
			if (
				workingTemplateDefault.templateName !==
				this.workingTemplateCache?.templateName
			) {
				const templateResource = new TemplateResource();
				const workingTemplatesResponse = await templateResource.getAll({
					templateName: workingTemplateDefault.templateName
				});
				this.workingTemplateCache = workingTemplatesResponse[0];
			}

			this.setState({ workingTemplate: this.workingTemplateCache });
		}
	}

	public submit(e: React.FormEvent<HTMLFormElement>) {
		const { createMode = false, editMode } = this.props;

		e.preventDefault();

		this.setState({
			error: undefined,
			submitting: true,
			templatesAreDistinct: true
		});

		if (
			uniq([
				this.state.workingTemplate?.templateName,
				this.state.defaultTemplate?.templateName,
				...this.state.alternateTemplates.map((at) => at.templateName)
			]).length !==
			2 + this.state.alternateTemplates.length // uniq total length always: working + default + all alternate
		) {
			this.setState({
				submitting: false,
				templatesAreDistinct: false,
				error: new Error('Routing profiles must be distinct from one another.')
			});
			return;
		}

		const routingGroupResource = new RoutingGroupResource();
		const {
			label,
			respOrg,
			workingTemplate,
			defaultTemplate,
			alternateTemplates,
			isActive
		} = this.state;
		const respOrgId = respOrg ? respOrg.respOrgId : '';
		const templates: RoutingGroupTemplateCreateRequest[] = [];

		const pushTemplate = (
			template: TemplateIndexResponse,
			routingTemplateTypeId: number,
			isCurrent: 0 | 1 = 0
		) => {
			const { templateName } = template;

			templates.push({
				templateName,
				label,
				routingTemplateTypeId,
				isCurrent
			});
		};

		if (workingTemplate) {
			pushTemplate(workingTemplate, RoutingTemplateTypes.Working);
		}

		if (defaultTemplate) {
			pushTemplate(defaultTemplate, RoutingTemplateTypes.Default, 1);
		}

		alternateTemplates.forEach((altTemplate) =>
			pushTemplate(altTemplate, RoutingTemplateTypes.Alternate)
		);

		if (createMode) {
			routingGroupResource
				.create(label, respOrgId, isActive, templates)
				.then((res) => {
					BrowserHistory.push(
						RoutingGroupRouter.getProfileRoute(res.routingGroupId)
					);
				})
				.catch((error: RtError) => {
					this.setState({ error });
				})
				.finally(() => {
					this.setState({ submitting: false });
				});
		} else if (editMode) {
			const { routingGroupId } = editMode;

			routingGroupResource
				.update(routingGroupId, label, respOrgId, isActive, templates)
				.then(() => {
					const { onUpdateSuccess = () => ({}) } = this.props;

					onUpdateSuccess();

					this.setState({ displayMode: true });
				})
				.catch((error: RtError) => {
					this.setState({ error });
				})
				.finally(() => {
					this.setState({ submitting: false });
				});
		}
	}

	public render() {
		const { createMode = false } = this.props;
		const { displayMode, submitting, editMode } = this.state;
		const currentEntityId = this.state.respOrg?.entityId;
		const workingTemplateDefault = editMode?.templates?.find(
			(template) =>
				template.routingTemplateTypeId === RoutingTemplateTypes.Working
		);
		const defaultTemplateDefault = editMode?.templates?.find(
			(template) =>
				template.routingTemplateTypeId === RoutingTemplateTypes.Default
		);
		const altTemplateDefault = editMode?.templates?.filter(
			(template) =>
				template.routingTemplateTypeId === RoutingTemplateTypes.Alternate
		);
		const canManage = createMode
			? true
			: RoutingGroupRouter.canManageWith(editMode);

		return (
			<RtUiWideForm
				error={this.state.error}
				createMode={createMode}
				displayMode={displayMode}
				isSubmitting={submitting}
				onCancel={this.onCancel}
				onChange={(displayMode) => this.setState({ displayMode })}
				onSubmit={(e) => this.submit(e)}
				hideButtons={!canManage}
			>
				<Row>
					<Col lg>
						<InputFormControl
							label="Label"
							required
							displayMode={displayMode && !createMode}
							onChange={(label) => this.setState({ label })}
							value={this.state.label}
							initialValue={editMode && editMode.summary}
						/>
					</Col>
					<Col lg>
						<RespOrgSelect
							required
							displayMode={displayMode && !createMode}
							onChange={(respOrg) => this.setState({ respOrg })}
							value={this.state.respOrg}
							initialOptionId={editMode && editMode.respOrgId}
						/>
					</Col>
				</Row>

				<Row>
					<Col lg>
						<TemplateSelect
							controlGroupClassName={clsx(
								!this.state.templatesAreDistinct && 'is-invalid'
							)}
							label="Working Routing Profile"
							required
							entityId={currentEntityId}
							displayMode={displayMode && !createMode}
							onChange={(workingTemplate) => this.setState({ workingTemplate })}
							value={this.state.workingTemplate}
							initialOptionId={
								workingTemplateDefault &&
								String(workingTemplateDefault.templateName)
							}
						/>
					</Col>
					<Col lg>
						<TemplateSelect
							controlGroupClassName={clsx(
								!this.state.templatesAreDistinct && 'is-invalid'
							)}
							label="Default Routing Profile"
							required
							entityId={currentEntityId}
							displayMode={displayMode && !createMode}
							onChange={(defaultTemplate) => this.setState({ defaultTemplate })}
							value={this.state.defaultTemplate}
							initialOptionId={
								defaultTemplateDefault &&
								String(defaultTemplateDefault.templateName)
							}
						/>
					</Col>
				</Row>

				<Row>
					<Col lg>
						<TemplateSelect<true>
							controlGroupClassName={clsx(
								!this.state.templatesAreDistinct && 'is-invalid'
							)}
							label="Alternate Routing Profiles"
							required
							displayMode={displayMode && !createMode}
							entityId={currentEntityId}
							onChange={(alternateTemplates) =>
								this.setState({ alternateTemplates })
							}
							value={this.state.alternateTemplates}
							initialOptionId={altTemplateDefault?.map(
								(alt) => alt.templateName
							)}
							multi
						/>
					</Col>
					<Col lg>
						<IsActiveRadioFormControl
							displayMode={displayMode && !createMode}
							hideBothOption
							onChange={(isActive) => this.setState({ isActive })}
							value={this.state.isActive}
						/>
					</Col>
				</Row>
			</RtUiWideForm>
		);
	}
}
