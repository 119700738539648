import {
	LcrTaskCarrierResultActivationRequest,
	LcrTaskResultIndexResponse,
	LcrTaskResultProfileResponse,
	LcrTaskResultProfileRequest,
	LcrTaskResultIndexRequest
} from 'RtModels';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class LcrTaskResultsResource extends HttpResource<
	LcrTaskResultIndexResponse,
	LcrTaskResultProfileResponse
> {
	constructor(public lcrTaskId: number) {
		super();

		const urlParams: LcrTaskResultIndexRequest = { lcrTaskId };

		this.setApiModule(Rt800ApiRoutes.LcrTaskResults, urlParams);
	}

	public activate(
		lcrTaskResultId: number,
		targetTypeId: number,
		targetKey: string,
		effectiveTs?: Date
	) {
		const req: LcrTaskCarrierResultActivationRequest = {
			targetTypeId,
			targetKey
		};
		const lcrTaskId = this.lcrTaskId;
		const urlParams: LcrTaskResultProfileRequest = {
			lcrTaskId,
			lcrTaskResultId
		};

		if (effectiveTs) {
			req.effectiveTs = effectiveTs;
		}

		const body = JSON.stringify(req);

		return this.fetchWithRoute<ITaskProfile>(
			Rt800ApiRoutes.LcrTaskCarrierResultsActivate.Create,
			{ body, urlParams }
		);
	}
}
