import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianDoNotOriginate,
	GuardianDoNotOriginateBulkCreateRequest,
	GuardianDoNotOriginateBulkCreateRequestItem,
	GuardianDoNotOriginateBulkDeleteRequest,
	GuardianDoNotOriginateCreateRequest,
	GuardianDoNotOriginateIndexResponse,
	GuardianDoNotOriginateProfileRequest,
	GuardianDoNotOriginateUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class DoNotOriginateResource extends HttpResource<
	GuardianDoNotOriginateIndexResponse,
	GuardianDoNotOriginate
> {
	constructor() {
		super(RtVueApiRoutes.GuardianDoNotOriginate);
	}

	public create(request: GuardianDoNotOriginateCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianDoNotOriginate>(
			RtVueApiRoutes.GuardianDoNotOriginate.Create,
			{ body }
		);
	}

	public update(ani: string, request: GuardianDoNotOriginateUpdateRequest) {
		const urlParams: GuardianDoNotOriginateProfileRequest = { ani };
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianDoNotOriginate>(
			RtVueApiRoutes.GuardianDoNotOriginate.Update,
			{ body, urlParams }
		);
	}

	public delete(ani: string) {
		const urlParams: GuardianDoNotOriginateProfileRequest = { ani };
		return this.fetchWithRoute(RtVueApiRoutes.GuardianDoNotOriginate.Delete, {
			urlParams
		});
	}

	public bulkCreate(items: GuardianDoNotOriginateBulkCreateRequestItem[]) {
		const bodyParams: GuardianDoNotOriginateBulkCreateRequest = {
			items
		};
		const body = JSON.stringify(bodyParams);

		return this.fetchWithRoute(
			RtVueApiRoutes.GuardianDoNotOriginateBulk.Create,
			{
				body
			}
		);
	}

	public bulkDelete(ani: string[]) {
		const bodyParams: GuardianDoNotOriginateBulkDeleteRequest = {
			ani
		};
		const body = JSON.stringify(bodyParams);

		return this.fetchWithRoute(
			RtVueApiRoutes.GuardianDoNotOriginateBulk.Delete,
			{
				body
			}
		);
	}
}
