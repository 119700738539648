/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $LcrTaskCarrierResultActivationRequest = {
    properties: {
        targetTypeId: {
            type: 'number',
            isRequired: true,
        },
        targetKey: {
            type: 'string',
            isRequired: true,
        },
        effectiveTs: {
            type: 'Date',
            format: 'date-time',
        },
    },
};