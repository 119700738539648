import * as React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { CollapsibleCard } from 'RtUi/components/ui/CollapsibleCard';
import { RocRequestResource } from '../resources/RocRequestResource';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ICancelRocRequestCardProps {
	rocTxnId: string;
}

interface ICancelRocRequestCardState {
	rocWasCancelled: boolean;
	submitting: boolean;
	error?: string;
}

export class CancelRocRequestCard extends React.Component<
	ICancelRocRequestCardProps,
	ICancelRocRequestCardState
> {
	public state: ICancelRocRequestCardState = {
		rocWasCancelled: false,
		submitting: false
	};

	public cancelRoc() {
		const { rocTxnId } = this.props;
		const rocRequestResource = new RocRequestResource();
		const confirmCancel = confirm(
			`Are you sure you would like to cancel ${rocTxnId}?`
		);

		if (!confirmCancel) {
			return;
		}

		this.setState({ submitting: true });

		rocRequestResource
			.cancelROC(rocTxnId)
			.then(() => {
				this.setState({ rocWasCancelled: true });
			})
			.catch((error: RtError) => {
				this.setState({ error: error.messages?.join(', ') });
			})
			.finally(() => {
				this.setState({ submitting: false });
			});
	}

	public render() {
		const { error, submitting, rocWasCancelled } = this.state;

		return (
			<CollapsibleCard header="Cancel ROC" useCardBody>
				{error && (
					<Alert variant="danger">
						<i className="fas fa-fw fa-exclamation-triangle" />
						<span>&nbsp;{error}</span>
					</Alert>
				)}
				{rocWasCancelled && (
					<Alert variant="success">
						<i className="fas fa-fw fa-check-circle" />
						<span>&nbsp;ROC was Successfully Cancelled!</span>
					</Alert>
				)}
				{!error && !rocWasCancelled && (
					<>
						<Alert variant="warning" transition={false}>
							<i className="fas fa-fw fa-sticky-note" />
							<span>
								&nbsp;This will result in the RespOrg Change Request to be
								cancelled.
							</span>
						</Alert>
						<Form.Group className="mb-3">
							<Button
								variant="outline-danger"
								disabled={submitting}
								onClick={() => this.cancelRoc()}
							>
								Cancel ROC
								{submitting && (
									<>
										&nbsp;
										<i className="fas fa-fw fa-cog fa-spin" />
									</>
								)}
							</Button>
						</Form.Group>
					</>
				)}
			</CollapsibleCard>
		);
	}
}
