import { cloneDeep } from 'lodash-es';
import * as React from 'react';
import {
	Card,
	Button,
	Dropdown,
	ButtonGroup,
	Tooltip,
	OverlayTrigger,
	Overlay,
	Popover
} from 'react-bootstrap';
import { ScenarioActionType, ScenarioProfileResponse } from 'RtModels';
import {
	ScenarioAction,
	ScenarioResource
} from 'RtUi/app/rtSip/Scenarios/lib/resources/ScenarioResource';
import { ScenarioActionUtils } from 'RtUi/app/rtSip/Scenarios/lib/utils/ScenarioActionUtils';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { IScenarioActionFormProps } from '../forms/ScenarioActionForm';

interface IScenarioActionsListProps {
	profile: ScenarioProfileResponse;
	onUpdate: (profile: ScenarioProfileResponse) => void;
}

interface IScenarioActionsListState {
	editingProfile: ScenarioProfileResponse;
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
	deletePopoverActionIndex: number;
	target?: HTMLElement;
}

export class ScenarioActionsList extends React.Component<
	IScenarioActionsListProps,
	IScenarioActionsListState
> {
	public state: IScenarioActionsListState;

	constructor(props: IScenarioActionsListProps) {
		super(props);

		const isSubmitting = false;
		const error = undefined;
		const displayMode = true;
		const editingProfile = cloneDeep(props.profile);
		const deletePopoverActionIndex = -1;

		this.state = {
			editingProfile,
			displayMode,
			isSubmitting,
			error,
			deletePopoverActionIndex
		};
	}

	public onCancel() {
		const editingProfile = cloneDeep(this.props.profile);
		const error = undefined;
		const isSubmitting = false;
		const displayMode = true;
		const deletePopoverActionIndex = -1;

		this.setState({
			editingProfile,
			displayMode,
			isSubmitting,
			error,
			deletePopoverActionIndex
		});
	}

	public async onSubmit(evt?: React.FormEvent<HTMLFormElement>) {
		if (evt) {
			evt.preventDefault();
		}

		this.setState({ isSubmitting: true, error: undefined });

		const scenarioActionUtils = new ScenarioActionUtils();
		const sipScenarioResource = new ScenarioResource();
		let updatedScenario: ScenarioProfileResponse | undefined;

		try {
			const { editingProfile } = this.state;
			const { profile } = this.props;
			const terminalNodes = editingProfile.actions.filter((action) =>
				scenarioActionUtils.isTerminal(action.scenarioActionId)
			);

			if (terminalNodes.length > 1) {
				throw new Error('Only one terminal action is allowed.');
			} else if (terminalNodes.length === 1) {
				const lastAction =
					editingProfile.actions[editingProfile.actions.length - 1];
				const isLastElementTerminal = scenarioActionUtils.isTerminal(
					lastAction.scenarioActionId
				);

				if (!isLastElementTerminal) {
					throw new Error('A terminal action must be the last action.');
				}
			}

			updatedScenario = await sipScenarioResource.updateScenario(
				profile.scenarioId,
				profile.label,
				profile.summary,
				profile.isEntryPoint,
				editingProfile.actions
			);

			this.setState({ displayMode: true });
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (updatedScenario) {
			this.props.onUpdate(updatedScenario);
		}
	}

	public moveAction(fromIndex: number, toIndex: number) {
		const { editingProfile } = this.state;

		const actionToBeMoved = editingProfile.actions.splice(fromIndex, 1).pop();

		if (!actionToBeMoved) {
			return;
		}

		editingProfile.actions.splice(toIndex, 0, actionToBeMoved);

		this.setState({ editingProfile });
	}

	public addScenarioAction(
		scenarioActionId: ScenarioActionType,
		insertIndex: number
	) {
		const scenarioActionUtils = new ScenarioActionUtils();
		const { editingProfile } = this.state;
		const newAction = scenarioActionUtils.createScenarioAction(
			editingProfile.scenarioId,
			scenarioActionId
		);

		editingProfile.actions.splice(insertIndex, 0, newAction);

		this.setState({ editingProfile });
	}

	public updateAction(newAction: ScenarioAction, index: number) {
		const { editingProfile } = this.state;

		editingProfile.actions[index] = newAction;

		this.setState({ editingProfile });
	}

	public removeAction(actionIndex: number) {
		const { editingProfile } = this.state;

		editingProfile.actions.splice(actionIndex, 1);

		this.setState({ editingProfile }, () => {
			//Submit profile if all actions are removed
			if (editingProfile.actions.length === 0) {
				this.onSubmit();
			}
		});
	}

	public renderFormActionHeader(action: ScenarioAction, index: number) {
		const scenarioActionUtils = new ScenarioActionUtils();
		const { actions } = this.state.editingProfile;
		const addRowAboveBtnId = `AddRowAbove-${index}`;
		const addRowBelowBtnId = `AddRowBelow-${index}`;
		const moveUpBtnId = `MoveUp-${index}`;
		const moveDownBtnId = `MoveDown-${index}`;
		const deleteBtnId = `Delete-${index}`;
		const isTerminal = scenarioActionUtils.isTerminal(action.scenarioActionId);
		const isFirstAction = index === 0;
		const isLastAction = actions.length - 1 === index;
		const visibleScenarioActionIds: ScenarioActionType[] = scenarioActionUtils
			.getScenarioActionsIds()
			.filter((scenarioActionId) =>
				scenarioActionUtils.isVisible(scenarioActionId)
			);

		return (
			<header className="h6 text-muted d-flex justify-content-between align-items-center">
				<header className="d-flex justify-content-start">
					<b>
						{index + 1}:{' '}
						{scenarioActionUtils.getScenarioActionName(action.scenarioActionId)}
					</b>
					{!this.state.displayMode && isTerminal && (
						<>
							{!isLastAction && (
								<span className="text-danger ms-2">(Terminal Action)</span>
							)}
							{isLastAction && <span className="ms-2">(Terminal Action)</span>}
						</>
					)}
				</header>
				<section>
					{!this.state.displayMode && (
						<ButtonGroup>
							<OverlayTrigger
								placement="right"
								overlay={(props) => (
									<Tooltip id={`${moveUpBtnId}-tooltip`} {...props}>
										Move Action Up
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										id={moveUpBtnId}
										variant="white"
										disabled={isFirstAction}
										type="button"
										onClick={() => this.moveAction(index, index - 1)}
									>
										<i className="fas fa-fw fa-arrow-up" />
									</Button>
								)}
							</OverlayTrigger>
							<OverlayTrigger
								placement="right"
								overlay={(props) => (
									<Tooltip id={`${moveDownBtnId}-tooltip`} {...props}>
										Move Action Down
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										id={moveDownBtnId}
										variant="white"
										disabled={isLastAction}
										type="button"
										onClick={() => this.moveAction(index, index + 1)}
									>
										<i className="fas fa-fw fa-arrow-down" />
									</Button>
								)}
							</OverlayTrigger>
							<Dropdown align="end">
								<OverlayTrigger
									placement="right"
									overlay={(props) => (
										<Tooltip id={`${addRowAboveBtnId}-tooltip`} {...props}>
											Add Action Above
										</Tooltip>
									)}
								>
									{({ ref, ...triggerHandler }) => (
										<Dropdown.Toggle
											ref={ref}
											{...triggerHandler}
											bsPrefix="m-0"
											id={addRowAboveBtnId}
											variant="white"
										>
											<img
												src="/assets/icons/icons8-insert-row-above-24.png"
												width="24"
												height="24"
											/>
										</Dropdown.Toggle>
									)}
								</OverlayTrigger>
								<Dropdown.Menu>
									{visibleScenarioActionIds.map((scenarioActionId) => (
										<Dropdown.Item
											key={scenarioActionId}
											onClick={() =>
												this.addScenarioAction(
													Number(scenarioActionId) as ScenarioActionType,
													index
												)
											}
										>
											{scenarioActionUtils.getScenarioActionName(
												scenarioActionId
											)}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
							<Dropdown align="end">
								<OverlayTrigger
									placement="right"
									overlay={(props) => (
										<Tooltip id={`${addRowBelowBtnId}-tooltip`} {...props}>
											Add Action Below
										</Tooltip>
									)}
								>
									{({ ref, ...triggerHandler }) => (
										<Dropdown.Toggle
											ref={ref}
											{...triggerHandler}
											bsPrefix="m-0"
											id={addRowBelowBtnId}
											variant="white"
										>
											<img
												src="/assets/icons/icons8-insert-row-24.png"
												width="24"
												height="24"
											/>
										</Dropdown.Toggle>
									)}
								</OverlayTrigger>
								<Dropdown.Menu>
									{visibleScenarioActionIds.map((scenarioActionId) => (
										<Dropdown.Item
											key={scenarioActionId}
											onClick={() =>
												this.addScenarioAction(
													Number(scenarioActionId) as ScenarioActionType,
													index + 1
												)
											}
										>
											{scenarioActionUtils.getScenarioActionName(
												scenarioActionId
											)}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
							<OverlayTrigger
								placement="right"
								overlay={(props) => (
									<Tooltip id={`${deleteBtnId}-tooltip`} {...props}>
										Delete Action
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										id={deleteBtnId}
										variant="white"
										type="button"
										onClick={(e) => {
											this.setState({
												deletePopoverActionIndex: index,
												target: e.currentTarget
											});
										}}
									>
										<i className="fas fa-fw fa-trash" />
									</Button>
								)}
							</OverlayTrigger>
						</ButtonGroup>
					)}
				</section>
				<Overlay
					key={`popover-scenario-delete-${this.state.deletePopoverActionIndex}`}
					target={this.state.target!}
					show={index === this.state.deletePopoverActionIndex}
					placement="bottom-end"
				>
					<Popover
						id={`popover-scenario-delete-${this.state.deletePopoverActionIndex}`}
					>
						<Popover.Body>
							<Card.Body>
								<header className="h6 text-muted mb-4">
									Are you sure you would like to delete this action?
								</header>
								<section className="d-flex justify-content-end">
									<Button
										type="button"
										variant="white"
										className="me-2"
										onClick={() =>
											this.setState({
												deletePopoverActionIndex: -1
											})
										}
									>
										Cancel
									</Button>
									<Button
										type="button"
										variant="danger"
										onClick={() => {
											this.setState({
												deletePopoverActionIndex: -1
											});
											this.removeAction(index);
										}}
									>
										Yes, Delete
									</Button>
								</section>
							</Card.Body>
						</Popover.Body>
					</Popover>
				</Overlay>
			</header>
		);
	}

	public renderAction(action: ScenarioAction, index: number) {
		const { displayMode } = this.state;
		const onChange = (action: ScenarioAction) =>
			this.updateAction(action, index);
		const actionProps: IScenarioActionFormProps<ScenarioAction> = {
			action,
			displayMode,
			onChange
		};
		const scenarioActionUtils = new ScenarioActionUtils();
		const ScenarioActionForm = scenarioActionUtils.getScenarioActionForm(
			action.scenarioActionId
		);

		if (ScenarioActionForm) {
			return (
				<section className="animate__animated animate__fadeIn">
					<ScenarioActionForm {...actionProps} />
				</section>
			);
		}

		return null;
	}

	public renderAddActionButtons() {
		const scenarioActionUtils = new ScenarioActionUtils();
		const scenarioActionIds = scenarioActionUtils.getScenarioActionMapping();
		const addFirstAction = (scenarioActionId: ScenarioActionType) => {
			this.setState({ displayMode: false });
			this.addScenarioAction(scenarioActionId, 0);
		};

		return (
			<section>
				<header className="h5 text-muted">Add Action</header>
				{Object.keys(scenarioActionIds).map((scenarioActionIdStr) => {
					const scenarioActionId = Number(
						scenarioActionIdStr
					) as ScenarioActionType;

					const scenarioAction = scenarioActionIds[scenarioActionId];

					if (!scenarioAction) {
						return <></>;
					}

					return (
						<Button
							key={scenarioActionId}
							className="me-2 mb-2"
							variant="submit"
							onClick={() => addFirstAction(scenarioActionId)}
						>
							{scenarioAction.name}
						</Button>
					);
				})}
			</section>
		);
	}

	public render() {
		const { actions } = this.state.editingProfile;
		const hasActions = actions.length > 0;

		return (
			<RtUiForm
				className="rounded"
				header="Actions"
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
				hideButtons={!hasActions}
				onChange={(displayMode) => this.setState({ displayMode })}
				onCancel={() => this.onCancel()}
				onSubmit={(evt) => this.onSubmit(evt)}
			>
				<section>
					{actions.map((action, index) => (
						<Card.Body
							key={`Actions-${index}-${actions.length}`}
							className="border-bottom"
						>
							{this.renderFormActionHeader(action, index)}
							<article>{this.renderAction(action, index)}</article>
						</Card.Body>
					))}
				</section>
				{!hasActions && (
					<Card.Body className="py-2">
						{this.renderAddActionButtons()}
					</Card.Body>
				)}
			</RtUiForm>
		);
	}
}
