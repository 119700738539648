import { PromptIndexRequest, PromptIndexResponse } from 'RtModels';
import { PromptSegmentRouter } from 'RtUi/app/rtSip/PromptSegments/PromptSegment.router';
import { useGetPromptSegment } from 'RtUi/app/rtSip/PromptSegments/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getPromptSegmentColumns } from 'RtUi/components/data/DataGrid/configurations/rtSip/promptSegment';
import { useMemo } from 'react';

interface IPromptSegmentDataGridColumnProps {
	resourceParams?: PromptIndexRequest;
}

export const PromptSegmentDataGridColumn = ({
	resourceParams
}: IPromptSegmentDataGridColumnProps) => {
	const { data, isFetching: isLoading } = useGetPromptSegment(resourceParams);

	const columns = useMemo(() => getPromptSegmentColumns(), []);

	return (
		<DataGrid<PromptIndexResponse>
			router={PromptSegmentRouter}
			data={data?.data}
			totalRows={data?.totalCount}
			loading={isLoading}
			pageName={'rtSip_promptSegment'}
			columns={columns}
		/>
	);
};
