/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $PortLimitCreateRequest = {
    properties: {
        ingressPortLimit: {
            type: 'number',
            isNullable: true,
        },
        egressPortLimit: {
            type: 'number',
            isNullable: true,
        },
        accountId: {
            type: 'number',
            isRequired: true,
        },
        countryAbbr: {
            type: 'string',
        },
    },
};