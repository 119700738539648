import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	SipAgentAddressCreateRequest,
	SipAgentAddressProfileResponse,
	SipAgentConfigIndexResponse,
	SipAgentIndexResponse,
	SwitchAddressIndexResponse
} from 'RtModels';
import { SipAgentConfigsSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentConfigSelect';
import { SipAgentsSelect } from 'RtUi/app/AccountManagement/SipAgents/lib/controls/SipAgentSelect';
import { SwitchAddressesSelect } from 'RtUi/app/AccountManagement/Switches/lib/controls/SwitchAddressSelect';
import { SipAgentAddressesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SipAgentAddressesResource';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { RtError } from 'RtUi/utils/errors/RtError';

interface ISipAgentAddressEditorProps {
	value?: SipAgentAddressProfileResponse;
	switchId: number;
	createMode?: boolean;
	onSuccess?: () => void;
	onCancel?: () => void;
}

export const SipAgentAddressEditor = ({
	value,
	switchId,
	createMode = true,
	onSuccess = () => {},
	onCancel = () => {}
}: ISipAgentAddressEditorProps) => {
	const resource = useMemo(() => new SipAgentAddressesResource(), []);
	const [switchAddress, setSwitchAddress] =
		useState<SwitchAddressIndexResponse>();
	const [sipAgent, setSipAgent] = useState<SipAgentIndexResponse>();
	const [sipAgentConfig, setSipAgentConfig] =
		useState<SipAgentConfigIndexResponse>();
	const [isEditing, setIsEditing] = useState<boolean>(createMode);

	const [error, setError] = useState<RtError>();
	const {
		control,
		formState: { isSubmitting },
		handleSubmit
	} = useForm<SipAgentAddressCreateRequest>({
		defaultValues: {
			...value
		}
	});

	const onSubmitHandler = async (
		data: SipAgentAddressCreateRequest | SipAgentAddressProfileResponse
	) => {
		setError(undefined);

		try {
			if (value) {
				await resource.update(data as SipAgentAddressProfileResponse);
			} else {
				await resource.create(data as SipAgentAddressCreateRequest);
			}
			onSuccess();
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<RtUiForm
			onSubmit={handleSubmit(onSubmitHandler)}
			onCancel={onCancel}
			onChange={(val) => setIsEditing(!val)}
			isSubmitting={isSubmitting}
			error={error}
		>
			<Controller
				control={control}
				name="switchAddressId"
				render={({ field: { onChange, value } }) => (
					<SwitchAddressesSelect
						value={switchAddress}
						onChange={(val) => {
							setSwitchAddress(val);
							onChange(val?.switchAddressId);
						}}
						switchId={switchId}
						isClearable={false}
						required
						displayMode={!isEditing}
						initialOptionId={value}
					/>
				)}
			/>
			<Controller
				control={control}
				name="sipAgentId"
				render={({ field: { onChange, value } }) => (
					<SipAgentsSelect
						value={sipAgent}
						onChange={(val) => {
							setSipAgent(val);
							onChange(val?.sipAgentId);
						}}
						isClearable={false}
						required
						displayMode={!isEditing}
						initialOptionId={value}
					/>
				)}
			/>
			<Controller
				control={control}
				name="sipAgentConfigId"
				render={({ field: { onChange, value } }) => (
					<SipAgentConfigsSelect
						value={sipAgentConfig}
						onChange={(val) => {
							setSipAgentConfig(val);
							onChange(val?.sipAgentConfigId);
						}}
						isClearable={false}
						required
						displayMode={!isEditing}
						initialOptionId={value}
					/>
				)}
			/>
			<Controller
				control={control}
				name="isActive"
				render={({ field: { onChange, value } }) => (
					<IsActiveRadioFormControl
						value={value}
						onChange={onChange}
						hideBothOption
					/>
				)}
			/>
		</RtUiForm>
	);
};
