import * as React from 'react';
import { Venn, IVennSet } from 'RtUi/components/charts/venn';
import { IMetricsIndexSummary } from 'RtUi/app/rtDid/Dashboard/lib/http/DidMetricHttp';
import { ResourceType } from 'RtModels';
import {
	SelectedSummariesContext,
	ISelectedSummariesContext
} from 'RtUi/app/rtDid/Dashboard/lib/components/context';

interface IMetricSummaryVennSet extends IVennSet {
	ResourceTypes: ResourceType[];
}

interface IMetricSummariesVennChartProps {
	summaries: IMetricsIndexSummary[];
	isLoading?: boolean;
	selected: ResourceType[];
	toggleSelected: (selected: ResourceType[]) => void;
	onClick: (selected: ResourceType[]) => void;
}

export class MetricSummariesVennChart extends React.Component<IMetricSummariesVennChartProps> {
	public static contextType = SelectedSummariesContext;
	private static readonly BillingSetName = 'Billing';
	private static readonly SwitchingSetName = 'Switching';
	private static readonly VendorSetName = 'Vendor';

	//@ts-ignore

	public onClick(data: IMetricSummaryVennSet) {
		(this.props as any).onClick(data.sets);
	}

	public render() {
		const { summaries } = this.props;

		const { BillingSetName, SwitchingSetName, VendorSetName } =
			MetricSummariesVennChart;

		const billingSummary = summaries.find((ms) => ms.source === 'billing');
		const vendorSummary = summaries.find((ms) => ms.source === 'vendor');
		const switchSummary = summaries.find((ms) => ms.source === 'switch');
		const billingMetric = billingSummary?.isUnique ?? 0;
		const vendorMetric = vendorSummary?.isUnique ?? 0;
		const switchMetric = switchSummary?.isUnique ?? 0;
		const billingAndVendorMetric = billingSummary?.inVendor ?? 0;
		const billingAndSwitchMetric = billingSummary?.inSwitch ?? 0;
		const vendorAndSwitchMetric = vendorSummary?.inSwitch ?? 0;
		const inAllMetric = vendorSummary?.inBoth ?? 0;

		const selectedSets: string[] = [];

		for (const selectedSummary of (this.props as any).selected) {
			if (selectedSummary === ResourceType.Billing) {
				selectedSets.push(BillingSetName);
			} else if (selectedSummary === ResourceType.Switch) {
				selectedSets.push(SwitchingSetName);
			} else if (selectedSummary === ResourceType.DidVendor) {
				selectedSets.push(VendorSetName);
			}
		}

		return (
			<Venn<IMetricSummaryVennSet>
				isLoading={this.props.isLoading}
				selectedSets={selectedSets}
				onClick={(event) => this.onClick((event as any).target.__data__)}
				sets={[
					{
						sets: [BillingSetName],
						size: 12,
						metric: billingMetric,
						ResourceTypes: [ResourceType.Billing]
					},
					{
						sets: [VendorSetName],
						size: 12,
						metric: vendorMetric,
						ResourceTypes: [ResourceType.DidVendor]
					},
					{
						sets: [SwitchingSetName],
						size: 12,
						metric: switchMetric,
						ResourceTypes: [ResourceType.Switch]
					},
					{
						sets: [BillingSetName, SwitchingSetName],
						size: 3.5,
						metric: billingAndSwitchMetric,
						ResourceTypes: [ResourceType.Billing, ResourceType.Switch]
					},
					{
						sets: [BillingSetName, VendorSetName],
						size: 3.5,
						metric: billingAndVendorMetric,
						ResourceTypes: [ResourceType.Billing, ResourceType.DidVendor]
					},
					{
						sets: [SwitchingSetName, VendorSetName],
						size: 3.5,
						metric: vendorAndSwitchMetric,
						ResourceTypes: [ResourceType.Switch, ResourceType.DidVendor]
					},
					{
						sets: [BillingSetName, SwitchingSetName, VendorSetName],
						size: 3.5,
						metric: inAllMetric,
						ResourceTypes: [
							ResourceType.Billing,
							ResourceType.Switch,
							ResourceType.DidVendor
						]
					}
				]}
			/>
		);
	}
}
