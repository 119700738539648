import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import {
	SubscriptionQosIndexResponse,
	SubscriptionQosProfileResponse
} from 'RtModels';
import { SipApplicationRouter } from 'RtUi/app/rtSip/rtSip.router';
import { RtxSipApiRoutes } from 'RtExports/routes';

interface IQosManagementRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const QosManagementContainerTabs: IQosManagementRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class QosManagementRouterClass extends SipApplicationRouter<
	SubscriptionQosIndexResponse,
	SubscriptionQosProfileResponse,
	IQosManagementRouterTabs
> {
	constructor() {
		super(
			'QOS Management',
			'subscriptionQos',
			'rateCenterId',
			QosManagementContainerTabs
		);

		this.setPermissionsFromApiRoute(RtxSipApiRoutes.SubscriptionQos);
	}

	public getProfileLabel(profile: {}): string {
		return '';
	}

	public getPluralName() {
		return this.getName();
	}
}

export const QosManagementRouter = new QosManagementRouterClass();
