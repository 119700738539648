/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $Lerg6IndexResponse = {
    properties: {
        id: {
            type: 'number',
            isRequired: true,
        },
        lata: {
            type: 'string',
            isRequired: true,
        },
        lataName: {
            type: 'string',
            isRequired: true,
        },
        npa: {
            type: 'string',
            isRequired: true,
        },
        nxx: {
            type: 'string',
            isRequired: true,
        },
        blockId: {
            type: 'string',
            isRequired: true,
        },
        endOffice: {
            type: 'string',
            isRequired: true,
        },
        accessTandem: {
            type: 'string',
            isRequired: true,
        },
        administrativeOcn: {
            type: 'string',
            isRequired: true,
        },
        operatingCompanyNumber: {
            type: 'string',
            isRequired: true,
        },
        linesFrom: {
            type: 'string',
            isRequired: true,
        },
        linesTo: {
            type: 'string',
            isRequired: true,
        },
        switch: {
            type: 'string',
            isRequired: true,
        },
        operatingCompanyName: {
            type: 'string',
            isRequired: true,
        },
    },
};