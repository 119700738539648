/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $NumberGroupIndexResponse = {
    properties: {
        numberGroupId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        tfn: {
            type: 'string',
            isRequired: true,
        },
        tfnQty: {
            type: 'number',
            isRequired: true,
        },
        createdAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        createdByEmailAddress: {
            type: 'string',
            isRequired: true,
        },
    },
};