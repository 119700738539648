import { compareDesc } from 'date-fns';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';
import { Alert, Card, Button, Form } from 'react-bootstrap';
import {
	GuardianReconciliationNoteIndexRequest,
	GuardianReconciliationNoteIndexResponse,
	GuardianReconciliationNoteTypes
} from 'RtModels';
import { ReconciliationNoteResource } from 'RtUi/app/rtVue/ReconciliationNote/lib/resources/ReconciliationNoteResource';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { dateToReadable } from 'RtUi/utils/maps';

interface IReconciliationNotesProps {
	reconciliationId: number;
	reconciliationNoteType: GuardianReconciliationNoteTypes;
	reconciliationDetailId?: number;
	fullHeight?: boolean;
}

export const ReconciliationNotes: FC<
	React.PropsWithChildren<IReconciliationNotesProps>
> = ({
	reconciliationId,
	reconciliationNoteType,
	reconciliationDetailId,
	fullHeight
}) => {
	const resource = new ReconciliationNoteResource();
	const notesContainerRef = useRef<HTMLDivElement>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [note, setNote] = useState<string>('');
	const [notes, setNotes] = useState<GuardianReconciliationNoteIndexResponse[]>(
		[]
	);

	useMount(async () => {
		const req: GuardianReconciliationNoteIndexRequest = {
			reconciliationId
		};

		if (reconciliationDetailId) {
			req.reconciliationDetailId = reconciliationDetailId;
		}

		if (reconciliationNoteType) {
			//Parent types show all types (don't use it as a filter)
			if (reconciliationNoteType !== GuardianReconciliationNoteTypes.Parent) {
				req.reconciliationNoteType = reconciliationNoteType;
			}
		}

		const reconNotes = await resource.getAll(req);

		reconNotes.sort((n1, n2) =>
			compareDesc(new Date(n1.createdTs), new Date(n2.createdTs))
		);

		setNotes(reconNotes);
	});

	useLayoutEffect(() => {
		const notesContainer = notesContainerRef.current;
		if (notesContainer) {
			notesContainer.scrollTop = notesContainer.scrollHeight;
		}
	});

	const createNote = async () => {
		try {
			setIsSubmitting(true);
			const newNote = await resource.create(
				reconciliationId,
				note,
				reconciliationNoteType,
				reconciliationDetailId
			);

			setNotes([...notes, newNote]);
			setIsSubmitting(false);
			setNote('');
		} catch {
			//TODO
			setIsSubmitting(false);
		}
	};

	return (
		<Form
			style={{ height: fullHeight ? '100%' : 'auto' }}
			onSubmit={(e) => {
				e.preventDefault();
				createNote();
			}}
		>
			<Card style={{ height: fullHeight ? '100%' : 'auto' }}>
				<div
					ref={notesContainerRef}
					className="card-body flex-fill"
					style={{
						minHeight: 250,
						height: fullHeight ? '100%' : '50vh',
						overflowY: 'auto'
					}}
				>
					{notes.length <= 0 && (
						<Alert variant="info">
							No notes taken for this reconciliation. Type in the box below to
							start.
						</Alert>
					)}
					{notes.map((note) => (
						<Alert
							key={note.guardianReconciliationNoteId}
							variant="warning"
							className="d-flex flex-column"
						>
							<small className="text-muted">
								{dateToReadable(note.createdTs, true)}
							</small>
							<section className="my-1">{note.note}</section>
							<small className="text-muted text-end">
								&mdash; {note.createdUserEmail}
							</small>
						</Alert>
					))}
				</div>
				<footer className="border-top p-2 d-flex">
					<InputFormControl
						placeholder="Type to add note here"
						disabled={isSubmitting}
						useControlGroup={false}
						label="Create new note"
						style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
						value={note}
						onChange={(note) => setNote(note)}
					/>
					<Button
						style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
						className="text-center"
						variant="primary"
						type="submit"
						disabled={isSubmitting}
					>
						Send
					</Button>
				</footer>
			</Card>
		</Form>
	);
};
