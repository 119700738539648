/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CountryFilterIndexResponse = {
    properties: {
        mode: {
            type: 'FilterModesEnum',
            isRequired: true,
        },
        reportIncludes: {
            type: 'FilterReportsEnum',
            isRequired: true,
        },
        dataSource: {
            type: 'DataSources',
        },
        commonName: {
            type: 'string',
            isRequired: true,
        },
        data: {
            type: 'Array',
            isRequired: true,
        },
    },
};