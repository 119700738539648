import * as React from 'react';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { UrlPartition, $UrlPartition } from 'RtModels';
import { BooleanRadioFormControl } from 'RtUi/components/form/BooleanRadioFormControl';
import {
	SimpleSelectFormControl,
	ISimpleSelectFormControlOption
} from 'RtUi/components/form/SelectFormControl/SimpleSelectFormControl';
import { PartitionUrlsResource } from 'RtUi/app/rtAdmin/Partitions/lib/resource/PartitionUrlsResource';
import { cloneDeep } from 'lodash-es';

interface IPartitionUrlEditorProps {
	onUpdate: (newUrlPartition: UrlPartition) => void;
	partitionId: number;
	disablePadding?: boolean;
	editMode?: UrlPartition;
	onCancel?: () => void;
	createMode?: boolean;
}

interface IPartitionUrlEditorState {
	isSaving: boolean;
	editingPartitionUrl: UrlPartition;
	displayMode: boolean;
	error?: any;
	logoOption?: ISimpleSelectFormControlOption<string>;
	loginLogoOption?: ISimpleSelectFormControlOption<string>;
}

export class PartitionUrlEditor extends React.Component<
	IPartitionUrlEditorProps,
	IPartitionUrlEditorState
> {
	public static defaultProps = {
		onCancel: () => null,
		createMode: false,
		disablePadding: false
	};

	private partitionUrlsResource = new PartitionUrlsResource();

	constructor(props: IPartitionUrlEditorProps) {
		super(props);

		let editingPartitionUrl: UrlPartition;

		if (props.editMode) {
			editingPartitionUrl = cloneDeep(props.editMode);
		} else {
			editingPartitionUrl = this.getDefaultUrlPartition();
		}

		this.state = {
			isSaving: false,
			editingPartitionUrl,
			displayMode: false
		};
	}

	public getDefaultUrlPartition(): UrlPartition {
		return {
			partitionId: this.props.partitionId,
			urlPartitionId: 0,
			url: '',
			logo: '',
			siteTitle: '',
			allowSiteSearch: 0,
			loginTitle: '',
			forgotPasswordTitle: '',
			loginLogo: '',
			forceSsl: 1
		};
	}

	public render() {
		const { editingPartitionUrl } = this.state;

		return (
			<RtUiForm
				createMode={this.props.createMode}
				disablePadding={this.props.disablePadding}
				onCancel={this.onCancel}
				isSubmitting={this.state.isSaving}
				displayMode={this.state.displayMode}
				onSubmit={this.onSubmit}
				error={this.state.error}
			>
				{!this.props.createMode && (
					<InputFormControl
						label="ID"
						readOnly={true}
						displayMode={true}
						value={String(editingPartitionUrl.urlPartitionId)}
					/>
				)}
				<InputFormControl
					required={$UrlPartition.properties.url.isRequired}
					label="Url"
					displayMode={this.state.displayMode}
					onChange={(value) => this.onChange('url', value)}
					value={editingPartitionUrl.url || ''}
				/>
				<InputFormControl
					required={$UrlPartition.properties.partitionId.isRequired}
					label="Partition Id"
					type="number"
					onChange={(value) => this.onChange('partitionId', Number(value))}
					displayMode={this.state.displayMode}
					value={String(editingPartitionUrl.partitionId)}
				/>
				<SimpleSelectFormControl<string>
					required={$UrlPartition.properties.logo.isRequired}
					label="Logo"
					clearable={false}
					onChange={(value) => this.updateLogo(value)}
					initialOptionId={editingPartitionUrl.logo}
					value={this.state.logoOption}
					options={[
						{ label: 'neptune.png', value: 'neptune.png' },
						{ label: 'transparent.png', value: 'transparent.png' }
					]}
				/>
				<InputFormControl
					required={$UrlPartition.properties.siteTitle.isRequired}
					label="Site title"
					onChange={(value) => this.onChange('siteTitle', value)}
					displayMode={this.state.displayMode}
					value={editingPartitionUrl.siteTitle || ''}
				/>
				<BooleanRadioFormControl
					required={$UrlPartition.properties.allowSiteSearch.isRequired}
					onChange={(value) => this.onChange('allowSiteSearch', value)}
					label="Allow site search"
					displayMode={this.state.displayMode}
					value={editingPartitionUrl.allowSiteSearch}
				/>
				<InputFormControl
					required={$UrlPartition.properties.loginTitle.isRequired}
					label="Login title"
					onChange={(value) => this.onChange('loginTitle', value)}
					displayMode={this.state.displayMode}
					value={editingPartitionUrl.loginTitle || ''}
				/>
				<InputFormControl
					required={$UrlPartition.properties.forgotPasswordTitle.isRequired}
					label="Forgot password title"
					onChange={(value) => this.onChange('forgotPasswordTitle', value)}
					displayMode={this.state.displayMode}
					value={editingPartitionUrl.forgotPasswordTitle || ''}
				/>
				<SimpleSelectFormControl<string>
					required={$UrlPartition.properties.loginLogo.isRequired}
					label="Login logo"
					clearable={false}
					onChange={(value) => this.updateLoginLogo(value)}
					initialOptionId={editingPartitionUrl.loginLogo}
					value={this.state.loginLogoOption}
					options={[
						{ label: 'neptune.png', value: 'neptune.png' },
						{ label: 'transparent.png', value: 'transparent.png' }
					]}
				/>
				<BooleanRadioFormControl
					required={$UrlPartition.properties.forceSsl.isRequired}
					onChange={(value) => this.onChange('forceSsl', value)}
					label="Force SSL"
					displayMode={this.state.displayMode}
					value={editingPartitionUrl.forceSsl}
				/>
			</RtUiForm>
		);
	}

	private updateLogo(logoOption: ISimpleSelectFormControlOption<string>) {
		this.onChange('logo', logoOption.value);

		this.setState({ logoOption });
	}

	private updateLoginLogo(
		loginLogoOption: ISimpleSelectFormControlOption<string>
	) {
		this.onChange('loginLogo', loginLogoOption.value);

		this.setState({ loginLogoOption });
	}

	private onCancel = () => {
		let editingPartitionUrl = this.getDefaultUrlPartition();

		if (this.props.editMode) {
			editingPartitionUrl = cloneDeep(this.props.editMode);
		}

		this.setState({ editingPartitionUrl }, () => {
			this.props.onCancel!();
		});
	};

	private onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.setState({ isSaving: true, error: undefined });

		try {
			let newPartitionUrl: UrlPartition;
			if (this.props.createMode) {
				newPartitionUrl = await this.partitionUrlsResource.createPartitionUrl(
					this.state.editingPartitionUrl
				);
			} else {
				newPartitionUrl = await this.partitionUrlsResource.updatePartitionUrl(
					this.state.editingPartitionUrl
				);
			}

			this.setState({ isSaving: false }, () => {
				this.props.onUpdate(newPartitionUrl);
			});
		} catch (error) {
			this.setState({ isSaving: false, error });
		}
	};

	private onChange = <K extends keyof UrlPartition>(
		key: K,
		value: UrlPartition[K]
	) => {
		if (!this.state.isSaving) {
			const { editingPartitionUrl } = this.state;

			editingPartitionUrl[key] = value;

			this.setState({ editingPartitionUrl });
		}
	};
}
