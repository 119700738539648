import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RoutingRuleProfileResponse } from 'RtModels';
import { RoutingRuleEditor } from 'RtUi/app/rtSip/RoutingRules/lib/forms/RoutingRuleEditor';
import { RoutingRuleResource } from 'RtUi/app/rtSip/RoutingRules/lib/resources/RoutingRuleResource';
import { useGetRoutingRule } from 'RtUi/app/rtSip/RoutingRules/lib/services';
import { RoutingRuleRouter } from 'RtUi/app/rtSip/RoutingRules/RoutingRule.router';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { Loading } from 'RtUi/components/ui/Loading';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';

export const RoutingRuleProfileContainer = () => {
	const [error, setError] = useState<string | JSX.Element>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const navigate = useNavigate();

	const currentId = useRef<string>();
	const routingRuleId = useIdParam();

	const {
		data: profile,
		isFetching: isLoading,
		refetch
	} = useGetRoutingRule(Number(routingRuleId));

	useEffect(() => {
		if (currentId.current !== routingRuleId) {
			currentId.current = routingRuleId;
			setError(undefined);
		}
	}, [currentId, routingRuleId]);

	if (isLoading) {
		return <Loading />;
	}

	if (!profile) {
		return <p>Not found!</p>;
	}

	const onChange = async (value: RoutingRuleProfileResponse) => {
		const resource = new RoutingRuleResource();
		setError(undefined);
		setIsSubmitting(true);

		try {
			await resource.update(value.routingRuleId, value);
			await refetch();
		} catch (e: any) {
			const id = (e.message as string).match(/(?:\d+)/g);
			if (id) {
				const url = RoutingRuleRouter.getProfileRoute(id[0]);
				const message = (
					<p>
						<span>
							Another Routing Rule already has the same Matching criteria.&nbsp;
						</span>
						<Link to={url}>Click here</Link>
						<span>&nbsp;to view this Routing Rule</span>
					</p>
				);

				setError(message);
			} else {
				setError(e.message);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const onCancelHandler = () => navigate('/rtSip/routingRules');

	return (
		<StandardLayout router={RoutingRuleRouter} profile={profile}>
			<RoutingRuleEditor
				profile={profile}
				onChange={(val) => onChange(val as RoutingRuleProfileResponse)}
				error={error}
				isSubmitting={isSubmitting}
				onCancel={onCancelHandler}
			/>
		</StandardLayout>
	);
};

RoutingRuleRouter.setProfileRtUiFunctionalComponent(
	RoutingRuleProfileContainer
);
