/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ResourceNumberBillingRecord = {
    properties: {
        number: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
        },
        startTs: {
            type: 'Date',
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            format: 'date-time',
        },
        accountNumber: {
            type: 'string',
        },
        recurringAmount: {
            type: 'number',
        },
        nonRecurringAmount: {
            type: 'number',
        },
    },
};