import { union } from 'lodash-es';
import * as React from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from 'RtUi/components/form/FormControl';
import { ControlGroup } from 'RtUi/components/ui/ControlGroup';
import { FileInputButton } from 'RtUi/components/ui/FileInputButton';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { TollFreeNumbersUtils } from '../utils/TollFreeNumbersUtils';

interface ITenDigitTextAreaProps extends IFormControlProps<string, true> {
	loading?: boolean;
	allowForUpload?: boolean;
	maxLimit?: number;
	allowInternational?: boolean;
}

interface ITenDigitTextAreaState extends IFormControlState {
	input: string;
	isReachedLimit: boolean;
}
export class TenDigitTextArea extends FormControl<
	string,
	true,
	ITenDigitTextAreaProps,
	ITenDigitTextAreaState
> {
	public resource = undefined;
	public state: ITenDigitTextAreaState = {
		input: '',
		formLabel: 'Ten Digit Numbers',
		isReachedLimit: false
	};

	public inputRef = React.createRef<HTMLInputElement>();
	public deleteWasHit = false;
	public fileUtils = new FileUtils();

	public onListClick() {
		if (this.inputRef && this.inputRef.current) {
			this.inputRef.current.focus();
		}
	}

	public handleInputChange(evt: React.ChangeEvent<HTMLInputElement>) {
		const inputValue = evt.target.value;
		const currentInput = this.state.input;
		const lastInputChar = inputValue[inputValue.length - 1];

		if (!this.deleteWasHit && /\D/.test(lastInputChar)) {
			evt.stopPropagation();
			evt.preventDefault();
			return;
		}

		const lastCharWasDash =
			currentInput.lastIndexOf('-') === currentInput.length - 1;
		let input = TollFreeNumbersUtils.tollFreeNumberToReadableString(inputValue);

		if (this.deleteWasHit) {
			this.deleteWasHit = false;

			if (lastCharWasDash) {
				input = input.slice(0, input.length - 1);
			}
		}

		this.resetLimit();

		if (input.length === 12) {
			this.addItem(input);
		} else {
			this.setState({ input });
		}
	}

	public resetLimit() {
		if (this.state.isReachedLimit) {
			this.setState({ isReachedLimit: false });
		}
	}

	public addItem(
		addItem: string[] | string,
		resetInput = true,
		skipFormat = false
	) {
		const { value = [] } = this.props;

		if (typeof addItem === 'string') {
			addItem = [addItem];
		}

		if (
			this.props.maxLimit &&
			value.length + addItem.length > this.props.maxLimit
		) {
			this.setState({ isReachedLimit: true });
			return;
		}

		//Remove dashes
		addItem = addItem.map((tfn) =>
			skipFormat
				? tfn
				: TollFreeNumbersUtils.tollFreeNumberToReadableString(tfn, false, true)
		);
		const newTfns = union(value, addItem);

		if (newTfns.length !== value.length) {
			this.updateConsumer(newTfns);
		}

		if (resetInput) {
			this.setState({ input: '' });
		}
	}

	public removeLastItem() {
		const { value = [] } = this.props;
		const valueCopy = [...value];

		valueCopy.pop();

		this.updateConsumer(valueCopy);
	}

	public handleRemoveItem(index: number) {
		const { value = [] } = this.props;
		const valueCopy = [...value];

		valueCopy.splice(index, 1);

		this.updateConsumer(valueCopy);
	}

	public clearAll() {
		this.setState({ input: '' });

		this.updateConsumer([]);
	}

	public updateConsumer(newValues: string[]) {
		const { onChange = () => ({}) } = this.props;
		const formattedNewValues = newValues.map((v) => v.replace(/\-/g, ''));
		this.resetLimit();
		onChange(formattedNewValues);
	}

	public cancelSubmit(evt: React.KeyboardEvent<HTMLInputElement>) {
		const { value: inputValue } = evt.currentTarget;

		if (evt.keyCode === 13) {
			evt.preventDefault();
		} else if (evt.keyCode === 8) {
			if (inputValue.length === 0) {
				this.removeLastItem();
				return;
			} else {
				this.deleteWasHit = true;
			}
		}
	}

	public onPaste(evt: React.ClipboardEvent<HTMLInputElement>) {
		const text = evt.clipboardData.getData('text');
		const validTfns = TollFreeNumbersUtils.filterTollFreeNumbersList(text)
			.map(
				(tfn) => `${tfn.charAt(0) === '+' ? '+' : ''}${tfn.replace(/\D/g, '')}`
			)
			.filter((tfn) => tfn);

		this.resetLimit();
		this.addItem(validTfns, false, true);

		evt.preventDefault();
	}

	public async processFile(file: File | null) {
		if (!file) {
			return;
		}

		this.resetLimit();
		try {
			const spreadsheet = await this.fileUtils.spreadsheetFileToJson(
				file,
				true
			);

			const validTfns: string[] = [];
			for (const row of spreadsheet) {
				for (const cell of row) {
					const value = cell.replace(/\D/g, '');
					if (value.length === 10) {
						validTfns.push(value);
					}
				}
			}

			this.addItem(validTfns, false);
		} catch (err) {
			console.error('err:', err);
		}
	}

	public render() {
		const {
			value = [],
			required,
			className = '',
			name,
			displayMode,
			allowForUpload = false
		} = this.props;
		const { formLabel } = this.state;
		const valueLabel = value ? value.join(', ') : '';
		const tfnLength = value.length;
		const isOverThousand = tfnLength > 1000;
		const [firstTfn = ''] = value;
		const overThousandText = `${TollFreeNumbersUtils.tollFreeNumberToReadableString(
			firstTfn
		)} and ${(tfnLength - 1).toLocaleString()} more numbers have been entered.`;

		return (
			<ControlGroup
				label={formLabel}
				value={valueLabel}
				displayMode={displayMode}
				required={this.props.required}
				sublabel={
					this.props.maxLimit
						? `max ${this.props.maxLimit}`
						: this.props.subLabel
				}
				errorText={
					this.state.isReachedLimit
						? `Warning: Maximum limit of ${this.props.maxLimit} is reached.`
						: ''
				}
			>
				<section style={{ position: 'relative' }}>
					<section
						style={{
							position: 'absolute',
							bottom: '100%',
							right: 0
						}}
					>
						<ButtonGroup>
							{allowForUpload && (
								<FileInputButton
									size="sm"
									color="white"
									outline
									accept={FileUtils.AcceptTypes.Spreadsheet}
									onChange={(fileBlob) => this.processFile(fileBlob)}
								>
									<i className="fas fa-fw fa-upload" />
									<span>&nbsp;Upload Numbers</span>
								</FileInputButton>
							)}
							<Button
								hidden={tfnLength === 0}
								size="sm"
								variant="outline-white"
								type="button"
								tabIndex={-1}
								onClick={() => this.clearAll()}
							>
								<i className="fas fa-fw fa-times-circle" />
								<span>&nbsp;Remove All</span>
							</Button>
							{this.props.loading && (
								<Button size="sm" variant="outline-white" type="button">
									<i className="fas fa-fw fa-cog fa-spin" />
									<span>&nbsp;Loading...</span>
								</Button>
							)}
						</ButtonGroup>
					</section>
					{isOverThousand && (
						<Form.Control disabled type="text" value={overThousandText} />
					)}
					{!isOverThousand && (
						<ul
							className={`tollfree-numbers-textarea ${className} ${
								this.state.isReachedLimit ? 'is-invalid' : ''
							}`}
							onClick={() => this.onListClick()}
						>
							{value.map((item, i) => (
								<li key={item + i} onClick={() => this.handleRemoveItem(i)}>
									{item.length > 10
										? item
										: TollFreeNumbersUtils.tollFreeNumberToReadableString(item)}
								</li>
							))}
							<li className="tollfree-numbers-textarea-input">
								<input
									type="text"
									ref={this.inputRef}
									value={this.state.input}
									onKeyDown={(e) => {
										this.cancelSubmit(e);
									}}
									onChange={(e) => {
										this.handleInputChange(e);
									}}
									onPaste={(e) => {
										this.onPaste(e);
									}}
									name={name}
									required={required && value.length === 0}
									minLength={12}
								/>
							</li>
						</ul>
					)}
				</section>
			</ControlGroup>
		);
	}
}
