/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum FirebaseTargetType {
    Global = 1,
    Partition = 2,
    Product = 3,
    User = 4,
    Login = 5,
}