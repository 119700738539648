/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $HavingFilter = {
    properties: {
        column: {
            type: 'string',
            isRequired: true,
        },
        isSum: {
            type: 'number',
            maximum: 1,
        },
        operator: {
            type: 'HavingFilterOperator',
            isRequired: true,
        },
        value: {
            type: 'string',
            isRequired: true,
        },
    },
};