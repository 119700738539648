import * as React from 'react';
import {
	JobTaskIndexResponse,
	JobStatusId,
	JobTaskRecord,
	JobTaskUpdateResponse,
	ResourceIndexResponse
} from 'RtModels';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import {
	JobTaskStatusSelect,
	IJobTaskStatusSelectOption
} from 'RtUi/app/rtCarrierConnect/Jobs/lib/controls/JobTaskStatusSelect';
import { SubmitFormControl } from 'RtUi/components/form/SubmitFormControl';
import { JobTaskResource } from 'RtUi/app/rtCarrierConnect/Jobs/lib/resources/JobTaskResource';
import { Form } from 'react-bootstrap';
import { ResourceSelect } from 'RtUi/app/rtCommon/Resources/lib/controls/ResourceSelect';

interface IJobTaskEditorProps {
	jobTaskResource: JobTaskResource;
	jobTask: JobTaskIndexResponse;
	onCancel: () => void;
	onUpdate: (jobTaskRes: JobTaskUpdateResponse) => void;
}

interface IJobTaskEditorState {
	resource?: ResourceIndexResponse;
	jobTaskStatus?: IJobTaskStatusSelectOption;
	isEditable: boolean;
	isSubmitting: boolean;
	errorText: string;
	error?: any;
}

export class JobTaskEditor extends React.Component<
	IJobTaskEditorProps,
	IJobTaskEditorState
> {
	public state: IJobTaskEditorState = {
		isEditable: false,
		isSubmitting: false,
		errorText: '',
		error: undefined
	};

	constructor(props: IJobTaskEditorProps) {
		super(props);

		this.state.isEditable =
			props.jobTask.taskStatusId ===
			JobStatusId.WAITING_FOR_TASK_MANUAL_COMPLETION;
	}

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const { jobTaskResource, jobTask } = this.props;

		const updateReq: JobTaskRecord = {
			referenceKey: jobTask.referenceKey,
			errorText: this.state.errorText
		};

		let jobTaskRes: JobTaskUpdateResponse | undefined;

		try {
			this.setState({ isSubmitting: true, error: undefined });

			jobTaskRes = await jobTaskResource.update(updateReq);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		if (jobTaskRes) {
			this.props.onUpdate(jobTaskRes);
		}
	}

	public render() {
		const { jobTask } = this.props;

		return (
			<Form onSubmit={(evt) => this.onSubmit(evt)}>
				<InputFormControl
					label="Reference"
					displayMode
					value={String(jobTask.referenceKey)}
				/>
				<ResourceSelect
					displayMode
					onChange={(resource) => this.setState({ resource })}
					value={this.state.resource}
					initialOptionId={String(jobTask.resourceId)}
				/>
				<JobTaskStatusSelect
					label="Status"
					clearable={false}
					enabledJobStatusIds={[
						JobStatusId.COMPLETED_WITH_ERROR,
						JobStatusId.COMPLETED_NO_ERROR
					]}
					displayMode={!this.state.isEditable}
					onChange={(jobTaskStatus) =>
						this.setState({ jobTaskStatus, errorText: '' })
					}
					value={this.state.jobTaskStatus}
					initialOptionId={String(jobTask.taskStatusId)}
				/>
				{this.state.isEditable && (
					<>
						{this.state.jobTaskStatus?.value ===
							JobStatusId.COMPLETED_WITH_ERROR && (
							<InputFormControl
								label="Error Response"
								required
								displayMode={false}
								onChange={(errorText) => this.setState({ errorText })}
								value={this.state.errorText}
							/>
						)}
						<SubmitFormControl
							onCancel={this.props.onCancel}
							displayMode={false}
							error={this.state.error}
							isSubmitting={this.state.isSubmitting}
						/>
					</>
				)}
			</Form>
		);
	}
}
