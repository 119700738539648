import {
	GuardianReconciliationUsageDetailsSummaryIndexRequest,
	GuardianReconciliationUsageDetailsSummaryIndexResponse
} from 'RtModels';
import { IReconciliationDirection } from 'RtUi/app/rtVue/ReconciliationUsage/lib/grids/ReconciliationUsageDialCodeSummaryGrid';
import { useGetGuardianReconciliationUsageDetailsSummary } from 'RtUi/app/rtVue/ReconciliationUsage/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getReconciliationUsageDetailSummaryColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/reconciliationUsageDetails';
import { FC, useMemo } from 'react';

interface IReconciliationUsageDetailsSummaryGridProps {
	resourceParams: GuardianReconciliationUsageDetailsSummaryIndexRequest;
	autoFocusFilter?: boolean;
	className?: string;
	onDrillDown: (
		row: GuardianReconciliationUsageDetailsSummaryIndexResponse,
		direction: IReconciliationDirection
	) => void;
}

export const ReconciliationUsageDetailsSummaryGrid: FC<
	React.PropsWithChildren<IReconciliationUsageDetailsSummaryGridProps>
> = ({ resourceParams, onDrillDown, className }) => {
	const { data, isFetching: isLoading } =
		useGetGuardianReconciliationUsageDetailsSummary(resourceParams);
	const columns = useMemo(
		() => getReconciliationUsageDetailSummaryColumns(onDrillDown),
		[onDrillDown]
	);

	return (
		<section className={className}>
			<h4>CDR Match Summary</h4>
			<DataGrid<GuardianReconciliationUsageDetailsSummaryIndexResponse>
				data={data?.data}
				columns={columns}
				totalRows={data?.totalCount}
				loading={isLoading}
				pageName={'rtVue_reconciliationUsageDetailsSummary'}
			/>
		</section>
	);
};
