import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianReconciliationMappingCreateRequest,
	GuardianReconciliationMappingIndexResponse,
	GuardianReconciliationMappingProfileResponse,
	GuardianReconciliationMappingProfileUrlRequest,
	GuardianReconciliationMappingUpdateRequest,
	GuardianReconciliationMappingRecurringScheduleType
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class GuardianReconciliationMappingResource extends HttpResource<
	GuardianReconciliationMappingIndexResponse,
	GuardianReconciliationMappingProfileResponse
> {
	constructor() {
		super(RtVueApiRoutes.GuardianReconciliationMappings);
	}

	public create(request: GuardianReconciliationMappingCreateRequest) {
		const body = JSON.stringify(request);

		return this.fetchWithRoute<GuardianReconciliationMappingProfileResponse>(
			RtVueApiRoutes.GuardianReconciliationMappings.Create,
			{
				body
			}
		);
	}

	public update(
		guardianReconciliationMappingId: number,
		request: GuardianReconciliationMappingUpdateRequest
	) {
		const body = JSON.stringify(request);
		const urlParams: GuardianReconciliationMappingProfileUrlRequest = {
			guardianReconciliationMappingId
		};

		return this.fetchWithRoute<GuardianReconciliationMappingProfileResponse>(
			RtVueApiRoutes.GuardianReconciliationMappings.Update,
			{
				body,
				urlParams
			}
		);
	}

	public createNewProfile() {
		const profile: GuardianReconciliationMappingProfileResponse = {
			guardianReconciliationMappingId: -1,
			reconciliationTypeId: null,
			summary: null,
			isActive: 1,
			createdTs: new Date(),
			updatedTs: new Date(),
			updatedBy: -1,
			carrierBanNumber: null,
			recurringScheduleType:
				GuardianReconciliationMappingRecurringScheduleType.Monthly
		};

		return profile;
	}
}
