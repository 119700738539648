/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum NotificationFrequencyType {
    Immediate = 1,
    Daily = 2,
    Hours1 = 3,
    Hours2 = 4,
    Hours4 = 5,
    Hours12 = 6,
}