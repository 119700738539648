/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ZipFormat {
    Zip = 1,
    GZip = 2,
    Parquet = 3,
    ParquetZip = 4,
}