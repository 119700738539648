import {
	ISelectFormControlProps,
	ISelectFormControlState,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { LcrCarrierIndexResponse } from 'RtModels';
import { LcrCarriersResource } from 'RtUi/app/rtLco/Carriers/lib/resources/LcrCarriersResource';
import { LcrCarrierRouter } from 'RtUi/app/rtLco/Carriers/LcrCarrier.router';

interface ILcrCarrierSelectProps<isMulti extends boolean = false>
	extends ISelectFormControlProps<LcrCarrierIndexResponse, isMulti> {
	respOrgId?: string;
	cic?: string;
}

export class LcrCarrierSelect<
	isMulti extends boolean = false
> extends SelectFormControl<
	LcrCarrierIndexResponse,
	isMulti,
	ILcrCarrierSelectProps<isMulti>
> {
	public static defaultProps = {
		router: LcrCarrierRouter
	};
	public resourceClass = LcrCarriersResource;
	public state: ISelectFormControlState<LcrCarrierIndexResponse> = {
		formLabel: 'Account',
		valueKey: 'lcrCarrierId',
		labelKey: 'label',
		placeholder: 'Select Account...'
	};

	constructor(props: ILcrCarrierSelectProps<isMulti>) {
		super(props);

		this.updateGetAllParams();
	}

	public componentDidUpdate(prevProps: ILcrCarrierSelectProps<isMulti>) {
		this.updateGetAllParams();

		super.componentDidUpdate(prevProps);
	}

	public updateGetAllParams() {
		const { respOrgId } = this.props;
		const getAllParams: any = { respOrgId };

		this.setGetAllParams(getAllParams);
	}

	/**
	 * @override
	 */
	public getData() {
		const { cic } = this.props;
		const data = super.getData();

		if (!cic) {
			return data;
		}

		return data.then((records) => {
			return records.filter((record) => record.cic === cic);
		});
	}

	public optionRenderer = (option: LcrCarrierIndexResponse) => {
		return <span>{`${option.label} (${option.lcrCarrierId})`}</span>;
	};
}
