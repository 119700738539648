import { padStart } from 'lodash-es';
import * as moment from 'moment-timezone';
import * as React from 'react';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import {
	Alert,
	Button,
	ButtonGroup,
	ButtonToolbar,
	Form,
	InputGroup,
	ListGroup
} from 'react-bootstrap';
import { generateUUID as generateUuid } from 'RtUi/utils/http/resources/utils';
import {
	getViewportHeight,
	isViewportAtOrBelowBreakpoint
} from 'RtUi/utils/react/ViewportHelpers';
import { ControlGroup } from '../../ui/ControlGroup';
import {
	FormControl,
	IFormControlProps,
	IFormControlState
} from '../FormControl';

type DateTimeFormControlValue<IsRequired extends boolean = false> =
	IsRequired extends true ? moment.Moment : moment.Moment | undefined;

export interface IDatetimeFormControlProps<IsRequired extends boolean = false>
	extends Omit<
		IFormControlProps<DateTimeFormControlValue<IsRequired>>,
		'required'
	> {
	required?: IsRequired;
	disabled?: boolean;
	placeholder?: string;
	minutesStep?: number;
	minDate?: moment.Moment;
	maxDate?: moment.Moment;
	disablePresets?: boolean;
	hideControlGroup?: boolean;
	disableNowPreset?: boolean;
	displayModeTimezone?: string;
	onChangeWithIsNow?: (
		value: DateTimeFormControlValue<IsRequired>,
		isNow: boolean
	) => void;
	enableSomosMaintenanceWindowSelection?: boolean;
	isNow?: boolean;
	initAsNow?: boolean;
	subLabel?: string;
	appendToBody?: boolean;
	showDateOnly?: boolean;
	id?: string;
}

interface IDatetimeFormControlState extends IFormControlState {
	isFocused: boolean;
	useFullScreen: boolean;
	isNow: boolean;
	targetRefCustomValidityMessage: string;
	openDirection: SingleDatePickerShape['openDirection'];
}

export class DatetimeFormControl<
	IsRequired extends boolean = false
> extends FormControl<
	moment.Moment,
	false,
	IDatetimeFormControlProps<IsRequired>,
	IDatetimeFormControlState
> {
	public static DATE_FORMAT = 'MM/DD/YYYY';
	public static TIME_FORMAT = 'hh:mma';

	public static GetDatetimeFormControlPresets() {
		const now = moment();
		const EightAm = moment().hours(8).startOf('hour');
		const EightPm = moment().hours(20).startOf('hour');

		if (now.isAfter(EightAm)) {
			EightAm.add(1, 'day');
		}

		if (now.isAfter(EightPm)) {
			EightPm.add(1, 'day');
		}

		return { EightAm, EightPm };
	}

	public presets = DatetimeFormControl.GetDatetimeFormControlPresets();
	public uuid = generateUuid();
	public resource = undefined;
	public state: IDatetimeFormControlState = {
		formLabel: 'Scheduled At',
		isFocused: false,
		isNow: false,
		targetRefCustomValidityMessage: '',
		openDirection: 'down',
		useFullScreen: false
	};

	public targetRef: HTMLInputElement | null = null;
	public controlRef = React.createRef<HTMLElement>();
	public ref = React.createRef<React.Component<SingleDatePickerShape>>();

	public componentDidMount() {
		super.componentDidMount();

		this.validateCurrentValue();

		const { value, minutesStep = 1, initAsNow = false } = this.props;

		if (minutesStep > 1 && value) {
			const roundedValue = value.round(minutesStep, 'minutes', 'ceil');

			this.onChangeHandler(roundedValue, initAsNow);
		} else if (initAsNow) {
			this.onChangeHandler(value, initAsNow);
		}
	}

	public componentDidUpdate(prevProps: IDatetimeFormControlProps<IsRequired>) {
		super.componentDidUpdate(prevProps);

		this.validateCurrentValue();
	}

	public validateCurrentValue() {
		const {
			value,
			maxDate,
			minDate,
			enableSomosMaintenanceWindowSelection = false
		} = this.props;
		const { isNow } = this.state;

		if (!value) {
			if (!minDate || !maxDate) {
				this.setTargetRefCustomValidityMessage('');
			}

			return;
		}

		let validityMessage = '';

		if (isNow) {
			this.setTargetRefCustomValidityMessage('');
			return;
		}

		if (minDate && value.isBefore(minDate)) {
			validityMessage = `Value must be after ${minDate.format(
				'MM/DD/YYYY hh:mma'
			)}`;
		}

		if (maxDate && value.isAfter(maxDate)) {
			validityMessage = `Value must be before ${maxDate.format(
				'MM/DD/YYYY hh:mma'
			)}`;
		}

		if (!validityMessage && !enableSomosMaintenanceWindowSelection) {
			const [
				somosMaintenanceWindowLowerBound,
				somosMaintenanceWindowUpperBound
			] = this.getSomosMaintenanceWindowForDate();
			const isInMaintenanceWindow = value.isBetween(
				somosMaintenanceWindowLowerBound,
				somosMaintenanceWindowUpperBound,
				'minutes',
				'[]'
			);

			if (isInMaintenanceWindow) {
				const maintenanceLowerDisplay =
					somosMaintenanceWindowLowerBound.format('hh:mma');
				const maintenanceUpperDisplay =
					somosMaintenanceWindowUpperBound.format('hh:mma');

				validityMessage = `Value cannot be set between ${maintenanceLowerDisplay} - ${maintenanceUpperDisplay}`;
			}
		}

		this.setTargetRefCustomValidityMessage(validityMessage);
	}

	public getSomosMaintenanceWindowForDate(): [moment.Moment, moment.Moment] {
		const calendarDay = moment().format('YYYY-MM-DD');
		const somosMaintenanceWindowLowerBound = moment.tz(
			`${calendarDay} 22:45:00`,
			'America/Chicago'
		);
		const somosMaintenanceWindowUpperBound = moment.tz(
			`${calendarDay} 23:15:00`,
			'America/Chicago'
		);

		return [somosMaintenanceWindowLowerBound, somosMaintenanceWindowUpperBound];
	}

	public setTargetRef(ref: HTMLInputElement | null) {
		this.targetRef = ref;

		if (this.targetRef) {
			this.targetRef.setCustomValidity(
				this.state.targetRefCustomValidityMessage
			);
		}
	}

	public setTargetRefCustomValidityMessage(
		targetRefCustomValidityMessage: string
	) {
		if (
			this.state.targetRefCustomValidityMessage ===
			targetRefCustomValidityMessage
		) {
			return;
		}

		this.setState({ targetRefCustomValidityMessage });

		if (this.targetRef) {
			this.targetRef.setCustomValidity(targetRefCustomValidityMessage);
		}
	}

	public onChangeHandler(newValue: moment.Moment | undefined, isNow: boolean) {
		const { onChange = () => ({}), onChangeWithIsNow = () => ({}) } =
			this.props;
		const { minutesStep = 1 } = this.props;

		if (newValue) {
			//this is need to refresh calendar
			newValue = newValue.clone();

			const movedToCustomMode = this.state.isNow && !isNow;
			const shouldRound = movedToCustomMode && minutesStep > 1;

			if (shouldRound) {
				newValue.round(minutesStep, 'minutes', 'ceil');
			}

			newValue.startOf('minute');
		}

		onChange(newValue as DateTimeFormControlValue<IsRequired>);
		this.setState({ isNow });
		onChangeWithIsNow(newValue as DateTimeFormControlValue<IsRequired>, isNow);

		if (isNow || this.props.showDateOnly) {
			this.onFocusChange(false);
		}
	}

	public updateHours(hoursStr: string) {
		let { value } = this.props;

		if (!value) {
			return;
		}

		const currentHours = value.hours();
		const isPm = currentHours > 12;
		let hours = Number(hoursStr);

		if (isPm && hours === 0) {
			hours = 12;
		} else if (isPm && currentHours === 12 && hours === 11) {
			hours = -1;
		} else if (!isPm && currentHours === 0 && hours === 11) {
			hours = -1;
		} else if (!isPm && currentHours === 0 && hours === 13) {
			hours = 1;
		} else if (isPm && hours <= 12) {
			hours += 12;
		}

		value = value.hours(hours);

		this.onChangeHandler(value, false);
	}

	public updateMinutes(minutesStr: string) {
		let { value } = this.props;
		const { minutesStep = 1 } = this.props;

		if (!value) {
			return;
		}

		const minutes = Number(minutesStr);

		value = value.minutes(minutes);

		if (minutesStep > 1) {
			value = value.round(minutesStep, 'minutes', 'round');
		}

		this.onChangeHandler(value, false);
	}

	public isOutsideOfRange(momentObj: moment.Moment): boolean {
		const { minDate, maxDate } = this.props;

		const isBefore = this.isBefore(momentObj, minDate);
		const isAfter = this.isAfter(momentObj, maxDate);

		return isBefore || isAfter;
	}

	public setToPreset(preset: moment.Moment) {
		const newPreset = preset.clone();

		this.onChangeHandler(newPreset, false);
		this.onFocusChange(false);
	}

	public onCalendarSelect(newDate: moment.Moment | null) {
		if (!newDate) {
			return;
		}

		const newValue = newDate.clone();
		const { value } = this.props;

		if (value) {
			newValue.hours(value.hours());
			newValue.minutes(value.minutes());
		}

		this.onChangeHandler(newValue, false);
	}

	public onAmPmChange(amOrPm: string) {
		let { value } = this.props;

		if (!value) {
			return;
		}

		const isPm = amOrPm.toLowerCase() === 'pm';
		const valueHours = value.hours();
		const valueIsPm = valueHours >= 12;

		if (isPm) {
			if (!valueIsPm) {
				value = value.hours(valueHours + 12);
			}
		} else {
			if (valueIsPm) {
				value = value.hours(valueHours - 12);
			}
		}

		this.onChangeHandler(value, false);
	}

	public onFocusChange(isFocused: boolean) {
		let openDirection: SingleDatePickerShape['openDirection'] = 'down';
		const widthAtOrBelowMd = isViewportAtOrBelowBreakpoint('md');
		const hasSmallViewportHeight = getViewportHeight() <= 600;
		const useFullScreen = widthAtOrBelowMd || hasSmallViewportHeight;

		if (isFocused && this.controlRef.current) {
			const clientRect = this.controlRef.current.getBoundingClientRect();
			const modalHeight = 362;
			const headerHeight = 60;
			const heightToShowModalTop = modalHeight + headerHeight;

			openDirection = clientRect.top >= heightToShowModalTop ? 'up' : 'down';
		}

		this.setState({ isFocused, openDirection, useFullScreen });
	}

	private isBefore(
		currentDate: moment.Moment,
		minDate: moment.Moment | undefined
	) {
		if (!minDate) {
			return false;
		}

		const formattedDateStr = currentDate.format('MM/DD/YYYY');
		const minDateDateStr = minDate.format('MM/DD/YYYY');
		const isSameDay = formattedDateStr === minDateDateStr;

		if (isSameDay) {
			return false;
		}

		return currentDate.isBefore(minDate);
	}

	private isAfter(
		currentDate: moment.Moment,
		maxDate: moment.Moment | undefined
	) {
		if (!maxDate) {
			return false;
		}

		const formattedDateStr = currentDate.format('MM/DD/YYYY');
		const isAfterDateStr = maxDate.format('MM/DD/YYYY');
		const isSameDay = formattedDateStr === isAfterDateStr;

		if (isSameDay) {
			return false;
		}

		return currentDate.isAfter(maxDate);
	}

	private getValueLabel() {
		let { value } = this.props;
		const { displayModeTimezone } = this.props;

		if (!value) {
			return undefined;
		}

		if (!value.isValid()) {
			return '';
		}

		if (displayModeTimezone) {
			value = moment.tz(value, displayModeTimezone);
		}

		const format = this.getFormat();
		let valueLabel = value.format(format);

		if (displayModeTimezone) {
			valueLabel += ` (${displayModeTimezone})`;
		}

		return valueLabel;
	}

	private getFormat() {
		return this.props.showDateOnly
			? DatetimeFormControl.DATE_FORMAT
			: `${DatetimeFormControl.DATE_FORMAT} ${DatetimeFormControl.TIME_FORMAT}`;
	}

	// Hacky way to handle the onBlur event on the picker (which is not supported natively)
	private onBlurHandler(evt: React.FocusEvent<HTMLElement, Element>) {
		const picker = this.ref.current;
		if (!picker) {
			return;
		}

		const target = evt.relatedTarget ?? evt.target;
		const { container, dayPickerContainer } = picker as any;

		if (
			!(container as HTMLElement)?.contains(target) &&
			!(dayPickerContainer as HTMLElement)?.contains(target)
		) {
			this.onFocusChange(false);
		}
	}

	public render() {
		const {
			required,
			name = this.uuid,
			displayMode,
			placeholder = '',
			disabled = false,
			minutesStep = 1,
			disablePresets = false,
			hideControlGroup = false,
			disableNowPreset = false
		} = this.props;
		let { value } = this.props;

		const { formLabel, useFullScreen } = this.state;
		const valueLabel = this.getValueLabel();
		let hourValue = value ? value.hours() : 0;
		let minuteValue: string | number = value ? value.minutes() : 0;
		const isAmOrPm = hourValue >= 12 ? 'PM' : 'AM';
		hourValue = hourValue % 12;

		//Pad minutes
		minuteValue = padStart(String(minuteValue), 2, '0');

		if (hourValue === 0) {
			hourValue = 12;
		}
		const displayFormat = this.state.isNow ? `\\N\\o\\w` : this.getFormat();
		const isEightAmPreset =
			value && !this.state.isNow && this.presets.EightAm.isSame(value);
		const isEightPmPreset =
			value && !this.state.isNow && this.presets.EightPm.isSame(value);
		const isCustom = !this.state.isNow && !isEightAmPreset && !isEightPmPreset;

		// give any value to show the "Now" display
		if (this.state.isNow) {
			value = moment();
		}

		const btnGroup = (
			<ButtonGroup size="sm" className="ms-2 mt-lg-2">
				<Button
					variant="outline-white"
					onClick={(e) => {
						e.preventDefault();
						const current = moment(value ?? new Date());
						const newValue = moment(current).hours(0).minutes(0);

						if (newValue.isSame(current)) {
							return this.onChangeHandler(newValue.subtract(1, 'day'), false);
						}

						this.onChangeHandler(newValue, false);
					}}
				>
					<i className="fa-solid fa-backward-fast" />
				</Button>
				<Button
					variant="outline-white"
					onClick={(e) => {
						e.preventDefault();
						const current = moment(value ?? new Date());
						const newValue = moment(current).hours(23).minutes(59);

						if (newValue.isSame(current)) {
							return this.onChangeHandler(newValue.add(1, 'day'), false);
						}

						this.onChangeHandler(newValue, false);
					}}
				>
					<i className="fa-solid fa-forward-fast" />
				</Button>
				<Button
					variant="outline-white"
					onClick={(e) => {
						e.preventDefault();
						const newDate = moment(value?.toDate() || new Date()).subtract(
							1,
							'hour'
						);
						this.onChangeHandler(value?.hour(newDate.hour()), false);
					}}
				>
					<i className="fa-solid fa-minus" />
				</Button>
				<Button
					variant="outline-white"
					onClick={(e) => {
						e.preventDefault();
						const newDate = moment(value?.toDate() || new Date()).add(
							1,
							'hour'
						);
						this.onChangeHandler(value?.hour(newDate.hour()), false);
					}}
				>
					<i className="fa-solid fa-plus" />
				</Button>
			</ButtonGroup>
		);

		const datePickerElem = (
			<section
				className="position-relative flex-fill"
				ref={this.controlRef}
				id={this.props.id}
				onBlur={this.onBlurHandler.bind(this)}
			>
				<SingleDatePicker
					id={name}
					ref={this.ref}
					required={required}
					disabled={disabled}
					block
					placeholder={placeholder}
					withFullScreenPortal={useFullScreen}
					keepOpenOnDateSelect={!useFullScreen}
					numberOfMonths={this.props.showDateOnly ? 2 : 1}
					customCloseIcon={undefined}
					date={value ? value : null}
					isOutsideRange={(momentObj: moment.Moment) =>
						this.isOutsideOfRange(momentObj)
					}
					displayFormat={displayFormat}
					focused={this.state.isFocused}
					onDateChange={(newValue) => this.onCalendarSelect(newValue)}
					onFocusChange={({ focused }) => this.onFocusChange(Boolean(focused))}
					calendarInfoPosition={useFullScreen ? 'bottom' : 'after'}
					anchorDirection="right"
					appendToBody={this.props.appendToBody}
					hideKeyboardShortcutsPanel
					openDirection={this.state.openDirection}
					renderCalendarInfo={
						this.props.showDateOnly
							? undefined
							: () => {
									return (
										<section
											style={{
												height: '100%',
												minHeight: 362,
												paddingLeft: 22,
												paddingRight: 22
											}}
										>
											<header className="CalendarMonth_caption pb-0 mb-3">
												<strong>Time</strong>
											</header>
											{!value && (
												<Alert
													variant="info"
													className="d-flex justify-content-start"
												>
													<i className="fas fa-fw fa-info-circle me-1" />
													<span>Select a date first</span>
												</Alert>
											)}
											<article
												className="d-flex justify-content-center align-items-center time-controls"
												style={{ opacity: this.state.isNow ? 0 : 1 }}
											>
												<InputGroup>
													<Form.Control
														type="number"
														min={0}
														max={13}
														value={hourValue}
														disabled={!value}
														onChange={(evt) =>
															this.updateHours(evt.target.value)
														}
													/>
													<Form.Control
														type="number"
														min={-minutesStep}
														max={60}
														step={minutesStep}
														value={minuteValue}
														onChange={(evt) =>
															this.updateMinutes(evt.target.value)
														}
														disabled={!value}
													/>
													<select
														className="form-select"
														value={isAmOrPm}
														onChange={(evt) =>
															this.onAmPmChange(evt.target.value)
														}
														disabled={!value}
													>
														<option>AM</option>
														<option>PM</option>
													</select>
												</InputGroup>
												<Button
													type="button"
													className="ms-1"
													variant="primary"
													onClick={() => this.onFocusChange(false)}
													disabled={!value}
												>
													<i className="fas fa-sm fa-fw fa-check" />
												</Button>
											</article>
											<div className="mt-2">{btnGroup}</div>
											{!disablePresets && (
												<article className="mt-4">
													<ListGroup className="list-group-sm list-group-selectable">
														{!disableNowPreset && (
															<ListGroup.Item
																action
																onClick={() =>
																	this.onChangeHandler(value, true)
																}
																active={this.state.isNow}
															>
																Now
															</ListGroup.Item>
														)}
														{this.state.isNow && (
															<ListGroup.Item
																action
																onClick={() =>
																	this.onChangeHandler(value, false)
																}
																active={isCustom}
															>
																Custom
															</ListGroup.Item>
														)}
														<ListGroup.Item
															action
															onClick={() =>
																this.setToPreset(this.presets.EightAm)
															}
															active={isEightAmPreset}
														>
															{`8:00 AM ${
																this.presets.EightAm.isSame(moment(), 'day')
																	? 'Today'
																	: 'Tomorrow'
															}`}
														</ListGroup.Item>
														<ListGroup.Item
															action
															onClick={() =>
																this.setToPreset(this.presets.EightPm)
															}
															active={isEightPmPreset}
														>
															{`8:00 PM ${
																this.presets.EightPm.isSame(moment(), 'day')
																	? 'Tonight'
																	: 'Tomorrow'
															}`}
														</ListGroup.Item>
													</ListGroup>
												</article>
											)}
										</section>
									);
								}
					}
				/>
				{!required && value && (
					<a
						className="input-action input-action-right"
						onClick={() => this.onChangeHandler(undefined, false)}
					>
						<i className="fas fa-fw fa-times" />
					</a>
				)}
				{!value && (
					<a
						className="input-action input-action-right"
						onClick={() => this.onFocusChange(true)}
					>
						<i className="fas fa-fw fa-clock" />
					</a>
				)}
				<input
					type="text"
					ref={(ref) => this.setTargetRef(ref)}
					tabIndex={-1}
					required
					minLength={1}
					onChange={() => void 0}
					value={this.state.targetRefCustomValidityMessage ? '' : '1'}
					style={{
						height: 1,
						width: 0,
						opacity: 0.01,
						position: 'absolute',
						left: '50%',
						bottom: 0
					}}
				/>
			</section>
		);

		return (
			<ControlGroup
				label={formLabel}
				value={valueLabel}
				hideFormGroup={hideControlGroup}
				hideLabel={hideControlGroup}
				displayMode={displayMode}
				sublabel={this.props.subLabel}
				required={this.props.required}
				className={this.props.className}
			>
				<ButtonToolbar className="d-flex">
					{datePickerElem}
					{!this.props.showDateOnly && btnGroup}
				</ButtonToolbar>
			</ControlGroup>
		);
	}
}
