import { LcrCarrierIndexResponse } from 'RtModels';
import { LcrCarriersResource } from 'RtUi/app/rtLco/Carriers/lib/resources/LcrCarriersResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface LcrCarrierDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<
		LcrCarrierIndexResponse,
		T
	> {}

export const LcCarrierDataGridCache =
	new ResourceCacheMap<LcrCarrierIndexResponse>(
		new LcrCarriersResource(),
		'lcrCarrierId',
		'label'
	);

export const LcrCarriersDataGridColumn = <T = any,>(
	config: LcrCarrierDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: LcCarrierDataGridCache,
		...config
	});
};
