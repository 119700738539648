import { NaHistory } from 'RtModels';
import { useGetNumberHistory } from 'RtUi/app/rt800/Numbers/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getNumberHistoryColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/numbers';
import { useMemo } from 'react';

interface INumberHistoryGridProps {
	tfn: string;
	quickView?: boolean;
	disableFilter?: boolean;
	mobilePagination?: boolean;
}

export const NumberHistoryGrid = ({
	tfn,
	quickView = false
}: INumberHistoryGridProps) => {
	const { data, isFetching: isLoading } = useGetNumberHistory({ tfn });
	const columns = useMemo(
		() => getNumberHistoryColumns(quickView),
		[quickView]
	);

	return (
		<DataGrid<NaHistory>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			disableControls
			pageName={'rt800_numberHistory'}
		/>
	);
};
