import {
	DataGridColumn,
	FooterProps
} from 'RtUi/components/data/DataGrid/types';
import {
	PrecisionDataGridColumn,
	PrecisionDataGridColumnConfiguration
} from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';
import { getColumnValue } from 'RtUi/components/data/DataGrid/utils';
import { sumBy } from 'lodash-es';

export interface PercentDataGridColumnConfiguration<T>
	extends PrecisionDataGridColumnConfiguration<T> {
	convertFromDecimal?: boolean;
}

export const PercentDataGridColumn = <T = any,>({
	...config
}: PercentDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const footerTotal = (props: FooterProps<T>) => {
		const currentData = props.table
			.getPrePaginationRowModel()
			.rows.reduce<any[]>((dest, row) => {
				const value = getColumnValue(row.original as T, config);

				if (value !== undefined) {
					dest.push(value);
				}

				return dest;
			}, []);

		if (!currentData.length) {
			return 0;
		}

		return sumBy(currentData) / currentData.length;
	};

	return PrecisionDataGridColumn({
		...config,
		template: ':value: %',
		footerTotal
	});
};
