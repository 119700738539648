/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $AccountIndexResponse = {
    properties: {
        accountId: {
            type: 'number',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        companyName: {
            type: 'string',
            isRequired: true,
        },
        isActive: {
            type: 'number',
        },
        customerBalance: {
            type: 'number',
            isRequired: true,
        },
        vendorBalance: {
            type: 'number',
            isRequired: true,
        },
        combinedBalance: {
            type: 'number',
            isRequired: true,
        },
        customerLimit: {
            type: 'number',
            isRequired: true,
        },
        vendorLimit: {
            type: 'number',
            isRequired: true,
        },
        combinedLimit: {
            type: 'number',
            isRequired: true,
        },
    },
};