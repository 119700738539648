import * as React from 'react';
import { Alert, Card } from 'react-bootstrap';
import { Aside } from 'RtUi/components/ui/Aside';
import { UncontrolledAsideContext } from 'RtUi/components/ui/Aside/Uncontrolled';
import {
	IUiNotificationSettings,
	IUiNotificationState,
	UiNotification
} from '../../../../../notifications/lib/UiNotification';

interface IDashboardContentProps {
	notification: UiNotification;
	renderWhenHasErrors: () => JSX.Element;
	onClose?: () => void;
}

interface IDashboardContentState
	extends IUiNotificationState,
		IUiNotificationSettings {}

export class DashboardContent extends React.Component<
	IDashboardContentProps,
	IDashboardContentState
> {
	public static contextType = UncontrolledAsideContext;
	public declare context: React.ContextType<typeof UncontrolledAsideContext>;

	public removeUpdateListenerFn: () => void = () => ({});

	public componentDidMount() {
		const { notification } = this.props;

		this.removeUpdateListenerFn = notification.onUpdate((settings, state) => {
			this.setState({
				...state,
				...settings
			});
		});
	}

	public componentWillUnmount() {
		this.removeUpdateListenerFn();
	}

	public render() {
		if (!this.state) {
			return null;
		}

		const { notification } = this.props;

		if (!notification.userHasAccessToNotification()) {
			return null;
		}

		const header = notification.getTitle();
		const { isFetching, issuesCount, isWarning } = this.state;
		const isSuccess = typeof issuesCount === 'number' && issuesCount === 0;

		if (isFetching) {
			return null;
		}

		const iconClassNames = ['me-2', 'fas', 'fa-fw'];

		if (isSuccess) {
			iconClassNames.push('fa-check-circle', 'text-success');
		} else if (isWarning) {
			iconClassNames.push('fa-exclamation-circle', 'text-warning');
		} else {
			iconClassNames.push('fa-exclamation-triangle', 'text-danger');
		}

		return (
			<section>
				<Aside.Header
					header={
						<>
							<i className={iconClassNames.join(' ')} />
							<span>{header}</span>
						</>
					}
					onClose={this.context.onClose}
				/>
				<Card.Body>
					{isSuccess && (
						<Alert variant="success" className="mt-3">
							{/* <i className="fas fa-fw fa-check-circle" /> */}
							<span>{header} is in perfect working order!</span>
						</Alert>
					)}
					{!isSuccess && this.props.renderWhenHasErrors()}
				</Card.Body>
			</section>
		);
	}
}
