import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { GuardianSubscriptionCicIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';
import { LcrCarriersDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LcrCarriersDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { DeleteDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DeleteDataGridColumn';

export const getSubscriptionCICColumns = (
	handleRemoveEntity: (row: GuardianSubscriptionCicIndexResponse) => void
): Array<DataGridColumn<GuardianSubscriptionCicIndexResponse>> => [
	DeleteDataGridColumn({
		onClick: handleRemoveEntity
	}),
	IdDataGridColumn({
		accessorKey: 'guardianSubscriptionCicId'
	}),
	SubscriptionDataGridColumn({
		accessorKey: 'subscriptionId',
		header: 'Subscription'
	}),
	DefaultDataGridColumn({
		accessorKey: 'cic',
		header: 'Cic'
	}),
	LcrCarriersDataGridColumn({
		accessorKey: 'lcoCarrierId',
		header: 'Lco Account'
	}),
	IntegerDataGridColumn({
		accessorKey: 'priority',
		header: 'Priority'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'updatedTs',
		header: 'Updated'
	})
];
