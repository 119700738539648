import { FC, useState } from 'react';
import { Card, Col, ListGroup, Nav, Row } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { GuardianRatingSimulatorResponse } from 'RtModels';
import { NestedTable } from 'RtUi/app/rtVue/RatingSimulator/lib/components/NestedTable';
import { RatingSimulatorSummary } from 'RtUi/app/rtVue/RatingSimulator/lib/components/RatingSimulatorSummary';

enum TabTypes {
	summary = 'Summary',
	json = 'JSON',
	table = 'Table'
}

interface IRatingSimulatorResultsProps {
	result?: GuardianRatingSimulatorResponse;
}

export const RatingSimulatorResults: FC<
	React.PropsWithChildren<IRatingSimulatorResultsProps>
> = ({ result }) => {
	const [activeTab, setActiveTab] = useState(TabTypes.summary);

	if (!result) {
		return <section>No results so far</section>;
	}

	return (
		<Row>
			<Col xs={{ order: 2 }} lg={{ span: 8, order: 1 }}>
				<Card className="mb-3">
					<Nav
						variant="tabs"
						defaultActiveKey={activeTab}
						onSelect={(eventKey) =>
							setActiveTab((eventKey as TabTypes) ?? TabTypes.table)
						}
					>
						<Nav.Link eventKey={TabTypes.summary}>
							{TabTypes.summary} View
						</Nav.Link>
						<Nav.Link eventKey={TabTypes.table}>{TabTypes.table} View</Nav.Link>
						<Nav.Link eventKey={TabTypes.json}>{TabTypes.json} View</Nav.Link>
					</Nav>
					<Card.Body>
						{activeTab === TabTypes.summary && (
							<RatingSimulatorSummary vcdr={result.cdr} />
						)}
						{activeTab === TabTypes.table && (
							<NestedTable
								data={result.cdr}
								ignoreProperties={[
									'cdrRecord',
									'mySqlConn',
									'decisionMatrix',
									'performance'
								]}
							/>
						)}
						{activeTab === TabTypes.json && (
							<ReactJson
								src={result.cdr}
								displayDataTypes={false}
								name={false}
								enableClipboard={false}
								collapsed={2}
							/>
						)}
					</Card.Body>
				</Card>
			</Col>
			<Col xs={{ order: 1 }} lg={{ span: 4, order: 2 }}>
				{Array.isArray(result.cdr.decisionMatrix) && (
					<Card className="mb-3">
						<Card.Header className="bg-info text-white">
							<header className="h5">Decision Matrix</header>
						</Card.Header>
						<Card.Body className="py-1">
							The rating simulator has created these results based on the
							following decisions and analysis:
						</Card.Body>
						<ListGroup
							variant="flush"
							as="ol"
							className="list-group-numbered rounded"
						>
							{Object.values(result.cdr.decisionMatrix).map((value, index) => (
								<ListGroup.Item
									key={value.function + index}
									as="li"
									className="d-flex justify-content-between align-items-start"
								>
									<div className="ms-2 me-auto">
										<header className="fw-bold">{value.summary}</header>
										<section>{value.outcome}</section>
									</div>
								</ListGroup.Item>
							))}
						</ListGroup>
					</Card>
				)}
			</Col>
		</Row>
	);
};
