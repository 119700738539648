/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocTfnIndexResponse = {
    properties: {
        tfn: {
            type: 'string',
            isRequired: true,
        },
        controllingRespOrgId: {
            type: 'string',
            isRequired: true,
        },
        rocStatusId: {
            type: 'number',
            isRequired: true,
        },
        processedTs: {
            type: 'Date',
            format: 'date-time',
        },
        rejectReasons: {
            type: 'Array',
        },
        rejectNote: {
            type: 'string',
        },
    },
};