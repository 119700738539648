/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipTraceProfileResponseCallData = {
    properties: {
        aliasDst: {
            type: 'string',
            isRequired: true,
        },
        aliasSrc: {
            type: 'string',
            isRequired: true,
        },
        create_date: {
            type: 'number',
            isRequired: true,
        },
        destination: {
            type: 'number',
            isRequired: true,
        },
        dstHost: {
            type: 'string',
            isRequired: true,
        },
        dstId: {
            type: 'string',
            isRequired: true,
        },
        dstIp: {
            type: 'string',
            isRequired: true,
        },
        dstPort: {
            type: 'string',
            isRequired: true,
        },
        id: {
            type: 'string',
            isRequired: true,
        },
        method: {
            type: 'string',
            isRequired: true,
        },
        method_text: {
            type: 'string',
            isRequired: true,
        },
        micro_ts: {
            type: 'number',
            isRequired: true,
        },
        msg_color: {
            type: 'string',
            isRequired: true,
        },
        protocol: {
            type: 'number',
            isRequired: true,
        },
        ruri_user: {
            type: 'string',
            isRequired: true,
        },
        sid: {
            type: 'string',
            isRequired: true,
        },
        srcHost: {
            type: 'string',
            isRequired: true,
        },
        srcId: {
            type: 'string',
            isRequired: true,
        },
        srcIp: {
            type: 'string',
            isRequired: true,
        },
        srcPort: {
            type: 'number',
            isRequired: true,
        },
        table: {
            type: 'string',
            isRequired: true,
        },
    },
};