import { SipAgentConfigEditor } from 'RtUi/app/AccountManagement/SipAgents/lib/forms/SipAgentConfigEditor';
import { SipAgentEditor } from 'RtUi/app/AccountManagement/SipAgents/lib/forms/SipAgentEditor';
import { SipAgentConfigsGrid } from 'RtUi/app/AccountManagement/SipAgents/lib/grids/SipAgentConfigsGrid';
import { SipAgentsGrid } from 'RtUi/app/AccountManagement/SipAgents/lib/grids/SipAgentsGrid';
import { SipAgentRouter } from 'RtUi/app/AccountManagement/SipAgents/SipAgent.router';
import { SipAgentConfigRouter } from 'RtUi/app/AccountManagement/SipAgents/SipAgentConfig.router';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const SipAgentIndexContainer = (): JSX.Element => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		SipAgents: { header: 'SIP Agents', isDefault: true },
		SipAgentConfigs: { header: 'SIP Agent Configs' },
		CreateSipAgent: { header: 'Create SIP Agent', isAction: true },
		CreateSipAgentConfigs: { header: 'Create SIP Agent Config', isAction: true }
	});

	return (
		<TabbedLayout
			router={SipAgentRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.SipAgents}>
				<SipAgentsGrid router={SipAgentRouter} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.SipAgentConfigs}>
				<SipAgentConfigsGrid router={SipAgentConfigRouter} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateSipAgent}>
				<SipAgentEditor
					createMode
					onSuccess={() => setActiveTab(tabs.SipAgents.header)}
					onCancel={() => setActiveTab(tabs.SipAgents.header)}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.CreateSipAgentConfigs}>
				<SipAgentConfigEditor
					createMode
					onSuccess={() => setActiveTab(tabs.SipAgentConfigs.header)}
					onCancel={() => setActiveTab(tabs.SipAgentConfigs.header)}
				/>
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

SipAgentIndexContainer.displayName = 'SipAgentIndexContainer';

SipAgentRouter.setIndexRtUiFunctionalComponent(SipAgentIndexContainer, {
	groupName: 'Operations',
	orderPriority: 1
});
