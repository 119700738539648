import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	BandwidthResourceCreateRequest,
	Integrations,
	NusoResourceCreateRequest,
	ResourceProfileResponse,
	ResourceType
} from 'RtModels';
import { IntegrationSelect } from 'RtUi/app/rtCommon/Integrations/controls/IntegrationSelect';
import {
	IIntegrationOption,
	IntegrationResource
} from 'RtUi/app/rtCommon/Integrations/resources/IntegrationResource';
import {
	ExtraParamTypes,
	IIntegrationConfig,
	IIntegrationField
} from 'RtUi/app/rtCommon/Integrations/resources/Integrations';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RadioFormControl } from 'RtUi/components/form/RadioFormControl';
import { UrlInputFormControl } from 'RtUi/components/form/UrlInputControl';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { PasswordFormControl } from 'RtUi/components/form/PasswordFormControl';
import { ResourceResource } from 'RtUi/app/rtCommon/Resources/lib/resources/ResourceResource';

const USER_FIELD_INTEGRATION_IDS = [
	4, 6, 8, 11, 12, 14, 15, 16, 17, 18, 19, 23, 25, 28, 33, 34, 35, 43, 44
];
const PASSWORD_FIELD_INTEGRATION_IDS = [
	4, 6, 8, 11, 12, 14, 15, 16, 17, 18, 19, 23, 25, 28, 32, 33, 34, 35, 37, 38,
	43, 44
];
const RES_REF_1_INTEGRATION_IDS = [
	1, 2, 3, 5, 7, 9, 10, 13, 15, 20, 32, 35, 36, 44, 49, 50
];
const RES_REF_2_INTEGRATION_IDS = [32, 35];

const RES_REF_1_FIELD_NAME_MAP = new Map<number, string>([
	[1, 'Token'],
	[2, 'Token'],
	[3, 'Token'],
	[5, 'Key'],
	[7, 'Token'],
	[9, 'API Key'],
	[10, 'Token'],
	[13, 'Token'],
	[15, 'Account'],
	[20, 'Key'],
	[32, 'Cert Path'],
	[35, 'API Username'],
	[36, 'API Key'],
	[44, 'API Key'],
	[49, 'API Key'],
	[50, 'API Key']
]);

const RES_REF_2_FIELD_NAME_MAP = new Map<number, string>([
	[32, 'Cert Key Path'],
	[35, 'API Key']
]);

const DEFAULT_PATH_MAP = new Map<number, string>([
	[Integrations.AniNetworks, 'ani-proxy.routetrust.com'],
	[Integrations.Avoxi, 'genius.avoxi.com/api/v2'],
	//cSpell:disable-next-line
	[Integrations.Bandwidth, 'dashboard.bandwidth.com/api/v1'],
	[Integrations.BlacklistAlliance, 'api.blacklistalliance.com'],
	[Integrations.Blitz, 'did-blitz-proxy.routetrust.com'],
	//cSpell:disable-next-line
	[Integrations.Call48, 'apicontrol.call48.com/api/v4'],
	//cSpell:disable-next-line
	[Integrations.DidWW, 'api.didww.com/v3'],
	//cSpell:disable-next-line
	[Integrations.Endstream, 'api.1pcom.net/ws2/DIDManagement.asmx?wsdl'],
	[Integrations.Flowroute, 'api.flowroute.com'],
	//cSpell:disable-next-line
	[Integrations.Fractel, 'api.fonestorm.com/v2'],
	[Integrations.Inteliquent, 'services.inteliquent.com'],
	[Integrations.Layered, 'api.layered.com'],
	//cSpell:disable-next-line
	[Integrations.Lingo, 'ftp.impacttele.com'],
	[Integrations.LumenApi, 'api.lumen.com/Voice/v1'],
	[
		Integrations.MyHealthAngel,
		'us-central1-mha-dashboard.cloudfunctions.net/ringba_data_append'
	],
	//cSpell:disable-next-line
	[Integrations.Nuso, 'cpaas.brightlink.com/api'],
	[
		Integrations.Peerless,
		//cSpell:disable-next-line
		'8181:animate.peerlessnetwork.com/public/APIService?wsdl'
	],
	//cSpell:disable-next-line
	[Integrations.QuestBlue, 'api2.questblue.com'],
	//cSpell:disable-next-line
	[Integrations.TeleApi, 'apiv1.teleapi.net'],
	[Integrations.Telnyx, 'api.telnyx.com'],
	[Integrations.Thinq, 'api.thinq.com'],
	//cSpell:disable-next-line
	[Integrations.ThreeEightTwo, 'secure1.382com.com/peer/peerxml.cgi'],
	//cSpell:disable-next-line
	[Integrations.ThreeThree, 'www.theweeky.com/fetchPhones'],
	[Integrations.Twilio, ' '],
	[Integrations.V1Voip, '3443:portal.v1voip.com/api/v3'],
	[Integrations.Verizon, 'api-secure-uat.verizon.com/v2'],
	[Integrations.Vitcom, 'customer.vitcom.net/api/did'],
	[Integrations.Vitelity, 'did-vitelity-proxy.routetrust.com'],
	[
		Integrations.VoipInnovations,
		//cSpell:disable-next-line
		'backoffice.voipinnovations.com/Services/APIService.asmx?wsdl'
	],
	[Integrations.Voxbone, 'api.voxbone.com'],
	[Integrations.West, 'api.west-ts.com']
	// If not found, set to empty
]);

const DEFAULT_PROTOCOL_MAP = new Map<number, string>([
	[Integrations.Lingo, 'ftp://']
	// If not found, set to https
]);

interface IResourceEditorProps {
	createMode?: boolean;
	editMode?: ResourceProfileResponse;
	excludeResourceTypes?: ResourceType[];
	resourceType?: ResourceType;
	onCreate?: (profile: ResourceProfileResponse) => void;
	onUpdate?: (profile: ResourceProfileResponse) => void;
	onCopy?: (profile: ResourceProfileResponse) => void;
}

interface IResourceEditorState {
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
	wizardStep: number;
	editModeSection?: 'commonParams' | 'extraParams';
	integration?: IIntegrationOption;
	currentConfig?: IIntegrationConfig;
	label: string;
	isActive: number;
	isDefault: number;
	url: string;
	user: string;
	password: string;
	extraParams: ExtraParamTypes;
	isCopying: boolean;
	costPerTn?: number;
	weight?: number;
	resRef1?: string;
	resRef2?: string;
}

export class CreateConnectionForm extends React.Component<
	IResourceEditorProps,
	IResourceEditorState
> {
	public resourceResource = new ResourceResource();
	public integrationResource = new IntegrationResource();
	public state: IResourceEditorState = {
		displayMode: true,
		isSubmitting: false,
		error: undefined,
		wizardStep: 1,
		label: '',
		isActive: 1,
		isDefault: 0,
		url: '',
		user: '',
		password: '',
		extraParams: {},
		isCopying: false,
		resRef1: '',
		resRef2: ''
	};

	public componentDidMount() {
		this.initIfInEditMode();
		this.initIfInCreateMode();
	}

	/**
	 * If in createMode, displayMode should be false
	 */
	public initIfInCreateMode() {
		const { createMode } = this.props;

		if (!createMode) {
			return;
		}

		this.setState({ displayMode: false });
	}

	/**
	 * If in editMode, use the profile to update form state
	 */
	public initIfInEditMode() {
		const { editMode } = this.props;

		if (!editMode) {
			return;
		}

		const isActive = editMode.isActive;
		const isDefault = editMode.isDefault;
		const label = editMode.label;
		const user = editMode.user ?? '';
		const password = editMode.password ?? '';
		const host = editMode.host ?? '';
		const path = editMode.path ?? '';
		const resRef1 = editMode.resRef1 ?? '';
		const resRef2 = editMode.resRef2 ?? '';
		const protocol = editMode.protocol ?? '';
		const port = typeof editMode.port === 'number' ? `:${editMode.port}` : '';
		const url = `${protocol}//${host}${port}${path}`;
		const integration = { label: '', value: editMode.integrationId };
		const costPerTn = editMode.costPerTn;
		const weight = editMode.weight;

		this.setState({
			isActive,
			isDefault,
			user,
			password,
			url,
			label,
			integration,
			costPerTn,
			weight,
			resRef1,
			resRef2
		});
	}

	/**
	 * When a user updates the integration AND in createMode, update form with defaults from
	 * new Integration Config
	 * @param integration
	 */
	public updateIntegration(integration: IIntegrationOption) {
		this.setState({ integration });
		this.setState({
			url: `${DEFAULT_PROTOCOL_MAP.get(integration.value) || 'https://'}${
				DEFAULT_PATH_MAP.get(integration.value) || ''
			}`
		});
	}

	/**
	 * Checks with currentConfig a custom label name exists.
	 * @param integrationKey
	 * @param defaultLabel
	 */
	public getFormLabel(
		integrationKey: string,
		defaultLabel: string = integrationKey
	): string {
		const { currentConfig } = this.state;

		if (!currentConfig) {
			return defaultLabel;
		}

		const field: IIntegrationField | undefined =
			currentConfig.params[integrationKey];

		if (typeof field === 'object' && !Array.isArray(field) && field.label) {
			return field.label;
		}

		return defaultLabel;
	}

	/**
	 * On form submit, create or update Resource
	 * @param evt
	 */
	public async onSubmit(evt?: React.FormEvent<HTMLFormElement>) {
		if (evt) {
			evt.preventDefault();
		}
		const connectionResource = new ResourceResource();
		const {
			integration,
			user,
			password,
			isActive,
			isDefault,
			label,
			resRef1,
			resRef2,
			url
		} = this.state;
		const { editMode } = this.props;

		const integrationId = integration?.value || editMode?.integrationId;

		const submitObject: any = {
			data: {
				integrationId: integrationId as number,
				isActive: isActive,
				label: label,
				isDefault: isDefault
			}
		};

		if (user) {
			(submitObject.data as BandwidthResourceCreateRequest).user = user;
		}

		if (password) {
			(submitObject.data as BandwidthResourceCreateRequest).password = password;
		}

		if (resRef1) {
			(submitObject.data as NusoResourceCreateRequest).resRef1 = resRef1;
		}

		if (resRef2) {
			(submitObject.data as NusoResourceCreateRequest).resRef2 = resRef2;
		}

		if (url) {
			const integrationUrl = new URL(url);
			const { protocol, hostname: host, pathname: path } = integrationUrl;
			const port = integrationUrl.port
				? Number(integrationUrl.port)
				: undefined;
			submitObject.data.protocol = protocol;
			submitObject.data.host = host;
			submitObject.data.path = path;
			submitObject.data.port = port;
		}

		submitObject.data.resourceTypeId = 3;
		if (window.location.pathname.includes('rtCarrierConnect')) {
			submitObject.data.resourceTypeId = 4;
		}

		this.setState({ isSubmitting: true, error: undefined });

		try {
			if (!editMode) {
				const response = await connectionResource.create(submitObject);
				localStorage.setItem('activeTab', 'Connections');
				const activePage = window.location.pathname.includes('rtDid')
					? 'resource'
					: 'resources';
				window.location.href = `${activePage}/${response.resourceId}?tab=Profile`;
			} else {
				await connectionResource.update(editMode.resourceId, submitObject);
				this.onDisplayModeToggle(true, 'commonParams');
			}
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}
	}

	public onCancel() {
		this.initIfInEditMode();
	}

	public renderUserField(integrationId: number) {
		return USER_FIELD_INTEGRATION_IDS.includes(integrationId);
	}

	public renderPasswordField(integrationId: number) {
		return PASSWORD_FIELD_INTEGRATION_IDS.includes(integrationId);
	}

	public renderResRef1Field(integrationId: number) {
		return RES_REF_1_INTEGRATION_IDS.includes(integrationId);
	}

	public renderResRef2Field(integrationId: number) {
		return RES_REF_2_INTEGRATION_IDS.includes(integrationId);
	}

	public getResourceOpenApiSchema() {
		return '';
	}

	public onDisplayModeToggle(
		newDisplayMode: boolean,
		editModeSection: IResourceEditorState['editModeSection']
	) {
		if (newDisplayMode) {
			editModeSection = undefined;
		}

		this.setState({ displayMode: newDisplayMode, editModeSection });
	}

	public isDisplayMode(
		editModeSection: IResourceEditorState['editModeSection']
	) {
		if (this.state.displayMode) {
			return true;
		}

		if (typeof this.state.editModeSection === 'undefined') {
			return false;
		}

		return this.state.editModeSection !== editModeSection;
	}

	public render() {
		const displayMode = this.isDisplayMode('commonParams');

		return (
			<>
				<Row>
					<Col lg={6}>
						<RtUiForm
							displayMode={this.isDisplayMode('commonParams')}
							isSubmitting={this.state.isSubmitting}
							error={this.state.error}
							onChange={(displayMode) =>
								this.onDisplayModeToggle(displayMode, 'commonParams')
							}
							onCancel={() => this.onCancel()}
							onSubmit={(evt) => this.onSubmit(evt)}
							createMode={!this.props.editMode}
						>
							<Row>
								<>
									<InputFormControl
										label="label"
										required
										displayMode={displayMode}
										onChange={(label) => this.setState({ label })}
										value={this.state.label}
									/>
									{typeof this.props.editMode === 'undefined' && (
										<IntegrationSelect
											required
											displayMode={displayMode}
											onChange={(integration) =>
												this.updateIntegration(integration)
											}
											value={this.state.integration}
										/>
									)}
									<UrlInputFormControl
										required={
											this.state.integration?.value &&
											this.state.integration.value === 28
										}
										displayMode={displayMode}
										onChange={(url) => this.setState({ url })}
										value={this.state.url}
									/>
									<IsActiveRadioFormControl
										required
										hideBothOption
										displayMode={displayMode}
										onChange={(isActive) => this.setState({ isActive })}
										value={this.state.isActive}
									/>
									<RadioFormControl<number>
										label="Default"
										required
										displayMode={displayMode}
										onChange={(isDefault) => this.setState({ isDefault })}
										value={this.state.isDefault}
										options={[
											{ value: 1, label: 'Yes' },
											{ value: 0, label: 'No' }
										]}
									/>
									{this.state.integration?.value &&
										this.renderUserField(this.state.integration.value) && (
											<InputFormControl
												required
												displayMode={displayMode}
												label={this.getFormLabel('user')}
												onChange={(user) => this.setState({ user })}
												value={this.state.user ?? ''}
											/>
										)}
									{this.state.integration?.value &&
										this.renderPasswordField(this.state.integration.value) && (
											<PasswordFormControl
												required
												displayMode={displayMode}
												label={this.getFormLabel('password')}
												onChange={(password) => this.setState({ password })}
												value={this.state.password ?? ''}
											/>
										)}
									{this.state.integration?.value &&
										this.renderResRef1Field(this.state.integration.value) && (
											<InputFormControl
												required
												displayMode={displayMode}
												label={
													RES_REF_1_FIELD_NAME_MAP.get(
														this.state.integration.value
													) ?? 'Customer External Reference 1'
												}
												onChange={(resRef1) => this.setState({ resRef1 })}
												value={this.state.resRef1 ?? ''}
											/>
										)}
									{this.state.integration?.value &&
										this.renderResRef2Field(this.state.integration.value) && (
											<InputFormControl
												required
												displayMode={displayMode}
												label={
													RES_REF_2_FIELD_NAME_MAP.get(
														this.state.integration.value
													) ?? 'Customer External Reference 2'
												}
												onChange={(resRef2) => this.setState({ resRef2 })}
												value={this.state.resRef2 ?? ''}
											/>
										)}
								</>
							</Row>
						</RtUiForm>
					</Col>
				</Row>
			</>
		);
	}
}
