import { RocIndexRequest, RocIndexResponse } from 'RtModels';
import {
	fetchRocRequests,
	useGetRocRequests
} from 'RtUi/app/rt800/RespOrgChange/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getRocRequestsColumns } from 'RtUi/components/data/DataGrid/configurations/rt800/rocRequests';
import { useGetFullResults } from 'RtUi/components/hooks/useGetFullResults';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { RocRequestRouter } from '../../RocRequest.router';

interface IRocRequestsGridProps {
	resourceParams?: RocIndexRequest;
	autoFocusFilter?: boolean;
	requestData?: Promise<RocIndexResponse[]>;
}

export const RocRequestsGrid = ({
	resourceParams,
	requestData
}: IRocRequestsGridProps) => {
	const { data, isFetching: isLoading } = useGetRocRequests(
		resourceParams,
		requestData === undefined
	);

	const { data: results } = useQuery<RocIndexResponse[] | undefined, Error>(
		[`RocRequestsGridData`, data, requestData],
		() => requestData ?? data?.data
	);

	const fullResultsReq = useGetFullResults(
		['exportRocRequests', resourceParams],
		resourceParams ?? {},
		(params) => fetchRocRequests(params)
	);
	const columns = useMemo(() => getRocRequestsColumns(), []);

	return (
		<DataGrid<RocIndexResponse>
			data={results}
			router={RocRequestRouter}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			enableExternalExport
			getExternalExportData={fullResultsReq}
			pageName={'rt800_RocRequests'}
		/>
	);
};
