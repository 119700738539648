/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FlowStepUpdateRequestStep = {
    properties: {
        label: {
            type: 'string',
            isRequired: true,
        },
        summary: {
            type: 'string',
            isRequired: true,
        },
        sequence: {
            type: 'number',
            isRequired: true,
        },
        integrationTaskTypeId: {
            type: 'IntegrationTaskType',
            isRequired: true,
        },
        continueOnErrors: {
            type: 'number',
            isRequired: true,
        },
        omitErrors: {
            type: 'number',
            isRequired: true,
        },
        resources: {
            type: 'Array',
            isRequired: true,
        },
    },
};