import { Chart, ChartConfiguration } from 'chart.js';
import clsx from 'clsx';
import { forwardRef, HTMLAttributes, Ref, useEffect, useRef } from 'react';

interface IChartWrapperProps extends HTMLAttributes<HTMLDivElement> {
	config: ChartConfiguration;
	key?: string;
	ref?: Ref<HTMLDivElement>;
}

export const ChartWrapper = forwardRef<HTMLDivElement, IChartWrapperProps>(
	(props, ref) => {
		const chartRef = useRef<HTMLCanvasElement>(null);
		const chartInstanceRef = useRef<Chart | null>();
		const { config, ...rest } = props;

		useEffect(() => {
			if (chartRef.current) {
				chartInstanceRef.current = new Chart(chartRef.current, config);
			}

			return () => {
				if (chartInstanceRef.current) {
					chartInstanceRef.current.destroy();
					chartInstanceRef.current = null;
				}
			};
		});

		return (
			<div
				{...rest}
				ref={ref}
				className={clsx('chart-wrapper', props.className)}
			>
				<canvas ref={chartRef} />
			</div>
		);
	}
);
