import { SwitchIndexResponse } from 'RtModels';
import { SwitchesResource } from 'RtUi/app/AccountManagement/Switches/lib/resources/SwitchesResource';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	ResourceDataGridColumn,
	ResourceDataGridColumnConfigurationInstance
} from 'RtUi/components/data/DataGrid/columns/ResourceDataGridColumn';
import { ResourceCacheMap } from 'RtUi/utils/cache/resourceMap/ResourceCacheMap';

interface SwitchDataGridColumnConfiguration<T>
	extends ResourceDataGridColumnConfigurationInstance<SwitchIndexResponse, T> {}

export const SwitchDataGridCache = new ResourceCacheMap<SwitchIndexResponse>(
	new SwitchesResource(),
	'switchId',
	'label'
);

export const SwitchDataGridColumn = <T = any,>(
	config: SwitchDataGridColumnConfiguration<T>
): DataGridColumn<T> => {
	return ResourceDataGridColumn({
		resourceCacheMap: SwitchDataGridCache,
		...config
	});
};
