import { RtxSipApiRoutes } from 'RtExports/routes';
import {
	BlockedNumber,
	BlockedNumberBulkCreateResponse,
	BlockedNumberCreateRequest,
	BlockedNumberProfileRequest,
	BlockedNumberUpdateRequest
} from 'RtModels';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';

export class BlockNumberResource extends HttpResource<
	BlockedNumber,
	BlockedNumber
> {
	constructor() {
		super(RtxSipApiRoutes.BlockedNumbers);
	}

	public create(createRequest: BlockedNumberCreateRequest) {
		const body = JSON.stringify(createRequest);

		return this.fetchWithRoute<BlockedNumber>(
			RtxSipApiRoutes.BlockedNumbers.Create,
			{
				body
			}
		);
	}

	public bulkCreate(createRequest: BlockedNumberCreateRequest) {
		const body = JSON.stringify(createRequest);

		return this.fetchWithRoute<BlockedNumberBulkCreateResponse>(
			RtxSipApiRoutes.BlockedNumbersBulk.Create,
			{
				body
			}
		);
	}

	public update(
		blockedNumberId: number,
		blockedNumber: BlockedNumberUpdateRequest
	) {
		const urlParams: BlockedNumberProfileRequest = { blockedNumberId };
		const body = JSON.stringify(blockedNumber);

		return this.fetchWithRoute<BlockedNumber>(
			RtxSipApiRoutes.BlockedNumbers.Update,
			{
				body,
				urlParams
			}
		);
	}

	public delete(blockedNumberId: number) {
		const urlParams: BlockedNumberProfileRequest = { blockedNumberId };
		return this.fetchWithRoute(RtxSipApiRoutes.BlockedNumbers.Delete, {
			urlParams
		});
	}
}
