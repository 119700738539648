import * as moment from 'moment-timezone';

export type TCacheStrategySearchCriteria<T> = {
	keyToSearchAgainst?: keyof T;
	exact?: string;
	like?: string;
	lessThan?: number;
	moreThan?: number;
};

/**
 * CacheStrategy's goal is to layout a reusable caching mechanism
 * across the platform.
 *
 * Possible concrete-classes can use localStorage, IndexedDb, etc.
 */
export abstract class CacheStrategy<T> {
	protected clearCacheTimeoutId: number = -1;
	protected isStale = true;
	protected storingPromise: Promise<T[]> | undefined;

	/**
	 * TTL will set a timeout to invalidate cache.
	 * If a cacheName is given, the TTL will be logged in localStorage
	 * for persistent use
	 * @param timeToLive
	 * @param cacheName
	 */
	constructor(
		protected timeToLive?: moment.Duration,
		protected cacheName?: string
	) {
		this.invalidateWithTtl();
	}

	/**
	 * Stores cache for usage in search() and getAll()
	 * @param records
	 */
	public async storeCache(records: T[] | Promise<T[]>): Promise<boolean> {
		let storeSuccess = false;

		if ('then' in records) {
			//We are assuming the promise will succeed and setting the stale to false
			this.isStale = false;
			//this.storingPromise is used in getAll() method. This makes sure
			//the cache is not perceived as stale while storing
			this.storingPromise = records;

			try {
				records = await this.storingPromise;
			} catch {
				//we tried
			}

			this.storingPromise = undefined;
		}

		if (Array.isArray(records)) {
			storeSuccess = await this.storeCacheInternal(records);
		}

		//If store does not succeed, then fail-over
		if (!storeSuccess) {
			this.isStale = true;

			return storeSuccess;
		}

		this.isStale = false;

		this.invalidateWithTtl(true);

		return storeSuccess;
	}

	/**
	 * Get all records. If records are currently being stored, then a copy
	 * of those will be returned when available.
	 */
	public getAll() {
		if (this.storingPromise) {
			return this.storingPromise;
		}

		return this.getAllInternal();
	}

	/**
	 * Clears the whole cache!
	 */
	public async invalidateCache() {
		//Invalidation begins
		this.isStale = true;

		if (this.storingPromise) {
			try {
				await this.storingPromise;
			} catch {
				//store fail, but carry on
			}
		}

		const localStorageTtlKey = this.getLocalStorageKeyForTtl();

		//Remove localStorageKey if exists
		localStorage.removeItem(localStorageTtlKey);

		if (this.clearCacheTimeoutId >= 0) {
			window.clearTimeout(this.clearCacheTimeoutId);
		}

		const invalidateSuccess = await this.invalidateCacheInternal();

		return invalidateSuccess;
	}

	/**
	 * Search through records
	 * @param criteria
	 */
	public async search(
		criteria: Array<TCacheStrategySearchCriteria<T>>
	): Promise<T[]> {
		const records = await this.getAll();

		return this.searchInternal(records, criteria);
	}

	/**
	 * Returns if the cache is not usable
	 */
	public isCacheStale() {
		return this.isStale;
	}

	/**
	 * Invalidate
	 */
	protected async invalidateWithTtl(force = false) {
		const localStorageTtlKey = this.getLocalStorageKeyForTtl();
		let ttl = this.timeToLive;
		const hasLocalStorageCache =
			this.cacheName && localStorage.getItem(localStorageTtlKey) !== null;
		const removeFromLocalStorageFn = () =>
			localStorage.removeItem(localStorageTtlKey);

		if (force) {
			removeFromLocalStorageFn();
		} else if (hasLocalStorageCache) {
			const ttlStr = localStorage.getItem(localStorageTtlKey);
			const ttlInStorage = ttlStr ? Number(ttlStr) : undefined;

			if (typeof ttlInStorage === 'number' && !isNaN(ttlInStorage)) {
				const now = moment();
				const ttlMoment = moment(ttlInStorage);

				if (now.isBefore(ttlMoment)) {
					const differenceInMs = ttlMoment.diff(now);
					ttl = moment.duration(differenceInMs, 'milliseconds');
					this.isStale = false;
				} else {
					ttl = undefined;
					this.invalidateCache();
				}
			} else {
				removeFromLocalStorageFn();
			}
		}

		if (this.clearCacheTimeoutId >= 0) {
			window.clearTimeout(this.clearCacheTimeoutId);
		}

		if (ttl) {
			const now = moment();
			const ttlMoment = now.clone().add(ttl);
			const ttlInUnixMilliseconds = ttlMoment.valueOf();
			const deltaToTtlInMilliseconds = ttlMoment.diff(now);

			if (this.cacheName) {
				localStorage.setItem(localStorageTtlKey, String(ttlInUnixMilliseconds));
			}

			const diffDuration = moment.duration(
				deltaToTtlInMilliseconds,
				'milliseconds'
			);

			//Only set timeout if it is within a day,
			//otherwise large numbers immediately set it off
			if (diffDuration.asDays() <= 1) {
				this.clearCacheTimeoutId = window.setTimeout(
					() => this.invalidateCache(),
					deltaToTtlInMilliseconds
				);
			}
		}
	}

	/**
	 * Standard search given records and criteria.
	 * @param records
	 * @param criteria
	 */
	protected standardSearch(
		records: T[],
		criteria: Array<TCacheStrategySearchCriteria<T>>
	): T[] {
		if (criteria.length <= 0 || records.length <= 0) {
			return records;
		}

		return records.filter((record) => {
			for (const singleCriteria of criteria) {
				const criteriaKeys = singleCriteria.keyToSearchAgainst
					? [singleCriteria.keyToSearchAgainst]
					: (Object.keys(record as object) as Array<keyof T>);

				for (const criteriaKey of criteriaKeys) {
					const value = record[criteriaKey];

					if (typeof value === 'undefined' || value === null) {
						continue;
					}

					if (singleCriteria.exact) {
						const hasExactMatches = String(value) === singleCriteria.exact;

						if (hasExactMatches) {
							return true;
						}
					}

					if (singleCriteria.like) {
						const valueToLower = String(value).toLowerCase();
						const likeValuesToLower = singleCriteria.like.toLowerCase();

						const isLikeMatch = valueToLower.includes(likeValuesToLower);

						if (isLikeMatch) {
							return true;
						}
					}

					if (typeof singleCriteria.lessThan === 'number') {
						const isLessThan = Number(value) < singleCriteria.lessThan;

						if (isLessThan) {
							return true;
						}
					}

					if (typeof singleCriteria.moreThan === 'number') {
						const isMoreThan = Number(value) > singleCriteria.moreThan;

						if (isMoreThan) {
							return true;
						}
					}
				}
			}

			return false;
		});
	}

	protected abstract invalidateCacheInternal(): Promise<boolean>;
	protected abstract searchInternal(
		records: T[],
		criteria: Array<TCacheStrategySearchCriteria<T>>
	): Promise<T[]>;
	protected abstract getAllInternal(): Promise<T[]>;
	protected abstract storeCacheInternal(records: T[]): Promise<boolean>;

	/**
	 * Get local storage used for TTL
	 */
	private getLocalStorageKeyForTtl() {
		return `${this.constructor.name}-${this.cacheName}`;
	}
}
