import { DataGridTableInstance } from 'RtUi/components/data/DataGrid/types';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { useMemo } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ICprNumbersCopyButtonProps<T = any> {
	table: DataGridTableInstance<T>;
	numberKey: keyof T;
}

export const TollFreeNumbersCopyButton = <T = any,>({
	table,
	numberKey: key
}: ICprNumbersCopyButtonProps<T>) => {
	const fileUtils = useMemo(() => new FileUtils(), []);

	const clickHandler = async () => {
		const rows = table
			.getFilteredRowModel()
			.rows.map((r) => r.original[key] as string)
			.join('\n');

		fileUtils.copyTextToClipboard(rows);
	};

	return (
		<OverlayTrigger
			overlay={(props) => (
				<Tooltip id="copyNumbers-tooltip" {...props}>
					Copy Toll-Free Numbers to Clipboard.
				</Tooltip>
			)}
		>
			{({ ref, ...triggerHandler }) => (
				<Button
					ref={ref}
					{...triggerHandler}
					variant="white"
					id="copyNumbers"
					onClick={clickHandler}
				>
					<span className="fa-stack">
						<i className="fas fa-clipboard fa-lg" />
						<i
							style={{ fontSize: 8, paddingTop: 2 }}
							className="fas fa-hashtag text-white fa-stack-1x"
						/>
					</span>
				</Button>
			)}
		</OverlayTrigger>
	);
};
