import { MetricsTotalsByResourceResponse } from 'RtModels';
import { IMetricsTotalsByResource } from 'RtUi/app/rtDid/Dashboard/lib/http/DidMetricHttp';
import { DidMetricTotalsByResourceResource } from 'RtUi/app/rtDid/Dashboard/lib/resources/DidMetricTotalsByResourceResource';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getMetricsByResourceColumns } from 'RtUi/components/data/DataGrid/configurations/rtDid/dashboard';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

interface IMetricTotalsByVendorGridProps {
	resource?: DidMetricTotalsByResourceResource;
	onRowClick?: (row: IMetricsTotalsByResource) => void;
	resourceParams?: any;
	autoFocusFilter?: boolean;
}

export const MetricTotalsByVendorGrid = ({
	resource,
	onRowClick
}: IMetricTotalsByVendorGridProps) => {
	const { data, isFetching: isLoading } = useQuery(
		['useGetMetricsByResource', resource],
		() => resource?.getAll()
	);

	const columns = useMemo(() => getMetricsByResourceColumns(), []);

	return (
		<DataGrid<MetricsTotalsByResourceResponse>
			data={data}
			columns={columns}
			loading={isLoading}
			onRowClick={onRowClick}
			pageName={'rtDid_dashboardByVendor'}
		/>
	);
};
