import { LrnProfileResponse, RoutingCacheTypes } from 'RtModels';
import { CallSimulatorAniLrnGrid } from 'RtUi/app/rt800/CallSimulator/lib/grid/CallSimulatorAniLrnGrid';
import { CallSimulatorGrid } from 'RtUi/app/rt800/CallSimulator/lib/grid/CallSimulatorGrid';
import { ICallSimulatorParams } from 'RtUi/app/rt800/CallSimulator/lib/services';
import { CprLergHttp } from 'RtUi/app/rt800/Cprs/lib/Http/CprLergHttp';
import { RoutingCacheSelect } from 'RtUi/app/rt800/Cprs/lib/controls/RoutingCacheSelect';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { useUrlSearchParams } from 'RtUi/components/hooks/useUrlSearchParams';
import { Aside } from 'RtUi/components/ui/Aside';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { StandardLayout } from 'RtUi/components/ui/StandardLayout';
import { RtError } from 'RtUi/utils/errors/RtError';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2';
import { FC, useMemo, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useMount } from 'react-use';
import { CallSimulatorRouter } from './CallSimulator.router';

interface ILrnProfiles {
	ani: string;
	profiles: LrnProfileResponse[];
}

export const CallSimulatorContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const [simulateBy, setSimulateBy] = useState<RoutingCacheTypes>(
		RoutingCacheTypes.TollFree
	);
	const [simulateValue, setSimulateValue] = useState<string>('');
	const [ani, setAni] = useState<string>('');
	const [simulatorParams, setSimulatorParams] = useState<
		ICallSimulatorParams[]
	>([]);
	const [lrnProfiles, setLrnProfiles] = useState<ILrnProfiles>();
	const [isLergReady, setIsLergReady] = useState<boolean>(false);
	const [error, setError] = useState<any>();

	const { getParam, setUrlSearchParams } = useUrlSearchParams();

	const valueParam = useMemo(() => getParam('simulateValue') ?? '', [getParam]);
	const aniParam = useMemo(() => getParam('ani'), [getParam]);
	const typeParam = useMemo(() => getParam('type') ?? '', [getParam]);

	useMount(async () => {
		const simulateType = typeParam ? Number(typeParam) : 2;
		const simulateValue =
			simulateType === RoutingCacheTypes.TollFree
				? TollFreeNumbersUtils.tollFreeNumberToReadableString(valueParam)
				: valueParam;
		const aniValue = aniParam ?? '';

		setSimulateValue(simulateValue);
		setAni(aniValue);
		setSimulateBy(simulateType);

		await initLergDictionary();

		if (simulateType && simulateValue && aniValue) {
			setSimulatorParamsState(simulateType, aniValue, simulateValue);
		}
	});

	const setSimulatorParamsState = (
		type: RoutingCacheTypes,
		ani: string,
		simulateValue: string
	) => {
		const newSimulatorParams: ICallSimulatorParams = {
			type,
			ani,
			simulateValue
		};

		setUrlSearchParams(newSimulatorParams);
		setSimulatorParams([newSimulatorParams, ...simulatorParams]);
	};

	const initLergDictionary = async () => {
		const cprLergHttpClient = new CprLergHttp();

		await cprLergHttpClient.loadCprLerg();

		setIsLergReady(true);
	};

	const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
		e?.preventDefault();
		setError(undefined);

		if (!CprLergCache.DoesAniNumberExist(ani)) {
			setError(new RtError({ message: 'Incorrect "ANI"' }));
			return;
		}

		setSimulatorParamsState(simulateBy, ani, simulateValue);
	};

	return (
		<StandardLayout router={CallSimulatorRouter}>
			<RtUiForm
				noValidate
				disablePadding
				hideButtons
				className="mb-5"
				error={error}
				onSubmit={onSubmit}
			>
				<Row>
					<Col lg={12}>
						<Alert variant="info-outline" className="mb-3">
							Select a Toll-free Number or Template to use for simulation. Enter
							an ANI (Caller-ID), to see the Carrier Identification Code (CIC)
							that should be used.
						</Alert>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<RoutingCacheSelect
							columnMode
							required
							label="Simulate With"
							routingCacheType={simulateBy}
							routingCacheValue={simulateValue}
							hiddenTypes={[RoutingCacheTypes.LcrGenerator]}
							onChange={(newSimulateBy, newSimulateValue) => {
								setSimulateBy(newSimulateBy);
								setSimulateValue(newSimulateValue);
							}}
						/>
					</Col>
					<Col lg={3}>
						<TollFreeNumberFormControl
							label="ANI"
							required
							alwaysShowMask
							onlyNumbers
							onChange={(ani) => {
								setAni(ani);
							}}
							value={ani}
						/>
					</Col>
					<Col lg={3} className="d-flex">
						<Form.Group className="align-self-end mb-3">
							<Button type="submit" variant="submit" disabled={!isLergReady}>
								<i className="fas fa-fw fa-play-circle fa-sm" />
								<span>&nbsp;Simulate</span>
							</Button>
						</Form.Group>
					</Col>
				</Row>
			</RtUiForm>
			{simulatorParams.map((simulatorParam, index) => (
				<section key={index} className="mb-5">
					<CallSimulatorGrid
						simulatorParam={simulatorParam}
						onClickSimulator={(ani, profiles) =>
							setLrnProfiles({
								ani,
								profiles
							})
						}
					/>
				</section>
			))}
			{lrnProfiles && (
				<Aside
					disabledBody
					fullscreen
					isOpen={Boolean(lrnProfiles)}
					onClickOutside={() => {
						setLrnProfiles(undefined);
					}}
				>
					<Aside.Header
						header={`ANI/LRN Info for ${TollFreeNumbersUtils.tollFreeNumberToReadableString(
							lrnProfiles.ani
						)}`}
						onClose={() => {
							setLrnProfiles(undefined);
						}}
					/>
					<section>
						<CallSimulatorAniLrnGrid data={lrnProfiles.profiles} />
					</section>
				</Aside>
			)}
		</StandardLayout>
	);
};

CallSimulatorRouter.setIndexRtUiFunctionalComponent(CallSimulatorContainer, {
	description: 'Simulate Calls'
});
