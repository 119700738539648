import {
	LcrScenarioIndexResponse,
	TemplateLcrScenarioIndexRequest
} from 'RtModels';
import { useGetLcrScenarios } from 'RtUi/app/rtLco/Scenarios/lib/services';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getLcrScenariosColumns } from 'RtUi/components/data/DataGrid/configurations/rtLco/lcrScenarios';
import { useMemo } from 'react';
import { LcrScenarioRouter } from '../../LcrScenario.router';

interface ILcrScenariosGridProps {
	resourceParams?: TemplateLcrScenarioIndexRequest;
	autoFocusFilter?: boolean;
}

export const LcrScenariosGrid = ({
	resourceParams
}: ILcrScenariosGridProps) => {
	const { data, isFetching: isLoading } = useGetLcrScenarios(resourceParams);
	const columns = useMemo(() => getLcrScenariosColumns(), []);

	return (
		<DataGrid<LcrScenarioIndexResponse>
			pageName="rtLco-Scenarios"
			loading={isLoading}
			data={data?.data}
			router={LcrScenarioRouter}
			totalRows={data?.totalCount}
			columns={columns}
		/>
	);
};
