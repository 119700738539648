import * as moment from 'moment-timezone';
import * as React from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import {
	MgiCprIndexResponse,
	RoutingCacheTypes,
	TemplateIndexResponse
} from 'RtModels';
import { TollFreeNumberFormControl } from 'RtUi/app/rt800/Numbers/lib/controls/TollFreeNumberFormControl';
import {
	INumberProfile,
	NumberResource
} from 'RtUi/app/rt800/Numbers/lib/resources/NumberResource';
import { TollFreeNumbersUtils } from 'RtUi/app/rt800/Numbers/lib/utils/TollFreeNumbersUtils';
import { CloneRoutingEffectiveTimestampSelect } from 'RtUi/app/rt800/Routing/lib/controls/CloneRoutingEffectiveSelect';
import { Cpr } from 'Somos/lib/SomosCpr/RtCprV2';
import { TemplateSelect } from '../../../Templates/lib/controls/TemplateSelect';
import { TemplateResource } from '../../../Templates/lib/resources/TemplateResource';

interface ISelectCprToCopyFromProps {
	cloneSource: RoutingCacheTypes;
	onCloneSourceChange: (cloneSource: RoutingCacheTypes) => void;
	onCprSelect: (mgiProfile: Cpr | undefined) => void;
	onLoadingChange?: (isLoading: boolean) => void;
	respOrgId?: string;
	required?: boolean;
}

interface ISelectCprToCopyFromState {
	//source
	sourceNumber: string;
	sourceCprs: MgiCprIndexResponse[];
	selectedCpr?: MgiCprIndexResponse;
	sourceTemplate?: TemplateIndexResponse;
	sourceTollFreeNumber?: INumberProfile;
	sourceDefaultTemplateName?: string;
	//form
	isLoadingSourceCprs: boolean;
	minEffectiveTs: moment.Moment;
	targetEffectiveTs: moment.Moment;
	targetEffectiveTsIsNow: boolean;
	isLoadingCpr: boolean;
}

export class SelectCprToCopyFrom extends React.Component<
	ISelectCprToCopyFromProps,
	ISelectCprToCopyFromState
> {
	public state: ISelectCprToCopyFromState = {
		sourceNumber: '',
		sourceCprs: [],
		isLoadingSourceCprs: false,
		targetEffectiveTs: moment(),
		targetEffectiveTsIsNow: true,
		minEffectiveTs: moment(),
		isLoadingCpr: false
	};

	public numberResource = new NumberResource();
	public templateResource = new TemplateResource();
	public targetRef: HTMLInputElement | null = null;

	public componentDidUpdate(prevProps: ISelectCprToCopyFromProps) {
		if (prevProps.cloneSource !== this.props.cloneSource) {
			this.updateCloneSource(this.props.cloneSource);
		}
	}

	public updateCloneSource(cloneSource: RoutingCacheTypes) {
		//Reset From controls
		const sourceNumber = '';
		const sourceTemplate = undefined;
		const sourceCprs: MgiCprIndexResponse[] = [];
		const selectedCpr = undefined;

		this.setState({
			sourceNumber,
			sourceTemplate,
			sourceCprs,
			selectedCpr
		});

		this.props.onCprSelect(undefined);
	}

	public async updateSourceNumber(sourceNumber: string) {
		const formattedTFN = TollFreeNumbersUtils.tollFreeNumberToReadableString(
			sourceNumber,
			false
		);
		const sourceCprs: MgiCprIndexResponse[] = [];
		const selectedCpr = undefined;

		this.setState({
			sourceNumber,
			sourceCprs,
			selectedCpr,
			sourceTollFreeNumber: undefined
		});

		if (!TollFreeNumbersUtils.isValid(sourceNumber)) {
			return;
		}

		this.setState({ isLoadingSourceCprs: true });

		try {
			const sourceTollFreeNumber = await this.numberResource.get(formattedTFN);

			if (!sourceTollFreeNumber) {
				return;
			}

			this.setState({ sourceTollFreeNumber });

			if (!sourceTollFreeNumber.templateName) {
				const sourceCprs = await this.numberResource
					.getCprResource(formattedTFN)
					.getAll();
				this.setState({ sourceCprs });
			}
		} finally {
			this.setState({ isLoadingSourceCprs: false });
		}
	}

	public updateSourceTemplate(sourceTemplate?: TemplateIndexResponse) {
		const sourceCprs: MgiCprIndexResponse[] = [];
		const selectedCpr = undefined;

		this.setState({ sourceTemplate, sourceCprs, selectedCpr });

		if (sourceTemplate) {
			this.setState({ isLoadingSourceCprs: true });

			this.templateResource
				.getCprResource(sourceTemplate.templateName)
				.getAll()
				.then((sourceCprs) => this.setState({ sourceCprs }))
				.finally(() => this.setState({ isLoadingSourceCprs: false }));
		}
	}

	public moveToSourceTemplateName(sourceDefaultTemplateName: string) {
		const sourceTollFreeNumber = undefined;

		this.setState({ sourceDefaultTemplateName, sourceTollFreeNumber });

		this.props.onCloneSourceChange(RoutingCacheTypes.Template);
	}

	public async onCprSelect(selectedCpr: MgiCprIndexResponse) {
		const { sourceTemplate, sourceTollFreeNumber } = this.state;
		const { onLoadingChange = () => ({}) } = this.props;
		const effectiveTs = selectedCpr.effectiveTs.toString();

		this.setState({ selectedCpr, isLoadingCpr: true });
		onLoadingChange(true);

		try {
			let mgiProfile: Cpr | undefined;

			if (sourceTemplate) {
				mgiProfile = await this.templateResource.cpr(
					sourceTemplate.templateName,
					effectiveTs
				);
			} else if (sourceTollFreeNumber) {
				mgiProfile = await this.numberResource.cpr(
					sourceTollFreeNumber.tfn,
					effectiveTs
				);
			}

			if (mgiProfile) {
				this.props.onCprSelect(mgiProfile);
				onLoadingChange(false);
			}
		} finally {
			this.setState({ isLoadingCpr: false });
		}
	}

	public render() {
		const { sourceTollFreeNumber } = this.state;

		return (
			<Row>
				<Col md={6}>
					{this.props.cloneSource === RoutingCacheTypes.TollFree && (
						<article>
							<TollFreeNumberFormControl
								required={this.props.required}
								alwaysShowMask
								onlyNumbers
								onChange={(sourceNumber) =>
									this.updateSourceNumber(sourceNumber)
								}
								value={this.state.sourceNumber}
							/>
						</article>
					)}
					{sourceTollFreeNumber && sourceTollFreeNumber.templateName && (
						<Alert variant="warning">
							<span>
								{TollFreeNumbersUtils.tollFreeNumberToReadableString(
									sourceTollFreeNumber.tfn
								)}{' '}
								is associated with a template.&nbsp;
							</span>
							<a
								onClick={() =>
									sourceTollFreeNumber.templateName &&
									this.moveToSourceTemplateName(
										sourceTollFreeNumber.templateName
									)
								}
							>
								Click here to use it&apos;s template,{' '}
								{sourceTollFreeNumber.templateName}.
							</a>
						</Alert>
					)}
					{this.props.cloneSource === RoutingCacheTypes.Template && (
						<article>
							<TemplateSelect
								required={this.props.required}
								initialOptionId={this.state.sourceDefaultTemplateName}
								respOrgId={this.props.respOrgId}
								onChange={(sourceTemplate) =>
									this.updateSourceTemplate(sourceTemplate)
								}
								value={this.state.sourceTemplate}
							/>
						</article>
					)}
				</Col>
				<Col md={6}>
					<article style={{ position: 'relative' }}>
						<CloneRoutingEffectiveTimestampSelect
							required={this.props.required}
							isLoading={this.state.isLoadingSourceCprs}
							options={this.state.sourceCprs}
							onChange={(selectedCpr) => this.onCprSelect(selectedCpr)}
							value={this.state.selectedCpr}
						/>
					</article>
				</Col>
			</Row>
		);
	}
}
