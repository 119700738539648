/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MetricsTotalsByDateResponse = {
    properties: {
        date: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        activationQty: {
            type: 'number',
            isRequired: true,
        },
        cancellationQty: {
            type: 'number',
            isRequired: true,
        },
        totalActiveQty: {
            type: 'number',
            isRequired: true,
        },
    },
};