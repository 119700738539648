/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoutingSummaryIndexResponse = {
    properties: {
        attempts: {
            type: 'number',
            isRequired: true,
        },
        accepts: {
            type: 'number',
            isRequired: true,
        },
        connects: {
            type: 'number',
            isRequired: true,
        },
        minutes: {
            type: 'number',
            isRequired: true,
        },
        asr: {
            type: 'number',
            isRequired: true,
        },
        acd: {
            type: 'number',
            isRequired: true,
        },
        ner: {
            type: 'number',
            isRequired: true,
        },
        customerSubscription: {
            type: 'string',
            isRequired: true,
        },
        vendorSubscription: {
            type: 'string',
            isRequired: true,
        },
        country: {
            type: 'string',
            isRequired: true,
        },
    },
};