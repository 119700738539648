import {
	AuditTemplateCprIndexResponse,
	AuditTollFreeCprIndexResponse,
	RespOrgTroubleProfileResponse,
	TollFreeCprCloneRequest,
	TollFreeCprIndexResponse,
	TollFreeCprProfileRequest,
	NaHistory,
	TollFreeIndexResponse,
	TollFreeProfileRequest,
	TollFreeProfileResponse,
	TollFreeQueryResponse,
	TollFreeScpIndexResponse,
	RoutingCacheTypes
} from 'RtModels';
import { CprResource } from 'RtUi/app/rt800/Cprs/lib/resources/CprResource';
import { Rt800ApiRoutes } from 'RtExports/routes';
import { ITaskProfile } from 'RtUi/app/rt800/Tasks/lib/resources/TaskResource';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';

export interface INumberTroubleProfile extends RespOrgTroubleProfileResponse {}
export interface INumberIndex extends TollFreeIndexResponse {}
export interface INumberProfile extends TollFreeProfileResponse {}
export interface ITollFreeQuery extends TollFreeQueryResponse {}
export interface IAuditTollFreeCprIndexResponse
	extends AuditTollFreeCprIndexResponse {}
export interface IAuditTemplateCprIndexResponse
	extends AuditTemplateCprIndexResponse {}

export type AuditTollFreeCprResourceType =
	ArrayResource<IAuditTollFreeCprIndexResponse>;
export type AuditTemplateForResourceType =
	ArrayResource<IAuditTemplateCprIndexResponse>;

export interface INumberResourceParams {
	entityId?: string;
	respOrgId?: string;
	templateName?: string;
	tfn?: string;
}

export class NumberResource extends CprResource<
	INumberIndex,
	INumberProfile,
	TollFreeCprIndexResponse,
	TollFreeScpIndexResponse
> {
	protected getAllParams: INumberResourceParams = {};

	constructor() {
		super(Rt800ApiRoutes.TollFrees);
	}

	public setParam<K extends keyof INumberResourceParams>(
		key: K,
		value?: INumberResourceParams[K]
	) {
		value = value ? value : undefined;
		this.getAllParams[key] = value;
	}

	public getParam<K extends keyof INumberResourceParams>(
		key: K
	): INumberResourceParams[K] | undefined {
		return this.getAllParams[key];
	}

	public reserve(tfn: string) {
		const urlParams: TollFreeProfileRequest = { tfn };

		return this.fetchWithRoute<ITaskProfile>(
			Rt800ApiRoutes.TollFreeActionReserve.Create,
			{ urlParams }
		);
	}

	public activate(tfn: string, templateName: string) {
		const req = { templateName };
		const body = JSON.stringify(req);
		const urlParams: TollFreeProfileRequest = { tfn };

		return this.fetchWithRoute<ITaskProfile>(
			Rt800ApiRoutes.TollFreeActionActivate.Create,
			{ body, urlParams }
		);
	}

	public trouble(tfn: string) {
		const urlParams: TollFreeProfileRequest = { tfn };

		return this.fetchWithRoute<INumberTroubleProfile>(
			Rt800ApiRoutes.TollFreeTrouble.Profile,
			{ urlParams }
		);
	}

	public query(tfn: string) {
		const urlParams: TollFreeProfileRequest = { tfn };

		return this.fetchWithRoute<ITollFreeQuery>(
			Rt800ApiRoutes.TollFreeQuery.Profile,
			{ urlParams }
		);
	}

	public history(tfn: string) {
		const urlParams: TollFreeProfileRequest = { tfn };

		return this.fetchWithRoute<NaHistory[]>(
			Rt800ApiRoutes.TollFreeHistory.Index,
			{ urlParams }
		);
	}

	public getHistoryResource(tfn: string) {
		const numberHistoryResource = new ArrayResource<NaHistory>('statusTs');
		const urlParams: TollFreeProfileRequest = { tfn };

		numberHistoryResource.setApiRouteForGetAll(
			Rt800ApiRoutes.TollFreeHistory.Index,
			{ urlParams }
		);

		return numberHistoryResource;
	}

	public getAuditCprResourceForTemplates(): AuditTemplateForResourceType {
		const auditTemplateResource: AuditTemplateForResourceType =
			new ArrayResource<IAuditTemplateCprIndexResponse>('templateName');

		auditTemplateResource.setApiRouteForGetAll(
			Rt800ApiRoutes.AuditTemplateCprs.Index
		);

		return auditTemplateResource;
	}

	public getAuditCprResourceForTollFrees(): AuditTollFreeCprResourceType {
		const auditTollFreeResource: AuditTollFreeCprResourceType =
			new ArrayResource<IAuditTollFreeCprIndexResponse>('tfn');

		auditTollFreeResource.setApiRouteForGetAll(
			Rt800ApiRoutes.AuditTollFreeCprs.Index
		);

		return auditTollFreeResource;
	}

	public clone(
		tfn: string,
		effectiveTs: Date,
		targetTypeId: RoutingCacheTypes,
		targetKey: string,
		targetEffectiveTs?: Date
	) {
		const req: TollFreeCprCloneRequest = {
			targetTypeId,
			targetKey,
			effectiveTs: targetEffectiveTs
		};

		//Delete if is now/undefined
		if (req.effectiveTs === undefined) {
			delete req.effectiveTs;
		}

		const body = JSON.stringify(req);
		const params: TollFreeCprProfileRequest = { effectiveTs };
		const urlParams: TollFreeProfileRequest = { tfn };

		return this.fetchWithRoute<ITaskProfile>(
			Rt800ApiRoutes.TollFreeCprClone.Update,
			{ body, params, urlParams }
		);
	}
}
