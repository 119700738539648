/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $SipAgentStatisticIndexResponse = {
    properties: {
        timeslot: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        reqInvQty: {
            type: 'number',
            isRequired: true,
        },
        reqOptQty: {
            type: 'number',
            isRequired: true,
        },
        reqRegQty: {
            type: 'number',
            isRequired: true,
        },
        res1xxQty: {
            type: 'number',
            isRequired: true,
        },
        res2xxQty: {
            type: 'number',
            isRequired: true,
        },
        res3xxQty: {
            type: 'number',
            isRequired: true,
        },
        res4xxQty: {
            type: 'number',
            isRequired: true,
        },
        res5xxQty: {
            type: 'number',
            isRequired: true,
        },
        res6xxQty: {
            type: 'number',
            isRequired: true,
        },
        agtLabel: {
            type: 'string',
        },
        agtFqdn: {
            type: 'string',
        },
        agtPort: {
            type: 'number',
        },
        remoteLabel: {
            type: 'string',
        },
        remoteFqdn: {
            type: 'string',
        },
        remotePort: {
            type: 'number',
        },
    },
};