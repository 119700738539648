import { TrackingGroupEditor } from 'RtUi/app/rtSip/TrackingGroups/lib/forms/TrackingGroupEditor';
import { ProfileApplicationContainer } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { TrackingGroupResource } from './lib/resources/TrackingGroupResource';
import {
	TrackingGroupContainerTabs,
	TrackingGroupRouter
} from './TrackingGroup.router';
import { TrackingGroupProfileResponse } from 'RtModels';

interface ITrackingGroupContainerState {
	sipTrackingGroup: TrackingGroupProfileResponse | undefined;
	activeTab: string;
}

@TrackingGroupRouter.getProfileRtUiController()
export class TrackingGroupProfileContainer extends ProfileApplicationContainer<
	{},
	ITrackingGroupContainerState
> {
	public Tabs = TrackingGroupContainerTabs;

	public state: ITrackingGroupContainerState = {
		sipTrackingGroup: undefined,
		activeTab: this.Tabs.Profile.header
	};

	public componentDidMount() {
		const sipTrackingGroupResource = new TrackingGroupResource();
		const sipId = this.getIdParam();

		sipTrackingGroupResource.get(sipId).then((sipTrackingGroup) => {
			this.setState({ sipTrackingGroup });
		});
	}

	public render() {
		const { sipTrackingGroup } = this.state;

		if (!sipTrackingGroup) {
			return null;
		}

		return (
			<TabbedLayout<TrackingGroupProfileResponse>
				router={TrackingGroupRouter}
				profile={sipTrackingGroup}
				activeTab={this.state.activeTab}
				onTabChange={(activeTab) => this.setState({ activeTab })}
			>
				<TabbedLayoutTab {...this.Tabs.Profile}>
					<TrackingGroupEditor
						editMode={sipTrackingGroup}
						onUpdateSuccess={(sipTrackingGroup) =>
							this.setState({ sipTrackingGroup })
						}
					/>
				</TabbedLayoutTab>
			</TabbedLayout>
		);
	}
}
