/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RtAosColDto = {
    properties: {
        aosNodeTypeId: {
            type: 'AosNodeType',
            isRequired: true,
        },
        values: {
            type: 'Array',
            isRequired: true,
        },
    },
};