/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $BulkSearchPatternRequest = {
    properties: {
        respOrgId: {
            type: 'string',
            isRequired: true,
        },
        areaCodes: {
            type: 'Array',
            isRequired: true,
        },
        pattern: {
            type: 'string',
            isRequired: true,
        },
        quantity: {
            type: 'number',
            isRequired: true,
        },
        consecutive: {
            type: 'boolean',
        },
        scheduledAt: {
            type: 'Date',
            format: 'date-time',
        },
        priority: {
            type: 'number',
        },
        taskLabel: {
            type: 'string',
        },
    },
};