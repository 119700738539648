/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export enum ReportAggregates {
    Asr = 'asr',
    Margin = 'margin',
    Network = 'network',
    Robo = 'robo',
}