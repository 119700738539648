import {
	ISelectFormControlState,
	ISelectFormControlProps,
	SelectFormControl
} from 'RtUi/components/form/SelectFormControl';
import { EmailAccountIndexResponse } from 'RtModels';
import { EmailAccountArrayResource } from 'RtUi/app/rtAdmin/Partitions/lib/resource/EmailAccountResource';
import { Badge } from 'react-bootstrap';

interface IEmailAccountSelectProps
	extends ISelectFormControlProps<EmailAccountIndexResponse> {}
interface IEmailAccountSelectState
	extends ISelectFormControlState<EmailAccountIndexResponse> {}

export class EmailAccountSelect extends SelectFormControl<
	EmailAccountIndexResponse,
	false,
	IEmailAccountSelectProps,
	IEmailAccountSelectState
> {
	public resourceClass = EmailAccountArrayResource;
	public state: IEmailAccountSelectState = {
		formLabel: 'Email Account',
		valueKey: 'emailAccountId',
		labelKey: 'displayName'
	};

	public optionRenderer = (emailAccount: EmailAccountIndexResponse) => (
		<section>
			<span className="d-inline-block me-2">{emailAccount.displayName}</span>
			<Badge bg="light" text="dark">
				{emailAccount.emailAddress}
			</Badge>
		</section>
	);
}
