import { IProfileApplicationContainerTabs } from 'RtUi/components/containers/TabbedApplicationContainer';
import { TabbedLayoutTabProps } from 'RtUi/components/ui/TabbedLayout';
import { RateLoadIndexResponse, RateLoadProfileResponse } from 'RtModels';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { format } from 'date-fns';
import { AccountManagementRouter } from 'RtUi/app/AccountManagement/AccountManagement.router';

interface IRateManagementRouterTabs extends IProfileApplicationContainerTabs {
	Profile: TabbedLayoutTabProps;
}

export const RateManagementContainerTabs: IRateManagementRouterTabs = {
	Profile: {
		header: 'Profile'
	}
};

export class RateManagementRouterClass extends AccountManagementRouter<
	RateLoadIndexResponse,
	RateLoadProfileResponse,
	IRateManagementRouterTabs
> {
	constructor() {
		super(
			'Rate Management',
			'rateLoads',
			'rateLoadId',
			RateManagementContainerTabs
		);

		this.setPermissionsFromApiRoute(RtCommonApiRoutes.RateLoads);
	}

	public getProfileLabel(profile: RateLoadProfileResponse): string {
		return `${profile.uploadedFileName} (${format(
			new Date(profile.notificationTs),
			'MM/dd/yyyy hh:mma'
		)})`;
	}

	public getPluralName() {
		return this.getName();
	}
}

export const RateManagementRouter = new RateManagementRouterClass();
