import { FC, useContext, useEffect, useMemo } from 'react';
import { useUpdate } from 'react-use';
import { Alert, Card } from 'react-bootstrap';
import * as CprErrorUtils from 'RtUi/app/rt800/Cprs/lib/util/CprErrorUtils';
import { CprError, CprErrorType, CprSection } from 'Somos/lib/SomosCpr/RtCprV2';
import { CprProfileContext } from 'RtUi/app/rt800/Cprs/lib/context/CprContext';

export const CprErrorsAlert: FC<React.PropsWithChildren<unknown>> = () => {
	const updateComponent = useUpdate();
	const {
		cpr: mgiProfile,
		setActiveTab,
		cprDataGrid,
		cprLabelDataGrid,
		router
	} = useContext(CprProfileContext);
	const tabs = useMemo(() => router.getProfileTabs(), [router]);

	useEffect(() => {
		return mgiProfile.onValidate(() => {
			updateComponent();
		});
	});

	const pageToCprGridIndex = (index: number) => {
		setActiveTab(tabs.Cpr.header);
		cprDataGrid.current?.pageToIndex(index);
	};

	const pageToLabelGridIndex = (index: number) => {
		setActiveTab(tabs.Lad.header);
		cprLabelDataGrid.current?.pageToIndex(index);
	};

	const renderCprError = (error: CprError) => {
		const message = CprErrorUtils.formatErrorMessage(error, true);

		return (
			<li
				key={error.cprIndex + error.message + error.cprValue}
				className="d-flex justify-content-start align-items-center"
			>
				<i
					className={CprErrorUtils.getErrorIconAndColorClassnames(
						error.cprErrorTypeId
					)}
				/>
				&nbsp;
				<span>
					{message}
					{error.cprValue && <b>&nbsp;({error.cprValue})</b>}
				</span>
			</li>
		);
	};

	const renderCprErrorBasedOnIndex = (
		header: string,
		cprErrors: CprError[],
		onHeaderClick: (index: number) => void
	) => {
		const errorsBasedOnIndexMap = new Map<number, CprError[]>();

		for (const cprError of cprErrors) {
			errorsBasedOnIndexMap.set(cprError.cprIndex, [
				...(errorsBasedOnIndexMap.get(cprError.cprIndex) ?? []),
				cprError
			]);
		}

		const errorsBasedOnIndexEntries = Array.from(
			errorsBasedOnIndexMap.entries()
		);

		errorsBasedOnIndexEntries.sort(([index1], [index2]) => index1 - index2);

		return errorsBasedOnIndexEntries.map(([index, errors]) => {
			const humanReadableIndex = index + 1;

			return (
				<section key={index} className="mb-2">
					<header>
						<h6>
							<a onClick={() => onHeaderClick(index)}>
								<i className="fas fa-fw fa-link" />
								{header} {humanReadableIndex.toLocaleString()}
							</a>
						</h6>
					</header>
					{errors.map(renderCprError)}
				</section>
			);
		});
	};

	if (!mgiProfile.hasErrors()) {
		return (
			<Alert variant="success">
				<h6>
					Validation Passed&nbsp;
					<i className="fas fa-fw fa-thumbs-up" />
				</h6>
				<p>No known errors were found.</p>
			</Alert>
		);
	}

	const errors = mgiProfile.getErrors();
	const errorsGroupedBySection = new Map<CprSection, CprError[]>();

	for (const error of errors) {
		const errorsWithSection =
			errorsGroupedBySection.get(error.cprSectionId) ?? [];

		errorsWithSection.push(error);

		errorsGroupedBySection.set(error.cprSectionId, errorsWithSection);
	}

	return (
		<Card className="border-danger">
			<section
				className="d-flex justify-content-end"
				style={{ position: 'absolute', right: '1.25rem', top: '1.25rem' }}
			>
				<span className="me-3">
					<i
						className={CprErrorUtils.getErrorIconAndColorClassnames(
							CprErrorType.Warning
						)}
					/>
					&nbsp;Warning
				</span>
				<span className="me-3">
					<i
						className={CprErrorUtils.getErrorIconAndColorClassnames(
							CprErrorType.Error
						)}
					/>
					&nbsp;Error
				</span>
				<span className="me-3">
					<i
						className={CprErrorUtils.getErrorIconAndColorClassnames(
							CprErrorType.Critical
						)}
					/>
					&nbsp;Critical Error
				</span>
			</section>
			<Card.Body className="section-container-mb-5">
				{errorsGroupedBySection.has(CprSection.CprSectionAos) && (
					<section>
						<h5>Areas of Service</h5>
						<hr />
						<ul className="list-unstyled">
							{errorsGroupedBySection
								.get(CprSection.CprSectionAos)!
								.slice(0, 10)
								.map(renderCprError)}
						</ul>
					</section>
				)}
				{errorsGroupedBySection.has(CprSection.CprSectionAosLabel) && (
					<section>
						<h5>Areas of Service Labels</h5>
						<hr />
						<ul className="list-unstyled">
							{errorsGroupedBySection
								.get(CprSection.CprSectionAosLabel)!
								.slice(0, 10)
								.map(renderCprError)}
						</ul>
					</section>
				)}
				{errorsGroupedBySection.has(CprSection.CprSectionAdmin) && (
					<section>
						<h5>Admin</h5>
						<hr />
						<ul className="list-unstyled">
							{errorsGroupedBySection
								.get(CprSection.CprSectionAdmin)!
								.slice(0, 10)
								.map(renderCprError)}
						</ul>
					</section>
				)}
				{errorsGroupedBySection.has(CprSection.CprSectionCpr) && (
					<section>
						<h5>CPR</h5>
						<hr />
						<ul className="list-unstyled">
							{renderCprErrorBasedOnIndex(
								'Row',
								errorsGroupedBySection
									.get(CprSection.CprSectionCpr)!
									.slice(0, 10),
								pageToCprGridIndex
							)}
						</ul>
					</section>
				)}
				{errorsGroupedBySection.has(CprSection.CprSectionCprLabel) && (
					<section>
						<h5>Labels</h5>
						<hr />
						<ul className="list-unstyled">
							{renderCprErrorBasedOnIndex(
								'Label',
								errorsGroupedBySection
									.get(CprSection.CprSectionCprLabel)!
									.slice(0, 10),
								pageToLabelGridIndex
							)}
						</ul>
					</section>
				)}
			</Card.Body>
		</Card>
	);
};
