import * as React from 'react';
import { RtUiWideForm } from 'RtUi/components/ui/RtUiForm';
import { DragAndDropFilesUploader } from 'RtUi/components/ui/DragAndDropFileUploader/DragAndDropFilesUploader';
import { UiPromptAudioFileUtils } from 'RtUi/app/rtSip/PromptSegments/lib/utils/UiPromptAudioFileUtils';
import { PromptResource } from 'RtUi/app/rtSip/PromptSegments/lib/resources/PromptResource';
import { PromptIndexResponse } from 'RtModels';

interface IBulkPromptUploaderProps {
	onCreate?: (promptIndexes: PromptIndexResponse[]) => void;
}

interface IBulkPromptUploaderState {
	displayMode: boolean;
	isSubmitting: boolean;
	error?: any;
	files?: File[];
}

export class BulkPromptUploader extends React.Component<
	IBulkPromptUploaderProps,
	IBulkPromptUploaderState
> {
	public promptMimeTypes = UiPromptAudioFileUtils.PromptFileTypes.join(',');
	public state: IBulkPromptUploaderState = {
		displayMode: false,
		isSubmitting: false,
		error: undefined
	};

	public async onSubmit(evt: React.FormEvent<HTMLFormElement>) {
		evt.preventDefault();

		const promptResource = new PromptResource();
		const { files } = this.state;

		if (!files) {
			return;
		}

		this.setState({ error: undefined, isSubmitting: true });
		let promptIndexes: PromptIndexResponse[] | null = null;

		try {
			promptIndexes = await promptResource.bulkCreate(files);
		} catch (error) {
			this.setState({ error });
		} finally {
			this.setState({ isSubmitting: false });
		}

		const { onCreate = () => ({}) } = this.props;

		if (promptIndexes) {
			onCreate(promptIndexes);
		}
	}

	public render() {
		return (
			<RtUiWideForm
				createMode
				onSubmit={(evt) => this.onSubmit(evt)}
				displayMode={this.state.displayMode}
				isSubmitting={this.state.isSubmitting}
				error={this.state.error}
			>
				<DragAndDropFilesUploader
					accept={this.promptMimeTypes}
					onDrop={(files) => this.setState({ files })}
					value={this.state.files}
				/>
			</RtUiWideForm>
		);
	}
}
