/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RejectSummaryIndexResponse = {
    properties: {
        callDate: {
            type: 'string',
            isRequired: true,
        },
        file_stream_id: {
            type: 'number',
            isRequired: true,
        },
        ingress_trunk_group_id: {
            type: 'string',
        },
        egress_trunk_group_id: {
            type: 'string',
        },
        revenue_reject_code: {
            type: 'RejectCodes',
        },
        cost_reject_code: {
            type: 'RejectCodes',
        },
        calculated_call_type: {
            type: 'string',
        },
        rejectRevenueCallCount: {
            type: 'number',
            isRequired: true,
        },
        rejectRevenueMinutes: {
            type: 'number',
            isRequired: true,
        },
        rejectCostCallCount: {
            type: 'number',
            isRequired: true,
        },
        rejectCostMinutes: {
            type: 'number',
            isRequired: true,
        },
        minCallDate: {
            type: 'string',
            isRequired: true,
        },
        maxCallDate: {
            type: 'string',
            isRequired: true,
        },
    },
};