import { RoutingRulesMatchTypesIndexResponse } from 'RtModels';
import { useGetRoutingRuleMatchTypes } from 'RtUi/app/rtSip/RoutingRules/lib/services';
import {
	ISelectFormControlInstanceProps,
	SelectFormControl
} from 'RtUi/components/ui/SelectFormControl';

interface IRoutingRuleMatchTypeSelectProps<IsMulti extends boolean>
	extends ISelectFormControlInstanceProps<
		RoutingRulesMatchTypesIndexResponse,
		'routingRuleMatchTypeId',
		IsMulti
	> {}

export const RoutingRuleMatchTypeSelect = <IsMulti extends boolean = false>({
	label = 'Match Type',
	...props
}: IRoutingRuleMatchTypeSelectProps<IsMulti>): JSX.Element => {
	const { data, isLoading } = useGetRoutingRuleMatchTypes();

	return (
		<SelectFormControl<
			RoutingRulesMatchTypesIndexResponse,
			'routingRuleMatchTypeId',
			IsMulti
		>
			label={label}
			valueKey="routingRuleMatchTypeId"
			isLoading={isLoading}
			labelKey="label"
			options={data ?? []}
			{...props}
		/>
	);
};
