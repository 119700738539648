import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import {
	DefaultSipAgentConfig,
	SipAgentAddressIndexRequest,
	SipAgentAddressIndexResponse,
	SipAgentConfigIndexRequest,
	SipAgentConfigIndexResponse,
	SipAgentConfigProfileResponse,
	SipAgentIndexRequest,
	SipAgentIndexResponse,
	SipAgentProfileResponse
} from 'RtModels';
import { SipAgentConfigsResource } from 'RtUi/app/AccountManagement/SipAgents/lib/resources/SipAgentConfigsResource';
import { SipAgentDefaultConfigResource } from 'RtUi/app/AccountManagement/SipAgents/lib/resources/SipAgentDefaultConfigResource';
import { SipAgentsResource } from 'RtUi/app/AccountManagement/SipAgents/lib/resources/SipAgentsResource';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchSipAgent = async (
	sipAgentId: number
): Promise<SipAgentProfileResponse> => {
	const data = await new SipAgentsResource().getProfile(sipAgentId);
	return data;
};

export const useGetSipAgent = (sipAgentId: number) => {
	return useQuery<SipAgentProfileResponse, Error>(
		[`getSipAgent${sipAgentId}`, sipAgentId],
		() => fetchSipAgent(sipAgentId),
		{ enabled: true }
	);
};

const fetchSipAgents = async (
	queryParams?: SipAgentIndexRequest
): Promise<FullResponse<SipAgentIndexResponse[]>> => {
	return handleGetRequest(RtCommonApiRoutes.SipAgents.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetSipAgents = (queryParams?: SipAgentIndexRequest) => {
	return useQuery<FullResponse<SipAgentIndexResponse[]>, Error>(
		['getSipAgents', queryParams],
		() => fetchSipAgents(queryParams),
		{ enabled: true }
	);
};

const fetchSipAgentConfigs = async (
	queryParams?: SipAgentConfigIndexRequest
): Promise<FullResponse<SipAgentConfigIndexResponse[]>> => {
	return handleGetRequest(RtCommonApiRoutes.SipAgentConfigs.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetSipAgentConfigs = (
	queryParams?: SipAgentConfigIndexRequest
) => {
	return useQuery<FullResponse<SipAgentConfigIndexResponse[]>, Error>(
		['getSipAgentConfigs', queryParams],
		() => fetchSipAgentConfigs(queryParams),
		{ enabled: true }
	);
};

const fetchSipAgentConfig = async (
	sipAgentConfigId: number
): Promise<SipAgentConfigProfileResponse> => {
	const data = await new SipAgentConfigsResource().getProfile(sipAgentConfigId);
	return data;
};

export const useGetSipAgentConfig = (sipAgentConfigId: number) => {
	return useQuery<SipAgentConfigProfileResponse, Error>(
		[`getSipAgentConfig${sipAgentConfigId}`, sipAgentConfigId],
		() => fetchSipAgentConfig(sipAgentConfigId),
		{ enabled: true }
	);
};

const fetchSipAgentDefaultConfig = async (): Promise<DefaultSipAgentConfig> => {
	const data = await new SipAgentDefaultConfigResource().getProfile();
	return data;
};

export const useGetSipAgentDefaultConfig = () => {
	return useQuery<DefaultSipAgentConfig, Error>(
		'getSipAgentDefaultConfig',
		fetchSipAgentDefaultConfig,
		{ enabled: true }
	);
};

const fetchSipAgentAddresses = async (
	queryParams?: SipAgentAddressIndexRequest
): Promise<FullResponse<SipAgentAddressIndexResponse[]>> => {
	return handleGetRequest(RtCommonApiRoutes.SipAgentAddresses.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetSipAgentAddresses = (
	queryParams?: SipAgentAddressIndexRequest
) => {
	return useQuery<FullResponse<SipAgentAddressIndexResponse[]>, Error>(
		['getSipAgentAddresses', queryParams],
		() => fetchSipAgentAddresses(queryParams),
		{ enabled: true }
	);
};
