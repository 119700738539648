import { AppProviders } from 'RtUi/app/AppProviders';
import 'RtUi/components/config';
import { generateUUID } from 'RtUi/utils/http/resources/utils';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import 'reflect-metadata';
import 'regenerator-runtime/runtime';
import {
	ApplicationShellContainer,
	IApplicationContainerProps
} from './ApplicationShell/ApplicationShellContainer';
import { RtUiAppModule } from './RtUiApp.module';

Chart.register(...registerables, zoomPlugin);
const rootEl = document.getElementById('root');
const root = createRoot(rootEl!);

/**
 * Renders the Application to the DOM
 * @param ApplicationShellContainer
 */
const renderApp = debounce(
	(
		ApplicationShellContainer: React.ComponentType<
			React.PropsWithChildren<IApplicationContainerProps>
		>,
		rtModule: new () => RtUiAppModule
	) => {
		const key = generateUUID();
		const renderElement = (
			<AppProviders>
				<React.StrictMode>
					<ApplicationShellContainer key={key} rtModule={rtModule} />
				</React.StrictMode>
			</AppProviders>
		);

		root.render(renderElement);
	},
	50
);

//Init App
renderApp(ApplicationShellContainer, RtUiAppModule);

// Hot-reloads components while developing
if (module.hot) {
	const updateApp = () => {
		const updatedAppShell =
			require('./ApplicationShell/ApplicationShellContainer') as {
				ApplicationShellContainer: typeof ApplicationShellContainer;
			};
		const updatedModule = require('./RtUiApp.module') as {
			RtUiAppModule: typeof RtUiAppModule;
		};

		const NewApplicationShellContainer =
			updatedAppShell.ApplicationShellContainer;
		const NewRtUiAppModule = updatedModule.RtUiAppModule;

		renderApp(NewApplicationShellContainer, NewRtUiAppModule);
	};

	module.hot.accept('./ApplicationShell/ApplicationShellContainer', updateApp);
	module.hot.accept('./RtUiApp.module', updateApp);
}
