import { CprNumberIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { Alert } from 'react-bootstrap';

interface FutureCprsDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	futureCprs: CprNumberIndexResponse[];
}

export const FutureCprsDataGridColumn = <T = any,>({
	futureCprs,
	...config
}: FutureCprsDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (tfn: string) => {
		return futureCprs.filter((futureCpr) => futureCpr.tfn === tfn).length;
	};

	const renderValue = (tfn: string) => {
		const futureCprsForTfn = getValue(tfn);

		const color = futureCprsForTfn > 0 ? 'warning' : 'light';
		const id = `alert-${tfn}`;

		return (
			<>
				<section className="text-end" id={id}>
					<Alert
						className="alert-sm mb-0 py-0 px-2 d-inline-block"
						style={{ minWidth: 65 }}
						transition={false}
						variant={color}
					>
						<span>{futureCprsForTfn}</span>
					</Alert>
				</section>
			</>
		);
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value) => getValue(value),
		getValue: ({ cell }) => renderValue(cell.getValue<string>())
	});
};
