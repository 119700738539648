import { CdrExportIndexResponse, CdrExportProfileResponse } from 'RtModels';
import { RtxSipApiRoutes } from 'RtExports/routes';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { HttpRequest } from 'RtUi/utils/http/HttpRequest';

export class CdrExportResource extends HttpResource<
	CdrExportIndexResponse,
	CdrExportProfileResponse
> {
	public fileUtils = new FileUtils();

	constructor() {
		super(RtxSipApiRoutes.CdrExport);
	}

	public get(cdrExportId: number): Promise<CdrExportProfileResponse> {
		const urlParams = { cdrExportId };

		return HttpRequest.fetch<CdrExportProfileResponse>(
			`${this.resourcePath}/${cdrExportId}`,
			this.profilePermission,
			{ urlParams }
		);
	}

	public async downloadCdr(cdrExportId: number) {
		const downloadPromise = this.get(cdrExportId).then((res) => ({
			url: res.signedUrl,
			fileName: res.fileName
		}));

		await this.fileUtils.downloadFromUrlWithPromise(downloadPromise);
	}
}
