import { ErrorBoundary } from 'RtUi/app/ApplicationShell/lib/components/ErrorBoundary';
import {
	IPermissionRoute,
	IRouteMenu,
	IRouteMenuBase
} from 'RtUi/app/ApplicationShell/lib/interfaces';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import { UserActions } from 'RtUi/state/actions/user';
import * as React from 'react';
import { Params, matchPath, useParams } from 'react-router-dom';
import { IClassType } from './interfaces';
import { RtxReportId } from 'RtModels';

export const RtUiModuleReflectKey = Symbol('RtUiModule');
export const RtUiControllerReflectKey = Symbol('RtUiController');

export type IRtUiControllerParams = IPermissionRoute & {
	hideNav?: boolean;
	unauthorizedAccessAllowed?: boolean;
};

interface IRtUiModuleRouteParam extends IRouteMenuBase {
	path?: string;
}

interface IRtUiModuleParams {
	route?: IRtUiModuleRouteParam;
	routers?: RtUiRouter[];
	controllers?: Array<React.ComponentType<React.PropsWithChildren<any>>>;
	imports?: IClassType[];
}

type IRtUiControllerReflectParams = IRtUiControllerParams & {
	element: React.ReactNode;
};

export interface IRouteMenuWithController extends IRouteMenu {
	routes: IRtUiControllerReflectParams[];
}

export interface IReportRoute {
	id: RtxReportId;
	path: string;
	name: string;
	groupName: string;
}

interface IAppRoutes {
	routeMenus: IRouteMenuWithController[];
	reportRoutes: Map<string, IReportRoute[]>;
}

interface IRouteComponentUrlParams {
	params: Readonly<Params<string>>;
}

export const withParams = <P = any,>(
	WrappedComponent: React.ComponentType<any>,
	key: string
): React.ComponentType<P & IRouteComponentUrlParams> => {
	return (props: P) => {
		const params = useParams();
		return (
			<ErrorBoundary key={key}>
				<WrappedComponent {...props} params={params} />
			</ErrorBoundary>
		);
	};
};

export const RtUiController = (
	params: IRtUiControllerParams
): ClassDecorator => {
	return (constructor: Function) => {
		const RouteComponent: React.ComponentType<React.PropsWithChildren<any>> =
			constructor as any;
		RtUiFunctionalComponent(RouteComponent, params);
	};
};

export const RtUiFunctionalComponent = (
	RouteComponent: React.ComponentType<any>,
	params: IRtUiControllerParams
) => {
	const RouteComponentWrapper = withParams(RouteComponent, params.path);
	const element: React.ReactNode = <RouteComponentWrapper />;

	const reflectParams: IRtUiControllerReflectParams = {
		...params,
		element
	};

	Reflect.defineMetadata(
		RtUiControllerReflectKey,
		reflectParams,
		RouteComponent
	);
};

export const RtUiModule: (params: IRtUiModuleParams) => ClassDecorator = (
	params: IRtUiModuleParams
) => {
	return (constructor: Function) => {
		Reflect.defineMetadata(RtUiModuleReflectKey, params, constructor);
	};
};

const addToReportRoutes = (
	reportRoutes: Map<string, IReportRoute[]>,
	key: string,
	value: IReportRoute[]
) => {
	const currentReportRoutes = reportRoutes.get(key) ?? [];
	reportRoutes.set(key, [...currentReportRoutes, ...value]);
};

/**
 *
 * @param rtModule
 * @param basePath
 */
export const GetRoutesFromModule = (
	rtModule: IClassType,
	basePath = '',
	routeMenu?: IRouteMenuWithController
): IAppRoutes => {
	const routeMenus: IRouteMenuWithController[] = [];
	const reportRoutes: Map<string, IReportRoute[]> = new Map();

	if (Reflect.hasMetadata(RtUiModuleReflectKey, rtModule)) {
		const rtModuleParams: IRtUiModuleParams = Reflect.getMetadata(
			RtUiModuleReflectKey,
			rtModule
		);

		if (rtModuleParams.route) {
			routeMenu = { ...rtModuleParams.route, routes: [] };
			basePath += rtModuleParams.route.path ?? '';
		}

		if (rtModuleParams.imports) {
			for (const rtModuleImport of rtModuleParams.imports) {
				const { routeMenus: importRoutes, reportRoutes: importReportRoutes } =
					GetRoutesFromModule(rtModuleImport, basePath, routeMenu);
				routeMenus.push(...importRoutes);
				importReportRoutes.forEach((value, key) => {
					addToReportRoutes(reportRoutes, key, value);
				});
			}
		}

		if (routeMenu && rtModuleParams.controllers) {
			const { controllers = [] } = rtModuleParams;

			for (const controllerClass of controllers) {
				if (Reflect.hasMetadata(RtUiControllerReflectKey, controllerClass)) {
					const controllerParams: IRtUiControllerReflectParams =
						Reflect.getMetadata(RtUiControllerReflectKey, controllerClass);
					//const { component } = controllerParams;

					const route: IRtUiControllerReflectParams = {
						...controllerParams
					};

					const { permissions = [] } = route;
					const hasPermissions = UserActions.has(...permissions);

					if (!hasPermissions) {
						continue;
					}

					//prepend module's path to controller's path
					route.path = basePath + route.path;

					if (route.isReport && rtModuleParams.route) {
						const newRoute: IReportRoute = {
							id: route.reportId,
							name: route.reportName,
							groupName: route.reportSubGroupName ?? '',
							path: route.path
						};

						addToReportRoutes(reportRoutes, route.reportGroupName, [newRoute]);
					}

					routeMenu.routes.push(route);
				}
			}

			// Sort routes
			routeMenu.routes.sort((r1, r2) => r1.name.localeCompare(r2.name));

			//const routeMenu: IRouteMenuWithController = { ...currentRoute, routes };

			if (!routeMenus.includes(routeMenu)) {
				routeMenus.push(routeMenu);
			}
		}
	}

	return {
		routeMenus: routeMenus.sort((a, b) => {
			const aPriority = a.orderPriority ?? 1;
			const bPriority = b.orderPriority ?? 1;

			return aPriority - bPriority;
		}),
		reportRoutes
	};
};

/**
 *
 * @param rtModule
 * @param basePath
 */
export const GetRoutersFromModule = (rtModule: IClassType): RtUiRouter[] => {
	const routerSet = new Set<RtUiRouter>();

	if (Reflect.hasMetadata(RtUiModuleReflectKey, rtModule)) {
		const rtModuleParams: IRtUiModuleParams = Reflect.getMetadata(
			RtUiModuleReflectKey,
			rtModule
		);

		if (rtModuleParams.routers) {
			for (const router of rtModuleParams.routers) {
				routerSet.add(router);
			}
		}

		if (rtModuleParams.imports) {
			for (const importedModule of rtModuleParams.imports) {
				const routers = GetRoutersFromModule(importedModule);

				for (const router of routers) {
					routerSet.add(router);
				}
			}
		}
	}

	return Array.from(routerSet);
};

export const findRouteMatch = (
	currentPath: string = '/',
	routes: IRouteMenuWithController[]
) => {
	for (const routeConfig of routes) {
		for (const route of routeConfig.routes) {
			if (matchPath(route, currentPath)) {
				return route;
			}
		}
	}

	return undefined;
};
