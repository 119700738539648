import { TrendAnalysisIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { PrecisionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/PrecisionDataGridColumn';

export const getTrendAnalysisColumns = (): Array<
	DataGridColumn<TrendAnalysisIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'country',
		header: 'Country'
	}),
	IntegerDataGridColumn({
		accessorKey: 'egAttempts',
		header: 'Attempts'
	}),
	IntegerDataGridColumn({
		accessorKey: 'egConnects',
		header: 'Connects'
	}),
	IntegerDataGridColumn({
		accessorKey: 'minutes',
		header: 'Minutes'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'revenue',
		header: 'Revenue'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'cost',
		header: 'Cost'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'margin',
		header: 'Margin'
	}),
	PrecisionDataGridColumn({
		accessorKey: 'mpm',
		header: 'MPM'
	})
];
