import { RtxDidApiRoutes } from 'RtExports/routes';
import {
	MetricsByResourceRequest,
	MetricsTotalsByDateRequest,
	MetricsTotalsByDateResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

export const fetchMetricsByDay = async (
	urlParams: MetricsByResourceRequest,
	queryParams?: MetricsTotalsByDateRequest
): Promise<FullResponse<MetricsTotalsByDateResponse[]>> => {
	return handleGetRequest<MetricsTotalsByDateResponse[], true>(
		RtxDidApiRoutes.MetricsByDay.Index,
		{
			includeFullResponse: true,
			queryParams
		}
	);
};

export const useGetMetricsByDay = (
	urlParams: MetricsByResourceRequest,
	queryParams?: MetricsTotalsByDateRequest
) => {
	return useQuery<FullResponse<MetricsTotalsByDateResponse[]>, Error>(
		[`useGetMetricsByDay`, urlParams, queryParams],
		() => fetchMetricsByDay(urlParams, queryParams)
	);
};
