import { useQuery } from 'react-query';
import { RtCommonApiRoutes } from 'RtExports/routes';
import { EmailAliasProfileResponse } from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchEmailAliases = async (): Promise<
	FullResponse<EmailAliasProfileResponse[]>
> => {
	return handleGetRequest<EmailAliasProfileResponse[], true>(
		RtCommonApiRoutes.EmailAlias.Index,
		{
			includeFullResponse: true
		}
	);
};

export const useGetEmailAliases = () => {
	return useQuery<FullResponse<EmailAliasProfileResponse[]>, Error>(
		'getEmailAliases',
		() => {
			return fetchEmailAliases();
		}
	);
};
