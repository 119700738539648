import {
	RoutingRuleSubscriptionResponse,
	SubscriptionIndexResponse
} from 'RtModels';
import { VendorTerminationSubscriptionSelect } from 'RtUi/app/rtSip/CallSimulator/lib/controls/VendorTerminationSubscriptionSelect';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	OverlayTrigger,
	Row,
	Tooltip
} from 'react-bootstrap';

interface IRoutingRuleActionSubscriptionEditorProps {
	subscriptions: RoutingRuleSubscriptionResponse[];
	displayMode?: boolean;
	onChange: (subscriptions: RoutingRuleSubscriptionResponse[]) => void;
}

export const RoutingRuleActionSubscriptionEditor = ({
	subscriptions,
	displayMode = false,
	onChange
}: IRoutingRuleActionSubscriptionEditorProps): JSX.Element => {
	const [value, setValue] = useState<RoutingRuleSubscriptionResponse[]>([]);
	const [subscriptionSelect, setSubscriptionSelect] = useState<
		Map<number, SubscriptionIndexResponse>
	>(new Map());

	useEffect(() => {
		setValue(subscriptions);
	}, [subscriptions]);

	const onAddHandler = () => {
		setValue((currentState) => {
			const newSubscription = {
				weight: 1,
				subscriptionId: undefined
			};

			const newValue = [...currentState, newSubscription as any];
			onChange(newValue);

			return newValue;
		});
	};

	const onChangeHandler = <K extends keyof RoutingRuleSubscriptionResponse>(
		index: number,
		key: K,
		value: RoutingRuleSubscriptionResponse[K]
	) => {
		setValue((currentState) => {
			const newValue = [...currentState];

			if (newValue[index][key] !== value) {
				newValue[index][key] = value;
				onChange(newValue);
			}

			return newValue;
		});
	};

	const onRemoveHandler = (index: number) => {
		setValue((currentState) => {
			const newValue = [...currentState];

			newValue.splice(index, 1);
			onChange(newValue);

			return newValue;
		});
	};

	const onChangeSubscriptionHandler = (
		index: number,
		subs: SubscriptionIndexResponse
	) => {
		setSubscriptionSelect((currentState) => {
			const newState = new Map(currentState);

			newState.set(index, subs);
			onChangeHandler(index, 'subscriptionId', subs.subscriptionId);

			return newState;
		});
	};

	return (
		<Row>
			<Col>
				<Row className="d-flex align-items-center">
					<Col md={11}>
						<h5>Subscriptions</h5>
					</Col>
					<Col md={1}>
						<OverlayTrigger
							overlay={(props) => (
								<Tooltip id={`add-subscription-tooltip`} {...props}>
									Add Subscription
								</Tooltip>
							)}
						>
							{({ ref, ...triggerHandler }) => (
								<Button
									ref={ref}
									{...triggerHandler}
									className="mb-3"
									variant="primary"
									disabled={displayMode}
									onClick={() => onAddHandler()}
								>
									<i className="fas fa-fw fa-plus" />
								</Button>
							)}
						</OverlayTrigger>
					</Col>
				</Row>
				{!Boolean(value.length) && <p>Not any subscription added yet!</p>}
				{value.map((subscription, subscriptionIndex) => (
					<Row key={subscriptionIndex}>
						<Col md={5} className="d-flex gap-3">
							<Form.Label className="pt-2">Weight</Form.Label>
							<InputFormControl
								label=""
								type="number"
								hideLabel
								min={1}
								controlGroupClassName="flex-grow-1"
								value={String(subscription.weight ?? '')}
								displayMode={displayMode}
								onChange={(val) =>
									onChangeHandler(subscriptionIndex, 'weight', Number(val))
								}
								required
							/>
						</Col>
						<Col md={6} className="d-flex gap-3">
							<Form.Label className="pt-2">Subscription</Form.Label>
							<VendorTerminationSubscriptionSelect
								hideLabel
								controlGroupClassName="flex-grow-1"
								value={subscriptionSelect.get(subscriptionIndex)}
								displayMode={displayMode}
								onChange={(subs) =>
									onChangeSubscriptionHandler(subscriptionIndex, subs)
								}
								initialOptionId={subscription.subscriptionId}
								isClearable={false}
								appendDropdownToBody
								required
							/>
						</Col>
						<Col md={1} className="d-flex align-items-end">
							<OverlayTrigger
								overlay={(props) => (
									<Tooltip id={`remove-subscription-tooltip`} {...props}>
										Remove Subscription
									</Tooltip>
								)}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										ref={ref}
										{...triggerHandler}
										className="mb-3"
										variant="danger"
										disabled={displayMode}
										onClick={() => onRemoveHandler(subscriptionIndex)}
									>
										<i className="fas fa-fw fa-trash-alt" />
									</Button>
								)}
							</OverlayTrigger>
						</Col>
					</Row>
				))}
			</Col>
		</Row>
	);
};
