/* eslint-disable no-return-await */
import { useQuery } from 'react-query';
import { RtAdmApiRoutes } from 'RtExports/routes';
import {
	ManagedEntityIndexRequest,
	ManagedEntityIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

const fetchManagedEntities = async (
	queryParams?: ManagedEntityIndexRequest
): Promise<FullResponse<ManagedEntityIndexResponse[]>> => {
	return handleGetRequest(RtAdmApiRoutes.ManagedEntities.Index, {
		queryParams,
		includeFullResponse: true
	});
};

export const useGetManagedEntities = (
	queryParams?: ManagedEntityIndexRequest
) => {
	return useQuery<FullResponse<ManagedEntityIndexResponse[]>, Error>(
		['getManagedEntities', queryParams],
		() => {
			return fetchManagedEntities(queryParams);
		}
	);
};
