/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $UserUpdateRequest = {
    properties: {
        firstName: {
            type: 'string',
            isRequired: true,
        },
        lastName: {
            type: 'string',
            isRequired: true,
        },
        iso3166Alpha3: {
            type: 'string',
            isRequired: true,
        },
        officePhone: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        mobilePhone: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        smsPhone: {
            type: 'string',
            isRequired: true,
            isNullable: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isLocked: {
            type: 'number',
            isRequired: true,
        },
        twoFactorAuthType: {
            type: 'number',
            isRequired: true,
        },
    },
};